import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { DateTimeHelper, log } from '@app/helpers'
import { BreaksViewManager, SectionSwitcherConfig } from '@app/models'
import { CoreService } from '@app/services'
import { SelectItem } from 'primeng/api'

import { BreakProfileTableComponent } from './break-profile-table/break-profile-table.component'

@Component({
    selector: 'app-breaks',
    templateUrl: './breaks.component.html',
    styleUrls: ['./breaks.component.scss'],
    standalone: false
})
export class BreaksComponent implements OnInit, AfterViewInit {
	switcherConfig = new SectionSwitcherConfig()

	// accessHelper: AccessHelper
	showMoreInfo = false

	timezoneOptions: Array<SelectItem> = []

	@ViewChild('breakProfileTable') breakProfileTable: BreakProfileTableComponent

	constructor(
		private router: Router,
		private coreSrvc: CoreService,
	) {
		this.setupAccessPermissions()
	}

	get viewManager(): BreaksViewManager {
		return this.coreSrvc.dbSrvc.breakSrvc.viewManager
	}

	get devDetect() {
		return this.coreSrvc.devDetect
	}

	get lastUpdated(): string {
		const date = this.coreSrvc.dbSrvc.breakSrvc.lastUpdated
		return `Updated ${DateTimeHelper.formatTimeFromDate(date)}`
	}

	ngOnInit() {}
	ngAfterViewInit() {
		scrollTo(0, 0)
	}

	setupAccessPermissions() {
		// this.accessHelper = new AccessHelper(this.coreSrvc.dbSrvc, 'RESOURCE')
	}
	canPerformAction(action) {
		return true
		// return this.accessHelper.canPerformAction(action, false)
	}

	toggleSectionHelp() {
		this.showMoreInfo = !this.showMoreInfo
		this.updateFixedHeader()
	}

	updateFixedHeader() {
		// Update fixed headers for any tables this section has
		setTimeout(() => {
			const table: any = $('#breakProfileTable').DataTable()
			table?.fixedHeader.adjust()
		}, 100)
	}

	public fetchAndReload() {
		this.breakProfileTable?.fetchAndReload()
	}

	createRecord() {
		this.breakProfileTable?.createRecord()
	}

	public openPrefsDialog() {
		this.coreSrvc.notifySrvc.notify('info', 'Not implemented', 'This feature is note yet implemented.', 3)
		return
		this.breakProfileTable?.openPrefsDialog()
	}
}
