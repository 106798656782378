<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<div *ngIf="!isConfigValid" class="update-alert">
		<div class="alert alert-warning">
			Your employee score configuration needs to be updated. Please review the settings below and click 'Save' when you are finished.
		</div>
	</div>

	<div class="slider-block">
		<div class="slider-label">
			<span>Late notifications to employee</span>
			<span class="inc-btns float-right">
				<a href="#" (click)="decrement('lateNotifyEmployee')">-</a> / <a href="#" (click)="increment('lateNotifyEmployee')">+</a>
			</span>
		</div>
		<div class="range-slider">
			<span class="range-slider__value">{{ scoreWeight.lateNotifyEmployee }}</span>
			<input
				[(ngModel)]="scoreWeight.lateNotifyEmployee"
				class="range-slider__range"
				type="range"
				value="100"
				min="0"
				max="100"
				(change)="isFormValid()"
			/>
		</div>

		<div class="slider-label">
			<span>Late notifications to supervisor</span>
			<span class="inc-btns float-right">
				<a href="#" (click)="decrement('lateNotifySupervisor')">-</a> / <a href="#" (click)="increment('lateNotifySupervisor')">+</a>
			</span>
		</div>
		<div class="range-slider">
			<span class="range-slider__value">{{ scoreWeight.lateNotifySupervisor }}</span>
			<input
				[(ngModel)]="scoreWeight.lateNotifySupervisor"
				class="range-slider__range"
				type="range"
				value="0"
				min="0"
				max="100"
				(change)="isFormValid()"
			/>
		</div>

		<div class="slider-label">
			<span>GPS requests completed</span>
			<span class="inc-btns float-right">
				<a href="#" (click)="decrement('gpsRequestsCompleted')">-</a> / <a href="#" (click)="increment('gpsRequestsCompleted')">+</a>
			</span>
		</div>
		<div class="range-slider">
			<span class="range-slider__value">{{ scoreWeight.gpsRequestsCompleted }}</span>
			<input
				[(ngModel)]="scoreWeight.gpsRequestsCompleted"
				class="range-slider__range"
				type="range"
				value="0"
				min="0"
				max="100"
				(change)="isFormValid()"
			/>
		</div>

		<div class="slider-label">
			<span>GPS response time</span>
			<span class="inc-btns float-right">
				<a href="#" (click)="decrement('gpsResponseOnTime')">-</a> / <a href="#" (click)="increment('gpsResponseOnTime')">+</a>
			</span>
		</div>
		<div class="range-slider">
			<span class="range-slider__value">{{ scoreWeight.gpsResponseOnTime }}</span>
			<input
				[(ngModel)]="scoreWeight.gpsResponseOnTime"
				class="range-slider__range"
				type="range"
				value="0"
				min="0"
				max="100"
				(change)="isFormValid()"
			/>
		</div>

		<div class="slider-label">
			<span>GPS distance from site</span>
			<span class="inc-btns float-right">
				<a href="#" (click)="decrement('gpsDistanceInBounds')">-</a> / <a href="#" (click)="increment('gpsDistanceInBounds')">+</a>
			</span>
		</div>
		<div class="range-slider">
			<span class="range-slider__value">{{ scoreWeight.gpsDistanceInBounds }}</span>
			<input
				[(ngModel)]="scoreWeight.gpsDistanceInBounds"
				class="range-slider__range"
				type="range"
				value="0"
				min="0"
				max="100"
				(change)="isFormValid()"
			/>
		</div>
	</div>

	<div class="weight-total-block">
		<span *ngIf="totalWeight < 100" class="weight-total" style="color: firebrick">{{ totalWeight }}</span>
		<span *ngIf="totalWeight === 100" class="weight-total" style="color: green">{{ totalWeight }}</span>
		<span *ngIf="totalWeight > 100" class="weight-total" style="color: firebrick">{{ totalWeight }}</span>
		<div class="float-right" style="display: inline-block; padding-top: 2px">Points Distributed</div>
	</div>

	<div class="score-action-block">
		<button class="btn btn-sm btn-secondary btn-block" (click)="resetScore()">Reset Score</button>
	</div>

	<div class="score-notes">
		Most customers will not need to configure these settings but if you wish to make changes then you have 100 points to distribute between the
		various behaviors that we track. To reset the scoring model, click the 'Reset' button and then choose 'Save'.
	</div>
</div>
