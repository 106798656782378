import { HttpClient } from '@angular/common/http'
import { environment } from '@env/environment'

import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { log } from '@app/helpers'

@Injectable({
	providedIn: 'root',
})
export class ConnectionService {
	private connectionMonitor: Observable<boolean>

	constructor(private httpClient: HttpClient) {
		log('Creating ConnectionService')

		this.connectionMonitor = new Observable((observer) => {
			window.addEventListener('offline', (e) => {
				observer.next(false)
			})
			window.addEventListener('online', (e) => {
				observer.next(true)
			})
		})
	}

	monitor(): Observable<boolean> {
		return this.connectionMonitor
	}

	getMyIp(): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			const endpoint = `https://${environment.apiEndpoint}/ClientCheck`
			log('endpoint', endpoint)
			const options = {
				responseType: 'text' as const,
			}
			this.httpClient.get(endpoint, options).subscribe((result) => {
				try {
					const parsedResponse = JSON.parse(result)
					const ip = parsedResponse.publicIPv4 as string
					resolve(ip)
				} catch (erro) {
					log('Got error with IP')
					resolve(null)
				}
			})
		})
	}
}
