import { Component, OnInit, ViewChild, ElementRef, Input, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Location } from '@angular/common'

import { BreakLogRecord, BreaktimeDataAccessRequest, TransactionLogRecord, TransactionDataAccessRequest, BreakType } from '@app/models'

import { EmployeesService, JobsService, TransactionService, LambdaService, CoreService } from '@app/services'

import { log, DateTimeHelper } from '@app/helpers'

import { Subscription } from 'rxjs'
import { SelectItem } from 'primeng/api'

import moment from 'moment-timezone'
import _ from 'lodash'

@Component({
    selector: 'app-break-edit',
    templateUrl: './break-edit.component.html',
    styleUrls: ['./break-edit.component.scss'],
    standalone: false
})
export class BreakEditComponent implements OnInit, OnDestroy {
	@Input() isDialog = false
	@Input() isInline = false
	@Input() transId: number = null
	@Output() durationUpdated: EventEmitter<any> = new EventEmitter()
	@Output() editingComplete: EventEmitter<boolean> = new EventEmitter()

	@ViewChild('addInput', { static: false }) addInput: ElementRef
	@ViewChild('editInput', { static: false }) editInput: ElementRef

	dataLoaded = false
	isUpdating = false
	isDeleting = false

	trans: TransactionLogRecord = null
	breaktimes: Array<BreakLogRecord> = []
	adminBreaks: Array<BreakLogRecord> = []
	userBreaks: Array<BreakLogRecord> = []
	addBreakTimeField = ''

	employeeName = ''
	jobName = ''
	initialDuration = null

	addInputValue: string
	selectedBreakTimeValue: string
	selectedBreakTime: BreakLogRecord
	selectedBreakForDelete: BreakLogRecord
	showAddBreakInput = false
	showEditBreakInput = false

	selectedBreakType: BreakType = 'UNPAID_BREAK'
	breakTypeOptions: Array<SelectItem> = [
		{ label: 'Unpaid Break', value: 'UNPAID_BREAK' },
		{ label: 'Paid Break', value: 'PAID_BREAK' },
	]

	dateLockOverride = { showDialog: false, allowOverride: false }
	lastOperation: 'INSERT' | 'UPDATE' | 'DELETE' = null

	showPaidBreakInfo = false

	private subs = new Subscription()
	// showUpdateBreakTimeDialog = false

	get isExported(): boolean {
		const trans = this.trans
		return trans ? !!trans.exported : false
	}

	get transDate(): string {
		const date = DateTimeHelper.stripUtcTag(this.trans.actual_start)
		const startMom = moment(date)
		if (startMom.isValid()) {
			return startMom.format('ddd MMM Do, YYYY @ h:mm a')
		}
		return ''
	}

	get transDateShort(): string {
		const date = DateTimeHelper.stripUtcTag(this.trans.actual_start)
		const startMom = moment(date)
		if (startMom.isValid()) {
			return startMom.format('ddd MMM Do @ h:mm a')
		}
		return ''
	}

	constructor(
		private cd: ChangeDetectorRef,
		private route: ActivatedRoute,
		private location: Location,
		private transSrvc: TransactionService,
		private lambdaSrvc: LambdaService,
		private coreSrvc: CoreService,
	) {
		this.subs.add(this.coreSrvc.dbSrvc.lambdaSrvc.dataAccessErrorEvent.subscribe((event) => this.dataAccessErrorEventHandler(event)))
		this.showPaidBreakInfo = this.coreSrvc.dbSrvc.settingSrvc.getCompany()?.break_type_prompt ?? false
	}

	ngOnInit() {
		log('Init transId', this.transId)
		if (this.isDialog) {
			log('Dialog Mode')
		} else {
			this.transId = parseInt(this.route.snapshot.params['transactionId'], 10) || null
		}

		const trans = this.transSrvc.getTransactionById(this.transId)
		this.trans = trans
		if (trans) {
			this.employeeName = trans.employee_name
			this.jobName = trans.job_description
			this.initialDuration = trans.break_time
			this.loadBreakTimes()
		}
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	private dataAccessErrorEventHandler(event: any) {
		// log('Error event caught in Transaction Detail Component', event)
		const errorType = event?.errorType
		const errorMsg = event?.errorMessage

		if (errorType === 'com.sst.ivr.lambda.exceptions.TransactionDateLockException') {
			this.dateLockOverride.showDialog = true
		}
	}

	loadBreakTimes() {
		const request = new BreaktimeDataAccessRequest(this.transId)
		log('Lambda Request', request)
		this.lambdaSrvc.dataAccess(request).then((result) => {
			log('Breaktime Results', result)
			const data = result.data
			const breaks = data.map((rec) => new BreakLogRecord(rec))
			const sortedBreaks = _.orderBy(breaks, ['actual_start'], ['asc'])
			this.breaktimes = sortedBreaks
			this.filterAdminSetBreaks()
			log('Breaktimes', this.breaktimes)
			if (this.isDialog) {
				this.cd.detectChanges()
				const mins = this.displayTotalTime('ANY')
				const duration = moment.duration(mins, 'minutes').toISOString()
				log('Duration', duration)
				this.durationUpdated.next({ transId: this.transId, duration: duration, initial: this.initialDuration })
			}
			this.dataLoaded = true
		})
	}

	filterAdminSetBreaks() {
		const actualStart = DateTimeHelper.stripUtcTag(this.trans.actual_start)
		this.userBreaks = []
		this.adminBreaks = []
		for (const breakTime of this.breaktimes) {
			if (breakTime.break_time_worked) {
				this.adminBreaks.push(breakTime)
			} else {
				this.userBreaks.push(breakTime)
			}
		}
	}

	addBreakTimeBtnClicked() {
		this.showEditBreakInput = false
		this.showAddBreakInput = true
		this.selectedBreakType = 'UNPAID_BREAK'
		setTimeout(() => {
			this.addInput.nativeElement.focus()
		}, 100)
	}

	addBreakTime(input) {
		log('addBreak Input', input)
		const newBreakInt = parseInt(input.value, 10)
		const request = new BreaktimeDataAccessRequest(this.transId)
		const actualStart = DateTimeHelper.stripUtcTag(this.trans.actual_start)

		if (this.dateLockOverride.allowOverride) {
			request['date_lock_override'] = true
			this.dateLockOverride.allowOverride = false
		}

		request.operation = 'insert'
		request['actual_start'] = actualStart
		request['employee_id'] = this.trans.employee_id.toString()
		request['break_type'] = this.selectedBreakType
		if (newBreakInt) {
			const startMom = moment(actualStart)
			const endMom = moment(actualStart)
			endMom.add(newBreakInt, 'minutes')
			request['actual_end'] = endMom.utc().toISOString()
			request['break_time'] = moment.duration(newBreakInt, 'minutes').toISOString()
			this.dataLoaded = false
			this.lastOperation = 'INSERT'
			this.lambdaSrvc
				.dataAccess(request)
				.then((result) => {
					log('Return form lambda add')
					// input.value = ''
					this.addInputValue = null
					this.showAddBreakInput = false
					this.loadBreakTimes()
				})
				.catch((error) => {
					log('addBreakTime Error')
				})
		}
	}

	cancelAddBreakTime() {
		this.showAddBreakInput = false
		this.addBreakTimeField = ''
	}

	editBreakTimeIconSelected(breakTime: BreakLogRecord) {
		this.selectedBreakTime = breakTime
		this.selectedBreakTimeValue = moment.duration(breakTime.break_time).asMinutes().toString()
		this.selectedBreakType = breakTime.break_type
		this.showAddBreakInput = false
		this.showEditBreakInput = true
		setTimeout(() => {
			this.editInput.nativeElement.select()
		}, 100)

		log('Input', this.editInput)
	}

	cancelEditBreakTime() {
		this.selectedBreakTimeValue = ''
		this.showEditBreakInput = false
	}

	updateBreakTime() {
		const newDurationInt = parseInt(this.selectedBreakTimeValue, 10)
		const actualStart = this.selectedBreakTime.actual_start
		const request = new BreaktimeDataAccessRequest(this.transId)
		request.operation = 'update'
		request['break_type'] = this.selectedBreakType

		if (this.dateLockOverride.allowOverride) {
			request['date_lock_override'] = true
			this.dateLockOverride.allowOverride = false
		}

		if (newDurationInt) {
			request['id'] = this.selectedBreakTime.id.toString()
			request['actual_start'] = actualStart
			// request['employee_id'] = this.selectedBreakTime.employee_id.toString();

			const endMom = moment(actualStart)
			endMom.add(newDurationInt, 'minutes')
			request['actual_end'] = endMom.utc().toISOString()
			request['break_time'] = moment.duration(newDurationInt, 'minutes').toISOString()

			log('Update Request', request)
			this.dataLoaded = false
			this.lastOperation = 'UPDATE'
			this.lambdaSrvc
				.dataAccess(request)
				.then((result) => {
					log('updatBreakTime Result', result)
					this.selectedBreakTimeValue = ''
					this.loadBreakTimes()
					this.showEditBreakInput = false
				})
				.catch((error) => {
					log('updateBreakTime Error')
				})
		}
	}

	deleteBtnClicked(breakTime: BreakLogRecord) {
		this.selectedBreakForDelete = breakTime
	}

	deleteBreakTime(breakTime: BreakLogRecord) {
		log('Delete Break', breakTime)
		this.selectedBreakTime = breakTime
		const request = new BreaktimeDataAccessRequest(this.transId)

		if (this.dateLockOverride.allowOverride) {
			request['date_lock_override'] = true
			this.dateLockOverride.allowOverride = false
		}

		request.operation = 'delete'
		request['id'] = breakTime.id.toString()
		this.dataLoaded = false
		this.lastOperation = 'DELETE'
		this.lambdaSrvc
			.dataAccess(request)
			.then((result) => {
				log('Delete Result', result)
				this.loadBreakTimes()
			})
			.catch((error) => {
				log('deleteBreakTime Error', error)
			})
	}

	displayTime(dateTime: string): string {
		const is12Hours = DateTimeHelper.format12Hour
		const date = DateTimeHelper.stripUtcTag(dateTime)
		const startMom = moment(date)
		if (startMom.isValid()) {
			const timezone = this.trans.timezone
			return is12Hours ? startMom.tz(timezone).format('h:mm a z') : startMom.tz(timezone).format('HH:mm z')
		}
		return ''
	}

	displayPaidTime(): string {
		return 'XX'
	}

	displayUnpaidTime(): string {
		return 'XX'
	}

	displayDuration(breakTime: BreakLogRecord): string {
		if (breakTime.actual_start && !breakTime.actual_end) return 'ongoing'

		const breakTimeString = breakTime.break_time
		const duration = moment.duration(breakTimeString).asMinutes()
		return duration + ' min'
	}

	displayTotalTime(type: 'ANY' | 'UNPAID_BREAK' | 'PAID_BREAK'): number {
		let time = 0
		this.breaktimes.forEach((bt) => {
			const breakTime = bt.break_time ?? 'PT0M'
			const shouldCount = type === 'ANY' || bt.break_type === type
			if (shouldCount) time += moment.duration(breakTime).asMinutes()
		})
		return time
	}

	doneBtnClicked() {
		this.editingComplete.emit(true)
		// const request = new TransactionDataAccessRequest()
		// request['id'] = this.transId
		// this.lambdaSrvc.dataAccess(request).then(result => {
		// 	log('Done Result', result)
		// 	const transArray = result.data as Array<Transaction>
		// 	const updatedTrans = transArray.shift()
		// 	if (updatedTrans) {
		// 		this.transSrvc.updateLocalTransaction(updatedTrans)
		// 		this.location.back()
		// 	}
		// })
	}

	public cancelDateLockOverride() {
		this.coreSrvc.notifySrvc.clear()
		this.dateLockOverride.allowOverride = false
		this.dateLockOverride.showDialog = false
		this.lastOperation = null
		this.loadBreakTimes()
		// this.showEditBreakInput = false
		// this.showAddBreakInput = false
	}

	public submitDateLockOverride() {
		this.coreSrvc.notifySrvc.clear()
		this.dateLockOverride.allowOverride = true
		this.dateLockOverride.showDialog = false

		switch (this.lastOperation) {
			case 'INSERT':
				const addValue = { value: this.addInputValue }
				log('Switch', addValue)
				this.addBreakTime(addValue)
				break
			case 'UPDATE':
				this.updateBreakTime()
				break
			case 'DELETE':
				const breakTime = this.selectedBreakTime
				this.deleteBreakTime(breakTime)
				break
		}
	}
}
