import { log } from '@app/helpers/logger'
import { Incident } from '@app/models/incident'
import { ReportGlobals } from '@app/models/report-user'
import { IncidentReportData } from '@app/models/report-incident'

import moment from 'moment-timezone'
import _ from 'lodash'
import { DatabaseService } from '@app/services'

export class IncidentHelper {
	static incidentStyles = `
		body { font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 1.2em; padding: 5px 15px; }
		pre {  font-family: "Lucida Console", Monaco, monospace; font-size: 1rem; }
		.opt-label { font-weight: bold; color: #135c94; }
		.section-block { margin-top: 25px; }
		.describe-block { margin-bottom: 15px; }
	`

	static makeBodyHtmlForReport(dbSrvc: DatabaseService, incident: Incident): string {
		const reportJson = incident.report_json
		if (!reportJson) {
			return null
		}

		let parsedReport = null
		try {
			parsedReport = JSON.parse(reportJson)
		} catch (err) {
			log(err)
		}

		// If report json could not parse then send back null
		if (!parsedReport) {
			return null
		}

		const metaData = parsedReport.metaData
		if (metaData.type !== 'INCIDENT') {
			return null
		}

		const reportData: IncidentReportData = parsedReport.reportData

		const transId = incident.transaction_log_id
		const trans = dbSrvc.tranSrvc.getTransactionById(transId)
		const tz = trans.timezone

		const dateCreated = moment.tz(incident.created, tz).format('ddd Mo Do, YYYY @ h:mm a z')

		// Incident Type
		const incidentDescription = reportData.incidentType.value
		const incidentType = reportData.incidentType.value
		const incidentTypeResolved =
			reportData.incidentType.value === 'OTHER' ? incidentDescription : IncidentHelper.getReportTypeForDescription(incidentType)
		const incidentTypeHtml = `<div><span class="opt-label">Incident Type:</span> ${incidentTypeResolved}</div>`

		// Incident Reporter
		const incidentReporter = reportData.incidentReporter
		const firstName = incidentReporter.firstName
		const lastName = incidentReporter.lastName
		const license = incidentReporter.license
		// const details = incidentReporter.details

		let incidentReporterInfo = firstName + ' ' + lastName
		incidentReporterInfo += license ? ` (#${license})` : ''
		const incidentReporterHtml = `<div><span class="opt-label">Incident Reported By:</span> ${incidentReporterInfo}</div>`

		// Describe Incident
		const describeIncident = reportData.describeIncident
		let describeIncidentHtml = ''

		for (const item in describeIncident) {
			if (describeIncident.hasOwnProperty(item)) {
				const label = _.startCase(item)
				describeIncidentHtml += `<div class="describe-block"><div class="opt-label">${label}:</div><div>${describeIncident[item]}</div></div>`
			}
		}

		const reportContainer = `
			<div class="container">
				<div style="margin-top:25px">
					<div class="float-right" style="padding-top:15px"><h5>${dateCreated}</h5></div>
					<h2>Incident Report</h2>
					<hr>
					<div class="section-block">${incidentTypeHtml}</div>
					<div class="section-block">${incidentReporterHtml}</div>
					<div class="section-block">${describeIncidentHtml}</div>
					<hr>
				</div>
			</div>
		`

		return `<div>${reportContainer}</div>`
	}

	static openMailWithReport(title: string, bodyHtml: string) {
		const headHtml = `
		<head>
			<title>${title}</title>
			<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css" integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk" crossorigin="anonymous">
    		<style>
				${IncidentHelper.incidentStyles}
    		</style>
		</head>`

		if (bodyHtml) {
			const html = `<html>${headHtml}<body>${bodyHtml}</body></html>`
			const mailText = 'mailto:abc@abc.com+?subject=files&body=' + html
			window.location.href = mailText
		}
	}

	static openReportPage(title: string, bodyHtml: string) {
		const headHtml = `
		<head>
			<title>${title}</title>
			<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css" integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk" crossorigin="anonymous">
			<script src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossorigin="anonymous"></script>
			<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js" integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo" crossorigin="anonymous"></script>
			<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.min.js" integrity="sha384-OgVRvuATP1z7JjHLkuOU7Xw704+h835Lr+6QL9UvYjZE3Ipu6Tp75j7Bh/kR0JKI" crossorigin="anonymous"></script>
    		<style>
				${IncidentHelper.incidentStyles}
    		</style>
		</head>`

		if (bodyHtml) {
			const html = `<html>${headHtml}<body>${bodyHtml}</body></html>`
			const win = window.open()
			win.document.write(html)
			win.document.close()
		}
	}

	static getReportTypeForDescription(type: string): string {
		const option = ReportGlobals.incidentTypeOptionsForSecurity.find((opt) => opt.type === type)
		return option ? option.name : 'Unknown Type'
	}
}
