import { Component, OnDestroy, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { BannerStatus, BannerViewManager, VacationRecord } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { Subject, Subscription } from 'rxjs'

import moment from 'moment-timezone'
import { log } from '@app/helpers'

@Component({
    selector: 'app-pending-time-off-banner',
    templateUrl: './pending-time-off-banner.component.html',
    styleUrls: ['./pending-time-off-banner.component.scss'],
    standalone: false
})
export class PendingTimeOffBannerComponent implements OnInit, OnDestroy {
	bvm: BannerViewManager
	status: BannerStatus

	subs = new Subscription()
	@Output() receivedUpdate = new Subject<boolean>()

	constructor(
		private router: Router,
		private coreSrvc: CoreService,
	) {
		this.bvm = this.coreSrvc.bannerSrvc.viewManager
		this.status = this.coreSrvc.bannerSrvc.viewManager.pendingTimeOff
		this.subs.add(this.coreSrvc.notifySrvc.databaseFinishedLoading.subscribe(() => this.updateUI()))

		this.subs.add(
			this.coreSrvc.dbSrvc.vacSrvc.vacationListUpdated.subscribe(() => {
				log('PendingTimeOffBannerComponent Got update')
				this.updateUI()
			}),
		)
	}

	get shouldShowBanner(): boolean {
		return this.status?.notificationCount > 0
	}

	private updateUI() {
		const pendingList = this.coreSrvc.dbSrvc.vacSrvc.getPendingVacations().filter((vac) => vac.getDerivedStatus() !== 'COMPLETED')
		const pendingCount = pendingList.length
		this.status.notificationCount = pendingCount

		const listItems = pendingList.map((pi) => new TimeOffWrapper(this.coreSrvc.dbSrvc, pi))

		let isWarn = false
		let isDanger = false
		let isCritical = false

		for (const item of listItems) {
			if (item.hoursUntilStart <= 72) isWarn = true
			if (item.hoursUntilStart <= 48) isDanger = true
			if (item.hoursUntilStart <= 24) isCritical = true
		}
		this.status.isWarn = isWarn
		this.status.isDanger = isDanger
		this.status.isCritical = isCritical

		this.coreSrvc.bannerSrvc.groupBannerNeedsUpdate.next(true)
	}

	public ngOnInit(): void {}
	public ngOnDestroy(): void {
		this.subs.unsubscribe()
	}
	public viewPendingTimeOff() {
		this.coreSrvc.bannerSrvc.bannerPanelShouldHide.next(true)
		this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.setTimeOffViewSubSection('PENDING')
		this.coreSrvc.dbSrvc.schedulerSrvc.toggleDayView('OFF')
		this.router.navigate(['/admin/scheduler'])
	}
}

class TimeOffWrapper {
	record: VacationRecord
	hoursUntilStart = null

	constructor(dbSrvc: DatabaseService, record: VacationRecord) {
		this.record = record
		const timezone = dbSrvc.settingSrvc.getCompany().timezone
		const startMom = moment.tz(record.start_date, timezone).startOf('day')

		this.hoursUntilStart = startMom.diff(moment(), 'hours')
		// log('Hours until timeoff start', this.hoursUntilStart)
	}
}
