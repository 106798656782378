import { Component, Input, OnInit } from '@angular/core'
import { log } from '@app/helpers'
import {
	DialogManager,
	EmployeeRecord,
	IScheduleOptionsDayOption,
	JobRecord,
	JobSiteRecord,
	OrganizationRecord,
	ReportsFilterInfoEvent,
	ScheduleOptions,
	UserRecord,
} from '@app/models'
import { DatabaseService } from '@app/services'
import _ from 'lodash'

@Component({
    selector: 'app-insight-report-filters-list',
    templateUrl: './insight-report-filters-list.component.html',
    styleUrls: ['./insight-report-filters-list.component.scss'],
    standalone: false
})
export class InsightReportFiltersListComponent implements OnInit {
	filterBlocks: Array<FilterBlockViewModel<any>> = []

	@Input() dialogManager: DialogManager

	constructor(private dbSrvc: DatabaseService) {}

	ngOnInit(): void {
		const filterEvent = this.dialogManager.dialogData as ReportsFilterInfoEvent
		const filterData = filterEvent.filter ? JSON.parse(filterEvent.filter) : null
		this.setupComponent(filterData)
	}

	private setupComponent(filterData: any) {
		log('Filter Data', filterData)
		const filters = []

		// Employee Supervisors
		const empSupBlock = new FilterBlockViewModel<UserRecord>(
			filterData.emp_supervisor_id,
			'filter-block-emp-sup',
			'Employee Supervisors',
			(id: number) => this.dbSrvc.settingSrvc.getUserForId(id),
			(rec: UserRecord) => rec.first_name + ' ' + rec.last_name,
		)
		filters.push(empSupBlock)

		// Job Site Supervisors
		const siteSupBlock = new FilterBlockViewModel<UserRecord>(
			filterData.jobsite_supervisor_id,
			'Job Site Supervisors',
			'filter-block-site-sup',
			(id: number) => this.dbSrvc.settingSrvc.getUserForId(id),
			(rec: UserRecord) => rec.first_name + ' ' + rec.last_name,
		)
		filters.push(siteSupBlock)

		// Employees
		const empBlock = new FilterBlockViewModel<EmployeeRecord>(
			filterData.emp_id,
			'Employees',
			'filter-block-emp',
			(id: number) => this.dbSrvc.empSrvc.getEmployeeById(id),
			(rec: EmployeeRecord) => rec.first + ' ' + rec.last,
		)
		filters.push(empBlock)

		// Employee Tags
		const empTagBlock = new FilterBlockViewModel<string>(
			filterData.employee_tags,
			'Employee Tags',
			'filter-block-emp-tags',
			(id: any) => id,
			(rec: string) => rec,
		)
		filters.push(empTagBlock)

		// Employee Departments
		const empDepartments = new FilterBlockViewModel<string>(
			filterData.employee_departments,
			'Employee Departments',
			'filter-block-emp-departments',
			(id: any) => id,
			(rec: string) => rec,
		)
		filters.push(empDepartments)

		// Job Sites
		const siteBlock = new FilterBlockViewModel<JobSiteRecord>(
			filterData.jobsite_id,
			'Job Sites',
			'filter-block-sites',
			(id: number) => this.dbSrvc.siteSrvc.getJobSiteById(id),
			(rec: JobSiteRecord) => rec.description,
		)
		filters.push(siteBlock)

		// Jobs
		const jobBlock = new FilterBlockViewModel<JobRecord>(
			filterData.job_id,
			'Jobs',
			'filter-block-jobs',
			(id: number) => this.dbSrvc.jobSrvc.getJobById(id),
			(rec: JobRecord) => rec.description,
		)
		filters.push(jobBlock)

		// Job Tags
		const jobTagsBlock = new FilterBlockViewModel<string>(
			filterData.job_tags,
			'Job Tags',
			'filter-block-job-tags',
			(id: any) => id,
			(rec: string) => rec,
		)
		filters.push(jobTagsBlock)

		// State / Province
		const districtBlock = new FilterBlockViewModel<string>(
			filterData.jobsite_districts,
			'State / Province',
			'filter-block-distric',
			(id: any) => id,
			(rec: string) => rec,
		)
		filters.push(districtBlock)

		// Clients
		const clientBlock = new FilterBlockViewModel<OrganizationRecord>(
			filterData.client_id,
			'Clients',
			'filter-block-clients',
			(id: number) => this.dbSrvc.orgSrvc.getOrganizationById(id),
			(rec: OrganizationRecord) => rec.name,
		)
		filters.push(clientBlock)

		// Vendors
		const vendorBlock = new FilterBlockViewModel<OrganizationRecord>(
			filterData.vendor_id,
			'Vendors',
			'filter-block-vendors',
			(id: number) => this.dbSrvc.orgSrvc.getOrganizationById(id),
			(rec: OrganizationRecord) => rec.name,
		)
		filters.push(vendorBlock)

		// Day of Week
		const dowBlock = new FilterBlockViewModel<IScheduleOptionsDayOption>(
			filterData.dow_iso_id,
			'Days of Week',
			'filter-block-dow',
			(id: number) => ScheduleOptions.dayOptions.find((dow) => dow.isoValue === id),
			(rec: IScheduleOptionsDayOption) => rec.name,
		)
		dowBlock.filters = ScheduleOptions.sortDaysByLongName(dowBlock.filters)
		filters.push(dowBlock)

		// Clean up
		this.filterBlocks = filters.filter((fb) => fb.hasFilters)
	}

	public collapseAll() {
		this.filterBlocks.forEach((b) => (b.isExpanded = false))
	}

	public expandAll() {
		this.filterBlocks.forEach((b) => (b.isExpanded = true))
	}
}

class FilterBlockViewModel<T> {
	isExpanded = true
	category: string = null
	blockClass = 'rep-filter-block'
	filters: Array<string> = []

	constructor(ids: Array<number>, name: string, blockClass: string, lookupFunction: (id: number) => T, resolver: (rec: T) => string) {
		if (ids) {
			this.category = name
			this.blockClass = blockClass
			const filters = ids
				.map((id) => lookupFunction(id))
				.filter((rec) => !!rec)
				.map((rec) => resolver(rec))
			this.filters = _.sortBy(filters)
		}
	}

	get hasFilters(): boolean {
		return this.filters.length > 0
	}
}

const example = {
	emp_id: [21615],
	job_id: [14263],
	client_id: [56],
	vendor_id: [58],
	dow_iso_id: [1, 2, 3, 4, 5, 6],
	jobsite_id: [11678],
	emp_supervisor_id: [1791],
	jobsite_supervisor_id: [1791],
}
