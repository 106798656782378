import { Component, OnInit } from '@angular/core'
import {
	CheckinAnnouncementRecord,
	AnnouncementDataAccessRequest,
	LanguageCode,
	AccessPermission,
	AccessPermissions,
	UserPermissions,
	CrudAction,
	EmployeeRecord,
	AuditActionEvent,
	AnnouncementsViewManager,
	JobSiteRecord,
} from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { AccessHelper } from '@app/helpers/access'
import { LanguageHelper, log } from '@app/helpers'

import moment from 'moment-timezone'
import _ from 'lodash'
import { Global } from '@app/models/global'

@Component({
    selector: 'app-announcements-checkin-list',
    templateUrl: './checkin-list.component.html',
    styleUrls: ['./checkin-list.component.scss'],
    standalone: false
})
export class AnnouncementsCheckinListComponent implements OnInit {
	accessHelper: AccessHelper
	permissions: UserPermissions

	isDataLoaded = false
	isShowingSectionHelp = false

	list: Array<CheckinAnnouncementViewModel> = []

	isDeletingRecord = false
	selectedRecord = { record: null, header: '', footer: '', showEditDialog: false, showDeleteDialog: false }

	CrudAction = CrudAction

	debounceSearch = _.debounce(this.performSearch, 250)
	searchFilter = new CheckinAnnouncementSearchFilter()

	constructor(private coreSrvc: CoreService) {
		this.setupAccessPermissions()
		this.fetchAndLoadData()
	}

	public performSearch(searchText: string) {
		const lcText = (searchText ?? '').toLowerCase()
		this.searchFilter.searchText = lcText
		for (const ann of this.list) {
			ann.applySearchFilter(this.searchFilter)
		}
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'announcement')
		this.permissions = this.accessHelper.getPermissionsFor('announcement')
	}

	canCreateRecord(): boolean {
		return this.canPerformAction(CrudAction.create, true)
	}
	canPerformAction(action: CrudAction, isMyRecord?: boolean): boolean {
		return this.accessHelper.canPerformAction(action, isMyRecord)
	}

	get viewManager(): AnnouncementsViewManager {
		return this.coreSrvc.dbSrvc.annSrvc.viewManager
	}

	get filteredList(): Array<CheckinAnnouncementViewModel> {
		return this.list.filter((rec) => rec.isVisible)
	}

	ngOnInit() {}

	fetchAndLoadData() {
		this.coreSrvc.dbSrvc.bulkRead(['announcements']).then((result) => {
			this.loadData()
		})
	}

	loadData() {
		const announceList = this.coreSrvc.dbSrvc.annSrvc.getCheckinAnnouncements()
		const viewModels = announceList.map((ann) => new CheckinAnnouncementViewModel(ann))
		// viewModels.forEach((vm) => vm.resolveTargets(this.coreSrvc.dbSrvc))
		this.list = viewModels
		this.isDataLoaded = true
	}

	toggleOption(rec: CheckinAnnouncementRecord, option: string) {
		if (!this.canPerformAction(CrudAction.update, false)) {
			this.notifyOperationNotAuthorized()
			return
		}
		if (!rec.enabled) {
			return
		}
		if (option === 'acknowledgement') {
			if (rec.always_play) {
				return
			}
		}
		rec[option] = !rec[option]
		this.updateRecord(rec, false)
	}

	setLanguage(event) {
		log('Setting Language', event)
		// this.displayLang = lang
	}

	// Formatters

	defaultDateFormatter(str: string) {
		const mom = moment(str)
		if (mom.isValid()) {
			return mom.format('MMM Do, YYYY')
		}
		return 'Invalid Date'
	}

	bodyTextFormatter(item: CheckinAnnouncementRecord) {
		const lang = 'body_' + this.viewManager.displayLang
		const translationText = item[lang]
		return translationText ? translationText : 'Translation pending, please refresh your browser.'
	}

	attachmentFormatter(item: CheckinAnnouncementRecord) {
		const count = item.fileList.length
		return count > 0 ? `${count} file attachment${count > 1 ? 's' : ''}` : ''
	}

	// Record Management

	createRecord() {
		if (!this.canPerformAction(CrudAction.create, true)) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.selectedRecord.record = null
		this.selectedRecord.header = 'New Announcement'
		this.selectedRecord.footer = 'Create new record'
		this.selectedRecord.showEditDialog = true
	}

	editRecord(rec: CheckinAnnouncementRecord) {
		if (!this.canPerformAction(CrudAction.update, true)) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.selectedRecord.record = rec
		this.selectedRecord.header = 'Edit Announcement'
		this.selectedRecord.footer = rec.name
		this.selectedRecord.showEditDialog = true
	}

	deleteRecord(rec: CheckinAnnouncementRecord) {
		if (!this.canPerformAction(CrudAction.delete, true)) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.selectedRecord.record = rec
		this.selectedRecord.showDeleteDialog = true
	}

	deleteConfirmed() {
		this.isDeletingRecord = true
		const record = this.selectedRecord.record
		if (record) {
			const recId = record.id
			const request = new AnnouncementDataAccessRequest({ id: recId, operation: 'delete' })
			log('Request', request)
			this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
				log('Delete Record Result', result)
				setTimeout(() => {
					this.selectedRecord.showDeleteDialog = false
					this.isDeletingRecord = false
					this.fetchAndLoadData()
				}, 1000)
			})
		}
	}

	auditRecord(ann: CheckinAnnouncementRecord) {
		const auditActionEvent = new AuditActionEvent('announcements', ann.id, ann.name)
		this.coreSrvc.eventSrvc.showAuditLog.next(auditActionEvent)
	}

	updateRecord(record: CheckinAnnouncementRecord, reload: boolean) {
		if (!this.canPerformAction(CrudAction.update, true)) {
			this.notifyOperationNotAuthorized()
			return
		}
		// log('Local Record', record)
		if (record.always_play) {
			record.acknowledgement = false
		}
		const request = new AnnouncementDataAccessRequest(record)
		// log('Lambda Request', request)
		request.operation = 'update'
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			// log('Lambda Result', result)
			if (reload) {
				this.loadData()
			}
		})
	}

	notifyOperationNotAuthorized() {
		this.coreSrvc.notifySrvc.default('operationNotAuthorized')
	}
}

class CheckinAnnouncementSearchFilter {
	searchText = null

	get isActive(): boolean {
		return !!this.searchText
	}
}

class CheckinAnnouncementViewModel {
	announcement: CheckinAnnouncementRecord

	targetedEmployeeCount = 0
	targetedEmployeeList: Array<EmployeeRecord> = []

	targetedJobSiteCount = 0
	targetedJobSiteList: Array<JobSiteRecord> = []

	show = {
		targetedEmployees: false,
		targetedJobsites: false,
	}

	searchFilterMatches = true
	searchFilterTextForModel = ''

	isEndDateCurrent = false
	isEndDatePast = false

	constructor(announcement: CheckinAnnouncementRecord) {
		this.announcement = announcement
		this.setupModel()
		this.resolveTargets(Global.coreSrvc.dbSrvc)
		this.setupSearchFilterString()
	}

	get isVisible(): boolean {
		return this.searchFilterMatches
	}

	private setupModel() {
		if (this.announcement.start_date && this.announcement.end_date) {
			this.isEndDateCurrent =
				moment(this.announcement.end_date, 'YYYY-MM-DD').isSameOrAfter(moment(), 'day') &&
				moment().isSameOrAfter(moment(this.announcement.start_date, 'YYYY-MM-DD'), 'day')
		}
		if (this.announcement.end_date) {
			this.isEndDatePast = moment(this.announcement.end_date, 'YYYY-MM-DD').isBefore(moment(), 'day')
		}
	}
	private resolveTargets(dbSrvc: DatabaseService) {
		const empList = this.announcement.filter_employee.map((id) => dbSrvc.empSrvc.getEmployeeById(id)).filter((emp) => !!emp)

		const sortedList = _.sortBy(empList, 'name')
		const activeEmps = sortedList.filter((emp) => emp.active)
		const inactiveEmps = sortedList.filter((emp) => !emp.active)

		this.targetedEmployeeCount = sortedList.length
		this.targetedEmployeeList = [...activeEmps, ...inactiveEmps]

		const jobList = this.announcement.filter_location.map((id) => dbSrvc.siteSrvc.getJobSiteById(id)).filter((site) => !!site)

		this.targetedJobSiteCount = jobList.length
		this.targetedJobSiteList = jobList
	}

	setupSearchFilterString() {
		const description = ''
		const message = this.announcement.body_en
		const employeeNames = this.targetedEmployeeList.map((rec) => rec.name).join(' / ')
		const jobSiteNames = this.targetedJobSiteList.map((rec) => rec.description).join(' / ')
		const filterString = description + ' / ' + message + ' / ' + employeeNames + ' / ' + jobSiteNames

		this.searchFilterTextForModel = (filterString ?? '').toLowerCase()
	}

	applySearchFilter(searchFilter: CheckinAnnouncementSearchFilter) {
		const searchText = searchFilter.searchText
		if (!searchText) {
			this.searchFilterMatches = true
			return
		}
		let matchFound = false
		if (this.searchFilterTextForModel.includes(searchText)) matchFound = true
		this.searchFilterMatches = matchFound
	}
}
