import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import {
	MailingAddress,
	UserReportType,
	TransactionMetaDataForReport,
	ToastNotification,
	ReportRendererConfig,
	UserReportHelper,
	Incident,
} from '@app/models'
import { CoreService, DatabaseService } from '@app/services'

import moment from 'moment-timezone'

@Component({
    selector: 'app-user-report-header',
    templateUrl: './user-report-header.component.html',
    styleUrls: ['./user-report-header.component.scss'],
    standalone: false
})
export class UserReportHeaderComponent implements OnInit {
	empPfpUrl: string
	reportIdentifier: string = null

	@Input() publicView = false
	@Input() logoUrl: string
	@Input() companyName: string
	@Input() reportType: UserReportType
	@Input() address: MailingAddress
	@Input() transactionMetaData: TransactionMetaDataForReport
	@Input() config: ReportRendererConfig
	@Output() printPageBtnClicked = new EventEmitter<boolean>()
	@Output() closeBtnClicked = new EventEmitter<boolean>()
	@Output() postToast = new EventEmitter<ToastNotification>()

	// DO NOT USE DBSRVC TO LOOKUP ANY DATA - ONLY USED FOR LINK COPIES
	constructor(private coreSrvc: CoreService) {}

	get showCloseBtn() {
		if (this.publicView) {
			if (window.opener || window.history.length === 1) {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}

	get formattedReportType(): string {
		return UserReportHelper.formatReportTypeLabel(this.reportType)
	}

	ngOnInit(): void {
		const incident = this.config.reportData as Incident
		this.empPfpUrl = this.config.empPfpUrl
		this.reportIdentifier = incident.reportIdentifier
	}

	printPage() {
		this.printPageBtnClicked.emit(true)
	}

	copyLink() {
		this.coreSrvc.dbSrvc.settingSrvc.copyToClipboard(this.config.publicLink)
		this.coreSrvc.notifySrvc.notify('success', 'Link Copied', 'The public link for this report has been copied to your clipboard.', 5)
	}

	closeReport(fromBtn: boolean) {
		if (this.publicView) {
			window.close()
		} else {
			this.closeBtnClicked.emit(fromBtn)
		}
	}
}
