import { Component, OnInit } from '@angular/core'
import { DateTimeHelper, log } from '@app/helpers'
import { DatabaseTableName, WIWSync } from '@app/models'
import { DatabaseService } from '@app/services'

class WhenIWorkIntegrationViewModel {
	empSyncDate = null
	empSyncErrorCount = 0
	showEmpTooltip = false

	posSyncDate = null
	posSyncErrorCount = 0
	showPosTooltip = false

	locSyncDate = null
	locSyncErrorCount = 0
	showLocTooltip = false

	schedSyncDate = null
	schedSyncErrorCount = 0
	showSchedTooltip = false

	constructor(wiwSync?: WIWSync) {
		if (wiwSync) {
			log('WIW Sync Record in VM', wiwSync)
			// Setup Employee Sync Info
			const empSyncDateString = wiwSync.employee_sync
			this.empSyncDate = empSyncDateString ? DateTimeHelper.mediumDateTimeFromDateString(empSyncDateString) : null
			this.empSyncErrorCount = wiwSync.employee_sync_errors

			const posSyncDateString = wiwSync.position_sync
			this.posSyncDate = posSyncDateString ? DateTimeHelper.mediumDateTimeFromDateString(posSyncDateString) : null
			this.posSyncErrorCount = wiwSync.position_sync_errors

			const locSyncDateString = wiwSync.location_sync
			this.locSyncDate = locSyncDateString ? DateTimeHelper.mediumDateTimeFromDateString(locSyncDateString) : null
			this.locSyncErrorCount = wiwSync.location_sync_errors

			const schedSyncDateString = wiwSync.schedule_sync
			this.schedSyncDate = schedSyncDateString ? DateTimeHelper.mediumDateTimeFromDateString(schedSyncDateString) : null
			this.schedSyncErrorCount = wiwSync.schedule_sync_errors
		}
	}
}

class WIWSyncIssue {
	message = ''

	constructor(data: any) {
		this.message = data.message
	}
}

@Component({
    selector: 'app-wheniworkintegration',
    templateUrl: './wiwintegration.component.html',
    styleUrls: ['./wiwintegration.component.scss'],
    standalone: false
})
export class WhenIWorkIntegrationComponent implements OnInit {
	isWorking = false
	isDataLoaded = false
	isIntegrated = false

	// syncIssues: Array<WIWSyncIssue> = []

	vm = new WhenIWorkIntegrationViewModel()
	wiwSync = new WIWSync()

	constructor(private dbSrvc: DatabaseService) {
		this.checkForIntegration()
		// this.checkForSyncIssues()
		this.loadData()
	}

	public ngOnInit() {}

	private checkForIntegration() {
		const company = this.dbSrvc.settingSrvc.getCompany()
		this.isIntegrated = !!company.wiw_id
	}

	// private checkForSyncIssues() {
	// 	const employeesMissingIdIssues = this.dbSrvc.empSrvc.getAllEmployees()
	// 		.filter(emp => !emp.external_id)
	// 		.map(emp => emp.name)
	// 		.map(name => `Employee ${name} is missing External ID`)
	// 		.map(msg => new WIWSyncIssue({ message: msg }))

	// 	this.syncIssues = [...employeesMissingIdIssues]
	// }

	private loadData() {
		log('Loading WIW Data')
		const tables: Array<DatabaseTableName> = ['wiw_sync']
		this.dbSrvc.bulkRead(tables).then((result) => {
			// log('Result', result)
			this.dbSrvc.wiwSrvc.logRecords()
			this.setupComponent()
			this.isDataLoaded = true
			this.isWorking = false
		})
	}

	private setupComponent() {
		if (!this.isIntegrated) {
			return
		}
		// Setup Sync Record
		const wiwSync = this.dbSrvc.wiwSrvc.getWIWSync()
		log('WIWSync', wiwSync)
		this.wiwSync = new WIWSync(wiwSync)
		this.vm = new WhenIWorkIntegrationViewModel(wiwSync)
		log('View Model', this.vm)
	}

	public importEmployeeData() {
		log('Import employee data')
	}

	public syncNow() {
		log('Sync now operation')
		this.isWorking = true
		const request = { operation: 'sync_now' }
		this.dbSrvc.lambdaSrvc.wiwSync(request).then((syncResult) => {
			log('WIW Sync Result', syncResult)
			this.loadData()
		})
	}

	public gotoWhenIWorkSite() {
		window.open('https://wheniwork.com/', '_blank')
	}
}
