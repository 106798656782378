import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { DataTablesHelper, DisplayHelper, GeneralTableHelper, TableActionFormatter, log } from '@app/helpers'
import {
	AuditActionEvent,
	C2CLogRecord,
	CommLogViewManager,
	ClickToCallGlobalConfig,
	ClickToCallRecord,
	DataAccessRequest,
	DialogManager,
	LocalPrefsData,
	LocalPrefsDialogData,
	LocalPrefsGroup,
	ComponentBridgeName,
	ContactSupportEvent,
} from '@app/models'
import { CoreService } from '@app/services'
import { environment } from '@env/environment'
import { CommLogC2CTableFormatter } from './comm-log-c2c-table.formatter'

import moment from 'moment-timezone'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'

enum AgentTableColumnIndex {
	id = 0,
	date,
	inOut,
	sourceType,
	from,
	to,
	message,
	details,
	company,
	status,
	actions,
}

@Component({
    selector: 'app-comm-log-agent-table',
    templateUrl: './comm-log-c2c-table.component.html',
    styleUrls: ['./comm-log-c2c-table.component.scss'],
    standalone: false
})
export class CommLogC2CTableComponent implements OnInit, OnDestroy, AfterViewInit {
	bridgeName: ComponentBridgeName = 'ngBridgeCallLogAgentTable'

	list: Array<C2CLogRecord> = []
	viewManager: CommLogViewManager

	playAudioAction = { recordId: null, audioFile: null as HTMLAudioElement, showDialog: false } // audioFile: HTMLAudioElement
	transcriptAction = {
		recordId: null,
		record: null as C2CLogRecord,
		timezone: 'UTC',
		audioFileUrl: null,
		imageFiles: [],
		header: 'Call Transcript',
		footer: '',
		content: '',
		showDialog: false,
	}
	auditAction = {
		recordId: null,
		resource: 'click_to_call_log',
		header: 'Audio File Access',
		footer: 'Audit history for audio file',
		showDialog: false,
	}

	private hasGlobalAccount = false

	private agentTable // : DataTables.DataTable;
	private defaultSortOrder = [[AgentTableColumnIndex.date, 'desc']]
	private tableFormatter: CommLogC2CTableFormatter

	public sectionPrefsDialogManager = new DialogManager('sectionPrefsDialog')

	private authLinkRegex = /token=[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}/gi
	private isInternalUser = false

	private subs = new Subscription()

	constructor(
		private cd: ChangeDetectorRef,
		private router: Router,
		private coreSrvc: CoreService,
	) {
		this.viewManager = this.coreSrvc.dbSrvc.commLogSrvc.viewManager
		this.tableFormatter = new CommLogC2CTableFormatter(this.coreSrvc.dbSrvc)
		this.hasGlobalAccount = this.coreSrvc.dbSrvc.settingSrvc.hasGlobalAccount()
		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()

		log('HAS GLOBAL', this.hasGlobalAccount)
		this.setupSubscriptions()
		this.setupLocalPrefsDialog()
	}

	public ngOnInit(): void {
		window[this.bridgeName] = this
		this.fetchAndReload()
	}

	public ngOnDestroy() {
		this.agentTable['fixedHeader'].disable()
		window[this.bridgeName] = null
		this.subs.unsubscribe()
	}

	public ngAfterViewInit() {
		this.initTable()

		$('#agentTable_filter input').attr('placeholder', ' filter')
		$('#agentTable_filter input').addClass('search-field-input')

		setTimeout(() => {
			$('#clear-search-icon').detach().appendTo('#agentTable_filter label')
		})
	}

	private setupSubscriptions() {
		this.subs.add(
			this.coreSrvc.callSrvc.c2cEventSubmitted.subscribe(() => {
				this.fetchAndReload()
			}),
		)
		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	private setupLocalPrefsDialog(): void {
		this.sectionPrefsDialogManager.headerLabel = 'Preferences'
		let columnVisibilityItems = LocalPrefsData.getCommLogCallTextColumnDisplayPrefs()
		if (!this.hasGlobalAccount) columnVisibilityItems = columnVisibilityItems.filter((item) => item.key !== 'commLogCallTextColCompanyVisible')
		const columnVisibilityGroup = new LocalPrefsGroup('Column Visibility', columnVisibilityItems)
		const dialogData = new LocalPrefsDialogData([columnVisibilityGroup])
		dialogData.localStorageKeyRemovalList = ['DataTables_agentTable']
		this.sectionPrefsDialogManager.dialogData = dialogData
	}

	private handleScreenSizeChanges() {
		if (this.agentTable) {
			this.agentTable.columns.adjust().responsive.recalc()
			this.agentTable.fixedHeader.adjust()
		}
	}

	public prefsDataSaved(): void {
		log('UpdatePrefsData')
		this.sectionPrefsDialogManager.isDialogVisible = false
		this.updateTable()
	}

	public openPrefsDialog(): void {
		this.sectionPrefsDialogManager.isDialogVisible = true
	}

	public clearSearch(): boolean {
		this.agentTable
			?.search('')
			.order([[AgentTableColumnIndex.date, 'desc']])
			.rows()
			.invalidate()
			.draw()
		this.coreSrvc.displaySrvc.enableAllTooltips()
		return false
	}

	private loadData() {
		this.list = this.coreSrvc.dbSrvc.commLogSrvc.getAgentRecords()
	}

	public updateTable() {
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadData()
			this.updateColumns()
		})
	}

	private updateColumns() {
		const inOut = this.coreSrvc.prefSrvc.data.commLogCallTextColInOutVisible
		const sourceType = this.coreSrvc.prefSrvc.data.commLogCallTextColTypeVisible
		const from = this.coreSrvc.prefSrvc.data.commLogCallTextColFromVisible
		const to = this.coreSrvc.prefSrvc.data.commLogCallTextColToVisible
		const message = this.coreSrvc.prefSrvc.data.commLogCallTextColMessageVisible
		const details = this.coreSrvc.prefSrvc.data.commLogCallTextColDetailsVisible
		const company = this.hasGlobalAccount && this.coreSrvc.prefSrvc.data.commLogCallTextColCompanyVisible
		const status = this.coreSrvc.prefSrvc.data.commLogCallTextColStatusVisible

		this.agentTable.clear()
		GeneralTableHelper.updateColumn(this.agentTable, AgentTableColumnIndex.inOut, inOut)
		GeneralTableHelper.updateColumn(this.agentTable, AgentTableColumnIndex.sourceType, sourceType)
		GeneralTableHelper.updateColumn(this.agentTable, AgentTableColumnIndex.from, from)
		GeneralTableHelper.updateColumn(this.agentTable, AgentTableColumnIndex.to, to)
		GeneralTableHelper.updateColumn(this.agentTable, AgentTableColumnIndex.message, message)
		GeneralTableHelper.updateColumn(this.agentTable, AgentTableColumnIndex.details, details)
		GeneralTableHelper.updateColumn(this.agentTable, AgentTableColumnIndex.company, company)
		GeneralTableHelper.updateColumn(this.agentTable, AgentTableColumnIndex.status, status)

		this.agentTable.rows.add(this.list)
		this.agentTable.draw(false)
	}

	public resetPage(reDraw: boolean) {
		this.agentTable.page(0)
		if (reDraw) {
			this.agentTable.draw(false)
		}
	}

	public fetchAndReload() {
		const options = this.makeRequestOptions()
		this.coreSrvc.dbSrvc.readTable('click_to_call_log', options).then((readSuccess) => {
			this.updateTable()
		})
	}

	private makeRequestOptions(): Object {
		const dayViewDate = this.viewManager.dayViewDate
		if (dayViewDate) {
			const dateStr = moment(dayViewDate).format('YYYY-MM-DD')
			const timezone = this.viewManager.timezone
			const start = moment.tz(dateStr, timezone).startOf('day').toISOString()
			const end = moment.tz(dateStr, timezone).endOf('day').toISOString()
			return { start_date: start, end_date: end }
		}
		return {}
	}

	private makeAudioFileUrl(id: number): string {
		const audioHostUrl = 'https://' + environment.audioDomain + '/'
		const fileId = this.coreSrvc.dbSrvc.commLogSrvc.getAgentRecordById(id).audio_file

		return fileId ? audioHostUrl + fileId : null
	}

	public playAudio(id: number) {
		const url = this.makeAudioFileUrl(id)
		this.audioFilePlayed(id)
		this.playAudioAction.audioFile = new Audio(url)
		this.playAudioAction.showDialog = true
		this.playAudioAction.audioFile.play().catch((err) => {
			alert('This audio file could not be played.')
			this.playAudioAction.showDialog = false
			this.cd.detectChanges()
		})
		this.playAudioAction.audioFile.onended = () => {
			this.playAudioAction.showDialog = false
			this.cd.detectChanges()
		}
	}

	private sendPlayAudioEvent(id: number) {
		const record = this.coreSrvc.dbSrvc.commLogSrvc.getAgentRecordById(id)
		if (record) {
			const data = { type: 'AUDIOACCESS', payload: { fileId: record.audio_file } }
			const dataJson = data ? JSON.stringify(data) : null
			const options = { resource: 'click_to_call_log', resource_id: id, data: dataJson }
			const request = new DataAccessRequest('none', 'track_event', options, null)
			this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
				log('sendPlayAudioEvent Result', result)
				this.fetchAndReload()
			})
		}
	}

	// Called after an audio is played from table icon or HTML audio player in transacript dialog
	public audioFilePlayed(id: number) {
		this.sendPlayAudioEvent(id)
	}

	public stopPlayingAudio() {
		log('Stop playing audio')
		this.playAudioAction.audioFile.pause()
	}

	public viewTranscript(id: number) {
		// alert(`Show transcript for record ${id}`)
		const rec = this.coreSrvc.dbSrvc.commLogSrvc.getAgentRecordById(id)
		const transcript = rec.transcript || ''
		this.transcriptAction.recordId = id
		this.transcriptAction.audioFileUrl = this.makeAudioFileUrl(id)
		this.transcriptAction.content = transcript
		this.transcriptAction.record = rec
		this.transcriptAction.imageFiles = rec.imageFiles
		this.transcriptAction.timezone = rec.timezone
		const date = this.tableFormatter.date(rec.created, this.viewManager.timezone)
		const time = this.tableFormatter.time(rec.created, this.viewManager.timezone)
		this.transcriptAction.header = `${date} @ ${time}`
		this.transcriptAction.footer = `${rec.caller_name} > ${rec.dest_name}`
		this.transcriptAction.showDialog = true
	}

	public formatTranscript(content: string) {
		let result = content || ''

		if (!this.isInternalUser && result.includes('auth?') && this.authLinkRegex.test(result)) {
			result = result.replace(this.authLinkRegex, 'token=[protected]')
		}

		return result
		// DEPRECATED - 20240126 - Just show everything
		// const text = content ?? ''
		// const regexp = /\[([^\][]*)]/
		// const matches = this.matchAll(regexp, text)
		// if (matches.length > 0) {
		// 	const msg = matches[0] ?? 'Message Missing'
		// 	const emps = matches[1] ?? 'Employee List Missing'
		// 	return `${msg[1]}\n\n${emps[1]}`
		// } else {
		// 	return text
		// }
	}

	public matchAll(pattern: RegExp, haystack: string) {
		const regex = new RegExp(pattern, 'g')
		const matches = []

		const match_result = haystack.match(regex)

		for (const index in match_result) {
			if (match_result[index]) {
				const item = match_result[index]
				matches[index] = item.match(new RegExp(pattern))
			}
		}
		return matches
	}

	public viewTimeEntry(id: number) {
		const rec = this.coreSrvc.dbSrvc.commLogSrvc.getAgentRecordById(id)
		const transId = rec.transaction_id
		const companyId = rec.tlog_company_id

		const isDesktop = this.coreSrvc.devDetect.isDesktop()
		const origin = this.coreSrvc.dbSrvc.settingSrvc.getOriginUrl()
		const path = `/redirect/record/${transId}/transactions/${companyId}`
		const transUrl = `${origin}/#${path}`

		this.coreSrvc.appSrvc.redirectBackLinkRoute = '/admin/comm-log'
		this.router.navigateByUrl(path)
	}

	public handleC2CEvent(id: number, column, action: 'CALL' | 'TEXT') {
		const record = this.coreSrvc.dbSrvc.commLogSrvc.getAgentRecordById(id)
		let name = ''
		let number = ''
		if (column === 'FROM') {
			name = record.caller_name || 'Unknown'
			number = record.caller_phone_e164
		}
		if (column === 'TO') {
			name = record.dest_name || 'Unknown'
			number = record.dest_phone_e164
		}
		if (name && number) {
			const c2cRecord = new ClickToCallRecord('UNSPECIFIED', name, null, number, null)
			const config = new ClickToCallGlobalConfig(c2cRecord, action, null, null)
			this.coreSrvc.eventSrvc.clickToCallGlobalEvent.next(config)
		}
		log('handleC2CEvent', id, column, action, record)
	}

	public auditRecord(recordId: number) {
		log('Audit', recordId)
		const record = this.coreSrvc.dbSrvc.commLogSrvc.getAgentRecordById(recordId)
		if (record.access_count === 0) {
			this.coreSrvc.notifySrvc.notify('info', 'No Events', 'No audio file access events have been recorded for this record.')
			return
		}
		const auditActionEvent = new AuditActionEvent('click_to_call_log', recordId, 'Audio File Access')
		auditActionEvent.trackType = 'AUDIOACCESS'
		this.coreSrvc.eventSrvc.showAuditLog.next(auditActionEvent)
	}

	/////////////////////
	// CONTACT SUPPORT //
	/////////////////////

	public contactSupport(id: number) {
		const event = new ContactSupportEvent('Calls / Texts', 'click_to_call_log', id)
		this.coreSrvc.eventSrvc.contactSupportEvent.next(event)
		// this.coreSrvc.dbSrvc.settingSrvc.emailSupportForGenericRecord('Calls / Texts', 'click_to_call_log', id)
	}

	public formatDetails(rec: C2CLogRecord) {
		const details = rec.last_status !== 'Call ended' ? rec.last_status || '' : ''
		return details.replace(/,/g, ', ')
	}

	private initTable() {
		this.coreSrvc.displaySrvc.startSectionLoader()
		const mythis = this
		this.agentTable = $('#agentTable').DataTable(<DataTables.Settings>{
			stateSave: false,
			responsive: true,
			processing: true,
			deferRender: true,
			paging: true,
			pageLength: 50,
			lengthChange: true,
			info: true,
			select: false,
			searching: true,
			fixedHeader: DataTablesHelper.fixedHeader,
			autoWidth: false,
			data: this.list,
			order: this.defaultSortOrder,
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{
					responsivePriority: 1,
					targets: [AgentTableColumnIndex.date],
				},
				{
					responsivePriority: 2,
					targets: [AgentTableColumnIndex.from, AgentTableColumnIndex.to],
				},
				{
					responsivePriority: 3,
					targets: [AgentTableColumnIndex.actions],
				},
				{
					responsivePriority: 4,
					targets: [AgentTableColumnIndex.message, AgentTableColumnIndex.status, AgentTableColumnIndex.details],
				},
				{
					// ID
					targets: AgentTableColumnIndex.id,
					visible: false,
					searchable: true,
					title: 'ID',
					data: null,
					render: (rec: C2CLogRecord, type, full, meta) => {
						return `${rec.id}`
					},
				},
				{
					// Date
					targets: AgentTableColumnIndex.date,
					visible: true,
					searchable: true,
					title: 'Date',
					data: null,
					render: (rec: C2CLogRecord, type, full, meta) => {
						const created = rec.created
						const date = this.tableFormatter.date(rec.created, this.viewManager.timezone)
						const time = this.tableFormatter.time(rec.created, this.viewManager.timezone)
						const result = `<span style="display: none">${created}</span>${date}<br>${time}`
						return `
						<div class="dtr-control-content">
							${result}
						</div>`
					},
				},
				{
					// In/Out
					targets: AgentTableColumnIndex.inOut,
					visible: this.coreSrvc.prefSrvc.data.commLogCallTextColInOutVisible,
					searchable: this.coreSrvc.prefSrvc.data.commLogCallTextColInOutVisible,
					title: 'In / Out',
					data: null,
					render: (rec: C2CLogRecord, type, full, meta) => {
						const direction = rec.direction === 'IN' ? 'Inbound' : 'Outbound'
						const tagStyle = direction === 'Inbound' ? 'backgroun-color: #8135a3' : 'background-color: #a38535'
						return `<div class="table-tag table-tag-c2c-details" style="${tagStyle}">${direction}</div>`
					},
				},
				{
					// Type
					targets: AgentTableColumnIndex.sourceType,
					visible: this.coreSrvc.prefSrvc.data.commLogCallTextColTypeVisible,
					searchable: this.coreSrvc.prefSrvc.data.commLogCallTextColTypeVisible,
					title: 'Type',
					data: null,
					render: (rec: C2CLogRecord, type, full, meta) => {
						return this.tableFormatter.sourceType(rec)
						// const actionType = this.tableFormatter.sourceType(rec)
						// const actionIcon = rec.call_source === 'VOICECHECKPOINT' ? 'far fa-user-check' : null
						// // const iconHtml = actionIcon ? `<i class="${actionIcon}" style="margin-left: 5px"></i>` : ''
						// const iconHtml = actionIcon ? ` / CP` : ''
						// return `<span>${actionType}${iconHtml}</span>`
					},
				},
				{
					// From
					targets: AgentTableColumnIndex.from,
					visible: this.coreSrvc.prefSrvc.data.commLogCallTextColFromVisible,
					searchable: this.coreSrvc.prefSrvc.data.commLogCallTextColFromVisible,
					title: 'From',
					data: null,
					render: (rec: C2CLogRecord, type, full, meta) => {
						if (rec.caller_phone_e164 === 'Blocked') {
							return '<div class="table-tag table-tag-c2c-blocked">Blocked Number</div>'
						}
						if (rec.caller_user_id === 1) {
							return `
							<div>${rec.caller_name}</div>
							<div class="tts-hl-2" style="font-weight: bold; font-size:small">Notified User</div>
							`
						}
						const c2cButtons =
							rec.caller_phone_e164 && rec.caller_phone_e164 !== '+18888888888'
								? GeneralTableHelper.makeC2CButtons(rec.id, 'FROM', this.bridgeName, 'handleC2CEvent')
								: ''
						return this.tableFormatter.callFrom(rec) + c2cButtons
					},
				},
				{
					// To
					targets: AgentTableColumnIndex.to,
					visible: this.coreSrvc.prefSrvc.data.commLogCallTextColToVisible,
					searchable: this.coreSrvc.prefSrvc.data.commLogCallTextColToVisible,
					title: 'To',
					data: null,
					render: (rec: C2CLogRecord, type, full, meta) => {
						const c2cButtons =
							rec.dest_phone_e164 && rec.dest_phone_e164 !== '+18888888888'
								? GeneralTableHelper.makeC2CButtons(rec.id, 'TO', this.bridgeName, 'handleC2CEvent')
								: ''
						return this.tableFormatter.callTo(rec) + c2cButtons
					},
				},
				{
					// Message
					targets: AgentTableColumnIndex.message,
					visible: this.coreSrvc.prefSrvc.data.commLogCallTextColMessageVisible,
					searchable: this.coreSrvc.prefSrvc.data.commLogCallTextColMessageVisible,
					title: 'Message',
					data: null,
					render: (rec: C2CLogRecord, type, full, meta) => {
						let message = rec.transcript || ''

						if (!this.isInternalUser && message.includes('auth?') && this.authLinkRegex.test(message)) {
							message = message.replace(this.authLinkRegex, 'token=[protected]')
						}

						if (!message && rec.imageFiles.length > 0) {
							message = 'This message includes images'
						}

						const imageHtml =
							rec.imageFiles.length > 0
								? `
							<span class="link-text" onclick="${this.bridgeName}.viewTranscript(${rec.id})">View ${rec.imageFiles.length} attached image${rec.imageFiles.length > 1 ? 's' : ''}</span>
						`
								: ''

						const messageHtml = message ? `<div class="trans-note-block hide-scrollbars">${message}</div>${imageHtml}` : ''
						return messageHtml
					},
				},
				{
					// Details
					targets: AgentTableColumnIndex.details,
					visible: this.coreSrvc.prefSrvc.data.commLogCallTextColDetailsVisible,
					searchable: this.coreSrvc.prefSrvc.data.commLogCallTextColDetailsVisible,
					title: 'Details',
					data: null,
					render: (rec: C2CLogRecord, type, full, meta) => {
						const details = rec.last_status !== 'Call ended' ? rec.last_status || '' : ''
						const formattedDetails = details.replace(/,/g, ', ')
						const htmlDetails = this.tableFormatter.htmlDetails(formattedDetails)
						const durationInSec = rec.duration ? Math.ceil(moment.duration(rec.duration).asSeconds()) : null
						const durationStr = durationInSec
							? `<div><span class="tts-hl-2" style="font-weight:600">Duration:</span> ${durationInSec} sec</div>`
							: ''
						return `
							<div class="cell-text-break" style="max-width:320px">${htmlDetails}</div>
							${durationStr}
						`
					},
				},
				{
					// Company
					targets: AgentTableColumnIndex.company,
					visible: this.hasGlobalAccount && this.coreSrvc.prefSrvc.data.commLogCallTextColCompanyVisible,
					searchable: this.hasGlobalAccount && this.coreSrvc.prefSrvc.data.commLogCallTextColCompanyVisible,
					title: 'Company',
					data: null,
					render: (rec: C2CLogRecord, type, full, meta) => {
						return rec.company_name || ''
					},
				},
				{
					// Status
					targets: AgentTableColumnIndex.status,
					visible: this.coreSrvc.prefSrvc.data.commLogCallTextColStatusVisible,
					searchable: this.coreSrvc.prefSrvc.data.commLogCallTextColStatusVisible,
					title: 'Status',
					data: null,
					render: (rec: C2CLogRecord, type, full, meta) => {
						return this.tableFormatter.status(rec, this.bridgeName)
					},
				},
				{
					// Actions
					targets: AgentTableColumnIndex.actions,
					visible: this.coreSrvc.prefSrvc.data.commLogCallTextColInOutVisible,
					searchable: this.coreSrvc.prefSrvc.data.commLogCallTextColInOutVisible,
					title: 'Actions',
					data: null,
					render: (rec: C2CLogRecord, type, full, meta) => {
						// const canAccessAudit = this.canAccessAuditLog
						const audioLink = this.tableFormatter.audioIcon(rec, this.bridgeName)
						const transcriptLink = this.tableFormatter.viewTranscript(rec, this.bridgeName)
						// const imagesLink = this.tableFormatter.imagesIcon(rec, this.bridgeTableName)

						const hasAuditLog = rec.access_count > 0
						const canAccessAudit = true // this.canAccessAuditLog
						const auditLink = TableActionFormatter.auditLink(this.bridgeName, 'auditRecord', rec.id, canAccessAudit, hasAuditLog)

						const supportLink = TableActionFormatter.supportLink(this.bridgeName, 'contactSupport', rec.id, 0)

						// const auditLink = hasAuditLog
						// 	? TableActionFormatter.auditLinkGlobal('click_to_call_log', rec.id, 'Audio File Access', true, false)
						// 	: ''

						return `<span class="act-ico-wrap">${audioLink}${transcriptLink}${auditLink}${supportLink}</span>`
					},
				},
			],
			// 'createdRow': (row, data, dataIndex) => {
			// 	const enabled = this.checkEnabledStatus(data as Vacation)
			// 	if (!enabled) {
			// 		$(row).addClass('vac-disabled')
			// 	}
			// }
			drawCallback: (settings) => {
				this.agentTable?.columns.adjust().responsive.recalc()
				this.coreSrvc.displaySrvc.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
			},
		})
		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter('agentTable', this.agentTable)
	}
}

// {
// 	"table": "audit_log",
// 	"operation": null,
// 	"resource": "click_to_call_log",
// 	"resource_id": "1220",
// 	"current_company_id": 1433,
// 	"cognito_phone_e164": ""
// }
