import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import {
	AnnouncementsViewManager,
	AuditActionEvent,
	DataAccessRequest,
	DialogManager,
	EmployeeRecord,
	LanguageCode,
	ScheduledAnnouncement,
	ScheduledAnnouncementEmployeeInfoCard,
	ScheduledAnnouncementRecord,
	DatabaseTableName,
} from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { DateTimeHelper, LanguageHelper, RecordBuilder, log } from '@app/helpers'
import moment from 'moment-timezone'
import _ from 'lodash'

@Component({
    selector: 'app-announcements-scheduled-list',
    templateUrl: './scheduled-list.component.html',
    styleUrls: ['./scheduled-list.component.scss'],
    standalone: false
})
export class AnnouncementsScheduledListComponent implements OnInit {
	dialogManager = new DialogManager('announcementsScheduledLocalEditModal')

	isDataLoaded = false
	list: Array<ScheduledAnnouncementViewModel> = []
	selectedRecord = { record: new ScheduledAnnouncement() }
	deleteAction = {
		tableName: 'announcements_scheduled' as DatabaseTableName,
		recordId: null,
		recordLabel: null,
		showDeleteRecordDialog: false,
	}

	previewDialogManager = new DialogManager('previewDialog')
	sendConfirmation = {
		isLoadingPreview: false,
		previewList: [],
		excludeList: [],
		message: '',
		vm: null,
	}

	debounceSearch = _.debounce(this.performSearch, 250)
	searchFilter = new ScheduledAnnouncementSearchFilter()

	constructor(
		private cd: ChangeDetectorRef,
		private coreSrvc: CoreService,
	) {
		this.fetchAndLoadData()
	}

	get viewManager(): AnnouncementsViewManager {
		return this.coreSrvc.dbSrvc.annSrvc.viewManager
	}

	get filteredList(): Array<ScheduledAnnouncementViewModel> {
		return this.list.filter((rec) => rec.isVisible)
	}

	public ngOnInit(): void {
		this.setupPreviewDialogManager()
	}

	private setupPreviewDialogManager() {
		this.previewDialogManager.headerLabel = 'Preview Recipients'
		this.previewDialogManager.submitBtnLabel = 'Send'
		this.previewDialogManager.cancelBtnAction = () => this.previewDialogCancelled()
		this.previewDialogManager.submitBtnAction = () => this.sendNow()
		this.previewDialogManager.canSubmit = () => this.sendConfirmation.previewList.length > 0
	}

	public loadData() {
		const unsortedList = this.coreSrvc.dbSrvc.annSrvc.getScheduledAnnouncements()
		const sortedList = this.sortAnnouncements(unsortedList)

		this.list = sortedList.map((r) => new ScheduledAnnouncement(r)).map((ann) => new ScheduledAnnouncementViewModel(this.coreSrvc.dbSrvc, ann))
		// log('Announcement List', this.list)
	}

	private sortAnnouncements(list: Array<ScheduledAnnouncementRecord>): Array<ScheduledAnnouncementRecord> {
		return _.orderBy(list, 'sortDate', ['desc'])

		// const noLastSent = list.filter((rec) => !rec.last_sent)
		// const noLastSorted = _.orderBy(noLastSent, 'id', ['desc'])

		// const noLastSentIsScheduled = noLastSorted.filter((rec) => !!rec.send_date)
		// const noLastSentIsNotScheduled = noLastSorted.filter((rec) => !rec.send_date)

		// const hasLastSent = list.filter((rec) => !!rec.last_sent)
		// const hasLastSentSorted = _.orderBy(hasLastSent, 'last_sent', ['desc'])
		// return [...noLastSentIsScheduled, ...hasLastSentSorted, ...noLastSentIsNotScheduled]
	}

	public fetchAndLoadData() {
		log('Fetch and Load Data')
		this.coreSrvc.dbSrvc.readTable('announcements_scheduled').then((readResult) => {
			this.loadData()
			this.isDataLoaded = true
		})
	}

	public createRecord() {
		log('Create Record')
		this.selectedRecord.record = null
		this.dialogManager.headerLabel = 'Add Announcement'
		this.dialogManager.isDialogVisible = true
	}

	public recordSaved() {
		log('Schedule List Record Saved')
		this.fetchAndLoadData()
		// this.cd.detectChanges()
	}

	public editAnnouncement(vm: ScheduledAnnouncementViewModel) {
		log('edit announcement', vm)
		this.selectedRecord.record = vm.announcement
		this.dialogManager.headerLabel = 'Edit Announcement'
		this.dialogManager.isDialogVisible = true
	}

	public deleteAnnouncement(vm: ScheduledAnnouncementViewModel) {
		log('edit announcement', vm)
		this.deleteAction.recordId = vm.announcement.id
		this.deleteAction.recordLabel = `Selected ${vm.announcement.subject}`
		this.deleteAction.showDeleteRecordDialog = true
	}

	public deleteActionComplete() {
		log('Delete Action Complete')
		this.loadData()
		this.deleteAction.showDeleteRecordDialog = false
	}

	auditRecord(vm: ScheduledAnnouncementViewModel) {
		const auditActionEvent = new AuditActionEvent('announcements_scheduled', vm.announcement.id, vm.lastSent)
		this.coreSrvc.eventSrvc.showAuditLog.next(auditActionEvent)
	}

	public bodyTextFormatter(vm: ScheduledAnnouncementViewModel) {
		const lang = 'body_' + this.viewManager.displayLang
		const translationText = vm.announcement[lang]
		return translationText ? translationText : 'Translation pending, please refresh your browser.'
	}

	public showSendConfirmation(vm: ScheduledAnnouncementViewModel) {
		this.sendConfirmation.vm = vm
		this.sendConfirmation.excludeList = []
		this.previewDialogManager.isDialogVisible = true
		const record = vm.announcement.clone().cleanUpFilterData().buildUpdateRecord()
		const request = new DataAccessRequest('none', 'announcement_preview', null, record)
		this.sendConfirmation.isLoadingPreview = true
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			const data = result.data
			const records = RecordBuilder.buildRecords(data)
			const sortedRecords = _.orderBy(records, ['name'], ['asc'])
				.map((rec) => new EmployeeRecord(rec))
				.map((emp) => new ScheduledAnnouncementEmployeeInfoCard(emp))
			this.sendConfirmation.previewList = sortedRecords
			this.sendConfirmation.isLoadingPreview = false
		})
	}

	public previewDialogCancelled() {
		log('Preview Dialog Cancelled')
		this.previewDialogManager.isDialogVisible = false
		this.sendConfirmation.isLoadingPreview = false
		this.sendConfirmation.previewList = []
		this.sendConfirmation.excludeList = []
		this.sendConfirmation.message = ''
	}

	public removeCard(card: ScheduledAnnouncementEmployeeInfoCard) {
		this.sendConfirmation.excludeList.push(card.employee.id)
		this.sendConfirmation.previewList = this.sendConfirmation.previewList.filter((c) => c.employee.id !== card.employee.id)
	}

	public sendNow() {
		const vm: ScheduledAnnouncementViewModel = this.sendConfirmation.vm
		const announcementId = `${vm.announcement.id}`
		const request = {
			table: 'bogus',
			operation: 'scheduled_announcement_send_now',
			id: announcementId,
			confirm_send: true,
			exclude_emp_ids: this.sendConfirmation.excludeList.join(','),
		}
		log('Sending announcement', request, vm)

		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((lambdaResult) => {
			this.previewDialogManager.isDialogVisible = false
			log('Send Now Result', lambdaResult)
			// this.coreSrvc.notifySrvc.notify('success', 'Sending Announcement', 'Your announcement is now being sent.', 3)
			const targetList = lambdaResult?.data?.targetList
			if (targetList) {
				const targetCount = targetList.length
				if (targetList.length > 0) {
					this.coreSrvc.notifySrvc.notify(
						'success',
						'Sending Announcement',
						`Your announcement is now being sent to ${targetCount} employee${targetCount > 1 ? 's' : ''}.`,
						3,
					)
				} else {
					this.coreSrvc.notifySrvc.notify('success', 'Sending Announcement', 'No employees were targeted for this announcement.', 3)
				}
			}
		})
	}

	public performSearch(searchText: string) {
		const lcText = (searchText ?? '').toLowerCase()
		this.searchFilter.searchText = lcText
		for (const ann of this.list) {
			ann.applySearchFilter(this.searchFilter)
		}
		log('performSearch', searchText)
	}
}

class ScheduledAnnouncementSearchFilter {
	searchText = null

	get isActive(): boolean {
		return !!this.searchText
	}
}

class ScheduledAnnouncementViewModel {
	dbSrvc: DatabaseService
	announcement: ScheduledAnnouncement

	targetedEmployeeCount = 0
	targetedEmployeeLabel = ''
	targetedEmployeeList: Array<EmployeeRecord> = []

	targetedJobSiteCount = 0
	targetedJobSiteList: string

	targetedDepartmentCount = 0
	targetedDepartmentList: Array<string> = []

	targetedTagCount = 0
	targetedTagList: Array<string> = []

	format12Hr = DateTimeHelper.format12Hour

	show = {
		targetedEmployees: false,
		targetedJobsites: false,
		targetedDepartments: false,
		targetedTags: false,
	}

	searchFilterMatches = true
	searchFilterTextForModel = ''

	constructor(dbSrvc: DatabaseService, announcement: ScheduledAnnouncement) {
		this.dbSrvc = dbSrvc
		this.announcement = announcement
		this.setupTargetedEmployeeList()
		this.setupTargetedDepartmentList()
		this.setupTargetedTagList()
		this.setupSearchFilterString()
	}

	get isVisible(): boolean {
		return this.searchFilterMatches
	}

	get scheduledOn(): string {
		const sendDate = this.announcement.sendDate
		if (sendDate) {
			const format = DateTimeHelper.format12Hour ? 'MMM Do, YYYY [at] h:mm a' : 'MMM Do, YYYY [at] HH:mm'
			return 'Send on ' + moment(sendDate).format(format)
		} else {
			return 'Not Scheduled'
		}
	}

	get lastSent(): string {
		const sendDate = this.announcement.lastSent
		if (sendDate) {
			const format = DateTimeHelper.format12Hour ? 'MMM Do, YYYY [at] h:mm a' : 'MMM Do, YYYY [at] HH:mm'
			return 'Last Sent on ' + moment(sendDate).format(format)
		} else {
			return 'Not yet sent'
		}
	}

	setupTargetedEmployeeList() {
		if (this.announcement.type === 'ALLEMPLOYEES') {
			this.targetedEmployeeLabel = 'All Employees'
			return
		}
		if (this.announcement.type === 'ALLACTIVEEMPLOYEES') {
			this.targetedEmployeeLabel = 'All Active Employees'
			return
		}
		const filter = this.announcement.filter
		if (filter) {
			const list = filter.emp_id.map((id) => this.dbSrvc.empSrvc.getEmployeeById(id)).filter((emp) => emp !== null)

			const sortedList = _.sortBy(list, 'name')
			const activeEmps = sortedList.filter((emp) => emp.active)
			const inactiveEmps = sortedList.filter((emp) => !emp.active)

			const targetedEmployeeList = [...activeEmps, ...inactiveEmps]
			const filteredTargetList = targetedEmployeeList.filter((emp) => emp && emp.status !== 'RESIGNED' && emp.status !== 'TERMINATED')

			this.targetedEmployeeList = filteredTargetList
			this.targetedEmployeeCount = filteredTargetList.length
		}
	}

	setupTargetedJobSiteList() {
		const filter = this.announcement.filter
		if (filter) {
			const siteNames = filter.jobsite_id
				.map((id) => this.dbSrvc.siteSrvc.getJobSiteById(id))
				.filter((js) => js !== null)
				.map((site) => site.description)
			this.targetedJobSiteCount = siteNames.length
			const siteList = siteNames.join(' / ')
			this.targetedJobSiteList = siteList
		}
	}

	setupTargetedDepartmentList() {
		const filter = this.announcement.filter
		if (filter) {
			const departments = filter.departments
			this.targetedDepartmentCount = departments.length
			this.targetedDepartmentList = departments
		}
	}

	setupTargetedTagList() {
		const filter = this.announcement.filter
		if (filter) {
			const tags = filter.employee_tags
			this.targetedTagCount = tags.length
			this.targetedTagList = tags
		}
	}

	setupSearchFilterString() {
		const description = '' // this.announcement.subject // Remove subject from search string
		const message = this.announcement.body_en
		const employeeNames = this.targetedEmployeeList.map((rec) => rec.name).join(' / ')
		const departments = this.targetedDepartmentList.join(' / ')
		const sortDate = moment(this.announcement.originalRecord.sortDate).format('YYYY-MM-DD')
		const filterString = description + ' / ' + message + ' / ' + employeeNames + ' / ' + departments + ' / ' + sortDate

		this.searchFilterTextForModel = (filterString ?? '').toLowerCase()
	}

	applySearchFilter(searchFilter: ScheduledAnnouncementSearchFilter) {
		const searchText = searchFilter.searchText
		if (!searchText) {
			this.searchFilterMatches = true
			return
		}
		let matchFound = false
		if (this.searchFilterTextForModel.includes(searchText)) matchFound = true
		this.searchFilterMatches = matchFound
	}
}
