import { Component, Input, ViewChild } from '@angular/core'
import { Helper } from '@app/helpers'
import { GenericPhoneListManager } from '@app/models/phone-list'
import { CountryCode } from 'libphonenumber-js'

@Component({
    selector: 'app-generic-phone-list',
    templateUrl: './generic-phone-list.component.html',
    styleUrls: ['./generic-phone-list.component.scss'],
    standalone: false
})
export class GenericPhoneListComponent {
	@Input() manager: GenericPhoneListManager

	constructor() {}
}
