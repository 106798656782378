import { DateTimeHelper } from '@app/helpers/datetime'

export interface HasPayRate {
	pay_rate: number
}

export type PayRateViewTabState = 'EMPLOYEE' | 'CLIENT' | 'VENDOR'

export class PayRateViewManager {
	version = 1
	currentView: PayRateViewTabState = 'EMPLOYEE'
}

// PayRateSource
// DEFAULT = From employee/client/vendor record
// JOB = From link table
// JOB_DEFAULT = From job record
// TIME_ENTRY = From transaction record

export type PayRateSource = 'DEFAULT' | 'JOB' | 'JOB_DEFAULT' | 'TIME_ENTRY'
export type PayRateSourceType = 'EMPLOYEE' | 'CLIENT' | 'VENDOR'

export class EmpJobPayRateRecord {
	id: number
	company_id: number
	employee_id: number
	job_id: number
	adp_rate_code_id: number
	pay_rate: number
	supervisor_ids: Array<number> = []
	created: string
	updated: string

	constructor(record?: EmpJobPayRateRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
		}
	}

	clone(): EmpJobPayRateRecord {
		return new EmpJobPayRateRecord(this)
	}
}

export type OrgJobPayRateRecordType = 'CLIENT' | 'VENDOR'

export class OrgJobPayRateRecord {
	id: number
	type: OrgJobPayRateRecordType
	company_id: number
	job_id: number
	vendor_client_id: number
	pay_rate: number
	supervisor_ids: Array<number> = []
	created: string
	updated: string

	constructor(record?: OrgJobPayRateRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
		}
	}

	clone(): OrgJobPayRateRecord {
		return new OrgJobPayRateRecord(this)
	}
}
