import { AfterContentInit, AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'

import { CompanyRecord, CrudAction, DialogManager, SecureFileDialogData } from '@app/models'
import { CoreService } from '@app/services'

import { TrackCodeLabel } from '@app/models/track'
import { DateTimeHelper, LanguageHelper, log } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'

import { JobSiteMergeMigrationComponent } from './job-site-merge-migration/job-site-merge-migration.component'
import { environment } from '@env/environment'

import moment from 'moment-timezone'

class SystemLanguagePrompt {
	name = ''
	enabled = false

	constructor(code: string, enabled: boolean) {
		this.name = LanguageHelper.getLanguageForCode(code)
		this.enabled = enabled
	}
}
@Component({
	selector: 'app-settings-company-info',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss'],
	standalone: false,
})
export class SettingsComponent implements OnInit, AfterViewInit, AfterContentInit {
	environment = environment
	CrudAction = CrudAction
	accessHelper: AccessHelper

	isInternalUser = false
	isAnalyticsEnabled = false
	isOnboardingEnabled = false

	company: CompanyRecord
	timezoneShort = ''
	userName

	adminPrefsDialog = { group: 'company', header: '', footer: '', show: false }
	empPrefsDialog = { group: 'company', header: '', footer: '', show: false }
	employeeScoreDialog = { header: '', footer: '', show: false }
	companyEditDialog = { section: 'GENERAL', header: '', footer: '', show: false }
	callBlockingDialogManager = new DialogManager('callBlocking')

	adpProfilesDialogManager = new DialogManager('adpProfilesDialog')
	adpPhoneNumbersDialogManager = new DialogManager('adpPhoneNumbersDialog')
	employeeOnboardingDialogManager = new DialogManager('employeeOnboardingDialog')
	employeeChecklistDialogManager = new DialogManager('employeeChecklistDialog')
	secureFilesDialogManager = new DialogManager('secureFilesDialog')
	callCenterDialogManager = new DialogManager('callCenter')
	workorderDialogManager = new DialogManager('workOrder')
	healthCenterDialogManager = new DialogManager('healthCenter')

	showWorkorderConfigButton: boolean = false
	showCallCenterConfigButton: boolean = false

	permissions = {
		configureAdpProfiles: true,
		configureAdpPhoneNumbers: true,
		configureCompanyLogo: true,
		configureAccountDefaults: true,
		configurePortalPrefs: true,
		configureOnboarding: true,
		configureEmpScore: true,
		configureEmpApp: true,
		configureCallBlocking: true,
		configureChecklist: true,
		configureSecureFiles: true,
		configureCallCenter: true,
		configureDataAnalytics: true,
		configureWorkorder: true,
		resetPassword: true,
		viewCallLog: false,
		viewAuditLog: true,
	}

	languagePrompts = []

	empAppErrorInput = ''
	empAppErrorResults: Array<any> = []

	@ViewChild('jobSiteMergeMigrationComponent', { static: false })
	mergeMigrationComponent: JobSiteMergeMigrationComponent

	constructor(
		private router: Router,
		private coreSrvc: CoreService,
	) {
		this.setupAvailability()
		this.setupCallCenterAccessibility()
		this.setupAccessPermissions()
		this.setupLanguagePrompts()
		this.setupSecureFilesDialog()
	}

	get isAdpIvr(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.getCompany().account_billing === 'ADP_IVR'
	}

	get isAuditMode(): boolean {
		return this.coreSrvc.auditMode
	}

	setupCallCenterAccessibility() {
		this.showCallCenterConfigButton = this.coreSrvc.dbSrvc.settingSrvc.isClickToCallCenterConfigurable()
	}

	ngOnInit() {
		// this.setupDisplayStrings()
		this.updateUI()
	}

	ngAfterViewInit() {}

	ngAfterContentInit() {
		// return // Don't show this tour yet
		setTimeout(() => {
			this.runGuidedTour()
		}, 500)
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'account')
		const permissions = this.accessHelper.getPermissionsFor('account')
		this.permissions.configureAccountDefaults = permissions.isOptionEnabledFor('ACTCAD')
		this.permissions.configureAccountDefaults = permissions.isOptionEnabledFor('ACTCAD')
		this.permissions.configurePortalPrefs = permissions.isOptionEnabledFor('ACTCPP')
		this.permissions.configureEmpScore = permissions.isOptionEnabledFor('ACTCES')
		this.permissions.configureEmpApp = permissions.isOptionEnabledFor('ACTCEA')
		this.permissions.configureCallBlocking = permissions.isOptionEnabledFor('ACTCCB')
		this.permissions.configureChecklist = permissions.isOptionEnabledFor('ACTCCL')
		this.permissions.configureSecureFiles = permissions.isOptionEnabledFor('ACTCAF')
		this.permissions.configureWorkorder = permissions.isOptionEnabledFor('ACTCWO')
		this.permissions.configureCallCenter = permissions.isOptionEnabledFor('ACTCCS')
		this.permissions.configureDataAnalytics = permissions.isOptionEnabledFor('ACTCDA')
		this.permissions.configureCompanyLogo = permissions.isOptionEnabledFor('ACTMCL')

		log('Options', this.permissions)
	}

	canPerformAction(action: CrudAction) {
		return this.accessHelper.canPerformAction(action, false)
	}

	setupAvailability() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		this.company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()

		this.coreSrvc.dbSrvc.settingSrvc.setUserAdminPrefs()

		const userPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		const arePendingSchedulesEnabled = userPrefs.schedulerEnablePendingQueue
		const isGlobal = this.coreSrvc.dbSrvc.settingSrvc.isGlobalAccount()

		// Setup internal user flag
		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()?.role === 'INTERNAL'

		// Setup analytics visibility flag
		this.isAnalyticsEnabled = userPrefs.globalEnableAnalytics

		// Setup onboarding visibility flag
		this.isOnboardingEnabled = company.onboard_required

		// Check for call log availablility
		const myRole = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser().role
		const isRoleAllowed = myRole === 'INTERNAL' || myRole === 'PRIMARY'
		const isC2CEnabled = this.coreSrvc.dbSrvc.settingSrvc.isClickToCallEnabled()
		if (isRoleAllowed && isC2CEnabled) {
			this.permissions.viewCallLog = true
		}

		// Setup whether or not we see the workorder config button
		this.showWorkorderConfigButton = arePendingSchedulesEnabled && !isGlobal && (!this.coreSrvc.auditMode || this.isInternalUser)
	}

	updateUI() {
		this.setupAvailability()
		const myActualUser = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
		this.userName = `${myActualUser.first_name} ${myActualUser.last_name}`
		this.mergeMigrationComponent?.updateUI()
	}

	setupLanguagePrompts() {
		const json = this.company.getLanguageJson()
		const prompts = json.order as Array<string>
		this.languagePrompts = prompts.map((p) => new SystemLanguagePrompt(p, json[p]))
	}

	setupSecureFilesDialog() {
		this.secureFilesDialogManager.dialogData = new SecureFileDialogData('ARCHIVE', null, null)
		this.secureFilesDialogManager.headerLabel = 'Archived Files'
	}

	startOfWorkWeek() {
		const wkst = this.company.wkst
		switch (wkst) {
			case 1:
				return 'Tuesday'
			case 2:
				return 'Wednesday'
			case 3:
				return 'Thursday'
			case 4:
				return 'Friday'
			case 5:
				return 'Saturday'
			case 6:
				return 'Sunday'
			default:
				return 'Monday'
		}
	}

	adpProfilesBtnClicked() {
		if (!this.permissions.configureAdpProfiles) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.adpProfilesDialogManager.headerLabel = 'ADP IVR Profiles'
		this.adpProfilesDialogManager.cancelBtnLabel = 'Close'
		this.adpProfilesDialogManager.isSubmitBtnVisible = false
		this.adpProfilesDialogManager.isDialogVisible = true
	}

	adpPhoneNumbersBtnClicked() {
		if (!this.permissions.configureAdpPhoneNumbers) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.adpPhoneNumbersDialogManager.headerLabel = 'ADP IVR Phone Numbers'
		this.adpPhoneNumbersDialogManager.cancelBtnLabel = 'Close'
		this.adpPhoneNumbersDialogManager.isSubmitBtnVisible = false
		this.adpPhoneNumbersDialogManager.isDialogVisible = true
	}

	editCompanyDefaultsBtnClicked(section: string) {
		if (!this.permissions.configureAccountDefaults) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.companyEditDialog.section = section
		if (section === 'GENERAL') {
			this.companyEditDialog.header = 'General Settings'
			this.companyEditDialog.footer = 'Edit general settings'
		}
		if (section === 'PROFILE') {
			this.companyEditDialog.header = 'Company Profile'
			this.companyEditDialog.footer = 'Edit company profile'
		}
		this.companyEditDialog.show = true
	}

	configureeAdminPrefsBtnClicked() {
		if (!this.permissions.configurePortalPrefs) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.adminPrefsDialog.header = 'Portal Preferences'
		this.adminPrefsDialog.footer = 'Configure company wide settings'
		this.adminPrefsDialog.group = 'company'
		this.adminPrefsDialog.show = true
	}

	employeeOnboardingBtnClicked() {
		if (!this.permissions.configureOnboarding) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.employeeOnboardingDialogManager.headerLabel = 'Employee Onboarding'
		this.employeeOnboardingDialogManager.footerLabel = 'Configure onboarding settings'
		this.employeeOnboardingDialogManager.submitBtnLabel = 'New'
		this.employeeOnboardingDialogManager.isSubmitBtnVisible = true
		this.employeeOnboardingDialogManager.isDialogVisible = true
	}

	employeeScoreBtnClicked() {
		if (!this.permissions.configureEmpScore) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.employeeScoreDialog.header = 'Employee Score'
		this.employeeScoreDialog.footer = 'Configure score settings'
		this.employeeScoreDialog.show = true
	}

	configureEmpAppBtnClicked() {
		if (!this.permissions.configureEmpApp) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.empPrefsDialog.header = 'Employee App Prefs'
		this.empPrefsDialog.footer = 'Configure company wide settings'
		this.empPrefsDialog.group = 'company'
		this.empPrefsDialog.show = true
	}

	configureCallBlockingBtnClicked() {
		if (!this.permissions.configureCallBlocking) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.callBlockingDialogManager.headerLabel = 'Call Blocking'
		this.callBlockingDialogManager.footerLabel = 'Configure call blocking'
		this.callBlockingDialogManager.isDialogVisible = true
	}

	configureChecklistBtnClicked() {
		if (!this.permissions.configureChecklist) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.employeeChecklistDialogManager.headerLabel = 'Employee Surveys'
		this.employeeChecklistDialogManager.submitBtnLabel = 'New'
		this.employeeChecklistDialogManager.cancelBtnLabel = 'Close'
		this.employeeChecklistDialogManager.isDialogVisible = true
	}

	configureArchivedFilesBtnClicked() {
		if (!this.permissions.configureSecureFiles) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.secureFilesDialogManager.isDialogVisible = true
	}

	configureeCallCenterBtnClicked() {
		if (!this.permissions.configureCallCenter) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.callCenterDialogManager.headerLabel = 'Call Center'
		this.callCenterDialogManager.footerLabel = 'Configure call center'
		this.callCenterDialogManager.isDialogVisible = true
	}

	configureWorkordersBtnClicked() {
		if (!this.permissions.configureWorkorder) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.workorderDialogManager.headerLabel = 'Configure Work Orders'
		this.workorderDialogManager.isDialogVisible = true
	}

	configureDataAnalyticsBtnClicked() {
		this.healthCenterDialogManager.headerLabel = 'Configure Data / Analytics'
		this.healthCenterDialogManager.isDialogVisible = true
	}

	companyRecordUpdated() {
		this.setupAvailability()
		this.setupLanguagePrompts()
		// this.setupDisplayStrings()
		this.coreSrvc.dbSrvc.readTable('company_switch_list').then((success) => {
			this.companyEditDialog.show = false
		})
	}

	adminPrefsUpdated() {
		this.adminPrefsDialog.show = false
		this.updateBannerSettings()
	}

	updateBannerSettings() {
		log('Update banner settings')
		const myAdminPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		this.coreSrvc.bannerSrvc.viewManager.groupAllBanners = myAdminPrefs.globalGroupNotificationBanners
		this.coreSrvc.bannerSrvc.groupBannerNeedsUpdate.next(true)
	}

	notifyOperationNotAuthorized() {
		this.coreSrvc.notifySrvc.default('operationNotAuthorized')
	}

	public decodeEmpAppResults() {
		const input = this.empAppErrorInput
		const regex = /[A-Za-z0-9+/]{100,}/g

		const encodedTextBlocks = input.match(regex)

		const decodedBlocks = encodedTextBlocks.map((etb) => atob(etb)).map((dtb) => JSON.parse(dtb))
		const tcLabel = new TrackCodeLabel()
		decodedBlocks.forEach((block) => {
			const currentArray = block.track ? [...block.track] : []
			if (currentArray)
				block.track = currentArray.map((data) => {
					const comps = data.split(':')
					const dur = comps[1]
					const key = comps[0]
					const name = tcLabel.lookup(key)
					return `${name} (${dur} sec)`
				})
		})
		this.empAppErrorResults = decodedBlocks
	}

	public showEmpAppResultMap(result: any) {
		const gps = `${result.gps}`

		if (gps.includes(',')) {
			// Open a new tab with the Google search page for the specified query
			const mapsLink = `https://www.google.com/maps?q=${gps}`
			window.open(mapsLink, '_blank')
		} else {
			this.coreSrvc.notifySrvc.notify('info', 'No GPS', 'No GPS data available to map.')
		}
	}

	public formatTimeFromErrorResult(result: any) {
		const date = result.lastRoute.date
		return date ? DateTimeHelper.standardDateTimeFromDateString(date) : ''
	}

	runGuidedTour() {
		if (window.screen.width >= 1180) {
			this.guidedTourForDesktop()
		} else {
			this.guidedTourForMobile()
		}
	}

	guidedTourForDesktop() {
		log('Should run guided tour checklist tour for desktop')
		const userIdSuffix = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()?.id ?? 0
		const cookieName = `gt-checklistrenamed-20240615-${userIdSuffix}`
		const options: any = {
			overlayOpacity: 0.5,
			exitOnOverlayClick: false,
			dontShowAgain: true,
			dontShowAgainCookie: cookieName,
			steps: [
				{
					element: document.querySelector('#customShiftReportsBtn'),
					intro: `We renamed Checklist Reports to <b>Employee Surveys</b> and you can now manage your own check-in, post-break, and check-out surveys from this section.<br /><br />These reports are available for submission using the employee web app and can be accessed by admins under Operations > Employee Shift Reports.`,
				},
			],
		}
		this.coreSrvc.gtSrvc.startTour(options)
	}

	guidedTourForMobile() {
		log('Should run guided tour checklist tour for mobile')
		const userIdSuffix = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()?.id ?? 0
		const cookieName = `gt-checklistrenamed-20240615-${userIdSuffix}`
		const options: any = {
			overlayOpacity: 0.5,
			exitOnOverlayClick: false,
			dontShowAgain: true,
			dontShowAgainCookie: cookieName,
			steps: [
				{
					intro: `We renamed Checklist Reports to <b>Employee Surveys</b> and you can now manage your own check-in, post-break, and check-out surveys from this section.<br /><br />These reports are available for submission using the employee web app and can be accessed by admins under Operations > Employee Shift Reports.`,
				},
			],
		}
		this.coreSrvc.gtSrvc.startTour(options)
	}
}
