import { Injectable } from '@angular/core'

import { log } from '@app/helpers'
import { IntegrationLog, WIWSync } from '@app/models'
import { SelectItem } from 'primeng/api'
import _ from 'lodash'
import { Global } from '@app/models/global'

class WhenIWorkEmployee {}

@Injectable({
	providedIn: 'root',
})
export class WhenIWworkService {
	private wiwEmployees: Array<WhenIWorkEmployee> = [] // wiw_employee
	private wiwSync: Array<WIWSync> = [] // wiw_sync
	private wiwLog: Array<IntegrationLog> = [] // wiw_log

	public wiwEmployeesDataLoaded = false
	public wiwSyncDataLoaded = false
	public wiwLogDataLoaded = false

	constructor() {
		log('Creating WhenIWorkService')
	}

	dataLoaded(): boolean {
		return this.wiwEmployeesDataLoaded && this.wiwSyncDataLoaded
	}

	isWiWIntegrated(): boolean {
		return !!this.getWIWSync()
	}

	clearData() {
		this.wiwEmployees = []
		this.wiwSync = []
		this.wiwEmployeesDataLoaded = false
		this.wiwSyncDataLoaded = false
	}

	getWIWSync(): WIWSync {
		return this.wiwSync[0] || null
	}

	setWIWSyncRecords(records: Array<Object>) {
		this.wiwSync = records.map((rec) => new WIWSync(rec))
		this.wiwSyncDataLoaded = true
	}

	getAdpLog(): Array<IntegrationLog> {
		return this.wiwLog
	}

	setWIWLogRecords(records: Array<Object>) {
		this.wiwLog = records.map((rec) => new IntegrationLog(rec))
		this.wiwLogDataLoaded = true
	}

	logRecords() {
		log('WIW Service Log loaded records')
		// log('Department Codes', this.adpDepartmentCodeDataLoaded, this.adpDepartmentCodes)
		// log('Earnings Codes', this.adpEarningsCodeDataLoaded, this.adpEarningsCodes)
		// log('Rate Codes', this.adpRateCodeDataLoaded, this.adpRateCodes)
		// log('Sync Records', this.wiwSyncDataLoaded, this.wiwSync)
		// log('Log Records', this.wiwLogDataLoaded, this.wiwLog)
	}
}
