export class BillingHistory {
	id: number
	company_id: number
	date: string
	reference_id: string
	description: string
	amount: number
	created: string
	error: boolean
	notes: string
	error_resolved: boolean
	bill_html: string
	invoice_url: string
	report_url: string
	paid: string

	constructor(record?: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
	}

	static getMockHistory() {
		return mockBillingHistory.map((rec) => new BillingHistory(rec)).reverse()
	}
}

const mockBillingHistory = [
	{
		id: 0,
		date: '2019-01-27',
		amount: '$48.00',
		notes: 'billed to card ending 5010',
		error: false,
		error_resolved: false,
	},
	{
		id: 1,
		date: '2019-02-27',
		amount: '$48.00',
		notes: 'failed to bill to card ending in 5010',
		error: true,
		error_resolved: false,
	},
	{
		id: 2,
		date: '2019-03-27',
		amount: '$48.00',
		notes: 'Could not bill to card',
		error: true,
		error_resolved: true,
	},
	{
		id: 3,
		date: '2019-04-27',
		amount: '$48.00',
		notes: 'billed to card ending 5010',
		error: false,
		error_resolved: false,
	},
]
