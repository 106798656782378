<div>
	<textarea
		#inputBasicTextArea
		rows="1"
		class="form-control item-label-textarea"
		placeholder="Enter input description"
		[(ngModel)]="config.label"
		(input)="textResizer.emit(inputBasicTextArea)"
		(focus)="clearError.emit(item)"
	></textarea>
	<div *ngIf="item.showConfig">
		<div class="checkbox-item cli-comment-block">
			<div class="options-title">No options to configure</div>
		</div>
	</div>
</div>
