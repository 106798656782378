import { Component, OnInit, AfterViewInit, ChangeDetectorRef, Input, Output, EventEmitter, OnDestroy, AfterContentInit } from '@angular/core'

import { UntypedFormBuilder, UntypedFormGroup, Validators, ValidationErrors } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'

import {
	TransactionLogRecord,
	TransAlert,
	SelectorPermissionName,
	FileUploadManager,
	FileUploaderProcessedFile,
	OrganizationSelectItem,
	PayRateSourceType,
	DialogManager,
	HelpDialogMessage,
	ContactSupportEvent,
} from '@app/models'
import { CoreService, DatabaseService } from '@app/services'

import { log, Helper, DateTimeHelper, DisplayHelper, TransTableFormatter, IJobScheduleDropdown, FormHelper, TransactionHelper } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'
import { SelectItem } from 'primeng/api'

import { Subscription } from 'rxjs'

import { NumberHelper } from '@app/helpers/number'
import { environment } from '@env/environment'
import { v4 as uuid } from 'uuid'

import _ from 'lodash'
import moment from 'moment-timezone'
import { ScheduleLogRecord } from '@app/models/schedule-log'

interface TemplateLabelBlock {
	className: string
	label: string
}
@Component({
	selector: 'app-transaction-edit',
	templateUrl: './transaction-edit.component.html',
	styleUrls: ['./transaction-edit.component.scss'],
	standalone: false,
})
export class TransactionEditComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {
	updateRecord: any // FOR TESTING

	accessHelper: AccessHelper
	transForm: UntypedFormGroup

	title: string
	isNew: boolean
	isUpdating = false
	isPickingShift = false
	// isRecordValid = true
	is12HourFormat = true
	isJobScheduleReferenceValid = false
	isAnyEmployeeNoShow = false

	isEditable = true
	canEditAdminNotes = false
	isEditingAdminNotes = false

	employeeDeleted = false
	recordExported = false
	clearException = false
	showTooltips = false

	dataMismatchErrors = [] // Check for deprecation - does it reference recur
	currentShiftInfo: CurrentShiftInfo

	employeesDropdown: Array<SelectItem> = []
	jobsDropdown: Array<SelectItem> = []
	clientDropdown: Array<OrganizationSelectItem> = []
	vendorDropdown: Array<OrganizationSelectItem> = []

	jobName: string
	originalStartEnd = ''
	originalStartTime = ''
	originalStartTimeModified = false
	originalEndTime = ''
	originalEndTimeModified = false
	defaultTimezone = '' // America/Chicago
	previousTimezone = ''
	jobSiteTZShort = ''
	useAdjustedTime = true
	adjustedStartTime = ''
	showNotesField = false

	breakLengthDisplay = '0'

	shiftLengthWarning = ''
	currentShiftLength = '0:00'

	appendToAdminNotes = ''

	showActualTimeWorkedDialog = false
	// showModifiedTimeWorkedDialog = false
	showBreakEditDialog = false
	allowHoursWorkedCalculation = false

	expandEmployeeNotes = false
	expandAdminNotes = false
	expandPhotosCheckin = false
	expandPhotosCheckout = false
	expandPayRates = false
	expandClientVendor = false

	inFileUploadManager = new FileUploadManager()
	outFileUploadManager = new FileUploadManager()

	currencySymbol = ''
	showPayRateOption = false

	empNotesLock = { geoStart: true, geoEnd: true, empNotes: true }

	showStartTimeDialog = false
	showEndTimeDialog = false

	jobLengthMax = 16
	checkInOutWindow = 'PT5H'

	travelJobIds = []
	isOriginalEntryTravel = false

	newUuid = null

	// Manage override pop-up for overlapping shifts
	overlapOverride = { showDialog: false, allowOverride: false }
	dateLockOverride = { showDialog: false, allowOverride: false }

	customJobDate: Date

	transAlert: TransAlert

	dstShift = false // Flag used to add extra hour in fall DST transition

	@Input() dialogManager: DialogManager

	@Input() action: string
	@Input() transactionId: number
	@Input() modified: boolean

	@Output() dataUpdated: EventEmitter<any> = new EventEmitter()
	@Output() transNeedsHighlight: EventEmitter<number> = new EventEmitter()
	@Output() recordUpdated: EventEmitter<number> = new EventEmitter()
	@Output() recordAdded: EventEmitter<boolean> = new EventEmitter()
	@Output() editCancelled: EventEmitter<boolean> = new EventEmitter()

	transaction: TransactionLogRecord
	private subs = new Subscription()

	startTimePickerStartAtDefault = null
	endTimePickerStartAtDefault = null

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private fb: UntypedFormBuilder,
		private cd: ChangeDetectorRef,
		private coreSrvc: CoreService,
	) {
		this.setupAccessPermissions()
		this.subs.add(this.coreSrvc.dbSrvc.lambdaSrvc.dataAccessErrorEvent.subscribe((event) => this.dataAccessErrorEventHandler(event)))

		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const user = this.coreSrvc.dbSrvc.settingSrvc.getMyUser()
		const userPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		this.is12HourFormat = userPrefs.globalFormatTime12Hours
		this.jobLengthMax = company.job_length_max
		this.defaultTimezone = company.timezone
		this.checkInOutWindow = company.check_in_out_window

		// Setup travel job IDs
		this.travelJobIds = this.coreSrvc.dbSrvc.jobSrvc.getTravelJobs().map((j) => j.id)
		this.isOriginalEntryTravel = this.transaction?.travel_job

		// Setup pay rate option
		this.showPayRateOption = company.pay_rate
		this.currencySymbol = this.coreSrvc.dbSrvc.settingSrvc.getCompanyCurrencySymbol()
	}

	get formattedJobDate(): string {
		const date = this.transaction ? this.transaction.job_date : ''
		return date ? moment(date, 'YYYY-MM-DD').format('ddd MMM Do, YYYY') : ''
	}

	get isEmployeeActive(): boolean {
		const empId = this.transForm.get('employee_id').value
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)
		return emp ? emp.active : false
	}

	get isCurrentEntryTravel(): boolean {
		const currentJobId = this.transForm?.get('job_id').value
		if (currentJobId && this.travelJobIds.includes(currentJobId)) {
			return true
		}
		return false
	}

	get isFutureStartDate(): boolean {
		const timezone = this.transForm.get('timezone').value
		const start = this.transForm.get('actual_start').value
		if (!start) {
			return false
		}
		const cutoff = moment.tz(timezone).endOf('day')
		const isNextDay = moment(start).tz(timezone).isAfter(cutoff, 'day')
		return isNextDay ? true : false
	}

	get inPhotoCount(): number {
		return this.inFileUploadManager.processedFiles.length
	}

	get outPhotoCount(): number {
		return this.outFileUploadManager.processedFiles.length
	}

	get hasClients(): boolean {
		return this.clientDropdown.length > 1
	}

	get hasVendors(): boolean {
		return this.vendorDropdown.length > 1
	}

	get isCurrentJobMultiday(): boolean {
		const jobId = this.transForm.get('job_id').value
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)
		return job ? job.multi_day : false
	}

	get shouldShowJobDatePicker(): boolean {
		return this.currentShiftInfo ? false : true
	}

	get shiftLengthBlock(): TemplateLabelBlock {
		let className = ''
		let label = ''

		return { className: className, label: label }
	}

	ngOnInit() {
		this.setupComponent()
		log('CurrentTransaction', this.transactionId, this.transaction)

		this.setupForm()

		// Setup current shift info from transaction - when no reference to recur is used, can probably change this to !this.isNew
		const hasShiftLogIdWithRecurrence = !this.isNew && this.transaction?.schedule_log_id && this.transaction?.recurrence
		if (!this.isNew && (this.transaction.schedule_recur_id || hasShiftLogIdWithRecurrence)) {
			this.currentShiftInfo = new CurrentShiftInfo(this.coreSrvc.dbSrvc, this.transaction)
		}

		// Setup dropdowns
		this.setupEmployeeDropdown()
		this.setupJobsDropdown()
		this.updateFormForJobId(this.transForm.value.job_id, false)
		this.setupOriginalStartAndEndTimes()
		this.calculateBreakTime()
		this.updatePickerStartAtDates() // Call after transaction has be assigned
		this.setupNotesExpansion()
		this.setupInFileUploadManager()
		this.setupOutFileUploadManager()
		this.setupClientVendorDropdown()
		this.adjustForAnyEmployeeEntry()
		this.setDSTSwitch() // Do this last after everything has been setup
	}

	calculateShiftLength(format): string {
		const form = this.transForm.value
		const timezone = this.transForm.get('timezone').value
		const startMoment = moment(form.actual_start).startOf('minute')
		const endMoment = moment(form.actual_end).startOf('minute')

		if (startMoment.isValid() && endMoment.isValid()) {
			const startString = startMoment.format('YYYY-MM-DDTHH:mm:ss')
			const endString = endMoment.format('YYYY-MM-DDTHH:mm:ss')

			const start = moment.tz(startString, timezone)
			const end = moment.tz(endString, timezone)
			let duration = moment.duration(end.diff(start)).asMilliseconds()

			// Adjustment for DST - If the switch is visible and enabled then adjust for extra hour
			if (this.showDSTSwitch) {
				if (this.dstShift) {
					duration = duration + 3600000
				}
			}

			this.currentShiftLength = this.timeWorked(format, duration)
			this.calculateHoursWorked()
			return this.currentShiftLength
		} else {
			if (startMoment.isValid()) {
				return ''
			}
		}
		return null
	}

	ngAfterViewInit() {
		this.allowHoursWorkedCalculation = true
		this.calculateHoursWorked()
		this.cd.detectChanges()
	}

	ngAfterContentInit() {
		this.setupDialogManager()
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	setupDialogManager() {
		this.dialogManager.canSubmit = () => this.isFormValid()
		this.dialogManager.submitBtnAction = () => this.submit()
	}

	adjustForAnyEmployeeEntry() {
		log('Got an any employee time entry')
		const empId = this.transForm.get('employee_id').value
		if (empId === 0) {
			this.isNew = true // Should only be 0 if it's an Any Employee no show

			this.isAnyEmployeeNoShow = true
			this.transForm.get('employee_id').setValue(null)
			this.transForm.get('employee_id').setValidators([Validators.required])
			this.transForm.get('actual_start').setValue(null)
			this.transForm.get('actual_start').setValidators([Validators.required])
			this.transForm.get('enable_notifications').setValue(true)
			this.currentShiftInfo = new CurrentShiftInfo(this.coreSrvc.dbSrvc, this.transaction)

			// Need to call dropdown changed to adjust original schedule info for an any employee no show
			this.employeeDropdownChanged()
			this.cd.markForCheck()
		}
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'transaction')
		const permissions = this.accessHelper.getPermissionsFor('transaction')
		const canUserEditAdminNotes = permissions.isOptionEnabledFor('TEEEAN')
		const userRole = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser().role
		this.canEditAdminNotes = userRole === 'INTERNAL' || canUserEditAdminNotes
	}

	get originalJobExists(): boolean {
		if (this.isNew) return true
		const jobId = this.transaction.job_id
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)
		return !!job
	}

	private dataAccessErrorEventHandler(event: any) {
		// log('Error event caught in Transaction Detail Component', event)
		const errorType = event?.errorType
		const errorMsg = event?.errorMessage

		if (errorType === 'com.sst.ivr.lambda.exceptions.TransactionEmployeeOverlapException') {
			// log('Got a transaction overlap error')
			const errorObj = errorMsg ? JSON.parse(errorMsg) : null
			// log('Error Obj', errorObj)
			const conflicts = errorObj?.conflicts
			const conflictTransId = conflicts?.[0] as number
			if (conflictTransId) {
				const conflictTrans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(conflictTransId)
				if (conflictTrans) {
					const jobName = conflictTrans.job_description
					const jobDate = conflictTrans.job_date
					const empName = conflictTrans.employee_name
					const notifyMsg = `This entry for ${empName} overlaps with an entry on ${jobDate} for ${jobName}.`
					this.coreSrvc.notifySrvc.notify('error', 'Time Entry Overlap', notifyMsg)
					this.transNeedsHighlight.next(conflictTrans.id)
				} else {
					this.coreSrvc.notifySrvc.notify(
						'error',
						'Time Entry Overlap',
						`This time entry overlaps with another that is out of range. To see the conflict, use the Filter Dates icon in the upper left to load the necessary time entries.`,
					)
				}
			}
			// this.overlapOverride.showDialog = true // DEPRECATED as we do not allow
		}

		if (errorType === 'com.sst.ivr.lambda.exceptions.TransactionDateLockException') {
			if (this.showBreakEditDialog) return
			this.dateLockOverride.showDialog = true
		}
	}

	// Begin - Manage DST Adjustment
	get showDSTSwitch(): boolean {
		const start = this.transForm.value.actual_start
		const end = this.transForm.value.actual_end
		const tz = this.transForm.value.timezone
		if (start && end && tz) {
			return this.isDateInFallDSTGap(start, end, tz)
		}
		return false
	}

	get doesShiftCrossDSTBoundary(): boolean {
		const form = this.transForm.value
		const start = form.actual_start
		const end = form.actual_end
		const tz = this.transForm.value.timezone

		if (start && end && tz) {
			const startString = moment(start).format('YYYY-MM-DDTHH:mm:ss')
			const endString = moment(end).format('YYYY-MM-DDTHH:mm:ss')

			const isStartDST = moment.tz(startString, tz).isDST()
			const isEndDST = moment.tz(endString, tz).isDST()

			return (isStartDST && !isEndDST) || (!isStartDST && isEndDST)
		}
		return false
	}

	isDateInFallDSTGap(start: Date, end: Date, tz: string): boolean {
		const startStr = moment(start).format('YYYY-MM-DDTHH:mm:ss')
		const startMoment = moment.tz(startStr, tz)

		const endStr = moment(end).format('YYYY-MM-DDTHH:mm:ss')
		const endMoment = moment.tz(endStr, tz)

		if (startStr && endStr && startMoment.isValid() && endMoment.isValid()) {
			const isEndDST = endMoment.isDST()

			const momentToCheck = endMoment.clone().startOf('minute')

			// Get the UTC offset before and after the moment
			const offsetBefore = momentToCheck.utcOffset()
			const offsetAfter = momentToCheck.add(1, 'hour').utcOffset()

			// If the UTC offset before and after is the same, it's not in the gap
			return isEndDST && offsetBefore !== offsetAfter
		}
		return false
	}

	private setDSTSwitch() {
		const transStart = this.transaction?.actual_start
		const transEnd = this.transaction?.actual_end
		// This basically looks at the duration of the shift to see if it would be different if you go back a week.
		// If it's different then we know a DST adjustment has been made
		if (transEnd) {
			const startMom = moment.tz(transStart, this.transaction.timezone)
			const endMom = moment.tz(transEnd, this.transaction.timezone)
			const duration = endMom.diff(startMom, 'seconds')
			startMom.subtract(1, 'week')
			endMom.subtract(1, 'week')
			const adjustDur = endMom.diff(startMom, 'seconds')
			if (duration === adjustDur) {
				this.dstShift = false
			} else {
				this.dstShift = true
			}
		}
	}
	// End - Manage DST Adjustment

	public cancelOverlapOverride() {
		this.overlapOverride.allowOverride = false
		this.overlapOverride.showDialog = false
	}

	public submitOverlapOverride() {
		this.overlapOverride.allowOverride = true
		this.overlapOverride.showDialog = false
		this.submit()
	}

	public cancelDateLockOverride() {
		this.coreSrvc.notifySrvc.clear()
		this.dateLockOverride.allowOverride = false
		this.dateLockOverride.showDialog = false
	}

	public submitDateLockOverride() {
		this.coreSrvc.notifySrvc.clear()
		this.dateLockOverride.allowOverride = true
		this.dateLockOverride.showDialog = false
		this.submit()
	}

	isFormValid(): boolean {
		const isValid = this.transForm.valid && !this.invalidForm() && !this.isUpdating
		return isValid
	}

	private setupComponent() {
		if (!this.transactionId && this.action !== 'new') {
			this.action = this.route.snapshot.params['action']
			this.transactionId = parseInt(this.route.snapshot.params['transactionId'], 10) || null
		}

		if (this.action === 'new') {
			this.title = 'Add Time Entry'
			this.isNew = true
		} else if (this.action === 'edit') {
			this.transaction = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(this.transactionId)
			this.title = 'Edit Time Entry'
			this.isNew = false
			const jobDescription = this.transaction.job_description
			this.jobName = Helper.getJobName(jobDescription)
			if (this.transaction) {
				log('Setting Up Trans Alert')
				this.transAlert = new TransAlert(this.transaction)
				log('Trans Alert', this.transAlert)
			}
		}

		const trans = this.transaction

		// See if this record is no longer editable
		if (trans) {
			if (trans.exported) {
				this.recordExported = true
				this.isEditable = false
			}
			const empName = this.coreSrvc.dbSrvc.empSrvc.employeeNameForId(this.transaction.employee_id)
			if (empName === 'Employee Not Found') {
				this.employeeDeleted = true
				this.isEditable = false
			}

			// Check for valid schedule references
			const jobId = trans.job_id
			const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)
			const schedId = trans.schedule_recur_id
			const sched = this.coreSrvc.dbSrvc.schedulerSrvc.getScheduleForId(schedId)
			if (jobId && job) {
				this.isJobScheduleReferenceValid = true
			}
			if (schedId && sched) {
				this.isJobScheduleReferenceValid = true
			}
		} else {
			if (this.isNew) {
				this.isJobScheduleReferenceValid = true
			}
		}
	}

	private setupNotesExpansion() {
		if (!this.transaction) return
		this.expandEmployeeNotes = !!this.transaction.emp_notes || !!this.transaction.geo_start_emp_note || !!this.transaction.geo_end_emp_note
		this.expandAdminNotes = !!this.transaction.notes
	}

	private setupInFileUploadManager() {
		log('Setup File Upload Manager')
		const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
		const fileUploadManager = new FileUploadManager()
		fileUploadManager.setConfiguration(companyId, environment.assetsBucket, 'checkpoints')
		fileUploadManager.supportedMimeTypes = ['image/gif', 'image/png', 'image/jpeg']
		fileUploadManager.useFileExtFallbackForMimeType = true
		if (this.transaction) {
			const files = this.transaction.getImages('IN').map((rec) => new FileUploaderProcessedFile(rec))
			fileUploadManager.processedFiles = files
		}
		this.inFileUploadManager = fileUploadManager
	}

	private setupOutFileUploadManager() {
		log('Setup File Upload Manager')
		const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
		const fileUploadManager = new FileUploadManager()
		fileUploadManager.setConfiguration(companyId, environment.assetsBucket, 'checkpoints')
		fileUploadManager.supportedMimeTypes = ['image/gif', 'image/png', 'image/jpeg']
		fileUploadManager.useFileExtFallbackForMimeType = true
		if (this.transaction) {
			const files = this.transaction.getImages('OUT').map((rec) => new FileUploaderProcessedFile(rec))
			fileUploadManager.processedFiles = files
		}
		this.outFileUploadManager = fileUploadManager
	}

	private setupClientVendorDropdown() {
		const clientDropdown = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationDropdownData('CLIENT')
		clientDropdown.unshift({ label: 'Use client set in job', value: null, data: null })
		this.clientDropdown = clientDropdown

		const vendorDropdown = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationDropdownData('VENDOR')
		vendorDropdown.unshift({ label: 'Use vendor set in job', value: null, data: null })
		this.vendorDropdown = vendorDropdown
	}

	updatePickerStartAtDates() {
		// this.pickerStartDate = this.transaction ? moment(this.transaction.job_date, 'YYYY-MM-DD').toDate() : new Date()
		const startTime = this.transForm.get('actual_start').value
		const endTime = this.transForm.get('actual_end').value
		const jobDate = this.transForm.get('job_date').value

		if (startTime && !endTime) {
			log('Actual Start', startTime)
			this.endTimePickerStartAtDefault = startTime
			return
		}
		if (jobDate) {
			this.startTimePickerStartAtDefault = moment(jobDate, 'YYYY-MM-DD').toDate()
			this.endTimePickerStartAtDefault = moment(jobDate, 'YYYY-MM-DD').toDate()
			return
		}
		// this.pickerStartDate = new Date(2019, 2, 15, 20, 30)
		log('Start Time Picker Start At', this.startTimePickerStartAtDefault)
		log('End Time Picker Start At', this.endTimePickerStartAtDefault)
	}

	setupForm() {
		// Setp synthetic ID
		let syntheticId = null
		const trans = this.transaction
		if (trans) {
			if (trans.job_id) {
				syntheticId = 'job-' + trans.job_id
			}
			if (trans.schedule_recur_id) {
				syntheticId = 'sched-' + trans.schedule_recur_id
			}
		}

		// const startTimeValidator = this.isNew ? [Validators.required] : null

		this.transForm = this.fb.group({
			id: [trans ? trans.id : ''],
			break_time: [this.transaction ? this.transaction.break_time : 'PTM0'],
			company_id: [this.transaction ? this.transaction.company_id : ''],
			employee_id: [this.transaction ? this.transaction.employee_id : null, [Validators.required]],
			employee_name: [this.transaction ? this.transaction.employee_name : ''],
			employee_first: [this.transaction ? this.transaction.employee_first : ''],
			employee_last: [this.transaction ? this.transaction.employee_last : ''],
			exception: [this.transaction ? this.transaction.exception : false],
			exception_type: [this.transaction ? this.transaction.exception_type : null],
			job_id: [this.transaction ? this.transaction.job_id : null],
			job_date: [this.transaction ? this.transaction.job_date : ''],
			job_description: [this.transaction ? this.transaction.job_description : ''],
			jobsite_id: [this.transaction ? this.transaction.jobsite_id : ''],
			actual_start: [this.transaction ? this.adjustTimestampToLocalDate(this.transaction.actual_start) : null],
			actual_end: [this.transaction ? this.adjustTimestampToLocalDate(this.transaction.actual_end) : null],
			time_worked: [this.transaction ? this.transaction.time_worked : ''],
			warned_flag: [this.transaction ? this.transaction.warned_flag : false],
			supervisor_flag: [this.transaction ? this.transaction.supervisor_flag : false],
			warned_flag_out: [this.transaction ? this.transaction.warned_flag_out : false],
			supervisor_flag_out: [this.transaction ? this.transaction.supervisor_flag_out : false],
			timezone: [this.transaction && this.transaction.timezone ? this.transaction.timezone : this.defaultTimezone],
			created: [this.transaction ? this.transaction.created : ''],
			exported: [this.transaction ? this.transaction.exported : ''],
			adjustedTime: [this.transaction ? this.formatDuration(this.transaction.time_worked) : ''],
			notes: [this.transaction ? this.transaction.notes : ''],
			geo_start_emp_note: [this.transaction ? this.transaction.geo_start_emp_note : ''],
			geo_end_emp_note: [this.transaction ? this.transaction.geo_end_emp_note : ''],
			emp_notes: [this.transaction ? this.transaction.emp_notes : ''],
			// geo_start_emp_note: [this.transaction ? { value: this.transaction.geo_start_emp_note, disabled: true } : { value: '', disabled: true }],
			// geo_end_emp_note: [this.transaction ? { value: this.transaction.geo_end_emp_note, disabled: true } : { value: '', disabled: true }],
			// emp_notes: [this.transaction ? { value: this.transaction.emp_notes, disabled: true } : { value: '', disabled: true }],
			enable_notifications: [this.transaction ? this.transaction.enable_notifications : false],

			client_id: [this.transaction ? this.transaction.client_id : null],
			vendor_id: [this.transaction ? this.transaction.vendor_id : null],

			pay_rate: [this.transaction ? this.setupPayRate(this.transaction, 'EMPLOYEE') : null],
			pay_rate_source: [this.transaction ? this.transaction.pay_rate_source : null],
			client_pay_rate: [this.transaction ? this.setupPayRate(this.transaction, 'CLIENT') : null],
			client_pay_rate_source: [this.transaction ? this.transaction.pay_rate_source : null],
			vendor_pay_rate: [this.transaction ? this.setupPayRate(this.transaction, 'VENDOR') : null],
			vendor_pay_rate_source: [this.transaction ? this.transaction.pay_rate_source : null],

			synthetic_id: [this.transaction ? syntheticId : null],
			recurrence: [this.transaction ? this.transaction.recurrence : null],
			recur_description: [this.transaction ? this.transaction.recur_description : null],
			start_time: [this.transaction ? this.transaction.start_time : null],
			end_time: [this.transaction ? this.transaction.end_time : null],
			employee_count: [this.transaction ? this.transaction.employee_count : null],
			schedule_recur_id: [this.transaction ? this.transaction.schedule_recur_id : null],
			schedule_log_id: [this.transaction ? this.transaction.schedule_log_id : null],
			recur_emp_name: [this.transaction ? this.transaction.recur_emp_name : null],
		})
		this.previousTimezone = this.transForm.get('timezone').value

		if (this.transaction) {
			if (this.transaction.notes) {
				this.showNotesField = true
			}
			if (this.transaction.time_worked) {
				this.useAdjustedTime = true
			}
			if (this.transaction.travel_job) {
				this.isOriginalEntryTravel = true
			}
		} else {
			this.transForm.get('enable_notifications').setValue(true)
		}

		if (this.isNew) {
			this.transForm.get('actual_start').setValidators([Validators.required])
		}

		// Setup custom job date field from record or default to today
		const jobDate = this.transaction?.job_date
		if (jobDate) {
			this.customJobDate = moment(jobDate).startOf('day').toDate()
		} else {
			const nowMom = moment().startOf('day')
			this.customJobDate = nowMom.toDate()
			this.transForm.get('job_date').setValue(nowMom.format('YYYY-MM-DD'))

			const dayViewDate = this.coreSrvc.dbSrvc.tranSrvc.dayViewDate
			if (this.isNew && dayViewDate) {
				const dayViewMom = moment(dayViewDate)
				this.customJobDate = dayViewMom.toDate()
				this.transForm.get('job_date').setValue(dayViewMom.format('YYYY-MM-DD'))
			}
		}
	}

	afterPickerClosed() {
		log('bluring')
		setTimeout(() => {
			$('.datetime-input').trigger('blur')
			this.updatePickerStartAtDates()
		}, 250)
	}

	toggleTooltips(): boolean {
		this.showTooltips = !this.showTooltips
		return false
	}

	alertMessage(): string {
		if (this.isNew) {
			return ''
		} else {
			const alert = new TransAlert(this.transaction)
			return alert.alertMessage()
		}
	}

	get isMobile(): boolean {
		if ($(window).width() < 560) {
			return true
		} else {
			return false
		}
	}

	get hasJobDateError() {
		const actualStartDate = this.transForm.get('actual_start').value
		// if (actualStartDate === 'Not Set') return false
		const jobDate = this.customJobDate
		if (actualStartDate && jobDate) {
			const actualStartMom = moment(actualStartDate).startOf('day')
			const jobDateMom = moment(jobDate).startOf('day')
			const diff = actualStartMom.diff(jobDateMom, 'day')
			// log('Diff', diff)
			if (Math.abs(diff) > 1) return true
		}
		return false
	}

	public getPayRateSource(sourceType: PayRateSourceType): string {
		switch (sourceType) {
			case 'EMPLOYEE':
				return this.transForm.value.pay_rate ? 'Time Entry' : this.transaction?.getPayRateSourceLabel(sourceType)
			case 'CLIENT':
				return this.transForm.value.client_pay_rate ? 'Time Entry' : this.transaction?.getPayRateSourceLabel(sourceType)
			case 'VENDOR':
				return this.transForm.value.vendor_pay_rate ? 'Time Entry' : this.transaction?.getPayRateSourceLabel(sourceType)
		}
	}

	public updateJobDate() {
		const jobDateString = moment(this.customJobDate).format('YYYY-MM-DD')
		this.transForm.get('job_date').setValue(jobDateString)
		this.updatePickerStartAtDates()
	}

	private setupPayRate(trans: TransactionLogRecord, rateType: 'EMPLOYEE' | 'CLIENT' | 'VENDOR') {
		const source =
			rateType === 'EMPLOYEE'
				? trans.pay_rate_source
				: rateType === 'CLIENT'
					? trans.client_pay_rate_source
					: rateType === 'VENDOR'
						? trans.vendor_pay_rate_source
						: null
		if (source === 'TIME_ENTRY') {
			switch (rateType) {
				case 'EMPLOYEE':
					return trans.pay_rate?.toFixed(2)
				case 'CLIENT':
					return trans.client_pay_rate?.toFixed(2)
				case 'VENDOR':
					return trans.vendor_pay_rate?.toFixed(2)
			}
		}
		return null
	}

	private setupOriginalStartAndEndTimes() {
		const is12Hours = this.is12HourFormat
		const tz = this.transForm.get('timezone').value
		const jobDate = this.transaction ? this.transaction.job_date : ''
		const abrv = jobDate ? moment(jobDate).tz(tz).zoneAbbr() : ''

		const startMom = moment(this.transForm.get('actual_start').value)
		let startTimeString = is12Hours ? startMom.format('ddd MMM Do h:mm a') : startMom.format('ddd MMM Do HH:mm')
		const endMom = moment(this.transForm.get('actual_end').value)
		let endTimeString = is12Hours ? endMom.format('ddd MMM Do h:mm a') : endMom.format('ddd MMM Do HH:mm')

		if (startMom.isValid()) {
			startTimeString += ' ' + abrv
		} else {
			startTimeString = ''
		}

		if (endMom.isValid()) {
			endTimeString += ' ' + abrv
		} else {
			endTimeString = ''
		}

		this.originalStartTime = startTimeString
		this.originalEndTime = endTimeString
		this.originalStartEnd = startTimeString + ' - ' + endTimeString
	}

	adjustTimestampToLocalDate(dateString: any): Date {
		const timezone = this.transaction.timezone
		return DateTimeHelper.convertIsoDateStringToLocalDate(dateString, timezone)
	}

	displayTimeAmPm(date: string): string {
		let actualDate: Date = null
		if (date === 'start') {
			actualDate = this.transForm ? this.transForm.value.actual_start : null
		}
		if (date === 'end') {
			actualDate = this.transForm ? this.transForm.value.actual_end : null
		}
		if (!actualDate) {
			return 'Not Set'
		}
		const mom = moment(actualDate)
		if (!mom.isValid()) {
			return 'Invalid'
		}
		return mom.format('h:mma ddd YYYY-MM-DD')
	}

	// CHECK FOR DEPRECATION
	startTimeNote() {
		return ''
	}

	// CHECK FOR DEPRECATION
	endTimeNote() {
		return ''
	}

	// Formate moment duration in H:mm format
	formatDuration(str: string) {
		if (!str) {
			return null
		}
		if (/-/.test(str)) {
			return null
		}
		const duration = moment.duration(str)
		return DateTimeHelper.formatDuration('H:mm', duration)
	}

	formatPlaceholderRate(prop: string) {
		const value = this.transaction?.[prop]
		if (value) return NumberHelper.formatPayRate(value)?.toFixed(2) ?? ''
		return ''
	}

	formatRateInput(prop: string) {
		const value = this.transForm.get(prop).value
		const newValue = NumberHelper.formatPayRate(value)?.toFixed(2) ?? null
		this.transForm.get(prop).setValue(newValue)
	}

	// CHECK FOR DEPRECATION
	showAdjustTimeWorkedOption(): boolean {
		return true
	}

	// CHECK FOR DEPRECATION
	toggleTimeWorkedSource(): boolean {
		// If we're using the adjusted time now then first remove any entry
		// before switching to using actual time.
		if (this.useAdjustedTime) {
			this.transForm.value.adjustedTime = null
		}
		this.useAdjustedTime = !this.useAdjustedTime
		return false
	}

	submit(): boolean {
		// Short circuit if already updating
		if (this.isUpdating) return false
		// FormHelper.trimOnlyWhitespace(this.transForm)

		// Short sircuit if there's an error making update record
		const record = this.makeUpdateRecord()
		if (!record) return false

		// Check if trying to edit an exported record
		if (record.exported) {
			this.coreSrvc.notifySrvc.notify('error', 'Not Supported', 'Editing exported time entries is not supported.')
			return
		}

		this.isUpdating = true
		this.cd.detectChanges()

		if (this.isNew) {
			this.coreSrvc.dbSrvc.insertRecord('transaction_log', record).then((insertSuccess) => {
				if (insertSuccess) {
					this.recordAdded.emit(true)
				} else {
					log('Error inserting transaction')
					this.isUpdating = false
					this.cd.detectChanges()
				}
			})
		} else {
			this.coreSrvc.dbSrvc.updateRecord('transaction_log', record).then((updateSuccess) => {
				if (updateSuccess) {
					this.recordUpdated.emit(this.transactionId)
				} else {
					log('Error updating transaction')
					this.isUpdating = false
					this.cd.detectChanges()
				}
			})
		}
		return false
	}

	onCancel(): boolean {
		// this.location.back()
		this.editCancelled.emit(true)
		return false
	}

	toggleCheckbox(prop: string) {
		this.transForm.get(prop).setValue(!this.transForm.get(prop).value)
	}

	toggleStandaloneCheckbox(prop: string) {
		this[prop] = !this[prop]
	}

	get jobHasBeenDeleted(): boolean {
		// Check if job has been deleted
		const jobId = this.transForm.get('job_id').value
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)
		if (jobId && !job) {
			return true
		}
		return false
	}

	get scheduleHasBeenDeleted(): boolean {
		const schedId = this.transForm.get('schedule_recur_id').value
		const sched = this.coreSrvc.dbSrvc.schedulerSrvc.getScheduleForId(schedId)
		if (schedId && sched) {
			return true
		}
		return false
	}

	hasShiftLengthWarning(): boolean {
		const form = this.transForm.value

		const startMoment = moment(form.actual_start)
		const endMoment = moment(form.actual_end)

		if (endMoment.isBefore(startMoment)) {
			this.shiftLengthWarning = 'End Time is before Start Time'
			return true
		}
		if (startMoment.isValid() && endMoment.isValid()) {
			const start = startMoment.toDate().getTime()
			const end = endMoment.toDate().getTime()
			const duration = (end - start) / 1000

			const maxLength = 60 * 60 * this.jobLengthMax
			if (duration > maxLength) {
				this.shiftLengthWarning = `Shift is longer than ${this.jobLengthMax} hours`
				return true
			}
		}
		this.shiftLengthWarning = ''
		return false
	}

	breakLengthUpdated(value) {
		log('Transaction Got New Break Length', value)
		const duration = value.duration
		this.transForm.get('break_time').setValue(duration)
		this.calculateHoursWorked()
		const data = { transId: this.transactionId, duration: duration, initial: this.transaction.break_time }
		this.dataUpdated.emit(data)
	}

	calculateBreakTime() {
		const form = this.transForm.value
		const jobId = form.job_id
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)
		if (job) {
			const transactionBreakTime = this.transaction ? this.transaction.break_time : 0
			const empSetBreak = job.allow_breaktime
			const breakCount = this.transaction ? this.transaction.break_count : 0
			if (empSetBreak || breakCount > 0) {
				// Leave break_time as set in transaction
				this.transForm.get('break_time').setValue(transactionBreakTime)
				const breakTimeDuration = moment.duration(transactionBreakTime)
				this.breakLengthDisplay = moment.duration(breakTimeDuration).minutes().toString()
			} else {
				this.transForm.get('break_time').setValue(null)
				this.breakLengthDisplay = '0'
			}
		} else {
			this.breakLengthDisplay = '0'
		}
	}

	zeroSecondsAndMilliseconds(mom: moment.Moment) {
		mom.seconds(0)
		mom.milliseconds(0)
	}

	get hasJobDefinedBreakTime(): boolean {
		const form = this.transForm.value
		const jobId = form.job_id
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)
		if (job && job.break_time) {
			return true
		}
		return false
	}

	calculateHoursWorked() {
		const form = this.transForm.value

		const startMoment = moment(form.actual_start).startOf('minute')
		const endMoment = moment(form.actual_end).startOf('minute')
		this.zeroSecondsAndMilliseconds(startMoment)
		this.zeroSecondsAndMilliseconds(endMoment)

		if (this.allowHoursWorkedCalculation && startMoment.isValid() && endMoment.isValid()) {
			const shiftLength = this.currentShiftLength + ':00' // Add seconds to shift length
			const shiftduration = moment.duration(shiftLength)
			const breakTime = moment.duration(form.break_time)
			shiftduration.subtract(breakTime)
			let adjustedTime = DateTimeHelper.formatDurationInHoursAndMinutes('H:mm', shiftduration)
			if (/^\-/.test(adjustedTime)) {
				adjustedTime = '0:00'
			}
			this.transForm.get('adjustedTime').setValue(adjustedTime)
		}
	}

	clearEndTime(): boolean {
		this.transForm.get('actual_end').setValue(null)
		this.transForm.get('adjustedTime').setValue(null)
		this.currentShiftLength = ''
		this.updatePickerStartAtDates()
		// this.calculateHoursWorked()
		return false
	}

	updateFormForJobId(jobId, updateHoursWorked) {
		// this.cd.detectChanges() // This causes UI crash on Cititrends Any Emp
		log('updateFormForJobId', jobId)
		this.transForm.get('job_id').setValue(jobId)
		if (!jobId) {
			this.jobSiteTZShort = 'No Job Selected'
			return
		}
		const jobSite = this.coreSrvc.dbSrvc.jobSrvc.getJobSiteForJobId(jobId)
		if (!jobSite) {
			const defaultTimezone = this.defaultTimezone
			const tzShortString = this.coreSrvc.dbSrvc.settingSrvc.getTimezoneDisplayNameForZoneName(defaultTimezone)
			this.jobSiteTZShort = tzShortString
			return
		}
		const timezoneId = jobSite.timezone_id
		if (!timezoneId) {
			this.jobSiteTZShort = 'No Timezone'
			return
		}
		this.jobSiteTZShort = this.coreSrvc.dbSrvc.settingSrvc.getTimezoneDisplayNameForId(timezoneId)
		const timezone = this.coreSrvc.dbSrvc.settingSrvc.getTimezoneZoneNameForId(timezoneId)

		this.transForm.get('timezone').setValue(timezone)
		this.transForm.get('jobsite_id').setValue(jobSite.id)

		this.updateTimesForNewTimezone(timezone)
		this.calculateBreakTime()
		this.calculateHoursWorked()
	}

	updateTimesForNewTimezone(newTz) {
		log('Given TZ', newTz)
		const prevTz = this.previousTimezone
		if (prevTz === newTz) {
			log('No Change in TZ')
			return
		}

		const startTime = this.transForm.get('actual_start').value
		const startMom = moment(startTime)
		const endTime = this.transForm.get('actual_end').value
		const endMom = moment(endTime)

		// log('Previous/New TZ', prevTz, newTz)
		const nowTs = startMom.unix()
		const prevTzOffset = moment.tz.zone(prevTz).utcOffset(nowTs)
		const newTzOffset = moment.tz.zone(newTz).utcOffset(nowTs)
		const diffInMin = prevTzOffset - newTzOffset
		// log('Difference', diffInMin)

		if (startMom.isValid()) {
			startMom.add(diffInMin, 'minutes')
			this.transForm.get('actual_start').setValue(startMom.toDate())
		}
		if (endMom.isValid()) {
			endMom.add(diffInMin, 'minutes')
			this.transForm.get('actual_end').setValue(endMom.toDate())
		}

		this.previousTimezone = newTz
	}

	jobSiteForCurrentJob(): string {
		const jobId = this.transForm.value.job_id
		const jobSite = this.coreSrvc.dbSrvc.jobSrvc.getJobSiteForJobId(jobId)

		if (!jobSite) {
			return ''
		}

		const jobSiteName = jobSite.description

		if (jobSiteName) {
			return DisplayHelper.truncateString(jobSiteName, 30)
		}
		return ''
	}

	adjustStartTime(value): string {
		const mom = moment(value)
		if (!mom.isValid()) {
			this.adjustedStartTime = 'No Start'
			return
		}
		const timezone = this.transForm.value.timezone
		if (!timezone) {
			this.adjustedStartTime = 'No Timezone'
			return
		}
		this.adjustedStartTime = mom.tz(timezone).format('h:mm A')
	}

	adjustEndTime(value): void {}

	get isOngoing(): boolean {
		const actualStart = this.transForm.get('actual_start').value
		const actualEnd = this.transForm.get('actual_end').value
		if (!(actualStart && !actualEnd)) return false
		const actStartString = moment(actualStart).format('YYYY-MM-DDTHH:mm:ss')
		const tz = this.transForm.get('timezone').value
		const actStartMom = moment.tz(actStartString, 'YYYY-MM-DDTHH:mm:ss', tz)
		const cutoff = actStartMom.clone().add(this.jobLengthMax, 'hours')
		const now = moment()
		if (now.isAfter(actStartMom, 'seconds') && now.isBefore(cutoff, 'seconds')) return true
		return false
	}

	get isMissing(): boolean {
		const actualStart = this.transForm.get('actual_start').value
		const actualEnd = this.transForm.get('actual_end').value
		if (!actualStart) return false
		const cutoff = moment().subtract(this.jobLengthMax, 'hours')
		const startMom = moment(this.transForm.value.actual_start)
		if (!this.transForm.value.actual_end && startMom.isValid()) {
			const schedId = this.transForm.get('schedule_recur_id').value
			if (schedId) {
				const sched = this.coreSrvc.dbSrvc.schedulerSrvc.getScheduleForId(schedId)
				if (sched) {
					const startTime = sched.start_time
					const endTime = sched.end_time
					if (startTime !== endTime) {
						const jobDate = this.transForm.get('job_date').value
						const startString = jobDate + 'T' + startTime
						const endString = jobDate + 'T' + endTime
						const tz = this.transForm.get('timezone').value
						const schedStartMom = moment.tz(startString, 'YYYY-MM-DDTHH:mm:ss', tz)
						const schedEndMom = moment.tz(endString, 'YYYY-MM-DDTHH:mm:ss', tz)
						if (schedEndMom.isBefore(schedEndMom)) {
							schedEndMom.add(1, 'day')
						}
						const schedCutoff = moment.duration(this.checkInOutWindow).asMinutes()
						const diff = moment().diff(schedEndMom, 'minutes')
						if (diff > schedCutoff) {
							// log('Missing', this.job_description, diff)
							return true
						}
					} else if (startMom.isBefore(cutoff)) {
						return true
					}
				}
			} else {
				if (startMom.isBefore(cutoff)) {
					return true
				}
			}
		}
		return false
	}

	get isNoShow(): boolean {
		if (this.isNew) {
			return false
		}
		if (!this.transForm.value.actual_start) {
			return true
		}
		return false
	}

	displayShiftLength(): string {
		// this.calculateHoursWorked();
		const displayString = this.calculateShiftLength('H:mm')
		if (/^\-/.test(displayString)) {
			return 'Invalid'
		}
		if (displayString) {
			return displayString
		}
		return 'n/a'
	}

	displayBreakLength(): string {
		let displayString = '0'
		const breaktime = this.transForm.get('break_time').value
		if (breaktime) {
			const duration = moment.duration(breaktime)
			displayString = duration.asMinutes().toString()
		}
		return displayString
	}

	displayActualTimeWorkedDialog(): boolean {
		this.showActualTimeWorkedDialog = true
		return false
	}

	toggleNotesField(): boolean {
		this.showNotesField = !this.showNotesField
		return false
	}
	showAddressSection(): boolean {
		return !!this.transaction.geo_start_address || !!this.transaction.geo_end_address
	}
	formatAddress(addressString: string): string {
		return Helper.getStreetInfoFromAddress(addressString)
	}

	// Custom Form Validations

	getFormValidationErrors() {
		Object.keys(this.transForm.controls).forEach((key) => {
			const controlErrors: ValidationErrors = this.transForm.get(key).errors
			if (controlErrors != null) {
				Object.keys(controlErrors).forEach((keyError) => {
					console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError])
				})
			}
		})
	}

	invalidForm(): boolean {
		if (!this.shiftLengthValid()) {
			return true
		}
		if (this.jobHasBeenDeleted) {
			return true
		}
		if (!this.isEditable) {
			return true
		}
		if (this.isNew && !this.transForm.get('actual_start').value) return true

		if (this.hasJobDateError) return true

		// Shift length
		const shiftLengthWarning = this.shiftLengthWarning ?? ''
		if (shiftLengthWarning.includes('Shift is longer than')) return true
		return false
	}

	// Checks for a negative shift length
	shiftLengthValid(): boolean {
		if (/^\-/.test(this.currentShiftLength)) {
			return false
		}
		return true
	}

	refreshPublicLink() {
		this.newUuid = uuid()
		log('New UUID', this.newUuid)
	}

	toggleAdminNotesEdit() {
		this.isEditingAdminNotes = !this.isEditingAdminNotes
		if (this.isEditingAdminNotes) {
			setTimeout(() => {
				const elm = $('#admin-notes-edit-textarea')?.[0]
				FormHelper.textAreaAdjust(elm)
			}, 100)
		}
	}

	formatPayRateForUpdateRecord(prop: string) {
		const value = this.transForm.get(prop).value
		if (value) {
			return parseFloat(value)
		}
		return null
	}

	openBreakLengthDialog() {
		if (!this.transaction.actual_start) {
			this.coreSrvc.notifySrvc.notify('error', 'Not Supported', 'Editing breaks for a no show time entry is not supported.')
			return
		}
		this.coreSrvc.notifySrvc.notify('error', 'Not Supported', 'Breaks must now be managed from the Breaks column of the Time Entry table.')
		return
		// if (this.transaction?.employee_id === 0) {
		// 	this.coreSrvc.notifySrvc.notify('error', 'Not Supported', 'Editing breaks for a no show time entry is not supported.')
		// } else {
		// 	if (this.isNew) {
		// 		this.coreSrvc.notifySrvc.notify('error', 'Not Supported', 'You must first save this time entry before you can edit breaks.')
		// 	} else {
		// 		this.showBreakEditDialog = true
		// 	}
		// }
	}

	// Create record for submission to lambda

	private makeUpdateRecord(emptyTrans?: boolean): TransactionLogRecord {
		// emptyTrans is used for troubleshooting record

		const trans = this.transaction
		const record: TransactionLogRecord = new TransactionLogRecord()
		const form = this.transForm.value

		// Copy original information from transaction
		if (!emptyTrans) {
			for (const attr in trans) {
				if (trans.hasOwnProperty(attr)) {
					record[attr] = trans[attr]
				}
			}
		}

		const empIdFromForm = this.transForm.get('employee_id').value
		const jobIdFromForm = this.transForm.get('job_id').value
		const jobFromForm = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobIdFromForm)
		const siteIdFromForm = this.transForm.get('jobsite_id').value
		const tzFromForm = this.transForm.get('timezone').value

		// Check to see if normal transaction is being converted to travel
		// Is this really necessary? May be able to get origTransIsTravel and currentTrasIsTravel
		// if not performing this check
		if (!this.isNew) {
			const transJobId = this.transaction.job_id
			const origTransIsTravel = this.travelJobIds.includes(transJobId)
			const currentTransIsTravel = this.travelJobIds.includes(jobIdFromForm)
			if (currentTransIsTravel && !origTransIsTravel) {
				this.coreSrvc.notifySrvc.notify('error', 'Invalid Operation', 'A standard time entry cannot be converted into a travel time entry.')
				return null
			}
		}

		record.timezone = tzFromForm
		record.employee_id = empIdFromForm
		record.employee_first = this.transForm.get('employee_first').value
		record.employee_last = this.transForm.get('employee_last').value
		record.employee_name = this.transForm.get('employee_name').value
		record.employee_count = this.transForm.get('employee_count').value

		// Clear exception if indicated
		if (this.clearException) {
			record.exception = false
		}

		// If the original transaction job was an unassigned job, restore the exception
		if (jobFromForm && jobFromForm.description === 'UNASSIGNED JOB') {
			log('Restoring exception')
			if (trans && trans.exception_type) {
				record.exception = true
			}
		} else {
			log('Clearing exception')
			record.exception = false
		}

		// When shift is selected from picker, the schedule_log_id will be set. When clear button used, it will be cleared.

		const isShiftSchedule = this.transForm.get('schedule_log_id').value

		if (isShiftSchedule) {
			record.recur_emp_name = this.transForm.get('recur_emp_name').value
			record.recur_description = this.transForm.get('recur_description').value
			record.recurrence = this.transForm.get('recurrence').value
			record.start_time = this.transForm.get('start_time').value
			record.end_time = this.transForm.get('end_time').value
			record.employee_count = this.transForm.get('employee_count').value
			record.schedule_recur_id = this.transForm.get('schedule_recur_id').value
			record.schedule_log_id = this.transForm.get('schedule_log_id').value
			record.job_id = this.transForm.get('job_id').value
		} else {
			record.recur_emp_name = null
			record.recur_description = null
			record.recurrence = null
			record.start_time = '09:00:00'
			record.end_time = '09:00:00'
			record.employee_count = null
			record.schedule_recur_id = null
			record.schedule_log_id = null
			record.job_id = jobIdFromForm
		}

		// Set the current job site
		record.jobsite_id = siteIdFromForm

		// Get new start and end times from the form and format them
		// properly in the current timezone which is determined from
		// the current job id set in the form

		const newStartString = moment(form.actual_start).format('YYYY-MM-DD HH:mm')
		const startMoment = moment.tz(newStartString, 'YYYY-MM-DD HH:mm', tzFromForm)
		const newEndString = moment(form.actual_end).format('YYYY-MM-DD HH:mm')
		const endMoment = moment.tz(newEndString, 'YYYY-MM-DD HH:mm', tzFromForm)

		record.actual_start = startMoment.isValid() ? startMoment.toISOString() : null
		record.actual_end = endMoment.isValid() ? endMoment.toISOString() : null

		// Apply DST adjustment if actual_end falls within DST adjustment period and switch is enabled
		if (this.showDSTSwitch) {
			if (this.dstShift) {
				record.actual_end = endMoment.clone().add(1, 'hour').toISOString()
			}
		}

		record.job_date = this.transForm.get('job_date').value
		record.job_description = this.transForm.get('job_description').value

		if (form.adjustedTime) {
			record.time_worked = form.adjustedTime + ':00'
		} else {
			record.time_worked = null
		}

		if (!record.actual_end) {
			record.time_worked = null
		}

		record.notes = form.notes
		if (this.appendToAdminNotes) {
			const currentNotes = record.notes ? record.notes : ''
			const timezone = this.transForm.get('timezone').value
			const newNotes = TransactionHelper.appendToAdminNotes(this.coreSrvc.dbSrvc, timezone, currentNotes, this.appendToAdminNotes)
			record.notes = newNotes
		}
		record.geo_start_emp_note = form.geo_start_emp_note
		record.geo_end_emp_note = form.geo_end_emp_note
		record.emp_notes = form.emp_notes

		// Do we need this? No UI available for notifications so problably not
		const enableNotifications = this.transForm.get('enable_notifications').value
		record.enable_notifications = enableNotifications

		// Set whether this time entry is a travel job
		if (this.travelJobIds.includes(jobIdFromForm)) {
			record.travel_job = true
		} else {
			record.travel_job = false
		}

		// Update Client/Vendor information
		record.client_id = this.transForm.get('client_id').value
		record.vendor_id = this.transForm.get('vendor_id').value

		// Not sure why all the source options are being nulled out, probably because back end will recalc
		record.pay_rate = this.formatPayRateForUpdateRecord('pay_rate')
		record.client_pay_rate = this.formatPayRateForUpdateRecord('client_pay_rate')
		record.vendor_pay_rate = this.formatPayRateForUpdateRecord('vendor_pay_rate')
		record.pay_rate_source = null
		record.client_pay_rate_source = null
		record.vendor_pay_rate_source = null

		// Used to force an override if the schedule overlaps and they choose to override
		if (this.overlapOverride.allowOverride) {
			record['bypass_overlap'] = true
		}

		// Used to override a date lock exception
		if (this.dateLockOverride.allowOverride) {
			record['date_lock_override'] = true
		}

		// Update Check In/Out Notes and Photos

		const inPhotoList = this.inFileUploadManager.processedFiles
		if (inPhotoList.length > 0) {
			const inImageListResult = { files: inPhotoList }
			record.geo_start_images_json = JSON.stringify(inImageListResult)
		} else {
			record.geo_start_images_json = null
		}
		const outPhotoList = this.outFileUploadManager.processedFiles
		if (outPhotoList.length > 0) {
			const outImageListResult = { files: outPhotoList }
			record.geo_end_images_json = JSON.stringify(outImageListResult)
		} else {
			record.geo_end_images_json = null
		}

		// Set shift summary report uuid
		record.uuid = this.isNew ? null : this.newUuid ? this.newUuid : this.transaction.uuid

		return record
	}

	timeWorked(format: any, duration: any) {
		return DateTimeHelper.formatDurationInHoursAndMinutes(format, duration)
	}

	// Transaction must be loaded before these next 2 methods can be called
	setupEmployeeDropdown() {
		const permissions = this.accessHelper.getPermissionsFor('transaction')
		const isRestricted = permissions.isSelectorRestrictedFor(SelectorPermissionName.employee)
		const isManager = this.coreSrvc.dbSrvc.settingSrvc.isUserAManager()

		const dropdownData = this.coreSrvc.dbSrvc.empSrvc.getEmployeeDropdown(this.coreSrvc.dbSrvc, isRestricted, isManager, [])
		// dropdown.unshift({ label: 'Any Employee', value: 0 })
		const dropdown = this.isNew ? dropdownData.filter((si) => si.data.active) : dropdownData
		dropdown.unshift({ label: 'Select an Employee', value: null, data: null })
		this.employeesDropdown = dropdown
		log('Employee Dropdown', dropdown)
	}

	setupJobsDropdown() {
		const includeIds = this.transaction ? [this.transaction.job_id] : []
		const permissions = this.accessHelper.getPermissionsFor('transaction')
		const isRestricted = false // permissions.isSelectorRestrictedFor(SelectorPermissionName.employee)
		const isManager = this.coreSrvc.dbSrvc.settingSrvc.isUserAManager()

		const dropdownData = this.coreSrvc.dbSrvc.jobSrvc.getJobDropdown(this.coreSrvc.dbSrvc, true, isRestricted, isManager, includeIds)
		const dropdown = this.isNew ? dropdownData.filter((si) => si.data.active) : dropdownData
		const unassignedJob = this.coreSrvc.dbSrvc.jobSrvc.getUnassignedJob()
		dropdown.unshift({ label: 'Select a Job', value: null, data: null })
		// dropdown.unshift({ label: '- Unassigned Job -', value: unassignedJob.id, data: unassignedJob })
		this.jobsDropdown = dropdown
		log('Job Dropdown', dropdown)
	}

	employeeDropdownChanged() {
		// If this was a no show, change the original schedule info to display new emp and filling in
		if (this.transaction?.employee_id === 0) {
			const empName = this.currentEmployeeName
			const count = this.transaction?.employee_count ?? 1
			if (empName) {
				this.currentShiftInfo.empName = empName
				this.currentShiftInfo.fillingIn = 'Filling in for Any Employee'
			} else {
				this.currentShiftInfo.empName = `Any Employee (${count})`
				this.currentShiftInfo.fillingIn = ''
			}
		}
		const empIdFromForm = this.transForm.get('employee_id').value
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empIdFromForm)
		if (emp) {
			const empName = emp.first + ' ' + emp.last
			this.transForm.get('employee_first').setValue(emp.first)
			this.transForm.get('employee_last').setValue(emp.last)
			this.transForm.get('employee_name').setValue(empName)
		}

		// Make a transaction with new values to create the schdule info if needed
		const hasShiftInfo = !!this.currentShiftInfo
		if (hasShiftInfo) {
			const transData = this.makeUpdateRecord()
			const record = new TransactionLogRecord(transData)

			this.currentShiftInfo = new CurrentShiftInfo(this.coreSrvc.dbSrvc, record)
		}
	}

	resetClientVendorIfJobChanged() {
		const origiJobId = this.transaction?.job_id
		const newJobId = this.transForm.get('job_id').value
		if (origiJobId !== newJobId) {
			this.transForm.get('client_id').setValue(null)
			this.transForm.get('vendor_id').setValue(null)
		}
	}

	jobDropdownChanged() {
		// log('Shift based job dropdown changed')
		// Copied and modified from the updateFormForSyntheticId

		const jobId = this.transForm.get('job_id').value
		if (!jobId) return

		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)
		const siteId = job.location_id
		const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(siteId)
		const tzId = site.timezone_id
		const timezone = this.coreSrvc.dbSrvc.settingSrvc.getTimezoneZoneNameForId(tzId)

		this.transForm.get('job_id').setValue(jobId)
		this.transForm.get('jobsite_id').setValue(siteId)
		this.transForm.get('schedule_recur_id').setValue(null)

		// Clear client and vendor settings so they will be updated
		this.resetClientVendorIfJobChanged()

		this.transForm.get('timezone').setValue(timezone)
		this.updateTimesForNewTimezone(timezone)
		this.isJobScheduleReferenceValid = true

		if (jobId && this.transaction?.job_id === jobId) {
			try {
				this.currentShiftInfo = new CurrentShiftInfo(this.coreSrvc.dbSrvc, this.transaction)
			} catch {
				this.currentShiftInfo = null
			}
		} else {
			this.currentShiftInfo = null
		}
	}

	get currentEmployeeName(): string {
		return this.transForm.get('employee_name').value
	}

	truncateForDisplay(str: string) {
		return DisplayHelper.truncateForDropdown(str)
	}

	// START SHIFT PICKING METHODS
	public startShiftPicker() {
		const isNoShow = !!this.transaction && !this.transaction.actual_start && !this.transaction.actual_end
		if (isNoShow) {
			this.coreSrvc.notifySrvc.notify('info', 'Not Permitted', 'This action is not permitted for no show time entries.', 5)
			return
		}

		const hasEmp = !!this.transForm.get('employee_id').value
		const hasJob = !!this.transForm.get('job_id').value
		const hasJobDate = !!this.transForm.get('job_date').value

		// if (!hasEmp) {
		// 	this.coreSrvc.notifySrvc.notify('error', 'Missing Info', 'You must first set an employee before you can select a shift.')
		// 	return
		// }

		if (!hasJobDate) {
			this.coreSrvc.notifySrvc.notify('error', 'Missing Info', 'You must first set a job date before you can select a shift.')
			return
		}

		this.dialogManager.pushState()
		this.isPickingShift = true
	}

	public cancelShiftPicker() {
		this.dialogManager.popStateAndApply()
		this.dialogManager.scrollToTop()
		this.isPickingShift = false
	}

	public clearSelectedShift() {
		log('clearSeletedShift')
		const isNoShow = !!this.transaction && !this.transaction.actual_start && !this.transaction.actual_end
		if (isNoShow) {
			this.coreSrvc.notifySrvc.notify('info', 'Not Permitted', 'This action is not permitted for no show time entries.', 5)
			return
		}
		this.currentShiftInfo = null
		this.transaction
		this.transForm.get('schedule_recur_id').setValue(null)
		this.transForm.get('schedule_log_id').setValue(null)
		// this.transForm.get('synthetic_id').setValue(null)
	}

	public shiftSelected(schedLog: ScheduleLogRecord) {
		log('Update using schedule log record', schedLog)

		// Setup transaction form from schedule log
		this.coreSrvc.notifySrvc.notify('info', 'Shift Updated', 'The shift has been updated.', 2)

		const schedStart = schedLog.effectiveStartTime()
		const schedEnd = schedLog.effectiveEndTime()
		const schedEmpCount = schedLog.effectiveEmpCount()

		// Set the form values that changed based on the schedule log
		this.transForm.get('schedule_log_id').setValue(schedLog.id)
		this.transForm.get('schedule_recur_id').setValue(schedLog.schedule_id)
		this.transForm.get('recur_emp_name').setValue(schedLog.employee_name)
		this.transForm.get('recurrence').setValue(schedLog.recurrence)
		this.transForm.get('start_time').setValue(schedStart)
		this.transForm.get('end_time').setValue(schedEnd)
		this.transForm.get('employee_count').setValue(schedEmpCount)
		this.transForm.get('job_id').setValue(schedLog.job_id)
		this.transForm.get('job_description').setValue(schedLog.job_description)
		this.transForm.get('jobsite_id').setValue(schedLog.jobsite_id)
		this.transForm.get('job_date').setValue(schedLog.job_date)

		// Clear client and vendor settings so they will be updated
		this.resetClientVendorIfJobChanged()

		const jobDate = schedLog.job_date
		this.customJobDate = moment(jobDate).startOf('day').toDate()

		// Update timezone if necessary
		const timezone = schedLog.timezone
		this.transForm.get('timezone').setValue(timezone)
		this.updateTimesForNewTimezone(timezone)

		// Make a transaction with new values to create the schdule info
		const transData = this.makeUpdateRecord()
		const record = new TransactionLogRecord(transData)

		this.currentShiftInfo = new CurrentShiftInfo(this.coreSrvc.dbSrvc, record)

		// Restore dialog state to form edit
		this.dialogManager.popStateAndApply()
		this.dialogManager.scrollToTop()
		this.isPickingShift = false
	}
	// END SHIFT PICKING METHODS

	setPickerDate(prop: string, date: Date) {
		log('Setting', prop, date)
		if (prop === 'actual_start') this.originalStartTimeModified = true
		if (prop === 'actual_end') this.originalEndTimeModified = true
		this.transForm.get(prop).setValue(date)
		this.afterPickerClosed()
	}

	startEndDateTimeFormatter(date: Date) {
		const format = this.is12HourFormat ? 'ddd MMM Do [at] h:mm a' : 'ddd MMM Do [at] HH:mm'
		return DateTimeHelper.formatDate(date, format)
	}

	// DEPRECATED - USED FOR TESTING
	// currentTransactionInfo() {
	// 	const type = this.transaction.schedule_recur_id ? 'SCHED' : 'JOB'
	// 	const html = TransTableFormatter.jobScheduleInfo(this.coreSrvc.dbSrvc, this.transaction, type, true)
	// 	return html
	// }

	// Used for troubleshooting
	troubleshootRecord() {
		if (this.invalidForm()) {
			this.updateRecord = 'Form is not valid'
			return
		}
		const record: any = this.makeUpdateRecord(true)
		for (const attr in record) {
			if (!record[attr]) {
				delete record[attr]
			}
		}
		const empId = record.employee_id
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)
		const siteId = record.jobsite_id
		const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(siteId)
		const schedId = record.schedule_recur_id
		const sched = this.coreSrvc.dbSrvc.schedulerSrvc.getScheduleForId(schedId)
		const schedJob = sched ? this.coreSrvc.dbSrvc.jobSrvc.getJobById(sched.job_id) : null
		const schedEmp = sched ? this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(sched.employee_id) : null
		const jobId = record.job_id
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)

		record.employee_id = emp ? `${empId} (${emp.name})` : `${empId} (Emp Not Found)`
		record.job_id = job ? `${jobId} (${job.description})` : `${jobId} (Job Not Found)`
		record.jobsite_id = job ? `${siteId} (${site.description})` : `${siteId} (Site Not Found)`
		record.schedule_recur_id = sched ? `${schedId} (${schedJob ? schedJob.description : 'Job Not Found'})` : schedId
		this.updateRecord = record
	}

	getTransactionLink() {
		const protocol = window.location.protocol
		const host = window.location.host
		const path = this.coreSrvc.dbSrvc.tranSrvc.getTransactionLinkPath(this.transactionId)
		return `${protocol}//${host}/#${path}`
	}

	copyTransactionLink() {
		const url = this.getTransactionLink()
		navigator.clipboard.writeText(url)
		this.coreSrvc.notifySrvc.notify('success', 'Link Copied', 'The link for this time entry has been copied to your clipboard.')
	}

	emailSupport() {
		const event = new ContactSupportEvent('Time Entry', 'transaction_log', this.transactionId)
		this.coreSrvc.eventSrvc.contactSupportEvent.next(event)
		// const url = this.getTransactionLink()
		// this.coreSrvc.dbSrvc.settingSrvc.emailSupportForTimeEntry(url)
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)

		const jobDateHelpMsg = this.customJobDate
			? `Job Date should usually match the date specified in Start Time. However, for payroll and reporting, you may wish to assign a job date that differers from the actual shift.`
			: `Please select the date this shift takes place. You will then be able to set the shift start and end times.`
		switch (trigger) {
			case 'jobDate':
				help.header = 'Job Date'
				help.message = jobDateHelpMsg
				break
			case 'enableNotifications':
				help.header = 'Enable Notifications'
				help.message = `This option allows you to stop and start notifications for this transaction.`
				break
			case 'empPayRate':
				help.header = 'Pay Rate'
				help.message =
					'Pay rates may come from an employee record or the pay rate table. You may also manually override a pay rate for a given time entry'
				break
			case 'clientPayRate':
				help.header = 'Bill Rate'
				help.message =
					'Bill rates may come from a client record or the bill rate table. You may also manually override a bill rate for a given time entry'
				break
			case 'vendorPayRate':
				help.header = 'Pay Rate'
				help.message =
					'Pay rates may come from a vendor record or the pay rate table. You may also manually override a pay rate for a given time entry'
				break
			case 'clients':
				help.header = 'Clients'
				help.message = `You may assign a client to this time entry for certain reporting and notification purposes.`
				break
			case 'vendors':
				help.header = 'Vendors'
				help.message = `You may assign a vendor to this time entry for certain reporting and notification purposes.`
				break
			case 'useEmpFromTimeEntry':
				help.header = 'Schedule Mismatch'
				help.message =
					'When the employee for a time entry does not match the selected schedule, you can use the current schedule information or override with the employee selected in the time entry.'
				break
			case 'useSchedFromTimeEntry':
				help.header = 'Schedule Mismatch'
				help.message =
					'When information in a time entry does not match the selected schedule, you can use the current schedule informaiton or override with information from the time entry. A mismatch may occur because of shift reassignment or the original schedule may have changed. Generally when there is a mismatch, you will want to use the schedule information from the time entry. This is the default.'
				break
			case 'unpaidBreaks':
				help.header = 'Unpaid Breaks'
				help.message = `Total number of minutes of unpaid break time taken during this shift. Breaks may be edited from the Breaks column of the Time Entry table.`
				break
			case 'payableHours':
				help.header = 'Payable Hours'
				help.message = `Payable hours is used for payroll and may be different from Shift Length if the system has calculated adjustments for automatic rounding or employee breaks.`
				break
			case 'dstShift':
				help.header = 'DST Adjustment'
				help.message = `During the transition from daylight saving time, when clocks are set back, the end time for a shift may be ambiguous. This switch allows you to manually adjust for the extra hour gained during the transition from DST.`
				break
			case 'shiftLength':
				help.header = 'Shift Length'
				help.message = `Shift length shows the duration from the start of the shift to the end of the shift and does not take into account any breaks.`
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = 'No help information for this topic is currently available.'
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}

class CurrentShiftInfo {
	dbSrvc: DatabaseService

	jobName: string
	empName: string
	empCount: number
	recurName: string
	fillingIn: string
	shift: string
	summary: string
	description: string
	oneTimeDate: string

	constructor(dbSrvc: DatabaseService, trans: TransactionLogRecord) {
		this.dbSrvc = dbSrvc
		this.setupFromTransaction(dbSrvc, trans)
	}

	setupFromTransaction(dbSrvc: DatabaseService, trans: TransactionLogRecord) {
		const info = TransTableFormatter.makeJobSchedulePseudoSchedule(dbSrvc, trans)
		this.jobName = info.data.description
		this.empName = info.data.empName

		const isMultiSched = trans.recur_emp_name === 'Multi Employee'
		const empCount = trans.employee_count ?? 1
		const sched = dbSrvc.schedulerSrvc.getScheduleForId(trans.schedule_recur_id)
		const scheduledEmpIds = sched?.employee_ids ?? []

		const jobName = info.label
		const data = info.data
		const empName = data.empName
		const recurName = data.recurName
		const days = data.days
		const shiftInfo = data.shiftInfo
		const start = shiftInfo.start
		const end = shiftInfo.end
		const scheduled = start === end ? `Anytime Job` : `${shiftInfo.start} - ${shiftInfo.end}`
		const isYearlyOrMonthly = data.freq === 0 || data.freq === 1
		const tzAbrev = shiftInfo.abrv

		const fillingInMsg = isMultiSched && sched && scheduledEmpIds.includes(trans.employee_id) ? '' : `Filling in for ${recurName}`
		this.jobName = jobName
		this.empName = empName
		this.empCount = empCount
		this.fillingIn = empName !== recurName ? fillingInMsg : ''
		this.recurName = recurName
		this.shift = `${scheduled} ${tzAbrev}`
		this.summary = isYearlyOrMonthly ? '' : days
		this.oneTimeDate = isYearlyOrMonthly ? data.freqString : ''
		if (!this.summary && !this.oneTimeDate) {
			this.oneTimeDate = trans.job_date
		}
	}
}
