import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { DateTimeHelper, DropdownHelper, DropdownHelperConfig, log } from '@app/helpers'
import {
	CommLogViewTabState,
	CommLogViewManager,
	IncidentLogViewManager,
	SectionSwitcherConfig,
	SectionSwitcherTab,
	ToursViewManager,
} from '@app/models'
import { CoreService } from '@app/services'
import { SelectItem } from 'primeng/api'

import { TourTableComponent } from './tour-table/tour-table.component'

import moment from 'moment-timezone'

@Component({
    selector: 'app-tours',
    templateUrl: './tours.component.html',
    styleUrls: ['./tours.component.scss'],
    standalone: false
})
export class ToursComponent implements OnInit, AfterViewInit {
	switcherConfig = new SectionSwitcherConfig()

	// accessHelper: AccessHelper
	showSectionHelp = false

	jobOptions: Array<SelectItem> = []

	@ViewChild('tourTable') tourTable: TourTableComponent

	constructor(
		private router: Router,
		private coreSrvc: CoreService,
	) {
		this.setupJobMultiSelect()
		this.setupAccessPermissions()
	}

	get viewManager(): ToursViewManager {
		return this.coreSrvc.dbSrvc.tourSrvc.viewManager
	}

	get devDetect() {
		return this.coreSrvc.devDetect
	}

	get lastUpdated(): string {
		const date = this.coreSrvc.dbSrvc.tourSrvc.lastUpdated
		return `Updated ${DateTimeHelper.formatTimeFromDate(date)}`
	}

	ngOnInit() {}
	ngAfterViewInit() {
		scrollTo(0, 0)
	}

	hideDtTimePicker() {
		$('body').addClass('hide-dt-time-picker')
	}
	showDtTimePicker() {
		$('body').removeClass('hide-dt-time-picker')
	}

	setupAccessPermissions() {
		// this.accessHelper = new AccessHelper(this.coreSrvc.dbSrvc, 'CALLLOG')
	}
	canPerformAction(action) {
		return true
		// return this.accessHelper.canPerformAction(action, false)
	}

	toggleSectionHelp() {
		this.showSectionHelp = !this.showSectionHelp
		this.updateFixedHeader()
	}

	updateFixedHeader() {
		// Update fixed headers for any tables this section has
		setTimeout(() => {
			const table: any = $('#tourTable').DataTable()
			table?.fixedHeader.adjust()
		}, 100)
	}

	public fetchAndReload() {
		this.tourTable?.fetchAndReload()
	}

	createRecord() {
		this.tourTable?.editRecord(null)
	}

	public openPrefsDialog() {
		this.tourTable?.openPrefsDialog()
	}

	private canAccessAgentsComponent() {
		// const permissions = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAccessPermissions().getUserPermissionsFor('incident_log')
	}

	private setupJobMultiSelect() {
		const permission = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAccessPermissions().getUserPermissionsFor('schedule_recur')
		const config = new DropdownHelperConfig(this.coreSrvc.dbSrvc, 'MULTISELECT')
		config.isRestricted = !permission.access.read
		const dropdownHelper = new DropdownHelper(config)
		const options = dropdownHelper.buildJobMenuOptions()
		this.jobOptions = options
	}

	public clearMenu() {
		this.viewManager.selectedJobIds = []
	}
}
