import { Component, Input, OnInit } from '@angular/core'
import { DateTimeHelper, log } from '@app/helpers'
import { BillingHistory } from '@app/models'
import { CoreService } from '@app/services'

import moment from 'moment-timezone'

@Component({
    selector: 'app-billing-log',
    templateUrl: './billing-log.component.html',
    styleUrl: './billing-log.component.scss',
    standalone: false
})
export class BillingLogComponent implements OnInit {
	@Input() billingHistory: Array<BillingHistory> = []

	isFetchingLink = false
	historyAction = { notes: null, showNotesDialog: false }

	currencySymbol = '$'

	cols = [
		{ field: 'dateInv', header: 'Date / Invoice' },
		{ field: 'amount', header: 'Amount' },
		{ field: 'paid', header: 'Paid On' },
	]

	migrationAlert = 'The following table contains historical billing records.'

	constructor(private coreSrvc: CoreService) {
		this.currencySymbol = this.coreSrvc.dbSrvc.settingSrvc.getCompany().currencySymbol()
	}

	ngOnInit(): void {
		const firstRecord = this.billingHistory[0]
		const recordDate = firstRecord?.date
		if (recordDate) {
			const formattedDate = moment(recordDate).format('MMM. Do, YYYY')
			this.migrationAlert += ` Please use the <b>Payment Portal</b> to view or manage any billing events occurring after <b>${formattedDate}</b>.`
		}
	}

	get isStripeCustomer(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.isStripeCustomer()
	}

	public billingAmountFormatter(rec: BillingHistory): string {
		const amount = rec.amount ? rec.amount : 0.0
		return amount.toFixed(2)
	}

	public showBillingNotes(id) {
		log('Show note for', id)
		const record = this.billingHistory.find((rec) => rec.id === id)
		this.historyAction.notes = record.notes
		this.historyAction.showNotesDialog = true
		log('Record', record)
	}

	public openBillHtml(id) {
		const record = this.billingHistory.find((rec) => rec.id === id)
		const billHtml = record.bill_html

		if (billHtml) {
			const headHtml = `
		<head>
			<title>Billing Statement</title>
				<style>
				body { font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 1.2em; padding: 5px 15px; }
				pre {  font-family: "Lucida Console", Monaco, monospace; font-size: 1rem; }
				</style>
		</head>`

			const html = `<html>${headHtml}<body>${billHtml}</body></html>`
			const blob = new Blob([html], { type: 'text/html' })

			// Create a URL for the Blob
			const blobUrl = URL.createObjectURL(blob)

			// Open a new window and set its location to the Blob URL
			const newWindow = window.open()
			newWindow.location.href = blobUrl
		}
	}

	openInvoice(id: number) {
		const record = this.billingHistory.find((rec) => rec.id === id)
		const url = record.invoice_url
		log('Invoice URL', url)
		if (url) {
			window.open(record.invoice_url, '_blank')
		}
	}

	openReport(id: number) {
		const record = this.billingHistory.find((rec) => rec.id === id)
		const url = record.report_url
		log('Report URL', url)
		if (url) {
			window.open(record.report_url, '_blank')
		}
	}

	manageBillingBtnClicked() {
		this.isFetchingLink = true
		this.coreSrvc.dbSrvc.billSrvc.stripeGetPortalSession().then((result) => {
			// Get the URL from the result
			const url = result.data.url

			// Show the loading spinner
			const element = $('#loading-spinner')
			element.css({ opacity: 1, display: 'block' })

			// Setup the redirect
			setTimeout(() => {
				window.location.href = url

				// Trigger an app reload
				setTimeout(() => {
					this.coreSrvc.dbSrvc.settingSrvc.reloadApplication()
					this.isFetchingLink = false
				}, 1200)
			}, 300)
		})
	}
}
