import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { log } from '@app/helpers'
import { EmployeeChecklistSearchFilter } from '@app/models'

import _ from 'lodash'

@Component({
    selector: 'app-employee-checklist-search-filter',
    templateUrl: './search-filter.component.html',
    styleUrls: ['./search-filter.component.scss'],
    standalone: false
})
export class EmployeeChecklistSearchFilterComponent implements OnInit {
	@Input() recordCount = 100
	@Input() searchFilter = new EmployeeChecklistSearchFilter()
	@Output() applyFilter = new EventEmitter<EmployeeChecklistSearchFilter>()

	debounceSearch = _.debounce(this.performSearchFromDebounce, this.debounceSearchDelay)

	get debounceSearchDelay(): number {
		const computeFactor = Math.ceil((this.recordCount + 100) * 0.5)
		return computeFactor < 500 ? computeFactor : 500
	}
	constructor() {}

	ngOnInit(): void {}

	private performSearchFromDebounce(event) {
		log('performSearchFromDebounce', event)
		const text = event?.target?.value || ''
		this.searchFilter.filterText = text
		this.applySearchFilter()
	}

	public clearFilter() {
		this.searchFilter.filterText = null
		this.applySearchFilter()
	}

	private applySearchFilter() {
		this.applyFilter.emit(this.searchFilter)
	}
}
