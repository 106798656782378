import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { DateTimeHelper } from '@app/helpers'
import { Global, StationViewManager } from '@app/models'
import { DatabaseService } from '@app/services'
import { StationTableComponent } from './station-table/station-table.component'
import { environment } from '@env/environment'

@Component({
    selector: 'app-station',
    templateUrl: './station.component.html',
    styleUrls: ['./station.component.scss'],
    standalone: false
})
export class StationComponent implements OnInit, AfterViewInit {
	showMoreInfo = false
	@ViewChild('stationTable') stationTable: StationTableComponent

	constructor(private dbSrvc: DatabaseService) {
		this.setupAccessPermissions()
	}

	get appUrl(): string {
		const customDomain = Global.coreSrvc.dbSrvc.settingSrvc.getCompany()?.emp_app_domain
		const empAppDdomain = customDomain ?? environment.empAppDomain
		const domain = window.location.origin.includes('localhost') ? 'http://localhost:4500' : 'https://' + empAppDdomain
		return domain
	}

	get stationUrl(): string {
		const customDomain = Global.coreSrvc.dbSrvc.settingSrvc.getCompany()?.emp_app_domain
		const empAppDdomain = customDomain ?? environment.empAppDomain
		const domain = window.location.origin.includes('localhost') ? 'http://localhost:4500' : 'https://' + empAppDdomain

		const url = `${domain}/#/station/setup`
		return url
	}

	get viewManager(): StationViewManager {
		return this.dbSrvc.stationSrvc.viewManager
	}

	get lastUpdated(): string {
		const date = this.dbSrvc.stationSrvc.lastUpdated
		return `Updated ${DateTimeHelper.formatTimeFromDate(date)}`
	}

	ngOnInit() {}
	ngAfterViewInit() {
		scrollTo(0, 0)
	}

	private setupAccessPermissions(): void {
		// this.accessHelper = new AccessHelper(this.dbSrvc, 'station')
	}

	canPerformAction(action) {
		return true // this.accessHelper.canPerformAction(action, false)
	}

	toggleTooltips() {
		this.showMoreInfo = !this.showMoreInfo
		this.updateFixedHeader()
	}

	updateFixedHeader() {
		setTimeout(() => {
			const table: any = $('#stationTable').DataTable()
			table.fixedHeader.adjust()
		}, 100)
	}

	reloadData() {
		this.stationTable?.fetchAndReloadData()
	}

	createRecord() {
		this.stationTable.createRecord()
	}

	openPrefsDialog() {
		this.stationTable.openPrefsDialog()
	}
}
