import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { DateTimeHelper, TransTableFormatter, log } from '@app/helpers'
import { DataAccessRequest, DialogManager, IDataAccessLambdaResult, IScheduleOptionsDayOption, TransactionLogRecord } from '@app/models'
import { ScheduleLogRecord } from '@app/models/schedule-log'
import { CoreService } from '@app/services'

import moment from 'moment-timezone'
import _ from 'lodash'
import { RRuleSet, rrulestr } from 'rrule'
import { SelectItem } from 'primeng/api'

@Component({
    selector: 'app-shift-log-picker',
    templateUrl: './shift-log-picker.component.html',
    styleUrls: ['./shift-log-picker.component.scss'],
    standalone: false
})
export class ShiftLogPickerComponent implements OnInit, AfterViewInit, AfterContentInit {
	@Input() jobId: number = null
	@Input() empId: number = null
	@Input() jobDate: string = null
	@Input() actualStart: string = null
	@Input() actualEnd: string = null
	@Input() timezone: string = null
	@Input() trans: TransactionLogRecord = null
	@Input() schedLogId: number = null
	@Input() empName: string = null
	@Input() jobName: string = null

	@Input() dialogManager: DialogManager
	@Output() closePicker = new EventEmitter<boolean>()
	@Output() shiftSelected = new EventEmitter<ScheduleLogRecord>()

	transJobDate = ''
	transJobName = ''
	transActualStart = ''
	transActualEnd = ''
	timezoneAbrev = ''

	isNoShow = false
	checkInTime = ''
	checkOutTime = ''

	isLoading = true
	schedLogCards: Array<ScheduleLogCard> = []

	selectedCard: ScheduleLogCard = null

	pickerDate: Date = null
	datePickerFormat = 'ddd. MMM Do, YYYY'
	actualDateTimeFormat = DateTimeHelper.format12Hour ? 'h:mm a z ' : 'HH:mm z'

	jobOptions: SelectItem[]
	empOptions: SelectItem[]

	fetchJobIds: Array<number> = []
	fetchEmpIds: Array<number> = []

	filterView: 'EMP' | 'JOB' | 'ALL' = 'ALL'

	filterInput = ''
	debounceSearch = _.debounce(this.performSearch, 250)

	constructor(private coreSrvc: CoreService) {}

	get filteredSchedLogCards(): Array<ScheduleLogCard> {
		switch (this.filterView) {
			case 'ALL':
				return this.schedLogCards
			case 'EMP':
				return this.schedLogCards.filter((card) => card.schedLog.effectiveEmpId() === this.empId)
			case 'JOB':
				return this.schedLogCards.filter((card) => card.schedLog.job_id === this.jobId)
		}
	}

	get displayCount(): number {
		return this.filteredSchedLogCards.filter((card) => card.matchesFilter).length
	}

	get hasShiftsMsg(): string {
		const recordCount = this.schedLogCards.length
		const displayCount = this.displayCount

		// return `${count} shift${count === 1 ? '' : 's'} found on ${this.formatViewDate(this.jobDate)}`

		return recordCount === displayCount
			? `${displayCount} shift${displayCount === 1 ? '' : 's'} found`
			: `showing ${displayCount} of ${recordCount} shift${recordCount === 1 ? '' : 's'} found`
	}

	get empNameLabel(): string {
		return this.empName || '< no employee specified >'
	}

	get jobNameLabel(): string {
		return this.jobName || '< no job specified >'
	}

	get noShiftsMsg(): string {
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(this.jobId)
		const date = moment(this.jobDate).format(this.datePickerFormat)
		// return `No shifts found on ${this.formatViewDate(this.jobDate)}`
		return `No shifts found`
	}

	get canGoBack(): boolean {
		const transJobDate = moment(this.transJobDate)
		const currentViewDate = moment(this.jobDate)
		const diff = transJobDate.diff(currentViewDate, 'days')
		log('back diff', diff)
		return diff < 1
	}

	get canGoForward(): boolean {
		const transJobDate = moment(this.transJobDate)
		const currentViewDate = moment(this.jobDate)
		const diff = currentViewDate.diff(transJobDate, 'days')
		log('forward diff', diff)
		return diff < 1
	}

	public ngOnInit(): void {
		this.pickerDate = moment(this.jobDate).toDate()
		this.transJobDate = this.jobDate
		this.transJobName = this.coreSrvc.dbSrvc.jobSrvc.getJobById(this.jobId)?.description

		// Setup actual start and end times
		// We aren't using timezone directly in formatter because actual start and end are
		// already converted to the local time for the current selected job

		this.transActualStart = this.actualStart ? moment(this.actualStart).format(this.actualDateTimeFormat) : null
		this.transActualEnd = this.actualEnd ? moment(this.actualEnd).format(this.actualDateTimeFormat) : null
		this.timezoneAbrev = moment().tz(this.timezone).format('z')

		this.isNoShow = !this.transActualStart
		this.checkInTime = this.isNoShow ? 'No Show' : this.transActualStart ? this.transActualStart : 'Not Set'
		this.checkOutTime = this.isNoShow ? 'No Show' : this.transActualEnd ? this.transActualEnd : 'Not Set'

		this.fetchJobIds = [this.jobId]
		this.fetchEmpIds = [this.empId]
	}

	public ngAfterViewInit(): void {
		// setTimeout(() => {
		// 	this.setupDialogManager()
		// 	this.loadData()
		// }, 50)
	}

	ngAfterContentInit() {
		setTimeout(() => {
			this.setupDialogManager()
			this.loadData()
		}, 50)
	}

	private setupDialogManager() {
		log('setupDialogManager')
		this.dialogManager.headerLabel = 'Pick Shift'
		this.dialogManager.submitBtnLabel = 'Update'
		this.dialogManager.cancelBtnLabel = 'Cancel'
		this.dialogManager.isCancelBtnVisble = false
		this.dialogManager.isBackBtnVisible = true
		this.dialogManager.submitBtnAction = () => this.saveBtnClicked()
		this.dialogManager.backBtnAction = () => this.cancelBtnClicked()
		this.dialogManager.cancelBtnAction = () => this.cancelBtnClicked()
		this.dialogManager.canSubmit = () => this.isFormValid()
	}

	private loadData() {
		log('XXXXXXXXX - loadData - XXXXXXXXXXX')
		this.isLoading = true
		const startDate = this.jobDate
		const endDate = moment(startDate).add(1, 'day').format('YYYY-MM-DD')
		const range = { start: startDate, end: endDate }
		const selectedJobIds = [] // this.fetchEmpIds
		const selectedEmpIds = [] // this.fetchJobIds
		const showOpenShifts = true

		// Perform load only when date range provided
		if (range.start && range.end) {
			const empIds = this.coreSrvc.dbSrvc.empSrvc.getAllEmployees().map((emp) => emp.id)
			empIds.unshift(0)
			const requestOptions = { job_ids: selectedJobIds, start_date: range.start, end_date: range.end, open_shifts: true }
			log('Request Options', requestOptions)
			const request = new DataAccessRequest('schedule_log', null, requestOptions)
			this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
				log('Schedule Log Fetch Result', result)
				this.processResult(result)
			})
		}
	}

	private processResult(result: IDataAccessLambdaResult) {
		log('processResult', result)
		const dayOptions = this.coreSrvc.dbSrvc.settingSrvc.getWkstRelativeDayOptions()
		const data = result.data as Array<ScheduleLogRecord>
		const records = data.map((rec) => new ScheduleLogRecord(rec)).filter((shift) => shift.job_date === this.jobDate)
		const sortedRecords = _.orderBy(records, ['job_description', 'job_start'], ['asc', 'asc'])
		const selectedRecord = sortedRecords.find((rec) => rec.id === this.schedLogId)
		const filteredRecords = sortedRecords.filter((rec) => rec.id !== this.schedLogId)
		if (selectedRecord) filteredRecords.unshift(selectedRecord)
		this.schedLogCards = filteredRecords.map((rec) => new ScheduleLogCard(rec, dayOptions))
		this.performSearch(this.filterInput)
		setTimeout(() => {
			this.updatedSelectedShift()
			this.isLoading = false
		}, 500)
	}

	private saveBtnClicked() {
		this.shiftSelected.next(this.selectedCard.schedLog)
	}

	private cancelBtnClicked() {
		this.closePicker.next(true)
	}

	public prevBtnClicked() {
		log('prevBtn')
		if (!this.canGoBack) {
			this.coreSrvc.notifySrvc.notify('info', 'Range Limit', 'You may not select a shift more than one day before the time entry job date.')
			return
		}
		this.jobDate = moment(this.jobDate).subtract(1, 'day').format('YYYY-MM-DD')
		this.pickerDate = moment(this.jobDate).toDate()
		this.loadData()
	}

	public nextBtnClicked() {
		log('nextBtn')
		if (!this.canGoForward) {
			this.coreSrvc.notifySrvc.notify('info', 'Range Limit', 'You may not select a shift more than one day after the time entry job date.')
			return
		}
		this.jobDate = moment(this.jobDate).add(1, 'day').format('YYYY-MM-DD')
		this.pickerDate = moment(this.jobDate).toDate()
		this.loadData()
	}

	private isFormValid(): boolean {
		return true
	}

	public formatViewDate(date: string) {
		return DateTimeHelper.mediumDateFromDateString(date)
	}

	public formatShiftTime(card: ScheduleLogCard) {
		const start = card.schedLog.effectiveStartTime()
		const end = card.schedLog.effectiveEndTime()
		const tz = card.schedLog.timezone
		const jobDate = card.schedLog.job_date

		const shiftTime = TransTableFormatter.makeShiftTime(start, end, tz, jobDate)
		return shiftTime.isAnytime ? `Anytime Job` : `${shiftTime.start}-${shiftTime.end}`
	}

	public pickerDidClose(date) {
		this.jobDate = moment(date).format('YYYY-MM-DD')
		this.pickerDate = moment(this.jobDate).toDate()
		this.loadData()
	}

	public selectCard(card: ScheduleLogCard) {
		if (this.selectedCard?.schedLog.id === card.schedLog.id) {
			this.selectedCard = null
			this.dialogManager.isSubmitBtnVisible = false
		} else {
			this.selectedCard = card
			this.dialogManager.isSubmitBtnVisible = true
		}
	}

	public updatedSelectedShift() {
		this.selectedCard = this.schedLogCards.find((slw) => slw.schedLog.id === this.schedLogId) ?? null
		if (this.selectedCard) {
			this.dialogManager.isSubmitBtnVisible = true
		} else {
			this.dialogManager.isSubmitBtnVisible = false
		}
		log('Selected Schedule Log', this.selectedCard)
	}

	public performSearch(searchText: string) {
		const lcText = (searchText ?? '').toLowerCase()
		this.filterInput = lcText
		for (const shift of this.schedLogCards) {
			shift.applySearchFilter(this.filterInput)
		}
		log('performSearch', searchText)
	}
}

class ScheduleLogCard {
	schedLog: ScheduleLogRecord
	shiftDays = ''
	empCount = ''
	matchesFilter = true
	searchText = ''

	constructor(schedLog: ScheduleLogRecord, dayOptions: Array<IScheduleOptionsDayOption>) {
		this.schedLog = schedLog

		// Compute DOW info
		const ruleStr = schedLog.recurrence
		const ruleSet = rrulestr(ruleStr, { forceset: true }) as RRuleSet
		const rule = ruleSet.rrules()[0]
		const daysScheduled = rule.options.byweekday || []
		const isWeekly = schedLog.recurring_freq === 'WEEKLY'
		const result = []

		this.empCount = schedLog.effectiveEmpId() === 0 ? `(${schedLog.effectiveEmpCount()})` : ''

		for (const day of dayOptions) {
			if (daysScheduled.includes(day.value)) {
				result.push(day.code)
			}
		}
		const days = result.join(', ')
		this.shiftDays = isWeekly ? days : schedLog.job_date
		this.makeSearchText()
	}

	makeSearchText() {
		const empName = this.schedLog.employee_name
		const jobName = this.schedLog.job_description
		this.searchText = `${empName}~${jobName}`.toLowerCase()
	}

	applySearchFilter(filterText: string) {
		if (!filterText) {
			this.matchesFilter = true
			return
		}

		if (this.searchText.includes(filterText)) {
			this.matchesFilter = true
		} else {
			this.matchesFilter = false
		}
	}
}
