import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { DatabaseService } from '@app/services'

@Component({
    selector: 'app-not-provisioned',
    templateUrl: './not-provisioned.component.html',
    styleUrls: ['./not-provisioned.component.scss'],
    standalone: false
})
export class NotProvisionedComponent implements OnInit {
	constructor(private router: Router, private location: Location, private dbSrvc: DatabaseService) {}

	ngOnInit() {
		this.location.replaceState('/')
	}

	tryAgain() {
		localStorage.clear()
		this.router.navigate(['/'])
	}

	get isUnderMaintenance(): boolean {
		return this.dbSrvc.lambdaSrvc.isUnderMaintenance
	}
}
