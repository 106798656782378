<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<div *ngIf="isViewingSystemRoleFromRolesTableAndNotInternalUser" class="alert alert-info">
		System role templates may be cloned but they cannot be modified.
	</div>

	<form class="edit-form-block" novalidate [formGroup]="roleForm" (keydown.enter)="$event.preventDefault()" autocomplete="off">
		<div class="options-block" [class.input-required]="!roleForm.get('description').value">
			<div class="options-title-inline">Role Name</div>
			<div *ngIf="!roleForm.get('description').value" class="float-right">(required)</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="description" />
		</div>
		<div class="options-block">
			<div class="options-title-inline">Details</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="details" />
		</div>
	</form>
	<div class="ur-separator ur-report-sep">Permissions Template</div>
	<div>
		<app-user-permissions #userPermComponent [roleId]="recordId"></app-user-permissions>
	</div>
</div>
