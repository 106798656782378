<div *ngIf="isAdpIntegrated" class="alert alert-warning" style="margin-top: 15px">
	Pay rate values will be updated the next time you sync with ADP. Please ensure you set a rate in ADP for any rate code / employee pairs listed
	here, otherwise payroll uploads will error.
</div>

<div *ngIf="!isAdpIntegrated" class="alert alert-info alert-bg-gray" style="margin-top: 15px">
	Press <strong>Enter/Return</strong> key after modifying an entry to submit your changes.
</div>

<div class="section-table">
	<table id="empJobPayRatesTable" class="stripe nowrap" cellspacing="0" width="100%"></table>
	<i id="clear-search-icon" (click)="clearSearch()" class="fa fa-times-circle-o" aria-hidden="true"></i>
</div>

<app-modal-dialog *ngIf="editDialogManager.isDialogVisible" [dialogManager]="editDialogManager">
	<app-emp-job-pay-rates-edit
		[recordId]="editAction.recordId"
		[action]="editAction.action"
		[dialogManager]="editDialogManager"
		(recordUpdated)="recordUpdated()"
	>
	</app-emp-job-pay-rates-edit>
</app-modal-dialog>

<app-delete-record-dialog
	*ngIf="deleteAction.showDeleteRecordDialog"
	[showDeleteRecordDialog]="deleteAction.showDeleteRecordDialog"
	[tableName]="deleteAction.tableName"
	[recordId]="deleteAction.recordId"
	[recordLabel]="deleteAction.recordLabel"
	(cancelDelete)="deleteActionCancel()"
	(actionComplete)="deleteActionComplete()"
>
</app-delete-record-dialog>
