<div>
	<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

	<form novalidate autocomplete="off" [formGroup]="compForm" [hidden]="isUpdating" (keydown.enter)="$event.preventDefault()">
		<div *ngIf="section === 'ALL' || section === 'PROFILE'">
			<div *ngIf="true" class="form-group" [class.input-required]="!compForm.get('name').value">
				<label for="name" class="form-control-label">Company Name</label>
				<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('companyName')"></i> -->
				<span *ngIf="!compForm.get('name').value" class="input-required float-right">(required)</span>
				<input class="form-control" type="text" formControlName="name" />
				<!-- <div class="input-disabled">{{ company.name }}</div> -->
			</div>

			<div>
				<div formGroupName="address">
					<div class="form-group">
						<label for="addrLine1" class="form-control-label">Street Address</label>
						<input id="addrLine1" type="text" class="form-control" formControlName="addr_1" placeholder="Street Address" autocomplete="off" />
					</div>

					<div class="form-group">
						<label for="addrLine2" class="form-control-label">Address Line 2</label>
						<input id="addrLine2" type="text" class="form-control" formControlName="addr_2" placeholder="Address Line 2" autocomplete="off" />
					</div>

					<div class="form-group">
						<label for="addrState" class="form-control-label">City</label>
						<input id="addrCity" type="text" class="form-control" formControlName="city" placeholder="City" autocomplete="off" />
					</div>

					<div class="row">
						<div class="col-7">
							<div class="form-group">
								<label for="state" class="form-control-label">State/Region</label>
								<input id="state" type="text" class="form-control" formControlName="state" placeholder="State / Region" autocomplete="off" />
							</div>
						</div>
						<div class="col-5">
							<div class="form-group">
								<label for="addrZip" class="form-control-label">Zip/Postcode</label>
								<input id="addrZip" type="text" class="form-control" formControlName="post_code" placeholder="Postcode" autocomplete="off" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- <div style="margin-bottom: 20px">
				<hr />
				<button class="btn btn-sm btn-secondary btn-block" (click)="hasAddress = !hasAddress">
					{{ hasAddress ? 'Remove Address' : 'Add Address' }}
				</button>
			</div> -->

			<div class="form-group" [class.input-required]="!compForm.get('timezone').value">
				<hr />

				<div class="input-label">
					<strong>Default Timezone</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('defaultTimezone')"></i>
					<span *ngIf="!compForm.get('timezone').value" class="float-right">(required)</span>
				</div>
				<select id="timezone" class="form-control" formControlName="timezone">
					<option default value="">Select Timezone</option>
					<option *ngFor="let tz of timezoneData" [value]="tz.zone_name">
						{{ tz.display_name ? tz.display_name : tz.zone_name }}
					</option>
				</select>
			</div>

			<div class="form-group" [class.input-required]="!compForm.get('supervisor_phone_e164').value">
				<div class="input-label">
					<strong>Default Supervisor Phone #</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('supervisorPhone')"></i>
					<span *ngIf="!compForm.get('supervisor_phone_e164').value" class="float-right">(required)</span>
				</div>
				<div>
					<table width="100%">
						<tr>
							<td style="width: 80px">
								<select class="form-control" formControlName="dialingCode" (change)="formatSupPhone()">
									<option *ngFor="let code of countryCodeData" value="{{ code.id }}">{{ code.id }}</option>
								</select>
							</td>
							<td>&nbsp;&nbsp;</td>
							<td style="max-width: 275px">
								<input
									(keyup)="formatSupPhone()"
									class="form-control"
									[class.is-valid]="supPhoneValid"
									formControlName="supervisor_phone_e164"
									type="tel"
									autocomplete="off"
								/>
							</td>
						</tr>
					</table>
				</div>
			</div>

			<div *ngIf="!supPhoneValid" class="alert alert-warning">
				Supervisor Mobile # is not valid for the
				<strong>{{ compForm.get('dialingCode').value }}</strong> region.
			</div>

			<div class="form-group form-group-lg" [class.input-required]="!compForm.get('supervisor_email').value">
				<div class="row">
					<div class="col-12">
						<div>
							<strong>Default Supervisor Email</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('supervisorEmail')"></i>
							<span *ngIf="!compForm.get('supervisor_email').value" class="float-right">(required)</span>
						</div>
						<div style="margin-top: 10px">
							<input type="text" class="form-control" formControlName="supervisor_email" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- General Settings -->
		<div *ngIf="section === 'ALL' || section === 'GENERAL'">
			<div style="padding-right: 15px">
				<!-- <div class="row">
					<div class="col-12" style="margin-bottom: 20px">
						<strong class="tts-hl-2">Miscellaneous Options</strong>
					</div>
				</div> -->

				<div class="checkbox-item d-flex justify-content-between">
					<div class="checkbox-wrap">
						<p-checkbox formControlName="allowAutoCreate" [binary]="true"></p-checkbox>
					</div>
					<div class="checkbox-label-wrap">
						<strong (click)="toggleCheckbox('allowAutoCreate')" class="has-pointer">Autocreate employees</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('autoCreateEmployees')"></i>
					</div>
				</div>

				<!-- Use Job Codes -->
				<div class="checkbox-item d-flex justify-content-between">
					<div class="checkbox-wrap">
						<p-checkbox formControlName="useJobCodes" [binary]="true" (onChange)="handleJobCodesChange()"></p-checkbox>
					</div>
					<div class="checkbox-label-wrap">
						<strong (click)="toggleCheckbox('useJobCodes')" class="has-pointer">Use job codes</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('useJobCodes')"></i>
					</div>
				</div>

				<div *ngIf="compForm.get('useJobCodes').value" class="checkbox-item d-flex justify-content-between">
					<div class="checkbox-wrap checkbox-child">
						<p-checkbox formControlName="allow_invalid_job_code" [binary]="true"></p-checkbox>
					</div>
					<div class="checkbox-label-wrap">
						<strong (click)="toggleCheckbox('allow_invalid_job_code')" class="has-pointer">Allow default job</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('allowInvalidJobCode')"></i>
					</div>
				</div>

				<div *ngIf="compForm.get('useJobCodes').value" class="checkbox-item d-flex justify-content-between">
					<div class="checkbox-wrap checkbox-child">
						<p-checkbox formControlName="allow_unscheduled_job" [binary]="true"></p-checkbox>
					</div>
					<div class="checkbox-label-wrap">
						<strong (click)="toggleCheckbox('allow_unscheduled_job')" class="has-pointer">Allow unscheduled jobs</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('allowUnscheduledJob')"></i>
					</div>
				</div>

				<div class="checkbox-item d-flex justify-content-between">
					<div class="checkbox-wrap">
						<p-checkbox formControlName="overage" [binary]="true"></p-checkbox>
					</div>
					<div class="checkbox-label-wrap">
						<strong (click)="toggleCheckbox('overage')" class="has-pointer">Track budgeted time overage</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('timeWorkedOverage')"></i>
					</div>
				</div>

				<div class="checkbox-item d-flex justify-content-between">
					<div class="checkbox-wrap">
						<p-checkbox formControlName="travel" [binary]="true"></p-checkbox>
					</div>
					<div class="checkbox-label-wrap">
						<strong (click)="toggleCheckbox('travel')" class="has-pointer">Enable travel tracking</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('travel')"></i>
					</div>
				</div>

				<div *ngIf="compForm.get('travel').value">
					<div class="checkbox-item d-flex justify-content-between">
						<div class="checkbox-wrap checkbox-child">
							<p-radioButton value="ALL" formControlName="travel_target"></p-radioButton>
						</div>
						<div class="checkbox-label-wrap radio-label-pad">
							<div (click)="compForm.get('travel_target').setValue('ALL')"><strong>For all employees</strong></div>
						</div>
					</div>

					<div class="checkbox-item d-flex justify-content-between">
						<div class="checkbox-wrap checkbox-child">
							<p-radioButton value="SELECTED" formControlName="travel_target"></p-radioButton>
						</div>
						<div class="checkbox-label-wrap radio-label-pad">
							<div (click)="compForm.get('travel_target').setValue('SELECTED')">
								<strong>Only for selected employees</strong>
							</div>
						</div>
					</div>
				</div>

				<div class="checkbox-item d-flex justify-content-between">
					<div class="checkbox-wrap">
						<p-checkbox formControlName="ivr_sms_breaks" [binary]="true"></p-checkbox>
					</div>
					<div class="checkbox-label-wrap">
						<strong (click)="toggleCheckbox('ivr_sms_breaks')" class="has-pointer">Enable IVR and SMS breaks</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('ivrSmsBreaks')"></i>
					</div>
				</div>

				<div class="checkbox-item d-flex justify-content-between">
					<div class="checkbox-wrap">
						<p-checkbox formControlName="confirm_breaks" [binary]="true"></p-checkbox>
					</div>
					<div class="checkbox-label-wrap">
						<strong (click)="toggleCheckbox('confirm_breaks')" class="has-pointer">Require break confirmation</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('confirm_breaks')"></i>
					</div>
				</div>

				<!-- Hide option in UI -->
				<div *ngIf="false" class="checkbox-item d-flex justify-content-between">
					<div class="checkbox-wrap">
						<p-checkbox formControlName="next_job" [binary]="true"></p-checkbox>
					</div>
					<div class="checkbox-label-wrap">
						<strong (click)="toggleCheckbox('next_job')" class="has-pointer">Enable next job option</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('nextJob')"></i>
					</div>
				</div>

				<div class="checkbox-item d-flex justify-content-between">
					<div class="checkbox-wrap">
						<p-checkbox formControlName="ivr_external_id" [binary]="true"></p-checkbox>
					</div>
					<div class="checkbox-label-wrap">
						<strong (click)="toggleCheckbox('ivr_external_id')" class="has-pointer" [class.disabled]="!isIvrExternalIdOptionAvailable"
							>Allow employees to access the system using their external ID</strong
						>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('allowExternalId')"></i>
					</div>
				</div>

				<div class="checkbox-item d-flex justify-content-between">
					<div class="checkbox-wrap">
						<p-checkbox formControlName="schedule_overlap" [binary]="true"></p-checkbox>
					</div>
					<div class="checkbox-label-wrap">
						<strong (click)="toggleCheckbox('schedule_overlap')" class="has-pointer">Allow employee schedules to overlap</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('empSchedOverlap')"></i>
					</div>
				</div>

				<div class="checkbox-item d-flex justify-content-between">
					<div class="checkbox-wrap">
						<p-checkbox formControlName="emp_schedule_notify" [binary]="true"></p-checkbox>
					</div>
					<div class="checkbox-label-wrap">
						<strong (click)="toggleCheckbox('emp_schedule_notify')" class="has-pointer">Send schedule change notifications to employees</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('empScheduleNotify')"></i>
					</div>
				</div>

				<!-- Enable schedule change notification emails in schedule edit -->
				<div class="checkbox-item d-flex justify-content-between">
					<div class="checkbox-wrap">
						<p-checkbox formControlName="schedule_change_notifications" [binary]="true"></p-checkbox>
					</div>
					<div class="checkbox-label-wrap">
						<strong (click)="toggleCheckbox('schedule_change_notifications')" class="has-pointer"
							>Enable schedule change notification email list in schedule edit dialog</strong
						>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('schedule_change_notifications')"></i>
					</div>
				</div>
			</div>

			<hr style="margin-top: 25px; margin-bottom: 25px" />

			<!-- IVR Language Options -->
			<div style="padding-right: 15px">
				<div class="options-section-header inline-text">IVR Language Options</div>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('systemPrompts')"></i>
				<div style="margin-top: 20px">
					<div *ngFor="let code of languages.order">
						<div class="formrow-checkbox">
							<div class="tts-hl-2 float-right">
								<i
									class="fa fa-arrow-up has-pointer"
									[class.action-icon-disabled]="!canMoveLangUp(code)"
									(click)="adjustLangOrder(code, 'UP')"
								></i>
								<span style="cursor: default"> / </span>
								<i
									class="fa fa-arrow-down has-pointer"
									[class.action-icon-disabled]="!canMoveLangDown(code)"
									(click)="adjustLangOrder(code, 'DOWN')"
								></i>
							</div>
							<p-checkbox [(ngModel)]="languages[code]" [ngModelOptions]="{ standalone: true }" [binary]="true"></p-checkbox>
							<strong (click)="toggleLanguageCheckbox(code)" class="has-pointer">{{ formatLanguageName(code) }}</strong>
						</div>
					</div>
				</div>
			</div>

			<hr style="margin-top: 25px; margin-bottom: 25px" />

			<div style="margin-top: 18px">
				<div class="options-section-header" style="margin-bottom: 20px">Report Options</div>

				<div>
					<div class="float-right">
						<input
							class="form-control small-input-inline"
							formControlName="emp_cost_multiplier"
							(change)="validateFloat('emp_cost_multiplier')"
						/>
					</div>
					<div class="options-title dur-label">
						Employee cost multiplier
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('emp_cost_multiplier')"></i>
					</div>
				</div>
			</div>

			<hr style="margin-top: 25px; margin-bottom: 25px" />

			<div class="options-section-header" style="margin-bottom: 20px">Miscellaneous Options</div>

			<!-- Default supervisor for autocreated employees -->
			<div class="form-group">
				<div class="input-label form-spacer">
					<strong>Supervisor for Autocreated Employees</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('autocreatedSupId')"></i>
				</div>
				<p-select
					appScrollLockSelect
					placeholder="Select a supervisor"
					id="supForAutocreatedDropdown"
					[options]="supervisorOptions"
					formControlName="default_supervisor_id"
					[filter]="false"
				></p-select>
			</div>

			<!-- Employee Vacation Clock-in Alerts -->
			<div style="padding-bottom: 10px">
				<!-- <hr style="margin-top: 30px" /> -->
				<div style="margin-bottom: 12px">
					<strong>Employee Vacation Clock-In Alerts</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('empVacAlertEmail')"></i>
				</div>
				<app-generic-email-list [manager]="vacAlertEmailListManager"></app-generic-email-list>
			</div>

			<!-- Auto Logout Timer - Deprecated -->
			<div *ngIf="false" class="form-group form-group-lg">
				<div class="row">
					<div class="col-7">
						<div class="input-label-horizontal">
							<strong>Auto-Logout After</strong>
						</div>
					</div>
					<div class="col-5">
						<div class="float-right">
							<select class="form-control" (change)="setAutoLogout($event)" formControlName="autoLogoutTimer">
								<option value="1">1 Hour</option>
								<option value="3">3 Hours</option>
								<option value="6">6 Hours</option>
								<option value="12">12 Hours</option>
								<option value="24">24 Hours</option>
							</select>
						</div>
					</div>
				</div>
				<br />
			</div>

			<!-- Internal Options Block -->
			<div *ngIf="isInternalUser">
				<hr style="margin-top: 28px" />

				<app-form-expander
					[style]="{ 'margin-top': '5px' }"
					[label]="'Internal Options'"
					[centerOnExpansion]="true"
					[(isExpanded)]="showInternalOptions"
				>
					<div>
						<!-- Industry -->
						<div class="form-group">
							<div class="input-label form-spacer">
								<strong>Industry</strong>
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('industry')"></i>
							</div>
							<p-select
								appScrollLockSelect
								id="industryDropdown"
								[options]="industryOptions"
								formControlName="industry"
								[filter]="false"
							></p-select>
						</div>
						<!-- Default Units -->
						<div class="form-group">
							<div class="input-label form-spacer">
								<strong>Default Units</strong>
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('default_units')"></i>
							</div>
							<p-select
								appScrollLockSelect
								id="defaultUnitsDropdown"
								[options]="defaultUnitsOptions"
								formControlName="default_units"
								[filter]="false"
							></p-select>
						</div>
						<!-- Account Status -->
						<div class="form-group">
							<div class="input-label form-spacer">
								<strong>Account Status</strong>
								<!-- <i
									title="More Information"
									class="far fa-info-circle tts-info-btn"
									(click)="showHelp('accountStatus')"
								></i> -->
							</div>
							<p-select
								appScrollLockSelect
								id="accountStatusDropdown"
								[options]="accountStatusOptions"
								formControlName="account_status"
								[filter]="false"
								(onChange)="onAccountStatusChange($event)"
							></p-select>
						</div>

						<!-- Scheduling Week Start -->
						<div *ngIf="false" class="form-group">
							<div class="input-label form-spacer">
								<strong>Scheduling Week Start</strong>
							</div>
							<select class="form-control" formControlName="wkst">
								<option [ngValue]="null">Monday</option>
								<option [ngValue]="1">Tuesday</option>
								<option [ngValue]="2">Wednesday</option>
								<option [ngValue]="3">Thursday</option>
								<option [ngValue]="4">Friday</option>
								<option [ngValue]="5">Saturday</option>
								<option [ngValue]="6">Sunday</option>
							</select>
						</div>

						<!-- Trial Expiration -->
						<!-- <div class="options-block">
							<div>
								<div class="options-title">Trial Expiration</div>
							</div>
							<app-date-time-picker
								[currentDate]="compForm.value.trial_expires"
								[pickerType]="'DATE'"
								[pickerMode]="'dialog'"
								[pickerLabel]="'Trial Expiration'"
								[hour12Timer]="true"
								[format]="'MMM Do, YYYY'"
								(pickerDidClose)="compForm.value.trial_expires = $event"
							>
							</app-date-time-picker>
						</div> -->

						<div class="options-block" style="margin-top: 26px; margin-bottom: 16px">
							<div class="float-right">
								<span class="tts-hl-2 cur-symbol">$</span>
								<input
									class="form-control small-input-inline"
									style="width: 80px"
									formControlName="cost_user_month"
									(change)="validateFloat('cost_user_month')"
								/>
							</div>
							<div class="options-title cost-user">Cost per user/mo</div>
						</div>

						<div class="options-block" style="margin-bottom: 16px">
							<div class="float-right">
								<span class="tts-hl-2 cur-symbol" style="position: relative; top: -10px">$</span>
								<div class="input-disabled" style="width: 80px; display: inline-block; text-align: right">
									{{ formatFloat('prepaid_balance') }}
								</div>
							</div>
							<div class="options-title cost-user">Prepaid balance</div>
						</div>

						<div class="options-block">
							<div class="float-right">
								<input
									class="form-control small-input-inline"
									style="width: 80px"
									placeholder="days"
									formControlName="invoice_due_days"
									(change)="validateNumericInput('invoice_due_days')"
								/>
							</div>
							<div class="options-title cost-user">
								Invoice Terms <span style="font-size: 1rem; color: gray; font-weight: 500">(Net Due)</span>
							</div>
						</div>

						<hr style="margin-top: 35px; margin-bottom: 25px" />

						<div class="options-section-header" style="margin-bottom: 20px">General Timers</div>

						<div class="form-group form-group-lg">
							<div class="row">
								<div class="col-8">
									<div>
										<strong>Portal Inactivity Automatic Logout Timer</strong>
										<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('auto_logout')"></i>
									</div>
								</div>
								<div class="col-4">
									<div class="float-right">
										<input
											type="tel"
											style="width: 55px; text-align: right"
											class="form-control small-input-inline"
											placeholder="min"
											formControlName="auto_logout"
										/>
									</div>
								</div>
							</div>
						</div>

						<div class="form-group form-group-lg">
							<div class="row">
								<div class="col-8">
									<div>
										<strong>IVR Required Checklist Automatic Checkout Window</strong>
										<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('checklist_timer')"></i>
									</div>
								</div>
								<div class="col-4">
									<div class="float-right">
										<input
											type="tel"
											style="width: 55px; text-align: right"
											class="form-control small-input-inline"
											placeholder="min"
											formControlName="checklist_timer"
										/>
									</div>
								</div>
							</div>
						</div>

						<div class="form-group form-group-lg">
							<div class="row">
								<div class="col-8">
									<div>
										<strong>GPS Response Time Timeout Window</strong>
										<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('gpsResponseTime')"></i>
									</div>
								</div>
								<div class="col-4">
									<div class="float-right">
										<input
											type="tel"
											style="width: 55px; text-align: right"
											class="form-control small-input-inline"
											placeholder="min"
											formControlName="gps_allowed_delay"
										/>
									</div>
								</div>
							</div>
						</div>

						<div class="form-group form-group-lg">
							<div class="row">
								<div class="col-8">
									<div>
										<strong>Automatic No Show Timeout Window</strong>
										<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('createNoShow')"></i>
									</div>
								</div>
								<div class="col-4">
									<div class="float-right">
										<input
											type="tel"
											style="width: 55px; text-align: right"
											class="form-control small-input-inline"
											placeholder="min"
											formControlName="create_no_show"
										/>
									</div>
								</div>
							</div>
						</div>

						<!-- Back-to-back auto shift -->
						<div *ngIf="compForm.get('create_no_show').value" class="checkbox-item d-flex justify-content-between">
							<div class="checkbox-wrap">
								<p-checkbox formControlName="b2b_shift_auto" [binary]="true"></p-checkbox>
							</div>
							<div class="checkbox-label-wrap">
								<strong (click)="toggleCheckbox('b2b_shift_auto')" class="has-pointer">Back-to-back auto shift check in</strong>
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('b2b_shift_auto')"></i>
							</div>
						</div>

						<!-- System Intervals -->
						<hr style="margin-top: 35px; margin-bottom: 25px" />

						<div class="options-section-header" style="margin-bottom: 20px">System Options</div>

						<!-- Fuzzy distance match -->
						<div style="margin-top: 18px">
							<div class="float-right">
								<input
									type="tel"
									class="form-control small-input-inline"
									formControlName="fuzzy_match_distance"
									(change)="validateNumericInput('fuzzy_match_distance')"
								/>
							</div>
							<div class="options-title dur-label">
								Fuzzy match distance
								<!-- <span class="dur-format"> (hr:min)</span> -->
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('fuzzy_match_distance')"></i>
							</div>
						</div>

						<hr style="margin-top: 35px; margin-bottom: 25px" />

						<div class="options-section-header" style="margin-bottom: 20px">Check In/Out Confirmation</div>

						<!-- IVR Requires key press for check in -->
						<div class="checkbox-item d-flex justify-content-between" style="margin-top: 20px">
							<div class="checkbox-wrap">
								<p-checkbox formControlName="ivr_require_key_check_in" [binary]="true"></p-checkbox>
							</div>
							<div class="checkbox-label-wrap">
								<strong (click)="toggleCheckbox('ivr_require_key_check_in')" class="has-pointer">Require key press for IVR check-in</strong>
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('ivr_require_key_check_in')"></i>
							</div>
						</div>

						<div style="margin-top: 15px">
							<div class="float-right">
								<input
									style="margin-left: 20px"
									class="form-control small-input-inline"
									formControlName="require_key_delay"
									placeholder="hr:mn"
									(change)="validateDurationHrsAndMinutes('require_key_delay')"
								/>
							</div>
							<div class="options-title dur-label">
								Require key press for IVR check-out
								<!-- <span class="dur-format"> (hr:min)</span> -->
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('require_key_delay')"></i>
							</div>
						</div>

						<!-- Notification Options -->
						<hr style="margin-top: 35px; margin-bottom: 25px" />

						<div class="options-section-header" style="margin-bottom: 20px">Notification Options</div>

						<div style="margin-top: 18px">
							<div class="float-right">
								<input
									class="form-control small-input-inline"
									formControlName="min_notification_interval"
									placeholder="hr:mn"
									(change)="validateDurationHrsAndMinutes('min_notification_interval')"
								/>
							</div>
							<div class="options-title dur-label">
								Minimum interval
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('min_notification_interval')"></i>
							</div>
						</div>

						<div>
							<div class="float-right">
								<input
									class="form-control small-input-inline"
									formControlName="max_notification_duration"
									placeholder="hr:mn"
									(change)="validateDurationHrsAndMinutes('max_notification_duration')"
								/>
							</div>
							<div class="options-title dur-label">
								Maximum duration
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('max_notification_duration')"></i>
							</div>
						</div>

						<div>
							<div class="float-right">
								<input
									class="form-control small-input-inline input-left-pad"
									formControlName="max_daily_hours"
									placeholder="hr:min"
									(change)="validateDurationHrsAndMinutes('max_daily_hours')"
								/>
							</div>
							<div class="options-title dur-label">
								Max daily hours
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('max_daily_hours')"></i>
							</div>
						</div>

						<!-- Additional Details -->
						<div class="checkbox-item d-flex justify-content-between" style="margin-top: 20px">
							<div class="checkbox-wrap">
								<p-checkbox formControlName="additional_details_notify" [binary]="true"></p-checkbox>
							</div>
							<div class="checkbox-label-wrap">
								<strong (click)="toggleCheckbox('additional_details_notify')" class="has-pointer">Enable notification details</strong>
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('additional_details_notify')"></i>
							</div>
						</div>

						<!-- Notify Sup for No Show when existing ongoing shift -->
						<div class="checkbox-item d-flex justify-content-between">
							<div class="checkbox-wrap">
								<p-checkbox formControlName="sup_notify_ongoing" [binary]="true"></p-checkbox>
							</div>
							<div class="checkbox-label-wrap">
								<strong (click)="toggleCheckbox('sup_notify_ongoing')" class="has-pointer"
									>Enable supervisor notifications for no shows when scheduled employee is checked into another shift</strong
								>
								<!-- <i
									title="More Information"
									class="far fa-info-circle tts-info-btn"
									(click)="showHelp('sup_notify_ongoing')"
								></i> -->
							</div>
						</div>

						<!-- Notify SMS using schedule -->
						<div class="checkbox-item d-flex justify-content-between">
							<div class="checkbox-wrap">
								<p-checkbox formControlName="notify_sms_using_schedule" [binary]="true"></p-checkbox>
							</div>
							<div class="checkbox-label-wrap">
								<strong (click)="toggleCheckbox('notify_sms_using_schedule')" class="has-pointer"
									>Enable supervisor SMS notifications based on employee schedule</strong
								>
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('notify_sms_using_schedule')"></i>
							</div>
						</div>

						<!-- Break Options -->
						<hr style="margin-top: 35px; margin-bottom: 25px" />

						<div class="options-section-header" style="margin-bottom: 20px">Break Options</div>

						<div>
							<div class="float-right">
								<input
									class="form-control small-input-inline"
									formControlName="break_start_reminder"
									placeholder="hr:min"
									(change)="validateDurationHrsAndMinutes('break_start_reminder')"
								/>
							</div>
							<div class="options-title dur-label">
								Break start reminder
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('break_start_reminder')"></i>
							</div>
						</div>

						<div>
							<div class="float-right">
								<input
									class="form-control small-input-inline"
									formControlName="break_early_warning"
									placeholder="hr:min"
									(change)="validateDurationHrsAndMinutes('break_early_warning')"
								/>
							</div>
							<div class="options-title dur-label">
								Short break warning
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('break_early_warning')"></i>
							</div>
						</div>

						<div>
							<div class="float-right">
								<input
									class="form-control small-input-inline"
									formControlName="break_reminder"
									placeholder="hr:min"
									(change)="validateDurationHrsAndMinutes('break_reminder')"
								/>
							</div>
							<div class="options-title dur-label">
								Long break warning
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('break_reminder')"></i>
							</div>
						</div>

						<!-- Open Time Entries Options -->
						<hr style="margin-top: 35px; margin-bottom: 25px" />

						<div class="options-section-header" style="margin-bottom: 20px">Time Entry Options</div>

						<!-- Hours Rounding -->
						<div class="form-group">
							<div class="input-label form-spacer">
								<strong>Hours Rounding</strong>
								<!-- <i
										title="More Information"
										class="far fa-info-circle tts-info-btn"
										(click)="showHelp('hours_rounding')"
									></i> -->
							</div>
							<p-select
								appScrollLockSelect
								id="hoursRoundingDropdown"
								[options]="hoursRoundingOptions"
								formControlName="hours_rounding"
								[filter]="false"
							></p-select>
						</div>

						<!-- Round up timer -->
						<div style="margin-top: 25px">
							<div class="float-right">
								<input
									class="form-control small-input-inline"
									formControlName="round_up_timer"
									placeholder="hr:min"
									(change)="validateDurationHrsAndMinutes('round_up_timer')"
								/>
							</div>
							<div class="options-title dur-label">
								Clock in adjustment timer
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('round_up_timer')"></i>
							</div>
						</div>

						<!-- Round up timer note -->
						<div>
							<div class="float-right">
								<input
									class="form-control small-input-inline"
									formControlName="roundup_note_timer"
									placeholder="hr:min"
									(change)="validateDurationHrsAndMinutes('roundup_note_timer')"
								/>
							</div>
							<div class="options-title dur-label">
								Clock in adjustment note timer
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('roundup_note_timer')"></i>
							</div>
						</div>

						<!-- Round down timer -->
						<div>
							<div class="float-right">
								<input
									class="form-control small-input-inline"
									formControlName="round_down_timer"
									placeholder="hr:min"
									(change)="validateDurationHrsAndMinutes('round_down_timer')"
								/>
							</div>
							<div class="options-title dur-label">
								Clock out adjustment timer
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('round_down_timer')"></i>
							</div>
						</div>

						<!-- Round down timer note -->
						<div>
							<div class="float-right">
								<input
									class="form-control small-input-inline"
									formControlName="rounddown_note_timer"
									placeholder="hr:min"
									(change)="validateDurationHrsAndMinutes('rounddown_note_timer')"
								/>
							</div>
							<div class="options-title dur-label">
								Clock out adjustment note timer
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('rounddown_note_timer')"></i>
							</div>
						</div>

						<div>
							<div class="float-right">
								<input
									class="form-control small-input-inline"
									formControlName="check_in_out_window"
									placeholder="hr:min"
									(change)="validateDurationHrsAndMinutes('check_in_out_window')"
								/>
							</div>
							<div class="options-title dur-label">
								Clock in/out window
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('check_in_out_window')"></i>
							</div>
						</div>

						<div>
							<div class="float-right">
								<input
									class="form-control small-input-inline"
									formControlName="auto_close_open_trans"
									placeholder="hr:min"
									(change)="validateDurationHrsAndMinutes('auto_close_open_trans')"
								/>
							</div>
							<div class="options-title dur-label">
								Auto-close duration
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('auto_close_open_trans')"></i>
							</div>
						</div>

						<div>
							<div class="float-right">
								<input
									class="form-control small-input-inline"
									formControlName="job_length_max"
									placeholder="hrs"
									(change)="validateNumericInput('job_length_max')"
								/>
							</div>
							<div class="options-title dur-label">
								Maximum job length
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('job_length_max')"></i>
							</div>
						</div>

						<!-- Duplicate Image Check -->
						<div>
							<div class="float-right">
								<input
									type="tel"
									class="form-control small-input-inline"
									formControlName="image_dup_check"
									placeholder="days"
									(change)="validateNumericInput('image_dup_check')"
								/>
							</div>
							<div class="options-title dur-label">
								Image dup check window
								<!-- <span class="dur-format"> (hr:min)</span> -->
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('image_dup_check')"></i>
							</div>
						</div>

						<!-- Validate QR Codes -->
						<div class="checkbox-item d-flex justify-content-between" style="margin-top: 16px">
							<div class="checkbox-wrap">
								<p-checkbox formControlName="validate_qr_codes" [binary]="true"></p-checkbox>
							</div>
							<div class="checkbox-label-wrap">
								<strong (click)="toggleCheckbox('validate_qr_codes')" class="has-pointer">Enable photo QR code validation</strong>
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('validate_qr_codes')"></i>
							</div>
						</div>

						<!-- Overtime Optionss -->
						<hr style="margin-top: 35px; margin-bottom: 25px" />

						<div class="options-section-header" style="margin-bottom: 20px">Overtime Options</div>

						<!-- Enable Overtime Protection -->
						<div class="form-group">
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="ot_enable" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('ot_enable')" class="has-pointer">Enable overtime protection</strong>
									<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('cc_enable')"></i> -->
								</div>
							</div>

							<div class="input-label form-spacer" style="margin-top: 22px">
								<strong>Day of Week Start</strong>
								<!-- <i
									title="More Information"
									class="far fa-info-circle tts-info-btn"
									(click)="showHelp('hours_rounding')"
								></i> -->
							</div>
							<p-select
								appScrollLockSelect
								id="otDowStartDropdown"
								[options]="otDayOfWeekOptions"
								formControlName="ot_dow_start"
								[filter]="false"
							></p-select>
						</div>

						<div style="margin-top: 25px">
							<div class="float-right">
								<input
									class="form-control small-input-inline"
									formControlName="ot_week_count"
									(change)="validateNumericInput('ot_week_count')"
								/>
							</div>
							<div class="options-title dur-label">
								Weeks in overtime period
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('ot_week_count')"></i>
							</div>
						</div>

						<div>
							<div class="float-right">
								<input
									class="form-control small-input-inline"
									formControlName="ot_threshold_week"
									(change)="validateDurationHrsAndMinutes('ot_threshold_week')"
								/>
							</div>
							<div class="options-title dur-label">
								Max hours per week
								<span class="dur-format"> (hr:min)</span>
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('ot_threshold_week')"></i>
							</div>
						</div>

						<div>
							<div class="float-right">
								<input
									class="form-control small-input-inline"
									formControlName="ot_threshold_day"
									(change)="validateDurationHrsAndMinutes('ot_threshold_day')"
								/>
							</div>
							<div class="options-title dur-label">
								Max hours per day
								<span class="dur-format"> (hr:min)</span>
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('ot_threshold_day')"></i>
							</div>
						</div>

						<hr style="margin-top: 35px; margin-bottom: 25px" />

						<!-- Employee Cost Multiplier / Job Profitability Report -->
						<div style="margin-top: 18px">
							<div class="options-section-header" style="margin-bottom: 20px">Report Options</div>

							<div class="checkbox-item d-flex justify-content-between" style="margin-top: 16px">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="midnight_splits" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('midnight_splits')" class="has-pointer">Split reports on day boundary</strong>
									<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('midnight_splits')"></i>
								</div>
							</div>
							<!-- Use Incidents -->
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="useIncidents" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('useIncidents')" class="has-pointer">Enable IVR incident reports</strong>
									<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('useIncidents')"></i>
								</div>
							</div>
						</div>

						<!-- Click-to-Call / Call Center -->
						<div>
							<hr style="margin-top: 35px; margin-bottom: 25px" />
							<div class="options-section-header" style="margin-bottom: 20px">Click-to-Call / Call Center</div>

							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="c2c_enable" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('c2c_enable')" class="has-pointer">Enable outbound Click-to-Call</strong>
									<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('c2c_enable')"></i> -->
								</div>
							</div>
							<div *ngIf="showCallCenterOption" class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="cc_enable" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('cc_enable')" class="has-pointer">Enable inbound Call Center</strong>
									<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('cc_enable')"></i> -->
								</div>
							</div>
							<div *ngIf="!showCallCenterOption" style="color: gray; font-style: italic">Modify Call Center option on global company</div>
						</div>

						<!-- DEPRECATED - 2025-01-12 - Moved to call center settins -->
						<!-- <div class="form-group form-group-lg" style="margin-top: 18px">
							<div class="row">
								<div class="col-8">
									<div>
										<strong>Active Agent Automatic Signout Timer</strong>
										<span class="text-muted tsf-time-abbrv">(hrs)</span>
										<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('agent_logout_timer')"></i>
									</div>
								</div>
								<div class="col-4">
									<div class="float-right">
										<input
											type="tel"
											style="width: 55px; text-align: right"
											class="form-control small-input-inline"
											formControlName="agent_logout_timer"
										/>
									</div>
								</div>
							</div>
						</div> -->

						<!-- Employee Text Options -->
						<hr style="margin-top: 25px; margin-bottom: 25px" />

						<div class="form-group">
							<div style="margin-bottom: 20px">
								<div class="options-section-header inline-text" style="margin-bottom: 20px">Text / Messaging Options</div>
								<a href="#" title="More Information" class="tts-info-btn" (click)="showTextOptionsDialog = true; (false)">
									<i class="far fa-info-circle"></i>
								</a>
							</div>

							<div *ngIf="showTextOptionsDialog" class="card card-body tooltip-block tooltip-info-text">
								<button type="button" class="close" aria-label="Close" (click)="showTextOptionsDialog = false">
									<span>&times;</span>
								</button>
								By default, employees can make inquires via text messages. Employees can text the letter
								<strong style="color: chocolate">S</strong> to receive a listing of their current 7-day recurring weekly schedule. They can
								also text the letter <strong style="color: chocolate">T</strong> to receive a report of the hours they have worked in the last
								30 days.
								<br />
								<br />
								<strong style="color: chocolate">Note: </strong>
								In the United States and Canada, employees send a text message to the same toll-free number they normally call. In the United
								Kingdom, toll-free numbers cannot receive text messages, so employees will need to send inquiry text messages to
								<strong style="color: chocolate">+44 20 3322 3496</strong>. This is a dedicated UK number that Telephone Timesheets uses for
								both sending and receiving text messages.
								<br />
							</div>

							<div class="formrow-checkbox">
								<p-checkbox formControlName="sms_responder_schedule" [binary]="true"></p-checkbox>
								<strong (click)="toggleCheckbox('sms_responder_schedule')" class="has-pointer">Emp can retrieve schedule via SMS</strong>
							</div>
							<div class="formrow-checkbox">
								<p-checkbox formControlName="sms_responder_time" [binary]="true"></p-checkbox>
								<strong (click)="toggleCheckbox('sms_responder_time')" class="has-pointer">Emp can retrieve timecard via SMS</strong>
							</div>

							<div style="margin-top: 12px">
								<div class="float-right">
									<input
										class="form-control small-input-inline"
										formControlName="sms_char_limit"
										(change)="validateNumericInput('sms_char_limit')"
									/>
								</div>
								<div class="options-title dur-label">
									Admin portal max SMS length
									<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('sms_char_limit')"></i>
								</div>
							</div>
						</div>

						<!-- Miscellaneous Internal Options -->
						<hr style="margin-top: 25px; margin-bottom: 25px" />

						<div style="padding-right: 15px">
							<div class="options-section-header" style="margin-bottom: 20px">Miscellaneous Options</div>

							<!-- Onboard Required -->
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="onboard_required" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('onboard_required')" class="has-pointer">Enable employee onboarding</strong>
									<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('onboard_required')"></i>
								</div>
							</div>

							<!-- Break Type Prompt -->
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="break_type_prompt" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('break_type_prompt')" class="has-pointer">Prompt employee for break type</strong>
									<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('break_type_prompt')"></i>
								</div>
							</div>

							<!-- Pure Mobile -->
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="pureMobile" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('pureMobile')" class="has-pointer">Enable pure mobile*</strong>
									<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('pureMobile')"></i>
								</div>
							</div>

							<!-- Force Job Codes -->
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="force_job_codes" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('force_job_codes')" class="has-pointer">Force job codes*</strong>
									<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('force_job_codes')"></i>
								</div>
							</div>

							<!-- GPS Prompt Extra -->
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="gps_prompt_extra" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('gps_prompt_extra')" class="has-pointer">GPS prompt extra</strong>
									<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('gps_prompt_extra')"></i>
								</div>
							</div>

							<!-- GPS Auto Location - DEPRECATED 2024-08-20 -->
							<!-- <div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="gps_auto_location" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('gps_auto_location')" class="has-pointer">GPS auto location</strong>
									<i
										title="More Information"
										class="far fa-info-circle tts-info-btn"
										(click)="showHelp('gps_auto_location')"
									></i>
								</div>
							</div> -->

							<!-- Use Checkpoints -->
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="useCheckpoints" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('useCheckpoints')" class="has-pointer">Use checkpoints (mobile)</strong>
									<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('useCheckpoints')"></i>
								</div>
							</div>

							<!-- Use Checkpoints Landline -->
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="useCheckpointsLL" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('useCheckpointsLL')" class="has-pointer">Use checkpoints (landline)</strong>
									<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('useCheckpointsLL')"></i>
								</div>
							</div>

							<!-- Enable Multi-Day Jobs -->
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="useMultiDay" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('useMultiDay')" class="has-pointer">Enable multi-day jobs</strong>
									<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('useMultiDay')"></i>
								</div>
							</div>

							<!-- NCCI Codes -->
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="ncci_codes" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('ncci_codes')" class="has-pointer">Enable NCCI codes</strong>
									<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('ncci_codes')"></i>
								</div>
							</div>

							<!-- Prevent Auto Inactive -->
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="prevent_auto_inactive" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('prevent_auto_inactive')" class="has-pointer">Prevent automatic inactivation</strong>
									<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('prevent_auto_inactive')"></i>
								</div>
							</div>

							<!-- Reject Inactive Employee -->
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="reject_inactive_employee" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('reject_inactive_employee')" class="has-pointer">Reject inactive employees</strong>
									<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('reject_inactive_employee')"></i>
								</div>
							</div>

							<!-- Pay Rates -->
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="pay_rate" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('pay_rate')" class="has-pointer">Enable bill/pay rates</strong>
									<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('pay_rate')"></i>
								</div>
							</div>

							<!-- Work Tax Location -->
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="work_tax_location" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('work_tax_location')" class="has-pointer">Enable work tax locations</strong>
									<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('work_tax_location')"></i>
								</div>
							</div>

							<!-- Hidden until we refactor employee / schedules auto check in/out functionality TTS-2560 & TTS-2561 -->
							<!-- Paid Holiday -->
							<!-- <div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="paid_holiday" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('paid_holiday')" class="has-pointer">Enable paid holidays</strong>
									<i
										title="More Information"
										class="far fa-info-circle tts-info-btn"
										(click)="showHelp('paid_holiday')"
									></i>
								</div>
							</div> -->

							<!-- Profile Photos -->
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="use_emp_photos" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('use_emp_photos')" class="has-pointer">Enable profile photos</strong>
									<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('use_emp_photos')"></i>
								</div>
							</div>

							<!-- Profile Photos -->
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="ssr_public_admin_notes" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('ssr_public_admin_notes')" class="has-pointer"
										>Enable admin notes in public shift summary reports</strong
									>
									<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('ssr_public_admin_notes')"></i>
								</div>
							</div>

							<!-- QBO Customer Location -->
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="useQBOCustLocations" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('useQBOCustLocations')" class="has-pointer">Enable QBO job sync</strong>
									<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('useQBOCustLocations')"></i>
								</div>
							</div>

							<!-- QBO Customer Location -->
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-checkbox formControlName="useQBOCustOrg" [binary]="true"></p-checkbox>
								</div>
								<div class="checkbox-label-wrap">
									<strong (click)="toggleCheckbox('useQBOCustOrg')" class="has-pointer">Enable QBO organization sync</strong>
									<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('useQBOCustOrg')"></i>
								</div>
							</div>
						</div>
					</div>
				</app-form-expander>

				<!-- <div style="font-size: 1.4em; cursor: pointer" (click)="showInternalOptions = !showInternalOptions">
					<div class="float-right" style="color: gray">
						<i *ngIf="!showInternalOptions" class="fa fa-chevron-right"></i>
						<i *ngIf="showInternalOptions" class="fa fa-chevron-down"></i>
					</div>
					<div class="tts-hl-2">Internal Options</div>
				</div> -->
			</div>
		</div>
	</form>
</div>

<!-- Form Validator - Need to evaluate for submit button state on parent dialog -->
<div [hidden]="true">
	<button id="compDetailSubmitBtn" type="submit" [disabled]="!isFormValid()" class="btn btn-success btn-sm btn-block">Save Account Defaults</button>
</div>

<div *ngIf="false" class="alert alert-info" style="margin-top: 25px">
	{{ compForm.value | json }}
</div>
