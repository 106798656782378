import { AfterContentInit, Component, OnDestroy } from '@angular/core'
import { log } from '@app/helpers'
import { AppVersion, DataAccessRequest, DialogManager, HelpDialogMessage } from '@app/models'
import { EnrollmentDocType, EnrollmentNeedsUpdateStatus, EnrollmentRecord } from '@app/models/enrollment'
import { CoreService } from '@app/services'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-enrollment',
    templateUrl: './enrollment.component.html',
    styleUrls: ['./enrollment.component.scss'],
    standalone: false
})
export class EnrollmentComponent implements AfterContentInit, OnDestroy {
	dialogManager = new DialogManager('EnrollmentDialog')

	enrollment = new EnrollmentRecord()
	needsUpdate: EnrollmentNeedsUpdateStatus

	tosConfirmed = false
	privacyConfirmed = false

	subs = new Subscription()

	isUpdating = false

	constructor(private coreSrvc: CoreService) {
		// this.setupDialogManager()
		this.coreSrvc.dbSrvc.settingSrvc.displayEnrollmentDialog.subscribe((showDialog) => {
			const user = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
			this.needsUpdate = AppVersion.getEnrollmentNeedsUpdateStatus(user)
			this.coreSrvc.connectSrvc.getMyIp().then((ipAddress) => {
				this.enrollment.ip_address = ipAddress
				this.dialogManager.isDialogVisible = showDialog
				// setTimeout(() => {
				// 	this.dialogManager.cancelBtnAction = () => this.cancelBtnClicked()
				// }, 250)
			})
		})
	}

	ngAfterContentInit() {
		this.setupDialogManager()
	}

	get isFormValid(): boolean {
		return (this.needsUpdate.vTos ? this.tosConfirmed : true) && (this.needsUpdate.vPrivacy ? this.privacyConfirmed : true) && !this.isUpdating
	}

	public ngOnDestroy() {
		this.subs.unsubscribe()
	}

	private setupDialogManager() {
		this.dialogManager.headerLabel = 'Service Agreements'
		this.dialogManager.submitBtnLabel = 'Submit'
		this.dialogManager.canSubmit = () => this.isFormValid
		this.dialogManager.submitBtnAction = () => this.submit()
		this.dialogManager.cancelBtnAction = () => this.cancelBtnClicked()
	}

	private submit(): void {
		this.isUpdating = true
		const request = new DataAccessRequest('enrollment', 'insert', this.enrollment)
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			log('result', result)
			this.coreSrvc.dbSrvc.settingSrvc.shouldSkipEnrollmentCheck = true
			this.dialogManager.isDialogVisible = false
			this.isUpdating = false
		})
	}

	private cancelBtnClicked(): void {
		log('Cancel Clicked')
		this.coreSrvc.dbSrvc.settingSrvc.shouldSkipEnrollmentCheck = true
		this.dialogManager.isDialogVisible = false
	}

	public toggleCheckbox(prop: string) {
		log('prop', prop)
		this[prop] = !this[prop]
	}

	public showDocument(docType: EnrollmentDocType) {
		let url = ''
		if (docType === 'TOS') url = 'https://telephonetimesheets.com/terms-of-service/'
		if (docType === 'PRIVACY') url = 'https://telephonetimesheets.com/privacy-policy/'
		window.open(url, '_blank')
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'tosConfirmed':
				help.header = 'Agreements'
				help.message = 'The Telephone Timesheets terms of service applies to all users of the service.'
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
