<app-activity-banner
	[isProcessing]="isLoadingConfig"
	[messageBlockClass]="'ab-message-block tts-hl-gray'"
	[message]="'Loading Configurations ...'"
></app-activity-banner>
<app-activity-banner [isProcessing]="isUpdating" [messageBlockClass]="'ab-message-block'" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating || isLoadingConfig">
	<div class="alert alert-info alert-bg-gray">
		Please contact <b><a class="link-text" href="mailto:support@telephonetimesheets.com">support</a></b> for assistance if you would like to setup
		additional or dedicated phone numbers to accomodate an advanced skills configuration.
	</div>
	<div class="options-block">
		<div class="options-title-inline">Skill Name</div>
		<input type="text" class="form-control" autocomplete="off" [(ngModel)]="skill.name" />
	</div>
	<div class="options-block">
		<div class="options-title-inline">Description</div>
		<textarea appTextAreaResizer class="options-textarea" placeholder="Enter skill description" [(ngModel)]="skill.details"></textarea>
	</div>
	<div class="options-block">
		<app-multi-select-details
			[type]="'PHONENUMBER'"
			[showClearBtn]="true"
			[dropdownLabel]="'Linked Numbers'"
			placeholder="Select Numbers"
			selectedItemsLabel="Numbers Selected: {0}"
			[options]="phoneOptions"
			[(model)]="skill.phone_number_ids"
		>
		</app-multi-select-details>
	</div>

	<div class="options-block">
		<app-multi-select-details
			[type]="'USER'"
			[showClearBtn]="true"
			[dropdownLabel]="'Linked Agents'"
			placeholder="Select Agents"
			selectedItemsLabel="Agents Selected: {0}"
			[options]="userOptions"
			[(model)]="skill.user_ids"
		>
		</app-multi-select-details>
	</div>

	<app-form-expander *ngIf="skillUsersList.length > 0" [style]="{ 'margin-top': '30px' }" [label]="'Current Skill Configuration'">
		<div>
			<div *ngFor="let suvm of skillUsersList" style="font-weight: 600">
				<div *ngIf="suvm.users.length > 0" class="usl-block">
					<div>{{ suvm.skill.name }}</div>
					<ul>
						<li *ngFor="let user of suvm.users">
							<span class="tts-hl-1">{{ user.first_name + ' ' + user.last_name }}</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</app-form-expander>

	<!-- <code>
		<pre>{{ skillUsersList | json }}</pre>
	</code> -->

	<app-form-expander *ngIf="userSKillsList.length > 0" [style]="{ 'margin-top': '5px' }" [label]="'Current User Configuration'">
		<div>
			<div *ngFor="let usvm of userSKillsList" style="font-weight: 600">
				<div *ngIf="usvm.skills.length > 0" class="usl-block">
					<div>{{ usvm.user.first_name + ' ' + usvm.user.last_name }}</div>
					<ul>
						<li *ngFor="let skill of usvm.skills">
							<span class="tts-hl-1">{{ skill.name }}</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</app-form-expander>
</div>

<!-- <code>
	<pre>{{ userSKillsList | json }}</pre>
</code> -->
