<div *ngIf="manager.isActive" class="batch-mode-block">
	<div>{{ recordsSelectedHeader }}</div>
	<div class="batch-links">
		<div *ngIf="!this.manager.isPreviewMode">
			<span class="link-text" (click)="batchAction('EXIT_BATCH')">exit batch</span> |
			<span class="link-text" (click)="batchAction('SELECT_ALL')">select visible</span> |
			<span class="link-text" (click)="batchAction('UNSELECT_ALL')">unselect visible</span>
		</div>
		<div>
			<span class="link-text" (click)="batchAction('TOGGLE_PREVIEW')">{{ this.manager.isPreviewMode ? 'exit preview' : 'preview selected' }}</span>
			|
			<span class="link-text" (click)="batchAction('EDIT')">edit batch</span>
		</div>
		<div *ngIf="resource === 'employee'">
			<span class="link-disabled" [class.link-text]="manager.selectedRecords.length > 0" (click)="batchAction('SEND_ANNOUNCEMENT')"
				>send announcement</span
			>
		</div>
	</div>
</div>
