import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import {
	UserRecord,
	CompanyRecord,
	ScheduleOptions,
	Timezone,
	ITimezone,
	AdminPrefs,
	EmpAppPrefs,
	EmployeeRecord,
	AccessPermissions,
	SupervisorsViewManager,
	UserGroupRecord,
	RegionCode,
	PhoneNumberRecord,
	NotificationProfile,
	UserRoleRecord,
	DeviceDetectorInfo,
	AppVersion,
	DatabaseTableName,
	VisualizationPrefs,
} from '@app/models'
import { CognitoUtil, NotificationsService, UserSessionService } from '@app/services'
import { log, DateTimeHelper, CurrencySymbol, CurrencyHelper } from '@app/helpers'

import _ from 'lodash'
import moment from 'moment-timezone'

import { SelectItem } from 'primeng/api/selectitem'
import { DatabaseService } from './database.service'
import { Subject } from 'rxjs'
import { environment } from '@env/environment'
import { DistanceHelper } from '@app/models/distance'
import { Global } from '@app/models/global'
import { v4 as uuid } from 'uuid'

@Injectable({
	providedIn: 'root',
})
export class SettingsService {
	// isAdpUser = false

	viewAsUserId: number = null
	supervisorsViewManager = new SupervisorsViewManager()

	public forceRouteToW2WIntegration = false

	private users: Array<UserRecord> = []
	public usersDataLoaded = false
	public usersLastUpdated = null
	public usersChanged = new Subject<boolean>()

	anySupervisor = new UserRecord()

	private userGroups: Array<UserGroupRecord> = []
	public userGroupsDataLoaded = false
	public userGroupsLastUpdated = null
	public userGroupsChanged = new Subject<boolean>()

	private userRoles: Array<UserRoleRecord> = []
	public userRolesDataLoaded = false
	public userRolesLastUpdated = null
	public userRolesChanged = new Subject<boolean>()

	private companyList: Array<CompanyRecord> = []
	public companyDataLoaded = false
	public companyChanged = new Subject<boolean>()
	public allowedCompaniesDataLoaded = false
	public globalCompanyDataLoaded = false

	timezoneDataLoaded = false

	callInNumbersDataLoaded = false
	isCompanyRefreshAllowed = true

	billingAlertHasRoutedToBilling = false

	phoneNumbersUpdatedEvent = new Subject<boolean>()

	shouldSkipEnrollmentCheck = false
	displayEnrollmentDialog = new Subject<boolean>()

	private allowedCompaniesList: Array<CompanyRecord> = []
	private globalCompanyList: Array<CompanyRecord> = [] // Only contains global list when in global company - otherwise current company
	// private callInNumbers: Array<string> = []
	private callInNumbersE164: Array<PhoneNumberRecord> = []
	private timezoneList: Array<Timezone> = []

	private redirectUrl = ''
	private lastRoute = null

	constructor(
		private router: Router,
		private cognitoUtil: CognitoUtil,
		private sessionSrvc: UserSessionService,
		private notifySrvc: NotificationsService,
	) {
		log('Creating SettingsService')

		// Setup the virtual Any Supervisor
		this.anySupervisor.id = 0
		this.anySupervisor.first_name = 'Any'
		this.anySupervisor.last_name = 'Supervisor'

		// Retrieve the last stored route if one exists
		const lastRoute = JSON.parse(localStorage.getItem('lastRoute'))
		this.lastRoute = lastRoute ? lastRoute.path : null
	}

	reloadApplication() {
		const element = $('#loading-spinner')
		element.css({ opacity: 1, display: 'block' })
		scrollTo(0, 0)
		top.frames.location.reload()
	}

	setRedirectUrl(url: string) {
		this.redirectUrl = url
	}

	followRedirectUrl() {
		if (this.redirectUrl) {
			const url = this.redirectUrl
			this.redirectUrl = ''
			this.lastRoute = null
			log('Redirect to provided url:', url)
			this.router.navigateByUrl(url).catch((reason) => {
				this.notifySrvc?.notify('warn', 'Navigation Error', 'Could not navigate to the link provided.')
				this.router.navigate(['/admin/dashboard'])
			})
		} else {
			const path = this.lastRoute
			if (path) {
				log('Redirect to lastRoute:', path)
				this.router.navigateByUrl(path).catch((reason) => {
					log('Could not navigate to last path')
				})
				this.lastRoute = null
			}
		}
	}

	clearSettingsForCompanySwitch() {
		localStorage.removeItem('lastRoute')
		localStorage.removeItem('transactionFilter')
	}

	clearSettingsData() {
		this.users = []
		this.usersDataLoaded = false

		this.userGroups = []
		this.userGroupsDataLoaded = false

		this.companyList = []
		this.companyDataLoaded = false

		this.allowedCompaniesList = []
		this.allowedCompaniesDataLoaded = false

		this.callInNumbersE164 = []
		this.callInNumbersDataLoaded = false

		this.timezoneList = []
		this.timezoneDataLoaded = false
	}

	// getDistanceConverter(): DistanceConverter {
	// 	const unitType = this.getCompany()?.default_units ?? 'IMPERIAL'
	// 	return new DistanceConverter(unitType)
	// }

	getDistrictLabel(): string {
		const region = this.getCompany().country_iso
		if (region === 'CA') {
			return 'Province'
		}
		return 'State'
	}

	getIndustryDropdown(): Array<SelectItem> {
		return [
			{ value: 'DEFAULT', label: 'Default' },
			{ value: 'SECURITY', label: 'Security' },
			{ value: 'HEALTHCARE', label: 'Healthcare' },
			{ value: 'CONSTRUCTION', label: 'Construction' },
		]
	}

	getHoursRoundingDropdown(): Array<SelectItem> {
		return [
			{ value: 'ROUNDING_NONE', label: 'None' },
			{ value: 'ROUNDING_15MIN_NEAREST', label: '15 Minutes Nearest' },
			{ value: 'ROUNDING_15MIN_UP', label: '15 Minutes Up' },
			{ value: 'ROUNDING_15MIN_DOWN', label: '15 Minutes Down' },
		]
	}

	isActualUserInternal(): boolean {
		const myActualUser = this.getMyActualUser()
		const role = myActualUser.role
		if (role === 'INTERNAL') {
			return true
		}
		return false
	}

	areEmployeeProfilesAvailable(): boolean {
		return this.getCompany()?.use_emp_photos ?? false
	}

	isAccountDisabled(): boolean {
		const company = this.getCompany()
		if (company) {
			const accountStatus = company.account_status
			if (
				accountStatus === 'LOCKED_SUSPENDED' ||
				accountStatus === 'BILLING_PORTAL_LOCKED' ||
				accountStatus === 'BILLING_ACCOUNT_LOCKED' // || accountStatus === 'BILLING_PAYMENT_FAILED' - DEPRECATED 20241104
			) {
				return true
			}
			// if (accountStatus === 'PREPAID' && company.prepaid_balance <= 0) { return true }
		}
		return false
	}

	// Global ID is 0 for normal companies
	hasGlobalAccount(): boolean {
		return !!this.getCompany().global_id
	}

	isGlobalAccount(): boolean {
		const company = this.getCompany()
		if (company && company.id === company.global_id) {
			return true
		}
		return false
	}

	canAccessGlobalAccount(): boolean {
		const globalId = this.getCompany().global_id
		if (globalId) {
			const myActualUser = this.getMyActualUser()
			const allowedCompanies = myActualUser.allowed_companies
			if (allowedCompanies.includes(0) || allowedCompanies.includes(globalId)) {
				return true
			}
		}
		return false
	}

	getOldestPrimary() {
		const users = this.users.filter((u) => u.role === 'PRIMARY')
		const sorted = _.orderBy(users, 'created')
		return sorted[0] ?? null
	}

	getUsers(): UserRecord[] {
		return this.users.filter((user) => user.role !== 'INTERNAL')
	}

	getAllUsersIncludingInternal(): UserRecord[] {
		return this.users
	}

	getUserForId(id: number): UserRecord {
		if (id === 0) {
			return this.anySupervisor
		}
		return this.users.find((u) => u.id === id)
	}

	getUserForE164Number(phoneE164: string) {
		return this.users.find((u) => u.phone_e164 === phoneE164)
	}

	getPhoneNumberRecordById(id: number) {
		return this.callInNumbersE164.find((num) => num.id === id)
	}

	getPhoneNumberRecordForE164CallIn4Number(phoneE164: string) {
		return this.callInNumbersE164.find((num) => num.number_e164 === phoneE164)
	}

	getUserForC2CE164Number(phoneE164: string) {
		return this.users.find((u) => u.phone_e164 === phoneE164 || u.c2c_phone_e164 === phoneE164)
	}

	getUserForEmailAddress(email: string) {
		return this.users.find((u) => u.email === email)
	}

	removeLocalUserRecord(recordId: number) {
		this.users = this.users.filter((rec) => rec.id !== recordId)
	}

	getPrimaryUser(): UserRecord {
		return this.users.find((u) => u.role === 'PRIMARY')
	}

	getPrimaryUsers(): Array<UserRecord> {
		return this.users.filter((u) => u.role === 'PRIMARY')
	}

	getPaidUsers(): Array<UserRecord> {
		return this.getUsers().filter((u) => u.role === 'PRIMARY' || u.role === 'MANAGER' || u.role === 'SECONDARY' || u.role === 'READONLY')
	}

	getMyUserAccessPermissions(): AccessPermissions {
		const user = this.getMyUser()
		return user.getAccessPermissions()
	}

	getMyUserAdminPrefs(): AdminPrefs {
		const user = this.getMyUser()
		if (user) {
			return this.getAdminPrefsForUserId(user.id)
		} else {
			return null
		}
	}

	getHealthCenterPrefsForCompany(): VisualizationPrefs {
		const prefs = this.getCompany().analytics_prefs_json
		return VisualizationPrefs.buildFromJson(prefs)
	}

	getAdminPrefsForCompany(): AdminPrefs {
		const prefs = this.getCompany().getCompanyAdminPrefs()
		return new AdminPrefs(prefs)
	}

	getAdminPrefsForUserId(id: number): AdminPrefs {
		const companyAdminPrefs = this.getCompany().getCompanyAdminPrefs()
		const userAdminPrefs = this.getUserForId(id).getUserAdminPrefsDelta()
		companyAdminPrefs.mergeData(userAdminPrefs)
		return companyAdminPrefs
	}

	getEmpAppPrefsForCompany(): EmpAppPrefs {
		return this.getCompany().getCompanyEmpAppPrefs()
	}

	getEmpAppPrefsForEmployee(emp: EmployeeRecord): EmpAppPrefs {
		const companyEmpAppPrefs = this.getEmpAppPrefsForCompany()
		const empAppPrefsForEmp = emp.getEmployeeAppPrefsDelta()
		companyEmpAppPrefs.mergeData(empAppPrefsForEmp)
		return companyEmpAppPrefs
	}

	getEmpAppDomain(): string {
		const customDomain = this.getCompany()?.emp_app_domain
		return customDomain || environment.empAppDomain
	}

	setUserAdminPrefs() {
		Global.distUnits = this.getCompany().default_units as 'IMPERIAL' | 'METRIC'

		const userPrefs = this.getMyUserAdminPrefs()
		const imageIssuesDuration = userPrefs.globalHighlightImgLastModDuration

		DateTimeHelper.format12Hour = userPrefs.globalFormatTime12Hours
		DateTimeHelper.imgIssuesDuration = imageIssuesDuration ? moment.duration(imageIssuesDuration, 'minutes').toISOString() : null
		log('IMGRANGE', DateTimeHelper.imgIssuesDuration)

		const unitType = this.getCompany()?.default_units ?? 'IMPERIAL'
		DistanceHelper.converter.unitType = unitType
		log('Set Distance Units', unitType)
	}

	/**
	 * Get the current logged in user
	 * @returns The currently logged in user
	 */

	getMyUser(): UserRecord {
		const viewAsUserId = this.viewAsUserId
		const viewAsUser = this.getUserForId(viewAsUserId)
		return viewAsUser ? viewAsUser : this.getMyActualUser()
	}

	getMyActualUser(): UserRecord {
		const adpCognitoId = this.sessionSrvc.adpUserSession?.cognitoId
		const cognitoId = adpCognitoId ? adpCognitoId : this.cognitoUtil.getCognitoIdentity()
		const user = this.users.find((u) => u.cognito_id === cognitoId)
		return user
	}

	getMyUserId(): number {
		return this.getMyUser().id
	}

	// Returns user ids for my user and any I manage

	isUserAManager(): boolean {
		const myUser = this.getMyUser()
		return myUser.role === 'MANAGER'
	}
	getManagedUserIds(): Array<number> {
		const myUserId = this.getMyUserId()
		const mangagedUserIds = [myUserId]
		const managedUsers = this.getUsers().filter((u) => u.managed_by === myUserId)
		managedUsers.forEach((mu) => {
			mangagedUserIds.push(mu.id)
		})
		return mangagedUserIds
	}
	getManagedUsers(includeIds?: Array<number>): Array<UserRecord> {
		const includedIds = includeIds ?? []
		const managedUserIds = this.getManagedUserIds()
		return this.getUsers().filter((u) => includedIds.includes(u.id) || managedUserIds.includes(u.id))
	}

	getUsernameForUserId(id: number): string {
		const user = this.users.find((u) => u.id === id)
		if (user) {
			return user.first_name + ' ' + user.last_name
		} else {
			return 'Unknown'
		}
	}

	getLinkedProfilesForUserId(dbSrvc: DatabaseService, id: number): Array<NotificationProfile> {
		const result = []
		const user = dbSrvc.settingSrvc.getUserForId(id)
		if (user) {
			const userPhone = user.phone_e164 || ' NO VALID PHONE'
			const userEmail = user.email || 'NO VALID EMAIL'
			const profiles = dbSrvc.npSrvc.getProfiles()
			for (const profile of profiles) {
				const npPhone = profile.supervisor_phone_e164 || ''
				const npEmails = profile.supervisor_email || ''
				if (npPhone.includes(userPhone) || npEmails.includes(userEmail)) {
					result.push(profile)
				}
			}
		}
		return result
	}

	getLinkedGroupsForUserId(dbSrvc: DatabaseService, id: number): Array<UserGroupRecord> {
		const groups = dbSrvc.settingSrvc.getUserGroupsByUserId(id)
		return groups
	}
	/**
	 * Check if current user is PRIMARY or INTERNAL
	 * @returns true if user is PRIMARY or INTERNAL
	 */

	isPrimaryOrInternalUser(): boolean {
		const myUser = this.getMyUser()
		if (myUser) {
			if (myUser.role === 'PRIMARY' || myUser.role === 'INTERNAL') return true
		}
		return false
	}

	isInternalUser(): boolean {
		const myUser = this.getMyUser()
		if (myUser) {
			if (myUser.role === 'INTERNAL') return true
		}
		return false
	}

	isPrimaryUser(): boolean {
		const myUser = this.getMyUser()
		if (myUser) {
			if (myUser.role === 'PRIMARY') return true
		}
		return false
	}

	getUsersDropdownData(): SelectItem[] {
		const dropdown = this.users
			.map((e) => e.id)
			.map((id) => {
				const user = this.getUserForId(id)
				return {
					label: user ? user.first_name + ' ' + user.last_name : 'User Not Found',
					value: id,
					data: user,
				}
			})
		const sortedDropdown = _.sortBy(dropdown, 'label')
		return sortedDropdown
	}

	canAccessAuditLog(): boolean {
		return this.isPrimaryOrInternalUser()
	}

	getCompany(): CompanyRecord {
		return this.companyList[0]
	}

	getAllowedCompanyForId(id: number) {
		return this.allowedCompaniesList.find((rec) => rec.id === id)
	}

	getAllowedCompaniesForUserId(id: number) {
		const user = this.getUserForId(id)
		if (user) {
			return user.allowed_companies.map((aId) => this.getAllowedCompanyForId(aId)).filter((c) => !!c)
		}
		return []
	}

	/**
	 * Returns an array of Company records which admin can manage
	 */

	getAllowedCompanies(): Array<CompanyRecord> {
		return this.allowedCompaniesList
	}

	// getCallInNumbers(): string[] {
	// 	return this.callInNumbers
	// }

	getCallInPhoneNumberRecords(): Array<PhoneNumberRecord> {
		return this.callInNumbersE164
	}

	getCallInNumbersE164(): Array<string> {
		return this.callInNumbersE164.map((pn) => pn.number_e164)
	}

	isStripeCustomer(): boolean {
		return this.getCompany().account_billing.includes('STRIPE')
	}

	isPayPalCustomer(): boolean {
		return this.getCompany().account_billing.includes('PAYPAL')
	}

	isQBOCustomer(): boolean {
		const company = this.getCompany()
		if (!company) {
			return false
		}
		if (company.qbo_id) {
			return true
		}
		return false
	}

	isFileImportIntegrated(): boolean {
		const company = this.getCompany()
		if (!company) {
			return false
		}
		return !!this.getCompany().file_import_id
	}

	isClickToCallEnabled(): boolean {
		const company = this.getCompany()
		return company.c2c_enable
	}

	isCallCenterEnabled(): boolean {
		return this.getCompany().cc_enable
	}

	// This is a check used with global companies to surface the config options for C2C/CC
	isClickToCallCenterConfigurable(): boolean {
		const hasGlobal = this.hasGlobalAccount()
		const isGlobal = this.isGlobalAccount()
		return hasGlobal ? isGlobal : true
	}

	clearAccountStatus() {
		const company = this.getCompany()
		company.account_status = null
	}

	clearCompanyData() {
		this.companyList = []
		this.companyDataLoaded = false
		// this.callInNumbers = []
		this.callInNumbersDataLoaded = false
	}

	/**
	 * Get monthly billing cost given number of employees
	 * @param empCount: Number of employees to count
	 * @returns Total monthly billing cost
	 */

	monthlyBillingCost(empCount: number): number {
		const userCount = this.getPaidUsers().length
		const costPerUser = this.getCompany().cost_user_month
		return costPerUser * (userCount + empCount)
	}

	// Methods for handling company list

	setCompanyRecords(records: Array<CompanyRecord>) {
		this.companyList = records.map((rec) => new CompanyRecord(rec))
		this.companyDataLoaded = true
		this.companyChanged.next(true)
		Global.coreSrvc.dbSrvc.billSrvc.setupBillingAlertMessages()
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateCompanyRecords(records: Array<CompanyRecord>) {
		const newRecords = records.map((rec) => new CompanyRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.companyList.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.companyList.push(newRecord)
			}
		}
		this.companyChanged.next(true)
		Global.coreSrvc.dbSrvc.billSrvc.setupBillingAlertMessages()
	}

	getGlobalCompany(): CompanyRecord {
		return this.getGlobalCompanyList().find((c) => c.id === c.global_id)
	}

	// Methods for handling global company list
	// Global list only contains full list when in global company, otherwise just current company

	getGlobalCompanyList(): Array<CompanyRecord> {
		return _.orderBy(this.globalCompanyList, 'name')
	}

	getGlobalCompanyById(id: number): CompanyRecord {
		return this.getGlobalCompanyList().find((c) => c.id === id)
	}

	getGlobalCompaniesForUserId(id: number) {
		const user = this.getUserForId(id)
		if (user) {
			return user.allowed_companies.map((aId) => this.getGlobalCompanyById(aId)).filter((c) => !!c)
		}
		return []
	}

	setGlobalCompanyRecords(records: Array<CompanyRecord>) {
		this.globalCompanyList = records.map((rec) => new CompanyRecord(rec))
		this.globalCompanyDataLoaded = true
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateFullCompanyRecords(records: Array<CompanyRecord>) {
		const newRecords = records.map((rec) => new CompanyRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.globalCompanyList.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.globalCompanyList.push(newRecord)
			}
		}
	}

	// Methods for handling user list

	setUserRecords(records: Array<UserRecord>) {
		this.users = records.map((rec) => new UserRecord(rec))
		this.usersDataLoaded = true
		this.usersChanged.next(true)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateUserRecords(records: Array<UserRecord>) {
		const newRecords = records.map((rec) => new UserRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.users.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.users.push(newRecord)
			}
		}
		this.usersChanged.next(true)
	}

	// Methods for handling user group list

	// User Group methods

	getUserGroups(): Array<UserGroupRecord> {
		return this.userGroups
	}

	getUserGroupById(id: number) {
		return this.userGroups.find((rec) => rec.id === id)
	}

	getUserGroupsByUserId(id: number) {
		return this.userGroups.filter((rec) => rec.supervisor_group_users.includes(id))
	}

	removeLocalUserGroupRecord(recordId: number) {
		this.userGroups = this.userGroups.filter((rec) => rec.id !== recordId)
	}

	setUserGroupRecords(records: Array<UserGroupRecord>) {
		this.userGroups = records.map((rec) => new UserGroupRecord(rec))
		this.usersDataLoaded = true
	}

	getUserGroupsDropdownData(hasNullOption: boolean): SelectItem[] {
		const dropdown = this.userGroups
			.map((e) => e.id)
			.map((id) => {
				const group = this.getUserGroupById(id)
				return {
					label: group.description,
					value: id,
					data: group,
				}
			})
		const sortedDropdown = _.sortBy(dropdown, 'label')
		if (hasNullOption) {
			sortedDropdown.unshift({ label: 'Select Group', value: null, data: null })
		}
		return sortedDropdown
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateUserGroupRecords(records: Array<UserGroupRecord>) {
		const newRecords = records.map((rec) => new UserGroupRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.userGroups.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.userGroups.push(newRecord)
			}
		}
	}

	// Methods for handling allowed companies

	setAllowedCompaniesRecords(records: Array<CompanyRecord>) {
		this.allowedCompaniesList = records.map((rec) => new CompanyRecord(rec))
		this.allowedCompaniesDataLoaded = true
	}

	// Methods for handling call-in phone number list

	setPhoneRecords(records: Array<any>) {
		// this.callInNumbers = records.filter((c) => c.route_application === 'TTS_PROD').map((c) => c.full_number)
		// this.callInNumbersE164 = records.filter((c) => c.route_application === 'TTS_PROD').map((c) => c.number_e164)
		this.callInNumbersE164 = records.map((rec) => new PhoneNumberRecord(rec))
		this.callInNumbersDataLoaded = true
		this.phoneNumbersUpdatedEvent.next(true)
		log('Call In Phone Records', this.callInNumbersE164)
	}

	getPhoneNumberLabel(record: PhoneNumberRecord) {
		return record.phoneNumberLabel
	}

	// START - Methods for working with Permission Roles

	getUserRoles(): Array<UserRoleRecord> {
		return this.userRoles
	}

	getUserRoleById(id: number) {
		return this.userRoles.find((role) => role.id === id)
	}

	getUsersByRoleId(id: number): Array<UserRecord> {
		return this.getUsers().filter((u) => u.supervisor_role_id === id)
	}

	setUserRoleRecords(records: Array<UserGroupRecord>) {
		this.userRoles = records.map((rec) => new UserRoleRecord(rec))
		this.userRolesDataLoaded = true
		this.userRolesChanged.next(true)
	}

	removeLocalUserRoleRecord(recordId: number) {
		this.userRoles = this.userRoles.filter((rec) => rec.id !== recordId)
	}

	getUserRolesDropdownData(hasNullOption: boolean, nullOptionLabel: string): SelectItem[] {
		const systemRoles = _.orderBy(
			this.getUserRoles().filter((role) => role.isSystemRole()),
			'id',
			['asc'],
		)
		const customRoles = _.orderBy(
			this.getUserRoles().filter((role) => !role.isSystemRole()),
			'description',
			['asc'],
		)
		const sortedRoles = [...customRoles, ...systemRoles]
		const dropdown = sortedRoles
			.map((e) => e.id)
			.map((id) => {
				const role = this.getUserRoleById(id)
				return {
					label: role.description,
					value: id,
					data: role,
				}
			})
		dropdown.forEach((dd) => {
			if (dd.data?.isSystemRole()) {
				dd.label = 'System - ' + dd.label
			} else {
				dd.label = 'Custom - ' + dd.label
			}
		})
		if (hasNullOption) dropdown.unshift({ label: nullOptionLabel ?? 'Select Template', value: null, data: null })
		return dropdown
	}

	addOrUpdateUserRoleRecords(records: Array<UserGroupRecord>) {
		const newRecords = records.map((rec) => new UserRoleRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.userRoles.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.userRoles.push(newRecord)
			}
			this.userRolesChanged.next(true)
		}
	}

	// END - Methods for working with Permission Roles

	// Methods for working with timezones

	getAllTimezones() {
		return this.timezoneList
	}

	getSupportedTimezones() {
		return this.timezoneList.filter((rec) => rec.active)
	}

	getTimezoneForId(id) {
		return this.timezoneList.find((rec) => rec.zone_id === id)
	}

	getTimezoneIdForZoneName(name: string) {
		const tz = this.timezoneList.find((rec) => rec.zone_name === name)
		return tz.zone_id
	}

	getTimezoneZoneNameForId(id: number) {
		const tz = this.getTimezoneForId(id)
		return tz ? tz.zone_name : 'Unknown/Timezone'
	}

	getTimezoneDisplayNameForId(id: number) {
		const tz = this.getTimezoneForId(id)
		return tz ? (tz.display_name ? tz.display_name : tz.zone_name) : 'Unknown/Timezone'
	}

	getTimezoneDisplayNameForZoneName(zoneName: string) {
		const tz = this.timezoneList.find((rec) => rec.zone_name === zoneName)
		return tz ? (tz.display_name ? tz.display_name : tz.zone_name) : 'Unknown/Timezone'
	}

	getCompanyDefaultTimezoneId(): number {
		const zoneName = this.getCompany().timezone
		return this.getTimezoneIdForZoneName(zoneName)
	}

	getCompanyDefaultTimezoneZoneName(): string {
		return this.getCompany().timezone
	}

	setTimezoneRecords(records: Array<ITimezone>) {
		const list = records.map((rec) => new Timezone(rec))
		const namedItems = list.filter((tz) => tz.display_name !== null)
		const sortedNamedItems = _.orderBy(namedItems, 'order')
		const unNamedItems = list.filter((tz) => tz.display_name === null)
		const sortedUnNamedItems = _.orderBy(unNamedItems, 'zone_name')
		this.timezoneList = [...sortedNamedItems, ...sortedUnNamedItems]
		this.timezoneDataLoaded = true
	}

	getWkstRelativeDayOptions() {
		const dayOptions = [...ScheduleOptions.dayOptions]
		const company = this.getCompany()
		const wkst = company.wkst || 0
		if (!wkst || wkst < 0 || wkst > 6) {
			return dayOptions
		}
		const comps = dayOptions.splice(wkst)
		const newDayOptions = [...comps, ...dayOptions]
		return newDayOptions
	}

	getCompanyCurrencySymbol(): CurrencySymbol {
		const regionCode = this.getCompany().country_iso as RegionCode
		return CurrencyHelper.getCurrencySymbolForRegion(regionCode)
	}

	getTaxLocations(dbSrvc: DatabaseService): Array<string> {
		const homeTaxLocations = dbSrvc.empSrvc.getHomeTaxLocations()
		const workTaxLocations = dbSrvc.jobSrvc.getWorkTaxLocations()
		const locations = [...homeTaxLocations, ...workTaxLocations]
		const result = [...new Set(locations)]
		return _.sortBy(result)
	}

	// Account Status Info

	public trialExpiresDate(): string {
		const company = this.getCompany()
		return this.getStripeTrialExpiresDate() || company.trial_expires
	}

	public hasTrialExpired(): boolean {
		const company = this.getCompany()
		const trialExpires = this.trialExpiresDate()
		const timezone = company.timezone
		const trialExpiresDate = moment.tz(trialExpires, 'YYYY-MM-DD', timezone)
		if (trialExpiresDate.isBefore(moment(), 'day')) return true
		return false
	}

	private getStripeTrialExpiresDate(): string {
		const stirpeInfo = Global.coreSrvc.dbSrvc.billSrvc.stripeCustomerInfo
		const trialEndTs = stirpeInfo?.customer?.subscriptions?.data[0]?.trial_end
		if (trialEndTs) {
			const timezone = this.getCompany().timezone
			return moment.unix(trialEndTs).tz(timezone).format('YYYY-MM-DD')
		}
		return null
	}

	public hasCardOnFile(): boolean {
		return Global.coreSrvc.dbSrvc.billSrvc.paypalHasCardOnFile()
	}

	public isPrepaid(): boolean {
		const company = this.getCompany()
		if (company && company.account_status === 'PREPAID') {
			return true
		}
		return false
	}

	public isInvoice(): boolean {
		const company = this.getCompany()
		if (company && (company.account_status === 'INVOICE' || company.account_status === 'ADP_INVOICE')) {
			return true
		}
		return false
	}

	public isTestingAccount(): boolean {
		const company = this.getCompany()
		if (company && company.account_status === 'TESTING') {
			return true
		}
		return false
	}

	// Returns the domain:port of current window
	getOriginUrl(): string {
		return window.location.origin
	}

	copyToClipboard(text: string) {
		const clipBoardElem = document.createElement('input')
		document.body.appendChild(clipBoardElem)
		clipBoardElem.value = text
		clipBoardElem.select()
		const successfulCopy = document.execCommand('copy')
		document.body.removeChild(clipBoardElem)
	}

	public emailSupportForTimeEntry(url: string, msg: string) {
		log('Got Here')
		const userId = this.getMyActualUser().id ?? null
		const companyId = this.getCompany()?.id ?? null

		const date = moment().toISOString()
		const dateMsg = `Date: ${date}\n`
		const trackIdMsg = `TTS Tracking ID: ${uuid()}\n`
		const linkMsg = `Link: ${url}\n`

		const devDetect = Global.coreSrvc.devDetect
		const devInfo = new DeviceDetectorInfo(devDetect)
		const devInfoPruned = new DeviceDetectorInfoPruned(devInfo)
		const lastErrorMsg = Global.coreSrvc.dbSrvc.lambdaSrvc.lastErrorMsg

		const dataObject = {
			ver: AppVersion.version,
			ids: { uid: userId, cid: companyId },
			devInfo: devInfoPruned,
			lastError: lastErrorMsg,
		}
		const jsonData = JSON.stringify(dataObject)
		const btoaDevMsg = `\n-- Session Data --\n\n${btoa(jsonData)}\n`

		let message = `${msg}\n\n${dateMsg}${linkMsg}${trackIdMsg}${btoaDevMsg}\n\n`

		const encodedSubject = `${encodeURIComponent('Support Request (Time Entry)')}`
		const encodedBody = `${encodeURIComponent(message)}`
		const mailLink = `mailto:support@telephonetimesheets.com?body=${encodedBody}&subject=${encodedSubject}`

		// Open the Email link
		window.location.href = mailLink
	}

	public emailSupportForGenericRecord(section: string, tableName: DatabaseTableName, recordId: number, msg: string) {
		const userId = Global.coreSrvc.dbSrvc.settingSrvc.getMyActualUser().id ?? null
		const companyId = Global.coreSrvc.dbSrvc.settingSrvc.getCompany()?.id ?? null

		const sectionInfo = `Section: ${section}\n`
		const companyUserInfo = `Company: ${companyId} / User: ${userId}\n`
		const recordTableInfo = `Record: ${recordId} (${tableName})\n`
		const trackIdMsg = `TTS Tracking ID: ${uuid()}\n`

		const message = msg ? `${msg}\n\n` : `\n\n-- Please describe the issue you are experiencing --\n\n`
		const data = `${sectionInfo}${companyUserInfo}${recordTableInfo}${trackIdMsg}\n\n`

		const encodedSubject = `${encodeURIComponent(`Support Request (${section})`)}`
		const encodedBody = `${encodeURIComponent(message + data)}`
		const mailLink = `mailto:support@telephonetimesheets.com?body=${encodedBody}&subject=${encodedSubject}`

		// Open the Email link
		window.location.href = mailLink
	}
}

class DeviceDetectorInfoPruned {
	browser: string = ''
	browserVersion: string = ''
	device: string = ''
	deviceType: string = ''
	orientation: string = ''
	os: string = ''
	osVersion: string = ''
	constructor(data?: any) {
		if (data) {
			for (const attr in data) {
				if (this.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
	}
}

const userRolesMock = [
	new UserRoleRecord({
		id: 1,
		description: 'User Role Mock',
		permissions_json:
			'[{"key":"employee","values":[{"data":"","type":"ACCESS"},{"data":"EMS:O","type":"SELECT"},{"data":"EMPPRF:D","type":"OPTION"}]},{"key":"location","values":[{"data":"","type":"ACCESS"},{"data":"ELN:O","type":"SELECT"}]},{"key":"job","values":[{"data":"","type":"ACCESS"},{"data":"SITE:O","type":"SELECT"}]},{"key":"schedule_recur","values":[{"data":"","type":"ACCESS"},{"data":"EMP:O","type":"SELECT"},{"data":"JOB:O","type":"SELECT"}]},{"key":"vacation","values":[{"data":"","type":"ACCESS"},{"data":"EMP:O","type":"SELECT"}]},{"key":"transaction_log","values":[{"data":"","type":"ACCESS"},{"data":"EMP:O","type":"SELECT"}]},{"key":"announcements","values":[{"data":"EMP:O","type":"SELECT"},{"data":"SITE:O","type":"SELECT"}]},{"key":"users","values":[{"data":"N","type":"ACCESS"},{"data":"","type":"OWNER"}]},{"key":"client","values":[{"data":"","type":"ACCESS"}]},{"key":"company","values":[{"data":"N","type":"ACCESS"},{"data":"","type":"OWNER"}]},{"key":"billing_log","values":[{"data":"N","type":"ACCESS"},{"data":"","type":"OWNER"}]},{"key":"adp_sync","values":[{"data":"N","type":"ACCESS"},{"data":"","type":"OWNER"}]},{"key":"file_import_sync","values":[{"data":"N","type":"ACCESS"},{"data":"","type":"OWNER"}]},{"key":"qbo_sync","values":[{"data":"N","type":"ACCESS"},{"data":"","type":"OWNER"}]},{"key":"google_cal_sync","values":[{"data":"N","type":"ACCESS"},{"data":"","type":"OWNER"}]},{"key":"wiw_sync","values":[{"data":"N","type":"ACCESS"},{"data":"","type":"OWNER"}]}]',
	}),
]
