import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { log } from '@app/helpers'
import {
	ContactOrganizationLinkCard,
	ContactHelper,
	ContactOrganizationLinkRecord,
	ContactRecord,
	DialogManager,
	OrganizationSelectItem,
} from '@app/models'
import { DatabaseService } from '@app/services'
import { SelectItem } from 'primeng/api'
import _ from 'lodash'

@Component({
    selector: 'app-link-contact-org-edit',
    templateUrl: './link-contact-org-edit.component.html',
    styleUrls: ['./link-contact-org-edit.component.scss'],
    standalone: false
})
export class LinkContactOrgEditComponent implements OnInit, AfterViewInit {
	record: ContactOrganizationLinkRecord
	form: UntypedFormGroup

	isUpdating = false

	@Input() source: 'CONTACT' | 'ORGANIZATION'
	@Input() dialogManager: DialogManager // dialogData is ContactOrganizationLinkRecord
	@Output() recordSaved = new EventEmitter<boolean>()
	@Output() cancelEdit = new EventEmitter<boolean>()

	contactOptions: Array<SelectItem> = []
	organizationOptions: Array<OrganizationSelectItem> = []
	associationOptions = ContactHelper.getContactTypeDropdown()

	constructor(
		private dbSrvc: DatabaseService,
		private fb: UntypedFormBuilder,
	) {
		this.setupDropdowns()
	}

	get isNew(): boolean {
		return !this.record.id
	}

	get contactName(): string {
		return this.dbSrvc.contactSrvc.getContactById(this.record?.contact_id)?.name ?? 'Unknown Contact'
	}

	get organizationName(): string {
		return this.dbSrvc.orgSrvc.getOrganizationById(this.record?.vendor_client_id)?.name ?? 'Unknown Client / Vendor'
	}

	get orgType(): string {
		const selectedOrgId = this.form.get('vendor_client_id').value
		const orgType = this.dbSrvc.orgSrvc.getOrganizationById(selectedOrgId)?.type
		return orgType ?? ''
	}

	ngOnInit(): void {
		this.record = this.dialogManager.dialogData as ContactOrganizationLinkRecord
		this.setupForm()
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.dialogManager.submitBtnAction = () => this.submitBtnClicked()
			this.dialogManager.cancelBtnAction = () => this.cancelBtnClicked()
		}, 150)
	}

	private setupDropdowns() {
		// Setup contact options
		this.contactOptions = this.dbSrvc.contactSrvc.getContactDropdownData()

		// Setup organization options
		const organizationOptions = this.dbSrvc.orgSrvc.getOrganizationDropdownData()
		for (const opt of organizationOptions) {
			if (opt.data.type === 'CLIENT') {
				opt.label = '[CLIENT] ' + opt.label
			} else {
				opt.label = '[VENDOR] ' + opt.label
			}
		}
		this.organizationOptions = _.orderBy(organizationOptions, 'label')
	}

	private setupForm() {
		const record = this.record
		this.form = this.fb.group({
			id: [record.id],
			contact_id: [record.contact_id],
			vendor_client_id: [record.vendor_client_id],
			notify: [record.notify],
			details: [record.details],
			association_type: [record.association_type],
		})
	}

	public toggleCheckbox(prop: string) {
		log('prop', prop)
		const current = this.form.get(prop).value
		this.form.get(prop).setValue(!current)
	}

	submitBtnClicked() {
		const record = this.makeUpdateRecord()
		if (!record) return
		this.isUpdating = true
		if (this.isNew) {
			this.dbSrvc.insertRecord('vendor_client_contact', record).then((insertSuccess) => {
				if (insertSuccess) {
					this.recordSaved.next(true)
				} else {
					this.isUpdating = false
				}
			})
		} else {
			this.dbSrvc.updateRecord('vendor_client_contact', record).then((updateSuccess) => {
				if (updateSuccess) {
					this.recordSaved.next(true)
				} else {
					this.isUpdating = false
				}
			})
		}
	}

	cancelBtnClicked() {
		this.cancelEdit.next(true)
	}

	makeUpdateRecord(): ContactOrganizationLinkRecord {
		const newRecord = this.record.clone()
		const formProps = this.form.value as ContactOrganizationLinkRecord
		newRecord.updateProperties(formProps)
		return newRecord
	}
}
