<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Manage Notifications"
	[(visible)]="manager.isDialogVisible"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="false"
	[focusOnShow]="false"
	(onShow)="configureData()"
>
	<div style="margin: 8px">
		<div class="options-block" style="margin-top: 16px">
			<div>
				<div *ngIf="date" class="link-text float-right" (click)="date = null">clear</div>
				<div class="options-title-inline">Current Lock Date</div>
			</div>
			<app-date-time-picker
				[currentDate]="date"
				[pickerType]="'DATE'"
				[pickerMode]="'dialog'"
				[pickerLabel]="'Current Lock Date'"
				[format]="'ddd MMM Do'"
				(pickerDidClose)="date = $event"
			></app-date-time-picker>
		</div>
		<div class="options-block">
			<div class="text-muted">
				Any time entry with a job date on or before the date indicated will be soft locked. These entries will require explicit confirmation for
				any modifications and an alert email will be sent to any supervisor subscribed to these notifications.
			</div>
		</div>
		<div style="margin-top: 24px">
			<div class="row">
				<div class="col-6">
					<button class="btn btn-sm btn-block btn-secondary" (click)="cancelDateLock()">Cancel</button>
				</div>
				<div class="col-6">
					<button class="btn btn-sm btn-block btn-success" (click)="updateDateLock()">
						<i *ngIf="manager.isUpdating" class="fa fa-loader fa-spin"></i>
						<span *ngIf="!manager.isUpdating">Update</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</p-dialog>
