<div class="public-container">
	<form novalidate (submit)="onNext()" role="form" autocomplete="off" name="verificationForm">
		<!-- <form class="form-signin" method="POST" action="#" role="form" autocomplete="off" name="verificationForm"> -->
		<div [hidden]="isWorking">
			<div class="form-group">
				<h2 style="color: #777">Reset Password</h2>
			</div>
			<hr />
			<div *ngIf="errorMessage" class="alert alert-danger" style="line-height: 1.1em">{{ errorMessage }}</div>

			<div hidden="true" class="form-group">
				<label class="form-label">Mobile Number</label>
				<div>
					<input
						id="username"
						type="text"
						maxlength="50"
						class="form-control"
						[ngModel]="username"
						[ngModelOptions]="{standalone: true}"
						autocomplete="username"
					/>
				</div>
			</div>
			<div class="form-group">
				<label class="form-label" for="newPassword">New Password</label>
				<span *ngIf="isPasswordValid()" class="pass-valid float-right">valid</span>
				<input
					pPassword
					id="newPassword"
					type="password"
					maxlength="25"
					class="form-control"
					length="40"
					[(ngModel)]="password"
					[ngModelOptions]="{standalone: true}"
					autocomplete="new-password"
				/>
				<div class="input-field-notes">Passwords must be at least <strong>8</strong> characters long and include both letters and numbers.</div>
			</div>
			<br />
			<div class="alert alert-info alert-bg-gray" [class.code-valid]="isCodeValid()">
				<div class="form-group">
					<label class="form-label">Verification Code</label>
					<input
						id="verificationCode"
						type="tel"
						maxlength="50"
						class="form-control"
						[(ngModel)]="verificationCode"
						[ngModelOptions]="{standalone: true}"
						autocomplete="one-time-code"
					/>
				</div>
			</div>
			<br />
			<div class="form-group">
				<button (click)="onNext()" [disabled]="!verificationCode || !password" id="signupSubmit" type="button" class="btn btn-primary btn-block">
					Reset Password
				</button>
			</div>
			<hr style="margin-bottom: 0px; margin-top: 30px" />
			<div style="text-align: center; margin-top: 10px">
				<a [routerLink]="['/home/forgotPassword']"> Start Over</a>
			</div>
			<!-- <p>
			<a [routerLink]="['/home/resendCode']"> <i class="fa fa-fw fa-group"></i> Go back</a>
		</p> -->
		</div>
		<div *ngIf="isWorking && !showSuccess" align="center">
			<h4><i class="fas fa-loader fa-spin" aria-hidden="true"></i> Resetting password</h4>
		</div>
		<div *ngIf="showSuccess" align="center">
			<strong>Your password has been reset</strong>
			<br />
			<br />
			<button (click)="navigateToLogin()" class="btn btn-primary">Login</button>
		</div>
	</form>
</div>
