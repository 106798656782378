<div class="container">
	<span class="section-header"><i class="fa fa-trash" aria-hidden="true"></i> Delete Employee</span><br />
	<div style="color: gray; font-weight: bold">Name: {{ employeeName }}</div>
	<div>
		<span style="color: gray; font-weight: bold">Status: </span>
		<span class="table-tag" [class.bg-chocolate]="!isActive" [class.bg-green]="isActive">{{ isActive ? 'Active' : 'Inactive' }}</span>
	</div>

	<div *ngIf="!employeeExists()" class="alert alert-warning delete-alert" role="alert">
		<strong>Record Not Found</strong>
	</div>

	<div>
		<div *ngIf="!requirementsMet()" class="alert alert-warning delete-alert" role="alert">
			<strong>Warning:</strong> This employee is currently scheduled for one or more jobs. These schedule items will also be removed when you
			delete this employee.
		</div>

		<div *ngIf="requirementsMet()"><br /></div>

		<div
			*ngIf="employeeExists() && requirementsMet()"
			class="card card-body section-requirement text-center"
			style="max-width: 350px; margin: 0 auto"
		>
			<div *ngIf="!isDeleting">
				<div class="are-you-sure">Are you sure you want to delete this employee</div>
				<div class="confirm-delete-btn-wrap">
					<button (click)="onCancel()" class="btn btn-sm btn-secondary">Cancel</button>
					<button (click)="deleteEmployee()" class="btn btn-sm btn-danger btn-confirm-delete">Delete</button>
				</div>
			</div>
			<div *ngIf="isDeleting" class="delete-diag-working" style="font-size: 1.6em"><i class="fas fa-loader fa-spin"></i> Deleting Record...</div>
		</div>

		<div *ngIf="schedulesExist()" class="card card-body section-requirement">
			<p class="card-text">
				<span class="section-req-title"><i class="fa fa-calendar" aria-hidden="true"></i> Schedule ({{ schedulesCount() }})</span>
				<span class="float-right">
					<button [routerLink]="scheduleViewRouterLink" class="btn btn-success btn-sm btn-req-setup" title="view">view</button>
				</span>
			</p>
		</div>

		<div *ngIf="!requirementsMet()">
			<div class="row">
				<div class="col-12 col-sm-6">
					<div><button (click)="onCancel()" class="btn btn-secondary btn-block action-button">Cancel Operation</button></div>
				</div>
				<div class="col-12 col-sm-6">
					<div><button (click)="deleteEmployee()" class="btn btn-danger btn-block action-button">Delete Employee</button></div>
				</div>
			</div>
		</div>
	</div>
</div>
