import { DateTimeHelper } from '@app/helpers'

// If you adjust ImageFile or ImageFileJsonData, make sure to update the other with
export class ImageFile {
	name = ''
	description = ''
	type = ''
	width: number
	height: number
	orient: number
	ts: number
	key: string
	bucket: string
	sha256: string
	qrcode: string
	isLoaded = false
	hasError = false

	constructor(record: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
	}

	getUrl(): string {
		return 'https://' + this.bucket + '/' + this.key
	}
}

export interface IReportImagesPayload {
	files: Array<IReportImageFile>
}

export class IReportImageFile {
	bucket: string
	key: string
	name: string
	linkText: string
	description: string
	ts: number
	size: number
	type: string
	sha256: string
	qrcode: string
	// companyId: number
	// directory: string
	// date: string
	// fileId: String
}

export class ReportImageFile implements IReportImageFile {
	bucket = ''
	key = ''
	name = ''
	linkText = ''
	description = ''
	ts = 0
	size = 0
	type = ''
	sha256: string = ''
	qrcode: string = ''
	// companyId = 0
	// directory = ''
	// date = ''
	// fileId = ''

	isLoaded = false
	hasError = false

	constructor(record: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
		if (!this.linkText) {
			this.linkText = this.name
		}
	}

	getUrl(): string {
		return 'https://' + this.bucket + '/' + this.key
	}

	getJson() {
		const newRecord = new ReportImageFile(this)
		delete newRecord.isLoaded
		delete newRecord.hasError
		return newRecord
	}
}

export class DuplicateImageRecord {
	id: number
	created: string
	employee_id: number
	company_id: number
	transaction_log_id: number
	transaction_log_uuid: string
	images_json: string
	updated: string
	job_date: string

	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
		}
	}
}
