import { Injectable } from '@angular/core'

import {
	AnnouncementsViewManager,
	CheckinAnnouncementRecord,
	ScheduledAnnouncement,
	ScheduledAnnouncementRecord,
	SendAnnouncementEvent,
} from '@app/models'

import { log } from '@app/helpers'
import _ from 'lodash'
import { Subject } from 'rxjs'
import { Global } from '@app/models/global'

@Injectable({ providedIn: 'root' })
export class AnnouncementService {
	viewManager = new AnnouncementsViewManager()

	checkinDataLoaded = false
	checkinList: CheckinAnnouncementRecord[] = []
	checkinLastUpdated: Date

	scheduledDataLoaded = false
	scheduledList: ScheduledAnnouncementRecord[] = []
	scheduledLastUpdated: Date

	// Triggers installation of announcements nav bar icon in header component
	installNavBarIcon = new Subject<boolean>()

	// Events
	sendAnnouncementEvent = new Subject<SendAnnouncementEvent>()

	constructor() {
		log('Creating AnnouncementService')
	}

	checkinCount(): number {
		return this.checkinList.length
	}

	clearData() {
		this.clearCheckinData()
		this.clearScheduledData()
	}

	clearCheckinData() {
		this.checkinDataLoaded = false
		this.checkinList = []
		this.checkinLastUpdated = null
	}

	clearScheduledData() {
		this.scheduledDataLoaded = false
		this.scheduledList = []
		this.scheduledLastUpdated = null
	}

	getCheckinAnnouncements(): Array<CheckinAnnouncementRecord> {
		return this.checkinList
	}

	getScheduledAnnouncements(): Array<ScheduledAnnouncementRecord> {
		return this.scheduledList
	}

	getCheckinAnnouncementById(id: number): CheckinAnnouncementRecord {
		const vac = this.checkinList.find((v) => v.id === id)
		return vac ? vac : null
	}

	setCheckinAnnouncementRecords(records: Array<CheckinAnnouncementRecord>) {
		this.checkinLastUpdated = new Date()
		this.checkinList = records.map((c) => new CheckinAnnouncementRecord(c))
		this.checkinDataLoaded = true
	}

	setScheduledAnnouncementRecords(records: Array<CheckinAnnouncementRecord>) {
		this.scheduledLastUpdated = new Date()
		this.scheduledList = records.map((c) => new ScheduledAnnouncementRecord(c))
		this.scheduledDataLoaded = true
	}

	removeLocalCheckinAnnouncementRecord(recordId: number) {
		this.checkinList = this.checkinList.filter((rec) => rec.id !== recordId)
	}

	removeLocalScheduledAnnouncementRecord(recordId: number) {
		this.scheduledList = this.scheduledList.filter((rec) => rec.id !== recordId)
	}

	updateCheckinAnnouncementRecords(records: Array<CheckinAnnouncementRecord>) {
		log('updateVacRecords', records)
		for (const newRecord of records) {
			if (newRecord) {
				const currentRecord = this.checkinList.find((rec) => rec.id === newRecord.id)
				if (currentRecord) {
					for (const attr in newRecord) {
						if (newRecord.hasOwnProperty(attr)) {
							currentRecord[attr] = newRecord[attr]
						}
					}
				}
			}
		}
	}

	updateScheduledAnnouncementRecords(records: Array<CheckinAnnouncementRecord>) {
		log('updateVacRecords', records)
		for (const newRecord of records) {
			if (newRecord) {
				const currentRecord = this.scheduledList.find((rec) => rec.id === newRecord.id)
				if (currentRecord) {
					for (const attr in newRecord) {
						if (newRecord.hasOwnProperty(attr)) {
							currentRecord[attr] = newRecord[attr]
						}
					}
				}
			}
		}
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateCheckinAnnouncementRecords(records: Array<CheckinAnnouncementRecord>) {
		const newRecords = records.map((rec) => new CheckinAnnouncementRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.checkinList.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.checkinList.push(newRecord)
			}
		}
	}

	addOrUpdateScheduledAnnouncementRecords(records: Array<ScheduledAnnouncementRecord>) {
		const newRecords = records.map((rec) => new ScheduledAnnouncementRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.scheduledList.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.scheduledList.push(newRecord)
			}
		}
	}
}
