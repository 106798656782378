import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { log } from '@app/helpers'
import {
	ChecklistFormItem,
	ChecklistReport,
	ChecklistReportData,
	DialogManager,
	EmployeeChecklist,
	EmployeeChecklistSearchFilter,
	EmployeeChecklistType,
	IUserReportFormat,
	Incident,
	DatabaseTableName,
	TransactionLogRecord,
	UserReportChecklistConfig,
	UserReportIndustryType,
	UserReportType,
} from '@app/models'
import { Global } from '@app/models/global'
import { CoreService, DatabaseService } from '@app/services'

import moment from 'moment-timezone'
import { DeviceDetectorService } from 'ngx-device-detector'
import { SelectItem } from 'primeng/api'

@Component({
    selector: 'app-employee-checklist-list',
    templateUrl: './employee-checklist-list.component.html',
    styleUrls: ['./employee-checklist-list.component.scss'],
    standalone: false
})
export class EmployeeChecklistListComponent implements OnInit, AfterViewInit, AfterContentInit {
	searchFilter = new EmployeeChecklistSearchFilter()

	status = { isLoading: true, isEditing: false }

	list: Array<EmployeeChecklistViewModel> = []
	currentType: 'ALL' | 'CHECKIN' | 'POST_BREAK' | 'CHECKOUT' | 'CUSTOM' = 'CHECKIN'

	selectedRecord: EmployeeChecklist
	deleteAction = { tableName: 'employee_checklist' as DatabaseTableName, recordId: null, recordLabel: null, showDeleteRecordDialog: false }

	preview = { isPreparing: false, empId: null, jobId: null, surveyId: null, show: false, reportData: null, reportConfig: null }
	checklistReport: ChecklistReport = null
	employeeOptions: Array<SelectItem> = []
	jobOptions: Array<SelectItem> = []

	@Input() dialogManager = new DialogManager()
	@Output() recordUpdated = new EventEmitter<boolean>()

	constructor(private coreSrvc: CoreService) {
		this.setupDropdownOptions()
		this.loadData()
		// this.dbSrvc.readTable('employee_checklist').then(success => {
		// 	this.loadData()
		// 	this.status.isLoading = false
		// })
	}

	get sectionList(): Array<EmployeeChecklistViewModel> {
		if (this.currentType === 'ALL') return this.list
		if (this.currentType === 'CHECKIN') return this.list.filter((vm) => vm.checklist.type === 'CHECKIN')
		if (this.currentType === 'POST_BREAK') return this.list.filter((vm) => vm.checklist.type === 'POST_BREAK')
		if (this.currentType === 'CHECKOUT') return this.list.filter((vm) => vm.checklist.type === 'CHECKOUT')
		if (this.currentType === 'CUSTOM') return this.list.filter((vm) => vm.checklist.type === 'CUSTOM')
		return []
	}

	get customPreviewList(): Array<EmployeeChecklistViewModel> {
		let list = this.list.filter((vm) => vm.checklist.type === 'CUSTOM')
		if (this.preview.empId)
			list = list.filter((vm) => vm.checklist.employeeIds.length === 0 || vm.checklist.employeeIds.includes(this.preview.empId))
		if (this.preview.jobId) list = list.filter((vm) => vm.checklist.jobIds.length === 0 || vm.checklist.jobIds.includes(this.preview.jobId))
		return list
	}

	get hasVisibleRecords(): boolean {
		const visibleRecords = this.sectionList.filter((vm) => vm.isVisible)
		return visibleRecords.length > 0
	}

	get hasExpandedCards(): boolean {
		for (const item of this.list) {
			if (item.listVisible.listView || item.listVisible.checkList) {
				return true
			}
		}
		return false
	}

	get devDetect(): DeviceDetectorService {
		return this.coreSrvc.devDetect
	}

	get surveyTypeLabel(): string {
		switch (this.currentType) {
			case 'CHECKIN':
				return 'check-in'
			case 'POST_BREAK':
				return 'post-break'
			case 'CHECKOUT':
				return 'check-out'
			case 'CUSTOM':
				return 'custom'
			default:
				return ''
		}
	}

	public ngOnInit(): void {}
	public ngAfterViewInit(): void {
		// setTimeout(() => {
		// 	this.dialogManager.submitBtnAction = () => this.newRecord()
		// }, 150)
	}

	public ngAfterContentInit() {
		this.dialogManager.submitBtnAction = () => this.newRecord()
	}

	private setupDropdownOptions() {
		this.employeeOptions = this.coreSrvc.dbSrvc.empSrvc.getEmployeeDropdown(this.coreSrvc.dbSrvc, false, false, [])
		this.jobOptions = this.coreSrvc.dbSrvc.jobSrvc.getJobDropdown(this.coreSrvc.dbSrvc, false, false, false, [])
	}

	private loadData(): Promise<Array<EmployeeChecklistViewModel>> {
		log('Load Data Strat')
		return new Promise<Array<EmployeeChecklistViewModel>>((resolve, reject) => {
			this.status.isLoading = true
			this.coreSrvc.dbSrvc.readTable('employee_checklist').then((success) => {
				log('Read Table Returned')
				this.refreshData()
				this.status.isLoading = false
				log('Final List', this.list)
				resolve(this.list)
			})
		})
	}

	private refreshData(withDelay?: number) {
		const delay = withDelay ? withDelay : 0
		setTimeout(() => {
			this.list = this.coreSrvc.dbSrvc.empChklstSrvc
				.getEmployeeChecklists()
				.map((r) => new EmployeeChecklist(r))
				.map((cl) => new EmployeeChecklistViewModel(this.coreSrvc.dbSrvc, cl))
		}, delay)
	}

	public toggleFilter(type: 'ALL' | 'CHECKIN' | 'POST_BREAK' | 'CHECKOUT' | 'CUSTOM') {
		this.currentType = type
		this.applySearchFilter(this.searchFilter)
	}

	public switchToEditFromListMode() {
		this.dialogManager.saveScrollPosition('ecList')
		this.dialogManager.pushState()
		this.status.isEditing = true
		this.dialogManager.scrollToTop()
		log('Current Selected Record', this.selectedRecord)
	}

	public switchFromEditToListMode() {
		this.status.isEditing = false
		this.dialogManager.popStateAndApply()
	}

	public recordSaved(event) {
		const recordId = event as number
		log('Employee checklist received recordSaved event for', recordId)
		this.status.isEditing = false
		// this.loadData()
		this.refreshData()
		this.switchFromEditToListMode()
		this.recordUpdated.emit(true)
	}

	public newRecord() {
		const record = new EmployeeChecklist()
		record.type = 'CHECKOUT'
		this.selectedRecord = record
		this.switchToEditFromListMode()
	}

	public editRecord(vm: EmployeeChecklistViewModel) {
		log('Edit Record Clicked')
		this.selectedRecord = vm.checklist.clone()
		this.switchToEditFromListMode()
	}

	public cancelEdit() {
		log('Cancel edit received from edit form')
		this.switchFromEditToListMode()
	}

	public cloneRecord(vm: EmployeeChecklistViewModel) {
		log('Clone Record Clicked')
		const record = vm.checklist.clone()
		record.id = null
		record.originalRecord = null
		record.title = record.title + ' (copy)'
		this.selectedRecord = record
		this.switchToEditFromListMode()
	}

	public deleteRecord(vm: EmployeeChecklistViewModel) {
		log('Delete Record Clicked')
		const checklist = vm.checklist
		this.deleteAction.recordId = checklist.id
		this.deleteAction.recordLabel = checklist.name
		this.deleteAction.showDeleteRecordDialog = true
	}

	public deleteActionComplete() {
		log('Delete Record Complete')
		this.refreshData()
		this.deleteAction.showDeleteRecordDialog = false
	}

	public updateRecord(vm: EmployeeChecklistViewModel) {
		log('Update Record Called', vm)
	}

	public toggleEnabled(vm: EmployeeChecklistViewModel) {
		const checklist = vm.checklist
		log('Checklist Enabled', checklist.enabled)
		const record = checklist.buildUpdateRecord()
		this.coreSrvc.dbSrvc.updateRecord('employee_checklist', record).then((result) => {
			log('Record update complete')
		})
	}

	public applySearchFilter(filter: EmployeeChecklistSearchFilter) {
		log('List component received search filter from filter component', filter)
		this.searchFilter = filter
		for (const vm of this.list) {
			vm.applySearchFilter(filter)
		}
	}

	// Collapse the list view on cards
	public resetListViews() {
		for (const vm of this.list) {
			vm.resetView()
		}
	}

	public startPeview() {
		if (this.sectionList.length === 0) {
			// this.coreSrvc.notifySrvc.notify('error', 'Preview', `There are no survey blocks setup for the ${this.surveyTypeLabel} survey.`, 5)
			return
		}
		this.dialogManager.pushState()
		this.dialogManager.isSubmitBtnVisible = false
		this.dialogManager.cancelBtnLabel = 'Back'
		this.dialogManager.cancelBtnAction = () => this.endPreview()
		this.dialogManager.isBackBtnVisible = true
		this.dialogManager.backBtnAction = () => this.endPreview()
		this.preview.isPreparing = true
	}

	public endPreview() {
		this.dialogManager.popStateAndApply()
		this.preview.isPreparing = false
	}

	public returnFromPreview() {
		this.preview.show = false
		this.dialogManager.popStateAndApply()
		this.dialogManager.restoreScrollPosition('surveyPreview')
		this.dialogManager.setBackgroundColor('#fff')
	}

	public setupPreviewForCustom(vm: EmployeeChecklistViewModel) {
		log('CL-setupPreviewForCustom', vm.checklist.id)
		this.preview.surveyId = vm.checklist.id
		this.createNewPreviewReport(true)
	}

	public createNewPreviewReport(skipCheck = false) {
		// If not skipping the check, then it's one of the grouped surveys CHECKIN, POST_BREAK, or CHECKOUT
		// and we need to clear the survey id so the filtering works properly
		if (!skipCheck) this.preview.surveyId = null

		// We don't offer an all view anymore so this is basically deprecated
		if (this.currentType === 'ALL') return

		// If not skipping the check, then make sure an employee and job is selected because the
		// grouped surveys are dynamic and require an employee and job ID to filter with
		if (!skipCheck && (!this.preview.empId || !this.preview.jobId)) {
			this.coreSrvc.notifySrvc.notify(
				'error',
				'Preview',
				`You must select an employee and a job to preview their ${this.surveyTypeLabel} survey.`,
				5,
			)
			return
		}
		const type = this.currentType as EmployeeChecklistType

		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(this.preview.empId)
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(this.preview.jobId)
		const trans = skipCheck ? new TransactionLogRecord() : new TransactionLogRecord({ employee_id: emp.id, job_id: job.id })
		this.preview.reportConfig = this.getReportConfig(type, trans)
		this.preview.reportData = this.makeNewReport(type, trans)

		// If no report was generated then don't show preview
		if (!this.preview.reportData) return

		this.dialogManager.saveScrollPosition('surveyPreview')
		this.dialogManager.pushState()
		this.dialogManager.scrollToTop()
		this.dialogManager.setBackgroundColor('#eee')

		this.dialogManager.backBtnAction = () => this.returnFromPreview()
		this.dialogManager.cancelBtnAction = () => this.returnFromPreview()
		this.dialogManager.cancelBtnLabel = 'Back'

		this.dialogManager.isSubmitBtnVisible = false
		this.dialogManager.isBackBtnVisible = true
		this.preview.show = true
	}

	private makeNewReport(type: EmployeeChecklistType, trans: TransactionLogRecord): ChecklistReportData {
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(this.preview.empId)
		const transactionMetaData = trans.getReportMetaData()

		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const countryCode = company?.country_iso
		const industryType = (company?.industry ?? 'DEFAULT') as UserReportIndustryType
		const reportFormat: IUserReportFormat = { reportType: 'SHIFT', industryType: industryType }

		reportFormat.reportType = type
		const surveyReportData = new ChecklistReportData({
			format: reportFormat,
			countryCode: countryCode,
			transactionMetaData: transactionMetaData,
		})
		surveyReportData.configureChecklistOptions(this.preview.reportConfig, type, null)

		const surveyOptions = surveyReportData.employeeChecklists ?? []
		if (surveyOptions.length === 0) {
			this.coreSrvc.notifySrvc.notify(
				'error',
				'No Survey Blocks',
				`Based on your target settings, no ${this.surveyTypeLabel} survey will be generated for the selected employee and job.`,
				10,
			)
			return null
		}

		surveyReportData.employee.firstName.value = emp?.first ?? ''
		surveyReportData.employee.lastName.value = emp?.last ?? ''
		surveyReportData.shiftDate.value = moment.tz().toDate()

		return surveyReportData
	}

	getReportConfig(type: EmployeeChecklistType, trans: TransactionLogRecord) {
		// Setup Post Break Survey Data

		const checklists = this.preview.surveyId
			? this.coreSrvc.dbSrvc.empChklstSrvc.getEmployeeChecklists().filter((cl) => cl.id === this.preview.surveyId)
			: this.coreSrvc.dbSrvc.empChklstSrvc.getChecklistsForFilter(trans.employee_id, trans.job_id).filter((cl) => cl.type === type && cl.enabled)
		log('CL-getReportConfig', checklists)
		const checklistData = checklists.map((clr) => new EmployeeChecklist(clr).makeEmployeeChecklistData())
		const checklistConfig = new UserReportChecklistConfig()
		checklistConfig.setEmployeeChecklistData(checklistData)
		return checklistConfig
	}
}

type EmployeChecklistListKey = 'checklist' | 'empList' | 'siteList' | 'jobList'

class EmployeeChecklistViewModel {
	listItems: Array<ChecklistFormItem> = []

	empList: Array<string> = []
	siteList: Array<string> = []
	jobList: Array<string> = []

	checklist: EmployeeChecklist

	// Filter properties
	textMatchModel = '' // string of non list text data in model
	textMatchChecklist = ''
	textMatchEmployee = ''
	textMatchSite = ''
	textMatchJob = ''

	matchesText = true
	matchesEmpIds = true
	matchesSiteIds = true
	matchesJobIds = true

	public listVisible = { listView: false, checkList: false, empList: false, siteList: false, jobList: false }

	constructor(dbSrvc: DatabaseService, checklist: EmployeeChecklist) {
		// this.dbSrvc = dbSrvc
		this.checklist = checklist
		this.setupLists()
		this.setupStringMatcher()
	}

	get isVisible(): boolean {
		return this.matchesText && this.matchesEmpIds && this.matchesSiteIds && this.matchesJobIds
	}

	get isJobSiteMerged(): boolean {
		return Global.coreSrvc.dbSrvc.settingSrvc.getCompany().merge_jobsite
	}

	get checklistTitle(): string {
		return this.checklist.title ? this.checklist.title : '< No Checklist Title >'
	}

	setupLists() {
		// Setup Employees
		this.listItems = this.checklist.checklist
		this.empList = this.checklist.employeeIds
			.map((id) => Global.coreSrvc.dbSrvc.empSrvc.getEmployeeById(id))
			.filter((emp) => emp !== null)
			.map((emp) => emp.name)
		this.siteList = this.checklist.siteIds
			.map((id) => Global.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(id))
			.filter((site) => site !== null)
			.map((site) => site.description)
		this.jobList = this.checklist.jobIds
			.map((id) => Global.coreSrvc.dbSrvc.jobSrvc.getJobById(id))
			.filter((job) => job !== null)
			.map((job) => job.description)
	}

	setupStringMatcher() {
		const clName = this.checklist.name || ''
		const clDescription = this.checklist.description || ''
		const clTitle = this.checklist.title || ''
		const type = this.checklist.formattedType

		this.textMatchModel = `${clName} * ${type} * ${clDescription} * ${clTitle}`.toLowerCase()

		this.textMatchChecklist = this.checklist.checklist
			.map((cli) => this.getChecklistFormItemLabel(cli))
			.join(' * ')
			.toLowerCase()
		this.textMatchEmployee = this.empList.join(' * ').toLowerCase()
		this.textMatchSite = this.siteList.join(' * ').toLowerCase()
		this.textMatchJob = this.jobList.join(' * ').toLowerCase()
	}

	public getChecklistFormItemLabel(item: ChecklistFormItem) {
		// Check if we are dealing with V1 format of checklist_json item
		if (typeof item === 'string') return item

		// Handle V2 format for checklist_json item
		switch (item.type) {
			case 'CHECKBOXBASIC':
				return item.config.label
			case 'INPUTBASIC':
				return item.config.label
			case 'RANGEBASIC':
				return item.config.label
			case 'SELECTBASIC':
				return item.config.label
			default:
				return ''
		}
	}

	resetView() {
		this.listVisible.listView = false
		this.listVisible.checkList = false
		this.listVisible.empList = false
		this.listVisible.siteList = false
		this.listVisible.jobList = false
	}

	toggleListView() {
		log('toggleView Clicked')
		this.listVisible.checkList = false
		if (this.listVisible.listView) {
			this.listVisible.listView = false
		} else {
			if (!this.listVisible.siteList && !this.listVisible.empList && !this.listVisible.jobList) {
				this.showList('empList')
			}
			this.listVisible.listView = true
		}
	}

	toggleChecklist() {
		this.listVisible.listView = false
		this.listVisible.checkList = !this.listVisible.checkList
	}

	toggleList(list: string) {
		this.hideReferenceLists()
		this.listVisible[list] = !this.listVisible[list]
	}

	hideReferenceLists() {
		this.listVisible.empList = false
		this.listVisible.siteList = false
		this.listVisible.jobList = false
	}

	showList(list: EmployeChecklistListKey) {
		this.resetView()
		this.listVisible[list] = true
		this.listVisible.listView = true
	}

	showChecklist() {
		this.resetView()
		this.listVisible.checkList = true
	}

	applySearchFilter(filter: EmployeeChecklistSearchFilter) {
		this.processFilterForText(filter)
	}

	processFilterForText(filter: EmployeeChecklistSearchFilter) {
		const text = (filter.filterText || '').toLowerCase()
		if (!text) {
			this.matchesText = true
			return
		}
		this.resetView()
		this.matchesText = false
		if (this.textMatchModel.includes(text)) {
			this.matchesText = true
			return
		}
		if (this.textMatchChecklist.includes(text)) {
			this.showChecklist()
			this.matchesText = true
			return
		}
		if (this.textMatchEmployee.includes(text)) {
			this.showList('empList')
			this.matchesText = true
			return
		}
		if (this.textMatchSite.includes(text)) {
			this.showList('siteList')
			this.matchesText = true
			return
		}
		if (this.textMatchJob.includes(text)) {
			this.showList('jobList')
			this.matchesText = true
			return
		}
	}
	processFilterForSiteIds(filter: EmployeeChecklistSearchFilter) {}
	processFilterForEmpIds(filter: EmployeeChecklistSearchFilter) {}
	processFilterForJobIds(filter: EmployeeChecklistSearchFilter) {}
}
