<div class="no-data-tip">
	<div class="icon-block">
		<div><i class="fas fa-plus no-data-icon" aria-hidden="true"></i> Create a new record</div>
		<div><i class="far fa-cog no-data-icon" aria-hidden="true"></i> Modify preferences</div>
		<div><i class="far fa-question-circle no-data-icon" aria-hidden="true"></i> Show/hide section help</div>
	</div>
	<div class="legend-note">
		Use the icons in the upper-right corner of your screen to add a new record, manage which columns are displayed for the table, or show tips for
		this section.
	</div>
</div>
<hr />
