import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit, AfterContentInit } from '@angular/core'
import { SafeUrl, DomSanitizer } from '@angular/platform-browser'

import {
	Incident,
	IncidentDataAccessRequest,
	TransactionLogRecord,
	IncidentUpdateDataAccessRequest,
	ReportImageFile,
	UserReportConfiguration,
	UserReportIndustryType,
	DialogManager,
	UserReportType,
	FileUploadManager,
	FileUploaderProcessedFile,
	AuditLogDataAccessRequest,
	AuditLog,
	ShiftReportData,
	HomecareReportData,
	IncidentReportData,
	VehicleInspectionReportData,
	IUserReportFormat,
	IncidentReport,
	ShiftReport,
	HomecareReport,
	VehicleInspectionReport,
	EmployeeChecklist,
	ChecklistReportData,
	UserReportChecklistConfig,
	ChecklistReport,
	UserReportHelper,
	DistanceResult,
	DistanceHelper,
	IncidentReadDataAccessRequest,
} from '@app/models'
import { CoreService, DatabaseService, LambdaService } from '@app/services'
import { log, DateTimeHelper, DisplayHelper } from '@app/helpers'
import { environment } from '@env/environment'

import { DeviceDetectorService } from 'ngx-device-detector'
import { CountryCode } from 'libphonenumber-js'
import { v4 as uuid } from 'uuid'

import moment from 'moment-timezone'

class ReportOption {
	label = ''
	type: UserReportType = 'SHIFT'
	recordId: number
	constructor(label: string, type: UserReportType) {
		this.label = label
		this.type = type
	}
}

@Component({
    selector: 'app-trans-reports-list',
    templateUrl: './reports-list.component.html',
    styleUrls: ['./reports-list.component.scss'],
    standalone: false
})
export class TransReportsListComponent implements OnInit, AfterViewInit, AfterContentInit {
	timezone: string
	incidents: Array<Incident> = []
	dataLoaded = false
	selectedIncident = new Incident(null)
	selectedImageList: Array<ReportImageFile> = []

	isDesktop = false
	isCreating = false
	isDeleting = false
	isSaving = false
	isShowingImages = false
	isViewingAuditLog = false
	isPickingReportType = false

	incidentToDelete: Incident
	// showDeleteConfirmationDialog = false

	trans: TransactionLogRecord
	transInfo = { isLoaded: false, jobDate: '', jobName: '', empName: '' }
	editReportAction = { record: null, report: null, reportConfig: new UserReportConfiguration(), isEditing: false }
	viewAuditAction = { records: [], auditEntry: null, isDataLoaded: false }
	publicLinkAction = { record: null, showConfirmation: false, isDialogVisible: false }
	employeeName: string
	jobName: string

	fileUploadManager = new FileUploadManager()

	reportOptions: Array<ReportOption> = []

	checkinChecklistConfig: UserReportChecklistConfig = null
	postBreakChecklistConfig: UserReportChecklistConfig = null
	checkoutChecklistConfig: UserReportChecklistConfig = null
	customChecklistConfig: UserReportChecklistConfig = null

	@Input() transId: number
	@Input() dialogManager: DialogManager
	@Output() dataUpdated: EventEmitter<any> = new EventEmitter()

	constructor(
		private cd: ChangeDetectorRef,
		private coreSrvc: CoreService,
		private lambdaSrvc: LambdaService,
		private domSanitizer: DomSanitizer,
		private devDetect: DeviceDetectorService,
	) {
		if (this.devDetect.isDesktop()) {
			this.isDesktop = true
		}
	}

	get isEditing(): boolean {
		return this.incidents.map((incd) => incd.isEditing).includes(true)
	}

	get totalShiftMileage(): string {
		let mileageInMeters = 0
		for (const inc of this.incidents) {
			if (inc.mileage) {
				const distInMeters = DistanceHelper.converter.setValue(inc.mileage, inc.mileage_units).getDistanceValue('M')
				mileageInMeters += distInMeters
			}
		}
		return mileageInMeters > 0 ? DistanceHelper.converter.setValue(mileageInMeters, 'M').getSmartDistanceString() : ''
	}

	ngOnInit() {
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(this.transId)
		this.trans = trans
		if (trans) {
			this.timezone = trans.timezone
			this.employeeName = trans.employee_name
			this.jobName = trans.job_description
			this.setupTransInfo()
		}
	}

	ngAfterViewInit() {
		// 20240615 - Moved this to after content init
		// setTimeout(() => {
		// 	this.setupDialogManager()
		// 	// Loading here because the dialog manager needs to be setup in case there are no reports
		// 	// In this case the choose report screen is shown
		// 	this.loadIncidents()
		// }, 100)
	}

	ngAfterContentInit() {
		this.setupDialogManager()
		this.loadIncidents()
	}

	setupDialogManager() {
		this.dialogManager.submitBtnAction = () => this.newReportBtnClicked()
		this.dialogManager.canSubmit = () => true
		this.dialogManager.submitBtnLabel = 'New'
		this.dialogManager.isSubmitBtnVisible = true
	}

	setupReportOptions() {
		const trans = this.trans
		const industry = this.coreSrvc.dbSrvc.settingSrvc.getCompany().industry
		const isHealthcare = industry === 'HEALTHCARE'

		// Setup Checkin Checklist Data
		const checkinChecklists = this.coreSrvc.dbSrvc.empChklstSrvc
			.getChecklistsForFilter(trans.employee_id, trans.job_id)
			.filter((cl) => cl.type === 'CHECKIN' && cl.enabled)
		const checkinChecklistData = checkinChecklists.map((clr) => new EmployeeChecklist(clr).makeEmployeeChecklistData())
		const checkinChecklistConfig = new UserReportChecklistConfig()
		checkinChecklistConfig.setEmployeeChecklistData(checkinChecklistData)
		this.checkinChecklistConfig = checkinChecklistConfig

		// Setup Post Break Checklist Data
		const postBreakChecklists = this.coreSrvc.dbSrvc.empChklstSrvc
			.getChecklistsForFilter(trans.employee_id, trans.job_id)
			.filter((cl) => cl.type === 'POST_BREAK' && cl.enabled)
		const postBreakChecklistData = postBreakChecklists.map((clr) => new EmployeeChecklist(clr).makeEmployeeChecklistData())
		const postBreakChecklistConfig = new UserReportChecklistConfig()
		postBreakChecklistConfig.setEmployeeChecklistData(postBreakChecklistData)
		this.postBreakChecklistConfig = postBreakChecklistConfig

		// Setup Checkout Checklist Data
		const checkoutChecklists = this.coreSrvc.dbSrvc.empChklstSrvc
			.getChecklistsForFilter(trans.employee_id, trans.job_id)
			.filter((cl) => cl.type === 'CHECKOUT' && cl.enabled)
		const checkoutChecklistData = checkoutChecklists.map((clr) => new EmployeeChecklist(clr).makeEmployeeChecklistData())
		const checkoutChecklistConfig = new UserReportChecklistConfig()
		checkoutChecklistConfig.setEmployeeChecklistData(checkoutChecklistData)
		this.checkoutChecklistConfig = checkoutChecklistConfig

		const shiftReport = new ReportOption('Basic Report', 'SHIFT')
		const homecareReport = new ReportOption('Homecare Report', 'HOMECARE')
		const incidentReport = new ReportOption('Incident Report', 'INCIDENT')
		const vehicleReport = new ReportOption('Vehicle Inspection Report', 'VEHICLEINSPECTION')
		const checkinChecklistReport = new ReportOption('Check-in Survey', 'CHECKIN')
		const postBreakChecklistReport = new ReportOption('Post-break Survey', 'POST_BREAK')
		const checkoutChecklistReport = new ReportOption('Check-out Survey', 'CHECKOUT')

		const options = []
		options.push(shiftReport)
		if (isHealthcare) options.push(homecareReport)
		options.push(incidentReport)
		options.push(vehicleReport)
		// IF there are any checklists available for emp/job combo then show the option
		if (checkinChecklists.length > 0) options.push(checkinChecklistReport)
		if (postBreakChecklists.length > 0) options.push(postBreakChecklistReport)
		if (checkoutChecklists.length > 0) options.push(checkoutChecklistReport)

		// Handle custom surveys
		const customChecklists = this.coreSrvc.dbSrvc.empChklstSrvc
			.getChecklistsForFilter(trans.employee_id, trans.job_id)
			.filter((cl) => cl.type === 'CUSTOM' && cl.enabled)

		if (customChecklists.length > 0) {
			for (const customSurvey of customChecklists) {
				const customChecklistReport = new ReportOption(customSurvey.title, 'CUSTOM')
				customChecklistReport.recordId = customSurvey.id
				options.push(customChecklistReport)
			}
		}

		this.reportOptions = options
	}

	setupTransInfo() {
		const trans = this.trans
		const empId = trans.employee_id
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)
		this.transInfo.jobDate = trans?.job_date
		this.transInfo.jobName = trans?.job_description
		this.transInfo.empName = emp?.name
		// this.transInfo.isLoaded = true
	}

	loadIncidents() {
		const request = new IncidentReadDataAccessRequest(this.transId, 'TRANS')
		log('Lambda Request', request)
		this.lambdaSrvc.dataAccess(request).then((result) => {
			const data: Array<any> = result.data
			const incidents = data.map((rec) => new Incident(rec))
			this.incidents = incidents
			log('Incidents', this.incidents)
			this.isSaving = false
			this.dataLoaded = true
			this.checkDates()
			this.setupReportOptions()
			this.setupCurrentView()
			this.editReportAction.isEditing = false
		})
	}

	setupCurrentView() {
		this.setupDialogManager()
		this.setupReportOptions()
		if (this.incidents.length === 0) {
			this.dialogManager.cancelBtnLabel = 'Close'
			this.dialogManager.isSubmitBtnVisible = false
			this.isPickingReportType = true
		}
	}

	checkDates() {
		const incDates = this.incidents.map((i) => i.created)
		const dateStrings = incDates.map((id) => moment(id).format('YYYY-MM-DD[@]h:mm:ss'))
	}

	dateFormatter(dateString: string): string {
		if (!dateString) return null
		const is12Hours = DateTimeHelper.format12Hour
		const dateMom = moment.tz(dateString, this.timezone)
		return is12Hours ? dateMom.format('ddd, MMM Do [at] h:mm a z') : dateMom.format('ddd, MMM Do [at] HH:mm z')
	}

	reportTypeFormatter(incident: Incident) {
		const type = incident.report_type as UserReportType
		return UserReportHelper.formatReportTypeLabel(type)
	}

	previewFormatter(str: string) {
		return DisplayHelper.truncateString(str, 150)
	}

	playAudio(incident: Incident) {
		log('Play audio', incident.audio_file)
		const url = 'https://' + environment.audioDomain + '/' + incident.audio_file
		this.coreSrvc.audioSrvc.playAudioUrl.next(url)
	}

	jsBridgePlayAudio(fileId: string) {
		return
	}

	newReportBtnClicked() {
		this.dialogManager.pushState()
		this.dialogManager.cancelBtnAction = () => this.newReportCancelBtnClicked()
		this.dialogManager.cancelBtnLabel = 'Back'
		this.dialogManager.isSubmitBtnVisible = false
		this.isPickingReportType = true
	}

	newReportCancelBtnClicked() {
		log('Add Report Cancelled')
		this.dialogManager.popStateAndApply()
		this.isPickingReportType = false
		this.setupCurrentView()
	}

	editReport(record: Incident) {
		log('Edit Report Btn Clicked', record)
		if (!record.report_json && record.audio_file) {
			this.coreSrvc.notifySrvc.notify('info', 'IVR Incident', 'Audio reports submitted via IVR cannot be edited.', 3)
			return
		}
		this.selectedIncident = record
		const reportJson = record.report_json
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const reportConfig = new UserReportConfiguration()
		reportConfig.countryCode = company.country_iso as CountryCode
		reportConfig.industryType = company.industry as UserReportIndustryType
		reportConfig.reportType = record.report_type as UserReportType
		this.editReportAction.record = record
		this.editReportAction.reportConfig = reportConfig
		this.editReportAction.report = reportJson ? JSON.parse(reportJson) : null
		log('Edit Report Action', this.editReportAction)
		this.dialogManager.pushState()
		this.editReportAction.isEditing = true
		this.dialogManager.scrollToTop()
		this.dialogManager.setBackgroundColor('#eee')
	}

	editReportSaved() {
		this.dialogManager.popStateAndApply()
		this.loadIncidents()
		log('Should see blink')
		this.fetchTransactionAndTrigerUpdate()
		this.dialogManager.setBackgroundColor('#fff')
	}

	fetchTransactionAndTrigerUpdate() {
		this.coreSrvc.dbSrvc.readRecord('transaction_log', this.transId).then((success) => {
			this.dataUpdated.emit(this.transId)
		})
	}

	editReportCancelled() {
		this.dialogManager.popStateAndApply()
		this.setupCurrentView()
		this.editReportAction.isEditing = false
		this.dialogManager.setBackgroundColor('#fff')
	}

	viewAuditLog(incident: Incident) {
		this.dialogManager.pushState()
		this.dialogManager.isSubmitBtnVisible = false
		this.dialogManager.cancelBtnAction = () => this.viewAuditLogDone()
		this.dialogManager.cancelBtnLabel = 'Done'
		this.dialogManager.headerLabel = 'View Audit Log'
		const recordId = incident.id
		log('Loading Records for', recordId)

		this.isViewingAuditLog = true
		this.viewAuditAction.isDataLoaded = false
		const options = { resource: 'incident_log', resource_id: recordId }
		const request = new AuditLogDataAccessRequest(options)
		log('Routed Request', request)
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			const data = result.data
			this.viewAuditAction.records = data.map((rec) => new AuditLog(rec))

			if (this.viewAuditAction.records.length > 0) {
				this.viewAuditAction.auditEntry = this.viewAuditAction.records[this.viewAuditAction.records.length - 1]
			}
			this.viewAuditAction.isDataLoaded = true
			log('History Results', data)
		})
	}

	viewAuditLogDone() {
		this.dialogManager.popStateAndApply()
		this.viewAuditAction.records = []
		this.viewAuditAction.auditEntry = null
		this.viewAuditAction.isDataLoaded = false
		this.isViewingAuditLog = false
	}

	confirmDelete(incident: Incident) {
		this.deleteIncident(incident)
	}

	deleteIncident(incident: Incident) {
		const transId = incident.transaction_log_id
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(transId)
		this.isDeleting = true
		const request = new IncidentDataAccessRequest(this.transId)
		request.operation = 'delete'
		request['id'] = incident.id.toString()
		this.lambdaSrvc.dataAccess(request).then((result) => {
			log('Delete Incident', result)
			this.incidents = this.incidents.filter((incd) => incd.id !== incident.id)
			trans.incident_count = this.incidents.length
			this.isDeleting = false
			// this.dataUpdated.emit(incident.transaction_log_id)
			this.fetchTransactionAndTrigerUpdate()
			this.setupCurrentView()
		})
	}

	prepareDialogForShiftEdit() {
		this.dialogManager.cancelBtnLabel = 'Cancel'
		this.dialogManager.submitBtnLabel = 'Save'
		this.dialogManager.headerLabel = 'Edit Notes'
		this.dialogManager.cancelBtnAction = () => this.cancelEditShiftReport()
		this.dialogManager.submitBtnAction = () => this.saveEditShiftReport()
		this.dialogManager.isCancelBtnVisble = true
		this.dialogManager.isSubmitBtnVisible = true
		this.dialogManager.canSubmit = () => true
	}

	editShiftReport(incident: Incident) {
		this.dialogManager.pushState()
		log('Incident', incident)
		this.selectedIncident = incident
		this.configureUploadManagerForShiftReport(incident)
		this.prepareDialogForShiftEdit()
		incident.isEditing = true
	}

	configureUploadManagerForShiftReport(incident?: Incident) {
		const imageFiles = incident?.imageFiles.map((file) => new FileUploaderProcessedFile(file))
		const date = moment().format('YYYY-MM-DD')
		const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
		const bucket = environment.assetsBucket
		const directory = 'shiftreports'
		this.fileUploadManager.setConfiguration(companyId, bucket, directory)
		this.fileUploadManager.supportedMimeTypes = ['image/gif', 'image/png', 'image/jpeg']
		this.fileUploadManager.processedFiles = imageFiles ? imageFiles : []
	}

	cancelEditShiftReport() {
		this.dialogManager.popStateAndApply()
		this.cd.detectChanges()
		const incident = this.selectedIncident
		this.isCreating = false
		incident.isEditing = false
		incident.transcript = incident.origTranscript
		incident.notes = incident.origNotes
	}

	saveEditShiftReport() {
		this.dialogManager.popStateAndApply()
		const incident = this.selectedIncident
		const imageFiles = this.fileUploadManager.processedFiles
		log('Processed Files', imageFiles)
		incident.images_json = imageFiles.length > 0 ? JSON.stringify({ files: imageFiles }) : null
		if (this.isCreating) {
			this.submitNewReport(incident)
			return
		}
		const request = new IncidentUpdateDataAccessRequest(incident)
		this.isSaving = true
		this.lambdaSrvc.dataAccess(request).then((result) => {
			log(result)
			incident.isEditing = false
			incident.origNotes = incident.notes
			this.dataUpdated.emit(incident.transaction_log_id)
			this.isSaving = false
			this.loadIncidents()
		})
	}

	createReport(type?: UserReportType) {
		this.configureUploadManagerForShiftReport()
		this.dialogManager.pushState()
		this.selectedIncident = new Incident(null)
		this.selectedIncident.isEditing = true
		this.prepareDialogForShiftEdit()
		this.isCreating = true
	}

	createNewFormReport(type: UserReportType, recordId: number) {
		this.dialogManager.popStateAndApply()
		this.isPickingReportType = false
		const newIncident = new Incident(null)
		const reportData = this.makeNewReport(type, recordId)
		if (!reportData) {
			alert('Report type not available')
			return
		}
		const reportJson = reportData ? JSON.stringify(reportData) : null
		newIncident.report_type = type
		newIncident.report_json = reportJson
		log('New Incident', newIncident)
		this.editReport(newIncident)
	}

	makeNewReport(type: UserReportType, recordId): any {
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(this.transId)
		const empFirst = trans?.employee_first ?? ''
		const empLast = trans?.employee_last ?? ''
		const reportDate = moment.tz(trans.actual_start ? trans.actual_start : trans.job_date, trans.timezone).toDate()
		const transactionMetaData = trans.getReportMetaData()
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const countryCode = company?.country_iso
		const industryType = (company?.industry ?? 'DEFAULT') as UserReportIndustryType
		const reportFormat: IUserReportFormat = { reportType: 'SHIFT', industryType: industryType }

		switch (type) {
			case 'SHIFT':
				reportFormat.reportType = 'SHIFT'
				const shiftReportData = new ShiftReportData({ format: reportFormat, countryCode: countryCode, transactionMetaData: transactionMetaData })
				return new ShiftReport(shiftReportData)
			case 'HOMECARE':
				reportFormat.reportType = 'HOMECARE'
				const homecareReportData = new HomecareReportData({
					format: reportFormat,
					countryCode: countryCode,
					transactionMetaData: transactionMetaData,
				})
				const homecareReport = new HomecareReport(homecareReportData)
				homecareReport.reportData.serviceDate.value = reportDate
				return homecareReport
			case 'INCIDENT':
				reportFormat.reportType = 'INCIDENT'
				const reportData = new IncidentReportData({ format: reportFormat, countryCode: countryCode, transactionMetaData: transactionMetaData })
				const incidentReport = new IncidentReport(reportData)
				incidentReport.reportData.incidentDate.value = reportDate
				incidentReport.reportData.incidentReporter.firstName.value = empFirst
				incidentReport.reportData.incidentReporter.lastName.value = empLast
				return incidentReport
			case 'VEHICLEINSPECTION':
				reportFormat.reportType = 'VEHICLEINSPECTION'
				const vehicleReportData = new VehicleInspectionReportData({
					format: reportFormat,
					countryCode: countryCode,
					transactionMetaData: transactionMetaData,
				})
				const vehicleInspectionReport = new VehicleInspectionReport(vehicleReportData)
				vehicleInspectionReport.reportData.inspectionDate.value = reportDate
				vehicleInspectionReport.reportData.inspectionReporter.firstName.value = empFirst
				vehicleInspectionReport.reportData.inspectionReporter.lastName.value = empLast
				return vehicleInspectionReport

			// Employee Surveys - Needs to have each one setup even though they are similar because a copy of each report type is created for editing

			case 'CHECKIN':
				reportFormat.reportType = 'CHECKIN'
				const checkinChecklistReportData = new ChecklistReportData({
					format: reportFormat,
					countryCode: countryCode,
					transactionMetaData: transactionMetaData,
				})
				checkinChecklistReportData.configureChecklistOptions(this.checkinChecklistConfig, 'CHECKIN', null)
				const checkinChecklistReport = new ChecklistReport(checkinChecklistReportData)
				checkinChecklistReport.reportData.shiftDate.value = reportDate
				checkinChecklistReport.reportData.employee.firstName.value = empFirst
				checkinChecklistReport.reportData.employee.lastName.value = empLast
				return checkinChecklistReport
			case 'POST_BREAK':
				reportFormat.reportType = 'POST_BREAK'
				const postBreakChecklistReportData = new ChecklistReportData({
					format: reportFormat,
					countryCode: countryCode,
					transactionMetaData: transactionMetaData,
				})
				postBreakChecklistReportData.configureChecklistOptions(this.postBreakChecklistConfig, 'POST_BREAK', null)
				const postBreakChecklistReport = new ChecklistReport(postBreakChecklistReportData)
				postBreakChecklistReport.reportData.shiftDate.value = reportDate
				postBreakChecklistReport.reportData.employee.firstName.value = empFirst
				postBreakChecklistReport.reportData.employee.lastName.value = empLast
				return postBreakChecklistReport
			case 'CHECKOUT':
				reportFormat.reportType = 'CHECKOUT'
				const checkoutChecklistReportData = new ChecklistReportData({
					format: reportFormat,
					countryCode: countryCode,
					transactionMetaData: transactionMetaData,
				})
				checkoutChecklistReportData.configureChecklistOptions(this.checkoutChecklistConfig, 'CHECKOUT', null)
				const checkoutChecklistReport = new ChecklistReport(checkoutChecklistReportData)
				checkoutChecklistReport.reportData.shiftDate.value = reportDate
				checkoutChecklistReport.reportData.employee.firstName.value = empFirst
				checkoutChecklistReport.reportData.employee.lastName.value = empLast
				return checkoutChecklistReport

			case 'CUSTOM':
				const customChecklists = this.coreSrvc.dbSrvc.empChklstSrvc
					.getChecklistsForFilter(trans.employee_id, trans.job_id)
					.filter((cl) => cl.type === 'CUSTOM' && cl.enabled && cl.id === recordId)
				const customChecklistData = customChecklists.map((clr) => new EmployeeChecklist(clr).makeEmployeeChecklistData())
				const customChecklistConfig = new UserReportChecklistConfig()
				customChecklistConfig.setEmployeeChecklistData(customChecklistData)
				this.customChecklistConfig = customChecklistConfig

				reportFormat.reportType = 'CUSTOM'
				const customChecklistReportData = new ChecklistReportData({
					format: reportFormat,
					countryCode: countryCode,
					transactionMetaData: transactionMetaData,
				})
				customChecklistReportData.configureChecklistOptions(this.customChecklistConfig, 'CUSTOM', null)
				const customChecklistReport = new ChecklistReport(customChecklistReportData)
				customChecklistReport.reportData.shiftDate.value = reportDate
				customChecklistReport.reportData.employee.firstName.value = empFirst
				customChecklistReport.reportData.employee.lastName.value = empLast
				return customChecklistReport

			default:
				return null
		}
	}

	submitNewReport(incident: Incident) {
		const request = new IncidentUpdateDataAccessRequest(incident)
		request.operation = 'insert'
		request['transaction_log_id'] = this.transId.toString()
		this.isSaving = true
		this.lambdaSrvc.dataAccess(request).then((result) => {
			const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(this.transId)
			if (trans) {
				trans.incident_count = this.incidents.length + 1
				log('Setting trans incident count to ', trans.incident_count)
				this.dataUpdated.emit(trans.id)
			}
			// this.collapseIncidents()
			this.selectedIncident.isEditing = false
			this.isCreating = false
			this.isSaving = false
			this.loadIncidents()
			this.dialogManager.popStateAndApply()
		})
	}

	public showPublicLinkDialog(incident: Incident) {
		const isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.getMyUser().role === 'INTERNAL'
		if (isInternalUser) {
			this.publicLinkAction.record = incident
			this.publicLinkAction.showConfirmation = false
			this.publicLinkAction.isDialogVisible = true
		} else {
			this.showReport(incident)
		}
	}

	public copyLink(incident: Incident) {
		this.coreSrvc.dbSrvc.settingSrvc.copyToClipboard(incident.publicReportLink)
		this.coreSrvc.notifySrvc.notify('success', 'Link Copied', 'The public link for this report has been copied to your clipboard.', 5)
	}

	public viewReportBtnClicked() {
		const incident = this.publicLinkAction.record
		this.showReport(incident)
		this.publicLinkAction.isDialogVisible = false
	}

	public copyPublicLinkBtnClicked() {
		log('Copy Public Link')
		this.copyLink(this.publicLinkAction.record)
		this.publicLinkAction.isDialogVisible = false
	}

	public confirmRefreshBtnClicked() {
		log('Refresh Public Link')
		const record = this.publicLinkAction.record
		record.uuid = uuid()
		this.coreSrvc.dbSrvc.updateRecord('incident_log', record).then((success) => {
			this.publicLinkAction.isDialogVisible = false
			this.loadIncidents()
			this.coreSrvc.notifySrvc.notify('success', 'Link Refreshed', 'The public link for this report has been refreshed.')
		})
	}

	showReport(incident: Incident) {
		log('Incident', incident)
		this.coreSrvc.eventSrvc.showFullScreenReport(incident)
	}

	showImages(incident: Incident) {
		this.selectedIncident = incident
		this.selectedImageList = incident.getImages()
		this.dialogManager.saveScrollPosition('manageImages')
		this.dialogManager.pushState()
		this.dialogManager.headerLabel = 'Manage Photos'
		this.dialogManager.isSubmitBtnVisible = true
		this.dialogManager.canSubmit = () => true
		this.dialogManager.cancelBtnAction = () => this.cancelManageImages()
		this.dialogManager.submitBtnAction = () => this.saveBtnClickedForManageImages()
		this.dialogManager.submitBtnLabel = 'Save'
		this.dialogManager.cancelBtnLabel = 'Cancel'
		this.isShowingImages = true
		this.dialogManager.scrollToTop()
	}

	saveBtnClickedForManageImages() {
		log('Current Images', this.selectedImageList)
		const newImageList = this.selectedImageList.map((img) => img.getJson())
		const imagesJson =
			this.selectedImageList.length === 0
				? null
				: {
						files: newImageList,
					}
		const updateRecord = { ...this.selectedIncident }
		updateRecord.images_json = JSON.stringify(imagesJson)
		this.isShowingImages = false
		this.isSaving = true
		this.coreSrvc.dbSrvc.updateRecord('incident_log', updateRecord).then((success) => {
			this.isSaving = false
			this.loadIncidents()
			this.cancelManageImages()
		})
		log('Images Json', imagesJson)
	}

	cancelManageImages() {
		this.dialogManager.popStateAndApply()
		this.dialogManager.restoreScrollPosition('manageImages')
		this.isShowingImages = false
	}

	removeImage(idx: number) {
		this.selectedImageList.splice(idx, 1)
	}

	showFullScreen(key: string) {
		if (this.isDesktop) {
			const url = `https://${environment.assetsDomain}/${key}`
			window.open(url, '_blank')
		}
	}

	imageDateFormatter(image: ReportImageFile) {
		const ts = image.ts
		const date = moment(ts)
		return date.format('ddd, MMM Do [at] h:mm a')
	}

	imageUrl(key: string): SafeUrl {
		const url = `https://${environment.assetsDomain}/${key}`
		const safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url)
		return safeUrl
	}

	imageLoaded(image: ReportImageFile) {
		image.isLoaded = true
	}
	imageError(image: ReportImageFile) {
		log('Image Error', image)
		image.hasError = true
	}
}
