<div class="ann-search-wrap">
	<div class="ann-search-box">
		<app-search-filter [placeholder]="'filter announcements'" (search)="debounceSearch($event)"> </app-search-filter>
	</div>
</div>

<div *ngIf="isDataLoaded && filteredList.length === 0" class="card no-records-card">No Check In Announcements</div>

<div>
	<div *ngIf="isDataLoaded">
		<div *ngIf="isShowingSectionHelp" class="card card-body tooltip-block tooltip-info-text tooltip-section-header">
			<button type="button" class="close" aria-label="Close" (click)="isShowingSectionHelp = false">
				<span>&times;</span>
			</button>
			<h4>Announcements Help</h4>
			<strong
				>Announcements allow you to deliver company wide messages when employees call in to the system or check in using the employee mobile
				website.</strong
			>
			<br /><br />

			<!-- <h4 style="color:chocolate">Configuration Options</h4> -->

			<strong>When Always Play is selected</strong> - This is known as an <strong>Always Play</strong> announcement. The announcement will be
			delivered every time a user checks in (either by phone or using employee mobile website). You do not have the ability to request
			acknowledgement that the employee heard or saw the announcement. You may attach files but only employees using the mobile web site will see
			these links. Employees calling in to the system will not receive a text message with links to the attachments.<br /><br />

			<strong>When Always Play is not selected</strong> - This is known as a <strong>Play Once</strong> announcement. The announcement will be
			delivered, one time only, for each employee. You may request acknowledgement that the employee heard or saw the announcement. Links to any
			attached files will be sent as a text message when a user calls in <strong>and</strong> be visible in the announcement when using the
			employee mobile website. Regardless of check in method, all employees will receive these links.
		</div>

		<!-- <div *ngIf="currentCount === 0" class="no-data-block">
			No Announcements
		</div> -->

		<div class="ann-list-block">
			<div *ngFor="let vm of filteredList" class="card ann-card" [class.ann-disabled]="!vm.announcement.enabled">
				<div>
					<div class="row">
						<div class="col-12 col-lg-3">
							<div class="ann-name">
								{{ vm.announcement.name }}
							</div>
						</div>
						<div class="col-12 col-lg-3">
							<div class="ann-label tp-3px" [class.hl-date-green]="vm.isEndDateCurrent" [class.hl-date-red]="vm.isEndDatePast">
								Starts on {{ defaultDateFormatter(vm.announcement.start_date) }}
							</div>
						</div>
						<div class="col-12 col-lg-3">
							<div class="ann-label tp-3px" [class.hl-date-green]="vm.isEndDateCurrent" [class.hl-date-red]="vm.isEndDatePast">
								{{ vm.announcement.end_date ? 'Ends on ' + defaultDateFormatter(vm.announcement.end_date) : 'No End Date' }}
							</div>
						</div>
						<div class="col-12 col-lg-3">
							<div class="ann-actions">
								<i
									class="fa fa-pencil-square-o action-i action-i-edit"
									[class.action-icon-disabled]="!canPerformAction(CrudAction.update)"
									title="Edit Announcement"
									(click)="editRecord(vm.announcement)"
								></i>
								<i
									class="fa fa-trash-o action-i action-i-delete"
									[class.action-icon-disabled]="!canPerformAction(CrudAction.delete)"
									title="Delete Announcement"
									(click)="deleteRecord(vm.announcement)"
								></i>
								<i
									class="far fa-database action-i action-i-audit"
									[class.action-i-orange]="vm.announcement.updated"
									[class.action-icon-disabled]="!canPerformAction(CrudAction.read)"
									title="Audit Announcement"
									(click)="auditRecord(vm.announcement)"
								></i>
								<!-- <i class="fa fa-arrow-up action-i" title="Increase Priority" (click)="increasePriority(rec)"></i>
					 <i class="fa fa-arrow-down action-i" title="Decrease Priority" (click)="decreasePriority(rec)"></i> -->
							</div>
							<div class="ann-priority">Priority:</div>
							<div class="ann-order">
								<select
									class="form-select form-select-sm"
									style="width: 55px"
									[disabled]="!canPerformAction(CrudAction.update) || !vm.announcement.enabled"
									[(ngModel)]="vm.announcement.order"
									(change)="updateRecord(vm.announcement, true)"
								>
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
								</select>
							</div>
						</div>
					</div>
					<hr />

					<div class="row">
						<div class="col-12 col-lg-3">
							<div class="ann-label ann-bm-5">
								<p-checkbox
									[(ngModel)]="vm.announcement.always_play"
									[binary]="true"
									[disabled]="!canPerformAction(CrudAction.update) || !vm.announcement.enabled"
									(onChange)="updateRecord(vm.announcement, true)"
								></p-checkbox>
								<div class="png-check-label" (click)="toggleOption(vm.announcement, 'always_play')">Always Play</div>
							</div>

							<div class="ann-label ann-bm-5">
								<p-checkbox
									[(ngModel)]="vm.announcement.acknowledgement"
									[disabled]="!canPerformAction(CrudAction.update) || !vm.announcement.enabled || vm.announcement.always_play"
									[binary]="true"
									(onChange)="updateRecord(vm.announcement, false)"
								></p-checkbox>
								<div
									class="png-check-label"
									[class.ann-disabled]="vm.announcement.always_play"
									(click)="toggleOption(vm.announcement, 'acknowledgement')"
								>
									Acknowledge
								</div>
							</div>

							<div *ngIf="false" class="ann-label ann-bm-5">
								<p-checkbox
									[(ngModel)]="vm.announcement.multi_play"
									[binary]="true"
									[disabled]="!canPerformAction(CrudAction.update) || !vm.announcement.enabled"
									(onChange)="updateRecord(vm.announcement, false)"
								></p-checkbox>
								<div class="png-check-label" (click)="toggleOption(vm.announcement, 'multi_play')">Continue</div>
							</div>
						</div>
						<div class="col-12 col-lg-6">
							<div class="ann-body">
								<div>
									<span style="white-space: pre-wrap">{{ bodyTextFormatter(vm.announcement) }}</span>
								</div>
								<div class="ann-attach">{{ attachmentFormatter(vm.announcement) }}</div>
							</div>
						</div>

						<div class="col-12 col-lg-3">
							<div class="ann-switch">
								<p-toggleswitch
									[disabled]="!canPerformAction(CrudAction.update)"
									[(ngModel)]="vm.announcement.enabled"
									binary="true"
									(onChange)="updateRecord(vm.announcement, false)"
								></p-toggleswitch>
							</div>
						</div>
					</div>

					<div class="target-block" *ngIf="vm.targetedEmployeeList || vm.targetedJobSiteList">
						<div *ngIf="vm.targetedEmployeeCount">
							<span class="target-label tts-hl-2">Targeted Employees</span>
							<span class="target-count link-text" (click)="vm.show.targetedEmployees = !vm.show.targetedEmployees">{{
								vm.targetedEmployeeCount
							}}</span>
							<div *ngIf="vm.show.targetedEmployees" class="target-list">
								<span *ngFor="let emp of vm.targetedEmployeeList; let idx = index">
									<span>{{ emp.name }}</span>
									<span *ngIf="!emp.active" class="emp-inactive"> (Inactive)</span>
									<span *ngIf="idx < vm.targetedEmployeeList.length - 1"> / </span>
								</span>
							</div>
						</div>

						<div *ngIf="vm.targetedJobSiteCount">
							<span class="target-label tts-hl-2">Targeted Job Sites</span>
							<span class="target-count link-text" (click)="vm.show.targetedJobsites = !vm.show.targetedJobsites">{{
								vm.targetedJobSiteCount
							}}</span>

							<div *ngIf="vm.show.targetedJobsites" class="target-list">
								<span *ngFor="let job of vm.targetedJobSiteList; let idx = index">
									<span>{{ job.description }}</span>
									<span *ngIf="idx < vm.targetedJobSiteList.length - 1"> / </span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-basic-modal
	[(visible)]="selectedRecord.showEditDialog"
	modalId="announceEditModal"
	[preventBackgroundClose]="true"
	[modalHeader]="selectedRecord.header"
	[modalFooter]="selectedRecord.footer"
	[modalWidth]="450"
	[saveTable]="'announcements'"
>
	<div *ngIf="selectedRecord.showEditDialog">
		<app-announcements-checkin-edit
			[record]="selectedRecord.record"
			(dialogClosed)="selectedRecord.showEditDialog = false"
			(recordUpdated)="fetchAndLoadData()"
		></app-announcements-checkin-edit>
	</div>
</app-basic-modal>

<p-dialog
	styleClass="p-dialog-noheader"
	[(visible)]="selectedRecord.showDeleteDialog"
	[modal]="false"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[baseZIndex]="5000"
	[dismissableMask]="false"
	[transitionOptions]="'0ms'"
	(onHide)="isDeletingRecord = false"
>
	<div *ngIf="isDeletingRecord" class="delete-diag-working"><i class="fas fa-loader fa-spin"></i> Deleting Record...</div>
	<div *ngIf="!isDeletingRecord" class="delete-diag-msg">Are you sure you want to delete this announcement?</div>
	<div *ngIf="!isDeletingRecord">
		<button class="btn btn-sm btn-secondary" (click)="selectedRecord.showDeleteDialog = false">Cancel</button>
		<button class="btn btn-sm btn-danger" style="margin-left: 10px" (click)="deleteConfirmed()">Delete</button>
	</div>
</p-dialog>
