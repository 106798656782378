<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<div class="alert alert-info alert-bg-gray alert-box">
		<div>To make changes, check each section you wish to modify and adjust the settings.</div>
		<div style="margin-top: 12px; color: firebrick">
			Please be careful when editing batches. These changes will overwrite existing settings for all of the selected jobs.
		</div>
		<div style="margin-top: 12px">
			<div>{{ record.job_ids.length }} job{{ record.job_ids.length !== 1 ? 's' : '' }} will be modified</div>
		</div>
	</div>

	<!-- Job active / inactive -->
	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="record.active_change" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong (click)="record.active_change = !record.active_change" class="has-pointer" [class.item-selected]="record.active_change"
				>Modify active / inactive status</strong
			>
		</div>
	</div>
	<div *ngIf="record.active_change" class="change-block">
		<app-form-input-switch
			[(model)]="record.active"
			[enabledLabel]="'Job is active'"
			[enabledHint]="'to de-activate'"
			[disabledLabel]="'Job is inactive'"
			[disabledHint]="'to activate'"
			[wrapStyle]="{ margin: '30px 0px 10px 0px' }"
			[labelStyle]="{ 'font-size': '1.4rem', 'font-weight': '400' }"
		></app-form-input-switch>
	</div>

	<!-- Supervisor -->
	<div *ngIf="areJobSitesMerged" class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="record.supervisor_id_change" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong
				(click)="record.supervisor_id_change = !record.supervisor_id_change"
				class="has-pointer"
				[class.item-selected]="record.supervisor_id_change"
				>Modify assigned supervisor</strong
			>
		</div>
	</div>
	<div *ngIf="record.supervisor_id_change" class="change-block">
		<p-select
			appScrollLockSelect
			[(ngModel)]="record.supervisor_id"
			[options]="supervisorOptions"
			[filter]="true"
			filterBy="label,data.external_id"
			[autofocusFilter]="devDetect.isDesktop()"
		></p-select>
	</div>

	<!-- Notification Profile -->
	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="record.notification_profile_id_change" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong
				(click)="record.notification_profile_id_change = !record.notification_profile_id_change"
				class="has-pointer"
				[class.item-selected]="record.notification_profile_id_change"
				>Modify notification profile</strong
			>
		</div>
	</div>
	<div *ngIf="record.notification_profile_id_change" class="change-block">
		<p-select
			appScrollLockSelect
			[(ngModel)]="record.notification_profile_id"
			[options]="npOptions"
			[filter]="true"
			filterBy="label,data.external_id"
			[autofocusFilter]="devDetect.isDesktop()"
		></p-select>
	</div>

	<!-- Client -->
	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="record.client_id_change" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong (click)="record.client_id_change = !record.client_id_change" class="has-pointer" [class.item-selected]="record.client_id_change"
				>Modify assigned client</strong
			>
		</div>
	</div>
	<div *ngIf="record.client_id_change" class="change-block">
		<p-select
			appScrollLockSelect
			[(ngModel)]="record.client_id"
			[options]="clientOptions"
			[filter]="true"
			filterBy="label,data.external_id"
			[autofocusFilter]="devDetect.isDesktop()"
		></p-select>
	</div>

	<!-- Vendor -->
	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="record.vendor_id_change" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong (click)="record.vendor_id_change = !record.vendor_id_change" class="has-pointer" [class.item-selected]="record.vendor_id_change"
				>Modify assigned vendor</strong
			>
		</div>
	</div>
	<div *ngIf="record.vendor_id_change" class="change-block">
		<p-select
			appScrollLockSelect
			[(ngModel)]="record.vendor_id"
			[options]="vendorOptions"
			[filter]="true"
			filterBy="label,data.external_id"
			[autofocusFilter]="devDetect.isDesktop()"
		></p-select>
	</div>

	<!-- Tags -->
	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="record.tags_json_change" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong (click)="record.tags_json_change = !record.tags_json_change" class="has-pointer" [class.item-selected]="record.tags_json_change"
				>Modify job tags</strong
			>
		</div>
	</div>
	<div *ngIf="record.tags_json_change" class="change-block">
		<app-form-tags
			#tagComp
			[tagsJson]="record.tags_json"
			[tagOptionsList]="tagOptionsList"
			(tagsJsonChange)="record.tags_json = $event"
		></app-form-tags>
		<hr style="margin: 30px 0px 25px 0px" />
		<div>
			<div class="float-right">
				<span
					class="badge has-pointer"
					style="margin-right: 8px"
					[class.text-bg-success]="record.tags_action === 'ADD'"
					[class.badge-bg-lightgray]="record.tags_action !== 'ADD'"
					(click)="record.tags_action = 'ADD'"
				>
					ADD
				</span>
				<span
					class="badge has-pointer"
					style="margin-right: 8px"
					[class.text-bg-success]="record.tags_action === 'REMOVE'"
					[class.badge-bg-lightgray]="record.tags_action !== 'REMOVE'"
					(click)="record.tags_action = 'REMOVE'"
				>
					REMOVE
				</span>
				<span
					class="badge has-pointer"
					[class.text-bg-success]="record.tags_action === 'REPLACE_ALL'"
					[class.badge-bg-lightgray]="record.tags_action !== 'REPLACE_ALL'"
					(click)="record.tags_action = 'REPLACE_ALL'"
				>
					REPLACE
				</span>
			</div>
			<div>
				<span style="font-weight: 700">Action</span>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('tags_action')"></i>
			</div>
		</div>
	</div>
</div>
