////////////////
// DIRECTIVES //
////////////////

import { AppAutofocusDirective } from '@app/directives/autofocus'
import { AppDraggableDirective } from '@app/directives/draggable'
import { AppDragAndDropDirective } from '@app/directives/drag-and-drop'
import { AppMoneyInputDirective } from '@app/directives/money-input'
import { AppScrollLockDropdownDirective } from '@app/directives/png-scroll-lock-dropdown'
import { AppScrollLockSelectDirective } from '@app/directives/png-scroll-lock-select'
import { AppScrollLockMultiSelectDirective } from '@app/directives/png-scroll-lock-multiselect'
import { AppScrollLockAutoCompleteDirective } from '@app/directives/png-scroll-lock-autocomplete'
import { AppTextAreaResizerDirective } from '@app/directives/text-area-resizer'

export const appDirectives = [
	AppAutofocusDirective,
	AppDraggableDirective,
	AppDragAndDropDirective,
	AppMoneyInputDirective,
	AppScrollLockDropdownDirective,
	AppScrollLockSelectDirective,
	AppScrollLockMultiSelectDirective,
	AppScrollLockAutoCompleteDirective,
	AppTextAreaResizerDirective,
]

///////////
// PIPES //
///////////

import { DateFormatter } from '@app/pipes/date-formatter.pipe'
import { DisplayPhonePipe } from '@app/pipes/display-phone.pipe'
import { DisplayEmployeeNamePipe } from '@app/pipes/display-employee-name.pipe'
import { DisplayJobSiteNamePipe } from '@app/pipes/display-job-site-name.pipe'
import { DisplayLastRunPipe } from '@app/pipes/display-lastrun.pipe'
import { DurationInHoursAndMinutesPipe } from '@app/pipes/duration-in-hours-and-minutes.pipe'
import { DurationInMinutesPipe } from '@app/pipes/duration-in-minutes.pipe'
import { FormatTimeFromDatePipe } from '@app/pipes/format-time-from-date.pipe'
import { LinebreaksPipe } from '@app/pipes/line-breaks.pipe'
import { AdminNotesFormatterPipe } from '@app/pipes/admin-notes-formatter.pipe'
import { MediumDateFromDateStringPipe } from '@app/pipes/medium-date-from-date-string.pipe'
import { SanitizerPipe } from '@app/pipes/sanitizer.pipe'
import { StandardTimeFromDateStringPipe } from '@app/pipes/standard-time-from-date-string.pipe'
import { DateTzFormatter } from '@app/pipes/date-tz-formatter'

export const appPipes = [
	AdminNotesFormatterPipe,
	DateFormatter,
	DateTzFormatter,
	DisplayEmployeeNamePipe,
	DisplayJobSiteNamePipe,
	DisplayLastRunPipe,
	DisplayPhonePipe,
	FormatTimeFromDatePipe,
	LinebreaksPipe,
	MediumDateFromDateStringPipe,
	DurationInMinutesPipe,
	DurationInHoursAndMinutesPipe,
	SanitizerPipe,
	StandardTimeFromDateStringPipe,
]

//////////
// BASE //
//////////

import { BaseComponent } from '@app/admin/base/base.component'
import { BaseEditComponent } from '@app/admin/base/base-edit/base-edit.component'
import { BaseListComponent } from '@app/admin/base/base-list/base-list.component'
import { BaseTableComponent } from '@app/admin/base/base-table/base-table.component'

const baseComponents = [BaseComponent, BaseEditComponent, BaseListComponent, BaseTableComponent]

////////////
// LAYOUT //
////////////

import { AppComponent } from '@app/app.component'
import { AppShellComponent } from '@app/app.shell'
import { HeaderComponent } from '@app/admin/header/header.component'
import { HeaderNotificationComponent } from '@app/admin/header/header-notification/header-notification.component'
import { HeaderDispatcherComponent } from '@app/admin/header/header-dispatcher/header-dispatcher.component'
import { HeaderDispatchSettingsComponent } from '@app/admin/header/header-dispatcher/header-dispatch-settings/header-dispatch-settings.component'
import { FooterComponent } from '@app/admin/footer/footer.component'

import { AdminHomeComponent } from '@app/admin/home/admin-home.component'
import { EnrollmentComponent } from '@app/components/enrollment/enrollment.component'
import { DashboardComponent } from '@app/admin/dashboard/dashboard.component'

const layoutComponents = [
	AppComponent,
	AppShellComponent,
	HeaderComponent,
	HeaderNotificationComponent,
	HeaderDispatcherComponent,
	HeaderDispatchSettingsComponent,
	FooterComponent,
	AdminHomeComponent,
	EnrollmentComponent,
	DashboardComponent,
]

// Banners
import { BannerGroupComponent } from '@app/components/banners/banner-group/banner-group.component'
import { NotificationsBannerComponent } from '@app/components/banners/notifications-banner/notifications-banner.component'
import { NewJobsiteNumberBannerComponent } from '@app/components/banners/new-jobsite-number-banner/new-jobsite-number-banner.component'
import { PendingSchedulesBannerComponent } from '@app/components/banners/pending-schedules-banner/pending-schedules-banner.component'
import { OpenShiftsBannerComponent } from '@app/components/banners/open-shifts-banner/open-shifts-banner.component'
import { PendingTimeOffBannerComponent } from '@app/components/banners/pending-time-off-banner/pending-time-off-banner.component'
import { ProfileUpdateBannerComponent } from '@app/components/banners/profile-update-banner/profile-update-banner.component'
import { W2WSyncErrorsBannerComponent } from '@app/components/banners/w2w-sync-errors-banner/w2w-sync-errors-banner.component'

const bannerComponents = [
	BannerGroupComponent,
	NotificationsBannerComponent,
	NewJobsiteNumberBannerComponent,
	PendingSchedulesBannerComponent,
	OpenShiftsBannerComponent,
	PendingTimeOffBannerComponent,
	ProfileUpdateBannerComponent,
	W2WSyncErrorsBannerComponent,
]

////////////
// PUBLIC //
////////////

import { AboutComponent } from '@app/public/about/about.component'
import { MaintenanceComponent } from '@app/components/maintenance/maintenance.component'
import { NotProvisionedComponent } from '@app/public/not-provisioned/not-provisioned.component'
import { PublicHomeComponent } from '@app/public/home/home.component'
import { PublicLandingComponent } from '@app/public/landing/landing.component'
import { ResetApplicationComponent } from '@app/components/reset-application/reset-application.component'

const publicComponents = [
	AboutComponent,
	MaintenanceComponent,
	NotProvisionedComponent,
	PublicHomeComponent,
	PublicLandingComponent,
	ResetApplicationComponent,
]

////////////////////
// AUTHENTICATION //
////////////////////

import { LoginComponent } from '@app/public/authenticate/login/login.component'
import { LogoutComponent } from '@app/public/authenticate/logout/logout.component'
import { RegisterComponent } from '@app/public/authenticate/register/registration.component'
import { ForgotPassword2Component, ForgotPasswordStep1Component } from '@app/public/authenticate/forgot/forgotPassword.component'
import { RegistrationConfirmationComponent } from '@app/public/authenticate/confirm/confirmRegistration.component'
import { ResendCodeComponent } from '@app/public/authenticate/resend/resendCode.component'
import { InactiveLogoutComponent } from '@app/public/authenticate/inactive/inactive-logout.component'

const authComponents = [
	LoginComponent,
	LogoutComponent,
	RegisterComponent,
	ForgotPassword2Component,
	ForgotPasswordStep1Component,
	RegistrationConfirmationComponent,
	ResendCodeComponent,
	InactiveLogoutComponent,
]

////////////
// PEOPLE //
////////////

// Employee Components
import { EmployeesComponent } from '@app/admin/employees/employees.component'
import { EmployeeTableComponent } from '@app/admin/employees/employee-table/employee-table.component'
import { EmployeeEditComponent } from '@app/admin/employees/employee-edit/employee-edit.component'
import { EmployeeEditBatchComponent } from '@app/admin/employees/employee-edit-batch/employee-edit-batch.component'
import { EmployeeDeleteComponent } from '@app/admin/employees/employee-delete/employee-delete.component'
import { ProfileUpdateListComponent } from '@app/admin/employees/profile-update-list/profile-update-list.component'
import { ManageProfileComponent } from '@app/admin/employees/manage-profile/manage-profile.component'

const employeeComponents = [
	EmployeesComponent,
	EmployeeTableComponent,
	EmployeeEditComponent,
	EmployeeEditBatchComponent,
	EmployeeDeleteComponent,
	ProfileUpdateListComponent,
	ManageProfileComponent,
]

// Supervisor Components
import { UsersComponent } from '@app/admin/users/users.component'
import { UserTableComponent } from '@app/admin/users/user-table/table.component'
import { UserEditComponent } from '@app/admin/users/user-edit/edit.component'
import { UserGroupTableComponent } from '@app/admin/users/group-table/group-table.component'
import { UserGroupEditComponent } from '@app/admin/users/group-edit/group-edit.component'
import { UserRoleTableComponent } from '@app/admin/users/role-table/role-table.component'
import { UserRoleEditComponent } from '@app/admin/users/role-edit/role-edit.component'
import { UserPermissionsComponent } from '@app/admin/users/user-permissions/user-permissions.component'

const supervisorComponents = [
	UsersComponent,
	UserTableComponent,
	UserEditComponent,
	UserGroupTableComponent,
	UserGroupEditComponent,
	UserRoleTableComponent,
	UserRoleEditComponent,
	UserPermissionsComponent,
]

// Contacts
import { ContactComponent } from '@app/admin/contacts/contact.component'
import { ContactTableComponent } from '@app/admin/contacts/contact-table/contact-table.component'
import { ContactEditComponent } from '@app/admin/contacts/contact-edit/contact-edit.component'
import { OrganizationTableComponent } from '@app/admin/contacts/organization-table/organization-table.component'
import { OrganizationEditComponent } from '@app/admin/contacts/organization-edit/organization-edit.component'
import { LinkContactOrgListComponent } from '@app/components/link-contact-org-list/link-contact-org-list.component'
import { LinkContactOrgEditComponent } from '@app/components/link-contact-org-edit/link-contact-org-edit.component'

const contactComponents = [
	ContactComponent,
	ContactTableComponent,
	ContactEditComponent,
	OrganizationTableComponent,
	OrganizationEditComponent,
	LinkContactOrgListComponent,
	LinkContactOrgEditComponent,
]

//////////
// WORK //
//////////

// Job Components
import { JobsComponent } from '@app/admin/jobs/jobs.component'
import { JobTableComponent } from '@app/admin/jobs/job-table/job-table.component'
import { JobEditComponent } from '@app/admin/jobs/job-edit/job-edit.component'
import { JobEditBatchComponent } from '@app/admin/jobs/job-edit-batch/job-edit-batch.component'
import { JobDeleteComponent } from '@app/admin/jobs/job-delete/job-delete.component'

const jobComponents = [JobsComponent, JobTableComponent, JobEditComponent, JobEditBatchComponent, JobDeleteComponent]

// Job Site Components
import { JobSitesComponent } from '@app/admin/job-sites/job-sites.component'
import { JobSiteTableComponent } from '@app/admin/job-sites/job-site-table/job-site-table.component'
import { JobSiteEditComponent } from '@app/admin/job-sites/job-site-edit/job-site-edit.component'
import { JobSiteDeleteComponent } from '@app/admin/job-sites/job-site-delete/job-site-delete.component'
import { JobsiteNumbersComponent } from '@app/components/jobsite-numbers/jobsite-numbers.component'
import { JobSiteEditLinkedNumbersComponent } from '@app/admin/job-sites/job-site-edit-linked-numbers/job-site-edit-linked-numbers.component'
import { JobSiteEditLocationComponent } from '@app/admin/job-sites/job-site-edit-location/job-site-edit-location.component'

const jobSiteComponents = [
	JobSitesComponent,
	JobSiteTableComponent,
	JobSiteEditComponent,
	JobSiteDeleteComponent,
	JobsiteNumbersComponent,
	JobSiteEditLinkedNumbersComponent,
	JobSiteEditLocationComponent,
]

// Project Components
import { ProjectsComponent } from '@app/admin/projects/projects.component'
import { ProjectEditComponent } from '@app/admin/projects/project-edit/project-edit.component'
import { ProjectTableComponent } from '@app/admin/projects/project-table/project-table.component'
const projectComponents = [ProjectsComponent, ProjectTableComponent, ProjectEditComponent]

// Break Components
import { BreaksComponent } from '@app/admin/breaks/breaks.component'
import { BreakProfileEditComponent } from '@app/admin/breaks/break-profile-edit/break-profile-edit.component'
import { BreakRuleEditComponent } from '@app/admin/breaks/break-profile-edit/break-rule-edit/break-rule-edit.component'
import { BreakProfileTableComponent } from '@app/admin/breaks/break-profile-table/break-profile-table.component'
const breakComponents = [BreaksComponent, BreakProfileEditComponent, BreakRuleEditComponent, BreakProfileTableComponent]

// Tour Components
import { ToursComponent } from '@app/admin/tours/tours.component'
import { TourEditComponent } from '@app/admin/tours/tour-edit/tour-edit.component'
import { TourWaypointComponent } from '@app/admin/tours/tour-edit/tour-waypoint/tour-waypoint.component'
import { TourTableComponent } from '@app/admin/tours/tour-table/tour-table.component'
import { TourScheduleViewComponent } from '@app/admin/tours/tour-edit/tour-schedule-view/tour-schedule-view.component'

const tourComponents = [ToursComponent, TourEditComponent, TourWaypointComponent, TourTableComponent, TourScheduleViewComponent]

// Notification Components
import { NotificationsComponent } from '@app/admin/notifications/notifications.component'
import { NotificationProfileTableComponent } from '@app/admin/notifications/notification-profile-table/profile-table.component'
import { NotificationProfileEditComponent } from '@app/admin/notifications/notification-profile-edit/profile-edit.component'

const notificationComponents = [NotificationsComponent, NotificationProfileTableComponent, NotificationProfileEditComponent]

////////////////
// SCHEDULING //
////////////////

// Schedule Components
import { SchedulerComponent } from '@app/admin/scheduler/scheduler.component'
import { SchedulerListComponent } from '@app/admin/scheduler/series-view/list.component'
import { SchedulerBatchEditComponent } from '@app/admin/scheduler/series-view/batch-edit/scheduler-batch-edit.component'
import { SchedulerEditComponent } from '@app/admin/scheduler/series-view/series-edit/edit.component'
import { SchedulerShiftViewComponent } from '@app/admin/scheduler/shift-view/scheduler-shift-view.component'
import { ScheduleSnapshotComponent } from '@app/admin/scheduler/schedule-snapshot/schedule-snapshot.component'
import { ScheduleViewChangedItemsComponent } from '@app/admin/scheduler/changes-view/changed-items.component'
import { ScheduleChangesListComponent } from '@app/admin/scheduler/changes-view/list/changes-list.component'
import { ScheduleChangesEditComponent } from '@app/admin/scheduler/changes-view/edit/changes-edit.component'
import { SchedulerShiftViewEditComponent } from '@app/admin/scheduler/shift-view/shift-view-edit/shift-view-edit.component'
import { SchedulerEmployeeAssignmentComponent } from '@app/admin/scheduler/series-view/series-edit/scheduler-employee-assignment/scheduler-employee-assignment.component'
import { SchedulerShiftViewEventActionsDialogComponent } from '@app/admin/scheduler/shift-view/event-actions-dialog/event-actions-dialog.component'
import { SchedulerListItemEntryComponent } from '@app/admin/scheduler/series-view/list-item-entry/list-item-entry.component'
import { ListItemScheduledEmployeesComponent } from '@app/admin/scheduler/series-view/list-item-entry/list-item-scheduled-employees/list-item-scheduled-employees.component'
import { PendingScheduleApprovalComponent } from '@app/admin/scheduler/series-view/pending-schedule-approval/pending-schedule-approval.component'
import { ScheduledAnnEmpInfoCardComponent } from '@app/admin/announcements/scheduled-ann-emp-info-card/scheduled-ann-emp-info-card.component'

const scheduleComponents = [
	SchedulerComponent,
	SchedulerListComponent,
	SchedulerBatchEditComponent,
	SchedulerEditComponent,
	SchedulerShiftViewComponent,
	SchedulerShiftViewEditComponent,
	SchedulerEmployeeAssignmentComponent,
	SchedulerShiftViewEventActionsDialogComponent,
	ScheduleChangesListComponent,
	ScheduleChangesEditComponent,
	ScheduleSnapshotComponent,
	SchedulerListItemEntryComponent,
	ListItemScheduledEmployeesComponent,
	PendingScheduleApprovalComponent,
	ScheduledAnnEmpInfoCardComponent,
	ScheduleViewChangedItemsComponent,
]

// Shift View Components
import { ShiftViewSelectedEmployeesComponent } from '@app/admin/scheduler/shift-view/shift-view-selected-employees/shift-view-selected-employees.component'
import { ShiftViewSelectedJobsComponent } from '@app/admin/scheduler/shift-view/shift-view-selected-jobs/shift-view-selected-jobs.component'
import { ShiftViewEventDetailsComponent } from '@app/admin/scheduler/shift-view/shift-view-event-details/shift-view-event-details.component'
import { OpenShiftsListComponent } from '@app/admin/scheduler/shift-view/open-shifts-list/open-shifts-list.component'
import { OpenShiftOfferEditComponent } from '@app/admin/scheduler/shift-view/open-shift-offer/open-shift-offer.component'
const shiftViewComponents = [
	ShiftViewSelectedEmployeesComponent,
	ShiftViewSelectedJobsComponent,
	ShiftViewEventDetailsComponent,
	OpenShiftsListComponent,
	OpenShiftOfferEditComponent,
]

// Time Off Components
import { TimeOffEditComponent } from '@app/admin/scheduler/time-off-view/time-off-edit/time-off-edit.component'
import { TimeOffTableComponent } from '@app/admin/scheduler/time-off-view/time-off-table/time-off-table.component'

const timeOffComponents = [TimeOffEditComponent, TimeOffTableComponent]

////////////////
// OPERATIONS //
////////////////

// Time Entry Components
import { TransactionsComponent } from '@app/admin/transactions/transactions.component'
import { TransactionTableComponent } from '@app/admin/transactions/transaction-table/transaction-table.component'
import { TransactionEditComponent } from '@app/admin/transactions/transaction-edit/transaction-edit.component'
import { ShiftDetailsViewComponent } from '@app/admin/transactions/details-dialog/shift-details-view/shift-details-view.component'
import { ShiftDetailsEventLogComponent } from '@app/admin/transactions/details-dialog/shift-details-view/shift-details-event-log/shift-details-event-log.component'
import { TransReportsListComponent } from '@app/admin/transactions/reports-dialog/reports-list.component'
import { TransactionMapComponent } from '@app/admin/exceptions/transaction/transaction-map/transaction-map.component'
import { TransactionLiveViewDialogComponent } from '@app/admin/transactions/live-view-dialog/live-view-dialog.component'
import { BreakEditComponent } from '@app/admin/transactions/break-edit/break-edit.component'
import { CheckpointsDialogComponent } from '@app/admin/transactions/checkpoints-dialog/checkpoints-dialog.component'
import { DuplicateImagesComponent } from '@app/admin/transactions/duplicate-images/duplicate-images.component'
import { ShiftDetailsDialogComponent } from '@app/admin/transactions/details-dialog/shift-details-dialog.component'
import { ShiftDetailsWrapperComponent } from '@app/admin/transactions/details-dialog/shift-details-wrapper/shift-details-wrapper.component'
import { TransReportsEditComponent } from '@app/admin/transactions/reports-dialog/report-edit/trans-reports-edit.component'
import { ShiftLogPickerComponent } from '@app/admin/transactions/shift-log-picker/shift-log-picker.component'
import { TransDateLockDialogComponent } from '@app/admin/transactions/date-lock-dialog/trans-date-lock-dialog.component'
import { CheckpointsListComponent } from '@app/admin/transactions/checkpoints-dialog/checkpoints-list/checkpoints-list.component'
import { CheckpointsListItemComponent } from '@app/admin/transactions/checkpoints-dialog/checkpoints-list-item/checkpoints-list-item.component'

const transactionComponents = [
	TransactionsComponent,
	TransactionTableComponent,
	TransactionEditComponent,
	ShiftDetailsViewComponent,
	ShiftDetailsEventLogComponent,
	TransReportsListComponent,
	TransactionMapComponent,
	TransactionLiveViewDialogComponent,
	BreakEditComponent,
	CheckpointsDialogComponent,
	DuplicateImagesComponent,
	ShiftDetailsDialogComponent,
	TransReportsEditComponent,
	ShiftDetailsWrapperComponent,
	TransDateLockDialogComponent,
	ShiftLogPickerComponent,
	CheckpointsListComponent,
	CheckpointsListItemComponent,
]

// Business Insight Report Components
import { InsightReportsComponent } from '@app/admin/reports-insight/insight-reports.component'
import { InsightReportEditComponent } from '@app/admin/reports-insight/insight-report-edit/insight-report-edit.component'
import { InsightReportTableComponent } from '@app/admin/reports-insight/insight-report-table/insight-report-table.component'
import { InsightReportHistoryTableComponent } from '@app/admin/reports-insight/insight-report-history-table/insight-report-history-table.component'
import { InsightReportFiltersListComponent } from '@app/admin/reports-insight/insight-report-filter-list/insight-report-filters-list.component'

const businessInsightReportComponents = [
	InsightReportsComponent,
	InsightReportEditComponent,
	InsightReportTableComponent,
	InsightReportHistoryTableComponent,
	InsightReportFiltersListComponent,
]

// Employee Shift Reports
import { ShiftReportsComponent } from '@app/admin/reports-shift/shift-reports.component'
import { ShiftReportTableComponent } from '@app/admin/reports-shift/shift-report-table/shift-report-table.component'
import { FilterRangePickerComponent } from '@app/components/filter-range-picker/filter-range-picker.component'
import { ShiftReportTableFilterBarComponent } from '@app/admin/reports-shift/shift-report-table/shift-report-table-filter-bar/shift-report-table-filter-bar.component'

const employeeShiftReportComponents = [
	ShiftReportsComponent,
	ShiftReportTableComponent,
	FilterRangePickerComponent,
	ShiftReportTableFilterBarComponent,
]

// Comm Log
import { CommLogComponent } from '@app/admin/comm-log//comm-log.component'
import { CommLogCardComponent } from '@app/admin/transactions/click-to-call/comm-log-card/comm-log-card.component'
import { CommLogC2CTableComponent } from '@app/admin/comm-log/c2c-table/comm-log-c2c-table.component'
import { CommLogIVRTableComponent } from '@app/admin/comm-log/ivr-table/comm-log-ivr-table.component'
import { CommLogSMSTableComponent } from '@app/admin/comm-log/sms-table/comm-log-sms-table.component'
import { IVRLogDataComponent } from '@app/admin/comm-log/ivr-table/ivr-log-data/ivr-log-data.component'

const commLogComponents = [
	CommLogComponent,
	CommLogCardComponent,
	CommLogC2CTableComponent,
	CommLogIVRTableComponent,
	CommLogSMSTableComponent,
	IVRLogDataComponent,
]

// Audit Log Components
import { AuditModalComponent } from '@app/admin/audit-log/audit-log-modal/audit-log-modal.component'
import { AuditLogComponent } from '@app/admin/audit-log/audit-log.component'
import { AuditLogDiffComponent } from '@app/admin/audit-log/audit-log-diff/audit-log-diff.component'

const auditLogComponents = [AuditModalComponent, AuditLogComponent, AuditLogDiffComponent]

// Announcements
import { AnnouncementsComponent } from '@app/admin/announcements/announcements.component'
import { AnnouncementsCheckinEditComponent } from '@app/admin/announcements/checkin-edit/checkin-edit.component'
import { AnnouncementsCheckinListComponent } from '@app/admin/announcements/checkin-list/checkin-list.component'
import { AnnouncementsScheduledEditComponent } from '@app/admin/announcements/scheduled-edit/scheduled-edit.component'
import { AnnouncementsScheduledListComponent } from '@app/admin/announcements/scheduled-list/scheduled-list.component'

const announcementComponents = [
	AnnouncementsComponent,
	AnnouncementsCheckinEditComponent,
	AnnouncementsCheckinListComponent,
	AnnouncementsScheduledEditComponent,
	AnnouncementsScheduledListComponent,
]

//////////////////
// INTEGRATIONS //
//////////////////

// Integration Components
import { ADPMarketplaceLoginComponent } from '@app/public/authenticate/adp-login/adp-login.component'
import { ADPIntegrationComponent } from '@app/admin/integrations/adp/adpintegration.component'
import { FileImportIntegrationComponent } from '@app/admin/integrations/fileimport/file-import-integration.component'
import { FileImportUploaderComponent } from '@app/admin/integrations/fileimport//file-import-uploader/file-import-uploader.component'
import { WhenIWorkIntegrationComponent } from '@app/admin/integrations/wheniwork/wiwintegration.component'
import { WhenToWorkIntegrationComponent } from '@app/admin/integrations/whentowork/w2wintegration.component'
import { QuickbooksIntegrationComponent } from '@app/admin/integrations/quickbooks/integration.component'
import { QBOEmployeeDataComponent } from '@app/admin/integrations/quickbooks/qboemployee-data/qboemployee-data.component'
import { QBOCustomerDataComponent } from '@app/admin/integrations/quickbooks/qbocustomer-data/qbocustomer-data.component'
import { QBOVendorDataComponent } from '@app/admin/integrations/quickbooks/qbovendor-data/qbovendor-data.component'
import { QBOServiceItemDataComponent } from '@app/admin/integrations/quickbooks/qboservice-item-data/qboservice-item-data.component'
import { QBOTransactionDataComponent } from '@app/admin/integrations/quickbooks/qbotransaction-data/qbotransaction-data.component'
import { QBODeleteLinkComponent } from '@app/admin/integrations/quickbooks/qbodelete-link/qbodelete-link.component'
import { QBDFileUploaderComponent } from '@app/admin/integrations/quickbooks/qbdfile-uploader/qbdfile-uploader.component'
import { IntegrtionSyncLogComponent } from '@app/admin/integrations/sync-log/sync-log.component'
import { IntegrationSyncLogBatchComponent } from '@app/admin/integrations/sync-log/sync-log-batch/sync-log-batch.component'

const integrationComponents = [
	ADPMarketplaceLoginComponent,
	ADPIntegrationComponent,
	FileImportIntegrationComponent,
	FileImportUploaderComponent,
	WhenIWorkIntegrationComponent,
	WhenToWorkIntegrationComponent,

	QuickbooksIntegrationComponent,
	QBOEmployeeDataComponent,
	QBOCustomerDataComponent,
	QBOVendorDataComponent,
	QBOServiceItemDataComponent,
	QBOTransactionDataComponent,
	QBODeleteLinkComponent,
	QBDFileUploaderComponent,

	IntegrtionSyncLogComponent,
	IntegrationSyncLogBatchComponent,
]

///////////
// ADMIN //
///////////

// Settings Components
import { AdminPrefsComponent } from '@app/admin/settings/app-prefs/admin-prefs.component'
import { CompanyEditComponent } from '@app/admin/settings/company-edit/company-edit.component'
import { EmpAppPrefsComponent } from '@app/admin/settings/app-prefs/emp-prefs.component'
import { CallBlockingComponent } from '@app/admin/settings/call-blocking/call-blocking.component'
import { JobSiteMergeMigrationComponent } from '@app/admin/settings/job-site-merge-migration/job-site-merge-migration.component'
import { LogoUploaderComponent } from '@app/admin/settings/logo-uploader/company-logo-uploader.component'
import { MergeTransferPhoneNumberDialogComponent } from '@app/components/merge-transfer-phone-number-dialog/merge-transfer-phone-number-dialog.component'
import { OnboardDefinitionEditComponent } from '@app/admin/settings/employee-onboarding/onboard-definition-edit/onboard-definition-edit.component'
import { OnboardDefinitionListComponent } from '@app/admin/settings/employee-onboarding/onboard-definition-list/onboard-definition-list.component'
import { ScoreEditComponent } from '@app/admin/settings/emp-score/edit/edit.component'
import { SettingsComponent } from '@app/admin/settings/settings.component'
import { SettingsBillingInformationComponent } from '@app/admin/settings/billing-information/settings-billing-information.component'
import { SettingsCloseAccountComponent } from '@app/admin/settings/close-account/settings-close-account.component'
import { WorkorderEditComponent } from '@app/admin/settings/workorder-edit/workorder-edit.component'

// Settings > ADP

import { AdpIvrPhoneNumbersComponent } from '@app/admin/settings/adp-ivr-phone-numbers/adp-ivr-phone-numbers.component'
import { AdpIvrProfilesComponent } from '@app/admin/settings/adp-ivr-profiles/adp-ivr-profiles.component'
import { AdpIvrPhoneNumberEditComponent } from '@app/admin/settings/adp-ivr-phone-numbers/adp-ivr-phone-number-edit/adp-ivr-phone-number-edit.component'
import { AdpIvrProfileEditComponent } from '@app/admin/settings/adp-ivr-profiles/adp-ivr-profile-edit/adp-ivr-profile-edit.component'

// Settings (Employee Surveys)

import { EmployeeChecklistEditComponent } from '@app/admin/settings/employee-checklists/edit/employee-checklist-edit.component'
import { EmployeeChecklistListComponent } from '@app/admin/settings/employee-checklists/list/employee-checklist-list.component'
import { EmployeeChecklistSearchFilterComponent } from '@app/admin/settings/employee-checklists/list/filter/search-filter.component'
import { ChecklistFormItemCheckboxComponent } from '@app/admin/settings/employee-checklists/edit/checklist-form-item-checkbox/checklist-form-item-checkbox.component'
import { ChecklistFormItemInputComponent } from '@app/admin/settings/employee-checklists/edit/checklist-form-item-input/checklist-form-item-input.component'
import { ChecklistReportItemCheckboxComponent } from '@app/admin/transactions/reports-dialog/report-edit/checklist-report/checklist-report-item-checkbox/checklist-report-item-checkbox.component'
import { ChecklistReportItemInputComponent } from '@app/admin/transactions/reports-dialog/report-edit/checklist-report/checklist-report-item-input/checklist-report-item-input.component'
import { ChecklistFormItemRangeComponent } from '@app/admin/settings/employee-checklists/edit/checklist-form-item-range/checklist-form-item-range.component'
import { ChecklistReportItemRangeComponent } from '@app/admin/transactions/reports-dialog/report-edit/checklist-report/checklist-report-item-range/checklist-report-item-range.component'
import { ChecklistFormItemSelectComponent } from '@app/admin/settings/employee-checklists/edit/checklist-form-item-select/checklist-form-item-select.component'
import { ChecklistReportItemSelectComponent } from '@app/admin/transactions/reports-dialog/report-edit/checklist-report/checklist-report-item-select/checklist-report-item-select.component'

const settingsComponents = [
	AdminPrefsComponent,
	CallBlockingComponent,
	CompanyEditComponent,
	EmpAppPrefsComponent,
	JobSiteMergeMigrationComponent,
	LogoUploaderComponent,
	MergeTransferPhoneNumberDialogComponent,
	OnboardDefinitionEditComponent,
	OnboardDefinitionListComponent,
	ScoreEditComponent,
	SettingsComponent,
	SettingsBillingInformationComponent,
	SettingsCloseAccountComponent,
	WorkorderEditComponent,

	// ADP
	AdpIvrPhoneNumbersComponent,
	AdpIvrPhoneNumberEditComponent,
	AdpIvrProfilesComponent,
	AdpIvrProfileEditComponent,

	// Employee Surveys
	EmployeeChecklistListComponent,
	EmployeeChecklistEditComponent,
	EmployeeChecklistSearchFilterComponent,
	ChecklistFormItemCheckboxComponent,
	ChecklistFormItemInputComponent,
	ChecklistReportItemCheckboxComponent,
	ChecklistReportItemInputComponent,
	ChecklistFormItemRangeComponent,
	ChecklistReportItemRangeComponent,
	ChecklistFormItemSelectComponent,
	ChecklistReportItemSelectComponent,
]

// Billing Components

import { BillingContactsComponent } from '@app/admin/settings/billing-information/billing-contacts/billing-contacts.component'
import { BillingContactsEditComponent } from '@app/admin/settings/billing-information/billing-contacts/billing-contacts-edit/billing-contacts-edit.component'
import { BillingLogComponent } from '@app/admin/settings/billing-information/billing-log/billing-log.component'
import { BillingDateInfoComponent } from '@app/admin/settings/billing-information/billing-date-info/billing-date-info.component'
import { BillingPaypalComponent } from '@app/admin/settings/billing-information/billing-paypal/billing-paypal.component'
import { BillingStatusAlertComponent } from '@app/admin/settings/billing-information/billing-status-alert/billing-status-alert.component'
import { BillingStripeComponent } from '@app/admin/settings/billing-information/billing-stripe/billing-stripe.component'

const billingComponents = [
	BillingContactsComponent,
	BillingContactsEditComponent,
	BillingLogComponent,
	BillingDateInfoComponent,
	BillingPaypalComponent,
	BillingStatusAlertComponent,
	BillingStripeComponent,
]

// Stations
import { StationComponent } from '@app/admin/stations/station.component'
import { StationTableComponent } from '@app/admin/stations/station-table/station-table.component'
import { StationEditComponent } from '@app/admin/stations/station-edit/station-edit.component'

const stationComponents = [StationComponent, StationTableComponent, StationEditComponent]

// Bill/Pay Rates
import { PayRatesComponent } from '@app/admin/pay-rates/pay-rates.component'
import { EmpJobPayRatesTableComponent } from '@app/admin/pay-rates/emp-job-pay-rates-table/emp-job-pay-rates-table.component'
import { EmpJobPayRatesEditComponent } from '@app/admin/pay-rates/emp-job-pay-rates-edit/emp-job-pay-rates-edit.component'
import { OrgJobPayRatesTableComponent } from '@app/admin/pay-rates/org-job-pay-rates-table/org-job-pay-rates-table.component'
import { OrgJobPayRatesEditComponent } from '@app/admin/pay-rates/org-job-pay-rates-edit/org-job-pay-rates-edit.component'

const payRatesComponents = [
	PayRatesComponent,
	EmpJobPayRatesTableComponent,
	EmpJobPayRatesEditComponent,
	OrgJobPayRatesTableComponent,
	OrgJobPayRatesEditComponent,
]

///////////////////
// VISUALIZATION //
///////////////////

import { VisualizationComponent } from '@app/admin/visualization/visualization.component'
import { VisualizationPrefsComponent } from '@app/admin/settings/visualization-prefs/visualization-prefs.component'
import { VizDashboardEditComponent } from '@app/admin/visualization/viz-dashboard-edit/viz-dashboard-edit.component'
import { VizSectionHeaderComponent } from '@app/admin/visualization/viz-section-header/viz-section-header.component'
import { VizDashboardFilterBarComponent } from '@app/admin/visualization/viz-dashboard-filter-bar/viz-dashboard-filter-bar.component'
import { VizCardCountComponent } from '@app/admin/visualization/viz-card-count/viz-card-count.component'
import { VizCardGraphComponent } from '@app/admin/visualization/viz-card-graph/viz-card-graph.component'
import { VizCardGraphEditComponent } from '@app/admin/visualization/viz-card-graph-edit/viz-card-graph-edit.component'
import { VizHealthCenterComponent } from '@app/admin/visualization/viz-health-center/viz-health-center.component'
import { VizHealthScoreBannerComponent } from '@app/admin/visualization/viz-health-score-banner/viz-health-score-banner.component'

const visualizationComponents = [
	VisualizationComponent,
	VisualizationPrefsComponent,
	VizDashboardEditComponent,
	VizSectionHeaderComponent,
	VizDashboardFilterBarComponent,
	VizCardCountComponent,
	VizCardGraphComponent,
	VizCardGraphEditComponent,
	VizHealthCenterComponent,
	VizHealthScoreBannerComponent,
]

///////////////////
// MISCELLANEOUS //
///////////////////

// Call Center
import { ClickToCallComponent } from '@app/admin/transactions/click-to-call/click-to-call.component'
import { CallCenterSettingsComponent } from '@app/admin/settings/call-center-settings/call-center-settings.component'

import { ClickToCallMessageComponent } from '@app/admin/call-center/click-to-call-message/click-to-call-message.component'
import { ClickToCallAddressBookComponent } from '@app/admin/call-center/click-to-call-address-book/click-to-call-address-book.component'
import { ClickToCallEventDialogComponent } from '@app/admin/call-center/click-to-call-event-dialog/click-to-call-event-dialog.component'
import { ClickToCallDirectoryComponent } from '@app/admin/transactions/click-to-call/click-to-call-directory/click-to-call-directory.component'
import { ClickToCallEventLogComponent } from '@app/admin/transactions/click-to-call/click-to-call-event-log/click-to-call-event-log.component'
import { ClickToCallNotesComponent } from '@app/admin/transactions/click-to-call/click-to-call-notes/click-to-call-notes.component'
import { CallCenterManageSkillsComponent } from '@app/admin/settings/call-center-settings/call-center-manage-skills/call-center-manage-skills.component'
import { CallCenterManageSkillEditComponent } from '@app/admin/settings/call-center-settings/call-center-manage-skill-edit/call-center-manage-skill-edit.component'
import { UserSkillLinkListComponent } from '@app/admin/users/user-table/user-skill-link-list/user-skill-link-list.component'
import { DispatchActiveCallManagerComponent } from '@app/admin/header/header-dispatcher/header-dispatch-settings/dispatch-active-call-manager/dispatch-active-call-manager.component'

const callCenterComponents = [
	ClickToCallComponent,
	ClickToCallMessageComponent,
	ClickToCallDirectoryComponent,
	ClickToCallEventLogComponent,
	ClickToCallNotesComponent,
	ClickToCallAddressBookComponent,
	ClickToCallEventDialogComponent,
	CallCenterSettingsComponent,
	CallCenterManageSkillsComponent,
	CallCenterManageSkillEditComponent,
	UserSkillLinkListComponent,
	DispatchActiveCallManagerComponent,
]

// Exception Components
import { ExceptionsComponent } from '@app/admin/exceptions/exceptions.component'
import { ExceptionsLocationComponent } from '@app/admin/exceptions/location/exceptions-location.component'
import { ExceptionsLocationListComponent } from '@app/admin/exceptions/location-list/exceptions-location-list.component'
import { ExceptionsTransactionComponent } from '@app/admin/exceptions/transaction/exceptions-transaction.component'
import { ExceptionsTransactionListComponent } from '@app/admin/exceptions/transaction-list/transaction-list.component'

const exceptionComponents = [
	ExceptionsComponent,
	ExceptionsLocationComponent,
	ExceptionsLocationListComponent,
	ExceptionsTransactionComponent,
	ExceptionsTransactionListComponent,
]

// General Components
import { ActivityBannerComponent } from '@app/components/activity-banner/activity-banner.component'
import { BasicModalComponent } from '@app/components/basic-modal/basic-modal.component'
import { BatchActionBannerComponent } from '@app/components/batch-action-banner/batch-action-banner.component'
import { BulkActionDialogComponent } from '@app/components/bulk-action-dialog/bulk-action-dialog.component'
import { ChatBotComponent } from '@app/components/chat-bot/chat-bot.component'
import { ColorPickerComponent } from '@app/components/color-picker/color-picker.component'
import { ConfirmationDialogComponent } from '@app/components/confirmation-dialog/confirmation-dialog.component'
import { ContactSupportDialogComponent } from '@app/components/contact-support-dialog/contact-support-dialog.component'
import { DateTimePickerComponent } from '@app/components/date-time-picker/date-time-picker.component'
import { DeleteRecordDialogComponent } from '@app/components/delete-record-dialog/delete-record-dialog.component'
import { EditMapMarkerComponent } from '@app/components/edit-map-marker/edit-map-marker.component'
import { EmailListComponent } from '@app/components/email-list/email-list.component'

import { FileDropperComponent } from '@app/components/file-dropper/file-dropper.component'
import { FileHashCompareComponent } from '@app/components/secure-files/file-hash-compare/file-hash-compare.component'
import { FileUploaderComponent } from '@app/components/file-uploader/file-uploader.component'

import { FormExpanderComponent } from '@app/components/form-expander/form-expander.component'
import { FormPhoneNumberComponent } from '@app/components/form-phone-number/form-phone-number.component'
import { FormAddressComponent } from '@app/components/form-address/form-address.component'
import { FormTagsComponent } from '@app/components/form-tags/form-tags.component'
import { FormInputSwitchComponentComponent } from '@app/components/form-input-switch/form-input-switch.component'

import { FullScreenLoaderComponent } from '@app/components/full-screen-loader/full-screen-loader.component'

import { GenericEmailListComponent } from '@app/components/generic-email-list/generic-email-list.component'
import { GenericPhoneListComponent } from '@app/components/generic-phone-list/generic-phone-list.component'

import { GlobalAudioPlayerComponent } from '@app/components/global-audio-player/global-audio-player.component'
import { HelpVideoLinkComponent } from '@app/components/help-video-link/help-video-link.component'
import { HorizontalPaddleScrollerComponent } from '@app/components/horizontal-paddle-scroller/horizontal-paddle-scroller.component'
import { IconHelpComponent } from '@app/components/icon-help/icon-help.component'
import { ImageFilesViewComponent } from '@app/components/image-files-view/image-files-view.component'
import { LocalPrefsComponent } from '@app/components/local-prefs/local-prefs.component'
import { ModalDialogComponent } from '@app/components/modal-dialog/modal-dialog.component'
import { MultiSelectDetailsComponent } from '@app/components/multi-select-details/multi-select-details.component'
import { MultiSelectorComponent } from '@app/components/multi-selector/multi-selector.component'
import { NoDataTipComponent } from '@app/components/no-data-tip/no-data-tip.component'
import { ProgressComponent } from '@app/components/progress/progress.component'
import { SearchFilterComponent } from '@app/components/search-filter/search-filter.component'
import { SectionListCountComponent } from '@app/components/section-list-counter/section-list-count.component'
import { SectionProcessIndicatorComponent } from '@app/components/section-process-indicator/section-process-indicator.component'
import { SectionSwitcherComponent } from '@app/components/section-switcher/section-switcher.component'

import { SecureFilesEditComponent } from '@app/components/secure-files/secure-files-edit/secure-files-edit.component'
import { SecureFilesListComponent } from '@app/components/secure-files/secure-files-list/secure-files-list.component'
import { SecureFilesListItemComponent } from '@app/components/secure-files/secure-files-list/secure-files-list-item/secure-files-list-item.component'
import { SecureFilesUploadComponent } from '@app/components/secure-files/secure-files-upload/secure-files-upload.component'

import { SubSectionTitleComponent } from '@app/components/sub-section-title/sub-section-title.component'
import { TableFilterButtonsComponent } from '@app/components/table-filter-buttons/table-filter-buttons.component'
import { TableFooterButtonsComponent } from '@app/components/table-footer-buttons/table-footer-buttons.component'
import { ViewQRCodeComponent } from '@app/components/view-qrcode/view-qrcode.component'

export const generalComponents = [
	ActivityBannerComponent,
	BasicModalComponent,
	BatchActionBannerComponent,
	BulkActionDialogComponent,
	ChatBotComponent,
	ColorPickerComponent,
	ConfirmationDialogComponent,
	ContactSupportDialogComponent,
	DateTimePickerComponent,
	DeleteRecordDialogComponent,
	EditMapMarkerComponent,
	EmailListComponent,

	FileDropperComponent,
	FileHashCompareComponent,
	FileUploaderComponent,

	FormExpanderComponent,
	FormPhoneNumberComponent,
	FormAddressComponent,
	FormInputSwitchComponentComponent,
	FormTagsComponent,

	FullScreenLoaderComponent,

	GenericEmailListComponent,
	GenericPhoneListComponent,

	GlobalAudioPlayerComponent,
	HelpVideoLinkComponent,
	HorizontalPaddleScrollerComponent,
	IconHelpComponent,
	ImageFilesViewComponent,
	LocalPrefsComponent,
	ModalDialogComponent,
	MultiSelectDetailsComponent,
	MultiSelectorComponent,
	NoDataTipComponent,
	ProgressComponent,
	SearchFilterComponent,
	SectionListCountComponent,
	SectionProcessIndicatorComponent,
	SectionSwitcherComponent,

	SecureFilesEditComponent,
	SecureFilesListComponent,
	SecureFilesListItemComponent,
	SecureFilesUploadComponent,

	SubSectionTitleComponent,
	TableFilterButtonsComponent,
	TableFooterButtonsComponent,
	ViewQRCodeComponent,
]

// Global Components
import { ChangePasswordComponent } from '@app/components/change-password/change-password.component'
import { RedirectComponent } from '@app/public/redirect/redirect.component'
import { RedirectRecordComponent } from '@app/public/redirect/redirect-record/redirect-record.component'
import { UnsupportedBrowserComponent } from '@app/components/unsupported-browser/unsupported-browser.component'

export const globalComponents = [ChangePasswordComponent, RedirectComponent, RedirectRecordComponent, UnsupportedBrowserComponent]

////////////
// SHARED //
////////////

import { ShiftReportPublicViewComponent } from '@app/components/shift-report/shift-report-public-view/shift-report-public-view.component'
import { UserReportRenderHostComponent } from '@app/components/shift-report/render-host/user-report-render-host.component'
import { UserReportHeaderComponent } from '@app/components/shift-report/shared-header/user-report-header.component'
import { UserReportFooterComponent } from '@app/components/shift-report/shared-footer/user-report-footer.component'
import { UserReportTransactionComponent } from '@app/components/shift-report/shared-transaction/user-report-transaction.component'
import { IncidentReportComponent } from '@app/admin/transactions/reports-dialog/report-edit/incident-report/incident-report.component'
import { IncidentContactComponent } from '@app/admin/transactions/reports-dialog/report-edit/incident-report/incident-contact/incident-contact.component'
import { IncidentReportRendererComponent } from '@app/components/shift-report/report-incident/incident-report-renderer.component'
import { HomecareReportComponent } from '@app/admin/transactions/reports-dialog/report-edit/homecare-report/homecare-report.component'
import { HomecareReportRendererComponent } from '@app/components/shift-report/report-homecare/homecare-report-renderer.component'
import { VehicleInspectionReportComponent } from '@app/admin/transactions/reports-dialog/report-edit/vehicle-inspection-report/vehicle-inspection-report.component'
import { VehicleInspectionReportRendererComponent } from '@app/components/shift-report/report-vehicle-inspection/vehicle-inspection-report-renderer.component'
import { ChecklistReportComponent } from '@app/admin/transactions/reports-dialog/report-edit/checklist-report/checklist-report.component'
import { ChecklistReportRendererComponent } from '@app/components/shift-report/report-checklist/checklist-report-renderer.component'
import { ShiftReportRendererComponent } from '@app/components/shift-report/report-shift/shift-report-renderer.component'
import { ShiftSummaryReportRendererComponent } from '@app/components/shift-report/report-shift-summary/shift-summary-report-renderer.component'
import { UserReportImagesComponent } from '@app/components/shift-report/shared-images/user-report-images.component'
import { UserReportEventMapComponent } from '@app/components/shift-report/shared-map/user-report-event-map.component'

const sharedReportComponents = [
	UserReportHeaderComponent,
	UserReportFooterComponent,
	UserReportTransactionComponent,
	IncidentReportComponent,
	IncidentContactComponent,
	IncidentReportRendererComponent,
	HomecareReportComponent,
	HomecareReportRendererComponent,
	VehicleInspectionReportComponent,
	VehicleInspectionReportRendererComponent,
	ChecklistReportComponent,
	ChecklistReportRendererComponent,
	ShiftReportRendererComponent,
	ShiftReportPublicViewComponent,
	ShiftSummaryReportRendererComponent,
	UserReportImagesComponent,
	UserReportEventMapComponent,
	UserReportRenderHostComponent,
]

///////////////////////////
// BASE SETUP COMPONENTS //
///////////////////////////

// This is the template for a new section. Duplicate these components and place where needed.

// import { BaseComponent } from '@app/admin/base/base.component'
// import { BaseTableComponent } from '@app/admin/base/base-table/base-table.component'
// import { BaseEditComponent } from '@app/admin/base/base-edit/base-edit.component'

// const baseComponents = [BaseComponent, BaseTableComponent, BaseEditComponent]

////////////////
// AGGREGATOR //
////////////////

export const appComponents = [
	// Base Section Template
	...baseComponents,

	// Application
	...layoutComponents,
	...bannerComponents,
	...publicComponents,
	...authComponents,

	// People
	...employeeComponents,
	...supervisorComponents,
	...contactComponents,

	// Work
	...jobSiteComponents,
	...jobComponents,
	...projectComponents,
	...breakComponents,
	...tourComponents,
	...notificationComponents,

	// Scheduling
	...scheduleComponents,
	...shiftViewComponents,
	...timeOffComponents,

	// Operations
	...transactionComponents,
	...employeeShiftReportComponents,
	...businessInsightReportComponents,
	...commLogComponents,
	...auditLogComponents,
	...announcementComponents,

	// Admin
	...settingsComponents,
	...billingComponents,
	...stationComponents,
	...payRatesComponents,

	// Integrations
	...integrationComponents,

	// Miscellaneous
	...callCenterComponents,
	...exceptionComponents,
	...visualizationComponents,
	...generalComponents,
	...globalComponents,
	...sharedReportComponents,

	// Base Setup Component Templates
	// ...baseComponents,
]

///////////////////////////
// STANDALONE COMPONENTS //
///////////////////////////

import { ImageCropperComponent } from 'ngx-image-cropper'

export const standaloneComponents = [ImageCropperComponent]
