<div class="row">
	<div class="col-12 col-sm-6 record-item">
		<button
			*ngIf="!syncData.desktop_sync"
			class="btn btn-sm btn-success btn-block qbo-button"
			[class.disabled]="!canPerformAction(CrudAction.update)"
			(click)="importData()"
		>
			<i *ngIf="!isWorking" class="fa fa-cloud-download" aria-hidden="true"></i>
			<i *ngIf="isWorking" class="fas fa-loader fa-spin" aria-hidden="true"></i>
			Import Employee Data
		</button>
		<strong *ngIf="syncData.desktop_sync">Employee Data</strong>
	</div>
	<div class="col-12 col-sm-6 record-item">
		<a href="#" (click)="toggleTooltips()">
			<span class="qbo-tooltip-icon">
				<i class="fal fa-question-circle" aria-hidden="true"></i>
			</span>
		</a>
		<div>
			<strong>Last import on:</strong>
		</div>
		<div>
			<small>
				<b>{{ lastImportDate }}</b>
			</small>
		</div>
	</div>
</div>

<div *ngIf="hasLog" class="row">
	<div class="col-12">
		<button *ngIf="syncErrors > 0" class="btn btn-block btn-sm btn-danger btn-qb-log" [routerLink]="['/admin/integrations/log/qbo/Employee']">
			View Log ( {{ syncErrors }} Error{{ syncErrors > 1 ? 's' : '' }} )
		</button>
		<button *ngIf="syncErrors === 0" class="btn btn-block btn-sm btn-secondary btn-qb-log" [routerLink]="['/admin/integrations/log/qbo/Employee']">
			View Log ( No Errors )
		</button>
	</div>
</div>

<div class="row">
	<div *ngIf="showTooltips" class="col-12 qbo-tooltip-block text-muted">
		<div *ngIf="syncData.desktop_sync">
			Employee names must be entered manually into Telephone Timesheets and they must match exactly with the employee names in QuickBooks. Click
			the “View Log” button above to see any mismatched names.
		</div>
		<div *ngIf="!syncData.desktop_sync">
			Click the Import Employee Data button to perform an initial import of your employees, and to import new or modified employees from the master
			list you keep in QuickBooks Online.
		</div>
	</div>
</div>

<div *ngIf="false" class="alert alert-info">
	{{ syncData | json }}
</div>

<hr class="qbo-log-divider" />
