import { AfterContentInit, AfterViewInit, Component, Input, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { FormHelper, log, PhoneHelper } from '@app/helpers'
import { DialogManager, FormPhoneNumberManager, GenericEmailListManager, HelpDialogMessage, CompanyRecord } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { environment } from '@env/environment'

@Component({
	selector: 'app-call-center-settings',
	templateUrl: './call-center-settings.component.html',
	styleUrls: ['./call-center-settings.component.scss'],
	standalone: false,
})
export class CallCenterSettingsComponent implements OnInit, AfterViewInit, AfterContentInit {
	environment = environment

	isInternal = false
	isUpdating = false
	isManagingSkills = false
	isInboundCallingEnabled = false
	isOutboundCallingEnabled = false

	outboundPhoneManager: FormPhoneNumberManager
	outboundCallRecordingsEmailListManager: GenericEmailListManager

	inboundPhoneManager: FormPhoneNumberManager
	inboundVoicemailEmailListManager: GenericEmailListManager
	inboundRecordingsEmailListManager: GenericEmailListManager

	ccForm: UntypedFormGroup

	@Input() dialogManager = new DialogManager()

	calloutCallerId: string
	inboundPhoneNumber: string

	constructor(
		private coreSrvc: CoreService,
		private fb: UntypedFormBuilder,
	) {
		this.setupComponent()
		this.setupFormInputManagers()
		this.setupForm()

		this.isInternal = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()
		this.calloutCallerId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().callout_callerid_e164
	}

	get outboundCallerId(): string {
		const e164 = this.outboundPhoneManager?.e164 ?? this.calloutCallerId
		return e164 ? PhoneHelper.formatPhoneFromE164(e164) : 'Not Setup'
	}

	ngOnInit(): void {}

	ngAfterViewInit(): void {}

	ngAfterContentInit() {
		this.setupDialogManager()
	}

	private setupComponent() {
		this.coreSrvc.dbSrvc.readTable('phone_number').then((success) => {
			const dispatchNumbers = this.coreSrvc.dbSrvc.settingSrvc
				.getCallInPhoneNumberRecords()
				.filter((pn) => pn.route_application.includes('DISPATCH'))
			this.inboundPhoneNumber = dispatchNumbers[0]?.number_e164 ?? null
		})
	}

	private setupDialogManager() {
		this.dialogManager.submitBtnAction = () => this.onSubmit()
		this.dialogManager.canSubmit = () => this.isFormValid
	}

	private setupFormInputManagers() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		log('Current Company Record', company)

		this.outboundPhoneManager = new FormPhoneNumberManager()
		this.outboundPhoneManager.e164 = company.c2c_caller_id_e164
		this.outboundPhoneManager.helpKey = 'outboundCallerId'
		this.outboundPhoneManager.label = 'Outbound Caller ID'

		this.outboundCallRecordingsEmailListManager = new GenericEmailListManager(this.coreSrvc)
		this.outboundCallRecordingsEmailListManager.initWithString(company.c2c_email)

		this.inboundPhoneManager = new FormPhoneNumberManager()
		this.inboundPhoneManager.helpKey = 'inboundPhone'
		this.inboundPhoneManager.label = 'Inbound Phone Number'
		// this.inboundPhoneManager.e164 = '+16125551212'

		this.inboundRecordingsEmailListManager = new GenericEmailListManager(this.coreSrvc)
		this.inboundRecordingsEmailListManager.initWithString(company.cc_email)

		this.inboundVoicemailEmailListManager = new GenericEmailListManager(this.coreSrvc)
		this.inboundVoicemailEmailListManager.initWithString(company.cc_vm_email)
	}

	private setupForm() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		this.isInboundCallingEnabled = company.cc_enable
		this.isOutboundCallingEnabled = company.c2c_enable

		this.ccForm = this.fb.group({
			// Outbound Click-to-Call
			c2c_enable: [!!company.c2c_enable],
			c2c_record_enable: [!!company.c2c_record_enable],
			c2c_preamble_message: [company.c2c_preamble_message],
			syn_c2c_email_enable: [!!company.c2c_email],

			// Inbound Call Center
			cc_enable: [!!company.cc_enable],
			cc_record_enable: [!!company.cc_record_enable],
			cc_preamble_message: [company.cc_preamble_message],

			syn_cc_email_enable: [!!company.cc_email],

			// Voicemail
			cc_vm_enable: [!!company.cc_vm_enable],
			syn_cc_vm_email_enable: [!!company.cc_vm_email],
			cc_vm_email: [!!company.cc_vm_email],

			cc_vm_message: [company.cc_vm_message],

			agent_logout_timer: [company.agent_logout_timer],
		})

		if (!company.c2c_enable) {
			this.ccForm.get('c2c_record_enable').disable()
			this.ccForm.get('syn_c2c_email_enable').disable()
		}

		if (!company.cc_enable) {
			this.ccForm.get('cc_record_enable').disable()
			this.ccForm.get('syn_cc_email_enable').disable()
			this.ccForm.get('cc_vm_enable').disable()
			this.ccForm.get('syn_cc_vm_email_enable').disable()
		}
	}

	private get isFormValid(): boolean {
		const isOubboundPhoneNumberValid = this.outboundPhoneManager.isFormValid
		const isOutboundEmailListValid = this.outboundCallRecordingsEmailListManager.isFormValid
		const isInboundPhoneNumberValid = this.inboundPhoneManager.isFormValid
		const isInboundVoicemailEmailListValid = this.inboundVoicemailEmailListManager.isFormValid
		const isInboundRecordingsEmailListValid = this.inboundRecordingsEmailListManager.isFormValid

		const isVoicemailEnabled = this.ccForm.get('cc_vm_enable').value
		const hasVoicemailMessage = !!this.ccForm.get('cc_vm_message').value
		const areVmSettingsValid = isVoicemailEnabled ? hasVoicemailMessage : true

		return (
			isOubboundPhoneNumberValid &&
			isOutboundEmailListValid &&
			isInboundPhoneNumberValid &&
			isInboundVoicemailEmailListValid &&
			isInboundRecordingsEmailListValid &&
			areVmSettingsValid
		)
	}

	private onSubmit() {
		if (this.isUpdating) return
		// FormHelper.trimOnlyWhitespace(this.ccForm)

		this.prepareForSubmission()
		const record = this.makeUpdateRecord()
		log('UPDATE RECORD', record)
		if (record) {
			this.isUpdating = true
			this.coreSrvc.dbSrvc.updateRecord('company', record).then((success) => {
				if (success) {
					this.dialogManager.isDialogVisible = false
				} else {
					this.isUpdating = false
				}
			})
		}
	}

	private prepareForSubmission(): boolean {
		this.inboundVoicemailEmailListManager.prepareForSubmission()
		this.inboundRecordingsEmailListManager.prepareForSubmission()
		this.outboundCallRecordingsEmailListManager.prepareForSubmission()
		return true
	}

	private makeUpdateRecord(): CompanyRecord {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const record = new CompanyRecord(company)

		// Outbound Recordings
		record.c2c_record_enable = this.ccForm.get('c2c_record_enable').value
		const outboundEmailEnabled = this.ccForm.get('syn_c2c_email_enable').value
		record.c2c_email = outboundEmailEnabled ? this.outboundCallRecordingsEmailListManager.emailListString : null

		const isC2CRecordingEnabled = this.ccForm.get('c2c_record_enable').value
		const c2cPreambleMsg = this.ccForm.get('c2c_preamble_message').value
		record.c2c_preamble_message = isC2CRecordingEnabled && !!c2cPreambleMsg ? c2cPreambleMsg : null

		// Inbound Recordings
		record.cc_record_enable = this.ccForm.get('cc_record_enable').value
		const inboundEmailEnabled = this.ccForm.get('syn_cc_email_enable').value
		record.cc_email = inboundEmailEnabled ? this.inboundRecordingsEmailListManager.emailListString : null

		const isCCRecordingEnabled = this.ccForm.get('cc_record_enable').value
		const ccPreambleMsg = this.ccForm.get('cc_preamble_message').value
		record.cc_preamble_message = isCCRecordingEnabled && !!ccPreambleMsg ? ccPreambleMsg : null

		// Voicemail Recordings
		record.cc_vm_enable = this.ccForm.get('cc_vm_enable').value
		const voicemailEmailEnabled = this.ccForm.get('syn_cc_vm_email_enable').value
		record.cc_vm_email = voicemailEmailEnabled ? this.inboundVoicemailEmailListManager.emailListString : null

		const isVoicemailEnabled = this.ccForm.get('cc_vm_enable').value
		const voicemailMessage = this.ccForm.get('cc_vm_message').value
		record.cc_vm_message = isVoicemailEnabled && !!voicemailMessage ? voicemailMessage : null

		// General Settings
		const agentLogoutTimer = this.ccForm.get('agent_logout_timer').value
		record.agent_logout_timer = parseInt(agentLogoutTimer, 10) ?? null

		return record
	}

	public toggleCheckbox(prop: string) {
		const current = this.ccForm.get(prop).value
		this.ccForm.get(prop).setValue(!current)
		this.updateDependencies()
	}

	// Used to get form in valid state when checkboxes are changed
	public updateDependencies() {
		const recordOutboundCalls = this.ccForm.get('c2c_record_enable').value
		if (!recordOutboundCalls) this.ccForm.get('syn_c2c_email_enable').setValue(false)

		const recordInboundCalls = this.ccForm.get('cc_record_enable').value
		if (!recordInboundCalls) this.ccForm.get('syn_cc_email_enable').setValue(false)

		const enableVoicemail = this.ccForm.get('cc_vm_enable').value
		if (!enableVoicemail) this.ccForm.get('syn_cc_vm_email_enable').setValue(false)
	}

	public startManagingSkills() {
		this.dialogManager.pushState()
		this.dialogManager.isBackBtnVisible = true
		this.dialogManager.isCancelBtnVisble = false
		this.dialogManager.submitBtnLabel = 'New'
		this.dialogManager.backBtnAction = () => this.endManagingSkills()
		this.isManagingSkills = true
	}

	public endManagingSkills() {
		this.isManagingSkills = false
		this.dialogManager.popStateAndApply()
	}

	public showHelp(key: string) {
		log('Show Help for', key)

		const help = new HelpDialogMessage('Topic Unavailable', 'Could not determine the help topic to display.')
		switch (key) {
			case 'outboundCallerId':
				help.header = 'Caller ID'
				help.message = `By default, all outbound click-to-calls will come from the same toll-free phone number that is used by your employees for clock in/out.  Please contact support if you would like to use a different phone number.  For example, you could use one of your existing customer service phone numbers for outbound calls.`
				break
			case 'c2c_preamble_message':
				help.header = 'Outbound Preamble'
				help.message = `This message will be played when the system is connecting a call to a live operator. It can be used to identify your company, state terms of service, let them know their calls are being recorded, or provide additional information before the caller is connected to an agent.`
				break
			case 'inboundPhone':
				help.header = 'Inbound Number'
				help.message = `This is the phone number that your customers, employees, etc. should call when they need to reach a live operator/dispatcher.\n\nAny calls to this number will be routed to all employees and supervisors who are active dispatchers.\n\nActive dispatchers include any employees who are clocked in to the ‘dispatch’ job as well as any supervisors who have clicked on the ‘Make me a dispatcher’ button in the admin portal.`
				break
			case 'cc_preamble_message':
				help.header = 'Inbound Preamble'
				help.message = `This message will be played when people call into the system. It can be used to identify your company, state terms of service, let them know their calls are being recorded, or provide additional information before the caller is connected to an agent.`
				break
			case 'emailOutboundCallRecordings':
				help.header = 'Outbound Recordings'
				help.message = `This is a list of email addresses which will receive call recordings from outbound calls.`
				break
			case 'emailInboundCallRecordings':
				help.header = 'Inbound Recordings'
				help.message = `This is a list of email addresses which will receive call recordings from inbound calls.`
				break
			case 'emailVoicemailRecordings':
				help.header = 'Voicemail Recordings'
				help.message = `This is a list of email addresses which will receive voicemail recordings.`
				break
			case 'agent_logout_timer':
				help.header = 'Agent Signout Timer'
				help.message =
					'This option specifies the maximum time (in hours) that an agent may be signed in before being automatically signed out from agent status. Leave blank to disable automatic signout for agents.'
				break
		}
		this.coreSrvc.notifySrvc.showHelp(help)
	}
}
