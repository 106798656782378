<div class="options-block">
	<div *ngIf="manager.showOptionalOrRequired && !manager.formInput" class="input-optional float-right" [class.input-required]="manager.isRequired">
		({{ manager.isRequired ? 'required' : 'optional' }})
	</div>
	<div *ngIf="manager.formInput && !manager.isNumberValid" class="input-required float-right">not valid</div>
	<div *ngIf="manager.formInput && manager.isNumberValid" class="float-right" style="color: green">valid</div>
	<div class="options-title">
		{{ manager.label }}
		<i *ngIf="manager.helpKey" title="More Information" class="far fa-info-circle tts-info-btn" aria-hidden="true" (click)="helpBtnClicked()"></i>
	</div>
	<div class="form-group">
		<table width="100%">
			<tr>
				<td class="phone-number-code-td">
					<select class="form-control phone-country-select" [(ngModel)]="dialingCode" (change)="formatNumber()">
						<option *ngFor="let code of countryCodeData" value="{{ code.id }}">{{ code.id }}</option>
					</select>
				</td>
				<td>&nbsp;&nbsp;</td>
				<td class="phone-number-input-td">
					<input
						(paste)="handlePasteEvent($event)"
						(keyup)="formatNumber()"
						[placeholder]="manager.placeholder"
						class="form-control"
						[class.is-valid]="manager.isNumberValid"
						[(ngModel)]="manager.formInput"
						[ngModelOptions]="{ standalone: true }"
						type="tel"
						autocomplete="off"
					/>
				</td>
			</tr>
		</table>
	</div>
</div>
