import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'lineBreaks',
    standalone: false
})
export class LinebreaksPipe implements PipeTransform {
	transform(text: any): any {
		if (!text) return ''
		return text.replace(/\n/g, '<br />')
	}
}
