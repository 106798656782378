import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { DataAccessRequest, DialogManager, HelpDialogMessage, DatabaseTableName } from '@app/models'
import { CoreService } from '@app/services'
import { DateTimeHelper, DropdownHelper, DropdownHelperConfig, log } from '@app/helpers'

import { FormTagsComponent } from '@app/components/form-tags/form-tags.component'

import { DeviceDetectorService } from 'ngx-device-detector/public-api'
import { SelectItem } from 'primeng/api'
import moment from 'moment-timezone'

@Component({
    selector: 'app-scheduler-batch-edit',
    templateUrl: './scheduler-batch-edit.component.html',
    styleUrl: './scheduler-batch-edit.component.scss',
    standalone: false
})
export class SchedulerBatchEditComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
	resource = 'schedule_recur' as DatabaseTableName
	isUpdating = false

	record = new SchedulerBatchEditRecord()
	tagOptionsList = this.coreSrvc.dbSrvc.schedulerSrvc.getTagLabels()

	employeeOptions: Array<SelectItem> = []
	jobOptions: Array<SelectItem> = []

	timeFormat = DateTimeHelper.getTimeFormat()

	@Input() dialogManager: DialogManager
	@Output() actionComplete = new EventEmitter<boolean>()
	@ViewChild('tagComp') tagComp: FormTagsComponent

	constructor(private coreSrvc: CoreService) {
		this.setupDropdowns()
	}

	get devDetect(): DeviceDetectorService {
		return this.coreSrvc.devDetect
	}

	public ngOnInit(): void {
		this.record.schedule_recur_ids = this.coreSrvc.dbSrvc.schedulerSrvc.scheduleBatchUpdateList
	}

	public ngAfterViewInit(): void {}

	public ngAfterContentInit(): void {
		this.setupDialogManager()
	}

	public ngOnDestroy(): void {}

	public setupDialogManager() {
		this.dialogManager.submitBtnAction = () => this.submit()
	}

	private setupDropdowns() {
		const ddConfig = new DropdownHelperConfig(this.coreSrvc.dbSrvc, 'DROPDOWN')
		ddConfig.isRestricted = false
		ddConfig.includeAnyEmployee = true
		const ddHelper = new DropdownHelper(ddConfig)

		this.employeeOptions = ddHelper.buildEmployeeMenuOptions()
		this.jobOptions = ddHelper.buildJobMenuOptions()
	}

	public submit() {
		// Guard against double submission
		if (this.isUpdating) return

		// Add any tag that might be in the input field
		this.tagComp?.addTag()

		// Check for tag being edited
		if (this.tagComp?.selectedTag) {
			this.coreSrvc.notifySrvc.notify(
				'info',
				'Action Required',
				'You are curretnly modifying a tag. You must cancel or confirm your changes before you can save this record.',
			)
			return
		}

		const record = this.makeUpdateRecord()
		if (!record) return
		log('Record to submit', record)

		this.isUpdating = true
		const request = new DataAccessRequest(this.resource, 'batch_update', null, record)
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			log('Lambda Result', result)
			this.actionComplete.emit(true)
			this.dialogManager.isDialogVisible = false
		})
	}

	private makeUpdateRecord() {
		const updateRecord = new SchedulerBatchEditRecord(this.record)
		if (updateRecord.start_date) updateRecord.start_date = moment(updateRecord.start_date).format('YYYY-MM-DD') as any
		if (updateRecord.start_time) updateRecord.start_time = moment(updateRecord.start_time).format('HH:mm') as any
		if (updateRecord.recurrence_end) updateRecord.recurrence_end = moment(updateRecord.recurrence_end).format('YYYY-MM-DD') as any
		if (updateRecord.end_time) updateRecord.end_time = moment(updateRecord.end_time).format('HH:mm') as any

		// Default employee_count to 1 if employee_count_change is set and employee_count is not
		if (updateRecord.employee_count_change && !updateRecord.employee_count) {
			updateRecord.employee_count = 1
		}

		// Ensure Any Employee is target if both employee and employee_count are set
		if (updateRecord.employee_count_change && updateRecord.employee_id_change && updateRecord.employee_id !== 0) {
			this.coreSrvc.notifySrvc.notify(
				'info',
				'Invalid Settings',
				'When changing both employee and employee count, you may only select Any Employee as the target employee.',
				8,
			)
			return null
		}

		return updateRecord
	}

	public validateNumericInput(input) {
		const value = this.record['input']
		const intValue = parseInt(value, 10)
		if (intValue) {
			this.record['input'] = intValue
		} else {
			this.record['input'] = null
		}
	}

	defaultStartEndTime(type: 'START' | 'END'): Date {
		// Using moment, return a start date for 9am and an end time for 5pm
		return type === 'START'
			? moment().startOf('day').hour(9).minute(0).second(0).toDate()
			: moment().startOf('day').hour(17).minute(0).second(0).toDate()
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'tags_action':
				help.header = 'Update Action'
				help.message =
					'<b>Add</b> will add the listed tags to each batched schedule while leaving other tags in place.<br /><br /><b>Remove</b> will remove the listed tags from each batched schedule while leaving other tags in place.<br /><br /><b>Replace</b> will replace all tags in each batched schedule with the listed tags.'
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}

class SchedulerBatchEditRecord {
	schedule_recur_ids: Array<number> = []

	enabled = false
	enabled_change = false

	employee_id: number = null
	employee_id_change = false

	employee_count: number = null
	employee_count_change = false

	job_id: number = null
	job_id_change = false

	start_date: Date = null
	start_date_change = false

	start_time: Date = null
	start_time_change = false

	recurrence_end: Date = null
	recurrence_end_change = false

	end_time: Date = null
	end_time_change = false

	tags_json: string
	tags_json_change = false
	tags_action: 'ADD' | 'REMOVE' | 'REPLACE_ALL' = 'ADD'

	constructor(data?: any) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
		this.schedule_recur_ids = [...this.schedule_recur_ids]
	}
}
