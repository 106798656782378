import { Component, EventEmitter, Input, Output } from '@angular/core'
import { DatabaseTableName } from '@app/models'
import { BatchActionManager, BatchActionType } from '@app/models/batch'
import { CoreService } from '@app/services'

@Component({
    selector: 'app-batch-action-banner',
    templateUrl: './batch-action-banner.component.html',
    styleUrl: './batch-action-banner.component.scss',
    standalone: false
})
export class BatchActionBannerComponent {
	@Input() manager: BatchActionManager
	@Input() resource: DatabaseTableName

	@Output() performAction = new EventEmitter<BatchActionType>()

	constructor(private coreSrvc: CoreService) {}

	get recordsSelectedHeader(): string {
		return this.manager.recordsSelectedHeader
	}

	public batchAction(action: BatchActionType) {
		switch (action) {
			case 'EXIT_BATCH':
				this.manager.reset()
				return

			case 'SELECT_ALL':
				this.performAction.emit('SELECT_VISIBLE')
				return

			case 'UNSELECT_ALL':
				this.performAction.emit('UNSELECT_VISIBLE')
				return

			case 'TOGGLE_PREVIEW':
				if (!this.manager.isPreviewMode && this.manager.selectedRecords.length === 0) {
					this.coreSrvc.notifySrvc.notify('info', 'No Records Selected', 'You must selecct at least one record to perform this action.', 6)
				} else {
					this.manager.togglePreview()
					this.performAction.emit('TOGGLE_PREVIEW')
				}
				return

			case 'EDIT':
				if (this.manager.selectedRecords.length === 0) {
					this.coreSrvc.notifySrvc.notify('info', 'No Records Selected', 'You must selecct at least one record to perform this action.', 6)
				} else {
					this.performAction.emit('EDIT')
				}
				return

			case 'SEND_ANNOUNCEMENT':
				if (this.manager.selectedRecords.length > 0) {
					this.performAction.emit('SEND_ANNOUNCEMENT')
				}
				break
		}
	}
}
