import { environment } from '@env/environment'
import { Injectable } from '@angular/core'
import { log } from '@app/helpers/logger'

import AWS from 'aws-sdk'

const adpUserSessionKey = 'adpUserSession'

interface IStoredAdpUserSession {
	username: string
	token: string
	cognitoId: string
	date: string
}

class AdpUserSession {
	username = ''
	token = ''
	cognitoId = ''
	date: Date = null

	constructor(username: string, token: string, cognitoId: string, date: Date) {
		this.username = username
		this.token = token
		this.cognitoId = cognitoId
		this.date = date
	}
}

@Injectable({
	providedIn: 'root',
})
export class UserSessionService {
	adpUserSession: AdpUserSession = null

	constructor() {
		log('Creating UserSessionService')
		this.adpUserSession = this.getAdpSession()
	}

	get isAdpUserLoggedIn(): boolean {
		return !!this.adpUserSession
	}

	setAdpSession(user: string, token: string, cognitoId: string) {
		const adpUserSession = new AdpUserSession(user, token, cognitoId, new Date())
		this.adpUserSession = adpUserSession
		log('Adp User Session', adpUserSession)
		const sessionString = JSON.stringify(adpUserSession)
		localStorage.setItem(adpUserSessionKey, sessionString)
	}

	getAdpSession(): AdpUserSession {
		const sessionString = localStorage.getItem(adpUserSessionKey)
		if (sessionString) {
			const parsedData = JSON.parse(sessionString)
			const user = parsedData.username
			const token = parsedData.token
			const cognitoId = parsedData.cognitoId
			const date = new Date(parsedData.date)
			return new AdpUserSession(user, token, cognitoId, date)
		}
		return null
	}

	clearAdpSession() {
		localStorage.removeItem(adpUserSessionKey)
		this.adpUserSession = null
	}

	clearCognitoCredentials() {
		const credentials: any = AWS?.config?.credentials
		if (credentials && credentials.clearCachedId) {
			credentials.clearCachedId()
		}
	}

	clearCognitoFromLocalStorage() {
		const keysToRemove: string[] = []

		// Get all keys from local storage
		for (let i = 0; i < localStorage.length; i++) {
			const key = localStorage.key(i)

			// Check if the key contains the string "cognito" (case-insensitive)
			if (key && key.toLowerCase().includes('cognito')) {
				keysToRemove.push(key)
			}
		}

		// Remove the keys from local storage
		keysToRemove.forEach((key) => {
			localStorage.removeItem(key)
		})
	}

	authenticateAdpUser(username: string, token: string): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			this.clearCognitoCredentials()
			AWS.config.update({ region: 'us-east-1' })

			const params = {
				RoleArn: environment.adpLoginRole,
				RoleSessionName: username /* required */,
				WebIdentityToken: token /* required */,
			}

			const credentials = new AWS.WebIdentityCredentials(params)
			console.log(credentials)

			// AWS.config.credentials = credentials
			AWS.config.update({ credentials: credentials })

			AWS.config.getCredentials((err) => {
				if (err) {
					log(err, err.stack) // an error occurred
					log('Unable to Obtain AWS IAM Temporary Credentials with Web Token')
					this.adpUserSession = null
					resolve(false)
				} else {
					log('Got credentials')
					const updatedCreds: any = AWS.config.credentials
					log('Updated Creds', updatedCreds)
					const data = updatedCreds.data
					const cognitoId = data.SubjectFromWebIdentityToken
					this.setAdpSession(username, token, cognitoId)
					resolve(true)
				}
			})
		})
	}
}

// https://adp-api.tts-stage.com/oauth2/redirect
