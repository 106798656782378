<div *ngIf="dropdownLabel" class="options-block">
	<div *ngIf="isRequired && selectedItems.length === 0" class="float-right">(required)</div>
	<div *ngIf="showClearBtn && selectedItems.length > 0" class="float-right link-text" (click)="clearBtnClicked()">clear</div>
	<div class="options-title-inline" [style]="dropdownLabelStyle">{{ dropdownLabel }}</div>
	<i *ngIf="showInfoTip" title="More Information" class="far fa-info-circle tts-info-btn" aria-hidden="true" (click)="showHelp.next(true)"></i>
</div>

<p-multiselect
	appScrollLockMultiSelect
	[placeholder]="placeholder"
	[selectedItemsLabel]="selectedItemsLabel"
	[autofocusFilter]="devDetect.isDesktop()"
	[resetFilterOnHide]="true"
	[showToggleAll]="showToggleAll"
	[maxSelectedLabels]="0"
	[filterBy]="filterBy"
	[options]="options"
	[group]="group"
	[(ngModel)]="model"
	(onChange)="updateState()"
	(onSelectAllChange)="updateState()"
	(onPanelHide)="updateState()"
>
	<ng-template let-group pTemplate="group">
		<span class="multiselect-group-label">{{ group.label }}</span>
	</ng-template>
</p-multiselect>

<div *ngIf="selectedItems.length > 0" class="msd-list-block" [class.item-list-box]="selectedItems.length > 3 && !showFullList">
	<div *ngFor="let item of selectedItems; let idx = index">
		<div class="del-btn float-right" (click)="removeItem(item)">X</div>
		<div>
			<div *ngIf="item.type !== 'EMP' && item.type !== 'JOB'" class="msd-item-label">
				<span [class.tag-label]="item.type === 'TAG'">{{ item?.label }}</span>
			</div>
			<div *ngIf="item.type === 'EMP' || item.type === 'JOB'" class="msd-item-label">
				<span>{{ item.label }} </span><span *ngIf="!item.active" class="tts-hl-1">(Inactive)</span>
			</div>
		</div>
	</div>
</div>

<!-- <div>
	<hr />
	<div>Model: {{ model | json }}</div>
</div> -->
