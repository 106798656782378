import { Injectable } from '@angular/core'
import { NotificationProfile } from '@app/models/notification-profile'
import { Subject } from 'rxjs'

import { log } from '@app/helpers'
import _ from 'lodash'
import { Global } from '@app/models/global'

@Injectable({ providedIn: 'root' })
export class NotificationProfileService {
	dataLoaded = false
	list: Array<NotificationProfile> = []
	lastUpdated: Date

	listUpdated = new Subject<boolean>()

	constructor() {
		log('Creating NotificationProfileService')
	}

	clearData() {
		this.dataLoaded = false
		this.list = []
		this.lastUpdated = new Date()
		this.listUpdated.next(true)
	}

	getProfiles(): Array<NotificationProfile> {
		return this.list
	}

	getProfileById(id: number): NotificationProfile {
		return this.list.find((np) => np.id === id)
	}

	getProfileByName(name: string): NotificationProfile {
		return this.list.find((np) => np.name === name)
	}

	setProfileRecords(records: Array<NotificationProfile>) {
		this.lastUpdated = new Date()
		this.list = records.map((rec) => new NotificationProfile(rec))
		this.dataLoaded = true
		this.listUpdated.next(true)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateProfileRecords(records: Array<NotificationProfile>) {
		log('Add or update profile records', records)
		const newRecords = records.map((rec) => new NotificationProfile(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.list.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.list.push(newRecord)
			}
		}
		this.listUpdated.next(true)
	}

	removeLocalprofileRecord(recordId: number) {
		log('Removing Notification Profile For', recordId)
		this.list = this.list.filter((rec) => rec.id !== recordId)
		this.listUpdated.next(true)
	}
}
