import { Component, OnInit, OnDestroy } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

import { AppVersion, GenericEvent, PhoneNumberRecord } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { log, DisplayHelper, PhoneHelper } from '@app/helpers'

import { Subscription } from 'rxjs'
import { Router } from '@angular/router'

import moment from 'moment-timezone'
import { environment } from '@env/environment'

interface CallInNumber {
	number: string
	label: string
}

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: false
})
export class FooterComponent implements OnInit, OnDestroy {
	chatbotAvailable = false
	currentAppVersion = AppVersion.version

	// countryCode = 'US'

	// supportDialog = { isVisible: false }
	supportInfoVisible = false

	callInNumbers: Array<CallInNumber> = []

	phoneNumbers: Array<PhoneNumberRecord> = []

	copyrightYear = moment().format('YYYY')

	private subs = new Subscription()

	constructor(
		private router: Router,
		private coreSrvc: CoreService,
		private sanitize: DomSanitizer,
	) {
		this.subs.add(this.coreSrvc.eventSrvc.genericEvents.subscribe((event) => this.processGenericEvents(event)))
		this.subs.add(this.coreSrvc.dbSrvc.settingSrvc.phoneNumbersUpdatedEvent.subscribe((event) => this.handlePhoneUpdateEvent()))
		this.subs.add(this.coreSrvc.notifySrvc.databaseFinishedLoading.subscribe((event) => this.handleChatbotLinkAvailablity()))
		// this.subs.add(this.coreSrvc.dbSrvc.settingSrvc.companyChanged.subscribe((event) => this.handleChatbotLinkAvailablity()))
	}

	get countryCode(): string {
		return this.coreSrvc.dbSrvc.settingSrvc.getCompany()?.country_iso ?? 'US'
	}

	get logoUrl(): string {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		if (company) {
			return company.logo_url ? company.logo_url : 'https://assets.telephonetimesheets.com/global/tts-logo.png'
		}
		return null
	}

	ngOnInit() {
		this.router.events.subscribe(() => (this.supportInfoVisible = false))
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	processGenericEvents(event: GenericEvent) {
		if (event.key === 'currentAppVersion') this.currentAppVersion = event.data
	}

	companyName(): string {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		if (company && company.name) {
			return company.name
		}
		return ''
	}

	companyId(): string {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		if (company) {
			const isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.getMyUser()?.role === 'INTERNAL'
			if (isInternalUser) {
				return `- (${company.id})`
			}
		}
		return ''
	}

	private handleChatbotLinkAvailablity() {
		const pref = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany()
		const user = this.coreSrvc.dbSrvc.settingSrvc.getMyUser()
		if (pref && user) {
			log('Setting up chatbot availablity: PREF/ROLE', pref?.globalEnableChatbot, user?.role)
			this.chatbotAvailable = user?.role === 'INTERNAL' || (pref?.globalEnableChatbot && user?.role === 'PRIMARY')
		}
	}

	private handlePhoneUpdateEvent() {
		log('HANDLE PHONE NUMBER UPDATE')
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const companyId = company?.id
		const globalId = company?.global_id
		const result = this.coreSrvc.dbSrvc.settingSrvc
			.getCallInPhoneNumberRecords()
			.filter((pn) => pn.company_id === companyId || pn.company_id === globalId)
		const dispatchNumbers = result.filter((pn) => pn.route_application === 'DISPATCH' && pn.company_id === companyId)
		const ttsProdNumber = result.filter((pn) => pn.route_application === 'TTS_PROD')
		const otherNumbers = result.filter((pn) => pn.route_application !== 'TTS_PROD')
		const sortedResult = ttsProdNumber.length > 0 ? [...ttsProdNumber, ...dispatchNumbers] : [...otherNumbers]
		this.callInNumbers = sortedResult.map((pn) => ({
			number: PhoneHelper.formatPhoneFromE164(pn.number_e164),
			label: pn.phoneNumberLabel,
		}))
	}

	// DEPRECATED 20240630 - moved label to getter on phone number record
	// getPhoneNumberLabel(record: PhoneNumberRecord) {
	// 	return this.coreSrvc.dbSrvc.settingSrvc.getPhoneNumberLabel(record)
	// }

	testBtnClicked() {
		this.coreSrvc.dbSrvc.readTable('company')
	}

	showManual() {
		window.open('https://www.manula.com/manuals/telephone-timesheets/telephone-timesheets-user-guide/', '_blank')
	}

	gotoChatbot() {
		this.router.navigate(['/admin/chatbot'])
	}

	reloadApplication() {
		localStorage.setItem('manualUpdateCheck', 'requested')
		this.coreSrvc.dbSrvc.settingSrvc.reloadApplication()
	}

	resetApplication() {
		this.router.navigate(['/reset'])
	}
}
