<div style="margin-top: 20px">
	<!-- No Active Calls -->
	<div *ngIf="list.length === 0" class="options-title clr-darkgold">
		<i *ngIf="!isDataLoaded" class="fas fa-loader fa-spin ab-icon" style="margin-right: 4px"></i>
		{{ isDataLoaded ? 'No Active Calls' : 'Refreshing Active Call List ...' }}
	</div>

	<!-- Active Call List -->
	<div *ngIf="list.length > 0">
		<div *ngFor="let card of list">
			<div class="card ac-card">
				<div>
					<div class="icon-block float-right" [class.icons-disabled]="!card.acr.bridged">
						<i
							*ngIf="card.hasTimeEntryLink"
							class="far fa-clock icon-wrap"
							title="View Time Entry"
							style="margin-right: 4px"
							(click)="viewTimeEntry(card)"
						></i>
						<i *ngIf="!card.acr.muted" class="far fa-microphone icon-wrap clr-green" title="Mute Caller" (click)="toggleMute(card)"></i>
						<i
							*ngIf="card.acr.muted"
							class="far fa-microphone-slash icon-wrap clr-firebrick"
							title="Unmute Caller"
							(click)="toggleMute(card)"
						></i>
						<i class="far fa-trash-alt icon-wrap clr-firebrick" title="Remove Caller" (click)="deleteBtnClicked(card)"></i>
					</div>

					<div class="ac-name tts-hl-2">
						{{ card.acr.display_name }}
						<span *ngIf="card.acr.phone_e164 === myUser.c2c_phone_e164 || card.acr.phone_e164 === myUser.phone_e164" class="ac-me">(Me)</span>
					</div>
					<div class="ac-number">{{ card.acr.phone_e164 | displayPhone }}</div>

					<div>
						<div class="ac-duration float-right">{{ card.callDuration }}</div>
						<div
							class="ac-status"
							[class.clr-green]="card.acr.last_status === 'CONNECTED' || card.acr.last_status === 'CALLING'"
							[class.clr-firebrick]="card.acr.last_status === 'FAILED' || card.acr.last_status === 'NOANSWER'"
							[class.clr-chocolate]="
								card.acr.last_status === 'BUSY' || card.acr.last_status === 'NODIGITS' || card.acr.last_status === 'HANGUP'
							"
						>
							{{ formatStatus(card.acr.last_status) }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
