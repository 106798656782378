import { Injectable, EventEmitter } from '@angular/core'
import { Incident, IncidentLogViewManager } from '@app/models'

import { log } from '@app/helpers'
import _ from 'lodash'
import { SelectItem } from 'primeng/api/selectitem'
import { Global } from '@app/models/global'

@Injectable({
	providedIn: 'root',
})
export class IncidentLogService {
	dataLoaded = false
	list: Array<Incident> = []
	listChange = new EventEmitter<Array<Incident>>()
	lastUpdated: Date

	viewManager = new IncidentLogViewManager()

	constructor() {
		log('Creating IncidentLogService')
	}

	clearData() {
		this.list = []
		this.dataLoaded = false
		this.lastUpdated = null
	}

	getIncidentLogRecords(): Array<Incident> {
		const result = _.sortBy(this.list, 'name')
		return result
	}

	getIncidentLogRecordById(id: number) {
		return this.list.find((rec) => rec.id === id)
	}

	removeLocalIncidentLogRecord(recordId: number) {
		this.list = this.list.filter((rec) => rec.id !== recordId)
	}

	setIncidentLogRecords(records: Array<Incident>) {
		this.lastUpdated = new Date()
		this.list = records.map((rec) => new Incident(rec))
		this.dataLoaded = true
		this.listChange.next(this.list)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateIncidentLogRecords(records: Array<Incident>) {
		const newRecords = records.map((rec) => new Incident(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.list.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.list.push(newRecord)
			}
		}
		this.lastUpdated = new Date()
		this.listChange.next(this.list)
	}
}
