import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { EditFormJobSiteLinkedNumbersManager } from '@app/models'

@Component({
    selector: 'app-job-site-edit-linked-numbers',
    templateUrl: './job-site-edit-linked-numbers.component.html',
    styleUrls: ['./job-site-edit-linked-numbers.component.scss'],
    standalone: false
})
export class JobSiteEditLinkedNumbersComponent implements OnInit {
	@Input() manager: EditFormJobSiteLinkedNumbersManager
	@Output() showHelp = new EventEmitter<string>()

	@ViewChild('newNumber') newNumber: ElementRef

	constructor() {}

	ngOnInit(): void {}

	showHelpEvent(topic: string) {
		this.showHelp.emit(topic)
	}
}
