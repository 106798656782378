import { Component, OnInit, Output, EventEmitter, Input, AfterViewInit } from '@angular/core'
import { CoreService } from '@app/services'
import { DialogManager, GenericEmailListManager, GenericPhoneListManager, HelpDialogMessage } from '@app/models'
import { log } from '@app/helpers'

import { CountryCode } from 'libphonenumber-js'

@Component({
    selector: 'app-billing-contacts-edit',
    templateUrl: './billing-contacts-edit.component.html',
    styleUrls: ['./billing-contacts-edit.component.scss'],
    standalone: false
})
export class BillingContactsEditComponent implements OnInit, AfterViewInit {
	isUpdating = false

	emailListManager: GenericEmailListManager
	phoneListManager: GenericPhoneListManager

	@Input() dialogManager = new DialogManager()
	@Output() recordUpdated = new EventEmitter<boolean>()

	constructor(private coreSrvc: CoreService) {
		this.setupEmailListManager()
		this.setupPhoneListManager()
	}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.dialogManager.submitBtnAction = () => this.submit()
			this.dialogManager.canSubmit = () => this.isBillingEmailValid()
		}, 100)
	}

	setupEmailListManager() {
		const manager = new GenericEmailListManager(this.coreSrvc)
		const emailList = this.coreSrvc.dbSrvc.settingSrvc.getCompany().billing_emails
		manager.initWithString(emailList)
		this.emailListManager = manager
	}

	setupPhoneListManager() {
		const manager = new GenericPhoneListManager(this.coreSrvc)
		const phoneList = this.coreSrvc.dbSrvc.settingSrvc.getCompany().billing_phones
		manager.dialingCode = this.coreSrvc.dbSrvc.settingSrvc.getCompany().country_iso as CountryCode
		manager.initWithString(phoneList)
		this.phoneListManager = manager
	}

	isBillingEmailValid(): boolean {
		return true
	}

	submit() {
		log('Submitting Changes')
		if (!this.emailListManager.addEmail()) return
		if (!this.phoneListManager.addPhone()) return

		if (this.emailListManager.emailList.length === 0) {
			this.coreSrvc.notifySrvc.notify('error', 'Email Contact Required', 'You must have at least one billing contact email address.')
			return
		}

		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const record = company.getUpdateRecord()
		record.billing_emails = this.emailListManager.emailListString
		record.billing_phones = this.phoneListManager.phoneListString
		log('Record to submit', record)
		this.isUpdating = true
		this.coreSrvc.dbSrvc.updateRecord('company', record).then((success) => {
			if (success) {
				this.recordUpdated.emit(true)
				this.dialogManager.isDialogVisible = false
			} else {
				this.isUpdating = false
			}
		})
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'billingEmail':
				help.header = 'Billing Contacts'
				help.message =
					'By default, the monthly billing statement will be sent to the email address that was entered during sign up. That value can be changed here. You must have at least one billing contact email address.'
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = 'No help information for this topic is currently available.'
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
