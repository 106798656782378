<div *ngIf="linkedNumbers.length > 0" style="margin-bottom: 25px">
	<strong style="color: chocolate">Landline Numbers</strong>
	<br>
	<ul>
		<li *ngFor="let number of linkedNumbers">{{ number }}</li>
	</ul>
</div>

<div *ngIf="linkedEmployees.length > 0">
	<strong style="color: chocolate">Employees</strong>
	<br>
	<ul>
		<li *ngFor="let employee of linkedEmployees">
			<div><strong>{{ employee.name }}</strong></div>
			<div style="color: #666; margin-top: -5px;">
				<small>{{ employee.phone }}</small>
			</div>
		</li>
	</ul>
</div>
