import { Component, OnInit } from '@angular/core'

import { DatabaseService } from '@app/services'

import { log } from '@app/helpers'

@Component({
    selector: 'app-exceptions',
    templateUrl: './exceptions.component.html',
    styleUrls: ['./exceptions.component.scss'],
    standalone: false
})
export class ExceptionsComponent implements OnInit {
	showInfoBox = false

	constructor(private dbSrvc: DatabaseService) {}

	ngOnInit() {
		this.loadData()
	}

	loadData() {
		this.dbSrvc.bulkRead(['employee', 'location', 'job', 'location_exception'])
	}

	get locationCount(): number {
		return this.dbSrvc.excptSrvc.locationCount()
	}
}
