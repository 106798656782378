import { DateTimeHelper, log } from '@app/helpers'
import { SelectItem } from 'primeng/api'
import { Global } from './global'
import { CompanyAccountStatus } from './company'

import _ from 'lodash'
import { EditFormAction } from './form'
import { ChartType } from 'chart.js'

export class CompanyActivityLogRecord {
	id: number = null
	company_id: number = null

	name: string = null
	account_status: CompanyAccountStatus = null
	ts: string = null // ISO Date - Record created
	created: string = null // ISO Date - Company created
	last_portal_access: string = null // ISO Date
	last_transaction: string = null // ISO Date

	////////////
	// PEOPLE //
	////////////

	employee_count = 0
	employee_active_count = 0
	employee_inactive_count = 0
	employee_has_address_count = 0
	employee_has_email_count = 0
	employee_has_tags_count = 0
	employee_has_commute_distance_count = 0
	employee_has_pay_rate_count = 0
	employee_has_file_upload_count = 0
	employee_has_disable_sms_count = 0

	user_count = 0
	user_has_email_count = 0

	contact_count = 0
	contact_has_phone_count = 0
	contact_has_email_count = 0
	contact_has_contact_method_count = 0
	contact_has_notify_count = 0

	organization_count = 0
	organization_has_email_count = 0
	organization_has_phone_count = 0
	organization_has_contact_method_count = 0
	organization_has_notify_count = 0

	//////////
	// WORK //
	//////////

	location_count = 0
	location_has_address_count = 0
	location_has_linked_landline_count = 0

	job_count = 0
	job_active_count = 0
	job_inactive_count = 0
	job_has_tags_count = 0
	job_has_linked_tour_count = 0
	job_has_linked_np_count = 0
	job_has_linked_schedule_count = 0
	job_has_client_count = 0
	job_has_vendor_count = 0
	job_has_emp_pay_rate_count = 0
	job_has_client_pay_rate_count = 0
	job_has_vendor_pay_rate_count = 0

	tour_count = 0
	tour_type_unstructured_count = 0
	tour_type_hourly_count = 0
	tour_type_daily_count = 0
	tour_type_shift_timed_count = 0
	tour_has_linked_job_count = 0

	np_count = 0
	np_has_linked_job_count = 0

	////////////////
	// OPERATIONS //
	////////////////

	schedule_count = 0

	transaction_count = 0
	transaction_count_30 = 0
	transaction_count_daily = 0
	trans_noshow_count_daily = 0
	trans_missing_checkout_count_daily = 0

	trans_created_by_landline_count_daily = 0 // LANDLINE
	trans_created_by_cell_phone_count_daily = 0 // MOBILE
	trans_created_by_station_kiosk_count_daily = 0 // KIOSK
	trans_created_by_station_mobile_count_daily = 0 // MOBILESTATION
	trans_created_by_station_count_daily = 0 // STATION
	trans_created_by_emp_app_count_daily = 0 // WEB
	trans_created_by_admin_count_daily = 0 // ADMIN

	trans_closed_by_landline_count_daily = 0 // LANDLINE
	trans_closed_by_cell_phone_count_daily = 0 // MOBILE
	trans_closed_by_station_kiosk_count_daily = 0 // KIOSK
	trans_closed_by_station_mobile_count_daily = 0 // MOBILESTATION
	trans_closed_by_station_count_daily = 0 // STATION
	trans_closed_by_emp_app_count_daily = 0 // WEB
	trans_closed_by_admin_count_daily = 0 // ADMIN

	trans_gps_blocked_inorout_count_daily = 0
	trans_gps_blocked_checkin_count_daily = 0
	trans_gps_blocked_checkout_count_daily = 0

	trans_notified_emp_count_daily = 0
	trans_notified_emp_late_checkin_count_daily = 0
	trans_notified_emp_late_checkpoint_daily = 0
	trans_notified_emp_late_checkout_count_daily = 0

	trans_notified_sup_count_daily = 0
	trans_notified_sup_late_checkin_count_daily = 0
	trans_notified_sup_late_checkpoint_daily = 0
	trans_notified_sup_late_checkout_count_daily = 0

	incident_log_count_daily = 0
	incident_log_type_shift_count_daily = 0
	incident_log_type_checkin_count_daily = 0
	incident_log_type_post_break_count_daily = 0
	incident_log_type_checkout_count_daily = 0
	incident_log_type_homecare_count_daily = 0
	incident_log_type_vehicle_inspection_count_daily = 0
	incident_log_type_incident_count_daily = 0
	incident_log_type_custom_count_daily = 0

	c2c_log_type_call_count_daily = 0
	c2c_log_type_text_count_daily = 0

	audit_log_op_insert_count_daily = 0
	audit_log_op_update_count_daily = 0
	audit_log_op_delete_count_daily = 0

	/**
	 * Create a new CompanyActivityLogRecord, optionally copying from the given record
	 * @param {Object} [record] - An object containing the attributes to copy
	 */
	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr) && record[attr] !== null) {
					this[attr] = record[attr]
				}
			}
			this.ts = DateTimeHelper.stripUtcTag(this.ts)
			this.created = DateTimeHelper.stripUtcTag(this.created)
		}
	}
}

export class DashboardRecord {
	id: number
	company_id: number

	created: string // ISO datatime
	updated: string // ISO datetime

	name: string
	data_json: string

	creator_user_id: number // creator of dashboard - managed by backend
	supervisor: number = 0 // current dashboard owner - default to Any Supervisor
	supervisor_group_id: number // shared with this supervisor group
	shared_supervisor_ids: Array<number> = [] // shared with these supervisors

	supervisor_ids: Array<number> = [] // property computed by back end from sharing options

	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
		}
	}
}

export type ChartsGraphType = 'bar' | 'line' | 'scatter' | 'bubble' | 'pie' | 'doughnut' | 'polarArea' | 'radar' | undefined
export interface ChartsConfigOptions {
	plugins?: {
		legend: {
			display: boolean
			position: 'top' | 'left' | 'right' | 'bottom' // Can be extended if necessary
		}
		autocolors: {
			mode: 'dataset' | 'data' // Assuming these are the valid modes; add more if needed
			enabled: boolean
			offset: number
			repeat: number
		}
	}
	scales?: {
		x: {
			stacked: boolean
		}
		y: {
			stacked: boolean
			ticks: {
				precision: number
				// callback: (value) => number
			}
		}
	}
}
export type ChartsDataset = {
	labels: string[] // Date labels (daily or monthly)
	datasets: {
		label: string
		data: number[]
		hidden?: boolean
		backgroundColor?: string
		borderColor?: string
		borderWidth?: number
	}[]
}

export type CardDataset = {
	sum: number
	average: number
}

export interface DashboardEditRecordUpdateEvent {
	action: EditFormAction
	recordId: number
}

export type VizCardType = 'COUNTER' | 'GRAPH'
export type VizCardMoveDirection = 'LEFT' | 'RIGHT' | 'UP' | 'DOWN'
export type VizCardAction = 'MOVE_CARD' | 'ADD_CARD' | 'REMOVE_CARD' | 'ADD_ROW' | 'REMOVE_ROW' | 'CONFIGURE_GRAPH'

export class VizCardActionEvent {
	action: VizCardAction
	cardType: VizCardType
	direction: VizCardMoveDirection
	rowIndex: number
	cardIndex: number
	data: VizCardCounterDataSource | VizCardGraphDataSource
	constructor(
		action: VizCardAction,
		cardType: VizCardType,
		direction: VizCardMoveDirection,
		rowIndex: number,
		cardIndex: number,
		data: VizCardCounterDataSource | VizCardGraphDataSource,
	) {
		this.action = action
		this.cardType = cardType
		this.direction = direction
		this.rowIndex = rowIndex
		this.cardIndex = cardIndex
		this.data = data
	}
}

export type VizDashboardConfirmAction =
	| 'ADD_DASHBOARD' // Adds a new dashboard
	| 'EDIT_DASHBOARD' // Edits the current dashboard
	| 'CLONE_DASHBOARD' // Clones the current dashboard
	| 'RENAME_DASHBOARD' // Renames existing dashboard (needs save)
	| 'DELETE_DASHBOARD' // Deletes the current dashboard
	| 'SAVE_DASHBOARD' // Saves the current dashboard
	| 'RESET_DASHBOARD' // Resets current dashboard to defaults
	| 'RESTORE_DASHBOARD' // Restores current dashboard from last save
	| 'ADD_DATASOURCE' // Adds a row of data sources
	| 'DELETE_DATASOURCES' // Deletes a row of data sources
	| 'CLOSE_MENU' // Closes the context menu (used for mobile)

export type VizDashboardFetchRangeType =
	| 'LAST_7_DAYS'
	| 'LAST_30_DAYS'
	| 'YEAR_TO_DATE'
	| 'ALL_TIME'
	| 'QUARTER_1'
	| 'QUARTER_2'
	| 'QUARTER_3'
	| 'QUARTER_4'
	| 'CUSTOM'

export class VizDashboardDataSourceRow {
	type: 'COUNTER' | 'GRAPH'
	cardData: Array<VizCardCounterDataSource> = []
	graphData: Array<VizCardGraphDataSource> = []

	constructor(type: 'COUNTER' | 'GRAPH', data: Array<VizCardCounterDataSource> | Array<VizCardGraphDataSource> = []) {
		this.type = type
		if (type === 'COUNTER') {
			// When creating cards, we only use the source type as we do not allow customizing other aspects of the card
			this.cardData = (data as Array<VizCardCounterDataSource>).map((d) => new VizCardCounterDataSource(d.source))
		} else {
			this.graphData = (data as Array<VizCardGraphDataSource>).map((d) => new VizCardGraphDataSource(d))
		}
	}

	getRowData(): Array<VizCardCounterDataSource> | Array<VizCardGraphDataSource> {
		return this.type === 'COUNTER' ? this.cardData : this.graphData
	}
}

// This class is used to prepare counter card data for storage. It prunes the unnecessary properties
export class VizCardCounterPrunedData {
	source: VizCardCounterDataSourceType
	title: string

	constructor(data: VizCardCounterDataSource) {
		this.source = data.source
		this.title = data.title
	}
}

// This class is used to prepare graph card data for storage. It prunes the unnecessary properties
export class VizCardGraphPrunedData {
	source: VizCardGraphDataSourceType
	title: string
	type: ChartsGraphType
	options: ChartsConfigOptions

	constructor(data: VizCardGraphDataSource) {
		this.source = data.source
		this.title = data.title
		this.type = data.type
		this.options = data.options
	}
}

export class VizDashboard {
	name: string = 'Default Dashboard'
	data: Array<VizDashboardDataSourceRow> = []

	record: DashboardRecord

	constructor(record?: DashboardRecord) {
		// Dashboard records
		if (record) {
			this.record = record
			this.name = record.name
			this.data = (record.data_json ? JSON.parse(record.data_json) : []).map((d) => new VizDashboardDataSourceRow(d.type, d.data))
		}
	}

	public isMyDashboard(): boolean {
		const myUserId = Global.coreSrvc.dbSrvc.settingSrvc.getMyUserId()
		if (!this.record.id || !this.record.supervisor) return true
		return this.record.supervisor === myUserId || this.record.creator_user_id === myUserId
	}

	public buildUpdateRecord(): DashboardRecord {
		const updateRecord = new DashboardRecord(this.record)
		updateRecord.name = this.name

		// Make duplicate of the stored data as it needs to be pruned before submitting
		const preSaveData = JSON.parse(JSON.stringify(this.data))

		// Iterate over the data and prune out anything that is not needed such as graphing data points
		for (const row of preSaveData as Array<VizDashboardDataSourceRow>) {
			if (row.type === 'COUNTER') {
				// Prune out everything except the source which is needed to setup a new card
				const prunedCardData = row.cardData.map((d) => new VizCardCounterPrunedData(d) as VizCardCounterDataSource)
				row.cardData = prunedCardData
			}
			if (row.type === 'GRAPH') {
				const prundedGraphData = row.graphData.map((d) => new VizCardGraphPrunedData(d) as VizCardGraphDataSource)
				row.graphData = prundedGraphData
			}
		}

		// Map to database storage format: { type: 'COUNTER' | 'GRAPH', data: <DATA> } objects
		const mappedData = preSaveData.map((d) => ({ type: d.type, data: d.type === 'COUNTER' ? d.cardData : d.graphData }))
		updateRecord.data_json = JSON.stringify(mappedData)

		return updateRecord
	}

	public setupDefaultDisplayData() {
		// Setup storage for row of four counter cards
		const cardRow = VizDashboard.buildDefaultCounterCardRow()

		// Setup storage for first graph row and trigger data updates
		const graphRowOne = VizDashboard.buildDefaultGraphCardRowOne()

		// Setup storage for second graph row and trigger data update
		const graphRowTwo = VizDashboard.buildDefaultGraphCardRowTwo()

		// Setup storage for second graph row and trigger data update
		const graphRowThree = VizDashboard.buildDefaultGraphCardRowThree()

		// Update the view manager displaData property
		this.data = [cardRow, graphRowOne, graphRowTwo, graphRowThree]
	}

	static buildDefaultCounterCardRow(): VizDashboardDataSourceRow {
		const empCard = new VizCardCounterDataSource('EMPLOYEES_ALL')
		const jobCard = new VizCardCounterDataSource('JOBS_ALL')
		const schedCard = new VizCardCounterDataSource('SCHEDULES_ALL')
		const transCard = new VizCardCounterDataSource('TRANS_ALL')

		// Create the row view model for the card row
		return new VizDashboardDataSourceRow('COUNTER', [empCard, jobCard, schedCard, transCard])
	}

	static buildDefaultGraphCardRowOne() {
		const transNoShowGraphDataSource = new VizCardGraphDataSource({ source: 'TRANS_NO_SHOW', type: 'bar' })
		const transMissingGraphDataSource = new VizCardGraphDataSource({ source: 'TRANS_MISSING', type: 'bar' })

		// Create the row view model for the first graph row
		return new VizDashboardDataSourceRow('GRAPH', [transNoShowGraphDataSource, transMissingGraphDataSource])
	}

	static buildDefaultGraphCardRowTwo() {
		const empGraphDataSource = new VizCardGraphDataSource({ source: 'TRANS_NOTIFIED_EMP', type: 'bar' })
		const jobGraphDataSource = new VizCardGraphDataSource({ source: 'TRANS_NOTIFIED_SUP', type: 'bar' })

		// Create the row view model for the first graph row
		return new VizDashboardDataSourceRow('GRAPH', [empGraphDataSource, jobGraphDataSource])
	}

	static buildDefaultGraphCardRowThree() {
		const gpsBlockedGraphDataSource = new VizCardGraphDataSource({ source: 'TRANS_GPS_BLOCKED_ALL', type: 'bar' })
		const createdByDataSource = new VizCardGraphDataSource({ source: 'TRANS_CREATED_ALL', type: 'bar' })

		// Create the row view model for the first graph row
		return new VizDashboardDataSourceRow('GRAPH', [gpsBlockedGraphDataSource, createdByDataSource])
	}
}

export type VisualizationViewTabState = 'HEALTH_CENTER' | 'DASHBOARDS'

export class VisualizationViewManager {
	version = 1
	startDate: Date = null
	endDate: Date = null
	currentView: VisualizationViewTabState = 'DASHBOARDS'

	contribFilterActive = false // Limit to contributors only when filter is active, otherwise show both contributors and non-contributors

	currentDashboardId: number = null
	rangeSelected: VizDashboardFetchRangeType = 'LAST_30_DAYS'

	save() {
		localStorage.setItem('VisualizationViewState', JSON.stringify(this))
	}

	restore() {
		const saveData = localStorage.getItem('VisualizationViewState')
		if (saveData) {
			const savedData = JSON.parse(saveData)
			this.version = savedData.version ?? 1
			this.startDate = new Date(savedData.startDate)
			this.endDate = new Date(savedData.endDate)
			this.currentView = savedData.currentView ?? 'HEALTH_CENTER'
			this.rangeSelected = savedData.rangeSelected
			this.currentDashboardId = savedData.currentDashboardId
		} else {
			const range = VizDashboardRangePicker.getRange(this.rangeSelected)
			this.startDate = range.startDate
			this.endDate = range.endDate
		}
	}
}

export class VizDashboardRangePicker {
	static dropdownOptions: Array<SelectItem> = [
		{ label: 'Last 7 Days', value: 'LAST_7_DAYS' },
		{ label: 'Last 30 Days', value: 'LAST_30_DAYS' },
		{ label: 'Year to Date', value: 'YEAR_TO_DATE' },
		{ label: 'All Time', value: 'ALL_TIME' },
		{ label: '1st Quarter', value: 'QUARTER_1' },
		{ label: '2nd Quarter', value: 'QUARTER_2' },
		{ label: '3rd Quarter', value: 'QUARTER_3' },
		{ label: '4th Quarter', value: 'QUARTER_4' },
		{ label: 'Custom Range', value: 'CUSTOM' },
	]

	static getRange(range: VizDashboardFetchRangeType, customStartDate?: Date, customEndDate?: Date): { startDate: Date; endDate: Date } {
		const currentDate = new Date()
		let startDate: Date
		let endDate: Date = currentDate

		switch (range) {
			case 'LAST_7_DAYS':
				startDate = new Date(currentDate)
				startDate.setDate(currentDate.getDate() - 7)
				break

			case 'LAST_30_DAYS':
				startDate = new Date(currentDate)
				startDate.setDate(currentDate.getDate() - 30)
				break

			case 'YEAR_TO_DATE':
				startDate = new Date(currentDate.getFullYear(), 0, 1) // Jan 1st of the current year
				break

			case 'ALL_TIME':
				const companyCreated = Global.coreSrvc.dbSrvc.settingSrvc.getCompany().created
				startDate = new Date(companyCreated)
				endDate = new Date(new Date().getFullYear(), 11, 31)
				break

			case 'QUARTER_1':
				startDate = new Date(currentDate.getFullYear(), 0, 1) // Jan 1st
				endDate = new Date(currentDate.getFullYear(), 2, 31) // Mar 31st
				break

			case 'QUARTER_2':
				startDate = new Date(currentDate.getFullYear(), 3, 1) // Apr 1st
				endDate = new Date(currentDate.getFullYear(), 5, 30) // Jun 30th
				break

			case 'QUARTER_3':
				startDate = new Date(currentDate.getFullYear(), 6, 1) // Jul 1st
				endDate = new Date(currentDate.getFullYear(), 8, 30) // Sep 30th
				break

			case 'QUARTER_4':
				startDate = new Date(currentDate.getFullYear(), 9, 1) // Oct 1st
				endDate = new Date(currentDate.getFullYear(), 11, 31) // Dec 31st
				break

			case 'CUSTOM':
				if (customStartDate && customEndDate) {
					startDate = customStartDate
					endDate = customEndDate
				} else {
					throw new Error("For 'CUSTOM' range, both customStartDate and customEndDate must be provided.")
				}
				break

			default:
				throw new Error('Invalid range type')
		}

		return { startDate: startDate, endDate: endDate }
	}
}

export type VizCardDataSourceComputeMethod = 'SUM' | 'AVERAGE'

export type VizCardCounterDataSourceType =
	| 'EMPLOYEES_ALL'
	| 'JOBS_ALL'
	| 'SCHEDULES_ALL'
	| 'TOURS_ALL'
	| 'TRANS_ALL'
	| 'TRANS_GPS_BLOCKED_ANY'
	| 'TRANS_MISSING'
	| 'TRANS_NO_SHOW'
	| 'TRANS_NOTIFIED_EMP'
	| 'TRANS_NOTIFIED_SUP'
	| 'SHIFT_REPORT_ALL'
	| 'SHIFT_REPORT_BASIC'
	| 'SHIFT_REPORT_CHECKIN'
	| 'SHIFT_REPORT_POST_BREAK'
	| 'SHIFT_REPORT_CHECKOUT'
	| 'SHIFT_REPORT_HOMECARE'
	| 'SHIFT_REPORT_VEHICLE'
	| 'SHIFT_REPORT_INCIDENT'
	| 'SHIFT_REPORT_CUSTOM'

export type VizCardGraphDataSourceType =
	| 'EMPLOYEES_ALL'
	| 'EMPLOYEES_ACTIVE'
	| 'EMPLOYEES_INACTIVE'
	| 'SUPERVISORS_ALL'
	| 'INACTIVE_EMPLOYEES'
	| 'JOBS_ALL'
	| 'JOBS_ACTIVE'
	| 'JOBS_INACTIVE'
	| 'JOBS_SCHEDULES'
	| 'TOURS_ALL'
	| 'TOURS_UNSTRUCTURED'
	| 'TOURS_HOURLY'
	| 'TOURS_DAILY'
	| 'TOURS_SHIFT'
	| 'TOURS_'
	// | 'SCHEDULES'
	| 'SCHEDULES_ALL'
	| 'ACTIVE_SCHEDULES'
	| 'INACTIVE_SCHEDULES'
	| 'TRANS_ALL'
	| 'TRANS_NO_SHOW'
	| 'TRANS_MISSING'
	| 'TRANS_CREATED_ALL'
	| 'TRANS_CREATED_LANDLINE'
	| 'TRANS_CREATED_MOBILE'
	| 'TRANS_CREATED_KIOSK'
	| 'TRANS_CREATED_MOBILESTATION'
	| 'TRANS_CREATED_STATION'
	| 'TRANS_CREATED_WEB'
	| 'TRANS_CREATED_ADMIN'
	| 'TRANS_CLOSED_ALL'
	| 'TRANS_CLOSED_LANDLINE'
	| 'TRANS_CLOSED_MOBILE'
	| 'TRANS_CLOSED_KIOSK'
	| 'TRANS_CLOSED_MOBILESTATION'
	| 'TRANS_CLOSED_STATION'
	| 'TRANS_CLOSED_WEB'
	| 'TRANS_CLOSED_ADMIN'
	| 'TRANS_GPS_BLOCKED_ALL'
	| 'TRANS_GPS_BLOCKED_IN'
	| 'TRANS_GPS_BLOCKED_OUT'
	| 'TRANS_NOTIFIED_ALL'
	| 'TRANS_NOTIFIED_EMP'
	| 'TRANS_NOTIFIED_EMP_LATE_CHECKIN'
	| 'TRANS_NOTIFIED_EMP_LATE_CHECKPOINT'
	| 'TRANS_NOTIFIED_EMP_LATE_CHECKOUT'
	| 'TRANS_NOTIFIED_SUP'
	| 'TRANS_NOTIFIED_SUP_LATE_CHECKIN'
	| 'TRANS_NOTIFIED_SUP_LATE_CHECKPOINT'
	| 'TRANS_NOTIFIED_SUP_LATE_CHECKOUT'
	| 'SHIFT_REPORT_ALL'
	| 'SHIFT_REPORT_BASIC'
	| 'SHIFT_REPORT_CHECKIN'
	| 'SHIFT_REPORT_POST_BREAK'
	| 'SHIFT_REPORT_CHECKOUT'
	| 'SHIFT_REPORT_HOMECARE'
	| 'SHIFT_REPORT_VEHICLE'
	| 'SHIFT_REPORT_INCIDENT'
	| 'SHIFT_REPORT_CUSTOM'
	| 'COMM_LOG_CALLS_TEXTS'
	| 'COMM_LOG_CALLS'
	| 'COMM_LOG_TEXTS'
	| 'AUDIT_LOG_ALL'

// Data source types for count / graph cards

export interface IVizCounterData {
	source: VizCardCounterDataSourceType
	title: string
	subTitle: string
	icon: string
}

export interface IVizGraphData {
	source: VizCardGraphDataSourceType
	title: string
	icon: string
	defaultChartType: ChartsGraphType
	charTypes: Array<ChartsGraphType>
}

export class VizDashboardHelper {
	static counters: Array<IVizCounterData> = [
		// People > Employees
		{
			source: 'EMPLOYEES_ALL',
			title: 'Active Employees',
			subTitle: 'Inactive employees',
			icon: 'far fa-user',
		},

		// Work > Jobs
		{
			source: 'JOBS_ALL',
			title: 'Active Jobs',
			subTitle: 'Inactive jobs',
			icon: 'far fa-tasks-alt',
		},

		// Operations ? Scheduling
		{
			source: 'SCHEDULES_ALL',
			title: 'Active Schedules',
			subTitle: 'Inactive schedules',
			icon: 'far fa-calendar',
		},

		// Operations > Time Entries
		{
			source: 'TRANS_ALL',
			title: 'Time Entries',
			subTitle: 'Average daily count',
			icon: 'far fa-clock',
		},
		{
			source: 'TRANS_MISSING',
			title: 'Missing Clock-Outs',
			subTitle: 'Average daily count',
			icon: 'far fa-clock',
		},
		{
			source: 'TRANS_NO_SHOW',
			title: 'No Shows',
			subTitle: 'Average daily count',
			icon: 'far fa-clock',
		},
		{
			source: 'TRANS_GPS_BLOCKED_ANY',
			title: 'GPS Blocked',
			subTitle: 'Average daily count',
			icon: 'far fa-clock',
		},
		{
			source: 'TRANS_NOTIFIED_EMP',
			title: 'Employee Notifications',
			subTitle: 'Average daily count',
			icon: 'far fa-clock',
		},
		{
			source: 'TRANS_NOTIFIED_SUP',
			title: 'Supervisor Notifications',
			subTitle: 'Average daily count',
			icon: 'far fa-clock',
		},

		// Operations > Employee Shift Reports
		{
			source: 'SHIFT_REPORT_ALL',
			title: 'Shift Reports',
			subTitle: 'Average daily count',
			icon: 'far fa-pie-chart',
		},
		{
			source: 'SHIFT_REPORT_BASIC',
			title: 'Basic Reports',
			subTitle: 'Average daily count',
			icon: 'far fa-pie-chart',
		},
		{
			source: 'SHIFT_REPORT_CHECKIN',
			title: 'Clock-In Reports',
			subTitle: 'Average daily count',
			icon: 'far fa-pie-chart',
		},
		{
			source: 'SHIFT_REPORT_POST_BREAK',
			title: 'Post-Break Reports',
			subTitle: 'Average daily count',
			icon: 'far fa-pie-chart',
		},
		{
			source: 'SHIFT_REPORT_CHECKOUT',
			title: 'Clock-Out Reports',
			subTitle: 'Average daily count',
			icon: 'far fa-pie-chart',
		},
		{
			source: 'SHIFT_REPORT_HOMECARE',
			title: 'Homecare Reports',
			subTitle: 'Average daily count',
			icon: 'far fa-pie-chart',
		},
		{
			source: 'SHIFT_REPORT_VEHICLE',
			title: 'Vehicle Reports',
			subTitle: 'Average daily count',
			icon: 'far fa-pie-chart',
		},
		{
			source: 'SHIFT_REPORT_INCIDENT',
			title: 'Incident Reports',
			subTitle: 'Average daily count',
			icon: 'far fa-pie-chart',
		},
		{
			source: 'SHIFT_REPORT_CUSTOM',
			title: 'Custom Reports',
			subTitle: 'Average daily count',
			icon: 'far fa-pie-chart',
		},
	]

	static graphs: Array<IVizGraphData> = [
		// People > Employees
		{ source: 'EMPLOYEES_ALL', title: 'Employees', icon: 'far fa-user', defaultChartType: 'bar', charTypes: ['bar', 'line'] },
		{ source: 'EMPLOYEES_ACTIVE', title: 'Active Employees', icon: 'far fa-user', defaultChartType: 'bar', charTypes: ['bar', 'line'] },
		{ source: 'EMPLOYEES_INACTIVE', title: 'Inactive Employees', icon: 'far fa-user', defaultChartType: 'bar', charTypes: ['bar', 'line'] },

		// People > Supervisors
		{ source: 'SUPERVISORS_ALL', title: 'Supervisors', icon: 'far fa-user', defaultChartType: 'bar', charTypes: ['bar', 'line'] },

		// Work > Jobs
		{ source: 'JOBS_ALL', title: 'Jobs', icon: 'far fa-tasks-alt', defaultChartType: 'bar', charTypes: ['bar', 'line'] },
		{ source: 'JOBS_ACTIVE', title: 'Active Jobs', icon: 'far fa-tasks-alt', defaultChartType: 'bar', charTypes: ['bar', 'line'] },
		{ source: 'JOBS_INACTIVE', title: 'Inactive Jobs', icon: 'far fa-tasks-alt', defaultChartType: 'bar', charTypes: ['bar', 'line'] },

		// Work > Tours
		{ source: 'TOURS_ALL', title: 'Tours', icon: 'far fa-tasks-alt', defaultChartType: 'bar', charTypes: ['bar', 'line'] },
		{ source: 'TOURS_UNSTRUCTURED', title: 'Basic Tours', icon: 'far fa-tasks-alt', defaultChartType: 'line', charTypes: ['bar', 'line'] },
		{ source: 'TOURS_HOURLY', title: 'Hourly Tours', icon: 'far fa-tasks-alt', defaultChartType: 'line', charTypes: ['bar', 'line'] },
		{ source: 'TOURS_DAILY', title: 'Daily Tours', icon: 'far fa-tasks-alt', defaultChartType: 'line', charTypes: ['bar', 'line'] },
		{ source: 'TOURS_SHIFT', title: 'Shift-timed Tours', icon: 'far fa-tasks-alt', defaultChartType: 'line', charTypes: ['bar', 'line'] },

		// Scheduling > Schedules
		// { source: 'SCHEDULES', title: 'Schedules', icon: 'far fa-calendar', defaultChartType: 'line', charTypes: ['bar', 'line'] },
		{ source: 'SCHEDULES_ALL', title: 'Schedules', icon: 'far fa-calendar', defaultChartType: 'line', charTypes: ['bar', 'line'] },

		// Operations > Time Entries
		{ source: 'TRANS_ALL', title: 'Time Entries', icon: 'far fa-clock', defaultChartType: 'line', charTypes: ['bar', 'line'] },
		{ source: 'TRANS_NO_SHOW', title: 'No Shows', icon: 'far fa-clock', defaultChartType: 'line', charTypes: ['bar', 'line'] },
		{ source: 'TRANS_MISSING', title: 'Missing Clock-Outs', icon: 'far fa-clock', defaultChartType: 'line', charTypes: ['bar', 'line'] },

		// Operations > Time Entries > Source > CLock-In
		{ source: 'TRANS_CREATED_ALL', title: 'Clock-In Source', icon: 'far fa-clock', defaultChartType: 'line', charTypes: ['bar', 'line'] },
		{ source: 'TRANS_CREATED_LANDLINE', title: 'Landline Clock-In', icon: 'far fa-clock', defaultChartType: 'line', charTypes: ['bar', 'line'] },
		{ source: 'TRANS_CREATED_MOBILE', title: 'Mobile Clock-In', icon: 'far fa-clock', defaultChartType: 'line', charTypes: ['bar', 'line'] },
		{ source: 'TRANS_CREATED_KIOSK', title: 'Kiosk Clock-In', icon: 'far fa-clock', defaultChartType: 'line', charTypes: ['bar', 'line'] },
		{
			source: 'TRANS_CREATED_MOBILESTATION',
			title: 'Mobile Station Clock-In',
			icon: 'far fa-clock',
			defaultChartType: 'line',
			charTypes: ['bar', 'line'],
		},
		{ source: 'TRANS_CREATED_STATION', title: 'Station Clock-In', icon: 'far fa-clock', defaultChartType: 'line', charTypes: ['bar', 'line'] },
		{ source: 'TRANS_CREATED_WEB', title: 'Emp App Clock-In', icon: 'far fa-clock', defaultChartType: 'line', charTypes: ['bar', 'line'] },
		{ source: 'TRANS_CREATED_ADMIN', title: 'Admin Clock-In', icon: 'far fa-clock', defaultChartType: 'line', charTypes: ['bar', 'line'] },

		// Operations > Time Entries > Source > Clock-Out
		{ source: 'TRANS_CLOSED_ALL', title: 'Clock-Out Source', icon: 'far fa-clock', defaultChartType: 'line', charTypes: ['bar', 'line'] },
		{ source: 'TRANS_CLOSED_LANDLINE', title: 'Landline Clock-Out', icon: 'far fa-clock', defaultChartType: 'line', charTypes: ['bar', 'line'] },
		{ source: 'TRANS_CLOSED_MOBILE', title: 'Mobile Clock-Out', icon: 'far fa-clock', defaultChartType: 'line', charTypes: ['bar', 'line'] },
		{ source: 'TRANS_CLOSED_KIOSK', title: 'Kiosk Clock-Out', icon: 'far fa-clock', defaultChartType: 'line', charTypes: ['bar', 'line'] },
		{
			source: 'TRANS_CLOSED_MOBILESTATION',
			title: 'Mobile Station Clock-Out',
			icon: 'far fa-clock',
			defaultChartType: 'line',
			charTypes: ['bar', 'line'],
		},
		{ source: 'TRANS_CLOSED_STATION', title: 'Station Clock-Out', icon: 'far fa-clock', defaultChartType: 'line', charTypes: ['bar', 'line'] },
		{ source: 'TRANS_CLOSED_WEB', title: 'Emp App Clock-Out', icon: 'far fa-clock', defaultChartType: 'line', charTypes: ['bar', 'line'] },
		{ source: 'TRANS_CLOSED_ADMIN', title: 'Admin Clock-Out', icon: 'far fa-clock', defaultChartType: 'line', charTypes: ['bar', 'line'] },

		// Operations > Time Entries > GPS Blocked
		{ source: 'TRANS_GPS_BLOCKED_ALL', title: 'GPS Blocked', icon: 'far fa-clock', defaultChartType: 'bar', charTypes: ['bar', 'line'] },
		{ source: 'TRANS_GPS_BLOCKED_IN', title: 'GPS Blocked (Clock-in)', icon: 'far fa-clock', defaultChartType: 'bar', charTypes: ['bar', 'line'] },
		{
			source: 'TRANS_GPS_BLOCKED_OUT',
			title: 'GPS Blocked (Clock-out)',
			icon: 'far fa-clock',
			defaultChartType: 'bar',
			charTypes: ['bar', 'line'],
		},

		// Operations > Time Entries > Notifications
		{ source: 'TRANS_NOTIFIED_ALL', title: 'Notifications', icon: 'far fa-clock', defaultChartType: 'bar', charTypes: ['bar', 'line'] },
		{ source: 'TRANS_NOTIFIED_EMP', title: 'Employee Notifications', icon: 'far fa-clock', defaultChartType: 'bar', charTypes: ['bar', 'line'] },
		{ source: 'TRANS_NOTIFIED_SUP', title: 'Supervisor Notifications', icon: 'far fa-clock', defaultChartType: 'bar', charTypes: ['bar', 'line'] },

		// Operations > Employee Shift Reports
		{ source: 'SHIFT_REPORT_ALL', title: 'Shift Reports', icon: 'far fa-pie-chart', defaultChartType: 'bar', charTypes: ['bar', 'line'] },
		{ source: 'SHIFT_REPORT_BASIC', title: 'Basic Shift Reports', icon: 'far fa-pie-chart', defaultChartType: 'bar', charTypes: ['bar', 'line'] },
		{ source: 'SHIFT_REPORT_CHECKIN', title: 'Clock-In Reports', icon: 'far fa-pie-chart', defaultChartType: 'bar', charTypes: ['bar', 'line'] },
		{
			source: 'SHIFT_REPORT_POST_BREAK',
			title: 'Post-Break Reports',
			icon: 'far fa-pie-chart',
			defaultChartType: 'bar',
			charTypes: ['bar', 'line'],
		},
		{ source: 'SHIFT_REPORT_CHECKOUT', title: 'Clock-Out Reports', icon: 'far fa-pie-chart', defaultChartType: 'bar', charTypes: ['bar', 'line'] },
		{ source: 'SHIFT_REPORT_HOMECARE', title: 'Home Care Reports', icon: 'far fa-pie-chart', defaultChartType: 'bar', charTypes: ['bar', 'line'] },
		{
			source: 'SHIFT_REPORT_VEHICLE',
			title: 'Vehicle Inspection Reports',
			icon: 'far fa-pie-chart',
			defaultChartType: 'bar',
			charTypes: ['bar', 'line'],
		},
		{ source: 'SHIFT_REPORT_INCIDENT', title: 'Incident Reports', icon: 'far fa-pie-chart', defaultChartType: 'bar', charTypes: ['bar', 'line'] },
		{ source: 'SHIFT_REPORT_CUSTOM', title: 'Custom Reports', icon: 'far fa-pie-chart', defaultChartType: 'bar', charTypes: ['bar', 'line'] },

		// Operations > Comm Log
		{
			source: 'COMM_LOG_CALLS_TEXTS',
			title: 'Comm Log (Calls / Texts)',
			icon: 'far fa-pie-chart',
			defaultChartType: 'line',
			charTypes: ['bar', 'line'],
		},
		{ source: 'COMM_LOG_CALLS', title: 'Comm Log (Calls)', icon: 'far fa-pie-chart', defaultChartType: 'bar', charTypes: ['bar', 'line'] },
		{ source: 'COMM_LOG_TEXTS', title: 'Comm Log (Texts)', icon: 'far fa-pie-chart', defaultChartType: 'bar', charTypes: ['bar', 'line'] },

		// Operations > Audit Log
		{ source: 'AUDIT_LOG_ALL', title: 'Audit Log', icon: 'far fa-pie-chart', defaultChartType: 'line', charTypes: ['bar', 'line'] },
	]

	static getTitleForDataSource(source: VizCardCounterDataSourceType | VizCardGraphDataSourceType, cardType: VizCardType): string {
		const options = cardType === 'COUNTER' ? VizDashboardHelper.counters : VizDashboardHelper.graphs
		const option = options.find((opt) => opt.source === source)
		return option ? option.title : 'Graph Title'
	}

	static getIconForDataSource(source: VizCardCounterDataSourceType | VizCardGraphDataSourceType, cardType: VizCardType): string {
		const options = cardType === 'COUNTER' ? VizDashboardHelper.counters : VizDashboardHelper.graphs
		const option = options.find((opt) => opt.source === source)
		return option ? option.icon : 'far fa-octagon-exclamation'
	}

	static canRenderChartTypeForDataSource(source: VizCardGraphDataSourceType, chartType: ChartType): boolean {
		const options = VizDashboardHelper.graphs
		const option = options.find((opt) => opt.source === source)
		return option?.charTypes.includes(chartType) ?? false
	}
}

export class VizCardCounterDataSource {
	// Need to store these properties in dashboard record
	source: VizCardCounterDataSourceType
	title: string = 'Blank Counter'

	// Do not need to store these properties in dashboard record
	// ['#a06666', '#7ed9da']
	count: number = 0
	countColor = '#a06666' // '#8366a1'
	subCount: number = 0
	subText = 'Secondary Counter'
	subCountColor = '#7ec1c2' // '#daa17d' //'#c4a753'
	iconClass = 'far fa-octagon-exclamation'

	pieOptions = null
	pieData = null

	constructor(source?: VizCardCounterDataSourceType) {
		if (source) {
			this.setSource(source)
			this.updateData()
		}
	}

	setSource(source: VizCardCounterDataSourceType) {
		this.source = source
		const option = VizDashboardHelper.counters.find((opt) => opt.source === source)
		if (option) {
			this.title = option.title
			this.subText = option.subTitle
			this.iconClass = option.icon
		}
	}

	updateData() {
		let records: Array<CompanyActivityLogRecord> = []
		let result: CardDataset = null
		switch (this.source) {
			// People > Employees
			case 'EMPLOYEES_ALL':
				this.count = Global.coreSrvc.dbSrvc.empSrvc.getActiveEmployees().length
				this.subCount = Global.coreSrvc.dbSrvc.empSrvc.getInactiveEmployees().length
				this.pieOptions = VizCardCounterDataSourceGenerator.makeOptionsForCounterPie()
				this.pieData = VizCardCounterDataSourceGenerator.makeDataForCounterPie(this.count, this.subCount)
				break

			// Work > Jobs
			case 'JOBS_ALL':
				this.count = Global.coreSrvc.dbSrvc.jobSrvc.getActiveJobs().length - Global.hiddenJobCount
				this.subCount = Global.coreSrvc.dbSrvc.jobSrvc.getInactiveJobs().length
				this.pieOptions = VizCardCounterDataSourceGenerator.makeOptionsForCounterPie()
				this.pieData = VizCardCounterDataSourceGenerator.makeDataForCounterPie(this.count, this.subCount)
				break

			// Scheduling > Schedules
			case 'SCHEDULES_ALL':
				this.count = Global.coreSrvc.dbSrvc.schedulerSrvc.getSchedules().filter((s) => s.enabled).length
				this.subCount = Global.coreSrvc.dbSrvc.schedulerSrvc.getSchedules().filter((s) => !s.enabled).length
				this.pieOptions = VizCardCounterDataSourceGenerator.makeOptionsForCounterPie()
				this.pieData = VizCardCounterDataSourceGenerator.makeDataForCounterPie(this.count, this.subCount)
				break

			// Operations > Time Entries
			case 'TRANS_ALL':
				records = Global.coreSrvc.dbSrvc.vizSrvc.getCompanyActivityLogRecords()
				result = VizCardGraphDataSourceGenerator.generateCouterData('transaction_count_daily', records)
				this.count = result.sum
				this.subCount = result.average
				break
			case 'TRANS_NO_SHOW':
				records = Global.coreSrvc.dbSrvc.vizSrvc.getCompanyActivityLogRecords()
				result = VizCardGraphDataSourceGenerator.generateCouterData('trans_noshow_count_daily', records)
				this.count = result.sum
				this.subCount = result.average
				break
			case 'TRANS_MISSING':
				records = Global.coreSrvc.dbSrvc.vizSrvc.getCompanyActivityLogRecords()
				result = VizCardGraphDataSourceGenerator.generateCouterData('trans_missing_checkout_count_daily', records)
				this.count = result.sum
				this.subCount = result.average
				break
			// Operations > Time Entries > GPS Blocked
			case 'TRANS_GPS_BLOCKED_ANY':
				records = Global.coreSrvc.dbSrvc.vizSrvc.getCompanyActivityLogRecords()
				result = VizCardGraphDataSourceGenerator.generateCouterData('trans_gps_blocked_inorout_count_daily', records)
				this.count = result.sum
				this.subCount = result.average
				break

			// Operations > Time Entries > Notifications
			case 'TRANS_NOTIFIED_EMP':
				records = Global.coreSrvc.dbSrvc.vizSrvc.getCompanyActivityLogRecords()
				result = VizCardGraphDataSourceGenerator.generateCouterData('trans_notified_emp_count_daily', records)
				this.count = result.sum
				this.subCount = result.average
				break
			case 'TRANS_NOTIFIED_SUP':
				records = Global.coreSrvc.dbSrvc.vizSrvc.getCompanyActivityLogRecords()
				result = VizCardGraphDataSourceGenerator.generateCouterData('trans_notified_sup_count_daily', records)
				this.count = result.sum
				this.subCount = result.average
				break

			// Operations > Shift Reports
			case 'SHIFT_REPORT_ALL':
				records = Global.coreSrvc.dbSrvc.vizSrvc.getCompanyActivityLogRecords()
				result = VizCardGraphDataSourceGenerator.generateCouterData('incident_log_count_daily', records)
				this.count = result.sum
				this.subCount = result.average
				break
			case 'SHIFT_REPORT_BASIC':
				records = Global.coreSrvc.dbSrvc.vizSrvc.getCompanyActivityLogRecords()
				result = VizCardGraphDataSourceGenerator.generateCouterData('incident_log_type_shift_count_daily', records)
				this.count = result.sum
				this.subCount = result.average
				break
			case 'SHIFT_REPORT_CHECKIN':
				records = Global.coreSrvc.dbSrvc.vizSrvc.getCompanyActivityLogRecords()
				result = VizCardGraphDataSourceGenerator.generateCouterData('incident_log_type_checkin_count_daily', records)
				this.count = result.sum
				this.subCount = result.average
				break
			case 'SHIFT_REPORT_POST_BREAK':
				records = Global.coreSrvc.dbSrvc.vizSrvc.getCompanyActivityLogRecords()
				result = VizCardGraphDataSourceGenerator.generateCouterData('incident_log_type_post_break_count_daily', records)
				this.count = result.sum
				this.subCount = result.average
				break
			case 'SHIFT_REPORT_CHECKOUT':
				records = Global.coreSrvc.dbSrvc.vizSrvc.getCompanyActivityLogRecords()
				result = VizCardGraphDataSourceGenerator.generateCouterData('incident_log_type_checkout_count_daily', records)
				this.count = result.sum
				this.subCount = result.average
				break
			case 'SHIFT_REPORT_HOMECARE':
				records = Global.coreSrvc.dbSrvc.vizSrvc.getCompanyActivityLogRecords()
				result = VizCardGraphDataSourceGenerator.generateCouterData('incident_log_type_homecare_count_daily', records)
				this.count = result.sum
				this.subCount = result.average
				break
			case 'SHIFT_REPORT_VEHICLE':
				records = Global.coreSrvc.dbSrvc.vizSrvc.getCompanyActivityLogRecords()
				result = VizCardGraphDataSourceGenerator.generateCouterData('incident_log_type_vehicle_inspection_count_daily', records)
				this.count = result.sum
				this.subCount = result.average
				break
			case 'SHIFT_REPORT_INCIDENT':
				records = Global.coreSrvc.dbSrvc.vizSrvc.getCompanyActivityLogRecords()
				result = VizCardGraphDataSourceGenerator.generateCouterData('incident_log_type_incident_count_daily', records)
				this.count = result.sum
				this.subCount = result.average
				break
			case 'SHIFT_REPORT_CUSTOM':
				records = Global.coreSrvc.dbSrvc.vizSrvc.getCompanyActivityLogRecords()
				result = VizCardGraphDataSourceGenerator.generateCouterData('incident_log_type_custom_count_daily', records)
				this.count = result.sum
				this.subCount = result.average
				break
		}
	}

	getTitle(): string {
		return VizDashboardHelper.getTitleForDataSource(this.source, 'COUNTER')
	}
}

export class VizCardCounterDataSourceGenerator {
	static makeOptionsForCounterPie(): Object {
		return {
			responsive: true,
			plugins: {
				legend: { display: false },
				tooltip: { enabled: false },
				autocolors: { mode: 'dataset', offset: 1, repeat: 1 },
			},
			hover: { mode: null },
		}
	}

	static makeDataForCounterPie(count: number, subCount: number): Object {
		// ['#8366a1', '#c4a753']
		// ['#8366a1', '#daa17d']
		// ['#a06666', '#7ed9da']

		return {
			datasets: [{ data: [count, subCount], backgroundColor: ['#a06666', '#7ed9da'] }],
		}
	}
}

// Parameters for VizCardGraphDataSource constructtor (graph card data source)
export interface VizCardGraphDataSourceParameters {
	source: VizCardGraphDataSourceType
	type?: ChartsGraphType
	options?: any
}

// Data source for grpah card
export class VizCardGraphDataSource {
	// Need to store these properties in dashboard record
	source: VizCardGraphDataSourceType
	title = 'Blank Graph'
	type: ChartsGraphType = 'bar'
	options: ChartsConfigOptions = {}

	// Do not need to store these properties in dashboard record
	iconClass = 'far fa-octagon-exclamation'
	data = {}

	constructor(data?: VizCardGraphDataSourceParameters) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) this[attr] = data[attr]
			}
		}
		if (_.isEmpty(this.options)) {
			this.setupOptions()
		}

		if (this.source) this.setSource(data.source)

		// Update to use default type if not available
		if (!data?.type) {
			const graphConfig = VizDashboardHelper.graphs.find((opt) => opt.source === this.source)
			if (graphConfig) {
				this.type = graphConfig.defaultChartType
			}
		}

		const yAxis = this.options?.scales?.y
		if (yAxis) {
			if (this.type === 'bar') {
				yAxis.stacked = true
			} else {
				yAxis.stacked = false
			}
		}

		this.updateData()
	}

	// We don't want to overwrite the stored options so we are only updating the data
	restoreFromSavedData(data: any) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) this[attr] = data[attr]
			}
			this.setSource(data.source)
			this.updateData()
		}
	}

	setSource(source: VizCardGraphDataSourceType) {
		this.source = source
		this.title = VizDashboardHelper.getTitleForDataSource(source, 'GRAPH')
		this.iconClass = VizDashboardHelper.getIconForDataSource(source, 'GRAPH')

		// If the chart type is not available for the data source, set chart type to the default
		const graphConfig = VizDashboardHelper.graphs.find((opt) => opt.source === source)
		if (graphConfig && !graphConfig.charTypes.includes(this.type)) {
			this.type = graphConfig.defaultChartType as ChartType
		}
	}

	setType(type: ChartsGraphType) {
		this.type = type
	}

	setupOptions() {
		this.options = VizCardGraphDataSourceGenerator.makeOptionsForGraph(this.source)
	}

	updateData() {
		const records = Global.coreSrvc.dbSrvc.vizSrvc.getCompanyActivityLogRecords()
		this.data = VizCardGraphDataSourceGenerator.makeDataForGraph(this.source, records)
	}
}

export class VizCardGraphDataSourceGenerator {
	static makeDataForGraph(source: VizCardGraphDataSourceType, records: Array<CompanyActivityLogRecord>): Object {
		let set1, set2, set3, set4, set5, set6, set7, set8: ChartsDataset

		// When assigning labels, only provide the first set or they will get duplicated
		switch (source) {
			// People > Employees
			case 'EMPLOYEES_ALL':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('employee_active_count', 'Active', records, false)
				set2 = VizCardGraphDataSourceGenerator.generateChartData('employee_inactive_count', 'Inactive', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets, ...set2.datasets] }
			case 'EMPLOYEES_ACTIVE':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('employee_active_count', 'Active', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'EMPLOYEES_INACTIVE':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('employee_inactive_count', 'Inactive', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }

			// People > Supervisors
			case 'SUPERVISORS_ALL':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('user_count', 'Supervisors', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }

			// Work > Jobs
			case 'JOBS_ALL':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('job_active_count', 'Active', records, false)
				set2 = VizCardGraphDataSourceGenerator.generateChartData('job_inactive_count', 'Inactive', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets, ...set2.datasets] }
			case 'JOBS_ACTIVE':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('job_active_count', 'Active', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'JOBS_INACTIVE':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('job_inactive_count', 'Inactive', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }

			// Work > Tours
			case 'TOURS_ALL':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('tour_type_unstructured_count', 'Basic', records, false)
				set2 = VizCardGraphDataSourceGenerator.generateChartData('tour_type_hourly_count', 'Hourly', records, false)
				set3 = VizCardGraphDataSourceGenerator.generateChartData('tour_type_daily_count', 'Daily', records, false)
				set4 = VizCardGraphDataSourceGenerator.generateChartData('tour_type_shift_timed_count', 'Shift-timed', records, false)
				return {
					labels: [...set1.labels],
					datasets: [...set1.datasets, ...set2.datasets, ...set3.datasets, ...set4.datasets],
				}
			case 'TOURS_UNSTRUCTURED':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('tour_type_unstructured_count', 'Basic', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'TOURS_HOURLY':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('tour_type_hourly_count', 'Hourly', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'TOURS_DAILY':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('tour_type_daily_count', 'Daily', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'TOURS_SHIFT':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('tour_type_shift_timed_count', 'Shift-timed', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }

			// Scheduling > Schedules
			case 'SCHEDULES_ALL':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('schedule_count', 'Schedules', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'JOBS_SCHEDULES':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('job_count', 'Jobs', records, false)
				set2 = VizCardGraphDataSourceGenerator.generateChartData('schedule_count', 'Schedules', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets, ...set2.datasets] }

			// Operations > Time Entries
			case 'TRANS_ALL':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('transaction_count_daily', 'Time Entries', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'TRANS_NO_SHOW':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_noshow_count_daily', 'No Shows', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'TRANS_MISSING':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_missing_checkout_count_daily', 'Missing Clock-Outs', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }

			// Operations > Time Entries > Source > Clock-In
			case 'TRANS_CREATED_ALL':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_created_by_landline_count_daily', 'Landline', records, false)
				set2 = VizCardGraphDataSourceGenerator.generateChartData('trans_created_by_cell_phone_count_daily', 'Mobile', records, false)
				set3 = VizCardGraphDataSourceGenerator.generateChartData('trans_created_by_station_kiosk_count_daily', 'Kiosk', records, false)
				set4 = VizCardGraphDataSourceGenerator.generateChartData('trans_created_by_station_mobile_count_daily', 'Mobile Station', records, false)
				set5 = VizCardGraphDataSourceGenerator.generateChartData('trans_created_by_station_count_daily', 'Station', records, false)
				set6 = VizCardGraphDataSourceGenerator.generateChartData('trans_created_by_emp_app_count_daily', 'Emp App', records, false)
				set7 = VizCardGraphDataSourceGenerator.generateChartData('trans_created_by_admin_count_daily', 'Admin', records, false)
				return {
					labels: [...set1.labels],
					datasets: [
						...set1.datasets,
						...set2.datasets,
						...set3.datasets,
						...set4.datasets,
						...set5.datasets,
						...set6.datasets,
						...set7.datasets,
					],
				}
			case 'TRANS_CREATED_LANDLINE':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_created_by_landline_count_daily', 'Landline Clock-In', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'TRANS_CREATED_MOBILE':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_created_by_cell_phone_count_daily', 'Mobile Clock-In', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'TRANS_CREATED_KIOSK':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_created_by_station_kiosk_count_daily', 'Kiosk Clock-In', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'TRANS_CREATED_MOBILESTATION':
				set1 = VizCardGraphDataSourceGenerator.generateChartData(
					'trans_created_by_station_mobile_count_daily',
					'Mobile Station Clock-In',
					records,
					false,
				)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'TRANS_CREATED_STATION':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_created_by_station_count_daily', 'Station Clock-In', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'TRANS_CREATED_WEB':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_created_by_emp_app_count_daily', 'Emp App Clock-In', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'TRANS_CREATED_ADMIN':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_created_by_admin_count_daily', 'Admin Clock-In', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }

			// Operations > Time Entries > Source > Closed
			case 'TRANS_CLOSED_ALL':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_closed_by_landline_count_daily', 'Landline', records, false)
				set2 = VizCardGraphDataSourceGenerator.generateChartData('trans_closed_by_cell_phone_count_daily', 'Mobile', records, false)
				set3 = VizCardGraphDataSourceGenerator.generateChartData('trans_closed_by_station_kiosk_count_daily', 'Kiosk', records, false)
				set4 = VizCardGraphDataSourceGenerator.generateChartData('trans_closed_by_station_mobile_count_daily', 'Mobile Station', records, false)
				set5 = VizCardGraphDataSourceGenerator.generateChartData('trans_closed_by_station_count_daily', 'Station', records, false)
				set6 = VizCardGraphDataSourceGenerator.generateChartData('trans_closed_by_emp_app_count_daily', 'Emp App', records, false)
				set7 = VizCardGraphDataSourceGenerator.generateChartData('trans_closed_by_admin_count_daily', 'Admin', records, false)
				return {
					labels: [...set1.labels],
					datasets: [
						...set1.datasets,
						...set2.datasets,
						...set3.datasets,
						...set4.datasets,
						...set5.datasets,
						...set6.datasets,
						...set7.datasets,
					],
				}
			case 'TRANS_CLOSED_LANDLINE':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_closed_by_landline_count_daily', 'Landline Clock-Out', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'TRANS_CLOSED_MOBILE':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_closed_by_cell_phone_count_daily', 'Mobile Clock-Out', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'TRANS_CLOSED_KIOSK':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_closed_by_station_kiosk_count_daily', 'Kiosk Clock-Out', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'TRANS_CLOSED_MOBILESTATION':
				set1 = VizCardGraphDataSourceGenerator.generateChartData(
					'trans_closed_by_station_mobile_count_daily',
					'Mobile Station Clock-In',
					records,
					false,
				)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'TRANS_CLOSED_STATION':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_closed_by_station_count_daily', 'Station Clock-Out', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'TRANS_CLOSED_WEB':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_closed_by_emp_app_count_daily', 'Emp App Clock-Out', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'TRANS_CLOSED_ADMIN':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_closed_by_admin_count_daily', 'Admin Clock-Out', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }

			// Operations > Time Entries > Notifications
			case 'TRANS_GPS_BLOCKED_ALL':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_gps_blocked_checkin_count_daily', 'Clock-In', records, false)
				set2 = VizCardGraphDataSourceGenerator.generateChartData('trans_gps_blocked_checkout_count_daily', 'Clock-Out', records, false)
				return {
					labels: [...set1.labels],
					datasets: [...set1.datasets, ...set2.datasets],
				}
			case 'TRANS_GPS_BLOCKED_IN':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_gps_blocked_checkin_count_daily', 'Clock-In', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'TRANS_GPS_BLOCKED_OUT':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_gps_blocked_checkout_count_daily', 'Clock-Out', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }

			// Operations > Time Entries > Notifications
			case 'TRANS_NOTIFIED_ALL':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_notified_emp_count_daily', 'Employee', records, false)
				set2 = VizCardGraphDataSourceGenerator.generateChartData('trans_notified_sup_count_daily', 'Supervisor', records, false)
				return {
					labels: [...set1.labels],
					datasets: [...set1.datasets, ...set2.datasets],
				}
			case 'TRANS_NOTIFIED_EMP':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_notified_emp_late_checkin_count_daily', 'Clock-In', records, false)
				set2 = VizCardGraphDataSourceGenerator.generateChartData('trans_notified_emp_late_checkpoint_daily', 'Checkpoint', records, false)
				set3 = VizCardGraphDataSourceGenerator.generateChartData('trans_notified_emp_late_checkout_count_daily', 'Clock-Out', records, false)

				return { labels: [...set1.labels], datasets: [...set1.datasets, ...set2.datasets, ...set3.datasets] }
			case 'TRANS_NOTIFIED_SUP':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('trans_notified_sup_late_checkin_count_daily', 'Clock-In', records, false)
				set2 = VizCardGraphDataSourceGenerator.generateChartData('trans_notified_sup_late_checkpoint_daily', 'Checkpoint', records, false)
				set3 = VizCardGraphDataSourceGenerator.generateChartData('trans_notified_sup_late_checkout_count_daily', 'Clock-Out', records, false)

				return { labels: [...set1.labels], datasets: [...set1.datasets, ...set2.datasets, ...set3.datasets] }

			// Operations > Shift Reports
			case 'SHIFT_REPORT_ALL':
				const industry = Global.coreSrvc.dbSrvc.settingSrvc.getCompany().industry
				set1 = VizCardGraphDataSourceGenerator.generateChartData('incident_log_type_shift_count_daily', 'Basic', records, false)
				set2 = VizCardGraphDataSourceGenerator.generateChartData('incident_log_type_checkin_count_daily', 'Clock-In', records, false)
				set3 = VizCardGraphDataSourceGenerator.generateChartData('incident_log_type_post_break_count_daily', 'Post-Break', records, false)
				set4 = VizCardGraphDataSourceGenerator.generateChartData('incident_log_type_checkout_count_daily', 'Clock-Out', records, false)
				set5 = VizCardGraphDataSourceGenerator.generateChartData('incident_log_type_homecare_count_daily', 'Home Care', records, false)
				set6 = VizCardGraphDataSourceGenerator.generateChartData('incident_log_type_vehicle_inspection_count_daily', 'Vehicle', records, false)
				set7 = VizCardGraphDataSourceGenerator.generateChartData('incident_log_type_incident_count_daily', 'Incident', records, false)
				set8 = VizCardGraphDataSourceGenerator.generateChartData('incident_log_type_custom_count_daily', 'Custom', records, false)

				if (industry === 'HEALTHCARE') {
					return {
						labels: [...set1.labels],
						datasets: [
							...set1.datasets,
							...set2.datasets,
							...set3.datasets,
							...set4.datasets,
							...set5.datasets,
							...set6.datasets,
							...set7.datasets,
							...set8.datasets,
						],
					}
				} else {
					return {
						labels: [...set1.labels],
						datasets: [
							...set1.datasets,
							...set2.datasets,
							...set3.datasets,
							...set4.datasets,
							...set6.datasets,
							...set7.datasets,
							...set8.datasets,
						],
					}
				}

			case 'SHIFT_REPORT_BASIC':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('incident_log_type_shift_count_daily', 'Basic', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }

			case 'SHIFT_REPORT_CHECKIN':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('incident_log_type_checkin_count_daily', 'Clock-In', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }

			case 'SHIFT_REPORT_POST_BREAK':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('incident_log_type_post_break_count_daily', 'Post-Break', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }

			case 'SHIFT_REPORT_CHECKOUT':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('incident_log_type_checkout_count_daily', 'Clock-Out', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }

			case 'SHIFT_REPORT_HOMECARE':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('incident_log_type_homecare_count_daily', 'Home Care', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }

			case 'SHIFT_REPORT_VEHICLE':
				set1 = VizCardGraphDataSourceGenerator.generateChartData(
					'incident_log_type_vehicle_inspection_count_daily',
					'Vehicle Inspection',
					records,
					false,
				)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }

			case 'SHIFT_REPORT_INCIDENT':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('incident_log_type_incident_count_daily', 'Incident', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }

			case 'SHIFT_REPORT_CUSTOM':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('incident_log_type_custom_count_daily', 'Custom', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }

			// Operations > Comm Log

			case 'COMM_LOG_CALLS_TEXTS':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('c2c_log_type_call_count_daily', 'Calls', records, false)
				set2 = VizCardGraphDataSourceGenerator.generateChartData('c2c_log_type_text_count_daily', 'Texts', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets, ...set2.datasets] }
			case 'COMM_LOG_CALLS':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('c2c_log_type_call_count_daily', 'Calls', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }
			case 'COMM_LOG_TEXTS':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('c2c_log_type_text_count_daily', 'Texts', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets] }

			case 'AUDIT_LOG_ALL':
				set1 = VizCardGraphDataSourceGenerator.generateChartData('audit_log_op_insert_count_daily', 'Insert', records, false)
				set2 = VizCardGraphDataSourceGenerator.generateChartData('audit_log_op_update_count_daily', 'Update', records, false)
				set3 = VizCardGraphDataSourceGenerator.generateChartData('audit_log_op_delete_count_daily', 'Delete', records, false)
				return { labels: [...set1.labels], datasets: [...set1.datasets, ...set2.datasets, ...set3.datasets] }

			default:
				return {
					labels: [],
					datasets: [
						{
							label: 'Active',
							data: [50],
						},
						{
							label: 'Inactive',
							data: [100],
						},
					],
				}
		}
	}

	static colors = ['#BBBBBB', '#8366A1', '#C4A753', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF']

	static makeOptionsForGraph(sourceType: VizCardGraphDataSourceType): ChartsConfigOptions {
		const options: ChartsConfigOptions = {
			plugins: {
				legend: {
					display: false,
					position: 'bottom',
				},
				autocolors: {
					mode: 'dataset',
					enabled: true,
					offset: 1,
					repeat: 1,
				},
			},
			scales: {
				x: { stacked: true },
				y: { stacked: true, ticks: { precision: 0 } },
			},
		}

		// Customize options based on the data source type
		switch (sourceType) {
			case 'EMPLOYEES_ALL':
			case 'JOBS_ALL':
			case 'TOURS_ALL':
			case 'TRANS_CREATED_ALL':
			case 'TRANS_CLOSED_ALL':
			case 'TRANS_GPS_BLOCKED_ALL':
			case 'TRANS_NOTIFIED_ALL':
			case 'TRANS_NOTIFIED_EMP':
			case 'TRANS_NOTIFIED_SUP':
			case 'SHIFT_REPORT_ALL':
			case 'COMM_LOG_CALLS_TEXTS':
			case 'AUDIT_LOG_ALL':
				options.plugins.legend.display = true
				return options

			default:
				return options
		}
	}

	static generateChartData(
		property: keyof CompanyActivityLogRecord,
		propertyLabel: string,
		records: CompanyActivityLogRecord[],
		hidden: boolean,
	): ChartsDataset {
		const labels: string[] = []
		const data: number[] = []

		// Determine bucket size based on the number of records
		const useDailyBuckets = records.length <= 31
		const useAverage = false // !useDailyBuckets - DEPRECATED - Always use sum

		// Helper function to format dates
		const formatDate = (date: Date, daily: boolean): string => {
			return daily
				? date.toISOString().split('T')[0] // YYYY-MM-DD for daily buckets
				: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}` // YYYY-MM for monthly buckets
		}

		// Initialize buckets
		const buckets: { [key: string]: { sum: number; count: number } } = {}

		// Populate the buckets
		records.forEach((record) => {
			const date = new Date(record.ts)

			// Handle lookback adjustments. Daily counters normally look at yesterday. Some might look back further
			if (property.includes('_daily')) date.setDate(date.getDate() - 1)
			if (property === 'trans_missing_checkout_count_daily') date.setDate(date.getDate() - 1)

			const bucketKey = formatDate(date, useDailyBuckets)

			if (!buckets[bucketKey]) {
				buckets[bucketKey] = { sum: 0, count: 0 }
			}

			// Accumulate the values in the appropriate bucket
			const value = record[property]
			if (typeof value === 'number') {
				buckets[bucketKey].sum += value
				buckets[bucketKey].count += 1
			}
		})

		// Generate labels and data arrays
		for (const [key, { sum, count }] of Object.entries(buckets)) {
			labels.push(key)
			// Depending on useAverage, either sum or calculate average
			const aggregatedValue = useAverage ? Math.ceil(sum / count) : sum
			data.push(aggregatedValue)
		}

		// Construct the dataset object for Chart.js
		return {
			labels,
			datasets: [
				{
					label: propertyLabel,
					data: data,
					hidden: hidden,
				},
			],
		}
	}

	static generateCouterData(property: keyof CompanyActivityLogRecord, records: CompanyActivityLogRecord[]): CardDataset {
		// Iterate through all the records and provide a sum and average for the property specified
		if (records.length === 0) return { sum: 0, average: 0 }
		let sum = 0
		let count = 0
		records.forEach((record) => {
			const value = record[property]
			if (typeof value === 'number') {
				sum += value ?? 0
				count += 1
			}
		})

		const avgResult = sum / count
		const result = { sum: sum, average: avgResult > 1 ? Math.ceil(avgResult) : avgResult }

		return result
	}
}

// const exampleDataSet = {
// 	labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
// 	datasets: [
// 		{
// 			label: 'Active',
// 			data: [65, 59, 80, 81, 56, 55, 40],
// 		},
// 		{
// 			label: 'Inactive',
// 			data: [28, 48, 40, 19, 86, 27, 90],
// 		},
// 	],
// }

export class VizCardContextMenu {
	menuItems: any = []

	constructor() {
		this.menuItems = []
	}
}
