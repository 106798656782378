import { DateTimeHelper, log } from '@app/helpers'

export interface IQBOSyncPayload {
	table: string
	operation: string
	start_date?: string
	end_date?: string
}

export interface IQBOOperationsPayload {
	operation: string
}

export class QBOCustomer {
	id: number
	company_id: number
	qbo_id: number
	name: string
	full_name: string
	created: string

	constructor(record?: QBOCustomer) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
		}
	}
}

export class QBOVendor {
	id: number
	company_id: number
	qbo_id: number
	name: string
	created: string

	constructor(record?: QBOVendor) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
		}
	}
}

export class QBOServiceItem {
	id: number
	company_id: number
	qbo_id: number
	name: string
	full_name: string
	description: string
	created: string

	constructor(record?: QBOServiceItem) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
		}
	}
}

export class QBOSync {
	access_token: string
	access_token_expires: string
	access_token_secret: string
	company_id: number
	created: string
	customer_sync: string
	customer_sync_errors: number
	desktop_company_name: string
	desktop_create_time: number
	desktop_payroll_hol: string
	desktop_payroll_ot: string
	desktop_payroll_othol: string
	desktop_payroll_reg: string
	desktop_sync: boolean
	employee_sync: string
	employee_sync_errors: number
	id: number
	realm_id: string
	request_token: string
	rquest_token_secret: string
	service_item_sync: string
	service_item_sync_errors: number
	transaction_log_lock: boolean // ???
	transaction_log_sync: string
	transaction_log_sync_errors: number
	transaction_log_last_start: string
	transaction_log_last_end: string
	updated: string
	vendor_sync: string
	vendor_sync_errors: number
	vendor_company_name: string

	constructor(record?: QBOSync) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)

			this.employee_sync = DateTimeHelper.stripUtcTag(this.employee_sync)
			this.customer_sync = DateTimeHelper.stripUtcTag(this.customer_sync)
			this.vendor_sync = DateTimeHelper.stripUtcTag(this.vendor_sync)
			this.service_item_sync = DateTimeHelper.stripUtcTag(this.service_item_sync)

			this.transaction_log_last_start = DateTimeHelper.stripUtcTag(this.transaction_log_last_start)
			this.transaction_log_last_end = DateTimeHelper.stripUtcTag(this.transaction_log_last_end)
		}
		log('QBOSync', this)
	}
}
