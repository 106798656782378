import { DateTimeHelper } from '@app/helpers'

export class StationRecord {
	id: number
	company_id: number

	description: string = null
	details: string = null
	employee_ids: Array<number> = []
	job_ids: Array<number> = []

	ip_allowlist: string = null

	kiosk_mode = false
	kiosk_checkpoints_enabled = false
	kiosk_reports_enabled = false

	mobile_station = false
	mobile_station_e164: string = null

	use_qr_code = false
	use_emp_pin = false
	use_job_pin = false
	allow_default_job = true

	// use_emp_pin will expose 3 matchers
	match_emp_station_pin = true
	match_emp_ext_id = false
	match_emp_phone = false

	setup_code: string = null
	admin_code: string = null
	status_list = false

	token: string = null

	created: string = null
	updated: string = null

	constructor(record?: StationRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
			this.employee_ids = record.employee_ids ?? []
			this.job_ids = record.job_ids ?? []
		}
	}
}

export class StationViewManager {
	version = 1
	isSectionWorking = false
}
