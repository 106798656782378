import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core'
import { UserReportType, UserReportImage, HomecareReportData, MailingAddress, Incident, ReportGlobals, ReportRendererConfig } from '@app/models'
import { log } from '@app/helpers'

import _ from 'lodash'
import moment from 'moment-timezone'

class ServiceProvided {
	label: string
	value: boolean
	skip: boolean
	// answer: string
	description: string

	constructor(key: string, value: boolean, skip: boolean, description: string) {
		this.label = _.startCase(key)
		this.value = value
		this.skip = skip
		// this.answer = value ? 'YES' : 'NO'
		this.description = description
	}
}

class HomecareReportViewModel {
	isDesktop = false
	images: Array<UserReportImage> = []

	reportType: UserReportType
	reportData: HomecareReportData

	reportDate = ''

	skipLabel = '< intentionally skipped >'
	noDescription = '< no information provided >'

	format12Hour = true
	timezone: string
	timeFormat = 'h:mm a'

	logoUrl: string
	companyName: string
	address: MailingAddress

	reportInfo = {
		serviceType: '',
		serviceProviderName: '',
	}

	clientInfo = {
		clientName: '',
		planDate: '',
	}

	servicesProvided: Array<ServiceProvided> = []

	constructor(config: ReportRendererConfig) {
		const incident = config.reportData as Incident
		this.setupViewModel(incident, config)
		this.setupSectionData(config)
	}

	prepareForPrint() {}

	setupViewModel(incident: Incident, config: ReportRendererConfig) {
		this.images = incident.imageFiles.map((img) => new UserReportImage(img))
		this.reportType = incident.report_type as UserReportType
		this.reportData = JSON.parse(incident.report_json)?.reportData ?? new HomecareReportData()

		// Setup timezone
		const transMetaData = this.reportData.transactionMetaData
		const timezone = transMetaData?.timezone
		this.timezone = timezone || moment.tz.guess()

		// Setup time formatting
		this.format12Hour = config.formatTime12Hour

		// Setup report date
		const timeFormat = this.format12Hour ? 'h:mm a' : 'HH:mm'
		this.timeFormat = timeFormat
		this.reportDate = moment.tz(incident.created, timezone).format(`ddd MMM Do, YYYY @ ${timeFormat} z`)
	}

	setupSectionData(config: ReportRendererConfig) {
		this.setupHeader(config)
		this.setupReportInfo()
		this.setupClientInfo()
		this.setupServicesProvided()
	}

	setupHeader(config: ReportRendererConfig) {
		this.logoUrl = config.logoUrl
		this.address = config.companyAddress
		this.companyName = config.companyName
	}

	// Report Specific Setup

	setupReportInfo() {
		const reportData = this.reportData
		this.reportInfo.serviceProviderName = reportData.serviceProvider.firstName.value + ' ' + reportData.serviceProvider.lastName.value

		const serviceType = reportData.serviceType.value
		const typeDescription = ReportGlobals.serviceTypeOptionsForHomecareReport.find((opt) => opt.type === serviceType)?.name
		this.reportInfo.serviceType = typeDescription
	}

	setupClientInfo() {
		const reportData = this.reportData
		this.clientInfo.clientName = reportData.clientInformation.firstName.value + ' ' + reportData.clientInformation.lastName.value

		const planDate = reportData.supportPlan.effectiveDate.value
		this.clientInfo.planDate = moment.tz(planDate, this.timezone).format(`ddd MMM Do, YYYY`)
	}

	setupServicesProvided() {
		const reportData = this.reportData
		const servicesProvided = reportData.servicesProvided

		const keys = [
			'assistWithRightsEducation',
			'assistWithAbuseEducation',
			'assistWithNeglectEducation',
			'assistWithExploitationEducation',
			'assistWithCommunicationSkills',
			'assistWithHealthMaintenance',
			'assistWithStrengthAndBalance',
			'assistWithDailyTasks',
			'assistWithSocialization',
			'assistWithShopping',
			'assistWithChoicesAndOptions',
			'assistWithOtherActivities',
		]

		for (const key of keys) {
			const sp = servicesProvided[key]
			if (sp) {
				const service = new ServiceProvided(key, sp.value, sp.skip, sp.description)
				this.servicesProvided.push(service)
			}
		}
	}
}

@Component({
    selector: 'app-homecare-report-renderer',
    templateUrl: './homecare-report-renderer.component.html',
    styleUrls: ['./homecare-report-renderer.component.scss'],
    standalone: false
})
export class HomecareReportRendererComponent implements OnInit, OnDestroy {
	constructor() {}

	public vm: HomecareReportViewModel
	public incident: Incident

	@Input() config: ReportRendererConfig
	@Input() publicView = false
	@Output() closeBtnClicked = new EventEmitter<boolean>()

	ngOnInit(): void {
		this.incident = this.config.reportData as Incident
		this.vm = new HomecareReportViewModel(this.config)
		log('Viewing Report', this.incident)
	}

	printPage() {
		this.vm.prepareForPrint()
		setTimeout(() => {
			window.print()
		}, 500)
	}

	ngOnDestroy() {}
}
