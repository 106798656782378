import { Component, OnInit } from '@angular/core'
import { UserLoginService } from '@app/services/aws/user-login.service'

import { log } from '@app/helpers'

@Component({
    selector: 'app-inactive-logout',
    templateUrl: './inactive-logout.component.html',
    styleUrls: ['./inactive-logout.component.scss'],
    standalone: false
})
export class InactiveLogoutComponent implements OnInit {

	showMsg = true

	constructor(
		private userService: UserLoginService
	) { }

	ngOnInit() {
		this.userService.clearLastActive()
	}

	isLoggingIn(event) {
		log('INACTICVE EVENT', event)
		if (event === true) {
			this.showMsg = false
		}
	}

}
