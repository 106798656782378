import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core'
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms'
import { environment } from '@env/environment'

import { CoreService } from '@app/services'
import { UserRecord, AccessRole, AccessRoleType, CompanyRecord, FormPhoneNumberManager, HelpDialogMessage, DialogManager } from '@app/models'
import { log, Helper, PhoneHelper, AccessHelper, FormHelper } from '@app/helpers'

import { parseNumber, formatNumber, CountryCode, ParsedNumber, isValidNumberForRegion } from 'libphonenumber-js'
import { Subscription } from 'rxjs'
import { SelectItem } from 'primeng/api'
import _ from 'lodash'

// Clean up User record which is mocking the allowed_companies field by adding their company id

class AllowedCompaniesDropdown {
	dropdownVisible = true
	menuOptions: Array<SelectItem> = []
	selected: Array<number> = []
	inaccessible: Array<SelectItem> = [] // Companies not available to this admin

	getUpdateList(company: CompanyRecord): Array<number> {
		const selectedList = this.selected ?? []
		const list = selectedList.filter((id) => !!id) // filter out 0, undefined, and null
		return list.length === 0 ? [company.id] : list
	}
}

@Component({
    selector: 'app-user-edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.scss'],
    standalone: false
})
export class UserEditComponent implements OnInit, AfterViewInit, OnDestroy {
	environment = environment

	user: UserRecord
	isNew = false
	isUpdating = false
	isDevelopment = environment.development
	canAccessOpenShiftOffers = false
	userForm: UntypedFormGroup

	managerDropdown: Array<SelectItem> = []
	roleDropdown: Array<SelectItem> = []
	rolePermissionsDropdown: Array<SelectItem> = []
	linkedEmployeeDropdown: Array<SelectItem> = []

	applyRolePermissions = false
	displayApplyRolePermissions = false
	displaySupervisorTypeAndRolePermissions = true
	displayManagedByDropdown = true

	allowedCompanies = new AllowedCompaniesDropdown()

	isAdminInternal = false
	isAdminPrimaryOrInternal = false
	isAdminManager = false

	dialingCode = null
	countryCodeData = Helper.countryIsoData.filter((region) => region.regionSupport)
	phoneNumber = ''
	isPhoneNumberValid = false

	// Dispatch Phone
	outboundC2CPhoneManager = new FormPhoneNumberManager()

	shouldShowLinkedEmployeeOption = false
	expandLinkedEmployeeOptions = false
	expandNotificationOptions = false

	bypasChangesNotifications = false

	@Input() recordId: number
	@Input() action = 'edit'

	@Input() dialogManager: DialogManager

	@Output() saveActionComplete = new EventEmitter<boolean>()
	@Output() closeDialog = new EventEmitter<boolean>()

	private subs = new Subscription()

	constructor(
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
	) {
		// Setup display of role dropdown
		const accessHelper = new AccessHelper(this.coreSrvc, 'supervisor')
		const myUserRole = this.coreSrvc.dbSrvc.settingSrvc.getMyUser().role
		const hasRoleAccess = accessHelper.getPermissionsFor('supervisor').isOptionEnabledFor('SUPROL')
		this.displaySupervisorTypeAndRolePermissions = myUserRole === 'PRIMARY' || myUserRole === 'INTERNAL' || hasRoleAccess
	}

	get isThisUserPrimary(): boolean {
		return this.userForm?.get('role').value === 'PRIMARY'
	}

	get isPrimaryOrInternalUser(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.isPrimaryOrInternalUser()
	}

	get shouldShowMaxDailyHoursNotification(): boolean {
		return !!this.coreSrvc.dbSrvc.settingSrvc.getCompany().max_daily_hours
	}

	get hasRoleChanged(): boolean {
		const user = this.user
		if (user && !this.isNew) {
			const origRole = user.role
			const currentRole = this.userForm.get('role').value
			return origRole !== currentRole && currentRole !== null
		}
		return false
	}

	isFormValid(): boolean {
		const isValid = this.userForm.valid && this.isPhoneValid() && this.outboundC2CPhoneManager.isFormValid
		return isValid
	}

	isEmailValid(): boolean {
		const email = this.userForm.get('email').value
		if (email) {
			return Helper.isValidEmail(email)
		}
		return true
	}

	ngOnInit() {
		if (this.action === 'new') {
			log('Creating a new user')
			this.isNew = true
			this.user = new UserRecord()
			const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
			this.dialingCode = company.country_iso as CountryCode
		} else {
			this.isNew = false
			this.user = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(this.recordId)
		}

		const adminPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		this.expandNotificationOptions = adminPrefs.globalExpandAdvancedOptions
		this.canAccessOpenShiftOffers = adminPrefs.enableOpenShiftOffers

		this.setupForm()
		this.setupPhoneNumber()
		this.formatNumber()

		this.configureFormState()
		this.setupManagerDropdown()
		this.setupRoleDropdown()
		this.setupAllowedCompaniesDropdown()
		this.setupLinkedEmployeeDropdown()
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.setupDialogManager()
		}, 500)
	}

	setupDialogManager() {
		this.dialogManager.canSubmit = () => this.isFormValid()
		this.dialogManager.submitBtnAction = () => this.submit()
	}

	configureFormState() {
		const accessHelper = new AccessHelper(this.coreSrvc, 'supervisor')
		const isLinkedEmployeeOptionAvailable = accessHelper.getPermissionsFor('supervisor').isOptionEnabledFor('SUPLEM')

		this.isAdminInternal = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()
		this.isAdminPrimaryOrInternal = this.coreSrvc.dbSrvc.settingSrvc.isPrimaryOrInternalUser()
		this.isAdminManager = this.coreSrvc.dbSrvc.settingSrvc.isUserAManager()
		this.shouldShowLinkedEmployeeOption = this.isAdminPrimaryOrInternal || isLinkedEmployeeOptionAvailable

		if (this.isNew) {
			// Editing a new record
			if (this.isAdminPrimaryOrInternal) {
				log('User is internal or primary')
				// this.displayRoleDropdowns = true
				this.displayApplyRolePermissions = true
				this.applyRolePermissions = false
			} else {
				log('User is not internal or primary')
				// this.displayRoleDropdowns = false
				this.displayApplyRolePermissions = false
				this.applyRolePermissions = true
				this.userForm.get('role').setValue('SECONDARY')
			}
			if (this.isAdminManager) {
				log('User is a manager')
				const myUserId = this.coreSrvc.dbSrvc.settingSrvc.getMyUserId()
				this.userForm.get('managed_by').setValue(myUserId)
			}
		} else {
			// Editing an existing record
			if (this.isAdminPrimaryOrInternal) {
				// this.displayRoleDropdowns = true
			} else {
				// this.displayRoleDropdowns = false
			}
		}
		log('Current form', this.userForm.value)
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	setupForm() {
		const user = this.user
		this.userForm = this.fb.group({
			id: [user ? user.id : null],
			first_name: [user ? user.first_name : null, Validators.required],
			last_name: [user ? user.last_name : null, Validators.required],
			phone_e164: [user ? user.phone_e164 : null],
			email: [user ? user.email : null],

			role: [user ? user.role : null, Validators.required],
			admin_prefs_json: [user ? user.admin_prefs_json : null],
			permissions_json: [user ? user.permissions_json : null],
			managed_by: [user ? user.managed_by : null],
			external_id: [user ? user.external_id : null],
			supervisor_role_id: [user ? user.supervisor_role_id : null],

			sms_email_enabled: [user ? !!user.sms_email_enabled : false],
			email_target: [user ? user.email_target : 'ALL'],
			time_off_enabled: [user ? user.time_off_enabled : false],
			time_off_target: [user ? user.time_off_target : 'ALL'],
			max_daily_enabled: [user ? user.max_daily_enabled : false],
			max_daily_target: [user ? user.max_daily_target : 'ALL'],
			schedule_offer_enabled: [user ? user.schedule_offer_enabled : false],
			schedule_offer_target: [user ? user.schedule_offer_target : 'ALL'],
			date_lock_enabled: [user ? user.date_lock_enabled : false],
			date_lock_target: [user ? user.date_lock_target : 'ALL'],

			linked_employee_id: [user ? user.linked_employee_id : null],
			edit_own_trans: [user ? user.edit_own_trans : false],
		})

		// Setup dispatch phone
		const outboundC2CPhone = this.user ? this.user.c2c_phone_e164 : null
		this.outboundC2CPhoneManager.configure('Dispatcher Phone Number', outboundC2CPhone, false, 'outboundC2C')
		this.outboundC2CPhoneManager.placeholder = PhoneHelper.formatPhoneFromE164(user.phone_e164)
	}

	private setupManagerRoleOption() {}

	submit() {
		// Guard against double submission
		if (this.isUpdating) return
		FormHelper.trimOnlyWhitespace(this.userForm)

		const myActualUser = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
		const userIdForEdit = this.userForm.get('id').value
		const role = this.userForm.get('role').value

		if (myActualUser.id === userIdForEdit && (role === 'READONLY' || role === 'NO_COGNITO')) {
			const roleLabel = role === 'READONLY' ? 'Read-Only' : 'Non-Admin'
			this.coreSrvc.notifySrvc.notify('error', 'Not Allowed', `Setting your own user to the ${roleLabel} supervisor type is not allowed.`, 5)
			return
		}

		const record = this.makeUpdateRecord()
		log('Record to submit', record)
		if (record) {
			if (this.isNew) {
				log('Adding new user', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.insertRecord('users', record).then((success) => {
					if (success) {
						this.saveActionComplete.emit(true)
					} else {
						this.isUpdating = false
					}
				})
			} else {
				log('Updating record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.updateRecord('users', record).then((success) => {
					if (success) {
						this.saveActionComplete.emit(true)
					} else {
						this.isUpdating = false
					}
				})
			}
		}
	}

	public cancel() {
		this.closeDialog.emit(true)
	}

	private setupRoleDropdown() {
		const isManagerRoleEnabled = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs().userEnableManagerRole
		this.roleDropdown = [
			{ label: 'Select Type', value: null },
			{ label: 'Primary Supervisor', value: 'PRIMARY' },
			{ label: 'Managing Supervisor', value: 'MANAGER' },
			{ label: 'Secondary Supervisor', value: 'SECONDARY' },
			{ label: 'Non-Admin Supervisor', value: 'NO_COGNITO' },
			{ label: 'Read-Only Supervisor', value: 'READONLY' },
		]

		const isManager = this.coreSrvc.dbSrvc.settingSrvc.isUserAManager()
		const myUser = this.coreSrvc.dbSrvc.settingSrvc.getMyUser()
		const myUserRole = myUser.role
		const thisRecordsUserRole = this.user.role

		log('User role for record', thisRecordsUserRole)

		// Remove manager role if not enabled and target user isn't a manager
		if (thisRecordsUserRole !== 'MANAGER') {
			if (!isManagerRoleEnabled) {
				this.roleDropdown = this.roleDropdown.filter((rdd) => rdd.value !== 'MANAGER')
			}
		}

		// Setup Role Permissions dropdown
		this.rolePermissionsDropdown = this.coreSrvc.dbSrvc.settingSrvc.getUserRolesDropdownData(true, 'Set Permissions Manually')
	}

	private setupManagerDropdown() {
		const fitleredUsers = this.coreSrvc.dbSrvc.settingSrvc.getUsers().filter((u) => u.role === 'MANAGER')
		log('Filtered Users', fitleredUsers)
		const dropdown = fitleredUsers.map((user) => {
			return {
				label: user.first_name + ' ' + user.last_name,
				value: user.id,
			}
		})
		const sortedDropdown = _.sortBy(dropdown, 'label')
		sortedDropdown.unshift({ label: 'Select a manager', value: null })
		this.managerDropdown = sortedDropdown
	}

	public shouldDisplayManagedByDropdown(): boolean {
		// Don't show dropdown if manager role is not available
		const isManagerRoleEnabled = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs().userEnableManagerRole
		if (!isManagerRoleEnabled) return false

		const myUser = this.coreSrvc.dbSrvc.settingSrvc.getMyUser()
		const myUserRole = myUser.role

		// Don't show the dropdwon if the user editing is not primary or internal
		if (myUserRole !== 'PRIMARY' && myUserRole !== 'INTERNAL') return false

		// Don't show the dropdwon unless the user being edited is secondary, no cognito, or readonly and there
		// is at least one manager to choose from
		const currentRole = this.userForm.get('role').value
		const displayForRole = currentRole === 'SECONDARY' || currentRole === 'NO_COGNITO' || currentRole === 'READONLY'
		if (displayForRole && this.managerDropdown.length > 1) return true

		// The default is to not show the dropdown
		return false
	}

	// Allowed Companies

	private setupAllowedCompaniesDropdown() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const globalId = company?.global_id
		const user = this.user
		const userRole = user.role
		const myRole = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser().role

		// Add conditions to exit setup of the allowed companies menu before the list is populated.
		// If the allowed companies list is left empty, back end will not make any membership changes.
		// this.allowedCompanies.selected is initialized as empty array which means no changes take place.

		// If this company doesn't have multiple companies I can't change allowed companies
		if (globalId === 0) {
			this.allowedCompanies.dropdownVisible = false
		}

		// If I'm not INTERNAL or PRIMARY I can't set companies for any user
		if (!(myRole === 'INTERNAL' || myRole === 'PRIMARY')) {
			this.allowedCompanies.dropdownVisible = false
		}

		// If the user I'm editing is INTERNAL, I cannot change them but shouldn't see them in the list anyway
		if (userRole === 'INTERNAL') {
			this.allowedCompanies.dropdownVisible = false
		}

		// If the user I'm editing is a PRIMARY, I can only change their companies if I'm INTERNAL
		if (userRole === 'PRIMARY' && myRole !== 'INTERNAL') {
			this.allowedCompanies.dropdownVisible = false
		}

		// Conditions for setting up assignment cleared so make menu visible and setup selected companies
		// this.allowedCompanies.dropdownVisible = true

		// Setup Selected Ids
		const selectedIds = user ? user.allowed_companies : []
		this.allowedCompanies.selected = selectedIds.length === 0 ? [company.id] : selectedIds

		// Setup the list of allowed companies to assign membership
		const companies = this.coreSrvc.dbSrvc.settingSrvc.getGlobalCompanyList().filter((c) => c.global_id === globalId)
		const sortedCompanies = _.sortBy(companies, 'name')
		this.allowedCompanies.menuOptions = sortedCompanies.map((c) => ({ label: c.name + ` (${c.id})`, value: c.id }))

		log('Allowed Companies', companies)
	}

	setupLinkedEmployeeDropdown() {
		this.linkedEmployeeDropdown = this.coreSrvc.dbSrvc.empSrvc.getDropdownData()
	}

	// Phone Number Methods

	setupPhoneNumber() {
		const phoneNumber = this.user.phone_e164
		if (phoneNumber) {
			const parsedNumber = parseNumber(phoneNumber) as ParsedNumber
			const dialingCode = parsedNumber.country
			this.dialingCode = dialingCode
			this.phoneNumber = phoneNumber
		}
	}

	formatNumber() {
		const countryCode: CountryCode = this.dialingCode
		const number = this.phoneNumber

		if (number && number.length > 3 && isValidNumberForRegion(number, countryCode)) {
			const parsedNumber = parseNumber(number, countryCode) as ParsedNumber
			this.isPhoneNumberValid = true
			this.phoneNumber = formatNumber(parsedNumber, 'NATIONAL')
		} else {
			this.isPhoneNumberValid = false
		}
	}

	isPhoneValid(): boolean {
		if (!this.phoneNumber) {
			return false
		}
		if (isValidNumberForRegion(this.phoneNumber, this.dialingCode)) {
			return true
		} else {
			return false
		}
	}

	makeUpdatePhone(): string {
		const parsedNumber = parseNumber(this.phoneNumber, this.dialingCode) as ParsedNumber
		if (isValidNumberForRegion(this.phoneNumber, this.dialingCode)) {
			return formatNumber(parsedNumber, 'E.164')
		} else {
			alert('The phone number you entered is not valid for the region selected.')
			return null
		}
	}

	makeUpdateRecord(): Object {
		const form = this.userForm.value
		const record = new UserRecord(form)
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()

		record.phone_e164 = this.makeUpdatePhone()
		record.c2c_phone_e164 = this.outboundC2CPhoneManager.e164

		// If no phone provided return null to stop submission
		if (!record.phone_e164) {
			return null
		}

		// Add the role template permissions if this is a new record or the role has changed
		// and the user indicates they wish to apply permissions
		if ((this.isNew || this.hasRoleChanged) && this.applyRolePermissions) {
			if (record.role === 'MANAGER') {
				record.permissions_json = AccessRole.getRole(AccessRoleType.manager)
			}
			if (record.role === 'SECONDARY' || record.role === 'NO_COGNITO') {
				record.permissions_json = AccessRole.getRole(AccessRoleType.secondary)
			}
			if (record.role === 'READONLY') {
				record.permissions_json = AccessRole.getRole(AccessRoleType.readOnly)
			}
		}

		// Clear role permissions template and role permissions if the user is a primary or non-admin user
		if (record.role === 'PRIMARY' || record.role === 'NO_COGNITO') {
			record.supervisor_role_id = null
			record.permissions_json = null
		}

		record.allowed_companies = this.allowedCompanies.getUpdateList(company)

		// If the user is a manager, clear the managed_by field
		if (record.role === 'MANAGER') {
			record.managed_by = null
		}

		// Check for the change notification bypass option
		if (this.bypasChangesNotifications) {
			record['bypass_change_notification'] = true
		}

		return record
	}

	roleDescription(): string {
		if (this.isNew && this.isAdminPrimaryOrInternal && this.applyRolePermissions) {
			const role = this.userForm.get('role').value
			switch (role) {
				case 'PRIMARY':
					return null
				case 'MANAGER':
					return `Manager permissions allow this user to access records they create along with the records of any supervisors they manage.`
				case 'SECONDARY':
					return `Secondary supervisor permissions allow this user to access records they create and this user may be assigned to a manager.`
				case 'NO_COGNITO':
					return `Non-Admin supervisor permissions may be used for creating reports by assigning this user ownership over employees, job sites, and jobs. This user may be assigned to a manager but they cannot log in to the admin portal to administer the system.`
				case 'READONLY':
					return `Read-Only supervisor permissions allow this user to view records but not alter them.`
				default:
					return null
			}
		} else {
			return null
		}
	}

	// Misc Methods

	toggleCheckbox(prop: string) {
		log('prop', prop)
		const current = this.userForm.get(prop).value
		this.userForm.get(prop).setValue(!current)
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		const applyRolePermissionsCopy = this.isNew
			? `When checked, permissions will be applied to restrict this user based on the role selected. When not checked, the user will have full access to the system. These permissions can be adjusted later by the primary account holder.`
			: `When checked, permissions will be applied to restrict this user based on the role selected. When not checked, the user will retain their current permissions. These permissions can be adjusted later by the primary account holder.`
		switch (trigger) {
			case 'bypassChangeNotification':
				help.header = 'Bypass Notifications'
				help.message = `When checked, changes to the user's role or phone number will not trigger a notification to the user.`
				break
			case 'enableNotifications':
				help.header = 'Relay Text Messages'
				help.message = `When checked, the system will forward employee text messages to this supervisor's email inbox.`
				break
			case 'time_off_enabled':
				help.header = 'Time Off Requests'
				help.message =
					'When checked, the system will notify this supervisor when an employee submits a new time off request or when the approval status of a time off entry changes.'
				break
			case 'max_daily_enabled':
				help.header = 'Max Daily Hours'
				help.message =
					'When checked, the system will notify this supervisor when an employee works longer than the maximum daily hours threshold company setting.'
				break
			case 'schedule_offer_enabled':
				help.header = 'Open Shift Offers'
				help.message = 'When checked, the system will notify this supervisor when an employee submits a response to an open shift offer.'
				break
			case 'date_lock_enabled':
				help.header = 'Date Locking'
				help.message =
					'When checked, the system will notify this supervisor when a time entry is added, deleted, or modified on or before the time entry date lock, if a date lock has been setup.'
				break
			case 'role':
				help.header = 'Supervisor Type'
				help.message =
					'The supervisor type configures certain aspects of the system. A primary supervisor is not restricted. A secondary supervisor can have access permissions configured. A manager is a secondary supervisor who can manage other supervisors. Please contact support for assistance if you need to help managing access for your supervisors.'
				break
			case 'supervisor_role_id':
				help.header = 'Permisssions Template'
				help.message =
					'A permissions template allows you to configre system access permissions in the template and apply that template to multiple supervisors. When the template is modified, any supervisor assigned that template will inherit those permissions. If you want to congigure permissions for a user manually, do not assign a template.'
				break
			case 'applyRolePermissions':
				help.header = 'Apply Permissions'
				help.message = applyRolePermissionsCopy
				break
			case 'outboundC2C':
				help.header = 'Dispatcher Phone'
				help.message =
					'Enter the number of the mobile or land line phone that will be used for outbound calls when a user clicks on a click-to-call phone number in the administrative portal.  If no number is specified, the user’s mobile phone will be used for outbound click-to-call.'
				break
			case 'linked_employee_id':
				help.header = 'Linked Employee'
				help.message =
					'This option allows you to link an employee record to this supervisor to indicate they are the same person. This is used when supervisors also use the system to check in and out of jobs.'
				break
			case 'edit_own_trans':
				help.header = 'Time Entries'
				help.message =
					'This option allows you to indicate whether this supervisor can edit their own time entries generated by the employee they are linked to above.'
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
