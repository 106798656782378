import { Injectable } from '@angular/core'
import { log } from '@app/helpers'
import { ClickToCallDialogManager, ClickToCallGlobalConfig, ClickToCallRecord, C2CEventSubmittedEvent } from '@app/models/click-to-call'
import { Global } from '@app/models/global'
import { Subject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class CallService {
	c2cManager = new ClickToCallDialogManager()

	c2cEventSubmitted = new Subject<C2CEventSubmittedEvent>()

	constructor() {
		log('Creating CallService')
	}

	public startC2CEventForEmpId(id: number, action: 'CALL' | 'TEXT', smsPlaceholder: string, initialMsg: string) {
		const emp = Global.coreSrvc.dbSrvc.empSrvc.getEmployeeById(id)
		if (emp) {
			const name = emp.first + ' ' + emp.last
			const number = emp.phone_number_e164
			const c2cRecord = new ClickToCallRecord('EMPLOYEEPHONE', name, null, number, null)
			const config = new ClickToCallGlobalConfig(c2cRecord, action, smsPlaceholder, initialMsg)
			Global.coreSrvc.eventSrvc.clickToCallGlobalEvent.next(config)
		}
	}

	public getEnabledStatus() {
		const company = Global.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const portalPrefs = Global.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany()

		// Setup Click to Call availability
		const backendEnabled = Global.coreSrvc.dbSrvc.settingSrvc.isClickToCallEnabled()
		const browserEnabled = portalPrefs.globalBrowserClickToCall

		return { backend: backendEnabled, browser: browserEnabled }
	}

	// When using C2C trigger icons in the shift actions Dialog, we hide it using this
	hideC2CTriggerHost() {
		const dialog = $('.c2c-trigger-host')[0]
		if (dialog) {
			dialog.hidden = true
			$('body').addClass('p-dialog-mask-reset') // Can be used to keep mask at same alphs
		}
	}

	// After performing or cancelling a C2C triggered action we should the shift actions dialog if it was hidden
	showC2CTriggerHost() {
		const dialog = $('.c2c-trigger-host')[0]
		if (dialog) {
			dialog.hidden = false
			$('body').removeClass('p-dialog-mask-reset') // Can be used to keep mask at same alpha
		}
	}

	public openEmail(dest: ClickToCallRecord) {
		log('Send Email @', dest.email)
	}
}
