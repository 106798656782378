import { Component, OnInit, ViewChild, AfterViewChecked, ChangeDetectorRef, NgZone, AfterViewInit, OnDestroy, ElementRef } from '@angular/core'

import {
	CrudAction,
	ScheduleViewManager,
	DialogManager,
	ScheduleEntry,
	ScheduleListItemEntry,
	ScheduleStatusFilter,
	ScheduleStatusType,
	SectionSwitcherConfig,
	SectionSwitcherTab,
	NewShiftRangeSelectInfo,
	ScheduleEditActionSelectedEntry,
	NewScheduleType,
} from '@app/models'

import { CoreService, NotificationsService } from '@app/services'

import { log, DateTimeHelper } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'

import { SchedulerListComponent } from './series-view/list.component'
import { TimeOffTableComponent } from './time-off-view/time-off-table/time-off-table.component'
import { SchedulerShiftViewComponent } from './shift-view/scheduler-shift-view.component'

import { Subscription } from 'rxjs'
import { DeviceDetectorService } from 'ngx-device-detector'

import _ from 'lodash'

@Component({
    selector: 'app-scheduler',
    templateUrl: './scheduler.component.html',
    styleUrls: ['./scheduler.component.scss'],
    standalone: false
})
export class SchedulerComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
	CrudAction = CrudAction
	accessHelper: AccessHelper

	switcherConfig = new SectionSwitcherConfig()

	// selectedEntry is { empName: null, jobName: null, entry: null, rule: null }
	editAction = { isNew: false, isCopy: false, source: null, selectedEntry: null, defaultJobId: null }
	editDialogManager = new DialogManager('seriesEditManager', 'scheduleSeriesEditDialog')

	// dayViewDate: Date
	isGlobalAccount = false
	isProcessingDayViewSchedules = false
	arePendingSchedulesEnabled = false

	showMultScheduleOption = false
	showBatchEditIcon = false

	wkst: number = null
	showTooltips = false

	manager: ScheduleViewManager

	// currentSearch = ''
	debounceSearch = _.debounce(this.searchListFromDebounce, 250)
	// canUseStatusFilter = true
	// statusFilter = new ScheduleStatusFilter()
	get statusFilter(): ScheduleStatusFilter {
		return this.coreSrvc.dbSrvc.schedulerSrvc.statusFilter
	}

	filter = {
		showExpiredScheduleFilter: true,
	}

	// needsViewCheck = false
	@ViewChild('searchInput') searchInput: ElementRef
	@ViewChild('seriesViewComponent', { static: true }) seriesViewComponent: SchedulerListComponent
	@ViewChild('shiftViewComponent', { static: false }) shiftViewComponent: SchedulerShiftViewComponent
	@ViewChild('timeOffTable', { static: false }) timeOffTable: TimeOffTableComponent

	subs = new Subscription()

	constructor(
		private cd: ChangeDetectorRef,
		private zone: NgZone,
		private coreSrvc: CoreService,
		private noteSrvc: NotificationsService,
	) {
		const userPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		this.arePendingSchedulesEnabled = userPrefs.schedulerEnablePendingQueue
		this.showBatchEditIcon = userPrefs.schedulerEnableBatchEdit

		this.manager = this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager
		this.isGlobalAccount = this.coreSrvc.dbSrvc.settingSrvc.isGlobalAccount()

		this.setupAccessPermissions()
		this.setupTabSwitcher()
		this.setupSubscriptions()
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		this.wkst = company.wkst
		// if (this.coreSrvc.dbSrvc.devDetect.isMobile() && !this.coreSrvc.dbSrvc.schedulerSrvc.currentDayViewDate) {
		// 	this.coreSrvc.dbSrvc.schedulerSrvc.currentDayViewDate = new Date()
		// }
		this.subs.add(this.coreSrvc.dbSrvc.lambdaSrvc.dataAccessErrorEvent.subscribe((event) => this.handleDataAccessErrorEvent(event)))
		this.subs.add(this.coreSrvc.dbSrvc.schedulerSrvc.checkMultiScheduleStatus.subscribe(() => this.handleCheckForMultiScheduleStatus()))

		// Add listener for drag to select creation of new one off shifts
		this.coreSrvc.dbSrvc.schedulerSrvc.createNewShiftEvent.subscribe((type) => this.handleCreateRecordEvent(type))

		// Add listener for series section change for pending banner
		this.subs.add(
			this.manager.scheduleViewSectionChanged.subscribe((section) => {
				this.switcherConfig.setTab(section)
				if (section === 'SERIES') {
					this.toggleSeriesView()
				}
				if (section === 'SHIFT') {
					this.toggleShiftView()
				}
				if (section === 'TIMEOFF') {
					this.toggleTimeOff()
				}
			}),
		)

		this.subs.add(
			this.manager.scheduleViewDateRangeChanged.subscribe((direction) => {
				if (direction === 'PREV') {
					this.previousDay()
				}
				if (direction === 'NEXT') {
					this.nextDay()
				}
			}),
		)

		this.filter.showExpiredScheduleFilter = !this.coreSrvc.dbSrvc.w2wSrvc.isW2WIntegrated()
	}

	get createRecordLabel(): string {
		switch (this.manager.currentView) {
			case 'SERIES':
				return 'Add Schedule'
			case 'SHIFT':
				return 'Add Shift'
			case 'TIMEOFF':
				return 'Add Time-Off'
			default:
				return 'Add Record'
		}
	}

	get searchPlaceholder(): string {
		if (this.manager.currentView === 'SERIES') {
			if (this.statusFilter.active) {
				if (this.statusFilter.showEmpCount) return 'filter by employee count'
			}
			return 'filter schedules'
		}
		return 'filter shifts'
	}

	get subSectionTitle(): string {
		const currentView = this.manager.currentView
		switch (currentView) {
			case 'SERIES':
				return ''
			case 'SHIFT':
				return 'Shifts'
			case 'TIMEOFF':
				return 'Time Off'
		}
	}

	get batchCount(): number {
		return this.coreSrvc.dbSrvc.schedulerSrvc.scheduleBatchUpdateList.length
	}

	get isViewingPending(): boolean {
		return this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.series.subSection === 'PENDING'
	}

	get isViewingChanges(): boolean {
		const currentView = this.coreSrvc.prefSrvc.data.schedDefaultListView
		return currentView === 'CHANGES'
	}

	get isViewingShifts(): boolean {
		const currentView = this.coreSrvc.prefSrvc.data.schedDefaultListView
		return currentView === 'SHIFT'
	}

	get devDetect(): DeviceDetectorService {
		return this.coreSrvc.devDetect
	}

	get dayViewDate(): Date {
		// REMOVE AND POINT TO currentDayViewDate
		return this.coreSrvc.dbSrvc.schedulerSrvc.currentDayViewDate
	}

	set dayViewDate(date: Date) {
		// REMOVE AND POINT TO currentDayViewDate
		this.coreSrvc.dbSrvc.schedulerSrvc.currentDayViewDate = date
	}

	get currentDayViewDate(): Date {
		return this.coreSrvc.dbSrvc.schedulerSrvc.currentDayViewDate
	}

	set currentDayViewDate(date: Date) {
		this.coreSrvc.dbSrvc.schedulerSrvc.currentDayViewDate = date
	}

	get tzAbrev(): string {
		return this.shiftViewComponent?.currentTzAbrev ?? 'UTC'
	}

	get isMultiDayJobEnabled(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.getCompany().useMultiDay
	}

	searchListFromDebounce(event) {
		const text = event?.target?.value || null
		this.searchList(text)
		this.seriesViewComponent.filterForStatusView(this.statusFilter)
	}

	searchList(searchText) {
		this.manager.currentSearchText = searchText
		const filterString = (searchText as string) || ''
		const lcFilterString = filterString.toLowerCase()

		if (this.manager.currentView === 'SERIES') {
			this.manager.series.searchText = searchText
			this.seriesViewComponent?.searchList(lcFilterString)
		}

		if (this.manager.currentView === 'SHIFT') {
			this.manager.shift.searchText = searchText
			this.shiftViewComponent?.searchEvents(lcFilterString)
		}
	}

	public showScheduleStatusFor(status: ScheduleStatusType) {
		if (status === 'BATCHED') {
			this.manager.currentSearchText = ''
		}
		this.statusFilter.setFilterStatus(status)
		this.searchList(this.manager.currentSearchText)
		this.seriesViewComponent.filterForStatusView(this.statusFilter)
	}

	// DEPRECATED 20240811 - No longer used
	// public toggleScheduleStatusFor(status: ScheduleStatusType) {
	// 	this.statusFilter.toggleStatus(status)
	// 	this.seriesViewComponent.filterForStatusView(this.statusFilter)
	// }

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'schedule')
	}

	canPerformAction(action: CrudAction, isMyRecord: boolean): boolean {
		return this.accessHelper.canPerformAction(action, isMyRecord)
	}

	setupSubscriptions() {
		this.subs.add(
			this.coreSrvc.dbSrvc.schedulerSrvc.scheduleRecurEditRecordId.subscribe((id) => {
				this.editRecord(id)
			}),
		)
		this.subs.add(
			this.coreSrvc.dbSrvc.schedulerSrvc.scheduleRecurCopyRecordId.subscribe((id) => {
				this.copyRecord(id)
			}),
		)
	}

	hideDtTimePicker() {
		$('body').addClass('hide-dt-time-picker')
	}
	showDtTimePicker() {
		$('body').removeClass('hide-dt-time-picker')
	}

	ngOnInit() {
		this.updateUI()
	}

	ngAfterViewInit() {
		this.searchInput.nativeElement.value = this.manager.currentSearchText

		this.subs.add(
			this.coreSrvc.dbSrvc.schedulerSrvc.dayViewProcessingComplete.subscribe(() => {
				log('Finished Processing')
				this.isProcessingDayViewSchedules = false
				this.coreSrvc.displaySrvc.stopSectionLoader()
			}),
		)
	}

	ngAfterViewChecked() {}
	ngOnDestroy() {
		this.subs.unsubscribe()
		// REF-STATUSFILTER
		// this.statusFilter.setFilterStatus('ALL')
	}

	// Begin Tab Swticher Methods
	private setupTabSwitcher() {
		const schedulesTab = new SectionSwitcherTab('SERIES', 'Schedules')
		schedulesTab.count = () => 0
		const shiftsTab = new SectionSwitcherTab('SHIFT', 'Shifts')
		shiftsTab.count = () => 0
		const timeOffTab = new SectionSwitcherTab('TIMEOFF', 'Time Off')
		timeOffTab.count = () => 0
		const config = new SectionSwitcherConfig([schedulesTab, shiftsTab, timeOffTab])
		this.switcherConfig = config
		this.switcherConfig.setInitialTabById(this.manager.currentView)
	}

	public sectionTabClicked(tab: SectionSwitcherTab) {
		switch (tab.id) {
			case 'SERIES':
				this.toggleSeriesView()
				break
			case 'SHIFT':
				this.toggleShiftView()
				break
			case 'TIMEOFF':
				this.toggleTimeOff()
				break
		}
		this.coreSrvc.notifySrvc.navBarNeedsUpdate.next(true)
	}
	// End Tab Switcher Methods

	private handleDataAccessErrorEvent(event) {
		// log('Schedule got overlap exception', event)
		const type = event?.errorType
		if (type === 'com.sst.ivr.lambda.exceptions.ScheduleChangeOverlapException') {
			const errorMsg = event?.errorMessage
			const schedRecurIds = JSON.parse(errorMsg) ?? []
			log('Got overlap exception for', schedRecurIds)
			for (const [idx, id] of schedRecurIds.entries()) {
				if (idx > 2) {
					break
				}
				const schedRecur = this.coreSrvc.dbSrvc.schedulerSrvc.getScheduleForId(id)
				if (schedRecur) {
					const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(schedRecur.job_id)
					if (job) {
						this.coreSrvc.notifySrvc.notify('error', 'Schedule Conflict', `Conflict found with schedule for ${job.description}`)
					}
				}
			}
		}
	}

	private handleCheckForMultiScheduleStatus() {
		const userPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		const userPrefShowIcon = userPrefs.schedulerEnableMultiSchedule
		const hasMultiSchedules = this.coreSrvc.dbSrvc.schedulerSrvc.getSchedules().filter((sched) => sched.employee_id === 1).length > 0
		this.showMultScheduleOption = userPrefShowIcon || hasMultiSchedules
	}

	updateUI() {}

	refreshBtnClicked() {
		log('CURRENT VIEW', this.manager.currentView)
		switch (this.manager.currentView) {
			case 'SERIES':
			case 'SHIFT':
				this.seriesViewComponent.loadData()
				setTimeout(() => {
					this.coreSrvc.dbSrvc.readTable('open_shifts')
				}, 800)
				break
			case 'TIMEOFF':
				this.timeOffTable.fetchAndReload()
				break
		}
	}

	schedCount(): number {
		return this.coreSrvc.dbSrvc.schedulerSrvc.count()
	}

	lastUpdated(): string {
		const date = this.coreSrvc.dbSrvc.schedulerSrvc.lastUpdated
		return `Updated ${DateTimeHelper.formatTimeFromDate(date)}`
	}

	setWeekStart(event) {
		const value = event?.target?.value || null
		log('Setting Week Start', value)
		const newValue = parseInt(value, 10) || null
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		company.wkst = newValue
		this.coreSrvc.dbSrvc.updateRecord('company', company).then((result) => {
			this.noteSrvc.notify('success', 'Settings Updated', 'Your settings have been saved.')
			this.wkst = newValue
			this.seriesViewComponent.arrangeDayOptionsForWkst()
		})
	}

	startProcessingSchedules() {
		this.isProcessingDayViewSchedules = true
		this.cd.detectChanges()
	}

	toggleDayView() {
		this.startProcessingSchedules()
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			setTimeout(() => {
				this.seriesViewComponent.toggleDayView()
				this.shiftViewComponent?.setDayViewDate(this.currentDayViewDate)
				// this.dayViewDate = this.scheduleList.currentDayViewDate
			}, 200)
		})
	}

	previousDay() {
		this.startProcessingSchedules()
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			setTimeout(() => {
				this.seriesViewComponent.previousDay()
				if (this.currentDayViewDate) {
					this.shiftViewComponent?.setDayViewDate(this.currentDayViewDate)
					this.timeOffTable?.updateTable()
				} else {
					this.shiftViewComponent?.previousWeek()
				}
			}, 200)
		})
	}

	nextDay() {
		this.startProcessingSchedules()
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			setTimeout(() => {
				this.seriesViewComponent.nextDay()
				if (this.currentDayViewDate) {
					this.shiftViewComponent?.setDayViewDate(this.currentDayViewDate)
					this.timeOffTable?.updateTable()
				} else {
					this.shiftViewComponent?.nextWeek()
				}
			}, 200)
		})
	}

	dayViewPickerClosed() {
		log('Day view picker closed')
		if (this.dayViewDate) {
			this.startProcessingSchedules()
			setTimeout(() => {
				this.currentDayViewDate = this.dayViewDate
				this.shiftViewComponent?.setDayViewDate(this.dayViewDate)
				this.seriesViewComponent?.filterForDayViewDate(this.dayViewDate)
				this.timeOffTable?.updateTable()
			}, 200)
		}
	}

	togglePreferences(): void {
		if (this.manager.currentView === 'SHIFT') {
			this.shiftViewComponent.sectionPrefsDialogManager.isDialogVisible = true
			return
		}
		if (this.manager.currentView === 'TIMEOFF') {
			this.timeOffTable.sectionPrefsDialogManager.isDialogVisible = true
			return
		}

		alert('There are currently no modifiable settings for this section.')
	}

	toggleTooltips() {
		this.showTooltips = !this.showTooltips
	}

	showNoDataTip(): boolean {
		if (this.schedCount() === 0) {
			return true
		}
		return false
	}

	createRecord(type: 'SINGLE' | 'MULTI') {
		if (this.manager.currentView === 'SERIES') {
			this.coreSrvc.dbSrvc.schedulerSrvc.createNewShiftEvent.next(type)
		}

		if (this.manager.currentView === 'SHIFT') {
			const empIds = this.manager.shift.selectedEmpIds
			const empId = empIds.length === 1 ? empIds[0] : null
			const jobIds = this.manager.shift.selectedJobIds
			const jobId = jobIds.length === 1 ? jobIds[0] : null
			this.coreSrvc.dbSrvc.schedulerSrvc.newShiftRangeSelectInfo = new NewShiftRangeSelectInfo(null, empId, jobId)
			this.coreSrvc.dbSrvc.schedulerSrvc.createNewShiftEvent.next(type)
		}

		if (this.manager.currentView === 'TIMEOFF') {
			log('Create Time Off Record')
			this.timeOffTable.createRecord()
		}
	}

	handleCreateRecordEvent(type: NewScheduleType) {
		this.editAction.defaultJobId = null
		this.editAction.selectedEntry = {
			empName: null,
			jobName: null,
			entry: null,
			rule: null,
			newSchedType: type,
		} as ScheduleEditActionSelectedEntry
		this.editAction.isNew = true
		this.editAction.isCopy = false
		this.editAction.source = this.manager.currentView
		this.editDialogManager.headerLabel =
			this.manager.currentView === 'SHIFT' ? 'Add Shift' : type === 'SINGLE' ? 'Add Schedule' : 'Add Multi-Schedule'
		this.editDialogManager.footerLabel = ''
		this.editDialogManager.isFooterLabelVisible = false
		this.editDialogManager.isDialogVisible = true
	}

	notifyOperationNotAuthorized() {
		this.coreSrvc.notifySrvc.default('operationNotAuthorized')
	}

	toggleSeriesView() {
		this.manager.isSwitchingViews = true
		this.manager.setCurrentView('SERIES')
		const searchText = this.manager.series.searchText
		this.manager.currentSearchText = searchText
		this.searchList(searchText)
		this.manager.isSwitchingViews = false
	}

	toggleShiftView() {
		this.manager.isSwitchingViews = true
		this.manager.setCurrentView('SHIFT')
		const searchText = this.manager.shift.searchText
		this.manager.currentSearchText = searchText
		this.searchList(searchText)
		this.manager.isSwitchingViews = false
	}

	toggleTimeOff() {
		this.manager.isSwitchingViews = true
		this.manager.setCurrentView('TIMEOFF')
		this.manager.isSwitchingViews = false
	}

	canEditRecord(entry: ScheduleEntry): boolean {
		// MIGRATED FROM CHILD
		const empId = entry.employee_id
		const jobId = entry.job_id
		const isMyRecord = empId === 0 ? this.accessHelper.isMyRecord(jobId, 'job') : this.accessHelper.isMyRecord(empId, 'employee')
		return this.canPerformAction(CrudAction.update, isMyRecord)
	}

	editRecord(id: number) {
		// MIGRATED FROM CHILD
		const schedEntry = this.coreSrvc.dbSrvc.schedulerSrvc.getScheduleForId(id)
		if (!this.canEditRecord(schedEntry)) {
			this.notifyOperationNotAuthorized()
			return
		}
		const empName = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(schedEntry.employee_id)?.name || 'Employee Not Found'
		const jobName = this.coreSrvc.dbSrvc.jobSrvc.getJobById(schedEntry.job_id)?.description || 'Job Not Found'
		this.editDialogManager.headerLabel = empName
		this.editDialogManager.footerLabel = ''
		this.editDialogManager.isFooterLabelVisible = false
		this.editAction.isNew = false
		this.editAction.isCopy = false
		this.editAction.selectedEntry = {
			empName: empName,
			jobName: jobName,
			entry: schedEntry,
			rule: null,
			newSchedType: null,
		} as ScheduleEditActionSelectedEntry
		log('editAction', this.editAction)
		this.editDialogManager.isDialogVisible = true
	}

	copyRecord(id: number) {
		const schedEntry = this.coreSrvc.dbSrvc.schedulerSrvc.getScheduleForId(id).clone()
		log('Existing Entry', schedEntry)
		if (!this.canEditRecord(schedEntry)) {
			this.notifyOperationNotAuthorized()
			return
		}
		const empName = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(schedEntry.employee_id)?.name || 'Employee Not Found'
		const jobName = this.coreSrvc.dbSrvc.jobSrvc.getJobById(schedEntry.job_id)?.description || 'Job Not Found'
		this.editDialogManager.headerLabel = `${empName} ( copy )`
		this.editDialogManager.isFooterLabelVisible = true
		this.editAction.isNew = false
		this.editAction.isCopy = true
		this.editAction.selectedEntry = {
			empName: empName,
			jobName: jobName,
			entry: schedEntry,
			rule: null,
		} as ScheduleListItemEntry
		log('editAction', this.editAction)
		this.editDialogManager.isDialogVisible = true
	}

	scheduleActionComplete() {
		log('Schedule Action Complete')
		this.seriesViewComponent.scheduleActionComplete()
		this.editDialogManager.isDialogVisible = false
		this.coreSrvc.dbSrvc.readTable('open_shifts')
	}

	exitDayView() {
		log('Exit Day View')
	}

	public toggleBatchMode(): void {
		const isBatchingEnabled = this.manager.series.isBatchingEnabled
		if (isBatchingEnabled) {
			this.manager.series.isBatchingEnabled = false
			this.coreSrvc.dbSrvc.schedulerSrvc.clearBatchUpdateList()
			this.showScheduleStatusFor('ALL')
		} else {
			this.manager.series.isBatchingEnabled = true
		}
	}
}
