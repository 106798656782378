<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<form class="edit-form-block" novalidate [formGroup]="groupForm" (keydown.enter)="$event.preventDefault()" autocomplete="off">
		<div class="options-block" [class.input-required]="!groupForm.get('description').value">
			<div class="options-title-inline">Name</div>
			<div *ngIf="!groupForm.get('description').value" class="float-right">(required)</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="description" />
		</div>

		<div class="options-block">
			<!-- <div *ngIf="!userForm.get('role').value" class="input-required float-right">(required)</div> -->
			<div class="options-title">Linked Supervisors</div>
			<p-multiselect
				appScrollLockMultiSelect
				placeholder="Select Supervisors"
				[maxSelectedLabels]="0"
				selectedItemsLabel="Supervisors Selected: {0}"
				[options]="supervisorOptions"
				formControlName="supervisor_group_users"
			>
			</p-multiselect>
		</div>

		<div class="options-block">
			<div class="options-title-inline">External ID</div>
			<!-- <div *ngIf="!groupForm.get('external_id').value" class="input-required float-right">(required)</div> -->
			<input type="text" class="form-control" autocomplete="off" formControlName="external_id" />
		</div>

		<div class="options-block">
			<div class="options-title-inline">Details</div>
			<!-- <div *ngIf="!groupForm.get('details').value" class="input-required float-right">(required)</div> -->
			<input type="text" class="form-control" autocomplete="off" formControlName="details" />
		</div>
	</form>
</div>
