<div>
	<div *ngIf="manager.showHeader" class="tts-hl-2" style="margin-bottom: 18px"><strong>Setup Linked Phone Numbers</strong></div>
	<!-- <div class="options-note-sm" style="margin-bottom: 25px;">Caller ID is used to help determine which jobs to present when an employee calls into the system. By linking phone numbers at this location you can streamline the check in and checkout process for your employees.</div> -->

	<!-- Form checkbox for landline numbers - moved to parent-->
	<div *ngIf="false" class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="manager.linkLandlineCheckbox" [binary]="true" (onChange)="manager.landlineCheckboxChanged()"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong (click)="manager.linkLandlineCheckbox = !manager.linkLandlineCheckbox; manager.landlineCheckboxChanged()" class="has-pointer"
				>Link job site to landline numbers</strong
			>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelpEvent('linkLandlineCheckbox')"></i>
		</div>
	</div>

	<!-- Form checkbox for employee mobile numbers - deprecated -->
	<div *ngIf="false" class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="manager.linkEmpMobileCheckbox" [binary]="true" (onChange)="manager.empMobileCheckboxChanged()"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<div
				class="checkbox-label-text has-pointer"
				(click)="manager.linkEmpMobileCheckbox = !manager.linkEmpMobileCheckbox; manager.empMobileCheckboxChanged()"
			>
				Link job site to<br />employee mobile numbers
			</div>
			<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelpEvent('linkMobileCheckbox')"></i> -->
		</div>
	</div>

	<!-- <pre><code>{{ manager.phoneEntries | json }}</code></pre> -->

	<!-- Form block for adding landline numbers -->
	<div *ngIf="manager.linkLandlineCheckbox" id="landline-block">
		<div *ngIf="manager.phoneEntries.length > 0" class="form-group" style="margin-top: 25px">
			<label class="form-control-label"> Current Landline Phone Numbers </label>
			<br />
			<div class="btn btn-sm btn-block btn-phone" *ngFor="let entry of manager.phoneEntries; let idx = index">
				<div>
					{{ entry.number | displayPhone }}
					<i (click)="manager.onRemoveNumber(idx)" class="fa fa-times float-right btn-phone-x" aria-hidden="true"></i>
				</div>
				<div *ngIf="manager.isEmployee(idx)" class="text-muted">
					<small class="emp-name">{{ entry.name }}</small>
				</div>
			</div>
		</div>

		<div class="input-label" style="margin-top: 25px">
			<strong>Link Landline Phone Number</strong>
			<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelpEvent('landlineNumbers')"></i> -->
			<a class="float-right has-pointer" (click)="manager.showResetLandlineNumbersDialog = true">
				<i class="fa fa-undo multi-select-undo-btn"></i>
			</a>
		</div>

		<div class="form-group">
			<table width="100%">
				<tr>
					<td style="width: 70px">
						<select
							#dialingCodeMenu
							class="form-control phone-country-select"
							[(ngModel)]="manager.countryCode"
							(change)="manager.formatPhone(newNumber)"
						>
							<option *ngFor="let code of manager.countryCodeData" value="{{ code.id }}">{{ code.id }}</option>
						</select>
					</td>
					<td>&nbsp;&nbsp;</td>
					<td colspan="3" style="max-width: 275px">
						<input
							#newNumber
							[(ngModel)]="manager.newLandlineInput"
							(keyup)="manager.formatPhone(newNumber)"
							(keyup.enter)="manager.onAddNumber()"
							id="newNumber"
							class="form-control"
							[class.is-valid]="manager.isPhoneValid(newNumber.value)"
							type="tel"
							autocomplete="off"
						/>
					</td>
				</tr>
			</table>
			<div style="text-align: center; margin-top: 20px">
				<button
					(click)="manager.onAddNumber()"
					(keyup.enter)="manager.onAddNumber(); dialingCodeMenu.focus(); (false)"
					class="btn btn-sm btn-block btn-success"
					style="margin: 10px 0px 10px 0px"
				>
					Add Number
				</button>
			</div>
		</div>
	</div>

	<!-- Form block for adding employee numbers -->
	<div *ngIf="false">
		<div *ngIf="manager.linkEmpMobileCheckbox">
			<div class="input-label" style="margin-top: 25px">
				<strong>Link Employee Mobile Numbers</strong>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelpEvent('mobileNumbers')"></i>
				<a class="float-right has-pointer" (click)="manager.showResetEmployeesSelectedDialog = true">
					<i class="fa fa-undo multi-select-undo-btn"></i>
				</a>
			</div>

			<div style="margin-bottom: 8px" *ngIf="manager.allowCallsFromEmployeeMobilePhones">
				<p-multiselect
					appScrollLockMultiSelect
					placeholder="Select Employees"
					[maxSelectedLabels]="0"
					selectedItemsLabel="Employees Selected: {0}"
					[options]="manager.employeePhoneNumbers"
					optionLabel="name"
					[(ngModel)]="manager.employeePhoneNumbersSelected"
				></p-multiselect>
			</div>
			<div style="margin-bottom: 20px">
				<div *ngFor="let empSelected of manager.selectedEmployeesList" class="selected-emp">
					<!-- <a [routerLink]="['/admin/employees/edit', empSelected.id]">{{ empSelected.name }}</a> -->
					{{ empSelected.name }}
					<span *ngIf="!empSelected.active" class="inactive-hl">inactive</span>
					<span class="selected-emp-del float-right" (click)="manager.removeEmployeePhone(empSelected)">x</span>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- <hr class="tooltip-divider"> -->

<p-dialog
	header="Undo Changes"
	[(visible)]="manager.showResetLandlineNumbersDialog"
	[modal]="false"
	[style]="{ width: '300px' }"
	[dismissableMask]="true"
>
	If you have made changes and would like to revert back to the initial list click the
	<strong>Revert</strong> button, otherwise click cancel.
	<br />
	<br />
	<button class="btn btn-sm btn-outline-secondary" (click)="manager.showResetLandlineNumbersDialog = false">Cancel</button>
	<button class="btn btn-sm btn-primary float-right" (click)="manager.resetLandlineNumbers()">Revert</button>
</p-dialog>

<p-dialog
	header="Undo Changes"
	[(visible)]="manager.showResetEmployeesSelectedDialog"
	[modal]="false"
	[style]="{ width: '300px' }"
	[dismissableMask]="true"
>
	If you have made changes and would like to revert back to the initial list click the
	<strong>Revert</strong> button, otherwise click cancel.
	<br />
	<br />
	<button class="btn btn-sm btn-outline-secondary" (click)="manager.showResetEmployeesSelectedDialog = false">Cancel</button>
	<button class="btn btn-sm btn-primary float-right" (click)="manager.resetEmployeesSelected()">Revert</button>
</p-dialog>
