import { Injectable, EventEmitter, Output } from '@angular/core'
import { log } from '@app/helpers/logger'
import { NavbarIcon } from '@app/models'
import { Global } from '@app/models/global'

import _ from 'lodash'

@Injectable({
	providedIn: 'root',
})
export class NavbarService {
	icons: Array<NavbarIcon> = []
	@Output() iconClicked = new EventEmitter<NavbarIcon>()

	constructor() {
		log('Creating NavbarService')
	}

	/**
	 * Add navbar icon
	 * @param records: Array of records to ad or update.
	 */

	addIcon(icon: NavbarIcon) {
		this.icons.unshift(icon)
	}
	clearIcons() {
		this.icons = []
	}

	removeIcons(icons: Array<NavbarIcon>) {
		icons.forEach((icon) => {
			this.icons = this.icons.filter((i) => i !== icon)
		})
	}

	iconBtnClicked(icon: NavbarIcon) {
		this.iconClicked.emit(icon)
	}
}
