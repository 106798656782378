<div class="main-block">
	<div class="row">
		<div class="col-12 col-lg-3" [class.list-item-disabled]="!item.isEnabled">
			<div class="job-name">
				<i
					*ngIf="isBatchingEnabled"
					class="far icon-batch"
					[class.fa-square]="!isBatchSelected"
					[class.fa-check-square]="isBatchSelected"
					title="Batch Edit"
					(click)="addToOrRemoveFromBatch()"
				></i>

				<!-- <i class="fa fa-circle" [style]="item.schedColorStyle"></i> -->
				<span (click)="addToOrRemoveFromBatch()" [class.has-pointer]="isBatchingEnabled">
					{{ type === 'EMP' ? item.jobName : item.empName }}</span
				>
				<span *ngIf="item.isIndividualEmpSchedWithInactiveEmp" style="color: chocolate"> (Inactive)</span>
				<span *ngIf="item.empCount && useEmpCounts" class="emp-count">({{ item.empCount }})</span>
				<i
					class="fal fa-copy icon-copy"
					[class.icon-legacy]="item.entry.legacy_entry"
					[class.action-icon-disabled]="!canEditRecord"
					title="Copy Schedule"
					(click)="copyRecord.emit(item)"
				></i>
				<i
					class="far fa-database icon-copy"
					[class.audit-highlight]="item.entry.updated"
					[class.icon-legacy]="item.entry.legacy_entry"
					title="Audit Record"
					(click)="auditRecord.emit(item)"
				></i>

				<!-- <i *ngIf="!currentDayViewDate && item.isEnabled && item.conflicts.length > 0" class="fa fa-exclamation-circle icon-overlap" (click)="showScheduleConflictsBtnClicked()"></i> -->
			</div>
			<div>
				<i class="fa fa-circle" [style]="item.schedColorStyle"></i>
				<span class="emp-list-job-hours" [class.job-hours-anytime]="item.isAnytimeJob" style="display: inline-block">{{
					listItemTimeFormatter()
				}}</span>
			</div>
			<div *ngIf="item.entry.holiday" class="list-item-holiday">Paid Holiday Schedule</div>
			<div class="list-item-break">{{ item.breakInfoSummary }}</div>
			<!-- <div *ngIf="item.entry.description" class="list-item-desc">{{ item.entry.description }}</div> -->
		</div>

		<div class="col-12 col-lg-5" [class.list-item-disabled]="!item.isEnabled">
			<div *ngIf="item.isWeekly" class="weekday-buttons-box">
				<div id="byweekday" class="weekday-buttons">
					<div
						*ngFor="let weekday of dayOptions"
						class="byweekday-option"
						[class.box-highlighter]="listItemIsWeekdaySelected(weekday)"
						[class.box-multiday]="listItemIsMultiDayJob(weekday)"
					>
						{{ weekday.code }}
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-3" [class.list-item-disabled]="!item.isEnabled">
			<div class="next-up-block">
				<div class="job-freq">{{ listItemFreqFormatter() }}</div>
				<div
					class="job-next-up"
					[class.scheduled-today]="(item.isEnabled && item.isUpcomingForToday) || (item.isScheduledForToday && item.isAnytimeJob)"
					[class.schedule-in-progress]="item.isEnabled && item.isInProgress && !item.isAnytimeJob"
					[class.schedule-finished]="item.isEnabled && !item.hasNextDate && !item.isInProgress"
					[class.schedule-calculating]="shouldCalculateNextUp && !item.info.nextUpInfo"
				>
					{{ listItemNextUpFormatter() }}
				</div>
				<div *ngIf="item.childCount > 0" class="list-item-changes">
					<div class="has-pointer" (click)="item.showScheduleChanges = !item.showScheduleChanges">
						<span class="change-copy">{{ item.showScheduleChanges ? 'Hide' : 'Show' }} Changes</span>
						<span class="badge badge-pill badge-dark change-badge">{{ item.childCount }}</span>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 col-lg-1">
			<div class="sched-action-icon-block">
				<div *ngIf="!isBatchingEnabled">
					<i
						class="fa fa-pencil-square-o icon-edit"
						[class.icon-legacy]="item.entry.legacy_entry"
						[class.action-icon-disabled]="!canEditRecord"
						(click)="editRecord.emit(item)"
					></i>
					<i
						class="fa fa-trash-o icon-delete"
						[class.icon-legacy]="item.entry.legacy_entry"
						[class.action-icon-disabled]="!canDeleteRecord"
						(click)="deleteRecord.emit(item)"
					></i>
				</div>
				<div *ngIf="batchCount > 0 && isBatchSelected" class="table-tag" style="background-color: #a19665">BATCH</div>
			</div>
		</div>
	</div>

	<!-- Schedule Changes - DEPRECATED MAYBE -->
	<!-- <div *ngIf="item.showScheduleChanges">
		<app-scheduler-list-changed-items [recordId]="item.entry.id"></app-scheduler-list-changed-items>
	</div> -->

	<div [class.list-item-disabled]="!item.isEnabled && !item.isPending" *ngIf="!item.showScheduleChanges">
		<div *ngIf="item.futureStart || item.futureEnd" class="sched-future-start">
			<div *ngIf="item.futureStart">{{ item.isScheduledOneTime ? 'Scheduled for' : 'Schedule starts' }} {{ item.futureStart }}</div>
			<div *ngIf="!item.isScheduledOneTime && item.futureEnd">Schedule ends {{ item.futureEnd }}</div>
		</div>

		<div *ngIf="item.employeeList.length > 0" class="sched-emp-list">
			<span style="font-weight: bold; color: green">Scheduled</span>&nbsp;<i
				*ngIf="item.entry.recurrence_freq === 'WEEKLY'"
				class="fa fa-eye link-text"
				title="View Schedule List"
				(click)="item.showScheduledEmployeeList = !item.showScheduledEmployeeList"
			></i>
			<div *ngIf="!item.showScheduledEmployeeList">
				<span *ngFor="let emp of item.employeeList; let idx = index">
					<span>{{ emp.name }}</span>
					<span *ngIf="!emp.active" class="emp-inactive"> (Inactive)</span>
					<span *ngIf="idx < item.employeeList.length - 1"> / </span>
				</span>
			</div>
			<app-list-item-scheduled-employees
				*ngIf="item.showScheduledEmployeeList"
				[dayOptions]="dayOptions"
				[schedEntry]="item.entry"
			></app-list-item-scheduled-employees>
		</div>
		<div *ngIf="item.entry.description" class="sched-comments">
			<span class="tts-hl-2" style="font-weight: bold">Details: </span> {{ item.entry.description }}
		</div>
		<div *ngIf="item.comments" class="sched-comments"><span class="tts-hl-2" style="font-weight: bold">Notes: </span> {{ item.comments }}</div>

		<div *ngIf="tagsHtml">
			<div [id]="'tag-' + item.entry.id" class="sched-tags" [innerHTML]="tagsHtml | sanitizer"></div>
		</div>

		<div *ngIf="item.entry.approval_state === 'PENDING'" class="approval-block">
			<!-- <div class="dm-separator" *ngIf="item.info?.lastUpMom && item.info?.nextUpMom">
				<div class="dm-tag">Initial Deadline Missed</div>
			</div> -->
			<div class="dm-tag-wrap" *ngIf="item.info?.lastUpMom && item.info?.nextUpMom">
				<div class="dm-tag">Initial Deadline Missed</div>
			</div>
			<div class="update-info">
				<div *ngIf="!lastUpdatedBy">
					<b>Managed by: <span style="color: chocolate">Unassigned</span></b>
				</div>
				<div *ngIf="lastUpdatedBy">
					Managed by <span style="color: green; font-weight: 600">{{ lastUpdatedBy }}</span>
				</div>
			</div>

			<div style="margin-top: 12px; height: 32px">
				<button class="btn btn-sm btn-success" (click)="takeAction.emit(item)">Actions</button>
				<div
					*ngIf="hoursLeft() > 0"
					class="hours-left"
					[class.hours-left-warn]="hoursLeft() <= 24"
					[class.hours-left-danger]="hoursLeft() <= 12"
					[class.blink]="hoursLeft() === 1"
				>
					{{ hoursLeft() > 48 ? '48+ hours' : timeRemaining() }}
					<!-- {{ hoursLeft() > 96 ? '96+' : hoursLeft() }} Hour{{ hoursLeft() === 1 ? '' : 's' }} Remaining -->
				</div>
				<div *ngIf="hoursLeft() === 0 && minutesLeft() > 0" class="hours-left hours-left-danger">
					<div *ngIf="minutesLeft() > 0">{{ minutesLeft() }} min</div>
				</div>
				<div *ngIf="isDeadlineMissed()" class="hours-left hours-left-danger">
					<div *ngIf="item.info?.nextUpMom">Deadline Missed</div>
					<div *ngIf="!item.info?.nextUpMom">Expired</div>
				</div>
			</div>
		</div>
		<!-- <div>
			<div>Hours Left: {{ hoursLeft() }}</div>
			<div>Minutes Left: {{ minutesLeft() }}</div>
			<div>Time Remaining: {{ timeRemaining() }}</div>
		</div> -->
	</div>
</div>
