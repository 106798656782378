import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { FormHelper, log } from '@app/helpers'
import {
	DialogManager,
	BreakProfileRecord,
	DatabaseTableName,
	EditFormAction,
	EditCompleteEvent,
	HelpDialogMessage,
	BreakRuleCard,
	BreakRule,
} from '@app/models'
import { CoreService } from '@app/services'
import { SelectItem } from 'primeng/api'

import moment from 'moment-timezone'

@Component({
    selector: 'app-break-profile-edit',
    templateUrl: './break-profile-edit.component.html',
    styleUrls: ['./break-profile-edit.component.scss'],
    standalone: false
})
export class BreakProfileEditComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
	resource: DatabaseTableName = 'break_profile'
	isUpdating = false
	form: UntypedFormGroup

	cards: Array<BreakRuleCard> = []
	currentCard: BreakRuleCard

	noDescriptionPlaceholder = '< Break Description Required >'

	linkedJobOptions: SelectItem[] = []

	// If both a record and recordId are provided, the record will take precedence
	// and the recordId will be ignored. If neither is provided, a new record will
	// be created. If only recordId is provided, the record will be retrieved from
	// the corosponding service. The defaut is to provide a recordId.

	@Input() recordId: number
	@Input() record: BreakProfileRecord
	@Input() action: EditFormAction = 'edit'

	@Input() dialogManager: DialogManager

	@Output() recordUpdated = new EventEmitter<EditCompleteEvent>()

	constructor(
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
	) {}

	get isNew(): boolean {
		return !this.record?.id
	}

	public ngOnInit(): void {
		this.setupComponent()
		this.setupForm()
		this.setupLinkedJobOptions()
	}

	public ngAfterViewInit(): void {}

	public ngAfterContentInit(): void {
		this.setupDialogManager()
	}

	public ngOnDestroy(): void {}

	public setupComponent() {
		const record = this.record ? this.record : this.coreSrvc.dbSrvc.breakSrvc.getBreakProfileRecordById(this.recordId)
		this.record = new BreakProfileRecord(record)

		if (this.action === 'clone') {
			this.record.id = null
			this.record.description = record.description + ' (copy)'
		}

		// Setup the break rules array
		const rules = this.record.rules_json ? JSON.parse(this.record.rules_json) : []
		this.cards = rules.map((rule) => new BreakRule(rule)).map((rule) => new BreakRuleCard(rule))
	}

	public setupForm() {
		const record = this.record

		this.form = this.fb.group({
			id: [record?.id],
			description: [record?.description, Validators.required],
			job_ids: [record?.job_ids],
		})
	}

	private setupLinkedJobOptions() {
		const includeIds = this.record.job_ids || []
		const options = this.coreSrvc.dbSrvc.jobSrvc.getJobDropdown(this.coreSrvc.dbSrvc, false, false, false, [])
		const filteredOptions = options.filter((jdd) => jdd.data.active || includeIds.includes(jdd.data.id))
		filteredOptions.forEach((opt) => {
			const jId = opt.data?.id
			const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jId)
			if (job) {
				const np = this.coreSrvc.dbSrvc.npSrvc.getProfileById(job.notification_profile_id)
				if (np) {
					const npName = np.name
					opt.label = opt.label + ` (${npName})`
				}
			}
		})
		this.linkedJobOptions = filteredOptions
	}

	public setupDialogManager() {
		this.dialogManager.canSubmit = () => this.isFormValid()
		this.dialogManager.submitBtnAction = () => this.submit()
	}

	public isFormValid(): boolean {
		const breaksHaveDescriptions = this.cards.filter((card) => card.rule.description).length > 0
		return this.form.valid && breaksHaveDescriptions
	}

	public submit() {
		// Guard against double submission
		if (this.isUpdating) return
		FormHelper.trimOnlyWhitespace(this.form)

		const record = this.makeUpdateRecord()
		log('Record to submit', record)
		if (record) {
			if (this.isNew) {
				log('Insert record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.insertRecord(this.resource, record).then((success) => {
					if (success) {
						this.recordUpdated.emit({ action: this.action, recordId: this.record?.id })
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			} else {
				log('Update record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.updateRecord(this.resource, record).then((success) => {
					if (success) {
						this.recordUpdated.emit({ action: this.action, recordId: this.record?.id })
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			}
		}
	}

	public makeUpdateRecord(): BreakProfileRecord {
		const record = new BreakProfileRecord(this.record)

		// Update any values changed by the form here
		record.description = this.form.get('description').value
		record.job_ids = this.form.get('job_ids').value

		record.rules_json = this.cards.length > 0 ? JSON.stringify(this.cards.map((card) => card.rule)) : null

		return record
	}

	////////////////////////
	// Manage Break Rules //
	////////////////////////

	public addRecord() {
		this.dialogManager.pushState()
		this.dialogManager.headerLabel = 'Add Break Rule'
		this.dialogManager.isCancelBtnVisble = false
		this.dialogManager.isSubmitBtnVisible = false
		this.dialogManager.isBackBtnVisible = true
		this.dialogManager.backBtnAction = () => this.editComplete()
		const card = new BreakRuleCard(new BreakRule())
		this.currentCard = card
		this.cards.push(card)
	}

	// public cancelAddRecord() {
	// 	// Remove the last card
	// 	this.cards.pop()
	// 	this.currentCard = null
	// 	this.dialogManager.popStateAndApply()
	// }

	public editCard(card: BreakRuleCard) {
		this.dialogManager.pushState()
		this.dialogManager.headerLabel = 'Update Break Rule'
		this.dialogManager.isCancelBtnVisble = false
		this.dialogManager.isSubmitBtnVisible = false
		this.dialogManager.isBackBtnVisible = true
		this.dialogManager.backBtnAction = () => this.editComplete()
		this.currentCard = card
	}

	public editComplete() {
		if (!this.doesCurrentCardValidate()) return
		this.currentCard.updateSummary()
		this.currentCard = null
		this.dialogManager.popStateAndApply()
	}

	public deleteCard(card: BreakRuleCard) {
		card.confirmDelete = true
	}

	public confirmDelete(card: BreakRuleCard) {
		// Remove this card from the cards array
		this.cards.splice(this.cards.indexOf(card), 1)
	}

	public moveCard(direction: 'UP' | 'DOWN', card: BreakRuleCard) {
		const index = this.cards.indexOf(card)
		if (direction === 'UP') {
			if (index > 0) {
				this.cards.splice(index - 1, 0, this.cards.splice(index, 1)[0])
			}
		} else if (direction === 'DOWN') {
			if (index < this.cards.length - 1) {
				this.cards.splice(index + 1, 0, this.cards.splice(index, 1)[0])
			}
		}
	}

	////////////////
	// VALIDATION //
	////////////////

	public doesCurrentCardValidate() {
		const rule = this.currentCard.rule
		// Validate startOffset
		if (!this.isDurationFormatValid(rule.startOffset)) {
			this.coreSrvc.notifySrvc.notify('error', 'Invalid Input', 'The start time for a break must be specified in hr:min format.', 8)
			this.flagForError('startOffset')
			return false
		}

		// Validate durationInMin
		if (!rule.duration) {
			this.coreSrvc.notifySrvc.notify('error', 'Invalid Input', 'The duration of a break must be specified in minutes.', 8)
			this.flagForError('duration')
			return false
		}

		// Validate startBreakReminder
		if (rule.startBreakReminder && rule.startBreakReminderOffsetType !== 'AT_EVENT' && !rule.startBreakReminderOffset) {
			// Validate startBreakReminderOffsetType
			this.coreSrvc.notifySrvc.notify('error', 'Invalid Input ', 'The start break reminder time must be specified in minutes.', 8)
			this.flagForError('startBreakReminderOffset')
			return false
		}

		// Validate endBreakReminder
		if (rule.endBreakReminder && rule.endBreakReminderOffsetType !== 'AT_EVENT' && !rule.endBreakReminderOffset) {
			// Validate endBreakReminderOffsetType
			this.coreSrvc.notifySrvc.notify('error', 'Invalid Input ', 'The end break reminder time must be specified in minutes.', 8)
			this.flagForError('endBreakReminderOffset')
			return false
		}

		// Validate flagBreakNotStartedBy
		if (rule.flagBreakNotStartedBy && !this.isDurationFormatValid(rule.flagBreakNotStartedByOffset)) {
			this.coreSrvc.notifySrvc.notify('error', 'Invalid Input', 'Must specify the duration when flagging a break as not started.', 8)
			this.flagForError('flagBreakNotStartedByOffset')
			return false
		}

		// Validate flagShortBreak
		if (rule.flagShortBreak && !rule.flagShortBreakDuration) {
			this.coreSrvc.notifySrvc.notify('error', 'Invalid Input', 'Must specify the duration when flagging a short break.', 8)
			this.flagForError('flagShortBreakDuration')
			return false
		}

		return true
	}

	public isDurationFormatValid(dur: string = '') {
		const comps = dur?.split(':')
		if (comps?.length === 2 && dur?.includes(':')) {
			const momDur = moment.duration(dur)
			if (momDur.isValid() && momDur.asSeconds() !== 0) return true
		}
		return false
	}

	///////////////////
	// MISCELLANEOUS //
	///////////////////

	public toggleCheckbox(prop: string) {
		const current = this.form.get(prop).value
		this.form.get(prop).setValue(!current)
	}

	public flagForError(prop: string) {
		$(`#${prop}`).addClass('input-error')
	}

	private scrollIntoView(id: string) {
		this.coreSrvc.displaySrvc.bringIntoViewBySelector(id)
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
