<div class="options-block">
	<div class="options-section-header-lg">Tags</div>
	<div class="p-ac-smallcap-wrap d-flex justify-content-evenly">
		<div style="width: 100%">
			<p-autoComplete
				#tagAutoComplete
				appScrollLockAutoComplete
				placeholder="Enter a tag"
				[inputStyleClass]="'p-autocomplete-mod'"
				[(ngModel)]="newTagLabel"
				[suggestions]="tagOptions"
				[delay]="250"
				[completeOnFocus]="true"
				(completeMethod)="searchTags($event)"
				(onSelect)="autoSelect($event)"
				(keyup.enter)="onEnterKeyPressed($event); tagAutoComplete.hide(); tagAutoComplete.cd.markForCheck()"
			>
			</p-autoComplete>
		</div>

		<button class="btn btn-success add-button" type="button" (click)="addTag()">Add</button>
	</div>

	<div style="margin-top: 12px">
		<div
			*ngFor="let tag of tags"
			class="badge form-tag mb-1 mr-1"
			[class.form-tag-expire-set]="tag.expire"
			[class.form-tag-expired]="tag.isExpired"
		>
			<i class="float-right fa fa-close tag-close" style="margin-left: 8px" (click)="removeTag(tag)"></i>
			<span class="tag-label" (click)="modifyProperties(tag)">{{ (tag.label ?? '').toUpperCase() }}</span>
		</div>
	</div>
</div>

<div id="tag-properties-block" class="tag-properties-block" *ngIf="selectedTag">
	<div class="options-block">
		<div>
			<div class="float-right">
				<span class="tag-label tts-hl-2" style="font-weight: 600">{{ (selectedTag.label ?? '').toUpperCase() }}</span>
			</div>
			<div class="options-title tts-hl-1">Modify Tag</div>
		</div>

		<hr />

		<div class="options-block">
			<div class="options-title">Label</div>
			<input type="text" class="form-control" style="text-transform: uppercase" [(ngModel)]="selectedTagLabel" />
		</div>

		<div>
			<div class="float-right link-text" (click)="clearSelectedTagExpire()">clear</div>
			<div class="options-title">Expiration</div>
		</div>
		<app-date-time-picker
			[currentDate]="selectedTagExpiration"
			[startAt]="selectedTagExpiration ? null : startAtPickerDefault"
			[pickerType]="'DATETIME'"
			[pickerMode]="'dialog'"
			[pickerLabel]="'Expiration'"
			[hour12Timer]="true"
			[format]="datePickerFormat"
			(pickerDidClose)="confirmSetDate($event)"
		></app-date-time-picker>
	</div>

	<div>
		<div class="float-right">
			<button class="btn btn-sm btn-secondary" (click)="cancelModification()" style="margin-right: 12px">Cancel</button>
			<button class="btn btn-sm btn-success" (click)="performModification()">Update</button>
		</div>
		<div class="tag-added-block">
			<div>Tag Added {{ formatStandardTime(selectedTag) }}</div>
			<div>{{ formatStandardDate(selectedTag) }}</div>
		</div>
	</div>
</div>

<div>
	<!-- <pre><code>{{ tags | json }}</code></pre> -->
	<!-- <pre><code>{{ selectedTag | json }}</code></pre> -->
</div>
