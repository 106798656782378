import moment from 'moment-timezone'
import { extendMoment, DateRange } from 'moment-range'
import { SelectItem } from 'primeng/api'
import { DateTimeHelper } from '@app/helpers'

const rangeMoment = extendMoment(<any>moment)

export type VacationType = 'UNPAID' | 'PAID' | 'SICK' | 'HOLIDAY' | 'BEREAVEMENT' | 'PERSONAL'

export type VacationApprovalState = 'DIRECT' | 'PENDING' | 'APPROVED' | 'REJECTED'

export const VacationTypeOptions: Array<SelectItem> = [
	{ label: 'Unpaid', value: 'UNPAID' },
	{ label: 'Paid Personal', value: 'PERSONAL' },
	{ label: 'Paid Sick Time', value: 'SICK' },
	{ label: 'Paid Holiday', value: 'HOLIDAY' },
	{ label: 'Paid Bereavement', value: 'BEREAVEMENT' },
]

// DEPRECATED 20241001
// export interface IVacation {
// 	id: number
// 	company_id: number
// 	employee_id: number
// 	external_id: string
// 	start_date: string
// 	end_date: string

// 	notes: string
// 	schedule_time_off: boolean

// 	type: VacationType
// 	hours: string

// 	created: string
// 	updated: string

// 	emp_notes: string
// 	approval_state: VacationApprovalState
// 	approval_state_last_updated: string
// 	approval_state_last_user_id: number

// 	// notify_emp_new_entry: boolean // virtual flag provided in form
// 	notify_employee: boolean // virtual flag to manage notifications

// 	job_id: number
// }

export class VacationRecord {
	id: number = null
	company_id: number = null
	employee_id: number = null
	external_id: string = null
	start_date: string = null
	end_date: string = null

	notes: string = null
	schedule_time_off = true

	type: VacationType = 'UNPAID'
	hours: string = null

	created: string = null
	updated: string = null

	emp_notes = null
	approval_state: VacationApprovalState = 'APPROVED'
	approval_state_last_updated: string = null // Date
	approval_state_last_user_id: number = null

	// notify_emp_new_entry: boolean = true // virtual flag provided in form
	notify_employee: boolean = true // virtual flag to manage notifications
	job_id: number

	constructor(record?: VacationRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(record.created)
			this.updated = DateTimeHelper.stripUtcTag(record.updated)
			this.approval_state_last_updated = DateTimeHelper.stripUtcTag(record.approval_state_last_updated)
		}
	}

	get lastManagedDate(): string {
		return this.approval_state_last_updated ?? this.updated ?? this.created
	}

	getDates(): Array<string> {
		const startMom = moment(this.start_date)
		const endMom = moment(this.end_date)
		const range = rangeMoment().range(startMom, endMom) /*can handle leap year*/
		const array = Array.from(range.by('days')) /*days, hours, years, etc.*/

		return array.map((m) => m.format('YYYY-MM-DD'))
		// array.map(m => {
		// 	console.log(m.format('YYYY-MM-DD')) /*or any format you like*/
		// })
	}

	getDerivedStatus(): 'UPCOMING' | 'ONGOING' | 'COMPLETED' | 'UNKNOWN' {
		const startMom = moment(this.start_date)
		const endMom = moment(this.end_date)
		const isOngoing = moment().isBetween(startMom, endMom, 'day', '[]')
		const isUpcoming = moment().isBefore(startMom, 'day')
		const isComplete = moment().isAfter(endMom, 'day')

		if (isUpcoming) return 'UPCOMING'
		if (isOngoing) return 'ONGOING'
		if (isComplete) return 'COMPLETED'
		return 'UNKNOWN'
	}
}
