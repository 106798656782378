<div style="text-align: center; margin-top: -55px">
	<div *ngIf="!isDataLoaded">
		<div *ngIf="!hasError" class="loading-block"><i class="fas fa-loader fa-spin"></i> {{ loadingMessage }}</div>
		<div *ngIf="hasError" class="error-block">
			<div class="error-note">An error was encountered attempting to load this report. It may have been deleted or is otherwise unavailable.</div>
			<div class="error-id">
				<div *ngIf="reportTypeMsg">{{ reportTypeMsg }}</div>
				<div>{{ uuid }}</div>
			</div>
		</div>
	</div>

	<div *ngIf="isDataLoaded" style="margin-top: 80px">
		<app-user-report-render-host [config]="config" [publicView]="true"></app-user-report-render-host>
	</div>
</div>
