<p-dialog
	styleClass="p-dialog-noheader"
	[(visible)]="showDialog"
	[modal]="false"
	[style]="{ width: '250px' }"
	[showHeader]="false"
	[baseZIndex]="5000"
	[dismissableMask]="true"
	(onHide)="stopPlayingAudio()"
>
	<div class="tts-default-font audio-msg">
		<i class="fas fa-loader fa-spin"></i> Playing Audio...
		<i class="far fa-circle-stop audio-stop" (click)="stopAndClose()"></i>
	</div>
</p-dialog>
