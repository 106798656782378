import { Global } from './global'
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js'
import { log } from '@app/helpers/logger'
import { ParserHelper } from '@app/helpers'

export interface TransactionMetaDataForReport {
	id: number
	employee_id: number
	employee_name: string
	emp_supervisor_id: number
	emp_supervisor_name: string
	job_id: number
	job_date: string
	job_description: string
	jobsite_id: number
	jobsite_description: string
	jobsite_supervisor_id: number
	jobsite_supervisor_name: string
	schedule_recur_id: number
	start_time: string
	end_time: string
	timezone: string
}
export type DistanceUnitFormat = 'IMPERIAL' | 'METRIC'
export type DistanceUnitOption = 'KM' | 'M' | 'MI' | 'FT'
export interface DistanceResult {
	value: number
	units: DistanceUnitOption
}

export class AdditionalShiftReportData {
	mileage: number = null
	mileageUnits: DistanceUnitOption = 'MI'
}

// Incident Reports: 'CHECKIN' | 'CHECKOUT' | 'CHECKLIST' | 'CUSTOM' | 'HOMECARE' | 'INCIDENT' | 'POST_BREAK' | 'PRE_BREAK' | 'SHIFT' | 'SHIFTSUMMARY' | 'VEHICLEINSPECTION'
// Shift Summary Report: 'SHIFTSUMMARY'

export type UserReportType =
	| 'CHECKIN'
	| 'CHECKOUT'
	| 'CHECKLIST'
	| 'CUSTOM'
	| 'HOMECARE'
	| 'INCIDENT'
	| 'POST_BREAK'
	| 'PRE_BREAK'
	| 'SHIFT'
	| 'SHIFTSUMMARY'
	| 'VEHICLEINSPECTION'
export type UserReportIndustryType = 'DEFAULT' | 'SECURITY' | 'HEALTHCARE' | 'CONSTRUCTION'

export class UserReportImage {
	width: string
	height: string
	url: string
	description: string

	constructor(img: any) {
		const bucket = img.bucket
		const path = img.key
		this.width = img.width > img.height ? '100' : null
		this.height = img.height > img.width ? '100' : null
		this.url = `https://${bucket}/${path}`
		this.description = img.description
	}
}

export interface IUserReportOption {
	name: string
	value: UserReportType
	customInstructions?: string
	customRecordId?: number
}

export interface IUserReport {
	metaData: any
	reportData: any
}

export class UserReportConfiguration {
	countryCode: CountryCode
	industryType: UserReportIndustryType
	reportType: UserReportType
}

export interface IUserReportFormat {
	reportType: UserReportType
	industryType: UserReportIndustryType
}

export interface IUserReportMetaData {
	version: number
	format: IUserReportFormat
}

export class UserReportMetaData implements IUserReportMetaData {
	version = 0
	format: IUserReportFormat
	constructor(version: number, format: IUserReportFormat) {
		this.format = format
		this.version = version
	}
}

export type ReportChecklistItemType = 'STRING' | 'BOOLEAN' | 'RANGE' | 'SELECT'

export interface IReportChecklistItem {
	tagId: string
	type: ReportChecklistItemType
	value: any
	label: string
	skip: boolean
	comment?: ReportFieldChecklistItemComment
}

export class ReportChecklistBasic {
	tagId = null
	type = 'CHECKLISTBASIC'
	title = ''
	instructions = ''
	items: Array<IReportChecklistItem> = []
	constructor(config?: any) {
		if (config) {
			for (const attr in config) {
				if (config.hasOwnProperty(attr)) {
					this[attr] = config[attr]
				}
			}
		}
	}
}

export class ReportFieldSelectWithOption {
	type = 'SELECT'
	value = ''
	option = ''
}

export class ReportFieldCheckbox {
	type = 'BOOLEAN'
	value = false
}

export class ReportFieldCheckboxWithDescription {
	type = 'BOOLEAN'
	value = false
	description = ''
	skip = false
	constructor(config?: any) {
		if (config) {
			for (const attr in config) {
				if (config.hasOwnProperty(attr)) {
					this[attr] = config[attr]
				}
			}
		}
	}
}

// TODO - Create a form class for a SURVEY item of type SURVEY

export class ReportFieldChecklistItemComment {
	include = false
	value = ''
}

// ChecklistFormItemType CHECKBOXBASIC maps to ReportFieldCheckboxBasic
export class ReportFieldCheckboxBasic {
	tagId = null
	type: ReportChecklistItemType = 'BOOLEAN'
	value = false
	label = 'Checkbox Label'
	skip = false
	comment = new ReportFieldChecklistItemComment()
	constructor(config?: any) {
		if (config) {
			for (const attr in config) {
				if (config.hasOwnProperty(attr)) {
					this[attr] = config[attr]
				}
			}
		}
	}
}

// ChecklistFormItemType INPUTBASIC maps to ReportFieldInputBasic
export class ReportFieldInputBasic {
	tagId = null
	type: ReportChecklistItemType = 'STRING'
	value = ''
	label = 'Input Label'
	skip = false
	constructor(config?: any) {
		if (config) {
			for (const attr in config) {
				if (config.hasOwnProperty(attr)) {
					this[attr] = config[attr]
				}
			}
		}
	}
}

// ChecklistFormItemType RANGEBASIC maps to ReportFieldRangeBasic
export class ReportFieldRangeBasic {
	tagId = null
	type: ReportChecklistItemType = 'RANGE'
	value = null
	label = 'Range Label'
	skip = false
	comment = new ReportFieldChecklistItemComment()
	min: number = null
	max: number = null
	step: number = null
	rangeLabel = { left: null, right: null }
	constructor(config?: any) {
		if (config) {
			for (const attr in config) {
				if (config.hasOwnProperty(attr)) {
					this[attr] = config[attr]
				}
			}
			// Normalize values and set defaults
			this.min = +this.min
			this.max = +this.max
			this.step = +this.step
			// Uncomment to force a default vaule for the range selector
			// if (!this.value) this.value = Math.round((this.max - this.min) / 2) + this.min
		}
	}
}

export class ReportFieldSelectBasicOption {
	label: string
	value: any
	selected = false // MAY NOT NEED THIS
	constructor(data?: any) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
	}
}

export class ReportFieldSelectBasic {
	// When style is MENU, the response will be stored in value unless value === CUSTOM. Then the response will be stored in custom.value
	// When style is LIST, the response will be stored in multi.options or mult.selected - TO BE DETERMINED
	// MutliSelect is only allowed when using type LIST
	tagId = null
	type: ReportChecklistItemType = 'SELECT'
	style: 'MENU' | 'LIST' = 'MENU'
	value = null
	label = 'Select Label'
	skip = false
	multi = { allow: false, selected: [] } // Flag to indicate if multiple selections allowed and selected holds the items selected
	custom = { allow: false, value: '' } // Flag to indicate if custom selection allowed and value holds the response
	options: Array<ReportFieldSelectBasicOption> = []
	comment = new ReportFieldChecklistItemComment()

	constructor(config?: any) {
		if (config) {
			for (const attr in config) {
				if (config.hasOwnProperty(attr)) {
					this[attr] = config[attr]
				}
			}
		}
		this.options = this.options.map((opt) => new ReportFieldSelectBasicOption(opt))
	}
}

export class ReportFieldGenericInput {
	type = 'STRING'
	value = ''
	skip = false
	style = 'INPUT' // INPUT | TEXTAREA
	constructor(config?: any) {
		if (config) {
			for (const attr in config) {
				if (config.hasOwnProperty(attr)) {
					this[attr] = config[attr]
				}
			}
		}
	}
}

export class ReportFieldDateTimeInput {
	type: DatePickerType = 'DATETIME' // Will always be DATETIME
	value = null
	style: DatePickerType = 'DATETIME' // Style used for rendering => DATETIME | DATE | TIME
	skip = false
	constructor(config?: any) {
		if (config) {
			for (const attr in config) {
				if (config.hasOwnProperty(attr)) {
					this[attr] = config[attr]
				}
			}
		}
	}
}

export class ReportFieldContactEmailInput {
	type = 'EMAIL'
	value = ''
	skip = false
	valid = null
	constructor(config?: any) {
		if (config) {
			for (const attr in config) {
				if (config.hasOwnProperty(attr)) {
					this[attr] = config[attr]
				}
			}
		}
	}
}

export class ReportFieldContactPhoneInput {
	type = 'PHONE'
	value = ''
	skip = false
	valid = null
	confirmed = null
	constructor(config?: any) {
		if (config) {
			for (const attr in config) {
				if (config.hasOwnProperty(attr)) {
					this[attr] = config[attr]
				}
			}
		}
	}
}

export type DatePickerType = 'DATETIME' | 'DATE' | 'TIME'

export type ChecklistOptionsType = 'EMP' | 'JOB' | 'SITE' | 'COMPANY'

export class ReportGlobals {
	static displayPrefs = {
		format12Hour: true,
	}

	// Incident Report Lists

	static incidentTypeOptionsForDefault = [
		{ type: 'ACCIDENT', name: 'Accident' },
		{ type: 'BODILYINJURY', name: 'Bodily Injury' },
		{ type: 'FIRE', name: 'Fire' },
		{ type: 'MAINTENANCE', name: 'Maintenance' },
		{ type: 'THEFT', name: 'Theft' },
		{ type: 'TRESPASSING', name: 'Trespassing' },
		{ type: 'OTHER', name: 'Other' },
	]

	static incidentTypeOptionsForSecurity = [
		{ type: 'ACCIDENT', name: 'Accident' },
		{ type: 'ASSAULT', name: 'Assault' },
		{ type: 'BODILYINJURY', name: 'Bodily Injury' },
		{ type: 'FIRE', name: 'Fire' },
		{ type: 'FLOOD', name: 'Flood' },
		{ type: 'MAINTENANCE', name: 'Maintenance' },
		{ type: 'THEFT', name: 'Theft' },
		{ type: 'TRESPASSING', name: 'Trespassing' },
		{ type: 'OTHER', name: 'Other' },
	]

	static incidentTypeOptionsForHealthCare = [
		{ type: 'ACCIDENT', name: 'Accident' },
		{ type: 'BODILYINJURY', name: 'Bodily Injury' },
		{ type: 'EMERGENCY', name: 'Emergency' },
		{ type: 'FIRE', name: 'Fire' },
		{ type: 'MAINTENANCE', name: 'Maintenance' },
		{ type: 'THEFT', name: 'Theft' },
		{ type: 'OTHER', name: 'Other' },
	]

	// Homecare Report Lists

	static serviceTypeOptionsForHomecareReport = [
		{ type: 'PERSONALSERVICE', name: 'Personal Service' },
		{ type: 'OTHER', name: 'Other' },
	]

	static getDatePickerFormat(type: DatePickerType): string {
		if (type === 'DATE') {
			return 'ddd, MMM Do YYYY'
		}
		if (ReportGlobals.displayPrefs.format12Hour) {
			switch (type) {
				case 'DATETIME':
					return 'ddd, MMM Do [at] h:mm a'
				case 'TIME':
					return 'h:mm a'
			}
		} else {
			switch (type) {
				case 'DATETIME':
					return 'ddd, MMM Do [at] HH:mm'
				case 'TIME':
					return 'HH:mm'
			}
		}
	}
}

// A subset of report types available for checklist use
export type ChecklistReportType = 'CHECKIN' | 'PRE_BREAK' | 'POST_BREAK' | 'CHECKOUT' | 'CUSTOM'

// Used by emp app to configure initial data for checkout form
export type EmployeeChecklistType = 'CHECKIN' | 'PRE_BREAK' | 'POST_BREAK' | 'CHECKOUT' | 'CUSTOM'

export type ChecklistFormItemType = 'INPUTBASIC' | 'CHECKBOXBASIC' | 'RANGEBASIC' | 'SELECTBASIC'

// Interface for what's stored in the config of a CHECKBOXBASIC
export class ChecklistFormConfigCheckboxBasic {
	label: string
	includeComment = false
	constructor(data?: any) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
	}
}

export class ChecklistFormConfigInputBasic {
	label: string
	constructor(data?: any) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
	}
}

export class ChecklistFormConfigRangeBasic {
	label: string
	includeComment = false
	min: number
	max: number
	step: number
	rangeLabel = { left: null, right: null }
	constructor(data?: any) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
	}
}

export class ChecklistFormConfigSelectBasicOption {
	label: string
	value: any
	selected = false
	constructor(data?: any) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
	}
}

export class ChecklistFormConfigSelectBasic {
	label: string
	style: 'MENU' | 'LIST' = 'MENU'
	custom = { allow: false, value: null }
	multi = { allow: false, selected: [] }
	options: Array<ChecklistFormConfigSelectBasicOption> = []
	constructor(data?: any) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
					this.options = this.options.map((opt) => new ChecklistFormConfigSelectBasicOption(opt))
				}
			}
		}
	}
}

export class ChecklistFormItem {
	type: ChecklistFormItemType = 'CHECKBOXBASIC'
	config: any = {} // Format dependant on type
	showDelete = false // Delete before submitting
	showConfig = false // Delete before submitting
	hasError = false // Delete before submitting

	constructor(data?: any) {
		if (data) {
			if (typeof data === 'string') {
				// Auto-convert V1 format which is a string array
				this.type = 'CHECKBOXBASIC'
				this.config = { label: data }
			} else {
				// Setup V2 format which uses a uniquie config based on the type
				for (const attr in data) {
					if (data.hasOwnProperty(attr)) {
						this[attr] = data[attr]
					}
				}
			}
			// Convert config information into object to normailze config properties
			this.setupConfig()
		}
	}

	setupConfig() {
		const type: ChecklistFormItemType = this.type
		switch (type) {
			case 'CHECKBOXBASIC':
				this.config = new ChecklistFormConfigCheckboxBasic(this.config)
				break
			case 'INPUTBASIC':
				this.config = new ChecklistFormConfigInputBasic(this.config)
				break
			case 'RANGEBASIC':
				this.config = new ChecklistFormConfigRangeBasic(this.config)
				break
			case 'SELECTBASIC':
				this.config = new ChecklistFormConfigSelectBasic(this.config)
				break
		}
	}

	removeLayoutProperties() {
		delete this.showDelete
		delete this.showConfig
		delete this.hasError
	}

	static labelForType(type: ChecklistFormItemType): string {
		switch (type) {
			case 'CHECKBOXBASIC':
				return 'Checkbox'
			case 'INPUTBASIC':
				return 'Input'
			case 'RANGEBASIC':
				return 'Range'
			case 'SELECTBASIC':
				return 'Select'
		}
	}
}

export class EmployeeChecklistData {
	id: number = null
	type: EmployeeChecklistType = 'CHECKOUT'
	name = ''
	title = ''
	instructions = ''
	required = false
	list: Array<ChecklistFormItem> = [] // Sourced from checklist_json in db record
	enabled = true

	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			// Parse the records and build the form list config information
			const list = record.list ? JSON.parse(record.list) : []
			this.list = list.map((li) => new ChecklistFormItem(li))
		}
	}
	get hasChecklistItems(): boolean {
		return this.list.length > 0
	}
}

// Used by emp app to configure initial data for checkout form
export class UserReportChecklistConfig {
	private employeeChecklistData: Array<EmployeeChecklistData> = [] // V2 Checklist Information

	get hasCheckinOptions(): boolean {
		return this.employeeChecklistData.filter((cld) => cld.type === 'CHECKIN' && cld.enabled).length > 0
	}
	get hasPostBreakOptions(): boolean {
		return this.employeeChecklistData.filter((cld) => cld.type === 'POST_BREAK' && cld.enabled).length > 0
	}
	get hasCheckoutOptions(): boolean {
		return this.employeeChecklistData.filter((cld) => cld.type === 'CHECKOUT' && cld.enabled).length > 0
	}
	get hasCustomOptions(): boolean {
		return this.employeeChecklistData.filter((cld) => cld.type === 'CUSTOM' && cld.enabled).length > 0
	}

	get isCheckinChecklistRequired(): boolean {
		// A check-in survey is required if any member surveys are required
		return this.employeeChecklistData.filter((cld) => cld.type === 'CHECKIN' && cld.enabled && cld.required).length > 0
	}
	get isPostBreakChecklistRequired(): boolean {
		// Post-break survey is required any member surverys is required
		return this.employeeChecklistData.filter((cld) => cld.type === 'POST_BREAK' && cld.enabled && cld.required).length > 0
	}
	get isCheckoutChecklistRequired(): boolean {
		// A check-out survey is required if any member surveys are required
		return this.employeeChecklistData.filter((cld) => cld.type === 'CHECKOUT' && cld.enabled && cld.required).length > 0
	}

	setEmployeeChecklistData(checklistData: Array<EmployeeChecklistData>) {
		this.employeeChecklistData = checklistData
	}

	// Record ID is only used by employee app when building reports. Generally you will first filter the available
	// checklist blocks manaually and provide null when getting checklist data. If you're trying to generate a report
	// preview and you don't get any data back for the report blocks, check to make sure you aren't filtering your blocks
	// first and also providing a recordId here.
	getEmployeeChecklistData(type: EmployeeChecklistType, recordId: number): Array<EmployeeChecklistData> {
		const data = recordId
			? this.employeeChecklistData.filter((cld) => cld.type === type && cld.enabled && cld.id === recordId)
			: this.employeeChecklistData.filter((cld) => cld.type === type && cld.enabled)
		return ParserHelper.deepCloneWithGetters(data)
	}

	resetLists() {
		this.employeeChecklistData = []
	}
}

export interface IScrollToRegionConfig {
	id: string
	isInDialog: boolean
	addClass?: string
	addClassToId?: string
}

export class UserReportValidator {
	static validateEmailAddress(email: string): boolean {
		return Global.emailRegex.test(email)
	}

	static validateAndFormatPhone(number: string, countryCode: CountryCode) {
		const phoneNumber = parsePhoneNumberFromString(number, countryCode)
		if (phoneNumber.isValid()) {
			return phoneNumber.formatNational()
		} else {
			return number
		}
	}

	static validationAlert(msg: string) {
		alert(msg)
	}

	static scrollToRegion(config: { id: string; isInDialog: boolean; addClass?: string; addClassToId?: string }) {
		const id = config.id
		const isInDialog = config.isInDialog
		log('Scrolling to', id)
		const element = document.getElementById(id)
		if (element) {
			// If a class is supplied then apply it to target
			if (config.addClass) {
				const classTarget = config.addClassToId ?? config.id
				$('#' + classTarget).addClass('ur-input-needs-info')
			}

			const offset = 0
			const bodyRect = document.body.getBoundingClientRect().top
			const elementRect = element.getBoundingClientRect().top
			const elementPosition = elementRect - bodyRect
			const offsetPosition = elementPosition - offset

			setTimeout(() => {
				element.scrollIntoView({ behavior: 'smooth', block: 'center' })
				// if (isInDialog) {
				// 	element.scrollIntoView({ behavior: 'smooth', block: 'center' })
				// } else {
				// 	window.scrollTo({
				// 		top: offsetPosition,
				// 		behavior: 'smooth',
				// 	})
				// }
			}, 100)
		}
	}
}

export class UserReportHelper {
	static formatReportTypeLabel(type: UserReportType) {
		switch (type) {
			case 'CHECKLIST':
				return 'Checklist Survey'
			case 'CHECKIN':
				return 'Check-in Survey'
			case 'PRE_BREAK':
				return 'Pre-break Survey'
			case 'POST_BREAK':
				return 'Post-break Survey'
			case 'CHECKOUT':
				return 'Check-out Survey'
			case 'CUSTOM':
				return 'Custom Survey'

			case 'SHIFT':
				return 'Basic Report'
			case 'HOMECARE':
				return 'Homecare Report'
			case 'INCIDENT':
				return 'Incident Report'

			case 'SHIFTSUMMARY':
				return 'Shift Summary Report'
			case 'VEHICLEINSPECTION':
				return 'Vehicle Inspection Report'
			default:
				return 'Unknown Report Type'
		}
	}
}

const v1Format = `["Item 1","Item 2"]`
const v2Format = `[{"type":"CHECKBOXBASIC","config":{"label":"Item 1+"}},{"type":"CHECKBOXBASIC","config":{"label":"Item 2+"}}]`
