import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { TransactionLogRecord } from '@app/models'
import { DatabaseService } from '@app/services'

@Component({
    selector: 'app-shift-details-wrapper',
    templateUrl: './shift-details-wrapper.component.html',
    styleUrls: ['./shift-details-wrapper.component.scss'],
    standalone: false
})
export class ShiftDetailsWrapperComponent implements OnInit {
	trans: TransactionLogRecord

	@Input() transId: number
	@Input() inOut: 'IN' | 'OUT'
	@Output() showMapMarker = new EventEmitter<{ type: 'IN' | 'OUT' | 'CHECKPOINT'; index: number }>()

	sectionTitle: string

	mergedDetails = true

	showCheckinDetails = false
	showCheckoutDetails = false
	showEmployeeNotes = false

	constructor(private dbSrvc: DatabaseService) {}

	ngOnInit(): void {
		this.setupExpansion()
		this.trans = this.dbSrvc.tranSrvc.getTransactionById(this.transId)
	}

	setupExpansion() {
		this.showCheckinDetails = this.inOut === 'IN'
		this.showCheckoutDetails = this.inOut === 'OUT'

		const trans = this.dbSrvc.tranSrvc.getTransactionById(this.transId)
		// if (trans.actual_start || trans.geo_start_images_json || trans.geo_start_emp_note) {
		// 	this.showCheckinDetails = true
		// }
		// if (trans.actual_end || trans.geo_end_images_json || trans.geo_end_emp_note) {
		// 	this.showCheckoutDetails = true
		// }
		if (trans.emp_notes) {
			this.showEmployeeNotes = true
		} else {
			this.showEmployeeNotes = false
		}
	}

	public getEventType(inOut: 'IN' | 'OUT'): string {
		const isTravel = this.trans?.travel_job
		return inOut === 'IN' ? (isTravel ? 'Travel Start' : 'Check-In') : isTravel ? 'Travel End' : 'Check-Out'
	}
}
