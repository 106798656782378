<div *ngIf="isSaving" class="loading-block">
	<div><i class="fas fa-loader fa-spin"></i>&nbsp;Saving changes...</div>
</div>

<div [hidden]="isSaving || preview.show" style="margin-bottom: 25px">
	<div class="options-block" style="margin-bottom: 12px">
		<app-form-input-switch
			[(model)]="record.enabled"
			[enabledLabel]="'Survey is enabled'"
			[disabledLabel]="'Survey is disabled'"
		></app-form-input-switch>
	</div>

	<!-- <div class="options-block">
		<div class="options-title-inline">Block Type</div>
		<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('type')"></i>
		<p-select appScrollLockSelect [options]="typeOptions" [(ngModel)]="record.type"></p-select>
	</div> -->

	<div class="options-block" [class.input-required]="!record.title">
		<div class="float-right" *ngIf="!record.title">(required)</div>
		<div class="options-title-inline">Title</div>
		<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('title')"></i>
		<input type="text" class="form-control" [(ngModel)]="record.title" />
	</div>

	<div class="options-block">
		<div class="options-title-inline">Instructions</div>
		<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('instructions')"></i>
		<textarea class="form-control options-textarea" [(ngModel)]="record.instructions"></textarea>
	</div>

	<div *ngFor="let item of formItems; let idx = index">
		<div class="card cl-card" [class.item-has-error]="item.hasError">
			<div>
				<div class="head-wrap">
					<div>
						<div class="icon-block float-right">
							<i class="far fa-trash-alt icon-wrap" style="color: firebrick" (click)="item.showConfig = false; item.showDelete = true"></i>
							<i class="fas fa-arrow-up icon-wrap" [class.icon-disabled]="idx === 0" (click)="moveUp(item)"></i>
							<i class="fas fa-arrow-down icon-wrap" [class.icon-disabled]="idx === formItems.length - 1" (click)="moveDown(item)"></i>
							<i class="far fa-cog icon-wrap" (click)="toggleItemConfig(item)"></i>
						</div>
						<div class="tts-hl-2 type-label">
							<span>{{ labelForItem(item) }}</span>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp(item.type)"></i>
						</div>
					</div>
				</div>
				<div class="item-form-block">
					<app-checklist-form-item-checkbox
						*ngIf="item.type === 'CHECKBOXBASIC'"
						[item]="item"
						(textResizer)="textResizer($event)"
						(clearError)="clearError($event)"
						(showHelp)="showHelp($event)"
					></app-checklist-form-item-checkbox>
					<app-checklist-form-item-input
						*ngIf="item.type === 'INPUTBASIC'"
						[item]="item"
						(textResizer)="textResizer($event)"
						(clearError)="clearError($event)"
						(showHelp)="showHelp($event)"
					></app-checklist-form-item-input>
					<app-checklist-form-item-range
						*ngIf="item.type === 'RANGEBASIC'"
						[item]="item"
						(textResizer)="textResizer($event)"
						(clearError)="clearError($event)"
						(showHelp)="showHelp($event)"
					></app-checklist-form-item-range>
					<app-checklist-form-item-select
						*ngIf="item.type === 'SELECTBASIC'"
						[item]="item"
						[blockIndex]="idx"
						(textResizer)="textResizer($event)"
						(clearError)="clearError($event)"
						(showHelp)="showHelp($event)"
					></app-checklist-form-item-select>
				</div>
				<div *ngIf="item.showDelete" class="delete-confirm-block">
					<hr class="delete-confirm-hr" />
					<div class="d-flex align-items-center">
						<div class="d-flex justify-content-between flex-grow-1">
							<div class="flex-grow-1 delete-confirm-label delete-label">Confirm Delete</div>
							<div class="d-flex">
								<button class="btn btn-sm btn-block btn-secondary" (click)="item.showDelete = false">Cancel</button>
								<button class="btn btn-sm btn-block btn-danger" style="margin-left: 8px" (click)="remove(item)">Delete</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="add-block">
		<div>
			<div class="options-title-inline">Add Form Element</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('addElement')"></i>
		</div>
		<div class="d-flex justify-content-between">
			<button class="btn btn-sm btn-outline-success btn-block" (click)="addItemToList('INPUTBASIC')" style="margin-right: 4px">Input</button>
			<button class="btn btn-sm btn-outline-success btn-block" (click)="addItemToList('CHECKBOXBASIC')" style="margin: 0px 4px">Checkbox</button>
			<button class="btn btn-sm btn-outline-success btn-block" (click)="addItemToList('SELECTBASIC')" style="margin: 0px 4px">Select</button>
			<button class="btn btn-sm btn-outline-success btn-block" (click)="addItemToList('RANGEBASIC')" style="margin-left: 4px">Range</button>
		</div>
		<button *ngIf="formItems.length > 0" class="btn btn-sm btn-outline-primary btn-block" (click)="previewForm()" style="margin-top: 8px">
			{{ checklistType === 'CUSTOM' ? 'Preview Survey Form' : 'Preview Survey Block' }}
		</button>
	</div>

	<!-- <div *ngIf="isDesktop && record.checklist.length > 2" class="ecc-note">
		Shift-click to select multiple items which you can remove or reorder as a group using the ordering buttons.
	</div> -->

	<!-- <hr /> -->

	<div class="tts-hl-2" style="font-size: 1.4em; margin: 25px 0px 15px 0px">Survey Targets</div>

	<div class="options-block">
		<div class="options-title">Targeted Employees</div>
		<app-multi-select-details
			[type]="'EMP'"
			[options]="targetedEmployeeOptions"
			placeholder="All Employees"
			selectedItemsLabel="Employees Targeted: {0}"
			[(model)]="record.employeeIds"
		>
		</app-multi-select-details>
	</div>

	<div class="options-block">
		<div class="options-title">Targeted Jobs</div>
		<app-multi-select-details
			[type]="'JOB'"
			[options]="targetedJobOptions"
			placeholder="All Jobs"
			selectedItemsLabel="Jobs Targeted: {0}"
			[(model)]="record.jobIds"
		>
		</app-multi-select-details>
	</div>

	<div class="end-note">
		When you configure target information, these survey items will be made available to everyone who satisfies the target requirements. If no
		targets are specified, these survey items will be made available to every employee at any job.
	</div>

	<div *ngIf="canRequireChecklist">
		<hr />

		<div class="tts-hl-2" style="font-size: 1.4em; margin: 20px 0px 10px 0px">Miscellaneous Options</div>

		<div style="margin-left: 12px">
			<!-- BEGIN CHECKBOX OPTIONS -->
			<div class="checkbox-item d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-checkbox [(ngModel)]="record.required" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<strong (click)="record.required = !record.required" class="has-pointer">Require this survey block</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('requireChecklist')"></i>
				</div>
			</div>
			<!-- END CHECKBOX OPTIONS -->
		</div>
	</div>
</div>

<!-- <pre><code>{{ record | json }}</code></pre> -->
<div *ngIf="preview.show" style="margin-top: -20px">
	<app-checklist-report [reportData]="preview.reportData" [reportConfig]="preview.reportConfig"></app-checklist-report>
</div>
