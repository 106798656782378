import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { DataTablesHelper, DisplayHelper, GeneralTableHelper, TableActionFormatter, log } from '@app/helpers'
import {
	ComponentBridgeName,
	DialogManager,
	LocalPrefsData,
	LocalPrefsDialogData,
	LocalPrefsGroup,
	DatabaseTableName,
	TourEditDialogData,
	TourRecord,
} from '@app/models'
import { CoreService } from '@app/services'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { ToursFormatter } from '../tours.formatter'

import _ from 'lodash'
import moment from 'moment-timezone'

enum TourTableIndex {
	id = 0,
	name,
	linkedJobs,
	tourType,
	chkpts,
	repeating,
	actions,
}

@Component({
    selector: 'app-tour-table',
    templateUrl: './tour-table.component.html',
    styleUrls: ['./tour-table.component.scss'],
    standalone: false
})
export class TourTableComponent implements OnInit, OnDestroy, AfterViewInit {
	bridgeName: ComponentBridgeName = 'ngBridgeTourTable'

	list: Array<TourRecord> = []

	private tourTable // : DataTables.DataTable;
	private defaultSortOrder = [[TourTableIndex.name, 'asc']]

	tourEditDialogManager = new DialogManager('tourEditDialog2')

	editAction = { recordId: null, action: 'edit', header: '', footer: '', showEditDialog: false }
	deleteAction = {
		tableName: 'notification_profile' as DatabaseTableName,
		recordId: null,
		recordLabel: null,
		showDeleteRecordDialog: false,
	}

	linkedRecordsDialogManager = new DialogManager('linkedRecordsModal')
	linkedRecordsDialog = { records: [], filteredRecords: [], searchText: null }

	public sectionPrefsDialogManager = new DialogManager('sectionPrefsDialog')

	debounceRedrawTable = _.debounce(this.redrawTable, 300)

	private subs = new Subscription()

	constructor(
		private cd: ChangeDetectorRef,
		private router: Router,
		private coreSrvc: CoreService,
	) {
		this.setupSubscriptions()
		this.setupLocalPrefsDialog()
		this.setupLinkedRecordsDialogManager()
	}

	get dtFilterText(): string {
		const input = $('.search-field-input')
		return (input?.val() as string) || ''
	}

	get viewManager() {
		return this.coreSrvc.dbSrvc.tourSrvc.viewManager
	}

	public ngOnInit(): void {
		window[this.bridgeName] = this
		this.fetchAndReload()
	}

	public ngOnDestroy() {
		this.tourTable['fixedHeader'].disable()
		window[this.bridgeName] = null
		this.subs.unsubscribe()
	}

	public ngAfterViewInit() {
		this.initTable()

		$('#tourTable_filter input').attr('placeholder', ' filter')
		$('#tourTable_filter input').addClass('search-field-input')

		$('#tourTable_filter input').on('keyup', () => {
			this.debounceRedrawTable()
		})

		setTimeout(() => {
			$('#clear-search-icon').detach().appendTo('#tourTable_filter label')
		})
	}

	private setupSubscriptions() {
		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	private setupLocalPrefsDialog(): void {
		this.sectionPrefsDialogManager.headerLabel = 'Preferences'
		let columnVisibilityItems = LocalPrefsData.getTourTableColumnDisplayPrefs()
		const columnVisibilityGroup = new LocalPrefsGroup('Column Visibility', columnVisibilityItems)
		const dialogData = new LocalPrefsDialogData([columnVisibilityGroup])
		this.sectionPrefsDialogManager.dialogData = dialogData
	}

	private setupLinkedRecordsDialogManager() {
		this.linkedRecordsDialogManager.isSubmitBtnVisible = false
		this.linkedRecordsDialogManager.cancelBtnLabel = 'Close'
	}

	private handleScreenSizeChanges() {
		if (this.tourTable) {
			this.tourTable.columns.adjust().responsive.recalc()
			this.tourTable.fixedHeader.adjust()
		}
	}

	public prefsDataSaved(): void {
		log('UpdatePrefsData')
		this.sectionPrefsDialogManager.isDialogVisible = false
		this.updateTable()
	}

	public openPrefsDialog(): void {
		this.sectionPrefsDialogManager.isDialogVisible = true
	}

	public clearSearch(): boolean {
		this.tourTable
			?.search('')
			.order([[TourTableIndex.name, 'asc']])
			.rows()
			.invalidate()
			.draw()
		this.coreSrvc.displaySrvc.enableAllTooltips()
		return false
	}

	private loadData() {
		this.list = this.coreSrvc.dbSrvc.tourSrvc.getTourRecords()
		log('loadData - list', this.list)
	}

	redrawTable() {
		this.tourTable?.rows().invalidate().search(this.dtFilterText).draw(true)
		// this.enableAllTooltips()
	}

	public updateTable() {
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadData()
			this.updateColumns()
		})
	}

	private updateColumns() {
		const linkedJobs = this.coreSrvc.prefSrvc.data.tourColLinkedJobsVisible
		const tourType = this.coreSrvc.prefSrvc.data.tourColTourTypeVisible
		const chkpts = this.coreSrvc.prefSrvc.data.tourColChkptsVisible
		const repeating = this.coreSrvc.prefSrvc.data.tourColRepeatingVisible

		this.tourTable.clear()
		GeneralTableHelper.updateColumn(this.tourTable, TourTableIndex.linkedJobs, linkedJobs)
		GeneralTableHelper.updateColumn(this.tourTable, TourTableIndex.tourType, tourType)
		GeneralTableHelper.updateColumn(this.tourTable, TourTableIndex.chkpts, chkpts)
		GeneralTableHelper.updateColumn(this.tourTable, TourTableIndex.repeating, repeating)

		this.tourTable.rows.add(this.list)
		this.tourTable.draw(false)
	}

	public resetPage(reDraw: boolean) {
		this.tourTable.page(0)
		if (reDraw) {
			this.tourTable.draw(false)
		}
	}

	public fetchAndReload() {
		log('Fetch and reload')
		// const options = this.makeRequestOptions()

		this.coreSrvc.dbSrvc.bulkRead(['job', 'tour']).then((readSuccess) => {
			this.updateTable()
		})
	}

	public editRecord(id: number) {
		const action = !!id ? 'edit' : 'new'
		this.tourEditDialogManager.headerLabel = 'Manage Tour'
		this.tourEditDialogManager.isCancelBtnVisble = true
		this.tourEditDialogManager.isSubmitBtnVisible = true
		this.tourEditDialogManager.dialogData = new TourEditDialogData('TOUR_TABLE', action, null, id)
		this.tourEditDialogManager.isDialogVisible = true
		log('Edit Tour for ID', id)
	}

	public cancelEdit() {
		log('Cancel Edit')
		this.tourEditDialogManager.isDialogVisible = false
	}

	public cloneRecord(id: number) {
		this.tourEditDialogManager.headerLabel = 'Manage Tour'
		this.tourEditDialogManager.isCancelBtnVisble = true
		this.tourEditDialogManager.isSubmitBtnVisible = true
		this.tourEditDialogManager.dialogData = new TourEditDialogData('TOUR_TABLE', 'clone', null, id)
		this.tourEditDialogManager.isDialogVisible = true
	}

	public deleteRecord(id: number) {
		log('Delete Record', id)
		const tour = this.coreSrvc.dbSrvc.tourSrvc.getTourRecordById(id)

		// Check for existing job links before deletion
		const jobsWithTour = [] // tour.job_ids
		const count = jobsWithTour.length ?? 0
		if (jobsWithTour.length > 0) {
			this.coreSrvc.notifySrvc.notify(
				'error',
				'Profile Linked',
				`This profile is in use by ${count} job${count > 1 ? 's' : ''} and must first be unlinked before deleting.`,
			)
			return
		}

		if (tour) {
			this.deleteAction.recordId = id
			this.deleteAction.recordLabel = tour.description
			this.deleteAction.tableName = 'tour'
			this.deleteAction.showDeleteRecordDialog = true
		}
	}

	deleteActionCancel() {
		this.deleteAction.showDeleteRecordDialog = false
	}

	deleteActionComplete() {
		setTimeout(() => {
			this.deleteAction.showDeleteRecordDialog = false
			this.updateTable()
		}, 1000)
	}

	tourRecordUpdated(record: TourRecord) {
		log('Tour Record Updated', event)
		this.tourEditDialogManager.isDialogVisible = false
		this.coreSrvc.zone.run(() => {
			this.fetchAndReload()
		})
	}

	public showLinkedRecords(id: number, type: string) {
		log('Show Linked Records')
		const jobIds = this.coreSrvc.dbSrvc.jobSrvc.getJobsByTourId(id) || []
		let headerLabel = ''
		switch (type) {
			case 'jobs':
				headerLabel = 'Linked Jobs'
				this.linkedRecordsDialog.records = jobIds
					.map((jid) => this.coreSrvc.dbSrvc.jobSrvc.getJobById(jid))
					.filter((job) => !!job)
					.map((job) => (!job.active ? `[INACTIVE] - ${job.description}` : job.description))
				this.linkedRecordsDialog.filteredRecords = [...this.linkedRecordsDialog.records]
				break
		}
		this.linkedRecordsDialogManager.headerLabel = headerLabel
		this.linkedRecordsDialogManager.isDialogVisible = true
	}

	public filterLinkedRecords(searchText: string) {
		log('Search', searchText)
		if (!searchText) {
			this.linkedRecordsDialog.filteredRecords = [...this.linkedRecordsDialog.records]
		} else {
			this.linkedRecordsDialog.filteredRecords = this.linkedRecordsDialog.records.filter((rec: string) =>
				rec.toLowerCase().includes(searchText.toLowerCase()),
			)
		}
	}

	private initTable() {
		this.coreSrvc.displaySrvc.startSectionLoader()
		const mythis = this
		this.tourTable = $('#tourTable').DataTable(<DataTables.Settings>{
			stateSave: false,
			responsive: true,
			processing: true,
			deferRender: true,
			paging: true,
			pageLength: 50,
			lengthChange: true,
			info: true,
			select: false,
			searching: true,
			fixedHeader: DataTablesHelper.fixedHeader,
			autoWidth: false,
			data: this.list,
			order: this.defaultSortOrder,
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{
					responsivePriority: 1,
					targets: [TourTableIndex.name],
				},
				{
					// ID
					targets: TourTableIndex.id,
					visible: false,
					searchable: true,
					title: 'ID',
					data: null,
					render: (rec: TourRecord, type, full, meta) => {
						return `${rec.id}`
					},
				},
				{
					// Description
					targets: TourTableIndex.name,
					visible: true,
					searchable: true,
					title: 'Tour Name',
					data: null,
					render: (rec: TourRecord, type, full, meta) => {
						return `
						<div class="dtr-control-content">
						<div style="white-space:normal;line-height:1.2em;margin-top:3px;margin-right: 8px;max-width:400px;">${rec.description ?? '< no name >'}</div>
							
						</div>`
					},
				},
				{
					// Linked Jobs
					targets: TourTableIndex.linkedJobs,
					visible: this.coreSrvc.prefSrvc.data.tourColLinkedJobsVisible,
					searchable: this.coreSrvc.prefSrvc.data.tourColLinkedJobsVisible,
					title: 'Linked Jobs',
					data: null,
					render: (rec: TourRecord, type, full, meta) => {
						return ToursFormatter.makeLinkedRecordsButton(this.coreSrvc.dbSrvc, this.bridgeName, this.dtFilterText, rec.id, 'jobs')
					},
				},
				{
					// Tour Type
					targets: TourTableIndex.tourType,
					visible: this.coreSrvc.prefSrvc.data.tourColTourTypeVisible,
					searchable: this.coreSrvc.prefSrvc.data.tourColTourTypeVisible,
					title: 'Tour Type',
					data: null,
					render: (rec: TourRecord, type, full, meta) => {
						return rec.tourTypeLabel
					},
				},
				{
					// Waypoint Count
					targets: TourTableIndex.chkpts,
					visible: this.coreSrvc.prefSrvc.data.tourColChkptsVisible,
					searchable: this.coreSrvc.prefSrvc.data.tourColChkptsVisible,
					title: 'Chkpts',
					data: null,
					render: (rec: TourRecord, type, full, meta) => {
						const count = rec.type === 'UNSTRUCTURED' ? '-' : `${rec.xWaypoints.length}`
						return `<span style="padding-left: 25px;">${count}</span>`
					},
				},
				{
					// Repeating Tour
					targets: TourTableIndex.repeating,
					visible: this.coreSrvc.prefSrvc.data.tourColRepeatingVisible,
					searchable: this.coreSrvc.prefSrvc.data.tourColRepeatingVisible,
					title: 'Repeating',
					data: null,
					render: (rec: TourRecord, type, full, meta) => {
						return TableActionFormatter.checkmarkLink(!!rec.repeat_interval, true, 'cell-icon-lg', 35, null)
					},
				},
				{
					// Actions
					targets: TourTableIndex.actions,
					visible: this.coreSrvc.prefSrvc.data.commLogCallTextColInOutVisible,
					searchable: this.coreSrvc.prefSrvc.data.commLogCallTextColInOutVisible,
					title: 'Actions',
					data: null,
					render: (rec: TourRecord, type, full, meta) => {
						const recordId = rec.id

						const canAccessEdit = true
						const editLink = TableActionFormatter.editLink(this.bridgeName, 'editRecord', recordId, canAccessEdit)

						const canAccessDelete = true
						const deleteLink = TableActionFormatter.deleteLink(this.bridgeName, 'deleteRecord', recordId, canAccessDelete)

						const canClone = true
						const cloneLink = TableActionFormatter.cloneLink(this.bridgeName, 'cloneRecord', recordId, canClone)

						const hasAuditLog = !!rec.updated
						const canAccessAudit = true // this.canAccessAuditLog
						const auditLink = TableActionFormatter.auditLinkGlobal('tour', rec.id, '', canAccessAudit, hasAuditLog)

						return `<span class="act-ico-wrap">${editLink}${cloneLink}${deleteLink}${auditLink}</span>`
					},
				},
			],
			// 'createdRow': (row, data, dataIndex) => {
			// 	const enabled = this.checkEnabledStatus(data as Vacation)
			// 	if (!enabled) {
			// 		$(row).addClass('vac-disabled')
			// 	}
			// }
			drawCallback: (settings) => {
				this.tourTable?.columns.adjust().responsive.recalc()
				this.coreSrvc.displaySrvc.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
			},
		})
		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter('tourTable', this.tourTable)
	}
}
