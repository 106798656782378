<div>
	<textarea
		#chkboxBasicTextArea
		rows="1"
		class="form-control item-label-textarea"
		placeholder="Enter menu/list description"
		[(ngModel)]="config.label"
		(input)="textResizer.emit(chkboxBasicTextArea)"
		(focus)="clearError.emit(item)"
	></textarea>
	<div *ngIf="item.showConfig">
		<!-- Layout Style -->
		<div class="options-block options-block-radio-lg" style="margin-top: 16px; margin-bottom: 16px">
			<div>
				<div class="form-check">
					<input
						type="radio"
						[name]="'select-list-type-block-' + blockIndex"
						class="form-check-input"
						[id]="'select-block-menu-' + blockIndex"
						[value]="'MENU'"
						[(ngModel)]="config.style"
						(change)="handleStyleChange()"
					/>
					<label class="form-check-label has-pointer" [for]="'select-block-menu-' + blockIndex" style="margin-left: 4px; font-weight: bold">
						<span>Dropdown menu</span>
					</label>
					<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp.emit('selectDropdownMenu')"></i> -->
				</div>
				<div class="form-check">
					<input
						type="radio"
						[name]="'select-list-type-block-' + blockIndex"
						class="form-check-input"
						[id]="'select-block-list-' + blockIndex"
						[value]="'LIST'"
						[(ngModel)]="config.style"
						(change)="handleStyleChange()"
					/>
					<label class="form-check-label has-pointer" [for]="'select-block-list-' + blockIndex" style="margin-left: 4px; font-weight: bold">
						<span>List of options</span>
						<span *ngIf="config.style === 'LIST' && config.multi.allow" class="tts-hl-1"> (checkboxes)</span>
						<span *ngIf="config.style === 'LIST' && !config.multi.allow" class="tts-hl-1"> (radio buttons)</span>
					</label>
					<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp.emit('selectExpandedList')"></i> -->
				</div>
			</div>
		</div>

		<!-- Allow custom option -->
		<div class="checkbox-item cli-option-block d-flex justify-content-between">
			<div class="checkbox-wrap">
				<p-checkbox [(ngModel)]="config.custom.allow" [binary]="true"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="config.custom.allow = !config.custom.allow" class="has-pointer">Allow custom response</strong>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp.emit('selectAllowCustom')"></i>
			</div>
		</div>

		<!-- Allow multiple selection -->
		<div *ngIf="config.style === 'LIST'" class="checkbox-item cli-option-block d-flex justify-content-between">
			<div class="checkbox-wrap">
				<p-checkbox [(ngModel)]="config.multi.allow" [binary]="true"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="config.multi.allow = !config.multi.allow" class="has-pointer">Allow multiple selection</strong>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp.emit('selectAllowMultiple')"></i>
			</div>
		</div>

		<hr />
		<div *ngFor="let option of config.options; let idx = index">
			<div class="d-flex justify-content-between so-block">
				<textarea
					#selectBasicOptionTextArea
					[id]="'block' + '-' + blockIndex + '-option-' + idx"
					rows="1"
					class="form-control item-label-textarea"
					placeholder="Enter option description"
					[(ngModel)]="option.label"
					(input)="textResizer.emit(selectBasicOptionTextArea)"
					(keydown.enter)="handleEnterKey(option, $event)"
					(focus)="clearError.emit(item)"
				></textarea>
				<div class="icon-block">
					<i class="far fa-trash-alt icon-wrap" style="color: firebrick" (click)="deleteBtnClicked(option)"></i>
					<i class="fas fa-arrow-up icon-wrap" [class.icon-disabled]="idx === 0" (click)="moveUp(option)"></i>
					<i class="fas fa-arrow-down icon-wrap" [class.icon-disabled]="idx === config.options.length - 1" (click)="moveDown(option)"></i>
				</div>
			</div>
			<div *ngIf="selectedOption === option" style="margin-top: 18px">
				<div class="d-flex justify-content-between">
					<div style="font-size: 1.2rem; font-weight: 600; margin-left: 4px">Confirm Delete</div>
					<div style="width: 150px; text-align: right">
						<button class="btn btn-sm btn-secondary" style="margin-right: 8px; padding: 2px 8px" (click)="selectedOption = null">Cancel</button>
						<button class="btn btn-sm btn-danger" style="padding: 2px 8px" (click)="confirmDelete()">Delete</button>
					</div>
				</div>
			</div>
		</div>

		<button class="btn btn-sm btn-outline-secondary btn-block" style="margin-top: 16px" (click)="addOption()">Add Option</button>
	</div>
</div>
