import {
	ReportGlobals,
	TransactionMetaDataForReport,
	UserReportMetaData,
	IUserReportFormat,
	ReportFieldDateTimeInput,
	ReportFieldGenericInput,
	UserReportValidator,
	ReportFieldCheckboxBasic,
	UserReportChecklistConfig,
	ReportChecklistBasic,
	EmployeeChecklistType,
	ChecklistFormConfigCheckboxBasic,
	ChecklistFormConfigInputBasic,
	ReportFieldInputBasic,
	ReportFieldRangeBasic,
	ChecklistFormConfigRangeBasic,
	ChecklistFormConfigSelectBasic,
	ReportFieldSelectBasic,
} from './report-user'

import { log } from '@app/helpers/logger'

export class ChecklistReport {
	metaData: UserReportMetaData
	reportData: ChecklistReportData

	constructor(reportData: ChecklistReportData) {
		const version = reportData.version
		const format = reportData.format
		this.metaData = new UserReportMetaData(version, format)
		this.reportData = reportData
	}
}

export class ChecklistReportData {
	version = 1
	format: IUserReportFormat

	locale = 'en'
	countryCode = null

	displayPrefs = {
		format12Hour: ReportGlobals.displayPrefs.format12Hour,
	}

	transactionMetaData: TransactionMetaDataForReport

	shiftDate = new ReportFieldDateTimeInput({ type: 'DATETIME', style: 'DATE', value: new Date(), skip: false })

	employee = {
		firstName: new ReportFieldGenericInput({ style: 'INPUT' }),
		lastName: new ReportFieldGenericInput({ style: 'INPUT' }),
	}

	companyChecklist: Array<ReportFieldCheckboxBasic> = [] // Required for existing checklists using company checklists
	employeeChecklists: Array<ReportChecklistBasic> = []

	summary = {
		additionalNotes: new ReportFieldGenericInput({ style: 'TEXTAREA' }),
	}

	constructor(data?: any) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
	}

	configureChecklistOptions(config: UserReportChecklistConfig, type: EmployeeChecklistType, recordId: number) {
		// Configure employee checkout checklists - V2 Current checklist format
		for (const [listIdx, listData] of config.getEmployeeChecklistData(type, recordId).entries()) {
			if (listData.hasChecklistItems) {
				const checklistEntries = []
				for (const [itemIdx, formItem] of listData.list.entries()) {
					const tagId = `empcl-option-${listIdx}-${itemIdx}`
					switch (formItem.type) {
						case 'INPUTBASIC':
							const tfConfig = new ChecklistFormConfigInputBasic(formItem.config)
							const tfItem = new ReportFieldInputBasic({ tagId: tagId, label: tfConfig.label })
							checklistEntries.push(tfItem)
							break
						case 'CHECKBOXBASIC':
							const cbwlConfig = new ChecklistFormConfigCheckboxBasic(formItem.config)
							const cbwlItem = new ReportFieldCheckboxBasic({ tagId: tagId, label: cbwlConfig.label })
							// If the checklist item includes the comment then then enable it
							if (cbwlConfig.includeComment) cbwlItem.comment.include = true
							checklistEntries.push(cbwlItem)
							break
						case 'RANGEBASIC':
							const rngConfig = new ChecklistFormConfigRangeBasic(formItem.config)
							const rangeLabels = { left: rngConfig.rangeLabel.left, right: rngConfig.rangeLabel.right }
							const rngItem = new ReportFieldRangeBasic({
								tagId: tagId,
								label: rngConfig.label,
								min: rngConfig.min,
								max: rngConfig.max,
								step: rngConfig.step,
								rangeLabel: rangeLabels,
							})
							if (rngConfig.includeComment) rngItem.comment.include = true
							checklistEntries.push(rngItem)
							break
						case 'SELECTBASIC':
							const slctConfig = new ChecklistFormConfigSelectBasic(formItem.config)
							const slctItem = new ReportFieldSelectBasic({
								tagId: tagId,
								label: slctConfig.label,
								options: slctConfig.options,
								custom: slctConfig.custom,
								multi: slctConfig.multi,
								style: slctConfig.style,
							})
							checklistEntries.push(slctItem)
							break
					}
				}
				log('CheckListEntries', checklistEntries)
				const title = listData.title
				const instructions = listData.instructions
				const blockTagId = `epmcl-block-${listIdx}`
				const employeeChecklist = new ReportChecklistBasic({
					tagId: blockTagId,
					title: title,
					instructions: instructions,
					items: checklistEntries,
				})
				this.employeeChecklists.push(employeeChecklist)
			}
		}

		// Configure checkout checklist options from company record info - V1 DEPRECATED BUT REQUIRED
		// Only used for new reports so at some point this can get removed. Existing reports will
		// populate the companyChecklist field. The template must always keep the company section
		//
		// const options = config.companyChecklistOptions
		// let index = 0
		// for (const option of options) {
		// 	const tagId = 'company-option-' + index++
		// 	const checkbox = new ReportFieldCheckboxBasic({ tagId: tagId, label: option })
		// 	this.companyChecklist.push(checkbox)
		// }
	}
}

export class ChecklistReportValidator {
	reportData: ChecklistReportData
	isInDialog = false

	constructor(reportData?: ChecklistReportData) {
		this.reportData = reportData
	}

	get isReportValid(): boolean {
		return this.validateReport(false)
	}

	setReportData(reportData: ChecklistReportData) {
		this.reportData = reportData
	}

	validateReport(interactive: boolean): boolean {
		const reportData = this.reportData
		log('reportData', reportData)

		// Provider first name
		if (!reportData.employee.firstName.value) {
			if (interactive) {
				UserReportValidator.validationAlert('Please enter your first name.')
				UserReportValidator.scrollToRegion({ id: 'EMPLOYEE_FIRST_NAME', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}
		// Provider last name
		if (!reportData.employee.lastName.value) {
			if (interactive) {
				UserReportValidator.validationAlert('Please enter your last name.')
				UserReportValidator.scrollToRegion({ id: 'EMPLOYEE_LAST_NAME', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}

		// If nothing is missing then the report is valid
		return true
	}
}
