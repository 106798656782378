import { GeneralTableHelper, log } from '@app/helpers'
import { DatabaseService } from '@app/services'

export class NotificationsFormatter {
	public static makeLinkedRecordsButton(dbSrvc: DatabaseService, bridgeTableName: string, dtFilterText: string, id: number, type: string): string {
		const np = dbSrvc.npSrvc.getProfileById(id)
		const filterText = dtFilterText?.toLowerCase() ?? ''
		let recordsText = ''
		let recordCount = 0
		let isAllRecords = false
		switch (type) {
			case 'jobs':
				const allJobs = dbSrvc.jobSrvc.getJobs()
				const jobIds = np.job_ids || []
				const jobs = jobIds.map((jid) => dbSrvc.jobSrvc.getJobById(jid)).filter((job) => !!job)
				recordsText = jobs.map((j) => j.description?.toLowerCase()).join(',')
				recordCount = jobs.length
				isAllRecords = recordCount === allJobs.length
				break
			case 'numbers':
				// Setup supervisor filtering text
				const userNumbers = np.supervisor_phone_e164?.split(',') || []
				const userNumberNames = userNumbers
					.map((num) => dbSrvc.settingSrvc.getUserForE164Number(num))
					.filter((u) => !!u)
					.map((u) => u.first_name + ' ' + u.last_name)
					.join(',')
					.toLowerCase()

				// Setup client filterint text
				const clientNumbers = np.client_phone_e164?.split(',') ?? []
				const clientNumberNames = clientNumbers
					.map((num) => dbSrvc.settingSrvc.getUserForE164Number(num))
					.filter((u) => !!u)
					.map((u) => u.first_name + ' ' + u.last_name)
					.join(',')
					.toLowerCase()

				recordsText = `${np.supervisor_phone_e164 ?? ''},${userNumberNames},${np.client_phone_e164 ?? ''},${clientNumberNames}`
				recordCount = userNumbers.length + clientNumbers.length
				break
			case 'emails':
				// Setup supervisor filtering text
				const clientEmails = np.client_email?.split(',') ?? []
				const clientEmailNames = clientEmails
					.map((email) => dbSrvc.orgSrvc.getOrganizationByEmailAddress(email))
					.filter((c) => !!c)
					.map((c) => c.name)
					.join(',')
					.toLowerCase()

				// Setup supervisor filtering text
				const userEmails = np.supervisor_email?.split(',') ?? []
				const userEmailNames = userEmails
					.map((email) => dbSrvc.settingSrvc.getUserForEmailAddress(email))
					.filter((u) => !!u)
					.map((u) => u.first_name + ' ' + u.last_name)
					.join(',')
					.toLowerCase()

				recordsText = `${np.supervisor_email ?? ''},${userEmailNames},${np.client_email ?? ''},${clientEmailNames}`
				recordCount = clientEmails.length + userEmails.length
				break
		}
		// Check if current filter text is in the data for the associated record list
		const filterClass = filterText && recordsText.toLowerCase().includes(filterText) ? ' table-modal-btn-match' : ''
		const recordsTextHtml = recordCount > 0 ? `<div style="display:none;">${recordsText}</div>` : ''
		const sortString = GeneralTableHelper.leftPadSortString(recordCount, 3)

		return recordCount > 0
			? `${sortString}<button onclick="${bridgeTableName}.showLinkedRecords(${id}, '${type}')" class="btn btn-sm btn-default table-modal-btn${filterClass}" style="width: 105px">${
					isAllRecords ? 'all records' : recordCount === 1 ? `1 record` : recordCount + ` records`
				}</button>${recordsTextHtml}`
			: ''
	}
}
