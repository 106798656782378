<div>
	<div id="VEHICLEINSPECTION_REPORT" class="ur-separator ur-report-sep" style="margin-bottom: 15px">Vehicle Inspection Report</div>

	<div class="form-group">
		<div class="ur-basic-label">Inspection Date</div>
		<app-date-time-picker
			id="SERVICE_DATE"
			[currentDate]="reportData.inspectionDate.value"
			[pickerType]="reportData.inspectionDate.style"
			[pickerMode]="'dialog'"
			[hour12Timer]="format12Hour"
			[format]="datePickerFormat"
			(pickerDidClose)="reportData.inspectionDate.value = $event"
		>
		</app-date-time-picker>
	</div>

	<div class="form-group">
		<div
			class="ur-skip-btn"
			*ngIf="!reportData.vehicleIdentifier.value"
			(click)="reportData.vehicleIdentifier.skip = !reportData.vehicleIdentifier.skip"
		>
			{{ reportData.vehicleIdentifier.skip ? 'edit' : 'n/a' }}
		</div>
		<div class="ur-basic-label" [class.ur-label-disabled]="reportData.vehicleIdentifier.skip">Vehicle Identifier</div>
		<div>
			<input
				id="VEHICLE_IDENTIFIER"
				class="form-control"
				[class.ur-skip-input]="reportData.vehicleIdentifier.skip"
				[placeholder]="reportData.vehicleIdentifier.skip ? '- skipped -' : 'Car Number / License / etc'"
				[disabled]="reportData.vehicleIdentifier.skip"
				[(ngModel)]="reportData.vehicleIdentifier.value"
				(focus)="elementFocused($event)"
			/>
		</div>
	</div>

	<div id="REPORTER_INFORMATION" class="ur-separator ur-report-sep">Reporter Information</div>

	<div class="form-group">
		<div class="row">
			<div class="col-6">
				<div class="ur-basic-label">First Name</div>
				<div>
					<input
						id="REPORTER_FIRST_NAME"
						class="form-control"
						[(ngModel)]="reportData.inspectionReporter.firstName.value"
						(focus)="elementFocused($event)"
					/>
				</div>
			</div>
			<div class="col-6">
				<div class="ur-basic-label">Last Name</div>
				<div>
					<input
						id="REPORTER_LAST_NAME"
						class="form-control"
						[(ngModel)]="reportData.inspectionReporter.lastName.value"
						(focus)="elementFocused($event)"
					/>
				</div>
			</div>
		</div>
	</div>

	<div id="VEHICLE_MILEAGE" class="ur-separator ur-report-sep">Vehicle Mileage</div>

	<div class="form-group">
		<div
			class="ur-skip-btn"
			*ngIf="!reportData.mileage.starting.value"
			(click)="reportData.mileage.starting.skip = !reportData.mileage.starting.skip"
		>
			{{ reportData.mileage.starting.skip ? 'edit' : 'n/a' }}
		</div>
		<div class="ur-basic-label" [class.ur-label-disabled]="reportData.mileage.starting.skip">Starting Mileage</div>
		<div>
			<input
				id="MILEAGE_STARTING"
				class="form-control"
				type="tel"
				[class.ur-skip-input]="reportData.mileage.starting.skip"
				[placeholder]="reportData.mileage.starting.skip ? '- skipped -' : ''"
				[disabled]="reportData.mileage.starting.skip"
				[(ngModel)]="reportData.mileage.starting.value"
				(focus)="elementFocused($event)"
			/>
		</div>
	</div>

	<div class="form-group">
		<div class="ur-skip-btn" *ngIf="!reportData.mileage.ending.value" (click)="reportData.mileage.ending.skip = !reportData.mileage.ending.skip">
			{{ reportData.mileage.ending.skip ? 'edit' : 'n/a' }}
		</div>
		<div class="ur-basic-label" [class.ur-label-disabled]="reportData.mileage.ending.skip">Ending Mileage</div>
		<div>
			<input
				id="MILEAGE_ENDING"
				class="form-control"
				type="tel"
				[class.ur-skip-input]="reportData.mileage.ending.skip"
				[placeholder]="reportData.mileage.ending.skip ? '- skipped -' : ''"
				[disabled]="reportData.mileage.ending.skip"
				[(ngModel)]="reportData.mileage.ending.value"
				(focus)="elementFocused($event)"
			/>
		</div>
	</div>

	<!-- Vehicle Condition -->
	<div id="VECHILE_CONDITION" class="ur-separator ur-report-sep">Vehicle Condition</div>

	<div class="form-group">
		<div
			class="ur-skip-btn"
			*ngIf="!reportData.vehicleCondition.interior.value"
			(click)="reportData.vehicleCondition.interior.skip = !reportData.vehicleCondition.interior.skip"
		>
			{{ reportData.vehicleCondition.interior.skip ? 'edit' : 'n/a' }}
		</div>
		<div class="ur-basic-label" [class.ur-label-disabled]="reportData.vehicleCondition.interior.skip">Describe Interior</div>
		<textarea
			id="CONDITION_INTERIOR"
			class="form-control ur-basic-textarea"
			[class.ur-skip-textarea]="reportData.vehicleCondition.interior.skip"
			[disabled]="reportData.vehicleCondition.interior.skip"
			[placeholder]="reportData.vehicleCondition.interior.skip ? '- skipped -' : 'Describe interior'"
			[(ngModel)]="reportData.vehicleCondition.interior.value"
			(focus)="elementFocused($event)"
		>
		</textarea>
	</div>

	<div class="form-group">
		<div
			class="ur-skip-btn"
			*ngIf="!reportData.vehicleCondition.exterior.value"
			(click)="reportData.vehicleCondition.exterior.skip = !reportData.vehicleCondition.exterior.skip"
		>
			{{ reportData.vehicleCondition.exterior.skip ? 'edit' : 'n/a' }}
		</div>
		<div class="ur-basic-label" [class.ur-label-disabled]="reportData.vehicleCondition.exterior.skip">Describe Exterior</div>
		<textarea
			id="CONDITION_EXTERIOR"
			class="form-control ur-basic-textarea"
			[class.ur-skip-textarea]="reportData.vehicleCondition.exterior.skip"
			[disabled]="reportData.vehicleCondition.exterior.skip"
			[placeholder]="reportData.vehicleCondition.exterior.skip ? '- skipped -' : 'Describe exterior'"
			[(ngModel)]="reportData.vehicleCondition.exterior.value"
			(focus)="elementFocused($event)"
		>
		</textarea>
	</div>

	<div class="form-group">
		<div
			class="ur-skip-btn"
			*ngIf="!reportData.vehicleCondition.lightsAndSignals.value"
			(click)="reportData.vehicleCondition.lightsAndSignals.skip = !reportData.vehicleCondition.lightsAndSignals.skip"
		>
			{{ reportData.vehicleCondition.lightsAndSignals.skip ? 'edit' : 'n/a' }}
		</div>
		<div class="ur-basic-label" [class.ur-label-disabled]="reportData.vehicleCondition.lightsAndSignals.skip">Describe Lights and Signals</div>
		<textarea
			id="CONDITION_LIGHTSANDSIGNALS"
			class="form-control ur-basic-textarea"
			[class.ur-skip-textarea]="reportData.vehicleCondition.lightsAndSignals.skip"
			[disabled]="reportData.vehicleCondition.lightsAndSignals.skip"
			[placeholder]="reportData.vehicleCondition.lightsAndSignals.skip ? '- skipped -' : 'Describe lights and signals'"
			[(ngModel)]="reportData.vehicleCondition.lightsAndSignals.value"
			(focus)="elementFocused($event)"
		>
		</textarea>
	</div>

	<div class="form-group">
		<div
			class="ur-skip-btn"
			*ngIf="!reportData.vehicleCondition.tires.value"
			(click)="reportData.vehicleCondition.tires.skip = !reportData.vehicleCondition.tires.skip"
		>
			{{ reportData.vehicleCondition.tires.skip ? 'edit' : 'n/a' }}
		</div>
		<div class="ur-basic-label" [class.ur-label-disabled]="reportData.vehicleCondition.tires.skip">Describe Tires</div>
		<textarea
			id="CONDITION_TIRES"
			class="form-control ur-basic-textarea"
			[class.ur-skip-textarea]="reportData.vehicleCondition.tires.skip"
			[disabled]="reportData.vehicleCondition.tires.skip"
			[placeholder]="reportData.vehicleCondition.tires.skip ? '- skipped -' : 'Describe Tires'"
			[(ngModel)]="reportData.vehicleCondition.tires.value"
			(focus)="elementFocused($event)"
		>
		</textarea>
	</div>

	<div class="form-group">
		<div
			class="ur-skip-btn"
			*ngIf="!reportData.vehicleCondition.performance.value"
			(click)="reportData.vehicleCondition.performance.skip = !reportData.vehicleCondition.performance.skip"
		>
			{{ reportData.vehicleCondition.performance.skip ? 'edit' : 'n/a' }}
		</div>
		<div class="ur-basic-label" [class.ur-label-disabled]="reportData.vehicleCondition.performance.skip">Describe Performance</div>
		<textarea
			id="CONDITION_PERFORMANCE"
			class="form-control ur-basic-textarea"
			[class.ur-skip-textarea]="reportData.vehicleCondition.performance.skip"
			[disabled]="reportData.vehicleCondition.performance.skip"
			[placeholder]="reportData.vehicleCondition.performance.skip ? '- skipped -' : 'Describe Performance'"
			[(ngModel)]="reportData.vehicleCondition.performance.value"
			(focus)="elementFocused($event)"
		>
		</textarea>
	</div>

	<div id="ADDITIONAL_INFORMATION" class="ur-separator ur-report-sep">Additional Information</div>

	<div class="form-group">
		<div
			class="ur-skip-btn"
			*ngIf="!reportData.additionalInspectionInformation.occupants.value"
			(click)="reportData.additionalInspectionInformation.occupants.skip = !reportData.additionalInspectionInformation.occupants.skip"
		>
			{{ reportData.additionalInspectionInformation.occupants.skip ? 'edit' : 'n/a' }}
		</div>
		<div class="ur-basic-label" [class.ur-label-disabled]="reportData.additionalInspectionInformation.occupants.skip">Vehicle Occupants</div>
		<textarea
			id="VEHICLE_OCCUPANTS"
			class="form-control ur-basic-textarea"
			[class.ur-skip-textarea]="reportData.additionalInspectionInformation.occupants.skip"
			[disabled]="reportData.additionalInspectionInformation.occupants.skip"
			[placeholder]="reportData.additionalInspectionInformation.occupants.skip ? '- skipped -' : 'List Occupants'"
			[(ngModel)]="reportData.additionalInspectionInformation.occupants.value"
			(focus)="elementFocused($event)"
		>
		</textarea>
	</div>

	<div class="form-group">
		<div
			class="ur-skip-btn"
			*ngIf="!reportData.additionalInspectionInformation.previousOccupant.value"
			(click)="
				reportData.additionalInspectionInformation.previousOccupant.skip = !reportData.additionalInspectionInformation.previousOccupant.skip
			"
		>
			{{ reportData.additionalInspectionInformation.previousOccupant.skip ? 'edit' : 'n/a' }}
		</div>
		<div class="ur-basic-label" [class.ur-label-disabled]="reportData.additionalInspectionInformation.previousOccupant.skip">Previous Occupant</div>
		<div>
			<input
				id="PREVIOUS_OCCUPANT"
				class="form-control"
				[class.ur-skip-input]="reportData.additionalInspectionInformation.previousOccupant.skip"
				[placeholder]="reportData.additionalInspectionInformation.previousOccupant.skip ? '- skipped -' : ''"
				[disabled]="reportData.additionalInspectionInformation.previousOccupant.skip"
				[(ngModel)]="reportData.additionalInspectionInformation.previousOccupant.value"
				(focus)="elementFocused($event)"
			/>
		</div>
	</div>

	<div class="form-group">
		<div
			class="ur-skip-btn"
			*ngIf="!reportData.additionalInspectionInformation.notes.value"
			(click)="reportData.additionalInspectionInformation.notes.skip = !reportData.additionalInspectionInformation.notes.skip"
		>
			{{ reportData.additionalInspectionInformation.notes.skip ? 'edit' : 'n/a' }}
		</div>
		<div class="ur-basic-label" [class.ur-label-disabled]="reportData.additionalInspectionInformation.notes.skip">Notes / Comments</div>
		<textarea
			id="NOTES_COMMENTS"
			class="form-control ur-basic-textarea"
			[class.ur-skip-textarea]="reportData.additionalInspectionInformation.notes.skip"
			[disabled]="reportData.additionalInspectionInformation.notes.skip"
			[placeholder]="reportData.additionalInspectionInformation.notes.skip ? '- skipped -' : 'Additional Notes'"
			[(ngModel)]="reportData.additionalInspectionInformation.notes.value"
			(focus)="elementFocused($event)"
		>
		</textarea>
	</div>
</div>
