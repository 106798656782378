import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core'
import { DateTimeHelper, DisplayHelper, log } from '@app/helpers'
import {
	Checkpoint,
	CheckpointDataAccessRequest,
	DataAccessRequest,
	HelpDialogMessage,
	TourWrapper,
	TourRecord,
	TourType,
	WaypointLogRecord,
	WaypointLogStore,
} from '@app/models'
import { CoreService, DatabaseService } from '@app/services'

import moment from 'moment-timezone'
import _ from 'lodash'

class CheckpointListHeader {
	employeeName = ''
	startTime = ''
	endTime = 'Shift Open'
	siteName = ''
	jobName = ''
	jobDate = ''

	isNoShow = false

	constructor(dbSrvc: DatabaseService, transId: number) {
		const trans = dbSrvc.tranSrvc.getTransactionById(transId)
		const employeeName = dbSrvc.empSrvc.getEmployeeById(trans?.employee_id)?.name
		const siteName = dbSrvc.siteSrvc.getJobSiteById(trans?.jobsite_id)?.description
		const jobName = dbSrvc.jobSrvc.getJobById(trans?.job_id)?.description

		this.employeeName = employeeName ?? trans.employee_name
		this.jobName = jobName ?? trans.job_description
		this.siteName = siteName ?? trans.jobsite_description
		if (this.jobName === this.siteName) this.siteName = ''

		// Start / End
		if (trans.actual_start) {
			const timezone = trans.timezone
			const abbrev = moment.tz(trans.job_date, timezone).zoneAbbr()
			const format = DateTimeHelper.format12Hour ? `MMM Do, YYYY @ h:mm a` : `MMM Do, YYYY @ HH:mm`
			const actualStartIso = trans.actual_start
			const actualEndIso = trans.actual_end
			this.startTime = moment.tz(actualStartIso, timezone).format(format)
			this.endTime = actualEndIso ? moment.tz(actualEndIso, timezone).format(format) : 'Shift Open'

			// Date / Timezone
			const jobDate = moment(trans.job_date).format('MMM Do, YYYY')
			const tzDisplayName = dbSrvc.settingSrvc.getTimezoneDisplayNameForZoneName(trans.timezone)
			this.jobDate = `${jobDate} / ${abbrev}`
		} else {
			this.isNoShow = true
		}
	}
}

@Component({
    selector: 'app-checkpoints-list',
    templateUrl: './checkpoints-list.component.html',
    styleUrls: ['./checkpoints-list.component.scss'],
    standalone: false
})
export class CheckpointsListComponent implements OnInit {
	vm: CheckpointListHeader

	@Input() transId: number
	@Input() timezone: string

	wplStore: WaypointLogStore = new WaypointLogStore()
	@Input() checkpoints: Array<Checkpoint> = []

	@Output() showCheckpoint = new EventEmitter<Checkpoint>()
	@Output() checkpointSaved = new EventEmitter<boolean>()
	@Output() showAuditLogDialog = new EventEmitter<Checkpoint>()

	isUpdatingCheckpoint = false
	isDataLoaded = false

	isCreatingTour = false
	isReplacingTour = false
	isUpdatingTour = false

	currentCheckpoint: Checkpoint = null

	enableAdvancedCheckpiontTour = false

	importTourType: TourType = 'STRUCTURED'

	jobHasExistingTour = false

	constructor(
		private coreSrvc: CoreService,
		private zone: NgZone,
	) {
		this.enableAdvancedCheckpiontTour = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs().jobEnableAdvancedTours
	}

	get selectedCheckpoints(): Array<Checkpoint> {
		return this.checkpoints.filter((cp) => cp.isSelected)
	}

	ngOnInit(): void {
		this.performExistingTourCheck()
		this.loadCheckpoints()
		this.vm = new CheckpointListHeader(this.coreSrvc.dbSrvc, this.transId)
	}

	public getWplRecord(cp: Checkpoint): WaypointLogRecord {
		return this.wplStore?.getWaypointLog(cp.waypoint_uuid, cp.tour_sequence_number)
	}

	public loadCheckpoints() {
		const transId = this.transId
		if (transId) {
			const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(transId)
			this.timezone = trans.timezone
			const request = new CheckpointDataAccessRequest(transId)

			this.coreSrvc.dbSrvc.readTable('waypoint_log', { transaction_log_id: transId }).then((success) => {
				const list = this.coreSrvc.dbSrvc.wpLogSrvc.getWaypointLogList()
				this.wplStore.setWaypointLogs(list)
				log('Waypoint List', list)

				this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
					const data = result.data
					const checkpoints = data.map((rec) => new Checkpoint(rec))
					checkpoints.forEach((cp) => (cp.showImages = true))
					this.checkpoints = checkpoints
					log('Checkpoints List', checkpoints)
					if (this.checkpoints.length === 0) this.newCheckpoint()
					this.isDataLoaded = true
					this.coreSrvc.displaySrvc.enableAllTooltips()
				})
			})
		}
	}

	private performExistingTourCheck() {
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(this.transId)
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(trans?.job_id)
		const tour = this.coreSrvc.dbSrvc.tourSrvc.getTourRecordById(job?.tour_id)

		log('Trans/Job/Tour', { trans, job, tour })

		// Check if job has existing tour
		this.jobHasExistingTour = tour && tour.type !== 'UNSTRUCTURED'
	}

	public newCheckpoint() {
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(this.transId)
		const empId = trans.employee_id
		const newCheckpoint = new Checkpoint()
		newCheckpoint.transaction_log_id = trans.id
		newCheckpoint.employee_id = empId
		newCheckpoint.group = 0
		newCheckpoint.type = 'SHIFT'
		newCheckpoint.source = 'ADMIN'
		newCheckpoint.isEditing = true
		newCheckpoint.admin_created = this.makeNewCheckpointDate()
		this.currentCheckpoint = newCheckpoint
		this.checkpoints.unshift(newCheckpoint)
	}

	public checkpointUpdatingEventHandlert() {
		this.isUpdatingCheckpoint = true
	}

	public checkpointSavedEventHandler() {
		this.checkpointSaved.next(true)
		this.isUpdatingCheckpoint = false
		this.loadCheckpoints()
	}

	public editCancelledEventHandler() {
		this.checkpoints = this.checkpoints.filter((cp) => !!cp.id)
	}

	public setCurrentCheckpoint(event: Checkpoint) {
		this.currentCheckpoint = event
		setTimeout(() => {
			log('Hiding Tooltips')
			this.coreSrvc.displaySrvc.enableAllTooltips()
		}, 500)
	}

	public addCheckpointToTour() {
		this.mergeCheckpointsIntoTour()
	}

	private makeNewCheckpointDate(): string {
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(this.transId)
		return trans.actual_start
	}

	public cancelTourImport() {
		this.checkpoints.forEach((cp) => {
			cp.isSelected = false
		})
		this.isCreatingTour = false
		this.isUpdatingTour = false
		this.isReplacingTour = false
	}

	public clearSelectedCheckpoints() {
		for (const cp of this.checkpoints) {
			cp.isSelected = false
		}
	}
	public selectAllCheckpoints() {
		for (const cp of this.checkpoints) {
			cp.isSelected = true
		}
	}

	public startImportSelection() {
		this.isReplacingTour = false
		this.isCreatingTour = true
		this.selectAllCheckpoints()
	}

	public createTourTemplate() {
		if (this.selectedCheckpoints.length === 0) {
			this.coreSrvc.notifySrvc.notify('info', 'No Selection', 'You must select at least one checkpoint to create a tour.', 4)
			return
		}

		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(this.transId)
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(trans?.job_id)
		const checkpoints = this.selectedCheckpoints
		const tour = TourWrapper.buildFromCheckpoints(trans, checkpoints, this.importTourType)
		const newTourRecord = tour.buildTourRecord()
		newTourRecord.type = this.importTourType
		log('Tour Record To Submit', newTourRecord)

		this.isUpdatingTour = true
		this.isCreatingTour = false
		this.isReplacingTour = false

		setTimeout(() => {
			const request = new DataAccessRequest('tour', 'insert', newTourRecord)
			this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
				log('Tour Insert', result)

				this.coreSrvc.notifySrvc.notify(
					'success',
					'Operation Complete',
					`Your checkpoint tour has been created and may be managed via Work > Tours.`,
					4,
				)
				this.checkpoints.forEach((cp) => (cp.isSelected = false))
				this.isUpdatingTour = false
			})
		}, 750)
	}

	public cancelTourCreation() {
		this.clearSelectedCheckpoints()
		this.isCreatingTour = false
		this.isReplacingTour = false
	}

	public mergeCheckpointsIntoTour() {
		if (this.selectedCheckpoints.length === 0) {
			this.coreSrvc.notifySrvc.notify('info', 'No Selection', 'This action requires selecting at least one checkpoint.')
			return
		}

		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(this.transId)
		const checkpoints = this.checkpoints.filter((cp) => cp.isSelected)
		const jobId = trans.job_id
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)
		const tourId = job.tour_id

		const tourRequest = new DataAccessRequest('tour', null, { id: tourId })
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(tourRequest).then((result) => {
			const tourData = result.data[0]
			if (!tourData) {
				this.coreSrvc.notifySrvc.notify('error', 'No Tour Found', 'The selected checkpoint tour could not be loaded.')
				this.clearSelectedCheckpoints()
				return
			}

			const originalTour = new TourRecord(tourData)
			if (originalTour.type === 'UNSTRUCTURED') {
				this.coreSrvc.notifySrvc.notify('error', 'Not Supported', 'The tour type associated with this job does not support custom checkpoints.')
				this.clearSelectedCheckpoints()
				return
			}

			const currentTour = new TourWrapper(originalTour)
			const mergeTour = TourWrapper.buildFromCheckpoints(trans, checkpoints, currentTour.type)

			currentTour.waypoints = [...currentTour.waypoints, ...mergeTour.waypoints]
			currentTour.waypoints = _.orderBy(currentTour.waypoints, 'startOffset')

			const newTourRecord = currentTour.buildTourRecord()
			const request = new DataAccessRequest('tour', 'update', newTourRecord)
			this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
				const notifyTitle = this.selectedCheckpoints.length > 1 ? 'Checkpoints Added' : 'Checkpoint Added'
				this.isCreatingTour = false
				this.clearSelectedCheckpoints()
				this.coreSrvc.notifySrvc.notify(
					'success',
					notifyTitle,
					`The selected ${
						this.selectedCheckpoints.length > 1 ? 'checkpoints have' : 'checkpoint has'
					} been add to the current tour for this job.`,
				)
			})
		})
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)

		const tourHeader = this.jobHasExistingTour ? 'Replace Tour' : 'Create Tour'
		const tourMsg = this.jobHasExistingTour
			? `Replace the current checkpoint tour for this job.`
			: `Create a new checkpoint tour for this job using the checkpoints listed as a template.`
		switch (trigger) {
			case 'tour_action':
				help.header = tourHeader
				help.message = tourMsg
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}

const origTour = {
	id: 399,
	company_id: 1433,
	repeat_interval: 'PT1H',
	waypoints_json: [
		{ name: null, uuid: '1daca7b1-0a26-44b1-82cd-f6ff5a284346', latitude: null, longitude: null, imageFiles: [], startOffset: 20 },
		{ name: null, uuid: 'a57a3742-d5de-430d-a6b4-728614a65445', latitude: null, longitude: null, imageFiles: [], startOffset: 40 },
		{ name: null, uuid: '90732633-7971-4726-b4a1-c13df4a12dc7', latitude: null, longitude: null, imageFiles: [], startOffset: 60 },
	],
	created: '2024-05-03T21:56:52.294496Z[UTC]',
	updated: '2024-08-14T22:36:52.552178Z[UTC]',
	type: 'STRUCTURED_HOUR_START',
	description: '1 - Hour Tour Test Tour',
	waypoint_count: 3,
	job_ids: [15792],
}

const newTour = {
	id: 399,
	job_ids: [15792],
	type: 'STRUCTURED_HOUR_START',
	xWaypoints: [],
	description: '1 - Hour Tour Test Tour',
	repeat_interval: null,
	waypoint_count: 4,
	waypoints_json: [
		{
			uuid: 'c8db6784-8269-4433-ac20-eddcae51c7e5',
			startOffset: 2,
			name: null,
			imageFiles: [],
			description: null,
			latitude: null,
			longitude: null,
		},
		{ uuid: '1daca7b1-0a26-44b1-82cd-f6ff5a284346', startOffset: 20, name: null, imageFiles: [], latitude: null, longitude: null },
		{ uuid: 'a57a3742-d5de-430d-a6b4-728614a65445', startOffset: 40, name: null, imageFiles: [], latitude: null, longitude: null },
		{ uuid: '90732633-7971-4726-b4a1-c13df4a12dc7', startOffset: 60, name: null, imageFiles: [], latitude: null, longitude: null },
	],
}
