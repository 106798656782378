<div *ngIf="manager.blockTitle" class="block-title">{{ manager.blockTitle }}</div>

<div *ngIf="manager.phoneList.length > 0" class="form-group">
	<div class="phone-box" *ngFor="let entry of manager.phoneList; let idx = index">
		<div>
			{{ entry.formattedNumber }}
			<i (click)="manager.removePhoneAtIndex(idx)" class="fa fa-times float-right phone-box-x" aria-hidden="true"></i>
		</div>
		<div *ngIf="entry.name" class="text-muted">
			<small class="phone-name">{{ entry.name }}</small>
		</div>
	</div>
</div>

<table width="100%">
	<tr>
		<td style="width: 80px">
			<select class="form-control phone-country-select" [(ngModel)]="manager.dialingCode" (change)="manager.formatPhoneInput()">
				<option *ngFor="let code of manager.countryCodeData" value="{{ code.id }}">
					{{ code.id }}
				</option>
			</select>
		</td>
		<td>&nbsp;&nbsp;</td>
		<td style="max-width: 275px">
			<input
				#phoneInput
				(keyup)="manager.formatPhoneInput()"
				class="form-control"
				[class.is-valid]="manager.isInputFieldValid"
				[(ngModel)]="manager.inputField"
				type="tel"
				autocomplete="off"
			/>
		</td>
	</tr>
</table>

<button class="btn btn-sm btn-block btn-success" style="margin-top: 16px" [disabled]="!manager.isInputFieldValid" (click)="manager.addPhone()">
	Add Phone Number
</button>
