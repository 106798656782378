import { Component, OnDestroy, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { log } from '@app/helpers'
import { BannerStatus, BannerViewManager } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { Subject, Subscription } from 'rxjs'

@Component({
    selector: 'app-new-jobsite-number-banner',
    templateUrl: './new-jobsite-number-banner.component.html',
    styleUrls: ['./new-jobsite-number-banner.component.scss'],
    standalone: false
})
export class NewJobsiteNumberBannerComponent implements OnInit, OnDestroy {
	bvm: BannerViewManager
	status: BannerStatus

	subs = new Subscription()
	@Output() receivedUpdate = new Subject<boolean>()

	constructor(private router: Router, private coreSrvc: CoreService) {
		this.bvm = this.coreSrvc.bannerSrvc.viewManager
		this.status = this.coreSrvc.bannerSrvc.viewManager.jobsiteNumbers
		this.subs.add(this.coreSrvc.notifySrvc.databaseFinishedLoading.subscribe(() => this.updateUI()))

		this.subs.add(
			this.coreSrvc.excptSrvc.locationListUpdated.subscribe(() => {
				log('NewJobsiteNumberBannerComponent Got update')
				this.updateUI()
			})
		)
	}

	get shouldShowBanner(): boolean {
		return this.status?.notificationCount > 0
	}

	private updateUI() {
		const numberCount = this.coreSrvc.excptSrvc.transactionCount() + this.coreSrvc.excptSrvc.locationCount()
		this.status.notificationCount = numberCount
		this.coreSrvc.bannerSrvc.groupBannerNeedsUpdate.next(true)
	}

	public ngOnInit(): void {}
	public ngOnDestroy(): void {
		this.subs.unsubscribe()
	}
	public viewNewJobsiteNumbers() {
		this.coreSrvc.bannerSrvc.bannerPanelShouldHide.next(true)
		this.router.navigate(['/admin/exceptions'])
	}
}
