import { log } from '@app/helpers/logger'

import { Subject } from 'rxjs'
import { v4 as uuid } from 'uuid'

import imageCompression from 'browser-image-compression'
import moment from 'moment-timezone'
import S3 from 'aws-sdk/clients/s3'

export type FileUploaderMimeType =
	| 'unknown/invalid'
	| 'text/plain'
	| 'text/html'
	| 'image/png'
	| 'image/gif'
	| 'image/jpeg'
	| 'application/pdf'
	| 'application/zip'

export class FileUploaderProgress {
	bytesSent = 0
	bytesTotal = 100
	constructor(sent: number, total: number) {
		this.bytesSent = sent
		this.bytesTotal = total
	}
}

export class FileUploaderProcessedFile {
	bucket = ''
	key = ''
	name = ''
	linkText = ''
	description = ''
	sha256 = null
	ts = 0
	size = 0
	type: FileUploaderMimeType = 'unknown/invalid'

	get fileUrl(): string {
		return `https://${this.bucket}/${this.key}`
	}

	get pathComponents(): Array<string> {
		return this.key.split('/')
	}
	get companyId(): number {
		return parseInt(this.pathComponents.shift(), 10)
	}
	get fileId(): string {
		return this.pathComponents.pop()
	}
	get directory(): string {
		const components = this.pathComponents
		let tmp = components.shift()
		tmp = components.pop()
		return components.join('/')
	}

	get faIconClass(): string {
		switch (this.type) {
			case 'image/png':
			case 'image/gif':
			case 'image/jpeg':
				return 'fas fa-file-image'
			case 'application/pdf':
				return 'fas fa-file-pdf'
			case 'text/plain':
				return 'fas fa-file-text'
			case 'text/html':
				return 'fas fa-file-code'
			default:
				return 'fas fa-file'
		}
	}

	constructor(record?: any) {
		// log('FileUploadProcessedFile Record Init', record)
		if (record) {
			for (const attr in record) {
				if (this.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			if (!this.linkText) {
				this.linkText = this.name
			}
		}
	}
}

export class FileUploadManager {
	isWorking = false
	isUploading = false
	isProcessing = false

	percentComplete = 0
	inputFiles: Array<File> = []
	errorFiles: Array<File> = []

	currentProcessFile = new FileUploaderProcessedFile()
	processedFiles: Array<FileUploaderProcessedFile> = []

	companyId = 0
	bucketName = ''
	directory = ''
	date = moment().format('YYYY-MM-DD')

	useFileExtFallbackForMimeType = false
	supportedMimeTypes: Array<FileUploaderMimeType> = []

	fileLimit: number = null

	fileAddedEvent = new Subject<FileUploaderProcessedFile>()
	errorFileAddedEvent = new Subject<number>()

	get areUploadSlotsAvailable(): boolean {
		if (this.fileLimit || this.fileLimit === 0) {
			return this.processedFiles.length < this.fileLimit
		}
		return true
	}

	get hasDuplicateFiles(): boolean {
		return false
		// if (this.processedFiles.length > 1) {
		// 	const fileKeys = this.processedFiles.map((f) => `${f.name}-${f.size}`)
		// 	return new Set(fileKeys).size !== fileKeys.length
		// }
		// return false
	}

	constructor() {}

	setConfiguration(companyId: number, bucketName: string, directory: string) {
		this.companyId = companyId
		this.bucketName = bucketName
		this.directory = directory
	}

	setInputFiles(files: Array<File>) {
		if (!this.companyId || !this.bucketName || !this.directory) {
			alert('Configuration not yet set')
			return
		}
		for (const file of files) {
			this.inputFiles.push(file)
		}
	}

	getUpdateJson(): string {
		if (this.processedFiles.length > 0) {
			const files = this.processedFiles.map((f) => new FileUploaderProcessedFile(f))
			const result = { files: files }
			return JSON.stringify(result)
		} else {
			return null
		}
	}

	getNextFile(): File {
		if (this.areUploadSlotsAvailable) {
			return this.inputFiles.shift()
		} else {
			this.inputFiles = []
			return null
		}
	}

	addErrorFile(file: File) {
		this.errorFiles.push(file)
		const index = this.errorFiles.length - 1
		this.errorFileAddedEvent.next(index)
	}

	getNewProcessFile(file: File): FileUploaderProcessedFile {
		const processFile = new FileUploaderProcessedFile()
		const fileId = uuid()
		processFile.bucket = this.bucketName
		processFile.key = `${this.companyId}/${this.directory}/${this.date}/${fileId}`
		processFile.name = file.name
		processFile.linkText = file.name
		processFile.ts = file.lastModified || moment().unix()
		processFile.size = file.size
		return processFile
	}

	removeFile(file: FileUploaderProcessedFile) {
		this.processedFiles = this.processedFiles.filter((f) => f !== file)
	}

	startProcessing() {
		this.isWorking = true
		this.isUploading = false
		this.isProcessing = true
		const file = this.getNextFile()
		if (file) {
			this.currentProcessFile = this.getNewProcessFile(file)
			// processFile.name = file.name
			// processFile.linkText = file.name
			// processFile.ts = file.lastModified
			// processFile.size = file.size
			// this.currentProcessFile = processFile

			FileUploadHelper.getFileMimeType(file, this.useFileExtFallbackForMimeType).then((mimeType) => {
				log('Mime Type', mimeType)
				this.currentProcessFile.type = mimeType
				if (this.supportedMimeTypes.includes(mimeType)) {
					this.hashFile(file).then(() => {
						this.compressFile(file).then((processedFile) => {
							this.uploadFile(processedFile).then((uploadSuccess) => {
								if (uploadSuccess) {
									log('File Uploaded')
									this.startProcessing()
								} else {
									log('File Not Uploaded')
									this.startProcessing()
								}
							})
						})
					})
				} else {
					log('File Type Not Supported', file)
					this.addErrorFile(file)
					this.isProcessing = false
					this.isUploading = false
					this.startProcessing()
				}
			})
		} else {
			log('Processing Finished', this.processedFiles)
			log('Input Files', this.inputFiles)
			this.isProcessing = false
			this.isUploading = false
			this.isWorking = false
		}
	}

	private hashFile(uploadFile: File): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			const reader = new FileReader()
			reader.onload = () => {
				const buffer: any = reader.result
				crypto.subtle
					.digest('SHA-256', buffer)
					.then((hashBuffer) => {
						const hashArray = Array.from(new Uint8Array(hashBuffer))
						const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('')
						this.currentProcessFile.sha256 = hashHex
						resolve(true)
					})
					.catch(() => {
						log('Encountered error hashing file object.')
						this.currentProcessFile.sha256 = null
						resolve(true)
					})
			}
			reader.onerror = reject
			reader.readAsArrayBuffer(uploadFile)
		})
	}

	private compressFile(file: File): Promise<File> {
		return new Promise((resolve, reject) => {
			// Check if file is compressable and return original if not
			if (!FileUploadHelper.canCompress(file.type as FileUploaderMimeType)) {
				resolve(file)
			}

			log('Start compressing file', file)
			const options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 1920,
				// exifOrientation: 1,
				useWebWorker: false,
			}

			log('originalFile instanceof Blob', file instanceof Blob) // true
			log(`originalFile size ${file.size / 1024 / 1024} MB`)

			imageCompression(file, options)
				.then((compressedFile: File) => {
					this.currentProcessFile.type = compressedFile.type as FileUploaderMimeType
					this.currentProcessFile.size = compressedFile.size
					log('compressedFile instanceof Blob', compressedFile instanceof Blob) // true
					log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`) // smaller than maxSizeMB
					resolve(compressedFile)
				})
				.catch((error) => {
					log(error.message)
					this.isProcessing = false
					resolve(file)
				})
		})
	}

	uploadFile(file: File): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this.percentComplete = 0
			this.isProcessing = false
			this.isUploading = true

			const params: any = {
				Metadata: {
					company_id: `${this.companyId}`,
				},
				Bucket: this.currentProcessFile.bucket,
				Key: this.currentProcessFile.key,
				ContentType: file.type,
				Body: file,
			}

			// Add the file hash to S3 object metadata if it exists
			if (this.currentProcessFile.sha256) {
				params.Metadata.sha256 = this.currentProcessFile.sha256
			}

			const bucket = new S3()
			bucket
				.upload(params, (err, data) => {
					// this.isUploading = false
					if (err) {
						log('Upload Error', err)
						resolve(false)
					} else {
						this.processedFiles.push(this.currentProcessFile)
						this.fileAddedEvent.next(this.currentProcessFile)
						resolve(true)
					}
				})
				.on('httpUploadProgress', (evt) => {
					const progress = new FileUploaderProgress(evt.loaded, evt.total)
					this.updatePercentComplete(progress)
				})
		})
	}

	updatePercentComplete(progress: FileUploaderProgress) {
		const result = (progress.bytesSent / progress.bytesTotal) * 100
		this.percentComplete = Math.floor(result)
	}
}

export class FileUploadHelper {
	static getFileMimeType(file: File, useFileExtensionFallback): Promise<FileUploaderMimeType> {
		return new Promise((resolve, reject) => {
			const filereader = new FileReader()

			filereader.onloadend = (evt: any) => {
				if (evt.target.readyState === FileReader.DONE) {
					const uint = new Uint8Array(evt.target.result)
					const bytes = []
					uint.forEach((byte) => {
						bytes.push(byte.toString(16))
					})
					const hex = bytes.join('').toUpperCase()
					let mimeType = FileUploadHelper.getMimeTypeForSignature(hex)
					if (mimeType === 'unknown/invalid' && useFileExtensionFallback) {
						mimeType = file.type as FileUploaderMimeType
					}
					resolve(mimeType)
				}
			}

			const blob = file.slice(0, 4)
			filereader.readAsArrayBuffer(blob)
		})
	}

	static canCompress(mimeType: FileUploaderMimeType): boolean {
		switch (mimeType) {
			case 'image/png':
			case 'image/gif':
			case 'image/jpeg':
				return true
			default:
				return false
		}
	}

	static getMimeTypeForSignature(signature): FileUploaderMimeType {
		switch (signature) {
			case '3C21646F':
				return 'text/html'
			case '89504E47':
				return 'image/png'
			case '47494638':
				return 'image/gif'
			case '25504446':
				return 'application/pdf'
			case 'FFD8FFDB':
			case 'FFD8FFE0':
				return 'image/jpeg'
			case '504B0304':
				return 'application/zip'
			default:
				return 'unknown/invalid'
		}
	}

	static formatBytes(bytes, decimals = 2) {
		if (bytes === 0) {
			return '0 Bytes'
		}
		const k = 1024
		const dm = decimals <= 0 ? 0 : decimals
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		const i = Math.floor(Math.log(bytes) / Math.log(k))
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
	}
}

class FileUploadWrapper {
	file: File
	sha256: string

	constructor(file: File) {
		this.file = file
	}
}
