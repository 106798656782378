<app-activity-banner
	[isProcessing]="!isDataLoaded || isUpdating"
	[message]="isUpdating ? 'Updating Record ...' : 'Loading Data ...'"
></app-activity-banner>

<div *ngIf="isDataLoaded" [hidden]="isUpdating">
	<div *ngIf="true">
		<div class="options-block">
			<div class="options-title">Company Name</div>
			<input type="text" class="form-control" [(ngModel)]="workorder.company_name" />
		</div>

		<app-form-phone-number [manager]="companyPhoneManager" (showHelp)="showHelp($event)"></app-form-phone-number>

		<div class="options-block">
			<div class="options-title">Company Email</div>
			<input type="text" class="form-control" [(ngModel)]="workorder.company_email" />
		</div>

		<div class="options-block">
			<div class="float-right link-text" (click)="htmlNotesFullScreenVisible = true">edit</div>
			<!-- <i *ngIf="isDesktop" class="expand-editor-icon float-right fas fa-expand-alt link-text" (click)="htmlNotesFullScreenVisible = true"></i> -->
			<div class="options-title-inline">Work Order Notes</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('html_notes')"></i>
			<!-- <div [class.no-opacity]="htmlNotesFullScreenVisible">
				<p-editor [(ngModel)]="workorder.html_notes" [style]="{ height: '320px' }"></p-editor>
			</div> -->
		</div>
	</div>

	<button class="btn btn-block btn-outline-secondary" style="margin-bottom: 15px" (click)="viewTemplateAsPdf()">Preview Work Order</button>

	<div class="template-note">
		Click <span class="link-text" (click)="viewDeveloperDocs()">here</span> for a list of custom template variables you can use in various fields
		when configuring your work order template and email.
	</div>

	<app-form-expander
		[label]="'Work Order Email'"
		[centerOnExpansion]="true"
		[(isExpanded)]="expander.showWorkOrderEmail"
		[hasError]="!workorder.email_subject || !workorder.html_email_body"
	>
		<div class="alert alert-info alert-bg-gray" style="margin-bottom: 22px">
			This section configures the email sent when a pending schedule is approved with a work order. Both <b>Email Subject</b> and
			<b>Email Body</b> are required.<br /><br /><b>Additional Recipients</b> will receive a copy of all work order emails. We strongly advise that
			you add at least one additional recipient at your company to receive a copy for future reference.
		</div>

		<div class="options-block" [class.input-required]="!workorder.email_subject">
			<div *ngIf="!workorder.email_subject" class="float-right input-required">(required)</div>
			<div class="options-title-inline">Email Subject</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('email_subject')"></i>
			<textarea type="text" class="form-control" [(ngModel)]="workorder.email_subject"></textarea>
		</div>

		<div class="options-block" [class.input-required]="!workorder.html_email_body" style="margin-bottom: 10px">
			<div class="float-right">
				<span *ngIf="!workorder.html_email_body" class="input-required" style="margin-right: 6px">(required)</span>
				<span class="link-text" (click)="htmlBodyFullScreenVisible = true">edit</span>
			</div>
			<div class="options-title-inline">Email Body</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('html_email_body')"></i>
		</div>

		<div class="options-block">
			<div class="options-title-inline">Additional Recipients</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('bcc_emails')"></i>
			<app-generic-email-list [manager]="bccEmailListManager"></app-generic-email-list>
		</div>
	</app-form-expander>

	<app-form-expander [label]="'Work Order Template'" [centerOnExpansion]="true" [(isExpanded)]="expander.showWorkOrderTemplate">
		<div *ngIf="workorder.html_template" class="alert alert-success" style="text-align: center">
			You are using a <strong>custom</strong> template.
		</div>
		<div *ngIf="!workorder.html_template" class="alert alert-warning" style="text-align: center">
			You are using the <strong>default</strong> template.
		</div>

		<div>
			<div *ngIf="workorder.html_template" class="float-right">
				<span class="link-text" (click)="viewTemplateAsPdf()">view</span> /
				<span class="link-text" (click)="downloadCustomTemplateBtnClicked()">download</span> /
				<span class="link-text" (click)="workorder.html_template = null">clear</span>
			</div>
			<div *ngIf="!workorder.html_template" class="float-right">
				<span class="link-text" (click)="viewTemplateAsPdf()">view</span> /
				<a href="/assets/html/workorder_template.html" download>download</a>
			</div>
			<div>
				<div class="options-title-inline">{{ workorder.html_template ? 'Custom' : 'Default' }} Template</div>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('template')"></i>
			</div>
		</div>

		<div *ngIf="false && isInternalUser" class="options-block">
			<select class="form-select form-select-sm float-right" style="width: 170px" [(ngModel)]="engine" name="engine">
				<option value="FlyingSaucer">Flying Saucer</option>
				<option value="OpenHtml">Open HTML</option>
			</select>
			<div class="options-title-inline" style="position: relative; top: 3px">PDF Engine</div>
		</div>

		<div *ngIf="workorder.html_template" class="template-note">
			Use the links above to manage your custom template or use the dropzone below to replace it with a new version. Clearing your template will
			use the system default.
		</div>
		<div *ngIf="!workorder.html_template" class="template-note">
			<span *ngIf="isDesktop">Use the links above to view or download the default template.</span>
			<span *ngIf="!isDesktop">Use the link above to download the default template.</span>
			You can then upload your customized template using the dropzone below. Click the <strong>Save</strong> button to update your settings.
		</div>

		<div class="drop-container" appDragAndDrop (fileDropped)="onTemplateDropped($event)">
			<input type="file" #templateDropRef id="templateDropRef" (change)="templateBrowseHandler($event)" />
			<div><i class="far fa-upload up-icon"></i></div>
			<div class="drop-copy" style="width: 230px">
				Drag and drop an <strong style="color: chocolate">HTML</strong> file here or click to browse
			</div>
		</div>
	</app-form-expander>

	<!-- Terms and Conditions -->
	<app-form-expander [label]="'Terms & Conditions'" [centerOnExpansion]="true" [(isExpanded)]="expander.showWorkOrderTAC">
		<div class="alert alert-info alert-bg-gray" style="margin-bottom: 22px">
			You may upload a Terms and Conditions document which will be attached to the work order emails sent when you approve a pending schedule.
		</div>

		<div class="options-block">
			<div>
				<div *ngIf="workorder.tac_url" class="float-right">
					<span class="link-text" (click)="viewTac()">view</span> / <span class="link-text" (click)="workorder.tac_url = null">clear</span>
				</div>
				<div *ngIf="!workorder.tac_url" class="float-right input-optional">
					<span>&lt; not set &gt;</span>
				</div>
				<div>
					<div class="options-title-inline">Terms and Conditions</div>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('tac_url')"></i>
				</div>
			</div>

			<div class="drop-container" appDragAndDrop (fileDropped)="onTacFileDropped($event)">
				<input type="file" #fileDropRef id="fileDropRef" (change)="tacFileBrowseHandler($event)" />
				<div><i class="far fa-upload up-icon" [class.blink-half]="currentFile"></i></div>
				<div *ngIf="!currentFile" class="drop-copy" styl="width: 230px">
					Drag and drop a <strong style="color: chocolate">PDF</strong> file here or click to browse
				</div>
				<div class="files-progress" *ngIf="currentFile">
					<app-progress [progress]="currentFile?.progress"></app-progress>
					<div class="info">
						<div class="size float-right">
							{{ formatBytes(currentFile?.size) }}
						</div>
						<div class="name">
							{{ currentFile?.name }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</app-form-expander>
</div>

<div *ngIf="htmlNotesFullScreenVisible">
	<p-dialog
		styleClass="p-dialog-whitebackground p-dialog-htmleditor"
		header="HTML Editor"
		[(visible)]="htmlNotesFullScreenVisible"
		[modal]="true"
		[showHeader]="true"
		[dismissableMask]="false"
		[focusOnShow]="true"
		[closable]="true"
	>
		<p-editor [(ngModel)]="workorder.html_notes" [style]="{ height: '320px' }"></p-editor>
	</p-dialog>
</div>

<div *ngIf="htmlBodyFullScreenVisible">
	<p-dialog
		styleClass="p-dialog-whitebackground p-dialog-htmleditor"
		header="HTML Editor"
		[(visible)]="htmlBodyFullScreenVisible"
		[modal]="true"
		[showHeader]="true"
		[dismissableMask]="false"
		[focusOnShow]="true"
		[closable]="true"
	>
		<p-editor [(ngModel)]="workorder.html_email_body" [style]="{ height: '320px' }"></p-editor>
	</p-dialog>
</div>
