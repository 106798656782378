import { DateTimeHelper } from '@app/helpers'

export type OnboardDefinitionType = 'FILE' | 'OTHER'

export class OnboardDefinitionRecord {
	id: number
	company_id: number
	created: string // Date
	updated: string // Date

	enabled: boolean = true
	name: string = 'Deprecated Field' // Required
	description: string = null // Admin notes about this requirement
	emp_instructions: string = null // Instructions for the employee
	expires: boolean = null // Whether or not the requirement expires
	type: OnboardDefinitionType = 'FILE'
	file_count: number = null
	requires_approval: boolean = false

	constructor(record?: OnboardDefinitionRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
		}
	}

	get requestType(): string {
		switch (this.type) {
			case 'FILE':
				return 'Document Request'
			default:
				return 'Unknown Request'
		}
	}
}
