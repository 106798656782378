<div *ngIf="!isLoading && filteredChangeList.length === 0" class="alert alert-info">No change requests to display</div>

<div style="max-width: 1200px; margin: auto">
	<div *ngIf="isLoading">
		<div class="alert alert-warning loading-box"><i class="fas fa-loader fa-spin"></i> Loading Change Requests...</div>
	</div>

	<div *ngFor="let card of filteredChangeList">
		<div *ngIf="!filterId || filterId === card.changeRequest.id" class="card req-card">
			<div class="card-body">
				<div class="list-item-header">{{ card.clientName }}</div>
				<hr class="list-item-header-hr" />
				<!-- <div class="list-item-label" style="margin-top:15px;color: rgb(94, 92, 92)">Site: {{ card.jobSiteName }}</div> -->
				<div class="list-item-label" style="margin-top: 15px; color: rgb(94, 92, 92)">{{ card.jobName }}</div>
				<div class="row">
					<div class="col-6 info-column tts-hl-2">
						<div class="separator sep-current">Current Schedule</div>
						<div class="item-default"><span class="item-label">Employee Count:</span> {{ card.currentEmpCount }}</div>
						<div class="item-default"><span class="item-label">Frequency:</span> {{ card.formatFreq('current') }}</div>
						<div class="item-default"><span class="item-label">Shift:</span> {{ card.currentShiftInfo }}</div>
						<div *ngIf="card.currentFreq === Frequency.WEEKLY" class="item-default">
							<span class="item-label">Scheduled:</span> {{ card.currentDays }}
						</div>
						<div *ngIf="card.currentFreq !== Frequency.WEEKLY" class="item-default item-details">
							<small>Scheduled {{ card.currentDetails }}</small>
						</div>
						<div class="item-default"><span class="item-label">Employee(s):</span> {{ card.currentEmployees }}</div>
					</div>
					<div class="col-6 info-column">
						<div class="separator sep-proposed tts-hl-1">Requested Changes</div>
						<div class="item-default" [class.item-changed]="card.hasChanged('empCount')">
							<span class="item-label">Employee Count:</span> {{ card.requestedEmpCount }}
						</div>
						<div class="item-default" [class.item-changed]="card.hasChanged('frequency')">
							<span class="item-label">Frequency:</span> {{ card.formatFreq('requested') }}
						</div>
						<div class="item-default" [class.item-changed]="card.hasChanged('shiftInfo')">
							<span class="item-label">Shift:</span> {{ card.requestedShiftInfo }}
						</div>
						<div *ngIf="card.requestedFreq === Frequency.WEEKLY" class="item-default" [class.item-changed]="card.hasChanged('weekDays')">
							<span class="item-label">Scheduled:</span> {{ card.requestedDays }}
						</div>
						<div *ngIf="card.requestedFreq !== Frequency.WEEKLY" class="item-default item-details">
							<small>Scheduled {{ card.requestedDetails }}</small>
						</div>
					</div>
				</div>

				<div *ngIf="card.comments.length > 0" style="margin-top: 15px">
					<div *ngFor="let comment of card.comments">
						<div class="comment-box" [class.admin-comment]="comment.role === 'ADMIN'">
							<div class="d-flex justify-content-between">
								<div class="comment-date">{{ formatComment('date', comment) }}</div>
								<div class="comment-date">{{ formatComment('time', comment) }}</div>
							</div>
							<div class="comment-note">
								<span class="comment-name">{{ comment.name || 'Name Missing' }} => </span> {{ comment.content || 'Comment Missing' }}
							</div>
						</div>
					</div>
				</div>
				<div style="margin-top: 15px">
					<div *ngIf="!card.confirmChangeMessage" class="link-text" style="display: inline-block" (click)="addComment(card)">
						<i class="fa fa-plus"></i> Add comment
					</div>
					<div style="margin-top: 15px">
						<div *ngIf="card.confirmChangeMessage">
							<textarea class="form-control confirm-textarea" [placeholder]="card.commentPlaceholder" [(ngModel)]="card.actionComment">
							</textarea>
						</div>
						<div class="row">
							<div class="col-sm-12 col-md-6">
								<div *ngIf="!card.confirmChangeMessage" class="change-duration">{{ card.changeDate }}</div>
								<div
									*ngIf="card.confirmChangeMessage"
									class="change-duration"
									[class.reject-change]="card.action === 'REJECTED'"
									[class.approve-change]="card.action === 'APPROVED'"
								>
									{{ card.confirmChangeMessage }}
								</div>
							</div>
							<div class="col-sm-12 col-md-6">
								<div class="action-btn-block">
									<div class="row">
										<div class="col-6">
											<button *ngIf="!card.confirmChangeMessage" class="btn btn-sm btn-danger btn-block" (click)="rejectChange(card)">
												Reject Changes
											</button>
											<button *ngIf="card.confirmChangeMessage" class="btn btn-sm btn-danger btn-block" (click)="cancelAction(card)">
												Cancel
											</button>
										</div>
										<div class="col-6">
											<button *ngIf="!card.confirmChangeMessage" class="btn btn-sm btn-success btn-block" (click)="approveChange(card)">
												Approve Changes
											</button>
											<button *ngIf="card.confirmChangeMessage" class="btn btn-sm btn-success btn-block" (click)="confirmAction(card)">
												Confirm
											</button>
										</div>
									</div>
									<!-- <button class="btn btn-sm btn-primary modal-btn" (click)="reviewBtnClicked(card)">Review Change Request</button> -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-modal-dialog *ngIf="editDialogManager.isDialogVisible" [dialogManager]="editDialogManager">
	<app-schedule-changes-edit
		[dialogManager]="editDialogManager"
		[schedRecurId]="reviewAction.schedRecurId"
		[changeReqId]="reviewAction.changeReqId"
		(recordUpdated)="recordUpdated($event)"
	>
	</app-schedule-changes-edit>
</app-modal-dialog>
