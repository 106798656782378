import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { DateTimeHelper, log } from '@app/helpers'
import { CommLogViewTabState, CommLogViewManager, SectionSwitcherConfig, SectionSwitcherTab } from '@app/models'
import { CoreService } from '@app/services'
import { SelectItem } from 'primeng/api'
import { CommLogC2CTableComponent } from './c2c-table/comm-log-c2c-table.component'

import moment from 'moment-timezone'
import { CommLogIVRTableComponent } from './ivr-table/comm-log-ivr-table.component'

@Component({
    selector: 'app-entity',
    templateUrl: './comm-log.component.html',
    styleUrls: ['./comm-log.component.scss'],
    standalone: false
})
export class CommLogComponent implements OnInit, AfterViewInit {
	switcherConfig = new SectionSwitcherConfig()

	// accessHelper: AccessHelper
	showSectionHelp = false

	timezoneOptions: Array<SelectItem> = []

	@ViewChild('agentTable') agentTable: CommLogC2CTableComponent
	@ViewChild('ivrTable') ivrTable: CommLogIVRTableComponent
	@ViewChild('smsTable') smsTable: CommLogIVRTableComponent

	showTabSwitcher = false

	constructor(
		private router: Router,
		private coreSrvc: CoreService,
	) {
		// Setup default timezone if not alread set
		if (!this.viewManager.timezone) {
			this.viewManager.timezone = this.coreSrvc.dbSrvc.settingSrvc.getCompany().timezone
		}
		this.setupTimezoneDropdown()
		this.setupAccessPermissions()
		this.setupTabSwitcher()
	}

	get devDetect() {
		return this.coreSrvc.devDetect
	}

	get viewManager(): CommLogViewManager {
		return this.coreSrvc.dbSrvc.commLogSrvc.viewManager
	}

	get subSectionTitle(): string {
		const currentView = this.viewManager.currentView
		switch (currentView) {
			case 'IVR':
				return 'IVR Debug'
			case 'SMS':
				return 'SMS Debug'
			case 'AGENTS':
				return 'Calls / Texts'
		}
	}

	get lastUpdated(): string {
		let date: Date
		if (this.viewManager.currentView === 'IVR') {
			date = new Date()
		} else {
			date = this.coreSrvc.dbSrvc.commLogSrvc.agentLastUpdated
		}
		return `Updated ${DateTimeHelper.formatTimeFromDate(date)}`
	}

	ngOnInit() {}
	ngAfterViewInit() {
		scrollTo(0, 0)
	}

	hideDtTimePicker() {
		$('body').addClass('hide-dt-time-picker')
	}
	showDtTimePicker() {
		$('body').removeClass('hide-dt-time-picker')
	}

	setupAccessPermissions() {
		// this.accessHelper = new AccessHelper(this.coreSrvc.dbSrvc, 'CALLLOG')
	}
	canPerformAction(action) {
		return true
		// return this.accessHelper.canPerformAction(action, false)
	}

	toggleSectionHelp() {
		this.showSectionHelp = !this.showSectionHelp
		this.updateFixedHeader()
	}

	updateFixedHeader() {
		// Update fixed headers for any tables this section has
		setTimeout(() => {
			const table: any = $('#agentTable').DataTable()
			table?.fixedHeader.adjust()
		}, 100)
	}

	public fetchAndReload() {
		// this.dispatchTable?.fetchAndReloadData()
		const currentView = this.viewManager.currentView

		if (currentView === 'IVR') this.ivrTable?.fetchAndReload()
		if (currentView === 'SMS') this.smsTable?.fetchAndReload()
		if (currentView === 'AGENTS') this.agentTable?.fetchAndReload()
	}

	createRecord() {
		// this.dispatchTable.createRecord()
	}

	public openPrefsDialog() {
		this.agentTable?.openPrefsDialog()
	}

	private canAccessAgentsComponent() {
		// Check for call log availablility
		// const myRole = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser().role
		// const isRoleAllowed = myRole === 'INTERNAL' || myRole === 'PRIMARY'
		const isCallCenterEnabled = this.coreSrvc.dbSrvc.settingSrvc.isCallCenterEnabled()

		const permissions = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAccessPermissions().getUserPermissionsFor('ivr_call_log')
		const isAgentsOptionAllowed = permissions?.isOptionEnabledFor('CLGAAL') ?? false
		return isAgentsOptionAllowed // isCallCenterEnabled && isAgentsOptionAllowed
	}

	private setupTimezoneDropdown() {
		const timezones = this.coreSrvc.dbSrvc.settingSrvc
			.getSupportedTimezones()
			.filter((tz) => tz.active)
			.map((tz) => {
				return { label: tz.display_name ? tz.display_name : tz.zone_name, value: tz.zone_name }
			})
		this.timezoneOptions = timezones
	}

	// Begin Tab Swticher Methods
	private setupTabSwitcher() {
		const canAccessAgentsComponent = this.canAccessAgentsComponent()
		this.showTabSwitcher = canAccessAgentsComponent

		// Setup tabs
		const agentsTab = new SectionSwitcherTab('AGENTS', 'AGENTS')
		agentsTab.label = 'calls / texts'
		const ivrTab = new SectionSwitcherTab('IVR', 'IVR')
		ivrTab.label = 'ivr debug'
		const smsTab = new SectionSwitcherTab('SMS', 'SMS')
		smsTab.label = 'sms debug'

		let tabs = [ivrTab]

		// Check if user is allowed to view AGENTS table and set it by default for the first time
		// viewing Comm Log for this session. Subsequent viewings should the use the default
		// set in the view manager

		if (canAccessAgentsComponent) {
			if (this.viewManager.shouldCheckAccess) {
				this.viewManager.currentView = 'AGENTS'
				this.viewManager.shouldCheckAccess = false
			}
			tabs.unshift(agentsTab)
		}

		tabs.push(smsTab)
		const config = new SectionSwitcherConfig(tabs)
		this.switcherConfig = config
		this.switcherConfig.setInitialTabById(this.viewManager.currentView)
	}

	public sectionTabClicked(tab: SectionSwitcherTab) {
		switch (tab.id) {
			case 'IVR':
				this.setDisplayState('IVR')
				break
			case 'SMS':
				this.setDisplayState('SMS')
				break
			case 'AGENTS':
				const canAccess = this.canAccessAgentsComponent()
				if (canAccess) {
					this.setDisplayState('AGENTS')
				}
				break
		}
	}

	private setDisplayState(state: CommLogViewTabState) {
		this.coreSrvc.dbSrvc.commLogSrvc.viewManager.currentView = state
	}
	// End Tab Switcher Methods

	public toggleDayView() {
		this.agentTable?.resetPage(false)
		if (this.viewManager.dayViewDate) {
			this.viewManager.dayViewDate = null
		} else {
			this.viewManager.dayViewDate = new Date()
		}
		this.fetchAndReload()
	}
	public previousDay() {
		const currentMom = moment(this.viewManager.dayViewDate).subtract(1, 'day')
		this.viewManager.dayViewDate = currentMom.toDate()
		this.fetchAndReload()
	}

	public nextDay() {
		const currentMom = moment(this.viewManager.dayViewDate).add(1, 'day')
		this.viewManager.dayViewDate = currentMom.toDate()
		this.fetchAndReload()
	}

	public dayViewPickerClosed() {
		log('Day view picker closed')
		this.fetchAndReload()
	}
}
