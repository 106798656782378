<div class="section-table">
	<table id="jobSitesTable" class="stripe nowrap" cellspacing="0" width="100%"></table>
	<i id="clear-search-icon" (click)="clearSearch()" class="fa fa-times-circle-o" aria-hidden="true"></i>
	<app-table-footer-buttons
		[filename]="'job_site_records'"
		[newBtnLabel]="'Add Job Site'"
		[exportBtnLabel]="'Export Records'"
		[table]="'jobSitesTable'"
		[marginTop]="'15px'"
		(newBtnClicked)="createRecord()"
	></app-table-footer-buttons>
</div>

<app-basic-modal
	[(visible)]="editAction.showEditDialog"
	modalId="jobsiteEditModal"
	[helpVidKey]="'jobSiteEditDialog'"
	[preventBackgroundClose]="true"
	[modalHeader]="editAction.header"
	[modalFooter]="editAction.footer"
	[modalWidth]="450"
	[saveTable]="'location'"
>
	<div *ngIf="editAction.showEditDialog">
		<app-job-site-edit
			[action]="editAction.action"
			[jobSiteId]="editAction.recordId"
			(saveActionComplete)="saveActionComplete($event)"
			(editActionCancelled)="editActionCancelled()"
		></app-job-site-edit>
	</div>
</app-basic-modal>

<app-basic-modal
	[(visible)]="siteNumbers.showDialog"
	modalId="siteNumbersModal"
	[modalHeader]="'Linked Job Site Numbers'"
	[modalWidth]="450"
	[modalHeight]="500"
>
	<div *ngIf="siteNumbers.showDialog">
		<app-jobsite-numbers
			*ngIf="siteNumbers.showDialog"
			[jobSiteId]="siteNumbers.recordId"
			(closeDialog)="siteNumbers.showDialog = false"
		></app-jobsite-numbers>
	</div>
</app-basic-modal>

<app-modal-dialog *ngIf="sectionPrefsDialogManager.isDialogVisible" [dialogManager]="sectionPrefsDialogManager">
	<app-local-prefs [dialogManager]="sectionPrefsDialogManager" (prefsUpdated)="prefsDataSaved()"></app-local-prefs>
</app-modal-dialog>
