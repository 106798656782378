<div class="notify-wrap">
	<!-- <p-badge value="2" styleClass="notify-badge"></p-badge> -->
	<i *ngIf="!hasNewNotifications" class="far fa-comment-dots notify-icon" (click)="loadData(); op.toggle($event)"></i>
	<i *ngIf="hasNewNotifications" class="far fa-comment-dots notify-icon" pBadge (click)="loadData(); op.toggle($event)"></i>
	<p-popover #op styleClass="hno-panel" [baseZIndex]="5000">
		<div class="panel-wrap">
			<div class="card notification-card" *ngFor="let ni of headerNotifications">
				<div style="margin-bottom: 12px">
					<div *ngIf="ni.linkUrl" class="link-text float-right" (click)="linkClicked()">more info</div>
					<div class="hni-date">{{ ni.date }}</div>
				</div>
				<div class="hni-subject"><strong>Subject:</strong> {{ ni.subject }}</div>
				<div class="hni-summary"><strong>Summary:</strong> {{ ni.summary }}</div>
			</div>
		</div>
	</p-popover>
</div>
