import { Injectable } from '@angular/core'

import { ConnectionService } from '@app/services'

import { log } from '@app/helpers'
import { Subject } from 'rxjs'
import { WorkingDialogConfig } from '@app/models'
import { Global } from '@app/models/global'

@Injectable({
	providedIn: 'root',
})
export class WorkingService {
	public workCount = 0
	public allowWorking = true

	workingDialogEvents = new Subject<WorkingDialogConfig>()

	constructor(private connectSrvc: ConnectionService) {
		log('Creating WorkingService')

		this.connectSrvc.monitor().subscribe((isConnected) => {
			this.resetWorking()
		})
	}

	startWork() {
		if (this.allowWorking) {
			this.workCount++
		}
	}

	stopWork() {
		if (this.workCount > 0) {
			this.workCount--
		}
	}

	isWorking(): boolean {
		// log('WS Checking for work');
		return this.workCount > 0
	}

	resetWorking() {
		this.workCount = 0
	}

	blockWorking() {
		this.allowWorking = false
	}

	unblockWorking() {
		this.allowWorking = true
	}

	postWorkingDialogEvent
}
