import { environment } from '@env/environment'
import { Injectable } from '@angular/core'

import { UserLoginService, UserLoginInfo } from '@app/services/aws/user-login.service'

import { AnalyticsService, ConnectionService, NotificationsService, WorkingService } from '@app/services'

import {
	ILambdaParams,
	IReportsPayload,
	IDataAccessLambdaPayload,
	IDataAccessLambdaResult,
	IQBOSyncPayload,
	IQBOOperationsPayload,
	IProvisionCompanyPayload,
} from '@app/models'

import { DisplayHelper, log, RecordBuilder } from '@app/helpers'
import Lambda from 'aws-sdk/clients/lambda'
import { Subject } from 'rxjs'

import { Router } from '@angular/router'
import { Global } from '@app/models/global'

@Injectable({
	providedIn: 'root',
})
export class LambdaService {
	public lastErrorMsg: string = null
	public isUnderMaintenance = false

	public static DATA_ACCESS_LAMBDA_FUNCTION_NAME = environment.dataServiceLambdaFunctionName
	public static ADPSYNC_LAMBDA_FUNCTION_NAME = environment.adpSyncLambdaFunctionName
	public static FISYNC_LAMBDA_FUNCTION_NAME = environment.fiSyncLambdaFunctionName
	public static QBOSYNC_LAMBDA_FUNCTION_NAME = environment.qboSyncLambdaFunctionName
	public static QBDSYNC_LAMBDA_FUNCTION_NAME = environment.qbdSyncLambdaFunctionName
	public static QBDSYNCS3_LAMBDA_FUNCTION_NAME = environment.qbdSyncS3LambdaFunctionName
	public static QBODISCONNECT_LAMBDA_FUNCTION_NAME = environment.qboDisconnectLambdaFunctionName
	public static QBOOPERATIONS_LAMBDA_FUNCTION_NAME = environment.qboOperationsLambdaFunctionName
	public static WIWSYNC_LAMBDA_FUNCTION_NAME = environment.wiwSyncLambdaFunctionName
	public static PAYPAL_LAMBDA_FUNCTION_NAME = environment.paypalLambdaFunctionName
	public static REPORTS_LAMBDA_FUNCTION_NAME = environment.reportsLambdaFunctionName
	public static TELI_LAMBDA_FUNCTION_NAME = environment.teliLambdaFunctionName
	public static COMPANY_CREATE_LAMBDA_FUNCTION_NAME = environment.companyCreateLambdaFunctionName
	public static EMPLOYEE_REPORT_REQUEST = environment.employeeReportRequest

	public static incorrectSystemTimeError = false

	public connected = true

	public dataAccessErrorEvent = new Subject<any>()

	private companyChangedExceptionCounter = 0

	constructor(
		private userLoginSrvc: UserLoginService,
		private workingSrvc: WorkingService,
		private notifySrvc: NotificationsService,
		private analyticsSrvc: AnalyticsService,
		private connectionSrvc: ConnectionService,
		private router: Router,
	) {
		log('Creating LambdaService')

		this.connectionSrvc.monitor().subscribe((connected) => (this.connected = connected))
	}

	// Data Access Lambda Methods

	/**
	 * @param payload: A properly composed lambda payload
	 * @returns A Promise for the results of the lambda call
	 */

	dataAccess(payload: IDataAccessLambdaPayload): Promise<IDataAccessLambdaResult> {
		// Setup parameters for lambda call and stringify payload object

		// Reset last error message
		this.lastErrorMsg = null
		this.isUnderMaintenance = false

		const currentCompanyId = Global.coreSrvc?.dbSrvc?.settingSrvc?.getCompany()?.id ?? null

		const modifiedPayload = { ...payload, current_company_id: currentCompanyId, cognito_phone_e164: UserLoginInfo.e164Phone }
		log('Modified Payload', modifiedPayload)

		const lambdaParams: ILambdaParams = {
			FunctionName: LambdaService.DATA_ACCESS_LAMBDA_FUNCTION_NAME,
			Payload: JSON.stringify(modifiedPayload),
		}

		return new Promise<IDataAccessLambdaResult>((resolve, reject) => {
			this.userLoginSrvc.refresh().then((success) => {
				if (success) {
					// Credentials refresh successful

					const lambda = new Lambda()
					this.workingSrvc.startWork()

					// Invoke the lambda
					lambda.invoke(lambdaParams, (invocationError, lambdaResponse) => {
						log('Lambda Response', lambdaResponse)
						if (invocationError) {
							// Lambda call encountered an error

							log('Lambda Invocation Error:', invocationError)
							this.processInvocationError(payload, invocationError)
							this.processLambdaError(payload, lambdaResponse)
							this.isUnderMaintenance = true
							this.router.navigate(['/home/not-provisioned'])
							// reject(new Error('Lambda Invocation: ' + invocationError))
						} else {
							// Lambda call successful, check for function errors

							// Parse result of the lambda call
							const lambdaPayload = JSON.parse(lambdaResponse.Payload as string)
							const functionError = lambdaResponse.FunctionError

							if (functionError) {
								// Encountered a function error

								const functionErrorMessage = lambdaPayload.cause ? lambdaPayload.cause.errorMessage : 'DataAccess Exception Error'
								log('Data Access Function Error:', functionErrorMessage)
								log(lambdaPayload)
								this.isUnderMaintenance = true
								this.processLambdaError(payload, lambdaResponse)
								// reject(new Error('Data Access Function: ' + functionErrorMessage))
								// In this case, the lambdaPayload is the error object
								reject(lambdaPayload)
							} else {
								// DataAccess request successful

								// If no operation specified it's a read operation so package up results after
								// building items from the payload, otherwise simply return the parsed response.

								const operation = payload.operation
								const lambdaResult: IDataAccessLambdaResult = {
									payload: payload,
									data:
										!operation || operation === 'read' || operation === 'insert' || operation === 'update'
											? RecordBuilder.buildRecords(lambdaPayload)
											: lambdaPayload,
								}
								resolve(lambdaResult)
							}
						}
						this.workingSrvc.stopWork()
					})
				} else {
					reject(new Error('Could not refresh credentials'))
				}
			})
		})
	}

	private processInvocationError(requestPayload: IDataAccessLambdaPayload, invocationError: AWS.AWSError) {
		log('Processing Invocation Error', invocationError)
		if (!this.connected) {
			// this.notificationSrvc.notify('error', 'No Network Connection', 'Your network is disconnected. You are currently viewing cached data and any attempt to modify records will not succeed.')
			return
		}
		const invocationMsg = invocationError?.message || `${invocationError}`
		log('Invocation Message', invocationMsg)
		if (invocationMsg) {
			if (
				!LambdaService.incorrectSystemTimeError &&
				(invocationMsg.includes('Signature expired') || invocationMsg.includes('Signature not yet'))
			) {
				this.postSystemTimeError()
				return
			}
		}
	}

	private postSystemTimeError() {
		const hasMessagePosted = LambdaService.incorrectSystemTimeError
		log('HAS ERROR POSTED', hasMessagePosted)
		if (!hasMessagePosted) {
			this.notifySrvc.notify(
				'error',
				'Incorrect System Time',
				'Your system time appears to be set incorrectly. Please update your clock and reload your browser or contact support if you continue to see this error.',
			)
			LambdaService.incorrectSystemTimeError = true
		}
	}

	private matchAll(pattern: RegExp, haystack: string) {
		const regex = new RegExp(pattern, 'g')
		const matches = []

		const match_result = haystack.match(regex)

		for (const index in match_result) {
			if (match_result[index]) {
				const item = match_result[index]
				matches[index] = item.match(new RegExp(pattern))
			}
		}
		return matches
	}

	private processLambdaError(requestPayload: IDataAccessLambdaPayload, lambdaResponse: any) {
		const responsePayload = lambdaResponse?.Payload
		const dataPayload = responsePayload ? JSON.parse(responsePayload as string) : null
		// log('Encountered Error in Lambda Response', dataPayload)
		if (dataPayload) {
			const errorType = dataPayload['errorType']
			const dateString = new Date().toDateString()
			const errorMessage = dataPayload['errorMessage']
			const gaErrorMessage = `${dateString} - ${errorMessage}`
			const parameters = `${requestPayload.table} \ ${requestPayload.operation}`
			this.analyticsSrvc.emitGAError('DataAccessError', parameters, gaErrorMessage)
			log('DataAccess Error:', gaErrorMessage)
			// log('Error Type:', errorType)

			if (gaErrorMessage && gaErrorMessage.includes('body size is too long')) {
				this.notifySrvc.notify(
					'error',
					'Record Limit Reached',
					'Too much information was requested. Try selecting fewer records or contact support if you continue to see this error.',
				)
				return
			}

			// SupOtherCompanyAssociationException and SupCompanyAssociationException
			// AccessDeniedException, MustBePrimaryException, OperationInvalidException, ReadOnlyException, TableNotProvidedException, UnsupportedTableNameException, UserNotProvisionedException
			switch (errorType) {
				// case 'org.jooq.exception.DataAccessException':
				// 	this.notificationSrvc.notify('error', 'Not Found', 'The specified record was not found and may have been deleted by another user.')
				// 	break
				//

				case 'Function.ResponseSizeTooLarge':
					// Lambda throws this exception when payload size exceeded
					const isInTrans = requestPayload?.table === 'transaction_log'
					const responseSizeTooLargeNotification = isInTrans
						? `Too many records requested. Please adjust record Fetch Count in preferences or contact support for assistance.`
						: `Unable to transfer all requested data. Please contact support so we can investigate this issue.`
					this.notifySrvc.notify('error', 'Transfer Error', responseSizeTooLargeNotification)
					return

				case 'java.lang.ExceptionInInitializerError':
					this.lastErrorMsg = 'The system is currently down for maintenance. Please try again in a few minutes.'
					this.isUnderMaintenance = true
					this.router.navigate(['/home/not-provisioned'])
					break

				case 'com.sst.ivr.lambda.exceptions.ModifyDeletedDataException':
				case 'com.sst.ivr.lambda.exceptions.MultipleWindowException': // DEPRECATED 20241003 - Switched to ModifyDeletedDataException
					this.notifySrvc.notify(
						'error',
						'Reload Required',
						'An error occurred which requires you to reload your browser. This usually happens if you attempt to modify data that has already been deleted.',
					)
					break

				case 'com.sst.ivr.lambda.exceptions.CompanyChangedException':
					// Check to see if a modal dialog is open. If not, reload the app automatically and set a flag which will
					// trigger a toast notification after reload. If they were editing something we will just post the company
					// changed notification and not reload the app.
					const isModalOpen = $('body').hasClass('modal-open')
					if (!isModalOpen) {
						localStorage.setItem('companyChangeReloadTriggered', 'true')
						Global.coreSrvc.dbSrvc.settingSrvc.reloadApplication()
						return
					}

					if (this.companyChangedExceptionCounter === 0) {
						this.notifySrvc.clear()
						this.notifySrvc.notify(
							'error',
							'Company Changed',
							'It appears the company has been changed in another tab. Please reload the app to re-synchronize the database.',
						)
						setTimeout(() => {
							this.companyChangedExceptionCounter = 0
						}, 3000)
					}
					this.companyChangedExceptionCounter++
					break

				case 'com.sst.ivr.lambda.exceptions.NullValueInRequiredFieldException':
					this.notifySrvc.notify(
						'error',
						'Input Error',
						'A required field was left empty or contains only spaces. Please check your data and try again.',
					)
					break

				case 'com.sst.ivr.lambda.exceptions.InvalidCallStateException':
					this.notifySrvc.notify('error', 'Call Error', errorMessage)
					break
				case 'com.sst.db.tts.exceptions.MaintenanceException':
					this.lastErrorMsg = errorMessage
					this.isUnderMaintenance = true
					this.router.navigate(['/home/not-provisioned'])
					break
				case 'com.sst.ivr.lambda.exceptions.C2CPhoneNumberNotValidException':
					this.notifySrvc.notify('error', 'Invalid Number', 'You cannot redirect your calls to an IVR phone number. Please try another number.')
					break
				case 'com.sst.ivr.lambda.exceptions.EmployeeAlreadyExistsException':
					this.notifySrvc.notify('error', 'Employee Exists', `The employee already exists in the selected company.`, 3)
					break
				case 'com.sst.ivr.lambda.exceptions.RoleInUseException':
					this.notifySrvc.notify('error', 'Not Allowed', errorMessage)
					break
				case 'com.sst.ivr.lambda.exceptions.TransactionJobDateRangeException':
					this.notifySrvc.notify('error', 'Range Error', 'This time entry can no longer be edited as the job date is too old.')
					break

				case 'com.sst.ivr.lambda.exceptions.CALNoUpdateForAccountStatusException':
					// Managed: viz-health-score-banner.component.ts
					break
				case 'com.sst.db.tts.exceptions.VacationDetectedException':
					// Managed: shift-view-edit.component.ts subscribes to dataAccessErrorEvent to process error in handleDataAccessErrorEvent method
					break
				case 'com.sst.ivr.lambda.exceptions.ScheduleChangeOverlapException':
					// Managed: scheduler.component.ts subscribes to dataAccessErrorEvent to process error in handleDataAccessErrorEvent method
					break
				case 'com.sst.ivr.lambda.exceptions.TransactionEmployeeOverlapException':
					// Managed: transaction-detail-component.ts subscribes to dataAccessErrorEvent to process error in handleDataAccessErrorEvent method
					break
				case 'com.sst.db.tts.exceptions.OvertimeDetectedException':
					// Managed: shift-view-edit.component.ts subscribes to dataAccessErrorEvent to process error in handleDataAccessErrorEvent method
					break

				case 'com.sst.ivr.lambda.exceptions.VacationOverlapException':
					// Managed: time-off-editt.component.ts subscribes to dataAccessErrorEvent to process error in handleDataAccessErrorEvent method
					break
				case 'com.sst.ivr.lambda.exceptions.VacationScheduledException':
					// Managed: time-off-editt.component.ts subscribes to dataAccessErrorEvent to process error in handleDataAccessErrorEvent method
					break

				case 'com.sst.ivr.lambda.exceptions.TransactionDateLockException':
					this.notifySrvc.notify('error', 'Record Locked', `All time entries on or before ${errorMessage} have been locked.`)
					break
				case 'com.sst.ivr.lambda.exceptions.ModifyArchivedTransException':
					this.notifySrvc.notify('error', 'Entry Archived', `${errorMessage}`)
					break
				case 'com.sst.ivr.lambda.exceptions.DuplicateEmployeeJobRateException':
					this.notifySrvc.notify('error', 'Duplicate Rate', `A pay rate entry already exists for the selected employee and job.`)
					break
				case 'com.sst.ivr.lambda.exceptions.DuplicateJobNameException':
					this.notifySrvc.notify(
						'error',
						'Duplicate Job Name',
						`Job names must be unique and there is already a job with the name you selected.`,
					)
					break
				case 'com.sst.ivr.lambda.exceptions.DuplicateCompanyNameException':
					this.notifySrvc.notify('error', 'Duplicate Name', `There is already a company with that name. Please choose another name.`)
					break
				case 'com.sst.ivr.lambda.exceptions.DuplicateExternalIdException':
					this.notifySrvc.notify('error', 'Duplicate External ID', errorMessage)
					break

				case 'com.sst.ivr.lambda.exceptions.DuplicateContactNameException':
					this.notifySrvc.notify('error', 'Duplicate Name', 'Duplicate contact names are not allowed.')
					break

				case 'com.sst.ivr.lambda.exceptions.DuplicateContactTypeException':
					this.notifySrvc.notify('error', 'Duplicate Link', 'Contact has already been linked to organization.')
					break

				case 'com.sst.ivr.lambda.exceptions.PhoneNumberNotValidException':
					this.notifySrvc.notify('error', 'Invalid Phone #', `${errorMessage} does not appear to be valid for the selected region.`)
					break
				case 'com.sst.ivr.lambda.exceptions.SupCompanyAssociationException':
					this.notifySrvc.notify(
						'error',
						'Unable to Delete',
						`This user has linked records. First transfer ownership of their records to another user and try again.`,
					)
					break
				case 'com.sst.ivr.lambda.exceptions.SupOtherCompanyAssociationException':
					this.notifySrvc.notify('error', 'Deletion Issue', errorMessage)
					break
				case 'com.sst.ivr.lambda.exceptions.ClientHasPendingChangeRequestsException':
					this.notifySrvc.notify('error', 'Unable to Delete', `This client has pending schedule change requests and cannot be deleted.`)
					break
				case 'com.sst.ivr.lambda.exceptions.PhoneNumberExistsException':
					const matches = DisplayHelper.getEmbededVariables(errorMessage)
					const userEmp = matches[0]
					if (userEmp) {
						this.notifySrvc.notify('error', 'Phone Number Exists', `The phone number entered is currently assigned to ${userEmp}.`)
					} else {
						this.notifySrvc.notify('error', 'Phone Number Exists', errorMessage)
					}
					// this.notificationSrvc.notify('error', 'Phone Number Exists', `The phone number you are trying to add alredy exists.`)
					break
				case 'com.sst.ivr.lambda.exceptions.TransactionOpenException':
					this.notifySrvc.notify('error', 'Open Transaction', errorMessage)
					break
				case 'com.sst.ivr.lambda.exceptions.AccessDeniedException':
					this.notifySrvc.notify('error', 'Not Authorized', 'You are not authorized to perform this action. (BE01)')
					break

				case 'com.sst.ivr.lambda.exceptions.TransactionAlreadyOpenException':
					this.notifySrvc.notify(
						'error',
						'Duplicate Job',
						'This employee already has an open transaction for the selected job. An employee cannot have two open transactions for the same job.',
					)
					break
				case 'com.sst.ivr.lambda.exceptions.OperationInvalidException':
					this.notifySrvc.notify('error', 'Invalid Operation', 'This operation is not supported, please notify support.')
					break
				case 'com.sst.ivr.lambda.exceptions.MustBePrimaryException':
					this.notifySrvc.notify('error', 'Must Be Primary', 'You must be a primary supervisor to perform this operation.')
					break
				case 'com.sst.ivr.lambda.exceptions.ReadOnlyException':
					this.notifySrvc.notify('error', 'Read Only Access', 'Your account permissions do not allow you to modify data.')
					break
				default:
					this.notifySrvc.notify('warn', 'Error Encountered', 'Please try this action again and notify support if the issue persists.')
			}

			// Post the error to be handled elsewhere if necessary
			this.dataAccessErrorEvent.next(dataPayload)
		}
	}

	reports(payload: IReportsPayload): Promise<any> {
		const lambdaParams = {
			FunctionName: LambdaService.REPORTS_LAMBDA_FUNCTION_NAME,
			Payload: JSON.stringify(payload),
		}
		return new Promise<any>((resolve, reject) => {
			this.userLoginSrvc.refresh().then((success) => {
				this.workingSrvc.startWork()

				const lambda = new Lambda()
				lambda.invoke(lambdaParams, (lambdaError, lambdaResponse) => {
					const result = { success: null, data: lambdaResponse }
					if (lambdaError) {
						log('Reports Lambda Error', lambdaError)
						result.success = false
						resolve(result)
					} else {
						result.success = true
						resolve(result)
					}
					this.workingSrvc.stopWork()
				})
			})
		})
	}

	//////////////
	// ADP Sync //
	//////////////

	adpSync(payload: Object): Promise<any> {
		const lambdaParams = {
			FunctionName: LambdaService.ADPSYNC_LAMBDA_FUNCTION_NAME,
			Payload: JSON.stringify(payload),
		}

		return new Promise<any>((resolve, reject) => {
			this.userLoginSrvc.refresh().then((success) => {
				if (success) {
					this.workingSrvc.startWork()
					const lambda = new Lambda()
					lambda.invoke(lambdaParams, (lambdaError, lambdaResponse) => {
						// log('lambda response', lambdaResponse)
						if (lambdaError) {
							log('ADPSync lambda error', lambdaError)
							reject(lambdaError)
						} else {
							const jsonResponse = JSON.parse(lambdaResponse.Payload as string)
							// log('ADPSync Response', jsonResponse)
							if (jsonResponse.errorType) {
								log(jsonResponse.errorMessage)
								this.processAdpSyncError(jsonResponse)
							}
							resolve(jsonResponse)
						}
						this.workingSrvc.stopWork()
					})
				}
			})
		})
	}

	private processAdpSyncError(response: any) {
		const errorType = response.errorType

		switch (errorType) {
			case 'com.sst.adp.AdpClientCredsException':
				break

			default:
				this.notifySrvc.notify(
					'warn',
					'Error Encountered',
					'We encountered an error while performing an ADP Sync operation. Please contact support to resolve this issue.',
				)
		}
	}

	//////////////////////
	// File Import Sync //
	//////////////////////

	fiSync(payload: Object): Promise<any> {
		const lambdaParams = {
			FunctionName: LambdaService.FISYNC_LAMBDA_FUNCTION_NAME,
			Payload: JSON.stringify(payload),
		}

		return new Promise<any>((resolve, reject) => {
			this.userLoginSrvc.refresh().then((success) => {
				if (success) {
					this.workingSrvc.startWork()
					const lambda = new Lambda()
					lambda.invoke(lambdaParams, (lambdaError, lambdaResponse) => {
						log('lambda response', lambdaResponse)
						if (lambdaError) {
							log('FISync lambda error', lambdaError)
							reject(lambdaError)
						} else {
							const jsonResponse = JSON.parse(lambdaResponse.Payload as string)
							log('FISync Response', jsonResponse)
							if (jsonResponse.errorMessage) {
								log(jsonResponse.errorMessage)
								this.notifySrvc.notify(
									'warn',
									'Error Encountered',
									'We encountered an error while performing a File Import Sync operation. Please contact support to resolve this issue.',
								)
							}
							resolve(jsonResponse)
						}
						this.workingSrvc.stopWork()
					})
				}
			})
		})
	}

	////////////////////////
	// QuickBooks Desktop //
	////////////////////////

	qbdSyncFileUpload(content: any): Promise<any> {
		const lambdaParams = {
			FunctionName: LambdaService.QBDSYNC_LAMBDA_FUNCTION_NAME,
			Payload: JSON.stringify({ file: btoa(content) }),
		}

		return new Promise<any>((resolve, reject) => {
			this.userLoginSrvc.refresh().then((success) => {
				if (success) {
					const lambda = new Lambda()
					lambda.invoke(lambdaParams, (lambdaError, lambdaResponse) => {
						if (lambdaError) {
							reject(new Error('Error calling QBDesktop lambda'))
						} else {
							log('Lambda Submission Successful')
							resolve(lambdaResponse)
						}
					})
				} else {
					reject(new Error('Could not refresh credentials'))
				}
			})
		})
	}

	qbdSyncS3FileUploaded(payload: any): Promise<any> {
		const lambdaParams = {
			FunctionName: LambdaService.QBDSYNCS3_LAMBDA_FUNCTION_NAME,
			Payload: JSON.stringify(payload),
		}

		log('Lambda Params', lambdaParams)

		return new Promise<any>((resolve, reject) => {
			this.userLoginSrvc.refresh().then((success) => {
				if (success) {
					const lambda = new Lambda()
					lambda.invoke(lambdaParams, (lambdaError, lambdaResponse) => {
						if (lambdaError) {
							reject(new Error('Error calling QBD esktopSync S3 lambda'))
						} else {
							log('QB DesktopSync S3 Submission Successful')
							resolve(lambdaResponse)
						}
					})
				} else {
					reject(new Error('Could not refresh credentials'))
				}
			})
		})
	}

	///////////////////////
	// QuickBooks Online //
	///////////////////////

	qboSync(payload: IQBOSyncPayload): Promise<any> {
		const lambdaParams = {
			FunctionName: LambdaService.QBOSYNC_LAMBDA_FUNCTION_NAME,
			Payload: JSON.stringify(payload),
		}

		return new Promise<any>((resolve, reject) => {
			this.userLoginSrvc.refresh().then((success) => {
				if (success) {
					this.workingSrvc.startWork()
					const lambda = new Lambda()
					lambda.invoke(lambdaParams, (lambdaError, lambdaResponse) => {
						if (lambdaError) {
							log('QBOline lambda error', lambdaError)
							reject(lambdaError)
						} else {
							const jsonResponse = JSON.parse(lambdaResponse.Payload as string)
							log('QBO Response', jsonResponse)
							if (jsonResponse.errorMessage) {
								log(jsonResponse.errorMessage)
								this.notifySrvc.notify(
									'warn',
									'Error Encountered',
									'We encountered an error while performing a QuickBooks Online operation. Please contact support to resolve this issue.',
								)
							}
							resolve(jsonResponse)
						}
						this.workingSrvc.stopWork()
					})
				}
			})
		})
	}

	quickBooksOnlineDisconnect(): Promise<any> {
		const payload = {}
		const lambdaParams = {
			FunctionName: LambdaService.QBODISCONNECT_LAMBDA_FUNCTION_NAME,
			Payload: JSON.stringify(payload),
		}

		return new Promise<any>((resolve, reject) => {
			this.userLoginSrvc.refresh().then((success) => {
				if (success) {
					this.workingSrvc.startWork()
					const lambda = new Lambda()
					lambda.invoke(lambdaParams, (lambdaError, lambdaResponse) => {
						if (lambdaError) {
							log('QBO Disconnect lambda error', lambdaError)
							reject(lambdaError)
						} else {
							resolve(lambdaResponse)
						}
						this.workingSrvc.stopWork()
					})
				}
			})
		})
	}

	qboOperations(payload: IQBOOperationsPayload): Promise<any> {
		const lambdaParams = {
			FunctionName: LambdaService.QBOOPERATIONS_LAMBDA_FUNCTION_NAME,
			Payload: JSON.stringify(payload),
		}

		return new Promise<any>((resolve, reject) => {
			this.userLoginSrvc.refresh().then((success) => {
				if (success) {
					this.workingSrvc.startWork()
					const lambda = new Lambda()
					lambda.invoke(lambdaParams, (lambdaError, lambdaResponse) => {
						if (lambdaError) {
							log('QBOline lambda error', lambdaError)
							reject(lambdaError)
						} else {
							const jsonResponse = JSON.parse(lambdaResponse.Payload as string)
							log('QBO Response', jsonResponse)
							if (jsonResponse.errorMessage) {
								log(jsonResponse.errorMessage)
								this.notifySrvc.notify(
									'warn',
									'Error Encountered',
									'We encountered an error while performing a QuickBooks operation. Please contact support to resolve this issue.',
								)
							}
							resolve(jsonResponse)
						}
						this.workingSrvc.stopWork()
					})
				}
			})
		})
	}

	/////////////////
	// When I Work //
	/////////////////

	wiwSync(payload: Object): Promise<any> {
		const lambdaParams = {
			FunctionName: LambdaService.WIWSYNC_LAMBDA_FUNCTION_NAME,
			Payload: JSON.stringify(payload),
		}

		return new Promise<any>((resolve, reject) => {
			this.userLoginSrvc.refresh().then((success) => {
				if (success) {
					this.workingSrvc.startWork()
					const lambda = new Lambda()
					lambda.invoke(lambdaParams, (lambdaError, lambdaResponse) => {
						// log('lambda response', lambdaResponse)
						if (lambdaError) {
							log('ADPSync lambda error', lambdaError)
							reject(lambdaError)
						} else {
							const jsonResponse = JSON.parse(lambdaResponse.Payload as string)
							// log('WIWSync Response', jsonResponse)
							if (jsonResponse.errorMessage) {
								log(jsonResponse.errorMessage)
								this.notifySrvc.notify(
									'warn',
									'Error Encountered',
									'We encountered an error while performing a When I Work Sync operation. Please contact support to resolve this issue.',
								)
							}
							resolve(jsonResponse)
						}
						this.workingSrvc.stopWork()
					})
				}
			})
		})
	}

	///////////////////
	// Account Setup //
	///////////////////

	provisionCompany(payload: IProvisionCompanyPayload): Promise<boolean> {
		const lambdaParams = {
			FunctionName: LambdaService.COMPANY_CREATE_LAMBDA_FUNCTION_NAME,
			Payload: JSON.stringify(payload),
		}
		return new Promise<boolean>((resolve, reject) => {
			this.userLoginSrvc.refresh().then((success) => {
				if (success) {
					const lambda = new Lambda()
					lambda.invoke(lambdaParams, (lambdaError, lambdaResponse) => {
						if (lambdaError) {
							log('Error creating company', lambdaResponse)
							resolve(false)
						} else {
							log('Company create call successful')
							resolve(true)
						}
					})
				} else {
					log('Could not refresh credentials')
					resolve(false)
				}
			})
		})
	}

	provisionPhoneNumber(): Promise<boolean> {
		const payload = {
			operation: 'ttsordertollfree',
		}
		const lambdaParams = {
			FunctionName: LambdaService.TELI_LAMBDA_FUNCTION_NAME,
			Payload: JSON.stringify(payload),
		}
		return new Promise<boolean>((resolve, reject) => {
			this.userLoginSrvc.refresh().then((success) => {
				if (success) {
					const lambda = new Lambda()
					lambda.invoke(lambdaParams, (lambdaError, lambdaResponse) => {
						if (lambdaError) {
							log('Error provisioning phone number', lambdaResponse)
							resolve(false)
						} else {
							log('Phone provision call successful')
							resolve(true)
						}
					})
				} else {
					log('Could not refresh credentials')
					resolve(false)
				}
			})
		})
	}

	///////////////////
	// PayPal Lambda //
	///////////////////

	paypalInfo(payload: any): Promise<any> {
		const lambdaParams = {
			FunctionName: LambdaService.PAYPAL_LAMBDA_FUNCTION_NAME,
			Payload: JSON.stringify(payload),
		}
		return new Promise<any>((resolve, reject) => {
			this.userLoginSrvc.refresh().then((success) => {
				if (success) {
					const lambda = new Lambda()
					this.workingSrvc.startWork()
					lambda.invoke(lambdaParams, (lambdaError, lambdaResponse) => {
						const response = JSON.parse(lambdaResponse.Payload as string)
						const result = response.errorMessage
							? { success: false, errorType: response.errorType, errorMessage: response.errorMessage }
							: { success: true, data: response }

						if (!result.success) {
							console.error('PayPal Lambda Error', response)
						}

						// const result = { success: true, data: response }
						if (lambdaError) {
							log('Error calling Paypal lambda', lambdaResponse)
							result.success = false
							this.workingSrvc.stopWork()
							resolve(result)
						} else {
							log('Paypal lambda call successful')
							this.workingSrvc.stopWork()
							resolve(result)
						}
					})
				} else {
					log('Could not refresh credentials')
					this.workingSrvc.stopWork()
					reject(new Error('Could not refresh credentials'))
				}
			})
		})
	}

	//////////////////////////////
	// Employee Reports Request //
	//////////////////////////////

	employeeReportRequest(payload: any): Promise<any> {
		const lambdaParams = {
			FunctionName: LambdaService.EMPLOYEE_REPORT_REQUEST,
			Payload: JSON.stringify(payload),
		}
		return new Promise<any>((resolve, reject) => {
			const lambda = new Lambda()
			this.workingSrvc.startWork()
			lambda.invoke(lambdaParams, (lambdaError, lambdaResponse) => {
				const result = JSON.parse(lambdaResponse.Payload as string)
				if (lambdaError) {
					log('Error calling employee report lambda', lambdaResponse)
					result.success = false
					this.workingSrvc.stopWork()
					resolve(result)
				} else {
					log('Employee report lambda call successful')
					this.workingSrvc.stopWork()
					resolve(result)
				}
			})
		})
	}
}
