import { Component, OnInit, Output, EventEmitter } from '@angular/core'

import { CrudAction } from '@app/models'
import { LambdaService, DatabaseService, CoreService } from '@app/services'
import { AccessHelper } from '@app/helpers/access'
import { log } from '@app/helpers'

@Component({
    selector: 'app-qbodelete-link',
    templateUrl: './qbodelete-link.component.html',
    styleUrls: ['./qbodelete-link.component.scss'],
    standalone: false
})
export class QBODeleteLinkComponent implements OnInit {
	@Output() disconnect = new EventEmitter<boolean>()
	showConfirmation = false
	showTooltips = false

	accessHelper: AccessHelper

	CrudAction = CrudAction

	constructor(private coreSrvc: CoreService, private lambdaSrvc: LambdaService) {
		this.setupAccessPermissions()
	}

	ngOnInit() {}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'quickbooks')
	}

	canPerformAction(action: CrudAction) {
		return this.accessHelper.canPerformAction(action, false)
	}

	toggleTooltips(): boolean {
		this.showTooltips = !this.showTooltips
		return false
	}

	deleteLink(): boolean {
		this.showConfirmation = true
		return false
	}

	cancelDelete(): boolean {
		this.showConfirmation = false
		return false
	}

	confirmDelete(): boolean {
		// this.lambdaSrvc.quickBooksOnlineDisconnect().then(result => {
		// 	this.coreSrvc.dbSrvc.bulkRead(['qbo_sync', 'company']).then(success => {
		// 		this.disconnect.emit(true)
		// 	})
		// })
		const request = { operation: 'disconnect' }
		this.lambdaSrvc.qboOperations(request).then((result) => {
			this.coreSrvc.dbSrvc.bulkRead(['qbo_sync', 'company']).then((success) => {
				this.disconnect.emit(true)
			})
		})
		return false
	}
}
