<app-activity-banner [isProcessing]="!status.isLoaded" [message]="'Loading Records ...'"></app-activity-banner>

<div *ngIf="status.isLoaded && !status.isEditing">
	<div style="margin: 0px 0px 20px 0px">
		<div style="text-align: center">
			<span class="link-text" (click)="toggleExpansion(false)">collapse all</span> |
			<span class="link-text" (click)="toggleExpansion(true)">expand all</span>
		</div>
	</div>

	<div *ngFor="let vm of list">
		<div class="card">
			<div class="card-body">
				<div (click)="vm.isExpanded = !vm.isExpanded" style="cursor: pointer">
					<div class="icon-wrap float-right">
						<i class="far fa-edit act-ico act-ico-clr-green act-ico-dark-hover" (click)="$event.stopPropagation(); editRecord(vm)"></i>
						<i class="far fa-trash-alt act-ico act-ico-clr-red act-ico-dark-hover" (click)="$event.stopPropagation(); deleteRecord(vm)"></i>
						<i class="far ico-chevron" [class.fa-chevron-right]="!vm.isExpanded" [class.fa-chevron-down]="vm.isExpanded"></i>
					</div>
					<div class="pf-desc tts-hl-2">{{ vm.record.description }}</div>
					<div *ngIf="vm.record.external_id" class="pf-ext-id tts-hl-1">{{ vm.record.external_id }}</div>
				</div>

				<!-- Delete Confirmation -->
				<div *ngIf="vm.confirmDelete" class="delete-confirm-block">
					<hr class="delete-confirm-hr" />
					<div class="float-right">
						<button class="btn btn-sm btn-outline-secondary" style="margin-right: 12px" (click)="vm.confirmDelete = false">Cancel</button>
						<button class="btn btn-sm btn-outline-danger" (click)="confirmDelete(vm)" style="width: 60px">
							<span *ngIf="!vm.isDeleting">Delete</span>
							<i class="fa fa-loader fa-spin" *ngIf="vm.isDeleting"></i>
						</button>
					</div>
					<div class="delete-confirm-label">Confirm Delete</div>
				</div>

				<div *ngIf="vm.isExpanded" style="line-height: 1.5rem">
					<hr />

					<!-- Allow Clocking -->
					<div class="pf-flag-item" [class.pf-check-color]="vm.record.allow_clocking" [class.pf-ban-color]="!vm.record.allow_clocking">
						<div class="float-right pf-flag-icon">
							<i class="far" [class.fa-check]="vm.record.allow_clocking" [class.fa-ban]="!vm.record.allow_clocking"></i>
						</div>
						<div class="pf-flag-label">Allow clocking</div>
					</div>

					<!-- Allow multiple clocking -->
					<div
						class="pf-flag-item"
						[class.pf-check-color]="vm.record.allow_multiple_clocking"
						[class.pf-ban-color]="!vm.record.allow_multiple_clocking"
					>
						<div class="float-right pf-flag-icon">
							<i class="far" [class.fa-check]="vm.record.allow_multiple_clocking" [class.fa-ban]="!vm.record.allow_multiple_clocking"></i>
						</div>
						<div class="pf-flag-label">Allow multiple clocking</div>
					</div>

					<!-- Allow labor level transfers -->
					<div
						class="pf-flag-item"
						[class.pf-check-color]="vm.record.allow_labor_level_transfers"
						[class.pf-ban-color]="!vm.record.allow_labor_level_transfers"
					>
						<div class="float-right pf-flag-icon">
							<i
								class="far"
								[class.fa-check]="vm.record.allow_labor_level_transfers"
								[class.fa-ban]="!vm.record.allow_labor_level_transfers"
							></i>
						</div>
						<div class="pf-flag-label">Allow labor level transfers</div>
					</div>

					<!-- Allow pay code transactions -->
					<div
						class="pf-flag-item"
						[class.pf-check-color]="vm.record.allow_pay_code_transactions"
						[class.pf-ban-color]="!vm.record.allow_pay_code_transactions"
					>
						<div class="float-right pf-flag-icon">
							<i
								class="far"
								[class.fa-check]="vm.record.allow_pay_code_transactions"
								[class.fa-ban]="!vm.record.allow_pay_code_transactions"
							></i>
						</div>
						<div class="pf-flag-label">Allow pay code transactions</div>
					</div>

					<!-- Allow work rule transfers -->
					<div
						class="pf-flag-item"
						[class.pf-check-color]="vm.record.allow_work_rule_transfers"
						[class.pf-ban-color]="!vm.record.allow_work_rule_transfers"
					>
						<div class="float-right pf-flag-icon">
							<i class="far" [class.fa-check]="vm.record.allow_work_rule_transfers" [class.fa-ban]="!vm.record.allow_work_rule_transfers"></i>
						</div>
						<div class="pf-flag-label">Allow work rule transfers</div>
					</div>

					<!-- Enable call tracking -->
					<div
						class="pf-flag-item"
						[class.pf-check-color]="vm.record.enable_call_tracking"
						[class.pf-ban-color]="!vm.record.enable_call_tracking"
					>
						<div class="float-right pf-flag-icon">
							<i class="far" [class.fa-check]="vm.record.enable_call_tracking" [class.fa-ban]="!vm.record.enable_call_tracking"></i>
						</div>
						<div class="pf-flag-label">Enable call tracking</div>
					</div>

					<!-- Enable phone restrictions -->
					<div
						class="pf-flag-item"
						[class.pf-check-color]="vm.record.enable_phone_restrictions"
						[class.pf-ban-color]="!vm.record.enable_phone_restrictions"
					>
						<div class="float-right pf-flag-icon">
							<i class="far" [class.fa-check]="vm.record.enable_phone_restrictions" [class.fa-ban]="!vm.record.enable_phone_restrictions"></i>
						</div>
						<div class="pf-flag-label">Allow phone restrictions</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="status.isEditing">
	<app-adp-ivr-profile-edit
		[dialogManager]="dialogManager"
		[recordId]="editAction.recordId"
		(recordUpdated)="editComplete()"
	></app-adp-ivr-profile-edit>
</div>
