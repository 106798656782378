<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<form class="edit-form-block" novalidate [formGroup]="form" autocomplete="off">
		<!-- Descriptions -->
		<div class="options-block">
			<div class="options-title-inline">Description</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="description" />
		</div>

		<!-- Phone Number (E164) -->
		<app-form-phone-number id="adpIvrPhoneNumberInput" [manager]="phoneNumberManager" (showHelp)="showHelp($event)"></app-form-phone-number>

		<!-- <div class="options-block" [class.input-required]="!form.get('phone_e164').value">
			<div class="options-title-inline">Phone Number</div>
			<div *ngIf="!form.get('phone_e164').value" class="float-right">(required)</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="phone_e164" />
		</div> -->

		<!-- External ID -->
		<div class="options-block">
			<div class="options-title-inline">External ID</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="external_id" />
		</div>
	</form>
</div>
