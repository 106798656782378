<div class="container" style="max-width: 800px; margin: 0 auto">
	<div class="title-block">
		<div class="section-header" style="line-height: 1.2em">
			<div class="float-right"><i class="far fa-info-circle link-text" (click)="showInfoBox = !showInfoBox"></i></div>
			<div>Review New Jobsite Numbers</div>
		</div>
	</div>

	<div *ngIf="showInfoBox">
		<div class="alert alert-info numbers-alert" role="alert">
			<div class="intro-block">
				When an employee calls from a job site phone that has not been configured in the system, they are required to press 1 to acknowledge they
				are calling from the job site. This generates the approval request(s) you see here.<br /><br />
				<strong>Accept</strong> - If you accept a newly discovered job site phone number, it is automatically added to the appropriate job site
				and will be recognized for all future calls.<br /><br />
				<strong>Reject</strong> - If you reject a newly discovered number it will be added to the <strong>Inbound Calling</strong> block list and
				employees will not be allowed to call in from this phone number. This list can be managed via
				<strong>Admin > Settings > Inbound Call Blocking</strong>.
			</div>
		</div>
	</div>
	<div *ngIf="locationCount === 0" class="alert alert-success section-alert" style="margin-top: 15px" role="alert">
		<div class="no-issues"><i class="fa fa-check-circle-o" aria-hidden="true"></i> No new numbers to review</div>
	</div>

	<app-exceptions-location-list *ngIf="locationCount > 0"></app-exceptions-location-list>
	<!-- <app-exceptions-transaction-list *ngIf="transactionDataLoaded"></app-exceptions-transaction-list> -->
</div>
