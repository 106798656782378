<p-dialog
	#clickToCallDialog
	styleClass="p-dialog-noheader p-dialog-whitebackground c2c-trigger-host"
	header="Click To Call"
	[(visible)]="manager.showDialog"
	[modal]="true"
	[showHeader]="false"
	[dismissableMask]="manager.canDismiss"
	[focusOnShow]="false"
	transitionOptions="0ms"
	(onHide)="resetComponents()"
	(onShow)="dialogOnShow()"
>
	<div
		[hidden]="
			manager.eventStatus.isPreparingText ||
			manager.eventStatus.isSendingMessage ||
			manager.eventStatus.isPlacingCall ||
			manager.eventStatus.isUpdatingNotes
		"
	>
		<!-- Tabs -->
		<i
			*ngIf="isRefreshIconAvailable && !manager.eventStatus.isRefreshing"
			class="fa fa-refresh float-right ctc-refresh-icon"
			(click)="refreshBtnClicked()"
		></i>
		<i
			*ngIf="isRefreshIconAvailable && manager.eventStatus.isRefreshing"
			class="fas fa-loader fa-spin float-right ctc-refresh-icon"
			(click)="refreshBtnClicked()"
		></i>
		<div *ngIf="true" style="margin-bottom: 26px">
			<div
				class="table-tag option-tag no-text-select"
				[class.option-tag-active]="manager.currentView === 'CONTACTS'"
				(click)="toggleViewState('CONTACTS')"
			>
				Contacts
			</div>
			<div
				class="table-tag option-tag no-text-select"
				[class.option-tag-active]="manager.currentView === 'NOTES'"
				(click)="toggleViewState('NOTES')"
			>
				Admin Notes
			</div>
			<div
				*ngIf="canAccessCommLog"
				class="table-tag option-tag no-text-select"
				[class.option-tag-active]="manager.currentView === 'EVENTLOG'"
				(click)="toggleViewState('EVENTLOG')"
			>
				Comm Log
			</div>
		</div>

		<div class="ctc-main-view hide-scrollbars">
			<!-- Section - Contacts -->
			<app-click-to-call-directory
				#c2cDirectory
				[hidden]="manager.currentView !== 'CONTACTS'"
				(startTextMsg)="startTextMsg($event)"
				(placeCall)="placeCall($event)"
			></app-click-to-call-directory>

			<!-- Section - Notes -->
			<app-click-to-call-notes #c2cNotes [hidden]="manager.currentView !== 'NOTES'"></app-click-to-call-notes>

			<!-- Section - Event Log -->
			<app-click-to-call-event-log
				#c2cEventLog
				[hidden]="manager.currentView !== 'EVENTLOG'"
				(startTextMsg)="startTextMsg($event)"
				(placeCall)="placeCall($event)"
			></app-click-to-call-event-log>
		</div>
	</div>

	<!-- Status Messages -->
	<div *ngIf="manager.eventStatus.isSendingMessage || manager.eventStatus.isPlacingCall || manager.eventStatus.isUpdatingNotes" class="ctc-status">
		<div *ngIf="manager.eventStatus.isUpdatingNotes"><i class="fas fa-loader fa-spin"></i> Updating Notes...</div>
	</div>

	<!-- Dialog Details - Footer information with time entry info -->
	<div
		*ngIf="
			!manager.eventStatus.isSendingMessage &&
			!manager.eventStatus.isPlacingCall &&
			!manager.eventStatus.isUpdatingNotes &&
			!manager.eventStatus.isPreparingText
		"
		class="ctc-dialog-detail"
	>
		{{ dialogInfoDetail }}
	</div>
</p-dialog>
