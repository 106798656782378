import { Component, EventEmitter, OnInit, OnDestroy, Output, Input, ChangeDetectorRef } from '@angular/core'
import { ColorVendor, EmployeeRecord, JobRecord, ScheduleViewManager, ShiftViewEventBackgroundColorSource } from '@app/models'
import { DatabaseService } from '@app/services'
import { Subscription } from 'rxjs'
import _ from 'lodash'
import { log } from '@app/helpers'

class ShiftViewSelectedJob {
	jobRecord: JobRecord
	backgroundColor = '#537792'

	constructor(job: JobRecord, colorFrom: ShiftViewEventBackgroundColorSource) {
		this.jobRecord = job
		if (colorFrom === 'JOB') {
			this.backgroundColor = ColorVendor.getColorById(job.id)
		}
	}
}

@Component({
    selector: 'app-shift-view-selected-jobs',
    templateUrl: './shift-view-selected-jobs.component.html',
    styleUrls: ['./shift-view-selected-jobs.component.scss'],
    standalone: false
})
export class ShiftViewSelectedJobsComponent implements OnInit, OnDestroy {
	jobList: Array<ShiftViewSelectedJob> = []
	manager: ScheduleViewManager

	@Input() jobIds: Array<number> = []
	@Input() colorSource: ShiftViewEventBackgroundColorSource = 'SCHED'
	@Output() listChanged = new EventEmitter<boolean>()

	private subs = new Subscription()

	constructor(
		private dbSrvc: DatabaseService,
		private cd: ChangeDetectorRef,
	) {
		this.manager = this.dbSrvc.schedulerSrvc.scheduleViewManager
		this.subs.add(
			this.manager.shiftViewJobListNeedsUpdate.subscribe(() => {
				this.updateJobList()
			}),
		)
	}

	ngOnInit(): void {
		this.updateJobList()
	}
	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	private updateJobList() {
		const jobIds = this.jobIds ?? []
		const uniqueIds = _.uniq(jobIds)
		const jobs = uniqueIds.map((id) => this.dbSrvc.jobSrvc.getJobById(id)) // this.manager.shift.selectedJobIds.map((id) => this.dbSrvc.jobSrvc.getJobById(id))
		const jobList = jobs.filter((job) => !!job).map((job) => new ShiftViewSelectedJob(job, this.colorSource))
		this.jobList = _.sortBy(jobList, 'jobRecord.description')
		this.cd.markForCheck()
	}

	public removeJob(sj: ShiftViewSelectedJob) {
		const id = sj.jobRecord.id
		this.manager.shift.removeSelectedJobId(id)
		this.updateJobList()
		this.listChanged.emit(true)
	}
}
