import { Component, OnInit, Input } from '@angular/core'
import { UserReportImage } from '@app/models'
import { DeviceDetectorService } from 'ngx-device-detector'

@Component({
    selector: 'app-user-report-images',
    templateUrl: './user-report-images.component.html',
    styleUrls: ['./user-report-images.component.scss'],
    standalone: false
})
export class UserReportImagesComponent implements OnInit {
	noDescriptionProvided = '< no description provided >'
	isDesktop = false

	@Input() header = 'Image List'
	@Input() images: Array<UserReportImage> = []
	@Input() showDescriptions = true

	constructor(private devDetect: DeviceDetectorService) {
		if (this.devDetect.isDesktop()) {
			this.isDesktop = true
		}
		scrollTo(0, 0)
	}

	ngOnInit(): void {}

	showFullScreen(url: string) {
		if (this.isDesktop) {
			window.open(url, '_blank')
		}
	}
}
