import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit, AfterContentInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { Router } from '@angular/router'

import {
	VacationRecord,
	DialogControlEvent,
	SelectorPermissionName,
	DialogManager,
	VacationTypeOptions,
	VacationType,
	VacationApprovalState,
	HelpDialogMessage,
} from '@app/models'
import { CoreService } from '@app/services'

import { DateTimeHelper, log } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'

import { SelectItem } from 'primeng/api'
import { Subscription } from 'rxjs'

import moment from 'moment-timezone'
import { TimeOffFormatter } from '../time-off.formatter'
import { Global } from '@app/models/global'
import { ScheduleLogRecord } from '@app/models/schedule-log'

@Component({
    selector: 'app-vacation-detail',
    templateUrl: './time-off-edit.component.html',
    styleUrls: ['./time-off-edit.component.scss'],
    standalone: false
})
export class TimeOffEditComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {
	accessHelper: AccessHelper

	// Component instance properties

	typeOptions = VacationTypeOptions

	title: string
	isNew: boolean
	isUpdating: boolean
	isMultiDay = false

	vacForm: UntypedFormGroup
	isRecordValid = true
	showOverrideConflictsBtn = false
	canOverrideScheduleConflicts = false

	employeesDropdown: SelectItem[]
	approvalStatusOptions: Array<SelectItem> = []
	jobOptions: Array<SelectItem> = []

	managedDateFormatter = 'MMM Do, YYYY @ h:mm a'

	// showTooltips = false
	showAdvancedOptions = false

	// Manages response from VacationScheduledException
	scheduleConflicts: { showDialog: boolean; list: Array<ScheduleConflictListItem> } = { showDialog: false, list: [] }

	@Input() action: string
	@Input() vacationId: number

	@Input() dialogManager: DialogManager
	@Output() editActionCancelled = new EventEmitter<boolean>()
	@Output() saveActionComplete = new EventEmitter<number>()

	public vacation: VacationRecord
	private subs = new Subscription()

	// public pendingStatus = { canUse: false, isPending: false, isApproved: false } // DEPRECATED 20241001

	// Component constructor and initialization

	constructor(
		private cd: ChangeDetectorRef,
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
		private router: Router,
	) {
		log('VacationDetailConstructor')
		this.setupAccessPermissions()
		this.subs.add(this.coreSrvc.eventSrvc.dialogControlEvents.subscribe((event) => this.processControlEvents(event)))
		this.subs.add(this.coreSrvc.dbSrvc.lambdaSrvc.dataAccessErrorEvent.subscribe((event) => this.handleDataAccessErrorEvent(event)))

		const prefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		this.showOverrideConflictsBtn = prefs.enableTimeOffConflictOverride

		this.jobOptions = this.coreSrvc.dbSrvc.jobSrvc.getDropdownData(true, false)
	}

	// DEPRECATED 20241001
	// get showEntryOnShiftViewVisible(): boolean {
	// 	const appState = this.vacForm.get('approval_state').value as VacationApprovalState
	// 	if (!!this.pendingStatus.isPending) return false
	// 	return true
	// }

	get managedByName(): string {
		const userId = this.vacation?.approval_state_last_user_id
		const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(userId)
		if (user) {
			return user.first_name + ' ' + user.last_name
		}
		return ''
	}

	get lastManagedDate(): string {
		const approvalUpdated = this.vacation?.approval_state_last_updated
		const updated = this.vacation?.created
		const created = this.vacation?.updated
		return approvalUpdated ?? updated ?? created
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'vacation')
	}

	private processControlEvents(event: DialogControlEvent) {
		const callerId = event.callerId
		const direction = event.direction
		if (callerId === 'vacation' && direction === 'toContent') {
			const eventName = event.eventName
			if (eventName === 'saveBtnClicked') {
				this.onSubmit()
			}
		}
	}

	private handleDataAccessErrorEvent(event) {
		// log('Schedule got overlap exception', event)
		const type = event?.errorType
		if (type === 'com.sst.ivr.lambda.exceptions.VacationOverlapException') {
			const errorMsg = event?.errorMessage
			this.coreSrvc.notifySrvc.notify('error', 'Overlap Error', errorMsg)
		}
		if (type === 'com.sst.ivr.lambda.exceptions.VacationScheduledException') {
			const errorMsg = event?.errorMessage
			this.coreSrvc.notifySrvc.notify('error', 'Schedule Conflict', 'This time off request conflicts with one or more scheduled shifts.', 10)
			try {
				// errorMsg format => [{empId: 123, jobDate: '2020-01-01'}]
				const conflictList = JSON.parse(errorMsg)
				this.scheduleConflicts.list = conflictList.map((item) => new ScheduleConflictListItem(item))
				// const item = this.scheduleConflicts.list[0]
				// item.jobName = item.jobName + ' - With a bunch of text added so it is at least two lines long'
				// for (let i = 0; i < 10; i++) {
				// 	this.scheduleConflicts.list.push(item)
				// }
				this.scheduleConflicts.showDialog = true
			} catch (error) {
				log('Error parsing schedule conflict list', error)
			}
		}
	}

	public showScheduleConflict(conflict: ScheduleConflictListItem) {
		this.coreSrvc.notifySrvc.clear()
		const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
		const empId = conflict.empId
		const jobDate = conflict.jobDate
		const url = `/redirect/params`
		this.router.navigate([url], { queryParams: { dlc: 'ssvfewd', cid: companyId, empId: empId, viewDate: jobDate } })
	}

	isFormValid(): boolean {
		const empId = this.vacForm.get('employee_id').value
		const hoursPerDay = this.vacForm.get('hours').value
		const vacType = this.vacForm.get('type').value as VacationType

		const isEmpIdValid = empId || empId === 0
		const isDateRangeValid = this.dateRangeValid()

		const isHoursValid = vacType !== 'UNPAID' ? !!hoursPerDay : true

		const isValid = isEmpIdValid && isDateRangeValid && isHoursValid
		const saveBtnState = isValid ? 'enableSaveBtn' : 'disableSaveBtn'

		const event = new DialogControlEvent('vacEditModal', 'toDialog', saveBtnState, null)
		this.coreSrvc.eventSrvc.dialogNotification(event)
		return isValid
	}

	ngOnInit() {
		this.setupComponent()
		this.setupForm()
		// this.setupPendingStatus() // DEPRECATED 20241001
		this.setupAdvancedOptions()
		this.setupEmployeeDropdown()
		this.setupApprovalStatusDropdown()
		log('VacationDetail: ngOnInit', this.action, this.vacationId)
	}

	ngAfterViewInit() {}

	ngAfterContentInit() {
		this.setupDialogManager()
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	setupDialogManager() {
		this.dialogManager.submitBtnAction = () => this.onSubmit()
		this.dialogManager.canSubmit = () => this.isFormValid()
	}

	private setupComponent() {
		if (this.action === 'new') {
			this.title = 'New Vacation'
			this.vacation = new VacationRecord()
			this.isNew = true
		} else if (this.action === 'edit') {
			this.vacation = this.coreSrvc.dbSrvc.vacSrvc.getVacationById(this.vacationId)
			this.title = 'Edit Vacation'
			this.isNew = false
			if (!this.vacation) {
				this.isRecordValid = false
				{
					return
				}
			}
		}
	}

	private setupForm() {
		if (!this.isRecordValid) {
			return
		}

		const myUserPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()

		const startDate = this.vacation.start_date ? moment(this.vacation.start_date).toDate() : null
		const endDate = this.vacation.end_date ? moment(this.vacation.end_date).toDate() : null

		this.vacForm = this.fb.group({
			id: [this.vacation ? this.vacation.id : ''],
			company_id: [this.vacation ? this.vacation.company_id : ''],
			employee_id: [this.vacation ? this.vacation.employee_id : ''],
			external_id: [this.vacation ? this.vacation.external_id : ''],
			start_date: [startDate ? startDate : ''],
			end_date: [endDate ? endDate : ''],
			notes: [this.vacation ? this.vacation.notes : ''],
			schedule_time_off: [this.vacation ? this.vacation.schedule_time_off : true],
			created: [this.vacation ? this.vacation.created : ''],
			type: [this.vacation ? this.vacation.type : null],
			hours: [this.vacation ? this.formatDuration(this.vacation.hours) : null],
			emp_notes: [this.vacation ? this.vacation.emp_notes : null],
			approval_state: [this.isNew ? 'APPROVED' : this.vacation.approval_state],
			job_id: [this.isNew ? null : this.vacation.job_id],
			notify_employee: [this.isNew ? true : false],
		})

		// Update approval state to the section being viewed and change DIRECT to APPROVED
		const currentState = this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.timeOff.subSection
		this.vacForm.get('approval_state').setValue(currentState)
		if (currentState === 'DIRECT') {
			this.vacForm.get('approval_state').setValue('APPROVED')
		}

		// DEPRECATED 20241001
		// const notifyEmpOnSave = myUserPrefs.timeOffNotifyEmpNewEntry
		// this.vacForm.get('notify_emp_new_entry').setValue(notifyEmpOnSave)

		// Check for single/multi day status
		const recordStart = this.vacation?.start_date
		const recordEnd = this.vacation?.end_date
		if (recordStart !== recordEnd) {
			this.isMultiDay = true
		}
	}

	// DEPRECATED 20241001
	// private setupPendingStatus() {
	// 	const isViewingPending = this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.timeOff.subSection === 'PENDING'
	// 	const thisRecordIsPending = this.vacForm.get('approval_state').value === 'PENDING'

	// 	if (this.isNew || isViewingPending || thisRecordIsPending) {
	// 		this.pendingStatus.canUse = true
	// 		if (this.isNew && isViewingPending) {
	// 			this.pendingStatus.isPending = true
	// 		}
	// 	}
	// }

	private formatDuration(isoDur: string): string {
		if (!isoDur) {
			return null
		}
		const momDur = moment.duration(isoDur)
		return DateTimeHelper.formatDurationInHoursAndMinutes('H:mm', momDur)
	}

	private setupAdvancedOptions() {
		const myUserPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		const showAdvancedOptions = myUserPrefs.globalExpandAdvancedOptions
		this.showAdvancedOptions = showAdvancedOptions
	}

	private setupEmployeeDropdown() {
		const permissions = this.accessHelper.getPermissionsFor('vacation')
		const isRestricted = permissions.isSelectorRestrictedFor(SelectorPermissionName.employee)
		const isManager = this.coreSrvc.dbSrvc.settingSrvc.isUserAManager()
		const selectedEmpId = this.vacForm.get('employee_id').value
		const addEmpIds = selectedEmpId ? [selectedEmpId] : []

		const dropdownData = this.coreSrvc.dbSrvc.empSrvc.getEmployeeDropdown(this.coreSrvc.dbSrvc, isRestricted, isManager, addEmpIds)
		const dropdown = dropdownData.filter((item) => item.data.active || addEmpIds.includes(item.data.id))
		dropdown.unshift({ label: 'All Employees', value: 0, data: null })
		dropdown.unshift({ label: 'Select an Employee', value: null, data: null })
		this.employeesDropdown = dropdown
	}

	// DEPRECATED 20241001
	// public setupApprovalStatusDropdown() {
	// 	const currentState = this.vacForm.get('approval_state').value
	// 	const deniedNotification = this.isNew ? 'send notification' : currentState === 'REJECTED' ? 'no emp notification' : 'send emp notification'
	// 	const pendingNotification = this.isNew ? 'no notification' : currentState === 'PENDING' ? 'no emp notification' : 'send emp notification'
	// 	const options = [
	// 		{ label: 'Approved (send emp notification)', value: 'APPROVED' },
	// 		{ label: 'Approved (no emp notification)', value: 'DIRECT' },
	// 		{ label: `Denied (${deniedNotification})`, value: 'REJECTED' },
	// 		{ label: `Pending (${pendingNotification})`, value: 'PENDING' },
	// 	]
	// 	this.approvalStatusOptions = options
	// }

	public setupApprovalStatusDropdown() {
		const options = [
			{ label: 'Request Approved', value: 'APPROVED' },
			{ label: `Request Denied`, value: 'REJECTED' },
			{ label: `Request Pending`, value: 'PENDING' },
		]
		this.approvalStatusOptions = options
	}

	// Determines if form should be shown or not
	get showForm(): boolean {
		if (!this.isRecordValid) {
			return false
		}
		return true
	}

	get totalHours(): string {
		const rec = this.makeUpdateRecord()
		return TimeOffFormatter.formatHours(rec, 'TOTAL')
	}

	get isDecimalInHours(): boolean {
		const hours = this.vacForm.get('hours').value ?? ''
		return hours.includes('.')
	}

	dateRangeValid(): boolean {
		const startDate = this.vacForm.get('start_date').value
		const startMom = moment(startDate)
		const endDate = this.vacForm.get('end_date').value
		const endMom = moment(endDate)
		if (startMom.isValid() && endMom.isValid()) {
			if (endMom.isAfter(startMom, 'day') || endMom.isSame(startMom, 'day')) {
				return true
			}
		}
		return false
	}

	showEmployeeRequiredTag(): boolean {
		const empId = this.vacForm.get('employee_id').value
		if (empId === 0) {
			return false
		}
		if (empId) {
			return false
		}
		return true
	}

	hideNote(): boolean {
		const f = this.vacForm.value
		if (f.start_date === '' || f.end_date === '') {
			return true
		}
		if (f.start_date > f.end_date) {
			return false
		}
		return true
	}

	// Event methods

	performSubmissionCheck(): boolean {
		const type = this.vacForm.get('type').value
		const hours = this.vacForm.get('hours').value ?? ''
		log('Type/Hours', type, hours)
		if (type !== 'UNPAID' && !hours) {
			this.coreSrvc.notifySrvc.notify('error', 'Missing Hours', 'Hours must be set for paid time-off.', 4)
			return false
		}
		if (hours.includes('.')) {
			this.coreSrvc.notifySrvc.notify('error', 'Wrong Format', 'Decimal format not supported. Please specify hours in the form of hr:min')
			return false
		}
		return true
	}

	onSubmit(): boolean {
		// Guard against double submission
		if (this.isUpdating) return
		// FormHelper.trimOnlyWhitespace(this.vacForm)

		const isValidForSubmission = this.performSubmissionCheck()
		if (!isValidForSubmission) return

		const record = this.makeUpdateRecord()
		// log('Record to submit', record)
		// return
		this.isUpdating = true
		this.cd.detectChanges()
		if (this.isNew) {
			this.coreSrvc.dbSrvc.insertRecord('vacation', record).then((success) => {
				if (success) {
					this.coreSrvc.dbSrvc.readTable('vacation').then((result) => {
						this.saveActionComplete.emit(null)
					})
				} else {
					this.isUpdating = false
				}
			})
		} else {
			this.coreSrvc.dbSrvc.updateRecord('vacation', record).then((success) => {
				if (success) {
					this.saveActionComplete.emit(record.id)
				} else {
					this.isUpdating = false
				}
			})
		}
		return false
	}

	onCancel(): boolean {
		this.editActionCancelled.emit(true)
		return false
	}

	public toggleCheckbox(prop: string) {
		this.vacForm.get(prop).setValue(!this.vacForm.get(prop).value)
	}

	private makeDuration(dur: string) {
		if (!dur) {
			return null
		}
		let adjustDur = dur
		if (!adjustDur.includes(':')) {
			adjustDur += ':00'
		}
		const momDur = moment.duration(adjustDur).toISOString()
		return momDur
	}

	private makeUpdateRecord(): VacationRecord {
		const form: VacationRecord = this.vacForm.value
		const rec = new VacationRecord()
		const startDate = form.start_date ? moment(form.start_date).format('YYYY-MM-DD') : ''
		const endDate = form.end_date ? moment(form.end_date).format('YYYY-MM-DD') : ''
		rec.id = form.id
		rec.company_id = form.company_id
		rec.employee_id = form.employee_id
		rec.start_date = startDate
		rec.end_date = endDate
		rec.external_id = form.external_id
		rec.notes = form.notes
		rec.created = form.created
		rec.schedule_time_off = form.schedule_time_off
		rec.type = form.type
		rec.hours = form.type !== 'UNPAID' ? this.makeDuration(form.hours) : null
		rec.approval_state = form.approval_state
		rec.job_id = form.job_id

		// Override show on schedule if approval state is REJECTED
		if (rec.approval_state === 'REJECTED') {
			rec.schedule_time_off = false
		}

		// Set virtual flag for notifying employee on new timee off entry
		rec.notify_employee = form.notify_employee
		// If it's for All Employees then set the flag to false
		if (form.employee_id === 0) rec.notify_employee = false

		// Handle override conflicts
		if (this.canOverrideScheduleConflicts) rec['schedule_override'] = true

		return rec
	}

	public overrideConflictsBtnClicked() {
		this.scheduleConflicts.showDialog = false
		this.coreSrvc.notifySrvc.clear()
		this.canOverrideScheduleConflicts = true
		this.onSubmit()
	}

	public handleStartDateChange() {
		if (!this.isMultiDay) {
			const startDate = this.vacForm.get('start_date').value
			this.vacForm.get('end_date').setValue(startDate)
		}
	}

	public handleApprovalStateChange() {
		if (!this.isNew) {
			const stateChanged = this.vacForm.get('approval_state').value !== this.vacation?.approval_state
			if (stateChanged) {
				this.vacForm.get('notify_employee').setValue(true)
			}
		}
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'externalID':
				help.header = 'External ID'
				help.message = 'Optional field used to link this record with a unique external identifier.'
				break
			case 'notify_employee':
				help.header = 'Notifications'
				help.message = 'When checked, the employee will received a notification related to the status of this time off request.'
				break
			case 'timeOff':
				help.header = 'Schedule Time Off'
				help.message =
					'When checked, time-off entries will be added to the top of the Shift View scheduler to indicate which days the employee is off. If the employee is assigned to any other shifts for a given day, the time-off entry will be red to indicate a scheduling conflict.'
				break
			case 'job_id':
				help.header = 'Job'
				help.message = `When linking this time off entry to a specific job, your business insight reports will associate this time with the selected job.`
				break
			case 'hoursOff':
				help.header = 'Hours Off'
				help.message = this.isMultiDay
					? 'Enter the number of hours off per day. This will be used to calculate the total number of hours for reporting purposes.'
					: 'Enter the total number of hours off for reporting purposes.'
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = 'No help information for this topic is currently available.'
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}

class ScheduleConflictListItem {
	schedLog: ScheduleLogRecord

	empId: number
	empName: string
	jobDate: string
	jobName: string
	tz: string
	tzAbrev: string
	timeFormat: string

	constructor(schedLog: ScheduleLogRecord) {
		this.schedLog = new ScheduleLogRecord(schedLog)
		this.empId = this.schedLog.effectiveEmpId()

		this.empName = Global.coreSrvc.dbSrvc.empSrvc.getEmployeeById(this.empId)?.name ?? 'Unknown Employee'
		this.jobDate = schedLog.job_date
		this.jobName = schedLog.job_description

		this.tz = schedLog.timezone
		this.tzAbrev = moment.tz(this.tz).format('z')
		this.timeFormat = DateTimeHelper.format12Hour ? 'h:mma' : 'HH:mm'
	}
}
