import { AfterContentInit, ChangeDetectorRef, Component, Input } from '@angular/core'
import {
	CardViewAction,
	ComponentBridgeName,
	DatabaseTableName,
	DialogManager,
	EditCompleteEvent,
	EditFormAction,
	OnboardDefinitionRecord,
} from '@app/models'
import { CoreService } from '@app/services'

import { log } from '@app/helpers'

@Component({
	selector: 'app-onboard-definition-list',
	standalone: false,

	templateUrl: './onboard-definition-list.component.html',
	styleUrl: './onboard-definition-list.component.scss',
})
export class OnboardDefinitionListComponent implements AfterContentInit {
	resource: DatabaseTableName = 'onboard_definition'

	isLoading = true
	editAction = { recordId: null, action: 'edit' as EditFormAction, isEditing: false }

	list: Array<OnboardDefinitionRecordWrapper> = []

	@Input() dialogManager = new DialogManager()

	constructor(private coreSrvc: CoreService) {
		this.fetchAndReload()
	}

	public ngAfterContentInit(): void {
		this.dialogManager.submitBtnAction = () => this.newRecord()
	}

	private loadData() {
		this.list = this.coreSrvc.dbSrvc.onboardSrvc.getOnboardDefinitionRecords().map((record) => new OnboardDefinitionRecordWrapper(record))
		this.isLoading = false
	}

	private fetchAndReload(): Promise<boolean> {
		return new Promise((resolve) => {
			this.coreSrvc.dbSrvc.readTable(this.resource).then(() => {
				this.loadData()
				resolve(true)
			})
		})
	}

	private resetDialog(): void {
		this.editAction = { recordId: null, action: 'edit', isEditing: false }
		this.dialogManager.popStateAndApply()
		this.dialogManager.restoreScrollPosition('main')
	}

	public actionIconClicked(event: Event, action: CardViewAction, vm: OnboardDefinitionRecordWrapper) {
		event.stopPropagation()
		switch (action) {
			case 'EDIT':
				this.prepareDialogForEdit(vm, 'edit', 'Edit Record')
				break
			case 'CLONE':
				this.prepareDialogForEdit(vm, 'clone', 'Clone Record')
				break
			case 'DELETE':
				this.list.forEach((r) => (r.isConfimingDelete = false))
				vm.isConfimingDelete = true
				break
			case 'AUDIT':
				this.showAuditLogDialog(vm)
				break
			case 'EXPAND':
				vm.isExpanded = !vm.isExpanded
				break
		}
	}

	private prepareDialogForEdit(vm: OnboardDefinitionRecordWrapper, action: EditFormAction, header: string): void {
		this.dialogManager.saveScrollPosition('main')
		this.dialogManager.pushState()
		this.dialogManager.cancelBtnAction = () => this.resetDialog()
		this.dialogManager.cancelBtnLabel = 'Cancel'
		this.dialogManager.submitBtnLabel = 'Save'
		this.dialogManager.isSubmitBtnVisible = true
		this.dialogManager.headerLabel = header
		this.editAction.recordId = vm.record.id
		this.editAction.action = action
		this.editAction.isEditing = true
	}

	public newRecord() {
		const vm = new OnboardDefinitionRecordWrapper(new OnboardDefinitionRecord())
		this.prepareDialogForEdit(vm, 'new', 'New Record')
	}

	public recordUpdated(event: EditCompleteEvent) {
		const action = event.action

		if (action === 'new' || action === 'clone') {
			this.fetchAndReload().then(() => {
				this.resetDialog()
			})
		}

		if (action === 'edit') {
			const recordId = event.recordId
			const record = this.coreSrvc.dbSrvc.onboardSrvc.getOnboardDefinitionRecordById(recordId)
			this.list.find((r) => r.record.id === record.id).record = record
			this.resetDialog()
		}
	}

	public confirmDelete(vm: OnboardDefinitionRecordWrapper) {
		vm.isDeleting = true
		this.coreSrvc.dbSrvc.deleteRecord(this.resource, vm.record.id).then((success) => {
			this.coreSrvc.dbSrvc.removeLocalRecord(this.resource, vm.record.id)
			this.loadData()
		})
	}

	public showAuditLogDialog(vm: OnboardDefinitionRecordWrapper) {
		setTimeout(() => {
			const recordId = vm.record.id
			const record = this.coreSrvc.dbSrvc.onboardSrvc.getOnboardDefinitionRecordById(recordId)
			const header = record.description

			const globalBridge: ComponentBridgeName = 'ngBridgeGlobal'
			const bridge = window[globalBridge]
			bridge.showAuditLog('onboard_definition', recordId, header)
		}, 250)
		this.dialogManager.isDialogVisible = false
	}
}

class OnboardDefinitionRecordWrapper {
	record: OnboardDefinitionRecord
	isExpanded = false
	isConfimingDelete = false
	isDeleting = false

	constructor(record: OnboardDefinitionRecord) {
		this.record = record
	}
}
