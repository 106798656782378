import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { log } from '@app/helpers'
import {
	HelpDialogMessage,
	IVizHealthAlertLink,
	IVizHealthAlertTip,
	VizHealthCenterCategory,
	VizHealthCenterSection,
	VizHealthCenterSectionAlert,
} from '@app/models'
import { CoreService, VisualizationService } from '@app/services'
import { preventDefault } from '@fullcalendar/core/internal'

import _ from 'lodash'

@Component({
    selector: 'app-viz-health-center',
    templateUrl: './viz-health-center.component.html',
    styleUrl: './viz-health-center.component.scss',
    standalone: false
})
export class VizHealthCenterComponent {
	isDesktop = true
	showMoreInfo = false

	warningThreshold = 76
	dangerThreshold = 51

	constructor(
		private coreSrvc: CoreService,
		private router: Router,
	) {
		this.isDesktop = this.coreSrvc.devDetect.isDesktop()
	}

	// Limit to contributors only when contributor filter is active
	get contribFilterActive(): boolean {
		return this.vizSrvc.viewManager.contribFilterActive
	}
	get vizSrvc(): VisualizationService {
		return this.coreSrvc.dbSrvc.vizSrvc
	}
	get categories(): Array<VizHealthCenterCategory> {
		return this.vizSrvc.healthCenterManager.healthCenterCategories
	}

	public visibleAlertsForCategory(category: VizHealthCenterCategory): Array<VizHealthCenterSectionAlert> {
		const visibleAlerts = category.alerts.filter((alert) => alert.enabled)
		return this.contribFilterActive ? visibleAlerts.filter((alert) => alert.contributor) : visibleAlerts
	}

	public visibleAlertsForSection(section: VizHealthCenterSection): Array<VizHealthCenterSectionAlert> {
		const visibleAlerts = section.alerts.filter((alert) => alert.enabled)
		return this.contribFilterActive ? visibleAlerts.filter((alert) => alert.contributor) : visibleAlerts
	}

	public focusSection(event: Event, section: VizHealthCenterSection) {
		event.preventDefault()
		event.stopPropagation()
		this.categories.forEach((category) => {
			category.expanded = false
			category.sections.forEach((item) => {
				if (item === section) {
					category.expanded = true
					section.expanded = true
				} else {
					item.expanded = false
				}
			})
		})
	}

	public handleAlertTip(tip: IVizHealthAlertTip) {
		if (tip.preRoute) tip.preRoute()
		const help = new HelpDialogMessage(tip.header, tip.message)
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}

	public handleAlertLink(link: IVizHealthAlertLink) {
		if (link.preRoute) link.preRoute()
		const path = link.path
		this.router.navigate([path])
	}

	public categoryHasDanger(category: VizHealthCenterCategory): boolean {
		// If any section has a score below the danger threshold, then the category is in danger
		const dangerThreshold = this.dangerThreshold
		return !category.expanded && category.sections.some((section) => section.score < dangerThreshold)
	}
}
