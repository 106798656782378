import { DateTimeHelper, Helper, log, PhoneHelper } from '@app/helpers'
import { C2CDirectoryLookup, C2CLogRecord, ClickToCallSourceType, ClickToCallSourceTypeFormatter } from '@app/models'
import { DatabaseService } from '@app/services'

import _ from 'lodash'
import moment from 'moment-timezone'

export class CommLogC2CTableFormatter {
	dbSrvc: DatabaseService
	directory: C2CDirectoryLookup

	constructor(dbSrvc: DatabaseService) {
		this.dbSrvc = dbSrvc
		this.directory = new C2CDirectoryLookup(dbSrvc)
	}

	date(date: string, tz: string) {
		return moment.tz(date, tz).format('YYYY-MM-DD')
	}

	time(date: string, tz: string) {
		const format12Hr = DateTimeHelper.format12Hour
		const mom = moment.tz(date, tz)
		return format12Hr ? mom.format('h:mm a') : mom.format('HH:mm')
	}

	callFrom(rec: C2CLogRecord) {
		const lookup = this.directory.lookupNumber(rec.caller_phone_e164, ['USER', 'SYSTEM', 'LINKEDJOB'])
		let result = `
			<div>${lookup ? lookup.name : rec.caller_name}</div>
			<div>${PhoneHelper.formatPhoneFromE164(rec.caller_phone_e164)}</div>
		`
		if (lookup) {
			const source = ClickToCallSourceTypeFormatter.getDescription(lookup.source)
			result += `
				<div class="tts-hl-2" style="font-weight: bold; font-size:small">${source}</div>
			`
		}
		return `<div class="cell-c2c">${result}</div>`
	}

	callTo(rec: C2CLogRecord) {
		let result = ''
		const callToNames = (rec.dest_name || '').split('|')
		const callToNumber = rec.dest_phone_e164 === '+18888888888' ? null : rec.dest_phone_e164
		for (let name of callToNames) {
			result += `<div>${name}</div>`
		}
		if (callToNumber) {
			result += `<div>${PhoneHelper.formatPhoneFromE164(rec.dest_phone_e164)}</div>`
		}
		const source = rec.call_source ?? ''
		const callSource = this.callSourceDescription(rec)
		const includeSource = rec.call_source !== 'INBOUNDCALL' && !source.includes('SMS')
		const highlightClass = source === 'VOICECHECKPOINT' ? 'tts-hl-1' : 'tts-hl-2'
		result += includeSource ? `<div class="${highlightClass}" style="font-weight: bold; font-size:small">${callSource}</div>` : ''
		return `<div class="cell-c2c">${result}</div>`
	}

	audioIcon(rec: C2CLogRecord, bridgeTable: string) {
		const hasAudio = rec.audio_file
		return hasAudio
			? `<i title="Play Audio" onclick="${bridgeTable}.playAudio(${rec.id})" class="far fa-volume-up act-ico act-ico-clr-green"></i>`
			: `<i title="No Audio Recorded" class="far fa-volume-up act-ico act-ico-disabled" style="cursor: default"></i>`
	}

	imagesIcon(rec: C2CLogRecord, bridgeTable: string) {
		const hasImages = rec.files_json
		return hasImages
			? `<i title="View Images" onclick="${bridgeTable}.playAudio(${rec.id})" class="fa fa-image act-ico act-ico-clr-green"></i>`
			: `<i title="No Images Attached" class="fa fa-image act-ico act-ico-disabled" style="cursor: default"></i>`
	}

	viewTranscript(rec: C2CLogRecord, bridgeTable: string) {
		const hasTranscript = rec.transcript
		return hasTranscript
			? `<i title="View Transcript" onclick="${bridgeTable}.viewTranscript(${rec.id})" class="far fa-file-lines act-ico act-ico-clr-green"></i>`
			: `<i title="No Audio Recorded" class="far fa-file-lines act-ico act-ico-disabled" style="cursor: default"></i>`
	}

	callSourceDescription(rec: C2CLogRecord): string {
		return ClickToCallSourceTypeFormatter.getDescription(rec.call_source as ClickToCallSourceType)
	}

	sourceType(rec: C2CLogRecord): string {
		const source = rec.call_source ?? ''
		return source.includes('SMS') ? 'Text' : 'Call'
	}

	status(rec: C2CLogRecord, bridgeTable: string): string {
		const transId = rec.transaction_id
		return transId
			? `<span class="link-text" onclick="${bridgeTable}.viewTimeEntry(${rec.id})">Linked <i title="View Time Entry" class="far fa-eye"></i></span>`
			: 'Orphan'
	}

	htmlDetails(input: string): string {
		// Regular expression to match "action at datetime"
		const actionAtDateRegex = /(\w+)\s+at\s+([\d\-:@\sA-Z]+)/
		// Regular expression to match "action by Firstname Lastname"
		const actionByNameRegex = /(\w+)\s+by\s+([\w\s]+)/

		// Test the input string against the regular expression
		const actionAtDateMatch = input.match(actionAtDateRegex)

		// If the input matches the expected format
		if (actionAtDateMatch) {
			const action = actionAtDateMatch[1] // The action (e.g., "Delivered" or "Sent")
			const dateTime = actionAtDateMatch[2] // The datetime (e.g., "2024-10-05@07:23 EDT")

			// Format the string into "<action> at:<br>[datetime]"
			return `<b style="color: #135c94">${action} at:</b><br>${dateTime}`
		}

		// Test the input string against the regular expression
		const actionByNameMatch = input.match(actionByNameRegex)

		// If the input matches the expected format
		if (actionByNameMatch) {
			const action = actionByNameMatch[1] // The action (e.g., "Answered")
			const fullName = actionByNameMatch[2] // The full name (e.g., "Firstname Lastname")

			// Format the string into "<action> at:<br>[datetime]"
			return `<b style="color: #135c94">${action} by:</b><br>${fullName}`
		}

		// If the input doesn't match, return the original input or handle it accordingly
		return input
	}
}
