import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { FormHelper, log } from '@app/helpers'
import { DialogManager, DatabaseTableName, EditFormAction, EditCompleteEvent, HelpDialogMessage, AdpIvrProfileRecord } from '@app/models'
import { CoreService } from '@app/services'

@Component({
    selector: 'app-adp-ivr-profile-edit',
    templateUrl: './adp-ivr-profile-edit.component.html',
    styleUrl: './adp-ivr-profile-edit.component.scss',
    standalone: false
})
export class AdpIvrProfileEditComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
	resource: DatabaseTableName = 'adp_ivr_profile'
	isUpdating = false
	form: UntypedFormGroup

	// If both a record and recordId are provided, the record will take precedence
	// and the recordId will be ignored. If neither is provided, a new record will
	// be created. If only recordId is provided, the record will be retrieved from
	// the corosponding service. The defaut is to provide a recordId.

	@Input() recordId: number
	@Input() record: AdpIvrProfileRecord
	@Input() action: EditFormAction = 'edit'

	@Input() dialogManager: DialogManager

	@Output() recordUpdated = new EventEmitter<EditCompleteEvent>()

	constructor(
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
	) {}

	get isNew(): boolean {
		return !this.record?.id
	}

	public ngOnInit(): void {
		this.setupComponent()
		this.setupForm()
	}

	public ngAfterViewInit(): void {}

	public ngAfterContentInit(): void {
		setTimeout(() => {
			this.setupDialogManager()
		}, 200)
	}

	public ngOnDestroy(): void {}

	public setupComponent() {
		const record = this.record ? this.record : this.coreSrvc.dbSrvc.adpSrvc.getAdpIvrProfileForId(this.recordId)
		log('Record', record)
		this.record = new AdpIvrProfileRecord(record)

		if (this.action === 'clone') {
			this.record.id = null
			this.record.description = record.description + ' (copy)'
		}
	}

	public setupForm() {
		const record = this.record

		this.form = this.fb.group({
			id: [record?.id],
			description: [record?.description, Validators.required],
			external_id: [record?.external_id, Validators.required],

			allow_clocking: [record?.allow_clocking ?? false],
			allow_multiple_clocking: [record?.allow_multiple_clocking ?? false],
			allow_labor_level_transfers: [record?.allow_labor_level_transfers ?? false],
			allow_work_rule_transfers: [record?.allow_work_rule_transfers ?? false],
			allow_pay_code_transactions: [record?.allow_pay_code_transactions ?? false],
			enable_call_tracking: [record?.enable_call_tracking ?? false],
			enable_phone_restrictions: [record?.enable_phone_restrictions ?? false],

			labor_level_transfer_list: [record?.labor_level_transfer_list ?? []],
			work_rule_transfer_list: [record?.work_rule_transfer_list ?? []],
			pay_code_transaction_list: [record?.pay_code_transaction_list ?? []],
		})
	}

	public setupDialogManager() {
		this.dialogManager.canSubmit = () => this.isFormValid()
		this.dialogManager.submitBtnAction = () => this.submit()
	}

	public isFormValid(): boolean {
		return this.form.valid
	}

	public submit() {
		// Guard against double submission
		if (this.isUpdating) return
		FormHelper.trimOnlyWhitespace(this.form)

		const record = this.makeUpdateRecord()
		log('Record to submit', record)

		if (record) {
			if (this.isNew) {
				log('Insert record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.insertRecord(this.resource, record).then((success) => {
					if (success) {
						this.recordUpdated.emit({ action: this.action, recordId: this.record?.id })
					} else {
						this.isUpdating = false
					}
				})
			} else {
				log('Update record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.updateRecord(this.resource, record).then((success) => {
					if (success) {
						this.recordUpdated.emit({ action: this.action, recordId: this.record?.id })
					} else {
						this.isUpdating = false
					}
				})
			}
		}
	}

	public makeUpdateRecord(): AdpIvrProfileRecord {
		const record = new AdpIvrProfileRecord(this.record)

		// Update any values changed by the form here
		record.description = this.form.get('description')?.value
		record.external_id = this.form.get('external_id')?.value

		record.allow_clocking = this.form.get('allow_clocking')?.value
		record.allow_multiple_clocking = this.form.get('allow_multiple_clocking')?.value
		record.allow_labor_level_transfers = this.form.get('allow_labor_level_transfers')?.value
		record.allow_work_rule_transfers = this.form.get('allow_work_rule_transfers')?.value
		record.allow_pay_code_transactions = this.form.get('allow_pay_code_transactions')?.value
		record.enable_call_tracking = this.form.get('enable_call_tracking')?.value
		record.enable_phone_restrictions = this.form.get('enable_phone_restrictions')?.value

		return record
	}

	public toggleCheckbox(prop: string) {
		const current = this.form.get(prop).value
		this.form.get(prop).setValue(!current)
	}

	private scrollIntoView(id: string) {
		this.coreSrvc.displaySrvc.bringIntoViewBySelector(id)
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
