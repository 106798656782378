import { Component, OnInit, EventEmitter, Input, Output, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core'
import { log } from '@app/helpers'

import _ from 'lodash'

@Component({
    selector: 'app-search-filter',
    templateUrl: './search-filter.component.html',
    styleUrls: ['./search-filter.component.scss'],
    standalone: false
})
export class SearchFilterComponent implements OnInit {
	public debounceSearch = _.debounce(this.performSearch, 200)

	@Input() searchText: string
	@Input() placeholder = 'filter'
	@Input() debounceTimer = 200
	@Input() emitLowerCase = true

	@Output() search = new EventEmitter<string>()

	constructor(private cd: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.performSearch()
		this.debounceSearch = _.debounce(this.performSearch, this.debounceTimer)
	}

	performSearch() {
		const textInput = this.searchText ?? ''
		const emittedText = this.emitLowerCase ? textInput.toLowerCase() : textInput
		this.search.emit(emittedText ? emittedText : null)
	}
}
