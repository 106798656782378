import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { DateTimeHelper, log } from '@app/helpers'
import { IncidentLogViewManager, DateRangeFilterEvent, SectionSwitcherConfig } from '@app/models'
import { CoreService } from '@app/services'
import { SelectItem } from 'primeng/api'

import { ShiftReportTableComponent } from './shift-report-table/shift-report-table.component'

import moment from 'moment-timezone'

@Component({
    selector: 'app-incident-log',
    templateUrl: './shift-reports.component.html',
    styleUrls: ['./shift-reports.component.scss'],
    standalone: false
})
export class ShiftReportsComponent implements OnInit, AfterViewInit {
	switcherConfig = new SectionSwitcherConfig()

	// accessHelper: AccessHelper
	showSectionHelp = false
	showFilterRangePicker = false

	timezoneOptions: Array<SelectItem> = []

	@ViewChild('incidentLogTable') incidentLogTable: ShiftReportTableComponent

	constructor(
		private router: Router,
		private coreSrvc: CoreService,
	) {
		if (!this.viewManager.timezone) {
			this.viewManager.timezone = this.coreSrvc.dbSrvc.settingSrvc.getCompany().timezone
		}

		this.setupTimezoneDropdown()
		this.setupAccessPermissions()
	}

	get viewManager(): IncidentLogViewManager {
		return this.coreSrvc.dbSrvc.incidentLogSrvc.viewManager
	}

	get devDetect() {
		return this.coreSrvc.devDetect
	}

	get lastUpdated(): string {
		const date = this.coreSrvc.dbSrvc.incidentLogSrvc.lastUpdated
		return `Updated ${DateTimeHelper.formatTimeFromDate(date)}`
	}

	ngOnInit() {}
	ngAfterViewInit() {
		scrollTo(0, 0)
	}

	setupAccessPermissions() {
		// this.accessHelper = new AccessHelper(this.coreSrvc.dbSrvc, 'SAMPLE')
	}
	canPerformAction(action) {
		return true
		// return this.accessHelper.canPerformAction(action, false)
	}

	toggleSectionHelp() {
		this.showSectionHelp = !this.showSectionHelp
		this.updateFixedHeader()
	}

	updateFixedHeader() {
		// Update fixed headers for any tables this section has
		setTimeout(() => {
			const table: any = $('#incidentLogTable').DataTable()
			table?.fixedHeader.adjust()
		}, 100)
	}

	public fetchAndReload() {
		this.incidentLogTable?.fetchAndReload()
	}

	createRecord() {
		// this.dispatchTable.createRecord()
	}

	public openPrefsDialog() {
		this.incidentLogTable?.openPrefsDialog()
	}

	private setupTimezoneDropdown() {
		const timezones = this.coreSrvc.dbSrvc.settingSrvc
			.getSupportedTimezones()
			.filter((tz) => tz.active)
			.map((tz) => {
				return { label: tz.display_name ? tz.display_name : tz.zone_name, value: tz.zone_name }
			})
		this.timezoneOptions = timezones
	}

	public setDayViewDate(date: Date) {
		this.viewManager.dayViewDate = date
		this.fetchAndReload()
	}

	// End Tab Switcher Methods

	public toggleDayView() {
		if (this.viewManager.isFilterRangeActive) this.viewManager.clearFlterRange()

		if (this.viewManager.dayViewDate) {
			this.viewManager.dayViewDate = null
		} else {
			this.viewManager.dayViewDate = new Date()
		}
		this.fetchAndReload()
	}
	public previousDay() {
		const currentMom = moment(this.viewManager.dayViewDate).subtract(1, 'day')
		this.viewManager.dayViewDate = currentMom.toDate()
		this.fetchAndReload()
	}

	public nextDay() {
		const currentMom = moment(this.viewManager.dayViewDate).add(1, 'day')
		this.viewManager.dayViewDate = currentMom.toDate()
		this.fetchAndReload()
	}

	public dayViewPickerClosed() {
		log('Day view picker closed')
		this.fetchAndReload()
	}

	public openFilterRangePicker() {
		this.showFilterRangePicker = true
	}

	public resetFilterRange() {
		this.viewManager.clearFlterRange()
		this.showFilterRangePicker = false
		this.fetchAndReload()
	}

	public setFilterRange(event: DateRangeFilterEvent) {
		this.viewManager.setFilterRange(event.start, event.end)
		this.showFilterRangePicker = false
		this.fetchAndReload()
	}
}
