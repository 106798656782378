import { DateTimeHelper } from '@app/helpers'
import { ContactOrganizationLinkRecord } from './link-contact-org'
import { IFileUploadsMetadata } from './secure-file'

export interface OrganizationSelectItem {
	label: string
	value: number
	data: OrganizationRecord
}

export enum OrgTableDisplayState {
	clients = 'CLIENTS',
	vendors = 'VENDORS',
	all = 'ALL',
}

export class OrganizationViewManager {
	version = 1
	currentView: OrgTableDisplayState = OrgTableDisplayState.all
	setViewStateForId(id: OrgTableDisplayState) {
		switch (id) {
			case 'CLIENTS':
				this.currentView = OrgTableDisplayState.clients
				break
			case 'VENDORS':
				this.currentView = OrgTableDisplayState.vendors
				break
			case 'ALL':
				this.currentView = OrgTableDisplayState.all
				break
		}
	}
}

export const organizationTypeOptions = [
	{ value: 'CLIENT', label: 'Client' },
	{ value: 'VENDOR', label: 'Vendor' },
]

export type OrganizationType = 'CLIENT' | 'VENDOR'

export class OrganizationRecord {
	id: number
	company_id: number
	external_id: string

	created: string
	updated: string

	details: string
	email: string
	name: string
	phone_e164: string
	type: OrganizationType = 'CLIENT'

	pay_rate: number

	notify_phone = true
	notify_sms = true
	notify_email = true

	file_uploads_metadata: IFileUploadsMetadata

	// Address data
	address_1: string = null
	address_2: string = null
	city: string = null
	district: string = null
	postal_code: string = null
	address_notes: string = null

	vendor_client_contact: Array<ContactOrganizationLinkRecord> = []

	constructor(record?: OrganizationRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
			this.vendor_client_contact = this.vendor_client_contact.map((rec) => new ContactOrganizationLinkRecord(rec))
		}
	}
}
