<div class="card card-body">
	<div>
		<button class="btn btn-sm btn-success float-right" (click)="toggleEdit()">Edit</button>
		<div><strong>Billing Contacts</strong></div>
		<div>{{ billingContacts }}</div>
	</div>
</div>

<app-modal-dialog *ngIf="dialogManager.isDialogVisible" [dialogManager]="dialogManager">
	<app-billing-contacts-edit (recordUpdated)="recordUpdated()" [dialogManager]="dialogManager"></app-billing-contacts-edit>
</app-modal-dialog>
