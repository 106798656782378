import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { CoreService, DatabaseService, UserLoginService } from '@app/services'

import { FileUploaderProcessedFile, FileUploadManager } from '@app/models'
import { log } from '@app/helpers'
import { Subscription } from 'rxjs'

import { DeviceDetectorService } from 'ngx-device-detector'

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
    standalone: false
})
export class FileUploaderComponent implements OnInit, OnDestroy {
	percentComplete = 0

	selectedFileForEdit: FileUploaderProcessedFile
	selectedFileForDelete: FileUploaderProcessedFile
	missingFilesList: Array<FileUploaderProcessedFile> = []

	@Input() fileUploadManager = new FileUploadManager()
	@Input() canEditLinkText = true
	@Output() listUpdated = new EventEmitter<boolean>()
	@ViewChild('fileUploadComponent', { static: true }) fileUploadComponent

	private subs = new Subscription()

	constructor(private coreSrvc: CoreService) {}

	ngOnInit(): void {
		this.subs.add(
			this.fileUploadManager.fileAddedEvent.subscribe({
				next: (file) => {
					this.updateUIForAddedFile(file)
				},
				error: (err) => log('File added error', err),
			}),
		)
		this.subs.add(
			this.fileUploadManager.errorFileAddedEvent.subscribe({
				next: (index) => {
					this.coreSrvc.notifySrvc.notify('error', 'Unsupported Type', 'The file type you selected is not supported.')
					const elemId = 'unsupportedFile_' + index
					this.bringIntoView(elemId)
				},
				error: (err) => log('Unsported File Listing Error', err),
			}),
		)
	}
	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	uploadHandler(event) {
		const files = event.files
		this.fileUploadComponent.clear()
		if (this.fileUploadManager.isWorking) {
			return
		}
		this.coreSrvc.loginSrvc.refresh().then((success) => {
			this.fileUploadManager.setInputFiles(files)
			this.fileUploadManager.startProcessing()
		})
	}

	isImage(file: FileUploaderProcessedFile) {
		const mimeType = file.type
		switch (mimeType) {
			case 'image/gif':
			case 'image/jpeg':
			case 'image/png':
				return true
			default:
				return false
		}
	}

	isFileMissing(file: FileUploaderProcessedFile): boolean {
		return this.missingFilesList.includes(file)
	}

	addToMissingFilesList(file: FileUploaderProcessedFile) {
		if (!this.missingFilesList.includes(file)) {
			this.missingFilesList.push(file)
		}
	}

	viewFile(file: FileUploaderProcessedFile) {
		log('View File', file)
		this.showFullScreen(file.fileUrl)
	}

	editFile(file: FileUploaderProcessedFile) {
		log('Edit File', file)
	}

	removeFile(file: FileUploaderProcessedFile) {
		log('Remove File', file)
		const fileId = file.fileId
		this.selectedFileForEdit = null
		this.selectedFileForDelete = file
		this.bringIntoView(fileId)
	}

	removeFileConfirmation(proceed: boolean) {
		if (proceed) {
			this.fileUploadManager.removeFile(this.selectedFileForDelete)
			this.listUpdated.next(true)
		}
		this.selectedFileForDelete = null
	}

	toggleEdit(file: FileUploaderProcessedFile) {
		this.selectedFileForDelete = null
		if (this.selectedFileForEdit === file) {
			this.selectedFileForEdit = null
			this.listUpdated.next(true)
		} else {
			this.selectedFileForEdit = file
			const blockId = `img_block_id_${file.fileId}`
			this.bringIntoView(blockId)
		}
	}

	showFullScreen(url: string) {
		window.open(url, '_blank')
	}

	updateUIForAddedFile(file: FileUploaderProcessedFile) {
		const fileId = file.fileId
		log('Updating UI for added file', fileId)
		this.bringIntoView(fileId)
		this.listUpdated.next(true)
	}

	bringIntoView(id: string) {
		setTimeout(() => {
			const elmnt = document.getElementById(id)
			if (elmnt) {
				elmnt.scrollIntoView({ behavior: 'smooth', block: 'center' })
			}
		}, 150)
	}
}
