import { GeneralTableHelper, log } from '@app/helpers'
import { DatabaseService } from '@app/services'

export class ToursFormatter {
	public static makeLinkedRecordsButton(dbSrvc: DatabaseService, bridgeTableName: string, dtFilterText: string, id: number, type: string): string {
		const tour = dbSrvc.tourSrvc.getTourRecordById(id)
		const jobIds = dbSrvc.jobSrvc.getJobsByTourId(id) || []
		const filterText = dtFilterText?.toLowerCase() ?? ''
		let recordsText = ''
		let recordCount = 0
		let isAllRecords = false
		switch (type) {
			case 'jobs':
				const allJobs = dbSrvc.jobSrvc.getJobs()
				const jobs = jobIds.map((jid) => dbSrvc.jobSrvc.getJobById(jid)).filter((job) => !!job)
				recordsText = jobs.map((j) => j.description?.toLowerCase()).join(',')
				recordCount = jobs.length
				isAllRecords = recordCount === allJobs.length
				break
		}
		// Check if current filter text is in the data for the associated record list
		const filterClass = filterText && recordsText.toLowerCase().includes(filterText) ? ' table-modal-btn-match' : ''
		const recordsTextHtml = recordCount > 0 ? `<div style="display:none;">${recordsText}</div>` : ''
		const sortString = GeneralTableHelper.leftPadSortString(recordCount, 3)

		return recordCount > 0
			? `${sortString}<button onclick="${bridgeTableName}.showLinkedRecords(${id}, '${type}')" class="btn btn-sm btn-default table-modal-btn${filterClass}" style="width: 105px">${
					isAllRecords ? 'all records' : recordCount === 1 ? `1 record` : recordCount + ` records`
				}</button>${recordsTextHtml}`
			: ''
	}
}
