import { DateTimeHelper } from '@app/helpers/datetime'
import { BulkReadWFromTableWithIds } from '@app/models/lambda'

import { RRuleSet, rrulestr } from 'rrule'

import { log } from '@app/helpers/logger'
import { DatabaseService } from '@app/services/backend/database.service'
import { TransactionMetaDataForReport } from './report-user'
import { ImageFile } from './image'

import _ from 'lodash'
import moment from 'moment-timezone'
import { PayRateSource, PayRateSourceType } from './pay-rate'
import { CoreService } from '@app/services'

export interface TransInfoHours {
	timeWorked: string
	hasMissingCheckout: boolean
	hasNoShow: boolean
	hasInvalid: boolean
	hoursNotSet: boolean
	onGoing: boolean
	isPending: boolean
}

export class TransactionLogRecord {
	id: number = null
	break_time: string = null
	break_time_paid: string = null
	break_count: number = null
	break_count_paid: number = null
	break_start: string = null
	c2c_count: number = null
	checkpoint_count: number = null
	company_id: number = null
	dup_images: string // JSON Array of ImageFile
	enable_notifications: boolean = null
	notify_paused_until: string = null // UTC Date
	employee_id: number = null
	employee_name: string = null
	employee_first: string = null
	employee_last: string = null
	job_id: number = null
	job_date: string = null
	job_description: string = null
	actual_start: string = null
	actual_end: string = null
	time_worked: string = null
	notes: string = null
	warned_flag: string = null
	warned_flag_out: string = null
	supervisor_flag: boolean = null
	supervisor_flag_out: boolean = null
	timezone: string = null
	geo_end_address: string = null
	geo_end_distance: number = null
	geo_end_emp_note: string = null
	geo_end_images_json: string = null
	geo_end_latitude: number = null
	geo_end_longitude: number = null
	geo_end_ts: string = null
	geo_start_address: string = null
	geo_start_distance: number = null
	geo_start_emp_note: string = null
	geo_start_images_json: string = null
	geo_start_latitude: number = null
	geo_start_longitude: number = null
	geo_start_ts: string = null
	emp_supervisor_id: number = null
	emp_supervisor_name: string = null
	jobsite_id: number = null
	jobsite_supervisor_id: number = null
	jobsite_supervisor_name: string = null
	jobsite_description: string = null
	created: string = null
	updated: string = null
	exported: string = null
	row_number: number = null
	exception: boolean = null
	// exception_comment: string = null
	exception_type: string = null
	exception_json: string = null
	incident_count: number = null
	in_voice_fingerprint = null
	in_meta_json: string = null
	out_voice_fingerprint = null
	out_meta_json: string = null
	emp_checkpoint: number = null
	sup_checkpoint: number = null
	uuid: string

	start_time: string = null
	end_time: string = null
	employee_count: number = null
	schedule_recur_id: number = null
	schedule_log_id: number = null
	recurrence: string = null
	recur_emp_name: string = null
	recur_description: string = null
	emp_notes: string = null

	travel_job = false
	travel_start_id: number = null
	travel_end_id: number = null

	sched_count: string = null
	notification_profile_id: number = null

	client_id: number = null
	client_external_id: string = null
	vendor_id: number = null
	vendor_external_id: string = null

	pay_rate: number = null
	pay_rate_source: PayRateSource = null
	client_pay_rate: number = null
	client_pay_rate_source: PayRateSource = null
	vendor_pay_rate: number = null
	vendor_pay_rate_source: PayRateSource = null

	open_shift: boolean = false
	qrc_checkin_valid: boolean = null
	qrc_checkout_valid: boolean = null

	imgErrCheckin = false // Local variable only
	imgErrCheckout = false // Local variable only

	checkout_checklist_count: number = 0 // Add via join op on incident_log table

	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			// All changes in constructor must be idempotent
			this.normalizeTimes()
			this.stripUTCTags()
			this.setupMetaData()
			this.setupImgChecks()
		}
	}

	get hasMissingChecklist(): boolean {
		const outMetaData: TransactionMetaData = this['outMetaData']
		return this.actual_end && outMetaData?.checklistTimerCheckoutTime && this.checkout_checklist_count === 0
	}

	get publicReportLink(): string {
		const origin = window.location.origin
		return `${origin}/#/reports/shiftsummary/${this.uuid}`
	}

	normalizeTimes() {
		this.start_time = DateTimeHelper.normalizeTime(this.start_time)
		this.end_time = DateTimeHelper.normalizeTime(this.end_time)
	}

	stripUTCTags() {
		this.created = DateTimeHelper.stripUtcTag(this.created)
		this.updated = DateTimeHelper.stripUtcTag(this.updated)
		this.actual_start = DateTimeHelper.stripUtcTag(this.actual_start)
		this.actual_end = DateTimeHelper.stripUtcTag(this.actual_end)
		this.break_start = DateTimeHelper.stripUtcTag(this.break_start)
		this.geo_start_ts = DateTimeHelper.stripUtcTag(this.geo_start_ts)
		this.geo_end_ts = DateTimeHelper.stripUtcTag(this.geo_end_ts)
		this.notify_paused_until = DateTimeHelper.stripUtcTag(this.notify_paused_until)
	}

	setupMetaData() {
		this['inMetaData'] = this.bulidMetaData('IN')
		this['outMetaData'] = this.bulidMetaData('OUT')
	}

	setupImgChecks() {
		if (DateTimeHelper.imgIssuesDuration && this.actual_start) {
			const refTs = moment(this.actual_start).unix()
			const images = this.getImages('IN')
			const isInRange = DateTimeHelper.tsInRange(images, refTs)
			this.imgErrCheckin = isInRange.includes(false)
		}
		if (DateTimeHelper.imgIssuesDuration && this.actual_end) {
			const refTs = moment(this.actual_end).unix()
			const images = this.getImages('OUT')
			const isInRange = DateTimeHelper.tsInRange(images, refTs)
			this.imgErrCheckout = isInRange.includes(false)
		}
	}

	createRecord(): TransactionLogRecord {
		const record: TransactionLogRecord = JSON.parse(JSON.stringify(this))
		record.actual_start = DateTimeHelper.stripUtcTag(record.actual_start)
		record.actual_end = DateTimeHelper.stripUtcTag(record.actual_end)
		record.break_start = DateTimeHelper.stripUtcTag(record.break_start)
		return record
	}

	getJobDate() {
		const dateString = DateTimeHelper.stripUtcTag(this.job_date)
		return new Date(dateString)
	}

	calculateHoursWorked(): moment.Duration {
		const start = moment(DateTimeHelper.stripUtcTag(this.actual_start)).startOf('minute')
		const end = moment(DateTimeHelper.stripUtcTag(this.actual_end)).startOf('minute')
		const breakDur = moment.duration(this.break_time)
		return end.isValid() && breakDur ? moment.duration(end.diff(start)).subtract(breakDur) : null
	}

	bulidMetaData(inOut: 'IN' | 'OUT'): TransactionMetaData {
		return new TransactionMetaData(inOut, this)
	}

	isNoShow(): boolean {
		if (!this.actual_start) {
			return true
		}
		return false
	}

	isOpen(): boolean {
		if (this.actual_start && !this.actual_end) {
			return true
		}
		return false
	}

	isOngoing(maxJobLength: number = 16): boolean {
		if (this.actual_start && !this.actual_end) {
			const startMom = moment(this.actual_start)
			const cutoff = startMom.clone().add(maxJobLength, 'hours')
			const now = moment()
			if (now.isAfter(startMom) && now.isBefore(cutoff)) {
				// if (now.isBefore(cutoff)) {
				return true
			}
		}
		return false
	}

	isPending() {
		if (this.actual_start && !this.actual_end) {
			const now = moment()
			const startMom = moment(this.actual_start)
			if (now.isBefore(startMom)) {
				return true
			}
		}
		return false
	}

	hasMissingCheckout(forNHours: number, checkInOutWindow: string = 'PT5H'): boolean {
		// Checks to see if not checked out by forNHours
		if (this.actual_start && !this.actual_end) {
			const start = new Date(DateTimeHelper.stripUtcTag(this.actual_start)).getTime()
			const now = new Date().getTime()
			const duration = (now - start) / 1000
			// log(duration);
			if (duration > 60 * 60 * forNHours) {
				return true
			}
		}
		return false
	}

	hasHoursNotSet(): boolean {
		return !this.time_worked && !!this.actual_start && !!this.actual_end
	}

	hasInvalidHours(): boolean {
		const timeWorked = this.time_worked || ''
		const isNegativeDuration = /-/.test(timeWorked)
		return isNegativeDuration ? true : false
	}

	buildValidationRequest(): Array<BulkReadWFromTableWithIds> {
		const empReq = new BulkReadWFromTableWithIds('employee', [this.employee_id])
		const siteReq = new BulkReadWFromTableWithIds('location', [this.jobsite_id])
		const jobReq = new BulkReadWFromTableWithIds('job', [this.job_id])
		const list = [empReq, siteReq, jobReq]
		if (this.schedule_recur_id) {
			const schedReq = new BulkReadWFromTableWithIds('schedule_recur', [this.schedule_recur_id])
			list.push(schedReq)
		}
		return list
	}

	getPayRateSourceLabel(sourceType: PayRateSourceType): string {
		const rateSource =
			sourceType === 'EMPLOYEE'
				? this.pay_rate_source
				: sourceType === 'CLIENT'
					? this.client_pay_rate_source
					: sourceType === 'VENDOR'
						? this.vendor_pay_rate_source
						: null

		const sourceRecordHint = _.capitalize(sourceType)
		switch (rateSource) {
			case 'DEFAULT':
				return `${sourceRecordHint} Record`
			case 'JOB_DEFAULT':
				return `Job Record`
			case 'JOB':
				return 'Rate Table'
			case 'TIME_ENTRY':
				return 'Time Entry'
			default:
				return 'Not Set'
		}
	}

	hasImages(inOut: 'IN' | 'OUT'): boolean {
		return inOut == 'IN' ? !!this.geo_start_images_json : !!this.geo_end_images_json
	}

	getImages(inOut: 'IN' | 'OUT'): Array<ImageFile> {
		const json = inOut == 'IN' ? this.geo_start_images_json : this.geo_end_images_json
		if (!json) {
			return []
		}
		const imagesPayload = JSON.parse(json)
		if (imagesPayload) {
			const files = imagesPayload.files
			if (files && files.length > 0) {
				return imagesPayload.files.map((rec) => new ImageFile(rec))
			} else {
				return []
			}
		} else {
			return []
		}
	}

	getReportMetaData(): TransactionMetaDataForReport {
		return {
			id: this.id,
			employee_id: this.employee_id,
			employee_name: this.employee_name,
			emp_supervisor_id: this.emp_supervisor_id,
			emp_supervisor_name: this.emp_supervisor_name,
			job_id: this.job_id,
			job_date: this.job_date,
			job_description: this.job_description,
			jobsite_id: this.jobsite_id,
			jobsite_description: this.jobsite_description,
			jobsite_supervisor_id: this.jobsite_supervisor_id,
			jobsite_supervisor_name: this.jobsite_supervisor_name,
			schedule_recur_id: this.schedule_recur_id,
			start_time: this.start_time,
			end_time: this.end_time,
			timezone: this.timezone,
		}
	}

	getScheduledDays() {
		if (!this.recurrence) return null
		const ruleString = this.recurrence
		const ruleSet = rrulestr(ruleString, { forceset: true }) as RRuleSet
		const rule = ruleSet.rrules()[0]
		return rule.options.byweekday || []
	}
}

export interface ITransMetaEvent {
	id: number
}

export interface ITransMetaJob {
	id: number
	index: number
	name: string
}

export interface ITransMetaLocation {
	id: number
	name: string
	union_zone: number
}

export type CheckInOutType = 'LANDLINE' | 'MOBILE' | 'WEB' | 'STATION' | 'MOBILESTATION' | 'ADMIN'

export class TransactionMetaData {
	calledTo: string
	callerIDName: string
	callerIDNumber: string
	calledFromCell: boolean
	checkedInCount: number
	employeeUnion: boolean
	events: Array<Object> = []
	jobs: Array<ITransMetaJob> = []
	jobCodeEntered1: number
	jobCodeEntered2: number
	locations: Array<ITransMetaLocation> = []
	requiredCount: number
	stationId: number
	webAPI: boolean
	gpsClientError: string
	gpsResponseTime: number
	confirmedBreaks: boolean
	qrcValid: boolean

	mobileStationE164?: number // Only on IN data

	reqCLCO: boolean
	checklistTimerCheckoutTime: string

	hasData = false

	get hasJobs(): boolean {
		return this.jobs && this.jobs.length > 0
	}
	get hasSites(): boolean {
		return this.locations && this.locations.length > 0
	}
	get hasEvents(): boolean {
		return this.events && this.events.length > 0
	}
	get isStation(): boolean {
		return !!this.stationId
	}
	get isMobileStation(): boolean {
		return !!this.mobileStationE164
	}
	get isLandline(): boolean {
		return this.calledTo && !this.calledFromCell && !this.webAPI
	}
	get isCellphone(): boolean {
		return this.calledFromCell
	}
	get isIvr(): boolean {
		return !!this.calledTo
	}
	get isWebApi(): boolean {
		return this.webAPI
	}
	get isAdmin(): boolean {
		return !this.isCellphone && !this.isLandline && !this.isWebApi
	}
	get checkInOutType(): CheckInOutType {
		if (this.isMobileStation) return 'MOBILESTATION'
		if (this.isStation) return 'STATION'
		if (this.isLandline) return 'LANDLINE'
		if (this.isCellphone) return 'MOBILE'
		if (this.isWebApi) return 'WEB'
		return 'ADMIN'
	}
	get checkInOutTypeLabel(): string {
		if (this.isMobileStation) return 'mobile station'
		if (this.isStation) return 'station'
		if (this.isLandline) return 'landline'
		if (this.isCellphone) return 'mobile'
		if (this.isWebApi) return 'web'
		return 'admin'
	}
	get hasIssues(): boolean {
		if (this.gpsClientError) {
			return true
		}
		if (this.confirmedBreaks === false) {
			return true
		}
		return false
	}

	constructor(inOut: 'IN' | 'OUT', trans: TransactionLogRecord) {
		const json = inOut === 'IN' ? trans.in_meta_json : trans.out_meta_json
		const data = JSON.parse(json)
		const isEmpty = _.isEmpty(data)
		if (!isEmpty) {
			this.hasData = true
			this.employeeUnion = data.employeeUnion
			this.events = data.events
			this.locations = data.locations
			this.jobs = data.jobs
			this.calledTo = data.calledTo
			this.callerIDName = data.callerIDName
			this.callerIDNumber = data.callerIDNumber
			this.calledFromCell = data.calledFromCell
			this.webAPI = data.webAPI
			this.jobCodeEntered1 = data.jobCodeEntered1
			this.jobCodeEntered2 = data.jobCodeEntered2
			this.checkedInCount = data.checkedInCount
			this.requiredCount = data.requiredCount
			this.stationId = data.stationId
			this.gpsClientError = data.gps_client_error
			this.gpsResponseTime = data.gps_response_time
			this.confirmedBreaks = data.confirmedBreaks

			this.qrcValid = data.qrcValid

			// If mobile station number in data, then add to mobileStationE164
			if (data.mobileStationE164) {
				this.mobileStationE164 = data.mobileStationE164
			}

			this.reqCLCO = data.reqCLCO
			this.checklistTimerCheckoutTime = data.checklistTimerCheckoutTime

			if (this.webAPI) {
				const geoLat = inOut === 'IN' ? trans.geo_start_latitude : trans.geo_end_latitude
				const geoLng = inOut === 'IN' ? trans.geo_start_longitude : trans.geo_end_longitude
				if ((!geoLat || !geoLng) && !this.gpsClientError) {
					this.gpsClientError = 'GPS Not Provided'
				}
			}
		}
	}
}

export type TransEventLogLevel = 'DEBUG' | 'INFO' | 'WARN' | 'ERROR'
export type TransEvenLogSubsystem = 'EMP' | 'IVR' | 'ADMIN' | 'NOTIFY' | 'GPS'

export class TransEventLogEntry {
	company_id: number
	created: string
	id: number
	log_level: TransEventLogLevel
	log_subsystem: TransEvenLogSubsystem
	message: string
	transaction_log_id: number

	constructor(record: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
		this.created = DateTimeHelper.stripUtcTag(this.created)
	}
}

export class CheckInOutThreshold {
	earlyCheckin = null
	lateCheckin = null
	earlyCheckout = null
	lateCheckout = null

	constructor(earlyCheckin: number, lateCheckin: number, earlyCheckout: number, lateCheckout: number) {
		this.earlyCheckin = earlyCheckin
		this.lateCheckin = lateCheckin
		this.earlyCheckout = earlyCheckout
		this.lateCheckout = lateCheckout
	}
}

export class ShiftThreshold {
	shortShift: number = null
	longShift: number = null

	constructor(shortShift: number, longShift: number) {
		this.shortShift = shortShift
		this.longShift = longShift
	}
}

export class TimeEntryCallerIdMatcher {
	empNumbers = new Map<string, boolean>()
	siteNumbers = new Map<string, boolean>()

	constructor(dbSrvc: DatabaseService) {
		// dbSrvc.empSrvc.getAllEmployees().forEach((emp) => {
		// 	if (emp.phone_number_e164) {
		// 		this.empNumbers.set(emp.phone_number_e164, true)
		// 	}
		// })
		dbSrvc.siteSrvc.getAllJobSites().forEach((site) => {
			if (site.phone_number_regex_e164) {
				const numbers = site.phone_number_regex_e164.split(',')
				numbers.forEach((num) => {
					this.siteNumbers.set(num, true)
				})
			}
		})
		log('CID Number Matcher', this)
	}
}

// Tag Filter Management

export enum TagFilterKey {
	clIssues = `#clErr`,
	imageIssues = `#imgErr`,
	jobFilter = `#jobId`,
	jobUnassigned = `#jobUnassigned`,
	recordFilter = `#transId`,
	openShifts = `#optr`,
	ongoingShifts = `#shft:ongoing`,
	onBreak = `#shft:onbreak`,
	missingCheckout = `#shft:missing`,
	noShowRecent = `#shft:noshow:rcnt`,
	noShow = `#shft:noshow`,
	invalidHours = `#shft:invalid`,
	hlEarlyLate = `#chkhl`,
	hlEarlyLateIn = `#chkhl:in`,
	hlEarlyLateOut = `#chkhl:out`,
	gpsAllIssues = `#gps`,
	gpsBlocked = `#gps:blocked`,
	gpsMissingInOut = `#gps:none`,
	gpsMissingIn = `#gps:none:in`,
	gpsMissingOut = `#gps:none:out`,
	gpsDistanceInOut = `#gps:dist`,
	gpsDistanceIn = `#gps:dist:in`,
	gpsDistanceOut = `#gps:dist:out`,
	gpsTimeInOut = `#gps:time`,
	gpsTimeIn = `#gps:time:in`,
	gpsTimeOut = `#gps:time:out`,
	rsbLandline = `#rsb:llp`,
	rsbEmpCell = `#rsb:ecp`,
	rsbStation = `#rsb:sta`,
	rsbEmpWebApp = `#rsb:ewa`,
	rsbAdmin = `#rsb:sau`,
	empDeniedBreak = `#brks:denied`,
	empCountOverage = `#eco`,
}

// Filter tags go from most specific to least specific in tag list

export class TransTagFilterManager {
	public list = [
		{ key: TagFilterKey.clIssues, label: 'Checklist Issues', available: true },
		{ key: TagFilterKey.imageIssues, label: 'Image Issues', available: true },
		{ key: TagFilterKey.jobFilter, label: 'Job Filter', available: true },
		{ key: TagFilterKey.jobUnassigned, label: 'Unassigned Jobs', available: true },
		{ key: TagFilterKey.recordFilter, label: 'Record Filter', available: true },
		{ key: TagFilterKey.openShifts, label: 'Unclosed Shifts', available: true },
		{ key: TagFilterKey.ongoingShifts, label: 'Ongoing Shifts', available: true },
		{ key: TagFilterKey.onBreak, label: 'Emp On Break', available: true },
		{ key: TagFilterKey.missingCheckout, label: 'Missing Checkout', available: true },
		{ key: TagFilterKey.noShowRecent, label: 'No Show (Recent)', available: true },
		{ key: TagFilterKey.noShow, label: 'No Show (All)', available: true },
		{ key: TagFilterKey.invalidHours, label: 'Invalid Hours', available: true },
		{ key: TagFilterKey.hlEarlyLateIn, label: 'Early/Late (In)', available: true },
		{ key: TagFilterKey.hlEarlyLateOut, label: 'Early/Late (Out)', available: true },
		{ key: TagFilterKey.hlEarlyLate, label: 'Early/Late (In/Out)', available: true },
		{ key: TagFilterKey.gpsMissingIn, label: 'GPS Missing (In)', available: true },
		{ key: TagFilterKey.gpsMissingOut, label: 'GPS Missing (Out)', available: true },
		{ key: TagFilterKey.gpsMissingInOut, label: 'GPS Missing (In/Out)', available: true },
		{ key: TagFilterKey.gpsDistanceIn, label: 'GPS Distance (In)', available: true },
		{ key: TagFilterKey.gpsDistanceOut, label: 'GPS Distance (Out)', available: true },
		{ key: TagFilterKey.gpsDistanceInOut, label: 'GPS Distance (In/Out)', available: true },
		{ key: TagFilterKey.gpsTimeIn, label: 'GPS Time (In)', available: true },
		{ key: TagFilterKey.gpsTimeOut, label: 'GPS Time (Out)', available: true },
		{ key: TagFilterKey.gpsTimeInOut, label: 'GPS Time (In/Out)', available: true },
		{ key: TagFilterKey.gpsBlocked, label: 'GPS Blocked', available: true },
		{ key: TagFilterKey.gpsAllIssues, label: 'All GPS Issues', available: true },
		{ key: TagFilterKey.rsbLandline, label: 'From Landline', available: true },
		{ key: TagFilterKey.rsbEmpCell, label: 'From Emp Cell', available: true },
		{ key: TagFilterKey.rsbStation, label: 'From Station', available: true },
		{ key: TagFilterKey.rsbEmpWebApp, label: 'From Web App', available: true },
		{ key: TagFilterKey.rsbAdmin, label: 'From Admin', available: true },
		{ key: TagFilterKey.empDeniedBreak, label: 'Emp Denied Break', available: true },
		{ key: TagFilterKey.empCountOverage, label: 'Emp Count Overage', available: true },
	]

	public getFilterForKey(key: TagFilterKey) {
		return this.list.find((item) => item.key === key)
	}

	public getLabelForKey(key: TagFilterKey) {
		const filter = this.getFilterForKey(key)
		if (key) {
			return filter.label
		}
		return ''
	}

	public disableFilters(keys: Array<TagFilterKey>) {
		for (const key of keys) {
			const item = this.getFilterForKey(key)
			if (item) {
				item.available = false
			}
		}
	}
}

// Manage global state variables used throughout the component
export class TransTableViewConfiguration {
	// Unsorted
	// --- Add Here ---

	// Manage DataTable
	public showPaging = true
	public defaultPageLength = 200

	// Manage Layout
	public isMobile = false
	public isDesktop = false

	// Manage cell computations
	public unassignedJobId = null
	public jobLengthMax = 16

	// Manage couter tags
	public noShowFilterDays = 1

	// Manage Access
	public canAccessAuditLog = false // Modified in component by setupAccessPermissions()

	private coreSrvc: CoreService

	constructor(coreSrvc: CoreService) {
		this.coreSrvc = coreSrvc
		this.updateConfiguration()
	}

	public updateConfiguration() {
		this.defaultPageLength = this.coreSrvc.prefSrvc.data.transTablePageLength
		this.isMobile = this.coreSrvc.devDetect.isMobile()
		this.isDesktop = this.coreSrvc.devDetect.isDesktop()
		this.unassignedJobId = this.coreSrvc.dbSrvc.jobSrvc.getUnassignedJob().id
		this.jobLengthMax = this.coreSrvc.dbSrvc.settingSrvc.getCompany().job_length_max ?? 16
		this.noShowFilterDays = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs().transNoShowFilterDays
	}
}

// NOT BEING USED
export class ImageDuplicateChecker {
	storage = {}

	addImages(images: Array<ImageFile>) {
		for (const image of images) {
			const sha256 = image.sha256
			if (sha256) {
				const found = this.storage[sha256]
				if (found) {
					this.storage[sha256]++
				} else {
					this.storage[sha256] = 1
				}
			}
		}
	}

	clear() {
		this.storage = {}
	}

	checkHash(hash: string) {
		if (this.storage[hash] && this.storage[hash] > 0) return true
		return false
	}

	checkImagesForDups(images: Array<ImageFile>) {
		for (const img of images) {
			if (this.checkHash(img.sha256)) return true
		}
		return false
	}

	checkTransForDups(inOut: 'IN' | 'OUT', trans: TransactionLogRecord): boolean {
		const images = trans.getImages(inOut)
		if (images.length > 0) {
			return this.checkImagesForDups(images)
		} else {
			return false
		}
	}
}

// TransDateLockManager
export class TransDateLockManager {
	date: Date = null
	isDialogVisible = false
	isUpdating = false
}
