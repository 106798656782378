<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Duplicate Number Dialog"
	[(visible)]="showDialog"
	[modal]="true"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
	(onHide)="showDialogChange.next(false)"
>
	<div class="mt-dialog-body">
		<div class="opt-copy">
			<div>
				<div class="float-right" style="font-size: 1.4rem; margin-left: 12px">
					<app-help-video-link key="employeeMergeTransferDialog"></app-help-video-link>
				</div>
				<div class="mt-dialog-header">The phone number you entered belongs to another employee.</div>
			</div>
			Press the Merge button to <strong>merge that employee with this employee.</strong>
			<div class="sub-note">Do this if both employees are actually the same person.</div>
		</div>
		<button class="btn btn-sm btn-block btn-outline-main" (click)="mergeBtnClicked.next(true)">Merge Employees</button>
		<hr />
		<div class="opt-copy" style="margin-top: 20px">
			Press the Transfer button if you would like to transfer the phone number from the other employee to this employee.
			<div class="sub-note">Do this if you are transferring a company-owned mobile phone from one employee to another.</div>
		</div>
		<button class="btn btn-sm btn-block btn-outline-main" (click)="transferBtnClicked.next(true)">Transfer Phone Number</button>
	</div>
</p-dialog>
