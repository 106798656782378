<div *ngIf="!showConfirmation" class="row">
	<div class="col-12 col-sm-6 record-item">
		<button
			class="btn btn-sm btn-danger qbo-button"
			[disabled]="!canPerformAction(CrudAction.delete)"
			(click)="deleteLink()"
		>
			<i class="fa fa-cloud-upload" aria-hidden="true"></i> Disconnect QuickBooks
		</button>
	</div>
</div>
<div *ngIf="showConfirmation" class="row delete-confirmation">
	<div class="col-12">
		<strong>Are you sure?</strong>
		<span class="float-right">
			<button (click)="cancelDelete()" class="btn btn-sm btn-secondary">Cancel</button>&nbsp;
			<button (click)="confirmDelete()" class="btn btn-sm btn-danger">Delete</button>
		</span>
	</div>
</div>
<div class="row">
	<div class="col-12 record-item text-muted">
		Use the 'Delete QuickBooks Link' button
		<strong style="color: firebrick">ONLY</strong> if you have stopped using QuickBooks. <strong>Note:</strong> This
		will remove all Customer and Service Item information that is currently linked to jobs.
	</div>
</div>
