import { Helper } from '@app/helpers/functions'
import { log } from '@app/helpers/logger'
import { CoreService } from '@app/services'
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js'
import { Subject } from 'rxjs'

export class PhoneListFormEntry {
	formattedNumber = ''
	number = ''
	name = ''
	constructor(e164Number: string = '', name: string) {
		log('PhoneListFormEntry', e164Number)
		this.number = e164Number
		this.name = name
		if (e164Number) {
			if (!e164Number.includes('+')) {
				// alert('Not an E164 Number')
				return
			}
			const parsedNumber = parsePhoneNumber(e164Number)
			this.formattedNumber = parsedNumber.isValid() ? parsedNumber.formatNational() : e164Number
		}
	}
}

export class GenericPhoneListManager {
	inputField: string
	phoneList: Array<PhoneListFormEntry> = []

	dialingCode: CountryCode = 'US'
	countryCodeData = Helper.countryIsoData

	blockTitle = ''
	invalidPhoneAlertMsg = 'Please enter a valid phone number.'

	listChanged = new Subject<boolean>()

	constructor(private coreSrvc: CoreService) {}

	get phoneListString(): string {
		return this.phoneList.map((entry) => entry.number).join(',')
	}

	get isInputFieldValid(): boolean {
		const number = this.inputField
		return this.isPhoneInputValid(number)
	}

	get isFormValid(): boolean {
		if (!this.inputField) return true
		return this.isInputFieldValid
	}

	isPhoneInputValid(number: string) {
		if (!number || number?.length < 3) return false
		const dialingCode = this.dialingCode
		const parsedNumber = parsePhoneNumber(number, dialingCode)
		return parsedNumber.isValid()
	}

	initWithString(strList: string) {
		if (strList) {
			const numbers = strList.split(',')
			this.phoneList = numbers.map((number) => new PhoneListFormEntry(number, null))
			for (const entry of this.phoneList) {
				this.lookupNameForListEntry(entry)
			}
		}
	}

	formatPhoneInput() {
		this.inputField = this.formatPhoneNumber(this.inputField)
	}

	removePhoneAtIndex(idx): boolean {
		log('Removing Phone')
		this.phoneList.splice(idx, 1)
		this.listChanged.next(true)
		return false
	}

	enterKeyPressed() {
		if (this.inputField && this.isInputFieldValid) {
			this.addPhone()
		}
	}

	formatPhoneNumber(number: string = '') {
		if (number.length < 3) return number
		const dialingCode = this.dialingCode
		const phoneNumber = parsePhoneNumber(number, dialingCode)
		return phoneNumber.isValid() ? phoneNumber.formatNational() : number
	}

	addPhone(): boolean {
		const number = this.inputField
		if (!number) {
			return true
		}
		if (!this.isPhoneInputValid(number)) {
			// alert(this.invalidPhoneAlertMsg)
			this.coreSrvc.notifySrvc.notify('error', 'Alert', this.invalidPhoneAlertMsg)
			return false
		}

		const parsedNumber = parsePhoneNumber(number, this.dialingCode)
		if (!parsedNumber.isValid()) {
			// alert('Phone number is not valid for region selected')
			this.coreSrvc.notifySrvc.notify('error', 'Alert', 'Phone number is not valid for region selected')
			return false
		}

		const e164 = parsedNumber.format('E.164')
		log('International Number', e164)

		const numberExists = this.phoneList.find((le) => le.number === e164)
		if (numberExists) {
			this.inputField = null
			return true
		}

		log('Adding Phone:', number)
		const entry = new PhoneListFormEntry(e164, null)
		this.lookupNameForListEntry(entry)
		this.phoneList.push(entry)
		this.inputField = null
		this.listChanged.next(true)
		return true
	}

	prepareForSubmission() {
		if (this.inputField) {
			const addError = this.addPhone()
			return addError
		}
		return true
	}

	lookupNameForListEntry(entry: PhoneListFormEntry) {
		// Check in user table
		log('lookup', entry)
		const number = entry.number
		if (number) {
			const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForE164Number(number)
			if (user) {
				entry.name = user.first_name + ' ' + user.last_name
			}
		}
	}
}
