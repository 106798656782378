<div class="ur-cli-block ur-cli-block-range">
	<div class="ur-range-row">
		<div class="float-right ur-skip-btn-wrap">
			<div class="ur-skip-btn" style="top: 0px; margin-left: 4px" (click)="toggleSkipCheckbox.emit(item)">
				{{ item.skip ? 'edit' : 'n/a' }}
			</div>
			<div class="float-left range-value" [class.range-value-gray]="(!item.value && item.value !== 0 && !item.comment.value) || item.skip">
				{{ formatRangeValue(item) }}
			</div>
		</div>
		<div class="form-check-label ur-basic-label" [class.ur-label-disabled]="item.skip">{{ item.label }}</div>
	</div>

	<div *ngIf="!item.skip">
		<div class="range-block">
			<!-- <label [for]="'cli_range_' + item.tagId" class="form-label">{{ item.label }}</label> -->
			<input
				type="range"
				class="form-range"
				[(ngModel)]="item.value"
				[min]="item.min"
				[max]="item.max"
				[step]="item.step"
				[id]="'cli_range_' + item.tagId"
			/>
			<div class="d-flex justify-content-between min-max-label">
				<div style="margin-left: 2px">
					{{ item.min }}<span *ngIf="item.rangeLabel.left"> / {{ item.rangeLabel.left }}</span>
				</div>
				<div style="margin-right: 2px">
					<span *ngIf="item.rangeLabel.right">{{ item.rangeLabel.right }} / </span>{{ item.max }}
				</div>
			</div>
		</div>
		<textarea
			#rangeBasicTextArea
			*ngIf="item.comment?.include"
			rows="1"
			class="form-control ur-cli-comment"
			placeholder="Enter optional comments"
			[(ngModel)]="item.comment.value"
			(input)="textResizer.next(rangeBasicTextArea)"
		></textarea>
	</div>
</div>
