<app-activity-banner [isProcessing]="isLoading" [message]="'Loading Skills ...'"></app-activity-banner>

<div [hidden]="isLoading">
	<div class="alert alert-info alert-bg-gray" style="margin-bottom: 30px">
		To enable a skill for this user, check the box next to the skill name and adjust the routing options. To configure available skills, go to
		<b>Admin > Settings > Call Center > Manage Skills</b>{{ hasGlobalAccount ? ' in your global account.' : '.' }}
		<!-- Toggle active skills for this user by clicking on the checkbox to the left of the skill name. For any active skill, you can expand it's options
		to configure them for this user.<br /><br />
		Go to <b>Admin > Settings > Call Center > Manage Skills</b>{{ hasGlobalAccount ? ' in your global account ' : ' ' }}to setup and configure new
		skiils. -->
	</div>

	<div *ngIf="skills.length === 0" class="no-records">No Skills Setup</div>

	<div *ngFor="let card of skills; let idx = index">
		<div class="card" [class.has-skill]="card.selected">
			<div>
				<div class="d-flex align-items-start" style="font-size: 1.2rem">
					<div class="select-icon mr-2" style="cursor: pointer" (click)="toggleCardSelection(card)">
						<i *ngIf="card.selected" class="far fa-check-square" style="color: green"></i>
						<i *ngIf="!card.selected" class="far fa-square"></i>
					</div>
					<div class="card-name flex-grow-1">{{ card.skill.name }}</div>
					<div class="card-status">{{ card.selected ? 'active' : 'inactive' }}</div>
					<!-- Example of card with expander icon and click handler  -->
					<!-- <div class="card-name flex-grow-1" (click)="toggleCardExpansion(card)">{{ card.skill.name }}</div>
					<div class="expand-icon ml-2" [class.skill-inactive]="!card.selected" (click)="toggleCardExpansion(card)">
						<i *ngIf="!card.expanded" class="fas fa-chevron-right"></i>
						<i *ngIf="card.expanded" class="fas fa-chevron-down"></i>
					</div> -->
				</div>

				<div *ngIf="card.skill.details" class="card-description">{{ card.skill.details }}</div>

				<div *ngIf="card.expanded">
					<hr style="margin: 10px 0px 20px 0px" />

					<div>
						<div class="link-block">
							<select
								class="form-select form-select-sm float-right"
								style="width: 80px"
								[(ngModel)]="card.userSkill.priority"
								(change)="setPriority(card.userSkill)"
							>
								<option *ngFor="let priority of priorityOptions" [ngValue]="priority.value">{{ priority.label }}</option>
							</select>
							<div class="link-label">
								Priority<i class="far fa-info-circle tts-info-btn" aria-hidden="true" (click)="showHelp('priority')"></i>
							</div>
						</div>
						<div class="link-block">
							<select
								class="form-select form-select-sm float-right"
								style="width: 80px"
								[(ngModel)]="card.userSkill.always_route"
								(change)="setAlwaysRoute(card.userSkill)"
							>
								<option *ngFor="let aro of alwaysRouteOptions" [ngValue]="aro.value">{{ aro.label }}</option>
							</select>
							<div class="link-label">
								Always Route<i class="far fa-info-circle tts-info-btn" aria-hidden="true" (click)="showHelp('always_route')"></i>
							</div>
						</div>
					</div>

					<!-- <hr />
					<code>
						<pre>
						<div>{{ card.userSkill | json }}</div>
					</pre>
					</code> -->
				</div>
			</div>
		</div>
	</div>
</div>
