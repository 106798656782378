import { IScheduleInfoData } from '@app/models/schedule'
import { RRule, RRuleSet, rrulestr } from 'rrule'

import moment from 'moment-timezone'
import { extendMoment, DateRange } from 'moment-range'

const rangeMoment = extendMoment(<any>moment)

export class ScheduleOverlapChecker {
	rule: RRule
	ruleSet: RRuleSet

	identifier: string
	timezone: string
	startDate: string
	startTime: string
	endTime: string

	isAnytime: boolean

	dateRanges: Array<DateRange> = []

	hasError = false

	constructor(data: IScheduleInfoData) {
		// log('ScheduleInfoData', data)
		if (!data.startTime || !data.endTime) {
			this.isAnytime = true
			this.hasError = true
			return
		}
		const dtStart = data.startDate.replace(/\-/g, '') + 'T' + data.startTime.replace(/\:/g, '')

		let recurRule = data.ruleStr
		if (!recurRule.includes('RRULE:')) {
			recurRule = 'RRULE:' + recurRule
		}

		const ruleString = `DTSTART;TZID=${data.timezone}:${dtStart};\n${recurRule}`
		// this.rule = RRule.fromString(ruleString)
		this.ruleSet = rrulestr(ruleString, { forceset: true }) as RRuleSet
		this.rule = this.ruleSet.rrules()[0]

		this.identifier = data.identifier
		this.timezone = data.timezone
		this.startDate = data.startDate
		this.startTime = data.startTime
		this.endTime = data.endTime

		if (data.startTime === data.endTime) {
			this.isAnytime = true
			this.startTime = '00:00:00'
			this.endTime = '23:59:59'
			return
		}

		const startDate = moment.tz(this.timezone).startOf('day').subtract(1, 'days').utc(true).toDate()
		const endDate = moment.tz(this.timezone).endOf('day').add(30, 'days').utc(true).toDate()

		const dates = this.rule.between(startDate, endDate)
		const localDateStrs = dates.map((date) => this.momentFromRuleDate(date).format('YYYY-MM-DD'))
		this.makeRanges(localDateStrs)
	}

	makeRanges(moments: Array<string>) {
		moments.forEach((dateStr) => {
			// const startMom = moment.tz(dateStr + 'T' + this.startTime, 'YYYY-MM-DDTHH:mm:ss', this.timezone)
			// const endMom = moment(dateStr + 'T' + this.endTime, 'YYYY-MM-DDTHH:mm:ss', this.timezone)
			const startMom = moment.tz(dateStr, 'YYYY-MM-DD', this.timezone).startOf('day').add(moment.duration(this.startTime))
			const endMom = moment.tz(dateStr, 'YYYY-MM-DD', this.timezone).startOf('day').add(moment.duration(this.endTime))
			// Original
			// const startMom = moment(dateStr + 'T' + this.startTime)
			// const endMom = moment(dateStr + 'T' + this.endTime)
			// Original
			if (endMom.isBefore(startMom, 'seconds')) {
				endMom.add(1, 'day')
			}
			const range = rangeMoment.range(startMom, endMom)
			this.dateRanges.push(range)
		})
	}

	containsOverlap(other: ScheduleOverlapChecker): string {
		const compareRanges = other.dateRanges
		for (const rangeA of this.dateRanges) {
			for (const rangeB of compareRanges) {
				if (rangeA.overlaps(rangeB)) {
					return other.identifier
				}
			}
		}
		return null
	}

	momentFromRuleDate(date: Date): moment.Moment {
		const dateString = date.toISOString().replace('Z', '')
		return moment(dateString)
	}
}
