import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { log } from '@app/helpers'
import {
	AuditActionEvent,
	AuditLog,
	AuditLogDataAccessRequest,
	DataAccessRequest,
	DialogManager,
	SecureFileDialogData,
	SecureFileLinkType,
	SecureFileListItem,
	SecureFileRecord,
	SecureFileUploadInfo,
} from '@app/models'
import { CoreService, DatabaseService } from '@app/services'

@Component({
    selector: 'app-secure-files-list',
    templateUrl: './secure-files-list.component.html',
    styleUrls: ['./secure-files-list.component.scss'],
    standalone: false
})
export class SecureFilesListComponent implements OnInit, AfterContentInit {
	isLoading = false
	isUploading = false
	list: Array<SecureFileListItem> = []
	currentView: 'LIST' | 'EDIT' = 'LIST'

	viewAuditAction = {
		item: null, // SecureFileListItem
		records: [],
		auditEntry: null,
		isDataLoaded: false,
		isAuditTypePickerVisible: false,
		isViewingAuditLog: false,
	}

	@Input() dialogManager: DialogManager
	@Output() recordUpdated = new EventEmitter<number>()

	constructor(private coreSrvc: CoreService) {
		log('SecureFilesListComponent initialized')
	}

	get dialogData(): SecureFileDialogData {
		return this.dialogManager.dialogData
	}

	get linkId(): number {
		return this.dialogData.linkId
	}

	get linkType(): SecureFileLinkType {
		return this.dialogData.linkType
	}

	get areAllItemsExpanded(): boolean {
		return this.list.filter((i) => !i.isExpanded).length === 0
	}

	get areAllItemsCollapsed(): boolean {
		return this.list.filter((i) => i.isExpanded).length === 0
	}

	get deletionNote(): string {
		if (this.linkType === 'ARCHIVE') {
			return 'Deleting an archived file will permanently remove it from the system.'
		}
		return null
	}

	ngOnInit(): void {
		// this.setupDialogManager()
		// this.loadData(true)
	}

	ngAfterContentInit() {
		this.setupDialogManager()
		this.loadData(true)
	}

	loadData(showIndicator: boolean) {
		this.isLoading = showIndicator
		const options = this.makeReadOptions()
		this.coreSrvc.dbSrvc.readTable('file_uploads', options).then((success) => {
			const list = this.coreSrvc.dbSrvc.secFileSrvc.getSecureFileList()
			const filteredList = this.filterList(list)
			this.list = filteredList.map((rec) => new SecureFileListItem(rec))
			this.isLoading = false
		})
	}

	private setupDialogManager() {
		this.dialogManager.submitBtnLabel = 'Add File'
		this.dialogManager.isSubmitBtnVisible = false
		this.dialogManager.cancelBtnLabel = 'Close'
		this.dialogManager.submitBtnAction = () => this.addFileBtnClicked()
		this.dialogManager.canCancel = () => !this.isUploading
	}

	// NOTE: When adding options here, make sure to add id variable in SecureFileRecord
	private makeReadOptions() {
		const linkType = this.dialogData.linkType
		const linkId = this.dialogData.linkId

		switch (linkType) {
			case 'EMPLOYEE':
				return { employee_id: linkId }
			case 'ORGANIZATION':
				return { vendor_client_org_id: linkId }
			case 'CONTACT':
				return { contact_id: linkId }
			default:
				return null
		}
	}

	private filterList(list: Array<SecureFileRecord>): Array<SecureFileRecord> {
		if (this.linkType === 'ARCHIVE') {
			return list.filter((li) => !li.hasLinkedRecords)
		}
		return list
	}

	private addFileBtnClicked() {
		// Save current dialog state then make necessary changes
		this.dialogManager.pushState()
		this.dialogData.recordId = null
		this.currentView = 'EDIT'
	}

	public editItem(item: SecureFileListItem) {
		// Save current dialog state then make necessary changes
		this.dialogManager.pushState()
		this.dialogData.recordId = item.record.id
		this.currentView = 'EDIT'
	}

	public cancelEdit() {
		// Return dialog state to what it was before editing
		this.dialogManager.popStateAndApply()
		this.currentView = 'LIST'
	}

	public fileSaved() {
		// Return dialog state to what it was before editing
		this.dialogManager.popStateAndApply()
		this.currentView = 'LIST'
		this.recordUpdated.next(this.dialogData.linkId)
		this.loadData(false)
	}

	public uploadProcessStarted() {
		this.isUploading = true
	}

	public uploadProcessComplete() {
		this.isUploading = false
	}

	public uploadComplete(uploadInfo: SecureFileUploadInfo) {
		log('Secure Files List Component - uploadComplete', uploadInfo)
		const record = this.makeInsertRecord(uploadInfo)
		this.coreSrvc.dbSrvc.insertRecord('file_uploads', record).then((success) => {
			this.loadData(false)
			this.coreSrvc.notifySrvc.notify('success', 'Upload Complete', 'File upload has completed.', 2)
			this.recordUpdated.emit(this.linkId)
		})
	}

	public deleteItem(item: SecureFileListItem) {
		log('Delete Item', item)
		if (this.linkType === 'ARCHIVE') {
			this.coreSrvc.dbSrvc.deleteRecord('file_uploads', item.record.id).then((success) => {
				this.loadData(false)
				this.coreSrvc.notifySrvc.notify('error', 'File Deleted', 'This file has been deleted.', 4)
				this.recordUpdated.emit(this.linkId)
			})
		} else {
			this.unlinkItem(item)
		}
	}

	public unlinkItem(item: SecureFileListItem) {
		log('Unlink Item', item)
		const options = { id: item.record.id }
		this.addInsertUnlinkOptions(options)
		const request = new DataAccessRequest('file_uploads', 'delete', options)
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			this.loadData(false)
			this.coreSrvc.notifySrvc.notify(
				'info',
				'File Archived',
				'This file has been archived and may be deleted from the settings control panel.',
				5,
			)
			this.recordUpdated.emit(this.linkId)
		})
	}

	public viewItem(item: SecureFileListItem) {
		log('View Item', item)
		// Create new window outside async call then set the url when call returns to get
		// safari to open the link
		const opener = window.open()
		this.getCredentialedLinkForItem(item).then((url) => {
			log('Credentialed Link', url)
			if (url) {
				// Send track event for viewing secure file
				this.coreSrvc.eventSrvc.sendSecureFileAccessEvent('VIEW', item.record, this.linkId, this.linkType)

				opener.location.assign(url)
				log('Window should have opened')
			}
		})
	}

	public downloadItem(item: SecureFileListItem) {
		log('Download Item', item)
		this.getCredentialedLinkForItem(item).then((url) => {
			// Send track event for downloading secure file
			this.coreSrvc.eventSrvc.sendSecureFileAccessEvent('DOWNLOAD', item.record, this.linkId, this.linkType)

			fetch(url, {
				method: 'GET',
			})
				.then((response) => response.blob())
				.then((blob) => {
					const url = window.URL.createObjectURL(blob)
					const a = document.createElement('a')
					a.href = url
					a.download = `${item.record.file_name}.${item.record.file_extension}`
					document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
					a.click()
					a.remove() //afterwards we remove the element again
					item.isDownloading = false
				})
		})
	}

	// auditRecord(item: SecureFileListItem) {
	// 	this.dialogManager.isDialogVisible = false
	// 	setTimeout(() => {
	// 		const auditActionEvent = new AuditActionEvent('file_uploads', item.record.id, item.record.file_name)
	// 		this.coreSrvc.eventSrvc.showAuditLog.next(auditActionEvent)
	// 	}, 100)
	// }

	pickAuditType(item: SecureFileListItem) {
		this.viewAuditAction.item = item
		this.viewAuditAction.isAuditTypePickerVisible = true
	}

	viewAuditLog(type: 'SECUREFILEACCESS') {
		this.viewAuditAction.isAuditTypePickerVisible = false
		this.dialogManager.pushState()
		this.dialogManager.isSubmitBtnVisible = false
		this.dialogManager.cancelBtnAction = () => this.viewAuditLogDone()
		this.dialogManager.cancelBtnLabel = 'Done'
		this.dialogManager.headerLabel = 'Audit History'
		const recordId = this.viewAuditAction.item.record.id
		log('Loading Records for', recordId)

		this.viewAuditAction.isViewingAuditLog = true
		this.viewAuditAction.isDataLoaded = false
		const options = { resource: 'file_uploads', resource_id: recordId, type: type }
		const request = new AuditLogDataAccessRequest(options)
		log('Routed Request', request)
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			const data = result.data
			this.viewAuditAction.records = data.map((rec) => new AuditLog(rec))

			if (this.viewAuditAction.records.length > 0) {
				this.viewAuditAction.auditEntry = this.viewAuditAction.records[this.viewAuditAction.records.length - 1]
			}
			this.viewAuditAction.isDataLoaded = true
			log('History Results', data)
		})
	}

	viewAuditLogDone() {
		this.dialogManager.popStateAndApply()
		this.viewAuditAction.records = []
		this.viewAuditAction.auditEntry = null
		this.viewAuditAction.isDataLoaded = false
		this.viewAuditAction.isViewingAuditLog = false
	}

	public setAllItemsExpansion(isExpanded: boolean) {
		for (const item of this.list) {
			item.isExpanded = isExpanded
		}
	}

	private makeInsertRecord(uploadInfo: SecureFileUploadInfo) {
		const record = new SecureFileRecord()
		const fileParts = uploadInfo.getFileParts()
		record.file_name = fileParts.name
		record.file_extension = fileParts.ext
		record.file_size = uploadInfo.file.size
		record.uuid = uploadInfo.uuid
		record.sha256 = uploadInfo.sha256
		this.addInsertUnlinkOptions(record)
		return record
	}

	private addInsertUnlinkOptions(record: any) {
		// When inserting a file record, an initial link is set to a supported resource. When unlinking a
		// record, the file record ID is referenced along with the resource_id of the resource to unlink

		const linkType = this.dialogData.linkType
		const linkId = this.dialogData.linkId

		switch (linkType) {
			case 'EMPLOYEE':
				record['employee_id'] = linkId
				break
			case 'ORGANIZATION':
				record['vendor_client_org_id'] = [linkId]
				break
			case 'CONTACT':
				record['contact_id'] = [linkId]
				break
		}
	}

	private getCredentialedLinkForItem(item: SecureFileListItem): Promise<string> {
		return new Promise((resolve, reject) => {
			const options = { id: item.record.id }
			const request = new DataAccessRequest('none', 'file_uploads_url', options)
			this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
				log('getCredentialedLink', result)
				const url = result?.data?.url as string
				resolve(url)
			})
		})
	}
}
