import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { DropdownHelper, DropdownHelperConfig, FormHelper, log } from '@app/helpers'
import { DialogManager, FormPhoneNumberManager, HelpDialogMessage, StationRecord } from '@app/models'
import { CoreService } from '@app/services'
import { environment } from '@env/environment'
import { SelectItem } from 'primeng/api'

@Component({
    selector: 'app-station-edit',
    templateUrl: './station-edit.component.html',
    styleUrls: ['./station-edit.component.scss'],
    standalone: false
})
export class StationEditComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
	environment = environment

	station: StationRecord
	isNew = false
	isUpdating = false
	stationForm: UntypedFormGroup

	employeeOptions: Array<SelectItem> = []
	jobOptions: Array<SelectItem> = []

	isInternalUser = false

	showAdvancedOptions = false

	mobileStationPhoneManager = new FormPhoneNumberManager()

	@Input() dialogManager = new DialogManager()
	@Input() recordId: number
	@Input() action = 'edit'

	@Output() recordUpdated = new EventEmitter<StationRecord>()

	constructor(
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
	) {
		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.getMyUser().role === 'INTERNAL'
	}

	get isSingleJobAssigned(): boolean {
		const currentJobIds: Array<number> = this.stationForm.get('job_ids').value
		return currentJobIds.length === 1
	}

	get isFormValid(): boolean {
		const isMobileStation = this.stationForm.get('mobile_station').value

		const isFormValid = this.stationForm.valid
		const isPhoneValid = isMobileStation ? !!this.mobileStationPhoneManager.isFormValid : true
		return isFormValid && isPhoneValid
	}

	// get isQRCodeOptionAvailable(): boolean {
	// 	const currentJobIds: Array<number> = this.stationForm.get('job_ids').value
	// 	return this.isInternalUser && this.isSingleJobAssigned
	// }

	ngOnInit() {
		if (this.action === 'new') {
			this.isNew = true
			this.station = new StationRecord()
		}
		if (this.action === 'clone') {
			this.isNew = true
			this.station = this.coreSrvc.dbSrvc.stationSrvc.getStationById(this.recordId)
		}
		if (this.action === 'edit') {
			this.isNew = false
			this.station = this.coreSrvc.dbSrvc.stationSrvc.getStationById(this.recordId)
		}

		log('Init Group Edit', this.station)

		this.setupForm()
		this.setupMobileStationPhoneManager()
		this.setupEmployeesDropdown()
		this.setupJobsDropdown()
	}

	ngAfterViewInit() {}

	ngAfterContentInit() {
		this.setupDialogManager()
	}

	ngOnDestroy() {}

	viwFormErrors() {
		FormHelper.getFormValidationErrors(this.stationForm)
	}

	setupForm() {
		const station = this.station
		this.stationForm = this.fb.group({
			id: [station.id],
			description: [station.description, Validators.required],
			details: [station.details],
			employee_ids: [station.employee_ids],
			job_ids: [station.job_ids],
			ip_allowlist: [station.ip_allowlist],
			setup_code: [station.setup_code],
			admin_code: [station.admin_code],

			kiosk_mode: [station.kiosk_mode],
			kiosk_checkpoints_enabled: [station.kiosk_checkpoints_enabled],
			kiosk_reports_enabled: [station.kiosk_reports_enabled],

			mobile_station: [station.mobile_station],
			mobile_station_e164: [station.mobile_station_e164],

			use_qr_code: [station.use_qr_code],

			use_emp_pin: [station.use_emp_pin],
			match_emp_station_pin: [station.match_emp_station_pin],
			match_emp_ext_id: [station.match_emp_ext_id],
			match_emp_phone: [station.match_emp_phone],

			use_job_pin: [station.use_job_pin],
			allow_default_job: [station.allow_default_job],
			status_list: [station.status_list],
			token: [station.token],
		})

		if (this.action === 'clone') {
			this.stationForm.get('description').setValue(null)
			this.stationForm.get('details').setValue(null)
		}
	}

	setupDialogManager() {
		this.dialogManager.canSubmit = () => this.isFormValid
		this.dialogManager.submitBtnAction = () => this.submit()
	}

	setupMobileStationPhoneManager() {
		this.mobileStationPhoneManager = new FormPhoneNumberManager()
		this.mobileStationPhoneManager.showOptionalOrRequired = true
		this.mobileStationPhoneManager.isRequired = true
		this.mobileStationPhoneManager.e164 = this.station.mobile_station_e164
		this.mobileStationPhoneManager.label = 'Mobile Station Number'
	}

	setupEmployeesDropdown() {
		const config = new DropdownHelperConfig(this.coreSrvc.dbSrvc, 'MULTISELECT')
		const dropdownHelper = new DropdownHelper(config)
		const options = dropdownHelper.buildGroupedEmployeeMenuOptions()
		this.employeeOptions = options
	}

	setupJobsDropdown() {
		const config = new DropdownHelperConfig(this.coreSrvc.dbSrvc, 'MULTISELECT')
		const dropdownHelper = new DropdownHelper(config)
		const options = dropdownHelper.buildGroupedJobMenuOptions()
		this.jobOptions = options
	}

	submit() {
		// Guard against double submission
		if (this.isUpdating) return
		// FormHelper.trimOnlyWhitespace(this.stationForm)

		const record = this.makeUpdateRecord()
		log('Record to submit', record)
		if (record) {
			if (this.isNew) {
				log('Adding new organization', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.insertRecord('station', record).then((success) => {
					if (success) {
						this.recordUpdated.emit(this.station)
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			} else {
				log('Updating record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.updateRecord('station', record).then((success) => {
					if (success) {
						this.recordUpdated.emit(this.station)
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			}
		}
	}

	adminCodeValidator() {
		const inputValue = this.stationForm.get('admin_code').value
		const result = inputValue.replace(/\D/g, '').slice(0, 10)
		this.stationForm.get('admin_code').setValue(result)
	}

	getPermissionsJsonString() {}

	makeUpdateRecord(): Object {
		const record = new StationRecord(this.station)
		const form = this.stationForm.getRawValue()

		record.description = form.description
		record.details = form.details
		record.employee_ids = form.employee_ids
		record.job_ids = form.job_ids
		record.allow_default_job = form.allow_default_job
		record.ip_allowlist = form.ip_allowlist
		record.setup_code = form.setup_code
		record.admin_code = form.admin_code
		record.kiosk_mode = form.kiosk_mode
		record.kiosk_checkpoints_enabled = form.kiosk_checkpoints_enabled
		record.kiosk_reports_enabled = form.kiosk_reports_enabled
		record.use_qr_code = this.isSingleJobAssigned ? form.use_qr_code : false

		// Handle employee pin pad with matcher options
		record.use_emp_pin = form.use_emp_pin

		record.match_emp_station_pin = record.use_emp_pin ? form.match_emp_station_pin : false
		record.match_emp_ext_id = record.use_emp_pin ? form.match_emp_ext_id : false
		record.match_emp_phone = record.use_emp_pin ? form.match_emp_phone : false

		if (record.use_emp_pin) {
			if (!(record.match_emp_station_pin || record.match_emp_ext_id || record.match_emp_phone)) {
				this.coreSrvc.notifySrvc.notify(
					'error',
					'Not Allowed',
					'You must select at least one matcher when using the PIN pad for employee selection.',
					7,
				)
				return null
			}
		}

		record.use_job_pin = form.use_job_pin
		record.status_list = form.status_list
		record.token = form.token

		// If mobile station checkbox selected, update record
		if (form.mobile_station) {
			record.mobile_station = true
			record.mobile_station_e164 = this.mobileStationPhoneManager.e164
		} else {
			record.mobile_station = false
			record.mobile_station_e164 = null
		}

		// Disable QR code if you're not in kiosk mode
		if (!record.kiosk_mode) {
			record.use_qr_code = false
		}

		return record
	}

	// Misc Methods

	toggleCheckbox(prop: string) {
		log('prop', prop)
		const current = this.stationForm.get(prop).value
		this.stationForm.get(prop).setValue(!current)
		if (prop === 'mobile_station') {
			this.scrollIntoView('#mobileStationE164Input')
		}
	}

	scrollIntoView(id: string) {
		this.coreSrvc.displaySrvc.bringIntoViewBySelector(id)
	}

	processKioskModeRadio() {
		log('Kisok Mode', this.stationForm.get('kiosk_mode').value)
		if (this.stationForm.get('kiosk_mode').value) {
			this.stationForm.get('mobile_station_e164').setValue(null)
			this.stationForm.get('mobile_station').setValue(false)
		}
	}

	public copySetupCode() {
		const recordId = this.station.id
		const setupCode = this.stationForm.get('setup_code').value
		const description = this.station.description
		this.coreSrvc.dbSrvc.stationSrvc.copySetupCode(recordId, setupCode, description)
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'setupLink':
				help.header = 'Setup Link'
				help.message =
					'This will copy the station setup link to your clipboard.<br /><br /><b style="color:firebrick">Warning:</b> Be careful who you share this link with as it can be used multiple times to setup the station on any device with a web browser.'
				break
			case 'admin_code':
				help.header = 'Admin Code'
				help.message =
					'Set the administrator access code for this station. Only numbers are allowed and the code can be up to 10 digits. If you do not add a PIN, anyone will be able to adjust administrative options or disable the station.'
				break
			case 'employee_ids':
				help.header = 'Allowed Employees'
				help.message = 'Select the employees who are allowed to check in/out using this station'
				break
			case 'job_ids':
				help.header = 'Allowed Jobs'
				help.message = 'Select the jobs that are available for check in/out from this station.'
				break
			case 'allow_default_job':
				help.header = 'Default Job'
				help.message = 'When checked, employees will be allowed to clock in/out using the default (unassigned) job.'
				break
			case 'details':
				help.header = 'Notes'
				help.message =
					'Enter administrative notes for this station. These notes are not displayed to employees, however, they will be displayed in station lists when using IP address auto-configuration.'
				break
			case 'ip_allowlist':
				help.header = 'IP Address / CIDR'
				help.message =
					'Enter a public IP address or CIDR block which is allowed to use this station. This is an advanced auto-configuration option and can safely be ignored. If you would like more information, please see the online manual.'
				break
			case 'station_mode':
				help.header = 'Station Mode'
				help.message =
					'<b>Personal Mode</b> allows a single employee at a time to clock in/out. You should have a unique station record and setup code for every device when using personal mode.\n\n<b>Kiosk Mode</b> allows multiple users to clock in/out. You may reuse the same station record and setup code for multiple devices when using kiosk mode.'
				break
			case 'kiosk_checkpoints_enabled':
				help.header = 'Kiosk Checkpoints'
				help.message = `When checked, employees will be allowed to submit photo checkpoints from the kiosk.`
				break
			case 'kiosk_reports_enabled':
				help.header = 'Kiosk Reports'
				help.message = `When checked, employees will be allowed to submit reports from the kiosk.<br><br>Please be aware that submitting reports may take several minutes and can lead to a back log of employees waiting to use the system.<br><br>Consider setting up multiple kiosks if you enable this option.`
				break
			case 'mobile_station':
				help.header = 'Mobile Station'
				help.message = `This is a special configuration for station mode where multiple employees will share a single mobile device.\n\n<b style="color:firebrick">Note</b>: When an employee is checked in to a mobile station, system calls and text messages will be sent to the shared mobile device instead of the employee's mobile number.`
				break
			case 'use_qr_code':
				help.header = 'QR Code'
				help.message =
					'When checked, a kiosk will show a QR code which employees may scan to check in/out. An option to switch to manual entry will be made available when showing QR codes.'
				break

			case 'use_emp_pin':
				help.header = 'Employee PINs'
				help.message =
					'When checked, stations will present a PIN pad for selecting an employee. If you use this option, you must select at least one matcher from the list provided. When unchecked, a list of employees will be displayed.<br><br> <b>Station PIN</b> codes, <b>External IDs</b>, and <b>Phone Numbers</b> are all configured in the employee record.'
				break
			case 'match_emp_station_pin':
				help.header = 'Station PIN'
				help.message = `When checked, employees can use their <b>Station PIN</b> to identify themselves at this station. The <b>Station PIN</b> can be configured in the employee record under <b>Advanced Options</b>.`
				break
			case 'match_emp_ext_id':
				help.header = 'External ID'
				help.message = `When checked, employees can use their <b>External ID</b> to identify themselves at this station. The <b>External ID</b> can be configured in the employee record under <b>Advanced Options</b>.`
				break
			case 'match_emp_phone':
				help.header = 'Employee Phone'
				help.message = `When checked, employees can use their 10-digit mobile <b>Phone Number</b> to identify themselves at this station. The <b>Phone Number</b> can be configured in the employee record.`
				break

			case 'use_job_pin':
				help.header = 'Job PINs'
				help.message =
					'When checked, stations will present a PIN pad for selectig a job using a job code. When unchecked, a list of jobs is displayed.'
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
