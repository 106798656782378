import { Component, EventEmitter, Input, Output } from '@angular/core'
import { log } from '@app/helpers'
import { ChecklistFormConfigSelectBasic, ChecklistFormConfigSelectBasicOption, ChecklistFormItem } from '@app/models'

@Component({
    selector: 'app-checklist-form-item-select',
    templateUrl: './checklist-form-item-select.component.html',
    styleUrl: './checklist-form-item-select.component.scss',
    standalone: false
})
export class ChecklistFormItemSelectComponent {
	@Input() item: ChecklistFormItem
	@Input() blockIndex: number

	@Output() textResizer = new EventEmitter<HTMLTextAreaElement>()
	@Output() clearError = new EventEmitter<ChecklistFormItem>()
	@Output() showHelp = new EventEmitter<string>()

	selectedOption: ChecklistFormConfigSelectBasicOption

	get config(): ChecklistFormConfigSelectBasic {
		return this.item?.config as ChecklistFormConfigSelectBasic
	}

	public addOption() {
		this.config.options.push({ label: '', value: null, selected: false })
	}

	public moveUp(item: ChecklistFormConfigSelectBasicOption) {
		this.moveItem(item, 'UP')
	}
	public moveDown(item: ChecklistFormConfigSelectBasicOption) {
		this.moveItem(item, 'DOWN')
	}
	public moveItem(item: ChecklistFormConfigSelectBasicOption, direction: 'UP' | 'DOWN') {
		const currentIndex = this.config.options.indexOf(item)
		// Check if item is at beginning or end of list and prevent movement out of range
		const canMove = direction === 'UP' ? currentIndex > 0 : currentIndex < this.config.options.length - 1
		if (canMove) {
			const newIndex = direction === 'UP' ? currentIndex - 1 : currentIndex + 1
			;[this.config.options[currentIndex], this.config.options[newIndex]] = [this.config.options[newIndex], this.config.options[currentIndex]]
		}
	}

	public handleEnterKey(option: ChecklistFormConfigSelectBasicOption, event) {
		// Check if option is the last in the list of this.config.options
		event.preventDefault()
		if (this.config.options.indexOf(option) === this.config.options.length - 1) {
			// Do something
			this.addOption()
			setTimeout(() => {
				const optionCount = this.config.options.length - 1
				const elemId = 'block' + '-' + this.blockIndex + '-option-' + optionCount
				$('#' + elemId).trigger('focus')
				log('Focused', elemId)
			}, 50)
		} else {
			event.target.blur()
		}
	}

	public deleteBtnClicked(option: ChecklistFormConfigSelectBasicOption) {
		this.selectedOption = option
	}

	public confirmDelete() {
		this.remove(this.selectedOption)
		this.selectedOption = null
	}

	public remove(option) {
		const newList = this.config.options.filter((opt) => opt !== option)
		this.config.options = newList
	}

	public handleStyleChange() {
		if (this.config.style === 'MENU') {
			this.config.multi.allow = false
		}
	}
}
