import { log, PhoneHelper } from '@app/helpers'
import { DatabaseService } from '@app/services'
import { CountryCode, formatNumber, isValidNumber, ParsedNumber, parseNumber } from 'libphonenumber-js'
import { ContactType } from './contact'
import _ from 'lodash'

export class C2CEventSubmittedEvent {
	success: boolean
	action: 'CALL' | 'TEXT'
	callRecord: ClickToCallRecord

	constructor(success: boolean, action: 'CALL' | 'TEXT', callRecord: ClickToCallRecord) {
		this.success = success
		this.action = action
		this.callRecord = callRecord
	}
}

export type ClickToCallSourceType =
	| 'EMPLOYEEDETAILS' // Employee Details
	| 'EMPLOYEEPHONE' // Employee Mobile Phone
	| 'EMPLOYEESUPERVISOR' // Employee Supervisor Phone
	| 'SCHEDULEDETAILS' // Schedule Details
	| 'SITEDETAILS' // Site Details
	| 'SITESUPERVISOR' // Site Supervisor
	| 'USERPHONE' // User Phone
	| 'JOBDETAILS' // Job Details
	| 'SCHEDULEDEMPLOYEE' // Scheduled Employee
	| 'SCHEDULENOTE' // Schedule Note
	| 'TRANSACTIONNOTE' // Transaction Note
	| 'INBOUNDCALL' // Inbound Call
	| 'UNSPECIFIED' // Unspecified (Default)
	| 'CLIENT' // Client Record
	| 'VENDOR' // Vendor Record
	| 'CONTACT' // Contact Record
	| 'CLIENTCONTACT' // Client Contact
	| 'VENDORCONTACT' // Vendor Contact
	| 'INBOUNDSMS' // Inbound SMS
	| 'OUTBOUNDSMS' // Outbound SMS
	| 'VOICECHECKPOINT' // Voice Checkpoint (Dispatcher Checkpoint)

	// Lookup only sources
	| 'LINKEDNUMBER'
	| 'DISPATCH'
	| 'MAIN'

export class ClickToCallSourceTypeFormatter {
	static getDescription(sourceType: ClickToCallSourceType): string {
		switch (sourceType) {
			case 'EMPLOYEEDETAILS':
				return 'Employee Details'
			case 'EMPLOYEEPHONE':
				return 'Employee'
			case 'EMPLOYEESUPERVISOR':
				return 'Employee Supervisor'
			case 'SCHEDULEDETAILS':
				return 'Schedule Details'
			case 'SITEDETAILS':
				return 'Site Details'
			case 'SITESUPERVISOR':
				return 'Site Supervisor'
			case 'USERPHONE':
				return 'Supervisor'
			case 'JOBDETAILS':
				return 'Job Details'
			case 'SCHEDULEDEMPLOYEE':
				return 'Scheduled Employee'
			case 'SCHEDULENOTE':
				return 'Schedule Notes'
			case 'TRANSACTIONNOTE':
				return 'Time Entry Notes'
			case 'INBOUNDCALL':
				return 'Inbound Call'
			case 'CLIENT':
				return 'Client'
			case 'VENDOR':
				return 'Vendor'
			case 'CONTACT':
				return 'General Contact'
			case 'CLIENTCONTACT':
				return 'Client Contact'
			case 'VENDORCONTACT':
				return 'Vendor Contact'
			case 'INBOUNDSMS':
				return 'Inbound Text'
			case 'OUTBOUNDSMS':
				return 'Outbound Text'
			case 'VOICECHECKPOINT':
				return 'Dispatcher Checkpoint'

			// Front end only (used for lookups)
			case 'LINKEDNUMBER':
				return 'Linked Number'
			case 'DISPATCH':
				return 'Dispatch'
			case 'MAIN':
				return 'Main' // Used to highlight parent record in address books

			default:
				return 'Unspecified'
		}
	}
}

export class ClickToCallRecord {
	sourceType: ClickToCallSourceType
	description: string
	phoneE164: string
	email: string
	contactType: ContactType

	notes = ''
	isPrimaryContact = false
	isManagerContact = false

	managerName = ''

	destEmpId = null
	destUserId = null

	filterText = ''

	linkedContacts: Array<ClickToCallRecord> = []

	constructor(sourceType: ClickToCallSourceType, description: string, notes: string, phoneE164: string, email: string) {
		this.sourceType = sourceType
		this.description = description ?? ''
		this.notes = notes ?? ''
		this.phoneE164 = phoneE164 ?? ''
		this.email = email ?? ''

		// Set dispatch to user as it's only meant for use when looking up a number
		if (sourceType === 'DISPATCH') this.sourceType = 'USERPHONE'
		this.updateFilterText()
	}

	private updateFilterText() {
		const formattedPhone = PhoneHelper.formatPhoneFromE164(this.phoneE164)
		this.filterText = `${this.description}*${this.notes}*${this.email}*${formattedPhone}`.toLowerCase()
	}
}

export interface ClickToCallMenuOption {
	type: ClickToCallSourceType
	label: string
}

export class ClickToCallSourceConfig {
	type: 'EMP' | 'USER' | 'TRANS' | 'SCHEDLOG' | 'CUSTOM' = null
	sourceId: number = null
	canDismiss = false
}

export class ClickToCallGlobalConfig {
	c2cRecord: ClickToCallRecord
	action: 'TEXT' | 'CALL' | 'LOG'
	placeholder = ''
	initialMsg = ''
	sourceConfig = new ClickToCallSourceConfig()

	constructor(c2cRecord: ClickToCallRecord, action: 'TEXT' | 'CALL' | 'LOG', placeholder: string, initialMsg: string) {
		this.c2cRecord = c2cRecord
		this.action = action
		this.placeholder = placeholder ?? ''
		this.initialMsg = initialMsg ?? ''
	}
}

export type ClickToCallDialogViewType = 'CONTACTS' | 'NOTES' | 'EVENTLOG'

export class ClickToCallEventStatus {
	isPreparingText = false
	isSendingMessage = false
	isUpdatingNotes = false
	isPlacingCall = false
	isRefreshing = false
	source: ClickToCallRecord
	dest: ClickToCallRecord
	message: string
}

export class ClickToCallDialogManager {
	config: ClickToCallSourceConfig
	showDialog = false
	canDismiss = true
	currentView: ClickToCallDialogViewType = 'CONTACTS'
	eventStatus = new ClickToCallEventStatus()

	resetStatus() {
		this.eventStatus = new ClickToCallEventStatus()
	}
}

export class ClickToCallRequestBuilder {
	destPhoneE164: string
	destName: string
	callSource: ClickToCallSourceType

	destEmpId: number // optional - ID  of employee record which triggered lookup
	destUserId: number // optional - ID of user record which triggered lookup

	transactionId: number = null // optional - ID of transaction which triggered lookup
	scheduleLogId: number = null // optionsl - ID of schedule log entry which triggered lookup

	constructor(dest: ClickToCallRecord, config: ClickToCallSourceConfig) {
		this.destName = dest.description
		this.destPhoneE164 = dest.phoneE164
		this.destEmpId = dest.destEmpId
		this.destUserId = dest.destUserId
		this.callSource = dest.sourceType

		switch (config?.type) {
			case 'EMP':
				this.destEmpId = config.sourceId
				break
			case 'USER':
				this.destUserId = config.sourceId
				break
			case 'TRANS':
				this.transactionId = config.sourceId
				break
			case 'SCHEDLOG':
				this.scheduleLogId = config.sourceId
				break
		}
	}

	getLambdaRequest(message?: string) {
		const result: any = {
			dest_phone_e164: this.destPhoneE164,
			dest_name: this.destName,
			call_source: message ? 'OUTBOUNDSMS' : this.callSource,
			dest_employee_id: this.destEmpId,
			dest_user_id: this.destUserId,
			transaction_id: this.transactionId,
			schedule_log_id: this.scheduleLogId,
		}
		if (message) result.transcript = message
		return result
	}
}

export type C2CDirectoryLookupSource = 'USER' | 'EMPLOYEE' | 'SYSTEM' | 'LINKEDJOB'

export class C2CDirectoryLookup {
	dbSrvc: DatabaseService

	constructor(dbSrvc: DatabaseService) {
		this.dbSrvc = dbSrvc
	}

	lookupNumber(number: string, sources: Array<C2CDirectoryLookupSource>) {
		// ['USER','EMPLOYEE','SYSTEM','LINKEDJOB']
		// ['USER','SYSTEM','LINKEDJOB']

		// Check for user
		if (sources.includes('USER')) {
			const user = this.dbSrvc.settingSrvc.getUserForC2CE164Number(number)
			if (user) {
				return new C2CDirectoryLookupResult(user.first_name + ' ' + user.last_name, 'USERPHONE')
			}
		}

		// Check for employee
		if (sources.includes('EMPLOYEE')) {
			const emp = this.dbSrvc.empSrvc.getEmployeeForPhoneNumberE164(number)
			if (emp) {
				return new C2CDirectoryLookupResult(emp.name, 'EMPLOYEEPHONE')
			}
		}

		// Check for system call in number
		if (sources.includes('SYSTEM')) {
			const phoneNumber = this.dbSrvc.settingSrvc.getPhoneNumberRecordForE164CallIn4Number(number)
			if (phoneNumber) {
				return new C2CDirectoryLookupResult('System Number', 'DISPATCH')
			}
		}

		// Check for linked job number
		if (sources.includes('LINKEDJOB')) {
			const linkedJob = this.dbSrvc.siteSrvc.jobSitesForLinkedE164Number(number)
			if (linkedJob.length > 0) {
				return new C2CDirectoryLookupResult(linkedJob[0].description, 'LINKEDNUMBER')
			}
		}

		return null
	}
}

export class C2CDirectoryLookupResult {
	name: string
	source: ClickToCallSourceType

	constructor(name: string, source: ClickToCallSourceType) {
		this.name = name
		this.source = source
	}
}

export class ClickToCallListBuilder {
	dialingCode: CountryCode = 'US'

	constructor(private dbSrvc: DatabaseService) {
		this.dialingCode = this.dbSrvc.settingSrvc.getCompany().country_iso as CountryCode
	}

	// getRecords methods take ID of the record used to generate list and type which indicates the categaory to group them under

	getRecordsForEmployees(empIds: Array<number>, type: ClickToCallSourceType): Array<ClickToCallRecord> {
		const records: Array<ClickToCallRecord> = []

		for (const empId of empIds) {
			const emp = this.dbSrvc.empSrvc.getEmployeeById(empId)
			if (emp) {
				const empRec = new ClickToCallRecord(type, emp.name, null, emp.phone_number_e164, emp.email)
				empRec.destEmpId = emp.id
				empRec.isPrimaryContact = true
				records.push(empRec)
			}
		}
		return _.sortBy(records, 'description')
	}

	getRecordsForSupervisors(supIds: Array<number>, type: ClickToCallSourceType): Array<ClickToCallRecord> {
		const records: Array<ClickToCallRecord> = []

		for (const supId of supIds) {
			const sup = this.dbSrvc.settingSrvc.getUserForId(supId)
			if (sup) {
				const supRec = new ClickToCallRecord(type, sup.first_name + ' ' + sup.last_name, null, sup.phone_e164, sup.email)
				supRec.destUserId = sup.id
				supRec.isPrimaryContact = sup.role === 'PRIMARY' ? true : false
				records.push(supRec)
			}
		}
		return _.sortBy(records, 'description')
	}

	getRecordsForGeneralContacts(contactIds: Array<number>, type: ClickToCallSourceType): Array<ClickToCallRecord> {
		const records: Array<ClickToCallRecord> = []
		for (const contactId of contactIds) {
			const contact = this.dbSrvc.contactSrvc.getContactById(contactId)
			if (contact) {
				const contactRec = new ClickToCallRecord(type, contact.name, null, contact.phone_e164, contact.email)
				contactRec.isPrimaryContact = true
				records.push(contactRec)
			}
		}
		return _.sortBy(records, 'description')
	}

	getRecordsForPrimaryClients(clientIds: Array<number>, type: ClickToCallSourceType): Array<ClickToCallRecord> {
		const records: Array<ClickToCallRecord> = []
		for (const clientId of clientIds) {
			const client = this.dbSrvc.orgSrvc.getOrganizationById(clientId)
			if (client) {
				const clientRec = new ClickToCallRecord(type, client.name, null, client.phone_e164, client.email)
				clientRec.isPrimaryContact = true
				records.push(clientRec)

				// Setup linked contacts
				for (const linkRecord of client.vendor_client_contact) {
					const linkContact = this.dbSrvc.contactSrvc.getContactById(linkRecord.contact_id)
					if (linkContact) {
						const linkCallRec = new ClickToCallRecord(
							type,
							client.name + ' - ' + linkContact.name,
							linkRecord.details,
							linkContact.phone_e164,
							linkContact.email,
						)
						linkCallRec.isPrimaryContact = false
						records.push(linkCallRec)
					}
				}
			}
		}
		return _.sortBy(records, 'description')
	}

	getRecordsForPrimaryVendors(vendorIds: Array<number>, type: ClickToCallSourceType): Array<ClickToCallRecord> {
		const records: Array<ClickToCallRecord> = []
		for (const vendorId of vendorIds) {
			const vendor = this.dbSrvc.orgSrvc.getOrganizationById(vendorId)
			if (vendor) {
				const vendorRec = new ClickToCallRecord(type, vendor.name, null, vendor.phone_e164, vendor.email)
				vendorRec.isPrimaryContact = true
				records.push(vendorRec)
			}

			// Setup linked contacts
			for (const linkRecord of vendor.vendor_client_contact) {
				const linkContact = this.dbSrvc.contactSrvc.getContactById(linkRecord.contact_id)
				if (linkContact) {
					const linkCallRec = new ClickToCallRecord(
						type,
						vendor.name + ' - ' + linkContact.name,
						linkRecord.details,
						linkContact.phone_e164,
						linkContact.email,
					)
					linkCallRec.isPrimaryContact = false
					records.push(linkCallRec)
				}
			}
		}
		return _.sortBy(records, 'description')
	}

	getRecordsForEmployeeSupervisor(empId: number, type: ClickToCallSourceType): Array<ClickToCallRecord> {
		const managerRecords: Array<ClickToCallRecord> = []
		const primarySupRecords: Array<ClickToCallRecord> = []
		const supRecords: Array<ClickToCallRecord> = []

		const emp = this.dbSrvc.empSrvc.getEmployeeById(empId)
		if (emp) {
			const empSupIds = emp.supervisor_ids
			const primaryEmpSupId = emp.supervisor
			const managerIds = []
			empSupIds.forEach((empSupId) => {
				const user = this.dbSrvc.settingSrvc.getUserForId(empSupId)
				if (user) {
					const userRec = new ClickToCallRecord(type, user.first_name + ' ' + user.last_name, null, user.phone_e164, user.email)
					userRec.destUserId = user.id
					if (user.managed_by) {
						managerIds.push(user.managed_by) // Add ID to managed list
						const manager = this.dbSrvc.settingSrvc.getUserForId(user.managed_by)
						if (manager) {
							userRec.managerName = manager.first_name + ' ' + manager.last_name
						}
					}

					if (empSupId === primaryEmpSupId) {
						userRec.isPrimaryContact = true
						userRec.contactType = 'PRIMARY'
						primarySupRecords.push(userRec)
					} else {
						supRecords.push(userRec)
					}
				}
			})
			const uniqueManagerIds = _.uniq(managerIds)
			uniqueManagerIds.forEach((managerId) => {
				const managerRecord = this.getRecordForManagerId(managerId, type)
				if (managerRecord) managerRecords.push(managerRecord)
			})
		}
		const sortedManagerRecords = _.sortBy(managerRecords, 'description')
		const sortedPrimaryRecords = _.sortBy(primarySupRecords, 'description')
		const sortedSupRecords = _.sortBy(supRecords, 'description')
		return [...sortedManagerRecords, ...sortedPrimaryRecords, ...sortedSupRecords]
	}

	getRecordsForEmployeeDetails(empId: number, type: ClickToCallSourceType): Array<ClickToCallRecord> {
		const emp = this.dbSrvc.empSrvc.getEmployeeById(empId)
		return emp ? this.parseRecordsFromString(emp.employee_details, type) : []
	}

	getRecordsForSiteSupervisor(siteId: number, type: ClickToCallSourceType): Array<ClickToCallRecord> {
		const managerRecords: Array<ClickToCallRecord> = []
		const primarySupRecords: Array<ClickToCallRecord> = []
		const supRecords: Array<ClickToCallRecord> = []

		const site = this.dbSrvc.siteSrvc.getJobSiteById(siteId)
		if (site) {
			const siteSupIds = site.supervisor_ids
			const primarySiteSupId = site.supervisor
			const managerIds = []
			siteSupIds.forEach((siteSupId) => {
				const user = this.dbSrvc.settingSrvc.getUserForId(siteSupId)
				if (user) {
					const userRec = new ClickToCallRecord(type, user.first_name + ' ' + user.last_name, null, user.phone_e164, user.email)
					userRec.destUserId = user.id
					if (user.managed_by) {
						managerIds.push(user.managed_by) // Add ID to managed list
						const manager = this.dbSrvc.settingSrvc.getUserForId(user.managed_by)
						if (manager) {
							userRec.managerName = manager.first_name + ' ' + manager.last_name
						}
					}
					if (siteSupId === primarySiteSupId) {
						userRec.isPrimaryContact = true
						userRec.contactType = 'PRIMARY'
						primarySupRecords.push(userRec)
					} else {
						supRecords.push(userRec)
					}
				}
			})
			const uniqueManagerIds = _.uniq(managerIds)
			uniqueManagerIds.forEach((managerId) => {
				const managerRecord = this.getRecordForManagerId(managerId, type)
				if (managerRecord) managerRecords.push(managerRecord)
			})
		}
		const sortedManagerRecords = _.sortBy(managerRecords, 'description')
		const sortedPrimaryRecords = _.sortBy(primarySupRecords, 'description')
		const sortedSupRecords = _.sortBy(supRecords, 'description')
		return [...sortedManagerRecords, ...sortedPrimaryRecords, ...sortedSupRecords]
	}

	getRecordForManagerId(managerId: number, type: ClickToCallSourceType): ClickToCallRecord {
		const manager = this.dbSrvc.settingSrvc.getUserForId(managerId)
		if (manager) {
			const managerRec = new ClickToCallRecord(type, manager.first_name + ' ' + manager.last_name, null, manager.phone_e164, manager.email)
			managerRec.destUserId = manager.id
			managerRec.isManagerContact = true
			managerRec.contactType = 'MANAGER'
			return managerRec
		}
		return null
	}

	getRecordsForSiteDetails(siteId: number, type: ClickToCallSourceType): Array<ClickToCallRecord> {
		const site = this.dbSrvc.siteSrvc.getJobSiteById(siteId)
		return site ? this.parseRecordsFromString(site.location_details, type) : []
	}

	getRecordsForJobDetails(jobId: number, type: ClickToCallSourceType): Array<ClickToCallRecord> {
		const job = this.dbSrvc.jobSrvc.getJobById(jobId)
		return job ? this.parseRecordsFromString(job.job_details, type) : []
	}

	getRecordsForScheduledEmployees(schedRecurId: number, type: ClickToCallSourceType): Array<ClickToCallRecord> {
		const records: Array<ClickToCallRecord> = []
		const schedRecur = this.dbSrvc.schedulerSrvc.getScheduleForId(schedRecurId)
		if (schedRecur) {
			// Get scheduled employees and filter out the any employee record
			const scheduledEmpIds = schedRecur.employee_ids.filter((id) => id !== 0)
			const scheduledEmpId = schedRecur.employee_id
			if (scheduledEmpId !== 0 && scheduledEmpId !== 1) scheduledEmpIds.unshift(scheduledEmpId)
			// scheduledEmpIds.forEach((empId) => {
			const empRecords = this.getRecordsForEmployees(scheduledEmpIds, type)
			records.push(...empRecords)
			// })
			// log('Scheduled Employees', scheduledEmpIds)
		}
		return _.sortBy(records, 'description')
	}

	getRecordsForScheduleDetails(recurId: number, type: ClickToCallSourceType): Array<ClickToCallRecord> {
		const sched = this.dbSrvc.schedulerSrvc.getScheduleForId(recurId)
		return sched ? this.parseRecordsFromString(sched.description, type) : []
	}

	getRecordsForScheduleNotes(recurId: number, type: ClickToCallSourceType): Array<ClickToCallRecord> {
		const sched = this.dbSrvc.schedulerSrvc.getScheduleForId(recurId)
		return sched ? this.parseRecordsFromString(sched.comments, type) : []
	}

	getRecordsForTransactionNotes(transId: number, type: ClickToCallSourceType): Array<ClickToCallRecord> {
		const trans = this.dbSrvc.tranSrvc.getTransactionById(transId)
		return trans ? this.parseRecordsFromString(trans.notes, type) : []
	}

	getRecordsForTransactionClients(transId: number, type: ClickToCallSourceType): Array<ClickToCallRecord> {
		let clientRecord: ClickToCallRecord = null
		let records: Array<ClickToCallRecord> = []
		const trans = this.dbSrvc.tranSrvc.getTransactionById(transId)

		const clientId = trans.client_id
		const client = this.dbSrvc.orgSrvc.getOrganizationById(clientId)
		if (client) {
			const callRec = new ClickToCallRecord(type, client.name, client.details, client.phone_e164, client.email)
			callRec.isPrimaryContact = true
			callRec.contactType = 'MAIN'
			clientRecord = callRec
			for (const linkRecord of client.vendor_client_contact) {
				const linkContact = this.dbSrvc.contactSrvc.getContactById(linkRecord.contact_id)
				if (linkContact) {
					const linkCallRec = new ClickToCallRecord(type, linkContact.name, linkRecord.details, linkContact.phone_e164, linkContact.email)
					// linkCallRec.contactType = linkRecord.association_type
					records.push(linkCallRec)
				}
			}
		}
		records = _.sortBy(records, 'description')
		if (clientRecord) records.unshift(clientRecord)
		return records
	}

	getRecordsForTransactionVendors(transId: number, type: ClickToCallSourceType): Array<ClickToCallRecord> {
		let vendorRecord: ClickToCallRecord = null
		let records: Array<ClickToCallRecord> = []
		const trans = this.dbSrvc.tranSrvc.getTransactionById(transId)

		const vendorId = trans.vendor_id
		const vendor = this.dbSrvc.orgSrvc.getOrganizationById(vendorId)
		if (vendor) {
			const callRec = new ClickToCallRecord(type, vendor.name, vendor.details, vendor.phone_e164, vendor.email)
			callRec.isPrimaryContact = true
			callRec.contactType = 'MAIN'
			vendorRecord = callRec
			for (const linkRecord of vendor.vendor_client_contact) {
				const linkContact = this.dbSrvc.contactSrvc.getContactById(linkRecord.contact_id)
				if (linkContact) {
					const linkCallRec = new ClickToCallRecord(type, linkContact.name, linkRecord.details, linkContact.phone_e164, linkContact.email)
					// linkCallRec.contactType = linkRecord.association_type
					records.push(linkCallRec)
				}
			}
		}
		records = _.sortBy(records, 'description')
		if (vendorRecord) records.unshift(vendorRecord)
		return records
	}

	parseRecordsFromString(input: string, type: ClickToCallSourceType): Array<ClickToCallRecord> {
		const details = input ?? ''
		const regexp = /\[\s*(?<desc>[^:\]]+)\s*:\s*(?<phone>\+?(1|44)?\d{3}[\s.-]?\d{3}[\s.-]?\d{4})\s*\]/
		const matches = this.matchAll(regexp, details)
		// log('ScheduleDetails Matches', matches)
		const records: Array<ClickToCallRecord> = []

		for (const match of matches) {
			// log('ScheduleDetails Match', match)
			const description = match[1] ?? '' // match?.groups?.desc || ''
			const phone = match[2] ?? '' // match?.groups?.phone || ''
			// log('ScheduleDetails Desc/Phone', description, phone)
			if (description && phone) {
				const hasPlus = phone.includes('+')
				const parsedNumber = hasPlus ? (parseNumber(phone) as ParsedNumber) : (parseNumber(phone, this.dialingCode) as ParsedNumber)
				if (parsedNumber.phone && isValidNumber(parsedNumber)) {
					const phoneE164 = formatNumber(parsedNumber, 'E.164')
					log('Found', description, phoneE164)
					const detailsRec = new ClickToCallRecord(type, description, null, phoneE164, null)
					records.push(detailsRec)
				}
			}
		}
		return _.sortBy(records, 'description')
	}

	matchAll(pattern: RegExp, haystack: string) {
		const regex = new RegExp(pattern, 'g')
		const matches = []

		const match_result = haystack.match(regex)

		for (const index in match_result) {
			if (match_result[index]) {
				const item = match_result[index]
				matches[index] = item.match(new RegExp(pattern))
			}
		}
		return matches
	}
}

export class ClickToCallViewModel {
	status = new ClickToCallEventStatus()

	records: Array<ClickToCallRecord> = []
	options: Array<ClickToCallMenuOption> = []
	selectedOptionType: ClickToCallSourceType = null

	sourceTypes: Array<ClickToCallSourceType> = [
		'EMPLOYEEPHONE',
		'EMPLOYEESUPERVISOR',
		'EMPLOYEEDETAILS',
		'SITESUPERVISOR',
		'SITEDETAILS',
		'JOBDETAILS',
		'SCHEDULEDEMPLOYEE',
		'SCHEDULEDETAILS',
		'SCHEDULENOTE',
		'TRANSACTIONNOTE',
		'CLIENT',
		'VENDOR',
	]

	public resetStatus() {
		this.status = new ClickToCallEventStatus()
	}

	public addRecords(data: Array<ClickToCallRecord>) {
		let records = [...this.records, ...data]
		// records = _.sortBy(records, 'description') // Sort moved to each builder
		// If only one record, then setup to show that record without dropdown
		if (records.length === 1) {
			this.selectedOptionType = records[0].sourceType
		}
		this.records = records
		log('View Model', this)
	}

	public getRecords(sourceType: ClickToCallSourceType): Array<ClickToCallRecord> {
		return this.records.filter((rec) => rec.sourceType === sourceType)
	}

	public countForType(sourceType: ClickToCallSourceType): number {
		return this.getRecords(sourceType).length
	}

	public getTitleForType(sourceType: ClickToCallSourceType): string {
		return ClickToCallSourceTypeFormatter.getDescription(sourceType)
	}

	formatContactType(contactType: ContactType) {
		switch (contactType) {
			case 'SITE_MANAGER':
				return 'SITE MANAGER'
			default:
				return contactType
		}
	}

	// Only call after all records have been added so counts can be made
	public optionsForRecords(records: Array<ClickToCallRecord>): Array<ClickToCallMenuOption> {
		const options: Array<ClickToCallMenuOption> = []
		for (const type of this.sourceTypes) {
			const count = this.countForType(type)
			if (count > 0) {
				const option = {
					type: type,
					label: `${this.getTitleForType(type)}`,
				}
				options.push(option)
			}
		}
		return options
	}
}
