<div class="ur-cli-block">
	<div class="ur-input-row">
		<div class="float-right ur-skip-btn-wrap">
			<div class="ur-skip-btn" style="top: 0px; margin-left: 4px" (click)="toggleSkipCheckbox.next(item)">
				{{ item.skip ? 'edit' : 'n/a' }}
			</div>
		</div>
		<div class="form-check-label ur-basic-label" [class.ur-label-disabled]="item.skip">{{ item.label }}</div>
	</div>

	<div [hidden]="item.skip">
		<div *ngIf="item.style === 'MENU'" class="ur-cli-block" style="margin: 18px 0px 12px 0px">
			<select
				class="form-select"
				style="white-space: normal"
				aria-label="Default select example"
				[(ngModel)]="item.value"
				(change)="updateTextareaHeights()"
			>
				<option [ngValue]="null">Select an option</option>
				<option *ngFor="let option of item.options" [ngValue]="option.label">
					{{ option.label }}
				</option>
				<option *ngIf="item.custom.allow" [ngValue]="'CUSTOM'">Other (custom response)</option>
			</select>
		</div>

		<div *ngIf="item.style === 'LIST'" style="margin: 22px 0px">
			<div class="form-check has-pointer" *ngFor="let option of item.options; let idx = index">
				<!-- Radio button options -->
				<input
					*ngIf="!item.multi.allow"
					class="form-check-input has-pointer"
					[name]="item.tagId + '-checkbox'"
					type="radio"
					[id]="item.tagId + '-opt-' + idx"
					[value]="option.label"
					[checked]="item.value === option.label"
					(click)="item.value === option.label ? (item.value = null) : (item.value = option.label)"
				/>
				<!-- Checkbox options -->
				<input
					*ngIf="item.multi.allow"
					class="form-check-input has-pointer"
					[name]="item.tagId + '-radio'"
					type="checkbox"
					[id]="item.tagId + '-opt-' + idx"
					[(ngModel)]="option.selected"
				/>
				<label
					class="form-check-label has-pointer"
					[class.label-selected]="item.multi.allow ? option.selected : item.value === option.label"
					[for]="item.tagId + '-opt-' + idx"
				>
					{{ option.label }}
				</label>
			</div>

			<!-- Handle the custom response -->
			<div *ngIf="item.custom.allow" class="form-check has-pointer">
				<!-- Radio button options -->
				<input
					*ngIf="!item.multi.allow"
					class="form-check-input has-pointer"
					[name]="item.tagId + '-radio'"
					type="radio"
					[id]="item.tagId + '-opt-' + item.options.length"
					[value]="'CUSTOM'"
					[checked]="item.value === 'CUSTOM'"
					(click)="item.value === 'CUSTOM' ? (item.value = null) : (item.value = 'CUSTOM')"
				/>
				<!-- Checkbox options -->
				<input
					*ngIf="item.multi.allow"
					class="form-check-input has-pointer"
					[name]="item.tagId + '-checkbox'"
					type="checkbox"
					[id]="item.tagId + '-opt-' + item.options.length"
					(change)="onSetCustomResponse($event)"
				/>
				<label
					class="form-check-label has-pointer"
					[class.label-selected]="item.value === 'CUSTOM'"
					[for]="item.tagId + '-opt-' + item.options.length"
				>
					Other (custom response)
				</label>
			</div>
		</div>

		<div>
			<textarea
				[hidden]="!item.custom.allow || item.value !== 'CUSTOM'"
				#txtFieldxBasicTextArea
				rows="1"
				class="form-control ur-cli-comment"
				placeholder="Enter your response"
				[(ngModel)]="item.custom.value"
				(input)="textResizer.next(txtFieldxBasicTextArea)"
			></textarea>
		</div>

		<!-- <div style="margin-top: 25px">
			<hr />
			<div>ITEM</div>
			<code>
				<pre>{{ item | json }}</pre>
			</code>
		</div> -->
	</div>
</div>
