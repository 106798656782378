import { environment } from '@env/environment'
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core'
import { Router } from '@angular/router'

import { CoreService, DatabaseService, PrefsService, UserLoginService } from '@app/services'

import { log } from '@app/helpers'
import { Subscription } from 'rxjs'
import { DeviceDetectorService } from 'ngx-device-detector'

@Component({
    selector: 'app-admin-home',
    templateUrl: './admin-home.component.html',
    styleUrls: ['./admin-home.component.scss'],
    standalone: false
})
export class AdminHomeComponent implements OnInit, OnDestroy {
	isLoggedIn = false
	// showExceptionsInfoDialog = false // DEPRECATED 2023-08-01
	showLogoutCountdownDialog = false

	private inactiveTimer: NodeJS.Timeout
	private tenMinuteTimer: NodeJS.Timeout
	private oneHourTimer: NodeJS.Timeout
	// private companyRefreshTimer: any
	private subs = new Subscription()

	@ViewChild('pickerFix', { static: false }) pickerFix: any

	constructor(
		private router: Router,
		private coreSrvc: CoreService,
		private dbSrvc: DatabaseService,
		private loginSrvc: UserLoginService,
		private prefsSrvc: PrefsService,
		private deviceSrvc: DeviceDetectorService,
	) {
		log('Initialize AdminHomeComponent')

		// Set body background color back to white because login process may have changed it
	}

	get showBanners(): boolean {
		return this.coreSrvc.bannerSrvc.showBanners
	}

	get showFooter(): boolean {
		return this.coreSrvc.displaySrvc.showFooter
	}

	ngOnInit() {
		// Uncomment the following line to enable autologout
		this.setupAutologout()
		// this.startCompanyRefresher()

		// Start exceptions loader
		this.runTenMinuteTimer()
		this.runOneHourTimer()

		// Show App Router
		this.loginSrvc.isAuthenticated$().then((isLoggedIn) => {
			this.isLoggedIn = isLoggedIn
		})
	}

	ngOnDestroy() {
		// clearInterval(this.companyRefreshTimer)
		clearInterval(this.inactiveTimer)
		clearTimeout(this.tenMinuteTimer)
		this.dbSrvc.billSrvc.billingMessageEvent.next(null)
		this.subs.unsubscribe()
	}

	runTenMinuteTimer(): void {
		this.tenMinuteTimer = setInterval(() => {
			log('Running Ten Minute Timer')
			this.dbSrvc.bulkRead(['location_exception', 'company', 'employee', 'employee_login', 'vacation', 'users'])
		}, 600000) // 10 Minutes
	}

	runOneHourTimer(): void {
		this.oneHourTimer = setInterval(() => {
			log('Running One Hour Timer')
			this.dbSrvc.bulkRead(['google_sync_log'])
		}, 3600000) // 1 hour
	}

	setupAutologout() {
		// If user disables auto logout then skip setup
		if (!this.prefsSrvc.data.enableAutoLogout) {
			log('User disabled auto logout')
			return
		}

		// Override company.auto_logout setting by setting timer manually
		// Set the timeout to 60 sec * 60 min => 1800
		// this.prefsSrvc.setAutoLogoutTimer(1800)

		const appTimeout = this.prefsSrvc.data.autoLogoutTimer

		// Skip setting up timer if auto-logout is null. This is set
		// in the login component based on the company.auto_logout field
		if (!appTimeout) {
			log('System disabled auto logout')
			return
		}

		log('Setting up auto logout activity checker')
		const checkInterval = 30000 // 1000 ms * 30 sec => 30000
		const storedLastActive = this.loginSrvc.getLastActive()

		// Check to see if the stored last active time exists and make
		// sure user is within allowed window. If there's no last active
		// set then set it for the first time.
		if (storedLastActive) {
			log('Got stored last active date')
			const currentTime = new Date().getTime()
			if ((currentTime - storedLastActive) / 1000 > appTimeout) {
				this.loginSrvc.clearLastActive()
				this.router.navigate(['/logout'])
				return
			}
		}
		this.loginSrvc.setLastActive()

		// Setup last activity timer for the current session
		this.inactiveTimer = setInterval(() => {
			log('Checking activity timer')
			const currentTime = new Date().getTime()
			const lastActive = this.loginSrvc.getLastActive()
			if (!lastActive) {
				this.navigateToInactiveScreen()
			}
			const timeInactive = (currentTime - lastActive) / 1000
			if (timeInactive > appTimeout) {
				if (this.deviceSrvc.isDesktop()) {
					if (this.showLogoutCountdownDialog) {
						this.navigateToInactiveScreen()
					} else {
						this.showLogoutCountdownDialog = true
					}
				} else {
					this.navigateToInactiveScreen()
				}
			}
		}, checkInterval)

		// Reset activity timer on route changes
		this.subs.add(
			this.router.events.subscribe((e) => {
				this.loginSrvc.setLastActive()
			}),
		)
	}

	navigateToInactiveScreen() {
		this.showLogoutCountdownDialog = false
		setTimeout(() => {
			this.closePickers()
			setTimeout(() => {
				this.router.navigate(['/logout/inactive'])
			}, 1250)
		}, 1250)
	}

	closePickers() {
		const picker = this.pickerFix
		picker?.dialogService?.closeAll()
	}

	resetInactivityTimer() {
		this.loginSrvc.setLastActive()
		this.showLogoutCountdownDialog = false
	}

	exceptionsCount(): number {
		return this.dbSrvc.excptSrvc.transactionCount() + this.dbSrvc.excptSrvc.locationCount()
		// return 3
	}

	exceptionsWarning(): string {
		const count = this.exceptionsCount()
		if (this.exceptionsCount() === 1) {
			return count + ' new job site number'
		} else {
			return count + ' new job site numbers'
		}
	}

	displayBanner(): boolean {
		if (this.exceptionsCount() > 0) {
			return true
		} else {
			return false
		}
	}

	displayFixButton(): boolean {
		if (this.router.url !== '/admin/exceptions') {
			return true
		} else {
			return false
		}
	}
}
