import { Component, OnDestroy, OnInit } from '@angular/core'
import { log } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'
import { CrudAction, DataAccessRequest, EmployeeRecord } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { Subscription } from 'rxjs'

class BulkActionDialogManager {
	isProcessing = false
	showDialog = false
	action: 'REJECT' | 'APPROVE' = null
}

@Component({
    selector: 'app-profile-update-list',
    templateUrl: './profile-update-list.component.html',
    styleUrls: ['./profile-update-list.component.scss'],
    standalone: false
})
export class ProfileUpdateListComponent implements OnInit, OnDestroy {
	private accessHelper: AccessHelper

	public list: Array<ProfileUpdateCard> = []
	private subs = new Subscription()

	public confirmDialog = new BulkActionDialogManager()

	constructor(private coreSrvc: CoreService) {
		// this.subs.add(this.coreSrvc.dbSrvc.empSrvc.employeenListUpdated.subscribe(() => this.updateUI()))
		this.setupAccessPermissions()
		this.updateUI()
	}

	get hasSelected(): boolean {
		for (const card of this.list) {
			if (card.selected) return true
		}
		return false
	}

	ngOnInit(): void {}
	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'employee')
	}

	canPerformAction(action: CrudAction, isMyRecord: boolean): boolean {
		return this.accessHelper.canPerformAction(action, isMyRecord)
	}

	updateUI() {
		this.list = this.coreSrvc.dbSrvc.empSrvc.getProfileNeedsUpdateRecords().map((emp) => new ProfileUpdateCard(emp, this.coreSrvc.dbSrvc))
	}

	pfpApproved(card: ProfileUpdateCard) {
		const isMyRecord = this.accessHelper.isMyRecord(card.emp.id, 'employee')
		if (!this.canPerformAction(CrudAction.update, isMyRecord)) {
			this.coreSrvc.notifySrvc.notify('error', 'Not Authorized', 'You are not authorized to perform this action.', 3)
			return
		}
		card.isProcessing = true
		const updateEmp = new EmployeeRecord(card.emp)
		updateEmp.pfp_approved = true
		this.coreSrvc.dbSrvc.updateRecord('employee', updateEmp).then(() => {
			this.clearCard(card)
			this.coreSrvc.notifySrvc.notify('success', 'Approved', `Employee profile for ${card.emp.name} has been approved.`)
		})
	}

	pfpRejected(card: ProfileUpdateCard) {
		const isMyRecord = this.accessHelper.isMyRecord(card.emp.id, 'employee')
		if (!this.canPerformAction(CrudAction.update, isMyRecord)) {
			this.coreSrvc.notifySrvc.notify('error', 'Not Authorized', 'You are not authorized to perform this action.', 3)
			return
		}
		card.isProcessing = true
		const empId = card.emp.id
		const updateEmp = new EmployeeRecord(card.emp)
		updateEmp.pfp_approved = false
		updateEmp.pfp_json = null
		this.coreSrvc.dbSrvc.updateRecord('employee', updateEmp).then(() => {
			this.clearCard(card)
			this.coreSrvc.notifySrvc.notify('error', 'Rejected', `Employee profile for ${card.emp.name} has been rejected.`)
			setTimeout(() => {
				this.coreSrvc.callSrvc.startC2CEventForEmpId(
					empId,
					'TEXT',
					'Send optional message to employee with reason for rejecting profile photo.',
					'Your profile photo was rejected because it did not meet the necessary requirements.',
				)
			}, 250)
		})
	}

	clearCard(card: ProfileUpdateCard) {
		this.list = this.list.filter((rec) => rec !== card)
	}

	clearCards(ids: Array<number>) {
		this.list = this.list.filter((card) => !ids.includes(card.emp.id))
	}

	handleImageLoadError(card: ProfileUpdateCard) {
		card.hasImageError = true
	}

	public clearSelectionBtnClicked() {
		for (const card of this.list) {
			card.selected = false
		}
	}

	public selectAllBtnClicked() {
		for (const card of this.list) {
			card.selected = true
		}
	}

	public toggleCard(card: ProfileUpdateCard) {
		card.selected = !card.selected
	}

	public bulkActionBtnClicked(action: 'REJECT' | 'APPROVE') {
		const perms = this.accessHelper.getPermissionsFor('employee')
		if (!perms.access.update) {
			this.coreSrvc.notifySrvc.notify('error', 'Not Authorized', 'You are not authorized to perform this action.', 3)
			return
		}
		this.confirmDialog.action = action
		this.confirmDialog.showDialog = true
	}

	public confirmBulkAction() {
		log('confirmBulkAction')
		this.confirmDialog.isProcessing = true
		const action = this.confirmDialog.action
		const empIds = this.list.filter((card) => card.selected).map((card) => card.emp.id)
		const options = { action: action, employee_ids: empIds }
		const request = new DataAccessRequest('none', 'pfp_bulk', options)
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			this.coreSrvc.dbSrvc.readTable('employee').then((success) => {
				this.updateUI()
				this.confirmDialog.showDialog = false
				if (action === 'APPROVE') this.coreSrvc.notifySrvc.notify('success', 'Profiles Approved', 'The selected profiles have been approved.')
				if (action === 'REJECT') this.coreSrvc.notifySrvc.notify('error', 'Profiles Rejected', 'The selected profiles have been rejected.')
			})
		})
		log('Request', request)
	}
}

class ProfileUpdateCard {
	selected = false
	emp: EmployeeRecord
	empSupName: string = '< no supervisor >'
	isProcessing = false
	hasImageError = false
	placeholderImage = '/assets/img/profile-placeholder.jpg'

	constructor(emp: EmployeeRecord, dbSrvc: DatabaseService) {
		this.emp = emp
		const sup = dbSrvc.settingSrvc.getUserForId(emp.supervisor)
		if (sup) {
			this.empSupName = `${sup.first_name} ${sup.last_name}`
		}
	}
	get profileUrl(): string {
		return this.emp.getPfpImageFile()?.getPfpImageUrl() ?? this.placeholderImage
	}
}

// S: {"ts":1672008829232,"key":"1433/profiles/9e76ccff-4437-4551-9fb3-a8b1135fcdd8","type":"image/png","bucket":"assets.tts-stage.com"}
// D: {"ts":1673044438211,"key":"1433/profiles/eb1ec063-5a0b-4b87-92fe-ab65220cc0e2","type":"image/png","bucket":"assets.tts-stage.com"}
