<table width="100%">
	<tr>
		<td>
			<input #newEmail id="newEmail" class="form-control" type="text" autocapitalize="off" autocomplete="off">
		</td>
		<td>&nbsp;</td>
		<td style="text-align: right">
			<button (click)="onAddEmail(newEmail)" class="btn btn-sm btn-info">Add</button>
		</td>
	</tr>
</table>

<div *ngIf="emailList.length > 0" class="form-group">
	<br>
	<div class="btn btn-info btn-sm email-box" *ngFor="let email of emailList, let idx = index">
		{{ email }}
		<i (click)="onRemoveEmail(idx)" class="fa fa-times float-right email-box-x" aria-hidden="true"></i>
	</div>
</div>

<div *ngIf="emailList.length === 0 && requiredNotice" class="alert alert-warning" style="margin-top:15px;">
	{{ requiredNotice }}
</div>

<!-- <div class="form-group">

		<label class="form-control-label">
			<strong>Default Billing</strong>
			<a href="#" title="More Information" class="tsf-info-btn" (click)="showBillingDialog = true; false;">
				<i class="far fa-info-circle" aria-hidden="true"></i>
			</a>
		</label>

		<div *ngIf="billingEmails.length > 0" class="form-group">
			<br>
			<div class="btn btn-info btn-sm email-box" *ngFor="let email of billingEmails, let idx = index">
				{{ email }}
				<i (click)="onRemoveEmail(idx)" class="fa fa-times float-right email-box-x" aria-hidden="true"></i>
			</div>
		</div>

		<label for="newEmail" class="form-control-label">
			<strong>Add Billing Email</strong>
		</label>
		<br>
		<div class="form-group">
			<table width="100%">
				<tr>
					<td>
						<input #newEmail id="newEmail" class="form-control" type="text" autocapitalize="off" autocomplete="off">
					</td>
					<td>&nbsp;</td>
					<td style="text-align: right">
						<button (click)="onAddEmail(newEmail)" class="btn btn-sm btn-info">Add</button>
					</td>
				</tr>
			</table>
		</div>

		<div *ngIf="billingEmails.length === 0" class="alert alert-warning">
			A billing email address is required.
		</div>
		<div *ngIf="!supPhoneValid" class="alert alert-warning">
			Supervisor Mobile # is not valid for the
			<strong>{{ compForm.get('dialingCode').value }}</strong> region. Use numbers only. No dashes, parentheses, or other punctuation.
		</div>
	</div> -->