<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Event Dialog"
	[(visible)]="manager.showDialog"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div>Bulk Action Dialog</div>
</p-dialog>
<!-- <div *ngIf="manager.isActionsBlockVisible">
	<hr>
	<div style="width: 190px;display:inline-block;">
		<p-select [options]="manager.actionOptions" [(ngModel)]="manager.selectedAction" (onChange)="actionChanged()"></p-select>
	</div>
	<div *ngIf="manager.selectedAction && manager.selectedRecords.length > 0" style="display: inline-block;">
		<button class="btn btn-sm btn-success btn-pad" (click)="submitBtnClicked()">Submit</button>
		<button class="btn btn-sm btn-secondary btn-pad" (click)="resetBtnClicked()">Reset</button>
	</div>
</div> -->
