<div *ngIf="status.isLoading">
	<div class="updating-note"><i class="fas fa-loader fa-spin"></i> Loading Data...</div>
</div>

<div [hidden]="status.isLoading || status.isEditing || preview.show || preview.isPreparing" class="list-container">
	<div class="d-flex justify-content-between" style="margin-top: 10px; margin-bottom: 14px">
		<div class="filter-btn" [class.filter-btn-active]="currentType === 'CHECKIN'" (click)="toggleFilter('CHECKIN')">check-in</div>
		<div class="filter-btn" [class.filter-btn-active]="currentType === 'POST_BREAK'" (click)="toggleFilter('POST_BREAK')">post-break</div>
		<div class="filter-btn" [class.filter-btn-active]="currentType === 'CHECKOUT'" (click)="toggleFilter('CHECKOUT')">check-out</div>
		<div class="filter-btn" [class.filter-btn-active]="currentType === 'CUSTOM'" (click)="toggleFilter('CUSTOM')">custom</div>
	</div>

	<div [hidden]="preview.isPreparing" class="alert alert-info alert-bg-gray" style="margin-top: 30px; margin-bottom: 30px; line-height: 1.3rem">
		<div *ngIf="currentType !== 'CUSTOM'">
			The <span style="font-weight: 600; color: chocolate">{{ surveyTypeLabel }}</span> survey can be configured to target specific employees
			and/or jobs. It will be presented to your employees automatically when using the employee app.
		</div>
		<div *ngIf="currentType === 'CUSTOM'">
			A <span style="font-weight: 600; color: chocolate">{{ surveyTypeLabel }}</span> survey can be configured to target specific employees and/or
			jobs. It will be available to employees in the <b>Reports</b> section of the employee app.
		</div>
		<!-- <div>
			Please contact <b><a class="link-text" href="mailto:support@telephonetimesheets.com">support</a></b> if would like assistance with this feature.
		</div> -->
	</div>

	<div style="margin-bottom: 20px">
		<app-employee-checklist-search-filter
			[recordCount]="list.length"
			[searchFilter]="searchFilter"
			(applyFilter)="applySearchFilter($event)"
		></app-employee-checklist-search-filter>
		<div class="has-pointer" style="margin-top: 2px; text-align: right">
			<div class="filter-action" [class.filter-action-available]="hasExpandedCards" (click)="resetListViews()">collapse all</div>
			<div class="filter-action" style="margin: 0px 8px">|</div>
			<div class="filter-action" [class.filter-action-available]="sectionList.length > 0" (click)="startPeview()">preview survey</div>
		</div>
	</div>

	<!-- No Visible Records -->
	<div *ngIf="!hasVisibleRecords">
		<div class="alert alert-warning" style="text-align: center; font-weight: bold">No {{ surveyTypeLabel }} survey blocks</div>
	</div>

	<!-- Filtered Records (Visible) -->
	<div *ngFor="let vm of sectionList" [hidden]="preview.isPreparing">
		<div *ngIf="vm.isVisible" class="card dlist-card">
			<div>
				<div class="float-right" style="padding-top: 4px">
					<p-toggleswitch [(ngModel)]="vm.checklist.enabled" binary="true" (onChange)="toggleEnabled(vm)"></p-toggleswitch>
				</div>
				<div class="dlist-title tts-hl-2">{{ vm.checklist.title }}</div>
			</div>
			<div>
				<hr class="list-divider" />
				<div class="pvm-actions d-flex justify-content-between">
					<i
						title="Checklist"
						aria-hidden="true"
						class="fa fa-list action-icon action-icon-list has-pointer"
						[class.action-icon-on]="vm.listVisible.checkList"
						(click)="vm.toggleChecklist()"
					></i>
					<i
						title="Details"
						aria-hidden="true"
						class="fal fa-bullseye action-icon action-icon-target has-pointer"
						[class.action-icon-on]="vm.listVisible.listView"
						(click)="vm.toggleListView()"
					></i>
					<i title="Edit" (click)="editRecord(vm)" class="fa fa-pencil-square-o action-icon action-icon-edit has-pointer" aria-hidden="true"></i>
					<i title="Clone" (click)="cloneRecord(vm)" class="fa fa-files-o action-icon action-icon-clone has-pointer" aria-hidden="true"></i>
					<i title="Delete" (click)="deleteRecord(vm)" class="fa fa-trash-o action-icon action-icon-delete has-pointer"></i>
				</div>
			</div>

			<div *ngIf="vm.listVisible.listView">
				<hr class="list-divider" />

				<div class="d-flex justify-content-start" style="margin-top: 12px">
					<a
						href="#"
						class="badge badge-secondary list-link"
						[class.list-active]="vm.listVisible.empList"
						(click)="vm.toggleList('empList'); (false)"
						>Emps ({{ vm.empList.length }})</a
					>
					<a
						href="#"
						class="badge badge-secondary list-link"
						style="margin-left: 12px"
						[class.list-active]="vm.listVisible.jobList"
						(click)="vm.toggleList('jobList'); (false)"
						>Jobs ({{ vm.jobList.length }})</a
					>
				</div>

				<div *ngIf="vm.listVisible.empList" class="ecl-list-box">
					<hr class="list-divider" />
					<div class="ecl-header">{{ vm.empList.length === 0 ? 'Targeting All Employees' : 'Targeted Employees' }}</div>
					<ul>
						<li *ngFor="let empName of vm.empList">{{ empName }}</li>
					</ul>
				</div>

				<div *ngIf="vm.listVisible.siteList" class="ecl-list-box">
					<hr class="list-divider" />
					<div class="ecl-header">{{ vm.siteList.length === 0 ? 'Targeting All Job Sites' : 'Targeted Job Sites' }}</div>
					<ul>
						<li *ngFor="let siteName of vm.siteList">{{ siteName }}</li>
					</ul>
				</div>

				<div *ngIf="vm.listVisible.jobList" class="ecl-list-box">
					<hr class="list-divider" />
					<div class="ecl-header">{{ vm.jobList.length === 0 ? 'Targeting All Jobs' : 'Targeted Jobs' }}</div>
					<ul>
						<li *ngFor="let jobName of vm.jobList">{{ jobName }}</li>
					</ul>
				</div>
			</div>

			<div *ngIf="vm.listVisible.checkList" class="ecl-list-box" style="margin-top: 0px">
				<hr class="list-divider" />
				<ul>
					<li *ngFor="let item of vm.listItems">{{ vm.getChecklistFormItemLabel(item) }}</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="new-hint">
		<div *ngIf="currentType !== 'CUSTOM'">Tap <b>New</b> to create a new {{ surveyTypeLabel }} survey block</div>
		<div *ngIf="currentType === 'CUSTOM'">Tap <b>New</b> to create a new {{ surveyTypeLabel }} survey</div>
	</div>
</div>

<div *ngIf="preview.isPreparing" [hidden]="preview.show" class="list-container">
	<div class="d-flex justify-content-between" style="margin-top: 10px; margin-bottom: 30px">
		<div class="filter-btn" [class.filter-btn-active]="currentType === 'CHECKIN'" (click)="toggleFilter('CHECKIN')">check-in</div>
		<div class="filter-btn" [class.filter-btn-active]="currentType === 'POST_BREAK'" (click)="toggleFilter('POST_BREAK')">post-break</div>
		<div class="filter-btn" [class.filter-btn-active]="currentType === 'CHECKOUT'" (click)="toggleFilter('CHECKOUT')">check-out</div>
		<div class="filter-btn" [class.filter-btn-active]="currentType === 'CUSTOM'" (click)="toggleFilter('CUSTOM')">custom</div>
	</div>

	<div>
		<div *ngIf="currentType !== 'CUSTOM'" class="alert alert-info alert-bg-gray" style="margin-top: 30px; margin-bottom: 30px">
			To preview the <span style="font-weight: 600; color: chocolate">{{ surveyTypeLabel }}</span> survey, first select an employee and a job. This
			survey is dynamically created from the various survey blocks you have configured and therefore it may be different for each employee / job
			combination.
		</div>
		<div *ngIf="currentType === 'CUSTOM'" class="alert alert-info alert-bg-gray" style="margin-top: 30px; margin-bottom: 30px">
			To preview a <span style="font-weight: 600; color: chocolate">{{ surveyTypeLabel }}</span> survey, you can select targeting information to
			display the surveys available for specific employee / job combinations. You can preview disabled surveys but only enabled surveys are
			available in the employee app.
		</div>
		<div class="options-block">
			<div *ngIf="preview.empId" class="float-right link-text" (click)="preview.empId = null">clear</div>
			<div class="options-title">Target Employee</div>
			<p-select
				appScrollLockSelect
				id="employeeOptions"
				[placeholder]="'Select an Employee'"
				[options]="employeeOptions"
				[(ngModel)]="preview.empId"
				[autofocusFilter]="devDetect.isDesktop()"
				[filter]="true"
			></p-select>
		</div>
		<div class="options-block">
			<div *ngIf="preview.jobId" class="float-right link-text" (click)="preview.jobId = null">clear</div>
			<div class="options-title">Target Job</div>
			<p-select
				appScrollLockSelect
				id="jobOptions"
				[placeholder]="'Select a Job'"
				[options]="jobOptions"
				[(ngModel)]="preview.jobId"
				[autofocusFilter]="devDetect.isDesktop()"
				[filter]="true"
			></p-select>
		</div>
		<div *ngIf="currentType === 'CUSTOM'" style="margin-top: 38px">
			<div *ngFor="let vm of customPreviewList">
				<div class="card preview-card" [class.preview-disabled]="!vm.checklist.enabled">
					<div>
						<div class="float-right link-text" (click)="setupPreviewForCustom(vm)">preview</div>
						<div>
							<div class="preview-name tts-hl-gold">{{ vm.checklist.title }}</div>
							<div class="preview-status">{{ vm.checklist.enabled ? 'enabled' : 'disabled' }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			*ngIf="currentType === 'CUSTOM' && customPreviewList.length === 0"
			class="alert alert-warning"
			style="text-align: center; font-weight: bold; margin-top: 30px"
		>
			No Surveys Available
		</div>
		<div
			*ngIf="currentType !== 'CUSTOM' && sectionList.length === 0"
			class="alert alert-warning"
			style="text-align: center; font-weight: bold; margin-top: 30px"
		>
			No Survey Available
		</div>
		<button
			*ngIf="currentType !== 'CUSTOM' && sectionList.length > 0"
			class="btn btn-outline-secondary btn-block"
			style="margin-top: 30px"
			(click)="createNewPreviewReport()"
		>
			Show Preview
		</button>
	</div>
</div>

<!-- CHECKLIST EDIT COMPONENT -->
<div *ngIf="status.isEditing">
	<app-employee-checklist-edit
		[record]="selectedRecord"
		[checklistType]="currentType"
		[dialogManager]="dialogManager"
		(recordSaved)="recordSaved($event)"
		(cancelEdit)="cancelEdit()"
	>
	</app-employee-checklist-edit>
</div>

<!-- DELETE RECORD DIALOG -->
<app-delete-record-dialog
	*ngIf="deleteAction.showDeleteRecordDialog"
	[showDeleteRecordDialog]="deleteAction.showDeleteRecordDialog"
	[tableName]="deleteAction.tableName"
	[recordId]="deleteAction.recordId"
	[recordLabel]="deleteAction.recordLabel"
	(cancelDelete)="deleteAction.showDeleteRecordDialog = false"
	(actionComplete)="deleteActionComplete()"
>
</app-delete-record-dialog>

<div *ngIf="preview.show" style="margin-top: -30px">
	<app-checklist-report [reportData]="preview.reportData" [reportConfig]="preview.reportConfig"></app-checklist-report>
</div>
