import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { Helper, log, PaypalHelper } from '@app/helpers'
import { AccessPermission, AccessPermissions, PaypalCardAction } from '@app/models'
import { CoreService } from '@app/services'
import { SelectItem } from 'primeng/api'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-billing-paypal',
    templateUrl: './billing-paypal.component.html',
    styleUrl: './billing-paypal.component.scss',
    standalone: false
})
export class BillingPaypalComponent implements OnInit, AfterViewInit, OnDestroy {
	@Output() cardBlockSetup = new EventEmitter<boolean>()
	@Output() isUpdatingCard = new EventEmitter<boolean>()
	@Output() isDeletingCard = new EventEmitter<boolean>()

	permissions: AccessPermission
	accessPermissions: AccessPermissions

	expMonthDropdown: SelectItem[]
	expYearDropdown: SelectItem[]

	regexAmex = /^(?:3[47][0-9]{13})$/
	regexVisa = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/
	regexMastercard = /^(?:5[1-5][0-9]{14})$/
	regexDiscover = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/
	regexDiners = /^(?:3(?:0[0-5]|[68][0-9])[0-9]{11})$/
	regexJcb = /^(?:(?:2131|1800|35\d{3})\d{11})$/

	showCCNumber = false
	showCVC = false

	paypalDataLoaded = false

	isEditingCard = false

	billForm: UntypedFormGroup

	cardBlockAlertMessage: string = null
	cardRemovedStatusMessage: string = null

	private subs = new Subscription()

	constructor(
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
	) {
		this.setupAccessPermissions()
		this.updateCardBlockEditModeStatus()
	}

	get hasTrialExpired(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.hasTrialExpired()
	}

	get isTestingAccount(): boolean {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		if (company && company.account_status === 'TESTING') {
			return true
		}
		return false
	}

	get isPrepaid(): boolean {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		if (company && company.account_status === 'PREPAID') {
			return true
		}
		return false
	}

	get hasCardOnFile(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.hasCardOnFile()
	}

	get isCardTypeValid(): boolean {
		const cardNumber = this.billForm.get('cardNumber').value
		if (cardNumber) {
			const cardType = PaypalHelper.cardType(cardNumber)
			if (cardType) {
				return true
			}
		}
		return false
	}

	get currentCardType(): string {
		const cardNumber = this.billForm.get('cardNumber').value
		if (cardNumber) {
			return PaypalHelper.cardType(cardNumber)
		}
		return null
	}

	get cardExpirationDayCount(): number {
		const count = this.coreSrvc.dbSrvc.billSrvc.cardExpirationDayCount
		const expirationThreshold = this.coreSrvc.dbSrvc.billSrvc.cardExpirationThreshold
		if (count < expirationThreshold) {
			return count
		}
		return null
	}

	get shouldShowRetryButton(): boolean {
		const accountStatus = this.coreSrvc.dbSrvc.settingSrvc.getCompany().account_status
		const count = this.coreSrvc.dbSrvc.billSrvc.cardExpirationDayCount
		const isExpired = count ? count < 0 : false

		return (
			!isExpired &&
			(accountStatus === 'BILLING_PAYMENT_FAILED' ||
				accountStatus === 'BILLING_PORTAL_LOCKED' ||
				accountStatus === 'BILLING_ACCOUNT_LOCKED' ||
				accountStatus === 'LOCKED_SUSPENDED')
		)
	}

	ngOnInit() {
		this.expMonthDropdown = this.createExpDropdownData()
		this.expYearDropdown = this.createYearDropdownData()
		this.billForm = this.fb.group({
			cardNumber: ['', Validators.required],
			type: [''],
			expMonth: ['', Validators.required],
			expYear: ['', Validators.required],
			cvc: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
		})
	}

	ngAfterViewInit() {
		this.coreSrvc.dbSrvc.billSrvc.paypalGetCardInfo().then((result) => {
			log('PAYPAL GETINFO RESPONSE', result)
			this.paypalDataLoaded = true
			this.cardBlockSetup.next(false)

			this.subs.add(this.coreSrvc.dbSrvc.billSrvc.updateUiForBillingComponents.subscribe({ next: () => this.updateUI() }))
		})
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	setupAccessPermissions() {
		this.accessPermissions = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAccessPermissions()
		this.permissions = this.accessPermissions.getAccessPermissionsFor('billing_log')
	}

	updateUI() {
		this.updateCardBlockEditModeStatus()
	}

	private createExpDropdownData(): any[] {
		return [
			{ label: 'Select Expiration Month', value: null },
			{ label: '01 - January', value: '01' },
			{ label: '02 - February', value: '02' },
			{ label: '03 - March', value: '03' },
			{ label: '04 - April', value: '04' },
			{ label: '05 - May', value: '05' },
			{ label: '06 - June', value: '06' },
			{ label: '07 - July', value: '07' },
			{ label: '08 - August', value: '08' },
			{ label: '09 - September', value: '09' },
			{ label: '10 - October', value: '10' },
			{ label: '11 - November', value: '11' },
			{ label: '12 - December', value: '12' },
		]
	}

	private createYearDropdownData(): any[] {
		return [
			{ label: 'Select Expiration Year', value: null },
			{ label: '2024', value: '2024' },
			{ label: '2025', value: '2025' },
			{ label: '2026', value: '2026' },
			{ label: '2027', value: '2027' },
			{ label: '2028', value: '2028' },
			{ label: '2029', value: '2029' },
			{ label: '2030', value: '2030' },
			{ label: '2031', value: '2031' },
			{ label: '2032', value: '2032' },
		]
	}

	validateCardType(): boolean {
		const cardNumber = this.billForm.get('cardNumber').value
		if (cardNumber) {
			const cardType = PaypalHelper.cardType(cardNumber)
			if (cardType) {
				this.billForm.get('type').setValue(cardType)
				return true
			} else {
				this.billForm.get('type').setValue('')
			}
		} else {
			this.billForm.get('type').setValue('')
		}
		return false
	}

	validCardNumber(): boolean {
		const input = this.billForm.get('cardNumber').value
		const cardType = this.billForm.get('type').value
		const cardNumber = Helper.stripNonNumeric(input)
		if (!cardNumber || !cardType) {
			return false
		}
		if (cardType === 'Amex') {
			if (cardNumber.length < 15) {
				return false
			}
		} else {
			if (cardNumber.length < 16) {
				return false
			}
		}
		const isValid = PaypalHelper.isValidCardNumber(cardNumber)
		return isValid
	}

	formatCreditCard(event) {
		const keyCode = event.keyCode
		const number = this.billForm.get('cardNumber').value
		const input = Helper.stripNonNumeric(number)
		const cardType = this.billForm.get('type').value
		if (keyCode !== 8 && keyCode !== 32) {
			const formatted = PaypalHelper.formatCardNumber(cardType, number)
			this.billForm.controls['cardNumber'].setValue(formatted)
		}
		this.validateCardType()
		if (this.validCardNumber()) {
			this.showCCNumber = false
		}
	}

	cardOnFileCardNumberString(): string {
		const cardInfo = this.coreSrvc.dbSrvc.billSrvc.paypalCreditCardInfo
		if (!cardInfo) {
			return 'Credit Card Missing'
		}
		return '**** **** **** ' + cardInfo.last_four
	}

	cardOnFileCardExpirationString(): string {
		const cardInfo = this.coreSrvc.dbSrvc.billSrvc.paypalCreditCardInfo
		if (!cardInfo) {
			return 'Credit Card Missing'
		}
		return cardInfo.expire_month + ' / ' + cardInfo.expire_year
	}

	cardOnFileCardTypeString(): string {
		const cardInfo = this.coreSrvc.dbSrvc.billSrvc.paypalCreditCardInfo
		if (!cardInfo) {
			return 'Credit Card Missing'
		}
		const cardType = cardInfo.type
		if (cardType) {
			return cardType.charAt(0).toUpperCase() + cardType.slice(1)
		}
		return 'Unknown Card Type'
	}

	toggleEdit() {
		if (!this.permissions.update) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.isEditingCard = !this.isEditingCard
	}

	public removeCard() {
		if (!this.permissions.update) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.cardBlockAlertMessage = null
		this.coreSrvc.dbSrvc.billSrvc.updateBillingNotes.next(null)

		this.coreSrvc.dbSrvc.billSrvc.paypalResetExpirationInfo()
		if (this.hasCardOnFile) {
			this.coreSrvc.dbSrvc.settingSrvc.clearAccountStatus()
			this.isDeletingCard.next(true)
			this.coreSrvc.dbSrvc.billSrvc.paypalDeleteCardInfo().then((result) => {
				if (result.success) {
					this.coreSrvc.dbSrvc.readTable('company').then((success) => {
						this.isDeletingCard.next(false)
						this.cardRemovedStatusMessage = 'Your card has been removed'
						// Trigger billing components to update their UI
						this.coreSrvc.dbSrvc.billSrvc.updateUiForBillingComponents.next(true)
					})
				}
			})
		}
	}

	public addOrRetryCard(action: PaypalCardAction) {
		// Notify user if they do not have permission to update card on file
		if (!this.permissions.update) {
			this.notifyOperationNotAuthorized()
			return
		}

		// Clear card alert messages
		this.cardBlockAlertMessage = null
		this.cardRemovedStatusMessage = null

		// Clear billing notes
		this.coreSrvc.dbSrvc.billSrvc.updateBillingNotes.next(null)

		this.isUpdatingCard.next(true)
		const record = this.makeUpdateRecord()

		this.coreSrvc.dbSrvc.billSrvc.paypalAddOrRetryInfo(record, action).then((addResult) => {
			this.coreSrvc.dbSrvc.billSrvc.paypalGetCardInfo().then((getResult) => {
				this.coreSrvc.dbSrvc.readTable('company').then((success) => {
					log('PAYPALL ADD/UPDATE RESPONSE', addResult)
					log('PAYPAL GETINFO RESPONSE', getResult)
					const message = addResult.data.errorMessage ?? ''
					const details = addResult.data.detals
					log('PAYPAL ERROR MESSAGE:', message)
					log('PAYPAL ERROR DETAILS:', details)

					if (message) {
						if (message.includes('CC expire_month, expire_year - Invalid expiration (cannot be in the past)')) {
							this.cardBlockAlertMessage = 'It appears your card has expired. Please check the expiration date or try a different card.'
						} else if (message.includes('Credit card was refused')) {
							this.cardBlockAlertMessage =
								'Your card was refused. Please ensure your card is unlocked. You may try a different card or contact your card provider for more information.'
						} else if (message.includes('Credit card token is expired')) {
							this.cardBlockAlertMessage =
								'The PayPal token for your account is no longer valid. Please report this to Telephone Timesheets support.'
						} else {
							this.cardBlockAlertMessage =
								'Your card could not be processed. Please ensure your card is unlocked, verify all fields are correct, and resubmit or try another card. Please contact support if you continue to experience issues.'
						}
					}
					// Trigger billing components to update their UI
					this.coreSrvc.dbSrvc.billSrvc.updateUiForBillingComponents.next(true)

					this.isUpdatingCard.next(false)
				})
			})
		})
	}

	// This determines whether or not the card block is expanded based on account status information.
	private updateCardBlockEditModeStatus() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		if (!company) return
		const status = company.account_status

		switch (status) {
			case 'TESTING':
			case 'TRIAL':
			case 'BILLING_NEED_INFO':
			case 'BILLING_PAYMENT_FAILED':
			case 'BILLING_PORTAL_LOCKED':
			case 'BILLING_ACCOUNT_LOCKED':
			case 'LOCKED_SUSPENDED':
				this.isEditingCard = true
				break

			case 'BILLING_OK':
			case 'PREPAID':
			case 'INVOICE':
			case 'ADP_INVOICE':
			case 'CANCEL_PENDING':
			case 'DELETED':
				this.isEditingCard = false
				break

			default:
				this.isEditingCard = true
		}
	}

	private makeUpdateRecord(): any {
		const form = this.billForm.value
		const type = form.type
		let cardNumber = form.cardNumber
		cardNumber = cardNumber.replace(/\D/g, '')
		const record = {
			number: cardNumber,
			type: '',
			exp_month: parseInt(form.expMonth, 10),
			exp_year: parseInt(form.expYear, 10),
		}

		// Change card type sent to server Amex -> American Express
		// Must be one of Visa, MasterCard, American Express, or Discover
		// to pass server validation

		switch (type) {
			case 'Amex':
				record.type = 'American Express'
				break
			default:
				record.type = type
		}

		return record
	}

	notifyOperationNotAuthorized() {
		this.coreSrvc.notifySrvc.default('operationNotAuthorized')
	}
}
