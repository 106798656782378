import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-full-screen-loader',
    templateUrl: './full-screen-loader.component.html',
    styleUrl: './full-screen-loader.component.scss',
    standalone: false
})
export class FullScreenLoaderComponent {
	@Input() isLoading: boolean = true
	@Input() loadingMessage = 'Loading Data'
}
