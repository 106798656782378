import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
    selector: 'app-merge-transfer-phone-number-dialog',
    templateUrl: './merge-transfer-phone-number-dialog.component.html',
    styleUrls: ['./merge-transfer-phone-number-dialog.component.scss'],
    standalone: false
})
export class MergeTransferPhoneNumberDialogComponent implements OnInit {
	@Input() showDialog = false
	@Output() showDialogChange = new EventEmitter<boolean>()

	@Output() mergeBtnClicked = new EventEmitter<boolean>()
	@Output() transferBtnClicked = new EventEmitter<boolean>()

	constructor() {}

	ngOnInit(): void {}
}
