import { Component, EventEmitter, Input, Output } from '@angular/core'
import { DisplayHelper, log } from '@app/helpers'
import { ReportFieldSelectBasic, ReportFieldSelectBasicOption } from '@app/models'

@Component({
    selector: 'app-checklist-report-item-select',
    templateUrl: './checklist-report-item-select.component.html',
    styleUrls: ['./checklist-report-item-select.component.scss'],
    standalone: false
})
export class ChecklistReportItemSelectComponent {
	@Input() item: ReportFieldSelectBasic
	@Output() toggleSkipCheckbox = new EventEmitter<ReportFieldSelectBasic>()
	@Output() textResizer = new EventEmitter<HTMLTextAreaElement>()

	compareFn = (o1: ReportFieldSelectBasicOption, o2: ReportFieldSelectBasicOption) => {
		return o1 && o2 ? o1.label === o2.label : o1 === o2
	}

	public handleOptionChange(item: ReportFieldSelectBasic) {
		item.value = item.value === 'CUSTOM' ? null : item.value
	}

	public updateTextareaHeights() {
		setTimeout(() => {
			$('.ur-cli-comment').each((idx, elem) => DisplayHelper.textAreaResizer(elem as HTMLElement))
		}, 50)
	}

	public onSetCustomResponse(event) {
		log('onSetCustomResponse', event)
		if (event.target.checked) {
			this.item.value = 'CUSTOM'
		} else {
			this.item.value = null
		}
	}
}
