import { Directive, ElementRef, AfterViewInit } from '@angular/core'

@Directive({
    selector: '[appAutoFocus]',
    standalone: false
})
export class AppAutofocusDirective implements AfterViewInit {
	constructor(private el: ElementRef) {}
	ngAfterViewInit() {
		this.el.nativeElement.focus()
	}
}
