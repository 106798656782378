import { DateTimeHelper } from '@app/helpers'

export class EmployeeLoginRecord {
	id: number
	company_id: number
	employee_id: number
	phone_e164: string
	token: string

	created: string
	updated: string

	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
		}
	}
}
