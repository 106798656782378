import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ElementRef, Inject, Injectable, AfterViewInit } from '@angular/core'
import { Router } from '@angular/router'
import { DateTimeHelper, DisplayHelper, DropdownHelper, DropdownHelperConfig, log } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'
import {
	ColorVendor,
	CustomEventInput,
	CustomExtendedProps,
	DataAccessRequest,
	DialogManager,
	FCMouseEnterLeaveInfo,
	HelpDialogMessage,
	IDataAccessLambdaResult,
	NewShiftRangeSelectInfo,
	OpenShiftOfferEditConfig,
	OpenShiftOfferRecord,
	ScheduleOptions,
	ScheduleShiftViewManager,
	ScheduleViewManager,
} from '@app/models'
import { ScheduleLogRecord } from '@app/models/schedule-log'
import { CoreService } from '@app/services'
import { SelectItem } from 'primeng/api/selectitem'
import { SchedulerShiftViewEventActionsDialogManager } from './event-action-dialog-manager'
import { ShiftViewSelectedEmployeesComponent } from './shift-view-selected-employees/shift-view-selected-employees.component'
import { ShiftViewSelectedJobsComponent } from './shift-view-selected-jobs/shift-view-selected-jobs.component'

import {
	CalendarApi,
	CalendarOptions,
	DateSelectArg,
	DatesSetArg,
	DayHeaderContentArg,
	EventApi,
	EventClickArg,
	EventInput,
	FormatterInput,
	// FullCalendarElement,
	// FullCalendarComponent,
} from '@fullcalendar/core'

import { FullCalendarComponent } from '@fullcalendar/angular'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import adaptivePlugin from '@fullcalendar/adaptive'
import interactionPlugin from '@fullcalendar/interaction' // for selectable

import { DeviceDetectorService } from 'ngx-device-detector'
import { Subscription } from 'rxjs'

import moment from 'moment-timezone'
import _ from 'lodash'

// Hammer Imports
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser'

// DEPRECATED 20240726 - Not needed
// import { DIRECTION_ALL, DIRECTION_HORIZONTAL, DIRECTION_VERTICAL } from 'hammerjs'
// import { Dictionary } from '@fullcalendar/core/internal'

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
	override overrides = <any>{
		// swipe: { direction: Hammer.DIRECTION_HORIZONTAL, threshold: 50 },
	}

	override buildHammer(element: HTMLElement): any {
		const mc = new Hammer(element, { touchAction: 'pan-x', domEvents: true })
		for (const [key, value] of Object.entries(this.overrides)) {
			mc.get(key).set(this.overrides[key])
		}
		return mc
	}
}

@Component({
    selector: 'app-scheduler-shift-view',
    templateUrl: './scheduler-shift-view.component.html',
    styleUrls: ['./scheduler-shift-view.component.scss'],
    providers: [{ provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig }],
    standalone: false
})
export class SchedulerShiftViewComponent implements OnInit, AfterViewInit, OnDestroy {
	// Component instance properties
	accessHelper: AccessHelper

	manager: ScheduleViewManager
	hammer: HammerManager

	eventEditManager = new DialogManager('eventDialogManager')
	openShiftOfferDialogManager = new DialogManager('openShiftOfferDialogManager')
	eventActionManager = new SchedulerShiftViewEventActionsDialogManager()

	jobOptions: SelectItem[]
	empOptions: SelectItem[]
	shiftEventBgOptions: SelectItem[] = [
		{ label: 'Highlight by Schedule', value: 'SCHED' },
		{ label: 'Highlight by Employee', value: 'EMP' },
		{ label: 'Highlight by Job', value: 'JOB' }, // Enable when jobs are added
	]
	weekStartOptions: SelectItem[] = []
	slotSizeOptions = [
		{ label: 'Compressed View', value: '00:60:00' },
		{ label: 'Standard View', value: '00:30:00' },
		{ label: 'Extended View', value: '00:15:00' },
	]

	selectedJobId: number = null
	timeFormat: FormatterInput = DateTimeHelper.format12Hour
		? { hour: 'numeric', minute: '2-digit', meridiem: true, hour12: true }
		: { hour: '2-digit', minute: '2-digit', meridiem: false, hour12: false }

	dayHeaderFormat: FormatterInput = { weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true }

	timeOffSource: Array<EventInput> = []
	shiftSource: Array<EventInput> = [] // Full list of events for updating via search
	calendarOptions: CalendarOptions

	colorVendor = new ColorVendor()

	isGlobalAccount = false
	isInitialized = false
	isLoading = false
	isMobile = false
	blockNextLoad = false

	// vwm: ScheduleShiftViewManager

	openShiftBgColor = '#ff0000' // firebrick: '#b22222'
	openShiftOfferedBgColor = '#CFC10C'
	openShiftOfferAcceptedBgColor = 'green'

	schedLogRecordToFlash: ScheduleLogRecord // Used to flash DOM element after open shift card navigation

	// public loadData = _.debounce(this.fetchAndUpdate, 500)
	private subs = new Subscription()

	public sectionPrefsDialogManager = new DialogManager('sectionPrefsDialog')

	@Input() searchText: string
	@ViewChild('calendar') calendarComponent: FullCalendarComponent

	@ViewChild('selectedEmpsTagList') selectedEmpsTagList: ShiftViewSelectedEmployeesComponent
	@ViewChild('selectedJobsTagList') selectedJobsTagList: ShiftViewSelectedJobsComponent

	@ViewChild('calBlock', { static: false }) calBlock: ElementRef

	calendarDisplayDate = { range: '', year: '' }

	tzOptions: SelectItem[]
	tzSelectAction = { isVisible: false }
	isTimezoneLocked = false
	currentTimezone = 'UTC'
	timezoneCounter = new TimezoneCounter()

	eventInFocus: FCMouseEnterLeaveInfo = null

	isProcessingGesture = false

	constructor(
		private coreSrvc: CoreService,
		private cd: ChangeDetectorRef,
		private router: Router,
		private deviceDetector: DeviceDetectorService,
		@Inject(HAMMER_GESTURE_CONFIG) private hammerConfig: CustomHammerConfig,
	) {
		this.isMobile = this.coreSrvc.devDetect.isMobile()
		this.isGlobalAccount = this.coreSrvc.dbSrvc.settingSrvc.isGlobalAccount()
		this.manager = this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager

		log('EVENTACTION', this.eventActionManager)
		// this.vwm = new ScheduleShiftViewManager()
		this.vwm.setWindowSize(28)

		this.setupLocalPrefsDialog()
		this.setupWeekStartOptions()
		this.setupEventListeners()
		this.setupAccessPermissions()
		this.setupTimezoneDropdown()
		this.setupJobsDropdown()
		this.setupEmployeesDropdown()
		this.setupLastSelectedJob()
		this.setupLastSelectedEmployees()
		// this.setupDefaultRange() // If the service has a default, start with that
		this.setupCalendarOptions() // Call last as it will trigger loadData()

		this.currentTimezone = this.coreSrvc.dbSrvc.settingSrvc.getCompany().timezone
	}

	get vwm(): ScheduleShiftViewManager {
		return this.coreSrvc.dbSrvc.schedulerSrvc.shiftViewManager
	}

	get devDetect() {
		return this.coreSrvc.devDetect
	}

	get currentDayViewDate(): Date {
		return this.coreSrvc.dbSrvc.schedulerSrvc.currentDayViewDate
	}
	set currentDayViewDate(date: Date) {
		this.coreSrvc.dbSrvc.schedulerSrvc.currentDayViewDate = date
	}

	get currentTzAbrev(): string {
		const tzDate = this.vwm.dateArg?.end
		return moment(tzDate).tz(this.currentTimezone).zoneAbbr()
	}

	get shouldDisplaySelectionBanner(): boolean {
		return this.manager.shift.selectedEmpIds.length > 0 || this.manager.shift.selectedJobIds.length > 0 ? false : true
		// const empListCount = this.selectedEmpsTagList?.empList.length ?? 0
		// const jobListCount = this.selectedJobsTagList?.jobList.length ?? 0
		// return empListCount + jobListCount === 0
	}

	get jobSelectorLabel(): string {
		return window.innerWidth >= 500 && window.innerWidth <= 1024 ? 'Jobs' : 'Select Jobs'
	}
	get empSelectorLabel(): string {
		return window.innerWidth >= 500 && window.innerWidth <= 1024 ? 'Employees' : 'Select Employees'
	}

	// get showNoMatchingShiftsAlert(): boolean {
	// 	const selectedEmpIds = this.manager.shift.selectedEmpIds
	// 	const selectedJobIds = this.manager.shift.selectedJobIds
	// 	if (selectedEmpIds.length === 0 && selectedJobIds.length === 0) return false

	// 	const noEventsForSelectedEmps = selectedEmpIds.length > 0 && this.visibleEmpIds.length === 0
	// 	const noEventsForSelectedJobs = selectedJobIds.length > 0 && this.visibleJobIds.length === 0
	// 	return noEventsForSelectedEmps || noEventsForSelectedJobs ? true : false
	// }

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		if (this.isMobile) this.setupSwipeNavigation()
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	// Gesture Testing
	// isTouchDown = false
	// isSwipeDetected = false
	// isPanDetected = false
	// xCoord = null
	// yCoord = null
	// deltaX: number = 0
	// deltaY: number = 0
	// panDirection: string = '-'
	// swipeDirection: string = '-'

	private setupSwipeNavigation() {
		const elm = $('.fc-scrollgrid-section-header')[0]
		const hammer = this.hammerConfig.buildHammer(elm)

		hammer.get('pan').recognizeWith([]) // Disable default recognizers for pan
		hammer.get('swipe').recognizeWith([]) // Disable default recognizers for pan

		hammer.on('swipeend panend', (event) => {
			log('panend', event)
			const direction = event.deltaX > 0 ? 'RIGHT' : 'LEFT'
			const distance = Math.abs(event.deltaX)

			if (distance > 50) {
				if (direction === 'LEFT') this.handleSwipeLeft(event)
				if (direction === 'RIGHT') this.handleSwipeRight(event)
			}
		})
		this.hammer = hammer
	}

	public handleSwipeRight(event: Event) {
		this.isProcessingGesture = true
		this.manager.scheduleViewDateRangeChanged.next('PREV')
	}

	public handleSwipeLeft(event: Event) {
		this.isProcessingGesture = true
		this.manager.scheduleViewDateRangeChanged.next('NEXT')
	}

	private setupLocalPrefsDialog(): void {
		this.sectionPrefsDialogManager.headerLabel = 'Shift Preferences'
		this.sectionPrefsDialogManager.isSubmitBtnVisible = false
		this.sectionPrefsDialogManager.cancelBtnLabel = 'Close'
		// const columnVisibilityItems = LocalPrefsData.getOrgTableColumnDisplayPrefs()
		// const columnVisibilityGroup = new LocalPrefsGroup('Column Visibility', columnVisibilityItems)
		// const dialogData = new LocalPrefsDialogData([columnVisibilityGroup])
		// dialogData.keyRemovalList = ['DataTables_organizationTable']
		// this.sectionPrefsDialogManager.dialogData = dialogData
	}

	public prefsDataSaved(): void {
		log('UpdatePrefsData')
		// this.sectionPrefsDialogManager.isDialogVisible = false
		// this.orgTable.updateTable()
	}

	private getInitialDateString(): string {
		let initialDate = moment().format('YYYY-MM-DD')
		if (this.currentDayViewDate) {
			initialDate = moment(this.currentDayViewDate).format('YYYY-MM-DD')
		}

		// If the deep link redirect or something else setup a default range, use that info
		const defaultDate = this.coreSrvc.dbSrvc.schedulerSrvc.shiftViewDefaultDate?.initialDate
		if (defaultDate) {
			initialDate = defaultDate
		}

		return initialDate
	}

	public setTimezoneOptions(zones: Array<string>) {
		const tzoptions = []
		for (const zone of zones) {
			tzoptions.push({ label: zone, value: zone })
		}
		tzoptions.unshift({ label: 'Universal Time', value: 'UTC' })
		this.tzOptions = tzoptions
	}

	public setDayViewDate(date: Date) {
		log('shiftView setDayViewDate', date)
		let gotoDate = moment().format('YYYY-MM-DD')
		if (date) {
			gotoDate = moment(date).format('YYYY-MM-DD')
		}
		this.pulseCalendarBlock()
		this.calendarComponent.getApi().gotoDate(gotoDate)
		log('setDayViewDate', gotoDate)
	}

	public previousWeek() {
		this.clearCalendarEvents()
		if (!this.currentDayViewDate) {
			const currentIncrement = this.calendarOptions.dateIncrement
			log('Date Increment', currentIncrement)
			this.pulseCalendarBlock()
			this.calendarComponent.getApi().prev()
		}
	}

	public nextWeek() {
		this.clearCalendarEvents()
		if (!this.currentDayViewDate) {
			const currentIncrement = this.calendarOptions.dateIncrement
			log('Date Increment', currentIncrement)
			this.pulseCalendarBlock()
			this.calendarComponent.getApi().next()
		}
	}

	private setupWeekStartOptions() {
		this.weekStartOptions = ScheduleOptions.dayOptions.map((opt) => {
			return {
				label: 'Week starts ' + opt.name,
				value: opt.fcValue,
			}
		})
	}

	private setupEventListeners() {
		// Update header toolbar when day view is toggled from scheduler header
		this.subs.add(
			this.coreSrvc.dbSrvc.schedulerSrvc.dayViewToggleEvent.subscribe(() => {
				this.processDayViewToggleEvent()
			}),
		)

		// Reload data after a schedule edit record update is finidhed / delay also in edit component
		this.subs.add(
			this.coreSrvc.dbSrvc.schedulerSrvc.scheduleRecurDidFinishLoading.subscribe(() => {
				this.setupJobsDropdown()
				this.loadData()
			}),
		)

		// Open open shift offer edit dialog
		this.subs.add(
			this.coreSrvc.dbSrvc.schedulerSrvc.offerOpenShiftEvent.subscribe((schedLog) => {
				this.offerOpenShiftEventHandler(schedLog)
			}),
		)

		// Fetch and reload all data
		this.subs.add(
			this.coreSrvc.dbSrvc.schedulerSrvc.fetchAndReloadAllDataForShiftViewEvent.subscribe(() => {
				this.fetchAndReloadAllData()
			}),
		)
	}

	private setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'job')
	}

	private setupTimezoneDropdown() {
		const timezones = this.coreSrvc.dbSrvc.settingSrvc
			.getSupportedTimezones()
			.filter((tz) => tz.active)
			.map((tz) => {
				return { label: tz.display_name ? tz.display_name : tz.zone_name, value: tz.zone_name }
			})
		timezones.unshift({ label: 'Automatic', value: null })
		this.tzOptions = timezones
	}

	private setupJobsDropdown() {
		// const scheduleRecurs = this.coreSrvc.dbSrvc.schedulerSrvc
		// 	.getSchedules()
		// 	.filter((s) => s.approval_state !== 'PENDING')
		// 	.map((sr) => sr.job_id)
		// const jobList = this.accessHelper.getJobTableList(JobTableDisplayState.all).filter((jr) => scheduleRecurs.includes(jr.id))

		// this.jobOptions = jobList.map((rec) => ({ label: rec.description, value: rec.id }))
		// // this.jobOptions.unshift({ label: 'Select a Job', value: null })
		// log('Jobs Dropdown', this.jobOptions)
		const permission = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAccessPermissions().getUserPermissionsFor('schedule_recur')
		const config = new DropdownHelperConfig(this.coreSrvc.dbSrvc, 'MULTISELECT')
		config.isRestricted = !permission.access.read
		const dropdownHelper = new DropdownHelper(config)
		const options = dropdownHelper.buildJobMenuOptions()
		this.jobOptions = options
	}

	// BEGIN - Setup Employe Dropdown
	private setupEmployeesDropdown() {
		if (this.isGlobalAccount) {
			const request = new DataAccessRequest('global_employee')
			this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
				const records: Array<GlobalEmployee> = result.data.map((rec) => new GlobalEmployee(rec)) || []
				const filteredRecords = records.filter((ge) => ge.active)
				const processedRecords = this.processGlobalEmployees(filteredRecords)
				const options = processedRecords.map((rec) => ({
					label: `${rec.active ? '' : '[INACTIVE] - '}${rec.employee_name} (${rec.companyNames.join(', ')})`,
					value: rec.employee_id,
					data: rec,
				}))
				this.empOptions = _.orderBy(options, 'label')

				// Switch selected emp IDs to the one we're using
				this.manager.shift.selectedEmpIds = this.manager.shift.selectedEmpIds.map((id) => this.swapEmployeeId(id))
			})
		} else {
			const permission = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAccessPermissions().getUserPermissionsFor('schedule_recur')
			const config = new DropdownHelperConfig(this.coreSrvc.dbSrvc, 'MULTISELECT')
			config.includeAnyEmployee = true
			config.isRestricted = !permission.access.read
			const dropdownHelper = new DropdownHelper(config)
			const options = dropdownHelper.buildEmployeeMenuOptions()
			this.empOptions = options
		}
	}

	private swapEmployeeId(id: number): number {
		const options: Array<any> = this.empOptions
		const globalEmpOpt = options.find((opt) => opt.data.employeeIds.includes(id))
		return globalEmpOpt?.data.employee_id ?? id
	}

	private processGlobalEmployees(records: Array<GlobalEmployee>): Array<GlobalEmployee> {
		const processed: Array<GlobalEmployee> = []
		records.forEach((rec) => {
			const found = processed.find((r) => r.phone_number_e164 === rec.phone_number_e164)
			if (found) {
				found.addRecord(rec)
			} else {
				processed.push(rec)
			}
		})
		return processed
	}
	// END - Setup Employe Dropdown

	private setupLastSelectedJob() {
		const savedJobId = this.coreSrvc.prefSrvc.data.schedDefaultShiftView
		const defaultTimezone = this.coreSrvc.dbSrvc.settingSrvc.getCompany().timezone
		log('Saved JobSite ID', savedJobId)
		if (savedJobId) {
			const jobSite = this.coreSrvc.dbSrvc.jobSrvc.getJobSiteForJobId(savedJobId)
			log('Got job site', jobSite)
			if (jobSite) {
				const timezone = this.coreSrvc.dbSrvc.settingSrvc.getTimezoneZoneNameForId(jobSite.timezone_id)
				log('Job site timezone', timezone)
				this.currentTimezone = timezone === 'Unknown/Timezone' ? defaultTimezone : timezone
				this.selectedJobId = this.jobOptions.find((dd) => dd.value === savedJobId)?.value
			} else {
				this.currentTimezone = defaultTimezone
				this.selectedJobId = null
			}
		}
	}

	private setupLastSelectedEmployees() {
		log('Setup last selected employees')
	}

	private getHeaderToolbarConfig() {
		const isMobile = this.deviceDetector.isMobile()
		const isDayView = !!this.currentDayViewDate
		if (isDayView) {
			return null
		}
		return {
			left: '',
			center: 'title',
			right: '',
		}
		// return {
		// 	left: isMobile ? 'prev' : 'title',
		// 	center: isMobile ? 'title' : '',
		// 	right: isMobile ? 'next' : ''
		// }
	}

	public processDayViewToggleEvent() {
		if (this.currentDayViewDate) {
			this.calendarComponent.getApi().changeView('timeGridDay')
			this.calendarComponent.getApi().setOption('dateIncrement', { days: 1 })
		} else {
			this.calendarComponent.getApi().changeView('timeGridWeek')
			this.calendarComponent.getApi().setOption('dateIncrement', { weeks: 1 })
		}
	}

	// private setupDefaultRange() {
	// 	if (!this.calendarComponent) return // exit if not yet setup

	// 	const defaultRange = this.coreSrvc.dbSrvc.schedulerSrvc.shiftViewDefaultDate
	// 	if (defaultRange) {
	// 		const start = defaultRange.start
	// 		const end = defaultRange.end
	// 		if (start && end) {
	// 			this.calendarComponent.getApi().changeView('timeGridWeek', start)
	// 		}
	// 	}
	// }

	private setupCalendarOptions() {
		// const isMobile = this.deviceDetector.isMobile()
		const isMobile = this.coreSrvc.devDetect.isMobile()
		const headerToolbar = this.getHeaderToolbarConfig()
		const calendarOptions: CalendarOptions = {
			// schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
			// Previous keys: '0872000639-fcs-1706016977', '0264983926-fcs-1654634710', // '0875718941-fcs-1621043076',
			schedulerLicenseKey: '0239728436-fcs-1737639377',
			height: 'auto',
			allDaySlot: true,
			allDayText: 'any time',
			initialView: this.currentDayViewDate ? 'timeGridDay' : 'timeGridWeek', // isMobile ? 'timeGridDay' : this.currentDayViewDate ? 'timeGridDay' : 'timeGridWeek',
			initialDate: this.getInitialDateString(),
			// timeZone: this.getCurrentTimezoneFromSelectedJob(),
			timeZone: 'local', // this.currentTimezone,
			weekNumberCalculation: 'ISO',
			firstDay: this.manager.shift.weekStart,
			eventTimeFormat: this.timeFormat,
			// eventOrder: ['job_start'],
			headerToolbar: false, // headerToolbar,
			eventDidMount: this.handleEventDidMount.bind(this),
			eventClick: this.handleEventClick.bind(this),
			dayHeaderDidMount: this.handleDayHeaderDidMount.bind(this),
			eventsSet: this.handleEventsSet.bind(this),
			eventMouseEnter: isMobile ? null : this.handleEventMouseEnter.bind(this),
			eventMouseLeave: isMobile ? null : this.handleEventMouseLeave.bind(this),
			dayHeaderFormat: this.dayHeaderFormat,
			dateIncrement: this.currentDayViewDate ? { days: 1 } : { weeks: 1 },
			nextDayThreshold: '00:00:00',
			// slotDuration: '01:00',
			displayEventTime: false,
			slotEventOverlap: false,
			slotDuration: this.manager.shift.slotDuration,
			datesSet: this.handleDateSetEvent.bind(this),
			events: [],
			plugins: isMobile
				? [adaptivePlugin, timeGridPlugin, momentTimezonePlugin]
				: [interactionPlugin, adaptivePlugin, timeGridPlugin, momentTimezonePlugin],
			selectable: isMobile ? false : true,
			select: isMobile ? null : this.handleSelectEvent.bind(this),
			editable: false,
		}
		this.calendarOptions = calendarOptions
	}

	public changeJob() {
		const currentJobId = this.selectedJobId
		this.coreSrvc.prefSrvc.data.schedDefaultShiftView = currentJobId
		this.coreSrvc.prefSrvc.save()
		this.loadData()
	}

	public test() {
		log('Just testing')
	}

	public updateJobs() {
		this.manager.shiftViewJobListNeedsUpdate.next(true)
		this.manager.save()
		this.loadData()
	}

	public jobListChanged() {
		this.loadData()
	}

	public updateJobList() {
		this.manager.shiftViewJobListNeedsUpdate.next(true)
	}

	public updateEmployees() {
		this.manager.shiftViewEmpListNeedsUpdate.next(true)
		this.manager.save()
		this.loadData()
	}

	public empListChanged() {
		this.loadData()
	}

	public updateEmpList() {
		this.manager.shiftViewEmpListNeedsUpdate.next(true)
	}

	public jobOrEmpListChanged() {
		this.loadData()
	}

	public updateEmpAndJobLists() {
		setTimeout(() => {
			this.manager.shiftViewJobListNeedsUpdate.next(true)
			this.manager.shiftViewEmpListNeedsUpdate.next(true)
			this.manager.save()
		}, 250)
	}

	private clearCalendarEvents() {
		log('Clearing calendar events')
		this.calendarComponent.getApi().removeAllEvents()
		// const events = this.calendarComponent.nativeElement.getApi().getEvents()
		// for (const event of events) {
		// 	event.remove()
		// }
	}

	private loadData() {
		log('XXXXXXXXX - loadData - XXXXXXXXXXX')
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.isLoading = true
			const range = this.vwm.getLoaderDates()

			// Perform load only when date range provided
			// Add 10 as a default ID because if empty it returns all and no ID of 10 exists
			if (range.start && range.end) {
				// this.coreSrvc.dbSrvc.displaySrvc.startSectionLoader()
				const emptySelect = this.manager.shift.selectedJobIds.length === 0 && this.manager.shift.selectedEmpIds.length === 0 ? [10] : []
				const showOpenShifts = this.manager.shift.showOpenShifts
				const selectedJobIds = this.manager.shift.selectedJobIds.length === 0 ? emptySelect : this.manager.shift.selectedJobIds
				const selectedEmpIds = this.manager.shift.selectedEmpIds.length === 0 ? emptySelect : this.manager.shift.selectedEmpIds
				const jobOptions = { job_ids: selectedJobIds, start_date: range.start, end_date: range.end, open_shifts: showOpenShifts }
				const empOptions = { employee_ids: selectedEmpIds, start_date: range.start, end_date: range.end, open_shifts: showOpenShifts }
				const requestOptions = {
					employee_ids: selectedEmpIds,
					job_ids: selectedJobIds,
					start_date: range.start,
					end_date: range.end,
					open_shifts: showOpenShifts,
				}
				const options = this.manager.shift.option === 'JOB' ? jobOptions : empOptions
				log('Request Options', options)
				const request = new DataAccessRequest('schedule_log', null, requestOptions)
				this.coreSrvc.dbSrvc.readTable('vacation').then((vacSuccess) => {
					this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
						log('Schedule Log Fetch Result', result)
						setTimeout(() => {
							this.processResult(result)
							// Wait a bit to turn loader status off as another load might be triggered during processResult
							setTimeout(() => {
								this.isLoading = false
								// this.coreSrvc.dbSrvc.displaySrvc.stopSectionLoader()
							}, 500)
						}, 500)
					})
				})
			}
		})
	}

	get visibleEmpIds(): Array<number> {
		if (this.calendarComponent) {
			const calApi = this.calendarComponent.getApi()
			const view = calApi.view
			const start = view.activeStart
			const end = view.activeEnd
			const allEvents = calApi.getEvents()
			const visibleEvents = allEvents.filter((event) => event.start >= start && event.end <= end)
			const filteredEvents = visibleEvents.filter((e) => !(e.extendedProps as CustomExtendedProps)?.schedLog?.open_shift)

			return (filteredEvents.map((e) => (e.extendedProps as CustomExtendedProps)?.schedLog?.effectiveEmpId()) ?? []).filter(
				(id) => !!id || id === 0,
			)
		} else {
			return []
		}
	}

	get visibleJobIds(): Array<number> {
		if (this.calendarComponent) {
			const calApi = this.calendarComponent.getApi()
			const view = calApi.view
			const start = view.activeStart
			const end = view.activeEnd
			const allEvents = calApi.getEvents()
			const visibleEvents = allEvents.filter((event) => event.start >= start && event.end <= end)
			const filteredEvents = visibleEvents.filter((e) => !(e.extendedProps as CustomExtendedProps)?.schedLog?.open_shift)

			return (filteredEvents.map((e) => (e.extendedProps as CustomExtendedProps)?.schedLog?.job_id) ?? []).filter((id) => !!id || id === 0)
		} else {
			return []
		}
	}

	// Go through all available event sources for the current calendar view and apply the search filter
	public getEventListForSearchFilter(): Array<EventInput> {
		if (!this.searchText) {
			return [...this.timeOffSource, ...this.shiftSource]
		} else {
			const filteredTimeOff = this.timeOffSource.filter((e: CustomEventInput) => {
				const filterText = e.filterText || ''
				return filterText.includes(this.searchText)
			})
			const filteredShifts = this.shiftSource.filter((e: CustomEventInput) => {
				const filterText = e.filterText || ''
				return filterText.includes(this.searchText)
			})

			return [...filteredTimeOff, ...filteredShifts]
		}
	}

	public searchEvents(text: string) {
		log('Searching calendar events for', text)
		this.searchText = text ? text.toLowerCase() : null
		this.calendarOptions.events = this.getEventListForSearchFilter()
		this.refreshCalendarView()
	}

	private filterRecords(records: Array<ScheduleLogRecord>) {
		const filteredRecords: Array<ScheduleLogRecord> = []
		const jobIds = this.manager.shift.selectedJobIds
		const shouldFilterDisabled = !this.manager.shift.showDisabledShifts
		for (const rec of records) {
			if (jobIds.length > 0 && !jobIds.includes(rec.job_id)) continue
			if (shouldFilterDisabled && !(rec.enabled_override !== null ? rec.enabled_override : rec.enabled)) continue
			filteredRecords.push(rec)
		}
		return this.manager.shift.showOpenShifts ? filteredRecords : filteredRecords.filter((rec) => !rec.open_shift)
	}

	private processResult(result: IDataAccessLambdaResult) {
		log('processResult Called')
		const records = result.data as Array<ScheduleLogRecord>
		const sortedRecords = _.orderBy(records, 'job_start')
		log('First Job Start', records[0]?.job_start)
		const filteredRecords = this.filterRecords(sortedRecords)
		this.timezoneCounter.processResult(this.vwm.dateArg, filteredRecords)
		this.postFetchUpdateTimezone()

		// Map the results into shift events and store the result
		const events = filteredRecords
			.map((rec) => new ScheduleLogRecord(rec))
			// .filter((rec) => rec.schedule_id !== null)
			.map((rec) => this.transformToShiftEvent(rec))
		this.shiftSource = events
		log('Shift Events', events)

		// Using the current shift event list, get the list of time off events which use the shift events to determine coloring
		const timeOffEvents = this.getTimeOffEvents(events)
		this.timeOffSource = timeOffEvents

		// After all events have been setup, apply search filter
		this.searchEvents(this.searchText)

		// Finished processing events
		this.coreSrvc.dbSrvc.schedulerSrvc.openShiftListUpdated.next(true) // Trigger a refresh of the open shift list
		this.updateEmpAndJobLists()

		// Clear out any default range set in scheduler service
		this.coreSrvc.dbSrvc.schedulerSrvc.shiftViewDefaultDate = null

		// Clear Gesture Processing Flag
		this.isProcessingGesture = false

		this.coreSrvc.displaySrvc.stopSectionLoader()
		this.coreSrvc.dbSrvc.schedulerSrvc.dayViewProcessingComplete.next(true)

		// Pulse Date Headers
		// if (this.isMobile) this.pulseCalendarBlock()
	}

	private pulseCalendarBlock() {
		if (!this.isMobile) return
		const elms = `#calendar-block`
		this.coreSrvc.displaySrvc.fadeOutIn(elms, 'fade-out-in-fast', 1100)

		// setTimeout(() => {
		// }, 100)
	}

	private refreshCalendarView() {
		this.calendarOptions = { ...this.calendarOptions } // Triggers a refresh of the calendar - should be a better way to do this.
		this.updateEmpAndJobLists()
	}

	// Version which shows time off entries only when visibility toggle is enabled
	// private getTimeOffEvents(shiftEvents: Array<EventInput>): Array<EventInput> {
	// 	if (!this.manager.shift.showTimeOff) return []
	// 	log('Process Time Off Entries')
	// 	const tz = this.vwm.timezone
	// 	const timeOffList = this.coreSrvc.dbSrvc.vacSrvc
	// 		.getVacations()
	// 		.filter((vac) => vac.schedule_time_off && (vac.approval_state === 'DIRECT' || vac.approval_state === 'APPROVED'))
	// 	const timeOffEvents: Array<EventInput> = []
	// 	for (const rec of timeOffList) {
	// 		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(rec.employee_id)
	// 		if (emp) {
	// 			const dates = rec.getDates()
	// 			for (const date of dates) {
	// 				const hasCalEvents = this.checkShiftEventsForEmpIdOnDate(shiftEvents, emp.id, date)
	// 				const title = ` ${emp.name} (OFF)`
	// 				const timeOffEvent: EventInput = {
	// 					title: title,
	// 					start: date,
	// 					end: date,
	// 					color: hasCalEvents ? '#e71111' : 'green',
	// 					timeFormat: this.timeFormat,
	// 					timeZoneName: 'short',
	// 					allDay: true,
	// 					schedLog: null,
	// 					isTimeOffEvent: true,
	// 					filterText: `${emp.name}`.toLowerCase(),
	// 					classNames: hasCalEvents ? ['fc-event-timeoff-warn'] : ['fc-event-timeoff-good'],
	// 				}
	// 				timeOffEvents.push(timeOffEvent)
	// 			}
	// 		}
	// 	}
	// 	return timeOffEvents
	// }

	// Version which shows time off collisions regardless of time off visibility toggle
	private getTimeOffEvents(shiftEvents: Array<EventInput>): Array<EventInput> {
		// if (!this.manager.shift.showTimeOff) return []
		log('Process Time Off Entries')
		const tz = this.vwm.timezone
		const timeOffList = this.coreSrvc.dbSrvc.vacSrvc
			.getVacations()
			.filter((vac) => vac.approval_state === 'DIRECT' || vac.approval_state === 'APPROVED')
		const timeOffEvents: Array<EventInput> = []
		for (const rec of timeOffList) {
			const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(rec.employee_id)
			if (emp) {
				const dates = rec.getDates()
				for (const date of dates) {
					const hasCalEvents = this.checkShiftEventsForEmpIdOnDate(shiftEvents, emp.id, date)
					const title = ` ${emp.name} (OFF)`
					const timeOffEvent: EventInput = {
						title: title,
						start: date,
						end: date,
						color: hasCalEvents ? '#e71111' : 'green',
						timeFormat: this.timeFormat,
						timeZoneName: 'short',
						allDay: true,
						schedLog: null,
						isTimeOffEvent: true,
						hasShiftConflict: hasCalEvents, // True if this time off conflicts with a shift.
						isVisibleInShiftView: rec.schedule_time_off || hasCalEvents, // True if this time off is visible in shift view
						filterText: `${emp.name}`.toLowerCase(),
						classNames: hasCalEvents ? ['fc-event-timeoff-warn'] : ['fc-event-timeoff-good'],
					}
					timeOffEvents.push(timeOffEvent)
				}
			}
		}
		// Make a list of conflicting time off events which are displayed regardless of time off visibility
		const hasCollision = timeOffEvents.filter((toe) => toe['hasShiftConflict'])
		// Make a list of all time off events to be displayed / conflicts always included
		const visibleTimeOffEvents = timeOffEvents.filter((to) => !!to['isVisibleInShiftView'])

		// If the view flag for showing time off entries is true, show all available entries, if it's
		// false, then only show conflicts

		return this.manager.shift.showTimeOff ? visibleTimeOffEvents : hasCollision
	}

	checkShiftEventsForEmpIdOnDate(calendarEvents: Array<EventInput>, id: number, date: string): boolean {
		// Get the calendar events for a given date
		const filteredEvents = calendarEvents.filter(
			(ce: CustomExtendedProps) =>
				((ce.schedLog?.effectiveEnabled() && ce.schedLog?.job_date) || '').includes(date) &&
				ce.schedLog?.effectiveEmpId() === id &&
				!ce.schedLog?.open_shift,
		)
		const result = filteredEvents.length > 0
		// log('Result', result)
		return result
	}

	public postFetchUpdateTimezone(): boolean {
		log('postFetch - currentTImezone', this.currentTimezone)
		const timezone = this.isTimezoneLocked ? this.currentTimezone : (this.timezoneCounter.getTimezone() ?? this.currentTimezone)
		const isTimezoneUnchanged = this.currentTimezone === timezone
		this.currentTimezone = timezone
		// this.blockNextLoad = true
		this.calendarOptions.timeZone = timezone
		this.calendarComponent.getApi()?.setOption('timeZone', timezone)
		return isTimezoneUnchanged
	}

	public updateTimezone(timezone: string) {
		log('Update', timezone)
		if (this.currentTimezone !== timezone) this.isLoading = true
		this.tzSelectAction.isVisible = false
		if (timezone === null) {
			const defaultTimezone = this.coreSrvc.dbSrvc.settingSrvc.getCompany().timezone
			this.currentTimezone = defaultTimezone
			this.calendarOptions.timeZone = defaultTimezone
			this.calendarComponent.getApi().setOption('timeZone', defaultTimezone)
			this.isTimezoneLocked = false
		} else {
			this.currentTimezone = timezone
			this.calendarOptions.timeZone = timezone
			this.calendarComponent.getApi().setOption('timeZone', timezone)
			this.isTimezoneLocked = true
		}
		// Disable loading indicator if not update was needed
		setTimeout(() => {
			this.isLoading = false
		}, 1000)
		// this.loadData()
	}
	// End - Timezone Management

	public updateOpenShiftCheckboxPref() {
		this.manager.save()
		this.coreSrvc.dbSrvc.schedulerSrvc.openShiftListUpdated.next(true)
	}

	private transformToShiftEvent(schedLog: ScheduleLogRecord): EventInput {
		if (schedLog.id === 1627970) {
			log('TRANSFORM FOR SHIFT EVENT', schedLog)
		}
		const isOpenShift = schedLog.open_shift
		const hasOpenShiftBeenOffered = schedLog.schedule_os_offer.length > 0
		const osOffer = schedLog.schedule_os_offer[0]
		const doesOfferNeedApproval = !!(osOffer?.status === 'OPEN_NEEDS_APPROVAL')
		const schedRecurId = schedLog.schedule_id
		const empId = schedLog.employee_id_override !== null ? schedLog.employee_id_override : schedLog.employee_id
		const jobId = schedLog.job_id
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)
		// const effectiveEmpName = emp ? emp.name : schedLog.effectiveEmpName()
		const textForNameSlot =
			isOpenShift && doesOfferNeedApproval
				? 'ACCEPTED'
				: isOpenShift && hasOpenShiftBeenOffered
					? 'OFFERED'
					: isOpenShift
						? 'OPEN SHIFT'
						: schedLog.effectiveEmpName()

		// const textForNameSlot =
		// isOpenShift && doesOfferNeedApproval
		// 	? 'ACCEPTED'
		// 	: isOpenShift && hasOpenShiftBeenOffered
		// 	? 'OFFERED'
		// 	: isOpenShift
		// 	? 'OPEN SHIFT'
		// 	: emp
		// 	? emp.name
		// 	: schedLog.employee_name ?? ''

		let empName = textForNameSlot.trim()
		// let empName = (isOpenShift ? 'OPEN SHIFT' : emp.name ?? '').trim()

		// Setup emp count
		const empCount = empId === 0 ? (schedLog.employee_count_override !== null ? schedLog.employee_count_override : schedLog.employee_count) : null
		if (empCount) {
			empName = `${empName} / ${empCount}`
		}

		const isEnableOverriden = schedLog.enabled_override !== null
		const isEnabled = isEnableOverriden ? schedLog.enabled_override : schedLog.enabled
		const isNotifyEnabled = schedLog.enable_notifications ? true : false
		const notifyIndicator = isEnabled && !isNotifyEnabled ? ' (x)' : ''
		const multidayIndicator = job?.multi_day ? ' (m)' : ''
		// const osOfferIndicator = schedLog.schedule_os_offer.length > 0 ? ' (o)' : ''
		const isAssigned = schedLog.employee_id_override !== null
		const isEmpView = this.manager.shift.option === 'EMP'
		// const isEmpActive = emp ? emp.active : true

		const filterText = this.makeFilterText(schedLog)
		let empNameString = isAssigned ? `[ ${empName} ]` : `${empName}`
		// if (!this.isGlobalAccount && emp?.active) {
		// 	empNameString += ' - [INACTIVE]'
		// }
		const jobName = schedLog.job_description // this.coreSrvc.dbSrvc.jobSrvc.getJobById(schedLog.job_id)?.description
		const title = `${empNameString} - ${jobName}`
		// let title = `${empNameString}` // isEnableOverriden ? `*${empNameString}` : `${empNameString}`
		// if (isEmpView) {
		// const jobName = this.coreSrvc.dbSrvc.jobSrvc.getJobById(schedLog.job_id)?.description
		// if (jobName) {
		// 	title += ` - ${jobName}`
		// }
		// }

		const schedRecur = this.coreSrvc.dbSrvc.schedulerSrvc.getScheduleForId(schedRecurId)
		const recurAssignedColor =
			isOpenShift && hasOpenShiftBeenOffered && doesOfferNeedApproval
				? this.openShiftOfferAcceptedBgColor
				: isOpenShift && hasOpenShiftBeenOffered
					? this.openShiftOfferedBgColor
					: isOpenShift
						? this.openShiftBgColor
						: schedLog.schedule_id
							? schedRecur?.assigned_color || ColorVendor.getColorById(schedRecurId)
							: ColorVendor.getColorById(schedLog.id)
		const empAssignedColor =
			isOpenShift && hasOpenShiftBeenOffered && doesOfferNeedApproval
				? this.openShiftOfferAcceptedBgColor
				: isOpenShift && hasOpenShiftBeenOffered
					? this.openShiftOfferedBgColor
					: isOpenShift
						? this.openShiftBgColor
						: this.getAssignedColorForShift(schedLog, 'EMP')
		// : emp?.getAssignedColor() || '#147725'
		const jobAssignedColor =
			isOpenShift && hasOpenShiftBeenOffered && doesOfferNeedApproval
				? this.openShiftOfferAcceptedBgColor
				: isOpenShift && hasOpenShiftBeenOffered
					? this.openShiftOfferedBgColor
					: isOpenShift
						? this.openShiftBgColor
						: this.getAssignedColorForShift(schedLog, 'JOB')
		// : job?.getAssignedColor() || '#147725'
		const isColorSourceSeries = this.manager.shift.shiftEventBgColorSource === 'SCHED'
		const isColorSourceEmployee = this.manager.shift.shiftEventBgColorSource === 'EMP'
		const isColorSourceJob = this.manager.shift.shiftEventBgColorSource === 'JOB'
		const eventColor = isColorSourceSeries ? recurAssignedColor : isColorSourceJob ? jobAssignedColor : empAssignedColor

		const startTime = schedLog.job_start // schedLog.job_start_override ? schedLog.job_start_override : schedLog.job_start
		const endTime = schedLog.job_end // schedLog.job_end_override ? schedLog.job_end_override : schedLog.job_end

		const calEvent: EventInput = {
			id: `event_id_${schedLog.id}`,
			title: `${title}${notifyIndicator}${multidayIndicator}`,
			start: startTime,
			end: endTime,
			color: eventColor, // sched.enabled ? eventColor : '#bdc3c7',
			timeFormat: this.timeFormat,
			timeZoneName: 'short',
			allDay: schedLog.anytime,
			schedLog: schedLog,
			filterText: filterText,
			// classNames: ['fc-event-disabled']
		}
		if (!isNotifyEnabled) {
			calEvent.classNames = ['fc-event-notify-disabled']
		}
		if (!isEnabled) {
			calEvent.classNames = ['fc-event-disabled']
		}
		// log('Event', event)
		return calEvent
	}

	getAssignedColorForShift(schedLog: ScheduleLogRecord, source: 'EMP' | 'JOB'): string {
		const id = source === 'EMP' ? (schedLog.employee_id_override ? schedLog.employee_id_override : schedLog.employee_id) : schedLog.job_id

		// const id = source === 'EMP' ? schedLog.employee_id : schedLog.job_id
		return ColorVendor.getColorById(id)
	}

	makeFilterText(sched: ScheduleLogRecord) {
		// const isEmpView = this.manager.shift.option === 'EMP'
		const isOpenShift = sched.open_shift
		// const empId = sched.employee_id_override
		// const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)
		const effectiveEmpFirst = sched.effectiveEmpFirst()
		const effectiveEmpLast = sched.effectiveEmpLast()
		const effectEmpName = sched.effectiveEmpName()
		// const originalEmpName = emp ? emp.first + ' ' + emp.last : ''
		const empName = isOpenShift ? 'OPEN SHIFT' : effectEmpName
		const jobName = sched.job_description // this.coreSrvc.dbSrvc.jobSrvc.getJobById(sched.job_id)?.description ?? ''
		return `${effectiveEmpFirst} ${effectiveEmpLast}~${empName}~${jobName}~${sched.comments}`.toLowerCase()
	}

	// Handlers

	private handleNavLinkWeekClick(calendar: CalendarApi, weekStart: Date, jsEvent: UIEvent) {
		log('handleNavLinkWeekClick', calendar, weekStart, jsEvent)
	}

	// When the navigation header mounts, add event listeners to the column date headers
	private handleDayHeaderDidMount(info: DayHeaderContentArg) {
		const el = info['el'] as HTMLElement
		// Set the date to focus for calendar based on timezone of the job and set the day view date
		// based on that particular day as local
		const calendarDate = moment(info.date).tz(this.currentTimezone).format('YYYY-MM-DD')
		const currentDayViewDate = moment(calendarDate).toDate()
		if (el) {
			el.addEventListener('click', (event) => {
				this.coreSrvc.dbSrvc.schedulerSrvc.currentDayViewDate = currentDayViewDate
				this.coreSrvc.dbSrvc.schedulerSrvc.recalculateDayViewFilter.next(true)
				this.calendarComponent.getApi().gotoDate(calendarDate)
				this.calendarComponent.getApi().changeView('timeGridDay')
			})
		}
	}

	// MODIFIED: 2023-06-15
	private handleEventsSet(events: Array<EventApi>) {
		// Handle flashing event after open shift card clicked
		// May require updating as handleEventsSet is called multiple times during layout
		// since calendar is modified multiple times
		if (events.length > 0 && this.schedLogRecordToFlash) {
			this.coreSrvc.dbSrvc.schedulerSrvc.schedLogIdsToFlash.push(this.schedLogRecordToFlash.id)
			// const lastSelectedOpenShiftId = this.schedLogRecordToFlash.id
			// const elemId = `#event_id_${lastSelectedOpenShiftId}`
			// setTimeout(() => {
			// 	this.coreSrvc.displaySrvc.fadeOutIn(elemId, 'fade-out-in', 1500)
			// 	this.schedLogRecordToFlash = null
			// }, 800)
		}
		this.flashEventByShiftLogIds()
	}

	private flashEventByShiftLogIds() {
		setTimeout(() => {
			const ids = this.coreSrvc.dbSrvc.schedulerSrvc.schedLogIdsToFlash
			for (const id of ids) {
				const elemId = `#event_id_${id}`
				this.coreSrvc.displaySrvc.fadeOutIn(elemId, 'fade-out-in', 1500)
			}
			this.coreSrvc.dbSrvc.schedulerSrvc.schedLogIdsToFlash = []
		}, 1300)
	}

	isEventInFocus = false
	debounceClearEventInFocus = _.debounce(this.clearEventInFocus, 300)

	public clearEventInFocus() {
		// log('Clear Event')
		if (!this.isEventInFocus) {
			this.eventInFocus = null
			this.cd.markForCheck()
		}
	}

	// Handle drag to select or for new event or modification of existing event
	public handleSelectEvent(range: DateSelectArg) {
		// If in global account just return as we cannot create / edit events
		if (this.isGlobalAccount) return

		const duration = moment(range.end).diff(moment(range.start), 'seconds')
		if (duration > 86400) {
			this.coreSrvc.notifySrvc.notify('error', 'Invalid Duration', 'You cannot create a shift longer than 24 hours')
			return
		}

		const empIds = this.manager.shift.selectedEmpIds
		const empId = empIds.length === 1 ? empIds[0] : null
		const jobIds = this.manager.shift.selectedJobIds
		const jobId = jobIds.length === 1 ? jobIds[0] : null
		this.coreSrvc.dbSrvc.schedulerSrvc.newShiftRangeSelectInfo = new NewShiftRangeSelectInfo(range, empId, jobId)
		this.coreSrvc.dbSrvc.schedulerSrvc.createNewShiftEvent.next('SINGLE')
	}

	private handleEventMouseEnter(info: FCMouseEnterLeaveInfo) {
		this.eventInFocus = info
		this.isEventInFocus = true
	}

	private handleEventMouseLeave(info: FCMouseEnterLeaveInfo) {
		this.isEventInFocus = false
		this.debounceClearEventInFocus()
	}

	// When the date changes this handler is called
	private handleDateSetEvent(dateArg: DatesSetArg) {
		// Update the View Window Managed
		log('handleDateSetEvent', dateArg)
		setTimeout(() => {
			const displayDate = this.calendarComponent?.getApi().getCurrentData().viewTitle
			this.calendarDisplayDate.range = displayDate.split(',')?.[0] ?? ''
			this.calendarDisplayDate.year = displayDate.split(',')?.[1] ?? ''
			// this.calendarDisplayDate = this.calendarComponent?.getApi().getCurrentData().viewTitle
			this.vwm.updateDateInfo(dateArg, this.currentTimezone)
			this.loadData()
		})
	}

	private handleEventClick(info: EventClickArg) {
		const event = info.event
		const extProps = event?.extendedProps as CustomExtendedProps
		if (extProps.schedLog) {
			this.handleEventClickForScheduleLog(info)
		} else {
			log('Not a schedule log event')
			if (extProps.isTimeOffEvent) {
				this.coreSrvc.notifySrvc.notify('info', 'Time Off', 'Use the Time Off tab to manage time off entries.', 5)
			}
		}
	}

	private handleEventClickForScheduleLog(info: EventClickArg) {
		// log('Handle Event Click', info)

		const event = info.event
		const extProps = event?.extendedProps
		const jsEvent = info.jsEvent
		const target = jsEvent.target
		const targetClass = $(target).attr('class')
		const schedLog = (extProps as CustomExtendedProps)?.schedLog
		$(target).trigger('blur')

		if (this.isGlobalAccount) {
			log('schedLog', schedLog)
			this.returnToCompanyViewFromGlobal(schedLog)
			return
		}

		// Save the target event info
		this.eventActionManager.clickInfo = info
		this.eventActionManager.scheduleLog = schedLog
		this.eventActionManager.option = this.manager.shift.option === 'JOB' ? 'EMP' : 'JOB'

		// log('Target class', targetClass)

		// Handle Assign Employee
		if (targetClass?.includes('tts-fc-event-icon-assign')) {
			this.eventActionManager.showDialog = true
			// this.assignEmployee()
			return
		}

		// Handle Split Shift
		if (targetClass?.includes('tts-fc-event-icon-split')) {
			this.eventActionManager.showDialog = true
			// this.splitShift()
			return
		}

		// Handle Delete Shift
		if (targetClass?.includes('tts-fc-event-icon-delete')) {
			this.eventActionManager.confirmCloseShift = true
			this.eventActionManager.showDialog = true
			return
		}

		// Handle Edit Shift
		if (targetClass?.includes('tts-fc-event-icon-edit')) {
			// this.editShift()
			return
		}

		// const schedLog = extProps.schedLog as ScheduleLogRecord
		// const endTime = schedLog.job_end
		// const endMom = moment(endTime)
		// if (moment().isAfter(endMom)) {
		// 	this.coreSrvc.notifySrvc.notify('error', 'Not Allowed', 'Schedules which have completed may not be altered', 3)
		// 	return
		// }

		// If no icon was selected then show full menu
		this.eventActionManager.confirmCloseShift = false
		this.eventActionManager.showDialog = true
	}

	private handleEventDidMount(info: EventClickArg) {
		// Add a background
		// const elm = info.el as HTMLElement
		// const eventMain = elm.firstElementChild as HTMLElement
		// const eventMainFrame = eventMain.firstElementChild
		// eventMain.classList.add('fc-back-disabled')

		// Add icons to event
		// this.addIconsToEvent(info)

		// Add footer with event times
		const elm = info.el as HTMLElement
		$(elm).attr('id', info.event.id)
		const eventMain = elm.firstElementChild as HTMLElement
		const eventMainFrame = eventMain.firstElementChild
		const newDiv = document.createElement('div')
		newDiv.innerHTML = this.makeEventFooter(info)
		eventMainFrame.prepend(newDiv)
		// log('Event Main Frame', eventMainFrame)
	}

	private makeActionIcons(info: EventClickArg): string {
		const html = `
		<div class="tts-fc-event-icon-box d-flex justify-content-between">
			<i class="fa fa-user-o tts-fc-event-icon tts-fc-event-icon-assign"></i>
			<i class="fa fa-pencil-square-o tts-fc-event-icon tts-fc-event-icon-edit"></i>
			<i class="fa fa-copy tts-fc-event-icon tts-fc-event-icon-split"></i>
		</div>
		`
		// <i class="fa fa-ellipsis-v tts-fc-event-icon tts-fc-event-icon-menu"></i>
		return html
	}

	private addIconsToEvent(info: EventClickArg) {
		const elm = info.el as HTMLElement
		const eventMain = elm.firstElementChild as HTMLElement
		const eventMainFrame = eventMain.firstElementChild
		const newDiv = document.createElement('div')
		newDiv.innerHTML = this.makeActionIcons(info)
		eventMainFrame.appendChild(newDiv)
		log('Event Main Frame', eventMainFrame)
	}

	private makeEventFooter(info: EventClickArg): string {
		const extendedProps = info.event.extendedProps as CustomExtendedProps
		const schedLog = extendedProps?.schedLog as ScheduleLogRecord
		if (!schedLog) {
			return ''
		}
		if (schedLog?.anytime) {
			return ''
		}
		if (schedLog) {
			const timeFormat = DateTimeHelper.format12Hour ? 'h:mma' : 'HH:mm'
			const start = schedLog.job_start
			const startMom = moment(start).tz(this.currentTimezone)
			const end = schedLog.job_end
			const endMom = moment(end).tz(this.currentTimezone)
			const isSameDay = startMom.isSame(endMom, 'day')
			const startString = startMom.format(timeFormat + (isSameDay ? '' : ' dd'))
			const endString = endMom.format(timeFormat + (isSameDay ? '' : ' dd'))
			const html = `
			<div class="tts-fc-event-footer; overflow: hidden;">
				<div class="tts-event-time"><span style="white-space: nowrap;">${startString} -</span> <span style="white-space: nowrap;">${endString}</span></div>
			</div>
			`
			return html
		}
	}

	public fetchAndReloadAllData() {
		this.loadData()
		this.coreSrvc.dbSrvc.readTable('open_shifts')
	}

	public actionEventRecordUpdated() {
		log('activeEventRecordUpdated called')
		this.loadData()
		this.coreSrvc.dbSrvc.readTable('open_shifts')
	}

	public saveViewPrefs() {
		this.manager.save()
		this.loadData()
	}

	public toggleEmpOption() {
		log('Toggle Emp Option')
		this.manager.setShiftViewOption('EMP')
		this.loadData()
	}

	public toggleJobOption() {
		log('Toggle Job Option')
		this.manager.setShiftViewOption('JOB')
		this.loadData()
	}

	public updateShiftEventBgSource() {
		log('current source', this.manager.shift.shiftEventBgColorSource)
		this.manager.save()
		setTimeout(() => {
			this.manager.shiftViewEmpListNeedsUpdate.next(true)
			this.manager.shiftViewJobListNeedsUpdate.next(true)
			this.loadData()
		}, 100)
	}

	public updateShiftSlotDuration() {
		this.manager.save()
		const slotDuration = this.manager.shift.slotDuration
		this.calendarComponent?.getApi().setOption('slotDuration', slotDuration)
	}

	public updateWeekStart() {
		const firstDay = this.manager.shift.weekStart
		this.calendarComponent?.getApi().setOption('firstDay', firstDay)
		this.manager.save()
	}

	public openTzSelectDialog() {
		this.tzSelectAction.isVisible = true
	}

	public gotoShiftLogShiftEventHandler(schedLog: ScheduleLogRecord) {
		// If not on desktop, scroll to top of screen
		const isDesktop = this.coreSrvc.devDetect.isDesktop()
		if (!isDesktop) DisplayHelper.scrollToTopBySelector('#calendar-block')
		// if (!isDesktop) DisplayHelper.scrollToTop()

		// If the open shift is on screen flash it and return as there is no need to
		// alter the range or load anything
		const isCurrentlyVisible = this.isScheduleLogCurrentlyVisible(schedLog)
		if (isCurrentlyVisible) {
			const timeout = isDesktop ? 0 : 800
			setTimeout(() => {
				const lastSelectedOpenShiftId = schedLog.id
				const elemId = `#event_id_${lastSelectedOpenShiftId}`
				this.coreSrvc.displaySrvc.fadeOutIn(elemId, 'fade-out-in', 1500)
				this.schedLogRecordToFlash = null
			}, timeout)
			return
		}

		// Set as last selected open shift in order to flash after update
		this.schedLogRecordToFlash = schedLog

		const date = schedLog.job_date
		const jobId = schedLog.job_id
		const jobIds = this.manager.shift.selectedJobIds
		log('Goto date', date)
		if (jobIds.length > 0) {
			if (!jobIds.includes(jobId)) {
				jobIds.push(jobId)
				this.manager.shiftViewJobListNeedsUpdate.next(true)
			}
		}
		this.isLoading = true
		this.clearCalendarEvents()

		// Set the timezone
		const timezone = schedLog.timezone
		this.currentTimezone = timezone
		this.calendarOptions.timeZone = timezone
		this.isTimezoneLocked = true

		const dayViewDate = this.manager.currentDayViewDate
		if (dayViewDate) {
			this.manager.setDayViewDate(moment(date).toDate())
		}

		setTimeout(() => {
			this.calendarComponent.getApi().gotoDate(date)
		}, 750)
	}

	isScheduleLogCurrentlyVisible(schedLog: ScheduleLogRecord) {
		// If jobs are selected, is it in the job list
		const selectedJobIds = this.manager.shift.selectedJobIds
		if (selectedJobIds.length > 0) {
			const schedLogJobId = schedLog.job_id
			if (!selectedJobIds.includes(schedLogJobId)) return false
		}

		const range = this.coreSrvc.dbSrvc.schedulerSrvc.shiftViewManager.getLoaderDates()
		if (range.start && range.end) {
			const timezone = schedLog.timezone
			const jobStart = moment(schedLog.job_start)
			// log('range/timezone/jobdate/start/end', range.start, range.end, timezone, schedLog.job_date, schedLog.job_start, schedLog.job_end)

			const startMom = moment.tz(range.start, timezone).startOf('day')
			const endMom = moment.tz(range.end, timezone).subtract(1, 'd').endOf('day') // range end has an extra day to ensure it gets all schedule log entries

			return jobStart.isBetween(startMom, endMom, 'minute', '[]')
		}
		return false
	}

	offerOpenShiftEventHandler(schedLog: ScheduleLogRecord) {
		const osoId = schedLog.schedule_os_offer[0]?.id

		// If I have an osoId
		if (osoId) {
			// this.coreSrvc.dbSrvc.displaySrvc.startSectionLoader()
			const request = new DataAccessRequest('schedule_os_offer', null, { id: osoId }, null)
			this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
				log('OSO Fetch', result)
				const data: Array<OpenShiftOfferRecord> = result.data ?? []
				const openShiftRecord = data[0]
				if (openShiftRecord && openShiftRecord.status !== 'FILLED_APPROVED' && openShiftRecord.status !== 'FILLED_AUTO') {
					const config = new OpenShiftOfferEditConfig()
					config.offer = new OpenShiftOfferRecord(data[0])
					config.schedLog = schedLog
					this.openShiftOfferDialogManager.dialogData = config
					this.openShiftOfferDialogManager.headerLabel = 'Manage Offer'
					this.openShiftOfferDialogManager.isDialogVisible = true
				} else {
					this.coreSrvc.notifySrvc.notify(
						'error',
						'Status Changed',
						'This shift has already been filled. Please refresh your borwser to re-sync shift information.',
					)
				}
				// this.coreSrvc.dbSrvc.displaySrvc.stopSectionLoader()
			})
		} else {
			const config = new OpenShiftOfferEditConfig()
			config.offer = new OpenShiftOfferRecord()
			config.schedLog = schedLog
			this.openShiftOfferDialogManager.dialogData = config
			this.openShiftOfferDialogManager.headerLabel = 'Offer Shift'
			this.openShiftOfferDialogManager.isDialogVisible = true
		}
	}

	openShiftOfferRecordUpdated() {
		log('openShiftOfferRecordUpdated')
		this.fetchAndReloadAllData()
		this.openShiftOfferDialogManager.isDialogVisible = false
	}

	public clearMenu(menu: 'JOB' | 'EMP') {
		switch (menu) {
			case 'JOB':
				this.manager.shift.selectedJobIds = []
				break
			case 'EMP':
				this.manager.shift.selectedEmpIds = []
				break
		}
	}

	public returnToCompanyViewFromGlobal(schedLog: ScheduleLogRecord) {
		const companyId = schedLog.company_id
		const switchName = this.coreSrvc.dbSrvc.settingSrvc.getAllowedCompanyForId(companyId)?.name ?? 'Company Account'
		const empId = schedLog.effectiveEmpId()
		const viewDate = schedLog.job_date
		const url = `/redirect/params`
		this.coreSrvc.notifySrvc.notify('info', 'Company View', `Switching to ${switchName}`, 3)
		setTimeout(() => {
			this.coreSrvc.dbSrvc.clearServiceData()
			this.coreSrvc.zone.run(() =>
				this.router.navigate([url], { queryParams: { dlc: 'ssvfewd', cid: companyId, empId: empId, viewDate: viewDate } }),
			)
		}, 500)
	}

	public showHelp(key: string): void {
		let topic = new HelpDialogMessage(null, null)
		switch (key) {
			case 'weekStart':
				topic.header = 'Week Start'
				topic.message = 'Select which day of week the schedule view starts.'
				break
			case 'highlightStyle':
				topic.header = 'Highlight Style'
				topic.message =
					'Select the resource used to determine the highlight color for shifts. Available options are Schedule, Shift, or Employee.'
				break
			case 'displayStyle':
				topic.header = 'Display Style'
				topic.message = 'Select Compressed to condense the height of the schedule or Expanded to increase it. Standard View is the default.'
				break
			case 'timezone':
				topic.header = 'Timezone'
				topic.message =
					'Specify the timezone you want to use when viewing the schedule. Choose the Automatic option to have the system pick the timezone based on the data being displayed.'
				break
		}
		this.coreSrvc.notifySrvc.showHelp(topic)
	}
}

interface TimezoneCounterItem {
	tz: string
	count: number
}
class TimezoneCounter {
	counter: Array<TimezoneCounterItem> = []

	processResult(dateArg: DatesSetArg, records: Array<ScheduleLogRecord>) {
		this.counter = []
		const start = moment(dateArg.start)
		const end = moment(dateArg.end)

		for (const record of records) {
			const jobDateMom = moment(record.job_date)
			if (jobDateMom.isBetween(start, end, 'day', '[]')) {
				const tz = record.timezone
				const tzCount = this.counter.find((tzi) => tzi.tz === tz)
				if (tzCount) {
					tzCount.count++
				} else {
					this.counter.push({ tz: tz, count: 1 })
				}
			}
		}
		log('Timezone Counter', this.counter)
	}

	getTimezone(): string {
		const sorted = _.orderBy(this.counter, 'count', ['desc'])
		const highest = sorted[0]
		const timezone = highest?.tz ?? null
		log('XXXX getTimezone', timezone)
		return timezone
	}
}

class GlobalEmployee {
	active: boolean
	company_id: number
	company_name: string
	employee_first: string
	employee_id: number
	employee_last: string
	employee_name: string
	phone_number_e164: string
	companyNames: Array<string> = []
	employeeIds: Array<number> = []

	constructor(record?: GlobalEmployee) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.companyNames.push(record.company_name)
			this.employeeIds.push(record.employee_id)
		}
	}

	addRecord(record: GlobalEmployee) {
		this.employeeIds.push(record.employee_id)
		this.companyNames.push(record.company_name)
		this.companyNames = _.orderBy(this.companyNames)
	}
}
