import { Component, EventEmitter, Input, Output } from '@angular/core'
import { DateTimeHelper } from '@app/helpers'
import { DateRangeFilterEvent } from '@app/models'

@Component({
    selector: 'app-filter-range-picker',
    templateUrl: './filter-range-picker.component.html',
    styleUrl: './filter-range-picker.component.scss',
    standalone: false
})
export class FilterRangePickerComponent {
	@Input() isDialogVisible = false
	@Input() startDate: Date = null
	@Input() endDate: Date = null

	@Output() resetFilter = new EventEmitter<boolean>()
	@Output() cancelFilter = new EventEmitter<boolean>()
	@Output() setFilter = new EventEmitter<DateRangeFilterEvent>()

	format12Hour = DateTimeHelper.format12Hour

	public resetFilterBtnClicked() {
		this.startDate = null
		this.endDate = null
		this.resetFilter.next(true)
	}
	public setFilterBtnClicked() {
		const event = new DateRangeFilterEvent(this.startDate, this.endDate)
		this.setFilter.next(event)
	}
}
