import { HasPayRate } from '@app/models/pay-rate'
import { ReportHistoryRecord } from '@app/models/report'
import { log } from '../logger'
import { DatabaseTableName } from '@app/models/table'
import { ComponentBridgeName } from '@app/models'

export class TableActionFormatter {
	// Use of this helper requires the method notifyOperationNotAuthorized() on the bridged table

	// General Table Links

	static toggleCheckbox(bridgeTableName: string, record: any, property: string, leftPadding: string) {
		let result = ''
		const recordId = record.id
		const currentVal = record[property]
		if (currentVal) {
			result = `<span style="padding-left: ${leftPadding}; color: green;"><span hidden="true">~</span><i class="fa fa-check-square" aria-hidden="true"></i></span>`
		} else {
			result = `<span style="padding-left: ${leftPadding}; color: gray;"><i class="fa fa-square-o" aria-hidden="true"></i></span>`
		}
		return `<span class="prop-toggle" onclick="${bridgeTableName}.jsBridgeToggleProperty(${recordId},'${property}')">${result}</span>`
	}

	// General action icons
	f
	static customLink(bridgeTable: string, bridgeFunction: string, recordId: number, title: string, iconClass: string, canAccess: boolean): string {
		const parentClass = canAccess ? `class="${iconClass} act-ico act-ico-dark-hover"` : `class="far fa-link act-ico act-ico-disabled"`
		const parentClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId})"`
		const parentLink = `<i title="${title}" ${parentClick} ${parentClass}></i>`
		return parentLink
	}

	static viewLink(bridgeTable: string, bridgeFunction: string, recordId: number, canAccess: boolean): string {
		const editClass = canAccess ? `class="far fa-eye act-ico act-ico-dark-hover"` : `class="far fa-eye act-ico act-ico-disabled`
		const editClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId})"`
		const editLink = `<i title="View" ${editClick} ${editClass} aria-hidden="true"></i>`
		return editLink
	}

	static editLink(bridgeTable: string, bridgeFunction: string, recordId: number, canAccess: boolean): string {
		const editClass = canAccess ? `class="far fa-edit act-ico act-ico-clr-green act-ico-dark-hover"` : `class="far fa-edit act-ico act-ico-disabled`
		const editClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId})"`
		const editLink = `<i title="Edit" ${editClick} ${editClass} aria-hidden="true"></i>`
		return editLink
	}

	static cloneLink(bridgeTable: string, bridgeFunction: string, recordId: number, canAccess: boolean): string {
		const cloneClass = canAccess ? `class="far fa-copy act-ico act-ico-dark-hover"` : `class="far fa-copy act-ico act-ico-disabled`
		const cloneClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId})"`
		const cloneLink = `<i title="Clone" ${cloneClick} ${cloneClass} aria-hidden="true"></i>`
		return cloneLink
	}

	static deleteLink(bridgeTable: string, bridgeFunction: string, recordId: number, canAccess: boolean): string {
		const deleteClass = canAccess
			? `class="far fa-trash-alt act-ico act-ico-clr-red act-ico-dark-hover"`
			: `class="far fa-trash-alt act-ico act-ico-disabled"`
		const deleteClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId})"`
		const deleteLink = `<i title="Delete" ${deleteClick} ${deleteClass}></i>`
		return deleteLink
	}

	static repHistParentLink(bridgeTable: string, bridgeFunction: string, recordId: number, canAccess: boolean): string {
		const parentClass = canAccess ? `class="far fa-link act-ico act-ico-dark-hover"` : `class="far fa-link act-ico act-ico-disabled"`
		const parentClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId})"`
		const parentLink = `<i title="View Parent Report" ${parentClick} ${parentClass}></i>`
		return parentLink
	}

	static chainLink(bridgeTable: string, bridgeFunction: string, recordId: number, title: string, canAccess: boolean): string {
		const parentClass = canAccess ? `class="far fa-link act-ico act-ico-dark-hover"` : `class="far fa-link act-ico act-ico-disabled"`
		const parentClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId})"`
		const parentLink = `<i title="${title}" ${parentClick} ${parentClass}></i>`
		return parentLink
	}

	static gotoCompanyRecordLink(bridgeTable: string, bridgeFunction: string, recordId: number, canAccess: boolean): string {
		const gotoClass = canAccess
			? `class="far fa-arrow-up-right-from-square act-ico act-ico-dark-hover"`
			: `class="far fa-arrow-up-right-from-square act-ico act-ico-disabled"`
		const gotoClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId})"`
		const gotoLink = `<i title="Goto Record" ${gotoClick} ${gotoClass}></i>`
		return gotoLink
	}

	static clickToCallLink(bridgeTable: string, bridgeFunction: string, recordId: number, canAccess: boolean): string {
		const iconClass = canAccess ? `class="act-ico act-ico-dark-hover"` : `class="act-ico act-ico-disabled"`
		const iconOnClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId})"`
		const iconHtml = `<div title="Address Book" ${iconOnClick}  ${iconClass} style="display:inline-block;width:66px;font-size:1.2rem"><i class="far fa-phone-office"></i> / <i class="far fa-message-dots"></i></div>`
		return iconHtml
	}

	static pinReportLink(bridgeTable: string, bridgeFunction: string, recordId: number, canAccess: boolean, hasPin: boolean): string {
		const auditClass = canAccess
			? hasPin
				? `class="fas fa-thumbtack act-ico act-ico-dark-hover"`
				: `class="fas fa-thumbtack act-ico act-ico-disabled act-ico-dark-hover"`
			: `class="fas fa-thumbtack act-ico act-ico-disabled"`
		const pinClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId})"`
		const pinLink = canAccess ? `<span style="display:none">${hasPin ? '1' : '0'}</span><i title="Toggle Pin" ${pinClick} ${auditClass}></i>` : ''
		return pinLink
	}

	static viewTimeEntryLink(bridgeTable: string, bridgeFunction: string, recordId: number, canAccess: boolean, hasEntry: boolean): string {
		const auditClass = canAccess
			? hasEntry
				? `class="far fa-clock act-ico act-ico-dark-hover"`
				: `class="far fa-clock act-ico act-ico-disabled"`
			: `class="far fa-clock act-ico act-ico-disabled"`
		const pinClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId})"`
		const pinLink = canAccess ? `<i title="View Time Entry" ${pinClick} ${auditClass}></i>` : ''
		return pinLink
	}

	// static auditLink(bridgeTable: string, bridgeFunction: string, recordId: number, canAccess: boolean, hasLog: boolean): string {
	// 	const auditClass = canAccess ? `class="far fa-database act-ico act-ico-dark-hover"` : `class="far fa-databse act-ico act-ico-disabled"`
	// 	const auditClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId})"`
	// 	const auditLink = canAccess && hasLog ? `<i title="Audit History" ${auditClick} ${auditClass}></i>` : ''
	// 	return auditLink
	// }

	static auditLink(bridgeTable: string, bridgeFunction: string, recordId: number, canAccess: boolean, hasLog: boolean): string {
		const auditClass = canAccess
			? hasLog
				? `class="far fa-database act-ico act-ico-highlight act-ico-dark-hover"`
				: `class="far fa-database act-ico act-ico-dark-hover"`
			: `class="far fa-databse act-ico act-ico-disabled"`
		const auditClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId})"`
		const auditLink = canAccess ? `<i title="Audit History" ${auditClick} ${auditClass}></i>` : ''
		return auditLink
	}

	static auditLinkGlobal(resource: DatabaseTableName, recordId: number, footer: string, canAccess: boolean, hasLog: boolean): string {
		const globalBridge: ComponentBridgeName = 'ngBridgeGlobal'
		const filteredFooter = footer.replace("'", "\\'")
		const auditClass = canAccess
			? hasLog
				? `class="far fa-database act-ico act-ico-highlight act-ico-dark-hover"`
				: `class="far fa-database act-ico act-ico-dark-hover"`
			: `class="far fa-databse act-ico act-ico-disabled"`
		const auditClick = `onclick="${globalBridge}.showAuditLog('${resource}',${recordId},'${filteredFooter ?? ''}')"`
		const auditLink = canAccess ? `<i title="Audit History" ${auditClick} ${auditClass}></i>` : ''
		return auditLink
	}

	static supportLink(bridgeTable: string, bridgeFunction: string, recordId: number, marginLeft: number): string {
		const iconClass = `class="far fa-messages-question act-ico act-ico-dark-hover"`
		const iconStyle = `style="margin-left:${marginLeft}px"`
		const iconClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId})"`
		return `<i title="Contact Support" ${iconClick} ${iconClass} ${iconStyle}></i>`
	}

	static transferOwnershipLink(bridgeTable: string, bridgeFunction: string, recordId: number, canAccess: boolean): string {
		const reassignClass = canAccess ? `class="far fa-random act-ico act-ico-dark-hover"` : `class="far fa-random act-ico-disabled"`
		const reassignClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId})"`
		const reassignLink = `<i title="Transfer Ownership" ${reassignClick} ${reassignClass} aria-hidden="true"></i>`
		return reassignLink
	}

	// Time Entries Table Actions

	static transReport(bridgeTable: string, bridgeFunction: string, recordId: number, canAccess: boolean): string {
		const transReportClass = canAccess
			? `class="far fa-file-invoice act-ico act-ico-dark-hover"`
			: `class="far fa-file-invoice act-ico act-ico-disabled"`
		const transReportClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId})"`
		const transReport = canAccess ? `<i title="Transaction Report" ${transReportClick} ${transReportClass}></i>` : ''
		return transReport
	}

	// Employee Table Links

	static empPrefsLink(bridgeTable: string, bridgeFunction: string, recordId: number, canAccess: boolean, hasPrefs: boolean): string {
		const prefsClass =
			canAccess && hasPrefs
				? `class="far fa-id-badge act-ico act-ico-highlight act-ico-dark-hover"`
				: canAccess
					? `class="far fa-id-badge act-ico act-ico-dark-hover"`
					: `class="far fa-id-badge act-ico act-ico-disabled"`
		const prefsClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId})"`
		// const prefsStyle = canAccess ? hasPrefs ? `style="color: chocolate;"` : `style="color: #0275d8;"` : ``
		const prefsLink = `<i title="Configure Employee Application" ${prefsClick} ${prefsClass}></i>`
		return prefsLink
	}

	static empAppViewLink(bridgeTable: string, bridgeFunction: string, recordId: number, canAccess: boolean, hasLogin: boolean): string {
		const debugClass =
			canAccess && hasLogin ? `class="fas fa-sign-in act-ico act-ico-dark-hover"` : `class="fas fa-sign-in act-ico act-ico-disabled"`
		const debugClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId})"`
		const debugLink = `<i title="Emp App View" ${debugClick} ${debugClass}></i>`
		return debugLink
	}

	// User Table Links

	static userPrefsLink(bridgeTable: string, bridgeFunction: string, recordId: number, canAccess: boolean, hasPrefs: boolean): string {
		const prefsClass =
			canAccess && hasPrefs
				? `class="far fa-cog act-ico act-ico-highlight act-ico-dark-hover"`
				: canAccess
					? `class="far fa-cog act-ico act-ico-dark-hover"`
					: `class="far fa-cog act-ico-disabled"`
		const prefsClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId})"`
		// const prefsStyle = canAccess ? hasPrefs ? `style="color: chocolate;"` : `style="color: #0275d8;"` : ``
		const prefsLink = `<i title="Configure Portal Preferences" ${prefsClick} ${prefsClass}></i>`
		return prefsLink
	}

	static userPermsLink(
		bridgeTable: string,
		bridgeFunction: string,
		recordId: number,
		canAccess: boolean,
		hasPerms: boolean,
		hasRoleAssigned: boolean,
	): string {
		const prefsClass =
			canAccess && hasRoleAssigned
				? `class="far fa-lock act-ico act-ico-clr-dark-magenta act-ico-dark-hover"`
				: canAccess && hasPerms
					? `class="far fa-lock act-ico act-ico-highlight act-ico-dark-hover"`
					: canAccess
						? `class="far fa-lock act-ico act-ico-dark-hover"`
						: `class="far fa-lock act-ico act-ico-disabled"`
		const prefsClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId})"`
		// const prefsStyle = canAccess ? (hasPerms ? `style="color: chocolate;"` : `style="color: #0275d8;"`) : ``
		const prefsLink = `<i title="Configure User Permissions" ${prefsClick} ${prefsClass}></i>`
		return prefsLink
	}

	// Reports and Report History Links

	static runReportLink(bridgeTable: string, bridgeFunction: string, recordId: number, canAccess: boolean): string {
		const runClass = canAccess ? `class="far fa-play-circle act-ico act-ico-dark-hover"` : `class="far fa-play-circle act-ico act-ico-disabled"`
		const runClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId}, true)"`
		const runLink = `<i title="Run report" ${runClick} ${runClass}></i>`
		return runLink
	}

	static runCurrentReportLink(bridgeTable: string, bridgeFunction: string, recordId: number, canAccess: boolean): string {
		const runClass = canAccess ? `class="far fa-forward act-ico act-ico-dark-hover"` : `class="far fa-forward act-ico-disabled"`
		const runClick = `onclick="${bridgeTable}.${bridgeFunction}(${recordId}, false)"`
		const runLink = `<i title="Run current report period to-date" ${runClick} ${runClass}></i>`
		return runLink
	}

	static viewOrDownloadReport(
		bridgeTable: string,
		bridgeFunction: string,
		repHist: ViewOrDownloadInfo,
		canAccess: boolean,
		source: 'REPORTS' | 'REPORTSLOG',
	): string {
		if (!repHist) return null
		const isEmpty = repHist.empty
		const extension = repHist.format.toLowerCase()
		const isHtmlFormat = extension === 'html'
		let iconType = isHtmlFormat ? `far fa-eye act-ico act-ico-clr-green act-ico-dark-hover` : `far fa-download act-ico act-ico-clr-green`
		if (repHist.error) {
			iconType += ' act-ico-rep-error'
		}
		const repLogModifier = source === 'REPORTS' ? ' Last Run ' : ' '
		const iconTitle = isHtmlFormat ? `View${repLogModifier}Report` : `Download${repLogModifier}Report`
		const viewClass = canAccess && !isEmpty ? `class="${iconType}"` : `class="${iconType} act-ico-disabled"`
		const viewLink = `onclick="${bridgeTable}.${bridgeFunction}(${repHist.id}, true)"`
		const runLink = `<i title="${iconTitle}" ${viewLink} ${viewClass}></i>`
		return runLink
	}

	static checkmarkLink(hasCheck: boolean, showBan: boolean = false, iconClass: string, leftMargin: number, tooltip: string) {
		const marginStyle = leftMargin ? `margin-left: ${leftMargin}px;` : ''
		const title = tooltip ? `title="${tooltip}"` : ''
		return hasCheck
			? `<div style="display: none;">${hasCheck ? '1' : '0'}</div><div ${title} class="${iconClass ?? ''}"><i class="fas fa-check" style="color: green; ${marginStyle}px;"></i></div>`
			: showBan
				? `<div style="display: none;">${hasCheck ? '1' : '0'}</div><div  ${title}class="${iconClass ?? ''}"><i class="far fa-ban" style="color: firebrick; ${marginStyle}px;"></i></div>`
				: ''
	}
}

export interface ViewOrDownloadInfo {
	id: number
	empty: boolean
	error?: string
	format: string
}
