<div class="public-container">
	<div class="logo-wrap">
		<img src="/assets/img/tts-logo.png" style="width: 100%" />
	</div>

	<div class="logout-icon">
		<i class="far fa-right-from-bracket"></i>
	</div>

	<div class="msg-box">
		<div>You have been logged out</div>
		<div *ngIf="isInactiveLogout">due to inactivity</div>

		<div *ngIf="!adpSession" style="margin-top: 15px; text-align: center">
			<a [routerLink]="['/home/login']">Click here to login</a>
		</div>
		<div *ngIf="adpSession" style="margin-top: 15px; text-align: center; color: green">
			<i class="fas fa-loader fa-spin"></i> Redirecting to ADP...
		</div>
	</div>
</div>
