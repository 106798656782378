<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<form class="edit-form-block" novalidate [formGroup]="reqForm" (keydown.enter)="$event.preventDefault()" autocomplete="off">
		<div class="options-block">
			<input class="form-control req-emp-count-input" formControlName="employee_count" />
			<div class="options-title-inline">Employee Count</div>
		</div>

		<hr />

		<div class="row">
			<div class="col-6">
				<div>
					<div class="options-title">Shift Start</div>
					<app-date-time-picker
						[currentDate]="reqForm.get('start_time').value"
						[pickerType]="'TIME'"
						[pickerMode]="'dialog'"
						[pickerLabel]="'Shift Start'"
						[hour12Timer]="is12Hour"
						[format]="is12Hour ? 'h:mma' : 'HH:mm:ss'"
						(pickerDidClose)="reqForm.get('start_time').setValue($event)"
					>
					</app-date-time-picker>
				</div>
			</div>
			<div class="col-6">
				<div>
					<div class="options-title">Shift End</div>
					<app-date-time-picker
						[currentDate]="reqForm.get('end_time').value"
						[pickerType]="'TIME'"
						[pickerMode]="'dialog'"
						[pickerLabel]="'Shift End'"
						[hour12Timer]="is12Hour"
						[format]="is12Hour ? 'h:mma' : 'HH:mm:ss'"
						(pickerDidClose)="reqForm.get('end_time').setValue($event)"
					>
					</app-date-time-picker>
				</div>
			</div>
		</div>

		<hr />

		<div class="options-block">
			<div class="options-title">Schedule Change Starts</div>
			<app-date-time-picker
				[currentDate]="reqForm.get('change_start_date').value"
				[pickerType]="'DATE'"
				[pickerMode]="'dialog'"
				[pickerLabel]="'Schedule Change Starts'"
				[format]="'MMM Do, YYYY'"
				(pickerDidClose)="reqForm.get('change_start_date').setValue($event)"
			>
			</app-date-time-picker>
		</div>

		<div class="options-block">
			<a class="clear-input" href="#" (click)="reqForm.get('change_end_date').setValue(null); (false)">clear</a>
			<div class="options-title">Schedule Change Ends</div>
			<app-date-time-picker
				[currentDate]="reqForm.get('change_end_date').value"
				[startAt]="reqForm.get('change_end_date').value ? null : reqForm.get('change_start_date').value"
				[pickerType]="'DATE'"
				[pickerMode]="'dialog'"
				[pickerLabel]="'Schedule Change Ends'"
				[format]="'MMM Do, YYYY'"
				(pickerDidClose)="reqForm.get('change_end_date').setValue($event)"
			>
			</app-date-time-picker>
		</div>

		<div class="alert alert-danger req-form-alert" *ngIf="!areChangeDatesValid()">End date is before start date</div>

		<hr />

		<div>
			<div class="options-title">Notes</div>
			<textarea class="form-control req-notes" formControlName="notes"></textarea>
		</div>

		<hr />

		<div class="options-block">
			<p-select
				appScrollLockSelect
				id="requestStatus"
				class="req-status-dropdown"
				[options]="requestStatusOptions"
				formControlName="request_status"
				[filter]="false"
			></p-select>
			<div class="options-title-inline">Request Status</div>
		</div>

		<div class="alert alert-warning req-form-alert">{{ changeDurationNote() }}</div>
	</form>

	<div style="margin: 20px 0px">
		<button class="btn btn-sm btn-outline-secondary" (click)="showInputData = !showInputData">
			{{ showInputData ? 'Hide Debug Data' : 'Show Debug Data' }}
		</button>
	</div>

	<div *ngIf="showInputData">
		<div>
			<pre><code>{{ schedInfo | json }}</code></pre>
		</div>

		<br /><br />

		<div>
			<pre><code>{{ changeRequest | json }}</code></pre>
		</div>
	</div>
</div>
