import { Component, EventEmitter, Input, Output } from '@angular/core'
import { log } from '@app/helpers'
import { CompanyRecord, TransDateLockManager } from '@app/models'
import { CoreService } from '@app/services'

import moment from 'moment-timezone'

@Component({
    selector: 'app-trans-date-lock-dialog',
    templateUrl: './trans-date-lock-dialog.component.html',
    styleUrls: ['./trans-date-lock-dialog.component.scss'],
    standalone: false
})
export class TransDateLockDialogComponent {
	@Input() manager: TransDateLockManager

	date: Date = null

	@Output() dateLockUpdated = new EventEmitter<boolean>()

	constructor(private coreSrvc: CoreService) {}

	public configureData() {
		const date = this.coreSrvc.dbSrvc.settingSrvc.getCompany().trans_lock_date
		this.date = date ? moment(date, 'YYYY-MM-DD').toDate() : null
	}

	public updateDateLock() {
		const date = this.date
		const dateStr = date ? moment(date).format('YYYY-MM-DD') : null
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const updateRecord = new CompanyRecord(company)
		updateRecord.trans_lock_date = dateStr
		this.coreSrvc.dbSrvc.updateRecord('company', updateRecord).then((result) => {
			const updatedLockDate = this.coreSrvc.dbSrvc.settingSrvc.getCompany().trans_lock_date
			this.manager.date = updatedLockDate ? moment(updatedLockDate).toDate() : null
			this.manager.isDialogVisible = false
			this.dateLockUpdated.next(true)
		})
	}

	public cancelDateLock() {
		this.manager.isDialogVisible = false
	}
}
