import { DatabaseService } from '@app/services'
import { v4 as uuid } from 'uuid'
import { environment } from '@env/environment'
import { log } from '@app/helpers'

export class FileDropperManager {
	constructor() {}

	getS3UploadParams = (fdInfo: FileDropperInfo) => {
		alert('You must define the getS3UploadParams function - see example in file-dropper.ts')
		return null
	}
}

export class FileDropperInfo {
	uuid: string
	file: File

	success = false

	constructor(file: File) {
		this.file = file
		this.uuid = uuid()
	}

	getFileParts(): { name: string; ext: string } {
		const fileName = this.file?.name ?? ''
		const nameComps = fileName.split('.')
		if (nameComps.length > 1) {
			const ext = nameComps.pop() || ''
			return {
				name: nameComps.join('.'),
				ext: ext.toLowerCase(),
			}
		} else {
			return {
				name: fileName,
				ext: '',
			}
		}
	}
}

// Example getS3UploadParams
// getS3UploadParams(file: File): any {
// 	const companyId = this.dbSrvc.settingSrvc.getCompany().id
// 	const userId = this.dbSrvc.settingSrvc.getMyActualUser().id
// 	const uploadFile = new FileDropperInfo(file)
// 	const bucketKey = uploadFile.uuid

// 	const params: any = {
// 		Metadata: {
// 			company_id: `${companyId}`,
// 			user_id: `${userId}`,
// 			file_type: file.type,
// 		},
// 		Bucket: environment.secureUploadBucket,
// 		Key: bucketKey,
// 		ContentType: file.type,
// 		Body: file,
// 	}

// 	log('Upload Parameters', params)
// 	return params
// }
