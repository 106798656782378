import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core'
import { AdminPrefs, AdminPrefsConfig, DialogControlEvent, IAdminPrefsConfigItem, HelpDialogMessage, IPrefsCategory } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { log } from '@app/helpers'
import { Subscription } from 'rxjs'

import _ from 'lodash'

@Component({
    selector: 'app-admin-prefs',
    templateUrl: './admin-prefs.component.html',
    styleUrls: ['./prefs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AdminPrefsComponent implements OnInit, OnDestroy {
	@Input() userId: number
	@Input() group: string // 'company' | 'user'
	@Output() saveComplete = new EventEmitter<boolean>()

	isUpdating = false
	isInternalUser = false

	prefsConfig = new AdminPrefsConfig()
	companyPrefs: AdminPrefs
	prefsCategories = AdminPrefsConfig.getCategories()
	selectedCategory = 'all'

	hiddenOptions = []

	searchFilterText = '' // Debounced in search filter component

	private subs = new Subscription()

	constructor(
		private coreSrvc: CoreService,
		private cd: ChangeDetectorRef,
	) {
		// log('Users', this.coreSrvc.dbSrvc.settingSrvc.getUsers())
		this.subs.add(this.coreSrvc.eventSrvc.dialogControlEvents.subscribe((event) => this.processControlEvents(event)))
		this.enableSaveBtn()

		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()
	}

	get hasAvailableOptions(): boolean {
		if (this.group === 'company') {
			return true
		}
		const category = this.selectedCategory
		if (category === 'all') {
			return true
		}
		const currentPrefs = this.prefsConfig.prefs.filter((pref) => pref.category === category && pref.userOverride === true)
		if (currentPrefs.length > 0) {
			return true
		}
		return false
	}

	processControlEvents(event: DialogControlEvent) {
		const callerId = event.callerId
		const direction = event.direction
		if (callerId === 'adminPrefs' && direction === 'toContent') {
			const eventName = event.eventName
			if (eventName === 'saveBtnClicked') {
				this.savePreferencesBtnClicked()
			}
		}
	}

	enableSaveBtn() {
		const event = new DialogControlEvent('prefsEdit', 'toDialog', 'enableSaveBtn', null)
		this.coreSrvc.eventSrvc.dialogNotification(event)
	}

	ngOnInit() {
		// log('action', this.group)
		this.companyPrefs = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany()
		if (this.group === 'company') {
			this.setupCompanyPrefs()
		} else {
			this.setupUserPrefs()
		}
		this.setupInternalPrefs()
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	setupCompanyPrefs() {
		// log('Setting company prefs')
		const compPrefs: AdminPrefs = this.coreSrvc.dbSrvc.settingSrvc.getCompany().getCompanyAdminPrefs()
		const adminPrefs = new AdminPrefs(compPrefs)
		this.prefsConfig.setPrefs(adminPrefs)
	}

	setupUserPrefs() {
		const userId = this.userId
		// log('Setting up user prefs', userId)
		const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(userId)
		if (user) {
			const userPrefs: AdminPrefs = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForUserId(userId)
			// log('User Prefs', userPrefs)
			const adminPrefs = new AdminPrefs(userPrefs)
			// log('Admin Prefs', adminPrefs)
			this.prefsConfig.setPrefs(adminPrefs)
		}
	}

	setupInternalPrefs() {
		const isInternal = this.coreSrvc.dbSrvc.settingSrvc.getMyUser()?.role === 'INTERNAL'
		if (isInternal) {
			this.prefsCategories.push({ value: 'internalNotifications', name: 'Notifications (Internal)', note: null, isExpanded: true })
			this.prefsCategories.push({ value: 'internalOption', name: 'Miscellaneous (Internal)', note: null, isExpanded: true })
		}
	}

	public getPrefsForCategory(category: IPrefsCategory) {
		return this.prefsConfig.prefs.filter((pi) => pi.category.includes(category.value))
	}

	validatePrefs(): boolean {
		const prefs = this.prefsConfig.prefs
		for (const pref of prefs) {
			const validator = pref.validator
			if (validator) {
				const type = validator.type
				if (type === 'INTEGER') {
					let value = parseInt(pref.value, 10) || null
					if (value || validator.required) {
						const min = validator.min
						const max = validator.max
						if ((min && value < min) || !value) {
							value = min
						}
						if (max && value > max) {
							value = max
						}
					}
					pref.value = value
				}
				if (type === 'FLOAT') {
					pref.value = parseFloat(pref.value) || null
				}
			}
		}
		return true
	}

	savePreferencesBtnClicked() {
		this.validatePrefs()
		log('adminPrefs', this.prefsConfig)
		// return
		if (this.group === 'company') {
			this.updateCompanyPreferences()
		} else {
			this.updateUserPreferences()
		}
	}

	resetPreferencesBtnClicked() {
		if (this.group === 'company') {
			const adminPrefs = new AdminPrefs()
			this.prefsConfig.setPrefs(adminPrefs)
		} else {
			this.updateUserPreferences(true)
		}
	}

	updateCompanyPreferences() {
		// Guard against double submission
		if (this.isUpdating) {
			return
		}

		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const updateRecord = company.getUpdateRecord()
		const prefsString = this.prefsConfig.getCompanyUpdateString()
		updateRecord.admin_prefs_json = prefsString
		this.isUpdating = true
		this.coreSrvc.dbSrvc.updateRecord('company', updateRecord).then((updateResult) => {
			this.coreSrvc.dbSrvc.settingSrvc.setUserAdminPrefs()
			this.saveComplete.emit(true)
		})
	}

	updateUserPreferences(reset?: boolean) {
		// Guard against double submission
		if (this.isUpdating) {
			return
		}

		const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(this.userId)
		const updateRecord = user.getUpdateRecord()
		const companyAdminPrefs = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany()
		const prefsString = this.prefsConfig.getUserUpdateString(companyAdminPrefs)
		updateRecord.admin_prefs_json = reset ? null : prefsString
		this.isUpdating = true
		this.coreSrvc.dbSrvc.updateRecord('users', updateRecord).then((updateResult) => {
			const updatedUser = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(this.userId)
			this.coreSrvc.dbSrvc.settingSrvc.setUserAdminPrefs()
			this.saveComplete.emit(true)
		})
	}

	// formatCategoryName(name: string): string {
	// 	return name.charAt(0).toUpperCase() + name.slice(1) + ' Preferences'
	// }

	// canEdit(pref: IAdminPrefsConfigItem) {
	// 	if (this.group === 'company') {
	// 		if (pref.showInAccount) { return true }
	// 	} else {
	// 		if (pref.showInUser && pref.userOverride) { return true }
	// 	}
	// 	return false
	// }

	isDifferentFromCompanyPrefs(pref) {
		if (this.group === 'user') {
			return pref.value !== this.companyPrefs[pref.name]
		}
		return false
	}

	resetPrefToCompanyDefault(pref) {
		pref.value = this.companyPrefs[pref.name]
	}

	// isHidden(name: string) {
	// 	return this.hiddenOptions.includes(name)
	// }

	isHidden(name: string) {
		const prefConfigItem: IAdminPrefsConfigItem = this.prefsConfig.getPrefForName(name)
		const isHiddenForView = this.hiddenOptions.includes(name)
		const isHiddenForState = prefConfigItem.isHidden ? prefConfigItem.isHidden() : false
		return isHiddenForView || isHiddenForState
	}

	// DEPRECATED 2024-04-04 - Refactored to use method on config item
	// isHidden(name: string) {
	// 	// lookup key in prefs config
	// 	const isHiddenForView = this.hiddenOptions.includes(name)
	// 	let isHiddenByDependency = false
	// 	const prefConfigItem: IAdminPrefsConfigItem = this.prefsConfig.getPrefForName(name)
	// 	if (prefConfigItem && !!prefConfigItem.isHidden) {
	// 		for (const dependency of prefConfigItem.isHidden) {
	// 			const parent = this.prefsConfig.getPrefForName(dependency)
	// 			if (parent) {
	// 				isHiddenByDependency = !parent.value // hide if parent value is not truthy
	// 			}
	// 		}
	// 	}
	// 	return isHiddenForView || isHiddenByDependency
	// }

	public toggleExpansion(isExpanded: boolean) {
		this.prefsCategories.forEach((pc) => (pc.isExpanded = isExpanded))
	}

	public showPrefHelp(pref: IAdminPrefsConfigItem) {
		const help = new HelpDialogMessage('Description', pref.description)
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}

	public performSearch(searchText: string) {
		const lcText = (searchText ?? '').toLowerCase()
		this.searchFilterText = lcText

		this.coreSrvc.zone.runOutsideAngular(() => {
			this.prefsCategories.forEach((pc) => {
				const prefs = this.getPrefsForCategory(pc)
				prefs.forEach((pref) => {
					pref.matchesSearchFilter = this.matchesSearchFilter(pref)
				})
			})
			this.prefsCategories.forEach((pc) => (pc.isExpanded = this.hasSearchFilterMatches(pc)))
			this.coreSrvc.zone.run(() => {
				this.cd.markForCheck()
			})
		})
	}

	public matchesSearchFilter(pref: IAdminPrefsConfigItem) {
		if (!this.searchFilterText) return true

		const perfSearchString = `${pref.name} ${pref.label} ${pref.description}`.toLowerCase()
		if (!perfSearchString.includes(this.searchFilterText)) return false

		return true
	}

	public hasSearchFilterMatches(category: IPrefsCategory) {
		const prefs = this.getPrefsForCategory(category)
		return prefs.some((pref) => pref.matchesSearchFilter)
	}

	get hasPrefsMatchingSearchFilter(): boolean {
		return this.prefsCategories.some((pc) => this.hasSearchFilterMatches(pc))
	}
}
