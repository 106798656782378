import { Injectable } from '@angular/core'
import { log } from '@app/helpers/logger'
import { Global } from '@app/models/global'

@Injectable({
	providedIn: 'root',
})
export class BrandingService {
	constructor() {
		log('Creating BrandingService')
	}

	logLocation() {
		const oLocation = location
		const aLog = ['Property (Typeof): Value', 'location (' + typeof oLocation + '): ' + oLocation]
		for (const sProp in oLocation) {
			if (oLocation.hasOwnProperty(sProp)) {
				aLog.push(sProp + ' (' + typeof oLocation[sProp] + '): ' + (oLocation[sProp] || 'n/a'))
			}
		}
		alert(aLog.join('\n'))
	}
}
