<div class="urr-block no-page-break">
	<div class="card urr-card">
		<div>
			<img *ngIf="empPfpUrl" [src]="empPfpUrl" class="float-right urr-pfp-image" (click)="openEmpPfp()" />
			<div>
				<div>
					<div class="urr-label urr-header-label nowrap">Employee:</div>
					<div class="urr-header-value">{{ transactionMetaData?.employee_name }}</div>
				</div>
				<div>
					<div class="urr-label urr-header-label nowrap">Emp Supervisor:</div>
					<div class="urr-header-value">{{ transactionMetaData?.emp_supervisor_name }}</div>
				</div>
				<div>
					<div class="urr-label urr-header-label nowrap">Job:</div>
					<div class="urr-header-value">{{ transactionMetaData?.job_description }}</div>
				</div>
				<div *ngIf="transactionMetaData?.job_description !== transactionMetaData?.jobsite_description">
					<div class="urr-label urr-header-label nowrap">Site:</div>
					<div class="urr-header-value">{{ transactionMetaData?.jobsite_description }}</div>
				</div>
				<div>
					<div class="urr-label urr-header-label nowrap">Site Supervisor:</div>
					<div class="urr-header-value">{{ transactionMetaData?.jobsite_supervisor_name }}</div>
				</div>
			</div>
		</div>
	</div>
</div>
