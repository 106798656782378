<div class="ctc-msg-header">
	<i *ngIf="showResponseIcon" class="fas fa-circle-chevron-down ctc-msg-response-icon" (click)="showResponseOptions = true"></i>
	<div class="ctc-msg-title">{{ title }}</div>
</div>
<div *ngIf="!showResponseOptions">
	<textarea
		[(ngModel)]="textMessage"
		placeholder="{{
			smsPlaceholder
				? smsPlaceholder
				: showResponseIcon
					? 'Enter your text message here or click the &#xf13a; icon above to send a canned message (employee app link, employee schedule link, etc.)'
					: 'Enter your text message here'
		}}"
		[maxlength]="smsCharLimit"
		class="ctc-msg-textarea form-control"
	></textarea>
	<div class="ctc-msg-footer">
		<div class="float-right">
			<button class="btn btn-sm btn-secondary" style="margin-right: 8px" (click)="cancelTextMsg()">Cancel</button>
			<button class="btn btn-sm btn-success" [disabled]="maxTextLengthExceeded || !textMessage" (click)="sendTextMsg()">Send</button>
		</div>
		<div class="ctc-msg-count" [class.ctc-msg-exceeded]="maxTextLengthExceeded">{{ textMessage.length }} / {{ smsCharLimit }}</div>
	</div>
</div>

<div *ngIf="showResponseOptions" style="margin: 10px auto; max-width: 280px">
	<button class="btn btn-sm btn-block btn-outline-main" style="margin-bottom: 10px" (click)="processResponseOption('EMPAPPLINK')">
		Employee App Link
	</button>
	<button class="btn btn-sm btn-block btn-outline-main" style="margin-bottom: 10px" (click)="processResponseOption('EMPSCHEDULELINK')">
		Employee Schedule Link
	</button>

	<button class="btn btn-sm btn-block btn-outline-secondary" (click)="showResponseOptions = false">Back to Message</button>
</div>
