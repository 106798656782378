import { DateTimeHelper } from '@app/helpers/datetime'
import { ReportRecord } from '@app/models'
import moment from 'moment-timezone'
import { log } from './logger'

interface IHasFilterProperty {
	id: number
	filter: string // Json string containing filter info
}

export class ReportHelper {
	/**
	 * Display last run date and time from timestamp
	 * @param timestamp Timestamp - ex. 2016-12-17T14:30:20.500Z[UTC]
	 * @returns A formatted string - ex. 2016-12-17 at 2:30 pm
	 */

	static displayLastRun(timestamp: any): string {
		const is12Hours = DateTimeHelper.format12Hour
		if (!timestamp || timestamp === '') {
			return ' '
		}
		const origDate = DateTimeHelper.stripUtcTag(timestamp)
		const mom = moment(origDate)
		const dateString = mom.format('YYYY-MM-DD')
		const timeString = is12Hours ? mom.format('h:mm a') : mom.format('HH:mm')
		return dateString + ' at ' + timeString
	}
}

export class ReportsTableFormatter {
	static reportFilterIcon(rec: IHasFilterProperty, bridgeTableName: string) {
		const options = rec.filter ? JSON.parse(rec.filter) : null
		const filterStyle = options ? `style="color:green"` : `style="color:lightgray"`
		let tooltipList = []
		if (options) {
			for (const opt of filterProps) {
				const label = opt.label
				const count = options[opt.key]?.length ?? null
				if (count) {
					tooltipList.push(`${label}: ${count}`)
				}
			}
		}
		const tooltipText = tooltipList.length > 0 ? tooltipList.join('<br>') : 'No Filters'
		const clickHandler = `onclick="${bridgeTableName}.showFilterInfo(${rec.id})"`
		return `<div style="display: inline-block; margin-left: 14px"><i ${clickHandler} class="fa fa-filter has-pointer item-tooltip" ${filterStyle} ></i></div>`
		// return `<div class="item-tooltip" data-bs-html="true" title="${tooltipText}" style="display: inline-block; margin-left: 14px">
		// 				<i ${clickHandler} class="fa fa-filter has-pointer item-tooltip" ${filterStyle} ></i>
		// 		</div>`
	}
}

const filterProps = [
	{ key: 'emp_supervisor_id', label: 'Emp Sups' },
	{ key: 'jobsite_supervisor_id', label: 'Site Sups' },
	{ key: 'emp_id', label: 'Employees' },
	{ key: 'jobsite_id', label: 'Sites' },
	{ key: 'job_id', label: 'Jobs' },
	{ key: 'client_id', label: 'Clients' },
	{ key: 'vendor_id', label: 'Vendors' },
	{ key: 'dow_iso_id', label: 'DOW' },
]
