import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { DateTimeHelper, GeneralTableHelper, Helper, log } from '@app/helpers'
import { AdminPrefs, IScheduleOptionsDayOption, ScheduleListItemEntry, ScheduleOptions } from '@app/models'
import { DatabaseService } from '@app/services'

import moment from 'moment-timezone'

@Component({
    selector: 'app-scheduler-list-item-entry',
    templateUrl: './list-item-entry.component.html',
    styleUrls: ['./list-item-entry.component.scss'],
    standalone: false
})
export class SchedulerListItemEntryComponent implements OnInit, OnDestroy {
	isInternalUser = false

	useEmpCounts = true
	dayOptions = ScheduleOptions.dayOptions

	autoRefreshInterval: NodeJS.Timeout

	@Input() type: 'EMP' | 'JOB'
	@Input() item: ScheduleListItemEntry
	@Input() canEditRecord: boolean
	@Input() canDeleteRecord: boolean
	@Input() isBatchingEnabled: boolean
	@Input() userPrefs: AdminPrefs
	@Input() shouldCalculateNextUp: boolean

	@Output() auditRecord = new EventEmitter<ScheduleListItemEntry>()
	@Output() editRecord = new EventEmitter<ScheduleListItemEntry>()
	@Output() copyRecord = new EventEmitter<ScheduleListItemEntry>()
	@Output() deleteRecord = new EventEmitter<ScheduleListItemEntry>()
	@Output() takeAction = new EventEmitter<ScheduleListItemEntry>()
	@Output() showScheduleConflicts = new EventEmitter<ScheduleListItemEntry>()

	tagsHtml = ''

	constructor(
		private cd: ChangeDetectorRef,
		private dbSrvc: DatabaseService,
	) {
		this.isInternalUser = this.dbSrvc.settingSrvc.isInternalUser()
		this.arrangeDayOptionsForWkst()
	}

	get batchCount(): number {
		return this.dbSrvc.schedulerSrvc.scheduleBatchUpdateList.length
	}

	get isBatchSelected(): boolean {
		return this.dbSrvc.schedulerSrvc.scheduleBatchUpdateList.includes(this.item.entry.id)
	}

	get currentDayViewDate(): Date {
		return this.dbSrvc.schedulerSrvc.currentDayViewDate
	}
	set currentDayViewDate(date: Date) {
		this.dbSrvc.schedulerSrvc.currentDayViewDate = date
	}

	get lastUpdatedOn(): string {
		const lastUpdated = this.item.entry.approval_state_last_updated
		return lastUpdated ? DateTimeHelper.standardDateTimeFromDateString(lastUpdated) : ''
	}

	get lastUpdatedBy(): string {
		const userId = this.item.entry.approval_state_last_user_id
		const user = this.dbSrvc.settingSrvc.getUserForId(userId)
		return user ? user.first_name + ' ' + user.last_name : ''
	}

	get nextUpProcessing(): boolean {
		return this.dbSrvc.schedulerSrvc.isNextUpProcessing
	}

	ngOnInit(): void {
		this.setupComponent()
	}

	ngOnDestroy(): void {
		clearInterval(this.autoRefreshInterval)
	}

	arrangeDayOptionsForWkst() {
		const dayOptions = [...ScheduleOptions.dayOptions]
		const company = this.dbSrvc.settingSrvc.getCompany()
		const wkst = company.wkst || 0
		if (!wkst || wkst < 0 || wkst > 6) {
			this.dayOptions = dayOptions
			return
		}
		const comps = dayOptions.splice(wkst)
		const newDayOptions = [...comps, ...dayOptions]
		this.dayOptions = newDayOptions
	}

	setupComponent() {
		const isPendingSched = this.item?.entry.approval_state === 'PENDING'
		if (isPendingSched) {
			this.autoRefreshInterval = setInterval(() => {
				this.cd.markForCheck()
			}, 10000)
		}

		// Setup Tags
		const tagsJson: any = this.item.entry
		if (tagsJson && tagsJson !== '[]') {
			this.tagsHtml = GeneralTableHelper.tags(tagsJson, true, false)
		}
	}

	showScheduleConflictsBtnClicked() {
		this.showScheduleConflicts.emit(this.item)
	}

	listItemTimeFormatter() {
		const item = this.item
		if (item.isAnytimeJob || item.startTime === item.endTime) {
			return `Anytime Job / ${item.tzAbrev}`
		} else {
			if (item.info) {
				return item.info.scheduledTimeInfo
			} else {
				const timeStr = item.startTime + ' - ' + item.endTime
				return `${timeStr} ${item.tzAbrev}`
			}
		}
	}

	listItemFreqFormatter() {
		const item = this.item // as ScheduleListRuleFormattable
		const rule = item.rule
		const freq = rule.options.freq
		const interval = rule.options.interval
		const freqStr = freq === 0 ? 'year' : freq === 1 ? 'month' : freq === 2 ? 'week' : 'unknown'
		const isOneTime = rule.options.count === 1
		if (isOneTime) {
			return 'Scheduled one time'
		}
		const isPlural = interval && Helper.isPlural(interval) ? true : false
		const freqResult = isPlural ? `${freqStr}s` : freqStr
		return `Scheduled every ${interval > 1 ? interval : ''} ${freqResult}`
	}

	listItemNextUpFormatter() {
		const item = this.item
		if (this.currentDayViewDate) {
			return ''
		}
		const isAnytime = item.isAnytimeJob
		const isScheduledForToday = item.isScheduledForToday
		const isInProgress = item.isInProgress
		const shouldCalculateNextUp = this.shouldCalculateNextUp
		const nextUpInfo = item.info.nextUpInfo
		// const isCompletedForToday = entry.isCompletedForToday
		// const isUpcomingForToday = entry.isUpcomingForToday
		// const hasNextDate = !entry.hasNextDate
		// const isAfter5pmLocal = parseFloat(moment().format('HH')) > 17
		// if (item.entry.approval_state === 'PENDING') {
		// 	return 'Awaiting Approval'
		// }
		// if (!item.entry.enabled) {
		// 	return 'Currently Disabled'
		// }
		if (isScheduledForToday && isAnytime) {
			return 'Scheduled For Today'
		}
		if (isInProgress && !isAnytime) {
			return 'Job In Progress'
		}
		return shouldCalculateNextUp && !nextUpInfo ? 'Calculating data...' : nextUpInfo
	}

	// editEntry() { }
	// deleteRecord() { }

	listItemConflictsFormatter(conflicts: Array<string>) {
		// Migrated
		if (!conflicts) {
			return
		}
		return conflicts.join(', ')
	}

	listItemIsWeekdaySelected(weekday: IScheduleOptionsDayOption): boolean {
		const days = this.item.rule.options.byweekday
		return days.includes(weekday.value)
		// return true
	}

	listItemIsMultiDayJob(weekday): boolean {
		const item = this.item
		const isSelected = this.listItemIsWeekdaySelected(weekday)
		if (isSelected && item.isMultDayJob) {
			return true
		} else {
			return false
		}
	}

	hoursLeft() {
		const nextUpMoment = this.item?.info?.nextUpMom
		if (nextUpMoment) {
			const nowMoment = moment()

			const hoursLeft = nextUpMoment.diff(nowMoment, 'hours')
			return hoursLeft <= 0 ? 0 : hoursLeft
		}
		return 0
	}

	minutesLeft() {
		const nextUpMoment = this.item?.info?.nextUpMom
		if (nextUpMoment) {
			const nowMoment = moment()

			const minutesLeft = nextUpMoment.diff(nowMoment, 'minutes')
			return minutesLeft <= 0 ? 0 : minutesLeft
		}
		return 0
	}

	timeRemaining() {
		const nextUpMoment = this.item?.info?.nextUpMom
		if (nextUpMoment) {
			const nowMoment = moment()

			const minutesLeft = nextUpMoment.diff(nowMoment, 'minutes')
			return DateTimeHelper.formatHrAndMinFromMinutes(minutesLeft)
		}
		return null
	}

	isDeadlineMissed() {
		const minutesLeft = this.minutesLeft()
		const isNextUpProcessing = this.dbSrvc.schedulerSrvc.isNextUpProcessing
		return minutesLeft === 0 && !isNextUpProcessing
	}

	addToOrRemoveFromBatch() {
		if (!this.isBatchingEnabled) return
		if (this.isBatchSelected) {
			this.dbSrvc.schedulerSrvc.removeScheduleIdFromBatchUpdate(this.item.entry.id)
		} else {
			this.dbSrvc.schedulerSrvc.addScheduleIdToBatchUpdate(this.item.entry.id)
		}
	}
}
