<div class="to-search-wrap">
	<app-search-filter [placeholder]="'filter records'" (search)="searchTable($event)"> </app-search-filter>
</div>

<div class="option-dropdown-wrap noprint">
	<app-table-filter-buttons [manager]="tableFilterManager" [noMargins]="true" (buttonClicked)="changeSubsection($event)"></app-table-filter-buttons>
</div>

<div class="section-table dt-search-hide">
	<table id="timeOffTable" class="stripe nowrap" cellspacing="0" width="100%"></table>
	<div class="table-footnote">
		<strong>Note: </strong>
		If you are tracking time off in reports, <strong style="color: firebrick">do not delete</strong> completed entries. They will automatically be
		removed from this view after 60 days.
		<span *ngIf="!isDayViewActive && hasCompletedEntries" class="link-text" style="margin-top: 6px" (click)="toggleHidden()">
			{{ showCompletedEntries ? 'hide' : 'show' }} completed entries
		</span>
	</div>

	<app-table-footer-buttons [marginTop]="'15px'" [newBtnLabel]="'Add Time Off'" (newBtnClicked)="createRecord()"></app-table-footer-buttons>
	<!-- <i id="clear-search-icon" (click)="clearSearch()" class="fa fa-times-circle-o" aria-hidden="true"></i> -->
</div>

<app-modal-dialog *ngIf="editDialogManager.isDialogVisible" [dialogManager]="editDialogManager">
	<app-vacation-detail
		[dialogManager]="editDialogManager"
		[action]="editAction.action"
		[vacationId]="editAction.recordId"
		(saveActionComplete)="saveActionComplete($event)"
		(editActionCancelled)="editActionCancelled()"
	></app-vacation-detail>
</app-modal-dialog>

<app-delete-record-dialog
	*ngIf="deleteAction.showDeleteRecordDialog"
	[showDeleteRecordDialog]="deleteAction.showDeleteRecordDialog"
	[tableName]="deleteAction.tableName"
	[recordId]="deleteAction.recordId"
	[recordLabel]="deleteAction.recordLabel"
	[showConfirmation]="true"
	[confirmationMsg]="deleteAction.confirmationMsg"
	(cancelDelete)="deleteActionCancel()"
	(actionComplete)="deleteActionComplete()"
>
</app-delete-record-dialog>

<app-modal-dialog *ngIf="sectionPrefsDialogManager.isDialogVisible" [dialogManager]="sectionPrefsDialogManager">
	<app-local-prefs [dialogManager]="sectionPrefsDialogManager" (prefsUpdated)="prefsDataSaved()"></app-local-prefs>
</app-modal-dialog>
