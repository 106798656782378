import { EventEmitter, Injectable } from '@angular/core'
import { SkillRecord } from '@app/models'
import { log } from '@app/helpers'
import _ from 'lodash'

@Injectable({
	providedIn: 'root',
})
export class CallCenterService {
	skillDataLoaded = false
	skillList: Array<SkillRecord> = []
	skillListChange = new EventEmitter<Array<SkillRecord>>()
	skillListlastUpdated: Date

	constructor() {
		log('Creating CallCenterService')
	}

	clearData() {
		this.skillDataLoaded = false
		this.skillList = []
		this.skillListlastUpdated = null
	}

	getSkillRecords(): Array<SkillRecord> {
		const list = this.skillList
		const result = _.sortBy(list, 'name')
		return result
	}

	getSkillRecordById(id: number) {
		return this.skillList.find((rec) => rec.id === id)
	}

	removeLocalSkillRecord(recordId: number) {
		this.skillList = this.skillList.filter((rec) => rec.id !== recordId)
	}

	setSkillRecords(records: Array<SkillRecord>) {
		this.skillListlastUpdated = new Date()
		this.skillList = records.map((rec) => new SkillRecord(rec))
		this.skillDataLoaded = true
		this.skillListChange.next(this.skillList)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateSkillRecords(records: Array<SkillRecord>) {
		const newRecords = records.map((rec) => new SkillRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.skillList.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.skillList.push(newRecord)
			}
		}
		this.skillListlastUpdated = new Date()
		this.skillListChange.next(this.skillList)
	}
}
