import { Injectable } from '@angular/core'
import { BannerViewManager } from '@app/models'
import { log } from '@app/helpers'
import { Subject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class BannerService {
	showBanners = true

	viewManager = new BannerViewManager()

	bannerPanelShouldHide = new Subject<boolean>() // bannerPanelShouldHide should be triggered after an individual banner updates so that the cumulative count of the notifications banner can be updated when banners are shown as grouped.

	groupBannerNeedsUpdate = new Subject<boolean>() // groupBannerNeedsUpdate should be triggered when a notification banner has been clicked on. It will then hide the popdown panel when banners are shown as grouped.

	bannerAvailabilityNeedsUpdate = new Subject<boolean>() // bannerAvailabilityNeedsUpdate should be triggered when any change is made that might affect banner availability such as a preference change or after some record has been loaded that might affect whether the banner is used. An example is that W2W Errors depend on an isW2WIntegrated check which isn't available until after the W2W sync record has been loaded.

	constructor() {
		log('Creating BannerService')
	}
}
