import { Component, Input, OnInit } from '@angular/core'
import { IScheduleOptionsDayOption, ScheduleEntry, ScheduleOptions } from '@app/models'
import { log } from '@app/helpers'
import { DatabaseService } from '@app/services'

interface IEmpDow {
	employee_id: number
	days_of_week: Array<number>
}

class EmployeeDowEntry {
	name = 'Employee Not Synchronized'
	isActive = true
	schedDays = ''

	constructor(dbSrvc: DatabaseService, dayOptions, recurDays: Array<number>, rec: IEmpDow) {
		log('rec', rec)
		const emp = dbSrvc.empSrvc.getEmployeeById(rec.employee_id)
		if (emp) {
			this.name = emp.name
			this.isActive = emp.active
			const isoDays = rec.days_of_week || []
			this.schedDays = dayOptions
				.filter((opt) => recurDays.includes(opt.value))
				.filter((opt) => isoDays.includes(opt.isoValue))
				.map((opt) => opt.code)
				.join(', ')
		}
	}
}

@Component({
    selector: 'app-list-item-scheduled-employees',
    templateUrl: './list-item-scheduled-employees.component.html',
    styleUrls: ['./list-item-scheduled-employees.component.scss'],
    standalone: false
})
export class ListItemScheduledEmployeesComponent implements OnInit {
	list: Array<EmployeeDowEntry> = []
	@Input() schedEntry: ScheduleEntry
	@Input() dayOptions: Array<IScheduleOptionsDayOption>

	constructor(private dbSrvc: DatabaseService) {}

	ngOnInit(): void {
		this.setupComponent()
	}

	private setupComponent() {
		log('schedEntry', this.schedEntry, this.schedEntry.getScheduledDays())
		const schedDays = this.schedEntry.getScheduledDays()
		this.list = this.schedEntry.emp_ids_days_of_week.map((e) => new EmployeeDowEntry(this.dbSrvc, this.dayOptions, schedDays, e))
	}
}
