import { Injectable } from '@angular/core'

import {
	ScheduleEntry,
	IScheduleChangeRequest,
	ScheduleChangeRequest,
	ScheduleViewManager,
	ScheduleStatusFilter,
	ScheduleShiftViewManager,
	NewShiftRangeSelectInfo,
	NewScheduleType,
	RecordTagContainer,
} from '@app/models'
import { log } from '@app/helpers'

import _ from 'lodash'
import { Subject } from 'rxjs'
import { ScheduleLogRecord } from '@app/models/schedule-log'

export class ShiftViewCalendarDefaults {
	initialDate: string // YYYY-MM-DD
	constructor(initialDate: string) {
		this.initialDate = initialDate
	}
}

@Injectable({
	providedIn: 'root',
})
export class SchedulerService {
	dataLoaded = false
	list: ScheduleEntry[] = []
	lastUpdated: Date

	changeRequestDataLoaded = false
	changeRequestList: Array<ScheduleChangeRequest> = []
	changeRequestLastUpdated: Date
	changeRequestFilterId: number = null

	fetchAndReloadAllDataForShiftViewEvent = new Subject<boolean>()

	offerOpenShiftEvent = new Subject<ScheduleLogRecord>() // Used to processing of schedules is complete
	dayViewProcessingComplete = new Subject<boolean>() // Opens the dialog in shift view component for editing offer
	openShiftsDataLoaded = false
	openShiftsList: Array<ScheduleLogRecord> = []

	scheduleLogDidBeginUpdate = new Subject<boolean>()
	scheduleLogDidFinishUpdate = new Subject<boolean>()

	// scheduleRecurDidBeginUpdate = new Subject<boolean>()
	openShiftListUpdated = new Subject<boolean>()
	scheduleRecurListUpdated = new Subject<boolean>()
	scheduleRecurDidFinishLoading = new Subject<boolean>()

	scheduleRecurCopyRecordId = new Subject<number>()
	scheduleRecurEditRecordId = new Subject<number>()

	dayViewToggleEvent = new Subject<boolean>()
	recalculateDayViewFilter = new Subject<boolean>()
	checkMultiScheduleStatus = new Subject<boolean>()

	scheduleViewManager = new ScheduleViewManager()
	shiftViewManager = new ScheduleShiftViewManager()
	statusFilter = new ScheduleStatusFilter() // Instantiate after scheduleViewManager because it uses settings from there

	isNextUpProcessing = false // Next up recalc UI blocker (only used in list item component to hide timer info when updating)

	newShiftRangeSelectInfo: NewShiftRangeSelectInfo = null // Holds date range for drag to select for new shift
	createNewShiftEvent = new Subject<NewScheduleType>() // Event fired to trigger createRecord event in SchedulerComponent

	shiftViewDefaultDate: ShiftViewCalendarDefaults // YYYY-MM-DD - Used to setup the calendar to show this date if it's set
	schedLogIdsToFlash: Array<number> = []

	scheduleBatchUpdateList: Array<number> = []

	get currentDayViewDate(): Date {
		return this.scheduleViewManager.currentDayViewDate
		// const storedDate = localStorage.getItem('schedulerDayViewDate')
		// if (storedDate) { return JSON.parse(storedDate) }
		// return null
	}

	set currentDayViewDate(date: Date) {
		this.scheduleViewManager.currentDayViewDate = date
		this.scheduleViewManager.save()
		// if (!date) { localStorage.removeItem('schedulerDayViewDate'); return }
		// localStorage.setItem('schedulerDayViewDate', JSON.stringify(date))
	}

	constructor() {
		log('Creating SchedulerService')

		// Set the filter based on what's been saved
		this.statusFilter.showExpired = this.scheduleViewManager.series.showExpiredSchedules
	}

	get pendingScheduleCount(): number {
		return this.getPendingSchedules().length
	}

	addScheduleIdToBatchUpdate(id: number) {
		this.scheduleBatchUpdateList.push(id)
	}

	removeScheduleIdFromBatchUpdate(id: number) {
		this.scheduleBatchUpdateList = _.without(this.scheduleBatchUpdateList, id)
	}

	clearBatchUpdateList() {
		this.scheduleBatchUpdateList = []
	}

	getCache() {
		const cache = {}
		for (const item of this.list) {
			cache[item.id] = item
		}
		return cache
	}

	getTagLabels(): Array<string> {
		let tags = []
		this.list.forEach((rec) => {
			const tagContainer = new RecordTagContainer(rec.tags_json)
			const parsedTags = tagContainer.tags.map((tag) => tag.label)
			tags = [...tags, ...parsedTags]
		})
		const unique = _.uniq(tags)
		tags = _.orderBy(unique)
		return tags
	}

	count(): number {
		return this.list.length
	}

	clearData() {
		this.list = []
		this.dataLoaded = false
	}

	// General Schedule

	toggleDayView(status?: 'OFF' | 'ON') {
		if (this.scheduleViewManager.currentDayViewDate || status === 'OFF') {
			this.scheduleViewManager.currentDayViewDate = null
			this.scheduleViewManager.save()
			this.dayViewToggleEvent.next(false)
			return
		}
		if (!this.scheduleViewManager.currentDayViewDate || status === 'ON') {
			this.scheduleViewManager.currentDayViewDate = new Date()
			this.scheduleViewManager.save()
			this.dayViewToggleEvent.next(true)
			return
		}
	}

	getSchedules(): Array<ScheduleEntry> {
		return this.list.filter((li) => li.approval_state !== 'REJECTED')
	}
	getPendingSchedules(): Array<ScheduleEntry> {
		return this.list.filter((rec) => rec.approval_state === 'PENDING')
	}
	getChangeRequests(): Array<ScheduleChangeRequest> {
		return this.changeRequestList
	}
	getOpenShifts(): Array<ScheduleLogRecord> {
		return this.openShiftsList.filter((os) => (os.enabled_override ? os.enabled_override : os.enabled))
	}

	getScheduleForId(id: number): ScheduleEntry {
		return this.list.find((sched) => sched.id === id)
	}
	getSchedulesForJobId(id: number): Array<ScheduleEntry> {
		return this.list.filter((sched) => sched.job_id === id)
	}
	getChangeRequestForId(id: number): ScheduleChangeRequest {
		return this.changeRequestList.find((cr) => cr.id === id)
	}

	getEntriesForEmpId(id: number): Array<ScheduleEntry> {
		// return this.list.filter(entry => entry.employee_id === id) // UPDATED
		return this.list.filter((entry) => entry.employee_id === id || entry.employee_ids.includes(id))
	}

	getChildEntriesForParentId(id: number) {
		return this.list.filter((entry) => entry.parent_schedule_id === id)
	}

	getEntriesForJobId(id: number): Array<ScheduleEntry> {
		return this.list.filter((entry) => entry.job_id === id && entry.approval_state !== 'REJECTED')
	}

	makeSchedule(record: ScheduleEntry): ScheduleEntry {
		const sched = new ScheduleEntry(record)
		return sched
	}

	setSchedulerRecords(records: Array<ScheduleEntry>) {
		this.lastUpdated = new Date()
		this.list = records.map((r) => this.makeSchedule(r))
		this.dataLoaded = true
		this.scheduleRecurListUpdated.next(true)
		// this.scheduleRecurDidFinishLoading.next(true)
	}

	setChangeRequestRecords(records: Array<IScheduleChangeRequest>) {
		this.changeRequestLastUpdated = new Date()
		this.changeRequestList = records.map((r) => new ScheduleChangeRequest(r))
		this.changeRequestDataLoaded = true
	}

	setOpenShiftRecords(records: Array<ScheduleLogRecord>) {
		this.lastUpdated = new Date()
		this.openShiftsList = records.map((r) => new ScheduleLogRecord(r))
		this.openShiftsDataLoaded = true
		this.openShiftListUpdated.next(true)
	}

	removeLocalRecord(recordId: number) {
		this.list = this.list.filter((rec) => rec.id !== recordId)
		this.scheduleRecurListUpdated.next(true)
	}

	removeLocalChangeRequestRecord(recordId: number) {
		this.changeRequestList = this.changeRequestList.filter((rec) => rec.id !== recordId)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateSchedulerRecords(records: Array<ScheduleEntry>) {
		const newRecords = records.map((rec) => this.makeSchedule(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.list.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.list.push(newRecord)
			}
		}
		this.scheduleRecurListUpdated.next(true)
	}

	addOrUpdateChangeRequestRecords(records: Array<IScheduleChangeRequest>) {
		const newRecords = records.map((rec) => new ScheduleChangeRequest(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.changeRequestList.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.changeRequestList.push(newRecord)
			}
		}
	}
}
