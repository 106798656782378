<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<div class="alert alert-info alert-bg-gray" style="margin-top: 15px; margin-bottom: 30px">
		By default, the monthly billing statement will be sent to the email address that was entered during sign up. That value can be changed here. You
		must have at least one billing contact email address.<br /><br />
		Billing phone numbers are not required. Text messages will be sent to these numbers in the event of a billing issue (failed payment, expired
		card, etc.)
	</div>

	<app-form-expander [isExpanded]="true" [label]="'Billing Email Addresses'">
		<app-generic-email-list [manager]="emailListManager"></app-generic-email-list>
	</app-form-expander>

	<app-form-expander [isExpanded]="true" [label]="'Billing Phone Numbers'">
		<app-generic-phone-list [manager]="phoneListManager"></app-generic-phone-list>
	</app-form-expander>
</div>
