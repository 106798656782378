import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { HelpDialogMessage, VizDashboardHelper, VizCardGraphDataSource } from '@app/models'
import { CoreService } from '@app/services'
import { SelectItem } from 'primeng/api'

@Component({
    selector: 'app-viz-card-graph-edit',
    templateUrl: './viz-card-graph-edit.component.html',
    styleUrl: './viz-card-graph-edit.component.scss',
    standalone: false
})
export class VizCardGraphEditComponent implements OnInit, OnDestroy {
	@Input() graph: VizCardGraphDataSource

	originalGraphOptionsJson: string = ''

	originalDataSource = null
	currentDataSource = null
	dataSourceOptions: Array<SelectItem> = []

	constructor(private coreSrvc: CoreService) {
		this.setupDataSourceOptions()
	}

	public ngOnInit() {
		this.originalDataSource = this.graph.source
		this.currentDataSource = this.graph.source
		this.originalGraphOptionsJson = JSON.stringify(this.graph.options)
	}

	public ngOnDestroy() {
		this.graph.source = this.currentDataSource
		this.graph.updateData()
		this.checkForChanges()
	}

	private setupDataSourceOptions() {
		this.dataSourceOptions = VizDashboardHelper.graphs.map((ds) => ({
			label: ds.title,
			value: ds.source,
		}))
	}

	private checkForChanges() {
		const currentOptionsJson = JSON.stringify(this.graph.options)
		const optionsChanged = this.originalGraphOptionsJson !== currentOptionsJson
		const dataSourceChanged = this.currentDataSource !== this.originalDataSource

		if (optionsChanged || dataSourceChanged) this.coreSrvc.dbSrvc.vizSrvc.markCurrentDashboardAsModified()
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'show_legend':
				help.header = 'Show Legend'
				help.message = 'Determines whether or not to show the legend.'
				break
			case 'stack_data':
				help.header = 'Stack Data'
				help.message = 'This will stack data sources on the specified axis.'
				break
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
