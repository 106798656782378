import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CoreService } from '@app/services'

@Component({
    selector: 'app-form-input-switch',
    templateUrl: './form-input-switch.component.html',
    styleUrls: ['./form-input-switch.component.scss'],
    standalone: false
})
export class FormInputSwitchComponentComponent {
	@Input() model: boolean = false
	@Output() modelChange = new EventEmitter<boolean>()

	@Input() enabledLabel = 'Item is enabled'
	@Input() enabledHint = 'to disable'

	@Input() disabledLabel = 'Item is disabled'
	@Input() disabledHint = 'to enable'

	@Input() wrapStyle: Object = { margin: '0px' }
	@Input() labelStyle: Object = { 'font-size': '1rem' }

	public showLabelHint = false
	private isDesktop = true

	constructor(private coreSrvc: CoreService) {
		this.isDesktop = this.coreSrvc.devDetect.isDesktop()
		if (!this.isDesktop) this.showLabelHint = true
	}

	get hintLabel(): string {
		const prefix = this.isDesktop ? 'Click ' : 'Tap '
		return prefix + `${this.model ? this.enabledHint : this.disabledHint}`
	}

	public toggleSwitch() {
		this.model = !this.model
		this.emitChange()
	}

	public emitChange() {
		this.modelChange.emit(this.model)
	}

	public onMouseEnter() {
		if (this.isDesktop) this.showLabelHint = true
	}

	public onMouseLeave() {
		if (this.isDesktop) this.showLabelHint = false
	}
}
