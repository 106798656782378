import {
	ReportGlobals,
	TransactionMetaDataForReport,
	UserReportMetaData,
	IUserReportFormat,
	ReportFieldDateTimeInput,
	ReportFieldGenericInput,
	ReportFieldCheckboxWithDescription,
	ReportFieldSelectWithOption,
	UserReportValidator,
} from './report-user'

import { log } from '@app/helpers/logger'

export class HomecareReport {
	metaData: UserReportMetaData
	reportData: HomecareReportData

	constructor(reportData: HomecareReportData) {
		const version = reportData.version
		const format = reportData.format
		this.metaData = new UserReportMetaData(version, format)
		this.reportData = reportData
	}
}

export class HomecareReportData {
	version = 1
	format: IUserReportFormat

	locale = 'en'
	countryCode = null

	displayPrefs = {
		format12Hour: ReportGlobals.displayPrefs.format12Hour,
	}

	transactionMetaData: TransactionMetaDataForReport

	serviceDate = new ReportFieldDateTimeInput({ type: 'DATETIME', style: 'DATE', value: new Date(), skip: false })

	serviceType = new ReportFieldSelectWithOption()

	serviceProvider = {
		firstName: new ReportFieldGenericInput({ style: 'INPUT' }),
		lastName: new ReportFieldGenericInput({ style: 'INPUT' }),
		license: new ReportFieldGenericInput({ style: 'INPUT' }),
	}

	clientInformation = {
		firstName: new ReportFieldGenericInput({ style: 'INPUT' }),
		lastName: new ReportFieldGenericInput({ style: 'INPUT' }),
		medicaidNumber: new ReportFieldGenericInput({ style: 'INPUT' }),
	}

	supportPlan = {
		effectiveDate: new ReportFieldDateTimeInput({ type: 'DATETIME', style: 'DATE', value: new Date(), skip: false }),
		planGoals: new ReportFieldGenericInput({ style: 'TEXTAREA' }),
	}

	// Any changes need to update homecare report edit form servicesProvidedKeys
	servicesProvided = {
		assistWithRightsEducation: new ReportFieldCheckboxWithDescription(),
		assistWithAbuseEducation: new ReportFieldCheckboxWithDescription(),
		assistWithNeglectEducation: new ReportFieldCheckboxWithDescription(),
		assistWithExploitationEducation: new ReportFieldCheckboxWithDescription(),
		assistWithCommunicationSkills: new ReportFieldCheckboxWithDescription(),
		assistWithHealthMaintenance: new ReportFieldCheckboxWithDescription(),
		assistWithStrengthAndBalance: new ReportFieldCheckboxWithDescription(),
		assistWithDailyTasks: new ReportFieldCheckboxWithDescription(),
		assistWithSocialization: new ReportFieldCheckboxWithDescription(),
		assistWithShopping: new ReportFieldCheckboxWithDescription(),
		assistWithChoicesAndOptions: new ReportFieldCheckboxWithDescription(),
		assistWithOtherActivities: new ReportFieldCheckboxWithDescription(),
	}

	summary = {
		summaryOfService: new ReportFieldGenericInput({ style: 'TEXTAREA' }),
		outcomeOfService: new ReportFieldGenericInput({ style: 'TEXTAREA' }),
		additionalNotes: new ReportFieldGenericInput({ style: 'TEXTAREA' }),
	}

	constructor(data?: any) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
	}
}

export class HomecareReportHelper {
	static servicesProvidedKeys = [
		'assistWithRightsEducation',
		'assistWithAbuseEducation',
		'assistWithNeglectEducation',
		'assistWithExploitationEducation',
		'assistWithCommunicationSkills',
		'assistWithHealthMaintenance',
		'assistWithStrengthAndBalance',
		'assistWithDailyTasks',
		'assistWithSocialization',
		'assistWithShopping',
		'assistWithChoicesAndOptions',
		'assistWithOtherActivities',
	]

	static lookupServiceProvidedLabel(key: string) {
		switch (key) {
			case 'assistWithRightsEducation':
				return 'Assist with rights education'
			case 'assistWithAbuseEducation':
				return 'Assist with abuse education'
			case 'assistWithNeglectEducation':
				return 'Assist with neglect education'
			case 'assistWithExploitationEducation':
				return 'Assist with exploitation education'
			case 'assistWithCommunicationSkills':
				return 'Assist with communication skills'
			case 'assistWithHealthMaintenance':
				return 'Assist with health maintenance'
			case 'assistWithStrengthAndBalance':
				return 'Assist with strength and balance'
			case 'assistWithDailyTasks':
				return 'Assist with daily tasks'
			case 'assistWithSocialization':
				return 'Assist with socialization'
			case 'assistWithShopping':
				return 'Assist with shopping'
			case 'assistWithChoicesAndOptions':
				return 'Assist with choices and options'
			case 'assistWithOtherActivities':
				return 'Assist with other activities'
			default:
				return 'Unknown Service Item'
		}
	}
}

export class HomecareReportValidator {
	reportData: HomecareReportData
	isInDialog = false

	constructor(reportData?: HomecareReportData) {
		this.reportData = reportData
	}

	get isReportValid(): boolean {
		return this.validateReport(false)
	}

	setReportData(reportData: HomecareReportData) {
		this.reportData = reportData
	}

	validateReport(interactive: boolean): boolean {
		const reportData = this.reportData
		log('reportData', reportData)

		// Service type selector
		if (!reportData.serviceType.value) {
			if (interactive) {
				UserReportValidator.validationAlert('Please select a service type.')
				UserReportValidator.scrollToRegion({ id: 'SERVICE_TYPE', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}
		// Custome service input
		if (reportData.serviceType.value === 'OTHER') {
			if (!reportData.serviceType.option) {
				if (interactive) {
					UserReportValidator.validationAlert('Please specify the type of service provided.')
					UserReportValidator.scrollToRegion({
						id: 'SERVICE_TYPE',
						isInDialog: this.isInDialog,
						addClass: 'ur-input-needs-info',
						addClassToId: 'HOMECARE_SPECIFY_TYPE',
					})
				}
				return false
			}
		}

		// Provider first name
		if (!reportData.serviceProvider.firstName.value) {
			if (interactive) {
				UserReportValidator.validationAlert('Please enter your first name.')
				UserReportValidator.scrollToRegion({ id: 'PROVIDER_FIRST_NAME', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}
		// Provider last name
		if (!reportData.serviceProvider.lastName.value) {
			if (interactive) {
				UserReportValidator.validationAlert('Please enter your last name.')
				UserReportValidator.scrollToRegion({ id: 'PROVIDER_LAST_NAME', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}
		// Provider license number
		if (!reportData.serviceProvider.license.skip && !reportData.serviceProvider.license.value) {
			if (interactive) {
				UserReportValidator.validationAlert('Please enter your license number.')
				UserReportValidator.scrollToRegion({ id: 'PROVIDER_LICENSE_NUM', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}

		// Client first name
		if (!reportData.clientInformation.firstName.value) {
			if (interactive) {
				UserReportValidator.validationAlert(`Please enter the client's first name.`)
				UserReportValidator.scrollToRegion({ id: 'CLIENT_FIRST_NAME', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}
		// Client last name
		if (!reportData.clientInformation.lastName.value) {
			if (interactive) {
				UserReportValidator.validationAlert(`Please enter the client's last name.`)
				UserReportValidator.scrollToRegion({ id: 'CLIENT_LAST_NAME', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}
		// Client medicaid number
		if (!reportData.clientInformation.medicaidNumber.skip && !reportData.clientInformation.medicaidNumber.value) {
			if (interactive) {
				UserReportValidator.validationAlert(`Please enter the client's medicaid number.`)
				UserReportValidator.scrollToRegion({ id: 'CLIENT_MEDICAID_NUM', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}

		// Plan goals
		if (!reportData.supportPlan.planGoals.skip && !reportData.supportPlan.planGoals.value) {
			if (interactive) {
				UserReportValidator.validationAlert(`Please enter the support plan goals for this client.`)
				UserReportValidator.scrollToRegion({ id: 'PLAN_GOALS', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}

		// Services Summary
		if (!reportData.summary.summaryOfService.skip && !reportData.summary.summaryOfService.value) {
			if (interactive) {
				UserReportValidator.validationAlert(`Please enter a summary for this service.`)
				UserReportValidator.scrollToRegion({ id: 'SUMMARY_SERVICES', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}

		// Outcome of Service
		if (!reportData.summary.outcomeOfService.skip && !reportData.summary.outcomeOfService.value) {
			if (interactive) {
				UserReportValidator.validationAlert(`Please enter the outcome for this service.`)
				UserReportValidator.scrollToRegion({ id: 'SUMMARY_OUTCOME', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}

		// Additional Notes
		// if (!reportData.summary.additionalNotes.skip && !reportData.summary.additionalNotes.value) {
		// 	if (interactive) {
		// 		UserReportValidator.validationAlert(`Please enter the support plan goals for this client.`)
		// 		UserReportValidator.scrollToRegion({ id: 'SUMMARY_SERVICES', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
		// 	}
		// 	return false
		// }

		// If nothing is missing then the report is valid
		return true
	}
}
