import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { Location } from '@angular/common'
import { log } from '@app/helpers'
import { DialogManager, EmployeeRecord, HelpDialogMessage, ManageProfileDialogData, PFPImageFile } from '@app/models'
import { CoreService } from '@app/services'
import { environment } from '@env/environment'

import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper'
import { v4 as uuid } from 'uuid'
import { Subject } from 'rxjs'

import S3 from 'aws-sdk/clients/s3'
import imageCompression from 'browser-image-compression'

@Component({
    selector: 'app-manage-profile',
    templateUrl: './manage-profile.component.html',
    styleUrls: ['./manage-profile.component.scss'],
    standalone: false
})
export class ManageProfileComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() dialogManager = new DialogManager()
	@Output() editActionComplete = new Subject<number>()

	currentView: 'EDIT' | 'VIEW' = 'EDIT'

	status = { isUploading: false, isComplete: false }

	imageFile: File = null
	// imageChangedEvent: any = ''
	croppedImage: any = ''
	canvasRotation = 0
	rotation = 0
	scale = 1
	showCropper = false
	containWithinAspectRatio = false
	transform: ImageTransform = {}

	companyId = 0
	bucket = environment.assetsBucket
	directory = 'profiles'

	pfpImageUrl = null

	emp: EmployeeRecord
	approved: boolean = false
	approvalStatusLabel: 'APPROVED' | 'REJECTED' | 'PENDING' = 'PENDING'

	constructor(
		private coreSrvc: CoreService,
		private cd: ChangeDetectorRef,
		private loc: Location,
	) {
		log('Profile Update Component Loading')

		// Setup company ID
		this.companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
	}

	// get approvalStatusLabel(): string {
	// 	if (this.pfpImageUrl && this.approved) return 'APPROVED'
	// 	if (this.pfpImageUrl && !this.approved) return 'PENDING'
	// 	return 'REJECTED'
	// }

	ngOnInit(): void {
		log('ngOnInit')
		// Reset status
		this.status = { isUploading: false, isComplete: false }

		// Setup existing picture
		const empId = (this.dialogManager.dialogData as ManageProfileDialogData).empId
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)
		this.emp = new EmployeeRecord(emp)

		const pfpImageFile = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId).getPfpImageFile()
		this.pfpImageUrl = pfpImageFile ? pfpImageFile.getPfpImageUrl() : '/assets/img/profile-placeholder-no-plus.jpg'

		// Link submit action
		this.dialogManager.submitBtnAction = () => this.submitBtnClicked()
		this.approved = this.emp.pfp_approved

		if (this.emp.pfp_json && this.emp.pfp_approved) {
			this.approvalStatusLabel = 'APPROVED'
		}
		if (this.emp.pfp_json && !this.emp.pfp_approved) {
			this.approvalStatusLabel = 'PENDING'
		}
	}

	get shouldShowSaveBtn(): boolean {
		return this.currentView === 'EDIT' && this.imageFile ? false : true
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.dialogManager.canSubmit = () => this.shouldShowSaveBtn
		}, 250)
	}

	ngOnDestroy(): void {
		this.dialogManager.canSubmit = () => true
	}

	setApprovalStatus(status: 'APPROVED' | 'REJECTED') {
		this.approvalStatusLabel = status
	}

	fileChangeEvent(event: any): void {
		log('fileChangeEvent', event)

		const file = event.target.files[0]
		log('Start compressing file', file)
		const options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 1920,
			exifOrientation: 1,
			useWebWorker: false,
		}

		log(`originalFile size ${file.size / 1024 / 1024} MB`)

		imageCompression(file, options)
			.then((compressedFile: File) => {
				log('Compressed File', file)
				log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`) // smaller than maxSizeMB

				this.imageFile = compressedFile
				this.showCropper = true
				this.cd.markForCheck()
			})
			.catch((error) => {
				log(error.message)
				alert('Error processing photo. Please notify your supervisor.')
			})

		// this.imageChangedEvent = event
	}

	// fileChangeEvent(event: any): void {
	// 	this.imageChangedEvent = event
	// 	this.approvalStatusLabel = 'APPROVED'
	// }

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64
		console.log(event, base64ToFile(event.base64))
	}

	imageLoaded() {
		this.showCropper = true
		console.log('Image loaded')
	}

	cropperReady(sourceImageDimensions: Dimensions) {
		console.log('Cropper ready', sourceImageDimensions)
	}

	loadImageFailed() {
		console.log('Load failed')
	}

	rotateLeft() {
		this.canvasRotation--
		this.flipAfterRotate()
	}

	rotateRight() {
		this.canvasRotation++
		this.flipAfterRotate()
	}

	private flipAfterRotate() {
		const flippedH = this.transform.flipH
		const flippedV = this.transform.flipV
		this.transform = {
			...this.transform,
			flipH: flippedV,
			flipV: flippedH,
		}
	}

	flipHorizontal() {
		this.transform = {
			...this.transform,
			flipH: !this.transform.flipH,
		}
	}

	flipVertical() {
		this.transform = {
			...this.transform,
			flipV: !this.transform.flipV,
		}
	}

	resetImage() {
		this.scale = 1
		this.rotation = 0
		this.canvasRotation = 0
		this.transform = {}
	}

	zoomOut() {
		this.scale -= 0.1
		this.transform = {
			...this.transform,
			scale: this.scale,
		}
	}

	zoomIn() {
		this.scale += 0.1
		this.transform = {
			...this.transform,
			scale: this.scale,
		}
	}

	toggleContainWithinAspectRatio() {
		this.containWithinAspectRatio = !this.containWithinAspectRatio
	}

	updateRotation() {
		this.transform = {
			...this.transform,
			rotate: this.rotation,
		}
	}

	public submitBtnClicked() {
		log('submitBtnClicked')
		this.status.isUploading = true

		// If we changed the image we need to go through upload flow, otherwise just update record
		if (this.imageFile) {
			this.uploadPhoto()
		} else {
			this.updateRecord(null)
		}
	}

	private uploadPhoto(): void {
		log('uploadPhoto')
		const fileId = uuid()

		if (!this.croppedImage) return
		const file = base64ToFile(this.croppedImage)

		// Setup Image file
		const newPfpImageFile = new PFPImageFile()
		newPfpImageFile.ts = Date.now()
		newPfpImageFile.bucket = environment.assetsBucket
		newPfpImageFile.key = `${this.companyId}/${this.directory}/${fileId}`
		newPfpImageFile.type = 'image/png'

		const params: any = {
			Metadata: {
				company_id: `${this.companyId}`,
			},
			Bucket: newPfpImageFile.bucket,
			Key: newPfpImageFile.key,
			ContentType: newPfpImageFile.type,
			Body: file,
		}

		log('Params', params)

		const s3 = new S3()
		s3.upload(params, (err, data) => {
			if (err) {
				log('Upload Error', err)
				this.handleUploadError()
			} else {
				this.updateRecord(newPfpImageFile)
			}
		})
		// .on('httpUploadProgress', (evt) => {
		// 	log('Upload progress')
		// })
	}

	public updateRecord(pfpImageFile: PFPImageFile) {
		const record = this.makeUpdateRecord(pfpImageFile)
		log('updateRecord', record)
		if (!record) return

		this.coreSrvc.dbSrvc.updateRecord('employee', record).then((success) => {
			log('updateRecordResponse', success)
			if (success) {
				this.editActionComplete.next(record.id)
			} else {
				this.status.isUploading = false
			}
		})
	}

	public makeUpdateRecord(pfpImageFile: PFPImageFile): EmployeeRecord {
		const emp = this.emp
		const record = new EmployeeRecord(emp)
		record.pfp_json = pfpImageFile ? JSON.stringify(pfpImageFile) : emp.pfp_json
		record.pfp_approved = this.approvalStatusLabel === 'APPROVED' ? true : false
		if (this.approvalStatusLabel === 'REJECTED') {
			record.pfp_json = null
		}

		return record
	}

	public cancelBtnClicked() {
		// this.loc.back()
	}

	private handleUploadError() {
		log('Error uploading file')
	}

	public handlePhotoLoadError() {
		this.pfpImageUrl = '/assets/images/profile-placeholder-no-plus.jpg'
	}

	public doneBtnClicked() {
		// this.coreSrvc.appSrvc.reloadApp()
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'pfp_details':
				help.header = 'Public Details'
				help.message =
					'This information will be publically available in various reports. Use this for certifications, specialities, or information you are comfortable sharing with others'
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
