<div class="card clc-card">
	<div>
		<div class="float-right">
			<i *ngIf="card.isSms" class="far fa-message clc-icon-message"></i>
			<i *ngIf="!card.isSms" class="far fa-phone clc-icon-message"></i>
			<div class="badge badge-pill badge-dark clc-badge clc-direction-badge" [class.clc-direction-in]="card.direction === 'IN'">
				{{ card.direction }}
			</div>
		</div>
		<div class="clc-date">
			<div>{{ card.clr.last_update | dateTzFormatter: timezone : dateFormat }}</div>
			<div>{{ card.clr.last_update | dateTzFormatter: timezone : timeFormat }}</div>
		</div>
		<div *ngIf="card.clr.call_source === 'VOICECHECKPOINT'">
			<div class="clc-voice-checkpoint">DISPATCHER CHECKPOINT</div>
		</div>
	</div>

	<div>
		<div *ngIf="card.clr.transcript" class="clc-transcript">
			<hr class="clc-hr-transcript" />
			<div>
				{{ card.isShowingFullTranscript ? transcriptFormatter(card.clr.transcript, true) : transcriptFormatter(card.clr.transcript, false) }}
			</div>
			<div
				*ngIf="(card.clr.transcript && card.clr.transcript.length >= 150) || card.audioFileUrl"
				class="clc-transcript-toggle link-text"
				(click)="card.isShowingFullTranscript = !card.isShowingFullTranscript"
			>
				{{ card.isShowingFullTranscript ? 'collapse transcript' : 'expand transcript' }}
			</div>
			<div *ngIf="card.isShowingFullTranscript && card.audioFileUrl" style="margin-top: 20px">
				<audio controls controlsList="nodownload" [src]="card.audioFileUrl" class="clc-audio-controls" (play)="audioFilePlayed()">
					Your browser does not support the
					<code>audio</code> element.
				</audio>
			</div>
		</div>
		<div *ngIf="card.clr.imageFiles.length > 0">
			<hr class="clc-hr-transcript" />
			<app-image-files-view [images]="card.clr.imageFiles" [timezone]="timezone"></app-image-files-view>
		</div>
		<div *ngIf="card.lastStatus" class="clc-last-status">
			<hr class="clc-hr-transcript" />
			<div>{{ card.lastStatus }}</div>
		</div>
	</div>

	<div class="clc-lookup-wrap">
		<hr class="clc-hr-lookup" />
		<div class="row">
			<div class="col-6">
				<div>
					<div class="clc-lookup-header">From</div>
					<div>{{ card.fromE164 | displayPhone }}</div>
					<div *ngIf="card.fromName">{{ card.fromName }}</div>
					<div class="clc-source">{{ card.fromSource }}</div>
					<div class="clc-call-btns">
						<i title="Place Call" class="far fa-phone-office c2c-ico link-text" (click)="setupEvent(card, 'FROM', 'CALL')"></i>
						<i title="Send Text" class="far fa-message-dots c2c-ico link-text" (click)="setupEvent(card, 'FROM', 'TEXT')"></i>
					</div>
				</div>
			</div>
			<div class="col-6">
				<div>
					<div class="clc-lookup-header">To</div>
					<div>{{ card.toE164 | displayPhone }}</div>
					<div *ngIf="card.toName">{{ card.toName }}</div>
					<div class="clc-source">{{ card.toSource }}</div>
					<div *ngIf="card.toE164" class="clc-call-btns">
						<i title="Place Call" class="far fa-phone-office c2c-ico link-text" (click)="setupEvent(card, 'TO', 'CALL')"></i>
						<i title="Send Text" class="far fa-message-dots c2c-ico link-text" (click)="setupEvent(card, 'TO', 'TEXT')"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
