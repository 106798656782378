import { log } from '@app/helpers/logger'
import { ChecklistFormItem, EmployeeChecklistData, EmployeeChecklistType, UserReportHelper } from './report-user'

export class EmployeeChecklistRecord {
	id: number
	type: EmployeeChecklistType
	name: string
	description: string
	title: string
	instructions: string
	required: boolean
	enabled: boolean
	employee_ids: Array<number> = []
	location_ids: Array<number> = []
	job_ids: Array<number> = []
	checklist_json: string

	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
		}
	}

	get formattedType(): string {
		return UserReportHelper.formatReportTypeLabel(this.type)
	}
}

export class EmployeeChecklist {
	id: number = null
	type: EmployeeChecklistType = 'CHECKOUT'
	name = ''
	description = ''
	title = ''
	instructions = ''
	required = false
	enabled = true
	employeeIds: Array<number> = []
	siteIds: Array<number> = []
	jobIds: Array<number> = []
	checklist: Array<ChecklistFormItem> = []

	originalRecord: EmployeeChecklistRecord

	constructor(record?: EmployeeChecklistRecord) {
		if (record) {
			this.originalRecord = record

			// Format various properties for internal use
			this.id = record.id
			this.type = record.type || 'CHECKOUT'
			this.name = record.name || ''
			this.description = record.description || ''
			this.title = record.title || ''
			this.instructions = record.instructions || ''
			this.required = record.required || false
			this.enabled = record.enabled || false
			this.employeeIds = record.employee_ids || []
			this.siteIds = record.location_ids || []
			this.jobIds = record.job_ids || []

			const parsedChecklist = record.checklist_json ? JSON.parse(record.checklist_json) : []
			this.checklist = parsedChecklist.map((cli) => new ChecklistFormItem(cli))
		}
	}

	get formattedType(): string {
		return UserReportHelper.formatReportTypeLabel(this.type)
	}

	buildUpdateRecord(): EmployeeChecklistRecord {
		const record = new EmployeeChecklistRecord()
		record.id = this.id
		record.type = this.type
		record.name = this.name
		record.description = this.description
		record.title = this.title
		record.instructions = this.instructions
		// If post-break then always require, otherwise use required property
		record.required = this.required ? true : false
		record.enabled = this.enabled
		record.employee_ids = [...this.employeeIds]
		record.location_ids = [...this.siteIds]
		record.job_ids = [...this.jobIds]
		record.checklist_json = JSON.stringify(this.checklist)

		return record
	}

	clone(): EmployeeChecklist {
		const record = this.buildUpdateRecord()
		const result = new EmployeeChecklist(record)
		result.originalRecord = this.originalRecord
		return result
	}

	// Used for code shared with emp app for report configuration
	makeEmployeeChecklistData(): EmployeeChecklistData {
		const data = new EmployeeChecklistData()
		data.type = this.type
		data.name = this.name
		data.title = this.title
		data.instructions = this.instructions
		data.required = this.required
		data.list = this.checklist ? [...this.checklist] : []
		return data
	}
}

export class EmployeeChecklistSearchFilter {
	filterText = ''
	empIds: Array<number> = []
	siteIds: Array<number> = []
	jobIds: Array<number> = []
}
