// Angular
import { NgModule, ModuleWithProviders } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
import { CommonModule } from '@angular/common'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'

// Full Calendar
import { FullCalendarModule } from '@fullcalendar/angular'

// HammerJs
import { HammerModule } from '@angular/platform-browser'

// Owl DateTime Picker
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker'

// PrimeNG
import { AutoCompleteModule } from 'primeng/autocomplete'
import { AutoFocusModule } from 'primeng/autofocus'
import { BadgeModule } from 'primeng/badge'
import { BlockUIModule } from 'primeng/blockui'
import { ChartModule } from 'primeng/chart'
import { CheckboxModule } from 'primeng/checkbox'
import { ContextMenuModule } from 'primeng/contextmenu'
import { DialogModule } from 'primeng/dialog'
import { DropdownModule } from 'primeng/dropdown'
import { EditorModule } from 'primeng/editor'
import { FileUploadModule } from 'primeng/fileupload'
import { ImageModule } from 'primeng/image'
import { MessageService } from 'primeng/api'
import { MultiSelectModule } from 'primeng/multiselect'
import { OrderListModule } from 'primeng/orderlist'
import { PasswordModule } from 'primeng/password'
import { PopoverModule } from 'primeng/popover'
import { RadioButtonModule } from 'primeng/radiobutton'
import { ScrollTopModule } from 'primeng/scrolltop'
import { ScrollerModule } from 'primeng/scroller'
import { SelectModule } from 'primeng/select'
import { TableModule } from 'primeng/table'
import { TimelineModule } from 'primeng/timeline'
import { ToastModule } from 'primeng/toast'
import { ToggleSwitchModule } from 'primeng/toggleswitch'
import { TooltipModule } from 'primeng/tooltip'

import { providePrimeNG } from 'primeng/config'
import Aura from '@primeng/themes/aura'
// import Lara from '@primeng/themes/lara'
// import Nora from '@primeng/themes/nora'

@NgModule({
	declarations: [],
	exports: [
		// Angular
		BrowserAnimationsModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,

		// Full Calendar
		FullCalendarModule,

		// HammerJs
		HammerModule,

		// Owl DateTime Picker
		OwlDateTimeModule,
		OwlNativeDateTimeModule,

		// PrimeNG
		AutoCompleteModule,
		AutoFocusModule,
		BadgeModule,
		BlockUIModule,
		ChartModule,
		CheckboxModule,
		ContextMenuModule,
		DialogModule,
		DropdownModule,
		EditorModule,
		FileUploadModule,
		ImageModule,
		MultiSelectModule,
		OrderListModule,
		PasswordModule,
		PopoverModule,
		RadioButtonModule,
		ScrollerModule,
		ScrollTopModule,
		SelectModule,
		TableModule,
		TimelineModule,
		ToastModule,
		ToggleSwitchModule,
		TooltipModule,
	],
	imports: [
		// Angular
		BrowserAnimationsModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,

		// Full Calendar
		FullCalendarModule,

		// HammerJS
		HammerModule,

		// Owl DateTime
		OwlDateTimeModule,
		OwlNativeDateTimeModule,

		// PrimeNg
		AutoCompleteModule,
		AutoFocusModule,
		BadgeModule,
		BlockUIModule,
		ChartModule,
		CheckboxModule,
		ContextMenuModule,
		DialogModule,
		DropdownModule,
		EditorModule,
		FileUploadModule,
		ImageModule,
		MultiSelectModule,
		OrderListModule,
		PasswordModule,
		PopoverModule,
		RadioButtonModule,
		ScrollerModule,
		ScrollTopModule,
		SelectModule,
		TableModule,
		TimelineModule,
		ToastModule,
		ToggleSwitchModule,
		TooltipModule,
	],
	providers: [
		provideHttpClient(withInterceptorsFromDi()),
		provideAnimationsAsync(),
		providePrimeNG({
			theme: {
				preset: Aura,
				options: {
					darkModeSelector: false || 'none',
				},
			},
		}),
	],
})
export class SharedModule {
	static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule,
			providers: [MessageService],
		}
	}
}
