<app-shift-report-table-filter-bar (filterUpdated)="fetchAndReload()"></app-shift-report-table-filter-bar>

<div class="section-table">
	<table id="incidentLogTable" class="stripe nowrap" cellspacing="0" width="100%"></table>
	<i id="clear-search-icon" (click)="clearSearch()" class="fa fa-times-circle-o" aria-hidden="true"></i>
	<app-table-footer-buttons
		[filename]="'employee_reports'"
		[newBtnLabel]="''"
		[exportBtnLabel]="'Export Records'"
		[table]="'incidentLogTable'"
		[marginTop]="'15px'"
	></app-table-footer-buttons>
</div>

<app-modal-dialog *ngIf="sectionPrefsDialogManager.isDialogVisible" [dialogManager]="sectionPrefsDialogManager">
	<app-local-prefs [dialogManager]="sectionPrefsDialogManager" (prefsUpdated)="prefsDataSaved()"></app-local-prefs>
</app-modal-dialog>

<app-modal-dialog *ngIf="reportsDialogManager.isDialogVisible" [dialogManager]="reportsDialogManager">
	<app-trans-reports-edit
		[dialogManager]="reportsDialogManager"
		[transId]="editReportAction.transId"
		[record]="editReportAction.record"
		[report]="editReportAction.report"
		[reportConfig]="editReportAction.reportConfig"
		(recordSaved)="editReportSaved()"
		(actionCancelled)="editReportCancelled()"
	>
	</app-trans-reports-edit>
</app-modal-dialog>

<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Link Options"
	[(visible)]="viewReportAction.isDialogVisible"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div>
		<div style="margin: 10px 0px">
			<div *ngIf="!viewReportAction.showConfirmation">
				<button class="btn btn-sm btn-block btn-outline-main btn-mb-8" (click)="viewReportBtnClicked()">View Report</button>
				<button class="btn btn-sm btn-block btn-outline-main btn-mb-8" (click)="copyPublicLinkBtnClicked()">Copy Public Link</button>
				<button class="btn btn-sm btn-block btn-outline-main" (click)="viewReportAction.showConfirmation = true">Refresh Public Link</button>
			</div>
			<div *ngIf="viewReportAction.showConfirmation">
				<div style="line-height: 1.2em; font-weight: 600">
					<span style="color: firebrick">Warning: </span>This will refresh the public link for this report and invalidate the original link.
					Public access to this report will only be available using the new link.
				</div>
				<div style="margin-top: 20px">
					<button class="btn btn-sm btn-outline-secondary" (click)="viewReportAction.showConfirmation = false">Cancel</button>
					<button class="btn btn-sm btn-outline-danger float-right" (click)="confirmRefreshBtnClicked()">Continue</button>
				</div>
			</div>
		</div>
	</div>
</p-dialog>

<app-delete-record-dialog
	*ngIf="deleteAction.showDialog"
	[showDeleteRecordDialog]="deleteAction.showDialog"
	[tableName]="deleteAction.tableName"
	[recordId]="deleteAction.recordId"
	[recordLabel]="deleteAction.recordLabel"
	(cancelDelete)="deleteActionCancel()"
	(actionComplete)="deleteActionComplete()"
>
</app-delete-record-dialog>
