<div *ngIf="showNoPersonalReportsNotice" class="alert alert-info alert-slim all-reports-banner">
	No personal or shared reports found. To see the full list, select <strong>All Reports</strong>.
</div>

<table id="reportsTable" class="stripe nowrap" cellspacing="0" width="100%" [class.search-active]="true"></table>
<app-table-footer-buttons [marginTop]="'15px'" [newBtnLabel]="'Add Report'" (newBtnClicked)="createRecord()"></app-table-footer-buttons>
<i id="clear-search-icon" (click)="clearSearch()" class="fa fa-times-circle-o clear-search-icon rep-clear-search-icon" aria-hidden="true"></i>

<app-delete-record-dialog
	*ngIf="deleteAction.showDeleteRecordDialog"
	[showDeleteRecordDialog]="deleteAction.showDeleteRecordDialog"
	[tableName]="deleteAction.tableName"
	[recordId]="deleteAction.recordId"
	[recordLabel]="deleteAction.recordLabel"
	(cancelDelete)="deleteActionCancel()"
	(actionComplete)="deleteActionComplete()"
></app-delete-record-dialog>

<app-basic-modal
	modalId="repEditModal"
	[(visible)]="editAction.showDialog"
	[helpVidKey]="'reportEditDialog'"
	[preventBackgroundClose]="true"
	[modalHeader]="editAction.header"
	[modalFooter]="editAction.footer"
	[modalWidth]="450"
	[saveTable]="'reports'"
>
	<div *ngIf="editAction.showDialog">
		<app-insight-report-edit [action]="editAction.action" [reportId]="editAction.recordId"></app-insight-report-edit>
	</div>
</app-basic-modal>

<p-dialog
	styleClass="p-dialog-noheader"
	[(visible)]="isRunningReport"
	[modal]="false"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[baseZIndex]="5000"
	[dismissableMask]="true"
	[transitionOptions]="'0ms'"
>
	<div class="tts-default-font" style="text-align: center; color: #566978; font-weight: bold">
		<i class="fas fa-loader fa-spin"></i> Running Report...
	</div>
</p-dialog>
