import { environment } from '@env/environment'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { DatabaseService, UserLoginService, UserSessionService } from '@app/services'

import { log } from '@app/helpers'
@Component({
    selector: 'app-adpdeveloper-login',
    templateUrl: './adp-login.component.html',
    styleUrls: ['./adp-login.component.scss'],
    standalone: false
})
export class ADPMarketplaceLoginComponent implements OnInit {
	// ADP
	region = environment.region
	adpLoginRole = environment.adpLoginRole

	user = ''
	token = ''

	loginFailed = false

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private dbSrvc: DatabaseService,
		private loginSrvc: UserLoginService,
		private sessionSrvc: UserSessionService,
	) {}

	ngOnInit() {
		const adpSession = this.sessionSrvc.getAdpSession()
		this.activatedRoute.queryParams.subscribe((params: any) => {
			log('Url Parameters', params)
			const user = params.user ? params.user : adpSession?.username
			const token = params.token ? params.token : adpSession?.token
			if (user && token) {
				this.loginSrvc.logout()
				this.user = user
				this.token = token
				this.setupAwsCredentials(user, token)
			} else {
				this.loginFailed = true
			}
		})
	}

	loadAllData() {
		this.dbSrvc.bulkRead(['company', 'users']).then((result) => {
			const company = this.dbSrvc.settingSrvc.getCompany()
			if (!company) {
				this.router.navigate(['/home/not-provisioned'])
			} else {
				// Setup auto logout and navigate to dashboard
				log('Routing to dashboard')
				this.router.navigate(['/admin/dashboard'])
			}
		})
	}

	authBtnClicked() {
		this.setupAwsCredentials(this.user, this.token)
	}

	// clearCognito() {
	// 	const credentials = AWS.config.credentials as any
	// 	log('Credentials', credentials)
	// 	if (credentials && credentials.clearCachedId) {
	// 		credentials.clearCachedId()
	// 	}
	// }

	setupAwsCredentials(user: string, token: string) {
		this.sessionSrvc.authenticateAdpUser(user, token).then((success) => {
			log('Setup Credentials Result', success)
			if (success) {
				this.loadAllData()
			} else {
				this.loginFailed = true
			}
		})

		// this.sessionSrvc.clearCognitoCredentials()
		// AWS.config.update({ region: 'us-east-1' })
		// const params = {
		// 	RoleArn: this.adpDeveloperRole,
		// 	RoleSessionName: user, /* required */
		// 	WebIdentityToken: token /* required */
		// }

		// const credentials = new AWS.WebIdentityCredentials(params)
		// console.log(credentials)

		// // AWS.config.credentials = credentials
		// AWS.config.update({ credentials: credentials })

		// AWS.config.getCredentials(err => {
		// 	if (err) {
		// 		console.log(err, err.stack) // an error occurred
		// 		console.log('Unable to Obtain AWS IAM Temporary Credentials with Web Token')
		// 		this.loginFailed = true
		// 	} else {
		// 		console.log('Got credentials')
		// 		const updatedCreds = AWS.config.credentials
		// 		console.log('Updated Creds', updatedCreds)
		// 		this.setupAdpUserSession(updatedCreds)
		// 		this.loadAllData()
		// 	}
		// })
	}

	// setupAdpUserSession(credentials: any) {
	// 	const data = credentials.data
	// 	const cognitoId = data.SubjectFromWebIdentityToken
	// 	this.sessionSrvc.setAdpSession(this.user, this.token, cognitoId)
	// }

	// getToken() {
	// 	// this.http.get('https://adp-api.tts-stage.com/oauth2/test').subscribe(result => {
	// 	// 	console.log('Result', result)
	// 	// })
	// 	window.open('https://adp-api.tts-stage.com/oauth2/test', '_blank')
	// }

	readTestBtnClicked() {
		this.dbSrvc.readTable('employee').then((result) => {
			log('Read Result', result)
		})
	}

	loginViaAdp() {
		alert('This login method is not yet available.')
	}
}
