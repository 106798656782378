import { Component, OnInit, Input, AfterViewInit, ViewChild, EventEmitter, Output } from '@angular/core'
import { DeviceDetectorService } from 'ngx-device-detector'

import { log } from '@app/helpers'

import { v4 as uuid } from 'uuid'

@Component({
    selector: 'app-edit-map-marker',
    templateUrl: './edit-map-marker.component.html',
    styleUrls: ['./edit-map-marker.component.scss'],
    standalone: false
})
export class EditMapMarkerComponent implements OnInit, AfterViewInit {
	@ViewChild('gmap', { static: true }) gmapElement: any
	map: google.maps.Map
	mapId = 'checkpointMap-' + uuid()

	@Input() latitude: number
	@Input() longitude: number
	@Input() panAferSetDelay = 750
	@Input() showClearLink = false
	@Output() gpsUpdated = new EventEmitter<any>()

	enableSatelliteView = false
	enableMouseZoom = true
	showZoomCheckbox = false

	private gpsLocationMarker: google.maps.marker.AdvancedMarkerElement

	constructor(private deviceDetect: DeviceDetectorService) {}

	ngOnInit() {
		// if (this.deviceDetect.isDesktop()) { this.showZoomCheckbox = true }
	}

	ngAfterViewInit() {
		log('Job Site Marker init with lat/long', this.latitude, this.longitude)
		setTimeout(() => {
			this.initMap()
			this.setupInitialMarker()
		}, 300)
	}

	initMap() {
		const styles: any = [
			{
				featureType: 'poi',
				elementType: 'labels.text',
				stylers: [
					{
						visibility: 'off',
					},
				],
			},
			{
				featureType: 'poi.business',
				stylers: [
					{
						visibility: 'off',
					},
				],
			},
			{
				featureType: 'transit',
				stylers: [
					{
						visibility: 'off',
					},
				],
			},
		]
		const mapProp = {
			mapId: this.mapId,
			gestureHandling: 'greedy',
			center: this.latitude && this.longitude ? new google.maps.LatLng(this.latitude, this.longitude) : new google.maps.LatLng(0, 0),
			zoom: this.calculateZoom(),
			// styles: styles,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			streetViewControl: false,
			zoomControl: true, // !this.showZoomCheckbox,
			mapTypeControl: false,
			scrollWheel: true, // false when using zoom checkbox
		}
		// if (this.latitude && this.longitude) {
		// 	mapProp['center'] = new google.maps.LatLng(this.latitude, this.longitude)
		// }
		this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp)

		this.map.addListener('click', (event) => {
			log('Event', event)
			this.closeAllInfoWindows()
			this.removeGpsLocationMarker()
			this.placeMarker(event.latLng)
		})
		this.map.setOptions({ scrollwheel: true }) // false when using zoom checkbox
	}

	setupInitialMarker() {
		if (this.latitude && this.longitude) {
			const latLng = new google.maps.LatLng(this.latitude, this.longitude)
			this.placeMarker(latLng)
			// const marker = new google.maps.Marker({
			// 	position: latLng,
			// 	map: this.map,
			// 	draggable: true,
			// 	title: 'Location Marker',
			// })
			// this.gpsLocationMarker = marker
		}
	}

	removeGpsLocationMarker() {
		if (this.gpsLocationMarker) {
			this.gpsLocationMarker.map = null
		}
	}

	clearMarker() {
		this.removeGpsLocationMarker()
		this.gpsUpdated.emit(null)
	}

	setMouseZoom() {
		if (this.enableMouseZoom) {
			this.map.setOptions({ scrollwheel: true })
		} else {
			this.map.setOptions({ scrollwheel: false })
		}
	}

	setSatelliteView() {
		if (this.enableSatelliteView) {
			this.map.setOptions({ mapTypeId: google.maps.MapTypeId.HYBRID })
		} else {
			this.map.setOptions({ mapTypeId: google.maps.MapTypeId.ROADMAP })
		}
	}

	calculateZoom() {
		if (this.latitude && this.longitude) {
			return 17
		}
		return 0
	}

	closeAllInfoWindows() {}

	placeMarker(location: google.maps.LatLng) {
		const pinImg = document.createElement('img')
		pinImg.src = '/assets/img/marker-red.png'

		const marker = new google.maps.marker.AdvancedMarkerElement({
			position: location,
			gmpDraggable: true,
			title: 'Location Marker',
			content: pinImg,
			map: this.map,
		})
		marker.addListener('dragend', (event) => {
			this.gpsLocationMarker = marker
			this.panToMarker(marker)
			const position = { latitude: marker.position.lat, longitude: marker.position.lng }
			this.gpsUpdated.emit(position)
		})
		this.gpsLocationMarker = marker
		this.panToMarker(marker)
		const position = { latitude: marker.position.lat, longitude: marker.position.lng }
		this.gpsUpdated.emit(position)
		// this.map.panTo(location)
	}

	panToMarker(marker: google.maps.marker.AdvancedMarkerElement) {
		if (this.panAferSetDelay) {
			setTimeout(() => {
				this.map.panTo(marker.position)
			}, this.panAferSetDelay)
		}
	}

	updatePosition(lat: number, lng: number) {
		const latLng = new google.maps.LatLng(lat, lng)
		this.closeAllInfoWindows()
		this.removeGpsLocationMarker()
		this.clearMarker()
		this.map.setZoom(17)
		this.map.setCenter(latLng)
		this.placeMarker(latLng)
		this.gpsUpdated.emit({ latitude: lat, longitude: lng })
	}

	// https://developers.google.com/maps/documentation/javascript/maxzoom
}
