export enum TrackCode {
	authLink = 'AL',
	appComponent = 'AC',
	bulletinBoard = 'BB',
	checkIn = 'CI',
	checkOut = 'CO',
	checkpoint = 'CP',
	checklist = 'CC',
	companySelector = 'CS',
	enrollment = 'EN',
	gpsShare = 'GS',
	home = 'HM',
	jobs = 'JB',
	jobSites = 'JS',
	manager = 'MG',
	picker = 'PK',
	pickerCode = 'PC',
	pickerSite = 'PS',
	pickerTravel = 'PT',
	profile = 'PF',
	register = 'RG',
	reports = 'RP',
	settings = 'ST',
	shiftHistory = 'SH',
	shiftSummary = 'SS',
	support = 'SP',
	userOpenShifts = 'OS',
	userTimecard = 'TC',
	userTimeOff = 'TO',
	userSchedule = 'MS',
	waypointPicker = 'WP',
}

export class TrackCodeLabel {
	AL = 'Auth Link'
	AC = 'App Component'
	BB = 'Bulletin Board'
	CI = 'Check In'
	CO = 'Check Out'
	CP = 'Checkpoint'
	CC = 'Checklist'
	CS = 'Company Selector'
	EN = 'Enrollment'
	GS = 'GPS Share'
	HM = 'Home'
	JB = 'Jobs'
	JS = 'Job Sites'
	MG = 'Manager'
	PK = 'Picker'
	PC = 'Code Picker'
	PS = 'Site Picker'
	PT = 'Travel Picker'
	PF = 'Profile'
	RG = 'Register'
	RP = 'Reports'
	ST = 'Settings'
	SH = 'Shift History'
	SS = 'Shift Summary'
	SP = 'Support'
	OS = 'Open Shifts'
	TC = 'Time Card'
	TO = 'Time Off'
	MS = 'My Schedule'
	WP = 'Waypoint Picker'

	lookup(key: string) {
		const value = this[key]
		return value ? value : 'Unknown Route'
	}
}
