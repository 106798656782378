<p-dialog
	#clickToCallGlobalEventDialog
	styleClass="p-dialog-noheader"
	[header]="title"
	[(visible)]="showDialog"
	[modal]="true"
	[showHeader]="false"
	[dismissableMask]="false"
	[focusOnShow]="true"
	transitionOptions="0ms"
>
	<div class="cs-dialog-wrap">
		<div class="cs-title">Contact Support</div>
		<textarea
			class="form-control options-textarea"
			[(ngModel)]="message"
			placeholder="Please describe the issue with this record. The more details you provide, the quicker we can route your request to the appropriate team member."
		></textarea>
		<div style="margin-top: 18px">
			<div class="float-right" style="margin-left: 28px">
				<button class="btn btn-sm btn-secondary" style="margin-right: 8px" (click)="cancelBtnClicked()">Cancel</button>
				<button class="btn btn-sm btn-success" [disabled]="!message" (click)="submitBtnClicked()">Continue</button>
			</div>
			<div *ngIf="!message" class="cs-desc-required">Description Required</div>
		</div>
	</div>
</p-dialog>
