<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<form
	novalidate
	autocomplete="off"
	[hidden]="isUpdating"
	class="form-horizontal"
	[formGroup]="profileForm"
	(keydown.escape)="$event.preventDefault()"
	(keydown.enter)="$event.preventDefault()"
>
	<div id="notify-settings">
		<div *ngIf="false">
			<strong class="notify-header">Notification Settings</strong>
			<div class="notify-note">
				<div style="margin-top: 10px">
					<div class="alert alert-warning">
						<strong>Notifications are only active when you schedule jobs using the scheduler, otherwise these settings have no effect.</strong>
					</div>
				</div>
				<!-- <div style="margin-top:10px">Default settings for notifications can be modified in the <strong>Account Defaults</strong> section available in the <strong>Admin</strong> menu. To disable repeating notifications, leave the corresponding fields blank.</div> -->
			</div>
		</div>

		<div>
			<div class="options-block" [class.input-required]="!profileForm.get('name').value">
				<div class="options-title-inline">Name</div>
				<span *ngIf="!profileForm.get('name').value" class="float-right">(required)</span>
				<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('name')"></i> -->
				<input type="text" class="form-control" formControlName="name" />
			</div>
		</div>

		<div class="options-block">
			<div class="options-title">Linked Jobs</div>
			<app-multi-select-details
				[type]="'JOB'"
				placeholder="Select Jobs"
				selectedItemsLabel="Jobs Selected: {0}"
				[options]="linkedJobOptions"
				[model]="profileForm.get('job_ids').value"
				(modelChange)="linkedJobsChanged($event)"
			>
			</app-multi-select-details>
		</div>

		<div *ngIf="alert.linkedJobsCount > 1" style="margin-top: 10px">
			<div class="alert alert-danger">This notification profile is linked to multiple jobs and any changes will affect all linked jobs.</div>
		</div>

		<!-- <div *ngIf="alert.linkedJobsCount === 1" style="margin-top:10px">
			<div class="alert alert-warning">Editting a profile that is linked to multiple jobs will affect notifications for all linked jobs.</div>
		</div> -->

		<div style="margin-top: 25px; margin-bottom: 25px">
			<div class="options-section-header">Notify Employee When:</div>
		</div>

		<!-- Employe is late checking in -->
		<div *ngIf="showNotifyEmpOnUpcomingShift" class="notify-block">
			<div>
				<div class="notify-type">Employee has an upcoming shift</div>
			</div>
			<div class="row" style="margin-top: 10px">
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox formControlName="call_employee_preshift" [binary]="true" (onChange)="clearInvalidInputsIfNotRequired()"></p-checkbox>
						<strong (click)="toggleCheckbox('call_employee_preshift')" class="has-pointer">Call</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox formControlName="sms_employee_preshift" [binary]="true" (onChange)="clearInvalidInputsIfNotRequired()"></p-checkbox>
						<strong (click)="toggleCheckbox('sms_employee_preshift')" class="has-pointer">Text</strong>
					</div>
				</div>
				<div class="col-4"></div>
			</div>
			<div *ngIf="profileForm.get('call_employee_preshift').value || profileForm.get('sms_employee_preshift').value" style="margin-bottom: 25px">
				<div style="display: inline-block; width: 150px; margin-right: 8px">
					<p-select
						appScrollLockSelect
						scrollHeight="220px"
						[options]="preShiftNotifyOptions"
						formControlName="emp_notification_preshift_interval"
					></p-select>
				</div>
				<div class="form-label-bold" style="display: inline-block">before shift starts</div>
			</div>
			<hr class="sub-divider" />
		</div>

		<!-- Employe is late checking in -->
		<div class="notify-block">
			<div>
				<div class="notify-type">Employee is late checking in</div>
			</div>
			<div class="row" style="margin-top: 10px">
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox formControlName="call_employee_late_in" [binary]="true" (onChange)="clearInvalidInputsIfNotRequired()"></p-checkbox>
						<strong (click)="toggleCheckbox('call_employee_late_in')" class="has-pointer">Call</strong>
					</div>
				</div>
				<div class="col-4"></div>
				<div class="col-4"></div>
			</div>
			<div *ngIf="profileForm.get('call_employee_late_in').value" style="margin-bottom: 25px">
				<div class="form-label-bold">
					<span class="mr-8">when they are</span>
					<input
						type="tel"
						class="form-control form-inline notify-input mr-8"
						formControlName="emp_notification_delay_late_in"
						(change)="validateNumericInput('emp_notification_delay_late_in')"
						(focus)="selectOnFocus($event)"
					/>
					<span class="mr-8">min late</span>
					<!-- Conditional block for repeating notification -->
					<div
						class="d-inline"
						*ngIf="
							showRepeatingInputs ||
							profileForm.get('emp_notification_interval_late_in').value ||
							profileForm.get('emp_notification_duration_late_in').value
						"
					>
						<span class="mr-8">and repeat notification every</span>
						<input
							type="tel"
							class="form-control form-inline notify-input mr-8"
							formControlName="emp_notification_interval_late_in"
							(change)="validateNumericInput('emp_notification_interval_late_in')"
							(focus)="selectOnFocus($event)"
						/>
						<span class="mr-8">min for</span>
						<input
							type="tel"
							class="form-control form-inline notify-input mr-8"
							formControlName="emp_notification_duration_late_in"
							(change)="validateNumericInput('emp_notification_duration_late_in')"
							(focus)="selectOnFocus($event)"
						/>
						<span class="mr-8">min or until employee checks in</span>
					</div>
				</div>
			</div>
			<hr class="sub-divider" />
		</div>

		<!-- Employe is late checking out -->
		<div class="notify-block">
			<div>
				<div class="notify-type">Employee is late checking out</div>
			</div>
			<div class="row" style="margin-top: 10px">
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox formControlName="call_employee_late_out" [binary]="true" (onChange)="clearInvalidInputsIfNotRequired()"></p-checkbox>
						<strong (click)="toggleCheckbox('call_employee_late_out')" class="has-pointer">Call</strong>
					</div>
				</div>
				<div class="col-4"></div>
				<div class="col-4"></div>
			</div>
			<div *ngIf="profileForm.get('call_employee_late_out').value" style="margin-bottom: 25px">
				<div class="form-label-bold">
					when they are
					<input
						type="tel"
						class="form-control form-inline notify-input"
						formControlName="emp_notification_delay_late_out"
						(change)="validateNumericInput('emp_notification_delay_late_out')"
						(focus)="selectOnFocus($event)"
					/>
					min late
					<!-- Conditional block for repeating notification -->
					<span
						*ngIf="
							showRepeatingInputs ||
							profileForm.get('emp_notification_interval_late_out').value ||
							profileForm.get('emp_notification_duration_late_out').value
						"
					>
						and repeat notification every
						<input
							type="tel"
							class="form-control form-inline notify-input"
							formControlName="emp_notification_interval_late_out"
							(change)="validateNumericInput('emp_notification_interval_late_out')"
							(focus)="selectOnFocus($event)"
						/>
						min for
						<input
							type="tel"
							class="form-control form-inline notify-input"
							formControlName="emp_notification_duration_late_out"
							(change)="validateNumericInput('emp_notification_duration_late_out')"
							(focus)="selectOnFocus($event)"
						/>
						min or until employee checks out
					</span>
				</div>
			</div>
			<hr class="sub-divider" />
		</div>

		<!-- Employe is late checking out -->
		<div class="notify-block">
			<div>
				<div class="notify-type">Employee is late submitting checkpoint</div>
			</div>
			<div class="row" style="margin-top: 10px">
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox formControlName="call_employee_checkpoint" [binary]="true" (onChange)="clearInvalidInputsIfNotRequired()"></p-checkbox>
						<strong (click)="toggleCheckbox('call_employee_checkpoint')" class="has-pointer">Call</strong>
					</div>
				</div>
				<div class="col-4"></div>
				<div class="col-4"></div>
			</div>
			<div *ngIf="profileForm.get('call_employee_checkpoint').value" style="margin-bottom: 25px">
				<div class="form-row align-items-center">
					<div class="form-label-bold checkpoint-note">
						when they are
						<input
							type="tel"
							class="form-control form-inline notify-input"
							formControlName="emp_notification_delay_checkpoint"
							(change)="validateNumericInput('emp_notification_delay_checkpoint')"
							(focus)="selectOnFocus($event)"
						/>
						min late for their checkpoint
						<!-- interval of
						<input
							type="tel"
							class="form-control form-inline notify-input"
							formControlName="checkpoint_frequency"
							(change)="validateNumericInput('checkpoint_frequency')"
						/>
						min -->
						<div class="alert alert-warning" style="margin-top: 15px; font-weight: 500">
							Repeating checkpoint intervals are now managed through the <strong class="tts-hl-2">Chkpts</strong> column in the Jobs table.
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- CLIENT NOTIFICATIONS BLOCK -->
		<div *ngIf="showClientOptions">
			<hr class="section-divider" />
			<div style="margin-top: 25px">
				<div class="options-section-header">Notify Client When:</div>
			</div>

			<!-- Employee submits an incident report -->

			<div style="margin-top: 25px">
				<div>
					<div class="notify-type">Employee submits either an incident report or a vehicle inspection report</div>
				</div>
				<div class="row" style="margin-top: 10px">
					<div class="col-4">
						<div class="notify-checkbox">
							<p-checkbox
								formControlName="call_client_on_incident"
								[binary]="true"
								(onChange)="clearInvalidInputsIfNotRequired()"
							></p-checkbox>
							<strong (click)="toggleCheckbox('call_client_on_incident')" class="has-pointer">Call</strong>
						</div>
					</div>
					<div class="col-4">
						<div class="notify-checkbox">
							<p-checkbox formControlName="sms_client_on_incident" [binary]="true" (onChange)="clearInvalidInputsIfNotRequired()"></p-checkbox>
							<strong (click)="toggleCheckbox('sms_client_on_incident')" class="has-pointer">Text</strong>
						</div>
					</div>
					<div class="col-4">
						<div class="notify-checkbox">
							<p-checkbox
								formControlName="email_client_on_incident"
								[binary]="true"
								(onChange)="clearInvalidInputsIfNotRequired()"
							></p-checkbox>
							<strong (click)="toggleCheckbox('email_client_on_incident')" class="has-pointer">Email</strong>
						</div>
					</div>
				</div>
			</div>

			<!-- NEWBLOCK -->

			<div *ngIf="isClientPhoneNumberRequired || isClientEmailRequired" class="form-group form-group-lg delay-notification-block">
				<div style="margin: 15px 0px">
					<strong class="tts-hl-2">Client Notification Information</strong>
				</div>

				<div *ngIf="isClientPhoneNumberRequired && clientPhoneNumbers.length > 0">
					<div style="margin-bottom: 10px"><strong>Current Client Numbers</strong></div>
					<div class="btn btn-sm btn-block btn-phone" *ngFor="let entry of clientPhoneNumbers; let idx = index">
						<div>
							{{ entry.number | displayPhone }}
							<i (click)="removePhone('client', idx)" class="fa fa-times float-right btn-phone-x" aria-hidden="true"></i>
						</div>
						<div *ngIf="doesPhoneEntryResolve('client', idx)" class="text-muted">
							<small class="sup-name">{{ entry.name }}</small>
						</div>
					</div>
				</div>

				<div style="margin-top: 20px" *ngIf="isClientPhoneNumberRequired" class="row input-horizontal-row">
					<div class="col-12">
						<div style="margin-bottom: 8px">
							<strong>Client Phone</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('clientPhone')"></i>
							<span *ngIf="profileForm.get('client_phone_e164').value && !clientPhoneValid" class="input-required float-right"
								>(not valid)</span
							>
						</div>

						<div class="form-group">
							<table width="100%">
								<tr>
									<td style="width: 80px">
										<select
											class="form-control"
											formControlName="clientDialingCode"
											(change)="formatPhone('clientDialingCode', 'client_phone_e164')"
										>
											<option *ngFor="let code of countryCodeData" value="{{ code.id }}">
												{{ code.id }}
											</option>
										</select>
									</td>
									<td>&nbsp;&nbsp;</td>
									<td style="max-width: 275px">
										<input
											(keyup)="formatPhone('clientDialingCode', 'client_phone_e164')"
											class="form-control"
											[class.is-valid]="clientPhoneValid"
											[class.ng-invalid]="profileForm.value.client_phone_e164 && !clientPhoneValid"
											formControlName="client_phone_e164"
											type="tel"
											autocomplete="off"
										/>
									</td>
								</tr>
							</table>
						</div>

						<button class="btn btn-sm btn-block btn-success" [disabled]="!clientPhoneValid" (click)="addClientPhone()">
							Add Another Number
						</button>
					</div>
				</div>

				<div style="margin-top: 15px" *ngIf="isClientEmailRequired">
					<div>
						<strong>Current Client Email{{ clientEmails.length > 1 ? 's' : '' }}</strong>
					</div>

					<div style="margin-top: 10px">
						<div>
							<div class="btn btn-sm btn-block btn-email" *ngFor="let clientEmail of clientEmails; let idx = index">
								<div>
									{{ clientEmail.email }}
									<i (click)="removeEmail('client', idx)" class="fa fa-times float-right btn-email-x" aria-hidden="true"></i>
								</div>
								<div *ngIf="clientEmail.isClient" class="text-muted">
									<small class="sup-name">{{ clientEmail.name }}</small>
								</div>
							</div>
						</div>
					</div>

					<div class="form-group" style="margin-top: 20px">
						<table width="100%">
							<tr>
								<td>
									<input
										#newClientEmail
										[(ngModel)]="newClientEmailInput"
										placeholder="enter email address"
										(focus)="newClientEmail.placeholder = ''"
										(blur)="newClientEmail.placeholder = 'enter email address'"
										[ngModelOptions]="{ standalone: true }"
										id="newClientEmail"
										class="form-control"
										type="text"
										autocapitalize="off"
										autocomplete="off"
									/>
								</td>
								<td>&nbsp;</td>
								<td style="text-align: right">
									<button
										(click)="addClientEmail(true)"
										(keyup.enter)="addClientEmail(true)"
										[disabled]="!isEmailInputValid('client')"
										class="btn btn-sm btn-success"
									>
										Add
									</button>
								</td>
							</tr>
						</table>
						<div *ngIf="newClientEmailInput && !isEmailInputValid('client')" style="color: firebrick; margin-top: 8px; font-weight: bold">
							Not a valid email address
						</div>
						<div *ngIf="newClientEmailInput && isEmailInputValid('client')" style="color: green; margin-top: 8px">
							Click <strong>Add</strong> to include another address
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- CLIENT NOTIFICATIONS BLOCK -->

		<hr class="section-divider" />

		<div style="margin-top: 25px; margin-bottom: 25px">
			<div class="options-section-header">Notify Supervisor When:</div>
		</div>

		<!-- Supervisor Early Check In -->

		<div *ngIf="showNotifySupOnEmpEarlyCheckin" class="notify-block">
			<div>
				<div class="notify-type">Employee checks in early</div>
			</div>
			<div class="row" style="margin-top: 10px">
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox formControlName="call_supervisor_early_in" [binary]="true" (onChange)="clearInvalidInputsIfNotRequired()"></p-checkbox>
						<strong (click)="toggleCheckbox('call_supervisor_early_in')" class="has-pointer">Call</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox formControlName="sms_supervisor_early_in" [binary]="true" (onChange)="clearInvalidInputsIfNotRequired()"></p-checkbox>
						<strong (click)="toggleCheckbox('sms_supervisor_early_in')" class="has-pointer">Text</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox formControlName="email_supervisor_early_in" [binary]="true" (onChange)="clearInvalidInputsIfNotRequired()"></p-checkbox>
						<strong (click)="toggleCheckbox('email_supervisor_early_in')" class="has-pointer">Email</strong>
					</div>
				</div>
			</div>
			<div
				*ngIf="
					profileForm.value.call_supervisor_early_in || profileForm.value.sms_supervisor_early_in || profileForm.value.email_supervisor_early_in
				"
				style="margin-bottom: 25px"
			>
				<div class="form-row align-items-center">
					<div class="col-auto">
						<div class="form-label-bold">
							if check in is
							<input
								type="tel"
								class="form-control form-inline notify-input"
								formControlName="sup_notification_early_in_min_time"
								(change)="validateNumericInput('sup_notification_early_in_min_time')"
								(focus)="selectOnFocus($event)"
							/>
							or more min early
						</div>
					</div>
				</div>
			</div>
			<hr class="sub-divider" />
		</div>

		<!-- Supervisor At Check In -->

		<div *ngIf="showNotifySupOnEmpCheckin" class="notify-block">
			<div>
				<div class="notify-type">Employee checks in</div>
			</div>
			<div class="row" style="margin-top: 10px">
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox
							formControlName="call_supervisor_at_check_in"
							[binary]="true"
							(onChange)="clearInvalidInputsIfNotRequired()"
						></p-checkbox>
						<strong (click)="toggleCheckbox('call_supervisor_at_check_in')" class="has-pointer">Call</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox
							formControlName="sms_supervisor_at_check_in"
							[binary]="true"
							(onChange)="clearInvalidInputsIfNotRequired()"
						></p-checkbox>
						<strong (click)="toggleCheckbox('sms_supervisor_at_check_in')" class="has-pointer">Text</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox
							formControlName="email_supervisor_at_check_in"
							[binary]="true"
							(onChange)="clearInvalidInputsIfNotRequired()"
						></p-checkbox>
						<strong (click)="toggleCheckbox('email_supervisor_at_check_in')" class="has-pointer">Email</strong>
					</div>
				</div>
			</div>
			<hr class="sub-divider" />
		</div>

		<!-- Supervisor Late Check In -->

		<div class="notify-block">
			<div>
				<div class="notify-type">Employee checks in late</div>
			</div>
			<div class="row" style="margin-top: 10px">
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox formControlName="call_supervisor_late_in" [binary]="true" (onChange)="clearInvalidInputsIfNotRequired()"></p-checkbox>
						<strong (click)="toggleCheckbox('call_supervisor_late_in')" class="has-pointer">Call</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox formControlName="sms_supervisor_late_in" [binary]="true" (onChange)="clearInvalidInputsIfNotRequired()"></p-checkbox>
						<strong (click)="toggleCheckbox('sms_supervisor_late_in')" class="has-pointer">Text</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox formControlName="email_supervisor_late_in" [binary]="true" (onChange)="clearInvalidInputsIfNotRequired()"></p-checkbox>
						<strong (click)="toggleCheckbox('email_supervisor_late_in')" class="has-pointer">Email</strong>
					</div>
				</div>
			</div>
			<div
				*ngIf="
					profileForm.value.call_supervisor_late_in || profileForm.value.email_supervisor_late_in || profileForm.value.sms_supervisor_late_in
				"
				style="margin-bottom: 25px"
			>
				<div class="form-label-bold">
					when employee is
					<input
						type="tel"
						class="form-control form-inline notify-input"
						formControlName="sup_notification_delay_late_in"
						(change)="validateNumericInput('sup_notification_delay_late_in')"
						(focus)="selectOnFocus($event)"
					/>
					min late
					<!-- Conditional block for repeating notification -->
					<span
						*ngIf="
							showRepeatingInputs ||
							profileForm.get('sup_notification_interval_late_in').value ||
							profileForm.get('sup_notification_duration_late_in').value
						"
					>
						and repeat notification every
						<input
							type="tel"
							class="form-control form-inline notify-input"
							formControlName="sup_notification_interval_late_in"
							(change)="validateNumericInput('sup_notification_interval_late_in')"
							(focus)="selectOnFocus($event)"
						/>
						min for
						<input
							type="tel"
							class="form-control form-inline notify-input"
							formControlName="sup_notification_duration_late_in"
							(change)="validateNumericInput('sup_notification_duration_late_in')"
						/>
						min or until employee checks in
					</span>
				</div>

				<div class="checkbox-item d-flex justify-content-between" style="margin-top: 10px">
					<div class="checkbox-wrap">
						<p-checkbox formControlName="notify_on_clear" [binary]="true"></p-checkbox>
					</div>
					<div class="checkbox-label-wrap">
						<strong (click)="toggleCheckbox('notify_on_clear')" class="notify-text has-pointer"
							>Also notify when employee finally checks in</strong
						>
					</div>
				</div>
			</div>
			<hr class="sub-divider" />
		</div>

		<!-- Supervisor Early Check Out -->

		<div class="notify-block">
			<div>
				<div class="notify-type">Employee checks out early</div>
			</div>
			<div class="row" style="margin-top: 10px">
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox
							formControlName="call_supervisor_on_early_out"
							[binary]="true"
							(onChange)="clearInvalidInputsIfNotRequired()"
						></p-checkbox>
						<strong (click)="toggleCheckbox('call_supervisor_on_early_out')" class="has-pointer">Call</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox
							formControlName="sms_supervisor_on_early_out"
							[binary]="true"
							(onChange)="clearInvalidInputsIfNotRequired()"
						></p-checkbox>
						<strong (click)="toggleCheckbox('sms_supervisor_on_early_out')" class="has-pointer">Text</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox
							formControlName="email_supervisor_on_early_out"
							[binary]="true"
							(onChange)="clearInvalidInputsIfNotRequired()"
						></p-checkbox>
						<strong (click)="toggleCheckbox('email_supervisor_on_early_out')" class="has-pointer">Email</strong>
					</div>
				</div>
			</div>
			<div
				*ngIf="
					profileForm.value.call_supervisor_on_early_out ||
					profileForm.value.sms_supervisor_on_early_out ||
					profileForm.value.email_supervisor_on_early_out
				"
				style="margin-bottom: 25px"
			>
				<div class="form-row align-items-center">
					<div class="col-auto">
						<div class="form-label-bold">
							if check out is
							<input
								type="tel"
								class="form-control form-inline notify-input"
								formControlName="sup_notification_early_out_min_time"
								(change)="validateNumericInput('sup_notification_early_out_min_time')"
								(focus)="selectOnFocus($event)"
							/>
							or more min early
						</div>
					</div>
				</div>
			</div>
			<hr class="sub-divider" />
		</div>

		<!-- Supervisor Late Check Out -->

		<div class="notify-block">
			<div>
				<div class="notify-type">Employee checks out late</div>
			</div>
			<div class="row" style="margin-top: 10px">
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox formControlName="call_supervisor_late_out" [binary]="true" (onChange)="clearInvalidInputsIfNotRequired()"></p-checkbox>
						<strong (click)="toggleCheckbox('call_supervisor_late_out')" class="has-pointer">Call</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox formControlName="sms_supervisor_late_out" [binary]="true" (onChange)="clearInvalidInputsIfNotRequired()"></p-checkbox>
						<strong (click)="toggleCheckbox('sms_supervisor_late_out')" class="has-pointer">Text</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox formControlName="email_supervisor_late_out" [binary]="true" (onChange)="clearInvalidInputsIfNotRequired()"></p-checkbox>
						<strong (click)="toggleCheckbox('email_supervisor_late_out')" class="has-pointer">Email</strong>
					</div>
				</div>
			</div>
			<div
				*ngIf="
					profileForm.value.call_supervisor_late_out || profileForm.value.email_supervisor_late_out || profileForm.value.sms_supervisor_late_out
				"
				style="margin-bottom: 25px"
			>
				<div class="form-label-bold">
					when employee is
					<input
						type="tel"
						class="form-control form-inline notify-input"
						formControlName="sup_notification_delay_late_out"
						(change)="validateNumericInput('sup_notification_delay_late_out')"
						(focus)="selectOnFocus($event)"
					/>
					min late
					<!-- Conditional block for repeating notification -->
					<span
						*ngIf="
							showRepeatingInputs ||
							profileForm.get('sup_notification_interval_late_out').value ||
							profileForm.get('sup_notification_duration_late_out').value
						"
					>
						and repeat notification every
						<input
							type="tel"
							class="form-control form-inline notify-input"
							formControlName="sup_notification_interval_late_out"
							(change)="validateNumericInput('sup_notification_interval_late_out')"
							(focus)="selectOnFocus($event)"
						/>
						min for
						<input
							type="tel"
							class="form-control form-inline notify-input"
							formControlName="sup_notification_duration_late_out"
							(change)="validateNumericInput('sup_notification_duration_late_out')"
							(focus)="selectOnFocus($event)"
						/>
						min or until employee checks out
					</span>
				</div>
			</div>
			<hr class="sub-divider" />
		</div>

		<!-- Employee Submits Invalid QR Code -->

		<div *ngIf="isQRCodeValidationEnabled" class="notify-block">
			<div>
				<div class="notify-type">Employee checks in/out with invalid QR code</div>
			</div>
			<div class="row" style="margin-top: 10px">
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox
							formControlName="call_supervisor_qrfail_inout"
							[binary]="true"
							(onChange)="clearInvalidInputsIfNotRequired()"
						></p-checkbox>
						<strong (click)="toggleCheckbox('call_supervisor_qrfail_inout')" class="has-pointer">Call</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox
							formControlName="sms_supervisor_qrfail_inout"
							[binary]="true"
							(onChange)="clearInvalidInputsIfNotRequired()"
						></p-checkbox>
						<strong (click)="toggleCheckbox('sms_supervisor_qrfail_inout')" class="has-pointer">Text</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox
							formControlName="email_supervisor_qrfail_inout"
							[binary]="true"
							(onChange)="clearInvalidInputsIfNotRequired()"
						></p-checkbox>
						<strong (click)="toggleCheckbox('email_supervisor_qrfail_inout')" class="has-pointer">Email</strong>
					</div>
				</div>
			</div>
			<hr class="sub-divider" />
		</div>

		<!-- Supervisor Employee Has GPS Issue -->

		<div class="notify-block">
			<div>
				<div class="notify-type">Employee has a GPS issue</div>
			</div>
			<div class="row" style="margin-top: 10px">
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox formControlName="call_sup_on_gps_issue" [binary]="true" (onChange)="clearInvalidInputsIfNotRequired()"></p-checkbox>
						<strong (click)="toggleCheckbox('call_sup_on_gps_issue')" class="has-pointer">Call</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox formControlName="sms_sup_on_gps_issue" [binary]="true" (onChange)="clearInvalidInputsIfNotRequired()"></p-checkbox>
						<strong (click)="toggleCheckbox('sms_sup_on_gps_issue')" class="has-pointer">Text</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox formControlName="email_sup_on_gps_issue" [binary]="true" (onChange)="clearInvalidInputsIfNotRequired()"></p-checkbox>
						<strong (click)="toggleCheckbox('email_sup_on_gps_issue')" class="has-pointer">Email</strong>
					</div>
				</div>
			</div>
			<div
				*ngIf="profileForm.value.call_sup_on_gps_issue || profileForm.value.sms_sup_on_gps_issue || profileForm.value.email_sup_on_gps_issue"
				style="margin-bottom: 25px"
			>
				<div class="form-label-bold checkpoint-note">
					if GPS share is
					<input
						type="tel"
						class="form-control form-inline notify-input"
						style="width: 60px"
						formControlName="sup_notification_time_late_gps"
						(change)="validateNumericInput('sup_notification_time_late_gps')"
						(focus)="selectOnFocus($event)"
					/>
					or more min late
				</div>
				<div class="form-label-bold checkpoint-note">
					if GPS share is
					<input
						type="tel"
						class="form-control form-inline notify-input"
						style="width: 60px"
						formControlName="sup_notification_distance_offsite_gps"
						(change)="validateNumericInput('sup_notification_distance_offsite_gps')"
						(focus)="selectOnFocus($event)"
					/>
					or more meters away
				</div>
			</div>
			<hr class="sub-divider" />
		</div>

		<!-- Supervisor Late Checkpoint -->

		<div class="notify-block">
			<div>
				<div class="notify-type">Employee is late sending checkpoint</div>
			</div>
			<div class="row" style="margin-top: 10px">
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox
							formControlName="call_supervisor_checkpoint"
							[binary]="true"
							(onChange)="clearInvalidInputsIfNotRequired()"
						></p-checkbox>
						<strong (click)="toggleCheckbox('call_supervisor_checkpoint')" class="has-pointer">Call</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox formControlName="sms_supervisor_checkpoint" [binary]="true" (onChange)="clearInvalidInputsIfNotRequired()"></p-checkbox>
						<strong (click)="toggleCheckbox('sms_supervisor_checkpoint')" class="has-pointer">Text</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox
							formControlName="email_supervisor_checkpoint"
							[binary]="true"
							(onChange)="clearInvalidInputsIfNotRequired()"
						></p-checkbox>
						<strong (click)="toggleCheckbox('email_supervisor_checkpoint')" class="has-pointer">Email</strong>
					</div>
				</div>
			</div>
			<div
				*ngIf="
					profileForm.value.call_supervisor_checkpoint ||
					profileForm.value.sms_supervisor_checkpoint ||
					profileForm.value.email_supervisor_checkpoint
				"
				style="margin-bottom: 25px"
			>
				<div class="form-label-bold checkpoint-note">
					when employee is
					<input
						type="tel"
						class="form-control form-inline notify-input"
						formControlName="sup_notification_delay_checkpoint"
						(change)="validateNumericInput('sup_notification_delay_checkpoint')"
						(focus)="selectOnFocus($event)"
					/>
					min late for their checkpoint
					<!-- interval of
					<input
						type="tel"
						class="form-control form-inline notify-input"
						formControlName="checkpoint_frequency"
						(change)="validateNumericInput('checkpoint_frequency')"
					/>
					min -->
					<div class="alert alert-warning" style="margin-top: 15px; font-weight: 500">
						Repeating checkpoint intervals are now managed through the <strong class="tts-hl-2">Chkpts</strong> column in the Jobs table.
					</div>
				</div>
			</div>
			<hr class="sub-divider" />
		</div>

		<!-- Supervisor missed dispatch checkpoint -->

		<div *ngIf="isCallCenterEnabled" class="notify-block">
			<div>
				<div class="notify-type">Employee misses dispatch checkpoint</div>
			</div>
			<div class="row" style="margin-top: 10px">
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox
							formControlName="call_supervisor_dispatch_cp_fail"
							[binary]="true"
							(onChange)="clearInvalidInputsIfNotRequired()"
						></p-checkbox>
						<strong (click)="toggleCheckbox('call_supervisor_dispatch_cp_fail')" class="has-pointer">Call</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox
							formControlName="sms_supervisor_dispatch_cp_fail"
							[binary]="true"
							(onChange)="clearInvalidInputsIfNotRequired()"
						></p-checkbox>
						<strong (click)="toggleCheckbox('sms_supervisor_dispatch_cp_fail')" class="has-pointer">Text</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox
							formControlName="email_supervisor_dispatch_cp_fail"
							[binary]="true"
							(onChange)="clearInvalidInputsIfNotRequired()"
						></p-checkbox>
						<strong (click)="toggleCheckbox('email_supervisor_dispatch_cp_fail')" class="has-pointer">Email</strong>
					</div>
				</div>
			</div>
		</div>

		<!-- Employee gets unscheduled version of job -->

		<div class="notify-block">
			<div>
				<div class="notify-type">Employee gets unscheduled version of job</div>
			</div>
			<div class="row" style="margin-top: 10px">
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox
							formControlName="call_supervisor_on_unscheduled_in"
							[binary]="true"
							(onChange)="clearInvalidInputsIfNotRequired()"
						></p-checkbox>
						<strong (click)="toggleCheckbox('call_supervisor_on_unscheduled_in')" class="has-pointer">Call</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox
							formControlName="sms_supervisor_on_unscheduled_in"
							[binary]="true"
							(onChange)="clearInvalidInputsIfNotRequired()"
						></p-checkbox>
						<strong (click)="toggleCheckbox('sms_supervisor_on_unscheduled_in')" class="has-pointer">Text</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox
							formControlName="email_supervisor_on_unscheduled_in"
							[binary]="true"
							(onChange)="clearInvalidInputsIfNotRequired()"
						></p-checkbox>
						<strong (click)="toggleCheckbox('email_supervisor_on_unscheduled_in')" class="has-pointer">Email</strong>
					</div>
				</div>
			</div>
			<hr class="sub-divider" />
		</div>

		<!-- Employee Submits Incident Report -->

		<div class="notify-block">
			<div>
				<div class="notify-type">Employee submits either an incident report or a vehicle inspection report</div>
			</div>
			<div class="row" style="margin-top: 10px">
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox
							formControlName="call_supervisor_on_incident"
							[binary]="true"
							(onChange)="clearInvalidInputsIfNotRequired()"
						></p-checkbox>
						<strong (click)="toggleCheckbox('call_supervisor_on_incident')" class="has-pointer">Call</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox
							formControlName="sms_supervisor_on_incident"
							[binary]="true"
							(onChange)="clearInvalidInputsIfNotRequired()"
						></p-checkbox>
						<strong (click)="toggleCheckbox('sms_supervisor_on_incident')" class="has-pointer">Text</strong>
					</div>
				</div>
				<div class="col-4">
					<div class="notify-checkbox">
						<p-checkbox
							formControlName="email_supervisor_on_incident"
							[binary]="true"
							(onChange)="clearInvalidInputsIfNotRequired()"
						></p-checkbox>
						<strong (click)="toggleCheckbox('email_supervisor_on_incident')" class="has-pointer">Email</strong>
					</div>
				</div>
			</div>
			<!-- <hr class="sub-divider" /> -->
		</div>
	</div>

	<div *ngIf="isSupervisorPhoneNumberRequired || isSupervisorEmailRequired" class="form-group form-group-lg delay-notification-block">
		<div style="margin: 15px 0px">
			<strong class="tts-hl-2">Supervisor Notification Information</strong>
		</div>

		<div *ngIf="isSupervisorPhoneNumberRequired && supervisorPhoneNumbers.length > 0">
			<div style="margin-bottom: 10px; font-weight: 600">
				Current Supervisor Numbers <span class="float-right">({{ supervisorPhoneNumbers.length }}/{{ supPhoneLimit }})</span>
			</div>
			<div class="btn btn-sm btn-block btn-phone" *ngFor="let entry of supervisorPhoneNumbers; let idx = index">
				<div>
					{{ entry.number | displayPhone }}
					<i (click)="removePhone('supervisor', idx)" class="fa fa-times float-right btn-phone-x" aria-hidden="true"></i>
				</div>
				<div *ngIf="entry.isUser" class="text-muted">
					<small class="sup-name">{{ entry.name }}</small>
				</div>
			</div>
		</div>

		<div style="margin-top: 20px" *ngIf="isSupervisorPhoneNumberRequired" class="row input-horizontal-row">
			<div class="col-12">
				<div style="margin-bottom: 8px">
					<strong>Supervisor Phone</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('supervisorPhone')"></i>
					<span *ngIf="profileForm.get('supervisor_phone_e164').value && !supPhoneValid" class="input-required float-right">(not valid)</span>
				</div>

				<div class="form-group">
					<table width="100%">
						<tr>
							<td style="width: 80px">
								<select
									class="form-control phone-country-select"
									formControlName="supDialingCode"
									(change)="formatPhone('supDialingCode', 'supervisor_phone_e164')"
								>
									<option *ngFor="let code of countryCodeData" value="{{ code.id }}">
										{{ code.id }}
									</option>
								</select>
							</td>
							<td>&nbsp;&nbsp;</td>
							<td style="max-width: 275px">
								<input
									#phoneInput
									(keyup)="formatPhone('supDialingCode', 'supervisor_phone_e164')"
									class="form-control"
									[class.is-valid]="supPhoneValid"
									[class.ng-invalid]="profileForm.value.supervisor_phone_e164 && !supPhoneValid"
									formControlName="supervisor_phone_e164"
									type="tel"
									autocomplete="off"
								/>
							</td>
						</tr>
					</table>
				</div>

				<button class="btn btn-sm btn-block btn-success" [disabled]="!supPhoneValid" (click)="addSupPhone()">Add Another Number</button>
			</div>
		</div>

		<div style="margin-top: 15px" *ngIf="isSupervisorEmailRequired">
			<div>
				<strong>Current Supervisor Email{{ supervisorEmails.length > 1 ? 's' : '' }}</strong>
			</div>

			<div style="margin-top: 10px">
				<div>
					<div *ngFor="let supEmail of supervisorEmails; let idx = index" class="btn btn-sm btn-block btn-email">
						<div>
							{{ supEmail.email }}
							<i (click)="removeEmail('supervisor', idx)" class="fa fa-times float-right btn-email-x" aria-hidden="true"></i>
						</div>
						<div *ngIf="supEmail.isUser" class="text-muted">
							<small class="sup-name">{{ supEmail.name }}</small>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group" style="margin-top: 20px">
				<table width="100%">
					<tr>
						<td>
							<input
								#newSupEmail
								[(ngModel)]="newSupEmailInput"
								placeholder="enter email address"
								(focus)="newSupEmail.placeholder = ''"
								(blur)="newSupEmail.placeholder = 'enter email address'"
								[ngModelOptions]="{ standalone: true }"
								id="newSupEmail"
								class="form-control"
								type="text"
								autocapitalize="off"
								autocomplete="off"
							/>
						</td>
						<td>&nbsp;</td>
						<td style="text-align: right">
							<button
								(click)="addSupEmail(true)"
								(keyup.enter)="addSupEmail(true)"
								[disabled]="!isEmailInputValid('supervisor')"
								class="btn btn-sm btn-success"
							>
								Add
							</button>
						</td>
					</tr>
				</table>
				<div *ngIf="newSupEmailInput && !isEmailInputValid('supervisor')" style="color: firebrick; margin-top: 8px; font-weight: bold">
					Not a valid email address
				</div>
				<div *ngIf="newSupEmailInput && isEmailInputValid('supervisor')" style="color: green; margin-top: 8px">
					Click <strong>Add</strong> to include another address
				</div>
			</div>
		</div>
	</div>

	<hr *ngIf="alert.linkedJobsCount > 0" class="section-divider" />

	<app-form-expander *ngIf="alert.linkedJobsCount" [label]="'Linked Jobs'" [counter]="alert.linkedJobsCount" [centerOnExpansion]="true">
		<div class="text-muted">
			<ul style="margin-top: 15px">
				<li *ngFor="let item of alert.linkedJobs" style="margin-left: -10px">
					<span style="color: chocolate; font-weight: 600">{{ item.jobName }}</span>
					<span *ngIf="item.repeatInterval && item.waypointCount === 0"> / Checkpoints expected every {{ item.repeatInterval }} min</span>
					<span *ngIf="item.waypointCount"> / Tour with {{ item.waypointCount }} checkpoints</span>
					<span *ngIf="item.repeatInterval && item.waypointCount > 0"> / Tour repeats after {{ item.repeatInterval }} min rest interval</span>
					<span *ngIf="!item.repeatInterval && !item.waypointCount"> / No checkpoints</span>
				</li>
			</ul>
		</div>
	</app-form-expander>

	<!-- <div *ngIf="alert.linkedJobsCount > 0">
		<hr class="section-divider" />
		<div style="margin-bottom: 25px">
			<div class="options-section-header">Profile linked to {{ alert.linkedJobsCount }} job{{ alert.linkedJobsCount === 1 ? '' : 's' }}</div>
			<div class="text-muted">
				<ul style="margin-top: 15px">
					<li *ngFor="let item of alert.linkedJobs" style="margin-left: -10px">
						{{ item.jobName }} <span *ngIf="item.repeatInterval"> / repeat checkpoints every {{ item.repeatInterval }} min</span>
					</li>
				</ul>
			</div>
		</div>
	</div> -->
</form>
