import { Component, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { Title } from '@angular/platform-browser'
import { CoreService } from '@app/services'

@Component({
    selector: 'app-reset-application',
    templateUrl: './reset-application.component.html',
    styleUrls: ['./reset-application.component.scss'],
    standalone: false
})
export class ResetApplicationComponent implements OnInit {
	headerText = 'Reset Application'
	resettingApplication = false
	reloadingApplication = false

	constructor(
		private title: Title,
		private location: Location,
		private coreSrvc: CoreService,
	) {
		localStorage.removeItem('lastRoute')
	}

	ngOnInit(): void {
		this.title.setTitle('Reset Application')
		const element = $('#loading-spinner')
		element.hide()
	}

	resetApplication() {
		this.resettingApplication = true
		this.headerText = 'Resetting Application'
		localStorage.clear()
		setTimeout(() => {
			this.reloadApplication()
		}, 2000)
	}

	cancelReset() {
		this.reloadingApplication = true
		this.headerText = 'Checking Application'
		setTimeout(() => {
			this.reloadApplication()
		}, 1000)
	}

	reloadApplication() {
		this.location.go('/admin/dashboard')
		this.coreSrvc.dbSrvc.settingSrvc.reloadApplication()
		// const element = $('#loading-spinner')
		// element.fadeTo(0, 1, () => {
		// 	element.show()
		// 	top.frames.location.reload()
		// })
	}
}
