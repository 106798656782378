import { RRule, RRuleSet, rrulestr } from 'rrule'
import { DateTimeHelper } from '@app/helpers/datetime'
import moment from 'moment-timezone'
import { log } from '@app/helpers/logger'

export class ScheduleChangeComment {
	event: string
	content: string
	date: string
	name: string
	userId: number
	role: 'ADMIN' | 'CLIENT' = 'ADMIN'

	constructor(data: any) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
	}
}

export class ScheduleChangeComments {
	data: Array<ScheduleChangeComment> = []
	constructor(data: Array<any>) {
		this.data = data.map((d) => new ScheduleChangeComment(d))
	}
}

export class IScheduleChangeRequest {
	id: number
	schedule_id: number
	client_id: number
	company_id: number
	employee_count: number

	recurrence: string

	start_time: string
	end_time: string
	change_start_date: string
	change_end_date: string

	notes: string
	comments_json: string
	request_status: string

	created: string
	updated: string
}

// ScheduleEntry is a schedule_recur record

export class ScheduleChangeRequest implements IScheduleChangeRequest {
	id: number = null
	schedule_id: number = null
	client_id: number = null
	company_id: number = null
	employee_count: number = null

	recurrence = 'FREQ=WEEKLY'

	start_time: string = null
	end_time: string = null
	change_start_date: string = null
	change_end_date: string = null

	notes: string = null
	request_status = 'PENDING'
	comments_json = null

	created: string = null
	updated: string = null

	constructor(record?: IScheduleChangeRequest) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.normalizeTimes()
			this.cleanupRecurrence()
		}
		this.created = DateTimeHelper.stripUtcTag(this.created)
		this.updated = DateTimeHelper.stripUtcTag(this.updated)
	}

	normalizeTimes() {
		this.start_time = DateTimeHelper.normalizeTime(this.start_time)
		this.end_time = DateTimeHelper.normalizeTime(this.end_time)
	}

	cleanupRecurrence() {
		const ruleStr = this.recurrence || ''
		if (ruleStr.includes('RRULE')) {
			const comps = ruleStr.split('RRULE:')
			this.recurrence = comps[1]
		}
	}

	getScheduledDays() {
		const ruleString = this.recurrence
		const ruleSet = rrulestr(ruleString, { forceset: true }) as RRuleSet
		const rule = ruleSet.rrules()[0]
		return rule.options.byweekday || []
	}

	getFrequency() {
		const ruleString = this.recurrence
		const ruleSet = rrulestr(ruleString, { forceset: true }) as RRuleSet
		const rule = ruleSet.rrules()[0]
		return rule.options.freq
	}

	getComments(): ScheduleChangeComments {
		log('Comments Json', this.comments_json)
		if (!this.comments_json) {
			return { data: [] }
		}
		const parsedData = JSON.parse(this.comments_json)
		const comments = new ScheduleChangeComments(parsedData.data)
		return comments
	}

	addComment(comment: ScheduleChangeComment) {
		const comments = this.getComments()
		comments.data.push(comment)
		this.comments_json = JSON.stringify(comments)
	}
}
