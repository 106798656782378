import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ColorVendor } from '@app/models'

@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
    standalone: false
})
export class ColorPickerComponent {
	@Input() heading: string
	@Input() color: string
	@Output() update: EventEmitter<string> = new EventEmitter<string>()

	public show = false
	public defaultColors: string[] = ColorVendor.colorList

	constructor() {
	}

	// Change color from default colors
	public changeColor(color: string): void {
		this.color = color
		this.update.emit(this.color)
		this.show = false
	}


	// Change color from input
	public changeColorManual(color: string): void {
		const isValid = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(color)

		if (isValid) {
			this.color = color
			this.update.emit(this.color)
		}
	}

	// Change status of visibility to color picker
	public toggleColors(): void {
		this.show = !this.show
	}
}
