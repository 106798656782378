export class OverrideProtectionDialogManager {
	type: 'OVERTIME' | 'TIMEOFF' = null
	showDialog = false
	allowOvertimeOverride = false
	allowTimeoffOverride = false

	get typeLabel(): string {
		switch (this.type) {
			case 'OVERTIME':
				return 'overtime'
			case 'TIMEOFF':
				return 'time-off'
			default:
				return 'INVALID'
		}
	}

	get headerLabel(): string {
		switch (this.type) {
			case 'OVERTIME':
				return 'Overtime Exceeded'
			case 'TIMEOFF':
				return 'Time-Off Conflict'
			default:
				return 'Unknown Conflict'
		}
	}
}
