<div>
	<div *ngIf="!manager.hasAddress" class="link-text" (click)="addAddress()"><i class="far fa-plus"></i> add address</div>

	<div *ngIf="manager.hasAddress" id="address-block">
		<div class="remove-link link-text float-right" (click)="manager.hasAddress = false"><i class="far fa-close"></i> remove address</div>
		<div class="options-section-header-lg">Address</div>
		<div>
			<div class="form-group" [class.input-required]="!manager.address.address_1">
				<label>Street Address</label>
				<span *ngIf="!manager.address.address_1" class="float-right">(required)</span>
				<input class="form-control" [(ngModel)]="manager.address.address_1" />
			</div>
			<div class="form-group">
				<label>Address Line 2</label>
				<input class="form-control" [(ngModel)]="manager.address.address_2" />
			</div>
			<div class="form-group" [class.input-required]="!manager.address.city">
				<label>City</label>
				<span *ngIf="!manager.address.city" class="float-right">(required)</span>
				<input class="form-control" [(ngModel)]="manager.address.city" />
			</div>
			<div class="row form-group">
				<div class="col-7" [class.input-required]="!manager.address.district">
					<label>State / Province</label>
					<span *ngIf="!manager.address.district" class="input-required float-right">(required)</span>
					<!-- <input class="form-control" [(ngModel)]="manager.address.district" /> -->
					<div class="autocomplete-input-wrap">
						<p-autoComplete
							appScrollLockAutoComplete
							[inputStyleClass]="'p-autocomplete-mod'"
							[(ngModel)]="manager.address.district"
							[suggestions]="stateOptions"
							[delay]="150"
							[completeOnFocus]="true"
							(completeMethod)="searchStates($event)"
						>
						</p-autoComplete>
					</div>
				</div>
				<div class="col-5">
					<label>Zip / Postcode</label>
					<input class="form-control" [(ngModel)]="manager.address.postal_code" />
				</div>
			</div>
			<div *ngIf="showNotesOption" class="form-group">
				<label>Address Notes</label>
				<textarea class="options-textarea" [(ngModel)]="manager.address.address_notes" placeholder="Enter address notes"></textarea>
			</div>
		</div>
	</div>
</div>
