import { Component, Input } from '@angular/core'
import { LogEntry, LogEntryBatch } from '@app/models'

@Component({
    selector: 'app-integration-sync-log-batch',
    templateUrl: './sync-log-batch.component.html',
    styleUrl: './sync-log-batch.component.scss',
    standalone: false
})
export class IntegrationSyncLogBatchComponent {
	@Input() batch: LogEntryBatch
	@Input() table: string

	get logLabel(): string {
		switch (this.table) {
			case 'Customer':
				return 'Customer Sync'
			case 'Employee':
				return 'Employee Sync'
			case 'Job':
				return 'Job Sync'
			case 'Location':
				return 'Job Site Sync'
			case 'Position':
				return 'Position Sync'
			case 'Schedule':
				return 'Schedule Sync'
			case 'ServiceItem':
				return 'Service Item Sync'
			case 'Supervisor':
				return 'Supervisor Sync'
			case 'TransactionLog':
				return 'Time Entry Sync'
			case 'Vacation':
				return 'Vacation Sync'
			case 'Vendor':
				return 'Vendor Sync'
			default:
				return 'Sync Log'
		}
	}
}
