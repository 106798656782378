<div class="urr-page-box">
	<div class="urr-report-wrapper">
		<app-user-report-header
			[config]="config"
			[logoUrl]="vm.logoUrl"
			[companyName]="vm.companyName"
			[address]="vm.address"
			[reportType]="vm.reportType"
			[transactionMetaData]="vm.reportData.transactionMetaData"
			[publicView]="publicView"
			(printPageBtnClicked)="printPage()"
			(closeBtnClicked)="closeBtnClicked.emit($event)"
		>
		</app-user-report-header>

		<app-user-report-event-map
			[dataSource]="vm.eventMapDataSource"
			[publicView]="publicView"
			(viewTimelineEvent)="handleViewTimelineEvent($event)"
		></app-user-report-event-map>

		<!-- Check-In Block -->
		<div *ngIf="!vm.eventFilter || vm.eventFilter.type === 'IN'" class="urr-block no-page-break">
			<div class="card urr-card">
				<div>
					<img
						*ngIf="!vm.eventFilter"
						title="Show on Map"
						class="tl-event-icon ng-star-inserted float-right"
						[class.icon-no-gps]="!vm.checkInHasGps"
						src="/assets/img/marker-black-i.png"
						(click)="showOnMap('IN')"
					/>
					<div *ngIf="vm.eventFilter" class="btn-all-events" (click)="vm.eventFilter = null">show all</div>
					<div>
						<div class="urr-card-title check-inout-color">
							Check-In<span class="check-inout-type">{{ vm.checkInTypeLabel }}</span>
						</div>
						<div class="ssr-cp-date">{{ vm.checkInTime }}</div>
						<div *ngIf="!vm.checkInHasGps" class="no-gps">{{ vm.noGps }}</div>
						<div *ngIf="!publicView && vm.checkInHasGps" class="gps-info-wrap">
							<span [innerHtml]="vm.checkInGeoInfo.distance | sanitizer"></span> /
							<span [innerHtml]="vm.checkInGeoInfo.time | sanitizer"></span>
						</div>
					</div>
				</div>
				<hr />
				<div>
					<div>
						<div class="urr-label urr-header-label nowrap">Employee Notes:</div>
						<div class="urr-header-value">{{ vm.checkInNotes ?? vm.noInfo }}</div>
					</div>
					<div *ngIf="vm.checkInImages.length > 0" class="ssr-images">
						<app-user-report-images
							[header]="'Check-In Images'"
							[images]="vm.checkInImages"
							[showDescriptions]="vm.showImageDescriptions"
						></app-user-report-images>
					</div>
				</div>
			</div>
		</div>

		<!-- Check-Out Block -->
		<div *ngIf="vm.checkOutTime && (!vm.eventFilter || vm.eventFilter.type === 'OUT')" class="urr-block no-page-break">
			<div class="card urr-card">
				<div>
					<img
						*ngIf="!vm.eventFilter"
						title="Show on Map"
						class="tl-event-icon ng-star-inserted float-right"
						[class.icon-no-gps]="!vm.checkOutHasGps"
						src="/assets/img/marker-black-o.png"
						(click)="showOnMap('OUT')"
					/>
					<div *ngIf="vm.eventFilter" class="btn-all-events" (click)="vm.eventFilter = null">show all</div>
					<div>
						<div class="urr-card-title check-inout-color">
							Check-Out<span class="check-inout-type">{{ vm.checkOutTypeLabel }}</span>
						</div>
						<div class="ssr-cp-date">{{ vm.checkOutTime }}</div>
						<div *ngIf="!vm.checkOutHasGps" class="no-gps">{{ vm.noGps }}</div>
						<div *ngIf="!publicView && vm.checkOutHasGps" class="gps-info-wrap">
							<span [innerHtml]="vm.checkOutGeoInfo.distance | sanitizer"></span> /
							<span [innerHtml]="vm.checkOutGeoInfo.time | sanitizer"></span>
						</div>
					</div>
				</div>
				<hr />
				<div>
					<div>
						<div class="urr-label urr-header-label nowrap">Employee Notes:</div>
						<div class="urr-header-value">{{ vm.checkOutNotes ?? vm.noInfo }}</div>
					</div>
					<div *ngIf="vm.checkOutImages.length > 0" class="ssr-images">
						<app-user-report-images
							[header]="'Check-Out Images'"
							[images]="vm.checkOutImages"
							[showDescriptions]="vm.showImageDescriptions"
						></app-user-report-images>
					</div>
				</div>
			</div>
		</div>

		<!-- Checkpoints Block -->
		<div *ngIf="!vm.eventFilter || vm.eventFilter.type === 'CP'">
			<div *ngFor="let cpw of vm.checkpointsForDisplays; let idx = index">
				<div *ngIf="!vm.eventFilter || vm.eventFilter?.cpIndex === idx" class="urr-block no-page-break">
					<div class="card urr-card">
						<div>
							<img
								*ngIf="!vm.eventFilter"
								title="Show on Map"
								class="tl-event-icon ng-star-inserted float-right"
								[class.icon-no-gps]="!cpw.cp.geo_latitude"
								src="/assets/img/marker-blue.png"
								(click)="showOnMap('CP', cpw.cp)"
							/>
							<div *ngIf="vm.eventFilter" class="btn-all-events noprint" (click)="vm.eventFilter = null">show all</div>
							<div>
								<div class="urr-card-title checkpoint-color">Checkpoint #{{ cpw.idx }}</div>
								<div class="ssr-cp-date">{{ vm.formatTimestamp(cpw.cp.effectiveDateString) }}</div>
								<div *ngIf="cpw.cp.waypoint_name" class="ssr-cp-name">{{ cpw.cp.waypoint_name }}</div>
								<div *ngIf="!cpw.cp.geo_latitude" class="no-gps">{{ vm.noGps }}</div>
							</div>
						</div>
						<hr />
						<div>
							<div>
								<div class="urr-label urr-header-label nowrap">Notes:</div>
								<div class="urr-header-value">{{ cpw.cp.comments ?? vm.noInfo }}</div>
							</div>
							<div *ngIf="cpw.images.length > 0" class="ssr-images">
								<app-user-report-images
									[header]="'Check-Out Images'"
									[images]="cpw.images"
									[showDescriptions]="vm.showImageDescriptions"
								></app-user-report-images>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Admin Notes and Footer -->
		<div *ngIf="!vm.eventFilter">
			<div *ngFor="let srw of vm.shiftReports">
				<div class="urr-block no-page-break">
					<div class="card urr-card">
						<div>
							<div class="btn-all-events" (click)="vm.viewReport(srw)">view</div>
							<div class="urr-card-title shift-report-color">{{ srw.cardLabel }}</div>
							<div class="ssr-cp-date">{{ vm.formatTimestamp(srw.sr.updated ? srw.sr.updated : srw.sr.created) }}</div>
						</div>
						<div *ngIf="srw.sr.notes">
							<hr />
							<div>
								<div class="urr-label urr-header-label nowrap">Notes:</div>
								<div class="urr-header-value">{{ srw.sr.notes ?? vm.noInfo }}</div>
							</div>
							<div *ngIf="srw.sr.mileage">
								<div class="urr-label urr-header-label nowrap">Mileage:</div>
								<div class="urr-header-value">{{ srw.sr.formattedMileage }}</div>
							</div>
							<!-- <div *ngIf="srw.images.length > 0" class="ssr-images">
								<app-user-report-images
									[header]="'Check-Out Images'"
									[images]="srw.images"
									[showDescriptions]="vm.showImageDescriptions"
								></app-user-report-images>
							</div> -->
						</div>
					</div>
				</div>
			</div>

			<div *ngIf="vm.adminNotes" class="urr-block no-page-break">
				<div class="card urr-card">
					<div *ngIf="false" class="urr-card-expander-box noprint" [hidden]="!vm.hasAdminNotesExpander">
						<div class="urr-card-expander-icon-box">
							<i
								class="fa urr-card-expander-icon"
								[class.fa-up-right-and-down-left-from-center]="!vm.isAdminNotesExpanded"
								[class.fa-down-left-and-up-right-to-center]="vm.isAdminNotesExpanded"
								(click)="vm.toggleAdminNotesExpander()"
							></i>
						</div>
					</div>
					<div>
						<div class="urr-label" style="margin-bottom: 12px">Admin Notes:</div>
						<div id="admin-notes-scrollbox" class="urr-header-value admin-notes" [class.admin-notes-expanded]="vm.isAdminNotesExpanded">
							<div [innerHtml]="vm.adminNotes ?? vm.noInfo | lineBreaks"></div>
						</div>
					</div>
				</div>
			</div>

			<div *ngIf="false" class="urr-block no-page-break">
				<div class="card urr-card">
					<div>
						<div class="urr-label urr-header-label nowrap">Record Created:</div>
						<div class="urr-header-value">{{ vm.reportDate }}</div>
					</div>
					<div *ngIf="vm.modifiedDate">
						<div class="urr-label urr-header-label nowrap">Record Modified:</div>
						<div class="urr-header-value">{{ vm.modifiedDate }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<app-user-report-footer></app-user-report-footer>
</div>
