import { environment } from '@env/environment'
import { Router } from '@angular/router'
import { Injectable } from '@angular/core'

import { log } from '@app/helpers'

import { CognitoUtil, CognitoCallback, LoggedInCallback } from '@app/services/aws/cognito.service'
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js'
import { UserSessionService } from './session.service'

import AWS from 'aws-sdk/global'
import STS from 'aws-sdk/clients/sts'
export class UserLoginInfo {
	static e164Phone = ''
}

@Injectable({
	providedIn: 'root',
})
export class UserLoginService {
	public credentialsLastRefreshed: Date
	private readonly credentialsRefreshInterval = 1800000 // 30 Minutes = 1000 ms * 60 sec * 30 min

	public showStartupBanner = true // Controls the starting up banner on the login component

	constructor(
		public cognitoUtil: CognitoUtil,
		private sessionSrvc: UserSessionService,
		private router: Router,
	) {
		log('Creating UserLogingService')
	}

	setLastActive() {
		localStorage.setItem('lastActive', new Date().getTime().toString())
	}

	getLastActive(): number {
		return parseInt(localStorage.getItem('lastActive'), 10)
	}

	clearLastActive() {
		localStorage.removeItem('lastActive')
	}

	authenticate(username: string, password: string, callback: CognitoCallback) {
		log('UserLoginService: starting the authentication')
		// Need to provide placeholder keys unless unauthorised user access is enabled for user pool
		// AWSCognito.config.update({ accessKeyId: 'anything', secretAccessKey: 'anything' });

		const authenticationData = {
			Username: username,
			Password: password,
		}
		const authenticationDetails = new AuthenticationDetails(authenticationData)
		// log(authenticationDetails)

		const userData = {
			Username: username,
			Pool: this.cognitoUtil.getUserPool(),
		}

		log('UserLoginService: Params set...Authenticating the user')
		const cognitoUser = new CognitoUser(userData)
		log(cognitoUser)
		log('UserLoginService: config is ' + AWS.config)
		const self = this
		cognitoUser.authenticateUser(authenticationDetails, {
			newPasswordRequired: function (userAttributes, requiredAttributes) {
				callback.cognitoCallback(`User needs to set password.`, null)
			},
			onSuccess: function (result) {
				log('In authenticateUser onSuccess callback')
				log('Username', username)
				UserLoginInfo.e164Phone = username

				const creds = self.cognitoUtil.buildCognitoCreds(result.getIdToken().getJwtToken())

				AWS.config.credentials = creds
				this.credentialsLastRefreshed = new Date()

				// So, when CognitoIdentity authenticates a user, it doesn't actually hand us the IdentityID,
				// used by many of our other handlers. This is handled by some sly underhanded calls to AWS Cognito
				// API's by the SDK itself, automatically when the first AWS SDK request is made that requires our
				// security credentials. The identity is then injected directly into the credentials object.
				// If the first SDK call we make wants to use our IdentityID, we have a
				// chicken and egg problem on our hands. We resolve this problem by "priming" the AWS SDK by calling a
				// very innocuous API call that forces this behavior.
				const clientParams: any = {}
				if (environment.sts_endpoint) {
					clientParams.endpoint = environment.sts_endpoint
				}
				const sts = new STS(clientParams)
				sts.getCallerIdentity(function (err, data) {
					log('UserLoginService: Successfully set the AWS credentials')
					callback.cognitoCallback(null, result)
				})
			},
			onFailure: function (err) {
				callback.cognitoCallback(err.message, null)
			},
		})
	}

	forgotPassword(username: string, callback: CognitoCallback) {
		const userData = {
			Username: username,
			Pool: this.cognitoUtil.getUserPool(),
		}

		const cognitoUser = new CognitoUser(userData)

		cognitoUser.forgotPassword({
			onSuccess: function () {},
			onFailure: function (err) {
				callback.cognitoCallback(err.message, null)
			},
			inputVerificationCode() {
				callback.cognitoCallback(null, null)
			},
		})
	}

	confirmNewPassword(username: string, verificationCode: string, password: string, callback: CognitoCallback) {
		const userData = {
			Username: username,
			Pool: this.cognitoUtil.getUserPool(),
		}

		const cognitoUser = new CognitoUser(userData)

		cognitoUser.confirmPassword(verificationCode, password, {
			onSuccess: function () {
				callback.cognitoCallback(null, null)
			},
			onFailure: function (err) {
				callback.cognitoCallback(err.message, null)
			},
		})
	}

	logout() {
		log('UserLoginService: Logging out')
		// this.ddb.writeLogEntry("logout");
		const currentUser = this.cognitoUtil.getCurrentUser()
		if (currentUser) {
			currentUser.signOut()
		}
	}

	globalLogout() {
		log('UserLoginService: Logging out')
		// this.ddb.writeLogEntry("logout");
		const currentUser = this.cognitoUtil.getCurrentUser()
		log('Global logout current user', currentUser)
		if (currentUser) {
			currentUser.globalSignOut({
				onSuccess: (msg) => {
					log('Global sign out success')
					log('Logout Msg', msg)
				},
				onFailure: (err) => {
					log('Global sign out failure')
					log('Logout Error', err)
				},
			})
		}
	}

	addMinutes(date, minutes) {
		return new Date(date.getTime() + minutes * 60000)
	}

	isAuthenticated(callback: LoggedInCallback) {
		// If this is ADP User just say they're authenticated for now
		if (this.sessionSrvc.isAdpUserLoggedIn) {
			log('Checking authentication for ADP User')
			callback.isLoggedIn(null, true)
			return
		}

		if (callback == null) {
			throw new Error('UserLoginService: Callback in isAuthenticated() cannot be null')
		}

		const cognitoUser = this.cognitoUtil.getCurrentUser()

		if (cognitoUser != null) {
			cognitoUser.getSession((err, session) => {
				if (err) {
					log("UserLoginService: Couldn't get the session: ", err, err.stack)
					callback.isLoggedIn(err, false)
				} else {
					log('UserLoginService: Session is ' + session.isValid())

					if (this.credentialsNeedRefreshing()) {
						// CREDENTIALS FIX
						log('loginSrvc.isAuthenticated - cognitoUser != null')

						const logins = {}
						logins['cognito-idp.us-east-1.amazonaws.com/' + CognitoUtil._USER_POOL_ID] = session.getIdToken().getJwtToken()

						// Add the User's Id Token to the Cognito credentials login map.
						AWS.config.credentials = new AWS.CognitoIdentityCredentials({
							IdentityPoolId: CognitoUtil._IDENTITY_POOL_ID,
							Logins: logins,
						})
						// CREDENTIALS FIX
						this.credentialsLastRefreshed = new Date()
					}

					callback.isLoggedIn(err, session.isValid())
				}
			})
		} else {
			log('UserLoginService: Cannot retrieve the current user')
			callback.isLoggedIn('Cannot retrieve the CurrentUser', false)
		}
	}

	isAuthenticated$(): Promise<boolean> {
		// simple check for adp user
		if (this.sessionSrvc.isAdpUserLoggedIn) {
			return Promise.resolve(true)
		}

		return new Promise<boolean>((resolve, reject) => {
			const cognitoUser = this.cognitoUtil.getCurrentUser()
			if (cognitoUser != null) {
				cognitoUser.getSession((error, session) => {
					if (error) {
						resolve(false)
					} else {
						if (this.credentialsNeedRefreshing()) {
							log('loginSrvc.isAuthenticated$ - cognitoUser != null')

							const logins = {}
							logins['cognito-idp.us-east-1.amazonaws.com/' + CognitoUtil._USER_POOL_ID] = session.getIdToken().getJwtToken()
							AWS.config.credentials = new AWS.CognitoIdentityCredentials({
								IdentityPoolId: CognitoUtil._IDENTITY_POOL_ID,
								Logins: logins,
							})
							// CREDENTIALS FIX
							this.credentialsLastRefreshed = new Date()
						}
						resolve(session.isValid())
					}
				})
			} else {
				resolve(false)
			}
		})
	}

	credentialsNeedRefreshing(): boolean {
		if (!this.credentialsLastRefreshed) {
			return true
		}
		const timeSinceRefresh = new Date().getTime() - this.credentialsLastRefreshed.getTime()
		return timeSinceRefresh > this.credentialsRefreshInterval ? true : false
	}

	refresh(): Promise<boolean> {
		// Handle ADP session timeout
		if (this.sessionSrvc.isAdpUserLoggedIn) {
			return new Promise<boolean>((resolve, reject) => {
				AWS.config.getCredentials((err, credentials) => {
					// log('ADP Credentials', credentials)
					if (err) {
						log('ADP Credentials Error', err)
						this.logoutUser()
						return resolve(false)
					} else {
						// const credentialsExpired = credentials?.['expired']
						// log('ADP Credentials Expired', credentialsExpired)
						// if (credentials?.['expired']) { return resolve(false) }
						return resolve(true)
					}
				})
			})
		}

		// Handle cognito refresh
		return new Promise((resolve, reject) => {
			this.cognitoUtil.getCurrentUser().getSession((err, session) => {
				if (err) {
					reject(new Error('Error getting session'))
				}

				if (this.credentialsNeedRefreshing()) {
					log('loginSrvc.refresh - credentialsNeedRefreshing')
					const logins = {}
					logins['cognito-idp.us-east-1.amazonaws.com/' + CognitoUtil._USER_POOL_ID] = session.getIdToken().getJwtToken()
					// Add the User's Id Token to the Cognito credentials login map.
					AWS.config.credentials = new AWS.CognitoIdentityCredentials({
						IdentityPoolId: CognitoUtil._IDENTITY_POOL_ID,
						Logins: logins,
					})
					this.credentialsLastRefreshed = new Date()
				}

				resolve(true)
			})
		})
	}

	logoutUser() {
		this.router.navigate(['/logout'])
	}
}
