import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-public-about',
    template: '<p>Hello and welcome!"</p>',
    standalone: false
})
export class AboutComponent {

}
