import { Component, EventEmitter, OnInit, OnDestroy, Output, Input, ChangeDetectorRef } from '@angular/core'
import { ColorVendor, EmployeeRecord, ScheduleViewManager, ShiftViewEventBackgroundColorSource } from '@app/models'
import { DatabaseService } from '@app/services'
import { Subscription } from 'rxjs'
import _ from 'lodash'
import { log } from '@app/helpers'
import { ScheduleLogRecord } from '@app/models/schedule-log'

class ShiftViewSelectedEmployee {
	empRecord: EmployeeRecord
	backgroundColor = '#537792'

	constructor(emp: EmployeeRecord, colorFrom: ShiftViewEventBackgroundColorSource) {
		this.empRecord = emp
		if (colorFrom === 'EMP') {
			this.backgroundColor = ColorVendor.getColorById(emp.id)
		}
	}
}

@Component({
    selector: 'app-shift-view-selected-employees',
    templateUrl: './shift-view-selected-employees.component.html',
    styleUrls: ['./shift-view-selected-employees.component.scss'],
    standalone: false
})
export class ShiftViewSelectedEmployeesComponent implements OnInit, OnDestroy {
	empList: Array<ShiftViewSelectedEmployee> = []
	manager: ScheduleViewManager

	@Input() empIds: Array<number> = []
	@Input() colorSource: ShiftViewEventBackgroundColorSource = 'SCHED'
	@Output() listChanged = new EventEmitter<boolean>()

	private subs = new Subscription()

	constructor(
		private dbSrvc: DatabaseService,
		private cd: ChangeDetectorRef,
	) {
		this.manager = this.dbSrvc.schedulerSrvc.scheduleViewManager
		this.subs.add(
			this.manager.shiftViewEmpListNeedsUpdate.subscribe(() => {
				this.updateEmpList()
			}),
		)
	}

	ngOnInit(): void {
		this.updateEmpList()
	}
	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	private updateEmpList() {
		const empIds = this.empIds ?? []
		const uniqueIds = _.uniq(empIds)
		const emps = uniqueIds.map((id) => this.dbSrvc.empSrvc.getEmployeeById(id)) // this.manager.shift.selectedEmpIds.map((id) => this.dbSrvc.empSrvc.getEmployeeById(id))
		const empList = emps.filter((emp) => !!emp).map((emp) => new ShiftViewSelectedEmployee(emp, this.colorSource))
		this.empList = _.sortBy(empList, 'empRecord.name')
		this.cd.markForCheck()
	}

	public removeEmployee(se: ShiftViewSelectedEmployee) {
		const id = se.empRecord.id
		this.manager.shift.removeSelectedEmpId(id)
		this.updateEmpList()
		this.listChanged.emit(true)
	}

	public empInList(emp: ShiftViewSelectedEmployee) {
		return this.manager.shift.selectedEmpIds.includes(emp.empRecord.id)
	}
}
