export class PFPImageFile {
	ts: number
	key: string
	type: string
	bucket: string

	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
		}
	}

	getPfpImageUrl(): string {
		if (this.bucket && this.key) {
			return `https://${this.bucket}/${this.key}`
		}
		return null
	}

	static buildFromJsonString(str: any): PFPImageFile {
		let result = null
		if (str) {
			try {
				result = JSON.parse(str)
			} catch (error) {
				console.log('Error parsing pfp json', error)
			}
		}
		return result ? new PFPImageFile(result) : null
	}
}
