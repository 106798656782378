import { Component, OnDestroy, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { log } from '@app/helpers'
import { BannerStatus, BannerViewManager, EmployeeRecord } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { Subject, Subscription } from 'rxjs'

@Component({
    selector: 'app-profile-update-banner',
    templateUrl: './profile-update-banner.component.html',
    styleUrls: ['./profile-update-banner.component.scss'],
    standalone: false
})
export class ProfileUpdateBannerComponent implements OnInit, OnDestroy {
	bvm: BannerViewManager
	status: BannerStatus

	subs = new Subscription()
	@Output() receivedUpdate = new Subject<boolean>()

	constructor(private router: Router, private coreSrvc: CoreService) {
		this.bvm = this.coreSrvc.bannerSrvc.viewManager
		this.status = this.coreSrvc.bannerSrvc.viewManager.profileUpdate
		this.subs.add(this.coreSrvc.notifySrvc.databaseFinishedLoading.subscribe(() => this.updateUI()))

		this.subs.add(
			this.coreSrvc.dbSrvc.empSrvc.employeenListUpdated.subscribe(() => {
				log('ProfileUpdateBannerComponent Got update')
				this.updateUI()
			})
		)
	}

	get shouldShowBanner(): boolean {
		return this.status?.notificationCount > 0
	}

	private updateUI() {
		const pendingList = this.coreSrvc.dbSrvc.empSrvc.getAllEmployees().filter((emp) => !!emp.pfp_json && !emp.pfp_approved)
		log('Profile update list', pendingList)
		const pendingCount = pendingList.length
		this.status.notificationCount = pendingCount
		this.coreSrvc.bannerSrvc.groupBannerNeedsUpdate.next(true)
	}

	public ngOnInit(): void {}
	public ngOnDestroy(): void {
		this.subs.unsubscribe()
	}
	public viewProfileUpdates() {
		this.coreSrvc.bannerSrvc.bannerPanelShouldHide.next(true)
		this.router.navigate(['/admin/employees/profile/updates'])
	}
}
