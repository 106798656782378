import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { log } from '@app/helpers'
import { DialogManager, EditFormAction, ProjectRecord, DatabaseTableName } from '@app/models'
import { CoreService } from '@app/services'

@Component({
    selector: 'app-project-edit',
    templateUrl: './project-edit.component.html',
    styleUrls: ['./project-edit.component.scss'],
    standalone: false
})
export class ProjectEditComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
	resource: DatabaseTableName = 'project'
	isUpdating = false
	form: UntypedFormGroup

	// If both a record and recordId are provided, the record will take precedence
	// and the recordId will be ignored. If neither is provided, a new record will
	// be created. If only recordId is provided, the record will be retrieved from
	// the corosponding service. The defaut is to provide a recordId.

	@Input() recordId: number
	@Input() record: ProjectRecord
	@Input() action: EditFormAction = 'edit'

	@Input() dialogManager: DialogManager

	@Output() recordUpdated = new EventEmitter<number>()

	constructor(
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
	) {}

	public get isNew(): boolean {
		return !this.record?.id
	}

	public ngOnInit(): void {
		this.setupComponent()
		this.setupForm()
	}

	public ngAfterViewInit(): void {}

	public ngAfterContentInit(): void {
		this.setupDialogManager()
	}

	public ngOnDestroy(): void {}

	public setupComponent() {
		const record = this.record ? this.record : this.coreSrvc.dbSrvc.projSrvc.getProjectRecordById(this.recordId)
		this.record = new ProjectRecord(record)

		if (this.action === 'clone') {
			this.record.id = null
			this.record.description = record.description + ' (copy)'
		}
	}

	public setupForm() {
		const record = this.record

		this.form = this.fb.group({
			id: [record?.id],
		})
	}

	public setupDialogManager() {
		this.dialogManager.canSubmit = () => this.isFormValid()
		this.dialogManager.submitBtnAction = () => this.submit()
	}

	public isFormValid(): boolean {
		return this.form.valid
	}

	public submit() {
		// Guard against double submission
		if (this.isUpdating) return

		const record = this.makeUpdateRecord()
		log('Record to submit', record)
		if (record) {
			if (this.isNew) {
				log('Insert record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.insertRecord(this.resource, record).then((success) => {
					if (success) {
						this.recordUpdated.emit(this.record?.id)
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			} else {
				log('Update record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.updateRecord(this.resource, record).then((success) => {
					if (success) {
						this.recordUpdated.emit(this.record?.id)
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			}
		}
	}

	public makeUpdateRecord(): ProjectRecord {
		const record = new ProjectRecord(this.record)

		return record
	}
}
