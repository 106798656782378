import { Component, Input, OnInit } from '@angular/core'
import { RRuleCalculator } from '@app/models'
import RRule from 'rrule'

import moment from 'moment-timezone'
import _ from 'lodash'
import { DateTimeHelper, log } from '@app/helpers'

class RowData {
	dayName: string
	dayDate: string
	month: string
	year: string

	// Provide converted moment ready for formatting
	constructor(mom: moment.Moment) {
		const dateString = mom.format('ddd Do MMM YYYY')
		const parts = dateString.split(' ')
		this.dayName = parts[0]
		this.dayDate = parts[1]
		this.month = parts[2]
		this.year = parts[3]
	}
}

@Component({
    selector: 'app-schedule-snapshot',
    templateUrl: './schedule-snapshot.component.html',
    styleUrls: ['./schedule-snapshot.component.scss'],
    standalone: false
})
export class ScheduleSnapshotComponent implements OnInit {
	selectedMonth: number
	monthOptions = [
		{ label: 'January', value: 0 },
		{ label: 'February', value: 1 },
		{ label: 'March', value: 2 },
		{ label: 'April', value: 3 },
		{ label: 'May', value: 4 },
		{ label: 'June', value: 5 },
		{ label: 'July', value: 6 },
		{ label: 'August', value: 7 },
		{ label: 'September', value: 8 },
		{ label: 'October', value: 9 },
		{ label: 'November', value: 10 },
		{ label: 'December', value: 11 },
	]

	selectedYear: number
	yearOptions = []

	rangeStartDate: Date
	rangeEndDate: Date

	scheduleCount = 0
	hasScheduledDates = false
	hidePastDates = true
	isExpanded = true

	@Input() rRuleCalculator: RRuleCalculator = new RRuleCalculator()

	constructor() {
		const date = new Date()
		this.selectedMonth = date.getMonth()
		this.selectedYear = date.getFullYear()
		this.rangeStartDate = moment().startOf('month').utc(true).toDate()
		this.rangeEndDate = moment().endOf('month').utc(true).toDate()
		this.setupYearOptions()
		this.updateRows()
	}

	get statusMessage(): string {
		let message = 'No schedules for time period'
		if (!this.rRuleCalculator.startDate) {
			message = 'Snapshot requires a start date'
		}
		return message
	}

	get rowData(): Array<RowData> {
		if (this.rRuleCalculator.rRule) {
			// Check start date and return empty array if not set
			if (!this.rRuleCalculator?.startDate) {
				return []
			}
			const timezone = this.rRuleCalculator.timezone
			const moments = this.rRuleCalculator.rRule.between(this.rangeStartDate, this.rangeEndDate).map((d) => this.convertFromRuleDate(d, timezone))

			const filteredMoments = this.hidePastDates ? moments.filter((mom) => !this.isMomentBeforeNow(mom)) : moments

			// If no scheduled dates then set flag accordingly
			const scheduleCount = filteredMoments.length
			if (scheduleCount > 0) {
				this.hasScheduledDates = true
				// this.scheduleCount = scheduleCount
			} else {
				this.hasScheduledDates = false
				// this.scheduleCount = 0
			}

			return filteredMoments.map((mom) => new RowData(mom))
		} else {
			this.hasScheduledDates = false
			// this.scheduleCount = 0
			return []
		}
	}

	// Rule dates are local time but with UTC timezone set so need to be converted
	// to local and then converted to timezone of location before display

	convertFromRuleDate(date: Date, timezone: string) {
		const dateString = date.toISOString().replace('Z', '')
		return moment(dateString).tz(timezone)
	}

	isMomentBeforeNow(mom: moment.Moment) {
		return mom.isBefore(moment(), 'day')
	}

	ngOnInit(): void {
		setTimeout(() => {
			this.updateRows()
		}, 100)
	}

	setupYearOptions() {
		const year = this.selectedYear
		this.yearOptions.push({ label: `${year}`, value: year })
		this.yearOptions.push({ label: `${year + 1}`, value: year + 1 })
	}

	updateYearOptions() {
		const yearInList = this.yearOptions.find((opt) => opt.value === this.selectedYear)
		if (!yearInList) {
			this.yearOptions.push({ label: `${this.selectedYear}`, value: this.selectedYear })
			this.yearOptions = _.sortBy(this.yearOptions, 'value')
		}
	}

	updateRows() {
		const rRule = this.rRuleCalculator.rRule
		if (rRule) {
			const startDate = moment([this.selectedYear, this.selectedMonth]).startOf('month').toDate()
			const endDate = moment([this.selectedYear, this.selectedMonth]).endOf('month').toDate()
			this.rangeStartDate = DateTimeHelper.makeUtcDayViewDates(startDate).start
			this.rangeEndDate = DateTimeHelper.makeUtcDayViewDates(endDate).end
			// const dates = this.rRuleCalculator.rRule.between(this.rangeStartDate, this.rangeEndDate)
		}
	}

	momentFromRuleDate(date: Date): moment.Moment {
		const dateString = date.toISOString().replace('Z', '')
		return moment(dateString)
	}

	nextMonth() {
		if (this.selectedMonth === 11) {
			this.selectedMonth = 0
			this.selectedYear++
		} else {
			this.selectedMonth++
		}
		this.updateYearOptions()
		this.updateRows()
	}

	previousMonth() {
		if (this.selectedMonth === 0) {
			this.selectedMonth = 11
			this.selectedYear--
		} else {
			this.selectedMonth--
		}
		this.updateYearOptions()
		this.updateRows()
	}
}
