import { Component, OnInit, OnDestroy, AfterViewInit, Renderer2, Input, Output, EventEmitter, ChangeDetectorRef, NgZone } from '@angular/core'
import { Router } from '@angular/router'
import { CoreService, PrefsService, ShiftViewCalendarDefaults } from '@app/services'

import {
	TransactionLogRecord,
	TransAlert,
	CrudAction,
	CheckInOutThreshold,
	ShiftThreshold,
	DialogManager,
	GenericEvent,
	DatabaseTableName,
	ClickToCallSourceConfig,
	TimeEntryCallerIdMatcher,
	TagFilterKey,
	TransTableViewConfiguration,
	LocalPrefsData,
	LocalPrefsGroup,
	LocalPrefsDialogData,
	WorkingDialogConfig,
	ShiftSummaryReport,
	CheckpointDataAccessRequest,
	Checkpoint,
	FullScreenViewEvent,
	IncidentDataAccessRequest,
	Incident,
	ClickToCallDialogViewType,
	TransInfoHours,
	TableRenderCache,
	ImageFile,
	DuplicateImagesDialogData,
	TransDateLockManager,
	HelpDialogMessage,
	ComponentBridgeName,
	ContactSupportEvent,
} from '@app/models'
import {
	log,
	Helper,
	DateTimeHelper,
	DisplayHelper,
	TransTableFormatter,
	TableActionFormatter,
	GeneralTableHelper,
	TransactionHelper,
	DataTablesHelper,
} from '@app/helpers'

import { environment } from '@env/environment'
import { AccessHelper } from '@app/helpers/access'
import { Subscription } from 'rxjs'
import { v4 as uuid } from 'uuid'

import _ from 'lodash'
import moment from 'moment-timezone'

enum TransTableColumnIndex {
	sortingId,
	empFirstName, // First
	empLastName, // Last
	company, // Company
	activeStatus, // Status
	externalIds, // Ids
	jobDescription, // Job
	tags,
	jobDate, // Date
	actualShift, // In - Out
	breaks, // Breaks
	hours, // Hours
	overage, // Overage
	// reports,
	notified,
	voice,
	gpsDetails,
	chkptsReports,
	empSupervisor,
	jobSiteSupervisor,
	notes,
	adpCustom1,
	client,
	vendor,
	jobAddress, // Job Address
	lock,
	actions,
}

@Component({
    selector: 'app-transaction-table',
    templateUrl: './transaction-table.component.html',
    styleUrls: ['./transaction-table.component.scss'],
    standalone: false
})
export class TransactionTableComponent implements OnInit, OnDestroy, AfterViewInit {
	bridgeName: ComponentBridgeName = 'ngBridgeTransTable'
	environment = environment
	TagFilterKey = TagFilterKey

	accessHelper: AccessHelper

	currentNotification = { severity: '', summary: '', detail: '', duration: null }

	@Input() list: Array<TransactionLogRecord> = []
	@Output() tableRefreshed = new EventEmitter<boolean>()
	@Output() filterEvent = new EventEmitter<string>()
	@Output() setFilterTag = new EventEmitter<string>()

	isLoadingData = false
	isGlobalAccount = false
	isAutoUpdateRunning = false
	isFetchingTransaction = false
	isClickToCallEnabled = false
	isInternalUser = false
	isSmartFilterEnabled = false
	is12HourFormat = true
	canAccessCommLog = false
	canAdminCreateCheckpoints = true

	isAdpIntegrated = false

	showProximityMap = false
	showCheckpointsModal = false
	// showTransAlertDialog = false // DEPRECATED 20240730 - Using job schedule popup with view alerts button
	showBreakEditDialog = false
	showTransNotesDialog = false
	// showPlayingAudioDialog = false // DEPRECATED 20240126 - Moved to audio service
	showIncidentsColumn = true
	showJSQualifier = true
	// showActualInOutDates = true // DEPRECATED 20240930
	showGpsTimeInfo = false

	currentAudioFile: HTMLAudioElement

	currentAlertId: number
	currentAlert: TransAlert
	currentAlertComment: string

	currentTransId: number
	currentProximityLinkType: string

	// currentTransNote: string // DEPRECATED

	transLockManager = new TransDateLockManager()

	statTagCount = {
		ongoing: 0,
		noshow: 0,
		missing: 0,
		invalid: 0,
		unassigned: 0,
		imgIssues: 0,
		clIssues: 0,
	}

	editDialogManager = new DialogManager('editDialogManager')
	editAction = { transId: null, action: 'edit', header: '', footer: '', modified: false, schedCount: null }

	editNotesAction = {
		transId: null,
		notes: '',
		appendNotes: '',
		enableNotifications: false,
		canEditAdminNotes: false,
		isEditingAdminNotes: false,
		showDialog: false,
	}
	editNotesDialogManager = new DialogManager('editNotesDialogManager')

	deleteAction = {
		tableName: 'transaction_log' as DatabaseTableName,
		recordId: null,
		recordLabel: null,
		showDeleteRecordDialog: false,
	}
	transEvent = { transId: null, header: '', footer: '' }
	// transMeta = { transId: null, inOut: null, header: '', footer: '', showDialog: false }

	notifications = { header: 'Notifications', footer: '', transId: null, enabled: true, pauseUntil: null, isUpdating: false, showDialog: false } // pauseUntil is a Date
	jobScheduleAction = { trans: null, recordId: null, hasSchedule: false, hasException: false, showDialog: false }

	reportsDialogManager = new DialogManager('reportsDialogModal')
	transReports = { transId: null, header: 'Employee Reports', footer: '', showDialog: false }
	shiftSummaryublicLinkAction = { record: null, showConfirmation: false, isDialogVisible: false }

	callerIdNumMatcher: TimeEntryCallerIdMatcher

	viewConfig: TransTableViewConfiguration

	empIdsAccessBlocklist: Array<number> = []

	areColumnsHidden = false // used for help icon banner

	private autoUpdateTimer: any

	public transactionTable // : DataTables.DataTable;
	private defaultSortOrder = [[TransTableColumnIndex.sortingId, 'asc']] // Back end derived sort order

	private checkInOutWindow = 'PT5H'
	private calculateOverageEnabled = false

	private audioHostUrl = 'https://' + environment.audioDomain + '/'

	private checkInOutThreshold: CheckInOutThreshold
	private shortBreakThreshold: number
	private longBreakThreshold: number
	private shiftThreshold: ShiftThreshold

	private userAllowedCompanies = []

	// private proximityListener: () => void // DEPRECATED 20240730 - Setup click events in table formatter

	private subs = new Subscription()
	private debounceTableSearch = _.debounce(this.processDtFilterSearch, 250)

	public sectionPrefsDialogManager = new DialogManager('sectionPrefsDialog')
	public dupImagesDialogManager = new DialogManager('dupImagesDialogManager')

	public validateQRCodes = false
	private renderCache = new TableRenderCache()

	constructor(
		private renderer: Renderer2,
		private cd: ChangeDetectorRef,
		private router: Router,
		private zone: NgZone,
		private coreSrvc: CoreService,
		private prefsSrvc: PrefsService, // private devDetect: DeviceDetectorService
	) {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const userPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()

		this.canAccessCommLog = this.canAccessAgentsComponent()
		this.isAdpIntegrated = this.coreSrvc.dbSrvc.adpSrvc.isAdpIntegrated()

		// Setup 12 hour time format flag
		this.is12HourFormat = userPrefs.globalFormatTime12Hours

		// Setup trans lock date
		this.transLockManager.date = company.trans_lock_date ? moment(company.trans_lock_date, 'YYYY-MM-DD').toDate() : null

		// QR Code config
		this.validateQRCodes = this.coreSrvc.dbSrvc.settingSrvc.getCompany().validate_qr_codes

		this.canAdminCreateCheckpoints = userPrefs.transEnableAdminCreatedCheckpoints

		this.setupSubscriptions()
		this.setupViewConfiguration() // Needs to be setup before anything else
		this.setupAccessPermissions()
		this.setupLocalPrefsDialog()
		this.setupDuplicateImagesDialog()
		this.loadList()
	}

	get dtFilterText(): string {
		const input = $('.search-field-input')
		return (input?.val() as string) || ''
	}

	get showTodayView(): boolean {
		return this.prefsSrvc.data.transDefaultToTodayView
	}
	get showFilterView(): boolean {
		return this.coreSrvc.dbSrvc.tranSrvc.filterStartDate ? true : false
	}

	get isDesktop(): boolean {
		return this.coreSrvc.devDetect.isDesktop()
	}

	ngOnInit() {
		window[this.bridgeName] = this
	}

	ngOnDestroy() {
		this.hideAllTooltips()
		log('ngOnDestroy called')
		const currentScrollPosition = $('body').scrollTop()
		log('Scroll Position onDestroy', currentScrollPosition)
		this.transactionTable.state.save()
		this.transactionTable['fixedHeader'].disable()

		// DEPRECATED 20240730 - Setup click events in table formatter
		// this.proximityListener() // Removes proximity listener

		// Remove the auto update timer
		clearInterval(this.autoUpdateTimer)

		window[this.bridgeName] = null

		this.subs.unsubscribe()
		this.coreSrvc.dbSrvc.tranSrvc.filterText = null
	}

	ngAfterViewInit() {
		this.initTable()

		$('#transactionsTable_filter input').attr('placeholder', ' filter')
		$('#transactionsTable_filter input').addClass('search-field-input')

		// Add row details listener for tooltips
		this.transactionTable.on('responsive-display', (e, datatable, row, showHide, update) => {
			// log('Details for row ' + row.index() + ' ' + (showHide ? 'shown' : 'hidden'))
			const itemTooltip: any = $('.item-tooltip')
			itemTooltip.tooltip({ show: { effect: 'none', delay: 0 } })
		})

		// Setup auto updater
		this.setupAutoUpdate()

		setTimeout(() => {
			// $('#company-name').detach().insertBefore('#transactionsTable_filter')

			$('#clear-search-icon').detach().appendTo('#transactionsTable_filter label')
			const tooltips = $('.item-tooltip') as any
			tooltips.tooltip('hide')
		})
		this.setupFilterText()
		this.fetchDataAndUpdateTable()
	}

	private setupSubscriptions() {
		// DEPRECATED 20240730 - Setup click events in table formatter
		// Setup listener for proximity link clicks
		// this.proximityListener = this.renderer.listen('document', 'click', (event) => {
		// 	log('Proximity Link Clicked', event.target.id)
		// 	const id = event.target.id
		// 	if (/^(proximity|address|checkpoint)Link(Start|End|Modal)-/.test(id)) {
		// 		const transId = id ? id.split('-')[1] : null
		// 		this.currentTransId = parseInt(transId, 10)

		// 		if ($(event.target).hasClass('startLink')) {
		// 			this.currentProximityLinkType = 'start'
		// 			this.showProximityMap = true
		// 		}
		// 		if ($(event.target).hasClass('endLink')) {
		// 			this.currentProximityLinkType = 'end'
		// 			this.showProximityMap = true
		// 		}
		// 	}
		// })

		// Setup subscriptsions
		this.subs.add(
			this.coreSrvc.eventSrvc.showFixedHeader.subscribe((show) => {
				log('Show Trans Table Fixed Header', show)
				if (show) {
					this.transactionTable?.fixedHeader.enable(true)
					setTimeout(() => {
						this.transactionTable.columns.adjust().responsive.recalc()
						this.transactionTable.fixedHeader.adjust()
					}, 100)
				} else {
					this.transactionTable?.fixedHeader.enable(false)
				}
			}),
		)

		this.subs.add(
			this.coreSrvc.dbSrvc.tranSrvc.recordNeedsRefresh.subscribe((recordId) => {
				const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(recordId)
				if (trans) {
					this.invalidateRowForTrans(trans)
					this.setModifiedRowHighlight(trans)
				}
			}),
		)

		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	private handleScreenSizeChanges = () => {
		if (this.transactionTable) {
			this.transactionTable.columns.adjust().responsive.recalc()
			this.transactionTable.fixedHeader.adjust()
			this.areColumnsHidden = this.transactionTable.responsive.hasHidden() || false
		}
	}

	private canAccessAgentsComponent() {
		const isC2CEnabled = this.coreSrvc.dbSrvc.settingSrvc.isClickToCallEnabled()
		const isCallCenterEnabled = this.coreSrvc.dbSrvc.settingSrvc.isCallCenterEnabled()

		const permissions = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAccessPermissions().getUserPermissionsFor('ivr_call_log')
		const isAgentsOptionAllowed = permissions?.isOptionEnabledFor('CLGAAL') ?? false
		return isAgentsOptionAllowed // (isC2CEnabled || isCallCenterEnabled) && isAgentsOptionAllowed
	}

	setupViewConfiguration() {
		this.viewConfig = new TransTableViewConfiguration(this.coreSrvc)
		log('View Config', this.viewConfig)

		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()

		log('Default Page Length', this.viewConfig.defaultPageLength)

		// Show or hide Overage column
		if (company) {
			this.calculateOverageEnabled = company.overage ? true : false
			this.checkInOutWindow = company.check_in_out_window

			const adminPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
			const myActualUser = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
			const isGlobalCompany = this.coreSrvc.dbSrvc.settingSrvc.isGlobalAccount()
			const earlyCheckIn = adminPrefs.transHighlightEarlyCheckin
			const lateCheckIn = adminPrefs.transHighlightLateCheckin
			const earlyCheckout = adminPrefs.transHighlightEarlyCheckout
			const lateCheckout = adminPrefs.transHighlightLateCheckout
			this.checkInOutThreshold = new CheckInOutThreshold(earlyCheckIn, lateCheckIn, earlyCheckout, lateCheckout)
			this.shortBreakThreshold = adminPrefs.transHighlightShortBreak
			this.longBreakThreshold = adminPrefs.transHighlightLongBreak
			this.showJSQualifier = isGlobalCompany ? false : adminPrefs.transShowJSQualifier
			// this.showActualInOutDates = adminPrefs.transShowActualInOutDates // DEPRECATED 20240930
			this.showGpsTimeInfo = adminPrefs.transDisplayGpsTimeInfo

			const shortShift = adminPrefs.transHighlightShortShift
			const longShift = adminPrefs.transHighlightLongShift
			this.shiftThreshold = new ShiftThreshold(shortShift, longShift)

			// Setup global company view flag
			this.isGlobalAccount = this.coreSrvc.dbSrvc.settingSrvc.isGlobalAccount()
			this.userAllowedCompanies = myActualUser.allowed_companies
			log('My actual user allowed companies', this.userAllowedCompanies)
		}

		// Setup caller ID number matcher
		this.callerIdNumMatcher = new TimeEntryCallerIdMatcher(this.coreSrvc.dbSrvc)
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'transaction')
		this.accessHelper.updateSupervisorIds()
		const auditPerms = this.accessHelper.getPermissionsFor('audit')
		this.viewConfig.canAccessAuditLog = auditPerms.access.read || auditPerms.owner.read
		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser().role === 'INTERNAL'

		// If sup is blocked from editing their own time entries, add their linked emp ID to blocklist
		const myUser = this.coreSrvc.dbSrvc.settingSrvc.getMyUser()
		const mySupLinkedEmpId = myUser.linked_employee_id
		if (mySupLinkedEmpId && !myUser.edit_own_trans) {
			this.empIdsAccessBlocklist.push(mySupLinkedEmpId)
		}
		log('EmpId Blocklist', this.empIdsAccessBlocklist)
	}

	private setupLocalPrefsDialog(): void {
		this.sectionPrefsDialogManager.headerLabel = 'Preferences'
		let columnVisibilityItems = LocalPrefsData.getTransTableColumnDisplayPrefs()
		if (!this.isAdpIntegrated) columnVisibilityItems = columnVisibilityItems.filter((item) => item.key !== 'transColAdpCustom1Visible')
		if (!this.calculateOverageEnabled) columnVisibilityItems = columnVisibilityItems.filter((item) => item.key !== 'transColOverageVisible')
		const columnVisibilityGroup = new LocalPrefsGroup('Column Visibility', columnVisibilityItems)
		const miscItems = LocalPrefsData.getTransTableMiscellaneousPrefs()
		const miscGroup = new LocalPrefsGroup('Miscellaneous Prefs', miscItems)
		const dialogData = new LocalPrefsDialogData([columnVisibilityGroup, miscGroup])
		dialogData.localStorageKeyRemovalList = ['DataTables_transactionsTable']
		this.sectionPrefsDialogManager.dialogData = dialogData
	}

	private setupDuplicateImagesDialog() {
		this.dupImagesDialogManager.headerLabel = 'Duplicate Images'
		this.dupImagesDialogManager.isSubmitBtnVisible = false
		this.dupImagesDialogManager.cancelBtnLabel = 'Close'
	}

	public prefsDataSaved(): void {
		log('UpdatePrefsData')
		this.sectionPrefsDialogManager.isDialogVisible = false
		this.updateTable()
	}

	canPerformAction(action: CrudAction, isMyRecord: boolean): boolean {
		return this.accessHelper.canPerformAction(action, isMyRecord)
	}

	isMyEditRecord(trans: TransactionLogRecord): boolean {
		if (this.empIdsAccessBlocklist.includes(trans.employee_id)) return false
		if (trans.employee_id === 0) {
			return this.accessHelper.isMyRecord(trans.jobsite_id, 'site')
		}
		if (trans.job_id === this.viewConfig.unassignedJobId) {
			return this.accessHelper.isMyRecord(trans.employee_id, 'employee')
		}
		return this.accessHelper.isMyRecord(trans.employee_id, 'employee') || this.accessHelper.isMyRecord(trans.jobsite_id, 'site')
	}

	isMyDeleteRecord(trans: TransactionLogRecord): boolean {
		if (this.empIdsAccessBlocklist.includes(trans.employee_id)) return false
		if (trans.employee_id === 0) {
			return this.accessHelper.isMyRecord(trans.jobsite_id, 'site')
		}
		if (trans.job_id === this.viewConfig.unassignedJobId) {
			return this.accessHelper.isMyRecord(trans.employee_id, 'employee')
		}
		return this.accessHelper.isMyRecord(trans.employee_id, 'employee') && this.accessHelper.isMyRecord(trans.jobsite_id, 'site')
	}

	setupAutoUpdate() {
		log('Setup auto update')

		// Check if autoupdate is enabled in preferences and exit if not
		const timer = this.prefsSrvc.data.transTableAutoUpdate
		if (timer === 0 || timer === null || timer === undefined) {
			return
		}

		this.autoUpdateTimer = setInterval(() => {
			log('Auto update check')

			const expandedRows = $('table.dataTable tr.child')
			const areAllRowsCollapsed = expandedRows && expandedRows.length === 0
			const transSrvc = this.coreSrvc.dbSrvc.tranSrvc
			const isFilterViewActive = !!transSrvc.filterStartDate
			const isDayViewActive = this.showTodayView
			const autoAdvanceDayView = transSrvc.autoAdvanceDayView
			const filterStart = moment(transSrvc.filterStartDate)
			const filterEnd = moment(transSrvc.filterEndDate)
			const todayInFilterRange = moment(moment()).isBetween(filterStart.startOf('day'), filterEnd.endOf('day'), null, '[]')
			const isDayViewDateToday = moment(transSrvc.dayViewDate).isSame(moment(), 'day')
			const isPrefsDialogOpen = this.sectionPrefsDialogManager.isDialogVisible

			// Check for conditions that allow or prevent autoupdating
			// DO NOT block for all dialogs. Map dialogs allow refresh so each dialog must be added individually

			if (areAllRowsCollapsed && !isPrefsDialogOpen && !this.showBreakEditDialog && !this.editDialogManager.isDialogVisible) {
				log('All rows collapsed')
				if (isDayViewActive) {
					log('Day view active')
					if (autoAdvanceDayView) {
						log('Auto advance day view, resetting view date and refreshing')
						// this.coreSrvc.dbSrvc.tranSrvc.dayViewDate = new Date()
						this.coreSrvc.dbSrvc.tranSrvc.setDayViewDate(new Date())
						// this.setSearchForToday()
						this.isAutoUpdateRunning = true
						this.fetchDataAndUpdateTable()
						return
					}
				}
				if (isDayViewActive && isFilterViewActive && isDayViewDateToday) {
					this.isAutoUpdateRunning = true
					this.fetchDataAndUpdateTable()
					return
				}
				if (isFilterViewActive && !isDayViewActive && todayInFilterRange) {
					this.isAutoUpdateRunning = true
					this.fetchDataAndUpdateTable()
					return
				}
				if (!isDayViewActive && !isFilterViewActive) {
					this.isAutoUpdateRunning = true
					this.fetchDataAndUpdateTable()
					return
				}
			} else {
				log('Pause autoupdate - Rows are expanded or dialog open')
			}
		}, timer * 60000) // timer * 60000
	}

	setupFilterText() {
		const filterText = this.coreSrvc.dbSrvc.tranSrvc.filterText
		log('GOT FILTER TEXT', filterText)
		if (filterText) {
			this.transactionTable.search(filterText)
			this.coreSrvc.dbSrvc.tranSrvc.filterText = null
		}
	}

	setFilterText(filterText: string) {
		this.transactionTable.search(filterText)
		setTimeout(() => {
			this.zone.run(() => {
				this.updateTable()
			})
		}, 100)
	}

	loadList() {
		this.list = this.accessHelper.getTransactionTableList()
		this.calculateStatTagCounts()
	}

	calculateStatTagCounts(): void {
		const jobLengthhMax = this.viewConfig.jobLengthMax
		const unassignedJobId = this.coreSrvc.dbSrvc.jobSrvc.getUnassignedJob()?.id

		let ongoing = 0
		let noshow = 0
		let missing = 0
		let invalid = 0
		let unassigned = 0
		let imgIssues = 0
		let clIssues = 0

		for (const trans of this.list) {
			// Check for unassigned job
			if (trans.job_id === unassignedJobId) unassigned++
			if (trans.dup_images || trans.imgErrCheckin || trans.imgErrCheckout) imgIssues++
			if (trans.hasMissingChecklist) clIssues++

			// Checks for tags related to hours
			const isNegativeDuration = /-/.test(trans.time_worked)
			if (isNegativeDuration) {
				invalid++
				continue
			}

			// If shift completed there's nothing to check
			if (trans.actual_end) {
				continue
			}
			// Check missing
			if (trans.actual_start && !trans.actual_end) {
				const hasMissingCheckout = trans.hasMissingCheckout(this.viewConfig.jobLengthMax, this.checkInOutWindow)
				if (hasMissingCheckout) {
					missing++
					continue
				}
			}

			// Check No Show
			if (!trans.actual_start && !trans.actual_end) {
				const noShowWindowDays = this.viewConfig.noShowFilterDays
				const noShowWindowHours = noShowWindowDays ? noShowWindowDays * 24 : null
				const shouldLimitNoShowCount = noShowWindowHours && !this.showTodayView && !this.showFilterView
				// log('noShowWindowHours / shouldLimitNoShowCount', noShowWindowHours, shouldLimitNoShowCount)

				const noShowLimitStartMom = moment().subtract(noShowWindowHours, 'hours')
				if (shouldLimitNoShowCount) {
					const createdMom = moment(trans.created)
					if (createdMom.isAfter(noShowLimitStartMom, 'second')) {
						noshow++
					}
				} else {
					noshow++
				}
				continue
			}

			// Check Ongoing
			if (trans.isOngoing(jobLengthhMax) || trans.isPending()) {
				ongoing++
			}
		}
		this.statTagCount.ongoing = ongoing
		this.statTagCount.missing = missing
		this.statTagCount.noshow = noshow
		this.statTagCount.invalid = invalid
		this.statTagCount.unassigned = unassigned
		this.statTagCount.imgIssues = imgIssues
		this.statTagCount.clIssues = clIssues
	}

	// Action methods

	createRecord() {
		if (!this.canPerformAction(CrudAction.create, true)) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.editAction = {
			transId: null,
			action: 'new',
			header: 'Add Time Entry',
			footer: 'Create a new time entry',
			modified: null,
			schedCount: null,
		}
		// this.showTransEditDialog = true // DEPRECATED 20230423
		this.editDialogManager.headerLabel = this.editAction.header
		this.editDialogManager.isDialogVisible = true
	}

	newRecordAdded() {
		// this.loadList()
		this.editDialogManager.isDialogVisible = false
		setTimeout(() => {
			this.updateTable()
			const newRecord = this.list.filter((t) => !t.row_number).pop()
			if (newRecord) {
				this.setModifiedRowHighlight(newRecord)
			}
		}, 150)

		// this.showTransEditDialog = false // DEPRECATED 20230423
	}

	editRecordForGlobal(id: number) {
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(id)
		const companyId = trans.company_id
		this.coreSrvc.dbSrvc.switchCompany(companyId).then((result) => {
			log('Company Switched', result)
			this.editRecord(id)
		})
	}

	gotoCompanyRecord(id: number) {
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(id)
		const transId = trans.id
		const companyId = trans.company_id
		const url = `/redirect/record/${transId}/transactions/${companyId}`
		this.coreSrvc.dbSrvc.clearServiceData()
		this.zone.run(() => this.router.navigate([url]))
	}

	editRecord(id: number): boolean {
		log('Edit record', id)
		const origTrans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(id)
		const isMyRecord = this.isMyEditRecord(origTrans)
		if (!this.canPerformAction(CrudAction.update, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}

		const origUpdate = origTrans.updated
		const rowNumber = origTrans.row_number
		this.isFetchingTransaction = true
		this.editAction.modified = false
		this.editAction.schedCount = origTrans.sched_count

		this.coreSrvc.dbSrvc.readRecord('transaction_log', id).then((result) => {
			log('Result of record fetch', result)
			if (result === 'DELETED') {
				log('Removing a deleted transaction', origTrans)
				this.isFetchingTransaction = false
				this.cd.detectChanges()
				setTimeout(() => {
					this.removeRowForTrans(origTrans)
				}, 750)
				return
			}
			const fetchedTrans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(id)
			if (!fetchedTrans) {
				return
			}
			fetchedTrans.row_number = rowNumber
			const fetchedUpdate = fetchedTrans.updated
			const hasBeenUpdated = origUpdate !== fetchedUpdate
			log('Orig/Fetched Update', origUpdate, fetchedUpdate)
			if (result === 'UPDATED') {
				if (hasBeenUpdated) {
					// alert('This record has been recently modified')
					log('This record has been updated')
					this.editAction.modified = true
				}
			}
			setTimeout(() => {
				log('Trans Updated', fetchedTrans.id)
				// this.cd.detectChanges()
				setTimeout(() => {
					if (fetchedTrans) {
						if (hasBeenUpdated) {
							this.updateRecord(fetchedTrans.id)
						}
						log('Updating linked records')
						const requestList = origTrans.buildValidationRequest()
						this.coreSrvc.dbSrvc.bulkReadWithIds(requestList).then((refResult) => {
							this.zone.run(() => {
								this.isFetchingTransaction = false
								this.cd.detectChanges()
								this.openEditDialogForId(id, fetchedTrans)
							})
						})
					}
				}, 500)
			}, 800)
		})
		return true
	}

	openEditDialogForId(id: number, fetchedTrans: TransactionLogRecord) {
		this.editAction.transId = id
		this.editAction.action = 'edit'

		// Check for regular entry or travel entry and set dialog header accordingly
		if (fetchedTrans.travel_job) {
			this.editAction.header = 'Edit Travel Entry' // DEPRECATED 20230423
		} else {
			this.editAction.header = 'Edit Time Entry' // DEPRECATED 20230423
		}

		this.editAction.footer = 'Job Date: ' + fetchedTrans.job_date
		// this.showTransEditDialog = true // DEPRECATED 20230423
		this.editDialogManager.headerLabel = this.editAction.header
		this.editDialogManager.isDialogVisible = true
	}

	editSaved(id: number) {
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(id)
		this.renderCache.invalidateRecord(id, 'hours')
		log('Saved', this.editAction.schedCount, trans)
		if (trans) {
			trans.sched_count = this.editAction.schedCount
			this.editAction.schedCount = null
		}
		this.updateRecord(id)
	}

	deleteRecord(id: number) {
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(id)
		const isMyRecord = this.isMyDeleteRecord(trans)
		if (!this.canPerformAction(CrudAction.delete, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}

		if (trans) {
			if (trans.exported) {
				this.coreSrvc.notifySrvc.notify('error', 'Delete Unavailable', 'This record has been exported and cannot be deleted.', 3)
				return
			}
			const jobDesc = this.makeJobDescription(trans)
			const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(trans.employee_id)
			const isEmpActive = emp ? emp.active : false
			const recordLabel = `Selected record for ${trans.employee_first} ${trans.employee_last}${isEmpActive ? '' : ' (Inactive)'} @ ${jobDesc} on ${
				trans.job_date
			}`
			this.deleteAction.recordLabel = recordLabel
		}
		this.deleteAction.recordId = id
		this.deleteAction.showDeleteRecordDialog = true
	}

	deleteActionComplete() {
		log('Delete Action Complete Caught')
		this.deleteAction.showDeleteRecordDialog = false
		setTimeout(() => {
			this.hideAllTooltips()
			this.updateTable()
			this.cd.detectChanges()
		}, 500)
	}

	clickToCall(recordId: number) {
		log('Open Click to call dialog for', recordId)
		const callConfig = new ClickToCallSourceConfig()
		callConfig.type = 'TRANS'
		callConfig.canDismiss = true
		callConfig.sourceId = recordId
		this.coreSrvc.callSrvc.c2cManager.currentView = 'CONTACTS'
		this.coreSrvc.eventSrvc.clickToCallEvent.next(callConfig)
	}

	openC2CDialog(recordId: number, view: ClickToCallDialogViewType) {
		const currentTransUpdate = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(recordId)?.updated
		this.coreSrvc.dbSrvc.readRecord('transaction_log', recordId).then((result) => {
			this.coreSrvc.zone.run(() => {
				const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(recordId)
				if (trans) {
					const callConfig = new ClickToCallSourceConfig()
					callConfig.type = 'TRANS'
					callConfig.canDismiss = true
					callConfig.sourceId = recordId
					this.coreSrvc.callSrvc.c2cManager.currentView = view
					this.coreSrvc.eventSrvc.clickToCallEvent.next(callConfig)
					if (currentTransUpdate !== trans.updated) {
						this.coreSrvc.dbSrvc.tranSrvc.recordNeedsRefresh.next(recordId)
					}
				} else {
					this.updateTable()
				}
			})
		})
	}

	// Shift / Incident Reports

	public showShiftSummaryReport(): void {
		const config = new WorkingDialogConfig()
		config.message = 'Generating Report...'
		config.isVisible = true
		config.onHide = () => {
			alert('Dialog Hidden')
		}
		this.coreSrvc.workSrvc.workingDialogEvents.next(config)
		setTimeout(() => {
			config.isVisible = false
			this.coreSrvc.workSrvc.workingDialogEvents.next(config)
		}, 2000)
	}

	editReport(transId: number) {
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(transId)

		if (this.isGlobalAccount) {
			this.coreSrvc.notifySrvc.notify('warn', 'Not Supported', 'This operation is not supported when viewing global account.')
			return
		}
		if (trans.employee_id === 0) {
			this.coreSrvc.notifySrvc.notify('error', 'Not Supported', 'Editing reports is not supported for Any Employee / No Show time entries.')
			return
		}
		this.transReports.transId = transId
		const isMyRecord = this.isMyEditRecord(trans)
		if (!this.canPerformAction(CrudAction.update, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}

		this.reportsDialogManager.canSubmit = () => false
		this.reportsDialogManager.cancelBtnLabel = 'Close'
		this.reportsDialogManager.isSubmitBtnVisible = false
		this.reportsDialogManager.headerLabel = 'Reports for ' + trans.employee_name
		this.reportsDialogManager.footerLabel = this.makeJobDescription(trans)
		this.reportsDialogManager.isFooterLabelVisible = true
		this.reportsDialogManager.isDialogVisible = true
	}

	incidentUpdated(id: number) {
		log('Trans Table received dataUpdated', id)
		this.updateRecord(id)
	}

	// openMetaDataDialog(event, transId: number, inOut: 'IN' | 'OUT') {
	// 	const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(transId)
	// 	if (trans) {
	// 		this.transMeta.footer = this.makeJobDescription(trans)
	// 	}
	// 	this.transMeta.transId = transId
	// 	this.transMeta.inOut = inOut
	// 	this.transMeta.header = inOut === 'IN' ? 'Check In Details' : 'Check Out Details'
	// 	this.transMeta.showDialog = true
	// }

	openCheckpointsDialog(id: number) {
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(id)
		const checkpointCount = trans.checkpoint_count
		log('CHECKPOINT COUNT', checkpointCount)
		if ((!checkpointCount || checkpointCount === 0) && !this.canAdminCreateCheckpoints) {
			this.coreSrvc.notifySrvc.notify('error', 'Not Allowed', 'Admins are not allowed to create checkpoints for this company.', 5)
			return
		}
		const isNoShow = !trans.actual_start
		if (isNoShow) {
			this.coreSrvc.notifySrvc.notify('error', 'No Show', 'Checkpoints are not supported for no show time entries.', 3)
		} else {
			this.currentTransId = id
			this.showCheckpointsModal = true
		}
	}

	checkpointUpdated() {
		const transId = this.currentTransId
		this.coreSrvc.dbSrvc.readRecord('transaction_log', transId).then((success) => this.updateRecord(this.currentTransId))
	}

	jsBridgeOpenGpsDialog(transId: number, inOut: 'IN' | 'OUT') {
		log('OPEN GPS DIALOG', transId, inOut)
		this.currentTransId = transId
		this.currentProximityLinkType = inOut === 'IN' ? 'start' : 'end'
		this.showProximityMap = true
	}

	// X1
	editNotes(transId: number) {
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(transId)
		const isPrimaryOrInternal = this.coreSrvc.dbSrvc.settingSrvc.isPrimaryOrInternalUser()
		this.editNotesAction.transId = trans.id
		this.editNotesAction.notes = trans.notes
		this.editNotesAction.appendNotes = ''
		this.editNotesAction.enableNotifications = trans.enable_notifications
		this.editNotesAction.canEditAdminNotes = isPrimaryOrInternal
		this.editNotesAction.isEditingAdminNotes = false
		this.editNotesDialogManager.headerLabel = isPrimaryOrInternal ? 'Edit Notes' : 'Add Notes'
		this.editNotesDialogManager.submitBtnAction = () => this.saveNotes()
		this.editNotesDialogManager.isDialogVisible = true
	}

	saveNotes() {
		log('Saving Notes')
		log('Notes Action', this.editNotesAction)
		const transId = this.editNotesAction.transId
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(transId)
		const notes = this.editNotesAction.notes
		const appendNotes = this.editNotesAction.appendNotes
		const timezone = trans.timezone
		const newNotes = TransactionHelper.appendToAdminNotes(this.coreSrvc.dbSrvc, timezone, notes, appendNotes)

		const enableNotifications = this.editNotesAction.enableNotifications
		const record = { id: transId, employee_id: '0', notes: newNotes, enable_notifications: enableNotifications }
		log('Record to update', record)
		this.coreSrvc.dbSrvc.updateRecord('transaction_log', record).then((success) => {
			log('Record updated')
			this.editNotesDialogManager.isDialogVisible = false
			this.updateRecord(transId)
			// this.setModifiedRowHighlight(trans)
		})
	}

	filterCountGroup(id: number) {
		log('Filter Group', id)
		this.hideAllTooltips()
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(id)
		if (trans) {
			const schedCountString = trans.sched_count
			if (schedCountString) {
				const components = schedCountString.split(':')
				const filterString = components[0]
				if (filterString) {
					this.transactionTable.search(filterString)
					this.updateTable()
				}
			}
		}
	}

	// General able component methods

	enableAllTooltips() {
		// $('[role="tooltip"]').each((idx, elm) => {
		// 	$(elm)['tooltip']('dispose')
		// })
		const tooltip: any = $('.tooltip')
		tooltip.tooltip({ show: { effect: 'none', delay: 0 } })
		const itemTooltip: any = $('.item-tooltip')
		itemTooltip.tooltip({ show: { effect: 'none', delay: 0 } })

		const stragglers: any = $('.bs-tooltip-auto')
		stragglers.tooltip('hide')

		setTimeout(() => {
			tooltip.tooltip('hide')
			itemTooltip.tooltip('hide')
		}, 1000)
	}

	hideAllTooltips() {
		const tooltip: any = $('.tooltip')
		const itemTooltip: any = $('.item-tooltip')
		const straglers: any = $('.bs-tooltip-auto')

		itemTooltip.tooltip('hide')
		tooltip.tooltip('hide')
		straglers.tooltip('hide')
	}

	// Updates the table row after record data changed
	updateRecord(id: number) {
		if (id) {
			this.loadList()
			const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(id)
			const idx = this.list.indexOf(trans)
			this.transactionTable.rows(idx).invalidate().draw(false)
			this.setModifiedRowHighlight(trans)
		} else {
			this.updateTable()
		}
		// this.showTransEditDialog = false // DEPRECATED 20230423
		this.editDialogManager.isDialogVisible = false
		this.enableAllTooltips()
		this.cd.detectChanges()
	}

	highlightTransWithId(id: number) {
		log('Trans table got highlight request for conflict', id)
		const entry = this.list.find((rec) => rec.id === id)
		if (entry) {
			this.setModifiedRowHighlight(entry)
		}
	}

	setModifiedRowHighlight(trans: TransactionLogRecord) {
		const idx = this.list.indexOf(trans)
		const selector = this.transactionTable.rows(idx).nodes().to$()
		// this.setRowHighlight(selector, trans)
		selector.removeClass('trans-has-exception')
		selector.removeClass('trans-hours-missing')
		selector.removeClass('trans-hours-ongoing')
		selector.addClass('row-pulse')
		setTimeout(() => {
			selector.removeClass('row-pulse')
			this.setRowHighlight(selector, trans)
		}, 3000)
		// selector.removeClass('trans-modified', { duration: 2000 })
	}

	updateBreakLength(data: any) {
		this.breakLengthUpdated(data)
	}

	editBreakTime(id: number): boolean {
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(id)
		if (!trans.actual_start) {
			this.coreSrvc.notifySrvc.notify('error', 'Not Supported', 'Editing breaks for a no show time entry is not supported.')
			return
		}
		if (this.isGlobalAccount) {
			this.coreSrvc.notifySrvc.notify('error', 'Not Supported', 'Editing breaks when viewing global account is not supported .')
			return
		}
		if (trans.employee_id === 0) {
			this.coreSrvc.notifySrvc.notify('error', 'Not Supported', 'Editing breaks for a no show time entry is not supported.')
			return
		}
		const isMyRecord = this.isMyEditRecord(trans)
		if (!this.canPerformAction(CrudAction.update, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}

		this.currentTransId = id
		this.showBreakEditDialog = true
		return false
	}

	breakLengthUpdated(value) {
		const transId = value.transId
		// const duration = value.duration
		// const initialDuration = moment.duration(value.initial).asMinutes()
		// const currentDuration = moment.duration(duration).asMinutes()
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(transId)

		// DEPRECATED 2025-01-05 - Updates are still needed even when break length is the same
		// if (initialDuration === currentDuration) return
		log('Break Length Updated', value)

		this.coreSrvc.dbSrvc.readRecord('transaction_log', transId).then((result) => {
			this.renderCache.invalidateRecord(transId, 'hours')
			this.invalidateRowForTrans(trans)
			this.setModifiedRowHighlight(trans)
		})
	}

	jsBridgePlayAudio(fileId: string) {
		const url = this.audioHostUrl + fileId
		this.coreSrvc.audioSrvc.playAudioUrl.next(url)
		return
	}

	showTransAlertEdit(): boolean {
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(this.currentAlertId)
		if (trans) {
			const alert = new TransAlert(trans)
			if (alert.hasEdit()) {
				return true
			}
		}
		return false
	}

	transReport(id: number) {
		this.zone.run(() => {
			this.router.navigate(['/reports/transaction/', id])
		})
	}

	removeRowForTrans(trans: TransactionLogRecord) {
		const idx = this.list.indexOf(trans)
		const row = this.transactionTable.row(idx).node()
		this.transactionTable.row(idx).remove().draw()
	}

	invalidateRowForTrans(trans: TransactionLogRecord) {
		const idx = this.list.indexOf(trans)
		const row = this.transactionTable.row(idx).node()
		$(row).removeClass('trans-has-exception').removeClass('text-blink')
		log('Node:', row)
		this.transactionTable.rows(idx).invalidate().draw(false)
	}

	clearSearchBtnCLicked() {
		this.clearSearch()
	}

	clearSearch(): boolean {
		this.transactionTable.state.clear()
		this.transactionTable.search('').columns().search('').columns(TransTableColumnIndex.sortingId).order('asc').draw()
		this.transactionTable.columns.adjust().responsive.recalc()
		return false
	}

	toggleLoadIssues(): boolean {
		this.coreSrvc.dbSrvc.tranSrvc.toggleLoadIssues()
		this.fetchDataAndUpdateTable()
		return false
	}

	toggleTodayView() {
		this.prefsSrvc.data.transDefaultToTodayView = !this.prefsSrvc.data.transDefaultToTodayView
		if (this.showTodayView) {
			this.adjustNoShowFilterTag()
			this.coreSrvc.dbSrvc.tranSrvc.activateTodayView()
		} else {
			this.coreSrvc.dbSrvc.tranSrvc.deactivateTodayView()
		}
		this.prefsSrvc.save()
	}

	adjustNoShowFilterTag() {
		const currentFilter: string = this.transactionTable?.search() ?? ''
		const adjustedFilter = currentFilter.replace('#shft:noshow:rcnt', '#shft:noshow')
		this.transactionTable?.search(adjustedFilter)
	}

	updateTodayView() {
		this.transactionTable.draw()
		this.enableAllTooltips()
	}

	public jobSchedClicked(transId: number) {
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(transId)
		const jobId = trans.job_id

		const showPopupEnabled = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs().transShowJobSchedPopup
		const hasException = !!trans.exception
		const hasSchedule = !!trans.schedule_log_id

		this.jobScheduleAction.trans = trans
		this.jobScheduleAction.recordId = jobId
		this.jobScheduleAction.hasException = hasException
		this.jobScheduleAction.hasSchedule = hasSchedule

		// If there are schedules for job then show dialog, else just filter for jobId
		if ((showPopupEnabled && (hasSchedule || hasException)) || (!showPopupEnabled && hasException)) {
			this.jobScheduleAction.showDialog = true
		} else {
			this.coreSrvc.zone.run(() => {
				this.jobSchedFilterForJob()
				this.hideAllTooltips()
				this.cd.markForCheck()
			})
		}
	}

	public jobSchedFilterForJob() {
		const jobId = this.jobScheduleAction.recordId
		this.jobScheduleAction.showDialog = false
		this.setFilterText(`#jobId:${jobId}`)
	}

	public jobSchedGotoShiftView() {
		const trans: TransactionLogRecord = this.jobScheduleAction.trans
		const viewDate = trans.job_date
		const empId = trans.employee_id

		this.coreSrvc.dbSrvc.schedulerSrvc.toggleDayView('OFF')

		const defaultRange = new ShiftViewCalendarDefaults(viewDate)
		this.coreSrvc.dbSrvc.schedulerSrvc.shiftViewDefaultDate = defaultRange
		if (trans?.schedule_log_id) this.coreSrvc.dbSrvc.schedulerSrvc.schedLogIdsToFlash.push(trans.schedule_log_id)

		const viewManager = this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager
		viewManager.setCurrentView('SHIFT')
		viewManager.shift.selectedJobIds = []
		viewManager.shift.selectedEmpIds = [empId]
		viewManager.shift.shiftEventBgColorSource = 'EMP'
		this.router.navigate(['/admin/scheduler'])
	}

	fetchDataAndUpdateTable(): Promise<boolean> {
		this.enableAllTooltips()
		this.renderCache.invalidateCache()
		return new Promise((resolve, reject) => {
			const startMom = moment(this.coreSrvc.dbSrvc.tranSrvc.filterStartDate)
			const endMom = moment(this.coreSrvc.dbSrvc.tranSrvc.filterEndDate)

			const startDate = startMom.isValid() ? startMom.format('YYYY-MM-DD') : null
			const endDate = endMom.isValid() ? endMom.format('YYYY-MM-DD') : null

			const loadIssues = this.coreSrvc.dbSrvc.tranSrvc.loadIssues
			const rowOverride = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany().transFetchCountOverride
			const rows = rowOverride ? rowOverride : this.prefsSrvc.data.transFetchCount.toString()
			const options = loadIssues ? { loadIssues: true, rows: 'suspect' } : { start_date: startDate, end_date: endDate, rows: rows }
			log('options', options)
			this.isLoadingData = true
			this.coreSrvc.dbSrvc.readTable('transaction_log', options).then((success) => {
				this.updateTable()
				this.tableRefreshed.emit(true)
				this.coreSrvc.eventSrvc.transListUpdated.next(true)
				this.isAutoUpdateRunning = false
				this.isLoadingData = false
				resolve(true)
			})
		})
	}

	private makeJobDescription(record: TransactionLogRecord): string {
		return Helper.getJobName(record.job_description)
	}

	updateTable() {
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadList()
			this.updateColumns()
		})
	}

	public statusCountClicked(key: TagFilterKey) {
		// Add check to show all no shows if in day view
		const hasDayViewDate = this.coreSrvc.dbSrvc.tranSrvc.dayViewDate
		const hasNoShowLookback = !!this.viewConfig.noShowFilterDays
		let adjustedKey = key
		if (hasDayViewDate || !hasNoShowLookback) {
			if (key === TagFilterKey.noShowRecent) {
				adjustedKey = TagFilterKey.noShow
			}
		}
		this.gotoTablePage(1)
		setTimeout(() => {
			this.setFilterTag.next(adjustedKey)
		}, 100)
	}

	public gotoTablePage(page: number) {
		const adjustedPage = page > 0 ? page - 1 : 0
		this.transactionTable.page(adjustedPage)
	}

	private updateColumns(): void {
		performance.mark('render:tableDraw:start')

		const company = this.isGlobalAccount
		const activeStatus = !this.isGlobalAccount && this.prefsSrvc.data.transColEmpStatusVisible
		const externalIds = !this.isGlobalAccount && this.prefsSrvc.data.transColExtIdVisible
		const jobDescription = this.coreSrvc.prefSrvc.data.transColJobVisible
		const jobAddress = this.coreSrvc.prefSrvc.data.transColJobAddressVisible
		const tags = this.coreSrvc.prefSrvc.data.transColTagsVisible
		const jobDate = this.coreSrvc.prefSrvc.data.transColJobDateVisible
		const actualShift = this.coreSrvc.prefSrvc.data.transColActualShiftVisible
		const breaks = this.coreSrvc.prefSrvc.data.transColBreaksVisible
		const hours = this.coreSrvc.prefSrvc.data.transColHoursVisible
		const overage = this.calculateOverageEnabled && this.coreSrvc.prefSrvc.data.transColOverageVisible
		const notified = this.coreSrvc.prefSrvc.data.transColLateCallsVisible
		const details = this.coreSrvc.prefSrvc.data.transColGpsDetailsVisible
		const voice = this.coreSrvc.prefSrvc.data.transColVoiceprintsVisible
		const chkptsReports = this.coreSrvc.prefSrvc.data.transColChkptsReportsVisible
		const empSupervisor = this.coreSrvc.prefSrvc.data.transColEmpSupVisible
		const jobSiteSupervisor = this.coreSrvc.prefSrvc.data.transColJobSiteSupVisible
		const notes = this.coreSrvc.prefSrvc.data.transColNotesVisible
		const adpCustom1 = this.isAdpIntegrated && this.coreSrvc.prefSrvc.data.transColAdpCustom1Visible
		const client = this.coreSrvc.prefSrvc.data.transColClientVisible
		const vendor = this.coreSrvc.prefSrvc.data.transColVendorVisible
		const lock = this.coreSrvc.prefSrvc.data.transColLockVisible

		this.transactionTable.clear()

		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.company, company)
		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.activeStatus, activeStatus)
		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.externalIds, externalIds)
		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.jobDescription, jobDescription)
		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.tags, tags)
		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.jobDate, jobDate)
		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.actualShift, actualShift)
		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.breaks, breaks)
		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.hours, hours)
		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.overage, overage)
		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.notified, notified)
		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.gpsDetails, details)
		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.voice, voice)
		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.chkptsReports, chkptsReports)
		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.empSupervisor, empSupervisor)
		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.jobSiteSupervisor, jobSiteSupervisor)
		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.notes, notes)
		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.adpCustom1, adpCustom1)
		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.client, client)
		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.vendor, vendor)
		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.jobAddress, jobAddress)
		GeneralTableHelper.updateColumn(this.transactionTable, TransTableColumnIndex.lock, lock)

		this.transactionTable.rows.add(this.list)
		this.transactionTable.draw(false)
	}

	public resetPage(reDraw: boolean) {
		this.transactionTable.page(0)
		if (reDraw) {
			this.transactionTable.draw(false)
		}
	}

	setRowHighlight(row, data: any) {
		// Clear out current flags
		$(row).removeClass('trans-has-exception')
		$(row).removeClass('trans-hours-missing')
		$(row).removeClass('trans-hours-ongoing')
		$(row).removeClass('trans-hours-pending')
		$(row).removeClass('trans-hours-paused')

		const trans = data as TransactionLogRecord
		const exception = data.exception
		const infoHours = data.infoHours as TransInfoHours
		const alert = new TransAlert(data)
		if (exception) {
			if (alert.hasHighlight()) {
				$(row).addClass('trans-has-exception')
				return
			}
		}
		if (infoHours) {
			const hasMissing = infoHours.hasMissingCheckout || infoHours.hasNoShow || infoHours.hasInvalid
			const isOnGoing = infoHours.onGoing
			const isPending = infoHours.isPending
			const isPaused = !trans.enable_notifications || trans.notify_paused_until

			if (isPaused) {
				$(row).addClass('trans-hours-paused')
				return
			}
			if (hasMissing) {
				$(row).addClass('trans-hours-missing')
				return
			}
			if (isPending) {
				$(row).addClass('trans-hours-pending')
				return
			}
			if (isOnGoing) {
				$(row).addClass('trans-hours-ongoing')
				return
			}
		}
	}

	notifyOperationNotAuthorized() {
		this.coreSrvc.notifySrvc.default('operationNotAuthorized')
	}

	viewShiftSummary(id: number) {
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(id)
		this.shiftSummaryublicLinkAction.record = trans
		this.viewShiftSummaryReportBtnClicked()
	}

	public manageNotifications(id: number) {
		log('manageNotifications', id)
		this.hideAllTooltips()
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(id)
		this.notifications.transId = id
		this.notifications.enabled = trans.enable_notifications

		const pauseUntil = trans.notify_paused_until
		this.notifications.pauseUntil = pauseUntil ? moment(trans.notify_paused_until).toDate() : null
		this.notifications.isUpdating = false
		this.notifications.showDialog = true
	}

	public clearNotificationPause() {
		this.notifications.pauseUntil = null
		this.cd.markForCheck()
	}

	public updateNotifications() {
		log('NOTIFICATIONS', this.notifications)
		const transId = this.notifications.transId
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(transId)
		const isEnabled = this.notifications.enabled
		trans.enable_notifications = isEnabled
		const pauseIso = this.notifications.pauseUntil ? moment(this.notifications.pauseUntil).toISOString() : null
		trans.notify_paused_until = pauseIso
		log('TRANS TO USE', trans)
		// return
		this.notifications.isUpdating = true
		this.coreSrvc.dbSrvc.updateRecord('transaction_log', trans).then((success) => {
			this.updateRecord(transId)
			const message = 'Notification settings for this time entry have been updated.' // isEnabled ? 'Notifications for this time entry are now enabled.' : 'Notifications for this time entry are now disabled.'
			this.coreSrvc.notifySrvc.notify('info', 'Notifications', message, 4)
			this.notifications.showDialog = false
			this.notifications.isUpdating = false
		})
	}

	public viewShiftSummaryReportBtnClicked() {
		const trans = this.shiftSummaryublicLinkAction.record
		const id = trans.id
		const request = new CheckpointDataAccessRequest(id)
		// Get Checkpoints
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			const data = result.data
			const checkpoints = data.map((rec) => new Checkpoint(rec))
			const request = new IncidentDataAccessRequest(id)
			log('Lambda Request', request)
			this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
				log('Incidents Results', result)
				const data: Array<any> = result.data
				const incidents = data.map((rec) => new Incident(rec)).reverse()
				const report = ShiftSummaryReport.buildFromTransaction(this.coreSrvc.dbSrvc, trans, checkpoints, incidents)
				log('Shift Summary Report', report)
				const event = new FullScreenViewEvent('SHOWSHIFTSUMMARYREPORT', report)
				this.zone.run(() => {
					this.coreSrvc.eventSrvc.fullScreenView.next(event)
					this.shiftSummaryublicLinkAction.isDialogVisible = false
				})
			})
		})
	}

	public copyShiftSummaryPublicLinkBtnClicked() {
		const trans = this.shiftSummaryublicLinkAction.record as TransactionLogRecord
		this.coreSrvc.dbSrvc.settingSrvc.copyToClipboard(trans.publicReportLink)
		this.coreSrvc.notifySrvc.notify('success', 'Link Copied', 'The public link for this report has been copied to your clipboard.', 5)
	}

	public confirmshiftSummaryPublicLinkRefreshBtnClicked() {
		const trans = this.shiftSummaryublicLinkAction.record as TransactionLogRecord
		trans.uuid = uuid()
		this.coreSrvc.dbSrvc.updateRecord('transaction_log', trans).then((success) => {
			this.shiftSummaryublicLinkAction.isDialogVisible = false
			this.updateRecord(trans.id)
			this.coreSrvc.notifySrvc.notify('success', 'Link Refreshed', 'The public link for this report has been refreshed.')
		})
	}

	public setPage(num: number) {
		this.transactionTable?.page(num)
	}

	public openDuplicateImagesDialog(transId: number) {
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(transId)
		if (trans && trans.dup_images) {
			const imagesJson = JSON.parse(trans.dup_images)
			const images = imagesJson.files.map((img) => new ImageFile(img))
			const dialogData = new DuplicateImagesDialogData(trans.id, images)
			this.dupImagesDialogManager.dialogData = dialogData
			this.dupImagesDialogManager.isDialogVisible = true
			log('Open Dups for', trans)
		}
	}

	public openDateLockDialog() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		this.transLockManager.date = moment(company.trans_lock_date).toDate()
		this.transLockManager.isDialogVisible = true
	}

	public contactSupport(id: number) {
		const event = new ContactSupportEvent('Time Entry', 'transaction_log', id)
		this.coreSrvc.eventSrvc.contactSupportEvent.next(event)
		// const protocol = window.location.protocol
		// const host = window.location.host
		// const path = this.coreSrvc.dbSrvc.tranSrvc.getTransactionLinkPath(id)
		// const url = `${protocol}//${host}/#${path}`
		// this.coreSrvc.dbSrvc.settingSrvc.emailSupportForTimeEntry(url)
	}

	private initTable() {
		performance.mark('render:tableDraw:start')

		log('Transactions: initTable() called')
		this.coreSrvc.displaySrvc.startSectionLoader()
		const filterText = this.coreSrvc.dbSrvc.tranSrvc.filterText || ''
		let isSmartFilterEnabled = this.coreSrvc.prefSrvc.data.transTableEnableSmartFilter
		if (filterText.includes(' ')) {
			log('Temp enable smart search filter')
			isSmartFilterEnabled = true
		}
		this.transactionTable = $('#transactionsTable').DataTable(<DataTables.Settings>{
			stateSave: false,
			responsive: true,
			processing: true,
			deferRender: true,
			paging: this.viewConfig.showPaging,
			pageLength: this.viewConfig.defaultPageLength,
			lengthChange: true,
			info: true,
			select: false,
			searching: true,
			searchDelay: 800,
			search: { search: `${filterText}`, smart: isSmartFilterEnabled },
			// search: { search: `${filterText}` },
			fixedHeader: DataTablesHelper.fixedHeader,
			autoWidth: false,
			data: this.list,
			order: [[0, 'asc']],
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{
					responsivePriority: 1,
					targets: [TransTableColumnIndex.empFirstName, TransTableColumnIndex.empLastName],
				},
				{
					responsivePriority: 2,
					targets: [TransTableColumnIndex.hours, TransTableColumnIndex.overage],
				},
				{
					responsivePriority: 3,
					targets: [TransTableColumnIndex.actualShift],
				},
				{
					responsivePriority: 4,
					targets: [TransTableColumnIndex.jobDescription, TransTableColumnIndex.actions, TransTableColumnIndex.company],
				},
				{
					responsivePriority: 5,
					targets: [
						// TransTableColumnIndex.tags,
						TransTableColumnIndex.jobDate,
						TransTableColumnIndex.notified,
						// TransTableColumnIndex.reports,
						TransTableColumnIndex.gpsDetails,
						TransTableColumnIndex.chkptsReports,
					],
				},
				// {
				// 	responsivePriority: 6,
				// 	targets: [TransTableColumnIndex.gpsInfo],
				// },
				{
					responsivePriority: 6,
					targets: [TransTableColumnIndex.voice, TransTableColumnIndex.lock],
				},
				{
					responsivePriority: 7,
					targets: [TransTableColumnIndex.breaks, TransTableColumnIndex.activeStatus],
				},
				{
					responsivePriority: 8,
					targets: [TransTableColumnIndex.empSupervisor, TransTableColumnIndex.jobSiteSupervisor],
				},
				{
					// Sorting Value
					targets: TransTableColumnIndex.sortingId,
					visible: false,
					searchable: false,
					title: 'Sorting',
					data: 'row_number',
				},
				{
					// Employee First Name
					targets: TransTableColumnIndex.empFirstName,
					visible: true,
					searchable: true,
					title: 'First', // 'First<br>Name',
					data: null,
					render: (trans: TransactionLogRecord, t, f, m) => {
						// Setup Info for other cells

						// If we don't have infoHours then add it to the transaction
						const isPending = trans.isPending()
						const info: TransInfoHours = {
							timeWorked: trans.time_worked ? trans.time_worked : '',
							hasMissingCheckout: trans.hasMissingCheckout(this.viewConfig.jobLengthMax, this.checkInOutWindow),
							hasNoShow: trans.isNoShow(),
							hasInvalid: trans.hasInvalidHours(), // TE20220819a
							// hasInvalid: false,
							hoursNotSet: trans.hasHoursNotSet() ? true : false, // TE20220819a
							// hoursNotSet: !trans.time_worked && trans.actual_start && trans.actual_end ? true : false, // TE20220819a
							onGoing: trans.isOngoing(this.viewConfig.jobLengthMax) || isPending, // TE20220819a
							isPending: trans.isPending(),
							// onGoing: false, // TE20220819a
						}

						trans['infoHours'] = info

						if (trans.open_shift && trans.isNoShow()) return '<span style="font-weight: bold; color: firebrick">Open</span>'

						const empName = `<div style="white-space: normal">${DisplayHelper.truncateForDisplay(trans.employee_first)}</div>`
						const tagInfo = TransTableFormatter.tagInfo(this.coreSrvc.dbSrvc, trans)

						// const activeHtml = TransTableFormatter.activityInfo(this.coreSrvc.dbSrvc, trans)

						return `
						<div class="dtr-control-content">
							${empName + tagInfo}
						</div>
						`
						// return '<div style="line-height:1.2em">' + empName + activeHtml + tagInfo + '</div>'
					},
				},
				{
					// Employee Last Name
					targets: TransTableColumnIndex.empLastName,
					visible: true,
					searchable: true,
					title: 'Last', // 'Last<br>Name',
					data: null,
					render: (trans: TransactionLogRecord, t, f, m) => {
						if (trans.open_shift && trans.isNoShow()) return '<span style="font-weight: bold; color: firebrick">Shift</span>'

						return `<div style="white-space: normal">${DisplayHelper.truncateForDisplay(trans.employee_last)}</div>`
					},
				},
				{
					// Company
					targets: TransTableColumnIndex.company,
					visible: this.isGlobalAccount,
					searchable: this.isGlobalAccount,
					orderable: true,
					title: 'Company',
					data: null,
					render: (trans: TransactionLogRecord, t, f, m) => {
						const companyName = this.coreSrvc.dbSrvc.settingSrvc.getGlobalCompanyById(trans.company_id)?.name || ''
						return `
							<div style="max-width:250px;margin-top: 4px;white-space: normal; line-height: 1.1em;">${companyName}</div>
						`
					},
				},
				{
					// Active Status
					targets: TransTableColumnIndex.activeStatus,
					visible: !this.isGlobalAccount && this.prefsSrvc.data.transColEmpStatusVisible,
					searchable: !this.isGlobalAccount && this.prefsSrvc.data.transColEmpStatusVisible,
					title: 'Status', // 'Emp<br>Status',
					data: null,
					render: (trans: TransactionLogRecord, t, f, m) => {
						return TransTableFormatter.activityInfo(this.coreSrvc.dbSrvc, trans)
					},
				},
				{
					// External IDs
					targets: TransTableColumnIndex.externalIds,
					visible: !this.isGlobalAccount && this.prefsSrvc.data.transColExtIdVisible,
					searchable: !this.isGlobalAccount && this.prefsSrvc.data.transColExtIdVisible,
					orderable: false,
					title: 'IDs', // 'External IDs',
					data: null,
					render: (trans: TransactionLogRecord, t, f, m) => {
						return TransTableFormatter.externalIds(this.coreSrvc.dbSrvc, trans)
					},
				},
				{
					// Job / Schedule Description
					targets: TransTableColumnIndex.jobDescription,
					visible: this.prefsSrvc.data.transColJobVisible,
					searchable: this.prefsSrvc.data.transColJobVisible,
					title: 'Job', // 'Job / Schedule'
					data: null,
					render: (trans: TransactionLogRecord, t, f, m) => {
						const cachedData = this.renderCache.getRenderData(trans.id, 'jobDescription')
						if (cachedData) return cachedData

						const renderData = TransTableFormatter.jobScheduleInfo(this.coreSrvc.dbSrvc, trans)

						this.renderCache.setRenderData(trans.id, 'jobDescription', renderData)
						return renderData
					},
				},
				{
					// Tags
					targets: TransTableColumnIndex.tags,
					visible: this.prefsSrvc.data.transColTagsVisible,
					searchable: this.prefsSrvc.data.transColTagsVisible,
					title: 'Tags',
					data: null,
					render: (trans: TransactionLogRecord, t, f, m) => {
						// const cachedData = this.renderCache.getRenderData(trans.id, 'tags')
						// if (cachedData) return cachedData

						const renderData = TransTableFormatter.tags(this.coreSrvc.dbSrvc, trans, this.dtFilterText)
						return renderData
					},
				},
				{
					// Date
					targets: TransTableColumnIndex.jobDate,
					visible: this.prefsSrvc.data.transColJobDateVisible,
					searchable: this.prefsSrvc.data.transColJobDateVisible,
					title: 'Date', // 'Date / Details',
					data: null,
					render: (trans: TransactionLogRecord, t, f, m) => {
						const cachedData = this.renderCache.getRenderData(trans.id, 'jobDate')
						if (cachedData) return cachedData

						const renderData =
							TransTableFormatter.jobDate(this.coreSrvc.dbSrvc, trans) + TransTableFormatter.jobScheduleDetails(this.coreSrvc.dbSrvc, trans)
						this.renderCache.setRenderData(trans.id, 'jobDate', renderData)
						return renderData
					},
				},
				{
					// In - Out
					targets: TransTableColumnIndex.actualShift,
					visible: this.prefsSrvc.data.transColActualShiftVisible,
					searchable: this.prefsSrvc.data.transColActualShiftVisible,
					title: 'In - Out', // 'Actual Shift',
					data: null,
					render: (trans: TransactionLogRecord, t, f, m) => {
						const cachedData = this.renderCache.getRenderData(trans.id, 'actualShift')
						if (cachedData) return cachedData

						const renderData = TransTableFormatter.actualShift(
							this.coreSrvc.dbSrvc,
							trans,
							this.viewConfig.isMobile,
							this.checkInOutThreshold,
							true,
						)
						this.renderCache.setRenderData(trans.id, 'actualShift', renderData)
						return renderData
					},
				},
				{
					// Breaks
					targets: TransTableColumnIndex.breaks,
					visible: this.prefsSrvc.data.transColBreaksVisible,
					searchable: false,
					title: 'Breaks', // 'Breaks<br>(min)',
					data: null, // 'breakTime',
					render: (trans: TransactionLogRecord, t, f, m) => {
						const cachedData = this.renderCache.getRenderData(trans.id, 'breaks')
						if (cachedData) return cachedData

						const renderData = trans.travel_job
							? ''
							: TransTableFormatter.breaks(this.coreSrvc.dbSrvc, trans, this.shortBreakThreshold, this.longBreakThreshold)
						this.renderCache.setRenderData(trans.id, 'breaks', renderData)
						return renderData
					},
				},
				{
					// Hours
					targets: TransTableColumnIndex.hours,
					visible: this.prefsSrvc.data.transColHoursVisible,
					searchable: this.prefsSrvc.data.transColHoursVisible,
					title: 'Hours', // 'Hours<br>(hrs:min)',
					data: null, // time_worked
					render: (trans: TransactionLogRecord, t, f, m) => {
						const cachedData = this.renderCache.getRenderData(trans.id, 'hours')
						if (cachedData) return cachedData

						const renderData = TransTableFormatter.hours(
							this.coreSrvc.dbSrvc,
							trans,
							this.viewConfig,
							this.longBreakThreshold,
							this.shiftThreshold,
						)
						this.renderCache.setRenderData(trans.id, 'hours', renderData)
						return renderData
					},
				},
				{
					// Overagepay
					targets: TransTableColumnIndex.overage,
					visible: this.calculateOverageEnabled && this.coreSrvc.prefSrvc.data.transColOverageVisible,
					searchable: this.calculateOverageEnabled && this.coreSrvc.prefSrvc.data.transColOverageVisible,
					title: 'Overage',
					data: null,
					render: (d, t, f, m) => {
						const overageTime = d.overage_time
						if (overageTime) {
							const duration = moment.duration(overageTime)
							return DateTimeHelper.formatDurationInHoursAndMinutes('H:mm', duration)
						} else {
							return ''
						}
					},
				},
				{
					// Notified
					targets: TransTableColumnIndex.notified,
					visible: this.prefsSrvc.data.transColLateCallsVisible,
					searchable: false,
					orderable: false,
					title: 'Notified',
					data: null,
					render: (trans: TransactionLogRecord, t, f, m) => {
						const cachedData = this.renderCache.getRenderData(trans.id, 'notified')
						if (cachedData) return cachedData

						const renderData = TransTableFormatter.notified(trans, this.bridgeName, this.viewConfig.isMobile)
						this.renderCache.setRenderData(trans.id, 'notified', renderData)
						return renderData
					},
				},
				{
					// Voiceprints
					targets: TransTableColumnIndex.voice,
					visible: this.prefsSrvc.data.transColVoiceprintsVisible,
					searchable: false,
					orderable: false,
					title: 'Voice',
					data: null,
					render: (trans: TransactionLogRecord, t, f, m) => {
						// IVR will not prompt for voice print if calling from an employee mobile number / only from landlines
						const cachedData = this.renderCache.getRenderData(trans.id, 'voice')
						if (cachedData) return cachedData

						const renderData = TransTableFormatter.voicePrints(this.coreSrvc.dbSrvc, trans)
						this.renderCache.setRenderData(trans.id, 'voice', renderData)
						return renderData
					},
				},
				{
					// Details
					targets: TransTableColumnIndex.gpsDetails,
					visible: this.prefsSrvc.data.transColGpsDetailsVisible,
					searchable: true,
					orderable: false,
					title: 'Details',
					data: null,
					render: (trans: TransactionLogRecord, t, f, m) => {
						const cachedData = this.renderCache.getRenderData(trans.id, 'gpsDetails')
						if (cachedData) return cachedData

						const isDesktop = this.viewConfig.isDesktop
						const showTime = false // this.showGpsTimeInfo
						const gpsIn = TransTableFormatter.gpsIcon(
							'IN',
							this.coreSrvc.dbSrvc,
							trans,
							this.callerIdNumMatcher,
							showTime,
							isDesktop,
							this.validateQRCodes,
						)
						const gpsOut = TransTableFormatter.gpsIcon(
							'OUT',
							this.coreSrvc.dbSrvc,
							trans,
							this.callerIdNumMatcher,
							showTime,
							isDesktop,
							this.validateQRCodes,
						)
						const iconDivider = `<span style="font-size: 1.3em"> / </span>` // `${showTime ? '' : ' '}/ `
						const detailsTags = TransTableFormatter.detailsTags(trans)
						const breaksDenied = TransTableFormatter.breaksDenied(trans)
						const duplicateImages = TransTableFormatter.duplicateImages(trans, this.bridgeName)
						const renderData = `
							<div style="display:inline-flex">
								<div class="act-ico-wrap" style="font-size: 1rem; min-width: 170px">
									${gpsIn}${iconDivider}${gpsOut}
									<div>${breaksDenied}${duplicateImages}</div>
								</div>
								${detailsTags}
							</div>`

						this.renderCache.setRenderData(trans.id, 'gpsDetails', renderData)
						return renderData
					},
				},
				{
					// Checkpoints / Reports
					targets: TransTableColumnIndex.chkptsReports,
					visible: this.prefsSrvc.data.transColChkptsReportsVisible,
					searchable: false,
					orderable: false,
					title: 'Chkpts / Reports', // 'Check<br>Points',
					data: null,
					render: (trans: TransactionLogRecord, t, f, m) => {
						const cachedData = this.renderCache.getRenderData(trans.id, 'chkptsReports')
						if (cachedData) return cachedData

						const chkptCount = trans.checkpoint_count || 0
						const chkptIcon = '<i class="fa fa-plus"></i>'
						const chkptClickHandler = `onclick="${this.bridgeName}.openCheckpointsDialog(${trans.id})"`
						let htmlString = ''
						if (chkptCount > 0) {
							htmlString = `<button ${chkptClickHandler} class="btn btn-sm btn-default table-modal-btn-hl" style="width: 60px"> ${chkptCount}</button>`
						} else {
							htmlString = `<button ${chkptClickHandler} class="btn btn-sm btn-default table-modal-btn"> ${chkptIcon}</button>`
						}
						const checkpointsBtn = htmlString

						const count = trans.incident_count || 0
						const icon = '<i class="fa fa-plus"></i>'
						const modalBtn = count > 0 ? 'table-modal-btn-hl' : 'table-modal-btn'
						const highlight = false ? 'text-blink' : ''

						const shiftReportIcon = trans.actual_start
							? `<i onclick="${this.bridgeName}.viewShiftSummary(${trans.id})" class="fa-regular fa-file-invoice trans-rep-icon"></i>`
							: ''

						const reportsBtn = `<button onclick="${this.bridgeName}.editReport(${trans.id})" ${
							highlight ? 'style="background-color:#9ccba4;color:green"' : ''
						} class="btn btn-sm btn-default ${modalBtn} ${highlight}"> ${count ? count : icon}</button>${shiftReportIcon}`

						const divider = '<span class="table-bubble-divider">/</span>'

						const missingChecklist = TransTableFormatter.missingChecklist(trans)

						const renderData = `<div class="trans-table-chkpt-rep-wrap">${checkpointsBtn}${divider}${reportsBtn}</div><div>${missingChecklist}</div>`
						this.renderCache.setRenderData(trans.id, 'chkptsReports', renderData)
						return renderData
					},
				},
				{
					// Employee Supervisor
					targets: TransTableColumnIndex.empSupervisor,
					visible: this.prefsSrvc.data.transColEmpSupVisible,
					searchable: this.prefsSrvc.data.transColEmpSupVisible,
					title: 'Emp Sup', // 'Employee<br>Supervisor',
					data: null,
					render: (trans: TransactionLogRecord, t, f, m) => {
						const name = trans.employee_id === 0 ? '' : trans.emp_supervisor_name
						return `<div style="white-space: normal">${name || ''}</div>`
					},
				},
				{
					// Job Site Supervisor
					targets: TransTableColumnIndex.jobSiteSupervisor,
					visible: this.prefsSrvc.data.transColJobSiteSupVisible,
					searchable: true,
					title: 'Job Sup', // 'Job Site<br>Supervisor',
					data: null,
					render: (trans: TransactionLogRecord, t, f, m) => {
						const name = trans.jobsite_supervisor_name
						return trans.travel_job ? '' : `<div style="white-space: normal">${name || ''}</div>`
					},
				},
				{
					// Notes
					targets: TransTableColumnIndex.notes,
					visible: this.prefsSrvc.data.transColNotesVisible,
					searchable: this.prefsSrvc.data.transColNotesVisible,
					title: 'Admin Notes',
					data: null,
					render: (trans: TransactionLogRecord, t, f, m) => {
						const cachedData = this.renderCache.getRenderData(trans.id, 'notes')
						if (cachedData) return cachedData

						// const adminNotes = DisplayHelper.replaceNewlinesWithBr(trans.notes)
						const adminNotes = DisplayHelper.formatAdminNotesForPreWrap(trans.notes)

						// WITH HEADER - <div class="table-note-block"><span class="table-note-label">Admin Notes:</span> <div class="trans-note-copy">${adminNotes}</div></div>
						const adminHtml = adminNotes ? `<div class="trans-note-block hide-scrollbars">${adminNotes}</div>` : ''

						const editNotesClick = `onclick="${this.bridgeName}.openC2CDialog(${trans.id}, 'NOTES')"`
						const editNotesHtml = `<div class="badge trans-note-link-badge trans-note-link-edit-badge" ${editNotesClick}>Edit Notes</div>`

						const commLogClick = `onclick="${this.bridgeName}.openC2CDialog(${trans.id}, 'EVENTLOG')"`
						const commLogCount = trans.c2c_count
						const commLogHtml =
							commLogCount && this.canAccessCommLog
								? `<div class="badge trans-note-link-badge" ${commLogClick}>Comm Log: ${commLogCount}</div>`
								: ''

						const buttonsHtml = !this.isGlobalAccount
							? `
							<div style="margin-bottom: 10px">
								${editNotesHtml}
								${commLogHtml}
							</div>
						`
							: ''

						const htmlResult = `
							${buttonsHtml}
							<div id="trans-notes-block-${trans.id}">
								${adminHtml}
							</div>
						`

						const renderData = htmlResult
						this.renderCache.setRenderData(trans.id, 'notes', renderData)
						return renderData
					},
				},
				{
					// ADP Custom 1
					targets: TransTableColumnIndex.adpCustom1,
					visible: this.isAdpIntegrated && this.coreSrvc.prefSrvc.data.transColAdpCustom1Visible,
					searchable: true,
					title: 'ADP (1)',
					data: null,
					render: (trans: TransactionLogRecord, t, f, m) => {
						const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(trans.employee_id)
						const adpCustom1 = emp?.adp_custom_1 ?? ''
						return adpCustom1 ? `<div class="custom-field">${adpCustom1}</div>` : ''
					},
				},
				{
					// Clients
					targets: TransTableColumnIndex.client,
					visible: this.prefsSrvc.data.transColClientVisible,
					searchable: true,
					orderable: false,
					title: 'Client',
					data: null,
					render: (trans: TransactionLogRecord, t, f, m) => {
						const clientExtId = trans.client_external_id || ''
						const clientName = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationById(trans.client_id)?.name || ''
						return `
							<div>${clientName}</div>
							<div class="valid-ext-id">${clientExtId}</div>
						`
					},
				},
				{
					// Vendor
					targets: TransTableColumnIndex.vendor,
					visible: this.prefsSrvc.data.transColVendorVisible,
					searchable: true,
					orderable: false,
					title: 'Vendor',
					data: null,
					render: (trans: TransactionLogRecord, t, f, m) => {
						if (!trans.vendor_id) return ''
						const vendorId = trans.vendor_id
						// if (vendorId) log('Has vendor', trans.id, trans.vendor_id)
						const vendorExtId = trans.vendor_external_id || ''
						const vendorName = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationById(trans.vendor_id)?.name || 'Unknown'
						return `
							<div>${vendorName}</div>
							<div class="valid-ext-id">${vendorExtId}</div>
						`
					},
				},
				{
					// Job Address
					targets: TransTableColumnIndex.jobAddress,
					visible: this.prefsSrvc.data.transColJobAddressVisible,
					searchable: true,
					orderable: false,
					title: 'Job Site Address',
					data: null,
					render: (trans: TransactionLogRecord, t, f, m) => {
						const siteId = trans.jobsite_id
						const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(siteId)
						return site?.address_1 ? `<div class="trans-job-addr">${site?.address_1}</div>` : ''
					},
				},
				{
					// Lock
					targets: TransTableColumnIndex.lock,
					visible: this.prefsSrvc.data.transColLockVisible,
					searchable: false,
					orderable: false,
					title: 'Lock',
					data: null,
					render: (trans: TransactionLogRecord, t, f, m) => {
						const isExported = trans.exported
						const isDateLocked = moment(trans.job_date, 'YYYY-MM-DD').isSameOrBefore(this.transLockManager.date, 'days')
						const icon = isExported
							? `<i class="far fa-lock act-ico act-ico-clr-red trans-lock-icon item-tooltip" title="Export Locked"></i>`
							: isDateLocked
								? `<i class="far fa-lock act-ico act-ico-highlight trans-lock-icon item-tooltip" title="Date Locked"></i>`
								: ''
						return icon
					},
				},
				{
					// Actions
					targets: TransTableColumnIndex.actions,
					visible: true,
					searchable: false,
					orderable: false,
					title: 'Actions',
					data: null,
					className: 'row-actions',
					render: (rec: TransactionLogRecord, t, f, m) => {
						const cachedData = this.renderCache.getRenderData(rec.id, 'actions')
						if (cachedData) return cachedData

						const recordId = rec.id
						const isMyEditRecord = this.isMyEditRecord(rec)
						const isMyDeleteRecord = this.isMyDeleteRecord(rec)
						const hasLog = rec.updated ? true : false

						const callLink = TableActionFormatter.clickToCallLink(this.bridgeName, 'clickToCall', recordId, true)

						const canAccessEdit = this.canPerformAction(CrudAction.update, isMyEditRecord)
						const editLink = TableActionFormatter.editLink(
							this.bridgeName,
							this.isGlobalAccount ? 'editRecordForGlobal' : 'editRecord',
							recordId,
							canAccessEdit,
						)

						const canAccessDelete = this.canPerformAction(CrudAction.delete, isMyDeleteRecord) && !rec.exported
						const deleteLink = TableActionFormatter.deleteLink(this.bridgeName, 'deleteRecord', recordId, canAccessDelete)

						const canAccessGotoRecord = this.userAllowedCompanies.includes(0) || this.userAllowedCompanies.includes(rec.company_id)
						const gotoCompanyRecordLink = this.isGlobalAccount
							? TableActionFormatter.gotoCompanyRecordLink(this.bridgeName, 'gotoCompanyRecord', recordId, canAccessGotoRecord)
							: ''

						const auditLink = TableActionFormatter.auditLinkGlobal(
							'transaction_log',
							recordId,
							rec.job_date + ' / ' + rec.employee_name,
							true,
							!!rec.updated,
						)

						const supportLink = TableActionFormatter.supportLink(this.bridgeName, 'contactSupport', rec.id, 4)

						const renderData = this.isGlobalAccount
							? `<span class="act-ico-wrap">${gotoCompanyRecordLink}</span>`
							: `<span class="act-ico-wrap">${editLink}${deleteLink}${callLink}${auditLink}${supportLink}</span>`
						this.renderCache.setRenderData(rec.id, 'actions', renderData)
						return renderData
					},
				},
			],
			createdRow: (row, data: any, dataIndex) => {
				this.setRowHighlight(row, data)
			},
			drawCallback: (settings) => {
				log('Datatables redraw')
				this.transactionTable?.columns.adjust().responsive.recalc()

				this.enableAllTooltips()
				const event = new GenericEvent('tableDraw', 'transactionsTable')
				this.coreSrvc.eventSrvc.postGenericEvent(event)
				this.coreSrvc.displaySrvc.stopSectionLoader()
				this.renderCache.invalidateCache()

				this.areColumnsHidden = this.transactionTable?.responsive.hasHidden() || false

				// performance.mark('render:tableDraw:end')
				// const measure = performance.measure('table-draw', 'render:tableDraw:start', 'render:tableDraw:end')
				// log('performance', measure)
			},
		})
		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter('transactionsTable', this.transactionTable)

		$('#transactionsTable').on('search.dt', () => {
			const input = this.transactionTable.search()
			this.debounceTableSearch(input)
		})
		$('#transactionsTable').on('order.dt', () => {
			// log('Recalculating table layout')
			this.transactionTable.columns.adjust().responsive.recalc()
		})
		$('#transactionsTable').on('page', () => {
			this.transactionTable.columns.adjust().responsive.recalc()
			this.transactionTable['fixedHeader'].adjust()
		})
	}

	processDtFilterSearch(filterInput: string) {
		this.filterEvent.emit(filterInput)
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'enableNotifications':
				help.header = 'Enable Notifications'
				help.message = `This option allows you to stop and start notifications for this transaction.`
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = 'No help information for this topic is currently available.'
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
