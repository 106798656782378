export interface ITimezone {
	zone_id: number
	country_code: string
	zone_name: string
	display_name: string
	active: boolean
	order: number
}

export class Timezone implements ITimezone {
	zone_id: number = null
	country_code: string = null
	zone_name: string = null
	display_name: string = null
	active: boolean = null
	order: number = null

	constructor(record?: ITimezone) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
		}
		// if (!this.display_name) { this.display_name = this.zone_name }
	}
}
