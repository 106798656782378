import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { log } from '@app/helpers'
import { AuditActionEvent, SecureFileListItem } from '@app/models'
import { CoreService } from '@app/services'

@Component({
    selector: 'app-secure-files-list-item',
    templateUrl: './secure-files-list-item.component.html',
    styleUrls: ['./secure-files-list-item.component.scss'],
    standalone: false
})
export class SecureFilesListItemComponent implements OnInit {
	@Input() item: SecureFileListItem
	@Output() viewItem = new EventEmitter<SecureFileListItem>()
	@Output() downloadItem = new EventEmitter<SecureFileListItem>()
	@Output() editItem = new EventEmitter<SecureFileListItem>()
	@Output() deleteItem = new EventEmitter<SecureFileListItem>()
	@Output() unlinkItem = new EventEmitter<SecureFileListItem>()
	@Output() auditRecord = new EventEmitter<SecureFileListItem>()

	constructor(private coreSrvc: CoreService) {}

	ngOnInit(): void {}

	public canViewItem(item: SecureFileListItem): boolean {
		const extension = item.record.file_extension
		switch (extension) {
			case 'txt':
			case 'pdf':
			case 'png':
			case 'gif':
			case 'jpg':
			case 'jpeg':
				return true
			default:
				return false
		}
	}

	public downloadBtnClicked(item: SecureFileListItem) {
		item.isDownloading = true
		this.downloadItem.next(item)
	}

	public deleteItemBtnClicked(item: SecureFileListItem) {
		item.isDeleting = true
		this.deleteItem.next(item)
	}
}
