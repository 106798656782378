import { Injectable } from '@angular/core'
import { CognitoUtil, Callback } from '@app/services/aws/cognito.service'
import { log } from '@app/helpers'

import AWS from 'aws-sdk/global'

// MIGRATED

@Injectable({
	providedIn: 'root',
})
export class AwsUtil {
	public static firstLogin = false
	public static runningInit = false

	static getCognitoParametersForIdConsolidation(idTokenJwt: string): {} {
		log('AwsUtil: enter getCognitoParametersForIdConsolidation()')
		const url = 'cognito-idp.' + CognitoUtil._REGION.toLowerCase() + '.amazonaws.com/' + CognitoUtil._USER_POOL_ID
		const logins: Array<string> = []
		logins[url] = idTokenJwt
		const params = {
			IdentityPoolId: CognitoUtil._IDENTITY_POOL_ID /* required */,
			Logins: logins,
		}
		return params
	}

	constructor(public cognitoUtil: CognitoUtil) {
		log('Creating AwsUtil')
		AWS.config.region = CognitoUtil._REGION
	}

	/**
	 * This is the method that needs to be called in order to init the aws global creds
	 */
	initAwsService(callback: Callback, isLoggedIn: boolean, idToken: string) {
		if (AwsUtil.runningInit) {
			// Need to make sure I don't get into an infinite loop here, so need to exit if this method is running already
			log("AwsUtil: Aborting running initAwsService()...it's running already.")
			// instead of aborting here, it's best to put a timer
			if (callback != null) {
				callback.callback()
				callback.callbackWithParam(null)
			}
			return
		}

		log('AwsUtil: Running initAwsService()')
		AwsUtil.runningInit = true

		// First check if the user is authenticated already
		if (isLoggedIn) {
			this.setupAWS(isLoggedIn, callback, idToken)
		}
	}

	/**
	 * Sets up the AWS global params
	 *
	 * @param isLoggedIn
	 * @param callback
	 */
	setupAWS(isLoggedIn: boolean, callback: Callback, idToken: string): void {
		log('AwsUtil: in setupAWS()')
		if (isLoggedIn) {
			log('AwsUtil: User is logged in')
			// Setup mobile analytics
			const options = {
				appId: 'cf12922978c14ca2aef53f34ba4733a0',
				appTitle: 'tts-admin-portal',
			}

			// TODO: Fix
			// const mobileAnalyticsClient = new AMA.Manager(options);
			// mobileAnalyticsClient.submitEvents();

			this.addCognitoCredentials(idToken)

			log('AwsUtil: Retrieving the id token')
		} else {
			log('AwsUtil: User is not logged in')
		}

		if (callback != null) {
			callback.callback()
			callback.callbackWithParam(null)
		}

		AwsUtil.runningInit = false
	}

	addCognitoCredentials(idTokenJwt: string): void {
		const creds = this.cognitoUtil.buildCognitoCreds(idTokenJwt)

		AWS.config.credentials = creds

		creds.get((err) => {
			if (!err) {
				if (AwsUtil.firstLogin) {
					// save the login info to DDB
					// this.ddb.writeLogEntry('login')
					AwsUtil.firstLogin = false
				}
			}
		})
	}
}
