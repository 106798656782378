<div [hidden]="!fileUploadManager.areUploadSlotsAvailable" [class.fc-inactive]="selectedFileForEdit || selectedFileForDelete">
	<p-fileUpload
		#fileUploadComponent
		name="myfile[]"
		[customUpload]="true"
		chooseLabel="Upload"
		[auto]="true"
		[multiple]="true"
		(uploadHandler)="uploadHandler($event)"
	>
		<ng-template let-file pTemplate="content">
			<div class="upload-info">
				<div *ngIf="!fileUploadManager.isUploading && !fileUploadManager.isProcessing">
					Click the Upload button to attach files or drag and drop the files here.
				</div>
				<div *ngIf="fileUploadManager.isUploading" class="upload-active">
					<i class="fas fa-loader fa-spin"></i> Uploading File ... {{ fileUploadManager.percentComplete }}%
				</div>
				<div *ngIf="fileUploadManager.isProcessing" class="upload-active"><i class="fas fa-loader fa-spin"></i> Processing File ...</div>
			</div>
		</ng-template>
		<ng-template pTemplate="file">
			<!-- Template for individual file -->
		</ng-template>
	</p-fileUpload>
	<div *ngIf="fileUploadManager.fileLimit" style="color: gray">
		Attachment slots available: {{ fileUploadManager.fileLimit - fileUploadManager.processedFiles.length }}
	</div>
</div>

<div *ngIf="!fileUploadManager.areUploadSlotsAvailable" class="alert alert-info" style="text-align: center">
	The limit of {{ fileUploadManager.fileLimit }} file attachment(s) has been reached.
</div>

<div *ngIf="fileUploadManager.hasDuplicateFiles" class="alert alert-warning" style="text-align: center; margin-top: 15px">
	Potential duplicates detected
</div>

<div *ngIf="fileUploadManager.errorFiles.length > 0" style="margin-top: 25px">
	<div class="float-right link-text" (click)="fileUploadManager.errorFiles = []">clear</div>
	<div class="tts-hl-2" style="font-weight: bold">Unsupported Files</div>
	<hr class="files-invalid-hr" />
	<div *ngFor="let errFile of fileUploadManager.errorFiles; let idx = index">
		<!-- <div class="col-11"> -->
		<div class="unsupported-file" [id]="'unsupportedFile_' + idx">{{ errFile.name }}</div>
		<!-- </div> -->
	</div>
	<hr class="files-invalid-hr" />
	<div class="text-muted" style="font-size: small">The files listed above are not supported</div>
</div>

<div>
	<div *ngIf="fileUploadManager.errorFiles.length > 0" class="tts-hl-2" style="margin-top: 25px; font-weight: bold">Attached Files</div>
	<div *ngFor="let upFile of fileUploadManager.processedFiles" [id]="upFile.fileId">
		<div
			class="file-card"
			[class.fc-active]="(selectedFileForEdit && upFile === selectedFileForEdit) || (selectedFileForDelete && upFile === selectedFileForDelete)"
			[class.fc-inactive]="(selectedFileForEdit && upFile !== selectedFileForEdit) || (selectedFileForDelete && upFile !== selectedFileForDelete)"
		>
			<div class="row">
				<div class="col-2">
					<div><i [class]="upFile.faIconClass" class="file-icon" (click)="viewFile(upFile)"></i></div>
				</div>
				<div class="col-10">
					<div style="margin-left: -10px">
						<div>
							<!-- <div [hidden]="selectedFileForDelete === upFile" class="float-right close-btn" (click)="removeFile(upFile)">X</div> -->
							<!-- <div class="file-upload-info">
								Uploaded: {{ upFile.date }}
							</div> -->
							<div class="file-name">{{ upFile.linkText }}</div>
						</div>
						<div class="file-actions">
							<!-- <div class="link-text" (click)="viewFile(upFile)">view</div> |  -->
							<div class="link-text" (click)="removeFile(upFile)">delete</div>
							<span> | </span>
							<div class="link-text" (click)="toggleEdit(upFile)">{{ upFile === selectedFileForEdit ? 'done editing' : 'edit' }}</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="selectedFileForEdit === upFile || selectedFileForDelete === upFile" class="file-description">
				<div [id]="'img_block_id_' + upFile.fileId">
					<div *ngIf="upFile === selectedFileForEdit">
						<div *ngIf="canEditLinkText" class="file-name-input">
							<input type="text" [(ngModel)]="upFile.linkText" placeholder="Link text" class="form-control" />
						</div>
						<textarea class="file-description-textarea" placeholder="Optional file description" [(ngModel)]="upFile.description"></textarea>
					</div>

					<div class="image-preview-block">
						<div *ngIf="isImage(upFile) && !isFileMissing(upFile)">
							<img [src]="upFile.fileUrl" width="100%" (click)="viewFile(upFile)" style="cursor: pointer; border-radius: 5px" />
						</div>
						<div *ngIf="isFileMissing(upFile)">
							<div style="font-weight: bold; font-size: 1em; color: chocolate">&lt; Image File Missing &gt;</div>
						</div>
						<div *ngIf="!isImage(upFile)">
							<div class="link-text" style="font-weight: bold; font-size: 1em" (click)="viewFile(upFile)">View File</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="selectedFileForDelete === upFile" class="remove-block">
				<!-- <hr /> -->
				<div class="float-right">
					<button class="btn btn-sm btn-secondary" style="margin-right: 12px" (click)="removeFileConfirmation(false)">Cancel</button>
					<button class="btn btn-sm btn-danger" (click)="removeFileConfirmation(true)">Delete</button>
				</div>
				<div style="padding: 3px 0px 6px 0px">Are you sure?</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="!fileUploadManager.isWorking" [hidden]="true">
	<div *ngFor="let cacheFile of fileUploadManager.processedFiles">
		<img *ngIf="isImage(cacheFile)" [src]="cacheFile.fileUrl" (error)="addToMissingFilesList(cacheFile)" width="1px" height="1px" />
	</div>
</div>
