<div class="color-picker">
	<div class="summary-info" (click)="toggleColors()">
		<div class="info">
			<p class="options-title">{{ heading }}</p>
			<p class="style-value">{{ color }}</p>
		</div>

		<div class="circle" [ngStyle]="{'background': color}"></div>
	</div>

	<div *ngIf="show" class="opened">
		<div class="colors">
			<div (click)="changeColor(paint)" *ngFor="let paint of defaultColors" class="circle" [ngStyle]="{'background': paint}"></div>
		</div>

		<!-- <div class="hex-code">
			<p>Hex Code</p>
			<div class="g-input">
				<input type="text" maxlength="7" [value]="color"
					(keyup)="changeColorManual(paintInput.value)"
					(keydown.enter)="toggleColors()"
					#paintInput />
			</div>
		</div> -->
	</div>
</div>