import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { CoreService, DatabaseService } from '@app/services'
import { DateTimeHelper, log } from '@app/helpers'

import { DialogManager, ScheduleChangeComment, ScheduleChangeRequest } from '@app/models'
import { ScheduleChangeRequestCard } from '../models'

import { Frequency } from 'rrule'

import moment from 'moment-timezone'

@Component({
    selector: 'app-schedule-changes-list',
    templateUrl: './changes-list.component.html',
    styleUrls: ['./changes-list.component.scss'],
    standalone: false
})
export class ScheduleChangesListComponent implements OnInit {
	isLoading = true
	Frequency = Frequency

	reviewAction = { schedRecurId: null, changeReqId: null }
	editDialogManager = new DialogManager('scheduleChangeRequestEdit')
	commentDialogManager = new DialogManager('scheduleChangeRequestComment')
	filteredChangeList: Array<ScheduleChangeRequestCard> = []

	selectedCard: ScheduleChangeRequestCard
	newComment = ''
	timeFormat = 'h:mm a'

	@Input() filterId = null
	@Output() actionComplete = new EventEmitter<string>()

	constructor(private coreSrvc: CoreService) {
		this.loadData()
		this.timeFormat = DateTimeHelper.format12Hour ? 'h:mm a' : 'HH:mm'
	}

	ngOnInit(): void {}

	loadData() {
		this.isLoading = true
		this.coreSrvc.dbSrvc.bulkRead(['schedule_change_request']).then((result) => {
			log('read change request', result)
			this.updateList()
			this.isLoading = false
		})
	}

	updateList() {
		this.filteredChangeList = this.coreSrvc.dbSrvc.schedulerSrvc
			.getChangeRequests()
			.map((cr) => new ScheduleChangeRequestCard(this.coreSrvc.dbSrvc, cr))
			.filter((cr) => cr.changeRequest.request_status === 'PENDING')
		log('Filtered Change List', this.filteredChangeList)
	}

	makeComment(event: string, comment: string): ScheduleChangeComment {
		const user = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
		const userName = `${user.first_name} ${user.last_name}`
		const userId = user.id
		return new ScheduleChangeComment({
			event: event,
			content: comment,
			date: moment().toISOString(),
			name: userName,
			userId: userId,
			role: 'ADMIN',
		})
	}

	addComment(card: ScheduleChangeRequestCard) {
		card.commentPlaceholder = `Add a comment and click 'Confirm' to submit.`
		card.confirmChangeMessage = 'Add Comment Confirmation'
		card.action = 'COMMENT'
	}

	rejectChange(card: ScheduleChangeRequestCard) {
		card.commentPlaceholder = `Add a final comment and click 'Confirm' to reject this schedule change request.`
		card.confirmChangeMessage = 'Reject Change Request Confirmation'
		card.action = 'REJECTED'
	}

	approveChange(card: ScheduleChangeRequestCard) {
		card.commentPlaceholder = `Add a final comment and click 'Confirm' to approve this schedule change request.`
		card.confirmChangeMessage = 'Approve Change Request Confirmation'
		card.action = 'APPROVED'
	}

	cancelAction(card: ScheduleChangeRequestCard) {
		card.commentPlaceholder = 'Add a comment'
		card.confirmChangeMessage = ''
		card.action = ''
		card.actionComment = ''
	}

	confirmAction(card: ScheduleChangeRequestCard) {
		this.performAction(card)
	}

	performAction(card: ScheduleChangeRequestCard) {
		const changeRequest = card.changeRequest
		const updateRecord = new ScheduleChangeRequest(changeRequest)
		const action = card.action
		const actionComment = card.actionComment

		if (action === 'APPROVED') {
			const approveComment = this.makeComment('APPROVED', actionComment ?? 'Change request approved.')
			updateRecord.addComment(approveComment)
			updateRecord.request_status = 'APPROVED'
		}
		if (action === 'REJECTED') {
			const rejectComment = this.makeComment('REJECTED', actionComment ?? 'Change request rejected.')
			updateRecord.addComment(rejectComment)
			updateRecord.request_status = 'REJECTED'
		}
		if (action === 'COMMENT') {
			const basicComment = this.makeComment('COMMENT', actionComment ?? 'Added empty comment.')
			updateRecord.addComment(basicComment)
		}

		log('Update Record', updateRecord)
		this.coreSrvc.dbSrvc.updateRecord('schedule_change_request', updateRecord).then((success) => {
			if (success) {
				if (action === 'APPROVED') {
					this.coreSrvc.notifySrvc.notify('info', 'Change Request Updated', 'The schedule change request has been marked as approved.', 4)
					changeRequest.request_status = 'APPROVED'
				}
				if (action === 'REJECTED') {
					this.coreSrvc.notifySrvc.notify('info', 'Change Request Updated', 'The schedule change request has been marked as rejected.', 4)
					changeRequest.request_status = 'REJECTED'
				}
				if (action === 'COMMENT') {
					this.coreSrvc.notifySrvc.notify('info', 'Change Request Updated', 'Your comment has been added to the change request.', 4)
				}
				this.updateList()
				this.actionComplete.emit(action)
			}
		})
	}

	reviewBtnClicked(card: ScheduleChangeRequestCard) {
		this.reviewAction.schedRecurId = card.schedRecur.id
		this.reviewAction.changeReqId = card.changeRequest.id
		this.editDialogManager.headerLabel = 'Review Change Request'
		this.editDialogManager.isDialogVisible = true
	}

	recordUpdated(changeReqId: number) {
		log('Change Request update for record id', changeReqId)
		this.updateList()
	}

	formatComment(prop: string, comment: ScheduleChangeComment) {
		if (prop === 'date') {
			const value = comment.date
			return value ? moment(value).format(`MMM Do, YYYY`) : '< date missing >'
		}
		if (prop === 'time') {
			const value = comment.date
			return value ? moment(value).format(`${this.timeFormat}`) : '< time missing >'
		}
		return '< data missing >'
	}
}
