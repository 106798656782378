<div class="section-table">
	<table id="npTable" class="stripe nowrap" cellspacing="0" width="100%"></table>
	<div *ngIf="hasDisabledItems" class="table-footnote">
		<strong>Note: </strong>
		<span style="color: firebrick">Expired entries are grayed out.</span>
	</div>
	<app-table-footer-buttons
		[marginTop]="'15px'"
		[newBtnLabel]="'Add Notification Profile'"
		(newBtnClicked)="createRecord()"
	></app-table-footer-buttons>
	<i id="clear-search-icon" (click)="clearSearch()" class="fa fa-times-circle-o" aria-hidden="true"></i>
</div>

<app-modal-dialog *ngIf="editDialogManager.isDialogVisible" [dialogManager]="editDialogManager">
	<app-notification-profile-edit
		[dialogManager]="editDialogManager"
		[recordId]="editAction.recordId"
		[action]="editAction.action"
		[performParentAction]="false"
		(profileUpdated)="saveActionComplete($event)"
	></app-notification-profile-edit>
</app-modal-dialog>

<app-modal-dialog *ngIf="linkedRecordsDialogManager.isDialogVisible" [dialogManager]="linkedRecordsDialogManager">
	<div style="margin-bottom: 15px">
		<app-search-filter [placeholder]="'filter records'" (search)="filterLinkedRecords($event)"> </app-search-filter>
	</div>

	<ul style="color: gray">
		<li *ngFor="let record of linkedRecordsDialog.filteredRecords">{{ record }}</li>
	</ul>
</app-modal-dialog>

<app-delete-record-dialog
	*ngIf="deleteAction.showDeleteRecordDialog"
	[showDeleteRecordDialog]="deleteAction.showDeleteRecordDialog"
	[tableName]="deleteAction.tableName"
	[recordId]="deleteAction.recordId"
	[recordLabel]="deleteAction.recordLabel"
	(cancelDelete)="deleteActionCancel()"
	(actionComplete)="deleteActionComplete()"
>
</app-delete-record-dialog>

<app-modal-dialog *ngIf="sectionPrefsDialogManager.isDialogVisible" [dialogManager]="sectionPrefsDialogManager">
	<app-local-prefs [dialogManager]="sectionPrefsDialogManager" (prefsUpdated)="prefsDataSaved()"></app-local-prefs>
</app-modal-dialog>
