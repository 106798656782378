<div class="search-box">
	<input type="text" [(ngModel)]="searchText" class="form-control search-field" (keyup)="debounceSearch()" [placeholder]="placeholder" />
	<div class="search-actions">
		<i *ngIf="!searchText" class="fa fa-search"></i>
		<i
			title="Clear Filter"
			*ngIf="searchText"
			class="fa fa-times-circle-o search-clear"
			[class.tts-hl-1]="searchText"
			(click)="searchText = ''; debounceSearch()"
		></i>
	</div>
</div>
