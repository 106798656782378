import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { IVRCallList, IVRCallListItem } from '@app/models'
import { DatabaseService } from '@app/services'
import { parsePhoneNumber } from 'libphonenumber-js'
import _ from 'lodash'

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
    standalone: false
})
export class MaintenanceComponent implements OnInit {
	useCustomBlock = false
	callInList: Array<IVRCallListItem> = []
	constructor(
		private dbSrvc: DatabaseService,
		private location: Location,
	) {
		const list = IVRCallList.retrieveFromLocalStorage()
		this.callInList = _.orderBy(list, 'companyName', 'asc')
	}

	ngOnInit(): void {
		setTimeout(() => {
			this.location.replaceState('/')
			localStorage.removeItem('lastRoute')
		}, 500)
	}

	get lastErrorMsg(): string {
		const lastErrorMsg = this.dbSrvc.lambdaSrvc.lastErrorMsg
		return (
			lastErrorMsg ??
			'The network connection is currently offline. Please make sure your internet access is working and your system clock is properly set and try reloading your browser.'
		)
	}

	formatPhoneNumber(phone: string): string {
		return parsePhoneNumber(phone).formatNational()
	}

	reloadApp() {
		window.location.reload()
	}
}
