import { ValidationErrors, UntypedFormGroup, FormGroup } from '@angular/forms'
import { log } from './logger'
import moment from 'moment-timezone'
import { DateTimeHelper } from './datetime'

export class FormHelper {
	static getFormValidationErrors(formGroup: UntypedFormGroup) {
		Object.keys(formGroup.controls).forEach((key) => {
			const controlErrors: ValidationErrors = formGroup.get(key).errors
			if (controlErrors != null) {
				Object.keys(controlErrors).forEach((keyError) => {
					log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError])
				})
			}
		})
	}

	static formatIsoDuration_Hmm(str: string) {
		if (!str) {
			return null
		}
		// log('Duration String', str)
		const dur = moment.duration(str)
		return DateTimeHelper.formatDuration('H:mm', dur)
	}

	// If only one component then it's minutes. If 2 components H:mm and ss will be added as necessary
	// to construct an H:mm:ss string for conversion to an ISO duration string

	static formDurationToIso(str: string) {
		if (!str) {
			return null
		}
		let durStr = ''
		const comps = str.split(':')
		const count = comps.length
		if (count === 1) {
			durStr = '00:' + str + ':00'
		}
		if (count === 2) {
			durStr = str + ':00'
		}
		const duration = moment.duration(durStr)
		return duration.toISOString()
	}

	// Assume what value means when single number
	// Takes a value in which may be minutes or hours. If the value has both, past it into formDurationToIso
	// which assumes a lone value is in minutes. If only single component, then pad it based on the assume
	// property before passing it to formDurationToIso.
	static formInputToIsoDuration(value: string, assume: 'hours' | 'minutes') {
		if (!value) return null
		if (value.includes('.')) return null

		const hasColon = value.includes(':')
		if (hasColon) {
			return FormHelper.formDurationToIso(value)
		} else {
			if (assume === 'hours') {
				return FormHelper.formDurationToIso(value + ':00')
			} else {
				return FormHelper.formDurationToIso('00:' + value)
			}
		}
	}

	static textAreaAdjust(element: any) {
		// log('TextArea to Expand', element)
		if (element) {
			element.style.height = '1px'
			element.style.height = 25 + element.scrollHeight + 'px'
		}
	}

	// When trimming whitespace, all date pickers must be setting values via forms API and not
	// directly using the form.value.property
	static trimOnlyWhitespace(input: FormGroup | UntypedFormGroup | Record<string, any>): void {
		if (input instanceof FormGroup || input instanceof UntypedFormGroup) {
			// Handle FormGroup or UntypedFormGroup
			Object.keys(input.controls).forEach((key) => {
				const control = input.get(key)

				if (control instanceof FormGroup || control instanceof UntypedFormGroup) {
					// Recursively trim nested form groups
					FormHelper.trimOnlyWhitespace(control)
				} else if (control?.value !== null && typeof control.value === 'string') {
					// Trim leading and trailing whitespace
					const newValue = control.value.trim() === '' ? null : control.value
					// log('NEW FORM VALUE', key, newValue)
					control.setValue(newValue, { emitEvent: false })
				}
			})
		} else if (typeof input === 'object' && input !== null) {
			// Handle standard object
			Object.keys(input).forEach((key) => {
				const value = input[key]
				if (value !== null && typeof value === 'string') {
					// Trim leading and trailing whitespace
					// log('NEW OBJECT VALUE', key, value)
					input[key] = value.trim() === '' ? null : value
				}
			})
		} else {
			throw new Error('Input must be a FormGroup, UntypedFormGroup, or a standard object')
		}
	}
}
