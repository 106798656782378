import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AccessHelper } from '@app/helpers/access'
import {
	ClickToCallSourceConfig,
	ClickToCallGlobalConfig,
	ClickToCallRecord,
	DialogManager,
	SelectorPermissionName,
	AuditActionEvent,
	ClickToCallSourceType,
} from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import {
	SchedulerEventActionsDialogShiftData,
	SchedulerEventActionsDialogViewState,
	SchedulerShiftViewEventActionsDialogManager,
} from '../event-action-dialog-manager'

import { log } from '@app/helpers'
import { environment } from '@env/environment'

import { SelectItem } from 'primeng/api/selectitem'
import { Router } from '@angular/router'

@Component({
    selector: 'app-scheduler-shift-view-event-actions-dialog',
    templateUrl: './event-actions-dialog.component.html',
    styleUrls: ['./event-actions-dialog.component.scss'],
    standalone: false
})
export class SchedulerShiftViewEventActionsDialogComponent implements OnInit {
	didClickManage = false
	canAccessOpenShiftFeature = false
	hasGlobalAccount = false

	public shift: SchedulerEventActionsDialogShiftData
	public viewState = new SchedulerEventActionsDialogViewState()

	public employeeOptions: SelectItem[]
	public selectedEmployee: number = null

	@Input() manager = new SchedulerShiftViewEventActionsDialogManager()
	@Input() eventEditManager: DialogManager

	@Output() recordUpdated = new EventEmitter<boolean>()
	@Output() editEvent = new EventEmitter<number>()

	constructor(
		private coreSrvc: CoreService,
		private router: Router,
	) {
		this.setupEmployeeOptions()

		// Setup check for enabling advanced edit
		const adminPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		this.canAccessOpenShiftFeature = adminPrefs.enableOpenShiftOffers

		// Check for global switcher
		const canAccessGlobalAccount = this.coreSrvc.dbSrvc.settingSrvc.canAccessGlobalAccount()
		this.hasGlobalAccount = canAccessGlobalAccount && this.coreSrvc.dbSrvc.settingSrvc.hasGlobalAccount()
	}

	public ngOnInit(): void {
		// log('eventActionComponent Init')
		this.viewState.option = this.manager.option

		const callAccess = this.coreSrvc.callSrvc.getEnabledStatus()
		if (callAccess.backend || callAccess.browser) {
			this.viewState.canAccessCallBtn = true
		} else {
			this.viewState.canAccessCallBtn = false
		}
		this.shift = new SchedulerEventActionsDialogShiftData(this.coreSrvc.dbSrvc, this.manager.scheduleLog)
	}

	get devDetect() {
		return this.coreSrvc.devDetect
	}

	get selectedEmpForAssignmentPhoneE164(): string {
		const empId = this.selectedEmployee
		if (empId) {
			const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)
			if (emp) {
				return emp.phone_number_e164
			}
		}
		return null
	}

	get hasShiftBeenModified(): boolean {
		const sl = this.shift.schedLog
		return (
			sl.enabled_override ||
			sl.employee_id_override === 0 ||
			!!sl.employee_id_override ||
			!!sl.employee_count_override ||
			!!sl.end_time_override ||
			!!sl.start_time_override
		)
	}

	get isOpenShift(): boolean {
		return this.shift.schedLog.open_shift
	}

	get canShowShiftOfferBtns(): boolean {
		const isShiftEnabled = this.shift.schedLog.effectiveEnabled()
		const doesShiftHaveOffers = this.shift.schedLog.schedule_os_offer.length > 0
		return isShiftEnabled || doesShiftHaveOffers
	}

	get hasShiftBeenOffered(): boolean {
		return this.shift.schedLog.schedule_os_offer.length > 0
	}

	public toggleNotifications() {
		log('Toggle Notifications')
		const currentLog = this.manager.scheduleLog
		currentLog.enable_notifications = !currentLog.enable_notifications
		this.coreSrvc.dbSrvc.updateRecord('schedule_log', currentLog).then((success) => {
			this.recordUpdated.emit(true)
			this.shift.isNotifyEnabled = !this.shift.isNotifyEnabled
			const isNotifyEnabled = this.shift.isNotifyEnabled
			if (isNotifyEnabled) {
				this.coreSrvc.notifySrvc.notify('success', 'Notifications', 'Notifications for this shift are now enabled.', 4)
			} else {
				this.coreSrvc.notifySrvc.notify('error', 'Notifications', 'Notifications for this shift are now disabled.', 4)
			}
		})
	}

	private setupEmployeeOptions() {
		const accessHelper = new AccessHelper(this.coreSrvc, 'schedule')
		const permissions = accessHelper.getPermissionsFor('schedule')
		const isRestricted = permissions.isSelectorRestrictedFor(SelectorPermissionName.employee)
		const isManager = this.coreSrvc.dbSrvc.settingSrvc.isUserAManager()

		// Add any employees that you might not own that were set by unrestricted user
		const addEmpIds = [] // this.manager.getOriginalEmployeeIds() || []
		addEmpIds.push(0) // Slip in Any Employee

		const dropdown = this.coreSrvc.dbSrvc.empSrvc
			.getEmployeeDropdown(this.coreSrvc.dbSrvc, isRestricted, isManager, addEmpIds)
			.filter((item) => item.data.active === true)
		dropdown.unshift({
			label: `Select Employee`,
			value: null,
			data: null,
		})
		this.employeeOptions = dropdown
	}

	public triggerC2CEvent(type: ClickToCallSourceType, action: 'CALL' | 'TEXT') {
		let callRecord: ClickToCallRecord

		if (type === 'EMPLOYEEPHONE') {
			const desc = this.shift.empName
			const phone = this.shift.empPhoneE164
			callRecord = phone ? new ClickToCallRecord(type, desc, null, phone, null) : null
			callRecord.destEmpId = this.shift.empId
		}

		if (type === 'EMPLOYEESUPERVISOR') {
			const desc = this.shift.empSupName
			const phone = this.shift.empSupPhoneE164
			callRecord = phone ? new ClickToCallRecord(type, desc, null, phone, null) : null
			callRecord.destUserId = this.shift.empSupId
		}

		if (type === 'SITESUPERVISOR') {
			const desc = this.shift.jobSiteSupName
			const phone = this.shift.jobSiteSupPhoneE164
			callRecord = phone ? new ClickToCallRecord(type, desc, null, phone, null) : null
			callRecord.destUserId = this.shift.jobSiteSupId
		}

		if (callRecord) {
			const sourceConfig = new ClickToCallSourceConfig()
			sourceConfig.type = 'SCHEDLOG'
			sourceConfig.sourceId = this.shift.schedLog.id

			const globalConfig = new ClickToCallGlobalConfig(callRecord, action, null, null)
			globalConfig.sourceConfig = sourceConfig

			this.coreSrvc.callSrvc.hideC2CTriggerHost()
			this.coreSrvc.eventSrvc.clickToCallGlobalEvent.next(globalConfig)
		}
	}

	public callOrTextEmpSelectedForAssignment(action: 'CALL' | 'TEXT' | 'LOG') {
		const empId = this.selectedEmployee
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(this.selectedEmployee)
		if (emp) {
			const desc = emp.name
			const phone = emp.phone_number_e164
			const callRecord = desc && phone ? new ClickToCallRecord('EMPLOYEEPHONE', desc, null, phone, null) : null
			if (callRecord) {
				const sourceConfig = new ClickToCallSourceConfig()
				sourceConfig.type = 'SCHEDLOG'
				sourceConfig.sourceId = this.shift.schedLog.id

				const globalConfig = new ClickToCallGlobalConfig(callRecord, action, null, null)
				globalConfig.sourceConfig = sourceConfig

				this.coreSrvc.callSrvc.hideC2CTriggerHost()
				this.coreSrvc.eventSrvc.clickToCallGlobalEvent.next(globalConfig)
			}
		}
	}

	public assignEmployee() {
		this.viewState.switchTo('assignEmployee')
	}

	public assignShift() {
		const currentLog = this.manager.scheduleLog
		currentLog.employee_id_override = this.selectedEmployee
		this.viewState.switchTo('processingView')
		this.coreSrvc.dbSrvc.updateRecord('schedule_log', currentLog).then((success) => {
			this.recordUpdated.emit(true)
			this.manager.showDialog = false
		})
	}

	public unAssignEmployee() {
		const currentLog = this.manager.scheduleLog
		currentLog.employee_id_override = null
		this.viewState.switchTo('processingView')
		this.coreSrvc.dbSrvc.updateRecord('schedule_log', currentLog).then((success) => {
			// log('openShift lambda returned')
			this.recordUpdated.emit(true)
			this.manager.showDialog = false
		})
	}

	public revertShift() {
		const currentLog = this.manager.scheduleLog
		currentLog.employee_id_override = null
		currentLog.enabled_override = null
		this.viewState.switchTo('processingView')
		this.coreSrvc.dbSrvc.updateRecord('schedule_log', currentLog).then((success) => {
			// log('revertShift lambda returned')
			this.recordUpdated.emit(true)
			this.manager.showDialog = false
		})
	}

	public offerShift() {
		log('Offer SHift')
		this.manager.showDialog = false
		setTimeout(() => {
			this.coreSrvc.dbSrvc.schedulerSrvc.offerOpenShiftEvent.next(this.manager.scheduleLog)
		}, 300)
	}

	public manageOffer() {
		const currentOffer = this.manager.scheduleLog.schedule_os_offer[0]
		if (currentOffer?.status === 'FILLED_APPROVED' || currentOffer?.status === 'FILLED_AUTO') {
			this.coreSrvc.notifySrvc.notify('error', 'Offer Filled', 'This current shift offer has been filled and may not be modified.')
			return
		}
		if (this.didClickManage) return
		this.didClickManage = true
		this.offerShift()
	}

	public cancelOffer() {
		this.viewState.switchTo('confirmCancelOffer')
		return
	}

	public confirmCancelOffer(confirm: boolean) {
		if (!confirm) {
			this.viewState.switchTo('extendedMenu')
			return
		}
		const offer = this.shift.schedLog.schedule_os_offer[0]
		if (offer) {
			const offerId = offer.id
			this.coreSrvc.dbSrvc.deleteRecord('schedule_os_offer', offerId).then((success) => {
				const removedOffer = this.shift.schedLog.schedule_os_offer.shift()
				log('Removed OS offer from shift', removedOffer)
				this.coreSrvc.dbSrvc.schedulerSrvc.fetchAndReloadAllDataForShiftViewEvent.next(true)
				this.coreSrvc.notifySrvc.notify('success', 'Offer Removed', 'This shift is no longer being offered as available.')
				this.manager.showDialog = false
			})
		}
	}

	public splitShift() {
		setTimeout(() => {
			this.coreSrvc.notifySrvc.notify('warn', 'Split Shift', 'This feature is not yet implemented.')
		}, 300)
		this.manager.showDialog = false
	}

	public editShift() {
		this.manager.showDialog = false
		setTimeout(() => {
			this.eventEditManager.headerLabel = 'Edit Shift'
			this.eventEditManager.dialogData = this.manager.scheduleLog
			this.eventEditManager.isDialogVisible = true
		}, 300)
	}

	// public disableShift() {}

	public deleteShift() {
		this.viewState.switchTo('confirmDelete')
	}

	public confirmDelete(confirm: boolean) {
		if (!confirm) {
			this.viewState.switchTo('extendedMenu')
			return
		}
		const currentLog = this.manager.scheduleLog
		this.viewState.switchTo('processingView')
		this.coreSrvc.dbSrvc.deleteRecord('schedule_log', currentLog.id).then((success) => {
			log('openShift lambda returned')
			this.recordUpdated.emit(true)
			this.manager.showDialog = false
		})
	}

	public enableShift() {
		const currentLog = this.manager.scheduleLog
		currentLog.enabled_override = true
		this.viewState.switchTo('processingView')
		this.coreSrvc.dbSrvc.updateRecord('schedule_log', currentLog).then((success) => {
			log('openShift lambda returned')
			this.recordUpdated.emit(true)
			this.manager.showDialog = false
		})
	}

	public editSeries() {
		const currentLog = this.manager.scheduleLog
		const schedRecurId = currentLog.schedule_id

		// Check if schedule is availabe for edit
		const sched = this.coreSrvc.dbSrvc.schedulerSrvc.getScheduleForId(schedRecurId)
		if (!sched) {
			this.coreSrvc.notifySrvc.notify(
				'info',
				'Expired Schedule',
				'The schedule which created this shift has expired and is not available for editing.',
			)
			return
		}
		this.manager.showDialog = false
		setTimeout(() => {
			this.coreSrvc.dbSrvc.schedulerSrvc.scheduleRecurEditRecordId.next(schedRecurId)
		}, 500)
	}

	public disableShift() {
		this.viewState.switchTo('confirmClose')
	}

	public confirmDisableShift(confirm: boolean) {
		if (!confirm) {
			this.viewState.switchTo('mainMenu')
			return
		}
		const currentLog = this.manager.scheduleLog
		currentLog.enabled_override = false
		this.viewState.switchTo('processingView')
		this.coreSrvc.dbSrvc.updateRecord('schedule_log', currentLog).then((success) => {
			this.recordUpdated.emit(true)
			this.manager.showDialog = false
		})
	}

	public cancelAction() {
		this.manager.showDialog = false
	}

	public empPhoneForEventAction() {
		const empId = this.manager.scheduleLog?.employee_id
		if (empId) {
			const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)
			if (emp) {
				return emp.phone_number_e164
			}
		}
		return null
	}

	public supPhoneForEventAction() {
		const supId = this.manager.scheduleLog?.emp_supervisor_id
		if (supId) {
			const sup = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(supId)
			if (sup) {
				return sup.phone_e164
			}
		}
		return null
	}

	public eventMenuBtnClicked() {
		alert('Menu for more options if needed')
	}

	public auditRecord() {
		const footerLabel = this.shift.schedLog.job_date + ' / ' + this.shift.jobName
		const auditActionEvent = new AuditActionEvent('schedule_log', this.shift.schedLog.id, footerLabel)
		this.manager.showDialog = false
		setTimeout(() => {
			this.coreSrvc.eventSrvc.showAuditLog.next(auditActionEvent)
		}, 250)
	}

	public gotoGolbalView() {
		const globalId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().global_id
		const switchName = this.coreSrvc.dbSrvc.settingSrvc.getAllowedCompanyForId(globalId)?.name ?? 'Global Account'
		const empId = this.shift.empId
		const viewDate = this.shift.schedLog.job_date
		const url = `/redirect/params`
		this.coreSrvc.notifySrvc.notify('info', 'Global View', `Switching to ${switchName}`, 3)
		log('url', url)
		setTimeout(() => {
			this.coreSrvc.dbSrvc.clearServiceData()
			this.coreSrvc.zone.run(() =>
				this.router.navigate([url], { queryParams: { dlc: 'ssvfewd', cid: globalId, empId: empId, viewDate: viewDate } }),
			)
		}, 500)
	}
}
