import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DisplayHelper, log } from '@app/helpers'
import { AppVersion } from '@app/models'
import { environment } from '@env/environment'
import { Global } from '@app/models/global'

import moment from 'moment-timezone'

@Injectable({ providedIn: 'root' })
export class AppService {
	redirectBackLinkRoute: string = null

	constructor(private http: HttpClient) {
		log('Creating AppService')
		this.startVersionChecker()
	}

	private vCheckInterval = 60000 * 10 // 10 minutes
	private vCheckTimer: NodeJS.Timeout

	private startVersionChecker(): void {
		const domain = window.location.hostname
		if (domain === 'localhost' || domain === '127.0.0.1') return

		this.vCheckTimer = setInterval(() => {
			this.getVersionAndProcessResults()
		}, this.vCheckInterval)
	}

	private processResult(result: string): void {
		log('Version Check local/remote', AppVersion.version, result)
		if (this.doesVersionNeedUpdate(result)) {
			if (DisplayHelper.isUpdateToastMessageOnScreen()) return
			Global.coreSrvc.notifySrvc.notificationClear.next(true)
			Global.coreSrvc.notifySrvc.notify(
				'info',
				'Application Update',
				`An update is available. Please reload the app to ensure you have the latest version.`,
			)
		}
	}

	private doesVersionNeedUpdate(version: string): boolean {
		if (version) {
			const fetchedVersion = version.split('b')?.[0]
			const [fMajor, fMinor, fPatch] = fetchedVersion.split('.').map(Number)
			const currentVersion = AppVersion.version.split('b')?.[0]
			const [cMajor, cMinor, cPatch] = currentVersion.split('.').map(Number)

			// log('Current', currentVersion, 'Fetched', fetchedVersion)
			// log('fMajor', fMajor, 'fMinor', fMinor, 'fPatch', fPatch)
			// log('cMajor', cMajor, 'cMinor', cMinor, 'cPatch', cPatch)

			if (fMajor > cMajor || fMinor > cMinor || fPatch > cPatch) return true
		}
		return false
	}

	public getVersion(): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			const timestamp = moment().format('YYYYMMDDTHHmmssSSS')
			const endpoint = `https://${environment.portalDomain}/version.json`
			// log('endpoint', endpoint)
			const options = {
				responseType: 'text' as const,
			}
			this.http.get(endpoint, options).subscribe((result) => {
				try {
					const parsedResponse = JSON.parse(result)
					const version = parsedResponse.version as string
					resolve(version)
				} catch (erro) {
					log('Got error with IP')
					resolve(null)
				}
			})
		})
	}

	public getVersionAndProcessResults() {
		const domain = window.location.hostname
		if (domain === 'localhost' || domain === '127.0.0.1') return

		this.getVersion().then((result) => {
			this.processResult(result)
		})
	}
}
