import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ClickToCallGlobalConfig, ClickToCallRecord, EmployeeRecord, ScheduledAnnouncementEmployeeInfoCard } from '@app/models'
import { CoreService } from '@app/services'

@Component({
    selector: 'app-scheduled-ann-emp-info-card',
    templateUrl: './scheduled-ann-emp-info-card.component.html',
    styleUrl: './scheduled-ann-emp-info-card.component.scss',
    standalone: false
})
export class ScheduledAnnEmpInfoCardComponent {
	@Input() card: ScheduledAnnouncementEmployeeInfoCard
	@Input() enableSelect = false
	@Output() removeCard = new EventEmitter<ScheduledAnnouncementEmployeeInfoCard>()

	constructor(private coreSrvc: CoreService) {}

	public clickToCall(emp: EmployeeRecord, action: 'CALL' | 'TEXT') {
		const record = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(emp.id)
		const name = record.first + ' ' + record.last
		let number = record.phone_number_e164
		if (name && number) {
			const c2cRecord = new ClickToCallRecord('EMPLOYEEPHONE', name, null, number, null)
			c2cRecord.destEmpId = emp.id
			const config = new ClickToCallGlobalConfig(c2cRecord, action, null, null)
			this.coreSrvc.eventSrvc.clickToCallGlobalEvent.next(config)
		}
	}
}
