import { AfterViewInit, Component, OnDestroy } from '@angular/core'
import { log } from '@app/helpers'
import { CoreService } from '@app/services'
import { Subscription } from 'rxjs'

import moment from 'moment-timezone'

@Component({
    selector: 'app-billing-date-info',
    templateUrl: './billing-date-info.component.html',
    styleUrl: './billing-date-info.component.scss',
    standalone: false
})
export class BillingDateInfoComponent implements AfterViewInit, OnDestroy {
	billingHeader: string = null
	trialDateInfo: string = null
	billingDateInfo: string = null
	hideBillingInfo = false

	timezoneShort = ''

	private subs = new Subscription()

	constructor(private coreSrvc: CoreService) {
		this.updateUI()
	}

	ngAfterViewInit(): void {
		this.subs.add(this.coreSrvc.dbSrvc.billSrvc.updateUiForBillingComponents.subscribe({ next: () => this.updateUI() }))

		// Status / Billing alert messages updated
		this.subs.add(this.coreSrvc.dbSrvc.billSrvc.billingMessageEvent.subscribe((event) => this.updateUI()))
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	get hasTrialExpired(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.hasTrialExpired()
	}

	get isPrepaid(): boolean {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		if (company && company.account_status === 'PREPAID') {
			return true
		}
		return false
	}

	get isInvoice(): boolean {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		if (company && (company.account_status === 'INVOICE' || company.account_status === 'ADP_INVOICE')) {
			return true
		}
		return false
	}

	updateUI() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		if (!company) {
			return
		}

		const timezone = company.timezone
		const today = new Date()
		const companyCreated = moment.tz(company.created, 'YYYY-MM-DD', timezone)
		const daysSinceCreated = Math.abs(companyCreated.diff(today, 'days'))

		log('Days Since Created', daysSinceCreated)

		this.timezoneShort = this.coreSrvc.dbSrvc.settingSrvc.getTimezoneDisplayNameForZoneName(timezone)

		// Figure out what to call the billing header
		const trialExpires = this.coreSrvc.dbSrvc.settingSrvc.trialExpiresDate()
		const trialExpiresMom = moment.tz(trialExpires, 'YYYY-MM-DD', timezone)

		if (!company.paypal_credit_card_id && !this.isPrepaid && !this.isInvoice) {
			this.billingHeader = 'Trial Expires On'
			const accountStatus = company.account_status
			if (accountStatus === 'TESTING') {
				this.billingHeader = 'Testing Account'
				this.trialDateInfo = 'No Expiration'
				return
			}
			if (this.hasTrialExpired) {
				this.trialDateInfo = 'Trial Has Expired'
			} else {
				this.trialDateInfo = trialExpiresMom.format('ddd, MMM Do, YYYY')
			}
			if (daysSinceCreated > 31 && this.hasTrialExpired) {
				this.hideBillingInfo = true
			}
		} else {
			this.billingHeader = 'Next Billing Date'
			const billingDay = company.billing_day
			const billingMom = moment({ day: billingDay })
			const todayMom = moment()
			this.hideBillingInfo = false
			log('Billing day this month', billingDay)
			if (todayMom.isAfter(billingMom, 'day') || todayMom.isSame(trialExpiresMom, 'month')) {
				this.billingDateInfo = billingMom.clone().add(1, 'month').format('ddd, MMM Do, YYYY')
				return
			}
			if (todayMom.isBefore(billingMom, 'day')) {
				this.billingDateInfo = billingMom.clone().format('ddd, MMM Do, YYYY')
				return
			}
			if (todayMom.isSame(billingMom, 'day')) {
				this.billingDateInfo = 'Today'
				return
			}
			this.billingDateInfo = billingMom.format('ddd, MMM Do, YYYY')
		}
	}
}
