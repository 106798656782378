import { DateTimeHelper, DisplayHelper, log } from '@app/helpers'
import { VacationRecord } from '@app/models'
import moment from 'moment-timezone'

export class TimeOffFormatter {
	static formatDuration(isoDur: string): string {
		if (!isoDur) {
			return null
		}
		const momDur = moment.duration(isoDur)
		return DateTimeHelper.formatDurationInHoursAndMinutes('H:mm', momDur)
	}

	static formatHours(vac: VacationRecord, display: 'PERDAY' | 'TOTAL' | 'BOTH'): string {
		const perDayStr = TimeOffFormatter.formatDuration(vac.hours)

		const perDayMins = moment.duration(vac.hours).asMinutes()
		const days = moment(vac.end_date).diff(moment(vac.start_date), 'days')
		const totalMin = perDayMins * (days + 1)
		const totalDur = moment.duration(totalMin, 'minutes')
		const totalStr = DateTimeHelper.formatDurationInHoursAndMinutes('H:mm', totalDur)

		switch (display) {
			case 'PERDAY':
				return perDayStr ? perDayStr : ''
			case 'TOTAL':
				return perDayStr ? totalStr : ''
			case 'BOTH':
				return perDayStr ? `${perDayStr} / ${totalStr}` : ''
		}
	}

	static formatStatusTag(vac: VacationRecord) {
		const startDate = vac.start_date
		const status = vac.getDerivedStatus()

		switch (status) {
			case 'UPCOMING':
				return `<div style="display:none">C_${startDate}</div><div class="table-tag" style="width:80px;">Upcoming</div>`
			case 'ONGOING':
				return `<div style="display:none">B_${startDate}</div><div class="table-tag" style="width:80px;background-color:#008000;">Ongoing</div>`
			case 'COMPLETED':
				return `<div style="display:none">A_${startDate}</div><div class="table-tag" style="width:80px;background-color:#69696f;">Complete</div>`
			default:
				return 'Unknown'
		}
	}

	// DEPRECATED 2024-03-26 - Refactored to use getDerivedStatus from VacationRecord
	// static formatStatusTag(vac: VacationRecord) {
	// 	const startDate = vac.start_date
	// 	const startMom = moment(vac.start_date)
	// 	const endMom = moment(vac.end_date)
	// 	const isOngoing = moment().isBetween(startMom, endMom, 'day', '[]')
	// 	const isUpcoming = moment().isBefore(startMom, 'day')
	// 	const isComplete = moment().isAfter(endMom, 'day')

	// 	if (isUpcoming) {
	// 		return `<div style="display:none">C_${startDate}</div><div class="table-tag" style="width:80px;">Upcoming</div>`
	// 	}
	// 	if (isOngoing) {
	// 		return `<div style="display:none">B_${startDate}</div><div class="table-tag" style="width:80px;background-color:#008000;">Ongoing</div>`
	// 	}
	// 	if (isComplete) {
	// 		return `<div style="display:none">A_${startDate}</div><div class="table-tag" style="width:80px;background-color:#69696f;">Complete</div>`
	// 	}
	// 	return 'Unknown'
	// }

	static formatDeadlineTag(vac: VacationRecord) {
		const startDate = vac.start_date
		const startMom = moment(vac.start_date)
		const endMom = moment(vac.end_date)
		const isOngoing = moment().isBetween(startMom, endMom, 'day', '[]')
		const isUpcoming = moment().isBefore(startMom, 'day')
		const isComplete = moment().isAfter(endMom, 'day')
		const daysAway = moment(startDate).startOf('day').diff(moment(), 'days')
		const daysResult = DisplayHelper.pluralize('Day', daysAway, true)
		const sortString = DisplayHelper.padNumber(daysAway, 8)

		if (isUpcoming) {
			let styleClass = 'table-tag'
			if (daysAway < 4) {
				styleClass = 'table-tag table-tag-warn'
			}
			if (daysAway < 2) {
				styleClass = 'table-tag table-tag-danger'
			}

			return `
				<div style="display:none">${sortString}</div>
				<div class="${styleClass}" style="width:80px;">${daysResult}</div>
			`
		}
		return `<div style="display:none">${sortString}</div>`
	}
}
