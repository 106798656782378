import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { PhoneHelper } from '@app/helpers'
import { CoreService } from '@app/services'

import qrcode from 'qrcode'

@Component({
    selector: 'app-view-qrcode',
    templateUrl: './view-qrcode.component.html',
    styleUrls: ['./view-qrcode.component.scss'],
    standalone: false
})
export class ViewQRCodeComponent implements OnInit, AfterViewInit {
	@Input() jobName: string = 'Job Name Goes Here'
	@Input() jobCode: string = '1234'
	@Input() inputString: string = '0'
	@Input() showPrintable = false

	@Input() printHeader = ''
	@Input() printSubHeader = ''

	@Output() printWindowOpened = new EventEmitter<boolean>()

	isPrintBtnVisible = false

	constructor(private coreSrvc: CoreService) {}

	canvas: HTMLCanvasElement

	ngOnInit() {
		if (this.coreSrvc.devDetect.isDesktop()) this.isPrintBtnVisible = true
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.canvas = document.getElementById('qrcode-canvas') as HTMLCanvasElement
			this.refreshQRCode()
		})
	}

	refreshQRCode() {
		const qrCodeString = this.inputString
		qrcode.toCanvas(this.canvas, qrCodeString, (error) => {
			if (error) {
				console.error(error)
			} else {
				console.log('QR code generated successfully.')
			}
		})
	}

	printCode() {
		this.printDivWithCanvas('qrcode-canvas')
	}

	printDivWithCanvas(canvasId) {
		// Get the canvas element and its content
		const canvas = document.getElementById(canvasId) as any
		const canvasContent = canvas.toDataURL('image/png') // Convert the canvas content to a data URL

		// Setup call in number
		const calloutCallerId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().callout_callerid_e164
		const tollFreeNumber = this.coreSrvc.dbSrvc.settingSrvc
			.getCallInPhoneNumberRecords()
			.find((pn) => pn.teli_type === 'tollfree' && pn.route_application === 'TTS_PROD')?.number_e164
		const phoneNumber = tollFreeNumber ?? calloutCallerId
		const displayNumber = `<strong>${PhoneHelper.formatPhoneFromE164(phoneNumber)}</strong>`

		// Calculate the left and top position to center the window
		const height = 800
		const width = 600
		const left = (window.innerWidth - width) / 2
		const top = (window.innerHeight - height) / 2

		// Create a new window or iframe
		const printWindow = window.open('', this.printHeader, `width=600,height=800,left=${left},top=${top}`)

		const jobName = this.jobName
		const jobCodeString = this.jobCode ? ` and use job code <strong>${this.jobCode}</strong>` : ''

		// Write the div and canvas content to the new window or iframe
		printWindow.document.open()
		printWindow.document.write(`
	    <html>
	    <head>
	      <title>Print</title>
        <style>
          .main-wrap {
            width: 100%;
            height:100%;
            display: flex;
            align-items: center; 
            justify-content: center;
            text-align: center;
          }
          .code-wrap {
            border: 1px dashed darkslategray;
            border-radius: 12px;
            padding: 50px;
            font-family: sans-serif;
            margin: 0px auto;
            font-size: 1.6rem;
            font-weight: 500;
          }
          .clock-in-label {
            max-width: 315px;
            color: chocolate;
            margin-bottom: 12px
          }
          .job-name-label {
            max-width: 315px;
            margin-bottom: 24px;
          }
			 .footer-note {
				margin-top: 18px;
				font-size: 1rem;
				line-height: 1.3rem;
				max-width: 315px
			 }
        </style>
	    </head>
	    <body>
	      <div class="main-wrap">
          <div class="code-wrap">
            <div class="clock-in-label">${this.printHeader}</div>
            <div  class="job-name-label">${this.printSubHeader}</div>
            <div><img src="${canvasContent}" /></div>
				<div class="footer-note">Start the clock in/out process by pointing your phone\’s camera at this QR code or call ${displayNumber}${jobCodeString}.</div>
          </div>
	      </div>
	    </body>
	    </html>
	  `)
		printWindow.document.close()
		if (printWindow) printWindow.focus()
		this.printWindowOpened.next(true)

		// Print the content
		// printWindow.print()
		// printWindow.close()
	}
}
