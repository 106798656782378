<app-header></app-header>

<app-notifications-banner *ngIf="showBanners"></app-notifications-banner>

<router-outlet *ngIf="isLoggedIn"></router-outlet>

<app-footer [hidden]="!showFooter"></app-footer>

<p-dialog
	header="Still working?"
	[baseZIndex]="100000"
	[(visible)]="showLogoutCountdownDialog"
	[modal]="true"
	[style]="{ width: '300px' }"
	[dismissableMask]="false"
>
	You will soon be logged out due to inactivity. Click the button below to remain logged in.<br /><br />
	<div><button class="btn btn-sm btn-block btn-success" (click)="resetInactivityTimer()">Continue Working</button></div>
</p-dialog>

<app-click-to-call-event-dialog></app-click-to-call-event-dialog>
