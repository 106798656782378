import { Injectable } from '@angular/core'

import { VacationRecord } from '@app/models'

import { log } from '@app/helpers'
import _ from 'lodash' // Don't add lodash to angular-cli.json or it goes in global scope.
import { Subject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class VacationService {
	dataLoaded = false
	list: VacationRecord[] = []
	lastUpdated: Date

	vacationListUpdated = new Subject<boolean>()

	constructor() {
		log('Creating VacationService')
	}

	count(supEmpIds: Array<number>): number {
		if (supEmpIds === null || supEmpIds === undefined) {
			return this.list.length
		} else {
			return this.list.filter((v) => supEmpIds.includes(v.employee_id)).length
		}
	}

	alertCount(): number {
		return 0
	}

	clearData() {
		this.list = []
		this.dataLoaded = false
	}

	getVacations(): Array<VacationRecord> {
		return this.list
	}

	getCompletedVacations(): Array<VacationRecord> {
		return this.list.filter((rec) => rec.getDerivedStatus() === 'COMPLETED')
	}

	getPendingVacations(): Array<VacationRecord> {
		return this.list.filter((rec) => rec.approval_state === 'PENDING')
	}

	getVacationById(id: number): VacationRecord {
		const vac = this.list.find((v) => v.id === id)
		return vac ? vac : null
	}

	makeVacation(record: VacationRecord): VacationRecord {
		const vac = new VacationRecord(record)
		return vac
	}

	setVacationRecords(records: Array<VacationRecord>) {
		log('setVacationRecords')
		this.lastUpdated = new Date()
		this.list = records.map((c) => this.makeVacation(c))
		this.dataLoaded = true
		this.vacationListUpdated.next(true)
	}

	removeLocalVacationRecord(recordId: number) {
		log('removeLocalVacationRecord')
		this.list = this.list.filter((rec) => rec.id !== recordId)
		this.vacationListUpdated.next(true)
	}

	updateVacRecords(records: Array<VacationRecord>) {
		log('updateVacRecords', records)
		for (const newRecord of records) {
			if (newRecord) {
				const currentRecord = this.list.find((rec) => rec.id === newRecord.id)
				if (currentRecord) {
					for (const attr in newRecord) {
						if (newRecord.hasOwnProperty(attr)) {
							currentRecord[attr] = newRecord[attr]
						}
					}
				}
			}
		}
		this.vacationListUpdated.next(true)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateVacationRecords(records: Array<VacationRecord>) {
		log('addOrUpdateVactionRecords')
		const newRecords = records.map((rec) => this.makeVacation(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.list.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.list.push(newRecord)
			}
		}
		this.vacationListUpdated.next(true)
	}
}
