<p-fileUpload #uploadComponent name="myfile[]" [customUpload]="true" chooseLabel="Upload" [auto]="true" (uploadHandler)="qbdFileHandler($event)">
	<ng-template let-file pTemplate="content">
		<div *ngIf="!isUploading && !isProcessing">Click the Upload button to select a file for upload, or drag and drop the file here.</div>
	</ng-template>
	<ng-template pTemplate="file">
		<div>
			<div *ngIf="isUploading"><i class="fas fa-loader fa-spin"></i> Uploading File ... {{ percentComplete }}%</div>
			<div *ngIf="isProcessing"><i class="fas fa-loader fa-spin"></i> Processing File ...</div>
		</div>
	</ng-template>
</p-fileUpload>
