import { Component, OnInit, OnDestroy, Input, AfterViewInit } from '@angular/core'
import { Router } from '@angular/router'

import {
	VacationRecord,
	CrudAction,
	DialogManager,
	DatabaseTableName,
	VacationTypeOptions,
	TableFilterManager,
	TableFilterButton,
	ScheduleViewTimeOffSubSectionType,
	ScheduleViewManager,
	ClickToCallRecord,
	ClickToCallGlobalConfig,
	LocalPrefsData,
	LocalPrefsGroup,
	LocalPrefsDialogData,
	ComponentBridgeName,
} from '@app/models'
import { CoreService, DatabaseService, PrefsService } from '@app/services'

import { DataTablesHelper, DateTimeHelper, DisplayHelper, GeneralTableHelper, log, TableActionFormatter } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'

import { TimeOffFormatter } from '../time-off.formatter'
import { Subscription } from 'rxjs'

import _ from 'lodash'
import moment from 'moment-timezone'

enum TimeOffTableColumnIndex {
	id = 0,
	employee,
	empStatus,
	requestStatus,
	deadline,
	type,
	hours,
	start,
	end,
	notes,
	managedBy,
	linkedJobs,
	actions,
}

@Component({
    selector: 'app-time-off-table',
    templateUrl: './time-off-table.component.html',
    styleUrls: ['./time-off-table.component.scss'],
    standalone: false
})
export class TimeOffTableComponent implements OnInit, OnDestroy, AfterViewInit {
	bridgeName: ComponentBridgeName = 'ngBridgeTimeOffTable'

	accessHelper: AccessHelper
	canAccessAuditLog = false

	@Input() public list: Array<VacationRecord> = []
	filteredList: Array<VacationRecord> = []
	showCompletedEntries = false

	hasDisabledItems = false

	public sectionPrefsDialogManager = new DialogManager('sectionPrefsDialog')

	editDialogManager = new DialogManager('vacEditDialog')
	editAction = { recordId: null, action: 'edit' }
	deleteAction = {
		tableName: 'vacation' as DatabaseTableName,
		recordId: null,
		confirmationMsg: null,
		recordLabel: null,
		showDeleteRecordDialog: false,
	}

	private timeOffTable // : DataTables.DataTable;
	private defaultSortOrder = [[TimeOffTableColumnIndex.requestStatus, 'asc']]

	tableFilterManager = new TableFilterManager()

	private clickToCallEnabled = { backend: false, browser: false } // Keep this. Need availability check here for buuttons
	private subs = new Subscription()

	constructor(private coreSrvc: CoreService) {
		this.setupSubscriptions()
		this.setupC2CAvailability()
		this.setupAccessPermissions()
		this.setupLocalPrefsDialog()
		this.setupTableFilterManager()
	}

	// get hasExpiredEntries(): boolean {
	// 	return this.list.length !== this.filteredList.length
	// }

	get canAccessC2CBtns(): boolean {
		return this.clickToCallEnabled.backend || this.clickToCallEnabled.browser
	}

	get viewManager(): ScheduleViewManager {
		return this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager
	}

	get hasCompletedEntries(): boolean {
		return this.coreSrvc.dbSrvc.vacSrvc.getCompletedVacations().length > 0
	}

	get hasPendingEntries(): boolean {
		return this.coreSrvc.dbSrvc.vacSrvc.getPendingVacations().length > 0
	}

	get isDayViewActive(): boolean {
		return !!this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.currentDayViewDate
	}

	public ngOnInit() {
		window[this.bridgeName] = this
	}

	public ngAfterViewInit() {
		this.initTable()
		this.fetchAndReload()

		$('#timeOffTable_filter input').attr('placeholder', ' filter')
		$('#timeOffTable_filter input').addClass('search-field-input')

		// Time Off table doesn't use DataTables Filter, it uses a customer filter inptu
		// setTimeout(() => {
		// 	$('#clear-search-icon').detach().appendTo('#timeOffTable_filter label')
		// })

		// Set tooltips when expanding rows
		this.timeOffTable.on('responsive-display', (e, datatable, row, showHide, update) => {
			this.coreSrvc.displaySrvc.enableAllTooltips()
		})
	}

	public ngOnDestroy() {
		this.timeOffTable['fixedHeader'].disable()
		window[this.bridgeName] = null
		this.subs.unsubscribe()
	}

	private setupSubscriptions() {
		this.subs.add(
			this.coreSrvc.dbSrvc.schedulerSrvc.dayViewToggleEvent.subscribe((event) => {
				this.handleDayViewToggleEvent()
			}),
		)

		this.subs.add(
			this.viewManager.timeOffViewSubSectionChanged.subscribe((subSection) => {
				this.tableFilterManager.setSelectedBtnById(subSection)
				this.setSubSection(subSection)
			}),
		)

		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	private setupC2CAvailability() {
		const portalPrefs = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany()
		const backEndEnabled = this.coreSrvc.dbSrvc.settingSrvc.isClickToCallEnabled()

		// Setup Click to Call availability
		this.clickToCallEnabled.backend = backEndEnabled
		this.clickToCallEnabled.browser = portalPrefs.globalBrowserClickToCall
	}

	private setupTableFilterManager() {
		const currentBtn = new TableFilterButton()
		currentBtn.id = 'APPROVED'
		currentBtn.label = 'Approved'
		currentBtn.count = null
		this.tableFilterManager.buttons.push(currentBtn)

		const declinedBtn = new TableFilterButton()
		declinedBtn.id = 'REJECTED'
		declinedBtn.label = 'Denied'
		declinedBtn.count = null
		this.tableFilterManager.buttons.push(declinedBtn)

		const pendingBtn = new TableFilterButton()
		pendingBtn.id = 'PENDING'
		pendingBtn.label = 'Pending'
		pendingBtn.count = null
		this.tableFilterManager.buttons.push(pendingBtn)

		const viewStateId = this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.timeOff.subSection
		this.tableFilterManager.setSelectedBtnById(viewStateId)
	}

	private handleScreenSizeChanges() {
		if (this.timeOffTable) {
			this.timeOffTable.columns.adjust().responsive.recalc()
			this.timeOffTable.fixedHeader.adjust()
		}
	}

	public changeSubsection(btn: TableFilterButton) {
		const subSection = btn.id as ScheduleViewTimeOffSubSectionType
		this.setSubSection(subSection)
	}

	setSubSection(subSection: ScheduleViewTimeOffSubSectionType) {
		this.setPage(0)
		this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.timeOff.subSection = subSection
		this.updateTable()
	}

	private setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'vacation')
		const auditPerms = this.accessHelper.getPermissionsFor('audit')
		this.canAccessAuditLog = auditPerms.access.read || auditPerms.owner.read
	}

	private setupLocalPrefsDialog(): void {
		this.sectionPrefsDialogManager.headerLabel = 'Preferences'
		const columnVisibilityItems = LocalPrefsData.getVacationTableDisplayPrefs()
		const columnVisibilityGroup = new LocalPrefsGroup('Column Visibility', columnVisibilityItems)
		const dialogData = new LocalPrefsDialogData([columnVisibilityGroup])
		this.sectionPrefsDialogManager.dialogData = dialogData
	}

	searchTable(text: string) {
		this.timeOffTable?.search(text ?? '').draw()
	}

	canPerformAction(action: CrudAction, isMyRecord: boolean): boolean {
		return this.accessHelper.canPerformAction(action, isMyRecord)
	}

	// Begin Load/filter/update methods

	handleDayViewToggleEvent() {
		this.showCompletedEntries = true
		this.updateTable()
	}

	loadData() {
		this.list = this.accessHelper.getVacationTableList()
		let filteredList = this.filterForCompletion(this.list)
		filteredList = this.filterForDayView(filteredList)
		filteredList = this.filterForApproval(filteredList)

		this.filteredList = filteredList

		this.hasDisabledItems = this.hasExpiredRecords()
	}

	fetchAndReload(): Promise<boolean> {
		return new Promise((resolve) => {
			this.coreSrvc.dbSrvc.readTable('vacation').then((readSuccess) => {
				this.updateTable()
				resolve(true)
			})
		})
	}

	updateTable() {
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadData()
			this.updateColumns()
		})
	}

	private updateColumns() {
		this.hasDisabledItems = this.hasExpiredRecords()
		const empStatus = this.coreSrvc.prefSrvc.data.vacColEmpStatusVisible
		const reqStatus = this.coreSrvc.prefSrvc.data.vacColReqStatusVisible
		const deadline = this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.timeOff.subSection === 'PENDING'
		const type = this.coreSrvc.prefSrvc.data.vacColTypeVisible
		const hours = this.coreSrvc.prefSrvc.data.vacColHoursVisible
		const start = this.coreSrvc.prefSrvc.data.vacColStartVisible
		const end = this.coreSrvc.prefSrvc.data.vacColEndVisible
		const notes = this.coreSrvc.prefSrvc.data.vacColNotesVisible
		const managedBy = this.coreSrvc.prefSrvc.data.vacColManagedByVisible
		const linkedJobs = this.coreSrvc.prefSrvc.data.vacColLinkedJobVisible

		this.timeOffTable.clear()
		GeneralTableHelper.updateColumn(this.timeOffTable, TimeOffTableColumnIndex.empStatus, empStatus)
		GeneralTableHelper.updateColumn(this.timeOffTable, TimeOffTableColumnIndex.deadline, deadline)
		GeneralTableHelper.updateColumn(this.timeOffTable, TimeOffTableColumnIndex.requestStatus, reqStatus)
		GeneralTableHelper.updateColumn(this.timeOffTable, TimeOffTableColumnIndex.type, type)
		GeneralTableHelper.updateColumn(this.timeOffTable, TimeOffTableColumnIndex.hours, hours)
		GeneralTableHelper.updateColumn(this.timeOffTable, TimeOffTableColumnIndex.start, start)
		GeneralTableHelper.updateColumn(this.timeOffTable, TimeOffTableColumnIndex.end, end)
		GeneralTableHelper.updateColumn(this.timeOffTable, TimeOffTableColumnIndex.notes, notes)
		GeneralTableHelper.updateColumn(this.timeOffTable, TimeOffTableColumnIndex.linkedJobs, linkedJobs)
		GeneralTableHelper.updateColumn(this.timeOffTable, TimeOffTableColumnIndex.managedBy, managedBy)

		this.timeOffTable.rows.add(this.filteredList)
		this.timeOffTable.draw(false)
	}

	filterForApproval(list: Array<VacationRecord>): Array<VacationRecord> {
		const approvalState = this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.timeOff.subSection

		if (approvalState === 'PENDING') return list.filter((rec) => rec.approval_state === 'PENDING')
		if (approvalState === 'REJECTED') return list.filter((rec) => rec.approval_state === 'REJECTED')
		if (approvalState === 'APPROVED') return list.filter((rec) => rec.approval_state === 'DIRECT' || rec.approval_state === 'APPROVED')
	}

	isDayViewDateInRange(vac: VacationRecord, dayViewMom: moment.Moment): boolean {
		const startMom = moment(vac.start_date).startOf('day')
		const endMom = moment(vac.end_date).endOf('day')
		return dayViewMom.isBetween(startMom, endMom, 'day', '[]')
	}

	filterForDayView(list: Array<VacationRecord>): Array<VacationRecord> {
		const dayViewDate = this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.currentDayViewDate
		if (!dayViewDate) {
			return list
		}
		const dayViewMom = moment(dayViewDate)
		const filteredList = list.filter((vac) => this.isDayViewDateInRange(vac, dayViewMom))
		return filteredList
	}

	isCompleted(vac: VacationRecord): boolean {
		if (this.showCompletedEntries || this.isDayViewActive) {
			return true
		} else {
			const endMom = moment(vac.end_date).endOf('day')
			return moment().isBefore(endMom)
		}
	}

	filterForCompletion(list: Array<VacationRecord>): Array<VacationRecord> {
		return list.filter((vac) => this.isCompleted(vac))
	}

	// End Load/filter/update methods

	toggleHidden() {
		this.showCompletedEntries = !this.showCompletedEntries
		this.updateTable()
	}

	saveActionComplete(event) {
		log('Emp Table Save Action Complete', event)
		this.editDialogManager.isDialogVisible = false
		this.updateTable()
	}

	editActionCancelled() {
		log('Emp Table Cancel Action Complete')
		this.editDialogManager.isDialogVisible = false
	}

	createRecord() {
		if (!this.canPerformAction(CrudAction.create, true)) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.editAction.action = 'new'
		this.editAction.recordId = null
		this.editDialogManager.headerLabel = 'Add Time Off Entry'
		this.editDialogManager.isDialogVisible = true
	}

	editRecord(id: number) {
		this.editAction.action = 'edit'
		this.editAction.recordId = id
		this.editDialogManager.headerLabel = 'Edit Time Off Entry'
		this.editDialogManager.isDialogVisible = true
	}

	deleteRecord(id: number) {
		const vac = this.coreSrvc.dbSrvc.vacSrvc.getVacationById(id)
		const empId = vac.employee_id
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)
		if (emp) {
			this.deleteAction.recordId = id
			this.deleteAction.recordLabel = id !== 0 ? emp.name : 'All Employees'
			this.deleteAction.tableName = 'vacation'
			this.deleteAction.confirmationMsg =
				vac.approval_state === 'APPROVED' && !moment(vac.end_date).isBefore(moment(), 'day')
					? `Deleting an approved time-off request will <b style="color:firebrick">not</b> notify the employee. You should first <b style="color:chocolate">deny</b> this request to trigger a notification or contact them manually.<br><br>Are you sure you want to proceed?`
					: 'Are you sure you want to delete this record? This action cannot be undone.'
			this.deleteAction.showDeleteRecordDialog = true
		}
	}

	deleteActionCancel() {
		this.deleteAction.showDeleteRecordDialog = false
	}

	deleteActionComplete() {
		setTimeout(() => {
			this.deleteAction.showDeleteRecordDialog = false
			this.updateTable()
		}, 1000)
	}

	clearSearch(): boolean {
		log('Clear Serach', this.timeOffTable)
		this.timeOffTable
			.search('')
			.order([[3, 'dsc']])
			.draw()
		return false
	}

	private hasExpiredRecords(): boolean {
		for (const vac of this.filteredList) {
			const endMom = moment(vac.end_date, 'YYYY-MM-DD')
			const nowMom = moment()
			if (endMom.isBefore(nowMom)) {
				// this.hasDisabledItems = true
				return true
			}
		}
		return false
	}

	private checkEnabledStatus(record: VacationRecord) {
		const endMom = moment(record.end_date, 'YYYY-MM-DD')
		const nowMom = moment()
		if (endMom.isBefore(nowMom)) {
			return false
		}
		return true
	}

	notifyOperationNotAuthorized() {
		this.coreSrvc.notifySrvc.default('operationNotAuthorized')
	}

	public handleC2CEvent(id: number, column, action: 'CALL' | 'TEXT') {
		const record = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(id)
		let name = ''
		let number = ''
		if (column === 'PHONE') {
			name = record.first + ' ' + record.last || 'Unknown'
			number = record.phone_number_e164
		}
		if (name && number) {
			const c2cRecord = new ClickToCallRecord('CONTACT', name, null, number, null)
			const config = new ClickToCallGlobalConfig(c2cRecord, action, null, null)
			this.coreSrvc.eventSrvc.clickToCallGlobalEvent.next(config)
		}
		log('handleC2CEvent', id, column, action, record)
	}

	public prefsDataSaved(): void {
		log('UpdatePrefsData')
		this.sectionPrefsDialogManager.isDialogVisible = false
		this.updateTable()
	}

	public setPage(num: number) {
		this.timeOffTable?.page(num)
	}

	private initTable() {
		const showDeadline = this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.timeOff.subSection === 'PENDING'
		this.coreSrvc.displaySrvc.startSectionLoader()
		this.timeOffTable = $('#timeOffTable').DataTable(<DataTables.Settings>{
			responsive: true,
			processing: true,
			paging: false,
			info: true,
			select: false,
			searching: true,
			fixedHeader: DataTablesHelper.fixedHeader,
			autoWidth: false,
			data: this.filteredList,
			order: this.defaultSortOrder,
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{
					responsivePriority: 1,
					targets: [TimeOffTableColumnIndex.employee],
				},
				{
					responsivePriority: 2,
					targets: [TimeOffTableColumnIndex.deadline],
				},
				{
					responsivePriority: 3,
					targets: [TimeOffTableColumnIndex.requestStatus],
				},
				{
					responsivePriority: 3,
					targets: [TimeOffTableColumnIndex.type],
				},
				{
					responsivePriority: 4,
					targets: [TimeOffTableColumnIndex.actions],
				},
				{
					// ID
					targets: TimeOffTableColumnIndex.id,
					visible: false,
					searchable: false,
					title: 'ID',
					data: null,
				},
				{
					// Employee
					targets: TimeOffTableColumnIndex.employee,
					visible: true,
					searchable: true,
					title: 'Employee',
					data: null,
					render: (rec: VacationRecord, t, f, m) => {
						const empId = rec.employee_id
						if (empId === 0) {
							return 'All Employees'
						}
						const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)
						if (!emp) return 'Unknown Employee'
						const phoneHtml = GeneralTableHelper.makeC2CButtons(emp.id, 'PHONE', this.bridgeName, 'handleC2CEvent')
						const c2cBtnRow = this.canAccessC2CBtns ? phoneHtml : ''

						return `
						<div class="dtr-control-content">
							<div class="table-name">
								<div>${emp.name}</div>
								<div style="margin-top: 4px">${c2cBtnRow}</div>
							</div>
						</div>`
					},
				},

				{
					// Emp Status
					targets: TimeOffTableColumnIndex.empStatus,
					visible: this.coreSrvc.prefSrvc.data.vacColEmpStatusVisible,
					searchable: this.coreSrvc.prefSrvc.data.vacColEmpStatusVisible,
					title: 'Employee<br>Status',
					data: null,
					render: (rec: VacationRecord, t, f, m) => {
						if (rec.employee_id === 0) {
							return 'All Employees'
						}
						const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(rec.employee_id)
						return emp?.active ? '<div class="table-tag bg-green">Active</div>' : `<div class="table-tag bg-chocolate">Inactive</div>`
					},
				},
				{
					// Request Status
					targets: TimeOffTableColumnIndex.requestStatus,
					visible: this.coreSrvc.prefSrvc.data.vacColReqStatusVisible,
					searchable: this.coreSrvc.prefSrvc.data.vacColReqStatusVisible,
					title: 'Request<br>Status',
					data: null,
					render: (vac: VacationRecord, t, f, m) => {
						return TimeOffFormatter.formatStatusTag(vac)
					},
				},
				{
					// Deadline
					targets: TimeOffTableColumnIndex.deadline,
					visible: showDeadline,
					searchable: false,
					title: 'Deadline',
					data: null,
					render: (vac: VacationRecord, t, f, m) => {
						return TimeOffFormatter.formatDeadlineTag(vac)
					},
				},
				{
					// Type
					targets: TimeOffTableColumnIndex.type,
					visible: this.coreSrvc.prefSrvc.data.vacColTypeVisible,
					searchable: this.coreSrvc.prefSrvc.data.vacColTypeVisible,
					title: 'Type',
					data: null,
					render: (vac: VacationRecord, t, f, m) => {
						const option = VacationTypeOptions.find((opt) => opt.value === vac.type)
						return option ? option.label : ''
					},
				},
				{
					// Hours
					targets: TimeOffTableColumnIndex.hours,
					visible: this.coreSrvc.prefSrvc.data.vacColHoursVisible,
					searchable: this.coreSrvc.prefSrvc.data.vacColHoursVisible,
					orderable: false,
					title: 'Hours (hr:min)<br>Per Day / Total',
					data: null,
					render: (vac: VacationRecord, t, f, m) => {
						return TimeOffFormatter.formatHours(vac, 'BOTH')
					},
				},
				{
					// Start Date
					targets: TimeOffTableColumnIndex.start,
					visible: this.coreSrvc.prefSrvc.data.vacColStartVisible,
					searchable: this.coreSrvc.prefSrvc.data.vacColStartVisible,
					title: 'Start',
					data: 'start_date',
				},
				{
					// End Date
					targets: TimeOffTableColumnIndex.end,
					visible: this.coreSrvc.prefSrvc.data.vacColEndVisible,
					searchable: this.coreSrvc.prefSrvc.data.vacColEndVisible,
					title: 'End',
					data: 'end_date',
				},
				{
					// Notes
					targets: TimeOffTableColumnIndex.notes,
					visible: this.coreSrvc.prefSrvc.data.vacColNotesVisible,
					searchable: this.coreSrvc.prefSrvc.data.vacColNotesVisible,
					title: 'Notes',
					data: null,
					render: (vac: VacationRecord, type, full, meta) => {
						// const empNotes = vac.emp_notes ? vac.emp_notes : ''
						const adminNotes = vac.notes ? vac.notes : ''
						const notesHtml = `<div class="table-note-block">${adminNotes}</div>`
						return adminNotes ? notesHtml : ''
					},
				},
				{
					// Managed By / Modified By
					targets: TimeOffTableColumnIndex.managedBy,
					visible: this.coreSrvc.prefSrvc.data.vacColManagedByVisible,
					searchable: this.coreSrvc.prefSrvc.data.vacColManagedByVisible,
					title: 'Managed By /<br>Modified On',
					data: null,
					render: (vac: VacationRecord, type, full, meta) => {
						const userId = vac.approval_state_last_user_id
						const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(userId)
						const userName = user ? `${user.first_name} ${user.last_name}` : null
						const date = vac.lastManagedDate

						const userHtml = user
							? `<div style="font-weight: bold; color: green">${userName}</div>`
							: `<div style="font-weight: bold; color: chocolate">Unassigned</div>`
						const dateHtml = `<div>${DateTimeHelper.mediumDateTimeFromDateString(date)}`
						return `
						${userHtml}${dateHtml}
						`
					},
				},
				{
					// Linked Job
					targets: TimeOffTableColumnIndex.linkedJobs,
					visible: this.coreSrvc.prefSrvc.data.vacColLinkedJobVisible,
					searchable: this.coreSrvc.prefSrvc.data.vacColLinkedJobVisible,
					orderable: true,
					title: 'Linked<br>Jobs',
					data: null,
					render: (rec: VacationRecord, t, f, m) => {
						let tooltip = ''
						let iconClass = 'cell-icon-lg'
						if (rec.job_id) {
							const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(rec.job_id)
							tooltip = job ? job.description : null
							iconClass = tooltip ? 'cell-icon-lg item-tooltip' : 'cell-icon-lg'
						}
						return TableActionFormatter.checkmarkLink(!!rec.job_id, false, iconClass, 10, tooltip)
					},
				},

				{
					// Actions
					targets: TimeOffTableColumnIndex.actions,
					visible: true,
					searchable: false,
					orderable: false,
					title: 'Actions',
					data: null,
					className: 'row-actions',
					render: (rec: VacationRecord, type, full, meta) => {
						const recordId = rec.id
						const isMyRecord = this.accessHelper.isMyRecord(rec.employee_id, 'employee')
						const hasLog = rec.updated ? true : false

						const canAccessEdit = this.canPerformAction(CrudAction.update, isMyRecord)
						const editLink = TableActionFormatter.editLink(this.bridgeName, 'editRecord', recordId, canAccessEdit)

						const canAccessDelete = this.canPerformAction(CrudAction.delete, isMyRecord)
						const deleteLink = TableActionFormatter.deleteLink(this.bridgeName, 'deleteRecord', recordId, canAccessDelete)

						const empId = rec.employee_id
						const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)
						const footerLabel = emp ? `${emp.name}` : 'Employee Not Found'
						const auditLink = TableActionFormatter.auditLinkGlobal('vacation', recordId, footerLabel, true, !!rec.updated)

						return `<span class="act-ico-wrap">${editLink}${deleteLink}${auditLink}</span>`
					},
				},
			],
			createdRow: (row, data, dataIndex) => {
				const vac = data as VacationRecord
				const endMom = moment(vac.end_date)
				const isComplete = moment().isAfter(endMom, 'day')
				if (isComplete) {
					$(row).addClass('vac-disabled')
				}
			},
			drawCallback: (settings) => {
				this.timeOffTable?.columns.adjust().responsive.recalc()
				this.coreSrvc.displaySrvc.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
			},
		})
		// Add search filter highlighting - Uses custom filter input
		// DisplayHelper.setupTableFilterHighlighter('timeOffTable', this.timeOffTable)
	}
}
