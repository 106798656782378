import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'

import { CompanyRecord, DataAccessRequest, BillingHistory, AccountBillingType } from '@app/models'
import { CoreService } from '@app/services'

import { log, DisplayHelper } from '@app/helpers'
import { Subscription } from 'rxjs'
import { environment } from '@env/environment'

import _ from 'lodash'

@Component({
    selector: 'app-settings-billing-information',
    templateUrl: './settings-billing-information.component.html',
    styleUrls: ['./settings-billing-information.component.scss'],
    standalone: false
})
export class SettingsBillingInformationComponent implements OnInit, OnDestroy, AfterViewInit {
	environment = environment
	isInternalUser = false
	isLoadingPayPalData = true
	isLoadingStripeData = true
	isUpdatingCard = false
	isDeletingCard = false

	company: CompanyRecord

	billingHistory: Array<BillingHistory> = []

	internalUserBanner = { label: 'Unknown', alert: 'DANGER', color: 'firebrick' }

	billingType: AccountBillingType

	private subs = new Subscription()

	get isPayPalCustomer(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.isPayPalCustomer()
	}

	get isStripeCustomer(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.isStripeCustomer()
	}

	get isLoadingData(): boolean {
		return this.isLoadingPayPalData || this.isLoadingStripeData
	}

	get isTestingAccount(): boolean {
		const company = this.company
		if (company && company.account_status === 'TESTING') {
			return true
		}
		return false
	}

	get isPrepaid(): boolean {
		const company = this.company
		if (company && company.account_status === 'PREPAID') {
			return true
		}
		return false
	}

	get isInvoice(): boolean {
		const company = this.company
		if (company && (company.account_status === 'INVOICE' || company.account_status === 'ADP_INVOICE')) {
			return true
		}
		return false
	}

	get hasBillingHistory(): boolean {
		return this.billingHistory.length > 0
	}

	get hasCardOnFile(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.hasCardOnFile()
	}

	constructor(private coreSrvc: CoreService) {
		this.company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()

		this.internalUserBanner = DisplayHelper.getAccountStatusAlertInfo(this.company.account_status)

		this.billingType = this.coreSrvc.dbSrvc.settingSrvc.getCompany().account_billing

		// Bypass loaders when billing provider cards are not available
		if (this.isPayPalCustomer) this.isLoadingStripeData = false
		if (this.isTestingAccount) this.isLoadingStripeData = false
		if (this.isStripeCustomer || this.isTestingAccount || this.isInvoice || this.isPrepaid) this.isLoadingPayPalData = false
		if (!this.isPayPalCustomer && !this.isStripeCustomer) {
			this.isLoadingStripeData = false
			this.isLoadingPayPalData = false
		}

		this.loadBillingHistory()
	}

	ngOnInit() {}

	ngAfterViewInit() {
		this.subs.add(this.coreSrvc.dbSrvc.billSrvc.updateUiForBillingComponents.subscribe({ next: () => this.updateUI() }))

		// Trigger an update of billing components
		this.coreSrvc.dbSrvc.billSrvc.updateUiForBillingComponents.next(true)
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	updateUI() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		this.company = company

		this.internalUserBanner = DisplayHelper.getAccountStatusAlertInfo(company.account_status)
	}

	loadBillingHistory() {
		const request = new DataAccessRequest('billing_log')
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			const data = result.data

			const billingHistory = data.map((rec) => new BillingHistory(rec))
			const sortedHistory = _.orderBy(billingHistory, ['id'], ['desc'])
			this.billingHistory = sortedHistory.slice(0, 5)

			log('loadBillingHistory', result)
			setTimeout(() => {
				this.coreSrvc.displaySrvc.enableAllTooltips()
			}, 500)
		})
	}
}
