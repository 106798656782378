import { AfterContentInit, ChangeDetectorRef, Component, Input } from '@angular/core'
import { AdpIvrProfileRecord, DialogManager, EditFormAction } from '@app/models'
import { CoreService } from '@app/services'

@Component({
    selector: 'app-adp-ivr-profiles',
    templateUrl: './adp-ivr-profiles.component.html',
    styleUrl: './adp-ivr-profiles.component.scss',
    standalone: false
})
export class AdpIvrProfilesComponent implements AfterContentInit {
	@Input() dialogManager: DialogManager

	status = { isLoaded: false, isEditing: false }
	editAction = { recordId: null, action: null as EditFormAction, confirmDelete: false }
	list: Array<AdpProfileCard> = []

	constructor(
		private coreSrvc: CoreService,
		private cd: ChangeDetectorRef,
	) {
		this.loadData()
	}

	ngAfterContentInit() {
		this.dialogManager.submitBtnLabel = 'New'
		this.dialogManager.isSubmitBtnVisible = true
		this.dialogManager.submitBtnAction = () => this.addRecord()
	}

	private loadData() {
		this.coreSrvc.dbSrvc.readTable('adp_ivr_profile').then(() => {
			this.list = this.coreSrvc.dbSrvc.adpSrvc.getAdpIvrProfiles().map((rec) => new AdpProfileCard(rec))
			this.status.isLoaded = true
		})
	}

	public toggleExpansion(isExpanded: boolean) {
		this.list.forEach((rec) => (rec.isExpanded = isExpanded))
	}

	public addRecord() {
		this.dialogManager.pushState()
		this.editAction.recordId = null
		this.dialogManager.headerLabel = 'Add ADP IVR Profile'
		this.dialogManager.cancelBtnLabel = 'Cancel'
		this.dialogManager.submitBtnLabel = 'Save'
		this.dialogManager.cancelBtnAction = () => this.editComplete()
		this.status.isEditing = true
	}

	public editRecord(card: AdpProfileCard) {
		this.dialogManager.pushState()
		this.editAction.recordId = card.record.id
		this.dialogManager.headerLabel = 'Update ADP IVR Profile'
		this.dialogManager.cancelBtnLabel = 'Cancel'
		this.dialogManager.submitBtnLabel = 'Save'
		this.dialogManager.cancelBtnAction = () => this.editComplete()
		this.status.isEditing = true
	}

	public editComplete() {
		this.list = this.coreSrvc.dbSrvc.adpSrvc.getAdpIvrProfiles().map((rec) => new AdpProfileCard(rec))
		this.dialogManager.popStateAndApply()
		this.status.isEditing = false
		this.cd.markForCheck()
	}

	public deleteRecord(card: AdpProfileCard) {
		card.confirmDelete = true
	}

	public confirmDelete(card: AdpProfileCard) {
		card.isDeleting = true
		this.coreSrvc.dbSrvc.deleteRecord('adp_ivr_profile', card.record.id).then((success) => {
			this.coreSrvc.dbSrvc.removeLocalRecord('adp_ivr_profile', card.record.id)
			this.list = this.coreSrvc.dbSrvc.adpSrvc.getAdpIvrProfiles().map((rec) => new AdpProfileCard(rec))
		})
	}
}

class AdpProfileCard {
	record: AdpIvrProfileRecord
	confirmDelete = false
	isDeleting = false
	isExpanded = false
	constructor(record: AdpIvrProfileRecord) {
		this.record = record
	}
}
