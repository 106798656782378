import { AfterContentInit, Component, OnInit } from '@angular/core'
import { DialogManager } from '@app/models'
import { DatabaseService } from '@app/services'
import { log } from '@app/helpers'

@Component({
    selector: 'app-billing-contacts',
    templateUrl: './billing-contacts.component.html',
    styleUrls: ['./billing-contacts.component.scss'],
    standalone: false
})
export class BillingContactsComponent implements OnInit, AfterContentInit {
	dialogManager = new DialogManager('billingContactsEdit')
	billingContacts = ''

	constructor(private dbSrvc: DatabaseService) {
		this.updateContacts()
	}

	ngOnInit(): void {}

	ngAfterContentInit() {
		this.setupDialogManager()
	}

	setupDialogManager() {
		this.dialogManager.headerLabel = 'Edit Billing Contacts'
	}

	updateContacts() {
		const company = this.dbSrvc.settingSrvc.getCompany()
		const emailString = company?.billing_emails || ''
		const emails = emailString.split(',')
		this.billingContacts = emails.join(', ')
	}

	toggleEdit() {
		log('Toggle Edit')
		this.dialogManager.isDialogVisible = true
	}

	recordUpdated() {
		this.updateContacts()
	}
}
