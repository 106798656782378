<div>
	<div class="options-title tts-hl-2">{{ dropdownLabel }}</div>
	<p-multiselect
		appScrollLockMultiSelect
		[placeholder]="placeholder"
		[maxSelectedLabels]="0"
		[showToggleAll]="false"
		[selectedItemsLabel]="selectedItemsLabel"
		[options]="dropdownOptions"
		[(ngModel)]="selectedIds"
		(onChange)="valueChanged()"
	>
	</p-multiselect>
	<div *ngIf="selectedItemNames.length > 0" class="multi-list-block">
		<div [class.multi-list-box]="selectedItemNames.length > 3">
			<div *ngFor="let name of selectedItemNames">{{ name }}</div>
		</div>
	</div>
	<div *ngIf="selectedItemNames.length === 0" style="margin-top: 25px"></div>
</div>
