import { Component, EventEmitter, OnDestroy, Output } from '@angular/core'
import { DisplayHelper, log } from '@app/helpers'
import { StripeCustomerInfo, StripePaymentMethod } from '@app/models/stripe'
import { CoreService } from '@app/services'

@Component({
    selector: 'app-billing-stripe',
    templateUrl: './billing-stripe.component.html',
    styleUrl: './billing-stripe.component.scss',
    standalone: false
})
export class BillingStripeComponent {
	@Output() stripeBlockSetup = new EventEmitter<boolean>()

	isFetchingLink = false

	stripeCustomerInfo: StripeCustomerInfo

	constructor(private coreSrvc: CoreService) {
		this.coreSrvc.dbSrvc.billSrvc
			.stripeGetCustomerInfo()
			.then((customerInfo) => {
				this.stripeCustomerInfo = customerInfo
				this.setupPauseInfo()
				this.stripeBlockSetup.emit(true)
			})
			.catch((error) => {
				this.stripeCustomerInfo = null
				this.stripeBlockSetup.emit(true)
			})
	}

	isPaused = false
	resumeDate: Date = null
	setupPauseInfo() {
		const subscriptions = this.stripeCustomerInfo?.customer?.subscriptions?.data ?? []
		if (subscriptions.length > 0) {
			this.isPaused = subscriptions.some((sub) => !!sub.pause_collection)
			const resumeAtList = subscriptions
				.map((sub) => sub.pause_collection?.resumes_at)
				.filter((ts) => !!ts)
				.map((ts) => new Date(ts * 1000))

			if (resumeAtList.length > 0) {
				this.resumeDate = resumeAtList[0]
			}
		}
	}

	get isTrial(): boolean {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		return company.account_status === 'TRIAL'
	}

	get cardExpirationDayCount(): number {
		const count = this.coreSrvc.dbSrvc.billSrvc.cardExpirationDayCount
		const expirationThreshold = this.coreSrvc.dbSrvc.billSrvc.cardExpirationThreshold
		if (count < expirationThreshold) {
			return count
		}
		return null
	}

	public formatCardName(name: string): string {
		return (name || '').toUpperCase()
	}

	public formatWalletType(payment: StripePaymentMethod): string {
		return DisplayHelper.replaceUnderscoreAndCapitalize(payment.card.wallet?.type)
	}

	public formatBankName(name: string): string {
		return DisplayHelper.capitalizeEachWord(name)
	}

	manageBillingBtnClicked() {
		this.isFetchingLink = true
		this.coreSrvc.dbSrvc.billSrvc.stripeGetPortalSession().then((result) => {
			// Get the URL from the result
			const url = result.data.url

			// Show the loading spinner
			const element = $('#loading-spinner')
			element.css({ opacity: 1, display: 'block' })

			// Setup the redirect
			setTimeout(() => {
				window.location.href = url

				// Trigger an app reload
				setTimeout(() => {
					this.coreSrvc.dbSrvc.settingSrvc.reloadApplication()
					this.isFetchingLink = false
				}, 1200)
			}, 300)
		})
	}
}
