<div [class.block-events]="viewOnly" [class.disabled]="viewOnly">
	<div *ngIf="isSavingReport" class="save-report-block">
		<div><i class="fas fa-loader fa-spin"></i> {{ isNew ? 'Saving' : 'Updating' }} Report...</div>
	</div>

	<form [hidden]="isSavingReport" novalidate [formGroup]="repForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<!-- <div *ngIf="showTooltips" class="card card-body tooltip-block tooltip-info-text">
			<strong class="tooltip-step">Step 1)</strong> Enter a name to identify this report which describes it's purpose.
		</div> -->

		<div class="form-group" [class.input-required]="!repForm.get('name').value">
			<div class="input-label">
				<strong>Report Name</strong>
				<span *ngIf="!repForm.get('name').value" class="float-right">(required)</span>
			</div>
			<input id="repName" type="text" class="form-control" formControlName="name" autocomplete="off" />
		</div>

		<!-- <div *ngIf="showTooltips">
			<hr class="tooltip-divider" />
		</div> -->

		<!-- <div *ngIf="showTooltips" class="card card-body tooltip-block tooltip-info-text">
			<strong class="tooltip-step">Step 2)</strong> Select the report type. There are three options to choose from:
			<br />
			<br />
			<strong>Employee &amp; Job Summary</strong>
			<br />
			<strong>Payroll Report</strong>
			<br />
			<strong>Payroll (US Holiday + Overtime)</strong>
			<br />
		</div> -->

		<div class="form-group form-group-lg" [class.input-required]="!repForm.get('java_class').value">
			<div class="input-label">
				<strong>Report Type</strong>
				<span *ngIf="!repForm.get('java_class').value" class="float-right">(required)</span>
			</div>
			<p-select
				appScrollLockSelect
				*ngIf="isNew"
				id="reportType"
				placeholder="Select Report Type"
				[options]="reportTypeDropdown"
				(onChange)="reportTypeSelected($event)"
				formControlName="java_class"
				[filter]="true"
				filterBy="label"
				[required]="true"
			></p-select>
			<div *ngIf="!isNew" class="input-disabled">{{ reportType }}</div>
		</div>

		<!-- <div *ngIf="showTooltips">
			<hr class="tooltip-divider" />
		</div> -->

		<!-- <div *ngIf="showTooltips" class="card card-body tooltip-block tooltip-info-text">
			<strong class="tooltip-step">Step 3)</strong> Select the frequency for this report to be run. If you choose <strong>Custom</strong>, the
			report will be run one time only for the date range you specify. <strong>Daily</strong> reports will automatically run every day. For the
			other options you will need to specify a starting date and then the report will run automatically based on the frequency you selected.
		</div> -->

		<div class="form-group form-group-lg" [class.input-required]="!repForm.get('period').value">
			<div class="input-label">
				<strong>Frequency</strong>
				<span *ngIf="!repForm.get('period').value" class="float-right">(required)</span>
				<span *ngIf="repForm.get('period').value" class="float-right freq-note">{{ frequencyNote() }}</span>
			</div>
			<p-select
				*ngIf="isNew"
				appScrollLockSelect
				id="frequency"
				placeholder="Select Frequency"
				[options]="frequencyDropdown"
				formControlName="period"
				[filter]="true"
				filterBy="label"
				[required]="true"
				(onChange)="frequencyChanged()"
			></p-select>
			<div *ngIf="!isNew" class="input-disabled">{{ reportFrequency }}</div>
		</div>

		<!-- Start Date Week Start Picker -->
		<div *ngIf="displayStartDateWeekStart()" style="margin-bottom: 15px">
			<div formGroupName="options" [class.input-required]="repForm.get('period').value === 'WEEKLY' && !startDateWeekStartIso">
				<div class="input-label">
					<!-- <div class="float-right">{{ repForm.get('period_start').value | dateFormatter: 'ddd MMM Do, YYYY' }}</div> -->
					<strong>Work Week</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('startDateWeekStart')"></i>
				</div>
				<p-select
					*ngIf="isNew"
					appScrollLockSelect
					id="startDateWeekStart"
					placeholder="Select Work Week"
					[options]="dayOfWeekDropdown"
					[ngModelOptions]="{ standalone: true }"
					[(ngModel)]="startDateWeekStartIso"
					(onChange)="startDateWeekStartChanged()"
				></p-select>
				<div *ngIf="!isNew" class="input-disabled">{{ startDateWeekStartName }}</div>
			</div>
		</div>

		<!-- OT/DOW Week Start Range Picker -->
		<div *ngIf="displayWeekStart()" style="margin-bottom: 15px">
			<div formGroupName="options">
				<div class="input-label">
					<strong>Work Week</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('weekStart')"></i>
				</div>
				<p-select
					appScrollLockSelect
					*ngIf="isNew"
					id="week-start"
					[options]="dayOfWeekDropdown"
					formControlName="dow_ot_start_iso_id"
				></p-select>
				<div *ngIf="!isNew" class="input-disabled">{{ dowWeekStartName }}</div>
			</div>
		</div>

		<div *ngIf="isMonthly()" style="margin-top: 25px">
			<div class="row">
				<div class="col-9">
					<div style="padding-top: 5px"><strong>Day of Month</strong></div>
				</div>
				<div class="col-3">
					<input
						type="tel"
						class="form-control float-right"
						style="width: 60px"
						[(ngModel)]="monthlyStartDay"
						[ngModelOptions]="{ standalone: true }"
					/>
				</div>
			</div>
			<div class="row" style="margin-top: 15px; font-size: 1em; line-height: 1.2em">
				<div class="col-12">
					<div class="alert alert-warning">Enter the day of the month to run this report. Must be between 1-28.</div>
				</div>
			</div>
		</div>

		<div
			*ngIf="isPeriodDate() && !displayStartDateWeekStart()"
			class="form-group form-group-lg"
			[class.input-required]="!repForm.get('period_start').value"
		>
			<div class="input-label">
				<strong>Start Date</strong>
				<span *ngIf="!repForm.get('period_start').value" class="float-right">(required)</span>
			</div>
			<app-date-time-picker
				id="PERIOD_START_DATE"
				[currentDate]="repForm.get('period_start').value"
				[pickerType]="'DATE'"
				[pickerLabel]="'Start Date'"
				[pickerMode]="'dialog'"
				[format]="'ddd MMM Do, YYYY'"
				(pickerDidClose)="repForm.get('period_start').setValue($event)"
			>
			</app-date-time-picker>
		</div>

		<div *ngIf="isCustomDate()" class="form-group form-group-lg" [class.input-required]="!repForm.get('custom_start').value">
			<div class="input-label">
				<strong>Start Date</strong>
				<span *ngIf="!repForm.get('custom_start').value" class="float-right">(required)</span>
			</div>
			<app-date-time-picker
				id="CUSTOM_START_DATE"
				[currentDate]="repForm.get('custom_start').value"
				[pickerType]="'DATE'"
				[pickerMode]="'dialog'"
				[pickerLabel]="'Start Date'"
				[format]="'ddd MMM Do, YYYY'"
				(pickerDidClose)="repForm.get('custom_start').setValue($event)"
			>
			</app-date-time-picker>
		</div>

		<div *ngIf="isCustomDate()" class="form-group form-group-lg" [class.input-required]="!repForm.get('custom_end').value">
			<div class="input-label">
				<strong>End Date</strong>
				<span *ngIf="!repForm.get('custom_end').value" class="float-right">(required)</span>
			</div>
			<app-date-time-picker
				id="CUSTOM_END_DATE"
				[currentDate]="repForm.get('custom_end').value"
				[pickerType]="'DATE'"
				[pickerMode]="'dialog'"
				[pickerLabel]="'End Date'"
				[format]="'ddd MMM Do, YYYY'"
				(pickerDidClose)="repForm.get('custom_end').setValue($event)"
			>
			</app-date-time-picker>
		</div>

		<div *ngIf="dateRangeInvalid()" class="row">
			<br />
			<div class="col-12">
				<div class="alert alert-danger">End date must be equal to or later than the start date</div>
			</div>
		</div>

		<div [hidden]="isCustomDate()" class="radio-block" style="margin-top: 30px">
			<!-- <hr style="margin-top: 30px" /> -->
			<div class="tts-hl-2" style="font-size: 1.4em; margin-bottom: 15px">Run Report</div>

			<div class="checkbox-item d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-radioButton value="MANUAL" formControlName="manual"></p-radioButton>
				</div>
				<div class="checkbox-label-wrap">
					<div (click)="toggleRadio('manual', 'MANUAL')"><strong>Only when I click run</strong></div>
				</div>
			</div>
			<div class="checkbox-item d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-radioButton value="AUTO" formControlName="manual"></p-radioButton>
				</div>
				<div class="checkbox-label-wrap">
					<div (click)="toggleRadio('manual', 'AUTO')"><strong>Automatically</strong></div>
				</div>
			</div>
		</div>

		<!-- <div *ngIf="!isCustomDate()" class="row">
			<div class="col-12">
				<div class="report-checkbox">
					<p-checkbox  formControlName="manual" [binary]="true"></p-checkbox>
					<strong (click)="toggleCheckbox('manual')" class="has-pointer">Manual run only</strong>
					<a href="#" title="More Information" class="tsf-info-btn" (click)="displayRunManuallyDialog()">
						<i class="far fa-info-circle" aria-hidden="true"></i>
					</a>
				</div>
			</div>
		</div> -->

		<div [hidden]="repForm.value.manual === 'MANUAL' || isCustomDate()" style="margin-top: 20px; color: #887a56">
			<div>
				<div class="run-label" style="font-size: 1.3em; font-weight: 600">Run at</div>
				<div class="run-input">
					<app-date-time-picker
						id="RUN_TIME"
						[currentDate]="repForm.get('run_time').value"
						[pickerType]="'TIME'"
						[pickerMode]="'dialog'"
						[pickerLabel]="'Run at'"
						[format]="is12Hours ? 'h:mm a' : 'HH:mm'"
						[hour12Timer]="is12Hours"
						(pickerDidClose)="repForm.get('run_time').setValue($event)"
					>
					</app-date-time-picker>
				</div>
			</div>

			<div style="margin-top: 20px">
				<!-- <strong>Timezone</strong> -->
				<select id="timezone" class="form-control" formControlName="timezone">
					<option *ngFor="let tz of timezoneData" [value]="tz.zone_name">{{ tz.display_name ? tz.display_name : tz.zone_name }}</option>
				</select>
			</div>

			<div *ngIf="isReportRunDelayAvailable && !isDaily()" class="form-label-bold" style="margin-top: 10px">
				<input
					type="tel"
					class="form-control form-inline delay-input"
					formControlName="delay_days"
					(change)="validateNumericInput('delay_days')"
				/>day(s) after end date
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('delayDays')"></i>
			</div>
		</div>

		<div *ngIf="displayDecimalCheckbox()" formGroupName="options" class="radio-block" style="margin-top: 30px">
			<!-- <hr style="margin-top: 30px" /> -->
			<div class="tts-hl-2" style="font-size: 1.4em; margin-bottom: 15px">Format Hours Using</div>

			<div class="checkbox-item d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-radioButton value="HOURMIN" formControlName="decimal_enabled"></p-radioButton>
				</div>
				<div class="checkbox-label-wrap">
					<div (click)="toggleRadio('options.decimal_enabled', 'HOURMIN')">
						<strong><span class="float-right" style="color: gray">(ex: 4:30)</span>Hours and minutes</strong>
					</div>
				</div>
			</div>
			<div class="checkbox-item d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-radioButton value="DECIMAL" formControlName="decimal_enabled"></p-radioButton>
				</div>
				<div class="checkbox-label-wrap">
					<div (click)="toggleRadio('options.decimal_enabled', 'DECIMAL')">
						<strong><span class="float-right" style="color: gray">(ex: 4.5)</span>Decimal form</strong>
					</div>
				</div>
			</div>
		</div>

		<!-- <div class="row" *ngIf="displayDecimalCheckbox()" style="margin-top: 25px;">
			<div class="col-12">
				<div class="report-checkbox">
					<div formGroupName="options" class="report-checkbox">
						<p-checkbox  formControlName="decimal_enabled" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('options.decimal_enabled')" class="has-pointer">Show hours worked as decimal</strong>
					</div>
				</div>
			</div>
		</div> -->

		<div [hidden]="true" class="action-btn-block">
			<button
				type="submit"
				[disabled]="!isFormValid()"
				class="btn btn-sm btn-block btn-success"
				(click)="submit(repForm)"
				(keyup.enter)="submit(repForm)"
			>
				{{ isNew ? 'Save Report' : 'Update Report' }}
			</button>
		</div>

		<!-- Brgin Advanced Block -->

		<!-- <hr style="margin-top: 25px" /> -->
		<app-form-expander
			[label]="'Advanced Options'"
			[style]="{ 'margin-top': '30px' }"
			[centerOnExpansion]="true"
			[(isExpanded)]="showAdvancedOptions"
		>
			<div class="form-group">
				<div class="input-label form-spacer">
					<strong>Report Owner</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('reportOwner')"></i>
					<!-- <span *ngIf="!repForm.get('supervisor').value" class="input-required float-right">(required)</span> -->
				</div>
				<p-select
					appScrollLockSelect
					id="supervisors"
					[options]="supervisorOptions"
					formControlName="supervisor"
					[filter]="true"
					filterBy="label"
				></p-select>
			</div>

			<div *ngIf="repForm.get('supervisor').value && showUserGroups" class="form-group">
				<div class="input-label form-spacer">
					<strong>Share with Group</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('userGroup')"></i>
					<!-- <span *ngIf="!repForm.get('supervisor_group_id').value" class="input-required float-right">(required)</span> -->
				</div>
				<p-select
					appScrollLockSelect
					id="userGroupsDropdown"
					[options]="userGroupOptions"
					formControlName="supervisor_group_id"
					[filter]="true"
					filterBy="label"
				></p-select>
			</div>

			<div *ngIf="repForm.get('supervisor').value && shouldShowShareWithSupervisors" class="form-group">
				<div class="input-label form-spacer">
					<strong>Share with Supervisors</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('shared_supervisor_ids')"></i>
				</div>
				<app-multi-select-details
					[type]="'USER'"
					[showFullList]="viewOnly"
					placeholder="Select Supervisors"
					selectedItemsLabel="Supervisors Selected: {0}"
					[options]="supervisorsMultiSelect"
					[model]="repForm.get('shared_supervisor_ids').value"
					(modelChange)="repForm.get('shared_supervisor_ids').setValue($event)"
				>
				</app-multi-select-details>
			</div>

			<div *ngIf="shouldShowFileUploadDaysInput" class="options-block" style="margin-top: 30px">
				<div formGroupName="options">
					<div class="float-right">
						<input type="number" pattern="\d*" class="form-control" style="max-width: 100px" formControlName="file_uploads_days" />
					</div>
					<div>
						<div style="margin-top: -4px">
							<strong>File expiration window</strong>
							<i
								title="More Information"
								class="far fa-info-circle tts-info-btn"
								aria-hidden="true"
								(click)="showHelp('file_uploads_days')"
							></i>
							<div style="font-size: 0.9rem; color: gray">Number of days</div>
						</div>
					</div>
				</div>
			</div>

			<!-- <div *ngIf="!showUserGroups" class="alert alert-warning">No options currently available</div> -->

			<!-- <div *ngIf="showTooltips">
				<hr class="tooltip-divider" />
			</div> -->

			<!-- Begin Report Options -->
			<div class="options-title tts-hl-2" style="margin-top: 30px">Report Options</div>

			<div class="row">
				<div class="col-12">
					<div formGroupName="options" class="report-checkbox">
						<p-checkbox formControlName="pagination_enabled" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('options.pagination_enabled')" class="has-pointer">Enable pagination</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('enablePagination')"></i>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<div formGroupName="options" class="report-checkbox">
						<p-checkbox formControlName="suspect_enabled" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('options.suspect_enabled')" class="has-pointer">Include time entry issues summary</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('suspectEntries')"></i>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div formGroupName="options" class="report-checkbox">
						<p-checkbox formControlName="employee_rate_enabled" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('options.employee_rate_enabled')" class="has-pointer">Include employee pay rate info</strong>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<div formGroupName="options" class="report-checkbox">
						<p-checkbox formControlName="vendor_rate_enabled" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('options.vendor_rate_enabled')" class="has-pointer">Include vendor pay rate info</strong>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div formGroupName="options" class="report-checkbox">
						<p-checkbox formControlName="client_rate_enabled" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('options.client_rate_enabled')" class="has-pointer">Include client bill rate info</strong>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div formGroupName="options" class="report-checkbox">
						<p-checkbox formControlName="show_emp_tags" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('options.show_emp_tags')" class="has-pointer">Include employee tag info</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('show_emp_tags')"></i>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div formGroupName="options" class="report-checkbox">
						<p-checkbox formControlName="show_job_tags" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('options.show_job_tags')" class="has-pointer">Include job tag info</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('show_job_tags')"></i>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div formGroupName="options" class="report-checkbox">
						<p-checkbox formControlName="show_emp_in_out_notes" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('options.show_emp_in_out_notes')" class="has-pointer">Include employee notes info</strong>
						<i
							title="More Information"
							class="far fa-info-circle tts-info-btn"
							aria-hidden="true"
							(click)="showHelp('show_emp_in_out_notes')"
						></i>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div formGroupName="options" class="report-checkbox">
						<p-checkbox formControlName="show_admin_notes" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('options.show_admin_notes')" class="has-pointer">Include admin notes info</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('show_admin_notes')"></i>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div formGroupName="options" class="report-checkbox">
						<p-checkbox formControlName="show_comm_log" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('options.show_comm_log')" class="has-pointer">Include comm log records</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('show_comm_log')"></i>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div class="report-checkbox">
						<p-checkbox formControlName="send_sms" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('send_sms')" class="has-pointer">Send text when report runs</strong>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<div class="report-checkbox">
						<p-checkbox formControlName="send_email" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('send_email')" class="has-pointer">Send email when report runs</strong>
					</div>
				</div>
			</div>

			<div *ngIf="displayAddEmailInput()" class="form-group" style="margin-top: 20px">
				<div *ngIf="reportEmails.length > 0" class="form-group">
					<div style="margin-bottom: 15px">
						<label class="form-control-label">
							<strong class="tts-hl-2">Send Report Emails To</strong>
						</label>
					</div>
					<div class="btn btn-sm btn-block btn-email" *ngFor="let email of reportEmails; let idx = index">
						{{ email }}
						<i (click)="onRemoveEmail(idx)" class="fa fa-times float-right btn-email-x" aria-hidden="true"></i>
					</div>
				</div>

				<div class="form-group" style="margin-top: 20px">
					<table width="100%">
						<tr>
							<td>
								<input
									#newEmail
									placeholder="enter email address"
									(focus)="newEmail.placeholder = ''"
									(blur)="newEmail.placeholder = 'enter email address'"
									[(ngModel)]="newEmailInput"
									[ngModelOptions]="{ standalone: true }"
									id="newEmail"
									class="form-control"
									type="text"
									autocapitalize="off"
									autocomplete="off"
								/>
							</td>
							<td>&nbsp;</td>
							<td style="text-align: right">
								<button
									(click)="addReportEmail(true)"
									(keyup.enter)="addReportEmail(true)"
									[disabled]="!isEmailInputValid()"
									class="btn btn-sm btn-success"
								>
									Add
								</button>
							</td>
						</tr>
					</table>
					<div *ngIf="isEmailInputValid()" style="color: green; margin-top: 8px">Click <strong>Add</strong> to include another address</div>
					<div *ngIf="!newEmailInput && !isReportEmailValid()" class="email-note" style="color: firebrick; margin-top: 8px">
						An email address is required
					</div>
					<div *ngIf="newEmailInput && !isEmailInputValid()" class="email-note" style="color: firebrick; margin-top: 8px">
						Not a valid email address
					</div>
					<hr style="margin-top: 35px" />
				</div>
			</div>
			<!-- End Report Options -->

			<div class="options-title tts-hl-2" style="margin-top: 20px">
				<div>Employee Sorting</div>
			</div>
			<div class="row" style="margin-top: 20px">
				<div class="col-4">
					<div style="padding-top: 5px">
						<strong>Sort by</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('employee_sort')"></i>
					</div>
				</div>
				<div class="col-8">
					<div formGroupName="options">
						<select class="form-control form-block" formControlName="employee_sort">
							<option value="last">Last Name</option>
							<option value="first">First Name</option>
							<option value="hours">Total Hours</option>
							<option value="transactions">Transaction Count</option>
							<option value="id">Order Created</option>
						</select>
					</div>
				</div>
			</div>
			<hr style="margin-top: 30px" />

			<!-- Begin Filter Options -->
			<div class="options-title tts-hl-2" style="margin-top: 20px">
				<div>Filter Options</div>
			</div>

			<div *ngIf="showSupFilterOptions">
				<!-- Employee Supervisors MultiSelect -->
				<div class="row filter-options-block">
					<div class="col-12">
						<div class="report-checkbox" formGroupName="filterOptions">
							<p-checkbox formControlName="emp_supervisor_id" [binary]="true"></p-checkbox>
							<strong (click)="toggleCheckbox('filterOptions.emp_supervisor_id')" class="has-pointer">Employee Supervisors</strong>
						</div>
						<div *ngIf="repForm.value.filterOptions.emp_supervisor_id" style="margin-bottom: 30px">
							<app-multi-select-details
								[type]="'USER'"
								[showFullList]="viewOnly"
								placeholder="Select Supervisors"
								selectedItemsLabel="Supervisors Selected: {0}"
								[options]="supervisorsMultiSelect"
								[model]="repForm.get('filterData.emp_supervisor_id').value"
								(modelChange)="repForm.get('filterData.emp_supervisor_id').setValue($event)"
							>
							</app-multi-select-details>
						</div>
					</div>
				</div>

				<!-- Job Site Supervisors MultiSelect -->
				<div class="row filter-options-block">
					<div class="col-12">
						<div class="report-checkbox" formGroupName="filterOptions">
							<p-checkbox formControlName="jobsite_supervisor_id" [binary]="true"></p-checkbox>
							<strong (click)="toggleCheckbox('filterOptions.jobsite_supervisor_id')" class="has-pointer">Job Site Supervisors</strong>
						</div>
						<div *ngIf="repForm.value.filterOptions.jobsite_supervisor_id" style="margin-bottom: 30px">
							<app-multi-select-details
								[type]="'USER'"
								[showFullList]="viewOnly"
								placeholder="Select Supervisors"
								selectedItemsLabel="Supervisors Selected: {0}"
								[options]="supervisorsMultiSelect"
								[model]="repForm.get('filterData.jobsite_supervisor_id').value"
								(modelChange)="repForm.get('filterData.jobsite_supervisor_id').setValue($event)"
							>
							</app-multi-select-details>
						</div>
					</div>
				</div>
			</div>

			<!-- Employees MultiSelect -->
			<div class="row filter-options-block">
				<div class="col-12">
					<div class="report-checkbox" formGroupName="filterOptions">
						<p-checkbox formControlName="emp_id" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('filterOptions.emp_id')" class="has-pointer">Employees</strong>
					</div>
					<div *ngIf="repForm.value.filterOptions.emp_id" style="margin-bottom: 30px">
						<app-multi-select-details
							[showFullList]="viewOnly"
							placeholder="Select Employees"
							selectedItemsLabel="Employees Selected: {0}"
							[options]="employeesMultiSelect"
							[model]="repForm.get('filterData.emp_id').value"
							(modelChange)="repForm.get('filterData.emp_id').setValue($event)"
						>
						</app-multi-select-details>
						<div class="tag-dd-block">
							<app-multi-select-details
								[showFullList]="viewOnly"
								[type]="'TAG'"
								[notFoundMsg]="'Unknown Tag'"
								placeholder="Select Employee Tags"
								selectedItemsLabel="Tags Selected: {0}"
								[options]="employeeTagMultiSelect"
								[model]="repForm.get('filterData.employee_tags').value"
								(modelChange)="repForm.get('filterData.employee_tags').setValue($event)"
							>
							</app-multi-select-details>
						</div>
						<div style="margin-top: 14px">
							<app-multi-select-details
								[showFullList]="viewOnly"
								[type]="'STRING'"
								[notFoundMsg]="'Unknown Dept'"
								placeholder="Select Departments"
								selectedItemsLabel="Departments Selected: {0}"
								[options]="employeeDepartmentMultiSelect"
								[model]="repForm.get('filterData.employee_departments').value"
								(modelChange)="repForm.get('filterData.employee_departments').setValue($event)"
							>
							</app-multi-select-details>
						</div>
					</div>
				</div>
			</div>

			<!-- Job Sites MultiSelect -->
			<div *ngIf="!isJobSiteMerged" class="row filter-options-block">
				<div class="col-12">
					<div class="report-checkbox" formGroupName="filterOptions">
						<p-checkbox formControlName="jobsite_id" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('filterOptions.jobsite_id')" class="has-pointer">Job Sites</strong>
					</div>
					<div *ngIf="repForm.value.filterOptions.jobsite_id" style="margin-bottom: 30px">
						<app-multi-select-details
							[type]="'SITE'"
							[showFullList]="viewOnly"
							placeholder="Select Job Sites"
							selectedItemsLabel="Job Sites Selected: {0}"
							[options]="jobSitesMultiSelect"
							[model]="repForm.get('filterData.jobsite_id').value"
							(modelChange)="repForm.get('filterData.jobsite_id').setValue($event)"
						>
						</app-multi-select-details>
						<div *ngIf="!isJobSiteMerged" class="district-block">
							<app-multi-select-details
								[type]="'STRING'"
								[showFullList]="viewOnly"
								[placeholder]="districtplaceholder"
								[selectedItemsLabel]="districtSelectedLabel"
								[options]="districtMultiSelect"
								[model]="repForm.get('filterData.jobsite_districts').value"
								(modelChange)="repForm.get('filterData.jobsite_districts').setValue($event)"
							>
							</app-multi-select-details>
						</div>
					</div>
				</div>
			</div>

			<!-- Jobs MultiSelect -->
			<div class="row filter-options-block">
				<div class="col-12">
					<div class="report-checkbox" formGroupName="filterOptions">
						<p-checkbox formControlName="job_id" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('filterOptions.job_id')" class="has-pointer">Jobs</strong>
					</div>
					<div *ngIf="repForm.value.filterOptions.job_id" style="margin-bottom: 30px">
						<app-multi-select-details
							[type]="'JOB'"
							[showFullList]="viewOnly"
							placeholder="Select Jobs"
							selectedItemsLabel="Jobs Selected: {0}"
							[options]="jobsMultiSelect"
							[model]="repForm.get('filterData.job_id').value"
							(modelChange)="repForm.get('filterData.job_id').setValue($event)"
						>
						</app-multi-select-details>
						<div class="tag-dd-block">
							<app-multi-select-details
								[showFullList]="viewOnly"
								[type]="'TAG'"
								[notFoundMsg]="'Unknown Tag'"
								placeholder="Select Job Tags"
								selectedItemsLabel="Tags Selected: {0}"
								[options]="jobTagMultiSelect"
								[model]="repForm.get('filterData.job_tags').value"
								(modelChange)="repForm.get('filterData.job_tags').setValue($event)"
							>
							</app-multi-select-details>
						</div>
						<div *ngIf="isJobSiteMerged" class="district-block">
							<app-multi-select-details
								[type]="'STRING'"
								[showFullList]="viewOnly"
								[placeholder]="districtplaceholder"
								[selectedItemsLabel]="districtSelectedLabel"
								[options]="districtMultiSelect"
								[model]="repForm.get('filterData.jobsite_districts').value"
								(modelChange)="repForm.get('filterData.jobsite_districts').setValue($event)"
							>
							</app-multi-select-details>
						</div>
					</div>
				</div>
			</div>

			<!-- Clients MultiSelect -->
			<div *ngIf="clientsMultiSelect.length > 0" class="row filter-options-block">
				<div class="col-12">
					<div class="report-checkbox" formGroupName="filterOptions">
						<p-checkbox formControlName="client_id" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('filterOptions.client_id')" class="has-pointer">Clients</strong>
					</div>
					<div *ngIf="repForm.value.filterOptions.client_id" style="margin-bottom: 30px">
						<app-multi-select-details
							[type]="'CLIENT'"
							[showFullList]="viewOnly"
							placeholder="Select Clients"
							selectedItemsLabel="Clients Selected: {0}"
							[options]="clientsMultiSelect"
							[model]="repForm.get('filterData.client_id').value"
							(modelChange)="repForm.get('filterData.client_id').setValue($event)"
						>
						</app-multi-select-details>
					</div>
				</div>
			</div>

			<!-- Vendors MultiSelect -->
			<div *ngIf="vendorsMultiSelect.length > 0" class="row filter-options-block">
				<div class="col-12">
					<div class="report-checkbox" formGroupName="filterOptions">
						<p-checkbox formControlName="vendor_id" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('filterOptions.vendor_id')" class="has-pointer">Vendors</strong>
					</div>
					<div *ngIf="repForm.value.filterOptions.vendor_id" style="margin-bottom: 30px">
						<app-multi-select-details
							[type]="'VENDOR'"
							[showFullList]="viewOnly"
							placeholder="Select Vendors"
							selectedItemsLabel="Vendors Selected: {0}"
							[options]="vendorsMultiSelect"
							[model]="repForm.get('filterData.vendor_id').value"
							(modelChange)="repForm.get('filterData.vendor_id').setValue($event)"
						>
						</app-multi-select-details>
					</div>
				</div>
			</div>

			<!-- State/Province MultiSelect -->
			<!-- <div *ngIf="districtMultiSelect.length > 0" class="row filter-options-block">
				<div class="col-12">
					<div class="report-checkbox" formGroupName="filterOptions">
						<p-checkbox  formControlName="district_code" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('filterOptions.district_code')" class="has-pointer">Region</strong>
					</div>
					<div *ngIf="repForm.value.filterOptions.district_code" style="margin-bottom: 30px">
						<app-multi-select-details
							[type]="'REGION'"
							[showFullList]="viewOnly"
							placeholder="Select Region"
							selectedItemsLabel="Region Selected: {0}"
							[options]="vendorsMultiSelect"
							[model]="repForm.get('filterData.district_code').value"
							(modelChange)="repForm.get('filterData.district_code').setValue($event)"
						>
						</app-multi-select-details>
					</div>
				</div>
			</div> -->

			<div *ngIf="displayDayOfWeekFilter()" class="row" style="margin-top: 25px">
				<div class="col-12">
					<div class="report-checkbox options-title">
						<strong class="tts-hl-2">Day of Week Filter</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('dayOfWeek')"></i>
					</div>
					<div *ngIf="selectedDays.length === 0" class="alert alert-warning">
						You must have at least one day selected for day of week report coverage.
					</div>
					<div class="weekday-buttons">
						<div
							*ngFor="let weekday of dayOptions"
							class="weekday-btn"
							[class.weekday-btn-hl]="isWeekdaySelected(weekday)"
							(click)="toggleWeekday(weekday)"
						>
							{{ weekday.code }}
						</div>
					</div>
				</div>
			</div>

			<!-- End Filter Options -->

			<hr style="margin-bottom: 40px" />

			<div class="alert alert-warning" style="margin-top: 25px; margin-bottom: 30px">
				You may create reports using the optional filters. When multiple options are selected, only records matching those filters will be
				included in the report, otherwise all records will be included. There is no need to select any filter options unless you wish to limit
				your reports to a subset of the available data.
			</div>
		</app-form-expander>

		<!-- <hr style="margin-top: 28px" />
		<div style="font-size: 1.4em; cursor: pointer" (click)="showAdvancedOptions = !showAdvancedOptions">
			<div class="float-right" style="color: gray">
				<i *ngIf="!showAdvancedOptions" class="fa fa-chevron-right"></i>
				<i *ngIf="showAdvancedOptions" class="fa fa-chevron-down"></i>
			</div>
			<div class="tts-hl-2">Advanced Options</div>
		</div>
		<div *ngIf="showAdvancedOptions" style="margin-top: 18px"></div> -->

		<!-- End Advanced Block -->
	</form>
</div>

<div *ngIf="false" class="container">
	<div class="alert alert-info" style="margin-top: 25px">
		<code>
			<pre>{{ repForm.value | json }}</pre>
		</code>
	</div>
</div>
