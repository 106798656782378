import { Component, OnDestroy, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { log } from '@app/helpers'
import { BannerStatus, BannerViewManager } from '@app/models'
import { ScheduleLogRecord } from '@app/models/schedule-log'
import { CoreService, DatabaseService } from '@app/services'
import { Subject, Subscription } from 'rxjs'
import moment from 'moment-timezone'

@Component({
    selector: 'app-open-shifts-banner',
    templateUrl: './open-shifts-banner.component.html',
    styleUrls: ['./open-shifts-banner.component.scss'],
    standalone: false
})
export class OpenShiftsBannerComponent implements OnInit, OnDestroy {
	bvm: BannerViewManager
	status: BannerStatus

	subs = new Subscription()

	@Output() receivedUpdate = new Subject<boolean>()

	constructor(
		private router: Router,
		private coreSrvc: CoreService,
	) {
		this.bvm = this.coreSrvc.bannerSrvc.viewManager
		this.status = this.coreSrvc.bannerSrvc.viewManager.openShifts
		this.subs.add(this.coreSrvc.notifySrvc.databaseFinishedLoading.subscribe(() => this.updateUI()))

		this.subs.add(
			this.coreSrvc.dbSrvc.schedulerSrvc.scheduleRecurListUpdated.subscribe(() => {
				log('OpenShiftsBannerComponent Got update')
				this.updateUI()
			}),
		)
		this.subs.add(
			this.coreSrvc.dbSrvc.schedulerSrvc.openShiftListUpdated.subscribe(() => {
				log('OpenShiftsBannerComponent Got update')
				this.updateUI()
			}),
		)
	}

	get shouldShowBanner(): boolean {
		return this.status?.notificationCount > 0
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	private updateUI() {
		const openShiftList = this.coreSrvc.dbSrvc.schedulerSrvc.getOpenShifts()
		this.status.notificationCount = openShiftList.length

		const listItems = openShiftList.map((rec) => new ScheduleLogWrapper(this.coreSrvc.dbSrvc, rec))

		let isWarn = false
		let isDanger = false
		let isCritical = false
		let hasGreenIndicator = false

		for (const item of listItems) {
			if (item.hoursUntilShift <= 24) isWarn = true
			if (item.hoursUntilShift <= 12) isDanger = true
			if (item.hoursUntilShift <= 1) isCritical = true
			if (item.hasAcceptedOffers) hasGreenIndicator = true
		}
		this.status.isWarn = isWarn
		this.status.isDanger = isDanger
		this.status.isCritical = isCritical
		this.status.hasBeenAccepted = hasGreenIndicator

		this.coreSrvc.bannerSrvc.groupBannerNeedsUpdate.next(true)
	}

	public viewOpenShifts() {
		log('View open shifts')
		this.coreSrvc.bannerSrvc.bannerPanelShouldHide.next(true)
		this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.shift.showOpenShifts = true
		this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.setScheduleViewSection('SHIFT')
		this.coreSrvc.dbSrvc.schedulerSrvc.toggleDayView('OFF')
		this.router.navigate(['/admin/scheduler'])
	}
}

class ScheduleLogWrapper {
	schedLog: ScheduleLogRecord
	hoursUntilShift = null
	hasAcceptedOffers = false

	constructor(dbSrvc: DatabaseService, schedLog: ScheduleLogRecord) {
		this.schedLog = schedLog

		const jobStartMom = moment(schedLog.job_start)
		this.hoursUntilShift = jobStartMom.diff(moment(), 'hours')

		// Check for accepted offers
		const offer = this.schedLog.schedule_os_offer[0]
		this.hasAcceptedOffers = offer?.status === 'OPEN_NEEDS_APPROVAL'
	}
}
