<nav class="navbar navbar-expand fixed-top navbar-light bg-light fh-fixedHeader noprint">
	<div class="container-fluid">
		<div>
			<div style="display: inline-block">
				<div [routerLink]="['/admin/dashboard']" class="navbar-quicklink" (click)="collapseMenu()">
					<i class="far fa-dashboard" aria-hidden="true"></i>
				</div>
				<app-header-dispatcher [dialogManager]="dispatchDialogManager"></app-header-dispatcher>
			</div>

			<div style="display: inline-block">
				<ul class="nav navbar-nav">
					<!-- People Menu -->
					<li class="nav-item nav-item-integration" style="cursor: pointer">
						<div class="btn-group">
							<span
								id="peopleMenu"
								class="nav-link link-has-carrot"
								[class.header-link-active]="menuManager.peopleMenu"
								data-bs-toggle="dropdown"
							>
								<i *ngIf="!menuManager.peopleMenu" class="fal fa-user" aria-hidden="true"></i>
								<i *ngIf="menuManager.peopleMenu" class="fas fa-user" aria-hidden="true"></i>
								People
							</span>

							<div class="dropdown-menu dropdown-menu-left" style="margin-top: 6px; width: 220px">
								<a class="dropdown-item" [class.header-link-active]="menuManager.employeesMenu" [routerLink]="['/admin/employees']">
									<i class="fal fa-user nav-sub-ico" aria-hidden="true"></i> Employees
								</a>
								<a class="dropdown-item" [class.header-link-active]="menuManager.supervisors" [routerLink]="['/admin/users']">
									<i class="fal fa-user nav-sub-ico" aria-hidden="true"></i> Supervisors
								</a>
								<a class="dropdown-item" [class.header-link-active]="menuManager.contacts" [routerLink]="['/admin/contacts']">
									<i class="fal fa-address-card nav-sub-ico" aria-hidden="true"></i> Contacts
								</a>
							</div>
						</div>
					</li>

					<!-- Work Menu -->
					<li class="nav-item nav-item-integration" style="cursor: pointer">
						<div class="btn-group">
							<span id="workMenu" class="nav-link link-has-carrot" [class.header-link-active]="menuManager.workMenu" data-bs-toggle="dropdown">
								<i *ngIf="!menuManager.workMenu" class="fal fa-tasks-alt" aria-hidden="true"></i>
								<i *ngIf="menuManager.workMenu" class="fas fa-tasks-alt" aria-hidden="true"></i>
								Work
							</span>

							<div class="dropdown-menu dropdown-menu-left" style="margin-top: 6px; width: 220px">
								<a
									*ngIf="!isJobSiteMerged"
									class="dropdown-item"
									[class.header-link-active]="menuManager.jobSitesMenu"
									[routerLink]="['/admin/jobsites']"
								>
									<i class="fal fa-map-marker-alt nav-sub-ico" aria-hidden="true"></i> Job Sites
								</a>
								<a class="dropdown-item" [class.header-link-active]="menuManager.jobsMenu" [routerLink]="['/admin/jobs']">
									<i class="fal fa-tasks-alt nav-sub-ico" aria-hidden="true"></i> Jobs
								</a>
								<!-- <a
									*ngIf="environment.development"
									class="dropdown-item"
									[class.header-link-active]="menuManager.projectsMenu"
									[routerLink]="['/admin/projects']"
								>
									<i class="fal fa-chart-kanban nav-sub-ico" aria-hidden="true"></i> Projects
								</a> -->
								<a
									*ngIf="environment.development"
									class="dropdown-item"
									[class.header-link-active]="menuManager.projectsMenu"
									[routerLink]="['/admin/breaks']"
								>
									<i class="fal fa-clock nav-sub-ico" aria-hidden="true"></i> Breaks
								</a>
								<a class="dropdown-item" [class.header-link-active]="menuManager.toursMenu" [routerLink]="['/admin/tours']">
									<i class="fal fa-map-pin nav-sub-ico" aria-hidden="true"></i> Tours
								</a>
								<a class="dropdown-item" [class.header-link-active]="menuManager.notificationsMenu" [routerLink]="['/admin/notifications']">
									<i class="fal fa-bell nav-sub-ico" aria-hidden="true"></i> Notifications
								</a>
							</div>
						</div>
					</li>

					<!-- Scheduling Menu -->
					<li class="nav-item nav-item-integration" style="cursor: pointer">
						<div class="btn-group">
							<span
								id="schedulingMenu"
								class="nav-link link-has-carrot"
								[class.header-link-active]="menuManager.schedulingMenu"
								data-bs-toggle="dropdown"
							>
								<i *ngIf="!menuManager.schedulingMenu" class="fal fa-calendar" aria-hidden="true"></i>
								<i *ngIf="menuManager.schedulingMenu" class="fas fa-calendar" aria-hidden="true"></i>
								Scheduling
							</span>

							<div class="dropdown-menu dropdown-menu-left" style="margin-top: 6px; width: 220px">
								<a class="dropdown-item" [class.header-link-active]="menuManager.schedulesMenu" (click)="switchToScheduleView('SERIES')">
									<i class="fal fa-calendar nav-sub-ico" aria-hidden="true"></i> Schedules
								</a>
								<a class="dropdown-item" [class.header-link-active]="menuManager.shiftsMenu" (click)="switchToScheduleView('SHIFT')">
									<i class="fal fa-calendar nav-sub-ico" aria-hidden="true"></i> Shifts
								</a>
								<a class="dropdown-item" [class.header-link-active]="menuManager.timeOffMenu" (click)="switchToScheduleView('TIMEOFF')">
									<i class="fal fa-calendar nav-sub-ico" aria-hidden="true"></i> Time Off
								</a>
							</div>
						</div>
					</li>

					<!-- Operations Menu -->
					<li class="nav-item nav-item-integration" style="cursor: pointer">
						<div class="btn-group">
							<span
								id="operationsMenu"
								class="nav-link link-has-carrot"
								[class.header-link-active]="menuManager.operationsMenu"
								data-bs-toggle="dropdown"
							>
								<i *ngIf="!menuManager.operationsMenu" class="fal fa-shield-check" aria-hidden="true"></i>
								<i *ngIf="menuManager.operationsMenu" class="fas fa-shield-check" aria-hidden="true"></i>
								Operations
							</span>

							<div class="dropdown-menu dropdown-menu-left" style="margin-top: 6px; width: 260px">
								<a class="dropdown-item" [class.header-link-active]="menuManager.timeEntriesMenu" [routerLink]="['/admin/transactions']">
									<i class="fal fa-clock nav-sub-ico" aria-hidden="true"></i> Time Entries
								</a>
								<div class="dropdown-divider"></div>
								<a class="dropdown-item" [class.header-link-active]="menuManager.reportsEmployee" [routerLink]="['/admin/reports/employee']">
									<i class="fal fa-pie-chart nav-sub-ico" aria-hidden="true"></i> Employee Shift Reports
								</a>
								<a class="dropdown-item" [class.header-link-active]="menuManager.reportsAdmin" [routerLink]="['/admin/reports/business']">
									<i class="fal fa-pie-chart nav-sub-ico" aria-hidden="true"></i> Business Insight Reports
								</a>

								<div *ngIf="isAnalyticsEnabled">
									<div class="dropdown-divider"></div>
									<a class="dropdown-item" [class.header-link-active]="menuManager.analytics" [routerLink]="['/admin/analytics']">
										<i class="fal fa-pie-chart nav-sub-ico" aria-hidden="true"></i> Data / Analytics
									</a>
								</div>

								<div class="dropdown-divider"></div>
								<a class="dropdown-item" [class.header-link-active]="menuManager.commLog" [routerLink]="['/admin/comm-log']">
									<i class="fal fa-bars-staggered nav-sub-ico" aria-hidden="true"></i> Comm Log
								</a>
								<a class="dropdown-item" [class.header-link-active]="menuManager.auditLog" [routerLink]="['/admin/auditlog']">
									<i class="fal fa-database nav-sub-ico" aria-hidden="true"></i> Audit Log
								</a>
								<div class="dropdown-divider"></div>
								<a class="dropdown-item" [class.header-link-active]="menuManager.announcements" [routerLink]="['/admin/announcements']">
									<i class="fal fa-bullhorn nav-sub-ico" aria-hidden="true"></i> Announcements
								</a>
							</div>
						</div>
					</li>

					<!-- Integration Menu -->
					<li class="nav-item nav-item-integration" style="cursor: pointer">
						<div class="btn-group">
							<span
								id="integrationsMenu"
								class="nav-link link-has-carrot"
								[class.header-link-active]="menuManager.integrationsMenu"
								data-bs-toggle="dropdown"
							>
								<i *ngIf="!menuManager.integrationsMenu" class="fal fa-plug" aria-hidden="true"></i>
								<i *ngIf="menuManager.integrationsMenu" class="fas fa-plug" aria-hidden="true"></i>
								Integrations
							</span>

							<div class="dropdown-menu dropdown-menu-left" style="margin-top: 6px; width: 220px">
								<a
									*ngIf="isFileImportIntegrated"
									class="dropdown-item"
									[class.header-link-active]="menuManager.fileImporter"
									[routerLink]="['/admin/integrations/fileimport']"
								>
									<i class="far fa-list-alt nav-sub-ico" aria-hidden="true"></i> File Importer
								</a>
								<a class="dropdown-item" [class.header-link-active]="menuManager.adp" [routerLink]="['/admin/integrations/adp']">
									<i class="far fa-list-alt nav-sub-ico" aria-hidden="true"></i> ADP
								</a>
								<a
									class="dropdown-item"
									[class.header-link-active]="menuManager.quickbooks"
									[routerLink]="['/admin/integrations/quickbooks']"
								>
									<i class="far fa-list-alt nav-sub-ico" aria-hidden="true"></i> QuickBooks
								</a>
								<a class="dropdown-item" [class.header-link-active]="menuManager.whenIWork" [routerLink]="['/admin/integrations/wheniwork']">
									<i class="far fa-list-alt nav-sub-ico" aria-hidden="true"></i> When I Work
								</a>
								<a
									class="dropdown-item"
									[class.header-link-active]="menuManager.whenToWork"
									[routerLink]="['/admin/integrations/whentowork']"
								>
									<i class="far fa-list-alt nav-sub-ico" aria-hidden="true"></i> WhenToWork
								</a>
							</div>
						</div>
					</li>

					<!-- Admin Menu -->
					<li class="nav-item nav-item-primary" style="cursor: pointer">
						<div class="btn-group">
							<span
								id="adminMenu"
								class="nav-link link-has-carrot"
								[class.header-link-active]="menuManager.adminMenu"
								data-bs-toggle="dropdown"
							>
								<i *ngIf="!menuManager.adminMenu" class="fal fa-cog" aria-hidden="true"></i>
								<i *ngIf="menuManager.adminMenu" class="fas fa-cog" aria-hidden="true"></i>
								Admin
							</span>

							<div class="dropdown-menu dropdown-menu-left" style="margin-top: 6px; width: 220px">
								<a class="dropdown-item" [class.header-link-active]="menuManager.settings" [routerLink]="['/admin/settings']">
									<i class="far fa-cog nav-sub-ico" aria-hidden="true"></i> Settings
								</a>
								<a class="dropdown-item" [class.header-link-active]="menuManager.billing" [routerLink]="['/admin/settings/billing']">
									<i class="far fa-credit-card nav-sub-ico" aria-hidden="true"></i> Billing
								</a>

								<div class="dropdown-divider"></div>

								<a
									*ngIf="!isAuditMode || isInternalUser"
									class="dropdown-item"
									[class.header-link-active]="menuManager.stations"
									[routerLink]="['/admin/stations']"
								>
									<i class="far fa-computer nav-sub-ico" aria-hidden="true"></i> Stations
								</a>
								<a
									*ngIf="canAccessPayRates"
									class="dropdown-item"
									[class.header-link-active]="menuManager.payRates"
									[routerLink]="['/admin/payrates']"
								>
									<i class="fal fa-money-check-edit-alt nav-sub-ico" aria-hidden="true"></i> Bill / Pay Rates
								</a>
								<div class="dropdown-divider"></div>

								<a class="dropdown-item" (click)="changePassword()">
									<i class="far fa-lock nav-sub-ico" aria-hidden="true"></i> Change Password
								</a>
								<a *ngIf="canCloseAccount" class="dropdown-item" [routerLink]="['/admin/close-account']">
									<i class="far fa-ban nav-sub-ico" aria-hidden="true"></i> Close Account
								</a>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</nav>

<div class="menu-box-floater noprint">
	<!-- Navbar Icons -->
	<div class="btn-group main-menu-button">
		<div style="margin-right: 5px">
			<div *ngFor="let icon of navbarIcons" style="display: inline-block">
				<div [hidden]="isIconHidden(icon)">
					<img *ngIf="icon.imageUrl" [src]="icon.imageUrl" [style]="icon.styleAttr" [title]="icon.title" (click)="icon.click()" />
					<i *ngIf="icon.class" [class]="icon.class" [style]="icon.styleAttr" [title]="icon.title" (click)="icon.click()"></i>
				</div>
			</div>
		</div>

		<a data-bs-toggle="dropdown" href="#" id="mainMenuDropdownBtn" aria-haspopup="true" aria-expanded="false" (click)="clearToasts()">
			<div style="width: 35px; text-align: right">
				<i *ngIf="!isWorking" class="fas fa-bars menu-button-icon" aria-hidden="true"></i>
				<i *ngIf="isWorking" class="fad fa-spinner fa-spin menu-button-spinner" aria-hidden="true"></i>
			</div>
		</a>

		<div class="dropdown-menu dropdown-menu-right" style="margin-top: 3px; width: 200px" aria-labelledby="mainMenuDropdownBtn">
			<a
				id="hbPeopleMenu"
				class="dropdown-item"
				[class.header-link-active]="menuManager.peopleMenu"
				(click)="showSettingsDialog = false; showPeopleDialog = true; collapseMenu()"
			>
				<i class="far fa-user nav-sub-ico" aria-hidden="true"></i> People</a
			>

			<a
				id="hbWorkMenu"
				class="dropdown-item"
				[class.header-link-active]="menuManager.workMenu"
				(click)="showSettingsDialog = false; showWorkDialog = true; collapseMenu()"
			>
				<i class="fal fa-tasks-alt nav-sub-ico" aria-hidden="true"></i> Work</a
			>

			<a
				id="hbSchedulingMenu"
				class="dropdown-item"
				[class.header-link-active]="menuManager.schedulingMenu"
				(click)="showSettingsDialog = false; showSchedulingDialog = true; collapseMenu()"
			>
				<i class="far fa-calendar nav-sub-ico" aria-hidden="true"></i> Scheduling</a
			>

			<a
				id="hbOperationsMenu"
				class="dropdown-item"
				[class.header-link-active]="menuManager.operationsMenu"
				(click)="showSettingsDialog = false; showOperationsDialog = true; collapseMenu()"
			>
				<i class="far fa-shield-check nav-sub-ico" aria-hidden="true"></i> Operations</a
			>

			<div class="dropdown-divider"></div>

			<a
				class="dropdown-item"
				[class.header-link-active]="menuManager.integrationsMenu"
				(click)="showSettingsDialog = false; showIntegrationDialog = true; collapseMenu()"
			>
				<i class="far fa-plug nav-sub-ico" aria-hidden="true"></i> Integrations</a
			>
			<a
				class="dropdown-item"
				[class.header-link-active]="menuManager.adminMenu"
				(click)="showMoreOptions = false; showIntegrationDialog = false; showSettingsDialog = true; collapseMenu()"
			>
				<i class="far fa-cog nav-sub-ico" aria-hidden="true"></i> Admin</a
			>
			<div class="dropdown-divider"></div>
			<a [routerLink]="['/logout']" class="dropdown-item" (click)="collapseMenu()">
				<i class="far fa-sign-out nav-sub-ico" aria-hidden="true"></i> Log Out</a
			>
			<div class="dropdown-item has-pointer" (click)="reloadApp()"><i class="far fa-refresh nav-sub-ico" aria-hidden="true"></i> Reload App</div>
		</div>
	</div>
</div>

<!-- Begin Admin Dialog -->
<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Settings Options"
	[(visible)]="showSettingsDialog"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div *ngIf="!showMoreOptions">
		<button [routerLink]="['/admin/settings']" class="btn-menu btn-menu-primary" style="margin-top: 6px" (click)="showSettingsDialog = false">
			Settings
		</button>

		<button [routerLink]="['/admin/settings/billing']" class="btn-menu btn-menu-primary" (click)="showSettingsDialog = false">Billing</button>

		<button
			*ngIf="!isAuditMode || isInternalUser"
			[routerLink]="['/admin/stations']"
			class="btn-menu btn-menu-primary"
			(click)="showSettingsDialog = false"
		>
			Stations
		</button>

		<button *ngIf="canAccessPayRates" [routerLink]="['/admin/payrates']" class="btn-menu btn-menu-primary" (click)="showSettingsDialog = false">
			Bill / Pay Rates
		</button>

		<button class="btn-menu btn-menu-secondary" (click)="showMoreOptions = true" style="margin-bottom: 6px">More Options</button>
	</div>

	<!-- More Options Menu Items -->
	<div *ngIf="showMoreOptions">
		<button class="btn-menu btn-menu-secondary" style="margin-top: 6px" (click)="changePassword()">Change Password</button>
		<button *ngIf="canCloseAccount" [routerLink]="['/admin/close-account']" class="btn-menu btn-menu-danger" (click)="showSettingsDialog = false">
			Close Account
		</button>
		<button class="btn-menu btn-menu-primary" (click)="showMainMenuButtons()" style="margin-bottom: 6px">Main Menu</button>
	</div>
</p-dialog>
<!-- End Admin Dialog -->

<!-- Begin People Dialog -->
<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Report Options"
	[(visible)]="showPeopleDialog"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div>
		<div>
			<button [routerLink]="['/admin/employees']" class="btn-menu btn-menu-primary" style="margin-top: 6px" (click)="showPeopleDialog = false">
				Employees
			</button>
			<button [routerLink]="['/admin/users']" class="btn-menu btn-menu-primary" (click)="showPeopleDialog = false">Supervisors</button>
			<button [routerLink]="['/admin/contacts']" class="btn-menu btn-menu-primary" style="margin-bottom: 6px" (click)="showPeopleDialog = false">
				Contacts
			</button>
		</div>
	</div>
</p-dialog>

<!-- Begin Work Dialog -->
<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Work Options"
	[(visible)]="showWorkDialog"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div>
		<div>
			<button
				*ngIf="!isJobSiteMerged"
				[routerLink]="['/admin/jobsites']"
				class="btn-menu btn-menu-primary"
				style="margin-top: 6px"
				(click)="showWorkDialog = false"
			>
				Job Sites
			</button>
			<button [routerLink]="['/admin/jobs']" class="btn-menu btn-menu-primary" (click)="showWorkDialog = false">Jobs</button>
			<!-- <button [routerLink]="['/admin/projects']" class="btn-menu btn-menu-primary" (click)="showWorkDialog = false">Projects</button> -->
			<button *ngIf="environment.development" [routerLink]="['/admin/breaks']" class="btn-menu btn-menu-primary" (click)="showWorkDialog = false">
				Breaks
			</button>
			<button [routerLink]="['/admin/tours']" class="btn-menu btn-menu-primary" (click)="showWorkDialog = false">Tours</button>
			<button
				[routerLink]="['/admin/notifications']"
				class="btn-menu btn-menu-primary"
				style="margin-bottom: 6px"
				(click)="showWorkDialog = false"
			>
				Notifications
			</button>
		</div>
	</div>
</p-dialog>

<!-- Begin Scheduling Dialog -->
<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Scheduling Options"
	[(visible)]="showSchedulingDialog"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div>
		<div>
			<button class="btn-menu btn-menu-primary" style="margin-top: 8px" (click)="switchToScheduleView('SERIES'); showSchedulingDialog = false">
				Schedules
			</button>
			<button class="btn-menu btn-menu-primary" (click)="switchToScheduleView('SHIFT'); showSchedulingDialog = false">Shifts</button>
			<button class="btn-menu btn-menu-primary" style="margin-bottom: 6px" (click)="switchToScheduleView('TIMEOFF'); showSchedulingDialog = false">
				Time Off
			</button>
		</div>
	</div>
</p-dialog>

<!-- Operations Dialog -->
<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Report Options"
	[(visible)]="showOperationsDialog"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div>
		<div>
			<button
				[routerLink]="['/admin/transactions']"
				class="btn-menu btn-menu-primary"
				style="margin-top: 6px"
				(click)="showOperationsDialog = false"
			>
				Time Entries
			</button>
			<button [routerLink]="['/admin/reports/employee']" class="btn-menu btn-menu-primary" (click)="showOperationsDialog = false">
				Employee Shift Reports
			</button>
			<button [routerLink]="['/admin/reports/business']" class="btn-menu btn-menu-primary" (click)="showOperationsDialog = false">
				Business Insight Reports
			</button>
			<button
				*ngIf="isAnalyticsEnabled"
				[routerLink]="['/admin/analytics']"
				class="btn-menu btn-menu-primary"
				style="margin-bottom: 6px"
				(click)="showOperationsDialog = false"
			>
				Data / Analytics
			</button>
			<div class="row" style="margin-top: 10px">
				<div class="col-6">
					<button [routerLink]="['/admin/comm-log']" class="btn-menu btn-menu-secondary" (click)="showOperationsDialog = false">Comm Log</button>
				</div>
				<div class="col-6">
					<button [routerLink]="['/admin/auditlog']" class="btn-menu btn-menu-secondary" (click)="showOperationsDialog = false">
						Audit Log
					</button>
				</div>
			</div>
			<button [routerLink]="['/admin/announcements']" class="btn-menu btn-menu-primary" (click)="showOperationsDialog = false">
				Announcements
			</button>
		</div>
	</div>
</p-dialog>

<!-- Begin Integration Dialog -->
<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Integration Options"
	[(visible)]="showIntegrationDialog"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div>
		<div>
			<button
				[routerLink]="['/admin/integrations/adp']"
				class="btn-menu btn-menu-primary"
				style="margin-top: 6px"
				(click)="showIntegrationDialog = false"
			>
				ADP
			</button>
			<button
				*ngIf="isFileImportIntegrated"
				[routerLink]="['/admin/integrations/fileimport']"
				class="btn-menu btn-menu-primary"
				(click)="showIntegrationDialog = false"
			>
				File Importer
			</button>
			<button [routerLink]="['/admin/integrations/quickbooks']" class="btn-menu btn-menu-primary" (click)="showIntegrationDialog = false">
				QuickBooks
			</button>
			<button [routerLink]="['/admin/integrations/wheniwork']" class="btn-menu btn-menu-primary" (click)="showIntegrationDialog = false">
				When I Work
			</button>
			<button
				[routerLink]="['/admin/integrations/whentowork']"
				class="btn-menu btn-menu-primary"
				style="margin-bottom: 6px"
				(click)="showIntegrationDialog = false"
			>
				WhenToWork
			</button>
		</div>
	</div>
</p-dialog>

<app-modal-dialog *ngIf="announcementsDialogManager.isDialogVisible" [dialogManager]="announcementsDialogManager" [modalHeight]="1280">
	<app-announcements-scheduled-edit
		[dialogManager]="announcementsDialogManager"
		[providedTargetEmpIds]="announcementTargetIds"
		(recordSaved)="announcementProcessed()"
	></app-announcements-scheduled-edit>
</app-modal-dialog>

<!--<button class="navbar-toggler hidden-sm-up" type="button" (click)="toggleMenu()" data-toggle="collapse" data-target="#exCollapsingNavbar2">-->
<!-- <p-growl [value]="msgs" baseZIndex="100000" sticky="true"></p-growl> -->

<app-modal-dialog *ngIf="changePasswordDialogManager.isDialogVisible" [dialogManager]="changePasswordDialogManager">
	<app-change-password [dialogManager]="changePasswordDialogManager" (updateComplete)="passwordUpdateComplete()"></app-change-password>
</app-modal-dialog>

<app-modal-dialog *ngIf="dispatchDialogManager.isDialogVisible" [dialogManager]="dispatchDialogManager"
	><app-header-dispatch-settings [dialogManager]="dispatchDialogManager"></app-header-dispatch-settings
></app-modal-dialog>
