import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ReportFieldRangeBasic } from '@app/models'

@Component({
    selector: 'app-checklist-report-item-range',
    templateUrl: './checklist-report-item-range.component.html',
    styleUrls: ['./checklist-report-item-range.component.scss'],
    standalone: false
})
export class ChecklistReportItemRangeComponent {
	@Input() item: ReportFieldRangeBasic
	@Output() toggleSkipCheckbox = new EventEmitter<ReportFieldRangeBasic>()
	@Output() textResizer = new EventEmitter<HTMLTextAreaElement>()

	public formatRangeValue(item: ReportFieldRangeBasic) {
		if (item.skip) return '-'
		if (!item.value && item.value !== 0 && !item.skip) return Math.round((item.max - item.min) / 2) + item.min
		return item.value
	}
}
