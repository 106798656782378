import { CountryCode } from 'libphonenumber-js'

export class RegistrationUser {
	username = ''
	firstName = ''
	lastName = ''
	companyName = ''
	mobile = ''
	name = ''
	email = ''
	password = ''
	confirmation = ''
	countryCode: CountryCode = 'US'
	timestamp = null
	nextRun = null
	refTag = ''

	get isValid(): boolean {
		// Check if there is a timestamp
		const parsedDate = Date.parse(this.timestamp)
		if (!parsedDate) {
			return false
		}
		const savedDate = new Date(parsedDate)

		if (savedDate) {
			const nextRun = new Date(savedDate)
			nextRun.setMinutes(nextRun.getMinutes() + 5)
			// nextRun.setUTCHours(11, 0, 0, 0);
			// if (savedDate.getTime() - nextRun.getTime() > 0) {
			// 	nextRun.setHours(nextRun.getHours() + 24);
			// }
			this.nextRun = nextRun.toISOString()
			const now = new Date().getTime()
			const difference = nextRun.getTime() - now
			return difference > 0
		}
		return false
	}
}
