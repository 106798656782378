<div style="margin-bottom: 22px">
	<app-viz-health-score-banner></app-viz-health-score-banner>
</div>

<div class="alert alert-info alert-bg-gray" style="line-height: 1.4rem; margin: 26px 0px">
	<b>Health Center</b> offers a visual overview of your health score, along with best practices for managing your organization using Telephone
	Timesheets.
	<span *ngIf="!showMoreInfo" class="link-text" (click)="showMoreInfo = !showMoreInfo">show more</span>

	<!-- Help Center Intro -->
	<div *ngIf="showMoreInfo">
		<div style="margin-top: 18px">
			This visual guide is designed to help you improve your health score, leading to smoother and more effective business operations. The metrics
			we track are based on extensive experience helping businesses streamline their processes and manage operations more efficiently. Below,
			you'll find all scoring metrics, organized to match the categories in our navigation menu. Each category corresponds to a top-level menu item
			and includes relevant sections, with detailed tracking metrics and recommendations for better system management.
		</div>
		<div style="margin-top: 18px">
			<b>Using Health Center Features</b><br />
			Click the <i class="far fa-badge-check"></i> icon above the health score progress bar to toggle the visibility of non-contributing metrics,
			allowing you to focus only on items that affect your health score. Use the <i class="fal fa-arrows-to-eye"></i> icon in expanded section
			headers to focus on a specific section and collapse all other categories and sections.
		</div>
		<div style="margin-top: 18px">
			<b>Improving Your Health Score</b><br />
			Explore each category to access the metrics for every section and identify what needs attention. Once you've made adjustments, refresh your
			score by clicking the <i class="far fa-refresh refresh-icon"></i> icon in the <b>Health Score</b> progress bar above. If you have any
			questions or would like assistance optimizing your setup, feel free to <a href="mailto:support@telephonetimesheets.com">email support</a>.
		</div>
		<div style="margin-top: 18px">
			<b>Configuring Your Health Score</b><br />
			If certain features of Telephone Timesheets aren't relevant to your business and you'd like to exclude them from your health score, navigate
			to <b>Admin > <a routerLink="/admin/settings">Settings</a></b> and select <b>Data / Analytics</b>. From there, you can configure which
			metrics are displayed and whether they should contribute to your overall health score.
		</div>
		<div *ngIf="showMoreInfo" style="margin-top: 12px">
			<span class="link-text" (click)="showMoreInfo = !showMoreInfo">hide details</span>
		</div>
	</div>

	<div class="alert-intro"></div>
</div>

<div *ngFor="let category of categories">
	<div *ngIf="visibleAlertsForCategory(category).length > 0">
		<div class="category-bar d-flex justify-content-between align-items-center" (click)="category.expanded = !category.expanded">
			<div class="d-flex justify-content-start align-items-center" [class.is-danger]="categoryHasDanger(category)">
				<span class="bar-icon-wrap"><i [class]="category.iconClass"></i></span>
				<span class="category-label">{{ category.label }}</span>
				<i
					class="far view-icon"
					[class.fa-eye]="category.expanded"
					[class.fa-eye-slash]="!category.expanded"
					[class.view-icon-collapsed]="!category.expanded"
				></i>
			</div>
			<div
				*ngIf="!category.expanded"
				class="progress category-progress"
				role="progressbar"
				aria-label="Category Progress"
				[attr.aria-valuenow]="category.score"
				aria-valuemin="0"
				aria-valuemax="100"
			>
				<div
					class="progress-bar bg-success"
					[class.progress-warning]="category.score < warningThreshold"
					[class.progress-danger]="category.score < dangerThreshold"
					[style.width]="category.score + '%'"
				></div>
				<div class="progress-score">{{ category.score }}%</div>
			</div>
		</div>

		<div *ngIf="category.expanded">
			<div *ngFor="let section of category.sections">
				<div *ngIf="visibleAlertsForSection(section).length > 0">
					<div class="section-bar d-flex justify-content-between align-items-center" (click)="section.expanded = !section.expanded">
						<div
							class="d-flex justify-content-start align-items-center"
							[class.is-danger]="!section.expanded && section.score < dangerThreshold"
						>
							<span class="bar-icon-wrap section-icon-wrap">
								<i *ngIf="section.expanded" [class]="section.iconClass"></i>
								<i *ngIf="!section.expanded" class="fad fas fa-arrow-turn-down-right"></i>
							</span>
							<span class="section-label">{{ section.label }}</span>
							<i
								class="far view-icon"
								[class.fa-eye]="section.expanded"
								[class.fa-eye-slash]="!section.expanded"
								[class.view-icon-collapsed]="!section.expanded"
							></i>
						</div>
						<i
							*ngIf="section.expanded"
							class="fal fa-arrows-to-eye focus-icon link-text"
							(click)="focusSection($event, section)"
							[pTooltip]="isDesktop ? 'Collapse other sections' : null"
							tooltipPosition="left"
							tooltipStyleClass="small-tooltip"
						></i>
						<div
							*ngIf="!section.expanded"
							class="progress section-progress float-right"
							role="progressbar"
							aria-label="Section Progress"
							[attr.aria-valuenow]="section.score"
							aria-valuemin="0"
							aria-valuemax="100"
						>
							<div
								class="progress-bar bg-success"
								[class.progress-warning]="section.score < warningThreshold"
								[class.progress-danger]="section.score < dangerThreshold"
								[style.width]="section.score + '%'"
							></div>
							<div class="progress-score">{{ section.score }}%</div>
						</div>
					</div>

					<div *ngIf="section.expanded">
						<div *ngFor="let alert of visibleAlertsForSection(section)">
							<div
								class="category-alert-wrap"
								[class.alert-is-contributor]="alert.contributor"
								[class.alert-is-danger]="alert.contributor && alert.score < dangerThreshold"
							>
								<div
									class="progress alert-progress float-right"
									role="progressbar"
									aria-label="Alert Progress"
									[attr.aria-valuenow]="alert.score"
									aria-valuemin="0"
									aria-valuemax="100"
								>
									<div
										class="progress-bar bg-success"
										[class.progress-warning]="alert.score < warningThreshold"
										[class.progress-danger]="alert.score < dangerThreshold"
										[style.width]="alert.score + '%'"
									></div>
									<div class="progress-score">{{ alert.score }}%</div>
								</div>
								<div>
									<div class="alert-label" [class.is-danger]="alert.contributor && alert.score < dangerThreshold">{{ alert.label }}</div>
									<div class="alert-description-wrap">
										<span class="alert-description" [innerHTML]="alert.description | sanitizer"></span>
									</div>

									<div *ngIf="alert.tips" class="alert-tips-wrap">
										<span class="alert-tip-label tts-hl-2">Tips: </span>
										<div class="alert-tip-list" *ngFor="let tip of alert.tips; let idx = index">
											<span class="link-text" (click)="handleAlertTip(tip)">{{ tip.header }}</span>
											<span *ngIf="idx < alert.tips.length - 1" style="margin: 0 4px">/</span>
										</div>
									</div>

									<div *ngIf="alert.links" class="alert-links-wrap">
										<span class="alert-link-label tts-hl-2">Links: </span>
										<div class="alert-link-list" *ngFor="let link of alert.links; let idx = index">
											<span class="link-text" (click)="handleAlertLink(link)">{{ link.label }}</span>
											<span *ngIf="idx < alert.links.length - 1" style="margin: 0 4px">/</span>
										</div>
									</div>

									<div class="alert-contributor-label">
										<div class="contributor-icon-wrap">
											<i *ngIf="alert.contributor" class="far fa-badge-check"></i>
											<i *ngIf="!alert.contributor" class="far fa-ban"></i>
										</div>
										<span>
											{{ alert.contributor ? 'Contributes to health score.' : 'Does not contribute to health score.' }}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- <div class="pre-code">{{ sections | json }}</div> -->
