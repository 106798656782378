<div class="section-table">
	<div *ngIf="showNoPersonalReportsNotice" class="alert alert-info alert-slim all-reports-banner">
		No personal or shared reports found. To see the full list, select <strong>All Reports</strong>.
	</div>
	<table id="reportsHistoryTable" class="stripe nowrap" cellspacing="0" width="100%"></table>
	<i
		id="clear-search-icon"
		(click)="clearSearch()"
		class="fa fa-times-circle-o clear-search-icon rep-history-clear-search-icon"
		aria-hidden="true"
	></i>
</div>
<p-dialog header="Empty Report" [modal]="false" [style]="{ width: '300px' }" [(visible)]="showEmptyReportDialog" [dismissableMask]="true">
	There are no transactions in the date range of this report.
</p-dialog>
<app-delete-record-dialog
	*ngIf="deleteAction.showDeleteRecordDialog"
	[showDeleteRecordDialog]="deleteAction.showDeleteRecordDialog"
	[tableName]="deleteAction.tableName"
	[recordId]="deleteAction.recordId"
	[recordLabel]="deleteAction.recordLabel"
	(cancelDelete)="deleteAction.showDeleteRecordDialog = false"
	(actionComplete)="deleteActionComplete()"
></app-delete-record-dialog>

<p-dialog
	styleClass="p-dialog-noheader"
	[(visible)]="isRunningReport"
	[modal]="false"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[baseZIndex]="5000"
	[dismissableMask]="true"
	[transitionOptions]="'0ms'"
>
	<div class="tts-default-font" style="text-align: center; color: #566978; font-weight: bold">
		<i class="fas fa-loader fa-spin"></i> Running Report...
	</div>
</p-dialog>
