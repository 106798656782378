import { Injectable, EventEmitter } from '@angular/core'
import { StationRecord, StationViewManager } from '@app/models'

import { log } from '@app/helpers'
import _ from 'lodash'
import { SelectItem } from 'primeng/api/selectitem'
import { Global } from '@app/models/global'
import { environment } from '@env/environment'

@Injectable({
	providedIn: 'root',
})
export class StationService {
	dataLoaded = false
	list: Array<StationRecord> = [] // stationListMock.map((s) => new StationRecord(s))
	listChange = new EventEmitter<Array<StationRecord>>()
	lastUpdated: Date

	viewManager = new StationViewManager()

	constructor() {
		log('Creating StationService')
	}

	clearData() {
		this.list = []
		this.dataLoaded = false
		this.lastUpdated = null
	}

	getStationDropdownData(): Array<SelectItem> {
		return this.getStations().map((station) => ({ label: station.description, value: station.id, data: station }))
	}

	getStations(): Array<StationRecord> {
		const result = _.sortBy(this.list, 'name')
		return result
	}

	getStationById(id: number) {
		return this.list.find((rec) => rec.id === id)
	}

	removeLocalStationRecord(recordId: number) {
		this.list = this.list.filter((rec) => rec.id !== recordId)
	}

	setStationRecords(records: Array<StationRecord>) {
		this.lastUpdated = new Date()
		this.list = records.map((rec) => new StationRecord(rec))
		this.dataLoaded = true
		this.listChange.next(this.list)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateStationRecords(records: Array<StationRecord>) {
		const newRecords = records.map((rec) => new StationRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.list.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.list.push(newRecord)
			}
		}
		this.lastUpdated = new Date()
		this.listChange.next(this.list)
	}

	public copySetupCode(id: number, setupCode: string, description: string) {
		const customDomain = Global.coreSrvc.dbSrvc.settingSrvc.getCompany()?.emp_app_domain
		const empAppDdomain = customDomain ?? environment.empAppDomain
		const domain = window.location.origin.includes('localhost') ? 'http://localhost:4500' : 'https://' + empAppDdomain

		const url = `${domain}/#/station/setup?code=${setupCode}`
		if (setupCode) {
			navigator.clipboard.writeText(url)
			Global.coreSrvc.notifySrvc.notify('success', 'Link Copied', `This station's setup link has been copied to your clipboard.`, 5)
			Global.coreSrvc.eventSrvc.sendGenericTrackEvent('STATIONLINKACCESS', 'station', id, {
				setupCode: setupCode,
				stationName: description,
			})
		}
	}
}

const stationListMock: Array<any> = [
	{ id: 1, description: 'Station 1', employee_ids: [21615, 24481], job_ids: [12319, 12204] },
	{ id: 2, description: 'Station 2', employee_ids: [26758], job_ids: [12327] },
]
