<div style="margin: auto">
	<div *ngIf="!trans">
		<div class="new-trans-note">Break times cannot be edited until a new time entry has been saved.</div>
		<div style="margin-top: 25px; text-align: center"><button class="btn btn-sm btn-primary" (click)="doneBtnClicked()">Done</button></div>
	</div>

	<div *ngIf="trans">
		<div *ngIf="isInline">
			<div class="title">
				Edit Breaktimes <span class="float-right"><button class="btn btn-sm btn-primary" (click)="doneBtnClicked()">Done</button></span>
			</div>
			<!-- <div class="emp-name">{{employeeName}}</div>
			<hr>
			<div class="job-name">{{jobName}}</div>
			<div class="job-start-label">Shift Started:</div>
			<div class="job-start-date">{{transDateShort}}</div> -->
			<hr />
		</div>

		<div *ngIf="!dataLoaded">
			<div class="updating-note"><i class="fas fa-loader fa-spin"></i> Processing...</div>
		</div>

		<div *ngIf="false" class="job-date">
			{{ transDate }}
		</div>

		<div *ngIf="dataLoaded">
			<div *ngIf="isExported" style="margin: 5px 0px 20px 0px">
				<div class="alert alert-warning">This time entry has been exported and therefore breaks may no longer be modified.</div>
			</div>

			<!-- Main break lists -->
			<div [hidden]="showAddBreakInput || showEditBreakInput">
				<div *ngIf="adminBreaks.length > 0">
					<div class="break-list">
						<div *ngFor="let breakTime of adminBreaks" style="margin-bottom: 6px">
							<div class="card">
								<div class="card-body">
									<div *ngIf="!breakTime.break_time_worked" class="float-right">
										<i
											title="Edit"
											style="cursor: pointer; margin-right: 15px"
											(click)="editBreakTimeIconSelected(breakTime)"
											class="action-icon-edit fa fa-pencil-square-o"
											[class.icon-disabled]="isExported"
										></i>
										<i
											title="Delete"
											style="cursor: pointer"
											(click)="deleteBreakTime(breakTime)"
											class="action-icon-delete fa fa-trash-o"
											[class.icon-disabled]="isExported"
										></i>
									</div>
									<div>Unpaid Auto-break &#64; {{ displayTime(breakTime.actual_start) }}</div>
									<div style="font-weight: 600">
										Duration: <span>{{ displayDuration(breakTime) }}</span>
									</div>
									<div class="set-by-label">Set by {{ breakTime.getSourceTypeDescription() }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div *ngIf="userBreaks.length > 0">
					<div class="break-list">
						<div *ngFor="let breakTime of userBreaks" style="margin-bottom: 12px">
							<div class="card">
								<div class="card-body">
									<div *ngIf="!breakTime.break_time_worked" class="float-right">
										<i
											title="Edit"
											style="cursor: pointer; margin-right: 15px"
											(click)="editBreakTimeIconSelected(breakTime)"
											class="action-icon-edit fa fa-pencil-square-o"
											[class.icon-disabled]="isExported"
										></i>
										<i
											title="Delete"
											style="cursor: pointer"
											(click)="deleteBtnClicked(breakTime)"
											class="action-icon-delete fa fa-trash-o"
											[class.icon-disabled]="isExported"
										></i>
									</div>
									<div class="tts-hl-2" style="font-weight: 600">
										{{ breakTime.getBreakTypeDescription() }}
										{{ breakTime.source === 'ADMIN' ? ' / Admin Created' : ' @ ' + displayTime(breakTime.actual_start) }}
									</div>
									<div style="font-weight: 600">
										Duration: <span [class.brk-ongoing]="displayDuration(breakTime) === 'ongoing'">{{ displayDuration(breakTime) }}</span>
									</div>
									<div class="set-by-label">
										<span *ngIf="breakTime.post_break_incident_log_id" class="float-right" style="color: green">Has Checklist</span>
										<span>Set by {{ breakTime.getSourceTypeDescription() }}</span>
									</div>
									<div *ngIf="selectedBreakForDelete === breakTime">
										<hr />
										<div class="d-flex justify-content-between">
											<div *ngIf="!isDeleting" class="confirm-delete-label">Confirm Delete</div>
											<div *ngIf="isDeleting" class="confirm-delete-label" style="color: gray">
												<i class="fa fa-floader fa-spin"></i> Deleting ...
											</div>
											<div *ngIf="!isDeleting">
												<button
													class="btn btn-sm btn-outline-secondary"
													style="margin-right: 12px"
													(click)="selectedBreakForDelete = null"
												>
													Cancel
												</button>
												<button class="btn btn-sm btn-outline-danger" (click)="deleteBreakTime(breakTime)">Delete</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div style="margin-top: 20px">
					<div *ngIf="showPaidBreakInfo">
						<div class="total-time">
							Paid Breaks: <span class="float-right">{{ displayTotalTime('PAID_BREAK') }} min</span>
						</div>
						<div class="total-time">
							Unpaid Breaks: <span class="float-right">{{ displayTotalTime('UNPAID_BREAK') }} min</span>
						</div>
					</div>
					<div class="total-time">
						Total Break Time: <span class="float-right">{{ displayTotalTime('ANY') }} min</span>
					</div>
				</div>

				<div *ngIf="!showAddBreakInput && !showEditBreakInput && !isExported" class="add-break-btn">
					<button class="btn btn-sm btn-success" (click)="addBreakTimeBtnClicked()">Add Break</button>
				</div>
			</div>

			<div *ngIf="showAddBreakInput" class="break-box">
				<div class="options-section-header">Add Break</div>

				<div *ngIf="showPaidBreakInfo" style="margin-top: 20px">
					<div class="options-title">Select Break Type</div>
					<p-select appScrollLockSelect id="breakType" [options]="breakTypeOptions" [(ngModel)]="selectedBreakType" filterBy="label"></p-select>
				</div>

				<div class="options-title" style="margin-top: 20px">Enter break time in minutes</div>
				<div class="box-h-item box-item-1-margin">
					<input
						#addInput
						id="addInput"
						class="form-control"
						style="width: 50px"
						type="tel"
						autocomplete="off"
						[(ngModel)]="addInputValue"
						(keyup.enter)="addBreakTime(addInput)"
					/>
				</div>
				<div class="box-h-item box-item-2-margin">
					<button type="button" class="btn btn-sm btn-success" (click)="addBreakTime(addInput)">Add</button>
				</div>
				<div class="box-h-item"><button type="button" class="btn btn-sm btn-secondary" (click)="cancelAddBreakTime()">Cancel</button></div>
			</div>

			<div *ngIf="showEditBreakInput" class="break-box">
				<div class="options-section-header">Edit Break</div>

				<div *ngIf="showPaidBreakInfo" style="margin-top: 20px">
					<div class="options-title">Select Break Type</div>
					<p-select appScrollLockSelect id="breakType" [options]="breakTypeOptions" [(ngModel)]="selectedBreakType" filterBy="label"></p-select>
				</div>

				<div class="options-title" style="margin-top: 20px">Enter break time in minutes</div>
				<div class="box-h-item box-item-1-margin">
					<input
						#editInput
						id="editInput"
						class="form-control"
						style="width: 50px"
						type="tel"
						autocomplete="off"
						[(ngModel)]="selectedBreakTimeValue"
						(keyup.enter)="updateBreakTime()"
					/>
				</div>
				<div class="box-h-item box-item-2-margin">
					<button type="button" class="btn btn-sm btn-success" (click)="updateBreakTime()">Update</button>
				</div>
				<div class="box-h-item"><button type="button" class="btn btn-sm btn-secondary" (click)="cancelEditBreakTime()">Cancel</button></div>
			</div>
		</div>
	</div>
</div>

<!-- <div>Edit Input Value: {{ selectedBreakTimeValue }}</div> -->
<p-dialog
	header="Date Locked"
	[(visible)]="dateLockOverride.showDialog"
	[closable]="false"
	[modal]="false"
	[style]="{ width: '300px' }"
	[dismissableMask]="true"
>
	<div class="tts-info-msg">
		<span
			>This time entry has been date locked. Click <strong>Confirm</strong> to allow this record to be modified. Supervisors will be notified of
			the change.</span
		>
	</div>
	<div style="margin-top: 18px">
		<button class="btn btn-sm btn-success float-right" (click)="submitDateLockOverride()">Confirm</button>
		<button class="btn btn-sm btn-secondary" (click)="cancelDateLockOverride()">Cancel</button>
	</div>
</p-dialog>

<!-- <p-dialog header="Update Break" [(visible)]="showUpdateBreakTimeDialog" [modal]="false" [style]="{'width':'300px'}" [dismissableMask]="true">
	<strong>Set length of break</strong>
	<br>
	<input #updateInput style="width: 100px" class="form-control" (keyup.enter)="updateBreakTime()" [(ngModel)]="selectedBreakTimeValue" type="tel" min="1" max="90" />
	<small class=" text-muted ">(in minutes)</small>
	<br>
	<br>
	<a (click)="updateBreakTime() " class="btn btn-sm btn-success " style="color: white ">Update</a>
</p-dialog> -->
