<div class="container">
	<div class="card adp-card">
		<strong>ADP Integration</strong>
		<hr />
		<div *ngIf="!isDataLoaded" class="loading-block"><i class="fas fa-loader fa-spin"></i> Loading Data ...</div>
		<div *ngIf="!isIntegrated && isDataLoaded">
			<!-- <div style="font-weight: bold;">To integrate with ADP</div> -->
			<div style="margin-top: 12px; line-height: 1.3em">
				<div *ngIf="!companyUuidCopied">
					This is your unique code for connecting to ADP. Click the green button below to copy the code to your clipboard and begin the linking
					process.
				</div>
				<div *ngIf="companyUuidCopied && !adpMarketplaceVisited">
					Now choose a product below to go to the ADP marketplace and purchase the ADP Connector. When asked for the linking identifier, paste
					the indentifier (show below) that has been copied to your clipboard. When you have finished purchasing the connector, return to this
					page to finish integration with Telephone Timesheets.
				</div>
				<div *ngIf="adpMarketplaceVisited">
					Now click the button below to finish integrating with Telephone Timesheets. You will be re-directed to the integration consent form at
					ADP. Once you have submitted the consent form, you will be re-directed back to Telephone Timesheets and your integration will be
					complete.
				</div>
			</div>
			<div style="margin-top: 12px">
				<div *ngIf="companyUuidCopied && !adpMarketplaceVisited" style="text-align: center">
					<!-- <button class="btn btn-sm btn-block btn-primary" (click)="gotoAdpMarketplace('RUN')">RUN Powered by ADP</button> -->

					<div class="row">
						<div class="col-md-6 col-sm-12">
							<button class="btn btn-sm btn-block btn-primary" style="margin-top: 15px" (click)="gotoAdpMarketplace('RUN')">
								RUN Powered by ADP
							</button>
						</div>
						<div class="col-md-6 col-sm-12">
							<button class="btn btn-sm btn-block btn-primary" style="margin-top: 15px" (click)="gotoAdpMarketplace('WFN')">
								ADP Workforce Now
							</button>
						</div>
					</div>
				</div>
				<div *ngIf="adpMarketplaceVisited" style="text-align: center; margin-top: 25px">
					<button class="btn btn-sm btn-block btn-secondary" (click)="finishIntegrationBtnClicked()">Finish Integration</button>
				</div>

				<div style="text-align: center">
					<div class="row">
						<div class="col-xs-12 col-md-10">
							<input
								readonly
								type="text"
								class="form-control uuid-input spanner-tm"
								style="text-align: center"
								[ngModel]="companyUuid"
								id="companyUuid"
							/>
						</div>
						<div class="col-xs-12 col-md-2">
							<button class="btn btn-sm btn-success btn-block uuid-copy-btn spanner-tm" (click)="copyUuid()">
								<i class="fa fa-clipboard"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="isIntegrated && isDataLoaded">
			<!-- <code><pre>{{ adpSync | json }}</pre></code>
			<hr> -->

			<!-- Employee Sync -->

			<div>
				<div class="row">
					<div class="col-md-6 col-sm-12 sync-label">
						<button class="btn btn-sm btn-success int-btn" (click)="importEmployeeData()">
							<i *ngIf="!isWorking.empImport" class="fa fa-cloud-download" aria-hidden="true"></i>
							<i *ngIf="isWorking.empImport" class="fas fa-loader fa-spin" aria-hidden="true"></i>
							Import Employee Data
						</button>
						<!-- <div class="tts-hl-2" style="height: 50px">Employee Data</div> -->
					</div>
					<div class="col-md-6 col-sm-12 sync-label">
						<i class="far fa-info-circle tts-info-btn sync-info-btn" (click)="vm.showEmpTooltip = !vm.showEmpTooltip"></i>
						<span>Last import on:</span>
						<div style="font-size: 0.8em">{{ vm.empSyncDate }}</div>
					</div>
				</div>
				<div style="margin-top: 20px">
					<button
						*ngIf="vm.empSyncErrorCount > 0"
						class="btn btn-block btn-sm btn-danger btn-qb-log"
						[routerLink]="['/admin/integrations/log/adp/Employee']"
					>
						View Log ( {{ vm.empSyncErrorCount }} Error{{ vm.empSyncErrorCount > 1 ? 's' : '' }} )
					</button>
					<button
						*ngIf="vm.empSyncErrorCount === 0"
						class="btn btn-block btn-sm btn-secondary btn-qb-log"
						[routerLink]="['/admin/integrations/log/adp/Employee']"
					>
						View Log ( No Errors )
					</button>
				</div>
				<div class="row" *ngIf="vm.showEmpTooltip">
					<div class="col-12 int-tooltip-block text-muted">
						Click the Import Employee Data button to perform an initial import of your employees, and to import new or modified employees from
						the master list you keep in ADP.
					</div>
				</div>
				<hr class="int-log-divider" />
			</div>

			<!-- Time Entry Sync -->

			<div>
				<div class="row">
					<div class="col-md-6 col-sm-12 sync-label">
						<button class="btn btn-sm btn-success int-btn" [disabled]="!selectedPayCycle" (click)="exportTransactionData()">
							<i *ngIf="!isWorking.transExport" class="fa fa-cloud-download" aria-hidden="true"></i>
							<i *ngIf="isWorking.transExport" class="fas fa-loader fa-spin" aria-hidden="true"></i>
							Export Time Entry Data
						</button>
						<!-- <div class="tts-hl-2" style="height: 50px">Transaction Data</div> -->
					</div>
					<div class="col-md-6 col-sm-12 sync-label">
						<i class="far fa-info-circle tts-info-btn sync-info-btn" (click)="vm.showTransTooltip = !vm.showTransTooltip"></i>
						<span>Last export on:</span>
						<div style="font-size: 0.8em">{{ vm.transSyncDate ? vm.transSyncDate : 'Awaiting initial export' }}</div>
					</div>
				</div>
				<div *ngIf="!vm.transSyncDate" class="alert alert-warning" style="margin: 15px 0px 20px 0px">
					Please make sure your payroll settings have been properly configured and any changes saved in the
					<strong class="tts-hl-2">Payroll Earnings and Rates</strong> section below.
				</div>
				<div style="margin-top: 10px">
					<p-select
						appScrollLockSelect
						[ttsCenterOnShow]="isMobile"
						[options]="payCycles"
						[(ngModel)]="selectedPayCycle"
						placeholder="Select Pay Cycle"
						[filter]="true"
						filterBy="label"
						[resetFilterOnHide]="true"
						(onChange)="payCycleDropdownChanged()"
					></p-select>
				</div>
				<div style="margin-top: 20px">
					<button
						*ngIf="vm.transSyncErrorCount > 0"
						class="btn btn-block btn-sm btn-danger btn-qb-log"
						[routerLink]="['/admin/integrations/log/adp/TransactionLog']"
					>
						View Log ( {{ vm.transSyncErrorCount }} Error{{ vm.transSyncErrorCount > 1 ? 's' : '' }} )
					</button>
					<button
						*ngIf="vm.transSyncErrorCount === 0"
						class="btn btn-block btn-sm btn-secondary btn-qb-log"
						[routerLink]="['/admin/integrations/log/adp/TransactionLog']"
					>
						View Log ( No Errors )
					</button>
				</div>
				<div class="row" *ngIf="vm.showTransTooltip">
					<div class="col-12 int-tooltip-block text-muted">
						Use the Export Transaction Data button to export timesheet entries that have not already been exported to ADP. Once a time entry is
						exported, it cannot be edited. Any changes must be made in ADP, so be sure to validate all
						<a [routerLink]="['/admin/transactions']">time entries</a> before performing an export.
					</div>
				</div>
				<hr class="int-log-divider" />
			</div>

			<div class="ear-header tts-hl-2">Payroll Earnings and Rates</div>

			<div class="row">
				<div class="col-md-6 col-sm-12">
					<div class="payroll-block" [class.input-required]="!selectedPayrollReg">
						<div *ngIf="!selectedPayrollReg" class="float-right">required</div>
						<div *ngIf="selectedPayrollReg" class="clear-link" (click)="selectedPayrollReg = null; payrollDropdownChanged()">clear</div>
						<div class="options-title">Regular</div>
						<p-select
							appScrollLockSelect
							[ttsCenterOnShow]="isMobile"
							[disabled]="!canSetDropdown('payroll_reg')"
							[options]="earningsCodes"
							[(ngModel)]="selectedPayrollReg"
							[filter]="true"
							filterBy="label"
							[resetFilterOnHide]="true"
							(onChange)="payrollDropdownChanged()"
						></p-select>
					</div>
				</div>
				<div class="col-md-6 col-sm-12">
					<div class="payroll-block">
						<div *ngIf="selectedPayrollOt" class="clear-link" (click)="selectedPayrollOt = null; payrollDropdownChanged()">clear</div>
						<div class="options-title">Overtime</div>
						<p-select
							appScrollLockSelect
							[ttsCenterOnShow]="isMobile"
							[disabled]="!canSetDropdown('payroll_ot')"
							[options]="earningsCodes"
							[(ngModel)]="selectedPayrollOt"
							[filter]="true"
							filterBy="label"
							[resetFilterOnHide]="true"
							(onChange)="payrollDropdownChanged()"
						></p-select>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6 col-sm-12">
					<div class="payroll-block">
						<div *ngIf="selectedPayrollHol" class="clear-link" (click)="selectedPayrollHol = null; payrollDropdownChanged()">clear</div>
						<div class="options-title">Holiday</div>
						<p-select
							appScrollLockSelect
							[ttsCenterOnShow]="isMobile"
							[disabled]="!canSetDropdown('payroll_hol')"
							[options]="earningsCodes"
							[(ngModel)]="selectedPayrollHol"
							[filter]="true"
							filterBy="label"
							[resetFilterOnHide]="true"
							(onChange)="payrollDropdownChanged()"
						></p-select>
					</div>
				</div>
				<div class="col-md-6 col-sm-12">
					<div class="payroll-block">
						<div *ngIf="selectedPayrollOtHol" class="clear-link" (click)="selectedPayrollOtHol = null; payrollDropdownChanged()">clear</div>
						<div class="options-title">Overtime + Holiday</div>
						<p-select
							appScrollLockSelect
							[ttsCenterOnShow]="isMobile"
							[disabled]="!canSetDropdown('payroll_othol')"
							[options]="earningsCodes"
							[(ngModel)]="selectedPayrollOtHol"
							[filter]="true"
							filterBy="label"
							[resetFilterOnHide]="true"
							(onChange)="payrollDropdownChanged()"
						></p-select>
					</div>
				</div>
			</div>

			<!-- Pay Rate / Time Off type mapper -->
			<hr />
			<div class="ear-header tts-hl-2">Paid Time-Off Mappings</div>

			<div>
				<div class="row">
					<div class="col-md-6 col-sm-12 timeoff-block">
						<div *ngIf="selectedPtoPersonal" class="clear-link" (click)="selectedPtoPersonal = null">clear</div>
						<div class="options-title">Personal</div>
						<p-select
							appScrollLockSelect
							[ttsCenterOnShow]="isMobile"
							[options]="earningsCodes"
							[(ngModel)]="selectedPtoPersonal"
							[filter]="true"
							filterBy="label"
							[resetFilterOnHide]="true"
						>
						</p-select>
					</div>
					<div class="col-md-6 col-sm-12 timeoff-block">
						<div *ngIf="selectedPtoSick" class="clear-link" (click)="selectedPtoSick = null">clear</div>
						<div class="options-title">Sick Time</div>
						<p-select
							appScrollLockSelect
							[ttsCenterOnShow]="isMobile"
							[options]="earningsCodes"
							[(ngModel)]="selectedPtoSick"
							[filter]="true"
							filterBy="label"
							[resetFilterOnHide]="true"
						>
						</p-select>
					</div>
				</div>

				<div class="row">
					<div class="col-md-6 col-sm-12 timeoff-block">
						<div *ngIf="selectedPtoHoliday" class="clear-link" (click)="selectedPtoHoliday = null">clear</div>
						<div class="options-title">Holiday</div>
						<p-select
							appScrollLockSelect
							[ttsCenterOnShow]="isMobile"
							[options]="earningsCodes"
							[(ngModel)]="selectedPtoHoliday"
							[filter]="true"
							filterBy="label"
							[resetFilterOnHide]="true"
						>
						</p-select>
					</div>
					<div class="col-md-6 col-sm-12 timeoff-block">
						<div *ngIf="selectedPtoBereavement" class="clear-link" (click)="selectedPtoBereavement = null">clear</div>
						<div class="options-title">Bereavement</div>
						<p-select
							appScrollLockSelect
							[ttsCenterOnShow]="isMobile"
							[options]="earningsCodes"
							[(ngModel)]="selectedPtoBereavement"
							[filter]="true"
							filterBy="label"
							[resetFilterOnHide]="true"
						>
						</p-select>
					</div>
				</div>
			</div>
			<!-- Pay Rate / Time Off type mapper -->

			<div *ngIf="vm.isWfnIntegrated">
				<hr />
				<div class="ear-header tts-hl-2">Workforce Now Settings</div>
				<div>
					<div class="row">
						<div class="col-md-6 col-sm-12">
							<div class="payroll-block">
								<div class="options-title">Frequency</div>
								<p-select
									appScrollLockSelect
									[ttsCenterOnShow]="isMobile"
									[options]="wfnFrequencyOptions"
									[(ngModel)]="vm.payrollPeriod"
									[filter]="false"
									[resetFilterOnHide]="true"
								></p-select>
							</div>
						</div>
						<div class="col-md-6 col-sm-12">
							<div class="payroll-block">
								<div>
									<div *ngIf="vm.payrollStartDate" class="clear-link float-right" (click)="vm.payrollStartDate = null; (false)">clear</div>
									<div class="options-title">Start Date</div>
								</div>
								<app-date-time-picker
									[currentDate]="vm.payrollStartDate"
									[pickerType]="'DATE'"
									[pickerMode]="'dialog'"
									[pickerLabel]="'Start Date'"
									[hour12Timer]="true"
									[format]="'MMM Do, YYYY'"
									(pickerDidClose)="vm.payrollStartDate = $event"
								>
								</app-date-time-picker>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-sm-12">
						<div class="options-block" [class.input-required]="!vm.payrollGroupCode">
							<div *ngIf="!vm.payrollGroupCode" class="float-right">required</div>
							<div class="options-title">Payroll Group Code</div>
							<!-- <input class="form-control" [(ngModel)]="vm.payrollGroupCode" /> -->
							<p-select
								appScrollLockSelect
								[ttsCenterOnShow]="isMobile"
								[options]="payrollGroupCodes"
								[(ngModel)]="vm.payrollGroupCode"
								[filter]="false"
								[resetFilterOnHide]="true"
							></p-select>
						</div>
					</div>
				</div>
			</div>

			<div style="margin-top: 15px; width: 200px">
				<button [disabled]="isUpdateBtnDisabled()" class="btn btn-sm btn-success btn-block" (click)="updateBtnClicked()">Update Settings</button>
			</div>
		</div>
	</div>
</div>
<div *ngIf="isIntegrated" style="margin-top: 4px; font-size: 0.9rem; color: gray; text-align: center">
	To disable ADP integration, please contact <a href="mailto:support@telephonetimesheets.com">support</a>.
</div>

<div *ngIf="false">
	<pre><code>{{ vm | json }}</code></pre>
</div>
