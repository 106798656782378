<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating || currentCard">
	<form class="edit-form-block" novalidate [formGroup]="form" autocomplete="off">
		<!-- Descriptions -->
		<div class="options-block" [class.input-required]="!form.get('description').value">
			<div class="options-title-inline">Profile Description</div>
			<div *ngIf="!form.get('description').value" class="float-right">(required)</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="description" />
		</div>

		<!-- Linked Jobs -->
		<div class="options-block">
			<div class="options-title">Linked Jobs</div>
			<app-multi-select-details
				[type]="'JOB'"
				placeholder="Select Jobs"
				selectedItemsLabel="Jobs Selected: {0}"
				[options]="linkedJobOptions"
				[model]="form.get('job_ids').value"
			>
			</app-multi-select-details>
		</div>

		<div class="no-records-card" *ngIf="cards.length === 0">No Breaks Defined</div>

		<div *ngIf="cards.length > 0" class="options-section-header" style="margin: 12px 0px">Defined Breaks</div>

		<!-- Card list -->
		<div *ngFor="let card of cards; let idx = index">
			<div class="card">
				<div class="card-body">
					<div class="icon-wrap float-right">
						<i class="far fa-edit act-ico act-ico-clr-green act-ico-dark-hover" (click)="editCard(card)"></i>
						<i class="far fa-trash-alt act-ico act-ico-clr-red act-ico-dark-hover" (click)="deleteCard(card)"></i>
						<span *ngIf="cards.length > 1">
							<i
								class="fas fa-arrow-up act-ico act-ico-dark-hover"
								[class.action-icon-disabled]="idx === 0"
								(click)="moveCard('UP', card)"
							></i>
							<i
								class="fas fa-arrow-down act-ico act-ico-dark-hover"
								[class.action-icon-disabled]="idx === cards.length - 1"
								(click)="moveCard('DOWN', card)"
							></i>
						</span>
					</div>
					<div class="brk-desc tts-hl-2" [class.tts-hl-orange]="!card.rule.description">
						{{ card.rule.description || noDescriptionPlaceholder }}
					</div>

					<hr style="margin: 12px 0px 14px 0px" />
					<div [innerHTML]="card.cardSummary | sanitizer"></div>
					<div class="brk-managed-label tts-hl-2" [class.tts-hl-gold]="card.rule.managedByType === 'EMPLOYEE'">
						{{ card.rule.manageTypeLabel }}
					</div>

					<!-- Delete Confirmation -->
					<div *ngIf="card.confirmDelete" class="delete-confirm-block">
						<hr class="delete-confirm-hr" />
						<div class="float-right">
							<button class="btn btn-sm btn-outline-secondary" style="margin-right: 12px" (click)="card.confirmDelete = false">Cancel</button>
							<button class="btn btn-sm btn-outline-danger" (click)="confirmDelete(card)" style="width: 60px">Delete</button>
						</div>
						<div class="delete-confirm-label">Confirm Delete</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Add new rule -->
		<button class="btn btn-sm btn-block btn-outline-success" style="margin: 12px 0px" (click)="addRecord()">
			Add {{ cards.length > 0 ? 'Another' : 'a' }} Break
		</button>
	</form>
</div>

<div *ngIf="currentCard">
	<app-break-rule-edit [card]="currentCard"></app-break-rule-edit>
</div>
