import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ChecklistFormConfigInputBasic, ChecklistFormItem } from '@app/models'

@Component({
    selector: 'app-checklist-form-item-input',
    templateUrl: './checklist-form-item-input.component.html',
    styleUrls: ['./checklist-form-item-input.component.scss'],
    standalone: false
})
export class ChecklistFormItemInputComponent {
	@Input() item: ChecklistFormItem

	@Output() textResizer = new EventEmitter<HTMLTextAreaElement>()
	@Output() clearError = new EventEmitter<ChecklistFormItem>()
	@Output() showHelp = new EventEmitter<string>()

	get config(): ChecklistFormConfigInputBasic {
		return this.item?.config as ChecklistFormConfigInputBasic
	}
}
