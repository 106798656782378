import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AccessHelper } from '@app/helpers/access'
import { log } from '@app/helpers/logger'
import { EmployeeRecord, IScheduleOptionsDayOption, ScheduleAssignmentManager, ScheduleOptions, SelectorPermissionName } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { SelectItem } from 'primeng/api'

import _ from 'lodash'
import { DeviceDetectorService } from 'ngx-device-detector/public-api'

@Component({
    selector: 'app-scheduler-employee-assignment',
    templateUrl: './scheduler-employee-assignment.component.html',
    styleUrls: ['./scheduler-employee-assignment.component.scss'],
    standalone: false
})
export class SchedulerEmployeeAssignmentComponent implements OnInit {
	employeeOptions: SelectItem[]

	dayOptions = ScheduleOptions.dayOptions
	selectedDayOption = ScheduleOptions.dayOptions[0]

	wkst: number

	@Input() manager: ScheduleAssignmentManager
	@Output() showHelp = new EventEmitter<string>()
	@Output() enableWeekday = new EventEmitter<IScheduleOptionsDayOption>()
	@Output() disableWeekday = new EventEmitter<IScheduleOptionsDayOption>()

	defaultAction = { showDialog: false, selectedEmployee: null }
	dupAction = { showWidget: false, selectedDays: {} }

	constructor(private coreSrvc: CoreService) {
		this.arrangeDayOptionsForWkst()
	}

	arrangeDayOptionsForWkst() {
		const dayOptions = [...ScheduleOptions.dayOptions]
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const wkst = company.wkst || 0
		if (!wkst || wkst < 0 || wkst > 6) {
			this.dayOptions = dayOptions
			return
		}
		const comps = dayOptions.splice(wkst)
		const newDayOptions = [...comps, ...dayOptions]
		this.dayOptions = newDayOptions
		this.selectedDayOption = this.dayOptions[0]
	}

	ngOnInit(): void {
		this.setupEmployeeOptions()
		this.manager.toggleWeekday = (day: IScheduleOptionsDayOption) => (this.selectedDayOption = day)
	}

	get devDetect(): DeviceDetectorService {
		return this.coreSrvc.devDetect
	}

	get isDayAssignmentValid(): boolean {
		const isDayScheduled = this.isDayScheduled(this.selectedDayOption)
		const areEmployeesAssigned = this.areEmployeesAssignedForSelectedDay()
		if (isDayScheduled && areEmployeesAssigned) {
			return true
		}
		return false
	}

	get assignmentListDayStyle(): Object {
		const isDayScheduled = this.isDayScheduled(this.selectedDayOption)
		const areEmployeesAssigned = this.areEmployeesAssignedForSelectedDay()
		if (isDayScheduled && areEmployeesAssigned) {
			return { color: 'green' }
		}
		if (isDayScheduled && !areEmployeesAssigned) {
			return { color: 'chocolate' }
		}
		return null
	}

	get currentEmpList(): Array<EmployeeRecord> {
		const currentDay = this.selectedDayOption?.name.toLowerCase()
		const empList = this.manager[currentDay] as Array<number>
		if (empList) {
			const list = empList.map((empId) => this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId))
			const sortedList = _.sortBy(list, 'name')
			const activeEmp = sortedList.filter((emp) => emp.active)
			const inactiveEmp = sortedList.filter((emp) => !emp.active)
			return [...activeEmp, ...inactiveEmp]
		} else {
			return []
		}
	}

	private setupEmployeeOptions() {
		const accessHelper = new AccessHelper(this.coreSrvc, 'schedule')
		const permissions = accessHelper.getPermissionsFor('schedule')
		const isRestricted = permissions.isSelectorRestrictedFor(SelectorPermissionName.employee)
		const isManager = this.coreSrvc.dbSrvc.settingSrvc.isUserAManager()

		// Add any employees that you might not own that were set by unrestricted user
		const addEmpIds = this.manager.getOriginalEmployeeIds() || []
		addEmpIds.push(0) // Slip in Any Employee

		const dropdownData = this.coreSrvc.dbSrvc.empSrvc.getEmployeeDropdown(this.coreSrvc.dbSrvc, isRestricted, isManager, addEmpIds)

		const dropdown = dropdownData.filter((item) => item.data.active === true || addEmpIds.includes(item.value))

		this.employeeOptions = dropdown
	}

	isDayScheduled(day: IScheduleOptionsDayOption) {
		return this.isDayValueScheduled(day.value)
	}

	isDayValueScheduled(value: number) {
		return this.manager.currentSelectedRRuleDays().includes(value)
	}

	// Employee Assignments
	public switchToDay(day: IScheduleOptionsDayOption) {
		this.selectedDayOption = day
		log('Assigning employees for', day.isoValue, day.short)
	}

	getTargetIconStyle(day: IScheduleOptionsDayOption): Object {
		const areEmployeesAssigned = this.areEmployeesAssignedForSpecificDay(day)
		const isDayScheduled = this.manager.currentSelectedRRuleDays().includes(day.value)
		if (isDayScheduled && areEmployeesAssigned) {
			return { color: 'green' }
		}
		if (isDayScheduled && !areEmployeesAssigned) {
			return { color: 'chocolate' }
		}
		return null
	}

	areEmployeesAssignedForSpecificDay(day: IScheduleOptionsDayOption): boolean {
		const dayName = day.name.toLowerCase()
		const count = this.manager[dayName]?.length
		return count > 0
	}

	areEmployeesAssignedForSelectedDay() {
		return this.areEmployeesAssignedForSpecificDay(this.selectedDayOption)
	}

	removeEmployee(emp: EmployeeRecord) {
		const empId = emp.id
		const currentDay = this.selectedDayOption.name.toLowerCase()
		const empList = this.manager[currentDay] as Array<number>
		// _.remove(empList, empId)
		const newList = empList.filter((elm) => elm !== empId)
		this.manager[currentDay] = newList
		this.updateSelectedDay()
	}

	public updateSelectedDay() {
		log('Update selected Day')
		const day = this.selectedDayOption.name.toLowerCase()
		if (this.manager[day].length === 0) {
			this.disableWeekday.next(this.selectedDayOption)
		} else {
			this.enableWeekday.next(this.selectedDayOption)
		}
	}

	emitHelpEvent(key: string) {
		this.showHelp.next(key)
	}

	cancelDuplication() {
		this.dupAction.showWidget = false
		const daysList = ScheduleOptions.dayOptions.map((opt) => opt.name)
		for (const day of daysList) {
			this.dupAction.selectedDays[day] = false
		}
	}

	confirmDuplication() {
		const selectedDay = this.selectedDayOption.name.toLowerCase()
		const selectedEmpIds = this.manager[selectedDay]
		const daysList = ScheduleOptions.dayOptions
		for (const dayOption of daysList) {
			const dayName = dayOption.name
			const shouldCopy = this.dupAction.selectedDays[dayName]
			if (shouldCopy) {
				const dayKeyName = dayName.toLowerCase()
				this.manager[dayKeyName] = [...selectedEmpIds]
				if (this.manager[dayKeyName].length > 0) {
					this.enableWeekday.next(dayOption)
				}
			}
		}
		this.coreSrvc.notifySrvc.notify('success', 'Schedule Copied', 'The scheduled has been copied to the days indicated.', 3)
		this.cancelDuplication()
	}

	// showDefaultSelectionDialog(emp: EmployeeRecord) {
	// 	this.defaultAction.selectedEmployee = emp
	// 	this.defaultAction.showDialog = true
	// }

	// setEmployeeAsDefault(confirm: boolean) {
	// 	if (confirm) {
	// 		const employee = this.defaultAction.selectedEmployee
	// 		log('Setting default emp', employee)
	// 		const empId = employee.id
	// 		const currentDay = this.selectedDayOption.name.toLowerCase()
	// 		const empList = this.manager[currentDay] as Array<number>
	// 		const newList = empList.filter(elm => elm !== empId)
	// 		newList.unshift(empId)
	// 		log('newList', currentDay, newList)
	// 		this.manager[currentDay] = newList
	// 		this.defaultAction.showDialog = false
	// 		this.coreSrvc.notifySrvc.notify('success', 'Default Set', `Employee ${employee.name} has been set as the default for ${this.selectedDayOption.name}.`, 3)
	// 	} else {
	// 		this.defaultAction.showDialog = false
	// 	}
	// }
}
