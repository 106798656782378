<div class="alert alert-info alert-bg-gray">These options allow you to configure settings related to this graph.</div>

<div *ngIf="false" class="options-block">
	<div class="options-title-inline">Data Source</div>
	<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('source')"></i> -->
	<p-select appScrollLockSelect id="dataSource" [options]="dataSourceOptions" [(ngModel)]="currentDataSource" filterBy="label"></p-select>
</div>

<div class="checkbox-item d-flex justify-content-between">
	<div class="checkbox-wrap">
		<p-checkbox [(ngModel)]="graph.options.plugins.legend.display" [binary]="true"></p-checkbox>
	</div>
	<div class="checkbox-label-wrap">
		<strong (click)="graph.options.plugins.legend.display = !graph.options.plugins.legend.display" class="has-pointer">Show graph legend</strong>
		<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('show_legend')"></i>
	</div>
</div>

<div *ngIf="graph.options.plugins.legend.display" class="options-block options-block-radio-lg" style="text-align: center">
	<div class="form-check form-check-inline">
		<input type="radio" class="form-check-input" id="legendTop" [value]="'top'" [(ngModel)]="graph.options.plugins.legend.position" />
		<label class="form-check-label has-pointer" for="legendTop" style="font-weight: bold">Top</label>
	</div>
	<div class="form-check form-check-inline">
		<input type="radio" class="form-check-input" id="legendRight" [value]="'right'" [(ngModel)]="graph.options.plugins.legend.position" />
		<label class="form-check-label has-pointer" for="legendRight" style="font-weight: bold">Right</label>
	</div>
	<div class="form-check form-check-inline">
		<input type="radio" class="form-check-input" id="legendBottom" [value]="'bottom'" [(ngModel)]="graph.options.plugins.legend.position" />
		<label class="form-check-label has-pointer" for="legendBottom" style="font-weight: bold">Bottom</label>
	</div>
	<div class="form-check form-check-inline">
		<input type="radio" class="form-check-input" id="legendLeft" [value]="'left'" [(ngModel)]="graph.options.plugins.legend.position" />
		<label class="form-check-label has-pointer" for="legendLeft" style="font-weight: bold">Left</label>
	</div>
</div>

<div *ngIf="false">
	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="graph.options.scales.x.stacked" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong (click)="graph.options.scales.x.stacked = !graph.options.scales.x.stacked" class="has-pointer">Stack data (x-axis)</strong>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('stack_data')"></i>
		</div>
	</div>

	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="graph.options.scales.y.stacked" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong (click)="graph.options.scales.y.stacked = !graph.options.scales.y.stacked" class="has-pointer">Stack data (y-axis)</strong>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('stack_data')"></i>
		</div>
	</div>
</div>

<!-- <div class="pre-code">{{ graph.options | json }}</div> -->
