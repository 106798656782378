import { Pipe, PipeTransform } from '@angular/core'
import { DisplayHelper } from '@app/helpers'

// Use white-space: pre-wrap on enclosing div

@Pipe({
    name: 'adminNotesFormatter',
    standalone: false
})
export class AdminNotesFormatterPipe implements PipeTransform {
	transform(text: string): any {
		return DisplayHelper.formatAdminNotesForPreWrap(text)
	}
}
