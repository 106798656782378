<div class="section-table">
	<table id="contactTable" class="stripe nowrap" cellspacing="0" width="100%"></table>
	<i id="clear-search-icon" (click)="clearSearch()" class="fa fa-times-circle-o"></i>
	<app-table-footer-buttons
		[filename]="'contact_records'"
		[newBtnLabel]="'Add Contact'"
		[exportBtnLabel]="'Export Records'"
		[table]="'contactTable'"
		[marginTop]="'15px'"
		(newBtnClicked)="createRecord()"
	></app-table-footer-buttons>
</div>

<app-modal-dialog *ngIf="editDialogManager.isDialogVisible" [dialogManager]="editDialogManager">
	<app-contact-edit
		[recordId]="editAction.recordId"
		[action]="editAction.action"
		[dialogManager]="editDialogManager"
		(recordUpdated)="recordUpdated()"
	>
	</app-contact-edit>
</app-modal-dialog>

<app-delete-record-dialog
	*ngIf="deleteAction.showDeleteRecordDialog"
	[showDeleteRecordDialog]="deleteAction.showDeleteRecordDialog"
	[tableName]="deleteAction.tableName"
	[recordId]="deleteAction.recordId"
	[recordLabel]="deleteAction.recordLabel"
	(cancelDelete)="deleteActionCancel()"
	(actionComplete)="deleteActionComplete()"
>
</app-delete-record-dialog>

<app-modal-dialog *ngIf="linkedOrgRecordsDialogManager.isDialogVisible" [dialogManager]="linkedOrgRecordsDialogManager">
	<app-link-contact-org-list
		[dialogManager]="linkedOrgRecordsDialogManager"
		[linkedRecordsManager]="linkedOrgRecordsManager"
		[source]="'CONTACT'"
		(recordUpdated)="contactOrgRecordSaved($event)"
	></app-link-contact-org-list>
</app-modal-dialog>

<app-modal-dialog *ngIf="sectionPrefsDialogManager.isDialogVisible" [dialogManager]="sectionPrefsDialogManager">
	<app-local-prefs [dialogManager]="sectionPrefsDialogManager" (prefsUpdated)="prefsDataSaved()"></app-local-prefs>
</app-modal-dialog>

<app-modal-dialog *ngIf="secureFilesDialogManager.isDialogVisible" [dialogManager]="secureFilesDialogManager">
	<app-secure-files-list [dialogManager]="secureFilesDialogManager" (recordUpdated)="updateRecord($event)"></app-secure-files-list>
</app-modal-dialog>
