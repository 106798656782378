<div [id]="pickerId" class="picker-wrapper" [owlDateTimeTrigger]="picker" (click)="doOpenPicker()">
	<div *ngIf="useTemplate">
		<ng-content></ng-content>
	</div>
	<div *ngIf="!useTemplate">
		<div class="input-group">
			<div class="input-group-text picker-icon">
				<i *ngIf="!(pickerType === 'TIME')" class="far fa-calendar datetime-icon"></i>
				<i *ngIf="pickerType === 'TIME'" class="far fa-clock datetime-clock-icon"></i>
			</div>
			<div class="form-control date-picker-input">
				<i *ngIf="useInlineClearIcon && this.currentDate" class="fad fa-times date-picker-clear-icon float-right" (click)="clearDate($event)"></i>
				<span>{{ formatDateString() }}</span>
			</div>
		</div>
	</div>

	<input
		[hidden]="true"
		[(ngModel)]="currentDate"
		[owlDateTime]="picker"
		[owlDateTimeTrigger]="picker"
		[min]="min"
		[max]="max"
		placeholder="Date Time"
	/>
	<owl-date-time
		#picker
		[pickerType]="owlPickerType"
		[pickerMode]="pickerMode"
		[hour12Timer]="hour12Timer"
		[startAt]="startAt"
		[stepHour]="stepHour"
		[stepMinute]="stepMinute"
		(afterPickerClosed)="afterPickerClosed()"
	>
	</owl-date-time>
</div>
