import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core'
import { Router, ActivatedRoute, Params } from '@angular/router'
import { HttpClient } from '@angular/common/http'

import { RegistrationUser } from '@app/models'
import { UserRegistrationService, CognitoCallback, PrefsService, AnalyticsService, CoreService } from '@app/services'

import { log, Helper, DisplayHelper } from '@app/helpers'
import { parseNumber, ParsedNumber, formatNumber, isValidNumberForRegion } from 'libphonenumber-js'
import { map } from 'rxjs/operators'
import { environment } from '@env/environment'
import { Subscription } from 'rxjs'

/**
 * This component is responsible for displaying and controlling
 * the registration of the user.
 */

@Component({
    selector: 'app-auth-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
    standalone: false
})
export class RegisterComponent implements OnInit, AfterViewInit, OnDestroy, CognitoCallback {
	userRegistration: RegistrationUser
	retrievedUser: RegistrationUser
	errorMessage: string

	errorUserExits = false

	registeringUser = false
	componentActive = true

	passwordValid = false
	mobileNumberValid = false

	pingTimer

	currentDate = new Date().toISOString()
	countryCodeData = Helper.countryIsoData.filter((region) => region.regionSupport).filter((region) => region.prefix !== '+44')

	showIntroDialog = false

	private subs = new Subscription()

	constructor(
		private http: HttpClient,
		private router: Router,
		private route: ActivatedRoute,
		private userRegistrationService: UserRegistrationService,
		private analyticsSrvc: AnalyticsService,
		private prefsService: PrefsService,
		private coreSrvc: CoreService,
	) {
		this.subs.add(
			this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => {
				this.handleScreenSizeChange()
			}),
		)
		this.handleScreenSizeChange()

		this.userRegistration = new RegistrationUser()
		this.retrievedUser = new RegistrationUser()
		const regInfo = this.userRegistrationService.getRegInfo()
		if (regInfo && regInfo.isValid) {
			this.userRegistrationService.userRegistration = regInfo
			this.retrievedUser = regInfo
		}
	}

	ngOnInit() {
		this.route.queryParams.subscribe((params: Params) => {
			const firstName = params['firstName'] ?? ''
			const lastName = params['lastName'] ?? ''
			const email = params['email'] ?? ''
			const phone = params['phone'] ?? ''
			const refTag = params['refTag'] || localStorage.getItem('refTag')
			if (firstName) {
				this.userRegistration.firstName = firstName
			}
			if (lastName) {
				this.userRegistration.lastName = lastName
			}
			if (email) {
				this.userRegistration.email = email
			}
			if (phone) {
				this.userRegistration.mobile = phone
			}
			if (refTag && refTag !== 'null') {
				localStorage.setItem('refTag', refTag)
				this.userRegistration.refTag = refTag
			}

			if (phone) {
				this.formatPhone()
			}
			log('Incoming params', params)
		})

		this.errorMessage = null
		// this.pingLambdas()
		// this.pingTimer = setInterval(() => {
		// 	this.pingLambdas()
		// }, 60000)
		this.analyticsSrvc.emitGAEvent('Registration', 'Started', 'User started registration', 1)
		this.analyticsSrvc.emitFBCustomEvent('StartRegistration')

		// Check the users IP to set their default region
		// $.getJSON('https://freegeoip.net/json/', (result) => {
		// 	log('Country for user:', result.country_code);
		// 	const countryCode = result.country_code;
		// 	switch (countryCode) {
		// 		case 'CA':
		// 			this.userRegistration.countryCode = 'CA';
		// 			break;
		// 		case 'GB':
		// 			this.userRegistration.countryCode = 'GB';
		// 			break;
		// 		default:
		// 			this.userRegistration.countryCode = 'US';
		// 	}
		// });
	}

	ngAfterViewInit() {
		scrollTo(0, 0)
		// setTimeout(() => {
		// 	this.showIntroDialog = true
		// }, 100)
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
		clearInterval(this.pingTimer)
	}

	handleScreenSizeChange() {
		log('Screen size changed - Registration')
		DisplayHelper.setBodyBackgroundForAuthFlow()
	}

	pingLambdas() {
		this.http.get('https://3zs535h5lk.execute-api.us-east-1.amazonaws.com/prod/TimesheetsCompanyCreatePing').subscribe((res) => {
			log(res)
		})
		this.http.get('https://3zs535h5lk.execute-api.us-east-1.amazonaws.com/prod/UserPoolTriggerPreSignUp').subscribe((res) => {
			log(res)
		})
		this.http.get('https://3zs535h5lk.execute-api.us-east-1.amazonaws.com/prod/PhoneNumberTeliPing').subscribe((res) => {
			log(res)
		})
		this.formatPhone()
	}

	checkEmail(email: string): boolean {
		return Helper.isValidEmail(email)
	}

	changeRegion(): boolean {
		this.formatPhone()
		this.prefsService.data.defaultLoginRegion = this.userRegistration.countryCode
		this.prefsService.save()
		return false
	}

	formatPhone() {
		const countryCode = this.userRegistration.countryCode
		const number = this.userRegistration.mobile

		if (number && number.length > 3 && isValidNumberForRegion(number, countryCode)) {
			const parsedNumber = parseNumber(number, countryCode) as ParsedNumber
			this.mobileNumberValid = true
			this.userRegistration.mobile = formatNumber(parsedNumber, 'NATIONAL')
		} else {
			this.mobileNumberValid = false
		}
	}

	isPasswordValid(): boolean {
		const password = this.userRegistration.password || ''
		return Helper.isPasswordValid(password)
		// if (isPasswordValid) {
		// 	return true
		// } else {
		// 	return false
		// }
	}

	isConfirmationValid(): boolean {
		if (!this.userRegistration.confirmation) {
			return false
		}
		if (this.userRegistration.password === this.userRegistration.confirmation) {
			return true
		}
		return false
	}

	onRegister() {
		this.errorMessage = null
		this.errorUserExits = false

		const user = this.userRegistration
		const countryCode = this.userRegistration.countryCode
		const number = this.userRegistration.mobile
		const parsedNumber = parseNumber(number, countryCode) as ParsedNumber

		const isPassValid = Helper.isPasswordValid(this.userRegistration.password)

		if (!user.firstName || !user.lastName || !user.mobile || !user.email || !user.password || !user.confirmation) {
			// this.googleAnalyticsService.emitEvent('Registration', 'ErrorMissingFields', 'User submitted form with missing fields', 1);
			alert('All fields are required unless marked as optional. Please check the form and try again.')
			return
		}
		if (!isValidNumberForRegion(number, countryCode)) {
			alert('The mobile number you entered is not valid for the region you selected.')
			return
		}
		if (!this.checkEmail(this.userRegistration.email)) {
			// this.googleAnalyticsService.emitEvent('Registration', 'ErrorEmailAddress', 'User entered: ' + this.registrationUser.email, 1);
			alert('Please enter a valid email address')
			return
		}
		if (!isPassValid) {
			// this.googleAnalyticsService.emitEvent('Registration', 'ErrorPassword', 'Password failed validation check', 1);
			alert('Passwords must be at least 8 characters long and include both letters and numbers.')
			return
		}
		if (this.userRegistration.password !== this.userRegistration.confirmation) {
			// this.googleAnalyticsService.emitEvent('Registration', 'ErrorPasswordConfirmation', 'Password confirmation did not match password', 1);
			alert('Passwords do not match. Please re-enter your password and password confirmation.')
			return
		}

		// Format the new user registration object

		const newUserRegistration = new RegistrationUser()
		newUserRegistration.mobile = formatNumber(parsedNumber, 'E.164')
		newUserRegistration.username = newUserRegistration.mobile.substr(1) + 'X' // Pull off + from e.164 and append X
		newUserRegistration.countryCode = this.userRegistration.countryCode
		newUserRegistration.password = this.userRegistration.password
		newUserRegistration.firstName = this.userRegistration.firstName
		newUserRegistration.lastName = this.userRegistration.lastName
		newUserRegistration.email = this.userRegistration.email
		newUserRegistration.companyName = this.userRegistration.companyName

		log(newUserRegistration)

		localStorage.clear()
		this.prefsService.data.defaultLoginRegion = countryCode
		this.prefsService.save()

		this.userRegistrationService.setRegInfo(newUserRegistration)
		const refTag = this.userRegistration.refTag
		localStorage.setItem('refTag', refTag)

		this.registeringUser = true
		this.analyticsSrvc.emitGAEvent(
			'Registration',
			'RegistrationSubmitted',
			'Registration form submitted for ' + user.mobile + ' / ' + user.email,
			1,
		)
		this.analyticsSrvc.emitFBSubmitRegistrationEvent(newUserRegistration)
		this.userRegistrationService.register(newUserRegistration, this)
	}

	saveReg() {
		this.userRegistrationService.setRegInfo(this.userRegistration)
	}

	getReg() {
		this.retrievedUser = this.userRegistrationService.getRegInfo()
	}

	userValidity(): string {
		const user = this.retrievedUser
		if (user) {
			if (user.isValid) {
				return user.timestamp
			} else {
				return 'Registration Expired'
			}
		}
		return 'No Set User'
	}

	resumeRegistration() {
		this.router.navigate(['/home/confirmRegistration', this.retrievedUser.username])
	}

	startOver() {
		// alert('Start over choosen');
		this.userRegistrationService.clearRegInfo()
		this.userRegistration = new RegistrationUser()
		this.retrievedUser = new RegistrationUser()
	}

	gotoTimeclock() {
		const empAppDomain = environment.empAppDomain
		const url = `https://${empAppDomain}`
		window.location.href = url
		// this.router.navigateByUrl(url)
	}

	openLink(link: string) {
		switch (link) {
			case 'TOS':
				window.open('https://telephonetimesheets.com/terms-of-service/', '_blank')
				break
			case 'PP':
				window.open('https://telephonetimesheets.com/privacy-policy/', '_blank')
				break
		}
	}

	cognitoCallback(message: string, result: any) {
		if (message != null) {
			// error
			const errorMsg = message ?? ''
			if (errorMsg.includes('already exists')) {
				this.errorMessage =
					'The mobile number you provided has already been registered. Please try logging in or contact support for more assistance.'
				this.errorUserExits = true
			} else {
				this.errorMessage =
					'An error has been encountered trying to register with the mobile number provided. Please contact support for assistance.'
			}
			this.userRegistrationService.clearRegInfo()
			// this.errorMessage = message;
			log('result: ' + this.errorMessage)
			this.analyticsSrvc.emitGAEvent(
				'Registration',
				'ErrorRegistrationSubmission',
				'Registration Error for ' + this.userRegistration.mobile + ': ' + message,
				1,
			)
			this.registeringUser = false
		} else {
			// success
			// move to the next step
			log('redirecting')
			this.router.navigate(['/home/confirmRegistration', result.user.username])
		}
	}
}
