import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ReportFieldCheckboxBasic } from '@app/models'

@Component({
    selector: 'app-checklist-report-item-checkbox',
    templateUrl: './checklist-report-item-checkbox.component.html',
    styleUrls: ['./checklist-report-item-checkbox.component.scss'],
    standalone: false
})
export class ChecklistReportItemCheckboxComponent {
	@Input() item: ReportFieldCheckboxBasic
	@Output() toggleSkipCheckbox = new EventEmitter<ReportFieldCheckboxBasic>()
	@Output() textResizer = new EventEmitter<HTMLTextAreaElement>()
}
