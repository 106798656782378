import { DateTimeHelper } from '@app/helpers'

export class UserGroupRecord {
	id: number
	company_id: number
	external_id: number
	primary: number
	description: string
	details: string
	supervisor_group_users: Array<number> = []
	created: string
	updated: string

	constructor(record?: UserGroupRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
		}
	}
}
