import { Injectable } from '@angular/core'
import { Router, NavigationEnd, NavigationStart } from '@angular/router'
import { distinctUntilChanged } from 'rxjs/operators'

import { DisplayHelper, log } from '@app/helpers'
import { RegistrationUser } from '@app/models'
import { environment } from '@env/environment'
import { Global } from '@app/models/global'
import { ViewportScroller } from '@angular/common'

declare var gtag
declare var fbq

@Injectable({
	providedIn: 'root',
})
export class AnalyticsService {
	analyticsStarted = false
	companyId: number

	constructor(
		private router: Router,
		private viewportScroller: ViewportScroller,
	) {
		log('Creating AnalyticsService')

		if (!this.analyticsStarted) {
			this.analyticsStarted = true

			// Navigation Start Events
			router.events
				.pipe(
					distinctUntilChanged((previous: any, current: any) => {
						// Subscribe to any `NavigationEnd` events where the url has changed
						if (current instanceof NavigationStart) {
							return previous.url === current.url
						}
						return true
					}),
				)
				.subscribe((x: any) => {
					DisplayHelper.scrollToTopViewportAdjust(0, 'instant')
				})

			// Navigation End Events
			router.events
				.pipe(
					distinctUntilChanged((previous: any, current: any) => {
						// Subscribe to any `NavigationEnd` events where the url has changed
						if (current instanceof NavigationEnd) {
							return previous.url === current.url
						}
						return true
					}),
				)
				.subscribe((x: any) => {
					// W2WAPIKEYCHECK - BEGIN
					if (Global.coreSrvc?.dbSrvc?.settingSrvc?.forceRouteToW2WIntegration) {
						const url = x.url || ''
						if (!url.includes('/admin/dashboard')) {
							setTimeout(() => {
								this.router.navigate(['/admin/integrations/whentowork'])
							}, 1000)
							return
						}
					}
					// W2WAPIKEYCHECK - END

					const url = x.url ?? ''
					if (url) {
						if (!url.includes('adpDeveloperLogin')) {
							log('Sending GA Page View for ', x.url)
							this.setLastRouteWithProcessing(x.url)
							this.emitGTMPageView(x.url)
						}
					}

					// Post route viewport tweak
					setTimeout(() => {
						DisplayHelper.scrollToTopInstant()
					}, 10)
				})
		}
	}

	private emitGTMPageView(url: string) {
		if (!url) return
		const gtmPageTag = {
			event: 'page_view',
			page_path: url,
		}
		this.addToDataLayer(gtmPageTag)
	}

	private addToDataLayer(data: any) {
		const dataLayer = window['dataLayer']
		if (dataLayer) {
			dataLayer.push(data)
		}
	}

	private setLastRouteWithoutProcessing(path: string) {
		const now = new Date()
		const lastRoute = {
			date: now,
			path: path,
		}
		localStorage.setItem('lastRoute', JSON.stringify(lastRoute))
	}

	private setLastRouteWithProcessing(path: string) {
		const now = new Date()
		const lastRoute = {
			date: now,
			path: path,
		}
		this.processRoute(lastRoute)
	}

	// Handle various routes that should not be saved when app reloads
	// Make sure to return from anything that shouldn't be saved. The
	// SettingsService loads the lastRoute on initialization.

	private processRoute(route) {
		if (route && route.path) {
			const path = route.path

			// 20240729 - Add redirect to /admin/reports/business for old /admin/reports route
			if (path === '/admin/reports' || path === '/admin/reports/') {
				const redirectPath = '/admin/reports/business'
				this.setLastRouteWithoutProcessing(redirectPath)
				return
			}

			if (path === '/') return
			if (path === '/admin') return
			if (path === '/maintenance') return

			if (path.includes('/home')) return
			if (path.includes('/new')) return
			if (path.includes('/edit')) return
			if (path.includes('/delete')) return
			if (path.includes('/redirect')) return
			if (path.includes('/integrations/log')) return
			if (path.includes('/reset')) return
			if (path.includes('/reports/employee/')) return // Add trailing / to only ignore child routes. The Emp Report log is then allowed to save on reload
			if (path.includes('/reports/shiftsummary')) return
			if (path.includes('/scheduler/filter')) return
			if (path.includes('/login')) return
			if (path.includes('/logout')) {
				localStorage.removeItem('lastRoute')
				return
			}
			// Assume route is valid and safe to go to on app reload
			localStorage.setItem('lastRoute', JSON.stringify(route))
		}
	}

	public setCompanyId(id: number) {
		this.companyId = id
	}

	public emitGAEvent(eventCategory: string, eventAction: string, eventLabel: string, eventValue: number) {
		log('emitGAEvent currently disabled')
		return
		// if (!environment.production) {
		// 	return
		// }
		// if (gtag) {
		// 	// Old Tag UA-79900114-2
		// 	gtag('event', eventCategory, {
		// 		send_to: 'GTM-K5V9LG2',
		// 		event_category: eventCategory,
		// 		event_action: eventAction,
		// 		event_label: eventLabel,
		// 	})
		// 	log('Send GA Event (Category, Action, Label):', eventCategory, eventAction, eventLabel)
		// }
	}

	public emitGAError(errorCategory: string, errorAction: string, errorLabel: string) {
		log('emitGAError currently disabled')
		return
		// if (!environment.production) {
		// 	return
		// }
		// if (gtag) {
		// 	const errorMessage = `Company ${this.companyId}: ${errorLabel}`
		// 	// Old Tag UA-79900114-2
		// 	gtag('event', errorCategory, {
		// 		send_to: 'GTM-K5V9LG2',
		// 		event_category: errorCategory,
		// 		event_action: errorAction,
		// 		event_label: errorMessage,
		// 	})
		// 	log('Send GA Error (Category, Action, Label):', errorCategory, errorAction, errorMessage)
		// }
	}

	// DEPRECATED
	// public sendStandardConversion() {
	// 	log('Sending a standard conversion')
	// 	const gtmConversionTag = {
	// 		event: 'conversion',
	// 	}
	// 	this.addToDataLayer(gtmConversionTag)
	// }

	public sendConversion() {
		// Working conversions
		// log('sendConversion')
		const gtmConversionTag = { event: 'gtm_conversion_gat' }
		this.addToDataLayer(gtmConversionTag)
		return

		// DEPRECATED - Use gtag to push to data layer
		// if (gtag) {
		// 	// gtag('event', 'conversion', { send_to: 'AW-879733486/nsUqCPvp7mcQ7tW-owM' })
		// 	gtag('event', 'conversion', { send_to: 'GTM-K5V9LG2/nsUqCPvp7mcQ7tW-owM' })
		// }
		// Facebook Deprecated
		// if (fbq) {
		// 	fbq('track', 'CompleteRegistration', { value: 1000.00, currency: 'USD' })
		// }
	}

	public emitFBCustomEvent(event: string) {
		// if (fbq) {
		// 	fbq('trackCustom', event)
		// }
	}

	public emitFBSubmitRegistrationEvent(regInfo: RegistrationUser) {
		// if (fbq) {
		// 	log('FB Event Info', regInfo)
		// 	const name = regInfo.firstName + ' ' + regInfo.lastName
		// 	const userInfo = { country: regInfo.countryCode, phone: regInfo.mobile, email: regInfo.email, name: name }
		// 	fbq('trackCustom', 'SubmitRegistration')
		// }
	}
}

// https://blog.thecodecampus.de/angular-2-include-google-analytics-event-tracking/
