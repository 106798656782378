<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<div *ngIf="isViewingFromUserTableWithRoleAssigned" class="alert alert-info">
		This supervisor's permissions are currently being managed by the <strong>{{ assignedRoleName }}</strong> role template. To manage permissions
		manually for this user, remove the role template assignment using the supervisor edit form.
	</div>

	<!-- Set Role -->
	<div *ngIf="isViewingFromUserTableWithoutRoleAssigned">
		<div class="card card-block perm-block">
			<div class="header-block role-config-header" (click)="toggleRoleConfigBlock()">
				<div class="display-name">Copy Role Permissions</div>
				<i *ngIf="!roleConfig.show" class="fa fa-chevron-right float-right show-btn"></i>
				<i *ngIf="roleConfig.show" class="fa fa-chevron-down float-right show-btn"></i>
			</div>
			<div *ngIf="roleConfig.show">
				<!-- <hr class="perm-hr" /> -->
				<div class="alert alert-warning" style="margin-top: 14px">
					When copying permissions, any changes to the selected role will not be inherited by this user. In order for their permissions to be
					managed by a role, use the supervisor edit dialog to assign the role permissions template instead.
				</div>
				<p-select
					appScrollLockSelect
					id="roleConfig"
					[options]="rolesDropdown"
					[(ngModel)]="selectedRole"
					placeholder="Select a role"
					[filter]="rolesDropdown.length > 10"
				></p-select>
				<button class="btn btn-sm btn-block btn-success apply-role-btn" (click)="applyRole()">Copy Permissions</button>
			</div>
		</div>
	</div>

	<!-- Multi Category Configuration - Not currently in use - Allows applying permissions to multiple permission blocks -->

	<div *ngIf="false && isAdminPrimaryOrInternal">
		<div class="card card-block perm-block">
			<div>
				<div class="header-block multi-config-header" (click)="toggleMultiConfig()">
					<i *ngIf="!multiConfig.show" class="fa fa-chevron-right float-right show-btn"></i>
					<i *ngIf="multiConfig.show" class="fa fa-chevron-down float-right show-btn"></i>
					<div class="display-name">Configure Multiple Permissions</div>
				</div>
			</div>

			<div *ngIf="multiConfig.show">
				<hr class="perm-hr" />

				<div class="multi-config-select">
					<p-multiselect
						appScrollLockMultiSelect
						placeholder="Select Categories"
						[maxSelectedLabels]="0"
						selectedItemsLabel="Categories Selected: {0}"
						[options]="permissionOptions"
						optionLabel="label"
						[(ngModel)]="selectedCategories"
					>
					</p-multiselect>
				</div>

				<div style="margin-bottom: 10px">
					<div class="row">
						<div class="col-6">
							<p-checkbox
								class="perm-checkbox"
								(onChange)="togglePermission(multiConfig, 'access', 'noAccess', true)"
								[(ngModel)]="multiConfig.access.noAccess"
								[binary]="true"
							></p-checkbox>
							<div class="access-label" (click)="togglePermission(multiConfig, 'access', 'noAccess')">No Access</div>
						</div>
						<div class="col-6">
							<!-- <div *ngIf="!multiConfig.access.noAccess">
								<p-checkbox class="perm-checkbox" [(ngModel)]="multiConfig.access.supervisor" [binary]="true"></p-checkbox>
								<div class="access-label" (click)="togglePermission(multiConfig, 'access', 'supervisor')">Supervisor</div>
							</div> -->
						</div>
					</div>

					<div *ngIf="!multiConfig.access.noAccess">
						<hr />
						<div class="record-header tts-hl-2">Access Permission for <span style="color: chocolate">all records</span></div>
						<div class="row">
							<div class="col-6">
								<p-checkbox
									class="perm-checkbox"
									[disabled]="isOptionDisabled(multiConfig, 'access', 'read')"
									(onChange)="togglePermission(multiConfig, 'access', 'read', true)"
									[(ngModel)]="multiConfig.access.read"
									[binary]="true"
								></p-checkbox>
								<div
									class="access-label"
									[class.disabled]="isOptionDisabled(multiConfig, 'access', 'read')"
									(click)="togglePermission(multiConfig, 'access', 'read')"
								>
									View
								</div>
							</div>
							<div class="col-6">
								<p-checkbox
									class="perm-checkbox"
									[disabled]="isOptionDisabled(multiConfig, 'access', 'update')"
									(onChange)="togglePermission(multiConfig, 'access', 'update', true)"
									[(ngModel)]="multiConfig.access.update"
									[binary]="true"
								></p-checkbox>
								<div
									class="access-label"
									[class.disabled]="isOptionDisabled(multiConfig, 'access', 'update')"
									(click)="togglePermission(multiConfig, 'access', 'update')"
								>
									Modify
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-6">
								<p-checkbox
									class="perm-checkbox"
									[disabled]="isOptionDisabled(multiConfig, 'access', 'create')"
									[(ngModel)]="multiConfig.access.create"
									(onChange)="togglePermission(multiConfig, 'access', 'create', true)"
									[binary]="true"
								></p-checkbox>
								<div
									class="access-label"
									[class.disabled]="isOptionDisabled(multiConfig, 'access', 'create')"
									(click)="togglePermission(multiConfig, 'access', 'create')"
								>
									Create
								</div>
							</div>
							<div class="col-6">
								<p-checkbox
									class="perm-checkbox"
									[disabled]="isOptionDisabled(multiConfig, 'access', 'delete')"
									(onChange)="togglePermission(multiConfig, 'access', 'delete', true)"
									[(ngModel)]="multiConfig.access.delete"
									[binary]="true"
								></p-checkbox>
								<div
									class="access-label"
									[class.disabled]="isOptionDisabled(multiConfig, 'access', 'delete')"
									(click)="togglePermission(multiConfig, 'access', 'delete')"
								>
									Delete
								</div>
							</div>
						</div>

						<!-- <div *ngIf="multiConfig.owner && multiConfig.access.supervisor"> -->
						<div>
							<hr />
							<div class="record-header tts-hl-2">Access Permission for <span style="color: chocolate">my records</span></div>
							<div>
								<div class="row">
									<div class="col-6">
										<p-checkbox
											class="perm-checkbox"
											[disabled]="isOptionDisabled(multiConfig, 'owner', 'read')"
											(onChange)="togglePermission(multiConfig, 'owner', 'read', true)"
											[(ngModel)]="multiConfig.owner.read"
											[binary]="true"
										></p-checkbox>
										<div
											class="access-label"
											[class.disabled]="isOptionDisabled(multiConfig, 'owner', 'read')"
											(click)="togglePermission(multiConfig, 'owner', 'read')"
										>
											View
										</div>
									</div>
									<div class="col-6">
										<p-checkbox
											class="perm-checkbox"
											[disabled]="isOptionDisabled(multiConfig, 'owner', 'update')"
											[(ngModel)]="multiConfig.owner.update"
											[binary]="true"
										></p-checkbox>
										<div
											class="access-label"
											[class.disabled]="isOptionDisabled(multiConfig, 'owner', 'update')"
											(click)="togglePermission(multiConfig, 'owner', 'update')"
										>
											Modify
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-6">
										<p-checkbox
											class="perm-checkbox"
											[disabled]="isOptionDisabled(multiConfig, 'owner', 'create')"
											[(ngModel)]="multiConfig.owner.create"
											[binary]="true"
										></p-checkbox>
										<div
											class="access-label"
											[class.disabled]="isOptionDisabled(multiConfig, 'owner', 'create')"
											(click)="togglePermission(multiConfig, 'owner', 'create')"
										>
											Create
										</div>
									</div>
									<div class="col-6">
										<p-checkbox
											class="perm-checkbox"
											[disabled]="isOptionDisabled(multiConfig, 'owner', 'delete')"
											[(ngModel)]="multiConfig.owner.delete"
											[binary]="true"
										></p-checkbox>
										<div
											class="access-label"
											[class.disabled]="isOptionDisabled(multiConfig, 'owner', 'delete')"
											(click)="togglePermission(multiConfig, 'owner', 'delete')"
										>
											Delete
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<hr />
				<div class="row">
					<div class="col-6">
						<button class="btn btn-sm btn-outline-secondary btn-block" (click)="setReadOnly(multiConfig)">Set View Only</button>
					</div>
					<div class="col-6">
						<button class="btn btn-sm btn-outline-secondary btn-block" (click)="setFullAccess(multiConfig)">Set Full Access</button>
					</div>
				</div>
				<!-- <hr> -->
				<div class="row apply-perms-block">
					<div class="col-12">
						<button class="btn btn-sm btn-success btn-block" (click)="applyMultiConfigPermissions()">Apply Permissions</button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- <hr /> -->

	<!-- Individual Categories -->

	<div>
		<div *ngFor="let item of accessPermissions.permissionItems">
			<div *ngIf="canModifyPermissions(item)" class="card card-block perm-block">
				<div
					[class.restricted]="(!isFullAccess(item) && !isNoAccess(item)) || isReadOnly(item)"
					[class.no-access]="isNoAccess(item)"
					[class.full-access]="isFullAccess(item)"
					[class.open-block]="hasOpenBlock() && !item.showDetails"
				>
					<div class="header-block" (click)="togglePermissionsBlock(item)">
						<i *ngIf="!item.showDetails" class="fa fa-chevron-right float-right show-btn"></i>
						<i *ngIf="item.showDetails" class="fa fa-chevron-down float-right show-btn"></i>
						<div class="display-name-wrapper">
							<div class="display-name">{{ item.label }}</div>
							<div *ngIf="!isFullAccess(item) && !isNoAccess(item) && !isReadOnly(item)" class="restrict-label">(restricted)</div>
							<div *ngIf="isReadOnly(item)" class="restrict-label">(view only)</div>
							<div *ngIf="isNoAccess(item)" class="restrict-label">(no access)</div>
							<div *ngIf="isFullAccess(item)" class="restrict-label">(full access)</div>
						</div>
					</div>
				</div>
				<div class="sup-note" *ngIf="!isNoAccess(item) && item.access.supervisor && !item.access.read">Restrict to supervisor's own records</div>
				<div *ngIf="false && !item.showDetails">
					<div class="selected-note">
						{{ selectedCrudOptions(item) }}
					</div>
				</div>

				<div *ngIf="item.showDetails">
					<hr class="perm-hr" />

					<div style="margin-bottom: 10px">
						<div class="row">
							<div class="col-6">
								<p-checkbox
									class="perm-checkbox"
									[disabled]="isOptionDisabled(item, 'access', 'noAccess')"
									(onChange)="togglePermission(item, 'access', 'noAccess', true)"
									[(ngModel)]="item.access.noAccess"
									[binary]="true"
								></p-checkbox>
								<div
									class="access-label"
									[class.disabled]="isOptionDisabled(item, 'access', 'noAccess')"
									(click)="togglePermission(item, 'access', 'noAccess')"
								>
									No Access
								</div>
							</div>
							<div class="col-6">
								<!-- <div *ngIf="!item.access.noAccess && item.selectors">
								<p-checkbox class="perm-checkbox" [(ngModel)]="item.access.supervisor" [binary]="true"></p-checkbox>
								<div class="access-label" (click)="togglePermission(item, 'access', 'supervisor')">Supervisor</div>
							</div> -->
							</div>
						</div>

						<div *ngIf="!item.access.noAccess">
							<div *ngIf="item.showAccess">
								<hr />
								<div class="record-header tts-hl-2">
									Access Permission <span *ngIf="item.showOwner"> for </span
									><span *ngIf="item.showOwner" style="color: chocolate">all records</span>
								</div>
								<div class="row">
									<div class="col-6">
										<p-checkbox
											class="perm-checkbox"
											[disabled]="isOptionDisabled(item, 'access', 'read')"
											(onChange)="togglePermission(item, 'access', 'read', true)"
											[(ngModel)]="item.access.read"
											[binary]="true"
										></p-checkbox>
										<div
											class="access-label"
											[class.disabled]="isOptionDisabled(item, 'access', 'read')"
											(click)="togglePermission(item, 'access', 'read')"
										>
											View
										</div>
									</div>
									<div class="col-6">
										<p-checkbox
											class="perm-checkbox"
											[disabled]="isOptionDisabled(item, 'access', 'update')"
											(onChange)="togglePermission(item, 'access', 'update', true)"
											[(ngModel)]="item.access.update"
											[binary]="true"
										></p-checkbox>
										<div
											class="access-label"
											[class.disabled]="isOptionDisabled(item, 'access', 'update')"
											(click)="togglePermission(item, 'access', 'update')"
										>
											Modify
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-6">
										<p-checkbox
											class="perm-checkbox"
											[disabled]="isOptionDisabled(item, 'access', 'create')"
											(onChange)="togglePermission(item, 'access', 'create', true)"
											[(ngModel)]="item.access.create"
											[binary]="true"
										></p-checkbox>
										<div
											class="access-label"
											[class.disabled]="isOptionDisabled(item, 'access', 'create')"
											(click)="togglePermission(item, 'access', 'create')"
										>
											Create
										</div>
									</div>
									<div class="col-6">
										<p-checkbox
											class="perm-checkbox"
											[disabled]="isOptionDisabled(item, 'access', 'delete')"
											(onChange)="togglePermission(item, 'access', 'delete', true)"
											[(ngModel)]="item.access.delete"
											[binary]="true"
										></p-checkbox>
										<div
											class="access-label"
											[class.disabled]="isOptionDisabled(item, 'access', 'delete')"
											(click)="togglePermission(item, 'access', 'delete')"
										>
											Delete
										</div>
									</div>
								</div>
							</div>

							<!-- <div *ngIf="item.owner && item.access.supervisor"> -->
							<div *ngIf="item.showOwner">
								<hr />
								<div class="record-header tts-hl-2">
									Access Permission <span *ngIf="item.showOwner"> for </span
									><span *ngIf="item.showOwner" style="color: chocolate">my records</span>
								</div>
								<div>
									<div class="row">
										<div class="col-6">
											<p-checkbox
												class="perm-checkbox"
												[disabled]="isOptionDisabled(item, 'owner', 'read')"
												(onChange)="togglePermission(item, 'owner', 'read', true)"
												[(ngModel)]="item.owner.read"
												[binary]="true"
											></p-checkbox>
											<div
												class="access-label"
												[class.disabled]="isOptionDisabled(item, 'owner', 'read')"
												(click)="togglePermission(item, 'owner', 'read')"
											>
												View
											</div>
										</div>
										<div class="col-6">
											<p-checkbox
												class="perm-checkbox"
												[disabled]="isOptionDisabled(item, 'owner', 'update')"
												[(ngModel)]="item.owner.update"
												[binary]="true"
											></p-checkbox>
											<div
												class="access-label"
												[class.disabled]="isOptionDisabled(item, 'owner', 'update')"
												(click)="togglePermission(item, 'owner', 'update')"
											>
												Modify
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-6">
											<p-checkbox
												class="perm-checkbox"
												[disabled]="isOptionDisabled(item, 'owner', 'create')"
												[(ngModel)]="item.owner.create"
												[binary]="true"
											></p-checkbox>
											<div
												class="access-label"
												[class.disabled]="isOptionDisabled(item, 'owner', 'create')"
												(click)="togglePermission(item, 'owner', 'create')"
											>
												Create
											</div>
										</div>
										<div class="col-6">
											<p-checkbox
												class="perm-checkbox"
												[disabled]="isOptionDisabled(item, 'owner', 'delete')"
												[(ngModel)]="item.owner.delete"
												[binary]="true"
											></p-checkbox>
											<div
												class="access-label"
												[class.disabled]="isOptionDisabled(item, 'owner', 'delete')"
												(click)="togglePermission(item, 'owner', 'delete')"
											>
												Delete
											</div>
										</div>
									</div>
								</div>
							</div>

							<div *ngIf="!isSelectorDisabled(item) && item.selectors && item.selectors.length > 0">
								<div *ngFor="let selector of item.selectors">
									<hr />
									<div class="record-header tts-hl-2">{{ selectorNameFormatter(selector) }}</div>
									<div class="row">
										<div class="col-12">
											<p-checkbox
												class="perm-checkbox"
												[disabled]="isSelectorDisabled(item)"
												[(ngModel)]="selector.restricted"
												(click)="toggleSelectorPermission(item, selector, true)"
												[binary]="true"
											></p-checkbox>
											<div
												class="access-label"
												[class.disabled]="isSelectorDisabled(item)"
												(click)="toggleSelectorPermission(item, selector)"
											>
												{{ selectorDescriptionFormatter(selector) }}
											</div>
											<i
												title="More Information"
												class="far fa-info-circle tts-info-btn"
												aria-hidden="true"
												(click)="showHelp(selector.type)"
											></i>
										</div>
									</div>
								</div>
							</div>

							<div *ngIf="item.options && item.options.length > 0">
								<hr />
								<div class="record-header tts-hl-2">Additional Permissions</div>
								<div *ngFor="let option of item.options">
									<div class="row">
										<div class="col-12">
											<p-checkbox
												class="perm-checkbox"
												[disabled]="isPermOptionDisabled(item)"
												[(ngModel)]="option.enabled"
												(click)="togglePermOptionPermission(item, option, true)"
												[binary]="true"
											></p-checkbox>
											<div
												class="access-label"
												[class.disabled]="isPermOptionDisabled(item)"
												(click)="togglePermOptionPermission(item, option)"
											>
												{{ optionDescriptionFormatter(option) }}
											</div>
											<i
												title="More Information"
												class="far fa-info-circle tts-info-btn"
												aria-hidden="true"
												(click)="showHelp(option.type)"
											></i>
										</div>
									</div>
								</div>
							</div>

							<!-- <hr *ngIf="!item.selectors || (item.selectors && item.selectors.length === 0)"> -->
						</div>
						<!-- <hr>
						<div class="row">
							<div class="col-6"><button class="btn btn-sm btn-outline-secondary btn-block" (click)="setReadOnly(item)">Set View Only</button></div>
							<div class="col-6"><button class="btn btn-sm btn-outline-secondary btn-block" (click)="setFullAccess(item)">Set Full Access</button></div>
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="isAdminPrimaryOrInternal && userId && !isViewingFromUserTableWithRoleAssigned">
		<hr />
		<button *ngIf="isAdminInternal" class="btn btn-sm btn-secondary float-right" (click)="showResultClicked()">Show Result</button>
		<button class="btn btn-sm btn-secondary" [class.btn-block]="!isAdminInternal" (click)="resetPermissions()">Reset Permissions</button>
		<div style="margin-top: 25px">
			<code>
				<pre>{{ jsonResult | json }}</pre>
			</code>
		</div>
	</div>
</div>
