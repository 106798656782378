import { log } from '@app/helpers/logger'
import { DatabaseService } from '@app/services'
import { Address, LocationAddress } from '@app/models/address'

// export class EditFormJobSiteLocationAddress {
// 	street = ''
// 	street2 = ''
// 	city = ''
// 	district = ''
// 	postcode = ''
// 	formatted = ''
// }

export class EditFormJobSiteLocationManagerConfig {
	geoCoded: boolean
	geoCodeReverse: boolean
	geoLatitude: number
	geoLongitude: number
	// geoLastCoding: string
	enableAddressByMapPin: boolean
	locationAddress: LocationAddress = new LocationAddress()
}

export class EditFormJobSiteLocationResult {
	address: string
	locationAddress: LocationAddress
	geoCodeReverse: boolean
	latitude: number
	longitude: number
}

export class EditFormJobSiteLocationManager {
	dbSrvc: DatabaseService

	address = new LocationAddress()
	latitude: number = null
	longitude: number = null
	selectedLocationType: 'address' | 'marker' | 'none' = 'address'

	enableMapView = false
	showMapViewOption = false

	get result(): EditFormJobSiteLocationResult {
		const result = new EditFormJobSiteLocationResult()
		result.address = this.address.addressString()
		result.locationAddress = this.address
		if (this.selectedLocationType === 'marker' && this.latitude && this.longitude) {
			result.geoCodeReverse = true
			result.latitude = this.latitude
			result.longitude = this.longitude
		} else {
			result.geoCodeReverse = false
			if (this.selectedLocationType === 'none') {
				result.address = null
				result.latitude = null
				result.longitude = null
			}
		}
		return result
	}

	constructor(dbSrvc: DatabaseService) {
		this.dbSrvc = dbSrvc
	}

	public resetAddress() {
		this.address = new LocationAddress()
	}

	public gpsUpdated(position: any) {
		this.latitude = position?.latitude ?? null
		this.longitude = position?.longitude ?? null
		log('Updated Job Site lat/lon', this.latitude, this.longitude)
	}

	public setupManager(config: EditFormJobSiteLocationManagerConfig) {
		log('Manage Config', config)
		// Setup the address
		this.address = new LocationAddress(config.locationAddress)

		// Setup position
		this.latitude = config.geoLatitude
		this.longitude = config.geoLongitude

		// Setup address by pin option
		this.showMapViewOption = config.enableAddressByMapPin

		// Setup location type
		log('GeoCoded Info', config.geoCoded)
		if (config.geoCodeReverse) {
			this.selectedLocationType = 'marker'
			this.enableMapView = true
			this.showMapViewOption = true
		} else {
			this.selectedLocationType = 'address'
			this.enableMapView = false
		}
	}

	public updateLocationType() {
		if (this.enableMapView) {
			this.selectedLocationType = 'marker'
		} else {
			this.selectedLocationType = 'address'
		}
	}

	// private makeAddressString(addr: EditFormJobSiteLocationAddress): string {
	// 	if (!addr.street) {
	// 		return null
	// 	}
	// 	let addressString = ''
	// 	if (addr.street) {
	// 		addressString += addr.street
	// 	}
	// 	if (addr.city) {
	// 		addressString += ', ' + addr.city
	// 	}
	// 	if (addr.postcode) {
	// 		addressString += ', ' + addr.postcode
	// 	}
	// 	return addressString
	// }
}
