import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { log } from '@app/helpers'
import { DialogManager, HelpDialogMessage, SkillRecord, UserSkillLinkRecord } from '@app/models'
import { CoreService } from '@app/services'

@Component({
    selector: 'app-user-skill-link-list',
    templateUrl: './user-skill-link-list.component.html',
    styleUrl: './user-skill-link-list.component.scss',
    standalone: false
})
export class UserSkillLinkListComponent implements OnInit {
	@Input() dialogManager: DialogManager
	@Output() skillLinkChange = new EventEmitter<boolean>()

	isLoading = true
	userId: number
	skills: Array<SkillLinkCard> = []

	hasGlobalAccount = false

	public priorityOptions = []
	public alwaysRouteOptions = [
		{ label: 'Yes', value: true },
		{ label: 'No', value: false },
	]
	constructor(private coreSrvc: CoreService) {
		this.priorityOptions = [null, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => ({ label: i ? i.toString() : 'None', value: i }))
		this.hasGlobalAccount = this.coreSrvc.dbSrvc.settingSrvc.hasGlobalAccount()
	}

	public ngOnInit(): void {
		this.userId = this.dialogManager.dialogData.userId
		this.loadData()
	}

	private loadData() {
		this.coreSrvc.dbSrvc.readTable('skill').then((success) => {
			this.skills = this.coreSrvc.dbSrvc.callCenterSrvc.getSkillRecords().map((skill) => new SkillLinkCard(skill, this.userId))
			this.isLoading = false
		})
	}

	public toggleCardSelection(card: SkillLinkCard) {
		card.selected = !card.selected
		const isActive = card.selected
		if (isActive) {
			card.skill.user_ids.push(this.userId)
		} else {
			card.skill.user_ids = card.skill.user_ids.filter((id) => id !== this.userId)
		}
		this.coreSrvc.dbSrvc.updateRecord('skill', card.skill).then((success) => {
			const skill = this.coreSrvc.dbSrvc.callCenterSrvc.getSkillRecordById(card.skill.id)
			card.updateCard(skill, this.userId)
			this.skillLinkChange.emit(true)
		})
	}

	public toggleCardExpansion(card: SkillLinkCard) {
		// if (!card.userSkill) {
		// 	this.coreSrvc.notifySrvc.notify('info', 'Not Allowed', 'This skill must be enabled before it can be configured.', 4)
		// 	return
		// }
		card.expanded = !card.expanded
	}

	public setPriority(link: UserSkillLinkRecord) {
		log('Set Priority', link)
		this.updateUserLinkRecord(link)
	}

	public setAlwaysRoute(link: UserSkillLinkRecord) {
		log('Set Always Route', link)
		this.updateUserLinkRecord(link)
	}

	public updateUserLinkRecord(link: UserSkillLinkRecord) {
		this.coreSrvc.dbSrvc.updateRecord('users_skill', link).then((success) => {
			log('Record updated', success)
		})
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'priority':
				help.header = 'Priority'
				help.message = `The highest priority is 1 and 10 is the lowest. When set to <b>None</b>, always ring this agent, regardless of other agent's priority settings.`
				break
			case 'always_route':
				help.header = 'Always Route'
				help.message = `When <b>Yes</b>, ring this agent even if they have an active call in progress (either inbound or C2C outbound).<br /><br />When <b>No</b> (the default), ring this agent only if they are not on an active call.`
				break
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}

class SkillLinkCard {
	userId: number
	skill: SkillRecord
	userSkill: UserSkillLinkRecord

	selected = false
	expanded = false

	constructor(skill: SkillRecord, userId: number) {
		this.updateCard(skill, userId)
	}

	get isSkillLinked(): boolean {
		return this.skill.user_ids.includes(this.userId)
	}

	updateCard(skill: SkillRecord, userId: number) {
		this.userId = userId
		this.skill = skill
		this.userSkill = this.skill.users_skill.find((us) => us.user_id === userId)
		if (this.userSkill) {
			this.expanded = true
			this.selected = true
		} else {
			this.expanded = false
			this.selected = false
		}
	}
}
