<div class="search-box">
	<input type="text" #searchInput class="form-control search-field" (keyup)="debounceSearch($event)" placeholder="filter survey blocks" />
	<div class="search-actions">
		<i *ngIf="!searchFilter.filterText" class="fa fa-search"></i>
		<i
			title="Clear Filter"
			*ngIf="searchFilter.filterText"
			class="fa fa-times-circle-o search-clear"
			[class.search-clear-btn-active]="searchFilter.filterText"
			(click)="searchInput.value = ''; clearFilter()"
		></i>
	</div>
</div>
