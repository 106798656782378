import { Component, OnInit } from '@angular/core'
import { log } from '@app/helpers'

@Component({
    selector: 'app-header-notification',
    templateUrl: './header-notification.component.html',
    styleUrls: ['./header-notification.component.scss'],
    standalone: false
})
export class HeaderNotificationComponent implements OnInit {
	hasNewNotifications = true
	headerNotifications: Array<HeaderNotificationItem> = []
	constructor() {}

	ngOnInit(): void {}

	loadData() {
		this.headerNotifications = notifications.map((n) => new HeaderNotificationItem(n))
	}

	linkClicked() {
		log('Link was clicked')
	}
}

type HeaderNotificationLinkType = 'INTERNAL' | 'EXTERNAL'

class HeaderNotificationItem {
	date: string
	subject: string
	summary: string
	linkUrl: string
	linkType: HeaderNotificationLinkType
	linkLabel: string
	newTab: boolean

	constructor(record) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
		}
	}
}

const notifications = [
	{
		date: 'Sat, Aug. 13 @ 4:55 PM',
		subject: 'New Time-Off Feature Added',
		summary: 'Employees can now request time off directly from the employee app. For more information, click the link below.',
		linkUrl: 'https://telephonetimesheets.com',
		linkType: 'EXTERNAL',
		linkLabel: 'more info',
		newTab: true,
	},
	{
		date: 'Fri, Aug. 12 @ 4:55 PM',
		subject: 'This is the notification subject',
		summary: 'This is the notification summary and would have more infor here. Probably truncate to a certain ...',
		linkUrl: 'https://telephonetimesheets.com',
		linkType: 'INTERNAL',
		linkLabel: 'more info',
		newTab: true,
	},
]
