import { Injectable } from '@angular/core'

import { EmployeeRecord, BulkActionManager, EmployeeSelectItem, SectionListCountManager, EmployeesViewManager, RecordTagContainer } from '@app/models'

import { DatabaseService } from '@app/services'

import { log } from '@app/helpers'

import _ from 'lodash' // Don't add lodash to angular-cli.json or it goes in global scope.
import { SelectItem } from 'primeng/api'
import { Subject } from 'rxjs'
import { BatchActionManager } from '@app/models/batch'

@Injectable({
	providedIn: 'root',
})
export class EmployeesService {
	dataLoaded = false
	list: EmployeeRecord[] = []
	// listChange = new EventEmitter<Array<EmployeeRecord>>()
	lastUpdated: Date

	employeenListUpdated = new Subject<boolean>()

	anyEmployee = new EmployeeRecord()
	multiEmployee = new EmployeeRecord()

	// Manage state for employee table display

	showActive = true
	// currentView: EmpTableViewState = EmpTableViewState.Active
	viewManager = new EmployeesViewManager()
	batchManager = new BatchActionManager('employee', 'ngBridgeEmpTable', null, null)

	warnings = {
		employeeScoreConfigInvalid: false,
	}

	listCountManager = new SectionListCountManager()

	bulkActionManager: BulkActionManager

	constructor() {
		log('Creating EmployeesService')

		// Setup the virtual Any Employee
		this.anyEmployee.id = 0
		this.anyEmployee.first = ' Any'
		this.anyEmployee.last = 'Employee'
		this.anyEmployee.name = ' Any Employee'

		// Setup the virtual Multiple Employees
		this.multiEmployee.id = 1
		this.multiEmployee.first = ' Multi'
		this.multiEmployee.last = 'Employee'
		this.multiEmployee.name = ' Multi Employee'

		// Customize the selected records header for the batch manager
		this.batchManager.generateRecordsSelectedHeader = () => {
			if (this.batchManager.selectedRecords.length === 0) return null
			const list = this.getAllEmployees()
			const activeSelected = list.filter((rec) => rec.active).filter((rec) => this.batchManager.selectedRecords.includes(rec.id)).length
			const inactiveSelected = list.filter((rec) => !rec.active).filter((rec) => this.batchManager.selectedRecords.includes(rec.id)).length
			return `${activeSelected} active / ${inactiveSelected} inactive`
		}
	}

	getCache() {
		const cache = {}
		for (const item of this.list) {
			cache[item.id] = item
		}
		return cache
	}

	getHomeTaxLocations(): Array<string> {
		const locations = this.list.filter((rec) => !!rec.home_tax_location).map((rec) => rec.home_tax_location)
		const result = [...new Set(locations)]
		return _.sortBy(result)
	}

	/**
	 * Returns alpha sorted list for primeng dropdown
	 */

	getEmployeeDropdown(dbSrvc: DatabaseService, isRestricted: boolean, isManager: boolean, includeIds: Array<number>): Array<EmployeeSelectItem> {
		const myUserId = dbSrvc.settingSrvc.getMyUserId()
		const managedIds = dbSrvc.settingSrvc.getManagedUserIds()
		const supId = isRestricted ? myUserId : null

		const employees = isRestricted && isManager ? this.getManagedAllEmployees(managedIds) : this.getAllEmployees(supId)
		const includeList = includeIds.map((id) => this.getEmployeeById(id)).filter((emp) => !!emp)

		// Include employees that are needed because they were set on the record by someone with unrestricted access
		includeList.forEach((emp) => {
			if (!employees.includes(emp)) {
				employees.push(emp)
			}
		})

		const dropdown = employees.map((emp) => {
			return {
				label: `${emp.active ? '' : '[INACTIVE] '}${emp.name}`,
				value: emp.id,
				data: emp,
			}
		})
		const sortedDropdown = _.sortBy(dropdown, 'label')
		return sortedDropdown
	}

	getEmploymentStatusDropdown(): Array<SelectItem> {
		return [
			{ label: 'Ongoing', value: null },
			{ label: 'Resigned', value: 'RESIGNED' },
			{ label: 'Terminated', value: 'TERMINATED' },
			{ label: 'Suspended', value: 'SUSPENDED' },
			{ label: 'Administrative Leave', value: 'ADMINISTRATIVE_LEAVE' },
			{ label: 'Personal Leave', value: 'PERSONAL_LEAVE' },
			{ label: 'FMLA Leave', value: 'FMLA_LEAVE' },
		]
	}

	// Replace with above version
	getDropdownData(supId?: number): any[] {
		log('Supervisor Id', supId)

		const dropdown = this.list
			.filter((e) => (supId ? e.supervisor === supId : true))
			.map((e) => e.id)
			.map((id) => {
				return {
					label: this.getEmployeeById(id).name,
					value: id,
				}
			})
		const sortedDropdown = _.sortBy(dropdown, 'label')
		sortedDropdown.unshift({ label: 'Select an Employee', value: null })
		return sortedDropdown
	}

	activeCount(supId?: number): number {
		return this.getActiveEmployees(supId).length
	}

	allCount(supId?: number): number {
		return this.getAllEmployees(supId).length
	}

	allManagedCount(supIds: Array<number>) {
		return this.getManagedAllEmployees(supIds).length
	}

	allManagedActiveCount(supIds: Array<number>) {
		return this.getManagedActiveEmployees(supIds).length
	}

	alertCount(): number {
		let count = 0
		this.list.forEach((emp) => {
			const name = emp.name || ''
			if (name.includes('AutoCreated')) {
				count++
			}
		})
		return count
	}

	/**
	 * Get a list of all employees
	 * @returns An array of employee records
	 */

	getAllEmployees(supId?: number): Array<EmployeeRecord> {
		const employees = _.sortBy(this.list, 'name')
		if (supId) {
			return employees.filter((emp) => emp.supervisor_ids.includes(supId))
		}
		return employees
	}

	getProfileNeedsUpdateRecords(): Array<EmployeeRecord> {
		return this.list.filter((emp) => emp.pfp_json && !emp.pfp_approved)
	}

	getManagedAllEmployees(supIds: Array<number>) {
		const employees = this.getAllEmployees()
		return employees.filter((emp) => supIds.some((id) => emp.supervisor_ids.indexOf(id) !== -1))
	}

	getActiveEmployees(supId?: number): Array<EmployeeRecord> {
		const employees = this.getAllEmployees().filter((emp) => emp.active === true)
		if (supId) {
			return employees.filter((emp) => emp.supervisor_ids.includes(supId))
		}
		return employees
	}

	getManagedActiveEmployees(supIds: Array<number>) {
		const employees = this.getAllEmployees().filter((emp) => emp.active === true)
		return employees.filter((emp) => supIds.some((id) => emp.supervisor_ids.indexOf(id) !== -1))
	}

	getInactiveEmployees(supId?: number): Array<EmployeeRecord> {
		const employees = this.getAllEmployees().filter((emp) => emp.active === false)
		if (supId) {
			return employees.filter((emp) => emp.supervisor_ids.includes(supId))
		}
		return employees
	}

	getManagedInctiveEmployees(supIds: Array<number>) {
		const employees = this.getAllEmployees().filter((emp) => emp.active === false)
		return employees.filter((emp) => supIds.some((id) => emp.supervisor_ids.indexOf(id) !== -1))
	}

	getEmployeeByEmailAddress(email: string) {
		return this.list.find((rec) => rec.email === email)
	}

	getEmployeeById(id: number): EmployeeRecord {
		if (id === 0) {
			return this.anyEmployee
		}
		if (id === 1) {
			return this.multiEmployee
		}
		const emp = this.list.find((e) => e.id === id)
		return emp ? emp : null
	}

	getEmployeesByGroupId(id: number): Array<EmployeeRecord> {
		return this.getAllEmployees().filter((emp) => emp.supervisor_group_id === id)
	}

	getEmployeeNameForId(id: number): string {
		const emp = this.getEmployeeById(id)
		return emp ? emp.name : 'Employee Missing'
	}

	getEmployeeForPhoneNumber(number: string): EmployeeRecord {
		const emp = this.list.find((e) => e.phone_number === number)
		return emp ? emp : null
	}

	getEmployeeForPhoneNumberE164(number: string): EmployeeRecord {
		const emp = this.list.find((e) => e.phone_number_e164 === number)
		return emp ? emp : null
	}

	// DEPRECATED 2023-01-25
	// getEmployeeForC2CE164Number(number: string): EmployeeRecord {
	// 	const emp = this.list.find((e) => e.phone_number_e164 === number || e.dispatch_phone_e164 === number)
	// 	return emp ? emp : null
	// }

	// Will return a name if matches an employee or the number itself
	getEmployeeNameForPhoneNumber(number: string): string {
		const emp = this.list.find((e) => e.phone_number === number)
		return emp ? emp.name : number
	}

	getEmployeeNameForPhoneNumberE164(number: string): string {
		const emp = this.list.find((e) => e.phone_number_e164 === number)
		return emp ? emp.name : number
	}

	getTagLabels(): Array<string> {
		let tags = []
		this.list.forEach((rec) => {
			const tagContainer = new RecordTagContainer(rec.tags_json)
			const parsedTags = tagContainer.tags.map((tag) => tag.label)
			tags = [...tags, ...parsedTags]
		})
		const unique = _.uniq(tags)
		tags = _.orderBy(unique)
		return tags
	}
	getTagDropdownData(includeTags: Array<string>): Array<SelectItem> {
		const currentTagLabels = this.getTagLabels()
		const labels = [...currentTagLabels, ...includeTags]
		const unique = _.uniq(labels)
		const sortedLabels = _.orderBy(unique)
		return sortedLabels.map((tagLabel) => ({
			label: tagLabel,
			value: tagLabel,
			data: { inUse: currentTagLabels.includes(tagLabel) ? true : false },
		}))
	}

	getDepartments(): Array<string> {
		const departments = this.list.filter((rec) => !!rec.department).map((rec) => rec.department)
		const result = [...new Set(departments)]
		return _.sortBy(result)
	}

	getDepartmentsDropdown(include: Array<string>): Array<SelectItem> {
		const departments = _.uniq([...include, ...this.getDepartments()]).sort()
		return departments.map((dept) => ({ label: dept, value: dept, data: null }))
	}

	// Takes a comma seperate list of phone numbers and replaces each number
	// with an employee name if it matches the employee phone number
	replaceNumbersWithEmpNames(numbers: string): string {
		if (!numbers) {
			return null
		}
		const tempArray = numbers.split(',')
		const result = tempArray.map((e) => this.getEmployeeNameForPhoneNumber(e))
		return result.join(',')
	}

	employeeNameForId(id: number): string {
		const emp = this.getEmployeeById(id)
		if (emp) {
			return emp.name
		}
		return 'Employee Not Found'
	}

	hasVoicePrintUsers(): boolean {
		const emps = this.getAllEmployees().filter((emp) => emp.voice_fingerprint)
		log('Voiceprint emps', emps)
		return emps.length > 0
	}

	clearData() {
		this.list = []
		this.dataLoaded = false
		this.employeenListUpdated.next(true)
	}

	makeEmployee(record: EmployeeRecord): EmployeeRecord {
		const emp = new EmployeeRecord(record)
		return emp
	}

	setEmployeeRecords(records: Array<EmployeeRecord>) {
		this.lastUpdated = new Date()
		this.list = records.map((c) => this.makeEmployee(c))
		this.dataLoaded = true
		this.employeenListUpdated.next(true)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateEmployeeRecords(records: Array<EmployeeRecord>) {
		this.lastUpdated = new Date()
		const newRecords = records.map((rec) => this.makeEmployee(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.list.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.list.push(newRecord)
			}
		}
		this.employeenListUpdated.next(true)
	}

	removeLocalEmployeeRecord(recordId: number) {
		if (!recordId || recordId === 0 || recordId === 1) return // Don't want to remove the Any Employee or Multi-Employee
		this.list = this.list.filter((rec) => rec.id !== recordId)
	}
}
