import { Component, OnInit, OnDestroy, AfterViewInit, Renderer2 } from '@angular/core'
import {
	UserRecord,
	CrudAction,
	DialogManager,
	DatabaseTableName,
	LocalPrefsData,
	LocalPrefsGroup,
	LocalPrefsDialogData,
	ClickToCallRecord,
	ClickToCallGlobalConfig,
	AuditActionEvent,
	ComponentBridgeName,
} from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { log, DisplayHelper, TableActionFormatter, DateTimeHelper, GeneralTableHelper, DataTablesHelper } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'
import { Subscription } from 'rxjs'

import _ from 'lodash'
import moment from 'moment'
import { SupervisorUsersFormatter } from '../users-formatter'
import { ParsedNumber, isValidNumber, parseNumber } from 'libphonenumber-js'

enum UserTableColumnIndex {
	id = 0,
	role,
	firstName,
	lastName,
	manager,
	skills,
	companies,
	employees,
	jobSites,
	jobs,
	profiles,
	groups,
	rolePerms,
	phone,
	email,
	dispatch,
	admin,
	notify,
	lastLogin,
	lastActive,
	actions,
}

@Component({
    selector: 'app-user-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    standalone: false
})
export class UserTableComponent implements OnInit, OnDestroy, AfterViewInit {
	bridgeName: ComponentBridgeName = 'ngBridgeUserTable'
	accessHelper: AccessHelper

	list: Array<UserRecord> = []
	isDataLoaded = false
	isInternalUser = false
	isPrimaryOrInternalUser = false
	isPrimaryInternalOrManager = false
	highlightMissingExternalIds = false
	showCompaniesColumn = false
	isManagerRoleAvailable = false
	canConfigurePrefs = false

	linkedRecordsDialogManager = new DialogManager('linkedRecordsModal')
	linkedRecordsDialog = { records: [], filteredRecords: [], details: null }

	userSkillDialogManager = new DialogManager('userSkillModal')
	transferOwnershipDialogManager = new DialogManager('transferOwnershipModal')
	transferOwnershipDialog = { isUpdating: false, sourceName: '', sourceId: null, destId: null, userList: [] }

	editDialogManager = new DialogManager('editUserModal')
	editAction = { recordId: null, action: 'edit' }

	deleteAction = { tableName: 'users' as DatabaseTableName, recordId: null, recordLabel: null, showDeleteRecordDialog: false }
	viewAuditAction = { recordId: null, isAuditTypePickerVisible: false }
	adminPrefsDialog = { group: 'user', userId: null, header: '', footer: '', show: false }
	adminPermsDialog = { userId: null, header: '', footer: '', saveBtnVisible: true, show: false }

	showDetailsModal = false
	showFilterDatesModal = false
	showDiffRecordsModal = false

	isManager = false
	managedUserIds = []

	private generalTableHelper: GeneralTableHelper
	private clickToCallEnabled = { backend: false, browser: false } // Keep this. Need availability check here for buuttons

	private userTable: any
	private defaultSortOrder = [
		[UserTableColumnIndex.role, 'asc'],
		[UserTableColumnIndex.firstName, 'asc'],
	]
	private defaultPageLength = 100

	debounceRedrawTable = _.debounce(this.redrawTable, 300)

	public sectionPrefsDialogManager = new DialogManager('sectionPrefsDialog')

	private subs = new Subscription()

	constructor(
		private coreSrvc: CoreService,
		private renderer: Renderer2,
	) {
		this.setupSubscriptions()
		this.setupC2CAvailability()
		this.setupAccessPermissions()
		this.setupComponent()
		this.setupLocalPrefsDialog()
	}

	get dtFilterText(): string {
		const input = $('.search-field-input')
		return (input?.val() as string) || ''
	}

	get canAccessC2CBtns(): boolean {
		return this.clickToCallEnabled.backend || this.clickToCallEnabled.browser
	}

	ngOnInit() {
		window[this.bridgeName] = this
		this.loadData()
	}

	ngOnDestroy() {
		this.userTable['fixedHeader'].disable()
		window[this.bridgeName] = null
		this.subs.unsubscribe()
	}

	ngAfterViewInit() {
		this.initTable()

		$('#userTable_filter input').attr('placeholder', ' filter')
		$('#userTable_filter input').addClass('search-field-input')

		setTimeout(() => {
			$('#clear-search-icon').detach().appendTo('#userTable_filter label')
		})

		// Handle count bubble search highlighting
		$('#userTable_filter input').on('keyup', () => {
			this.debounceRedrawTable()
		})

		this.fetchAndReloadData()
	}

	private setupSubscriptions() {
		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	private setupC2CAvailability() {
		const portalPrefs = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany()
		const backEndEnabled = this.coreSrvc.dbSrvc.settingSrvc.isClickToCallEnabled()

		// Setup Click to Call availability
		this.clickToCallEnabled.backend = backEndEnabled
		this.clickToCallEnabled.browser = portalPrefs.globalBrowserClickToCall
	}

	private setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'supervisor')
		this.accessHelper.updateSupervisorIds()
	}

	private setupComponent() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const myUserAdminPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		const user = this.coreSrvc.dbSrvc.settingSrvc.getMyUser()
		if (user.role === 'PRIMARY' || user.role === 'INTERNAL') {
			this.isPrimaryOrInternalUser = true
			this.isPrimaryInternalOrManager = true
		} else {
			this.isPrimaryOrInternalUser = false
		}
		if (user.role === 'INTERNAL') {
			this.isInternalUser = true
		}
		if (user.role === 'MANAGER') {
			this.isManager = true
			this.isPrimaryInternalOrManager = true
			this.managedUserIds = this.coreSrvc.dbSrvc.settingSrvc.getManagedUserIds()
		}

		// Check for missing ID highlight requirement
		this.highlightMissingExternalIds = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany().globalHighlightMissingExtId

		// Check to see if the companies column should be visible
		this.showCompaniesColumn = !!this.coreSrvc.dbSrvc.settingSrvc.getCompany().global_id

		// Is manager role available
		this.isManagerRoleAvailable = myUserAdminPrefs.userEnableManagerRole

		// Check for access to configure permissions
		this.canConfigurePrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs().userEnablePortalPrefs
	}

	public handleC2CEvent(id: number, column, action: 'CALL' | 'TEXT') {
		const record = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(id)
		let name = ''
		let number = ''
		if (column === 'MOBILE') {
			name = record.first_name + ' ' + record.last_name || 'Unknown'
			number = record.phone_e164
		}
		if (name && number) {
			const c2cRecord = new ClickToCallRecord('USERPHONE', name, null, number, null)
			c2cRecord.destUserId = id
			const config = new ClickToCallGlobalConfig(c2cRecord, action, null, null)
			this.coreSrvc.eventSrvc.clickToCallGlobalEvent.next(config)
		}
		log('handleC2CEvent', id, column, action, record)
	}

	private handleScreenSizeChanges() {
		if (this.userTable) {
			this.userTable.columns.adjust().responsive.recalc()
			this.userTable.fixedHeader.adjust()
		}
	}

	canPerformAction(action: CrudAction, isMyRecord: boolean) {
		return this.accessHelper.canPerformAction(action, isMyRecord)
	}
	isMyRecord(id: number): boolean {
		const myUserId = this.coreSrvc.dbSrvc.settingSrvc.getMyUserId()
		if (this.isPrimaryOrInternalUser) {
			return true
		}
		if (this.isManager) {
			return this.managedUserIds.includes(id) ? true : false
		}
		if (myUserId === id) {
			return true
		}
		return false
	}

	// Manage UI

	private setupLocalPrefsDialog(): void {
		this.sectionPrefsDialogManager.headerLabel = 'Preferences'
		const columnVisibilityItems = LocalPrefsData.getSupUserTableColumnDisplayPrefs()
		const columnVisibilityGroup = new LocalPrefsGroup('Column Visibility', columnVisibilityItems)
		const dialogData = new LocalPrefsDialogData([columnVisibilityGroup])
		dialogData.localStorageKeyRemovalList = ['DataTables_userTable']
		this.sectionPrefsDialogManager.dialogData = dialogData
	}

	public prefsDataSaved(): void {
		log('UpdatePrefsData')
		this.sectionPrefsDialogManager.isDialogVisible = false
		this.updateTable()
	}

	setupTransferOwnershipList() {
		const users = this.coreSrvc.dbSrvc.settingSrvc.getUsers().map((u) => {
			const label = u.first_name + ' ' + u.last_name
			const value = u.id
			return { label: label, value: value }
		})
		const sortedUsers = _.sortBy(users, 'label')
		sortedUsers.unshift({ label: 'Select Supervisor', value: null })
		this.transferOwnershipDialog.userList = sortedUsers
		log('Transfer Ownership Object', this.transferOwnershipDialog)
	}

	applySearchBoxHack() {
		$('#clear-search-icon').detach().appendTo('#userTable_filter label').show()
		$('#userTable_filter input').attr('placeholder', ' filter')
		$('#userTable_filter input').addClass('search-field-input')
	}

	clearSearch(): boolean {
		this.userTable
			.search('')
			.order([
				[UserTableColumnIndex.role, 'asc'],
				[UserTableColumnIndex.firstName, 'asc'],
			])
			.rows()
			.invalidate()
			.draw()
		return false
	}

	loadData() {
		this.list = this.accessHelper.getUserTableList().filter((u) => u.role !== 'INTERNAL')
		this.managedUserIds = this.coreSrvc.dbSrvc.settingSrvc.getManagedUserIds()
		// this.list = this.coreSrvc.dbSrvc.settingSrvc.getUsers()
	}

	fetchData(): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			this.coreSrvc.dbSrvc.bulkRead(['users', 'supervisor_group', 'supervisor_role']).then((result) => {
				this.coreSrvc.dbSrvc.settingSrvc.usersLastUpdated = new Date()
				resolve(true)
			})
		})
	}

	fetchAndReloadData() {
		this.fetchData().then((success) => {
			this.updateTable()
		})
	}

	redrawTable() {
		this.userTable?.rows().invalidate().search(this.dtFilterText).draw(true)
		this.coreSrvc.displaySrvc.enableAllTooltips()
	}

	public updateTable() {
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadData()
			this.updateColumns()
		})
	}

	private updateColumns(): void {
		const manager = this.isManagerRoleAvailable && this.coreSrvc.prefSrvc.data.supUsersColManagerVisible
		const skills = this.coreSrvc.prefSrvc.data.supUsersColSkillsPermVisible
		const companies = this.showCompaniesColumn && this.coreSrvc.prefSrvc.data.supUsersColCompaniesVisible
		const employees = this.coreSrvc.prefSrvc.data.supUsersColEmployeesVisible
		const jobSites = false // this.coreSrvc.prefSrvc.data.supUsersColJobSitesVisible
		const jobs = this.coreSrvc.prefSrvc.data.supUsersColJobSitesVisible
		const profiles = this.coreSrvc.prefSrvc.data.supUsersColProfilesVisible
		const groups = this.coreSrvc.prefSrvc.data.supUsersColGroupsVisible
		const rolePerms = this.coreSrvc.prefSrvc.data.supUsersColRolePermVisible
		const phone = this.coreSrvc.prefSrvc.data.supUsersColPhoneVisible
		const email = this.coreSrvc.prefSrvc.data.supUsersColEmailVisible
		const admin = this.coreSrvc.prefSrvc.data.supUsersColAdminVisible
		const notify = this.coreSrvc.prefSrvc.data.supUserColNotifyVisible
		const lastLogin = this.coreSrvc.prefSrvc.data.supUsersColLastLoginVisible
		const lastActive = this.coreSrvc.prefSrvc.data.supUsersColLastActiveVisible

		GeneralTableHelper.updateColumn(this.userTable, UserTableColumnIndex.manager, manager)
		GeneralTableHelper.updateColumn(this.userTable, UserTableColumnIndex.skills, skills)
		GeneralTableHelper.updateColumn(this.userTable, UserTableColumnIndex.companies, companies)
		GeneralTableHelper.updateColumn(this.userTable, UserTableColumnIndex.employees, employees)
		GeneralTableHelper.updateColumn(this.userTable, UserTableColumnIndex.jobSites, jobSites)
		GeneralTableHelper.updateColumn(this.userTable, UserTableColumnIndex.jobs, jobs)
		GeneralTableHelper.updateColumn(this.userTable, UserTableColumnIndex.profiles, profiles)
		GeneralTableHelper.updateColumn(this.userTable, UserTableColumnIndex.groups, groups)
		GeneralTableHelper.updateColumn(this.userTable, UserTableColumnIndex.rolePerms, rolePerms)
		GeneralTableHelper.updateColumn(this.userTable, UserTableColumnIndex.phone, phone)
		GeneralTableHelper.updateColumn(this.userTable, UserTableColumnIndex.email, email)
		GeneralTableHelper.updateColumn(this.userTable, UserTableColumnIndex.admin, admin)
		GeneralTableHelper.updateColumn(this.userTable, UserTableColumnIndex.notify, notify)
		GeneralTableHelper.updateColumn(this.userTable, UserTableColumnIndex.lastLogin, lastLogin)
		GeneralTableHelper.updateColumn(this.userTable, UserTableColumnIndex.lastActive, lastActive)

		this.userTable.clear()
		this.userTable.rows.add(this.list)
		this.userTable.draw(false)
	}

	createRecord() {
		if (!this.canPerformAction(CrudAction.create, null)) {
			this.accessHelper.notifyOperationNotAuthorized()
			return
		}
		this.editAction.recordId = null
		this.editAction.action = 'new'
		this.editDialogManager.headerLabel = 'Add User'
		this.editDialogManager.isDialogVisible = true
	}

	// Bridge Methods
	editRecord(id: number) {
		const isMyRecord = this.isMyRecord(id)
		if (!this.canPerformAction(CrudAction.update, isMyRecord)) {
			this.accessHelper.notifyOperationNotAuthorized()
			return
		}
		const user = this.list.find((rec) => rec.id === id)
		if (user) {
			log('Got user', user)
			this.editAction.recordId = id
			this.editAction.action = 'edit'
			this.editDialogManager.headerLabel = `${user.first_name} ${user.last_name}`
			this.editDialogManager.isDialogVisible = true
		}
	}

	transferOwnership(id: number) {
		log('Reassign User', id)
		const sourceUser = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(id)
		this.setupTransferOwnershipList()
		this.transferOwnershipDialog.userList = this.transferOwnershipDialog.userList.filter((u) => u.value !== sourceUser.id)
		if (sourceUser) {
			this.transferOwnershipDialogManager.headerLabel = 'Transfer Ownership'
			this.transferOwnershipDialogManager.submitBtnLabel = 'Submit'
			this.transferOwnershipDialogManager.submitBtnAction = () => this.transferOwnershipSaveBtnClicked()
			this.transferOwnershipDialogManager.canSubmit = () => this.isTransferOwnershipSelectionValid()
			this.transferOwnershipDialog.isUpdating = false
			this.transferOwnershipDialog.sourceId = id
			this.transferOwnershipDialog.sourceName = sourceUser.first_name + ' ' + sourceUser.last_name
			this.transferOwnershipDialogManager.isDialogVisible = true
		}
	}

	isTransferOwnershipSelectionValid() {
		return !!this.transferOwnershipDialog.destId
	}

	transferOwnershipSaveBtnClicked() {
		log('Transfer ownership save button clicked.')
		this.transferOwnershipDialog.isUpdating = true
		const sourceId = this.transferOwnershipDialog.sourceId
		const destId = this.transferOwnershipDialog.destId
		const request = { table: 'bogus', operation: 'transfer_user', src_id: `${sourceId}`, dest_id: `${destId}` }
		const sourceUser = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(sourceId)
		const destUser = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(destId)
		log('Transfer Request', request)
		log('Transfering From/To', sourceUser, destUser)
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			log('Lambda Result', result)
			const tables: Array<DatabaseTableName> = ['employee', 'location', 'users']
			this.coreSrvc.dbSrvc.bulkRead(tables).then((bulkReadResult) => {
				this.loadData()
				this.updateTable()
				this.transferOwnershipDialogManager.isDialogVisible = false
				this.transferOwnershipDialog.isUpdating = false
			})
		})
	}

	userUpdated() {
		// log('User record updated')
		// this.editAction.showDialog = false
		this.editDialogManager.isDialogVisible = false
		this.coreSrvc.dbSrvc.readTable('users').then(() => {
			this.loadData()
			this.updateTable()
		})
	}

	deleteRecord(id: number) {
		const isMyRecord = this.isMyRecord(id)
		const myUserId = this.coreSrvc.dbSrvc.settingSrvc.getMyUserId()
		if (!this.canPerformAction(CrudAction.delete, isMyRecord)) {
			this.accessHelper.notifyOperationNotAuthorized()
			return
		}
		const userToDelete = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(id)
		const myActualUser = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()

		// Check to make sure user isn't deleting themselves
		if (userToDelete.id === myUserId) {
			this.coreSrvc.notifySrvc.notify('error', 'Invalid Action', 'Deleting yourself is not allowed. Please contact support for assistance.')
			return
		}

		// Check to make sure only primary or internal users are deleting primary users
		if (userToDelete.role === 'PRIMARY') {
			if (!(myActualUser.role === 'PRIMARY' || myActualUser.role === 'INTERNAL')) {
				this.coreSrvc.notifySrvc.notify(
					'error',
					'Invalid Action',
					'Deleting a primary user is not allowed. Please contact support for assistance.',
				)
				return
			}
		}

		// Check to make sure managing user isn't deleting another manager
		if (this.isManager) {
			if (userToDelete.role === 'MANAGER') {
				this.coreSrvc.notifySrvc.notify(
					'error',
					'Invalid Action',
					'Only a primary supervisor may delete a managing supervisor. Please contact your administrator for assistance.',
				)
				return
			}
			if (userToDelete.managed_by !== myUserId) {
				this.coreSrvc.notifySrvc.notify('error', 'Invalid Action', 'A supervising manager may only delete their assigned supervisors.')
				return
			}
		}

		log('Invite ID', userToDelete.id)
		if (userToDelete) {
			this.deleteAction.recordId = userToDelete.id
			this.deleteAction.recordLabel = userToDelete.first_name + ' ' + userToDelete.last_name
			this.deleteAction.tableName = 'users'
			this.deleteAction.showDeleteRecordDialog = true
		}
	}

	deleteActionComplete() {
		const tables: Array<DatabaseTableName> = ['employee', 'location', 'users', 'company_globals']
		this.coreSrvc.dbSrvc.bulkRead(tables).then((readResult) => {
			this.loadData()
			this.updateTable()
			this.deleteAction.showDeleteRecordDialog = false
		})
	}

	editPrefs(id: number) {
		const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(id)
		this.adminPrefsDialog.userId = id
		this.adminPrefsDialog.header = 'Portal Preferences'
		this.adminPrefsDialog.footer = `${user.first_name} ${user.last_name}`
		this.adminPrefsDialog.show = true
	}

	prefsSaved() {
		this.adminPrefsDialog.show = false
		this.loadData()
		this.updateTable()
	}

	editPermissions(id: number) {
		const isMyRecord = this.isMyRecord(id)
		if (!this.isPrimaryInternalOrManager) {
			this.accessHelper.notifyOperationNotAuthorized()
			return
		}
		if (!this.canPerformAction(CrudAction.update, isMyRecord)) {
			this.accessHelper.notifyOperationNotAuthorized()
			return
		}
		const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(id)
		this.adminPermsDialog.userId = user.id
		this.adminPermsDialog.header = `${user.first_name} ${user.last_name}`
		this.adminPermsDialog.footer = `${user.first_name} ${user.last_name}`

		this.adminPermsDialog.saveBtnVisible = !user.supervisor_role_id
		this.adminPermsDialog.show = true
	}

	permissionsSaved() {
		log('Permissions saved')
		this.coreSrvc.dbSrvc.readTable('users').then(() => {
			this.loadData()
			this.updateTable()
			this.adminPermsDialog.show = false
		})
	}

	hasUserPrefs(userId: number) {
		const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(userId)
		if (user) {
			return user.admin_prefs_json ? true : false
		}
	}

	deleteActionCancel() {
		this.deleteAction.showDeleteRecordDialog = false
	}

	public filterLinkedRecords(searchText: string) {
		// log('Search', searchText)
		if (!searchText) {
			this.linkedRecordsDialog.filteredRecords = [...this.linkedRecordsDialog.records]
		} else {
			this.linkedRecordsDialog.filteredRecords = this.linkedRecordsDialog.records.filter((rec: string) => rec.toLowerCase().includes(searchText))
		}
	}

	openAuditLogPicker(id: number) {
		this.viewAuditAction.recordId = id
		let isCallCenterEnabled = false

		const hasGlobal = this.coreSrvc.dbSrvc.settingSrvc.hasGlobalAccount()
		if (hasGlobal) {
			isCallCenterEnabled = this.coreSrvc.dbSrvc.settingSrvc.getGlobalCompany()?.cc_enable
		} else {
			isCallCenterEnabled = this.coreSrvc.dbSrvc.settingSrvc.getCompany()?.cc_enable
		}

		// If call center is enabled, show the pop up to pick either audit log or call center activity for the user

		if (isCallCenterEnabled) {
			this.viewAuditAction.isAuditTypePickerVisible = true
		} else {
			const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(id)
			const footer = user ? `${user.first_name} ${user.last_name}` : null
			const auditActionEvent = new AuditActionEvent('users', id, footer)
			this.coreSrvc.eventSrvc.showAuditLog.next(auditActionEvent)
		}
	}

	viewAuditLog(type: 'CALLCENTER') {
		const viewType = type ? 'Call Center Activity' : 'Record Modification'
		this.viewAuditAction.isAuditTypePickerVisible = false
		const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(this.viewAuditAction.recordId)
		const footer = user ? `${user.first_name} ${user.last_name}` : null
		setTimeout(() => {
			if (type) {
				const auditActionEvent = new AuditActionEvent('users', this.viewAuditAction.recordId, footer ? footer + ` / ${viewType}` : viewType)
				auditActionEvent.trackType = 'CALLCENTER'
				this.coreSrvc.eventSrvc.showAuditLog.next(auditActionEvent)
			} else {
				const auditActionEvent = new AuditActionEvent('users', this.viewAuditAction.recordId, footer ? footer + ` / ${viewType}` : viewType)
				this.coreSrvc.eventSrvc.showAuditLog.next(auditActionEvent)
			}
		}, 100)
	}

	public showLinkedRecords(id: number, type: string) {
		let headerLabel = ''
		switch (type) {
			case 'company':
				this.linkedRecordsDialog.details = null
				this.linkedRecordsDialog.records = this.coreSrvc.dbSrvc.settingSrvc.getGlobalCompaniesForUserId(id).map((c) => c.name)
				this.linkedRecordsDialog.filteredRecords = [...this.linkedRecordsDialog.records]
				headerLabel = 'Company Membership'
				break
			case 'employee':
				this.linkedRecordsDialog.details = null
				this.linkedRecordsDialog.records = this.coreSrvc.dbSrvc.empSrvc
					.getAllEmployees()
					.filter((emp) => emp.supervisor_ids.includes(id) && emp.active)
					.map((emp) => emp.name)
				this.linkedRecordsDialog.filteredRecords = [...this.linkedRecordsDialog.records]
				headerLabel = 'Managed Employees'
				break
			case 'site':
				this.linkedRecordsDialog.details = null
				this.linkedRecordsDialog.records = this.coreSrvc.dbSrvc.siteSrvc
					.getAllJobSites()
					.filter((site) => site.supervisor === id)
					.map((site) => site.description)
				this.linkedRecordsDialog.filteredRecords = [...this.linkedRecordsDialog.records]
				headerLabel = 'Managed Job Sites'
				break
			case 'job':
				this.linkedRecordsDialog.details = null
				this.linkedRecordsDialog.records = this.coreSrvc.dbSrvc.jobSrvc
					.getAllJobs()
					.filter((job) => job.supervisor_ids.includes(id) && job.active)
					.map((job) => job.description)
				this.linkedRecordsDialog.filteredRecords = [...this.linkedRecordsDialog.records]
				headerLabel = 'Managed Jobs'
				break
			case 'profile':
				this.linkedRecordsDialog.details = null
				headerLabel = 'Linked Profiles'
				this.linkedRecordsDialog.records = this.coreSrvc.dbSrvc.settingSrvc
					.getLinkedProfilesForUserId(this.coreSrvc.dbSrvc, id)
					.map((np) => np.name)
				this.linkedRecordsDialog.filteredRecords = [...this.linkedRecordsDialog.records]
				break
			case 'group':
				this.linkedRecordsDialog.details = null
				headerLabel = 'Linked Groups'
				this.linkedRecordsDialog.records = this.coreSrvc.dbSrvc.settingSrvc
					.getLinkedGroupsForUserId(this.coreSrvc.dbSrvc, id)
					.map((grp) => grp.description)
				this.linkedRecordsDialog.filteredRecords = [...this.linkedRecordsDialog.records]
				break
			case 'skill':
				const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(id)
				if (user.role === 'NO_COGNITO') {
					this.coreSrvc.notifySrvc.notify('error', 'Not Allowed', 'Non-admin users cannot be assigned skills.')
					return
				}
				this.userSkillDialogManager.headerLabel = `${user.first_name} ${user.last_name}'s Skills`
				this.userSkillDialogManager.dialogData = { userId: id }
				this.userSkillDialogManager.isSubmitBtnVisible = false
				this.userSkillDialogManager.cancelBtnLabel = 'Close'
				this.userSkillDialogManager.isDialogVisible = true
				return
		}

		this.linkedRecordsDialogManager.headerLabel = headerLabel
		this.linkedRecordsDialogManager.isSubmitBtnVisible = false
		this.linkedRecordsDialogManager.cancelBtnLabel = 'Close'
		this.linkedRecordsDialogManager.isDialogVisible = true
	}

	public onSkillLinkChange() {
		log('onSkillLinkChange')
		const userId = this.userSkillDialogManager.dialogData.userId
		this.coreSrvc.dbSrvc.readRecord('users', userId).then((success) => {
			this.invalidateRowForTable(userId)
		})
	}

	private invalidateRowForTable(userId: number) {
		const user = this.list.find((rec) => rec.id === userId)
		const idx = this.list.indexOf(user)
		this.userTable.rows(idx).invalidate().draw(false)
	}

	// DataTables Initialization

	private initTable() {
		this.coreSrvc.displaySrvc.startSectionLoader()
		this.userTable = $('#userTable').DataTable(<DataTables.Settings>{
			stateSave: false,
			responsive: true,
			processing: true,
			paging: true,
			pageLength: this.defaultPageLength,
			lengthChange: true,
			info: true,
			select: false,
			searching: true,
			fixedHeader: DataTablesHelper.fixedHeader,
			autoWidth: false,
			data: this.list,
			order: this.defaultSortOrder,
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{
					responsivePriority: 1,
					targets: [UserTableColumnIndex.role, UserTableColumnIndex.firstName, UserTableColumnIndex.lastName, UserTableColumnIndex.phone],
				},
				{
					responsivePriority: 2,
					targets: [
						UserTableColumnIndex.companies,
						UserTableColumnIndex.employees,
						UserTableColumnIndex.jobSites,
						UserTableColumnIndex.profiles,
					],
				},
				{
					responsivePriority: 3,
					targets: [UserTableColumnIndex.actions],
				},
				{
					responsivePriority: 4,
					targets: [UserTableColumnIndex.email],
				},
				{
					// ID
					targets: UserTableColumnIndex.id,
					visible: false,
					searchable: true,
					title: 'ID',
					data: null,
					render: (rec: UserRecord, t, f, m) => {
						// Render
						return rec.id
					},
				},
				{
					// Type / ID
					targets: UserTableColumnIndex.role,
					visible: true,
					searchable: true,
					title: 'Type / ID',
					data: null,
					render: (rec: UserRecord, t, f, m) => {
						// Render
						const externalId = rec.external_id
							? `<div style="color:#537792;font-size:.8em;font-weight:bold;font-style:italic;">${rec.external_id}</div>`
							: ''
						const noExternalId =
							this.highlightMissingExternalIds && !rec.external_id ? `<div class="table-tag bg-chocolate">No ID Set</div>` : ''
						let role = ``
						switch (rec.role) {
							case 'PRIMARY':
								role = `<div style="display: none;">0</div><div style="color:green;font-weight:bold;">Primary</div>`
								break
							case 'MANAGER':
								role = '<div style="display: none;">1</div><div style="color:#135c94;font-weight:bold;">Manager</div>'
								break
							case 'SECONDARY':
								role = '<div style="display: none;">2</div><div style="color:slategray;font-weight:bold;">Secondary</div>'
								break
							case 'READONLY':
								role = '<div style="display: none;">3</div><div style="color:firebrick;font-weight:bold;">Read-Only</div>'
								break
							case 'NO_COGNITO':
								role = '<div style="display: none;">4</div><div style="color:firebrick;font-weight:bold;">Non-Admin</div>'
								break
							case 'INTERNAL':
								role = '<div style="display: none;">5</div><div style="color:chocolate;font-weight:bold;">Internal</div>'
								break
							default:
								role = '<div style="display: none;">6</div><div style="color:chocolate;font-weight:bold;">Unknown</div>'
						}

						return `
						<div class="dtr-control-content">
							<div style="white-space:normal;line-height:1.3em;min-width:100px"><div>${role}</div>
							${externalId}
							${noExternalId}
							</div>
						</div>`
					},
				},
				{
					// First Name
					targets: UserTableColumnIndex.firstName,
					visible: true,
					searchable: true,
					title: 'First Name',
					data: null,
					render: (rec: UserRecord, t, f, m) => {
						// Render
						return `<div style="white-space: normal">${rec.first_name}</div>`
					},
				},
				{
					// Last Name
					targets: UserTableColumnIndex.lastName,
					visible: true,
					searchable: true,
					title: 'Last Name',
					data: null,
					render: (rec: UserRecord, t, f, m) => {
						// Render
						return `<div style="white-space: normal">${rec.last_name}</div>`
					},
				},
				{
					// Manager
					targets: UserTableColumnIndex.manager,
					visible: this.isManagerRoleAvailable && this.coreSrvc.prefSrvc.data.supUsersColManagerVisible,
					searchable: true,
					title: 'Manager',
					data: null,
					render: (rec: UserRecord, t, f, m) => {
						// Render
						const managerId = rec.managed_by
						const manager = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(managerId)
						if (manager) {
							const managerName = manager.first_name + ' ' + manager.last_name
							return `<div style="white-space: normal">${managerName}</div>`
						}
						return ``
					},
				},
				{
					// Skills
					targets: UserTableColumnIndex.skills,
					visible: this.coreSrvc.prefSrvc.data.supUsersColSkillsPermVisible,
					searchable: false,
					title: 'Skills',
					data: null,
					render: (rec: UserRecord, t, f, m) => {
						// Render
						const result = SupervisorUsersFormatter.makeUsersLinkedRecordsButton(
							this.coreSrvc.dbSrvc,
							this.bridgeName,
							this.dtFilterText,
							rec.id,
							'skill',
						)

						const sortString = GeneralTableHelper.leftPadSortString(0, 3)

						return result
							? result
							: `${sortString}<button onclick="${this.bridgeName}.showLinkedRecords(${rec.id}, 'skill')" class="btn btn-sm btn-default table-modal-btn" style="width: 105px; color: #7c7c44">link skills</button>`

						// return this.makeLinkedRecordsButton(rec.id, 'site')
					},
				},
				{
					// Companies
					targets: UserTableColumnIndex.companies,
					visible: this.showCompaniesColumn && this.coreSrvc.prefSrvc.data.supUsersColEmployeesVisible,
					searchable: false,
					title: 'Companies',
					data: null,
					render: (rec: UserRecord, t, f, m) => {
						// Render
						return SupervisorUsersFormatter.makeUsersLinkedRecordsButton(
							this.coreSrvc.dbSrvc,
							this.bridgeName,
							this.dtFilterText,
							rec.id,
							'company',
						)
						// this.makeLinkedRecordsButton(rec.id, 'company')
					},
				},
				{
					// Employees
					targets: UserTableColumnIndex.employees,
					visible: this.coreSrvc.prefSrvc.data.supUsersColEmployeesVisible,
					searchable: false,
					title: 'Employees',
					data: null,
					render: (rec: UserRecord, t, f, m) => {
						// Render
						return SupervisorUsersFormatter.makeUsersLinkedRecordsButton(
							this.coreSrvc.dbSrvc,
							this.bridgeName,
							this.dtFilterText,
							rec.id,
							'employee',
						)
						// return this.makeLinkedRecordsButton(rec.id, 'employee')
					},
				},
				{
					// Job Sites
					targets: UserTableColumnIndex.jobSites,
					visible: false, // this.coreSrvc.prefSrvc.data.supUsersColJobSitesVisible,
					searchable: false,
					title: 'Job Sites',
					data: null,
					render: (rec: UserRecord, t, f, m) => {
						// Render
						return SupervisorUsersFormatter.makeUsersLinkedRecordsButton(
							this.coreSrvc.dbSrvc,
							this.bridgeName,
							this.dtFilterText,
							rec.id,
							'site',
						)
						// return this.makeLinkedRecordsButton(rec.id, 'site')
					},
				},
				{
					// Jobs
					targets: UserTableColumnIndex.jobs,
					visible: this.coreSrvc.prefSrvc.data.supUsersColJobsVisible,
					searchable: false,
					title: 'Jobs',
					data: null,
					render: (rec: UserRecord, t, f, m) => {
						// Render
						return SupervisorUsersFormatter.makeUsersLinkedRecordsButton(
							this.coreSrvc.dbSrvc,
							this.bridgeName,
							this.dtFilterText,
							rec.id,
							'job',
						)
						// return this.makeLinkedRecordsButton(rec.id, 'site')
					},
				},
				{
					// Profiles
					targets: UserTableColumnIndex.profiles,
					visible: this.coreSrvc.prefSrvc.data.supUsersColProfilesVisible,
					searchable: false,
					title: 'Profiles',
					data: null,
					render: (rec: UserRecord, t, f, m) => {
						// Render
						return SupervisorUsersFormatter.makeUsersLinkedRecordsButton(
							this.coreSrvc.dbSrvc,
							this.bridgeName,
							this.dtFilterText,
							rec.id,
							'profile',
						)
						// return this.makeLinkedRecordsButton(rec.id, 'profile')
					},
				},
				{
					// Groups
					targets: UserTableColumnIndex.groups,
					visible: this.coreSrvc.prefSrvc.data.supUsersColGroupsVisible,
					searchable: false,
					title: 'Groups',
					data: null,
					render: (rec: UserRecord, t, f, m) => {
						// Render
						return SupervisorUsersFormatter.makeUsersLinkedRecordsButton(
							this.coreSrvc.dbSrvc,
							this.bridgeName,
							this.dtFilterText,
							rec.id,
							'group',
						)
						// return this.makeLinkedRecordsButton(rec.id, 'group')
					},
				},
				{
					// Role Permissions
					targets: UserTableColumnIndex.rolePerms,
					visible: this.coreSrvc.prefSrvc.data.supUsersColRolePermVisible,
					searchable: false,
					title: 'Role',
					data: null,
					render: (rec: UserRecord, t, f, m) => {
						const role = this.coreSrvc.dbSrvc.settingSrvc.getUserRoleById(rec.supervisor_role_id)
						if (role) {
							const isSystemRole = role.id < 1000
							const name = role.description || ''
							const sortString = isSystemRole ? `~${role.id}` : role.description
							const sortHtml = `<div style="display:none">${sortString}</div>`
							const systemRoleHtml = isSystemRole ? `<div class="table-tag bg-sysjob" style="width:130px">System Role</div>` : ''
							return `
							<div>
								${sortHtml}
								${systemRoleHtml}
								<div style="min-width: 150px; line-height: 1.3em; white-space: normal">${name}</div>
							</div>`
						}
						return ''
					},
				},
				{
					// Phone
					targets: UserTableColumnIndex.phone,
					visible: this.coreSrvc.prefSrvc.data.supUsersColPhoneVisible,
					searchable: true,
					title: 'Phone',
					data: null,
					render: (rec: UserRecord, t, f, m) => {
						// Render
						// return DisplayHelper.displayPhone(rec.phone_e164)
						if (!rec.phone_e164) return '<span style="color: firebrick; font-weight: 700">Missing Number</span>'
						const number = rec.phone_e164
						const parsedNumber = parseNumber(number) as ParsedNumber
						const c2cButtons = this.canAccessC2CBtns
							? GeneralTableHelper.makeC2CButtons(rec.id, 'MOBILE', this.bridgeName, 'handleC2CEvent')
							: ''
						if (parsedNumber.country && isValidNumber(parsedNumber)) {
							return `
							<div>${DisplayHelper.displayPhone(number)}</div>
							${c2cButtons}
							`
						} else {
							return '<strong style="color: firebrick">' + number + '</strong>'
						}
					},
				},
				{
					// Email
					targets: UserTableColumnIndex.email,
					visible: this.coreSrvc.prefSrvc.data.supUsersColEmailVisible,
					searchable: true,
					title: 'Email',
					data: null,
					render: (rec: UserRecord, t, f, m) => {
						// Render
						return `<div class="table-email">${rec.email ? rec.email : ''}</div>`
					},
				},

				{
					// Dispatch
					targets: UserTableColumnIndex.dispatch,
					visible: this.coreSrvc.prefSrvc.data.supUsersColDispatchnVisible,
					searchable: false,
					title: 'Dispatch',
					data: null,
					render: (rec: UserRecord, t, f, m) => {
						// Render
						const isDispatcher = `<span style="display: none;">0</span><i class="far fa-check user-icon-checkban" style="color: green;;"></i>`
						const isNotDispatcher = `<span style="display: none;">1</span><i class="far fa-ban user-icon-checkban" style="color: firebrick;"></i>`
						return rec.dispatcher_active ? isDispatcher : isNotDispatcher
					},
				},
				{
					// Admin
					targets: UserTableColumnIndex.admin,
					visible: this.coreSrvc.prefSrvc.data.supUsersColAdminVisible,
					searchable: false,
					title: 'Admin',
					data: null,
					render: (rec: UserRecord, t, f, m) => {
						// Render
						const hasPortalAccess = `<span style="display: none;">0</span><i class="far fa-check user-icon-checkban" style="color: green;;"></i>`
						const noPortalAccess = `<span style="display: none;">1</span><i class="far fa-ban user-icon-checkban" style="color: firebrick;"></i>`
						return rec.role === 'NO_COGNITO' ? noPortalAccess : hasPortalAccess
					},
				},
				{
					// Notify
					targets: UserTableColumnIndex.notify,
					visible: this.coreSrvc.prefSrvc.data.supUserColNotifyVisible,
					searchable: false,
					title: 'Notify',
					data: null,
					render: (rec: UserRecord, t, f, m) => {
						// Render
						const shouldNotify = `<span style="display: none;">0</span><i class="far fa-check user-icon-checkban" style="color: green;"></i>`
						const shouldNotNotify = `<span style="display: none;">1</span><i class="far fa-ban user-icon-checkban" style="color: firebrick;"></i>`
						return rec.sms_email_enabled ? shouldNotify : shouldNotNotify
					},
				},
				{
					// Last Login
					targets: UserTableColumnIndex.lastLogin,
					visible: this.coreSrvc.prefSrvc.data.supUsersColLastLoginVisible,
					searchable: true,
					title: 'Last Login',
					data: null,
					render: (rec: UserRecord, t, f, m) => {
						// Render
						const sortText = rec.last_login ? rec.last_login : ''
						const sortHtml = `<div style="display:none">${sortText}</div>`
						const noLoginHtml = `<div><span style="font-size:.9em;font-weight:bold;color:chocolate;">&lt; no prior login &gt;</span></div>`
						if (!rec.last_login) {
							return noLoginHtml
						}
						const lastLoginDate = DateTimeHelper.mediumDateFromDateString(rec.last_login) // moment(rec.last_login).format('')
						const lastLoginHtml = `<div><span style="font-size:.9em;font-weight:bold;color:#135d94;">${lastLoginDate}</span></div>`
						return sortHtml + lastLoginHtml
					},
				},
				{
					// Last Active
					targets: UserTableColumnIndex.lastActive,
					visible: this.coreSrvc.prefSrvc.data.supUsersColLastLoginVisible,
					searchable: true,
					title: 'Last Active',
					data: null,
					render: (rec: UserRecord, t, f, m) => {
						// Render
						const sortText = rec.last_activity ? rec.last_activity : ''
						const sortHtml = `<div style="display:none">${sortText}</div>`
						const noLoginHtml = `<div><span style="font-size:.9em;font-weight:bold;color:chocolate;">&lt; no prior activity &gt;</span></div>`
						if (!rec.last_activity) {
							return noLoginHtml
						}
						const lastActiveDate = DateTimeHelper.mediumDateFromDateString(rec.last_activity)
						const lastActiveHtml = `<div><span style="font-size:.9em;font-weight:bold;color:#135d94;">${lastActiveDate}</span></div>`
						return sortHtml + lastActiveHtml
					},
				},
				{
					// Actions
					targets: UserTableColumnIndex.actions,
					visible: true,
					searchable: false,
					orderable: false,
					title: 'Actions',
					data: null,
					render: (rec: UserRecord, t, f, m) => {
						const recordId = rec.id
						const isRecForPrimary = rec.role === 'PRIMARY'
						const isRecForManager = rec.role === 'MANAGER'
						const isRecForNonAdmin = rec.role === 'NO_COGNITO'
						const isRecForPrimaryOrManager = isRecForPrimary || isRecForManager

						const isMyRecord = this.isMyRecord(rec.id)
						const hasRoleAssigned = !!rec.supervisor_role_id

						const canAccessEdit = this.accessHelper.canPerformAction(CrudAction.update, isMyRecord)
						const editLink = TableActionFormatter.editLink(this.bridgeName, 'editRecord', recordId, canAccessEdit)

						const canAccessTransferOwnership = isMyRecord && !isRecForPrimary && this.isPrimaryInternalOrManager
						const transferOwnershipLink = canAccessTransferOwnership
							? TableActionFormatter.transferOwnershipLink(this.bridgeName, 'transferOwnership', recordId, canAccessTransferOwnership)
							: ''

						const canAccessDelete = this.accessHelper.canPerformAction(CrudAction.delete, isMyRecord)
						const deleteLink = TableActionFormatter.deleteLink(this.bridgeName, 'deleteRecord', recordId, canAccessDelete)

						const hasPerms = !!rec.permissions_json
						const canAccessPerms = isMyRecord && this.isPrimaryInternalOrManager

						const isUserPrimaryOrInternal = this.isPrimaryOrInternalUser
						const isUserPrimaryOrInternalOrManager = this.isPrimaryInternalOrManager
						const permsLink =
							isRecForPrimary ||
							isRecForNonAdmin ||
							(isRecForPrimaryOrManager && !isUserPrimaryOrInternal) ||
							!isUserPrimaryOrInternalOrManager ||
							!canAccessPerms
								? ''
								: TableActionFormatter.userPermsLink(this.bridgeName, 'editPermissions', recordId, canAccessPerms, hasPerms, hasRoleAssigned)

						const canAccessPrefs = this.isInternalUser || this.canConfigurePrefs
						const hasPrefs = !!rec.admin_prefs_json
						const prefsLink = canAccessPrefs
							? TableActionFormatter.userPrefsLink(this.bridgeName, 'editPrefs', recordId, canAccessPrefs, hasPrefs)
							: ''

						const auditLink = TableActionFormatter.auditLink(this.bridgeName, 'openAuditLogPicker', recordId, true, !!rec.updated)
						// const auditLink = TableActionFormatter.auditLinkGlobal('users', recordId, rec.first_name + ' ' + rec.last_name, true, !!rec.updated)

						return `<span class="act-ico-wrap">${editLink}${deleteLink}${prefsLink}${auditLink}${transferOwnershipLink}${permsLink}</span>`
					},
				},
			],

			drawCallback: (settings) => {
				this.userTable?.columns.adjust().responsive.recalc()
				this.coreSrvc.displaySrvc.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
			},
		})
		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter('userTable', this.userTable)
	}
}
