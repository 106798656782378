import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ChecklistFormConfigCheckboxBasic, ChecklistFormItem, ChecklistFormItemType } from '@app/models'

@Component({
    selector: 'app-checklist-form-item-checkbox',
    templateUrl: './checklist-form-item-checkbox.component.html',
    styleUrls: ['./checklist-form-item-checkbox.component.scss'],
    standalone: false
})
export class ChecklistFormItemCheckboxComponent {
	@Input() item: ChecklistFormItem

	@Output() textResizer = new EventEmitter<HTMLTextAreaElement>()
	@Output() clearError = new EventEmitter<ChecklistFormItem>()
	@Output() showHelp = new EventEmitter<string>()

	get config(): ChecklistFormConfigCheckboxBasic {
		return this.item?.config as ChecklistFormConfigCheckboxBasic
	}
}
