import { log } from '@app/helpers'
import { EmployeeRecord, JobRecord, JobSiteRecord, UserRecord } from '@app/models'
import { ScheduleLogRecord } from '@app/models/schedule-log'
import { DatabaseService } from '@app/services'

import moment from 'moment-timezone'

export class SchedulerShiftViewEventActionsDialogManager {
	showDialog = false
	confirmCloseShift = false
	scheduleLog: ScheduleLogRecord = null
	clickInfo = null
	option: 'EMP' | 'JOB' = 'EMP'
}

type SchedulerEventActionsDialogViewStateView =
	| 'mainMenu'
	| 'extendedMenu'
	| 'assignEmployee'
	| 'splitShift'
	| 'confirmCancelOffer'
	| 'confirmClose'
	| 'confirmDelete'
	| 'processingView'
	| 'callingView'
	| 'globalC2CView'

// export class OrgContact {
// 	name: string
// 	phone: string
// 	constructor(name: string, phone: string) {
// 		this.name = name
// 		this.phone = phone
// 	}
// }

export class SchedulerEventActionsDialogShiftData {
	schedLog: ScheduleLogRecord

	isShiftOver: boolean
	isShiftAssigned: boolean
	isShiftEnabled: boolean
	isShiftOverridden: boolean
	isNotifyEnabled: boolean
	hasAuditLog: boolean

	empId: number
	emp: EmployeeRecord
	empName: string
	empPhoneE164: string
	empSupId: number
	empSup: UserRecord
	empSupName: string
	empSupPhoneE164: string
	origEmp: string
	jobId: number
	job: JobRecord
	jobName: string
	jobSiteId: number
	jobSite: JobSiteRecord
	jobSiteSupId: number
	jobSiteSup: UserRecord
	jobSiteSupName: string
	jobSiteSupPhoneE164: string

	constructor(dbSrvc: DatabaseService, schedLog: ScheduleLogRecord) {
		// Setup current shift. The order of processing is important because of potential overrides

		if (!schedLog) {
			return
		}
		this.schedLog = schedLog

		// Calculate isShiftOver
		const endTime = schedLog.job_end // schedLog.job_end_override ? schedLog.job_end_override : schedLog.job_end
		log('End TIme', endTime)
		const endMom = moment(endTime)
		if (moment().isAfter(endMom, 'day')) {
			this.isShiftOver = true
		} else {
			this.isShiftOver = false
		}

		// Calculate isShiftAssigned
		this.isShiftAssigned = schedLog.employee_id_override !== null ? true : false

		// Calculate isShiftEnabled
		this.isShiftEnabled = schedLog.enabled_override !== null ? schedLog.enabled_override : schedLog.enabled

		// Calculate isShiftOverridden
		this.isShiftOverridden = schedLog.employee_id_override !== null || schedLog.enabled_override !== null

		// Calculate isNotifyEnabled
		this.isNotifyEnabled = !!schedLog.enable_notifications

		// Check to see if record has been updated
		const isInternalUser = dbSrvc.settingSrvc.isInternalUser()
		this.hasAuditLog = isInternalUser && !!schedLog.updated

		// Calculate Employee Info
		this.empId = this.isShiftAssigned ? schedLog.employee_id_override : schedLog.employee_id
		this.emp = dbSrvc.empSrvc.getEmployeeById(this.empId)
		this.empName = this.emp ? this.emp.name : 'Employee Not Found'
		this.empPhoneE164 = this.emp?.phone_number_e164
		this.empSupId = this.emp?.supervisor
		this.empSup = dbSrvc.settingSrvc.getUserForId(this.empSupId)
		this.empSupName = this.empSup ? this.empSup.first_name + ' ' + this.empSup.last_name : 'User Not Found'
		this.empSupPhoneE164 = this.empSup ? this.empSup.phone_e164 : null
		this.origEmp =
			schedLog.employee_id_override || schedLog.employee_id_override === 0 ? dbSrvc.empSrvc.getEmployeeById(schedLog.employee_id)?.name : ''

		// Calculate Job Info
		this.jobId = schedLog.job_id
		this.job = dbSrvc.jobSrvc.getJobById(this.jobId)
		this.jobName = schedLog.job_description

		// Calculate Job Site Info
		this.jobSiteId = schedLog.jobsite_id
		this.jobSite = dbSrvc.siteSrvc.getJobSiteById(this.jobSiteId)
		this.jobSiteSupId = schedLog.jobsite_supervisor_id
		this.jobSiteSup = dbSrvc.settingSrvc.getUserForId(this.jobSiteSupId)
		this.jobSiteSupName = schedLog.jobsite_supervisor_name
		this.jobSiteSupPhoneE164 = this.jobSiteSup?.phone_e164
	}
}

export class SchedulerEventActionsDialogViewState {
	mainMenu = true
	option: 'EMP' | 'JOB' | 'ORG' = 'EMP'
	extendedMenu = false
	assignEmployee = false
	splitShift = false
	confirmCancelOffer = false
	confirmClose = false
	confirmDelete = false
	processingView = false

	canAccessCallBtn = false

	reset() {
		this.mainMenu = false
		this.extendedMenu = false
		this.assignEmployee = false
		this.splitShift = false
		this.confirmCancelOffer = false
		this.confirmClose = false
		this.confirmDelete = false
		this.processingView = false
	}

	switchTo(view: SchedulerEventActionsDialogViewStateView) {
		this.reset()
		this[view] = true
	}

	toggleOption(opt: 'EMP' | 'JOB' | 'ORG') {
		this.option = opt
	}
}
