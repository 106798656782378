<p-dialog
	styleClass="p-dialog-noheader"
	header="Delete Confirmation"
	appendTo="body"
	[(visible)]="showDeleteRecordDialog"
	[modal]="false"
	[baseZIndex]="10000"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="false"
	[focusOnShow]="false"
	(onHide)="deleteActionCancelled()"
>
	<div *ngIf="!deleteInProgress">
		<div *ngIf="recordLabel && !showConfirmation" class="record-label">{{ recordLabel }}</div>
		<div *ngIf="showConfirmation" class="record-label">Confirmation Needed</div>
		<div *ngIf="!showConfirmation" class="dialog-copy">Are you sure you want to delete this {{ itemType }}? This action cannot be undone.</div>
		<div *ngIf="showConfirmation" class="dialog-copy">
			<div [innerHtml]="confirmationMsg | sanitizer"></div>
		</div>
		<div class="delete-buttons">
			<button class="btn btn-secondary btn-sm dialog-btn" (click)="deleteActionCancelled()">Cancel</button>
			<button *ngIf="!showConfirmation" class="btn btn-danger btn-sm dialog-btn float-right" (click)="confirmDeleteButtonClicked()">Delete</button>
			<button *ngIf="showConfirmation" class="btn btn-success btn-sm dialog-btn float-right" (click)="confirmDeleteButtonClicked()">
				Confirm
			</button>
		</div>
	</div>
	<div class="delete-in-progress" *ngIf="deleteInProgress"><i class="fas fa-loader fa-spin"></i> Deleting Record...</div>
</p-dialog>
