import { DatabaseTableName } from '@app/models/table'

// The following interfaces define the objects passed into and
// received from the lambda invocation.

export interface ILambdaParams {
	FunctionName: string // Lambda function name
	Payload: string // Stringified version of IDataAccessLambdaPayload
}

export interface IDataAccessLambdaPayload {
	table: string
	operation: string
}

export interface IDataAccessLambdaResult {
	payload: IDataAccessLambdaPayload
	data: any
}

// This is a generic lambda request object that can be used to read or write
// table data. If using for a write then the record is included. Since everything
// gets flattened into the payload, anything in the record will overwrite an
// identical value in options. All values are stringified to make back end happy.

export class DataAccessRequest implements IDataAccessLambdaPayload {
	table: string
	operation: string

	constructor(tableName: DatabaseTableName, operation?: string, options?: any, record?: any) {
		this.table = tableName
		this.operation = operation ? operation : null
		if (options) {
			for (const attr in options) {
				if (options.hasOwnProperty(attr)) {
					this[attr] = options[attr] || options[attr] === 0 || options[attr] === false ? options[attr].toString() : ''
				}
			}
		}
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr] || record[attr] === 0 || record[attr] === false ? record[attr].toString() : ''
				}
			}
		}
	}
}

// The following types are helper objects to make constructing certain DataAccess
// lambda requests more convenient.

export class CompanyActivityLogDataAccessRequest implements IDataAccessLambdaPayload {
	table = 'company_activity_log'
	operation: string = null
	start_date: string // YYYY-MM-DD
	end_date: string // YYYY-MM-DD

	constructor(start_date: string, end_date: string) {
		this.start_date = start_date
		this.end_date = end_date
	}
}

export class TransactionDataAccessRequest implements IDataAccessLambdaPayload {
	table = 'transaction_log'
	operation: string
	rows: string
	loadIssues: boolean

	constructor(operation?: string, rows?: number, loadIssues?: boolean) {
		this.operation = operation ? operation : null
		this.rows = rows ? rows.toString() : null
		this.loadIssues = loadIssues ? loadIssues : false
	}
}

export class AuditLogDataAccessRequest implements IDataAccessLambdaPayload {
	table = 'audit_log'
	operation = null
	tz: string
	start_date: string
	end_date: string

	constructor(options?: Object) {
		for (const attr in options) {
			if (options.hasOwnProperty(attr)) {
				this[attr] = options[attr] || options[attr] === 0 ? options[attr].toString() : ''
			}
		}
	}
}

export class AnnouncementDataAccessRequest implements IDataAccessLambdaPayload {
	table = 'announcements'
	operation = null

	constructor(options?: Object) {
		for (const attr in options) {
			if (options.hasOwnProperty(attr)) {
				this[attr] = options[attr] || options[attr] === 0 ? options[attr].toString() : ''
			}
		}
	}
}

export class BreaktimeDataAccessRequest implements IDataAccessLambdaPayload {
	table = 'break_log'
	transaction_log_id: string
	operation = null

	constructor(transId: number) {
		this.transaction_log_id = transId.toString()
	}
}

export class IncidentDataAccessRequest implements IDataAccessLambdaPayload {
	table = 'incident_log'
	transaction_log_id: string
	operation = null

	constructor(transId: number) {
		this.transaction_log_id = transId.toString()
	}
}

export type IncidentReadDataAccessRequestType = 'JOB' | 'SITE' | 'TRANS'

export class IncidentReadDataAccessRequest implements IDataAccessLambdaPayload {
	table = 'incident_log'
	job_id: number = null
	job_site_id: number = null
	transaction_log_id: number = null
	operation = null

	constructor(id: number, type: IncidentReadDataAccessRequestType) {
		switch (type) {
			case 'JOB':
				this.job_id = id
				break
			case 'SITE':
				this.job_site_id = id
				break
			case 'TRANS':
				this.transaction_log_id = id
				break
		}
	}
}

export class IncidentUpdateDataAccessRequest implements IDataAccessLambdaPayload {
	table = 'incident_log'
	id: string
	operation = 'update'

	constructor(record?: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr] || record[attr] === 0 ? record[attr].toString() : ''
			}
		}
	}
}

export class CheckpointDataAccessRequest implements IDataAccessLambdaPayload {
	table = 'checkpoint'
	transaction_log_id: string
	operation = null

	constructor(transId: number) {
		this.transaction_log_id = transId.toString()
	}
}

export class BulkReadDataAccessRequest implements IDataAccessLambdaPayload {
	table = ''
	operation = 'bulkread'

	constructor(tables: Array<DatabaseTableName>) {
		this.table = tables.join(',')
	}
}

export class BulkReadDurationDataAccessRequest implements IDataAccessLambdaPayload {
	table = ''
	operation = 'bulkreadduration'
	duration = null

	constructor(tables: Array<DatabaseTableName>, duration: string) {
		this.table = tables.join(',')
		duration = duration
		this.operation = 'bulkreadduration'
	}
}

export class BulkReadWFromTableWithIds {
	table: DatabaseTableName
	recordIds: Array<number>

	constructor(table: DatabaseTableName, recordIds: Array<number>) {
		this.table = table
		this.recordIds = recordIds
	}
}

export class BulkReadWithIdsDataAccessRequest implements IDataAccessLambdaPayload {
	table = 'bogus'
	operation = 'bulkreadwithids'

	constructor(list: Array<BulkReadWFromTableWithIds>) {
		for (const item of list) {
			const table = item.table
			const records = item.recordIds.join(',')
			this[table] = records
			// delete this['table']
		}
	}
}

// This is the interface used for provisioning a phone number

export interface IProvisionCompanyPayload {
	username: string
	email: string
	first_name: string
	last_name: string
	company_name: string
	phone: string
	ref_tag
}

export interface IReportsPayload {
	operation: string
}
