import { DateTimeHelper, DisplayHelper, Helper, PhoneHelper } from '@app/helpers'
import { C2CDirectoryLookup, SMSLogRecord, ClickToCallSourceTypeFormatter } from '@app/models'
import { DatabaseService } from '@app/services'

import _ from 'lodash'
import moment from 'moment-timezone'

export class CommLogSMSTableFormatter {
	dbSrvc: DatabaseService
	directory: C2CDirectoryLookup

	constructor(dbSrvc: DatabaseService) {
		this.dbSrvc = dbSrvc
		this.directory = new C2CDirectoryLookup(dbSrvc)
	}

	date(date: string, tz: string) {
		return moment.tz(date, tz).format('YYYY-MM-DD')
	}

	time(date: string, tz: string) {
		const format12Hr = DateTimeHelper.format12Hour
		const mom = moment.tz(date, tz)
		return format12Hr ? mom.format('h:mm a') : mom.format('HH:mm')
	}

	callLookup(num: string) {
		const lookup = this.directory.lookupNumber(num, ['USER', 'EMPLOYEE', 'SYSTEM', 'LINKEDJOB'])
		let result = `
			<div>${lookup ? lookup.name : 'Unmatched Number'}</div>
			<div>${PhoneHelper.formatPhoneFromE164(num)}</div>
		`
		if (lookup) {
			const source = ClickToCallSourceTypeFormatter.getDescription(lookup.source)
			result += `
				<div class="tts-hl-2" style="font-weight: bold; font-size:small">${source}</div>
			`
		}
		return result
	}

	callTo(rec: SMSLogRecord) {
		const companyName = rec.company_name
		const number = rec.to
		const result = `
			<div>${companyName}</div>
			<div>${PhoneHelper.formatPhoneFromE164(number)}</div>
		`
		return result
	}

	status(rec: SMSLogRecord, tz: string) {
		let date = '',
			time = '',
			tooltip = '',
			error = ''

		if (rec.provider_last_update) {
			date = this.date(rec.provider_last_update, tz)
			time = this.time(rec.provider_last_update, tz)
			tooltip = `${date} @ ${time}`
		} else {
			tooltip = 'No Status Update'
		}

		let status = rec.provider_status ?? 'UNKNOWN'
		if (status === 'UNKNOWN' && rec.direction === 'IN') status = 'RECEIVED'
		const styleColor = status === 'RECEIVED' ? '#537792' : status === 'DELIVERED' ? 'green' : status === 'SENT' ? 'chocolate' : 'firebrick'

		if (status === 'UNDELIVERED') {
			const errorCode = rec.provider_error_code ?? ''
			if (errorCode.length === 5) {
				const code = parseInt(errorCode, 10)
				error = `<div><a href="https://www.twilio.com/docs/api/errors/${code}" target="twilioErrorCode">Error: ${code}</a></div>`
			}

			if (errorCode.length > 12) {
				error = `<div><a href="javascript:void(0);" onclick="alert('${rec.provider_error_code}')">Error Info</a></div>`
			}
		}

		const formattedStatus = DisplayHelper.capitalizeSingleWord(status)

		return status
			? `<div class="table-tag item-tooltip" title="${tooltip}" style="min-width:96px;background-color:${styleColor}">${formattedStatus}</div>${error}`
			: ''
	}
}
