import { Injectable, EventEmitter } from '@angular/core'
import { log } from '@app/helpers/logger'
import { HelpVideoRecord } from '@app/models'
import { Global } from '@app/models/global'

@Injectable({
	providedIn: 'root',
})
export class HelpVideoService {
	dataLoaded = false
	list: Array<HelpVideoRecord> = []
	listChange = new EventEmitter<Array<HelpVideoRecord>>()
	lastUpdated: Date

	constructor() {
		log('Creating HelpVideoService')
		// this.list = mockRecords.map((rec) => new HelpVideoRecord(rec))
	}

	clearData() {
		this.list = []
		this.dataLoaded = false
		this.lastUpdated = null
	}

	getHelpVideos(): Array<HelpVideoRecord> {
		return this.list
	}

	getHelpVideoById(id: number) {
		return this.list.find((rec) => rec.id === id)
	}

	getHelpVideoByKey(key: string) {
		return this.list.find((rec) => rec.key === key)
	}

	removeLocalHelpVideoRecord(recordId: number) {
		this.list = this.list.filter((rec) => rec.id !== recordId)
	}

	setHelpVideoRecords(records: Array<HelpVideoRecord>) {
		this.lastUpdated = new Date()
		this.list = records.map((rec) => new HelpVideoRecord(rec))
		this.dataLoaded = true
		this.listChange.next(this.list)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateHelpVideoRecords(records: Array<HelpVideoRecord>) {
		const newRecords = records.map((rec) => new HelpVideoRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.list.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.list.push(newRecord)
			}
		}
		this.dataLoaded = true
		this.lastUpdated = new Date()
		this.listChange.next(this.list)
	}
}

const mockRecords: Array<any> = [
	{ id: 1, key: 'employeeEditDialog', url: 'https://telephonetimesheets.com' },
	{ id: 2, key: 'jobSiteEditDialog', url: 'https://telephonetimesheets.com' },
	{ id: 3, key: 'jobEditDialog', url: 'https://telephonetimesheets.com' },
	{ id: 4, key: 'scheduleEditDialog', url: 'https://telephonetimesheets.com' },
	{ id: 5, key: 'notificationEditDialog', url: 'https://telephonetimesheets.com' },
	{ id: 6, key: 'reportEditDialog', url: 'https://telephonetimesheets.com' },
]
