import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { FileDropperInfo, FileDropperManager, FileUploaderProgress, FileUploadHelper } from '@app/models'
import { CoreService } from '@app/services'
import { log } from '@app/helpers'

import S3 from 'aws-sdk/clients/s3'

@Component({
    selector: 'app-file-dropper',
    templateUrl: './file-dropper.component.html',
    styleUrls: ['./file-dropper.component.scss'],
    standalone: false
})
export class FileDropperComponent {
	files: any[] = []
	processedFiles: Array<FileDropperInfo> = []
	currentFile: any

	@Input() fileDropperManager: FileDropperManager
	@Input() maxFileCount: number = null

	@Output() uploadProcessStarted = new EventEmitter<boolean>() // Emit event when batch process begins
	@Output() uploadComplete = new EventEmitter<FileDropperInfo>() // Emit even when a single file completes
	@Output() uploadProcessComplete = new EventEmitter<Array<FileDropperInfo>>() // Emit event when batch process completes
	@ViewChild('fileDropperRef', { static: false }) fileDropperEl: ElementRef

	constructor(private coreSrvc: CoreService) {}

	onFileDropped(files) {
		log('onFIlesDropped', files)
		this.prepareFilesList(files)
	}

	fileBrowseHandler(event: any) {
		log('fileBrowseHandler')
		const target = event.target as HTMLInputElement
		const files = target.files as unknown as Array<any>
		this.prepareFilesList(files)
	}

	prepareFilesList(files: Array<any>) {
		// Short circuit if an upload is in progress
		if (this.currentFile) return

		// Short circuit and notify if file limit exceeded
		if (this.maxFileCount && files.length > this.maxFileCount) {
			this.coreSrvc.notifySrvc.notify('error', 'Limit Exceeded', `The maximum number of files you may upload is ${this.maxFileCount}`)
			return
		}

		for (const item of files) {
			item.progress = 0
			this.files.unshift(item)
		}
		log('Beginning Files List', this.files)
		this.fileDropperEl.nativeElement.value = ''
		this.processedFiles = []
		this.uploadProcessStarted.next(true)
		this.uploadFiles()
	}

	formatBytes(bytes) {
		return FileUploadHelper.formatBytes(bytes)
	}

	uploadFiles() {
		const file = this.files.shift()
		log('Upload', file)
		if (file) {
			this.currentFile = file
			setTimeout(() => {
				// Allow time for progress bar to reset
				this.uploadFile(file).then((uploadInfo) => {
					log('Upload Result', uploadInfo)
					if (uploadInfo.success) {
						this.processedFiles.push(uploadInfo)
						this.postProcessFileUpload(uploadInfo)
					} else {
						this.postProcessFileUpload(uploadInfo)
						this.coreSrvc.notifySrvc.notify(
							'error',
							'Upload Error',
							`An error was encountered uploading ${uploadInfo.file.name} - Please contact support to report this issue.`,
						)
					}
					setTimeout(() => {
						this.uploadFiles()
					}, 500)
				})
			}, 1000)
		} else {
			log('Uploading complete')
			this.uploadsFinished()
		}
	}

	uploadFile(file: File): Promise<FileDropperInfo> {
		return new Promise((resolve, reject) => {
			const uploadFile = new FileDropperInfo(file)
			const params = this.fileDropperManager.getS3UploadParams(uploadFile)
			if (!params) resolve(uploadFile)
			const bucket = new S3()
			log('Upload Params', params)

			bucket
				.upload(params, (err, data) => {
					if (err) {
						// Encountered an error
						log('Upload Error', err)
						resolve(uploadFile)
					} else {
						// Upload successful
						uploadFile.success = true
						resolve(uploadFile)
					}
				})
				.on('httpUploadProgress', (evt) => {
					const progress = new FileUploaderProgress(evt.loaded, evt.total)
					this.updatePercentComplete(progress)
				})
		})
	}

	updatePercentComplete(progress: FileUploaderProgress) {
		const result = (progress.bytesSent / progress.bytesTotal) * 100
		this.currentFile.progress = Math.floor(result)
	}

	postProcessFileUpload(uploadInfo: FileDropperInfo) {
		log('Files Upload Component - postProcessFileUpload', uploadInfo)
		const name = uploadInfo.file.name
		this.uploadComplete.next(uploadInfo)
	}

	uploadsFinished() {
		this.files = []
		this.currentFile = null
		this.uploadProcessComplete.next(this.processedFiles)
	}
}
