<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<form class="edit-form-block" novalidate [formGroup]="form" autocomplete="off">
		<!-- Descriptions -->
		<div class="options-block" [class.input-required]="!form.get('description').value">
			<div class="options-title-inline">Description</div>
			<div *ngIf="!form.get('description').value" class="float-right">(required)</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="description" />
		</div>

		<!-- External ID -->
		<div class="options-block" [class.input-required]="!form.get('external_id').value">
			<div class="options-title-inline">External ID</div>
			<div *ngIf="!form.get('external_id').value" class="float-right">(required)</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="external_id" />
		</div>

		<!-- Allow clocking checkbox -->
		<div class="checkbox-item d-flex justify-content-between" style="margin-top: 28px">
			<div class="checkbox-wrap">
				<p-checkbox formControlName="allow_clocking" [binary]="true"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleCheckbox('allow_clocking')" class="has-pointer">Allow Clocking</strong>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('allow_clocking')"></i>
			</div>
		</div>

		<!-- Allow multiple clocking checkbox -->
		<div class="checkbox-item d-flex justify-content-between">
			<div class="checkbox-wrap">
				<p-checkbox formControlName="allow_multiple_clocking" [binary]="true"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleCheckbox('allow_multiple_clocking')" class="has-pointer">Allow Multiple Clocking</strong>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('allow_multiple_clocking')"></i>
			</div>
		</div>

		<!-- Allow labor level transfers checkbox -->
		<div class="checkbox-item d-flex justify-content-between">
			<div class="checkbox-wrap">
				<p-checkbox formControlName="allow_labor_level_transfers" [binary]="true"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleCheckbox('allow_labor_level_transfers')" class="has-pointer">Allow labor level transfers</strong>
				<i
					title="More Information"
					class="far fa-info-circle tts-info-btn"
					aria-hidden="true"
					(click)="showHelp('allow_labor_level_transfers')"
				></i>
			</div>
		</div>

		<!-- Allow work rule transfers checkbox -->
		<div class="checkbox-item d-flex justify-content-between">
			<div class="checkbox-wrap">
				<p-checkbox formControlName="allow_work_rule_transfers" [binary]="true"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleCheckbox('allow_work_rule_transfers')" class="has-pointer">Allow work rule transfers</strong>
				<i
					title="More Information"
					class="far fa-info-circle tts-info-btn"
					aria-hidden="true"
					(click)="showHelp('allow_work_rule_transfers')"
				></i>
			</div>
		</div>

		<!-- Allow pay code transactions checkbox -->
		<div class="checkbox-item d-flex justify-content-between">
			<div class="checkbox-wrap">
				<p-checkbox formControlName="allow_pay_code_transactions" [binary]="true"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleCheckbox('allow_pay_code_transactions')" class="has-pointer">Allow pay code transactions</strong>
				<i
					title="More Information"
					class="far fa-info-circle tts-info-btn"
					aria-hidden="true"
					(click)="showHelp('allow_pay_code_transactions')"
				></i>
			</div>
		</div>

		<!-- Enable call tracking checkbox -->
		<div class="checkbox-item d-flex justify-content-between">
			<div class="checkbox-wrap">
				<p-checkbox formControlName="enable_call_tracking" [binary]="true"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleCheckbox('enable_call_tracking')" class="has-pointer">Enable call tracking</strong>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('enable_call_tracking')"></i>
			</div>
		</div>

		<!-- Enable phone restrictions checkbox -->
		<div class="checkbox-item d-flex justify-content-between">
			<div class="checkbox-wrap">
				<p-checkbox formControlName="enable_phone_restrictions" [binary]="true"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleCheckbox('enable_phone_restrictions')" class="has-pointer">Enable phone restrictions</strong>
				<i
					title="More Information"
					class="far fa-info-circle tts-info-btn"
					aria-hidden="true"
					(click)="showHelp('enable_phone_restrictions')"
				></i>
			</div>
		</div>
	</form>
</div>
