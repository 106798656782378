import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { log } from '@app/helpers'
import { ClickToCallDialogManager, ClickToCallSourceConfig, ClickToCallDialogViewType, ClickToCallRecord, ClickToCallGlobalConfig } from '@app/models'
import { CoreService } from '@app/services'
import { ClickToCallDirectoryComponent } from './click-to-call-directory/click-to-call-directory.component'
import { Subscription } from 'rxjs'
import { ClickToCallEventLogComponent } from './click-to-call-event-log/click-to-call-event-log.component'
import { ClickToCallNotesComponent } from './click-to-call-notes/click-to-call-notes.component'

@Component({
    selector: 'app-click-to-call',
    templateUrl: './click-to-call.component.html',
    styleUrls: ['./click-to-call.component.scss'],
    standalone: false
})
export class ClickToCallComponent implements OnInit, OnDestroy {
	dialogInfoDetail = ''

	manager: ClickToCallDialogManager
	clickToCallEnabled = { backend: false, browser: false }
	canAccessCommLog = false

	@ViewChild('c2cDirectory', { static: true }) c2cDirectory: ClickToCallDirectoryComponent
	@ViewChild('c2cNotes', { static: true }) c2cNotes: ClickToCallNotesComponent
	@ViewChild('c2cEventLog', { static: true }) c2cEventLog: ClickToCallEventLogComponent

	subs = new Subscription()

	constructor(private coreSrvc: CoreService) {
		this.manager = this.coreSrvc.callSrvc.c2cManager
		this.subs.add(
			this.coreSrvc.eventSrvc.clickToCallEvent.subscribe((config) => {
				this.setupAvailability()
				this.resetComponents()
				this.processRequest(config)
				this.setupDialogInfoDetail()
			}),
		)
	}

	get isRefreshIconAvailable(): boolean {
		const currentView = this.manager?.currentView
		if (currentView === 'EVENTLOG') return true
		return false
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	private setupAvailability() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const portalPrefs = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany()

		// Setup Click to Call availability
		this.clickToCallEnabled.backend = this.coreSrvc.dbSrvc.settingSrvc.isClickToCallEnabled()
		this.clickToCallEnabled.browser = portalPrefs.globalBrowserClickToCall

		this.canAccessCommLog = this.canAccessAgentsComponent()
	}

	private canAccessAgentsComponent() {
		const isC2CEnabled = this.coreSrvc.dbSrvc.settingSrvc.isClickToCallEnabled()
		const isCallCenterEnabled = this.coreSrvc.dbSrvc.settingSrvc.isCallCenterEnabled()

		const permissions = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAccessPermissions().getUserPermissionsFor('ivr_call_log')
		const isAgentsOptionAllowed = permissions?.isOptionEnabledFor('CLGAAL') ?? false
		return isAgentsOptionAllowed // (isC2CEnabled || isCallCenterEnabled) && isAgentsOptionAllowed
	}

	resetComponents() {
		this.coreSrvc.dbSrvc.readTable('employee_login')
		this.manager.resetStatus()
		this.c2cDirectory.resetVm()
		this.c2cEventLog.resetVm()
		this.c2cNotes.resetVm()
	}

	dialogOnShow() {
		this.c2cDirectory.dialogOnShow()
		this.c2cNotes.dialogOnShow()
		this.c2cEventLog.dialogOnShow()
	}

	toggleViewState(state: ClickToCallDialogViewType) {
		this.manager.currentView = state
	}

	private processRequest(config: ClickToCallSourceConfig) {
		log('Got config', config)
		this.manager.config = config
		this.c2cDirectory.setupComponent()
		this.c2cNotes.setupComponent()
		this.manager.canDismiss = config.canDismiss
		this.manager.showDialog = true
	}

	private setupDialogInfoDetail() {
		const config = this.manager.config
		const type = config.type
		switch (type) {
			case 'TRANS':
				const transId = config.sourceId
				const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(transId)
				if (trans) {
					this.dialogInfoDetail = `${trans.employee_name} / ${trans.job_date}`
				}
		}
	}

	// Call Processing
	public setupCallEventStatus(source: ClickToCallRecord, dest: ClickToCallRecord) {
		this.manager.eventStatus.source = source
		this.manager.eventStatus.dest = dest
		this.manager.eventStatus.isPlacingCall = true
	}

	public triggerC2CEvent(c2cRecord: ClickToCallRecord, action: 'CALL' | 'TEXT') {
		if (c2cRecord) {
			const sourceConfig = this.manager.config
			const config = new ClickToCallGlobalConfig(c2cRecord, action, null, null)
			config.sourceConfig = sourceConfig
			this.coreSrvc.callSrvc.hideC2CTriggerHost()
			log('Config to global', config)
			this.coreSrvc.eventSrvc.clickToCallGlobalEvent.next(config)
		}
	}

	public placeCall(dest: ClickToCallRecord) {
		this.triggerC2CEvent(dest, 'CALL')
	}

	// Message Processing
	public startTextMsg(dest: ClickToCallRecord) {
		this.triggerC2CEvent(dest, 'TEXT')
	}

	refreshBtnClicked() {
		const currentView = this.manager.currentView
		switch (currentView) {
			case 'EVENTLOG':
				this.c2cEventLog.resetVm()
				this.c2cEventLog.isDataLoaded = false
				this.manager.eventStatus.isRefreshing = true
				this.c2cEventLog.setupComponent()
		}
	}

	private sendPostActionEvents() {
		const config = this.manager.config
		const type = config.type

		switch (type) {
			case 'TRANS':
				const transId = config.sourceId
				this.coreSrvc.dbSrvc.readRecord('transaction_log', transId).then((success) => {
					this.coreSrvc.dbSrvc.tranSrvc.recordNeedsRefresh.next(transId)
				})
		}
	}
}
