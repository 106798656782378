import { Directive, AfterViewInit, Input, OnInit } from '@angular/core'
import { CoreService } from '@app/services'
import { OverlayOptions } from 'primeng/api'
import { Select } from 'primeng/select'

@Directive({
	selector: '[appScrollLockSelect]',
	standalone: false,
})
export class AppScrollLockSelectDirective implements OnInit, AfterViewInit {
	@Input() ttsCenterOnShow = true
	// @Input() ttsMoveToTop = false

	constructor(
		private select: Select,
		private coreSrvc: CoreService,
	) {}
	ngOnInit(): void {
		// If it's not desktop, don't autofocus the filter option
		if (!this.coreSrvc.devDetect.isDesktop()) this.select.autofocusFilter = false
	}
	ngAfterViewInit() {
		const overlayOptions: OverlayOptions = {
			listener: (event, options) => options.type === 'outside',
		}
		// if (this.ttsMoveToTop && window.innerWidth < 600) {
		// 	overlayOptions.mode = 'modal'
		// 	overlayOptions.responsive = { direction: 'top' }
		// }
		this.select.overlayOptions = overlayOptions

		// If it's desktop and set to center on show, then setup the handler
		// if (this.coreSrvc.devDetect.isDesktop() && this.ttsCenterOnShow) this.select.onShow.subscribe(() => this.handleOnPanelShow())
		if (this.ttsCenterOnShow) this.select.onShow.subscribe(() => this.handleOnPanelShow())
	}

	handleOnPanelShow() {
		const elm = $('.p-overlay-content') as any
		setTimeout(() => {
			elm?.[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' })
		}, 125)
	}
}
