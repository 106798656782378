import { Injectable } from '@angular/core'

import { JobRecord, JobsViewManager, SectionListCountManager, JobSiteRecord, RecordTagContainer } from '@app/models'
import { JobSitesService } from '@app/services/backend/job-sites.service'

import { log } from '@app/helpers'
import _ from 'lodash' // Don't add lodash to angular-cli.json or it goes in global scope.
import { DatabaseService } from './database.service'
import { Global } from '@app/models/global'
import { BatchActionManager } from '@app/models/batch'
import { SelectItem } from 'primeng/api'

export interface JobSelectItem {
	label: string
	value: number
	data: JobRecord
}

@Injectable({
	providedIn: 'root',
})
export class JobsService {
	dataLoaded = false
	list: JobRecord[] = []
	lastUpdated: Date

	viewManager = new JobsViewManager()
	listCountManager = new SectionListCountManager()

	mockTours = {}

	batchManager = new BatchActionManager('job', 'ngBridgeJobTable', null, null)

	constructor(private siteSrvc: JobSitesService) {
		log('Creating JobsService')

		localStorage.removeItem('DataTables_jobsTable_/')
	}

	getCache() {
		const cache = {}
		for (const item of this.list) {
			cache[item.id] = item
		}
		return cache
	}

	hasSyncLockRecords(): boolean {
		return this.list.filter((rec) => rec.sync_lock).length > 0
	}

	getWorkTaxLocations(): Array<string> {
		const locations = this.list.filter((rec) => !!rec.work_tax_location).map((rec) => rec.work_tax_location)
		const result = [...new Set(locations)]
		return _.sortBy(result)
	}

	getTagLabels(): Array<string> {
		let tags = []
		this.list.forEach((rec) => {
			const tagContainer = new RecordTagContainer(rec.tags_json)
			const parsedTags = tagContainer.tags.map((tag) => tag.label)
			tags = [...tags, ...parsedTags]
		})
		const unique = _.uniq(tags)
		tags = _.orderBy(unique)
		return tags
	}

	getTagDropdownData(includeTags: Array<string>): Array<SelectItem> {
		const currentTagLabels = this.getTagLabels()
		const labels = [...currentTagLabels, ...includeTags]
		const unique = _.uniq(labels)
		const sortedLabels = _.orderBy(unique)
		return sortedLabels.map((tagLabel) => ({
			label: tagLabel,
			value: tagLabel,
			data: { inUse: currentTagLabels.includes(tagLabel) ? true : false },
		}))
	}

	getJobDropdown(
		dbSrvc: DatabaseService,
		includeSystemJobs: boolean,
		isRestricted: boolean,
		isManager: boolean,
		includeIds: Array<number>,
	): Array<JobSelectItem> {
		const myUserId = dbSrvc.settingSrvc.getMyUserId()
		const managedIds = dbSrvc.settingSrvc.getManagedUserIds()
		const supId = isRestricted ? myUserId : null

		const jobs = isRestricted && isManager ? this.getManagedAllJobs(managedIds) : this.getAllJobs(supId)
		const includeList = includeIds.map((id) => this.getJobById(id)).filter((job) => !!job)

		// Include jobs that are needed because they were set on the record by someone with unrestricted access
		includeList.forEach((job) => {
			if (!jobs.includes(job)) {
				jobs.push(job)
			}
		})

		let dropdown = jobs.map((job) => {
			return {
				label: `${job.active ? '' : '[INACTIVE] '}${job.description}`,
				value: job.id,
				data: job,
			}
		})

		// Prepend [System] to label if system jobs included or filter them out if not
		if (includeSystemJobs) {
			dropdown.forEach((dd) => {
				if (dd.data.company_default || dd.data.travel) {
					dd.label = ' [System] ' + dd.label
				}
			})
		} else {
			dropdown = dropdown.filter((dd) => !dd.data.company_default && !dd.data.travel)
		}

		const sortedDropdown = _.sortBy(dropdown, 'label')
		return sortedDropdown
	}

	// Old method - audit for removal
	getDropdownData(skipSystemJobs?: boolean, isMultiSelect?: boolean): Array<JobSelectItem> {
		const allJobs = this.getJobs()
		const unassignedJob = allJobs.find((j) => j.company_default)
		const unassignedJobMenuItem = this.makeDropDownItem(unassignedJob)
		const travelJobMenuItems = allJobs.filter((j) => j.travel).map((j) => this.makeDropDownItem(j))
		const nonSystemJobMenuItems = allJobs.filter((j) => !j.company_default && !j.travel).map((j) => this.makeDropDownItem(j))
		const selectJobMenuItem = { label: 'Select a Job', value: null, data: null }
		let dropdownMenuItems = [...nonSystemJobMenuItems]

		if (!skipSystemJobs) {
			dropdownMenuItems.push(unassignedJobMenuItem)
			dropdownMenuItems = [...dropdownMenuItems, ...travelJobMenuItems]
		}

		if (!isMultiSelect) {
			dropdownMenuItems.unshift(selectJobMenuItem)
		}
		return dropdownMenuItems
	}

	// Old method - audit for removal
	makeDropDownItem(job: JobRecord): JobSelectItem {
		return {
			label: this.getJobById(job.id).description,
			value: job.id,
			data: job,
		}
	}

	activeCount(siteIds: Array<number>): number {
		return this.getJobsForJobSiteIds(siteIds).filter((j) => j.active).length
	}
	inactiveCount(siteIds: Array<number>): number {
		return this.getJobsForJobSiteIds(siteIds).filter((j) => !j.active).length
	}
	allCount(siteIds: Array<number>): number {
		return this.getJobsForJobSiteIds(siteIds).length
	}
	allManagedCount(supIds: Array<number>) {
		return this.getManagedAllJobs(supIds).length
	}
	allManagedActiveCount(supIds: Array<number>) {
		return this.getManagedActiveJobs(supIds).length
	}

	alertCount(): number {
		let count = 0
		this.list.forEach((site) => {
			const description = site.description || ''
			if (description.includes('AUTOCREATED')) {
				count++
			}
		})
		return count
	}

	clearData() {
		this.list = []
		this.dataLoaded = false
	}

	formatSystemJobName(name: string) {
		if (name === 'UNASSIGNED JOB') return 'Unassigned Job'
		// if (name === 'W2W-TEMPLATE') return 'W2W Template'
		return name
	}

	isSystemJob(job: JobRecord): boolean {
		if (!job) return false
		const isTravelJob = job.travel
		const isUnassigned = job.description === 'UNASSIGNED JOB'
		// const isW2WTemplate = job.description === 'W2W-TEMPLATE'
		if (isTravelJob || isUnassigned) return true
		return false
	}

	isTravelJob(job: JobRecord): boolean {
		if (!job) return false
		return !!job.travel
	}

	getUnassignedJob(): JobRecord {
		return this.list.find((j) => j.company_default)
	}

	getTravelJobs(): Array<JobRecord> {
		return this.list.filter((j) => j.travel)
	}

	getTemplateJobs(): Array<JobRecord> {
		return this.list.filter((j) => j.description === 'W2W-TEMPLATE')
	}

	// Old method still in use - need to audit and replace some of these
	getJobs(): JobRecord[] {
		const unsorted = this.list.filter((job) => job.description !== 'W2W-TEMPLATE')
		return _.sortBy(unsorted, 'description')
	}

	/**
	 * Get a list of all jobs given a supervisor ID
	 * @returns An array of employee records
	 */

	getAllJobs(supId?: number): Array<JobRecord> {
		const filteredJobs = this.list.filter((job) => job.description !== 'W2W-TEMPLATE')
		const jobs = _.sortBy(filteredJobs, 'name')
		if (supId) {
			return jobs.filter((job) => job.supervisor_ids.includes(supId))
		}
		return jobs
	}

	getManagedAllJobs(supIds: Array<number>) {
		const jobs = this.getAllJobs()
		return jobs.filter((job) => supIds.some((id) => job.supervisor_ids.indexOf(id) !== -1))
	}

	getActiveJobs(supId?: number): Array<JobRecord> {
		const jobs = this.getAllJobs().filter((job) => job.active === true)
		if (supId) {
			return jobs.filter((job) => job.supervisor_ids.includes(supId))
		}
		return jobs
	}

	getInactiveJobs(supId?: number): Array<JobRecord> {
		const jobs = this.getAllJobs().filter((job) => job.active === false)
		if (supId) {
			return jobs.filter((job) => job.supervisor_ids.includes(supId))
		}
		return jobs
	}

	getManagedActiveJobs(supIds: Array<number>) {
		const jobs = this.getAllJobs().filter((job) => job.active === true)
		return jobs.filter((job) => supIds.some((id) => job.supervisor_ids.indexOf(id) !== -1))
	}

	getInactiveEmployees(supId?: number): Array<JobRecord> {
		const jobs = this.getAllJobs().filter((job) => job.active === false)
		if (supId) {
			return jobs.filter((job) => job.supervisor_ids.includes(supId))
		}
		return jobs
	}

	getManagedInctiveJobs(supIds: Array<number>) {
		const jobs = this.getAllJobs().filter((job) => job.active === false)
		return jobs.filter((job) => supIds.some((id) => job.supervisor_ids.indexOf(id) !== -1))
	}

	getJobsByGroupId(id: number): Array<JobRecord> {
		return this.getAllJobs().filter((job) => job.supervisor_group_id === id)
	}

	getJobsByTourId(id: number): Array<number> {
		return this.getAllJobs()
			.filter((job) => job.tour_id === id)
			.map((j) => j.id)
	}

	// Miscellaneous methods

	getJobsForTable(): JobRecord[] {
		const unsorted = this.list.filter((job) => !job.company_default && !job.travel && job.description !== 'W2W-TEMPLATE')
		return _.sortBy(unsorted, 'description')
	}

	jobExistsForId(id: number): boolean {
		const job = this.getJobById(id)
		if (job) {
			return true
		} else {
			return false
		}
	}

	getJobsForJobSiteId(id: number): JobRecord[] {
		if (id) {
			return this.list.filter((j) => j.location_id === id)
		} else {
			return []
		}
	}

	getJobsForJobSiteIds(siteIds?: Array<number>): Array<JobRecord> {
		if (siteIds === null || siteIds === undefined) {
			return this.getJobsForTable()
		} else {
			return this.getJobsForTable().filter((j) => {
				return siteIds.includes(j.location_id)
			})
		}
	}

	getJobSiteForJobId(id: number): JobSiteRecord {
		const job = this.getJobById(id)
		if (!job) {
			return null
		}
		return this.siteSrvc.getJobSiteById(job.location_id)
	}

	getJobById(id: number): JobRecord {
		const job = this.list.find((j) => j.id === id)
		return job ? job : null
	}

	getJobsForOrganizationId(id: number): Array<JobRecord> {
		return this.list.filter((j) => j.client_id === id || j.vendor_id === id)
	}

	// getTimezoneStringForJobId(id: number): string {
	// 	const jobSite = this.getJobSiteForJobId(id)
	// 	if (!jobSite) { return 'No Job Site' } // Do not change this value, used in transactions table
	// 	return this.siteSrvc.getTimezoneStringForJobSiteId(jobSite.id)
	// }

	jobNameForId(id: number): string {
		const job = this.list.find((j) => j.id === id)
		if (!job) {
			return 'Job Not Found'
		}
		return job.description
	}

	makeJob(record: JobRecord): JobRecord {
		const job = new JobRecord(record)
		// job.normalizeTimes()
		return job
	}

	setJobRecords(records: Array<JobRecord>) {
		this.lastUpdated = new Date()
		this.list = records.map((c) => this.makeJob(c))
		this.dataLoaded = true
	}

	removeLocalJobRecord(recordId: number) {
		this.list = this.list.filter((rec) => rec.id !== recordId)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateJobRecords(records: Array<JobRecord>) {
		const newRecords = records.map((rec) => this.makeJob(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.list.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.list.push(newRecord)
			}
		}
	}
}
