import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { SettingsService } from '@app/services'
import { DatabaseTableName } from '@app/models/table'
import { log } from '@app/helpers'
import { NotificationsService } from '@app/services'

/**
 * Check if the active user's permissions allow for access to a given table.
 * If no key is provided then full access is assumed. Keys are added to the
 * data field on the routing table. The key should be a DataAccess table.
 *
 * If a route contains a fragment, that fragment overrides any key provided
 * in the routing table and is used to lookup the table it's associated with.
 * If there is no fragment in the route, then the key provided in the routing
 * table will be used to look up permissions for that table.
 */

@Injectable({ providedIn: 'root' })
export class AccessGuard {
	constructor(
		private settingSrvc: SettingsService,
		private notifySrvc: NotificationsService,
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const keys = (route.data['keys'] as Array<DatabaseTableName>) ?? []

		// Check if route has access keys and add access status to an array
		const results = keys.map((key) => this.permissionsValidForKey(key))
		// If route not protected or any key allows access then allow access for that route
		if (results.length === 0 || results.includes(true)) {
			return true
		} else {
			this.notifySrvc.notify('error', 'Not Authorized', 'You are not authorized to access this section.', 2)
			return false
		}
	}

	/**
	 * Look up user permisions for a DataAccess table and check if they
	 * have read access.
	 *
	 * @param key: A DataAccess table name
	 * @returns boolean value indicating if they have read access
	 */

	permissionsValidForKey(key: DatabaseTableName): boolean {
		if (key) {
			log('Key', key)
			const user = this.settingSrvc.getMyUser()
			if (user) {
				const accessPermissions = user.getAccessPermissions()
				const permItem = accessPermissions.getPermissionItemForKey(key)
				if (permItem) {
					const permissions = permItem.access
					const ownerPermissions = permItem.owner
					const canAccess = permissions.read || (ownerPermissions ? ownerPermissions.read : false)
					if (canAccess) {
						log('ACCESSGUARD: USER AUTHORIZED')
						return true
					} else {
						log('ACCESSGUARD: USER NOT AUTHORIZED')
						return false
					}
				} else {
					log('Access Guard: No access permissions for key:', key)
					return false
				}
			}
		}
		return true
	}
}
