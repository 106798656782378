<div *ngIf="visible" class="card card-body record-transaction">
	<div class="row">
		<div class="col-12 record-item">
			<div>
				<strong class="exception-type"><i class="fa fa-tasks"></i> No Matching Job</strong>
				<span
					><a href="#" class="section-control" title="More Information" data-toggle="collapse" [attr.data-target]="'#' + infoBoxId"
						><i class="far fa-info-circle" aria-hidden="true"></i></a
				></span>
			</div>
			<hr />
		</div>
	</div>

	<div [id]="infoBoxId" class="collapse">
		<div class="alert alert-info info-box" role="alert">
			The system does not have enough information to determine which job this employee is trying to check in to. Please assign an existing job from
			the drop down and click the green check mark icon to create a time entry record.
		</div>
	</div>

	<div class="row">
		<div class="col-12 col-sm-7 record-item">
			<strong>Name</strong><br />
			{{ trans.employee_id | displayEmployeeName }}
		</div>
		<div class="col-12 col-sm-5 record-item">
			<strong>Hours Worked</strong><br />
			<strong *ngIf="showTimeWorked" style="color: #444">{{ timeWorkedString }}</strong>
			<strong *ngIf="showTimeOngoing" style="color: green">{{ timeWorkedString }}</strong>
			<strong *ngIf="showTimeMissing" style="color: firebrick">{{ timeWorkedString }}</strong>
		</div>
	</div>
	<div class="row">
		<div class="col-7 record-item">
			<strong>Start Date</strong><br />
			{{ trans.actual_start | mediumDateFromDateString }}
		</div>
		<div class="col-5 record-item">
			<strong>Start Time</strong><br />
			{{ trans.actual_start | standardTimeFromDateString }} {{ trans.timezone }}
		</div>
	</div>
	<div *ngIf="trans.actual_end" class="row">
		<div class="col-7 record-item">
			<strong>End Date</strong><br />
			{{ trans.actual_end | mediumDateFromDateString }} {{ trans.timezone }}
		</div>
		<div class="col-5 record-item">
			<strong>End Time</strong><br />
			{{ trans.actual_end | standardTimeFromDateString }}
		</div>
	</div>
	<div class="row">
		<div class="col-7 record-item">
			<div class="row" *ngIf="trans.location_id">
				<div class="col-12 record-item">
					<strong>Known Job Site</strong><br />
					<span>{{ trans.location_id | displayJobSiteName }} / {{ trans.caller_id_number_e164 | displayPhone }}</span>
				</div>
			</div>
			<div class="row" *ngIf="trans.caller_id_number_e164 && !trans.location_id">
				<div class="col-12 record-item">
					<strong>Caller ID Information</strong><br />
					<span>{{ callerIdInformation() }}</span>
				</div>
			</div>
		</div>
		<div class="col-5 record-item action-buttons">
			<button class="btn btn-sm btn-danger action-button" title="Reject" (click)="reject()">
				<i class="fa fa-times" aria-hidden="true"></i>
			</button>
			<button class="btn btn-sm btn-success action-button" title="Accept" (click)="accept()" [disabled]="!isValid()">
				<i class="fa fa-check" aria-hidden="true"></i>
			</button>
			<span class="hidden-sm-up"><br /><br /></span>
			<button
				*ngIf="trans.geo_start_address"
				class="btn btn-sm btn-info action-button"
				data-toggle="collapse"
				[attr.data-target]="'#mapView_' + idx"
				title="Toggle Map"
			>
				Map
			</button>
		</div>
	</div>

	<div class="row">
		<div class="col-12 record-item">
			<strong>Select a Job</strong><br />
			<p-select appScrollLockSelect id="job" [options]="jobsDropdown" [(ngModel)]="trans.job_id" [filter]="true" filterBy="label"></p-select>
		</div>
	</div>

	<hr *ngIf="hasFooter()" />

	<div class="row" *ngIf="trans.notes">
		<div class="col-12 record-item">
			<strong>Notes</strong><br />
			<span style="color: firebrick">{{ trans.notes }}</span>
		</div>
	</div>
	<div class="row" *ngIf="trans.not_scheduled">
		<div class="col-12 record-item">
			<span style="color: firebrick"
				><strong><i class="fa fa-exclamation-triangle"></i> Employee Not On Schedule</strong></span
			>
		</div>
	</div>
	<div class="row" *ngIf="trans.called_from_cell">
		<div class="col-12 record-item">
			<span style="color: firebrick"
				><strong><i class="fa fa-exclamation-triangle"></i> Employee Called From Cell</strong></span
			>
		</div>
		<div class="col-12 approx-addr-note">
			<strong>Approximate Address</strong><br />
			<span *ngIf="trans.geo_start_address">{{ trans.geo_start_address }}</span>
			<span *ngIf="!trans.geo_start_address" style="color: firebrick">Could Not Be Determined</span>
		</div>
	</div>
	<div class="row" *ngIf="trans.rejected_location">
		<div class="col-12 record-item">
			<span style="color: firebrick"
				><strong><i class="fa fa-exclamation-triangle"></i> Previously Rejected Job Site #</strong></span
			>
		</div>
	</div>
	<div class="collapse" *ngIf="trans.geo_start_address" [id]="'mapView_' + idx">
		<div class="row">
			<div class="col-12 record-item">
				<hr />
				<strong>Location of Check-in</strong><br />
				<app-transaction-map [addr]="trans.geo_start_address"></app-transaction-map>
			</div>
		</div>
	</div>
	<div *ngIf="false" class="row">
		<div class="col-12 record-item">
			<strong>JSON</strong><br />
			{{ trans | json }}
		</div>
	</div>
</div>
