import { log, DateTimeHelper } from '@app/helpers'

export type BreakType = 'UNPAID_BREAK' | 'PAID_BREAK'

export class BreakLogRecord {
	tableName = 'break_log'

	id: number = null
	company_id: number = null
	employee_id: number = null
	transaction_log_id: number = null
	actual_start: string = null
	actual_end: string = null
	break_time: string = null
	break_time_worked: boolean = null
	break_type: BreakType = 'UNPAID_BREAK'
	created: string = null
	comments: string = null
	source: string = null
	post_break_incident_log_id: number = null

	constructor(record?: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
		// Fix date entries by stripping off UTC
		this.created = DateTimeHelper.stripUtcTag(this.created)
		this.actual_start = this.actual_start ? DateTimeHelper.stripUtcTag(this.actual_start) : null
		this.actual_end = this.actual_end ? DateTimeHelper.stripUtcTag(this.actual_end) : null
	}

	getBreakTypeDescription(): string {
		switch (this.break_type) {
			case 'UNPAID_BREAK':
				return 'Unpaid Break'
			case 'PAID_BREAK':
				return 'Paid Break'
			default:
				return 'Unknown Break Type'
		}
	}

	getSourceTypeDescription(): string {
		switch (this.source) {
			case 'SMS':
				return 'SMS'
			case 'IVR':
				return 'IVR'
			case 'EMP_APP':
				return 'Employee App'
			case 'STATION_APP':
				return 'Time Station'
			case 'ADMIN':
				return 'Admin'
			case 'TIME_WORKED':
				return 'Time Worked'
			case 'JOB':
				return 'Job'
			case 'SCHEDULE':
				return 'Schedule'
			default:
				return 'Unspecified'
		}
	}
}
