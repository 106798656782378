<div class="container section-container">
	<div class="sec-head-wrap" [class.section-head-pad]="filterTagLabel">
		<!-- Section Controls -->
		<div *ngIf="showTransactionsTable()" class="sec-act-ico-wrap">
			<i
				*ngIf="!isGlobalAccount"
				class="fas fa-plus sec-act-ico"
				[class.section-control-disabled]="!canPerformAction(CrudAction.create)"
				(click)="createRecord()"
				title="Add Time Entry"
			></i>
			<i class="far fa-cog sec-act-ico" (click)="togglePrefs()" title="Preferences"></i>
			<i class="far fa-question-circle sec-act-ico" (click)="toggleTooltips()" title="Show Help"></i>
		</div>

		<!-- Section Title -->
		<div class="sec-title-wrap" [class.header-highlight]="showTodayView">
			<div *ngIf="!showTodayView"><i class="far fa-clock sec-head-ico" aria-hidden="true"></i><span>Time Entries</span></div>
			<div
				*ngIf="showTodayView"
				class="view-active"
				style="display: inline-block; cursor: pointer"
				[owlDateTimeTrigger]="dtDayViewDateA"
				(click)="hideDtTimePicker()"
			>
				{{ currentDayViewDate | dateFormatter: 'ddd, MMM Do' }}
				<div class="sec-title-ico-wrap">
					<i class="fal fa-calendar sec-title-ico"></i>
				</div>
			</div>
		</div>

		<!-- Day View Date Picker -->
		<owl-date-time
			#dtDayViewDateA
			[startAt]="dayViewDate"
			[hour12Timer]="true"
			[pickerType]="'both'"
			[pickerMode]="'dialog'"
			(afterPickerClosed)="showDtTimePicker(); dayViewPickerClosed()"
		>
		</owl-date-time>
		<input
			[hidden]="true"
			class="form-control datetime-input"
			id="dayViewDate"
			placeholder=""
			[min]="navStartDate"
			[max]="navEndDate"
			[(ngModel)]="dayViewDate"
			[owlDateTime]="dtDayViewDateA"
		/>

		<!-- Sub-header Icons -->
		<div class="sub-head-wrap">
			<div class="sub-head-ico-wrap">
				<!-- Tag Icon -->
				<div class="ico-inline-block">
					<a data-bs-toggle="dropdown" href="#" id="tagDropdown" aria-haspopup="true" aria-expanded="false">
						<i
							*ngIf="!isTagFilterProcessing"
							class="far fa-tag sub-head-ico"
							[class.tag-filter-active]="hasTagFilter"
							[class.fas]="hasTagFilter"
							title="Tag Filter"
						></i>
						<i
							*ngIf="isTagFilterProcessing"
							class="far fa-loader fa-spin sub-head-ico"
							[class.tag-filter-active]="true"
							title="Tag Filter"
							(click)="scrollTagMenu()"
						></i>
					</a>
					<div
						id="tag-filter-menu"
						class="dropdown-menu tag-dropdown"
						aria-labelledby="tagDropdown"
						style="height: 295px; overflow: hidden; overflow-y: scroll"
					>
						<div class="dropdown-item has-pointer" (click)="setTagFilterFromDropdown('')">Clear Tag Filter</div>
						<div class="dropdown-divider"></div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.openShifts + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.openShifts)"
						>
							Unclosed Shifts
						</div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.ongoingShifts + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.ongoingShifts)"
						>
							Ongoing Shifts
						</div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.onBreak + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.onBreak)"
						>
							Employees On Break
						</div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.missingCheckout + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.missingCheckout)"
						>
							Missing Checkout
						</div>
						<div
							*ngIf="showNoShowRecentTag"
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.noShowRecent + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.noShowRecent)"
						>
							No Show (Recent)
						</div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.noShow + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.noShow)"
						>
							No Show (All)
						</div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.invalidHours + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.invalidHours)"
						>
							Invalid Hours
						</div>
						<div *ngIf="showEarlyLateIssuesTags" class="dropdown-divider"></div>
						<div
							*ngIf="showEarlyLateIssuesTags"
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.hlEarlyLate + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.hlEarlyLate)"
						>
							Early/Late (In/Out)
						</div>
						<div
							*ngIf="showEarlyLateIssuesTags"
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.hlEarlyLateIn)"
							(click)="setTagFilterFromDropdown(TagFilterKey.hlEarlyLateIn)"
						>
							Early/Late (In)
						</div>
						<div
							*ngIf="showEarlyLateIssuesTags"
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.hlEarlyLateOut)"
							(click)="setTagFilterFromDropdown(TagFilterKey.hlEarlyLateOut)"
						>
							Early/Late (Out)
						</div>
						<div class="dropdown-divider"></div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.gpsAllIssues + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.gpsAllIssues)"
						>
							All GPS Issues
						</div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.gpsBlocked + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.gpsBlocked)"
						>
							GPS Blocked
						</div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.gpsMissingInOut + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.gpsMissingInOut)"
						>
							GPS Missing (In/Out)
						</div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.gpsMissingIn)"
							(click)="setTagFilterFromDropdown(TagFilterKey.gpsMissingIn)"
						>
							GPS Missing (In)
						</div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.gpsMissingOut + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.gpsMissingOut)"
						>
							GPS Missing (Out)
						</div>
						<div class="dropdown-divider"></div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.gpsDistanceInOut + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.gpsDistanceInOut)"
						>
							GPS Distance (In/Out)
						</div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.gpsDistanceIn + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.gpsDistanceIn)"
						>
							GPS Distance (In)
						</div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.gpsDistanceOut + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.gpsDistanceOut)"
						>
							GPS Distance (Out)
						</div>
						<div class="dropdown-divider"></div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.gpsTimeInOut + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.gpsTimeInOut)"
						>
							GPS Time (In/Out)
						</div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.gpsTimeIn + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.gpsTimeIn)"
						>
							GPS Time (In)
						</div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.gpsTimeOut + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.gpsTimeOut)"
						>
							GPS Time (Out)
						</div>
						<div class="dropdown-divider"></div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.rsbLandline + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.rsbLandline)"
						>
							From Landline
						</div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.rsbEmpCell + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.rsbEmpCell)"
						>
							From Emp Cell
						</div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.rsbStation + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.rsbStation)"
						>
							From Station
						</div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.rsbEmpWebApp + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.rsbEmpWebApp)"
						>
							From Web App
						</div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.rsbAdmin + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.rsbAdmin)"
						>
							From Admin
						</div>
						<div class="dropdown-divider"></div>
						<div
							class="dropdown-item has-pointer"
							*ngIf="showDuplicatePhotosTag"
							[class.active]="filterInput.includes(TagFilterKey.imageIssues + ' ')"
							(click)="setFilterText(TagFilterKey.imageIssues)"
						>
							Image Issues
						</div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.clIssues + ' ')"
							(click)="setFilterText(TagFilterKey.clIssues)"
						>
							Checklist Issues
						</div>
						<div
							class="dropdown-item has-pointer"
							*ngIf="showConfirmBreakIssuesTag"
							[class.active]="filterInput.includes(TagFilterKey.empDeniedBreak + ' ')"
							(click)="setFilterText(TagFilterKey.empDeniedBreak)"
						>
							Employee Denied Break
						</div>
						<div
							class="dropdown-item has-pointer"
							[class.active]="filterInput.includes(TagFilterKey.empCountOverage + ' ')"
							(click)="setTagFilterFromDropdown(TagFilterKey.empCountOverage)"
						>
							Employee Count Overage
						</div>
					</div>
				</div>

				<!-- Day View Toggle Icon -->
				<div
					*ngIf="!showIssues() || (!showFilterView && showTodayView)"
					(click)="toggleTodayView()"
					class="ico-inline-block"
					title="Toggle Day View"
				>
					<i *ngIf="!hasViewBeenChecked || !showTodayView" class="far fa-calendar sub-head-ico"></i>
					<i *ngIf="hasViewBeenChecked && showTodayView && !isLoadingData" class="far fa-calendar-check sub-head-ico sub-head-ico-highlight"></i>
					<i
						*ngIf="hasViewBeenChecked && showTodayView && isLoadingData"
						class="fa fa-loader fa-spin sub-head-ico sub-head-ico-highlight"
						title="Day View Loading"
					></i>
				</div>

				<!-- Day View Back and Forward Icons -->
				<div *ngIf="showTodayView" class="ico-inline-block">
					<i
						class="far fa-arrow-left sub-head-ico"
						[class.sub-head-ico-disabled]="!dayViewCanGoBack"
						(click)="dayViewBack()"
						title="Previous Day"
					></i>
					<i
						class="far fa-arrow-right sub-head-ico"
						[class.sub-head-ico-disabled]="!dayViewCanGoForward"
						(click)="dayViewForward()"
						title="Next Day"
					></i>
				</div>

				<!-- Range Filter Icon -->
				<div *ngIf="!showIssues() && !showTodayView" class="ico-inline-block">
					<i
						class="far fa-filter sub-head-ico"
						[class.sub-head-ico-highlight]="showFilterView"
						(click)="openDatePicker()"
						title="Filter Dates"
					></i>
				</div>

				<!-- Show Issues Icon -->
				<div *ngIf="!showTodayView && !showFilterView" class="ico-inline-block" (click)="transTable.toggleLoadIssues()" title="Show Issues">
					<i *ngIf="!showIssues()" class="far fa-star sub-head-ico" aria-hidden="true"></i>
					<i *ngIf="showIssues()" class="far fa-star sub-head-ico sub-head-ico-highlight" aria-hidden="true"></i>
				</div>

				<!-- Switch to global company -->
				<div *ngIf="canAccessGlobalAccount" class="ico-inline-block" (click)="switchToGlobalAccount()" title="Switch to Global Company">
					<i class="fal fa-globe sub-head-ico" aria-hidden="true"></i>
				</div>

				<!-- Refresh Icon -->
				<i class="far fa-refresh sub-head-ico" (click)="loadData()" title="Refresh Data"></i>
			</div>

			<!-- Updated At -->
			<div class="updated-wrap">
				<div *ngIf="!filterTagLabel" class="last-updated">{{ lastUpdated() }}</div>
				<div *ngIf="filterTagLabel" class="table-tag filter-label-active">
					<div class="filter-label-text">{{ filterTagLabel }}</div>
					&nbsp;<span class="tag-close" (click)="setTagFilterFromDropdown('')">&times;</span>
				</div>
			</div>
		</div>
	</div>

	<!-- Filtered Date Range -->
	<div *ngIf="showFilterView && !showTodayView" class="date-range-wrap">
		<div class="date-range-string">
			{{ displayDateRange() }}
		</div>
		<!-- <i *ngIf="displayRangeReset()" style="z-index: 1900" (click)="clearDateRange()" class="fa fa-times-circle-o range-reset" aria-hidden="true"></i> -->
	</div>

	<!-- Company Name -->
	<div class="company-name tts-hl-2" *ngIf="companyName">
		{{ companyName }}
	</div>

	<!-- Close Filter Button -->
	<div *ngIf="showFilterView && displayRangeReset()" class="nav-clear-block">
		<div class="nav-clear-btn" (click)="clearDateRange()">
			{{ showTodayView ? 'exit day view' : 'clear filter' }}
		</div>
	</div>

	<!-- Help Information -->
	<div *ngIf="showTooltips" class="card card-body tooltip-block tooltip-info-text tooltip-section-header">
		<button type="button" class="close" aria-label="Close" (click)="toggleTooltips()">
			<span aria-hidden="true">&times;</span>
		</button>

		<app-no-data-tip *ngIf="showNoDataTip()"></app-no-data-tip>

		<div>
			<div style="max-width: 525px">
				<div class="row">
					<div class="col-12 col-sm-6">
						<div style="line-height: 1.8em">
							<div style="margin: 15px 0px 10px 0px">
								<strong style="color: chocolate">Section Icons</strong>
							</div>
							<i style="width: 20px; color: #0275d8" class="far fa-tag"></i> Tag Filter<br />
							<i style="width: 20px; color: #0275d8" class="far fa-filter"></i> Filter Date Range<br />
							<i style="width: 20px; color: #0275d8" class="far fa-calendar"></i> Toggle Day View<br />
							<i style="width: 20px; color: #0275d8" class="far fa-star"></i> Toggle Issues View<br />
							<i style="width: 20px; color: #0275d8" class="far fa-refresh"></i> Refresh Time Entry Data<br />
						</div>
					</div>
					<div class="col-12 col-sm-6">
						<div style="line-height: 1.8em">
							<div style="margin: 15px 0px 10px 0px">
								<strong style="color: chocolate">Row Higlights</strong>
							</div>
							<i style="width: 20px; color: #61a061" class="fas fa-square"></i> Ongoing<br />
							<i style="width: 20px; color: #8fc08f" class="fas fa-square"></i> Pending<br />
							<i style="width: 20px; color: #d3a5a5" class="fas fa-square"></i> No Show / Missing<br />
							<i style="width: 20px; color: #1a90d59c" class="fas fa-square"></i> Notifications Paused<br />
						</div>
					</div>
				</div>
			</div>
		</div>

		<hr style="margin: 20px 0px 30px 0px" />

		<div>
			Time entries are created and updated automatically each time an employee checks in or out. You may modify existing entries or add new ones
			here. You can display recent entries, jump to a specific date to view those entries, or select a date range to view entries from that range.

			<br /><br />
			<strong class="opt-header-nav">Tag Filter</strong><i style="color: #0275d8; padding: 0px 4px" class="fa fa-tag"></i>Use the Tag Filter icon
			to filter the list of visible time entries based on various conditions. See the section
			<span style="color: chocolate">Using Filter Tags</span> below for more information. <br /><br />
			<strong class="opt-header-nav">Activate Day View</strong><i style="color: #0275d8; padding: 0px 4px" class="fa fa-calendar-o"></i>Use this
			icon to activate <strong>Day View</strong> which shows you entries for a specific date. When in Day View, only time entries for the date
			indicated in the upper left are displayed and you can jump to any specific date using the <strong>Day View Date Selector</strong> icon next
			to this date. <br /><br />
			<strong class="opt-header-nav">Day View Date Selector</strong><i style="color: #0275d8; padding: 0px 4px" class="fa fa-calendar"></i>This
			icon will be visible next to the date displayed in the upper left when in <strong>Day View</strong>. Use it to select a specific date to view
			time entries for just that that date. <br /><br />
			<strong class="opt-header-nav">Exit Day View</strong><i style="color: chocolate; padding: 0px 4px" class="fa fa-calendar-check-o"></i>This
			icon will be visible when in <strong>Day View</strong>. Use this icon or the button in the navigation bar to exit
			<strong>Day View</strong> and return to the default list of recent time entries. <br /><br />
			<strong class="opt-header-nav">Date Range Filter</strong><i style="color: #0275d8; padding: 0px 4px" class="fa fa-filter"></i>Use this icon
			to select a date range filter which will show only time entries from that range. A maximum of 5000 records can be displayed and if the range
			you select has more records than can be displayed you will see a notification indicating this. If this happens, you will need to select a
			smaller range to see this missing records. When a date range filter is active, this icon will highlight to indicate the filter is active and
			the date range will be listed below this icon. You can clear the date range filter using the button in the navigation bar or clicking the
			<i style="color: gray" class="fa fa-times-circle-o"></i> icon displayed after the date range. <br /><br />
			<strong class="opt-header-nav">Show Issues</strong><i style="color: #0275d8; padding: 0px 4px" class="fa fa-star-o"></i>Use this icon to show
			a list of time entries that have been flagged as needing attention by the system. This icon will have a highlight color when this filter is
			active. Click it again to toggle back to the default time entry list.

			<hr />
			<!-- <strong class="opt-secondary">Performance Options</strong> Click the button at the bottom of the table labelled <strong>Performance Options</strong> to
			configure options for speeding up the display of data. <br /><br /> -->
			<strong class="opt-secondary">Sorting Columns</strong> If a column supports sorting, you can click on the column header to entries using that
			column. Click the header again to reverse the sort order. <br /><br />
			<strong class="opt-secondary">Reset Sort Order</strong> Click the <i style="color: gray" class="fa fa-times-circle-o"></i> icon to the right
			of the <strong>filter</strong> search box to reset to the default sort order. <br /><br />
			<!-- <strong class="opt-secondary">Performance Options</strong> can be configured using the button at the bottom of the table. Modify these settings to
			speed up the load time and display of data. <br /><br /> -->
			<strong class="opt-secondary">Row Highlights</strong>&nbsp; <strong style="color: green">Green</strong> indicates an ongoing time entry where
			an employee has checked in but has not yet checked out. <strong style="color: firebrick">Red</strong> indicates a time entry where the
			employee failed to check out. <strong style="color: #cccc6d">Yellow</strong> indicates a recently modified time entry and will only flash
			briefly after a change has been made. <br /><br />
			<strong class="opt-secondary">Late Notifications</strong> The <strong>Late In/Out</strong> column displays information about late
			notifications which are available for scheduled jobs. <br /><br />
			<strong class="opt-secondary">Checkpoints</strong> Checkpoints log GPS and time information during a shift and are available using the
			employe mobile web site. This feature is used for jobs which require employees to make rounds during their shift and can be configured for
			individual jobs.

			<hr />

			<!-- <i class="fa fa-tag" style="color:chocolate;margin-right: 10px; font-size: 1.3em;"></i> -->
			<strong style="color: chocolate">Using Filter Tags</strong> <br /><br />
			Use the tag filter menu <i class="fa fa-tag" style="color: #0275d8"></i> to show various groups of time entries which may be useful in
			tracking down issues. You may refine a filter by adding extra terms such as an employee or job name after the tag (separated by spaces). Use
			the Clear Tag Filter option from the Filter Tag menu or clear the filter search box to display the full time entry list.<br />

			<br />

			<div class="help-item">
				<strong style="color: chocolate">Clear Tag Filter</strong> - Clear any current filter tags and display the full list
			</div>
			<div class="help-item">
				<strong style="color: chocolate">Open Time Entries</strong> - Display time entries which are still open. This includes no shows, missing
				checkouts, and ongoing shifts.
			</div>
			<div class="help-item"><strong style="color: chocolate">Ongoing Shift</strong> - Display shifts which are currently ongoing.</div>
			<div class="help-item">
				<strong style="color: chocolate">Employee On Break</strong> - Display shifts where the employee is currently on break.
			</div>
			<div class="help-item">
				<strong style="color: chocolate">Missing Checkout</strong> - Display shifts where the employee failed to checkout.
			</div>
			<div class="help-item">
				<strong style="color: chocolate">Employee No Show</strong> - Display shifts where the employee faild to check in.
			</div>
			<div class="help-item"><strong style="color: chocolate">All GPS Issues</strong> - Display all shifts with GPS issues.</div>
			<div class="help-item"><strong style="color: chocolate">GPS Missing (In/Out)</strong> - Display shifts missing GPS information.</div>
			<div class="help-item">
				<strong style="color: chocolate">GPS Missing (In)</strong> - Display shifts missing GPS information for check in.
			</div>
			<div class="help-item">
				<strong style="color: chocolate">GPS Missing (Out)</strong> - Display shifts missing GPS information for check out.
			</div>
			<div class="help-item">
				<strong style="color: chocolate">GPS Distance (In/Out)</strong> - Display shifts missing GPS distance information for check in or check
				out.
			</div>
			<div class="help-item">
				<strong style="color: chocolate">GPS Distance (In)</strong> - Display shifts missing GPS distance information for check in.
			</div>
			<div class="help-item">
				<strong style="color: chocolate">GPS Distance (Out)</strong> - Display shifts missing GPS distance information for check out.
			</div>
			<div class="help-item">
				<strong style="color: chocolate">GPS Time (In/Out)</strong> - Display shifts where employee failed to respond on time to requests for
				their GPS information.
			</div>
			<div class="help-item">
				<strong style="color: chocolate">GPS Time (In)</strong> - Display shifts where employee failed to respond on time to requests for their
				GPS information during check in.
			</div>
			<div class="help-item">
				<strong style="color: chocolate">GPS Time (Out)</strong> - Display shifts where employee failed to respond on time to requests for their
				GPS information during check out.
			</div>
			<div class="help-item" *ngIf="showConfirmBreakIssuesTag">
				<strong style="color: chocolate">Employee Denied Break</strong>- Display shifts where employee indicated they were not allowed to take
				their breaks.
			</div>
			<div class="help-item">
				<strong style="color: chocolate">Employee Count Overage</strong> - Display shifts where more than the scheduled number of employees
				checked in for a shift.
			</div>
		</div>
	</div>

	<div *ngIf="showIssues() && hasData()" style="text-align: center; margin: 20px 0px 30px 0px; color: chocolate">
		<div class="alert alert-warning">
			Showing time entries from the last 30 days that may need attention. Click
			<a href="#" (click)="transTable.toggleLoadIssues()">here</a> to show all entries.
		</div>
	</div>
	<div *ngIf="showIssues() && !hasData()" style="text-align: center; margin: 20px 0px 30px 0px; color: chocolate">
		<div class="alert alert-warning">
			There are no time entries that need attention. Click the star icon at the top of the page to show all entries.
		</div>
	</div>

	<app-transaction-table
		#transTable
		*ngIf="showTransactionsTable()"
		(setFilterTag)="setTagFilterFromDropdown($event)"
		(tableRefreshed)="tableRefreshed()"
		(filterEvent)="processTableFilterEvent($event)"
	></app-transaction-table>

	<div style="margin-top: 25px">
		<div class="scroll-to-top">
			<a href="#" (click)="scrollToTop()"
				><small>Back To Top <i class="fa fa-arrow-up" aria-hidden="true"></i></small
			></a>
		</div>
	</div>
</div>

<!-- Date Range Dialog -->
<p-dialog header="Filter Dates" [(visible)]="showFilterDatesDialog" [modal]="false" [style]="{ width: '300px' }" [dismissableMask]="true">
	<div class="date-filter-block">
		<div class="input-label">Start Date</div>
		<div class="input-group date-picker-wrapper" [owlDateTimeTrigger]="dtRangeFilterStart" (click)="hideDtTimePicker()">
			<div class="input-group-prepend">
				<div class="input-group-text">
					<span class="datetime-icon"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
			<div class="date-picker-input">{{ filterStartDate | dateFormatter: 'YYYY-MM-DD' }}</div>
			<input
				[hidden]="true"
				[tabindex]="14"
				class="form-control datetime-input"
				id="filterRangeStartDate"
				placeholder=""
				[(ngModel)]="filterStartDate"
				[owlDateTime]="dtRangeFilterStart"
				[owlDateTimeTrigger]="dtRangeFilterStart"
			/>
		</div>
		<owl-date-time
			#dtRangeFilterStart
			[hour12Timer]="true"
			[pickerType]="'both'"
			[pickerMode]="'dialog'"
			(afterPickerClosed)="showDtTimePicker(); datePickerClosed()"
		></owl-date-time>
	</div>
	<div class="date-filter-block">
		<div class="input-label">End Date</div>
		<div class="input-group date-picker-wrapper" [owlDateTimeTrigger]="dtRangeFilterEnd" (click)="hideDtTimePicker()">
			<div class="input-group-prepend">
				<div class="input-group-text">
					<span class="datetime-icon"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
			<div class="date-picker-input">{{ filterEndDate | dateFormatter: 'YYYY-MM-DD' }}</div>
			<input
				[hidden]="true"
				[tabindex]="14"
				class="form-control datetime-input"
				id="filterRangeEndDate"
				placeholder=""
				[(ngModel)]="filterEndDate"
				[owlDateTime]="dtRangeFilterEnd"
				[owlDateTimeTrigger]="dtRangeFilterEnd"
			/>
		</div>
		<owl-date-time
			#dtRangeFilterEnd
			[startAt]="filterStartDate"
			[hour12Timer]="true"
			[pickerType]="'both'"
			[pickerMode]="'dialog'"
			(afterPickerClosed)="showDtTimePicker(); datePickerClosed()"
		></owl-date-time>
	</div>
	<div class="date-filter-actions">
		<div class="row">
			<div class="col-6">
				<button class="btn btn-sm btn-outline-danger" (click)="resetFilterBtnPressed()">Reset</button>
			</div>
			<div class="col-6">
				<button [disabled]="!filterStartDate || !filterEndDate" class="btn btn-sm btn-success float-right" (click)="saveDatePickerSettings()">
					Set Range
				</button>
			</div>
		</div>
	</div>
</p-dialog>

<!-- Open Transactions Map -->
<app-live-view-dialog *ngIf="showTransMapModal" (closeDialog)="showTransMapModal = false; (false)"></app-live-view-dialog>
