import { log } from '@app/helpers'

export class StripeCustomerInfo {
	customer?: StripeCustomer
	paymentMethods?: StripePaymentMethod[] = []

	constructor(response?: any) {
		log('StripeCustomerInfo', response)
		const customerData = response.customer
		if (customerData) this.customer = new StripeCustomer(customerData)

		const paymentMethodsData = response.paymentMethods
		if (paymentMethodsData) this.paymentMethods = paymentMethodsData.map((item) => new StripePaymentMethod(item))
	}

	getDefaultPaymentMethod(): StripePaymentMethod {
		return this.paymentMethods.find((item) => item.id === this.customer?.invoice_settings.default_payment_method)
	}

	isDefaultPaymentMethod(id: string): boolean {
		return id === this.customer?.invoice_settings.default_payment_method
	}
}

export class StripeCustomer {
	id: string
	object: string
	address?: {
		city?: string
		country?: string
		line1?: string
		line2?: string
		postal_code?: string
		state?: string
	}
	balance: number
	created: number
	currency?: string
	default_source?: string | null
	delinquent?: boolean
	description?: string
	discount?: {
		coupon: {
			id: string
			object: string
			amount_off?: number | null
			created: number
			currency?: string | null
			duration: string
			duration_in_months?: number | null
			livemode: boolean
			max_redemptions?: number | null
			metadata: { [key: string]: string }
			name?: string | null
			percent_off?: number | null
			redeem_by?: number | null
			times_redeemed: number
			valid: boolean
		}
		customer: string
		end: number | null
		start: number
		subscription: string | null
	} | null
	email?: string
	invoice_prefix: string
	invoice_settings: {
		custom_fields?: Array<{ name: string; value: string }> | null
		default_payment_method?: string | null
		footer?: string | null
	}
	livemode: boolean
	metadata: { [key: string]: string }
	name?: string
	next_invoice_sequence: number
	phone?: string
	preferred_locales: string[]
	shipping?: {
		address: {
			city?: string
			country?: string
			line1: string
			line2?: string
			postal_code?: string
			state?: string
		}
		name: string
		phone?: string
	}
	sources?: {
		object: string
		data: Array<{
			id: string
			object: string
			brand: string
			country: string
			customer: string
			exp_month: number
			exp_year: number
			last4: string
			metadata: { [key: string]: string }
		}>
		has_more: boolean
		total_count: number
		url: string
	}
	subscriptions?: {
		object: string
		data: Array<{
			id: string
			object: string
			application_fee_percent?: number | null
			billing_cycle_anchor: number
			billing_thresholds?: { amount_gte: number; reset_billing_cycle_anchor: boolean } | null
			cancel_at?: number | null
			cancel_at_period_end: boolean
			canceled_at?: number | null
			collection_method: string
			created: number
			current_period_end: number
			current_period_start: number
			customer: string
			days_until_due?: number | null
			default_payment_method?: string | null
			default_source?: string | null
			discount?: {
				coupon: {
					id: string
					object: string
					amount_off?: number | null
					created: number
					currency?: string | null
					duration: string
					duration_in_months?: number | null
					livemode: boolean
					max_redemptions?: number | null
					metadata: { [key: string]: string }
					name?: string | null
					percent_off?: number | null
					redeem_by?: number | null
					times_redeemed: number
					valid: boolean
				}
				customer: string
				end: number | null
				start: number
				subscription: string | null
			} | null
			ended_at?: number | null
			items: {
				object: string
				data: Array<{
					id: string
					object: string
					billing_thresholds?: { usage_gte: number } | null
					created: number
					metadata: { [key: string]: string }
					plan: {
						id: string
						object: string
						active: boolean
						aggregate_usage?: string | null
						amount?: number | null
						amount_decimal?: string | null
						billing_scheme: string
						created: number
						currency: string
						interval: string
						interval_count: number
						livemode: boolean
						metadata: { [key: string]: string }
						nickname?: string | null
						product: string
						tiers_mode?: string | null
						transform_usage?: { divide_by: number; round: string } | null
						trial_period_days?: number | null
						usage_type: string
					}
					price: {
						id: string
						object: string
						active: boolean
						billing_scheme: string
						created: number
						currency: string
						custom_unit_amount?: {
							enabled: boolean
							minimum?: number | null
							maximum?: number | null
							preset?: number | null
						} | null
						livemode: boolean
						lookup_key?: string | null
						metadata: { [key: string]: string }
						nickname?: string | null
						product: string
						recurring?: {
							aggregate_usage?: string | null
							interval: string
							interval_count: number
							trial_period_days?: number | null
							usage_type: string
						} | null
						tiers_mode?: string | null
						transform_usage?: { divide_by: number; round: string } | null
						type: string
						unit_amount?: number | null
						unit_amount_decimal?: string | null
					}
					quantity?: number | null
					subscription: string
					tax_rates: Array<{
						id: string
						object: string
						active: boolean
						country: string
						created: number
						description: string
						display_name: string
						inclusive: boolean
						jurisdiction?: string | null
						livemode: boolean
						metadata: { [key: string]: string }
						percentage: number
					}>
				}>
				has_more: boolean
				total_count: number
				url: string
			}
			latest_invoice?: string | null
			livemode: boolean
			metadata: { [key: string]: string }
			next_pending_invoice_item_invoice?: number | null
			pause_collection?: {
				behavior: string
				resumes_at?: number | null
			} | null
			pending_invoice_item_interval?: { interval: string; interval_count: number } | null
			pending_setup_intent?: string | null
			pending_update?: {
				billing_cycle_anchor?: number | null
				expires_at: number
				subscription_items: Array<{
					id: string
					object: string
					billing_thresholds?: { usage_gte: number } | null
					created: number
					metadata: { [key: string]: string }
					plan: {
						id: string
						object: string
						active: boolean
						aggregate_usage?: string | null
						amount?: number | null
						amount_decimal?: string | null
						billing_scheme: string
						created: number
						currency: string
						interval: string
						interval_count: number
						livemode: boolean
						metadata: { [key: string]: string }
						nickname?: string | null
						product: string
						tiers_mode?: string | null
						transform_usage?: { divide_by: number; round: string } | null
						trial_period_days?: number | null
						usage_type: string
					}
					price: {
						id: string
						object: string
						active: boolean
						billing_scheme: string
						created: number
						currency: string
						custom_unit_amount?: {
							enabled: boolean
							minimum?: number | null
							maximum?: number | null
							preset?: number | null
						} | null
						livemode: boolean
						lookup_key?: string | null
						metadata: { [key: string]: string }
						nickname?: string | null
						product: string
						recurring?: {
							aggregate_usage?: string | null
							interval: string
							interval_count: number
							trial_period_days?: number | null
							usage_type: string
						} | null
						tiers_mode?: string | null
						transform_usage?: { divide_by: number; round: string } | null
						type: string
						unit_amount?: number | null
						unit_amount_decimal?: string | null
					}
					quantity?: number | null
					subscription: string
					tax_rates: Array<{
						id: string
						object: string
						active: boolean
						country: string
						created: number
						description: string
						display_name: string
						inclusive: boolean
						jurisdiction?: string | null
						livemode: boolean
						metadata: { [key: string]: string }
						percentage: number
					}>
				}>
				trial_end?: number | null
			} | null
			schedule?: {
				id: string
				object: string
				canceled_at?: number | null
				completed_at?: number | null
				created: number
				current_phase?: {
					end_date: number
					start_date: number
				}
				customer: string
				default_settings: {
					application_fee_percent?: number | null
					billing_cycle_anchor: string
					billing_thresholds?: { amount_gte: number; reset_billing_cycle_anchor: boolean } | null
					collection_method?: string | null
					description?: string | null
					invoice_settings?: {
						custom_fields?: Array<{ name: string; value: string }> | null
						default_payment_method?: string | null
						footer?: string | null
					} | null
					metadata: { [key: string]: string }
					on_behalf_of?: string | null
					transfer_data?: {
						amount_percent?: number | null
						destination: string
					} | null
				}
				end_behavior: string
				livemode: boolean
				metadata: { [key: string]: string }
				phases: Array<{
					application_fee_percent?: number | null
					billing_cycle_anchor?: number | null
					billing_thresholds?: { amount_gte: number; reset_billing_cycle_anchor: boolean } | null
					collection_method?: string | null
					coupon?: string | null
					default_payment_method?: string | null
					description?: string | null
					end_date: number
					invoice_settings?: {
						custom_fields?: Array<{ name: string; value: string }> | null
						default_payment_method?: string | null
						footer?: string | null
					} | null
					items: Array<{
						billing_thresholds?: { usage_gte: number } | null
						plan: string
						price: string
						quantity?: number | null
						tax_rates?: Array<{
							id: string
							object: string
							active: boolean
							country: string
							created: number
							description: string
							display_name: string
							inclusive: boolean
							jurisdiction?: string | null
							livemode: boolean
							metadata: { [key: string]: string }
							percentage: number
						}>
					}>
					metadata: { [key: string]: string }
					on_behalf_of?: string | null
					start_date: number
					transfer_data?: {
						amount_percent?: number | null
						destination: string
					} | null
					trial_end?: number | null
				}>
				status: string
				subscription: string
			} | null
			start_date: number
			status: string
			transfer_data?: {
				amount_percent?: number | null
				destination: string
			} | null
			trial_end?: number | null
			trial_start?: number | null
		}>
		has_more: boolean
		total_count: number
		url: string
	}
	tax_exempt: string
	tax_ids: {
		object: string
		data: Array<{
			id: string
			object: string
			country: string
			created: number
			customer: string
			livemode: boolean
			type: string
			value: string
			verification?: {
				status: string
				verified_address?: string | null
				verified_name?: string | null
			} | null
		}>
		has_more: boolean
		total_count: number
		url: string
	}

	constructor(record?: StripeCustomer) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
		}
	}
}

export class StripePaymentMethod {
	id: string
	object: string
	billing_details: {
		address: {
			city?: string | null
			country?: string | null
			line1?: string | null
			line2?: string | null
			postal_code?: string | null
			state?: string | null
		}
		email?: string | null
		name?: string | null
		phone?: string | null
	}
	acss_debit: {
		last_response: string
		bank_name: string
		fingerprint: string
		institution_number: string
		last4: string
		transit_number: string
	}
	card?: {
		brand: string
		checks: {
			address_line1_check?: string | null
			address_postal_code_check?: string | null
			cvc_check?: string | null
		}
		country: string
		exp_month: number
		exp_year: number
		fingerprint: string
		funding: string
		last4: string
		networks?: {
			available: string[]
			preferred?: string | null
		}
		three_d_secure_usage?: {
			supported: boolean
		}
		wallet?: {
			type: string
			apple_pay?: {
				dynamic_last4?: string | null
			}
			google_pay?: {
				dynamic_last4?: string | null
			}
			samsung_pay?: {
				dynamic_last4?: string | null
			}
			masterpass?: {
				billing_address?: {
					city?: string | null
					country?: string | null
					line1?: string | null
					line2?: string | null
					postal_code?: string | null
					state?: string | null
				}
				email?: string | null
				name?: string | null
				shipping_address?: {
					city?: string | null
					country?: string | null
					line1?: string | null
					line2?: string | null
					postal_code?: string | null
					state?: string | null
				}
			}
			visa_checkout?: {
				billing_address?: {
					city?: string | null
					country?: string | null
					line1?: string | null
					line2?: string | null
					postal_code?: string | null
					state?: string | null
				}
				email?: string | null
				name?: string | null
				shipping_address?: {
					city?: string | null
					country?: string | null
					line1?: string | null
					line2?: string | null
					postal_code?: string | null
					state?: string | null
				}
			}
		}
	}
	created: number
	customer?: string | null
	livemode: boolean
	metadata: { [key: string]: string }
	type: string
	au_becs_debit?: {
		bsb_number: string
		fingerprint: string
		last4: string
	}
	bacs_debit?: {
		fingerprint: string
		last4: string
		sort_code: string
	}
	bancontact?: {
		bank_code?: string | null
		bank_name?: string | null
		bic?: string | null
		iban_last4?: string | null
		preferred_language?: string | null
	}
	eps?: {
		bank?: string | null
	}
	fpx?: {
		account_holder_type?: string | null
		bank: string
	}
	giropay?: {
		bank_code?: string | null
		bank_name?: string | null
		bic?: string | null
		iban_last4?: string | null
	}
	grabpay?: {}
	ideal?: {
		bank?: string | null
		bic?: string | null
		iban_last4?: string | null
	}
	interac_present?: {
		card_number?: string | null
		cardholder_name?: string | null
		emv_auth_data?: string | null
		exp_month?: number | null
		exp_year?: number | null
		fingerprint?: string | null
		funding?: string | null
		generated_card?: string | null
		last4?: string | null
		network?: string | null
		preferences?: {
			preferred_format: string
		}
	}
	klarna?: {
		preferred_locale?: string | null
	}
	konbini?: {
		store: {
			chain: string
			store_code?: string | null
			store_name?: string | null
		}
	}
	p24?: {
		bank?: string | null
	}
	sepa_debit?: {
		bank_code?: string | null
		bank_name?: string | null
		bic?: string | null
		fingerprint: string
		iban_last4: string
	}
	sofort?: {
		bank_code?: string | null
		bank_name?: string | null
		bic?: string | null
		country?: string | null
		iban_last4?: string | null
	}
	us_bank_account?: {
		account_holder_type: string
		account_type?: string | null
		bank_name: string
		fingerprint: string
		last4: string
		routing_number: string
	}
	constructor(record?: StripeCustomer) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
		}
	}
}
