export class TableFilterButton {
	id: string = null
	count: () => number = () => 0
	label = ''
}

export class TableFilterManager {
	buttons: Array<TableFilterButton> = []
	selectedBtn: TableFilterButton = null

	setSelectedBtnById(id: string) {
		const btn = this.buttons.find((btn) => btn.id === id)
		if (btn) this.selectedBtn = btn
	}
}
