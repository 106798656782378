import { Component, OnInit, OnDestroy, AfterViewInit, Renderer2 } from '@angular/core'
import { TableActionFormatter, log, DisplayHelper, GeneralTableHelper, DataTablesHelper } from '@app/helpers'
import {
	CrudAction,
	Client,
	DialogManager,
	DatabaseTableName,
	ContactRecord,
	SecureFileDialogData,
	AddressHelper,
	LinkedRecordsManager,
	ContactOrganizationLinkCard,
	LinkContactOrgHostRecordUpdateEvent,
	LocalPrefsData,
	LocalPrefsGroup,
	LocalPrefsDialogData,
	ClickToCallRecord,
	ClickToCallGlobalConfig,
	ComponentBridgeName,
} from '@app/models'
import { AccessHelper } from '@app/helpers/access'
import { Subscription } from 'rxjs'
import { CoreService, DatabaseService } from '@app/services'
import _ from 'lodash'
import { ContactsFormatter } from '../formatter'

enum ContactTableColumnIndex {
	id,
	name,
	// type,
	linkedOrgs,
	phone,
	email,
	address,
	map,
	notifyPhone,
	notifyText,
	notifyEmail,
	notes,
	files,
	actions,
}

@Component({
    selector: 'app-contact-table',
    templateUrl: './contact-table.component.html',
    styleUrls: ['./contact-table.component.scss'],
    standalone: false
})
export class ContactTableComponent implements OnInit, OnDestroy, AfterViewInit {
	// accessHelper: AccessHelper

	bridgeName: ComponentBridgeName = 'ngBridgeContactTable'

	list: Array<ContactRecord> = []
	isDataLoaded = false
	isPrimaryOrInternalUser = false
	isPrimaryInternalOrManager = false

	editDialogManager = new DialogManager()
	editAction = { recordId: null, action: 'edit' }

	deleteAction = {
		tableName: 'contact' as DatabaseTableName,
		recordId: null,
		recordLabel: null,
		showDeleteRecordDialog: false,
	}

	linkedOrgRecordsDialogManager = new DialogManager('linkedOrgRecordsModal')
	linkedOrgRecordsManager = new LinkedRecordsManager<ContactOrganizationLinkCard>()

	sectionPrefsDialogManager = new DialogManager('sectionPrefsDialog')
	secureFilesDialogManager = new DialogManager('secureFilesDialog')

	showDetailsModal = false
	showFilterDatesModal = false
	showDiffRecordsModal = false

	canViewManagerColumn = false
	canConfigurePrefs = false
	highlightMissingExternalIds = false

	isManager = false
	managedUserIds = []

	private contactTable: any
	private defaultSortOrder = [[ContactTableColumnIndex.name, 'asc']]
	private defaultPageLength = 50

	public debounceRedrawTable = _.debounce(this.redrawTable, 300)

	private subs = new Subscription()

	private generalTableHelper: GeneralTableHelper
	private addressHelper: AddressHelper

	private clickToCallEnabled = { backend: false, browser: false } // Keep this. Need availability check here for buuttons

	constructor(
		private coreSrvc: CoreService,
		private renderer: Renderer2,
	) {
		this.setupSubscriptions()
		this.setupC2CAvailability()
		this.setupAccessPermissions()
		this.setupComponent()
		this.setupLocalPrefsDialog()

		// Setup table helpers
		this.addressHelper = new AddressHelper(coreSrvc.dbSrvc)

		// Check for missing ID highlight requirement
		this.highlightMissingExternalIds = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany().globalHighlightMissingExtId
	}

	get canAccessC2CBtns(): boolean {
		return this.clickToCallEnabled.backend || this.clickToCallEnabled.browser
	}

	// Linked Records Filter Methods
	get dtFilterText(): string {
		const input = $('.search-field-input')
		return (input?.val() as string) || ''
	}

	ngOnInit() {
		window[this.bridgeName] = this
		this.loadData()
	}

	ngOnDestroy() {
		this.contactTable['fixedHeader'].disable()
		window[this.bridgeName] = null
		this.subs.unsubscribe()
	}

	ngAfterViewInit() {
		this.initTable()

		$('#contactTable_filter input').attr('placeholder', ' filter')
		$('#contactTable_filter input').addClass('search-field-input')

		setTimeout(() => {
			$('#clear-search-icon').detach().appendTo('#contactTable_filter label')
		})

		// Handle count bubble search highlighting
		$('#contactTable_filter input').on('keyup', () => {
			this.debounceRedrawTable()
		})

		this.fetchAndReloadData()
	}

	redrawTable() {
		this.contactTable?.rows().invalidate().search(this.dtFilterText).draw(true)
		this.coreSrvc.displaySrvc.enableAllTooltips()
	}
	// Linked Records Filter Methods

	private setupSubscriptions() {
		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	private setupAccessPermissions() {
		// this.accessHelper = new AccessHelper(this.coreSrvc.dbSrvc, 'contact')
		// this.accessHelper.updateSupervisorIds()
	}

	private setupC2CAvailability() {
		const portalPrefs = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany()
		const backEndEnabled = this.coreSrvc.dbSrvc.settingSrvc.isClickToCallEnabled()

		// Setup Click to Call availability
		this.clickToCallEnabled.backend = backEndEnabled
		this.clickToCallEnabled.browser = portalPrefs.globalBrowserClickToCall
	}

	private setupLocalPrefsDialog(): void {
		this.sectionPrefsDialogManager.headerLabel = 'People Preferences'
		const columnVisibilityItems = LocalPrefsData.getContactTableColumnDisplayPrefs()
		const columnVisibilityGroup = new LocalPrefsGroup('Column Visibility', columnVisibilityItems)
		const dialogData = new LocalPrefsDialogData([columnVisibilityGroup])
		dialogData.localStorageKeyRemovalList = ['DataTables_contactTable']
		this.sectionPrefsDialogManager.dialogData = dialogData
	}

	private handleScreenSizeChanges() {
		if (this.contactTable) {
			this.contactTable.columns.adjust().responsive.recalc()
			this.contactTable.fixedHeader.adjust()
		}
	}

	public prefsDataSaved(): void {
		log('UpdatePrefsData')
		this.sectionPrefsDialogManager.isDialogVisible = false
		this.updateTable()
	}

	canPerformAction(action: CrudAction, isMyRecord: boolean) {
		return true // this.accessHelper.canPerformAction(action, isMyRecord)
	}
	isMyRecord(id: number): boolean {
		return true
	}

	// Manage UI
	setupComponent() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
	}

	applySearchBoxHack() {
		$('#clear-search-icon').detach().appendTo('#contactTable_filter label').show()
		$('#contactTable_filter input').attr('placeholder', ' filter')
		$('#contactTable_filter input').addClass('search-field-input')
	}

	enableAllTooltips() {
		// Enable Tooltips
		const tooltips: any = $('.item-tooltip')
		tooltips.tooltip({ show: { effect: 'none', delay: 0 } })
		// Delay long enough to hide tooltips if cursor was hovering during page load
		setTimeout(() => {
			tooltips.tooltip('hide')
		}, 1000)
	}

	clearSearch(): boolean {
		this.contactTable
			.search('')
			.order([[ContactTableColumnIndex.name, 'asc']])
			.rows()
			.invalidate()
			.draw()
		return false
	}

	fetchData(): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			this.coreSrvc.dbSrvc.bulkRead(['contact', 'vendor_client_org']).then((result) => {
				this.coreSrvc.dbSrvc.contactSrvc.lastUpdated = new Date()
				resolve(true)
			})
		})
	}

	loadData() {
		const currentView = this.coreSrvc.dbSrvc.contactSrvc.viewManager.currentView
		this.list = this.coreSrvc.dbSrvc.contactSrvc.getContacts()
	}

	fetchAndReloadData() {
		this.fetchData().then((success) => {
			this.updateTable()
		})
	}

	updateColumns() {
		// const type = this.coreSrvc.dbSrvc.contactSrvc.viewManager.currentView === ContactTableDisplayState.all
		const linkedJobs = this.coreSrvc.prefSrvc.data.contactColLinkedOrgsVisible
		const phone = this.coreSrvc.prefSrvc.data.contactColPhoneVisible
		const email = this.coreSrvc.prefSrvc.data.contactColEmailVisible
		const address = this.coreSrvc.prefSrvc.data.contactColAddressVisible
		const map = this.coreSrvc.prefSrvc.data.contactColMapVisible
		const notifyPhone = this.coreSrvc.prefSrvc.data.contactColNotifyPhoneVisible
		const notifyText = this.coreSrvc.prefSrvc.data.contactColNotifyTextVisible
		const notifyEmail = this.coreSrvc.prefSrvc.data.contactColNotifyEmaiVisible
		const notes = this.coreSrvc.prefSrvc.data.contactColNotesVisible
		const files = this.coreSrvc.prefSrvc.data.contactColFilesVisible

		// Set column visibility
		// GeneralTableHelper.updateColumn(this.contactTable, ContactTableColumnIndex.type, type)
		GeneralTableHelper.updateColumn(this.contactTable, ContactTableColumnIndex.linkedOrgs, linkedJobs)
		GeneralTableHelper.updateColumn(this.contactTable, ContactTableColumnIndex.phone, phone)
		GeneralTableHelper.updateColumn(this.contactTable, ContactTableColumnIndex.email, email)
		GeneralTableHelper.updateColumn(this.contactTable, ContactTableColumnIndex.address, address)
		GeneralTableHelper.updateColumn(this.contactTable, ContactTableColumnIndex.map, map)
		GeneralTableHelper.updateColumn(this.contactTable, ContactTableColumnIndex.notifyPhone, notifyPhone)
		GeneralTableHelper.updateColumn(this.contactTable, ContactTableColumnIndex.notifyText, notifyText)
		GeneralTableHelper.updateColumn(this.contactTable, ContactTableColumnIndex.notifyEmail, notifyEmail)
		GeneralTableHelper.updateColumn(this.contactTable, ContactTableColumnIndex.notes, notes)
		GeneralTableHelper.updateColumn(this.contactTable, ContactTableColumnIndex.files, files)

		this.contactTable.clear()
		this.contactTable.rows.add(this.list)
		this.contactTable.draw(false)
		// this.contactTable.columns.adjust().responsive.recalc() // Moved to drawCallback
	}

	updateTable() {
		log('contactTable - updateTable')
		this.managedUserIds = this.coreSrvc.dbSrvc.settingSrvc.getManagedUserIds()

		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadData()
			this.updateColumns()
		})
	}

	createRecord() {
		log('createRecord called')
		// if (!this.canPerformAction(CrudAction.create, null)) {
		// 	this.accessHelper.notifyOperationNotAuthorized()
		// 	return
		// }
		this.editAction.recordId = null
		this.editAction.action = 'new'
		this.editDialogManager.dialogData = this.editAction
		this.editDialogManager.headerLabel = 'Add Contact'
		this.editDialogManager.isDialogVisible = true
	}

	// Bridge Methods
	public editRecord(id: number) {
		log('editRecord called')
		const isMyRecord = this.isMyRecord(id)
		if (!this.canPerformAction(CrudAction.update, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}
		const record = this.coreSrvc.dbSrvc.contactSrvc.getContactById(id)
		if (record) {
			this.editAction.recordId = id
			this.editAction.action = 'edit'
			this.editDialogManager.headerLabel = 'Edit Contact'
			this.editDialogManager.dialogData = this.editAction
			this.editDialogManager.isDialogVisible = true
		}
	}

	openFilesDialog(id: number) {
		log('Open Files Dialog')
		const isMyRecord = this.isMyRecord(id)
		if (!this.canPerformAction(CrudAction.update, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}
		const headerLabel = this.coreSrvc.dbSrvc.contactSrvc.getContactById(id).name
		this.secureFilesDialogManager.dialogData = new SecureFileDialogData('CONTACT', id, null)
		this.secureFilesDialogManager.headerLabel = headerLabel
		this.secureFilesDialogManager.isDialogVisible = true
	}

	updateRecord(id: number) {
		log('Update Contact', id)
		if (id) {
			this.coreSrvc.dbSrvc.readRecord('contact', id).then((result) => {
				this.loadData()
				const record = this.coreSrvc.dbSrvc.contactSrvc.getContactById(id)
				const idx = this.list.indexOf(record)
				this.contactTable.rows(idx).invalidate().draw(false)
			})
		} else {
			this.updateTable()
		}
	}

	recordUpdated() {
		log('recordUpdaated: Contact record has been updated')
		this.loadData()
		this.updateTable()
	}

	deleteRecord(id: number) {
		log('deleteRecord called')
		const isMyRecord = this.isMyRecord(id)
		if (!this.canPerformAction(CrudAction.delete, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}
		const record = this.coreSrvc.dbSrvc.contactSrvc.getContactById(id)
		const isLinked = false // this.coreSrvc.dbSrvc.checkAssignment(id)
		if (isLinked) {
			this.coreSrvc.notifySrvc.notify(
				'error',
				'Contact Assigned',
				'In order to delete this contact you must unlink them from all other records.',
				5,
			)
			return
		}
		if (record) {
			this.deleteAction.recordId = record.id
			this.deleteAction.recordLabel = record.name
			this.deleteAction.tableName = 'contact'
			this.deleteAction.showDeleteRecordDialog = true
		}
	}

	deleteActionComplete() {
		this.loadData()
		this.updateTable()
		this.deleteAction.showDeleteRecordDialog = false
	}

	deleteActionCancel() {
		this.deleteAction.showDeleteRecordDialog = false
	}

	// Manage Linked Org Records

	public contactOrgRecordSaved(event: LinkContactOrgHostRecordUpdateEvent) {
		log('Contact Table - contactOrgRecordSaved', event)
		const contactId = event.id
		if (contactId) {
			this.coreSrvc.dbSrvc.readRecord('contact', contactId).then(() => {
				const record = this.coreSrvc.dbSrvc.contactSrvc.getContactById(contactId)
				this.invalidateRowForRecord(record)
				this.buildContactOrgLinkData(contactId)
			})
		}
	}

	public showLinkedRecords(id: number, type: string) {
		let headerLabel = ''
		switch (type) {
			case 'organization':
				const record = this.coreSrvc.dbSrvc.contactSrvc.getContactById(id)
				headerLabel = 'Linked Clients / Vendors'
				this.buildContactOrgLinkData(id)
				this.linkedOrgRecordsDialogManager.headerLabel = headerLabel
				this.linkedOrgRecordsDialogManager.isSubmitBtnVisible = true
				this.linkedOrgRecordsDialogManager.submitBtnLabel = 'New'
				this.linkedOrgRecordsDialogManager.cancelBtnLabel = 'Close'
				this.linkedOrgRecordsDialogManager.dialogData = record
				this.linkedOrgRecordsDialogManager.isDialogVisible = true
				break
		}
	}

	private buildContactOrgLinkData(id: number) {
		this.linkedOrgRecordsManager.records = this.coreSrvc.dbSrvc.contactSrvc
			.getContactById(id)
			.vendor_client_contact.map((rec) => new ContactOrganizationLinkCard(this.coreSrvc.dbSrvc, rec, 'CONTACT'))
		this.linkedOrgRecordsManager.filteredRecords = [...this.linkedOrgRecordsManager.records]
	}

	// public filterLinkedOrgRecords(searchText: string) {
	// 	log('Search', searchText)
	// 	if (!searchText) {
	// 		this.linkedOrgRecordsManager.filteredRecords = [...this.linkedOrgRecordsManager.records]
	// 	} else {
	// 		this.linkedOrgRecordsManager.filteredRecords = this.linkedOrgRecordsManager.records.filter((rec: ContactOrganizationLinkCard) =>
	// 			(rec.organization.name + rec.linkRecord.details).toLowerCase().includes(searchText)
	// 		)
	// 	}
	// }

	public jsBridgeToggleProperty(id: number, prop: string) {
		const isMyRecord = true // this.accessHelper.isMyRecord(id, 'organization')
		if (!this.canPerformAction(CrudAction.update, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}
		log('Toggling', id, prop)
		this.coreSrvc.workSrvc.blockWorking()
		const record = this.coreSrvc.dbSrvc.contactSrvc.getContactById(id)
		record[prop] = !record[prop]
		this.coreSrvc.dbSrvc.updateRecord('contact', record).then((success) => {
			if (success) {
				this.invalidateRowForRecord(record)
			} else {
				record[prop] = !record[prop]
			}
			this.coreSrvc.workSrvc.unblockWorking()
		})
	}

	notifyOperationNotAuthorized() {
		this.coreSrvc.notifySrvc.default('operationNotAuthorized')
	}

	invalidateRowForRecord(record: ContactRecord) {
		log('Invalidated', record)
		const idx = this.list.indexOf(record)
		this.contactTable.rows(idx).invalidate().draw(false)
	}

	public setPage(num: number) {
		this.contactTable?.page(num)
	}

	public handleC2CEvent(id: number, column, action: 'CALL' | 'TEXT') {
		const record = this.coreSrvc.dbSrvc.contactSrvc.getContactById(id)
		let name = ''
		let number = ''
		if (column === 'PHONE') {
			name = record.first + ' ' + record.last || 'Unknown'
			number = record.phone_e164
		}
		if (name && number) {
			const c2cRecord = new ClickToCallRecord('CONTACT', name, null, number, null)
			const config = new ClickToCallGlobalConfig(c2cRecord, action, null, null)
			this.coreSrvc.eventSrvc.clickToCallGlobalEvent.next(config)
		}
		log('handleC2CEvent', id, column, action, record)
	}

	// DataTables Initialization

	private initTable() {
		this.coreSrvc.displaySrvc.startSectionLoader()
		this.contactTable = $('#contactTable').DataTable(<DataTables.Settings>{
			stateSave: false,
			responsive: true,
			processing: true,
			paging: true,
			pageLength: this.defaultPageLength,
			lengthChange: true,
			info: true,
			select: false,
			searching: true,
			fixedHeader: DataTablesHelper.fixedHeader,
			autoWidth: false,
			data: this.list,
			order: this.defaultSortOrder,
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{
					responsivePriority: 1,
					targets: [ContactTableColumnIndex.name],
				},
				{
					responsivePriority: 2,
					targets: [ContactTableColumnIndex.phone],
				},
				{
					responsivePriority: 3,
					targets: [ContactTableColumnIndex.linkedOrgs],
				},
				{
					responsivePriority: 4,
					targets: [ContactTableColumnIndex.notifyPhone, ContactTableColumnIndex.notifyText, ContactTableColumnIndex.notifyEmail],
				},
				{
					responsivePriority: 5,
					targets: [ContactTableColumnIndex.notes],
				},
				{
					responsivePriority: 6,
					targets: [ContactTableColumnIndex.actions],
				},
				{
					// ID
					targets: ContactTableColumnIndex.id,
					visible: false,
					searchable: true,
					title: 'ID',
					data: null,
					render: (rec: Client, t, f, m) => {
						return rec.id
					},
				},
				{
					// Name
					targets: ContactTableColumnIndex.name,
					visible: true,
					searchable: true,
					title: 'Name',
					data: null,
					render: (rec: ContactRecord, t, f, m) => {
						const name = rec.name || ''
						const externalId = rec.external_id ? `<div class="table-ext-id-normal">${rec.external_id}</div>` : ''
						const noExternalId =
							this.highlightMissingExternalIds && !rec.external_id ? `<div class="table-tag bg-chocolate">No ID Set</div>` : ''

						const nameHtml = `<div>${name}</div>`
						return `
						<div class="dtr-control-content">
							<div class="table-name">${nameHtml}${externalId}${noExternalId}</div>
						</div>`
						// return `<div style="white-space: normal">${rec.name}</div>`
					},
				},
				// {
				// 	// Type
				// 	targets: ContactTableColumnIndex.type,
				// 	visible: false,
				// 	searchable: true,
				// 	title: 'Type',
				// 	data: null,
				// 	render: (rec: ContactRecord, t, f, m) => {
				// 		const type = rec.type
				// 		const tagClass = type === 'CLIENT' ? 'table-tag-client' : 'table-tag-vendor'
				// 		const label = rec.type === 'CLIENT' ? 'Client' : 'Vendor'
				// 		return `<span class="table-tag ${tagClass}" style="width:60px;">${label}</span>`
				// 	},
				// },
				{
					// Linked Organizations
					targets: ContactTableColumnIndex.linkedOrgs,
					visible: this.coreSrvc.prefSrvc.data.contactColLinkedOrgsVisible,
					searchable: false,
					title: 'Client /<br>Vendor',
					data: null,
					render: (rec: Client, t, f, m) => {
						// return ContactsFormatter.makeLinkedRecordsButton(rec.id, 'organization')
						return ContactsFormatter.makeLinkedRecordsButton(this.coreSrvc.dbSrvc, this.bridgeName, this.dtFilterText, rec.id, 'organization')
					},
				},
				{
					// Phone
					targets: ContactTableColumnIndex.phone,
					visible: this.coreSrvc.prefSrvc.data.contactColPhoneVisible,
					searchable: this.coreSrvc.prefSrvc.data.contactColPhoneVisible,
					title: 'Phone',
					data: null,
					render: (rec: ContactRecord, t, f, m) => {
						return GeneralTableHelper.makePHoneNumberWithC2C(
							rec.id,
							rec.phone_e164,
							'PHONE',
							this.canAccessC2CBtns,
							this.bridgeName,
							'handleC2CEvent',
						)
						// const phone = rec.phone_e164
						// const formattedPhone = DisplayHelper.displayPhone(phone)
						// return `<div style="white-space: nowrap">${formattedPhone}</div>`
					},
				},
				{
					// Email
					targets: ContactTableColumnIndex.email,
					visible: this.coreSrvc.prefSrvc.data.contactColEmailVisible,
					searchable: this.coreSrvc.prefSrvc.data.contactColEmailVisible,
					title: 'Email',
					data: null,
					render: (rec: ContactRecord, t, f, m) => {
						return `<div class="table-email">${rec.email || ''}</div>`
					},
				},
				{
					// Address
					targets: ContactTableColumnIndex.address,
					visible: this.coreSrvc.prefSrvc.data.contactColAddressVisible,
					searchable: this.coreSrvc.prefSrvc.data.contactColAddressVisible,
					title: 'Address',
					data: null,
					render: (rec: ContactRecord, t, f, m) => {
						return this.addressHelper.addressFormatter(rec)
					},
				},
				{
					// Map
					targets: ContactTableColumnIndex.map,
					visible: this.coreSrvc.prefSrvc.data.contactColMapVisible,
					searchable: this.coreSrvc.prefSrvc.data.contactColMapVisible,
					title: 'Map',
					data: null,
					render: (emp: ContactRecord, t, f, m) => {
						return this.addressHelper.mapFormatter(emp)
					},
				},
				{
					// Notify Phone
					targets: ContactTableColumnIndex.notifyPhone,
					visible: this.coreSrvc.prefSrvc.data.contactColNotifyPhoneVisible,
					searchable: false,
					title: 'Notify<br>Phone',
					data: null,
					render: (rec: ContactRecord, t, f, m) => {
						return TableActionFormatter.toggleCheckbox(this.bridgeName, rec, 'notify_phone', '20px')
					},
				},
				{
					// Notify SMS
					targets: ContactTableColumnIndex.notifyText,
					visible: this.coreSrvc.prefSrvc.data.contactColNotifyTextVisible,
					searchable: false,
					title: 'Notify<br>Text',
					data: null,
					render: (rec: ContactRecord, t, f, m) => {
						return TableActionFormatter.toggleCheckbox(this.bridgeName, rec, 'notify_sms', '20px')
					},
				},
				{
					// Notify Email
					targets: ContactTableColumnIndex.notifyEmail,
					visible: this.coreSrvc.prefSrvc.data.contactColNotifyEmaiVisible,
					searchable: false,
					title: 'Notify<br>Email',
					data: null,
					render: (rec: ContactRecord, t, f, m) => {
						return TableActionFormatter.toggleCheckbox(this.bridgeName, rec, 'notify_email', '20px')
					},
				},
				{
					// Notes / Details
					targets: ContactTableColumnIndex.notes,
					visible: this.coreSrvc.prefSrvc.data.contactColNotesVisible,
					searchable: this.coreSrvc.prefSrvc.data.contactColNotesVisible,
					title: 'Notes',
					data: null,
					render: (rec: ContactRecord, t, f, m) => {
						const details = rec.details || ''
						return details ? `<div class="table-note-block"><div class="table-note-copy">${details}</div></div>` : ''
					},
				},
				{
					// Files
					targets: ContactTableColumnIndex.files,
					visible: this.coreSrvc.prefSrvc.data.contactColFilesVisible,
					searchable: false,
					title: 'Files',
					data: null,
					render: (rec: ContactRecord, t, f, m) => {
						return GeneralTableHelper.makeFilesCountBubble(rec.id, rec.file_uploads_metadata, this.bridgeName, 'openFilesDialog')
					},
				},
				{
					// Actions
					targets: ContactTableColumnIndex.actions,
					visible: true,
					searchable: false,
					orderable: false,
					title: 'Actions',
					data: null,
					render: (rec: ContactRecord, t, f, m) => {
						const recordId = rec.id
						const isMyRecord = this.isMyRecord(rec.id)

						const canAccessEdit = true // this.accessHelper.canPerformAction(CrudAction.update, isMyRecord)
						const editLink = TableActionFormatter.editLink(this.bridgeName, 'editRecord', recordId, canAccessEdit)

						const canAccessDelete = true // this.accessHelper.canPerformAction(CrudAction.delete, isMyRecord)
						const deleteLink = TableActionFormatter.deleteLink(this.bridgeName, 'deleteRecord', recordId, canAccessDelete)

						const auditLink = TableActionFormatter.auditLinkGlobal('contact', recordId, rec.name, true, !!rec.updated)

						return `<span class="act-ico-wrap">${editLink}${deleteLink}${auditLink}</span>`
					},
				},
			],
			drawCallback: (settings) => {
				this.contactTable?.columns.adjust().responsive.recalc()
				this.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
			},
		})
		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter('contactTable', this.contactTable)
	}
}
