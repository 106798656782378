<div class="stat-tag-block">
	<div
		class="badge stat-tag stat-tag-ongoing"
		[class.stat-tag-green]="statTagCount.ongoing > 0"
		(click)="statusCountClicked(TagFilterKey.ongoingShifts)"
	>
		{{ statTagCount.ongoing }} Ongoing
	</div>
	<div class="badge stat-tag stat-tag-noshow" [class.stat-tag-red]="statTagCount.noshow > 0" (click)="statusCountClicked(TagFilterKey.noShowRecent)">
		{{ statTagCount.noshow }} No Show
	</div>
	<div
		class="badge stat-tag stat-tag-missing"
		[class.stat-tag-red]="statTagCount.missing > 0"
		(click)="statusCountClicked(TagFilterKey.missingCheckout)"
	>
		{{ statTagCount.missing }} Missing
	</div>
	<div
		*ngIf="statTagCount.invalid > 0"
		class="badge stat-tag stat-tag-noshow"
		[class.stat-tag-red]="statTagCount.invalid > 0"
		(click)="statusCountClicked(TagFilterKey.invalidHours)"
	>
		{{ statTagCount.invalid }} Invalid
	</div>
	<div
		*ngIf="statTagCount.unassigned > 0"
		class="badge stat-tag stat-tag-unassigned"
		[class.stat-tag-red]="statTagCount.unassigned > 0"
		(click)="statusCountClicked(TagFilterKey.jobUnassigned)"
	>
		{{ statTagCount.unassigned }} Unassigned
	</div>
	<div
		*ngIf="statTagCount.imgIssues > 0"
		class="badge stat-tag stat-tag-unassigned"
		[class.stat-tag-red]="statTagCount.imgIssues > 0"
		(click)="statusCountClicked(TagFilterKey.imageIssues)"
	>
		{{ statTagCount.imgIssues }} Photo{{ statTagCount.imgIssues === 1 ? '' : 's' }}
	</div>
	<div
		*ngIf="statTagCount.clIssues > 0"
		class="badge stat-tag stat-tag-unassigned"
		[class.stat-tag-red]="statTagCount.clIssues > 0"
		(click)="statusCountClicked(TagFilterKey.clIssues)"
	>
		{{ statTagCount.clIssues }} Checklist{{ statTagCount.clIssues === 1 ? '' : 's' }}
	</div>
</div>

<div class="section-table">
	<app-icon-help [rowCount]="this.list.length" [hasHidden]="areColumnsHidden"></app-icon-help>

	<table id="transactionsTable" class="stripe nowrap" cellspacing="0" width="100%"></table>

	<app-table-footer-buttons
		[filename]="'time_entry_records'"
		[newBtnLabel]="'Add Time Entry'"
		[exportBtnLabel]="'Export Records'"
		[lockBtnLabel]="'Lock Records'"
		[table]="'transactionsTable'"
		[marginTop]="'15px'"
		(newBtnClicked)="createRecord()"
		(lockBtnClicked)="openDateLockDialog()"
	></app-table-footer-buttons>
	<i id="clear-search-icon" (click)="clearSearchBtnCLicked()" class="fa fa-times-circle-o" aria-hidden="true"></i>
</div>

<app-shift-details-dialog
	*ngIf="showProximityMap"
	[transId]="currentTransId"
	[linkType]="currentProximityLinkType"
	(closeDialog)="showProximityMap = false; (false)"
></app-shift-details-dialog>

<app-checkpoints-dialog
	*ngIf="showCheckpointsModal"
	[transId]="currentTransId"
	(closeDialog)="showCheckpointsModal = false"
	(recordUpdated)="checkpointUpdated()"
></app-checkpoints-dialog>

<app-basic-modal
	[(visible)]="showBreakEditDialog"
	modalId="breakEditModal"
	[modalHeader]="'Edit Break Time'"
	[modalFooter]=""
	[modalWidth]="450"
	[preventBackgroundClose]="true"
>
	<div *ngIf="showBreakEditDialog">
		<app-break-edit
			[isDialog]="true"
			[transId]="currentTransId"
			style="height: 500px"
			(durationUpdated)="breakLengthUpdated($event)"
		></app-break-edit>
	</div>
</app-basic-modal>

<app-modal-dialog *ngIf="editDialogManager.isDialogVisible" [dialogManager]="editDialogManager" [helpVidKey]="'transactionEditDialog'">
	<app-transaction-edit
		[action]="editAction.action"
		[transactionId]="editAction.transId"
		[modified]="editAction.modified"
		[dialogManager]="editDialogManager"
		(dataUpdated)="updateBreakLength($event)"
		(transNeedsHighlight)="highlightTransWithId($event)"
		(recordUpdated)="editSaved($event)"
		(recordAdded)="newRecordAdded()"
	>
	</app-transaction-edit>
</app-modal-dialog>

<!-- // DEPRECATED 20240730 - Using job schedule popup to with view alert button to open standard edit dialog -->
<!-- <app-basic-modal
	[(visible)]="transMeta.showDialog"
	modalId="transMetaModal"
	[modalHeader]="transMeta.header"
	[modalFooter]="transMeta.footer"
	[modalWidth]="450"
	[preventBackgroundClose]="true"
>
	<div *ngIf="transMeta.showDialog">
		<app-trans-meta-view [transId]="transMeta.transId" [inOut]="transMeta.inOut" [qrcRequired]="validateQRCodes"></app-trans-meta-view>
	</div>
</app-basic-modal> -->

<app-modal-dialog *ngIf="reportsDialogManager.isDialogVisible" [dialogManager]="reportsDialogManager">
	<app-trans-reports-list
		[dialogManager]="reportsDialogManager"
		[transId]="transReports.transId"
		(dataUpdated)="incidentUpdated($event)"
	></app-trans-reports-list>
</app-modal-dialog>

<app-modal-dialog *ngIf="editNotesDialogManager.isDialogVisible" [dialogManager]="editNotesDialogManager">
	<textarea
		*ngIf="editNotesAction.isEditingAdminNotes"
		class="notes-textarea"
		placeholder="Add an admin note"
		style="margin-top: 12px"
		rows="3"
		[(ngModel)]="editNotesAction.notes"
	></textarea>
	<textarea
		*ngIf="!editNotesAction.isEditingAdminNotes"
		class="notes-textarea"
		placeholder="Add an admin note"
		[(ngModel)]="editNotesAction.appendNotes"
	></textarea>
	<div style="margin-top: 20px">
		<p-checkbox [(ngModel)]="editNotesAction.enableNotifications" [binary]="true"></p-checkbox>
		<strong (click)="editNotesAction.enableNotifications = !editNotesAction.enableNotifications" class="has-pointer">Enable Notifications</strong>
		<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('enableNotifications')"></i>
	</div>
	<div class="notes-text">
		This time entry is tracking multiple employee check ins and you may only edit the notes field. The system will update this time entry
		automatically and remove it if all requirements are met.
	</div>
	<hr />
	<div *ngIf="editNotesAction.canEditAdminNotes && editNotesAction.notes" style="margin-top: 12px">
		<div class="link-text" (click)="editNotesAction.isEditingAdminNotes = !editNotesAction.isEditingAdminNotes">
			{{ editNotesAction.isEditingAdminNotes ? 'done editing' : 'edit current notes' }}
		</div>
	</div>
	<div *ngIf="!editNotesAction.isEditingAdminNotes && editNotesAction.notes" style="margin-top: 12px; white-space: pre-wrap">
		<div>{{ editNotesAction.notes | adminNotesFormatter }}</div>
	</div>
</app-modal-dialog>

<p-dialog
	styleClass="p-dialog-noheader"
	[(visible)]="isFetchingTransaction"
	[modal]="false"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[baseZIndex]="5000"
	[dismissableMask]="true"
	[transitionOptions]="'0ms'"
>
	<div class="tts-default-font" style="text-align: center; color: #566978; font-weight: bold">
		<i class="fas fa-loader fa-spin"></i> Validating Entry...
	</div>
</p-dialog>

<!-- DEPRECATED 20240729 - Switched to use the job schedule pop up to view the time entry which will show the alert -->
<!-- <p-dialog
	header="Time Entry Alert"
	[(visible)]="showTransAlertDialog"
	[modal]="false"
	[focusOnShow]="false"
	[style]="{ width: '300px' }"
	[dismissableMask]="true"
>
	<div class="trans-alert-comment">{{ currentAlertComment }}</div>
	<div class="clear-alert-buttons">
		<button class="btn btn-sm btn-secondary" (click)="showTransAlertDialog = false">Cancel</button>
		<span class="float-right">
			<button *ngIf="showTransAlertEdit()" class="btn btn-sm btn-success clear-alert-edit" (click)="showCurrentAlertsDetails()">Details</button>
		</span>
	</div>
</p-dialog> -->

<app-delete-record-dialog
	*ngIf="deleteAction.showDeleteRecordDialog"
	[showDeleteRecordDialog]="deleteAction.showDeleteRecordDialog"
	[tableName]="deleteAction.tableName"
	[recordId]="deleteAction.recordId"
	[recordLabel]="deleteAction.recordLabel"
	[confirmationMsg]="'Please confirm you wish to delete this record. Supervisors will be notifified if you confirm this deletion.'"
	(cancelDelete)="deleteAction.showDeleteRecordDialog = false"
	(actionComplete)="deleteActionComplete()"
></app-delete-record-dialog>

<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Event Dialog"
	[(visible)]="jobScheduleAction.showDialog"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div>
		<button
			*ngIf="jobScheduleAction.hasSchedule"
			class="btn btn-sm btn-block btn-outline-secondary"
			style="margin-bottom: 8px"
			(click)="jobSchedGotoShiftView()"
		>
			Goto Shift View
		</button>
		<button class="btn btn-sm btn-block btn-outline-secondary" (click)="jobSchedFilterForJob()">Filter For Job</button>
		<button
			*ngIf="jobScheduleAction.hasException"
			class="btn btn-sm btn-block btn-outline-danger"
			style="margin-top: 8px"
			(click)="editRecord(jobScheduleAction.trans?.id); jobScheduleAction.showDialog = false"
		>
			View Alert
		</button>
	</div>
</p-dialog>

<app-modal-dialog *ngIf="sectionPrefsDialogManager.isDialogVisible" [dialogManager]="sectionPrefsDialogManager">
	<app-local-prefs [dialogManager]="sectionPrefsDialogManager" (prefsUpdated)="prefsDataSaved()"></app-local-prefs>
</app-modal-dialog>

<app-modal-dialog *ngIf="dupImagesDialogManager.isDialogVisible" [dialogManager]="dupImagesDialogManager">
	<app-duplicate-images
		[transId]="dupImagesDialogManager.dialogData.transId"
		[images]="dupImagesDialogManager.dialogData.images"
	></app-duplicate-images>
</app-modal-dialog>

<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Link Options"
	[(visible)]="shiftSummaryublicLinkAction.isDialogVisible"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div>
		<div style="margin: 10px 0px">
			<div *ngIf="!shiftSummaryublicLinkAction.showConfirmation">
				<button class="btn btn-sm btn-block btn-outline-main" (click)="viewShiftSummaryReportBtnClicked()">View Report</button>
				<button class="btn btn-sm btn-block btn-outline-main" (click)="copyShiftSummaryPublicLinkBtnClicked()">Copy Public Link</button>
				<button class="btn btn-sm btn-block btn-outline-main" (click)="shiftSummaryublicLinkAction.showConfirmation = true">
					Refresh Public Link
				</button>
			</div>
			<div *ngIf="shiftSummaryublicLinkAction.showConfirmation">
				<div style="line-height: 1.2em; font-weight: 600">
					<span style="color: firebrick">Warning: </span>This will refresh the public link for this report and invalidate the original link.
					Public access to this report will only be available using the new link.
				</div>
				<div style="margin-top: 20px">
					<button class="btn btn-sm btn-outline-secondary" (click)="shiftSummaryublicLinkAction.showConfirmation = false">Cancel</button>
					<button class="btn btn-sm btn-outline-danger float-right" (click)="confirmshiftSummaryPublicLinkRefreshBtnClicked()">Continue</button>
				</div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Manage Notifications"
	[(visible)]="notifications.showDialog"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div style="margin: 8px">
		<div>
			<div class="float-right">
				<p-toggleswitch [(ngModel)]="notifications.enabled" binary="true"></p-toggleswitch>
			</div>
			<div *ngIf="notifications.enabled">
				<span class="options-title-inline" style="color: green">Notifications enabled</span>
			</div>
			<div *ngIf="!notifications.enabled">
				<span class="options-title-inline" style="color: firebrick">Notifications disabled</span>
			</div>
		</div>
		<div *ngIf="notifications.enabled" class="options-block" style="margin-top: 16px">
			<div>
				<div *ngIf="notifications.pauseUntil" class="link-text float-right" (click)="clearNotificationPause()">clear</div>
				<div class="options-title-inline">Pause Until</div>
			</div>
			<app-date-time-picker
				[currentDate]="notifications.pauseUntil"
				[pickerType]="'DATETIME'"
				[pickerMode]="'dialog'"
				[format]="is12HourFormat ? 'ddd MMM Do h:mm a' : 'ddd MMM Do HH:mm'"
				(pickerDidClose)="notifications.pauseUntil = $event"
			></app-date-time-picker>
		</div>
		<div style="margin-top: 24px">
			<div class="row">
				<div class="col-6">
					<button class="btn btn-sm btn-block btn-secondary" (click)="notifications.showDialog = false">Cancel</button>
				</div>
				<div class="col-6">
					<button class="btn btn-sm btn-block btn-success" (click)="updateNotifications()">
						<i *ngIf="notifications.isUpdating" class="fa fa-loader fa-spin"></i>
						<span *ngIf="!notifications.isUpdating">Update</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</p-dialog>

<app-trans-date-lock-dialog [manager]="transLockManager" (dateLockUpdated)="updateTable()"></app-trans-date-lock-dialog>
