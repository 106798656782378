import {
	ReportGlobals,
	TransactionMetaDataForReport,
	UserReportMetaData,
	IUserReportFormat,
	UserReportType,
	ReportFieldDateTimeInput,
	ReportFieldGenericInput,
	ReportFieldContactPhoneInput,
	ReportFieldContactEmailInput,
	ReportFieldCheckbox,
	ReportFieldCheckboxWithDescription,
	ReportFieldSelectWithOption,
	UserReportValidator,
} from './report-user'

import { log } from '@app/helpers/logger'

export class VehicleInspectionReport {
	metaData: UserReportMetaData
	reportData: VehicleInspectionReportData

	constructor(reportData: VehicleInspectionReportData) {
		const version = reportData.version
		const format = reportData.format
		this.metaData = new UserReportMetaData(version, format)
		this.reportData = reportData
	}
}

export class VehicleInspectionReportData {
	version = 1
	format: IUserReportFormat

	locale = 'en'
	countryCode = null

	displayPrefs = {
		format12Hour: ReportGlobals.displayPrefs.format12Hour,
	}

	transactionMetaData: TransactionMetaDataForReport

	inspectionDate = new ReportFieldDateTimeInput({ type: 'DATETIME', style: 'DATE', value: new Date(), skip: false })
	vehicleIdentifier = new ReportFieldGenericInput({ style: 'INPUT' })

	inspectionReporter = {
		firstName: new ReportFieldGenericInput({ style: 'INPUT' }),
		lastName: new ReportFieldGenericInput({ style: 'INPUT' }),
	}

	mileage = {
		starting: new ReportFieldGenericInput({ style: 'INPUT' }),
		ending: new ReportFieldGenericInput({ style: 'INPUT' }),
	}

	vehicleCondition = {
		interior: new ReportFieldGenericInput({ style: 'TEXTAREA' }),
		exterior: new ReportFieldGenericInput({ style: 'TEXTAREA' }),
		lightsAndSignals: new ReportFieldGenericInput({ style: 'TEXTAREA' }),
		tires: new ReportFieldGenericInput({ style: 'TEXTAREA' }),
		performance: new ReportFieldGenericInput({ style: 'TEXTAREA' }),
	}

	additionalInspectionInformation = {
		occupants: new ReportFieldGenericInput({ style: 'TEXTAREA' }),
		previousOccupant: new ReportFieldGenericInput({ style: 'INPUT' }),
		notes: new ReportFieldGenericInput({ style: 'TEXTAREA' }),
	}

	constructor(data?: any) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
	}
}

export class VehicleInspectionReportValidator {
	reportData: VehicleInspectionReportData
	isInDialog = false

	constructor(reportData?: VehicleInspectionReportData) {
		this.reportData = reportData
	}

	get isReportValid(): boolean {
		return this.validateReport(false)
	}

	setReportData(reportData: VehicleInspectionReportData) {
		this.reportData = reportData
	}

	validateReport(interactive: boolean): boolean {
		const reportData = this.reportData
		log('reportData', reportData)

		// Vehicle Identifier
		if (!reportData.vehicleIdentifier.skip && !reportData.vehicleIdentifier.value) {
			if (interactive) {
				UserReportValidator.validationAlert('Please enter the vehicle identifier.')
				UserReportValidator.scrollToRegion({ id: 'VEHICLE_IDENTIFIER', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}

		// Reporter first name
		if (!reportData.inspectionReporter.firstName.value) {
			if (interactive) {
				UserReportValidator.validationAlert('Please enter your first name.')
				UserReportValidator.scrollToRegion({ id: 'REPORTER_FIRST_NAME', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}
		// Provider last name
		if (!reportData.inspectionReporter.lastName.value) {
			if (interactive) {
				UserReportValidator.validationAlert('Please enter your last name.')
				UserReportValidator.scrollToRegion({ id: 'REPORTER_LAST_NAME', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}

		// Starting Mileage
		if (!reportData.mileage.starting.skip && !reportData.mileage.starting.value) {
			if (interactive) {
				UserReportValidator.validationAlert('Please enter your starting mileage.')
				UserReportValidator.scrollToRegion({ id: 'MILEAGE_STARTING', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}

		// Ending Mileage
		if (!reportData.mileage.ending.skip && !reportData.mileage.ending.value) {
			if (interactive) {
				UserReportValidator.validationAlert('Please enter your ending mileage.')
				UserReportValidator.scrollToRegion({ id: 'MILEAGE_ENDING', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}

		// Vehicle Condition - Interior
		if (!reportData.vehicleCondition.interior.skip && !reportData.vehicleCondition.interior.value) {
			if (interactive) {
				UserReportValidator.validationAlert(`Please describe the vehicle's interior.`)
				UserReportValidator.scrollToRegion({ id: 'CONDITION_INTERIOR', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}
		// Vehicle Condition - Exterior
		if (!reportData.vehicleCondition.exterior.skip && !reportData.vehicleCondition.exterior.value) {
			if (interactive) {
				UserReportValidator.validationAlert(`Please describe the vehicle's exterior.`)
				UserReportValidator.scrollToRegion({ id: 'CONDITION_EXTERIOR', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}
		// Vehicle Condition - Lights and Signals
		if (!reportData.vehicleCondition.lightsAndSignals.skip && !reportData.vehicleCondition.lightsAndSignals.value) {
			if (interactive) {
				UserReportValidator.validationAlert(`Please describe the vehicle's lights and signals.`)
				UserReportValidator.scrollToRegion({ id: 'CONDITION_LIGHTSANDSIGNALS', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}
		// Vehicle Condition - Tires
		if (!reportData.vehicleCondition.tires.skip && !reportData.vehicleCondition.tires.value) {
			if (interactive) {
				UserReportValidator.validationAlert(`Please describe the vehicle's tires.`)
				UserReportValidator.scrollToRegion({ id: 'CONDITION_TIRES', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}
		// Vehicle Condition - Performance
		if (!reportData.vehicleCondition.performance.skip && !reportData.vehicleCondition.performance.value) {
			if (interactive) {
				UserReportValidator.validationAlert(`Please describe the vehicle's performance.`)
				UserReportValidator.scrollToRegion({ id: 'CONDITION_PERFORMANCE', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}

		// Additional Information - Vehicle Occupants
		if (!reportData.additionalInspectionInformation.occupants.skip && !reportData.additionalInspectionInformation.occupants.value) {
			if (interactive) {
				UserReportValidator.validationAlert(`Please list occupants of vehicle.`)
				UserReportValidator.scrollToRegion({ id: 'VEHICLE_OCCUPANTS', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}
		// Additional Information - Previous Occupants
		if (!reportData.additionalInspectionInformation.previousOccupant.skip && !reportData.additionalInspectionInformation.previousOccupant.value) {
			if (interactive) {
				UserReportValidator.validationAlert(`Please list the previous occupant of vehicle.`)
				UserReportValidator.scrollToRegion({ id: 'PREVIOUS_OCCUPANT', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}

		// Additional Information - Notes / Comments
		if (!reportData.additionalInspectionInformation.notes.skip && !reportData.additionalInspectionInformation.notes.value) {
			if (interactive) {
				UserReportValidator.validationAlert(`Please enter any additional notes.`)
				UserReportValidator.scrollToRegion({ id: 'NOTES_COMMENTS', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}

		// All checks out
		return true
	}
}
