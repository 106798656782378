<div class="container section-container">
	<div class="sec-head-wrap">
		<div class="sec-act-ico-wrap">
			<i class="far fa-cog sec-act-ico" (click)="openPrefsDialog()" title="Manage Columns"></i>
			<i class="far fa-question-circle sec-act-ico" (click)="toggleSectionHelp()" title="Show Help"></i>
		</div>

		<div class="sec-title-wrap" [class.sec-head-title-highlight]="viewManager.dayViewDate">
			<div *ngIf="!viewManager.dayViewDate">
				<i class="far fa-pie-chart sec-head-ico" aria-hidden="true"></i>
				<span>Shift Reports</span>
			</div>

			<div *ngIf="viewManager.dayViewDate" style="cursor: pointer; display: inline">
				{{ viewManager.dayViewDate | dateFormatter: 'ddd, MMM Do' }}
				<div class="sec-title-ico-wrap">
					<app-date-time-picker
						[useTemplate]="true"
						[currentDate]="viewManager.dayViewDate"
						[pickerType]="'DATE'"
						[pickerMode]="'dialog'"
						[hour12Timer]="true"
						[format]="'MMM Do, YYYY'"
						(pickerDidClose)="setDayViewDate($event)"
						><i class="fal fa-calendar sec-title-ico"></i
					></app-date-time-picker>
				</div>
			</div>
		</div>

		<div class="sub-head-wrap">
			<div class="sub-head-ico-wrap">
				<!-- Day View Icon -->
				<div class="ico-inline-block" title="Toggle Day View" (click)="toggleDayView()">
					<i *ngIf="!viewManager.dayViewDate" class="far fa-calendar sub-head-ico"></i>
					<i *ngIf="viewManager.dayViewDate" class="far fa-calendar-check sub-head-ico sub-head-ico-highlight"></i>
				</div>

				<!-- Prev / Next Icons -->
				<div *ngIf="viewManager.dayViewDate" class="ico-inline-block">
					<i class="far fa-arrow-left sub-head-ico" (click)="previousDay()" title="Next"></i>
					<i class="far fa-arrow-right sub-head-ico" (click)="nextDay()" title="Previous"></i>
				</div>

				<div *ngIf="!viewManager.dayViewDate" class="ico-inline-block">
					<i
						class="far fa-filter sub-head-ico"
						[class.sub-head-ico-highlight]="viewManager.isFilterRangeActive"
						(click)="openFilterRangePicker()"
						title="Filter Dates"
					></i>
				</div>

				<!-- Refresh Icon -->
				<i class="fa fa-refresh sub-head-ico" (click)="fetchAndReload()" title="Refresh Data"></i>
				<div class="updated-wrap">{{ lastUpdated }}</div>
			</div>
		</div>
	</div>

	<div class="filter-info" *ngIf="viewManager.isFilterRangeActive">
		<span
			>{{ viewManager.filterStartDate | dateFormatter: 'MMM. Do, YYYY' }} - {{ viewManager.filterEndDate | dateFormatter: 'MMM. Do, YYYY' }}
			<i class="far fa-circle-x has-pointer" (click)="resetFilterRange()"></i
		></span>
	</div>

	<div *ngIf="showSectionHelp" class="card card-body tooltip-block tooltip-info-text tooltip-section-header">
		<button type="button" class="close" aria-label="Close" (click)="toggleSectionHelp()">
			<span aria-hidden="true">&times;</span>
		</button>
		Employee Shift Report are submitted through the employee app and available to browse here.
		<br /><br />Click the <i class="fas fa-thumbtack" style="color: #a8a7b3"></i> icon to pin any report to the bulletin board. When a report is
		pinned, it will be made available in the employee app for any employee checked into that job.<br /><br />
		<i class="fas fa-thumbtack" style="color: #0275d8"></i> = Pinned to Bulletin Board<br />
		<i class="fas fa-thumbtack" style="color: #a8a7b3"></i> = Report Not Pinned<br /><br />
		Each report has a unique identifier which you can use to reference the report. This is shown in the report as the <strong>Report ID</strong> and
		is available in the <strong>Identifier</strong> column of this table. You may also filter the reports for a given employee, job, or combination
		of both. You can use the <i class="far fa-calendar" style="color: #0275d8"></i> icon to filter on a specifc day or the
		<i class="far fa-filter" style="color: #0275d8"></i> icon to filter on a date range.
	</div>

	<app-filter-range-picker
		[startDate]="viewManager.filterStartDate"
		[endDate]="viewManager.filterEndDate"
		[isDialogVisible]="showFilterRangePicker"
		(cancelFilter)="showFilterRangePicker = false"
		(resetFilter)="resetFilterRange()"
		(setFilter)="setFilterRange($event)"
	></app-filter-range-picker>

	<app-shift-report-table #incidentLogTable></app-shift-report-table>
</div>
