import { AfterViewInit, Component, Input, OnInit } from '@angular/core'
import { CoreService } from '@app/services'

@Component({
    selector: 'app-icon-help',
    templateUrl: './icon-help.component.html',
    styleUrls: ['./icon-help.component.scss'],
    standalone: false
})
export class IconHelpComponent implements AfterViewInit {
	@Input() rowCount = 0
	@Input() hasHidden = false

	constructor(private coreSrvc: CoreService) {}

	get showBanner(): boolean {
		return this.rowCount && this.hasHidden && this.coreSrvc.helpSrvc.prefs.showTimeEntryIconHelp
	}

	ngAfterViewInit(): void {}

	closeHelp() {
		this.coreSrvc.helpSrvc.prefs.showTimeEntryIconHelp = false
		this.coreSrvc.helpSrvc.prefs.savePrefs()
	}
}
