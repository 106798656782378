import { Component, AfterViewInit, OnDestroy, ViewChild, AfterViewChecked } from '@angular/core'
import { Router } from '@angular/router'

import { CoreService, DatabaseService, PrefsService } from '@app/services'
import { CrudAction, NavbarIcon, TagFilterKey, TransTagFilterManager } from '@app/models'

import { log, DateTimeHelper } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'

import { TransactionTableComponent } from './transaction-table/transaction-table.component'
import { Subscription } from 'rxjs'

import moment from 'moment-timezone'

@Component({
    selector: 'app-transactions',
    templateUrl: './transactions.component.html',
    styleUrls: ['./transactions.component.scss'],
    standalone: false
})
export class TransactionsComponent implements AfterViewInit, OnDestroy, AfterViewChecked {
	CrudAction = CrudAction
	TagFilterKey = TagFilterKey
	currentTagFilterKey = null
	tagFilterManager = new TransTagFilterManager()

	isGlobalAccount = false
	accessHelper: AccessHelper

	@ViewChild('transTable') transTable: TransactionTableComponent
	// @ViewChild('prefsComponent', { static: true }) prefsComponent

	navbarIcons: Array<NavbarIcon> = []

	// alertCount = 0
	showTooltips = false
	showTransMapModal = false
	showFilterDatesDialog = false

	showEarlyLateIssuesTags = false
	showConfirmBreakIssuesTag = false
	showDuplicatePhotosTag = false

	hasViewBeenChecked = false
	isTagFilterProcessing = false
	showGpsTimeInfo = false
	canAccessGlobalAccount = false

	dayViewDate = this.coreSrvc.dbSrvc.tranSrvc.dayViewDate
	filterStartDate: Date = null
	filterEndDate: Date = null

	showPrefs = { options: false, columns: false }

	subs = new Subscription()

	filterInput = ''
	filterTagLabel = ''

	companyName = ''

	constructor(
		private router: Router,
		private coreSrvc: CoreService,
		private prefsSrvc: PrefsService,
	) {
		const adminPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		this.showGpsTimeInfo = adminPrefs.transDisplayGpsTimeInfo

		this.setupAccessPermissions()
		this.setupDateRange()
		this.setupNavbarIcon()
		this.setupTagVisibility()
		this.setupTagFilterSubscriptions()

		// Setup company name when there are multiple companies
		const myActualUser = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
		const userAllowedCompanies = myActualUser.allowed_companies
		const hasMultipleCompanies = userAllowedCompanies.includes(0) || userAllowedCompanies.length > 1
		this.companyName = hasMultipleCompanies ? this.coreSrvc.dbSrvc.settingSrvc.getCompany().name : null
	}

	switchToGlobalAccount() {
		log('Switch to global company')
		const globalId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().global_id
		if (globalId) {
			this.coreSrvc.dbSrvc.switchCompany(globalId).then(() => {
				const dayViewDate = this.coreSrvc.dbSrvc.tranSrvc.dayViewDate
				if (dayViewDate) {
					this.coreSrvc.prefSrvc.data.transDefaultToTodayView = true
					this.coreSrvc.prefSrvc.save()
				}
				setTimeout(() => {
					this.coreSrvc.dbSrvc.settingSrvc.reloadApplication()
				}, 150)
			})
		}
	}

	setupTagFilterSubscriptions() {
		this.subs.add(
			this.coreSrvc.eventSrvc.genericEvents.subscribe((event) => {
				if (event.key === 'tableDraw' && event.data === 'transactionsTable') {
					setTimeout(() => {
						this.isTagFilterProcessing = false
					}, 100)
				}
			}),
		)
	}

	setupTagVisibility() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		this.showConfirmBreakIssuesTag = company.confirm_breaks
		this.showDuplicatePhotosTag = !!company.image_dup_check
		const userPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		this.showEarlyLateIssuesTags =
			userPrefs.transHighlightEarlyCheckin ||
			userPrefs.transHighlightEarlyCheckout ||
			userPrefs.transHighlightLateCheckin ||
			userPrefs.transHighlightLateCheckout
	}

	setupNavbarIcon() {
		log('Installing Nav Bar Icon')
		const icon = new NavbarIcon()
		icon.class = 'far fa-map-marked-alt right-menu-icon'
		icon.title = 'View Open Time Entries'
		icon.styleAttr = {
			'width': '20px',
			'height': '14px',
			'margin': '0px 20px 5px 8px',
			'font-size': '1em',
			'cursor': 'pointer',
		}
		icon.click = () => this.openTransMapModalDialog()
		this.coreSrvc.navbarSrvc.addIcon(icon)
		this.navbarIcons.push(icon)

		// const icon = new NavbarIcon()
		// icon.imageUrl = '/assets/img/transmap.png'
		// icon.styleAttr = { 'width': '30px', 'height': '28px', 'margin': '0px 6px 5px 0px', 'cursor': 'pointer' }
		// icon.click = () => this.openTransMapModalDialog()
		// this.navbarIcons.push(icon)
		// this.coreSrvc.dbSrvc.navbarSrvc.addIcon(icon)
	}

	openTransMapModalDialog() {
		log('Open Trans Map Modal')
		this.showTransMapModal = true
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'transaction')
		this.isGlobalAccount = this.coreSrvc.dbSrvc.settingSrvc.isGlobalAccount()
		this.canAccessGlobalAccount = !this.isGlobalAccount && this.coreSrvc.dbSrvc.settingSrvc.canAccessGlobalAccount()
	}

	canPerformAction(action: CrudAction) {
		return this.accessHelper.canPerformAction(action, true)
	}

	// get notificationCount(): number { return 0 }
	get transCount(): number {
		return this.coreSrvc.dbSrvc.tranSrvc.count()
	}

	get showNoShowRecentTag(): boolean {
		const hasDayViewDate = !!this.coreSrvc.dbSrvc.tranSrvc.dayViewDate
		const adminPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		const hasNoShowLookback = !!adminPrefs.transNoShowFilterDays

		return !hasDayViewDate && hasNoShowLookback
	}

	get showTodayView(): boolean {
		return this.prefsSrvc.data.transDefaultToTodayView
	}
	get showFilterView(): boolean {
		return this.coreSrvc.dbSrvc.tranSrvc.filterStartDate ? true : false
	}
	get dayViewDelta(): number {
		return this.coreSrvc.dbSrvc.tranSrvc.dayViewDelta || 0
	}
	get pickerEndDateDefault(): Date {
		return moment('2019-01-01').toDate()
	}
	get dayViewCanGoBack(): boolean {
		return this.coreSrvc.dbSrvc.tranSrvc.dayViewCanGoBack()
	}
	get dayViewCanGoForward(): boolean {
		return this.coreSrvc.dbSrvc.tranSrvc.dayViewCanGoForward()
	}
	get currentDayViewDate(): Date {
		return this.coreSrvc.dbSrvc.tranSrvc.dayViewDate
	}
	get isLoadingData(): boolean {
		return this.transTable?.isLoadingData ?? false
	}

	get navStartDate(): Date {
		if (this.showTodayView) {
			const created = this.coreSrvc.dbSrvc.settingSrvc.getCompany().created
			return new Date(created)
		} else {
			return this.coreSrvc.dbSrvc.tranSrvc.filterStartDate
		}
	}

	get navEndDate(): Date {
		return this.filterStartDate && !this.showTodayView ? this.filterEndDate : new Date()
	}

	get hasTagFilter(): boolean {
		const input = this.filterInput || ''
		if (input.includes('#')) {
			return true
		}

		return false
	}

	ngAfterViewInit() {
		// this.loadData()
		scrollTo(0, 0)
		$('#tagDropdown').on('hide.bs.dropdown', () => {
			this.scrollTagMenu()
		})
		setTimeout(() => {
			this.hasViewBeenChecked = true
		}, 500)
	}

	ngAfterViewChecked() {
		// Mathod can be called many times so only set once
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
		this.coreSrvc.navbarSrvc.removeIcons(this.navbarIcons)
	}

	hideDtTimePicker() {
		$('body').addClass('hide-dt-time-picker')
	}
	showDtTimePicker() {
		$('body').removeClass('hide-dt-time-picker')
	}

	showNoDataTip(): boolean {
		if (this.transCount === 0) {
			return true
		}
		return false
	}
	loadData() {
		this.transTable?.fetchDataAndUpdateTable()
	}

	setupDateRange() {
		this.filterStartDate = this.coreSrvc.dbSrvc.tranSrvc.filterStartDate
		this.filterEndDate = this.coreSrvc.dbSrvc.tranSrvc.filterEndDate
	}

	displayDateRange() {
		const startDate = this.coreSrvc.dbSrvc.tranSrvc.filterStartDate
		const endDate = this.coreSrvc.dbSrvc.tranSrvc.filterEndDate
		const startMom = moment(startDate)
		const startString = startMom.isValid() ? startMom.format('MMM Do, YYYY') : ''
		const endMom = moment(endDate)
		const endString = endMom.isValid() ? endMom.format('MMM Do, YYYY') : 'Not Set'

		if (startString) {
			return `${startString} - ${endString}`
		} else {
			return ''
		}
	}

	dayViewPickerClosed() {
		log('Day view picker closed')
		const selectedDate = moment(this.dayViewDate)
		if (selectedDate.isSame(moment(), 'day')) {
			this.coreSrvc.dbSrvc.tranSrvc.autoAdvanceDayView = true
		} else {
			this.coreSrvc.dbSrvc.tranSrvc.autoAdvanceDayView = false
		}
		this.coreSrvc.dbSrvc.tranSrvc.setDayViewDate(this.dayViewDate)
		this.transTable.fetchDataAndUpdateTable()
	}

	displayRangeReset(): boolean {
		if (this.coreSrvc.dbSrvc.tranSrvc.filterStartDate) {
			return true
		} else {
			return false
		}
	}

	tableRefreshed() {
		log('Table Refresh Caught')
		this.checkForFilterLimitReached()
		// this.alertCount = this.coreSrvc.dbSrvc.tranSrvc.alertCount()
	}

	checkForFilterLimitReached() {
		if (this.showFilterView) {
			const transactions = this.coreSrvc.dbSrvc.tranSrvc.getTransactions()
			log('Transaction Count', transactions.length)
			if (transactions?.length === 5000) {
				this.coreSrvc.notifySrvc.notify(
					'warn',
					'Limit Reached',
					'Not all records can be displayed as the current range filter exceeds the limit of 5000 records. Set your filter request to a shorter range to avoid this limit.',
				)
			}
		}
	}

	hasData(): boolean {
		return this.coreSrvc.dbSrvc.tranSrvc.count() > 0
	}

	showIssues(): boolean {
		if (this.coreSrvc.dbSrvc.tranSrvc.loadIssues) {
			return true
		}
		return false
	}

	// toggleNotifications() {
	// 	this.transTable.toggleNotifications()
	// }

	toggleTodayView() {
		this.transTable.resetPage(false)
		this.transTable.toggleTodayView()
		this.transTable.fetchDataAndUpdateTable()
		if (!this.showTodayView) {
			this.dayViewDate = new Date()
		}
	}

	currentDayView(): string {
		const delta = this.coreSrvc.dbSrvc.tranSrvc.dayViewDelta || 0
		const date = this.coreSrvc.dbSrvc.tranSrvc.dayViewDate
		return moment(date).add(delta, 'days').format('ddd, MMM Do')
	}

	dayViewBack() {
		this.coreSrvc.dbSrvc.tranSrvc.dayViewBack()
		this.dayViewDate = this.currentDayViewDate
		this.transTable.fetchDataAndUpdateTable()
	}
	dayViewForward() {
		this.coreSrvc.dbSrvc.tranSrvc.dayViewForward()
		this.dayViewDate = this.currentDayViewDate
		this.transTable.fetchDataAndUpdateTable()
	}

	transAlertCount(): number {
		return this.coreSrvc.dbSrvc.tranSrvc.alertCount()
	}

	viewAlerts(): boolean {
		this.router.navigate(['/admin/transactions/alerts'])
		return false
	}

	lastUpdated(): string {
		const date = this.coreSrvc.dbSrvc.tranSrvc.lastUpdated
		return `Updated ${DateTimeHelper.formatTimeFromDate(date)}`
	}

	reloadBtnClicked() {
		this.transTable.fetchDataAndUpdateTable()
	}

	toggleTooltips(): boolean {
		this.showTooltips = !this.showTooltips
		this.updateFixedHeader()
		return false
	}

	updateFixedHeader() {
		setTimeout(() => {
			const table: any = $('#transactionsTable').DataTable()
			table.fixedHeader.adjust()
		}, 100)
	}

	togglePrefs(): void {
		const table = this.transTable
		if (table) {
			table.sectionPrefsDialogManager.isDialogVisible = true
		}
	}

	showManageOptions() {
		this.showPrefs.options = true
		this.showPrefs.columns = false
		this.showTooltips = false
	}

	togglePrefsPanel(): boolean {
		this.showTooltips = false
		this.showPrefs.options = false
		this.showPrefs.columns = false
		return false
	}

	showTransactionsTable(): boolean {
		return true
	}

	datePickerClosed() {
		log('Date Picker Closed', this.filterStartDate, this.filterEndDate)
	}

	openDatePicker() {
		this.setupDateRange()
		this.showFilterDatesDialog = true
	}

	cancelDatePicker() {
		this.setupDateRange()
		this.showFilterDatesDialog = false
	}

	resetFilterBtnPressed() {
		log('Resetting filter')
		this.showFilterDatesDialog = false
		setTimeout(() => {
			this.clearDateRange()
		}, 250)
	}

	clearDateRange() {
		log('Clearing date range')
		localStorage.removeItem('transactionFilter')
		this.coreSrvc.dbSrvc.tranSrvc.filterStartDate = null
		this.coreSrvc.dbSrvc.tranSrvc.filterEndDate = null
		this.filterStartDate = null
		this.filterEndDate = null
		if (this.prefsSrvc.data.transDefaultToTodayView) {
			this.toggleTodayView()
			this.coreSrvc.dbSrvc.tranSrvc.dayViewDate = new Date()
			this.dayViewDate = new Date()
		} else {
			this.coreSrvc.dbSrvc.tranSrvc.dayViewDate = new Date()
			this.dayViewDate = new Date()
			this.setupDateRange()
			this.transTable.clearSearch()
			this.transTable.fetchDataAndUpdateTable()
			this.coreSrvc.dbSrvc.tranSrvc.autoAdvanceDayView = true
		}
	}

	saveDatePickerSettings() {
		if (this.filterStartDate) {
			this.coreSrvc.dbSrvc.tranSrvc.dayViewDate = this.filterStartDate
			this.dayViewDate = this.coreSrvc.dbSrvc.tranSrvc.dayViewDate
		}
		this.coreSrvc.dbSrvc.tranSrvc.filterStartDate = this.filterStartDate
		this.coreSrvc.dbSrvc.tranSrvc.filterEndDate = this.filterEndDate
		this.coreSrvc.dbSrvc.tranSrvc.saveDateFilter()
		this.showFilterDatesDialog = false
		this.transTable?.adjustNoShowFilterTag()
		setTimeout(() => {
			this.prefsSrvc.data.transDefaultToTodayView = false
			this.prefsSrvc.save()
			this.transTable.fetchDataAndUpdateTable()
		}, 250)
	}

	createRecord() {
		this.transTable.createRecord()
	}

	processTableFilterEvent(filterInput: string) {
		const input = filterInput || ''
		this.filterInput = input
		for (const filter of this.tagFilterManager.list) {
			if (input.includes(filter.key)) {
				this.filterTagLabel = filter.label.toUpperCase()
				return
			}
		}
		this.filterTagLabel = ''
	}

	setTagFilterFromDropdown(filterText: string) {
		const menu = $('#tag-filter-menu')
		if (menu) {
			menu.scrollTop(0)
		}
		this.isTagFilterProcessing = true
		setTimeout(() => {
			const updateText = filterText ? filterText + ' ' : ''
			this.setFilterText(updateText)
		}, 200)
	}

	setFilterText(filterText: string) {
		this.showTooltips = false
		this.transTable.setFilterText(filterText)
	}

	scrollTagMenu() {
		const menu = $('#tag-filter-menu')
		if (menu) {
			menu.scrollTop(0)
		}
	}

	scrollToTop(): boolean {
		window.scrollTo(0, 0)
		return false
	}
}
