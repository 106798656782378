<div class="section-block filter-wrap">
	<div class="d-flex align-items-center justify-content-center" *ngIf="!isDataLoaded" style="font-size: 1.5rem; color: gray">
		<div><i class="fas fa-loader fa-spin" aria-hidden="true"></i> Loading Data</div>
	</div>

	<div *ngIf="isDataLoaded">
		<div *ngIf="viewManager.rangeSelected !== 'CUSTOM'" class="date-range-label">
			{{ viewManager.startDate | dateFormatter: 'MMM. Do, YYYY' }} - {{ viewManager.endDate | dateFormatter: 'MMM. Do, YYYY' }}
		</div>

		<div class="options-wrap d-flex flex-wrap align-items-center justify-content-between">
			<div class="option-block range-dropdown">
				<p-select
					appScrollLockSelect
					[appendTo]="devDetect.isMobile() ? null : 'body'"
					placeholder="All Employees"
					[filter]="false"
					filterBy="label"
					[scrollHeight]="'380px'"
					[resetFilterOnHide]="true"
					[autofocusFilter]="devDetect.isDesktop()"
					[options]="rangeOptions"
					[(ngModel)]="viewManager.rangeSelected"
					(onChange)="handleRangeSelectionChanged()"
				>
				</p-select>
			</div>

			<div *ngIf="viewManager.rangeSelected === 'CUSTOM'" class="range-wrapper">
				<div class="option-block date-picker">
					<app-date-time-picker
						[currentDate]="viewManager.startDate"
						[pickerType]="'DATE'"
						[pickerMode]="'dialog'"
						[pickerLabel]="'Start Date'"
						[hour12Timer]="true"
						[format]="'MMM Do, YYYY'"
						(pickerDidClose)="setDate('startDate', $event)"
					>
					</app-date-time-picker>
				</div>

				<span class="date-seperator" style="margin: 0px 10px">-</span>

				<div class="option-block date-picker">
					<app-date-time-picker
						[currentDate]="viewManager.endDate"
						[pickerType]="'DATE'"
						[pickerMode]="'dialog'"
						[pickerLabel]="'End Date'"
						[hour12Timer]="true"
						[format]="'MMM Do, YYYY'"
						(pickerDidClose)="setDate('endDate', $event)"
					>
					</app-date-time-picker>
				</div>
				<button *ngIf="viewManager.rangeSelected === 'CUSTOM'" class="btn btn-sm btn-success update-btn" (click)="handleUpdateBtnClick()">
					Update
				</button>
			</div>
		</div>
	</div>
</div>
