<div *ngIf="filterBlocks.length === 0">
	<div class="no-records">No Active Filters</div>
	<div class="no-records-note">
		When a report shows an active filter but this view does not, it means the database needs to be refreshed (reload your browser) or the filter is
		referencing records which no longer exist.
	</div>
</div>
<div *ngIf="filterBlocks.length > 0">
	<div style="margin-bottom: 25px">
		<div class="float-right">
			<div class="link-text inline-block" (click)="collapseAll()">collapse all</div>
			|
			<div class="link-text inline-block" (click)="expandAll()">expand all</div>
		</div>
		<div style="font-weight: 600; color: green">{{ filterBlocks.length }} active filters</div>
	</div>

	<div>
		<div *ngFor="let block of filterBlocks">
			<app-form-expander
				[style]="{ 'margin-top': '5px' }"
				[contentStyle]="{ margin: '5px 0px 15px 0px' }"
				[label]="block.category"
				[(isExpanded)]="block.isExpanded"
			>
				<div [class]="block.blockClass">
					<div *ngFor="let filter of block.filters">
						<div class="filter-name">{{ filter }}</div>
					</div>
				</div>
			</app-form-expander>
		</div>
	</div>
</div>
