import { Component, Input, OnInit } from '@angular/core'
import { log } from '@app/helpers'
import { AddressHelper, FormAddressManager } from '@app/models'
import { DatabaseService } from '@app/services'
import { CountryCode } from 'libphonenumber-js'

@Component({
    selector: 'app-form-address',
    templateUrl: './form-address.component.html',
    styleUrls: ['./form-address.component.scss'],
    standalone: false
})
export class FormAddressComponent implements OnInit {
	@Input() manager: FormAddressManager
	@Input() showNotesOption = false

	private stateList = []
	public stateOptions = []

	constructor(private dbSrvc: DatabaseService) {
		const addressHelper = new AddressHelper(dbSrvc)
		this.stateList = addressHelper.states.map((s) => s.name)
	}

	ngOnInit(): void {}

	public searchStates(event): void {
		const text: string = this.manager.address.district
		if (!text) {
			this.stateOptions = [...this.stateList]
			return
		}
		const filteredOptions = this.stateList.filter((opt) => opt.toLowerCase().includes(text.toLowerCase()))
		this.stateOptions = filteredOptions
	}

	public addAddress(): void {
		log('Add Address')
		this.manager.hasAddress = true
		setTimeout(() => {
			const selector = document.getElementById('address-block')
			log('Selector', selector)
			selector.scrollIntoView({ behavior: 'smooth', block: 'start' })
		}, 100)
	}
}
