import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core'
import { Helper, log } from '@app/helpers'
import {
	ChecklistFormConfigCheckboxBasic,
	ChecklistFormConfigInputBasic,
	ChecklistFormConfigRangeBasic,
	ChecklistFormConfigSelectBasic,
	ChecklistFormItem,
	ChecklistFormItemType,
	ChecklistReport,
	ChecklistReportData,
	DialogManager,
	EmployeeChecklist,
	HelpDialogMessage,
	UserReportChecklistConfig,
	UserReportConfiguration,
} from '@app/models'
import { CoreService } from '@app/services'
import { DeviceDetectorService } from 'ngx-device-detector'

import _ from 'lodash'
import { SelectItem } from 'primeng/api'

import { environment } from '@env/environment'

@Component({
	selector: 'app-employee-checklist-edit',
	templateUrl: './employee-checklist-edit.component.html',
	styleUrls: ['./employee-checklist-edit.component.scss'],
	standalone: false,
})
export class EmployeeChecklistEditComponent implements OnInit, AfterViewInit {
	environment = environment

	isSaving = false
	isDesktop = true

	newItem = '' // Checklist item input field
	selectedItems = [] // Selected checklist items

	formItems: Array<ChecklistFormItem> = []

	// When selecting both sites and jobs, both targets must match their memembers
	// sitesRequiredForSelectedJobs: Array<string> = [] // Deprecated with no site targeting
	// sitesWithNoJobsSelected: Array<string> = [] // Deprecated with no site targeting

	typeOptions: Array<SelectItem> = []
	targetedEmployeeOptions: Array<SelectItem> = []
	targetedJobOptions: Array<SelectItem> = []

	preview = { show: false, reportData: null, reportConfig: null } // ReportData / UserReportConfiguration

	@Input() record: EmployeeChecklist
	@Input() checklistType: 'ALL' | 'CHECKIN' | 'POST_BREAK' | 'CHECKOUT' | 'CUSTOM' = 'ALL'

	@Input() dialogManager = new DialogManager()

	@Output() recordSaved = new EventEmitter<number>()
	@Output() cancelEdit = new EventEmitter<boolean>()

	constructor(
		private coreSrvc: CoreService,
		private deviceSrvc: DeviceDetectorService,
	) {
		if (this.deviceSrvc.isMobile()) {
			this.isDesktop = false
		}
	}

	get surveyTypeLabel(): string {
		switch (this.checklistType) {
			case 'CHECKIN':
				return 'check-in'
			case 'POST_BREAK':
				return 'post-break'
			case 'CHECKOUT':
				return 'check-out'
			case 'CUSTOM':
				return 'custom'
			default:
				return ''
		}
	}

	get isJobSiteMerged(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.getCompany().merge_jobsite
	}

	get canRequireChecklist(): boolean {
		return this.record.type === 'CHECKIN' || this.record.type === 'POST_BREAK' || this.record.type === 'CHECKOUT'
	}

	public ngOnInit(): void {
		this.setupTypeOptions()
		this.setupTargetedEmployeeOptions()
		this.setupTargetedJobOptions()
		this.setupFormItems()
		this.setupRecordType()
	}

	public ngAfterViewInit(): void {
		this.setupDialogState()
		this.setupTextareaHeights()
		// this.swapBtnOrder()
	}

	private setupDialogState() {
		setTimeout(() => {
			const recordId = this.record.id
			const label = Helper.capitalizeFirstLetter(`${this.surveyTypeLabel}`)
			const formOrBlock = this.checklistType === 'CUSTOM' ? 'Form' : 'Block'
			const title = recordId ? `Edit ${label} Survey ${formOrBlock}` : `New ${label} Survey ${formOrBlock}`
			this.dialogManager.headerLabel = title
			this.dialogManager.submitBtnLabel = 'Save'
			this.dialogManager.cancelBtnLabel = 'Cancel'
			this.dialogManager.canSubmit = () => this.isFormValid()
			this.dialogManager.submitBtnAction = () => this.submitBtnClicked()
			this.dialogManager.cancelBtnAction = () => this.cancelEdit.emit(true)
		}, 100)
	}

	private setupTypeOptions() {
		this.typeOptions = [
			{ label: 'Check-in Survey', value: 'CHECKIN' },
			{ label: 'Post-break Survey', value: 'POST_BREAK' },
			{ label: 'Check-out Survey', value: 'CHECKOUT' },
			{ label: 'Custom Survey', value: 'CUSTOM' },
		]
	}

	private setupTargetedEmployeeOptions() {
		const includeIds = this.record.employeeIds
		const empOptions = this.coreSrvc.dbSrvc.empSrvc.getEmployeeDropdown(this.coreSrvc.dbSrvc, false, false, [])
		this.targetedEmployeeOptions = empOptions.filter((si) => si.data.active || includeIds.includes(si.data.id))
	}

	private setupTargetedJobOptions() {
		const includeIds = this.record.jobIds
		const jobOptions = this.coreSrvc.dbSrvc.jobSrvc.getJobDropdown(this.coreSrvc.dbSrvc, false, false, false, [])
		this.targetedJobOptions = jobOptions.filter((si) => si.data.active || includeIds.includes(si.data.id))
	}

	private setupFormItems() {
		this.formItems = this.record?.checklist.map((cli) => new ChecklistFormItem(cli)) ?? []
	}

	private setupRecordType() {
		if (this.checklistType === 'ALL') return
		this.record.type = this.checklistType
	}

	private setupTextareaHeights() {
		setTimeout(() => {
			$('.item-label-textarea').each((idx, elem) => this.textResizer(elem as HTMLElement))
		})
	}

	public labelForItem(item: ChecklistFormItem): string {
		return ChecklistFormItem.labelForType(item.type)
	}

	public submitBtnClicked() {
		// Validate each form item
		if (!this.validateListItems()) return

		// Validation passed - now save the form
		this.isSaving = true
		this.formItems = this.formItems.filter((fi) => !!fi.config.label)
		this.formItems.forEach((fi) => fi.removeLayoutProperties())
		this.record.checklist = _.cloneDeep(this.formItems)
		const record = this.record.buildUpdateRecord()
		record.name = record.formattedType
		const recordId = record.id
		if (recordId) {
			this.coreSrvc.dbSrvc.updateRecord('employee_checklist', record).then((updateSuccess) => {
				if (updateSuccess) {
					log('Record Updated')
					this.recordSaved.emit(recordId)
				}
			})
		} else {
			this.coreSrvc.dbSrvc.insertRecord('employee_checklist', record).then((insertSuccess) => {
				if (insertSuccess) {
					log('Record Inserted')
					this.recordSaved.emit(null)
				}
			})
		}
	}

	private validateListItems(): boolean {
		let errorMsg = null
		for (const item of this.formItems) {
			// Check for descriptions
			if (!item.config.label) {
				item.hasError = true
				item.showConfig = true
				errorMsg = 'You are missing the description for a form item.'
				break
			}
			if (item.type === 'INPUTBASIC') {
				const inputConfig = item.config as ChecklistFormConfigInputBasic
				// Stub for input errors
			}
			if (item.type === 'CHECKBOXBASIC') {
				const checkboxConfig = item.config as ChecklistFormConfigCheckboxBasic
				// Stub for checkbox errors
			}
			if (item.type === 'RANGEBASIC') {
				const rangeConfig = item.config as ChecklistFormConfigRangeBasic
				// Normalize config values
				rangeConfig.min = +rangeConfig.min || 0
				rangeConfig.max = +rangeConfig.max || null
				rangeConfig.step = +rangeConfig.step || null

				// Check if both range labels are set it one is set
				if (rangeConfig.rangeLabel.left || rangeConfig.rangeLabel.right) {
					if (!rangeConfig.rangeLabel.left || !rangeConfig.rangeLabel.right) {
						item.hasError = true
						item.showConfig = true
						errorMsg = `When using range labels, you must either set both of them or make sure they are both empty.`
						break
					}
				}

				// Check for min / max / step
				const isRangeValid =
					(rangeConfig.min || rangeConfig.min === 0) && rangeConfig.max && rangeConfig.max > rangeConfig.min && !!rangeConfig.step
				if (!isRangeValid) {
					item.hasError = true
					item.showConfig = true
					errorMsg = 'You have a range item with an error. Please makes sure all fields are properly configured.'
					break
				}
			}
			if (item.type === 'SELECTBASIC') {
				const selectConfig = item.config as ChecklistFormConfigSelectBasic
				if (selectConfig.options.length === 0) {
					item.hasError = true
					item.showConfig = true
					errorMsg = 'You are missing options for a select block. Either add some options or remove the form block.'
					break
				}
				for (const option of selectConfig.options) {
					if (!option.label) {
						item.hasError = true
						item.showConfig = true
						errorMsg = 'You are missing the description for a select block option.'
						break
					}
				}
			}
		}
		if (errorMsg) {
			this.coreSrvc.notifySrvc.notify('error', 'Invalid Form', errorMsg)
			return false
		}
		return true
	}

	public addItemToList(type: ChecklistFormItemType) {
		if (type === 'CHECKBOXBASIC') {
			const config = new ChecklistFormConfigCheckboxBasic()
			const data = { type: type, config: config, showConfig: true }
			const checkbox = new ChecklistFormItem(data)
			this.formItems.push(checkbox)
		}
		if (type === 'INPUTBASIC') {
			const config = new ChecklistFormConfigInputBasic()
			const data = { type: type, config: config, showConfig: true }
			const input = new ChecklistFormItem(data)
			this.formItems.push(input)
		}
		if (type === 'RANGEBASIC') {
			const config = new ChecklistFormConfigRangeBasic({ min: 0, max: 10, step: 1 })
			const data = { type: type, config: config, showConfig: true }
			const range = new ChecklistFormItem(data)
			this.formItems.push(range)
		}
		if (type === 'SELECTBASIC') {
			const config = new ChecklistFormConfigSelectBasic({
				allowCustom: false,
				multiSelect: false,
				options: [{ label: '', value: null, selected: false }],
			})
			const data = { type: type, config: config, showConfig: true }
			const select = new ChecklistFormItem(data)
			this.formItems.push(select)
		}
	}

	remove(item: ChecklistFormItem) {
		const newList = this.formItems.filter((itm) => itm !== item)
		this.formItems = newList
	}
	moveUp(item: ChecklistFormItem) {
		this.moveItem(item, 'UP')
	}
	moveDown(item: ChecklistFormItem) {
		log('MOVE DOWN')
		this.moveItem(item, 'DOWN')
	}
	moveItem(item: ChecklistFormItem, direction: 'UP' | 'DOWN') {
		const currentIndex = this.formItems.indexOf(item)
		// Check if item is at beginning or end of list and prevent movement out of range
		const canMove = direction === 'UP' ? currentIndex > 0 : currentIndex < this.formItems.length - 1
		if (canMove) {
			const newIndex = direction === 'UP' ? currentIndex - 1 : currentIndex + 1
			;[this.formItems[currentIndex], this.formItems[newIndex]] = [this.formItems[newIndex], this.formItems[currentIndex]]
		}
	}

	// DEPRECATED 20250115 - Was used in original company checklist using PrimeNG OrderList component

	// private enableOrderListBtns() {
	// 	const buttons = $('.p-button-icon-only')
	// 	buttons.removeClass('ol-p-btn-disabled')
	// }

	// private disableOrderListBtns() {
	// 	const buttons = $('.p-button-icon-only')
	// 	buttons.addClass('ol-p-btn-disabled')
	// }

	// public selectionChanged(event) {
	// 	log('Selection changed', event)
	// 	const selectedItems = this.selectedItems
	// 	if (selectedItems.length > 0) {
	// 		this.enableOrderListBtns()
	// 	} else {
	// 		this.disableOrderListBtns()
	// 	}
	// }

	isFormValid(): boolean {
		if (!this.record.title) return false
		if (this.formItems.length === 0) return false
		return true

		// if (!this.record.name) {
		// 	return false
		// }
		// if (this.record.checklist.length === 0) {
		// 	return false
		// }
		// if (this.sitesRequiredForSelectedJobs.length > 0) {
		// 	return false
		// }
	}

	public textResizer(elm: HTMLElement) {
		elm.style.height = 'auto'
		elm.style.height = elm.scrollHeight + 'px'
	}

	public clearError(item: ChecklistFormItem) {
		item.hasError = false
		this.coreSrvc.notifySrvc.clear()
	}

	public toggleItemConfig(item: ChecklistFormItem) {
		item.showConfig = !item.showConfig
		if (item.showConfig) {
			setTimeout(() => {
				this.resizeConfigTextAreas()
			}, 50)
		}
	}

	public resizeConfigTextAreas() {
		log('Resizing config text areas')
		$('.item-label-textarea').each((idx, elm) => {
			this.textResizer(elm as HTMLElement)
		})
	}

	public previewForm(option: 'PREVIEW' | 'CUSTOMIZE' = 'PREVIEW') {
		// Clone the checklist record and update the checklist items
		const updatedRecord = this.record.clone()
		updatedRecord.checklist = option === 'PREVIEW' ? _.cloneDeep(this.formItems) : this.formItems
		const checklistData = updatedRecord.makeEmployeeChecklistData()

		// Barebones version of form setup from reports-list.component.ts
		// Setup the checklist config and report data, then bundle into a report

		const checklistConfig = new UserReportChecklistConfig()
		checklistConfig.setEmployeeChecklistData([checklistData])

		const checklistReportData = new ChecklistReportData()
		checklistReportData.configureChecklistOptions(checklistConfig, this.record.type, null)

		const checklistReport = new ChecklistReport(checklistReportData)

		this.preview.reportConfig = new UserReportConfiguration()
		this.preview.reportData = checklistReport.reportData

		// Setup dialog manager for preview state
		this.dialogManager.saveScrollPosition(this.dialogManager.modalId)
		this.dialogManager.pushState()
		this.dialogManager.headerLabel = 'Preview Form'
		this.dialogManager.isCancelBtnVisble = false
		this.dialogManager.isSubmitBtnVisible = false
		this.dialogManager.isBackBtnVisible = true
		this.dialogManager.backBtnAction = () => this.previewFinished()
		this.dialogManager.scrollToTop()
		this.dialogManager.setBackgroundColor('#eee')
		this.preview.show = true
	}

	public previewFinished() {
		this.dialogManager.popStateAndApply()
		this.dialogManager.restoreScrollPosition(this.dialogManager.modalId)
		this.dialogManager.setBackgroundColor('#fff')
		this.preview.show = false
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)

		let requiredMsg = 'Placeholder'
		const checkInTypeMsg =
			'A check-in survey can be submitted any time after check-in. If marked as required, it will be presented automatically after check-in, and employees are not allowed to skip submission.'
		const postBreakTypeMsg =
			'A post-break survey can be submitted after breaks. If marked as required, it will be presented automatically after each break and employees are not allowed to skip submission.'
		const checkOutTypeMsg =
			'A check-out survey can be submitted any time before check-out. If marked as required, it will be presented automatically during check-out, and employees will not be allowed to skip submission.'
		const customTypeMsg = 'A custom survey can be submitted any time before check-out and are available in the reports section of the employee app.'

		if (this.record.type === 'CHECKIN') requiredMsg = checkInTypeMsg
		if (this.record.type === 'POST_BREAK') requiredMsg = postBreakTypeMsg
		if (this.record.type === 'CHECKOUT') requiredMsg = checkOutTypeMsg
		if (this.record.type === 'CUSTOM') requiredMsg = customTypeMsg

		switch (trigger) {
			case 'name':
				help.header = 'Name'
				help.message = 'Name appears only in the admin portal and is used to identify this survey for administrators. This field is required.'
				break
			case 'type':
				help.header = 'Block Type'
				help.message = `${checkInTypeMsg}\n\n${postBreakTypeMsg}\n\n${checkOutTypeMsg}\n\n${customTypeMsg}`
				// `A check-in survey may be filled out during a shift. If marked as required, it will be presented automatically after check-in.\n\nA Post-break survey will be presented after every break.\n\nA check-out survey may be filled out during a shift. If marked as required, it will be presented automatically at check-out if it has not already been submitted.`
				break
			case 'description':
				help.header = 'Description'
				help.message =
					'Description appears only in the admin portal to provide more information about this survey block for administrators. This field is optional.'
				break
			case 'title':
				help.header = 'Title'
				help.message = 'Title appears in the employee web application and print reports to identify this survey block. This field is required.'
				break
			case 'instructions':
				help.header = 'Instructions'
				help.message =
					'Instructions appear in the employee web application and print reports. Length should be kept to a few sentences. This field is optional.'
				break
			case 'enableChecklist':
				help.header = 'Enable Block'
				help.message = 'When enabled, this survey block will be made available to the employee for submission in reports.'
				break
			case 'requireChecklist':
				help.header = 'Require Block'
				help.message = requiredMsg
				break
			case 'itemHasComment':
				help.header = 'Include Comment'
				help.message = 'When checked, this item will include a field where employees can enter optional comments.'
				break
			case 'addElement':
				help.header = 'Form Elements'
				help.message =
					'An <b class="tts-hl-1">input</b> offers a basic text field for entering information.\n\nA <b class="tts-hl-1">checkbox</b> offers the ability to solicit a yes/no style answer along with optional comments.\n\nA <b class="tts-hl-1">select</b> offers the ability to pick one or more items from a set you define and can be presented as a dropdown menu or list of options.\n\nA <b class="tts-hl-1">range</b> offers the ability to select a position on a sliding scale between a minimum and maximum value such as 1-10.'
				break
			case 'CHECKBOXBASIC':
				help.header = 'Checkbox'
				help.message =
					'This option offers a checkbox, a description, and an optional comments field.\n\nThe description should be formatted as a question which can be answered as yes, no, or not applicable.\n\nSelecting the checkbox indicates a yes, leaving the checkbox unselected registers a no, and N/A can be selected to indicate this question is not applicable.'
				break
			case 'INPUTBASIC':
				help.header = 'Input'
				help.message =
					'This option offers an input field to solicit general information. Enter the information you are requesting in the input description.'
				break
			case 'RANGEBASIC':
				help.header = 'Range'
				help.message =
					'This option allows for adding range based or ratings style survey questions and allow for choosing a value between a minimum and maximum range that you define.'
				break

			case 'rangeMin':
				help.header = 'Minimum'
				help.message = 'Enter the minimum value allowed. This value represents the left most position of the slide control.'
				break
			case 'rangeMax':
				help.header = 'Maximum'
				help.message = 'Enter the maximum value allowed. This value represents the right most position of the slide control.'
				break
			case 'rangeStep':
				help.header = 'Step Value'
				help.message =
					'Enter the increment allowed between values. Generally you would leave this option blank or set to 1 unless you are allowing for fractional values such as increments of .5 or .25.'
				break
			case 'rangeLabel':
				help.header = 'Range Label'
				help.message = `Range labels are optional and can be at most 20 characters. They are displayed next to the minimum and maximum range values.\n\nThese labels indicate the range of values you are measuring: 'Least Likely' to 'Most Likely' or 'Strongly Disagree' to 'Strongly Agree').`
				break
			case 'SELECTBASIC':
				help.header = 'Select'
				help.message =
					'This form block offers the ability to select one or more items from a pre-defined list.\n\nTwo display styles are available to choose from: <b style="color:chocolate">dropdown menu</b> or <b style="color:chocolate">list of options</b>. The <b>dropdown menu</b> supports single selection while the <b>list of options</b> supports multiple selection.\n\nWhen displaying a <b>dropdown menu</b>, tapping on the menu will show the list of options to choose from. When using a <b>list of options</b> style, all the options are presented on screen at once.'
				break
			case 'selectAllowCustom':
				help.header = 'Allow Custom'
				help.message = 'Check this box to add an <b>Other</b> option which will expose an input field for entering a custom response.'
				break
			case 'selectAllowMultiple':
				help.header = 'Allow Multiple'
				help.message = 'Check this box to allow for multiple selection from the available options.'
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = 'No help information for this topic is currently available.'
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}

// ["Dressing/Grooming", "Bathing", "Ostomy Hygiene", "Catheter Hygiene", "Bowel and Bladder Routine", "Assist with Toileting", "Assistive Transfer Device", "Passive Range of Motion", "Mobility & Transfer", "Medications", "Turning and Positioning", "Treatments", "Clean and Maintain Equipment", "Clean Bath", "Make Bed", "Change Linens", "Clean Floor", "Tidy and Dust", "Laundry", "Trash", "Essential Correspondence", "Meal Prep and Eating", "Wash Dishes", "Clean Kitchen", "Essential Transportation (non medical)", "Shopping and Errands"]
