import { DistanceUnitFormat, DistanceUnitOption } from './report-user'

export class DistanceConversionResult {
	value: number
	units: DistanceUnitOption

	constructor(value: number, units: DistanceUnitOption) {
		this.value = value
		this.units = units
	}
}

export class DistanceConverter {
	value: number // stored as meters
	unitType: DistanceUnitFormat

	constructor(unitType: DistanceUnitFormat) {
		this.unitType = unitType
	}

	setUnitType(unitType: DistanceUnitFormat) {
		switch (unitType) {
			case 'IMPERIAL':
			case 'METRIC':
				this.unitType = unitType
		}
	}

	// Return this for operation chaining
	setValue(value: number, units: DistanceUnitOption): DistanceConverter {
		const input = value ?? 0
		switch (units) {
			case 'KM':
				this.value = input * 1000
				break
			case 'M':
				this.value = input
				break
			case 'MI':
				this.value = input * 1609.344
				break
			case 'FT':
				this.value = input * 0.304
				break
		}
		return this
	}

	getDistanceValue(units: DistanceUnitOption): number {
		switch (units) {
			case 'KM':
				return this.value / 1000
			case 'M':
				return this.value
			case 'MI':
				return this.value / 1609.344
			case 'FT':
				return this.value * 3.28084
		}
	}

	getSmartDistance(): DistanceConversionResult {
		if (!this.value) return null

		// Return string with proper distance value and units
		switch (this.unitType) {
			case 'IMPERIAL':
				const ftValue = this.getDistanceValue('FT')
				if (ftValue < 5280) {
					return new DistanceConversionResult(Math.floor(ftValue), 'FT')
				} else {
					return new DistanceConversionResult(ftValue / 5280, 'MI')
				}
			case 'METRIC':
				const mValue = this.getDistanceValue('M')
				if (mValue < 1000) {
					return new DistanceConversionResult(Math.floor(mValue), 'M')
				} else {
					return new DistanceConversionResult(mValue / 1000, 'KM')
				}
		}
	}

	getSmartDistanceString(): string {
		// If no distance value return empty string
		const dist = this.getSmartDistance()
		if (!dist) return ''

		switch (dist.units) {
			// If feet or meters then return distance with 0 decimal places
			case 'FT':
			case 'M':
				return `${dist.value} ${dist.units.toLowerCase()}`
			// If kilometers or miles then return distance with 2 decimal places
			case 'KM':
			case 'MI':
				return `${dist.value.toFixed(2)} ${dist.units.toLowerCase()}`
		}
	}
}

export class DistanceHelper {
	static converter = new DistanceConverter('IMPERIAL')
}
