import { Pipe, PipeTransform } from '@angular/core'
import { log, DateTimeHelper } from '@app/helpers'
import moment from 'moment-timezone'

@Pipe({
    name: 'dateFormatter',
    standalone: false
})
export class DateFormatter implements PipeTransform {
	transform(value: any, args?: any): any {
		const dateMom = moment(value)
		const format = args ? args : 'YYYY-MM-DD'
		if (dateMom.isValid()) {
			return dateMom.format(format)
		} else {
			return 'Not Set'
		}
	}
}
