<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating" *ngFor="let group of groups" class="pref-group">
	<button class="btn btn-sm btn-outline-secondary defaults-btn float-right" (click)="resetSettings(group)">reset</button>
	<div class="options-section-header-lg">{{ group.label }}</div>
	<div *ngFor="let item of group.items">
		<div *ngIf="isPrefVisible(item, group)" class="pref-item">
			<!-- Render Checkbox -->
			<hr *ngIf="item.config.divider" />
			<div *ngIf="item.type === 'CHECKBOX'" [id]="item.key">
				<p-checkbox [(ngModel)]="item.currentValue" [binary]="true"></p-checkbox>
				<div class="has-pointer pref-chkbox-label" (click)="toggleColumn(item)">{{ item.label }}</div>
				<i *ngIf="item.config.help" title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp(item)"></i>
			</div>
			<!-- Render Integer Input -->
			<div *ngIf="item.type === 'INTEGER'" [id]="item.key">
				<div class="pref-integer">
					<input
						[id]="'pref_input_' + item.key"
						class="form-control float-right pref-int-input"
						inputmode="numeric"
						[(ngModel)]="item.currentValue"
						(focus)="focusInput(item)"
					/>
					<div class="pref-int-label" [class.pref-has-error]="item.errorMsg">{{ item.label }}</div>
					<i *ngIf="item.config.help" title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp(item)"></i>
					<div *ngIf="item.config.hint" class="pref-int-hint" [class.pref-has-error]="item.errorMsg">{{ item.config.hint }}</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- <pre><code>{{ groups | json }}</code></pre> -->
