import { Component, OnInit, OnDestroy } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'

import { IProvisionCompanyPayload, RegistrationUser } from '@app/models'
import { DatabaseService, UserLoginService, UserRegistrationService, AnalyticsService, CoreService } from '@app/services'

import { environment } from '@env/environment'
import { log } from '@app/helpers'

// Registration Confirmation Component

@Component({
    selector: 'app-auth-register',
    templateUrl: './confirmRegistration.component.html',
    styleUrls: ['./confirmRegistration.component.scss'],
    standalone: false
})
export class RegistrationConfirmationComponent implements OnInit, OnDestroy {
	confirmForm: UntypedFormGroup

	confirmationCode: string
	mobile: string
	username: string
	errorMessage: string

	confirmingRegistration = false
	provisioningCompany = false
	provisioningTeli = false

	showFacebookPixel = false

	pingTimer

	userRegistration: RegistrationUser

	private sub: any
	private loginAttempted = false

	constructor(
		private fb: UntypedFormBuilder,
		private http: HttpClient,
		private router: Router,
		private route: ActivatedRoute,
		private coreSrvc: CoreService,
		private loginSrvc: UserLoginService,
		private registrationSrvc: UserRegistrationService,
		private analyticsSrvc: AnalyticsService,
	) {}

	ngOnInit() {
		this.sub = this.route.params.subscribe((params) => {
			this.username = params['username']
			log('Confirm Registeration User Name', this.username)
		})
		this.confirmForm = this.fb.group({
			confirmationCode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
		})
		this.userRegistration = this.registrationSrvc.getRegInfo()
		this.errorMessage = null
		// this.pingLambdas()
		// this.pingTimer = setInterval(() => {
		// 	this.pingLambdas()
		// }, 60000)
	}

	ngOnDestroy() {
		this.sub.unsubscribe()
		clearInterval(this.pingTimer)
	}

	pingLambdas() {
		this.http.get(environment.timesheetsCompanyCreatePing).subscribe((res) => {
			log(res)
		})
		this.http.get(environment.userPoolTriggerPreSignUp).subscribe((res) => {
			log(res)
		})
		this.http.get(environment.phoneNumberTeliPing).subscribe((res) => {
			log(res)
		})
	}

	onConfirmRegistration() {
		const confirmationCode = this.confirmForm.get('confirmationCode').value
		this.errorMessage = null
		this.confirmingRegistration = true
		this.analyticsSrvc.emitGAEvent(
			'Registration',
			'ConfirmationCodeSubmitted',
			'Confirmation code (' + confirmationCode + ') submitted for ' + this.userRegistration.mobile,
			1,
		)
		this.registrationSrvc.confirmRegistration(this.username, confirmationCode, this)
	}

	hasValidNumber(): boolean {
		const regInfo = this.userRegistration
		if (regInfo && regInfo.mobile) {
			return true
		}
		return false
	}

	cognitoCallback(message: string, result: any) {
		// Process errors from confirmation or authentication attempt
		if (message != null) {
			// error
			this.confirmingRegistration = false
			this.provisioningCompany = false
			this.provisioningTeli = false
			if (!this.loginAttempted) {
				if (message.includes('user status is not UNCONFIRMED')) {
					alert('Your registration has been confirmed. Please log in.')
					this.router.navigate(['/home/login'])
				} else {
					this.errorMessage = message
				}
				log('message: ', this.errorMessage)
				this.analyticsSrvc.emitGAEvent(
					'Registration',
					'ConfirmationCodeError',
					'Error: ' + this.errorMessage + ' for ' + this.userRegistration.mobile,
					1,
				)
			} else {
				this.analyticsSrvc.emitGAEvent(
					'Registration',
					'PostConfirmUserAuthError',
					'Error: ' + this.errorMessage + ' for ' + this.userRegistration.mobile,
					1,
				)
				alert('Error setting up user account. Please contact support at 1-888-250-8463')
			}
		} else {
			// Confirmation successful but not yet logged in
			if (!this.loginAttempted) {
				log('Confirmation Successful - Results:', result)
				this.loginAttempted = true
				this.analyticsSrvc.emitGAEvent(
					'Registration',
					'ConfirmationCodeAccepted',
					'User confirmation code accepted for ' + this.userRegistration.mobile,
					1,
				)
				this.loginSrvc.authenticate(this.userRegistration.username, this.userRegistration.password, this)
			} else {
				// Confirmation successful and user logged in
				this.analyticsSrvc.emitGAEvent(
					'Registration',
					'PostConfirmUserAuthenticated',
					'Authentication succeeded for ' + this.userRegistration.mobile,
					1,
				)
				this.confirmingRegistration = false
				// this.setupCompany()
				this.showFacebookPixel = true
				this.startProvisioningCompany()
			}
		}
	}

	resendCode() {
		const username = this.username
		this.registrationSrvc
			.resendCode$(username)
			.then((result) => {
				log('Resend Result:', result)
				alert('A new 6-digit confirmation code has been sent to your mobile number. Please enter it here to continue.')
			})
			.catch((error) => {
				log('Resend Error', error)
				if (error.includes('User is already confirmed')) {
					alert('It appears your registration is already confirmed. Please login.')
					this.router.navigate(['/home/login'])
				}
				if (error.includes('Username/client id combination not found')) {
					alert('An error was encountered. Please try registering again.')
					this.router.navigate(['/home/register'])
				}
				if (error.includes('Attempt limit exceeded, please try after some time')) {
					alert(
						'Exceeded limit for resending codes. Please check that you used the correct mobile number when registering. If it is correct you can wait about an hour and try again. Contact support if you continue to experience problems.',
					)
				}
			})
		return false
	}

	startProvisioningCompany() {
		const registrationInfo = this.registrationSrvc.getRegInfo()
		const companyPayload: IProvisionCompanyPayload = {
			username: registrationInfo.username,
			email: registrationInfo.email,
			first_name: registrationInfo.firstName,
			last_name: registrationInfo.lastName,
			company_name: registrationInfo.companyName,
			phone: registrationInfo.mobile,
			ref_tag: localStorage.getItem('refTag') || null,
		}
		this.provisioningCompany = true
		this.coreSrvc.workSrvc.startWork()
		this.coreSrvc.dbSrvc.lambdaSrvc.provisionCompany(companyPayload).then((companySuccess) => {
			if (companySuccess) {
				this.provisioningCompany = false
				this.provisioningTeli = true
				this.coreSrvc.dbSrvc.lambdaSrvc.provisionPhoneNumber().then((phoneSuccess) => {
					if (phoneSuccess) {
						this.coreSrvc.workSrvc.stopWork()
						// If email passes conversion validation check, send conversion event
						// if (UserRegistrationService.validateEmailForConversion(registrationInfo.email)) {
						this.analyticsSrvc.sendConversion()
						// }
						this.registrationSrvc.clearRegInfo()
						this.router.navigate(['/home/login'])
					} else {
						this.coreSrvc.workSrvc.stopWork()
						this.provisioningTeli = false
						alert('Error provisioning phone number. Please contact support at 1-888-250-8463')
					}
				})
			} else {
				this.coreSrvc.workSrvc.stopWork()
				this.provisioningCompany = false
				this.registrationSrvc.clearRegInfo()
				alert('Error provisioning company. Please contact support at 1-888-250-8463')
			}
		})
	}
}
