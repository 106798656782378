import { DateTimeHelper } from '@app/helpers'

export class WorkOrderConfigRecord {
	id: number
	company_id: number
	created: string
	updated: string

	company_name: string
	company_phone_e164: string
	company_email: string

	email_subject: string
	bcc_emails: string
	html_email_body: string

	html_notes: string
	html_template: string
	tac_url: string

	constructor(record?: WorkOrderConfigRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
		}
	}
}
