<div class="card card-expanded viz-card">
	<div class="card-body">
		<div>
			<div class="title-block">
				<i class="viz-card-icon float-right {{ data.iconClass }}"></i>

				<!-- Use right-click for context menu -->
				<!-- <span #contextMenuTarget [class.has-context-menu]="isInternalUser" class="viz-card-title">
					{{ data.getTitle() }}
				</span> -->

				<!-- Use gear icon for context menu -->
				<span class="viz-card-title">
					<span>{{ data.title }}</span>
					<i
						#contextMenuTarget
						[hidden]="devDetect.isMobile() || devDetect.isTablet()"
						class="far fa-cog viz-config-icon link-text"
						(click)="openContextMenu($event, cm)"
					></i>
				</span>
			</div>
			<p-chart [type]="data.type" [data]="data.data" [options]="data.options"></p-chart>
		</div>
	</div>
</div>

<!-- Use right-click for context menu -->
<!-- <p-contextMenu *ngIf="isInternalUser && devDetect.isDesktop()" [target]="contextMenuTarget" [model]="contextMenu" /> -->

<!-- Use gear icon for context menu -->
<p-contextMenu #cm [target]="contextMenuTarget" [model]="contextMenu" />
