import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { log } from '@app/helpers'
import {
	ChartsGraphType,
	VizCardActionEvent,
	VizDashboard,
	VizCardGraphDataSourceType,
	VizDashboardHelper,
	VizCardGraphDataSource,
	VizCardAction,
	VizCardMoveDirection,
} from '@app/models'
import { CoreService, VisualizationService } from '@app/services'
import { DeviceDetectorService } from 'ngx-device-detector'
import { ContextMenu } from 'primeng/contextmenu'

@Component({
	selector: 'app-viz-card-graph',
	templateUrl: './viz-card-graph.component.html',
	styleUrl: './viz-card-graph.component.scss',
	standalone: false,
})
export class VizCardGraphComponent implements AfterViewInit {
	isInternalUser = false

	@Input() rowIndex: number
	@Input() cardIndex: number
	@Input() data: VizCardGraphDataSource

	@Output() cardEvent = new EventEmitter<VizCardActionEvent>()

	contextMenu: any = [
		{
			label: 'Add Card',
			icon: 'far fa-plus',
			command: (event) => this.handleMenuItemAction('ADD_CARD', null),
		},
		{
			label: 'Remove Card',
			icon: 'far fa-minus',
			command: (event) => this.handleMenuItemAction('REMOVE_CARD', null),
		},
		{
			label: 'Move Card',
			icon: 'far fa-up-down-left-right',
			items: [
				{
					label: 'Move Right',
					icon: 'fa fa-arrow-right',
					command: (event) => this.handleMenuItemAction('MOVE_CARD', 'RIGHT'),
					disabled: () => this.shouldDisableMove('RIGHT'),
				},
				{
					label: 'Move Left',
					icon: 'fa fa-arrow-left',
					command: (event) => this.handleMenuItemAction('MOVE_CARD', 'LEFT'),
					disabled: () => this.shouldDisableMove('LEFT'),
				},
			],
		},
		{
			separator: true,
		},
		{
			label: 'Add Row',
			icon: 'far fa-plus',
			command: (event) => this.handleMenuItemAction('ADD_ROW', null),
		},
		{
			label: 'Remove Row',
			icon: 'far fa-minus',
			command: (event) => this.handleMenuItemAction('REMOVE_ROW', null),
		},
		{
			label: 'Move Row',
			icon: 'far fa-up-down-left-right',
			items: [
				{
					label: 'Move Up',
					icon: 'fa fa-arrow-up',
					command: (event) => this.handleMenuItemAction('MOVE_CARD', 'UP'),
					disabled: () => this.shouldDisableMove('UP'),
				},
				{
					label: 'Move Down',
					icon: 'fa fa-arrow-down',
					command: (event) => this.handleMenuItemAction('MOVE_CARD', 'DOWN'),
					disabled: () => this.shouldDisableMove('DOWN'),
				},
			],
		},
		{
			separator: true,
		},
		{
			label: 'Data Source',
			icon: 'far fa-database',
			items: [
				{
					label: 'People',
					// icon: 'far fa-user',
					items: [
						{
							label: 'Employees',
							// icon: 'far fa-database',
							items: [
								{
									label: 'All',
									// icon: 'far fa-database',
									command: (event) => this.handleDataSourceChange('EMPLOYEES_ALL'),
									disabled: () => this.shouldDisableDataSource('EMPLOYEES_ALL'),
								},
								{
									label: 'Active',
									// icon: 'far fa-database',
									command: (event) => this.handleDataSourceChange('EMPLOYEES_ACTIVE'),
									disabled: () => this.shouldDisableDataSource('EMPLOYEES_ACTIVE'),
								},
								{
									label: 'Inactive',
									// icon: 'far fa-database',
									command: (event) => this.handleDataSourceChange('EMPLOYEES_INACTIVE'),
									disabled: () => this.shouldDisableDataSource('EMPLOYEES_INACTIVE'),
								},
							],
						},
						{
							label: 'Supervisors',
							command: (event) => this.handleDataSourceChange('SUPERVISORS_ALL'),
							disabled: () => this.shouldDisableDataSource('SUPERVISORS_ALL'),
						},
					],
				},

				{
					label: 'Work',
					items: [
						{
							label: 'Jobs',
							// icon: 'fal fa-tasks-alt',
							items: [
								{
									label: 'All',
									// icon: 'far fa-database',
									command: (event) => this.handleDataSourceChange('JOBS_ALL'),
									disabled: () => this.shouldDisableDataSource('JOBS_ALL'),
								},
								{
									label: 'Active',
									// icon: 'far fa-database',
									command: (event) => this.handleDataSourceChange('JOBS_ACTIVE'),
									disabled: () => this.shouldDisableDataSource('JOBS_ACTIVE'),
								},
								{
									label: 'Inactive',
									// icon: 'far fa-database',
									command: (event) => this.handleDataSourceChange('JOBS_INACTIVE'),
									disabled: () => this.shouldDisableDataSource('JOBS_INACTIVE'),
								},
							],
						},
						{
							label: 'Tours',
							items: [
								{
									label: 'All',
									command: (event) => this.handleDataSourceChange('TOURS_ALL'),
									disabled: () => this.shouldDisableDataSource('TOURS_ALL'),
								},
								{
									label: 'Basic',
									command: (event) => this.handleDataSourceChange('TOURS_UNSTRUCTURED'),
									disabled: () => this.shouldDisableDataSource('TOURS_UNSTRUCTURED'),
								},
								{
									label: 'Hourly',
									command: (event) => this.handleDataSourceChange('TOURS_HOURLY'),
									disabled: () => this.shouldDisableDataSource('TOURS_HOURLY'),
								},
								{
									label: 'Daily',
									command: (event) => this.handleDataSourceChange('TOURS_DAILY'),
									disabled: () => this.shouldDisableDataSource('TOURS_DAILY'),
								},
								{
									label: 'Shift-timed',
									command: (event) => this.handleDataSourceChange('TOURS_SHIFT'),
									disabled: () => this.shouldDisableDataSource('TOURS_SHIFT'),
								},
							],
						},
					],
				},

				// Scheduling
				{
					label: 'Scheduling',
					items: [
						// Scheduling > Schedules
						{
							label: 'Schedules',
							command: (event) => this.handleDataSourceChange('SCHEDULES_ALL'),
							disabled: () => this.shouldDisableDataSource('SCHEDULES_ALL'),
						},
					],
				},

				// Operations
				{
					label: 'Operations',
					items: [
						// Operations > Time Entries
						{
							label: 'Time Entries',
							items: [
								{
									label: 'Time Entries',
									command: (event) => this.handleDataSourceChange('TRANS_ALL'),
									disabled: () => this.shouldDisableDataSource('TRANS_ALL'),
								},
								{
									label: 'No Shows',
									command: (event) => this.handleDataSourceChange('TRANS_NO_SHOW'),
									disabled: () => this.shouldDisableDataSource('TRANS_NO_SHOW'),
								},
								{
									label: 'No Clock-Out',
									command: (event) => this.handleDataSourceChange('TRANS_MISSING'),
									disabled: () => this.shouldDisableDataSource('TRANS_MISSING'),
								},

								// Operations > Time Entries > Created
								{
									label: 'Source',
									items: [
										{
											label: 'Clock-In',
											items: [
												{
													label: 'All',
													command: (event) => this.handleDataSourceChange('TRANS_CREATED_ALL'),
													disabled: () => this.shouldDisableDataSource('TRANS_CREATED_ALL'),
												},
												{
													label: 'Landline',
													command: (event) => this.handleDataSourceChange('TRANS_CREATED_LANDLINE'),
													disabled: () => this.shouldDisableDataSource('TRANS_CREATED_LANDLINE'),
												},
												{
													label: 'Mobile',
													command: (event) => this.handleDataSourceChange('TRANS_CREATED_MOBILE'),
													disabled: () => this.shouldDisableDataSource('TRANS_CREATED_MOBILE'),
												},
												{
													label: 'Kiosk',
													command: (event) => this.handleDataSourceChange('TRANS_CREATED_KIOSK'),
													disabled: () => this.shouldDisableDataSource('TRANS_CREATED_KIOSK'),
												},
												{
													label: 'Mobile Station',
													command: (event) => this.handleDataSourceChange('TRANS_CREATED_MOBILESTATION'),
													disabled: () => this.shouldDisableDataSource('TRANS_CREATED_MOBILESTATION'),
												},
												{
													label: 'Station',
													command: (event) => this.handleDataSourceChange('TRANS_CREATED_STATION'),
													disabled: () => this.shouldDisableDataSource('TRANS_CREATED_STATION'),
												},
												{
													label: 'Emp App',
													command: (event) => this.handleDataSourceChange('TRANS_CREATED_WEB'),
													disabled: () => this.shouldDisableDataSource('TRANS_CREATED_WEB'),
												},
												{
													label: 'Admin',
													command: (event) => this.handleDataSourceChange('TRANS_CREATED_ADMIN'),
													disabled: () => this.shouldDisableDataSource('TRANS_CREATED_ADMIN'),
												},
											],
										},
										{
											label: 'Clock-Out',
											items: [
												{
													label: 'All',
													command: (event) => this.handleDataSourceChange('TRANS_CLOSED_ALL'),
													disabled: () => this.shouldDisableDataSource('TRANS_CLOSED_ALL'),
												},
												{
													label: 'Landline',
													command: (event) => this.handleDataSourceChange('TRANS_CLOSED_LANDLINE'),
													disabled: () => this.shouldDisableDataSource('TRANS_CLOSED_LANDLINE'),
												},
												{
													label: 'Mobile',
													command: (event) => this.handleDataSourceChange('TRANS_CLOSED_MOBILE'),
													disabled: () => this.shouldDisableDataSource('TRANS_CLOSED_MOBILE'),
												},
												{
													label: 'Kiosk',
													command: (event) => this.handleDataSourceChange('TRANS_CLOSED_KIOSK'),
													disabled: () => this.shouldDisableDataSource('TRANS_CLOSED_KIOSK'),
												},
												{
													label: 'Mobile Station',
													command: (event) => this.handleDataSourceChange('TRANS_CLOSED_MOBILESTATION'),
													disabled: () => this.shouldDisableDataSource('TRANS_CLOSED_MOBILESTATION'),
												},
												{
													label: 'Station',
													command: (event) => this.handleDataSourceChange('TRANS_CLOSED_STATION'),
													disabled: () => this.shouldDisableDataSource('TRANS_CLOSED_STATION'),
												},
												{
													label: 'Emp App',
													command: (event) => this.handleDataSourceChange('TRANS_CLOSED_WEB'),
													disabled: () => this.shouldDisableDataSource('TRANS_CLOSED_WEB'),
												},
												{
													label: 'Admin',
													command: (event) => this.handleDataSourceChange('TRANS_CLOSED_ADMIN'),
													disabled: () => this.shouldDisableDataSource('TRANS_CLOSED_ADMIN'),
												},
											],
										},
									],
								},

								// Operations > Time Entries > Notifications
								{
									label: 'GPS Blocked',
									items: [
										{
											label: 'All',
											command: (event) => this.handleDataSourceChange('TRANS_GPS_BLOCKED_ALL'),
											disabled: () => this.shouldDisableDataSource('TRANS_GPS_BLOCKED_ALL'),
										},
										{
											label: 'Clock-in',
											command: (event) => this.handleDataSourceChange('TRANS_GPS_BLOCKED_IN'),
											disabled: () => this.shouldDisableDataSource('TRANS_GPS_BLOCKED_IN'),
										},
										{
											label: 'Clock-out',
											command: (event) => this.handleDataSourceChange('TRANS_GPS_BLOCKED_OUT'),
											disabled: () => this.shouldDisableDataSource('TRANS_GPS_BLOCKED_OUT'),
										},
									],
								},

								// Operations > Time Entries > Notifications
								{
									label: 'Notifications',
									items: [
										{
											label: 'All',
											command: (event) => this.handleDataSourceChange('TRANS_NOTIFIED_ALL'),
											disabled: () => this.shouldDisableDataSource('TRANS_NOTIFIED_ALL'),
										},
										{
											label: 'Employee',
											command: (event) => this.handleDataSourceChange('TRANS_NOTIFIED_EMP'),
											disabled: () => this.shouldDisableDataSource('TRANS_NOTIFIED_EMP'),
										},
										{
											label: 'Supervisor',
											command: (event) => this.handleDataSourceChange('TRANS_NOTIFIED_SUP'),
											disabled: () => this.shouldDisableDataSource('TRANS_NOTIFIED_SUP'),
										},
									],
								},
							],
						},

						// Operations > Shift Reports
						{
							label: 'Shift Reports',
							// icon: 'far fa-pie-chart',
							items: this.buildShiftReportMenuItems(),
						},

						// Operation > Logs
						{
							label: 'Logging',
							items: [
								{
									label: 'Comm Log',
									command: (event) => this.handleDataSourceChange('COMM_LOG_CALLS_TEXTS'),
									disabled: () => this.shouldDisableDataSource('COMM_LOG_CALLS_TEXTS'),
								},
								{
									label: 'Audit Log',
									// icon: 'far fa-pie-chart',
									command: (event) => this.handleDataSourceChange('AUDIT_LOG_ALL'),
									disabled: () => this.shouldDisableDataSource('AUDIT_LOG_ALL'),
								},
							],
						},
					],
				},
			],
		},
		{
			label: 'Graph Type',
			icon: 'far fa-chart-simple',
			items: [
				{
					label: 'Bar Chart',
					// icon: 'far fa-chart-simple',
					command: (event) => this.handleGraphTyypeChange('bar'),
					visible: () => this.canRenderGraphType('bar'),
				},
				{
					label: 'Line Chart',
					// icon: 'far fa-chart-simple',
					command: (event) => this.handleGraphTyypeChange('line'),
					visible: () => this.canRenderGraphType('line'),
				},
				{
					label: 'Pie Chart',
					// icon: 'far fa-chart-simple',
					command: (event) => this.handleGraphTyypeChange('pie'),
					visible: () => this.canRenderGraphType('pie'),
				},
			],
			visible: () => !!this.data.source,
		},
		{
			separator: true,
		},
		{
			label: 'Configure Graph',
			icon: 'far fa-cog',
			command: (event) => this.handleMenuItemAction('CONFIGURE_GRAPH', null),
		},
		{
			label: 'Close Menu',
			icon: 'far fa-circle-x',
			command: (event) => this.cm.hide(),
			visible: () => !this.devDetect.isDesktop(),
		},
	]

	@ViewChild('cm') cm!: ContextMenu

	constructor(private coreSrvc: CoreService) {
		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()
	}

	get devDetect(): DeviceDetectorService {
		return this.coreSrvc.devDetect
	}
	get vizSrvc(): VisualizationService {
		return this.coreSrvc.dbSrvc.vizSrvc
	}
	get currentDashboard(): VizDashboard {
		return this.coreSrvc.dbSrvc.vizSrvc.currentDashboard
	}

	public ngAfterViewInit(): void {
		this.updateStackingForChartType()
	}

	private buildShiftReportMenuItems(): Array<any> {
		const industry = this.coreSrvc.dbSrvc.settingSrvc.getCompany().industry
		const menuItems = [
			{
				label: 'All',
				command: (event) => this.handleDataSourceChange('SHIFT_REPORT_ALL'),
				disabled: () => this.shouldDisableDataSource('SHIFT_REPORT_ALL'),
			},
			{
				label: 'Basic',
				command: (event) => this.handleDataSourceChange('SHIFT_REPORT_BASIC'),
				disabled: () => this.shouldDisableDataSource('SHIFT_REPORT_BASIC'),
			},
			{
				label: 'Clock-In',
				command: (event) => this.handleDataSourceChange('SHIFT_REPORT_CHECKIN'),
				disabled: () => this.shouldDisableDataSource('SHIFT_REPORT_CHECKIN'),
			},
			{
				label: 'Post-Break',
				command: (event) => this.handleDataSourceChange('SHIFT_REPORT_POST_BREAK'),
				disabled: () => this.shouldDisableDataSource('SHIFT_REPORT_POST_BREAK'),
			},
			{
				label: 'Clock-Out',
				command: (event) => this.handleDataSourceChange('SHIFT_REPORT_CHECKOUT'),
				disabled: () => this.shouldDisableDataSource('SHIFT_REPORT_CHECKOUT'),
			},
			{
				label: 'Home Care',
				command: (event) => this.handleDataSourceChange('SHIFT_REPORT_HOMECARE'),
				disabled: () => this.shouldDisableDataSource('SHIFT_REPORT_HOMECARE'),
			},
			{
				label: 'Vehicle',
				command: (event) => this.handleDataSourceChange('SHIFT_REPORT_VEHICLE'),
				disabled: () => this.shouldDisableDataSource('SHIFT_REPORT_VEHICLE'),
			},
			{
				label: 'Incident',
				command: (event) => this.handleDataSourceChange('SHIFT_REPORT_INCIDENT'),
				disabled: () => this.shouldDisableDataSource('SHIFT_REPORT_INCIDENT'),
			},
			{
				label: 'Custom',
				command: (event) => this.handleDataSourceChange('SHIFT_REPORT_CUSTOM'),
				disabled: () => this.shouldDisableDataSource('SHIFT_REPORT_CUSTOM'),
			},
		]
		if (industry === 'HEALTHCARE') {
			return menuItems
		} else {
			const index = menuItems.findIndex((item) => item.label === 'Home Care')
			if (index) {
				menuItems.splice(index, 1)
			}
			return menuItems
		}
	}

	public openContextMenu(event: MouseEvent, cm: any) {
		cm.show(event)
		event.preventDefault() // Prevent default to avoid actual context menu on right click
	}

	private handleMenuItemAction(action: VizCardAction, direction: VizCardMoveDirection) {
		const event = new VizCardActionEvent(action, 'GRAPH', direction, this.rowIndex, this.cardIndex, this.data)
		this.cardEvent.next(event)
	}

	private handleDataSourceChange(source: VizCardGraphDataSourceType) {
		if (this.data.source === source) return // no change

		// Change stacking based on the current chart type
		if (this.data.type === 'bar') {
			this.data.options.scales.y.stacked = true
		} else {
			this.data.options.scales.y.stacked = false
		}

		this.data.setSource(source)
		this.data.setupOptions()
		this.data.updateData()
		this.updateStackingForChartType()
		this.vizSrvc.markCurrentDashboardAsModified()
	}

	private shouldDisableMove(direction: VizCardMoveDirection) {
		const dashboardData = this.currentDashboard.data
		const row = dashboardData[this.rowIndex].getRowData()
		const cardIndex = this.cardIndex
		if (cardIndex !== -1) {
			if (direction === 'RIGHT' && cardIndex < row.length - 1) {
				return false
			} else if (direction === 'LEFT' && cardIndex > 0) {
				return false
			} else if (direction === 'UP' && this.rowIndex > 0) {
				return false
			} else if (direction === 'DOWN' && this.rowIndex < dashboardData.length - 1) {
				return false
			}
		}
		return true
	}

	private shouldDisableDataSource(source: VizCardGraphDataSourceType) {
		return false // this.rowHasInstanceOf(source)
	}

	private rowHasInstanceOf(source: VizCardGraphDataSourceType) {
		const dashboardData = this.currentDashboard.data
		const row = dashboardData[this.rowIndex].getRowData()
		for (const card of row) {
			if (card.source === source) return true
		}
		return false
	}

	// Methods specific to graph cards

	private canRenderGraphType(type: ChartsGraphType) {
		return VizDashboardHelper.canRenderChartTypeForDataSource(this.data.source, type)
	}

	private handleGraphTyypeChange(type: ChartsGraphType) {
		if (this.data.type === type) return // no change
		this.data.setType(type)
		this.data.setupOptions()
		this.data.updateData()
		this.updateStackingForChartType()
		this.vizSrvc.markCurrentDashboardAsModified()
	}

	private updateStackingForChartType() {
		if (this.data.type === 'bar') {
			this.data.options.scales.y.stacked = true
		} else {
			this.data.options.scales.y.stacked = false
		}
	}
}
