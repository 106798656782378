<!-- DEPCRECATED 20240718 - Not sure if used -->
<!-- <input type="text" id="focus-trap" style="position: absolute; top: -1000px; left: -1000px" /> -->

<div [hidden]="fullScreenManager.isFullScreen" [class.noprint]="fullScreenManager.isFullScreen">
	<app-section-process-indicator></app-section-process-indicator>
	<app-shell></app-shell>
	<nav
		*ngIf="!isConnected || billingMessage || currentDisplayUser"
		class="navbar navbar-light bg-faded nav-offline status-bg-dark"
		[class.status-bg-beige]="!currentDisplayUser && billingMessage?.warn"
		[class.status-view-as]="currentDisplayUser && !billingMessage && isConnected"
	>
		<div *ngIf="!isConnected" style="text-align: center; margin: auto"><strong>No Network Connection</strong></div>
		<div *ngIf="billingMessage && isConnected" style="text-align: center; margin: auto">
			<strong [class.blink]="billingMessage.blink">{{ billingMessage.message }}</strong>
			<a *ngIf="displayFixButton()" [routerLink]="['/admin/settings/billing']" class="btn btn-sm btn-outline-secondary btn-billing">{{
				billingMessage.btnText
			}}</a>
		</div>
		<div *ngIf="isConnected && !billingMessage && currentDisplayUser" style="text-align: center; margin: auto">
			Viewing site as {{ currentDisplayUser }}
		</div>
	</nav>

	<app-audit-modal
		[recordId]="auditAction.recordId"
		[resource]="auditAction.resource"
		[header]="auditAction.header"
		[footer]="auditAction.footer"
		[trackType]="auditAction.trackType"
		[(visible)]="auditAction.showDialog"
	></app-audit-modal>
</div>

<div *ngIf="fullScreenManager.isFullScreen" style="width: 100%; text-align: center">
	<app-user-report-render-host [config]="fullScreenManager.config"></app-user-report-render-host>
</div>

<div *ngIf="!fullScreenManager.isFullScreen">
	<app-click-to-call></app-click-to-call>

	<!-- Working/Progress HUD -->
	<p-dialog
		styleClass="p-dialog-noheader"
		[(visible)]="workingDialogConfig.isVisible"
		[modal]="false"
		[style]="{ width: '300px' }"
		[showHeader]="false"
		[baseZIndex]="5000"
		[dismissableMask]="true"
		[transitionOptions]="'0ms'"
		(onShow)="workingDialogConfig.onShow()"
		(onHide)="workingDialogConfig.onHide()"
	>
		<div class="tts-default-font" style="text-align: center; color: #566978; font-weight: bold">
			<i class="fas fa-loader fa-spin"></i> {{ workingDialogConfig.message }}
		</div>
	</p-dialog>
</div>

<p-toast position="top-right" key="noteSrvc" [baseZIndex]="10000"></p-toast>

<app-enrollment></app-enrollment>
<app-global-audio-player></app-global-audio-player>

<!-- Use hidden attribute to manage dialog changing visibile option causes Bootstrap modals to scroll contents to top -->
<!-- [style]="{ width: '300px', position: 'absolute', top: '10px', left: '10px' }" -->

<div [hidden]="!help.showDialog">
	<p-dialog
		[maskStyleClass]="'no-mask-dialog'"
		[header]="help.header"
		[visible]="true"
		[modal]="false"
		[style]="{ width: '300px' }"
		[dismissableMask]="true"
		[focusOnShow]="false"
		(visibleChange)="help.showDialog = $event"
	>
		<div class="tts-info-msg">
			<span [innerHTML]="help.msg | sanitizer"></span>
		</div>
	</p-dialog>
</div>

<div id="date-picker-floating-title" class="title-wrap">{{ datePickerLabel }}</div>

<app-contact-support-dialog
	[(showDialog)]="contactSupportAction.showDialog"
	[resource]="contactSupportAction.resource"
	[recordId]="contactSupportAction.recordId"
	[title]="contactSupportAction.title"
></app-contact-support-dialog>
