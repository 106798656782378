import { CoreService } from '@app/services'
import _ from 'lodash'
import { Global } from './global'

// Client portal preferences

export interface IClientPrefs {
	placeholder: string
}

export class ClientPrefs implements IClientPrefs {
	placeholder: string

	constructor(data?: AdminPrefs) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
	}
}

// Admin portal preferences - When changing a prefs default, make sure to update the prefs array in AdminPrefsConfig

export class AdminPrefs {
	// Notification Options (Internal)
	npEnableNoteEmpPreShift = false
	npEnableNoteEmpEarlyCheckin = false
	npEnableNoteEmpCheckin = false
	npEnableRepeatingInputs = false
	npEnableClientOptions = false
	npSupPhoneNumberLimit = 3

	// Miscellaneous (Internal)
	enableGlobalCognitoSignout = false
	enableTagExpansion = true
	employeeEnableEmpAppPrefs = false
	userEnablePortalPrefs = false
	enableAddressByMapPin = false

	transDisplayGpsTimeInfo = false
	userEnableManagerRole = false
	schedulerEnableBatchEdit = false
	schedulerEnablePendingQueue = false
	schedulerEnableMultiSchedule = false
	schedulerEnableAnytimeCheckbox = false
	// timeOffNotifyEmpNewEntry = true // DEPRECATED 20241001
	enableOpenShiftOffers = true
	openTimeEntryDetailsInMapView = false

	globalEnableAnalytics = false
	globalEnableChatbot = false
	globalFormatTime12Hours = true
	globalHighlightMissingExtId = false
	globalBrowserClickToCall = false
	globalExpandAdvancedOptions = false
	globalGroupNotificationBanners = false
	globalExcelFormulaGuard = false

	// Employee
	employeeAutoCheckInOut = false
	employeeDisplayLastFirst = false

	// Job
	jobEnableQRCodeColumn = false
	jobEnableAdvancedTours = true

	// Breaks
	breakEnableAdvancedReminders = false

	// Schedule
	enableTimeOffConflictOverride = true

	schedulerAutoDisableExpired = null
	schedulerAutoDeleteExpired = null

	// Time Entry
	transEnableAdminCreatedCheckpoints = true
	transNoShowFilterDays = 1
	transHighlightEarlyCheckin = null
	transHighlightLateCheckin = null
	transHighlightEarlyCheckout = null
	transHighlightLateCheckout = null
	transHighlightShortBreak = null
	transHighlightLongBreak = null
	transHighlightShortShift = null
	transHighlightLongShift = null
	transFetchCountOverride = null
	// transShowActualInOutDates = true // DEPRECATED 20240930
	transShowJSQualifier = true
	transShowJobSchedPopup = true

	globalHighlightImgLastModDuration = 30

	// Reports
	reportShowDelayRunInput = false

	constructor(data?: AdminPrefs) {
		this.mergeData(data)
	}

	mergeData(data?: AdminPrefs) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
	}
}

export interface IAdminPrefsConfigItemIntegerValidator {
	type: string
	min: number
	max: number
}

export interface IPrefsCategory {
	name: string
	value: string
	isExpanded: boolean
	note: string
}

export interface IAdminPrefsConfigItem {
	name: string
	default: any
	value: any
	label: string
	type: string
	category: string
	validator: any
	description: string
	uiConfig: any
	userOverride: boolean
	isChild: boolean
	isHidden: () => boolean // Use introspection on AdminPrefsConfig to determine if this should be shown
	matchesSearchFilter: boolean // Search filter will set this when running the search
}

// The userOverride option is used to control if it can be overriden by individual users

export class AdminPrefsConfig {
	prefs: Array<IAdminPrefsConfigItem> = [
		// Notification Options (Internal)

		// DEPRECATED 20241001
		// {
		// 	name: 'timeOffNotifyEmpNewEntry',
		// 	default: true,
		// 	value: true,
		// 	label: 'Enable notification to employees when a new time off entry is created',
		// 	type: 'BOOLEAN',
		// 	category: 'internalNotifications',
		// 	validator: null,
		// 	description: `When checked, the option to notify employees when a new time off entry is created will default to enabled. When unchecked the default will be disabled.`,
		// 	uiConfig: null,
		// 	userOverride: true,
		// 	isChild: false,
		// 	isHidden: null,
		// 	matchesSearchFilter: true,
		// },
		{
			name: 'npEnableNoteEmpPreShift',
			default: false,
			value: false,
			label: 'Enable employee pre-shift notification option in notification profiles',
			type: 'BOOLEAN',
			category: 'internalNotifications',
			validator: null,
			description: `When checked, an option will be exposed in the notification profiles edit dialog allowing for employee pre-shift notifications.`,
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'npEnableNoteEmpEarlyCheckin',
			default: false,
			value: false,
			label: 'Enable employee early checkin notification to supervisor in notification profiles',
			type: 'BOOLEAN',
			category: 'internalNotifications',
			validator: null,
			description: `When checked, an option will be exposed in the notification profiles edit dialog allowing supervisor to be notified for employee early checkin.`,
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'npEnableNoteEmpCheckin',
			default: false,
			value: false,
			label: 'Enable employee checkin notification to supervisor in notification profiles',
			type: 'BOOLEAN',
			category: 'internalNotifications',
			validator: null,
			description: `When checked, an option will be exposed in the notification profiles edit dialog allowing supervisor to be notified for employee checkin.`,
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'npEnableRepeatingInputs',
			default: false,
			value: false,
			label: 'Enable repeating notifications option in notification profiles',
			type: 'BOOLEAN',
			category: 'internalNotifications',
			validator: null,
			description: `When checked, repeat notfication settings with be exposed in notification profiles edit dialog.`,
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'npEnableClientOptions',
			default: false,
			value: false,
			label: 'Enable client notifications options in notification profiles',
			type: 'BOOLEAN',
			category: 'internalNotifications',
			validator: null,
			description: `When checked, the section for setting client notification options will be available for notification profiles.`,
			uiConfig: null,
			userOverride: true, // false
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'npSupPhoneNumberLimit',
			default: null,
			value: null,
			label: 'Supervisor phone number limit',
			type: 'INPUT',
			category: 'internalNotifications',
			validator: { type: 'INTEGER', min: 3, max: null },
			description: 'Maximum number of supervisor phone numbers that may be added to a notification profile.',
			uiConfig: {
				inputLabel: 'Phone number limit',
				inputClass: 'prefs-input-delay',
				inputType: 'tel',
			},
			userOverride: false,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},

		// Schedule Prefs
		{
			name: 'enableTimeOffConflictOverride',
			default: true,
			value: true,
			label: 'Allow overriding time-off conflicts with scheduled shifts',
			type: 'BOOLEAN',
			category: 'scheduleOption',
			validator: null,
			description: `When checked, time-off entries that conflict with scheduled shifts may be saved and re-assignment of conflicting shifts deferred.\n\nWhen left unchecked, admins must fix any scheduling conflicts before the time-off entry may be saved.`,
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},

		// Internal Prefs

		{
			name: 'globalEnableAnalytics',
			default: false,
			value: false,
			label: 'Enable Data / Analytics',
			type: 'BOOLEAN',
			category: 'internalOption',
			validator: null,
			description: `When checked, will expose the Data / Analytics section and Health Score banner.`,
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'globalEnableChatbot',
			default: false,
			value: false,
			label: 'Enable AI chatbot link',
			type: 'BOOLEAN',
			category: 'internalOption',
			validator: null,
			description: `When checked, will expose the chatbot link in the footer.`,
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},

		// Jobs
		{
			name: 'jobEnableQRCodeColumn',
			default: false,
			value: false,
			label: 'Enable QR codes without validation',
			type: 'BOOLEAN',
			category: 'internalOption',
			validator: null,
			description: `When checked, this will expose the QR code column in the Jobs table without enabling QR code validation.\n\nValidation is managed in Admin > Settings > General Settings.`,
			uiConfig: null,
			userOverride: false,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'jobEnableAdvancedTours',
			default: true,
			value: true,
			label: 'Enable advanced tours',
			type: 'BOOLEAN',
			category: 'internalOption',
			validator: null,
			description: `When checked, this will enable advanced checkpoint tours on the job.`,
			uiConfig: null,
			userOverride: false,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},

		// Breaks
		{
			name: 'breakEnableAdvancedReminders',
			default: false,
			value: false,
			label: 'Enable advanced break reminders',
			type: 'BOOLEAN',
			category: 'internalOption',
			validator: null,
			description: `When checked, this will enable the ability to step through advanced reminder notification options.`,
			uiConfig: null,
			userOverride: false,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'transEnableAdminCreatedCheckpoints',
			default: true,
			value: true,
			label: 'Allow admins to create checkpoints on time entries',
			type: 'BOOLEAN',
			category: 'internalOption',
			validator: null,
			description: `When checked, admins will be able to create new checkpoints on time entries. The default is enabled.`,
			uiConfig: null,
			userOverride: false,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'enableGlobalCognitoSignout',
			default: false,
			value: false,
			label: 'Enable global Cognito signout',
			type: 'BOOLEAN',
			category: 'internalOption',
			validator: null,
			description: `When checked, will perform a global signout from Cognito when you logout from portal. This is a system requirement for ADP compliance.`,
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'enableTagExpansion',
			default: true,
			value: true,
			label: 'Enable table tags expansion',
			type: 'BOOLEAN',
			category: 'internalOption',
			validator: null,
			description: `When checked, individual tags will be shown in the tables instead of a compressed summary icon.`,
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'enableOpenShiftOffers',
			default: true,
			value: true,
			label: 'Enable open shift offer feature',
			type: 'BOOLEAN',
			category: 'internalOption',
			validator: null,
			description: `When checked, admins will be able to create open shift offers.`,
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'enableAddressByMapPin',
			default: false,
			value: false,
			label: 'Enable setting address with map pin in job / site edit form',
			type: 'BOOLEAN',
			category: 'internalOption',
			validator: null,
			description: `When checked, an option will be exposed in the job / site edit form allowing addresses to be set using a map and pin.`,
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'openTimeEntryDetailsInMapView',
			default: false,
			value: false,
			label: 'Open time entry details in map view instead of list view',
			type: 'BOOLEAN',
			category: 'internalOption',
			validator: null,
			description: `When checked, the details dialog will open in map view by default. When unchecked it will open in info view.`,
			uiConfig: null,
			userOverride: true, // false
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},

		{
			name: 'schedulerEnableBatchEdit',
			default: false,
			value: false,
			label: 'Enable batch schedule editing',
			type: 'BOOLEAN',
			category: 'internalOption',
			validator: null,
			description: `When checked, options are exposed in the schedule view to allow for batch editing.`,
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'schedulerEnablePendingQueue',
			default: false,
			value: false,
			label: 'Enable pending schedule queue',
			type: 'BOOLEAN',
			category: 'internalOption',
			validator: null,
			description: `When checked, options are exposed in the schedule edit form to allow marking records as pending.`,
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'schedulerEnableMultiSchedule',
			default: false,
			value: false,
			label: `Enable multi-schedule option`,
			type: 'BOOLEAN',
			category: 'internalOption',
			validator: null,
			description: `When checked, this will enable showing the mult-schedule option in the scheduler. The option will also be visible if any existing schedule is setup as multi-schedule.`,
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'schedulerEnableAnytimeCheckbox',
			default: false,
			value: false,
			label: `Enable 'Schedule anytime of day' checkbox in the schedule edit form`,
			type: 'BOOLEAN',
			category: 'internalOption',
			validator: null,
			description: `When checked, this will enable showing the 'Schedule anytime of day' checkbox in the schedule edit form. The checkbox will also be visible if any existing schedule for the company is set as an anytime schedule.`,
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'userEnableManagerRole',
			default: false,
			value: false,
			label: 'Enable managing supervisor role',
			type: 'BOOLEAN',
			category: 'internalOption',
			validator: null,
			description:
				'When checked, the user edit form will allow setting the manager role for supervisors and the permissions template will be made available for use.',
			uiConfig: null,
			userOverride: true, // false
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'transDisplayGpsTimeInfo',
			default: false,
			value: false,
			label: 'Display GPS time information seperate from distance',
			type: 'BOOLEAN',
			category: 'internalOption',
			validator: null,
			description:
				'When checked, the GPS time icon and information will be shown separately from the GPS distance information in some views. When unchecked, the GPS marker will reflect issues with either time or distance.',
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'employeeEnableEmpAppPrefs',
			default: false,
			value: false,
			label: 'Enable managing employee app prefs for individual employees',
			type: 'BOOLEAN',
			category: 'internalOption',
			validator: null,
			description: `When checked, an option will be exposed in the employee list allowing for modification of employee app prefs for individual employees which will override company settings.`,
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'userEnablePortalPrefs',
			default: false,
			value: false,
			label: 'Enable managing admin portal prefs for individual supervisors',
			type: 'BOOLEAN',
			category: 'internalOption',
			validator: null,
			description: `When checked, an option will be exposed in the supervisor list allowing for modification of admin portal prefs for individual supervisors which will override company settings.`,
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},

		{
			name: 'schedulerAutoDisableExpired',
			default: null,
			value: null,
			label: 'Auto disable expired schedule',
			type: 'INPUT',
			category: 'internalOption',
			validator: { type: 'INTEGER', min: null, max: null },
			description:
				'Automatically disable expired schedules after a set number of days when the schedule specifies an end condition. Leave blank to disable this feature.',
			uiConfig: {
				inputLabel: 'Duration in days',
				inputClass: 'prefs-input-delay',
				inputType: 'tel',
			},
			userOverride: false,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'schedulerAutoDeleteExpired',
			default: null,
			value: null,
			label: 'Auto delete expired schedule',
			type: 'INPUT',
			category: 'internalOption',
			validator: { type: 'INTEGER', min: null, max: null },
			description:
				'Automatically delete expired schedules after a set number of days when the schedule specifies an end condition. Leave blank to disable this feature.',
			uiConfig: {
				inputLabel: 'Duration in days',
				inputClass: 'prefs-input-delay',
				inputType: 'tel',
			},
			userOverride: false,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},

		// General Prefs
		{
			name: 'globalFormatTime12Hours',
			default: true,
			value: true,
			label: 'Use 12-hour time format',
			type: 'BOOLEAN',
			category: 'generalOption',
			validator: null,
			description: 'When checked, the system will format times for a 12 hour clock, otherwise times will be shown in 24 hour format.',
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'globalGroupNotificationBanners',
			default: false,
			value: false,
			label: 'Group notification banners',
			type: 'BOOLEAN',
			category: 'generalOption',
			validator: null,
			description: 'When checked, the system group all notifications under a single banner rather than display individual notification chips.',
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'globalHighlightMissingExtId',
			default: false,
			value: false,
			label: 'Highlight missing external IDs',
			type: 'BOOLEAN',
			category: 'generalOption',
			validator: null,
			description: `When checked, records which do not have external record IDs assigned will be flagged with a note indicate the ID is missing.`,
			uiConfig: null,
			userOverride: true, // false
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'globalBrowserClickToCall',
			default: false,
			value: false,
			label: 'Enable browser Click-to-Call',
			type: 'BOOLEAN',
			category: 'generalOption',
			validator: null,
			description: 'When checked, context specific address books will provide a call and text buttons to facilitate dialing from your browser.',
			// description: 'When checked, context specific address books will provide call/text buttons to initiate these actions from your browser.',
			uiConfig: null,
			userOverride: true, // false
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'globalExpandAdvancedOptions',
			default: false,
			value: false,
			label: 'Auto-expand advanced options',
			type: 'BOOLEAN',
			category: 'generalOption',
			validator: null,
			description: 'When checked, the <b>Advanced Options</b> of various edit dialogs will be expanded automatically.',
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'globalExcelFormulaGuard',
			default: false,
			value: false,
			label: 'Excel import formula protection',
			type: 'BOOLEAN',
			category: 'generalOption',
			validator: null,
			description: `When this option is enabled, a single quote (') will be added before cell values that could be interpreted as Excel formulas during the export of records from the admin portal.<br><br>This precaution helps prevent potential security issues but may affect your data when importing into some spreadsheet applications. Be sure to account for this as part of your import process.`,
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},

		// Employee Prefs
		{
			name: 'employeeDisplayLastFirst',
			default: false,
			value: false,
			label: 'Display name as Last, First',
			type: 'BOOLEAN',
			category: 'employeeOption',
			validator: null,
			description: `When checked, the employee display name will be automatically formatted to use 'Last, First' when editing their name.`,
			uiConfig: null,
			userOverride: true, // false
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'employeeAutoCheckInOut',
			default: false,
			value: false,
			label: 'Enable auto check in/out option',
			type: 'BOOLEAN',
			category: 'employeeOption',
			validator: null,
			description: `When checked, an option will be exposed in the employee edit dialog for enabling automatic check in/out for employees. The default is unchecked.`,
			uiConfig: null,
			userOverride: true, // false
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},

		// DEPRECATED 20240930
		// Transaction Prefs
		// {
		// 	name: 'transShowActualInOutDates',
		// 	default: true,
		// 	value: true,
		// 	label: 'Show month and day on times for in / out column',
		// 	type: 'BOOLEAN',
		// 	category: 'transactionOption',
		// 	validator: null,
		// 	description: 'When checked, the month and day (mm/dd) will be shown next to the check in and out times for the <b>In/Out</b> column.',
		// 	uiConfig: null,
		// 	userOverride: true,
		// 	isChild: false,
		// 	isHidden: null,
		// 	matchesSearchFilter: true,
		// },
		{
			name: 'transShowJSQualifier',
			default: true,
			value: true,
			label: 'Show job / schedule qualifier',
			type: 'BOOLEAN',
			category: 'transactionOption',
			validator: null,
			description:
				'When checked, a colored qualifier tag is shown in the Job / Schedule column of the transaction table to highlight various useful information.',
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'transShowJobSchedPopup',
			default: true,
			value: true,
			label: 'Show action pop-up when clicking on job / schedule',
			type: 'BOOLEAN',
			category: 'transactionOption',
			validator: null,
			description:
				'When checked, the action popup will be displayed allowing job filtering or routing to shift view. When unchecked, no dialog is displayed and filtering is performed.',
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'transFetchCountOverride',
			default: null,
			value: null,
			label: 'Time entry fetch count',
			type: 'INPUT',
			category: 'internalOption',
			validator: { type: 'INTEGER', min: 500, max: 3000 },
			description:
				'Override the number of records to fetch in the time entries table. Set this to a value between 500 - 3000 to override individual user settings. A higher count fetches more records but decreases performance.',
			uiConfig: {
				inputLabel: 'Number of records',
				inputClass: 'prefs-input-delay',
				inputType: 'tel',
			},
			userOverride: false,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'transNoShowFilterDays',
			default: 1,
			value: 1,
			label: 'No show counter lookback window',
			type: 'INPUT',
			category: 'internalOption',
			validator: { type: 'INTEGER', min: null, max: null },
			description: 'Set the number of days to look back when counting no show time entries. The default is 1 day. Leave blank to disable.',
			uiConfig: {
				inputLabel: 'Duration in days',
				inputClass: 'prefs-input-delay',
				inputType: 'tel',
			},
			userOverride: false,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'transHighlightEarlyCheckin',
			default: null,
			value: null,
			label: 'Highlight early check in',
			type: 'INPUT',
			category: 'transactionOption',
			validator: { type: 'INTEGER', min: null, max: null },
			description:
				'Highlight early check in for time entries. For example, if you want to flag employees who check in more than 1 hour early, enter 60 in the box provided. Leave blank to disable.',
			uiConfig: {
				inputLabel: 'Duration in minutes',
				inputClass: 'prefs-input-delay',
				inputType: 'tel',
			},
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'transHighlightLateCheckin',
			default: null,
			value: null,
			label: 'Highlight late check in',
			type: 'INPUT',
			category: 'transactionOption',
			validator: { type: 'INTEGER', min: null, max: null },
			description:
				'Highlight late check in for time entries. For example, if you want to flag employees who check in more than 1 hour late, enter 60 in the box provided. Leave blank to disable.',
			uiConfig: {
				inputLabel: 'Duration in minutes',
				inputClass: 'prefs-input-delay',
				inputType: 'tel',
			},
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'transHighlightEarlyCheckout',
			default: null,
			value: null,
			label: 'Highlight early check out',
			type: 'INPUT',
			category: 'transactionOption',
			validator: { type: 'INTEGER', min: null, max: null },
			description:
				'Highlight early check out for time entries. For example, if you want to flag employees who check out more than 1 hour early, enter 60 in the box provided. Leave blank to disable.',
			uiConfig: {
				inputLabel: 'Duration in minutes',
				inputClass: 'prefs-input-delay',
				inputType: 'tel',
			},
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'transHighlightLateCheckout',
			default: null,
			value: null,
			label: 'Highlight late check out',
			type: 'INPUT',
			category: 'transactionOption',
			validator: { type: 'INTEGER', min: null, max: null },
			description:
				'Highlight late check out for time entries. For example, if you want to flag employees who check out more than 1 hour late, enter 60 in the box provided. Leave blank to disable.',
			uiConfig: {
				inputLabel: 'Duration in minutes',
				inputClass: 'prefs-input-delay',
				inputType: 'tel',
			},
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'transHighlightShortShift',
			default: null,
			value: null,
			label: 'Highlight short shifts',
			type: 'INPUT',
			category: 'transactionOption',
			validator: { type: 'INTEGER', min: null, max: null },
			description:
				'Highlight short shifts for time entries. For example, if you want to flag employees that worked less than 1 hour, enter 60 in the box provided. Leave blank to disable.',
			uiConfig: {
				inputLabel: 'Duration in minutes',
				inputClass: 'prefs-input-delay',
				inputType: 'tel',
			},
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'transHighlightLongShift',
			default: null,
			value: null,
			label: 'Highlight long shifts',
			type: 'INPUT',
			category: 'transactionOption',
			validator: { type: 'FLOAT', min: null, max: null },
			description:
				'Highlight long shifts for time entries. For example, if you want to flag employees that worked more than 8 hours, enter 8 in the box provided. Decimal values are allowed. Leave blank to disable.',
			uiConfig: {
				inputLabel: 'Duration in hours',
				inputClass: 'prefs-input-delay',
				inputType: 'tel',
			},
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'transHighlightShortBreak',
			default: null,
			value: null,
			label: 'Highlight short breaks',
			type: 'INPUT',
			category: 'transactionOption',
			validator: { type: 'INTEGER', min: null, max: null },
			description:
				'Highlight short breaks for time entries. For example, if you want to flag employees that are on break for less than 30 minutes, enter 30 in the box provided. Leave blank to disable.',
			uiConfig: {
				inputLabel: 'Duration in minutes',
				inputClass: 'prefs-input-delay',
				inputType: 'tel',
			},
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'transHighlightLongBreak',
			default: null,
			value: null,
			label: 'Highlight long breaks',
			type: 'INPUT',
			category: 'transactionOption',
			validator: { type: 'INTEGER', min: null, max: null },
			description:
				'Highlight long breaks for time entries. For example, if you want to flag employees that are on break for more than 1 hour, enter 60 in the box provided. Leave blank to disable.',
			uiConfig: {
				inputLabel: 'Duration in minutes',
				inputClass: 'prefs-input-delay',
				inputType: 'tel',
			},
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},

		{
			name: 'globalHighlightImgLastModDuration',
			default: 30,
			value: 30,
			label: 'Highlight invalid images',
			type: 'INPUT',
			category: 'internalOption',
			validator: { type: 'INTEGER', min: null, max: null },
			description: `Specify the allowed delay between when an image is generated and when it is submitted. Clear the field to disable this feature.`,
			uiConfig: {
				inputLabel: 'Duration in minutes',
				inputClass: 'prefs-input-delay',
				inputType: 'tel',
			},
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},

		// Report Prefs
		{
			name: 'reportShowDelayRunInput',
			default: false,
			value: false,
			label: 'Enable report run delay',
			type: 'BOOLEAN',
			category: 'reportsOption',
			validator: null,
			description: `Select this option to expose a field in the <b>Insight Reports</b> edit form to delay running a report by a number of days.<br><br>Run delays are useful for those who need a day or two after a payroll period closes to verify time entries before payroll reports are run.`,
			uiConfig: null,
			userOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
	]

	unknownPrefs = []

	static getCategories(): Array<IPrefsCategory> {
		return [
			{ value: 'generalOption', name: 'General', note: null, isExpanded: true },
			{ value: 'employeeOption', name: 'Employees', note: null, isExpanded: true },
			{ value: 'scheduleOption', name: 'Schedules', note: null, isExpanded: true },
			{ value: 'transactionOption', name: 'Time Entries', note: null, isExpanded: true },
			{ value: 'reportsOption', name: 'Reports', note: null, isExpanded: true },
		]
	}

	setPrefs(prefs: AdminPrefs) {
		for (const attr in prefs) {
			if (this.getPrefForName(attr)) {
				const config = this.getPrefForName(attr)
				config.value = prefs[attr]
			} else {
				const unknownPref = { name: attr, value: prefs[attr] }
				this.unknownPrefs.push(unknownPref)
			}
		}
	}

	getPrefForName(name: string) {
		return this.prefs.find((item) => item.name === name)
	}

	getCompanyUpdateString(): string {
		const result = {}
		this.prefs.forEach((pref) => {
			if (pref.value !== pref.default) {
				result[pref.name] = pref.value
			}
		})
		return _.isEmpty(result) ? null : JSON.stringify(result)
	}

	getUserUpdateString(companyAdminPrefs: AdminPrefs): string {
		const result = {}
		this.prefs.forEach((pref) => {
			if (pref.userOverride && pref.value !== companyAdminPrefs[pref.name]) {
				result[pref.name] = pref.value
			}
		})
		// this.unknownPrefs.forEach(pref => { result[pref.name] = pref.value })
		// return JSON.stringify(result)
		return _.isEmpty(result) ? null : JSON.stringify(result)
	}

	format(name: string, value: string): any {
		const config = this.getPrefForName(name)
		return config[value]
	}
}

// Employee App Preferences

export type EmpAppPickerOption = 'CODE' | 'SITE' | 'SCHEDULE' | 'DISABLE'

// When updating a prefs default, make sure to update the prefs array in EmpAppPrefsConfig

export class EmpAppPrefs {
	// Internal
	enableSimpleGps = false
	enableTimeOffRequests = true
	enableAdvancedTravelTracking = false
	enableReportLinksForEmpApp = true
	enableJobCodeFilterForEmpShifts = false

	// General
	enableWebApiCheckin = true
	preventCheckInForGpsDenied = false
	preventClockInForGpsRange = false
	preventClockInForGpsDistance = null

	// Job Site Picker
	pickerOptionShowSelectBySite = true
	jobSitePickerShowDefaultJob = true
	jobSitePickerFilterBySchedule = false

	// Schedule Picker
	pickerOptionShowSelectBySchedule = false
	schedulePickerShowDefaultJob = true

	// Misc Options
	pickerOptionDefaultPicker: EmpAppPickerOption = 'SITE'

	// Checkin Screen
	checkinShowSiteDetails = false
	checkinShowJobDetails = false
	checkinShowSchedDetails = false

	// Available Reports
	enableReportBasicShift = true
	enableReportHomecare = true
	enableReportIncident = true
	enableReportVehicleInspection = true

	// Reports
	virInstructions = ''
	virCaptureOnly = false
	virRequirePhoto = false
	virRequirePhotoCount = null

	constructor(data?: EmpAppPrefs) {
		this.mergeData(data)
	}

	mergeData(data?: EmpAppPrefs) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
	}
}

// NOTE : empOverride has not been thouroughly tested
export interface IEmpAppPrefsConfigItem {
	name: string
	default: any
	value: any
	label: string
	type: string
	validator: any
	category: string
	showGlobal: boolean
	options: Array<any> // used for select options
	description: string // available via info icon
	enabledNote: string // alert-warning when enabledNote value is not null
	placeholder: string // used for input/textarea placeholder
	uiConfig: any
	empOverride: boolean
	isChild: boolean
	isHidden: () => boolean // Use introspection on EmpAppPrefsConfig to determine if this should be shown
	matchesSearchFilter: boolean // Search filter will set this when search is run
}

export class EmpAppPrefsConfig {
	prefs: Array<IEmpAppPrefsConfigItem> = [
		//Internal
		{
			name: 'enableSimpleGps',
			default: false,
			value: false,
			label: 'Enable simple clock in/out',
			type: 'BOOLEAN',
			validator: null,
			category: 'internalOption',
			showGlobal: false,
			options: [],
			description: `When employees check in or out using the employee app, they will not be prompted for notes or photos unless the job requires them.`,
			enabledNote: null, // 'When simple clock in/out is enabled, employees will no longer be given the option to submit notes and photos. Any existing job-level photo requirements will be ignored.',
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'enableTimeOffRequests',
			default: true,
			value: true,
			label: 'Enable time off requests',
			type: 'BOOLEAN',
			validator: null,
			category: 'internalOption',
			showGlobal: false,
			options: [],
			description: `When checked, the option for employees to request time off will be available in the Time Off tab of the Schedule view.`,
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},

		{
			name: 'enableAdvancedTravelTracking',
			default: false,
			value: false,
			label: 'Enable advanced travel tracking',
			type: 'BOOLEAN',
			validator: null,
			category: 'internalOption',
			showGlobal: false,
			options: [],
			description: `When checked, the Travel button will offer the ability to select either Driver or Passenger when starting travel.`,
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'enableReportLinksForEmpApp',
			default: true,
			value: true,
			label: 'Enable employee access to public reports in shift history view',
			type: 'BOOLEAN',
			validator: null,
			category: 'internalOption',
			showGlobal: false,
			options: [],
			description: `Allow employees to access public reports, including admin notes, for any shift reports they've submitted.`,
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'enableJobCodeFilterForEmpShifts',
			default: false,
			value: false,
			label: `Enable job code filter restriction for explicit employee shifts`,
			type: 'BOOLEAN',
			validator: null,
			category: 'internalOption',
			showGlobal: false,
			options: [],
			description: `When checked, if an employee is explicitly scheduled, they will be restricted to those shifts while they are in window. Any Employee shifts will not be available while an explicit shift is in window.`,
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},

		// General
		{
			name: 'enableWebApiCheckin',
			default: true,
			value: true,
			label: 'Enable web based check in',
			type: 'BOOLEAN',
			validator: null,
			category: 'generalOption,enablePicker',
			showGlobal: true,
			options: [],
			description: `Allow employees to check in using the mobile web app. When disabled, employees will only be able to check in by phone.`,
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'preventCheckInForGpsDenied',
			default: false,
			value: false,
			label: 'Prevent clock in for blocked GPS',
			type: 'BOOLEAN',
			validator: null,
			category: 'generalOption,enablePicker',
			showGlobal: true,
			options: [],
			description: `When checked, employees will not be able to check in if they are explicitly blocking GPS sharing on their phones.`,
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: () => {
				// Item hidden unless enableWebApiCheckin is enabled
				return !this.getPrefForName('enableWebApiCheckin').value
			},
			matchesSearchFilter: true,
		},

		{
			name: 'preventClockInForGpsRange',
			default: false,
			value: false,
			label: 'Prevent clock in based on employee distance from job site',
			type: 'BOOLEAN',
			validator: null,
			category: 'generalOption',
			showGlobal: true,
			options: [],
			description: `When checked, a value can be set to prevent employees from clocking in if they are too far from the job site. This feature only applies to jobs with an address or GPS marker set.<br><br><b style="color: firebrick">NOTE:</b> If an employee is blocking GPS (permissions issue) or the device cannot get a GPS signal (line of sight issue), they will not be able to clock in until their device provides valid GPS information.`,
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: () => {
				// Item hidden unless enableWebApiCheckin is enabled
				return !this.getPrefForName('enableWebApiCheckin').value
			},
			matchesSearchFilter: true,
		},

		{
			name: 'preventClockInForGpsDistance',
			default: null,
			value: null,
			label: 'Max distance from job site',
			type: 'INPUT',
			validator: { type: 'INTEGER', min: null, max: null },
			category: 'generalOption',
			showGlobal: false,
			options: [],
			description:
				'Enter the maximum distance in meters an employee can be from the job site in order to clock in. If the employee is not within this range, they will not be allowed to clock in.',
			enabledNote: null,
			placeholder: '',
			uiConfig: {
				inputLabel: 'Distance in meters',
				inputClass: 'prefs-input-number',
				inputType: 'tel',
			},
			empOverride: true,
			isChild: false,
			isHidden: () => {
				// Item hidden unless enableWebApiCheckin is enabled
				return !this.getPrefForName('enableWebApiCheckin').value || !this.getPrefForName('preventClockInForGpsRange').value
			},
			matchesSearchFilter: true,
		},

		// Job Site Picker
		{
			name: 'pickerOptionShowSelectBySite',
			default: true,
			value: true,
			label: 'Enable job site picker',
			type: 'BOOLEAN',
			validator: null,
			category: 'enablePicker,jobSitePicker',
			showGlobal: false,
			options: [],
			description: `Allow employees to check in by job site. This option filters the available options based on the employee's location when available.`,
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'jobSitePickerShowDefaultJob',
			default: true,
			value: true,
			label: 'Enable job site picker default job',
			type: 'BOOLEAN',
			validator: null,
			category: 'jobSitePicker',
			showGlobal: false,
			options: [],
			description: `Allow employees to check in to the default job when using the job site picker.`,
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'jobSitePickerFilterBySchedule',
			default: false,
			value: false,
			label: 'Filter job site picker by schedule',
			type: 'BOOLEAN',
			validator: null,
			category: 'availableFilters,jobSitePicker',
			showGlobal: false,
			options: [],
			description: `Filter available entries in the job site picker to display only scheduled jobs.`,
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},

		// Schedule Picker
		{
			name: 'pickerOptionShowSelectBySchedule',
			default: true,
			value: true,
			label: 'Enable schedule picker',
			type: 'BOOLEAN',
			validator: null,
			category: 'enablePicker,schedulePicker',
			showGlobal: false,
			options: [],
			description: `Allow employees to check in by selecting from a list of scheduled jobs.`,
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'schedulePickerShowDefaultJob',
			default: true,
			value: true,
			label: 'Enable schedule picker default job',
			type: 'BOOLEAN',
			validator: null,
			category: 'schedulePicker',
			showGlobal: false,
			options: [],
			description: `Allow employees to check in to the default job when using the schedule picker.`,
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},

		// Shift Details
		{
			name: 'checkinShowSiteDetails',
			default: false,
			value: false,
			label: 'Show job site details',
			type: 'BOOLEAN',
			validator: null,
			category: 'checkinView',
			showGlobal: false,
			options: [],
			description: `Show job site details from the job/site record to employees on the check in screen and schedule view.`,
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'checkinShowJobDetails',
			default: false,
			value: false,
			label: 'Show job details',
			type: 'BOOLEAN',
			validator: null,
			category: 'checkinView',
			showGlobal: false,
			options: [],
			description: `Show job details from the job record to employees on the check in screen and schedule view.`,
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'checkinShowSchedDetails',
			default: false,
			value: false,
			label: 'Show schedule details',
			type: 'BOOLEAN',
			validator: null,
			category: 'checkinView',
			showGlobal: false,
			options: [],
			description: `Show schedule details from the schedule record to employees on the check in screen and schedule view.`,
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},

		// Available Reports

		{
			name: 'enableReportBasicShift',
			default: true,
			value: true,
			label: 'Basic Shift Report',
			type: 'BOOLEAN',
			validator: null,
			category: 'availableReports',
			showGlobal: false,
			options: [],
			description: `Allow access to the basic shift report.`,
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'enableReportHomecare',
			default: true,
			value: true,
			label: 'Homecare Report',
			type: 'BOOLEAN',
			validator: null,
			category: 'availableReports',
			showGlobal: false,
			options: [],
			description: `Allow access to the homecare report. Will only be available if the company industry type is set to healtcare.`,
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'enableReportIncident',
			default: true,
			value: true,
			label: 'Incident Report',
			type: 'BOOLEAN',
			validator: null,
			category: 'availableReports',
			showGlobal: false,
			options: [],
			description: `Allow access to the incident report.`,
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'enableReportVehicleInspection',
			default: true,
			value: true,
			label: 'Vehicle Inspection Report',
			type: 'BOOLEAN',
			validator: null,
			category: 'availableReports',
			showGlobal: false,
			options: [],
			description: `Allow access to the vehicle inspection report.`,
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},

		// Misc Options

		/*
			  WARNING If pickerOptionDefaultPicker is altered - YOU MUST check constructor of emp-prefs.compoent and makeUpdateRecord
			  of company edit form to make sure it dones't break
		*/
		{
			name: 'pickerOptionDefaultPicker',
			default: 'SITE',
			value: 'SITE',
			label: 'Default job picker',
			type: 'SELECT',
			validator: null,
			category: 'enablePicker',
			showGlobal: true,
			options: [
				{ name: 'Job Code Picker', value: 'CODE' },
				{ name: 'Job Site Picker', value: 'SITE' },
				{ name: 'Schedule Picker', value: 'SCHEDULE' },
			],
			description:
				'This option determines which job picker is used by default when an employe clicks on the check in button. Make sure the picker you select here is also enabled.',
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		// SEE ABOVE NOTE - THIS ITEM HAS DEPENDENCIES IN OTHER COMPONENTS

		// Report Options
		{
			name: 'virInstructions',
			default: '',
			value: '',
			label: 'Vehicle Inspection Report Instructions',
			type: 'TEXTAREA',
			validator: null,
			category: 'vehicleInspectionReport',
			showGlobal: false,
			options: [],
			description: 'Enter instructions for use of this form',
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'virRequirePhoto',
			default: false,
			value: false,
			label: 'Report requires photos',
			type: 'BOOLEAN',
			validator: null,
			category: 'vehicleInspectionReport',
			showGlobal: false,
			options: [],
			description: `User is required to attach photos when submitting a vehicle inspection report`,
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'virCaptureOnly',
			default: false,
			value: false,
			label: 'No photos from gallery',
			type: 'BOOLEAN',
			validator: null,
			category: 'vehicleInspectionReport',
			showGlobal: false,
			options: [],
			description: `Select this to prevent photos from being selected from the photo gallery.`,
			enabledNote: null,
			placeholder: '',
			uiConfig: null,
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
		{
			name: 'virRequirePhotoCount',
			default: null,
			value: null,
			label: 'Required photo count',
			type: 'INPUT',
			validator: { type: 'INTEGER', min: null, max: null },
			category: 'vehicleInspectionReport',
			showGlobal: false,
			options: [],
			description: 'If photos are required, enter the minimum number of photos that must be included or leave blank for no limit.',
			enabledNote: null,
			placeholder: '',
			uiConfig: {
				inputLabel: 'Minimum photos required',
				inputClass: 'prefs-input-number',
				inputType: 'tel',
			},
			empOverride: true,
			isChild: false,
			isHidden: null,
			matchesSearchFilter: true,
		},
	]

	unknownPrefs = []

	static getCategories(): Array<IPrefsCategory> {
		return [
			{ value: 'generalOption', name: 'General', note: null, isExpanded: true },
			{ value: 'jobSitePicker', name: 'Job Picker', note: null, isExpanded: true },
			{ value: 'checkinView', name: 'Shift Details', note: null, isExpanded: true },
		]
	}

	setPrefs(prefs: EmpAppPrefs) {
		for (const attr in prefs) {
			if (this.getPrefForName(attr)) {
				const config = this.getPrefForName(attr)
				config.value = prefs[attr]
			} else {
				const unknownPref = { name: attr, value: prefs[attr] }
				this.unknownPrefs.push(unknownPref)
			}
		}
	}

	getPrefForName(name: string) {
		return this.prefs.find((item) => item.name === name)
	}

	setValueForKey(key: string, value: any) {
		const pref = this.getPrefForName(key)
		pref.value = value
	}

	getCompanyUpdateString(): string {
		const result = {}
		this.prefs.forEach((pref) => {
			if (pref.value !== pref.default) {
				result[pref.name] = pref.value
			}
		})
		return _.isEmpty(result) ? null : JSON.stringify(result)
	}

	getEmployeeUpdateString(companyAppPrefs: EmpAppPrefs): string {
		const companyResult = {}
		const result = {}
		this.prefs.forEach((pref) => {
			if (pref.empOverride && pref.value !== companyAppPrefs[pref.name]) {
				result[pref.name] = pref.value
			}
		})
		return _.isEmpty(result) ? null : JSON.stringify(result)
	}

	format(name: string, value: string): any {
		const config = this.getPrefForName(name)
		return config[value]
	}
}

// Local Prefs Management

// Type of each preference item e.g. BOOLEAN, NUMBER, STRING, TEXTAREA
export type LocalPrefsItemType = 'CHECKBOX' | 'INTEGER'

export class LocalPrefsItem {
	key: string
	type: LocalPrefsItemType
	label: string
	config: ILocalPrefsItemConfig
	defaultValue: any
	currentValue: any
	isVisible = true
	errorMsg = null

	constructor(key: string, config: ILocalPrefsItemConfig) {
		this.key = key
		this.type = config.type
		this.label = config.label
		this.config = config
	}
}

export interface ILocalPrefsItemConfig {
	type: LocalPrefsItemType
	label: string
	hint?: string
	helpTitle?: string
	help?: string
	minValue?: number
	maxValue?: number
	divider?: boolean
}

export class LocalPrefsGroup {
	label: string
	items: Array<LocalPrefsItem> = []
	isVisible = true
	hiddenPrefKeys: Array<string> = []
	constructor(label: string, items: Array<LocalPrefsItem>) {
		this.label = label
		this.items = items
	}
}

export class LocalPrefsDialogData {
	groups: Array<LocalPrefsGroup> = []
	localStorageKeyRemovalList: Array<string> = [] // Used to clear localstorage keys on save
	constructor(groups: Array<LocalPrefsGroup>) {
		this.groups = groups
	}
}

export class LocalPrefsData {
	///////////////
	// EMPLOYEES //
	///////////////

	empColNameVisible = true
	empColFirstNameVisible = false
	empColLastNameVisible = false
	empColTagsVisible = true
	empColScoreVisible = true
	empColDepartmentVisible = false
	empColDetailsVisible = true
	empColSupervisorVisible = true
	empColSupervisorGroupVisible = false
	empColManagerVisible = false
	empColJobSitesVisible = true
	empColMobileVisible = true
	empColEmailVisible = true
	empColDistrictVisible = true
	empColAddressVisible = true
	empColMapVisible = true
	empColDisableSmsVisible = true // Use GPS
	empColVoiceprintsVisible = false
	empColFloaterVisible = false // Check if still used
	// empColUnionVisible = false // Check if still used
	empColAdpCustom1Visible = false
	empColLanguageVisible = true
	empColStartDateVisible = true
	empColTaxLocationVisible = true
	empColPayRateVisible = true // Hidden if company.pay_rate is false
	empColCreatedVisible = false
	empColLastActiveVisible = true
	empColEmpStatusVisible = false
	empColProfileVisible = true
	empColFilesVisible = true

	static getEmpTableColumnDisplayPrefs(): Array<LocalPrefsItem> {
		const districtLabel = Global.coreSrvc.dbSrvc.settingSrvc.getDistrictLabel()
		return [
			new LocalPrefsItem('empColFirstNameVisible', { type: 'CHECKBOX', label: 'First Name' }),
			new LocalPrefsItem('empColLastNameVisible', { type: 'CHECKBOX', label: 'Last Name' }),
			new LocalPrefsItem('empColTagsVisible', { type: 'CHECKBOX', label: 'Tags' }),
			new LocalPrefsItem('empColScoreVisible', { type: 'CHECKBOX', label: 'Score' }),
			new LocalPrefsItem('empColDepartmentVisible', { type: 'CHECKBOX', label: 'Department' }),
			new LocalPrefsItem('empColDetailsVisible', { type: 'CHECKBOX', label: 'Details' }),
			new LocalPrefsItem('empColSupervisorVisible', { type: 'CHECKBOX', label: 'Supervisor' }),
			new LocalPrefsItem('empColSupervisorGroupVisible', { type: 'CHECKBOX', label: 'Group' }),
			new LocalPrefsItem('empColManagerVisible', { type: 'CHECKBOX', label: 'Manager' }),
			new LocalPrefsItem('empColJobSitesVisible', { type: 'CHECKBOX', label: 'Linked (Job Sites)' }),
			new LocalPrefsItem('empColMobileVisible', { type: 'CHECKBOX', label: 'Mobile (Phone)' }),
			new LocalPrefsItem('empColEmailVisible', { type: 'CHECKBOX', label: 'Email' }),
			new LocalPrefsItem('empColDistrictVisible', { type: 'CHECKBOX', label: districtLabel }),
			new LocalPrefsItem('empColAddressVisible', { type: 'CHECKBOX', label: 'Address' }),
			new LocalPrefsItem('empColMapVisible', { type: 'CHECKBOX', label: 'Map' }),
			new LocalPrefsItem('empColDisableSmsVisible', {
				type: 'CHECKBOX',
				label: 'Use GPS',
				help: LocalPrefsHelp.empColDisableSmsVisible,
			}),
			new LocalPrefsItem('empColVoiceprintsVisible', { type: 'CHECKBOX', label: 'Voiceprints' }),
			// new LocalPrefsItem('empColUnionVisible', { type: 'CHECKBOX', label: 'Union' }),
			new LocalPrefsItem('empColAdpCustom1Visible', { type: 'CHECKBOX', label: 'ADP (1)', help: LocalPrefsHelp.empColAdpCustom1Visible }),
			new LocalPrefsItem('empColLanguageVisible', { type: 'CHECKBOX', label: 'Language' }),
			new LocalPrefsItem('empColStartDateVisible', { type: 'CHECKBOX', label: 'Start Date' }),
			new LocalPrefsItem('empColTaxLocationVisible', { type: 'CHECKBOX', label: 'Tax Location' }),
			new LocalPrefsItem('empColPayRateVisible', { type: 'CHECKBOX', label: 'Pay Rates' }),
			new LocalPrefsItem('empColCreatedVisible', { type: 'CHECKBOX', label: 'Created' }),
			new LocalPrefsItem('empColLastActiveVisible', { type: 'CHECKBOX', label: 'Last Active' }),
			new LocalPrefsItem('empColEmpStatusVisible', { type: 'CHECKBOX', label: 'Emp Status' }),
			new LocalPrefsItem('empColProfileVisible', { type: 'CHECKBOX', label: 'Profile' }),
			new LocalPrefsItem('empColFilesVisible', { type: 'CHECKBOX', label: 'Files' }),
		]
	}

	empTableIncludeInlinePfp = false

	static getEmpTableMiscellaneousPrefs(): Array<LocalPrefsItem> {
		return [
			new LocalPrefsItem('empTableIncludeInlinePfp', {
				type: 'CHECKBOX',
				label: 'Show profile picture in list',
				helpTitle: 'Show Photo',
				help: 'When selected, employee profile photos will be listed in the Display Name column.',
			}),
		]
	}

	/////////////////////////
	// SUPERVISORS / USERS //
	/////////////////////////

	supUsersColManagerVisible = true
	supUsersColSkillsPermVisible = false
	supUsersColCompaniesVisible = true
	supUsersColEmployeesVisible = true
	supUsersColJobSitesVisible = true
	supUsersColJobsVisible = true
	supUsersColProfilesVisible = true
	supUsersColGroupsVisible = false
	supUsersColRolePermVisible = false
	supUsersColPhoneVisible = true
	supUsersColEmailVisible = true
	supUsersColDispatchnVisible = true
	supUsersColAdminVisible = true
	supUserColNotifyVisible = true
	supUsersColLastLoginVisible = true
	supUsersColLastActiveVisible = true

	static getSupUserTableColumnDisplayPrefs(): Array<LocalPrefsItem> {
		return [
			new LocalPrefsItem('supUsersColManagerVisible', { type: 'CHECKBOX', label: 'Manager' }),
			new LocalPrefsItem('supUsersColSkillsPermVisible', { type: 'CHECKBOX', label: 'Skills' }),
			new LocalPrefsItem('supUsersColCompaniesVisible', { type: 'CHECKBOX', label: 'Companies' }),
			new LocalPrefsItem('supUsersColEmployeesVisible', { type: 'CHECKBOX', label: 'Employees' }),
			// new LocalPrefsItem('supUsersColJobSitesVisible', { type: 'CHECKBOX', label: 'Job Sites' }),
			new LocalPrefsItem('supUsersColJobsVisible', { type: 'CHECKBOX', label: 'Jobs' }),
			new LocalPrefsItem('supUsersColProfilesVisible', { type: 'CHECKBOX', label: 'Profiles' }),
			new LocalPrefsItem('supUsersColGroupsVisible', { type: 'CHECKBOX', label: 'Groups' }),
			new LocalPrefsItem('supUsersColRolePermVisible', { type: 'CHECKBOX', label: 'Role' }),
			new LocalPrefsItem('supUsersColPhoneVisible', { type: 'CHECKBOX', label: 'Phone' }),
			new LocalPrefsItem('supUsersColEmailVisible', { type: 'CHECKBOX', label: 'Email' }),
			new LocalPrefsItem('supUsersColAdminVisible', { type: 'CHECKBOX', label: 'Admin' }),
			new LocalPrefsItem('supUserColNotifyVisible', { type: 'CHECKBOX', label: 'Notify' }),
			new LocalPrefsItem('supUsersColLastLoginVisible', { type: 'CHECKBOX', label: 'Last Login' }),
			new LocalPrefsItem('supUsersColLastActiveVisible', { type: 'CHECKBOX', label: 'Last Active' }),
		]
	}

	//////////////////////////
	// SUPERVISORS / GROUPS //
	//////////////////////////

	supGroupsColDetailsVisible = true
	supGroupsColSupervisorsVisible = true
	supGroupsColEmployeesVisible = true
	supGroupsColJobSitesVisible = true
	supGroupsColJobsVisible = true

	static getSupGroupTableColumnDisplayPrefs(): Array<LocalPrefsItem> {
		return [
			new LocalPrefsItem('supGroupsColDetailsVisible', { type: 'CHECKBOX', label: 'Details' }),
			new LocalPrefsItem('supGroupsColSupervisorsVisible', { type: 'CHECKBOX', label: 'Linked Supervisors' }),
			new LocalPrefsItem('supGroupsColEmployeesVisible', { type: 'CHECKBOX', label: 'Linked Employees' }),
			new LocalPrefsItem('supGroupsColJobSitesVisible', { type: 'CHECKBOX', label: 'Linked Job Sites' }),
			new LocalPrefsItem('supGroupsColJobsVisible', { type: 'CHECKBOX', label: 'Linked Jobs' }),
		]
	}

	////////////////////////////
	// CONTACTS / INDIVIDUALS //
	////////////////////////////

	contactColLinkedOrgsVisible = true
	contactColPhoneVisible = true
	contactColEmailVisible = true
	contactColAddressVisible = true
	contactColMapVisible = true
	contactColNotifyPhoneVisible = true
	contactColNotifyTextVisible = true
	contactColNotifyEmaiVisible = true
	contactColNotesVisible = true
	contactColFilesVisible = true

	static getContactTableColumnDisplayPrefs(): Array<LocalPrefsItem> {
		return [
			new LocalPrefsItem('contactColLinkedOrgsVisible', { type: 'CHECKBOX', label: 'Client / Vendor' }),
			new LocalPrefsItem('contactColAddressVisible', { type: 'CHECKBOX', label: 'Address' }),
			new LocalPrefsItem('contactColMapVisible', { type: 'CHECKBOX', label: 'Map' }),
			new LocalPrefsItem('contactColPhoneVisible', { type: 'CHECKBOX', label: 'Phone' }),
			new LocalPrefsItem('contactColEmailVisible', { type: 'CHECKBOX', label: 'Email' }),
			new LocalPrefsItem('contactColNotifyPhoneVisible', { type: 'CHECKBOX', label: 'Notify Phone' }),
			new LocalPrefsItem('contactColNotifyTextVisible', { type: 'CHECKBOX', label: 'Notify Text' }),
			new LocalPrefsItem('contactColNotifyEmaiVisible', { type: 'CHECKBOX', label: 'Notify Email' }),
			new LocalPrefsItem('contactColNotesVisible', { type: 'CHECKBOX', label: 'Notes' }),
			new LocalPrefsItem('contactColFilesVisible', { type: 'CHECKBOX', label: 'Files' }),
		]
	}

	//////////////////////////////
	// CONTACTS / ORGANIZATIONS //
	//////////////////////////////

	orgColLinkedJobsVisible = true
	orgColLinkedContactsVisible = true
	orgColAddressVisible = true
	orgColMapVisible = true
	orgColPhoneVisible = true
	orgColEmailVisible = true
	orgColNotifyPhoneVisible = true
	orgColNotifyTextVisible = true
	orgColNotifyEmaiVisible = true
	orgColNotesVisible = true
	orgColPayRateVisible = true
	orgColFilesVisible = true

	static getOrgTableColumnDisplayPrefs(): Array<LocalPrefsItem> {
		return [
			new LocalPrefsItem('orgColLinkedJobsVisible', { type: 'CHECKBOX', label: 'Jobs' }),
			new LocalPrefsItem('orgColLinkedContactsVisible', { type: 'CHECKBOX', label: 'Contacts' }),
			new LocalPrefsItem('orgColAddressVisible', { type: 'CHECKBOX', label: 'Address' }),
			new LocalPrefsItem('orgColMapVisible', { type: 'CHECKBOX', label: 'Map' }),
			new LocalPrefsItem('orgColPhoneVisible', { type: 'CHECKBOX', label: 'Phone' }),
			new LocalPrefsItem('orgColEmailVisible', { type: 'CHECKBOX', label: 'Email' }),
			new LocalPrefsItem('orgColNotifyPhoneVisible', { type: 'CHECKBOX', label: 'Notify Phone' }),
			new LocalPrefsItem('orgColNotifyTextVisible', { type: 'CHECKBOX', label: 'Notify Text' }),
			new LocalPrefsItem('orgColNotifyEmaiVisible', { type: 'CHECKBOX', label: 'Notify Email' }),
			new LocalPrefsItem('orgColNotesVisible', { type: 'CHECKBOX', label: 'Notes' }),
			new LocalPrefsItem('orgColPayRateVisible', { type: 'CHECKBOX', label: 'Bill / Pay Rate' }),
			new LocalPrefsItem('orgColFilesVisible', { type: 'CHECKBOX', label: 'Files' }),
		]
	}

	///////////////
	// JOB SITES //
	///////////////

	jobSiteColDescriptionVisible = true
	jobSiteColSupervisorVisible = true
	jobSiteColSupervisorGroupVisible = false
	jobSiteColManagerVisible = false // Hidden if manager role not available
	jobSiteColClientVisible = false // Hidden if no clients have been entered
	jobSiteColDetailsVisible = true
	jobSiteColNumberVisible = true
	jobSiteColDistrictVisible = true
	jobSiteColAddressVisible = true
	jobSiteColMapVisible = true
	jobSiteColTimezoneVisible = true
	jobSiteColSyncLockVisible = true

	static getJobSiteTableColumnDisplayPrefs(): Array<LocalPrefsItem> {
		const districtLabel = Global.coreSrvc.dbSrvc.settingSrvc.getDistrictLabel()
		return [
			new LocalPrefsItem('jobSiteColSupervisorVisible', { type: 'CHECKBOX', label: 'Supervisor' }),
			new LocalPrefsItem('jobSiteColSupervisorGroupVisible', { type: 'CHECKBOX', label: 'Group' }),
			new LocalPrefsItem('jobSiteColManagerVisible', { type: 'CHECKBOX', label: 'Manager' }),
			new LocalPrefsItem('jobSiteColClientVisible', { type: 'CHECKBOX', label: 'Client' }),
			new LocalPrefsItem('jobSiteColDetailsVisible', { type: 'CHECKBOX', label: 'Details' }),
			new LocalPrefsItem('jobSiteColNumberVisible', { type: 'CHECKBOX', label: 'Linked Numbers' }),
			new LocalPrefsItem('jobSiteColDistrictVisible', { type: 'CHECKBOX', label: districtLabel }),
			new LocalPrefsItem('jobSiteColAddressVisible', { type: 'CHECKBOX', label: 'Address' }),
			new LocalPrefsItem('jobSiteColMapVisible', { type: 'CHECKBOX', label: 'Map' }),
			new LocalPrefsItem('jobSiteColTimezoneVisible', { type: 'CHECKBOX', label: 'Timezone' }),
			new LocalPrefsItem('jobSiteColSyncLockVisible', { type: 'CHECKBOX', label: 'Lock', help: LocalPrefsHelp.jobOrSiteColSyncLockVisible }),
		]
	}

	//////////
	// JOBS //
	//////////

	jobColDescriptionVisible = true
	jobColDetailsVisible = true
	jobColJobCodeVisible = true
	jobColQRCodeVisible = true
	jobColMultiDayVisible = true
	jobColTagsVisible = true
	jobColSiteDetailsVisible = true // Hidden if not merged job sites
	jobColJobSiteVisible = true // Hidden for merged job sites
	jobColSupervisorVisible = true
	jobColSupervisorGroupVisible = false
	jobColManagerVisible = false // Hidden if manager role not available
	jobColBreakVisible = true
	jobColProfileNameVisible = true
	jobColTourVisible = true
	jobColScheduledVisible = true
	jobColADPVisible = true // Available when ADP integrated
	jobColQBOVisible = true // Available when QB integrated
	jobColSiteLinkedNumbersVisible = true // Hidden if not merged job sites
	jobColSiteDistrictVisible = true // Hidden if not merged job sites
	jobColSiteAddressVisible = true // Hidden if not merged job sites
	jobColSiteMapVisible = true // Hidden if not merged job sites
	jobColSiteTimezoneVisible = true // Hidden if not merged job sites
	jobColEmployeeVisible = false // Available always
	jobColClientsVisible = false // Available when clients exist
	jobColVendorsVisible = false // Available when vendors exist
	jobColTaxLocationVisible = true
	jobColSyncLockVisible = true

	static getJobTableColumnDisplayPrefs(): Array<LocalPrefsItem> {
		const districtLabel = Global.coreSrvc.dbSrvc.settingSrvc.getDistrictLabel()
		return [
			new LocalPrefsItem('jobColTagsVisible', { type: 'CHECKBOX', label: 'Tags' }),
			new LocalPrefsItem('jobColJobCodeVisible', { type: 'CHECKBOX', label: 'Job Code' }),
			new LocalPrefsItem('jobColQRCodeVisible', { type: 'CHECKBOX', label: 'QR Code' }),
			new LocalPrefsItem('jobColMultiDayVisible', { type: 'CHECKBOX', label: 'Multi Day' }),
			new LocalPrefsItem('jobColDetailsVisible', { type: 'CHECKBOX', label: 'Job Details' }),
			new LocalPrefsItem('jobColSiteDetailsVisible', { type: 'CHECKBOX', label: 'Site Details' }),
			new LocalPrefsItem('jobColJobSiteVisible', { type: 'CHECKBOX', label: 'Job Site' }),
			new LocalPrefsItem('jobColSupervisorVisible', { type: 'CHECKBOX', label: 'Supervisor' }),
			new LocalPrefsItem('jobColSupervisorGroupVisible', { type: 'CHECKBOX', label: 'Group' }),
			new LocalPrefsItem('jobColManagerVisible', { type: 'CHECKBOX', label: 'Manager' }),
			new LocalPrefsItem('jobColBreakVisible', { type: 'CHECKBOX', label: 'Breaks' }),
			new LocalPrefsItem('jobColProfileNameVisible', { type: 'CHECKBOX', label: 'Notification Profile' }),
			new LocalPrefsItem('jobColTourVisible', { type: 'CHECKBOX', label: 'Tour' }),
			new LocalPrefsItem('jobColScheduledVisible', { type: 'CHECKBOX', label: 'Scheduled' }),
			new LocalPrefsItem('jobColADPVisible', { type: 'CHECKBOX', label: 'ADP' }),
			new LocalPrefsItem('jobColQBOVisible', { type: 'CHECKBOX', label: 'QB' }),
			new LocalPrefsItem('jobColSiteLinkedNumbersVisible', { type: 'CHECKBOX', label: 'Linked (Phone Numbers)' }),
			new LocalPrefsItem('jobColSiteDistrictVisible', { type: 'CHECKBOX', label: districtLabel }),
			new LocalPrefsItem('jobColSiteAddressVisible', { type: 'CHECKBOX', label: 'Address' }),
			new LocalPrefsItem('jobColSiteMapVisible', { type: 'CHECKBOX', label: 'Map' }),
			new LocalPrefsItem('jobColSiteTimezoneVisible', { type: 'CHECKBOX', label: 'Timezone' }),
			new LocalPrefsItem('jobColEmployeeVisible', { type: 'CHECKBOX', label: 'Employee' }),
			new LocalPrefsItem('jobColClientsVisible', { type: 'CHECKBOX', label: 'Client' }),
			new LocalPrefsItem('jobColVendorsVisible', { type: 'CHECKBOX', label: 'Vendor' }),
			new LocalPrefsItem('jobColTaxLocationVisible', { type: 'CHECKBOX', label: 'Tax Location' }),
			new LocalPrefsItem('jobColSyncLockVisible', { type: 'CHECKBOX', label: 'Lock', help: LocalPrefsHelp.jobOrSiteColSyncLockVisible }),
		]
	}

	jobTableShowSystemJobs = false

	static getJobTableMiscellaneousPrefs(): Array<LocalPrefsItem> {
		return [
			new LocalPrefsItem('jobTableShowSystemJobs', {
				type: 'CHECKBOX',
				label: 'Show system jobs in jobs list',
				helpTitle: 'System Jobs',
				help: 'When selected, you will see system jobs in the jobs list along with your custom jobs.',
			}),
		]
	}

	///////////
	// TOURS //
	///////////

	tourColLinkedJobsVisible = true
	tourColTourTypeVisible = true
	tourColChkptsVisible = true
	tourColRepeatingVisible = true

	static getTourTableColumnDisplayPrefs(): Array<LocalPrefsItem> {
		return [
			new LocalPrefsItem('tourColLinkedJobsVisible', { type: 'CHECKBOX', label: 'Linked Jobs' }),
			new LocalPrefsItem('tourColTourTypeVisible', { type: 'CHECKBOX', label: 'Type' }),
			new LocalPrefsItem('tourColChkptsVisible', { type: 'CHECKBOX', label: 'Chkpts' }),
			new LocalPrefsItem('tourColRepeatingVisible', { type: 'CHECKBOX', label: 'Repeating' }),
		]
	}

	////////////////////
	// NOTIFICATIONS //
	////////////////////

	npColLinkedJobsVisible = true
	npColLinkedNumbersVisible = true
	npColLinkedEmailsVisible = true
	npColEmpNotificationsVisible = true
	npColSupNotificationsVisible = true

	static getNpTableColumnDisplayPrefs(): Array<LocalPrefsItem> {
		return [
			new LocalPrefsItem('npColLinkedJobsVisible', { type: 'CHECKBOX', label: 'Linked Jobs' }),
			new LocalPrefsItem('npColLinkedNumbersVisible', { type: 'CHECKBOX', label: 'Linked Numbers' }),
			new LocalPrefsItem('npColLinkedEmailsVisible', { type: 'CHECKBOX', label: 'Linked Emails' }),
			new LocalPrefsItem('npColEmpNotificationsVisible', { type: 'CHECKBOX', label: 'Employee Notifications' }),
			new LocalPrefsItem('npColSupNotificationsVisible', { type: 'CHECKBOX', label: 'Supervisor Notifications' }),
		]
	}

	///////////////
	// SCHEDULES //
	///////////////

	schedDefaultListView = 'EMP'
	schedDefaultShiftView = null

	/////////////////////////
	// TIME-OFF / VACATION //
	/////////////////////////

	vacColEmpStatusVisible = true
	vacColReqStatusVisible = true
	vacColTypeVisible = true
	vacColHoursVisible = true
	vacColStartVisible = true
	vacColEndVisible = true
	vacColNotesVisible = true
	vacColManagedByVisible = true
	vacColLinkedJobVisible = true

	static getVacationTableDisplayPrefs(): Array<LocalPrefsItem> {
		return [
			new LocalPrefsItem('vacColEmpStatusVisible', { type: 'CHECKBOX', label: 'Employee Status' }),
			new LocalPrefsItem('vacColReqStatusVisible', { type: 'CHECKBOX', label: 'Request Status' }),
			new LocalPrefsItem('vacColTypeVisible', { type: 'CHECKBOX', label: 'Type' }),
			new LocalPrefsItem('vacColHoursVisible', { type: 'CHECKBOX', label: 'Hours' }),
			new LocalPrefsItem('vacColStartVisible', { type: 'CHECKBOX', label: 'Start' }),
			new LocalPrefsItem('vacColEndVisible', { type: 'CHECKBOX', label: 'End' }),
			new LocalPrefsItem('vacColNotesVisible', { type: 'CHECKBOX', label: 'Notes' }),
			new LocalPrefsItem('vacColManagedByVisible', { type: 'CHECKBOX', label: 'Managed By / Modified By' }),
			new LocalPrefsItem('vacColLinkedJobVisible', { type: 'CHECKBOX', label: 'Linked Jobs' }),
		]
	}

	/////////////////////////////////
	// TIME ENTRIES (TRANSACTIONS) //
	/////////////////////////////////

	transColEmpVisible = true // Deprecated with new prefs dialog

	transColEmpStatusVisible = true
	transColExtIdVisible = true
	transColJobVisible = true
	transColTagsVisible = false
	transColJobDateVisible = true
	transColActualShiftVisible = true
	transColBreaksVisible = true
	transColHoursVisible = true
	transColOverageVisible = true
	transColLateCallsVisible = true
	transColVoiceprintsVisible = false
	transColGpsDetailsVisible = true
	transColChkptsReportsVisible = true
	transColEmpSupVisible = true
	transColJobSiteSupVisible = true
	transColNotesVisible = true
	transColAdpCustom1Visible = false
	transColClientVisible = true
	transColVendorVisible = true
	transColJobAddressVisible = false
	transColLockVisible = false

	transColApproxStartAddrVisible = true // Deprecated
	transColApproxEndAddrVisible = true // Deprecated

	static getTransTableColumnDisplayPrefs(): Array<LocalPrefsItem> {
		return [
			new LocalPrefsItem('transColEmpStatusVisible', { type: 'CHECKBOX', label: 'Status' }),
			new LocalPrefsItem('transColExtIdVisible', { type: 'CHECKBOX', label: 'IDs' }),
			new LocalPrefsItem('transColJobVisible', { type: 'CHECKBOX', label: 'Job' }),
			new LocalPrefsItem('transColTagsVisible', { type: 'CHECKBOX', label: 'Tags' }),
			new LocalPrefsItem('transColJobDateVisible', { type: 'CHECKBOX', label: 'Date' }),
			new LocalPrefsItem('transColActualShiftVisible', { type: 'CHECKBOX', label: 'In - Out' }),
			new LocalPrefsItem('transColBreaksVisible', { type: 'CHECKBOX', label: 'Breaks' }),
			new LocalPrefsItem('transColHoursVisible', { type: 'CHECKBOX', label: 'Hours' }),
			new LocalPrefsItem('transColOverageVisible', { type: 'CHECKBOX', label: 'Overage' }),
			new LocalPrefsItem('transColLateCallsVisible', { type: 'CHECKBOX', label: 'Notified' }),
			new LocalPrefsItem('transColVoiceprintsVisible', { type: 'CHECKBOX', label: 'Voice' }),
			new LocalPrefsItem('transColGpsDetailsVisible', { type: 'CHECKBOX', label: 'Details' }),
			new LocalPrefsItem('transColChkptsReportsVisible', { type: 'CHECKBOX', label: 'Chkpts / Reports' }),
			new LocalPrefsItem('transColEmpSupVisible', { type: 'CHECKBOX', label: 'Emp Sup' }),
			new LocalPrefsItem('transColJobSiteSupVisible', { type: 'CHECKBOX', label: 'Job Sup' }),
			new LocalPrefsItem('transColNotesVisible', { type: 'CHECKBOX', label: 'Admin Notes' }),
			new LocalPrefsItem('transColAdpCustom1Visible', { type: 'CHECKBOX', label: 'ADP (1)' }),
			new LocalPrefsItem('transColClientVisible', { type: 'CHECKBOX', label: 'Client' }),
			new LocalPrefsItem('transColVendorVisible', { type: 'CHECKBOX', label: 'Vendor' }),
			new LocalPrefsItem('transColJobAddressVisible', { type: 'CHECKBOX', label: 'Job Address' }),
			new LocalPrefsItem('transColLockVisible', { type: 'CHECKBOX', label: 'Lock' }),
		]
	}

	//////////////////////////////////
	// TIME ENTRIES / MISCELLANEOUS //
	//////////////////////////////////

	transFetchCount = 500 // Number of entries to fetch from server
	transTablePageLength = 50 // Number of entries to display per page
	transTableAutoUpdate = 5 // Time in minutes for autorefresh inteval
	transAutoComputeHoursWorked = true
	transDefaultToTodayView = false
	transTableEnableSmartFilter = true
	transMapEnableClustering = false
	transMapFlagGpsIssues = true

	static getTransTableMiscellaneousPrefs(): Array<LocalPrefsItem> {
		return [
			new LocalPrefsItem('transTableEnableSmartFilter', {
				type: 'CHECKBOX',
				label: 'Enable smart search filtering',
				helpTitle: 'Smart Filter',
				help: 'When selected, you can use more complex search options when filtering and search across multiple columns at once. You must reload your browser for this setting to take effect.',
			}),
			new LocalPrefsItem('transMapEnableClustering', {
				type: 'CHECKBOX',
				label: 'Enable Map Clustering in Live View',
				helpTitle: 'Map Clustering',
				help: 'When selected, Ongoing shift markers in the Live View map will be grouped together to reduce clutter. No Shows are always shown as separate markers.',
			}),
			new LocalPrefsItem('transMapFlagGpsIssues', {
				type: 'CHECKBOX',
				label: 'Flag GPS Issues in Live View',
				helpTitle: 'GPS Issues',
				help: 'When selected, shift markers with missing GPS information will be flagged as having an issue in the Live View map.',
			}),
			new LocalPrefsItem('transFetchCount', {
				type: 'INTEGER',
				label: 'Fetch Count',
				help: 'Set the number of time entries to fetch for display. A lower number will increase performance, a higher number lets you see further back in time without switching to day view. Valid range is 500 to 5000. You must reload your browser for this setting to take effect.',
				divider: true,
				minValue: 500,
				maxValue: 3000,
				hint: 'Valid range: 500-3000',
			}),
			new LocalPrefsItem('transTablePageLength', {
				type: 'INTEGER',
				label: 'Page Length',
				help: 'Set the number of time entries to display per page. When the number of entries exceeds this value, you can use the page controls at the bottom of the table to view the next set of time entries. A lower number will increase performance, a higher number lets you see more entries at once. Valid range is 50 to 300. You must reload your browser for this setting to take effect.',
				minValue: 25,
				maxValue: 100,
				hint: 'Valid range: 25-100',
			}),
			new LocalPrefsItem('transTableAutoUpdate', {
				type: 'INTEGER',
				label: 'Auto Refresh',
				help: 'Determines how often (in minutes) the time entries screen will refresh. Valid range is 1 to 60. Enter 0 to disable auto refresh. You must reload your browser for this setting to take effect.',
				minValue: 0,
				maxValue: 60,
				hint: 'Valid range: 0-60 (0 to disable)',
			}),
		]
	}

	////////////////////////////
	// EMPLOYEE SHIFT REPORTS //
	////////////////////////////

	incidentLogEmployeePrefVisible = true
	incidentLogRepTypePrefVisible = true
	incidentLogJobPrefVisible = true
	incidentLogPinnedPrefVisible = true
	incidentLogAdminNotesPrefVisible = true
	incidentLogSummaryPrefVisible = true
	incidentLogIdentifierPrefVisible = true
	incidentLogTranscriptPrefVisible = true

	static getIncidentLogColumnDisplayPrefs(): Array<LocalPrefsItem> {
		return [
			new LocalPrefsItem('incidentLogEmployeePrefVisible', { type: 'CHECKBOX', label: 'Employee' }),
			new LocalPrefsItem('incidentLogRepTypePrefVisible', { type: 'CHECKBOX', label: 'Type' }),
			new LocalPrefsItem('incidentLogJobPrefVisible', { type: 'CHECKBOX', label: 'Job' }),
			new LocalPrefsItem('incidentLogPinnedPrefVisible', { type: 'CHECKBOX', label: 'Pinned' }),
			new LocalPrefsItem('incidentLogAdminNotesPrefVisible', { type: 'CHECKBOX', label: 'Admin Notes' }),
			new LocalPrefsItem('incidentLogSummaryPrefVisible', { type: 'CHECKBOX', label: 'Summary' }),
			new LocalPrefsItem('incidentLogIdentifierPrefVisible', { type: 'CHECKBOX', label: 'Identifier' }),
			new LocalPrefsItem('incidentLogTranscriptPrefVisible', { type: 'CHECKBOX', label: 'Transcript' }),
		]
	}

	///////////////////////////////////
	// INSIGHT REPORTS > DEFINITIONS //
	///////////////////////////////////

	repColNameVisible = true
	repColTypeVisible = true
	repColFrequencyVisible = true
	repColFiltersVisible = true
	repColLastRunVisible = true
	repColNextRunVisible = true
	repColNotifySmsVisible = true
	repColNotifyEmailVisible = true
	repColOwnerVisible = true

	static getReportTableColumnDisplayPrefs(): Array<LocalPrefsItem> {
		return [
			// new LocalPrefsItem('repColNameVisible', { type: 'CHECKBOX', label: 'Name' }),
			new LocalPrefsItem('repColTypeVisible', { type: 'CHECKBOX', label: 'Type' }),
			new LocalPrefsItem('repColFrequencyVisible', { type: 'CHECKBOX', label: 'Frequency' }),
			new LocalPrefsItem('repColFiltersVisible', { type: 'CHECKBOX', label: 'Filters' }),
			new LocalPrefsItem('repColLastRunVisible', { type: 'CHECKBOX', label: 'Last Run' }),
			new LocalPrefsItem('repColNextRunVisible', { type: 'CHECKBOX', label: 'Next Run' }),
			new LocalPrefsItem('repColNotifySmsVisible', { type: 'CHECKBOX', label: 'Notify Text' }),
			new LocalPrefsItem('repColNotifyEmailVisible', { type: 'CHECKBOX', label: 'Notify Email' }),
			new LocalPrefsItem('repColOwnerVisible', { type: 'CHECKBOX', label: 'Report Owner' }),
		]
	}

	///////////////////////////////
	// INSIGHT REPORTS > HISTORY //
	///////////////////////////////

	repHisColNameVisible = true
	repHisColTypeVisible = true
	repHisColFilterVisible = true
	repHisColStartVisible = true
	repHisColEndVisible = true
	repHisColCompletedVisible = true
	repHisColDataVisible = true

	static getReportHistoryTableColumnDisplayPrefs(): Array<LocalPrefsItem> {
		return [
			// new LocalPrefsItem('repHisColNameVisible', { type: 'CHECKBOX', label: 'Name' }),
			new LocalPrefsItem('repHisColTypeVisible', { type: 'CHECKBOX', label: 'Type' }),
			new LocalPrefsItem('repHisColFilterVisible', { type: 'CHECKBOX', label: 'Filter' }),
			new LocalPrefsItem('repHisColStartVisible', { type: 'CHECKBOX', label: 'Start' }),
			new LocalPrefsItem('repHisColEndVisible', { type: 'CHECKBOX', label: 'End' }),
			new LocalPrefsItem('repHisColCompletedVisible', { type: 'CHECKBOX', label: 'Completed' }),
			new LocalPrefsItem('repHisColDataVisible', { type: 'CHECKBOX', label: 'Data', help: LocalPrefsHelp.repHisColDataVisible }),
		]
	}

	// Reports Miscellaneous
	repShowAllReports = false // Used to manage reports toggle - should be moved to a view manager

	////////////////////////////
	// Comm Log > Calls/Texts //
	////////////////////////////

	commLogCallTextColInOutVisible = true
	commLogCallTextColTypeVisible = true
	commLogCallTextColFromVisible = true
	commLogCallTextColToVisible = true
	commLogCallTextColMessageVisible = true
	commLogCallTextColDetailsVisible = true
	commLogCallTextColCompanyVisible = true
	commLogCallTextColStatusVisible = true

	static getCommLogCallTextColumnDisplayPrefs(): Array<LocalPrefsItem> {
		return [
			new LocalPrefsItem('commLogCallTextColInOutVisible', { type: 'CHECKBOX', label: 'In / Out' }),
			new LocalPrefsItem('commLogCallTextColTypeVisible', { type: 'CHECKBOX', label: 'Type' }),
			new LocalPrefsItem('commLogCallTextColFromVisible', { type: 'CHECKBOX', label: 'From' }),
			new LocalPrefsItem('commLogCallTextColToVisible', { type: 'CHECKBOX', label: 'To' }),
			new LocalPrefsItem('commLogCallTextColMessageVisible', { type: 'CHECKBOX', label: 'Message' }),
			new LocalPrefsItem('commLogCallTextColDetailsVisible', { type: 'CHECKBOX', label: 'Details' }),
			new LocalPrefsItem('commLogCallTextColCompanyVisible', { type: 'CHECKBOX', label: 'Company' }),
			new LocalPrefsItem('commLogCallTextColStatusVisible', { type: 'CHECKBOX', label: 'Status' }),
		]
	}

	//////////////////////
	// ADMIN > STATIONS //
	//////////////////////

	stationColSetupCodeVisible = true
	stationColLinkedEmployeesVisible = true
	stationColLinkedJobsVisible = true
	stationColNotesVisible = true
	stationColStationModeVisible = true
	stationColIpLockVisible = true

	static getStationColumnDisplayPrefs(): Array<LocalPrefsItem> {
		return [
			new LocalPrefsItem('stationColSetupCodeVisible', { type: 'CHECKBOX', label: 'Setup Code' }),
			new LocalPrefsItem('stationColLinkedEmployeesVisible', { type: 'CHECKBOX', label: 'Linked Employees' }),
			new LocalPrefsItem('stationColLinkedJobsVisible', { type: 'CHECKBOX', label: 'Linked Jobs' }),
			new LocalPrefsItem('stationColNotesVisible', { type: 'CHECKBOX', label: 'Notes' }),
			new LocalPrefsItem('stationColStationModeVisible', { type: 'CHECKBOX', label: 'Mode' }),
			new LocalPrefsItem('stationColIpLockVisible', { type: 'CHECKBOX', label: 'IP Lock' }),
		]
	}

	////////////////////////
	// GLOBAL PREFERENCES //
	////////////////////////

	autoLogoutTimer = 1800 // 60 seconds * 30 minutes =  1 hour
	enableAutoLogout = true
	defaultLoginRegion = 'US'
	defaultOpenTransactionMapRange = 24
}

enum LocalPrefsHelp {
	empColAdpCustom1Visible = 'Manages the first custom field from ADP employee records as set through ADP',
	empColDisableSmsVisible = 'When employee calls in from landline or mobile, we can send a text requesting GPS information. This column manages that option.',
	repHisColDataVisible = 'This column indicates whether a report contains data or is empty and allows sorting on this column.',
	jobOrSiteColSyncLockVisible = 'This column indicates records which are managed through a 3rd party integration.',
}
