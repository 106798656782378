import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core'
import {
	UserReportType,
	UserReportImage,
	MailingAddress,
	Incident,
	ReportRendererConfig,
	ChecklistReportData,
	ReportChecklistItemType,
} from '@app/models'
import { log } from '@app/helpers'

import _ from 'lodash'
import moment from 'moment-timezone'

// data is the actual form item stored in the form data

class ChecklistGroupItem {
	data: any
	type: ReportChecklistItemType
	label: string
	value: any
	skip: boolean
	comment?: string
	show = false
	constructor(data: any, type: ReportChecklistItemType, label: string, value: any, comment: string, skip: boolean) {
		this.data = data
		this.type = type
		this.label = label
		this.value = value
		this.skip = skip
		this.comment = comment
	}
}

class ChecklistGroup {
	title: string
	instructions: string
	items: Array<ChecklistGroupItem> = []
	constructor(name: string, instructions: string) {
		this.title = name
		this.instructions = instructions
	}
}

class ChecklistReportViewModel {
	isDesktop = false
	images: Array<UserReportImage> = []

	reportType: UserReportType
	reportData: ChecklistReportData

	reportDate = ''
	employeeName = '< no information provided >'

	skipLabel = '< intentionally skipped >'
	noDescription = '< no information provided >'

	format12Hour = true
	timezone: string
	timeFormat = 'h:mm a'

	logoUrl: string
	companyName: string
	address: MailingAddress

	reportInfo = {
		serviceType: '',
		serviceProviderName: '',
	}

	clientInfo = {
		clientName: '',
		planDate: '',
	}

	checklists: Array<ChecklistGroup> = []

	constructor(config: ReportRendererConfig) {
		const incident = config.reportData as Incident
		this.setupViewModel(incident, config)
		this.setupSectionData(config)
	}

	prepareForPrint() {}

	setupViewModel(incident: Incident, config: ReportRendererConfig) {
		this.images = incident.imageFiles.map((img) => new UserReportImage(img))
		this.reportType = incident.report_type as UserReportType
		this.reportData = JSON.parse(incident.report_json)?.reportData ?? new ChecklistReportData()

		// Setup timezone
		const transMetaData = this.reportData.transactionMetaData
		const timezone = transMetaData?.timezone
		this.timezone = timezone || moment.tz.guess()

		// Setup time formatting
		this.format12Hour = config.formatTime12Hour

		// Setup report date
		const timeFormat = this.format12Hour ? 'h:mm a' : 'HH:mm'
		this.timeFormat = timeFormat
		this.reportDate = moment.tz(incident.created, timezone).format(`ddd MMM Do, YYYY @ ${timeFormat} z`)
	}

	setupSectionData(config: ReportRendererConfig) {
		this.setupHeader(config)
		this.setupReportInfo()
		this.setupChecklists()
	}

	setupHeader(config: ReportRendererConfig) {
		this.logoUrl = config.logoUrl
		this.address = config.companyAddress
		this.companyName = config.companyName
	}

	// Report Specific Setup

	setupReportInfo() {
		const reportData = this.reportData
		this.employeeName = reportData.employee.firstName.value + ' ' + reportData.employee.lastName.value
	}

	setupChecklists() {
		// Setup Employee Checklists
		const employeeChecklists = this.reportData.employeeChecklists
		if (employeeChecklists && employeeChecklists.length > 0) {
			for (const checklist of employeeChecklists) {
				const checklistVm = new ChecklistGroup(checklist.title, checklist.instructions)
				for (const clItem of checklist.items) {
					const checklistItemVm = new ChecklistGroupItem(clItem, clItem.type, clItem.label, clItem.value, clItem.comment?.value, clItem.skip)
					checklistVm.items.push(checklistItemVm)
				}
				this.checklists.push(checklistVm)
			}
		}

		// Manage original version of checkout checklist from company record - Only needed for checkout report
		// Should be able to remove from check in and survey reports as there won't be companyChecklist in
		// report data
		const companyChecklist = this.reportData['companyChecklist']
		if (companyChecklist && companyChecklist.length > 0) {
			log('Got checklist for', 'companyChecklist', companyChecklist.length)
			const checklistVm = new ChecklistGroup('Checkout Checklist', null)
			for (const item of companyChecklist) {
				const checklistItemVm = new ChecklistGroupItem(item, item.type, item.label, item.value, item.comment?.value, item.skip)
				checklistVm.items.push(checklistItemVm)
			}
			this.checklists.push(checklistVm)
		}
		log('Checklists', this.checklists)
	}
}

@Component({
    selector: 'app-checklist-report-renderer',
    templateUrl: './checklist-report-renderer.component.html',
    styleUrls: ['./checklist-report-renderer.component.scss'],
    standalone: false
})
export class ChecklistReportRendererComponent implements OnInit, OnDestroy {
	constructor() {}

	public vm: ChecklistReportViewModel
	public incident: Incident

	@Input() config: ReportRendererConfig
	@Input() publicView = false
	@Output() closeBtnClicked = new EventEmitter<boolean>()

	ngOnInit(): void {
		this.incident = this.config.reportData as Incident
		this.vm = new ChecklistReportViewModel(this.config)
		log('Viewing Report', this.incident)
	}

	printPage() {
		this.vm.prepareForPrint()
		setTimeout(() => {
			window.print()
		}, 500)
	}

	ngOnDestroy() {}

	formatRangeValue(item: any) {
		if (item.skip) return 'N/A'
		if (item.value || item.value === 0) return item.value

		// Check data to return average
		const data = item.data
		if (!data) return '---'
		if (!item.value && !item.skip) return Math.round((data.max - data.min) / 2) + data.min
	}
}
