import { Component, OnInit, Input } from '@angular/core'
import { TransactionMetaDataForReport } from '@app/models'

@Component({
    selector: 'app-user-report-transaction',
    templateUrl: './user-report-transaction.component.html',
    styleUrls: ['./user-report-transaction.component.scss'],
    standalone: false
})
export class UserReportTransactionComponent implements OnInit {
	@Input() empPfpUrl: string
	@Input() transactionMetaData: TransactionMetaDataForReport

	constructor() {}

	ngOnInit(): void {}

	public openEmpPfp() {
		if (this.empPfpUrl) {
			window.open(this.empPfpUrl, 'empPfpImage')
		}
	}
}
