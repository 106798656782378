import { DateTimeHelper, log } from '@app/helpers'
import _ from 'lodash'
import moment from 'moment-timezone'

export class RecordTagContainer {
	tags: Array<RecordTag> = []
	constructor(json?: string) {
		if (json) {
			try {
				const tagContainer = JSON.parse(json)
				const tagArray = tagContainer.tags.map((rec) => new RecordTag(rec))
				this.tags = tagArray // _.orderBy(tagArray, 'label')
			} catch (error) {
				log('Error parsing tags')
			}
		}
	}

	getTagLabels(): Array<string> {
		return this.tags.map((tag) => (tag.isExpired ? `${tag.label}*` : tag.label))
	}

	getTagLabelsForFilter(): Array<string> {
		return this.tags.map((tag) => tag.label)
	}

	getSearchString(): string {
		return this.getTagLabels().join('*').toLowerCase()
	}

	hasExpiredTag(): boolean {
		return this.tags.filter((tag) => tag.isExpired).length > 0
	}
}

export interface IRecordTag {
	label: string
	expire?: string
	ts?: string
}

export class RecordTag {
	label: string = ''
	expire?: string = null
	ts?: string = null

	constructor(record: IRecordTag) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
		}
	}

	get isExpired(): boolean {
		if (!this.expire) return false
		return moment(this.expire).isBefore(moment(), 'seconds')
	}

	get expireTooltip(): string {
		const expire = this.expire
		const expireLabel = this.isExpired ? 'Expired' : 'Expires'
		return expire
			? `${expireLabel} On<br>${DateTimeHelper.mediumDateFromDateString(expire) + '<br>@ ' + DateTimeHelper.standardTimeFromDateString(expire)}`
			: ''
	}
}
