import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { DateTimeHelper, log } from '@app/helpers'
import { ProjectViewManager, SectionSwitcherConfig } from '@app/models'
import { CoreService } from '@app/services'
import { SelectItem } from 'primeng/api'

import { ProjectTableComponent } from './project-table/project-table.component'

import moment from 'moment-timezone'

@Component({
    selector: 'app-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['./projects.component.scss'],
    standalone: false
})
export class ProjectsComponent implements OnInit, AfterViewInit {
	switcherConfig = new SectionSwitcherConfig()

	// accessHelper: AccessHelper
	showSectionHelp = false

	timezoneOptions: Array<SelectItem> = []

	@ViewChild('projectTable') projectTable: ProjectTableComponent

	constructor(
		private router: Router,
		private coreSrvc: CoreService,
	) {
		if (!this.viewManager.timezone) {
			this.viewManager.timezone = this.coreSrvc.dbSrvc.settingSrvc.getCompany().timezone
		}

		this.setupTimezoneDropdown()
		this.setupAccessPermissions()
	}

	get viewManager(): ProjectViewManager {
		return this.coreSrvc.dbSrvc.projSrvc.viewManager
	}

	get devDetect() {
		return this.coreSrvc.devDetect
	}

	get lastUpdated(): string {
		const date = this.coreSrvc.dbSrvc.projSrvc.lastUpdated
		return `Updated ${DateTimeHelper.formatTimeFromDate(date)}`
	}

	ngOnInit() {}
	ngAfterViewInit() {
		scrollTo(0, 0)
	}

	setupAccessPermissions() {
		// this.accessHelper = new AccessHelper(this.coreSrvc.dbSrvc, 'CALLLOG')
	}
	canPerformAction(action) {
		return true
		// return this.accessHelper.canPerformAction(action, false)
	}

	toggleSectionHelp() {
		this.showSectionHelp = !this.showSectionHelp
		this.updateFixedHeader()
	}

	updateFixedHeader() {
		// Update fixed headers for any tables this section has
		setTimeout(() => {
			const table: any = $('#projectTable').DataTable()
			table?.fixedHeader.adjust()
		}, 100)
	}

	public fetchAndReload() {
		log('Component fetchAndReload')
		this.projectTable?.fetchAndReload()
	}

	createRecord() {
		log('Component createRecord')
		this.projectTable?.createRecord()
	}

	public openPrefsDialog() {
		log('Component openPrefsDialog')
		this.projectTable?.openPrefsDialog()
	}

	private canAccessAgentsComponent() {
		// const permissions = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAccessPermissions().getUserPermissionsFor('incident_log')
	}

	private setupTimezoneDropdown() {
		const timezones = this.coreSrvc.dbSrvc.settingSrvc
			.getSupportedTimezones()
			.filter((tz) => tz.active)
			.map((tz) => {
				return {
					label: tz.display_name ? tz.display_name : tz.zone_name,
					value: tz.zone_name,
				}
			})
		this.timezoneOptions = timezones
	}

	public setDayViewDate(date: Date) {
		this.viewManager.dayViewDate = date
		this.fetchAndReload()
	}

	// End Tab Switcher Methods

	public toggleDayView() {
		// this.incidentLogTable?.resetPage(false)
		if (this.viewManager.dayViewDate) {
			this.viewManager.dayViewDate = null
		} else {
			this.viewManager.dayViewDate = new Date()
		}
		this.fetchAndReload()
	}
	public previousDay() {
		const currentMom = moment(this.viewManager.dayViewDate).subtract(1, 'day')
		this.viewManager.dayViewDate = currentMom.toDate()
		this.fetchAndReload()
	}

	public nextDay() {
		const currentMom = moment(this.viewManager.dayViewDate).add(1, 'day')
		this.viewManager.dayViewDate = currentMom.toDate()
		this.fetchAndReload()
	}

	public dayViewPickerClosed() {
		log('Day view picker closed')
		this.fetchAndReload()
	}
}
