<div>
	<textarea
		#chkboxBasicTextArea
		rows="1"
		class="form-control item-label-textarea"
		placeholder="Enter checkbox description"
		[(ngModel)]="config.label"
		(input)="textResizer.emit(chkboxBasicTextArea)"
		(focus)="clearError.emit(item)"
	></textarea>
	<div *ngIf="item.showConfig" class="checkbox-item cli-comment-block d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="config.includeComment" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong (click)="config.includeComment = !config.includeComment" class="has-pointer">Include comment field</strong>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp.emit('itemHasComment')"></i>
		</div>
	</div>
</div>
