import { DatabaseService } from '@app/services'
import { DateTimeHelper } from './datetime'

import moment from 'moment-timezone'

export class TransactionHelper {
	static appendToAdminNotes(dbSrvc: DatabaseService, timezone: string, currentNotes: string, newNote: string): string {
		if (!newNote) return currentNotes
		const is12Hour = DateTimeHelper.format12Hour
		const format = is12Hour ? 'YYYY-MM-DD@h:mma z' : 'YYYY-MM-DD@HH:mm z'
		const timestamp = moment().tz(timezone).format(format)
		const myUser = dbSrvc.settingSrvc.getMyUser()
		const firstName = myUser.first_name ?? ''
		const lastName = myUser.last_name ?? ''
		const name = `${firstName} ${lastName}`

		const start = `[${timestamp}] [${name}]\n`
		const end = `\n`

		// const padding = currentNotes ? '\n' : ''
		return start + newNote + end + (currentNotes ?? '')

		// Previous Implementation
		// if (!newNote) return currentNotes
		// const is12Hour = DateTimeHelper.format12Hour
		// const format = is12Hour ? 'YYYY-MM-DD@h:mma z' : 'YYYY-MM-DD@HH:mm z'
		// const timestamp = moment().tz(timezone).format(format)
		// const myUser = dbSrvc.settingSrvc.getMyUser()
		// const firstName = myUser.first_name ?? ''
		// const lastName = myUser.last_name ?? ''
		// const name = `${firstName} ${lastName}`

		// const start = `[${name}]\n`
		// const end = `\n[${timestamp}]`

		// const padding = currentNotes ? '\n\n' : '\n'
		// return (currentNotes ?? '') + padding + start + newNote + end
	}
}
