import { environment } from '@env/environment'
import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'

import {
	DataAccessRequest,
	FileDropperInfo,
	FileDropperManager,
	GenericEmailListManager,
	GoogleCalendarSync,
	GoogleSyncLogEntry,
	HelpDialogMessage,
} from '@app/models'

import { CoreService } from '@app/services'
import { DateTimeHelper, log } from '@app/helpers'

import { Subscription } from 'rxjs'
import { Table } from 'primeng/table'

import _ from 'lodash'
import moment from 'moment-timezone'
import { Global } from '@app/models/global'

@Component({
    selector: 'app-whentoworkintegration',
    templateUrl: './w2wintegration.component.html',
    styleUrls: ['./w2wintegration.component.scss'],
    standalone: false
})
export class WhenToWorkIntegrationComponent implements OnInit, OnDestroy {
	vm = new WhenToWorkIntegrationViewModel()
	emailManager: GenericEmailListManager

	subs = new Subscription()

	isLogUpdating = false
	syncLog: Array<GoogleSyncLogEntry> = []

	cols = [
		{ field: 'message', header: 'Message' },
		{ field: 'summary', header: 'AmoSummaryunt' },
	]

	syncCheckInterval: NodeJS.Timeout
	syncCheckTimestamp = null

	fileDropperManager: FileDropperManager

	isAdpOrQbIntegrated = false

	ignoreInput = ''
	anyEmployeeInput = ''

	@ViewChild('syncLogTable') syncLogTable: Table | undefined

	constructor(
		private coreSrvc: CoreService,
		private zone: NgZone,
		private router: Router,
	) {
		this.isAdpOrQbIntegrated =
			this.coreSrvc.dbSrvc.qbSrvc.isQBDCustomer() || this.coreSrvc.dbSrvc.qbSrvc.isQBOCustomer() || this.coreSrvc.dbSrvc.adpSrvc.isAdpIntegrated()

		clearInterval(this.syncCheckInterval)
		this.setupFileDropperManager()
		this.setupEmailManager()
		this.loadData()
	}

	get isIntegrating(): boolean {
		return this.coreSrvc.dbSrvc.w2wSrvc.isIntegrating
	}

	get syncCountHeader(): string {
		if (this.syncLog.length === 0) return 'No sync errors'
		return `${this.syncLog.length} sync error${this.syncLog.length > 1 ? 's' : ''}`
	}

	get hasEmailAddresses(): boolean {
		return this.emailManager.emailList.length > 0
	}

	// DEPRECATED - use isSyncPolling
	// get isSyncEnabled(): boolean {
	// 	return !this.vm.syncLock && !this.vm.isSyncing
	// }
	// get disableSyncBtn(): boolean {
	// 	return !(this.vm?.w2wSync?.sync_last_full || this.vm?.w2wSync?.sync_last_incremental) || this.vm.isSyncing
	// }

	get isSyncPolling(): boolean {
		return this.coreSrvc.dbSrvc.w2wSrvc.isSyncPolling
	}

	get emailInputNotEmpty(): boolean {
		return !!this.emailManager.inputField
	}

	get isInternalUser(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()
	}

	public ngOnInit() {}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	setupFileDropperManager() {
		const manager = new FileDropperManager()
		const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
		const bucketName = environment.qbdUploadBucket
		manager.getS3UploadParams = (fdInfo: FileDropperInfo) => {
			const params = {
				Metadata: {
					company_id: `${companyId}`,
				},
				Bucket: bucketName,
				Key: fdInfo.uuid,
				ContentType: 'text/plain; charset=ISO-8859-1',
				Body: fdInfo.file,
			}
			return params
		}
		this.fileDropperManager = manager
	}

	setupEmailManager() {
		this.emailManager = new GenericEmailListManager(this.coreSrvc)
		this.emailManager.blockTitle = 'Email daily error reports to:'
		this.emailManager.listChanged.subscribe((changed) => {
			this.handleEmailListChanges()
		})
	}

	public handleEmailListChanges() {
		log('Email list changed')
		const emails = this.emailManager.emailListString
		const syncId = this.vm.id
		const request = new DataAccessRequest('google_cal_sync', 'update', null, { id: syncId, email: emails })
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			this.zone.run(() => {
				this.loadData()
			})
		})
		log('Request', request)
	}

	public handleApKeyAction(action: 'REMOVE' | 'UPDATE') {
		log('Action', action)
		this.vm.isUpdating = true
		if (action === 'REMOVE') this.vm.w2wApiKey = null
		const w2wApiKey = this.vm.w2wApiKey || null
		const anyEmployeeList = this.vm.w2wSync.any_employee.map((item) => `"${item}"`)
		const ignoreList = this.vm.w2wSync.ignore_entries_with.map((item) => `"${item}"`)
		const updateIgnoreList = ignoreList?.length > 0 ? ignoreList : null
		const request = new DataAccessRequest('google_cal_sync', 'update', null, {
			w2w_api_key: w2wApiKey,
			any_employee: anyEmployeeList,
			ignore_entries_with: updateIgnoreList,
		})
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			this.zone.run(() => {
				this.loadData()
			})
		})
		log('W2W Api Key Action Request', request)
	}

	private loadData() {
		log('Loading W2W Data')
		this.coreSrvc.dbSrvc.bulkRead(['google_cal_sync', 'google_sync_log']).then((success) => {
			this.processLoadResult()
		})
		// const request = new DataAccessRequest('google_cal_sync', null)
		// this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
		// 	this.processLoadResult(result)
		// })
	}

	private processLoadResult() {
		this.zone.run(() => {
			const w2wSync = this.coreSrvc.dbSrvc.w2wSrvc.getW2WSync()
			if (w2wSync) {
				this.vm = new WhenToWorkIntegrationViewModel(w2wSync)
				this.emailManager.initWithString(w2wSync.email)
				if (!w2wSync.partner_api) {
					this.coreSrvc.notifySrvc.notify(
						'info',
						'Key Required',
						'Your W2W Partner Key needs to be setup. Please follow the instructions in the link shown. Contact support if you require assistance.',
					)
					this.vm.isEditing = true
				}
			} else {
				this.vm = new WhenToWorkIntegrationViewModel(w2wSync)
			}
			this.updateSyncLog()
		})
	}

	// private processLoadResult(result: IDataAccessLambdaResult) {
	// 	log('result', result)
	// 	this.zone.run(() => {
	// 		const data = result.data as Array<GoogleCalendarSync>
	// 		if (data?.length > 0) {
	// 			const syncRec = data[0]
	// 			const w2wSync = new GoogleCalendarSync(syncRec)
	// 			this.emailManager.initWithString(w2wSync.email)
	// 			this.vm = new WhenToWorkIntegrationViewModel(w2wSync)
	// 		}
	// 		this.updateSyncLog()
	// 	})
	// }

	startIntegration() {
		this.coreSrvc.dbSrvc.w2wSrvc.isIntegrating = true
		const options = { calendar_id: this.vm.integrationLink }

		// Add API key if it's not empty
		const w2wApiKey = this.vm.w2wApiKey
		if (w2wApiKey) options['w2w_api_key'] = w2wApiKey

		const request = new DataAccessRequest('none', 'google_cal_sync_init', options)
		this.vm.isIntegrating = true
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			setTimeout(() => {
				this.coreSrvc.notifySrvc.notify('success', 'Sync Started', 'Schedule synchronization started. This may take a few minutes to complete.')
				this.coreSrvc.zone.run(() => this.startPolling())
			}, 10000)
		})
	}

	public updateSyncLog() {
		this.isLogUpdating = true
		this.coreSrvc.dbSrvc.readTable('google_sync_log').then((success) => {
			this.zone.run(() => {
				this.syncLog = this.coreSrvc.dbSrvc.w2wSrvc.getW2WFilteredSyncLog()
				this.vm.isDataLoaded = true
				this.vm.isSyncing = false
				this.vm.isIntegrating = false
				this.isLogUpdating = false
			})
		})
	}

	public syncNow() {
		log('Sync now operation')
		this.vm.isSyncing = true
		this.coreSrvc.dbSrvc.w2wSrvc.startSyncPolling()
		const request = new DataAccessRequest('google_cal_sync', 'google_cal_sync_verify', null, null)
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			this.startPolling()
		})
	}

	private startPolling(): void {
		this.syncCheckInterval = setInterval(() => {
			log('Poll for sync timestamp')
			this.coreSrvc.dbSrvc.readTable('google_cal_sync').then((success) => {
				const w2wSync = this.coreSrvc.dbSrvc.w2wSrvc.getW2WSync()
				if (w2wSync.sync_last_api !== this.coreSrvc.dbSrvc.w2wSrvc.lastApiTimestamp) {
					// Timestamps differ so there's been an update. Time to stop
					clearInterval(this.syncCheckInterval)
					this.processLoadResult()
					this.coreSrvc.notifySrvc.notify('success', 'Sync Complete', 'Your WhenToWork schedule sync has completed.')
					this.coreSrvc.dbSrvc.w2wSrvc.isIntegrating = false
					this.coreSrvc.dbSrvc.w2wSrvc.endSyncPolling()
				}
			})
		}, 5000)
	}

	// private startPolling(): void {
	// 	this.syncCheckInterval = setInterval(() => {
	// 		log('Checking Sync Lock')
	// 		const request = new DataAccessRequest('google_cal_sync', null)
	// 		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
	// 			const data = result.data as Array<GoogleCalendarSync>
	// 			if (data?.length > 0) {
	// 				const syncRec = data[0]
	// 				const w2wSync = new GoogleCalendarSync(syncRec)
	// 				if (!w2wSync.sync_lock) {
	// 					clearInterval(this.syncCheckInterval)
	// 					this.processLoadResult(result)
	// 					this.coreSrvc.notifySrvc.notify('success', 'Sync Complete', 'Your When To Work schedule sync has completed.')
	// 				}
	// 			}
	// 		})
	// 	}, 5000)
	// }

	public gotoWhenToWorkSite() {
		window.open('https://whentowork.com/', '_blank')
	}

	public applyFilterGlobal($event, stringVal) {
		this.syncLogTable.filterGlobal(($event.target as HTMLInputElement).value, stringVal)
	}

	public clear(table: Table, input: HTMLInputElement) {
		log('Clearing Filter')
		table.clear()
		input.value = null
	}

	public viewImportLog() {
		this.router.navigate(['/admin/integrations/log/w2w/Employee'])
	}

	public addIgnoreItem(type: 'ANYEMPLOYEE' | 'IGNORELIST') {
		if (type === 'ANYEMPLOYEE') {
			const item = this.anyEmployeeInput
			this.vm.w2wSync.any_employee.push(item)
			this.anyEmployeeInput = ''
		} else {
			const item = this.ignoreInput
			this.vm.w2wSync.ignore_entries_with.push(item)
			this.ignoreInput = ''
		}
	}

	public removeIgnoreItem(type: 'ANYEMPLOYEE' | 'IGNORELIST', index: number) {
		if (type === 'ANYEMPLOYEE') {
			this.vm.w2wSync.any_employee.splice(index, 1)
		} else {
			this.vm.w2wSync.ignore_entries_with.splice(index, 1)
		}
	}

	public uploadProcessComplete(uploadInfos: Array<FileDropperInfo>) {
		log('Upload Process Complete', uploadInfos)
		this.coreSrvc.notifySrvc.notify('success', 'Processing', 'Your file is currently being processed. You will be notified when it is complete.')
		const uploadFile = uploadInfos.pop()
		if (uploadFile) {
			const payload = { filename: uploadFile.uuid }
			this.coreSrvc.dbSrvc.lambdaSrvc
				.qbdSyncS3FileUploaded(payload)
				.then((result) => {
					log('QBDS3 Lambda Result', result)
					if (result.FunctionError) {
						this.coreSrvc.notifySrvc.notify(
							'error',
							'Integration Error',
							'We encountered an unknown error when processing your file. Please contact support to resolve this issue.',
						)
						return
					} else {
						this.coreSrvc.notifySrvc.notify('success', 'File Processed', 'Your file has been successfully processed.')
						this.loadData()
					}
				})
				.catch((error) => {
					log('QBDS3 Lambda Error', error)
					this.coreSrvc.notifySrvc.notify('error', 'Integration Error', 'An error occurred processing your file. Please contact support.')
				})
		}
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'w2w_api_key':
				help.header = 'W2W API Key'
				help.message = 'Partner API Key provided by WhenToWork.'
				break
			case 'any_employee':
				help.header = 'Any Employee List'
				help.message =
					'Enter any part of a name (search term) that when matched to a WhenToWork emplloyee, will be mapped to the system employee in Telephone Timesheets referred to as Any Employee.'
				break
			case 'ignore_entries_with':
				help.header = 'Ignore List'
				help.message =
					'Enter any part of a name (search term) you want to exclude from Telephone Timesheets processing.\n\nIf the WhenToWork employee name contains the search term, it will be ignored from our side, and will not be processed into your Telephone Timesheets schedule.'
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}

////////////////////////////////////

interface IDateComps {
	date: string
	time: string
}

class WhenToWorkIntegrationViewModel {
	w2wSync: GoogleCalendarSync

	id: number

	w2wApiKey = ''
	integrationLink = ''

	syncLock = null

	isDataLoaded = false
	isIntegrated = false
	isProIntegrated = false

	isWorking = false
	isSyncing = false
	isIntegrating = false
	isEditing = false
	isUpdating = false

	hasLastImport = false

	syncMessage = 'Schedule has not yet been synchronized'

	constructor(w2wSync?: GoogleCalendarSync) {
		if (w2wSync) {
			log('W2W Sync Record in VM', w2wSync)
			this.w2wSync = w2wSync
			this.id = w2wSync.id
			this.isIntegrated = true
			this.syncLock = w2wSync.sync_lock
			this.hasLastImport = !!w2wSync.sync_last_file_import
			this.w2wApiKey = w2wSync.w2w_api_key
			this.isProIntegrated = !!this.w2wApiKey
			this.processSyncMessage()
		}
	}

	get lastSyncDate(): string {
		const lastFull = this.w2wSync?.sync_last_full ?? null
		const lastIncremental = this.w2wSync?.sync_last_incremental ?? null
		const list = [lastFull, lastIncremental].filter((date) => date !== null)
		const latest = _.orderBy(list, null, ['desc']).shift()
		return latest || null
	}

	get lastEmpImportDate(): string {
		// return 'Last import on XXX at YYY'
		const lastImport = this.w2wSync?.sync_last_file_import ?? null
		if (!lastImport) return null
		const timeStamp = this.formatDateString(lastImport)
		return `Last import on ${timeStamp.date} at ${timeStamp.time}`
	}

	get isIntegrationLinkValid(): boolean {
		const link = this.integrationLink ?? ''
		if (link) {
			const comps = link.split('@')
			const lastComp = comps.pop() ?? ''
			if (lastComp === 'group.calendar.google.com') return true
		}
		return false
	}

	formatDateString(date: string): IDateComps {
		const dateFormatter = 'ddd. MMM. Do, YYYY'
		const timeFormatter = DateTimeHelper.format12Hour ? 'h:mm a' : 'HH:mm'
		return { date: moment(date).format(dateFormatter), time: moment(date).format(timeFormatter) }
	}

	processSyncMessage() {
		const dateFormatter = 'ddd. MMM. Do, YYYY'
		const timeFormatter = DateTimeHelper.format12Hour ? 'h:mm a' : 'HH:mm'

		const manualSyncMom = this.w2wSync.sync_last_verify ? moment(this.w2wSync.sync_last_verify) : null
		const autoSyncMom = this.w2wSync.sync_last_incremental ? moment(this.w2wSync.sync_last_incremental) : null
		const apiSyncMom = this.w2wSync.sync_last_api ? moment(this.w2wSync.sync_last_api) : null

		const isSyncPolling = Global.coreSrvc.dbSrvc.w2wSrvc.isSyncPolling
		if (isSyncPolling) {
			this.syncMessage = 'Synchronization in progress...'
			return
		}
		// No sync dates set so schedule has not yet been synchronized
		if (!apiSyncMom) return

		log('APISyncMom', apiSyncMom)
		this.syncMessage = apiSyncMom
			? `Schedule last synchronized on ${apiSyncMom.format(dateFormatter)} at ${apiSyncMom.format(timeFormatter)}`
			: `Schedule synchronization pending`

		// DEPRECATED with switch to API
		// Both sync dates have been set - use the most recent
		// if (manualSyncMom && autoSyncMom) {
		// 	if (manualSyncMom.isAfter(autoSyncMom)) {
		// 		this.syncMessage = `Schedule last synchronized manually on ${manualSyncMom.format(dateFormatter)} at ${manualSyncMom.format(
		// 			timeFormatter,
		// 		)}`
		// 	} else {
		// 		this.syncMessage = `Schedule last synchronized automatically on ${autoSyncMom.format(dateFormatter)} at ${autoSyncMom.format(
		// 			timeFormatter,
		// 		)}`
		// 	}
		// }

		// if (manualSyncMom && !autoSyncMom) {
		// 	this.syncMessage = `Schedule last synchronized manually on ${manualSyncMom.format(dateFormatter)} at ${manualSyncMom.format(timeFormatter)}`
		// }

		// if (autoSyncMom && !manualSyncMom) {
		// 	this.syncMessage = `Schedule last synchronized automatically on ${autoSyncMom.format(dateFormatter)} at ${autoSyncMom.format(timeFormatter)}`
		// }
	}
}
