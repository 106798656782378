<div class="drop-container" appDragAndDrop (fileDropped)="onFileDropped($event)">
	<input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event)" />
	<div><i class="far fa-upload up-icon" [class.blink-half]="currentFile"></i></div>
	<div class="drop-copy" *ngIf="!currentFile">Drag and drop files here or click to browse</div>
	<div class="files-progress" *ngIf="currentFile">
		<app-progress [progress]="currentFile?.progress"></app-progress>
		<div class="info">
			<div class="size float-right">
				{{ formatBytes(currentFile?.size) }}
			</div>
			<div class="name">
				{{ currentFile?.name }}
			</div>
		</div>
	</div>
</div>

<!-- <button (click)="currentFile = currentFile ? false : true">Toggle</button> -->
