<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<div class="group-block">
		<div *ngIf="group === 'company'">
			<div class="group-info">
				These preferences will customize the employee web application for all employees. To override these settings for individual employees, use
				the <i class="fa fa-id-badge"></i> icon in the <strong>Actions</strong> column of the <strong>Employees</strong> list.
			</div>
		</div>
		<div *ngIf="group === 'employee'">
			<div class="group-info">
				These preferences are used to customize the employee app for individual employess. If you wish to configure the employee app for your
				entire company, please go to <strong>Admin > Settings > Employee Application</strong>. Employee preferences override company settings. A
				<span style="color: chocolate">highlight</span> is applied to employee settings that differ from your company defaults.
			</div>
		</div>
		<!-- <hr> -->
	</div>

	<div style="margin: 0px 0px 20px 0px">
		<div style="margin-bottom: 6px">
			<app-search-filter [placeholder]="'filter preferences'" (search)="performSearch($event)"></app-search-filter>
		</div>

		<div style="text-align: right">
			<span class="link-text" (click)="toggleExpansion(true)">expand all</span> |
			<span class="link-text" (click)="toggleExpansion(false)">collapse all</span>
		</div>
	</div>

	<div *ngIf="!hasPrefsMatchingSearchFilter" style="text-align: center; font-weight: bold">No prefs matching filter</div>

	<div *ngFor="let category of prefsCategories">
		<app-form-expander
			[style]="{ 'margin-top': '5px' }"
			[label]="category.name"
			[(isExpanded)]="category.isExpanded"
			[hidden]="!hasSearchFilterMatches(category)"
		>
			<div *ngIf="category.note" class="category-note">{{ category.note }}</div>
			<div *ngFor="let pref of getPrefsForCategory(category)">
				<div
					*ngIf="group === 'company' || (group === 'employee' && pref.empOverride)"
					[hidden]="isHidden(pref.name) || !pref.matchesSearchFilter"
					class="prefs-block"
					[class.prefs-block-child]="pref.isChild"
				>
					<div *ngIf="pref.type === 'SELECT' && pref.options.length > 1" class="select-block">
						<!-- <hr /> -->
						<div class="select-label" [class.is-different]="isDifferentFromCompanyPrefs(pref)">
							<strong>{{ pref.label }}</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showPrefHelp(pref)"></i>
							<i
								class="fa fa-undo pref-undo link-text"
								*ngIf="isDifferentFromCompanyPrefs(pref)"
								(click)="resetPrefToCompanyDefault(pref)"
							></i>
						</div>
						<select [(ngModel)]="pref.value" class="form-control">
							<option *ngFor="let option of pref.options" [value]="option.value">{{ option.name }}</option>
						</select>
					</div>
					<div *ngIf="pref.type === 'BOOLEAN'" class="checkbox-block">
						<div class="prefs-checkbox">
							<p-checkbox class="float-left" [(ngModel)]="pref.value" [binary]="true"></p-checkbox>
						</div>
						<div class="prefs-item">
							<span class="prefs-label" [class.is-different]="isDifferentFromCompanyPrefs(pref)" (click)="pref.value = !pref.value">
								{{ pref.label }} </span
							><i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showPrefHelp(pref)"></i>
						</div>
					</div>

					<div *ngIf="pref.type === 'INPUT'">
						<div class="card prefs-input-block">
							<div class="prefs-input-label" [class.is-different]="isDifferentFromCompanyPrefs(pref)">
								<strong>{{ pref.label }}</strong>
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showPrefHelp(pref)"></i>
								<i
									class="fa fa-undo pref-undo link-text"
									*ngIf="isDifferentFromCompanyPrefs(pref)"
									(click)="resetPrefToCompanyDefault(pref)"
								></i>
							</div>
							<div class="prefs-input-label">
								<strong [class.is-different]="isDifferentFromCompanyPrefs(pref)">{{ pref.uiConfig.inputLabel }}</strong>
								<input [(ngModel)]="pref.value" [type]="pref.uiConfig.inputType" class="form-control {{ pref.uiConfig.inputClass }}" />
							</div>
						</div>
					</div>

					<div *ngIf="pref.type === 'TEXTAREA'" class="textarea-block">
						<div class="select-label" [class.is-different]="isDifferentFromCompanyPrefs(pref)">
							<strong>{{ pref.label }}</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showPrefHelp(pref)"></i>
							<i
								class="fa fa-undo pref-undo link-text"
								*ngIf="isDifferentFromCompanyPrefs(pref)"
								(click)="resetPrefToCompanyDefault(pref)"
							></i>
						</div>
						<textarea [(ngModel)]="pref.value" [placeholder]="pref.placeholder" class="form-control textarea-input"></textarea>
					</div>
				</div>
				<div *ngIf="pref.enabledNote && pref.value" class="alert alert-warning">{{ pref.enabledNote }}</div>
			</div>
		</app-form-expander>
	</div>

	<div *ngIf="isInternalUser" class="action-block">
		<div class="row">
			<div class="col-12">
				<button class="btn btn-sm btn-block btn-secondary" (click)="resetPreferencesBtnClicked()">Reset Preferences</button>
			</div>
		</div>
	</div>
</div>

<div *ngIf="false">
	<pre><code>{{ prefsConfig | json }}</code></pre>
</div>
