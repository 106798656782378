<hr class="qbo-log-divider" />

<div class="row">
	<div class="col-12 col-sm-6" style="margin-bottom: 18px">
		<div class="options-title">Start Date</div>
		<app-date-time-picker
			[currentDate]="startDate"
			[pickerType]="'DATE'"
			[pickerMode]="'dialog'"
			[pickerLabel]="'Start Date'"
			[hour12Timer]="true"
			[format]="'MMM Do, YYYY'"
			(pickerDidClose)="startDate = $event"
		>
		</app-date-time-picker>
	</div>
	<div class="col-12 col-sm-6" style="margin-bottom: 18px">
		<div class="options-title">End Date</div>
		<app-date-time-picker
			[currentDate]="endDate"
			[pickerType]="'DATE'"
			[pickerMode]="'dialog'"
			[pickerLabel]="'End Date'"
			[hour12Timer]="true"
			[format]="'MMM Do, YYYY'"
			(pickerDidClose)="endDate = $event"
		>
		</app-date-time-picker>
	</div>
</div>

<div class="row">
	<div class="col-12 col-sm-6 record-item">
		<button
			*ngIf="!syncData.desktop_sync"
			class="btn btn-sm btn-block btn-success qbo-button"
			[class.disabled]="!canPerformAction(CrudAction.update)"
			[disabled]="!employeesSyncd()"
			(click)="exportData()"
		>
			<i *ngIf="!isWorking" class="fa fa-cloud-upload" aria-hidden="true"></i>
			<i *ngIf="isWorking" class="fas fa-loader fa-spin" aria-hidden="true"></i>
			Export Time Entry Data
		</button>
		<strong *ngIf="syncData.desktop_sync">Time Entry Data</strong>
	</div>
	<div class="col-12 col-sm-6 record-item">
		<a href="#" (click)="toggleTooltips()">
			<span class="qbo-tooltip-icon">
				<i class="fal fa-question-circle" aria-hidden="true"></i>
			</span>
		</a>
		<div>
			<strong>Last export on:</strong>
		</div>
		<div>
			<small>
				<b>{{ lastImportDate }}</b>
			</small>
		</div>
	</div>
</div>

<div *ngIf="hasLog" class="row">
	<div class="col-12">
		<button *ngIf="syncErrors > 0" class="btn btn-block btn-sm btn-danger btn-qb-log" [routerLink]="['/admin/integrations/log/qbo/TransactionLog']">
			View Log ( {{ syncErrors }} Error{{ syncErrors > 1 ? 's' : '' }} )
		</button>
		<button
			*ngIf="syncErrors === 0"
			class="btn btn-block btn-sm btn-secondary btn-qb-log"
			[routerLink]="['/admin/integrations/log/qbo/TransactionLog']"
		>
			View Log ( No Errors )
		</button>
	</div>
</div>

<div class="row">
	<div *ngIf="!employeesSyncd()" class="col-12 record-item">
		<strong style="color: chocolate">You must import employee data before you can export time entries.</strong>
	</div>
	<div *ngIf="showTooltips" class="col-12 qbo-tooltip-block text-muted">
		<div *ngIf="syncData.transaction_log_last_start && syncData.transaction_log_last_end" style="margin-bottom: 12px">
			<div class="range-label"><strong>Last export range: </strong></div>
			<span>{{ syncData.transaction_log_last_start | dateFormatter: 'MMM Do, YYYY' }}</span>
			<span> - </span>
			<span>{{ syncData.transaction_log_last_end | dateFormatter: 'MMM Do, YYYY' }}</span>
		</div>
		You can export any date range, but only completed time entries that have not already been exported will be sent to QuickBooks Online. Generally,
		you want your next export to be contiguous with the last export. For example, if your last export was from 4/1 through 4/15, then your next
		export should start on 4/16.
	</div>
</div>

<div *ngIf="false" class="alert alert-info">
	{{ syncData | json }}
</div>
