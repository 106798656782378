import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core'

import {
	ReportGlobals,
	MailingAddress,
	UserReportType,
	UserReportImage,
	Incident,
	IncidentReportData,
	IncidentReportContact,
	IncidentReportContactType,
	IncidentReportDepartmentContact,
	IncidentReportInjuredContact,
	ReportRendererConfig,
} from '@app/models'

import { log } from '@app/helpers'
import { environment } from '@env/environment'

import moment from 'moment-timezone'
import _ from 'lodash'
import { CoreService } from '@app/services'

interface DescriptionBlock {
	label: string
	description: string
}

class AnswerBlock {
	show = false
	label: string
	answer: string
	description: string
	contacts: Array<IncidentReportContact> = []
	departments: Array<IncidentReportDepartmentContact> = []
	injured: Array<IncidentReportInjuredContact> = []
	classType = 'DEFAULT'
	get type(): IncidentReportContactType {
		const contact = this.contacts[0]
		return contact?.type ?? null
	}
	get contactCount(): number {
		if (this.classType === 'DEPARTMENT') {
			return this.departments.length
		}
		if (this.classType === 'INJURED') {
			return this.injured.length
		}
		return this.contacts.length
	}
	addContacts(contacts: Array<any>) {
		// log('Adding Contacts', contacts)
		for (const contact of contacts) {
			if (contact.type === 'DEPARTMENT') {
				this.departments.push(contact)
			}
			if (contact.type === 'INJURED') {
				this.injured.push(contact)
			}
			this.contacts.push(contact)
		}
		if (this.departments.length > 0) {
			this.classType = 'DEPARTMENT'
		}
		if (this.injured.length > 0) {
			this.classType = 'INJURED'
		}
	}
}

class IVRReport {
	isIvrIncidentReport = false
	ivrTranscript = ''
	created = ''
}

class IncidentReportViewModel {
	isDesktop = false
	images: Array<UserReportImage> = []

	reportType: UserReportType
	reportData: IncidentReportData

	reportDate = ''

	reporter = { name: '', license: '' }

	incidentType = ''
	incidentTypeDescription = ''

	describeIncident: Array<DescriptionBlock> = []
	answers: Array<AnswerBlock> = []
	conditions: Array<DescriptionBlock> = []

	skipLabel = '< intentionally skipped >'

	format12Hour = true
	timezone: string

	logoUrl: string
	companyName: string
	address: MailingAddress

	ivr = new IVRReport()

	constructor(config: ReportRendererConfig) {
		const incident = config.reportData as Incident
		this.setupViewModel(incident, config)
		this.setupSectionData(config)
	}

	setupViewModel(incident: Incident, config: ReportRendererConfig) {
		this.images = incident.imageFiles.map((img) => new UserReportImage(img))
		this.reportType = incident.report_type as UserReportType
		this.reportData = JSON.parse(incident.report_json)?.reportData ?? new IncidentReportData()

		// Setup timezone
		const transMetaData = this.reportData.transactionMetaData
		const timezone = transMetaData?.timezone
		this.timezone = timezone || moment.tz.guess()

		// Setup time formatting
		this.format12Hour = config.formatTime12Hour

		// Setup report date
		const timeFormat = this.format12Hour ? 'h:mm a' : 'HH:mm'
		this.reportDate = moment.tz(incident.created, timezone).format(`ddd MMM Do, YYYY @ ${timeFormat} z`)

		// Handle IVR Incident Report Case
		if (!incident.report_json && incident.audio_file) {
			this.ivr.isIvrIncidentReport = true
			this.ivr.ivrTranscript = incident.transcript
		}
	}

	setupSectionData(config: ReportRendererConfig) {
		this.setupHeader(config)
		this.setupIncidentDescription()
		this.setupAnswersBlock()
		this.setupConditionsBlock()
	}

	setupHeader(config: ReportRendererConfig) {
		this.logoUrl = config.logoUrl
		this.address = config.companyAddress
		this.companyName = config.companyName

		const incidentType = this.reportData.incidentType.value
		const typeDescription = ReportGlobals.incidentTypeOptionsForSecurity.find((opt) => opt.type === incidentType)?.name
		const otherDescription = this.reportData.incidentType.option
		const isOther = incidentType === 'OTHER'

		this.incidentType = isOther ? otherDescription : typeDescription ? typeDescription : 'Unknown Type'

		const reporter = this.reportData.incidentReporter
		this.reporter.name = reporter.firstName.value + ' ' + reporter.lastName.value
		this.reporter.license = reporter.license.value || this.skipLabel
	}

	setupIncidentDescription() {
		const keys = ['whatHappened', 'whenDidItHappen', 'whyDidItHappen', 'howDidItHappen']
		const describeIncident = this.reportData.describeIncident

		keys.forEach((key) => {
			const label = _.startCase(key)
			const description = describeIncident[key]?.value || this.skipLabel
			this.describeIncident.push({ label: label, description: description })
		})
	}

	setupAnswersBlock() {
		const keys = [
			'didYouObserve',
			'wereYouInvolved',
			'didSomeoneInformYou',
			'wereStatementsGiven',
			'wasPropertyDamaged',
			'wasSupervisorOrManagerCalled',
			'wasCustomerCalled',
			'wereOfficialsCalled',
			'wasAnyoneInjured',
			'wasMedicalAssistanceRefused',
			'wasMedicalAssistanceRequested',
			'wasInjuredPartyWearingGlasses',
			'wasInjuredPartyCarryingAnything',
		]

		keys.forEach((key) => {
			const answerBlock = this.processAnswerBlock(key)
			if (answerBlock) {
				this.answers.push(answerBlock)
			}
		})
		log('Answers', this.answers)
	}

	processAnswerBlock(key: string): AnswerBlock {
		const block = this.reportData[key]
		if (block) {
			const ab = new AnswerBlock()
			ab.label = _.startCase(key)
			ab.answer = block.value ? 'YES' : 'NO'
			ab.description = block.description
			ab.addContacts(block.contacts ?? [])
			return ab
		}
		return null
	}

	prepareForPrint() {
		for (const answer of this.answers) {
			answer.show = true
		}
	}

	setupConditionsBlock() {
		const keys = ['weather', 'location', 'lighting', 'floor', 'signage']
		const describeConditions = this.reportData.describeConditions

		keys.forEach((key) => {
			const label = 'Describe ' + _.startCase(key)
			const description = describeConditions[key]?.value || this.skipLabel
			this.conditions.push({ label: label, description: description })
		})
	}

	isStandardContact(type: IncidentReportContactType) {
		if (type === 'DEPARTMENT' || type === 'INJURED') {
			return false
		}
		return true
	}

	doesContactTypeShowDate(type: IncidentReportContactType): boolean {
		switch (type) {
			case 'CUSTOMER':
			case 'DEPARTMENT':
			case 'SUPERVISOR':
				return true
			default:
				return false
		}
	}

	displayDateForContact(contact: { date: { value: string } }) {
		const date = contact.date.value
		const timeFormat = this.format12Hour ? 'h:mm a' : 'HH:mm'
		const mom = moment.tz(date, this.timezone)
		return mom.isValid() ? mom.format(`ddd MMM Do, YYYY @ ${timeFormat} z`) : ''
	}

	typeLabelFormatter(type: IncidentReportContactType) {
		return _.capitalize(type)
	}
}

@Component({
    selector: 'app-incident-report-renderer',
    templateUrl: './incident-report-renderer.component.html',
    styleUrls: ['./incident-report-renderer.component.scss'],
    standalone: false
})
export class IncidentReportRendererComponent implements OnInit, OnDestroy {
	// CoreService added for access to audioSrvc. Do not use for anything database related
	// as this component will not have access to any of these services

	constructor(private coreSrvc: CoreService) {}

	public vm: IncidentReportViewModel
	public incident: Incident

	@Input() config: ReportRendererConfig
	@Input() publicView = false
	@Output() closeBtnClicked = new EventEmitter<boolean>()

	ngOnInit(): void {
		this.incident = this.config.reportData as Incident
		this.vm = new IncidentReportViewModel(this.config)
		log('Viewing Report', this.incident)
	}

	printPage() {
		this.vm.prepareForPrint()
		setTimeout(() => {
			window.print()
		}, 500)
	}

	playAudio() {
		const fileId = this.incident.audio_file
		const audioHostUrl = 'https://' + environment.audioDomain + '/'

		const url = audioHostUrl + fileId
		this.coreSrvc.audioSrvc.playAudioUrl.next(url)
	}

	ngOnDestroy() {}
}
