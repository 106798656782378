import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core'
import { AuditLog, AuditLogDataAccessRequest, AuditLogTrackType } from '@app/models'
import { DatabaseService } from '@app/services'
import { log } from '@app/helpers'
import { v4 as uuid } from 'uuid'

@Component({
    selector: 'app-audit-modal',
    templateUrl: './audit-log-modal.component.html',
    styleUrls: ['./audit-log-modal.component.scss'],
    standalone: false
})
export class AuditModalComponent implements OnInit, OnChanges {
	id = uuid() // 'auditModal'

	isDataLoaded = false
	isDataLoading = false

	records: Array<AuditLog> = []

	@Input() header = 'Header'
	@Input() footer = 'Footer'

	@Input() recordId: number
	@Input() resource: string
	@Input() trackType: AuditLogTrackType

	@Input() visible = false
	@Output() visibleChange = new EventEmitter<boolean>()

	auditEntry: AuditLog

	constructor(
		private cd: ChangeDetectorRef,
		private dbSrvc: DatabaseService,
	) {}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['visible']) {
			this.visibleChange.emit(this.visible)
			if (this.visible) {
				this.loadRecords()
			} else {
				log('Dialog is hiding')
				this.isDataLoaded = false
				this.isDataLoading = false
			}
		}
	}
	visibilityChanged(visible: boolean) {
		this.visibleChange.emit(visible)
	}

	loadRecords() {
		log('Audit Log Modal - Loading Records for', this.recordId)

		// Clear any existing setup from previous audits
		this.records = []
		this.auditEntry = null

		this.isDataLoaded = false
		this.isDataLoading = true
		const options = { resource: this.resource, resource_id: this.recordId }
		if (this.trackType) options['type'] = this.trackType
		const request = new AuditLogDataAccessRequest(options)
		log('Routed Request', request)
		this.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			const data = result.data
			this.records = data.map((rec) => new AuditLog(rec))

			if (this.records.length > 0) {
				this.auditEntry = this.records[this.records.length - 1]
			}
			this.isDataLoaded = true
			this.isDataLoading = false
			this.updateUI()
			log('History Results', data)
		})
	}

	updateUI() {
		log('Updating UI')
		this.cd.detectChanges()
	}
}
