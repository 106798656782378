import { DateTimeHelper } from '@app/helpers'
import { SelectItem } from 'primeng/api'
import { ContactOrganizationLinkRecord } from './link-contact-org'
import { IFileUploadsMetadata } from './secure-file'

export type ContactType =
	| 'MAIN'
	| 'DEFAULT'
	| 'BILLING'
	| 'EMERGENCY'
	| 'EMPLOYEE'
	| 'PRIMARY'
	| 'MANAGER'
	| 'SECONDARY'
	| 'SITE_MANAGER'
	| 'SUPERVISOR'

export class ContactHelper {
	static getContactTypeDropdown(): Array<SelectItem> {
		return [
			{ label: 'Primary', value: 'PRIMARY' },
			{ label: 'Secondary', value: 'SECONDARY' },
			{ label: 'Emergency', value: 'EMERGENCY' },
			{ label: 'Employee', value: 'EMPLOYEE' },
			{ label: 'Supervisor', value: 'SUPERVISOR' },
			{ label: 'Site Manager', value: 'SITE_MANAGER' },
			{ label: 'Billing', value: 'BILLING' },
		]
	}
	static formatContactType(type: ContactType) {
		switch (type) {
			case 'MAIN':
				return 'MAIN'
			case 'SITE_MANAGER':
				return 'SITE MANAGER'
			default:
				return type
		}
	}
}

export type ContactsViewTabState = 'INDIVIDUALS' | 'ORGANIZATIONS'

export class ContactsViewManager {
	isSectionWorking = false
	currentView: ContactsViewTabState = 'INDIVIDUALS'
}

export class ContactRecord {
	id: number
	company_id: number
	internal_user: number
	external_id: string

	created: string
	updated: string

	details: string
	first: string
	last: string
	name: string
	email: string
	phone_e164: string

	file_uploads_metadata: IFileUploadsMetadata

	notify_phone = true
	notify_sms = true
	notify_email = true

	// Address data
	address_1: string = null
	address_2: string = null
	city: string = null
	district: string = null
	postal_code: string = null
	address_notes: string = null

	vendor_client_contact: Array<ContactOrganizationLinkRecord> = []

	constructor(record?: ContactRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
			this.vendor_client_contact = this.vendor_client_contact.map((rec) => new ContactOrganizationLinkRecord(rec))
		}
	}
}
