import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { EditMapMarkerComponent } from '@app/components/edit-map-marker/edit-map-marker.component'
import { DateTimeHelper, DisplayHelper, log } from '@app/helpers'
import {
	FileUploadManager,
	FileUploaderProcessedFile,
	HelpDialogMessage,
	TourType,
	TourWaypoint,
	WaypointImageFile,
	WaypointViewModel,
} from '@app/models'
import { CoreService } from '@app/services'
import { environment } from '@env/environment'
import moment from 'moment'
import { SelectItem } from 'primeng/api'
import { Dropdown, DropdownChangeEvent } from 'primeng/dropdown/public_api'
@Component({
    selector: 'app-tour-waypoint',
    templateUrl: './tour-waypoint.component.html',
    styleUrls: ['./tour-waypoint.component.scss'],
    standalone: false
})
export class TourWaypointComponent implements OnInit {
	is12HourFormat = DateTimeHelper.format12Hour
	offsetStartLabel = 'min after check-in'

	@Input() wpVm: WaypointViewModel
	@Input() idx: number
	@Input() listLength: number
	@Input() timezone: string = 'UTC'
	@Input() tourType: TourType
	@Input() linkedJobOptions: Array<SelectItem> = []

	@Output() moveDown = new EventEmitter<WaypointViewModel>()
	@Output() moveUp = new EventEmitter<WaypointViewModel>()
	@Output() editWaypoint = new EventEmitter<WaypointViewModel>()
	@Output() cloneWaypoint = new EventEmitter<WaypointViewModel>()
	@Output() deleteWaypoint = new EventEmitter<WaypointViewModel>()
	@Output() needsValidationCheck = new EventEmitter<boolean>()
	@Output() inputFocused = new EventEmitter<WaypointViewModel>()
	@Output() inputBlurred = new EventEmitter<WaypointViewModel>()

	@ViewChild('mapMarkerPicker', { static: false }) mapMarkerPicker: EditMapMarkerComponent

	fileUploadManager = new FileUploadManager()

	currentImagesList: Array<WaypointImageFile> = []

	constructor(
		private coreSrvc: CoreService,
		private cd: ChangeDetectorRef,
	) {}

	public ngOnInit(): void {
		this.setOffsetStartLabel()
		this.setupFileUploadManager()
		this.configureFileUploadManager(this.wpVm.twp)
		this.updateCurrentImagesList()
		this.wpVm.updateClockTimeFromOffset()
	}

	public setOffsetStartLabel() {
		switch (this.tourType) {
			case 'STRUCTURED':
				this.offsetStartLabel = 'min after check-in'
				break
			case 'STRUCTURED_HOUR_START':
				this.offsetStartLabel = 'min after tour starts'
				break
			default:
				this.offsetStartLabel = 'min after start'
		}
	}

	public validateInt(prop: string) {
		const value = this.wpVm.twp[prop]
		if (!value) {
			this.wpVm.twp[prop] = 0
		} else {
			this.wpVm.twp[prop] = parseInt(this.wpVm.twp[prop], 10)
		}
		this.needsValidationCheck.next(true)
	}

	public gpsUpdated(event) {
		log('GPS Updated', event)
		this.wpVm.twp.latitude = event?.latitude ?? null
		this.wpVm.twp.longitude = event?.longitude ?? null
		this.cd.detectChanges()
	}

	private setupFileUploadManager() {
		log('Setup File Upload Manager')
		const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
		const fileUploadManager = new FileUploadManager()
		fileUploadManager.setConfiguration(companyId, environment.assetsBucket, 'checkpoints')
		fileUploadManager.supportedMimeTypes = ['image/gif', 'image/png', 'image/jpeg']
		fileUploadManager.useFileExtFallbackForMimeType = true
		this.fileUploadManager = fileUploadManager
	}

	private configureFileUploadManager(twp: TourWaypoint): void {
		const files = twp.getImageFiles().map((rec) => new FileUploaderProcessedFile(rec))
		let index = 1
		for (const file of files) {
			file.linkText = `Photo #${index++}`
		}
		this.fileUploadManager.processedFiles = files
	}

	public updateCurrentImagesList() {
		this.currentImagesList = this.fileUploadManager.processedFiles.map((pf) => new WaypointImageFile(pf))
		this.wpVm.twp.imageFiles = this.currentImagesList
	}

	public deleteBtnClicked(wpVm: WaypointViewModel) {
		wpVm.isConfirmingDelete = true
		const selector = '#wpFlash_' + wpVm.twp.uuid
		this.coreSrvc.displaySrvc.bringIntoViewBySelector(selector)
	}

	public deleteConfirmed(wpVm: WaypointViewModel) {
		this.deleteWaypoint.next(wpVm)
	}

	public setWaypointClockTime(date: Date) {
		log('setWaypointClockTime', date)
		const timeStr = '2020-01-01T' + moment(date).format('HH:mm')
		const offsetTime = moment(timeStr).diff(moment('2020-01-01'), 'minutes')
		this.wpVm.clockTime = date
		this.wpVm.twp.startOffset = offsetTime
		this.needsValidationCheck.next(true)
	}

	selectedGpsItem: number = null
	public setGpsFromJob(event: DropdownChangeEvent) {
		log('setGpsFromJob', event)
		const jobId = event.value
		if (!jobId) return
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)
		const site = this.coreSrvc.dbSrvc.jobSrvc.getJobSiteForJobId(jobId)
		if (site) {
			const lat = site.geo_latitude
			const lng = site.geo_longitude
			if (lat && lng) {
				this.wpVm.twp.latitude = lat
				this.wpVm.twp.longitude = lng
				this.mapMarkerPicker.updatePosition(lat, lng)
			} else {
				this.coreSrvc.notifySrvc.notify('info', 'No GPS Coordinates', 'No GPS coordinates were found for this job.', 8)
			}
		}
		setTimeout(() => {
			this.selectedGpsItem = null
		})
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'identifier':
				help.header = 'Identifier'
				help.message =
					'Enter a short public facing description (e.g. Main Parking Lot, East Stairwell, Break Room) to identify this checkpoint. This identifier will be displayed internally and in public reports.'
				break
			case 'requirements':
				help.header = 'Requirements'
				help.message =
					'Enter notes and describe any requirements such as specific photos that should be submitted during this checkpoint. This information will be available in the employee app.'
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
