import { Component, OnInit, AfterViewInit, EventEmitter, Output, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'

import { DeviceDetectorService } from 'ngx-device-detector'

import { UserLoginService, LoggedInCallback, CognitoCallback, DatabaseService, PrefsService, UserSessionService, CoreService } from '@app/services'

import { parseNumber, ParsedNumber, formatNumber, CountryCode, isValidNumberForRegion } from 'libphonenumber-js'
import { log, Helper, DisplayHelper } from '@app/helpers'
import { DataAccessRequest, DeviceDetectorInfo } from '@app/models'
import { Subscription } from 'rxjs'

// declare const AWS

@Component({
    selector: 'app-auth-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy, CognitoCallback, LoggedInCallback {
	mobile: string
	email: string
	password: string
	errorMessage: string
	isWorking = false
	countryCode: CountryCode = 'US'
	countryCodeData = Helper.countryIsoData.filter((region) => region.regionSupport)
	mobileNumberValid = false

	employeesLoaded = false
	jobSitesLoaded = false
	reportsLoaded = false

	hideLogin = true
	keepMeLoggedIn = false

	loginWithEmail = false // Enable checkLoginMethod in constructor

	devInfo: DeviceDetectorInfo

	// Emit true when starting login. Used to hide inactive logout message
	@Output() startLogin = new EventEmitter<boolean>()

	// private sectionsLoaded = 0

	private subs = new Subscription()

	constructor(
		private coreSrvc: CoreService,
		public devDetect: DeviceDetectorService,
		public router: Router,
		private userSrvc: UserLoginService,
		private sessionSrvc: UserSessionService,
		private dbSrvc: DatabaseService,
		private prefsService: PrefsService,
	) {
		log('LoginComponent - Created')

		// Color background based on screen size
		this.subs.add(
			this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => {
				this.handleScreenSizeChange()
			}),
		)
		this.handleScreenSizeChange()

		this.devInfo = new DeviceDetectorInfo(devDetect)
		this.startLogin.emit(false)
	}

	ngOnInit() {
		this.hideLogin = this.coreSrvc.loginSrvc.showStartupBanner
		const bannerDelay = this.coreSrvc.loginSrvc.showStartupBanner ? 1500 : 0
		setTimeout(() => {
			this.errorMessage = null
			log('LoginComponent check if user is logged in')
			// Clear stale data before login
			this.dbSrvc.clearServiceData()
			this.userSrvc.isAuthenticated(this)
			this.countryCode = this.prefsService.data.defaultLoginRegion as CountryCode
		}, bannerDelay)
	}

	ngAfterViewInit() {
		scrollTo(0, 0)
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	handleScreenSizeChange() {
		DisplayHelper.setBodyBackgroundForAuthFlow()
	}

	checkLoginMethod() {
		const loginMethod = localStorage.getItem('loginMethod')
		log('loginMethod', loginMethod)
		if (loginMethod === 'email') {
			this.loginWithEmail = true
		} else {
			this.loginWithEmail = false
		}
	}

	setLoginMethod(method: string): boolean {
		if (method === 'email') {
			this.loginWithEmail = true
			localStorage.setItem('loginMethod', 'email')
		} else {
			this.loginWithEmail = false
			localStorage.removeItem('loginMethod')
		}
		return false
	}

	onLogin() {
		// Clear cognito credentials
		this.coreSrvc.sessionSrvc.clearCognitoFromLocalStorage()

		this.errorMessage = null
		const mobileNumber = Helper.stripNonNumeric(this.mobile)
		const password = this.password
		const useEmail = this.loginWithEmail
		let username

		if (useEmail) {
			if (!Helper.isValidEmail(this.email)) {
				alert('Your email address does not appear to be valid')
				return
			}
		} else {
			if (!mobileNumber || mobileNumber === '') {
				// this.errorMessage = 'Please check your mobile number.';
				alert('Please enter a valid mobile phone number for the reqion selected.')
				return
			}
		}

		if (password === '') {
			// this.errorMessage = 'Password is required.';
			alert('Password is required.')
			return
		}

		if (this.keepMeLoggedIn) {
			this.prefsService.disableAutoLogout()
		} else {
			this.prefsService.enableAutoLogout()
		}

		if (useEmail) {
			log('Login Using Email')
			this.startLogin.emit(true)
			this.isWorking = true
			username = this.email
			this.userSrvc.authenticate(username, this.password, this)
			return
		} else {
			log('Login Using Mobile')
			if (mobileNumber.length > 3 && isValidNumberForRegion(mobileNumber, this.countryCode)) {
				const parsedNumber = parseNumber(mobileNumber, this.countryCode) as ParsedNumber
				this.startLogin.emit(true)
				this.isWorking = true
				username = formatNumber(parsedNumber, 'E.164')
				this.userSrvc.logout()
				this.sessionSrvc.clearCognitoCredentials()
				this.sessionSrvc.clearAdpSession()
				this.userSrvc.authenticate(username, this.password, this)
			} else {
				alert('Please enter a valid mobile number for the region selected.')
				return
			}
		}
	}

	cognitoCallback(message: string, result: any) {
		log('LogingComponent: Enter cognito callback')

		if (message != null) {
			// error
			this.isWorking = false
			log('result: ', this.errorMessage)

			// Check if online
			const online = navigator.onLine
			if (!online) {
				this.errorMessage = 'You appear to be offline. Please check your network connection and try again.'
				return
			}
			// if (message === 'User is disabled') {
			// 	this.errorMessage = 'This account has been closed. Please contact support for more information.'
			// } else if (message === 'User is not confirmed.') {
			// 	this.errorMessage = 'Account confirmation failed. Please contact support at 1-888-250-8463 to resolve this issue.';
			// } else {
			// 	this.errorMessage = message;
			// }
			this.errorMessage =
				'Login failed. Make sure you selected the correct region and entered the right username and password. Please contact support if the issue persists.'
		} else {
			// success
			// log('DDB Write User Login')
			// this.ddb.writeLogEntry("login");
			const devInfo = this.devInfo ? JSON.stringify(this.devInfo) : null
			const options = { pw: this.password, devInfo: devInfo }
			const request = new DataAccessRequest('', 'login_event', options, null)
			this.dbSrvc.lambdaSrvc.dataAccess(request)
			log('LogingComponent: User has logged in')
			// this.hideLogin = true
			this.userSrvc.setLastActive()
			this.loadAllData()
		}
	}

	clearLocalStorage() {
		log('Clearing local storage for new login')
		this.coreSrvc.cognito.cognitoCreds?.clearCachedId()
		// AWS.config.credentials.clearCachedId()
	}

	setRegion() {
		this.prefsService.data.defaultLoginRegion = this.countryCode
		this.prefsService.save()
	}

	isLoggedIn(message: string, isLoggedIn: boolean) {
		if (isLoggedIn) {
			this.hideLogin = true
			this.loadAllData()
		} else {
			log('LogingComponent: User not logged in')
			this.prefsService.disableAutoLogout()
			this.dbSrvc.settingSrvc.clearCompanyData()
			this.hideLogin = false
		}
	}

	loadAllData() {
		this.dbSrvc.bulkRead(['company', 'users']).then((result) => {
			const company = this.dbSrvc.settingSrvc.getCompany()
			if (!company) {
				this.router.navigate(['/home/not-provisioned'])
			} else {
				// Setup auto logout and navigate to dashboard
				this.setupAutoLogout()
				this.router.navigate(['/admin/dashboard'])
			}
		})
	}

	private setupAutoLogout() {
		log('Login Component setting up autoLogout')
		const company = this.dbSrvc.settingSrvc.getCompany()
		const logoutInMin = company.auto_logout
		if (logoutInMin) {
			this.prefsService.setAutoLogoutTimer(logoutInMin * 60)
		} else {
			this.prefsService.setAutoLogoutTimer(null)
		}
		// this.userService.clearLastActive() // Clear last active timestamp before starting
	}
}
