import { Component, OnInit, OnDestroy, Input, AfterViewInit, Output, EventEmitter, AfterContentInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'

import {
	Client,
	DialogManager,
	FormAddressManager,
	HelpDialogMessage,
	JobRecord,
	OrganizationRecord,
	organizationTypeOptions,
	OrgTableDisplayState,
} from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { log, Helper, FormHelper } from '@app/helpers'

import { parseNumber, formatNumber, CountryCode, ParsedNumber, isValidNumberForRegion, isValidNumber } from 'libphonenumber-js'
import { NumberHelper } from '@app/helpers/number'

@Component({
    selector: 'app-organization-edit',
    templateUrl: './organization-edit.component.html',
    styleUrls: ['./organization-edit.component.scss'],
    standalone: false
})
export class OrganizationEditComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
	org: OrganizationRecord
	isNew = false
	isUpdating = false
	orgForm: UntypedFormGroup

	currencySymbol = ''
	isPayRateAvailable = false

	dialingCode = 'US' as CountryCode
	countryCodeData = Helper.countryIsoData.filter((region) => region.regionSupport)
	phoneNumber = ''
	isPhoneNumberValid = false

	orgTypeOptions = organizationTypeOptions

	linkedJobs: Array<JobRecord> = []

	// Address
	formAddressManager: FormAddressManager

	@Input() dialogManager = new DialogManager()
	@Input() recordId: number
	@Input() action = 'edit'

	@Output() recordUpdated = new EventEmitter<OrganizationRecord>()

	constructor(
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
	) {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		this.formAddressManager = new FormAddressManager(this.coreSrvc)

		// Setup pay rate options
		this.currencySymbol = this.coreSrvc.dbSrvc.settingSrvc.getCompanyCurrencySymbol()
		this.isPayRateAvailable = company.pay_rate
	}

	get basePayRateLabel(): string {
		const orgType = this.orgForm?.get('type').value
		switch (orgType) {
			case 'CLIENT':
				return 'Base Bill Rate'
			case 'VENDOR':
				return 'Base Pay Rate'
			default:
				'Base Bill/Pay Rate'
		}
	}

	ngOnInit() {
		if (this.action === 'new') {
			log('Creating a new client')
			this.isNew = true
			this.org = new OrganizationRecord()
			// Setup type based on which view is selected - the default is CLIENT
			const currentView = this.coreSrvc.dbSrvc.orgSrvc.viewManager.currentView
			if (currentView === OrgTableDisplayState.vendors) {
				this.org.type = 'VENDOR'
			}
			const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
			this.dialingCode = company.country_iso as CountryCode
		} else {
			this.isNew = false
			this.org = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationById(this.recordId)
		}

		log('Init Organization', this.org)

		this.setupForm()
		this.setupPhoneNumber()
		this.formatNumber()
		this.setupLinkedJobs()
	}

	ngAfterViewInit() {}

	ngAfterContentInit() {
		this.setupDialogManager()
	}

	ngOnDestroy() {}

	viwFormErrors() {
		FormHelper.getFormValidationErrors(this.orgForm)
	}

	isFormValid(): boolean {
		const isEmailValid = this.isEmailValid()
		const isPhoneValid = this.isPhoneValid()
		const isFormValid = this.orgForm.valid && this.formAddressManager.isFormValid
		return isFormValid // && isEmailValid && isPhoneValid
	}

	isEmailValid(): boolean {
		const email = this.orgForm.get('email').value
		if (email) {
			return Helper.isValidEmail(email)
		}
		return true
	}

	setupForm() {
		const org = this.org
		this.orgForm = this.fb.group({
			id: [org.id],
			name: [org.name, Validators.required],
			type: [org.type],
			external_id: [org.external_id],
			phone_e164: [org.phone_e164],
			email: [org.email],
			details: [org.details],
			pay_rate: [this.org ? this.org.pay_rate?.toFixed(2) : null],
			notify_phone: [org.notify_phone],
			notify_sms: [org.notify_sms],
			notify_email: [org.notify_email],
		})
		this.formAddressManager.setAddress(org)
	}

	setupDialogManager() {
		this.dialogManager.canSubmit = () => this.isFormValid()
		this.dialogManager.submitBtnAction = () => this.submit()
	}

	setupLinkedJobs() {
		const orgId = this.org?.id
		this.linkedJobs = this.coreSrvc.dbSrvc.jobSrvc.getJobsForOrganizationId(orgId)
		log('Linked Jobs', this.linkedJobs)
	}

	// Phone Number Methods

	setupPhoneNumber() {
		const phoneNumber = this.org.phone_e164
		if (phoneNumber) {
			const parsedNumber = parseNumber(phoneNumber) as ParsedNumber
			const dialingCode = parsedNumber.country
			if (isValidNumber(parsedNumber)) {
				this.dialingCode = dialingCode
			}
			this.phoneNumber = phoneNumber
		}
	}

	formatNumber() {
		const countryCode: CountryCode = this.dialingCode
		const number = this.phoneNumber

		if (number && number.length > 3 && isValidNumberForRegion(number, countryCode)) {
			const parsedNumber = parseNumber(number, countryCode) as ParsedNumber
			this.isPhoneNumberValid = true
			this.phoneNumber = formatNumber(parsedNumber, 'NATIONAL')
		} else {
			this.isPhoneNumberValid = false
		}
	}

	isPhoneValid(): boolean {
		if (!this.phoneNumber) {
			return true
		}
		if (isValidNumberForRegion(this.phoneNumber, this.dialingCode)) {
			return true
		} else {
			return false
		}
	}

	submit() {
		// Guard against double submission
		if (this.isUpdating) return
		FormHelper.trimOnlyWhitespace(this.orgForm)

		const record = this.makeUpdateRecord()
		log('Record to submit', record)
		if (record) {
			if (this.isNew) {
				log('Adding new organization', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.insertRecord('vendor_client_org', record).then((success) => {
					if (success) {
						this.recordUpdated.emit(this.org)
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			} else {
				log('Updating record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.updateRecord('vendor_client_org', record).then((success) => {
					if (success) {
						this.recordUpdated.emit(this.org)
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			}
		}
	}

	makeUpdatePhone(): string {
		if (!this.phoneNumber) {
			return null
		}
		const parsedNumber = parseNumber(this.phoneNumber, this.dialingCode) as ParsedNumber
		if (isValidNumberForRegion(this.phoneNumber, this.dialingCode)) {
			return formatNumber(parsedNumber, 'E.164')
		} else {
			alert('The phone number you entered is not valid for the region selected.')
			return null
		}
	}

	makeUpdateRecord(): Object {
		const form = this.orgForm.value
		const record = new OrganizationRecord(form)

		if (this.phoneNumber && !this.isPhoneNumberValid) {
			this.coreSrvc.notifySrvc.notify(
				'error',
				'Invalid Phone',
				'Please enter a valid phone number for the region selected or clear the phone number field.',
			)
			return null
		}

		const doesNameExist = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationByName(record.name)
		if (this.isNew && doesNameExist) {
			this.coreSrvc.notifySrvc.notify(
				'error',
				'Duplicate Name',
				'The organization name already exists. Organization names must be unique. Please choose another name.',
			)
			return null
		}

		record.phone_e164 = this.makeUpdatePhone()
		this.formAddressManager.prepareForSubmission(record)

		return record
	}

	// Misc Methods

	public formatRateInput(prop: string) {
		const value = this.orgForm.get(prop).value
		const newValue = NumberHelper.formatPayRate(value)
		this.orgForm.get(prop).setValue(newValue)
	}

	toggleCheckbox(prop: string) {
		log('prop', prop)
		const current = this.orgForm.get(prop).value
		this.orgForm.get(prop).setValue(!current)
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)

		const orgType = this.orgForm.get('type').value
		const rateHelpHeader = orgType === 'CLIENT' ? 'Bill Rate' : 'Pay Rate'
		const rateHelpMsg =
			orgType === 'CLIENT'
				? `This is the rate which will be used when calculating the client bill amount when no job-specific rates are defined for this organization.`
				: `This is the rate which will be used when calculating the vendor pay amount when no job-specific rates are defined for this organization.`

		switch (trigger) {
			case 'payRate':
				help.header = rateHelpHeader
				help.message = rateHelpMsg
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
