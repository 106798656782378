<div class="container section-container">
	<div class="sec-head-wrap">
		<div class="sec-act-ico-wrap">
			<i
				class="fas fa-plus sec-act-ico"
				[class.section-control-disabled]="!canPerformAction(CrudAction.create)"
				(click)="createRecord()"
				title="Add Job Site"
			></i>
			<i class="far fa-cog sec-act-ico" (click)="togglePrefsPanel()" title="Preferences"></i>
			<i class="far fa-question-circle sec-act-ico" (click)="toggleTooltips()" title="Show Help"></i>
		</div>

		<div class="sec-title-wrap">
			<i class="far fa-map-marker-alt sec-head-ico" aria-hidden="true"></i>
			<span>Job Sites</span>
			<app-section-list-count [manager]="listCountManager"></app-section-list-count>
		</div>

		<div class="sub-head-wrap">
			<div class="sub-head-ico-wrap">
				<i class="far fa-refresh sub-head-ico" (click)="loadData()" title="Refresh Data"></i>
			</div>
			<div class="updated-wrap">{{ lastUpdated() }}</div>
		</div>
	</div>

	<div *ngIf="showTooltips" class="card card-body tooltip-block tooltip-info-text tooltip-section-header">
		<button type="button" class="close" aria-label="Close" (click)="toggleTooltips()">
			<span aria-hidden="true">&times;</span>
		</button>
		<app-no-data-tip *ngIf="showNoDataTip()"></app-no-data-tip>
		<strong class="tooltip-step"
			>If your employees are 100% mobile, and you don’t require hours worked broken down by job, and you do not plan to use the call-out reminders,
			there is no need to create any job sites. The system will track employee hours against a built-in “catch-all” job site and job.</strong
		><br /><br />
		<strong>For those who need more sophisticated tracking, there are two types of job sites:</strong><br /><br />
		<ol>
			<li style="margin-bottom: 10px">
				Fixed location job sites with a street address. These can be linked to land lines, mobile phones, or both. If you have a fairly static set
				of locations where your employees work, you will want to enter them here. Be sure to include the street address if you want to take
				advantage of the system’s GPS location recording capability.
			</li>

			<li>
				Roaming job sites that are associated with the mobile phone number of just one employee. This is useful when employees don’t work at a
				fixed set of locations. For example, a plumber might check in at different homes every day. In that case, you would create a job site that
				has no address, and is linked to just that plumber’s mobile number. The job site is, in essence, wherever his mobile phone is at the time
				he checks in and out. The system will prompt for GPS location at check in and check out and record that information with the transaction
				record. If your workforce is entirely mobile like this example, please see the note above. You may not need to create any job sites or
				jobs at all.
			</li>
		</ol>
		Whether an employee calls in using a land line or a mobile phone, our system sees the caller ID of the line they are calling from, and matches
		it to one or more job sites. If there is more than one job site associated with the same mobile phone number, the system will prompt the
		employee to choose from a list of possible jobs. Each job site must be linked to at least one job. However, if no job is associated with a job
		site, the system will create one automatically.<br />
	</div>

	<app-job-site-table #sitesTable></app-job-site-table>
</div>
