import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { PhoneHelper, log } from '@app/helpers'
import {
	DialogManager,
	HelpDialogMessage,
	PhoneNumberRecord,
	PhoneNumberSkillLinkRecord,
	SkillRecord,
	UserRecord,
	UserSkillLinkRecord,
} from '@app/models'
import { Global } from '@app/models/global'
import { CoreService } from '@app/services'
import { SelectItem } from 'primeng/api'

@Component({
    selector: 'app-call-center-manage-skills',
    templateUrl: './call-center-manage-skills.component.html',
    styleUrl: './call-center-manage-skills.component.scss',
    standalone: false
})
export class CallCenterManageSkillsComponent implements OnInit, AfterContentInit {
	isLoading = true
	isEditing = false

	@Input() dialogManager: DialogManager
	@Output() endManagingSkills = new EventEmitter<boolean>()

	public skills: Array<SkillCard> = []
	public editAction = { recordId: null }

	public priorityOptions = []
	public alwaysRouteOptions = [
		{ label: 'Yes', value: true },
		{ label: 'No', value: false },
	]

	constructor(private coreSrvc: CoreService) {
		this.priorityOptions = [null, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => ({ label: i ? i.toString() : 'None', value: i }))
	}

	ngOnInit(): void {
		this.loadData()
	}
	ngAfterContentInit(): void {
		this.setupDialogManager()
	}

	private setupDialogManager() {
		this.dialogManager.submitBtnAction = () => this.createRecord()
	}

	private loadData() {
		this.coreSrvc.dbSrvc.readTable('skill').then((success) => {
			const skills = this.coreSrvc.dbSrvc.callCenterSrvc.getSkillRecords()
			for (const skill of skills) {
				const card = this.skills.find((s) => s.skill.id === skill.id)
				if (card) {
					card.updateSkill(skill)
				} else {
					this.skills.push(new SkillCard(skill))
				}
			}

			// this.skills = this.coreSrvc.dbSrvc.callCenterSrvc.getSkillRecords().map((rec) => new SkillCard(rec))
			this.isLoading = false
		})
	}

	createRecord() {
		log('Create Record')
		const card = new SkillCard(new SkillRecord())
		this.editRecord(card)
	}

	editRecord(card: SkillCard) {
		const recordId = card.skill.id
		this.editAction.recordId = recordId
		this.dialogManager.pushState()
		this.dialogManager.backBtnAction = () => this.endEditing()
		this.dialogManager.submitBtnLabel = recordId ? 'Update' : 'Save'
		this.isEditing = true
	}

	endEditing() {
		log('End Editing')
		this.dialogManager.popStateAndApply()
		this.isEditing = false
		this.loadData()
	}

	deleteRecord(card: SkillCard) {
		this.coreSrvc.dbSrvc.deleteRecord('skill', card.skill.id).then((success) => {
			if (success) {
				this.coreSrvc.dbSrvc.callCenterSrvc.removeLocalSkillRecord(card.skill.id)
				this.skills = this.skills.filter((c) => c.skill.id !== card.skill.id)
				this.loadData()
			}
		})
	}

	public formatPhoneNumberForId(id: number) {
		const pn = this.coreSrvc.dbSrvc.settingSrvc.getCallInPhoneNumberRecords().find((pn) => pn.id === id)?.number_e164
		return pn ? PhoneHelper.formatPhoneFromE164(pn) : 'Number Missing'
	}

	public formatUserNameForId(id: number) {
		const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(id)
		return user ? user.first_name + ' ' + user.last_name : 'User Missing'
	}

	public setPriority(link: UserSkillLinkRecord) {
		log('Set Priority', link)
		this.updateUserLinkRecord(link)
	}

	public setAlwaysRoute(link: UserSkillLinkRecord) {
		log('Set Always Route', link)
		this.updateUserLinkRecord(link)
	}

	public updateUserLinkRecord(link: UserSkillLinkRecord) {
		this.coreSrvc.dbSrvc.updateRecord('users_skill', link).then((success) => {
			log('Record updated', success)
		})
	}

	public removeSkill(card: SkillCard) {
		if (card.skill.locked) {
			this.coreSrvc.notifySrvc.notify('error', 'Not Allowed', 'This skill is locked and cannot be deleted.')
			return
		}
		card.showDeleteConfirmation = true
	}

	public removeAgent(card: SkillCard, user: UserRecord) {
		const updateSkill = new SkillRecord(card.skill)
		updateSkill.user_ids = updateSkill.user_ids.filter((id) => id !== user.id)
		this.coreSrvc.dbSrvc.updateRecord('skill', updateSkill).then((success) => {
			this.loadData()
		})
	}

	public addAgent(card: SkillCard, agent: UserRecord) {
		const userId = agent.id
		const updateSkill = new SkillRecord(card.skill)
		updateSkill.user_ids.push(userId)
		this.coreSrvc.dbSrvc.updateRecord('skill', updateSkill).then((success) => {
			this.loadData()
		})
	}

	public formatPhoneNumberLabel(phoneNumId: number) {
		const pn = this.coreSrvc.dbSrvc.settingSrvc.getPhoneNumberRecordById(phoneNumId)
		return this.coreSrvc.dbSrvc.settingSrvc.getPhoneNumberLabel(pn)
	}

	public removeNumber(card: SkillCard, pn: PhoneNumberRecord) {
		log('Remove Number Link', card, pn)
		const phoneId = pn.id
		const updateSkill = new SkillRecord(card.skill)
		updateSkill.phone_number_ids = card.skill.phone_number_ids.filter((id) => id !== phoneId)
		this.coreSrvc.dbSrvc.updateRecord('skill', updateSkill).then((success) => {
			this.loadData()
		})
	}

	public addNumber(card: SkillCard, number: PhoneNumberRecord) {
		const updateSkill = new SkillRecord(card.skill)
		updateSkill.phone_number_ids.push(number.id)
		this.coreSrvc.dbSrvc.updateRecord('skill', updateSkill).then((success) => {
			this.loadData()
		})
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'priority':
				help.header = 'Priority'
				help.message = `The highest priority is 1 and 10 is the lowest. When set to <b>None</b>, always ring this agent, regardless of other agent's priority settings.`
				break
			case 'always_route':
				help.header = 'Always Route'
				help.message = `When <b>Yes</b>, ring this agent even if they have an active call in progress (either inbound or C2C outbound).<br /><br />When <b>No</b> (the default), ring this agent only if they are not on an active call.`
				break
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}

class SkillCard {
	skill: SkillRecord
	showDeleteConfirmation = false

	expander = { showLinkedAgents: false, showUnlinkedAgents: false, showLinkedNumbers: false, showUnlinkedNumbers: false }

	phoneNumbers: Array<SkillCardPhoneNumberLinkWrapper> = []
	users: Array<SkillCardUserLinkWrapper> = []

	constructor(skill: SkillRecord) {
		this.updateSkill(skill)
	}
	get isSupMissingPriority(): boolean {
		for (const user of this.skill.users_skill) {
			if (user.priority === null) return true
		}
		return false
	}

	updateSkill(skill: SkillRecord) {
		this.skill = skill

		// Setup phone numbers
		const phoneList = []
		const phoneNumbers = Global.coreSrvc.dbSrvc.settingSrvc.getCallInPhoneNumberRecords().filter((pn) => pn.route_application !== 'TTS_PROD')
		for (const pn of phoneNumbers) {
			const pnLink = this.skill.phone_number_skill.find((n) => n.phone_number_id === pn.id)
			phoneList.push(new SkillCardPhoneNumberLinkWrapper(pn, pnLink))
		}
		this.phoneNumbers = phoneList

		// Setup users
		const userList = []
		const users = Global.coreSrvc.dbSrvc.settingSrvc.getUsers().filter((u) => u.role !== 'NO_COGNITO')
		for (const user of users) {
			const userLink = this.skill.users_skill.find((u) => u.user_id === user.id)
			userList.push(new SkillCardUserLinkWrapper(user, userLink))
		}
		this.users = userList
	}

	isPhoneNumberLinked(pn: PhoneNumberRecord) {
		return this.skill.phone_number_skill.find((link) => link.phone_number_id === pn.id)
	}

	isUserLinked(user: UserRecord) {
		return this.skill.users_skill.find((link) => link.user_id === user.id)
	}
}

class SkillCardPhoneNumberLinkWrapper {
	pn: PhoneNumberRecord
	link: PhoneNumberSkillLinkRecord

	constructor(pn: PhoneNumberRecord, link: PhoneNumberSkillLinkRecord) {
		this.pn = pn
		this.link = link
	}
}

class SkillCardUserLinkWrapper {
	user: UserRecord
	link: UserSkillLinkRecord

	constructor(user: UserRecord, link: UserSkillLinkRecord) {
		this.user = user
		this.link = link
	}
}
