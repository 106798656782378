<div [id]="'FEL_' + componentId" style="text-align: left">
	<div [id]="'FE_' + componentId" class="options-expand-header" (click)="toggleExpansion()" [class.options-expand-error]="hasError" [style]="style">
		<div class="float-right options-expand-chevron">
			<div
				*ngIf="counter || forceCounter"
				class="badge fe-badge"
				[style]="{ 'min-width': minCounterWidth, 'background-color': badgeColor, 'color': textColor }"
			>
				{{ counter }}
			</div>
			<i *ngIf="!isExpanded" class="fa fa-chevron-right fe-chevron"></i>
			<i *ngIf="isExpanded" class="fa fa-chevron-down fe-chevron hl-expanded"></i>
		</div>
		<div class="tts-hl-2">
			<div class="fe-label" [style]="labelStyle" [class.hl-expanded]="isExpanded">{{ label }}</div>
		</div>
	</div>

	<div *ngIf="isExpanded" [style]="contentStyle">
		<ng-content></ng-content>
	</div>
</div>
