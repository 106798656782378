<div class="container section-container">
	<div class="sec-head-wrap">
		<div *ngIf="showJobsTable()" class="sec-act-ico-wrap">
			<i
				*ngIf="requirementsMet()"
				class="fas fa-plus sec-act-ico"
				[class.section-control-disabled]="!canPerformAction(CrudAction.create)"
				(click)="createRecord()"
				title="Add Job"
			></i>
			<i class="far fa-cog sec-act-ico" (click)="togglePrefsPanel()" title="Preferences"></i>
			<i class="far fa-question-circle sec-act-ico" (click)="toggleTooltips()" title="Show Help"></i>
		</div>

		<div class="sec-title-wrap">
			<i class="fal fa-tasks-alt sec-head-ico" aria-hidden="true"></i>
			<span>Jobs</span>
			<app-sub-section-title [isVisible]="true" [subSectionTitle]="subSectionTitle"></app-sub-section-title>
			<app-section-list-count [manager]="listCountManager"></app-section-list-count>
		</div>

		<div class="sub-head-wrap">
			<div class="sub-head-ico-wrap">
				<i
					class="fal fa-cubes sub-head-ico"
					[class.sub-head-ico-highlight]="batchManager.isActive"
					(click)="batchManager.toggleActive()"
					title="Batch Mode"
				></i>
				<i class="far fa-refresh sub-head-ico" (click)="reloadTable()" title="Refresh Data"></i>
			</div>
			<div class="updated-wrap">
				{{ lastUpdated() }}
			</div>
		</div>
	</div>

	<div>
		<app-section-switcher [config]="switcherConfig" [type]="'NOCOUNT'" [maxWidth]="'240px'" (tabClicked)="sectionTabClicked($event)">
		</app-section-switcher>
	</div>

	<div *ngIf="filter" style="margin-top: 10px; color: chocolate">
		Showing jobs for <strong>{{ filterNote }}</strong>
	</div>

	<div *ngIf="showTooltips" class="card card-body tooltip-block tooltip-info-text tooltip-section-header">
		<button type="button" class="close" aria-label="Close" (click)="toggleTooltips()">
			<span aria-hidden="true">&times;</span>
		</button>
		<app-no-data-tip *ngIf="showNoDataTip()"></app-no-data-tip>

		<div [class.j-help-pad]="!isJobSiteMerged" style="margin-top: 25px">{{ defaultHelpInfo }}</div>

		<strong class="tooltip-step">Note:</strong> The system has a catch-all job called <strong>UNASSIGNED JOB</strong> which is not displayed in this
		list. You will see time entries and reports reference this special job whenever an employee checks in and the system is not able to infer which
		job they are checking into or the employee does not select a specific job. You may manually adjust an employee's check in/out information in the
		Time Entries section if needed.<br /><br />
		When a job allows employees to register their break start and stop times they wil be marked as
		<span style="color: #555">Set by <i class="fa fa-user" aria-hidden="true"></i></span> in the <strong>Break</strong> column.
	</div>

	<div *ngIf="!requirementsMet()" class="alert alert-warning section-alert" style="margin-top: 15px" role="alert">
		The following items need to be setup before you can configure a job.
	</div>

	<div *ngIf="!jobSitesExist() && !isJobSiteMerged" class="card card-body section-requirement">
		<p class="card-text">
			<span class="section-req-title"><i class="fa fa-map-marker" aria-hidden="true"></i> Job Sites</span>
			<a [routerLink]="['/admin/jobsites']" class="btn btn-success btn-sm btn-req-setup float-right">setup</a>
		</p>
	</div>

	<app-job-table #jobTable *ngIf="showJobsTable()" [filter]="filter"></app-job-table>
</div>
