import { Component, Input, OnInit } from '@angular/core'

import { DataAccessRequest, TransactionException } from '@app/models'
import { DatabaseService } from '@app/services'

import { SelectItem } from 'primeng/api'

import { log, DisplayHelper, DateTimeHelper } from '@app/helpers'
import moment from 'moment-timezone'

@Component({
    selector: 'app-exceptions-transaction',
    templateUrl: './exceptions-transaction.component.html',
    styleUrls: ['./exceptions-transaction.component.scss'],
    standalone: false
})
export class ExceptionsTransactionComponent implements OnInit {
	@Input() trans: TransactionException
	jobsDropdown: SelectItem[]
	visible = true
	infoBoxId: string
	idx: number // Used for index during iteration in template

	showTimeWorked = false
	showTimeOngoing = false
	showTimeMissing = false
	timeWorkedString = ''

	private timeMissingLimit = 60 * 60 * 12

	constructor(private dbSrvc: DatabaseService) {
		this.jobsDropdown = this.dbSrvc.jobSrvc.getDropdownData()
	}

	ngOnInit() {
		this.trans.job_id = null
		this.infoBoxId = 'transactionInfoBox_' + this.trans.id
		if (this.trans.called_from_cell) {
			this.jobsDropdown.push({ label: 'Auto-create a new anytime job', value: 0 })
		} else {
			this.jobsDropdown.pop()
		}
		this.setupTimeWorkedField()
	}

	private setupTimeWorkedField(): void {
		const timeWorked = this.trans.time_worked
		const tz = this.trans.timezone
		const startMom = moment.tz(this.trans.actual_start, 'YYYY-MM-DD HH:mm', tz).toDate().getTime()
		const nowMom = moment.tz(tz).toDate().getTime()
		const openDuration = (nowMom - startMom) / 1000

		if (!timeWorked) {
			if (openDuration < this.timeMissingLimit) {
				this.timeWorkedString = 'Ongoing'
				this.showTimeOngoing = true
				return
			} else {
				this.timeWorkedString = 'Missing'
				this.showTimeMissing = true
				return
			}
		} else {
			const dur = moment.duration(timeWorked)
			this.showTimeWorked = true
			this.timeWorkedString = DateTimeHelper.formatDuration('H:mm', dur) + ' (hrs:min)'
		}
	}

	callerIdInformation(): string {
		const trans = this.trans
		const callerIdName = trans.caller_id_name
		const callerIdNumber = trans.caller_id_number_e164
		if (!callerIdName || callerIdName === 'error' || callerIdName === '') {
			return DisplayHelper.displayPhone(callerIdNumber)
		} else {
			return callerIdName + ' / ' + DisplayHelper.displayPhone(callerIdNumber)
		}
	}

	hasFooter(): boolean {
		const trans = this.trans
		if (trans) {
			return !!trans.notes || trans.called_from_cell || trans.not_scheduled
		}
		return false
	}

	isValid(): boolean {
		if (this.trans.job_id || this.trans.job_id === 0) {
			return true
		}
		return false
	}

	accept(): boolean {
		const request = new DataAccessRequest('none', 'transaction_exception_commit', null, this.trans)
		this.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			log('Accept Result', result)
			this.processException()
		})
		return false
	}

	reject(): boolean {
		const request = new DataAccessRequest('none', 'transaction_exception_delete', null, this.trans)
		this.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			log('Accept Result', result)
			this.processException()
		})
		return false
	}

	private processException() {
		this.visible = false
		this.dbSrvc.bulkRead(['employee', 'location', 'job']).then((success) => {
			if (success) {
				this.dbSrvc.excptSrvc.removeTransactionById(this.trans.id)
			}
		})
	}
}
