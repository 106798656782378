import { Component, Input, OnDestroy, AfterViewInit, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { JobSiteRecord, CrudAction, DialogManager, LocalPrefsData, LocalPrefsGroup, LocalPrefsDialogData, ComponentBridgeName } from '@app/models'
import { CoreService, PrefsService } from '@app/services'
import { log, DisplayHelper, TableActionFormatter, SiteTableFormatter, GeneralTableHelper, DataTablesHelper } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'

import _ from 'lodash'
import { Subscription } from 'rxjs'

enum JobSiteTableColumnIndex {
	description = 0,
	supervisor,
	supervisorGroup,
	manager,
	// client,
	details,
	linkedNumbers,
	district,
	address,
	map,
	timezone,
	syncLock,
	actions,
}

@Component({
    selector: 'app-job-site-table',
    templateUrl: './job-site-table.component.html',
    styleUrls: ['./job-site-table.component.scss'],
    standalone: false
})
export class JobSiteTableComponent implements OnInit, OnDestroy, AfterViewInit {
	bridgeName: ComponentBridgeName = 'ngBridgeJobsiteTable'

	accessHelper: AccessHelper
	canAccessAuditLog = false

	hasClients = false
	isManagerRoleAvailable = false
	showModalDialog = false
	currentDisplayType: string

	@Input() list: JobSiteRecord[] = []
	editAction = { recordId: null, action: 'edit', header: '', footer: '', showEditDialog: false }
	siteNumbers = { recordId: null, showDialog: false }

	private jobSitesTable // : DataTables.DataTable;
	public sectionPrefsDialogManager = new DialogManager('sectionPrefsDialog')

	debounceRedrawTable = _.debounce(this.redrawTable, 300)

	private subs = new Subscription()

	constructor(
		private router: Router,
		private coreSrvc: CoreService,
		private prefsService: PrefsService,
	) {
		this.checkForClients()
		this.setupSubscriptions()
		this.setupAccessPermissions()
		this.setupLocalPrefsDialog()
		this.loadData()

		// Check for managing supervisor role availability
		this.isManagerRoleAvailable = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany().userEnableManagerRole

		// Setup list count manager
		this.coreSrvc.dbSrvc.siteSrvc.listCountManager.listCount = () => this.list.length
	}

	get dtFilterText(): string {
		const input = $('.search-field-input')
		return (input?.val() as string) || ''
	}

	ngOnInit() {
		window[this.bridgeName] = this
	}

	ngOnDestroy() {
		this.jobSitesTable['fixedHeader'].disable()
		$('.tooltip').remove() // Remove Bootstrap Tooltips
		window[this.bridgeName] = null
		this.subs.unsubscribe()
	}

	ngAfterViewInit() {
		this.initTable()

		// position the clear search button
		$('#jobSitesTable_filter input').attr('placeholder', ' filter')
		$('#jobSitesTable_filter input').addClass('search-field-input')

		setTimeout(() => {
			$('#clear-search-icon').detach().appendTo('#jobSitesTable_filter label')
		})

		// Handle count bubble search highlighting
		$('#jobSitesTable_filter input').on('keyup', () => {
			this.debounceRedrawTable()
		})

		// Add row details listener for tooltips
		this.jobSitesTable.on('responsive-display', (e, datatable, row, showHide, update) => {
			// log('Details for row ' + row.index() + ' ' + (showHide ? 'shown' : 'hidden'))
			const itemTooltip: any = $('.item-tooltip')
			itemTooltip.tooltip({ show: { effect: 'none', delay: 0 } })
		})
	}

	private setupSubscriptions() {
		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	private handleScreenSizeChanges() {
		if (this.jobSitesTable) {
			this.jobSitesTable.columns.adjust().responsive.recalc()
			this.jobSitesTable.fixedHeader.adjust()
		}
	}

	checkForClients() {
		const clients = this.coreSrvc.dbSrvc.orgSrvc.getOrganizations('CLIENT')
		if (clients.length > 0) {
			log('Clients found')
			this.hasClients = true
		}
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'site')
		const auditPerms = this.accessHelper.getPermissionsFor('audit')
		this.canAccessAuditLog = auditPerms.access.read || auditPerms.owner.read
	}

	private setupLocalPrefsDialog(): void {
		this.sectionPrefsDialogManager.headerLabel = 'Preferences'
		const columnVisibilityItems = LocalPrefsData.getJobSiteTableColumnDisplayPrefs()
		const columnVisibilityGroup = new LocalPrefsGroup('Column Visibility', columnVisibilityItems)
		if (!this.hasClients) {
			log('Hiding clients')
			columnVisibilityGroup.hiddenPrefKeys.push('jobSiteColClientVisible')
		}
		if (!this.coreSrvc.dbSrvc.siteSrvc.hasSyncLockRecords()) {
			columnVisibilityGroup.hiddenPrefKeys.push('jobSiteColSyncLockVisible')
		}
		const dialogData = new LocalPrefsDialogData([columnVisibilityGroup])
		dialogData.localStorageKeyRemovalList = ['DataTables_jobSitesTable']
		this.sectionPrefsDialogManager.dialogData = dialogData
	}

	public prefsDataSaved(): void {
		log('UpdatePrefsData')
		this.sectionPrefsDialogManager.isDialogVisible = false
		this.updateTable()
	}

	canPerformAction(action: CrudAction, isMyRecord: boolean): boolean {
		return this.accessHelper.canPerformAction(action, isMyRecord)
	}

	enableAllTooltips() {
		const itemTooltip: any = $('.item-tooltip')
		itemTooltip.tooltip({ show: { effect: 'none', delay: 0 } })
	}

	clearSearch(): boolean {
		this.jobSitesTable
			.search('')
			.order([[0, 'asc']])
			.draw()
		this.redrawTable()
		return false
	}

	saveActionComplete(event) {
		log('Emp Table Save Action Complete', event)
		this.editAction.showEditDialog = false
		this.updateTable()
	}

	editActionCancelled() {
		log('Emp Table Cancel Action Complete')
		this.editAction.showEditDialog = false
	}

	createRecord() {
		if (!this.canPerformAction(CrudAction.create, null)) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.editAction.recordId = null
		this.editAction.action = 'new'
		this.editAction.header = 'Add Job Site'
		this.editAction.footer = 'Create a new job site'
		this.editAction.showEditDialog = true
	}

	editRecord(id: number) {
		const isMyRecord = this.accessHelper.isMyRecord(id, 'site')
		if (!this.canPerformAction(CrudAction.update, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}
		const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(id)
		if (site) {
			this.editAction.recordId = id
			this.editAction.action = 'edit'
			this.editAction.header = 'Edit Job Site'
			this.editAction.footer = site.description
			this.editAction.showEditDialog = true
		}
	}

	deleteRecord(id: number) {
		const isMyRecord = this.accessHelper.isMyRecord(id, 'site')
		const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(id)
		if (site?.sync_lock) {
			this.coreSrvc.notifySrvc.notify('error', 'Not Allowed', 'This record is managed by a 3rd party integration and cannot be deleted.')
			return
		}
		if (!this.canPerformAction(CrudAction.delete, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.router.navigate([`/admin/jobsites/delete/${id}`])
	}

	showLinkedNumbers(recordId: number) {
		this.siteNumbers.recordId = recordId
		this.siteNumbers.showDialog = true
	}

	// private makeNumbersButton(record: JobSiteRecord): string {

	// 	if (!record.phone_number_regex_e164) { return 'Missing Number' }
	// 	const phoneArray = record.phone_number_regex_e164.split(',').filter(num => num !== '+18888888888')
	// 	const numbers = phoneArray.map(num => this.coreSrvc.dbSrvc.empSrvc.getEmployeeNameForPhoneNumberE164(num))
	// 	const jobSiteId = record.id

	// 	return numbers.length > 0 ? `<button onclick="${this.bridgeTableName}.showLinkedNumbers(${jobSiteId})" class="btn btn-sm btn-default table-modal-btn" style="width: 105px">${numbers.length === 1 ? '1 number' : numbers.length + ' numbers'}</button>` : ''
	// }

	// private makeShowMapIcon(record: JobSiteRecord) {
	// 	if (!record.address) { return '' }
	// 	return `<i class="fa fa-map link-text" style="margin-right:4px;" title="Show Map" onclick="ngBridgeJobsiteTable.gotoAddress(${record.id})"></i>`
	// }

	public gotoAddress(id: number) {
		const record = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(id)
		const url = `https://maps.google.com/?q=${record.address_formatted}`
		const target = '_address' // this.coreSrvc.dbSrvc.devDetect.isDesktop() ? 'sitemap' : '_blank'
		window.open(url, target)
	}

	reloadTable() {
		$('.tooltip').remove()
		this.coreSrvc.dbSrvc.readTable('location').then((success) => {
			this.coreSrvc.dbSrvc.bulkRead(['supervisor_group']).then((bulkSuccess) => {
				this.updateTable()
			})
		})
	}

	loadData() {
		this.list = this.accessHelper.getJobSiteTableList()
		this.coreSrvc.dbSrvc.siteSrvc.listCountManager.isReady = true
	}

	public redrawTable() {
		this.jobSitesTable?.rows().invalidate().search(this.dtFilterText).draw(true)
		// this.enableAllTooltips()
	}

	updateTable() {
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadData()
			this.updateColumns()
		})

		// $(window).trigger('resize')
	}

	public pendingAddressRefresh() {
		this.coreSrvc.zone.run(() => {
			this.reloadTable()
		})
	}

	updateColumns() {
		if (!this.jobSitesTable) return
		const supervisor = this.coreSrvc.prefSrvc.data.jobSiteColSupervisorVisible
		const supervisorGroup = this.coreSrvc.prefSrvc.data.jobSiteColSupervisorGroupVisible
		const manager = this.coreSrvc.prefSrvc.data.jobSiteColManagerVisible
		const client = this.hasClients && this.coreSrvc.prefSrvc.data.jobSiteColClientVisible
		const details = this.coreSrvc.prefSrvc.data.jobSiteColDetailsVisible
		const linkedNumbers = this.coreSrvc.prefSrvc.data.jobSiteColNumberVisible
		const address = this.coreSrvc.prefSrvc.data.jobSiteColAddressVisible
		const district = this.coreSrvc.prefSrvc.data.jobSiteColDistrictVisible
		const map = this.coreSrvc.prefSrvc.data.jobSiteColMapVisible
		const timezone = this.coreSrvc.prefSrvc.data.jobSiteColTimezoneVisible
		const syncLock = this.coreSrvc.prefSrvc.data.jobSiteColSyncLockVisible && this.coreSrvc.dbSrvc.siteSrvc.hasSyncLockRecords()

		// Set column visibility
		GeneralTableHelper.updateColumn(this.jobSitesTable, JobSiteTableColumnIndex.supervisor, supervisor)
		GeneralTableHelper.updateColumn(this.jobSitesTable, JobSiteTableColumnIndex.supervisorGroup, supervisorGroup)
		GeneralTableHelper.updateColumn(this.jobSitesTable, JobSiteTableColumnIndex.manager, manager)
		// GeneralTableHelper.updateColumn(this.jobSitesTable, JobSiteTableColumnIndex.client, client)
		GeneralTableHelper.updateColumn(this.jobSitesTable, JobSiteTableColumnIndex.details, details)
		GeneralTableHelper.updateColumn(this.jobSitesTable, JobSiteTableColumnIndex.linkedNumbers, linkedNumbers)
		GeneralTableHelper.updateColumn(this.jobSitesTable, JobSiteTableColumnIndex.address, address)
		GeneralTableHelper.updateColumn(this.jobSitesTable, JobSiteTableColumnIndex.district, district)
		GeneralTableHelper.updateColumn(this.jobSitesTable, JobSiteTableColumnIndex.map, map)
		GeneralTableHelper.updateColumn(this.jobSitesTable, JobSiteTableColumnIndex.timezone, timezone)
		GeneralTableHelper.updateColumn(this.jobSitesTable, JobSiteTableColumnIndex.syncLock, syncLock)

		this.jobSitesTable.clear()
		this.jobSitesTable.rows.add(this.list)
		this.jobSitesTable.draw(false)
		// this.jobSitesTable.columns.adjust().responsive.recalc() // Moved to drawCallback
	}

	// private enableTooltips() {
	// 	const tooltips: any = $('[data-toggle="tooltip"]')
	// 	tooltips.tooltip()
	// }

	notifyOperationNotAuthorized() {
		this.coreSrvc.notifySrvc.default('operationNotAuthorized')
	}

	private isTemplateSite(site: JobSiteRecord) {
		return site.description === 'W2W-TEMPLATE'
	}

	private initTable() {
		// const showSupColumn = (this.settingsService.getUsers().length > 1) && this.prefsService.data.jobSiteColSupervisorVisible ? true : false;
		this.coreSrvc.displaySrvc.startSectionLoader()
		this.jobSitesTable = $('#jobSitesTable').DataTable(<DataTables.Settings>{
			stateSave: false,
			responsive: true,
			processing: true,
			deferRender: true,
			paging: true,
			pageLength: 50,
			lengthChange: true,
			info: true,
			select: false,
			searching: true,
			fixedHeader: DataTablesHelper.fixedHeader,
			autoWidth: false,
			data: this.list,
			order: [[JobSiteTableColumnIndex.description, 'asc']],
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{ responsivePriority: 1, targets: JobSiteTableColumnIndex.description },
				{ responsivePriority: 2, targets: JobSiteTableColumnIndex.actions },
				{
					responsivePriority: 3,
					targets: [
						JobSiteTableColumnIndex.linkedNumbers,
						JobSiteTableColumnIndex.district,
						JobSiteTableColumnIndex.address,
						JobSiteTableColumnIndex.timezone,
					],
				},
				{
					// Description
					targets: JobSiteTableColumnIndex.description,
					visible: true,
					searchable: true,
					title: 'Job Site Name',
					data: null,
					render: (site: JobSiteRecord, t, f, m) => {
						const name = DisplayHelper.truncateForWrapDisplay(site.description)
						const sortName = this.isTemplateSite(site) ? `~${name}` : name
						const sortHtml = `<div style="display:none">${sortName}</div>`
						return `
						<div class="dtr-control-content">
							${sortHtml}<div style="max-width: 255px; line-height: 1.2em; white-space: normal">${name}</div>
						</div>`
					},
				},
				{
					// Supervisor
					targets: JobSiteTableColumnIndex.supervisor,
					visible: this.prefsService.data.jobSiteColSupervisorVisible,
					searchable: true,
					title: 'Supervisor',
					data: null,
					render: (site: JobSiteRecord, t, f, m) => {
						// return this.coreSrvc.dbSrvc.settingSrvc.getUsernameForUserId(d)
						const supName = this.coreSrvc.dbSrvc.settingSrvc.getUsernameForUserId(site.supervisor) || ''
						const supHtml = `<div>${supName}</div>`
						// const groupId = site.supervisor_group_id
						// const groupName = groupId ? this.coreSrvc.dbSrvc.settingSrvc.getUserGroupById(groupId)?.description : ''
						// const groupHtml = groupName ? `<div style="color:#537792;font-size:.8em;font-weight:bold;font-style:italic;">${groupName}</div>` : ''
						return `${supHtml}`
					},
				},
				{
					// Supervisor Group
					targets: JobSiteTableColumnIndex.supervisorGroup,
					visible: this.prefsService.data.jobSiteColSupervisorGroupVisible,
					searchable: true,
					title: 'Group',
					data: null,
					render: (site: JobSiteRecord, t, f, m) => {
						const groupId = site.supervisor_group_id
						const groupName = groupId ? this.coreSrvc.dbSrvc.settingSrvc.getUserGroupById(groupId)?.description : ''
						const groupHtml = groupName ? `<div>${groupName}</div>` : ''
						return `${groupHtml}`
					},
				},
				{
					// Manager
					targets: JobSiteTableColumnIndex.manager,
					visible: this.isManagerRoleAvailable && this.prefsService.data.jobSiteColManagerVisible,
					searchable: this.isManagerRoleAvailable && this.prefsService.data.jobSiteColManagerVisible,
					title: 'Manager',
					data: null,
					render: (js: JobSiteRecord, t, f, m) => {
						const supId = js.supervisor
						const sup = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(supId)
						if (sup) {
							const managerId = sup.managed_by
							if (managerId) {
								const manager = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(managerId)
								if (manager) {
									return manager.first_name + ' ' + manager.last_name
								}
							}
						}
						return ''
					},
				},
				// DEPRECATED SERVICE - Switch to org service to display client info
				// {
				// 	// Client / Customer
				// 	targets: JobSiteTableColumnIndex.client,
				// 	visible: this.hasClients && this.coreSrvc.prefSrvc.data.jobSiteColClientVisible,
				// 	searchable: true,
				// 	title: 'Client',
				// 	data: null,
				// 	render: (rec: JobSiteRecord, t, f, m) => {
				// 		const client = this.coreSrvc.dbSrvc.clientSrvc.getClientById(rec.client_id)
				// 		return client ? client.client_company_name : ''
				// 	},
				// },
				{
					// Details
					targets: JobSiteTableColumnIndex.details,
					visible: this.prefsService.data.jobSiteColDetailsVisible,
					searchable: true,
					title: 'Details',
					data: null,
					render: (site: JobSiteRecord, t, f, m) => {
						const details = site.location_details ? site.location_details : ''
						// const chkptsRequirePhoto = site.chkpts_require_photo
						// const photoIcon = chkptsRequirePhoto ? `<i title="Checkpoints require photo" class="fa fa-picture-o item-tooltip" style="color:chocolate;"></i> ` : ''
						return `<div style="line-height: 1.2em; white-space: normal">${details}</div>`
					},
				},
				{
					// Linked
					targets: JobSiteTableColumnIndex.linkedNumbers,
					visible: this.prefsService.data.jobSiteColNumberVisible,
					searchable: this.prefsService.data.jobSiteColNumberVisible,
					title: `Linked #'s`,
					data: null,
					render: (site: JobSiteRecord, t, f, m) => {
						return SiteTableFormatter.makeLinkedNumbers(this.coreSrvc.dbSrvc, this.bridgeName, this.dtFilterText, site, false)
					},
				},
				{
					// Address
					targets: JobSiteTableColumnIndex.address,
					visible: this.prefsService.data.jobSiteColAddressVisible,
					searchable: this.prefsService.data.jobSiteColAddressVisible,
					title: 'Address',
					data: null,
					render: (site: JobSiteRecord, t, f, m) => {
						return SiteTableFormatter.makeAddress(site, this.bridgeName)
					},
				},
				{
					// District
					targets: JobSiteTableColumnIndex.district,
					visible: this.prefsService.data.jobSiteColDistrictVisible,
					searchable: this.prefsService.data.jobSiteColDistrictVisible,
					title: this.coreSrvc.dbSrvc.settingSrvc.getDistrictLabel(),
					data: null,
					render: (site: JobSiteRecord, t, f, m) => {
						return site?.district ?? ''
					},
				},
				{
					// Map
					targets: JobSiteTableColumnIndex.map,
					visible: this.coreSrvc.prefSrvc.data.jobSiteColMapVisible,
					searchable: this.coreSrvc.prefSrvc.data.jobSiteColMapVisible,
					title: 'Map',
					data: null,
					render: (site: JobSiteRecord, t, f, m) => {
						return SiteTableFormatter.makeMapMarkerIcon(site)
					},
				},
				{
					// Timezone
					targets: JobSiteTableColumnIndex.timezone,
					visible: this.prefsService.data.jobSiteColTimezoneVisible,
					searchable: this.prefsService.data.jobSiteColTimezoneVisible,
					title: 'Timezone',
					data: null,
					render: (site: JobSiteRecord, t, f, m) => {
						return this.coreSrvc.dbSrvc.settingSrvc.getTimezoneDisplayNameForId(site.timezone_id)
					},
				},
				{
					// Sync Lock
					targets: JobSiteTableColumnIndex.syncLock,
					visible: this.coreSrvc.prefSrvc.data.jobSiteColSyncLockVisible && this.coreSrvc.dbSrvc.siteSrvc.hasSyncLockRecords(),
					searchable: false,
					title: 'Lock',
					data: null,
					render: (rec: JobSiteRecord, t, f, m) => {
						if (rec.sync_lock) {
							return `<span style="display: none;">0</span><i class="far fa-lock table-ico-sync-lock item-tooltip" title="Integration Lock"></i>`
						} else {
							return ``
						}
					},
				},
				{
					// Actions
					targets: JobSiteTableColumnIndex.actions,
					visible: true,
					searchable: false,
					orderable: false,
					title: 'Actions',
					data: null,
					className: 'row-actions',
					render: (rec: JobSiteRecord, t, f, m) => {
						const recordId = rec.id
						const isMyRecord = this.accessHelper.isMyRecord(recordId, 'site')
						const isSyncLocked = rec.sync_lock
						const hasAuditLog = rec.updated ? true : false
						const hasLog = rec.updated ? true : false

						const canAccessEdit = this.canPerformAction(CrudAction.update, isMyRecord)
						const editLink = TableActionFormatter.editLink(this.bridgeName, 'editRecord', recordId, canAccessEdit)

						const canAccessDelete = this.canPerformAction(CrudAction.delete, isMyRecord) && !isSyncLocked
						const deleteLink = TableActionFormatter.deleteLink(this.bridgeName, 'deleteRecord', recordId, canAccessDelete)

						const auditLink = TableActionFormatter.auditLinkGlobal('location', recordId, rec.description, true, !!rec.updated)

						return `<span class="act-ico-wrap">${editLink}${deleteLink}${auditLink}</span>`
					},
				},
			],
			drawCallback: (settings) => {
				log('Datatables redraw')
				this.jobSitesTable?.columns.adjust().responsive.recalc()
				this.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
			},
		})
		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter('jobSitesTable', this.jobSitesTable)
	}
}

// http://stackoverflow.com/questions/35527456/angular2-window-resize-event
