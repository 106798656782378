import { Component, EventEmitter, Input, Output } from '@angular/core'
import { VizDashboardFetchRangeType, VizDashboardRangePicker, VisualizationViewManager } from '@app/models'
import { CoreService } from '@app/services'
import { SelectItem } from 'primeng/api'

@Component({
    selector: 'app-viz-dashboard-filter-bar',
    templateUrl: './viz-dashboard-filter-bar.component.html',
    styleUrl: './viz-dashboard-filter-bar.component.scss',
    standalone: false
})
export class VizDashboardFilterBarComponent {
	rangeOptions: Array<SelectItem> = VizDashboardRangePicker.dropdownOptions

	@Input() isDataLoaded: boolean
	@Output() updateBtnClicked = new EventEmitter<boolean>()

	constructor(private coreSrvc: CoreService) {
		this.handleRangeSelectionChanged()
	}
	get devDetect() {
		return this.coreSrvc.devDetect
	}

	get viewManager(): VisualizationViewManager {
		return this.coreSrvc.dbSrvc.vizSrvc.viewManager
	}

	public setDate(prop: 'startDate' | 'endDate', date: Date) {
		this.viewManager[prop] = date
	}

	public handleRangeSelectionChanged() {
		const rangeOption = this.viewManager.rangeSelected
		const dates = VizDashboardRangePicker.getRange(rangeOption, this.viewManager.startDate, this.viewManager.endDate)
		this.setDate('startDate', dates.startDate)
		this.setDate('endDate', dates.endDate)
		this.viewManager.save()
		if (this.viewManager.rangeSelected !== 'CUSTOM') this.updateBtnClicked.next(true)
	}

	public handleUpdateBtnClick() {
		this.viewManager.save()
		this.updateBtnClicked.next(true)
	}
}
