import { Pipe, PipeTransform } from '@angular/core'
import { log, DateTimeHelper } from '@app/helpers'

@Pipe({
    name: 'standardTimeFromDateString',
    standalone: false
})
export class StandardTimeFromDateStringPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		return DateTimeHelper.standardTimeFromDateString(value)
	}
}
