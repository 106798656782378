<div [hidden]="status.isUploading || status.isComplete" style="text-align: center; max-width: 100%; margin: 0 auto">
	<div *ngIf="imageFile" class="row" style="margin-bottom: 25px">
		<div class="col-6">
			<button class="btn btn-primary btn-block" [disabled]="currentView === 'EDIT'" (click)="currentView = 'EDIT'">Back</button>
		</div>
		<div class="col-6">
			<button class="btn btn-primary btn-block" [disabled]="currentView === 'VIEW'" (click)="currentView = 'VIEW'">Next</button>
		</div>
	</div>

	<div [hidden]="currentView === 'VIEW'">
		<div [class.pfp-image-cropper-wrap]="imageFile">
			<image-cropper
				output="base64"
				[imageFile]="imageFile"
				[maintainAspectRatio]="true"
				[containWithinAspectRatio]="containWithinAspectRatio"
				[aspectRatio]="1 / 1"
				[resizeToWidth]="300"
				[cropperMinWidth]="128"
				[onlyScaleDown]="true"
				[roundCropper]="false"
				[canvasRotation]="canvasRotation"
				[transform]="transform"
				[alignImage]="'center'"
				[style.display]="showCropper ? null : 'none'"
				format="png"
				(imageCropped)="imageCropped($event)"
				(imageLoaded)="imageLoaded()"
				(cropperReady)="cropperReady($event)"
				(loadImageFailed)="loadImageFailed()"
			></image-cropper>
		</div>

		<div *ngIf="imageFile" class="pfp-icons-wrap d-flex justify-content-between">
			<i class="fa fa-rotate-left profile-act-icon" (click)="rotateLeft()"></i>
			<i class="fa fa-rotate-right profile-act-icon" (click)="rotateRight()"></i>
			<i class="fa fa-minus profile-act-icon" (click)="zoomOut()"></i>
			<i class="fa fa-plus profile-act-icon" (click)="zoomIn()"></i>
		</div>
	</div>

	<div *ngIf="currentView === 'VIEW'" style="padding: 35px 0px; height: 310px">
		<div class="pfp-image-cropped-wrap">
			<img [src]="croppedImage" class="generic-profile-img" [style.border]="croppedImage ? '1px solid black' : 'none'" />
		</div>
	</div>

	<div *ngIf="currentView === 'EDIT' && !imageFile" class="pfp-input-wrap">
		<!-- <div class="section-title">Profile Photo</div> -->
		<div class="profile-image-wrap">
			<img *ngIf="pfpImageUrl" class="generic-profile-img" [src]="pfpImageUrl" (error)="handlePhotoLoadError()" />
		</div>
	</div>

	<div style="margin-top: 15px" *ngIf="!status.isComplete">
		<div *ngIf="currentView === 'EDIT'">
			<input type="file" name="file" id="file" class="input-file" accept="image/*" (change)="fileChangeEvent($event)" />
			<label for="file" class="outline-btn">Select Photo</label>
		</div>
		<!-- <button *ngIf="imageChangedEvent && currentView === 'VIEW'" class="btn btn-success btn-block" (click)="submitPhotoBtnClicked()">Submit</button> -->
		<!-- <button class="outline-btn" (click)="cancelBtnClicked()">Cancel</button> -->
	</div>

	<!-- PFP Details -->
	<div *ngIf="(currentView === 'EDIT' && !imageFile) || currentView === 'VIEW'" [class.crop-pad]="!imageFile">
		<div class="options-block" style="text-align: left">
			<div class="options-title-inline">Public Profile Details</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('pfp_details')"></i>
			<div>
				<textarea class="options-textarea" [(ngModel)]="emp.pfp_details" [placeholder]="'Enter public facing employee information'"></textarea>
			</div>
		</div>
	</div>
</div>

<div *ngIf="status.isUploading" class="container">
	<div class="pfp-uploading"><i class="fa fa-loader fa-spin"></i> Processing...</div>
	<!-- <div class="pfp-image-cropped-wrap">
		<img [src]="croppedImage" class="generic-profile-img" [style.border]="croppedImage ? '1px solid black' : 'none'" />
	</div> -->
</div>

<!-- <div *ngIf="status.isComplete" class="container" style="margin-top: 60px">
	<h2 class="section-title">Update Complete</h2>
	<div class="secondary-actions-block done-block">
		<div class="green-check-logo"><i class="far fa-circle-check"></i></div>
		<button class="outline-btn" (click)="doneBtnClicked()">Done</button>
	</div>
</div> -->

<div *ngIf="emp.pfp_json && !imageFile && !status.isUploading">
	<hr style="margin: 30px 0px" />
	<div style="text-align: left">
		<div class="section-title">
			Photo Status:
			<span [class.is-approved]="approvalStatusLabel === 'APPROVED'" [class.is-rejected]="approvalStatusLabel === 'REJECTED'">{{
				approvalStatusLabel
			}}</span>
		</div>
	</div>

	<div class="d-flex justify-content-evenly">
		<button class="btn btn-sm btn-block btn-danger status-btn" style="margin-right: 12px" (click)="setApprovalStatus('REJECTED')">Reject</button>
		<button class="btn btn-sm btn-block btn-success status-btn" style="margin-left: 12px" (click)="setApprovalStatus('APPROVED')">Approve</button>
	</div>
</div>

<!-- <div>{{ currentView }} / {{ !!imageChangedEvent }}</div> -->
