import { DateTimeHelper } from '@app/helpers/datetime'

export class JobSiteRecord {
	id: number = null
	caller_id: string = null
	chkpts_require_photo = false
	client_id: number = null
	company_default: boolean = null
	company_id: number = null
	created: string = null
	updated: string = null
	description: string = null
	location_details: string = null
	phone_number_regex: string = null
	phone_number_regex_e164: string = null
	supervisor: number = null
	supervisor_ids: Array<number> = []
	supervisor_group_id: number = null
	sync_lock: boolean
	timezone_id: number = null
	union_zone: number = null

	address: string = null

	address_1: string = null
	address_2: string = null
	city: string = null
	district: string = null
	postal_code: string = null
	address_formatted: string
	geo_coded_ts: string = null // timestamp without time zone
	requires_geo_coding: boolean = false // boolean NOT NULL DEFAULT false

	geo: string = null
	geo_code_reverse: boolean = null
	geo_coded: boolean = null
	geo_last_coding: string = null // no longer sent to client
	geo_coding_failed: boolean
	geo_latitude: number = null
	geo_longitude: number = null

	constructor(record?: JobSiteRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
		}
	}

	clone(): JobSiteRecord {
		return new JobSiteRecord(this)
	}
}
