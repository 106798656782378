<div class="container">
	<div class="np-title" *ngIf="!isUnderMaintenance">
		<h4 style="color: chocolate">
			We're sorry, we encountered an error trying to access your account. Please make sure your computer's clock is properly set and try again.
		</h4>
		<br />
		<button class="btn btn-danger" (click)="tryAgain()">Try Again</button>
	</div>
	<div *ngIf="isUnderMaintenance">
		<app-maintenance></app-maintenance>
	</div>
	<div class="np-message" style="margin-top: 20px; color: gray">
		If you continue to see this message, please contact support to resolve this issue.
		<br />
		<br />
		<strong>North American Support</strong>
		<br />
		<a href="tel:+1-888-250-8463">1-888-250-8463</a>
		<br />
		<br />
		<strong>European Support</strong>
		<br />
		<a href="tel:+44 0808 164 5656">+44 0808 164 5656</a>
	</div>
</div>
