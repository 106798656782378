<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<div class="alert alert-info alert-bg-gray">
		Use these settings to control who can edit and view this dashboard. The creator and owner have full permissions, while shared users can view the
		dashboard but are not allowed to make changes.
	</div>

	<form class="edit-form-block" novalidate [formGroup]="form" autocomplete="off">
		<div class="options-block" [class.input-required]="!form.get('name').value">
			<div class="options-title-inline">Dashboard Name</div>
			<div *ngIf="!form.get('name').value" class="float-right">(required)</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="name" />
		</div>

		<div class="options-block">
			<div class="options-title-inline">Dashboard Owner</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('supervisor')"></i>
			<p-select
				appScrollLockSelect
				id="supervisors"
				[options]="supervisorOptions"
				formControlName="supervisor"
				[filter]="true"
				filterBy="label"
			></p-select>
		</div>

		<div *ngIf="form.get('supervisor').value !== 0" class="checkbox-item d-flex justify-content-between" style="margin: 16px 0px">
			<div class="checkbox-wrap">
				<p-checkbox
					[(ngModel)]="shareWithAnySupervisor"
					[binary]="true"
					[ngModelOptions]="{ standalone: true }"
					(onChange)="updateShareWIthAnySupervisor()"
				></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="shareWithAnySupervisor = !shareWithAnySupervisor" class="has-pointer" (click)="updateShareWIthAnySupervisor()"
					>Share with all supervisors</strong
				>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('share_with_all')"></i>
			</div>
		</div>

		<div *ngIf="form.get('supervisor').value && showUserGroups && !shareWithAnySupervisor" class="options-block">
			<div class="options-title-inline">Share with Group</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('supervisor_group_id')"></i>
			<p-select
				appScrollLockSelect
				id="userGroupsDropdown"
				[options]="userGroupOptions"
				formControlName="supervisor_group_id"
				[filter]="true"
				filterBy="label"
			></p-select>
		</div>

		<div *ngIf="form.get('supervisor').value && !shareWithAnySupervisor" class="options-block">
			<div class="options-title-inline">Share with Supervisors</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('shared_supervisor_ids')"></i>
			<app-multi-select-details
				[type]="'USER'"
				placeholder="Select Supervisors"
				selectedItemsLabel="Supervisors Selected: {0}"
				[options]="supervisorsMultiSelect"
				[model]="form.get('shared_supervisor_ids').value"
				(modelChange)="form.get('shared_supervisor_ids').setValue($event)"
			>
			</app-multi-select-details>
		</div>

		<div *ngIf="dashboardCreatorName" class="options-block">
			<div class="options-title-inline">Created by</div>
			<div class="input-disabled">{{ dashboardCreatorName }}</div>
		</div>
	</form>
</div>

<!-- <div class="pre-code">{{ form.value | json }}</div> -->
