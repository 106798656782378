<div class="container section-container" style="width: 100%">
	<div class="sec-head-wrap">
		<div class="sec-act-ico-wrap">
			<i class="fas fa-plus sec-act-ico" [class.section-control-disabled]="!canCreateRecord()" (click)="createRecord()" title="Create Record"></i>
			<i class="far fa-question-circle sec-act-ico" title="Section Help" (click)="showSectionHelp()"></i>
		</div>
		<div class="sec-title-wrap">
			<i class="far fa-bullhorn sec-head-ico"></i>
			<span>Announcements</span>
			<app-sub-section-title [isVisible]="true" [subSectionTitle]="subSectionTitle"></app-sub-section-title>
		</div>
	</div>

	<div *ngIf="!isDataLoaded" class="data-loading"><i class="fas fa-loader fa-spin"></i>&nbsp;Loading Data...</div>

	<!-- isDataLoaded -->
	<div *ngIf="isDataLoaded">
		<div class="ann-tab-switcher">
			<app-section-switcher [config]="switcherConfig" [type]="'NOCOUNT'" [maxWidth]="'240px'" (tabClicked)="sectionTabClicked($event)">
			</app-section-switcher>
		</div>

		<div *ngIf="viewManager.currentView === 'CHECKIN'">
			<app-announcements-checkin-list #checkinListComponent></app-announcements-checkin-list>
		</div>

		<div *ngIf="viewManager.currentView === 'SCHEDULED'">
			<app-announcements-scheduled-list #scheduledListComponent></app-announcements-scheduled-list>
		</div>

		<div class="d-flex flex-column flex-md-row justify-content-between">
			<app-table-footer-buttons
				[marginTop]="'15px'"
				[newBtnLabel]="'Add Announcement'"
				(newBtnClicked)="createRecord()"
			></app-table-footer-buttons>
			<div *ngIf="languageList.length > 0" class="table-footer-block">
				<select class="form-select" [(ngModel)]="viewManager.displayLang" (change)="updateLanguage()">
					<option *ngFor="let lang of languageList" [value]="lang.value">{{ lang.label }}</option>
				</select>
			</div>
		</div>
	</div>
	<!-- isDataLoaded -->
</div>
