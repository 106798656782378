<div *ngIf="!isDataLoaded" class="loading-block">
	<div><i class="fas fa-loader fa-spin"></i>&nbsp;Loading checkpoints...</div>
</div>
<div *ngIf="isUpdatingCheckpoint" class="loading-block">
	<div><i class="fas fa-loader fa-spin"></i>&nbsp;Updating checkpoint...</div>
</div>
<div *ngIf="isDataLoaded" [hidden]="isUpdatingCheckpoint">
	<!-- Transaction Metadata -->
	<div class="cp-meta-box chkpt-box">
		<div class="row">
			<div class="col-xs-12 col-xl-6">
				<div *ngIf="!vm.isNoShow">
					<div>
						<div class="cp-meta-label cp-meta-label-1 tts-hl-1">Date:</div>
						{{ vm.jobDate }}
					</div>
					<div>
						<div class="cp-meta-label cp-meta-label-1 tts-hl-1">Start:</div>
						{{ vm.startTime }}
					</div>
					<div>
						<div class="cp-meta-label cp-meta-label-1 tts-hl-1">End:</div>
						{{ vm.endTime }}
					</div>
				</div>
				<div *ngIf="vm.isNoShow">
					<div>
						<div class="cp-meta-label cp-meta-label-1 tts-hl-1">Shift:</div>
						No Show
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-xl-6">
				<div>
					<div class="cp-meta-label cp-meta-label-2 tts-hl-1">Emp:</div>
					{{ vm.employeeName }}
				</div>

				<div *ngIf="vm.siteName">
					<div class="cp-meta-label cp-meta-label-2 tts-hl-1">Site:</div>
					{{ vm.siteName }}
				</div>
				<div>
					<div class="cp-meta-label cp-meta-label-2 tts-hl-1">Job:</div>
					{{ vm.jobName }}
				</div>

				<div
					*ngIf="enableAdvancedCheckpiontTour && !currentCheckpoint && !isCreatingTour && checkpoints.length > 0"
					style="font-weight: 600; display: inline-block"
				>
					<div *ngIf="!isUpdatingTour" class="link-text" (click)="startImportSelection()">Import checkpoints into a new tour</div>
				</div>
			</div>
		</div>

		<!-- DEPRECATED 2024-05-08 -->
		<!-- <div *ngIf="isReplacingTour">
			<hr />
			<div class="row">
				<div class="col-md-6 col-sm-12 cp-selected-counter" style="color: rgb(80, 77, 77)">
					<div>Are you sure?</div>
					<div class="cp-selected-note" style="color: firebrick">The current tour will be replaced</div>
				</div>
				<div class="col-md-6 col-sm-12 d-flex justify-content-between">
					<button class="btn btn-sm btn-block btn-outline-secondary tour-btn" style="margin-right: 4px" (click)="isReplacingTour = false">
						Cancel
					</button>
					<button class="btn btn-sm btn-block btn-outline-danger tour-btn" style="margin-left: 4px" (click)="createTourTemplate()">
						Replace
					</button>
				</div>
			</div>
		</div> -->
	</div>

	<!-- <div>
		<code>
			<pre>{{ wplStore | json }}</pre>
		</code>
		<div *ngIf="wplStore.getNextExpectedCheckpoint(this.timezone)">{{ wplStore.getNextExpectedCheckpoint(this.timezone) }}</div>
	</div> -->

	<!-- No Checkpoints Message -->
	<div *ngIf="checkpoints.length === 0" class="cp-list-empty">
		<div style="color: gray">No checkpoints</div>
	</div>

	<div *ngIf="isUpdatingTour" style="margin: 0px 20px; margin: 0px 20px; font-weight: 600; color: green; text-align: center">
		<i class="fas fa-loader fa-spin"></i>&nbsp;Creating checkpoint tour ...
	</div>

	<!-- Checkpoints List -->
	<div *ngIf="checkpoints.length > 0">
		<div *ngIf="isCreatingTour" style="margin: 0px 20px">
			<div
				*ngIf="enableAdvancedCheckpiontTour"
				class="options-block options-block-radio-lg"
				style="margin-top: 16px; margin-bottom: 16px; text-align: center"
			>
				<div>
					<div class="form-check form-check-inline">
						<input
							type="radio"
							[name]="'tour-type-radios'"
							class="form-check-input"
							[id]="'tour-type-shift'"
							[value]="'STRUCTURED'"
							[(ngModel)]="importTourType"
						/>
						<label class="form-check-label has-pointer" [for]="'tour-type-shift'" style="margin-left: 4px; font-weight: bold">
							<span>Shift-timed Tour</span>
						</label>
						<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp.emit('selectDropdownMenu')"></i> -->
					</div>

					<div class="form-check form-check-inline">
						<input
							type="radio"
							[name]="'tour-type-radios'"
							class="form-check-input"
							[id]="'tour-type-daily'"
							[value]="'STRUCTURED_DAY_START'"
							[(ngModel)]="importTourType"
						/>
						<label class="form-check-label has-pointer" [for]="'tour-type-daily'" style="margin-left: 4px; font-weight: bold">
							<span>Daily Tour</span>
						</label>
						<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp.emit('selectExpandedList')"></i> -->
					</div>

					<div class="form-check form-check-inline" style="margin-right: 0px">
						<input
							type="radio"
							[name]="'tour-type-radios'"
							class="form-check-input"
							[id]="'tour-type-hourly'"
							[value]="'STRUCTURED_HOUR_START'"
							[(ngModel)]="importTourType"
						/>
						<label class="form-check-label has-pointer" [for]="'tour-type-hourly'" style="margin-left: 4px; font-weight: bold">
							<span>Hourly Tour</span>
						</label>
						<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp.emit('selectExpandedList')"></i> -->
					</div>
				</div>
			</div>

			<div style="text-align: center">
				<span class="link-text" (click)="cancelTourImport()">cancel import</span> |
				<span class="link-text" (click)="clearSelectedCheckpoints()">clear selection</span> |
				<span class="link-text" (click)="selectAllCheckpoints()">select all</span>
			</div>
			<div style="text-align: center; font-weight: bold; margin-top: 8px" [style.color]="selectedCheckpoints.length > 0 ? 'green' : 'gray'">
				<div>{{ selectedCheckpoints.length }} checkpoints selected</div>
			</div>

			<button class="btn btn-block btn-outline-success" style="margin: 12px 0px" (click)="createTourTemplate()">
				Create a new tour from selection
			</button>
		</div>
		<div
			class="chkpt-box"
			[class.chkpt-box-editing]="cp.isEditing"
			[class.card-selected]="cp.isSelected"
			*ngFor="let cp of checkpoints; let cpIdx = index"
			[hidden]="(currentCheckpoint && currentCheckpoint !== cp) || (!cp.isEditing && !cp.id)"
		>
			<app-checkpoints-list-item
				[cp]="cp"
				[cpIdx]="cpIdx"
				[showCheckbox]="isCreatingTour"
				[timezone]="timezone"
				[doesJobHaveTour]="jobHasExistingTour"
				[wplRecord]="getWplRecord(cp)"
				(setCurrentCheckpoint)="setCurrentCheckpoint($event)"
				(showCheckpoint)="showCheckpoint.next(cp)"
				(checkpointUpdating)="checkpointUpdatingEventHandlert()"
				(checkpointUpdated)="checkpointSavedEventHandler()"
				(editCancelled)="editCancelledEventHandler()"
				(addCheckpointToTour)="addCheckpointToTour()"
				(showAuditLogDialog)="showAuditLogDialog.next($event)"
			></app-checkpoints-list-item>
		</div>
	</div>
</div>
