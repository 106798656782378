import { Component, OnDestroy, OnInit } from '@angular/core'
import { BannerViewManager } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-banner-group',
    templateUrl: './banner-group.component.html',
    styleUrls: ['./banner-group.component.scss'],
    standalone: false
})
export class BannerGroupComponent implements OnInit, OnDestroy {
	bvm: BannerViewManager
	private subs = new Subscription()

	constructor(private coreSrvc: CoreService) {
		this.bvm = this.coreSrvc.bannerSrvc.viewManager
		this.updateUI()

		// Watch for changes to Company record to manage availability for profiles and open shift banners
		this.subs.add(
			this.coreSrvc.dbSrvc.settingSrvc.companyChanged.subscribe(() => {
				this.updateUI()
			})
		)

		// Watch for changes to W2W Sync record to manage availability for W2W Errors banner
		this.subs.add(
			this.coreSrvc.dbSrvc.w2wSrvc.w2wSyncUpdated.subscribe(() => {
				this.updateUI()
			})
		)

		this.subs.add(
			this.coreSrvc.bannerSrvc.bannerAvailabilityNeedsUpdate.subscribe(() => {
				this.updateUI()
			})
		)
	}

	ngOnInit(): void {}
	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	updateUI() {
		this.coreSrvc.zone.run(() => {
			const isGlobalAccount = this.coreSrvc.dbSrvc.settingSrvc.isGlobalAccount()
			this.bvm.openShifts.isAvailable = !isGlobalAccount
			const areEmployeeProfilesAvailable = this.coreSrvc.dbSrvc.settingSrvc.areEmployeeProfilesAvailable()
			this.bvm.profileUpdate.isAvailable = areEmployeeProfilesAvailable
			const areW2WErrorsAvailable = this.coreSrvc.dbSrvc.w2wSrvc.isW2WIntegrated()
			this.bvm.w2wErrors.isAvailable = areW2WErrorsAvailable
		})
	}
}
