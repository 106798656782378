import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Helper, log, PhoneHelper } from '@app/helpers'
import { DialogManager } from '@app/models'
import { CoreService, DatabaseService, UserLoginService } from '@app/services'

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    standalone: false
})
export class ChangePasswordComponent implements OnInit, AfterViewInit, AfterContentInit {
	@Input() dialogManager: DialogManager
	@Output() updateComplete = new EventEmitter<boolean>()

	userE164 = ''
	username = ''

	newPassword = ''
	confirmPassword = ''

	confirmCode = ''

	currentView: 'CHANGEPASS' | 'CONFIRMATION' = 'CHANGEPASS'
	isConfirming = false

	constructor(private coreSrvc: CoreService) {
		const user = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
		const phone = user.phone_e164
		this.userE164 = phone
		this.username = PhoneHelper.formatPhoneFromE164(phone)
		log('Username', this.username)
	}

	ngOnInit(): void {}

	ngAfterViewInit(): void {}

	ngAfterContentInit() {
		this.setupDialogManager()
	}

	private setupDialogManager() {
		this.dialogManager.submitBtnLabel = 'Update'
		this.dialogManager.submitBtnAction = () => this.onSubmit()
		this.dialogManager.canSubmit = () => this.canSubmit()
	}

	private canSubmit(): boolean {
		if (this.currentView === 'CHANGEPASS') {
			return this.isPasswordValid('confirmPassword')
		}
		if (this.currentView === 'CONFIRMATION') {
			const code = Helper.stripNonNumeric(this.confirmCode)
			return code.length === 6
		}
		return false
	}

	private onSubmit() {
		if (this.currentView === 'CHANGEPASS') {
			this.coreSrvc.loginSrvc.forgotPassword(this.userE164, this)
			return
		}
		if (this.currentView === 'CONFIRMATION') {
			this.confirmPasswordChange()
		}
	}

	private confirmPasswordChange() {
		this.isConfirming = true
		this.coreSrvc.loginSrvc.confirmNewPassword(this.userE164, this.confirmCode, this.newPassword, this)
	}

	public isPasswordValid(prop) {
		const passInput = this[prop]

		if (prop === 'newPassword') {
			return Helper.isPasswordValid(passInput)
		}

		if (prop === 'confirmPassword') {
			return passInput === this.newPassword && Helper.isPasswordValid(passInput)
		}

		return false
	}

	public cognitoCallback(message: string, result: any) {
		if (this.currentView === 'CHANGEPASS') {
			this.currentView = 'CONFIRMATION'
			this.dialogManager.submitBtnLabel = 'Confirm'
			return
		}

		if (this.currentView === 'CONFIRMATION') {
			if (message) {
				this.coreSrvc.notifySrvc.notify(
					'error',
					'Error',
					'Password reset not successful. Please check your confirmation code and try again or contact support for assistance.',
				)
				this.isConfirming = false
			} else {
				this.updateComplete.next(true)
			}
			return
		}
	}
}
