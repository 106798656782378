import { Component, OnDestroy, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { log } from '@app/helpers'
import { BannerStatus, BannerViewManager } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { Subject, Subscription } from 'rxjs'

@Component({
    selector: 'app-w2w-sync-errors-banner',
    templateUrl: './w2w-sync-errors-banner.component.html',
    styleUrls: ['./w2w-sync-errors-banner.component.scss'],
    standalone: false
})
export class W2WSyncErrorsBannerComponent implements OnInit, OnDestroy {
	bvm: BannerViewManager
	status: BannerStatus

	subs = new Subscription()
	@Output() receivedUpdate = new Subject<boolean>()

	constructor(private router: Router, private coreSrvc: CoreService) {
		this.bvm = this.coreSrvc.bannerSrvc.viewManager
		this.status = this.coreSrvc.bannerSrvc.viewManager.w2wErrors
		this.subs.add(this.coreSrvc.notifySrvc.databaseFinishedLoading.subscribe(() => this.updateUI()))

		this.subs.add(
			this.coreSrvc.dbSrvc.w2wSrvc.w2wSyncLogUpdated.subscribe(() => {
				log('W2WSyncErrorsBannerComponent Got update')
				this.updateUI()
			})
		)
	}

	get shouldShowBanner(): boolean {
		return this.status?.notificationCount > 0
	}

	private updateUI() {
		const numberCount = this.coreSrvc.dbSrvc.w2wSrvc.getW2WFilteredSyncLog().length
		this.status.notificationCount = numberCount
		this.coreSrvc.bannerSrvc.groupBannerNeedsUpdate.next(true)
	}

	public ngOnInit(): void {}
	public ngOnDestroy(): void {
		this.subs.unsubscribe()
	}
	public navigateFromBanner() {
		this.coreSrvc.bannerSrvc.bannerPanelShouldHide.next(true)
		this.router.navigate(['/admin/integrations/whentowork'])
	}
}
