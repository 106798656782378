<div class="container section-container">
	<div class="sec-head-wrap">
		<div class="sec-act-ico-wrap">
			<i
				class="fas fa-plus sec-act-ico"
				[class.section-control-disabled]="!canPerformAction('create')"
				aria-hidden="true"
				[title]="viewManager.currentView === 'CLIENT' ? 'Add Bill Rate' : 'Add Pay Rate'"
				(click)="createRecord()"
			></i>
			<i class="far fa-question-circle sec-act-ico" (click)="toggleSectionHelp()" title="Show Help"></i>
		</div>

		<div class="sec-title-wrap">
			<i class="fal fa-money-check-edit-alt" aria-hidden="true"></i>&nbsp;
			<span>{{ viewManager.currentView === 'CLIENT' ? 'Bill' : 'Pay' }} Rates</span>
			<app-sub-section-title [isVisible]="true" [subSectionTitle]="subSectionTitle"></app-sub-section-title>
		</div>

		<div class="sub-head-wrap">
			<div class="sub-head-ico-wrap">
				<i class="fa fa-refresh sub-head-ico" (click)="reloadData()" title="Refresh Data"></i>
			</div>
			<div class="updated-wrap">{{ lastUpdated }}</div>
		</div>
	</div>

	<div>
		<app-section-switcher [config]="switcherConfig" [type]="'NOCOUNT'" [maxWidth]="'310px'" (tabClicked)="sectionTabClicked($event)">
		</app-section-switcher>
	</div>

	<div *ngIf="vm.showSectionHelp" class="card card-body tooltip-block tooltip-info-text tooltip-section-header">
		<button type="button" class="close" aria-label="Close" (click)="toggleSectionHelp()">
			<span aria-hidden="true">&times;</span>
		</button>
		Pay rates allow you to customize {{ helpLabelRateInfo }} for specific jobs.
		<br />
	</div>

	<div *ngIf="viewManager.currentView === 'EMPLOYEE'">
		<app-emp-job-pay-rates-table #empJobPayRatesTable></app-emp-job-pay-rates-table>
	</div>
	<div *ngIf="viewManager.currentView !== 'EMPLOYEE'">
		<app-org-job-pay-rates-table #orgJobPayRatesTable></app-org-job-pay-rates-table>
	</div>
</div>
