import { Component, OnInit, AfterViewInit, Input, ChangeDetectorRef } from '@angular/core'
import { VehicleInspectionReportData, ReportGlobals, UserReportConfiguration } from '@app/models'
import { log } from '@app/helpers/logger'

@Component({
    selector: 'app-vehicle-inspection-report',
    templateUrl: './vehicle-inspection-report.component.html',
    styleUrls: ['./vehicle-inspection-report.component.scss'],
    standalone: false
})
export class VehicleInspectionReportComponent implements OnInit, AfterViewInit {
	@Input() reportData = new VehicleInspectionReportData()
	@Input() reportConfig: UserReportConfiguration

	format12Hour = ReportGlobals.displayPrefs.format12Hour
	datePickerFormat = ReportGlobals.getDatePickerFormat('DATE')

	constructor(private cd: ChangeDetectorRef) {
		// Don't do anything in constructor, component needs data from above.
		// Don't make calls to any service. Place needed data in reportConfig
	}

	ngOnInit(): void {}

	ngAfterViewInit(): void {}

	skipInput(prop: any, field: string) {
		log('Skipping', field)
		prop[field] = `< skipped >`
		this.cd.detectChanges()
	}

	elementFocused(event: any) {
		log('Event', event)
		const id = event.target?.id
		// Remove any validation border colorering
		if (id) {
			$(`#${id}`).removeClass('ur-input-needs-info')
		}
	}
}
