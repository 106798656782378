import { DateTimeHelper } from '@app/helpers'
import { ClickToCallSourceType } from './click-to-call'

export type ActiveCallLastStatusType = 'BUSY' | 'CALLING' | 'CONNECTED' | 'FAILED' | 'HANGUP' | 'NOANSWER' | 'NODIGITS'

export class ActiveCallLastStatus {
	static lookupType(type: ActiveCallLastStatusType) {
		switch (type) {
			case 'BUSY':
				return 'BUSY'
			case 'CALLING':
				return 'CALLING'
			case 'CONNECTED':
				return 'CONNECTED'
			case 'FAILED':
				return 'FAILED'
			case 'HANGUP':
				return 'HANG UP'
			case 'NOANSWER':
				return 'NO ANSWER'
			case 'NODIGITS':
				return 'NO RESPONSE'
		}
	}
}

export class ActiveCallLegRecord {
	id: number
	company_id: number
	created: string
	click_to_call_log_id: number
	user_id: number
	phone_e164: string
	display_name: string
	bridged = false
	channel: string
	muted = false
	hostname: string
	call_source: ClickToCallSourceType
	last_status: string
	last_update: string
	transaction_id: number

	constructor(record: ActiveCallLegRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.last_update = DateTimeHelper.stripUtcTag(this.last_update)
		}
	}
}
