import { environment } from '@env/environment'
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'
import { CompanyRecord, FileUploaderMimeType, FileUploadHelper } from '@app/models'
import { CoreService } from '@app/services'
import { log } from '@app/helpers'

import { v4 as uuid } from 'uuid'
import S3 from 'aws-sdk/clients/s3'

class FileUploaderProgress {
	bytesSent = 0
	bytesTotal = 100
	constructor(sent: number, total: number) {
		this.bytesSent = sent
		this.bytesTotal = total
	}
}

@Component({
    selector: 'app-company-logo-uploader',
    templateUrl: './company-logo-uploader.component.html',
    styleUrls: ['./company-logo-uploader.component.scss'],
    standalone: false
})
export class LogoUploaderComponent implements OnInit {
	companyId = 0
	filePath = 'branding/adminportal/companylogo'
	fileName = uuid()

	logoUploaderVisible = false
	hasCustomLogo = false

	assetsBucket = environment.assetsBucket
	status = { isUploading: false, isProcessing: false, percentComplete: 0 }

	@Output() uploadComplete = new EventEmitter<boolean>()

	@ViewChild('logoUploader', { static: false }) logoUploader

	inputFiles: Array<File> = []
	processedFiles: Array<File> = []
	invalidFiles: Array<File> = []

	constructor(private coreSrvc: CoreService) {
		this.companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id || 0
	}

	ngOnInit(): void {
		this.updateUI()
	}

	updateUI() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		log('Company Record', company)
		const logoUrl = company?.logo_url
		if (logoUrl) {
			this.hasCustomLogo = true
		} else {
			this.hasCustomLogo = false
		}
	}

	prepareForLogoUpload() {
		log('Prepare for logo upload')
		this.logoUploaderVisible = true
	}

	removeCompanyLogo() {
		log('Remove company logo')
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const newCompany = new CompanyRecord(company)
		const companyId = this.companyId
		if (companyId) {
			newCompany.logo_url = null
			log('company logo', newCompany.logo_url)
			this.coreSrvc.dbSrvc.updateRecord('company', newCompany).then((success) => {
				log('Updated company record')
				this.updateUI()
			})
		}
	}

	uploadHandler(event) {
		log('uploadHandler', event)
		this.resetQueue()
		if (this.status.isUploading) {
			return
		}
		const files = event.files
		this.inputFiles = []
		for (const file of files) {
			this.inputFiles.push(file)
		}
		this.logoUploader.clear()
		this.coreSrvc.loginSrvc.refresh().then((success) => {
			this.startProcessing()
		})
	}

	startProcessing() {
		log('Input Files', this.inputFiles)
		const nextFile = this.inputFiles.shift()
		if (nextFile) {
			this.fileName = uuid()

			FileUploadHelper.getFileMimeType(nextFile, false).then((type) => {
				if (this.isValidMimeType(type)) {
					log('Uploading next file', nextFile)
					this.uploadFile(nextFile).then((success) => {
						if (success) {
							this.processedFiles.push(nextFile)
							this.logoUploadSuccess()
						} else {
							log('Encountered error during upload of file', nextFile)
							this.resetQueue()
						}
					})
				} else {
					log('No valid file to upload')
					log('Invalid file found', nextFile)
					this.coreSrvc.notifySrvc.notify(
						'error',
						'Invalid File Type',
						'You may only upload image files as your logo. Accepted formats are GIF, JPEG, and PNG. The preferred format is PNG.',
					)
				}
			})
		}
	}

	logoUploadSuccess() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const assetsUrl = environment.assetsBucket
		const newCompany = new CompanyRecord(company)
		const companyId = this.companyId
		const filePath = this.filePath
		const fileName = this.fileName

		if (companyId) {
			newCompany.logo_url = `https://${assetsUrl}/${companyId}/${filePath}/${fileName}`
			log('company logo', newCompany.logo_url)
			this.coreSrvc.dbSrvc.updateRecord('company', newCompany).then((success) => {
				log('Updated company record')
				this.updateUI()
				this.finishedProcessing()
			})
		}
	}

	isValidMimeType(mimeType: FileUploaderMimeType) {
		switch (mimeType) {
			case 'image/gif':
			case 'image/jpeg':
			case 'image/png':
				return true
			default:
				return false
		}
	}

	uploadFile(file: File): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this.status.percentComplete = 0
			this.status.isProcessing = false
			this.status.isUploading = true

			const assetsBucket = this.assetsBucket
			const companyId = this.companyId
			const filePath = this.filePath
			const fileName = this.fileName

			const params: any = {
				Metadata: {
					company_id: `${companyId}`,
				},
				Bucket: `${assetsBucket}`,
				Key: `${companyId}/${filePath}/${fileName}`,
				ContentType: file.type,
				Body: file,
			}

			log('Upload params', params)

			const bucket = new S3()
			bucket
				.upload(params, (err, data) => {
					// this.isUploading = false
					if (err) {
						log('Upload Error', err)
						resolve(false)
					} else {
						resolve(true)
					}
				})
				.on('httpUploadProgress', (evt) => {
					const progress = new FileUploaderProgress(evt.loaded, evt.total)
					this.updatePercentComplete(progress)
				})
		})
	}

	finishedProcessing() {
		this.uploadComplete.emit(true)
		if (this.processedFiles.length > 0) {
			this.coreSrvc.notifySrvc.notify('success', 'Upload Finished', 'Your logo has been uploaded and will be added to your admin portal.')
		} else {
			this.coreSrvc.notifySrvc.notify(
				'warn',
				'No Valid Files',
				'No valid files were found for upload. Please check naming requirements and try again.',
			)
		}
		this.status.isProcessing = false
		this.status.isUploading = false
	}

	resetQueue() {
		this.status.isProcessing = false
		this.status.isUploading = false
		this.inputFiles = []
		this.invalidFiles = []
		this.processedFiles = []
	}

	updatePercentComplete(progress: FileUploaderProgress) {
		const result = (progress.bytesSent / progress.bytesTotal) * 100
		this.status.percentComplete = Math.floor(result)
	}
}

// https://assets.telephonetimesheets.com/1433/branding/tts-logo.png
