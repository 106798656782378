import { Injectable } from '@angular/core'
import { Helper, log } from '@app/helpers'
import { Subject } from 'rxjs'

import { IntroJs } from 'intro.js/src/intro'
import introJs from 'intro.js'

@Injectable({ providedIn: 'root' })
export class GuidedTourService {
	isTourRunning = false
	tourOptions: Array<TourOptions> = []

	constructor() {
		log('Creating GuidedTourService')
	}

	startTour(options: any) {
		if (Helper.getCookieByName(options.dontShowAgainCookie)) return
		if (this.tourOptions.length > 0) return
		this.tourOptions.push(options)

		if (this.isTourRunning) return
		this.runNextTour()
	}

	runNextTour() {
		if (this.tourOptions.length === 0) {
			this.isTourRunning = false
			return
		}

		this.isTourRunning = true
		const currentOptions: any = this.tourOptions.shift()
		console.log('Starting Guided Tour', currentOptions.dontShowAgainCookie)

		const tour = introJs()
		tour.setOptions(currentOptions)
		tour.start()

		tour.oncomplete(() => {
			console.log('Ending guided tour by exit')
			this.endTour()
		})
		tour.onexit(() => {
			console.log('Ending guided tour by completion')
			this.endTour()
		})
	}

	endTour() {
		setTimeout(() => {
			this.runNextTour()
		}, 250)
	}
}

class TourOptions {
	overlayOpacity: number
	exitOnOverlayClick: boolean
	dontShowAgain: boolean
	dontShowAgainCookie: string
	steps: {
		element: Element
		intro: string
	}[]
}
