import { log } from './logger'

export class DialogHelper {
	/**
	 * Resets the modal dialog to the center of the screen
	 * @param selector The main modal dialog ID
	 */
	static resetBootstrapModalDialog(selector: string) {
		const modal = document.getElementById(selector) // Adjust the selector if needed
		if (modal) {
			const modalDialog = modal.querySelector('.modal-dialog') as HTMLElement

			if (modalDialog) {
				// Reset any inline styles
				modalDialog.style.top = ''
				modalDialog.style.left = ''
				modalDialog.style.marginBottom = ''
				modalDialog.style.marginRight = ''

				// Get modal dimensions
				const modalHeight = modalDialog.offsetHeight
				const modalWidth = modalDialog.offsetWidth

				// Get window dimensions
				const windowHeight = window.innerHeight
				const windowWidth = window.innerWidth

				// Calculate new top and left values
				const top = Math.max((windowHeight - modalHeight) / 2, 0) + 'px'
				const left = Math.max((windowWidth - modalWidth) / 2, 0) + 'px'

				// Apply the new values
				modalDialog.style.marginTop = top
				modalDialog.style.marginLeft = left

				log('Dialog Reset')
			}
		}
	}

	static dialogSavePosition: DialogSavePosition

	/**
	 * Saves the dialog position
	 * @param selector The main modal dialog ID
	 */
	static handleSavedPositioning(selector: string) {
		const savedPosition = DialogHelper.dialogSavePosition
		const modal = document.getElementById(selector) // Adjust the selector if needed
		const modalDialog = modal.querySelector('.modal-dialog') as HTMLElement

		if (savedPosition) {
			log('LOADING POSITION:', savedPosition)

			modalDialog.style.top = savedPosition.top
			modalDialog.style.left = savedPosition.left
		}

		// Log the initial position
		const initialPosition = modalDialog.getBoundingClientRect()
		log('INITIAL POSITION:', { top: initialPosition.top, left: initialPosition.left })

		const element: any = $('#' + selector)
		element.modal('show')

		modal.addEventListener('hide.bs.modal', () => {
			const modalPosition = modalDialog.getBoundingClientRect()
			const top = modalPosition.top
			const left = modalPosition.left

			// Calculate the center position
			const screenWidth = window.innerWidth
			const dialogWidth = modalDialog.offsetWidth
			const centerLeft = (screenWidth - dialogWidth) / 2

			if (savedPosition) {
				savedPosition.top = top - 28 + 'px'
				savedPosition.left = left - centerLeft + 'px'
			} else {
				const savePosition = { top: top - 28 + 'px', left: left - centerLeft + 'px' }
				DialogHelper.dialogSavePosition = savePosition
			}
			log('SAVING POSITION:', DialogHelper.dialogSavePosition)
		})

		// DisplayHelper.setHtmlScrolling(false) // Disable HTML scrolling on modal open
	}
}

interface DialogSavePosition {
	top: string
	left: string
}
