import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { log } from '@app/helpers'
import { v4 as uuid } from 'uuid'

@Component({
    selector: 'app-form-expander',
    templateUrl: './form-expander.component.html',
    styleUrls: ['./form-expander.component.scss'],
    standalone: false
})
export class FormExpanderComponent implements OnInit {
	componentId = uuid()

	@Input() style: Object = { 'margin-top': '10px' }
	@Input() contentStyle: Object = { margin: '18px 0px 22px 0px' }

	@Input() centerOnExpansion = false
	@Input() label = 'Form Section'
	@Input() labelStyle: Object = { 'font-size': '1em' }
	@Input() counter: number = null
	@Input() forceCounter: boolean = false
	@Input() minCounterWidth = '30px'
	@Input() badgeColor = '#135c94'
	@Input() textColor = 'white'
	@Input() isExpanded = false
	@Input() hasError = false
	@Output() isExpandedChange = new EventEmitter<boolean>()

	constructor() {}

	ngOnInit(): void {
		// log('Center on expansion', this.centerOnExpansion)
	}

	public toggleExpansion() {
		this.isExpanded = !this.isExpanded
		this.isExpandedChange.emit(this.isExpanded)
		log('Center', this.centerOnExpansion)
		if (this.isExpanded && this.centerOnExpansion) {
			setTimeout(() => {
				const elemId = 'FE_' + this.componentId
				const elem = document.getElementById(elemId)
				elem.scrollIntoView({ behavior: 'smooth', block: 'center' })
			}, 100)
		}
	}
}
