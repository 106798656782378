import { DatabaseService } from '@app/services'
import { ComponentBridgeName } from './bridge-table'
import { DatabaseTableName } from './table'
import { log } from '@app/helpers/logger'
import { Subject } from 'rxjs'

export type BatchActionType =
	| 'EXIT_BATCH'
	| 'SELECT_ALL'
	| 'UNSELECT_ALL'
	| 'SELECT_VISIBLE'
	| 'UNSELECT_VISIBLE'
	| 'TOGGLE_PREVIEW'
	| 'DELETE'
	| 'EDIT'
	| 'SEND_ANNOUNCEMENT'

export class BatchActionManager {
	// dbSrvc: DatabaseService

	table: DatabaseTableName
	bridgeName: ComponentBridgeName

	resource = { singular: 'record', plural: 'records' } // Used to customize the resource name if desired

	isActive = false
	isPreviewMode = false
	showDialog = false

	updateTable = new Subject<boolean>() // Called when the table needs to be updated

	// Override the selected records header by adding a custom method to calculte the label
	// to be displayed. This method will be called each time the selectedRecords array changes
	generateRecordsSelectedHeader: () => string = null
	overrrideRecordsSelectedHeader: string = null
	selectedRecords = [] // If selectedRecords changes, call updateRecordsSelectedHeader()
	get recordsSelectedHeader(): string {
		return this.overrrideRecordsSelectedHeader
			? this.overrrideRecordsSelectedHeader
			: this.selectedRecords.length === 1
				? `${this.selectedRecords.length} ${this.resource.singular} selected`
				: `${this.selectedRecords.length} ${this.resource.plural} selected`
	}

	constructor(table: DatabaseTableName, bridgeName: ComponentBridgeName, singular: string, plural: string) {
		this.table = table
		this.bridgeName = bridgeName
		if (singular) this.resource.singular = singular
		if (plural) this.resource.plural = plural
	}

	public reset() {
		this.isActive = false
		this.isPreviewMode = false
		this.selectedRecords = []
		this.updateRecordsSelectedHeader()
		this.updateTable.next(true)
	}

	public clearSelected() {
		this.selectedRecords = []
		this.isPreviewMode = false
		this.updateRecordsSelectedHeader()
		this.updateTable.next(true)
	}

	public updateRecordsSelectedHeader() {
		if (this.generateRecordsSelectedHeader) this.overrrideRecordsSelectedHeader = this.generateRecordsSelectedHeader()
	}

	public setSelectedRecords(ids: Array<number>) {
		this.selectedRecords = ids
		this.updateRecordsSelectedHeader()
		this.updateTable.next(true)
	}

	public setSelectState(id: number, isSelected: boolean) {
		if (isSelected) {
			this.selectedRecords.push(id)
		} else {
			this.selectedRecords = this.selectedRecords.filter((rec) => rec !== id)
		}
		// If a custom method is defined to calculate the records selected header then call it
		this.updateRecordsSelectedHeader()
	}

	public isRecordSelected(id: number): boolean {
		return this.selectedRecords.includes(id)
	}

	public toggleActive() {
		this.isActive = !this.isActive
		if (!this.isActive) this.clearSelected()
		this.updateTable.next(true)
	}

	public togglePreview() {
		this.isPreviewMode = !this.isPreviewMode
		this.updateTable.next(true)
	}

	public toggleRecordSelection(id: number) {
		if (this.isRecordSelected(id)) {
			this.setSelectState(id, false)
		} else {
			this.setSelectState(id, true)
		}
	}

	public formatSelectBox(id: number) {
		let result = ''
		if (this.isRecordSelected(id)) {
			// Original color:#0275d8 / #135c94
			result = `<span style="font-size:22px;color:#135c94;"><i class="fa fa-check-square" aria-hidden="true"></i></span>`
		} else {
			result = `<span style="font-size:22px;color:gray;"><i class="fa fa-square-o" aria-hidden="true"></i></span>`
		}
		return `<div style="display:inline-block;width:20px;margin-left:8px;cursor:pointer;" onclick="${this.bridgeName}.toggleBatchActionRecordSelection(${id})">${result}</div>`
	}

	// DEPRECATED 2024-10-24 - Moved into batch action component banner
	// public performAction(action: BatchActionType) {
	// 	log('Perform Action', action, this.selectedRecords)
	// 	switch (action) {
	// 		case 'EXIT_BATCH':
	// 			this.reset()
	// 			break
	// 		case 'CLEAR':
	// 			this.handleClearAction()
	// 			break
	// 		case 'DELETE':
	// 			this.handleDeleteAction()
	// 			break
	// 	}
	// }

	// private handleClearAction() {
	// 	this.selectedRecords = []
	// 	this.updateTable.next(true)
	// }

	// private handleDeleteAction() {
	// 	this.showDialog = true
	// }
}
