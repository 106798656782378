<div class="card card-body">
	<div class="row">
		<div class="col-12 record-item">
			<div class="float-right">
				<span class="link-text" (click)="batch.expandAll()">expand</span> | <span class="link-text" (click)="batch.collapseAll()">collapse</span>
			</div>
			<div>
				<strong class="settings-card-title"><i class="far fa-database"></i> {{ logLabel }}</strong>
			</div>
			<hr />
		</div>
	</div>
	<div class="col-12">
		<div class="log-date">{{ batch.formattedLogDate('START') }}</div>

		<div style="margin: 15px 0px 0px 0px">
			<app-form-expander
				[label]="'Summary'"
				[counter]="batch.counts.summary"
				[forceCounter]="true"
				[minCounterWidth]="'50px'"
				[(isExpanded)]="batch.isExpanded.summary"
			>
				<div *ngIf="batch.counts.summary === 0" class="no-events">No Summary Events</div>
				<div *ngIf="batch.counts.summary > 0">
					<div *ngFor="let summaryEvent of batch.summaryEvents">
						<span *ngIf="summaryEvent.type === 'SUMMARY'" style="color: #8b772e">{{ summaryEvent.mesg }}</span>
					</div>
				</div>
			</app-form-expander>

			<app-form-expander
				[label]="'Errors'"
				[counter]="batch.counts.error"
				[forceCounter]="true"
				[minCounterWidth]="'50px'"
				[(isExpanded)]="batch.isExpanded.error"
			>
				<div *ngIf="batch.counts.error === 0" class="no-events">No Error Events</div>
				<div *ngIf="batch.counts.error > 0">
					<div *ngFor="let errorEvent of batch.errorEvents">
						<span *ngIf="errorEvent.type === 'ERROR'" style="color: firebrick"><strong>Error: </strong> {{ errorEvent.mesg }}</span>
					</div>
				</div>
			</app-form-expander>

			<app-form-expander
				[label]="'Needs Fixing'"
				[counter]="batch.counts.fix"
				[forceCounter]="true"
				[minCounterWidth]="'50px'"
				[(isExpanded)]="batch.isExpanded.fix"
			>
				<div *ngIf="batch.counts.fix === 0" class="no-events">No Fix Events</div>
				<div *ngIf="batch.counts.fix > 0">
					<div *ngFor="let fixEvent of batch.fixEvents">
						<span *ngIf="fixEvent.type === 'FIX'"><strong style="color: chocolate">Fix: </strong> {{ fixEvent.mesg }}</span>
					</div>
				</div>
			</app-form-expander>

			<app-form-expander
				[label]="'Informational'"
				[counter]="batch.counts.info"
				[forceCounter]="true"
				[minCounterWidth]="'50px'"
				[(isExpanded)]="batch.isExpanded.info"
			>
				<div *ngIf="batch.counts.info === 0" class="no-events">No Informational Events</div>
				<div *ngIf="batch.counts.info > 0">
					<div *ngFor="let infoEvent of batch.infoEvents">
						<span *ngIf="infoEvent.type === 'INFO'" style="color: #8b772e">{{ infoEvent.mesg }}</span>
					</div>
				</div>
			</app-form-expander>
		</div>

		<!-- <div class="log-date">Completed: {{ batch.formattedLogDate('END') }}</div> -->
	</div>
</div>
