<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<form class="edit-form-block" novalidate [formGroup]="orgForm" (keydown.enter)="$event.preventDefault()" autocomplete="off">
		<div class="options-block" [class.input-required]="!orgForm.get('name').value">
			<div class="options-title-inline">Name</div>
			<div *ngIf="!orgForm.get('name').value" class="float-right">(required)</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="name" />
		</div>

		<div class="options-block">
			<div class="options-title">Type</div>
			<p-select appScrollLockSelect [options]="orgTypeOptions" formControlName="type" [filter]="false"></p-select>
		</div>

		<div class="options-block">
			<div class="options-title-inline">External ID</div>
			<!-- <div *ngIf="!orgForm.get('external_id').value" class="input-required float-right">(required)</div> -->
			<input type="text" class="form-control" autocomplete="off" formControlName="external_id" />
		</div>
		<!-- <div class="options-block">
			<div class="options-title-inline">Contact First Name</div>
			<div *ngIf="!orgForm.get('first_name').value" class="input-required float-right">(required)</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="first_name" />
		</div>

		<div class="options-block">
			<div class="options-title-inline">Contact Last Name</div>
			<div *ngIf="!orgForm.get('last_name').value" class="input-required float-right">(required)</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="last_name" />
		</div> -->

		<div class="options-block">
			<!-- <div *ngIf="!phoneNumber" class="input-required float-right">(required)</div> -->
			<div class="options-title">Phone Number</div>
			<div class="form-group">
				<table width="100%">
					<tr>
						<td style="width: 70px">
							<select
								class="form-control phone-country-select"
								[(ngModel)]="dialingCode"
								[ngModelOptions]="{ standalone: true }"
								(change)="formatNumber()"
							>
								<option *ngFor="let code of countryCodeData" value="{{ code.id }}">{{ code.id }}</option>
							</select>
						</td>
						<td>&nbsp;&nbsp;</td>
						<td style="max-width: 275px">
							<input
								(keyup)="formatNumber()"
								class="form-control"
								[class.is-valid]="isPhoneNumberValid"
								[(ngModel)]="phoneNumber"
								[ngModelOptions]="{ standalone: true }"
								type="tel"
								autocomplete="off"
							/>
						</td>
					</tr>
				</table>
			</div>
		</div>

		<div class="options-block">
			<!-- <div *ngIf="!orgForm.get('email').value" class="input-required float-right">(required)</div> -->
			<div class="options-title-inline">Email Address</div>
			<input
				type="text"
				class="form-control"
				[class.is-valid]="orgForm.get('email').value && isEmailValid()"
				autocomplete="off"
				formControlName="email"
			/>
		</div>

		<div class="options-block">
			<div class="options-title-inline">Notes</div>
			<div>
				<textarea class="org-details" formControlName="details" placeholder="Enter notes"></textarea>
			</div>
		</div>

		<div style="margin: 25px 0px">
			<app-form-address [manager]="formAddressManager" [showNotesOption]="true"></app-form-address>
		</div>

		<div *ngIf="isPayRateAvailable" class="options-block form-spacer">
			<!-- <div class="options-section-header" style="margin-bottom: 25px">Payroll Options</div> -->
			<div class="float-right">
				<span class="pay-rate-symbol tts-hl-2">{{ currencySymbol }}</span>
				<input type="number" class="form-control pay-rate-input" formControlName="pay_rate" (blur)="formatRateInput('pay_rate')" />
			</div>
			<div style="padding-top: 4px">
				<strong>{{ basePayRateLabel }}</strong>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('payRate')"></i>
			</div>
		</div>

		<!-- <hr class="section-divider"> -->
		<div class="options-section-header" style="margin-bottom: 25px">Notification Options</div>

		<div class="checkbox-item d-flex justify-content-between">
			<div class="checkbox-wrap">
				<p-checkbox formControlName="notify_phone" [binary]="true"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleCheckbox('notify_phone')" class="has-pointer">Enable Phone Notifications</strong>
				<!-- <i title="More Information" class="far fa-info-circle tts-info-btn"  (click)="showHelp('notifyPhone')"></i> -->
			</div>
		</div>
		<div class="checkbox-item d-flex justify-content-between">
			<div class="checkbox-wrap">
				<p-checkbox formControlName="notify_sms" [binary]="true"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleCheckbox('notify_sms')" class="has-pointer">Enable Text Notifications</strong>
				<!-- <i title="More Information" class="far fa-info-circle tts-info-btn"  (click)="showHelp('notifySms')"></i> -->
			</div>
		</div>
		<div class="checkbox-item d-flex justify-content-between">
			<div class="checkbox-wrap">
				<p-checkbox formControlName="notify_email" [binary]="true"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleCheckbox('notify_email')" class="has-pointer">Enable Email Notifications</strong>
				<!-- <i title="More Information" class="far fa-info-circle tts-info-btn"  (click)="showHelp('notifyEmail')"></i> -->
			</div>
		</div>
	</form>

	<div *ngIf="linkedJobs.length > 0">
		<hr class="section-divider" />
		<div style="margin-bottom: 25px">
			<div class="options-section-header">Linked Jobs</div>
			<div class="text-muted">
				<div class="linked-jobs-note">This {{ org?.type === 'CLIENT' ? 'client' : 'vendor' }} is linked to the following jobs.</div>
				<ul style="margin-top: 15px">
					<li *ngFor="let job of linkedJobs" style="margin-left: -10px">{{ job.description }}</li>
				</ul>
			</div>
		</div>
	</div>
</div>

<!-- <div *ngIf="true">
	<button class="btn btn-sm btn-secondary" (click)="viwFormErrors()">Form Errors</button>
	<div>{{ orgForm.valid }}</div>
	<br>
	<div>{{ orgForm.value | json }}</div>
</div> -->
