import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core'

import {
	CrudAction,
	DialogManager,
	LocalPrefsData,
	LocalPrefsDialogData,
	LocalPrefsGroup,
	ReportsFilterInfoEvent,
	ReportsViewManager,
	ReportsViewTabState,
	SectionSwitcherConfig,
	SectionSwitcherTab,
	TableFilterButton,
	TableFilterManager,
} from '@app/models'
import { CoreService } from '@app/services'

import { log, DateTimeHelper } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'

import { InsightReportTableComponent } from '@app/admin/reports-insight/insight-report-table/insight-report-table.component'
import { InsightReportHistoryTableComponent } from '@app/admin/reports-insight/insight-report-history-table/insight-report-history-table.component'

import { Subscription } from 'rxjs'

@Component({
    selector: 'app-insight-reports',
    templateUrl: './insight-reports.component.html',
    styleUrls: ['./insight-reports.component.scss'],
    standalone: false
})
export class InsightReportsComponent implements OnInit, OnDestroy, AfterViewInit {
	accessHelper: AccessHelper

	switcherConfig = new SectionSwitcherConfig()

	prefsVisible = false
	showTooltips = false

	@ViewChild('table') table: InsightReportTableComponent
	@ViewChild('historyTable') historyTable: InsightReportHistoryTableComponent

	CrudAction = CrudAction
	tableFilterManager = new TableFilterManager()

	public sectionPrefsDialogManager = new DialogManager('sectionPrefsDialog')
	public reportViewDialogManager = new DialogManager('reportViewDialogManager')
	public filterViewDialogManager = new DialogManager('filterViewDialogManager')

	private subs = new Subscription()

	constructor(private coreSrvc: CoreService) {
		this.setupAccessPermissions()
		this.setupTabSwitcher()
		this.setupLocalPrefsDialog()
		this.setupTableFilterManager()

		this.subs.add(
			this.coreSrvc.dbSrvc.reportSrvc.viewManager.sectionTabNeedsUpdate.subscribe(() => {
				log('Switching Tabs')
				const currentView = this.coreSrvc.dbSrvc.reportSrvc.viewManager.currentView
				this.switcherConfig.setTab(currentView)
			}),
		)
		this.subs.add(
			this.viewManager.showReportInfo.subscribe((repId) => {
				this.handleShowReportInfoEvent(repId)
			}),
		)
		this.subs.add(
			this.viewManager.showFilterInfo.subscribe((filterInfo) => {
				this.handleShowFilterInfoEvent(filterInfo)
			}),
		)
		// Read locations in order to get last_geo_coding info
		// this.coreSrvc.dbSrvc.readTable('location')
	}

	get viewManager(): ReportsViewManager {
		return this.coreSrvc.dbSrvc.reportSrvc.viewManager
	}

	get subSectionTitle(): string {
		switch (this.viewManager.currentView) {
			case 'DEFINE':
				return 'Define'
			case 'VIEW':
				return 'View'
		}
	}

	get currentDayViewDate(): Date {
		return this.viewManager.dayViewDate
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'report')
		this.viewManager.showAllReports = this.coreSrvc.prefSrvc.data.repShowAllReports
		// Was going to use for show all records toggle but that's available for all
		// const canSeeAllRecords = this.accessHelper.canPerformAction(CrudAction.read, false)
		// log('Can See All', canSeeAllRecords)
	}

	canPerformAction(action: CrudAction) {
		return this.accessHelper.canPerformAction(action, true)
	}

	ngOnInit() {}

	ngAfterViewInit() {
		scrollTo(0, 0)
		this.reloadTables()
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	// Begin Tab Swticher Methods
	private setupTabSwitcher() {
		const activeTab = new SectionSwitcherTab('DEFINE', 'Define')
		activeTab.count = () => 0
		const inactiveTab = new SectionSwitcherTab('VIEW', 'View')
		inactiveTab.count = () => 1
		const config = new SectionSwitcherConfig([activeTab, inactiveTab])
		this.switcherConfig = config
		this.switcherConfig.setInitialTabById(this.viewManager.currentView)
	}

	public sectionTabClicked(tab: SectionSwitcherTab) {
		const view = tab.id as ReportsViewTabState
		this.viewManager.setView(view)
		setTimeout(() => {
			this.reloadTables()
		}, 125)
	}
	// End Tab Switcher Methods

	private setupTableFilterManager() {
		const myReportsBtn = new TableFilterButton()
		myReportsBtn.id = 'MYREPORTS'
		myReportsBtn.label = 'My Reports'
		myReportsBtn.count = null
		this.tableFilterManager.buttons.push(myReportsBtn)

		const allReportsBtn = new TableFilterButton()
		allReportsBtn.id = 'ALLREPORTS'
		allReportsBtn.label = 'All Reports'
		allReportsBtn.count = null
		this.tableFilterManager.buttons.push(allReportsBtn)

		const viewStateId = this.viewManager.showAllReports ? 'ALLREPORTS' : 'MYREPORTS'
		this.tableFilterManager.setSelectedBtnById(viewStateId)
	}

	private setupLocalPrefsDialog(): void {
		this.sectionPrefsDialogManager.headerLabel = 'Preferences'
		const columnVisibilityReportItems = LocalPrefsData.getReportTableColumnDisplayPrefs()
		const columnVisibilityReportGroup = new LocalPrefsGroup('Column Visibility', columnVisibilityReportItems)

		const columnVisibilityReportHistoryItems = LocalPrefsData.getReportHistoryTableColumnDisplayPrefs()
		const columnVisibilityReportHistoryGroup = new LocalPrefsGroup('Column Visibility', columnVisibilityReportHistoryItems)

		const dialogData =
			this.viewManager.currentView === 'DEFINE'
				? new LocalPrefsDialogData([columnVisibilityReportGroup])
				: new LocalPrefsDialogData([columnVisibilityReportHistoryGroup])
		dialogData.localStorageKeyRemovalList = ['DataTables_reportsTable', 'DataTables_reportsHistoryTable']
		this.sectionPrefsDialogManager.dialogData = dialogData
	}

	public prefsIconClicked() {
		this.setupLocalPrefsDialog()
		this.sectionPrefsDialogManager.isDialogVisible = true
	}

	public prefsDataSaved(): void {
		log('UpdatePrefsData')
		this.sectionPrefsDialogManager.isDialogVisible = false
		this.table?.updateTable()
		this.historyTable?.updateTable()
	}

	public moveToPreviousDate() {
		this.historyTable?.moveToPreviousDate()
	}
	public moveToNextDate() {
		this.historyTable?.moveToNextDate()
	}

	public dayViewDateIconClicked() {
		this.historyTable?.dayViewDateIconClicked()
	}

	public setDayViewDate(date: Date) {
		this.historyTable?.setDayViewDate(date)
	}

	public openDayViewPicker() {}

	hasData() {
		return this.coreSrvc.dbSrvc.reportSrvc.reportsCount() > 0
	}

	showNoDataTip(): boolean {
		if (!this.hasData()) {
			return true
		}
		return false
	}

	reloadTables() {
		log('Reloading Tables')
		this.table?.reloadTable()
		this.historyTable?.reloadTable()
	}

	lastUpdated(): string {
		const date = this.coreSrvc.dbSrvc.reportSrvc.lastUpdated
		return `Updated ${DateTimeHelper.formatTimeFromDate(date)}`
	}

	toggleTooltips(): boolean {
		this.showTooltips = !this.showTooltips
		this.updateFixedHeader()
		return false
	}

	updateFixedHeader() {
		setTimeout(() => {
			const reportsTable: any = $('#reportsTable').DataTable()
			reportsTable.fixedHeader.adjust()
			const repHistoryTable: any = $('#reportsHistoryTable').DataTable()
			repHistoryTable.fixedHeader.adjust()
		}, 100)
	}

	togglePrefsPanel(): boolean {
		this.showTooltips = false
		this.prefsVisible = !this.prefsVisible
		return false
	}

	createRecord() {
		this.table.createRecord()
	}

	public changeSubsection(btn: TableFilterButton) {
		const newSetting = btn.id === 'ALLREPORTS' ? true : false
		this.viewManager.showAllReports = newSetting
		this.coreSrvc.prefSrvc.data.repShowAllReports = newSetting
		this.coreSrvc.prefSrvc.save()
		this.reloadTables()
		// if (newSetting) {
		// 	this.coreSrvc.dbSrvc.notifySrvc.notify('success', 'All Reports', 'Showing all available reports', 3)
		// } else {
		// 	this.coreSrvc.dbSrvc.notifySrvc.notify('success', 'Shared Reports', 'Showing only reports explicitly owned by or shared with you.')
		// }
	}

	private handleShowReportInfoEvent(repId: number) {
		const rep = this.coreSrvc.dbSrvc.reportSrvc.getReportById(repId)
		if (rep) {
			this.reportViewDialogManager.headerLabel = 'Linked Parent Report (View Only)'
			this.reportViewDialogManager.dialogData = repId
			this.reportViewDialogManager.isSubmitBtnVisible = false
			this.reportViewDialogManager.cancelBtnLabel = 'Close'
			this.reportViewDialogManager.isDialogVisible = true
		} else {
			this.coreSrvc.notifySrvc.notify(
				'error',
				'Report Not Found',
				'Could not find a linked parent report. It may not yet be synchronized or has been deleted. Refresh your browser and try again.',
			)
		}
	}

	private handleShowFilterInfoEvent(filterEvent: ReportsFilterInfoEvent) {
		if (!filterEvent.filter) {
			this.coreSrvc.notifySrvc.notify('error', 'No Filters', 'No filter information present', 2)
			return
		}
		this.filterViewDialogManager.headerLabel = 'Active Filters'
		this.filterViewDialogManager.dialogData = filterEvent
		this.filterViewDialogManager.isSubmitBtnVisible = false
		this.filterViewDialogManager.cancelBtnLabel = 'Close'
		this.filterViewDialogManager.isDialogVisible = true
		// log('Filter Json', filterEvent)
	}
}
