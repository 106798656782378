import { Component, OnInit, OnDestroy, Input, AfterViewInit, Output, EventEmitter, AfterContentInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'

import { AdpRateCodeSelectItem, DialogManager, EmpJobPayRateRecord, HelpDialogMessage } from '@app/models'
import { CoreService } from '@app/services'
import { log, FormHelper, DropdownHelperConfig, DropdownHelper } from '@app/helpers'
import { SelectItem } from 'primeng/api/selectitem'
import { NumberHelper } from '@app/helpers/number'

import _ from 'lodash'

@Component({
    selector: 'app-emp-job-pay-rates-edit',
    templateUrl: './emp-job-pay-rates-edit.component.html',
    styleUrls: ['./emp-job-pay-rates-edit.component.scss'],
    standalone: false
})
export class EmpJobPayRatesEditComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
	payRate: EmpJobPayRateRecord
	isNew = false
	isUpdating = false
	rateForm: UntypedFormGroup

	@Input() dialogManager = new DialogManager()
	@Input() recordId: number
	@Input() action = 'edit'

	@Output() recordUpdated = new EventEmitter<EmpJobPayRateRecord>()

	shouldRestrictDropdowns = true
	currencySymbol = ''

	employeeOptions: Array<SelectItem> = []
	jobOptions: Array<SelectItem> = []
	adpRateCodes: Array<AdpRateCodeSelectItem> = []

	isAdpIntegrated = false

	constructor(
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
	) {
		// Setup dropdown population (restrictions)
		const accessPermissions = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAccessPermissions().getAccessPermissionsFor('employee_job_pay_rate')
		this.shouldRestrictDropdowns = !accessPermissions.create // Restrict if user doesn't have all access create

		this.employeeOptions = this.makeEmployeeDropdown()
		this.jobOptions = this.makeJobsDropdown()
		this.currencySymbol = this.coreSrvc.dbSrvc.settingSrvc.getCompanyCurrencySymbol()

		this.isAdpIntegrated = this.coreSrvc.dbSrvc.adpSrvc.isAdpIntegrated()
		if (this.isAdpIntegrated) this.setupAdpDropdown()
	}

	setupAdpDropdown() {
		this.adpRateCodes = this.coreSrvc.dbSrvc.adpSrvc.getAdpRateCodeDropdown(true)
	}

	makeEmployeeDropdown(): Array<SelectItem> {
		const config = new DropdownHelperConfig(this.coreSrvc.dbSrvc, 'DROPDOWN')
		config.isRestricted = this.shouldRestrictDropdowns
		const dropdownHelper = new DropdownHelper(config)
		const options = dropdownHelper.buildEmployeeMenuOptions()
		return options
	}

	makeJobsDropdown(): Array<SelectItem> {
		const config = new DropdownHelperConfig(this.coreSrvc.dbSrvc, 'DROPDOWN')
		config.isRestricted = this.shouldRestrictDropdowns
		const dropdownHelper = new DropdownHelper(config)
		const options = dropdownHelper.buildJobMenuOptions()
		return options
	}

	ngOnInit() {
		if (this.action === 'new') {
			log('Creating a new client')
			this.isNew = true
			this.payRate = new EmpJobPayRateRecord()
		} else {
			this.isNew = false
			this.payRate = this.coreSrvc.dbSrvc.payRatesSrvc.getEmpJobPayRateById(this.recordId)
		}
		log('Init Pay Rate', this.payRate)
		this.setupForm()
	}

	ngAfterViewInit() {}

	ngAfterContentInit() {
		this.setupDialogManager()
	}

	ngOnDestroy() {}

	viwFormErrors() {
		FormHelper.getFormValidationErrors(this.rateForm)
	}

	isRateValid(): boolean {
		return true
	}

	isFormValid(): boolean {
		const isRateValid = this.isRateValid()
		const isFormValid = this.rateForm.valid
		return isFormValid && isRateValid
	}

	setupForm() {
		const payRate = this.payRate
		this.rateForm = this.fb.group({
			id: [payRate.id],
			adp_rate_code_id: [payRate.adp_rate_code_id],
			company_id: [payRate.company_id],
			employee_id: [payRate.employee_id, Validators.required],
			job_id: [payRate.job_id, Validators.required],
			pay_rate: [payRate.pay_rate],
		})
		if (this.isAdpIntegrated) {
			this.rateForm.get('adp_rate_code_id').setValidators(Validators.required)
		} else {
			this.rateForm.get('pay_rate').setValidators(Validators.required)
		}
	}

	setupDialogManager() {
		this.dialogManager.canSubmit = () => this.isFormValid()
		this.dialogManager.submitBtnAction = () => this.submit()
	}

	submit() {
		// Guard against double submission
		if (this.isUpdating) return
		// FormHelper.trimOnlyWhitespace(this.rateForm)

		const record = this.makeUpdateRecord()
		log('Record to submit', record)
		if (record) {
			if (this.isNew) {
				log('Adding new pay rate', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.insertRecord('employee_job_pay_rate', record).then((success) => {
					if (success) {
						this.recordUpdated.emit(this.payRate)
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			} else {
				log('Updating record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.updateRecord('employee_job_pay_rate', record).then((success) => {
					if (success) {
						this.recordUpdated.emit(this.payRate)
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			}
		}
	}

	formatInput(prop: string) {
		const value = this.rateForm.get(prop).value
		const newValue = NumberHelper.formatPayRate(value)
		this.rateForm.get(prop).setValue(newValue)
	}

	makeUpdateRecord(): Object {
		const form = this.rateForm.value
		const record = new EmpJobPayRateRecord(form)

		// if (this.phoneNumber && !this.isPhoneNumberValid) {
		// 	this.coreSrvc.dbSrvc.notifySrvc.notify('error', 'Invalid Phone', 'Please enter a valid phone number for the region selected or clear the phone number field.')
		// 	return null
		// }

		// const doesNameExist = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationByName(record.name)
		// if (this.isNew && doesNameExist) {
		// 	this.coreSrvc.dbSrvc.notifySrvc.notify('error', 'Duplicate Name', 'The organization name already exists. Organization names must be unique. Please choose another name.')
		// 	return null
		// }

		// record.phone_e164 = this.makeUpdatePhone()

		return record
	}

	// Misc Methods

	toggleCheckbox(prop: string) {
		log('prop', prop)
		const current = this.rateForm.get(prop).value
		this.rateForm.get(prop).setValue(!current)
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'payRate':
				help.header = 'Pay Rate'
				help.message = 'Enter the pay rate for the employee when working at the specified job.'
				break
			case 'adpPayRate':
				help.header = 'ADP Pay Rate'
				help.message = 'You must ensure you set a rate in ADP for the rate code and employee listed here, otherwise payroll uploads will error.'
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
