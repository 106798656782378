import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { log } from '@app/helpers'
import { DataAccessRequest, EmployeeChecklist, EmployeeChecklistRecord, JobRecord, JobSiteRecord, ReportRecord } from '@app/models'
import { DatabaseService } from '@app/services'

@Component({
    selector: 'app-job-site-merge-migration',
    templateUrl: './job-site-merge-migration.component.html',
    styleUrls: ['./job-site-merge-migration.component.scss'],
    standalone: false
})
export class JobSiteMergeMigrationComponent implements OnInit {
	showMigrationDialog = false
	showIssues = false
	mergeCandidate: JobSiteMergeCandidate

	constructor(
		private cd: ChangeDetectorRef,
		private router: Router,
		private dbSrvc: DatabaseService,
	) {
		this.mergeCandidate = new JobSiteMergeCandidate(dbSrvc)
	}

	ngOnInit(): void {}

	updateUI() {
		this.mergeCandidate = new JobSiteMergeCandidate(this.dbSrvc)
	}

	gotoJobSites() {
		scrollTo(0, 0)
		this.router.navigate(['/admin/jobsites/'])
	}

	migrationFixProcessingFinished() {
		setTimeout(() => {
			this.mergeCandidate = new JobSiteMergeCandidate(this.dbSrvc)
			this.showMigrationDialog = false
			this.cd.detectChanges()
		}, 500)
	}

	mergeName(selected: 'SITE' | 'JOB', entry: MergeEntry) {
		log('Selected', selected, entry)
		this.showMigrationDialog = true
		const site = entry.site
		const job = entry.job
		if (selected === 'SITE') {
			job.description = site.description
			this.dbSrvc.updateRecord('job', job).then((success) => {
				this.migrationFixProcessingFinished()
			})
		} else {
			site.description = job.description
			this.dbSrvc.updateRecord('location', site).then((success) => {
				this.migrationFixProcessingFinished()
			})
		}
	}

	splitJob(job: JobRecord) {
		log('Split Job', job)
		this.showMigrationDialog = true
		const siteId = job.location_id
		const site = this.dbSrvc.siteSrvc.getJobSiteById(siteId)
		if (site) {
			// Duplicate the job's job site using the job name
			const newSite = new JobSiteRecord(site)
			newSite.id = null
			newSite.description = job.description
			const request = new DataAccessRequest('location', 'insert', null, newSite)

			this.dbSrvc.lambdaSrvc
				.dataAccess(request)
				.then((result) => {
					const records = result.data
					const createdSite = records[0]
					if (createdSite) {
						// Read the jobs table in order to see new site's default job
						this.dbSrvc.readTable('job').then((readJobSuccess) => {
							// Get the newly created defult job for later deletion
							const newSiteJobs = this.dbSrvc.jobSrvc.getJobs().filter((j) => j.location_id === createdSite.id)
							const autoCreatedJobId = newSiteJobs[0]?.id

							// Migrate the original job to the newly created job site
							const migrateJob = new JobRecord(job)
							migrateJob.location_id = createdSite.id
							this.dbSrvc.updateRecord('job', migrateJob).then((migrateSuccess) => {
								if (migrateSuccess) {
									if (autoCreatedJobId) {
										log('Deleting Job ID', autoCreatedJobId)
										this.dbSrvc.deleteRecord('job', autoCreatedJobId).then((deleteSuccess) => {
											if (deleteSuccess) {
												this.dbSrvc.jobSrvc.removeLocalJobRecord(autoCreatedJobId)
												this.dbSrvc.readTable('location').then((readLocSuccess) => {
													this.migrationFixProcessingFinished()
												})
											} else {
												alert('Error deleting default job')
												this.migrationFixProcessingFinished()
											}
										})
									}
								} else {
									alert('Error migrating job')
									this.migrationFixProcessingFinished()
								}
							})
						})
					} else {
						alert('Error creating job site record')
						this.migrationFixProcessingFinished()
					}
				})
				.catch((error) => {
					alert('Error creating job site record')
					this.migrationFixProcessingFinished()
				})
		}
	}

	fixChecklist(checklist: EmployeeChecklistRecord) {
		log('Fix checklist', checklist)
		this.showMigrationDialog = true
		const record = new EmployeeChecklist(checklist)

		const siteIds = record.siteIds
		log('Site IDs', siteIds)
		const jobIds = record.jobIds
		log('Job IDs', jobIds)
		for (const siteId of siteIds) {
			const jobsForSite = this.dbSrvc.jobSrvc.getJobsForJobSiteId(siteId)
			log('Jobs for site', jobsForSite)
			for (const job of jobsForSite) {
				if (!jobIds.includes(job.id)) {
					jobIds.push(job.id)
				}
			}
		}
		record.siteIds = []

		// Update checklist
		const updateRecord = record.buildUpdateRecord()
		const recordId = updateRecord.id
		if (recordId) {
			this.dbSrvc.updateRecord('employee_checklist', updateRecord).then((updateSuccess) => {
				if (updateSuccess) {
					log('Record Updated')
					this.migrationFixProcessingFinished()
				}
			})
		}
	}

	fixReport(report: ReportRecord) {
		log('Fix report', report)
		this.showMigrationDialog = true
		const filterJson = report.filter
		if (filterJson) {
			const filter = JSON.parse(filterJson)
			log('Report filter', filter)
			const siteIds = filter.jobsite_id || []
			const jobIds = filter.job_id || []
			for (const siteId of siteIds) {
				const jobsForSite = this.dbSrvc.jobSrvc.getJobsForJobSiteId(siteId)
				log('Jobs for site', jobsForSite)
				for (const job of jobsForSite) {
					if (!jobIds.includes(job.id)) {
						jobIds.push(job.id)
					}
				}
			}
			filter.job_id = jobIds.length > 0 ? jobIds : null
			filter.jobsite_id = null
			log('Replacement filter', filter)
			const replacementFilter = JSON.stringify(filter)
			const updateReport = new ReportRecord(report)
			updateReport.filter = replacementFilter
			this.dbSrvc.reportSrvc.updateReport(updateReport).then((updateSuccess) => {
				this.dbSrvc.readTable('reports').then((readSuccess) => {
					this.migrationFixProcessingFinished()
				})
			})
		}
	}
}

interface MergeEntry {
	site: JobSiteRecord
	job: JobRecord
}

class JobSiteMergeCandidate {
	dbSrvc: DatabaseService

	isInternalUser = false
	isMerged = false
	isMergeCandidate = false
	checklistsUsingJobSiteFilter = []
	reportsWithJobSiteFilter = []
	jobsWithoutSites = []
	sitesWithoutJobs = []
	sitesWithMultipleJobs = []
	sitesWithDifferentJobNames = []

	constructor(dbSrvc: DatabaseService) {
		this.dbSrvc = dbSrvc
		this.isMerged = dbSrvc.settingSrvc.getCompany()?.merge_jobsite

		const unassignedJob = dbSrvc.jobSrvc.getUnassignedJob()
		const unassignedJobSiteId = unassignedJob?.location_id
		// const unassignedJobSite = dbSrvc.siteSrvc.getJobSiteById(unassignedJob?.location_id)

		// Checklists using job site filter
		dbSrvc.readTable('employee_checklist').then((success) => {
			const checklists = dbSrvc.empChklstSrvc.getEmployeeChecklists()
			for (const checklist of checklists) {
				if (checklist.location_ids) {
					this.checklistsUsingJobSiteFilter.push(checklist)
				}
			}
		})

		// Check for reports filtering job sites
		const reports = dbSrvc.reportSrvc.getReports()
		for (const report of reports) {
			const filter = report.filter
			if (filter) {
				const parsedFilter = JSON.parse(filter)
				if (parsedFilter.jobsite_id?.length > 0) {
					this.reportsWithJobSiteFilter.push(report)
				}
			}
		}

		// Check jobs for issues
		const jobs = dbSrvc.jobSrvc.getJobs()
		for (const job of jobs) {
			const siteId = job.location_id
			const site = dbSrvc.siteSrvc.getJobSiteById(siteId)
			if (!site) {
				this.jobsWithoutSites.push(job)
			}
		}

		// Check sites for issues
		const sites = dbSrvc.siteSrvc.getAllJobSites()
		for (const site of sites) {
			if (site.id !== unassignedJobSiteId) {
				const jobsForSite = dbSrvc.jobSrvc.getJobsForJobSiteId(site.id)
				if (jobsForSite.length === 0) {
					this.sitesWithoutJobs.push(site)
				}
				if (jobsForSite.length > 1) {
					const entry = { site: site, jobs: jobsForSite }
					this.sitesWithMultipleJobs.push(entry)
				}
				if (jobsForSite.length === 1) {
					const job = jobsForSite[0]
					if (site.description !== job?.description && !site.company_default) {
						const entry = { site: site, job: job }
						this.sitesWithDifferentJobNames.push(entry)
					}
				}
			}
		}

		// Check whether company is a merge candidate or has issues
		if (
			this.checklistsUsingJobSiteFilter.length === 0 &&
			this.reportsWithJobSiteFilter.length === 0 &&
			this.sitesWithoutJobs.length === 0 &&
			this.sitesWithMultipleJobs.length === 0 &&
			this.sitesWithDifferentJobNames.length === 0 &&
			this.jobsWithoutSites.length === 0
		) {
			this.isMergeCandidate = true
		}

		// Check if user is internal as only internal user will see merge candidate info
		const myUser = dbSrvc.settingSrvc.getMyActualUser()
		if (myUser.role === 'INTERNAL') {
			this.isInternalUser = true
		}
	}
}
