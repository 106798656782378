import { CoreService } from '@app/services'
import { ToastSeverity } from './toast'

import { v4 as uuid } from 'uuid'

type GlobalErrorKey = 'MISSINGRECURRENCE'

// Global namespace references all main services but should only be used by components.
// Services should inject any service dependencies themselves.

export class Global {
	public static coreSrvc: CoreService

	// Tracks the number of hidden jobs that are not visibile in the job list
	public static hiddenJobCount = 2

	// Tracks whether job sites are merged or not
	public static areJobSitesMerged = true

	// Tracks which units are being used
	public static distUnits: 'IMPERIAL' | 'METRIC' = 'IMPERIAL'

	// Regex for validating email
	public static emailRegex = new RegExp(/^([a-zA-Z0-9_\.\-\+\'])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/)

	// Auth Link Regex
	public static authLinkRegex = /token=[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}/gi

	// Method for generating a UUID
	public static createUuid(): string {
		return uuid()
	}

	// Global error tracking - used to supress duplicate errors
	private static globalErrorKeys = []
	public static reportGlobalErrorOneTime(key: GlobalErrorKey, severity: ToastSeverity, summary: string, detail: string, duration?: number) {
		if (Global.globalErrorKeys.includes(key)) return
		Global.globalErrorKeys.push(key)
		Global.coreSrvc.notifySrvc.notify(severity, summary, detail, duration)
	}
}
