import { Injectable } from '@angular/core'
import { LocalPrefsData } from '@app/models'

import { log } from '@app/helpers'

@Injectable({
	providedIn: 'root',
})
export class PrefsService {
	public static LOCAL_STORAGE_KEY = 'TTSAdmin-Prefs'

	public data: LocalPrefsData = new LocalPrefsData()

	public defaults: LocalPrefsData

	constructor() {
		log('Creating PrefsService')

		this.defaults = JSON.parse(JSON.stringify(this.data))
		this.load()
	}

	load(): void {
		const prefString: any = localStorage.getItem(PrefsService.LOCAL_STORAGE_KEY)
		const storedPrefs = JSON.parse(prefString)
		if (storedPrefs) {
			for (const attr in storedPrefs) {
				if (this.data.hasOwnProperty(attr)) {
					this.data[attr] = storedPrefs[attr]
				}
			}
		}
	}

	enableAutoLogout() {
		this.data.enableAutoLogout = true
		this.save()
	}

	disableAutoLogout() {
		this.data.enableAutoLogout = false
		this.save()
	}

	save(): void {
		const prefString = JSON.stringify(this.data)
		localStorage.setItem(PrefsService.LOCAL_STORAGE_KEY, prefString)
	}

	resetAllSettings(): void {
		this.data = JSON.parse(JSON.stringify(this.defaults))
		this.save()
	}

	setAutoLogoutTimer(seconds: number) {
		this.data.autoLogoutTimer = seconds
		this.save()
	}
}
