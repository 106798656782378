import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core'
import { UserReportType, UserReportImage, MailingAddress, Incident, VehicleInspectionReportData, ReportRendererConfig } from '@app/models'
import { log, DateTimeHelper } from '@app/helpers'

import _ from 'lodash'
import moment from 'moment-timezone'

class VehicleInspectionReportViewModel {
	isDesktop = false
	images: Array<UserReportImage> = []

	reportType: UserReportType
	reportData: VehicleInspectionReportData

	reportDate = 'DATE'
	reportInfo = {
		name: 'Name',
		startMileage: '',
		endMileage: '',
		totalMileage: 0,
		shiftLength: '',
		occupants: '',
		previousOccupant: '',
		notes: '',
	}

	skipLabel = '< intentionally skipped >'
	noDescription = '< no information provided >'

	format12Hour = true
	timezone: string
	timeFormat = 'h:mm a'

	logoUrl: string
	companyName: string
	address: MailingAddress

	conditions = []

	constructor(config: ReportRendererConfig) {
		const incident = config.reportData as Incident
		this.setupViewModel(incident, config)
		this.setupSectionData(config)
	}

	prepareForPrint() {}

	setupViewModel(incident: Incident, config: ReportRendererConfig) {
		this.images = incident.imageFiles.map((img) => new UserReportImage(img))
		this.reportType = incident.report_type as UserReportType
		this.reportData = JSON.parse(incident.report_json)?.reportData ?? new VehicleInspectionReportData()

		// Setup timezone
		const transMetaData = this.reportData.transactionMetaData
		const timezone = transMetaData?.timezone
		this.timezone = timezone || moment.tz.guess()

		// Setup time formatting
		this.format12Hour = config.formatTime12Hour

		// Setup report date
		const timeFormat = this.format12Hour ? 'h:mm a' : 'HH:mm'
		this.timeFormat = timeFormat
		this.reportDate = moment.tz(incident.created, timezone).format(`ddd MMM Do, YYYY @ ${timeFormat} z`)
	}

	setupSectionData(config: ReportRendererConfig) {
		this.setupHeader(config)
		this.setupReportInfo(config)
		this.setupVehicleConditions()
	}

	setupHeader(config: ReportRendererConfig) {
		this.logoUrl = config.logoUrl
		this.address = config.companyAddress
		this.companyName = config.companyName
	}

	setupReportInfo(config: ReportRendererConfig) {
		const reportData = this.reportData
		this.reportInfo.name = reportData.inspectionReporter.firstName.value + ' ' + reportData.inspectionReporter.lastName.value

		const startMileage = reportData.mileage.starting.value
		const endMileage = reportData.mileage.ending.value
		this.reportInfo.startMileage = startMileage || this.skipLabel
		this.reportInfo.endMileage = endMileage || this.skipLabel

		const actualStart = config.transaction.actual_start
		const actualEnd = config.transaction.actual_end
		if (actualStart && actualEnd) {
			const actualStartMom = moment(actualStart)
			const actualEndMom = moment(actualEnd)
			const duration = moment.duration(actualStartMom.diff(actualEndMom)).asMilliseconds()
			this.reportInfo.shiftLength = DateTimeHelper.formatDurationInHoursAndMinutes('H:mm', duration)
		}

		const startMileageInt = parseInt(startMileage, 10)
		const endMileageInt = parseInt(endMileage, 10)

		if (startMileageInt && endMileageInt) {
			this.reportInfo.totalMileage = endMileageInt - startMileageInt
		}

		const occupants = reportData.additionalInspectionInformation.occupants.value || this.skipLabel
		this.reportInfo.occupants = occupants.replace('\n', ', ')
		this.reportInfo.previousOccupant = reportData.additionalInspectionInformation.previousOccupant.value || this.skipLabel
		this.reportInfo.notes = reportData.additionalInspectionInformation.notes.value || this.skipLabel
	}

	setupVehicleConditions() {
		const lookup = this.reportData.vehicleCondition
		const conditions = [
			{ key: 'interior', label: 'Interior', value: '' },
			{ key: 'exterior', label: 'Exterior', value: '' },
			{ key: 'lightsAndSignals', label: 'Lights and Signals', value: '' },
			{ key: 'tires', label: 'Tires', value: '' },
			{ key: 'performance', label: 'Performance', value: '' },
		]
		for (const cond of conditions) {
			cond.value = lookup[cond.key]?.value || this.skipLabel
		}
		this.conditions = conditions
	}
}

@Component({
    selector: 'app-vehicle-inspection-report-renderer',
    templateUrl: './vehicle-inspection-report-renderer.component.html',
    styleUrls: ['./vehicle-inspection-report-renderer.component.scss'],
    standalone: false
})
export class VehicleInspectionReportRendererComponent implements OnInit, OnDestroy {
	constructor() {}

	public vm: VehicleInspectionReportViewModel
	public incident: Incident

	@Input() config: ReportRendererConfig
	@Input() publicView = false
	@Output() closeBtnClicked = new EventEmitter<boolean>()

	ngOnInit(): void {
		this.incident = this.config.reportData as Incident
		this.vm = new VehicleInspectionReportViewModel(this.config)
		log('Viewing Report', this.incident)
	}

	printPage() {
		this.vm.prepareForPrint()
		setTimeout(() => {
			window.print()
		}, 500)
	}

	ngOnDestroy() {}
}
