import { DateTimeHelper } from '@app/helpers'

export type ProjectViewState = 'ACTIVE' | 'INACTIVE' | 'ALL'

export class ProjectViewManager {
	version = 1
	isLoading = false
	currentView: ProjectViewState
	dayViewDate: Date
	timezone: string
}

export class ProjectRecord {
	id: number
	description: string
	created: string
	updated: string

	constructor(record: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
		}
	}
}
