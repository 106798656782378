<div class="login-wrapper">
	<div class="public-container" style="margin-top: -30px; margin-bottom: 50px" [hidden]="hideLogin">
		<form id="loginForm" role="form">
			<div class="form-group">
				<div class="logo-wrap">
					<img src="/assets/img/tts-logo.png" style="width: 100%" />
				</div>
				<!-- <h2 style="color: #777">
					Login
					<i *ngIf="isWorking" class="fas fa-loader fa-spin float-right" aria-hidden="true"></i>
				</h2> -->
			</div>

			<div *ngIf="errorMessage" class="alert alert-danger login-error">
				{{ errorMessage }}
			</div>
			<!-- <hr /> -->
			<div *ngIf="true" class="form-group" style="margin-top: 22px">
				<div class="row">
					<div class="col-4">
						<label class="region-label form-label">
							<span style="color: chocolate; font-size: 1.5rem; font-weight: 500">Region</span>
						</label>
					</div>
					<div class="col-8">
						<select class="form-select form-select float-right" [(ngModel)]="countryCode" name="countryCode" (change)="setRegion()">
							<option *ngFor="let code of countryCodeData" value="{{ code.id }}">{{ code.region }}</option>
						</select>
					</div>
				</div>
			</div>

			<div *ngIf="loginWithEmail" class="form-group" style="margin-top: 20px">
				<label class="form-label" for="signupEmail">Email Address </label>
				<input
					id="signupEmail"
					#signupEmail
					required
					type="email"
					maxlength="100"
					class="form-control"
					[(ngModel)]="email"
					[ngModelOptions]="{ standalone: true }"
					autocapitalize="off"
					autocorrect="off"
					autocomplete="off"
				/>
			</div>

			<div *ngIf="!loginWithEmail" class="form-group" style="margin-top: 20px">
				<label class="form-label" for="signupMobile">Mobile Number </label>
				<input
					id="signupMobile"
					#signupMobile
					required
					type="tel"
					maxlength="100"
					class="form-control"
					[(ngModel)]="mobile"
					[ngModelOptions]="{ standalone: true }"
					autocapitalize="off"
					autocorrect="off"
					autocomplete="off"
				/>
			</div>

			<div class="form-group">
				<label class="form-label" for="signupPassword">Password </label>
				<input
					id="signupPassword"
					required
					type="password"
					maxlength="25"
					class="form-control"
					length="40"
					[(ngModel)]="password"
					[ngModelOptions]="{ standalone: true }"
					autocomplete="off"
				/>
			</div>
			<div class="form-group">
				<p-checkbox name="groupname" [(ngModel)]="keepMeLoggedIn" [binary]="true"></p-checkbox>
				<strong style="color: #888" (click)="keepMeLoggedIn = !keepMeLoggedIn" class="has-pointer">Keep me logged in</strong>
			</div>
			<div class="form-group" style="margin-top: 25px; margin-bottom: 22px">
				<button (click)="onLogin()" id="signupSubmit" type="submit" class="btn btn-primary btn-block">
					<span *ngIf="!isWorking">Log in</span>
					<i *ngIf="isWorking" class="fas fa-loader fa-spin" style="color: white" aria-hidden="true"></i>
				</button>
			</div>
			<!-- <hr style="margin: 20px 0px 20px 0px" /> -->
			<div>
				<!-- <a [routerLink]="['/home/register']">
				<i class="fa fa-fw fa-group"></i> Register Account</a>
			<br /> -->
				<div *ngIf="false">
					<div *ngIf="!loginWithEmail">
						<a href="#" (click)="setLoginMethod('email')"> <i class="fa fa-fw fa-group"></i> Log in using email</a>
						<br />
					</div>
					<div *ngIf="loginWithEmail">
						<a href="#" (click)="setLoginMethod('mobile')"> <i class="fa fa-fw fa-group"></i> Log in using mobile</a>
						<br />
					</div>
				</div>
				<div>
					<a *ngIf="errorMessage" class="float-right" href="mailto:support@telephonetimesheets.com">
						<i class="far fa-envelope"></i> Email Support</a
					>
					<a [routerLink]="['/home/forgotPassword']"> <i class="far fa-lock"></i> Reset Password</a>
				</div>

				<!-- <br />
				<a *ngIf="false" [routerLink]="['/home/resendCode']"> <i class="fa fa-fw fa-group"></i> Resend confirmation code</a> -->
			</div>
		</form>
	</div>
</div>

<div [hidden]="!hideLogin" class="start-up-block-wrapper">
	<div class="start-up-block">
		<div class="start-up-label">Starting Up</div>
		<i class="fa fa-loader fa-spin start-up-spinner" aria-hidden="true"></i>
	</div>
</div>
