import { Component, Input } from '@angular/core'
import { DateTimeHelper, DisplayHelper, log } from '@app/helpers'
import { CustomExtendedProps, FCMouseEnterLeaveInfo } from '@app/models'
import { ScheduleLogRecord } from '@app/models/schedule-log'
import { CoreService, DatabaseService } from '@app/services'

import moment from 'moment-timezone'

@Component({
    selector: 'app-shift-view-event-details',
    templateUrl: './shift-view-event-details.component.html',
    styleUrls: ['./shift-view-event-details.component.scss'],
    standalone: false
})
export class ShiftViewEventDetailsComponent {
	isAvailable = false
	@Input() eventInfo: FCMouseEnterLeaveInfo = null

	isGlobalAccount = false

	constructor(private coreSrvc: CoreService) {
		if (coreSrvc.devDetect.isDesktop()) this.isAvailable = true
		this.isGlobalAccount = this.coreSrvc.dbSrvc.settingSrvc.isGlobalAccount()
	}

	get style(): Object {
		const style = this.eventInfo?.el.style
		return {
			'border-color': style.borderColor,
			'background-color': style.backgroundColor,
			'background-image': style.backgroundImage,
		}
	}

	get classList(): string {
		const classes = ['cal-event-block']
		let eventClasses = this.eventInfo?.el.classList.toString()
		eventClasses = eventClasses.replace('fade-out-in', '')
		if (this.schedLog?.effectiveEnabled()) {
			classes.push('cal-event-enabled')
		}
		return classes.join(' ') + ' ' + eventClasses
	}

	get isTimeOffEvent(): boolean {
		const extendedProps = this.eventInfo.event.extendedProps as CustomExtendedProps
		return extendedProps.isTimeOffEvent
	}

	get timeOffDate(): string {
		const date = this.eventInfo.event.start
		return moment(date).format('ddd, MMM Do YYYY')
	}

	get hasSchedLog(): boolean {
		return !!this.schedLog
	}

	get isShiftEnabled(): boolean {
		return this.schedLog?.effectiveEnabled()
	}
	get areNotificationsEnabled(): boolean {
		return this.schedLog?.enable_notifications
	}
	get isShiftFilled(): boolean {
		return !this.schedLog?.open_shift
	}

	get hasEmployeeOverride(): boolean {
		return this.schedLog.employee_id_override !== null
	}
	get originalEmployeeName(): string {
		return this.schedLog.employee_name
	}

	get overrideEmployeeName(): string {
		return this.schedLog.effectiveEmpName() // this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(this.schedLog.employee_id_override)?.name
	}

	get jobDate(): string {
		const timezone = this.schedLog.timezone
		return this.schedLog ? moment.tz(this.schedLog.job_date, timezone).format('ddd, MMM Do YYYY') : ''
	}

	get jobName(): string {
		if (this.isGlobalAccount) {
			const companyId = this.schedLog.company_id
			const company = this.coreSrvc.dbSrvc.settingSrvc.getGlobalCompanyById(companyId)
			if (company) {
				return this.schedLog?.job_description + ' / ' + company.name
			}
		} else {
			return this.schedLog?.job_description
		}
	}

	get schedLog(): ScheduleLogRecord {
		return this.eventInfo?.event?.extendedProps['schedLog'] ?? null
	}

	get eventTitle(): string {
		return this.eventInfo?.event?.title ?? 'No Event Title'
	}

	get startTime(): string {
		const is12Hours = DateTimeHelper.format12Hour
		const format = is12Hours ? 'h:mm a' : 'HH:mm'
		return moment(this.schedLog?.start_time, 'HH:mm').format(format) ?? 'START'
	}

	get endTime(): string {
		const is12Hours = DateTimeHelper.format12Hour
		const format = is12Hours ? 'h:mm a' : 'HH:mm'
		return moment(this.schedLog?.end_time, 'HH:mm').format(format) ?? 'START'
	}

	get tzAbrev(): string {
		return moment.tz(this.schedLog.timezone).zoneAbbr()
	}

	get shiftStatusEnabledText(): string {
		return this.isShiftEnabled ? 'Shift Enabled' : 'Shift Disabled'
	}
	get notificationsEnabledText(): string {
		return this.schedLog?.enable_notifications ? 'Notifications Enabled' : 'Notifications Disabled'
	}
	get shiftOpenText(): string {
		return this.schedLog?.open_shift ? 'Shift Open' : 'Shift Filled'
	}

	// get detachShiftDetailsView(): boolean {
	// 	return this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.shift.detachShiftDetailsView
	// }
}
