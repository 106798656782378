import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'

import {
	CheckinAnnouncementRecord,
	AnnouncementDataAccessRequest,
	DialogControlEvent,
	SelectorPermissionName,
	FileUploadManager,
	FileUploaderProcessedFile,
	FileUploaderMimeType,
	HelpDialogMessage,
} from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { DateTimeHelper, FormHelper, log } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'
import { environment } from '@env/environment'

import { Subscription } from 'rxjs'
import { SelectItem } from 'primeng/api'

import moment from 'moment-timezone'
import _ from 'lodash'

@Component({
    selector: 'app-announcements-checkin-edit',
    templateUrl: './checkin-edit.component.html',
    styleUrls: ['./checkin-edit.component.scss'],
    standalone: false
})
export class AnnouncementsCheckinEditComponent implements OnInit, OnDestroy {
	accessHelper: AccessHelper

	// isNew = false
	isUpdating = false
	is12Hour = DateTimeHelper.format12Hour
	editForm: UntypedFormGroup

	isEditingTranslations = false

	multiEmployeeDropdown: SelectItem[]
	multiJobSiteDropdown: SelectItem[]

	isAttachingFiles = false
	isUploadingFiles = false
	isUploadComplete = false

	showDateError = false
	expandAdvancedOptions = false

	filesTransferred: Array<FileUploaderProcessedFile> = []
	fileUploadManager = new FileUploadManager()

	subs = new Subscription()

	@Input() record: CheckinAnnouncementRecord
	@Output() dialogClosed = new EventEmitter<boolean>()
	@Output() recordUpdated = new EventEmitter<boolean>()

	constructor(
		private cd: ChangeDetectorRef,
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
	) {
		this.setupAccessPermissions()
		this.setupSubscriptions()
	}

	get transferCount(): number {
		return this.filesTransferred.length
	}

	get isNew(): boolean {
		return !this.record?.id
	}

	// get selectedMultiEmployeeNames(): Array<string> { - DEPRECATED with switch to multi-select-details
	// 	const empIds = this.editForm.get('filter_employee').value as Array<number>
	// 	if (empIds && empIds.length > 0) {
	// 		const names = empIds
	// 			.map(id => this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(id))
	// 			.filter(emp => !!emp)
	// 			.map(emp => emp.name)
	// 		return _.sortBy(names)
	// 	}
	// 	return []
	// }

	// get selectedMultiJobSiteNames(): Array<string> {
	// 	const siteIds = this.editForm.get('filter_location').value as Array<number>
	// 	if (siteIds && siteIds.length > 0) {
	// 		const names = siteIds
	// 			.map(id => this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(id))
	// 			.filter(site => !!site)
	// 			.map(site => site.description)
	// 		return _.sortBy(names)
	// 	}
	// 	return []
	// }

	private setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'announcement')
	}

	private processControlEvents(event: DialogControlEvent) {
		const callerId = event.callerId
		const direction = event.direction
		if (callerId === 'announcements' && direction === 'toContent') {
			const eventName = event.eventName
			if (eventName === 'saveBtnClicked') {
				this.saveBtnClicked()
			}
		}
	}

	private setupFileUploadManager() {
		log('Setup File Upload Manager')
		const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
		const fileUploadManager = new FileUploadManager()
		fileUploadManager.fileLimit = 3
		fileUploadManager.setConfiguration(companyId, environment.assetsBucket, 'announcements/checkin')
		fileUploadManager.useFileExtFallbackForMimeType = true
		fileUploadManager.supportedMimeTypes = ['text/plain', 'text/html', 'application/pdf', 'image/jpeg', 'image/png', 'image/gif']
		if (this.record) {
			const files = this.record.fileList.map((rec) => new FileUploaderProcessedFile(rec))
			fileUploadManager.processedFiles = files
			log('Files', files)
		}
		this.fileUploadManager = fileUploadManager
		log('File Manager', this.fileUploadManager)
	}

	isFormValid(): boolean {
		const isValid = this.editForm.valid && this.areDatesValid()
		const saveBtnState = isValid ? 'enableSaveBtn' : 'disableSaveBtn'
		const event = new DialogControlEvent('announcements', 'toDialog', saveBtnState, null)
		this.coreSrvc.eventSrvc.dialogNotification(event)
		return isValid
	}

	setupSubscriptions() {
		this.subs.add(this.coreSrvc.eventSrvc.dialogControlEvents.subscribe((event) => this.processControlEvents(event)))
	}

	ngOnInit() {
		// log('Init with', this.record)
		// this.isNew = !!this.record

		this.setupForm()
		this.setupMultEmployeeDropdown()
		this.setupMultJobSiteDropdown()
		this.setupFileUploadManager()
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	setupForm() {
		const record = this.record || new CheckinAnnouncementRecord(null)
		this.editForm = this.fb.group({
			id: [record.id],
			order: [record.order],
			name: [record.name, Validators.required],
			startDate: [this.makeDate(record.start_date), Validators.required],
			endDate: [this.makeDate(record.end_date), Validators.required],
			enabled: [record.enabled],
			alwaysPlay: [record.always_play],
			multiPlay: [record.multi_play],
			acknowledgement: [record.acknowledgement],
			bodyEN: [record.body_en, Validators.required],
			bodyES: [record.body_es],
			bodyCN: [record.body_cn],
			bodyFR: [record.body_fr],
			files: [record.files_json],
			filter_employee: [record.filter_employee],
			filter_location: [record.filter_location],
		})
		if (this.record) {
			this.filesTransferred = this.record.fileList
		} else {
			this.editForm.get('order').setValue(1)
			this.editForm.get('enabled').setValue(true)
		}
	}

	private setupMultEmployeeDropdown() {
		const permissions = this.accessHelper.getPermissionsFor('announcement')
		const isRestricted = permissions.isSelectorRestrictedFor(SelectorPermissionName.employee)
		const isManager = this.coreSrvc.dbSrvc.settingSrvc.isUserAManager()

		// Add any employees that you might not own that were set by unrestricted user
		const addEmpIds = this.editForm.get('filter_employee').value || []
		const dropdownData = this.coreSrvc.dbSrvc.empSrvc.getEmployeeDropdown(this.coreSrvc.dbSrvc, isRestricted, isManager, addEmpIds)
		log('isNew', this.isNew, addEmpIds)
		this.multiEmployeeDropdown = this.isNew
			? dropdownData.filter((item) => item.data.active)
			: dropdownData.filter((item) => item.data.active || addEmpIds.includes(item.data.id))
	}

	private setupMultJobSiteDropdown() {
		const permissions = this.accessHelper.getPermissionsFor('announcement')
		const isRestricted = permissions.owner.read && !permissions.access.read // permissions.isSelectorRestrictedFor(SelectorPermissionName.site)
		const isManager = this.coreSrvc.dbSrvc.settingSrvc.isUserAManager()
		log('MultiJobSite Setup', isRestricted, isManager)

		// Add any employees that you might not own that were set by unrestricted user
		const addSiteIds = this.editForm.get('filter_location').value || []
		const dropdown = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteDropdown(this.coreSrvc.dbSrvc, isRestricted, isManager, addSiteIds)
		this.multiJobSiteDropdown = dropdown
	}

	makeDate(dateStr: string): Date {
		log('Date String', dateStr)
		return dateStr ? moment(dateStr, 'YYYY-MM-DD').toDate() : null
	}

	setDate(control: string, date: Date) {
		this.editForm.get(control).setValue(date)
		this.areDatesValid()
	}
	areDatesValid() {
		const start = this.editForm.get('startDate').value
		const end = this.editForm.get('endDate').value
		if (start && end) {
			const startMom = moment(start)
			const endMom = moment(end)
			if (endMom.isBefore(startMom)) {
				this.showDateError = true
				return false
			}
		}
		this.showDateError = false
		return true
	}

	toggleCheckbox(control: string) {
		const value = this.editForm.get(control).value
		this.editForm.get(control).setValue(!value)
	}

	cancelBtnClicked() {
		this.dialogClosed.emit(true)
	}

	saveBtnClicked() {
		// Guard against double submission
		if (this.isUpdating) return
		// FormHelper.trimOnlyWhitespace(this.editForm)

		const record = this.makeUpdateRecord()
		const request = new AnnouncementDataAccessRequest(record)
		if (this.record) {
			request.operation = 'update'
		} else {
			request.operation = 'insert'
		}
		log('Update Record', record)
		// return
		this.isUpdating = true
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			log('Save lambda result', result)
			this.recordUpdated.emit(true)
			this.dialogClosed.emit(true)
		})
	}

	makeUpdateRecord(): CheckinAnnouncementRecord {
		const record = new CheckinAnnouncementRecord(null)
		const form = this.editForm.value

		record.id = form.id
		record.company_id = form.company_id
		record.order = form.order
		record.name = form.name
		record.start_date = moment(form.startDate).format('YYYY-MM-DD')

		const endMom = moment(form.endDate)
		if (endMom.isValid()) {
			record.end_date = endMom.format('YYYY-MM-DD')
		}

		record.enabled = form.enabled
		record.always_play = form.alwaysPlay
		record.multi_play = true
		record.acknowledgement = form.acknowledgement
		if (record.always_play) {
			record.acknowledgement = false
		}
		record.body_en = form.bodyEN
		record.body_es = form.bodyES
		record.body_fr = form.bodyFR
		record.body_cn = form.bodyCN

		// record.files_json = this.filesTransferred.length > 0 ? JSON.stringify(this.filesTransferred) : null
		const processedFiles = this.fileUploadManager.processedFiles
		record.files_json = processedFiles.length > 0 ? JSON.stringify(processedFiles) : null
		record.filter_employee = this.editForm.get('filter_employee').value
		record.filter_location = this.editForm.get('filter_location').value
		return record
	}

	// Uploading methods

	showBackBtn() {
		const event = new DialogControlEvent('announcements', 'toDialog', 'showBackBtn', null)
		this.coreSrvc.eventSrvc.dialogNotification(event)
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'message':
				help.header = 'Message'
				help.message =
					'Enter the English language text for the announcement here. When saved, this announcement message will be automatically translated into all supported languages.'
				break
			case 'alwaysPlay':
				help.header = 'Always Play'
				help.message =
					'When selected, this announcement will repeat each time an employee calls in. When not selected, the announcement will play once for each employee.'
				break
			case 'acknowledgement':
				help.header = 'Acknowledgements'
				help.message = 'When selected, this announcement will require the employee to acknowledge they have heard the message.'
				break
			case 'continue':
				help.header = 'Continue to next'
				help.message = 'When selected, the system will move on to the next enabled announcement.'
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = 'No help information for this topic is currently available.'
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
