<div
	*ngIf="!vm.status.isPlacingCall && !vm.status.isPreparingText && !vm.status.isSendingMessage"
	[class.ctc-min-height]="vm.records.length > 1"
	class="ctc-directory"
>
	<div *ngIf="vm.records.length === 0" class="ctc-status">No Call Records Found</div>

	<div *ngIf="vm.records.length > 1" style="margin-bottom: 15px">
		<p-select
			#listDropdown
			scrollHeight="260px"
			[options]="vm.options"
			[(ngModel)]="vm.selectedOptionType"
			optionLabel="label"
			optionValue="type"
			placeholder="Select Contact Type"
		></p-select>
	</div>

	<div *ngIf="vm.selectedOptionType === null" style="margin-bottom: 15px">
		<div class="alert alert-warning" style="margin-top: 25px">
			Select a contact type to view a specific list. Click the <strong>Call</strong> button to connect a call.
		</div>
	</div>

	<div class="ctc-list hide-scrollbars">
		<div class="ctc-type-header" *ngIf="vm.records.length === 1">{{ vm.getTitleForType(vm.records[0].sourceType) }}</div>

		<div *ngIf="vm.selectedOptionType !== null">
			<div *ngFor="let rec of vm.getRecords(vm.selectedOptionType); let idx = index">
				<div class="card ctc-card">
					<div>
						<div class="ctc-desc tts-hl-2">
							<div class="ctc-contact-type float-right">{{ vm.formatContactType(rec.contactType) }}</div>
							<div>{{ rec.description }}</div>
						</div>
						<div *ngIf="rec.notes" class="ctc-notes">
							{{ rec.notes }}
						</div>
					</div>

					<!-- Phone Number -->
					<div style="margin-top: 2px">
						<div class="ctc-call-btn-block float-right">
							<i
								*ngIf="canAccessCallBtn && rec.phoneE164"
								title="Place Call"
								class="far fa-phone-office c2c-ico link-text"
								style="margin-right: 6px"
								(click)="placeCall.emit(rec)"
							></i>
							<i
								*ngIf="canAccessCallBtn && rec.phoneE164"
								title="Send Text"
								class="far fa-message-dots c2c-ico link-text"
								(click)="startTextMsg.emit(rec)"
							></i>
						</div>
						<div *ngIf="rec.phoneE164" class="ctc-e164">{{ rec.phoneE164 | displayPhone }}</div>
						<div *ngIf="!rec.phoneE164" class="ctc-e164">No number available</div>
					</div>

					<!-- Email Address -->
					<div class="ctc-email trunc-ellipsis">
						<a *ngIf="rec.email" class="link-text ctc-email" [href]="'mailto:' + rec.email">{{ rec.email }}</a>
					</div>
					<!-- Managed By -->
					<div class="ctc-managed-by" *ngIf="rec.managerName">Managed By: {{ rec.managerName }}</div>
				</div>
			</div>
		</div>
	</div>
</div>
