import { Router } from '@angular/router'
import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'

import { CoreService, DatabaseService } from '@app/services'
import { DataAccessRequest, CrudAction } from '@app/models'

import { log } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'

@Component({
    selector: 'app-settings-close-account',
    templateUrl: './settings-close-account.component.html',
    styleUrls: ['./settings-close-account.component.scss'],
    standalone: false
})
export class SettingsCloseAccountComponent implements OnInit {
	CrudAction = CrudAction
	accessHelper: AccessHelper

	showDialog = false
	isWorking = false
	approveClose = false

	constructor(private router: Router, private coreSrvc: CoreService, private location: Location) {
		this.setupAccessPermissions()
	}

	ngOnInit() {}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'account')
	}
	canPerformAction(action: CrudAction) {
		return this.accessHelper.canPerformAction(action, false)
	}

	onCloseAccount(): boolean {
		this.showDialog = true
		return false
	}

	onCancelClose() {
		this.cancelBtnClicked()
	}

	onConfirmClose(): boolean {
		this.isWorking = true
		const request = new DataAccessRequest('none', 'close_account')
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			this.router.navigate(['/logout'])
		})
		return false
	}

	cancelBtnClicked() {
		this.location.back()
	}
}
