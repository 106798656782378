import { Component, OnInit } from '@angular/core'
import { DateTimeHelper, log } from '@app/helpers'
import { AdpEarningsCodeRecord, AdpPayCycle, AdpRateCodeRecord, AdpSyncRecord, DialogManager, DatabaseTableName } from '@app/models'
import { CoreService } from '@app/services'
import { SelectItem } from 'primeng/api'
import { environment } from '@env/environment'

import moment from 'moment-timezone'

class AdpIntegrationViewModel {
	empSyncDate = null
	empSyncErrorCount = 0
	showEmpTooltip = false

	transSyncDate = null
	transSyncErrorCount = 0
	showTransTooltip = false

	// WFN Properties
	isWfnIntegrated = false
	payrollPeriod: string = null
	payrollStartDate: Date = null
	payrollGroupCode: string = null

	constructor(adpSync?: AdpSyncRecord) {
		if (adpSync) {
			const empSyncDateString = adpSync.employee_sync
			if (empSyncDateString) {
				this.empSyncDate = DateTimeHelper.mediumDateTimeFromDateString(empSyncDateString) // moment(empSyncDateString).format('ddd MMM Do, YYYY')
				this.empSyncErrorCount = adpSync.employee_sync_errors
			}
			const transSyncDateString = adpSync.transaction_log_sync
			if (transSyncDateString) {
				this.transSyncDate = DateTimeHelper.mediumDateTimeFromDateString(transSyncDateString) // moment(transSyncDateString).format('ddd MMM Do, YYYY')
				this.transSyncErrorCount = adpSync.transaction_log_sync_errors
			}
			// Setup WFN options
			log('Initial ADPVM setup', adpSync)
			if (adpSync.isWfnIntegrated()) {
				log('Setup WFN')
				this.isWfnIntegrated = true
				this.payrollPeriod = adpSync.payroll_period
				this.payrollStartDate = moment(adpSync.payroll_start_date).toDate()
				this.payrollGroupCode = adpSync.payroll_group_code
			}
		}
	}
}

@Component({
    selector: 'app-adpintegration',
    templateUrl: './adpintegration.component.html',
    styleUrls: ['./adpintegration.component.scss'],
    standalone: false
})
export class ADPIntegrationComponent implements OnInit {
	isWorking = { empImport: false, transExport: false }
	isDataLoaded = false
	isIntegrated = false

	vm = new AdpIntegrationViewModel()

	adpSync = new AdpSyncRecord()
	updatedAdpSync = new AdpSyncRecord()

	earningsCodes: Array<SelectItem> = []
	rateCodes: Array<SelectItem> = []
	payrollGroupCodes: Array<SelectItem> = []

	selectedPayrollReg: AdpEarningsCodeRecord
	selectedPayrollOt: AdpEarningsCodeRecord
	selectedPayrollHol: AdpEarningsCodeRecord
	selectedPayrollOtHol: AdpEarningsCodeRecord

	wfnFrequencyOptions: Array<SelectItem> = []

	// adpRateCodes: Array<AdpRateCodeSelectItem> = []
	selectedPtoPersonal: AdpEarningsCodeRecord
	selectedPtoSick: AdpEarningsCodeRecord
	selectedPtoHoliday: AdpEarningsCodeRecord
	selectedPtoBereavement: AdpEarningsCodeRecord

	payCycles: Array<SelectItem> = [{ label: 'Loading pay cycles ...', value: null }]
	selectedPayCycle: AdpPayCycle

	dialogManager = new DialogManager()

	companyUuid = 'company-uuid'
	companyUuidCopied = false

	selectedProduct: 'RUN' | 'WFN'
	adpMarketplaceVisited = false
	// adpMarketplaceUrl = environment.adpRunMarketplaceAppUrl

	isMobile = false

	constructor(private coreSrvc: CoreService) {
		this.checkForIntegration()
		this.loadData()
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		this.companyUuid = company.uuid
		this.isMobile = this.coreSrvc.devDetect.isMobile()
		// this.adpRateCodes = this.coreSrvc.dbSrvc.adpSrvc.getAdpRateCodeDropdown(true)
	}

	ngOnInit(): void {}

	checkForIntegration() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		this.isIntegrated = !!company.adp_id
	}

	formatPayCycleLabel(pc: AdpPayCycle): string {
		const period = pc.shortName
		const start = pc.openCalendarStartDate
		const end = pc.openCalendarEndDate
		const check = pc.openCalendarCheckDate
		return `${period}: ${start} to ${end} w/check on ${check}`
	}

	getPayCycles() {
		const request = { operation: 'pay_cycles' }
		this.coreSrvc.dbSrvc.lambdaSrvc.adpSync(request).then((result) => {
			log('Paycycles Result', result)
			const payCycles = result.map((pc) => new AdpPayCycle(pc))
			const payCycleDropdown = payCycles.map((pc) => ({
				label: this.formatPayCycleLabel(pc),
				value: pc,
			}))
			payCycleDropdown.unshift({ label: 'Select Pay Cycle', value: null })
			this.payCycles = payCycleDropdown
			log('Pay Cycles', this.payCycles)
		})
	}

	loadData() {
		log('Loading ADP Data')
		const tables: Array<DatabaseTableName> = ['adp_department_code', 'adp_payroll_group_code', 'adp_earnings_code', 'adp_rate_code', 'adp_sync']
		this.coreSrvc.dbSrvc.bulkRead(tables).then((result) => {
			// log('Result', result)
			this.coreSrvc.dbSrvc.adpSrvc.logRecords()
			this.setupComponent()
			this.isDataLoaded = true
		})
		if (this.isIntegrated) {
			this.getPayCycles()
		}
	}

	setupComponent() {
		if (!this.isIntegrated) {
			return
		}
		log('Setup ADP Integration Component')
		// Setup Sync Record
		const adpSync = this.coreSrvc.dbSrvc.adpSrvc.getAdpSync()
		this.adpSync = new AdpSyncRecord(adpSync)
		this.vm = new AdpIntegrationViewModel(adpSync)
		// Setup earnings codes dropdown
		this.earningsCodes = this.coreSrvc.dbSrvc.adpSrvc.getAdpEarningsCodesDropdown()
		this.rateCodes = this.coreSrvc.dbSrvc.adpSrvc.getAdpRateCodeDropdown(true)
		this.payrollGroupCodes = this.coreSrvc.dbSrvc.adpSrvc.getAdpPayrollGroupCodesDropdown()
		// this.wfnPayrollGroupCodeOptions = this.coreSrvc.dbSrvc.adpSrvc.getAdpWfnPayrollGroupCodeDropdown()
		this.wfnFrequencyOptions = this.coreSrvc.dbSrvc.adpSrvc.getAdpWfnFrequencyDropdown()

		// log('Earnings Codes', this.earningsCodes)
		// Setup selected dropdowns
		this.selectedPayrollReg = this.getEarningsCodeForId(adpSync.payroll_reg)
		this.selectedPayrollOt = this.getEarningsCodeForId(adpSync.payroll_ot)
		this.selectedPayrollHol = this.getEarningsCodeForId(adpSync.payroll_hol)
		this.selectedPayrollOtHol = this.getEarningsCodeForId(adpSync.payroll_othol)

		this.selectedPtoPersonal = this.getEarningsCodeForId(adpSync.pto_personal)
		this.selectedPtoSick = this.getEarningsCodeForId(adpSync.pto_sick)
		this.selectedPtoHoliday = this.getEarningsCodeForId(adpSync.pto_holiday)
		this.selectedPtoBereavement = this.getEarningsCodeForId(adpSync.pto_bereavement)
	}

	getEarningsCodeForId(id: number): AdpEarningsCodeRecord {
		const codeList = this.earningsCodes.filter((ec) => ec.value !== null)
		const result = codeList.find((ec) => ec.value.id === id)
		if (result) {
			return result.value as AdpEarningsCodeRecord
		}
		return null
	}

	getRateCodeForId(id: number): AdpRateCodeRecord {
		const rateList = this.rateCodes.filter((rc) => rc.value !== null)
		const result = rateList.find((rc) => rc.value.id === id)
		if (result) {
			return result.value as AdpRateCodeRecord
		}
		return null
	}

	canSetDropdown(id: string) {
		switch (id) {
			case 'payroll_reg':
				return true
			case 'payroll_ot':
				return !!this.selectedPayrollReg
			case 'payroll_hol':
				return !!this.selectedPayrollReg
			case 'payroll_othol':
				return !!!!this.selectedPayrollReg && !!this.selectedPayrollOt && !!this.selectedPayrollHol
			default:
				return true
		}
	}

	importEmployeeData() {
		// log('Import Employee Data')
		const isWfn = this.adpSync.isWfnIntegrated()
		const nextSyncMoment = this.getNextSyncMoment()
		const syncDelay = moment.duration(this.adpSync.emp_sync_delay).asHours()

		if (isWfn && nextSyncMoment) {
			log('nextSyncMoment', nextSyncMoment)
			const is12Hour = DateTimeHelper.format12Hour
			const formatString = is12Hour ? 'ddd MMM Do @ h:mm a' : 'ddd MMM Do @ HH:mm'
			const availableString = nextSyncMoment.format(formatString)
			this.coreSrvc.notifySrvc.notify(
				'info',
				'Import Disabled',
				`You may only import employee data once every ${syncDelay} hours. Import will be available again on ${availableString}.`,
			)
			return
		}

		this.isWorking.empImport = true
		this.coreSrvc.notifySrvc.notify(
			'success',
			'Import Started',
			'Employee data is now being imported from ADP. You will be notified when it is complete.',
		)
		const request = { table: null, operation: 'employee' }
		this.coreSrvc.dbSrvc.lambdaSrvc.adpSync(request).then((result) => {
			log('Result from lambda call', result)
			const errorType = result?.errorType
			if (errorType === 'com.sst.adp.AdpClientCredsException') {
				this.isWorking.empImport = false
				this.coreSrvc.notifySrvc.notify('error', 'Consent Required', 'ADP consent required before we can sync data.')
				this.showADPConsentSite()
			} else {
				this.isWorking.empImport = false
				this.loadData()
				this.coreSrvc.dbSrvc.readTable('employee').then((empReadResult) => {
					this.coreSrvc.notifySrvc.notify('success', 'Import Finished', 'Employee data has finished importing from ADP.')
				})
			}
		})
	}

	showADPConsentSite() {
		setTimeout(() => {
			window.open('https://adpapps.adp.com/consent-manager/', '_ADPConsent')
		}, 1000)
	}

	getNextSyncMoment(): moment.Moment {
		// Check if it's ok to import employee data. If not, return moment indicated when sync will be available
		const adpSync = this.adpSync
		const syncTime = adpSync.employee_sync
		log('SyncTime', syncTime)

		const empDelay = moment.duration(adpSync.emp_sync_delay)

		if (syncTime) {
			const lastSyncMom = moment(syncTime).startOf('minute').add(1, 'minutes')
			const nextSyncMom = lastSyncMom.clone().add(empDelay)
			if (moment().isAfter(nextSyncMom, 'second')) {
				return null
			}
			return nextSyncMom
		} else {
			// No sync lock in place
			return null
		}
	}

	exportTransactionData() {
		log('Export Transaction Data')
		// this.coreSrvc.notifySrvc.notify('success', 'Export Started', 'Transaction data exporting would be started at this point and you would be notified when it is done.')
		// return
		this.isWorking.transExport = true
		this.coreSrvc.notifySrvc.notify(
			'success',
			'Export Started',
			'Transaction data is now being exported to ADP. You will be notified when it is complete.',
		)
		const payCycleCode = this.selectedPayCycle.code
		const openCalendarStartDate = this.selectedPayCycle?.openCalendarStartDate ?? null
		const openCalendarEndDate = this.selectedPayCycle?.openCalendarEndDate ?? null

		const request = {
			table: null,
			operation: 'transaction_log',
			pay_cycle: payCycleCode,
			openCalendarStartDate: openCalendarStartDate,
			openCalendarEndDate: openCalendarEndDate,
		}

		log('Export Transaction Operation', request)
		this.coreSrvc.dbSrvc.lambdaSrvc.adpSync(request).then((result) => {
			this.isWorking.transExport = false
			this.loadData()
			this.coreSrvc.notifySrvc.notify('success', 'Export Finished', 'Transaction data has finished exporting to ADP.')
		})
	}

	payrollDropdownChanged() {
		if (!this.selectedPayrollReg) {
			this.selectedPayrollOt = null
			this.selectedPayrollHol = null
			this.selectedPayrollOtHol = null
		}
		if (!this.selectedPayrollOt || !this.selectedPayrollHol) {
			this.selectedPayrollOtHol = null
		}
	}

	payCycleDropdownChanged() {
		log('Pay cycle dropdown changed.')
	}

	updateBtnClicked() {
		const record = this.makePayrollUpdateRecord()
		log('Record to send', record)
		if (!record) {
			return
		}
		if (this.vm.isWfnIntegrated) {
			if (!this.vm.payrollGroupCode) {
				this.coreSrvc.notifySrvc.notify(
					'error',
					'Missing Info',
					'The Payroll Group Code is required. Please select one before updating your settings.',
				)
				return
			}
		}

		this.updatedAdpSync = record
		this.coreSrvc.dbSrvc.updateRecord('adp_sync', record).then((result) => {
			log('Update Result', result)
			this.loadData()
		})
	}

	isUpdateBtnDisabled(): boolean {
		if (!this.selectedPayrollReg) {
			return true
		}
		return false
	}

	makeUpdateRecordPayrollStartDate(): string {
		const startDate = this.vm.payrollStartDate
		if (!startDate) {
			return null
		} else {
			const startMom = moment(startDate)
			if (startMom.isValid()) {
				return startMom.format('YYYY-MM-DD')
			}
		}
		return null
	}

	makePayrollUpdateRecord(): AdpSyncRecord {
		const record = new AdpSyncRecord(this.adpSync)

		record.payroll_reg = this.selectedPayrollReg?.id ?? null
		record.payroll_ot = this.selectedPayrollOt?.id ?? null
		record.payroll_hol = this.selectedPayrollHol?.id ?? null
		record.payroll_othol = this.selectedPayrollOtHol?.id ?? null

		record.payroll_period = this.vm.payrollPeriod
		record.payroll_start_date = this.makeUpdateRecordPayrollStartDate()
		record.payroll_group_code = this.vm.payrollGroupCode

		record.pto_personal = this.selectedPtoPersonal?.id ?? null
		record.pto_sick = this.selectedPtoSick?.id ?? null
		record.pto_holiday = this.selectedPtoHoliday?.id ?? null
		record.pto_bereavement = this.selectedPtoBereavement?.id ?? null

		return record
	}

	copyUuid() {
		log('Copy UUID')
		const copyInput = document.getElementById('companyUuid') as any

		/* Select the text field */
		copyInput.select()
		copyInput.setSelectionRange(0, 99999) /* For mobile devices */

		/* Copy the text inside the text field */
		document.execCommand('copy')
		copyInput?.blur()
		this.companyUuidCopied = true
	}

	gotoAdpMarketplace(product: 'RUN' | 'WFN') {
		this.adpMarketplaceVisited = true
		if (product === 'RUN') {
			this.selectedProduct = 'RUN'
			window.open(environment.adpRunMarketplaceAppUrl)
		}
		if (product === 'WFN') {
			this.selectedProduct = 'WFN'
			window.open(environment.adpWfnMarketplaceAppUrl)
			// this.coreSrvc.notifySrvc.notify(
			// 	'info',
			// 	'Workforce Now',
			// 	'ADP Workforce now connector is currently being finalized. Please contact support for more information'
			// )
		}
	}

	finishIntegrationBtnClicked() {
		if (this.selectedProduct === 'RUN') {
			window.open(environment.adpRunIntegrationConsentUrl, '_self')
		}
		if (this.selectedProduct === 'WFN') {
			window.open(environment.adpWfnIntegrationConsentUrl, '_self')
		}
	}

	reloadApplication() {
		this.coreSrvc.dbSrvc.settingSrvc.reloadApplication()
		// const element = $('#loading-spinner')
		// element.fadeTo(0, 1, () => {
		// 	element.show()
		// 	top.frames.location.reload()
		// })
	}
}
