<p-fileUpload
	#fileImportUploader
	name="myfile[]"
	[customUpload]="true"
	chooseLabel="Upload"
	[auto]="true"
	[multiple]="true"
	(uploadHandler)="uploadHandler($event)"
>
	<ng-template let-file pTemplate="content">
		<div class="upload-info">
			<div *ngIf="!status.isUploading">Click the Upload button to upload files or drag and drop the files here.</div>
			<div *ngIf="status.isUploading" class="upload-active">
				<i class="fas fa-loader fa-spin"></i> Uploading File ... {{ status.percentComplete }}%
			</div>
			<!-- <div *ngIf="status.isProcessing" class="upload-active"><i class="fas fa-loader fa-spin"></i> Processing File ...</div> -->
		</div>
	</ng-template>
	<ng-template pTemplate="file">
		<!-- Template for individual file -->
	</ng-template>
</p-fileUpload>

<div *ngIf="invalidFiles.length > 0" style="margin-top: 25px">
	<div class="float-right link-text" (click)="invalidFiles = []">clear</div>
	<div class="tts-hl-2" style="font-weight: bold">Unsupported Files</div>
	<hr class="files-invalid-hr" />
	<div *ngFor="let file of invalidFiles">
		<div class="col-11">
			{{ file.name }}
		</div>
	</div>
	<hr class="files-invalid-hr" />
	<div class="text-muted" class="invalid-file-note">
		The files listed above were not uploaded becase the filename is not valid. Please make sure your employee import file has
		<strong>employee</strong> in the name and your job import file has <strong>job</strong> in the filename.
	</div>
</div>
