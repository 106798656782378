<div *ngIf="images.length > 0">
	<div class="row">
		<div *ngFor="let img of images; let idx = index" class="col-auto">
			<div class="urr-img-box no-page-break" (click)="showFullScreen(img.url)">
				<img class="img-fluid" [src]="img.url" />
				<div style="text-align: center; font-variant: small-caps; font-size: 0.8em">photo {{ idx + 1 }}</div>
			</div>
		</div>
	</div>
	<div *ngIf="showDescriptions">
		<div *ngFor="let img of images; let idx = index">
			<div *ngIf="img.description">
				<div class="urr-label urr-header-label">Photo {{ idx + 1 }}:</div>
				{{ img.description ? img.description : noDescriptionProvided }}
			</div>
		</div>
	</div>
</div>
