<div *ngIf="!showPrintable">
	<canvas id="qrcode-canvas" class="qr-canvas"></canvas>
</div>

<div *ngIf="showPrintable" class="d-flex justify-content-center align-items-center">
	<div style="text-align: center">
		<div><canvas id="qrcode-canvas" class="qr-canvas"></canvas></div>
		<div *ngIf="isPrintBtnVisible" class="outline-btn" style="margin-top: 12px" (click)="printCode()">printable version</div>
	</div>
</div>
