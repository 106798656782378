import { log } from '@app/helpers/logger'
import { DatesSetArg } from '@fullcalendar/core'
import { Subject } from 'rxjs'
import moment from 'moment-timezone'

export type ScheduleViewTabState = 'SERIES' | 'SHIFT' | 'TIMEOFF' | 'CHANGES'
export type ShiftViewEventBackgroundColorSource = 'SCHED' | 'EMP' | 'JOB'
// Series View

export type ScheduleViewSeriesSubSectionType = 'CURRENT' | 'PENDING'

export type ScheduleViewSeriesOption = 'EMP' | 'JOB'
export class ScheduleViewSeriesState {
	option: ScheduleViewSeriesOption = 'JOB'
	searchText = ''
	subSection: ScheduleViewSeriesSubSectionType = 'CURRENT'

	showExpiredSchedules = true
	showDisabledSchedules = true
	selectedTags: Array<string> = []
	tagFilterMethod: 'OR' | 'AND' = 'AND'

	isBatchingEnabled = false

	constructor(data?: any) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
	}
}

// Shift View
export type ScheduleViewShiftOption = 'EMP' | 'JOB'
export class ScheduleViewShiftState {
	option: ScheduleViewShiftOption = 'JOB'
	searchText = ''
	selectedJobId: number = null
	selectedJobIds: Array<number> = []
	selectedEmpIds: Array<number> = []
	shiftEventBgColorSource: ShiftViewEventBackgroundColorSource = 'SCHED'
	weekStart = 1
	slotDuration = '00:60:00'
	showTimeOff = true
	showOpenShifts = true
	showDisabledShifts = true

	userSideScrollForOpenShiftList = true
	restrictOpenShiftsToRange = false
	// detachShiftDetailsView = true

	constructor(data?: any) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
		this.selectedEmpIds = []
		this.selectedJobIds = []
	}
	removeSelectedEmpId(id: number) {
		this.selectedEmpIds = this.selectedEmpIds.filter((empId) => empId !== id)
	}
	removeSelectedJobId(id: number) {
		this.selectedJobIds = this.selectedJobIds.filter((jobId) => jobId !== id)
	}
	setSlotSize(slotSize: string) {
		this.slotDuration = slotSize
	}
}

// Time Off View

export type ScheduleViewTimeOffSubSectionType = 'DIRECT' | 'APPROVED' | 'PENDING' | 'REJECTED'

export class ScheduleViewTimeOffState {
	subSection: ScheduleViewTimeOffSubSectionType = 'APPROVED'
	searchText = ''

	constructor(data?: any) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
	}
}

export class ScheduleViewManager {
	version = 1
	currentView: ScheduleViewTabState = 'SERIES'
	isSwitchingViews = false

	series = new ScheduleViewSeriesState()
	shift = new ScheduleViewShiftState()
	timeOff = new ScheduleViewTimeOffState()

	currentSearchText: String = null
	searchNeedsUpdate = new Subject<string>()

	currentDayViewDate: Date = null

	shiftViewEmpListNeedsUpdate = new Subject<boolean>()
	shiftViewJobListNeedsUpdate = new Subject<boolean>()

	scheduleViewSectionChanged = new Subject<ScheduleViewTabState>()
	seriesViewSubSectionChanged = new Subject<ScheduleViewSeriesSubSectionType>()
	timeOffViewSubSectionChanged = new Subject<ScheduleViewTimeOffSubSectionType>()

	scheduleViewDateRangeChanged = new Subject<'PREV' | 'NEXT'>()

	constructor() {
		this.restore()
	}

	get currentViewState() {
		switch (this.currentView) {
			case 'SERIES':
				return this.series
			case 'SHIFT':
				return this.shift
		}
	}

	public setScheduleViewSection(section: ScheduleViewTabState) {
		this.currentView = section
		this.scheduleViewSectionChanged.next(section)
	}

	public setSeriesViewSubSection(subSection: 'CURRENT' | 'PENDING') {
		this.setCurrentView('SERIES')
		this.series.subSection = 'PENDING'
		this.scheduleViewSectionChanged.next('SERIES')
		this.seriesViewSubSectionChanged.next(subSection)
	}

	public setTimeOffViewSubSection(subSection: 'APPROVED' | 'PENDING') {
		this.setCurrentView('TIMEOFF')
		this.timeOff.subSection = subSection
		this.scheduleViewSectionChanged.next('TIMEOFF')
		this.timeOffViewSubSectionChanged.next(subSection)
	}

	public setShiftViewOption(option: 'JOB' | 'EMP') {
		this.shift.option = option
		this.save()
	}

	public setShiftViewSelectedJobId(id: number) {
		this.shift.selectedJobId = id
		this.save()
	}

	public setShiftViewSelectedEmpIds(ids: Array<number>) {
		this.shift.selectedEmpIds = ids
		// this.save()
	}

	public setCurrentView(view: ScheduleViewTabState) {
		this.currentView = view
		this.save()
	}

	public setDayViewDate(date: Date) {
		this.currentDayViewDate = date
		this.save()
	}

	public setShiftViewEventBgColorSource(source: ShiftViewEventBackgroundColorSource) {
		this.shift.shiftEventBgColorSource = source
		this.save()
	}

	public setShiftViewWeekStart(isoDay: number) {
		this.shift.weekStart = isoDay
		this.save()
	}

	public performSearch(type: ScheduleViewTabState, searchText: string) {
		switch (type) {
			case 'SERIES':
				this.series.searchText = searchText
				if (this.currentView === 'SERIES') {
					this.currentSearchText = searchText
				}
				break
			case 'SHIFT':
				this.shift.searchText = searchText
				if (this.currentView === 'SHIFT') {
					this.currentSearchText = searchText
				}
				break
			default:
				this.series.searchText = searchText
				this.shift.searchText = searchText
				this.currentSearchText = searchText
		}
		this.searchNeedsUpdate.next(searchText)
	}

	public save() {
		const saveData = {
			version: this.version,
			currentSearchText: this.currentSearchText,
			currentDayViewDate: this.currentDayViewDate,
			currentView: this.currentView,
			series: this.series,
			shift: this.shift,
		}
		localStorage.setItem('ScheduleViewState', JSON.stringify(saveData))
	}

	private restore() {
		try {
			const record = JSON.parse(localStorage.getItem('ScheduleViewState'))
			if (record) {
				for (const attr in record) {
					if (record.hasOwnProperty(attr)) {
						this[attr] = record[attr]
					}
				}
				const dayViewDateStr = record['currentDayViewDate']
				this.currentDayViewDate = dayViewDateStr ? new Date(dayViewDateStr) : null
				this.series = new ScheduleViewSeriesState(this.series)
				this.shift = new ScheduleViewShiftState(this.shift)
				// this.performSearch(null, null)
			}
		} catch (err) {
			log('Error restoring ScheduleViewState', err)
		}
		this.currentSearchText = null
		this.series.searchText = null
		this.series.isBatchingEnabled = false
		this.series.selectedTags = []
		this.shift.searchText = null
		this.series.subSection = 'CURRENT'
		this.isSwitchingViews = false // Override in case reload during switch
	}
}

export class ScheduleShiftViewManager {
	version = 1
	timezone = 'UTC'
	tzAbbrev = 'UTC'
	windowSize = 28
	endMom: moment.Moment = null
	dateArg: DatesSetArg = null
	shouldShowWarning = false
	displayDate = null

	public setWindowSize(days: number) {
		this.windowSize = days
		this.endMom = moment().add(days, 'days')
	}

	public updateDateInfo(dateArg: DatesSetArg, timezone: string) {
		this.dateArg = dateArg
		this.timezone = timezone
		this.tzAbbrev = moment.tz(timezone).zoneAbbr()
		const endDate = dateArg.end
		if (endDate) {
			const lastScheduledDay = moment(endDate).subtract(1, 'day')
			if (lastScheduledDay.isValid() && lastScheduledDay.isAfter(this.endMom)) {
				this.shouldShowWarning = true
			} else {
				this.shouldShowWarning = false
			}
		}
		// log('viewWindow', this)
	}

	public getLoaderDates(): { start: string; end: string } {
		if (!this.dateArg) {
			return { start: null, end: null }
		}
		// log('Date Arg', this.dateArg)
		const start = moment(this.dateArg.start).tz(this.timezone).format('YYYY-MM-DD')
		const end = moment(this.dateArg.end).tz(this.timezone).format('YYYY-MM-DD')
		return {
			start: start,
			end: end,
		}
	}
}
