<div class="target-block">
	<div class="d-flex justify-content-between">
		<div *ngFor="let day of dayOptions">
			<i class="fa fa-bullseye target" [style]="getTargetIconStyle(day)" (click)="switchToDay(day)"> </i>
		</div>
	</div>
</div>

<div [style]="assignmentListDayStyle">
	<div class="assignment-list-day float-right" [style]="assignmentListDayStyle">{{ selectedDayOption.name }}</div>
	<div>
		<div class="options-title-inline" style="margin-top: 12px; margin-bottom: 12px">Schedule Employees</div>
		<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="emitHelpEvent('assignEmployees')"></i>
	</div>
</div>

<div class="dropdown-block">
	<div *ngIf="selectedDayOption?.isoValue === 1" [class.input-required]="isDayValueScheduled(0) && !isDayAssignmentValid">
		<p-multiselect
			appScrollLockMultiSelect
			placeholder="Add Monday Employees"
			selectedItemsLabel="Scheduled for Monday: {0}"
			[maxSelectedLabels]="0"
			[autofocusFilter]="devDetect.isDesktop()"
			filterBy="label,data.external_id"
			[showToggleAll]="false"
			[resetFilterOnHide]="true"
			[options]="employeeOptions"
			[(ngModel)]="manager.monday"
			(onChange)="updateSelectedDay()"
		>
		</p-multiselect>
	</div>

	<div *ngIf="selectedDayOption?.isoValue === 2" [class.input-required]="isDayValueScheduled(1) && !isDayAssignmentValid">
		<p-multiselect
			appScrollLockMultiSelect
			placeholder="Add Tuesday Employees"
			selectedItemsLabel="Scheduled for Tuesday: {0}"
			[maxSelectedLabels]="0"
			[autofocusFilter]="devDetect.isDesktop()"
			filterBy="label,data.external_id"
			[showToggleAll]="false"
			[resetFilterOnHide]="true"
			[options]="employeeOptions"
			[(ngModel)]="manager.tuesday"
			(onChange)="updateSelectedDay()"
		>
		</p-multiselect>
	</div>

	<div *ngIf="selectedDayOption?.isoValue === 3" [class.input-required]="isDayValueScheduled(2) && !isDayAssignmentValid">
		<p-multiselect
			appScrollLockMultiSelect
			placeholder="Add Wednesday Employees"
			selectedItemsLabel="Scheduled for Wednesday: {0}"
			[maxSelectedLabels]="0"
			[autofocusFilter]="devDetect.isDesktop()"
			filterBy="label,data.external_id"
			[showToggleAll]="false"
			[resetFilterOnHide]="true"
			[options]="employeeOptions"
			[(ngModel)]="manager.wednesday"
			(onChange)="updateSelectedDay()"
		>
		</p-multiselect>
	</div>

	<div *ngIf="selectedDayOption?.isoValue === 4" [class.input-required]="isDayValueScheduled(3) && !isDayAssignmentValid">
		<p-multiselect
			appScrollLockMultiSelect
			placeholder="Add Thursday Employees"
			selectedItemsLabel="Scheduled for Thursday: {0}"
			[maxSelectedLabels]="0"
			[autofocusFilter]="devDetect.isDesktop()"
			filterBy="label,data.external_id"
			[showToggleAll]="false"
			[resetFilterOnHide]="true"
			[options]="employeeOptions"
			[(ngModel)]="manager.thursday"
			(onChange)="updateSelectedDay()"
		>
		</p-multiselect>
	</div>

	<div *ngIf="selectedDayOption?.isoValue === 5" [class.input-required]="isDayValueScheduled(4) && !isDayAssignmentValid">
		<p-multiselect
			appScrollLockMultiSelect
			placeholder="Add Friday Employees"
			selectedItemsLabel="Scheduled for Friday: {0}"
			[maxSelectedLabels]="0"
			[autofocusFilter]="devDetect.isDesktop()"
			filterBy="label,data.external_id"
			[showToggleAll]="false"
			[resetFilterOnHide]="true"
			[options]="employeeOptions"
			[(ngModel)]="manager.friday"
			(onChange)="updateSelectedDay()"
		>
		</p-multiselect>
	</div>

	<div *ngIf="selectedDayOption?.isoValue === 6" [class.input-required]="isDayValueScheduled(5) && !isDayAssignmentValid">
		<p-multiselect
			appScrollLockMultiSelect
			placeholder="Add Saturday Employees"
			selectedItemsLabel="Scheduled for Saturday: {0}"
			[maxSelectedLabels]="0"
			[autofocusFilter]="devDetect.isDesktop()"
			filterBy="label,data.external_id"
			[showToggleAll]="false"
			[resetFilterOnHide]="true"
			[options]="employeeOptions"
			[(ngModel)]="manager.saturday"
			(onChange)="updateSelectedDay()"
		>
		</p-multiselect>
	</div>

	<div *ngIf="selectedDayOption?.isoValue === 7" [class.input-required]="isDayValueScheduled(6) && !isDayAssignmentValid">
		<p-multiselect
			appScrollLockMultiSelect
			placeholder="Add Sunday Employees"
			selectedItemsLabel="Scheduled for Sunday: {0}"
			[maxSelectedLabels]="0"
			[autofocusFilter]="devDetect.isDesktop()"
			filterBy="label,data.external_id"
			[showToggleAll]="false"
			[resetFilterOnHide]="true"
			[options]="employeeOptions"
			[(ngModel)]="manager.sunday"
			(onChange)="updateSelectedDay()"
		>
		</p-multiselect>
	</div>

	<div *ngIf="areEmployeesAssignedForSelectedDay()" class="item-list-block">
		<div *ngFor="let emp of currentEmpList">
			<div class="float-right del-btn" (click)="removeEmployee(emp)">X</div>
			<div class="item-label">
				<span>{{ emp?.name || 'Unknown Employee' }} </span><span *ngIf="!emp.active" class="emp-inactive">(Inactive)</span>
			</div>
		</div>
	</div>

	<div class="dup-block" [class.dup-block-active]="dupAction.showWidget">
		<div *ngIf="!dupAction.showWidget" style="height: 20px; margin-top: 6px">
			<div class="link-text float-right" (click)="dupAction.showWidget = true">clone schedule</div>
		</div>
		<div *ngIf="dupAction.showWidget" class="dup-widget">
			<div *ngIf="areEmployeesAssignedForSelectedDay()">
				<div class="tts-hl-2" style="font-weight: 600; margin: 0px 0px 12px 0px">
					Copy <span class="tts-hl-1">{{ selectedDayOption.name }}</span> schedule to:
				</div>
				<div *ngFor="let day of dayOptions" style="margin-left: 16px">
					<p-checkbox [(ngModel)]="dupAction.selectedDays[day.name]" [binary]="true"></p-checkbox>
					<div class="dup-day" (click)="dupAction.selectedDays[day.name] = !dupAction.selectedDays[day.name]">{{ day.name }}</div>
				</div>
			</div>
			<div *ngIf="!areEmployeesAssignedForSelectedDay()">
				<div style="margin-top: 8px; font-weight: 600; color: chocolate">No employes to copy</div>
			</div>
			<hr class="row-divider" />
			<div class="d-flex justify-content-between">
				<button class="btn btn-sm btn-outline-secondary" (click)="cancelDuplication()">Cancel</button>
				<button *ngIf="areEmployeesAssignedForSelectedDay()" class="btn btn-sm btn-outline-secondary" (click)="confirmDuplication()">Copy</button>
			</div>
		</div>
	</div>
</div>

<div *ngIf="!manager.areAssignmentsValid()">
	<div class="alert alert-warning">
		Every scheduled day must have at least one employee selected. Click the <i class="fa fa-bullseye"></i> icons above to switch to a specific day's
		scheduler, then select employees from the menu. When a day is properly setup, the target icon will turn green.
	</div>
</div>

<!-- <p-dialog styleClass="p-dialog-noheader p-dialog-whitebackground" header="Set As Default"
	[(visible)]="defaultAction.showDialog"
	[modal]="true"
	[style]="{'width':'300px'}"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false">
	<div style="font-size: 1.5em;margin-bottom: 25px;line-height: 1.3em;">Set as default?</div>
	<div class="d-flex justify-content-between">
		<button class="btn btn-sm btn-outline-secondary" (click)="setEmployeeAsDefault(false)">Cancel</button>
		<button class="btn btn-sm btn-outline-secondary" (click)="setEmployeeAsDefault(true)">Confrim</button>
	</div>
</p-dialog> -->

<!-- <div>
	<pre><code>{{ manager | json }}</code></pre>
</div> -->
