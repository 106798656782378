import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { DateTimeHelper, log } from '@app/helpers'
import { DialogManager, GenericEmailListManager, HelpDialogMessage, SecureFileDialogData, SecureFileRecord } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'

import moment from 'moment'
import { v4 as uuid } from 'uuid'

@Component({
    selector: 'app-secure-files-edit',
    templateUrl: './secure-files-edit.component.html',
    styleUrls: ['./secure-files-edit.component.scss'],
    standalone: false
})
export class SecureFilesEditComponent implements OnInit, AfterViewInit, AfterContentInit {
	form: UntypedFormGroup
	is12HourFormat = DateTimeHelper.format12Hour
	fileUploaded: string = null

	showValidator = false

	emailListManager: GenericEmailListManager

	@Input() dialogManager: DialogManager
	@Output() cancelEdit = new EventEmitter<boolean>()
	@Output() fileSaved = new EventEmitter<boolean>()

	constructor(
		private coreSrvc: CoreService,
		private fb: UntypedFormBuilder,
	) {}

	get dialogData(): SecureFileDialogData {
		return this.dialogManager.dialogData
	}

	get isNew(): boolean {
		return !this.dialogData.recordId
	}

	get hasFileName(): boolean {
		return !!this.form.get('file_name').value
	}

	get isFormValid(): boolean {
		return this.hasFileName && this.form.valid
	}

	ngOnInit(): void {
		this.setupForm()
		this.setupEmailListManager()
	}

	ngAfterViewInit(): void {
		// setTimeout(() => {
		// 	this.setupDialogManager()
		// })
	}

	ngAfterContentInit(): void {
		setTimeout(() => {
			this.setupDialogManager()
		})
	}

	private setupForm() {
		const recordId = this.dialogData.recordId
		const record = this.coreSrvc.dbSrvc.secFileSrvc.getSecureFileById(recordId) ?? new SecureFileRecord()
		const expiration = record.expiration

		this.form = this.fb.group({
			id: [record.id ?? null],
			expiration: [this.normalizeDate(record.expiration)],
			file_name: [record.file_name],
			file_extension: [record.file_extension],
			notes: [record.notes],
			uuid: [record.uuid],
			sha256: [record.sha256],
			notification_date: [this.normalizeDate(record.notification_date)],
			notification_message: [record.notification_message],
			notification_sent: [this.normalizeDate(record.notification_sent)],
		})

		// If we have a record, set the formated uploaded date
		this.fileUploaded = recordId ? DateTimeHelper.mediumDateTimeFromDateString(record.created) : null
	}

	private normalizeDate(date: string) {
		return date ? moment(date).startOf('day').toDate() : null
	}

	private setupEmailListManager() {
		const recordId = this.dialogData.recordId
		const record = this.coreSrvc.dbSrvc.secFileSrvc.getSecureFileById(recordId) ?? new SecureFileRecord()

		const manager = new GenericEmailListManager(this.coreSrvc)
		const emailList = record.notification_emails ?? ''
		manager.initWithString(emailList)
		this.emailListManager = manager
	}

	private setupDialogManager() {
		this.dialogManager.submitBtnLabel = 'Save'
		this.dialogManager.cancelBtnLabel = 'Cancel'
		this.dialogManager.isSubmitBtnVisible = true
		this.dialogManager.submitBtnAction = () => this.saveBtnClicked()
		this.dialogManager.cancelBtnAction = () => this.cancelBtnClicked()
		this.dialogManager.canSubmit = () => this.isFormValid
	}

	private cancelBtnClicked() {
		this.cancelEdit.next(true)
	}

	private saveBtnClicked() {
		const isEmailValid = this.emailListManager.addEmail()
		if (!isEmailValid) return null

		const record = this.makeUpdateRecord()
		if (!record) return

		if (this.isNew) {
			// Add insert options to the record to link it to resource
			this.addInsertOptions(record)
			log('Insert Record', record)
			this.coreSrvc.dbSrvc.insertRecord('file_uploads', record).then((success) => {
				if (success) {
					this.fileSaved.next(true)
				}
			})
		} else {
			// Update record
			log('Update Record', record)
			this.coreSrvc.dbSrvc.updateRecord('file_uploads', record).then((success) => {
				if (success) {
					log('File Saved')
					this.fileSaved.next(true)
				}
			})
		}
	}

	private makeUpdateRecord() {
		const record = new SecureFileRecord(this.form.value)

		// Format expiration date as YYYY-MM-DD an expiration date has been set
		record.expiration = record.expiration ? moment(record.expiration).format('YYYY-MM-DD') : null
		record.uuid = this.isNew ? uuid() : record.uuid

		record.notification_date = record.notification_date ? moment(record.notification_date).format('YYYY-MM-DD') : null
		record.notification_emails = this.emailListManager.emailListString
		return record
	}

	// CLEAN THIS UP AS IT WON'T BE USED - ONLY UPDATES MANAGED FROM THE EDIT COMPONENT
	private addInsertOptions(record: SecureFileRecord) {
		const linkType = this.dialogData.linkType
		const linkId = this.dialogData.linkId

		switch (linkType) {
			case 'EMPLOYEE':
				record['employee_id'] = linkId
				break
		}
	}

	public copyExpireationToNotification() {
		const expiration = this.form.get('expiration').value
		if (expiration) {
			const clone = moment(expiration).startOf('day').toDate()
			this.form.get('notification_date').setValue(clone)
		}
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'notification_date':
				help.header = 'Notification Date'
				help.message = 'Set a date you would like to be notified. An email will be sent to the addresses provided.'
				break
			case 'notification_message':
				help.header = 'Message'
				help.message = 'Enter the message you would like to be notified with. This will be displayed in the notification email.'
				break
		}

		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
