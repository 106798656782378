<div class="card card-body">
	<!-- <div *ngIf="isStripeCustomer" class="alert alert-info alert-bg-gray">
		<div [innerHTML]="migrationAlert | sanitizer"></div>
	</div> -->

	<!-- <p-table
	[columns]="cols"
	[value]="billingHistory"
	[paginator]="true"
	[paginatorDropdownAppendTo]="'body'"
	[rows]="10"
	[rowsPerPageOptions]="[5, 10, 15, 20]"
	[totalRecords]="billingHistory.length"
	[pageLinks]="3"> -->

	<div>
		<p-table [columns]="cols" [value]="billingHistory" [paginator]="false" [totalRecords]="billingHistory.length">
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th scope="col">Date / Invoice</th>
					<th scope="col">Amount</th>
					<th scope="col" class="paid-header">Paid On</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData let-columns="columns">
				<tr>
					<td>
						<div>
							<b>{{ rowData.date }}</b>
						</div>
						<div class="tts-hl-1" style="font-size: 0.9em; line-height: 1em"># {{ rowData.id }}</div>
					</td>
					<td>{{ currencySymbol }}{{ billingAmountFormatter(rowData) }}</td>
					<td class="paid-cell">
						<!-- If bill has not been paid -->
						<div *ngIf="!rowData.paid" style="color: firebrick">PENDING</div>

						<!-- If bill has been paid -->
						<div style="color: green">{{ rowData.paid }}</div>

						<!-- If there's an invoice for this bill -->
						<!-- <span class="view-link" (click)="openInvoice(rowData.id)">
							<i class="far fa-file-invoice-dollar" [class.link-text]="rowData.invoice_url" title="View Invoice"></i>
						</span> -->
						<!-- <span style="margin: 0px 8px">/</span> -->
						<!-- If there's a report for this bill -->

						<span *ngIf="rowData.report_url" class="link-text" (click)="openReport(rowData.id)">
							<i class="far fa-file-invoice" title="View Report"></i> Report
						</span>

						<!-- DEPRECATED 2024-10-28 - Only showing paid information -->
						<!-- <i *ngIf="!rowData.error || rowData.error_resolved" class="fa fa-check" style="color: green; cursor: default"></i>
						<i
							*ngIf="rowData.error && !rowData.error_resolved"
							class="fa fa-exclamation-triangle tts-color-info billing-error"
							style="cursor: pointer; color: firebrick"
							(click)="showBillingNotes(rowData.id)"
						></i>
						<i
							*ngIf="rowData.bill_html"
							title="View Summary"
							class="fa fa-eye tts-color-info action-icon-leftpad has-pointer"
							(click)="openBillHtml(rowData.id)"
						></i>
						<i
							*ngIf="rowData.error_resolved"
							style="margin-left: 8px; color: green"
							class="fas fa-triangle-exclamation item-tooltip"
							title="The original invoice shown here encountered an issue.  The issue has since been resolved"
						></i> -->
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
	<button *ngIf="isStripeCustomer" class="btn btn-sm btn-block btn-outline-success" style="margin-top: 8px" (click)="manageBillingBtnClicked()">
		<i *ngIf="isFetchingLink" class="fas fa-loader fa-spin" style="margin-right: 6px"></i>
		<span>{{ isFetchingLink ? 'Redirecting to Stripe billing portal' : 'See more in the Stripe billing portal' }}</span>
	</button>
</div>

<p-dialog header="Billing Notes" [(visible)]="historyAction.showNotesDialog" [modal]="false" [style]="{ width: '300px' }" [dismissableMask]="true">
	{{ historyAction.notes }}
</p-dialog>
