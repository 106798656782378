<div>
	<textarea
		*ngIf="isEditingAdminNotes"
		class="notes-textarea textarea-editing"
		placeholder="Add an admin note"
		style="margin-top: 12px"
		rows="3"
		[(ngModel)]="notes"
	></textarea>

	<textarea
		*ngIf="!isEditingAdminNotes"
		class="notes-textarea"
		placeholder="Enter note here then click add note"
		[(ngModel)]="appendNotes"
	></textarea>

	<div style="margin-top: 6px; height: 20px">
		<div *ngIf="!isEditingAdminNotes">
			<span class="link-text float-right" [class.link-disabled]="!appendNotes" (click)="addAdminNote()">add note</span>
			<span *ngIf="appendNotes" class="link-text" (click)="cancelAddAdminNote()">cancel</span>
			<!-- <span *ngIf="canEditAdminNotes && notes && !appendNotes && !isEditingAdminNotes" class="link-text" (click)="this.isEditingAdminNotes = true">edit existing notes</span> -->
		</div>

		<div class="link-text" *ngIf="isEditingAdminNotes">
			<span class="link-text float-right" (click)="updateAdminNotes()">update notes</span>
			<span class="link-text" (click)="cancelEditAdminNotes()">cancel</span>
		</div>
	</div>

	<div *ngIf="!isEditingAdminNotes && notes" class="notes-display-wrap hide-scrollbars">
		<div class="notes-display">{{ notes | adminNotesFormatter }}</div>
	</div>
</div>
