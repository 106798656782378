<div class="public-container">
	<form class="form-signin" method="POST" action="#" role="form">
		<div class="form-group">
			<h2>Resend Code</h2>
		</div>
		<div *ngIf="errorMessage" class="alert alert-danger">
			{{ errorMessage }}
		</div>
		<div class="form-group">
			<label class="control-label" for="signupMobile">Mobile Number</label>
			<input id="signupMobile" type="tel" maxlength="50" class="form-control" [(ngModel)]="mobile" [ngModelOptions]="{ standalone: true }" />
		</div>
		<div class="form-group">
			<button (click)="resendCode()" id="signupSubmit" type="submit" class="btn btn-primary btn-block">Resend Code</button>
		</div>

		<hr />
		<p>
			<a [routerLink]="['/home/register']"> <i class="far fa-user"></i> Register</a>
			<a [routerLink]="['/home/login']" class="float-right"> <i class="far fa-lock"></i> Login</a>
		</p>
	</form>
</div>
