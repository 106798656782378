import { log } from '@app/helpers/logger'
import _ from 'lodash'

export class ColorVendor {
	// FlatUI https://flatuicolors.com/palette/defo
	// Any Employee Color #147725

	static colorList = [
		'#34495e',
		'#ee3e6d',
		'#1abc9c',
		'#3498db',
		'#c97545',
		'#9b59b6',
		'#d63d62',
		'#16a085',
		'#2980b9',
		'#8e44ad',
		'#000105',
		'#3e6158',
		'#3f7a89',
		'#96c582',
		'#f46600',
		'#7c90c1',
		'#9d8594',
		'#4b4fce',
		'#4e0a77',
		'#c6a670',
		'#59b759',
		'#cf0500',
		'#8e0622',
		'#62382f',
		'#c1800b',
	]

	// assignedColors = {}
	// index = 0

	static getRandomColor() {
		return _.sample(ColorVendor.colorList)
	}

	static getColorById(id: number) {
		if (id === 0) {
			return '#147725'
		}
		if (!id) {
			return ColorVendor.getRandomColor()
		}
		const index = id % ColorVendor.colorList.length
		return ColorVendor.colorList[index]
	}
}

// .color-palette {
// 	color: #34495e;
// 	color: #ee3e6d;
// 	color: #1abc9c;
// 	color: #3498db;
// 	color: #c97545;
// 	color: #9b59b6;
// 	color: #d63d62;
// 	color: #16a085;
// 	color: #2980b9;
// 	color: #8e44ad;
// 	color: #000105;
// 	color: #3e6158;
// 	color: #3f7a89;
// 	color: #96c582;
// 	color: #f46600;
// 	color: #7c90c1;
// 	color: #9d8594;
// 	color: #4b4fce;
// 	color: #4e0a77;
// 	color: #c6a670;
// 	color: #59b759;
// 	color: #cf0500;
// 	color: #8e0622;
// 	color: #62382f;
// 	color: #c1800b;
// }
