import { Injectable, Inject } from '@angular/core'

import { CognitoCallback, CognitoUtil } from '@app/services'
import { RegistrationUser } from '@app/models'
import { log } from '@app/helpers'

import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js'
import SimpleCryptoJS from 'simple-crypto-js'

@Injectable({
	providedIn: 'root',
})
export class UserRegistrationService {
	public userRegistration: RegistrationUser
	public forgotPassMobile = ''

	private regInfoKey = 'eOejHEPklsHkbnEoXpaKK'
	private regInfoStorageKey = 'TTSADMINRegInfo'
	private simpleCrypto = new SimpleCryptoJS(this.regInfoKey)

	constructor(@Inject(CognitoUtil) public cognitoUtil: CognitoUtil) {}

	register(user: RegistrationUser, callback: CognitoCallback): void {
		log('UserRegistrationService: user is ' + user)

		this.userRegistration = user

		const attributeList = []

		const dataFirst = {
			Name: 'given_name',
			Value: user.firstName,
		}
		const dataLast = {
			Name: 'family_name',
			Value: user.lastName,
		}
		const dataEmail = {
			Name: 'email',
			Value: user.email,
		}
		// const dataNickname = {
		// 	Name: 'nickname',
		// 	Value: user.name
		// };
		const dataPhoneNumber = {
			Name: 'phone_number',
			Value: user.mobile,
		}
		attributeList.push(new CognitoUserAttribute(dataFirst))
		attributeList.push(new CognitoUserAttribute(dataLast))
		attributeList.push(new CognitoUserAttribute(dataEmail))
		attributeList.push(new CognitoUserAttribute(dataPhoneNumber))

		this.cognitoUtil.getUserPool().signUp(user.username, user.password, attributeList, null, function (err, result) {
			if (err) {
				callback.cognitoCallback(err.message, null)
			} else {
				log('UserRegistrationService: registered user is ', result)
				callback.cognitoCallback(null, result)
			}
		})
	}

	confirmRegistration(username: string, confirmationCode: string, callback: CognitoCallback): void {
		const userData = {
			Username: username,
			Pool: this.cognitoUtil.getUserPool(),
		}

		const cognitoUser = new CognitoUser(userData)

		cognitoUser.confirmRegistration(confirmationCode, true, function (err, result) {
			if (err) {
				callback.cognitoCallback(err.message, null)
			} else {
				callback.cognitoCallback(null, result)
			}
		})
	}

	resendCode(username: string, callback: CognitoCallback): void {
		const userData = {
			Username: username,
			Pool: this.cognitoUtil.getUserPool(),
		}

		const cognitoUser = new CognitoUser(userData)

		cognitoUser.resendConfirmationCode(function (err, result) {
			if (err) {
				callback.cognitoCallback(err.message, null)
			} else {
				callback.cognitoCallback(null, result)
			}
		})
	}

	resendCode$(username: string): Promise<any> {
		return new Promise((resolve, reject) => {
			const userData = {
				Username: username,
				Pool: this.cognitoUtil.getUserPool(),
			}

			const cognitoUser = new CognitoUser(userData)
			cognitoUser.resendConfirmationCode(function (err, result) {
				if (err) {
					reject(err.message)
				} else {
					resolve(result)
				}
			})
		})
	}

	setRegInfo(regInfo: RegistrationUser) {
		regInfo.timestamp = new Date().toISOString()
		// regInfo.timestamp = '2018-05-16T12:02:24.026Z';
		const encryptedObj = this.simpleCrypto.encrypt(regInfo)
		localStorage.setItem(this.regInfoStorageKey, encryptedObj)
	}

	getRegInfo(): RegistrationUser {
		const userInfo = localStorage.getItem(this.regInfoStorageKey)
		if (userInfo) {
			const regInfo = this.simpleCrypto.decrypt(userInfo) as Object
			if (regInfo && regInfo['password']) {
				const userRegistration = new RegistrationUser()
				for (const attr in regInfo) {
					if (regInfo.hasOwnProperty(attr)) {
						userRegistration[attr] = regInfo[attr]
					}
				}
				userRegistration.confirmation = userRegistration.password
				userRegistration.name = userRegistration.firstName + ' ' + userRegistration.lastName
				return userRegistration
			} else {
				return null
			}
		}
		return null
	}

	clearRegInfo() {
		localStorage.removeItem(this.regInfoStorageKey)
		this.userRegistration = new RegistrationUser()
	}

	static emailProviderMatcher = [
		'amazon.com',
		'gmail.com',
		'hotmail.com',
		'outlook.com',
		'yahoo.com',
		'icloud.com',
		'aol.com',
		'comcast.com',
		'comcast.net',
	]

	static emailStringMatcher = [
		'build',
		'care',
		'clean',
		'comp',
		'concrete',
		'construct',
		'cool',
		'corp',
		'enterprise',
		'force',
		'guard',
		'health',
		'heat',
		'help',
		'home',
		'inc',
		'industr',
		'info',
		'jani',
		'llc',
		'ltd',
		'main',
		'manage',
		'mech',
		'mgmt',
		'propert',
		'sale',
		'secur',
		'serve',
		'service',
		'solution',
		'support',
	]

	static validateEmailForConversion(str: string): boolean {
		// If no address, return false
		if (!str) return false

		// See if email address includes substrings from emailStringMatcher it is valid for a conversion
		const email = `${str}`.toLowerCase()

		// Check if email address contains any valid email provider
		let matchesEmailProvider = false
		for (const provider of UserRegistrationService.emailProviderMatcher) {
			if (email.includes(provider)) matchesEmailProvider = true
		}

		if (matchesEmailProvider) {
			const emailStringMatcher = UserRegistrationService.emailStringMatcher
			for (const substring of emailStringMatcher) {
				if (email.includes(substring.toLowerCase())) return true
			}

			// Check if local part ends with any size number which is not valid for a conversion
			const localPart = email.split('@')[0]
			if (localPart && /\d+$/.test(localPart)) return false
		}

		// If not already processed or filtered out, consider the email address valid for a conversion event
		return true
	}
}
