import { Component, Input, OnInit } from '@angular/core'

@Component({
    selector: 'app-sub-section-title',
    templateUrl: './sub-section-title.component.html',
    styleUrls: ['./sub-section-title.component.scss'],
    standalone: false
})
export class SubSectionTitleComponent implements OnInit {

	@Input() isVisible = false
	@Input() subSectionTitle = 'SubTitle'

	constructor() { }

	ngOnInit(): void {
	}

}
