<div *ngIf="!isBotReady">
	<div class="no-msg-wrap">
		<div class="no-msg-content">
			<div class="no-msg-icon"><i class="fas fa-loader fa-spin"></i></div>
			<div class="no-msg-copy">AI assistant is initializing.</div>
		</div>
	</div>
</div>

<div *ngIf="isBotReady">
	<div *ngIf="!hasMessages" class="no-msg-wrap">
		<div class="no-msg-content">
			<div class="no-msg-icon"><i class="fad fa-messages"></i></div>
			<div class="no-msg-copy">Ask a question to start a conversation with our AI assistant.</div>
		</div>
	</div>

	<div class="bottom-blocker"></div>

	<div class="chat-container">
		<div class="message-box">
			<div *ngFor="let message of messages">
				<div [id]="message.id" class="chat-message" [class.local-message]="message.source === 'LOCAL'" [innerHTML]="sanitize(message.text)"></div>
			</div>
		</div>

		<div id="prompt-box" class="prompt-box">
			<div class="input-group mb-3">
				<input
					type="text"
					class="form-control send-input"
					aria-label="Ask a question"
					aria-describedby="button-send"
					placeholder="Ask a question ..."
					[(ngModel)]="newMessage"
					(keydown.enter)="sendMessage()"
				/>
				<button class="btn btn-primary send-btn" type="button" id="button-send" (click)="sendMessage()">Send</button>
			</div>
		</div>
		<!-- <div *ngIf="isSessionClosed" class="session-closed">Session Closed</div> -->
	</div>
</div>
