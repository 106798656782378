import { Component, Input, OnInit } from '@angular/core'
import { DateTimeHelper, log } from '@app/helpers'
import { TransEventLogEntry } from '@app/models'
import { DatabaseService } from '@app/services'

import moment from 'moment-timezone'

@Component({
    selector: 'app-ivr-log-data',
    templateUrl: './ivr-log-data.component.html',
    styleUrls: ['./ivr-log-data.component.scss'],
    standalone: false
})
export class IVRLogDataComponent implements OnInit {
	constructor(private dbSrvc: DatabaseService) {}

	@Input() logId: number
	@Input() timezone: string
	logEntries: Array<TransEventLogEntry> = []

	ngOnInit(): void {
		this.loadLogEntries()
	}

	private loadLogEntries() {
		const logId = this.logId
		const request = { table: 'ivr_call_event_log', operation: null, ivr_call_log_id: logId }
		log('Lambda Request', request)
		this.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			log('Result', result)
			this.logEntries = result.data.map((rec) => new TransEventLogEntry(rec))
			log('Total Entries:', this.logEntries.length)
		})
	}

	public dateFormatter(date: string): string {
		const mom = moment(date)
		if (mom.isValid()) {
			return mom.tz(this.timezone).format('ddd MMM Do')
		}
		return 'Invalid'
	}

	public timeFormatter(date: string): string {
		const mom = moment(date)
		if (mom.isValid()) {
			const is12Hours = DateTimeHelper.format12Hour
			const timeFormat = is12Hours ? 'h:mm:ss a z' : 'HH:mm:ss z'
			return mom.tz(this.timezone).format(timeFormat)
		}
		return 'Invalid'
	}
}
