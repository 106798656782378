import { DateTimeHelper } from '@app/helpers/datetime'

export class HelpVideoRecord {
	created: string // Date created
	updated: string // Date updated

	id: number
	key: string // The key used for lookup
	target: string // Component the video is targeting
	comment: string // Text description of the video
	url: string // URL for the video

	constructor(record?: HelpVideoRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
		}
	}
}
