import { EventEmitter, Injectable } from '@angular/core'
import { log } from '@app/helpers/logger'
import { C2CLogRecord, IVRLogRecord, SMSLogRecord, CommLogViewManager } from '@app/models'
import { Global } from '@app/models/global'
import { Subject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class CommLogService {
	// redirectAgentRecordId: number = null // DEPRECATED 20231101

	// click_to_call_log
	agentDataLoaded = false
	agentList: Array<C2CLogRecord> = []
	agentListChange = new EventEmitter<Array<C2CLogRecord>>()
	agentLastUpdated: Date

	// ivr_call_log
	ivrDataLoaded = false
	ivrList: Array<IVRLogRecord> = []
	ivrListChange = new EventEmitter<Array<IVRLogRecord>>()
	ivrLastUpdated: Date

	// sms_log
	smsDataLoaded = false
	smsList: Array<SMSLogRecord> = []
	smsListChange = new EventEmitter<Array<SMSLogRecord>>()
	smsLastUpdated: Date

	// callLogRecordHasBeenAdded = new Subject<boolean>() // DEPRECATED - Use c2cEventSubmitted in CallService

	public viewManager = new CommLogViewManager()

	constructor() {
		log('Creating CallLogService')
	}

	clearData() {
		this.agentDataLoaded = false
		this.agentList = []
		this.agentLastUpdated = null

		this.ivrDataLoaded = false
		this.ivrList = []
		this.ivrLastUpdated = null

		this.smsDataLoaded = false
		this.smsList = []
		this.smsLastUpdated = null
	}

	// Agent Record Methods

	getAgentRecords(): Array<C2CLogRecord> {
		return this.agentList
	}

	setAgentRecords(records: Array<C2CLogRecord>) {
		this.agentLastUpdated = new Date()
		this.agentList = records.map((rec) => new C2CLogRecord(rec))
		this.agentDataLoaded = true
		this.agentListChange.next(this.agentList)
	}

	getAgentRecordById(id: number): C2CLogRecord {
		return this.agentList.find((rec) => rec.id === id)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateAgentRecords(records: Array<C2CLogRecord>) {
		const newRecords = records.map((rec) => new C2CLogRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.agentList.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.agentList.push(newRecord)
			}
		}
		this.agentLastUpdated = new Date()
		this.agentListChange.next(this.agentList)
	}

	// IVR Record Methods
	getIvrRecords(): Array<IVRLogRecord> {
		return this.ivrList
	}

	setIvrRecords(records: Array<IVRLogRecord>) {
		this.ivrLastUpdated = new Date()
		this.ivrList = records.map((rec) => new IVRLogRecord(rec))
		this.ivrDataLoaded = true
		this.ivrListChange.next(this.ivrList)
	}

	getIvrRecordById(id: number): IVRLogRecord {
		return this.ivrList.find((rec) => rec.id === id)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateIvrRecords(records: Array<IVRLogRecord>) {
		const newRecords = records.map((rec) => new IVRLogRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.ivrList.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.ivrList.push(newRecord)
			}
		}
		this.ivrLastUpdated = new Date()
		this.ivrListChange.next(this.ivrList)
	}

	// SMS Record Methods
	getSmsRecords(): Array<SMSLogRecord> {
		return this.smsList
	}

	setSmsRecords(records: Array<SMSLogRecord>) {
		this.smsLastUpdated = new Date()
		this.smsList = records.map((rec) => new SMSLogRecord(rec))
		this.smsDataLoaded = true
		this.smsListChange.next(this.smsList)
	}

	getSmsRecordById(id: number): SMSLogRecord {
		return this.smsList.find((rec) => rec.id === id)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateSmsRecords(records: Array<SMSLogRecord>) {
		const newRecords = records.map((rec) => new SMSLogRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.smsList.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.smsList.push(newRecord)
			}
		}
		this.smsLastUpdated = new Date()
		this.smsListChange.next(this.smsList)
	}
}
