<div class="urr-page-box">
	<div class="urr-report-wrapper">
		<app-user-report-header
			[config]="config"
			[logoUrl]="vm.logoUrl"
			[companyName]="vm.companyName"
			[address]="vm.address"
			[reportType]="vm.reportType"
			[transactionMetaData]="vm.reportData.transactionMetaData"
			[publicView]="publicView"
			(printPageBtnClicked)="printPage()"
			(closeBtnClicked)="closeBtnClicked.emit($event)"
		>
		</app-user-report-header>

		<div class="urr-block no-page-break">
			<div class="card urr-card">
				<div>
					<div class="urr-label urr-header-label nowrap">Reported By:</div>
					<div class="urr-header-value">{{ vm.reportInfo.name }}</div>
				</div>
				<div>
					<div class="urr-label urr-header-label nowrap">Submitted On:</div>
					<div class="urr-header-value">{{ vm.reportDate }}</div>
				</div>
				<div>
					<div class="urr-label urr-header-label nowrap">Starting Mileage:</div>
					<div class="urr-header-value">{{ vm.reportInfo.startMileage }}</div>
				</div>
				<div>
					<div class="urr-label urr-header-label nowrap">Ending Mileage:</div>
					<div class="urr-header-value">{{ vm.reportInfo.endMileage }}</div>
				</div>
				<div *ngIf="vm.reportInfo.totalMileage">
					<div class="urr-label urr-header-label nowrap">Total Mileage:</div>
					<div class="urr-header-value">{{ vm.reportInfo.totalMileage }}</div>
				</div>
				<div *ngIf="vm.reportInfo.shiftLength">
					<div class="urr-label urr-header-label nowrap">Shift Length (hrs:min):</div>
					<div class="urr-header-value">{{ vm.reportInfo.shiftLength }}</div>
				</div>
			</div>
		</div>

		<div class="urr-block no-page-break">
			<div class="card urr-card">
				<div *ngFor="let cond of vm.conditions" class="urr-section">
					<div class="urr-label">Describe {{ cond.label }}</div>
					<div class="urr-description">{{ cond.value }}</div>
				</div>
			</div>
		</div>

		<div class="urr-block no-page-break">
			<div class="card urr-card">
				<div class="urr-section">
					<div class="urr-label">Vehicle Occupants</div>
					<div class="urr-description">{{ vm.reportInfo.occupants }}</div>
				</div>
				<div class="urr-section">
					<div class="urr-label">Previous Occupant</div>
					<div class="urr-description">{{ vm.reportInfo.previousOccupant }}</div>
				</div>
				<div class="urr-section">
					<div class="urr-label">Notes / Comments</div>
					<div class="urr-description">{{ vm.reportInfo.notes }}</div>
				</div>
			</div>
		</div>

		<app-user-report-footer [incident]="incident" [images]="vm.images"></app-user-report-footer>
	</div>
</div>
