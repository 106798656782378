import { DateTimeHelper } from '@app/helpers/datetime'
import { DatabaseService } from '@app/services/backend/database.service'
import { SelectItem } from 'primeng/api'
import { ContactRecord, ContactType } from './contact'
import { OrganizationRecord } from './organization'

export class ContactOrganizationLinkRecord {
	id: number = null
	company_id: number = null
	contact_id: number = null
	vendor_client_id: number = null

	notify: boolean = true
	created: string = null
	details: string = ''

	association_type: ContactType = 'PRIMARY'

	constructor(record?: ContactOrganizationLinkRecord) {
		this.updateProperties(record)
	}

	updateProperties(record?: ContactOrganizationLinkRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
		}
	}

	clone() {
		return new ContactOrganizationLinkRecord(this)
	}
}

export class ContactOrganizationLinkCard {
	title: string
	contact: ContactRecord
	organization: OrganizationRecord
	linkRecord: ContactOrganizationLinkRecord

	showDeleteConfirmation = false

	constructor(dbSrvc: DatabaseService, linkRecord: ContactOrganizationLinkRecord, source: 'CONTACT' | 'ORGANIZATION') {
		this.linkRecord = linkRecord
		switch (source) {
			case 'CONTACT':
				const organization = dbSrvc.orgSrvc.getOrganizationById(linkRecord.vendor_client_id)
				this.organization = organization
				this.title = organization.name
				break
			case 'ORGANIZATION':
				const contact = dbSrvc.contactSrvc.getContactById(linkRecord.contact_id)
				this.contact = contact
				this.title = contact.name
				break
		}
	}
}

// This interface is the result returned when the list componnt tells the table it's updating a
// record. The id is the contact or organization id and the source is which table type the
// list is being viewed from
export interface LinkContactOrgHostRecordUpdateEvent {
	id: number
	source: 'CONTACT' | 'ORGANIZATION'
}
