<div class="container section-container">
	<div class="sec-head-wrap">
		<div class="sec-act-ico-wrap">
			<i
				class="fas fa-plus sec-act-ico"
				[class.section-control-disabled]="!canPerformAction('create')"
				[title]="createRecordLabel"
				(click)="createRecord()"
			></i>
			<i class="far fa-cog sec-act-ico" (click)="openPrefsDialog()" title="Manage Prefs"></i>
			<i class="far fa-question-circle sec-act-ico" (click)="toggleTooltips()" title="Show Help"></i>
		</div>

		<div class="sec-title-wrap">
			<div class="sec-head-ico-wrap">
				<i *ngIf="viewManager.isSectionWorking" class="fas fa-loader fa-spin sec-head-ico"></i>
				<i *ngIf="!viewManager.isSectionWorking" class="far fa-address-card sec-head-ico"></i>
			</div>
			<span>Contacts</span>
			<app-sub-section-title [isVisible]="true" [subSectionTitle]="subSectionTitle"></app-sub-section-title>
		</div>

		<div class="sub-head-wrap">
			<div class="sub-head-ico-wrap">
				<i class="fa fa-refresh sub-head-ico" (click)="reloadData()" title="Refresh Data"></i>
			</div>
			<div class="updated-wrap">{{ lastUpdated }}</div>
		</div>
	</div>

	<div>
		<app-section-switcher [config]="switcherConfig" [type]="'NOCOUNT'" [maxWidth]="'300px'" (tabClicked)="sectionTabClicked($event)">
		</app-section-switcher>
	</div>

	<div *ngIf="vm.showTooltips" class="card card-body tooltip-block tooltip-info-text tooltip-section-header">
		<button type="button" class="close" aria-label="Close" (click)="toggleTooltips()">
			<span>&times;</span>
		</button>
		Contacts are managed here and may be assigned to various records.
		<br />
	</div>

	<div *ngIf="viewManager.currentView === 'INDIVIDUALS'">
		<app-contact-table #contactTable></app-contact-table>
	</div>

	<div *ngIf="viewManager.currentView === 'ORGANIZATIONS'">
		<app-organization-table #orgTable></app-organization-table>
	</div>
</div>
