<div *ngIf="showBanner && rowCount > 0" class="alert alert-info alert-help">
	<div class="content-wrap d-flex justify-content-center">
		<i class="far fa-circle-xmark close-icon" (click)="closeHelp()" title="Close"></i>
		<div class="help-icon-wrap">
			<i class="fas fa-circle-caret-right help-icon" style="color: #31b131"></i>
			<div class="help-icon-label">expands row</div>
		</div>
		<div class="help-icon-wrap">
			<i class="fas fa-circle-caret-down help-icon" style="color: #d33333"></i>
			<div class="help-icon-label">collapses row</div>
		</div>
		<div class="help-icon-wrap">
			<i class="far fa-cog help-icon" style="color: #0275d8"></i>
			<div class="help-icon-label">manages columns</div>
		</div>
	</div>
</div>
