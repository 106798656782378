import { Directive, AfterViewInit, OnDestroy, Input, OnInit } from '@angular/core'
import { log } from '@app/helpers'
import { CoreService, DatabaseService } from '@app/services'
import { OverlayOptions } from 'primeng/api'
import { MultiSelect } from 'primeng/multiselect'

@Directive({
	selector: '[appScrollLockMultiSelect]',
	standalone: false,
})
export class AppScrollLockMultiSelectDirective implements OnInit, AfterViewInit {
	@Input() ttsCenterOnShow = true

	constructor(
		private multiselect: MultiSelect,
		private coreSrvc: CoreService,
	) {}
	ngOnInit(): void {
		// If it's not desktop, don't autofocus the filter option
		if (!this.coreSrvc.devDetect.isDesktop()) this.multiselect.autofocusFilter = false
	}
	ngAfterViewInit() {
		const overlayOptions: OverlayOptions = {
			listener: (event, options) => options.type === 'outside',
		}
		this.multiselect.overlayOptions = overlayOptions

		// If it's desktop and set to center on show, then setup the handler
		// if (this.coreSrvc.devDetect.isDesktop()) this.multiselect.onPanelShow.subscribe(() => this.handleOnPanelShow())
		if (this.ttsCenterOnShow) this.multiselect.onPanelShow.subscribe(() => this.handleOnPanelShow())
	}

	handleOnPanelShow() {
		const elm = $('.p-overlay-content') as any
		setTimeout(() => {
			elm?.[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' })
		}, 125)
	}
}

// const overlayOptions: OverlayOptions = {
// 	listener: (event, options) => {
// 		// If clicking outside overlay then close otherwise just selecting multiple options so stay open
// 		// log('Overlay', event, options)
// 		if (options.type === 'outside' && options.valid) {
// 			return true
// 		}
// 	},
// }
