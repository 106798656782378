import { Injectable } from '@angular/core'

import { GoogleCalendarSync, GoogleSyncLogEntry } from '@app/models'
import { log } from '@app/helpers'

import { Subject } from 'rxjs'
import _ from 'lodash'

@Injectable({
	providedIn: 'root',
})
export class WhenToWworkService {
	private w2wSync: Array<GoogleCalendarSync> = [] // google_sync_log
	private w2wSyncLog: Array<GoogleSyncLogEntry> = [] // google_cal_sync

	public w2wSyncDataLoaded = false
	public w2wLogDataLoaded = false

	public w2wSyncUpdated = new Subject<boolean>()
	public w2wSyncLogUpdated = new Subject<boolean>()

	public lastApiTimestamp = null
	public isSyncPolling = false
	public isIntegrating = false

	constructor() {
		log('Creating WhenToWorkService')
	}

	dataLoaded(): boolean {
		return this.w2wSyncDataLoaded
	}

	isW2WIntegrated(): boolean {
		return !!this.getW2WSync()
	}

	clearData() {
		this.w2wSync = []
		this.w2wSyncDataLoaded = false
	}

	public startSyncPolling() {
		this.lastApiTimestamp = this.getW2WSync()?.sync_last_api ?? null
		this.isSyncPolling = true
	}
	public endSyncPolling() {
		this.isSyncPolling = false
	}

	getW2WSync(): GoogleCalendarSync {
		return this.w2wSync[0] || null
	}

	setW2WSyncRecords(records: Array<any>) {
		this.w2wSync = records.map((rec) => new GoogleCalendarSync(rec))
		this.w2wSyncDataLoaded = true
		this.w2wSyncUpdated.next(true)
	}

	getW2WSyncLog(): Array<GoogleSyncLogEntry> {
		return this.w2wSyncLog
	}

	getW2WFilteredSyncLog(): Array<GoogleSyncLogEntry> {
		const list = this.getW2WSyncLog()
		const mapper = new Map<string, GoogleSyncLogEntry>()
		const result: Array<GoogleSyncLogEntry> = []
		for (const entry of list) {
			const foundEntry = mapper.get(entry.message)
			if (foundEntry) {
				continue
			}
			mapper.set(entry.message, entry)
		}
		for (const [key, entry] of mapper.entries()) {
			result.push(entry)
		}
		return result
	}

	setW2WSyncLogRecords(records: Array<GoogleSyncLogEntry>) {
		this.w2wSyncLog = records.map((rec) => new GoogleSyncLogEntry(rec))
		this.w2wLogDataLoaded = true
		this.w2wSyncLogUpdated.next(true)
	}

	logRecords() {
		log('W2W Service Log loaded records')
	}
}
