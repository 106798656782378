import { DateTimeHelper, log } from '@app/helpers'
import moment from 'moment-timezone'
import { v4 as uuid } from 'uuid'
import { FileUploadHelper } from './file-uploader'

export class SecureFileRecord {
	// Uses file_uploads table

	company_id: number = null
	created: string = null // ISO Date
	expiration: string = null // ISO Date
	notification_date: string = null
	notification_emails: string = null
	notification_message: string = null
	notification_sent: string = null
	file_name: string = ''
	file_extension: string = ''
	file_size: number = null
	id: number = null
	notes: string = ''
	uuid: string = ''
	sha256: string = null

	updated: string = null

	// NOTE: When adding options here, make sure to add id variable in comonent makeReadOptions
	employee_ids: Array<number> = []
	contact_ids: Array<number> = []
	vendor_client_org_ids: Array<number> = []

	constructor(record?: SecureFileRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
			this.notification_sent = DateTimeHelper.stripUtcTag(this.notification_sent)
		}
	}

	get hasLinkedRecords(): boolean {
		return this.employee_ids.length > 0 || this.contact_ids.length > 0 || this.vendor_client_org_ids.length > 0
	}

	public clone() {
		return new SecureFileRecord(this)
	}
}

export class SecureFileListItem {
	record: SecureFileRecord
	created: string
	expiration: string = null // Date expiration
	extension: string = ''
	fileSize: string = ''

	isExpanded = false
	isExpired = false
	isDownloading = false
	isDeleting = false

	notifyOnDate: string = null // Date notification_date
	lastNotifiedDate: string = null // Date notification_sent

	showRemovalOptions = false

	constructor(record: SecureFileRecord) {
		this.record = record
		this.created = DateTimeHelper.mediumDateTimeFromDateString(record.created)
		this.expiration = DateTimeHelper.mediumDateFromDateString(record.expiration)
		this.notifyOnDate = DateTimeHelper.mediumDateFromDateString(record.notification_date)
		this.lastNotifiedDate = DateTimeHelper.mediumDateFromDateString(record.notification_sent)

		// Setup file extension
		const ext = record.file_extension ?? ''
		this.extension = ext.toUpperCase()

		// Setup file size
		const fileSize = record.file_size
		if (fileSize) {
			this.fileSize = FileUploadHelper.formatBytes(fileSize)
		}

		this.setupExpirationFlag()
	}

	setupExpirationFlag() {
		if (this.record.expiration) {
			const expireMom = moment(this.record.expiration + 'T00:00:00')
			if (expireMom.isBefore(moment(), 'minute')) {
				this.isExpired = true
			}
		}
	}
}

export type SecureFileLinkType = 'ARCHIVE' | 'COMPANY' | 'EMPLOYEE' | 'CONTACT' | 'ORGANIZATION'

export class SecureFileDialogData {
	linkType: SecureFileLinkType
	linkId: number
	recordId: number

	/**
	 * Add or update local database records
	 * @param linkType: Which file list you want to work with
	 * @param linkId: Record ID for the entity whose files you will view
	 * @param recordId: File ID for a given file record to edit
	 */

	constructor(linkType: SecureFileLinkType, linkId: number, recordId: number) {
		this.linkType = linkType
		this.linkId = linkId
		this.recordId = recordId
	}
}

export class SecureFileUploadInfo {
	uuid = uuid()
	sha256 = null
	file: File

	success = false

	constructor(file: File) {
		this.file = file
	}

	getFileParts(): { name: string; ext: string } {
		const fileName = this.file?.name ?? ''
		const nameComps = fileName.split('.')
		if (nameComps.length > 1) {
			const ext = nameComps.pop() || ''
			return {
				name: nameComps.join('.'),
				ext: ext.toLowerCase(),
			}
		} else {
			return {
				name: fileName,
				ext: '',
			}
		}
	}
}

export interface IFileUploadsMetadata {
	fileCount: number
	hasExpiredFiles: boolean
}
