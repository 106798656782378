<div class="public-container" style="margin-top: -30px; margin-bottom: 50px">
	<form novalidate (ngSubmit)="onConfirmRegistration()" [formGroup]="confirmForm" autocomplete="off">
		<div style="text-align: center">
			<div style="margin-bottom: 20px">
				<img src="/assets/img/tts-logo.png" style="width: 100%" />
			</div>
		</div>
		<div [hidden]="confirmingRegistration || provisioningCompany || provisioningTeli">
			<div style="text-align: center">
				<h3 style="color: #777">Confirm Registration</h3>
			</div>

			<div *ngIf="hasValidNumber()">
				<div class="confirmation-notes">
					You will receive a 6 digit verification code via text message at the mobile number you used to register. Please enter that code below
					and click
					<strong>Confirm</strong> to finish setting up your account.
					<br />
					<br />
					<strong style="color: chocolate"
						>Mobile Number: {{ this.userRegistration ? (this.userRegistration.mobile | displayPhone) : 'Number Missing' }}</strong
					>
				</div>
				<div *ngIf="errorMessage" class="alert alert-danger">
					{{ errorMessage }}
				</div>
				<div class="form-group">
					<label class="form-label" for="confirmationCode">Enter Code </label>
					<input id="confirmationCode" type="tel" maxlength="50" class="form-control" formControlName="confirmationCode" autocomplete="off" />
				</div>
				<div class="form-group">
					<button type="submit" [disabled]="confirmForm.invalid" id="confirmRegistration" type="submit" class="btn btn-success btn-block">
						Confirm
					</button>
				</div>

				<div *ngIf="true">
					<div>Can't find your code?</div>
					<div>
						<a href="#" (click)="resendCode()"> <i class="fa fa-fw fa-group"></i> Resend Code</a>
					</div>
					<div *ngIf="false">
						<a [routerLink]="['/home/register']"> <i class="fa fa-fw fa-group"></i> Register</a> /
						<a [routerLink]="['/home/login']"> <i class="fa fa-fw fa-user"></i> Login</a>
					</div>
				</div>
			</div>

			<div *ngIf="!hasValidNumber()" style="text-align: center; color: firebrick">
				<h4 style="margin: 25px 0px">Registration Info Missing</h4>
				<button class="btn btn-danger" [routerLink]="['/home/register']">Start Over</button>
			</div>
		</div>
	</form>

	<div *ngIf="confirmingRegistration || provisioningCompany || provisioningTeli">
		<div class="confirmation-notes">
			<div class="alert alert-warning" style="font-weight: 600; font-size: 1.2em; margin-bottom: 30px; text-align: center">
				<div>Please be patient. <br />We are setting up your account.</div>
			</div>

			<!-- <strong style="color: firebrick">DO NOT</strong> refresh your browser or navigate away from this page. If the setup is interrupted, you will
			need to contact support to have your account manually activated. -->
		</div>
	</div>

	<div style="text-align: center; color: green">
		<div *ngIf="confirmingRegistration">
			<div class="loading-spinner">
				<i class="fas fa-loader fa-spin" aria-hidden="true"></i>
			</div>
			<h2>Confirming Registration</h2>
		</div>
		<div *ngIf="provisioningCompany">
			<div class="loading-spinner">
				<i class="fas fa-loader fa-spin" aria-hidden="true"></i>
			</div>
			<h2>Creating Account</h2>
		</div>
		<div *ngIf="provisioningTeli">
			<div class="loading-spinner">
				<i class="fas fa-loader fa-spin" aria-hidden="true"></i>
			</div>
			<h2>Provisioning System</h2>
		</div>
	</div>

	<div *ngIf="confirmingRegistration || provisioningCompany || provisioningTeli" style="text-align: center; margin-top: 25px">
		<div class="confirmation-notes">When setup is finished you will automatically be signed into the administrative portal.</div>
		<div *ngIf="showFacebookPixel">
			<!-- <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=547404745751065&ev=PageView&noscript=1" /> -->
		</div>
	</div>

	<!-- <img src="//www.googleadservices.com/pagead/conversion/879733486/?label=nsUqCPvp7mcQ7tW-owM&amp;guid=ON&amp;script=0" height="1" width="1" style="border-style:none;" alt="" />
<img src="//bat.bing.com/action/0?ti=5612548&Ver=2" height="0" width="0" style="display:none; visibility: hidden;" /> -->

	<div *ngIf="false" class="alert alert-info" style="margin-top: 25px">
		{{ confirmForm.value | json }}
	</div>
</div>
