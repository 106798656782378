import { log } from './logger'

export class RecordBuilder {
	/**
	 * @param fields: An array of the record field definitions
	 * @param record: An array of the record data items
	 * @returns An object representing a single record
	 */

	private static buildRecord(fields: any[], recordData): any {
		const item = {}
		const fieldCount = fields.length
		for (let i = 0; i < fieldCount; i++) {
			const field = fields[i].name
			item[field] = recordData[i]
		}
		return item
	}

	/**
	 * @param payload: JSON parsed results from the lambda call
	 * @returns An array of parsed record objects
	 */

	static buildRecords(payload: { fields: any[]; records: any[] }): any[] {
		const items: any[] = []
		const fields = payload.fields
		const records = payload.records
		records.forEach((rec) => {
			const item: any = this.buildRecord(fields, rec)
			items.push(item)
		})
		return items
	}
}
