<div class="urr-page-box">
	<div class="urr-report-wrapper">
		<app-user-report-header
			[config]="config"
			[logoUrl]="vm.logoUrl"
			[companyName]="vm.companyName"
			[address]="vm.address"
			[reportType]="vm.reportType"
			[transactionMetaData]="vm.reportData.transactionMetaData"
			[publicView]="publicView"
			(printPageBtnClicked)="printPage()"
			(closeBtnClicked)="closeBtnClicked.emit($event)"
		>
		</app-user-report-header>

		<div *ngIf="vm.ivr.isIvrIncidentReport">
			<div class="urr-block no-page-break" style="text-align: center">
				<div class="card urr-card">
					<div>
						<div class="urr-label urr-header-label">IVR Incident Report</div>
					</div>
				</div>
			</div>

			<div class="urr-block no-page-break">
				<div class="card urr-card">
					<div>
						<div class="urr-label urr-header-label nowrap">Submitted On:</div>
						<div class="urr-header-value">{{ vm.reportDate }}</div>
					</div>
				</div>
			</div>

			<div class="urr-block no-page-break">
				<div class="card urr-card">
					<div>
						<div class="urr-label urr-header-label nowrap">Transcript:</div>
						<div>{{ vm.ivr.ivrTranscript }}</div>
						<div (click)="playAudio()" class="audio-link"><i title="Play Audio" class="fa fa-volume-up"></i> Play Audio</div>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="!vm.ivr.isIvrIncidentReport">
			<div class="urr-block no-page-break">
				<div class="card urr-card">
					<div>
						<div class="urr-label urr-header-label nowrap">Incident Type:</div>
						<div class="urr-header-value">{{ vm.incidentType }}</div>
					</div>
					<div>
						<div class="urr-label urr-header-label nowrap">Reported By:</div>
						<div class="urr-header-value">{{ vm.reporter.name }}</div>
					</div>
					<div>
						<div class="urr-label urr-header-label nowrap">License Number:</div>
						<div class="urr-header-value">{{ vm.reporter.license }}</div>
					</div>
					<div>
						<div class="urr-label urr-header-label nowrap">Submitted On:</div>
						<div class="urr-header-value">{{ vm.reportDate }}</div>
					</div>
				</div>
			</div>

			<div class="urr-block no-page-break">
				<div class="card urr-card">
					<div *ngFor="let desc of vm.describeIncident" class="urr-section">
						<div class="urr-label">{{ desc.label }}</div>
						<div class="urr-description">{{ desc.description }}</div>
					</div>
				</div>
			</div>

			<div class="urr-block">
				<div *ngFor="let ab of vm.answers" class="card urr-card urr-answer-card no-page-break">
					<div>
						<div class="urr-answer-btn float-right" [class.urr-answer-yes]="ab.answer === 'YES'">{{ ab.answer }}</div>
						<div class="urr-label urr-answer-label">{{ ab.label }}</div>
						<a class="noprint badge badge-secondary urr-show-link" *ngIf="ab.contactCount > 0" href="#" (click)="ab.show = !ab.show; (false)">{{
							ab.show ? 'hide' : 'show'
						}}</a>
					</div>
					<div class="urr-description" *ngIf="ab.description">{{ ab.description }}</div>
					<div *ngIf="ab.contactCount > 0 && ab.show">
						<hr />

						<!-- General Contacts -->
						<div class="urr-contact-block" *ngFor="let contact of ab.contacts; let idx = index">
							<div *ngIf="vm.isStandardContact(contact.type)" class="urr-contact-item">
								<div class="row">
									<div class="col-md-4 col-sm-12">
										<div class="urr-contact-label">Name</div>
										<div class="urr-contact-value">{{ contact.name.skip ? vm.skipLabel : contact.name.value }}</div>
									</div>
									<div class="col-md-4 col-sm-12">
										<div class="urr-contact-label">Phone</div>
										<div class="urr-contact-value">{{ contact.phone.skip ? vm.skipLabel : contact.phone.value }}</div>
									</div>
									<div class="col-md-4 col-sm-12">
										<div class="urr-contact-label">Email</div>
										<div class="urr-contact-value">{{ contact.email.skip ? vm.skipLabel : contact.email.value }}</div>
									</div>
								</div>
								<div class="row" *ngIf="vm.doesContactTypeShowDate(contact.type)" style="margin-top: 15px">
									<div class="col-12">
										<div class="urr-contact-label">Contacted On:</div>
										<div class="urr-contact-value">{{ vm.displayDateForContact(contact) }}</div>
									</div>
								</div>
								<div *ngIf="contact.notes?.value" class="row" style="margin-top: 25px">
									<div class="col-12">
										<div class="urr-contact-notes"><strong>Notes:</strong> {{ contact.notes?.value }}</div>
									</div>
								</div>
								<hr *ngIf="idx < ab.contacts.length - 1" />
							</div>
						</div>

						<!-- Department Contacts -->
						<div class="urr-contact-block" *ngFor="let dept of ab.departments; let idx = index">
							<div class="urr-contact-item">
								<div class="row">
									<div class="col-md-4 col-sm-12">
										<div class="urr-contact-label">Name / Department</div>
										<div class="urr-contact-value">{{ dept.name.skip ? vm.skipLabel : dept.name.value }}</div>
									</div>
									<div class="col-md-4 col-sm-12">
										<div class="urr-contact-label">Identification Number</div>
										<div class="urr-contact-value">{{ dept.identifier.skip ? vm.skipLabel : dept.identifier.value }}</div>
									</div>
									<div class="col-md-4 col-sm-12">
										<div class="urr-contact-label">Report / Case Number</div>
										<div class="urr-contact-value">{{ dept.reportNumber.skip ? vm.skipLabel : dept.reportNumber.value }}</div>
									</div>
								</div>
								<div class="row" style="margin-top: 15px">
									<div class="col-12">
										<div class="urr-contact-label">Contacted On:</div>
										<div class="urr-contact-value">{{ vm.displayDateForContact(dept) }}</div>
									</div>
								</div>
								<div *ngIf="dept.notes?.value" class="row" style="margin-top: 25px">
									<div class="col-12">
										<div class="urr-contact-notes"><strong>Notes:</strong> {{ dept.notes?.value }}</div>
									</div>
								</div>
								<hr *ngIf="idx < ab.departments.length - 1" />
							</div>
						</div>

						<!-- Injured Contacts -->
						<div class="urr-contact-block" *ngFor="let injured of ab.injured; let idx = index">
							<div class="urr-contact-item">
								<div class="row">
									<div class="col-md-4 col-sm-12">
										<div class="urr-contact-label">Name</div>
										<div class="urr-contact-value">{{ injured.name.skip ? vm.skipLabel : injured.name.value }}</div>
									</div>
									<div class="col-md-4 col-sm-12">
										<div class="urr-contact-label">Phone</div>
										<div class="urr-contact-value">{{ injured.phone.skip ? vm.skipLabel : injured.phone.value }}</div>
									</div>
									<div class="col-md-4 col-sm-12">
										<div class="urr-contact-label">Email</div>
										<div class="urr-contact-value">{{ injured.email.skip ? vm.skipLabel : injured.email.value }}</div>
									</div>
								</div>

								<hr />

								<div style="max-width: 400px; margin-top: 25px">
									<div class="urr-sub-answer-block">
										<div class="urr-answer-btn urr-answer-btn-sm" [class.urr-answer-yes]="injured.wasMedicalAssistanceRefused.value">
											{{ injured.wasMedicalAssistanceRefused.value ? 'YES' : 'NO' }}
										</div>
										<div class="urr-sub-answer-label">Was medical assistance refused</div>
									</div>
									<div class="urr-sub-answer-block">
										<div class="urr-answer-btn urr-answer-btn-sm" [class.urr-answer-yes]="injured.wasMedicalAssistanceRequested.value">
											{{ injured.wasMedicalAssistanceRequested.value ? 'YES' : 'NO' }}
										</div>
										<div class="urr-sub-answer-label">Was medical assistance requested</div>
									</div>
									<div class="urr-sub-answer-block">
										<div class="urr-answer-btn urr-answer-btn-sm" [class.urr-answer-yes]="injured.wasInjuredPartyWearingGlasses.value">
											{{ injured.wasInjuredPartyWearingGlasses.value ? 'YES' : 'NO' }}
										</div>
										<div class="urr-sub-answer-label">Was injured party wearing glasses</div>
									</div>
									<div class="urr-sub-answer-block">
										<div class="urr-answer-btn urr-answer-btn-sm" [class.urr-answer-yes]="injured.wasInjuredPartyCarryingAnything.value">
											{{ injured.wasInjuredPartyCarryingAnything.value ? 'YES' : 'NO' }}
										</div>
										<div class="urr-sub-answer-label">Was injured party carrying anything</div>
									</div>
								</div>
							</div>

							<hr />

							<div>
								<div class="urr-contact-label" style="margin-top: 25px">Describe Injuries</div>
								<div class="urr-answer-description">{{ injured.describeInjuries.value }}</div>

								<div style="margin-top: 25px">
									<div class="urr-contact-label">Describe injured party's shoes</div>
									<div class="urr-answer-description">{{ injured.describeShoesOfInjuredParty.value }}</div>
								</div>

								<div *ngIf="injured.wasInjuredPartyCarryingAnything.value" style="margin-top: 25px">
									<div class="urr-contact-label">Describe what injured party was carrying</div>
									<div class="urr-answer-description">{{ injured.wasInjuredPartyCarryingAnything.description }}</div>
								</div>

								<div *ngIf="injured.notes?.value" class="row" style="margin-top: 25px">
									<div class="col-12">
										<div class="urr-contact-notes"><strong>Notes:</strong> {{ injured.notes?.value }}</div>
									</div>
								</div>
								<hr *ngIf="idx < ab.injured.length - 1" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="urr-block no-page-break">
				<div class="card urr-card">
					<div *ngFor="let desc of vm.conditions" class="urr-section">
						<div class="urr-label">{{ desc.label }}</div>
						<div class="urr-description">{{ desc.description }}</div>
					</div>
				</div>
			</div>

			<app-user-report-footer [incident]="incident" [images]="vm.images"></app-user-report-footer>
		</div>
	</div>
</div>
