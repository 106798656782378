import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ReportFieldInputBasic } from '@app/models'

@Component({
    selector: 'app-checklist-report-item-input',
    templateUrl: './checklist-report-item-input.component.html',
    styleUrls: ['./checklist-report-item-input.component.scss'],
    standalone: false
})
export class ChecklistReportItemInputComponent {
	@Input() item: ReportFieldInputBasic
	@Output() toggleSkipCheckbox = new EventEmitter<ReportFieldInputBasic>()
	@Output() textResizer = new EventEmitter<HTMLTextAreaElement>()
}
