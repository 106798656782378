import { Router } from '@angular/router'
import { Component, OnInit, OnDestroy, AfterViewInit, Input, Output, EventEmitter } from '@angular/core'

import { JobSiteRecord } from '@app/models'
import { EmployeesService, JobSitesService } from '@app/services'

import { log, Helper, DisplayHelper } from '@app/helpers'
import _ from 'lodash'

class JobSiteNumberEmployee {
	name: string
	phone: string
	empId: number
}

@Component({
    selector: 'app-jobsite-numbers',
    templateUrl: './jobsite-numbers.component.html',
    styleUrls: ['./jobsite-numbers.component.scss'],
    standalone: false
})
export class JobsiteNumbersComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() jobSiteId: number
	@Input() linkType: string
	@Output() closeDialog: EventEmitter<boolean> = new EventEmitter()

	linkedNumbers: Array<string> = []
	linkedEmployees: Array<JobSiteNumberEmployee> = []
	jobSite: JobSiteRecord

	constructor(
		private router: Router,
		private empSrvc: EmployeesService,
		private siteSrvc: JobSitesService,
	) {}

	ngOnInit() {
		log('JobSiteId:', this.jobSiteId)
		this.jobSite = this.siteSrvc.getJobSiteById(this.jobSiteId)
		const jobSite = this.jobSite

		if (jobSite) {
			const numbers = jobSite.phone_number_regex_e164.split(',').filter((num) => num !== '+18888888888')
			numbers.forEach((num) => {
				const employee = this.empSrvc.getEmployeeForPhoneNumberE164(num)
				if (employee) {
					const emp = new JobSiteNumberEmployee()
					emp.name = employee.name
					emp.phone = DisplayHelper.displayPhone(employee.phone_number_e164)
					emp.empId = employee.id
					this.linkedEmployees.push(emp)
				} else {
					this.linkedNumbers.push(DisplayHelper.displayPhone(num))
				}
				this.linkedEmployees = _.sortBy(this.linkedEmployees, 'name')
			})
			// this.linkedNumbers = numbers.map(num => {
			// 	return Helper.displayPhone(this.empSrvc.getEmployeeNameForPhoneNumberE164(num));
			// });
		}

		log('JOBSITE:', jobSite)
	}

	goToEmployee(empId: number): boolean {
		this.closeDialog.emit(true)
		const empUrl = '/admin/employees/edit/' + empId
		this.router.navigate([empUrl])
		return false
	}

	ngAfterViewInit() {
		const element: any = $('#jobSiteNumbersModal')
		element.modal('show')
	}

	ngOnDestroy() {
		const element: any = $('#jobSiteNumbersModal')
		element.modal('hide')
	}

	onClose() {
		this.closeDialog.emit(true)
	}
}
