import { Component, OnInit } from '@angular/core'
import { DateTimeHelper, log } from '@app/helpers'
import { FISync, DatabaseTableName } from '@app/models'
import { CoreService } from '@app/services'
import { environment } from '@env/environment'

// Component used for general file importing by customers. When setup for this, they can drop
// files to upload them. The files are uploaded to a bucket in S3 under the companyId and
// then processed.

class FileImportIntegrationViewModel {
	coreSrvc: CoreService
	record: FISync = new FISync()

	credentials = { show: true, awsKey: 'AWSKEY', awsSecret: 'AWSSECRET', awsBucket: 'AWSBUCKET' }
	hideSecretKey = true

	showEmpTooltip = false
	showJobTooltip = false
	showSupervisorTooltip = false
	showTransLogTooltip = false
	showVacationTooltip = false

	constructor(coreSrvc: CoreService, record?: FISync) {
		if (record) {
			// Setup Employee Sync Info
			this.coreSrvc = coreSrvc
			const bucket = environment.fileImportBucket
			this.record = record
			this.credentials.awsBucket = `s3://${bucket}/${record.companyId}/`
			this.credentials.awsKey = record.awsAccessKeyId
			this.credentials.awsSecret = record.awsSecretAccessKey
		}
	}

	get emailAddressList(): string {
		return this.record.emails.join(', ')
	}

	public formatSyncDate(date: Date) {
		return DateTimeHelper.mediumDateTimeFromDate(date)
	}

	public copyCredential(key: string) {
		const copyText = document.getElementById(`${key}`) as any

		/* Select the text field */
		copyText.select()
		copyText.setSelectionRange(0, 99999) /* For mobile devices */

		/* Copy the text inside the text field */
		document.execCommand('copy')
		this.notifyContentsCopied(key)
	}

	private notifyContentsCopied(key: string) {
		let keyName = null
		switch (key) {
			case 'awsBucket':
				keyName = 'AWS Bucket URL'
				break
			case 'awsKey':
				keyName = 'AWS Key'
				break
			case 'awsSecret':
				keyName = 'AWS Secret Key'
				break
		}
		if (keyName) {
			this.coreSrvc.notifySrvc.notify('success', 'Item Copied', `The ${keyName} has been copied to your clipboard.`, 3)
		}
	}
}

@Component({
    selector: 'app-file-import-integration',
    templateUrl: './file-import-integration.component.html',
    styleUrls: ['./file-import-integration.component.scss'],
    standalone: false
})
export class FileImportIntegrationComponent implements OnInit {
	// isWorking = { syncNow: false, empImport: false, jobImport: false, vacImport: false }
	isDataLoaded = false
	isIntegrated = false

	vm = new FileImportIntegrationViewModel(this.coreSrvc)
	fiSync = new FISync()

	constructor(private coreSrvc: CoreService) {
		this.checkForIntegration()
		this.loadData()
	}

	ngOnInit(): void {}

	private checkForIntegration() {
		this.isIntegrated = this.coreSrvc.dbSrvc.settingSrvc.isFileImportIntegrated()
	}

	private loadData() {
		log('Loading File Import Data')
		const tables: Array<DatabaseTableName> = ['file_import_sync']
		this.coreSrvc.dbSrvc.bulkRead(tables).then((result) => {
			this.setupComponent()
			this.isDataLoaded = true
		})
	}

	private setupComponent() {
		log('Setting up component')
		const fiSync = this.coreSrvc.dbSrvc.fiSrvc.getFISync()
		this.vm = new FileImportIntegrationViewModel(this.coreSrvc, fiSync)
		log('FISync Record', fiSync)
	}

	public uploadComplete() {
		log('File Import Integration Component received uploadComplete event')
	}
}
