import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, AfterViewInit, ChangeDetectionStrategy, NgZone } from '@angular/core'
import {
	AuditLog,
	AuditLogDataAccessRequest,
	AuditLogDiffSlot,
	AuditLogFormatterData,
	AuditLogPruneList,
	AuditLogRequestOptions,
	AuditPropertyFormatter,
	auditPropertyLookup,
	ComponentBridgeName,
	Incident,
} from '@app/models'

import { CoreService, DatabaseService } from '@app/services'
import { log, DisplayHelper, DateTimeHelper, Helper } from '@app/helpers'

import moment from 'moment-timezone'
import _ from 'lodash'

@Component({
    selector: 'app-audit-log-diff',
    templateUrl: './audit-log-diff.component.html',
    styleUrls: ['./audit-log-diff.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AuditLogDiffComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() entry: AuditLog
	@Input() records: Array<AuditLog> = []

	idx: number

	difference: Object
	previousData: Object
	currentData: Object

	viewFullRecord: 'CURRENT' | 'PREVIOUS' = null

	isDataLoaded = false
	hasChanges = true
	// hasAuditInfo = true
	showCurrentRecord = false

	bridgeName: ComponentBridgeName = 'ngBridgeAuditDialogDiff'

	constructor(
		private cd: ChangeDetectorRef,
		private zone: NgZone,
		private coreSrvc: CoreService,
	) {}

	get hasData(): boolean {
		return this.isDataLoaded && (this.records.length > 0 || this.coreSrvc.dbSrvc.auditLogSrvc.list.length > 0)
	}

	ngOnInit() {
		window[this.bridgeName] = this
	}

	ngOnDestroy() {
		delete window[this.bridgeName]
	}

	ngAfterViewInit() {
		if (this.records.length === 0) {
			this.loadRecords()
		} else {
			// log('Records Supplied by parent')
			this.setupRecords(this.records)
			this.isDataLoaded = true
			this.cd.detectChanges()
		}
		// log('Audit Log Resource', this.entry.resource)
	}

	loadRecords() {
		this.coreSrvc.workSrvc.blockWorking()
		if (this.entry) {
			const options = this.getRequestOptionsForRecord(this.entry)
			const request = new AuditLogDataAccessRequest(options)
			// log('Lambda Request', request)
			this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
				const data = result.data
				const records = data.map((rec) => new AuditLog(rec))
				this.coreSrvc.dbSrvc.auditLogSrvc.diffList = records
				this.setupRecords(records)
				this.isDataLoaded = true
				this.coreSrvc.workSrvc.unblockWorking()
				this.cd.detectChanges()
				// log('Lambda Results', records)
			})
		}
	}

	getRequestOptionsForRecord(record: AuditLog): AuditLogRequestOptions {
		const options = { resource: record.resource, resource_id: record.resource_id, type: null }
		if (record.operation === 'track') {
			const trackData = record.data ? JSON.parse(record.data) : null
			const type = trackData.type
			options.type = type
		}
		log('OPTIONS', options)
		return options
	}

	setupRecords(records: Array<AuditLog>) {
		const idx = records.findIndex((rec) => rec.id === this.entry.id)
		this.idx = idx
		this.records = records

		if (idx === 0) {
			this.showCurrentRecord = true
		} else {
			this.showCurrentRecord = false
		}
		// log('Current Idx', this.idx)

		if (idx || idx === 0) {
			const currentData = records[idx].data
			const previousData = idx > 0 ? records[idx - 1].data : ''
			this.currentData = this.prepareObject(currentData)
			this.previousData = this.prepareObject(previousData)

			// log('Current Data', this.currentData)
			// log('Previous Data', this.previousData)

			this.difference = AuditLog.difference(this.currentData, this.previousData)
			this.previousData = this.prunePreviousRecord(this.previousData, this.difference)

			if (_.isEmpty(this.difference)) {
				this.hasChanges = false
			} else {
				this.hasChanges = true
			}
		}
	}

	viewWorkorderTemplate(slotIndex: number) {
		const index = slotIndex
		const record = this.records[index]
		const recordDataString = record?.data
		if (recordDataString) {
			const parsedRecord = JSON.parse(recordDataString)
			const htmlTemplate = parsedRecord.html_template
			const win = window.open()
			win.document.write(htmlTemplate)
			win.document.close()
		}
	}

	downloadWorkorderTemplate(slotIndex: number) {
		const index = slotIndex
		const record = this.records[index]
		const recordDataString = record?.data
		if (recordDataString) {
			const parsedRecord = JSON.parse(recordDataString)
			const htmlTemplate = parsedRecord.html_template
			Helper.downloadHtmlContent(htmlTemplate, 'workorder_template.html')
		}
	}

	viewIncidentReport(slotIndex: number) {
		// The commented out logic may actually come into play. Need to test this with new transactions and reports.
		const index = slotIndex // this.isViewingFullRecord && this.viewFullRecord === 'CURRENT' ? slotIndex + 1 : slotIndex
		log('Looking at effective Index', index)

		// log('View Record', index)
		// alert('This functionality has not yet been implemented')
		const record = this.records[index]
		const recordDataString = record?.data
		if (recordDataString) {
			const parsedRecord = JSON.parse(recordDataString)
			const imageFiles = parsedRecord?.images_json
			const reportData = parsedRecord?.report_json
			parsedRecord.images_json = imageFiles ? JSON.stringify(imageFiles) : null
			parsedRecord.report_json = reportData ? JSON.stringify(reportData) : null
			const incident = new Incident(parsedRecord)

			// If it's a shift report then notify there is no report to view
			// if (incident.report_type === 'SHIFT') {
			// 	this.coreSrvc.notifySrvc.notify('info', 'No Report', 'Shift reports do not support report view at this time.', 4)
			// 	return
			// }

			this.displayReportFullScreen(incident)
			// DEPRECATED 2024-04-10 - Just show the incident report that was pulled from the record
			// const transId = incident?.transaction_log_id
			// const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(transId)
			// if (trans) {
			// 	this.displayReportFullScreen(incident)
			// } else {
			// 	this.coreSrvc.dbSrvc.readRecord('transaction_log', transId).then((result) => {
			// 		this.displayReportFullScreen(incident)
			// 	})
			// }

			log('Incident', incident)
		}
		// log('Record to view', record)
		// return false
	}

	displayReportFullScreen(incident: Incident) {
		this.zone.run(() => this.coreSrvc.eventSrvc.showFullScreenReport(incident))
	}

	get currentOperation(): string {
		const current = this.getCurrentRecord()
		return current ? current.operation : 'Unknown'
	}

	get currentAdminUsername(): string {
		const current = this.getCurrentRecord()
		return current ? current.user_name : null
	}

	get currentRecordDate(): string {
		const current = this.getCurrentRecord()
		if (current) {
			const mom = moment(current.created)
			if (mom.isValid()) {
				return mom.format('ddd MMM Do, YYYY [@] hh:mm a')
			}
		}
		return null
	}

	get isCurrentOperationTrack(): boolean {
		const current = this.getCurrentRecord()
		if (current && current.operation === 'track') {
			return true
		}
		return false
	}

	get isCurrentOperationLogin(): boolean {
		const current = this.getCurrentRecord()
		if (current && current.operation === 'login') {
			return true
		}
		return false
	}

	get isCurrentOperationDelete(): boolean {
		const current = this.getCurrentRecord()
		if (current && current.operation === 'delete') {
			return true
		}
		return false
	}

	get isCurrentOperationInsert(): boolean {
		const current = this.getCurrentRecord()
		if (current && current.operation === 'insert') {
			return true
		}
		return false
	}

	get isCurrentOperationUpdate(): boolean {
		const current = this.getCurrentRecord()
		if (current && current.operation === 'update') {
			return true
		}
		return false
	}

	get isViewingFullRecord(): boolean {
		return !!this.viewFullRecord
	}

	// get headerMessage(): string {
	// 	if (this.isCurrentOperationLogin) return 'User Login Event'
	// 	if (this.isCurrentOperationTrack) return 'Tracking Event'
	// 	return 'Initial Log Entry'
	// }

	// getNextRecord(): AuditLog {
	// 	const idx = this.idx
	// 	if (idx && idx < this.records.length - 1) { return this.records[idx + 1] }
	// 	return null
	// }

	getCurrentRecord(): AuditLog {
		const idx = this.idx
		return this.records[idx]
	}

	// getPreviousRecord(): AuditLog {
	// 	const idx = this.idx
	// 	if (idx > 0) { return this.records[idx - 1] }
	// 	return null
	// }

	// Navigation

	get canMoveToNext(): boolean {
		const idx = this.idx
		if (idx < this.records.length - 1) {
			return true
		}
		return false
	}

	get canMoveToPrevious(): boolean {
		const idx = this.idx
		if (idx > 0) {
			return true
		}
		return false
	}

	get fullPreviousRecord(): any {
		const idx = this.idx
		if (idx === 0) {
			return {}
		} else {
			const data = this.records[idx - 1].data || ''
			return this.prepareObject(data)
		}
	}

	moveToNext() {
		if (!this.canMoveToNext) {
			return
		}
		const idx = this.idx
		this.entry = this.records[idx + 1]
		// log('Entry', this.entry)
		this.setupRecords(this.records)
	}

	moveToPrevious() {
		if (!this.canMoveToPrevious) {
			return
		}
		const idx = this.idx
		this.entry = this.records[idx - 1]
		// log('Entry', this.entry)
		this.setupRecords(this.records)
	}

	logState() {
		log('Difference', this.difference)
		log('Current', this.currentData)
		log('Previous', this.previousData)
		log('Records', this.records)
	}

	prepareObject(str: string): Object {
		if (!str) {
			return {}
		}
		let result = JSON.parse(str)
		result = this.stringifyProperties(result)
		result = this.pruneProperties(result)
		return result
	}

	stringifyProperties(obj: Object): Object {
		const result = _.cloneDeep(obj) // { ...obj }
		for (const attr in obj) {
			if (obj.hasOwnProperty(attr)) {
				// log('Attr', attr, obj[attr])
				const currentProp = obj[attr]
				if (_.isPlainObject(currentProp) || _.isArray(currentProp)) {
					result[attr] = JSON.stringify(currentProp)
				} else {
					result[attr] = currentProp?.toString() ?? '' // currentProp  ? currentProp.toString() : ''
				}
			}
		}
		return result
	}

	pruneProperties(obj: Object): Object {
		const result = { ...obj }
		AuditLogPruneList.forEach((attr) => {
			delete result[attr]
		})
		return result
	}

	prunePreviousRecord(obj: Object, changes: Object): Object {
		const result = {}
		for (const attr in changes) {
			if (obj.hasOwnProperty(attr)) {
				result[attr] = obj[attr]
			}
		}
		return result
	}

	makePropertyListArray(obj: Object, previous: boolean) {
		const results = []
		for (const attr in obj) {
			if (obj.hasOwnProperty(attr)) {
				const value = obj[attr]
				const item = { name: attr, value: value } // ? value : previous ? '< not set >' : '< not set >' }
				// if (item.value) {
				results.push(item)
				// }
			}
		}
		return results
	}

	propertyNameFormatter(name: string): string {
		if (!name) {
			return ''
		}
		const resource = this.entry.resource
		const mapper = this.entry.operation === 'track' ? auditPropertyLookup['track'] : auditPropertyLookup[resource]

		if (mapper) {
			const mappedName = mapper[name]
			if (mappedName) {
				return mappedName.name
			}
		}
		const comps = name.split('_')
		return comps.map((c) => c.charAt(0).toUpperCase() + c.slice(1)).join(' ')
	}

	phoneNumberFormatter(number: string): string {
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeForPhoneNumberE164(number)
		if (emp) {
			return `${DisplayHelper.displayPhone(number)} - ${emp.name}`
		}
		return DisplayHelper.displayPhone(number)
	}

	propertyValueFormatter(prop: string, value: any, slot: AuditLogDiffSlot, fullView: boolean): string {
		// At this point in display cycle, value will have been set to < not set > if property is empty and
		// so check against that rather than for empty values.

		let index = this.idx
		if (slot === 'PREVIOUS') {
			index--
		}

		// Check for specififc resource property formatter and use that

		// log('Property', prop)
		const resource = this.entry.resource
		const mapper = this.entry.operation === 'track' ? auditPropertyLookup['track'] : auditPropertyLookup[resource]
		const propFormatter = mapper ? mapper[prop] : null

		if (propFormatter) {
			const formatter = propFormatter.formatter
			if (formatter) {
				const formatterData = new AuditLogFormatterData(
					this.coreSrvc.dbSrvc,
					slot,
					this.previousData,
					this.currentData,
					prop,
					value,
					index,
					fullView,
				)
				const result = formatter(formatterData)
				// log('Got a property formatter', result)
				return result ?? '< not set >'
			}
		}

		// If no property formatter, use the default formatters
		// log('Using default formatter')
		const id = parseInt(value, 10)
		switch (prop) {
			case 'report_body':
				return `&lt; html data &gt;`
			case 'admin_prefs_json':
				return `&lt; json data &gt;`
			case 'emp_notification_delay':
			case 'sup_notification_delay':
			case 'emp_notification_delay_out':
			case 'sup_notification_delay_out':
				return `${DateTimeHelper.formatDurationInMinutes(value)} minutes`
			case 'employee_id':
			case 'employee_id_override':
				const empId = id ? id : 0
				const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)
				return emp
					? `<b style="color:chocolate">${empId} / <i>${emp.name}</i></b>`
					: `<b style="color:chocolate">${empId} / &lt; unavailable &gt;</b>`
			case 'job_id':
				const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(id)
				return job
					? `<b style="color:chocolate">${value} / <i>${job.description}</i></b>`
					: `<b style="color:chocolate">${value} / &lt; unavailable &gt;</b>`
			case 'last_active':
				return value ? DateTimeHelper.mediumDateTimeFromDateString(value) : ' < not set >'
			case 'location_id':
			case 'jobsite_id':
				const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(id)
				return site
					? `<b style="color:chocolate">${value} / <i>${site.description}</i></b>`
					: `<b style="color:chocolate">${value} / &lt; unavailable &gt;</b>`
			case 'jobsite_supervisor_id':
			case 'emp_supervisor_id':
				const name = this.coreSrvc.dbSrvc.settingSrvc.getUsernameForUserId(id)
				return name
					? `<b style="color:chocolate">${value} / <i>${name}</i></b>`
					: `<b style="color:chocolate">${value} / &lt; unavailable &gt;</b>`
			case 'phone_number_regex_e164':
				const phoneComps = ((value as string) || '')
					.split(',')
					.filter((number) => number !== '+18888888888')
					.map((number) => this.phoneNumberFormatter(number))
				return phoneComps.join('<br>')
			case 'recurrence':
				const recurrComps = ((value as string) || '').split(';')
				return recurrComps.join('<br>')
			case 'supervisor_email':
				const emailComps = ((value as string) || '').split(',')
				return emailComps.join('<br>')
			case 'start_time':
			case 'end_time':
				const mom = moment(value, 'HH:mm:ss')
				if (mom.isValid()) {
					return mom.format('h:mm a')
				}
				return value
			case 'timezone_id':
				return this.coreSrvc.dbSrvc.settingSrvc.getTimezoneDisplayNameForId(id)
			default:
				return value ? value : `< not set >`
		}
	}
}

// phone_number_regex_e164
