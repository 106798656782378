import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { SectionSwitcherConfig, SectionSwitcherTab } from '@app/models'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-section-switcher',
    templateUrl: './section-switcher.component.html',
    styleUrls: ['./section-switcher.component.scss'],
    standalone: false
})
export class SectionSwitcherComponent implements OnInit, OnDestroy {
	tabs = []
	currentTab = new SectionSwitcherTab('NONE', 'None')

	subs = new Subscription()

	@Input() config = new SectionSwitcherConfig()
	@Input() message = ''
	@Output() tabClicked = new EventEmitter<SectionSwitcherTab>()

	@Input() liftOn: 'PORTRAIT' | 'LANDSCAPE' = 'PORTRAIT' // 768px or 1024px
	@Input() maxWidth = '200px'
	@Input() paddingTop = '0px'
	@Input() type: 'COUNT' | 'NOCOUNT' = 'COUNT'

	switcherBlockStyle = {
		'padding-top': '0px',
	}
	switcherWrapStyle = {
		'max-width': '200px',
		'margin': 'auto',
	}

	constructor() {}

	ngOnInit(): void {
		this.tabs = this.config.tabs
		if (this.config.initialTab) {
			this.currentTab = this.config.initialTab
		}
		this.setupStyles()

		// Setup tab watcher subscription
		this.subs.add(
			this.config.tabChanged.subscribe((tab) => {
				this.currentTab = tab
			}),
		)
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	setupStyles() {
		this.switcherBlockStyle['padding-top'] = this.paddingTop
		this.switcherWrapStyle['max-width'] = this.maxWidth
	}

	tabBtnClicked(tab: SectionSwitcherTab) {
		this.currentTab = tab
		this.tabClicked.emit(tab)
	}

	// If you need to change the tab from outside
	public switchTab(id: string) {
		const tab = this.tabs.find((tab) => tab.id === id)
		if (tab) {
			this.currentTab = tab
		}
	}
}
