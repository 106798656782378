import { Component, OnInit, OnDestroy, Input, AfterViewInit, Output, EventEmitter, AfterContentInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'

import { Client, DialogManager, FormAddressManager, JobRecord, ContactRecord, OrgTableDisplayState, HelpDialogMessage } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { log, Helper, FormHelper } from '@app/helpers'

import { parseNumber, formatNumber, CountryCode, ParsedNumber, isValidNumberForRegion } from 'libphonenumber-js'

@Component({
    selector: 'app-contact-edit',
    templateUrl: './contact-edit.component.html',
    styleUrls: ['./contact-edit.component.scss'],
    standalone: false
})
export class ContactEditComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
	contact: ContactRecord
	isUpdating = false
	contactForm: UntypedFormGroup

	dialingCode = 'US' as CountryCode
	countryCodeData = Helper.countryIsoData.filter((region) => region.regionSupport)
	phoneNumber = ''
	isPhoneNumberValid = false

	linkedJobs: Array<JobRecord> = []

	// Address
	formAddressManager: FormAddressManager

	@Input() dialogManager = new DialogManager()
	@Input() recordId: number
	@Input() action = 'edit'

	@Output() recordUpdated = new EventEmitter<ContactRecord>()

	constructor(
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
	) {
		this.formAddressManager = new FormAddressManager(this.coreSrvc)
	}

	get isNew(): boolean {
		return !this.contactForm?.get('id').value
	}

	ngOnInit() {
		if (this.action === 'new') {
			log('Creating a new client')
			this.contact = new ContactRecord()
			// Setup type based on which view is selected - the default is CLIENT
			const currentView = this.coreSrvc.dbSrvc.contactSrvc.viewManager.currentView
			// if (currentView === OrgTableDisplayState.vendors) {
			// 	this.org.type = 'VENDOR'
			// }
			const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
			this.dialingCode = company.country_iso as CountryCode
		} else {
			this.contact = this.coreSrvc.dbSrvc.contactSrvc.getContactById(this.recordId)
		}

		log('Init Contact', this.contact)

		this.setupForm()
		this.setupPhoneNumber()
		this.formatNumber()
		this.setupLinkedJobs()
	}

	ngAfterViewInit() {}

	ngAfterContentInit() {
		this.setupDialogManager()
	}

	ngOnDestroy() {}

	viwFormErrors() {
		FormHelper.getFormValidationErrors(this.contactForm)
	}

	isFormValid(): boolean {
		const isEmailValid = this.isEmailValid()
		const isPhoneValid = this.isPhoneValid()
		const isFormValid = this.contactForm.valid && this.formAddressManager.isFormValid
		return isFormValid // && isEmailValid && isPhoneValid
	}

	isEmailValid(): boolean {
		const email = this.contactForm.get('email').value
		if (email) {
			return Helper.isValidEmail(email)
		}
		return true
	}

	setupForm() {
		const contact = this.contact
		this.contactForm = this.fb.group({
			id: [contact.id],
			first: [contact.first, Validators.required],
			last: [contact.last, Validators.required],
			name: [contact.name, Validators.required],
			external_id: [contact.external_id],
			phone_e164: [contact.phone_e164],
			email: [contact.email],
			details: [contact.details],
			notify_phone: [contact.notify_phone],
			notify_sms: [contact.notify_sms],
			notify_email: [contact.notify_email],
		})
		this.formAddressManager.setAddress(contact)
	}

	setupDialogManager() {
		this.dialogManager.canSubmit = () => this.isFormValid()
		this.dialogManager.submitBtnAction = () => this.submit()
	}

	setupLinkedJobs() {
		// const orgId = this.contact?.id
		// this.linkedJobs = this.coreSrvc.dbSrvc.jobSrvc.getJobsForContactId(orgId)
		// log('Linked Jobs', this.linkedJobs)
	}

	// Phone Number Methods

	setupPhoneNumber() {
		const phoneNumber = this.contact.phone_e164
		if (phoneNumber) {
			const parsedNumber = parseNumber(phoneNumber) as ParsedNumber
			const dialingCode = parsedNumber.country
			this.dialingCode = dialingCode
			this.phoneNumber = phoneNumber
		}
	}

	onNameChange() {
		log('Value Changed')
		const firstName = this.contactForm.get('first').value ?? ''
		const lastName = this.contactForm.get('last').value ?? ''
		// if (this.shouldFormatDisplayNameLastFirst) {
		// 	this.contactForm.get('name').setValue(lastName + ', ' + firstName)
		// } else {
		this.contactForm.get('name').setValue(firstName + ' ' + lastName)
		// }
	}

	formatNumber() {
		const countryCode: CountryCode = this.dialingCode
		const number = this.phoneNumber

		if (number && number.length > 3 && isValidNumberForRegion(number, countryCode)) {
			const parsedNumber = parseNumber(number, countryCode) as ParsedNumber
			this.isPhoneNumberValid = true
			this.phoneNumber = formatNumber(parsedNumber, 'NATIONAL')
		} else {
			this.isPhoneNumberValid = false
		}
	}

	isPhoneValid(): boolean {
		if (!this.phoneNumber) {
			return true
		}
		if (isValidNumberForRegion(this.phoneNumber, this.dialingCode)) {
			return true
		} else {
			return false
		}
	}

	submit() {
		// Guard against double submission
		if (this.isUpdating) return
		FormHelper.trimOnlyWhitespace(this.contactForm)

		const record = this.makeUpdateRecord()
		log('Record to submit', record)
		if (record) {
			if (this.isNew) {
				log('Adding new contact', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.insertRecord('contact', record).then((success) => {
					if (success) {
						this.recordUpdated.emit(this.contact)
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			} else {
				log('Updating record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.updateRecord('contact', record).then((success) => {
					if (success) {
						this.recordUpdated.emit(this.contact)
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			}
		}
	}

	makeUpdatePhone(): string {
		if (!this.phoneNumber) {
			return null
		}
		const parsedNumber = parseNumber(this.phoneNumber, this.dialingCode) as ParsedNumber
		if (isValidNumberForRegion(this.phoneNumber, this.dialingCode)) {
			return formatNumber(parsedNumber, 'E.164')
		} else {
			alert('The phone number you entered is not valid for the region selected.')
			return null
		}
	}

	makeUpdateRecord(): Object {
		const form = this.contactForm.value
		const record = new ContactRecord(form)

		if (this.phoneNumber && !this.isPhoneNumberValid) {
			this.coreSrvc.notifySrvc.notify(
				'error',
				'Invalid Phone',
				'Please enter a valid phone number for the region selected or clear the phone number field.',
			)
			return null
		}

		const doesNameExist = this.coreSrvc.dbSrvc.contactSrvc.getContactByName(record.name)
		if (this.isNew && doesNameExist) {
			this.coreSrvc.notifySrvc.notify(
				'error',
				'Duplicate Name',
				'The contact name already exists. Contact names must be unique. Please choose another name.',
			)
			return null
		}

		record.phone_e164 = this.makeUpdatePhone()
		this.formAddressManager.prepareForSubmission(record)

		return record
	}

	// Misc Methods

	toggleCheckbox(prop: string) {
		log('prop', prop)
		const current = this.contactForm.get(prop).value
		this.contactForm.get(prop).setValue(!current)
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
