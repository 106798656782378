<div>
	<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

	<div [hidden]="isUpdating">
		<form novalidate class="edit-form-block" [formGroup]="jobSiteForm" (keydown.enter)="$event.preventDefault()" autocomplete="off">
			<div *ngIf="isSyncLocked" class="alert alert-warning">
				This job site is maintained via 3rd party integration and some fields may not be edited.
			</div>

			<div class="form-group" [class.input-required]="!jobSiteForm.get('description').value">
				<div class="input-label">
					<strong>Job Site Name</strong>
					<span *ngIf="!jobSiteForm.get('description').value" class="float-right">(required)</span>
				</div>
				<input id="empName" type="text" class="form-control" formControlName="description" autocomplete="off" />
			</div>

			<div *ngIf="showSupervisors" class="form-group" [class.input-required]="!jobSiteForm.get('supervisor').value" style="margin-top: 25px">
				<div class="input-label">
					<strong>Job Site Supervisor</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('supervisor')"></i>
					<span *ngIf="!jobSiteForm.get('supervisor').value" class="float-right">(required)</span>
				</div>
				<p-select
					appScrollLockSelect
					id="supervisors"
					[options]="supervisorOptions"
					formControlName="supervisor"
					[filter]="true"
					filterBy="label"
				></p-select>
			</div>

			<div class="form-group">
				<div class="input-label form-spacer">
					<strong>Timezone</strong>
					<span *ngIf="!jobSiteForm.get('timezone_id').value" class="input-required float-right">(required)</span>
				</div>
				<p-select appScrollLockSelect [options]="timezoneOptions" formControlName="timezone_id" [filter]="true" filterBy="label"></p-select>
			</div>

			<!-- <div class="form-group">
				<div class="input-label">
					<strong>Timezone</strong>
					<span *ngIf="!jobSiteForm.get('timezone_id').value" class="input-required float-right">(required)</span>
				</div>
				<select id="timezone" class="form-control" formControlName="timezone_id">
					<option default value="">Select Timezone</option>
					<option *ngFor="let tz of timezoneData" [value]="tz.zone_id">{{ tz.display_name ? tz.display_name : tz.zone_name }}</option>
				</select>
			</div> -->

			<div>
				<app-job-site-edit-location [manager]="locationManager"></app-job-site-edit-location>
			</div>

			<div *ngIf="false && !isFormValid()" class="form-group">
				<div *ngIf="jobSiteForm.invalid" class="alert alert-warning" style="margin-top: 30px">
					Form submission is disabled until all required fields have been set.
				</div>
			</div>

			<!-- Advanced Block -->
			<hr />

			<app-form-expander
				[label]="'Advanced Options'"
				[centerOnExpansion]="true"
				[isExpanded]="showAdvancedOptions"
				(isExpandedChange)="showAdvancedOptions = $event"
			>
				<div *ngIf="showUserGroups" class="form-group">
					<div class="input-label form-spacer">
						<strong>Supervisor Group</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('userGroup')"></i>
						<!-- <span *ngIf="!empForm.get('supervisor_group_id').value" class="input-required float-right">(required)</span> -->
					</div>
					<p-select
						appScrollLockSelect
						id="userGroupsDropdown"
						[options]="userGroupOptions"
						formControlName="supervisor_group_id"
						[filter]="true"
						filterBy="label"
					></p-select>
				</div>

				<div class="form-group">
					<div class="input-label">
						<strong>Job Site Details</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('details')"></i>
						<!-- <span *ngIf="!jobSiteForm.get('description').value" class="input-required float-right">(required)</span> -->
					</div>
					<input id="siteDetails" type="text" class="form-control" formControlName="location_details" autocomplete="off" />
				</div>

				<div *ngIf="isQBDCustomer">
					<div class="tts-hl-2" style="margin: 35px 0px 15px 0px"><strong>QuickBooks Payroll Options</strong></div>
					<div>
						<div style="display: inline-block; padding-top: 7px"><strong>Select Union Zone</strong></div>
						<div class="float-right">
							<select class="form-control" formControlName="union_zone">
								<option default [ngValue]="null">None</option>
								<option [ngValue]="1">Zone 1</option>
								<option [ngValue]="2">Zone 2</option>
								<option [ngValue]="3">Zone 3</option>
								<option [ngValue]="4">Zone 4</option>
								<option [ngValue]="5">Zone 5</option>
								<option [ngValue]="6">Zone 6</option>
								<option [ngValue]="7">Zone 7</option>
								<option [ngValue]="8">Zone 8</option>
							</select>
						</div>
					</div>
				</div>

				<div>
					<div class="tts-hl-2" style="margin: 35px 0px 15px 0px"><strong>Miscellaneous Options</strong></div>
					<!-- Form checkbox for landline numbers -->
					<div class="checkbox-item d-flex justify-content-between">
						<div class="checkbox-wrap">
							<p-checkbox
								[(ngModel)]="linkedNumberManager.linkLandlineCheckbox"
								[ngModelOptions]="{ standalone: true }"
								[binary]="true"
								(onChange)="linkedNumberManager.landlineCheckboxChanged()"
							></p-checkbox>
						</div>
						<div class="checkbox-label-wrap">
							<strong
								(click)="
									linkedNumberManager.linkLandlineCheckbox = !linkedNumberManager.linkLandlineCheckbox;
									linkedNumberManager.landlineCheckboxChanged()
								"
								class="has-pointer"
								>Link job site to landline numbers</strong
							>
							<i
								title="More Information"
								class="far fa-info-circle tts-info-btn"
								aria-hidden="true"
								(click)="showHelp('linkLandlineCheckbox')"
							></i>
						</div>
					</div>
					<app-job-site-edit-linked-numbers [manager]="linkedNumberManager" (showHelp)="showHelp($event)"></app-job-site-edit-linked-numbers>
				</div>
			</app-form-expander>

			<div *ngIf="isInternalUser && jobSite?.created">
				<hr />
				<small>
					<div><strong>Created:</strong> {{ jobSite?.created | dateFormatter: 'YYYY-MM-DD @ h:mma' }}</div>
					<div><strong>Updated:</strong> {{ jobSite?.updated | dateFormatter: 'YYYY-MM-DD @ h:mma' }}</div>
				</small>
			</div>

			<div class="row">
				<div class="col-12">
					<div [hidden]="true" style="margin-top: 15px">
						<button tabindex="16" (click)="onCancel()" (keyup.enter)="onCancel()" class="btn btn-sm btn-danger">Cancel</button>
						<button
							tabindex="17"
							type="submit"
							[disabled]="!isFormValid()"
							class="btn btn-sm btn-success tsf-save-btn"
							(click)="onSubmit(jobSiteForm)"
							(keyup.enter)="onSubmit(jobSiteForm)"
						>
							Save
						</button>
					</div>
					<!-- <div *ngIf="jobSite && jobSite.updated" class="audit-link"><a [routerLink]="['/admin/auditlog', 'location', jobSite.id]"><i class="fa fa-history"></i>&nbsp;Show History</a></div> -->
				</div>
			</div>
		</form>
	</div>
</div>

<br /><br />

<!-- <div *ngIf="false" class="alert alert-info" style="margin-top: 25px;">
	{{ jobSiteForm.value | json }}
	<br> {{ phoneEntries | json }}
</div> -->
