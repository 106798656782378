import { TransactionLogRecord, JobSiteRecord, GPSResponseCalc, Checkpoint, ImageFile } from '@app/models'
import { DatabaseService } from '@app/services/backend/database.service'
import { Helper } from './functions'

import moment from 'moment-timezone'
import { log } from './logger'

import _ from 'lodash'

export interface IMarkerBuilderResult {
	marker: google.maps.marker.AdvancedMarkerElement
	infoWindow: google.maps.InfoWindow
}

export class MapHelper {
	static addJitter(lat: number, lng: number): { lat: number; lng: number } {
		let newLat = lat
		let newLng = lng

		if (lat) {
			const val = Math.floor(Math.random() * 9) / 50000
			newLat += val * (Math.round(Math.random()) * 2 - 1)
		}
		if (lng) {
			const val = Math.floor(Math.random() * 9) / 50000
			newLng += val * (Math.round(Math.random()) * 2 - 1)
		}

		return { lat: newLat, lng: newLng }
	}

	static getNoMapCenter(dbSrvc: DatabaseService): { lat: number; lng: number; zoom: number } {
		const region = dbSrvc.settingSrvc.getCompany().country_iso
		return MapHelper.getNoMapCenterFromRegion(region)
	}

	static getNoMapCenterFromRegion(region: string): { lat: number; lng: number; zoom: number } {
		switch (region) {
			case 'CA':
				return { lat: 49.76773, lng: -96.8097, zoom: 4 }
			case 'GB':
				return { lat: 54.5, lng: -3.2, zoom: 6 }
			case 'US':
				return { lat: 39.828175, lng: -98.5795, zoom: 4 }
			default:
				return { lat: 39.828175, lng: -98.5795, zoom: 4 }
		}
	}

	static formatTimestamp(dateTime: string, timezone: string, is12Hours: boolean, divider: string) {
		const mom = moment(dateTime)
		if (mom.isValid()) {
			const format = is12Hours ? `ddd, MMM Do [${divider}] h:mm a z` : `ddd, MMM Do [${divider}] HH:mm z`
			return mom.tz(timezone).format(format)
		}
		return null
	}

	static buildJobSiteMarker(map: google.maps.Map, site: JobSiteRecord): IMarkerBuilderResult {
		const description = site && site.description ? site.description : 'Missing Info'
		// const address = site.address ? Helper.getStreetInfoFromAddress(site.address) : 'Missing Info'
		const address = site.address_1 ? site.address_1 : 'Missing Info'

		const latLng = new google.maps.LatLng(site.geo_latitude, site.geo_longitude)
		const pinImg = document.createElement('img')
		pinImg.src = '/assets/img/marker-green.png'

		const marker = new google.maps.marker.AdvancedMarkerElement({
			position: latLng,
			map: map,
			title: 'Job Site Location',
			content: pinImg,
		})

		// const marker = new google.maps.Marker({
		// 	position: latLng,
		// 	map: map,
		// 	icon: '/assets/img/marker-green.png',
		// 	title: 'Job Site Location',
		// })

		const titleHtml = `<div class="info-job-title">Job Site</div>`

		const contentString = `
				<div class="map-iw-content">
					${titleHtml}
					<div><strong style="margin-top: 8px;color: #002e99">${description}</strong></div>
					<div class="info-job-addr">${address}</div>
				</div>`

		const infoWindow = new google.maps.InfoWindow({
			content: contentString,
		})

		return { marker: marker, infoWindow: infoWindow }
	}

	// Delay is ISO Duration format PT5M
	static buildTransInOutMarker(
		map: google.maps.Map,
		inOut: 'IN' | 'OUT',
		trans: TransactionLogRecord,
		delay: string,
		includeImages: boolean,
		format12Hours: boolean,
		showDistTime: boolean,
	): IMarkerBuilderResult {
		const markerIcon = inOut === 'IN' ? '/assets/img/marker-black-i.png' : '/assets/img/marker-black-o.png'

		const gpsResponse = new GPSResponseCalc(trans, delay, null, false)
		const metaData = inOut === 'IN' ? trans.bulidMetaData('IN') : trans.bulidMetaData('OUT')

		const address =
			inOut === 'IN' ? Helper.getStreetInfoFromAddress(trans.geo_start_address) : Helper.getStreetInfoFromAddress(trans.geo_end_address)
		const isWebApi = metaData.isWebApi
		const isMobile = metaData.isCellphone
		const isMobileStation = metaData.isMobileStation
		const isStation = metaData.isStation

		const timeHtml = isWebApi
			? `<span class="gps-icon-box hl-valid"> <i class="fa fa-clock-o" aria-hidden="true"></i> 1 min</span>`
			: gpsResponse.getTimeHtml(inOut, false, false, '')

		const distHtml = gpsResponse.getDistanceHtml(inOut, false, false, '')
		const distTimeHtml = trans?.travel_job ? '' : gpsResponse.getDistanceHtml(inOut, true, false, '') + ' / ' + timeHtml

		const lat = inOut === 'IN' ? trans.geo_start_latitude : trans.geo_end_latitude
		const lng = inOut === 'IN' ? trans.geo_start_longitude : trans.geo_end_longitude

		// const jitterCoord = MapHelper.addJitter(lat, lng)

		const latLng = new google.maps.LatLng(lat, lng)
		const pinImg = document.createElement('img')
		pinImg.src = markerIcon

		const marker = new google.maps.marker.AdvancedMarkerElement({
			position: latLng,
			map: map,
			title: inOut === 'IN' ? 'Checkin Location' : 'Checkout Location',
			content: pinImg,
		})

		// const marker = new google.maps.Marker({
		// 	position: latLng,
		// 	map: map,
		// 	title: inOut === 'IN' ? 'Checkin Location' : 'Checkout Location',
		// 	icon: markerIcon,
		// })

		const employeeHtml = `<div><strong>${trans.employee_name}</strong></div>`
		const addressHtml = address ? `<div>${address}</div>` : ``
		const jobHtml = `<div style="margin-top: 10px">${trans.job_description}</div>`
		const sourceType = Helper.capitalizeEachWord(metaData.checkInOutTypeLabel)
		const distanceTime = isWebApi || isMobileStation ? distHtml : distTimeHtml
		const source =
			isStation && !isMobileStation ? `<div><b>On Site ${sourceType}</b></div>` : `<div><b>${sourceType}</b><br />${distanceTime}</div>`
		// const source = isWebApi ? `Web / ${distHtml}` : isMobile ? `Mobile / ${distTimeHtml}` : ''
		const iconsHtml = source && showDistTime ? `<div style="white-space:nowrap;margin-top:10px;line-height:1.2rem">${source}</div>` : ''

		const isTravel = trans.travel_job
		const timezone = trans.timezone
		const timestamp = gpsResponse.getTimestamp(inOut)
		const formattedTimestamp = MapHelper.formatTimestamp(timestamp, timezone, format12Hours, '/')
		const timestampHtml = formattedTimestamp ? `<div class="map-iw-footer">${formattedTimestamp}</div>` : ``
		const inOutType = inOut === 'IN' ? (isTravel ? 'Travel Start' : 'Check-In') : isTravel ? 'Travel End' : 'Check-Out'
		const inOutHtml = `<div class="info-inout-title">${inOutType}</div>`

		const imageList = trans.getImages(inOut)
		const imageScrollerHtml = includeImages ? MapHelper.buildImageScroller(imageList, imageList.length > 1) : ''
		const bodyString = includeImages && imageList.length > 0 ? imageScrollerHtml : `${employeeHtml}${addressHtml}${iconsHtml}${jobHtml}`

		const contentString = `
				<div class="map-iw-content">
					${inOutHtml}
					${bodyString}
					${timestampHtml}
				</div>`

		const infoWindow = new google.maps.InfoWindow({
			content: contentString,
		})

		return { marker: marker, infoWindow: infoWindow }
	}

	static buildCheckpointMarker(
		map: google.maps.Map,
		cp: Checkpoint,
		timezone: string,
		addJitter: boolean,
		includeImages: boolean,
		checkPointNum: number,
		format12Hours: boolean,
	) {
		const jitterCoord = MapHelper.addJitter(cp.geo_latitude, cp.geo_longitude)
		const lat = addJitter ? jitterCoord.lat : cp.geo_latitude
		const lng = addJitter ? jitterCoord.lng : cp.geo_longitude
		const latLng = new google.maps.LatLng(lat, lng)

		const pinImg = document.createElement('img')
		pinImg.src = '/assets/img/marker-blue.png'

		const marker = new google.maps.marker.AdvancedMarkerElement({
			position: latLng,
			map: map,
			title: 'Checkpoint ' + checkPointNum,
			content: pinImg,
		})

		// const marker = new google.maps.Marker({
		// 	position: latLng,
		// 	map: map,
		// 	title: 'Checkpoint ' + checkPointNum,
		// 	icon: '/assets/img/marker-blue.png',
		// })

		const titleHtml = `<div class="info-checkpoint-title">Checkpoint ${checkPointNum}</div>`
		const formattedTimestamp = MapHelper.formatTimestamp(cp.created, timezone, format12Hours, ' / ')
		const timestampHtml = formattedTimestamp ? `<div class="map-iw-footer">${formattedTimestamp}</div>` : ``
		const imageScrollerHtml = includeImages ? MapHelper.buildImageScroller(cp.imageList, cp.imageList.length > 1) : ''

		const contentString = `
				<div class="map-iw-content">
					${titleHtml}
					${imageScrollerHtml}
					${timestampHtml}					
				</div>`

		const infoWindow = new google.maps.InfoWindow({
			content: contentString,
		})

		return { marker: marker, infoWindow: infoWindow }
	}

	static buildImageScroller(images: Array<ImageFile>, showIcons = true) {
		const prevIcon = showIcons ? '<i class="fad fa-circle-left map-scroll-prev-icon"></i>' : ''
		const nextIcon = showIcons ? '<i class="fad fa-circle-right map-scroll-next-icon"></i>' : ''

		let html = `<div class="map-img-scroll-wrapper hide-scrollbars">${prevIcon}`

		for (const image of images) {
			const imageUrl = image.getUrl()
			html += `
			 <div class="map-img-scroll-item">
				<a href="${imageUrl}" target="_blank"><img src="${imageUrl}" alt="Image" style="width: 100px" /></a>
			 </div>
			`
		}

		html += `${nextIcon}</div>`
		return html
	}

	static initializeImageScroller() {
		const wrapper = $('.map-img-scroll-wrapper')[0]
		// log('initializeImageScroller', wrapper)

		wrapper?.addEventListener('scroll', () => {
			MapHelper.updateImageScroller()
		})

		const prevIcon = $('.map-scroll-prev-icon')
		const nextIcon = $('.map-scroll-next-icon')
		const scrollItems = $('.map-img-scroll-item')

		// Add click event listener to the prev icon
		prevIcon?.on('click', () => {
			const currentIndex = Math.floor(wrapper.scrollLeft / $('.map-img-scroll-item').eq(0).outerWidth())
			// log('prevIcon clicked', currentIndex)

			if (currentIndex > 0) {
				MapHelper.scrollToScrollerIndex(currentIndex - 1)
			}
		})

		// Add click event listener to the next icon
		nextIcon?.on('click', () => {
			const currentIndex = Math.floor(wrapper.scrollLeft / $('.map-img-scroll-item').eq(0).outerWidth())
			// log('nextIcon clicked', currentIndex)
			if (currentIndex < scrollItems.length - 1) {
				MapHelper.scrollToScrollerIndex(currentIndex + 1)
			}
		})
	}

	static updateImageScroller = _.debounce(MapHelper.deBouncedupdateImageScroller, 100)

	static deBouncedupdateImageScroller() {
		const wrapper = $('.map-img-scroll-wrapper')[0]
		if (wrapper) {
			const canScrollLeft = wrapper.scrollLeft > 0
			const canScrollRight = wrapper.scrollLeft < wrapper.scrollWidth - wrapper.clientWidth

			// log('updateImageScroller', canScrollLeft, canScrollRight)
			wrapper.classList.toggle('map-scroll-prev-visible', canScrollLeft)
			wrapper.classList.toggle('map-scroll-next-visible', canScrollRight)
		}
	}

	static scrollToScrollerIndex(index) {
		// log('scrollToScrollerIndex', index)
		const wrapper = $('.map-img-scroll-wrapper')[0]
		const scrollPosition = index * $('.map-img-scroll-item').eq(0).outerWidth()
		wrapper?.scrollTo({
			left: scrollPosition,
			behavior: 'smooth',
		})
	}

	static getGoogleClusterInlineSvg(color) {
		const encoded = window.btoa(
			`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200">
				<defs>
					<g id="a" transform="rotate(45)">
						<path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.7"/>
						<path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.5"/>
						<path d="M0 86A86 86 0 0 0 86 0L100 0A100 100 0 0 1 0 100Z" fill-opacity="0.3"/>
					</g>
				</defs>
				<g fill="${color}">
					<circle r="42"/>
					<use xlink:href="#a"/>
					<g transform="rotate(120)">
						<use xlink:href="#a"/>
					</g>
					<g transform="rotate(240)">
						<use xlink:href="#a"/>
					</g>
				</g>
			</svg>`,
		)

		return 'data:image/svg+xml;base64,' + encoded
	}

	static customMarkerSvg(color) {
		const encoded = window.btoa(
			`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200">
				<defs>
					<g id="a" transform="rotate(45)">
						<path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.7"/>
						<path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.5"/>
						<path d="M0 86A86 86 0 0 0 86 0L100 0A100 100 0 0 1 0 100Z" fill-opacity="0.3"/>
					</g>
				</defs>
				<g fill="${color}">
					<circle r="42"/>
					<use xlink:href="#a"/>
					<g transform="rotate(120)">
						<use xlink:href="#a"/>
					</g>
					<g transform="rotate(240)">
						<use xlink:href="#a"/>
					</g>
				</g>
			</svg>`,
		)

		return encoded
	}
}

// <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
// 			<circle cx="120" cy="120" opacity=".8" r="70" />
// 	</svg>

// https://github.com/Concept211/Google-Maps-Markers
