<div class="container">
	<div class="card w2w-card">
		<div>
			<button *ngIf="vm.isIntegrated && !vm.isEditing" class="btn btn-sm btn-success btn-edit float-right" (click)="vm.isEditing = true">
				Edit
			</button>
			<button *ngIf="vm.isIntegrated && vm.isEditing" class="btn btn-sm btn-primary btn-edit float-right" (click)="vm.isEditing = false">
				Done
			</button>
			<div>
				<strong>WhenToWork Integration</strong>
			</div>
			<hr />
		</div>

		<div *ngIf="!vm.isDataLoaded && !isIntegrating" class="loading-block"><i class="fas fa-loader fa-spin"></i> Loading Data ...</div>

		<div *ngIf="vm.isWorking" class="loading-block"><i class="fas fa-loader fa-spin"></i> Verifying Schedule ...</div>

		<div *ngIf="!vm.isIntegrated && vm.isDataLoaded && !isIntegrating">
			<div class="tts-dev-banner">
				<div>WhenToWork Not Integrated</div>
			</div>

			<div class="alert alert-warning pro-plan-details" style="margin-top: 25px">
				Instructions for finding your partner key can be found
				<a
					href="https://www.manula.com/manuals/telephone-timesheets/telephone-timesheets-user-guide/version.3.0/en/topic/setup-standard-integration"
					target="_blank"
					>here</a
				>.
			</div>

			<div class="options-block">
				<div *ngIf="!vm.w2wApiKey" class="input-required float-right">(required)</div>
				<div class="options-title-inline">
					Partner Key
					<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('w2w_api_key')"></i> -->
				</div>

				<div class="input-group">
					<input [(ngModel)]="vm.w2wApiKey" type="text" class="form-control" placeholder="Enter Partner Key" aria-label="Enter Partner Key" />
				</div>
			</div>
			<div>
				<button [disabled]="!vm.w2wApiKey" class="btn btn-success btn-slim float-right" type="button" (click)="startIntegration()">
					Integrate
				</button>
			</div>
		</div>

		<div *ngIf="isIntegrating" class="integrating-block">
			<div><i class="fas fa-loader fa-spin"></i> Integrating WhenToWork...</div>
			<div class="integrating-note">
				Integration may take up to a minute, please be patient. After we finish integrating with WhenToWork, we will begin synchronizing your
				calendar. You do not need to wait here for integration to finish. We will send you an email if we encounter any errors during integration.
			</div>
		</div>

		<!-- View to show when editing integration options once integrated -->
		<div *ngIf="vm.isEditing">
			<div class="alert alert-info alert-bg-gray" style="margin: 15px 0px">
				<div class="pro-plan-details">
					Paste your WhenToWork Partner Key below.
					<a
						href="https://www.manula.com/manuals/telephone-timesheets/telephone-timesheets-user-guide/version.3.0/en/topic/setup-standard-integration"
						target="_blank"
						>Click here</a
					>
					for instructions on obtaining the API key from the WhenToWork admin portal.
				</div>
			</div>

			<div class="options-block" style="margin: 35px 0px">
				<div *ngIf="!vm.w2wApiKey" class="input-required float-right">(required)</div>
				<div class="options-title-inline">
					Partner Key
					<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('w2w_api_key')"></i> -->
				</div>

				<div class="input-group">
					<input [(ngModel)]="vm.w2wApiKey" type="text" class="form-control" placeholder="Enter Partner Key" aria-label="Enter Partner Key" />
				</div>
			</div>

			<div *ngIf="vm.w2wSync.w2w_api_key && vm.w2wSync.partner_api" class="row">
				<div class="col-md-6 col-sm-12">
					<div class="options-block">
						<div class="options-title-inline">Any Employee List</div>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('any_employee')"></i>
						<div class="d-flex justify-content-between" style="max-width: 350px; margin-bottom: 20px">
							<input type="text" class="form-control" [(ngModel)]="anyEmployeeInput" (keyup.enter)="addIgnoreItem('ANYEMPLOYEE')" />
							<button
								class="btn btn-sm btn-outline-secondary"
								style="margin-left: 12px; height: 36px !important"
								(click)="addIgnoreItem('ANYEMPLOYEE')"
							>
								Add
							</button>
						</div>
						<div class="d-flex justify-content-start flex-wrap">
							<div class="badge table-tag mb-1 mr-1" *ngFor="let item of vm.w2wSync.any_employee; let i = index">
								<span>{{ item }}</span>
								<i class="far fa-xmark has-pointer" style="margin-left: 6px" (click)="removeIgnoreItem('ANYEMPLOYEE', i)"></i>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6 col-sm-12">
					<div class="options-block">
						<div class="options-title-inline">Ignore List</div>
						<i
							title="More Information"
							class="far fa-info-circle tts-info-btn"
							aria-hidden="true"
							(click)="showHelp('ignore_entries_with')"
						></i>
						<div class="d-flex justify-content-between" style="max-width: 350px; margin-bottom: 20px">
							<input type="text" class="form-control" [(ngModel)]="ignoreInput" (keyup.enter)="addIgnoreItem('IGNORELIST')" />
							<button
								class="btn btn-sm btn-outline-secondary"
								style="margin-left: 12px; height: 36px !important"
								(click)="addIgnoreItem('IGNORELIST')"
							>
								Add
							</button>
						</div>
						<div class="d-flex justify-content-start flex-wrap">
							<div class="badge table-tag mb-1 mr-1" *ngFor="let item of vm.w2wSync.ignore_entries_with; let i = index">
								<span>{{ item }}</span>
								<i class="far fa-xmark has-pointer" style="margin-left: 6px" (click)="removeIgnoreItem('IGNORELIST', i)"></i>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="options-block">
				<div *ngIf="!vm.isUpdating">
					<!-- <button *ngIf="vm.w2wApiKey" class="btn btn-sm btn-danger" style="margin-right: 8px" (click)="handleApKeyAction('REMOVE')">
						Remove
					</button> -->
					<button class="btn btn-outline-success btn-block" (click)="handleApKeyAction('UPDATE')">
						<span *ngIf="!vm.isUpdating">Update Settings</span>
						<span *ngIf="vm.isUpdating"><i class="fas fa-loader fa-spin" aria-hidden="true"></i> Updating ...</span>
					</button>
				</div>
			</div>
		</div>

		<!-- Default view to show when integrated -->
		<div *ngIf="!isIntegrating && vm.isIntegrated && vm.isDataLoaded && !vm.isWorking && !vm.isEditing">
			<div>
				<div class="w2w-sync-counter" [class.has-sync-errors]="syncLog.length > 0">{{ syncCountHeader }}</div>
				<div>{{ vm.syncMessage }}</div>
			</div>

			<!-- Sync Log -->
			<div id="sync-log-table" class="sync-log" *ngIf="!(isLogUpdating || vm.isSyncing)">
				<p-table
					#syncLogTable
					dataKey="id"
					[columns]="cols"
					[value]="syncLog"
					[paginator]="true"
					[rows]="10"
					[rowsPerPageOptions]="[10, 25, 50]"
					[totalRecords]="syncLog.length"
					[pageLinks]="3"
					[globalFilterFields]="['message', 'summary']"
					styleClass="p-datatable-striped"
					paginatorDropdownAppendTo="body"
				>
					<ng-template pTemplate="caption">
						<div class="png-datatable-header">
							<div class="header-wrap">
								<div class="header-block">
									<div class="auto-sync-label">Auto-Sync Enabled</div>
									<!-- <button [disabled]="isSyncPolling" class="btn btn-sm btn-success btn-block btn-slim" (click)="syncNow()">
										<i *ngIf="!isSyncPolling" class="fa fa-cloud-download" aria-hidden="true"></i>
										<i *ngIf="isSyncPolling" class="fas fa-loader fa-spin" aria-hidden="true"></i>
										{{ isSyncPolling ? 'Sync In Progress' : 'Synchronize Schedule' }}
									</button> -->
								</div>
								<div class="header-block">
									<div class="filter-wrap">
										<!-- <i class="fa fa-search search-icon" style="margin-right: 8px"></i> -->
										<input
											#filterInput
											pInputText
											type="text"
											class="form-control"
											(input)="applyFilterGlobal($event, 'contains')"
											placeholder="Filter"
										/>
										<i
											*ngIf="filterInput.value"
											class="fa fa-times-circle-o"
											style="margin-left: 8px; cursor: pointer"
											(click)="clear(syncLogTable, filterInput)"
										></i>
									</div>
								</div>
							</div>
						</div>
					</ng-template>
					<ng-template pTemplate="header" let-columns>
						<tr>
							<th scope="col">Sync Error</th>
							<th scope="col">W2W Entry With Error</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-rowData let-columns="columns">
						<tr>
							<td [vAlign]="'top'">
								<div>
									<div class="mobile-label">Sync Error</div>
									<div>{{ rowData.message }}</div>
								</div>
							</td>
							<td [vAlign]="'top'">
								<div>
									<div class="mobile-label">W2W Entry With Error</div>
									<div>{{ rowData.summary }}</div>
								</div>
							</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage">
						<tr>
							<td colspan="2"><span style="font-weight: bold; color: green">No sync issues reported</span></td>
						</tr>
					</ng-template>
				</p-table>
			</div>

			<div *ngIf="isLogUpdating || vm.isSyncing" class="working-banner">
				<div *ngIf="isLogUpdating" class="alert alert-info alert-working" style="text-align: center">
					<i class="fas fa-loader fa-spin"></i> Refreshing Log...
				</div>
				<div *ngIf="vm.isSyncing" class="alert alert-info alert-working" style="text-align: center">
					<i class="fas fa-loader fa-spin"></i> Performing Sync...
				</div>
			</div>
			<!-- Sync Log -->
		</div>
	</div>

	<!-- <div *ngIf="vm.isDataLoaded && vm.isIntegrated && !vm.isEditing && !vm.w2wApiKey && !isAdpOrQbIntegrated" style="margin-top: 20px">
		<div class="import-block-header tts-hl-2">
			<div>WhenToWork Employee File Import</div>
			<div *ngIf="vm.hasLastImport" class="last-import-date">{{ vm.lastEmpImportDate }}</div>
		</div>
		<app-file-dropper
			[maxFileCount]="1"
			[fileDropperManager]="fileDropperManager"
			(uploadProcessComplete)="uploadProcessComplete($event)"
		></app-file-dropper>
		<button class="btn btn-secondary btn-block" (click)="viewImportLog()">View Import Log</button>
		<div *ngIf="vm.isIntegrated && !vm.w2wApiKey" class="w2w-pro-block" style="margin-top: 22px">
			<div class="alert alert-warning">
				<div class="pro-block-header">Want an easier way to synchronize your employees?</div>
				<div class="pro-plan-details">
					The WhenToWork Pro Plan offers the ability to automatically synchronize your employees from WhenToWork to Telephone Timesheets, over
					the internet. <a href="https://when2work.com/help/mgr/w2w-pro-plan/" target="_blank">Click here</a> for more information on the
					WhenToWork Pro Plan. Already a Pro Plan customer? <span class="link-text" (click)="vm.isEditing = true">Click here</span> to add your
					Pro Plan API key and enable employee sync.
				</div>
			</div>
		</div>
	</div> -->
</div>
