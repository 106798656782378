import { Component, OnInit, AfterViewInit, Input, ChangeDetectorRef } from '@angular/core'
import { ChecklistReportData, IReportChecklistItem, ReportGlobals, UserReportConfiguration } from '@app/models'
import { log } from '@app/helpers/logger'

@Component({
    selector: 'app-checklist-report',
    templateUrl: './checklist-report.component.html',
    styleUrls: ['./checklist-report.component.scss'],
    standalone: false
})
export class ChecklistReportComponent implements OnInit, AfterViewInit {
	@Input() reportData = new ChecklistReportData()
	@Input() reportConfig: UserReportConfiguration

	format12Hour = ReportGlobals.displayPrefs.format12Hour
	datePickerFormat = ReportGlobals.getDatePickerFormat('DATE')

	constructor(private cd: ChangeDetectorRef) {
		// Don't do anything in constructor, component needs data from above.
		// Don't make calls to any service. Place needed data in reportConfig
	}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		this.setupTextareaHeights()
	}

	skipInput(prop: any, field: string) {
		// log('Skipping', field)
		prop[field] = `< skipped >`
		this.cd.detectChanges()
	}

	toggleSkipCheckbox(item: IReportChecklistItem) {
		// log('Item', item)
		item.skip = !item.skip
		switch (item.type) {
			// If dealing with a checkbox, adjust the value when toggling
			case 'BOOLEAN':
				if (item.skip) {
					item.value = false
				} else {
					item.value = true
				}
				break
		}
		this.setupTextareaHeights()
	}

	elementFocused(event: any) {
		// log('Event', event)
		const id = event.target?.id
		// Remove any validation border colorering
		if (id) {
			$(`#${id}`).removeClass('ur-input-needs-info')
		}
	}

	private setupTextareaHeights() {
		setTimeout(() => {
			$('.ur-cli-comment').each((idx, elem) => this.textResizer(elem as HTMLElement))
		}, 125)
	}

	public textResizer(elm: HTMLElement) {
		elm.style.height = 'auto'
		elm.style.height = elm.scrollHeight + 'px'
	}
}
