import { Address, IFormAddressable } from '@app/models/address'
import { Global } from '@app/models/global'
import { JobSiteRecord } from '@app/models/jobsite'
import { DatabaseService } from '@app/services'
import { PhoneHelper } from '../phone'
import { log } from '../logger'

export class SiteTableFormatter {
	static makeLinkedNumbers(
		dbSrvc: DatabaseService,
		bridgeTableName: string,
		dtFilterText: string,
		site: JobSiteRecord,
		linkedOnly: boolean,
	): string {
		if (!site) {
			return 'Unknown'
		}
		if (!site.phone_number_regex_e164) {
			return 'Missing Number'
		}
		const phoneArray = site.phone_number_regex_e164.split(',').filter((num) => num !== '+18888888888')
		const numbers = phoneArray.map((num) => dbSrvc.empSrvc.getEmployeeNameForPhoneNumberE164(num))
		const jobSiteId = site.id
		const filterText = dtFilterText ? `${dtFilterText}` : ''

		const recordsTextE164 = phoneArray.length > 0 ? phoneArray.toString() : ''
		const recordsTextFormatted = phoneArray
			.map((pn) => PhoneHelper.formatPhoneFromE164(pn))
			.join(' ')
			.toString()

		const recordsText = `${recordsTextE164} / ${recordsTextFormatted}`
		const filterClass = filterText && recordsText.includes(filterText) ? ' table-modal-btn-match' : ''
		const recordsTextHtml = phoneArray.length > 0 ? `<div style="display:none;">${recordsText}</div>` : ''
		const linkLockIcon = linkedOnly ? '<i class="fas fa-link" style="margin-left:4px;"></i>' : ''

		return numbers.length > 0
			? `<button onclick="${bridgeTableName}.showLinkedNumbers(${jobSiteId},${linkedOnly})" class="btn btn-sm btn-default table-modal-btn${filterClass}" style="width: 120px">${
					numbers.length === 1 ? '1 number' : numbers.length + ' numbers'
				}${linkLockIcon}</button>${recordsTextHtml}`
			: ''
	}

	static makeShowMapIcon(site: JobSiteRecord, bridgeTableName: string) {
		if (!site.address_1) {
			return ''
		}
		return `<i class="fa fa-map link-text" style="margin-right:4px;" title="Show Map" onclick="${bridgeTableName}.gotoAddress(${site.id})"></i>`
	}

	static makeAddress(site: JobSiteRecord, bridgeTableName: string) {
		const address = site.address_formatted
		const isPending = site.requires_geo_coding
		const geoCodingFailed = site.geo_coding_failed

		if (isPending)
			return `
				<div>
					<span style="color:chocolate; font-weight:600">Update Pending</span>
					<i class="fa fa-refresh link-text" onclick="${bridgeTableName}.pendingAddressRefresh()"></i>
				</div>`

		if (!address) return ''

		const mapIcon = SiteTableFormatter.makeShowMapIcon(site, bridgeTableName)
		const markerHtml = site.geo_code_reverse ? `<i class="fa fa-map-marker" style="margin-left:4px; color:chocolate"></i>` : ``
		const formattedAddress = SiteTableFormatter.addressFormatter(address, mapIcon, markerHtml)

		return geoCodingFailed
			? `<div style="color:firebrick; cursor:default"><span><strong>No Geo Coding</strong></span></div>`
			: `<div>${formattedAddress}</div>`
	}

	static makeMapMarkerIcon(site: JobSiteRecord) {
		if (!site.address_1) {
			return ' '
		}

		const reverseMarkerHtml = site.geo_code_reverse ? `<i class="fa fa-map-marker" style="margin-left:4px; color:chocolate"></i>` : ``
		const addrString = `${site.address_1},${site.city},${site.district},${site.postal_code}`
		const url = site.geo_code_reverse
			? `https://maps.google.com/maps/place/${site.geo_latitude},${site.geo_longitude}`
			: `https://maps.google.com/?q=${addrString}`
		const iconBlock = `
			<div class="cell-map-block" style="margin-left: 0px">
				<a href="${url}" target="_address"><i class="fa fa-map link-text" title="Show Map"></i></a>
				${reverseMarkerHtml}
			</div>
		`

		return iconBlock
	}

	static addressFormatter(addr: string, mapIcon: string, markerHtml: string) {
		if (!addr) return ''
		const comps = (addr ?? '').split(',')
		const country = comps.pop() ?? ''
		const postCode = comps.pop() ?? ''
		const district = comps.pop() ?? ''
		const city = comps.pop() ?? ''

		// const extras = mapIcon || markerHtml ? `<span style="margin-left: 8px">${mapIcon}${markerHtml}</span>` : ''

		let result = ''
		result += '<div>'
		comps.forEach((line, index) => {
			// result += `<div>${line}${index === 0 ? extras : ''}</div>`
			result += `<div>${line}</div>`
		})

		result += `${city}, ${district}, ${postCode}`
		result += '<div>'
		return result
	}
}
