import { Injectable } from '@angular/core'

import {
	AdpDepartmentCodeRecord,
	AdpEarningsCodeRecord,
	AdpIvrPhoneNumberRecord,
	AdpIvrProfileRecord,
	AdpPayrollGroupCodeRecord,
	AdpRateCodeRecord,
	AdpRateCodeSelectItem,
	AdpSyncRecord,
	IntegrationLog,
} from '@app/models'
import { log } from '@app/helpers'

import { SelectItem } from 'primeng/api'
import _ from 'lodash'
import { Subject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class ADPService {
	private adpDepartmentCodes: Array<AdpDepartmentCodeRecord> = [] // adp_department_code
	private adpEarningsCodes: Array<AdpEarningsCodeRecord> = [] // adp_earnings_code
	private adpIvrPhoneNumbers: Array<AdpIvrPhoneNumberRecord> = [] // adp_ivr_phone_number
	private adpIvrProfiles: Array<AdpIvrProfileRecord> = [] // adp_ivr_profile
	private adpPayrollGroupCodes: Array<AdpPayrollGroupCodeRecord> = [] // adp_payroll_group_code
	private adpRateCodes: Array<AdpRateCodeRecord> = [] // adp_rate_code
	private adpSync: Array<AdpSyncRecord> = [] // adp_sync
	private adpLog: Array<IntegrationLog> = [] // adp_log

	public adpDepartmentCodeDataLoaded = false
	public adpEarningsCodeDataLoaded = false
	public adpIvrPhoneNumberDataLoaded = false
	public adpIvrProfileDataLoaded = false
	public adpRateCodeDataLoaded = false
	public adpPayrollGroupCodeDataLoaded = false
	public adpSyncDataLoaded = false
	public adpLogDataLoaded = false

	public adpIvrPhoneNumbersChange = new Subject<Array<AdpIvrPhoneNumberRecord>>()
	public adpIvrProfilesChange = new Subject<Array<AdpIvrProfileRecord>>()

	constructor() {
		log('Creating ADPService')
	}

	dataLoaded(): boolean {
		return (
			this.adpDepartmentCodeDataLoaded &&
			this.adpEarningsCodeDataLoaded &&
			this.adpPayrollGroupCodeDataLoaded &&
			this.adpRateCodeDataLoaded &&
			this.adpSyncDataLoaded
		)
	}

	isAdpIntegrated(): boolean {
		return !!this.getAdpSync()
	}

	isAdpWfnIntegrated(): boolean {
		const adpSync = this.getAdpSync()
		return adpSync ? adpSync.isWfnIntegrated() : false
	}

	clearData() {
		this.adpDepartmentCodes = []
		this.adpEarningsCodes = []
		this.adpRateCodes = []
		this.adpSync = []
		this.adpDepartmentCodeDataLoaded = false
		this.adpEarningsCodeDataLoaded = false
		this.adpPayrollGroupCodeDataLoaded = false
		this.adpRateCodeDataLoaded = false
		this.adpSyncDataLoaded = false
	}

	//////////////////////////
	// ADP DEPARTMENT CODES //
	//////////////////////////

	getAdpDepartmentCodes(): Array<AdpDepartmentCodeRecord> {
		return this.adpDepartmentCodes
	}

	getAdpDepartmentCodeForId(id: number) {
		return this.adpDepartmentCodes.find((dc) => dc.id === id)
	}

	setAdpDepartmentCodeRecords(records: Array<AdpDepartmentCodeRecord>) {
		this.adpDepartmentCodes = records.map((rec) => new AdpDepartmentCodeRecord(rec))
		this.adpDepartmentCodeDataLoaded = true
	}

	getAdpDepartmentCodesDropdown(idOnly?: boolean): Array<SelectItem> {
		const dropdown = this.getAdpDepartmentCodes().map((i) => ({
			label: i.shortName,
			value: idOnly ? i.id : i,
		}))
		dropdown.unshift({ label: 'Select department code', value: null })
		return dropdown
	}

	////////////////////////
	// ADP Earnings Codes //
	////////////////////////

	getAdpEarningsCodes(): Array<AdpEarningsCodeRecord> {
		return this.adpEarningsCodes
	}

	getAdpEarningsCodeForId(id: number) {
		return this.adpEarningsCodes.find((ec) => ec.id === id)
	}

	setAdpEarningsCodeRecords(records: Array<AdpEarningsCodeRecord>) {
		this.adpEarningsCodes = records.map((rec) => new AdpEarningsCodeRecord(rec))
		this.adpEarningsCodeDataLoaded = true
	}

	getAdpEarningsCodesDropdown(): Array<SelectItem> {
		const dropdown = this.getAdpEarningsCodes().map((i) => ({
			label: i.shortName,
			value: i,
		}))
		dropdown.unshift({ label: 'Select earnings code', value: null })
		return dropdown
	}

	///////////////////////////
	// ADP IVR PHONE NUMBERS //
	///////////////////////////

	getAdpIvrPhoneNumbers(): Array<AdpIvrPhoneNumberRecord> {
		return _.orderBy(this.adpIvrPhoneNumbers, ['phone_e164', 'description'], ['asc', 'asc'])
	}

	getAdpIvrPhoneNumberForId(id: number) {
		return this.adpIvrPhoneNumbers.find((rec) => rec.id === id)
	}

	setAdpIvrPhoneNumberRecords(records: Array<AdpIvrPhoneNumberRecord>) {
		this.adpIvrPhoneNumbers = records.map((rec) => new AdpIvrPhoneNumberRecord(rec))
		this.adpIvrPhoneNumberDataLoaded = true
	}

	addOrUpdateAdpIvrPhoneNumberRecords(records: Array<AdpIvrPhoneNumberRecord>) {
		const newRecords = records.map((rec) => new AdpIvrPhoneNumberRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.adpIvrPhoneNumbers.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.adpIvrPhoneNumbers.push(newRecord)
			}
		}
		this.adpIvrPhoneNumbersChange.next(this.adpIvrPhoneNumbers)
	}

	removeLocalAdpIvrPhoneNumberRecord(recordId: number) {
		this.adpIvrPhoneNumbers = this.adpIvrPhoneNumbers.filter((rec) => rec.id !== recordId)
	}

	//////////////////////
	// ADP IVR PROFILEs //
	//////////////////////

	getAdpIvrProfiles(): Array<AdpIvrProfileRecord> {
		return _.orderBy(this.adpIvrProfiles, ['description'], ['asc'])
	}

	getAdpIvrProfileForId(id: number) {
		return this.adpIvrProfiles.find((rec) => rec.id === id)
	}

	setAdpIvrProfileRecords(records: Array<AdpIvrProfileRecord>) {
		this.adpIvrProfiles = records.map((rec) => new AdpIvrProfileRecord(rec))
		this.adpIvrProfileDataLoaded = true
	}

	addOrUpdateAdpIvrProfileRecords(records: Array<AdpIvrProfileRecord>) {
		const newRecords = records.map((rec) => new AdpIvrProfileRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.adpIvrProfiles.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.adpIvrProfiles.push(newRecord)
			}
		}
		this.adpIvrProfilesChange.next(this.adpIvrProfiles)
	}

	removeLocalAdpIvrProfileRecord(recordId: number) {
		this.adpIvrProfiles = this.adpIvrProfiles.filter((rec) => rec.id !== recordId)
	}

	/////////////////////////////
	// ADP Payroll Group Codes //
	/////////////////////////////

	getAdpPayrollGroupCodes(): Array<AdpPayrollGroupCodeRecord> {
		return this.adpPayrollGroupCodes
	}

	getAdpPayrollGroupCodeForId(id: number) {
		return this.adpPayrollGroupCodes.find((pgc) => pgc.id === id)
	}

	setAdpPayrollGroupCodeRecords(records: Array<AdpPayrollGroupCodeRecord>) {
		this.adpPayrollGroupCodes = records.map((rec) => new AdpPayrollGroupCodeRecord(rec))
		this.adpPayrollGroupCodeDataLoaded = true
	}

	getAdpPayrollGroupCodesDropdown(): Array<SelectItem> {
		const dropdown = this.getAdpPayrollGroupCodes().map((i) => ({
			label: `${i.shortName} - ${i.codeValue}`,
			value: i.codeValue,
		}))
		dropdown.unshift({ label: 'Select payroll group code', value: null })
		return dropdown
	}

	/////////////////////////
	// ADP WFN GROUP CODES //
	/////////////////////////

	// getAdpWfnPayrollGroupCodes(): Array<AdpWfnGroupCode> {
	// 	return [
	// 		new AdpWfnGroupCode({ id: 1, description: 'ADP-WFN Group Code 1', code: 'CODE-1' }),
	// 		new AdpWfnGroupCode({ id: 2, description: 'ADP-WFN Group Code 2', code: 'CODE-2' }),
	// 		new AdpWfnGroupCode({ id: 3, description: 'ADP-WFN Group Code 3', code: 'CODE-3' }),
	// 	]
	// }
	// getAdpWfnPayrollGroupCodeDropdown(): Array<SelectItem> {
	// 	return this.getAdpWfnPayrollGroupCodes().map((gc) => {
	// 		return {
	// 			label: gc.description,
	// 			value: gc.code,
	// 			data: gc,
	// 		}
	// 	})
	// }

	////////////////////
	// ADP RATE CODEs //
	////////////////////

	getAdpWfnFrequencyDropdown(): Array<SelectItem> {
		return [
			{ label: 'Select Frequency', value: null },
			{ label: 'Weekly', value: 'WEEKLY' },
			{ label: 'Bi-Weekly', value: 'BIWEEKLY' },
			{ label: 'Monthly', value: 'MONTHLY' },
		]
	}

	getAdpRateCodes(): Array<AdpRateCodeRecord> {
		return this.adpRateCodes
	}

	getAdpRateCodeForId(id: number) {
		return this.adpRateCodes.find((rc) => rc.id === id)
	}

	setAdpRateCodeRecords(records: Array<Object>) {
		this.adpRateCodes = records.map((rec) => new AdpRateCodeRecord(rec))
		this.adpRateCodeDataLoaded = true
	}

	getAdpRateCodeDropdown(addNullOption: boolean): Array<AdpRateCodeSelectItem> {
		const dropdown = this.getAdpRateCodes().map((rc) => ({
			label: rc.shortName,
			value: rc.id,
			data: rc,
		}))
		if (addNullOption) dropdown.unshift({ label: 'Select rate code', value: null, data: new AdpRateCodeRecord() })
		return dropdown
	}

	/////////////////////
	// ADP SYNC RECORD //
	/////////////////////

	getAdpSync(): AdpSyncRecord {
		return this.adpSync[0] || null
	}

	setAdpSyncRecords(records: Array<Object>) {
		this.adpSync = records.map((rec) => new AdpSyncRecord(rec))
		this.adpSyncDataLoaded = true
	}

	getAdpLog(): Array<IntegrationLog> {
		return this.adpLog
	}

	setAdpLogRecords(records: Array<Object>) {
		this.adpLog = records.map((rec) => new IntegrationLog(rec))
		this.adpLogDataLoaded = true
	}

	/////////////////////
	// GENERAL METHODS //
	/////////////////////

	logRecords() {
		log('Department Codes', this.adpDepartmentCodeDataLoaded, this.adpDepartmentCodes)
		log('Earnings Codes', this.adpEarningsCodeDataLoaded, this.adpEarningsCodes)
		log('Rate Codes', this.adpRateCodeDataLoaded, this.adpRateCodes)
		log('Sync Records', this.adpSyncDataLoaded, this.adpSync)
		log('Log Records', this.adpLogDataLoaded, this.adpLog)
	}
}
