<div *ngIf="isPaused" class="alert alert-info alert-bg-gray pause-alert">
	<div *ngIf="!resumeDate">Billing is currently paused</div>
	<div *ngIf="resumeDate">Billing paused until {{ resumeDate | dateFormatter: 'ddd. MMM. Do, YYYY' }}</div>
</div>

<div class="card card-body">
	<div>
		<div class="float-right" (click)="manageBillingBtnClicked()">
			<span class="btn btn-sm btn-success">
				<div class="float-left"><i *ngIf="isFetchingLink" class="fas fa-loader fa-spin" style="margin-right: 6px"></i></div>
				<span>{{ isFetchingLink ? 'Redirecting' : 'Manage in Stripe' }}</span>
			</span>
		</div>
		<div class="payment-info-label"><strong>Payment Information</strong></div>
		<div class="payment-info-note">Manage payment methods and invoices via your Stripe billing dashboard.</div>
	</div>

	<div *ngIf="stripeCustomerInfo">
		<hr />

		<div *ngIf="stripeCustomerInfo.paymentMethods.length === 0">
			<strong style="color: chocolate">No payment method has been configured</strong>
		</div>

		<div *ngIf="isTrial" class="trial-note">
			<b>Note:</b> Your first month is free, even if you enter your card information now. Once the 30-day free trial is over, you will enter a
			regular monthly billing cycle, but we don't charge your card until the end of the billing cycle. Effectively giving you 60 days of use,
			before you see your first charge.
			<hr *ngIf="stripeCustomerInfo.paymentMethods.length > 0" />
		</div>

		<div *ngIf="stripeCustomerInfo.paymentMethods.length > 0">
			<div *ngFor="let payment of stripeCustomerInfo.paymentMethods; let idx = index" style="margin-top: 8px; font-weight: 500">
				<!-- Add a divider if it's not the first card -->
				<hr *ngIf="idx !== 0" />

				<!-- Handle non-supported payment methods with a note -->
				<div *ngIf="!payment.card && !payment.us_bank_account && !payment.acss_debit">
					<strong style="color: slategray">Unsupported payment method configured</strong>
				</div>

				<!-- Handle card payment method -->
				<div
					*ngIf="payment.card"
					[class.tts-hl-orange]="stripeCustomerInfo.isDefaultPaymentMethod(payment.id) && cardExpirationDayCount > 0"
					[class.tts-hl-firebrick]="
						stripeCustomerInfo.isDefaultPaymentMethod(payment.id) && (cardExpirationDayCount < 0 || cardExpirationDayCount === 0)
					"
				>
					<div class="d-flex justify-content-between">
						<div>
							<div>{{ formatCardName(payment.card.brand) }}</div>
							<div style="color: black">•••• •••• •••• {{ payment.card.last4 }}</div>
							<div *ngIf="payment.card.wallet?.type">{{ formatWalletType(payment) }}</div>
							<div *ngIf="stripeCustomerInfo.isDefaultPaymentMethod(payment.id)">
								<div *ngIf="cardExpirationDayCount > 0">Card expires in {{ cardExpirationDayCount }} days</div>
								<div *ngIf="cardExpirationDayCount === 0">Card expires today</div>
								<div *ngIf="cardExpirationDayCount < 0">Card has expired</div>
							</div>
						</div>
						<div style="text-align: right">
							<div>Exp: {{ payment.card.exp_month }}/{{ payment.card.exp_year }}</div>
							<div *ngIf="stripeCustomerInfo.isDefaultPaymentMethod(payment.id)" class="default-tag">default</div>
						</div>
					</div>
				</div>

				<!-- Handle Pre-authorized bank debit payment method -->
				<div *ngIf="payment.acss_debit">
					<div class="d-flex justify-content-between">
						<div>
							<div>Pre-Auth Debit</div>
							<div style="color: black">•••• •••• •••• {{ payment.acss_debit.last4 }}</div>
						</div>
						<div style="text-align: right">
							<div>{{ payment.acss_debit.institution_number }} / {{ payment.acss_debit.transit_number }}</div>
							<div *ngIf="stripeCustomerInfo.isDefaultPaymentMethod(payment.id)" class="default-tag">default</div>
						</div>
					</div>
					<div>{{ formatBankName(payment.acss_debit.bank_name) }}</div>
				</div>

				<!-- Handle US bank account payment method -->
				<div *ngIf="payment.us_bank_account">
					<div class="d-flex justify-content-between">
						<div>
							<div>Bank Account</div>
							<div style="color: black">•••• •••• •••• {{ payment.us_bank_account.last4 }}</div>
						</div>
						<div style="text-align: right; font-variant: small-caps">
							<div>{{ payment.us_bank_account.account_type }}</div>
							<div *ngIf="stripeCustomerInfo.isDefaultPaymentMethod(payment.id)" class="default-tag">default</div>
						</div>
					</div>
					<div>{{ formatBankName(payment.us_bank_account.bank_name) }}</div>
				</div>
			</div>
		</div>

		<!-- <hr />
		<div class="pre-code">{{ stripeCustomerInfo.paymentMethods | json }}</div> -->
	</div>
</div>
