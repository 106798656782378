import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'
import { log } from '@app/helpers/logger'
import { CoreService, UserLoginService } from '@app/services'
import { environment } from '@env/environment'

import S3 from 'aws-sdk/clients/s3'

class FileUploaderProgress {
	bytesSent = 0
	bytesTotal = 100
	constructor(sent: number, total: number) {
		this.bytesSent = sent
		this.bytesTotal = total
	}
}

@Component({
    selector: 'app-file-import-uploader',
    templateUrl: './file-import-uploader.component.html',
    styleUrls: ['./file-import-uploader.component.scss'],
    standalone: false
})
export class FileImportUploaderComponent implements OnInit {
	companyId = 0
	fiBucket = environment.fileImportBucket
	status = { isUploading: false, isProcessing: false, percentComplete: 0 }

	@Output() uploadComplete = new EventEmitter<boolean>()

	@ViewChild('fileImportUploader', { static: true }) fileImportUploader

	inputFiles: Array<File> = []
	processedFiles: Array<File> = []
	invalidFiles: Array<File> = []

	constructor(
		private coreSrvc: CoreService,
		private loginSrvc: UserLoginService,
	) {
		this.companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany()?.id || null
	}

	ngOnInit(): void {}

	uploadHandler(event) {
		log('uploadHandler', event)
		this.resetQueue()
		if (this.status.isUploading) {
			return
		}
		const files = event.files
		this.inputFiles = []
		for (const file of files) {
			this.inputFiles.push(file)
		}
		this.fileImportUploader.clear()
		this.loginSrvc.refresh().then((success) => {
			this.startProcessing()
		})
	}

	startProcessing() {
		log('Input Files', this.inputFiles)
		const nextFile = this.inputFiles.shift()
		if (nextFile) {
			if (this.validateFile(nextFile)) {
				log('Uploading next file', nextFile)
				this.uploadFile(nextFile).then((success) => {
					if (success) {
						this.processedFiles.push(nextFile)
						this.startProcessing()
					} else {
						log('Encountered error during upload of file', nextFile)
						this.resetQueue()
					}
				})
			} else {
				log('Invalid file found', nextFile)
				this.invalidFiles.push(nextFile)
				this.startProcessing()
			}
		} else {
			log('Finished uploading files')
			this.finishedProcessing()
		}
	}

	finishedProcessing() {
		this.uploadComplete.emit(true)
		if (this.processedFiles.length > 0) {
			this.coreSrvc.notifySrvc.notify(
				'success',
				'Upload Finished',
				'Your files are now being processed and you will receive an email shortly with more information.',
			)
		} else {
			this.coreSrvc.notifySrvc.notify(
				'warn',
				'No Valid Files',
				'No valid files were found for upload. Please check naming requirements and try again.',
			)
		}
		this.status.isProcessing = false
		this.status.isUploading = false
	}

	resetQueue() {
		this.status.isProcessing = false
		this.status.isUploading = false
		this.inputFiles = []
		this.invalidFiles = []
		this.processedFiles = []
	}

	validateFile(file: File) {
		if (
			!(
				file.name.includes('employee') ||
				file.name.includes('job') ||
				file.name.includes('supervisor') ||
				file.name.includes('vacation') ||
				file.name.includes('final')
			)
		) {
			return false
		}
		return true
	}

	uploadFile(file: File): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this.status.percentComplete = 0
			this.status.isProcessing = false
			this.status.isUploading = true

			const params: any = {
				Metadata: {
					company_id: `${this.companyId}`,
				},
				Bucket: `${this.fiBucket}`,
				Key: `${this.companyId}/${file.name}`,
				ContentType: file.type,
				Body: file,
			}

			log('Upload params', params)

			const bucket = new S3()
			bucket
				.upload(params, (err, data) => {
					// this.isUploading = false
					if (err) {
						log('Upload Error', err)
						resolve(false)
					} else {
						resolve(true)
					}
				})
				.on('httpUploadProgress', (evt) => {
					const progress = new FileUploaderProgress(evt.loaded, evt.total)
					this.updatePercentComplete(progress)
				})
		})
	}

	updatePercentComplete(progress: FileUploaderProgress) {
		const result = (progress.bytesSent / progress.bytesTotal) * 100
		this.status.percentComplete = Math.floor(result)
	}
}
