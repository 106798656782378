<p-dialog
	#clickToCallGlobalEventDialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Click To Call"
	[(visible)]="viewState.showDialog"
	[modal]="true"
	[showHeader]="false"
	[dismissableMask]="false"
	[focusOnShow]="false"
	transitionOptions="0ms"
>
	<div style="min-width: 300px">
		<div *ngIf="isViewStateEmpty" style="color: rgb(101, 98, 98)">Initializing ...</div>
		<!-- Text message composition -->
		<div *ngIf="viewState.prepareText">
			<app-click-to-call-message
				[c2cRecord]="c2cRecord"
				[smsPlaceholder]="viewState.smsPlaceholder"
				[textMessage]="viewState.initialMsg"
				(cancelMessage)="cancelTextMsg()"
				(sendMessage)="sendTextMsg($event)"
			></app-click-to-call-message>
		</div>

		<div *ngIf="viewState.placeCallConfirm">
			<div class="float-right">
				<button class="btn btn-sm btn-secondary" (click)="cancelPlaceCall()" style="margin-right: 8px">Cancel</button>
				<button class="btn btn-sm btn-success" (click)="placeCallConfirmed()">Confirm</button>
			</div>
			<div class="confirm-note">Place Call?</div>

			<div class="d-flex justify-content-between align-items-center" style="margin-top: 8px">
				<div class="confirm-name">{{ currentConfig?.c2cRecord?.description }}</div>
				<div class="confirm-phone">{{ currentConfig?.c2cRecord?.phoneE164 | displayPhone }}</div>
			</div>
		</div>

		<div *ngIf="viewState.viewLog" class="lv-wrapper">
			<div *ngIf="viewState.isLogLoaded">
				<div class="lv-header">
					<div class="float-right">
						<i class="close-ico fa-regular fa-circle-xmark has-pointer" (click)="viewLogDone()"></i>
					</div>
					<div>
						<div class="tts-hl-2">Comm Log</div>
						<div *ngIf="viewState.isLogLoaded" class="lv-sub-header">{{ currentConfig?.c2cRecord?.description }}</div>
					</div>
				</div>
			</div>

			<div *ngIf="viewState.isLogLoaded">
				<div #scrollContainer class="lv-content hide-scrollbars">
					<div *ngIf="logList.length > 0">
						<div *ngFor="let item of logList">
							<app-comm-log-card
								[card]="item"
								[timezone]="timezone"
								(placeCall)="dispatchC2CEventFromLog('CALL', $event)"
								(startTextMsg)="dispatchC2CEventFromLog('TEXT', $event)"
							></app-comm-log-card>
						</div>
					</div>
				</div>

				<div *ngIf="logList.length === 0" class="lv-no-events lv-no-events-border">No Comm Log Events</div>

				<div style="margin: 12px 0px 0px 0px">
					<div class="float-right link-text no-select" (click)="loadMoreLogs()">load more</div>
					<div style="font-weight: 600">Last {{ lastNDaysCount }} days</div>
				</div>
			</div>

			<div *ngIf="!viewState.isLogLoaded" class="lv-no-events" style="margin: 0px 0px"><i class="fas fa-loader fa-spin"></i> Loading...</div>
		</div>

		<!-- Status Messages -->
		<div *ngIf="viewState.sendMessage || viewState.placeCall" class="ctc-status">
			<div *ngIf="viewState.sendMessage"><i class="fas fa-loader fa-spin c2c-activity-icon"></i> Sending Message...</div>
			<div *ngIf="viewState.placeCall"><i class="fas fa-loader fa-spin c2c-activity-icon"></i> Connecting Call...</div>
		</div>
	</div>
</p-dialog>
