<div class="container">
	<div class="maint-wrap">
		<div class="maint-header">Connection Offline</div>
		<div *ngIf="!useCustomBlock" class="maint-msg">{{ lastErrorMsg }}</div>
		<div style="margin-top: 50px">
			<button class="btn btn-success" (click)="reloadApp()">Check Status</button>
		</div>

		<div *ngIf="useCustomBlock" class="maint-update-block">
			<div class="maint-update-date">2023-06-13 &#64; 3:11 PM CDT</div>
			<span class="maint-update-info"
				>We are currently experiencing technical difficulties due to an ongoing outage with Amazon Web Services (AWS). Our services are affected
				as a result.<br /><br />

				We are actively working with AWS, who have identified the issue and are actively working to resolve it. We apologize for any inconvenience
				this may cause and assure you that we are doing everything possible to restore normal operations as quickly as possible.<br /><br />

				We appreciate your patience and understanding during this time. We will provide further updates as soon as more information becomes
				available.</span
			>
		</div>
	</div>

	<div *ngIf="callInList.length > 0">
		<div style="text-align: center; font-weight: 600; font-size: 1.2rem; margin-bottom: 25px">IVR Call-In Numbers</div>
		<div [class.columns-2]="callInList.length === 2" [class.columns-3]="callInList.length >= 3">
			<div *ngFor="let item of callInList">
				<div class="num-block">
					<div>{{ item.companyName }}</div>
					<div>{{ formatPhoneNumber(item.ivrNumber) }}</div>
				</div>
			</div>
		</div>
	</div>
</div>
