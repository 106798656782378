import { DateTimeHelper } from '@app/helpers/datetime'
import { log } from '@app/helpers/logger'

export class AdpRateCodeSelectItem {
	label: string
	value: number
	data: AdpRateCodeRecord
}

export class AdpDepartmentCodeRecord {
	id: number
	company_id: number
	codeValue: string
	shortName: string
	created: string

	constructor(record: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
		this.created = DateTimeHelper.stripUtcTag(this.created)
	}
}

export class AdpEarningsCodeRecord {
	id: number
	company_id: number
	codeValue: string
	shortName: string
	created: string

	constructor(record: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
		this.created = DateTimeHelper.stripUtcTag(this.created)
	}
}

export class AdpPayrollGroupCodeRecord {
	id: number
	company_id: number
	codeValue: string
	shortName: string
	created: string

	constructor(record: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
		this.created = DateTimeHelper.stripUtcTag(this.created)
	}
}

export class AdpRateCodeRecord {
	id: number = null
	company_id: number = null
	codeValue: string = null
	shortName: string = null
	created: string = null

	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
		}
	}
}

export class AdpPayCycle {
	code: string // "BIWEEKLY"
	codeValue: string // "B"
	openCalendarCheckDate: string // "2021-01-26"
	openCalendarEndDate: string // "2021-01-24"
	openCalendarStartDate: string // "2021-01-11"
	shortName: string // "Biweekly"

	constructor(record: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
	}
}

export class AdpSyncRecord {
	id: number
	company_id: number
	employee_sync: string // date string
	transaction_log_sync: string // date string
	transaction_log_lock: string // date string
	created: string // date string
	employee_sync_errors: number
	transaction_log_sync_errors = 0
	updated: string // date string
	o2_client_id: string
	o2_client_secret: string
	organization_oid: string
	payroll_reg: number
	payroll_ot: number
	payroll_hol: number
	payroll_othol: number

	payroll_period: string
	payroll_start_date: string
	payroll_group_code: string

	pto_personal: number
	pto_sick: number
	pto_holiday: number
	pto_bereavement: number

	product: string = ''

	emp_sync_delay: string = 'PT8H'

	constructor(record?: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
		this.employee_sync = DateTimeHelper.stripUtcTag(this.employee_sync)
		this.transaction_log_sync = DateTimeHelper.stripUtcTag(this.transaction_log_sync)
		this.transaction_log_lock = DateTimeHelper.stripUtcTag(this.transaction_log_lock)
		this.created = DateTimeHelper.stripUtcTag(this.created)
		this.updated = DateTimeHelper.stripUtcTag(this.updated)
	}

	isWfnIntegrated(): boolean {
		return this.product && this.product.startsWith('WFN') ? true : false
	}
}

export class AdpWfnGroupCode {
	id: number
	description: string
	code: string
	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
		}
	}
}

export class AdpIvrPhoneNumberRecord {
	id: number
	company_id: number
	external_id: string
	description: string
	created: string
	updated: string
	phone_e164: string

	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
		}
	}
}

export interface IAdpLaborLevelTransferListItem {
	name: string
	profileId: number
	promptOrder: number
}

export class AdpIvrProfileRecord {
	id: number
	company_id: number
	external_id: string
	description: string
	created: string
	updated: string
	allow_clocking: boolean
	allow_multiple_clocking: boolean
	allow_labor_level_transfers: boolean
	allow_work_rule_transfers: boolean
	allow_pay_code_transactions: boolean
	enable_call_tracking: boolean
	enable_phone_restrictions: boolean
	labor_level_transfer_list: Array<IAdpLaborLevelTransferListItem> = []
	work_rule_transfer_list: Array<any> = []
	pay_code_transaction_list: Array<any> = []

	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
		}
	}
}
