import { Injectable, EventEmitter } from '@angular/core'
import {
	CompanyActivityLogRecord,
	DashboardRecord,
	VizDashboard,
	VizDashboardDataSourceRow,
	VisualizationViewManager,
	VisualizationViewTabState,
	VizHealthCenterManager,
	DataAccessRequest,
	Global,
} from '@app/models'

import { log } from '@app/helpers'
import { Subject } from 'rxjs'

import _ from 'lodash'
import moment from 'moment-timezone'

@Injectable({
	providedIn: 'root',
})
export class VisualizationService {
	companyActivityLogListDataLoaded = false
	companyActivityLogList: Array<CompanyActivityLogRecord> = []
	companyActivityLogListChange = new EventEmitter<Array<CompanyActivityLogRecord>>()
	companyActivityLogListLastUpdated: Date

	dashboardListDataLoaded = false
	dashboardList: Array<DashboardRecord> = []
	dashboardListChange = new EventEmitter<Array<DashboardRecord>>()
	dashboardListLastUpdated: Date

	dashboardsNeedUpdate = new Subject<boolean>() // Event to trigger update on all dashboards

	currentDashboardIndex = 0
	dashboards: Array<VizDashboard> = []
	modifiedDashboards: Array<VizDashboard> = []

	viewManager = new VisualizationViewManager() // Restored in CoreService init
	tabStateDidChange = new Subject<VisualizationViewTabState>()

	healthCenterManager = new VizHealthCenterManager()

	constructor() {
		log('Creating VisualizationService')
	}

	get currentDashboard(): VizDashboard {
		return this.dashboards[this.currentDashboardIndex]
	}
	get currentDashboardData(): Array<VizDashboardDataSourceRow> {
		return this.currentDashboard.data
	}
	get hasCurrentDashboardBeenModified(): boolean {
		return this.modifiedDashboards.includes(this.currentDashboard)
	}

	clearData() {
		this.clearCompanyActivityLogData()
		this.clearDashboardData()
	}

	////////////////
	// NAVIGATION //
	////////////////

	public setCurrentTab(tab: VisualizationViewTabState) {
		this.viewManager.currentView = tab
		this.tabStateDidChange.next(this.viewManager.currentView)
	}

	///////////////////////////
	// HEALTH CENTER METHODS //
	///////////////////////////

	fetchHealthCenterData(): Promise<boolean> {
		return new Promise((resolve) => {
			const hcDates = {
				start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
				end_date: moment().format('YYYY-MM-DD'),
			}
			const request = new DataAccessRequest('company_activity_log', null, hcDates)
			Global.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
				const data = result.data ?? []
				const records = data.map((rec) => new CompanyActivityLogRecord(rec))
				this.healthCenterManager.healthCenterActivityLogList = records
				resolve(true)
			})
		})
	}

	//////////////////////////////////
	// COMPANY ACTIVITY LOG METHODS //
	//////////////////////////////////

	clearCompanyActivityLogData() {
		this.companyActivityLogList = []
		this.companyActivityLogListDataLoaded = false
		this.companyActivityLogListLastUpdated = null
	}

	getHealthCenterActivityLogRecords(): Array<CompanyActivityLogRecord> {
		return this.healthCenterManager.healthCenterActivityLogList
	}

	getCompanyActivityLogRecords(): Array<CompanyActivityLogRecord> {
		return this.companyActivityLogList
	}

	getCompanyActivityLogRecordById(id: number): CompanyActivityLogRecord {
		return this.companyActivityLogList.find((rec) => rec.id === id)
	}

	removeLocalCompanyActivityLogRecord(recordId: number) {
		this.companyActivityLogList = this.companyActivityLogList.filter((rec) => rec.id !== recordId)
	}

	setCompanyActivityLogRecords(records: Array<CompanyActivityLogRecord>) {
		this.companyActivityLogListLastUpdated = new Date()
		this.companyActivityLogList = records.map((rec) => new CompanyActivityLogRecord(rec))
		this.companyActivityLogListDataLoaded = true
		this.companyActivityLogListChange.next(this.companyActivityLogList)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateCompanyActivityLogRecords(records: Array<CompanyActivityLogRecord>) {
		const newRecords = records.map((rec) => new CompanyActivityLogRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.companyActivityLogList.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.companyActivityLogList.push(newRecord)
			}
		}
		this.companyActivityLogListLastUpdated = new Date()
		this.companyActivityLogListChange.next(this.companyActivityLogList)
	}

	///////////////////////
	// DASHBOARD RECORDS //
	///////////////////////

	clearDashboardData() {
		this.dashboardList = []
		this.dashboardListDataLoaded = false
		this.dashboardListLastUpdated = null
	}

	getDashboardRecords(userId?: number): Array<DashboardRecord> {
		const list = this.dashboardList
		if (userId) return list.filter((rec) => rec.creator_user_id === userId || rec.supervisor_ids.includes(userId) || rec.supervisor_ids.includes(0))
		return list
	}

	getDashboardRecordById(id: number): DashboardRecord {
		return this.dashboardList.find((rec) => rec.id === id)
	}

	getMyLastCreatedRecord(): DashboardRecord {
		const myUserId = Global.coreSrvc.dbSrvc.settingSrvc.getMyActualUser().id
		const sorted = _.orderBy(this.dashboardList, ['created'], ['asc']).filter((rec) => rec.creator_user_id == myUserId)
		return sorted.pop()
	}

	removeLocalDashboardRecord(recordId: number) {
		this.dashboardList = this.dashboardList.filter((rec) => rec.id !== recordId)
	}

	setCurrentDashboardIndexToDashboardId(id: number) {
		const dashboardForId = this.dashboards.find((db) => db.record?.id === id)
		if (dashboardForId) {
			this.currentDashboardIndex = this.dashboards.findIndex((db) => db.record?.id === id) ?? 0
		} else {
			// Clear out current dasboard index and ID if dashboard not available
			this.currentDashboardIndex = 0
			this.viewManager.currentDashboardId = null
		}
	}

	saveCurrentDashboardIdAndIndex() {
		this.currentDashboardIndex = this.dashboards.indexOf(this.currentDashboard)
		this.viewManager.currentDashboardId = this.currentDashboard.record?.id ?? null
		this.viewManager.save()
	}

	setDashboardRecords(records: Array<DashboardRecord>) {
		this.dashboardListLastUpdated = new Date()
		this.dashboardList = records.map((rec) => new DashboardRecord(rec))
		this.dashboardListDataLoaded = true
		this.dashboardListChange.next(this.dashboardList)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateDashboardRecords(records: Array<DashboardRecord>) {
		const newRecords = records.map((rec) => new DashboardRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.dashboardList.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.dashboardList.push(newRecord)
			}
		}
		this.dashboardListLastUpdated = new Date()
		this.dashboardListChange.next(this.dashboardList)
	}

	defaultDashboardHasBeenAdded = false
	loadDashboardData() {
		const myUserId = Global.coreSrvc.dbSrvc.settingSrvc.getMyUser()?.id
		const records = this.getDashboardRecords(myUserId)
		if (records.length > 0) {
			this.dashboards = records.map((rec) => new VizDashboard(rec))
		} else {
			// We don't have any dashboard records so setup the default
			if (!this.defaultDashboardHasBeenAdded) this.addNewDashboard('Default Dashboard')
			this.defaultDashboardHasBeenAdded = true
		}

		// Setup the last viewed dashboard if it's still available
		this.setCurrentDashboardIndexToDashboardId(this.viewManager.currentDashboardId)
	}

	addNewDashboard(name: string) {
		const myUserId = Global.coreSrvc.dbSrvc.settingSrvc.getMyUser()?.id
		const newDashboardRecord = new DashboardRecord({ name: name, supervisor: myUserId })
		const newDashboard = new VizDashboard()
		newDashboard.setupDefaultDisplayData()

		// Add setup the default data_json
		newDashboardRecord.data_json = newDashboard.buildUpdateRecord().data_json
		newDashboard.name = name
		newDashboard.record = newDashboardRecord

		// Add it to the dashboard list
		this.dashboards.push(newDashboard)
		this.currentDashboardIndex = this.dashboards.length - 1
	}

	markCurrentDashboardAsModified() {
		// Add to modifiy list if not aleady present
		if (!this.modifiedDashboards.includes(this.currentDashboard)) {
			this.modifiedDashboards.push(this.currentDashboard)
		}
	}

	hasDashboardBeenModified(dashboard: VizDashboard): boolean {
		return this.modifiedDashboards.includes(dashboard)
	}

	clearCurrentDashboardModificationStatus() {
		this.modifiedDashboards = this.modifiedDashboards.filter((dashboard) => dashboard !== this.currentDashboard)
	}
}
