import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { FormHelper, log } from '@app/helpers'
import { DialogManager, HelpDialogMessage, UserGroupRecord, UserRecord } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { SelectItem } from 'primeng/api'

@Component({
    selector: 'app-user-group-edit',
    templateUrl: './group-edit.component.html',
    styleUrls: ['./group-edit.component.scss'],
    standalone: false
})
export class UserGroupEditComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
	group: UserGroupRecord
	isNew = false
	isUpdating = false
	groupForm: UntypedFormGroup

	supervisorOptions: Array<SelectItem> = []

	@Input() dialogManager = new DialogManager()
	@Input() recordId: number
	@Input() action = 'edit'

	@Output() recordUpdated = new EventEmitter<UserGroupRecord>()

	constructor(
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
	) {}

	ngOnInit() {
		if (this.action === 'new') {
			log('Creating a new group')
			this.isNew = true
			this.group = new UserGroupRecord()
		} else {
			this.isNew = false
			this.group = this.coreSrvc.dbSrvc.settingSrvc.getUserGroupById(this.recordId)
		}

		log('Init Group Edit', this.group)

		this.setupForm()
		this.setupSupervisorsDropdown()
	}

	ngAfterViewInit() {}

	ngAfterContentInit() {
		this.setupDialogManager()
	}

	ngOnDestroy() {}

	viwFormErrors() {
		FormHelper.getFormValidationErrors(this.groupForm)
	}

	isFormValid(): boolean {
		const isFormValid = this.groupForm.valid
		return isFormValid // && isEmailValid && isPhoneValid
	}

	setupForm() {
		const group = this.group
		this.groupForm = this.fb.group({
			id: [group.id],
			description: [group.description, Validators.required],
			external_id: [group.external_id],
			details: [group.details],
			supervisor_group_users: [group.supervisor_group_users],
		})
	}

	setupDialogManager() {
		this.dialogManager.canSubmit = () => this.isFormValid()
		this.dialogManager.submitBtnAction = () => this.submit()
	}

	setupSupervisorsDropdown() {
		this.supervisorOptions = this.coreSrvc.dbSrvc.settingSrvc.getUsersDropdownData()
		log('Supervisor Options', this.supervisorOptions)
	}

	submit() {
		// Guard against double submission
		if (this.isUpdating) return
		FormHelper.trimOnlyWhitespace(this.groupForm)

		const record = this.makeUpdateRecord()
		log('Record to submit', record)
		if (record) {
			if (this.isNew) {
				log('Adding new organization', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.insertRecord('supervisor_group', record).then((success) => {
					if (success) {
						this.recordUpdated.emit(this.group)
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			} else {
				log('Updating record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.updateRecord('supervisor_group', record).then((success) => {
					if (success) {
						this.recordUpdated.emit(this.group)
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			}
		}
	}

	makeUpdateRecord(): Object {
		const form = this.groupForm.value
		const record = new UserGroupRecord(form)
		return record
	}

	// Misc Methods

	toggleCheckbox(prop: string) {
		log('prop', prop)
		const current = this.groupForm.get(prop).value
		this.groupForm.get(prop).setValue(!current)
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
