<div *ngIf="isAvailable && eventInfo" class="block-wrapper detach-details">
	<!-- Render a time off record -->
	<div *ngIf="isTimeOffEvent" class="cal-event-wrap">
		<div [class]="classList" [style]="style">
			<div>
				<div class="float-right">Scheduled Anytime</div>
				<div>{{ timeOffDate }}</div>
			</div>
			<div>{{ eventTitle }}</div>
		</div>
	</div>

	<!-- Render a shift event with a schedule log record -->
	<div *ngIf="hasSchedLog" class="cal-event-wrap">
		<div [class]="classList" [style]="style">
			<div class="float-right">
				<div *ngIf="!schedLog.anytime">{{ startTime }} - {{ endTime }} {{ tzAbrev }}</div>
				<div *ngIf="schedLog.anytime">Scheduled Anytime</div>
			</div>
			<div>{{ jobDate }}</div>
			<div *ngIf="!hasEmployeeOverride" class="trunc-ellipsis">{{ originalEmployeeName }}</div>
			<div *ngIf="hasEmployeeOverride">
				<div class="float-right">Filling in for {{ originalEmployeeName }}</div>
				<div class="trunc-ellipsis">{{ overrideEmployeeName }}</div>
			</div>
			<div class="trunc-ellipsis">{{ jobName }}</div>
			<div class="status-bar">
				<div
					class="table-tag"
					[class.table-tag-enabled]="isShiftEnabled"
					[class.table-tag-disabled]="!isShiftEnabled"
					[class.status-clear]="isShiftEnabled"
				>
					{{ shiftStatusEnabledText }}
				</div>
				<div
					*ngIf="isShiftEnabled"
					class="table-tag"
					[class.table-tag-enabled]="isShiftEnabled"
					[class.status-clear]="areNotificationsEnabled"
					[class.status-flag]="!areNotificationsEnabled"
				>
					{{ notificationsEnabledText }}
				</div>
				<div
					*ngIf="isShiftEnabled"
					class="table-tag"
					[class.table-tag-enabled]="isShiftEnabled"
					[class.status-clear]="isShiftFilled"
					[class.status-flag]="!isShiftFilled"
				>
					{{ shiftOpenText }}
				</div>
			</div>
		</div>

		<!-- <div>
			<hr />
			<code>
				<pre>{{ eventInfo | json }}</pre>
			</code>
		</div> -->
	</div>
</div>
