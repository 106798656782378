import { Component, Input, Renderer2 } from '@angular/core'
import { BreakRuleCard, Global, HelpDialogMessage } from '@app/models'
import { CoreService } from '@app/services'
import { SelectItem } from 'primeng/api'

import moment from 'moment-timezone'
import { log } from '@app/helpers'

@Component({
    selector: 'app-break-rule-edit',
    templateUrl: './break-rule-edit.component.html',
    styleUrl: './break-rule-edit.component.scss',
    standalone: false
})
export class BreakRuleEditComponent {
	@Input() card: BreakRuleCard

	showOptionCycleBtn = false

	constructor(
		private coreSrvc: CoreService,
		private renderer: Renderer2,
	) {
		this.showOptionCycleBtn = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs().breakEnableAdvancedReminders
	}

	payrollTypeOptions: Array<SelectItem> = [
		{ label: 'Paid Break', value: 'PAID' },
		{ label: 'Unpaid Break', value: 'UNPAID' },
	]

	public selectOnFocus(event: any): void {
		// 'event.target' refers to the DOM element that triggered the event
		const input = $(event.target)
		input.trigger('select')

		const id = event.target?.id
		$(`#${id}`).removeClass('input-error')
	}

	public validateNumericInput(propName: string) {
		const value = this.card.rule[propName]
		const intValue = parseInt(value, 10)

		switch (propName) {
			case 'rule_prop':
				const minRuleValue = 10
				if (intValue && intValue < minRuleValue) {
					this.coreSrvc.notifySrvc.notify('warn', 'Invalid Value', `The minimum value for this field is ${minRuleValue} minutes.`, 3)
					this.card.rule[propName] = minRuleValue
				}
				return
			default:
				if (intValue) {
					this.card.rule[propName] = intValue
				} else {
					this.card.rule[propName] = null
				}
		}
	}

	validateDurationHrsAndMinutes(propName: string) {
		const value = this.card.rule[propName]
		let adjustedValue = value || ''
		if (!`${adjustedValue}`.includes(':')) {
			// Handle whether a single value is interpreted as minutes or hours by where the 00 is placed
			switch (propName) {
				default:
					adjustedValue = adjustedValue + ':00'
					break
			}
			this.card.rule[propName] = adjustedValue
		}

		const dur = moment.duration(adjustedValue)
		if (!dur.isValid() || dur.asSeconds() === 0) {
			this.card.rule[propName] = null
			this.flagForError(propName)
		}
	}

	public flagForError(prop: string) {
		$(`#${prop}`).addClass('input-error')
	}

	public toggleCheckbox(propName: string) {
		this.card.rule[propName] = !this.card.rule[propName]
		this.validateCheckboxBlock(propName)
	}

	public validateCheckboxBlock(propName: string) {
		// If unchecking the box, clear any error and state variables for this option
		if (!this.card.rule[propName]) {
			switch (propName) {
				case 'startBreakReminder':
					$(`#startBreakReminderOffset`).removeClass('input-error')
					this.card.rule[propName] = null
					break
				case 'endBreakReminder':
					$(`#endBreakReminderOffset`).removeClass('input-error')
					this.card.rule[propName] = null
					break
				case 'flagBreakNotStartedBy':
					$(`#flagBreakNotStartedByOffset`).removeClass('input-error')
					break
				case 'flagShortBreak':
					$(`#flagShortBreakDuration`).removeClass('input-error')
					break
			}
		}
	}

	public rotateIcon(icon: HTMLElement) {
		// Retrieve the current rotation angle from a custom data attribute or start at 0
		let currentAngle = parseFloat(icon.getAttribute('data-angle')) || 0

		// Increment by 90 degrees
		currentAngle += 90

		// Apply the new rotation
		this.renderer.setStyle(icon, 'transform', `rotate(${currentAngle}deg)`)
		this.renderer.setStyle(icon, 'transition', 'transform 0.3s ease')

		// Store the new angle back in the element's data attribute
		icon.setAttribute('data-angle', currentAngle.toString())
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'breakOptions':
				help.header = 'Managed By'
				help.message =
					'<b>System</b> managed breaks are handled automatically by the system and do not require any action from the employee. These breaks will be computed when the time entry is closed. <br /><br /><b>Employee</b> managed breaks are handled by the employee using either the IVR system or employee web application.'
				break
			case 'startBreakReminder':
				help.header = 'Start Reminder'
				help.message = `When checked, the system will automatically send a text message to employees reminding them to take this break.`
				break
			case 'endBreakReminder':
				help.header = 'End Reminder'
				help.message = `When checked, the system will automatically send a text reminder to employees when their break duration is complete.`
				break
			case 'flagBreakNotStartedBy':
				help.header = 'Flag Start Time'
				help.message = `When checked, the system will visually highlight the break in the time entries page of the admin portal, as well as in reports, indicating a break compliance issue if this break does not start within the defined window.`
				break
			case 'flagShortBreak':
				help.header = 'Flag Short Break'
				help.message = `When checked, the system will visually highlight the break in the time entries page of the admin portal, as well as in reports, indicating a break compliance issue if this break is not at least this duration.`
				break
			case 'confirmEndBreakEarly':
				help.header = 'Confirm Early End'
				help.message = `When checked, the system prompt the employee to confirm their intent before allowing the employee to end a break prematurely.`
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
