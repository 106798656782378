<div class="ann-search-wrap">
	<div class="ann-search-box">
		<app-search-filter [placeholder]="'filter announcements'" (search)="debounceSearch($event)"> </app-search-filter>
	</div>
</div>

<div *ngIf="isDataLoaded && filteredList.length === 0" class="card no-records-card">No Scheduled Announcements</div>

<div *ngFor="let vm of filteredList">
	<div class="card ann-sched-card">
		<div>
			<div style="margin-top: 3px">
				<div class="ann-actions">
					<i class="fa fa-pencil-square-o action-i action-i-edit" title="Edit Announcement" (click)="editAnnouncement(vm)"></i>
					<i class="fa fa-trash-o action-i action-i-delete" title="Delete Announcement" (click)="deleteAnnouncement(vm)"></i>
					<i
						class="far fa-database action-i action-i-audit"
						[class.action-i-orange]="vm.announcement.updated"
						title="Delete Announcement"
						(click)="auditRecord(vm)"
					></i>
					<i class="fa fa-paper-plane action-i action-i-sendnow" title="Send Now" (click)="showSendConfirmation(vm)"></i>
				</div>
				<div class="ann-send-date">{{ vm.scheduledOn }}</div>
				<!-- <div class="ann-subject">{{ vm.announcement.subject }}</div> -->
			</div>
		</div>
		<!-- <hr /> -->

		<div class="ann-body">
			<i *ngIf="vm.announcement.action === 'SEND_EMP_SCHEDULE'" class="far fa-link" style="margin-right: 8px"></i>
			<span>{{ bodyTextFormatter(vm) }}</span>
		</div>

		<!-- Targeting All Active Employees -->
		<!-- <div class="target-block" *ngIf="vm.announcement.type === 'ALLACTIVEEMPLOYEES'">
			<div>
				<div class="target-label">
					<span class="tts-hl-2">Targeted Employees </span>
					<div style="color: chocolate">{{ vm.targetedDepartmentCount ? 'Active Employees In' : 'All Active Employees' }}</div>
				</div>
			</div>
		</div> -->

		<!-- Targeted Employees -->
		<div class="target-block" *ngIf="vm.targetedEmployeeCount || vm.targetedJobSiteCount">
			<div *ngIf="vm.targetedEmployeeCount">
				<span class="target-label tts-hl-2">Targeted Employees</span>
				<span
					class="target-count link-text"
					[class.target-count-active]="vm.show.targetedEmployees"
					(click)="vm.show.targetedEmployees = !vm.show.targetedEmployees"
					>{{ vm.targetedEmployeeCount }}</span
				>
				<div *ngIf="vm.show.targetedEmployees" class="target-list">
					<span *ngFor="let emp of vm.targetedEmployeeList; let idx = index">
						<span>{{ emp.name }}</span>
						<span *ngIf="!emp.active" class="emp-inactive"> (Inactive)</span>
						<span *ngIf="idx < vm.targetedEmployeeList.length - 1"> / </span>
					</span>
				</div>
			</div>

			<!-- Targeted Job sites -->
			<div *ngIf="vm.targetedJobSiteCount">
				<span class="target-label tts-hl-2">Targeted Job Sites</span>
				<span
					class="target-count link-text"
					[class.target-count-active]="vm.show.targetedJobsites"
					(click)="vm.show.targetedJobsites = !vm.show.targetedJobsites"
				>
					{{ vm.targetedJobSiteCount }}
				</span>
				<div *ngIf="vm.show.targetedJobsites" class="target-list">{{ vm.targetedJobSiteList }}</div>
			</div>
		</div>

		<!-- Targeted Tags -->
		<div *ngIf="vm.targetedTagCount" style="margin-top: 8px">
			<span class="target-label tts-hl-2">Targeted Tags</span>
			<span
				class="target-count link-text"
				[class.target-count-active]="vm.show.targetedTags"
				(click)="vm.show.targetedTags = !vm.show.targetedTags"
				>{{ vm.targetedTagCount }}</span
			>
			<div *ngIf="vm.show.targetedTags" class="target-list" style="font-variant: small-caps">
				<span *ngFor="let tag of vm.targetedTagList; let idx = index">
					<span>{{ tag }}</span>
					<span *ngIf="idx < vm.targetedTagList.length - 1"> / </span>
				</span>
			</div>
		</div>

		<!-- Targeted Departments -->
		<div *ngIf="vm.targetedDepartmentCount" style="margin-top: 8px">
			<span class="target-label tts-hl-2">Targeted Departments</span>
			<span
				class="target-count link-text"
				[class.target-count-active]="vm.show.targetedDepartments"
				(click)="vm.show.targetedDepartments = !vm.show.targetedDepartments"
				>{{ vm.targetedDepartmentCount }}</span
			>
			<div *ngIf="vm.show.targetedDepartments" class="target-list">
				<span *ngFor="let dept of vm.targetedDepartmentList; let idx = index">
					<span>{{ dept }}</span>
					<span *ngIf="idx < vm.targetedDepartmentList.length - 1"> / </span>
				</span>
			</div>
		</div>

		<div *ngIf="vm.announcement.lastSent" style="margin-top: 12px">
			<div class="ann-last-sent">{{ vm.lastSent }}</div>
		</div>
	</div>
</div>

<app-delete-record-dialog
	*ngIf="deleteAction.showDeleteRecordDialog"
	[showDeleteRecordDialog]="deleteAction.showDeleteRecordDialog"
	[tableName]="deleteAction.tableName"
	[recordId]="deleteAction.recordId"
	[recordLabel]="deleteAction.recordLabel"
	(cancelDelete)="deleteAction.showDeleteRecordDialog = false"
	(actionComplete)="deleteActionComplete()"
></app-delete-record-dialog>

<app-modal-dialog *ngIf="dialogManager.isDialogVisible" [dialogManager]="dialogManager">
	<app-announcements-scheduled-edit
		[dialogManager]="dialogManager"
		[record]="selectedRecord.record"
		(recordSaved)="recordSaved()"
	></app-announcements-scheduled-edit>
</app-modal-dialog>

<app-modal-dialog *ngIf="previewDialogManager.isDialogVisible" [dialogManager]="previewDialogManager">
	<app-activity-banner [isProcessing]="sendConfirmation.isLoadingPreview" [message]="'Loading Preview ...'"></app-activity-banner>

	<div *ngIf="!sendConfirmation.isLoadingPreview">
		<div class="preview-result-header">
			{{ sendConfirmation.previewList.length }} employee{{ sendConfirmation.previewList.length === 1 ? '' : 's' }} targeted
		</div>
		<div *ngFor="let card of sendConfirmation.previewList">
			<app-scheduled-ann-emp-info-card
				*ngIf="!sendConfirmation.excludeList.includes(card.employee.id)"
				[card]="card"
				(removeCard)="removeCard($event)"
			></app-scheduled-ann-emp-info-card>
		</div>
	</div>
</app-modal-dialog>
