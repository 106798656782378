import { IUserReportFormat, ReportFieldDateTimeInput, ReportGlobals, TransactionMetaDataForReport, UserReportMetaData } from './report-user'

export class ShiftReport {
	metaData: UserReportMetaData
	reportData: ShiftReportData

	constructor(reportData: ShiftReportData) {
		const version = reportData.version
		const format = reportData.format
		this.metaData = new UserReportMetaData(version, format)
		this.reportData = reportData
	}
}

export class ShiftReportData {
	version = 1
	format: IUserReportFormat

	locale = 'en'
	countryCode = null

	displayPrefs = {
		format12Hour: ReportGlobals.displayPrefs.format12Hour,
	}

	transactionMetaData: TransactionMetaDataForReport

	reportDate = new ReportFieldDateTimeInput({ type: 'DATETIME', style: 'DATE', value: new Date(), skip: false })

	constructor(data?: any) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
	}
}
