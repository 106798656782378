import { Injectable } from '@angular/core'
import { log } from '@app/helpers/logger'
import { ActiveCallLegRecord } from '@app/models'
import { Global } from '@app/models/global'
import { Subject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class ActiveCallService {
	activeCallDataLoaded = false
	activeCallList: Array<ActiveCallLegRecord> = []
	activeCallListChange = new Subject<Array<ActiveCallLegRecord>>()
	activeCallLastUpdated: Date

	constructor() {
		log('Creating ActiveCallService')
	}

	clearData() {
		this.activeCallDataLoaded = false
		this.activeCallList = []
		this.activeCallLastUpdated = null
	}

	fetchActiveCalls() {
		Global.coreSrvc.dbSrvc.readTable('active_call_legs')
	}

	getactiveCallRecords(): Array<ActiveCallLegRecord> {
		return this.activeCallList
	}

	setActiveCallRecords(records: Array<ActiveCallLegRecord>) {
		this.activeCallLastUpdated = new Date()
		this.activeCallList = records.map((rec) => new ActiveCallLegRecord(rec))
		this.activeCallDataLoaded = true
		this.activeCallListChange.next(this.activeCallList)
	}

	getActiveCallRecordById(id: number): ActiveCallLegRecord {
		return this.activeCallList.find((rec) => rec.id === id)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateActiveCallRecords(records: Array<ActiveCallLegRecord>) {
		const newRecords = records.map((rec) => new ActiveCallLegRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.activeCallList.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.activeCallList.push(newRecord)
			}
		}
		this.activeCallLastUpdated = new Date()
		this.activeCallListChange.next(this.activeCallList)
	}
}

const mocks = [
	{
		id: 101,
		company_id: 1433,
		created: '2023-12-30 14:57:54.86539',
		click_to_call_log_id: 869,
		user_id: 1784,
		phone_e164: '+16128024949',
		display_name: 'Daryl Leibel',
		bridged: 0,
		channel: 'dude',
		muted: 0,
		hostname: 'IVREZC01',
		call_source: 'USERPHONE',
		last_status: 'CONNECTED',
		last_update: '2023-12-30 14:57:54.86539',
	},
	{
		id: 102,
		company_id: 1433,
		created: '2023-12-30 14:58:49.946483',
		click_to_call_log_id: 869,
		user_id: null,
		phone_e164: '+16122260725',
		display_name: 'Steve Jensen',
		bridged: 0,
		channel: 'steve',
		muted: 0,
		hostname: 'IVREZC01',
		call_source: 'EMPLOYEEPHONE',
		last_status: 'CONNECTED',
		last_update: '2023-12-30 14:58:49.946483',
	},
]
