<div>
	<div id="INCIDENT_REPORT" class="ur-separator ur-report-sep" style="margin-bottom: 15px">Incident Report</div>

	<div class="form-group">
		<div class="ur-basic-label">Incident Type</div>
		<select id="INCIDENT_TYPE" class="form-control" [(ngModel)]="reportData.incidentType.value" (focus)="elementFocused($event)">
			<option value="" selected disabled hidden>select incident type</option>
			<option *ngFor="let option of incidentTypeOptions" [value]="option.type">{{ option.name }}</option>
		</select>
		<input
			id="INCD_SPECIFY_TYPE"
			class="form-control"
			style="margin-top: 8px; margin-bottom: 15px"
			*ngIf="reportData.incidentType.value === 'OTHER'"
			[(ngModel)]="reportData.incidentType.option"
			placeholder="specify an incident type"
			(focus)="elementFocused($event)"
		/>
	</div>

	<div class="form-group">
		<div class="ur-basic-label">Incident Date</div>
		<app-date-time-picker
			id="INCIDENT_DATE"
			[currentDate]="reportData.incidentDate.value"
			[pickerType]="reportData.incidentDate.type"
			[pickerMode]="'dialog'"
			[hour12Timer]="format12Hour"
			[format]="datePickerFormat"
			(pickerDidClose)="reportData.incidentDate.value = $event"
		>
		</app-date-time-picker>
	</div>

	<div id="REPORTER_INFORMATION" class="ur-separator ur-report-sep">Reporter Information</div>

	<div class="form-group">
		<div class="row">
			<div class="col-6">
				<div class="ur-basic-label">First Name</div>
				<div>
					<input
						id="REPORTER_FIRST_NAME"
						class="form-control"
						[(ngModel)]="reportData.incidentReporter.firstName.value"
						(focus)="elementFocused($event)"
					/>
				</div>
			</div>
			<div class="col-6">
				<div class="ur-basic-label">Last Name</div>
				<div>
					<input
						id="REPORTER_LAST_NAME"
						class="form-control"
						[(ngModel)]="reportData.incidentReporter.lastName.value"
						(focus)="elementFocused($event)"
					/>
				</div>
			</div>
		</div>
	</div>

	<div class="form-group">
		<div
			class="ur-skip-btn"
			*ngIf="!reportData.incidentReporter.license.value"
			(click)="reportData.incidentReporter.license.skip = !reportData.incidentReporter.license.skip"
		>
			{{ reportData.incidentReporter.license.skip ? 'edit' : 'n/a' }}
		</div>
		<div class="ur-basic-label" [class.ur-label-disabled]="reportData.incidentReporter.license.skip">License #</div>
		<div>
			<input
				id="LICENSE_NUM"
				class="form-control"
				[class.ur-skip-input]="reportData.incidentReporter.license.skip"
				[placeholder]="reportData.incidentReporter.license.skip ? '- skipped -' : ''"
				[disabled]="reportData.incidentReporter.license.skip"
				[(ngModel)]="reportData.incidentReporter.license.value"
				(focus)="elementFocused($event)"
			/>
		</div>
	</div>

	<div id="INCIDENT_SUMMARY" class="ur-separator ur-report-sep">Incident Summary</div>

	<div class="form-group">
		<div
			class="ur-skip-btn"
			*ngIf="!reportData.describeIncident.whatHappened.value"
			(click)="reportData.describeIncident.whatHappened.skip = !reportData.describeIncident.whatHappened.skip"
		>
			{{ reportData.describeIncident.whatHappened.skip ? 'edit' : 'n/a' }}
		</div>
		<div class="ur-basic-label" [class.ur-label-disabled]="reportData.describeIncident.whatHappened.skip">What happened?</div>
		<textarea
			id="WHAT_HAPPENED_SUM"
			class="form-control ur-basic-textarea"
			[class.ur-skip-textarea]="reportData.describeIncident.whatHappened.skip"
			[disabled]="reportData.describeIncident.whatHappened.skip"
			[placeholder]="reportData.describeIncident.whatHappened.skip ? '- skipped -' : 'Describe what happened'"
			[(ngModel)]="reportData.describeIncident.whatHappened.value"
			(focus)="elementFocused($event)"
		>
		</textarea>
	</div>

	<div class="form-group">
		<div
			class="ur-skip-btn"
			*ngIf="!reportData.describeIncident.whenDidItHappen.value"
			(click)="reportData.describeIncident.whenDidItHappen.skip = !reportData.describeIncident.whenDidItHappen.skip"
		>
			{{ reportData.describeIncident.whenDidItHappen.skip ? 'edit' : 'n/a' }}
		</div>
		<div class="ur-basic-label" [class.ur-label-disabled]="reportData.describeIncident.whenDidItHappen.skip">When did it happen?</div>
		<textarea
			id="WHEN_HAPPENED_SUM"
			class="form-control ur-basic-textarea"
			[class.ur-skip-textarea]="reportData.describeIncident.whenDidItHappen.skip"
			[disabled]="reportData.describeIncident.whenDidItHappen.skip"
			[placeholder]="reportData.describeIncident.whenDidItHappen.skip ? '- skipped -' : 'Describe when it happened'"
			[(ngModel)]="reportData.describeIncident.whenDidItHappen.value"
			(focus)="elementFocused($event)"
		>
		</textarea>
	</div>

	<div class="form-group">
		<div
			class="ur-skip-btn"
			*ngIf="!reportData.describeIncident.whyDidItHappen.value"
			(click)="reportData.describeIncident.whyDidItHappen.skip = !reportData.describeIncident.whyDidItHappen.skip"
		>
			{{ reportData.describeIncident.whyDidItHappen.skip ? 'edit' : 'n/a' }}
		</div>
		<div class="ur-basic-label" [class.ur-label-disabled]="reportData.describeIncident.whyDidItHappen.skip">Why did it happen?</div>
		<textarea
			id="WHY_HAPPENED_SUM"
			class="form-control ur-basic-textarea"
			[class.ur-skip-textarea]="reportData.describeIncident.whyDidItHappen.skip"
			[disabled]="reportData.describeIncident.whyDidItHappen.skip"
			[placeholder]="reportData.describeIncident.whyDidItHappen.skip ? '- skipped -' : 'Describe why it happend'"
			[(ngModel)]="reportData.describeIncident.whyDidItHappen.value"
			(focus)="elementFocused($event)"
		>
		</textarea>
	</div>

	<div class="form-group">
		<div
			class="ur-skip-btn"
			*ngIf="!reportData.describeIncident.howDidItHappen.value"
			(click)="reportData.describeIncident.howDidItHappen.skip = !reportData.describeIncident.howDidItHappen.skip"
		>
			{{ reportData.describeIncident.howDidItHappen.skip ? 'edit' : 'n/a' }}
		</div>
		<div class="ur-basic-label" [class.ur-label-disabled]="reportData.describeIncident.howDidItHappen.skip">How did it happen?</div>
		<textarea
			id="HOW_HAPPENED_SUM"
			class="form-control ur-basic-textarea"
			[class.ur-skip-textarea]="reportData.describeIncident.howDidItHappen.skip"
			[disabled]="reportData.describeIncident.howDidItHappen.skip"
			[placeholder]="reportData.describeIncident.howDidItHappen.skip ? '- skipped -' : 'Describe how it happend'"
			[(ngModel)]="reportData.describeIncident.howDidItHappen.value"
			(focus)="elementFocused($event)"
		>
		</textarea>
	</div>

	<div>
		<div class="ur-separator ur-report-sep">Additional Information</div>

		<div class="row" style="margin-bottom: 25px; color: chocolate">
			<div class="col-10"><strong>Select YES if applicable</strong></div>
			<div class="col-2" style="text-align: right">
				<span style="margin-right: 5px"><strong>YES</strong></span>
			</div>
		</div>

		<div class="row ur-checkbox-row">
			<div class="col-10">
				<label class="form-check-label ur-checkbox-label" for="didYouObserve">Did you observe the incident?</label>
			</div>
			<div class="col-2 text-right">
				<input id="didYouObserve" type="checkbox" class="form-check-input ur-checkbox" [(ngModel)]="reportData.didYouObserve.value" />
			</div>
		</div>

		<div class="row ur-checkbox-row">
			<div class="col-10">
				<label class="form-check-label ur-checkbox-label" for="wereYouInvolved">Were you involved?</label>
			</div>
			<div class="col-2 text-right">
				<input id="wereYouInvolved" type="checkbox" class="form-check-input ur-checkbox" [(ngModel)]="reportData.wereYouInvolved.value" />
			</div>
		</div>

		<div class="row ur-checkbox-row">
			<div class="col-10">
				<label class="form-check-label ur-checkbox-label" for="didSomeoneInformYou">Did someone inform you of the incident?</label>
			</div>
			<div class="col-2 text-right">
				<input id="didSomeoneInformYou" type="checkbox" class="form-check-input ur-checkbox" [(ngModel)]="reportData.didSomeoneInformYou.value" />
			</div>
		</div>

		<div *ngIf="reportData.didSomeoneInformYou.value" class="form-group ur-checkbox-textarea">
			<app-incident-contact
				[reportConfig]="reportConfig"
				[contacts]="reportData.didSomeoneInformYou.contacts"
				[limit]="1"
				[type]="'INFORMANT'"
			></app-incident-contact>
		</div>

		<div class="row ur-checkbox-row">
			<div class="col-10">
				<label class="form-check-label ur-checkbox-label" for="wereStatementsGiven">Were statements given?</label>
			</div>
			<div class="col-2 text-right">
				<input id="wereStatementsGiven" type="checkbox" class="form-check-input ur-checkbox" [(ngModel)]="reportData.wereStatementsGiven.value" />
			</div>
		</div>

		<div *ngIf="reportData.wereStatementsGiven.value" class="form-group ur-checkbox-textarea">
			<app-incident-contact
				[reportConfig]="reportConfig"
				[contacts]="reportData.wereStatementsGiven.contacts"
				[type]="'WITNESS'"
			></app-incident-contact>
		</div>

		<div class="row ur-checkbox-row">
			<div class="col-10">
				<label class="form-check-label ur-checkbox-label" for="wasPropertyDamaged">Was property damaged?</label>
			</div>
			<div class="col-2 text-right">
				<input id="wasPropertyDamaged" type="checkbox" class="form-check-input ur-checkbox" [(ngModel)]="reportData.wasPropertyDamaged.value" />
			</div>
		</div>

		<div *ngIf="reportData.wasPropertyDamaged.value" class="form-group ur-checkbox-textarea">
			<textarea
				class="form-control ur-basic-textarea"
				placeholder="Describe damage to property"
				[(ngModel)]="reportData.wasPropertyDamaged.description"
				(focus)="elementFocused($event)"
			>
			</textarea>
		</div>

		<div class="row ur-checkbox-row">
			<div class="col-10">
				<label class="form-check-label ur-checkbox-label" for="wasSupervisorOrManagerCalled">Was supervisor/manager called?</label>
			</div>
			<div class="col-2 text-right">
				<input
					id="wasSupervisorOrManagerCalled"
					type="checkbox"
					class="form-check-input ur-checkbox"
					[(ngModel)]="reportData.wasSupervisorOrManagerCalled.value"
				/>
			</div>
		</div>

		<div *ngIf="reportData.wasSupervisorOrManagerCalled.value" class="form-group ur-checkbox-textarea">
			<app-incident-contact
				[reportConfig]="reportConfig"
				[contacts]="reportData.wasSupervisorOrManagerCalled.contacts"
				[type]="'SUPERVISOR'"
			></app-incident-contact>
		</div>

		<div class="row ur-checkbox-row">
			<div class="col-10">
				<label class="form-check-label ur-checkbox-label" for="wasCustomerCalled">Was customer/client called?</label>
			</div>
			<div class="col-2 text-right">
				<input id="wasCustomerCalled" type="checkbox" class="form-check-input ur-checkbox" [(ngModel)]="reportData.wasCustomerCalled.value" />
			</div>
		</div>

		<div *ngIf="reportData.wasCustomerCalled.value" class="form-group ur-checkbox-textarea">
			<app-incident-contact
				[reportConfig]="reportConfig"
				[contacts]="reportData.wasCustomerCalled.contacts"
				[type]="'CUSTOMER'"
			></app-incident-contact>
		</div>

		<div class="row ur-checkbox-row">
			<div class="col-10">
				<label class="form-check-label ur-checkbox-label" for="wereOfficialsCalled">Were Police/Fire/EMS called?</label>
			</div>
			<div class="col-2 text-right">
				<input id="wereOfficialsCalled" type="checkbox" class="form-check-input ur-checkbox" [(ngModel)]="reportData.wereOfficialsCalled.value" />
			</div>
		</div>

		<div *ngIf="reportData.wereOfficialsCalled.value" class="form-group ur-checkbox-textarea">
			<app-incident-contact
				[reportConfig]="reportConfig"
				[departments]="reportData.wereOfficialsCalled.contacts"
				[type]="'DEPARTMENT'"
			></app-incident-contact>
		</div>

		<div class="row ur-checkbox-row">
			<div class="col-10">
				<label class="form-check-label ur-checkbox-label" for="wasAnyoneInjured">Was anyone Injured?</label>
			</div>
			<div class="col-2 text-right">
				<input id="wasAnyoneInjured" type="checkbox" class="form-check-input ur-checkbox" [(ngModel)]="reportData.wasAnyoneInjured.value" />
			</div>
		</div>

		<div *ngIf="reportData.wasAnyoneInjured.value" class="form-group ur-checkbox-textarea">
			<app-incident-contact
				[reportConfig]="reportConfig"
				[injured]="reportData.wasAnyoneInjured.contacts"
				[type]="'INJURED'"
			></app-incident-contact>
		</div>

		<!-- Someone was injured begin -->
		<div *ngIf="reportData.wasAnyoneInjured.value">
			<hr class="incident-hr" />

			<div class="form-group">
				<div
					class="ur-skip-btn"
					*ngIf="!reportData.describeConditions.weather.value"
					(click)="reportData.describeConditions.weather.skip = !reportData.describeConditions.weather.skip"
				>
					{{ reportData.describeConditions.weather.skip ? 'edit' : 'n/a' }}
				</div>
				<div class="ur-basic-label" [class.ur-label-disabled]="reportData.describeConditions.weather.skip">Describe weather</div>
				<textarea
					id="DESCRIBE_WEATHER"
					class="form-control ur-basic-textarea"
					[class.ur-skip-textarea]="reportData.describeConditions.weather.skip"
					[disabled]="reportData.describeConditions.weather.skip"
					[placeholder]="
						reportData.describeConditions.weather.skip
							? '- skipped -'
							: 'Describe weather in detail if incident occured outdoors. Was the weather clear, cloudy, or rainy?'
					"
					[(ngModel)]="reportData.describeConditions.weather.value"
					(focus)="elementFocused($event)"
				>
				</textarea>
			</div>

			<div class="form-group">
				<div
					class="ur-skip-btn"
					*ngIf="!reportData.describeConditions.location.value"
					(click)="reportData.describeConditions.location.skip = !reportData.describeConditions.location.skip"
				>
					{{ reportData.describeConditions.location.skip ? 'edit' : 'n/a' }}
				</div>
				<div class="ur-basic-label" [class.ur-label-disabled]="reportData.describeConditions.location.skip">Describe location</div>
				<textarea
					id="DESCRIBE_LOCATION"
					class="form-control ur-basic-textarea"
					[class.ur-skip-textarea]="reportData.describeConditions.location.skip"
					[disabled]="reportData.describeConditions.location.skip"
					[placeholder]="
						reportData.describeConditions.location.skip
							? '- skipped -'
							: 'Describe location in detail. Was the area wet or dry? Were any obstacles present?'
					"
					[(ngModel)]="reportData.describeConditions.location.value"
					(focus)="elementFocused($event)"
				>
				</textarea>
			</div>

			<div class="form-group">
				<div
					class="ur-skip-btn"
					*ngIf="!reportData.describeConditions.lighting.value"
					(click)="reportData.describeConditions.lighting.skip = !reportData.describeConditions.lighting.skip"
				>
					{{ reportData.describeConditions.lighting.skip ? 'edit' : 'n/a' }}
				</div>
				<div class="ur-basic-label" [class.ur-label-disabled]="reportData.describeConditions.lighting.skip">Describe lighting</div>
				<textarea
					id="DESCRIBE_LIGHTING"
					class="form-control ur-basic-textarea"
					[class.ur-skip-textarea]="reportData.describeConditions.lighting.skip"
					[disabled]="reportData.describeConditions.lighting.skip"
					[placeholder]="
						reportData.describeConditions.lighting.skip
							? '- skipped -'
							: 'Describe lighting in detail. Was there sufficient light? Were any lights not working?'
					"
					[(ngModel)]="reportData.describeConditions.lighting.value"
					(focus)="elementFocused($event)"
				>
				</textarea>
			</div>

			<div class="form-group">
				<div
					class="ur-skip-btn"
					*ngIf="!reportData.describeConditions.floor.value"
					(click)="reportData.describeConditions.floor.skip = !reportData.describeConditions.floor.skip"
				>
					{{ reportData.describeConditions.floor.skip ? 'edit' : 'n/a' }}
				</div>
				<div class="ur-basic-label" [class.ur-label-disabled]="reportData.describeConditions.floor.skip">Describe floor</div>
				<textarea
					id="DESCRIBE_FLOOR"
					class="form-control ur-basic-textarea"
					[class.ur-skip-textarea]="reportData.describeConditions.floor.skip"
					[disabled]="reportData.describeConditions.floor.skip"
					[placeholder]="
						reportData.describeConditions.floor.skip ? '- skipped -' : 'Describe the ground or floor in detail. Were any defects present?'
					"
					[(ngModel)]="reportData.describeConditions.floor.value"
					(focus)="elementFocused($event)"
				>
				</textarea>
			</div>

			<div class="form-group">
				<div
					class="ur-skip-btn"
					*ngIf="!reportData.describeConditions.signage.value"
					(click)="reportData.describeConditions.signage.skip = !reportData.describeConditions.signage.skip"
				>
					{{ reportData.describeConditions.signage.skip ? 'edit' : 'n/a' }}
				</div>
				<div class="ur-basic-label" [class.ur-label-disabled]="reportData.describeConditions.signage.skip">Describe signage</div>
				<textarea
					id="DESCRIBE_SIGNAGE"
					class="form-control ur-basic-textarea"
					[class.ur-skip-textarea]="reportData.describeConditions.signage.skip"
					[disabled]="reportData.describeConditions.signage.skip"
					[placeholder]="
						reportData.describeConditions.signage.skip
							? '- skipped -'
							: 'Describe signage in detail. Was there proper signange and was the signage visible?'
					"
					[(ngModel)]="reportData.describeConditions.signage.value"
					(focus)="elementFocused($event)"
				>
				</textarea>
			</div>
		</div>
		<!-- Someone was injured end -->
	</div>
</div>

<!-- <div *ngIf="true">
	<hr>
	<div>
		<pre>{{ reportData | json }}</pre>
	</div>
	<hr>
</div> -->
