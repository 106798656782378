<app-modal-dialog *ngIf="dialogManager.isDialogVisible" [dialogManager]="dialogManager">
	<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

	<div *ngIf="!isUpdating" class="main-block">
		<div class="alert alert-info">Please check all boxes to indicate you have read and agree to the documents listed.</div>

		<div class="checkbox-block" *ngIf="needsUpdate.vTos">
			<div class="checkbox-item d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-checkbox [(ngModel)]="tosConfirmed" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<div (click)="toggleCheckbox('tosConfirmed')" class="has-pointer">
						I have read and agree to the Telephone Timesheets Terms of Service and Additional Terms for Subscribers
					</div>
					<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('tosConfirmed')"></i> -->
					<div class="link-text" (click)="showDocument('TOS')">view document</div>
				</div>
			</div>
		</div>

		<div class="checkbox-block" *ngIf="needsUpdate.vPrivacy">
			<div class="checkbox-item d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-checkbox [(ngModel)]="privacyConfirmed" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<div (click)="toggleCheckbox('privacyConfirmed')" class="has-pointer">
						I have read and agree to the Telephone Timesheets Privacy Policy
					</div>
					<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('notifySms')"></i> -->
					<div class="link-text" (click)="showDocument('PRIVACY')">view document</div>
				</div>
			</div>
		</div>
	</div>
</app-modal-dialog>
