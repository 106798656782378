export class LanguageHelper {
	static list = [
		{ label: 'English', value: 'en' },
		{ label: 'Spanish', value: 'es' },
		{ label: 'French', value: 'fr' },
		{ label: 'Chinese', value: 'cn' },
		{ label: 'Russian', value: 'ru' },
	]

	static getLanguageForCode(code: string) {
		const lang = LanguageHelper.list.find((l) => l.value === code.toLowerCase())
		if (lang) {
			return lang.label
		}
		return 'Unknown'
	}
}
