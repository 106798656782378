import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, ChangeDetectorRef } from '@angular/core'
import { EmpAppPrefs, EmpAppPrefsConfig, DialogControlEvent, IPrefsCategory, HelpDialogMessage, IEmpAppPrefsConfigItem } from '@app/models'
import { CoreService } from '@app/services'
import { log } from '@app/helpers'
import { Subscription } from 'rxjs'
import _ from 'lodash'

@Component({
    selector: 'app-emp-prefs',
    templateUrl: './emp-prefs.component.html',
    styleUrls: ['./prefs.component.scss'],
    standalone: false
})
export class EmpAppPrefsComponent implements OnInit, OnDestroy {
	@Input() empId: number
	@Input() group: string // 'company' | 'employee'
	@Output() saveComplete = new EventEmitter<boolean>()

	isUpdating = false
	isInternalUser = false

	prefsConfig = new EmpAppPrefsConfig()
	companyPrefs: EmpAppPrefs
	prefsCategories = EmpAppPrefsConfig.getCategories()
	selectedCategory = 'all'

	hiddenOptions = ['pickerOptionDefaultPicker', 'pickerOptionShowSelectBySite']

	searchFilterText = '' // Debounced in search filter component

	private subs = new Subscription()

	constructor(
		private cd: ChangeDetectorRef,
		private coreSrvc: CoreService,
	) {
		this.subs.add(this.coreSrvc.eventSrvc.dialogControlEvents.subscribe((event) => this.processControlEvents(event)))
		this.enableSaveBtn()

		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()

		// Check if use job codes is setup and remove from options if not
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const pickerOption = this.prefsConfig.getPrefForName('pickerOptionDefaultPicker')
		if (!company.useJobCodes) {
			if (pickerOption) {
				pickerOption.options = pickerOption.options.filter((opt) => opt.value !== 'CODE')
			}
		}
		// Remove schedule picker from picker options
		pickerOption.options = pickerOption.options.filter((opt) => opt.value !== 'SCHEDULE')

		// Hide option for enable simple GPS if not using emp app for gps
		// if (!company.gps_use_emp_app) {
		// 	this.hiddenOptions.push('enableSimpleGps')
		// }

		// Hide Job Site Picker options if job codes in use
		const useJobCodes = company.useJobCodes
		if (useJobCodes) {
			this.prefsCategories = this.prefsCategories.filter((pc) => pc.value !== 'jobSitePicker')
		}

		const isHealthcare = this.coreSrvc.dbSrvc.settingSrvc.getCompany().industry === 'HEALTHCARE'
		if (!isHealthcare) {
			this.hiddenOptions.push('enableReportHomecare')
		}
	}

	processControlEvents(event: DialogControlEvent) {
		const callerId = event.callerId
		const direction = event.direction
		if (callerId === 'empAppPrefs' && direction === 'toContent') {
			const eventName = event.eventName
			if (eventName === 'saveBtnClicked') {
				this.savePreferencesBtnClicked()
			}
		}
	}

	enableSaveBtn() {
		const event = new DialogControlEvent('prefsEdit', 'toDialog', 'enableSaveBtn', null)
		this.coreSrvc.eventSrvc.dialogNotification(event)
	}

	ngOnInit() {
		log('action', this.group)
		// Setup companyPrefs for checking current settings
		this.companyPrefs = this.coreSrvc.dbSrvc.settingSrvc.getEmpAppPrefsForCompany()
		if (this.group === 'company') {
			this.setupCompanyPrefs()
		} else {
			this.setupEmployeePrefs()
		}
		this.setupInternalPrefs()
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	setupCompanyPrefs() {
		log('Setting company prefs')
		const compPrefs: EmpAppPrefs = this.coreSrvc.dbSrvc.settingSrvc.getCompany().getCompanyEmpAppPrefs()
		// const empAppPrefs = new EmpAppPrefs(compPrefs)
		this.prefsConfig.setPrefs(compPrefs)
	}

	setupEmployeePrefs() {
		const empId = this.empId
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)
		if (emp) {
			const empPrefs = this.coreSrvc.dbSrvc.settingSrvc.getEmpAppPrefsForEmployee(emp)
			// const empAppPrefs = new EmpAppPrefs(empPrefs)
			this.prefsConfig.setPrefs(empPrefs)
		}
	}

	setupInternalPrefs() {
		const isInternal = this.coreSrvc.dbSrvc.settingSrvc.getMyUser()?.role === 'INTERNAL'
		if (isInternal) {
			const reportsNote =
				'The Homecare report option is only available when a company is designated as being in the healthcare industry. Employee Surveys are managed in their own section. '
			this.prefsCategories.push({ value: 'availableReports', name: 'Available Reports (Internal)', note: null, isExpanded: true })
			this.prefsCategories.push({ value: 'internalOption', name: 'Miscellaneous (Internal)', note: null, isExpanded: true })
		}
	}

	public getPrefsForCategory(category: IPrefsCategory) {
		return this.prefsConfig.prefs.filter((pi) => pi.category.includes(category.value))
	}

	savePreferencesBtnClicked() {
		this.validatePrefs()
		if (this.group === 'company') {
			this.updateCompanyPreferences()
		} else {
			this.updateEmployeePreferences()
		}
	}

	updateCompanyPreferences() {
		// Guard against double submission
		if (this.isUpdating) {
			return
		}

		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const updateRecord = company.getUpdateRecord()
		const prefsString = this.prefsConfig.getCompanyUpdateString()
		updateRecord.web_prefs_json = prefsString
		this.isUpdating = true
		this.coreSrvc.dbSrvc.updateRecord('company', updateRecord).then((updateResult) => {
			this.saveComplete.emit(true)
		})
	}

	updateEmployeePreferences(reset?: boolean) {
		// Guard against double submission
		if (this.isUpdating) {
			return
		}

		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(this.empId)
		const updateRecord = emp.getUpdateRecord()
		const companyAppPrefs = this.coreSrvc.dbSrvc.settingSrvc.getEmpAppPrefsForCompany()
		const prefsString = this.prefsConfig.getEmployeeUpdateString(companyAppPrefs)
		updateRecord.web_prefs_json = reset ? null : prefsString
		this.isUpdating = true
		this.coreSrvc.dbSrvc.updateRecord('employee', updateRecord).then((updateResult) => {
			this.saveComplete.emit(true)
		})
	}

	resetPreferencesBtnClicked() {
		if (this.group === 'company') {
			const empAppPrefs = new EmpAppPrefs()
			this.prefsConfig.setPrefs(empAppPrefs)
		} else {
			this.updateEmployeePreferences(true)
		}
	}

	// validatePrefs(): boolean {
	// 	const prefs = this.prefsConfig.prefs
	// 	for (const pref of prefs) {
	// 		const validator = pref.validator
	// 		if (validator) {
	// 			const type = validator.type
	// 			if (type === 'INTEGER') {
	// 				pref.value = parseInt(pref.value, 10) || null
	// 			}
	// 			if (type === 'FLOAT') {
	// 				pref.value = parseFloat(pref.value) || null
	// 			}
	// 		}
	// 	}
	// 	return true
	// }

	validatePrefs(): boolean {
		const prefs = this.prefsConfig.prefs
		for (const pref of prefs) {
			const validator = pref.validator
			if (validator) {
				const type = validator.type
				if (type === 'INTEGER') {
					let value = parseInt(pref.value, 10) || null
					if (value || validator.required) {
						const min = validator.min
						const max = validator.max
						if ((min && value < min) || !value) {
							value = min
						}
						if (max && value > max) {
							value = max
						}
					}
					pref.value = value
				}
				if (type === 'FLOAT') {
					pref.value = parseFloat(pref.value) || null
				}
			}
		}
		return true
	}

	// formatCategoryName(name: string): string {
	// 	return name.charAt(0).toUpperCase() + name.slice(1) + ' Preferences'
	// }

	isDifferentFromCompanyPrefs(pref) {
		if (this.group === 'employee') {
			return pref.value !== this.companyPrefs[pref.name]
		}
		return false
	}

	resetPrefToCompanyDefault(pref) {
		pref.value = this.companyPrefs[pref.name]
	}

	isHidden(name: string) {
		const prefConfigItem: IEmpAppPrefsConfigItem = this.prefsConfig.getPrefForName(name)
		const isHiddenForView = this.hiddenOptions.includes(name)
		const isHiddenForState = prefConfigItem.isHidden ? prefConfigItem.isHidden() : false
		return isHiddenForView || isHiddenForState
	}

	// DEPRECATED 2024-04-04 - Refactored to use method on config item
	// isHidden(name: string) {
	// 	// lookup key in prefs config
	// 	const isHiddenForView = this.hiddenOptions.includes(name)
	// 	let isHiddenByDependency = false
	// 	const prefConfigItem: IEmpAppPrefsConfigItem = this.prefsConfig.getPrefForName(name)
	// 	if (prefConfigItem && !!prefConfigItem.dependsOn) {
	// 		for (const dependency of prefConfigItem.dependsOn) {
	// 			const parent = this.prefsConfig.getPrefForName(dependency)
	// 			if (parent) {
	// 				isHiddenByDependency = !parent.value // hide if parent value is not truthy
	// 			}
	// 		}
	// 	}
	// 	return isHiddenForView || isHiddenByDependency
	// }

	public toggleExpansion(isExpanded: boolean) {
		this.prefsCategories.forEach((pc) => (pc.isExpanded = isExpanded))
	}

	public showPrefHelp(pref: IEmpAppPrefsConfigItem) {
		const help = new HelpDialogMessage('Description', pref.description)
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}

	public performSearch(searchText: string) {
		const lcText = (searchText ?? '').toLowerCase()
		this.searchFilterText = lcText

		this.coreSrvc.zone.runOutsideAngular(() => {
			this.prefsCategories.forEach((pc) => {
				const prefs = this.getPrefsForCategory(pc)
				prefs.forEach((pref) => {
					pref.matchesSearchFilter = this.matchesSearchFilter(pref)
				})
			})
			this.prefsCategories.forEach((pc) => (pc.isExpanded = this.hasSearchFilterMatches(pc)))
			this.coreSrvc.zone.run(() => {
				this.cd.markForCheck()
			})
		})
	}

	public matchesSearchFilter(pref: IEmpAppPrefsConfigItem) {
		if (!this.searchFilterText) return true

		const perfSearchString = `${pref.name} ${pref.label} ${pref.description}`.toLowerCase()
		if (!perfSearchString.includes(this.searchFilterText)) return false

		return true
	}

	public hasSearchFilterMatches(category: IPrefsCategory) {
		const prefs = this.getPrefsForCategory(category)
		return prefs.some((pref) => pref.matchesSearchFilter)
	}

	get hasPrefsMatchingSearchFilter(): boolean {
		return this.prefsCategories.some((pc) => this.hasSearchFilterMatches(pc))
	}
}
