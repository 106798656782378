import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { DataTablesHelper, DateTimeHelper, DisplayHelper, GeneralTableHelper, Helper, TableActionFormatter, log } from '@app/helpers'
import {
	ComponentBridgeName,
	CrudAction,
	DialogManager,
	Incident,
	LocalPrefsData,
	LocalPrefsDialogData,
	LocalPrefsGroup,
	DatabaseTableName,
	UserReportConfiguration,
	UserReportHelper,
	UserReportIndustryType,
	UserReportType,
} from '@app/models'
import { CoreService } from '@app/services'

import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { SelectItem } from 'primeng/api/public_api'
import { CountryCode } from 'libphonenumber-js'

import moment from 'moment-timezone'
import { v4 as uuid } from 'uuid'
import { AccessHelper } from '@app/helpers/access'

enum ShiftReportTableColumnIndex {
	id = 0,
	date,
	repType,
	employee,
	job,
	pinned,
	adminNotes,
	summary,
	identifier,
	transcript,
	actions,
}

@Component({
    selector: 'app-shift-report-table',
    templateUrl: './shift-report-table.component.html',
    styleUrls: ['./shift-report-table.component.scss'],
    standalone: false
})
export class ShiftReportTableComponent implements OnInit, OnDestroy, AfterViewInit {
	bridgeName: ComponentBridgeName = 'ngBridgeShiftReportTable'
	accessHelper: AccessHelper

	list: Array<Incident> = []
	jobOptions: SelectItem[] = []
	tzOptions: SelectItem[] = []

	private incidentLogTable // : DataTables.DataTable;
	private defaultSortOrder = [[ShiftReportTableColumnIndex.date, 'desc']]

	private subs = new Subscription()

	public sectionPrefsDialogManager = new DialogManager('sectionPrefsDialog')

	viewReportAction = { record: null, showConfirmation: false, isDialogVisible: false }
	editReportAction = { transId: null, record: null, report: null, reportConfig: new UserReportConfiguration(), isEditing: false }
	deleteAction = {
		tableName: 'incident_log' as DatabaseTableName,
		recordId: null,
		recordLabel: null,
		showDialog: false,
	}
	reportsDialogManager = new DialogManager('reportsDialogModal')

	constructor(
		private cd: ChangeDetectorRef,
		private router: Router,
		private coreSrvc: CoreService,
	) {
		this.jobOptions = this.coreSrvc.dbSrvc.jobSrvc.getDropdownData()
		this.setupSubscriptions()
		this.setupAccessPermissions()
		this.setupTimezoneDropdown()
		this.setupLocalPrefsDialog()
	}

	get devDetect() {
		return this.coreSrvc.devDetect
	}

	get viewManager() {
		return this.coreSrvc.dbSrvc.incidentLogSrvc.viewManager
	}

	public ngOnInit(): void {
		window[this.bridgeName] = this
		this.fetchAndReload()
	}

	public ngOnDestroy() {
		this.incidentLogTable['fixedHeader'].disable()
		window[this.bridgeName] = null
		this.subs.unsubscribe()
	}

	public ngAfterViewInit() {
		this.initTable()

		$('#incidentLogTable_filter input').attr('placeholder', ' filter')
		$('#incidentLogTable_filter input').addClass('search-field-input')

		setTimeout(() => {
			$('#clear-search-icon').detach().appendTo('#incidentLogTable_filter label')
		})
	}

	private setupSubscriptions() {
		this.subs.add(
			this.coreSrvc.eventSrvc.showFixedHeader.subscribe((show) => {
				log('Show Trans Table Fixed Header', show)
				if (show) {
					this.incidentLogTable?.fixedHeader.enable(true)
					setTimeout(() => {
						this.incidentLogTable.columns.adjust().responsive.recalc()
						this.incidentLogTable.fixedHeader.adjust()
					}, 100)
				} else {
					this.incidentLogTable?.fixedHeader.enable(false)
				}
			}),
		)

		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	private setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'incident')
		this.accessHelper.updateSupervisorIds()
	}

	private handleScreenSizeChanges() {
		if (this.incidentLogTable) {
			this.incidentLogTable.columns.adjust().responsive.recalc()
			this.incidentLogTable.fixedHeader.adjust()
		}
	}

	private canPerformAction(action: CrudAction, isMyRecord: boolean): boolean {
		return this.accessHelper.canPerformAction(action, isMyRecord)
	}

	private isMyRecord(incident: Incident): boolean {
		return this.accessHelper.isMyRecord(incident.employee_id, 'employee') || this.accessHelper.isMyRecord(incident.job_site_id, 'site')
	}

	private setupLocalPrefsDialog(): void {
		this.sectionPrefsDialogManager.headerLabel = 'Preferences'
		let columnVisibilityItems = LocalPrefsData.getIncidentLogColumnDisplayPrefs()
		const columnVisibilityGroup = new LocalPrefsGroup('Column Visibility', columnVisibilityItems)
		const dialogData = new LocalPrefsDialogData([columnVisibilityGroup])
		dialogData.localStorageKeyRemovalList = ['DataTables_incidentLogTable']
		this.sectionPrefsDialogManager.dialogData = dialogData
	}

	private setupTimezoneDropdown() {
		const timezones = this.coreSrvc.dbSrvc.settingSrvc
			.getSupportedTimezones()
			.filter((tz) => tz.active)
			.map((tz) => {
				return { label: tz.display_name ? tz.display_name : tz.zone_name, value: tz.zone_name }
			})
		this.tzOptions = timezones
	}

	public prefsDataSaved(): void {
		log('UpdatePrefsData')
		this.sectionPrefsDialogManager.isDialogVisible = false
		this.updateTable()
	}

	public openPrefsDialog(): void {
		this.sectionPrefsDialogManager.isDialogVisible = true
	}

	public clearSearch(): boolean {
		this.incidentLogTable
			?.search('')
			.order([[ShiftReportTableColumnIndex.date, 'desc']])
			.rows()
			.invalidate()
			.draw()
		this.coreSrvc.displaySrvc.enableAllTooltips()
		return false
	}

	private loadData() {
		this.list = this.accessHelper.getIncidentTableList()
	}

	public updateTable() {
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadData()
			this.updateColumns()
		})
	}

	private updateColumns() {
		const employee = this.coreSrvc.prefSrvc.data.incidentLogEmployeePrefVisible
		const repType = this.coreSrvc.prefSrvc.data.incidentLogRepTypePrefVisible
		const summary = this.coreSrvc.prefSrvc.data.incidentLogSummaryPrefVisible
		const job = this.coreSrvc.prefSrvc.data.incidentLogJobPrefVisible
		const pinned = this.coreSrvc.prefSrvc.data.incidentLogPinnedPrefVisible
		const adminNotes = this.coreSrvc.prefSrvc.data.incidentLogAdminNotesPrefVisible
		const identifier = this.coreSrvc.prefSrvc.data.incidentLogIdentifierPrefVisible
		const transcript = this.coreSrvc.prefSrvc.data.incidentLogTranscriptPrefVisible

		this.incidentLogTable.clear()
		GeneralTableHelper.updateColumn(this.incidentLogTable, ShiftReportTableColumnIndex.employee, employee)
		GeneralTableHelper.updateColumn(this.incidentLogTable, ShiftReportTableColumnIndex.repType, repType)
		GeneralTableHelper.updateColumn(this.incidentLogTable, ShiftReportTableColumnIndex.summary, summary)
		GeneralTableHelper.updateColumn(this.incidentLogTable, ShiftReportTableColumnIndex.job, job)
		GeneralTableHelper.updateColumn(this.incidentLogTable, ShiftReportTableColumnIndex.pinned, pinned)
		GeneralTableHelper.updateColumn(this.incidentLogTable, ShiftReportTableColumnIndex.adminNotes, adminNotes)
		GeneralTableHelper.updateColumn(this.incidentLogTable, ShiftReportTableColumnIndex.identifier, identifier)
		GeneralTableHelper.updateColumn(this.incidentLogTable, ShiftReportTableColumnIndex.transcript, transcript)

		this.incidentLogTable.rows.add(this.list)
		this.incidentLogTable.draw(false)
	}

	public resetPage(reDraw: boolean) {
		this.incidentLogTable.page(0)
		if (reDraw) {
			this.incidentLogTable.draw(false)
		}
	}

	public fetchAndReload() {
		log('Fetch and reload')
		const options = this.makeRequestOptions()
		this.coreSrvc.dbSrvc.readTable('incident_log', options).then((readSuccess) => {
			this.updateTable()
		})
	}

	private makeRequestOptions(): Object {
		const dayViewDate = this.viewManager.dayViewDate
		const filterDates = this.viewManager.getFilterRangeStrings()

		log('makeRequestOptions/dayView/filterDate', filterDates, dayViewDate)

		// If filter range is active the use it
		if (filterDates) {
			return {
				start_date: filterDates.start,
				end_date: filterDates.end,
				job_id: this.viewManager.selectedJobId,
				emp_id: this.viewManager.selectedEmpId,
			}
		}

		// If DayView is active then use that
		if (dayViewDate) {
			const dateStr = moment(dayViewDate).format('YYYY-MM-DD')
			return { start_date: dateStr, end_date: dateStr, job_id: this.viewManager.selectedJobId, emp_id: this.viewManager.selectedEmpId }
		}

		// Otherwise use the default query
		return { job_id: this.viewManager.selectedJobId, emp_id: this.viewManager.selectedEmpId }
	}

	public viewTimeEntry(id: number) {
		const rec = this.coreSrvc.dbSrvc.incidentLogSrvc.getIncidentLogRecordById(id)

		if (!rec.transaction_log_id) {
			this.coreSrvc.notifySrvc.notify(
				'error',
				'No Time Entry',
				'The time entry linked to this report has been deleted or is otherwise unavailable.',
			)
			return
		}
		const transId = rec.transaction_log_id
		const isDesktop = this.coreSrvc.devDetect.isDesktop()
		const origin = this.coreSrvc.dbSrvc.settingSrvc.getOriginUrl()
		const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
		const path = `/redirect/record/${transId}/transactions/${companyId}`
		const transUrl = `${origin}/#${path}`

		this.coreSrvc.dbSrvc.readRecord('transaction_log', transId).then((readSuccess) => {
			this.coreSrvc.zone.run(() => {
				if (readSuccess === 'UPDATED') {
					if (isDesktop) {
						window.open(transUrl, '_viewTimeEntry')
					} else {
						this.router.navigate([path])
					}
				}
			})
		})
	}

	public viewReport(id: number) {
		const rec = this.coreSrvc.dbSrvc.incidentLogSrvc.getIncidentLogRecordById(id)
		const viewUrl = rec.publicReportLink
		window.open(viewUrl, '_employeeReportView')
	}

	public togglePin(id: number) {
		const rec = this.coreSrvc.dbSrvc.incidentLogSrvc.getIncidentLogRecordById(id)
		rec.pinned = !rec.pinned
		this.coreSrvc.dbSrvc.updateRecord('incident_log', rec).then(() => {
			const updatedRec = this.coreSrvc.dbSrvc.incidentLogSrvc.getIncidentLogRecordById(id)
			const pinState = updatedRec.pinned ? 'pinned to' : 'unpinned from'
			this.coreSrvc.notifySrvc.notify('info', 'Bulletin Board', `The selected report has been ${pinState} the bullentin board.`, 5)
			this.updateTable()
		})
	}

	editReport(id: number) {
		const record = this.coreSrvc.dbSrvc.incidentLogSrvc.getIncidentLogRecordById(id)
		const isMyRecord = this.isMyRecord(record)
		if (!this.canPerformAction(CrudAction.update, isMyRecord)) {
			this.coreSrvc.notifySrvc.default('operationNotAuthorized')
			return
		}

		log('Edit Report Btn Clicked', record)
		if (!record.report_json && record.audio_file) {
			this.coreSrvc.notifySrvc.notify('info', 'IVR Incident', 'Audio reports submitted via IVR cannot be edited.', 3)
			return
		}

		const reportJson = record.report_json
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const reportConfig = new UserReportConfiguration()
		reportConfig.countryCode = company.country_iso as CountryCode
		reportConfig.industryType = company.industry as UserReportIndustryType
		reportConfig.reportType = record.report_type as UserReportType
		this.editReportAction.record = record
		this.editReportAction.reportConfig = reportConfig
		this.editReportAction.report = reportJson ? JSON.parse(reportJson) : null
		this.editReportAction.transId = record.transaction_log_id || null
		log('Edit Report Action', this.editReportAction)
		this.reportsDialogManager.headerLabel = 'Edit Report'
		this.reportsDialogManager.pushState()
		this.reportsDialogManager.scrollToTop()
		this.reportsDialogManager.isDialogVisible = true
		setTimeout(() => {
			this.reportsDialogManager.setBackgroundColor('#eee')
		}, 100)
	}

	public editReportSaved() {
		log('Edir Report Saved')
		this.reportsDialogManager.isDialogVisible = false
		this.reportsDialogManager.setBackgroundColor('#fff')
		this.fetchAndReload()
	}

	public editReportCancelled() {
		log('Edit Report Cancelled')
		this.reportsDialogManager.setBackgroundColor('#fff')
		this.reportsDialogManager.isDialogVisible = false
	}

	public showViewReportDialog(id: number) {
		const record = this.coreSrvc.dbSrvc.incidentLogSrvc.getIncidentLogRecordById(id)
		this.viewReportAction.record = record
		this.viewReportAction.showConfirmation = false
		this.viewReportAction.isDialogVisible = true
	}

	public copyPublicLinkBtnClicked() {
		this.coreSrvc.dbSrvc.settingSrvc.copyToClipboard(this.viewReportAction.record.publicReportLink)
		this.coreSrvc.notifySrvc.notify('success', 'Link Copied', 'The public link for this report has been copied to your clipboard.', 5)
	}

	public viewReportBtnClicked() {
		log('View Report Btn Clicked')
		this.viewReportAction.isDialogVisible = false
		setTimeout(() => {
			this.viewReport(this.viewReportAction.record.id)
		}, 100)
	}

	public confirmRefreshBtnClicked() {
		log('Confirm Refresh Public Link')
		log('Refresh Public Link')
		const record = this.viewReportAction.record
		record.uuid = uuid()
		this.coreSrvc.dbSrvc.updateRecord('incident_log', record).then((success) => {
			this.viewReportAction.isDialogVisible = false
			this.updateTable()
			this.coreSrvc.notifySrvc.notify('success', 'Link Refreshed', 'The public link for this report has been refreshed.')
		})
	}

	public deleteReport(id: number) {
		const record = this.coreSrvc.dbSrvc.incidentLogSrvc.getIncidentLogRecordById(id)
		const isMyRecord = this.isMyRecord(record)
		if (!this.canPerformAction(CrudAction.delete, isMyRecord)) {
			this.coreSrvc.notifySrvc.default('operationNotAuthorized')
			return
		}

		const recLabel = UserReportHelper.formatReportTypeLabel(record.report_type as UserReportType)
		const tz = this.viewManager.timezone
		const created = moment(record.created).tz(tz).format('YYYY-MM-DD')
		this.deleteAction.recordId = id
		this.deleteAction.recordLabel = `${recLabel} for ${record.employee_name} on ${created}`
		this.deleteAction.showDialog = true
	}

	public deleteReportConfirmation() {
		this.deleteAction.recordId
	}

	public deleteActionCancel() {
		this.deleteAction.showDialog = false
	}

	public deleteActionComplete() {
		this.deleteAction.showDialog = false
		this.updateTable()
	}

	private initTable() {
		this.coreSrvc.displaySrvc.startSectionLoader()
		const mythis = this
		this.incidentLogTable = $('#incidentLogTable').DataTable(<DataTables.Settings>{
			stateSave: false,
			responsive: true,
			processing: true,
			deferRender: true,
			paging: true,
			pageLength: 50,
			lengthChange: true,
			info: true,
			select: false,
			searching: true,
			fixedHeader: DataTablesHelper.fixedHeader,
			autoWidth: false,
			data: this.list,
			order: this.defaultSortOrder,
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{
					responsivePriority: 1,
					targets: [ShiftReportTableColumnIndex.date, ShiftReportTableColumnIndex.repType, ShiftReportTableColumnIndex.actions],
				},
				{
					// ID
					targets: ShiftReportTableColumnIndex.id,
					visible: false,
					searchable: true,
					title: 'ID',
					data: null,
					render: (rec: Incident, type, full, meta) => {
						return `${rec.id}`
					},
				},
				{
					// Date
					targets: ShiftReportTableColumnIndex.date,
					visible: true,
					searchable: true,
					title: 'Date',
					data: null,
					render: (rec: Incident, type, full, meta) => {
						const recDate = rec.created
						const tz = this.viewManager.timezone
						const format = DateTimeHelper.getTimeFormat()
						const date = moment(recDate).tz(tz).format('YYYY-MM-DD')
						const time = moment(recDate).tz(tz).format(format)
						const result = `<span style="display: none">${recDate}</span>${date}<br>${time}`
						return `
						<div class="dtr-control-content">
							${result}
						</div>`
					},
				},
				{
					// Type
					targets: ShiftReportTableColumnIndex.repType,
					visible: this.coreSrvc.prefSrvc.data.incidentLogRepTypePrefVisible,
					searchable: true,
					title: 'Type',
					data: null,
					render: (rec: Incident, type, full, meta) => {
						const repType = rec.report_type as UserReportType
						const color = repType === 'INCIDENT' ? 'firebrick' : '#135c94'
						const repLabel = UserReportHelper.formatReportTypeLabel(repType)

						const customTitle = rec.customReportData ? rec.customReportData.reportData.employeeChecklists[0]?.title : ''
						const customTitleHtml = customTitle ? `<div style="font-size: small; color: gray">${customTitle}</div>` : ''
						return `<div style="min-width: 155px;white-space:normal;font-weight:600; color: ${color}">${repLabel}${customTitleHtml}</div>`
					},
				},
				{
					// Employee
					targets: ShiftReportTableColumnIndex.employee,
					visible: this.coreSrvc.prefSrvc.data.incidentLogEmployeePrefVisible,
					searchable: true,
					title: 'Employee',
					data: null,
					render: (rec: Incident, type, full, meta) => {
						return `${rec.employee_name}`
					},
				},
				{
					// Job
					targets: ShiftReportTableColumnIndex.job,
					visible: this.coreSrvc.prefSrvc.data.incidentLogJobPrefVisible,
					searchable: true,
					title: 'Job',
					data: null,
					render: (rec: Incident, type, full, meta) => {
						return `${rec.job_description}`
					},
				},
				{
					// Pinned
					targets: ShiftReportTableColumnIndex.pinned,
					visible: this.coreSrvc.prefSrvc.data.incidentLogPinnedPrefVisible,
					searchable: true,
					title: 'Pinned',
					data: null,
					render: (rec: Incident, type, full, meta) => {
						const hasPin = !!rec.pinned
						const canAccessPin = true
						const pinLink = TableActionFormatter.pinReportLink(this.bridgeName, 'togglePin', rec.id, canAccessPin, hasPin)
						return `<span class="act-ico-wrap">${pinLink}</span>`
					},
				},
				{
					// Admin Notes
					targets: ShiftReportTableColumnIndex.adminNotes,
					visible: this.coreSrvc.prefSrvc.data.incidentLogAdminNotesPrefVisible,
					searchable: true,
					title: 'Admin Notes',
					data: null,
					render: (rec: Incident, type, full, meta) => {
						return rec.notes ? `<div class="trans-note-block hide-scrollbars">${rec.notes}</div>` : ''
					},
				},
				{
					// Summary
					targets: ShiftReportTableColumnIndex.summary,
					visible: this.coreSrvc.prefSrvc.data.incidentLogSummaryPrefVisible,
					searchable: true,
					title: 'Summary',
					data: null,
					render: (rec: Incident, type, full, meta) => {
						const summaryNote = rec.getSummaryNote()
						return summaryNote ? `<div class="trans-note-block hide-scrollbars">${summaryNote}</div>` : ''
					},
				},
				{
					// Transcript
					targets: ShiftReportTableColumnIndex.transcript,
					visible: this.coreSrvc.prefSrvc.data.incidentLogTranscriptPrefVisible,
					searchable: true,
					title: 'Transcript',
					data: null,
					render: (rec: Incident, type, full, meta) => {
						return rec.transcript ? `<div class="trans-note-block hide-scrollbars">${rec.transcript}</div>` : ''
					},
				},
				{
					// Identifier
					targets: ShiftReportTableColumnIndex.identifier,
					visible: this.coreSrvc.prefSrvc.data.incidentLogIdentifierPrefVisible,
					searchable: true,
					title: 'Identifier',
					data: null,
					render: (rec: Incident, type, full, meta) => {
						return rec.reportIdentifier
					},
				},
				{
					// Actions
					targets: ShiftReportTableColumnIndex.actions,
					visible: true,
					searchable: false,
					title: 'Actions',
					data: null,
					render: (rec: Incident, type, full, meta) => {
						const isMyRecord = this.isMyRecord(rec)

						const canAccessEdit = this.canPerformAction(CrudAction.update, isMyRecord)
						const editLink = TableActionFormatter.editLink(this.bridgeName, 'editReport', rec.id, canAccessEdit)

						const canAccessDelete = this.canPerformAction(CrudAction.delete, isMyRecord)
						const deleteLink = TableActionFormatter.deleteLink(this.bridgeName, 'deleteReport', rec.id, canAccessDelete)

						const repLink = TableActionFormatter.customLink(
							this.bridgeName,
							'showViewReportDialog',
							rec.id,
							'View Report',
							'far fa-file-invoice',
							true,
						)

						const hasTimeEntry = !!rec.transaction_log_id
						const timeEntryLink = TableActionFormatter.viewTimeEntryLink(this.bridgeName, 'viewTimeEntry', rec.id, true, hasTimeEntry)

						const hasAuditLog = !!rec.updated
						const canAccessAudit = true // this.canAccessAuditLog
						const auditLink = TableActionFormatter.auditLinkGlobal('incident_log', rec.id, 'Employee Report', canAccessAudit, hasAuditLog)

						return `<span class="act-ico-wrap">${editLink}${deleteLink}${repLink}${timeEntryLink}${auditLink}</span>`
					},
				},
			],
			// 'createdRow': (row, data, dataIndex) => {
			// 	const enabled = this.checkEnabledStatus(data as Vacation)
			// 	if (!enabled) {
			// 		$(row).addClass('vac-disabled')
			// 	}
			// }
			drawCallback: (settings) => {
				this.incidentLogTable?.columns.adjust().responsive.recalc()
				this.coreSrvc.displaySrvc.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
			},
		})
		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter('incidentLogTable', this.incidentLogTable)
	}
}

const x = {
	metaData: { format: { reportType: 'CUSTOM', industryType: 'SECURITY' }, version: 1 },
	reportData: {
		format: { reportType: 'CUSTOM', industryType: 'SECURITY' },
		locale: 'en',
		summary: { additionalNotes: { skip: false, type: 'STRING', style: 'TEXTAREA', value: '' } },
		version: 1,
		employee: {
			lastName: { skip: false, type: 'STRING', style: 'INPUT', value: 'Employee' },
			firstName: { skip: false, type: 'STRING', style: 'INPUT', value: 'Daryl' },
		},
		shiftDate: { skip: false, type: 'DATETIME', style: 'DATE', value: '2024-07-04T01:18:55.379Z' },
		countryCode: 'US',
		displayPrefs: { format12Hour: true },
		companyChecklist: [],
		employeeChecklists: [
			{
				type: 'CHECKLISTBASIC',
				items: [
					{
						skip: false,
						type: 'SELECT',
						label: 'Incident Type',
						multi: { allow: true, selected: [] },
						style: 'LIST',
						tagId: 'empcl-option-0-0',
						value: null,
						custom: { allow: true, value: null },
						comment: { value: '', include: false },
						options: [
							{ label: 'Fire / Emergency', value: null, selected: true },
							{ label: 'Trespassing', value: null, selected: false },
							{ label: 'Accident', value: null, selected: true },
						],
					},
				],
				tagId: 'epmcl-block-0',
				title: 'List / Checkboxes / Custom',
				instructions:
					'This survey form demonstrates a couple variations of the SELECT form block, including a dropdown menu and a list of options. It also includes a RANGE block for good measure.',
			},
		],
		transactionMetaData: {
			id: 925389,
			job_id: 12224,
			end_time: '09:00',
			job_date: '2024-07-03',
			timezone: 'America/Chicago',
			jobsite_id: 13145,
			start_time: '09:00',
			employee_id: 21615,
			employee_name: 'Daryl Employee',
			job_description: 'Local Job',
			emp_supervisor_id: 1784,
			schedule_recur_id: null,
			emp_supervisor_name: 'Daryl Leibel',
			jobsite_description: 'Local Job',
			jobsite_supervisor_id: 1794,
			jobsite_supervisor_name: 'Primary Supervisor',
		},
	},
}
