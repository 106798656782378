import { Injectable } from '@angular/core'
import { log } from '@app/helpers'
import { Subject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class AudioService {
	playAudioUrl = new Subject<string>()

	constructor() {
		log('Creating AudioService')
	}
}
