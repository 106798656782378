import { DateTimeHelper } from '@app/helpers'
import { log } from '@app/helpers/logger'
import { ColorVendor, DistanceUnitOption, IFileUploadsMetadata } from '@app/models'
import { PFPImageFile } from './pfp'
import { EmpAppPrefs } from './prefs'

export interface EmployeeSelectItem {
	label: string
	value: number
	data: EmployeeRecord
}

export type EmployeesViewTabState = 'ACTIVE' | 'INACTIVE' | 'ALL'

export class EmployeesViewManager {
	version = 1
	showInlinePfp = true
	currentView: EmployeesViewTabState = 'ACTIVE'
}

export class EmpScoreWeight {
	lateNotifyEmployee = 30
	lateNotifySupervisor = 30
	gpsRequestsCompleted = 20
	gpsResponseOnTime = 10
	gpsDistanceInBounds = 10

	static isConfigValid(data: string): boolean {
		if (!data) {
			return true
		}
		let config: EmpScoreWeight = null
		try {
			config = JSON.parse(data)
		} catch (err) {
			return false
		}

		// Check for all properties

		if (typeof config.lateNotifyEmployee === 'undefined') {
			return false
		}
		if (typeof config.lateNotifySupervisor === 'undefined') {
			return false
		}
		if (typeof config.gpsRequestsCompleted === 'undefined') {
			return false
		}
		if (typeof config.gpsResponseOnTime === 'undefined') {
			return false
		}
		if (typeof config.gpsDistanceInBounds === 'undefined') {
			return false
		}

		// Check that all properties add up to 100

		const sum =
			config.lateNotifyEmployee + config.lateNotifySupervisor + config.gpsRequestsCompleted + config.gpsResponseOnTime + config.gpsDistanceInBounds

		if (sum === 100) {
			return true
		} else {
			return false
		}
	}

	setState(data: string) {
		if (!data) {
			return
		}
		const isConfigValid = EmpScoreWeight.isConfigValid(data)

		if (isConfigValid) {
			const config: EmpScoreWeight = JSON.parse(data)
			for (const attr in config) {
				if (config.hasOwnProperty(attr)) {
					this[attr] = config[attr]
				}
			}
		}
	}

	getStateString(): string {
		return JSON.stringify(this)
	}

	totalWeight(): number {
		return this.lateNotifyEmployee + this.lateNotifySupervisor + this.gpsRequestsCompleted + this.gpsResponseOnTime + this.gpsDistanceInBounds
	}
}

export class EmployeeStats {
	weights = new EmpScoreWeight()

	count: number = null
	exceptions: number = null
	gps_completed: number = null
	gps_distance: number = null
	gps_late: number = null
	gps_open: number = null
	gps_requests: number = null
	time_worked: string = null
	timestamp: string = null
	supervisor_flag: number = null
	supervisor_flag_possible: number = null
	warned_flag: number = null
	warned_flag_possible: number = null

	constructor(input: string) {
		if (!input) {
			return
		}
		let record = null
		try {
			record = JSON.parse(input)
		} catch (err) {
			return
		}

		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
	}

	getLateNotifyEmployeeScore(): number {
		const score = ((this.warned_flag || 0) / (this.warned_flag_possible || 1)) * this.weights.lateNotifyEmployee
		if (score > this.weights.lateNotifyEmployee) {
			return 0
		} else {
			return Math.floor(this.weights.lateNotifyEmployee - score)
		}
	}

	getLateNotifySupervisorScore(): number {
		const score = ((this.supervisor_flag || 0) / (this.supervisor_flag_possible || 1)) * this.weights.lateNotifySupervisor
		if (score > this.weights.lateNotifySupervisor) {
			return 0
		} else {
			return Math.floor(this.weights.lateNotifySupervisor - score)
		}
	}

	getGpsRequestsCompletedScore(): number {
		const score = ((this.gps_open || 0) / (this.gps_requests || 1)) * this.weights.gpsRequestsCompleted
		if (score > this.weights.gpsRequestsCompleted) {
			return 0
		} else {
			return Math.floor(this.weights.gpsRequestsCompleted - score)
		}
	}

	getGpsResponseOnTimeScore(): number {
		const score = ((this.gps_late || 0) / (this.gps_completed || 1)) * this.weights.gpsResponseOnTime
		if (score > this.weights.gpsResponseOnTime) {
			return 0
		} else {
			return Math.floor(this.weights.gpsResponseOnTime - score)
		}
	}

	getGpsDistanceInBoundsScore(): number {
		const score = ((this.gps_distance || 0) / (this.gps_completed || 1)) * this.weights.gpsDistanceInBounds
		if (score > this.weights.gpsDistanceInBounds) {
			return 0
		} else {
			return Math.floor(this.weights.gpsDistanceInBounds - score)
		}
	}

	showScoring() {
		// log('Late Warn Employee', this.getLateNotifyEmployeeScore())
		// log('Late Warn Supervisor', this.getLateNotifySupervisorScore())
		// log('GPS Completed', this.getGpsRequestsCompletedScore())
		// log('GPS Late', this.getGpsResponseOnTimeScore())
		// log('GPS Outside Bounds', this.getGpsDistanceInBoundsScore())
	}

	setWeights(data: string) {
		this.weights.setState(data)
	}

	getScore(): number {
		return (
			this.getLateNotifyEmployeeScore() +
			this.getLateNotifySupervisorScore() +
			this.getGpsResponseOnTimeScore() +
			this.getGpsRequestsCompletedScore() +
			this.getGpsDistanceInBoundsScore()
		)
	}

	getStyleTextColor() {
		const score = this.getScore()
		return score > 50 ? '#536474' : '#fff'
	}

	getStyleBackgroundColor() {
		const score = this.getScore()
		const percent = (100 - score) / 100
		const hue = ((1 - percent) * 85).toString(10)
		return `hsl(${hue},70%,60%)`
	}
}

export type EmployeeStatus = 'RESIGNED' | 'TERMINATED' | 'SUSPENDED' | 'ADMINISTRATIVE_LEAVE' | 'PERSONAL_LEAVE' | 'FMLA_LEAVE'

export class EmployeeRecord {
	////////////////////////
	// Primary Keys / IDs //
	////////////////////////

	id: number = null
	adp_id: string = null
	company_id: number = null
	qbo_id: string = null
	wiw_id: number = null
	w2w_id: number = null

	///////////////
	// Main Form //
	///////////////

	first = '' // First Name (required)
	last = '' // Last Name (required)
	name = '' // Display Name (required)
	supervisor: number = null // Employee Supervisor (required / dropdown)
	phone_number: string = null // Phone Number (semi-deprecated)
	phone_number_e164: string = null // Phone Number (required)
	email: string = null // Email Address (optional)
	language = 'en' // Language (required)
	tags_json: string = null // Tags (optional)

	// Address (optional / some fields required if set)
	address_1: string = null // Stree Address (required)
	address_2: string = null // Address Line 2
	city: string = null // City (required)
	district: string = null // State / Province (required)
	postal_code: string = null // Zip / Postal Code
	address_notes: string = null // Not exposed

	//////////////////////
	// Advanced Options //
	//////////////////////

	status: EmployeeStatus = null // Employee Status (optional dropdown)
	employment_status_notes: string = null // Employee Status Notes (optional)
	external_id: string = null // External ID (optional)
	emp_code: number = null // Station PIN (optional / 10 digits max)
	employee_details: string = null // Employee Details (optional)
	department: string = null // Department (optional / autocomplete)
	start_date: string = null // Start Date (ISO Date)
	birthday: string = null // Birthday (ISO Date)
	anniversary: string = null // Anniversary (ISO Date)

	// Travel Options
	commute_distance: number = null // Maximum Commute Range (optional)
	commute_distance_units: DistanceUnitOption = null // Units for Commute Range

	// Schedule Options
	max_hrs_daily: number = null // Max Hours Daily (optional)
	max_shifts_daily: number = null // Max Shifts Daily (optional)
	max_hrs_weekly: number = null // Max Hours Weekly (optional)
	max_days_weekly: number = null // Max Days Weekly (optional)

	// Payroll Options
	adp_custom_1: string = null // Not exposed
	pay_rate: number = null // Base Pay Rate (optional)
	home_tax_location: string = null // Home Tax Location (optional / autocomplete)

	// Employee Active / Inactive Status
	active = true // Active / Inactive (radio)

	// Messaging Options
	phone_sms_id: number = null // Send Texts From (optional / dropdown)

	// Miscellaneous Options
	auto_check_in_out = false
	disable_sms: boolean = null // Use GPS (value negated in UI)
	disable_sms_ts: string = null // Set by backend when GPS is disabled
	floater = false // Enable job floating / Hidden in UI
	union = false // Hidden in UI
	voice_fingerprint = false // Enable voice fingerprints
	travel: boolean = false // Visibility tied to portal pref

	////////////////////////
	// Employee App Prefs //
	////////////////////////

	web_prefs_json: string = null

	//////////////////////
	// Employee Profile //
	//////////////////////

	pfp_json: string = null
	pfp_approved: boolean = false
	pfp_details: string = null

	////////////////////
	// Employee Score //
	////////////////////

	stats_json: string = null

	///////////////////
	// MISCELLANEOUS //
	///////////////////

	round_up_timer_disable = false // Disables company.round_up_timer
	round_down_timer_disable = false // Disables company.round_down_timer

	/////////////////////////
	// Computed Properties //
	/////////////////////////

	file_uploads_count = 0
	file_uploads_metadata: IFileUploadsMetadata
	supervisor_ids: Array<number> = []
	supervisor_group_id: number = null

	////////////////////////////
	// Unsorted / Not Exposed //
	////////////////////////////

	created: string = null
	updated: string = null
	assigned_color = null // Color used in shift view / not exposed
	comments: string = null // Not exposed
	enrolled: boolean = false // Track system enrollment
	last_active = null // Last use of system

	////////////////
	// DEPRECATED //
	////////////////

	// dispatch_phone_e164: string = null - DEPRECATED 2023-01-25

	constructor(record?: EmployeeRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(record.created)
			this.updated = DateTimeHelper.stripUtcTag(record.updated)
			this.last_active = DateTimeHelper.stripUtcTag(record.last_active)
			this.start_date = DateTimeHelper.stripUtcTag(record.start_date)
		}
	}

	hasStats(): boolean {
		if (this.stats_json) {
			return true
		} else {
			return false
		}
	}

	getStats(): EmployeeStats {
		return new EmployeeStats(this.stats_json)
	}

	// Use the interface and do not instantiate a class object or the company
	// defaults will be overwritten when merged with the company emp app prefs
	getEmployeeAppPrefsDelta(): EmpAppPrefs {
		let result: EmpAppPrefs = null
		if (this.web_prefs_json) {
			try {
				result = JSON.parse(this.web_prefs_json)
			} catch (error) {
				log('Error parsing employee web prefs', error)
			}
		}
		return result
	}

	getUpdateRecord(): EmployeeRecord {
		return JSON.parse(JSON.stringify(this))
	}

	getDisplayName(): string {
		if (this.active) {
			return this.name
		} else {
			return `**${this.name}**`
		}
	}

	getAssignedColor(): string {
		if (this.id === 0) {
			return '#147725'
		}
		if (this.assigned_color) {
			return this.assigned_color
		}
		return ColorVendor.getColorById(this.id)
	}

	getPfpImageFile(): PFPImageFile {
		return PFPImageFile.buildFromJsonString(this.pfp_json)
	}

	getStatus(): string {
		if (!this.status) return 'Ongoing'
		switch (this.status) {
			case 'RESIGNED':
				return 'Resigned'
			case 'TERMINATED':
				return 'Terminated'
			case 'SUSPENDED':
				return 'Suspended'
			case 'ADMINISTRATIVE_LEAVE':
				return 'Administrative Leave'
			case 'PERSONAL_LEAVE':
				return 'Personal Leave'
			case 'FMLA_LEAVE':
				return 'FMLA Leave'
			default:
				return 'Unknown Status'
		}
	}
}

export class ManageProfileDialogData {
	empId: number
	constructor(id: number) {
		this.empId = id
	}
}

const exampleStats = {
	count: 29,
	timestamp: '2019-01-20T21:32:59Z',
	end_period: '2019-01-18T23:59:59Z',
	exceptions: 0,
	time_worked: 'PT95H45M',
	geo_dist_avg: null,
	geo_dist_max: null,
	geo_dist_std: null,
	gps_completed: 1,
	gps_distance: 0,
	gps_late: 1,
	gps_open: 57,
	gps_requests: 58,
	start_period: '2018-12-20T00:00:00Z',
	geo_delay_avg: 'PT2M37S',
	geo_delay_max: 'PT2M37S',
	geo_end_dist_avg: null,
	geo_end_dist_max: null,
	geo_end_dist_std: null,
	geo_end_delay_avg: null,
	geo_end_delay_max: null,
	geo_start_dist_avg: null,
	geo_start_dist_max: null,
	geo_start_dist_std: null,
	geo_start_delay_avg: 'PT2M37S',
	geo_start_delay_max: 'PT2M37S',
	supervisor_flag: 1,
	supervisor_flag_possible: 28,
	warned_flag: 0,
	warned_flag_possible: 0,
}
