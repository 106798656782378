import { Injectable } from '@angular/core'
import { log } from '@app/helpers/logger'
import { WaypointLogRecord } from '@app/models'

import { Subject } from 'rxjs'
import _ from 'lodash'

@Injectable({
	providedIn: 'root',
})
export class WaypointLogService {
	dataLoaded = false
	list: Array<WaypointLogRecord> = []
	listChange = new Subject<Array<WaypointLogRecord>>()
	lastUpdated: Date

	constructor() {
		log('Creating WaypointLogService')
	}

	getWaypointLogList(): Array<WaypointLogRecord> {
		return _.orderBy(this.list, ['created'], ['asc'])
	}

	getWaypointLogById(id: number) {
		return this.list.find((rec) => rec.id === id)
	}

	setWaypointLogRecords(records: Array<WaypointLogRecord>) {
		this.lastUpdated = new Date()
		this.list = records.map((rec) => new WaypointLogRecord(rec))
		this.dataLoaded = true
		this.listChange.next(this.list)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateWaypointLogeRecords(records: Array<WaypointLogRecord>) {
		this.lastUpdated = new Date()
		const newRecords = records.map((rec) => new WaypointLogRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.list.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.list.push(newRecord)
			}
		}
		this.listChange.next(this.list)
	}
}
