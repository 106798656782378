import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { DatabaseService } from '@app/services'
import { SelectItem } from 'primeng/api'

import _ from 'lodash'
import { log } from '@app/helpers'

@Component({
    selector: 'app-multi-selector',
    templateUrl: './multi-selector.component.html',
    styleUrls: ['./multi-selector.component.scss'],
    standalone: false
})
export class MultiSelectorComponent implements OnInit {
	@Input() type: 'EMPLOYEE' | 'JOBSITE' | 'JOB' = 'EMPLOYEE'
	@Input() dropdownLabel = ''

	@Input() selectedIds: Array<number> = []
	@Output() selectedIdsChange = new EventEmitter<Array<number>>()

	placeholder = 'Select Items'
	selectedItemsLabel = 'Items Selected: {0}'
	dropdownOptions: Array<SelectItem> = []

	constructor(private dbSrvc: DatabaseService) {}

	get selectedItemNames(): Array<string> {
		switch (this.type) {
			case 'EMPLOYEE':
				return this.selectedEmployeeNames
			case 'JOBSITE':
				return this.selectedJobSiteNames
			case 'JOB':
				return this.selectedJobNames
			default:
				return []
		}
	}

	get selectedEmployeeNames(): Array<string> {
		const empIds = this.selectedIds
		if (empIds && empIds.length > 0) {
			const names = empIds
				.map((id) => this.dbSrvc.empSrvc.getEmployeeById(id))
				.filter((emp) => !!emp)
				.map((emp) => emp.name)
			return _.sortBy(names)
		}
		return []
	}

	get selectedJobSiteNames(): Array<string> {
		const siteIds = this.selectedIds
		if (siteIds && siteIds.length > 0) {
			const names = siteIds
				.map((id) => this.dbSrvc.siteSrvc.getJobSiteById(id))
				.filter((site) => !!site)
				.map((site) => site.description)
			return _.sortBy(names)
		}
		return []
	}

	get selectedJobNames(): Array<string> {
		const jobIds = this.selectedIds
		if (jobIds && jobIds.length > 0) {
			const names = jobIds
				.map((id) => this.dbSrvc.jobSrvc.getJobById(id))
				.filter((job) => !!job)
				.map((job) => job.description)
			return _.sortBy(names)
		}
		return []
	}

	ngOnInit(): void {
		this.setupComponent()
		this.setupDropdown()
	}

	setupComponent() {
		// Setup labels
		switch (this.type) {
			case 'EMPLOYEE':
				this.placeholder = 'Select Employees'
				this.selectedItemsLabel = 'Employees Selected: {0}'
				break
			case 'JOBSITE':
				this.placeholder = 'Select Job Sites'
				this.selectedItemsLabel = 'Job Sites Selected: {0}'
				break
			case 'JOB':
				this.placeholder = 'Select Jobs'
				this.selectedItemsLabel = 'Jobs Selected: {0}'
				break
		}
	}

	private setupDropdown() {
		switch (this.type) {
			case 'EMPLOYEE':
				this.setupEmployeeDropdown()
				break
			case 'JOBSITE':
				this.setupJobSiteDropdown()
				break
			case 'JOB':
				this.setupJobDropdown()
		}
	}

	private setupEmployeeDropdown() {
		// const permissions = this.accessHelper.getPermissionsFor('announcement')
		const isRestricted = false // permissions.isSelectorRestrictedFor(SelectorPermissionName.employee)
		const isManager = false // this.dbSrvc.settingSrvc.isUserAManager()

		// Add any employees that you might not own that were set by unrestricted user
		const addEmpIds = this.selectedIds || []
		const options = this.dbSrvc.empSrvc.getEmployeeDropdown(this.dbSrvc, isRestricted, isManager, addEmpIds)
		this.dropdownOptions = options as Array<SelectItem>
	}

	private setupJobSiteDropdown() {
		const isRestricted = false
		const isManager = false

		const addSiteIds = this.selectedIds || []
		const options = this.dbSrvc.siteSrvc.getJobSiteDropdown(this.dbSrvc, isRestricted, isManager, addSiteIds)
		this.dropdownOptions = options as Array<SelectItem>
	}

	private setupJobDropdown() {
		const isRestricted = false
		const isManager = false

		const addSiteIds = this.selectedIds || []
		const options = this.dbSrvc.jobSrvc.getDropdownData(true)
		const selectAJob = options.shift()
		this.dropdownOptions = options as Array<SelectItem>
	}

	valueChanged() {
		log('Value Changed')
		this.selectedIdsChange.emit(this.selectedIds)
	}
}
