import { Component, Input, OnInit } from '@angular/core'
import { GenericEmailListManager } from '@app/models'

@Component({
    selector: 'app-generic-email-list',
    templateUrl: './generic-email-list.component.html',
    styleUrls: ['./generic-email-list.component.scss'],
    standalone: false
})
export class GenericEmailListComponent implements OnInit {

	@Input() manager: GenericEmailListManager

	constructor() { }

	ngOnInit() { }


}
