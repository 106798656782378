<app-form-expander [label]="'Event Log'" [isExpanded]="showEventLog">
	<div style="margin-top: 18px">
		<div class="meta-list-block">
			<div *ngFor="let entry of logEntries" class="meta-list-entry">
				<div class="row">
					<div class="col-12">
						<div class="meta-subsystem">
							<div
								class="badge badge-pill badge-dark level-badge"
								[class.level-debug]="entry.log_level === 'DEBUG'"
								[class.level-warn]="entry.log_level === 'WARN'"
								[class.level-error]="entry.log_level === 'ERROR'"
							>
								{{ entry.log_level }}
							</div>
							<div class="badge badge-pill badge-dark">{{ entry.log_subsystem }}</div>
						</div>
						<div class="meta-date">{{ dateFormatter(entry.created) }}</div>
						<div class="meta-time">{{ timeFormatter(entry.created) }}</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<div
							class="meta-message"
							[class.meta-debug]="entry.log_level === 'DEBUG'"
							[class.meta-warn]="entry.log_level === 'WARN'"
							[class.meta-error]="entry.log_level === 'ERROR'"
						>
							{{ entry.message }}
						</div>
					</div>
				</div>
				<hr />
			</div>
		</div>
		<div *ngIf="isDataLoaded && logEntries.length === 0" class="meta-info-block">
			<div class="no-data">No event details available</div>
		</div>
	</div>
</app-form-expander>
