<div class="card card-expanded viz-card">
	<div class="card-body">
		<div class="d-flex justify-content-between">
			<div>
				<!-- Use right-click for context menu -->
				<!-- <span #contextMenuTarget class="viz-card-title" [class.has-context-menu]="isInternalUser">{{ data.title }}</span> -->

				<!-- Use gear icon for context menu -->
				<span class="viz-card-title">
					<span>{{ data.title }}</span>
					<i
						#contextMenuTarget
						[hidden]="devDetect.isMobile() || devDetect.isTablet()"
						class="far fa-cog viz-config-icon link-text"
						(click)="openContextMenu($event, cm)"
					></i>
				</span>

				<div class="viz-card-count" [style.color]="data.countColor">{{ data.count }}</div>
			</div>
			<div class="d-flex align-items-center justify-content-center viz-card-icon-box">
				<i class="{{ data.iconClass }}"></i>
			</div>
		</div>
		<div class="viz-card-footer">
			<span *ngIf="data.subCount !== null" class="viz-card-sub-count" [style.color]="data.subCountColor">{{
				data.subCount >= 1 ? data.subCount : lessThanOne
			}}</span>
			<span class="viz-card-sub-text">{{ data.subText }}</span>
			<!-- Add a small pie chart to lower right -->
			<div *ngIf="data.count > 0" class="counter-pie float-right">
				<p-chart [type]="'pie'" [data]="data.pieData" [options]="data.pieOptions"></p-chart>
			</div>
		</div>
	</div>
</div>

<!-- Use right-click for context menu -->
<!-- <p-contextMenu *ngIf="isInternalUser && devDetect.isDesktop()" [target]="contextMenuTarget" [model]="contextMenu" /> -->

<!-- Use gear icon for context menu -->
<p-contextMenu #cm [target]="contextMenuTarget" [model]="contextMenu" />
