import { Component, AfterViewInit, Input, ViewChild, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import {
	CrudAction,
	JobsViewManager,
	SectionSwitcherConfig,
	SectionSwitcherTab,
	SectionListCountManager,
	JobsViewTabState,
	BatchActionManager,
} from '@app/models'
import { CoreService, DatabaseService } from '@app/services'

import { log, DateTimeHelper, DisplayHelper } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'
import { JobTableComponent } from './job-table/job-table.component'
import { environment } from '@env/environment'

@Component({
    selector: 'app-jobs',
    templateUrl: './jobs.component.html',
    styleUrls: ['./jobs.component.scss'],
    standalone: false
})
export class JobsComponent implements AfterViewInit, OnDestroy {
	// JobsCurrentViewType = JobsJobwState
	environment = environment
	accessHelper: AccessHelper
	siteIdsForSup: Array<number> = null

	// npDialogManager = new DialogManager('npDialog')

	@Input() filter = null
	filterNote: string

	showTooltips = false

	switcherConfig = new SectionSwitcherConfig()

	@ViewChild('jobTable') jobTable: JobTableComponent

	CrudAction = CrudAction

	defaultHelpInfo = ''

	isInternalUser = false

	constructor(
		route: ActivatedRoute,
		private router: Router,
		private coreSrvc: CoreService,
	) {
		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()

		const filterField = route.snapshot.params['filterField'] || null
		const filterId = parseInt(route.snapshot.params['filterId'], 10) || null
		if (filterField && filterId) {
			this.filter = { field: filterField, id: filterId }
			this.setupFilterNote(this.filter)
		} else {
			this.filter = null
		}
		this.setupAccessPermissions()
		this.setupTabSwitcher()
		this.setupDefaultHelpInfo()
	}

	get listCountManager(): SectionListCountManager {
		return this.coreSrvc.dbSrvc.jobSrvc.listCountManager
	}

	get viewManager(): JobsViewManager {
		return this.coreSrvc.dbSrvc.jobSrvc.viewManager
	}

	get batchManager(): BatchActionManager {
		return this.coreSrvc.dbSrvc.jobSrvc.batchManager
	}

	get isVacationMerged(): boolean {
		return this.coreSrvc.dbSrvc.mergeVacations
	}
	get isJobSiteMerged(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.getCompany().merge_jobsite
	}

	get subSectionTitle(): string {
		const currentView = this.viewManager.currentView
		return DisplayHelper.capitalizeSingleWord(currentView)
	}

	private setupDefaultHelpInfo() {
		if (!this.isJobSiteMerged) {
			this.defaultHelpInfo = `Every job site should have at least one job associated with it. The system will create a default job with the same name as the job site whenever you create a new job site. If you wish to track hours worked by individual jobs, as opposed to tracking hours worked by employee, you will need to create entries for each of the jobs you wish to track. The hours worked for each job are available in the Employee & Jobs Summary report. (see the Reports section, to create and modify reports).`
		}
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'job')
		const userPermissions = this.accessHelper.getPermissionsFor('job')
		const access = userPermissions.access
		const owner = userPermissions.owner

		const isManager = this.coreSrvc.dbSrvc.settingSrvc.isUserAManager()
		const managedSupIds = this.coreSrvc.dbSrvc.settingSrvc.getManagedUserIds()
		const isSupRestricted = !access.read && owner.read

		if (isSupRestricted) {
			const supId = this.coreSrvc.dbSrvc.settingSrvc.getMyUserId()
			this.siteIdsForSup = isManager
				? this.coreSrvc.dbSrvc.siteSrvc.getAllManagedJobSites(managedSupIds).map((js) => js.id)
				: this.coreSrvc.dbSrvc.siteSrvc.getJobSites(supId).map((js) => js.id)
		}
	}

	canPerformAction(action: CrudAction) {
		return this.accessHelper.canPerformAction(action, true)
	}

	get jobCount(): number {
		return this.coreSrvc.dbSrvc.jobSrvc.allCount(this.siteIdsForSup)
	}
	get activeCount(): number {
		return this.coreSrvc.dbSrvc.jobSrvc.activeCount(this.siteIdsForSup)
	}
	get inactiveCount(): number {
		return this.coreSrvc.dbSrvc.jobSrvc.inactiveCount(this.siteIdsForSup)
	}
	get allCount(): number {
		return this.coreSrvc.dbSrvc.jobSrvc.allCount(this.siteIdsForSup)
	}

	ngAfterViewInit() {
		this.loadData()
	}

	ngOnDestroy() {
		// this.npDialogManager.resetActions()
	}

	hasData(): boolean {
		return this.jobCount > 0
	}

	showNoDataTip(): boolean {
		if (this.jobCount === 0) {
			return true
		}
		return false
	}

	loadData() {
		this.coreSrvc.dbSrvc.readTable('location').then((locSuccess) => {
			this.coreSrvc.dbSrvc.bulkRead(['supervisor_group', 'job']).then((success) => {
				if (success) {
					if (this.jobTable) {
						this.jobTable.updateTable()
						this.coreSrvc.dbSrvc.readTable('notification_profile')
					}
				}
				// if (this.jobCount === 0) { this.showTooltips = true }
			})
		})
	}

	reloadTable() {
		this.jobTable.reloadTable()
	}

	lastUpdated(): string {
		const date = this.coreSrvc.dbSrvc.jobSrvc.lastUpdated
		return `Updated ${DateTimeHelper.formatTimeFromDate(date)}`
	}

	setupFilterNote(filter) {
		let note = ''
		const field = filter.field
		const id = filter.id

		if (field === 'employee_id') {
			note = this.coreSrvc.dbSrvc.empSrvc.employeeNameForId(id)
		}
		if (field === 'location_id') {
			note = this.coreSrvc.dbSrvc.siteSrvc.jobSiteNameForId(id)
		}
		this.filterNote = note
	}

	employeesExist(): boolean {
		return this.coreSrvc.dbSrvc.empSrvc.activeCount() > 0
	}
	jobSitesExist(): boolean {
		return this.coreSrvc.dbSrvc.siteSrvc.count() > 0
	}
	requirementsMet(): boolean {
		return this.jobSitesExist() || this.isJobSiteMerged
	}

	showJobsTable(): boolean {
		if (this.requirementsMet() || this.hasData()) {
			return true
		} else {
			return false
		}
	}

	toggleTooltips(): boolean {
		this.showTooltips = !this.showTooltips
		this.updateFixedHeader()
		return false
	}

	updateFixedHeader() {
		setTimeout(() => {
			const table: any = $('#jobsTable').DataTable()
			table.fixedHeader.adjust()
		}, 100)
	}

	togglePrefsPanel(): void {
		if (this.showJobsTable()) {
			this.jobTable.sectionPrefsDialogManager.isDialogVisible = true
		}
	}

	// Begin Tab Swticher Methods
	private setupTabSwitcher() {
		const activeTab = new SectionSwitcherTab('ACTIVE', 'Active')
		activeTab.count = () => this.activeCount
		const inactiveTab = new SectionSwitcherTab('INACTIVE', 'Inactive')
		inactiveTab.count = () => this.inactiveCount
		const allTab = new SectionSwitcherTab('ALL', 'All')
		allTab.count = () => this.allCount
		const config = new SectionSwitcherConfig([activeTab, inactiveTab, allTab])
		this.switcherConfig = config
		this.switcherConfig.setInitialTabById(this.viewManager.currentView)
	}

	public sectionTabClicked(tab: SectionSwitcherTab) {
		this.jobTable?.setPage(0)
		this.setDisplayState(tab.id as JobsViewTabState)
	}

	private setDisplayState(state: JobsViewTabState) {
		this.viewManager.currentView = state
		this.jobTable?.updateTable()
	}

	createRecord() {
		this.jobTable.createRecord()
	}

	public gotoTours() {
		this.router.navigate(['/admin/tours'])
	}
}
