import { Component, Input, OnInit } from '@angular/core'

import { DataAccessRequest, LocationException } from '@app/models'
import { DatabaseService } from '@app/services'

import { log, DateTimeHelper } from '@app/helpers'
import moment from 'moment-timezone'

@Component({
    selector: 'app-exceptions-location',
    templateUrl: './exceptions-location.component.html',
    styleUrls: ['./exceptions-location.component.scss'],
    standalone: false
})
export class ExceptionsLocationComponent implements OnInit {
	@Input() loc: LocationException
	infoBoxId: string
	visible = true

	constructor(private dbSrvc: DatabaseService) {}

	ngOnInit() {
		this.infoBoxId = 'locationInfoBox_' + this.loc.id
	}

	accept(): boolean {
		const request = new DataAccessRequest('none', 'location_exception_approve', null, this.loc)
		this.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			log('Accept Result', result)
			// this.processException()
			this.loadData()
		})
		return false
	}

	reject(): boolean {
		const request = new DataAccessRequest('none', 'location_exception_reject', null, this.loc)
		this.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			log('Accept Result', result)
			// this.processException()
			this.loadData()
		})
		return false
	}

	loadData() {
		this.dbSrvc.bulkRead(['location_exception'])
	}

	// private processException() {
	// 	this.visible = false
	// 	this.dbSrvc.bulkRead(['employee', 'location', 'job']).then(success => {
	// 		if (success) {
	// 			this.dbSrvc.excptSrvc.removeLocationById(this.loc.id)
	// 		}
	// 	})
	// }

	encounterNotes(): string {
		const encounterDate = DateTimeHelper.stripUtcTag(this.loc.created)
		const mom = moment(encounterDate)
		const dateString = mom.format('LLLL')
		const encounters = this.loc.usage
		const employeeId = this.loc.employee_id
		const employeeName = this.dbSrvc.empSrvc.employeeNameForId(employeeId)
		if (this.loc.created && this.loc.employee_id && this.loc.usage) {
			return 'First encounter by ' + employeeName + ' on ' + dateString + ' with a total of ' + encounters + ' encounters.'
		} else {
			return 'Could not determine encounter information.'
		}
	}
}
