import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ChecklistFormConfigRangeBasic, ChecklistFormItem } from '@app/models'

@Component({
    selector: 'app-checklist-form-item-range',
    templateUrl: './checklist-form-item-range.component.html',
    styleUrls: ['./checklist-form-item-range.component.scss'],
    standalone: false
})
export class ChecklistFormItemRangeComponent {
	@Input() item: ChecklistFormItem

	@Output() textResizer = new EventEmitter<HTMLTextAreaElement>()
	@Output() clearError = new EventEmitter<ChecklistFormItem>()
	@Output() showHelp = new EventEmitter<string>()

	get config(): ChecklistFormConfigRangeBasic {
		return this.item?.config as ChecklistFormConfigRangeBasic
	}
}
