export class NumberHelper {
	static formatPayRate(num: number) {
		if (!num) {
			return null
		}
		return Math.abs(parseFloat((Math.round(num * 100) / 100).toFixed(2)))
	}
	static formatDecimal(num: number, decimals: number) {
		if (!num) {
			return null
		}
		return Math.abs(parseFloat((Math.round(num * 100) / 100).toFixed(decimals)))
	}
	static formatInteger(num: number) {
		if (!num) {
			return null
		}
		const numStr = `${num}`
		return parseInt(numStr, 10)
	}
}
