import { DatabaseService } from '@app/services/backend/database.service'

export class ContactsFormatter {
	public static makeLinkedRecordsButton(dbSrvc: DatabaseService, bridgeTableName: string, dtFilterText: string, id: number, type: string): string {
		const filterText = dtFilterText?.toLowerCase() ?? ''
		let recordsText = ''
		let recordCount = 0
		switch (type) {
			case 'organization':
				recordCount = dbSrvc.contactSrvc.getContactById(id).vendor_client_contact.length
				recordsText = dbSrvc.contactSrvc
					.getContactById(id)
					.vendor_client_contact.map((link) => {
						return link.details + dbSrvc.orgSrvc.getOrganizationById(link.vendor_client_id)?.name
					})
					.join(',')
					.toLowerCase()
				break
		}

		const filterClass = filterText && recordsText.toLowerCase().includes(filterText) ? 'table-modal-btn-match' : ''
		const recordsTextHtml = recordCount > 0 ? `<div style="display:none;">${recordsText}</div>` : ''

		// return `<button onclick="${bridgeTableName}.showLinkedRecords(${id}, '${type}')" class="btn btn-sm btn-default table-modal-btn ${filterClass}" style="width: 105px">${
		// 	recordCount === 1 ? `1 record` : recordCount + ` records`
		// }</button>${recordsTextHtml}`

		return recordCount > 0
			? `<button onclick="${bridgeTableName}.showLinkedRecords(${id}, '${type}')" class="btn btn-sm btn-default table-modal-btn table-modal-btn-hl ${filterClass}"">${recordCount}</button>${recordsTextHtml}`
			: `<button onclick="${bridgeTableName}.showLinkedRecords(${id}, '${type}')" class="btn btn-sm btn-default table-modal-btn ${filterClass}"><i class="fa fa-plus"></i></button>${recordsTextHtml}`
	}
}

export class OrganizationFormatter {
	public static makeLinkedRecordsButton(dbSrvc: DatabaseService, bridgeTableName: string, dtFilterText: string, id: number, type: string): string {
		const filterText = dtFilterText?.toLowerCase() ?? ''
		let recordsText = ''
		let recordCount = 0
		switch (type) {
			case 'job':
				recordCount = dbSrvc.jobSrvc.getJobsForOrganizationId(id).length
				recordsText = dbSrvc.jobSrvc
					.getJobsForOrganizationId(id)
					.map((job) => job.description)
					.join(',')
					.toLowerCase()
				break
			case 'contact':
				recordCount = dbSrvc.orgSrvc.getOrganizationById(id).vendor_client_contact.length
				recordsText = dbSrvc.orgSrvc
					.getOrganizationById(id)
					.vendor_client_contact.map((link) => {
						return dbSrvc.contactSrvc.getContactById(link.contact_id)?.name + link.details
					})
					.join(',')
					.toLowerCase()
				break
		}

		const filterClass = filterText && recordsText.toLowerCase().includes(filterText) ? 'table-modal-btn-match' : ''
		const recordsTextHtml = recordCount > 0 ? `<div style="display:none;">${recordsText}</div>` : ''

		return recordCount > 0
			? `<button onclick="${bridgeTableName}.showLinkedRecords(${id}, '${type}')" class="btn btn-sm btn-default table-modal-btn table-modal-btn-hl ${filterClass}">${recordCount}</button>${recordsTextHtml}`
			: type === 'job'
				? ''
				: `<button onclick="${bridgeTableName}.showLinkedRecords(${id}, '${type}')" class="btn btn-sm btn-default table-modal-btn ${filterClass}"><i class="fa fa-plus"></i></button>${recordsTextHtml}`
	}
}
