<div class="section-table">
	<table id="ivrTable" class="stripe nowrap" cellspacing="0" width="100%"></table>
	<i id="clear-search-icon" (click)="clearSearch()" class="fa fa-times-circle-o"></i>
	<app-table-footer-buttons
		[filename]="'ivr_debug_records'"
		[newBtnLabel]="''"
		[exportBtnLabel]="'Export Records'"
		[table]="'ivrTable'"
		[marginTop]="'15px'"
	></app-table-footer-buttons>
</div>

<!-- <p-dialog
	styleClass="p-dialog-noheader"
	[(visible)]="playAudioAction.showDialog"
	[modal]="true"
	[style]="{ width: '250px' }"
	[showHeader]="false"
	[baseZIndex]="5000"
	[dismissableMask]="true"
	(onHide)="stopPlayingAudio()"
>
	<div class="tts-default-font" style="text-align: center; color: #566978; font-weight: bold">
		<i class="fas fa-loader fa-spin"></i> Playing Audio...
	</div>
</p-dialog> -->

<app-basic-modal
	[(visible)]="viewLogAction.showDialog"
	modalId="ivCallLogModal"
	[modalHeader]="viewLogAction.header"
	[modalFooter]="viewLogAction.footer"
	[modalWidth]="450"
>
	<app-ivr-log-data *ngIf="viewLogAction.showDialog" [logId]="viewLogAction.callLogId" [timezone]="viewManager.timezone"></app-ivr-log-data>
</app-basic-modal>
