<div class="public-container">
	<div class="adp-login-block">
		<div style="height: 60px">
			<div *ngIf="!loginFailed" class="adp-spinner"><i class="fas fa-loader fa-spin"></i></div>
		</div>

		<div *ngIf="!loginFailed">
			<h3>Authenticating via ADP...</h3>
		</div>

		<div *ngIf="loginFailed">
			<h3>Authentication Failed</h3>
			<div style="margin-top: 12px">
				<a href="mailto:support@telephonetimesheets.com">Email Support</a> / <a href="tel:+18882508463">1-888-250-8463</a>
			</div>
			<div style="margin-top: 32px"><a [routerLink]="['/home/login']" class="btn btn-outline-secondary">Telephone Timesheets Login</a></div>

			<!-- <div class="link-text" (click)="loginViaAdp()">Login with ADP</div> -->
		</div>

		<!-- <div *ngIf="false" class="view-box">
			<div style="width: 550px; margin: auto">
				<input class="form-control" [(ngModel)]="user" placeholder="enter username" />
			</div>
			<div style="width: 550px; margin: auto">
				<textarea class="form-control wrapword" [(ngModel)]="token" style="height: 400px; margin-top: 10px" placeholder="enter token"></textarea>
			</div>
			<div style="margin-top: 10px">
				<button class="btn btn-sm btn-secondary" (click)="getToken()">Get Token</button>
				<button class="btn btn-sm btn-success" (click)="authBtnClicked()" style="margin-left: 16px">Authenticate</button>
				<button class="btn btn-sm btn-success" (click)="readTestBtnClicked()" style="margin-left: 16px">Read Test</button>
			</div>
		</div> -->
	</div>
</div>
