import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { log } from '@app/helpers'
import { ChatBotMessage, DataAccessRequest } from '@app/models'
import { CognitoUtil, CoreService } from '@app/services'
import { LexRuntimeV2 } from 'aws-sdk'
import { Subscription } from 'rxjs'
@Component({
    selector: 'app-chat-bot',
    templateUrl: './chat-bot.component.html',
    styleUrls: ['./chat-bot.component.scss'],
    standalone: false
})
export class ChatBotComponent implements OnInit, AfterViewInit, OnDestroy {
	newMessage: string = ''
	lexBotConfig: LexBotDefinition
	isBotReady = false
	subs = new Subscription()

	constructor(
		private coreSrvc: CoreService,
		private cognito: CognitoUtil,
		private sanitizer: DomSanitizer,
	) {
		this.coreSrvc.bannerSrvc.showBanners = false
		this.coreSrvc.displaySrvc.showFooter = false
		this.coreSrvc.cbSrvc.messageHasBeenPosted.subscribe((message) => this.handleMessagePosting(message))
		this.setupComponent()
	}

	get hasMessages(): boolean {
		return this.messages.length > 0
	}
	get messages(): Array<ChatBotMessage> {
		return this.coreSrvc.cbSrvc.getMessages()
	}
	get lastMessage(): ChatBotMessage {
		const length = this.messages.length
		if (length === 0) return null
		return this.messages[length - 1]
	}

	// get isSessionClosed(): boolean {
	// 	const lastMsg = this.lastMessage
	// 	if (lastMsg) {
	// 		return lastMsg.text.includes('Session Closed, Thank You')
	// 	} else {
	// 		return false
	// 	}
	// }

	public ngOnInit(): void {}

	public ngAfterViewInit(): void {
		const lastMsg = this.messages[this.messages.length - 1]
		if (lastMsg) this.handleMessagePosting(lastMsg)
		setTimeout(() => {
			$('#prompt-box').click()
		}, 125)
	}

	public ngOnDestroy(): void {
		this.coreSrvc.bannerSrvc.showBanners = true
		this.coreSrvc.displaySrvc.showFooter = true
		this.subs.unsubscribe()
	}

	private setupComponent() {
		// Get setup info

		const request = new DataAccessRequest('bot_definition')
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			const data = result.data[0]
			if (data) {
				const cognitoId = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser().cognito_id
				const lexBotConfig = new LexBotDefinition(data)
				lexBotConfig.cognitoId = cognitoId

				const cognitoCreds = this.cognito.cognitoCreds
				const accessKeyId = cognitoCreds.accessKeyId
				const secretAccessKey = cognitoCreds.secretAccessKey

				log('ACCESSKEYID/SECRETACCESSKEY', accessKeyId, secretAccessKey)

				// const lexRuntime = new LexRuntimeV2({
				// 	region: 'us-east-1', // Set your Bot Region
				// })

				// log('LEXRUNTIME', lexRuntime)
				log('LEXBOTCONFIG', lexBotConfig)
				this.lexBotConfig = lexBotConfig
				// this.lexRuntime = lexRuntime
				setTimeout(() => {
					this.coreSrvc.zone.run(() => {
						this.isBotReady = true
					})
				}, 750)
			}
		})
	}

	public sendMessage(): void {
		if (this.newMessage.trim() !== '') {
			const message = new ChatBotMessage('LOCAL', this.newMessage)
			this.coreSrvc.cbSrvc.postMessage(message)
			this.newMessage = ''

			this.coreSrvc.displaySrvc.startSectionLoader()

			// Refresh credentials and setup lex conversation
			this.coreSrvc.loginSrvc.refresh().then(() => {
				const lexParams = {
					botAliasId: this.lexBotConfig.bot_alias, // Enter the botAliasId
					botId: this.lexBotConfig.bot_id, // Enter the botId
					localeId: 'en_US',
					sessionId: this.lexBotConfig.cognitoId,
					text: message.text,
				}

				const lexRuntime = new LexRuntimeV2({
					region: 'us-east-1', // Set your Bot Region
				})

				log('PARAMS', lexParams)

				lexRuntime.recognizeText(lexParams, (err, data) => {
					if (err) {
						// an error occurred
						log(err, err.stack)
						this.coreSrvc.displaySrvc.stopSectionLoader()
						this.coreSrvc.notifySrvc.notify(
							'error',
							'Unknown Error',
							`We're sorry, an error was encountered trying to process your request. If you continue to encounter this message, please contact support.`,
						)
					} else {
						// successful response
						log('LEXRESPONSE', data)
						const response = new LexResponse(data as any)
						const messages = response.messages
						log('LEXMESSAGES', messages)
						for (const message of messages) {
							const sanatizedReply = this.sanitizeReply(message.content)
							const reply = new ChatBotMessage('REMOTE', sanatizedReply)
							this.coreSrvc.cbSrvc.postMessage(reply)
							log('REPLY', reply)
							this.coreSrvc.displaySrvc.stopSectionLoader()
						}
					}
				})
			})
		}
	}

	public sanitize(htmlString: string) {
		return this.sanitizer.bypassSecurityTrustHtml(htmlString)
	}

	public sanitizeReply(str: string) {
		return this.convertMarkdownLinksToHtml(str)
	}

	public convertMarkdownLinksToHtml(textWithMarkdown: string): string {
		const regex = /\[(.*?)\]\((.*?)\)/g
		const htmlText = textWithMarkdown.replace(regex, '<a href="$2" target="_chatresults">$1</a>')
		return htmlText
	}

	private handleMessagePosting(message: ChatBotMessage) {
		setTimeout(() => {
			const id = message.id
			this.coreSrvc.displaySrvc.bringIntoView(id)
		}, 125)
	}
}

export class LexBotDefinition {
	id: number
	bot_id: string
	bot_alias: string
	cognitoId: string

	constructor(record: LexBotDefinition) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
		}
	}
}

export class LexResponse {
	messages: Array<any> = []

	constructor(record: LexResponse) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
		}
	}
}
