import { Component, OnInit, ViewChild } from '@angular/core'

import { AccessPermission, AccessPermissions, OwnerPermission, CrudAction, SectionListCountManager } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'

import { log, DateTimeHelper } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'

@Component({
    selector: 'app-job-sites',
    templateUrl: './job-sites.component.html',
    styleUrls: ['./job-sites.component.scss'],
    standalone: false
})
export class JobSitesComponent implements OnInit {
	supId: number
	isManager = false
	managedSiteIds: Array<number> = []
	accessHelper: AccessHelper

	showTooltips = false
	@ViewChild('sitesTable') sitesTable

	CrudAction = CrudAction

	constructor(private coreSrvc: CoreService) {
		this.setupAccessPermissions()
	}

	get listCountManager(): SectionListCountManager {
		return this.coreSrvc.dbSrvc.siteSrvc.listCountManager
	}
	get siteCount(): number {
		return this.isManager ? this.coreSrvc.dbSrvc.siteSrvc.managedCount(this.managedSiteIds) : this.coreSrvc.dbSrvc.siteSrvc.count(this.supId)
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'site')
		this.isManager = this.coreSrvc.dbSrvc.settingSrvc.isUserAManager()
		this.managedSiteIds = this.coreSrvc.dbSrvc.settingSrvc.getManagedUserIds()
		const userPermissions = this.accessHelper.getPermissionsFor('site')
		const access = userPermissions.access
		const owner = userPermissions.owner
		if (!access.read && owner.read) {
			this.supId = this.coreSrvc.dbSrvc.settingSrvc.getMyUserId()
		} else {
			this.supId = null
		}
	}

	canPerformAction(action: CrudAction) {
		return this.accessHelper.canPerformAction(action, true)
	}

	ngOnInit() {
		this.loadData()
	}

	showNoDataTip(): boolean {
		if (this.siteCount === 0) {
			return true
		}
		return false
	}

	loadData() {
		this.coreSrvc.dbSrvc.readTable('location').then((success) => {
			this.coreSrvc.dbSrvc.bulkRead(['supervisor_group']).then((bulkSuccess) => {
				if (success) {
					this.sitesTable.updateTable()
				}
			})
		})
	}

	lastUpdated(): string {
		const date = this.coreSrvc.dbSrvc.siteSrvc.lastUpdated
		return `Updated ${DateTimeHelper.formatTimeFromDate(date)}`
	}

	toggleTooltips(): boolean {
		this.showTooltips = !this.showTooltips
		this.updateFixedHeader()
		return false
	}

	updateFixedHeader() {
		setTimeout(() => {
			const table: any = $('#jobSitesTable').DataTable()
			table.fixedHeader.adjust()
		}, 100)
	}

	togglePrefsPanel(): void {
		this.sitesTable.sectionPrefsDialogManager.isDialogVisible = true
	}

	createRecord() {
		this.sitesTable.createRecord()
	}
}
