import { DateTimeHelper } from '@app/helpers'
import { AppVersion } from './version'

export type EnrollmentDocType = 'TOS' | 'PRIVACY'

export class EnrollmentRecord {
	id: number = null
	company_id: number = null
	created: string = null
	user_type: 'ADMIN' | 'EMPLOYEE' = 'EMPLOYEE'
	v_tos: string = AppVersion.vTos
	v_privacy: string = AppVersion.vPrivacy
	phone_e164: string = null
	ip_address: string = null
	employee_id: number = null
	employee_first: string = null
	employee_last: string = null
	user_id: number = null
	user_first: string = null
	user_last: string = null

	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			// Fix date entries by stripping off UTC
			this.created = DateTimeHelper.stripUtcTag(this.created)
		}
	}
}

export interface IEnrollmentVersionable {
	v_tos: string
	v_privacy: string
}

export class EnrollmentNeedsUpdateStatus {
	vTos: boolean
	vPrivacy: boolean
	constructor(vTos: boolean, vPrivacy: boolean) {
		this.vTos = vTos
		this.vPrivacy = vPrivacy
	}

	needsUpdate(): boolean {
		return this.vTos || this.vPrivacy
	}
}
