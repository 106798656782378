<div style="max-width: 100%; margin: 0 auto">
	<div *ngIf="!isRecordValid" class="edit-record-not-found">
		<div>Record</div>
		<div>Not Found</div>
	</div>

	<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

	<div [hidden]="isUpdating">
		<form *ngIf="showForm" class="edit-form-block" novalidate [formGroup]="vacForm" autocomplete="off">
			<!-- Employee -->
			<div class="form-group form-group-lg" [class.input-required]="showEmployeeRequiredTag()">
				<div class="input-label">
					<strong>Employee</strong>
					<span *ngIf="showEmployeeRequiredTag()" class="float-right">(required)</span>
				</div>
				<p-select
					appScrollLockSelect
					id="jobSite"
					[options]="employeesDropdown"
					formControlName="employee_id"
					[filter]="true"
					filterBy="label,data.external_id"
					[required]="true"
				></p-select>
			</div>

			<div class="form-group radio-wrap">
				<div class="form-check form-check-inline">
					<input
						class="form-check-input"
						type="radio"
						id="singleDate"
						[value]="false"
						[(ngModel)]="isMultiDay"
						[ngModelOptions]="{ standalone: true }"
					/>
					<label class="form-check-label" [class.tts-hl-2]="!isMultiDay" for="singleDate">Single Day</label>
				</div>
				<div class="form-check form-check-inline">
					<input
						class="form-check-input"
						type="radio"
						id="multiDate"
						[value]="true"
						[(ngModel)]="isMultiDay"
						[ngModelOptions]="{ standalone: true }"
					/>
					<label class="form-check-label" [class.tts-hl-2]="isMultiDay" for="multiDate">Multiple Days</label>
				</div>
			</div>

			<!-- Start Date -->
			<div class="options-block" [class.input-required]="!vacForm.get('start_date').value">
				<div>
					<div *ngIf="!vacForm.get('start_date').value" class="float-right">(required)</div>
					<div class="options-title">{{ isMultiDay ? 'Start Date' : 'Date' }}</div>
				</div>
				<app-date-time-picker
					[currentDate]="vacForm.get('start_date').value"
					[pickerType]="'DATE'"
					[pickerLabel]="'Start Date'"
					[hour12Timer]="true"
					[format]="'MMM Do, YYYY'"
					(pickerDidClose)="vacForm.get('start_date').setValue($event); handleStartDateChange()"
				>
				</app-date-time-picker>
			</div>

			<!-- End Date -->
			<div *ngIf="isMultiDay" class="options-block" [class.input-required]="!vacForm.get('end_date').value">
				<div>
					<div *ngIf="!vacForm.get('end_date').value" class="float-right">(required)</div>
					<div class="options-title">End Date</div>
				</div>
				<app-date-time-picker
					[currentDate]="vacForm.get('end_date').value"
					[pickerType]="'DATE'"
					[pickerLabel]="'End Date'"
					[hour12Timer]="true"
					[format]="'MMM Do, YYYY'"
					(pickerDidClose)="vacForm.get('end_date').setValue($event)"
				>
				</app-date-time-picker>
			</div>

			<div [hidden]="hideNote()" class="alert alert-warning">End date must be later than start date</div>

			<div class="options-block">
				<!-- <div *ngIf="!userForm.get('role').value" class="input-required float-right">(required)</div> -->
				<div class="options-title">Type</div>
				<p-select appScrollLockSelect id="typeDropdown" [options]="typeOptions" formControlName="type"></p-select>
			</div>

			<div
				*ngIf="vacForm.get('type').value !== 'UNPAID'"
				class="options-block"
				[class.input-required]="vacForm.get('type').value !== 'UNPAID' && !vacForm.get('hours').value"
			>
				<input
					class="float-right"
					style="width: 60px; margin-left: 20px; text-align: center"
					id="hours"
					type="text"
					class="form-control float-right"
					formControlName="hours"
				/>
				<div>
					<div style="line-height: 1em">
						<strong>Hours</strong><span class="text-muted tsf-time-abbrv">(hr:min)</span>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('hoursOff')"></i>
						<br />
						<span class="tts-hl-2" style="font-size: 0.8em; font-variant: small-caps; font-weight: 700">{{
							isMultiDay ? 'per day' : 'total'
						}}</span>
					</div>
				</div>

				<div *ngIf="vacForm.get('hours').value && vacForm.get('start_date').value && vacForm.get('end_date').value" style="margin-top: 18px">
					<div class="float-right" style="width: 60px; height: 38px; color: chocolate; text-align: center">
						<strong>{{ isDecimalInHours ? 'Invalid' : totalHours }}</strong>
					</div>
					<div><strong>Total Hours</strong>&nbsp;<span class="text-muted">(hr:min)</span></div>
				</div>
			</div>

			<div *ngIf="isDecimalInHours" class="alert alert-warning" style="font-weight: 600; text-align: center">
				Please specify Hours in hr:min format
			</div>

			<!-- Approval Status -->
			<div class="approval-wrap">
				<div class="form-group form-group-lg">
					<div class="input-label">
						<strong class="float-right tts-hl-2" style="font-variant: small-caps" *ngIf="vacForm.get('approval_state').value === 'PENDING'"
							>pending</strong
						>
						<strong
							class="float-right tts-hl-2"
							style="color: green; font-variant: small-caps"
							*ngIf="vacForm.get('approval_state').value === 'DIRECT' || vacForm.get('approval_state').value === 'APPROVED'"
							>approved</strong
						>
						<strong
							class="float-right"
							style="color: firebrick; font-variant: small-caps"
							*ngIf="vacForm.get('approval_state').value === 'REJECTED'"
							>denied</strong
						>
						<strong>Approval Status</strong>
					</div>
					<div style="height: 40px">
						<p-select
							*ngIf="vacForm.get('approval_state').value !== 'PENDING'"
							appScrollLockSelect
							id="jobSite"
							[options]="approvalStatusOptions"
							formControlName="approval_state"
							[required]="true"
							(onChange)="handleApprovalStateChange()"
						></p-select>
						<div *ngIf="vacForm.get('approval_state').value === 'PENDING'" class="d-flex justify-content-evenly" style="height: 36px">
							<button
								class="btn btn-sm btn-outline-danger btn-block"
								style="margin-right: 4px"
								(click)="vacForm.get('approval_state').setValue('REJECTED'); handleApprovalStateChange()"
							>
								Deny
							</button>
							<button
								class="btn btn-sm btn-outline-success btn-block"
								style="margin-left: 4px"
								(click)="vacForm.get('approval_state').setValue('APPROVED'); handleApprovalStateChange()"
							>
								Approve
							</button>
						</div>
					</div>
				</div>

				<div *ngIf="vacForm.get('employee_id').value">
					<div class="options-block" style="margin-bottom: 10px">
						<div class="checkbox-item">
							<p-checkbox formControlName="notify_employee" [binary]="true"></p-checkbox>
							<strong (click)="toggleCheckbox('notify_employee')" class="has-pointer">Notify Employee</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('notify_employee')"></i>
						</div>
					</div>

					<hr style="margin: 15px 0 20px 0" />
				</div>

				<div>
					<div>
						<div *ngIf="managedByName" class="managed-value float-right" style="color: green">{{ managedByName }}</div>
						<div *ngIf="!managedByName" class="managed-value float-right" style="color: chocolate">Unassigned</div>
						<div class="options-title-inline">Managed By</div>
					</div>
					<div>
						<div *ngIf="lastManagedDate" class="managed-value float-right">{{ lastManagedDate | dateFormatter: managedDateFormatter }}</div>
						<div *ngIf="!lastManagedDate" class="managed-value float-right">{{ lastManagedDate | dateFormatter: managedDateFormatter }}</div>
						<div class="options-title-inline">Last Modified</div>
					</div>
				</div>
			</div>

			<!-- Employee Request Notes -->
			<div *ngIf="!isNew && vacForm.get('emp_notes').value" style="margin: 20px 0px">
				<div class="options-title-inline">Employee Request Notes</div>
				<div>{{ vacForm.get('emp_notes').value }}</div>
			</div>

			<!-- Notes -->
			<div class="options-block">
				<div class="options-title-inline">Admin Notes</div>
				<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('pauseNotes')"></i> -->
				<div>
					<textarea class="options-textarea" formControlName="notes"></textarea>
				</div>
			</div>

			<!-- Begin Advanced Options -->
			<hr style="margin-top: 28px" />
			<div style="font-size: 1.4em; cursor: pointer" (click)="showAdvancedOptions = !showAdvancedOptions">
				<div class="float-right" style="color: gray">
					<i *ngIf="!showAdvancedOptions" class="fa fa-chevron-right"></i>
					<i *ngIf="showAdvancedOptions" class="fa fa-chevron-down"></i>
				</div>
				<div class="tts-hl-2">Advanced Options</div>
			</div>

			<div *ngIf="showAdvancedOptions" style="margin-top: 18px">
				<!-- Job Dropdown -->
				<div class="options-block">
					<!-- <div *ngIf="!vacForm.get('job_id').value" class="input-optional float-right">(optional)</div> -->
					<div class="options-title-inline">Linked Job</div>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('job_id')"></i>
					<p-select
						appScrollLockSelect
						id="jobOptions"
						placeholder="Select Job"
						[options]="jobOptions"
						formControlName="job_id"
						[filter]="true"
						filterBy="label"
					></p-select>
				</div>
				<!-- External ID -->
				<div class="options-block">
					<div class="options-title-inline">External ID</div>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('externalID')"></i>
					<!-- <div *ngIf="!vacForm.get('external_id').value" class="input-required float-right">(required)</div> -->
					<input type="text" class="form-control" autocomplete="off" formControlName="external_id" />
				</div>

				<div *ngIf="vacForm.get('approval_state').value !== 'REJECTED'" class="checkbox-item d-flex justify-content-between">
					<div class="checkbox-wrap">
						<p-checkbox formControlName="schedule_time_off" [binary]="true"></p-checkbox>
					</div>
					<div class="checkbox-label-wrap">
						<strong (click)="toggleCheckbox('schedule_time_off')" class="has-pointer">Show time off entry on shift view</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('timeOff')"></i>
					</div>
				</div>
			</div>
			<!-- End Advanced Options -->

			<div [hidden]="true">
				<br />
				<button (click)="onCancel()" class="btn btn-sm btn-danger">Cancel</button>
				<button type="submit" [disabled]="isFormValid()" class="btn btn-sm btn-success tsf-save-btn">Save</button>
				<div *ngIf="vacation && vacation.updated" class="audit-link">
					<a [routerLink]="['/admin/auditlog', 'vacation', vacation.id]"><i class="fa fa-history"></i>&nbsp;Show History</a>
				</div>
			</div>
		</form>
	</div>
</div>

<div *ngIf="false" class="container">
	<div class="alert alert-info" style="margin-top: 25px">
		{{ vacForm.value | json }}
		<br />
		StartDate: {{ vacForm.value.start_date }} <br />
		Date Range Valid: {{ dateRangeValid() }}
	</div>
</div>

<p-dialog
	id="schedule-conflicts-dialog"
	styleClass="p-dialog-noheader p-dialog-whitebackground c2c-trigger-host"
	header="Event Dialog"
	[(visible)]="scheduleConflicts.showDialog"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
	transitionOptions="0ms"
>
	<div class="sched-conflict-dialog-content">
		<div class="alert alert-danger" style="text-align: center; font-weight: 600; margin-bottom: 18px">Schedule Conflicts</div>
		<div class="sched-conflict-list hide-scrollbars">
			<div *ngFor="let conflict of scheduleConflicts.list; let idx = index">
				<div style="margin-bottom: 4px">
					<hr *ngIf="idx !== 0" />
					<div>
						<div class="float-right link-text" (click)="showScheduleConflict(conflict)">
							{{ conflict.jobDate | dateTzFormatter: conflict.tz : 'MMM Do' }}
						</div>
						<div class="conflict-time">
							<span>{{ conflict.schedLog.job_start | dateTzFormatter: conflict.tz : conflict.timeFormat }}</span>
							<span> - </span>
							<span>{{ conflict.schedLog.job_end | dateTzFormatter: conflict.tz : conflict.timeFormat }}</span>
							<span>&nbsp;</span>
							<span class="tts-hl-orange">{{ conflict.tzAbrev }}</span>
						</div>
					</div>
					<div>
						<div class="conflict-job">{{ conflict.jobName }}</div>
					</div>
				</div>
			</div>
		</div>
		<button *ngIf="showOverrideConflictsBtn" class="btn btn-sm btn-block btn-success conflict-override-btn" (click)="overrideConflictsBtnClicked()">
			Override All Conflicts
		</button>
	</div>
</p-dialog>
