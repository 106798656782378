import { Component, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { ActivatedRoute } from '@angular/router'

import { EmployeeRecord } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'

import { log } from '@app/helpers'

@Component({
    selector: 'app-employee-delete',
    templateUrl: './employee-delete.component.html',
    styleUrls: ['./employee-delete.component.scss'],
    standalone: false
})
export class EmployeeDeleteComponent implements OnInit {
	employeeId: number
	employeeName: string
	isDeleting = false

	scheduleViewRouterLink = null

	private employee: EmployeeRecord

	constructor(
		route: ActivatedRoute,
		private location: Location,
		private coreSrvc: CoreService,
	) {
		this.employeeId = parseInt(route.snapshot.params['employeeId'], 10) || null
		this.employee = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(this.employeeId)
		if (this.employee) {
			this.employeeName = this.employee.name
		}

		// Setup router link to view schedules
		this.scheduleViewRouterLink = ['/admin/scheduler/filter', 'employee_id', this.employeeId]
	}

	get isActive(): boolean {
		return this.employee?.active
	}

	ngOnInit() {}

	employeeExists(): boolean {
		return this.employee != null
	}
	schedulesExist(): boolean {
		return this.schedulesCount() > 0
	}
	requirementsMet(): boolean {
		return !this.schedulesExist()
	}
	schedulesCount(): number {
		return this.coreSrvc.dbSrvc.schedulerSrvc.getEntriesForEmpId(this.employeeId).length
	}

	deleteEmployee(): boolean {
		this.isDeleting = true
		this.coreSrvc.dbSrvc.deleteRecord('employee', this.employeeId).then((empSuccess) => {
			if (empSuccess) {
				this.coreSrvc.zone.run(() => {
					this.location.back()
				})
			} else {
				this.isDeleting = false
			}
		})
		return false
	}

	onCancel(): boolean {
		this.location.back()
		return false
	}
}
