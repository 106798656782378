import { JobRecord } from '@app/models/job'
import { DateTimeHelper } from '@app/helpers/datetime'
import { NotificationProfile } from '@app/models'
import { DatabaseService } from '@app/services'

export class JobTableFormatter {
	static makeJobDetails(job: JobRecord) {
		const result = job.job_details ? job.job_details : ''
		const requirePhotoCheckin = job.require_photo_checkin
		const photoCheckinText = requirePhotoCheckin ? 'Checkin requires photo. ' : ''
		const requirePhotoCheckpoint = job.chkpts_require_photo
		const photoCheckpointText = requirePhotoCheckpoint ? 'Checkpoint requires photo. ' : ''
		const requirePhotoCheckout = job.require_photo_checkout
		const photoCheckoutText = requirePhotoCheckout ? 'Checkout requires photo.' : ''
		const requiredPhotosText = `${photoCheckinText}${photoCheckpointText}${photoCheckoutText}`
		const showIcon = requirePhotoCheckin || requirePhotoCheckpoint || requirePhotoCheckout
		const photoIcon = showIcon
			? `<i title="${requiredPhotosText}" class="fa fa-picture-o item-tooltip" style="margin-right:2px;color:chocolate;"></i> `
			: ''
		return result || showIcon ? `<div class="table-note-block">${photoIcon}${result}</div>` : ''
	}

	static makeNotificationForEmployee(profile: NotificationProfile) {
		const notifyEmpPreShift = profile.call_employee_preshift
		const notifyEmpPreShiftMinutes = DateTimeHelper.formatDurationInMinutes(profile.emp_notification_preshift_interval)
		const notifyEmpPreShiftDelay = DateTimeHelper.formatMinutestAsHrsAndMinutes(notifyEmpPreShiftMinutes)
		const notifyEmpPreShiftText = notifyEmpPreShift ? `Employee will be notified ${notifyEmpPreShiftDelay} before their upcoming shift. ` : ''
		// : 'Employee will NOT be notified for their upcoming shift.'

		const notifyEmpLateIn = profile.call_employee_late_in
		const notifyEmpLateInDelay = DateTimeHelper.formatDurationInMinutes(profile.emp_notification_delay_late_in)
		const notifyEmpLateInText = notifyEmpLateIn ? `Employee will be notified when they are ${notifyEmpLateInDelay} min late checking in. ` : ''
		// : 'Employee will NOT be notified for late check in.'

		const notifyEmpCheckInIcon = `<i title="${notifyEmpPreShiftText}${notifyEmpLateInText}" class="fa fa-sign-in item-tooltip job-icon-notify-in ${
			notifyEmpLateIn || notifyEmpPreShift ? 'hl-notify-green' : 'hl-notify-gray'
		}" aria-hidden="true"></i>`

		const notifyEmpLateOut = profile.call_employee_late_out
		const notifyEmpLateOutDelay = DateTimeHelper.formatDurationInMinutes(profile.emp_notification_delay_late_out)
		const notifyEmpLateOutText = notifyEmpLateOut ? `Employee will be notified when they are ${notifyEmpLateOutDelay} min late checking out. ` : ''
		// : 'Employee will NOT be notified for late check out.'

		const notifyEmpCheckOutIcon = `<i title="${notifyEmpLateOutText}" class="fa fa-sign-out item-tooltip job-icon-notify-out ${
			notifyEmpLateOut ? 'hl-notify-green' : 'hl-notify-gray'
		}" aria-hidden="true"></i>`

		const notifyEmpCheckpoint = profile.call_employee_checkpoint
		const notifyEmpCheckpointDelay = DateTimeHelper.formatDurationInMinutes(profile.emp_notification_delay_checkpoint)
		const notifyEmpCheckpointFreq = DateTimeHelper.formatDurationInMinutes(profile.checkpoint_frequency)
		const checkpointFreqText = '' // notifyEmpCheckpoint && notifyEmpCheckpointFreq ? `Checkpoints required every ${notifyEmpCheckpointFreq} min. ` : ``
		const notifyEmpCheckpointText = notifyEmpCheckpoint
			? `${checkpointFreqText}Employee will be notified when they are ${notifyEmpCheckpointDelay} min late for checkpoint. `
			: ''
		// : 'Employee will NOT be notified for late checkpoint.'

		const notifyEmpCheckpointIconClass = `class="fa fa-map-marker item-tooltip job-icon-notify-cp ${
			notifyEmpCheckpoint ? 'hl-notify-green' : 'hl-notify-gray'
		}"`
		const notifyEmpCheckpointIcon = `<i title="${notifyEmpCheckpointText}" ${notifyEmpCheckpointIconClass} aria-hidden="true"></i>`

		return `${notifyEmpCheckInIcon}/${notifyEmpCheckOutIcon}/${notifyEmpCheckpointIcon}`
	}

	static makeNotificationForSupervisor(profile: NotificationProfile) {
		// Notify Supervisor on Employee Early Check In
		const notifySupEmpEarlyCheckIn = profile.call_supervisor_early_in || profile.sms_supervisor_early_in || profile.email_supervisor_early_in
		const notifySupEmpEarlyCheckInText = notifySupEmpEarlyCheckIn ? `Supervisor will be notified when employee checks in early. ` : ''
		// : 'Supervisor will NOT be notified when employee checks in early. '

		// Notify Supervisor at Employee Check In
		const notifySupEmpCheckIn = profile.call_supervisor_at_check_in || profile.email_supervisor_at_check_in || profile.sms_supervisor_at_check_in
		const notifySupEmpCheckInText = notifySupEmpCheckIn ? `Supervisor will be notified when employee checks in. ` : ''
		// : 'Supervisor will NOT be notified when employee checks in. '

		// Notify Supervisor for Employee Late Check In
		const notifySupEmpLateIn = profile.call_supervisor_late_in || profile.email_supervisor_late_in || profile.sms_supervisor_late_in
		const notifySupEmpLateInDelay = DateTimeHelper.formatDurationInMinutes(profile.sup_notification_delay_late_in)
		const notifySupEmpLateInText = notifySupEmpLateIn
			? `Supervisor will be notified when employee is ${notifySupEmpLateInDelay} min late checking in. `
			: ''
		// : 'Supervisor will NOT be notified when employee is late checking in.'

		const checkInIcon = `<i title="${notifySupEmpEarlyCheckInText}${notifySupEmpCheckInText}${notifySupEmpLateInText}" class="fa fa-sign-in item-tooltip job-icon-notify-in ${
			notifySupEmpEarlyCheckIn || notifySupEmpCheckIn || notifySupEmpLateIn ? 'hl-notify-green' : 'hl-notify-gray'
		}" aria-hidden="true"></i>`

		// Notify Supervisor at Employee Early Check Out
		const notifySupOnEarlyCheckOut =
			profile.call_supervisor_on_early_out || profile.email_supervisor_on_early_out || profile.sms_supervisor_on_early_out
		const notifySupOnEarlyOutDelay = DateTimeHelper.formatDurationInMinutes(profile.sup_notification_early_out_min_time)
		const earlyCheckOutDelay = notifySupOnEarlyCheckOut && notifySupOnEarlyOutDelay ? ` by ${notifySupOnEarlyOutDelay} or more min` : ``
		const earlyCheckOutText = notifySupOnEarlyCheckOut ? `Supervisor will be notified when employee checks out early${earlyCheckOutDelay}. ` : ''
		// : 'Supervisor will NOT be notified when employee checks out early. '

		// Notify Supervisor at Employee Late Check Out
		const notifySupAtLateCheckOut = profile.call_supervisor_late_out || profile.email_supervisor_late_out || profile.sms_supervisor_late_out
		const notifySupAtLateOutDelay = DateTimeHelper.formatDurationInMinutes(profile.sup_notification_delay_late_out)
		const lateCheckOutText = notifySupAtLateCheckOut
			? `Supervisor will be notified when employee is ${notifySupAtLateOutDelay} min late checking out. `
			: ''
		// : 'Supervisor will NOT be notified when employee is late checking out.'

		const checkOutIcon = `<i title="${earlyCheckOutText}${lateCheckOutText}" class="fa fa-sign-out item-tooltip job-icon-notify-out ${
			notifySupOnEarlyCheckOut || notifySupAtLateCheckOut ? 'hl-notify-green' : 'hl-notify-gray'
		}" aria-hidden="true"></i>`

		// Notify Supervisor for Late Checkpoint
		const notifySupForLateCheckpoint =
			profile.call_supervisor_checkpoint || profile.email_supervisor_checkpoint || profile.sms_supervisor_checkpoint
		const notifySupForLateCheckpointFreq = DateTimeHelper.formatDurationInMinutes(profile.checkpoint_frequency)
		const checkpointFreq = '' // notifySupForLateCheckpoint && notifySupForLateCheckpointFreq ? `Checkpoints required every ${notifySupForLateCheckpointFreq} min. ` : ``
		const checkpointText =
			notifySupForLateCheckpoint && notifySupForLateCheckpointFreq
				? `${checkpointFreq}Supervisor will be notified when employee is ${notifySupForLateCheckpointFreq} min late for checkpoint. `
				: ''
		// : 'Supervisor will NOT be notified when employee is late for checkpoint.'

		const checkpointIcon = `<i title="${checkpointText}" class="fa fa-map-marker item-tooltip job-icon-notify-cp ${
			notifySupForLateCheckpoint ? 'hl-notify-green' : 'hl-notify-gray'
		}" aria-hidden="true"></i>`

		return `${checkInIcon}/${checkOutIcon}/${checkpointIcon}`
	}

	static empPayCellRenderer(job: JobRecord, dbSrvc: DatabaseService) {
		const targetPayRate = job.employee_pay_rate?.toFixed(2)
		const currencySymbol = dbSrvc.settingSrvc.getCompanyCurrencySymbol()
		const basePayHtml = targetPayRate
			? `<div style="font-size: 0.9rem"><span class="tts-hl-2" style="font-weight:600">Base Rate:</span>&nbsp;<span>${currencySymbol}${targetPayRate}</span></div>`
			: ''
		return basePayHtml
	}

	static clientVendorCellRenderer(job: JobRecord, dbSrvc: DatabaseService, type: 'CLIENT' | 'VENDOR') {
		const targetId = type === 'CLIENT' ? job.client_id : job.vendor_id
		const target = dbSrvc.orgSrvc.getOrganizationById(targetId)
		const targetName = target?.name
		const targetPayRate = type === 'CLIENT' ? (job.client_pay_rate?.toFixed(2) ?? '') : (job.vendor_pay_rate?.toFixed(2) ?? '')
		const notSetString = targetPayRate && !targetName ? `< ${type === 'CLIENT' ? 'client' : 'vendor'} not set >` : ''
		const clientNameHtml = targetName
			? `<div class="table-details" style="min-width: 150px; max-width:200px">${targetName}</div>`
			: `<div class="table-value-not-set">${notSetString}</div>`

		const currencySymbol = dbSrvc.settingSrvc.getCompanyCurrencySymbol()
		const basePayHtml = targetPayRate
			? `<div style="font-size: 0.9rem"><span class="tts-hl-2" style="font-weight:600">Base Rate:</span>&nbsp;<span>${currencySymbol}${targetPayRate}</span></div>`
			: ''
		const sortHtml = `<div style="display:none">${clientNameHtml}</div>`
		return sortHtml + basePayHtml + clientNameHtml
	}
}
