// This file is shared through codesync

import { log } from '@app/helpers'
import { Checkpoint } from './checkpoint'
import { Global } from './global'

import moment from 'moment-timezone' // Import the moment library for date/time calculations
import { TransactionLogRecord } from './transaction'
import { ImageFile } from './image'
import { DateTimeHelper } from '../helpers/datetime'

export class WaypointImageFile {
	// Using 'this' to match properties in constructor so every prop must have default. This is
	// used instead of record to match to filter out various properties that do not need to be
	// stored for this particular object since ImageFile is what's being passed in usually.

	description = ''
	type = ''
	ts: number = 0
	key: string = ''
	bucket: string = ''
	sha256: string = ''
	qrcode: string = ''

	constructor(record: any) {
		for (const attr in record) {
			if (this.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
	}

	getUrl(): string {
		return 'https://' + this.bucket + '/' + this.key
	}
}

export class TourWaypoint {
	uuid = Global.createUuid()
	startOffset: number = null // Number of minutes offset from start of tour
	name: string = null
	description: string // Notes about the waypoint
	latitude: number
	longitude: number
	imageFiles: Array<WaypointImageFile> = []

	constructor(record?: TourWaypoint) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.imageFiles = (this.imageFiles ?? []).map((i) => new WaypointImageFile(i))
		}
	}

	// shiftStart is an ISO date string for UTC timezone (example: 2023-08-20T10:44:20.439124Z)
	static buildFromCheckpoint(cp: Checkpoint, startOffset: string): TourWaypoint {
		const twp = new TourWaypoint()
		// Calculate the difference in minutes between shiftStart and checkpoint's created time
		const shiftStartTime = moment.utc(startOffset).startOf('minute')
		const checkpointTime = moment.utc(cp.created).startOf('minute')
		const minutesOffset = checkpointTime.diff(shiftStartTime, 'minutes')
		twp.startOffset = minutesOffset

		// Copy checkpoint data
		twp.name = cp.waypoint_name
		twp.description = cp.comments
		twp.latitude = cp.geo_latitude
		twp.longitude = cp.geo_longitude
		twp.imageFiles = (cp.imageList ?? []).map((i) => new WaypointImageFile(i))

		return twp
	}

	getImageFiles(): Array<ImageFile> {
		return this.imageFiles as Array<ImageFile>
	}

	clone(): TourWaypoint {
		const newTwp = new TourWaypoint(this)
		const imagesJson = JSON.stringify(this.imageFiles)
		const parsedImages = JSON.parse(imagesJson)
		newTwp.imageFiles = (parsedImages ?? []).map((i) => new WaypointImageFile(i))
		newTwp.uuid = Global.createUuid()
		return newTwp
	}
}

export class TourRecord {
	id: number
	company_id: number
	job_ids: Array<number> = []

	created: string
	updated: string

	type: TourType = 'UNSTRUCTURED'
	description: string

	repeat_interval: string
	waypoints_json: string
	waypoint_count: number

	xWaypoints: Array<TourWaypoint> = []

	constructor(record?: TourRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
			this.parseWaypointsJson()
		}
	}

	get tourTypeLabel(): string {
		switch (this.type) {
			case 'UNSTRUCTURED':
				return 'Repeating Checkpoints'
			case 'STRUCTURED':
				return 'Shift-timed Tour'
			case 'STRUCTURED_HOUR_START':
				return 'Hourly Tour'
			case 'STRUCTURED_DAY_START':
				return 'Daily Tour'
			default:
				return ''
		}
	}

	parseWaypointsJson() {
		try {
			this.xWaypoints = JSON.parse(this.waypoints_json)
		} catch (error) {
			log('Error parsing waypoints', error)
		}
	}
}

export type TourType = 'UNSTRUCTURED' | 'STRUCTURED' | 'STRUCTURED_HOUR_START' | 'STRUCTURED_DAY_START'

export class TourWrapper {
	id: number // Tour ID
	jobId: number // Job ID
	jobIds: Array<number> = []

	type: TourType = 'UNSTRUCTURED'
	description: string = 'Default Tour' // Description of tour
	repeatInterval: number = null // Interval at which the tour repeats

	waypoints: Array<TourWaypoint> = [] // List of waypoints on the tour

	constructor(record?: TourRecord) {
		if (record) {
			this.id = record.id
			this.jobIds = record.job_ids ? record.job_ids : []

			this.type = record.type
			this.description = record.description
			this.repeatInterval = record.repeat_interval ? moment.duration(record.repeat_interval).asMinutes() : null

			const waypoints = JSON.parse(record.waypoints_json)
			if (waypoints) {
				this.waypoints = waypoints.map((p) => new TourWaypoint(p))
			}
		}
	}

	// shiftStart is an ISO date string for UTC timezone (example: 2023-08-20T10:44:20.439124Z)
	static buildFromCheckpoints(trans: TransactionLogRecord, checkpoints: Array<Checkpoint>, tourType: TourType): TourWrapper {
		const tour = new TourWrapper()
		tour.type = tourType
		tour.jobId = trans.job_id
		tour.description = `(Imported) Created from time entry for ${trans.employee_name} at ${trans.job_description} on ${trans.job_date}`

		switch (tourType) {
			case 'STRUCTURED_HOUR_START':
				tour.waypoints = checkpoints.map((cp) => TourWaypoint.buildFromCheckpoint(cp, trans.actual_start))
				tour.waypoints.forEach((twp) => {
					if (twp.startOffset < 0) twp.startOffset = 0
					if (twp.startOffset > 59) twp.startOffset = 59
				})
				tour.repeatInterval = 60
				break
			case 'STRUCTURED_DAY_START':
				const dayStartOffset = moment.tz(trans.actual_start, trans.timezone).startOf('day').toISOString()
				tour.waypoints = checkpoints.map((cp) => TourWaypoint.buildFromCheckpoint(cp, dayStartOffset))
				break
			default:
				tour.waypoints = checkpoints.map((cp) => TourWaypoint.buildFromCheckpoint(cp, trans.actual_start))
		}

		return tour
	}

	public buildTourRecord(baseRecord?: TourRecord): TourRecord {
		const record = new TourRecord(baseRecord)
		record.id = this.id
		record.job_ids = this.jobIds

		record.type = this.type
		record.description = this.description
		record.repeat_interval = this.repeatInterval ? moment.duration(this.repeatInterval, 'minutes').toISOString() : null

		record.waypoint_count = this.waypoints.length
		record.waypoints_json = JSON.stringify(this.waypoints)

		return record
	}

	getWaypointForUuid(uuid: string): TourWaypoint {
		return this.waypoints.find((wp) => wp.uuid === uuid)
	}
}

export class TourEditDialogData {
	action: 'new' | 'edit' | 'clone' = 'new'
	source: 'JOB_TABLE' | 'TOUR_TABLE'
	jobId: number
	tourId: number

	constructor(source: 'JOB_TABLE' | 'TOUR_TABLE', action: 'new' | 'edit' | 'clone', jobId: number, tourId: number) {
		this.source = source
		this.action = action
		this.jobId = jobId
		this.tourId = tourId
	}
}

export class WaypointLogRecord {
	id: number
	company_id: number
	transaction_log_id: number
	uuid: string
	name: string
	tour_sequence_number: number // Starts at 1
	expected_time: string // ISO Date String - not NULL
	actual_time: string // ISO Date String
	created: string // ISO Date String

	emp_id: number
	emp_name: string

	emp_notified: boolean
	sup_notified: boolean
	emp_notified_ts: string // ISO Date String
	sup_notified_ts: string // ISO Date String

	geo_distance: number // Miles from expected gps location

	constructor(record?: WaypointLogRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.expected_time = DateTimeHelper.stripUtcTag(this.expected_time)
			this.actual_time = DateTimeHelper.stripUtcTag(this.actual_time)
			this.emp_notified_ts = DateTimeHelper.stripUtcTag(this.emp_notified_ts)
			this.sup_notified_ts = DateTimeHelper.stripUtcTag(this.sup_notified_ts)
		}
	}
}

// WaypointLogStore used by checkpoint list component to display waypoint log information
export class WaypointLogStore {
	public waypoints: Array<WaypointLogRecord> = []
	constructor(waypoints?: Array<WaypointLogRecord>) {
		if (waypoints) {
			this.waypoints = waypoints
		}
	}

	setWaypointLogs(waypoints: Array<WaypointLogRecord>) {
		this.waypoints = waypoints
	}

	getWaypointLog(uuid: string, seqNum: number) {
		return this.waypoints.find((wp) => wp.uuid === uuid && wp.tour_sequence_number === seqNum) || null
	}
}

// WaypointViewModel used by TourEditComponent and TourScheduleViewComponent for handling waypoints
export class WaypointViewModel {
	isEditing = false
	isConfirmingDelete = false
	hasOrderIssue = false // Waypoint offsets not in ascending order
	hasRepeatIssue = false // Tour repeat interval less than a waypoint offset
	twp: TourWaypoint

	clockTime: Date = null

	constructor(twp: TourWaypoint) {
		this.twp = twp
	}

	// Indicates the offset input field should be flagged
	get hasInputIssue(): boolean {
		return this.hasOrderIssue || this.hasRepeatIssue
	}

	clone(): WaypointViewModel {
		const newTwp = this.twp.clone()
		return new WaypointViewModel(newTwp)
	}

	updateClockTimeFromOffset() {
		this.clockTime = moment('2020-01-01').add(this.twp.startOffset, 'minutes').toDate()
	}
}

export class ToursViewManager {
	version = 1
	selectedJobIds: Array<number> = []
	isLoading = false
}
