import { Injectable, NgZone } from '@angular/core'
import { Subject } from 'rxjs'
import {
	DialogControlEvent,
	GenericEvent,
	FullScreenViewAction,
	FullScreenViewEvent,
	Incident,
	UserReportType,
	ClickToCallSourceConfig,
	ClickToCallGlobalConfig,
	DataAccessRequest,
	ShiftSummaryReportEventItem,
	AuditActionEvent,
	SecureFileRecord,
	AuditLogTrackEvent,
	SecureFileLinkType,
	AuditLogTrackType,
	DatabaseTableName,
	ContactSupportEvent,
} from '@app/models'
import { log } from '@app/helpers'
import { Global } from '@app/models/global'

type Severity = 'success' | 'info' | 'warn' | 'error'

@Injectable({
	providedIn: 'root',
})
export class EventService {
	constructor(private zone: NgZone) {
		log('Creating EventService')
	}

	dialogControlEvents = new Subject<DialogControlEvent>()
	currentDisplayUser = new Subject<string>()
	genericEvents = new Subject<GenericEvent>()
	fullScreenView = new Subject<FullScreenViewEvent>()
	showFixedHeader = new Subject<boolean>()
	transListUpdated = new Subject<boolean>()
	clickToCallEvent = new Subject<ClickToCallSourceConfig>()
	clickToCallGlobalEvent = new Subject<ClickToCallGlobalConfig>()
	showAuditLog = new Subject<AuditActionEvent>()
	contactSupportEvent = new Subject<ContactSupportEvent>()

	// Used by shift summary report when list item is clicked to update map view
	shiftSummaryEventListItemClicked = new Subject<ShiftSummaryReportEventItem>()

	dialogNotification(controlEvent: DialogControlEvent) {
		this.zone.run(() => {
			this.dialogControlEvents.next(controlEvent)
		})
	}

	displayUserChanged(userName: string) {
		this.currentDisplayUser.next(userName)
	}

	postGenericEvent(event: GenericEvent) {
		this.genericEvents.next(event)
	}

	showFullScreenReport(incident: Incident) {
		const data = incident
		let action: FullScreenViewAction = 'SHOWUNKNOWNREPORT'
		switch (incident.report_type as UserReportType) {
			case 'SHIFT':
				action = 'SHOWSHIFTREPORT'
				break
			case 'INCIDENT':
				action = 'SHOWINCIDENTREPORT'
				break
			case 'HOMECARE':
				action = 'SHOWHOMECAREREPORT'
				break
			case 'VEHICLEINSPECTION':
				action = 'SHOWVEHICLEINSPECTIONREPORT'
				break
			case 'CHECKIN':
			case 'PRE_BREAK':
			case 'POST_BREAK':
			case 'CHECKOUT':
			case 'CUSTOM':
				action = 'SHOWCHECKLISTREPORT'
				break
		}
		const event = new FullScreenViewEvent(action, data)
		this.fullScreenView.next(event)
	}

	closeFullscreenView(data?: any) {
		const event = new FullScreenViewEvent('CLOSE', data)
		this.fullScreenView.next(event)
	}

	handleBeforeUnload(event) {
		event.preventDefault()
		event.returnValue = ''
	}

	addBeforeUnloadBlocker() {
		log('Add addBeforeUnloadBlocker')
		window.addEventListener('beforeunload', this.handleBeforeUnload)
	}

	removeBeforeUnloadBlocker() {
		log('Remove addBeforeUnloadBlocker')
		window.removeEventListener('beforeunload', this.handleBeforeUnload)
	}

	public sendAuthLinkAccessEvent(empId: number, data: Object) {
		// Back end skips if user is INTERNAL
		const payload = data ?? {}

		const trackEvent = new AuditLogTrackEvent('AUTHLINKACCESS', payload)
		const dataJson = trackEvent ? JSON.stringify(trackEvent) : null
		const options = { resource: 'employee', resource_id: empId, data: dataJson }
		const request = new DataAccessRequest('none', 'track_event', options, null)
		const emp = Global.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)

		if (emp.status === 'RESIGNED' || emp.status === 'TERMINATED') {
			Global.coreSrvc.notifySrvc.notify(
				'info',
				'Invalid Status',
				`This employee has been marked as terminated or resigned. You must manually clear their 'Employee Status' and mark them active before they will be allowed to check in.`,
			)
			return
		}

		Global.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			log('sendAuthLinkAccessEvent Result', result)
			const emp = Global.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)
		})
	}

	public sendSecureFileAccessEvent(action: 'VIEW' | 'DOWNLOAD', record: SecureFileRecord, linkId: number, linkType: SecureFileLinkType) {
		// Back end skips if user is INTERNAL
		const trackData = new AuditLogSecureFileTrackData(action, linkId, linkType, record.file_name, record.id)

		const data = new AuditLogTrackEvent('SECUREFILEACCESS', trackData)
		const dataJson = data ? JSON.stringify(data) : null
		const options = { resource: 'file_uploads', resource_id: record.id, data: dataJson }
		const request = new DataAccessRequest('none', 'track_event', options, null)
		Global.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			log('sendSecureFileAccessEvent Result', result)
		})
	}

	public sendGenericTrackEvent(eventType: AuditLogTrackType, resource: DatabaseTableName, recordId: number, metadata: Object) {
		// Back end skips if user is INTERNAL
		const trackData = metadata ?? {}

		const data = new AuditLogTrackEvent(eventType, trackData)
		const dataJson = data ? JSON.stringify(data) : null
		const options = { resource: resource, resource_id: recordId, data: dataJson }
		const request = new DataAccessRequest('none', 'track_event', options, null)
		Global.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			log('sendGenericTrackEvent Result', result)
		})
	}
}

export class AuditLogTrackEventData {
	action: string
	constructor(action: string) {
		this.action = action
	}
}

export class AuditLogSecureFileTrackData {
	fileName: string = null
	action: 'VIEW' | 'DOWNLOAD' = null
	linkId: number = null
	linkType: SecureFileLinkType = null
	secureFileId: number = null

	constructor(action: 'VIEW' | 'DOWNLOAD', linkId: number, linkType: SecureFileLinkType, fileName: string, secureFileId: number) {
		this.action = action
		this.fileName = fileName
		this.linkId = linkId
		this.linkType = linkType
		this.secureFileId = secureFileId
	}
}
