import { Component, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router'

import { JobRecord } from '@app/models'
import { DatabaseService } from '@app/services'

import { log } from '@app/helpers'

@Component({
    selector: 'app-job-delete',
    templateUrl: './job-delete.component.html',
    styleUrls: ['./job-delete.component.scss'],
    standalone: false
})
export class JobDeleteComponent implements OnInit {
	jobId: number
	jobName: string
	isWorking = false

	scheduleViewRouterLink = null

	openTransactions = 0

	private job: JobRecord

	constructor(
		route: ActivatedRoute,
		private router: Router,
		private location: Location,
		private dbSrvc: DatabaseService,
	) {
		this.jobId = parseInt(route.snapshot.params['jobId'], 10) || null
		this.job = this.dbSrvc.jobSrvc.getJobById(this.jobId)
		if (this.job) {
			this.jobName = this.job.description
		}
		this.scheduleViewRouterLink = ['/admin/scheduler/filter', 'job_id', this.jobId]
		this.checkForOngoingTransactions()
	}

	ngOnInit() {}

	schedulesCount(): number {
		return this.dbSrvc.schedulerSrvc.getEntriesForJobId(this.jobId).length
	}

	checkForOngoingTransactions() {
		if (this.job) {
			const maxJobLength = this.dbSrvc.settingSrvc.getCompany().job_length_max ?? 16
			const transactions = this.dbSrvc.tranSrvc.getTransactions().filter((t) => t.job_id === this.jobId && t.isOngoing(maxJobLength))
			this.openTransactions = transactions.length
		}
	}

	jobExists() {
		return this.job != null
	}
	schedulesExist(): boolean {
		return this.schedulesCount() > 0
	}

	requirementsMet(): boolean {
		if (this.schedulesExist()) {
			return false
		}
		if (this.openTransactions) {
			return false
		}
		return true
	}
	canDeleteJob(): boolean {
		if (this.openTransactions) {
			return false
		}
		return true
	}

	deleteJob(): boolean {
		this.dbSrvc.deleteRecord('job', this.jobId).then((delSuccess) => {
			if (delSuccess) {
				this.location.back()
				// schedule_view deprecated
				// this.dbSrvc.readTable('schedule_view').then(readSuccess => {
				// 	if (readSuccess) {
				// 		this.location.back()
				// 	}
				// })
			}
		})
		return false
	}

	viewOngoingTransactions() {
		this.dbSrvc.tranSrvc.filterText = `#jobid:${this.jobId} #shft:ongoing`
		this.router.navigate(['/admin/transactions'])
	}

	viewSchedules() {
		const job = this.dbSrvc.jobSrvc.getJobById(this.jobId)
		const filterText = job?.description
		const svm = this.dbSrvc.schedulerSrvc.scheduleViewManager
		svm.currentView = 'SERIES'
		svm.series.subSection = 'CURRENT'
		svm.currentSearchText = filterText
		svm.series.searchText = filterText
		this.router.navigate(['/admin/scheduler'])
	}

	onCancel(): boolean {
		this.location.back()
		return false
	}
}
