import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { DisplayHelper, FormHelper, Helper, log } from '@app/helpers'
import {
	DataAccessRequest,
	DialogManager,
	FileUploadHelper,
	FileUploaderProgress,
	FormPhoneNumberManager,
	GenericEmailListManager,
	HelpDialogMessage,
	WorkOrderConfigRecord,
} from '@app/models'
import { CoreService } from '@app/services'
import { environment } from '@env/environment'

import moment from 'moment-timezone'
import S3 from 'aws-sdk/clients/s3'
import { v4 as uuid } from 'uuid'

@Component({
    selector: 'app-workorder-edit',
    templateUrl: './workorder-edit.component.html',
    styleUrl: './workorder-edit.component.scss',
    standalone: false
})
export class WorkorderEditComponent implements OnInit, AfterViewInit, AfterContentInit {
	environment = environment

	isDataLoaded = false
	isUpdating = false

	workorder: WorkOrderConfigRecord

	companyPhoneManager = new FormPhoneNumberManager()
	bccEmailListManager: GenericEmailListManager = null

	expander = {
		showWorkOrderEmail: false,
		showWorkOrderTemplate: false,
		showWorkOrderTAC: false,
	}

	// Uploader
	files: UploadFileInfo[] = []
	currentFile: any

	engine: 'FlyingSaucer' | 'OpenHtml' = 'OpenHtml'

	htmlNotesFullScreenVisible = false
	htmlBodyFullScreenVisible = false

	@Input() dialogManager: DialogManager
	@ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef

	constructor(private coreSrvc: CoreService) {
		this.fetchAndLoadData()
	}
	get isInternalUser(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()
	}

	get isDesktop(): boolean {
		return this.coreSrvc.devDetect.isDesktop()
	}

	get isNotStandalone(): boolean {
		return DisplayHelper.isNotStandalone
	}

	get isFormValid(): boolean {
		if (!this.workorder) return true
		const isPhoneValid = !!this.companyPhoneManager.isFormValid
		const isEmailBodyValid = !!this.workorder.html_email_body
		const isEmailSubjectValid = !!this.workorder.email_subject
		return isPhoneValid && isEmailSubjectValid && isEmailBodyValid
	}

	ngOnInit(): void {}

	ngAfterViewInit(): void {}

	ngAfterContentInit() {
		this.setupDialogManager()
	}

	setupDialogManager() {
		this.dialogManager.submitBtnAction = () => this.submit()
		this.dialogManager.canSubmit = () => this.isFormValid
	}

	setupCompanyPhoneManager() {
		this.companyPhoneManager = new FormPhoneNumberManager()
		this.companyPhoneManager.showOptionalOrRequired = false
		this.companyPhoneManager.e164 = this.workorder.company_phone_e164
		// this.companyPhoneManager.helpKey = 'company_phone'
		this.companyPhoneManager.label = 'Company Phone Number'
	}

	setupEmailManager() {
		this.bccEmailListManager = new GenericEmailListManager(this.coreSrvc)
		this.bccEmailListManager.lookupLocations = ['EMP', 'CONTACT', 'ORG', 'USER']
		this.bccEmailListManager.invalidEmailAlertMsg =
			'Please check the Additional Recipients input field as it does not appear to be a valid email address.'
		if (this.workorder.bcc_emails) {
			this.bccEmailListManager.initWithString(this.workorder.bcc_emails)
		}
	}

	private fetchAndLoadData() {
		const request = new DataAccessRequest('wo_config')
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			const data = result.data
			log('Result', result)
			this.workorder = new WorkOrderConfigRecord(data[0])
			this.setupCompanyPhoneManager()
			this.setupEmailManager()
			this.isDataLoaded = true
		})
	}

	private submit() {
		if (this.isUpdating) return
		const record = this.makeUpdateRecord()
		if (!record) return
		// FormHelper.trimOnlyWhitespace(this.workorder)

		this.isUpdating = true
		const operation = !this.workorder.id ? 'insert' : 'update'
		const request = new DataAccessRequest('wo_config', operation, null, record)
		log('Request', request)
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			this.dialogManager.isDialogVisible = false
			// this.isUpdating = false
		})
	}

	private makeUpdateRecord(): WorkOrderConfigRecord {
		// Attempt to add anything in email input
		if (!this.bccEmailListManager.addEmail()) {
			this.expander.showWorkOrderEmail = true
			return null
		}

		const form = this.workorder
		const record = new WorkOrderConfigRecord(form)
		record.company_phone_e164 = this.companyPhoneManager.e164
		record.bcc_emails = this.bccEmailListManager.emailListString
		return record
	}

	// Template File Management

	public templateBrowseHandler(event: any) {
		log('templateBrowseHandler', event)
		const target = event.target as HTMLInputElement
		const files = target.files as unknown as Array<any>
		this.prepareTemplate(files)
	}

	public onTemplateDropped($event) {
		log('onTemplateDropped', $event)
		this.prepareTemplate($event)
	}

	public prepareTemplate(files: Array<File>) {
		const template = files[0]
		log('Template File', template)

		if (template.type !== 'text/html') {
			this.coreSrvc.notifySrvc.notify('error', 'Invalid File Type', 'Only HTML files are supported.', 5)
			return
		}

		this.extractTextFromFile(template).then((html) => {
			this.workorder.html_template = html
			this.coreSrvc.notifySrvc.notify('success', 'Template Updated', 'Your custom template file has been updated.', 5)
		})
	}

	public downloadCustomTemplateBtnClicked() {
		this.downloadHtmlContent(this.workorder.html_template, 'custom_workorder_template.html')
	}

	private extractTextFromFile(file: File): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			const reader = new FileReader()

			reader.onload = (event) => {
				const result = event.target?.result as string
				resolve(result)
			}

			reader.onerror = (event) => {
				reject(event.target?.error)
			}

			reader.readAsText(file, 'UTF-8') // Read the file as text
		})
	}

	private downloadHtmlContent(htmlContent: string, fileName: string) {
		Helper.downloadHtmlContent(htmlContent, fileName)
	}

	public viewTemplate() {
		const templateHtml = this.workorder.html_template
		const win = window.open()
		win.document.write(templateHtml)
		win.document.close()
	}

	public viewTemplateAsPdf() {
		const record = new WorkOrderConfigRecord(this.workorder)
		record['engine'] = this.engine

		// Open a new tabe for the PDF
		const newWindow = window.open()
		if (!newWindow) {
			alert('Please allow pop-ups to view the PDF or use the preview option from a desktop browser.')
			return
		}

		newWindow.document.write('<html><head><title>Loading...</title></head><body><p>Loading PDF..</p></body></html>')

		// Fetch the PDF Data
		const request = new DataAccessRequest('none', 'wo_generate', record, null)
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			const response = result.data
			const base64Data = response.data
			// log('Data', base64Data)
			const byteCharacters = atob(base64Data)
			const byteNumbers = new Array(byteCharacters.length)
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i)
			}
			const byteArray = new Uint8Array(byteNumbers)
			const blob = new Blob([byteArray], { type: 'application/pdf' })
			const blobUrl = URL.createObjectURL(blob)

			// Set the dyncamically created PDF to the new tabs
			newWindow.location.href = blobUrl
		})
	}

	// Terms and Conditions Upload Management

	public tacFileBrowseHandler(event: any) {
		const target = event.target as HTMLInputElement
		const files = target.files as unknown as Array<any>
		this.prepareTacFilesList(files)
	}

	public onTacFileDropped($event) {
		this.prepareTacFilesList($event)
	}

	private prepareTacFilesList(files: Array<any>) {
		// Short circuit if an upload is in progress
		if (this.currentFile) return

		const file = new UploadFileInfo(files[0])

		if (file.file.size > 5 * 1024 * 1024) {
			this.coreSrvc.notifySrvc.notify('error', 'Size Limit Exceeded', 'Attachments must be less than 5MB.', 5)
			return
		}

		if (file.file.type !== 'application/pdf') {
			this.coreSrvc.notifySrvc.notify('error', 'Invalid File Type', 'Only PDF files are supported at this time.', 5)
			return
		}

		file.progress = 0
		this.files.unshift(file)

		this.fileDropEl.nativeElement.value = ''
		this.uploadFile(file).then((uploadFile) => {
			this.currentFile = null
			const bucket = environment.assetsBucket
			const key = uploadFile.bucketKey
			const url = `https://${bucket}/${key}`
			this.workorder.tac_url = url
		})
	}

	public formatBytes(bytes) {
		return FileUploadHelper.formatBytes(bytes)
	}

	private uploadFile(uploadFile: UploadFileInfo): Promise<UploadFileInfo> {
		const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
		const userId = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser().id
		const filename = uuid()
		const bucketKey = `${companyId}/workorders/tac/${filename}`
		uploadFile.bucketKey = bucketKey

		return new Promise((resolve, reject) => {
			const params: any = {
				Metadata: {
					company_id: `${companyId}`,
					user_id: `${userId}`,
					file_type: uploadFile.file.type,
				},
				Bucket: environment.assetsBucket,
				Key: bucketKey,
				ContentType: uploadFile.file.type,
				Body: uploadFile.file,
			}

			log('Upload Parameters', params)

			const bucket = new S3()
			bucket
				.upload(params, (err, data) => {
					if (err) {
						// Encountered an error
						log('Upload Error', err)
						resolve(uploadFile)
					} else {
						// Upload successful
						log('Upload Success')
						uploadFile.success = true
						resolve(uploadFile)
					}
				})
				.on('httpUploadProgress', (evt) => {
					const progress = new FileUploaderProgress(evt.loaded, evt.total)
					this.updatePercentComplete(progress)
				})
		})
	}

	private updatePercentComplete(progress: FileUploaderProgress) {
		const result = (progress.bytesSent / progress.bytesTotal) * 100
		this.currentFile.progress = Math.floor(result)
	}

	public viewTac() {
		const url = this.workorder.tac_url
		window.open(url, '_blank')
	}

	public viewDeveloperDocs() {
		const developerDomain = environment.developerDomain
		const url = `https://${developerDomain}/pdf_template_doc.html`
		window.open(url, '_blank')
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)

		const templateMsg = !!this.workorder.html_template
			? 'You are using the default tempalate. You may download the default, customize it, and upload to the dropzone below.'
			: 'default'

		switch (trigger) {
			case 'email_subject':
				help.header = 'Email Subject'
				help.message = `This is the subject of the email sent when a pending schedule is approved with a work order and is a required field.\n\nThis field may contain template variables.`
				break
			case 'html_email_body':
				help.header = 'Email Body'
				help.message = `This is the body of the email sent when a pending schedule is approved with a work order and is a required field.\n\nThis field may contain HTML formatting as well as template variables.`
				break
			case 'bcc_emails':
				help.header = 'Additional Recipients'
				help.message = `Telephone Timesheets does NOT store copies of your work orders. Therefore, we strongly advise that you add at least one additional recipient at your company to receive a copy for future reference.`
				break
			case 'html_notes':
				help.header = 'Work Order Notes'
				help.message = `Use these notes to include information shared by all work orders. Holiday pricing information is a good example.\n\nDo not use these notes for Terms and Conditions which have their own section.\n\nThe notes field may contain HTML formatting as well as template variables`
				break
			case 'template':
				help.header = 'Template'
				help.message = `You may use the default template or upload your own custom version. Use the link to download the current template and change as desired.\n\nIf you've uploaded a custom version of the template, you can view, download, or clear it using links which will be displayed above the file drop zone.`
				break
			case 'tac_url':
				help.header = 'Terms & Conditions'
				help.message = `You may add optional terms and conditions which will be attached to the workorder emails sent when you approve a pending schedule.`
				break
		}

		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}

class UploadFileInfo {
	file: File
	bucket = environment.assetsBucket
	bucketKey = ''
	progress: number
	success = false

	constructor(file: File) {
		this.file = file
	}
}
