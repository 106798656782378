<div *ngIf="currentView === 'ADDRESSBOOK'">
	<div style="margin-bottom: 15px">
		<p-select
			#listDropdown
			[options]="options"
			[(ngModel)]="selectedOptionType"
			optionLabel="label"
			optionValue="type"
			placeholder="Select Contact Type"
			[scrollHeight]="'400px'"
			(onChange)="updateUI()"
		></p-select>
	</div>

	<div style="margin-bottom: 35px">
		<app-search-filter [placeholder]="'filter records'" (search)="filterRecords($event)"> </app-search-filter>
	</div>

	<div *ngIf="selectedOptionType !== null">
		<div *ngIf="filteredRecords.length === 0" class="ctc-no-records">No contact records available</div>
		<div *ngFor="let rec of filteredRecords; let idx = index">
			<div class="card ctc-card">
				<div>
					<div class="ctc-desc tts-hl-2">
						<div class="ctc-contact-type float-right">{{ formatContactType(rec.contactType) }}</div>
						<div>{{ rec.description }}</div>
					</div>
					<div *ngIf="rec.notes" class="ctc-notes">
						{{ rec.notes }}
					</div>
				</div>

				<!-- Phone Number -->
				<div style="margin-top: 2px">
					<div class="ctc-call-btn-block float-right">
						<i
							*ngIf="canAccessCallBtn && rec.phoneE164"
							title="Place Call"
							class="far fa-phone-office c2c-ico link-text"
							style="margin-right: 6px"
							(click)="placeCall(rec)"
						></i>
						<i
							*ngIf="canAccessCallBtn && rec.phoneE164"
							title="Send Text"
							class="far fa-message-dots c2c-ico link-text"
							(click)="startTextMsg(rec)"
						></i>
					</div>
					<div *ngIf="rec.phoneE164" class="ctc-e164">{{ rec.phoneE164 | displayPhone }}</div>
					<div *ngIf="!rec.phoneE164" class="ctc-e164">No number available</div>
				</div>

				<!-- Email Address -->
				<div class="ctc-email trunc-ellipsis">
					<a *ngIf="rec.email" class="link-text ctc-email" [href]="'mailto:' + rec.email">{{ rec.email }}</a>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="currentView === 'COMPOSEMESSAGE'">
	<app-click-to-call-message
		[c2cRecord]="selectedC2CRecord"
		(cancelMessage)="cancelTextMsg()"
		(sendMessage)="sendTextMsg($event)"
	></app-click-to-call-message>
</div>

<div *ngIf="currentView === 'SENDINGMESSAGE'">
	<div class="updating-note"><i class="fas fa-loader fa-spin"></i> Sending Message...</div>
</div>

<div *ngIf="currentView === 'PLACINGCALL'">
	<div class="updating-note"><i class="fas fa-loader fa-spin"></i> Placing Call...</div>
</div>
