import { Subject } from 'rxjs'
import { log } from '@app/helpers'
import { ImageFile } from './image'

// export class DialogSaveEvent {
// 	data: any = {}
// 	constructor(data: any) {
// 		this.data = data
// 	}
// }

class DialogScrollPosition {
	id: string
	position = 0

	constructor(id: string, position: number) {
		this.id = id
		this.position = position
	}
}

class DialogManagerState {
	modalId = 'modalDialog'
	helpVideoKey = ''
	headerLabel = ''
	footerLabel = ''
	submitBtnLabel = 'Save'
	cancelBtnLabel = 'Cancel'
	isEditing = false
	isDialogVisible = false
	isCancelBtnVisble = true
	isSubmitBtnVisible = true
	isBackBtnVisible = false
	isFooterLabelVisible = true
	ignoreBackgroundClose = true
	dialogData = null

	scrollPositions: Array<DialogScrollPosition> = []

	canSubmit: () => boolean = null
	canCancel: () => boolean = null

	backBtnAction: () => void = null
	submitBtnAction: () => void = null
	cancelBtnAction: () => void = null
	createBtnAction: () => void = null

	constructor(dialog: DialogManager) {
		for (const attr in dialog) {
			if (this.hasOwnProperty(attr)) {
				// log('Property saved', attr)
				this[attr] = dialog[attr]
			}
		}
	}
}

export class DialogManager implements DialogManagerState {
	modalId = 'modalDialog'
	helpVideoKey = ''
	headerLabel = ''
	footerLabel = ''
	submitBtnLabel = 'Save'
	cancelBtnLabel = 'Cancel'
	isEditing = false
	isDialogVisible = false
	isCancelBtnVisble = true
	isSubmitBtnVisible = true
	isBackBtnVisible = false
	isFooterLabelVisible = true
	ignoreBackgroundClose = true
	dialogData = null

	scrollPositions: Array<DialogScrollPosition> = []

	private dialogManagerState: Array<DialogManagerState> = []

	canSubmit = () => true
	canCancel = () => true

	backBtnAction = () => {}
	submitBtnAction = () => {}
	cancelBtnAction = () => {
		this.isDialogVisible = false
	}
	createBtnAction = () => {}

	constructor(modalId?: string, helpVideoKey?: string) {
		if (modalId) this.modalId = modalId
		if (helpVideoKey) this.helpVideoKey = helpVideoKey
	}

	public pushState() {
		const state = new DialogManagerState(this)
		this.dialogManagerState.push(state)
	}

	public popState(): DialogManagerState {
		return this.dialogManagerState.pop()
	}

	public popStateAndApply(): boolean {
		const state = this.popState()
		// log('Popped State', state)
		if (state) {
			for (const attr in state) {
				if (this.hasOwnProperty(attr)) {
					this[attr] = state[attr]
				}
			}
			return true
		}
		return false
	}

	public scrollToTop() {
		if (this.modalId === 'modalDialog') {
			log('Scroll position cannot be changed until modalId has been set')
			return
		}
		const selector = `#${this.modalId} .modal-body`
		// log('Setting scrollToTop position for', selector)
		$(selector).scrollTop(0)
	}

	public saveScrollPosition(id: string) {
		const position = this.getScrollPosition()
		const savedPosition = this.scrollPositions.find((sp) => sp.id === id)
		if (savedPosition) {
			savedPosition.position = position
		} else {
			const scrollPosition = new DialogScrollPosition(id, position)
			this.scrollPositions.push(scrollPosition)
		}
	}

	public restoreScrollPosition(id: string) {
		const savedPosition = this.scrollPositions.find((sp) => sp.id === id)
		if (savedPosition) {
			this.setScrollPosition(savedPosition.position)
		} else {
			log('Unable to restore scroll position - no saved position found.')
		}
	}

	public getScrollPosition(): number {
		if (this.modalId === 'modalDialog') {
			log('Set modalId before requesting scroll position.')
			return 0
		}
		const selector = `#${this.modalId} .modal-body`
		const position = $(selector).scrollTop()
		// log('Getting scroll position for', selector, position)
		return position
	}

	public setScrollPosition(position: number) {
		if (this.modalId === 'modalDialog') {
			log('Scroll position cannot be changed until modalId has been set')
			return
		}
		const selector = `#${this.modalId} .modal-body`
		// log('Setting scroll position for', selector, position)
		setTimeout(() => {
			$(selector).scrollTop(position)
		})
	}

	public setBackgroundColor(color: string) {
		$('.modal-content').css('background-color', color)
	}

	// Called by the dialog host when destroyed to remove any circular references
	public resetActions() {
		this.backBtnAction = () => {}
		this.submitBtnAction = () => {}
		this.cancelBtnAction = () => {
			this.isDialogVisible = false
		}
		this.createBtnAction = () => {}
	}

	public resetCancelBtnAction() {
		this.cancelBtnAction = () => {
			this.isDialogVisible = false
		}
	}

	public hideButtons() {
		this.isCancelBtnVisble = false
		this.isSubmitBtnVisible = false
	}
}

export class DuplicateImagesDialogData {
	transId?: number
	images: Array<ImageFile> = []

	constructor(transId: number, images: Array<ImageFile>) {
		this.transId = transId
		this.images = images
	}
}
