<div id="job-top"></div>
<div style="max-width: 100%; margin: 0 auto">
	<div *ngIf="!isRecordValid" class="edit-record-not-found">
		<div>Record</div>
		<div>Not Found</div>
	</div>

	<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

	<div [hidden]="isUpdating || isEditingNotifications">
		<form
			*ngIf="showForm"
			class="form-horizontal edit-form-block"
			novalidate
			[formGroup]="jobForm"
			autocomplete="off"
			(keydown.escape)="$event.preventDefault()"
			(keydown.enter)="$event.preventDefault()"
		>
			<div>
				<div *ngIf="useJobCodesEnabled" class="form-group" style="margin-top: 10px; margin-bottom: 30px">
					<strong>Job Code</strong><i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('jobCodes')"></i>
					<span>
						<input type="tel" maxlength="9" formControlName="job_code" class="form-control float-right" style="width: 120px" />
					</span>
				</div>

				<div *ngIf="isSyncLocked" class="alert alert-warning">
					This job is maintained via 3rd party integration and some fields may not be edited.
				</div>

				<div *ngIf="isJobDefaultJobForJobSite && !isSyncLocked" class="alert alert-warning">
					This job is the default job for this job site and cannot be renamed or assigned to another site.
				</div>

				<div *ngIf="isSystemJob" class="alert alert-info">
					This is a system managed job and only a subset of job properties are available for configuration.
				</div>

				<div *ngIf="!isSystemJob" class="form-group" [class.input-required]="!jobForm.get('description').value">
					<div class="input-label">
						<strong>Job Name</strong>
						<span *ngIf="!jobForm.get('description').value" class="float-right">(required)</span>
					</div>
					<div>
						<input
							id="jobDescription"
							type="text"
							class="form-control"
							formControlName="description"
							autocomplete="off"
							(keyup)="isFormValid()"
						/>
					</div>
				</div>

				<div *ngIf="!isJobSiteMerged && !isSystemJob" class="form-group" [class.input-required]="!jobForm.get('location_id').value">
					<div class="input-label">
						<strong>Job Site</strong>
						<span *ngIf="!jobForm.get('location_id').value" class="float-right">(required)</span>
					</div>
					<p-select
						appScrollLockSelect
						id="jobSite"
						[options]="jobSitesDropdown"
						formControlName="location_id"
						(onChange)="isFormValid(); detectChanges()"
						[filter]="true"
						filterBy="label"
						[required]="true"
					></p-select>
				</div>

				<!-- BEGIN MERGED JOB SITE -->

				<div *ngIf="isJobSiteMerged">
					<div *ngIf="showSupervisors" class="form-group" [class.input-required]="!jobForm.get('jsSupervisor').value">
						<div class="input-label">
							<strong>Job Site Supervisor</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('jsSupervisor')"></i>
							<span *ngIf="!jobForm.get('jsSupervisor').value" class="float-right">(required)</span>
						</div>
						<p-select
							appScrollLockSelect
							id="supervisors"
							[options]="supervisorOptions"
							formControlName="jsSupervisor"
							[filter]="true"
							filterBy="label"
						></p-select>
					</div>

					<div class="form-group" [class.input-required]="!jobForm.get('jsTimezoneId').value">
						<div class="input-label form-spacer">
							<strong>Timezone</strong>
							<span *ngIf="!jobForm.get('jsTimezoneId').value" class="float-right">(required)</span>
						</div>
						<p-select
							appScrollLockSelect
							[options]="timezoneOptions"
							formControlName="jsTimezoneId"
							[virtualScrollItemSize]="32"
							[resetFilterOnHide]="true"
							[filter]="true"
							filterBy="label"
						></p-select>
					</div>

					<!-- <div class="form-group">
						<div class="input-label">
							<strong>Timezone</strong>
							<span *ngIf="!jobForm.get('jsTimezoneId').value" class="input-required float-right">(required)</span>
						</div>
						<select id="timezone" class="form-control" formControlName="jsTimezoneId">
							<option default value="">Select Timezone</option>
							<option *ngFor="let tz of timezoneData" [value]="tz.zone_id">{{ tz.display_name ? tz.display_name : tz.zone_name }}</option>
						</select>
					</div> -->

					<div *ngIf="!isTravelJob" style="margin-bottom: 18px">
						<app-job-site-edit-location [manager]="locationManager"></app-job-site-edit-location>
					</div>

					<!-- <hr style="margin: 25px 0px 25px 0px;"> -->
				</div>

				<!-- END MERGED JOB SITE -->
			</div>

			<div style="margin-top: 25px">
				<app-form-tags
					#tagComp
					[tagsJson]="jobForm.get('tags_json').value"
					[tagOptionsList]="tagOptionsList"
					(tagsJsonChange)="jobForm.get('tags_json').setValue($event)"
				></app-form-tags>
			</div>

			<!-- Notify Hide - DEPRECATED 20241126 -->
			<!-- <div [hidden]="isShowingNotifications">
				<div *ngIf="false && jobForm.invalid" class="alert alert-warning" style="margin-top: 25px">
					Form submission is disabled until all required fields have been set.
				</div>
			</div> -->
			<!-- Notify Hide -->

			<!-- Advanced Block -->
			<app-form-expander
				[style]="{ 'margin-top': '30px' }"
				[contentStyle]="{ margin: '30px 0px 22px 0px' }"
				[label]="'Advanced Options'"
				[centerOnExpansion]="true"
				[(isExpanded)]="showAdvancedOptions"
			>
				<div *ngIf="showUserGroups && isJobSiteMerged && !isSystemJob" class="form-group">
					<div class="input-label form-spacer">
						<strong>Supervisor Group</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('userGroup')"></i>
						<!-- <span *ngIf="!empForm.get('supervisor_group_id').value" class="input-required float-right">(required)</span> -->
					</div>
					<p-select
						appScrollLockSelect
						id="userGroupsDropdown"
						[options]="userGroupOptions"
						formControlName="jsSupervisorGroupId"
						[filter]="true"
						filterBy="label"
					></p-select>
				</div>

				<div class="form-group">
					<div class="input-label">
						<strong>External ID</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('externalID')"></i>
						<!-- <span *ngIf="!jobForm.get('description').value" class="input-required float-right">(required)</span> -->
					</div>
					<div>
						<input id="externalId" type="text" class="form-control" formControlName="external_id" autocomplete="off" />
					</div>
				</div>

				<div *ngIf="isJobSiteMerged">
					<div class="form-group">
						<div class="input-label">
							<strong>Job Site Details</strong>
							<i
								title="More Information"
								class="far fa-info-circle tts-info-btn"
								aria-hidden="true"
								(click)="showHelp('jsLocationDetails')"
							></i>
						</div>
						<input id="siteDetails" type="text" class="form-control" formControlName="jsLocationDetails" autocomplete="off" />
					</div>
				</div>

				<div class="form-group">
					<div class="input-label">
						<strong>Job Details</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('details')"></i>
						<!-- <span *ngIf="!jobForm.get('description').value" class="input-required float-right">(required)</span> -->
					</div>
					<div>
						<input id="jobDetails" type="text" class="form-control" formControlName="job_details" autocomplete="off" (keyup)="isFormValid()" />
					</div>
				</div>

				<!-- Begin Notification Profile Dropdown -->
				<div>
					<div class="form-group">
						<div class="options-block" style="margin-bottom: 15px">
							<div class="options-title-inline">Notification Profile</div>
							<i
								title="More Information"
								class="far fa-info-circle tts-info-btn"
								aria-hidden="true"
								(click)="showHelp('notificationProfile')"
							></i>
							<p-select
								appScrollLockSelect
								id="notificationProfiles"
								[options]="profilesDropdown"
								formControlName="notification_profile_id"
								[filter]="true"
								filterBy="label"
								(onChange)="updateProfile()"
							></p-select>
						</div>
					</div>
				</div>
				<!-- End Notification Profile Dropdown -->

				<!-- Begin Tour Dropdown -->
				<div class="options-block" style="margin-bottom: 30px">
					<div class="options-title-inline">Tour</div>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('tour_id')"></i>
					<p-select
						appScrollLockSelect
						id="notificationProfiles"
						[options]="toursDropdown"
						formControlName="tour_id"
						[filter]="true"
						filterBy="label"
					></p-select>
				</div>
				<!-- End Tour Dropdown -->

				<!-- Begin Call Center Options -->
				<div *ngIf="isCallCenterEnabled">
					<div class="options-section-header" style="margin-top: 15px">Dispatcher Checkpoints</div>

					<div class="row">
						<div class="col-9">
							<div class="form-group form-group-lg" style="padding-top: 5px">
								<strong>Outbound Call Interval</strong> <span class="text-muted tsf-time-abbrv">(min)</span>
								<i
									title="More Information"
									class="far fa-info-circle tts-info-btn"
									aria-hidden="true"
									(click)="showHelp('outbound_call_interval')"
								></i>
							</div>
						</div>
						<div class="col-3">
							<div class="form-group form-group-lg">
								<input
									style="width: 60px; text-align: right"
									id="breakLength"
									type="text"
									class="form-control float-right"
									formControlName="outbound_call_interval"
								/>
							</div>
						</div>
					</div>
				</div>
				<!-- End Call Center -->

				<!-- Begin Break Options -->
				<div class="options-section-header" style="margin-top: 15px">Break Options</div>

				<div class="row">
					<div class="col-9">
						<div class="form-group form-group-lg" style="padding-top: 5px">
							<strong>Break Length</strong> <span class="text-muted tsf-time-abbrv">(min)</span>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('breakTime')"></i>
						</div>
					</div>
					<div class="col-3">
						<div class="form-group form-group-lg">
							<input
								style="width: 60px; text-align: right"
								id="breakLength"
								type="text"
								class="form-control float-right"
								formControlName="break_time"
							/>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-9">
						<div class="form-group form-group-lg" style="padding-top: 5px">
							<strong>Auto-apply Break</strong> <span class="text-muted tsf-time-abbrv">(hr:min)</span>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('breakTimeWorked')"></i>
						</div>
					</div>
					<div class="col-3">
						<div class="form-group form-group-lg">
							<input
								style="width: 60px; text-align: right"
								id="breakTimeWorked"
								type="text"
								class="form-control float-right"
								formControlName="break_time_worked"
							/>
						</div>
					</div>
				</div>
				<!-- End Break Options -->

				<!-- Native to Job Record -->
				<div class="options-section-header" style="margin-top: 15px">Client / Vendor Options</div>

				<div *ngIf="hasClients" class="form-group">
					<div class="input-label">
						<strong>Client / Customer</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('clients')"></i>
						<!-- <span class="input-optional float-right">(optional)</span> -->
					</div>
					<p-select
						appScrollLockSelect
						id="clients"
						[options]="clientDropdown"
						formControlName="client_id"
						[filter]="true"
						filterBy="label"
					></p-select>
				</div>

				<div *ngIf="hasVendors" class="form-group">
					<div class="input-label">
						<strong>Vendor</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('vendors')"></i>
						<!-- <span class="input-optional float-right">(optional)</span> -->
					</div>
					<p-select
						appScrollLockSelect
						id="vendors"
						[options]="vendorDropdown"
						formControlName="vendor_id"
						[filter]="true"
						filterBy="label"
					></p-select>
				</div>

				<div *ngIf="isPayRateAvailable">
					<div class="options-section-header" style="margin-top: 30px">Bill / Pay Rate Options</div>

					<!-- Employee Pay Rate -->
					<div class="options-block form-spacer" style="margin-top: 15px">
						<div class="float-right">
							<span class="pay-rate-symbol tts-hl-2">{{ currencySymbol }}</span>
							<input
								type="number"
								class="form-control pay-rate-input"
								formControlName="employee_pay_rate"
								(blur)="formatRateInput('employee_pay_rate')"
							/>
						</div>
						<div style="padding-top: 4px">
							<strong>Base Employee Pay Rate</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('employeePayRate')"></i>
						</div>
					</div>

					<!-- Client Pay Rate -->
					<div class="options-block form-spacer" style="margin-top: 15px">
						<div class="float-right">
							<span class="pay-rate-symbol tts-hl-2">{{ currencySymbol }}</span>
							<input
								type="number"
								class="form-control pay-rate-input"
								formControlName="client_pay_rate"
								(blur)="formatRateInput('client_pay_rate')"
							/>
						</div>
						<div style="padding-top: 4px">
							<strong>Base Client Bill Rate</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('clientPayRate')"></i>
						</div>
					</div>

					<!-- Vendor Pay Rate -->
					<div class="options-block form-spacer" style="margin-top: 15px">
						<div class="float-right">
							<span class="pay-rate-symbol tts-hl-2">{{ currencySymbol }}</span>
							<input
								type="number"
								class="form-control pay-rate-input"
								formControlName="vendor_pay_rate"
								(blur)="formatRateInput('vendor_pay_rate')"
							/>
						</div>
						<div style="padding-top: 4px">
							<strong>Base Vendor Pay Rate</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('vendorPayRate')"></i>
						</div>
					</div>
				</div>

				<!-- End Billing Options -->

				<!-- Begin Budgeted Time Overage -->
				<div *ngIf="overageCalculationsEnabled" style="margin-top: 25px">
					<div class="options-section-header">Budgeted Time / Overage</div>
					<div class="row">
						<div class="col-8">
							<div style="max-width: 200px; line-height: 1.3em">
								<strong>Budgeted time for this job</strong>
								<span class="text-muted tsf-time-abbrv">(hr:min)</span
								><i
									title="More Information"
									class="far fa-info-circle tts-info-btn"
									aria-hidden="true"
									(click)="showHelp('trackOverage')"
								></i>
							</div>
						</div>
						<div class="col-4">
							<input style="width: 60px; text-align: right" class="form-control float-right" formControlName="overage_time" />
						</div>
					</div>

					<!-- Begin Notification Profile Dropdown -->
					<div *ngIf="this.jobForm.get('overage_time').value" style="margin-top: 25px">
						<div class="form-group">
							<div class="options-block" style="margin-bottom: 15px">
								<div class="options-title-inline">Budget Adjustment Rule</div>
								<i
									title="More Information"
									class="far fa-info-circle tts-info-btn"
									aria-hidden="true"
									(click)="showHelp('adjustHoursRule')"
								></i>
								<p-select
									appScrollLockSelect
									id="budgetAdjustmentRule"
									[options]="adjustHoursRuleDropdown"
									formControlName="adjust_hours_rule"
									[filter]="true"
									filterBy="label"
									(onChange)="updateProfile()"
								></p-select>
							</div>
						</div>
						<div class="link-text" (click)="viewBudgetRules()">See manual for rule descriptions</div>
					</div>

					<!-- <div *ngIf="this.jobForm.get('overage_time').value" class="options-block" style="margin-top: 25px">
						<div class="checkbox-item d-flex justify-content-between">
							<div class="checkbox-wrap">
								<p-checkbox formControlName="flat_rate" [binary]="true"></p-checkbox>
							</div>
							<div class="checkbox-label-wrap">
								<strong (click)="toggleCheckbox('flat_rate')" class="has-pointer"
									>Adjust hours worked on time entries to match hours budgeted</strong
								>
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('flatRate')"></i>
							</div>
						</div>
					</div> -->
				</div>
				<!-- End Budgeted Time Overage -->

				<div *ngIf="showAdpOptions()">
					<div class="options-section-header">ADP Options</div>

					<div class="row" *ngIf="adpDepartmentCodes.length > 1">
						<div class="col-12">
							<div class="form-group form-group-lg">
								<div class="input-label">
									<strong>Department Code</strong>
								</div>
								<p-select
									appScrollLockSelect
									[virtualScrollItemSize]="32"
									[virtualScroll]="true"
									[options]="adpDepartmentCodes"
									formControlName="adp_department_code_id"
									[filter]="true"
									filterBy="label"
									[resetFilterOnHide]="true"
								>
								</p-select>
							</div>
						</div>
					</div>
					<div class="row" *ngIf="adpRateCodes.length > 1">
						<div class="col-12">
							<div class="form-group form-group-lg">
								<div class="input-label">
									<strong>Rate Code</strong>
								</div>
								<p-select
									appScrollLockSelect
									[virtualScrollItemSize]="32"
									[virtualScroll]="true"
									[options]="adpRateCodes"
									formControlName="adp_rate_code_id"
									[filter]="true"
									filterBy="label"
									[resetFilterOnHide]="true"
								>
								</p-select>
							</div>
						</div>
					</div>
					<div *ngIf="false" class="row">
						<div class="row-divider">
							<hr />
						</div>
					</div>
				</div>

				<div *ngIf="showQBOOptions()">
					<div class="options-section-header">QuickBooks Options</div>

					<div *ngIf="qbCustomerDropdown.length > 1" style="margin-top: 20px">
						<div class="options-title-inline">Customer</div>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('qbo_customer')"></i>

						<p-select
							appScrollLockSelect
							id="qboCustomer"
							placeholder="Select a customer"
							[options]="qbCustomerDropdown"
							[virtualScroll]="true"
							[virtualScrollItemSize]="32"
							formControlName="qbo_customer_id"
							[filter]="true"
							filterBy="label"
						></p-select>
					</div>

					<div *ngIf="qbVendorDropdown.length > 1" style="margin-top: 20px">
						<div class="options-title-inline">Vendor</div>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('qbo_vendor')"></i>

						<p-select
							appScrollLockSelect
							id="qboVendor"
							placeholder="Select a vendor"
							[options]="qbVendorDropdown"
							[virtualScroll]="true"
							[virtualScrollItemSize]="32"
							formControlName="qbo_vendor_id"
							[filter]="true"
							filterBy="label"
						></p-select>
					</div>

					<div *ngIf="qbServiceItemDropdown.length > 1" style="margin-top: 20px">
						<div class="options-title-inline">Service Item</div>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('qbo_service_item')"></i>

						<p-select
							appScrollLockSelect
							id="qboServiceItem"
							placeholder="Select a service item"
							[options]="qbServiceItemDropdown"
							[virtualScroll]="true"
							[virtualScrollItemSize]="32"
							formControlName="qbo_service_item_id"
							[filter]="true"
							filterBy="label"
						></p-select>
					</div>

					<div *ngIf="false" style="margin-top: 15px">
						<div style="display: inline-block; padding-top: 7px"><strong>Select Union Zone</strong></div>
						<div class="float-right">
							<select class="form-control" formControlName="jsUnionZone">
								<option default [ngValue]="null">None</option>
								<option [ngValue]="1">Zone 1</option>
								<option [ngValue]="2">Zone 2</option>
								<option [ngValue]="3">Zone 3</option>
								<option [ngValue]="4">Zone 4</option>
								<option [ngValue]="5">Zone 5</option>
								<option [ngValue]="6">Zone 6</option>
								<option [ngValue]="7">Zone 7</option>
								<option [ngValue]="8">Zone 8</option>
							</select>
						</div>
					</div>
				</div>

				<div *ngIf="showTaxLocations || isNcciCodeAvailable">
					<div class="options-section-header">General Payroll Options</div>

					<!-- Work Tax Location -->
					<div *ngIf="showTaxLocations" style="width: 100%">
						<div class="input-label form-spacer">
							<div
								*ngIf="jobForm.get('work_tax_location').value"
								class="link-text float-right"
								(click)="jobForm.get('work_tax_location').setValue(null)"
							>
								clear
							</div>
							<strong>Work Tax Location</strong>
							<i
								title="More Information"
								class="far fa-info-circle tts-info-btn"
								aria-hidden="true"
								(click)="showHelp('work_tax_location')"
							></i>
						</div>
						<p-autoComplete
							appScrollLockAutoComplete
							placeholder="Work Tax Location"
							[inputStyleClass]="'p-autocomplete-mod'"
							formControlName="work_tax_location"
							[suggestions]="taxLocationOptions"
							[delay]="150"
							[completeOnFocus]="true"
							(completeMethod)="searchTaxLocation($event)"
						>
						</p-autoComplete>
					</div>

					<div *ngIf="isNcciCodeAvailable" style="width: 100%; margin-top: 20px">
						<div class="input-label">
							<strong>NCCI Code</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('ncci_code')"></i>
							<div *ngIf="ncciCode" class="float-right link-text" (click)="ncciCode = null">clear</div>
						</div>
						<p-autoComplete
							appScrollLockAutoComplete
							placeholder="search by code or description"
							[inputStyleClass]="'p-autocomplete-mod'"
							[(ngModel)]="ncciCode"
							[ngModelOptions]="{ standalone: true }"
							[suggestions]="ncciOptions"
							[forceSelection]="true"
							(completeMethod)="searchNcci($event)"
							(onSelect)="handleNcciSelect()"
							(onClear)="handleNcciClear()"
							field="descLong"
						>
						</p-autoComplete>
					</div>
				</div>
				<!-- End native to job record -->

				<div *ngIf="!isTravelJob">
					<div class="options-section-header">Miscellaneous Options</div>

					<div *ngIf="!isSystemJob" class="checkbox-item d-flex justify-content-between">
						<div class="checkbox-wrap">
							<p-checkbox formControlName="active" [binary]="true"></p-checkbox>
						</div>
						<div class="checkbox-label-wrap">
							<strong (click)="toggleCheckbox('active')" class="has-pointer">Mark job active</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('jobActive')"></i>
						</div>
					</div>

					<div *ngIf="showMultiDayJobOption && !isSystemJob" class="checkbox-item d-flex justify-content-between">
						<div class="checkbox-wrap">
							<p-checkbox formControlName="multi_day" [binary]="true"></p-checkbox>
						</div>
						<div class="checkbox-label-wrap">
							<strong (click)="toggleCheckbox('multi_day')" class="has-pointer">Enable multi-day job</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('multiDayJob')"></i>
						</div>
					</div>

					<div class="checkbox-item d-flex justify-content-between">
						<div class="checkbox-wrap">
							<p-checkbox formControlName="require_photo_checkin" [binary]="true"></p-checkbox>
						</div>
						<div class="checkbox-label-wrap">
							<strong (click)="toggleCheckbox('require_photo_checkin')" class="has-pointer">Require photo check in</strong>
							<i
								title="More Information"
								class="far fa-info-circle tts-info-btn"
								aria-hidden="true"
								(click)="showHelp('requirePhotoCheckin')"
							></i>
						</div>
					</div>

					<div *ngIf="isJobQRCodeAvailable" class="checkbox-item d-flex justify-content-between">
						<div class="checkbox-wrap">
							<p-checkbox formControlName="require_qrc_checkin" [binary]="true"></p-checkbox>
						</div>
						<div class="checkbox-label-wrap">
							<strong (click)="toggleCheckbox('require_qrc_checkin')" class="has-pointer">Require QR Code check in</strong>
							<!-- <i title="QR Code" class="far fa-qr-code tts-info-btn" aria-hidden="true" (click)="viewQRCode()"></i> -->
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('requireQrcCheck')"></i>
						</div>
					</div>

					<div class="checkbox-item d-flex justify-content-between">
						<div class="checkbox-wrap">
							<p-checkbox formControlName="chkpts_require_photo" [binary]="true"></p-checkbox>
						</div>
						<div class="checkbox-label-wrap">
							<strong (click)="toggleCheckbox('chkpts_require_photo')" class="has-pointer">Require photo checkpoints</strong>
							<i
								title="More Information"
								class="far fa-info-circle tts-info-btn"
								aria-hidden="true"
								(click)="showHelp('requirePhotoCheckpoints')"
							></i>
						</div>
					</div>

					<div class="checkbox-item d-flex justify-content-between">
						<div class="checkbox-wrap">
							<p-checkbox formControlName="require_photo_checkout" [binary]="true"></p-checkbox>
						</div>
						<div class="checkbox-label-wrap">
							<strong (click)="toggleCheckbox('require_photo_checkout')" class="has-pointer">Require photo check out</strong>
							<i
								title="More Information"
								class="far fa-info-circle tts-info-btn"
								aria-hidden="true"
								(click)="showHelp('requirePhotoCheckout')"
							></i>
						</div>
					</div>

					<div *ngIf="isJobQRCodeAvailable" class="checkbox-item d-flex justify-content-between">
						<div class="checkbox-wrap">
							<p-checkbox formControlName="require_qrc_checkout" [binary]="true"></p-checkbox>
						</div>
						<div class="checkbox-label-wrap">
							<strong (click)="toggleCheckbox('require_qrc_checkout')" class="has-pointer">Require QR Code check out</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('requireQrcCheck')"></i>
						</div>
					</div>
				</div>

				<!-- Form checkbox for landline numbers -->
				<div *ngIf="isJobSiteMerged && !isSystemJob" class="checkbox-item d-flex justify-content-between">
					<div class="checkbox-wrap">
						<p-checkbox
							[(ngModel)]="linkedNumberManager.linkLandlineCheckbox"
							[ngModelOptions]="{ standalone: true }"
							[binary]="true"
							(onChange)="linkedNumberManager.landlineCheckboxChanged()"
						></p-checkbox>
					</div>
					<div class="checkbox-label-wrap">
						<strong
							(click)="
								linkedNumberManager.linkLandlineCheckbox = !linkedNumberManager.linkLandlineCheckbox;
								linkedNumberManager.landlineCheckboxChanged()
							"
							class="has-pointer"
							>Link job site to landline numbers</strong
						>
						<i
							title="More Information"
							class="far fa-info-circle tts-info-btn"
							aria-hidden="true"
							(click)="showHelp('linkLandlineCheckbox')"
						></i>
					</div>
				</div>

				<div *ngIf="showLinkOnlyCheckbox && !isSystemJob" class="checkbox-item d-flex justify-content-between">
					<div class="checkbox-wrap" [class.checkbox-child]="isJobSiteMerged">
						<p-checkbox formControlName="linked_only" [binary]="true"></p-checkbox>
					</div>
					<div class="checkbox-label-wrap">
						<strong (click)="toggleCheckbox('linked_only')" class="has-pointer">Only allow linked numbers</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('linked_only')"></i>
					</div>
				</div>

				<div *ngIf="isJobSiteMerged && !isSystemJob">
					<app-job-site-edit-linked-numbers [manager]="linkedNumberManager" (showHelp)="showHelp($event)"></app-job-site-edit-linked-numbers>
				</div>
			</app-form-expander>

			<!-- End Advanced Options -->

			<app-form-expander *ngIf="pinnedReports.length > 0" [label]="'Pinned Reports'" [centerOnExpansion]="true">
				<!-- <div style="font-weight: 700; margin-top: -10px">
					<div class="float-right">Links</div>
					<div>Last Modified</div>
				</div> -->
				<div *ngFor="let report of pinnedReports; let idx = index" style="margin-bottom: 8px">
					<div class="card pinn-card">
						<div>
							<div>
								<strong>{{ formatReportDate(report.incident) }}</strong>
							</div>
							<div style="color: gray">
								<span class="link-text" (click)="viewPinnedReport(report)"><i class="far fa-eye"></i> Report</span>
								<span> | </span>
								<span class="has-pointer" [class.link-text]="report.incident.transaction_log_id" (click)="gotoTimeEntry(report)"
									><i class="far fa-eye"></i> Time Entry</span
								>
								<span> | </span>
								<span class="link-text" (click)="report.showDeleteConfirm = true"><i class="far fa-map-pin"></i> Unpin</span>
							</div>
							<div *ngIf="report.showDeleteConfirm" style="margin: 6px 0px">
								<hr style="margin: 12px 0px 16px 0px" />
								<div class="float-right">
									<button class="btn btn-sm btn-outline-danger" style="margin-right: 12px" (click)="report.showDeleteConfirm = false">
										No
									</button>
									<button class="btn btn-sm btn-outline-success" (click)="confirmUnpinReport(report)">Yes</button>
								</div>
								<div style="font-weight: 600; font-size: 1.3rem">Are you sure?</div>
							</div>
						</div>
					</div>
				</div>
			</app-form-expander>
		</form>

		<div *ngIf="isInternalUser && job?.created">
			<hr />
			<small>
				<div><strong>Created:</strong> {{ job.created | dateFormatter: 'YYYY-MM-DD @ h:mma' }}</div>
				<div><strong>Updated:</strong> {{ job.updated | dateFormatter: 'YYYY-MM-DD @ h:mma' }}</div>
			</small>
		</div>
	</div>

	<div *ngIf="isEditingNotifications">
		<app-notification-profile-edit
			[dialogManager]="dialogManager"
			[incrementJobLinkCount]="profileEdit.incrementLinkCount"
			[recordId]="profileEdit.recordId"
			[action]="profileEdit.action"
			[performParentAction]="false"
			(profileUpdated)="profileUpdated()"
		>
		</app-notification-profile-edit>
	</div>

	<div *ngIf="false" class="alert alert-info" style="margin-top: 25px">
		{{ jobForm.value | json }}
	</div>
</div>

<!-- <button (click)="getFormValidationErrors()">Validate</button> -->
<p-dialog
	[header]="'Confim Action'"
	[(visible)]="pinnedReportAction.showDialog"
	[modal]="false"
	[style]="{ width: '300px' }"
	[dismissableMask]="true"
>
	Are you sure you wish to unpin this report
</p-dialog>
