import { EmployeeRecord, OrganizationRecord, UserRecord } from '@app/models'
import { DatabaseService } from '@app/services'
import { SelectItem } from 'primeng/api'

import _ from 'lodash'

export class DropdownHelperConfig {
	dbSrvc: DatabaseService
	isRestricted = false
	includeInactive = false
	includeAnyEmployee = false // Only use for employee menu
	includeAnySupervisor = false // Only use for supervisor menu
	includeSystemRecords = false // Only used for job menu
	includeNullSelect: boolean = false // Used to include a null option
	includeIds: Array<number> = []

	constructor(dbSrvc: DatabaseService, dropdownType: 'DROPDOWN' | 'MULTISELECT') {
		this.dbSrvc = dbSrvc
		if (dropdownType === 'DROPDOWN') {
			this.includeNullSelect = true
		} else {
			this.includeNullSelect = false
		}
	}
}

export class DropdownHelper {
	dbSrvc: DatabaseService
	myUser: UserRecord
	managedUserIds: Array<number> = []

	isPrimaryOrInternalUser = false
	isRestricted = false
	includeInactive = false
	includeAnyEmployee = false // Only use for employee menu
	includeAnySupervisor = false // Only use for supervisor menu
	includeSystemRecords = false // Only used for job menu
	includeNullSelect = false
	nullOptionLabel = null // Change before building lists
	includeIds: Array<number> = []

	// Dropdowns using this helper must have supervisor_ids available on record
	// Restriction checks are done separately and passed in via dropdown config

	constructor(config: DropdownHelperConfig) {
		this.dbSrvc = config.dbSrvc
		this.myUser = this.dbSrvc.settingSrvc.getMyUser()
		this.managedUserIds = this.myUser.managed_supervisor_ids
		this.isPrimaryOrInternalUser = this.dbSrvc.settingSrvc.isPrimaryOrInternalUser()

		this.isRestricted = config.isRestricted
		this.includeInactive = config.includeInactive
		this.includeAnyEmployee = config.includeAnyEmployee
		this.includeAnySupervisor = config.includeAnySupervisor
		this.includeSystemRecords = config.includeSystemRecords
		this.includeNullSelect = config.includeNullSelect
		this.includeIds = config.includeIds
	}

	resetOptions() {
		// We don't include reseting the includeNullSelect since that depends on whether
		// this is a dropdown or multi-select which is set in the config constructor

		this.isRestricted = false
		this.includeInactive = false
		this.includeAnyEmployee = false // Only use for employee menu
		this.includeAnySupervisor = false // Only use for supervisor menu
		this.includeSystemRecords = false // Only used for job menu
		this.includeIds = []
	}

	buildEmployeeMenuOptions(): Array<SelectItem> {
		let workingList = this.dbSrvc.empSrvc.getAllEmployees()

		if (!this.includeInactive) {
			workingList = workingList.filter((emp) => emp.active)
		}

		// Filter if restricted
		if (this.isRestricted && !this.isPrimaryOrInternalUser) {
			workingList = workingList.filter(
				(emp) => this.managedUserIds.some((id) => emp.supervisor_ids.indexOf(id) !== -1) || this.includeIds.includes(emp.id),
			)
		}

		// Create SelectItem compatible object array
		const selectItems = workingList.map((emp) => {
			return {
				label: emp.name,
				value: emp.id,
				data: emp,
			}
		})

		// Setup labels for inactive
		if (this.includeInactive) {
			selectItems.forEach((si) => {
				if (!si.data.active) {
					si.label = '[INACTIVE] - ' + si.label
				}
			})
		}

		const sortedItems = _.sortBy(selectItems, 'label')

		if (this.includeAnyEmployee) {
			const anyEmp = this.dbSrvc.empSrvc.anyEmployee
			sortedItems.unshift({ label: anyEmp.name, value: anyEmp.id, data: anyEmp })
		}

		// Add Select and Employee option
		if (this.includeNullSelect) {
			const nullOptionLabel = this.nullOptionLabel ?? 'Select an Employee'
			sortedItems.unshift({ label: nullOptionLabel, value: null, data: null })
		}
		return sortedItems
	}

	buildGroupedEmployeeMenuOptions() {
		let workingList = this.dbSrvc.empSrvc.getAllEmployees()

		// Filter if restricted
		if (this.isRestricted && !this.isPrimaryOrInternalUser) {
			workingList = workingList.filter(
				(emp) => this.managedUserIds.some((id) => emp.supervisor_ids.indexOf(id) !== -1) || this.includeIds.includes(emp.id),
			)
		}

		const sortedList = _.sortBy(workingList, 'name')
		const activeList = sortedList
			.filter((emp) => emp.active)
			.map((emp) => {
				return {
					label: emp.name + ' (Active)',
					value: emp.id,
					data: emp,
				}
			})
		const inactiveList = sortedList
			.filter((emp) => !emp.active)
			.map((emp) => {
				return {
					label: emp.name + ' (Inactive)',
					value: emp.id,
					data: emp,
				}
			})
		return [
			{
				label: 'Active Employees',
				value: 'ACTIVE',
				items: [...activeList],
			},
			{
				label: 'Inactive Employees',
				value: 'INACTIVE',
				items: [...inactiveList],
			},
		]
	}

	buildJobMenuOptions(): Array<SelectItem> {
		let workingList = this.dbSrvc.jobSrvc.getAllJobs()

		// Filter on active if indicated
		if (!this.includeInactive) {
			workingList = workingList.filter((job) => job.active)
		}

		if (!this.includeSystemRecords) {
			workingList = workingList.filter((job) => !job.company_default && !job.travel)
		}

		// Filter if restricted
		if (this.isRestricted && !this.isPrimaryOrInternalUser) {
			workingList = workingList.filter(
				(job) => this.managedUserIds.some((id) => job.supervisor_ids.indexOf(id) !== -1) || this.includeIds.includes(job.id),
			)
		}

		// Create SelectItem compatible object array
		const selectItems = workingList.map((job) => {
			return {
				label: job.description,
				value: job.id,
				data: job,
			}
		})

		// Setup labels for inactive jobs
		if (this.includeInactive) {
			selectItems.forEach((si) => {
				if (!si.data.active) {
					si.label = '[INACTIVE] - ' + si.label
				}
			})
		}

		// Setup labels for system jobs
		if (this.includeInactive) {
			selectItems.forEach((si) => {
				if (si.data.company_default || si.data.travel) {
					si.label = '[SYSTEM] - ' + si.label
				}
			})
		}

		const sortedItems = _.sortBy(selectItems, 'label')

		// Add Select and Employee option
		if (this.includeNullSelect) {
			const nullOptionLabel = this.nullOptionLabel ?? 'Select a Job'
			sortedItems.unshift({ label: nullOptionLabel, value: null, data: null })
		}
		return sortedItems
	}

	buildGroupedJobMenuOptions() {
		let workingList = this.dbSrvc.jobSrvc.getAllJobs()

		if (!this.includeSystemRecords) {
			workingList = workingList.filter((job) => !job.company_default && !job.travel)
		}

		// Filter if restricted
		if (this.isRestricted && !this.isPrimaryOrInternalUser) {
			workingList = workingList.filter(
				(job) => this.managedUserIds.some((id) => job.supervisor_ids.indexOf(id) !== -1) || this.includeIds.includes(job.id),
			)
		}

		const sortedList = _.sortBy(workingList, 'description')
		const activeList = sortedList
			.filter((job) => job.active)
			.map((job) => {
				return {
					label: job.description + ' (Active)',
					value: job.id,
					data: job,
				}
			})
		const inactiveList = sortedList
			.filter((job) => !job.active)
			.map((job) => {
				return {
					label: job.description + ' (Inactive)',
					value: job.id,
					data: job,
				}
			})
		return [
			{
				label: 'Active Jobs',
				value: 'ACTIVE',
				items: [...activeList],
			},
			{
				label: 'Inactive Jobs',
				value: 'INACTIVE',
				items: [...inactiveList],
			},
		]
	}

	buildOrganizationDropdownMenuOptions(orgType: 'CLIENT' | 'VENDOR' | 'ALL'): Array<SelectItem> {
		let workingList = this.dbSrvc.orgSrvc.getOrganizations()

		const selectLabel = orgType === 'CLIENT' ? 'Select a client' : orgType === 'VENDOR' ? 'Select a vendor' : 'Select an organization'

		if (orgType === 'CLIENT') workingList = workingList.filter((rec) => rec.type === 'CLIENT')
		if (orgType === 'VENDOR') workingList = workingList.filter((rec) => rec.type === 'VENDOR')

		// Create SelectItem compatible object array
		const selectItems = workingList.map((org) => {
			return {
				label: org.name,
				value: org.id,
				data: org,
			}
		})
		selectItems.unshift({ label: selectLabel, value: null, data: null })

		return selectItems
	}

	buildSupervisorMenuOptions(): Array<SelectItem> {
		const includedIds = this.includeIds
		const availableUserIds = this.dbSrvc.settingSrvc.getAllUsersIncludingInternal().map((user) => user.id)

		const idList = [...new Set(includedIds.concat(availableUserIds))]
		const users = idList.map((id) => this.dbSrvc.settingSrvc.getUserForId(id)).filter((user) => user)

		const selectItems = users.map((user) => {
			return {
				label: user.first_name + ' ' + user.last_name,
				value: user.id,
				data: user,
			}
		})

		const sortedItems = _.sortBy(selectItems, 'label')

		if (this.includeAnySupervisor) {
			sortedItems.unshift({ label: 'Any Supervisor', value: 0, data: null })
		}

		if (this.includeNullSelect) {
			sortedItems.unshift({ label: 'Select a Supervisor', value: null, data: null })
		}
		return sortedItems
	}

	buildSupervisorGroupMenuOptions(): Array<SelectItem> {
		const selectItems = this.dbSrvc.settingSrvc.getUserGroups().map((group) => {
			return {
				label: group.description,
				value: group.id,
				data: group,
			}
		})
		const sortedItems = _.sortBy(selectItems, 'label')

		if (this.includeNullSelect) {
			sortedItems.unshift({ label: 'Select a Group', value: null, data: null })
		}
		return sortedItems
	}

	buildNotificationProfileOptions(): Array<SelectItem> {
		const selectItems = this.dbSrvc.npSrvc.getProfiles().map((profile) => {
			return {
				label: profile.name,
				value: profile.id,
				data: profile,
			}
		})
		const sortedItems = _.sortBy(selectItems, 'label')

		if (this.includeNullSelect) {
			sortedItems.unshift({ label: 'Select a Profile', value: null, data: null })
		}

		return sortedItems
	}
}
