import { Component, Input, OnInit } from '@angular/core'
import { log } from '@app/helpers'
import { HelpVideoRecord } from '@app/models'
import { DatabaseService } from '@app/services'

@Component({
    selector: 'app-help-video-link',
    templateUrl: './help-video-link.component.html',
    styleUrls: ['./help-video-link.component.scss'],
    standalone: false
})
export class HelpVideoLinkComponent implements OnInit {
	@Input() key: string

	linkUrl: string

	constructor(private dbSrvc: DatabaseService) {}

	ngOnInit(): void {
		this.linkUrl = this.dbSrvc.helpVidSrvc.getHelpVideoByKey(this.key)?.url
	}

	public linkBtnClicked() {
		log('Link button clicked for key', this.key)
		const url = this.linkUrl
		if (url) {
			window.open(url, '_helpVideoLink')
		}
	}
}
