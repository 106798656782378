import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { DataTablesHelper, DisplayHelper, GeneralTableHelper, log, TableActionFormatter } from '@app/helpers'
import {
	ComponentBridgeName,
	CrudAction,
	DialogManager,
	LocalPrefsData,
	LocalPrefsDialogData,
	LocalPrefsGroup,
	DatabaseTableName,
	UserGroupRecord,
} from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { Subscription } from 'rxjs'
import { SupervisorGroupsFormatter } from '../users-formatter'

import _ from 'lodash'

enum UserGroupTableColumnIndex {
	id,
	description,
	details,
	linkedUsers,
	linkedEmps,
	linkedJobSites,
	linkedJobs,
	actions,
}

@Component({
    selector: 'app-user-group-table',
    templateUrl: './group-table.component.html',
    styleUrls: ['./group-table.component.scss'],
    standalone: false
})
export class UserGroupTableComponent implements OnInit, OnDestroy, AfterViewInit {
	bridgeName: ComponentBridgeName = 'ngBridgeUserGroupTable'

	list: Array<UserGroupRecord> = []
	isDataLoaded = false

	editDialogManager = new DialogManager('UserGroupsDialog')
	editAction = { recordId: null, action: 'edit' }

	deleteAction = { tableName: 'supervisor_group' as DatabaseTableName, recordId: null, recordLabel: null, showDeleteRecordDialog: false }

	linkedRecordsDialogManager = new DialogManager('linkedRecordsModal')
	linkedRecordsDialog = { records: [], filteredRecords: [] }

	highlightMissingExternalIds = false

	managedUserIds = []

	isMergedJobSites = false

	private userGroupTable: any
	private defaultSortOrder = [[UserGroupTableColumnIndex.description, 'asc']]
	private defaultPageLength = 100

	debounceRedrawTable = _.debounce(this.redrawTable, 300)

	public sectionPrefsDialogManager = new DialogManager('sectionPrefsDialog')

	private subs = new Subscription()

	constructor(private coreSrvc: CoreService) {
		this.highlightMissingExternalIds = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany().globalHighlightMissingExtId
		this.isMergedJobSites = this.coreSrvc.dbSrvc.settingSrvc.getCompany().merge_jobsite

		this.setupSubscriptions()
		this.setupAccessPermissions()
		this.setupLocalPrefsDialog()
	}

	get dtFilterText(): string {
		const input = $('.search-field-input')
		return (input?.val() as string) || ''
	}

	ngOnInit() {
		window[this.bridgeName] = this
		this.loadData()
	}

	ngAfterViewInit() {
		this.initTable()

		$('#userGroupTable_filter input').attr('placeholder', ' filter')
		$('#userGroupTable_filter input').addClass('search-field-input')

		setTimeout(() => {
			$('#clear-search-icon').detach().appendTo('#userGroupTable_filter label')
		})

		$('#userGroupTable_filter input').on('keyup', () => {
			this.debounceRedrawTable()
		})

		this.fetchAndReloadData()
	}

	ngOnDestroy() {
		this.userGroupTable['fixedHeader'].disable()
		window[this.bridgeName] = null
		this.subs.unsubscribe()
	}

	private setupSubscriptions() {
		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	private setupAccessPermissions() {
		// this.accessHelper = new AccessHelper(this.coreSrvc.dbSrvc, 'supervisor_group')
		// this.accessHelper.updateSupervisorIds()
	}
	private setupLocalPrefsDialog(): void {
		this.sectionPrefsDialogManager.headerLabel = 'Preferences'
		const columnVisibilityItems = LocalPrefsData.getSupGroupTableColumnDisplayPrefs()
		const columnVisibilityGroup = new LocalPrefsGroup('Column Visibility', columnVisibilityItems)
		const dialogData = new LocalPrefsDialogData([columnVisibilityGroup])
		dialogData.localStorageKeyRemovalList = ['DataTables_userGroupTable']
		this.sectionPrefsDialogManager.dialogData = dialogData
	}

	private handleScreenSizeChanges() {
		if (this.userGroupTable) {
			this.userGroupTable.columns.adjust().responsive.recalc()
			this.userGroupTable.fixedHeader.adjust()
		}
	}

	public prefsDataSaved(): void {
		log('UpdatePrefsData')
		this.sectionPrefsDialogManager.isDialogVisible = false
		this.updateTable()
	}

	canPerformAction(action: CrudAction, isMyRecord: boolean) {
		// return this.accessHelper.canPerformAction(action, isMyRecord)
		return true
	}
	isMyRecord(id: number): boolean {
		return true
	}

	enableAllTooltips() {
		// Enable Tooltips
		const tooltips: any = $('.item-tooltip')
		tooltips.tooltip({ show: { effect: 'none', delay: 0 } })
		// Delay long enough to hide tooltips if cursor was hovering during page load
		setTimeout(() => {
			tooltips.tooltip('hide')
		}, 1000)
	}

	clearSearch(): boolean {
		this.userGroupTable
			.search('')
			.order([[UserGroupTableColumnIndex.description, 'asc']])
			.rows()
			.invalidate()
			.draw()
		this.enableAllTooltips()
		return false
	}

	fetchData(): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			this.coreSrvc.dbSrvc.bulkRead(['users', 'supervisor_group', 'supervisor_role']).then((result) => {
				this.coreSrvc.dbSrvc.settingSrvc.userGroupsLastUpdated = new Date()
				resolve(true)
			})
		})
	}

	loadData() {
		this.list = this.coreSrvc.dbSrvc.settingSrvc.getUserGroups()
		this.managedUserIds = this.coreSrvc.dbSrvc.settingSrvc.getManagedUserIds()
	}

	fetchAndReloadData() {
		this.fetchData().then((success) => {
			this.loadData()
			this.updateTable()
		})
	}

	redrawTable() {
		this.userGroupTable?.rows().invalidate().search(this.dtFilterText).draw(true)
		this.enableAllTooltips()
	}

	public updateTable() {
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadData()
			this.updateColumns()
		})
	}

	private updateColumns() {
		const details = this.coreSrvc.prefSrvc.data.supGroupsColDetailsVisible
		const linkedUsers = this.coreSrvc.prefSrvc.data.supGroupsColSupervisorsVisible
		const linkedEmps = this.coreSrvc.prefSrvc.data.supGroupsColEmployeesVisible
		const linkedJobSites = !this.isMergedJobSites && this.coreSrvc.prefSrvc.data.supGroupsColJobSitesVisible
		const linkedJobs = this.coreSrvc.prefSrvc.data.supGroupsColJobsVisible

		GeneralTableHelper.updateColumn(this.userGroupTable, UserGroupTableColumnIndex.details, details)
		GeneralTableHelper.updateColumn(this.userGroupTable, UserGroupTableColumnIndex.linkedUsers, linkedUsers)
		GeneralTableHelper.updateColumn(this.userGroupTable, UserGroupTableColumnIndex.linkedEmps, linkedEmps)
		GeneralTableHelper.updateColumn(this.userGroupTable, UserGroupTableColumnIndex.linkedJobSites, linkedJobSites)
		GeneralTableHelper.updateColumn(this.userGroupTable, UserGroupTableColumnIndex.linkedJobs, linkedJobs)

		this.userGroupTable.clear()
		this.userGroupTable.rows.add(this.list)
		this.userGroupTable.draw(false)
	}

	createRecord() {
		log('createRecord called')
		// if (!this.canPerformAction(CrudAction.create, null)) { this.accessHelper.notifyOperationNotAuthorized(); return }
		this.editAction.recordId = null
		this.editAction.action = 'new'
		this.editDialogManager.dialogData = this.editAction
		this.editDialogManager.headerLabel = 'Add Group'
		this.editDialogManager.isDialogVisible = true
	}

	// Bridge Methods
	public editRecord(id: number) {
		log('editRecord called')
		const isMyRecord = true // this.isMyRecord(id)
		const group = this.coreSrvc.dbSrvc.settingSrvc.getUserGroupById(id)
		if (group) {
			this.editAction.recordId = id
			this.editAction.action = 'edit'
			this.editDialogManager.headerLabel = group.description
			this.editDialogManager.dialogData = this.editAction
			this.editDialogManager.isDialogVisible = true
		}
		log('Edit Action', this.editAction)
		log('Edit Dialog Manager', this.editDialogManager)
	}

	recordUpdated() {
		log('recordUpdaated: Client record has been updated')
		// this.fetchAndReloadData()
		this.loadData()
		this.updateTable()
	}

	deleteRecord(id: number) {
		log('deleteRecord called')
		const group = this.coreSrvc.dbSrvc.settingSrvc.getUserGroupById(id)
		// const isLinked = this.coreSrvc.dbSrvc.jobSrvc.getJobsForOrganizationId(id).length > 0
		// if (isLinked) {
		// 	this.coreSrvc.notifySrvc.notify('error', 'Organization Assigned', 'In order to delete this organization you must unlink them from all jobs.', 5)
		// 	return
		// }
		if (group) {
			this.deleteAction.recordId = group.id
			this.deleteAction.recordLabel = group.description
			this.deleteAction.tableName = 'supervisor_group'
			this.deleteAction.showDeleteRecordDialog = true
		}
	}

	deleteActionComplete() {
		this.loadData()
		this.updateTable()
		this.deleteAction.showDeleteRecordDialog = false
	}

	deleteActionCancel() {
		this.deleteAction.showDeleteRecordDialog = false
	}

	// Manage Linked Records

	public filterLinkedRecords(searchText: string) {
		log('Search', searchText)
		if (!searchText) {
			this.linkedRecordsDialog.filteredRecords = [...this.linkedRecordsDialog.records]
		} else {
			this.linkedRecordsDialog.filteredRecords = this.linkedRecordsDialog.records.filter((rec: string) => rec.toLowerCase().includes(searchText))
		}
	}

	public showLinkedRecords(id: number, type: string) {
		let headerLabel = ''
		switch (type) {
			case 'supervisor':
				const group = this.coreSrvc.dbSrvc.settingSrvc.getUserGroupById(id)
				const supNames = group.supervisor_group_users.map((supId) => this.coreSrvc.dbSrvc.settingSrvc.getUsernameForUserId(supId))
				this.linkedRecordsDialog.records = supNames
				headerLabel = 'Linked Supervisors'
				break
			case 'employee':
				const empNames = this.coreSrvc.dbSrvc.empSrvc.getEmployeesByGroupId(id).map((emp) => emp.name)
				this.linkedRecordsDialog.records = empNames
				headerLabel = 'Linked Employees'
				break
			case 'jobsite':
				const siteNames = this.coreSrvc.dbSrvc.siteSrvc.getJobSitesByGroupId(id).map((site) => site.description)
				this.linkedRecordsDialog.records = siteNames
				headerLabel = 'Linked Job Sites'
				break
			case 'job':
				// const jobNames = this.coreSrvc.dbSrvc.jobSrvc.getJobsByGroupId(id).map(job => job.description)
				const jobSiteIdsForGroupId = this.coreSrvc.dbSrvc.siteSrvc.getJobSitesByGroupId(id).map((site) => site.id)
				const jobsList = this.coreSrvc.dbSrvc.jobSrvc.getJobsForJobSiteIds(jobSiteIdsForGroupId)
				const jobNames = jobsList.map((job) => job.description)
				this.linkedRecordsDialog.records = jobNames
				headerLabel = 'Linked Jobs'
				break
		}

		this.linkedRecordsDialog.filteredRecords = [...this.linkedRecordsDialog.records]

		this.linkedRecordsDialogManager.headerLabel = headerLabel
		this.linkedRecordsDialogManager.isSubmitBtnVisible = false
		this.linkedRecordsDialogManager.cancelBtnLabel = 'Close'
		this.linkedRecordsDialogManager.isDialogVisible = true
	}

	notifyOperationNotAuthorized() {
		this.coreSrvc.notifySrvc.default('operationNotAuthorized')
	}

	invalidateRowForRecord(group: UserGroupRecord) {
		log('Invalidated', group)
		const idx = this.list.indexOf(group)
		this.userGroupTable.rows(idx).invalidate().draw(false)
	}

	// DataTables Initialization

	private initTable() {
		this.coreSrvc.displaySrvc.startSectionLoader()
		this.userGroupTable = $('#userGroupTable').DataTable(<DataTables.Settings>{
			stateSave: false,
			responsive: true,
			processing: true,
			paging: true,
			pageLength: this.defaultPageLength,
			lengthChange: true,
			info: true,
			select: false,
			searching: true,
			fixedHeader: DataTablesHelper.fixedHeader,
			autoWidth: false,
			data: this.list,
			order: [[UserGroupTableColumnIndex.description, 'asc']],
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{
					responsivePriority: 1,
					targets: [UserGroupTableColumnIndex.description, UserGroupTableColumnIndex.linkedUsers],
				},
				{
					// ID
					targets: UserGroupTableColumnIndex.id,
					visible: false,
					searchable: true,
					title: 'ID',
					data: null,
					render: (rec: UserGroupRecord, t, f, m) => {
						return rec.id
					},
				},
				{
					// Description
					targets: UserGroupTableColumnIndex.description,
					visible: true,
					searchable: true,
					title: 'Name',
					data: null,
					render: (rec: UserGroupRecord, t, f, m) => {
						const name = rec.description || ''
						const externalId = rec.external_id ? `<div class="table-ext-id-normal">${rec.external_id}</div>` : ''
						const noExternalId =
							this.highlightMissingExternalIds && !rec.external_id ? `<div class="table-tag bg-chocolate">No ID Set</div>` : ''

						const nameHtml = `<div>${name}</div>`
						return `
						<div class="dtr-control-content">
							<div style="white-space:normal;line-height:1.3em;min-width: 100px;">${nameHtml}${externalId}${noExternalId}</div>
						</div>`
					},
				},
				{
					// Details
					targets: UserGroupTableColumnIndex.details,
					visible: this.coreSrvc.prefSrvc.data.supGroupsColDetailsVisible,
					searchable: this.coreSrvc.prefSrvc.data.supGroupsColDetailsVisible,
					title: 'Details',
					data: null,
					render: (rec: UserGroupRecord, t, f, m) => {
						return rec.details || ''
					},
				},
				{
					// Linked Supervisors
					targets: UserGroupTableColumnIndex.linkedUsers,
					visible: this.coreSrvc.prefSrvc.data.supGroupsColSupervisorsVisible,
					searchable: this.coreSrvc.prefSrvc.data.supGroupsColSupervisorsVisible,
					title: 'Linked<br>Supervisors',
					data: null,
					render: (rec: UserGroupRecord, t, f, m) => {
						return SupervisorGroupsFormatter.makeGroupsLinkedRecordsButton(
							this.coreSrvc.dbSrvc,
							this.bridgeName,
							this.dtFilterText,
							rec.id,
							'supervisor',
						)
					},
				},
				{
					// Linked Employees
					targets: UserGroupTableColumnIndex.linkedEmps,
					visible: this.coreSrvc.prefSrvc.data.supGroupsColEmployeesVisible,
					searchable: this.coreSrvc.prefSrvc.data.supGroupsColEmployeesVisible,
					title: 'Linked<br>Employees',
					data: null,
					render: (rec: UserGroupRecord, t, f, m) => {
						return SupervisorGroupsFormatter.makeGroupsLinkedRecordsButton(
							this.coreSrvc.dbSrvc,
							this.bridgeName,
							this.dtFilterText,
							rec.id,
							'employee',
						)
					},
				},
				{
					// Linked Job Sites
					targets: UserGroupTableColumnIndex.linkedJobSites,
					visible: !this.isMergedJobSites && this.coreSrvc.prefSrvc.data.supGroupsColJobSitesVisible,
					searchable: !this.isMergedJobSites && this.coreSrvc.prefSrvc.data.supGroupsColJobSitesVisible,
					title: 'Linked<br>Job Sites',
					data: null,
					render: (rec: UserGroupRecord, t, f, m) => {
						return SupervisorGroupsFormatter.makeGroupsLinkedRecordsButton(
							this.coreSrvc.dbSrvc,
							this.bridgeName,
							this.dtFilterText,
							rec.id,
							'jobsite',
						)
					},
				},
				{
					// Linked Jobs
					targets: UserGroupTableColumnIndex.linkedJobs,
					visible: this.coreSrvc.prefSrvc.data.supGroupsColJobsVisible,
					searchable: this.coreSrvc.prefSrvc.data.supGroupsColJobsVisible,
					title: 'Linked<br>Jobs',
					data: null,
					render: (rec: UserGroupRecord, t, f, m) => {
						return SupervisorGroupsFormatter.makeGroupsLinkedRecordsButton(
							this.coreSrvc.dbSrvc,
							this.bridgeName,
							this.dtFilterText,
							rec.id,
							'job',
						)
					},
				},
				{
					// Actions
					targets: UserGroupTableColumnIndex.actions,
					visible: true,
					searchable: false,
					orderable: false,
					title: 'Actions',
					data: null,
					render: (rec: UserGroupRecord, t, f, m) => {
						const recordId = rec.id
						const isMyRecord = true // this.isMyRecord(rec.id)

						const canAccessEdit = true // this.accessHelper.canPerformAction(CrudAction.update, isMyRecord)
						const editLink = TableActionFormatter.editLink(this.bridgeName, 'editRecord', recordId, canAccessEdit)

						const canAccessDelete = true // this.accessHelper.canPerformAction(CrudAction.delete, isMyRecord)
						const deleteLink = TableActionFormatter.deleteLink(this.bridgeName, 'deleteRecord', recordId, canAccessDelete)

						const auditLink = TableActionFormatter.auditLinkGlobal('supervisor_group', recordId, rec.description, true, !!rec.updated)

						return `<span class="act-ico-wrap">${editLink}${deleteLink}${auditLink}</span>`
					},
				},
			],
			drawCallback: (settings) => {
				this.userGroupTable?.columns.adjust().responsive.recalc()
				this.coreSrvc.displaySrvc.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
			},
		})
		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter('userGroupTable', this.userGroupTable)
	}
}
