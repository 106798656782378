import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { DateTimeHelper, DisplayHelper, log } from '@app/helpers'
import {
	C2CDirectoryLookup,
	C2CLogRecord,
	ClickToCallSourceConfig,
	ClickToCallDialogManager,
	ClickToCallRecord,
	DataAccessRequest,
	TransactionLogRecord,
	CallLogRecordWrap,
} from '@app/models'
import { CoreService } from '@app/services'
import _ from 'lodash'

@Component({
    selector: 'app-click-to-call-event-log',
    templateUrl: './click-to-call-event-log.component.html',
    styleUrls: ['./click-to-call-event-log.component.scss'],
    standalone: false
})
export class ClickToCallEventLogComponent implements OnInit {
	manager: ClickToCallDialogManager
	timezone = 'UTC'
	dateFormat = 'ddd MMM Do [at] h:mm a z'
	trans: TransactionLogRecord

	list: Array<CallLogRecordWrap> = []
	directory: C2CDirectoryLookup

	isDataLoaded = false

	@Output() placeCall = new EventEmitter<ClickToCallRecord>()
	@Output() startTextMsg = new EventEmitter<ClickToCallRecord>()

	constructor(private coreSrvc: CoreService) {
		this.manager = this.coreSrvc.callSrvc.c2cManager
		this.directory = new C2CDirectoryLookup(coreSrvc.dbSrvc)
	}

	ngOnInit(): void {}

	public dialogOnShow() {
		this.setupComponent()
	}

	public resetVm() {
		this.list = []
		this.isDataLoaded = false
	}

	public setupComponent() {
		this.dateFormat = DateTimeHelper.format12Hour ? 'ddd MMM Do [at] h:mm a z' : 'ddd MMM Do [at] HH:mm z'
		const config = this.manager.config
		switch (config.type) {
			case 'TRANS':
				this.setupComponentForTransaction(config)
				break
		}
	}

	private setupComponentForTransaction(config: ClickToCallSourceConfig) {
		const transId = config.sourceId
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(transId)
		this.trans = trans ? trans : null
		this.timezone = trans?.timezone ?? 'UTC'
		this.fetchData()
	}

	private fetchData() {
		this.isDataLoaded = false
		this.manager.eventStatus.isRefreshing = true
		const options = { transaction_log_id: this.trans.id }
		const request = new DataAccessRequest('click_to_call_log')
		request['transaction_log_id'] = this.trans?.id
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			const data: Array<C2CLogRecord> = result.data ?? []
			const records = data.map((rec) => new C2CLogRecord(rec))
			const sortedRecords = _.sortBy(records, 'created').reverse()
			const wrappedRecords = sortedRecords.map((clr) => new CallLogRecordWrap(clr, this.directory))
			log('Lambda Result', records)
			setTimeout(() => {
				this.list = wrappedRecords
				this.isDataLoaded = true
				this.manager.eventStatus.isRefreshing = false
			}, 500)
		})
	}
}
