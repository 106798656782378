<div class="options-block options-switch has-pointer" [style]="wrapStyle" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
	<div class="float-right" style="padding-top: 2px">
		<p-toggleswitch [(ngModel)]="model" binary="true" (ngModelChange)="emitChange()"></p-toggleswitch>
	</div>
	<div *ngIf="model" (click)="toggleSwitch()">
		<span class="options-title-inline png-clr-green" [style]="labelStyle">{{ enabledLabel }}</span>
	</div>
	<div *ngIf="!model" (click)="toggleSwitch()">
		<span class="options-title-inline png-clr-firebrick" [style]="labelStyle">{{ disabledLabel }}</span>
	</div>
	<div class="options-switch-hint" (click)="toggleSwitch()">
		<div *ngIf="showLabelHint">{{ hintLabel }}</div>
	</div>
</div>
