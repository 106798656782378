<div class="card raised-card response-card" [class.card-selected]="card.selected" [class.card-expanded]="card.expanded">
	<div class="response-card-body">
		<div class="float-right d-flex justify-content-between" style="cursor: pointer">
			<div *ngIf="enableSelect" class="select-icon" (click)="card.selected = !card.selected">
				<i *ngIf="card.selected" class="fa fa-check"></i>
			</div>
			<div class="expand-icon" (click)="card.expanded = !card.expanded">
				<i *ngIf="!card.expanded" class="fas fa-chevron-right"></i>
				<i *ngIf="card.expanded" class="fas fa-chevron-down"></i>
			</div>
		</div>
		<div class="name-label">
			<i
				class="fa-duotone fa-circle-xmark close-btn"
				[class.close-active]="card.closeHover"
				(mouseenter)="card.closeHover = true"
				(mouseleave)="card.closeHover = false"
				(click)="removeCard.next(card)"
				style="margin-right: 6px"
			></i
			><span class="has-pointer" (click)="card.expanded = !card.expanded">{{ card.employee.name }}</span>
		</div>
		<div class="sub-ital-label">{{ card.distLabel }}</div>
		<div class="active-status" [class.as-inactive]="!card.employee.active">{{ card.employee.active ? 'active' : 'inactive' }}</div>
		<div style="margin-top: 6px" *ngIf="card.tags.length > 0">
			<hr style="margin: 12px 0px 16px 0px" />
			<div
				*ngFor="let tag of card.tags"
				data-bs-html="true"
				class="badge form-tag mb-1 mr-1 item-tooltip"
				[class.form-tag-expire-set]="tag.expire"
				[class.form-tag-expired]="tag.isExpired"
				[title]="tag.expireTooltip"
			>
				<span class="tag-label">{{ (tag.label ?? '').toUpperCase() }}</span>
			</div>
		</div>
		<div *ngIf="card.expanded">
			<hr style="margin: 10px 0px 14px 0px" />
			<div class="detail-block" style="margin-bottom: 12px">
				<div>
					<div class="float-right">
						<i
							title="Place Call"
							class="far fa-phone-office c2c-ico link-text"
							style="margin-right: 4px"
							(click)="clickToCall(card.employee, 'CALL')"
						></i>
						<i title="Send Text" class="far fa-message-dots c2c-ico link-text" (click)="clickToCall(card.employee, 'TEXT')"></i>
					</div>
					<div>{{ card.employee.phone_number_e164 | displayPhone }}</div>
				</div>
			</div>
			<div class="detail-block"><strong>Details:</strong> {{ card.employee.employee_details ?? 'no detail information' }}</div>
		</div>
	</div>
</div>
