<div class="container">
	<div class="card fi-card">
		<div class="options-header-large">File Importer</div>
		<hr />

		<div *ngIf="!isDataLoaded" class="loading-block"><i class="fas fa-loader fa-spin"></i> Loading Data ...</div>

		<div *ngIf="!isIntegrated && isDataLoaded">
			<div class="tts-dev-banner">
				<div>File Import Not Integrated</div>
			</div>
		</div>

		<div *ngIf="isIntegrated && isDataLoaded">
			<!-- <div>
				<button class="btn btn-sm btn-success btn-block" (click)="syncNow()">
					<i *ngIf="!isWorking.syncNow" class="fa fa-cloud-download" aria-hidden="true"></i>
					<i *ngIf="isWorking.syncNow" class="fas fa-loader fa-spin" aria-hidden="true"></i>
					Sync Now
				</button>
			</div> -->

			<div>
				Import reports will be emailed to: <strong class="tts-hl-2">{{ vm.emailAddressList }}</strong>
			</div>
			<hr style="margin-bottom: 25px" />

			<!-- Employee Data -->
			<div>
				<div class="row">
					<div class="col-md-6 col-sm-12 sync-label">Employee Data</div>
					<div class="col-md-6 col-sm-12 sync-label">
						<i class="far fa-info-circle tts-info-btn sync-info-btn" (click)="vm.showEmpTooltip = !vm.showEmpTooltip"></i>
						<span>Last import on:</span>
						<div style="font-size: 0.8em">
							{{ vm.record.employeeSyncDate ? vm.formatSyncDate(vm.record.employeeSyncDate) : 'Awaiting Initial Import' }}
						</div>
					</div>
				</div>

				<div style="margin-top: 20px">
					<button
						*ngIf="vm.record.employeeSyncErrors > 0"
						class="btn btn-block btn-sm btn-danger int-log-btn"
						[routerLink]="['/admin/integrations/log/fileimport/Employee']"
					>
						View Log ( {{ vm.record.employeeSyncErrors }} Error{{ vm.record.employeeSyncErrors > 1 ? 's' : '' }} )
					</button>
					<button
						*ngIf="vm.record.employeeSyncErrors === 0"
						class="btn btn-block btn-sm btn-secondary int-log-btn"
						[routerLink]="['/admin/integrations/log/fileimport/Employee']"
					>
						View Log ( No Errors )
					</button>
				</div>
				<div class="row" *ngIf="vm.showEmpTooltip">
					<div class="col-12 int-tooltip-block text-muted">
						When uploading an employee data file, the filename must contain <strong>employee</strong> to be processed properly.
					</div>
				</div>
				<hr class="int-log-divider" />
			</div>

			<!-- Job Data -->
			<div>
				<div class="row">
					<div class="col-md-6 col-sm-12 sync-label">Job Data</div>
					<div class="col-md-6 col-sm-12 sync-label">
						<i class="far fa-info-circle tts-info-btn sync-info-btn" (click)="vm.showJobTooltip = !vm.showJobTooltip"></i>
						<span>Last import on:</span>
						<div style="font-size: 0.8em">
							{{ vm.record.jobSyncDate ? vm.formatSyncDate(vm.record.jobSyncDate) : 'Awaiting Initial Import' }}
						</div>
					</div>
				</div>

				<div style="margin-top: 20px">
					<button
						*ngIf="vm.record.jobSyncErrors > 0"
						class="btn btn-block btn-sm btn-danger int-log-btn"
						[routerLink]="['/admin/integrations/log/fileimport/Job']"
					>
						View Log ( {{ vm.record.jobSyncErrors }} Error{{ vm.record.jobSyncErrors > 1 ? 's' : '' }} )
					</button>
					<button
						*ngIf="vm.record.jobSyncErrors === 0"
						class="btn btn-block btn-sm btn-secondary int-log-btn"
						[routerLink]="['/admin/integrations/log/fileimport/Job']"
					>
						View Log ( No Errors )
					</button>
				</div>
				<div class="row" *ngIf="vm.showJobTooltip">
					<div class="col-12 int-tooltip-block text-muted">
						When uploading a job data file, the filename must contain <strong>job</strong> to be processed properly.
					</div>
				</div>
				<hr class="int-log-divider" />
			</div>

			<!-- Supervisor Data -->
			<div>
				<div class="row">
					<div class="col-md-6 col-sm-12 sync-label">Supervisor Data</div>
					<div class="col-md-6 col-sm-12 sync-label">
						<i class="far fa-info-circle tts-info-btn sync-info-btn" (click)="vm.showSupervisorTooltip = !vm.showSupervisorTooltip"></i>
						<span>Last import on:</span>
						<div style="font-size: 0.8em">
							{{ vm.record.supervisorSyncDate ? vm.formatSyncDate(vm.record.supervisorSyncDate) : 'Awaiting Initial Import' }}
						</div>
					</div>
				</div>

				<div style="margin-top: 20px">
					<button
						*ngIf="vm.record.supervisorSyncErrors > 0"
						class="btn btn-block btn-sm btn-danger int-log-btn"
						[routerLink]="['/admin/integrations/log/fileimport/Supervisor']"
					>
						View Log ( {{ vm.record.supervisorSyncErrors }} Error{{ vm.record.supervisorSyncErrors > 1 ? 's' : '' }} )
					</button>
					<button
						*ngIf="vm.record.supervisorSyncErrors === 0"
						class="btn btn-block btn-sm btn-secondary int-log-btn"
						[routerLink]="['/admin/integrations/log/fileimport/Supervisor']"
					>
						View Log ( No Errors )
					</button>
				</div>
				<div class="row" *ngIf="vm.showSupervisorTooltip">
					<div class="col-12 int-tooltip-block text-muted">View log information related to the synchronization of supervisor data.</div>
				</div>
				<hr class="int-log-divider" />
			</div>

			<!-- Time Entries Data -->
			<div>
				<div class="row">
					<div class="col-md-6 col-sm-12 sync-label">Time Entries Data</div>
					<div class="col-md-6 col-sm-12 sync-label">
						<i class="far fa-info-circle tts-info-btn sync-info-btn" (click)="vm.showTransLogTooltip = !vm.showTransLogTooltip"></i>
						<span>Last import on:</span>
						<div style="font-size: 0.8em">
							{{ vm.record.transactionsSyncDate ? vm.formatSyncDate(vm.record.transactionsSyncDate) : 'Awaiting Initial Import' }}
						</div>
					</div>
				</div>

				<div style="margin-top: 20px">
					<button
						*ngIf="vm.record.transactionsSyncErrors > 0"
						class="btn btn-block btn-sm btn-danger int-log-btn"
						[routerLink]="['/admin/integrations/log/fileimport/TransactionLog']"
					>
						View Log ( {{ vm.record.transactionsSyncErrors }} Error{{ vm.record.transactionsSyncErrors > 1 ? 's' : '' }} )
					</button>
					<button
						*ngIf="vm.record.transactionsSyncErrors === 0"
						class="btn btn-block btn-sm btn-secondary int-log-btn"
						[routerLink]="['/admin/integrations/log/fileimport/TransactionLog']"
					>
						View Log ( No Errors )
					</button>
				</div>
				<div class="row" *ngIf="vm.showTransLogTooltip">
					<div class="col-12 int-tooltip-block text-muted">View log information related to the synchronization of employee time entries.</div>
				</div>
				<hr class="int-log-divider" />
			</div>

			<!-- Vacation Data -->
			<div>
				<div class="row">
					<div class="col-md-6 col-sm-12 sync-label">Vacation Data</div>
					<div class="col-md-6 col-sm-12 sync-label">
						<i class="far fa-info-circle tts-info-btn sync-info-btn" (click)="vm.showVacationTooltip = !vm.showVacationTooltip"></i>
						<span>Last import on:</span>
						<div style="font-size: 0.8em">
							{{ vm.record.vacationSyncDate ? vm.formatSyncDate(vm.record.vacationSyncDate) : 'Awaiting Initial Import' }}
						</div>
					</div>
				</div>

				<div style="margin-top: 20px">
					<button
						*ngIf="vm.record.vacationSyncErrors > 0"
						class="btn btn-block btn-sm btn-danger int-log-btn"
						[routerLink]="['/admin/integrations/log/fileimport/Vacation']"
					>
						View Log ( {{ vm.record.vacationSyncErrors }} Error{{ vm.record.vacationSyncErrors > 1 ? 's' : '' }} )
					</button>
					<button
						*ngIf="vm.record.vacationSyncErrors === 0"
						class="btn btn-block btn-sm btn-secondary int-log-btn"
						[routerLink]="['/admin/integrations/log/fileimport/Vacation']"
					>
						View Log ( No Errors )
					</button>
				</div>
				<div class="row" *ngIf="vm.showVacationTooltip">
					<div class="col-12 int-tooltip-block text-muted">View log information related to the synchronization of vacation data.</div>
				</div>
				<hr class="int-log-divider" />
			</div>

			<div class="fi-uploader">
				<app-file-import-uploader (uploadComplete)="uploadComplete()"></app-file-import-uploader>
			</div>

			<!-- Customer Credentials -->
			<div *ngIf="vm.credentials.show">
				<hr class="int-log-divider" />
				<div style="margin-bottom: 16px; line-height: 1.2em; color: #8f826c">
					<div>
						These are the credentials for accessing your company's AWS S3 file import bucket programatically. Use the green buttons to copy the
						information to your clipboard.
					</div>
				</div>
				<div style="margin-top: 12px">
					<div class="fi-cred-header">AWS Bucket</div>
					<div style="text-align: center">
						<div class="row fi-cred-row">
							<div class="col-xs-12 col-md-10">
								<input
									readonly
									type="text"
									class="form-control fi-cred-input spanner-tm"
									style="text-align: center"
									[ngModel]="vm.credentials.awsBucket"
									id="awsBucket"
								/>
							</div>
							<div class="col-xs-12 col-md-2">
								<button class="btn btn-sm btn-success btn-block fi-copy-btn spanner-tm" (click)="vm.copyCredential('awsBucket')">
									<i class="fa fa-clipboard"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div style="margin-top: 12px">
					<div class="fi-cred-header">AWS Key</div>
					<div style="text-align: center">
						<div class="row fi-cred-row">
							<div class="col-xs-12 col-md-10">
								<input
									readonly
									type="text"
									class="form-control fi-cred-input spanner-tm"
									style="text-align: center"
									[ngModel]="vm.credentials.awsKey"
									id="awsKey"
								/>
							</div>
							<div class="col-xs-12 col-md-2">
								<button class="btn btn-sm btn-success btn-block fi-copy-btn spanner-tm" (click)="vm.copyCredential('awsKey')">
									<i class="fa fa-clipboard"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div style="margin-top: 12px">
					<div class="fi-cred-header">
						AWS Secret Key
						<i
							style="margin-left: 6px"
							class="fa link-text has-pointer"
							[class.fa-eye]="vm.hideSecretKey"
							[class.fa-eye-slash]="!vm.hideSecretKey"
							(click)="vm.hideSecretKey = !vm.hideSecretKey"
						>
						</i>
					</div>

					<div style="text-align: center">
						<div class="row fi-cred-row">
							<div class="col-xs-12 col-md-10">
								<input
									readonly
									type="text"
									class="form-control fi-cred-input spanner-tm"
									[class.hide-input]="vm.hideSecretKey"
									style="text-align: center"
									[ngModel]="vm.credentials.awsSecret"
									id="awsSecret"
								/>
							</div>
							<div class="col-xs-12 col-md-2">
								<button class="btn btn-sm btn-success btn-block fi-copy-btn spanner-tm" (click)="vm.copyCredential('awsSecret')">
									<i class="fa fa-clipboard"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
				<!-- <hr style="margin: 30px 0px;"> -->
			</div>
		</div>
	</div>
</div>
