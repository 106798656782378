<div [hidden]="!hasMarkers" class="urr-block no-page-break">
	<div style="margin: 12px">
		<div *ngIf="mapReady && markerCount > 0 && !areAllMarkersVisible" class="zoom-icon">
			<i title="Zoom Checkpoints" class="far fa-bullseye map-icon-zoom" (click)="zoomToFitAllMarkers()"></i>
		</div>
		<div *ngIf="gpsCheckinMarker" class="legend-icon-wrap has-pointer" (click)="panFromLegend('IN')">
			<img src="/assets/img/marker-black-i.png" class="legend-icon" /> Check-In
		</div>
		<div *ngIf="gpsCheckoutMarker" class="legend-icon-wrap has-pointer" (click)="panFromLegend('OUT')">
			<img src="/assets/img/marker-black-o.png" class="legend-icon" /> Check-Out
		</div>
		<div *ngIf="jobSiteMarker" class="legend-icon-wrap has-pointer" (click)="panFromLegend('SITE')">
			<img src="/assets/img/marker-green.png" class="legend-icon" /> Job Site
		</div>
		<div *ngIf="checkpointMarkers.length > 0" class="legend-icon-wrap">
			<img src="/assets/img/marker-blue.png" class="legend-icon" /> Checkpoint
		</div>
	</div>
	<div>
		<div id="eventMap" #eventMap class="event-map-box"></div>
	</div>
	<!-- Timeline navigation when we have less than 20 events -->
	<div *ngIf="events.length > 1 && !useArrowNav" style="margin-bottom: -30px">
		<p-timeline [value]="events" layout="horizontal" align="bottom">
			<ng-template pTemplate="content" let-event>
				<img [src]="event.image" class="tl-event-icon" (click)="panFromTimeline(event)" />
			</ng-template>
		</p-timeline>
	</div>
	<!-- Arrow navigation when we have more than 20 events -->
	<div *ngIf="events.length > 1 && useArrowNav" style="margin-bottom: -30px">
		<div class="d-flex justify-content-evenly flex-nowrap arrow-nav-wrap">
			<!-- <div>Check-In</div> -->
			<div>
				<i class="fas fa-arrow-left arrow-icon link-text" style="margin-right: 12px" (click)="previousEvent()"></i>
				<i class="fas fa-arrow-right arrow-icon link-text" style="margin-left: 12px" (click)="nextEvent()"></i>
			</div>
			<!-- <div>Check-Out</div> -->
		</div>
	</div>
</div>
