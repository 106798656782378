<p-dialog
	id="event-actions-dialog"
	styleClass="p-dialog-noheader p-dialog-whitebackground c2c-trigger-host"
	header="Event Dialog"
	[(visible)]="manager.showDialog"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
	transitionOptions="0ms"
>
	<div>
		<div *ngIf="viewState.processingView">
			<div style="text-align: center; font-size: 1.2em; color: slategray"><i class="fas fa-loader fa-spin"></i> Processing...</div>
		</div>

		<div *ngIf="viewState.mainMenu">
			<div class="float-right">
				<!-- <div class="event-menu-icon" *ngIf="shift.hasAuditLog">
					<i class="far fa-database act-ico act-ico-dark-hover event-icon-audit" (click)="auditRecord()"></i>
				</div> -->
				<div *ngIf="shift.isShiftEnabled && !shift.isShiftOver" class="event-menu-icon" (click)="toggleNotifications()">
					<i *ngIf="shift.isNotifyEnabled" class="far fa-bell event-icon-notify event-notify-enabled"></i>
					<i *ngIf="!shift.isNotifyEnabled" class="fas fa-bell-slash event-icon-notify event-notify-disabled"></i>
				</div>
			</div>

			<div class="event-info" style="min-height: 180px">
				<div style="position: relative; top: 6px; margin-bottom: 18px; margin-right: 50px">
					<div
						class="table-tag option-tag no-text-select"
						[class.option-tag-active]="viewState.option === 'EMP'"
						(click)="viewState.toggleOption('EMP')"
					>
						Emp
					</div>
					<div
						class="table-tag option-tag no-text-select"
						[class.option-tag-active]="viewState.option === 'JOB'"
						(click)="viewState.toggleOption('JOB')"
					>
						Job
					</div>
					<!-- <div class="table-tag option-tag no-text-select" [class.option-tag-active]="viewState.option === 'ORG'" (click)="viewState.toggleOption('ORG')">Org</div> -->
				</div>

				<div class="event-content">
					<div *ngIf="viewState.option === 'EMP'">
						<div class="event-info-block">
							<div class="event-info-label">
								<span>Employee</span>
								<i
									*ngIf="hasGlobalAccount && shift.empId"
									class="fal fa-globe float-right event-icon-global link-text"
									(click)="gotoGolbalView()"
								></i>
							</div>
							<div class="event-info-hl">
								<span>{{ shift.empName }}</span>
								<span *ngIf="!shift.emp.active" style="color: chocolate"> (Inactive)</span>
							</div>
							<div *ngIf="viewState.canAccessCallBtn && shift.empPhoneE164" class="float-right">
								<i title="Place Call" class="far fa-phone-office c2c-ico link-text" (click)="triggerC2CEvent('EMPLOYEEPHONE', 'CALL')"></i>
								<i title="Send Text" class="far fa-message-dots c2c-ico link-text" (click)="triggerC2CEvent('EMPLOYEEPHONE', 'TEXT')"></i>
							</div>
							<div *ngIf="manager.scheduleLog">{{ shift.empPhoneE164 | displayPhone }}</div>
							<!-- <div *ngIf="shift.origEmp" class="event-emp-sub">Filling in for {{ shift.origEmp }}</div> -->
						</div>

						<div *ngIf="shift.empSup" class="event-info-block">
							<div class="event-info-label">Employee Supervisor</div>
							<div class="event-info-hl">{{ shift.empSupName }}</div>
							<div *ngIf="viewState.canAccessCallBtn && shift.empSupPhoneE164" class="float-right">
								<i
									title="Place Call"
									class="far fa-phone-office c2c-ico link-text"
									(click)="triggerC2CEvent('EMPLOYEESUPERVISOR', 'CALL')"
								></i>
								<i
									title="Send Text"
									class="far fa-message-dots c2c-ico link-text"
									(click)="triggerC2CEvent('EMPLOYEESUPERVISOR', 'TEXT')"
								></i>
							</div>
							<div *ngIf="manager.scheduleLog">{{ shift.empSupPhoneE164 | displayPhone }}</div>
						</div>
					</div>

					<div *ngIf="viewState.option === 'JOB'">
						<div class="event-info-block">
							<div class="event-info-label">Job</div>
							<div class="event-info-hl">{{ shift.jobName }}</div>
						</div>

						<div *ngIf="shift.jobSiteSup" class="event-info-block">
							<div class="event-info-label">Job Site Supervisor</div>
							<div class="event-info-hl">{{ shift.jobSiteSupName }}</div>
							<div *ngIf="viewState.canAccessCallBtn && shift.jobSiteSupPhoneE164" class="float-right">
								<i title="Place Call" class="far fa-phone-office c2c-ico link-text" (click)="triggerC2CEvent('SITESUPERVISOR', 'CALL')"></i>
								<i title="Send Text" class="far fa-message-dots c2c-ico link-text" (click)="triggerC2CEvent('SITESUPERVISOR', 'TEXT')"></i>
							</div>
							<div *ngIf="manager.scheduleLog">{{ shift.jobSiteSupPhoneE164 | displayPhone }}</div>
						</div>
					</div>

					<div *ngIf="viewState.option === 'ORG'">
						<div class="event-info-block">
							<div class="event-info-label">Client Contact</div>
							<div class="event-info-hl">{{ shift.jobName }}</div>
						</div>
					</div>
				</div>
			</div>

			<div *ngIf="false">
				<div class="alert alert-warning">This shift has completed and may no longer be modified.</div>
			</div>

			<div *ngIf="true">
				<!-- Advanced Shift Edit -->
				<div>
					<!-- DEPRECATED 20240405 - Doesn't work with override check on time off collision -->
					<!-- <button
						*ngIf="!shift.isShiftEnabled"
						class="btn btn-sm btn-block btn-outline-success"
						style="margin-bottom: 8px"
						(click)="enableShift()"
					>
						Enable Shift
					</button> -->
					<!-- <button
						*ngIf="shift.isShiftEnabled"
						class="btn btn-sm btn-block btn-outline-danger"
						style="margin-bottom: 8px"
						(click)="disableShift()"
					>
						Disable Shift
					</button> -->

					<button class="btn btn-sm btn-block btn-outline-success" style="margin-bottom: 8px" (click)="editShift()">Edit Shift</button>

					<button class="btn btn-sm btn-block btn-outline-secondary" style="margin-bottom: 8px" (click)="viewState.switchTo('extendedMenu')">
						More Options
					</button>
					<div *ngIf="shift.origEmp" class="shift-modified">Filling in for {{ shift.origEmp }}</div>
					<div *ngIf="hasShiftBeenModified && !shift.origEmp" class="shift-modified">shift has been modified</div>
					<div *ngIf="!hasShiftBeenModified" class="shift-matches">shift matches schedule</div>
				</div>
			</div>
		</div>

		<div *ngIf="viewState.extendedMenu">
			<!-- <button
				*ngIf="!shift.schedLog.isShiftOver()"
				class="btn btn-sm btn-block btn-outline-success"
				style="margin-bottom: 8px"
				(click)="viewState.switchTo('assignEmployee')"
			>
				Assign Shift
			</button> -->

			<!-- Shift Offer Options -->
			<div *ngIf="canAccessOpenShiftFeature && canShowShiftOfferBtns">
				<div *ngIf="!hasShiftBeenOffered && !shift.schedLog.isShiftOver()">
					<button class="btn btn-sm btn-block btn-outline-success" style="margin-bottom: 8px" (click)="offerShift()" style="margin-bottom: 8px">
						Offer Shift
					</button>
				</div>

				<div *ngIf="hasShiftBeenOffered">
					<div class="d-flex justify-content-evenly">
						<button class="btn btn-sm btn-block btn-outline-secondary" style="margin-bottom: 8px; margin-right: 4px" (click)="cancelOffer()">
							Cancel Offer
						</button>
						<button class="btn btn-sm btn-block btn-outline-secondary" style="margin-bottom: 8px; margin-left: 4px" (click)="manageOffer()">
							<span *ngIf="!didClickManage">Manage Offer</span>
							<i *ngIf="didClickManage" class="fas fa-loader fa-spin"></i>
						</button>
					</div>
				</div>
			</div>

			<button
				*ngIf="shift.schedLog.isShiftOver()"
				class="btn btn-sm btn-block btn-outline-danger"
				style="margin-bottom: 8px"
				(click)="deleteShift()"
			>
				Delete Shift
			</button>
			<button class="btn btn-sm btn-block btn-outline-primary" style="margin-bottom: 8px" (click)="auditRecord()">Audit Shift</button>
			<button
				*ngIf="!!shift.schedLog.schedule_id"
				class="btn btn-sm btn-block btn-outline-success"
				style="margin-bottom: 8px"
				(click)="editSeries()"
			>
				Edit Schedule
			</button>
			<button class="btn btn-sm btn-block btn-outline-secondary" style="margin-top: 12px" (click)="viewState.switchTo('mainMenu')">Go Back</button>
		</div>

		<div *ngIf="viewState.assignEmployee" style="min-height: 328px" class="event-info">
			<div class="event-info-hl" style="margin-bottom: 16px">
				<span>Assign Shift To</span>
			</div>

			<p-select
				appScrollLockSelect
				placeholder="Assign Employee"
				selectedItemsLabel="Assign Shift To: {0}"
				[autofocusFilter]="devDetect.isDesktop()"
				[options]="employeeOptions"
				[filter]="true"
				filterBy="label"
				[(ngModel)]="selectedEmployee"
			>
			</p-select>
			<div style="height: 30px; margin: 16px 2px 0px 2px">
				<div *ngIf="viewState.canAccessCallBtn && selectedEmployee" class="float-right">
					<i title="Place Call" class="far fa-phone-office c2c-ico link-text" (click)="callOrTextEmpSelectedForAssignment('CALL')"></i>
					<i title="Send Text" class="far fa-message-dots c2c-ico link-text" (click)="callOrTextEmpSelectedForAssignment('TEXT')"></i>
				</div>
				<div>
					{{ selectedEmpForAssignmentPhoneE164 | displayPhone }}
				</div>
			</div>
			<div style="margin: 2px">
				<div class="menu-notes" style="margin: 5px 0px 10px 0px">You may select a specific employee or assign this shift to any employee.</div>
				<div style="margin-top: 30px; height: 75px">
					<div
						*ngIf="selectedEmployee || selectedEmployee === 0"
						class="btn btn-sm btn-block btn-outline-success"
						style="margin-bottom: 12px"
						(click)="assignShift()"
					>
						Assign Shift
					</div>
					<button class="btn btn-sm btn-block btn-outline-secondary" (click)="viewState.switchTo('extendedMenu')">Go Back</button>
				</div>
			</div>
		</div>

		<div *ngIf="viewState.confirmClose">
			<div style="font-size: 1.2em; margin-bottom: 25px; line-height: 1.3em">
				Are you sure you want to disable this shift which will stop notifications?
			</div>
			<div class="d-flex justify-content-between">
				<button class="btn btn-sm btn-outline-secondary" (click)="confirmDisableShift(false)">Go Back</button>
				<button class="btn btn-sm btn-outline-danger" (click)="confirmDisableShift(true)">Disable Shift</button>
			</div>
		</div>

		<div *ngIf="viewState.confirmDelete">
			<div style="font-size: 1.2em; margin-bottom: 25px; line-height: 1.3em">Are you sure you want to delete this shift?</div>
			<div class="d-flex justify-content-between">
				<button class="btn btn-sm btn-outline-secondary" (click)="confirmDelete(false)">Go Back</button>
				<button class="btn btn-sm btn-outline-danger" (click)="confirmDelete(true)">Delete Shift</button>
			</div>
		</div>

		<div *ngIf="viewState.confirmCancelOffer">
			<div style="font-size: 1.2em; margin-bottom: 25px; line-height: 1.3em">Are you sure you want to cancel this offer?</div>
			<div class="d-flex justify-content-between">
				<button class="btn btn-sm btn-outline-secondary" (click)="confirmCancelOffer(false)">Go Back</button>
				<button class="btn btn-sm btn-outline-danger" (click)="confirmCancelOffer(true)">Cancel Offer</button>
			</div>
		</div>
	</div>
</p-dialog>

<!-- <p-menu [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" #menu [popup]="true" [model]="menuItems"></p-menu> -->
