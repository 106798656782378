import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { DropdownHelper, DropdownHelperConfig, log } from '@app/helpers'
import {
	DialogManager,
	DashboardRecord,
	DatabaseTableName,
	EditFormAction,
	DashboardEditRecordUpdateEvent,
	HelpDialogMessage,
	VizDashboardHelper,
} from '@app/models'
import { CoreService } from '@app/services'
import { SelectItem } from 'primeng/api'

import _ from 'lodash'

@Component({
    selector: 'app-viz-dashboard-edit',
    templateUrl: './viz-dashboard-edit.component.html',
    styleUrl: './viz-dashboard-edit.component.scss',
    standalone: false
})
export class VizDashboardEditComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
	resource: DatabaseTableName = 'dashboard'
	isUpdating = false
	form: UntypedFormGroup

	showUserGroups = false
	supervisorOptions: Array<SelectItem> = []
	userGroupOptions: Array<SelectItem> = []
	supervisorsMultiSelect: Array<SelectItem> = []

	dashboardCreatorName = null
	shareWithAnySupervisor = false

	// If both a record and recordId are provided, the record will take precedence
	// and the recordId will be ignored. If neither is provided, a new record will
	// be created. If only recordId is provided, the record will be retrieved from
	// the corosponding service. The defaut is to provide a recordId.

	@Input() recordId: number
	@Input() record: DashboardRecord
	@Input() action: EditFormAction = 'edit'

	@Input() dialogManager: DialogManager

	@Output() recordUpdated = new EventEmitter<DashboardEditRecordUpdateEvent>()

	constructor(
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
	) {}

	get isNew(): boolean {
		return !this.record?.id
	}

	public ngOnInit(): void {
		this.setupComponent()
		this.setupForm()
		this.setupSupervisorsDropdown()
		this.setupUserGroupDropdown()
		this.setupSupervisorsMultiSelect()

		log('RECORD', this.record)
		const dashboardCreator = this.coreSrvc.dbSrvc.settingSrvc.getUsernameForUserId(this.record?.creator_user_id)
		this.dashboardCreatorName = dashboardCreator && dashboardCreator !== 'Unknown' ? dashboardCreator : null
	}

	public ngAfterViewInit(): void {}

	public ngAfterContentInit(): void {
		this.setupDialogManager()
	}

	public ngOnDestroy(): void {}

	public setupComponent() {
		const record = this.record ? this.record : this.coreSrvc.dbSrvc.vizSrvc.getDashboardRecordById(this.recordId)
		this.record = new DashboardRecord(record)

		if (this.action === 'clone') {
			this.record.id = null
			this.record.name = record.name + ' (copy)'
			this.record.shared_supervisor_ids = []
			this.record.supervisor_group_id = null
		}
	}

	public setupForm() {
		const record = this.record
		const myUserId = this.coreSrvc.dbSrvc.settingSrvc.getMyUserId()
		const defaultSupId = record.id && (record.supervisor || record.supervisor === 0) ? record.supervisor : myUserId
		log('Supervisor/ID/Default', record.id, record.supervisor, defaultSupId)

		this.form = this.fb.group({
			id: [record?.id],
			name: [record?.name],

			supervisor: [defaultSupId],
			supervisor_group_id: [record?.supervisor_group_id],
			shared_supervisor_ids: [record?.shared_supervisor_ids ?? []],
		})
	}

	public setupDialogManager() {
		this.dialogManager.canSubmit = () => this.isFormValid()
		this.dialogManager.submitBtnAction = () => this.submit()
	}

	private setupSupervisorsDropdown() {
		const myUserId = this.coreSrvc.dbSrvc.settingSrvc.getMyUserId()
		const config = new DropdownHelperConfig(this.coreSrvc.dbSrvc, 'DROPDOWN')
		config.includeIds = [myUserId]
		config.includeNullSelect = false
		config.includeAnySupervisor = true
		const dropdownHelper = new DropdownHelper(config)
		const options = dropdownHelper.buildSupervisorMenuOptions()
		this.supervisorOptions = options
	}

	private setupUserGroupDropdown() {
		this.userGroupOptions = this.coreSrvc.dbSrvc.settingSrvc.getUserGroupsDropdownData(true)
		if (this.userGroupOptions.length > 1) {
			this.showUserGroups = true
		}
		log('userGroupOptions', this.userGroupOptions)
	}

	private setupSupervisorsMultiSelect() {
		const dropdown = this.coreSrvc.dbSrvc.settingSrvc.getUsers().map((user) => {
			return {
				label: user.first_name + ' ' + user.last_name,
				value: user.id,
			}
		})
		const sortedDropdown = _.sortBy(dropdown, 'label')
		this.supervisorsMultiSelect = sortedDropdown
	}

	public isFormValid(): boolean {
		return this.form.valid
	}

	public submit() {
		// Guard against double submission
		if (this.isUpdating) return

		const record = this.makeUpdateRecord()
		log('Record to submit', record)
		if (record) {
			if (this.isNew) {
				log('Insert record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.insertRecord(this.resource, record).then((success) => {
					if (success) {
						this.recordUpdated.emit({ action: this.action, recordId: this.record?.id })
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			} else {
				log('Update record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.updateRecord(this.resource, record).then((success) => {
					if (success) {
						this.recordUpdated.emit({ action: this.action, recordId: this.record?.id })
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			}
		}
	}

	public makeUpdateRecord(): DashboardRecord {
		const record = new DashboardRecord(this.record)

		// Update any values changed by the form here
		record.name = this.form.get('name').value
		record.supervisor = this.form.get('supervisor').value
		record.supervisor_group_id = this.form.get('supervisor_group_id').value
		record.shared_supervisor_ids = this.form.get('shared_supervisor_ids').value

		// If supervisor is set to 'Any Supervisor', reset supervisor group and shared supervisors
		if (record.supervisor === 0) {
			record.supervisor_group_id = null
			record.shared_supervisor_ids = []
		}

		return record
	}

	public updateShareWIthAnySupervisor() {
		log('Share With Any Supervisor', this.shareWithAnySupervisor)
		if (this.shareWithAnySupervisor) {
			this.form.get('shared_supervisor_ids').setValue([0])
		} else {
			this.form.get('shared_supervisor_ids').setValue([])
		}
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'supervisor':
				help.header = 'Dashboard Owner'
				help.message = `The owner of this dashboard may modify it's settings. Select 'Any Supervisor' to allow anyone to modify it.<br><br>To share this dashboard with everyone without allowing modifications, first set yourself as owner and then select the <b>Share with all supervisors</b> checkbox.`
				break
			case 'supervisor_group_id':
				help.header = 'Share with Group'
				help.message = 'Optionally assign a supervisor group which will allow members of this group to view this dashboard.'
				break
			case 'shared_supervisor_ids':
				help.header = 'Share with Supervisors'
				help.message = 'Optionally share this dashboard with individual supervisors which will allow them to view this dashboard.'
				break
			case 'share_with_all':
				help.header = 'Share with All'
				help.message = 'Optionally share this dashboard with all supervisors which will allow them to view this dashboard.'
				break
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
