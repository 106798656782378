import { Injectable, EventEmitter } from '@angular/core'
import { ContactRecord, ContactsViewTabState, ContactsViewManager } from '@app/models/contact'

import { log } from '@app/helpers'
import _ from 'lodash'
import { SelectItem } from 'primeng/api/selectitem'
import { Global } from '@app/models/global'

@Injectable({
	providedIn: 'root',
})
export class ContactService {
	dataLoaded = false
	list: Array<ContactRecord> = []
	listChange = new EventEmitter<Array<ContactRecord>>()
	lastUpdated: Date

	viewManager = new ContactsViewManager()

	constructor() {
		log('Creating ContactService')
	}

	clearData() {
		this.list = []
		this.dataLoaded = false
		this.lastUpdated = null
	}

	getContactDropdownData(): Array<SelectItem> {
		return this.getContacts().map((contact) => ({ label: contact.name, value: contact.id, data: contact }))
	}

	getContacts(): Array<ContactRecord> {
		const result = _.sortBy(this.list, 'name')
		return result
	}

	getContactsByViewState(state: ContactsViewTabState) {
		switch (state) {
			case 'INDIVIDUALS':
				return this.getContacts()
		}
	}

	getContactById(id: number) {
		return this.list.find((rec) => rec.id === id)
	}

	getContactByName(name: string) {
		return this.list.find((rec) => rec.name === name)
	}

	getContactByE164Number(phoneE164: string) {
		return this.list.find((rec) => rec.phone_e164 === phoneE164)
	}

	getContactByEmailAddress(email: string) {
		return this.list.find((rec) => rec.email === email)
	}

	removeLocalContactRecord(recordId: number) {
		this.list = this.list.filter((rec) => rec.id !== recordId)
	}

	setContactRecords(records: Array<ContactRecord>) {
		this.lastUpdated = new Date()
		this.list = records.map((rec) => new ContactRecord(rec))
		this.dataLoaded = true
		this.listChange.next(this.list)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateContactRecords(records: Array<ContactRecord>) {
		const newRecords = records.map((rec) => new ContactRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.list.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.list.push(newRecord)
			}
		}
		this.lastUpdated = new Date()
		this.listChange.next(this.list)
	}
}
