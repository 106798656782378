import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-activity-banner',
    templateUrl: './activity-banner.component.html',
    styleUrls: ['./activity-banner.component.scss'],
    standalone: false
})
export class ActivityBannerComponent {
	@Input() isProcessing = false
	@Input() message = ''
	@Input() messageBlockClass = 'ab-message-block'
	@Input() messageTextClass = ''
	@Input() iconClass = 'fas fa-loader fa-spin ab-icon'
}
