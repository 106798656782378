import { Component, EventEmitter, Input, Output } from '@angular/core'
import { log } from '@app/helpers'
import { DatabaseTableName } from '@app/models'
import { CoreService } from '@app/services'

@Component({
    selector: 'app-contact-support-dialog',
    templateUrl: './contact-support-dialog.component.html',
    styleUrl: './contact-support-dialog.component.scss',
    standalone: false
})
export class ContactSupportDialogComponent {
	@Input() showDialog = false
	@Input() resource: DatabaseTableName = null
	@Input() recordId = 0
	@Input() title = ''

	@Output() showDialogChange = new EventEmitter<boolean>()

	message = ''

	constructor(private coreSrvc: CoreService) {}

	cancelBtnClicked() {
		this.showDialog = false
		this.message = ''
		this.showDialogChange.emit(this.showDialog)
	}

	submitBtnClicked() {
		log('submitBtnClicked')
		if (this.resource === 'transaction_log') {
			const protocol = window.location.protocol
			const host = window.location.host
			const path = this.coreSrvc.dbSrvc.tranSrvc.getTransactionLinkPath(this.recordId)
			const url = `${protocol}//${host}/#${path}`
			this.coreSrvc.dbSrvc.settingSrvc.emailSupportForTimeEntry(url, this.message)
			this.showDialog = false
			this.message = ''
			this.showDialogChange.emit(this.showDialog)
		} else {
			this.coreSrvc.dbSrvc.settingSrvc.emailSupportForGenericRecord('Calls / Texts', 'click_to_call_log', this.recordId, this.message)
			this.showDialog = false
			this.message = ''
			this.showDialogChange.emit(this.showDialog)
		}
	}
}
