import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { log, MapHelper } from '@app/helpers'
import { MapEvent, ShiftSummaryMapDataSource, ShiftSummaryReportEventItem } from '@app/models'
import { DeviceDetectorService } from 'ngx-device-detector'

import _ from 'lodash'
import { Global } from '@app/models/global'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-user-report-event-map',
    templateUrl: './user-report-event-map.component.html',
    styleUrls: ['./user-report-event-map.component.scss'],
    standalone: false
})
export class UserReportEventMapComponent implements OnInit, OnDestroy {
	@Input() publicView = false
	@Input() dataSource: ShiftSummaryMapDataSource
	@Output() viewTimelineEvent = new EventEmitter<MapEvent>()
	@ViewChild('eventMap', { static: true }) gmapElement: any
	map: google.maps.Map

	public jobSiteMarker: google.maps.marker.AdvancedMarkerElement
	public gpsCheckinMarker: google.maps.marker.AdvancedMarkerElement
	public gpsCheckoutMarker: google.maps.marker.AdvancedMarkerElement
	public checkpointMarkers: Array<google.maps.marker.AdvancedMarkerElement> = []

	topZIndex = google.maps.Marker.MAX_ZINDEX

	events: Array<MapEvent> = []
	mapReady = false

	currentMarker: google.maps.marker.AdvancedMarkerElement = null

	useArrowNav = false

	private subs = new Subscription()

	constructor(private deviceDetect: DeviceDetectorService) {
		this.subs.add(
			Global.coreSrvc.eventSrvc.shiftSummaryEventListItemClicked.subscribe((event) => this.handleShiftSummaryEventListItemClicked(event)),
		)
	}

	get hasMarkers(): boolean {
		return !!this.jobSiteMarker || !!this.gpsCheckinMarker || !!this.gpsCheckoutMarker || this.checkpointMarkers.length > 0
	}

	get areAllMarkersVisible(): boolean {
		const markers = [this.jobSiteMarker, this.gpsCheckinMarker, this.gpsCheckoutMarker, ...this.checkpointMarkers].filter((marker) => !!marker)
		const bounds = new google.maps.LatLngBounds()
		let areAllInBounds = true
		for (const marker of markers) {
			const position = marker.position
			if (!this.map.getBounds().contains(position)) areAllInBounds = false
		}
		return areAllInBounds
	}
	get markerCount(): number {
		return [this.jobSiteMarker, this.gpsCheckinMarker, this.gpsCheckoutMarker, ...this.checkpointMarkers].filter((marker) => !!marker).length
	}

	ngOnInit(): void {
		this.setupMapData()
		this.setupEventTimeline(this.dataSource)
		this.selectNavigationStyle()
		// setTimeout(() => {
		this.initMap()
		this.addJobSiteMarker()
		this.addGpsLocationMarker('IN')
		this.addGpsLocationMarker('OUT')
		this.addCheckpointMarkers()
		setTimeout(() => {
			this.zoomToFitAllMarkers()
			setTimeout(() => {
				if (this.jobSiteMarker) this.jobSiteMarker.zIndex = this.topZIndex++
				this.setRegionZoomIfNecessary()
				this.mapReady = true
			}, 600)
		}, 600)
		// }, 600)
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	private setupMapData() {}

	private setRegionZoomIfNecessary() {
		if (!this.hasMarkers) {
			const noMapCenter = MapHelper.getNoMapCenterFromRegion(this.dataSource.countryCode)
			const center = new google.maps.LatLng(noMapCenter.lat, noMapCenter.lng)
			const zoom = noMapCenter.zoom
			this.map.setCenter(center)
			this.map.setZoom(zoom)
		}
	}

	private setupEventTimeline(dataSource: ShiftSummaryMapDataSource) {
		let index = 0
		const trans = dataSource.transaction
		const checkIn = trans.actual_start // && trans.geo_start_latitude
		const checkOut = trans.actual_end // && trans.geo_end_latitude
		const checkpoints = _.orderBy(dataSource.checkpoints, 'effectiveDateString')
		if (checkIn) this.events.push(new MapEvent(index++, 'IN', trans.actual_start, '/assets/img/marker-black-i.png', trans.getImages('IN')))
		let cpIndex = 0
		for (const cp of checkpoints) {
			// if (cp.geo_latitude) {
			const event = new MapEvent(index++, 'CP', cp.created, '/assets/img/marker-blue.png', cp.getImages())
			event.cpIndex = cpIndex++
			event.cpId = cp.id
			event.hasGps = !!cp.geo_latitude
			this.events.push(event)
			// }
		}
		if (checkOut) this.events.push(new MapEvent(index++, 'OUT', trans.actual_end, '/assets/img/marker-black-o.png', trans.getImages('OUT')))
		// log('Events', this.events)

		// this.events = this.events.slice(0, 30) // Used for testing marker navigation count
	}

	private handleShiftSummaryEventListItemClicked(event: ShiftSummaryReportEventItem) {
		log('Got Event', event)
		if (event.type === 'IN') {
			if (this.gpsCheckinMarker) {
				this.panToMarker(this.gpsCheckinMarker)
				const tlEvent = this.getMapEventForMarker(this.gpsCheckinMarker)
				this.viewTimelineEvent.next(tlEvent)
				return
			}
		}
		if (event.type === 'OUT') {
			if (this.gpsCheckoutMarker) {
				this.panToMarker(this.gpsCheckoutMarker)
				const tlEvent = this.getMapEventForMarker(this.gpsCheckoutMarker)
				this.viewTimelineEvent.next(tlEvent)
				return
			}
		}
		if (event.type === 'CP') {
			const marker = this.checkpointMarkers.find((m) => m['checkpointId'] === event.cp.id)
			if (marker) {
				this.panToMarker(marker)
				const tlEvent = this.getMapEventForMarker(marker)
				this.viewTimelineEvent.next(tlEvent)
				return
			}
		}
		Global.coreSrvc.notifySrvc.notify('info', 'No GPS', 'No GPS information available.', 3)
	}

	private selectNavigationStyle() {
		const width = window.innerWidth
		const count = this.events.length
		// log('width/count', width, count)
		let useArrowNav = false

		if (width <= 768 && count > 10) {
			useArrowNav = true
		} else if (width <= 1024 && count > 20) {
			useArrowNav = true
		} else if (width <= 1024 && count > 20) {
			useArrowNav = true
		} else if (width > 1024 && count > 30) {
			useArrowNav = true
		}

		this.useArrowNav = useArrowNav
		// log('useArrowNav', useArrowNav)
	}

	private initMap() {
		const styles: any = [
			{
				featureType: 'poi',
				stylers: [
					{
						visibility: 'off',
					},
				],
			},
			{
				featureType: 'poi.business',
				stylers: [
					{
						visibility: 'on',
					},
				],
			},
			{
				featureType: 'transit',
				stylers: [
					{
						visibility: 'off',
					},
				],
			},
		]
		const showExtraControls = this.deviceDetect.isMobile() ? false : true
		const jobSite = this.dataSource.jobSite
		const hasCenter = jobSite ? jobSite.geo_latitude : null
		const noMapCenter = MapHelper.getNoMapCenterFromRegion(this.dataSource.countryCode)
		const mapProp = {
			mapId: 'eventMap',
			gestureHandling: 'greedy',
			center: hasCenter
				? new google.maps.LatLng(jobSite.geo_latitude, jobSite.geo_longitude)
				: new google.maps.LatLng(noMapCenter.lat, noMapCenter.lng),
			zoom: 16, // hasCenter ? 16 : noMapCenter.zoom,
			// styles: styles,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			streetViewControl: false,
			zoomControl: showExtraControls,
			clickableIcons: false,
			// mapTypeControl: showExtraControls,
			mapTypeControlOptions: {
				style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
				mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID],
			},
		}
		this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp)

		this.map.addListener('click', () => {
			this.closeAllInfoWindows()
		})
	}

	public panFromLegend(type: 'SITE' | 'IN' | 'OUT') {
		switch (type) {
			case 'SITE':
				if (this.jobSiteMarker) this.panToMarker(this.jobSiteMarker)
				this.viewTimelineEvent.next(null)
				break
			case 'IN':
				const inEvent = this.events.find((event) => event.type === 'IN')
				const inMarker = this.gpsCheckinMarker
				if (inMarker && inEvent) {
					this.panToMarker(inMarker)
					this.viewTimelineEvent.next(inEvent)
				}
				break
			case 'OUT':
				const outEvent = this.events.find((event) => event.type === 'OUT')
				const outMarker = this.gpsCheckoutMarker
				if (outEvent && outMarker) {
					this.panToMarker(outMarker)
					this.viewTimelineEvent.next(outEvent)
				}
		}
	}

	public panFromTimeline(mapEvent: MapEvent) {
		log('Pan from timeline', mapEvent)
		let marker: google.maps.marker.AdvancedMarkerElement = null
		switch (mapEvent.type) {
			case 'IN':
				marker = this.gpsCheckinMarker
				break
			case 'CP':
				marker = this.checkpointMarkers.find((cpm) => cpm['checkpointId'] === mapEvent.cpId)
				break
			case 'OUT':
				marker = this.gpsCheckoutMarker
				break
		}

		// const markers = [this.gpsCheckinMarker, ...this.checkpointMarkers, this.gpsCheckoutMarker].filter((m) => !!m)
		// const index = mapEvent.index
		// const marker = markers[index]
		if (marker) {
			this.panToMarker(marker)
		} else {
			this.closeAllInfoWindows()
			Global.coreSrvc.notifySrvc.notify('info', 'No GPS', 'No GPS information available.', 3)
		}
		this.viewTimelineEvent.next(mapEvent)
	}

	public panToMarker(marker: google.maps.marker.AdvancedMarkerElement) {
		this.closeAllInfoWindows()
		if (marker) {
			marker.zIndex = this.topZIndex++
			const latLng = marker.position
			this.map.panTo(latLng)
			this.openInfoWindow(marker)
			const mapEventType = marker['mapEventType'] as 'IN' | 'OUT' | 'CP'
			log('Pan Event', mapEventType)
			switch (mapEventType) {
				case 'IN':
					const inEvent = this.events.find((event) => event.type === 'IN')
					this.viewTimelineEvent.next(inEvent)
					break
				case 'OUT':
					const outEvent = this.events.find((event) => event.type === 'OUT')
					this.viewTimelineEvent.next(outEvent)
					break
				case 'CP':
					const cpIndex = marker['mapEventCpIndexe'] as number
					const cpEvent = this.events.find((event) => event.cpIndex === cpIndex)
					this.viewTimelineEvent.next(cpEvent)
			}
		}
	}

	private isInfoWindowOpen(infoWindow) {
		const map = infoWindow.getMap()
		return map !== null && typeof map !== 'undefined'
	}

	private closeAllInfoWindows() {
		this.jobSiteMarker?.['infoWindow']?.close()
		this.gpsCheckinMarker?.['infoWindow']?.close()
		this.gpsCheckoutMarker?.['infoWindow']?.close()
		this.checkpointMarkers?.forEach((marker) => {
			marker['infoWindow'].close()
		})
		this.viewTimelineEvent.next(null)
	}

	public zoomToFitAllMarkers() {
		const markers = [this.jobSiteMarker, this.gpsCheckinMarker, this.gpsCheckoutMarker, ...this.checkpointMarkers].filter((marker) => !!marker)
		const bounds = new google.maps.LatLngBounds()
		let needsZoom = false
		for (const marker of markers) {
			const position = marker.position
			if (!this.map.getBounds().contains(position)) needsZoom = true
			bounds.extend(position)
		}
		// If everything is in view then no need to zoom out
		if (!needsZoom) return
		this.map.fitBounds(bounds)
		setTimeout(() => {
			const currentZoom = this.map.getZoom() ?? 17
			this.map.setZoom(currentZoom - 1)
		}, 500)
	}

	private addJobSiteMarker() {
		const site = this.dataSource.jobSite
		if (!site || !site.geo_latitude) return

		const result = MapHelper.buildJobSiteMarker(this.map, site)
		const marker = result.marker
		const infoWindow = result.infoWindow

		marker.addListener('click', () => {
			marker.zIndex = this.topZIndex++
			if (this.isInfoWindowOpen(infoWindow)) {
				infoWindow.close()
			} else {
				this.closeAllInfoWindows()
				this.openInfoWindow(marker)
			}
		})

		// Save copy of infoWindow in marker and keep ref for marker
		marker['infoWindow'] = infoWindow
		marker['mapEventType'] = 'SITE'
		this.jobSiteMarker = marker
	}

	private addGpsLocationMarker(inOut: 'IN' | 'OUT') {
		const trans = this.dataSource.transaction
		const hasInOut = inOut === 'IN' ? !!trans.actual_start : !!trans.actual_end
		const hasGps = inOut === 'IN' ? !!trans.geo_start_latitude : !!trans.geo_end_latitude
		if (!trans || !hasInOut || !hasGps) return

		const delay = this.dataSource.gpsAllowedDelay
		const includeImages = false // Global.coreSrvc.devDetect.isDesktop()
		const is12Hours = this.dataSource.format12Hours

		const result = MapHelper.buildTransInOutMarker(this.map, inOut, trans, delay, includeImages, is12Hours, !this.publicView)
		const marker = result.marker
		const infoWindow = result.infoWindow

		marker.addListener('click', () => {
			marker.zIndex = this.topZIndex++
			if (this.isInfoWindowOpen(infoWindow)) {
				infoWindow.close()
			} else {
				this.closeAllInfoWindows()
				this.openInfoWindow(marker)
			}
			const mapEvent = this.events.find((event) => event.type === inOut)
			this.viewTimelineEvent.next(mapEvent)
		})

		// Save copy of infoWindow in marker and keep ref for marker
		marker['infoWindow'] = infoWindow
		marker['isClustered'] = false
		marker['mapEventType'] = inOut
		if (inOut === 'IN') {
			this.gpsCheckinMarker = marker
		} else {
			this.gpsCheckoutMarker = marker
		}
	}

	private addCheckpointMarkers() {
		const checkpoints = this.dataSource.checkpoints
		const timezone = this.dataSource.transaction.timezone
		const format12Hours = this.dataSource.format12Hours
		let checkpointMarkers: Array<google.maps.marker.AdvancedMarkerElement> = []

		let checkPointNum = 1
		for (const cp of checkpoints) {
			// Skip if no geo location
			if (!cp.geo_latitude) {
				checkPointNum++
				continue
			}

			const result = MapHelper.buildCheckpointMarker(this.map, cp, timezone, true, false, checkPointNum, format12Hours)
			const marker = result.marker
			const infoWindow = result.infoWindow

			marker.addListener('click', () => {
				marker.zIndex = this.topZIndex++
				if (this.isInfoWindowOpen(infoWindow)) {
					infoWindow.close()
				} else {
					this.closeAllInfoWindows()
					this.openInfoWindow(marker)
				}
				const checkPointIndex = marker['mapEventCpIndex']
				const cpEvent = this.getMapEventForMarker(marker) // this.events.find((event) => event.cpIndex === checkPointIndex)
				log('CP Event', checkPointIndex, cpEvent)
				this.viewTimelineEvent.next(cpEvent)
			})

			// Save copy of infoWindow in marker and save ref to marker
			marker['infoWindow'] = infoWindow
			marker['checkpointId'] = cp.id
			marker['mapEventType'] = `CP`
			marker['mapEventCpIndex'] = checkPointNum - 1
			checkpointMarkers.push(marker)
			checkPointNum++
		}
		this.checkpointMarkers = checkpointMarkers
	}

	getMapEventForMarker(marker: google.maps.marker.AdvancedMarkerElement) {
		const markers = [this.gpsCheckinMarker, ...this.checkpointMarkers, this.gpsCheckoutMarker].filter((m) => !!m)
		const index = markers.indexOf(marker)
		return this.events[index]
	}

	openInfoWindow(marker: google.maps.marker.AdvancedMarkerElement) {
		const infoWindow = marker['infoWindow']
		infoWindow?.open(this.map, marker)
		this.currentMarker = marker
	}

	// public formatTimestamp(dateTime: string) {
	// 	const mom = moment(dateTime)
	// 	if (mom.isValid()) {
	// 		const is12Hours = this.dataSource.format12Hours
	// 		const timezone = this.dataSource.transaction.timezone
	// 		const format = is12Hours ? 'ddd MMM Do [<br>] h:mm a z' : 'ddd MMM Do [<br>] HH:mm z'
	// 		return mom.tz(timezone).format(format)
	// 	}
	// 	return null
	// }

	public getEventType(inOut: 'IN' | 'OUT'): string {
		const isTravel = this.dataSource.transaction?.travel_job
		return inOut === 'IN' ? (isTravel ? 'Travel Start' : 'Check-In') : isTravel ? 'Travel End' : 'Check-Out'
	}

	public previousEvent() {
		const markers = [this.gpsCheckinMarker, ...this.checkpointMarkers, this.gpsCheckoutMarker].filter((m) => !!m)
		const index = markers.indexOf(this.currentMarker)
		if (index > 0) {
			const marker = markers[index - 1]
			this.panToMarker(marker)
			const cpEvent = this.getMapEventForMarker(marker)
			this.viewTimelineEvent.next(cpEvent)
		}
	}

	public nextEvent() {
		const markers = [this.gpsCheckinMarker, ...this.checkpointMarkers, this.gpsCheckoutMarker].filter((m) => !!m)
		const index = markers.indexOf(this.currentMarker)
		if (index < markers.length - 1) {
			const marker = markers[index + 1]
			this.panToMarker(marker)
			const cpEvent = this.getMapEventForMarker(marker)
			this.viewTimelineEvent.next(cpEvent)
		}
	}
}
