<div class="sec-head-wrap">
	<div class="sec-act-ico-wrap">
		<i
			*ngIf="devDetect.isDesktop() && vizSrvc.viewManager.currentView === 'DASHBOARDS'"
			class="far fa-plus sec-act-ico"
			(click)="confirmAction('ADD_DASHBOARD')"
			title="Add Dashboard"
		></i>
		<i class="far fa-question-circle sec-act-ico" style="margin-right: 4px" (click)="toggleHelp.next(true)" title="Show Help"></i>
	</div>

	<div class="sec-title-wrap">
		<i class="far fa-pie-chart sec-head-ico"></i>
		<span>Data / Analytics</span>
	</div>

	<!-- Dashboards Sub Section -->
	<div [hidden]="vizSrvc.viewManager.currentView !== 'DASHBOARDS'" #dbContextMenuTarget class="sub-section-wrap" style="margin-top: 2px">
		<!-- Dashboard configuration icon (cog) -->
		<span [hidden]="devDetect.isMobile() || devDetect.isTablet()" class="viz-config-icon" style="margin: 0px 6px 0px 4px">
			<i class="far fa-cog viz-act-ico link-text" #contextMenuTarget (click)="openContextMenu($event, alCm)"></i
		></span>

		<!-- Dashboard navigation icons (arrows) -->
		<span *ngIf="dashboards.length > 1" style="margin-right: 6px">
			<i class="far fa-arrow-left viz-act-ico link-text" style="margin-left: 6px; margin-right: 6px" (click)="previousDashboard()"></i>
			<i class="far fa-arrow-right viz-act-ico link-text" (click)="nextDashboard()"></i>
		</span>

		<!-- Dashboard dropdown (when more than one dashboard) -->
		<div class="dashboard-dropdown">
			<a data-bs-toggle="dropdown" href="#" id="dashListDropdown" aria-haspopup="true" aria-expanded="false">
				<div [class.has-pointer]="dashboards.length > 1" class="dropdown-name">
					<!-- If this is not the default dashboard, show ownership info via an icon with tooltip -->
					<span *ngIf="currentDashboard.record?.id" [hidden]="devDetect.isMobile() || devDetect.isTablet()" style="margin: 6px 6px">
						<i
							*ngIf="isMyDashboard(currentDashboard)"
							class="far fa-lock viz-act-ico dash-owned"
							[pTooltip]="editPermissionTooltip"
							tooltipPosition="right"
							tooltipStyleClass="small-tooltip"
						></i>
						<i
							*ngIf="!isMyDashboard(currentDashboard)"
							class="far fa-lock viz-act-ico dash-shared"
							[pTooltip]="editPermissionTooltip"
							tooltipPosition="right"
							tooltipStyleClass="small-tooltip"
						></i>
					</span>
					{{ currentDashboard.name }}<i *ngIf="dashboards.length > 1" class="fa fa-chevron-down" style="margin-left: 4px"></i>
				</div>
			</a>
			<div class="dropdown-menu dropdown-menu-start" aria-labelledby="dashListDropdown">
				<div
					*ngFor="let dashboard of dashboards; let idx = index"
					class="dropdown-item d-flex justify-content-start"
					[class.dropdown-item-modified]="vizSrvc.hasDashboardBeenModified(dashboard)"
					[class.mb-1]="idx !== dashboards.length - 1"
					(click)="handleDashboardDropdownChange(dashboard)"
				>
					<div class="dropdown-owner-icon" style="margin-right: 10px" [hidden]="devDetect.isMobile() || devDetect.isTablet()">
						<i *ngIf="isMyDashboard(dashboard)" class="far fa-lock dash-owned"></i>
						<i *ngIf="!isMyDashboard(dashboard)" class="far fa-lock dash-shared"></i>
					</div>
					<div>{{ dashboard.name }}</div>
					<span *ngIf="vizSrvc.hasDashboardBeenModified(dashboard)"
						><i class="fas fa-circle-small" style="color: chocolate; margin-left: 10px"></i
					></span>
				</div>
			</div>
		</div>

		<!-- Dashboard modified indicator -->
		<span *ngIf="hasDashboardBeenModified"
			><i
				class="fas fa-circle-small"
				pTooltip="Has unsaved changes"
				tooltipPosition="right"
				tooltipStyleClass="small-tooltip"
				style="color: chocolate; margin-left: 6px"
			></i
		></span>
	</div>

	<!-- Health Sub Section -->
	<div *ngIf="vizSrvc.viewManager.currentView === 'HEALTH_CENTER'" class="sub-section-wrap">
		<i
			title="Toggle Non-Contributors"
			class="far fa-badge-check viz-act-ico contrib-icon"
			style="margin: 0px 6px 0px 4px"
			[class.contrib-filtered]="vizSrvc.viewManager.contribFilterActive"
			(click)="toggleNonContributors()"
		></i>
		<span class="link-text" (click)="collapseAllHealthSections()">Collapse All</span> /
		<span class="link-text" (click)="showDefaultView()">Default</span> /
		<span class="link-text" (click)="expandAllHealthSections()">Expand All</span>
	</div>
</div>

<app-section-switcher
	[config]="switcherConfig"
	[liftOn]="'PORTRAIT'"
	[type]="'NOCOUNT'"
	[maxWidth]="'320px'"
	(tabClicked)="sectionTabClicked($event)"
>
</app-section-switcher>

<p-contextMenu #alCm [target]="contextMenuTarget" [model]="actionListContextMenu" />

<!-- Confirmation Dialog -->
<p-dialog
	styleClass="p-dialog-noheader"
	header="Preferences"
	[(visible)]="actionDialog.show"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="true"
>
	<!-- Add and rename confirmation options -->
	<div *ngIf="actionDialog.action === 'ADD_DASHBOARD' || actionDialog.action === 'RENAME_DASHBOARD'">
		<div class="confirm-note" *ngIf="actionDialog.action === 'ADD_DASHBOARD'">Please specify the name for your new dashboard.</div>
		<div class="confirm-note" *ngIf="actionDialog.action === 'RENAME_DASHBOARD'">Please specify the new name for this dashboard.</div>
		<form>
			<div>
				<input
					id="dashboard-name"
					type="text"
					name="title"
					class="form-control"
					placeholder="Enter dashboard name"
					[(ngModel)]="actionDialog.title"
					(keyup.enter)="performAction(actionDialog.action)"
				/>
			</div>
			<div class="d-flex justify-content-between" style="margin-top: 18px">
				<button class="btn btn-sm btn-secondary btn-block" style="margin-right: 8px" (click)="actionDialog.show = false">Cancel</button>
				<button
					class="btn btn-sm btn-success btn-block"
					style="margin-left: 8px"
					[disabled]="!actionDialog.title"
					(click)="performAction(actionDialog.action)"
				>
					Save
				</button>
			</div>
		</form>
	</div>

	<!-- Add new row -->
	<div *ngIf="actionDialog.action === 'ADD_DATASOURCE'">
		<div class="confirm-note">Configure the options below to insert a new row at the top of your dashboard</div>

		<div class="options-block options-block-radio-lg" style="text-align: center; margin-bottom: 12px">
			<div class="form-check form-check-inline">
				<input
					name="createType"
					type="radio"
					class="form-check-input"
					id="createTypeCounter"
					[value]="'COUNTER'"
					[(ngModel)]="actionDialog.create.type"
				/>
				<label class="form-check-label has-pointer" for="createTypeCounter" style="font-weight: bold">Counters</label>
			</div>
			<div class="form-check form-check-inline">
				<input
					name="createType"
					type="radio"
					class="form-check-input"
					id="createTypeGraph"
					[value]="'GRAPH'"
					[(ngModel)]="actionDialog.create.type"
				/>
				<label class="form-check-label has-pointer" for="createTypeGraph" style="font-weight: bold">Graphs</label>
			</div>
		</div>

		<div class="d-flex justify-content-between" style="margin-top: 22px">
			<button class="btn btn-sm btn-secondary btn-block" style="margin-right: 8px" (click)="actionDialog.show = false">Cancel</button>
			<button class="btn btn-sm btn-success btn-block" style="margin-left: 8px" (click)="performAction('ADD_DATASOURCE')">Confirm</button>
		</div>
	</div>

	<!-- Save confirmation option -->
	<div *ngIf="actionDialog.action === 'SAVE_DASHBOARD'">
		<div class="confirm-note">This will save the dashboard and overwrite any previous settings.</div>

		<div class="d-flex justify-content-between" style="margin-top: 18px">
			<button class="btn btn-sm btn-secondary btn-block" style="margin-right: 8px" (click)="actionDialog.show = false">Cancel</button>
			<button class="btn btn-sm btn-success btn-block" style="margin-left: 8px" (click)="performAction('SAVE_DASHBOARD')">Confirm</button>
		</div>
	</div>

	<!-- Reset confirmation option -->
	<div *ngIf="actionDialog.action === 'RESET_DASHBOARD'">
		<div class="confirm-note">This will reset the dashboard to it's default settings?</div>
		<div class="d-flex justify-content-between" style="margin-top: 18px">
			<button class="btn btn-sm btn-secondary btn-block" style="margin-right: 8px" (click)="actionDialog.show = false">Cancel</button>
			<button class="btn btn-sm btn-success btn-block" style="margin-left: 8px" (click)="performAction('RESET_DASHBOARD')">Confirm</button>
		</div>
	</div>

	<!-- Delete confirmation option -->
	<div *ngIf="actionDialog.action === 'DELETE_DASHBOARD'">
		<div class="confirm-note">This will permenantly delete the dashboard.</div>

		<div class="d-flex justify-content-between" style="margin-top: 18px">
			<button class="btn btn-sm btn-secondary btn-block" style="margin-right: 8px" (click)="actionDialog.show = false">Cancel</button>
			<button class="btn btn-sm btn-success btn-block" style="margin-left: 8px" (click)="performAction('DELETE_DASHBOARD')">Confirm</button>
		</div>
	</div>

	<!-- Revert confirmation option -->
	<div *ngIf="actionDialog.action === 'RESTORE_DASHBOARD'">
		<div class="confirm-note">This will restore the dashboard to it's last saved state.</div>
		<div class="d-flex justify-content-between" style="margin-top: 18px">
			<button class="btn btn-sm btn-secondary btn-block" style="margin-right: 8px" (click)="actionDialog.show = false">Cancel</button>
			<button class="btn btn-sm btn-success btn-block" style="margin-left: 8px" (click)="performAction('RESTORE_DASHBOARD')">Confirm</button>
		</div>
	</div>
</p-dialog>

<app-modal-dialog *ngIf="editDashboardDialogManager.isDialogVisible" [dialogManager]="editDashboardDialogManager">
	<app-viz-dashboard-edit
		[dialogManager]="editDashboardDialogManager"
		[action]="editDashboardAction.action"
		[record]="editDashboardAction.record"
		(recordUpdated)="handleDashboardRecordUpdated($event)"
	>
	</app-viz-dashboard-edit>
</app-modal-dialog>
