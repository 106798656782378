import { Component, OnInit, Output, EventEmitter, Input, AfterViewInit, AfterContentInit } from '@angular/core'

import { DialogManager, LocalPrefsItem, LocalPrefsDialogData, LocalPrefsGroup, HelpDialogMessage } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { log } from '@app/helpers'
import _ from 'lodash'

@Component({
    selector: 'app-local-prefs',
    templateUrl: './local-prefs.component.html',
    styleUrls: ['./local-prefs.component.scss'],
    standalone: false
})
export class LocalPrefsComponent implements OnInit, AfterViewInit, AfterContentInit {
	@Input() dialogManager: DialogManager
	@Output() prefsUpdated = new EventEmitter<boolean>()

	public isUpdating = false
	public groups: Array<LocalPrefsGroup> = []

	constructor(private coreSrvc: CoreService) {}

	public ngOnInit(): void {
		this.loadColumnPrefsData()
		this.loadCurrentSettings()
	}

	public ngAfterViewInit(): void {}

	public ngAfterContentInit() {
		this.setupDialogManager()
	}

	private loadColumnPrefsData(): void {
		const dialogData = this.dialogManager.dialogData as LocalPrefsDialogData
		this.groups = dialogData.groups
	}

	private setupDialogManager() {
		this.dialogManager.submitBtnAction = () => this.prefsSaved()
		this.dialogManager.canCancel = () => !this.isUpdating
		this.dialogManager.canSubmit = () => !this.isUpdating
	}

	private prefsSaved(): void {
		const arePrefsValid = this.validatePrefs()
		if (this.isUpdating || !arePrefsValid) {
			return
		}
		this.isUpdating = true
		this.savePrefs()
		setTimeout(() => {
			this.prefsUpdated.emit(true)
		}, 300)
	}

	private loadCurrentSettings(): void {
		for (const group of this.groups) {
			const items = group.items
			for (const item of items) {
				item.defaultValue = this.coreSrvc.prefSrvc.defaults[item.key]
				item.currentValue = this.coreSrvc.prefSrvc.data[item.key]
			}
		}
	}

	public isPrefVisible(item: LocalPrefsItem, group: LocalPrefsGroup): boolean {
		return !group.hiddenPrefKeys.includes(item.key)
	}

	public resetSettings(group: LocalPrefsGroup): void {
		for (const item of group.items) {
			item.currentValue = item.defaultValue
		}
	}

	public toggleColumn(item: LocalPrefsItem) {
		item.currentValue = !item.currentValue
	}

	public showHelp(item: LocalPrefsItem): void {
		const header = item.config.helpTitle ?? item.label
		const hint = item.config.help
		const message = new HelpDialogMessage(header, hint)
		this.coreSrvc.notifySrvc.showHelp(message)
	}

	public focusInput(item: LocalPrefsItem) {
		item.errorMsg = null
		const id = `pref_input_${item.key}`
		const input = document.getElementById(id) as HTMLInputElement
		input?.select()
	}

	// Returns true if all prefs validate, otherwise false
	private validatePrefs(): boolean {
		for (const group of this.groups) {
			const items = group.items
			for (const item of items) {
				if (item.type === 'INTEGER') {
					const value = parseInt(item.currentValue, 10)
					if (!_.isInteger(value) || item.currentValue < item.config.minValue || item.currentValue > item.config.maxValue) {
						const errorMsg = `${item.label} must be between ${item.config.minValue} and ${item.config.maxValue}.`
						this.coreSrvc.notifySrvc.notify('error', 'Range Error', errorMsg, 5)
						item.errorMsg = errorMsg
						this.coreSrvc.displaySrvc.bringIntoView(item.key)
						return false
					}
				}
			}
		}
		return true
	}

	private savePrefs(): void {
		for (const group of this.groups) {
			const items = group.items
			for (const item of items) {
				if (item.type === 'CHECKBOX') {
					this.coreSrvc.prefSrvc.data[item.key] = item.currentValue
				}
				if (item.type === 'INTEGER') {
					this.coreSrvc.prefSrvc.data[item.key] = +item.currentValue
				}
			}
		}
		this.coreSrvc.prefSrvc.save()
		const dialogData = this.dialogManager.dialogData as LocalPrefsDialogData
		const removalKeys = dialogData.localStorageKeyRemovalList
		for (const key of removalKeys) {
			localStorage.removeItem(key)
			localStorage.removeItem(key + '_/')
		}
	}
}
