import { DateTimeHelper } from '@app/helpers/datetime'
import { FileUploaderProcessedFile } from './file-uploader'
import { log } from '@app/helpers/logger'

import { EmployeeRecord } from './employee'
import { RecordTag } from './tag'
import { DistanceUnitOption } from './report-user'
import { LanguageCode } from './company'

import moment from 'moment-timezone'

export type AnnouncementType = 'PLAY_ALWAYS' | 'PLAY_ONCE'

export type AnnouncementsViewTabState = 'CHECKIN' | 'SCHEDULED'
export class AnnouncementsViewManager {
	version = 1
	currentView: AnnouncementsViewTabState = 'SCHEDULED'
	displayLang: LanguageCode = 'en'

	constructor() {
		this.restore()
	}

	public setCurrentView(view: AnnouncementsViewTabState) {
		this.currentView = view
		this.save()
	}

	public save() {
		localStorage.setItem('AnnouncementsViewState', JSON.stringify(this))
	}

	private restore() {
		try {
			const record = JSON.parse(localStorage.getItem('AnnouncementsViewState'))
			if (record && record.version === this.version) {
				for (const attr in record) {
					if (record.hasOwnProperty(attr)) {
						this[attr] = record[attr]
					}
				}
			} else {
				localStorage.removeItem('AnnouncementsViewState')
			}
		} catch (err) {
			log('Error restoring AnnouncementsViewState', err)
		}
	}
}

export class CheckinAnnouncementRecord {
	id: number
	company_id: number
	created: string
	updated: string
	order: number
	name: string
	start_date: string
	end_date: string

	enabled: boolean
	always_play: boolean
	multi_play: boolean
	acknowledgement: boolean

	body_en: string
	body_es: string
	body_fr: string
	body_cn: string
	body_ru: string

	files_json: string

	filter_employee: Array<number> = []
	filter_location: Array<number> = []

	get fileList(): Array<FileUploaderProcessedFile> {
		if (this.files_json) {
			return JSON.parse(this.files_json)
		}
		return []
	}

	constructor(record: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
		if (!this.filter_employee) {
			this.filter_employee = []
		} // Make into empty array if null
		if (!this.filter_location) {
			this.filter_location = []
		} // Make into empty array if null
		this.created = DateTimeHelper.stripUtcTag(this.created)
		this.updated = DateTimeHelper.stripUtcTag(this.updated)
	}
}

// Scheduled Announcement Classes

export type ScheduledAnnouncementTargetType = 'ALLEMPLOYEES' | 'ALLACTIVEEMPLOYEES' | 'SELECTEDEMPLOYEES'
export type ScheduledAnnouncementActionType = 'CUSTOM_MESSAGE' | 'SEND_EMP_SCHEDULE' | 'SEND_EMP_AUTH_LINK'

export class ScheduledAnnouncementRecord {
	id: number
	action: ScheduledAnnouncementActionType
	save_as_template: boolean
	send_date: string // Date
	last_sent: string // Date
	subject: string
	body_en: string
	body_es: string
	body_fr: string
	body_cn: string
	body_ru: string

	type: ScheduledAnnouncementTargetType
	filter_json: string
	files_json: string

	created: string
	updated: string

	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.send_date = DateTimeHelper.stripUtcTag(this.send_date)
			this.last_sent = DateTimeHelper.stripUtcTag(this.last_sent)
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
		}
	}

	get sortDate(): string {
		return this.last_sent ? this.last_sent : this.updated ? this.updated : this.created
	}

	getFilter(): ScheduledAnnouncementFilter {
		return new ScheduledAnnouncementFilter(this.filter_json)
	}
}

export class ScheduledAnnouncementFilter {
	emp_id: Array<number> = [] // Supoorts multiple employees
	jobsite_id: Array<number> = [] // Supports multiple job sites
	departments: Array<string> = [] // Supports multiple departments
	employee_tags: Array<string> = [] // Supports multiple tags
	emp_active_status: 'ACTIVE' | 'INACTIVE' | null = 'ACTIVE'

	job_id: number // Supports a single job
	job_distance: number
	job_distance_units: DistanceUnitOption

	constructor(data?: any) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
	}
}

export class ScheduledAnnouncement {
	id: number = null
	action: ScheduledAnnouncementActionType = 'CUSTOM_MESSAGE'
	save_as_template: boolean = false
	sendDate: Date = null
	lastSent: Date = null
	subject = ''
	body_en = ''
	body_es = ''
	body_fr = ''
	body_cn = ''
	body_ru = ''

	type: ScheduledAnnouncementTargetType = 'SELECTEDEMPLOYEES'
	filter = new ScheduledAnnouncementFilter()
	files: Array<FileUploaderProcessedFile> = []

	originalRecord: ScheduledAnnouncementRecord

	created: string
	updated: string

	constructor(record?: ScheduledAnnouncementRecord) {
		if (record) {
			this.originalRecord = record

			// Format various properties for internal use
			this.id = record.id
			this.action = record.action
			this.save_as_template = record.save_as_template
			this.type = record.type as ScheduledAnnouncementTargetType
			this.subject = record.subject || ''
			this.body_en = record.body_en || ''
			this.body_es = record.body_es || ''
			this.body_fr = record.body_fr || ''
			this.body_cn = record.body_cn || ''
			this.body_ru = record.body_ru || ''

			this.created = DateTimeHelper.stripUtcTag(record.created)
			this.updated = DateTimeHelper.stripUtcTag(record.updated)

			this.sendDate = record.send_date ? moment(record.send_date).toDate() : null
			this.lastSent = record.last_sent ? moment(record.last_sent).toDate() : null

			const parsedFilter = record.filter_json ? JSON.parse(record.filter_json) : null
			this.filter = new ScheduledAnnouncementFilter(parsedFilter)

			const parsedFiles = record.files_json ? JSON.parse(record.files_json) : null
			this.files = parsedFiles ? parsedFiles.map((f) => new FileUploaderProcessedFile(f)) : []

			// Convert ALLACTIVEEMPLOYEES to ALLEMPLOYEES
			if (this.type === 'ALLACTIVEEMPLOYEES') {
				this.type = 'ALLEMPLOYEES'
				this.filter.emp_active_status = 'ACTIVE'
			}
			// Clear all filter options except emp_id when type is SELECTEDEMPLOYEES
			if (this.type === 'SELECTEDEMPLOYEES') {
				this.filter.emp_active_status = null
				this.filter.departments = []
				this.filter.employee_tags = []
				this.filter.job_id = null
				this.filter.job_distance = null
				this.filter.jobsite_id = []
			}
		}
	}

	buildUpdateRecord(): ScheduledAnnouncementRecord {
		// Include properties from original record
		const originalRecord = this.originalRecord
		const record = new ScheduledAnnouncementRecord(originalRecord)

		// Format properties for database record
		record.id = this.id
		record.action = this.action
		record.save_as_template = this.save_as_template
		record.send_date = this.sendDate ? this.sendDate.toISOString() : null
		record.subject = this.subject
		record.body_en = this.body_en
		record.body_es = this.body_es
		record.body_fr = this.body_fr
		record.body_cn = this.body_cn
		record.body_ru = this.body_ru
		record.type = this.type
		record.filter_json = JSON.stringify(this.filter)
		record.files_json = this.files.length > 0 ? JSON.stringify(this.files) : null
		return record
	}

	clone(): ScheduledAnnouncement {
		const record = this.buildUpdateRecord()
		const result = new ScheduledAnnouncement(record)
		result.originalRecord = this.originalRecord
		return result
	}

	cleanUpFilterData(): ScheduledAnnouncement {
		log('Cleaning up Filter', this.type)
		switch (this.type) {
			case 'ALLEMPLOYEES':
			case 'ALLACTIVEEMPLOYEES':
				this.filter.emp_id = []
				this.filter.jobsite_id = []
				break
			case 'SELECTEDEMPLOYEES':
				this.filter.jobsite_id = []
				this.filter.departments = []
				break
		}
		return this
	}
}

export class ScheduledAnnouncementEmployeeInfoCard {
	employee: EmployeeRecord
	tags: Array<RecordTag> = []
	selected = false
	expanded = false
	closeHover = false
	distLabel = '' // Distance label

	constructor(employee: EmployeeRecord) {
		this.employee = employee
		if (employee?.tags_json) {
			const tagsJson = JSON.parse(employee.tags_json as any as string)
			this.tags = tagsJson.tags.map((rec) => new RecordTag(rec))
		}
	}
}
