import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

import { log, Helper } from '@app/helpers'

@Component({
    selector: 'app-email-list',
    templateUrl: './email-list.component.html',
    styleUrls: ['./email-list.component.scss'],
    standalone: false
})
export class EmailListComponent implements OnInit {

	@Input() emailList: Array<String>
	@Output() emailListChange: EventEmitter<Array<String>> = new EventEmitter()

	@Input() inputLabel: string
	@Input() requiredNotice: string

	constructor() { }

	ngOnInit() {
		if (!this.emailList) { this.emailList = [] }
	}

	onAddEmail(input): boolean {
		const email = input.value
		if (!Helper.isValidEmail(email)) {
			alert('Please enter a valid email address.')
			return false
		}
		log('Adding Email:', email)
		this.emailList.unshift(email)
		input.value = ''
		return false
	}

	onRemoveEmail(idx): boolean {
		log('Removing Email')
		this.emailList.splice(idx, 1)
		return false
	}

	onChange() {
		this.emailListChange.emit(this.emailList)
	}


}
