import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { log } from '@app/helpers'
import { CompanyRecord, DataAccessRequest, DatabaseTableName } from '@app/models'
import { CoreService, ShiftViewCalendarDefaults, UserLoginService } from '@app/services'
import { DatesSetArg } from '@fullcalendar/core'
import { Subscription } from 'rxjs'

// Deep Link Codes

// code: ssvfewd = Scheduler Shift View For Employww With Date
// code: tvsi = Transaction View Show Issues // https://<domain>/redirect/params?dlc=tvsi&cid=<COMPANY_ID>
// parameters: dlc (deep link code), cid (company ID), eid (employee ID), start (YYYY-MM-DD), end (YYYY-MM-DD)

type DeepLinkCode = 'ssvfewd' | 'tvsi'

@Component({
    selector: 'app-redirect-record',
    templateUrl: './redirect-record.component.html',
    styleUrls: ['./redirect-record.component.scss'],
    standalone: false
})
export class RedirectRecordComponent implements OnInit, OnDestroy {
	// redirect/record/:recordId/:section/:companyId

	status = {
		isLoggedIn: false,
		loginChecked: false,
		authorizationChecked: false,
		isAuthorized: false,
		companyChecked: false,
		invalidRequest: false,
	}

	params: Params
	recordId: number
	section: string
	companyId: number
	deepLinkCode: DeepLinkCode

	private subs = new Subscription()

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private userSrvc: UserLoginService,
		private coreSrvc: CoreService,
	) {}

	////////////////////////////////////////////////////////////////////
	// Route: /redirect/record/:recordId/:section/:companyId
	// Route: /redirect/params (handles via paramaters: cid/rid/sec/dlc)

	ngOnInit(): void {
		this.companyId = parseInt(this.route.snapshot.params['companyId'], 10)
		this.recordId = parseInt(this.route.snapshot.params['recordId'], 10)

		this.section = this.route.snapshot.params['section']

		this.subs.add(
			this.route.queryParams.subscribe((params: Params) => {
				this.params = params

				if (params['cid']) this.companyId = +params['cid']
				if (params['rid']) this.recordId = +params['rid']
				if (params['sec']) this.section = params['sec']
				if (params['dlc']) this.deepLinkCode = params['dlc']

				log('cid/rid/sec/dlc', this.companyId, this.recordId, this.section, this.deepLinkCode)

				this.userSrvc.isAuthenticated$().then((authenticationSuccess) => {
					this.status.loginChecked = true
					if (authenticationSuccess) {
						this.status.isLoggedIn = true
						this.checkAuthorization()
					} else {
						this.status.isLoggedIn = false
					}
				})
			}),
		)
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	private checkAuthorization() {
		const tables: Array<DatabaseTableName> = ['company', 'company_switch_list', 'users']
		this.coreSrvc.dbSrvc.bulkRead(tables).then((lambdaResult) => {
			setTimeout(() => {
				this.processRequest()
			}, 500)
		})
	}

	private processRequest() {
		const section = this.section
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		// log('Company record', company)
		const allowedCompanies = this.coreSrvc.dbSrvc.settingSrvc.getAllowedCompanies()
		const isCompanyCurrent = company.id === this.companyId
		// log('Is company current', isCompanyCurrent)
		const companySwitchList = allowedCompanies.filter((c) => c.id === this.companyId)
		// log('Switch list', companySwitchList)
		const isSwitchAllowed = companySwitchList.length > 0
		// log('Is switch allowed', isSwitchAllowed)
		const switchCompany = companySwitchList.pop()
		const isAuthorized = this.isActionAuthorized(switchCompany)
		log('Is action authorized', isAuthorized)

		// Check if company is authorized
		this.status.authorizationChecked = true
		this.status.isAuthorized = isAuthorized
		if (!isAuthorized) {
			return
		}

		log('Current Company', company)
		log('Is company current', isCompanyCurrent)
		log('Is company switch allowed', isSwitchAllowed)
		// log('Allowed Companies', allowedCompanies)

		if (isSwitchAllowed && isCompanyCurrent) {
			log('No company switch necessary')
			this.dispatchRedirect(section)
		}
		if (isSwitchAllowed && !isCompanyCurrent) {
			log('Switching company')
			const request = new DataAccessRequest('company_switch_list', 'switch_company', { id: this.companyId })
			this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
				this.coreSrvc.dbSrvc.settingSrvc.clearSettingsForCompanySwitch()
				this.coreSrvc.dbSrvc.clearServiceData()
				this.dispatchRedirect(section)
			})
		}
	}

	private dispatchRedirect(section: string) {
		// If we have a deep link code, process via that method, otherwise fall back to old method
		const deepLinkCode = this.deepLinkCode
		if (deepLinkCode) {
			this.dispatchDeepLink(deepLinkCode)
			return
		}

		switch (section) {
			case 'transactions':
				this.handleTransactionRedirect()
				break
			case 'changeRequest':
				this.handleChangeRequestRedirect()
				break
			default:
				this.status.invalidRequest = true
		}
	}

	private dispatchDeepLink(code: DeepLinkCode) {
		log('Got a deeplink code', code)
		switch (code) {
			case 'ssvfewd':
				this.handleSchedulerShiftViewForEmployeeWithDateRange()
				return
			case 'tvsi':
				this.handleTransactionViewShowIssues()
				return
			default:
				this.status.invalidRequest = true
		}
	}

	private isActionAuthorized(company: CompanyRecord): boolean {
		log('Check authorization for company', company)
		if (!company) {
			return false
		}
		// Deprecated - allow switch as long as company is allowed
		// if (company.account_status === 'BILLING_OK') {
		// 	return true
		// }
		// if (company.account_status === 'TESTING') {
		// 	return true
		// }
		// const myActualUser = this.dbSrvc.settingSrvc.getMyActualUser()
		// log('My Actual User', myActualUser)
		// if (myActualUser?.role === 'INTERNAL' || myActualUser?.role === 'PRIMARY' || myActualUser?.role === 'SECONDARY') {
		// 	return true
		// }
		return true
	}

	private handleTransactionRedirect() {
		log('Handling Transaction')
		const url = '/admin/transactions'
		this.coreSrvc.dbSrvc.tranSrvc.filterText = `#transId:${this.recordId}`
		this.coreSrvc.dbSrvc.tranSrvc.requiredId = this.recordId
		this.coreSrvc.dbSrvc.settingSrvc.setRedirectUrl(url)
		this.router.navigate(['/admin/dashboard'])
	}

	private handleTransactionViewShowIssues() {
		log('Handling Transaction View Show Issues')
		const url = '/admin/transactions'
		this.coreSrvc.dbSrvc.tranSrvc.loadIssues = true
		this.coreSrvc.dbSrvc.tranSrvc.filterStartDate = null
		this.coreSrvc.dbSrvc.tranSrvc.filterEndDate = null
		this.coreSrvc.prefSrvc.data.transDefaultToTodayView = false
		this.coreSrvc.dbSrvc.settingSrvc.setRedirectUrl(url)
		this.router.navigate(['/admin/dashboard'])
	}

	private handleChangeRequestRedirect() {
		log('Handling Change Request Redirect')
		const url = '/admin/scheduler'
		this.coreSrvc.dbSrvc.schedulerSrvc.changeRequestFilterId = this.recordId
		this.coreSrvc.dbSrvc.settingSrvc.setRedirectUrl(url)
		this.router.navigate(['/admin/dashboard'])
	}

	private handleSchedulerShiftViewForEmployeeWithDateRange() {
		const params = this.params
		const empId = +params['empId']
		const viewDate = params['viewDate']

		this.coreSrvc.dbSrvc.schedulerSrvc.toggleDayView('OFF')

		const defaultRange = new ShiftViewCalendarDefaults(viewDate)
		this.coreSrvc.dbSrvc.schedulerSrvc.shiftViewDefaultDate = defaultRange

		const viewManager = this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager
		viewManager.setCurrentView('SHIFT')
		viewManager.shift.selectedJobIds = []
		viewManager.shift.selectedEmpIds = [empId]
		viewManager.shift.shiftEventBgColorSource = 'EMP'

		const url = '/admin/scheduler'
		this.coreSrvc.dbSrvc.settingSrvc.setRedirectUrl(url)
		this.router.navigate(['/admin/dashboard'])

		log('Scheduler Shift View For Employee with Date Range', empId, viewDate)
	}
}
