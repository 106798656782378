import { DateTimeHelper } from '@app/helpers'
import moment from 'moment-timezone'

export class LogEntry {
	mesg: string
	table: string
	type: string
	batch_num: number
	needsFix = false

	static buildFromDataAccess(data: any): LogEntry {
		const entry = new LogEntry()
		entry.mesg = data.log_message
		entry.table = data.log_table
		entry.type = data.log_type
		entry.needsFix = data.needsFix
		return entry
	}
}

export class LogEntryBatch {
	start: LogEntry
	end: LogEntry
	logs: LogEntry[] = []

	counts = {
		summary: 0,
		error: 0,
		fix: 0,
		info: 0,
	}

	isExpanded = {
		summary: false,
		error: false,
		fix: false,
		info: false,
	}

	formattedLogDate(startEnd: 'START' | 'END'): string {
		const msg = startEnd === 'START' ? this.start.mesg : this.end.mesg
		const date = DateTimeHelper.getDateFromLogEntryMessage(msg)
		if (date) {
			const timeFormat = DateTimeHelper.getTimeFormat()
			return moment(date).format('ddd MMM Do, YYYY [@] ' + timeFormat)
		} else {
			return '< invalid date format >'
		}
	}

	get summaryEvents(): Array<LogEntry> {
		return this.logs.filter((log) => log.type === 'SUMMARY')
	}
	get errorEvents(): Array<LogEntry> {
		return this.logs.filter((log) => log.type === 'ERROR')
	}
	get fixEvents(): Array<LogEntry> {
		return this.logs.filter((log) => log.type === 'FIX')
	}
	get infoEvents(): Array<LogEntry> {
		return this.logs.filter((log) => log.type === 'INFO')
	}

	expandAll() {
		this.isExpanded.summary = true
		this.isExpanded.error = true
		this.isExpanded.info = true
		this.isExpanded.fix = true
	}

	collapseAll() {
		this.isExpanded.summary = false
		this.isExpanded.error = false
		this.isExpanded.info = false
		this.isExpanded.fix = false
	}

	updateCountState() {
		this.counts.summary = this.summaryEvents.length
		this.counts.error = this.errorEvents.length
		this.counts.info = this.infoEvents.length
		this.counts.fix = this.fixEvents.length
	}
}
