<div class="os-list">
	<!-- List -->
	<div
		class="list-wrap d-flex flex-wrap justify-content-start"
		[class.hide-scrollbars]="!useSideScroller || filteredList.length < 10"
		[class.side-scroller]="useSideScroller"
	>
		<div *ngFor="let shift of filteredList">
			<div
				class="card os-card"
				[class.shift-offered]="shift.hasShiftBeenOffered"
				[class.shift-accepted]="shift.hasShiftBeenAccepted"
				(click)="shiftCardClicked(shift)"
			>
				<div class="card-header">
					<div *ngIf="!shift.hasShiftBeenOffered">
						<div class="float-right os-status">Needs Coverage</div>
						<div>Open Shift</div>
					</div>

					<div *ngIf="shift.hasShiftBeenOffered && !shift.hasShiftBeenAccepted">
						<div class="float-right os-status">Pending</div>
						<div>Shift Offered</div>
					</div>

					<div *ngIf="shift.hasShiftBeenOffered && shift.hasShiftBeenAccepted">
						<div class="float-right os-status">Needs Approval</div>
						<div>Offer Accepted <i class="far fa-circle-check" style="margin-left: 4px"></i></div>
					</div>
				</div>
				<div class="os-card-body">
					<div class="trunc-ellipsis">{{ shift.jobName }}</div>
					<div class="trunc-ellipsis">Fill in for {{ shift.empName }}</div>
					<div>{{ shift.jobDate }}</div>
					<div class="table-tag os-countdown" [class.is-warning]="shift.isWarning" [class.is-critical]="shift.isCritial">
						{{ shift.countdown }}
					</div>
				</div>
				<!-- <div class="card-footer" [class.is-warning]="shift.isWarning" [class.is-critical]="shift.isCritial">
					{{ shift.countdown }}
				</div> -->
			</div>
		</div>
	</div>
</div>

<!-- Open Shift Manager Dialog -->
<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Open Shift"
	[(visible)]="openShiftAction.showDialog"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div>
		<button class="btn btn-sm btn-block btn-outline-secondary" (click)="gotoOpenShiftBtnClicked()">Goto Shift</button>
		<button class="btn btn-sm btn-block btn-outline-secondary" (click)="offerOpenShiftBtnClicked()" style="margin-top: 8px">Offer Shift</button>
	</div>
</p-dialog>
