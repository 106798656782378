<div *ngIf="manager.blockTitle" class="block-title">{{ manager.blockTitle }}</div>

<div *ngIf="manager.emailList.length > 0" class="form-group">
	<div class="email-box" *ngFor="let entry of manager.emailList; let idx = index">
		<div>
			{{ entry.address }}
			<i (click)="manager.removeEmailAtIndex(idx)" class="fa fa-times float-right email-box-x" aria-hidden="true"></i>
		</div>
		<div *ngIf="entry.name" class="text-muted">
			<small class="email-name">{{ entry.name }}</small>
		</div>
	</div>
</div>

<table width="100%">
	<tr>
		<td>
			<input
				class="form-control"
				[class.input-invalid]="!manager.isFormValid"
				[class.is-valid]="manager.inputField ? manager.isFormValid : false"
				[(ngModel)]="manager.inputField"
				type="text"
				placeholder="enter email address"
				autocapitalize="off"
				autocomplete="off"
				(keyup.enter)="manager.enterKeyPressed()"
			/>
		</td>
		<td>&nbsp;</td>
		<td style="text-align: right">
			<button (click)="manager.addEmail()" [disabled]="!manager.isInputFieldValid" class="btn btn-sm btn-success">Add</button>
		</td>
	</tr>
</table>

<!-- <div *ngIf="emailList.length === 0 && requiredNotice" class="alert alert-warning" style="margin-top:15px;">
	{{ requiredNotice }}
</div> -->
