import {
	ReportGlobals,
	TransactionMetaDataForReport,
	UserReportMetaData,
	IUserReportFormat,
	UserReportType,
	ReportFieldDateTimeInput,
	ReportFieldGenericInput,
	ReportFieldContactPhoneInput,
	ReportFieldContactEmailInput,
	ReportFieldCheckbox,
	ReportFieldCheckboxWithDescription,
	ReportFieldSelectWithOption,
	IScrollToRegionConfig,
	UserReportValidator,
} from './report-user'

import { log } from '@app/helpers/logger'

export type IncidentReportContactType = 'DEFAULT' | 'DEPARTMENT' | 'CUSTOMER' | 'INFORMANT' | 'INJURED' | 'SUPERVISOR' | 'WITNESS'

export class IncidentReport {
	metaData: UserReportMetaData
	reportData: IncidentReportData

	constructor(reportData: IncidentReportData) {
		const version = reportData.version
		const format = reportData.format
		this.metaData = new UserReportMetaData(version, format)
		this.reportData = reportData
	}
}

export class IncidentReportContact {
	type: IncidentReportContactType = 'DEFAULT'

	date = new ReportFieldDateTimeInput()
	name = new ReportFieldGenericInput()
	phone = new ReportFieldContactPhoneInput()
	email = new ReportFieldContactEmailInput()

	notes: ReportFieldGenericInput = null

	constructor(config?: any) {
		if (config) {
			for (const attr in config) {
				if (config.hasOwnProperty(attr)) {
					this[attr] = config[attr]
				}
			}
		}
	}
}

export class IncidentReportDepartmentContact {
	type: IncidentReportContactType = 'DEPARTMENT'

	date = new ReportFieldDateTimeInput()
	name = new ReportFieldGenericInput()
	identifier = new ReportFieldGenericInput()
	reportNumber = new ReportFieldGenericInput()

	notes: ReportFieldGenericInput = null

	constructor(config?: any) {
		if (config) {
			for (const attr in config) {
				if (config.hasOwnProperty(attr)) {
					this[attr] = config[attr]
				}
			}
		}
	}
}

export class IncidentReportInjuredContact {
	type: IncidentReportContactType = 'INJURED'

	date = new ReportFieldDateTimeInput()
	name = new ReportFieldGenericInput()
	phone = new ReportFieldContactPhoneInput()
	email = new ReportFieldContactEmailInput()

	describeInjuries = new ReportFieldGenericInput()

	wasMedicalAssistanceRefused = new ReportFieldCheckbox()
	wasMedicalAssistanceRequested = new ReportFieldCheckbox()
	wasInjuredPartyWearingGlasses = new ReportFieldCheckbox()
	wasInjuredPartyCarryingAnything = new ReportFieldCheckboxWithDescription()
	describeShoesOfInjuredParty = new ReportFieldGenericInput()

	notes: ReportFieldGenericInput = null
}

export class IncidentReportHelper {
	// Modifies the provided reportData to remove contacts for unchecked options
	static cleanUpEmptyContacts(reportData: IncidentReportData) {
		if (reportData) {
			if (!reportData.didSomeoneInformYou.value) {
				reportData.didSomeoneInformYou.contacts = []
			}
			if (!reportData.wereStatementsGiven.value) {
				reportData.wereStatementsGiven.contacts = []
			}
			if (!reportData.wasSupervisorOrManagerCalled.value) {
				reportData.wasSupervisorOrManagerCalled.contacts = []
			}
			if (!reportData.wasCustomerCalled.value) {
				reportData.wasCustomerCalled.contacts = []
			}
			if (!reportData.wereOfficialsCalled.value) {
				reportData.wereOfficialsCalled.contacts = []
			}
			if (!reportData.wasAnyoneInjured.value) {
				reportData.wasAnyoneInjured.contacts = []
			}
		}
	}
}

export class IncidentReportData {
	version = 1
	format: IUserReportFormat

	locale = 'en'
	countryCode = null

	displayPrefs = {
		format12Hour: ReportGlobals.displayPrefs.format12Hour,
	}

	transactionMetaData: TransactionMetaDataForReport

	incidentDate = new ReportFieldDateTimeInput({ type: 'DATETIME', style: 'DATETIME', value: new Date(), skip: false })

	incidentType = new ReportFieldSelectWithOption()

	incidentReporter = {
		firstName: new ReportFieldGenericInput({ style: 'INPUT' }),
		lastName: new ReportFieldGenericInput({ style: 'INPUT' }),
		license: new ReportFieldGenericInput({ style: 'INPUT' }),
	}

	describeIncident = {
		whatHappened: new ReportFieldGenericInput({ style: 'TEXTAREA' }),
		whenDidItHappen: new ReportFieldGenericInput({ style: 'TEXTAREA' }),
		whyDidItHappen: new ReportFieldGenericInput({ style: 'TEXTAREA' }),
		howDidItHappen: new ReportFieldGenericInput({ style: 'TEXTAREA' }),
	}

	didSomeoneInformYou = {
		type: 'BOOLEAN',
		value: false,
		contacts: [new IncidentReportContact({ type: 'INFORMANT' })],
	}

	didYouObserve = new ReportFieldCheckbox()

	wereYouInvolved = new ReportFieldCheckbox()

	wereStatementsGiven = {
		type: 'BOOLEAN',
		value: false,
		contacts: [new IncidentReportContact({ type: 'WITNESS' })],
	}

	wasPropertyDamaged = new ReportFieldCheckboxWithDescription()

	wasSupervisorOrManagerCalled = {
		type: 'BOOLEAN',
		value: false,
		contacts: [new IncidentReportContact({ type: 'SUPERVISOR' })],
	}

	wasCustomerCalled = {
		type: 'BOOLEAN',
		value: false,
		contacts: [new IncidentReportContact({ type: 'CUSTOMER' })],
	}

	wereOfficialsCalled = {
		type: 'BOOLEAN',
		value: false,
		contacts: [new IncidentReportDepartmentContact()],
	}

	wasAnyoneInjured = {
		type: 'BOOLEAN',
		value: false,
		contacts: [new IncidentReportInjuredContact()],
	}

	describeConditions = {
		weather: new ReportFieldGenericInput({ style: 'TEXTAREA' }),
		location: new ReportFieldGenericInput({ style: 'TEXTAREA' }),
		lighting: new ReportFieldGenericInput({ style: 'TEXTAREA' }),
		floor: new ReportFieldGenericInput({ style: 'TEXTAREA' }),
		signage: new ReportFieldGenericInput({ style: 'TEXTAREA' }),
	}

	additionalInformation = { value: '' }

	constructor(data?: any) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
	}
}

export class IncidentReportValidator {
	reportData: IncidentReportData
	isInDialog = false

	constructor(reportData?: IncidentReportData) {
		this.reportData = reportData
	}

	get isReportValid(): boolean {
		return this.validateReport(false)
	}

	setReportData(reportData: IncidentReportData) {
		this.reportData = reportData
	}

	validateGenericContactInfo(contacts: Array<IncidentReportContact>, type: string, interactive: boolean) {
		let aOrAn = 'A'
		switch (type) {
			case 'informant':
				aOrAn = 'An'
		}

		for (let i = 0; i < contacts.length; i++) {
			const c = contacts[i]
			const id = `${c.type}_${i}`

			if (!(c.type === 'INFORMANT' || c.type === 'WITNESS')) {
				if (!c.date.skip && !c.date.value) {
					if (interactive) {
						UserReportValidator.validationAlert(`${aOrAn} ${type} is missing date contacted.`)
						UserReportValidator.scrollToRegion({ id: id + '_CONTACTED', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
					}
					return false
				}
			}
			if (!c.name.skip && !c.name.value) {
				if (interactive) {
					UserReportValidator.validationAlert(`${aOrAn} ${type} is missing contact name.`)
					UserReportValidator.scrollToRegion({ id: id + '_NAME', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
				}
				return false
			}
			if (!c.phone.skip && !c.phone.value) {
				if (interactive) {
					UserReportValidator.validationAlert(`${aOrAn} ${type} is missing phone number.`)
					UserReportValidator.scrollToRegion({ id: id + '_PHONE', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
				}
				return false
			}
			if (c.phone.value && !c.phone.valid && !(c.phone.skip || c.phone.confirmed)) {
				if (interactive) {
					UserReportValidator.validationAlert(`${aOrAn} ${type} has an invalid phone number.`)
					UserReportValidator.scrollToRegion({ id: id + '_PHONE', isInDialog: this.isInDialog })
				}
				return false
			}

			if (!c.email.skip && !c.email.value) {
				if (interactive) {
					UserReportValidator.validationAlert(`${aOrAn} ${type} is missing email address.`)
					UserReportValidator.scrollToRegion({ id: id + '_EMAIL', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
				}
				return false
			}
			if (c.email.value && c.email.valid === false) {
				if (interactive) {
					UserReportValidator.validationAlert(`${aOrAn} ${type} has an invalid email address.`)
					UserReportValidator.scrollToRegion({ id: id + '_EMAIL', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
				}
				return false
			}
		}
		// Return true if nothing got flagged
		return true
	}

	validateDepartmentContacts(contacts: Array<IncidentReportDepartmentContact>, interactive: boolean) {
		for (let i = 0; i < contacts.length; i++) {
			const c = contacts[i]
			const id = `${c.type}_${i}`

			if (!c.date.value) {
				if (interactive) {
					UserReportValidator.validationAlert('A Police/Fire/EMS contact is missing date contacted.')
					UserReportValidator.scrollToRegion({ id: id + '_CONTACTED', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
				}
				return false
			}
			if (!c.name.skip && !c.name.value) {
				if (interactive) {
					UserReportValidator.validationAlert('A Police/Fire/EMS contact is missing contact name or department.')
					UserReportValidator.scrollToRegion({ id: id + '_NAME', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
				}
				return false
			}
			if (!c.identifier.skip && !c.identifier.value) {
				if (interactive) {
					UserReportValidator.validationAlert('An Police/Fire/EMS contact is missing identification number.')
					UserReportValidator.scrollToRegion({ id: id + '_IDENTIFIER', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
				}
				return false
			}
			if (!c.reportNumber.skip && !c.reportNumber.value) {
				if (interactive) {
					UserReportValidator.validationAlert('An Police/Fire/EMS contact is missing a case report number.')
					UserReportValidator.scrollToRegion({ id: id + '_CASENUMBER', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
				}
				return false
			}
		}
		// Return true if nothing got flagged
		return true
	}

	validateInjuredContacts(contacts: Array<IncidentReportInjuredContact>, interactive: boolean) {
		for (let i = 0; i < contacts.length; i++) {
			const c = contacts[i]
			const id = `${c.type}_${i}`

			if (!c.name.skip && !c.name.value) {
				if (interactive) {
					UserReportValidator.validationAlert('An injured party contact is missing contact name.')
					UserReportValidator.scrollToRegion({ id: id + '_NAME', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
				}
				return false
			}
			if (!c.phone.skip && !c.phone.value) {
				if (interactive) {
					UserReportValidator.validationAlert('An injured party contact is missing phone number.')
					UserReportValidator.scrollToRegion({ id: id + '_PHONE', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
				}
				return false
			}
			if (!c.email.skip && !c.email.value) {
				if (interactive) {
					UserReportValidator.validationAlert('An injured party contact is missing email address.')
					UserReportValidator.scrollToRegion({ id: id + '_EMAIL', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
				}
				return false
			}
			if (c.email.value && c.email.valid === false) {
				if (interactive) {
					UserReportValidator.validationAlert(`An injured party has an invalid email address.`)
					UserReportValidator.scrollToRegion({ id: id + '_EMAIL', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
				}
				return false
			}
			if (c.wasInjuredPartyCarryingAnything.value && !c.wasInjuredPartyCarryingAnything.description) {
				if (interactive) {
					UserReportValidator.validationAlert('Please describe any items the injuried party was carrying.')
					UserReportValidator.scrollToRegion({ id: id + '_DESCRIBE_CARRYING', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
				}
				return false
			}
			if (!c.describeInjuries.skip && !c.describeInjuries.value) {
				if (interactive) {
					UserReportValidator.validationAlert('Please describe any injuries this party incurred.')
					UserReportValidator.scrollToRegion({ id: id + '_DESCRIBE_INJURIES', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
				}
				return false
			}
			if (!c.describeShoesOfInjuredParty.skip && !c.describeShoesOfInjuredParty.value) {
				if (interactive) {
					UserReportValidator.validationAlert("Please describe the injuried party's shoes.")
					UserReportValidator.scrollToRegion({ id: id + '_DESCRIBE_SHOES', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
				}
				return false
			}
		}
		// Return true if nothing got flagged
		return true
	}

	validateReport(interactive: boolean): boolean {
		const reportData = this.reportData
		log('reportData', reportData)

		// alert('Form validation and submission is currently disabled.'); return

		// Incident type selector
		if (!reportData.incidentType.value) {
			if (interactive) {
				UserReportValidator.validationAlert('Please select an incident type.')
				UserReportValidator.scrollToRegion({ id: 'INCIDENT_TYPE', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}
		// Custom incident input
		if (reportData.incidentType.value === 'OTHER') {
			if (!reportData.incidentType.option) {
				if (interactive) {
					UserReportValidator.validationAlert('Please specify the type of incident.')
					UserReportValidator.scrollToRegion({
						id: 'INCIDENT_TYPE',
						isInDialog: this.isInDialog,
						addClass: 'ur-input-needs-info',
						addClassToId: 'INCD_SPECIFY_TYPE',
					})
				}
				return false
			}
		}

		// Reporter first name
		if (!reportData.incidentReporter.firstName.value) {
			if (interactive) {
				UserReportValidator.validationAlert('Please enter your first name.')
				UserReportValidator.scrollToRegion({ id: 'REPORTER_FIRST_NAME', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}
		// Reporter last name
		if (!reportData.incidentReporter.lastName.value) {
			if (interactive) {
				UserReportValidator.validationAlert('Please enter your last name.')
				UserReportValidator.scrollToRegion({ id: 'REPORTER_LAST_NAME', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}
		// Reporter license number
		if (!reportData.incidentReporter.license.skip && !reportData.incidentReporter.license.value) {
			if (interactive) {
				UserReportValidator.validationAlert('Please enter your license number.')
				UserReportValidator.scrollToRegion({ id: 'LICENSE_NUM', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}

		// Describe incident section
		if (!reportData.describeIncident.whatHappened.skip && !reportData.describeIncident.whatHappened.value) {
			if (interactive) {
				UserReportValidator.validationAlert('Please describe what happened.')
				UserReportValidator.scrollToRegion({ id: 'WHAT_HAPPENED_SUM', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}
		if (!reportData.describeIncident.whenDidItHappen.skip && !reportData.describeIncident.whenDidItHappen.value) {
			if (interactive) {
				UserReportValidator.validationAlert('Please describe when incident happened.')
				UserReportValidator.scrollToRegion({ id: 'WHEN_HAPPENED_SUM', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}
		if (!reportData.describeIncident.whyDidItHappen.skip && !reportData.describeIncident.whyDidItHappen.value) {
			if (interactive) {
				UserReportValidator.validationAlert('Please describe why incident happened.')
				UserReportValidator.scrollToRegion({ id: 'WHY_HAPPENED_SUM', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}
		if (!reportData.describeIncident.howDidItHappen.skip && !reportData.describeIncident.howDidItHappen.value) {
			if (interactive) {
				UserReportValidator.validationAlert('Please describe how incident happened.')
				UserReportValidator.scrollToRegion({ id: 'HOW_HAPPENED_SUM', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}

		// Informant section
		if (reportData.didSomeoneInformYou.value) {
			const informantContacts = reportData.didSomeoneInformYou.contacts
			if (!this.validateGenericContactInfo(informantContacts, 'informant', interactive)) {
				return false
			}
		}

		// Statements section
		if (reportData.wereStatementsGiven.value) {
			const witnessContacts = reportData.wereStatementsGiven.contacts
			if (!this.validateGenericContactInfo(witnessContacts, 'witness', interactive)) {
				return false
			}
		}

		// Property damaged section
		if (reportData.wasPropertyDamaged.value && !reportData.wasPropertyDamaged.description) {
			if (interactive) {
				UserReportValidator.validationAlert('Please describe what property was damaged.')
				UserReportValidator.scrollToRegion({ id: 'wasPropertyDamaged', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
			}
			return false
		}

		// Supervisor Contacts
		if (reportData.wasSupervisorOrManagerCalled.value) {
			const supervisorContacts = reportData.wasSupervisorOrManagerCalled.contacts
			if (!this.validateGenericContactInfo(supervisorContacts, 'supervisor', interactive)) {
				return false
			}
		}

		// Customer Contacts
		if (reportData.wasCustomerCalled.value) {
			const customerContacts = reportData.wasCustomerCalled.contacts
			if (!this.validateGenericContactInfo(customerContacts, 'customer', interactive)) {
				return false
			}
		}

		// Department Contacts
		if (reportData.wereOfficialsCalled.value) {
			const deptContacts = reportData.wereOfficialsCalled.contacts
			if (!this.validateDepartmentContacts(deptContacts, interactive)) {
				return false
			}
		}

		// Injury section
		if (reportData.wasAnyoneInjured.value) {
			const injuredContacts = reportData.wasAnyoneInjured.contacts
			if (!this.validateInjuredContacts(injuredContacts, interactive)) {
				return false
			}

			// Describe incident section
			if (!reportData.describeConditions.weather.skip && !reportData.describeConditions.weather.value) {
				if (interactive) {
					UserReportValidator.validationAlert('Please describe weather conditions.')
					UserReportValidator.scrollToRegion({ id: 'DESCRIBE_WEATHER', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
				}
				return false
			}
			if (!reportData.describeConditions.location.skip && !reportData.describeConditions.location.value) {
				if (interactive) {
					UserReportValidator.validationAlert('Please describe location conditions.')
					UserReportValidator.scrollToRegion({ id: 'DESCRIBE_LOCATION', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
				}
				return false
			}
			if (!reportData.describeConditions.lighting.skip && !reportData.describeConditions.lighting.value) {
				if (interactive) {
					UserReportValidator.validationAlert('Please describe lighting conditions.')
					UserReportValidator.scrollToRegion({ id: 'DESCRIBE_LIGHTING', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
				}
				return false
			}
			if (!reportData.describeConditions.floor.skip && !reportData.describeConditions.floor.value) {
				if (interactive) {
					UserReportValidator.validationAlert('Please describe floor conditions.')
					UserReportValidator.scrollToRegion({ id: 'DESCRIBE_FLOOR', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
				}
				return false
			}
			if (!reportData.describeConditions.signage.skip && !reportData.describeConditions.signage.value) {
				if (interactive) {
					UserReportValidator.validationAlert('Please describe signage conditions.')
					UserReportValidator.scrollToRegion({ id: 'DESCRIBE_SIGNAGE', isInDialog: this.isInDialog, addClass: 'ur-input-needs-info' })
				}
				return false
			}
		}

		// If nothing is missing then the report is valid
		return true
	}
}
