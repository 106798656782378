import { DateTimeHelper } from '@app/helpers/datetime'
import moment from 'moment-timezone'
import { Subject } from 'rxjs'

export class ReportRecord {
	id: number
	company_id: number
	created: string
	custom_end: string
	custom_start: string
	delay_days: number
	deleted: string
	email_addresses: string
	employee_sort = 'LAST'
	filter: string
	java_class: string
	last_run: string
	last_run_meta: string
	last_submitted: string
	manual: boolean
	name: string
	next_run: string
	next_run_ts: string
	options: string
	period: string
	period_start: string
	run_time: string
	send_email: boolean
	send_sms: boolean
	suspect_enabled: boolean
	timezone: string
	updated: string
	supervisor = 0
	supervisor_group_id: number
	shared_supervisor_ids: Array<number> = []
	supervisor_ids: Array<number> = [] // Virtual property

	constructor(record?: ReportRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.normalizeDates()
			this.normalizeTimes()
		}
	}

	normalizeTimes() {
		this.run_time = DateTimeHelper.normalizeTime(this.run_time)
	}

	normalizeDates() {
		this.created = DateTimeHelper.stripUtcTag(this.created)
		this.updated = DateTimeHelper.stripUtcTag(this.updated)
		this.last_run = DateTimeHelper.stripUtcTag(this.last_run)
		this.last_submitted = DateTimeHelper.stripUtcTag(this.last_submitted)
		this.next_run_ts = DateTimeHelper.stripUtcTag(this.next_run_ts)
	}
}

export class ReportHistoryRecord {
	id: number
	reports_id: number
	name: string
	java_class: string
	start: string
	end: string
	completed: string
	empty: boolean
	error: string
	filter: string
	file_reference: string
	created: string
	ran_by: number
	supervisor_ids: Array<number> = []
	format = ''

	constructor(record?: ReportHistoryRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
		}
	}
}

export interface IReportsLambdaResult {
	success: boolean
	data: any
}

// ID - Emp ID, First - First Name, Last - Last Name, Hours - Total Hours, Transactions - Total Transaction Count
export type ReportOptionsEmployeeSort = 'id' | 'first' | 'last' | 'hours' | 'transactions'

export class ReportOptions {
	pagination_enabled = false
	show_emp_in_out_notes = false
	show_admin_notes = false
	show_comm_log = false
	employee_sort = 'last'
	suspect_enabled = false
	decimal_enabled = false
	show_emp_tags = false
	show_job_tags = false
	dow_ot_start_iso_id = 1
	employee_rate_enabled = false
	vendor_rate_enabled = false
	client_rate_enabled = false
	file_uploads_days: number = null

	constructor(data?: string) {
		let config = null
		try {
			config = JSON.parse(data)
		} catch (err) {
			return
		}
		for (const attr in config) {
			if (config.hasOwnProperty(attr)) {
				this[attr] = config[attr]
			}
		}
	}
}

export interface FilterData {
	emp_supervisor_id: number | Array<number>
	employee_tags: string | Array<string>
	employee_departments: string | Array<string>
	jobsite_supervisor_id: number | Array<number>
	emp_id: number | Array<number>
	jobsite_id: number | Array<number>
	job_id: number | Array<number>
	job_tags: string | Array<string>
	jobsite_districts: string | Array<string>
	client_id: number | Array<number>
	vendor_id: number | Array<number>
	dow_iso_id: Array<number>
	// aal1_code: string | Array<string>
}

export class ReportType {
	id: number
	order: number
	company_id: number
	name: string
	java_class: string
	created: string
	description: string

	constructor(record: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
	}
}

export type ReportsViewTabState = 'DEFINE' | 'VIEW'

export class ReportsFilterInfoEvent {
	name: string
	filter: string
	constructor(name: string, filter: string) {
		this.name = name
		this.filter = filter
	}
}

export class ReportsViewManager {
	version = 1
	showReportInfo = new Subject<number>()
	showFilterInfo = new Subject<ReportsFilterInfoEvent>()

	showAllReports = false

	pulseFirstRow = false
	searchFilter = ''

	currentView: ReportsViewTabState = 'DEFINE'
	dayViewDate: Date = null

	sectionTabNeedsUpdate = new Subject<boolean>()

	setView(view: ReportsViewTabState) {
		this.currentView = view
	}
}

export class ReportSaveCompleteEvent {
	action: string
	record: ReportRecord
	constructor(action: string, record: ReportRecord) {
		this.action = action
		this.record = record
	}
}
