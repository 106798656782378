<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<div class="alert alert-info alert-bg-gray alert-box">
		<div>To make changes, check each section you wish to modify and adjust the settings.</div>
		<div style="margin-top: 12px; color: firebrick">
			Please be careful when editing batches. These changes will overwrite existing settings for all of the selected employees.
		</div>
		<div style="margin-top: 12px">
			<div>{{ record.employee_ids.length }} employee{{ record.employee_ids.length !== 1 ? 's' : '' }} will be modified</div>
		</div>
	</div>

	<!-- Employee active / inactive -->
	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="record.active_change" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong (click)="record.active_change = !record.active_change" class="has-pointer" [class.item-selected]="record.active_change"
				>Modify active / inactive status</strong
			>
		</div>
	</div>
	<div *ngIf="record.active_change" class="change-block">
		<app-form-input-switch
			[(model)]="record.active"
			[enabledLabel]="'Employee is active'"
			[enabledHint]="'to de-activate'"
			[disabledLabel]="'Employee is inactive'"
			[disabledHint]="'to activate'"
			[wrapStyle]="{ margin: '30px 0px 10px 0px' }"
			[labelStyle]="{ 'font-size': '1.4rem', 'font-weight': '400' }"
		></app-form-input-switch>
	</div>

	<!-- Supervisor -->
	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="record.supervisor_id_change" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong
				(click)="record.supervisor_id_change = !record.supervisor_id_change"
				class="has-pointer"
				[class.item-selected]="record.supervisor_id_change"
				>Modify assigned supervisor</strong
			>
		</div>
	</div>
	<div *ngIf="record.supervisor_id_change" class="change-block">
		<p-select
			appScrollLockSelect
			[(ngModel)]="record.supervisor_id"
			[options]="supervisorOptions"
			[filter]="true"
			filterBy="label,data.external_id"
			[autofocusFilter]="devDetect.isDesktop()"
		></p-select>
	</div>

	<!-- Start Date -->
	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="record.start_date_change" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong (click)="record.start_date_change = !record.start_date_change" class="has-pointer" [class.item-selected]="record.start_date_change"
				>Modify start date</strong
			>
		</div>
	</div>
	<div *ngIf="record.start_date_change" class="change-block">
		<app-date-time-picker
			id="START_DATE"
			[currentDate]="record.start_date"
			[pickerType]="'DATE'"
			[pickerMode]="'dialog'"
			[pickerLabel]="'Start Date'"
			[format]="'ddd MMM Do, YYYY'"
			[useInlineClearIcon]="true"
			(pickerDidChange)="record.start_date = $event"
			(pickerDidClose)="record.start_date = $event"
		>
		</app-date-time-picker>
	</div>

	<!-- Employment Status-->
	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="record.status_change" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong (click)="record.status_change = !record.status_change" class="has-pointer" [class.item-selected]="record.status_change"
				>Modify employment status</strong
			>
		</div>
	</div>
	<div *ngIf="record.status_change" class="change-block">
		<p-select
			appScrollLockSelect
			[(ngModel)]="record.status"
			[options]="employmentStatusOptions"
			[filter]="true"
			filterBy="label,data.external_id"
			[autofocusFilter]="devDetect.isDesktop()"
		></p-select>
	</div>

	<!-- Department -->
	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="record.department_change" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong (click)="record.department_change = !record.department_change" class="has-pointer" [class.item-selected]="record.department_change"
				>Modify department</strong
			>
		</div>
	</div>
	<div *ngIf="record.department_change" class="change-block">
		<p-autoComplete
			appScrollLockAutoComplete
			placeholder="Employee Department"
			[inputStyleClass]="'p-autocomplete-mod'"
			[(ngModel)]="record.department"
			[suggestions]="departmentOptions"
			[delay]="150"
			[completeOnFocus]="true"
			(completeMethod)="searchDepartment($event)"
		>
		</p-autoComplete>
	</div>

	<!-- Tags -->
	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="record.tags_json_change" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong (click)="record.tags_json_change = !record.tags_json_change" class="has-pointer" [class.item-selected]="record.tags_json_change"
				>Modify employee tags</strong
			>
		</div>
	</div>
	<div *ngIf="record.tags_json_change" class="change-block">
		<app-form-tags
			#tagComp
			[tagsJson]="record.tags_json"
			[tagOptionsList]="tagOptionsList"
			(tagsJsonChange)="record.tags_json = $event"
		></app-form-tags>
		<hr style="margin: 30px 0px 25px 0px" />
		<div>
			<div class="float-right">
				<span
					class="badge has-pointer"
					style="margin-right: 8px"
					[class.text-bg-success]="record.tags_action === 'ADD'"
					[class.badge-bg-lightgray]="record.tags_action !== 'ADD'"
					(click)="record.tags_action = 'ADD'"
				>
					ADD
				</span>
				<span
					class="badge has-pointer"
					style="margin-right: 8px"
					[class.text-bg-success]="record.tags_action === 'REMOVE'"
					[class.badge-bg-lightgray]="record.tags_action !== 'REMOVE'"
					(click)="record.tags_action = 'REMOVE'"
				>
					REMOVE
				</span>
				<span
					class="badge has-pointer"
					[class.text-bg-success]="record.tags_action === 'REPLACE_ALL'"
					[class.badge-bg-lightgray]="record.tags_action !== 'REPLACE_ALL'"
					(click)="record.tags_action = 'REPLACE_ALL'"
				>
					REPLACE
				</span>
			</div>
			<div>
				<span style="font-weight: 700">Action</span>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('tags_action')"></i>
			</div>
		</div>
	</div>
</div>
