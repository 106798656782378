import { DateTimeHelper } from '@app/helpers/datetime'
import { log } from '@app/helpers/logger'

export enum GoogleCalendarSyncType {
	w2w = 'W2W',
}

// export interface IGoogleCalendarSync {
// 	id: number
// 	company_id: number
// 	type: GoogleCalendarSyncType
// 	created: string // Date
// 	updated: string // Date
// 	calendar_id: string
// 	watch_uuid: string
// 	watch_expires: string // Date
// 	watch_resource_id: string
// 	sync_last_full: string // Date
// 	sync_last_incremental: string // Date
// 	next_sync_token: string
// 	email: string
// 	ignore_entries_with: Array<string>
// 	sync_lock: string // Date
// 	sync_last_verify: string // Date
// 	sync_error_count: number
// }

export class GoogleCalendarSync {
	id: number
	company_id: number
	type: GoogleCalendarSyncType
	created: string
	updated: string
	calendar_id: string
	watch_uuid: string
	watch_expires: string
	watch_resource_id: string
	sync_last_full: string
	sync_last_incremental: string
	sync_last_api: string
	next_sync_token: string
	email: string
	any_employee: Array<string> = []
	ignore_entries_with: Array<string> = []
	sync_lock: string
	sync_last_verify: string = null
	sync_error_count: number = 0
	sync_last_file_import: string = null
	w2w_api_key: string = null
	partner_api: boolean = false

	constructor(record?: GoogleCalendarSync) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(record.created)
			this.updated = DateTimeHelper.stripUtcTag(record.updated)
			this.sync_last_full = DateTimeHelper.stripUtcTag(record.sync_last_full)
			this.sync_last_incremental = DateTimeHelper.stripUtcTag(record.sync_last_incremental)
			this.sync_last_verify = DateTimeHelper.stripUtcTag(record.sync_last_verify)
			this.sync_last_api = DateTimeHelper.stripUtcTag(record.sync_last_api)
			this.watch_expires = DateTimeHelper.stripUtcTag(record.watch_expires)
			this.sync_last_file_import = DateTimeHelper.stripUtcTag(record.sync_last_file_import)

			if (!this.any_employee) this.any_employee = []
			if (!this.ignore_entries_with) this.ignore_entries_with = []
		}
	}
}

export class GoogleSyncLogEntry {
	id: number
	company_id: number
	created: string
	error: boolean
	message: string
	event_id: string
	summary: string
	event_date: string
	event_detail: string
	cal_sync_id: number

	constructor(record?: GoogleSyncLogEntry) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(record.created)
		}
	}

	getEventDetail(): Object {
		let result = null
		try {
			result = JSON.parse(this.event_detail)
		} catch (err) {
			log('Parse Error', err)
		}
		return result
	}
}
