import { Component, OnInit, OnDestroy } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { CognitoCallback, CoreService, PrefsService, UserLoginService, UserRegistrationService } from '@app/services'

import { parseNumber, ParsedNumber, formatNumber, CountryCode, isValidNumberForRegion } from 'libphonenumber-js'

import { log, Helper, DisplayHelper } from '@app/helpers'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-auth-forgot-pass-1',
    templateUrl: './forgotPassword.html',
    styleUrls: ['./forgotPassword.component.scss'],
    standalone: false
})
export class ForgotPasswordStep1Component implements OnDestroy, CognitoCallback {
	mobile: string
	mobileE164: string
	mobileNumberValid = false
	countryCode: CountryCode
	countryCodeData = Helper.countryIsoData.filter((region) => region.regionSupport)
	errorMessage: string

	// private usernameFixAttempted = false;
	// private currentUsername = '';

	ipAddress: string = ''

	private subs = new Subscription()
	constructor(
		public router: Router,
		public userService: UserLoginService,
		public userRegSrvc: UserRegistrationService,
		private prefsService: PrefsService,
		private coreSrvc: CoreService,
	) {
		log('ForgotPasswordStep1Component - Created')
		this.subs.add(
			this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => {
				this.handleScreenSizeChange()
			}),
		)
		this.handleScreenSizeChange()

		this.errorMessage = null
		this.countryCode = this.prefsService.data.defaultLoginRegion as CountryCode
		this.coreSrvc.connectSrvc.getMyIp().then((ipAddress) => {
			this.ipAddress = ipAddress ? `(${ipAddress})` : ''
		})
	}

	ngOnDestroy() {
		log('ForgotPasswordStep1Component - Destroyed')
	}

	handleScreenSizeChange() {
		DisplayHelper.setBodyBackgroundForAuthFlow()
	}

	formatPhone() {
		const number = this.mobile
		const countryCode = this.countryCode

		if (number && number.length > 3 && isValidNumberForRegion(number, this.countryCode)) {
			const parsedNumber = parseNumber(number, countryCode) as ParsedNumber
			this.mobile = formatNumber(parsedNumber, 'NATIONAL')
			this.mobileNumberValid = true
		} else {
			this.mobileNumberValid = false
		}
	}

	isPhoneValid(): boolean {
		const number = this.mobile
		const countryCode = this.countryCode
		// const parsedNumber = parseNumber(this.mobile, this.countryCode);
		if (number && number.length > 3 && isValidNumberForRegion(this.mobile, countryCode)) {
			return true
		}
		return false
	}

	onNext() {
		this.errorMessage = null
		// this.usernameFixAttempted = false;
		// this.currentUsername = this.mobile;
		const number = this.mobile
		const countryCode = this.countryCode
		if (number && number.length > 3 && isValidNumberForRegion(number, countryCode)) {
			const parsedNumber = parseNumber(number, countryCode) as ParsedNumber
			this.mobileE164 = formatNumber(parsedNumber, 'E.164')
			log('Reset Password for:', this.mobileE164)
			this.userRegSrvc.forgotPassMobile = this.mobileE164
			this.userService.forgotPassword(this.mobileE164, this)
			this.router.navigate(['/home/forgotPasswordConfirm']) /// ADDED
		} else {
			alert('Mobile number is not valid for the region selected.')
		}
	}

	cognitoCallback(message: string, result: any) {
		// this.router.navigate(['/home/forgotPassword', this.mobileE164]);
		// if (message == null && result == null) {
		// 	this.router.navigate(['/home/forgotPassword', this.mobileE164]);
		// } else {
		// 	// this.errorMessage = message;
		// 	this.errorMessage = 'Mobile number not found. Please try again or contact support for assistance.'
		// }
	}
}

@Component({
    selector: 'app-auth-forgot-pass-2',
    templateUrl: './forgotPasswordStep2.html',
    styleUrls: ['./forgotPassword.component.scss'],
    standalone: false
})
export class ForgotPassword2Component implements CognitoCallback, OnInit, OnDestroy {
	verificationCode = ''
	mobile: string
	username: string
	password = ''
	errorMessage: string
	isWorking = false
	showSuccess = false
	private sub: any

	constructor(
		public router: Router,
		public route: ActivatedRoute,
		public userService: UserLoginService,
		private userRegSrvc: UserRegistrationService,
	) {}

	ngOnInit() {
		// this.sub = this.route.params.subscribe(params => {
		// 	this.mobile = params['mobile']
		// 	log('Mobile Number from the url: ', this.mobile)
		// })
		this.mobile = this.userRegSrvc.forgotPassMobile
		this.username = formatNumber(this.mobile, 'NATIONAL')
		this.errorMessage = null
	}

	ngOnDestroy() {
		// this.sub.unsubscribe()
	}

	isPasswordValid(): boolean {
		return Helper.isPasswordValid(this.password)
	}

	isCodeValid() {
		const code = Helper.stripNonNumeric(this.verificationCode)
		return code.length === 6 && this.verificationCode.length === 6
	}

	onNext() {
		this.errorMessage = null
		const isPassValid = Helper.isPasswordValid(this.password)
		const isConfirmCodeValid = this.verificationCode.length === 6
		log('Password', this.password, isPassValid)
		if (!isPassValid) {
			this.errorMessage = null
			alert('Passwords must be at least 8 characters long and include both letters and numbers.')
			return
		}
		if (!isConfirmCodeValid) {
			const alertMsg = `Please enter the 6 digit confirmation code sent to ${this.username}`
			alert(alertMsg)
			return
		}
		// else {
		// 	this.errorMessage = 'Pass valid.'
		// 	return
		// }
		this.isWorking = true
		this.userService.confirmNewPassword(this.mobile, this.verificationCode, this.password, this)
	}

	cognitoCallback(message: string) {
		if (message != null) {
			// error
			// this.errorMessage = message;
			this.errorMessage = 'Password reset not successful. Please try again or contact support to resolve this issue.'
			this.isWorking = false
			log('result: ', this.errorMessage)
		} else {
			// success
			this.showSuccess = true
			// this.navigateToLogin();
		}
	}

	navigateToLogin(): boolean {
		this.router.navigate(['/home/login'])
		return false
	}
}
