import { log } from '@app/helpers/logger'
import { ReportRendererConfig } from './report-renderer'
import { DatabaseTableName } from './table'

// Generic Events
// key: tableDraw - DataTables draw event => data: Table HTML tag ID e.g. transactionsTable

export type EventTimePosition = 'BEFORE_EVENT' | 'AT_EVENT' | 'AFTER_EVENT'

export class GenericEvent {
	key: string
	data: any

	constructor(key: string, data: any) {
		this.key = key
		this.data = data
	}
}

export class DateRangeFilterEvent {
	start: Date
	end: Date
	constructor(start: Date, end: Date) {
		this.start = start
		this.end = end
	}
}

export class BillingMessageEvent {
	btnText: string
	message: string
	blink = false
	warn = false
	constructor(btnText: string, message: string, blink: boolean) {
		this.btnText = btnText
		this.message = message
		this.blink = blink
	}
}

export interface DialogActionEvent {
	action: string
	table: DatabaseTableName
}

export class DialogControlEvent {
	callerId: string
	direction: 'toDialog' | 'toContent' | 'toBroadcast'
	eventName: DialogControlEventName
	data: any

	constructor(identifier: string, direction: 'toDialog' | 'toContent' | 'toBroadcast', eventName: DialogControlEventName, data: any) {
		this.callerId = identifier
		this.direction = direction
		this.eventName = eventName
		this.data = data
	}
}

export type DialogControlEventName =
	| 'showBackBtn'
	| 'hideBackBtn'
	| 'backBtnClicked'
	| 'enableSaveBtn'
	| 'disableSaveBtn'
	| 'saveBtnClicked'
	| 'saveComplete'

// Manage Full Screen in App Component

export class FullScreenViewConfig {
	isFullScreen = false
	action: FullScreenViewAction = 'CLOSE'
	config: ReportRendererConfig = null
	data: any = null
}

export type FullScreenViewAction =
	| 'CLOSE'
	| 'SHOWSHIFTREPORT'
	| 'SHOWINCIDENTREPORT'
	| 'SHOWHOMECAREREPORT'
	| 'SHOWVEHICLEINSPECTIONREPORT'
	| 'SHOWCHECKLISTREPORT'
	| 'SHOWSHIFTSUMMARYREPORT'
	| 'SHOWUNKNOWNREPORT'

export class FullScreenViewEvent {
	action: FullScreenViewAction
	data: any
	constructor(action: FullScreenViewAction, data: any) {
		this.action = action
		this.data = data
	}
}

export class WorkingDialogConfig {
	onShow = () => {}
	onHide = () => {}

	message = 'Working...'
	style = { width: '300px' }
	dismissableMask = true
	isVisible = false

	public updateConfig(config: WorkingDialogConfig) {
		for (const attr in config) {
			if (config.hasOwnProperty(attr)) {
				this[attr] = config[attr]
			}
		}
	}
}

export class ContactSupportEvent {
	title: string
	resource: DatabaseTableName
	recordId: number

	constructor(title: string, resource: DatabaseTableName, recordId: number) {
		this.title = title
		this.resource = resource
		this.recordId = recordId
	}
}

export class SendAnnouncementEvent {
	ids: Array<number> = []

	constructor(ids: Array<number>) {
		this.ids = ids
	}
}
