import { AdminPrefs } from '@app/models/prefs'
import { AccessPermissions } from '@app/models/permissions'
import { log } from '@app/helpers/logger'
import { DateTimeHelper } from '@app/helpers'
import { UserSkillLinkRecord } from './call-center'

export type EmailNotificationTarget = 'ALL' | 'ASSIGNED'
export type UserRole = 'INTERNAL' | 'PRIMARY' | 'SECONDARY' | 'MANAGER' | 'NO_COGNITO' | 'READONLY'

export class UserRecord {
	id: number
	allowed_companies: Array<number>
	company_id: number
	c2c_phone_e164: string
	dispatcher_active: string
	dispatcher_status: string
	username: string
	phone: string
	phone_e164: string
	cognito_id: string
	description: string
	password: string
	role: UserRole
	last_login: string
	last_activity: string
	failed_attempts: number
	locked: string
	email: string
	first_name: string
	last_name: string
	admin_prefs_json: string
	permissions_json: string
	managed_by: number
	managed_supervisor_ids: Array<number> = []
	external_id: string = null
	supervisor_role_id: number = null

	sms_email_enabled: boolean
	email_target: EmailNotificationTarget = 'ALL'
	time_off_enabled: boolean
	time_off_target: EmailNotificationTarget = 'ALL'
	max_daily_enabled: boolean
	max_daily_target: EmailNotificationTarget = 'ALL'
	schedule_offer_enabled: boolean
	schedule_offer_target: EmailNotificationTarget = 'ALL'
	date_lock_enabled: boolean
	date_lock_target: EmailNotificationTarget = 'ALL'

	linked_employee_id: number
	edit_own_trans: boolean

	users_skill: Array<UserSkillLinkRecord> = []

	v_tos: string
	v_privacy: string

	created: string
	updated: string

	constructor(record?: UserRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.last_activity = DateTimeHelper.stripUtcTag(this.last_activity)
			this.last_login = DateTimeHelper.stripUtcTag(this.last_login)
			this.dispatcher_active = DateTimeHelper.stripUtcTag(this.dispatcher_active)
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
			this.users_skill = this.users_skill.map((rec) => new UserSkillLinkRecord(rec))
		}
		if (!this.allowed_companies) {
			this.allowed_companies = []
		}
	}

	// Use the interface and do not instantiate a class object or the company
	// defaults will be overwritten when merged with the company admin prefs
	getUserAdminPrefsDelta(): AdminPrefs {
		let result: AdminPrefs = null
		if (this.admin_prefs_json) {
			try {
				result = JSON.parse(this.admin_prefs_json)
			} catch (error) {
				log('Error parsing user admin prefs', error)
			}
		}
		return result
	}

	getAccessPermissions(): AccessPermissions {
		return new AccessPermissions(this.permissions_json)
	}

	getUpdateRecord(): UserRecord {
		return JSON.parse(JSON.stringify(this))
	}
}
