import { DateTimeHelper } from '@app/helpers/datetime'
import { environment } from '@env/environment'
import { ImageFile } from './image'
import { log } from '@app/helpers'
import { C2CDirectoryLookup, ClickToCallSourceType, ClickToCallSourceTypeFormatter } from '@app/models'

export type CommLogViewTabState = 'AGENTS' | 'IVR' | 'SMS'

// Set shouldCheckAccess to false after check for access to AGENT table so return visit to
// Comm Log will be able to use the last viewed tab.
export class CommLogViewManager {
	version = 1
	shouldCheckAccess = true
	isLoading: false
	currentView: CommLogViewTabState = 'IVR'
	dayViewDate: Date
	timezone: string
}

export class C2CLogRecord {
	id: number
	created: string
	company_id: number
	company_name: string
	caller_user_id: number // User ID
	caller_name: string
	caller_phone_e164: string
	dest_phone_e164: string
	dest_name: string
	dest_employee_id: number // Employee ID
	dest_user_id: number // User ID
	dest_caller_id: string
	transaction_id: number // Transaction ID
	tlog_company_id: number // Nullable Company ID for transaction
	access_count: number = 0
	audio_file: string
	transcript: string
	transcript_json: string // JSON Blob (May not be included)
	last_status: string
	last_update: string // Timestamp
	call_source: ClickToCallSourceType // Enum for call ?
	schedule_log_id: number // Schedule Log ID
	duration: string // PT5M

	direction: 'IN' | 'OUT'
	files_json: string

	timezone: string // Coalesced from time entry or company default
	imageFiles: Array<ImageFile> = []

	constructor(record?: C2CLogRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.last_update = DateTimeHelper.stripUtcTag(this.last_update)
			if (this.files_json) {
				try {
					const data = JSON.parse(this.files_json)
					this.imageFiles = data.files.map((rec) => new ImageFile(rec))
				} catch (error) {
					log('Error parsing image files', error)
				}
			}
		}
	}

	public getAudioFileUrl(): string {
		const audioHostUrl = 'https://' + environment.audioDomain + '/'
		const fileId = this.audio_file

		return fileId ? audioHostUrl + fileId : null
	}
}

export class IVRLogRecord {
	id: number
	created: string
	company_id: number
	company_name: string
	call_id: string
	from_e164: string
	from_desc: string
	to_e164: string

	constructor(record?: IVRLogRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
		}
	}
}

export class SMSLogRecord {
	id: number
	created: string // UTC date string
	company_id: number
	company_name: string
	from: string
	to: string
	body: string
	provider: string
	provider_status: 'SENT' | 'UNDELIVERED' | 'DELIVERED'
	provider_error_code: string
	provider_last_update: string // UTC date string
	direction: 'IN' | 'OUT'
	provider_id: string
	c2c_id: number
	ann_id: number
	offer_entry_id: number

	constructor(record?: SMSLogRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.provider_last_update = DateTimeHelper.stripUtcTag(this.provider_last_update)
			if (this.provider_status) this.provider_status = this.provider_status.toUpperCase() as 'SENT' | 'UNDELIVERED' | 'DELIVERED'
		}
	}
}

export class CallLogRecordWrap {
	clr: C2CLogRecord

	direction: 'IN' | 'OUT'
	isSms = false
	isShowingFullTranscript = false
	lastStatus = ''

	// fromLookup: C2CDirectoryLookupResult
	fromE164: string
	fromName: string
	fromSource: string

	// toLookup: C2CDirectoryLookupResult
	toE164: string
	toName
	toSource: string

	audioFileUrl = ''

	constructor(record: C2CLogRecord, directory: C2CDirectoryLookup) {
		this.clr = record
		this.direction = record.direction
		this.isSms = record.call_source === 'INBOUNDSMS' || record.call_source === 'OUTBOUNDSMS'
		this.lastStatus = record.last_status && record.last_status !== 'Call ended' ? record.last_status : ''

		const fromLookup = directory.lookupNumber(record.caller_phone_e164, ['USER', 'SYSTEM', 'LINKEDJOB'])
		this.fromE164 = record.caller_phone_e164
		this.fromName = fromLookup ? fromLookup.name : record.caller_name
		this.fromSource = fromLookup ? ClickToCallSourceTypeFormatter.getDescription(fromLookup.source) : ''

		const toLookup = directory.lookupNumber(record.dest_phone_e164, ['USER', 'SYSTEM', 'LINKEDJOB'])
		this.toE164 = record.dest_phone_e164 === '+18888888888' ? null : record.dest_phone_e164
		this.toName = toLookup ? toLookup.name : record.dest_name
		this.toSource = toLookup ? ClickToCallSourceTypeFormatter.getDescription(toLookup.source) : ''

		this.audioFileUrl = record.getAudioFileUrl()
	}
}
