import { CoreService } from '@app/services'
import { environment } from '@env/environment'

export class ViewQRCodeDialogData {
	inputString = ''
	printHeader = ''
	printSubHeader = ''
	jobCode = ''

	constructor(inputString: string, printHeader: string, printSubHeader: string, jobCode: string) {
		this.inputString = inputString
		this.printHeader = printHeader
		this.printSubHeader = printSubHeader
		this.jobCode = jobCode
	}
}

export class JobQRCodeBUilder {
	static buildCode(jobId: number, coreSrvc: CoreService): string {
		const company = coreSrvc.dbSrvc.settingSrvc.getCompany()
		const companyId = company.id
		const empAppDomain = company.emp_app_domain
		const encodeString = `${companyId}|${jobId}`
		const base64String = window.btoa(encodeString)
		const hostname = empAppDomain ?? environment.empAppDomain
		return `https://${hostname}/#/auth?jqrcode=${base64String}`
	}
}

export class TransactionTableQRCodeConfig {
	enableValidation = false
	requireOnCheckin = false
	requireonCheckout = false

	constructor(enableVerification: boolean, requireOnCheckin: boolean, requireOnCheckout: boolean) {
		this.enableValidation = enableVerification
		this.requireOnCheckin = requireOnCheckin
		this.requireonCheckout = requireOnCheckout
	}
}
