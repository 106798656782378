<div class="container section-container">
	<div>
		<div class="section-header">
			<i *ngIf="!isShowingHistory" class="far fa-database"></i>
			<i *ngIf="isShowingHistory" class="far fa-history"></i>
			{{ isShowingHistory ? 'History View' : 'Audit Log' }}
		</div>
		<div class="alt-nav-block float-right">
			<i
				class="fas fa-chevron-left alt-nav-icon"
				[class.alt-nav-icon-disabled]="!canMoveToPreviousDate || isShowingHistory || endMom"
				(click)="moveToPreviousDate()"
			></i>
			<i class="far fa-calendar alt-nav-icon" (click)="showFilterDatesModal = true"></i>
			<i
				class="fas fa-chevron-right alt-nav-icon"
				[class.alt-nav-icon-disabled]="!canMoveToNextDate || isShowingHistory || endMom"
				(click)="moveToNextDate()"
			></i>
		</div>
		<div *ngIf="!isDataLoaded" class="data-loading"><i class="fa fa-loader fa-spin"></i>&nbsp;Loading Data...</div>
	</div>

	<div *ngIf="isDataLoaded" class="current-view" [class.current-view-lg]="!endMom && !isShowingHistory">
		<button *ngIf="isShowingHistory" class="exit-history-btn" (click)="exitHistoryViewBtnClicked()">Exit History View</button>
		<div *ngIf="!isShowingHistory">
			<div>{{ currentViewFormatter() }}</div>
			<div *ngIf="endMom" class="link-text" (click)="resetFilterClicked()">clear filter</div>
		</div>
	</div>

	<div class="section-table">
		<table id="auditLogTable" class="stripe nowrap" cellspacing="0" width="100%"></table>
	</div>
</div>

<i id="clear-search-icon" (click)="clearSearch()" class="fa fa-times-circle-o"></i>

<app-basic-modal
	[(visible)]="selectedRecord.showDialog"
	modalId="auditLogDetailsModal"
	modalHeader="{{ selectedRecord.header }}"
	modalFooter="{{ selectedRecord.footer }}"
	[modalWidth]="450"
	[modalHeight]="800"
	(visibleChange)="detailsDialogChange($event)"
>
	<div *ngIf="selectedRecord.showDialog">
		<div *ngFor="let item of getSelectedRecordPropertyList()">
			<div class="item-name">{{ resourceFormatter(item.name) }}</div>
			<div class="item-value">{{ item.value }}</div>
		</div>
		<!-- <code><pre>{{ selectedRecord.data | json }}</pre></code> -->
	</div>
</app-basic-modal>

<app-basic-modal
	[(visible)]="diffRecords.showDialog"
	[preventBackgroundClose]="true"
	modalId="auditLogDiffModal"
	modalHeader="{{ diffRecords.header }}"
	modalFooter="{{ diffRecords.footer }}"
	[modalWidth]="450"
>
	<div *ngIf="diffRecords.showDialog">
		<app-audit-log-diff [entry]="diffRecords.record"></app-audit-log-diff>
	</div>
</app-basic-modal>

<p-dialog
	styleClass="p-dialog-noheader"
	header="Filter Dates"
	[(visible)]="showFilterDatesModal"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div class="options-block" style="margin-top: 10px">
		<div>
			<!-- <a href="#" class="clear-link float-right" (click)="filterStartDate = null; false">clear</a> -->
			<div class="options-title">Start Date</div>
		</div>
		<app-date-time-picker
			[currentDate]="filterStartDate"
			[pickerType]="'DATE'"
			[pickerMode]="'dialog'"
			[pickerLabel]="'Start Date'"
			[hour12Timer]="format12Hour"
			[format]="'MMM Do, YYYY'"
			(pickerDidClose)="filterStartDate = $event"
		>
		</app-date-time-picker>
	</div>

	<div class="options-block">
		<div>
			<a href="#" class="clear-link float-right" (click)="filterEndDate = null; (false)">clear</a>
			<div class="options-title">End Date</div>
		</div>
		<app-date-time-picker
			[currentDate]="filterEndDate"
			[pickerType]="'DATE'"
			[pickerMode]="'dialog'"
			[pickerLabel]="'End Date'"
			[hour12Timer]="format12Hour"
			[format]="'MMM Do, YYYY'"
			(pickerDidClose)="filterEndDate = $event"
		>
		</app-date-time-picker>
	</div>

	<div class="filter-note">Leave <b>End Date</b> blank to view a specific date.</div>

	<div style="margin-bottom: 10px">
		<button class="btn btn-sm btn-block btn-outline-success filter-btn" (click)="applyFilterBtnClicked()">Apply Filter</button>
		<button *ngIf="endMom" class="btn btn-sm btn-block btn-outline-secondary" style="margin-top: 8px" (click)="resetFilterClicked()">
			Reset Filter
		</button>
	</div>
</p-dialog>
