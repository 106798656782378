import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnDestroy, ViewChild } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'

import { CoreService } from '@app/services'
import {
	ScheduleEntry,
	ScheduleOptions,
	DialogControlEvent,
	SelectorPermissionName,
	RRuleCalculator,
	RRuleCalculatorConfig,
	ScheduleAssignmentManager,
	ColorVendor,
	EmployeeRecord,
	DialogManager,
	OverrideProtectionDialogManager,
	NewScheduleType,
	GenericEmailListManager,
	HelpDialogMessage,
} from '@app/models'

import { SelectItem } from 'primeng/api'
import { RRule, RRuleSet, rrulestr, Frequency } from 'rrule'
import { log, Helper, DateTimeHelper, FormHelper } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'
import { Subscription } from 'rxjs'
import { environment } from '@env/environment'
import { FormTagsComponent } from '@app/components/form-tags/form-tags.component'

import { DeviceDetectorService } from 'ngx-device-detector/public-api'
import { DateSelectArg } from '@fullcalendar/core'

import _ from 'lodash'
import moment from 'moment-timezone'

enum ScheduleType {
	anyEmployee,
	multiEmployee,
	individualEmployee,
}

class RuleParameters {
	freq = RRule.WEEKLY
	tzid = ''
	interval = 1
	bymonth = []
	byweekday = []
	bysetpos = []
	wkst = null
	dtstart = null
	until = null
	count = null
}

@Component({
    selector: 'app-scheduler-edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.scss'],
    standalone: false
})
export class SchedulerEditComponent implements OnInit, AfterViewInit, OnDestroy {
	ScheduleType = ScheduleType

	pendingStatus = { canUse: false, isPending: false, isApproved: false }
	overrideProtection = new OverrideProtectionDialogManager()

	useScheduleSnapshot = true
	rRuleCalculator = new RRuleCalculator()

	environment = environment

	isUpdating = false

	accessHelper: AccessHelper

	assignmentManager = new ScheduleAssignmentManager()
	approveWithWorkorderEmailListManager: GenericEmailListManager = null

	selectedScheduleType
	scheduleTypeDropdown: SelectItem[]
	multiEmployeeDropdown: SelectItem[]
	employeesDropdown: SelectItem[]
	jobsDropdown: SelectItem[]

	duplicateJobFound = false
	overlapFound = false
	overlappingJobs = []

	english = 'Scheduled'

	rule: RRule
	ruleSet: RRuleSet
	rulesForm: UntypedFormGroup
	selectedRule
	input = ''

	// dates: Array<any>
	currentScheduleCount = 0
	childEntryCount = 0

	ruleConst = ScheduleOptions.ruleConst
	// ruleOptions = ScheduleOptions.ruleOptions
	freqOptions = ScheduleOptions.freqOptions
	monthOptions = ScheduleOptions.monthOptions
	dayOptions = ScheduleOptions.dayOptions
	positionOptions = ScheduleOptions.positionOptions
	// tzOptions = ScheduleOptions.tzOptions

	currentRuleOptions = {
		freq: true,
		interval: true,
		bymonth: true,
		wkst: true,
		byweekday: true,
		bymonthday: true,
		tzid: true,
		dtstart: true,
		bysetpos: true,
		until: true,
		count: true,
	}

	monthType: 'onDay' | 'bySetPos' = 'bySetPos'
	endSelector = 'never'

	RuleConst = ScheduleOptions.ruleConst

	startDateTip = ''

	newSchedChangeEmailInput: string
	schedChangeEmails: Array<string> = []
	showAdvancedOptions = false
	showSchedChangeEmail = false
	is12HourFormat = true
	isAnytimeJob = false
	showAnytimeCheckbox = false
	isTrackingOverage = false
	arePendingSchedulesEnabled = false

	placeholder = {
		break_time: '',
		break_time_worked: '',
		comments: 'optional extended shift notes',
		description: '',
		overage_time: '',
		defaults: {
			break_time: '',
			break_time_worked: '',
			comments: 'optional extended shift notes',
			description: '',
			overage_time: '',
		},
	}

	tagOptionsList = this.coreSrvc.dbSrvc.schedulerSrvc.getTagLabels()

	@Input() dialogManager: DialogManager

	@Input() isCopy = false
	@Input() newSchedType: NewScheduleType = 'SINGLE'
	@Input() scheduleEntry: ScheduleEntry
	@Input() defaultEmpId: number = null
	@Input() defaultJobId: number = null // Will be set on shift view if job exists
	@Input() source: 'SERIES' | 'SHIFT' = null

	@Output() actionComplete = new EventEmitter<boolean>()

	@ViewChild('tagComp') tagComp: FormTagsComponent

	private subs = new Subscription()

	constructor(
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
	) {
		this.setupAccessPermissions()
		this.arrangeDayOptionsForWkst()

		// Setup user prefs
		const userPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		this.is12HourFormat = userPrefs.globalFormatTime12Hours
		this.arePendingSchedulesEnabled = userPrefs.schedulerEnablePendingQueue

		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		this.isTrackingOverage = company.overage

		// Process dialog control events because it's the shitty dialog implementation
		this.subs.add(this.coreSrvc.eventSrvc.dialogControlEvents.subscribe((event) => this.processControlEvents(event)))

		// Handle overtime exceptions
		this.subs.add(this.coreSrvc.dbSrvc.lambdaSrvc.dataAccessErrorEvent.subscribe((event) => this.handleDataAccessErrorEvent(event)))

		// Manage 'Schedule anytime of day' checkbox
		const userPrefShowCheckbox = userPrefs.schedulerEnableAnytimeCheckbox
		const hasExistingAnytimeSchedules =
			this.coreSrvc.dbSrvc.schedulerSrvc.getSchedules().filter((sched) => sched.start_time === sched.end_time).length > 0
		this.showAnytimeCheckbox = userPrefShowCheckbox || hasExistingAnytimeSchedules
	}

	get isOneTimeShift(): boolean {
		return this.isNew && this.source === 'SHIFT'
	}

	get devDetect(): DeviceDetectorService {
		return this.coreSrvc.devDetect
	}

	get currentRuleString(): string {
		if (this.rule) {
			const ruleStr = this.rule.toString()
			// return ruleStr
			if (ruleStr) {
				const comps = ruleStr.split('RRULE:')
				return comps[1] || ''
			}
		}
		return ''
	}

	get currentTextString(): string {
		if (this.rule) {
			const ruleStr = this.rule.toString()
			const textStr = rrulestr(ruleStr).toText()
			// const textStr = this.rule.toText()
			if (textStr) {
				return 'Scheduled ' + textStr
			}
		}
		return 'Error parsing rule'
	}

	get showHolidayOption(): boolean {
		const empId = this.rulesForm.get('employee_id').value
		const isNotAnyEmployee = empId !== 0
		const isCompanySettingEnabled = this.coreSrvc.dbSrvc.settingSrvc.getCompany().paid_holiday
		const isOriginalRecordAHolidy = this.scheduleEntry?.holiday
		return (isCompanySettingEnabled || isOriginalRecordAHolidy) && isNotAnyEmployee
		// if (empId !== 0) { return true }
		// return false
	}

	get dateRangeError(): boolean {
		const start = this.rulesForm.get('startDate').value
		const end = this.rulesForm.get('endDate').value
		if (start && end) {
			return moment(start).isAfter(moment(end))
		}
		return false
	}

	get scheduleEndDateMax(): Date {
		return moment().add(1, 'year').toDate()
	}

	get isNew(): boolean {
		return !this.scheduleEntry?.id
	}

	get selectedMultiEmployees(): Array<{ label: string; data: EmployeeRecord }> {
		const empIds = this.rulesForm.get('employee_ids').value as Array<number>
		if (empIds && empIds.length > 0) {
			const emps = empIds
				.map((id) => this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(id))
				.filter((emp) => !!emp)
				.map((emp) => ({ label: `${emp.active ? '' : '[INACTIVE] '}${emp.name}`, data: emp }))
			return _.sortBy(emps, 'label')
		}
		return []
	}

	get isScheduledOneTime(): boolean {
		return this.rulesForm.get('schedule_one_time').value || false
	}

	get currentFreqInterval(): string {
		// Get frequency and interval to determine the merged dropdown state
		const formFreq = this.rulesForm.get('freq').value
		const freq: Frequency = formFreq?.value
		const interval = this.rulesForm.get('interval').value

		if (freq === RRule.WEEKLY) {
			if (interval === 1) return '1'
			if (interval === 2) return '2'
			if (interval === 3) return '3'
			if (interval === 4) return '4'
		}

		if (freq === RRule.MONTHLY) return 'A'
		if (freq === RRule.YEARLY) return 'B'

		return null
	}

	public ngOnInit() {
		if (this.scheduleEntry) {
			this.scheduleEntry = this.coreSrvc.dbSrvc.schedulerSrvc.getScheduleForId(this.scheduleEntry.id)

			this.input = this.scheduleEntry.recurrence
			this.ruleSet = rrulestr(this.input, { forceset: true }) as RRuleSet
			this.rule = this.ruleSet.rrules()[0]

			if (this.rule.origOptions.bymonthday) {
				this.monthType = 'onDay'
				this.rule.options.bysetpos = []
			}

			// Check for child entries
			const entries = this.coreSrvc.dbSrvc.schedulerSrvc.getChildEntriesForParentId(this.scheduleEntry.id)
			this.childEntryCount = entries.length
		} else {
			this.input = 'FREQ=WEEKLY;WKST=' + this.getWkstDayCodeFromCompany()
			this.rule = rrulestr(this.input)
			this.rule.options.byweekday = [0, 1, 2, 3, 4]
			this.currentRuleOptions.bysetpos = false
		}

		// Check to see if schedule change email should be available
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		this.showSchedChangeEmail = company.schedule_change_notifications

		// Finish setting up component after first setting up the form
		this.setupForm()
		this.setupPendingStatus()
		this.setupAdvancedOptions()
		this.setupAssignmentManager()
		this.setupWorkorderEmailManager()

		this.adjustFormValuesForInput()
		this.setupJobOverrides()
		this.setupStartEndDates() // REQUIRED - Call after setupForm - Will override start date for existing records
		// this.updateRRuleCalculator()

		this.setupEmailLists()
		this.setupScheduleTypeDropdown()
		this.setupMultEmployeeDropdown()
		this.setupEmployeeDropdown()
		this.setupJobsDropdown()
		this.addSourceCustomizations() // Leave as last thing before updating rule
		this.updateRule()
	}

	public ngAfterViewInit() {
		this.setupDialogState()
		this.updateRule()
	}

	public ngOnDestroy() {
		this.subs.unsubscribe()
	}

	private setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'schedule')
	}

	private setupDialogState() {
		setTimeout(() => {
			this.dialogManager.submitBtnLabel = 'Save'
			this.dialogManager.cancelBtnLabel = 'Cancel'
			this.dialogManager.canSubmit = () => this.isFormValid()
			this.dialogManager.submitBtnAction = () => this.submitBtnClicked()
			this.dialogManager.cancelBtnAction = () => (this.dialogManager.isDialogVisible = false)
		}, 100)
	}

	private setupAdvancedOptions() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const hasSchedDetails = this.rulesForm.get('description').value
		const hasSchedNotes = this.rulesForm.get('comments').value
		const hasNotificationEmails = this.rulesForm.get('notification_email').value
		const showEmailForNotifications = company.schedule_change_notifications
		const myUserPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		const showAdvancedOptions = myUserPrefs.globalExpandAdvancedOptions
		this.showAdvancedOptions = showAdvancedOptions // !!hasSchedDetails || !!hasSchedNotes || !!hasNotificationEmails || showEmailForNotifications
	}

	private submitBtnClicked() {
		// Check for tag being edited
		this.tagComp?.addTag()
		if (this.tagComp?.selectedTag) {
			this.coreSrvc.notifySrvc.notify(
				'info',
				'Action Required',
				'You are curretnly modifying a tag. You must cancel or confirm your changes before you can save this record.',
			)
			return
		}
		if (this.isNew || this.isCopy) {
			this.saveSchedule('insert')
		} else {
			this.saveSchedule('update')
		}
	}

	public processControlEvents(event: DialogControlEvent) {
		const callerId = event.callerId
		const direction = event.direction
		if (callerId === 'schedule_recur' && direction === 'toContent') {
			const eventName = event.eventName
			if (eventName === 'saveBtnClicked') {
				if (this.isNew) {
					this.saveSchedule('insert')
				} else {
					this.saveSchedule('update')
				}
			}
		}
	}

	private handleDataAccessErrorEvent(event) {
		// log('Schedule got overlap exception', event)
		const type = event?.errorType
		if (type === 'com.sst.db.tts.exceptions.OvertimeDetectedException') {
			const errorMsg = event?.errorMessage
			log('Error Message', errorMsg)
			this.coreSrvc.notifySrvc.notify('error', 'Overtime Exceeded', errorMsg)
			this.overrideProtection.type = 'OVERTIME'
			this.overrideProtection.showDialog = true
		}
		if (type === 'com.sst.db.tts.exceptions.VacationDetectedException') {
			const errorMsg = event?.errorMessage
			log('Error Message', errorMsg)
			this.coreSrvc.notifySrvc.notify('error', 'Time-Off Conflict', errorMsg)
			this.overrideProtection.type = 'TIMEOFF'
			this.overrideProtection.showDialog = true
		}
	}

	public submitOverrideClicked() {
		if (this.overrideProtection.type === 'OVERTIME') {
			this.overrideProtection.allowOvertimeOverride = true
		}
		if (this.overrideProtection.type === 'TIMEOFF') {
			this.overrideProtection.allowTimeoffOverride = true
		}
		this.submitBtnClicked()
	}

	// Setup Dropdowns

	private setupMultEmployeeDropdown() {
		const permissions = this.accessHelper.getPermissionsFor('schedule')
		const isRestricted = permissions.isSelectorRestrictedFor(SelectorPermissionName.employee)
		const isManager = this.coreSrvc.dbSrvc.settingSrvc.isUserAManager()

		// Add any employees that you might not own that were set by unrestricted user
		const addEmpIds = this.rulesForm.get('employee_ids').value ?? []

		const dropdownData = this.coreSrvc.dbSrvc.empSrvc.getEmployeeDropdown(this.coreSrvc.dbSrvc, isRestricted, isManager, addEmpIds)

		const newDropdown = dropdownData.filter((item) => item.data.active === true)
		const editDropdown = dropdownData.filter((item) => item.data.active === true || addEmpIds.includes(item.value))

		const dropdown = this.isNew ? newDropdown : editDropdown
		this.multiEmployeeDropdown = dropdown
	}

	private setupEmployeeDropdown() {
		const permissions = this.accessHelper.getPermissionsFor('schedule')
		const isRestricted = permissions.isSelectorRestrictedFor(SelectorPermissionName.employee)
		const isManager = this.coreSrvc.dbSrvc.settingSrvc.isUserAManager()
		const selectedEmpId = this.rulesForm.get('employee_id').value

		// Add any employees that you might not own that were set by unrestricted user
		const addEmpIds = this.rulesForm.get('employee_ids').value || []

		const dropdownData = this.coreSrvc.dbSrvc.empSrvc.getEmployeeDropdown(this.coreSrvc.dbSrvc, isRestricted, isManager, addEmpIds)

		const newDropdown = dropdownData.filter((item) => item.data.active === true)
		const editDropdown = dropdownData.filter((item) => item.data.active === true || item.value === selectedEmpId)

		const dropdown = this.isNew ? newDropdown : editDropdown
		dropdown.unshift({ label: 'Any Employee', value: 0, data: null })
		dropdown.unshift({ label: 'Select an Employee', value: null, data: null })

		this.employeesDropdown = dropdown
	}

	private setupJobsDropdown() {
		// const isSupRestricted = this.isNew ? (!this.permissions.create && this.owner.create) : (!this.permissions.update && this.owner.update)
		const permissions = this.accessHelper.getPermissionsFor('schedule')
		const isRestricted = permissions.isSelectorRestrictedFor(SelectorPermissionName.job)
		const isManager = this.coreSrvc.dbSrvc.settingSrvc.isUserAManager()
		const selectedJobId = this.rulesForm.get('job_id').value
		const addJobIds = selectedJobId ? [selectedJobId] : []
		log('addJobs', addJobIds)

		const dropdownData = this.coreSrvc.dbSrvc.jobSrvc.getJobDropdown(this.coreSrvc.dbSrvc, false, isRestricted, isManager, addJobIds)

		const dropdown = this.isNew
			? dropdownData.filter((item) => item.data.active === true)
			: dropdownData.filter((item) => item.data.active === true || item.value === selectedJobId)
		dropdown.unshift({ label: 'Select a Job', value: null, data: null })
		this.jobsDropdown = dropdown

		// if (isRestricted) {
		// 	const myUserId = this.coreSrvc.dbSrvc.settingSrvc.getMyUserId()
		// 	const jobSiteIds = this.accessHelper.getAccessibleIdsFor('site') // this.coreSrvc.dbSrvc.siteSrvc.getJobSiteIdsForSupId(myUserId)

		// 	// Add current job to dropdown in case it's for another site
		// 	if (!this.isNew) {
		// 		const currentJobId = this.scheduleEntry.job_id
		// 		if (currentJobId && !jobSiteIds.includes(currentJobId)) {
		// 			const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(currentJobId)
		// 			if (job) {
		// 				jobSiteIds.push(job.location_id)
		// 			}
		// 		}
		// 	}

		// 	const filteredDropdown = this.coreSrvc.dbSrvc.jobSrvc.getJobsForJobSiteIds(jobSiteIds).map(j => ({ label: j.description, value: j.id }))
		// 	filteredDropdown.unshift({ label: 'Select a Job', value: null })
		// 	this.jobsDropdown = filteredDropdown
		// 	return
		// }
		// this.jobsDropdown = this.coreSrvc.dbSrvc.jobSrvc.getDropdownData(true)
	}

	private setupScheduleTypeDropdown() {
		const options = [
			{ label: 'Individual Employee', value: 2 },
			{ label: 'Multiple Employees', value: 1 },
			{ label: 'Any Employee(s)', value: 0 },
		]
		this.scheduleTypeDropdown = options
	}

	// Form Validation Checks and Constraints

	public isFormValid(): boolean {
		let isValid = true
		if (this.rulesForm.value.employee_id === null && this.selectedScheduleType === ScheduleType.individualEmployee) {
			// log('no emp id')
			isValid = false
		}
		if (!this.rulesForm.valid) {
			// log('rulesForm.valid is false')
			isValid = false
		}
		if (this.dateRangeError) {
			// log('dateRangeError found')
			isValid = false
		}
		if (this.hideTable()) {
			// log('hideTable')
			isValid = false
		}
		if (!this.isMultiEmployeeChoiceValid()) {
			// log('isMultEmployeeChoiceValid')
			isValid = false
		}
		if (this.rulesForm.value.freq.value === 0 && !this.hasMonthsSelected()) {
			// log('Month selected for monthly', false)
			isValid = false
		}
		if (!this.isOnDaySelectionValid()) {
			// log('daySelection')
			isValid = false
		}
		const saveBtnState = isValid ? 'enableSaveBtn' : 'disableSaveBtn'
		const event = new DialogControlEvent('employeeEdit', 'toDialog', saveBtnState, null)
		this.coreSrvc.eventSrvc.dialogNotification(event)
		return isValid
	}

	// BEGIN - Form Troubleshooting methods
	public checkValid() {
		const result = this.isFormValid()
		log('Valid Result', result)
	}

	public getFormValidationErrors() {
		FormHelper.getFormValidationErrors(this.rulesForm)
	}
	// END - Form Troubleshooting methods

	public isOnDaySelectionValid(): boolean {
		if (this.rulesForm.value.freq.value === 0 || this.rulesForm.value.freq.value === 1) {
			const byMonthDay = this.rulesForm.value.bymonthday
			const byMonthDayEmpty = !byMonthDay
			const byMonthDayArrayEmpty = _.isArray(byMonthDay) && _.isEmpty(byMonthDay)
			if (this.monthType === 'onDay' && (byMonthDayEmpty || byMonthDayArrayEmpty)) {
				return false
			}
		}
		return true
	}

	// Check Multi-Employee schedule for Weekly
	public isMultiEmployeeChoiceValid() {
		if (this.selectedScheduleType === 1) {
			if (this.rulesForm.value.freq.value === 2) {
				return this.assignmentManager.areAssignmentsValid()
			} else {
				const multiEmpCount = (this.rulesForm.get('employee_ids').value as Array<number>).length
				return multiEmpCount > 0
			}
		}
		return true
	}

	public constrainByMonthDay(updateRule: boolean) {
		const freq = this.rulesForm.get('freq').value
		const isYearly = freq.value === 0
		const bymonth = this.rulesForm.get('bymonth').value
		const input = this.rulesForm.get('bymonthday').value
		const month = _.isArray(bymonth) ? bymonth[0] : bymonth // Getting element from array
		const maxDays = isYearly ? this.daysInMonth(month) : 31
		let intValue = parseInt(input, 10)
		if (intValue < 1) {
			intValue = 1
		}
		if (intValue > maxDays) {
			intValue = maxDays
		}
		if (!intValue) {
			intValue = null
		}
		this.rulesForm.get('bymonthday').setValue(intValue)
		if (updateRule) {
			this.updateRule()
		}
	}

	private daysInMonth(month) {
		// m is 0 indexed: 0-11
		switch (month) {
			case 2:
				return 29
			case 4:
			case 6:
			case 9:
			case 11:
				return 30
			default:
				return 31
		}
	}

	// Form Actions

	doProcessScheduleRepeatType() {
		const isOneTime = this.rulesForm.get('schedule_one_time').value
		log('Is One Time', isOneTime)
		if (isOneTime) {
			const freq = ScheduleOptions.freqOptions.find((opt) => opt.value === 1)
			this.rulesForm.get('freq').setValue(freq)
			this.selectFrequency()
			this.toggleMonthlyType('bySetPos')
			this.rulesForm.get('interval').setValue(1)
			this.endSelector = 'count'
			this.rulesForm.get('count').setValue(1)
			this.rulesForm.get('endDate').setValue(null)
			this.updateRule()
		} else {
			const freq = ScheduleOptions.freqOptions.find((opt) => opt.value === 2)
			this.rulesForm.get('freq').setValue(freq)
			this.selectFrequency()
			this.setDefaultWeekdaysSelected()
			this.rulesForm.get('interval').setValue(1)
			this.rulesForm.get('count').setValue(null)
			this.clearDate('endDate')
			this.endSelector = 'never'
			this.setEndSelector()
			this.updateRule()
		}
	}

	setDefaultWeekdaysSelected() {
		// this.rule.options.byweekday = [0, 1, 2, 3, 4]
		this.rulesForm.get('byweekday').setValue([0, 1, 2, 3, 4])
	}

	// doSetOneTimeSchedule() {
	// 	this.showAdvancedOptions = true
	// }

	doChangeScheduleTypeDropdown() {
		const type = this.selectedScheduleType
		// log('Current Type', type)
		switch (type) {
			case ScheduleType.individualEmployee:
				this.rulesForm.get('employee_id').setValue(null)
				break
			case ScheduleType.multiEmployee:
				this.rulesForm.get('employee_id').setValue(ScheduleType.multiEmployee)
				break
			case ScheduleType.anyEmployee:
				this.rulesForm.get('employee_id').setValue(ScheduleType.anyEmployee)
				this.rulesForm.get('holiday').setValue(false)
				break
		}
	}

	doChangeSingleEmployeDropdown() {
		const empId = this.rulesForm.get('employee_id').value
		if (empId === 0) {
			this.selectedScheduleType = ScheduleType.anyEmployee
			this.rulesForm.get('holiday').setValue(false)
		} else {
			this.selectedScheduleType = ScheduleType.individualEmployee
			this.rulesForm.get('employee_count').setValue(1)
		}
	}

	updatePlaceholder(field: string, action: string) {
		if (action === 'focus') {
			this.placeholder[field] = ''
		} else {
			this.placeholder[field] = this.placeholder.defaults[field]
		}
	}

	setStartEndPickerTime(prop: string, date: Date) {
		this.rulesForm.get(prop).setValue(date)
	}

	clearDateTime(field: string) {
		this.rulesForm.get(field).setValue(null)
		return false
	}

	getWkstDayCodeFromCompany(): string {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const wkst = company.wkst
		if (!wkst || wkst < 0 || wkst > 6) {
			return 'MO'
		}
		switch (wkst) {
			case 0:
				return 'MO'
				break
			case 1:
				return 'TU'
				break
			case 2:
				return 'WE'
				break
			case 3:
				return 'TH'
				break
			case 4:
				return 'FR'
				break
			case 5:
				return 'SA'
				break
			case 6:
				return 'SU'
				break
		}
	}

	arrangeDayOptionsForWkst() {
		const dayOptions = [...ScheduleOptions.dayOptions]
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const wkst = company.wkst || 0
		if (!wkst || wkst < 0 || wkst > 6) {
			this.dayOptions = dayOptions
			return
		}
		const comps = dayOptions.splice(wkst)
		const newDayOptions = [...comps, ...dayOptions]
		this.dayOptions = newDayOptions
	}

	// setupShowState() {
	// 	const interval = this.rule.options.interval
	// 	// { value: 0, name: 'Yearly', interval: 'year', rRuleFreq: RRule.YEARLY },
	// 	const freq = this.freqOptions.find(elm => elm.value === interval).interval
	// 	this.setCurrentRuleOptions(freq)
	// }

	private getStartEndTimeDefaults(range: DateSelectArg): { start: string; end: string; startDate: Date } {
		if (this.isNew && range) {
			const startDate = moment(range.start).toDate()
			if (range.allDay) {
				this.isAnytimeJob = true
				return { start: '09:00:00', end: '09:00:00', startDate: startDate }
			} else {
				const start = moment(range.start).format('HH:mm:[00]')
				const end = moment(range.end).format('HH:mm:[00]')
				return { start: start, end: end, startDate: startDate }
			}
		} else {
			// If we're in day view, default start date to this date, otherwise default to today
			const dayViewDate = this.coreSrvc.dbSrvc.schedulerSrvc.currentDayViewDate
			const alternateStartDate = dayViewDate ? dayViewDate : moment().toDate()
			return {
				start: this.scheduleEntry?.start_time ?? '09:00:00',
				end: this.scheduleEntry?.end_time ?? '17:00:00',
				startDate: alternateStartDate,
			}
		}
	}

	setupForm() {
		// Setup defaults from drage to select in shift view
		const newShiftInfo = this.coreSrvc.dbSrvc.schedulerSrvc.newShiftRangeSelectInfo
		const range = newShiftInfo?.range
		this.coreSrvc.dbSrvc.schedulerSrvc.newShiftRangeSelectInfo = null

		// Setup start date and times based on how the edit component is invoked
		// These values will be overridden in setupStartEndDates for existing records
		const startEndDefaults = this.getStartEndTimeDefaults(range)
		const startTime = startEndDefaults.start
		const endTime = startEndDefaults.end
		const startDate = startEndDefaults.startDate

		if (newShiftInfo?.empId) this.defaultEmpId = newShiftInfo.empId
		if (newShiftInfo?.jobId) this.defaultJobId = newShiftInfo.jobId

		if (newShiftInfo?.empId === 0) {
			log('Got Here')
			this.defaultEmpId = 0
			this.selectedScheduleType = ScheduleType.anyEmployee
		}

		// Check anytime status
		if (startTime === endTime) {
			this.isAnytimeJob = true
		} else {
			this.isAnytimeJob = false
		}

		// Setup default colors
		const assignedColor = this.isCopy
			? ColorVendor.getColorById(null)
			: this.scheduleEntry?.assigned_color
				? this.scheduleEntry.assigned_color
				: ColorVendor.getColorById(this.scheduleEntry?.id)

		this.english = this.rule.toText()

		this.rulesForm = this.fb.group({
			tags_json: [this.isNew ? null : this.scheduleEntry.tags_json],
			approval_state: [this.isNew ? 'DIRECT' : this.scheduleEntry.approval_state],
			assigned_color: [this.isNew ? ColorVendor.getRandomColor() : assignedColor],
			enabled: [this.isNew ? true : this.scheduleEntry.enabled],
			employee_id: [this.isNew ? this.defaultEmpId : this.scheduleEntry.employee_id],
			employee_ids: [this.isNew ? [] : this.scheduleEntry.employee_ids],
			approve_with_workorder: [this.isNew ? false : this.scheduleEntry.approve_with_workorder],
			workorder_email: [this.isNew ? null : this.scheduleEntry.workorder_email],
			job_id: [this.isNew ? this.defaultJobId : this.scheduleEntry.job_id, Validators.required],
			notification_email: [this.isNew ? null : this.scheduleEntry.notification_email],
			freq: [this.getFreq(), Validators.required],
			interval: [this.getInterval()],
			tzid: [this.getsiteTzZoneName()], // tzid in RRule refers to the actual timezone zone name, not TTS zone_id
			bymonth: [this.getByMonth()],
			bymonthday: [this.getByMonthDay()],
			wkst: [this.getWkst()],
			byweekday: [this.getByWeekday()],
			dtstart: [this.getDtStart()],
			bysetpos: [this.getBySetPos()],
			until: [this.getUntil()],
			count: [this.getCount()],
			description: [this.isNew ? null : this.scheduleEntry.description],
			start_time: [startTime ? this.makeTime(startTime) : null],
			end_time: [endTime ? this.makeTime(endTime) : null],
			comments: [this.isNew ? null : this.scheduleEntry.comments],
			employee_count: [this.isNew ? 1 : this.scheduleEntry.employee_count],
			break_time: [this.isNew ? null : this.makeBreakTime(this.scheduleEntry.break_time)],
			break_time_worked: [this.isNew ? null : this.makeBreakTimeWorked(this.scheduleEntry.break_time_worked)],
			overage_time: [this.isNew ? null : this.makeOverage(this.scheduleEntry.overage_time)],
			holiday: [this.isNew ? false : this.scheduleEntry.holiday],
			startDate: [startDate, Validators.required],
			schedule_one_time: [this.isNew ? false : this.scheduleEntry.schedule_one_time],
			endDate: [null],
		})

		// If we are creating a new record, configure for multi if necessary
		if (this.newSchedType === 'MULTI') {
			this.rulesForm.get('employee_id').setValue(1)
		}

		const employeeId = this.rulesForm.get('employee_id').value
		switch (employeeId) {
			case 0:
				this.selectedScheduleType = 0 // ID = 0 - Any Employee
				break
			case 1:
				this.selectedScheduleType = 1 // ID = 1 - Multi-Employee
				break
			default:
				this.selectedScheduleType = 2 // ID > 1 - Individual Employee
		}
	}

	addSourceCustomizations() {
		// Setup one time requirements if opend from shift view
		if (this.isOneTimeShift) {
			this.rulesForm.get('schedule_one_time').setValue(true)
			this.doProcessScheduleRepeatType()
			this.scheduleTypeDropdown = this.scheduleTypeDropdown.filter((dd) => dd.value !== 1)
		}
	}

	setupPendingStatus() {
		const myAdminPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		const pendingQueueEnabled = myAdminPrefs.schedulerEnablePendingQueue
		const isViewingPending = this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.series.subSection === 'PENDING'
		const thisRecordIsPending = this.rulesForm.get('approval_state').value === 'PENDING'

		if (((this.isNew || this.isCopy) && pendingQueueEnabled) || isViewingPending || thisRecordIsPending) {
			this.pendingStatus.canUse = true
			if (this.isNew && isViewingPending) {
				this.pendingStatus.isPending = true
			}
		}
	}

	setupAssignmentManager() {
		const assignments = this.scheduleEntry?.getShiftAssignments() || []
		this.assignmentManager.setupManager(assignments)
		this.assignmentManager.currentScheduleType = () => this.selectedScheduleType
		this.assignmentManager.currentRRule = () => this.rule
		this.assignmentManager.currentSelectedRRuleDays = () => this.rulesForm?.value.byweekday || []
	}

	setupWorkorderEmailManager() {
		this.approveWithWorkorderEmailListManager = new GenericEmailListManager(this.coreSrvc)
		const workorderEmails = this.rulesForm.get('workorder_email').value
		if (workorderEmails) {
			this.approveWithWorkorderEmailListManager.initWithString(workorderEmails)
		}
	}

	setupStartEndDates() {
		// setupForm manages seting up the start date depending on where the edit form is called from
		// however this method will correctly setup the start date picker based on what was in the
		// original record and override any values that may have been chosen during new form setup

		// DO NOT REFACTOR without tracing start date setup in setupForm method

		// If this is a new schedule then exit and use values from setupForm
		const sched = this.scheduleEntry
		if (!sched) return

		// If this is an edit, use the values from the existing record
		const start = this.scheduleEntry.start_date
		const startMom = moment(start)
		if (startMom.isValid()) {
			const startDate = startMom.toDate()
			this.rulesForm.get('startDate').setValue(startDate)
		}

		const end = this.scheduleEntry.recurrence_end
		const endMom = moment(end)
		if (endMom.isValid()) {
			const endDate = endMom.toDate()
			this.rulesForm.get('endDate').setValue(endDate)
		}
	}

	updateStartEndDates() {
		const start = this.rulesForm.get('startDate').value

		// If it's a one time schedule, clear the end date which gets updated by back end to avoid invalid date range error
		if (this.isScheduledOneTime) {
			this.rulesForm.get('endDate').setValue(null)
		}

		log('Start Date', start)
		if (start) {
			const utcStart = DateTimeHelper.makeUtcDayViewDates(start).start
			this.rulesForm.get('dtstart').setValue(utcStart)
		} else {
			this.rulesForm.get('dtstart').setValue(null)
		}
		const end = this.rulesForm.get('endDate').value
		log('End Date', end)
		if (end) {
			const utcEnd = DateTimeHelper.makeUtcDayViewDates(end).end
			this.rulesForm.get('until').setValue(utcEnd)
		} else {
			this.rulesForm.get('until').setValue(null)
		}
		this.updateRule()
	}

	updateRRuleCalculator() {
		const rRuleString = this.rule.toString()
		const timezone = this.rulesForm.get('tzid').value
		const startDate = this.rulesForm.get('startDate').value
		const startDateString = startDate ? moment(startDate).format('YYYY-MM-DD') : null
		const endDate = this.rulesForm.get('endDate').value
		const endDateString = endDate ? moment(endDate).format('YYYY-MM-DD') : null
		const startTime = this.rulesForm.get('start_time').value
		const startTimeString = startTime ? moment(startTime).format('HH:mm:ss') : null
		const endTime = this.rulesForm.get('end_time').value
		const endTimeString = endTime ? moment(endTime).format('HH:mm:ss') : null
		const adjustedRuleString = this.getAdjustedRuleString(true)

		const ruleConfig: RRuleCalculatorConfig = {
			rRuleString: adjustedRuleString,
			timezone: timezone,
			startTime: startTimeString,
			endTime: endTimeString,
			startDate: startDateString,
			endDate: endDateString,
		}

		// log('Calling rRuleCalculator.updateRuleConfig', ruleConfig)
		this.rRuleCalculator.updateRuleConfig(ruleConfig)
	}

	makeBreakTime(breakTime: string) {
		if (!breakTime) {
			return null
		}
		return moment.duration(breakTime).asMinutes()
	}

	makeBreakTimeWorked(dur: string) {
		if (!dur) {
			return null
		}
		const duration = moment.duration(dur)
		return DateTimeHelper.formatDuration('H:mm', duration)
	}

	makeOverage(jobDuration: string): string {
		if (!jobDuration) {
			return null
		}
		const duration = moment.duration(jobDuration)
		return DateTimeHelper.formatDurationInHoursAndMinutes('H:mm', duration)
	}

	makeTime(time: string): Date {
		if (time) {
			const dateStr = '2019-01-01T' + time
			const mom = moment(dateStr)
			if (mom.isValid()) {
				return mom.toDate()
			}
		}
		return null
	}

	validateNumericInput(input) {
		const value = this.rulesForm.get(input).value
		const intValue = parseInt(value, 10)
		if (intValue) {
			this.rulesForm.get(input).setValue(intValue)
		} else {
			this.rulesForm.get(input).setValue(null)
		}
	}

	setupEmailLists() {
		const emailString = this.rulesForm.get('notification_email').value
		if (emailString) {
			this.schedChangeEmails = emailString.split(',')
		}
	}

	// Called after setting up the form or changing the Frequency type
	adjustFormValuesForInput() {
		// log('Adjust Form Values For Input')
		// log('Form Object', this.rulesForm)
		this.endSelector = this.rule.options.count ? 'count' : this.rule.options.until ? 'until' : 'never'
		// log(this.rule)
		const byweekday = this.rulesForm.get('byweekday').value
		// log('BYWEEKDAY', byweekday)
		// log('INPUT', this.input)
		// FREQ=MONTHLY;BYDAY=TU;WKST=MO;BYSETPOS=2
		const input = this.input
		// log('AFI - input', input)
		if ((input.includes('FREQ=MONTHLY') || input.includes('FREQ=YEARLY')) && this.input.includes('BYMONTHDAY')) {
			// log('Adjust Monthly/Yearly - onDay')
			this.monthType = 'onDay'
			this.rulesForm.get('byweekday').setValue([])
			this.rulesForm.get('bysetpos').setValue([])
			return
		}
		if ((input.includes('FREQ=MONTHLY') || input.includes('FREQ=YEARLY')) && this.input.includes('BYDAY')) {
			// log('Adjust Monthly/Yearly - onThe')
			this.monthType = 'bySetPos'
			const ruleByWeekday = this.rule.options.byweekday
			const ruleBySetPos = this.rule.options.bysetpos

			if (ruleByWeekday.length === 7) {
				// If all days selected
				this.rulesForm.get('byweekday').setValue(ScheduleOptions.ruleConst.byWeekdayDefault)
			} else {
				// If one option selected
				const weekday = ruleByWeekday[0]
				if (weekday || weekday === 0) {
					const inputValue = this.getByDayOption(weekday)
					this.rulesForm.get('byweekday').setValue(inputValue)
				}
			}
			if (!ruleBySetPos) {
				this.rulesForm.get('bysetpos').setValue(ScheduleOptions.ruleConst.bySetPosDefault)
			}
			return
		}
		if (input.includes('FREQ=WEEKLY')) {
			// log('Got a weekly schedule')
			this.rulesForm.get('bymonth').setValue([])
			this.rulesForm.get('bymonthday').setValue([])
			this.rulesForm.get('bysetpos').setValue([])
			return
		}
	}

	getByDayOption(value: number) {
		const option = this.dayOptions.find((elm) => elm.value === value)
		if (option) {
			return option.aValue
		}
		return []
	}

	getFreq() {
		const freq = this.rule.options.freq
		// log('Rule Frequency', freq)
		return ScheduleOptions.freqOptions.find((opt) => opt.value === freq)
	}

	getInterval() {
		return this.rule.options.interval || 1
	}
	getByMonth() {
		return this.rule.options.bymonth || []
	}
	getByMonthDay() {
		return this.rule.options.bymonthday
	}
	getWkst() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const defaultWkst = company.wkst ? company.wkst : 0
		// log('Week start', defaultWkst)
		return defaultWkst
	}
	getByWeekday() {
		return this.rule.options.byweekday || []
	}

	getsiteTzZoneName() {
		// If there's a schedule, return the TZ zone name from referenced job site, otherwise return the default timezone zone name
		const sched = this.scheduleEntry
		if (sched) {
			const jobId = sched.job_id
			const site = this.coreSrvc.dbSrvc.jobSrvc.getJobSiteForJobId(jobId)
			const siteTzId = site.timezone_id
			return this.coreSrvc.dbSrvc.settingSrvc.getTimezoneZoneNameForId(siteTzId)
		} else {
			return this.coreSrvc.dbSrvc.settingSrvc.getCompanyDefaultTimezoneZoneName()
		}
	}

	getDtStart(): Date {
		if (!this.isNew) {
			const entryStartDate = this.scheduleEntry.start_date
			const startDate = moment(entryStartDate, 'YYYY-MM-DD').startOf('day').toDate()
			return startDate
		} else {
			return moment().startOf('day').toDate()
		}
	}

	getBySetPos() {
		const position = this.rule.options.bysetpos
		if (position) {
			return position[0]
		} else {
			return 1
		}
	}

	getUntil() {
		if (!this.isNew) {
			const entryEndDate = moment(this.rule.options.until).utc().endOf('day').toDate()
			const endDate = moment(entryEndDate, 'YYYY-MM-DD').utc().endOf('day')
			if (endDate.isValid()) {
				log('Got a valid until date')
				log('End Date JS', endDate.toDate())
				return endDate.toDate()
			} else {
				log('No valid until date')
				return null
			}
		} else {
			return null
		}
	}

	getCount() {
		return this.rule.options.count
	}

	processFormInput(attr: string, field: any) {
		// log('Processing Form', attr, field)
		const form = this.rulesForm.value
		const hasByWeekday = form.freq.value !== RRule.YEARLY

		switch (attr) {
			case 'freq':
				return field.value
			case 'interval':
				return field || 1
			case 'bymonth':
				return field
			case 'bymonthday':
				return this.parseByMonthDay(field)
			case 'wkst':
				return field
			case 'byweekday':
				return _.sortBy(field)
			case 'tzid':
				return field // tzid in RRule refers to the actual timezone zone name, not TTS zone_id
			case 'dtstart':
				return field
			case 'bysetpos':
				return field
			case 'until':
				return field
			case 'count':
				return field
			default:
				return null
		}
	}

	parseByMonthDay(input: string) {
		const value = parseInt(input, 10)
		if (value) {
			return value
		}
		return []
	}

	updateRule() {
		// log('updateRule start')
		const params = new RuleParameters()
		const form = this.rulesForm.value
		const freq = form.freq

		for (const attr in form) {
			if (this.currentRuleOptions[attr]) {
				params[attr] = this.processFormInput(attr, form[attr])
			} else {
				delete params[attr]
			}
		}

		// delete params.tzid

		// If an end date is set, remove the count option
		if (this.endSelector === 'until') {
			delete params.count
		}
		if (this.endSelector === 'count') {
			delete params.until
		}

		// log('Rule Parameters', params)

		const result = new RRule(params)
		this.rule = result
		this.input = result.toString() || ''
		this.english = result.isFullyConvertibleToText() ? result.toText() : ''

		// log('Rule String', this.input)
		// log('Rule Text', this.english)

		const todayStr = moment().format('YYYY-MM-DD')
		// // const start = moment().subtract(1, 'day').startOf('day').toDate()
		// const start = moment(todayStr).utc().startOf('day').toDate()
		const start = moment.utc().subtract(1, 'day').startOf('day').toDate()
		const end = moment().utc().endOf('day').add(30, 'days').toDate()
		const dates = result.between(start, end)

		// const max = 10
		// const dates = this.rule.all((date, i) => {
		// 	if (!this.rule.options.count && (i === max)) {
		// 		return false // That's enough
		// 	}
		// 	return true
		// })
		// log('Date Count', dates.length)
		this.currentScheduleCount = dates.length
		// this.makeRows(dates) - migrated
		// this.checkForOverlap()
		this.updateRRuleCalculator()
		// log('updateRule end')
	}

	selectFrequency() {
		const form = this.rulesForm.value
		const freq = form.freq.interval
		// log('Freq', freq)
		this.setCurrentRuleOptions(freq)
		this.resetForm(freq)
		this.resetRule(freq)
		this.updateRule()
	}

	selectInterval() {
		this.updateRule()
	}

	setFreqInterval(event: any) {
		const currentInterval = this.rulesForm.get('interval').value
		const currentFreqOption = this.rulesForm.get('freq').value
		const currentFreq = currentFreqOption?.value

		log('Current Interval', currentInterval)
		log('CurrentFreqOption', currentFreqOption)
		log('CurrentFreq', currentFreq)

		let interval = 1
		let freq = 2
		const option = event.target.value

		log('Selected Option', option)
		switch (option) {
			case '1': // WEEKLY
				interval = 1
				freq = RRule.WEEKLY // 2
				break
			case '2': // Every Other Week
				interval = 2
				freq = RRule.WEEKLY
				break
			case '3': // Every 3 Weeks
				interval = 3
				freq = RRule.WEEKLY
				break
			case '4': // Every 4 Weeks
				interval = 4
				freq = RRule.WEEKLY
				break
			case 'A': // Monthly
				interval = 1
				freq = RRule.MONTHLY // 1
				break
			case 'B': // Yearly
				interval = 1
				freq = RRule.YEARLY // 0
				break
		}

		const newFreqOption = ScheduleOptions.freqOptions.find((opt) => opt.rRuleFreq === freq)
		this.rulesForm.get('freq').setValue(newFreqOption)
		this.rulesForm.get('interval').setValue(interval)
		if (currentFreq === freq) {
			this.updateRule()
		} else {
			this.selectFrequency()
			this.rulesForm.get('interval').setValue(interval)
			// When switching from non week back to week, set default days selected
			if (currentFreq !== 2 && freq === 2) this.setDefaultWeekdaysSelected()
			this.updateRule()
		}

		log('New Option to set', newFreqOption)

		// YEARLY = 0,
		// MONTHLY = 1,
		// WEEKLY = 2,
	}

	resetForm(freq: string) {
		this.rulesForm.get('interval').setValue(1)
		this.rulesForm.get('bysetpos').setValue([])
		this.rulesForm.get('bymonth').setValue([])
		this.rulesForm.get('bymonthday').setValue([])
		this.rulesForm.get('byweekday').setValue([])
		if (freq === 'year' || freq === 'month') {
			// log('Resetting month view')
			const byWeekday = ScheduleOptions.ruleConst.byWeekdayDefault
			this.rulesForm.get('byweekday').setValue(byWeekday)
			const bySetPos = ScheduleOptions.ruleConst.bySetPosDefault
			this.rulesForm.get('bysetpos').setValue(bySetPos)
		}
		if (freq === 'month') {
			this.rulesForm.get('bymonth').setValue(null)
		}
	}

	resetRule(freq: string) {
		if (freq === 'month') {
			this.rule.options.bymonthday = []
		}
	}

	setCurrentRuleOptions(freq: string) {
		switch (freq) {
			case 'year':
				this.currentRuleOptions = {
					freq: true,
					interval: true,
					bymonth: true,
					wkst: true,
					byweekday: true,
					bymonthday: true,
					tzid: true,
					dtstart: true,
					bysetpos: true,
					until: true,
					count: true,
				}
				break
			case 'month':
				this.currentRuleOptions = {
					freq: true,
					interval: true,
					bymonth: true,
					wkst: true,
					byweekday: true,
					bymonthday: true,
					tzid: true,
					dtstart: true,
					bysetpos: true,
					until: true,
					count: true,
				}
				break
			case 'week':
				this.currentRuleOptions = {
					freq: true,
					interval: true,
					bymonth: false,
					wkst: true,
					byweekday: true,
					bymonthday: false,
					tzid: true,
					dtstart: true,
					bysetpos: false,
					until: true,
					count: true,
				}
				break
			case 'day':
				this.currentRuleOptions = {
					freq: true,
					interval: true,
					bymonth: false,
					wkst: true,
					byweekday: false,
					bymonthday: false,
					tzid: true,
					dtstart: true,
					bysetpos: false,
					until: true,
					count: true,
				}
				break
			default:
				break
		}
	}

	setEndSelector() {
		// log('Setting end selector')
		if (this.endSelector === 'never') {
			this.rulesForm.get('count').setValue(null)
			this.rulesForm.get('until').setValue(null)
			this.rulesForm.get('endDate').setValue(null)
		}
		if (this.endSelector === 'until') {
			this.rulesForm.get('count').setValue(null)
		}
		if (this.endSelector === 'count') {
			this.rulesForm.get('until').setValue(null)
			this.rulesForm.get('endDate').setValue(null)
		}
		this.updateRule()
	}

	toggleFormCheckbox(field: string) {
		const currentSetting = this.rulesForm.get(field).value
		this.rulesForm.get(field).setValue(!currentSetting)
	}

	toggleEnabled() {
		const currentSetting = this.rulesForm.get('enabled').value
		this.rulesForm.get('enabled').setValue(!currentSetting)
	}

	toggleMonth(month) {
		if (this.isMonthSelected(month)) {
			this.rulesForm.get('bymonth').setValue([])
		} else {
			this.rulesForm.get('bymonth').setValue([month.value])
		}
		this.rulesForm.get('bymonthday').setValue(null) // clear the on day input
		this.updateRule()
	}

	hasMonthsSelected(): boolean {
		return !_.isEmpty(this.rulesForm.value.bymonth)
	}

	isMonthSelected(month) {
		const months: Array<number> = this.rulesForm.value.bymonth || []
		return months.includes(month.value)
	}

	toggleMonthlyType(type: string) {
		// log('Toggling monthly type to', type)
		// this.rulesForm.get('bymonth').setValue([])
		if (type === 'onDay') {
			this.monthType = 'onDay'
			this.rulesForm.get('byweekday').setValue([])
			this.rulesForm.get('bysetpos').setValue([])
		}
		if (type === 'bySetPos') {
			this.monthType = 'bySetPos'
			const byWeekday = ScheduleOptions.ruleConst.byWeekdayDefault
			this.rulesForm.get('byweekday').setValue(byWeekday)
			const bySetPos = ScheduleOptions.ruleConst.bySetPosDefault
			this.rulesForm.get('bysetpos').setValue(bySetPos)
			this.rulesForm.get('bymonthday').setValue([])
		}
		this.updateRule()
	}

	monthDayFocus() {
		// log('Focused onDay')
		this.monthType = 'onDay'
		this.toggleMonthlyType('onDay')
	}

	onPositionalInputFocus() {
		// log('Focused Monthly bysetpos')
		this.monthType = 'bySetPos'
		this.rulesForm.get('bymonthday').setValue([])

		const byWeekday = this.rulesForm.get('byweekday').value
		if (_.isArray(byWeekday) && _.isEmpty(byWeekday)) {
			const newByWeekday = ScheduleOptions.ruleConst.byWeekdayDefault
			this.rulesForm.get('byweekday').setValue(newByWeekday)
		}

		const bySetPos = this.rulesForm.get('bysetpos').value
		// log('bysetpos input', bySetPos)
		if (_.isArray(bySetPos) && _.isEmpty(bySetPos)) {
			const newBySetPos = ScheduleOptions.ruleConst.bySetPosDefault
			this.rulesForm.get('bysetpos').setValue(newBySetPos)
		}
	}

	listDaysSelected() {
		const jobId = this.rulesForm.get('job_id').value
		const job = jobId ? this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId) : null
		const isMultiDay = job ? job.multi_day : false

		const selected = []
		this.dayOptions.forEach((day) => {
			if (this.isWeekdaySelected(day)) {
				selected.push(day.code)
			}
		})

		const result = isMultiDay ? selected.join(' or ') : selected.join(', ')
		return result ? result : '(required)'
	}

	public enableWeekday(day) {
		// log('EditForm Enable Day', day)
		const days: Array<number> = this.rulesForm.value.byweekday
		if (!days.includes(day.value)) {
			days.push(day.value)
			this.updateRule()
		}
	}

	public disableWeekday(day) {
		// log('EditForm Disable Day', day)
		const days: Array<number> = this.rulesForm.value.byweekday
		this.rulesForm.get('byweekday').setValue(days.filter((elm) => elm !== day.value))
		this.updateRule()
	}

	toggleWeekday(day) {
		// log(day)
		const days: Array<number> = this.rulesForm.value.byweekday
		// log(days)
		if (this.isWeekdaySelected(day)) {
			// log('is selected')
			this.rulesForm.get('byweekday').setValue(days.filter((elm) => elm !== day.value))
		} else {
			// log('is not selected')
			days.push(day.value)
		}
		this.assignmentManager.toggleWeekday(day)
		this.updateRule()
	}

	hasDaysSelected(): boolean {
		return !_.isEmpty(this.rulesForm.value.byweekday)
	}

	isWeekdaySelected(day): boolean {
		const byweekday = this.rulesForm.get('byweekday').value
		const days: Array<number> = this.rulesForm.value.byweekday || []
		return days.includes(day.value)
	}

	isWeekdaySelectedForMultiDayJob(day): boolean {
		const jobId = this.rulesForm.get('job_id').value
		if (!jobId) {
			return false
		}
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)
		const isSelected = this.isWeekdaySelected(day)
		if (isSelected && job.multi_day) {
			return true
		} else {
			return false
		}
	}

	isMultiDayJob(): boolean {
		const jobId = this.rulesForm.get('job_id').value
		if (!jobId) {
			return false
		}
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)
		return job.multi_day
	}

	momentFromRuleDate(date: Date): moment.Moment {
		const dateString = date.toISOString().replace('Z', '')
		return moment(dateString)
	}

	getAdjustedRuleString(addUntilTime: boolean) {
		const ruleStr = this.currentRuleString
		const comps = ruleStr.split(';')
		// log('COMPS', comps)
		const newComps = []
		comps.forEach((comp) => {
			if (!comp.includes('UNTIL')) {
				newComps.push(comp)
			} else {
				const untilComps = comp.split('=')
				const date = untilComps[1]
				if (date) {
					const newUntilDate = date.split('T')
					const newUntilString = 'UNTIL=' + newUntilDate[0] // + 'T235959'
					const newUntilResult = addUntilTime ? newUntilString + 'T235959' : newUntilString
					newComps.push(newUntilResult)
				}
			}
		})
		const result = newComps.join(';')
		return result
	}

	showStartDateTip(): boolean {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const wkstDefault = company.wkst ? company.wkst : 0
		const rule = this.rule
		if (!rule) {
			return false
		}
		const startInput = this.rulesForm.get('dtstart').value
		if (!startInput) {
			return false
		}
		const interval = this.rulesForm.get('interval').value
		if (!interval || interval === 1 || interval === '1') {
			return false
		}
		// log('Interval', interval)
		const startDate = this.rule.options.dtstart
		const dayNum = moment(startDate).weekday()
		// log('DAYNUM', dayNum)
		if (rule.options.freq === 2) {
			if (wkstDefault === 0 && dayNum !== 1) {
				this.startDateTip =
					'For this type of weekly schedule you should set the Start Date to the Monday of the week you want your schedule to begin.'
				return true
			}
			if (wkstDefault === 6 && dayNum !== 0) {
				this.startDateTip =
					'For this type of weekly schedule you should set the Start Date to the Sunday of the week you want your schedule to begin.'
				return true
			}
		}
		return false
	}

	// Update rule for job when selected from dropdown by setting new timezone
	selectJob() {
		const jobId = this.rulesForm.get('job_id').value
		const site = this.coreSrvc.dbSrvc.jobSrvc.getJobSiteForJobId(jobId)
		if (site) {
			const tzId = site.timezone_id
			const siteTzZoneName = this.coreSrvc.dbSrvc.settingSrvc.getTimezoneZoneNameForId(tzId)
			this.rule.options.tzid = siteTzZoneName
			this.rule.origOptions.tzid = siteTzZoneName
			this.rulesForm.get('tzid').setValue(siteTzZoneName)
		}
		this.setupJobOverrides()
		// this.checkForDuplicateJob()
		this.updateRule()
	}

	setupJobOverrides() {
		const jobId = this.rulesForm.get('job_id').value
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)
		const breakTime = job ? job.break_time : null
		const breakTimeWorked = job ? job.break_time_worked : null
		const breakTimeWorkedDuration = moment.duration(breakTimeWorked)
		const breakTimeWorkedFormatted = breakTimeWorked ? DateTimeHelper.formatDuration('H:mm', breakTimeWorkedDuration) : ''
		const overage = job ? job.overage_time : null
		const formattedBreakTime = this.makeBreakTime(breakTime)
		const formattedOverage = this.makeOverage(overage)
		this.placeholder.break_time = breakTime ? `${formattedBreakTime}` : ''
		this.placeholder.defaults.break_time = breakTime ? `${formattedBreakTime}` : ''
		this.placeholder.break_time_worked = breakTimeWorked ? breakTimeWorkedFormatted : ''
		this.placeholder.defaults.break_time_worked = breakTimeWorked ? breakTimeWorkedFormatted : ''
		this.placeholder.overage_time = overage ? `${formattedOverage}` : ''
		this.placeholder.defaults.overage_time = overage ? `${formattedOverage}` : ''
	}

	// Displays the shift info above the job dropdown
	displayShiftInfo(): string {
		const jobId = this.rulesForm.get('job_id').value
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)
		if (job) {
			const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(job.location_id)
			if (site) {
				const siteTzId = site.timezone_id
				return this.coreSrvc.dbSrvc.settingSrvc.getTimezoneDisplayNameForId(siteTzId)
			}
		}
		return null
	}

	makeOverlapTime(time: string): string {
		if (this.isAnytimeJob) {
			return '09:00:00'
		}
		const formTime = this.rulesForm.get(time).value
		return moment(formTime).format('HH:mm:ss')
	}

	updateNumericInput(input: string) {
		const value = this.rulesForm.get(input).value
		if (value === 0 || value === '0' || value === '') {
			this.rulesForm.get(input).setValue(null)
			this.updateRule()
			return
		}
		const intString = Helper.stripNonNumeric(value)
		const intValue = parseInt(intString, 10)

		// If setting count, enforce a limit
		if (input === 'count') {
			if (intValue > 99) {
				this.rulesForm.get(input).setValue('99')
				this.updateRule()
				alert('The maximum value for count is 99.')
				return
			}
		}

		this.rulesForm.get(input).setValue(intString)
		// log(input, value)
		if (intValue) {
			this.updateRule()
		}
	}

	clearDate(input: string) {
		this.rulesForm.get(input).setValue(null)
		this.updateStartEndDates()
		// this.updateRule()
		return false
	}

	makeUpdateTime(date: Date): string {
		if (this.isAnytimeJob) {
			return '09:00:00'
		}
		const mom = moment(date)
		if (mom.isValid()) {
			return mom.format('HH:mm:ss')
		}
		return null
	}

	saveSchedule(action: string) {
		// Guard against double submission
		if (this.isUpdating) return
		// FormHelper.trimOnlyWhitespace(this.rulesForm)

		const record = this.makeUpdateRecord()
		log('Record to send', record)
		// return
		if (record) {
			this.isUpdating = true
			// this.coreSrvc.dbSrvc.schedulerSrvc.scheduleRecurDidBeginUpdate.next(true)
			if (action === 'insert') {
				this.coreSrvc.dbSrvc
					.insertRecord('schedule_recur', record)
					.then((success) => {
						log('Save Schedule Result', success)
						if (success) {
							const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(record.employee_id)
							const empName = emp ? emp.first + ' ' + emp.last : null
							if (empName) {
								this.coreSrvc.notifySrvc.notify('success', 'Schedule Created', `A new schedule has been added for ${empName}.`, 4)
							}
							this.actionComplete.next(true)
						} else {
							this.isUpdating = false
						}
					})
					.catch((err) => {
						log('insert sched_recur catch', err)
					})
			} else {
				this.coreSrvc.dbSrvc
					.updateRecord('schedule_recur', record)
					.then((success) => {
						log('Update Schedule Result', success)
						if (success) {
							this.actionComplete.next(true)
						} else {
							this.isUpdating = false
						}
					})
					.catch((err) => {
						log('update sched_recur catch', err)
					})
			}
		}
	}

	getBreakTimeForUpdateRecord(): string {
		const value = this.rulesForm.get('break_time').value
		const intValue = parseInt(value, 10)

		if (intValue === 0) {
			return 'PT0M'
		}
		if (!intValue) {
			return null
		}
		return moment.duration(intValue, 'minutes').toISOString()

		// const absValue = Math.abs(intValue)
		// if (!absValue) {
		// 	if (absValue === 0) {
		// 		return 'PT0M'
		// 	} else {
		// 		return null
		// 	}
		// }

		// const dur = moment.duration(intValue, 'minutes')
		// if (dur) {
		// 	const result = DateTimeHelper.formatDuration('HH:mm:ss', dur)
		// 	return result
		// }
		// return null
	}

	getOverageForUpdateRecord(): string {
		const jobDuration = this.rulesForm.get('overage_time').value
		const duration = moment.duration(jobDuration)

		if (duration.asMinutes() > 0) {
			return duration.toISOString()
		} else {
			return jobDuration ? 'PT0M' : null
		}
	}

	makeBreakTimeWorkedForUpdateRecord(dur: string): string {
		let breakTimeWorkedDuration = moment.duration(dur)
		if (dur && !dur.includes(':')) {
			const intValue = parseInt(dur, 10)
			const absValue = Math.abs(intValue)
			if (absValue > 16) {
				breakTimeWorkedDuration = moment.duration(absValue, 'minutes')
			} else {
				breakTimeWorkedDuration = moment.duration(absValue, 'hours')
			}
		}
		const breakTimeWorkedIsValid = breakTimeWorkedDuration.asMinutes() > 0
		return breakTimeWorkedIsValid ? breakTimeWorkedDuration.toISOString() : null
	}

	// makeNonWeeklyMultiEmpDaysOfWeek(): Array<any> {
	// 	const empIds = this.rulesForm.get('employee_ids').value
	// 	// [{\"employee_id\":0,\"days_of_week\":[2]},{\"employee_id\":21615,\"days_of_week\":[2]}]
	// 	return empIds.map(id => ({ employee_id: id, days_of_week: null }))
	// }

	makeUpdateRecord(): ScheduleEntry {
		// Check if email is valid and add it to form
		if (this.newSchedChangeEmailInput) {
			if (!this.isSchedChangeEmailInputValid()) {
				alert('Please enter a valid email address for schedule change notifications.')
				return null
			} else {
				this.addSchedChangeEmail(false)
			}
		}

		const record = new ScheduleEntry()
		const entry = this.scheduleEntry
		const recordId = entry ? entry.id : null
		const isEnabled = this.rulesForm.get('enabled').value
		const assignedColor = this.rulesForm.get('assigned_color').value
		const empId = this.rulesForm.get('employee_id').value
		const empIds = this.rulesForm.get('employee_ids').value
		const jobId = this.rulesForm.get('job_id').value

		const description: string = this.rulesForm.get('description').value
		const startTime = this.rulesForm.get('start_time').value
		const endTime = this.rulesForm.get('end_time').value
		const empCount = this.rulesForm.get('employee_count').value
		const comments = this.rulesForm.get('comments').value
		const adjustedRuleString = this.getAdjustedRuleString(false)
		const notificationEmails = this.schedChangeEmails.join(',')
		// const breakTimeWorkedDuration = moment.duration(breakTimeWorked)
		// const breakTimeWorkedIsValid = breakTimeWorkedDuration.asMinutes() > 0
		const overage = this.getOverageForUpdateRecord()

		record.id = recordId
		record.enabled = isEnabled
		record.assigned_color = assignedColor
		record.recurrence = adjustedRuleString

		if (record.recurrence && !record.recurrence.includes('RRULE:')) {
			record.recurrence = 'RRULE:' + record.recurrence
		}

		// Set approval_state based on selected options
		if (this.pendingStatus.isPending || this.pendingStatus.isApproved) {
			if (this.pendingStatus.isPending) record.approval_state = 'PENDING'
			if (this.pendingStatus.isApproved) record.approval_state = 'APPROVED'
		} else {
			record.approval_state = this.rulesForm.get('approval_state').value
		}

		record.holiday = this.rulesForm.get('holiday').value

		// Deal with employee ID and employee IDs
		switch (this.selectedScheduleType) {
			case ScheduleType.anyEmployee:
				record.employee_id = 0
				record.employee_ids = []
				record.employee_count = empCount || 1
				break
			case ScheduleType.multiEmployee:
				record.employee_id = 1
				record.employee_ids = empIds
				record.employee_count = 1
				break
			case ScheduleType.individualEmployee:
				record.employee_id = empId
				record.employee_ids = []
				record.employee_count = 1
				break
		}

		// Manage emp_ids for DOW when it's a weekly schedule
		const isWeeklySchedule = this.rule.options.freq === 2
		const isMultiEmployeeSchedule = this.selectedScheduleType === ScheduleType.multiEmployee
		if (isMultiEmployeeSchedule) {
			if (isWeeklySchedule) {
				record.emp_ids_days_of_week = this.assignmentManager.getUpdateString()
			} else {
				const empIdsDaysOfWeek = empIds.map((id) => ({ employee_id: id, days_of_week: null }))
				record.emp_ids_days_of_week = JSON.stringify(empIdsDaysOfWeek)
			}
		} else {
			record.emp_ids_days_of_week = null
		}

		// record.employee_id = empId
		// record.employee_ids = empIds
		// record.employee_count = empCount ? empCount : 1

		// Validate break time fields
		const breakTime = this.rulesForm.get('break_time').value
		const breakTimeString = breakTime || breakTime === 0 ? `${breakTime}` : ''
		const isBreakTimeValid = !breakTimeString || breakTimeString === '0' || parseInt(breakTimeString, 10)
		const breakTimeIntValue = parseInt(breakTime, 10)
		const breakTimeWorked = this.rulesForm.get('break_time_worked').value
		record.break_time = this.getBreakTimeForUpdateRecord()
		record.break_time_worked = this.makeBreakTimeWorkedForUpdateRecord(breakTimeWorked)

		if (!isBreakTimeValid) {
			alert(`Please enter a valid value for 'Break Length' or clear the field.`)
			return null
		}
		if (breakTimeWorked && !record.break_time_worked) {
			alert(`Please enter a valid value for 'Auto-apply Break' or clear the field.`)
			return null
		}
		if (breakTimeWorked && !breakTimeIntValue) {
			alert(`You must explicitly set a valid 'Break Length' if you wish to auto apply break times to this schedule.`)
			return null
		}

		// Handle work order email flag
		record.approve_with_workorder = this.rulesForm.get('approve_with_workorder').value || false
		record.workorder_email = this.approveWithWorkorderEmailListManager.emailListString

		record.job_id = jobId
		record.notification_email = notificationEmails
		record.description = description
		record.comments = comments
		record.overage_time = overage

		record.schedule_one_time = this.rulesForm.get('schedule_one_time').value

		const startDate: Date = this.rulesForm.get('startDate').value
		const endDate: Date = this.rulesForm.get('endDate').value
		// log('Start Date', startDate)
		// log('End Date', endDate)

		if (startDate) {
			record.start_date = moment(startDate).format('YYYY-MM-DD')
		} else {
			record.start_date = moment().startOf('isoWeek').format('YYYY-MM-DD')
		}
		if (endDate) {
			record.recurrence_end = moment(endDate).format('YYYY-MM-DD')
		}

		// Make Start/End times

		record.start_time = this.makeUpdateTime(startTime)
		record.end_time = this.makeUpdateTime(endTime)

		// log('Current Rule String', this.currentRuleString)
		// log('Record', record)
		// return null

		// If the overtime exception is to be overridden, insert the flag here
		if (this.overrideProtection.allowOvertimeOverride) {
			record['ot_override'] = true
		}
		if (this.overrideProtection.allowTimeoffOverride) {
			record['vacation_override'] = true
		}

		// Tags
		record.tags_json = this.rulesForm.get('tags_json').value

		return record
	}

	sortByWeekdayList() {
		const byWeekdayList = this.rule.options.byweekday
		this.rule.options.byweekday = _.sortBy(byWeekdayList)
		// log('rule byweekday', byWeekdayList)
	}

	hideTable(): boolean {
		const rule = this.rule
		const freq = this.rule.options.freq

		if (freq === 2) {
			const byWeekDay = this.rule.options.byweekday
			if (!byWeekDay || _.isEmpty(byWeekDay)) {
				return true
			}
		}

		return false
	}

	isSchedChangeEmailInputValid(): boolean {
		const email = this.newSchedChangeEmailInput
		return Helper.isValidEmail(email)
	}

	addSchedChangeEmail(showAlert: boolean) {
		const email = this.newSchedChangeEmailInput
		if (email && this.isSchedChangeEmailInputValid()) {
			this.schedChangeEmails.push(email)
			this.newSchedChangeEmailInput = ''
		} else {
			if (showAlert) {
				alert('The contact email address for schedule changes is not a valid address.')
			}
			return
		}
	}

	removeEmail(idx: number, emailList: string) {
		if (emailList === 'schedChangeEmail') {
			this.schedChangeEmails.splice(idx, 1)
		}
	}

	calculateShiftLength(): string {
		let result: number

		const startTime: any = moment(this.rulesForm.get('start_time').value).clone()
		const endTime: any = moment(this.rulesForm.get('end_time').value).clone()

		if (endTime < startTime) {
			const newEnd: any = moment(endTime).clone().add(1, 'days')
			result = moment.duration(newEnd - startTime).asHours()
		} else {
			result = moment.duration(endTime - startTime).asHours()
		}

		if (result) {
			return result.toFixed(2)
		} else {
			return null
		}
	}

	paidHolidayCheck() {
		const isHoliday = this.rulesForm.get('holiday').value
		if (isHoliday) {
			this.isAnytimeJob = false
			// const freq = 'year'
			// this.setShow(freq)
			// this.resetForm(freq)
			// this.updateRule()
			// this.cd.detectChanges()
		}
	}

	togglePaidHoliday() {
		const currentValue = this.rulesForm.get('holiday').value
		this.rulesForm.get('holiday').setValue(!currentValue)
		this.paidHolidayCheck()
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'auto_check_in_out':
				help.header = 'Auto Check In/Out'
				help.message =
					'When selected, this option will automatically check an employee in and for this shift.<br /><br /><b style="color:firebrick">DO NOT</b> select this option unless you are sure this schedule should be managed by the system. When using this option, you will not receive any notifications if your employee does not show up for this shift.'
				break
			case 'employee_count':
				help.header = 'Any Employee'
				help.message = `The 'Any Employee' option allows for a flexible approach to scheduling. Instead of assigning specific employees, simply indicate the number of employees required for this shift and our system will manage the rest.`
				break
			case 'markPending':
				help.header = 'Mark Pending'
				help.message = `When a schedule is marked as pending, it will be held in the Pending queue until it is approved. Pending schedules do not generate No Show records or trigger notifications to employees or supervisors.`
				break
			case 'markApproved':
				help.header = 'Mark Approved'
				help.message = `When a schedule is marked as approved, it will be removed from the pending queue and will be available to the system for tracking purposes. Approved schedules may generate No Show records and can trigger notifications.`
				break
			case 'approve_with_workorder':
				help.header = 'Work Orders'
				help.message = 'When checked, the system will send a work order for this schedule to the email addresses provided.'
				break
			case 'assignEmployees':
				help.header = 'Schedule Employees'
				help.message = `For multi-employee schedules, at least one employee must be scheduled for every day you select.`
				break
			case 'details':
				help.header = 'Schedule Details'
				help.message = `Schedule Details are displayed in the Date/Details column of the time entries screen and you may optionally expose this information to employees in the employee web portal during check in.`
				break
			case 'schedulerNotes':
				help.header = 'Scheduler Notes'
				help.message = `Employees will not see these notes. They are only available in the admin portal to those who maintain the schedule and time entries.`
				break
			case 'scheduleType':
				help.header = 'Schedule Type'
				help.message = `Use 'Individual Employee' to schedule a single known employee. Use 'Multiple Employees' to schedule multiple known employees. Use 'Any Employee(s)' when you want to track that an employee checks in for the job but are not concerned about which employee is doing the work.`
				break
			case 'shiftDescription':
				help.header = 'Schedule Details'
				help.message = `This field may be used to add extra information to identify this specific shift in the transaction log. For more extensive information about this shift, use the Schedule Notes field.`
				break
			case 'paidHoliday':
				help.header = 'Paid Holiday'
				help.message = `Check this box to make this schedule a paid holiday which will automatically check the selected employees in and out for this schedule.`
				break
			case 'anytimeJob':
				help.header = 'Anytime Job'
				help.message = `Check this box if you don’t know (or don’t care) what time the employee starts or ends this job.`
				break
			case 'breakTime':
				help.header = 'Break Length'
				help.message = `You may override any break length set for this job by entering a value here.`
				break
			case 'breakTimeWorked':
				help.header = 'Auto-apply Break'
				help.message = `Specify how long an employee must work (in hours and minutes) in order for the the Break Length to be applied. For example, to add a fifteen minute break every four and a half hours worked, set the Break Length to 15 and Auto-apply Break to 4:30. Leave blank if you want the Break Length to apply regardless of time worked.`
				break
			case 'trackOverage':
				help.header = 'Time Overage'
				help.message = `When an employee is checked in longer than the time specified, that time will be tracked in a separate column in the Time Entries list.`
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
