import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core'
import {
	VisualizationViewManager,
	VizCardActionEvent,
	VizCardGraphDataSource,
	DialogManager,
	VizDashboard,
	VizCardCounterDataSource,
} from '@app/models'
import { CoreService } from '@app/services'

import { Subscription } from 'rxjs'
import moment from 'moment-timezone'
import { log } from '@app/helpers'
import { VisualizationService } from '@app/services/backend/visualization.service'
import { VizSectionHeaderComponent } from './viz-section-header/viz-section-header.component'
import { VizHealthCenterComponent } from './viz-health-center/viz-health-center.component'

import { Chart } from 'chart.js'

@Component({
    selector: 'app-visualization',
    templateUrl: './visualization.component.html',
    styleUrl: './visualization.component.scss',
    standalone: false
})
export class VisualizationComponent implements AfterViewInit, OnDestroy {
	isInternalUser = false
	isDataLoaded = false

	configureGraphAction: { graph: VizCardGraphDataSource } = { graph: null }
	configureGraphDialogManager = new DialogManager('ConfigureGraphDialog')

	@ViewChild('healthCenter') healthCenter: VizHealthCenterComponent
	@ViewChild('dashboardHeader') dashboardHeader: VizSectionHeaderComponent

	private subs = new Subscription()

	constructor(private coreSrvc: CoreService) {
		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()

		// Setup handler for screen resize
		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.updateUI()))
		this.subs.add(this.coreSrvc.dbSrvc.vizSrvc.dashboardsNeedUpdate.subscribe(() => this.updateUI()))
		this.setupDialogManager()

		Chart.defaults.color = '#000' // Set the default text color
		Chart.defaults.backgroundColor = ['#ff6384', '#36a2eb', '#ffce56', '#4bc0c0', '#9966ff'] as any // Set the default background colors
		Chart.defaults.borderColor = 'rgba(0, 0, 0, 0.1)' // Set the default border color
	}

	ngAfterViewInit(): void {
		this.vizSrvc.fetchHealthCenterData().then(() => {
			this.loadDashboardData()
		})
	}
	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	get vizSrvc(): VisualizationService {
		return this.coreSrvc.dbSrvc.vizSrvc
	}
	get dashboards(): Array<VizDashboard> {
		return this.coreSrvc.dbSrvc.vizSrvc.dashboards
	}
	get currentDashboard(): VizDashboard {
		return this.coreSrvc.dbSrvc.vizSrvc.currentDashboard
	}
	get viewManager(): VisualizationViewManager {
		return this.coreSrvc.dbSrvc.vizSrvc.viewManager
	}

	private loadDashboardData() {
		const dates = this.getfetchParameters()
		this.coreSrvc.dbSrvc.readTable('company_activity_log', dates).then(() => {
			this.coreSrvc.dbSrvc.readTable('dashboard').then(() => {
				this.coreSrvc.dbSrvc.vizSrvc.loadDashboardData()
				this.updateUI()
			})
		})
	}

	private updateUI() {
		const recordCount = this.coreSrvc.dbSrvc.vizSrvc.getCompanyActivityLogRecords().length

		// Refresh all card data
		for (const dashboard of this.dashboards) {
			for (const row of dashboard.data) {
				for (const card of row.cardData) {
					card.updateData()
				}
				for (const graph of row.graphData) {
					graph.updateData()
				}
			}
		}

		if (recordCount === 0 && this.viewManager.currentView === 'DASHBOARDS') {
			this.coreSrvc.notifySrvc.notify('info', 'No Data', 'No data available for the selected date range.', 5)
		}

		// this.viewManager.save() // Don't ave in this method, save when changing things elsewhere
		setTimeout(() => {
			this.isDataLoaded = true
			this.coreSrvc.displaySrvc.enableAllTooltips()
		}, 500)
	}

	private setupDialogManager() {
		this.configureGraphDialogManager.headerLabel = 'Configure Graph'
		this.configureGraphDialogManager.isSubmitBtnVisible = false
		this.configureGraphDialogManager.cancelBtnLabel = 'Close'
		this.configureGraphDialogManager.cancelBtnAction = () => {
			this.configureGraphDialogManager.isDialogVisible = false
			this.updateUI()
		}
	}

	private getfetchParameters() {
		const start = this.viewManager.startDate
		const end = this.viewManager.endDate
		return {
			start_date: moment(start).format('YYYY-MM-DD'),
			end_date: moment(end).format('YYYY-MM-DD'),
		}
	}

	public fetchAndReload() {
		const dates = this.getfetchParameters()
		this.coreSrvc.dbSrvc.readTable('company_activity_log', dates).then(() => {
			this.updateUI()
		})
	}

	public handleFilterUpdate() {
		this.fetchAndReload()
	}

	public handleCardEvent(event: VizCardActionEvent) {
		// Get references to the rows and data based on the type of card we are dealing with
		const dashboardRows = this.currentDashboard.data
		const rowData = event.cardType === 'COUNTER' ? dashboardRows[event.rowIndex].cardData : dashboardRows[event.rowIndex].graphData
		log('Card Event', event)

		if (event.action === 'MOVE_CARD') {
			if (event.direction === 'LEFT' || event.direction === 'RIGHT') {
				this.moveCard(event.direction, event.rowIndex, event.cardIndex)
			}
			if (event.direction === 'UP' || event.direction === 'DOWN') {
				this.moveRow(event.direction, event.rowIndex)
			}
		}

		// Add a card
		if (event.action === 'ADD_CARD') {
			if (event.cardType === 'COUNTER') {
				// Add a counter card to the row
				if (rowData.length === 4) {
					this.coreSrvc.notifySrvc.notify('error', 'Invalid Operation', 'Cannot add more than 4 cards per row.', 5)
					return
				}
				const empCard = new VizCardCounterDataSource()
				rowData.splice(event.cardIndex + 1, 0, empCard)
			}
			if (event.cardType === 'GRAPH') {
				// Add a graph card to the row
				if (rowData.length === 2) {
					this.coreSrvc.notifySrvc.notify('error', 'Invalid Operation', 'Cannot add more than 2 graphs per row.', 5)
					return
				}
				const empGraph = new VizCardGraphDataSource()
				rowData.splice(event.cardIndex + 1, 0, empGraph)
			}
			this.vizSrvc.markCurrentDashboardAsModified()
		}

		// Remove the card and also remove the row if it's the last card in the row
		if (event.action === 'REMOVE_CARD') {
			rowData.splice(event.cardIndex, 1)
			if (rowData.length === 0) dashboardRows.splice(event.rowIndex, 1)
			this.vizSrvc.markCurrentDashboardAsModified()
		}

		// Remove the row
		if (event.action === 'REMOVE_ROW') {
			dashboardRows.splice(event.rowIndex, 1)
			this.vizSrvc.markCurrentDashboardAsModified()
		}

		// Add a row
		if (event.action === 'ADD_ROW') {
			this.dashboardHeader.confirmAction('ADD_DATASOURCE')
		}

		// Configure graph
		if (event.action === 'CONFIGURE_GRAPH') {
			this.configureGraphAction.graph = event.data as VizCardGraphDataSource
			this.configureGraphDialogManager.isDialogVisible = true
		}
	}

	private moveRow(direction: 'UP' | 'DOWN', rowIndex: number): boolean {
		const dashboardData = this.currentDashboard.data
		if (rowIndex !== -1) {
			if (direction === 'UP' && rowIndex > 0) {
				// Move row up
				;[dashboardData[rowIndex], dashboardData[rowIndex - 1]] = [dashboardData[rowIndex - 1], dashboardData[rowIndex]]
				this.vizSrvc.markCurrentDashboardAsModified()
				return true
			} else if (direction === 'DOWN' && rowIndex < dashboardData.length - 1) {
				// Move row down
				;[dashboardData[rowIndex], dashboardData[rowIndex + 1]] = [dashboardData[rowIndex + 1], dashboardData[rowIndex]]
				this.vizSrvc.markCurrentDashboardAsModified()
				return true
			}
		}
		return false // Return false if the row couldn't be moved
	}

	private moveCard(direction: 'RIGHT' | 'LEFT', rowIndex: number, cardIndex: number): boolean {
		const dashboardData = this.currentDashboard.data
		const row = dashboardData[rowIndex].getRowData()
		if (cardIndex !== -1) {
			if (direction === 'RIGHT' && cardIndex < row.length - 1) {
				// Move card to the right
				;[row[cardIndex], row[cardIndex + 1]] = [row[cardIndex + 1], row[cardIndex]]
				this.vizSrvc.markCurrentDashboardAsModified()
				return true
			} else if (direction === 'LEFT' && cardIndex > 0) {
				// Move card to the left
				;[row[cardIndex], row[cardIndex - 1]] = [row[cardIndex - 1], row[cardIndex]]
				this.vizSrvc.markCurrentDashboardAsModified()
				return true
			}
		}
		return false // Return false if the card couldn't be moved
	}

	public toggleHelp() {
		if (this.vizSrvc.viewManager.currentView === 'HEALTH_CENTER') {
			this.healthCenter.showMoreInfo = !this.healthCenter.showMoreInfo
		} else {
			this.coreSrvc.notifySrvc.notify('info', 'Help Unavailable', 'This section does not yet have any help information.', 3)
		}
	}
}
