<app-activity-banner [isProcessing]="isLoading" [message]="'Loading Data ...'"></app-activity-banner>

<div *ngIf="!isLoading && !editAction.isEditing">
	<div *ngFor="let vm of list">
		<div class="card list-card-no-shadow" [class.list-card-disabled]="!vm.record.enabled">
			<div class="card-body list-card-body">
				<div class="list-card-header" (click)="vm.isExpanded = !vm.isExpanded">
					<div class="float-right list-card-icon-wrap">
						<i
							class="far fa-edit act-ico act-ico-clr-green act-ico-dark-hover list-card-act-ico"
							title="Edit Record"
							(click)="actionIconClicked($event, 'EDIT', vm)"
						></i>
						<!-- <i
							class="far fa-copy act-ico act-ico-dark-hover list-card-act-ico"
							title="Clone Record"
							(click)="actionIconClicked($event, 'CLONE', vm)"
						></i> -->
						<i
							class="far fa-trash-alt act-ico act-ico-clr-red act-ico-dark-hover list-card-act-ico"
							title="Delete Record"
							(click)="actionIconClicked($event, 'DELETE', vm)"
						></i>
						<i
							class="far fa-database act-ico act-ico-dark-hover list-card-act-ico"
							[class.act-ico-highlight]="vm.record.updated"
							title="Audit Record"
							(click)="actionIconClicked($event, 'AUDIT', vm)"
						></i>
						<i
							class="fas fa-edit act-ico act-ico-dark-hover list-card-act-ico"
							[class.fa-chevron-right]="!vm.isExpanded"
							[class.fa-chevron-down]="vm.isExpanded"
							[class.act-ico-highlight]
							title="Expand Record"
							(click)="actionIconClicked($event, 'EXPAND', vm)"
						></i>
					</div>
					<div class="list-card-title tts-hl-2">{{ vm.record.requestType }}</div>
					<div class="list-card-subtitle">{{ vm.record.description }}</div>
				</div>
				<div *ngIf="vm.isExpanded && !vm.isConfimingDelete">
					<div class="alert alert-bg-gray emp-instructions">{{ vm.record.emp_instructions }}</div>
				</div>
				<div *ngIf="vm.isConfimingDelete" class="delete-confirm-block">
					<hr class="delete-confirm-hr" />
					<div class="float-right">
						<button class="btn btn-sm btn-outline-secondary" style="margin-right: 12px" (click)="vm.isConfimingDelete = false">Cancel</button>
						<button class="btn btn-sm btn-outline-danger" (click)="confirmDelete(vm)" style="width: 60px">
							<span *ngIf="!vm.isDeleting">Delete</span>
							<i class="fa fa-loader fa-spin" *ngIf="vm.isDeleting"></i>
						</button>
					</div>
					<div class="delete-confirm-label">Confirm Delete</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-onboard-definition-edit
	*ngIf="editAction.isEditing"
	[recordId]="editAction.recordId"
	[action]="editAction.action"
	[dialogManager]="dialogManager"
	(recordUpdated)="recordUpdated($event)"
>
</app-onboard-definition-edit>
