<form novalidate [formGroup]="form" (keydown.enter)="$event.preventDefault()" autocomplete="off">
	<div class="form-group">
		<label for="name-input">Name</label>
		<span *ngIf="!hasFileName" class="input-required float-right">(required)</span>
		<input id="name-input" class="form-control" formControlName="file_name" />
	</div>

	<div class="form-group">
		<label>Expiration</label>
		<div class="float-right link-text" (click)="form.get('expiration').setValue(null)">clear</div>
		<app-date-time-picker
			id="EXPIRATION"
			[currentDate]="form.get('expiration').value"
			[pickerType]="'DATE'"
			[pickerMode]="'dialog'"
			[pickerLabel]="'Expiration'"
			[format]="'ddd. MMM. Do, YYYY'"
			[hour12Timer]="is12HourFormat"
			(pickerDidClose)="form.get('expiration').setValue($event)"
		>
		</app-date-time-picker>
	</div>

	<div class="form-group">
		<label for="notes-input">Notes</label>
		<textarea id="notes-input" class="options-textarea" formControlName="notes"></textarea>
	</div>
	<div class="form-group">
		<label class="text-label">File Uploaded</label>
		<div class="uploade-date">{{ fileUploaded }}</div>
	</div>

	<!-- File hash and validator -->
	<div *ngIf="form.get('sha256').value" class="form-group">
		<div>
			<div class="link-text float-right" (click)="showValidator = !showValidator">{{ showValidator ? 'hide validator' : 'show validator' }}</div>
			<label class="text-label">SHA-256 File Hash</label>
		</div>
		<div class="file-hash">{{ form.get('sha256').value }}</div>
		<div *ngIf="showValidator" style="margin-top: 16px">
			<app-file-hash-compare [sha256]="form.get('sha256').value"></app-file-hash-compare>
		</div>
	</div>

	<app-form-expander [label]="'Manage Notifications'" [centerOnExpansion]="true">
		<div class="form-group">
			<label>Notification Date</label>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('notification_date')"></i>

			<div *ngIf="form.get('notification_date').value" class="float-right link-text" (click)="form.get('notification_date').setValue(null)">
				clear
			</div>
			<div
				*ngIf="form.get('expiration').value && !form.get('notification_date').value"
				class="float-right link-text"
				(click)="copyExpireationToNotification()"
			>
				use expiration
			</div>
			<app-date-time-picker
				id="NOTIFICATION"
				[currentDate]="form.get('notification_date').value"
				[pickerType]="'DATE'"
				[pickerMode]="'dialog'"
				[pickerLabel]="'Notification Date'"
				[format]="'ddd. MMM. Do, YYYY'"
				[hour12Timer]="is12HourFormat"
				(pickerDidClose)="form.get('notification_date').setValue($event)"
			>
			</app-date-time-picker>
			<div *ngIf="form.get('notification_sent').value" class="last-notified">
				<div class="float-right">{{ form.get('notification_sent').value | dateFormatter: 'ddd. MMM. Do, YYYY' }}</div>
				<div>Last Notified:</div>
			</div>
		</div>

		<div class="options-block">
			<div class="options-title-inline">Notification Message</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('notification_message')"></i>
			<textarea type="text" class="form-control" formControlName="notification_message"></textarea>
		</div>

		<app-generic-email-list [manager]="emailListManager"></app-generic-email-list>
	</app-form-expander>
</form>

<!-- <div>{{ dialogData.linkType }}: {{ dialogData.linkId }} / File: {{ dialogData.recordId }}</div> -->
