<app-activity-banner [isProcessing]="isUpdating" [message]="'Processing ...'"></app-activity-banner>

<div [hidden]="isUpdating || showPreview">
	<!-- <div class="options-block" [class.input-required]="!record.subject">
		<div class="float-right" *ngIf="!record.subject">(required)</div>
		<div class="options-title-inline">Description</div>
		<i title="More Information" class="far fa-info-circle tts-info-btn"  (click)="showHelp('description')"></i>
		<input type="text" class="form-control" [(ngModel)]="record.subject" />
	</div> -->

	<!-- Not available in production test environment -->
	<div *ngIf="(!isAuditMode || isInternalUser) && !isTargetListProvided" class="options-block">
		<div class="options-title">Action</div>
		<p-select appScrollLockSelect [options]="actionDropdownOptions" [(ngModel)]="record.action" [filter]="false"></p-select>
	</div>

	<div *ngIf="record.action === 'CUSTOM_MESSAGE'">
		<div class="options-block" [class.input-required]="!record.body_en">
			<div *ngIf="!record.body_en" class="float-right">(required)</div>
			<div *ngIf="record.body_en" class="float-right msg-limit" [style]="record.body_en.length > smsCharLimit ? { color: 'firebrick' } : {}">
				{{ record.body_en.length }} / {{ smsCharLimit }}
			</div>
			<div class="options-title-inline">Message</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('message')"></i>
			<textarea class="form-control msg-textarea" [(ngModel)]="record.body_en"></textarea>
		</div>

		<div *ngIf="record.body_en.length > smsCharLimit" class="alert alert-warning" style="text-align: center">
			Maximum message size is {{ smsCharLimit }} characters
		</div>
	</div>

	<div *ngIf="record.action === 'SEND_EMP_SCHEDULE'" class="alert alert-info alert-bg-gray type-alert">
		Send each employee you target below a link to their schedule in the employee web app.
	</div>

	<div *ngIf="record.action === 'SEND_EMP_AUTH_LINK'" class="alert alert-info alert-bg-gray type-alert">
		Send each employee you target below a link to access the employee web app.
	</div>

	<div class="options-block">
		<!-- <div class="checkbox-item d-flex justify-content-start">
			<div class="checkbox-wrap">
				<p-checkbox [(ngModel)]="sendNow" [binary]="true" (onChange)="updateSubmitButtonLabel()"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="sendNow = !sendNow; updateSubmitButtonLabel()" class="has-pointer">Send immediately</strong>
				<i title="More Information" class="far fa-info-circle tts-info-btn"  (click)="showHelp('sendNow')"></i>
			</div>
		</div> -->
		<div *ngIf="isNew" class="checkbox-item d-flex justify-content-start">
			<div class="checkbox-wrap">
				<p-checkbox [(ngModel)]="record.save_as_template" [binary]="true"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="record.save_as_template = !record.save_as_template" class="has-pointer">Save as template</strong>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('save_as_template')"></i>
			</div>
		</div>
	</div>

	<div class="form-group options-block options-block-radio-lg">
		<hr *ngIf="isNew" />
		<div class="form-check form-check-inline">
			<input
				type="radio"
				class="form-check-input"
				id="sendNowTrue"
				name="sendNowGroup"
				[value]="true"
				[(ngModel)]="sendNow"
				(click)="updateSaveAsTemplateOption(true)"
			/>
			<label class="form-check-label has-pointer" for="sendNowTrue" style="font-weight: bold" (click)="updateSaveAsTemplateOption(true)"
				>Send now</label
			>
		</div>
		<div class="form-check form-check-inline" style="margin-left: 20px">
			<input
				type="radio"
				class="form-check-input"
				id="sendNowFalse"
				name="sendNowGroup"
				[value]="false"
				[(ngModel)]="sendNow"
				(click)="updateSaveAsTemplateOption(false)"
			/>
			<label class="form-check-label has-pointer" for="sendNowFalse" style="font-weight: bold" (click)="updateSaveAsTemplateOption(false)"
				>Send later</label
			>
		</div>
	</div>

	<div *ngIf="!sendNow" class="options-block" style="margin-bottom: 28px" [class.input-required]="!record.sendDate && !record.save_as_template">
		<div>
			<div *ngIf="record.sendDate" class="link-text float-right" (click)="record.sendDate = null">clear</div>
			<div *ngIf="!record.sendDate && !record.save_as_template" class="float-right">(required)</div>
			<div *ngIf="!record.sendDate && record.save_as_template" class="float-right input-optional">(optional)</div>
			<div class="options-title">Date Scheduled</div>
		</div>
		<app-date-time-picker
			[currentDate]="record.sendDate"
			[pickerType]="'DATETIME'"
			[pickerMode]="'dialog'"
			[pickerLabel]="'Date Scheduled'"
			[hour12Timer]="is12Hour"
			[format]="datePickerFormat"
			(pickerDidClose)="record.sendDate = $event"
		>
		</app-date-time-picker>
	</div>

	<!-- <div *ngIf="!sendNow && !record.sendDate && !record.save_as_template" style="margin: 30px 0px">
		<div class="alert alert-warning alert-note">
			This announcement is not scheduled or marked for immediate send and will instead be saved as a template for later use.
		</div>
	</div> -->

	<div *ngIf="record.sendDate && isScheduledDatePast" style="margin: 30px 0px">
		<div class="alert alert-warning alert-note">
			The schedule date for this announcement is in the past and will not be processed further unless you change your settings or manually send it.
		</div>
	</div>

	<!-- Target Dropdown -->
	<!-- <div>
		<hr />
		<div *ngIf="!isTargetListProvided" class="options-block">
			<div class="options-title">Target</div>
			<p-select appScrollLockSelect [options]="targetDropdownOptions" [(ngModel)]="record.type" [filter]="false"></p-select>
		</div>
	</div> -->

	<div *ngIf="!isTargetListProvided" class="form-group options-block options-block-radio-lg">
		<hr />
		<div class="options-header-large" style="margin-bottom: 16px">Manage Target List</div>
		<div class="form-check">
			<input
				type="radio"
				class="form-check-input"
				id="annTypeSelectName"
				name="annTypeGroup"
				[value]="'SELECTEDEMPLOYEES'"
				[(ngModel)]="record.type"
				(click)="updateManageList('SELECTEDEMPLOYEES')"
			/>
			<label class="form-check-label has-pointer" for="annTypeSelectName" style="font-weight: bold" (click)="updateManageList('SELECTEDEMPLOYEES')"
				>Target employees by name</label
			>
		</div>
		<div class="form-check" style="margin-top: 10px">
			<input
				type="radio"
				class="form-check-input"
				id="annTypeDynamic"
				name="annTypeGroup"
				[value]="'ALLEMPLOYEES'"
				[(ngModel)]="record.type"
				(click)="updateManageList('ALLEMPLOYEES')"
			/>
			<label class="form-check-label has-pointer" for="annTypeDynamic" style="font-weight: bold" (click)="updateManageList('ALLEMPLOYEES')"
				>Target employees using rules</label
			><i
				title="More Information"
				class="far fa-info-circle tts-info-btn"
				style="position: relative; top: 2px"
				(click)="showHelp('targetRules')"
			></i>
		</div>
	</div>

	<div *ngIf="isEmployeesDropdownVisible" [class.input-required]="record.filter.emp_id.length === 0" style="margin-top: 26px">
		<div class="options-block">
			<!-- <div class="options-title-inline">Employee List</div>
			<div *ngIf="record.filter.emp_id.length === 0" class="float-right">(required)</div> -->
			<app-multi-select-details
				#selectedEmployeesMultiselect
				id="selectedEmployeesMultiselect"
				placeholder="Select Employees"
				selectedItemsLabel="Employees Selected: {0}"
				[dropdownLabel]="'Employee List'"
				[type]="'EMP'"
				[isRequired]="true"
				[showClearBtn]="true"
				[showToggleAll]="true"
				[options]="employeeDropdownOptions"
				[(model)]="record.filter.emp_id"
			>
			</app-multi-select-details>
		</div>
	</div>

	<div *ngIf="isEmployeesDropdownVisible && record.filter.emp_id.length > 0" class="alert alert-info alert-bg-gray">
		<div class="d-flex justify-content-between" style="font-weight: 600">
			<div>Targeting:</div>
			<div>
				<span style="color: green">{{ activeCount }} Active</span>
				<span> / </span>
				<span style="color: chocolate">{{ inactiveCount }} Inactive</span>
			</div>
		</div>
	</div>

	<!-- <div *ngIf="record.type === 'ALLEMPLOYEES'" class="alert alert-info alert-bg-gray">
		When targeting employees using rules, each option adds an additional filter, so only employees who meet all the selected criteria will be
		included.
	</div> -->

	<div *ngIf="!isEmployeesDropdownVisible">
		<!-- Employee Status -->
		<div class="options-block" style="margin-top: 26px">
			<div *ngIf="record.filter.emp_active_status" class="float-right link-text" (click)="record.filter.emp_active_status = null">clear</div>
			<div class="options-title-inline" [class.filter-block-active]="record.filter.emp_active_status">Employee Status</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('emp_active_status')"></i>
			<p-select
				appScrollLockSelect
				id="employeeStatus"
				placeholder="Any Status"
				[options]="employeeStatusOptions"
				[(ngModel)]="record.filter.emp_active_status"
			></p-select>
		</div>

		<!-- Employee Tags -->
		<div class="options-block tag-dd-block">
			<!-- <div *ngIf="record.filter.employee_tags.length > 0" class="float-right link-text">clear</div>
			<div class="options-title-inline" [class.filter-block-active]="record.filter.employee_tags.length > 0">Employee Tags</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn"  (click)="showHelp('employee_tags')"></i> -->

			<app-multi-select-details
				*ngIf="isEmployeeTagFilterAvailable"
				id="tagsMultiselect"
				placeholder="Select Employee Tags"
				[showInfoTip]="true"
				[dropdownLabel]="'Employee Tags'"
				[dropdownLabelStyle]="record.filter.employee_tags.length > 0 ? { color: '#ba078f' } : { color: 'black' }"
				[showClearBtn]="true"
				[type]="'TAG'"
				[notFoundMsg]="'Unknown Tag'"
				selectedItemsLabel="Tags Selected: {0}"
				[options]="employeeTagMultiSelectOptions"
				[(model)]="record.filter.employee_tags"
				(showHelp)="showHelp('employee_tags')"
			>
			</app-multi-select-details>
			<div class="filter-unavailable" *ngIf="!isEmployeeTagFilterAvailable">Unavailable - no employee tags setup</div>
		</div>

		<!-- Department Dropdown -->
		<div class="options-block">
			<div *ngIf="record.filter.departments.length > 0" class="float-right link-text" (click)="record.filter.departments = []">clear</div>

			<div class="options-title-inline" [class.filter-block-active]="record.filter.departments.length > 0">Department</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('department')"></i>
			<p-multiselect
				*ngIf="isDepartmentFilterAvailable"
				appScrollLockMultiSelect
				id="deptMultiselect"
				placeholder="Any Department"
				selectedItemsLabel="Departments Selected: {0}"
				[showToggleAll]="false"
				[options]="departmentDropdownOptions"
				[(ngModel)]="record.filter.departments"
				[filter]="true"
			></p-multiselect>
			<div class="filter-unavailable" *ngIf="!isDepartmentFilterAvailable">Unavailable - no departments setup</div>
		</div>

		<!-- Distance from job -->
		<div class="options-block">
			<div class="options-title-inline" [class.filter-block-active]="record.filter.job_distance">Distance from Job</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('job_distance')"></i>
			<div
				*ngIf="record.filter.job_distance"
				class="link-text float-right"
				(click)="record.filter.job_distance = null; record.filter.job_id = null"
			>
				clear
			</div>

			<div class="input-group mb-3">
				<span class="input-group-text tts-height" id="dist-addon">Distance</span>
				<input
					type="number"
					inputmode="decimal"
					class="form-control"
					style="text-align: right"
					placeholder="enter distance in"
					[(ngModel)]="record.filter.job_distance"
				/>
				<select class="form-select tts-height" [(ngModel)]="record.filter.job_distance_units" style="max-width: 80px">
					<option value="KM">km</option>
					<option value="MI">mi</option>
				</select>
			</div>
		</div>

		<!-- Distance from Job - Job Dropdown -->
		<div *ngIf="record.filter.job_distance" class="options-block" [class.input-required]="!record.filter.job_id">
			<div *ngIf="!record.filter.job_id" class="float-right">(required)</div>
			<div class="options-title-inline" [class.filter-block-active]="record.filter.job_id">Job</div>
			<div *ngIf="record.filter.job_id" class="link-text float-right" (click)="record.filter.job_id = null">clear</div>
			<p-select
				appScrollLockSelect
				id="jobOptions"
				placeholder="Select Job"
				[options]="jobDropdownOptions"
				[(ngModel)]="record.filter.job_id"
				[filter]="true"
				filterBy="label"
			></p-select>

			<div *ngIf="record.filter.job_distance && !record.filter.job_id" class="alert alert-warning" style="margin-top: 20px">
				The <b>Distance to Job</b> filter requires a job.
			</div>

			<div *ngIf="shouldShowNoGeoCodeAlert" class="alert alert-warning" style="margin-top: 20px">
				The selected job does not have a valid address and so this filter will not be applied.
			</div>
		</div>
	</div>

	<!-- Preview Target List -->
	<button class="btn btn-block btn-outline-secondary" style="margin-top: 10px" (click)="previewTargetList()">
		<span *ngIf="!isBuildingPreview">Preview Target List</span>
		<span *ngIf="isBuildingPreview"><i class="fas fa-loader fa-spin"></i> Building List ...</span>
	</button>

	<!-- <div *ngIf="record.type === 'SELECTEDEMPLOYEES' && record.filter.emp_id.length === 0" style="margin-top: 25px">
			<div class="alert alert-warning alert-note">You must select at least one employee when targeting selected employees.</div>
		</div> -->

	<div [class.mobile-pad-bottom]="isEmployeesDropdownVisible && !coreSrvc.devDetect.isDesktop()" style="margin-top: 24px">
		<app-form-expander [label]="'Manage Attachments'" [counter]="attachmentCounter" [isExpanded]="expandManageAttachments">
			<div class="tts-hl-2"><strong>Supported File Types for Attachments</strong></div>
			<div class="type-note">HTML, PDF, TXT, JPG / JPEG, PNG, GIF</div>
			<div style="margin-top: 20px">
				<app-file-uploader [fileUploadManager]="fileUploadManager"></app-file-uploader>
			</div>
		</app-form-expander>
	</div>
</div>

<div *ngIf="showPreview" [hidden]="isUpdating">
	<div class="preview-result-header">
		<div class="float-right">
			<span style="color: green">{{ previewActiveCount }} Active</span> /
			<span style="color: chocolate">{{ previewInactiveCount }} Inactive</span>
		</div>
		<div>Targeting</div>
		<!-- <div>{{ previewList.length }} Employee{{ previewList.length === 1 ? '' : 's' }}</div> -->
	</div>
	<div *ngFor="let card of previewList">
		<app-scheduled-ann-emp-info-card
			*ngIf="!previewExcludeIds.includes(card.employee.id)"
			[card]="card"
			(removeCard)="removeCard($event)"
		></app-scheduled-ann-emp-info-card>
	</div>
</div>
