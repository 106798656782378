import { Component, OnDestroy, Input, AfterViewInit, ChangeDetectorRef, NgZone, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import {
	ReportHistoryRecord,
	CrudAction,
	DatabaseTableName,
	PermissionResolver,
	ReportsViewManager,
	ReportsFilterInfoEvent,
	ComponentBridgeName,
} from '@app/models'
import { CoreService, PrefsService } from '@app/services'

import { log, DisplayHelper, ReportHelper, TableActionFormatter, GeneralTableHelper, ReportsTableFormatter, DataTablesHelper } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'
import { environment } from '@env/environment'

import { Subscription } from 'rxjs'

import _ from 'lodash'
import moment from 'moment-timezone'

enum RepHistTableColumnIndex {
	name,
	type,
	filter,
	startDate,
	endDate,
	completed,
	data,
	actions,
}

@Component({
    selector: 'app-insight-report-history-table',
    templateUrl: './insight-report-history-table.component.html',
    styleUrls: ['./insight-report-history-table.component.scss'],
    standalone: false
})
export class InsightReportHistoryTableComponent implements OnInit, OnDestroy, AfterViewInit {
	bridgeName: ComponentBridgeName = 'ngBridgeRepHistoryTable'

	accessHelper: AccessHelper
	permResolver: PermissionResolver
	canAccessAuditLog = false

	// permissions: AccessPermission
	// accessPermissions: AccessPermissions

	@Input() list: ReportHistoryRecord[] = []

	showEmptyReportDialog = false
	showDayViewPicker = false
	hasEmptyReports = false
	isRunningReport = false

	deleteAction = {
		tableName: 'reports_log_delete' as DatabaseTableName,
		recordId: null,
		recordLabel: null,
		showDeleteRecordDialog: false,
	}

	showPaging = false
	private reportsHistoryTable // : DataTables.DataTable;
	private defaultSortOrder = [[RepHistTableColumnIndex.completed, 'desc']]
	private subs = new Subscription()

	constructor(
		public router: Router,
		private cd: ChangeDetectorRef,
		private coreSrvc: CoreService,
		private zone: NgZone,
		private prefsService: PrefsService,
	) {
		window[this.bridgeName] = this

		this.setupSubscriptions()
		this.setupAccessPermissions()

		if (this.list.length > 10) this.showPaging = true
		this.loadData()
	}

	get viewManager(): ReportsViewManager {
		return this.coreSrvc.dbSrvc.reportSrvc.viewManager
	}

	get showNoPersonalReportsNotice(): boolean {
		return !this.viewManager.showAllReports && this.list.length === 0
	}

	ngOnInit() {}

	ngAfterViewInit() {
		this.initTable()

		$('#reportsHistoryTable_filter input').attr('placeholder', ' filter')
		$('#reportsHistoryTable_filter input').addClass('search-field-input')

		setTimeout(() => {
			$('.rep-history-clear-search-icon').detach().appendTo('#reportsHistoryTable_filter label')
		})
	}

	ngOnDestroy() {
		this.reportsHistoryTable['fixedHeader'].disable()
		window[this.bridgeName] = null
		this.subs.unsubscribe()
	}

	private setupSubscriptions() {
		// Subscribe to report run events
		this.subs.add(
			this.coreSrvc.dbSrvc.reportSrvc.reportRunStart.subscribe((result) => {
				this.isRunningReport = true
				this.cd.detectChanges()
			}),
		)
		this.subs.add(
			this.coreSrvc.dbSrvc.reportSrvc.reportRunComplete.subscribe((result) => {
				setTimeout(() => {
					this.zone.run(() => {
						log('VIEW - Got Report Run Complete')
						this.reloadTable()
						this.isRunningReport = false
						// this.coreSrvc.dbSrvc.workSrvc.resetWorking()
						// this.cd.detectChanges()
					})
				}, 2000)
			}),
		)
		this.subs.add(
			this.coreSrvc.dbSrvc.reportSrvc.reportSaveComplete.subscribe((result) => {
				this.zone.run(() => {
					this.reloadTable()
				})
			}),
		)
		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	private setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'report')
		this.permResolver = new PermissionResolver(this.coreSrvc.dbSrvc, 'reports')
		this.canAccessAuditLog = this.accessHelper.canPerformAction(CrudAction.read, true, 'audit')
	}

	private handleScreenSizeChanges() {
		if (this.reportsHistoryTable) {
			this.reportsHistoryTable.columns.adjust().responsive.recalc()
			this.reportsHistoryTable.fixedHeader.adjust()
		}
	}

	canPerformAction(action: CrudAction, isMyRecord: boolean) {
		return this.accessHelper.canPerformAction(action, isMyRecord)
	}

	runReport(id: number, standardReport: boolean) {
		log('Running Report')
		const repHist = this.coreSrvc.dbSrvc.reportSrvc.getReportHistoryById(id)
		const canRun = this.permResolver.canPerformAction(CrudAction.read, repHist)
		if (!canRun) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.coreSrvc.dbSrvc.reportSrvc.reportRunStart.emit(true)
		this.coreSrvc.dbSrvc.reportSrvc.runReportHistoryById(id).then((success) => {
			this.viewManager.dayViewDate = null
			this.viewManager.pulseFirstRow = true
			this.coreSrvc.dbSrvc.reportSrvc.reportRunComplete.emit(true)
		})
	}

	viewOrDownloadReport(id: number) {
		const repHist = this.coreSrvc.dbSrvc.reportSrvc.getReportHistoryById(id)
		const canViewOrDownload = this.permResolver.canPerformAction(CrudAction.read, repHist)
		if (repHist.error) {
			this.coreSrvc.notifySrvc.notify('error', 'Report Error', 'An error occurred while generating this report. Please contact support.')
			return
		}
		if (!canViewOrDownload) {
			this.notifyOperationNotAuthorized()
			return
		}
		if (!repHist.completed) {
			this.coreSrvc.notifySrvc.notify('info', 'Processing', 'The report is still running, please try again in a moment.')
			this.reloadTable()
			return
		}
		if (repHist.empty) {
			this.coreSrvc.notifySrvc.notify('error', 'Empty Report', 'The selected report does not contain any data.')
			return
		}

		const extension = repHist.format.toLowerCase()
		const fileName = repHist.file_reference + '.' + extension
		const reportUrl = `https://${environment.reportsDomain}/${fileName}`
		log('FIlename', repHist.file_reference)
		if (repHist.format === 'HTML') {
			window.open(reportUrl, '_blank')
		} else {
			window.location.assign(reportUrl)
			this.coreSrvc.notifySrvc.notify('success', 'Report Downloaded', 'Please check your downloads folder for a copy of this report.', 4)
		}
	}

	deleteRecord(id: number) {
		log('ID', id)
		if (!this.canPerformAction(CrudAction.delete, false)) {
			this.notifyOperationNotAuthorized()
			return
		}
		const repHistory = this.coreSrvc.dbSrvc.reportSrvc.getReportHistoryById(id)
		if (repHistory) {
			this.deleteAction.recordId = id
			this.deleteAction.recordLabel = repHistory.name
			this.deleteAction.showDeleteRecordDialog = true
		}
	}

	deleteActionComplete() {
		log('Delete action complete')
		this.deleteAction.showDeleteRecordDialog = false
		this.list = this.coreSrvc.dbSrvc.reportSrvc.getReportsHistory()
		this.updateTable()
		this.coreSrvc.notifySrvc.notify('success', 'Report Deleted', 'The selected report has been deleted.', 3)
	}

	loadData() {
		const list = this.coreSrvc.dbSrvc.reportSrvc.getReportsHistory()
		let filteredList = list.filter((rec) => this.permResolver.canPerformAction(CrudAction.read, rec))

		// If not forceing all reports, then only show mine
		if (!this.viewManager.showAllReports) {
			const myUserId = this.coreSrvc.dbSrvc.settingSrvc.getMyUser().id
			filteredList = filteredList.filter((rec) => rec.supervisor_ids.includes(myUserId))
		}

		// Look for empty reports
		const foundEmpty = list.find((rep) => rep.empty)
		if (foundEmpty) {
			this.hasEmptyReports = true
		} else {
			this.hasEmptyReports = false
		}

		const notCompleted = filteredList.filter((rec) => !rec.completed)
		const completed = filteredList.filter((rec) => !!rec.completed)
		const finalList = [...notCompleted, ...completed]
		log('Final List', finalList)
		this.list = finalList
	}

	reloadTable() {
		log('Reloading table')
		let completedParam = ''
		if (this.viewManager.dayViewDate) {
			completedParam = moment(this.viewManager.dayViewDate).format('YYYY-MM-DD')
		}
		const options = completedParam ? { completed: completedParam } : {}

		this.coreSrvc.dbSrvc.readTable('reports_log', options).then((success) => {
			log('Reading Reports Log')
			this.coreSrvc.zone.run(() => {
				this.updateTable()
			})
		})
	}

	clearSearch(): boolean {
		this.reportsHistoryTable
			.search('')
			.order([[5, 'desc']])
			.draw()
		return false
	}

	public updateTable() {
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadData()
			this.updateColumns()
		})
	}

	private flashMostRecentEntry() {
		// Set row pulse
		if (this.viewManager.pulseFirstRow && !this.viewManager.dayViewDate) {
			this.viewManager.pulseFirstRow = false
			const firstRow = this.list[0]
			if (firstRow) {
				this.setModifiedRowHighlight(firstRow)
			}
		}
	}

	private updateColumns(): void {
		const name = this.coreSrvc.prefSrvc.data.repHisColNameVisible
		const type = this.coreSrvc.prefSrvc.data.repHisColTypeVisible
		const filter = this.coreSrvc.prefSrvc.data.repHisColFilterVisible
		const startDate = this.coreSrvc.prefSrvc.data.repHisColStartVisible
		const endDate = this.coreSrvc.prefSrvc.data.repHisColEndVisible
		const completed = this.coreSrvc.prefSrvc.data.repHisColCompletedVisible
		const data = this.coreSrvc.prefSrvc.data.repHisColDataVisible

		this.reportsHistoryTable.clear()

		GeneralTableHelper.updateColumn(this.reportsHistoryTable, RepHistTableColumnIndex.name, name)
		GeneralTableHelper.updateColumn(this.reportsHistoryTable, RepHistTableColumnIndex.type, type)
		GeneralTableHelper.updateColumn(this.reportsHistoryTable, RepHistTableColumnIndex.filter, filter)
		GeneralTableHelper.updateColumn(this.reportsHistoryTable, RepHistTableColumnIndex.startDate, startDate)
		GeneralTableHelper.updateColumn(this.reportsHistoryTable, RepHistTableColumnIndex.endDate, endDate)
		GeneralTableHelper.updateColumn(this.reportsHistoryTable, RepHistTableColumnIndex.completed, completed)
		GeneralTableHelper.updateColumn(this.reportsHistoryTable, RepHistTableColumnIndex.data, data)

		this.reportsHistoryTable.rows.add(this.list)
		this.reportsHistoryTable.draw(false)
		this.flashMostRecentEntry()
	}

	// private formatExtension(reportType: string): string {
	// 	// === ('QBDesktop' || 'CSVTransactions') ? 'iif' : 'html',
	// 	let type = 'html'
	// 	if (reportType === 'QBDesktop') {
	// 		type = 'iif'
	// 	}
	// 	if (reportType === 'CSVTransactions') {
	// 		type = 'csv'
	// 	}
	// 	return type
	// }

	setModifiedRowHighlight(repHist: ReportHistoryRecord) {
		const idx = this.list.indexOf(repHist)
		const selector = this.reportsHistoryTable.rows(idx).nodes().to$()
		selector.addClass('row-pulse')
		setTimeout(() => {
			selector.removeClass('row-pulse')
		}, 3000)
	}

	// public shouldBumpNote() {
	// 	if (this.showPaging) {
	// 		const width = $(window).width()
	// 		if (width > 780) {
	// 			return true
	// 		}
	// 	}
	// 	return false
	// }

	notifyOperationNotAuthorized() {
		this.coreSrvc.notifySrvc.default('operationNotAuthorized')
	}

	// Manage Day View

	public dayViewDateIconClicked() {
		log('dayviewDateIconCliced')
		if (this.viewManager.dayViewDate) {
			this.viewManager.dayViewDate = null
		} else {
			this.viewManager.dayViewDate = moment().toDate()
		}
		this.reloadTable()
	}

	public moveToPreviousDate() {
		log('moveToPreviousDate')
		const currentDate = this.viewManager.dayViewDate
		if (currentDate) {
			const prevDate = moment(currentDate).subtract(1, 'day').toDate()
			this.viewManager.dayViewDate = prevDate
		}
		this.reloadTable()
	}

	public moveToNextDate() {
		log('moveToNextDate')
		const currentDate = this.viewManager.dayViewDate
		if (currentDate) {
			const prevDate = moment(currentDate).add(1, 'day').toDate()
			this.viewManager.dayViewDate = prevDate
		}
		this.reloadTable()
	}

	// public gotoDayViewDateBtnClicked() {
	// 	log('gotoDayViewDateBtnClicked')
	// }

	// public exitDayViewBtnClicked() {
	// 	log('exitDayViewBtnClicked')
	// }

	public setDayViewDate(date: Date) {
		log('setDayViewDate', event)
		if (date) {
			this.viewManager.dayViewDate = date
		} else {
			this.viewManager.dayViewDate = null
		}
		this.reloadTable()
	}

	public getDayViewDateAsDate(): Date {
		return moment().toDate()
	}

	public showReportInfo(id: number) {
		const repHist = this.coreSrvc.dbSrvc.reportSrvc.getReportHistoryById(id)
		this.viewManager.showReportInfo.next(repHist?.reports_id)
	}

	public showFilterInfo(id: number) {
		const rep = this.coreSrvc.dbSrvc.reportSrvc.getReportHistoryById(id)
		if (rep) {
			const event = new ReportsFilterInfoEvent(rep.name, rep.filter)
			this.viewManager.showFilterInfo.next(event)
		}
	}

	// public toggleShowAllReports() {
	// 	const newSetting = !this.viewManager.showAllReports
	// 	this.viewManager.showAllReports = newSetting
	// 	this.coreSrvc.prefSrvc.data.repShowAllReports = newSetting
	// 	this.coreSrvc.prefSrvc.save()
	// 	this.updateTable()
	// 	if (newSetting) {
	// 		this.coreSrvc.notifySrvc.notify('success', 'All Reports', 'Showing all available reports', 3)
	// 	} else {
	// 		this.coreSrvc.notifySrvc.notify('success', 'Shared Reports', 'Showing only reports explicitly owned by or shared with you.')
	// 	}
	// }

	private initTable() {
		log('Reports: initTable() called')
		const searchText = this.viewManager.searchFilter
		this.coreSrvc.displaySrvc.startSectionLoader()
		this.reportsHistoryTable = $('#reportsHistoryTable').DataTable(<DataTables.Settings>{
			responsive: true,
			processing: true,
			paging: true,
			pageLength: 25,
			lengthMenu: [
				[25, 50, 100, 500],
				[25, 50, 100, 500],
			],
			info: true,
			select: false,
			search: { search: `${searchText}`, smart: true },
			searching: true,
			searchDelay: 500,
			fixedHeader: DataTablesHelper.fixedHeader,
			// scrollY: '200px',
			// scrollCollapse: true,
			autoWidth: false,
			data: this.list,
			order: this.defaultSortOrder,
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{ responsivePriority: 1, targets: RepHistTableColumnIndex.name },
				{ responsivePriority: 2, targets: RepHistTableColumnIndex.completed },
				{ responsivePriority: 3, targets: RepHistTableColumnIndex.actions },
				{
					// Name
					targets: RepHistTableColumnIndex.name,
					visible: this.prefsService.data.repHisColNameVisible,
					searchable: true,
					title: 'Report Name',
					data: null,
					render: (rec: ReportHistoryRecord, t, f, m) => {
						return `
						<div class="dtr-control-content">
							<div class="table-name">${rec.name}</div>
						</div>`
					},
				},
				{
					// Type
					targets: RepHistTableColumnIndex.type,
					visible: this.prefsService.data.repHisColTypeVisible,
					searchable: true,
					title: 'Type',
					data: null,
					render: (d, t, f, m) => {
						const type = this.coreSrvc.dbSrvc.reportSrvc.getReportTypeName(d.java_class)
						return `<div class="table-text">${type}</div>`
					},
				},
				{
					// Filter
					targets: RepHistTableColumnIndex.filter,
					visible: this.prefsService.data.repHisColFilterVisible,
					searchable: true,
					title: 'Filter',
					data: null,
					render: (repHist: ReportHistoryRecord, t, f, m) => {
						return ReportsTableFormatter.reportFilterIcon(repHist, this.bridgeName)
					},
				},
				{
					// Start
					targets: RepHistTableColumnIndex.startDate,
					visible: this.prefsService.data.repHisColStartVisible,
					searchable: true,
					title: 'Start',
					data: 'start',
				},
				{
					// End
					targets: RepHistTableColumnIndex.endDate,
					visible: this.prefsService.data.repHisColEndVisible,
					searchable: true,
					title: 'End',
					data: 'end',
				},
				{
					// Completed
					targets: RepHistTableColumnIndex.completed,
					visible: this.prefsService.data.repHisColCompletedVisible,
					searchable: false,
					title: 'Completed',
					data: null,
					render: (repHist: ReportHistoryRecord, type, f, m) => {
						const errorMsg = repHist.error
							? `<div style="font-weight:600;color:firebrick;">Error Encountered</div>` // <div style="font-size:0.9rem;color:gray;">Please contact support</div>`
							: ''
						const sortData = repHist.completed
						if (type === 'sort') {
							return sortData ? sortData : '2030-12-31T23:59:59.9999Z'
						}
						// const sortString = DateTimeHelper.trimMsFromDateString(repHist.completed) || '_'
						const completed = ReportHelper.displayLastRun(repHist.completed)
						if (!sortData) {
							return `
							<div style="line-height:1em;"><i class="fas fa-loader fa-spin"></i> Running Report</div>
							<div class="link-text" onclick="${this.bridgeName}.reloadTable()" style="margin-left: 20px;">click to refresh</div>`
						}

						const userId = repHist.ran_by
						const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(userId)
						const runBy = user ? user.first_name + ' ' + user.last_name : 'Unknown User'
						const runByHtml = user
							? `<div style="font-weight:600"><span style="color:chocolate">by</span> <span style="color:#135c94">${runBy}</span></div>`
							: ''

						return `
						<div>${completed}</div>
						${runByHtml}${errorMsg}`
					},
				},
				{
					// Data
					targets: RepHistTableColumnIndex.data,
					visible: this.prefsService.data.repHisColDataVisible,
					searchable: false,
					title: 'Data',
					data: null,
					render: (repHist: ReportHistoryRecord, t, f, m) => {
						const icon =
							repHist.empty || repHist.error
								? '<span style="color: firebrick;"><strong>X</strong></span>'
								: '<span style="color: green;"><i class="fa fa-check" aria-hidden="true"></i></span>'
						return `<span style="margin-left:15px">${icon}</span>`
					},
				},
				{
					// Actions
					targets: RepHistTableColumnIndex.actions,
					visible: true,
					searchable: false,
					orderable: false,
					title: 'Actions',
					data: null,
					className: 'row-actions',
					render: (repHist: ReportHistoryRecord, t, f, m) => {
						const recordId = repHist.id
						// const isMyRecord = false

						// const canAccessRun = this.canPerformAction(CrudAction.read, isMyRecord)
						const canRead = this.permResolver.canPerformAction(CrudAction.read, repHist)
						const runReportLink = TableActionFormatter.runReportLink(this.bridgeName, 'runReport', recordId, canRead)

						// const canAccessView = this.canPerformAction(CrudAction.read, isMyRecord)
						const canView = canRead && !!repHist.completed
						const viewLink = TableActionFormatter.viewOrDownloadReport(this.bridgeName, 'viewOrDownloadReport', repHist, canView, 'REPORTSLOG')

						// const canAccessDelete = this.canPerformAction(CrudAction.delete, isMyRecord)
						const canAccessDelete = this.permResolver.canPerformAction(CrudAction.delete, repHist)
						const deleteLink = TableActionFormatter.deleteLink(this.bridgeName, 'deleteRecord', recordId, canAccessDelete)

						const parentLink = TableActionFormatter.repHistParentLink(this.bridgeName, 'showReportInfo', recordId, canRead)

						return `<span class="act-ico-wrap">${runReportLink}${viewLink}${deleteLink}${parentLink}</span>`
					},
				},
				{
					targets: 6,
					visible: false,
					searchable: false,
					title: 'ID',
					data: 'id',
				},
			],
			drawCallback: (settings) => {
				// log('drawCallback')
				this.reportsHistoryTable?.columns.adjust().responsive.recalc()
				this.coreSrvc.displaySrvc.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
			},
			createdRow: (row, data, dataIndex) => {
				if (data['empty'] === true) {
					$(row).addClass('rep-history-disabled')
				}
				if (data['error']) {
					$(row).addClass('row-has-error')
				}
			},
		})
		$('#reportsHistoryTable').on('search.dt', () => {
			const input = this.reportsHistoryTable.search() || ''
			this.viewManager.searchFilter = input
		})

		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter('reportsHistoryTable', this.reportsHistoryTable)

		// Flash first row
		this.flashMostRecentEntry()

		// this.reportsHistoryTable['fixedHeader'].adjust();
	}
}
