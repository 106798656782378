import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { environment } from '@env/environment'

import { DateTimeHelper, log } from '@app/helpers'
import {
	DataAccessRequest,
	DialogManager,
	HelpDialogMessage,
	TourWrapper,
	TourEditDialogData,
	TourRecord,
	TourType,
	TourWaypoint,
	WaypointViewModel,
} from '@app/models'
import { CoreService } from '@app/services'
import { SelectItem } from 'primeng/api'

import moment from 'moment-timezone'
import { v4 as uuid } from 'uuid'

import { TourScheduleViewComponent } from './tour-schedule-view/tour-schedule-view.component'

class TourEditLinkedJobAlertItem {
	jobName: string
}
class TourEditLinkedJobsAlert {
	linkedJobsCount = 0
	linkedJobs: Array<TourEditLinkedJobAlertItem> = []
}

@Component({
    selector: 'app-tour-edit',
    templateUrl: './tour-edit.component.html',
    styleUrls: ['./tour-edit.component.scss'],
    standalone: false
})
export class TourEditComponent implements OnInit, AfterViewInit, AfterContentInit {
	public environment = environment

	public isInternalUser = false
	public isDataLoaded = false
	public isNew = false
	public tour: TourWrapper
	public tourRecord: TourRecord
	public waypoints: Array<WaypointViewModel> = []

	public alert = new TourEditLinkedJobsAlert()
	public linkedJobOptions: SelectItem[] = []
	public tourTypeOptions: Array<SelectItem> = []

	public tourForm: UntypedFormGroup

	public confirmDelete = false
	public showRepeatWizard = false

	// public hourBased = new HourBasedSetupManager()

	// public offsetAdjustment: number = 0 // DEPRECATED 20240420
	// public previousStartOffset: Array<number> = [] // DEPRECATED 20240420

	public useRepeatIntervalPicker = false
	public enableValidation = true
	public freezeOffsetTimes = true
	public freeFormEditMode = false

	public wpInputHasFocus = false
	public restIntervalInputHasFocus = false

	public currentWaypointVm: WaypointViewModel = null
	public timezone = 'UTC'

	public profile = {
		name: '',
		notifyEmp: false,
		notifySup: false,
	}

	public enableAdvancedTours = false
	public enableAdvancedRepeatTours = false

	public lastTourDurationInMin = 0
	public repeatIntervalCleared = false // This is used when a configuration change causes an invalid repeat interval

	@Input() dialogManager = new DialogManager()
	@Output() recordUpdated = new EventEmitter<TourRecord>()
	@ViewChild('scheduleView') scheduleView: TourScheduleViewComponent

	constructor(
		private coreSrvc: CoreService,
		private cd: ChangeDetectorRef,
	) {
		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()
		this.enableAdvancedTours = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs().jobEnableAdvancedTours
		this.enableAdvancedRepeatTours = true // this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs().jobEnableAdvancedRepeatTours
		this.useRepeatIntervalPicker = true // this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs().jobEnableTourRepeatIntervalPicker

		this.tourTypeOptions = [
			// { label: 'Repeating Checkpoints', value: 'UNSTRUCTURED' },
			{ label: 'Shift-timed Tour', value: 'STRUCTURED' },
			{ label: 'Daily Tour', value: 'STRUCTURED_DAY_START' },
		]
	}

	get accessedFromTourTable(): boolean {
		return this.dialogManager.dialogData?.source === 'TOUR_TABLE'
	}

	get waypointGpsJobOptions(): Array<SelectItem> {
		if (this.accessedFromTourTable) {
			const linkedJobOptions = this.linkedJobOptions.filter((ljo) => this.tour?.jobIds.includes(ljo.value))
			// linkedJobOptions.unshift({ label: 'Set GPS from Linked Job', value: null })
			return linkedJobOptions
		} else {
			return []
		}
	}

	get tourTypeLabel(): string {
		if (!this.tour) return ''
		switch (this.tour.type) {
			case 'UNSTRUCTURED':
				return 'Repeating Checkpoints'
			case 'STRUCTURED':
				return 'Shift-timed Tour'
			case 'STRUCTURED_HOUR_START':
				return 'Hourly Tour'
			case 'STRUCTURED_DAY_START':
				return 'Daily Tour'
			default:
				return ''
		}
	}

	get mockTours() {
		return this.coreSrvc.dbSrvc.jobSrvc.mockTours
	}

	get hasRepeatError() {
		for (const wpVm of this.waypoints) {
			if (wpVm.hasRepeatIssue) return true
		}
		return false
	}

	get hasOrderError() {
		for (const wpVm of this.waypoints) {
			if (wpVm.hasOrderIssue) return true
		}
		return false
	}

	get tourRepeatDurationInMin(): number {
		const firstWp = this.waypoints[0]
		const lastWp = this.waypoints[this.waypoints.length - 1]
		let result: number = 0

		if (!firstWp && !lastWp) return 0
		if (!this.tour.repeatInterval) return 0

		if (!lastWp) {
			const oneWpDur = (this.tour.repeatInterval ?? 0) - firstWp.twp.startOffset
			result = oneWpDur
		} else {
			const tourDuration = lastWp.twp.startOffset - firstWp.twp.startOffset + this.tour.repeatInterval
			result = tourDuration
		}
		return result
	}

	get tourRepeatDurationInMInStr(): string {
		return this.tourRepeatDurationInMInStrFormatter(this.tourRepeatDurationInMin)
	}

	get tourDurationInMin(): number {
		if (this.tour.type === 'STRUCTURED_HOUR_START') return 60

		const firstWp = this.waypoints[0]
		const lastWp = this.waypoints[this.waypoints.length - 1]
		if (!firstWp || !lastWp) return 0
		return lastWp.twp.startOffset - firstWp.twp.startOffset
	}

	get tourDurationInMinStr(): string {
		return DateTimeHelper.formatMinutestAsHrsAndMinutes(this.tourDurationInMin)
	}

	get isFormValid(): boolean {
		if (!this.tour) return false
		const isRepeatIntervalValid = this.tour.repeatInterval ? this.tour.repeatInterval > 0 : true
		// const isUnstructuredValid = this.tour.type === 'UNSTRUCTURED' ? this.tour.repeatInterval > 0 : true
		return !this.hasOrderError && !this.hasRepeatError && !!this.tour.description && isRepeatIntervalValid // && isUnstructuredValid
	}

	ngOnInit() {
		const dialogData = this.dialogManager.dialogData as TourEditDialogData
		const action = dialogData.action
		const jobIdFromDialogData = this.dialogManager.dialogData.jobId
		const tourIdFromDialogData = this.dialogManager.dialogData.tourId

		if (action === 'new') {
			log('Creating a new tour')
			this.tour = new TourWrapper()
			this.tour.type = null
			this.tour.jobId = dialogData.jobId
			this.tour.description = 'Default Tour'
			this.isNew = true
			this.setupLinkedJobOptions()
			this.isDataLoaded = true
		}

		if (action === 'edit' || action === 'clone') {
			const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobIdFromDialogData)
			const tourId = job?.tour_id ?? tourIdFromDialogData
			const request = new DataAccessRequest('tour', null, { id: tourId })
			this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
				const tourRecord: TourRecord = result.data[0]
				log('Tour Record', tourRecord)

				// If we're cloning the record then adjust properties as needed
				if (action === 'clone') {
					const name = tourRecord.description ? tourRecord.description : '< no name >'
					tourRecord.id = null
					tourRecord.description = name + ' (Copy)'
					tourRecord.job_ids = []

					// Create new waypoint UUIDs
					const waypoints = tourRecord.waypoints_json ? JSON.parse(tourRecord.waypoints_json) : []
					waypoints.forEach((wp) => {
						wp.uuid = uuid()
					})
					tourRecord.waypoints_json = JSON.stringify(waypoints)
				}

				this.tour = new TourWrapper(tourRecord)
				this.waypoints = this.tour.waypoints.map((wp) => new WaypointViewModel(wp))

				this.needsValidationCheck()
				this.updateDialogHeader()
				this.setupLinkedJobOptions()
				this.isDataLoaded = true
				this.cd.markForCheck()
				return
			})
		}
		this.setupTimezone()
		this.setupNotificationProfile()
	}

	ngAfterViewInit(): void {
		// DEPRECATED 20240615 - Moved to ngAfterContentInit
		// setTimeout(() => {
		// 	this.setupDialogManager()
		// 	this.lastTourDurationInMin = this.tourRepeatDurationInMin
		// 	// this.updateWaypointClockTimesForHourBased()
		// }, 275)
	}

	ngAfterContentInit(): void {
		this.setupDialogManager()
		this.lastTourDurationInMin = this.tourRepeatDurationInMin
	}

	private setupTimezone() {
		const jobId = this.dialogManager.dialogData.jobId
		if (jobId) {
			const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)
			const siteId = job.location_id
			const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(siteId)
			const tzId = site.timezone_id
			this.timezone = this.coreSrvc.dbSrvc.settingSrvc.getTimezoneZoneNameForId(tzId)
		} else {
			this.timezone = this.coreSrvc.dbSrvc.settingSrvc.getCompany().timezone
		}
	}

	private setupNotificationProfile() {
		const jobId = this.dialogManager.dialogData.jobId
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)
		if (job) {
			const npId = job.notification_profile_id
			const profile = this.coreSrvc.dbSrvc.npSrvc.getProfileById(npId)
			if (profile) {
				this.profile.name = profile.name
				this.profile.notifyEmp = !!profile.call_employee_checkpoint
				this.profile.notifySup =
					!!profile.call_supervisor_checkpoint || !!profile.sms_supervisor_checkpoint || !!profile.email_supervisor_checkpoint
			}
		}
	}

	private setupLinkedJobOptions() {
		const includeIds = this.tour.jobIds || []
		const options = this.coreSrvc.dbSrvc.jobSrvc.getJobDropdown(this.coreSrvc.dbSrvc, false, false, false, [])
		const filteredOptions = options.filter((jdd) => jdd.data.active || includeIds.includes(jdd.data.id))
		// filteredOptions.forEach((opt) => {
		// 	const jId = opt.data?.id
		// 	const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jId)
		// 	if (job) {
		// 		const tour = this.coreSrvc.dbSrvc.tourSrvc.getTourRecordById(job.tour_id)
		// 		if (tour) {
		// 			const tourName = tour.description || '< no name >'
		// 			opt.label = opt.label + ` (${tourName})`
		// 		}
		// 	}
		// })
		this.linkedJobOptions = filteredOptions
	}

	public linkedJobsChanged(ids: Array<number>) {
		this.tour.jobIds = ids
		this.updateLinkedJobs()
	}

	private updateLinkedJobs() {
		const linkedJobs = []
		const jobIds = this.tour.jobIds ?? []
		const jobs = jobIds.map((id) => this.coreSrvc.dbSrvc.jobSrvc.getJobById(id)).filter((job) => !!job)
		for (const job of jobs) {
			const item = new TourEditLinkedJobAlertItem()
			item.jobName = job.description
			linkedJobs.push(item)
		}
		this.alert.linkedJobs = linkedJobs
		this.alert.linkedJobsCount = jobs.length
	}

	public validateInt(prop: string) {
		const value = +this.tour[prop]
		log('validate', value)
		if (!value) {
			switch (prop) {
				case 'startOffset':
					this.tour[prop] = null
					break
				default:
					this.tour[prop] = null
			}
		} else {
			this.tour[prop] = parseInt(this.tour[prop], 10)
		}
		this.needsValidationCheck()
	}

	// DEPRECATED 20240420
	// public undoOffsetAdjustment() {
	// 	const value = this.previousStartOffset.pop()
	// 	const wpOneOffset = this.waypoints[0].twp.startOffset || 0
	// 	const diff = value - wpOneOffset
	// 	for (const wpVm of this.waypoints) {
	// 		wpVm.twp.startOffset += diff
	// 	}
	// }

	// DEPRECATED 20240420
	// public adjustStart() {
	// 	// First check for issues
	// 	this.needsValidationCheck()
	// 	for (const wpVm of this.waypoints) {
	// 		if (wpVm.hasOrderIssue) {
	// 			this.coreSrvc.notifySrvc.notify(
	// 				'error',
	// 				'Offset Issuess',
	// 				'Before this action can be performed, please maske sure all checkpoint times are valid, in order, and that the repeat interval (if set) is greater than the last checkpoint time.',
	// 			)
	// 			return
	// 		}
	// 	}

	// 	const value = +this.offsetAdjustment
	// 	if (value < 0) {
	// 		this.coreSrvc.notifySrvc.notify('error', 'Invalid Start', 'Negative start times are not supported.')
	// 		return
	// 	}

	// 	const wpOneOffset = this.waypoints[0].twp.startOffset || 0

	// 	const diff = value - wpOneOffset
	// 	for (const wpVm of this.waypoints) {
	// 		wpVm.twp.startOffset += diff
	// 	}
	// 	this.previousStartOffset.push(wpOneOffset)
	// 	this.offsetAdjustment = 0
	// }

	// DEPRECATED 20240420
	// public adjustWith(sign: 'MINUS' | 'PLUS') {
	// 	const value = +this.offsetAdjustment
	// 	for (const wpVm of this.waypoints) {
	// 		if (sign === 'MINUS') {
	// 			wpVm.twp.startOffset -= value
	// 		} else {
	// 			wpVm.twp.startOffset += value
	// 		}
	// 	}
	// 	this.offsetAdjustment = 0
	// 	this.needsValidationCheck()
	// }

	// DEPRECATED 20240420
	// public adjustOffsets() {
	// 	const value = +this.offsetAdjustment
	// 	for (const wpVm of this.waypoints) {
	// 		wpVm.twp.startOffset += value
	// 	}
	// 	this.offsetAdjustment = 0
	// 	this.needsValidationCheck()
	// }

	private setupDialogManager() {
		this.dialogManager.submitBtnAction = () => this.submit()
		this.dialogManager.backBtnAction = () => this.backBtnClicked()
		this.dialogManager.canSubmit = () => this.isFormValid
		setTimeout(() => {
			this.updateDialogHeader()
		}, 125)
	}

	private updateDialogHeader() {
		let label = ''
		if (!this.tour?.type) {
			label = 'Manage Tour'
		} else {
			// const prefix = this.dialogManager.dialogData.action === 'new' ? 'New' : 'Edit'
			const tourType = this.tourTypeLabel
			label = 'Edit ' + tourType
		}
		this.dialogManager.headerLabel = label
	}

	private closeAllCards() {
		for (const wpVm of this.waypoints) {
			wpVm.isEditing = false
		}
	}

	private submit() {
		// If no tour is selected and on main screen then delete
		if (!this.tour.description) {
			this.coreSrvc.notifySrvc.notify('error', 'No Name', 'Please enter a name for this tour.', 4)
			return
		}
		if (this.tour.type === 'UNSTRUCTURED' && !this.tour.repeatInterval) {
			this.coreSrvc.notifySrvc.notify('error', 'Missing Interval', 'Please specify how often you expect checkpoints to be submitted.', 6)
			return
		}
		// if (!this.tour.type) {
		// 	this.conrirmDeleteBtnClicked()
		// 	return
		// }

		// // If all it's a basic tour without repeating interval then just delete it
		// if (this.tour.id && this.tour.type === 'UNSTRUCTURED' && !this.tour.repeatInterval) {
		// 	this.conrirmDeleteBtnClicked()
		// 	return
		// }

		// // If it's an advanced tour without any waypoints then just delete it
		// if (this.tour.id && this.tour.type === 'STRUCTURED' && this.waypoints.length === 0) {
		// 	this.conrirmDeleteBtnClicked()
		// 	return
		// }

		// // If it's a timed tour without any waypoints then just delete it
		// if (this.tour.id && this.tour.type === 'STRUCTURED_DAY_START' && this.waypoints.length === 0) {
		// 	this.conrirmDeleteBtnClicked()
		// 	return
		// }

		this.enableValidation = true
		this.freezeOffsetTimes = true
		this.freeFormEditMode = false
		this.needsValidationCheck()
		if (!this.doesFormValidate()) return

		const jobId = this.dialogManager.dialogData.jobId
		const record = this.makeUpdateRecord()
		log('Record to submit', record)
		// return
		// If the record as an ID then it's an update, otherwise it's an insert
		if (!record.id) {
			const request = new DataAccessRequest('tour', 'insert', record)
			this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
				log('Tour Insert', result)
				this.recordUpdated.next(record)
			})
		} else {
			const request = new DataAccessRequest('tour', 'update', record)
			this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
				log('Tour Update', result)
				this.recordUpdated.next(record)
			})
		}
	}

	private makeUpdateRecord() {
		// Put current waypoint view models back into to waypoints
		this.tour.waypoints = this.waypoints.map((wpVm) => wpVm.twp)
		const tour = this.tour.buildTourRecord(this.tourRecord)

		tour.description = this.tour.description
		// const jobId = tour.job_id // // DEPRECATED 20240514
		// DEPRECATED 20240514
		// if (this.accessedFromTourTable) {
		// 	tour.description = this.tour.description
		// } else {
		// 	const description = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)?.description
		// 	tour.description = description ? `${description} Tour` : 'No Linked Job'
		// }

		if (tour.type === 'UNSTRUCTURED') tour.waypoints_json = '[]'
		return tour
	}

	// public getCurrentUpdateDescription(): string {
	// 	const interval = this.tourRepeatDurationInMin
	// 	if (!interval) return 'Non-repeating tour'
	// 	switch (this.tour.type) {
	// 		case 'UNSTRUCTURED':
	// 			return `Repeats every ${interval} miniutes`
	// 		case 'STRUCTURED':
	// 			return `Repeats after ${interval} miniute rest`
	// 		case 'STRUCTURED_HOUR_START':
	// 			return `Repeats every 60 minutes`
	// 		case 'STRUCTURED_DAY_START':
	// 			return `Repeats every ${interval} minutes`
	// 		default:
	// 			return ''
	// 	}
	// }

	public clearAllValidationIssues() {
		for (const wpVm of this.waypoints) {
			wpVm.hasOrderIssue = false
			wpVm.hasRepeatIssue = false
		}
	}

	public validationCheckboxChanged() {
		log('Validation changed', this.enableValidation)
		if (this.enableValidation) {
			this.needsValidationCheck(true)
		} else {
			this.clearAllValidationIssues()
		}
	}

	public needsValidationCheck(forced: boolean = false): void {
		log('needsValidationCheckCalled', forced)

		// If validation is not being forced, then check if it's enabled and exit if it is not.
		if ((!forced && !this.enableValidation) || this.tour.type === 'UNSTRUCTURED') return

		const wpVms = this.waypoints
		// Reset all issues
		for (const wpVm of wpVms) {
			wpVm.hasOrderIssue = false
			wpVm.hasRepeatIssue = false
		}
		// Recheck all waypoints
		for (let i = 0; i < wpVms.length; i++) {
			const wpVm = wpVms[i]

			// Check if the current item's startOffset is greater than the next item's startOffset
			if (i < wpVms.length - 1 && wpVm.twp.startOffset > wpVms[i + 1].twp.startOffset) {
				wpVm.hasOrderIssue = true
			}

			// Check if the current item's startOffset is less than the previous item's startOffset
			if (i > 0 && wpVm.twp.startOffset < wpVms[i - 1].twp.startOffset) {
				wpVm.hasOrderIssue = true
			}

			// If the tour type is hour based, we need to check that the waypoint offset stays in bounds
			if (this.tour.type === 'STRUCTURED_HOUR_START') {
				const offset = wpVm.twp.startOffset
				const maxLength = 59
				if (offset > maxLength) {
					wpVm.twp.startOffset = maxLength
					this.coreSrvc.notifySrvc.notify(
						'info',
						'Invalid Offset',
						`The checkpoint offset must be less than the maximum tour length of ${maxLength} minutes.`,
						7,
					)
				}
			}
		}
		this.updateRepeatInterval()
	}

	public updateRepeatInterval() {
		// Guard against times we don't want to update the repeat interval
		if (this.tour.type === 'UNSTRUCTURED' || this.tour.type === 'STRUCTURED') return
		if (!this.useRepeatIntervalPicker || !this.tour.repeatInterval || !this.lastTourDurationInMin) return

		const changeDiff = this.tourRepeatDurationInMin - this.lastTourDurationInMin
		log('changeDiff', changeDiff)
		if (changeDiff) {
			this.tour.repeatInterval -= changeDiff
			this.lastTourDurationInMin = this.tourRepeatDurationInMin

			if (this.tour.repeatInterval === 0) {
				this.repeatIntervalCleared = true
				this.coreSrvc.notifySrvc.notify(
					'error',
					'Repeat Interval Cleared',
					'A configuration change invalidated the repeat interval. Please set a new one if you want this tour to repeat.',
				)
			}
		}
		setTimeout(() => {
			this.scheduleView?.updateTourList()
		}, 100)
	}

	public doesFormValidate(): boolean {
		const wpVms = this.waypoints
		if (this.tour.type === 'STRUCTURED' || this.tour.type === 'STRUCTURED_DAY_START') {
			for (const wpVm of wpVms) {
				// log('waypoint offset', wpVm.twp.startOffset)
				if (wpVm.twp.startOffset < 0) {
					this.coreSrvc.notifySrvc.notify('error', 'Invalid Offset', 'Checkpoints may not have a negative offset time.', 8)
					return false
				}
				// Check for proper order time offsets
				if (wpVm.hasOrderIssue) {
					this.coreSrvc.notifySrvc.notify('error', 'Invalid Offset', 'Checkpoint offset times must be in order.', 8)
					return false
				}
				if (this.tour.repeatInterval < 0) {
					this.coreSrvc.notifySrvc.notify('error', 'Invalid Repeat Interval', 'This repeat interval must be greater than the tour length.', 8)
					return false
				}
			}
		}
		return true
	}

	public moveWpVm(wpVm: WaypointViewModel, direction: 'UP' | 'DOWN') {
		const currentIndex = this.waypoints.indexOf(wpVm)
		// Check if item is at beginning or end of list and prevent movement out of range
		const canMove = direction === 'UP' ? currentIndex > 0 : currentIndex < this.waypoints.length - 1
		if (canMove) {
			const newIndex = direction === 'UP' ? currentIndex - 1 : currentIndex + 1
			const newWpVm = this.waypoints[newIndex]

			// Swap indexes
			;[this.waypoints[currentIndex], this.waypoints[newIndex]] = [this.waypoints[newIndex], this.waypoints[currentIndex]]

			// Adjust times if offsets not frozen
			if (this.freezeOffsetTimes) {
				// Adjust offsets
				;[this.waypoints[currentIndex].twp.startOffset, this.waypoints[newIndex].twp.startOffset] = [
					this.waypoints[newIndex].twp.startOffset,
					this.waypoints[currentIndex].twp.startOffset,
				]

				// Adjust clock times
				;[wpVm.clockTime, newWpVm.clockTime] = [newWpVm.clockTime, wpVm.clockTime]
			}

			// Blink the rows
			setTimeout(() => {
				const elem = `#wpFlash_${newWpVm.twp.uuid}`
				this.coreSrvc.displaySrvc.bringIntoViewBySelector(elem)
			}, 50)

			this.needsValidationCheck()

			// Blink the rows
			if (!this.freeFormEditMode) {
				setTimeout(() => {
					const elem = `#wpFlash_${newWpVm.twp.uuid}`
					this.coreSrvc.displaySrvc.fadeOutIn(elem, 'fade-out-in', 1500)
				}, 50)
			}
		}
	}

	public moveUp(wpVm: WaypointViewModel) {
		this.moveWpVm(wpVm, 'UP')
	}
	public moveDown(wpVm: WaypointViewModel) {
		this.moveWpVm(wpVm, 'DOWN')
	}

	public addNewWaypoint(offset?: number) {
		this.needsValidationCheck()
		if (this.hasOrderError || this.hasRepeatError) {
			this.coreSrvc.notifySrvc.notify('error', 'Issues Found', `Please correct any issues before adding a new checkpoint.`, 2)
			return
		}
		const jobId = this.tour.jobId
		const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)
		const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(job?.location_id)
		const twp = new TourWaypoint()

		// Set to last checkpoint time
		const wpOneOffset = offset ? offset : this.waypoints[this.waypoints.length - 1]?.twp.startOffset
		twp.startOffset = wpOneOffset || 0

		twp.latitude = site?.geo_latitude ?? null
		twp.longitude = site?.geo_longitude ?? null
		const wpVm = new WaypointViewModel(twp)
		wpVm.updateClockTimeFromOffset()
		this.waypoints.push(wpVm)
		this.isNew = false
	}

	public setupExampleWaypoints() {
		let offset = 15
		for (let i = 0; i < 3; i++) {
			this.addNewWaypoint(offset)
			offset += 15
		}
	}

	public editWaypoint(wpVm: WaypointViewModel) {
		this.dialogManager.saveScrollPosition('editWaypoint')
		this.dialogManager.isBackBtnVisible = true
		this.dialogManager.isCancelBtnVisble = false
		this.dialogManager.isSubmitBtnVisible = false
		this.currentWaypointVm = wpVm
		setTimeout(() => {
			this.dialogManager.scrollToTop()
		}, 125)
	}

	public cloneWaypoint(wpVm: WaypointViewModel) {
		const lastWaypointOffset = this.waypoints[this.waypoints.length - 1]?.twp.startOffset
		const newWpVm = wpVm.clone()
		newWpVm.twp.startOffset = lastWaypointOffset
		this.waypoints.push(newWpVm)
		setTimeout(() => {
			const selector = `#wpFlash_${newWpVm.twp.uuid}`
			this.coreSrvc.displaySrvc.bringIntoViewBySelector(selector)
			setTimeout(() => {
				this.coreSrvc.displaySrvc.fadeOutIn(selector, 'fade-out-in', 1500)
			}, 750)
		}, 125)
	}

	private backBtnClicked() {
		this.closeAllCards()
		this.dialogManager.isBackBtnVisible = false
		this.dialogManager.isCancelBtnVisble = true
		this.dialogManager.isSubmitBtnVisible = true
		this.currentWaypointVm = null
		setTimeout(() => {
			this.dialogManager.restoreScrollPosition('editWaypoint')
		}, 25)
	}

	public deleteWaypoint(wpVm: WaypointViewModel) {
		const newList = this.waypoints.filter((item) => item !== wpVm)
		this.waypoints = newList
		if (this.waypoints.length === 0) this.clearTourAndStartOver()

		this.updateRepeatInterval()
		this.needsValidationCheck(true)
	}

	public wpOffsetInputFocused(wpVm: WaypointViewModel) {
		this.wpInputHasFocus = true
	}
	public wpOffsetInputBlurred(wpVm: WaypointViewModel) {
		wpVm.updateClockTimeFromOffset()
		this.wpInputHasFocus = false
	}

	public showDeleteConfirmation() {
		this.dialogManager.headerLabel = 'Confirm Delete'
		this.dialogManager.isCancelBtnVisble = false
		this.dialogManager.isSubmitBtnVisible = false
		this.confirmDelete = true
	}
	public cancelDeleteConfirmation() {
		this.dialogManager.headerLabel = 'Edit Tour'
		this.dialogManager.isCancelBtnVisble = true
		this.dialogManager.isSubmitBtnVisible = true
		this.confirmDelete = false
	}
	public conrirmDeleteBtnClicked() {
		this.isDataLoaded = false
		this.confirmDelete = false
		const request = new DataAccessRequest('tour', 'delete', { id: this.tour.id })
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			this.recordUpdated.next(null)
		})
	}
	public clearTourAndStartOver() {
		this.waypoints = []
		this.tour.waypoints = []

		this.tour.type = null
		this.tour.repeatInterval = null

		this.lastTourDurationInMin = 0

		this.updateDialogHeader()
	}

	public showManual() {
		window.open('https://www.manula.com/manuals/telephone-timesheets/telephone-timesheets-user-guide/', '_blank')
	}

	public setCheckpointMethod(type: TourType) {
		if (!this.enableAdvancedTours && (type === 'STRUCTURED' || type === 'STRUCTURED_DAY_START')) return
		log('setCheckpointMethod', type)
		if ((type === 'STRUCTURED' || type === 'STRUCTURED_DAY_START') && this.waypoints.length === 0) {
			this.tour.repeatInterval = null
			this.setupExampleWaypoints()
		}
		if (type === 'STRUCTURED_HOUR_START') {
			this.tour.repeatInterval = 60
			this.setupExampleWaypoints()
		}
		this.tour.type = type
		this.updateDialogHeader()
	}

	public tourTypeChanged() {
		this.scheduleView?.setTourStartTimeFromPicker(null)
		this.scheduleView?.setTourEndTimeFromPicker(null)
		for (const wpVm of this.waypoints) {
			wpVm.updateClockTimeFromOffset()
		}
	}

	public repeatWizard = {
		hourOptions: repeatWizardHrOptions,
		selectedHours: 0,
		minuteOptions: repeatWizardMinOptions,
		selectedMinutes: 0,
		tourDurInMinStr: '0 minutes',
	}

	public startRepeatWizard() {
		this.repeatIntervalCleared = false // We assume they saw the error.

		this.dialogManager.saveScrollPosition('tourEdit')
		this.dialogManager.isSubmitBtnVisible = false
		this.dialogManager.isCancelBtnVisble = false

		const firstWp = this.waypoints[0]
		const lastWp = this.waypoints[this.waypoints.length - 1]

		const minimumRepeatInterval = this.tourRepeatDurationInMin || 0 // || this.tourDurationInMin + 1
		const repeatIntervalComps = this.convertMinutesToHoursAndMinutes(minimumRepeatInterval)

		this.repeatWizard.selectedHours = repeatIntervalComps.hours * 60 // convert to minutes for use by dropdown
		this.repeatWizard.selectedMinutes = repeatIntervalComps.minutes

		this.repeatWizard.tourDurInMinStr =
			DateTimeHelper.formatMinutestAsHrsAndMinutes(lastWp.twp.startOffset - firstWp.twp.startOffset) || '0 minutes'

		this.showRepeatWizard = true
	}

	public cancelRepeatWizard() {
		this.dialogManager.isCancelBtnVisble = true
		this.dialogManager.isSubmitBtnVisible = true
		this.restoreScrollPosition()
		this.showRepeatWizard = false
		this.scheduleView?.updateTourList()
	}

	public calculateTourRepeatIntervalFromPicker() {
		const firstWp = this.waypoints[0]
		const lastWp = this.waypoints[this.waypoints.length - 1]

		const tourDuration = lastWp.twp.startOffset - firstWp.twp.startOffset
		const durResult = DateTimeHelper.formatMinutestAsHrsAndMinutes(tourDuration) || '0 minutes'
		const repeatDuration = this.repeatWizard.selectedHours + this.repeatWizard.selectedMinutes

		if (repeatDuration <= tourDuration) {
			this.coreSrvc.notifySrvc.notify('error', 'Invalid Length', `If you use a repeat interval, it must be greater than ${durResult}.`)
			return
		}

		this.tour.repeatInterval = repeatDuration - tourDuration
		this.lastTourDurationInMin = this.tourRepeatDurationInMin

		this.dialogManager.isSubmitBtnVisible = true
		this.dialogManager.isCancelBtnVisble = true
		this.restoreScrollPosition()
		this.showRepeatWizard = false
		this.needsValidationCheck()
	}

	setRestIntervalInputFocused() {
		this.restIntervalInputHasFocus = true
	}

	setRestIntervalInputBlurred() {
		this.restIntervalInputHasFocus = false
	}

	// updateTourLengthInterval(minutes: number) {
	// 	this.tour.tourLength = minutes
	// 	// this.needsValidationCheck()
	// }

	// updateTourRepeatInterval(minutes: number) {
	// 	this.tour.repeatInterval = minutes
	// 	// this.needsValidationCheck()
	// }

	// validateHourBasedRadioBtns() {
	// 	const tourLength = this.tour.tourLength
	// 	if (tourLength === 60) this.tour.repeatInterval = 60
	// }

	// setTourStartTimeFromPicker(date: Date) {
	// 	log('EVENT", event')
	// 	const timeStr = '2020-01-01T' + moment(date).format('HH:mm')
	// 	const offsetTime = moment(timeStr).diff(moment('2020-01-01'), 'minutes')
	// 	this.hourBased.startTime = date
	// 	this.tour.startOffset = offsetTime
	// 	this.updateWaypointClockTimesForHourBased()
	// }

	clearTourRepeatInterval() {
		this.tour.repeatInterval = 0
		this.lastTourDurationInMin = 0
		this.needsValidationCheck()
		this.cancelRepeatWizard()
	}

	logTourRepeatInterval() {
		log('lastTourRepeatInterval', this.lastTourDurationInMin)
		log('tourRepeatDurationInMin', this.tourRepeatDurationInMin)
		log('Current repeatInterval', this.tour.repeatInterval)
	}

	public restoreScrollPosition() {
		this.dialogManager.restoreScrollPosition('tourEdit')
	}

	// public updateWaypointClockTimesForHourBased() {
	// 	for (const wp of this.waypoints) {
	// 		wp.clockTime = moment(this.hourBased.startTime).add(wp.twp.startOffset, 'minutes').toDate()
	// 	}
	// }

	public freeFormEditModeChanged() {
		log('freeFormEditModeChanged', event)
		if (this.freeFormEditMode) {
			this.enableValidation = false
			this.freezeOffsetTimes = false
			this.clearAllValidationIssues()
		} else {
			this.enableValidation = true
			this.freezeOffsetTimes = true
			this.needsValidationCheck()
		}
	}

	public convertMinutesToHoursAndMinutes(minutes: number): { hours: number; minutes: number } {
		let hours = Math.floor(minutes / 60)
		let remainingMinutes = minutes % 60

		if (remainingMinutes !== 0) {
			const roundedMinutes = Math.ceil(remainingMinutes / 15) * 15
			if (roundedMinutes === 60) {
				remainingMinutes = 0
				hours++
			} else {
				remainingMinutes = roundedMinutes
			}
		}

		const result = { hours, minutes: remainingMinutes }
		log('convertMinutesToHoursAndMinutes', result)
		return result
	}

	// Used to display the repeat interval duration. Custome version of DateTimeHelper.formatMinutestAsHrsAndMinutes
	// Which basically removes the 1 when showing 1 hour to fit better with display of a duration.

	private tourRepeatDurationInMInStrFormatter(totalMinutes: number): string {
		const days = Math.floor(totalMinutes / 1440)
		const hours = Math.floor((totalMinutes % 1440) / 60)
		const minutes = totalMinutes % 60

		const parts = []
		if (days > 0) {
			parts.push(`${days} day${days > 1 ? 's' : ''}`)
		}
		if (hours > 0) {
			if (hours === 1 && minutes === 0) {
				parts.push('hour')
			} else {
				parts.push(`${hours} hour${hours > 1 ? 's' : ''}`)
			}
		}
		if (minutes > 0) {
			parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`)
		}

		return parts.join(' ')
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)

		const orderErrorMessage =
			this.tour.type === 'STRUCTURED_DAY_START'
				? `All checkpoints must define an offset time relative to the beginiing of the day and times must be in ascending order.`
				: `All checkpoints must define an offset time relative to the beginiing of the shift and offset times must be in ascending order.`

		let repeatIntervalInfoMessage =
			this.tour.type === 'STRUCTURED_DAY_START'
				? `You may optionally provide a rest interval if you wish to repeat this tour throughout the day. This value indicates how long after the last checkpoint time before the tour starts again.`
				: `You may optionally provide a rest interval if you wish to repeat this tour throughout the shift. This value indicates how long after the last checkpoint is submitted before the tour starts again.`

		// repeatIntervalInfoMessage += '\n\nOr use the wand icon to start the repeat wizard which can help you figure out the proper rest interval.'

		switch (trigger) {
			case 'repeating_checkpoints':
				help.header = 'Repeating Checkpoints'
				help.message = `Employee is expected to do a checkpoint on a regular recurring schedule (every 30 minutes, every 60 minutes, etc.). No specific stops are defined. Only the frequency of checkpoints.`
				break
			case 'shift_based':
				help.header = 'Shift-timed Tour'
				help.message = `The timing of each stop is defined relative to when the employee clocks in for their shift. (10 minutes after clock in, 20 minutes after, etc.)`
				break
			case 'day_based':
				help.header = 'Daily Tour'
				help.message = `Tour is defined over an entire day. (1:15am, 3:40am, 2:00pm, etc.) each stop must be performed on time regardless of who is working or when they started their shift)`
				break
			case 'hour_based':
				help.header = 'Hourly Tour'
				help.message = `Tour is defined over the course of an hour, and then repeated at some hourly interval. (e.g. every hour, every other hour, every half-hour, etc)`
				break

			// DEPRECATED 20240420
			// case 'adjust_offsets':
			// 	help.header = 'Adjust Start'
			// 	help.message = `Enter a value and click the 'set' button. This will indicate when the first checkpoint is due. All other checkpoint times will be adjusted to maintain the original timing between checkpoints.`
			// 	// help.message =
			// 	// 	'Modify the tour start time (relative to when a shift begins) by adjusting all checkpoint offset times a given amount.\n\nTo do this, enter a value here and click the (-) button to subtract that amount from all checkpoints or click the (+) button to add that amount to all checkpoints.'
			// 	break

			case 'repeat_error':
				help.header = 'Repeat Interval'
				help.message = `All checkpoint offset times must be less than the tour repeat interval. Either increase the repeat interval or lower checkpoint offset times to be in range.`
				break
			case 'order_error':
				help.header = 'Ordering Issues'
				help.message = orderErrorMessage
				break

			case 'freeformEditMode':
				help.header = 'Free-form Edit'
				help.message = `Free-form edit mode disables validation and unlocks offset times, allowing them to move with the checkpoint when you modify the order of items in the list.<br><br>When exiting free-form edit mode, validation will be run and any checkpoints with invalid offset times will be flagged. Saving the tour will automatically exit free-form edit mode and run validation.`
				break

			// DEPRECATED 20240420
			// case 'enaable_validation':
			// 	help.header = 'Enable Validation'
			// 	help.message = `Validation is enabled by default and errors will be flagged when modifying the tour.\n\nUncheck this box to disable error messages until the tour is saved or validation is re-enabled.`
			// 	break

			// DEPRECATED 20240420
			// case 'freeze_offsets':
			// 	help.header = 'Freeze Offsets'
			// 	help.message = `Offset times are frozen by default and locked to the checkpoint slot. They will not change when you modify the checkpiont order.\n\nWhen this box is unchecked, offset times will move with the checkpoint and you will need to manually fix any ordering issues.\n\nUncheck this box to position new checkpoints without disrupting the offset times of current checkpoints.`
			// 	break

			case 'repeatIntervalStructured':
				help.header = 'Rest Interval'
				help.message = repeatIntervalInfoMessage
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}

class RepeatWizardOption {
	isAvailable = false
	label: string = 'Option'
}

const repeatWizardHrOptions = [
	{ label: '0', value: 0 },
	{ label: '1', value: 60 },
	{ label: '2', value: 120 },
	{ label: '3', value: 180 },
	{ label: '4', value: 240 },
	{ label: '5', value: 300 },
	{ label: '6', value: 360 },
	{ label: '7', value: 420 },
	{ label: '8', value: 480 },
	{ label: '9', value: 540 },
	{ label: '10', value: 600 },
	{ label: '11', value: 660 },
	{ label: '12', value: 720 },
	{ label: '13', value: 780 },
	{ label: '14', value: 840 },
	{ label: '15', value: 900 },
	{ label: '16', value: 960 },
	{ label: '17', value: 1020 },
	{ label: '18', value: 1080 },
	{ label: '19', value: 1140 },
	{ label: '20', value: 1200 },
	{ label: '21', value: 1260 },
	{ label: '22', value: 1320 },
	{ label: '23', value: 1380 },
	{ label: '24', value: 1440 },
]

const repeatWizardMinOptions = [
	{ label: '00', value: 0 },
	{ label: '15', value: 15 },
	{ label: '30', value: 30 },
	{ label: '45', value: 45 },
]
