<div *ngIf="newBtnLabel" class="button-link link-text-dark" [style]="btnStyle" (click)="newBtnClicked.next(true)">
	<i class="fa fa-plus btn-icon"></i> {{ newBtnLabel }}
</div>
<div *ngIf="table" class="button-link link-text-dark" [style]="btnStyle" (click)="exportBtnClicked()">
	<i class="far fa-file-spreadsheet btn-icon"></i> {{ exportBtnLabel }}
</div>
<div *ngIf="multiSchedBtnLabel" class="button-link link-text-dark" [style]="btnStyle" (click)="multiSchedBtnClicked.next(true)">
	<i class="far fa-users btn-icon" style="font-size: 0.8em"></i> {{ multiSchedBtnLabel }}
</div>
<div *ngIf="lockBtnLabel" class="button-link link-text-dark" [style]="btnStyle" (click)="lockBtnClicked.next(true)">
	<i class="far fa-lock btn-icon"></i> {{ lockBtnLabel }}
</div>
