import { ScheduleOptions, ScheduleEntry, ScheduleChangeRequest, ScheduleChangeComment } from '@app/models'
import { DatabaseService } from '@app/services'
import { DateTimeHelper, log } from '@app/helpers'

import { rrulestr, Frequency, RRuleSet } from 'rrule'

import moment from 'moment-timezone'

export class ScheduleChangeFormatter {
	static makeChangeDateString(startDate: string, endDate: string): string {
		let changeDate = ''
		const formattedStart = moment(startDate).format('MMM Do, YYYY')
		const formattedEnd = moment(endDate).format('MMM Do, YYYY')
		if (!endDate) {
			return `Requesting a permanent change starting ${formattedStart}`
		}
		if (startDate === endDate) {
			changeDate = `Requesting changes for ${formattedStart}`
		} else {
			changeDate = `Requesting changes from ${formattedStart} to ${formattedEnd}`
		}
		return changeDate
	}

	static makeShiftTimeInfo(startTime: string, endTime: string, tz: string): { startTime: string; endTime: string; shiftInfo: string } {
		const is12Hours = DateTimeHelper.format12Hour
		const startMom = moment.tz(startTime, 'HH:mm:ss', tz)
		const endMom = moment.tz(endTime, 'HH:mm:ss', tz)
		if (startTime === endTime) {
			return { startTime: null, endTime: null, shiftInfo: 'Scheduled Anytime' }
		}
		if (endMom.isBefore(startMom, 'seconds')) {
			endMom.add(1, 'day')
		}
		if (is12Hours) {
			const startTime12Hr = startMom.format('h:mm a')
			const endTime12Hr = endMom.format('h:mm a')
			const shiftInfo12Hr = startTime12Hr + ' - ' + endTime12Hr
			return { startTime: startTime12Hr, endTime: endTime12Hr, shiftInfo: shiftInfo12Hr }
		} else {
			const startTime24Hr = startMom.format('HH:mm')
			const endTime24Hr = endMom.format('HH:mm')
			const shiftInfo24Hr = startTime24Hr + ' - ' + endTime24Hr
			return { startTime: startTime24Hr, endTime: endTime24Hr, shiftInfo: shiftInfo24Hr }
		}
	}

	static makeEmployeeListString(dbSrvc: DatabaseService, schedRecur: ScheduleEntry): string {
		const empId = schedRecur.employee_id
		const employeeIds = schedRecur.employee_ids
		const empIdList = employeeIds.length > 0 ? employeeIds : [empId]
		return empIdList
			.map((id) => dbSrvc.empSrvc.getEmployeeById(id))
			.filter((emp) => !!emp)
			.map((emp) => emp.name)
			.join(', ')
	}

	static makeRuleOptions(recurrence: string): { freq: Frequency; days: string; details: string } {
		const ruleString = recurrence || 'FREQ=WEEKLY'
		// const rule = RRule.fromString(recurStr)
		const ruleSet = rrulestr(ruleString, { forceset: true }) as RRuleSet
		const rule = ruleSet.rrules()[0]

		const freq: Frequency = rule.options.freq

		const dayOptions = ScheduleOptions.dayOptions
		const days = rule.options.byweekday
			.map((day) => dayOptions.find((opt) => opt.value === day))
			.map((opt) => opt.code)
			.join(', ')

		const details = rule.toText()

		return { freq: freq, days: days, details: details }
	}

	static formatFrequency(freq: Frequency): string {
		const freqOption = ScheduleOptions.freqOptions.find((opt) => opt.rRuleFreq === freq)
		return freqOption.name
	}
}

export class ScheduleChangeRequestCard {
	schedRecur: ScheduleEntry
	changeRequest: ScheduleChangeRequest

	clientName = ''
	jobName = ''
	jobSiteName = ''
	employeeName = ''
	notes = ''

	comments: Array<ScheduleChangeComment> = []

	currentEmpCount = 0
	currentShiftInfo = ''
	currentFreq: Frequency
	currentDays = ''
	currentDetails = ''
	currentEmployees = ''

	requestedEmpCount = 0
	requestedShiftInfo = ''
	requestedFreq: Frequency
	requestedDays = ''
	requestedDetails = ''

	changeDate = ''
	confirmChangeMessage = ''
	commentPlaceholder = `Add a comment and click 'Confirm' to submit.`
	action = ''
	actionComment = ''

	timezone = ''

	constructor(dbSrvc: DatabaseService, changeRequest: ScheduleChangeRequest) {
		this.setupCardData(dbSrvc, changeRequest)
	}

	setupCardData(dbSrvc: DatabaseService, changeRequest: ScheduleChangeRequest) {
		this.changeRequest = changeRequest
		this.comments = changeRequest.getComments()?.data || []
		const schedRecur = dbSrvc.schedulerSrvc.getScheduleForId(changeRequest.schedule_id)
		if (schedRecur) {
			this.schedRecur = schedRecur
			const job = dbSrvc.jobSrvc.getJobById(schedRecur.job_id)
			if (job) {
				const jobSite = dbSrvc.siteSrvc.getJobSiteById(job.location_id)
				if (jobSite) {
					const tzId = jobSite.timezone_id
					this.timezone = dbSrvc.settingSrvc.getTimezoneZoneNameForId(tzId)
					this.jobSiteName = jobSite.description
					// this.clientName = dbSrvc.clientSrvc.getClientById(changeRequest.client_id)?.client_company_name || 'Client Info Missing'
					this.jobName = dbSrvc.jobSrvc.getJobById(schedRecur.job_id)?.description || 'Job Info Missing'
					this.employeeName = dbSrvc.empSrvc.getEmployeeById(schedRecur.employee_id)?.name || 'Employee Info Missing'
					const currentShiftTime = ScheduleChangeFormatter.makeShiftTimeInfo(schedRecur.start_time, schedRecur.end_time, this.timezone)
					this.currentShiftInfo = currentShiftTime.shiftInfo
					const requestedShiftTime = ScheduleChangeFormatter.makeShiftTimeInfo(changeRequest.start_time, changeRequest.end_time, this.timezone)
					this.requestedShiftInfo = requestedShiftTime.shiftInfo
					this.currentEmpCount = schedRecur.employee_count || 1
					this.requestedEmpCount = changeRequest.employee_count || 1
					this.changeDate = ScheduleChangeFormatter.makeChangeDateString(changeRequest.change_start_date, changeRequest.change_end_date)
					this.notes = changeRequest.notes
					this.currentEmployees = ScheduleChangeFormatter.makeEmployeeListString(dbSrvc, this.schedRecur)
					this.setupRuleInfo()
				}
			}
		}
	}

	setupRuleInfo() {
		const curRuleStr = this.schedRecur.recurrence
		const curOptions = ScheduleChangeFormatter.makeRuleOptions(curRuleStr)
		this.currentFreq = curOptions.freq
		this.currentDays = curOptions.days
		this.currentDetails = curOptions.details

		const reqRuleStr = this.changeRequest.recurrence
		const reqOptions = ScheduleChangeFormatter.makeRuleOptions(reqRuleStr)
		this.requestedFreq = reqOptions.freq
		this.requestedDays = reqOptions.days
		this.requestedDetails = reqOptions.details
	}

	hasChanged(data: string) {
		switch (data) {
			case 'empCount':
				return this.currentEmpCount !== this.requestedEmpCount
			case 'shiftInfo':
				return this.currentShiftInfo !== this.requestedShiftInfo
			case 'frequency':
				return this.currentFreq !== this.requestedFreq
			case 'weekDays':
				return this.currentDays !== this.requestedDays
			default:
				log('Unknown property - no change information')
		}
	}

	formatFreq(type: string): string {
		const freq = type === 'current' ? this.currentFreq : this.requestedFreq
		return ScheduleChangeFormatter.formatFrequency(freq)
	}
}
