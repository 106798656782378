<div class="urr-page-box">
	<div class="urr-report-wrapper">
		<app-user-report-header
			[config]="config"
			[logoUrl]="vm.logoUrl"
			[companyName]="vm.companyName"
			[address]="vm.address"
			[reportType]="vm.reportType"
			[transactionMetaData]="vm.reportData.transactionMetaData"
			[publicView]="publicView"
			(printPageBtnClicked)="printPage()"
			(closeBtnClicked)="closeBtnClicked.emit($event)"
		>
		</app-user-report-header>

		<div class="urr-block no-page-break">
			<div class="card urr-card">
				<div>
					<div class="urr-label urr-header-label nowrap">Employee:</div>
					<div class="urr-header-value">{{ vm.employeeName }}</div>
				</div>
				<div>
					<div class="urr-label urr-header-label nowrap">Submitted On:</div>
					<div class="urr-header-value">{{ vm.reportDate }}</div>
				</div>
			</div>
		</div>

		<div *ngFor="let cl of vm.checklists" style="margin-top: 24px">
			<div style="margin-bottom: 12px; font-size: 1.2em; font-weight: bold; color: #a28643">{{ cl.title }}</div>
			<div *ngIf="cl.instructions" class="urr-ecl-instructions">{{ cl.instructions }}</div>
			<div *ngFor="let item of cl.items; let idx = index" class="ur-cli-row no-page-break" [class.row-hl]="idx % 2 === 0">
				<!-- Input -->
				<div *ngIf="item.type === 'STRING'">
					<div *ngIf="item.skip" class="urr-answer-btn urr-answer-skip float-right">N/A</div>
					<div class="urr-label urr-answer-label">{{ item.label }}</div>
					<div *ngIf="!item.skip && item.value" class="cl-comment-label">{{ item.value }}</div>
				</div>
				<!-- Checkbox -->
				<div *ngIf="item.type === 'BOOLEAN'">
					<div class="urr-answer-btn float-right" [class.urr-answer-yes]="item.value" [class.urr-answer-skip]="item.skip">
						<span *ngIf="!item.skip && item.value"><i class="fa fa-check"></i></span>
						<span *ngIf="!item.skip && !item.value"><i class="fa fa-ban"></i></span>
						<span *ngIf="item.skip">N/A</span>
						<!-- {{ item.skip ? 'N/A' : item.value ? 'YES' : 'NO' }} -->
					</div>
					<div class="urr-label urr-answer-label">{{ item.label }}</div>
					<div *ngIf="!item.skip && item.comment" class="cl-comment-label">{{ item.comment }}</div>
				</div>
				<!-- Range -->
				<div *ngIf="item.type === 'RANGE'">
					<div
						class="urr-answer-btn urr-answer-value float-right"
						[class.urr-answer-skip]="item.skip || (!item.value && item.value !== 0 && !item.comment)"
					>
						{{ formatRangeValue(item) }}
					</div>
					<div class="urr-label urr-answer-label">
						{{ item.label }}
					</div>
					<!-- If errors found, use optional chaining after rangeLabel to support previous forms that may not have had this information -->
					<div
						*ngIf="(item.data.min || item.data.min === 0) && item.data.max && !item.data.rangeLabel.left && !item.data.rangeLabel.right"
						class="cl-metadata-label"
					>
						Range: {{ item.data.min }} - {{ item.data.max }}
					</div>
					<div *ngIf="item.data?.rangeLabel?.left && item.data?.rangeLabel?.right" class="cl-metadata-label">
						<span style="white-space: nowrap">{{ item.data.min }}={{ item.data.rangeLabel.left }}</span> -
						<span style="white-space: nowrap">{{ item.data.max }}={{ item.data.rangeLabel.right }}</span>
					</div>
					<div *ngIf="!item.skip && item.comment" class="cl-comment-label">{{ item.comment }}</div>
					<!-- <pre><code>{{ item | json }}</code></pre> -->
				</div>
				<!-- Select -->
				<div *ngIf="item.type === 'SELECT'">
					<div *ngIf="item.skip" class="urr-answer-btn urr-answer-skip float-right">N/A</div>
					<div class="urr-label urr-answer-label">{{ item.label }}</div>
					<div *ngIf="!item.skip" style="line-height: 1.4rem">
						<!-- Dropdown -->
						<div *ngIf="item.value && item.data.style === 'MENU'" class="cl-comment-label cl-options-list">
							<!-- Menu View / Selected from List -->
							<div *ngIf="item.value !== 'CUSTOM'" class="cl-option-selected">{{ item.value }}</div>
							<!-- Menu View / Custom Response -->
							<div *ngIf="item.value === 'CUSTOM'">
								<span class="cl-option-other">Other:</span>
								<span class="cl-option-selected">{{ item.data.custom.value }}</span>
							</div>
						</div>
						<div *ngIf="item.data.style === 'LIST'" class="cl-comment-label cl-options-list">
							<!-- List View / Radio Buttons / One Selection -->
							<div *ngIf="!item.data.multi.allow">
								<!-- Menu View / Selected from List -->
								<div *ngIf="item.value !== 'CUSTOM'" class="cl-option-selected">{{ item.value }}</div>
								<!-- Menu View / Custom Response -->
								<div *ngIf="item.value === 'CUSTOM'">
									<span class="cl-option-other">Other:</span>
									<span class="cl-option-selected">{{ item.data.custom.value }}</span>
								</div>
							</div>
							<!-- List View / Checkboxes / Multiple Selections -->
							<div *ngIf="item.data.multi.allow">
								<div *ngFor="let opt of item.data.options; let idx = index">
									<div *ngIf="opt.selected" class="cl-option-selected">{{ opt.label }}</div>
								</div>
								<!-- Menu View / Custom Response -->
								<div *ngIf="item.value === 'CUSTOM'">
									<span class="cl-option-other">Other:</span>
									<span class="cl-option-selected">{{ item.data.custom.value }}</span>
								</div>
							</div>
						</div>

						<!-- Show Options -->
						<div *ngIf="item.data.options.length > 0">
							<div class="noprint badge badge-secondary urr-show-link has-pointer" style="margin-top: 12px" (click)="item.show = !item.show">
								{{ item.show ? 'hide options' : 'show options' }}
							</div>
							<div *ngIf="item.show" style="margin-top: 12px">
								<div class="print-only" style="font-weight: 600; margin-bottom: 12px; color: gray">Available Options</div>
								<div class="cl-options-list">
									<div *ngFor="let opt of item.data.options; let idx = index">{{ opt.label }}</div>
									<div *ngIf="item.data.custom.allow">Other (custom response)</div>
								</div>
							</div>
						</div>

						<!-- <div>
							<code>
								<pre>{{ item.data | json }}</pre>
							</code>
						</div> -->
					</div>
				</div>
			</div>

			<div class="urr-block no-page-break">
				<div class="card urr-card">
					<div class="urr-label">Comments</div>
					<div class="urr-description">{{ vm.reportData.summary.additionalNotes.value || vm.noDescription }}</div>
				</div>
			</div>
		</div>

		<app-user-report-footer [incident]="incident" [images]="vm.images"></app-user-report-footer>
	</div>
</div>
