import { Pipe, PipeTransform } from '@angular/core'

import { log, DisplayHelper } from '@app/helpers'

@Pipe({
    name: 'displayPhone',
    standalone: false
})
export class DisplayPhonePipe implements PipeTransform {
	transform(value: any, args?: any): string {
		return DisplayHelper.displayPhone(value)
	}
}
