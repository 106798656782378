import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { log } from '@app/helpers/logger'

@Component({
    selector: 'app-horizontal-scroll-menu',
    templateUrl: './horizontal-paddle-scroller.component.html',
    styleUrls: ['./horizontal-paddle-scroller.component.scss'],
    standalone: false
})
export class HorizontalPaddleScrollerComponent implements AfterViewInit {
	@Input() itemClass = 'os-card'

	@ViewChild('menu', { static: true }) menu: ElementRef
	@ViewChild('leftPaddle', { static: true }) leftPaddle: ElementRef
	@ViewChild('rightPaddle', { static: true }) rightPaddle: ElementRef

	itemWidth = 350
	itemHeight = 150
	itemCount = 9
	scrollDuration = 300
	paddleMargin = 20

	constructor() {}

	get hideLeftPaddle(): boolean {
		return false
	}

	get hideRightPaddle(): boolean {
		return false
	}

	ngAfterViewInit(): void {
		this.setupMenu()
	}

	setupMenu(): void {
		// const menuItems = this.menu.nativeElement.querySelectorAll(`.${this.itemClass}`)
		const menuWrapperSize = this.menu.nativeElement.offsetWidth
		const menuVisibleSize = menuWrapperSize
		const itemsLength = this.itemCount // menuItems.length
		const itemSize = this.itemWidth // menuItems[0].offsetWidth

		// this.menu.nativeElement.style.width = `${itemSize * itemsLength}px`

		let menuInvisibleSize = itemSize * itemsLength - menuWrapperSize
		const menuEndOffset = menuInvisibleSize - this.paddleMargin

		this.menu.nativeElement.addEventListener('scroll', () => {
			const menuPosition = this.menu.nativeElement.scrollLeft

			if (menuPosition <= this.paddleMargin) {
				this.leftPaddle.nativeElement.classList.add('hidden')
				this.rightPaddle.nativeElement.classList.remove('hidden')
			} else if (menuPosition < menuEndOffset) {
				this.leftPaddle.nativeElement.classList.remove('hidden')
				this.rightPaddle.nativeElement.classList.remove('hidden')
			} else if (menuPosition >= menuEndOffset) {
				this.leftPaddle.nativeElement.classList.remove('hidden')
				this.rightPaddle.nativeElement.classList.add('hidden')
			}
		})

		this.leftPaddle.nativeElement.addEventListener('click', () => {
			this.menu.nativeElement.animate({ scrollLeft: 0 }, this.scrollDuration)
		})

		this.rightPaddle.nativeElement.addEventListener('click', () => {
			this.menu.nativeElement.animate({ scrollLeft: menuInvisibleSize }, this.scrollDuration)
		})
	}

	handleRightPaddleClick() {
		log('handleRightClick')
	}

	handleLeftClick() {
		log('handleLeftClick')
	}
}
