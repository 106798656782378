import { Component, EventEmitter, Input, Output } from '@angular/core'
import { log } from '@app/helpers'
import { DataAccessRequest, HelpDialogMessage, PendingScheduleApprovalAction, ScheduleEntry } from '@app/models'
import { CoreService } from '@app/services'

@Component({
    selector: 'app-pending-schedule-approval',
    templateUrl: './pending-schedule-approval.component.html',
    styleUrl: './pending-schedule-approval.component.scss',
    standalone: false
})
export class PendingScheduleApprovalComponent {
	@Input() approvalAction = new PendingScheduleApprovalAction()
	@Output() approvalComplete = new EventEmitter<boolean>()

	constructor(private coreSrvc: CoreService) {}

	public takeActionBtnClicked(action: 'REJECT' | 'APPROVE' | 'UPDATE') {
		this.approvalAction.takeAction = action
		switch (action) {
			case 'UPDATE':
				this.confirmTakeAction()
				break
			case 'REJECT':
				this.approvalAction.showConfirmation = true
				break
			case 'APPROVE':
				this.approvalAction.showConfirmation = true
				break
		}
	}

	confirmTakeAction() {
		const action = this.approvalAction.takeAction
		const recordId = this.approvalAction.recordId
		const schedRecur = this.coreSrvc.dbSrvc.schedulerSrvc.getScheduleForId(recordId)
		const updateRecord = new ScheduleEntry(schedRecur)

		// Hide the allow overtime and time off conflict checkboxes but don't reset them
		// this.approvalAction.showOvertimeOverrideCheckbox = false
		// this.approvalAction.showTimeOffOverrideCheckbox = false

		if (this.approvalAction.approveWithWorkorder) {
			const emailListManager = this.approvalAction.approveWithWorkorderEmailListManager
			// Add any email in the input field and return if there's an error. Otherwise, continue
			if (!emailListManager.addEmail()) return

			if (action === 'APPROVE') {
				updateRecord.approve_with_workorder = true
				updateRecord.workorder_email = emailListManager.emailListString
			}
		}

		this.approvalAction.isProcessing = true

		let actionType = 'updated'
		switch (action) {
			case 'REJECT':
				updateRecord.approval_state = 'REJECTED'
				actionType = 'rejected'
				break
			case 'APPROVE':
				updateRecord.approval_state = 'APPROVED'
				actionType = 'approved'
				break
		}
		updateRecord.comments = this.approvalAction.notes
		updateRecord.emp_ids_days_of_week = schedRecur.buildEmpIdsDaysOfWeekForUpdate()
		// Below is a hack to prevent timer from showing as Deadline Missed when updating

		if (this.approvalAction.confirmOvertimeOverride) updateRecord['ot_override'] = true
		if (this.approvalAction.confirmTimeOffOverride) updateRecord['vacation_override'] = true

		const request = new DataAccessRequest('schedule_recur', 'update', updateRecord)
		this.coreSrvc.dbSrvc.lambdaSrvc
			.dataAccess(request)
			.then((result) => {
				log('SUCCESS', result)
				const records = result.data
				this.coreSrvc.dbSrvc.schedulerSrvc.addOrUpdateChangeRequestRecords(records)
				this.coreSrvc.dbSrvc.readRecord('schedule_recur', recordId).then((readSuccess) => {
					this.coreSrvc.zone.run(() => {
						this.coreSrvc.notifySrvc.notify(
							actionType === 'rejected' ? 'error' : 'success',
							`Schedule ${actionType}`,
							`This schedule has been ${actionType}.`,
							3,
						)
						this.approvalComplete.next(true)
						this.approvalAction.isDialogVisible = false
					})
				})
			})
			.catch((error) => {
				const msg = error.errorMessage
				const errorType = error.errorType
				let errorLabel = 'Error Encountered'

				if (errorType === 'com.sst.db.tts.exceptions.OvertimeDetectedException') {
					this.approvalAction.showOvertimeOverrideCheckbox = true
					errorLabel = 'Overtime Conflict'
				}
				if (errorType === 'com.sst.db.tts.exceptions.VacationDetectedException') {
					this.approvalAction.showTimeOffOverrideCheckbox = true
					errorLabel = 'Time-Off Conflict'
				}

				this.coreSrvc.notifySrvc.notify('error', errorLabel, msg)
				this.approvalAction.isProcessing = false
			})

		// this.coreSrvc.dbSrvc
		// 	.updateRecord('schedule_recur', updateRecord)
		// 	.then((success) => {
		// 		if (success) {
		// 			this.coreSrvc.dbSrvc.readRecord('schedule_recur', recordId).then((readSuccess) => {
		// 				this.coreSrvc.zone.run(() => {
		// 					this.coreSrvc.notifySrvc.notify(
		// 						actionType === 'rejected' ? 'error' : 'success',
		// 						`Schedule ${actionType}`,
		// 						`This schedule has been ${actionType}.`,
		// 						3,
		// 					)
		// 					this.approvalAction.isDialogVisible = false
		// 					this.checkSubSectionForPendingSchedules()
		// 				})
		// 			})
		// 		} else {
		// 			this.approvalAction.isProcessing = false
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		log('ERRRRRRRRRRRRRRRRROOOOOOOOOOORR', error)
		// 	})
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'approve_with_workorder':
				help.header = 'Work Orders'
				help.message =
					'When checked, the system will send a work order for this schedule to the email addresses provided. You can configure your workorder options under Admin > Settings > Work Orders.'
				break
			case 'overtime_override':
				help.header = 'Overtime Override'
				help.message = `This schedule exceeds overtime limits. Select the override checkbox and re-submit to confirm that the overtime generated by this schedule has been approved.`
				break
			case 'timeoff_override':
				help.header = 'Time-Off Override'
				help.message = `This schedule conflicts with an existing time-off entry. Select the override checkbox and re-submit to override the conflict and schedule anyway.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
