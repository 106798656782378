<div class="section-table">
	<table id="userRoleTable" class="stripe nowrap" cellspacing="0" width="100%"></table>
	<app-table-footer-buttons [marginTop]="'15px'" [newBtnLabel]="'Add Role'" (newBtnClicked)="createRecord()"></app-table-footer-buttons>
	<i id="clear-search-icon" (click)="clearSearch()" class="fa fa-times-circle-o" aria-hidden="true"></i>
</div>

<app-modal-dialog *ngIf="editDialogManager.isDialogVisible" [dialogManager]="editDialogManager">
	<app-user-role-edit
		[recordId]="editAction.recordId"
		[action]="editAction.action"
		[dialogManager]="editDialogManager"
		(recordUpdated)="recordUpdated()"
	>
	</app-user-role-edit>
</app-modal-dialog>

<app-delete-record-dialog
	*ngIf="deleteAction.showDeleteRecordDialog"
	[showDeleteRecordDialog]="deleteAction.showDeleteRecordDialog"
	[tableName]="deleteAction.tableName"
	[recordId]="deleteAction.recordId"
	[recordLabel]="deleteAction.recordLabel"
	(cancelDelete)="deleteActionCancel()"
	(actionComplete)="deleteActionComplete($event)"
>
</app-delete-record-dialog>

<app-modal-dialog *ngIf="linkedRecordsDialogManager.isDialogVisible" [dialogManager]="linkedRecordsDialogManager">
	<div style="margin-bottom: 15px">
		<app-search-filter [placeholder]="'filter records'" (search)="filterLinkedRecords($event)"> </app-search-filter>
	</div>

	<ul style="color: gray">
		<li *ngFor="let record of linkedRecordsDialog.filteredRecords">{{ record }}</li>
	</ul>
</app-modal-dialog>
