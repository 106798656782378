<p-dialog
	header="Filter Dates"
	[(visible)]="isDialogVisible"
	[modal]="true"
	[showHeader]="true"
	[style]="{ width: '300px' }"
	[dismissableMask]="true"
	(onHide)="cancelFilter.next(true)"
>
	<div class="options-block">
		<div class="options-title">Start Date</div>
		<app-date-time-picker
			[currentDate]="startDate"
			[pickerType]="'DATE'"
			[pickerMode]="'dialog'"
			[pickerLabel]="'Start Date'"
			[hour12Timer]="format12Hour"
			[format]="'MMM Do, YYYY'"
			(pickerDidClose)="startDate = $event"
		>
		</app-date-time-picker>
	</div>

	<div class="options-block">
		<div class="options-title">End Date</div>
		<app-date-time-picker
			[currentDate]="endDate"
			[pickerType]="'DATE'"
			[pickerMode]="'dialog'"
			[pickerLabel]="'End Date'"
			[hour12Timer]="format12Hour"
			[format]="'MMM Do, YYYY'"
			(pickerDidClose)="endDate = $event"
		>
		</app-date-time-picker>
	</div>

	<div class="d-flex justify-content-evenly" style="margin-top: 32px">
		<button type="submit" class="btn btn-sm btn-block btn-secondary" style="margin-right: 4px" (click)="resetFilterBtnClicked()">Reset</button>
		<button type="submit" class="btn btn-block btn-success" style="margin-left: 4px" (click)="setFilterBtnClicked()">Set</button>
	</div>
</p-dialog>
