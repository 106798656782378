<!-- Batch Action Banner -->
<app-batch-action-banner [manager]="batchManager" [resource]="'job'" (performAction)="peformBatchAction($event)"></app-batch-action-banner>

<div class="section-table">
	<table id="jobsTable" class="stripe nowrap" cellspacing="0" width="100%"></table>
	<i id="clear-search-icon" (click)="clearSearch()" class="fa fa-times-circle-o" aria-hidden="true"></i>
	<app-table-footer-buttons
		[filename]="'job_records'"
		[newBtnLabel]="'Add Job'"
		[exportBtnLabel]="'Export Records'"
		[table]="'jobsTable'"
		[marginTop]="'15px'"
		(newBtnClicked)="createRecord()"
	></app-table-footer-buttons>
</div>

<!-- Edit Record Dialog -->
<app-modal-dialog *ngIf="editDialogManager.isDialogVisible" [dialogManager]="editDialogManager">
	<app-job-detail
		[dialogManager]="editDialogManager"
		[action]="editAction.action"
		[jobId]="editAction.recordId"
		(saveActionComplete)="saveActionComplete()"
		(editActionCancelled)="editActionCancelled()"
	></app-job-detail>
</app-modal-dialog>

<!-- Job Batch Edit Dialog -->
<app-modal-dialog *ngIf="batchDialogManager.isDialogVisible" [dialogManager]="batchDialogManager">
	<app-job-edit-batch [dialogManager]="batchDialogManager" (actionComplete)="fetchAndReloadData()"></app-job-edit-batch>
</app-modal-dialog>

<!-- Tour Edit Dialog -->
<app-modal-dialog *ngIf="tourEditDialogManager.isDialogVisible" [dialogManager]="tourEditDialogManager">
	<app-tour-edit
		[dialogManager]="tourEditDialogManager"
		[action]="editAction.action"
		[jobId]="editAction.recordId"
		(recordUpdated)="tourRecordUpdated($event)"
	></app-tour-edit>
</app-modal-dialog>

<!-- Site Numbers Dialog -->
<app-basic-modal
	[(visible)]="siteNumbers.showDialog"
	modalId="siteNumbersModal"
	[modalHeader]="'Linked Job Site Numbers'"
	[modalWidth]="450"
	[modalHeight]="500"
>
	<div *ngIf="siteNumbers.showDialog">
		<div *ngIf="siteNumbers.linkedOnly" class="linked-only-note tts-hl-2">-- Job only allows linked numbers --</div>
		<app-jobsite-numbers
			*ngIf="siteNumbers.showDialog"
			[jobSiteId]="siteNumbers.recordId"
			(closeDialog)="siteNumbers.showDialog = false"
		></app-jobsite-numbers>
	</div>
</app-basic-modal>

<!-- Prefs Edit Dialog -->
<app-modal-dialog *ngIf="sectionPrefsDialogManager.isDialogVisible" [dialogManager]="sectionPrefsDialogManager">
	<app-local-prefs [dialogManager]="sectionPrefsDialogManager" (prefsUpdated)="prefsDataSaved()"></app-local-prefs>
</app-modal-dialog>

<!-- QR Code Dialog -->
<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="QR Code"
	[(visible)]="qrCodeManager.isDialogVisible"
	[modal]="true"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div style="padding: 50px">
		<app-view-qrcode
			*ngIf="qrCodeManager.isDialogVisible"
			[inputString]="qrCodeManager.dialogData.inputString"
			[printHeader]="qrCodeManager.dialogData.printHeader"
			[printSubHeader]="qrCodeManager.dialogData.printSubHeader"
			[jobCode]="qrCodeManager.dialogData.jobCode"
			[showPrintable]="true"
			(printWindowOpened)="qrCodeManager.isDialogVisible = false"
		></app-view-qrcode>
	</div>
</p-dialog>
