<div class="container" style="margin-top: 40px">
	<div *ngIf="isLoadingData" class="info-banner">
		<div class="banner-text"><i class="fas fa-loader fa-spin" aria-hidden="true"></i> Loading Data</div>
	</div>

	<div *ngIf="isDataLoaded && batches.length === 0" class="info-banner">
		<div class="banner-text">No log entries</div>
	</div>

	<div *ngFor="let batch of batches" style="margin-bottom: 25px">
		<app-integration-sync-log-batch [batch]="batch" [table]="table"></app-integration-sync-log-batch>
	</div>
</div>
