import { Component, OnInit, Input } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

import { log } from '@app/helpers'

@Component({
    selector: 'app-transaction-map',
    templateUrl: './transaction-map.component.html',
    styleUrls: ['./transaction-map.component.scss'],
    standalone: false
})
export class TransactionMapComponent implements OnInit {

	@Input() addr: string
	@Input() visible = false
	mapUrl: SafeResourceUrl

	constructor(
		private sanitizer: DomSanitizer
	) { }

	makeUrl(addr: string): string {
		return 'http://' + addr
	}

	ngOnInit() {
		log('Transaction Address:', this.addr)
		const url = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyDYoKyWfAMgVjj_vyG28ozaVY4ZFnoxwN8&zoom=17&q=' + this.addr
		const sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url)
		log('URL:', url)
		this.mapUrl = sanitizedUrl
	}

}
