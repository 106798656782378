<div>
	<p-checkbox name="enableSatelliteView" (onChange)="setSatelliteView()" [(ngModel)]="enableSatelliteView" [binary]="true"></p-checkbox>
	<div class="checkbox-label" (click)="enableSatelliteView = !enableSatelliteView; setSatelliteView()">Show satellite view</div>
</div>
<div *ngIf="showZoomCheckbox" style="margin-top: 8px">
	<p-checkbox name="enableMouseZoom" (onChange)="setMouseZoom()" [(ngModel)]="enableMouseZoom" [binary]="true"></p-checkbox>
	<div class="checkbox-label" (click)="enableMouseZoom = !enableMouseZoom; setMouseZoom()">Enable scroll to zoom</div>
</div>

<div style="margin-top: 20px">
	<div [id]="mapId" #gmap class="map-box"></div>
	<!-- <div [class.d-flex]="showMapClear" [class.justify-content-between]="showMapClear" style="margin-top: 4px"> -->
	<div class="d-flex justify-content-between" style="margin-top: 4px">
		<div style="font-size: 0.9em; color: gray; text-align: center">click map to set marker</div>
		<div *ngIf="showClearLink" class="link-text" (click)="clearMarker()">clear</div>
	</div>
</div>
