import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import {
	ClickToCallGlobalConfig,
	ClickToCallRecord,
	ClickToCallRequestBuilder,
	C2CEventSubmittedEvent,
	DataAccessRequest,
	C2CLogRecord,
	CallLogRecordWrap,
	C2CDirectoryLookup,
} from '@app/models'
import { CoreService } from '@app/services'
import { log } from '@app/helpers'

import { Subscription } from 'rxjs'
import _ from 'lodash'
import moment from 'moment-timezone'

@Component({
    selector: 'app-click-to-call-event-dialog',
    templateUrl: './click-to-call-event-dialog.component.html',
    styleUrls: ['./click-to-call-event-dialog.component.scss'],
    standalone: false
})
export class ClickToCallEventDialogComponent implements OnInit {
	timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC'
	startDate = moment().subtract(30, 'days')
	endDate = moment()

	c2cRecord: ClickToCallRecord
	clickToCallEnabled = { backend: false, browser: false }
	viewState = {
		showDialog: false,
		showResponseOptions: false,
		smsPlaceholder: '',
		initialMsg: '',
		prepareText: false,
		sendMessage: false,
		placeCallConfirm: false,
		placeCall: false,
		viewLog: false,
		isLogLoaded: false,
	}

	logList: Array<CallLogRecordWrap> = []

	currentConfig: ClickToCallGlobalConfig

	subs = new Subscription()

	@ViewChild('scrollContainer') scrollContainer!: ElementRef

	constructor(
		private coreSrvc: CoreService,
		private cd: ChangeDetectorRef,
	) {
		this.subs.add(
			this.coreSrvc.eventSrvc.clickToCallGlobalEvent.subscribe((config) => {
				this.setupAvailability()
				this.processRequest(config)
			}),
		)
	}

	get lastNDaysCount(): number {
		return this.endDate.diff(this.startDate, 'days')
	}

	ngOnInit(): void {}

	private setupAvailability() {
		const portalPrefs = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany()

		// Setup Click to Call availability
		this.clickToCallEnabled.backend = this.coreSrvc.dbSrvc.settingSrvc.isClickToCallEnabled()
		this.clickToCallEnabled.browser = portalPrefs.globalBrowserClickToCall
	}

	private resetViewState() {
		this.c2cRecord = null
		this.currentConfig = null
		this.viewState.showDialog = false
		this.viewState.placeCallConfirm = false
		this.viewState.placeCall = false
		this.viewState.prepareText = false
		this.viewState.sendMessage = false
		this.viewState.smsPlaceholder = ''
		this.viewState.initialMsg = ''
		this.viewState.viewLog = false
		this.viewState.isLogLoaded = false
		this.logList = []
	}

	get isViewStateEmpty(): boolean {
		return (
			!this.viewState.placeCallConfirm &&
			!this.viewState.placeCall &&
			!this.viewState.prepareText &&
			!this.viewState.sendMessage &&
			!this.viewState.viewLog
		)
	}

	private processRequest(config: ClickToCallGlobalConfig) {
		log('Got config', config)
		if (this.clickToCallEnabled.backend) this.resetViewState()

		this.coreSrvc.zone.run(() => {
			// Setup optional placeholder and initial message copy
			if (config.placeholder) this.viewState.smsPlaceholder = `${config.placeholder}`
			if (config.initialMsg) this.viewState.initialMsg = `${config.initialMsg}`

			// Setup the source config
			this.currentConfig = config

			switch (config.action) {
				case 'CALL':
					if (this.clickToCallEnabled.backend) {
						this.viewState.placeCallConfirm = true
						this.viewState.showDialog = true
					} else {
						this.coreSrvc.callSrvc.showC2CTriggerHost()
						this.placeCall(config.c2cRecord)
					}
					break
				case 'TEXT':
					this.startTextMsg(config.c2cRecord)
					break
				case 'LOG':
					this.startLogView(config.c2cRecord)
					break
			}
		})
	}

	/////////////////////
	// Call Processing //
	/////////////////////

	public placeCall(dest: ClickToCallRecord) {
		const backendEnabled = this.clickToCallEnabled.backend
		if (backendEnabled) {
			this.viewState.placeCall = true
			this.viewState.showDialog = true
			log('Placing Call', dest)
			const user = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
			const source = new ClickToCallRecord('USERPHONE', user.first_name + ' ' + user.last_name, null, user.phone_e164, user.email)
			log('User Placing Call', source)
			const sourceConfig = this.currentConfig?.sourceConfig ?? null
			const builder = new ClickToCallRequestBuilder(dest, sourceConfig)
			const request = builder.getLambdaRequest()
			log('Lambda Request', request)
			this.coreSrvc.dbSrvc.insertRecord('click_to_call_log', request).then((success) => {
				log('Call Request Sent')
				if (success) {
					setTimeout(() => {
						// this.manager.showDialog = false
						this.coreSrvc.notifySrvc.notify('success', 'Request Sent', `Your call request to ${dest.description} has been initiated.`, 3)
						this.resetViewState()
						this.coreSrvc.callSrvc.c2cEventSubmitted.next(new C2CEventSubmittedEvent(success, 'CALL', dest))
						this.coreSrvc.callSrvc.showC2CTriggerHost()
					}, 1000)
				} else {
					this.resetViewState()
					this.coreSrvc.callSrvc.c2cEventSubmitted.next(new C2CEventSubmittedEvent(success, 'CALL', dest))
					this.coreSrvc.callSrvc.showC2CTriggerHost()
				}
			})
		} else {
			// Using browser click-to-call
			const phone = dest.phoneE164
			document.location.href = 'tel:' + phone
		}
	}

	public placeCallConfirmed() {
		const config = this.currentConfig
		this.viewState.placeCallConfirm = false
		this.placeCall(config.c2cRecord)
	}

	public cancelPlaceCall(): void {
		this.resetViewState()
		setTimeout(() => {
			this.coreSrvc.callSrvc.showC2CTriggerHost()
		}, 20)
	}

	////////////////////////
	// Message Processing //
	////////////////////////

	public startTextMsg(dest: ClickToCallRecord) {
		log('startTextMsg', this.viewState)
		const backendEnabled = this.clickToCallEnabled.backend
		if (backendEnabled) {
			this.c2cRecord = dest
			this.viewState.prepareText = true
			this.viewState.showDialog = true
		} else {
			this.coreSrvc.callSrvc.showC2CTriggerHost()
			const phone = dest.phoneE164
			document.location.href = 'sms:' + phone
		}
	}

	// We know back end is enabled if it reaches this method since text button launches sms link if it isn't
	public sendTextMsg(message: string): void {
		log('Send text message', message)
		this.viewState.prepareText = false
		this.viewState.sendMessage = true
		const dest = this.c2cRecord
		const sourceConfig = this.currentConfig?.sourceConfig ?? null
		const builder = new ClickToCallRequestBuilder(dest, sourceConfig)
		const request = builder.getLambdaRequest(message)
		log('SMS Lambda Request', request)
		this.coreSrvc.dbSrvc.insertRecord('click_to_call_log', request).then((success) => {
			log('SMS Request Sent')
			setTimeout(() => {
				this.resetViewState()
				this.coreSrvc.notifySrvc.notify('success', 'Message Sent', `Your message to ${dest.description} has been sent.`, 3)
				this.coreSrvc.callSrvc.c2cEventSubmitted.next(new C2CEventSubmittedEvent(success, 'TEXT', dest))
				this.coreSrvc.callSrvc.showC2CTriggerHost()
			}, 1000)
		})
	}

	public cancelTextMsg(): void {
		// this.resetViewState()
		this.resetViewState()
		setTimeout(() => {
			this.coreSrvc.callSrvc.showC2CTriggerHost()
		}, 20)
	}

	//////////////
	// Log View //
	//////////////

	public startLogView(record: ClickToCallRecord) {
		log('startLogView', this.viewState)
		this.c2cRecord = record
		this.viewState.viewLog = true
		this.viewState.showDialog = true

		this.startDate = moment().subtract(30, 'days')
		this.endDate = moment()

		const options = {
			phone_e164: record.phoneE164,
			start_date: this.startDate.toISOString(),
			end_date: this.endDate.toISOString(),
		}

		const request = new DataAccessRequest('click_to_call_log', null, options)
		const directory = new C2CDirectoryLookup(this.coreSrvc.dbSrvc)
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			const data: Array<C2CLogRecord> = result.data ?? []
			const records = data.map((rec) => new C2CLogRecord(rec))
			const sortedRecords = _.sortBy(records, 'created').reverse()
			const wrappedRecords = sortedRecords.map((clr) => new CallLogRecordWrap(clr, directory))
			log('Lambda Result', records)
			setTimeout(() => {
				this.logList = wrappedRecords
				this.viewState.isLogLoaded = true
			}, 500)
		})
	}

	public viewLogDone(): void {
		this.resetViewState()
		setTimeout(() => {
			this.coreSrvc.callSrvc.showC2CTriggerHost()
		}, 20)
	}

	public loadMoreLogs(): void {
		const scrollElement = this.scrollContainer.nativeElement
		const previousScrollTop = scrollElement.scrollTop
		log('scrollHeight', previousScrollTop)

		const newStart = this.startDate.clone().subtract(30, 'day')
		const options = {
			phone_e164: this.c2cRecord.phoneE164,
			start_date: this.startDate.toISOString(),
			end_date: this.endDate.toISOString(),
		}
		const request = new DataAccessRequest('click_to_call_log', null, options)
		const directory = new C2CDirectoryLookup(this.coreSrvc.dbSrvc)
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			const data: Array<C2CLogRecord> = result.data ?? []
			const records = data.map((rec) => new C2CLogRecord(rec))
			const sortedRecords = _.sortBy(records, 'created').reverse()
			const wrappedRecords = sortedRecords.map((clr) => new CallLogRecordWrap(clr, directory))
			const filteredRecords = wrappedRecords.filter((rec) => !this.isLogRecordInList(rec.clr.id))

			this.logList = [...this.logList, ...filteredRecords]
			this.startDate = newStart
			// Wait for the DOM to update and then adjust scroll position
			setTimeout(() => {
				const bumpHeight = filteredRecords.length > 0 ? 25 : 0
				scrollElement.scrollTop = previousScrollTop + bumpHeight
			})
		})
	}

	private isLogRecordInList(id: number) {
		return this.logList.find((rec) => rec.clr.id === id)
	}

	public dispatchC2CEventFromLog(action: 'CALL' | 'TEXT', record: ClickToCallRecord) {
		const config = new ClickToCallGlobalConfig(record, action, null, null)
		this.coreSrvc.eventSrvc.clickToCallGlobalEvent.next(config)
	}
}

// {"ts":1672113933487,"key":"1433/profiles/24a89bc8-c746-4641-a99f-ebbad80965bd","type":"image/png","bucket":"assets.tts-stage.com"}
