<hr class="noprint" style="margin-top: 35px" />

<div class="footer">
	<div *ngIf="logoUrl" style="margin-top: 20px; margin-bottom: 20px">
		<img [src]="logoUrl" style="max-width: 250px" />
	</div>
	<div class="noprint" style="font-weight: bold; margin-bottom: 20px">
		<span id="footerCompanyName" style="font-size: 1.2em">{{ companyName() }} {{ companyId() }}</span>
		<br />
		<div *ngIf="callInNumbers.length > 0" style="font-size: 1.2em">
			<div *ngFor="let item of callInNumbers" class="call-in-block">
				<div class="call-in-label tts-hl-2">{{ item.label ? item.label : 'IVR Call-in Number' }}</div>
				<div>{{ item.number }}</div>
			</div>
		</div>
		<br />
		<div>Copyright &copy; {{ copyrightYear }} Telephone Timesheets</div>
		<div>
			<span class="link-text" (click)="supportInfoVisible = true">Contact Support</span> /
			<span class="link-text" (click)="showManual()">View Manual</span>
			<div *ngIf="chatbotAvailable" style="display: inline-block">&nbsp;/ <span class="link-text" (click)="gotoChatbot()">Chatbot</span></div>
		</div>

		<div *ngIf="supportInfoVisible">
			<br />Customer Support Contacts<br />
			<a href="mailto:support@telephonetimesheets.com">support&#64;telephonetimesheets.com</a>
			<div *ngIf="companyName()">
				<a *ngIf="countryCode === 'GB' || countryCode === 'JE'" href="tel:+448081645656">Call: 0808 164 5656</a>
				<a *ngIf="countryCode === 'US'" href="tel:+18882508463">Call: 1-888-250-8463</a>
			</div>
			<div class="link-text" style="margin-top: 10px" (click)="resetApplication()">Reset Application</div>
		</div>
	</div>
	<div *ngIf="currentAppVersion" class="noprint" style="margin-bottom: 70px">
		<button class="btn btn-sm btn-outline-secondary btn-update" (click)="reloadApplication()">Check For Updates - v{{ currentAppVersion }}</button>
	</div>
</div>
