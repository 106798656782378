export class IntegrationLog {
	id: number
	company_id: number
	log_date: string // YYYY-MM-DD
	log_type: string
	log_table: string
	log_message: string

	constructor(record: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
	}
}
