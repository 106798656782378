<app-full-screen-loader [isLoading]="!isDataLoaded" [loadingMessage]="'Loading Analytics'"></app-full-screen-loader>

<div class="container section-container">
	<!-- Dashboard section header -->
	<app-viz-section-header #dashboardHeader *ngIf="isDataLoaded" (toggleHelp)="toggleHelp()"></app-viz-section-header>

	<!-- Dashboards view -->
	<div *ngIf="viewManager.currentView === 'DASHBOARDS'" class="content-container">
		<!-- Dashboard filter bar -->
		<app-viz-dashboard-filter-bar [isDataLoaded]="isDataLoaded" (updateBtnClicked)="handleFilterUpdate()"></app-viz-dashboard-filter-bar>

		<div *ngIf="isDataLoaded && currentDashboard.data.length === 0" class="no-data-banner">
			<div>Add Data Sources</div>
		</div>

		<div *ngIf="isDataLoaded && currentDashboard.data.length > 0">
			<div *ngFor="let row of currentDashboard.data; let rowIndex = index">
				<!-- Render row of four counter cards -->
				<div *ngIf="row.type === 'COUNTER' && (row.cardData.length === 3 || row.cardData.length === 4)">
					<div class="row">
						<div *ngFor="let data of row.cardData; let cardIndex = index" class="col-12 col-md-6 col-lg-3">
							<app-viz-card-count
								[data]="data"
								[rowIndex]="rowIndex"
								[cardIndex]="cardIndex"
								(cardEvent)="handleCardEvent($event)"
							></app-viz-card-count>
						</div>
					</div>
				</div>
				<div *ngIf="row.type === 'COUNTER' && (row.cardData.length === 1 || row.cardData.length === 2)">
					<div class="row">
						<div *ngFor="let data of row.cardData; let cardIndex = index" class="col-12 col-md-6">
							<app-viz-card-count
								[data]="data"
								[rowIndex]="rowIndex"
								[cardIndex]="cardIndex"
								(cardEvent)="handleCardEvent($event)"
							></app-viz-card-count>
						</div>
					</div>
				</div>
				<!-- Render row of two graphs -->
				<div *ngIf="row.type === 'GRAPH' && row.graphData.length === 2">
					<div class="row">
						<div class="col-12 col-md-6">
							<app-viz-card-graph
								[data]="row.graphData[0]"
								[rowIndex]="rowIndex"
								[cardIndex]="0"
								(cardEvent)="handleCardEvent($event)"
							></app-viz-card-graph>
						</div>
						<div class="col-12 col-md-6">
							<app-viz-card-graph
								[data]="row.graphData[1]"
								[rowIndex]="rowIndex"
								[cardIndex]="1"
								(cardEvent)="handleCardEvent($event)"
							></app-viz-card-graph>
						</div>
					</div>
				</div>

				<!-- Render row of one graph -->
				<div *ngIf="row.type === 'GRAPH' && row.graphData.length === 1">
					<div class="row">
						<div class="col-12">
							<app-viz-card-graph
								[data]="row.graphData[0]"
								[rowIndex]="rowIndex"
								[cardIndex]="0"
								(cardEvent)="handleCardEvent($event)"
							></app-viz-card-graph>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Health view -->
	<div *ngIf="viewManager.currentView === 'HEALTH_CENTER'" class="content-container">
		<app-viz-health-center #healthCenter></app-viz-health-center>
	</div>
</div>

<app-modal-dialog *ngIf="configureGraphDialogManager.isDialogVisible" [dialogManager]="configureGraphDialogManager">
	<app-viz-card-graph-edit [graph]="configureGraphAction.graph"></app-viz-card-graph-edit>
</app-modal-dialog>
