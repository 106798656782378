<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<form class="edit-form-block" novalidate [formGroup]="form" autocomplete="off">
		<!-- Enabled -->
		<app-form-input-switch
			[model]="form.get('enabled').value"
			(modelChange)="form.get('enabled').setValue($event)"
			[wrapStyle]="{ margin: '0px 0px 18px 0px' }"
			[enabledLabel]="'Request is enabled'"
			[disabledLabel]="'Request is disabled'"
		></app-form-input-switch>

		<!-- Descriptions -->
		<div class="options-block" [class.input-required]="!form.get('description').value">
			<div class="options-title-inline">Description</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('description')"></i>

			<div *ngIf="!form.get('description').value" class="float-right">(required)</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="description" />
		</div>

		<!-- Employee Instructions -->
		<div class="options-block" [class.input-required]="!form.get('emp_instructions').value">
			<div class="options-title-inline">Employee Instructions</div>
			<div *ngIf="!form.get('emp_instructions').value" class="float-right">(required)</div>
			<div>
				<textarea
					class="form-control emp-instructions"
					style="height: 210px"
					formControlName="emp_instructions"
					placeholder="Enter instructions for the employee describing what documents are being requested."
				></textarea>
			</div>
		</div>

		<!-- File Count -->
		<div class="options-block" style="margin-top: 28px">
			<div class="d-flex justify-content-between">
				<div>
					<strong>Minimum number of files the employee must provide</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('file_count')"></i>
				</div>
				<input
					class="float-right"
					type="tel"
					style="width: 55px; text-align: right; margin-left: 25px"
					class="form-control"
					placeholder=""
					formControlName="file_count"
				/>
			</div>
		</div>

		<!-- Requires Approval -->
		<div class="checkbox-item d-flex justify-content-between" style="margin-top: 28px">
			<div class="checkbox-wrap">
				<p-checkbox formControlName="requires_approval" [binary]="true"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleCheckbox('requires_approval')" class="has-pointer">Require admin apprroval</strong>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('requires_approval')"></i>
			</div>
		</div>

		<!-- Expires -->
		<div *ngIf="form.get('requires_approval').value" class="checkbox-item d-flex justify-content-between">
			<div class="checkbox-wrap">
				<p-checkbox formControlName="expires" [binary]="true"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleCheckbox('expires')" class="has-pointer">Require expiration on apprroval</strong>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('expires')"></i>
			</div>
		</div>
	</form>
</div>
