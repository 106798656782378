import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { DisplayHelper, log, MapHelper } from '@app/helpers'
import {
	Checkpoint,
	Incident,
	MailingAddress,
	MapEvent,
	ReportRendererConfig,
	ShiftSummaryMapDataSource,
	ShiftSummaryReport,
	ShiftSummaryReportData,
	ShiftSummaryReportEventItem,
	TransactionMetaData,
	TransMetaGeoInfo,
	UserReportHelper,
	UserReportImage,
	UserReportType,
} from '@app/models'
import { DeviceDetectorService } from 'ngx-device-detector'

import moment from 'moment-timezone'
import _ from 'lodash'
import { Global } from '@app/models/global'

class CheckpointWrapper {
	cp: Checkpoint
	images: Array<UserReportImage> = []
	idx: number

	constructor(cp: Checkpoint) {
		this.cp = cp
		this.images = cp.getImages().map((img) => new UserReportImage(img))
	}
}

class ShiftReportWrapper {
	sr: Incident
	cardLabel = ''
	images: Array<UserReportImage> = []

	constructor(sr: Incident) {
		this.sr = sr
		this.cardLabel = UserReportHelper.formatReportTypeLabel(sr.report_type as UserReportType)
		this.images = sr.getImages().map((img) => new UserReportImage(img))
	}
}

class ShiftSummaryReportViewModel {
	isDesktop = false

	reportType: UserReportType
	reportData: ShiftSummaryReportData

	noInfo = '< no information provided >'
	noGps = '< GPS not provided >'

	reportDate = ''
	modifiedDate = ''
	adminNotes = ''

	format12Hour = true
	timezone: string
	timeFormat = 'h:mm a'

	logoUrl: string
	companyName: string
	address: MailingAddress

	showImageDescriptions = true

	checkInHasGps = false
	checkInType = ''
	checkInTypeLabel = ''
	checkInTime = ''
	checkInGeoInfo: TransMetaGeoInfo
	checkInImages: Array<UserReportImage> = []
	checkInNotes = 'Web / Marker'

	checkOutHasGps = false
	checkOutType = ''
	checkOutTypeLabel = ''
	checkOutTime = ''
	checkOutGeoInfo: TransMetaGeoInfo
	checkOutImages: Array<UserReportImage> = []
	checkOutNotes = ''

	checkpoints: Array<CheckpointWrapper> = []
	shiftReports: Array<ShiftReportWrapper> = []

	eventMapDataSource: ShiftSummaryMapDataSource
	eventFilter: MapEvent = null

	isAdminNotesExpanded = true

	constructor(config: ReportRendererConfig) {
		this.setupViewModel(config)
	}

	get checkpointsForDisplays(): Array<CheckpointWrapper> {
		return this.checkpoints // this.eventFilter ? this.checkpoints.filter((cpw) => !!cpw.cp.geo_latitude) : this.checkpoints
	}

	// get hasGps(): boolean {
	// 	const cpHasGps = this.checkpoints.filter((cpw) => !!cpw.cp.geo_latitude).length > 0
	// 	return this.checkInHasGps || this.checkOutHasGps || cpHasGps
	// }

	get hasAdminNotesExpander(): boolean {
		const element = document.getElementById('admin-notes-scrollbox')
		if (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth) {
			return true
		} else {
			return false
		}
	}

	prepareForPrint() {
		// this.eventFilter = null
	}

	setupViewModel(config: ReportRendererConfig) {
		const shiftSummaryReport = config.reportData as ShiftSummaryReport
		this.reportData = shiftSummaryReport.reportData ?? new ShiftSummaryReportData()
		this.reportType = config.reportType
		const adminNotes = shiftSummaryReport.reportData.transaction.notes
		this.adminNotes = DisplayHelper.formatAdminNotesForPreWrap(adminNotes)

		// Setup timezone
		const timezone = shiftSummaryReport.reportData.transaction.timezone ?? moment.tz.guess()
		this.timezone = timezone

		// Setup time formatting
		this.format12Hour = config.formatTime12Hour

		// Setup report date
		const timeFormat = this.format12Hour ? 'h:mm a' : 'HH:mm'
		this.timeFormat = timeFormat
		const created = shiftSummaryReport.reportData.transaction.created
		const modified = shiftSummaryReport.reportData.transaction.updated
		this.reportDate = MapHelper.formatTimestamp(created, timezone, this.format12Hour, '@')
		this.modifiedDate = modified ? MapHelper.formatTimestamp(created, timezone, this.format12Hour, '@') : ''

		// Show Descriptions
		this.showImageDescriptions = true // config.publicView ? false : true

		this.setupHeader(config)
		this.setupMapDataSource(shiftSummaryReport)
		this.setupSectionData(shiftSummaryReport)
		this.setupGeoInfo(shiftSummaryReport) // Be sure to call after setupMapDataSource
	}

	private setupHeader(config: ReportRendererConfig) {
		this.logoUrl = config.logoUrl
		this.address = config.companyAddress
		this.companyName = config.companyName
	}

	private setupSectionData(report: ShiftSummaryReport) {
		const trans = report.reportData.transaction
		this.checkInHasGps = !!trans.geo_start_latitude
		this.checkInTime = this.formatTimestamp(trans.actual_start)
		this.checkInNotes = trans?.geo_start_emp_note
		this.checkInImages = trans?.getImages('IN').map((img) => new UserReportImage(img))

		this.checkOutHasGps = !!trans.geo_end_latitude
		this.checkOutTime = this.formatTimestamp(trans.actual_end)
		this.checkOutNotes = trans?.geo_end_emp_note
		this.checkOutImages = trans?.getImages('OUT').map((img) => new UserReportImage(img))

		this.checkpoints = report.reportData.checkpoints.map((cp) => new CheckpointWrapper(cp))

		// Storing checkpoint index here is needed because indexes used elsewhere may be from lists
		// which filter out checkpoints without GPS data. So we need to setup the index in several
		// places to keep things in sync.

		let cpIndex = 1
		for (const cp of this.checkpoints) {
			cp.idx = cpIndex++
		}

		const shiftReports = _.orderBy(report.reportData.shiftReports, 'created')
		this.shiftReports = shiftReports.map((sr) => new ShiftReportWrapper(sr))
	}

	private setupMapDataSource(report: ShiftSummaryReport) {
		const region = report.reportData.countryCode
		const allowedDelay = report.reportData.gpsAllowedDelay
		const dataSource = new ShiftSummaryMapDataSource()
		dataSource.countryCode = region
		dataSource.gpsAllowedDelay = allowedDelay
		dataSource.jobSite = report.reportData.jobSite
		dataSource.transaction = report.reportData.transaction
		dataSource.checkpoints = report.reportData.checkpoints
		this.eventMapDataSource = dataSource
	}

	private setupGeoInfo(report: ShiftSummaryReport) {
		const trans = this.eventMapDataSource.transaction
		const allowedDelay = this.eventMapDataSource.gpsAllowedDelay
		const inMetaData = new TransactionMetaData('IN', trans)
		this.checkInType = inMetaData.checkInOutType
		this.checkInTypeLabel = inMetaData.checkInOutTypeLabel
		this.checkInGeoInfo = new TransMetaGeoInfo('IN', trans, allowedDelay)
		const outMetaData = new TransactionMetaData('OUT', trans)
		this.checkOutType = outMetaData.checkInOutType
		this.checkOutTypeLabel = outMetaData.checkInOutTypeLabel
		this.checkOutGeoInfo = new TransMetaGeoInfo('OUT', trans, allowedDelay)
	}

	public formatTimestamp(dateTime: string) {
		const timezone = this.timezone
		return MapHelper.formatTimestamp(dateTime, timezone, this.format12Hour, '@')
	}

	public toggleAdminNotesExpander() {
		this.isAdminNotesExpanded = !this.isAdminNotesExpanded
	}

	public viewReport(swr: ShiftReportWrapper) {
		const url = swr.sr.publicReportLink
		window.open(url, '_blank')
	}
}

@Component({
    selector: 'app-shift-summary-report-renderer',
    templateUrl: './shift-summary-report-renderer.component.html',
    styleUrls: ['./shift-summary-report-renderer.component.scss'],
    standalone: false
})
export class ShiftSummaryReportRendererComponent implements OnInit, OnDestroy {
	vm: ShiftSummaryReportViewModel

	@Input() config: ReportRendererConfig
	@Input() publicView = false
	@Output() closeBtnClicked = new EventEmitter<boolean>()

	constructor(
		private cd: ChangeDetectorRef,
		private devDetect: DeviceDetectorService,
	) {}

	ngOnInit(): void {
		log('Render Config', this.config)
		this.vm = new ShiftSummaryReportViewModel(this.config)
		this.cd.detectChanges()
	}

	public handleViewTimelineEvent(mapEvent: MapEvent) {
		log('handleViewTiimelineEvent', mapEvent)
		this.clearTimelineHighlights()
		this.addTimelineHighlight(mapEvent)
		this.vm.eventFilter = mapEvent
		// if (!this.devDetect.isMobile()) this.vm.eventFilter = mapEvent
	}

	public showOnMap(type: 'IN' | 'OUT' | 'CP', cp: Checkpoint = null) {
		const event = new ShiftSummaryReportEventItem(type, cp)
		Global.coreSrvc.eventSrvc.shiftSummaryEventListItemClicked.next(event)
	}

	clearTimelineHighlights() {
		$('.p-timeline-event-marker').removeClass('timeline-highlight')
	}

	addTimelineHighlight(mapEvent: MapEvent) {
		if (!mapEvent) return
		const index = mapEvent.index
		log('Adding Timeline Highlight', index)
		if (!index && index !== 0) return
		const elms = $('.p-timeline-event-marker')
		const elm = elms.eq(index)
		if (elm) {
			// log('Got the element')
			elm.addClass('timeline-highlight')
		}
		// log('Elements', index, elms)
	}

	printPage() {
		this.vm.prepareForPrint()
		setTimeout(() => {
			window.print()
		}, 500)
	}

	ngOnDestroy() {}
}
