<div *ngIf="visible" class="card card-body record-location">
	<div class="row">
		<div class="col-7 record-item">
			<strong>Expected Job Site</strong><br />
			{{ loc.location_id | displayJobSiteName }}
		</div>
		<div class="col-5">
			<button class="btn btn-sm btn-danger btn-block action-button" title="Reject" (click)="reject()">
				Reject <i class="far fa-ban" aria-hidden="true"></i>
			</button>
			<button class="btn btn-sm btn-success btn-block action-button" title="Accept" (click)="accept()">
				Accept <i class="far fa-circle-check" aria-hidden="true"></i>
			</button>
		</div>
	</div>
	<div class="row">
		<div class="col-12 col-sm-7 record-item">
			<strong>Caller ID Name</strong><br />
			<span *ngIf="loc.caller_id_name">{{ loc.caller_id_name }}</span>
			<span *ngIf="!loc.caller_id_name">&lt; not available &gt;</span>
		</div>
		<div class="col-12 col-sm-5 record-item">
			<strong>Caller ID Number</strong><br />
			{{ loc.caller_id_number_e164 | displayPhone }}
		</div>
	</div>
	<div *ngIf="loc.usage" class="row">
		<div class="col-12 record-item">
			<strong>Encounter Info</strong><br />
			{{ encounterNotes() }}
		</div>
	</div>
	<div *ngIf="false" class="alert alert-info">
		<pre><code>{{ loc | json }}</code></pre>
	</div>
</div>
