import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core'
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms'

import { DatabaseService } from '@app/services'
import { DialogManager, ScheduleEntry, ScheduleChangeRequest } from '@app/models'
import { ScheduleChangeFormatter } from '../models'
import { DateTimeHelper, log } from '@app/helpers'

import { Frequency } from 'rrule'
import { SelectItem } from 'primeng/api'

import moment from 'moment-timezone'

class ScheduleInfoViewModel {
	timezone = ''

	jobName = ''
	jobSiteName = ''
	employeeNames = ''

	empCount = 0
	shiftInfo = ''

	freq: Frequency
	formattedFreq = ''
	days = ''
	details = ''

	constructor(dbSrvc: DatabaseService, schedRecur: ScheduleEntry) {
		const job = dbSrvc.jobSrvc.getJobById(schedRecur.job_id)
		if (job) {
			const jobSite = dbSrvc.siteSrvc.getJobSiteById(job.location_id)
			if (jobSite) {
				const tzId = jobSite.timezone_id
				this.timezone = dbSrvc.settingSrvc.getTimezoneZoneNameForId(tzId)

				this.jobName = dbSrvc.jobSrvc.getJobById(schedRecur.job_id)?.description || 'Job Info Missing'
				this.jobSiteName = jobSite.description
				this.employeeNames = ScheduleChangeFormatter.makeEmployeeListString(dbSrvc, schedRecur)

				this.empCount = schedRecur.employee_count || 1
				const shiftTime = ScheduleChangeFormatter.makeShiftTimeInfo(schedRecur.start_time, schedRecur.end_time, this.timezone)
				this.shiftInfo = shiftTime.shiftInfo

				// Setup rule options
				const ruleStr = schedRecur.recurrence
				const ruleOptions = ScheduleChangeFormatter.makeRuleOptions(ruleStr)
				this.freq = ruleOptions.freq
				this.formattedFreq = ScheduleChangeFormatter.formatFrequency(this.freq)
				this.days = ruleOptions.days
				this.details = ruleOptions.details
			}
		}
	}
}

@Component({
    selector: 'app-schedule-changes-edit',
    templateUrl: './changes-edit.component.html',
    styleUrls: ['./changes-edit.component.scss'],
    standalone: false
})
export class ScheduleChangesEditComponent implements OnInit, AfterViewInit {
	showInputData = false

	isNew = false
	isUpdating = false
	is12Hour = DateTimeHelper.format12Hour

	reqForm: UntypedFormGroup

	schedInfo: ScheduleInfoViewModel
	schedRecur = new ScheduleEntry()
	changeRequest = new ScheduleChangeRequest()

	requestStatusOptions: Array<SelectItem> = []

	@Input() dialogManager = new DialogManager()

	@Input() schedRecurId: number
	@Input() changeReqId: number
	@Output() recordUpdated = new EventEmitter<number>()

	constructor(
		private fb: UntypedFormBuilder,
		private dbSrvc: DatabaseService,
	) {}

	ngOnInit(): void {
		this.setupComponent()
		this.setupForm()
		this.makeRequestStatusDropdown()
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.dialogManager.canSubmit = () => this.isFormValid()
			this.dialogManager.submitBtnAction = () => this.submit()
		}, 100)
	}

	setupComponent() {
		// Load the schedule entry and the change request. If no change request provided this
		// indicates the user will be creating a new change request and UI must be exposed
		// for configuring client_id. A new change request cannot be created without a referenced
		// schedule_recur_id

		if (this.schedRecurId) {
			this.schedRecur = this.dbSrvc.schedulerSrvc.getScheduleForId(this.schedRecurId)
			this.schedInfo = new ScheduleInfoViewModel(this.dbSrvc, this.schedRecur)
			log('Incoming Schedule Recur', this.schedRecur)
		} else {
			alert('Missing Schedule Recur ID')
		}

		if (this.changeReqId) {
			this.changeRequest = this.dbSrvc.schedulerSrvc.getChangeRequestForId(this.changeReqId)
			log('Incoming Change Request', this.changeRequest)
		} else {
			this.isNew = true
		}
	}

	setupForm() {
		const req = this.changeRequest || new ScheduleChangeRequest()
		this.reqForm = this.fb.group({
			id: [req.id],
			schedule_id: [req.schedule_id],
			client_id: [req.client_id],
			employee_count: [req.employee_count],
			start_time: [this.makeDateFromTimeString(req.start_time || '09:00:00')],
			end_time: [this.makeDateFromTimeString(req.end_time || '17:00:00')],
			change_start_date: [this.makeDateFromString(req.change_start_date), Validators.required],
			change_end_date: [this.makeDateFromString(req.change_end_date)],
			notes: [req.notes],
			recurrence: [req.recurrence],
			request_status: [req.request_status],
		})
	}

	makeRequestStatusDropdown() {
		this.requestStatusOptions = [
			{ label: 'Pending', value: 'PENDING' },
			{ label: 'Approved', value: 'APPROVED' },
			{ label: 'Rejected', value: 'REJECTED' },
			{ label: 'Cancelled', value: 'CANCELLED' },
		]
	}

	private makeDateFromTimeString(time: string): Date {
		return moment(time, 'HH:mm:ss').toDate()
	}

	private makeTimeStringFromDate(date: Date) {
		return date ? moment(date).format('HH:mm:ss') : null
	}

	private makeDateFromString(date: string): Date {
		return date ? moment(date, 'YYYY-MM-DD').toDate() : null
	}

	private makeDateStringFromDate(date: Date): string {
		return date ? moment(date).format('YYYY-MM-DD') : null
	}

	areChangeDatesValid(): boolean {
		const start = this.reqForm.get('change_start_date').value
		const end = this.reqForm.get('change_end_date').value
		if (end) {
			const startMom = moment(start)
			const endMom = moment(end)
			if (endMom.isBefore(startMom, 'minute')) {
				return false
			}
		}
		return true
	}

	isFormValid(): boolean {
		// Do form checking here and return result. This is linked to the save button
		// enable/disable status in UI. Certain validation may be triggered during
		// the submit process if some sort of alert should be displayed.

		const areChangeDatesValid = this.areChangeDatesValid()

		// Combine checks from above and return value
		return areChangeDatesValid
	}

	submit() {
		const recordId = this.changeReqId
		const record = this.makeUpdateRecord()
		log('Record to Submit', record)
		if (record) {
			this.isUpdating = true
			if (this.isNew) {
				// Insert the record
			} else {
				this.dbSrvc.updateRecord('schedule_change_request', record).then((success) => {
					if (success) {
						this.recordUpdated.emit(recordId)
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			}
		}
	}

	makeUpdateRecord(): ScheduleChangeRequest {
		const form = this.reqForm.value
		const record = new ScheduleChangeRequest(form)

		const startTimeDate = this.reqForm.get('start_time').value
		const endTimeDate = this.reqForm.get('end_time').value

		record.start_time = this.makeTimeStringFromDate(startTimeDate)
		record.end_time = this.makeTimeStringFromDate(endTimeDate)

		const changeStartDate = this.reqForm.get('change_start_date').value
		const changeEndDate = this.reqForm.get('change_end_date').value

		record.change_start_date = this.makeDateStringFromDate(changeStartDate)
		record.change_end_date = this.makeDateStringFromDate(changeEndDate)

		return record
	}

	changeDurationNote(): string {
		const start = this.reqForm.get('change_start_date').value
		const end = this.reqForm.get('change_end_date').value
		const startString = moment(start).format('YYYY-MM-DD')
		const endString = end ? moment(end).format('YYYY-MM-DD') : null
		if (startString === endString) {
			return 'Requesting single day change'
		}
		if (startString && !endString) {
			return 'Requesting permanent change'
		}
		return 'Requesting temporary change'
	}
}
