<div class="container">
	<div class="card card-body">
		<div *ngIf="quickbooksIntegrating()">
			<div class="qbio-block">
				<div class="qbio-spinner"><i class="fas fa-loader fa-spin" aria-hidden="true"></i></div>
				<div class="qbio-header">Integrating QuickBooks Online</div>
			</div>
			<div class="qbio-note text-muted">Click <a href="#" (click)="reloadApplication()">here</a> when finished authenticating.</div>
		</div>

		<div *ngIf="!dataLoaded() || isUpdating">
			<div class="banner-text"><i class="fas fa-loader fa-spin" aria-hidden="true"></i> Loading Data</div>
		</div>

		<div [hidden]="quickbooksIntegrating() || isUpdating" *ngIf="!quickbooksIntegrated() && dataLoaded()" class="row">
			<div class="col-12 record-item">
				<strong class="settings-card-title"> <i class="fa fa-list-alt" aria-hidden="true"></i> QuickBooks</strong>
				<button
					*ngIf="false"
					(click)="editBtnClicked()"
					class="float-right btn btn-sm btn-success"
					[class.disabled]="!canPerformAction(CrudAction.update)"
				>
					Edit
				</button>
				<hr />

				<div [hidden]="integratingDesktop" style="padding-top: 10px; text-align: center">
					<div style="max-width: 110px; margin: auto; display: none">
						<div style="margin-bottom: 15px" (click)="qboIntegrating = true">
							<div id="qb-button-container" #qbContainer></div>
						</div>
					</div>
					<div [hidden]="qboConnectReady" class="qbd-btn setup-btn">
						<div style="margin-top: 5px"><i class="fas fa-loader fa-spin"></i> Setting up...</div>
					</div>
					<div [hidden]="!qboConnectReady" style="margin-bottom: 15px; cursor: pointer">
						<img [src]="qboImgSrc" (mouseover)="qboBtnMouseOver()" (mouseout)="qboBtnMouseOut()" (click)="qboConnectBtnClicked()" />
					</div>
					<div>Click the button above to integrate with QuickBooks Online.</div>
					<hr />
					<div style="width: 100%; margin-bottom: 15px">
						<button
							class="btn btn-sm btn-secondary qbd-btn"
							(click)="startIntegratingDesktop()"
							[class.disabled]="!canPerformAction(CrudAction.create)"
						>
							Use QuickBooks Desktop
						</button>
					</div>
					<div>Click the button above to integrate with QuickBooks Desktop.</div>

					<!-- <div class="col-12">
					<button class="btn btn-sm btn-secondary btn-block" style="margin-bottom: 15px" (click)="startIntegratingOnline()" [class.disabled]="!canPerformAction('create')">Integrate QuickBooks Online</button>

				</div>
				<div class="col-12" style="text-align: center;">
					
				</div> -->
				</div>
				<!-- <div [hidden]="integratingDesktop" style="line-height: 1.3em;">
				<hr> To begin using QuickBooks with Telephone Timesheets you must first choose your preferred integration method. Click the 'Connect to QuickBooks' button to use QuickBooks Online. Click 'Use QuickBooks Desktop' button to use QuickBooks Desktop.
			</div> -->
				<div [hidden]="!integratingDesktop" style="line-height: 1.3em">
					To get started with QuickBooks Desktop integration, please upload your QuickBooks data file in the section below. Your data file will
					be uploaded to the system and you'll be able to view any errors encountered during the upload. To update your information, simply
					upload a newer version of your data file.
					<br />
					<br />
					<button (click)="integratingDesktop = false; showUploadBox = false; (false)" class="btn btn-sm btn-danger">Cancel</button>
				</div>
			</div>
		</div>
		<div [hidden]="isUpdating" *ngIf="quickbooksIntegrated() && dataLoaded()">
			<strong class="settings-card-title"> <i class="fa fa-list-alt" aria-hidden="true"></i> QuickBooks {{ integrationType }}</strong>
			<button
				*ngIf="!showDelete"
				(click)="showDelete = !showDelete"
				class="float-right btn btn-sm btn-success"
				[disabled]="!canPerformAction(CrudAction.update)"
			>
				Edit
			</button>
			<button *ngIf="showDelete" (click)="showDelete = !showDelete" class="float-right btn btn-sm btn-primary">Done</button>
			<!-- <div style="margin-top: -8px; color: #999"><small><strong>{{ integrationType }} Version</strong></small></div> -->
			<hr />
			<div [hidden]="showDelete">
				<div *ngIf="!uploadingFile">
					<div *ngIf="isQbDesktopIntegrated || isQbOnlineIntegrationValid">
						<!-- <app-qboemployee-data (reloadRequested)="reloadSyncData($event)"></app-qboemployee-data>
					<app-qbocustomer-data (reloadRequested)="reloadSyncData($event)"></app-qbocustomer-data> -->
						<app-qboemployee-data></app-qboemployee-data>
						<app-qbocustomer-data></app-qbocustomer-data>
						<app-qbovendor-data [hidden]="syncData && syncData.desktop_sync"></app-qbovendor-data>
						<app-qboservice-item-data></app-qboservice-item-data>
						<app-qbotransaction-data [hidden]="syncData && syncData.desktop_sync"></app-qbotransaction-data>
					</div>
					<div *ngIf="!isQbDesktopIntegrated && !isQbOnlineIntegrationValid">
						There seems to be an issue with your QuickBooks integration. If your integration attempt did not complete successfully, you may
						remove integration and try again by clicking the
						<strong>Edit</strong> button and selecting <strong>Delete Quickbooks Link</strong>.<br /><br /><strong style="color: firebrick"
							>Warning:</strong
						>
						If QuickBooks Online was previously working properly,
						<strong style="color: firebrick">DO NOT</strong> remove integration, please contact support so we may resolve this issue.
					</div>
				</div>
				<div *ngIf="uploadingFile">
					<strong style="color: #999">Processing QuickBooks Data File.</strong>
				</div>
			</div>
			<div [hidden]="!showDelete">
				<app-qbodelete-link (disconnect)="onDisconnected($event)"></app-qbodelete-link>
			</div>
		</div>
	</div>

	<!-- Section containing upload box and Desktop Payroll Items form -->

	<div *ngIf="showUploader()" [hidden]="showDelete">
		<div class="options-header" style="margin: 16px 0px">QuickBooks Desktop File Upload</div>

		<div>
			<app-qbdfile-uploader
				(uploadComplete)="uploadingFile = false; finishedIntegrating()"
				(uploadStarted)="uploadingFile = true"
			></app-qbdfile-uploader>
		</div>

		<br />

		<form (keydown.enter)="$event.preventDefault()">
			<div *ngIf="quickbooksIntegrated()" style="margin-top: 10px">
				<hr style="margin-bottom: 25px" />

				<div class="row">
					<div class="col-12">
						<div class="options-header" style="margin: 16px 0px; display: inline-block">QuickBooks Desktop Payroll Items</div>
						<i
							title="More Information"
							class="far fa-info-circle tts-info-btn"
							aria-hidden="true"
							(click)="showPayrollItemsTooltip = !showPayrollItemsTooltip"
						></i>
					</div>
				</div>

				<div class="row">
					<div class="col-12">
						<div *ngIf="showPayrollItemsTooltip" class="card card-body tooltip-block tooltip-info-text" style="margin-top: 20px">
							<button type="button" class="close" aria-label="Close" (click)="showPayrollItemsTooltip = false; (false)">
								<span aria-hidden="true">&times;</span>
							</button>
							Telephone Timesheets has the ability to break down employee hours into the 4 categories you see here. If you use QuickBooks
							Payroll Items to break down pay by any of these categories, you will need to enter the
							<strong style="color: firebrick">exact</strong> names that you use in QuickBooks (including capitalization). It is critical that
							these mappings are done correctly. Please contact support if you have any questions.
							<br />
							<br />
							<strong class="tooltip-step">Note:</strong> You must enter your Payroll Items in the order listed. Some fields depend on other
							fields and will be grayed out if the dependent field is empty. Leave blank to use system defaults.
						</div>
					</div>
				</div>

				<div class="row" style="margin-top: 20px">
					<div class="col-12 col-sm-6">
						<fieldset [attr.disabled]="disablePayrollReg">
							<div class="form-group">
								<label for="payrollReg">
									<strong>1) Regular</strong>
								</label>
								<input
									[(ngModel)]="payrollRegInput"
									(keyup)="checkPayrollItems()"
									name="payrollReg"
									id="payrollReg"
									type="text"
									class="form-control"
								/>
							</div>
						</fieldset>
					</div>
					<div class="col-12 col-sm-6">
						<fieldset [attr.disabled]="disablePayrollOT">
							<div class="form-group">
								<label for="payrollOT">
									<strong>2) Overtime</strong>
								</label>
								<input
									[(ngModel)]="payrollOTInput"
									(keyup)="checkPayrollItems()"
									name="payrollOT"
									id="payrollOT"
									type="text"
									class="form-control"
								/>
							</div>
						</fieldset>
					</div>
				</div>

				<div class="row" style="margin-top: 8px">
					<div class="col-12 col-sm-6">
						<fieldset [attr.disabled]="disablePayrollHol">
							<div class="form-group">
								<label for="payrollHol">
									<strong>3) Holiday</strong>
								</label>
								<input
									[(ngModel)]="payrollHolInput"
									(keyup)="checkPayrollItems()"
									name="payrollHol"
									id="payrollHol"
									type="text"
									class="form-control"
								/>
							</div>
						</fieldset>
					</div>
					<div class="col-12 col-sm-6">
						<fieldset [attr.disabled]="disablePayrollHolOT">
							<div class="form-group">
								<label for="payrollHolOT">
									<strong>4) Holiday + Overtime</strong>
								</label>
								<input
									[(ngModel)]="payrollHolOTInput"
									(keyup)="checkPayrollItems()"
									name="payrollHolOT"
									id="payrollHolOT"
									type="text"
									class="form-control"
								/>
							</div>
						</fieldset>
					</div>
				</div>

				<div class="row" style="margin-top: 15px">
					<div class="col-12">
						<button (click)="savePayrollItems()" class="btn btn-success">Save</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>

<p-dialog header="Edit" [(visible)]="showEditDialog" [modal]="false" [style]="{ width: '300px' }" [dismissableMask]="true">
	You must first integrate with QuickBooks before you can edit your settings.
</p-dialog>
