import { Injectable, EventEmitter } from '@angular/core'
import { BreakProfileRecord, BreaksViewManager } from '@app/models'

import { log } from '@app/helpers'
import _ from 'lodash'
import { SelectItem } from 'primeng/api/selectitem'
import { Global } from '@app/models/global'

@Injectable({
	providedIn: 'root',
})
export class BreakProfileService {
	dataLoaded = false
	list: Array<BreakProfileRecord> = []
	listChange = new EventEmitter<Array<BreakProfileRecord>>()
	lastUpdated: Date = new Date()

	viewManager = new BreaksViewManager()

	constructor() {
		log('Creating BaseService')
		this.list.push(new BreakProfileRecord(profileMock))
	}

	clearData() {
		this.list = []
		this.dataLoaded = false
		this.lastUpdated = null
	}

	getBreakProfileRecords(): Array<BreakProfileRecord> {
		const result = _.sortBy(this.list, 'name')
		return result
	}

	getBreakProfileRecordById(id: number) {
		return this.list.find((rec) => rec.id === id)
	}

	removeBreakProfileRecord(recordId: number) {
		this.list = this.list.filter((rec) => rec.id !== recordId)
	}

	setBreakProfileRecords(records: Array<BreakProfileRecord>) {
		this.lastUpdated = new Date()
		this.list = records.map((rec) => new BreakProfileRecord(rec))
		this.dataLoaded = true
		this.listChange.next(this.list)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateBreakProfileRecords(records: Array<BreakProfileRecord>) {
		const newRecords = records.map((rec) => new BreakProfileRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.list.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.list.push(newRecord)
			}
		}
		this.lastUpdated = new Date()
		this.listChange.next(this.list)
	}
}

const profileMock = {
	id: 1,
	description: 'Break Profile Mockup',
	job_ids: [15792, 15791, 15793],
	rules_json: `[{"description":"First Rest Break","managedByType":"SYSTEM","payrollType":"PAID","offset":"2:00","durationInMin":15,"startBreakReminder":false,"startBreakReminderOffset":null,"endBreakReminder":false,"endBreakReminderOffset":null,"flagBreakNotStartedBy":false,"flagBreakNotStartedByOffset":null,"flagShortBreak":false,"flagShortBreakDuration":null,"confirmEndBreakEarly":false},{"description":"Lunch Break","managedByType":"EMPLOYEE","payrollType":"UNPAID","offset":"4:00","durationInMin":30,"startBreakReminder":false,"startBreakReminderOffset":null,"endBreakReminder":false,"endBreakReminderOffset":null,"flagBreakNotStartedBy":false,"flagBreakNotStartedByOffset":null,"flagShortBreak":false,"flagShortBreakDuration":null,"confirmEndBreakEarly":false},{"description":"Second Rest Break","managedByType":"SYSTEM","payrollType":"PAID","offset":"6:00","durationInMin":15,"startBreakReminder":false,"startBreakReminderOffset":null,"endBreakReminder":false,"endBreakReminderOffset":null,"flagBreakNotStartedBy":false,"flagBreakNotStartedByOffset":null,"flagShortBreak":false,"flagShortBreakDuration":null,"confirmEndBreakEarly":false}]`,
}
