import { AdpRateCodeSelectItem, EmpJobPayRateRecord, OrgJobPayRateRecord } from '@app/models'
import { DatabaseService } from '@app/services'
import { SelectItem } from 'primeng/api'

export class PayRateHelper {
	static isValidPayRate(value: number): boolean {
		if (!value) {
			return false
		}
		return true
	}
	static getPayRateIdFromTag(tagId: string): number {
		return Number(tagId.split('_')[1])
	}
}

export class PayRateTableFormatter {
	static payRateInput(payRate: EmpJobPayRateRecord | OrgJobPayRateRecord, symbol: string, canEdit: boolean): string {
		// const onBlur = `${bridgeTable}.updatePayRateIputForTagId('${tagId}')`
		const rate = payRate.pay_rate?.toFixed(2) ?? ''
		const tagId = `payrate-input-id_${payRate.id}`
		const tagClass = `form-control pay-rate-input pay-rate-table-input`
		const currencyHtml = `<span class="pay-rate-symbol tts-hl-2">${symbol}</span>`
		const inputHtml = `<input type="number" id="${tagId}" class="${tagClass}" placeholder="${rate}" value="${rate}" onfocus="this.select()" />`
		const nonInputStyle = `display:inline-block;line-height:1em;background-color:#efefef;`
		const nonInputHtml = `<div class="${tagClass}" style="${nonInputStyle}">${rate ? rate : '&nbsp;'}</div>`
		const sortHtml = `<span style="display:none;">${rate}</span>`
		const resultHtml = `${sortHtml}${currencyHtml} ${canEdit ? inputHtml : nonInputHtml}`
		return resultHtml
	}

	static payRateCode(
		dbSrvc: DatabaseService,
		payRate: EmpJobPayRateRecord,
		codeOptions: Array<AdpRateCodeSelectItem>,
		bridgeTable: string,
		bridgeFunction: string,
		canEdit: boolean
	) {
		const rateCodeId = payRate.adp_rate_code_id
		const rateCodeShortName = dbSrvc.adpSrvc.getAdpRateCodeForId(payRate.adp_rate_code_id)?.shortName ?? ''
		// if (!rateCodeId) return '<span style="font-weight: bold; color: chocolate">&lt; not set &gt;</span>'
		// return dbSrvc.adpSrvc.getAdpRateCodeForId(payRate.adp_rate_code_id)?.shortName ?? ''

		const styles = `max-width: 350px`
		const classes = `form-control prt-code-select`
		const onChangeHandler = `${bridgeTable}.${bridgeFunction}(${payRate.id}, this.value)`

		let select = `<select class="${classes}" style="${styles}" onchange="${onChangeHandler}" ${canEdit ? '' : 'disabled'}>`
		select += rateCodeId ? '' : `<option value="null" selected>Select Rate</option>`
		for (const rateCode of codeOptions) {
			const isSelected = payRate.adp_rate_code_id === rateCode.data.id ? 'selected' : ''
			select += `<option value="${rateCode.data.id}" ${isSelected}>${rateCode.data.shortName}</option>`
		}
		select += `</select>`

		const sortHtml = `<span style="display:none">#${rateCodeShortName}</span>`
		return `<div>${sortHtml}${select}</div>`
	}
}
