import moment from 'moment-timezone'
import { DateTimeHelper } from './datetime'

interface ShiftLengthComponents {
	hr: string
	min: string
}
export class ScheduleHelper {
	static calculateShiftLength(timezone: string, dateStr: string, start: string, end: string): ShiftLengthComponents {
		const startStr = dateStr + 'T' + start
		const startMom = moment.tz(startStr, timezone)
		const endStr = dateStr + 'T' + end
		const endMom = moment.tz(endStr, timezone)
		if (endMom.isBefore(startMom, 'minutes')) {
			endMom.add(1, 'day')
		}
		const duration = moment.duration(endMom.diff(startMom))
		const result = DateTimeHelper.formatDurationInHoursAndMinutes('H:mm', duration)
		const comps = result.split(':')
		return { hr: comps[0] || null, min: comps[1] || null }
	}
}
