<!-- <div class="alert alert-warning">{{ rRuleCalculator.rRule.options.byweekday | json }}</div> -->
<!-- <div class="alert alert-warning">
	<pre><code>{{ rRuleCalculator.rRule.options | json }}</code></pre>
</div> -->
<div>
	<div class="d-flex justify-content-between" style="margin: 0px 0px 15px 0px">
		<div class="tts-hl-2" style="font-size: 1.4em">Schedule Snapshot</div>
		<div class="d-flex justify-content-between" style="width: 60px; position: relative; top: -3px">
			<div><i class="fa fa-arrow-left step-icon" (click)="previousMonth()"></i></div>
			<div><i class="fa fa-arrow-right step-icon" (click)="nextMonth()"></i></div>
		</div>
	</div>

	<div class="d-flex justify-content-between">
		<div>
			<i
				[title]="isExpanded ? 'Compress List' : 'Expand List'"
				class="fa expand-icon"
				[class.fa-expand]="!isExpanded"
				[class.fa-compress]="isExpanded"
				[class.expand-disable]="rowData.length < 7"
				(click)="isExpanded = !isExpanded"
			></i>
		</div>
		<div style="width: 140px">
			<p-select
				appScrollLockSelect
				id="sched-snapshot-month"
				[(ngModel)]="selectedMonth"
				[options]="monthOptions"
				[filter]="false"
				(onChange)="updateRows()"
			></p-select>
		</div>
		<div style="width: 100px">
			<p-select
				appScrollLockSelect
				id="sched-snapshot-year"
				[(ngModel)]="selectedYear"
				[options]="yearOptions"
				[filter]="false"
				(onChange)="updateRows()"
			></p-select>
		</div>
	</div>

	<div class="data-view" [class.data-view-box]="!isExpanded">
		<table class="table table-sm" style="max-width: 600px; margin: auto">
			<tr *ngFor="let row of rowData; let idx = index" style="text-align: center" [class.row-b]="idx % 2 === 0">
				<td>{{ row.dayName }}</td>
				<td>{{ row.dayDate }}</td>
				<td>{{ row.month }}</td>
				<td>{{ row.year }}</td>
			</tr>
		</table>

		<div *ngIf="!hasScheduledDates" class="data-view-empty">{{ statusMessage }}</div>
	</div>

	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="hidePastDates" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong (click)="hidePastDates = !hidePastDates" class="has-pointer">Hide past schedule dates</strong>
			<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('requireChecklist')"></i> -->
		</div>
	</div>

	<div *ngIf="false">
		<br />
		<div *ngIf="true" class="alert alert-info">
			<h2>Rule Calc Options</h2>
			<pre><code>{{ rRuleCalculator | json }}</code></pre>
		</div>
		<div class="alert alert-info">
			<h2>Timezone</h2>
			<pre><code>{{ rRuleCalculator.timezone }}</code></pre>
		</div>
		<div *ngIf="false" class="alert alert-info">
			<h2>Rule Object</h2>
			<pre><code>{{ rRuleCalculator.rRule | json }}</code></pre>
		</div>
	</div>
</div>
