import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit } from '@angular/core'
import { DatabaseTableName } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { log } from '@app/helpers'

@Component({
    selector: 'app-delete-record-dialog',
    templateUrl: './delete-record-dialog.component.html',
    styleUrls: ['./delete-record-dialog.component.scss'],
    standalone: false
})
export class DeleteRecordDialogComponent implements OnInit, AfterViewInit {
	@Input() tableName: DatabaseTableName
	@Input() recordId: number
	@Input() recordLabel: string
	@Input() itemType = 'record'
	@Input() showDeleteRecordDialog = false
	@Input() confirmationMsg = 'This record requires explict confirmation. Please confirm you wish to delete this record.'
	@Input() showConfirmation = false

	@Output() cancelDelete = new EventEmitter<boolean>()
	@Output() actionComplete = new EventEmitter<boolean>()

	deleteInProgress = false

	constructor(
		private cd: ChangeDetectorRef,
		private coreSrvc: CoreService,
	) {}

	ngOnInit() {
		// if (!this.itemType) { this.itemType = 'record' }
	}

	ngAfterViewInit() {
		$('.ui-dialog-titlebar-close').hide()
	}

	deleteActionCancelled() {
		log('Delete Dialog - Delete action cancelled')
		this.recordId = null
		this.cancelDelete.emit(true)
		this.showDeleteRecordDialog = false
		this.showConfirmation = false
		this.coreSrvc.notifySrvc.clear()
		this.cd.detectChanges()
	}

	confirmDeleteButtonClicked() {
		this.coreSrvc.notifySrvc.clear()
		this.deleteInProgress = true
		const tableName = this.tableName
		switch (tableName) {
			case 'reports_delete':
				this.deleteFromReports()
				break
			case 'reports_log_delete':
				this.deleteFromReportHistory()
				break
			case 'users':
				this.deleteFromDataAccess(false)
				break
			case 'transaction_log':
				this.deleteFromTransaction()
				break
			default:
				this.deleteFromDataAccess(true)
		}
	}

	deleteFromDataAccess(shouldDeleteLocal: boolean) {
		this.coreSrvc.dbSrvc
			.deleteRecord(this.tableName, this.recordId)
			.then((success) => {
				if (success) {
					if (shouldDeleteLocal) {
						this.coreSrvc.dbSrvc.removeLocalRecord(this.tableName, this.recordId)
					}
					this.actionComplete.emit(true)
					this.recordId = null
				} else {
					this.actionComplete.emit(false)
				}

				// this.deleteInProgress = false
			})
			.catch((error) => {
				this.actionComplete.emit(false)
			})
	}

	deleteFromTransaction() {
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(this.recordId)
		const jobDate = trans?.job_date ?? null
		const options = this.showConfirmation ? { date_lock_override: true, job_date: jobDate } : { job_date: jobDate }

		this.coreSrvc.dbSrvc
			.deleteRecord(this.tableName, this.recordId, options)
			.then((success) => {
				if (success) {
					this.coreSrvc.dbSrvc.removeLocalRecord(this.tableName, this.recordId)
					this.actionComplete.emit(true)
					this.recordId = null
				} else {
					this.deleteInProgress = false
					this.showConfirmation = true
				}
			})
			.catch((error) => {
				log('deleteFromTransaction ERROR', error)

				if (error?.errorType === 'com.sst.ivr.lambda.exceptions.TransactionDateLockException') {
					this.deleteInProgress = false
					this.showConfirmation = true
				} else if (error?.errorType === 'com.sst.ivr.lambda.exceptions.ModifyArchivedTransException') {
					this.deleteInProgress = false
				} else {
					setTimeout(() => {
						this.deleteInProgress = false
					}, 500)
				}
			})
	}

	deleteFromReports() {
		log('Delete Report', this.recordId)
		this.coreSrvc.dbSrvc.reportSrvc.deleteReportById(this.recordId).then((success) => {
			this.coreSrvc.dbSrvc.removeLocalRecord(this.tableName, this.recordId)
			this.actionComplete.emit(true)
			this.recordId = null
			this.deleteInProgress = false
		})
	}

	deleteFromReportHistory() {
		log('Delete Report History', this.recordId)
		this.coreSrvc.dbSrvc.reportSrvc.deleteReportHistoryById(this.recordId).then((success) => {
			this.coreSrvc.dbSrvc.removeLocalRecord(this.tableName, this.recordId)
			this.actionComplete.emit(true)
			this.recordId = null
			this.deleteInProgress = false
		})
	}
}
