import { Component, AfterViewInit, Input, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, NgZone } from '@angular/core'
import { Router } from '@angular/router'

import {
	ReportRecord,
	AccessPermission,
	AccessPermissions,
	AccessPermissionOption,
	CrudAction,
	DatabaseTableName,
	PermissionResolver,
	ReportHistoryRecord,
	ReportsViewManager,
	ReportsFilterInfoEvent,
	ComponentBridgeName,
} from '@app/models'
import { CoreService, DatabaseService, PrefsService } from '@app/services'

import {
	log,
	DisplayHelper,
	ReportHelper,
	TableActionFormatter,
	GeneralTableHelper,
	ReportsTableFormatter,
	DateTimeHelper,
	DataTablesHelper,
} from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'
import { environment } from '@env/environment'

import { Subscription } from 'rxjs'

import _ from 'lodash'

enum RepTableColumnIndex {
	name,
	type,
	frequency,
	filters,
	lastRun,
	nextRun,
	notifyText,
	notifyEmail,
	owner,
	actions,
}

@Component({
    selector: 'app-insight-report-table',
    templateUrl: './insight-report-table.component.html',
    styleUrls: ['./insight-report-table.component.scss'],
    standalone: false
})
export class InsightReportTableComponent implements OnDestroy, AfterViewInit {
	bridgeName: ComponentBridgeName = 'ngBridgeReportsTable'

	accessHelper: AccessHelper
	permResolver: PermissionResolver
	canAccessAuditLog = false

	@Input() list: ReportRecord[] = []

	deleteAction = {
		tableName: 'reports_delete' as DatabaseTableName,
		recordId: null,
		recordLabel: null,
		showDeleteRecordDialog: false,
	}
	editAction = { action: 'edit', recordId: null, header: '', footer: '', showDialog: false }

	isRunningReport = false

	private reportsTable // : DataTables.DataTable;
	private defaultSortOrder = [[RepTableColumnIndex.name, 'asc']]
	private subs = new Subscription()

	constructor(
		public router: Router,
		private zone: NgZone,
		private cd: ChangeDetectorRef,
		private coreSrvc: CoreService,
		private prefsService: PrefsService,
	) {
		window[this.bridgeName] = this

		this.setupSubscriptions()
		this.setupAccessPermissions()

		this.loadData()
	}

	get viewManager(): ReportsViewManager {
		return this.coreSrvc.dbSrvc.reportSrvc.viewManager
	}

	get showNoPersonalReportsNotice(): boolean {
		return !this.viewManager.showAllReports && this.list.length === 0
	}

	ngAfterViewInit() {
		this.initTable()

		$('#reportsTable_filter input').attr('placeholder', ' filter')
		$('#reportsTable_filter input').addClass('search-field-input')

		setTimeout(() => {
			$('.rep-clear-search-icon').detach().appendTo('#reportsTable_filter label')
			this.coreSrvc.displaySrvc.enableAllTooltips()
		})

		// Add row details listener for tooltips
		this.reportsTable.on('responsive-display', (e, datatable, row, showHide, update) => {
			log('Details for row ' + row.index() + ' ' + (showHide ? 'shown' : 'hidden'))
			this.coreSrvc.displaySrvc.enableAllTooltips()
		})
	}

	ngOnDestroy() {
		this.reportsTable['fixedHeader'].disable()
		window[this.bridgeName] = null
		this.subs.unsubscribe()
	}

	private setupSubscriptions() {
		// Subscribe to report run events
		this.subs.add(
			this.coreSrvc.dbSrvc.reportSrvc.reportRunStart.subscribe((result) => {
				this.isRunningReport = true
				this.cd.detectChanges()
			}),
		)

		// Subscribe to report run complete
		this.subs.add(
			this.coreSrvc.dbSrvc.reportSrvc.reportRunComplete.subscribe((result) => {
				setTimeout(() => {
					this.zone.run(() => {
						log('Report Run Complete')
						this.isRunningReport = false
					})
				}, 2000)
			}),
		)

		// Subscribe to report save complete
		this.subs.add(
			this.coreSrvc.dbSrvc.reportSrvc.reportSaveComplete.subscribe((event) => {
				this.zone.run(() => {
					if (this.coreSrvc.dbSrvc.reportSrvc.viewManager.pulseFirstRow) {
						this.coreSrvc.dbSrvc.readTable('reports_log').then((success) => {
							// If I'm not the owner of the saved report, will need to show all reports when switching to view.
							const myUserId = this.coreSrvc.dbSrvc.settingSrvc.getMyUser().id
							if (myUserId !== event.record.supervisor) {
								this.coreSrvc.dbSrvc.reportSrvc.viewManager.showAllReports = true
							}
							this.coreSrvc.dbSrvc.reportSrvc.viewManager.dayViewDate = null
							this.coreSrvc.dbSrvc.reportSrvc.viewManager.setView('VIEW')
							this.coreSrvc.dbSrvc.reportSrvc.viewManager.sectionTabNeedsUpdate.next(true)
						})
					} else {
						this.reloadTable()
						this.editAction.showDialog = false
					}
				})
			}),
		)

		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'report')
		this.permResolver = new PermissionResolver(this.coreSrvc.dbSrvc, 'reports')
		this.canAccessAuditLog = this.accessHelper.canPerformAction(CrudAction.read, true, 'audit')
		log('PermissionResolver', this.permResolver)
	}

	private handleScreenSizeChanges() {
		if (this.reportsTable) {
			this.reportsTable.columns.adjust().responsive.recalc()
			this.reportsTable.fixedHeader.adjust()
		}
	}

	canPerformAction(action: CrudAction, isMyRecord: boolean) {
		return this.accessHelper.canPerformAction(action, isMyRecord)
	}

	runReport(id: number, standardReport: boolean) {
		// if (!this.canPerformAction(CrudAction.read, false)) { this.notifyOperationNotAuthorized(); return }
		const report = this.coreSrvc.dbSrvc.reportSrvc.getReportById(id)
		const canRun = this.permResolver.canPerformAction(CrudAction.read, report)
		if (!canRun) {
			this.notifyOperationNotAuthorized()
			return
		}
		log(`${standardReport ? 'Running standard report' : 'Running current period'}`)
		this.coreSrvc.dbSrvc.reportSrvc.reportRunStart.emit(true)
		this.coreSrvc.dbSrvc.reportSrvc.runReportById(id, standardReport).then((success) => {
			setTimeout(() => {
				this.coreSrvc.dbSrvc.readTable('reports_log').then((success) => {
					this.coreSrvc.dbSrvc.reportSrvc.reportRunComplete.emit(true)
					this.viewManager.dayViewDate = null
					this.viewManager.pulseFirstRow = true
					this.coreSrvc.dbSrvc.reportSrvc.viewManager.setView('VIEW')
					this.coreSrvc.dbSrvc.reportSrvc.viewManager.sectionTabNeedsUpdate.next(true)
				})
			}, 500)
		})
	}

	createRecord() {
		// if (!this.canPerformAction(CrudAction.create, true)) { this.notifyOperationNotAuthorized(); return }
		const canCreate = this.permResolver.canPerformAction(CrudAction.create, null)
		if (!canCreate) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.editAction.action = 'new'
		this.editAction.header = 'Add Report'
		this.editAction.footer = 'Create a new report'
		this.editAction.showDialog = true
	}

	editRecord(id: number) {
		// if (!this.canPerformAction(CrudAction.update, false)) { this.notifyOperationNotAuthorized(); return }
		const record = this.coreSrvc.dbSrvc.reportSrvc.getReportById(id)
		const canEdit = this.permResolver.canPerformAction(CrudAction.update, record)
		if (!canEdit) {
			this.notifyOperationNotAuthorized()
			return
		}
		const rep = this.coreSrvc.dbSrvc.reportSrvc.getReportById(id)
		this.editAction.action = 'edit'
		this.editAction.recordId = id
		this.editAction.header = 'Edit Report'
		this.editAction.footer = rep.name
		this.editAction.showDialog = true
	}

	deleteRecord(id: number) {
		// if (!this.canPerformAction(CrudAction.delete, false)) { this.notifyOperationNotAuthorized(); return }
		const record = this.coreSrvc.dbSrvc.reportSrvc.getReportById(id)
		const canDelete = this.permResolver.canPerformAction(CrudAction.delete, record)
		if (!canDelete) {
			this.notifyOperationNotAuthorized()
			return
		}
		if (record) {
			log('Report', record)
			this.deleteAction.recordId = id
			this.deleteAction.recordLabel = record.name
			this.deleteAction.showDeleteRecordDialog = true
			this.cd.detectChanges()
		}
	}

	deleteActionCancel() {
		log('Cancel Delete')
		this.deleteAction.showDeleteRecordDialog = false
		// this.cd.detectChanges()
	}

	deleteActionComplete() {
		log('Delete action complete')
		this.deleteAction.showDeleteRecordDialog = false
		this.list = this.coreSrvc.dbSrvc.reportSrvc.getReports()
		this.updateTable()
		this.cd.detectChanges()
	}

	cloneRecord(id: number) {
		const record = this.coreSrvc.dbSrvc.reportSrvc.getReportById(id)
		const canDelete = this.permResolver.canPerformAction(CrudAction.delete, record)
		if (!canDelete) {
			this.notifyOperationNotAuthorized()
			return
		}
		if (record) {
			this.editAction.recordId = id
			this.editAction.action = 'clone'
			this.editAction.header = 'Copy Report'
			this.editAction.footer = record.name
			this.editAction.showDialog = true
		}
	}

	loadData() {
		const list = this.coreSrvc.dbSrvc.reportSrvc.getReports()
		log('Unfiltered List', list)
		let filteredList = list.filter((rec) => this.permResolver.canPerformAction(CrudAction.read, rec))
		// If not forceing all reports, then only show mine
		if (!this.viewManager.showAllReports) {
			const myUserId = this.coreSrvc.dbSrvc.settingSrvc.getMyUser().id
			filteredList = filteredList.filter((rec) => rec.supervisor_ids.includes(myUserId))
		}
		this.list = filteredList
		// return false
	}

	reloadTable() {
		this.coreSrvc.dbSrvc.bulkRead(['supervisor_group', 'reports']).then((success) => {
			if (success) {
				this.updateTable()
			}
		})
	}

	clearSearch(): boolean {
		this.reportsTable
			.search('')
			.order([[RepTableColumnIndex.name, 'asc']])
			.draw()
		return false
	}

	public updateTable() {
		log('Reports updateTable() called')
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadData()
			this.updateColumns()
		})
	}

	notifyOperationNotAuthorized() {
		this.coreSrvc.notifySrvc.default('operationNotAuthorized')
	}

	private updateColumns() {
		const name = this.coreSrvc.prefSrvc.data.repColNameVisible
		const owner = this.coreSrvc.prefSrvc.data.repColOwnerVisible
		const type = this.coreSrvc.prefSrvc.data.repColTypeVisible
		const frequency = this.coreSrvc.prefSrvc.data.repColFrequencyVisible
		const filters = this.coreSrvc.prefSrvc.data.repColFiltersVisible
		const lastRun = this.coreSrvc.prefSrvc.data.repColLastRunVisible
		const nextRun = this.coreSrvc.prefSrvc.data.repColNextRunVisible
		const notifyText = this.coreSrvc.prefSrvc.data.repColNotifySmsVisible
		const notifyEmail = this.coreSrvc.prefSrvc.data.repColNotifyEmailVisible

		this.reportsTable.clear()

		GeneralTableHelper.updateColumn(this.reportsTable, RepTableColumnIndex.name, name)
		GeneralTableHelper.updateColumn(this.reportsTable, RepTableColumnIndex.owner, owner)
		GeneralTableHelper.updateColumn(this.reportsTable, RepTableColumnIndex.type, type)
		GeneralTableHelper.updateColumn(this.reportsTable, RepTableColumnIndex.frequency, frequency)
		GeneralTableHelper.updateColumn(this.reportsTable, RepTableColumnIndex.filters, filters)
		GeneralTableHelper.updateColumn(this.reportsTable, RepTableColumnIndex.lastRun, lastRun)
		GeneralTableHelper.updateColumn(this.reportsTable, RepTableColumnIndex.nextRun, nextRun)
		GeneralTableHelper.updateColumn(this.reportsTable, RepTableColumnIndex.notifyText, notifyText)
		GeneralTableHelper.updateColumn(this.reportsTable, RepTableColumnIndex.notifyEmail, notifyEmail)

		this.reportsTable.rows.add(this.list)
		this.reportsTable.draw(false)
	}

	public viewOrDownloadReport(id: number) {
		const rep = this.coreSrvc.dbSrvc.reportSrvc.getReportById(id)
		const repHist = rep.last_run_meta as any as ReportHistoryRecord
		// const canViewOrDownload = this.permResolver.canPerformAction(CrudAction.read, repHist)
		// if (!canViewOrDownload) {
		// 	this.notifyOperationNotAuthorized()
		// 	return
		// }
		if (repHist.empty) {
			this.coreSrvc.notifySrvc.notify('error', 'Empty Report', 'The selected report does not contain any data.')
			// this.showEmptyReportDialog = true
			return
		}
		const extension = repHist.format.toLowerCase()
		const fileName = repHist.file_reference + '.' + extension
		const reportUrl = `https://${environment.reportsDomain}/${fileName}`
		log('FIlename', repHist.file_reference)
		if (repHist.format === 'HTML') {
			window.open(reportUrl, '_blank')
		} else {
			window.location.assign(reportUrl)
			this.coreSrvc.notifySrvc.notify('success', 'Report Downloaded', 'Please check your downloads folder for a copy of this report.', 4)
		}
	}

	public showFilterInfo(id: number) {
		const rep = this.coreSrvc.dbSrvc.reportSrvc.getReportById(id)
		if (rep) {
			const event = new ReportsFilterInfoEvent(rep.name, rep.filter)
			this.viewManager.showFilterInfo.next(event)
		}
	}

	// public toggleShowAllReports() {
	// 	const newSetting = !this.viewManager.showAllReports
	// 	this.viewManager.showAllReports = newSetting
	// 	this.coreSrvc.prefSrvc.data.repShowAllReports = newSetting
	// 	this.coreSrvc.prefSrvc.save()
	// 	this.updateTable()
	// 	if (newSetting) {
	// 		this.coreSrvc.notifySrvc.notify('success', 'All Reports', 'Showing all available reports', 3)
	// 	} else {
	// 		this.coreSrvc.notifySrvc.notify('success', 'Shared Reports', 'Showing only reports explicitly owned by or shared with you.')
	// 	}
	// }

	private initTable() {
		log('Reports: initTable() called')
		const searchText = this.viewManager.searchFilter
		this.coreSrvc.displaySrvc.startSectionLoader()
		this.reportsTable = $('#reportsTable').DataTable(<DataTables.Settings>{
			responsive: true,
			processing: true,
			paging: true,
			pageLength: 25,
			lengthMenu: [
				[25, 50, 100, 500],
				[25, 50, 100, 500],
				// [25, 50, 100, 500, -1],
				// [25, 50, 100, 500, 'All'],
			],
			info: true,
			select: false,
			search: { search: `${searchText}`, smart: true },
			searching: true,
			searchDelay: 500,
			fixedHeader: DataTablesHelper.fixedHeader,
			// scrollY: '200px',
			// scrollCollapse: true,
			autoWidth: false,
			data: this.list,
			order: this.defaultSortOrder,
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{ responsivePriority: 1, targets: RepTableColumnIndex.name },
				{ responsivePriority: 2, targets: RepTableColumnIndex.type },
				{ responsivePriority: 3, targets: RepTableColumnIndex.lastRun },
				{ responsivePriority: 5, targets: RepTableColumnIndex.nextRun },
				{ responsivePriority: 6, targets: RepTableColumnIndex.frequency },
				{ responsivePriority: 8, targets: RepTableColumnIndex.filters },
				{ responsivePriority: 7, targets: RepTableColumnIndex.owner },

				{
					// Report Name
					targets: RepTableColumnIndex.name,
					visible: this.prefsService.data.repColNameVisible,
					title: 'Report Name',
					data: null,
					render: (rec: ReportRecord, t, f, m) => {
						return `
						<div class="dtr-control-content">
							<div class="table-text">${rec.name}</div>
						</div>`
					},
				},
				{
					// Type
					targets: RepTableColumnIndex.type,
					visible: this.prefsService.data.repColTypeVisible,
					title: 'Type',
					data: null,
					render: (d: ReportRecord, t, f, m) => {
						const reportType = this.coreSrvc.dbSrvc.reportSrvc.getReportTypeName(d.java_class)
						return `<div class="table-text">${reportType}</div>`
					},
				},
				{
					// Frequency
					targets: RepTableColumnIndex.frequency,
					visible: this.prefsService.data.repColFrequencyVisible,
					title: 'Frequency',
					data: null,
					render: (d: ReportRecord, t, f, m) => {
						const freqName = this.coreSrvc.dbSrvc.reportSrvc.getReportFrequencyName(d.period)
						const freqNote = this.coreSrvc.dbSrvc.reportSrvc.getFrequencyNote(d.period)
						return `<div style="white-space: normal">
						<div>${freqName}<div>
						<div style="font-size: 0.8em; color: gray;">${freqNote}<div>
						</div>`
					},
				},
				{
					// Filters
					targets: RepTableColumnIndex.filters,
					visible: this.prefsService.data.repColFiltersVisible,
					title: 'Filters',
					data: null,
					render: (record: ReportRecord, t, f, m) => {
						return ReportsTableFormatter.reportFilterIcon(record, this.bridgeName)
					},
				},
				{
					// Last Run
					targets: RepTableColumnIndex.lastRun,
					visible: this.prefsService.data.repColLastRunVisible,
					title: 'Last Run',
					data: null,
					render: (rec: ReportRecord, t, f, m) => {
						const lastRunMeta = new LastRunMeta(rec.last_run_meta)
						const userId = lastRunMeta.ran_by
						const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(userId)
						const runBy = user ? user.first_name + ' ' + user.last_name : 'Unknown User'
						const runByHtml = user
							? `<div style="font-weight:600"><span style="color:chocolate">by</span> <span style="color:#135c94">${runBy}</span></div>`
							: ''
						return `
						<div>
							<div style="display:inline-block">${ReportHelper.displayLastRun(rec.last_run)}</div>
							${runByHtml}
						</div>
						`
					},
				},
				{
					// Next Run
					targets: RepTableColumnIndex.nextRun,
					visible: this.prefsService.data.repColNextRunVisible,
					title: 'Next Run',
					data: null,
					render: (d, t, f, m) => {
						return d.manual ? '<span style="color: chocolate"><strong>Manual</strong></span>' : d.next_run || ''
					},
				},
				{
					// Notify SMS
					targets: RepTableColumnIndex.notifyText,
					visible: this.prefsService.data.repColNotifySmsVisible,
					title: 'SMS',
					data: null,
					render: (d: ReportRecord, t, f, m) => {
						const icon = d.send_sms
							? '<span style="color: green;"><i class="fa fa-check" aria-hidden="true"></i></span>'
							: '<span style="color: firebrick;"><strong>X</strong></span>'
						return `<span style="margin-left:15px">${icon}</span>`
					},
				},
				{
					// Notify Email
					targets: RepTableColumnIndex.notifyEmail,
					visible: this.prefsService.data.repColNotifyEmailVisible,
					title: 'Email',
					data: null,
					render: (d: ReportRecord, t, f, m) => {
						const icon = d.send_email
							? '<span style="color: green;"><i class="fa fa-check" aria-hidden="true"></i></span>'
							: '<span style="color: firebrick;"><strong>X</strong></span>'
						return `<span style="margin-left:15px">${icon}</span>`
					},
				},
				{
					// Report Owner
					targets: RepTableColumnIndex.owner,
					visible: this.prefsService.data.repColOwnerVisible,
					title: 'Report Owner',
					data: null,
					render: (rep: ReportRecord, t, f, m) => {
						const supId = rep.supervisor
						if (supId === 0) return 'Any Supervisor'
						const sup = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(supId)
						const supName = sup ? sup.first_name + ' ' + sup.last_name : 'Unknown Supervisor'
						return `<div style="white-space: normal">${supName}</div>`
					},
				},
				// Actions
				{
					targets: RepTableColumnIndex.actions,
					visible: true,
					searchable: false,
					orderable: false,
					title: 'Actions',
					data: null,
					className: 'row-actions',
					render: (rep: ReportRecord, t, f, m) => {
						const recordId = rep.id
						// const isMyRecord = false

						// const canAccessEdit = this.canPerformAction(CrudAction.update, isMyRecord)
						const canAccessEdit = this.permResolver.canPerformAction(CrudAction.update, rep)
						const editLink = TableActionFormatter.editLink(this.bridgeName, 'editRecord', recordId, canAccessEdit)

						// const canAccessRun = this.canPerformAction(CrudAction.read, isMyRecord)
						const canAccessRun = this.permResolver.canPerformAction(CrudAction.read, rep)
						const runReportLink = TableActionFormatter.runReportLink(this.bridgeName, 'runReport', recordId, canAccessRun)
						const runCurrentReportLink = TableActionFormatter.runCurrentReportLink(this.bridgeName, 'runReport', recordId, canAccessRun)

						const canClone = canAccessEdit
						const cloneLink = TableActionFormatter.cloneLink(this.bridgeName, 'cloneRecord', recordId, canClone)

						// const canAccessDelete = this.canPerformAction(CrudAction.delete, isMyRecord)
						const canAccessDelete = this.permResolver.canPerformAction(CrudAction.delete, rep)
						const deleteLink = TableActionFormatter.deleteLink(this.bridgeName, 'deleteRecord', recordId, canAccessDelete)

						const lastRunMeta = rep.last_run_meta
						const repHist = new LastRunMeta(lastRunMeta)
						repHist.id = rep.id
						const viewLink = lastRunMeta
							? TableActionFormatter.viewOrDownloadReport(this.bridgeName, 'viewOrDownloadReport', repHist, true, 'REPORTS')
							: ''

						const auditLink = TableActionFormatter.auditLinkGlobal('reports', recordId, rep.name, true, !!rep.updated)

						return `<span class="act-ico-wrap">${editLink}${cloneLink}${runReportLink}${runCurrentReportLink}${deleteLink}${auditLink}${viewLink}</span>`
					},
				},
			],
			drawCallback: (settings) => {
				// log('drawCallback')
				this.reportsTable?.columns.adjust().responsive.recalc()
				this.coreSrvc.displaySrvc.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
			},
		})
		$('#reportsTable').on('search.dt', () => {
			const input = this.reportsTable.search() || ''
			this.viewManager.searchFilter = input
		})
		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter('reportsTable', this.reportsTable)
	}
}

class LastRunMeta {
	id: number
	format: string
	empty: boolean
	ran_by: number
	file_reference: string

	constructor(record: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
	}
}
