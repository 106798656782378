import { Pipe, PipeTransform } from '@angular/core'
import { EmployeesService } from '@app/services/backend/employees.service'

@Pipe({
    name: 'displayEmployeeName',
    standalone: false
})
export class DisplayEmployeeNamePipe implements PipeTransform {
	constructor(private employeesService: EmployeesService) {}

	transform(value: any, args?: any): any {
		const employee = this.employeesService.getEmployeeById(value)
		if (employee) {
			return employee.name
		}
		return 'Employee Not Found'
	}
}
