import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import {
	VizCardActionEvent,
	VizCardCounterDataSource,
	VizDashboard,
	VizCardGraphDataSourceType,
	VizCardAction,
	VizCardCounterDataSourceType,
} from '@app/models'
import { CoreService, VisualizationService } from '@app/services'

import { DeviceDetectorService } from 'ngx-device-detector'
import { ContextMenu } from 'primeng/contextmenu'

@Component({
	selector: 'app-viz-card-count',
	templateUrl: './viz-card-count.component.html',
	styleUrl: './viz-card-count.component.scss',
	standalone: false,
})
export class VizCardCountComponent {
	isInternalUser = false

	@Input() rowIndex: number
	@Input() cardIndex: number
	@Input() data: VizCardCounterDataSource

	@Output() cardEvent = new EventEmitter<VizCardActionEvent>()

	lessThanOne = '<1'

	contextMenu: any = [
		{
			label: 'Add Card',
			icon: 'far fa-plus',
			command: (event) => this.handleMenuItemAction('ADD_CARD', null),
		},
		{
			label: 'Remove Card',
			icon: 'far fa-minus',
			command: (event) => this.handleMenuItemAction('REMOVE_CARD', null),
		},
		{
			label: 'Move Card',
			icon: 'far fa-up-down-left-right',
			items: [
				{
					label: 'Move Right',
					icon: 'fa fa-arrow-right',
					command: (event) => this.handleMenuItemAction('MOVE_CARD', 'RIGHT'),
					disabled: () => this.shouldDisableMove('RIGHT'),
				},
				{
					label: 'Move Left',
					icon: 'fa fa-arrow-left',
					command: (event) => this.handleMenuItemAction('MOVE_CARD', 'LEFT'),
					disabled: () => this.shouldDisableMove('LEFT'),
				},
			],
		},
		{
			separator: true,
		},
		{
			label: 'Add Row',
			icon: 'far fa-plus',
			command: (event) => this.handleMenuItemAction('ADD_ROW', null),
		},
		{
			label: 'Remove Row',
			icon: 'far fa-minus',
			command: (event) => this.handleMenuItemAction('REMOVE_ROW', null),
		},
		{
			label: 'Move Row',
			icon: 'far fa-up-down-left-right',
			items: [
				{
					label: 'Move Up',
					icon: 'fa fa-arrow-up',
					command: (event) => this.handleMenuItemAction('MOVE_CARD', 'UP'),
					disabled: () => this.shouldDisableMove('UP'),
				},
				{
					label: 'Move Down',
					icon: 'fa fa-arrow-down',
					command: (event) => this.handleMenuItemAction('MOVE_CARD', 'DOWN'),
					disabled: () => this.shouldDisableMove('DOWN'),
				},
			],
		},
		{
			separator: true,
		},
		{
			label: 'Data Source',
			icon: 'far fa-database',
			items: [
				{
					label: 'People',
					items: [
						{
							label: 'Employees',
							// icon: 'far fa-user',
							command: (event) => this.handleDataSourceChange('EMPLOYEES_ALL'),
							disabled: () => this.shouldDisableDataSource('EMPLOYEES_ALL'),
						},
					],
				},
				{
					label: 'Work',
					items: [
						{
							label: 'Jobs',
							// icon: 'far fa-tasks-alt',
							command: (event) => this.handleDataSourceChange('JOBS_ALL'),
							disabled: () => this.shouldDisableDataSource('JOBS_ALL'),
						},
					],
				},
				{
					label: 'Scheduling',
					items: [
						{
							label: 'Schedules',
							// icon: 'far fa-calendar',
							command: (event) => this.handleDataSourceChange('SCHEDULES_ALL'),
							disabled: () => this.shouldDisableDataSource('SCHEDULES_ALL'),
						},
					],
				},

				{
					label: 'Operations',
					items: [
						{
							label: 'Time Entries',
							// icon: 'far fa-clock',
							items: [
								{
									label: 'Time Entries',
									command: (event) => this.handleDataSourceChange('TRANS_ALL'),
									disabled: () => this.shouldDisableDataSource('TRANS_ALL'),
								},
								{
									label: 'No Shows',
									command: (event) => this.handleDataSourceChange('TRANS_NO_SHOW'),
									disabled: () => this.shouldDisableDataSource('TRANS_NO_SHOW'),
								},
								{
									label: 'No Clock-Out',
									command: (event) => this.handleDataSourceChange('TRANS_MISSING'),
									disabled: () => this.shouldDisableDataSource('TRANS_MISSING'),
								},
								{
									label: 'Blocked GPS',
									command: (event) => this.handleDataSourceChange('TRANS_GPS_BLOCKED_ANY'),
									disabled: () => this.shouldDisableDataSource('TRANS_GPS_BLOCKED_ANY'),
								},
								{
									label: 'Notifications',
									items: [
										{
											label: 'Employee',
											command: (event) => this.handleDataSourceChange('TRANS_NOTIFIED_EMP'),
											disabled: () => this.shouldDisableDataSource('TRANS_NOTIFIED_EMP'),
										},
										{
											label: 'Supervisor',
											command: (event) => this.handleDataSourceChange('TRANS_NOTIFIED_SUP'),
											disabled: () => this.shouldDisableDataSource('TRANS_NOTIFIED_SUP'),
										},
									],
								},
							],
						},
						{
							label: 'Shift Reports',
							// icon: 'far fa-pie-chart',
							items: [
								{
									label: 'All',
									command: (event) => this.handleDataSourceChange('SHIFT_REPORT_ALL'),
									disabled: () => this.shouldDisableDataSource('SHIFT_REPORT_ALL'),
								},
								{
									label: 'Basic',
									command: (event) => this.handleDataSourceChange('SHIFT_REPORT_BASIC'),
									disabled: () => this.shouldDisableDataSource('SHIFT_REPORT_BASIC'),
								},
								{
									label: 'Clock-In',
									command: (event) => this.handleDataSourceChange('SHIFT_REPORT_CHECKIN'),
									disabled: () => this.shouldDisableDataSource('SHIFT_REPORT_CHECKIN'),
								},
								{
									label: 'Post-Break',
									command: (event) => this.handleDataSourceChange('SHIFT_REPORT_POST_BREAK'),
									disabled: () => this.shouldDisableDataSource('SHIFT_REPORT_POST_BREAK'),
								},
								{
									label: 'Clock-Out',
									command: (event) => this.handleDataSourceChange('SHIFT_REPORT_CHECKOUT'),
									disabled: () => this.shouldDisableDataSource('SHIFT_REPORT_CHECKOUT'),
								},
								{
									label: 'Homecare',
									command: (event) => this.handleDataSourceChange('SHIFT_REPORT_HOMECARE'),
									disabled: () => this.shouldDisableDataSource('SHIFT_REPORT_HOMECARE'),
								},
								{
									label: 'Vehicle',
									command: (event) => this.handleDataSourceChange('SHIFT_REPORT_VEHICLE'),
									disabled: () => this.shouldDisableDataSource('SHIFT_REPORT_VEHICLE'),
								},
								{
									label: 'Incident',
									command: (event) => this.handleDataSourceChange('SHIFT_REPORT_INCIDENT'),
									disabled: () => this.shouldDisableDataSource('SHIFT_REPORT_INCIDENT'),
								},
								{
									label: 'Custom',
									command: (event) => this.handleDataSourceChange('SHIFT_REPORT_CUSTOM'),
									disabled: () => this.shouldDisableDataSource('SHIFT_REPORT_CUSTOM'),
								},
							],
						},
					],
				},
			],
		},
		{
			separator: true,
			visible: () => !this.devDetect.isDesktop(),
		},
		{
			label: 'Close Menu',
			icon: 'far fa-circle-x',
			command: (event) => this.cm.hide(),
			visible: () => !this.devDetect.isDesktop(),
		},
	]

	@ViewChild('cm') cm!: ContextMenu

	constructor(private coreSrvc: CoreService) {
		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()
	}

	get devDetect(): DeviceDetectorService {
		return this.coreSrvc.devDetect
	}
	get vizSrvc(): VisualizationService {
		return this.coreSrvc.dbSrvc.vizSrvc
	}
	get currentDashboard(): VizDashboard {
		return this.coreSrvc.dbSrvc.vizSrvc.currentDashboard
	}

	public openContextMenu(event: MouseEvent, cm: any) {
		cm.show(event)
		event.preventDefault() // Prevent default to avoid actual context menu on right click
	}

	private handleMenuItemAction(action: VizCardAction, direction: 'RIGHT' | 'LEFT' | 'UP' | 'DOWN') {
		const event = new VizCardActionEvent(action, 'COUNTER', direction, this.rowIndex, this.cardIndex, this.data)
		this.cardEvent.next(event)
	}

	private handleDataSourceChange(source: VizCardCounterDataSourceType) {
		if (this.data.source === source) return // no change
		this.data.setSource(source)
		this.data.updateData()
		this.vizSrvc.markCurrentDashboardAsModified()
	}

	private shouldDisableMove(direction: 'RIGHT' | 'LEFT' | 'UP' | 'DOWN') {
		const dashboardData = this.currentDashboard.data
		const row = dashboardData[this.rowIndex].getRowData()
		const cardIndex = this.cardIndex
		if (cardIndex !== -1) {
			if (direction === 'RIGHT' && cardIndex < row.length - 1) {
				return false
			} else if (direction === 'LEFT' && cardIndex > 0) {
				return false
			} else if (direction === 'UP' && this.rowIndex > 0) {
				return false
			} else if (direction === 'DOWN' && this.rowIndex < dashboardData.length - 1) {
				return false
			}
		}
		return true
	}

	private shouldDisableDataSource(source: VizCardCounterDataSourceType) {
		return false // this.rowHasInstanceOf(source)
	}

	private rowHasInstanceOf(source: VizCardCounterDataSourceType) {
		const dashboardData = this.currentDashboard.data
		const row = dashboardData[this.rowIndex].getRowData()
		for (const card of row) {
			if (card.source === source) return true
		}
		return false
	}
}
