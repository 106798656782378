import { Helper } from '@app/helpers/functions'
import { log } from '@app/helpers/logger'
import { CoreService } from '@app/services'
import { Subject } from 'rxjs'

type EmailLookupLocation = 'EMP' | 'USER' | 'CONTACT' | 'ORG'

export class EmailListFormEntry {
	address = ''
	name = ''
	constructor(address: string, name: string) {
		this.address = address
		this.name = name
	}
}

export class GenericEmailListManager {
	inputField: string
	emailList: Array<EmailListFormEntry> = []

	// In lookup, if found in more than one location, the later location takes precedence
	lookupLocations: Array<EmailLookupLocation> = ['CONTACT', 'ORG', 'EMP', 'USER']

	blockTitle = ''
	invalidEmailAlertMsg = 'Please enter a valid email address or clear the input field.'

	listChanged = new Subject<boolean>()

	constructor(private coreSrvc: CoreService) {}

	get emailListString(): string {
		return this.emailList.map((entry) => entry.address).join(',')
	}

	get isInputFieldValid(): boolean {
		const address = this.inputField
		return Helper.isValidEmail(address)
	}

	get isFormValid(): boolean {
		if (!this.inputField) return true
		return this.isInputFieldValid
	}

	initWithString(strList: string) {
		if (strList) {
			const emails = strList.split(',')
			this.emailList = emails.map((email) => new EmailListFormEntry(email, null))
			for (const entry of this.emailList) {
				this.lookupNameForListEntry(entry)
			}
		}
	}

	removeEmailAtIndex(idx): boolean {
		log('Removing Email')
		this.emailList.splice(idx, 1)
		this.listChanged.next(true)
		return false
	}

	enterKeyPressed() {
		if (this.inputField && this.isInputFieldValid) {
			this.addEmail()
		}
	}

	addEmail(): boolean {
		const email = this.inputField
		if (!email) {
			return true
		}
		if (!Helper.isValidEmail(email)) {
			// alert(this.invalidEmailAlertMsg)
			this.coreSrvc.notifySrvc.notify('error', 'Alert', this.invalidEmailAlertMsg)

			return false
		}
		const emailExists = this.emailList.find((le) => le.address === email)
		if (emailExists) {
			this.inputField = null
			return true
		}

		log('Adding Email:', email)
		const entry = new EmailListFormEntry(email, null)
		this.lookupNameForListEntry(entry)
		this.emailList.push(entry)
		this.inputField = null
		this.listChanged.next(true)
		return true
	}

	prepareForSubmission() {
		if (this.inputField) {
			const addError = this.addEmail()
			return addError
		}
		return true
	}

	lookupNameForListEntry(entry: EmailListFormEntry) {
		for (const location of this.lookupLocations) {
			this.lookupAddressForLocation(entry, location)
		}
	}

	lookupAddressForLocation(entry: EmailListFormEntry, location: EmailLookupLocation) {
		const address = entry.address
		switch (location) {
			case 'EMP':
				const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeByEmailAddress(address)
				if (emp) entry.name = emp.first + ' ' + emp.last
				break
			case 'USER':
				const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForEmailAddress(address)
				if (user) entry.name = user.first_name + ' ' + user.last_name
				break
			case 'CONTACT':
				const contact = this.coreSrvc.dbSrvc.contactSrvc.getContactByEmailAddress(address)
				if (contact) entry.name = contact.name
				break
			case 'ORG':
				const org = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationByEmailAddress(address)
				if (org) entry.name = org.name
				break
		}
	}
}
