<div class="row">
	<div class="col-12 col-sm-6 record-item">
		<button
			*ngIf="!syncData.desktop_sync"
			class="btn btn-sm btn-success btn-block qbo-button"
			[class.disabled]="!canPerformAction(CrudAction.update)"
			(click)="importData()"
		>
			<i *ngIf="!isWorking" class="fa fa-cloud-download" aria-hidden="true"></i>
			<i *ngIf="isWorking" class="fas fa-loader fa-spin" aria-hidden="true"></i>
			Import Service Item Data
		</button>
		<strong *ngIf="syncData.desktop_sync">Service Item Data</strong>

		<div *ngIf="false && hasLog" class="show-log-link">
			<a href="#" [routerLink]="['/admin/integrations/log/qbo/ServiceItem']" (click)="showLog()">Show Import Log</a>
			<span *ngIf="syncErrors > 0" class="qbo-sync-error">( {{ syncErrors }} Errors )</span>
		</div>
	</div>
	<div class="col-12 col-sm-6 record-item">
		<a href="#" (click)="toggleTooltips()">
			<span class="qbo-tooltip-icon">
				<i class="fal fa-question-circle" aria-hidden="true"></i>
			</span>
		</a>
		<div>
			<strong>Last import on:</strong>
		</div>
		<div>
			<small>
				<b>{{ lastImportDate }}</b>
			</small>
		</div>
	</div>
</div>

<div *ngIf="hasLog" class="row">
	<div class="col-12">
		<button *ngIf="syncErrors > 0" class="btn btn-block btn-sm btn-danger btn-qb-log" [routerLink]="['/admin/integrations/log/qbo/ServiceItem']">
			View Log ( {{ syncErrors }} Error{{ syncErrors > 1 ? 's' : '' }} )
		</button>
		<button
			*ngIf="syncErrors === 0"
			class="btn btn-block btn-sm btn-secondary btn-qb-log"
			[routerLink]="['/admin/integrations/log/qbo/ServiceItem']"
		>
			View Log ( No Errors )
		</button>
	</div>
</div>

<div class="row">
	<div *ngIf="showTooltips" class="col-12 qbo-tooltip-block text-muted">
		<div *ngIf="syncData.desktop_sync">
			Service Item Data is imported from the master list you keep in QuickBooks. In order to keep Telephone Timesheets accurate, you must perform a
			new upload any time you make changes to Service Item Data in QuickBooks. Each time you perform an upload, you should click the “View Log”
			button above to see any errors.
		</div>
		<div *ngIf="!syncData.desktop_sync">
			Click the Import Service Item Data button to perform an initial import of your service items, and to import new or modified service items
			from the master list you keep in QuickBooks Online. This service information can then be associated with jobs.
		</div>
	</div>
</div>

<div *ngIf="false" class="alert alert-info">
	{{ syncData | json }}
</div>
