import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { CoreService, DatabaseService } from '@app/services'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-section-process-indicator',
    templateUrl: './section-process-indicator.component.html',
    styleUrls: ['./section-process-indicator.component.scss'],
    standalone: false
})
export class SectionProcessIndicatorComponent implements OnInit, OnDestroy {
	isVisible = false

	private subs = new Subscription()

	constructor(private coreSrvc: CoreService, private cd: ChangeDetectorRef) {
		this.subs.add(
			this.coreSrvc.displaySrvc.sectionLoaderVisible.subscribe((isVisible) => {
				this.isVisible = isVisible
				this.cd.markForCheck()
			})
		)
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}
}
