import { Component, Input, OnInit } from '@angular/core'
import { log } from '@app/helpers'
import { DataAccessRequest, DuplicateImageRecord, ImageFile } from '@app/models'
import { CoreService } from '@app/services'
import { resolve } from 'path'
import _ from 'lodash'

@Component({
    selector: 'app-duplicate-images',
    templateUrl: './duplicate-images.component.html',
    styleUrls: ['./duplicate-images.component.scss'],
    standalone: false
})
export class DuplicateImagesComponent implements OnInit {
	@Input() transId: number = null
	@Input() images: Array<ImageFile> = []
	constructor(private coreSrvc: CoreService) {}

	selectedImage = null

	duplicates: Array<DuplicateRecordWrapper> = []

	ngOnInit(): void {
		// log('Images', this.images)
		const firstImage = this.images[0]
		if (firstImage) this.fetchInfo(firstImage)
	}

	fetchInfo(image: ImageFile) {
		this.selectedImage = image
		// log('Selected Image', image)
		this.fetchDuplicates(image).then((result) => {
			this.duplicates = result
		})
	}

	fetchDuplicates(image: ImageFile): Promise<Array<DuplicateRecordWrapper>> {
		return new Promise((resolve, reject) => {
			const request = new DataAccessRequest('dup_images', null, { sha256: image.sha256 })
			this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
				const records = (result.data ?? []).map((rec) => new DuplicateImageRecord(rec))
				const sortedRecords = _.orderBy(records, 'transaction_log_id', 'desc').map((dir) => new DuplicateRecordWrapper(dir))
				resolve(sortedRecords)
			})
		})
	}

	viewTransaction(drw: DuplicateRecordWrapper) {
		const rec = drw.record
		const transId = rec.transaction_log_id
		const companyId = rec.company_id
		const url = `${window.location.origin}/#/redirect/params?rid=${transId}&sec=transactions&cid=${companyId}`
		window.open(url, '_blank')
	}

	viewShiftReport(drw: DuplicateRecordWrapper) {
		const rec = drw.record
		const transUuid = rec.transaction_log_uuid
		const url = `${window.location.origin}/#/reports/shiftsummary/${transUuid}`
		window.open(url, '_blank')
	}
}

class DuplicateRecordWrapper {
	record: DuplicateImageRecord

	constructor(record: DuplicateImageRecord) {
		this.record = record
	}
}
