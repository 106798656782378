import { DateTimeHelper } from './../../helpers/datetime'
import { Component, OnInit, OnDestroy, AfterViewInit, Renderer2, ChangeDetectorRef } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import {
	AuditLog,
	AuditLogResourceRequest,
	AuditLogDataAccessRequest,
	AuditTableNameMapper,
	AuditLogPruneList,
	AuditLogRequestOptions,
	ComponentBridgeName,
} from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { DataTablesHelper, DisplayHelper, log } from '@app/helpers'

import _ from 'lodash'
import moment from 'moment-timezone'
import { Subscription } from 'rxjs/internal/Subscription'

enum AuditLogTableColumnIndex {
	id = 0,
	date = 1,
	user = 2,
	operation = 3,
	resource = 4,
	employee = 5,
	job = 6,
	site = 7,
	actions = 8,
}

@Component({
    selector: 'app-audit-log',
    templateUrl: './audit-log.component.html',
    styleUrls: ['./audit-log.component.scss'],
    standalone: false
})
export class AuditLogComponent implements OnInit, OnDestroy, AfterViewInit {
	bridgeName: ComponentBridgeName = 'ngBridgeAuditLogTable'
	list: Array<AuditLog> = []
	isDataLoaded = false
	isShowingHistory = false
	isShowingFromRoute = false
	selectedRecord = { id: null, data: null, header: '', footer: '', showDialog: false }
	diffRecords = { record: null, header: 'Audit History', footer: 'Showing changes to record', showDialog: false }

	showDetailsModal = false
	showFilterDatesModal = false
	showDiffRecordsModal = false

	filterStartDate: Date
	filterEndDate: Date = null

	format12Hour = DateTimeHelper.format12Hour

	startMom: moment.Moment
	endMom: moment.Moment
	private tz

	private auditLogTable: any
	private defaultSortOrder = [[0, 'desc']]
	private defaultPageLength = 100

	private subs = new Subscription()

	constructor(
		private cd: ChangeDetectorRef,
		private route: ActivatedRoute,
		private coreSrvc: CoreService,
		private renderer: Renderer2,
	) {
		this.setupSubscriptions()
		this.setupComponent()
	}

	ngOnInit() {
		window[this.bridgeName] = this
		this.initTable()
		// Check if we're showing history based on routed request
		const request: AuditLogResourceRequest = this.getResourceRequestFromRoute()
		if (request) {
			this.loadHistoryForRoutedRequest(request)
		} else {
			this.fetchAndReloadData()
		}
	}

	ngOnDestroy() {
		this.auditLogTable['fixedHeader'].disable()
		window[this.bridgeName] = null
		this.subs.unsubscribe()
	}

	ngAfterViewInit() {
		// position the clear search button
		$('#auditLogTable_filter input').attr('placeholder', ' filter')
		$('#auditLogTable_filter input').addClass('search-field-input')
		setTimeout(() => {
			$('#clear-search-icon').detach().appendTo('#auditLogTable_filter label').show()
		})
	}

	private setupSubscriptions() {
		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	private handleScreenSizeChanges() {
		if (this.auditLogTable) {
			this.auditLogTable.columns.adjust().responsive.recalc()
			this.auditLogTable.fixedHeader.adjust()
		}
	}

	// Manage UI
	setupComponent() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		this.tz = company ? company.timezone : null
		this.startMom = moment()
		this.endMom = null
	}

	getResourceRequestFromRoute() {
		const resource = this.route.snapshot.params['resource']
		const resourceId = parseInt(this.route.snapshot.params['resourceId'], 10) || null
		if (resource && resourceId) {
			log('Got resource Info from route', resource, resourceId)
			const request = new AuditLogResourceRequest(resource, resourceId)
			return request
		}
		return null
	}

	clearSearch() {
		this.auditLogTable
			.search('')
			.order([[0, 'desc']])
			.draw()
		this.auditLogTable.columns.adjust().responsive.recalc()
	}

	fetchAndReloadData(): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			const tz = this.tz
			const startDate = this.startMom.tz(tz).format('YYYY-MM-DD')
			const endDate = this.endMom ? this.endMom.tz(tz).format('YYYY-MM-DD') : startDate

			const options = { tz: tz, start_date: startDate, end_date: endDate }
			const request = new AuditLogDataAccessRequest(options)
			this.coreSrvc.dbSrvc.lambdaSrvc
				.dataAccess(request)
				.then((result) => {
					log('AuditLog Lambda Result', result)
					const data = result.data
					const records = data.map((rec) => new AuditLog(rec))
					this.coreSrvc.dbSrvc.auditLogSrvc.list = records
					this.list = records
					this.isDataLoaded = true

					this.updateTable()
				})
				.catch((error) => {
					log('AuditLog Error', error)
				})
		})
	}

	// {table: "audit_log", operation: null, resource: "transaction_log", resource_id: "543004"}

	loadHistoryForRoutedRequest(resRequest: AuditLogResourceRequest) {
		this.isShowingHistory = true
		const options = { resource: resRequest.resource, resource_id: resRequest.resourceId }
		const request = new AuditLogDataAccessRequest(options)
		log('Routed Request', request)
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			const data = result.data
			this.list = data.map((rec) => new AuditLog(rec))
			this.isDataLoaded = true

			// Init the table and tweak search box if initial load
			if (!this.auditLogTable) {
				this.initTable()
			}

			this.updateTable()
			log('History Results', data)
		})
	}

	updateTable() {
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.updateColumns()
		})
	}

	updateColumns() {
		this.auditLogTable.clear()
		this.auditLogTable.rows.add(this.list)
		this.auditLogTable.draw(false)
	}

	// Bridge Methods
	jsBridgeShowDetails(id: number) {
		log('jsBridgeShowDetails', id)
		const entry = this.list.find((rec) => rec.id === id)
		if (entry) {
			const is12Hours = DateTimeHelper.format12Hour
			const format = is12Hours ? 'YYYY-MM-DD [@] h:mm a' : 'YYYY-MM-DD [@] HH:mm'
			const date = moment(entry.created).tz(this.tz).format(format)
			this.selectedRecord.id = id
			this.selectedRecord.header = entry.user_name
			this.selectedRecord.footer = date
			this.selectedRecord.data = JSON.parse(entry.data)
			this.selectedRecord.showDialog = true
		}
		log(this.selectedRecord)
	}

	jsBridgeShowHistory(id: number) {
		log('jsBridgeShowHistory', id)
		const record = this.list.find((rec) => rec.id === id)
		this.coreSrvc.workSrvc.blockWorking()
		if (record) {
			this.isShowingHistory = true
			const options = this.getRequestOptionsForRecord(record)

			const request = new AuditLogDataAccessRequest(options)
			log('Request', request)
			this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
				const data = result.data
				this.list = data.map((rec) => new AuditLog(rec))
				this.updateTable()
				this.coreSrvc.workSrvc.unblockWorking()
				log('History Results', data)
			})
		}
	}

	getRequestOptionsForRecord(record: AuditLog): AuditLogRequestOptions {
		const options = { resource: record.resource, resource_id: record.resource_id, type: null }
		if (record.operation === 'track') {
			const trackData = record.data ? JSON.parse(record.data) : null
			const type = trackData.type
			options.type = type
		}
		return options
	}

	exitHistoryViewBtnClicked() {
		this.isShowingHistory = false
		this.resetPage(true)
		this.fetchAndReloadData()
	}

	jsBridgeShowDiff(id: number) {
		log('jsBridgeShowDiff', id)
		const record = this.list.find((rec) => rec.id === id)
		// if (record && !record.resource_id) {
		// 	alert('Record missing resource ID')
		// 	return
		// }
		this.diffRecords.record = record
		this.diffRecords.showDialog = true
	}

	detailsDialogChange(event) {
		log(event)
	}

	// Formatters

	pruneProperties(obj: Object): Object {
		const result = { ...obj }
		AuditLogPruneList.forEach((attr) => {
			delete result[attr]
		})
		return result
	}

	getSelectedRecordPropertyList() {
		const results = []
		const data = this.pruneProperties(this.selectedRecord.data)
		// const data = this.selectedRecord.data
		for (const attr in data) {
			if (data.hasOwnProperty(attr)) {
				const item = { name: attr, value: data[attr] }
				results.push(item)
			}
		}
		return results
	}

	currentViewFormatter() {
		if (this.endMom) {
			const startDate = this.startMom.tz(this.tz).format('MMM Do, YYYY')
			const endDate = this.endMom.tz(this.tz).format('MMM Do, YYYY')
			return `${startDate} - ${endDate}`
		}
		return this.startMom.tz(this.tz).format('ddd MMM Do, YYYY')
	}

	dateFormatter(rec: AuditLog) {
		const is12Hours = DateTimeHelper.format12Hour
		const format = is12Hours ? 'YYYY-MM-DD [@] h:mm a z' : 'YYYY-MM-DD [@] HH:mm z'
		const result = moment(rec.created).tz(this.tz).format(format)
		return `<span style="display:none;">${rec.id}</span><span>${result}</span>`
	}

	// userNameFormatter(id: number) {
	// 	const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForId(id)
	// 	if (user) { return user.first_name + ' ' + user.last_name }
	// 	return 'Unavailable'
	// }

	operationFormatter(str: string) {
		if (!str) {
			return ''
		}
		let styleClass = ''
		switch (str) {
			case 'insert':
				styleClass = 'alt-op-insert'
				break
			case 'update':
				styleClass = 'alt-op-update'
				break
			case 'delete':
				styleClass = 'alt-op-delete'
				break
			default:
				styleClass = 'alt-op-unknown'
				break
		}
		const result = str.split('_').join('<br>')
		return `<span class="alt-op-block ${styleClass}">${result}</span>`
	}

	resourceFormatter(str: string) {
		if (!str) {
			return ''
		}
		const comps = str.split('_')
		return comps.map((c) => c.charAt(0).toUpperCase() + c.slice(1)).join(' ')
	}

	actionBtnsFormatter(rec: AuditLog, bridgeName: ComponentBridgeName) {
		const count = rec.count
		const countIdx = rec.count_index
		const isShowingHistory = this.isShowingHistory
		const showHistIcon = count > 1
		const showDiffIcon = true

		const diffIcon = `<i title="Compare Changes" class="far fa-database act-ico" onclick="${bridgeName}.jsBridgeShowDiff(${rec.id})"></i>`
		const historyIcon = `<i title="History View" class="far fa-history act-ico" onclick="${bridgeName}.jsBridgeShowHistory(${rec.id})"></i>`

		if (isShowingHistory) {
			return `<span class="act-ico-wrap" style="margin-left:10px;">${showDiffIcon ? diffIcon : ''}</span>`
		} else {
			return `<span class="act-ico-wrap" style="margin-left:10px;">${showDiffIcon ? diffIcon : ''}${showHistIcon ? historyIcon : ''}</span>`
		}
	}

	// Navigation

	get canMoveToPreviousDate(): boolean {
		if (this.isShowingHistory) {
			return false
		}
		return true
	}
	get canMoveToNextDate(): boolean {
		if (this.isShowingHistory) {
			return false
		}
		return true
	}

	clearDataTablesSavedState() {}

	moveToPreviousDate() {
		log('Moving to previous date')
		if (!this.canMoveToPreviousDate) {
			return
		}
		this.endMom = null
		this.filterEndDate = null
		this.auditLogTable.page('first')
		this.startMom.subtract(1, 'day')
		this.fetchAndReloadData()
	}

	moveToNextDate() {
		log('Moving to next date')
		if (!this.canMoveToNextDate) {
			return
		}
		this.endMom = null
		this.filterEndDate = null
		this.auditLogTable.page('first')
		this.startMom.add(1, 'day')
		this.fetchAndReloadData()
	}

	// selectDate() {
	// 	log('Selecting a date')
	// }

	public applyFilterBtnClicked() {
		this.showFilterDatesModal = false
		this.isShowingHistory = false
		this.startMom = moment(this.filterStartDate)
		if (this.filterEndDate) {
			this.endMom = moment(this.filterEndDate)
		} else {
			this.endMom = null
		}
		this.fetchAndReloadData()
	}

	public resetFilterClicked() {
		this.showFilterDatesModal = false
		this.filterStartDate = moment().toDate()
		this.filterEndDate = null
		this.startMom = null
		this.endMom = null
		this.setupComponent()
		this.fetchAndReloadData()
	}

	jobNameFormatter(name: string): string {
		// return name
		if (!name) {
			return ''
		}
		const truncName = name.split('[')[0].trim()
		if (truncName === 'UNASSIGNED JOB') {
			return `<span style="color:firebrick"><b>Unassigned</b></span>`
		} else {
			return truncName
		}
	}

	siteNameFormatter(name: string): string {
		if (!name) {
			return ''
		}
		if (name === 'UNASSIGNED JOB SITE') {
			return `<span style="color:firebrick"><b>Unassigned</b></span>`
		} else {
			return name
		}
	}

	public resetPage(reDraw: boolean) {
		this.auditLogTable.page(0)
		if (reDraw) {
			this.auditLogTable.draw(false)
		}
	}

	// DataTables Initialization

	private initTable() {
		this.coreSrvc.displaySrvc.startSectionLoader()
		this.auditLogTable = $('#auditLogTable').DataTable(<DataTables.Settings>{
			stateSave: false,
			responsive: true,
			processing: true,
			paging: true,
			pageLength: this.defaultPageLength,
			lengthChange: true,
			lengthMenu: [
				[25, 50, 100, 500],
				[25, 50, 100, 500],
			],
			info: true,
			select: false,
			searching: true,
			fixedHeader: DataTablesHelper.fixedHeader,
			autoWidth: false,
			data: this.list,
			order: this.defaultSortOrder,
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{
					responsivePriority: 1,
					targets: [AuditLogTableColumnIndex.date, AuditLogTableColumnIndex.operation],
				},
				{
					responsivePriority: 2,
					targets: [AuditLogTableColumnIndex.user, AuditLogTableColumnIndex.resource],
				},
				{
					// ID
					targets: AuditLogTableColumnIndex.id,
					visible: false,
					searchable: true,
					title: 'ID',
					data: null,
					render: (rec: AuditLog, t, f, m) => {
						// Render
						return rec.id
					},
				},
				{
					// Date
					targets: AuditLogTableColumnIndex.date,
					visible: true,
					searchable: true,
					title: 'Date',
					data: null,
					render: (rec: AuditLog, t, f, m) => {
						// Render
						const content = this.dateFormatter(rec)
						return `
						<div class="dtr-control-content">
							${content}
						</div>`
					},
				},
				{
					// User
					targets: AuditLogTableColumnIndex.user,
					visible: true,
					searchable: true,
					title: 'User',
					data: null,
					render: (rec: AuditLog, t, f, m) => {
						// Render
						return rec.user_name
					},
				},
				{
					// Event
					targets: AuditLogTableColumnIndex.operation,
					visible: true,
					searchable: true,
					title: 'Event',
					data: null,
					render: (rec: AuditLog, t, f, m) => {
						// Render
						return this.operationFormatter(rec.operation)
					},
				},
				{
					// Resource
					targets: AuditLogTableColumnIndex.resource,
					visible: true,
					searchable: true,
					title: 'Resource',
					data: null,
					render: (rec: AuditLog, t, f, m) => {
						// Render
						const count = rec.count
						const resource = AuditTableNameMapper.resourceNameForRecord(rec)
						return this.isShowingHistory ? resource : resource + `<span class="alt-rec-count">(${count})</span>`
					},
				},
				{
					// Site
					targets: AuditLogTableColumnIndex.site,
					visible: true,
					searchable: true,
					title: 'Job Site',
					data: null,
					render: (rec: AuditLog, t, f, m) => {
						// Render
						const siteName = this.siteNameFormatter(rec.jobsite_name)
						return `<div style="white-space: normal">${siteName}</div>`
					},
				},
				{
					// Job
					targets: AuditLogTableColumnIndex.job,
					visible: true,
					searchable: true,
					title: 'Job',
					data: null,
					render: (rec: AuditLog, t, f, m) => {
						// Render
						const jobName = this.jobNameFormatter(rec.job_name)
						return `<div style="white-space: normal">${jobName}</div>`
					},
				},
				{
					// Employee
					targets: AuditLogTableColumnIndex.employee,
					visible: true,
					searchable: true,
					title: 'Employee',
					data: null,
					render: (rec: AuditLog, t, f, m) => {
						// Render
						return `<div style="white-space: normal">${rec.employee_name || ''}</div>`
					},
				},
				{
					// Details
					targets: AuditLogTableColumnIndex.actions,
					visible: true,
					searchable: true,
					orderable: false,
					title: 'Actions',
					data: null,
					render: (rec: AuditLog, t, f, m) => {
						// Render
						return this.actionBtnsFormatter(rec, this.bridgeName)
					},
				},
			],
			drawCallback: (settings) => {
				this.auditLogTable?.columns.adjust().responsive.recalc()
				this.coreSrvc.displaySrvc.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
			},
		})
		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter('auditLogTable', this.auditLogTable)
	}
}
