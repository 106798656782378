<div *ngIf="isConfirming">
	<div class="updating-note"><i class="fas fa-loader fa-spin"></i> Confirming Change...</div>
</div>

<div [hidden]="isConfirming">
	<div [hidden]="currentView === 'CONFIRMATION'">
		<div class="options-block">
			<div>
				<div *ngIf="isPasswordValid('newPassword')" class="float-right pass-valid">valid</div>
				<div *ngIf="newPassword && !isPasswordValid('newPassword')" class="float-right pass-invalid">invalid</div>
				<div class="options-title">New Password</div>
			</div>

			<p-password [id]="'password'" [inputStyleClass]="'form-control'" [(ngModel)]="newPassword" [toggleMask]="true"></p-password>
		</div>

		<div class="options-block">
			<div>
				<div *ngIf="isPasswordValid('confirmPassword')" class="float-right pass-valid">matches</div>
				<div *ngIf="!isPasswordValid('confirmPassword') && confirmPassword" class="float-right pass-mismatch">mismatch</div>
				<div class="options-title">Confirm Password</div>
			</div>
			<p-password [inputStyleClass]="'form-control'" [feedback]="false" [(ngModel)]="confirmPassword" [toggleMask]="true"></p-password>
		</div>

		<div class="alert alert-warning" style="margin-top: 25px">
			Passwords must be at least 8 characters long and include both letters and numbers.
		</div>
	</div>

	<div [hidden]="currentView === 'CHANGEPASS'">
		<div class="options-block">
			<div>
				<div class="options-title">Confirmation Code</div>
			</div>

			<input class="form-control" [(ngModel)]="confirmCode" />
		</div>

		<div class="alert alert-warning" style="margin-top: 25px">
			Enter the 6-digit confirmation code which was sent via text message to <strong>{{ username }}</strong
			>. Allow a couple minutes for the code to be sent. If you encounter issues changing your password, please contact support.
		</div>
	</div>
</div>

<input id="username" [hidden]="true" [ngModel]="username" />
