<div class="container">
	<span class="section-header"><i class="fa fa-trash" aria-hidden="true"></i> Delete Job</span><br />
	<strong *ngIf="jobExists()" style="color: gray">Name: {{ jobName }}</strong>

	<div *ngIf="!jobExists()" class="alert alert-warning delete-alert" role="alert">
		<strong>Record Not Found</strong>
	</div>

	<div [hidden]="isWorking">
		<div *ngIf="!requirementsMet()" class="alert alert-warning delete-alert" role="alert" style="margin-top: 15px">
			<!-- <div><strong>Warning:</strong></div> -->
			<div *ngIf="schedulesExist() && !openTransactions">
				<strong>Warning:</strong> This job is currently scheduled. These schedule items will also be removed when you delete this job.
			</div>
			<div *ngIf="openTransactions">
				<strong>Warning:</strong> This job has open transactions and cannot be deleted until all employess have checked out for this job.
			</div>

			<!-- <ul style="margin-bottom: 0px">
				<li>
				</li>
				<li>
					This job has open transactions and cannot be deleted until all employess have checked out for this job.
				</li>
			</ul> -->
		</div>

		<div *ngIf="requirementsMet()"><br /></div>

		<div *ngIf="jobExists() && requirementsMet()" class="card card-body section-requirement text-center" style="max-width: 350px; margin: 0 auto">
			<p class="card-text">
				<span class="section-req-title">Are you sure?</span>
			</p>
			<div class="confirm-delete-btn-wrap">
				<button (click)="onCancel()" class="btn btn-sm btn-secondary">Cancel</button>
				<button (click)="deleteJob()" class="btn btn-sm btn-danger btn-confirm-delete">Delete</button>
			</div>
		</div>

		<div *ngIf="schedulesExist() && !openTransactions" class="card card-body section-requirement">
			<p class="card-text">
				<span class="section-req-title"><i class="fa fa-calendar" aria-hidden="true"></i> Schedule ({{ schedulesCount() }})</span>
				<span class="float-right">
					<button class="btn btn-success btn-sm btn-req-setup" title="view" (click)="viewSchedules()">view</button>
					<!-- <button [routerLink]="scheduleViewRouterLink" class="btn btn-success btn-sm btn-req-setup" title="view">view</button> -->
				</span>
			</p>
		</div>

		<div *ngIf="openTransactions" class="card card-body section-requirement">
			<p class="card-text">
				<span class="section-req-title"><i class="fa fa-exchange" aria-hidden="true"></i> Transactions ({{ openTransactions }})</span>
				<span class="float-right">
					<button (click)="viewOngoingTransactions()" class="btn btn-success btn-sm btn-req-setup" title="view">view</button>
				</span>
			</p>
		</div>

		<div *ngIf="!requirementsMet()">
			<div class="row">
				<div class="col-12 col-sm-6">
					<div><button (click)="onCancel()" class="btn btn-secondary btn-block action-button">Cancel Operation</button></div>
				</div>
				<div class="col-12 col-sm-6">
					<div>
						<button [disabled]="!canDeleteJob()" (click)="deleteJob()" class="btn btn-danger btn-block action-button">Delete Job</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
