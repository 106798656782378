import { Injectable } from '@angular/core'
import { AuditLog } from '@app/models'

@Injectable({
	providedIn: 'root',
})
export class AuditLogService {
	list: Array<AuditLog> = []
	diffList: Array<AuditLog> = []

	public clearData() {
		this.list = []
		this.diffList = []
	}
	public getRecords(): Array<AuditLog> {
		return this.list
	}

	public getRecordById(id: number) {
		return this.list.find((rec) => rec.id === id)
	}

	public getDiffList(): Array<AuditLog> {
		return this.diffList
	}

	public getDiffRecordById(id: number) {
		return this.diffList.find((rec) => rec.id === id)
	}
}
