import { Component, OnInit } from '@angular/core'
import { log, TransactionHelper } from '@app/helpers'
import { ClickToCallSourceConfig, ClickToCallDialogManager, TransactionLogRecord } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'

@Component({
    selector: 'app-click-to-call-notes',
    templateUrl: './click-to-call-notes.component.html',
    styleUrls: ['./click-to-call-notes.component.scss'],
    standalone: false
})
export class ClickToCallNotesComponent implements OnInit {
	manager: ClickToCallDialogManager

	notes = ''
	originalNotes = ''
	appendNotes = ''
	timezone = 'UTC'

	canEditAdminNotes = false
	isEditingAdminNotes = false

	trans: TransactionLogRecord

	constructor(private coreSrvc: CoreService) {
		this.manager = this.coreSrvc.callSrvc.c2cManager
	}

	ngOnInit(): void {}

	public dialogOnShow() {
		log('C2CNotes - dialogOnShow')
	}

	public resetVm() {
		this.isEditingAdminNotes = false
		this.appendNotes = ''
	}

	public setupComponent() {
		const role = this.coreSrvc.dbSrvc.settingSrvc.getMyUser().role
		this.canEditAdminNotes = role === 'INTERNAL' || role === 'PRIMARY'
		const config = this.manager.config
		switch (config.type) {
			case 'TRANS':
				this.setupComponentForTransaction(config)
				break
		}
	}

	private setupComponentForTransaction(config: ClickToCallSourceConfig) {
		const transId = config.sourceId
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(transId)
		this.timezone = trans.timezone
		if (trans) {
			this.trans = trans
			this.notes = trans.notes ?? ''
			this.originalNotes = trans.notes ?? ''
		} else {
			this.trans = null
		}
	}

	public addAdminNote() {
		log('addAdminNote')
		const canModify = this.canSubmitNoteChanges()
		if (!canModify) return

		if (this.appendNotes) {
			// const trans = this.trans
			const transId = this.trans.id
			this.coreSrvc.dbSrvc.readRecord('transaction_log', transId).then((status) => {
				if (status === 'UPDATED') {
					this.manager.eventStatus.isUpdatingNotes = true

					setTimeout(() => {
						const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(transId)
						const updateTrans = new TransactionLogRecord(trans)
						const currentNotes = trans.notes ? trans.notes : ''
						const newNotes = TransactionHelper.appendToAdminNotes(this.coreSrvc.dbSrvc, this.timezone, currentNotes, this.appendNotes)
						updateTrans.notes = newNotes
						log('Record to send', trans)
						this.coreSrvc.dbSrvc.updateRecord('transaction_log', updateTrans).then((success) => {
							if (success) {
								this.setupComponent()
								this.appendNotes = ''
								this.manager.eventStatus.isUpdatingNotes = false
								this.isEditingAdminNotes = false
								this.coreSrvc.dbSrvc.tranSrvc.recordNeedsRefresh.next(transId)
								this.coreSrvc.notifySrvc.notify(
									'success',
									'Note Added',
									'Your note has been added to the admin notes for this time entry.',
									3,
								)
							} else {
								this.coreSrvc.notifySrvc.notify(
									'error',
									'Update Error',
									'An unknonwn error was encountered and this note could not be written to the time entry.',
								)
							}
						})
					}, 500)
				}
			})
		}
	}

	public cancelAddAdminNote() {
		log('cancelAddAdminNote')
		this.appendNotes = ''
	}

	public updateAdminNotes() {
		log('updateAdminNotes')
		const canModify = this.canSubmitNoteChanges()
		if (!canModify) return

		const transId = this.trans.id
		this.coreSrvc.dbSrvc.readRecord('transaction_log', transId).then((status) => {
			if (status === 'UPDATED') {
				this.manager.eventStatus.isUpdatingNotes = true

				const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(transId)
				const updateTrans = new TransactionLogRecord(trans)

				setTimeout(() => {
					const currentNotes = this.notes ? this.notes : ''
					updateTrans.notes = currentNotes
					log('Record to send', trans)
					this.coreSrvc.dbSrvc.updateRecord('transaction_log', updateTrans).then((success) => {
						this.setupComponent()
						this.appendNotes = ''
						this.isEditingAdminNotes = false
						this.manager.eventStatus.isUpdatingNotes = false
						this.coreSrvc.dbSrvc.tranSrvc.recordNeedsRefresh.next(transId)
						this.coreSrvc.notifySrvc.notify('success', 'Note Updated', 'Admin notes for this time entry have been updated.', 3)
					})
				}, 500)
			}
		})
	}

	private canSubmitNoteChanges(): boolean {
		const config = this.manager.config
		if ((config.type = 'TRANS')) {
			const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(this.trans?.id)
			const jobId = trans?.job_id
			const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)
			if (!job) {
				this.coreSrvc.notifySrvc.notify(
					'error',
					'Missing Job',
					'The job for this time entry cannot be found. Try reloading your browser to resync data or use the time entry edit form to make any changes.',
				)
				return false
			}
		}
		return true
	}

	public cancelEditAdminNotes() {
		log('cancelEditAdminNotes')
		this.notes = this.originalNotes
		this.isEditingAdminNotes = false
	}
}
