import { Component, OnInit } from '@angular/core'

import { QBOSync, IQBOSyncPayload, CrudAction } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'

import { log, DateTimeHelper } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'
import { Router } from '@angular/router'

@Component({
    selector: 'app-qboemployee-data',
    templateUrl: './qboemployee-data.component.html',
    styleUrls: ['./qboemployee-data.component.scss'],
    standalone: false
})
export class QBOEmployeeDataComponent implements OnInit {
	accessHelper: AccessHelper

	syncData: QBOSync
	lastImportDate = 'Ready for initial import'
	hasLog = false
	isWorking = false
	syncErrors = 0
	showTooltips = false

	CrudAction = CrudAction

	constructor(
		private router: Router,
		private coreSrvc: CoreService,
	) {
		this.setupAccessPermissions()
	}

	ngOnInit() {
		this.updateUI()
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'quickbooks')
	}

	canPerformAction(action: CrudAction) {
		return this.accessHelper.canPerformAction(action, false)
	}

	updateUI() {
		log('QBOSync Employee Component updating UI')
		this.syncData = this.coreSrvc.dbSrvc.qbSrvc.getQBOSyncData()
		this.syncErrors = this.syncData.employee_sync_errors
		if (this.syncData && this.syncData.employee_sync) {
			const importDate = this.syncData.employee_sync
			this.lastImportDate = DateTimeHelper.mediumDateTimeFromDateString(importDate)
			this.hasLog = true
		}
	}

	showLog(): boolean {
		return false
	}

	toggleTooltips(): boolean {
		this.showTooltips = !this.showTooltips
		return false
	}

	importData() {
		log('QBOSync Employee Component import data button clicked')
		if (!this.accessHelper.canPerformAction(CrudAction.update, false)) {
			this.accessHelper.notifyOperationNotAuthorized()
			return
		}
		this.isWorking = true
		const request: IQBOSyncPayload = {
			table: null,
			operation: 'employee',
		}
		this.coreSrvc.dbSrvc.lambdaSrvc.qboSync(request).then((result) => {
			this.coreSrvc.dbSrvc.readTable('qbo_sync').then((success) => {
				this.isWorking = false
				this.updateUI()
				this.router.navigate(['/admin/integrations/log/qbo/Employee'])
			})
		})
	}
}
