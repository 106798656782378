import { GeneralTableHelper } from '@app/helpers'
import { log } from '@app/helpers/logger'
import { DatabaseService } from '@app/services/backend/database.service'

export class SupervisorUsersFormatter {
	public static makeUsersLinkedRecordsButton(
		dbSrvc: DatabaseService,
		bridgeTableName: string,
		dtFilterText: string,
		id: number,
		type: string,
	): string {
		const user = dbSrvc.settingSrvc.getUserForId(id)
		const filterText = dtFilterText?.toLowerCase() ?? ''
		let recordsText = ''
		let recordCount = 0
		let isAllRecords = false
		switch (type) {
			case 'company':
				const allCompanies = dbSrvc.settingSrvc.getGlobalCompanyList()
				const allowedCompanies = dbSrvc.settingSrvc.getGlobalCompaniesForUserId(id)
				recordCount = allowedCompanies.length || 0
				const companyNames = allowedCompanies.map((c) => c.name)
				recordsText = companyNames.join(',').toLowerCase()
				isAllRecords = recordCount === allCompanies.length
				break
			case 'employee':
				const allEmployees = dbSrvc.empSrvc.getAllEmployees()
				const employees = dbSrvc.empSrvc.getAllEmployees().filter((emp) => emp.supervisor_ids.includes(id) && emp.active)
				recordCount = employees.length || 0
				const employeeNames = employees.map((e) => e.name)
				recordsText = employeeNames.join(',').toLowerCase()
				isAllRecords = recordCount === allEmployees.length
				break
			case 'site':
				const allJobSites = dbSrvc.siteSrvc.getAllJobSites()
				const sites = dbSrvc.siteSrvc.getAllJobSites().filter((site) => site.supervisor === id)
				recordCount = sites.length || 0
				const siteNames = sites.map((s) => s.description)
				recordsText = siteNames.join(',').toLowerCase()
				isAllRecords = recordCount === allJobSites.length
				break
			case 'job':
				const allJobs = dbSrvc.jobSrvc.getAllJobs()
				const jobs = dbSrvc.jobSrvc
					.getAllJobs()
					.filter((job) => job.supervisor_ids.includes(user.id))
					.filter((job) => job.active)
				recordCount = jobs.length
				const jobNames = jobs.map((j) => j.description)
				recordsText = jobNames.join(',').toLowerCase()
				isAllRecords = recordCount === allJobs.length
				break
			case 'profile':
				const allProfiles = dbSrvc.npSrvc.getProfiles()
				const profiles = dbSrvc.settingSrvc.getLinkedProfilesForUserId(dbSrvc, id)
				recordCount = profiles.length || 0
				const profileNames = profiles.map((p) => p.name)
				recordsText = profileNames.join(',').toLowerCase()
				isAllRecords = recordCount === allProfiles.length
				break
			case 'group':
				const allGroups = dbSrvc.settingSrvc.getUserGroups()
				const groups = dbSrvc.settingSrvc.getLinkedGroupsForUserId(dbSrvc, id)
				recordCount = groups.length || 0
				const groupNames = groups.map((g) => g.description)
				recordsText = groupNames.join(',').toLowerCase()
				isAllRecords = recordCount === allGroups.length
				break
			case 'skill':
				const skills = user.users_skill
				recordCount = skills.length || 0
				const SkillNames = skills.map((s) => s.name)
				recordsText = SkillNames.join(',').toLowerCase()
				break
		}

		const filterClass = filterText && recordsText.toLowerCase().includes(filterText) ? ' table-modal-btn-match' : ''
		const recordsTextHtml = recordCount > 0 ? `<div style="display:none;">${recordsText}</div>` : ''
		const sortString = GeneralTableHelper.leftPadSortString(recordCount, 3)

		if (type === 'skill' && user?.role === 'NO_COGNITO') return `${sortString}`

		return recordCount > 0
			? `${sortString}<button onclick="${bridgeTableName}.showLinkedRecords(${id}, '${type}')" class="btn btn-sm btn-default table-modal-btn${filterClass}" style="width: 105px">${
					isAllRecords ? 'all records' : recordCount === 1 ? `1 record` : recordCount + ` records`
				}</button>${recordsTextHtml}`
			: ''
	}
}

export class SupervisorGroupsFormatter {
	public static makeGroupsLinkedRecordsButton(
		dbSrvc: DatabaseService,
		bridgeTableName: string,
		dtFilterText: string,
		id: number,
		type: string,
	): string {
		const group = dbSrvc.settingSrvc.getUserGroupById(id)
		const filterText = dtFilterText?.toLowerCase() ?? ''
		let recordsText = ''
		let recordCount = 0
		let isAllRecords = false
		switch (type) {
			case 'supervisor':
				const allSupervisors = dbSrvc.settingSrvc.getUsers()
				recordCount = group?.supervisor_group_users.length || 0
				const supNames = group.supervisor_group_users.map((supId) => dbSrvc.settingSrvc.getUsernameForUserId(supId))
				recordsText = supNames.join(',').toLowerCase()
				isAllRecords = recordCount === allSupervisors.length
				break
			case 'employee':
				const allEmployees = dbSrvc.empSrvc.getAllEmployees()
				recordCount = dbSrvc.empSrvc.getEmployeesByGroupId(id).length || 0
				const empNames = dbSrvc.empSrvc.getEmployeesByGroupId(id).map((emp) => emp.name)
				recordsText = empNames.join(',').toLowerCase()
				isAllRecords = recordCount === allEmployees.length
				break
			case 'jobsite':
				const allJobSites = dbSrvc.siteSrvc.getAllJobSites()
				recordCount = dbSrvc.siteSrvc.getJobSitesByGroupId(id).length || 0
				const siteNames = dbSrvc.siteSrvc.getJobSitesByGroupId(id).map((site) => site.description)
				recordsText = siteNames.join(',').toLowerCase()
				isAllRecords = recordCount === allJobSites.length
				break
			case 'job':
				const allJobs = dbSrvc.jobSrvc.getAllJobs()
				const jobSiteIdsForGroupId = dbSrvc.siteSrvc.getJobSitesByGroupId(id).map((site) => site.id)
				const jobsList = dbSrvc.jobSrvc.getJobsForJobSiteIds(jobSiteIdsForGroupId)
				recordCount = jobsList.length || 0
				const jobNames = jobsList.map((job) => job.description)
				recordsText = jobNames.join(',').toLowerCase()
				isAllRecords = recordCount === allJobs.length
				break
		}

		// Check if current filter text is in the data for the associated record list
		const filterClass = filterText && recordsText.toLowerCase().includes(filterText) ? ' table-modal-btn-match' : ''
		const recordsTextHtml = recordCount > 0 ? `<div style="display:none;">${recordsText}</div>` : ''
		const sortString = GeneralTableHelper.leftPadSortString(recordCount, 3)

		return recordCount > 0
			? `${sortString}<button onclick="${bridgeTableName}.showLinkedRecords(${id}, '${type}')" class="btn btn-sm btn-default table-modal-btn${filterClass}" style="width: 105px">${
					isAllRecords ? 'all records' : recordCount === 1 ? `1 record` : recordCount + ` records`
				}</button>${recordsTextHtml}`
			: ''
	}
}

export class SupervisorRolesFormatter {
	public static makeGroupsLinkedRecordsButton(
		dbSrvc: DatabaseService,
		bridgeTableName: string,
		dtFilterText: string,
		id: number,
		type: string,
	): string {
		const role = dbSrvc.settingSrvc.getUserRoleById(id)
		const filterText = dtFilterText?.toLowerCase() ?? ''
		let recordsText = ''
		let recordCount = 0
		let isAllRecords = false
		switch (type) {
			case 'supervisor':
				const allSupervisors = dbSrvc.settingSrvc.getUsers()
				const users = dbSrvc.settingSrvc.getUsersByRoleId(id)
				recordCount = users.length || 0
				const supNames = users.map((u) => u.first_name + ' ' + u.last_name)
				recordsText = supNames.join(',').toLowerCase()
				isAllRecords = recordCount === allSupervisors.length
				break
		}

		// Check if current filter text is in the data for the associated record list
		const filterClass = filterText && recordsText.toLowerCase().includes(filterText) ? ' table-modal-btn-match' : ''
		const recordsTextHtml = recordCount > 0 ? `<div style="display:none;">${recordsText}</div>` : ''
		const sortString = GeneralTableHelper.leftPadSortString(recordCount, 3)

		return recordCount > 0
			? `${sortString}<button onclick="${bridgeTableName}.showLinkedRecords(${id}, '${type}')" class="btn btn-sm btn-default table-modal-btn${filterClass}" style="width: 105px">${
					isAllRecords ? 'all records' : recordCount === 1 ? `1 record` : recordCount + ` records`
				}</button>${recordsTextHtml}`
			: ''
	}
}
