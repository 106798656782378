import { Injectable } from '@angular/core'
import { log } from '@app/helpers'

@Injectable({ providedIn: 'root' })
export class HelpService {
	prefs = HelpServicePrefs.loadPrefsFromStorage()

	constructor() {
		log('Creating HelpService')
	}
}

export class HelpServicePrefs {
	showTimeEntryIconHelp = true

	constructor(record?: HelpServicePrefs) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
		}
	}

	public savePrefs() {
		const prefsData = JSON.stringify(this)
		localStorage.setItem('TTSAdmin-HelpPrefs', JSON.stringify(this))
		log('savePrefs', prefsData)
	}

	public static loadPrefsFromStorage(): HelpServicePrefs {
		const prefsJson = localStorage.getItem('TTSAdmin-HelpPrefs')
		if (prefsJson) {
			return new HelpServicePrefs(JSON.parse(prefsJson))
		}
		return new HelpServicePrefs()
	}
}
