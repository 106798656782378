<div style="min-height: 80px">
	<div class="float-right">
		<div *ngIf="logoUrl">
			<div class="header-title print-only">{{ formattedReportType }}</div>
		</div>
		<div class="noprint icons-wrap">
			<i class="fa fa-print icon-head icon-print" (click)="printPage()"></i>
			<button *ngIf="showCloseBtn" class="btn btn-sm btn-outline-secondary close-btn" (click)="closeReport(true)">X</button>
		</div>
	</div>
	<div *ngIf="logoUrl" style="max-width: 300px">
		<img [src]="logoUrl" class="logo-img" />
	</div>
	<div *ngIf="!logoUrl" class="header-left-no-logo">
		<div class="header-title">{{ formattedReportType }}</div>
		<div class="company-name" *ngIf="companyName">{{ companyName }}</div>
	</div>

	<div *ngIf="!config.publicView && config.publicLink" class="noprint float-right">
		<span class="noprint public-link-wrap" (click)="copyLink()"> <i class="fa fa-link"></i> public link </span>
	</div>

	<div class="address-line" *ngIf="address">
		<div>
			<span *ngIf="address.addr_1">{{ address?.addr_1 }} / </span>
			<span *ngIf="address.addr_2">{{ address?.addr_2 }} / </span>
			<span *ngIf="address.addr_3">{{ address?.addr_3 }} / </span>
			<span *ngIf="address.city">{{ address?.city }}, </span>
			<span *ngIf="address.state">{{ address?.state }} </span>
			<span *ngIf="address.post_code">{{ address?.post_code }} </span>
		</div>
	</div>

	<div *ngIf="reportIdentifier" class="urr-block">
		<div class="card urr-card">
			<div>
				<div class="float-right">{{ reportIdentifier }}</div>
				<div class="urr-label">Report ID:</div>
			</div>
		</div>
	</div>
</div>

<app-user-report-transaction
	*ngIf="transactionMetaData"
	[empPfpUrl]="empPfpUrl"
	[transactionMetaData]="transactionMetaData"
></app-user-report-transaction>
