import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { DateTimeHelper, log } from '@app/helpers'
import { TourWrapper, TourType, WaypointViewModel } from '@app/models'
import { Global } from '@app/models/global'
import moment from 'moment-timezone'
import _ from 'lodash'

@Component({
    selector: 'app-tour-schedule-view',
    templateUrl: './tour-schedule-view.component.html',
    styleUrl: './tour-schedule-view.component.scss',
    standalone: false
})
export class TourScheduleViewComponent implements OnInit {
	@Input() tour: TourWrapper
	@Input() startTime: Date = null
	@Input() endTime: Date = null
	@Input() tourRepeatDurationInMin: number = 0
	@Input() waypoints: Array<WaypointViewModel> = []

	timeFormat = 'HH:mm'

	constructor(private cd: ChangeDetectorRef) {
		this.timeFormat = DateTimeHelper.getTimeFormat()
	}

	tourList: Array<TourVm> = []

	ngOnInit() {
		this.updateTourList()
	}

	updateTourList() {
		log('updateTourList called')
		const tourList: Array<TourVm> = []
		const shiftDuration = this.getTimeDifferenceInMinutes(this.startTime, this.endTime)
		const offsetLimit = this.tour.type === 'STRUCTURED' ? shiftDuration : 1440
		const isNonRepeating = !this.tour.repeatInterval

		for (let i = 0; i < 200; i++) {
			const repeatInterval = this.tour.type === 'STRUCTURED_HOUR_START' ? 60 : this.tourRepeatDurationInMin
			const tourVm = new TourVm(this.tour.type, this.startTime, i, repeatInterval, this.waypoints)
			if (tourVm.offsetInMin >= offsetLimit) break
			if (isNonRepeating && i === 1) break // Break after first sequence if no repeat interval set
			tourList.push(tourVm)
		}
		this.tourList = tourList
		this.cd.detectChanges()
	}

	shouldShowWaypoint(seq: number, wp: WaypointViewModel) {
		const duration = this.tour.type === 'STRUCTURED_HOUR_START' ? 60 : this.tourRepeatDurationInMin
		const wpStartTime = moment(wp.clockTime).add(duration * seq, 'minutes')
		return wpStartTime.isBefore(moment(wp.clockTime).endOf('day'), 'second')
	}

	formatTimeForCheckpoint(seq: number, wp: WaypointViewModel) {
		const duration = this.tour.type === 'STRUCTURED_HOUR_START' ? 60 : this.tourRepeatDurationInMin
		const padTime = duration * seq

		const startTime = this.startTime
			? moment(this.startTime).add(padTime + wp.twp.startOffset, 'minutes')
			: moment(wp.clockTime).add(padTime, 'minutes')

		return startTime.format(this.timeFormat)
	}

	setTourStartTimeFromPicker(startTime: Date) {
		this.startTime = startTime
	}

	setTourEndTimeFromPicker(endTime: Date) {
		this.endTime = endTime
	}

	getTimeDifferenceInMinutes(startDate: Date, endDate: Date): number {
		if (!startDate || !endDate) return 0
		// Clone the dates to avoid modifying the original dates
		const clonedStartDate = new Date(startDate.getTime())
		const clonedEndDate = new Date(endDate.getTime())

		clonedStartDate.setSeconds(0, 0)
		clonedEndDate.setSeconds(0, 0)

		// Set the date part of both dates to the same date (e.g., 1970-01-01)
		clonedStartDate.setFullYear(1970, 0, 1)
		clonedEndDate.setFullYear(1970, 0, 1)

		// Add 24 hours to the endDate if it is before the startDate
		if (clonedEndDate < clonedStartDate) {
			clonedEndDate.setDate(clonedEndDate.getDate() + 1)
		}

		// Calculate the difference in milliseconds
		const timeDifferenceInMillis = clonedEndDate.getTime() - clonedStartDate.getTime()

		// Convert milliseconds to minutes and return the result
		return Math.floor(timeDifferenceInMillis / (1000 * 60))
	}
}

class TourVm {
	tourType: TourType
	seq: number
	durationInMin: number
	offsetInMin: number
	startTime: Date = null
	startTimeFormatted: string
	constructor(tourType: TourType, startTime: Date, seq: number, durationInMin: number, waypoints: Array<WaypointViewModel> = []) {
		this.tourType = tourType
		this.seq = seq
		this.durationInMin = durationInMin
		this.setupStartTime(startTime, waypoints)
	}

	setupStartTime(startTime: Date, waypoints: Array<WaypointViewModel> = []) {
		if (waypoints.length === 0) return
		const firstWp = waypoints[0]
		if (!firstWp) return
		this.offsetInMin = firstWp.twp.startOffset + this.seq * this.durationInMin

		const startTimeMom = startTime
			? moment(startTime).add(this.durationInMin * this.seq, 'minutes')
			: this.tourType === 'STRUCTURED_HOUR_START'
				? moment()
						.startOf('day')
						.add(this.seq * 60, 'minutes')
				: moment(firstWp.clockTime).add(this.durationInMin * this.seq, 'minutes')

		this.startTime = startTimeMom.toDate()
		this.startTimeFormatted = startTimeMom.format(DateTimeHelper.getTimeFormat())
	}
}
