<div class="component-wrapper">
	<!-- <div *ngIf="!status.loginChecked" class="loading-block"><i class="fas fa-loader fa-spin"></i> Initializing</div> -->

	<div
		*ngIf="!status.loginChecked || (status.loginChecked && status.isLoggedIn && !status.invalidRequest && !status.authorizationChecked)"
		class="loading-block"
	>
		<i class="fas fa-loader fa-spin"></i> Redirecting
	</div>

	<div *ngIf="status.authorizationChecked && !status.isAuthorized && !status.invalidRequest" class="loading-block not-authorized">
		Not Authorized
	</div>

	<div *ngIf="status.invalidRequest" class="loading-block">Invalid Request</div>

	<div *ngIf="status.loginChecked && !status.isLoggedIn" class="not-logged-in-block">
		<div class="notes">You must be logged into your account to perform the requested action.</div>
		<button [routerLink]="['/home/login']" class="btn btn-sm btn-outline-secondary" style="margin: 25px">Login</button>
		<div class="notes">Please login and try again.</div>
	</div>

	<!-- <div style="margin: auto">
		<div>RecordId: {{ recordId }}</div>
		<div>Section: {{ section }}</div>
		<div>CompanyId: {{ companyId }}</div>
	</div> -->
</div>
