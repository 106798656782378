import { Injectable, EventEmitter } from '@angular/core'
import {
	OrganizationType as OrganizationType,
	OrganizationRecord,
	OrgTableDisplayState,
	OrganizationViewManager,
	OrganizationSelectItem,
} from '@app/models/organization'

import { log } from '@app/helpers'
import _ from 'lodash'
import { SelectItem } from 'primeng/api/selectitem'
import { ContactOrganizationLinkRecord } from '@app/models'
import { Global } from '@app/models/global'

@Injectable({
	providedIn: 'root',
})
export class OrganizationService {
	dataLoaded = false
	list: Array<OrganizationRecord> = []
	listChange = new EventEmitter<Array<OrganizationRecord>>()
	lastUpdated: Date

	viewManager = new OrganizationViewManager()
	// orgTableCurrentView: OrgTableDisplayState = OrgTableDisplayState.all // Deprecated

	constructor() {
		log('Creating OrganizationService')
	}

	clearData() {
		this.list = []
		this.dataLoaded = false
		this.lastUpdated = null
	}

	getOrganizationDropdownData(type?: OrganizationType): Array<OrganizationSelectItem> {
		return this.getOrganizations(type).map((org) => ({ label: org.name, value: org.id, data: org }))
	}

	getOrganizations(type?: OrganizationType): Array<OrganizationRecord> {
		const result = _.sortBy(this.list, 'name')
		if (type) {
			return result.filter((org) => org.type === type)
		}
		return result
	}

	getOrganizationsByViewState(state: OrgTableDisplayState) {
		switch (state) {
			case OrgTableDisplayState.clients:
				return this.getOrganizations('CLIENT')
			case OrgTableDisplayState.vendors:
				return this.getOrganizations('VENDOR')
			case OrgTableDisplayState.all:
				return this.getOrganizations()
		}
	}

	getOrganizationById(id: number) {
		return this.list.find((rec) => rec.id === id)
	}

	getOrganizationByName(name: string) {
		return this.list.find((rec) => rec.name === name)
	}

	getOrganizationByE164Number(phoneE164: string) {
		return this.list.find((rec) => rec.phone_e164 === phoneE164)
	}

	getOrganizationByEmailAddress(email: string) {
		return this.list.find((rec) => rec.email === email)
	}

	getContactLinkRecords(): Array<ContactOrganizationLinkRecord> {
		return this.list.flatMap((rec) => rec.vendor_client_contact)
	}

	// getContactLinksForContactId(id: number) {
	// 	const records: Array<ContactOrganizationLinkRecord> = []
	// 	for (const rec of this.getContactLinkRecords()) {

	// 	}
	// }

	removeLocalOrganizationRecord(recordId: number) {
		this.list = this.list.filter((rec) => rec.id !== recordId)
	}

	setOrganizationRecords(records: Array<OrganizationRecord>) {
		this.lastUpdated = new Date()
		this.list = records.map((rec) => new OrganizationRecord(rec))
		this.dataLoaded = true
		this.listChange.next(this.list)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateOrganizationRecords(records: Array<OrganizationRecord>) {
		const newRecords = records.map((rec) => new OrganizationRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.list.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.list.push(newRecord)
			}
		}
		this.lastUpdated = new Date()
		this.listChange.next(this.list)
	}
}
