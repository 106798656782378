import { Component, Input, OnInit } from '@angular/core'
import { SectionListCountManager } from '@app/models'
import { DatabaseService } from '@app/services'

@Component({
    selector: 'app-section-list-count',
    templateUrl: './section-list-count.component.html',
    styleUrls: ['./section-list-count.component.scss'],
    standalone: false
})
export class SectionListCountComponent implements OnInit {
	@Input() manager: SectionListCountManager

	constructor(private dbSrvc: DatabaseService) {}

	ngOnInit(): void {}
}
