<div class="card card-body">
	<div>
		<strong class="settings-card-title"> <i class="fa fa-credit-card" aria-hidden="true"></i> Card Info </strong>
		<button *ngIf="!isEditingCard" class="btn btn-sm btn-success float-right" (click)="toggleEdit()">Edit</button>
		<button *ngIf="isEditingCard" class="btn btn-sm btn-primary float-right" (click)="isEditingCard = !isEditingCard">Done</button>

		<!-- Credit Card Form -->
		<form [hidden]="!isEditingCard" novalidate [formGroup]="billForm" autocomplete="off">
			<hr />

			<div *ngIf="(paypalDataLoaded && !hasCardOnFile && !isTestingAccount && !isPrepaid) || cardRemovedStatusMessage" style="margin-top: 15px">
				<div class="row>" style="margin-bottom: 25px">
					<img width="250px" src="/assets/img/creditcards.png" />
				</div>
				<div class="row">
					<div class="col-12 col-sm-6 record-item">
						<div class="options-block">
							<div class="float-right">
								<small class="cardtype" [class.cardtype-valid]="validCardNumber()"
									><strong>{{ billForm.value.type }}</strong></small
								>
							</div>
							<div class="options-title-inline" [class.cardnumber-valid]="validCardNumber()">Card Number</div>
							<i
								style="margin-left: 6px"
								class="fa link-text has-pointer"
								[class.fa-eye]="!showCCNumber"
								[class.fa-eye-slash]="showCCNumber"
								(click)="showCCNumber = !showCCNumber"
							></i>
							<div>
								<input
									appAutoFocus
									tabindex="1"
									name="CCNumber"
									type="tel"
									class="form-control slim-card"
									[class.hide-input]="!showCCNumber"
									formControlName="cardNumber"
									(keyup)="formatCreditCard($event)"
									autocomplete="off"
								/>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6 record-item">
						<div class="options-block">
							<div class="float-right">
								<small><strong style="color: #888">(3 or 4 Digits)</strong></small>
							</div>
							<div class="options-title-inline">Security Code</div>
							<input
								tabindex="2"
								name="CVCNumber"
								type="tel"
								class="form-control slim-code"
								[class.hide-input]="!showCVC"
								formControlName="cvc"
								autocomplete="off"
							/>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-sm-6 record-item">
						<div class="options-title">Exp Month</div>
						<p-select
							appendTo="body"
							appScrollLockSelect
							[tabindex]="3"
							id="expMonth"
							[options]="expMonthDropdown"
							formControlName="expMonth"
						></p-select>
					</div>
					<div class="col-12 col-sm-6 record-item">
						<div class="options-title">Exp Year</div>
						<p-select
							appendTo="body"
							appScrollLockSelect
							[tabindex]="4"
							id="expYear"
							[options]="expYearDropdown"
							formControlName="expYear"
						></p-select>
					</div>
				</div>

				<div
					*ngIf="!isCardTypeValid && billForm.get('cardNumber').touched && billForm.get('cardNumber').value"
					class="alert alert-danger alert-form-error"
				>
					Invalid Card Number
				</div>

				<div *ngIf="!billForm.get('cvc').valid && billForm.get('cvc').touched" class="alert alert-danger alert-form-error">
					Invalid Security Code
				</div>

				<div class="row billing-notes-row">
					<div class="col-12 col-sm-9">
						<div [hidden]="hasTrialExpired" class="card-info-text">
							<strong>Note:</strong> Your first month is free, even if you enter your card information now. Once the 30-day free trial is over,
							you will enter a regular monthly billing cycle, but we don't charge your card until the end of the billing cycle. Effectively
							giving you 60 days of use, before you see your first charge.
						</div>
					</div>
					<div class="col-12 col-sm-3">
						<button
							tabindex="5"
							[disabled]="billForm.invalid"
							class="btn btn-sm btn-success btn-block submit-card-btn"
							(click)="addOrRetryCard('ADD')"
						>
							Submit Card
						</button>
					</div>
				</div>
			</div>

			<div *ngIf="cardExpirationDayCount < 0" class="alert alert-danger" style="text-align: center; font-weight: 600">
				You credit card has expired.
			</div>

			<div *ngIf="paypalDataLoaded && hasCardOnFile" style="margin-top: 20px">
				<div class="row">
					<div class="col-12 col-sm-6">
						<strong>Credit Card Number</strong>
						<br />
						{{ cardOnFileCardNumberString() }}
					</div>
					<div class="col-12 col-sm-6">
						<strong>Expiration Date</strong>
						<br />
						{{ cardOnFileCardExpirationString() }}
					</div>
				</div>
				<br />
				<div class="row">
					<div class="col-12 col-sm-6">
						<strong>Card Type</strong>
						<br />
						{{ cardOnFileCardTypeString() }}
					</div>
					<div class="col-12 col-sm-6">
						<div class="row">
							<div class="col-6">
								<button class="btn btn-sm btn-danger btn-block remove-card-button" (click)="removeCard()">Remove Card</button>
							</div>
							<div class="col-6">
								<button
									*ngIf="shouldShowRetryButton"
									class="btn btn-sm btn-success btn-block remove-card-button"
									(click)="addOrRetryCard('RETRY')"
								>
									Retry Card
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>

		<div *ngIf="cardBlockAlertMessage">
			<div class="alert alert-danger card-alert-message">
				{{ cardBlockAlertMessage }}
			</div>
		</div>

		<div *ngIf="cardRemovedStatusMessage">
			<div class="alert alert-warning card-alert-message">
				{{ cardRemovedStatusMessage }}
			</div>
		</div>
	</div>
</div>
