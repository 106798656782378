<div class="container section-container">
	<div class="sec-head-wrap">
		<div class="sec-act-ico-wrap">
			<i
				class="fas fa-plus sec-act-ico"
				[class.section-control-disabled]="!canPerformAction('create')"
				title="Add Record"
				(click)="createRecord()"
			></i>
			<i class="far fa-cog sec-act-ico" (click)="openPrefsDialog()" title="Manage Columns"></i>
			<i class="far fa-question-circle sec-act-ico" (click)="toggleSectionHelp()" title="Show Help"></i>
		</div>

		<div class="sec-title-wrap">
			<div>
				<i class="far fa-clock sec-head-ico"></i>
				<span>Breaks</span>
			</div>
		</div>

		<div class="sub-head-wrap">
			<div class="sub-head-ico-wrap">
				<!-- Refresh Icon -->
				<i class="fa fa-refresh sub-head-ico" (click)="fetchAndReload()" title="Refresh Data"></i>
				<div class="updated-wrap">{{ lastUpdated }}</div>
			</div>
		</div>
	</div>

	<div class="alert alert-info alert-bg-gray" style="line-height: 1.4rem; margin: 22px 0px">
		<b>Break Profiles</b> enable you to create detailed break schedules for shifts, with options to track and enforce compliance with labor local
		laws.
		<span *ngIf="!showMoreInfo" class="link-text" (click)="toggleSectionHelp()">show more</span>

		<div *ngIf="showMoreInfo">
			<hr />

			<h4 class="tts-hl-2">Break Types</h4>
			<div style="margin-top: 12px">
				<b>Employee Managed</b> breaks allow you to define when breaks should happen but allow your employees to start and end their breaks.<br />
				<b>System Managed</b> breaks allow for fully automated handling of scheduled breaks.
			</div>

			<hr />

			Some of the features break profiles offer include the ability to notify employees when breaks start and end along with flagging time entries
			that include any break issues.<br /><br />

			Break profiles are essential in time tracking systems to ensure businesses meet legal requirements for employee breaks, which vary by region
			and industry. Without proper tracking, companies risk non-compliance with labor laws, potentially leading to fines or legal action. Break
			profiles help streamline compliance by allowing organizations to set up structured break periods for shifts, ensuring that employees get the
			mandated rest intervals. This proactive approach eliminates the need for manual monitoring, reduces errors, and ensures adherence to
			regulations.<br /><br />

			Beyond compliance, break profiles also promote employee well-being. By automatically scheduling and tracking breaks, companies can ensure
			that their workforce gets adequate rest during their shifts, leading to better performance and job satisfaction. Employees can focus more on
			their tasks, knowing that their break times are built into the system. Additionally, with enforcement options, businesses can prevent
			overwork and burnout, resulting in healthier, more productive teams while maintaining a transparent and organized work environment.
		</div>

		<div *ngIf="showMoreInfo" class="link-text" style="margin-top: 12px" (click)="toggleSectionHelp()">hide details</div>
	</div>

	<app-break-profile-table #breakProfileTable></app-break-profile-table>
</div>
