import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core'

import { SectionSwitcherConfig, SectionSwitcherTab, SupervisorsViewManager, SupervisorsViewTabState } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { AccessHelper } from '@app/helpers/access'
import { DisplayHelper, log } from '@app/helpers'

import { UserTableComponent } from '@app/admin/users/user-table/table.component'
import { UserGroupTableComponent } from '@app/admin/users/group-table/group-table.component'
// import { InviteTableComponent } from '@app/admin/users/invite-table/table.component'

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    standalone: false
})
export class UsersComponent implements OnInit, AfterViewInit {
	accessHelper: AccessHelper
	showTooltips = false

	prefsVisible = false

	manager: SupervisorsViewManager

	switcherConfig = new SectionSwitcherConfig()

	// @ViewChild('inviteTable', { static: true }) inviteTable: InviteTableComponent
	@ViewChild('userTable', { static: false }) userTable: UserTableComponent
	@ViewChild('groupTable', { static: false }) groupTable: UserGroupTableComponent
	@ViewChild('roleTable', { static: false }) roleTable: UserGroupTableComponent

	constructor(private coreSrvc: CoreService) {
		this.manager = this.coreSrvc.dbSrvc.settingSrvc.supervisorsViewManager
		this.setupAccessPermissions()
		this.setupTabSwitcher()

		// Stop viewing as specific user as table will reload
		// this.coreSrvc.dbSrvc.eventSrvc.displayUserChanged(null)
	}

	get createRecordLabel(): string {
		switch (this.manager.currentView) {
			case 'USERS':
				return 'Add User'
			case 'GROUPS':
				return 'Add Group'
			case 'ROLES':
				return 'Add Role'
		}
	}

	get isManagingSupervisorAvailable(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany().userEnableManagerRole
	}

	get subSectionTitle(): string {
		const currentView = this.manager.currentView
		return DisplayHelper.capitalizeSingleWord(currentView)
	}

	ngOnInit() {}
	ngAfterViewInit() {
		scrollTo(0, 0)
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'supervisor')
	}

	private setupTabSwitcher() {
		const usersTab = new SectionSwitcherTab('USERS', 'Users')
		const groupsTab = new SectionSwitcherTab('GROUPS', 'Groups')
		const rolesTab = new SectionSwitcherTab('ROLES', 'Roles')
		const config = new SectionSwitcherConfig([usersTab, groupsTab, rolesTab])
		this.switcherConfig = config
		this.switcherConfig.setInitialTabById(this.manager.currentView)
	}

	public sectionTabClicked(tab: SectionSwitcherTab) {
		this.manager.currentView = tab.id as SupervisorsViewTabState
	}

	canPerformAction(action) {
		return this.accessHelper.canPerformAction(action, false)
	}

	toggleTooltips() {
		this.showTooltips = !this.showTooltips
		this.updateFixedHeader()
	}

	updateFixedHeader() {
		setTimeout(() => {
			const userTable: any = $('#userTable').DataTable()
			userTable?.fixedHeader.adjust()
			const groupTable: any = $('#userGroupTable').DataTable()
			groupTable?.fixedHeader.adjust()
			const roleTable: any = $('#userRoleTable').DataTable()
			roleTable?.fixedHeader.adjust()
		}, 100)
	}

	createRecord() {
		const currentView = this.manager.currentView
		if (currentView === 'USERS') {
			this.userTable.createRecord()
		}
		if (currentView === 'GROUPS') {
			this.groupTable.createRecord()
		}
		if (currentView === 'ROLES') {
			this.roleTable.createRecord()
		}
	}

	togglePrefs(): void {
		const selectedView = this.manager.currentView
		switch (selectedView) {
			case 'USERS':
				this.userTable.sectionPrefsDialogManager.isDialogVisible = true
				break
			case 'GROUPS':
				this.groupTable.sectionPrefsDialogManager.isDialogVisible = true
				break
			default:
				this.coreSrvc.notifySrvc.notify(
					'info',
					'No Prefs Available',
					'There are currently no preferences available for modification for this section.',
				)
		}
	}

	toggleUsersView() {
		log('toggleUsersView')
		this.manager.currentView = 'USERS'
	}

	toggleGroupsView() {
		log('toggleGroupsView')
		this.manager.currentView = 'GROUPS'
	}

	toggleRolesView() {
		const canConfigureRoles = this.accessHelper.getPermissionsFor('supervisor').isOptionEnabledFor('SUPROL')
		if (canConfigureRoles) {
			this.manager.currentView = 'ROLES'
		} else {
			this.coreSrvc.notifySrvc.notify('error', 'Not Allowed', 'You do not have permission to manage supervisor roles.')
		}
	}
}
