import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { log } from '@app/helpers'
import { ClickToCallRecord } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'

@Component({
    selector: 'app-click-to-call-message',
    templateUrl: './click-to-call-message.component.html',
    styleUrls: ['./click-to-call-message.component.scss'],
    standalone: false
})
export class ClickToCallMessageComponent implements OnInit {
	smsCharLimit = 140

	isInternalUser = false
	showResponseOptions = false // Manages state to show options list

	@Input() c2cRecord: ClickToCallRecord
	@Input() textMessage = ''
	@Input() smsPlaceholder = ''
	@Output() cancelMessage = new EventEmitter<boolean>()
	@Output() sendMessage = new EventEmitter<string>()

	constructor(private coreSrvc: CoreService) {
		// Set sms character limit
		this.smsCharLimit = this.coreSrvc.dbSrvc.settingSrvc.getCompany()?.sms_char_limit ?? 140
		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()
	}

	get title(): string {
		return this.showResponseOptions ? '' : `Text ${this.c2cRecord?.description ?? ''}`
	}

	get maxTextLengthExceeded(): boolean {
		return this.textMessage.length > this.smsCharLimit
	}

	get showResponseIcon(): boolean {
		return !this.showResponseOptions && !!this.c2cRecord?.destEmpId && (!this.coreSrvc.auditMode || this.isInternalUser)
	}

	ngOnInit(): void {}

	public cancelTextMsg(): void {
		log('Cancel text message')
		this.textMessage = ''
		this.smsPlaceholder = ''
		this.cancelMessage.next(true)
	}

	public sendTextMsg(): void {
		log('Send text message')
		this.sendMessage.next(this.textMessage)
	}

	public processResponseOption(option: C2CMsgResponseOption) {
		switch (option) {
			case 'EMPAPPLINK':
				this.processResponseOptionEmpAppLink()
				this.sendMessage.next(this.textMessage)
				break
			case 'EMPSCHEDULELINK':
				this.processResponseOptionEmpSchedLink()
				this.sendMessage.next(this.textMessage)
				break
		}
	}

	private processResponseOptionEmpAppLink() {
		const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
		const empId = this.c2cRecord.destEmpId
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)
		if (emp) {
			const empAppLink = this.coreSrvc.dbSrvc.empLoginSrvc.getEmployeeLoginAuthUrlByEmpId(this.coreSrvc.dbSrvc, empId, companyId)
			if (empAppLink) {
				const data = { sourceType: this.c2cRecord.sourceType, description: this.c2cRecord.description, source: 'C2CAUTHLINK' }
				const empAppLinkCopy = `Timeclock Link:\n\n${empAppLink}\n\n`
				this.textMessage = empAppLinkCopy + this.textMessage
				this.coreSrvc.eventSrvc.sendAuthLinkAccessEvent(empId, data)
			} else {
				const empAppDomain = 'https://' + this.coreSrvc.dbSrvc.settingSrvc.getEmpAppDomain()
				const empAppLinkCopy = `Timeclock Link:\n\n${empAppDomain}/\n\n`
				this.textMessage = empAppLinkCopy + this.textMessage
				this.coreSrvc.notifySrvc.notify(
					'warn',
					'Timeclock Link',
					'The credentialed application link for this employee is not currently available. The default link is being provided',
				)
			}
		}
		this.showResponseOptions = false
	}

	private processResponseOptionEmpSchedLink() {
		const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
		const empId = this.c2cRecord.destEmpId
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)
		if (emp) {
			const empSchedLink = this.coreSrvc.dbSrvc.empLoginSrvc.getEmployeeScheduleUrlByEmpId(this.coreSrvc.dbSrvc, empId)
			if (empSchedLink) {
				const data = { sourceType: this.c2cRecord.sourceType, description: this.c2cRecord.description, source: 'C2CSCHEDULELINK' }
				const empAppSchedLinkCopy = `Schedule Link:\n\n${empSchedLink}\n\n`
				this.textMessage = empAppSchedLinkCopy + this.textMessage
				this.coreSrvc.eventSrvc.sendAuthLinkAccessEvent(empId, data)
			} else {
				const empAppDomain = 'https://' + this.coreSrvc.dbSrvc.settingSrvc.getEmpAppDomain()
				const empAppLinkCopy = `Timeclock Link:\n\n${empAppDomain}/\n\n`
				this.textMessage = empAppLinkCopy + this.textMessage
				this.coreSrvc.notifySrvc.notify(
					'warn',
					'Timeclock Link',
					'The employee app schedule link for this employee is not currently available. The default link is being provided',
				)
			}
		}
		this.showResponseOptions = false
	}
}

type C2CMsgResponseOption = 'EMPAPPLINK' | 'EMPSCHEDULELINK'
