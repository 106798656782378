import { Component, OnInit, Input, Output, EventEmitter, AfterContentInit } from '@angular/core'
import { CoreService, DatabaseService } from '@app/services'
import {
	Incident,
	IUserReport,
	UserReportConfiguration,
	IncidentReportValidator,
	ReportGlobals,
	DialogManager,
	HomecareReportValidator,
	UserReportType,
	VehicleInspectionReportValidator,
	ChecklistReportValidator,
	FileUploadManager,
	FileUploaderProcessedFile,
	AdditionalShiftReportData,
	DistanceUnitOption,
	DistanceHelper,
	IncidentReportHelper,
	HelpDialogMessage,
} from '@app/models'
import { environment } from '@env/environment'

import { log } from '@app/helpers'

@Component({
    selector: 'app-trans-reports-edit',
    templateUrl: './trans-reports-edit.component.html',
    styleUrls: ['./trans-reports-edit.component.scss'],
    standalone: false
})
export class TransReportsEditComponent implements OnInit, AfterContentInit {
	isSaving = false
	isUnknownReportType = false

	notes = ''
	summary = ''
	pinned = false
	additionalData = new AdditionalShiftReportData() // Extra information for shift reports

	@Input() transId: number
	@Input() record: Incident
	@Input() report: IUserReport
	@Input() reportConfig: UserReportConfiguration

	@Output() recordSaved = new EventEmitter<boolean>()
	@Output() actionCancelled = new EventEmitter<boolean>()

	@Input() dialogManager: DialogManager

	fileUploadManager = new FileUploadManager()

	reportType: UserReportType
	reportData

	incidentReportValidator: IncidentReportValidator
	homecareReportValidator: HomecareReportValidator
	vehicleInspectionReportValidator: VehicleInspectionReportValidator
	checklistReportValidator: ChecklistReportValidator

	constructor(private coreSrvc: CoreService) {
		// Report is already parssed
		this.setupGlobals()
	}

	ngOnInit(): void {
		// Setup default units
		const defaultUnits = this.coreSrvc.dbSrvc.settingSrvc.getCompany().default_units
		const defaultDistanceUnit: DistanceUnitOption = defaultUnits === 'IMPERIAL' ? 'MI' : 'KM'

		// Setup record info
		const type = this.reportConfig.reportType
		this.reportType = this.reportConfig.reportType
		this.reportData = this.report?.reportData
		this.notes = this.record?.notes
		this.summary = this.record?.summary
		this.pinned = this.record?.pinned ?? false
		this.additionalData.mileage = this.record?.mileage ?? null
		this.additionalData.mileageUnits = this.record?.mileage_units ?? defaultDistanceUnit

		switch (type) {
			case 'SHIFT':
				// Don't do anything for a shift report except bypass setting unknown report type as true
				break
			case 'INCIDENT':
				this.incidentReportValidator = new IncidentReportValidator(this.reportData)
				this.incidentReportValidator.isInDialog = true
				break
			case 'HOMECARE':
				this.homecareReportValidator = new HomecareReportValidator(this.reportData)
				this.homecareReportValidator.isInDialog = true
				break
			case 'VEHICLEINSPECTION':
				this.vehicleInspectionReportValidator = new VehicleInspectionReportValidator(this.reportData)
				this.vehicleInspectionReportValidator.isInDialog = true
				break
			case 'CHECKIN':
			case 'PRE_BREAK':
			case 'POST_BREAK':
			case 'CHECKOUT':
			case 'CUSTOM':
				this.checklistReportValidator = new ChecklistReportValidator(this.reportData)
				this.checklistReportValidator.isInDialog = true
				break
			default:
				this.isUnknownReportType = true
		}

		// 20240615 - Moved this to after content init
		// setTimeout(() => {
		// 	this.setupDialogManager()
		// 	this.setupFileUploadManager()
		// }, 100)
	}

	ngAfterContentInit() {
		setTimeout(() => {
			this.setupDialogManager()
			this.setupFileUploadManager()
		}, 100)
	}

	private setupDialogManager() {
		this.dialogManager.cancelBtnLabel = 'Cancel'
		this.dialogManager.submitBtnLabel = 'Save'
		this.dialogManager.isSubmitBtnVisible = true
		this.dialogManager.isCancelBtnVisble = true
		this.dialogManager.canSubmit = () => true
		this.dialogManager.cancelBtnAction = () => this.cancelBtnClicked()
		this.dialogManager.submitBtnAction = () => this.saveReportBtnClicked()
	}

	private setupFileUploadManager() {
		log('Setup File Upload Manager')
		const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
		const fileUploadManager = new FileUploadManager()
		fileUploadManager.setConfiguration(companyId, environment.assetsBucket, 'shiftreports')
		fileUploadManager.supportedMimeTypes = ['image/gif', 'image/png', 'image/jpeg']
		if (this.record) {
			const files = this.record.imageFiles.map((rec) => new FileUploaderProcessedFile(rec))
			fileUploadManager.processedFiles = files
			log('Files', files)
		}
		this.fileUploadManager = fileUploadManager
		log('File Manager', this.fileUploadManager)
	}

	setupGlobals() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany()
		ReportGlobals.displayPrefs.format12Hour = company.globalFormatTime12Hours
	}

	saveReportBtnClicked(): void {
		log('Saving Report')
		let isValid = false

		switch (this.reportType) {
			case 'SHIFT':
				isValid = true
				break
			case 'HOMECARE':
				isValid = this.homecareReportValidator.validateReport(true)
				break
			case 'INCIDENT':
				isValid = this.incidentReportValidator.validateReport(true)
				break
			case 'VEHICLEINSPECTION':
				isValid = this.vehicleInspectionReportValidator.validateReport(true)
				break
			case 'CHECKIN':
			case 'PRE_BREAK':
			case 'POST_BREAK':
			case 'CHECKOUT':
			case 'CUSTOM':
				isValid = this.checklistReportValidator.validateReport(true)
				break
		}

		if (isValid) {
			const updateRecord = this.makeUpdateRecord()
			log('Record to send', updateRecord)

			if (updateRecord) {
				this.isSaving = true
				setTimeout(() => {
					if (updateRecord.id) {
						this.coreSrvc.dbSrvc.updateRecord('incident_log', updateRecord).then((result) => {
							log('Result from save', result)
							this.recordSaved.emit(true)
						})
					} else {
						updateRecord.transaction_log_id = this.transId
						this.coreSrvc.dbSrvc.insertRecord('incident_log', updateRecord).then((result) => {
							log('Result from save', result)
							this.recordSaved.emit(true)
						})
					}
				}, 500)
			}
		}
	}

	cancelBtnClicked() {
		this.actionCancelled.emit(true)
	}

	makeUpdateRecord(): Incident {
		const metaData = this.report?.metaData
		let reportData = this.reportData

		// If it's an incident report, clean up the reportData
		if (this.reportType === 'INCIDENT') IncidentReportHelper.cleanUpEmptyContacts(reportData)

		log('Metadata to save', metaData)
		log('Report data to save', reportData)

		const report: IUserReport = {
			metaData: metaData,
			reportData: reportData,
		}
		const updateRecord = new Incident(this.record)

		// If it's an old shift report without any metadata then there won't be report_json
		updateRecord.report_json = metaData ? JSON.stringify(report) : null
		updateRecord.notes = this.notes
		updateRecord.pinned = this.pinned

		// If it's a new report, then dig out the summary note, otherwise copy from current record.
		updateRecord.summary = this.reportType === 'SHIFT' ? this.summary : updateRecord.getSummaryNote()
		updateRecord.mileage = this.additionalData.mileage
		updateRecord.mileage_units = this.additionalData.mileageUnits

		const imageList = this.fileUploadManager.processedFiles
		if (imageList.length > 0) {
			const imageListResult = { files: imageList }
			updateRecord.images_json = JSON.stringify(imageListResult)
		} else {
			updateRecord.images_json = null
		}

		return updateRecord
	}

	convertMileage() {
		const mileage = this.additionalData.mileage
		const units = this.additionalData.mileageUnits
		if (!mileage) {
			this.additionalData.mileage = null
			return
		}

		switch (units) {
			case 'KM':
				log('Switch to KM', mileage, units)
				this.additionalData.mileage = parseFloat(DistanceHelper.converter.setValue(mileage, 'MI').getDistanceValue('KM').toFixed(2))
				break
			case 'MI':
				log('Switch to MI', mileage, units)
				this.additionalData.mileage = parseFloat(DistanceHelper.converter.setValue(mileage, 'KM').getDistanceValue('MI').toFixed(2))
				break
		}
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'pinned':
				help.header = 'Bulletin Board'
				help.message =
					'The bulletin board is available in the employee application and pinning a report will make it available to any employee when clocked into the associated job'
				break
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
