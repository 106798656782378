import { DateTimeHelper } from '@app/helpers/datetime'
import { Subject } from 'rxjs'

export class PhoneNumberRecord {
	company_id: number
	created: string
	dispatcher_id: number
	full_number: number
	id: number
	label: string
	number_e164: string
	part_npa: number
	part_nxx: number
	part_xxxx: number
	provider: string // TWILIO
	provider_id: string
	rate_center: string // TOLLFREE | LOCAL | LONDON
	route_application: string // TTS_PROD | DISPATCH (as part of string)
	state: string
	teli_call_flow_id: number
	teli_channel_group_id: number
	teli_cnam_enabled: boolean
	teli_id: number
	teli_sms_enabled: boolean
	teli_type: string // tollfree
	teli_voicemail_id: number
	verified_sms: boolean

	constructor(record?: PhoneNumberRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(record.created)
		}
	}

	get phoneNumberLabel(): string {
		const label =
			this.route_application === 'TTS_PROD'
				? 'IVR Call-in Number'
				: this.route_application === 'DISPATCH'
					? 'Call Center Inbound'
					: 'Unspecified Phone Number'
		return this.label ? this.label : label
	}
}

export class FormPhoneNumberManager {
	label = ''
	e164 = null // Will only contain a valid e164 number - otherwise it's null
	formInput = '' // Will contain the user input
	isRequired = false
	helpKey: string
	placeholder = ''
	showOptionalOrRequired = true
	limitCodes = []

	configChange = new Subject<boolean>()

	public configure(label: string, e164: string, isRequired: boolean, helpKey: string) {
		this.label = label
		this.e164 = e164
		this.isRequired = isRequired
		this.helpKey = helpKey
		this.configChange.next(true) // Triggers setup on the phone number component
	}

	get isNumberValid(): boolean {
		return this.formInput && this.e164
	}
	get isFormValid(): boolean {
		return this.isRequired ? !!this.e164 : !!this.formInput ? this.isNumberValid : true
	}
}
