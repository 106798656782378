import { Component, OnInit, AfterViewInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Location } from '@angular/common'

import { LogEntry, LogEntryBatch as LogEntryBatch } from '@app/models'
import { CoreService } from '@app/services'

import { log } from '@app/helpers'
import _ from 'lodash'

@Component({
    selector: 'app-int-sync-log',
    templateUrl: './sync-log.component.html',
    styleUrls: ['./sync-log.component.scss'],
    standalone: false
})
export class IntegrtionSyncLogComponent implements OnInit, AfterViewInit {
	public isLoadingData = true
	public isDataLoaded = false

	private service: string
	public table: string

	public batches: Array<LogEntryBatch> = []

	constructor(
		route: ActivatedRoute,
		private location: Location,
		private coreSrvc: CoreService,
	) {
		this.service = route.snapshot.params['service']
		this.table = route.snapshot.params['tableName']
		log('Service/Table', this.service, this.table)
	}

	ngOnInit() {
		this.dispatchRequest()
	}

	ngAfterViewInit() {
		window.scrollTo(0, 0)
	}

	dispatchRequest() {
		const table = this.table
		const service = this.service

		switch (service) {
			case 'adp':
				this.fetchAdpLog(table)
				return
			case 'fileimport':
				this.fetchFileImportLog(table)
				return
			case 'qbo':
				this.fetchQbLog(table)
				return
			case 'wiw':
				this.fetchWiWLog(table)
				return
		}
		alert('Error fetching log data. Please notify support.')
	}

	fetchAdpLog(table: string) {
		log('Fetchig ADP table for', table)
		const request = {
			table: table,
			operation: 'log',
		}
		this.coreSrvc.dbSrvc.lambdaSrvc.adpSync(request).then((result: Array<any>) => {
			log('Log Result', result)
			const logList = result.map((le) => this.makeLogEntry(le))
			this.generateCompletionIndexList(logList)
			this.isLoadingData = false
			this.isDataLoaded = true
		})
	}

	fetchFileImportLog(table: string) {
		log('Fetching File Import table for', table)
		const request = {
			table: table,
			operation: 'log',
		}
		log('File Import Payload', request)
		this.coreSrvc.dbSrvc.lambdaSrvc.fiSync(request).then((result: Array<any>) => {
			log('Log Result', result)
			const logList = result.map((le) => this.makeLogEntry(le))
			this.generateCompletionIndexList(logList)
			this.isLoadingData = false
			this.isDataLoaded = true
		})
	}

	fetchQbLog(table: string) {
		const request = {
			table: table,
			operation: 'log',
		}
		this.coreSrvc.dbSrvc.lambdaSrvc.qboSync(request).then((result: Array<any>) => {
			log('Log Result', result)
			const logList = result.map((le) => this.makeLogEntry(le))
			this.generateCompletionIndexList(logList)
			this.isLoadingData = false
			this.isDataLoaded = true
		})
	}

	fetchWiWLog(table: string) {
		const request = {
			table: table,
			operation: 'log',
		}
		this.coreSrvc.dbSrvc.lambdaSrvc.wiwSync(request).then((result: Array<any>) => {
			log('WIW Log Result', table, result)
			const logList = result.map((le) => this.makeLogEntry(le))
			this.generateCompletionIndexList(logList)
			this.isLoadingData = false
			this.isDataLoaded = true
		})
	}

	makeLogEntry(record: any): LogEntry {
		const entry = new LogEntry()
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				entry[attr] = record[attr]
			}
		}
		if (entry.mesg === 'FIX') {
			entry.needsFix = true
		}
		return entry
	}

	generateCompletionIndexList(logList: Array<LogEntry>) {
		const batches: Array<LogEntryBatch> = []
		let batch = new LogEntryBatch()

		for (let i = 0; i < logList.length; i++) {
			const logItem = logList[i]
			const message = logItem.mesg

			if (message.includes('Operation Started')) {
				batch.start = logItem
			} else if (message.includes('Operation Completed')) {
				batch.end = logItem

				// Compensate for issue with double entry of log records. If the start message
				// already exists then assume it's a double batch and do not include it.
				const isStartInBatch = !!batches.find((b) => b.start.mesg === batch.start.mesg)
				if (!isStartInBatch) batches.push(batch)

				batch = new LogEntryBatch()
			} else {
				batch.logs.push(logItem)
			}
		}
		batches.reverse()
		batches.forEach((batch) => {
			batch.updateCountState()
		})
		this.batches = batches

		const firstBatch = this.batches[0]
		if (firstBatch) {
			firstBatch.isExpanded.summary = true
			firstBatch.isExpanded.error = true
			firstBatch.isExpanded.fix = true
		}
		log('Batch List', batches)
	}

	closeLog(): boolean {
		this.location.back()
		return false
	}

	formatMessage(msg: string): string {
		const formattedMessage = ''
		const components = msg.split(',')
		if (components.length > 1) {
			return components.join('<br>')
		}
		return msg
	}
}
