import { FormGroup } from '@angular/forms'
import { Injectable, EventEmitter } from '@angular/core'
import { TransactionLogRecord } from '@app/models'

import { log } from '@app/helpers'
import _ from 'lodash' // Don't add lodash to angular-cli.json or it goes in global scope.
import moment from 'moment-timezone'
import { Subject } from 'rxjs'
import { Global } from '@app/models/global'

@Injectable({
	providedIn: 'root',
})
export class TransactionService {
	dataLoaded = false
	list: TransactionLogRecord[] = []
	listChange = new EventEmitter<Array<TransactionLogRecord>>()
	lastUpdated: Date
	transactionTableScrollPosition = null

	openEditDialog = new EventEmitter<number>()

	loadIssues = false

	filterStartDate: Date = null
	filterEndDate: Date = null
	filterText: String = null
	requiredId: number = null // Used when a redirect needs a specific transaction

	// cachedForm: FormGroup
	dayViewDate: Date = new Date()
	dayViewDelta = 0
	autoAdvanceDayView = true

	recordNeedsRefresh = new Subject<number>()

	constructor() {
		log('Creating TransactionService')

		this.loadDateFilter()
	}

	getCache() {
		const cache = {}
		for (const item of this.list) {
			cache[item.id] = item
		}
		return cache
	}

	count(): number {
		return this.list.length
	}

	alertCount(): number {
		let count = 0
		this.list.forEach((trans) => {
			if (trans.exception) {
				count++
			}
		})
		return count
	}

	getAlerts(): Array<TransactionLogRecord> {
		return this.list.filter((trans) => trans.exception === true)
	}

	clearData() {
		this.list = []
		this.dataLoaded = false
		this.listChange.emit(this.list)
	}

	removeLocalRecord(recordId: number) {
		this.list = this.list.filter((rec) => rec.id !== recordId)
	}

	toggleLoadIssues() {
		this.loadIssues = !this.loadIssues
		log('Load Issues Is', this.loadIssues)
	}

	saveDateFilter() {
		const data = {
			filterStartDate: this.filterStartDate,
			filterEndDate: this.filterEndDate,
			dayViewDate: this.dayViewDate,
			autoAdvanceDayView: this.autoAdvanceDayView,
		}
		const json = JSON.stringify(data)
		localStorage.setItem('transactionFilter', json)
	}

	clearDateFilter() {
		localStorage.removeItem('transactionFilter')
	}

	loadDateFilter() {
		const json = localStorage.getItem('transactionFilter')
		const data = JSON.parse(json)
		if (data) {
			this.filterStartDate = data.filterStartDate ? new Date(data.filterStartDate) : null
			this.filterEndDate = data.filterEndDate ? new Date(data.filterEndDate) : null
			this.dayViewDate = data.autoAdvanceDayView ? new Date() : data.dayViewDate ? new Date(data.dayViewDate) : new Date()
			this.autoAdvanceDayView = data.autoAdvanceDayView
		}
	}

	activateTodayView() {
		this.dayViewDate = new Date()
		this.filterStartDate = new Date()
		this.filterEndDate = new Date()
		this.updateAutoUpdateSetting()
		this.saveDateFilter()
	}

	deactivateTodayView() {
		this.dayViewDate = null
		this.filterStartDate = null
		this.filterEndDate = null
		this.updateAutoUpdateSetting()
		this.saveDateFilter()
	}

	dayViewCanGoBack(): boolean {
		return true
	}

	dayViewBack() {
		const dayViewMom = moment(this.dayViewDate)
		this.dayViewDate = dayViewMom.add(-1, 'days').toDate()
		this.filterStartDate = new Date(this.dayViewDate.getTime())
		this.filterEndDate = new Date(this.dayViewDate.getTime())
		this.updateAutoUpdateSetting()
		this.saveDateFilter()
	}

	dayViewCanGoForward(): boolean {
		return true
	}

	setDayViewDate(date: Date) {
		this.dayViewDate = date
		this.filterStartDate = new Date(this.dayViewDate.getTime())
		this.filterEndDate = new Date(this.dayViewDate.getTime())
		this.updateAutoUpdateSetting()
		this.saveDateFilter()
	}

	dayViewForward() {
		const dayViewMom = moment(this.dayViewDate)
		this.dayViewDate = dayViewMom.add(1, 'days').toDate()
		this.filterStartDate = new Date(this.dayViewDate.getTime())
		this.filterEndDate = new Date(this.dayViewDate.getTime())
		this.updateAutoUpdateSetting()
		this.saveDateFilter()
	}

	updateAutoUpdateSetting() {
		const dateIsToday = moment(this.dayViewDate).isSame(moment(), 'day')
		if (dateIsToday) {
			this.autoAdvanceDayView = true
		} else {
			this.autoAdvanceDayView = false
		}
	}

	getTransactionLinkPath(id: number) {
		const trans = this.getTransactionById(id)
		const transId = trans.id
		const companyId = trans.company_id
		return `/redirect/record/${transId}/transactions/${companyId}`
	}

	getTransactions(): Array<TransactionLogRecord> {
		return this.list
	}

	getTransactionById(id: number): TransactionLogRecord {
		const trans = this.list.find((t) => t.id === id)
		return trans ? trans : null
	}

	// When an individual transaction is retrieved from the server and the local copy
	// needs to be updated it can be provided as a parameter to this method.

	updateLocalTransaction(newTrans: TransactionLogRecord) {
		const id = newTrans.id
		const currentTrans = this.getTransactionById(id)
		if (currentTrans) {
			for (const attr in newTrans) {
				if (currentTrans.hasOwnProperty(attr)) {
					currentTrans[attr] = newTrans[attr]
				}
			}
			currentTrans['inMetaData'] = currentTrans.bulidMetaData('IN')
			currentTrans['outMetaData'] = currentTrans.bulidMetaData('OUT')
		}
	}

	makeTransaction(record: TransactionLogRecord): TransactionLogRecord {
		const trans = new TransactionLogRecord(record)
		return trans
	}

	setTransactionRecords(records: Array<TransactionLogRecord>) {
		this.lastUpdated = new Date()
		this.list = records.map((c) => this.makeTransaction(c))
		this.dataLoaded = true
	}

	updateTransRecords(records: Array<TransactionLogRecord>) {
		log('updateTransRecords', records)
		for (const newRecord of records) {
			if (newRecord) {
				const currentRecord = this.list.find((rec) => rec.id === newRecord.id)
				if (currentRecord) {
					for (const attr in newRecord) {
						if (newRecord.hasOwnProperty(attr)) {
							currentRecord[attr] = newRecord[attr]
						}
					}
					currentRecord['inMetaData'] = currentRecord.bulidMetaData('IN')
					currentRecord['outMetaData'] = currentRecord.bulidMetaData('OUT')
					currentRecord.normalizeTimes()
				}
			}
		}
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateTransactionRecords(records: Array<TransactionLogRecord>) {
		const transRecords = records.map((rec) => this.makeTransaction(rec))
		for (const newRecord of transRecords) {
			const currentRecord = this.list.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				const currentRowOrder = currentRecord.row_number
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
				currentRecord.row_number = currentRowOrder
			} else {
				this.list.push(newRecord)
			}
		}
	}
}
