<div class="modal fade" tabindex="-1" [id]="modalId" data-bs-keyboard="false" data-bs-backdrop="static" data-bs-focus="false">
	<!-- <div
		#modalElement
		class="modal-dialog modal-dialog-center"
		[class.tts-mobile-dialog]="isMobile"
		style="max-width: 450px"
		appDraggable
		handle=".modal-header"
	> -->
	<div #modalElement class="modal-dialog modal-dialog-center" [class.tts-mobile-dialog]="isMobile" style="max-width: 450px">
		<div class="modal-content" [class.tts-mobile-content]="isMobile">
			<!-- Modal Header -->
			<div class="modal-header">
				<div *ngIf="!showBackBtn" class="modal-title">{{ modalHeader }}</div>
				<div *ngIf="showBackBtn" class="modal-title back-btn" (click)="backBtnClicked()"><i class="fa fa-chevron-left"></i> Back</div>
				<div class="upper-right">
					<div class="help-video"><app-help-video-link [key]="helpVidKey"></app-help-video-link></div>
					<div *ngIf="!saveTable" class="close-button" (click)="onClose()">&times;</div>
				</div>
			</div>

			<!-- Modal body -->
			<div class="modal-body" [class.remove-padding]="removePadding">
				<ng-content></ng-content>
			</div>

			<!-- Modal footer -->
			<div *ngIf="modalFooter" class="modal-footer">
				<div class="float-right" style="margin-top: -3px">
					<button *ngIf="saveTable" class="btn btn-sm btn-secondary modal-btn" (click)="onClose()">
						{{ saveBtnVisible ? 'Cancel' : 'Close' }}
					</button>
					<button
						*ngIf="saveTable && saveBtnVisible"
						style="margin-left: 12px"
						class="btn btn-sm btn-success modal-btn"
						[disabled]="!enableSaveBtn"
						(click)="saveBtnClicked()"
					>
						{{ saveBtnName }}
					</button>
				</div>

				<div *ngIf="!saveTable" class="modal-footer-left" [class.modal-footer-save]="saveTable" style="font-size: 0.9em">
					<div class="modal-footer-label">
						<strong>{{ modalFooter }}</strong>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
