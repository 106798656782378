<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<div class="alert alert-info alert-bg-gray alert-box">
		<div>To make changes, check each section you wish to modify and adjust the settings.</div>
		<div style="margin-top: 12px; color: firebrick">
			Please be careful when editing batches. These changes will overwrite existing settings for all of the selected schedules.
		</div>
		<div style="margin-top: 12px">
			<div>{{ record.schedule_recur_ids.length }} schedule{{ record.schedule_recur_ids.length !== 1 ? 's' : '' }} will be modified</div>
		</div>
	</div>

	<!-- Schedule Enabled / Disabled -->
	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="record.enabled_change" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong (click)="record.enabled_change = !record.enabled_change" class="has-pointer" [class.item-selected]="record.enabled_change"
				>Modify enabled / disabled status</strong
			>
		</div>
	</div>
	<div *ngIf="record.enabled_change" class="change-block">
		<app-form-input-switch
			[(model)]="record.enabled"
			[enabledLabel]="'Schedule is enabled'"
			[disabledLabel]="'Schedule is disabled'"
			[wrapStyle]="{ margin: '30px 0px 10px 0px' }"
			[labelStyle]="{ 'font-size': '1.4rem', 'font-weight': '400' }"
		></app-form-input-switch>
	</div>

	<!-- Employee -->
	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="record.employee_id_change" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong
				(click)="record.employee_id_change = !record.employee_id_change"
				class="has-pointer"
				[class.item-selected]="record.employee_id_change"
				>Modify scheduled employee</strong
			>
		</div>
	</div>
	<div *ngIf="record.employee_id_change" class="change-block">
		<p-select
			appScrollLockSelect
			[(ngModel)]="record.employee_id"
			[options]="employeeOptions"
			[filter]="true"
			filterBy="label,data.external_id"
			[autofocusFilter]="devDetect.isDesktop()"
		></p-select>
	</div>

	<!-- Employee Count -->
	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="record.employee_count_change" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong
				(click)="record.employee_count_change = !record.employee_count_change"
				class="has-pointer"
				[class.item-selected]="record.employee_count_change"
				>Modify employee count</strong
			>
		</div>
	</div>
	<div *ngIf="record.employee_count_change" class="change-block">
		<div class="form-group options-block" style="margin-top: 20px; margin-bottom: 20px">
			<div class="form-row">
				<div class="form-label-bold">
					Schedule
					<input
						type="tel"
						class="form-control form-inline emp-count-input"
						[(ngModel)]="record.employee_count"
						(change)="validateNumericInput('employee_count')"
					/>
					employee(s)
				</div>
			</div>
		</div>
	</div>

	<!-- Job -->
	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="record.job_id_change" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong (click)="record.job_id_change = !record.job_id_change" class="has-pointer" [class.item-selected]="record.job_id_change"
				>Modify scheduled job</strong
			>
		</div>
	</div>
	<div *ngIf="record.job_id_change" class="change-block">
		<p-select
			appScrollLockSelect
			[(ngModel)]="record.job_id"
			[options]="jobOptions"
			[filter]="true"
			filterBy="label,data.external_id"
			[autofocusFilter]="devDetect.isDesktop()"
		></p-select>
	</div>

	<!-- Start Date -->
	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="record.start_date_change" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong (click)="record.start_date_change = !record.start_date_change" class="has-pointer" [class.item-selected]="record.start_date_change"
				>Modify start date</strong
			>
		</div>
	</div>
	<div *ngIf="record.start_date_change" class="change-block">
		<app-date-time-picker
			id="START_DATE"
			[currentDate]="record.start_date"
			[pickerType]="'DATE'"
			[pickerMode]="'dialog'"
			[pickerLabel]="'Start Date'"
			[format]="'ddd MMM Do, YYYY'"
			[useInlineClearIcon]="true"
			(pickerDidChange)="record.start_date = $event"
			(pickerDidClose)="record.start_date = $event"
		>
		</app-date-time-picker>
	</div>

	<!-- Start Time -->
	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="record.start_time_change" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong (click)="record.start_time_change = !record.start_time_change" class="has-pointer" [class.item-selected]="record.start_time_change"
				>Modify start time</strong
			>
		</div>
	</div>
	<div *ngIf="record.start_time_change" class="change-block">
		<app-date-time-picker
			id="START_TIME"
			[currentDate]="record.start_time"
			[startAt]="defaultStartEndTime('START')"
			[pickerType]="'TIME'"
			[pickerMode]="'dialog'"
			[pickerLabel]="'Start Time'"
			[format]="timeFormat"
			[useInlineClearIcon]="true"
			(pickerDidChange)="record.start_time = $event"
			(pickerDidClose)="record.start_time = $event"
		>
		</app-date-time-picker>
	</div>

	<!-- End Date -->
	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="record.recurrence_end_change" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong
				(click)="record.recurrence_end_change = !record.recurrence_end_change"
				class="has-pointer"
				[class.item-selected]="record.recurrence_end_change"
				>Modify end date</strong
			>
		</div>
	</div>
	<div *ngIf="record.recurrence_end_change" class="change-block">
		<app-date-time-picker
			id="END_DATE"
			[currentDate]="record.recurrence_end"
			[pickerType]="'DATE'"
			[pickerMode]="'dialog'"
			[pickerLabel]="'End Date'"
			[format]="'ddd MMM Do, YYYY'"
			[useInlineClearIcon]="true"
			(pickerDidChange)="record.recurrence_end = $event"
			(pickerDidClose)="record.recurrence_end = $event"
		>
		</app-date-time-picker>
	</div>

	<!-- End Time -->
	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="record.end_time_change" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong (click)="record.end_time_change = !record.end_time_change" class="has-pointer" [class.item-selected]="record.end_time_change"
				>Modify end time</strong
			>
		</div>
	</div>
	<div *ngIf="record.end_time_change" class="change-block">
		<app-date-time-picker
			id="END_TIME"
			[currentDate]="record.end_time"
			[startAt]="defaultStartEndTime('END')"
			[pickerType]="'TIME'"
			[pickerMode]="'dialog'"
			[pickerLabel]="'Start Time'"
			[format]="timeFormat"
			[useInlineClearIcon]="true"
			(pickerDidChange)="record.end_time = $event"
			(pickerDidClose)="record.end_time = $event"
		>
		</app-date-time-picker>
	</div>

	<!-- Tags -->
	<div class="checkbox-item d-flex justify-content-between">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="record.tags_json_change" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap">
			<strong (click)="record.tags_json_change = !record.tags_json_change" class="has-pointer" [class.item-selected]="record.tags_json_change"
				>Modify schedule tags</strong
			>
		</div>
	</div>
	<div *ngIf="record.tags_json_change" class="change-block">
		<app-form-tags
			#tagComp
			[tagsJson]="record.tags_json"
			[tagOptionsList]="tagOptionsList"
			(tagsJsonChange)="record.tags_json = $event"
		></app-form-tags>
		<hr style="margin: 30px 0px 25px 0px" />
		<div>
			<div class="float-right">
				<span
					class="badge has-pointer"
					style="margin-right: 8px"
					[class.text-bg-success]="record.tags_action === 'ADD'"
					[class.badge-bg-lightgray]="record.tags_action !== 'ADD'"
					(click)="record.tags_action = 'ADD'"
				>
					ADD
				</span>
				<span
					class="badge has-pointer"
					style="margin-right: 8px"
					[class.text-bg-success]="record.tags_action === 'REMOVE'"
					[class.badge-bg-lightgray]="record.tags_action !== 'REMOVE'"
					(click)="record.tags_action = 'REMOVE'"
				>
					REMOVE
				</span>
				<span
					class="badge has-pointer"
					[class.text-bg-success]="record.tags_action === 'REPLACE_ALL'"
					[class.badge-bg-lightgray]="record.tags_action !== 'REPLACE_ALL'"
					(click)="record.tags_action = 'REPLACE_ALL'"
				>
					REPLACE
				</span>
			</div>
			<div>
				<span style="font-weight: 700">Action</span>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('tags_action')"></i>
			</div>
		</div>
	</div>
</div>
