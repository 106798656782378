<div>
	<div id="HOMECARE_REPORT" class="ur-separator ur-report-sep" style="margin-bottom: 15px">Homecare Report</div>

	<div class="form-group">
		<div class="ur-basic-label">Service Type</div>
		<select id="SERVICE_TYPE" class="form-control" [(ngModel)]="reportData.serviceType.value" (focus)="elementFocused($event)">
			<option value="" selected disabled hidden>select service type</option>
			<option *ngFor="let option of serviceTypeOptions" [value]="option.type">{{ option.name }}</option>
		</select>
		<input
			id="HOMECARE_SPECIFY_TYPE"
			class="form-control"
			style="margin-top: 8px; margin-bottom: 15px"
			*ngIf="reportData.serviceType.value === 'OTHER'"
			[(ngModel)]="reportData.serviceType.option"
			placeholder="specify type of service"
			(focus)="elementFocused($event)"
		/>
	</div>

	<div class="form-group">
		<div class="ur-basic-label">Service Date</div>
		<app-date-time-picker
			id="SERVICE_DATE"
			[currentDate]="reportData.serviceDate.value"
			[pickerType]="reportData.serviceDate.style"
			[pickerMode]="'dialog'"
			[hour12Timer]="format12Hour"
			[format]="datePickerFormat"
			(pickerDidClose)="reportData.serviceDate.value = $event"
		>
		</app-date-time-picker>
	</div>

	<div id="PROVIDER_INFORMATION" class="ur-separator ur-report-sep">Provider Information</div>

	<div class="form-group">
		<div class="row">
			<div class="col-6">
				<div class="ur-basic-label">First Name</div>
				<div>
					<input
						id="PROVIDER_FIRST_NAME"
						class="form-control"
						[(ngModel)]="reportData.serviceProvider.firstName.value"
						(focus)="elementFocused($event)"
					/>
				</div>
			</div>
			<div class="col-6">
				<div class="ur-basic-label">Last Name</div>
				<div>
					<input
						id="PROVIDER_LAST_NAME"
						class="form-control"
						[(ngModel)]="reportData.serviceProvider.lastName.value"
						(focus)="elementFocused($event)"
					/>
				</div>
			</div>
		</div>
	</div>

	<div class="form-group">
		<div
			class="ur-skip-btn"
			*ngIf="!reportData.serviceProvider.license.value"
			(click)="reportData.serviceProvider.license.skip = !reportData.serviceProvider.license.skip"
		>
			{{ reportData.serviceProvider.license.skip ? 'edit' : 'n/a' }}
		</div>
		<div class="ur-basic-label" [class.ur-label-disabled]="reportData.serviceProvider.license.skip">License #</div>
		<div>
			<input
				id="PROVIDER_LICENSE_NUM"
				class="form-control"
				[class.ur-skip-input]="reportData.serviceProvider.license.skip"
				[placeholder]="reportData.serviceProvider.license.skip ? '- skipped -' : ''"
				[disabled]="reportData.serviceProvider.license.skip"
				[(ngModel)]="reportData.serviceProvider.license.value"
				(focus)="elementFocused($event)"
			/>
		</div>
	</div>

	<div id="CLIENT_INFORMATION" class="ur-separator ur-report-sep">Client Information</div>

	<div class="form-group">
		<div class="row">
			<div class="col-6">
				<div class="ur-basic-label">First Name</div>
				<div>
					<input
						id="CLIENT_FIRST_NAME"
						class="form-control"
						[(ngModel)]="reportData.clientInformation.firstName.value"
						(focus)="elementFocused($event)"
					/>
				</div>
			</div>
			<div class="col-6">
				<div class="ur-basic-label">Last Name</div>
				<div>
					<input
						id="CLIENT_LAST_NAME"
						class="form-control"
						[(ngModel)]="reportData.clientInformation.lastName.value"
						(focus)="elementFocused($event)"
					/>
				</div>
			</div>
		</div>
	</div>

	<div class="form-group">
		<div
			class="ur-skip-btn"
			*ngIf="!reportData.clientInformation.medicaidNumber.value"
			(click)="reportData.clientInformation.medicaidNumber.skip = !reportData.clientInformation.medicaidNumber.skip"
		>
			{{ reportData.clientInformation.medicaidNumber.skip ? 'edit' : 'n/a' }}
		</div>
		<div class="ur-basic-label" [class.ur-label-disabled]="reportData.clientInformation.medicaidNumber.skip">Medicaid #</div>
		<div>
			<input
				id="CLIENT_MEDICAID_NUM"
				class="form-control"
				[class.ur-skip-input]="reportData.clientInformation.medicaidNumber.skip"
				[placeholder]="reportData.clientInformation.medicaidNumber.skip ? '- skipped -' : ''"
				[disabled]="reportData.clientInformation.medicaidNumber.skip"
				[(ngModel)]="reportData.clientInformation.medicaidNumber.value"
				(focus)="elementFocused($event)"
			/>
		</div>
	</div>

	<div id="SUPPORT_PLAN" class="ur-separator ur-report-sep">Support Plan</div>

	<div class="form-group">
		<div class="ur-basic-label">Effective Date</div>
		<app-date-time-picker
			id="PLAN_EFFECTIVE_DATE"
			[currentDate]="reportData.supportPlan.effectiveDate.value"
			[pickerType]="reportData.supportPlan.effectiveDate.style"
			[pickerMode]="'dialog'"
			[hour12Timer]="format12Hour"
			[format]="datePickerFormat"
			(pickerDidClose)="reportData.supportPlan.effectiveDate.value = $event"
		>
		</app-date-time-picker>
	</div>

	<div class="form-group">
		<div
			class="ur-skip-btn"
			*ngIf="!reportData.supportPlan.planGoals.value"
			(click)="reportData.supportPlan.planGoals.skip = !reportData.supportPlan.planGoals.skip"
		>
			{{ reportData.supportPlan.planGoals.skip ? 'edit' : 'n/a' }}
		</div>
		<div class="ur-basic-label" [class.ur-label-disabled]="reportData.supportPlan.planGoals.skip">Support Plan Goals</div>
		<textarea
			id="PLAN_GOALS"
			class="form-control ur-basic-textarea"
			[class.ur-skip-textarea]="reportData.supportPlan.planGoals.skip"
			[disabled]="reportData.supportPlan.planGoals.skip"
			[placeholder]="reportData.supportPlan.planGoals.skip ? '- skipped -' : 'Describe support plan goals'"
			[(ngModel)]="reportData.supportPlan.planGoals.value"
			(focus)="elementFocused($event)"
		>
		</textarea>
	</div>

	<div id="SERVICES_PROVIDED" class="ur-separator ur-report-sep">Services Provided</div>

	<div *ngFor="let spw of servicesProvided">
		<div class="ur-checkbox-row">
			<div class="d-flex justify-content-between">
				<div>
					<label class="form-check-label ur-checkbox-label" [class.ur-label-disabled]="spw.service.skip" [for]="spw.key">{{ spw.label }}</label>
				</div>
				<div style="min-width: 80px">
					<div class="ur-skip-btn" style="top: 0px; margin-left: 4px" (click)="toggleSkipCheckbox(spw.service)">
						{{ spw.service.skip ? 'edit' : 'n/a' }}
					</div>
					<input
						[disabled]="spw.service.skip"
						[id]="spw.key"
						type="checkbox"
						class="form-check-input ur-checkbox"
						style="display: inline-block"
						[(ngModel)]="spw.service.value"
					/>
				</div>
			</div>
		</div>

		<div *ngIf="spw.service.value" class="form-group ur-checkbox-textarea">
			<textarea
				class="form-control ur-basic-textarea"
				placeholder="Describe assistance given with rights education"
				[(ngModel)]="spw.service.description"
				(focus)="elementFocused($event)"
			>
			</textarea>
		</div>
	</div>

	<!-- Summary Section -->
	<div id="SUMMARY_SERVICES_PROVIDED" class="ur-separator ur-report-sep">Summary</div>

	<!-- Service Summary -->
	<div class="form-group">
		<div
			class="ur-skip-btn"
			*ngIf="!reportData.summary.summaryOfService.value"
			(click)="reportData.summary.summaryOfService.skip = !reportData.summary.summaryOfService.skip"
		>
			{{ reportData.summary.summaryOfService.skip ? 'edit' : 'n/a' }}
		</div>
		<div class="ur-basic-label" [class.ur-label-disabled]="reportData.summary.summaryOfService.skip">Summary of Service</div>
		<textarea
			id="SUMMARY_SERVICES"
			class="form-control ur-basic-textarea"
			[class.ur-skip-textarea]="reportData.summary.summaryOfService.skip"
			[disabled]="reportData.summary.summaryOfService.skip"
			[placeholder]="reportData.summary.summaryOfService.skip ? '- skipped -' : 'Summarize services provided'"
			[(ngModel)]="reportData.summary.summaryOfService.value"
			(focus)="elementFocused($event)"
		>
		</textarea>
	</div>

	<!-- Outcome of Service -->
	<div class="form-group">
		<div
			class="ur-skip-btn"
			*ngIf="!reportData.summary.outcomeOfService.value"
			(click)="reportData.summary.outcomeOfService.skip = !reportData.summary.outcomeOfService.skip"
		>
			{{ reportData.summary.outcomeOfService.skip ? 'edit' : 'n/a' }}
		</div>
		<div class="ur-basic-label" [class.ur-label-disabled]="reportData.summary.outcomeOfService.skip">Outcome of Service</div>
		<textarea
			id="SUMMARY_OUTCOME"
			class="form-control ur-basic-textarea"
			[class.ur-skip-textarea]="reportData.summary.outcomeOfService.skip"
			[disabled]="reportData.summary.outcomeOfService.skip"
			[placeholder]="reportData.summary.outcomeOfService.skip ? '- skipped -' : 'Describe outcome of services provided'"
			[(ngModel)]="reportData.summary.outcomeOfService.value"
			(focus)="elementFocused($event)"
		>
		</textarea>
	</div>

	<!-- Additional Notes -->
	<div class="form-group">
		<!-- <div class="ur-skip-btn"
				*ngIf="!reportData.summary.additionalNotes.value"
				(click)="reportData.summary.additionalNotes.skip = !reportData.summary.additionalNotes.skip">
				{{ reportData.summary.additionalNotes.skip ? 'edit' : 'n/a' }}
			</div> -->
		<div class="ur-basic-label">Additional Notes</div>
		<textarea
			id="SUMMARY_NOTES"
			class="form-control ur-basic-textarea"
			[class.ur-skip-textarea]="reportData.summary.additionalNotes.skip"
			[disabled]="reportData.summary.additionalNotes.skip"
			[placeholder]="reportData.summary.additionalNotes.skip ? '- skipped -' : 'Additional notes related to services provided'"
			[(ngModel)]="reportData.summary.additionalNotes.value"
			(focus)="elementFocused($event)"
		>
		</textarea>
	</div>
</div>
