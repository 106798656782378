import { Directive, ElementRef, AfterViewInit, HostListener } from '@angular/core'

@Directive({
    selector: '[appTextAreaResizer]',
    standalone: false
})
export class AppTextAreaResizerDirective implements AfterViewInit {
	constructor(private element: ElementRef) {}

	@HostListener('input')
	onInput(): void {
		this.resize()
	}

	ngAfterViewInit(): void {
		setTimeout(() => requestAnimationFrame(() => this.resize()), 250)
	}

	private resize(): void {
		const textarea = this.element.nativeElement
		textarea.style.height = 'auto'
		textarea.style.height = `${textarea.scrollHeight}px`
	}
}
