import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { log } from '@app/helpers'
import { DateTimePickerType } from '@app/models'
import { CoreService } from '@app/services'

import { PickerType, PickerMode } from '@danielmoncada/angular-datetime-picker/lib/date-time/date-time.class'
import moment from 'moment-timezone'

import { v4 as uuid } from 'uuid'

@Component({
    selector: 'app-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.scss'],
    standalone: false
})
export class DateTimePickerComponent implements OnInit {
	public owlPickerType: PickerType = 'both'
	@Input() useTemplate = false
	@Input() useInlineClearIcon = false
	@Input() format = 'YYYY-MM-DD h:m a'

	@Input() pickerType: DateTimePickerType = 'DATETIME'
	@Input() pickerMode: PickerMode = 'dialog'
	@Input() pickerLabel = ''
	@Input() hour12Timer = true
	@Input() currentDate: Date
	@Input() startAt: Date

	@Input() stepMinute: number = 1
	@Input() stepHour: number = 1
	@Input() min: Date = null
	@Input() max: Date = null

	@Output() pickerDidOpen = new EventEmitter<Date>()
	@Output() pickerDidClose = new EventEmitter<Date>()
	@Output() pickerDidChange = new EventEmitter<Date>()

	pickerId = uuid()

	constructor(private coreSrvc: CoreService) {}

	// NOTE: A hack is used here to prevent the date time picker from closing on double-click without
	// setting the day selected. Instead of using the owlPickerType of 'calendar' for date only mode,
	// both calendar and time are made available and then a hack is used to hide the time section when
	// only DATE is needed. The hide-dt-time-picker class is added to the body tag and CSS

	// Another hack is used to prevent bootstrap dialogs from scrolling to top after selecting a date.
	// This hack hides the bootstrap dialog when the date time picker is opened and shows it again when
	// it is closed. It works by figuring out which dialog it's in if any and hiding it when the datetime
	// picker opens and showing the bootstrap dialog again when the datetime picker closes.

	// The following CSS should be added to main style sheet to implement this hack:
	//
	// body.hide-dt-date-picker .owl-dt-dialog-container .owl-dt-calendar {
	// 	display: none;
	// }
	//
	// body.hide-dt-time-picker .owl-dt-dialog-container .owl-dt-timer {
	// 	display: none;
	// }

	public ngOnInit(): void {
		switch (this.pickerType) {
			case 'DATETIME':
				this.owlPickerType = 'both'
				break
			case 'DATE':
				this.owlPickerType = 'both' // Leave as both and use hide-dt-time-picker hack
				break
			case 'TIME':
				this.owlPickerType = 'timer'
				break
		}
	}

	// Do not remove the hide-dt-time-picker class which needs to be set when the compoent opens and removed when it closes
	// Do not remove the hack for bootstrap dialog scrolling to top unless tests pass in safari

	public doOpenPicker() {
		// Class used to indicate datetime picker is open / used to adjust cdk overlay opacity
		$('body')?.addClass('dt-picker-open')

		// Class used in the hack to prevent close on double-click - only needed for DATE mode
		if (this.pickerType === 'DATE') $('body')?.addClass('hide-dt-time-picker')

		// Handle bootstrap issue with scroll jumping to top when setting date
		this.handleBootstrapScrollIssue('open')

		// Used to show the picker label above the picker
		// this.coreSrvc.displaySrvc.datePickerDidOpen.next(this.pickerLabel)
	}

	public afterPickerClosed(): void {
		this.pickerDidClose.emit(this.currentDate)

		// Used to hide the picker label above the picker
		// this.coreSrvc.displaySrvc.datePickerDidClose.next(this.pickerLabel)

		// Need to ensure the picker has time to close before showing the parent dialog
		setTimeout(() => {
			$('body')?.removeClass('dt-picker-open') // Class to indicate a datetime picker is open
			$('body')?.removeClass('hide-dt-time-picker') // Class used in the hack to prevent close on double-click
			this.handleBootstrapScrollIssue('close') // Handle bootstrap issue with scroll jumping to top when setting date
		}, 250)
	}

	handleBootstrapScrollIssue(state: 'open' | 'close') {
		// If the bootstrap modal has  tabindex="-1", they do not need to be hidden when picker is shown
		// in order to prevent scrolling to top on datepicker close. However the bootstrap modals should be
		// hidden to prevent click through on elements in the dialog.
		// if (!this.coreSrvc.devDetect.isDesktop()) return
		if (state === 'open') {
			$(`#${this.pickerId}`).closest('.modal-dialog')?.hide()
		} else {
			$(`#${this.pickerId}`).closest('.modal-dialog')?.show()
		}
	}

	public formatDateString() {
		const mom = moment(this.currentDate)
		if (mom.isValid()) {
			return mom.format(this.format)
		} else {
			return 'Not Set'
		}
	}

	public clearDate(event: MouseEvent) {
		event.preventDefault()
		event.stopPropagation()
		this.currentDate = null
		this.pickerDidChange.emit(this.currentDate)
	}
}
