import { DateTimeHelper } from '@app/helpers/datetime'
import { ColorVendor } from './color-vendor'
import { log } from '@app/helpers'

export interface JobSelectItem {
	label: string
	value: number
	data: JobRecord
}

export type JobsViewTabState = 'ACTIVE' | 'INACTIVE' | 'ALL'

export class JobsViewManager {
	version = 1
	currentView: JobsViewTabState = 'ACTIVE'
}

export class JobRecord {
	active = true
	adp_department_code_id: number
	adp_rate_code_id: number
	allow_breaktime = true // Deprecated - always allow
	auto_created: boolean
	// allow_checkpoints: boolean
	break_time: string
	break_time_worked: string
	check_in_out_window: string
	chkpts_require_photo: boolean
	client_id: number
	client_pay_rate: number
	company_default: boolean
	company_id: number
	created: string
	description: string
	description_long: string
	employee_pay_rate: number
	external_id: string
	flat_rate: boolean = false
	geo_coded: boolean
	id: number
	job_code: number
	job_details: string
	linked_only: boolean = false
	location_id: number
	multi_day: boolean
	ncci_code: number
	notification_profile_id: number
	overage_time: string
	adjust_hours_rule: string // enum for custom rule set
	qbo_customer_id: number
	qbo_vendor_id: number
	qbo_service_item_id: number
	require_photo_checkin: boolean
	require_qrc_checkin: boolean
	require_photo_checkout: boolean
	require_qrc_checkout: boolean
	supervisor_ids: Array<number> = []
	supervisor_group_id: number = null
	sync_lock: boolean
	tags_json: string = null // `{"tags":[{"ts":"2023-11-18T12:38:06.518Z","label":"test","expire":""}]}`
	tour_id: number
	travel: string // 'PAID' | 'UNPAID'
	tts_description: string
	updated: string
	vendor_id: number
	vendor_pay_rate: number
	work_tax_location: string
	waypoint_count: number = 0
	outbound_call_interval: number = null // Integer value / number of minutes / expose when company.cc_enable === TRUE

	repeat_interval: string // Duration joined from tour record

	constructor(record?: JobRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			// this.normalizeTimes()
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
		}
	}

	getAssignedColor(): string {
		if (this.id === 0) {
			return '#147725'
		}
		// if (this.assigned_color) {
		// 	return this.assigned_color
		// }
		return ColorVendor.getColorById(this.id)
	}
}
