import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { FormHelper, log } from '@app/helpers'
import { DatabaseTableName, DialogManager, EditCompleteEvent, EditFormAction, HelpDialogMessage, OnboardDefinitionRecord } from '@app/models'
import { CoreService } from '@app/services'

@Component({
	selector: 'app-onboard-definition-edit',
	standalone: false,

	templateUrl: './onboard-definition-edit.component.html',
	styleUrl: './onboard-definition-edit.component.scss',
})
export class OnboardDefinitionEditComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
	resource: DatabaseTableName = 'onboard_definition'
	isUpdating = false
	form: UntypedFormGroup

	// If both a record and recordId are provided, the record will take precedence
	// and the recordId will be ignored. If neither is provided, a new record will
	// be created. If only recordId is provided, the record will be retrieved from
	// the corosponding service. The defaut is to provide a recordId.

	@Input() recordId: number
	@Input() record: OnboardDefinitionRecord
	@Input() action: EditFormAction = 'edit'

	@Input() dialogManager: DialogManager

	@Output() recordUpdated = new EventEmitter<EditCompleteEvent>()

	constructor(
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
	) {}

	get isNew(): boolean {
		return !this.record?.id
	}

	public ngOnInit(): void {
		this.setupComponent()
		this.setupForm()
	}

	public ngAfterViewInit(): void {}

	public ngAfterContentInit(): void {
		this.setupDialogManager()
	}

	public ngOnDestroy(): void {}

	public setupComponent() {
		const record = this.record ? this.record : this.coreSrvc.dbSrvc.onboardSrvc.getOnboardDefinitionRecordById(this.recordId)
		this.record = new OnboardDefinitionRecord(record)

		if (this.action === 'clone') {
			this.record.id = null
			this.record.description = record.description + ' (copy)'
		}
	}

	public setupForm() {
		const record = this.record

		this.form = this.fb.group({
			id: [record?.id],
			enabled: [record.enabled],
			description: [record.description, Validators.required],
			emp_instructions: [record.emp_instructions, Validators.required],
			requires_approval: [record.requires_approval],
			expires: [record.expires],
			file_count: [record.file_count],
		})
	}

	public setupDialogManager() {
		setTimeout(() => {
			this.dialogManager.canSubmit = () => this.isFormValid()
			this.dialogManager.submitBtnAction = () => this.submit()
		}, 125)
	}

	public isFormValid(): boolean {
		return this.form.valid
	}

	public submit() {
		// Guard against double submission
		if (this.isUpdating) return
		FormHelper.trimOnlyWhitespace(this.form)

		const record = this.makeUpdateRecord()

		if (record) {
			if (this.isNew) {
				log('Insert record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.insertRecord(this.resource, record).then((success) => {
					if (success) {
						this.recordUpdated.emit({ action: this.action, recordId: this.record?.id })
					} else {
						this.isUpdating = false
					}
				})
			} else {
				log('Update record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.updateRecord(this.resource, record).then((success) => {
					if (success) {
						this.recordUpdated.emit({ action: this.action, recordId: this.record?.id })
					} else {
						this.isUpdating = false
					}
				})
			}
		}
	}

	public makeUpdateRecord(): OnboardDefinitionRecord {
		const record = new OnboardDefinitionRecord(this.record)

		record.enabled = this.form.get('enabled').value
		record.description = this.form.get('description').value
		record.emp_instructions = this.form.get('emp_instructions').value
		record.requires_approval = this.form.get('requires_approval').value
		record.expires = record.requires_approval ? this.form.get('expires').value : false
		record.file_count = parseInt(this.form.get('file_count').value, 10)

		// Update any values changed by the form here

		return record
	}

	public toggleCheckbox(prop: string) {
		const current = this.form.get(prop).value
		this.form.get(prop).setValue(!current)
	}

	private scrollIntoView(id: string) {
		this.coreSrvc.displaySrvc.bringIntoViewBySelector(id)
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'description':
				help.header = 'Description'
				help.message =
					'Enter a short description that can be used to identify this document. Use the <b>Employee Instructions</b> field to provide detailed instructions to the employee.'
				break
			case 'emp_instructions':
				help.header = 'Instructions'
				help.message =
					'Enter detailed information about what documents you are requesting from the employee. Any type of file can be uploaded to this document request, including images, pdfs, and scanned documents.'
				break
			case 'file_count':
				help.header = 'File Count'
				help.message =
					'This options specifies the minimum number of files the employee needs to provide to complete this document request. This information should match what is being requested in the <b>Employee Instructions</b> field. If this field is blank, no minimum file count will be enforced.'
				break
			case 'require_approval':
				help.header = 'Approval Required'
				help.message =
					'When this option is selected, responses to this document request will be showing in an onboarding review section for approval by an admin.'
				break
			case 'expires':
				help.header = 'Expiration'
				help.message =
					'When this option is selected, the admin reviewing responses to this document request must set an expiration date for the documents submitted.<br><br>On the expiration date, a new document request will be automatically triggered for the employee. This option is typically used to request updated copies of certifications that require periodic renewal.'
				break

			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
