//
// Production environment - debug/development mode
//

export const environment = {
	production: false,
	development: true,

	enableAuditMode: true,
	enableLogging: true,

	// Global
	region: 'us-east-1',

	// ADP Login
	adpLoginRole: 'arn:aws:iam::359590772493:role/Cognito_IVRTIMESHEETSAuth_Role',

	adpRunMarketplaceAppUrl: 'https://apps.adp.com/apps/335869',
	adpRunIntegrationConsentUrl:
		'https://adpapps.adp.com/consent-manager/pending/direct?consumerApplicationID=68583ac4-cc3d-49e8-b790-ad517c15ee48&successUri=https://secure.telephonetimesheets.com/#/admin/integrations/adp',

	adpWfnMarketplaceAppUrl: 'https://apps.adp.com/apps/379098',
	adpWfnIntegrationConsentUrl:
		'https://adpapps.adp.com/consent-manager/pending/direct?consumerApplicationID=96b3b999-8bb8-4b90-a3aa-856e1450b86d&successUri=https://secure.telephonetimesheets.com/#/admin/integrations/adp',

	// Cognito
	identityPoolId: 'us-east-1:c1984192-c437-45b0-a8c7-6987da4b1a65',
	userPoolId: 'us-east-1_Hfqg0pdfs',
	clientId: '78kjchbo67rtjau68v2drc11e5',

	// Endpoints
	cognito_idp_endpoint: '',
	cognito_identity_endpoint: '',
	sts_endpoint: '',

	// Buckets
	qbdUploadBucket: 'tts-quickbooks-upload',
	assetsBucket: 'assets.telephonetimesheets.com',
	audioBucket: 'audio.telephonetimesheets.com',
	reportsBucket: 'reports.telephonetimesheets.com',
	fileImportBucket: 'uploads.telephonetimesheets.com',
	secureUploadBucket: 'file-uploads.telephonetimesheets.com',

	// App Domains
	empAppDomain: 'testclock.telephonetimesheets.com',
	portalDomain: 'test.telephonetimesheets.com',

	// Common Domains
	assetsDomain: 'assets.telephonetimesheets.com',
	audioDomain: 'audio.telephonetimesheets.com',
	reportsDomain: 'reports.telephonetimesheets.com',
	developerDomain: 'developer.telephonetimesheets.com',

	// Lambdas
	dataServiceLambdaFunctionName: 'TimesheetsDataAccess',
	fiSyncLambdaFunctionName: 'CustomerFileImport',
	adpSyncLambdaFunctionName: 'AdpDataSync',
	qboSyncLambdaFunctionName: 'QBODataSync',
	qbdSyncLambdaFunctionName: 'QBDesktopSync',
	qbdSyncS3LambdaFunctionName: 'QBDesktopSyncS3',
	qboDisconnectLambdaFunctionName: 'QBODisconnect',
	qboOperationsLambdaFunctionName: 'QBOOperations',
	wiwSyncLambdaFunctionName: 'WIWDataSync',
	reportsLambdaFunctionName: 'ReportLambda:SNAP',
	paypalLambdaFunctionName: 'PayPal',
	teliLambdaFunctionName: 'PhoneNumberTeli',
	companyCreateLambdaFunctionName: 'TimesheetsCompanyCreate',
	employeeReportRequest: 'TimesheetsIncidentAPI',

	// API Gateway Urls
	timesheetsCompanyCreatePing: 'https://3zs535h5lk.execute-api.us-east-1.amazonaws.com/prod/TimesheetsCompanyCreatePing',
	userPoolTriggerPreSignUp: 'https://3zs535h5lk.execute-api.us-east-1.amazonaws.com/prod/UserPoolTriggerPreSignUp',
	phoneNumberTeliPing: 'https://3zs535h5lk.execute-api.us-east-1.amazonaws.com/prod/PhoneNumberTeliPing',

	// Endpoints
	apiEndpoint: 'api.telephonetimesheets.com',
}
