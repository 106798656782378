import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { DateTimeHelper, DisplayHelper, log } from '@app/helpers'
import { Checkpoint, FileUploaderProcessedFile, FileUploadManager, ImageFile, WaypointLogRecord, WaypointLogStore } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { environment } from '@env/environment'
import moment from 'moment-timezone'

class CPEdit {
	adminCreatedDate: Date = null
	comments = ''
	currentImagesList: Array<ImageFile> = []
	fileUploadManager = new FileUploadManager()
}

@Component({
    selector: 'app-checkpoints-list-item',
    templateUrl: './checkpoints-list-item.component.html',
    styleUrls: ['./checkpoints-list-item.component.scss'],
    standalone: false
})
export class CheckpointsListItemComponent implements OnInit {
	isDesktop = false
	isGlobalAccount = false
	isDeleteAllowed = true

	showConfirmDelete = false
	showWaypointAddConfirmation = false
	isDeleting = false

	is12HourFormat = true
	datePickerFormat = 'MMM Do, YYYY @ h:mm a'

	@Input() cp: Checkpoint
	@Input() cpIdx: number
	@Input() showCheckbox: boolean
	@Input() timezone: string
	@Input() doesJobHaveTour = true
	@Input() wplRecord: WaypointLogRecord

	@Output() setCurrentCheckpoint = new EventEmitter<Checkpoint>()
	@Output() showCheckpoint = new EventEmitter<Checkpoint>()
	@Output() checkpointUpdating = new EventEmitter<boolean>()
	@Output() checkpointUpdated = new EventEmitter<boolean>()
	@Output() editCancelled = new EventEmitter<boolean>()
	@Output() addCheckpointToTour = new EventEmitter<boolean>()
	@Output() showAuditLogDialog = new EventEmitter<Checkpoint>()

	cpEdit = new CPEdit()
	refTs: number = null

	// currentImagesList: Array<ImageFile> = []
	// fileUploadManager = new FileUploadManager()

	constructor(private coreSrvc: CoreService) {
		if (this.coreSrvc.devDetect.isDesktop()) {
			this.isDesktop = true
		}
		this.isGlobalAccount = this.coreSrvc.dbSrvc.settingSrvc.isGlobalAccount()
		this.isDeleteAllowed = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()
		this.setupFileUploadManager()
	}

	get isDevelopment(): boolean {
		return !this.coreSrvc.env.production
	}

	ngOnInit(): void {
		// Setup date picker formatter
		const abbrev = moment.tz(this.timezone).zoneAbbr()
		this.is12HourFormat = DateTimeHelper.format12Hour
		this.datePickerFormat = this.is12HourFormat ? `MMM Do, YYYY @ h:mm a` : `MMM Do, YYYY @ HH:mm`
		this.refTs = moment(this.cp.created).unix()
		this.setupEditor()
	}

	// Formatting

	formatTime(timeString: string, type: 'SHORT' | 'LONG' = 'LONG'): string {
		const is12Hours = DateTimeHelper.format12Hour
		const checkpointMoment = moment.utc(timeString)
		const hoursFormat = is12Hours ? 'h:mm a z' : 'HH:mm z'
		const format = type === 'SHORT' ? `${hoursFormat}` : `ddd Do @ ${hoursFormat}`
		return checkpointMoment.tz(this.timezone).format(format)
	}

	formatDistance(dist: number) {
		return `${dist} mi`
	}

	formatGps(cp: Checkpoint) {
		if (cp.admin_created) return 'Admin Checkpoint'
		return cp.client_error ? cp.client_error : 'GPS Not Provided'
	}

	formatType(cp: Checkpoint) {
		const type = cp.type
		switch (type) {
			case 'CHECKIN':
				return 'check in checkpoint'
			case 'CHECKOUT':
				return 'check out checkpoint'
			default:
				return 'shift checkpoint'
		}
	}

	// Events

	showDeleteCheckpointConfirmation() {
		this.showConfirmDelete = true
		this.showWaypointAddConfirmation = false
		const selector = '#cp-list-item-content-' + this.cpIdx
		DisplayHelper.scrollIntoViewBySelector(selector)
	}

	deleteCheckpoint(cp: Checkpoint) {
		// this.checkpointUpdating.next(true)
		// this.showConfirmDelete = false
		this.isDeleting = true
		const cpId = cp.id
		this.coreSrvc.dbSrvc.deleteRecord('checkpoint', cp.id).then((result) => {
			log('Checkpoint Deleted')
			this.checkpointUpdated.next(true)
		})
	}

	addWaypointToTour(cp: Checkpoint) {
		cp.isSelected = true
		this.addCheckpointToTour.next(true)
		this.showWaypointAddConfirmation = false
	}

	setupEditor() {
		const cp = this.cp
		this.cpEdit.adminCreatedDate = DateTimeHelper.convertIsoDateStringToLocalDate(this.cp.admin_created, this.timezone)
		this.cpEdit.comments = this.cp.comments
		// log('cpEdit', this.cpEdit)
		this.configureFileUploadManager(cp)
		this.updateCurrentImagesList()
	}

	editCheckpoint(cp: Checkpoint) {
		log('Editing checkpoint', cp)
		this.showConfirmDelete = false
		this.setCurrentCheckpoint.next(cp)
		this.setupEditor()
		cp.isEditing = true
		// cp.origComments = cp.comments
	}

	showAuditLog(cp: Checkpoint) {
		this.showAuditLogDialog.next(cp)
	}

	cancelCheckpointEdit(cp: Checkpoint) {
		log('Cancel checkpoint edit')
		// cp.comments = cp.origComments
		cp.isEditing = false
		this.setCurrentCheckpoint.next(null)
		this.editCancelled.next(true)
	}

	saveCheckpointEdit(cp: Checkpoint) {
		log('Save changes', cp)
		const record = this.makeUpdateRecord(cp)
		this.checkpointUpdating.next(true)
		if (record.id) {
			// Editing an existing checkpoint
			this.coreSrvc.dbSrvc.updateRecord('checkpoint', record).then((success) => {
				log('Result of update', success)
				cp.isEditing = false
				this.setCurrentCheckpoint.next(null)
				this.cpEdit.fileUploadManager.processedFiles = []
				this.checkpointUpdated.next(true)
			})
		} else {
			// Creating a new checkpoint
			log('Creating a new checkpoint')
			this.coreSrvc.dbSrvc.insertRecord('checkpoint', record).then((success) => {
				log('Result of insert', success)
				cp.isEditing = false
				this.setCurrentCheckpoint.next(null)
				this.cpEdit.fileUploadManager.processedFiles = []
				this.checkpointUpdated.next(true)
			})
		}
	}

	public showAddWaypointConfirmation() {
		this.showConfirmDelete = false
		this.showWaypointAddConfirmation = true
		const selector = '#cp-list-item-content-' + this.cpIdx
		DisplayHelper.scrollIntoViewBySelector(selector)
	}

	private makeUpdateRecord(cp: Checkpoint): Checkpoint {
		const record = new Checkpoint(cp)
		record.images_json = this.cpEdit.fileUploadManager.getUpdateJson()
		record.comments = this.cpEdit.comments
		record.admin_created = DateTimeHelper.convertDateToIsoStringKeepingLocalTime(this.cpEdit.adminCreatedDate, this.timezone)
		return record
	}

	private setupFileUploadManager() {
		log('Setup File Upload Manager')
		const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
		const fileUploadManager = new FileUploadManager()
		fileUploadManager.setConfiguration(companyId, environment.assetsBucket, 'checkpoints')
		fileUploadManager.supportedMimeTypes = ['image/gif', 'image/png', 'image/jpeg']
		fileUploadManager.useFileExtFallbackForMimeType = true
		this.cpEdit.fileUploadManager = fileUploadManager
	}

	private configureFileUploadManager(cp: Checkpoint): void {
		const files = cp.getImages().map((rec) => new FileUploaderProcessedFile(rec))
		this.cpEdit.fileUploadManager.processedFiles = files
	}

	public updateCurrentImagesList(): void {
		this.cpEdit.currentImagesList = this.cpEdit.fileUploadManager.processedFiles.map((pf) => new ImageFile(pf))
		// if (!this.currentCheckpoint) {
		// 	this.currentImagesList = []
		// } else {
		// 	this.currentImagesList = this.fileUploadManager.processedFiles.map((pf) => new ImageFile(pf))
		// }
	}

	public postNoGpsForCheckpointNotification() {
		this.coreSrvc.notifySrvc.notify('info', 'No GPS Info', 'There is no GPS information available for this checkpoint')
	}
}
