<div class="container section-container">
	<div class="sec-head-wrap">
		<div class="sec-act-ico-wrap">
			<i *ngIf="viewManager.currentView === 'AGENTS'" class="far fa-cog sec-act-ico" (click)="openPrefsDialog()" title="Manage Columns"></i>
			<i class="far fa-question-circle sec-act-ico" (click)="toggleSectionHelp()" title="Show Help"></i>
		</div>

		<div class="sec-title-wrap" [class.sec-head-title-highlight]="viewManager.dayViewDate">
			<div *ngIf="!viewManager.dayViewDate">
				<i class="far fa-bars-staggered sec-head-ico"></i>
				<span>Comm Log</span>
				<app-sub-section-title [isVisible]="true" [subSectionTitle]="subSectionTitle"></app-sub-section-title>
			</div>
			<div
				*ngIf="viewManager.dayViewDate"
				[owlDateTimeTrigger]="dtDayViewDate"
				(click)="hideDtTimePicker()"
				style="cursor: pointer; display: inline"
			>
				{{ viewManager.dayViewDate | dateFormatter: 'ddd, MMM Do' }}
				<div class="sec-title-ico-wrap">
					<i class="fal fa-calendar sec-title-ico"></i>
				</div>
			</div>
		</div>

		<!-- Day View Date Picker -->
		<owl-date-time
			#dtDayViewDate
			[startAt]="viewManager.dayViewDate"
			[hour12Timer]="true"
			[pickerType]="'both'"
			[pickerMode]="'dialog'"
			(afterPickerClosed)="showDtTimePicker(); dayViewPickerClosed()"
		></owl-date-time>
		<input
			[hidden]="true"
			class="form-control datetime-input"
			id="dayViewDate"
			placeholder=""
			[(ngModel)]="viewManager.dayViewDate"
			[owlDateTime]="dtDayViewDate"
		/>

		<div class="sub-head-wrap">
			<div class="sub-head-ico-wrap">
				<!-- Day View Icon -->
				<div class="ico-inline-block" title="Toggle Day View" (click)="toggleDayView()">
					<i *ngIf="!viewManager.dayViewDate" class="far fa-calendar sub-head-ico"></i>
					<i *ngIf="viewManager.dayViewDate" class="far fa-calendar-check sub-head-ico sub-head-ico-highlight"></i>
				</div>

				<!-- Prev / Next Icons -->
				<div *ngIf="viewManager.dayViewDate" class="ico-inline-block">
					<i class="far fa-arrow-left sub-head-ico" (click)="previousDay()" title="Next"></i>
					<i class="far fa-arrow-right sub-head-ico" (click)="nextDay()" title="Previous"></i>
				</div>

				<!-- Refresh Icon -->
				<i class="fa fa-refresh sub-head-ico" (click)="fetchAndReload()" title="Refresh Data"></i>
				<div class="updated-wrap">{{ lastUpdated }}</div>
			</div>
		</div>
	</div>

	<div *ngIf="showTabSwitcher">
		<app-section-switcher [config]="switcherConfig" [type]="'NOCOUNT'" [maxWidth]="'410px'" (tabClicked)="sectionTabClicked($event)">
		</app-section-switcher>
	</div>

	<div *ngIf="showSectionHelp" class="card card-body tooltip-block tooltip-info-text tooltip-section-header">
		<button type="button" class="close" aria-label="Close" (click)="toggleSectionHelp()">
			<span>&times;</span>
		</button>
		Comm logs show information related to phone / text interactions with the system.
		<span *ngIf="showTabSwitcher"
			><strong>Calls / Texts</strong> shows interactions between dispatchers and others along with system generated events.</span
		>
		<strong>IVR Debug</strong> entries are generated when a call into the system ends without creating or modifying a time entry. This usually
		indicates that an employee is having trouble checking in or checking out. <strong>SMS Debug</strong> entries show information about all text
		messages received by or sent from the system.

		<br />
	</div>

	<div class="option-dropdown-wrap">
		<!-- <div class="job-timezone">{{ currentTimezone }}</div> -->
		<div *ngIf="viewManager.isLoading" class="processing-status"><i class="fas fa-loader fa-spin"></i> Processing Log...</div>
		<div
			*ngIf="!viewManager.isLoading"
			[class.option-dropdown-mobile]="devDetect.isMobile()"
			[class.d-flex]="!devDetect.isMobile()"
			[class.justify-content-between]="!devDetect.isMobile()"
		>
			<div class="selector-wrap">
				<div class="option-dropdown">
					<p-select
						appScrollLockSelect
						[options]="timezoneOptions"
						[(ngModel)]="viewManager.timezone"
						[filter]="true"
						filterBy="label"
						(onChange)="fetchAndReload()"
					></p-select>
				</div>
			</div>
			<div *ngIf="!devDetect.isMobile()" class="option-wrap-title-right">{{ subSectionTitle }}</div>
			<!-- <div style="display: inline-block;"><i class="fa fa-refresh"></i></div> -->
		</div>
	</div>

	<!-- <div *ngIf="viewManager.currentView === 'IVR'" class="ivr-block">
		<div class="card" style="padding: 15px; margin-top: 25px">
			<div style="text-align: center; font-size: 32px">
				<i class="far fa-screwdriver-wrench"></i> Under Construction
			</div>
		</div>
	</div> -->

	<div *ngIf="viewManager.currentView === 'AGENTS'" class="dispatch-block">
		<app-comm-log-agent-table #agentTable></app-comm-log-agent-table>
	</div>
	<div *ngIf="viewManager.currentView === 'IVR'" class="dispatch-block">
		<app-comm-log-ivr-table #ivrTable></app-comm-log-ivr-table>
	</div>
	<div *ngIf="viewManager.currentView === 'SMS'" class="dispatch-block">
		<app-comm-log-sms-table #smsTable></app-comm-log-sms-table>
	</div>
</div>
