<div
	*ngIf="shouldShowBanner"
	class="top-banner noprint"
	[class.banner-wrap-no-group]="!bvm.groupAllBanners"
	[class.top-banner-warn]="status.isWarn"
	[class.top-banner-danger]="status.isDanger"
	(click)="viewOpenShifts()"
>
	<div class="top-banner-content" [class.blink]="status.isCritical">
		<i *ngIf="status.hasBeenAccepted" class="far fa-circle-check accepted-icon"></i>Open Shifts
		<span class="badge top-banner-badge">{{ status.notificationCount }}</span>
	</div>
</div>
