import { Component, OnInit, OnDestroy, Input, EventEmitter, Output, ChangeDetectorRef, ViewChild } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'

import { EmployeeRecord, JobSiteRecord, DialogControlEvent, UserPermissions, FormAddressManager, HelpDialogMessage, DialogManager } from '@app/models'
import { CoreService } from '@app/services'

import { log, Helper, LanguageHelper, PhoneHelper, FormHelper, DisplayHelper } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'

import { SelectItem } from 'primeng/api'
import { parseNumber, format, formatNumber, CountryCode, ParsedNumber, isValidNumber, isValidNumberForRegion } from 'libphonenumber-js'
import { parsePhoneNumber } from 'libphonenumber-js/max'

import { Subscription } from 'rxjs'
import { NumberHelper } from '@app/helpers/number'
import { environment } from '@env/environment'

import _ from 'lodash'
import moment from 'moment-timezone'
import { FormTagsComponent } from '@app/components/form-tags/form-tags.component'
interface DuplicateNumberAction {
	showDialog: boolean
	action: 'merge' | 'transfer'
}

@Component({
	selector: 'app-employee-edit',
	templateUrl: './employee-edit.component.html',
	styleUrls: ['./employee-edit.component.scss'],
	standalone: false,
})
export class EmployeeEditComponent implements OnInit, OnDestroy {
	accessHelper: AccessHelper
	permissions: UserPermissions

	languageList = LanguageHelper.list

	title: string
	empForm: UntypedFormGroup

	isNew: boolean
	isUpdating: boolean

	isRecordValid = true // Assume record is valid
	isQBDIntegrated = false
	isQBOIntegrated = false
	isADPIntegrated = false
	isW2WIntegrated = false

	isInternalUser = false
	isDevEnvironment = environment.development

	hasRoundUpTimer = false
	hasRoundDownTimer = false

	showSupervisors = false
	showUserGroups = false
	showAdvancedOptions = false
	showAutoCheckInOutOption = false
	showPayRateOption = false
	showTravelTracking = false
	showTaxLocations = false

	currencySymbol = ''
	countryCodeData = Helper.countryIsoData.filter((region) => region.empSupport)

	phoneNumberValid = false
	duplicateNumber: DuplicateNumberAction = { showDialog: false, action: null }

	formAddressManager: FormAddressManager // Manage Address Block

	taxLocationList: Array<string> = []
	taxLocationOptions: Array<string> = []

	supervisorOptions: Array<SelectItem> = []
	statusOptions: Array<SelectItem> = []
	userGroupOptions: Array<SelectItem> = []
	tagOptionsList = this.coreSrvc.dbSrvc.empSrvc.getTagLabels()

	smsOverridePlaceholder = 'Override SMS Number'
	smsOverrideOptions: Array<SelectItem> = []

	linkedJobSites: JobSiteRecord[] = []

	shouldFormatDisplayNameLastFirst = false

	departmentList: Array<string> = []
	departmentOptions: Array<string> = []

	empAppLoginUrl: string = null

	notSet = '< not set >'

	@Input() action: string
	@Input() employeeId: number
	@Input() dialogManager: DialogManager

	@Output() editActionCancelled = new EventEmitter<boolean>()
	@Output() saveActionComplete = new EventEmitter<number>()

	@ViewChild('tagComp') tagComp: FormTagsComponent

	public employee: EmployeeRecord
	private subs = new Subscription()

	constructor(
		private cd: ChangeDetectorRef,
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
	) {
		log('EmployeeDetailConstructor')
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()

		this.setupAccessPermissions()
		// DEPRECATED 20240911 - Moved to new dialog component
		// this.subs.add(this.coreSrvc.eventSrvc.dialogControlEvents.subscribe((event) => this.processControlEvents(event)))

		// Subscribe to dataAccessError to deal with duplicate numbers
		this.subs.add(this.coreSrvc.dbSrvc.lambdaSrvc.dataAccessErrorEvent.subscribe((event) => this.handleDataAccessErrorEvent(event)))

		// Setup company wide prefs
		const companyPrefs = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany()
		this.shouldFormatDisplayNameLastFirst = companyPrefs.employeeDisplayLastFirst
		this.showAutoCheckInOutOption = companyPrefs.employeeAutoCheckInOut

		// Setup department list
		this.departmentList = this.coreSrvc.dbSrvc.empSrvc.getDepartments()
		this.currencySymbol = this.coreSrvc.dbSrvc.settingSrvc.getCompanyCurrencySymbol()

		// Setup tax location list
		this.showTaxLocations = company.work_tax_location
		this.taxLocationList = this.coreSrvc.dbSrvc.settingSrvc.getTaxLocations(this.coreSrvc.dbSrvc)
		this.showPayRateOption = company.pay_rate

		// Setup travel tracking option
		this.showTravelTracking = company.travel && company.travel_target === 'SELECTED'

		// Create new address form manager
		this.formAddressManager = new FormAddressManager(this.coreSrvc)

		// Setup round up (clock-in) timer and rounddown (clock-out) timer
		this.hasRoundUpTimer = !!company.round_up_timer
		this.hasRoundDownTimer = !!company.round_down_timer

		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()
	}

	get isAuditMode(): boolean {
		return this.coreSrvc.auditMode
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'employee')
		this.permissions = this.accessHelper.getPermissionsFor('employee')
	}

	isFormValid(): boolean {
		const isValid = this.empForm.valid && this.phoneNumberValid && this.formAddressManager.isFormValid
		return isValid
	}

	ngOnInit() {
		this.setupComponent()
		this.setupForm()
		this.setupAdvancedOptions()
		this.setupSupervisorsDropdown()
		this.setupStatusDropdown()
		this.setupUserGroupDropdown()
		this.setupSmsOverrideDropdown()
		this.setupEmpAppLoginUrl()
		log('EmployeeDetail: ngOnInit', this.action, this.employeeId, this.employee)
	}

	ngAfterViewInit() {
		this.setupDialogManager()
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	setupDialogManager() {
		setTimeout(() => {
			this.dialogManager.submitBtnAction = () => this.submit()
			this.dialogManager.canSubmit = () => this.isFormValid()
		}, 100)
	}

	setupComponent() {
		if (this.action === 'new') {
			this.title = 'New Employee'
			this.employee = new EmployeeRecord()
			this.isNew = true
		} else if (this.action === 'edit') {
			this.employee = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(this.employeeId)
			this.title = 'Edit Employee'
			this.isNew = false
			// Hide form if trying to edit non-existent record
			if (!this.employee) {
				this.isRecordValid = false
				return
			}
			// Get list of linked job sites
			if (this.employee) {
				this.linkedJobSites = this.coreSrvc.dbSrvc.siteSrvc.jobSitesForEmpMobileNumberE164(this.employee.phone_number_e164)
			}
		}
		// Setup supervisors list

		// Check for QBDesktop integration
		this.isQBDIntegrated = this.coreSrvc.dbSrvc.qbSrvc.isQBDCustomer()
		this.isQBOIntegrated = this.coreSrvc.dbSrvc.qbSrvc.isQBOCustomer()
		this.isADPIntegrated = this.coreSrvc.dbSrvc.adpSrvc.isAdpIntegrated()
		this.isW2WIntegrated = this.coreSrvc.dbSrvc.w2wSrvc.isW2WIntegrated()
	}

	setupForm() {
		const birthday = this.employee.birthday ? moment(this.employee.birthday).toDate() : null
		const anniversary = this.employee.anniversary ? moment(this.employee.anniversary).toDate() : null

		const units = this.coreSrvc.dbSrvc.settingSrvc.getCompany().default_units
		const distUnits = units === 'IMPERIAL' ? 'MI' : 'KM'

		if (!this.isRecordValid) {
			return
		}
		this.empForm = this.fb.group({
			active: [this.employee ? this.employee.active : true],
			// adp_rate_code_id: [this.employee ? this.employee.adp_rate_code_id : null],
			auto_check_in_out: [this.employee ? this.employee.auto_check_in_out : false],
			anniversary: [this.employee ? anniversary : null],
			birthday: [this.employee ? birthday : null],
			department: [this.employee ? this.employee.department : null],
			email: [this.employee ? this.employee.email : null],
			emp_code: [this.employee ? this.employee.emp_code : null],
			employee_details: [this.employee ? this.employee.employee_details : null],
			external_id: [this.employee ? this.employee.external_id : null],
			home_tax_location: [this.employee ? this.employee.home_tax_location : null],
			id: [this.employee ? this.employee.id : ''],
			name: [this.employee ? this.employee.name : '', Validators.required],
			first: [this.employee ? this.employee.first : '', Validators.required],
			last: [this.employee ? this.employee.last : '', Validators.required],
			pay_rate: [this.employee ? this.employee.pay_rate?.toFixed(2) : null],
			phone_number: [this.employee ? this.employee.phone_number : ''],
			phone_number_e164: [this.employee ? this.employee.phone_number_e164 : '', [Validators.required]],
			phone_sms_id: [this.employee ? this.employee.phone_sms_id : null],
			language: [this.employee ? this.employee.language : 'en'],
			supervisor: [this.employee ? this.employee.supervisor : null, [Validators.required]],
			supervisor_group_id: [this.employee ? this.employee.supervisor_group_id : null],
			disable_sms: [this.employee ? !this.employee.disable_sms : false], // Negate from server in
			qbo_id: [this.employee ? this.employee.qbo_id : ''],
			dialingCode: this.coreSrvc.dbSrvc.settingSrvc.getCompany().country_iso,
			start_date: [this.employee ? this.employee.start_date : null],
			travel: [this.employee ? this.employee.travel : false],
			union: [this.employee ? this.employee.union : false],
			floater: [this.employee ? this.employee.floater : false],
			tags_json: [this.employee ? this.employee.tags_json : null],
			voice_fingerprint: [this.employee ? this.employee.voice_fingerprint : false],

			round_up_timer_disable: [this.employee ? this.employee.round_up_timer_disable : false],
			round_down_timer_disable: [this.employee ? this.employee.round_down_timer_disable : false],

			commute_distance: [this.employee ? this.employee.commute_distance : null],
			commute_distance_units: [this.employee ? (this.employee.commute_distance_units ?? distUnits) : distUnits],

			// Disable editing if W2W integrated
			max_hrs_daily: [this.employee ? this.employee.max_hrs_daily : null],
			max_shifts_daily: [this.employee ? this.employee.max_shifts_daily : null],
			max_hrs_weekly: [this.employee ? this.employee.max_hrs_weekly : null],
			max_days_weekly: [this.employee ? this.employee.max_days_weekly : null],

			status: [this.employee ? this.employee.status : null],
			employment_status_notes: [this.employee ? this.employee.employment_status_notes : null],
		})

		// Setup country code and formatted number for existing records
		if (this.action === 'edit') {
			const parsedNumber = parseNumber(this.employee.phone_number_e164) as ParsedNumber
			if (parsedNumber.phone && isValidNumber(parsedNumber)) {
				this.empForm.patchValue({ dialingCode: parsedNumber.country })
				this.empForm.patchValue({ phone_number_e164: formatNumber(parsedNumber, 'NATIONAL') })
				this.addDialingCodeForNumber(parsedNumber)
			}
		}
		this.formatPhoneNumber()

		// Setup Form Address Manager
		this.formAddressManager.setAddress(this.employee)

		// Disable fields if W2W Integrated
		if (this.isW2WIntegrated) {
			this.empForm.get('max_hrs_daily').disable()
			this.empForm.get('max_shifts_daily').disable()
			this.empForm.get('max_hrs_weekly').disable()
			this.empForm.get('max_days_weekly').disable()
		}
	}

	private handleDataAccessErrorEvent(event) {
		// log('Schedule got overlap exception', event)
		const type = event?.errorType
		if (type === 'com.sst.ivr.lambda.exceptions.PhoneNumberExistsException') {
			this.coreSrvc.zone.run(() => {
				this.duplicateNumber.action = null
				this.duplicateNumber.showDialog = true
			})
		}
	}

	public mergeBtnClicked() {
		log('mergeBtnClicked')
		this.coreSrvc.notifySrvc.clear()
		this.duplicateNumber.showDialog = false
		this.duplicateNumber.action = 'merge'
		this.submit()
	}

	public transferBtnClicked() {
		log('transferBtnClicked')
		this.coreSrvc.notifySrvc.clear()
		this.duplicateNumber.showDialog = false
		this.duplicateNumber.action = 'transfer'
		this.submit()
	}

	private setupAdvancedOptions() {
		const myUserPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		const showAdvancedOptions = myUserPrefs.globalExpandAdvancedOptions
		this.showAdvancedOptions = showAdvancedOptions
		if (this.showAdvancedOptions) this.updateDynamicTextareas()
	}

	private setupEmpAppLoginUrl() {
		const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
		this.empAppLoginUrl = this.coreSrvc.dbSrvc.empLoginSrvc.getEmployeeLoginAuthUrlByEmpId(this.coreSrvc.dbSrvc, this.employeeId, companyId)
		if (!this.isAuditMode || this.isInternalUser) log('Employee Login Link', this.empAppLoginUrl)
	}

	public copyCredential() {
		if (this.empAppLoginUrl) {
			const data = this.employee ? { source: 'EMPEDITFORM', description: `${this.employee.first} ${this.employee.last}` } : null
			navigator.clipboard.writeText(this.empAppLoginUrl)
			this.coreSrvc.notifySrvc.notify('success', 'Link Copied', 'The authentication link for this employee has been copied to your clipboard.')
			this.coreSrvc.eventSrvc.sendAuthLinkAccessEvent(this.employeeId, data)
			this.empForm.get('active').setValue(true)
		}
	}

	addDialingCodeForNumber(parsedNumber: ParsedNumber) {
		log('Add Dialing Code For', parsedNumber)
		const countryCode = parsedNumber.country
		const callingCode = parsedNumber.countryCallingCode
		log('Current Countries', this.countryCodeData)
		const found = this.countryCodeData.find((cc) => cc.id === countryCode)
		if (!found) {
			const newCode = {
				id: countryCode,
				prefix: `${callingCode}`,
				region: `Custom ${countryCode}`,
				regionSupport: false,
				empSupport: true,
			}
			this.countryCodeData.push(newCode)
		}
	}

	// Determines if form should be shown or not
	get showForm(): boolean {
		if (!this.isRecordValid) {
			return false
		}
		return true
	}

	setupSupervisorsDropdown() {
		const userPermissions = this.accessHelper.getPermissionsFor('employee')
		const isRestricted = userPermissions.owner.read && !userPermissions.access.read
		const myUserId = this.coreSrvc.dbSrvc.settingSrvc.getMyUserId()
		const includeIds = this.isNew ? [] : this.employee.supervisor_ids

		// Filter users if menu permissions restricted.
		const fitleredUsers = isRestricted ? this.coreSrvc.dbSrvc.settingSrvc.getManagedUsers(includeIds) : this.coreSrvc.dbSrvc.settingSrvc.getUsers()

		log('Filtered Users', fitleredUsers)
		const dropdown = fitleredUsers.map((user) => {
			return {
				label: user.first_name + ' ' + user.last_name,
				value: user.id,
			}
		})
		const sortedDropdown = _.sortBy(dropdown, 'label')
		sortedDropdown.unshift({ label: 'Select Supervisor', value: null })
		sortedDropdown.push({ label: 'Unassigned Supervisor', value: 0 })
		this.supervisorOptions = sortedDropdown

		// Autofill supervisor when only one available

		if (fitleredUsers.length === 1) {
			const currentSupId = this.empForm.get('supervisor').value
			if (!currentSupId) {
				const myUserId = this.coreSrvc.dbSrvc.settingSrvc.getMyUser()?.id ?? null
				this.empForm.get('supervisor').setValue(myUserId)
			}
			this.showSupervisors = false
		} else {
			this.showSupervisors = true
		}

		const myUser = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
		if (myUser.role === 'INTERNAL') {
			this.showSupervisors = true
		}
	}

	setupStatusDropdown() {
		this.statusOptions = this.coreSrvc.dbSrvc.empSrvc.getEmploymentStatusDropdown()
	}

	setupUserGroupDropdown() {
		this.userGroupOptions = this.coreSrvc.dbSrvc.settingSrvc.getUserGroupsDropdownData(true)
		if (this.userGroupOptions.length > 1) {
			this.showUserGroups = true
		}
	}

	setupSmsOverrideDropdown() {
		const smsDefaultNumber = this.coreSrvc.dbSrvc.settingSrvc.getCompany().callout_callerid_e164
		const formattedDefalutSms = PhoneHelper.formatPhoneFromE164(smsDefaultNumber)

		let options = this.coreSrvc.dbSrvc.settingSrvc
			.getCallInPhoneNumberRecords()
			.map((pn) => ({ label: pn.number_e164, value: pn.id, data: pn, xLabel: this.coreSrvc.dbSrvc.settingSrvc.getPhoneNumberLabel(pn) }))

		options = options.filter((opt) => opt.data.number_e164 !== smsDefaultNumber)

		for (const option of options) {
			option.label = PhoneHelper.formatPhoneFromE164(option.label) + ' / ' + option.xLabel
		}

		this.smsOverridePlaceholder = formattedDefalutSms + ' (Company Default)'

		options = _.orderBy(options, 'xLabel', ['asc'])

		this.smsOverrideOptions = options
		log('Sms Override Options', options)
	}

	formatPhoneNumber() {
		const countryCode: CountryCode = this.empForm.get('dialingCode').value
		const number = this.empForm.get('phone_number_e164').value

		if (number && number.length > 3 && isValidNumberForRegion(number, countryCode)) {
			const phoneNumber = parsePhoneNumber(number, countryCode)
			log('parsedNumber.isValid', phoneNumber.isValid())
			if (phoneNumber.isValid()) {
				this.empForm.patchValue({ phone_number_e164: phoneNumber.formatNational() })
				this.phoneNumberValid = true
			} else {
				this.phoneNumberValid = false
			}
		} else {
			this.phoneNumberValid = false
		}
	}

	onNameChange() {
		log('Value Changed')
		const firstName = this.empForm.get('first').value
		const lastName = this.empForm.get('last').value
		if (this.shouldFormatDisplayNameLastFirst) {
			this.empForm.get('name').setValue(lastName + ', ' + firstName)
		} else {
			this.empForm.get('name').setValue(firstName + ' ' + lastName)
		}
	}

	// If the form has erros, return false. Otherwise, return true
	private performSubmitValidation(showAlerts: boolean): boolean {
		const form = this.empForm.getRawValue()

		// Check for duplicate display name
		if (this.hasDuplicateDisplayName()) {
			if (showAlerts)
				this.coreSrvc.notifySrvc.notify(
					'error',
					'Duplicate Display Name',
					`The selected 'Display Name' is already in use. Please select another 'Display Name' for this employee.`,
				)
			return false
		}

		// Check for disallowed characters in name fields
		if (this.hasDisallowedCharactersInName(form)) {
			if (showAlerts)
				this.coreSrvc.notifySrvc.notify(
					'error',
					'Invalid Character',
					`The character '|' is not allowed in First Name, Last Name, or Display Name fields.`,
				)
			return false
		}

		// Check for use of reserved 888 number for employee phone
		const reservedNumberMatch = this.empForm.get('phone_number_e164').value === '(888) 888-8888'
		if (reservedNumberMatch) {
			if (showAlerts)
				this.coreSrvc.notifySrvc.notify(
					'error',
					'Invalid Phone Number',
					`The phone number 888-888-8888 is not available for assignment to an employee. Please choose another phone number.`,
				)
			return false
		}

		// Check for leading zero in employee code
		const empCode = this.empForm.get('emp_code').value
		const leadingZeroRegex = /^0\d+/
		const hasLeadingZero = leadingZeroRegex.test(empCode)

		if (hasLeadingZero) {
			if (showAlerts)
				this.coreSrvc.notifySrvc.notify('error', 'Leading Zero', `Station PIN codes cannot begin with a 0. The first digit must be 1-9.`)
			return false
		}

		// Check for tag being edited
		if (this.tagComp && !this.tagComp?.addTag()) return false

		if (this.tagComp?.selectedTag) {
			if (showAlerts)
				this.coreSrvc.notifySrvc.notify(
					'info',
					'Action Required',
					'You are curretnly modifying a tag. You must cancel or confirm your changes before you can save this record.',
				)
			return false
		}

		return true
	}

	submit(): boolean {
		// Guard against double submission
		if (this.isUpdating) return
		if (!this.performSubmitValidation(true)) return

		FormHelper.trimOnlyWhitespace(this.empForm)

		// Looks good, submit the form
		const record = this.makeUpdateRecord()
		log('Update Record', record)

		if (record) {
			this.isUpdating = true

			// Inject the merge / transfer override if it's been set
			if (this.duplicateNumber.action) record['merge_transfer'] = this.duplicateNumber.action

			if (record.status === 'RESIGNED' || (record.status === 'TERMINATED' && this.employee?.status !== record.status)) {
				this.coreSrvc.notifySrvc.notify('info', 'Employee Moved', 'Based on the selected Employee Status, this employee has become inactive.')
			}

			if (this.isNew) {
				this.coreSrvc.dbSrvc.insertRecord('employee', record).then((success) => {
					// if (success) { this.location.back() }
					if (success) {
						this.coreSrvc.dbSrvc.readTable('employee_login').then((empLoginReadSuccess) => {
							this.coreSrvc.dbSrvc.readTable('employee').then((result) => {
								this.saveActionComplete.emit(null)
							})
						})
					} else {
						this.isUpdating = false
						this.cd.detectChanges()
					}
				})
			} else {
				this.coreSrvc.dbSrvc.updateRecord('employee', record).then((success) => {
					// if (success) { this.location.back() }
					if (success) {
						this.saveActionComplete.emit(record.id)
					} else {
						this.isUpdating = false
						this.cd.detectChanges()
					}
				})
			}
		}

		return false
	}

	onCancel(): boolean {
		// this.location.back()
		log('Edit component cancel clicked')
		this.editActionCancelled.emit(true)
		return false
	}

	enterKeyUp(): boolean {
		return false
	}

	formatRateInput(prop: string) {
		const value = this.empForm.get(prop).value
		const newValue = NumberHelper.formatPayRate(value)
		this.empForm.get(prop).setValue(newValue)
	}

	formatDecimal(prop: string, decimals: number) {
		const value = this.empForm.get(prop).value
		const newValue = NumberHelper.formatDecimal(value, decimals)
		this.empForm.get(prop).setValue(newValue)
	}

	formatInteger(prop: string) {
		const value = this.empForm.get(prop).value
		const newValue = NumberHelper.formatInteger(value)
		this.empForm.get(prop).setValue(newValue)
	}

	toggleCheckbox(prop: string) {
		this.empForm.get(prop).setValue(!this.empForm.get(prop).value)
	}

	searchDepartment(event) {
		const text: string = this.empForm.get('department').value
		if (!text) {
			this.departmentOptions = [...this.departmentList]
			return
		}
		const filteredOptions = this.departmentList.filter((opt) => opt.toLowerCase().includes(text.toLowerCase()))
		this.departmentOptions = filteredOptions
	}

	hasDuplicateDisplayName(): boolean {
		const empId = this.empForm.get('id').value
		const displayName = this.empForm.get('name').value
		const emps = this.coreSrvc.dbSrvc.empSrvc.getAllEmployees()
		const matchingEmp = emps.find((rec) => rec.name === displayName)
		if (matchingEmp) {
			if (empId !== '' && matchingEmp.id !== empId) {
				return true
			}
		}
		return false
	}

	hasDisallowedCharactersInName(rec: EmployeeRecord) {
		const name = rec.name || ''
		const firstName = rec.first || ''
		const lastName = rec.last || ''
		const checkString = `${firstName}${lastName}${name}`
		if (checkString.includes('|')) {
			return true
		}
		return false
	}

	searchTaxLocation(event) {
		const text: string = this.empForm.get('home_tax_location').value
		if (!text) {
			this.taxLocationOptions = [...this.taxLocationList]
			return
		}
		const filteredOptions = this.taxLocationList.filter((opt) => opt.toLowerCase().includes(text.toLowerCase()))
		this.taxLocationOptions = filteredOptions
	}

	activeStatusChange() {}

	public pinCodeValidator() {
		const inputValue = this.empForm.get('emp_code').value
		const result = inputValue.replace(/\D/g, '').slice(0, 10)
		this.empForm.get('emp_code').setValue(result)
	}

	makeUpdateRecord(): EmployeeRecord {
		const form = this.empForm.getRawValue()
		const record = new EmployeeRecord(this.employee)
		for (const attr in form) {
			if (form.hasOwnProperty(attr)) {
				record[attr] = form[attr]
			}
		}

		// Hardcode set floater to true
		record.floater = true

		// Need to negate as we use Allow SMS on UI side
		record.disable_sms = !record.disable_sms

		record.start_date = form.start_date ? moment(form.start_date).format('YYYY-MM-DD') : null
		record.birthday = form.birthday ? moment(form.birthday).format('YYYY-MM-DD') : null
		record.anniversary = form.anniversary ? moment(form.anniversary).format('YYYY-MM-DD') : null

		const countryCode: CountryCode = this.empForm.get('dialingCode').value
		const parsedNumber = parseNumber(record.phone_number_e164, countryCode) as ParsedNumber
		if (parsedNumber.country && isValidNumber(parsedNumber)) {
			record.phone_number_e164 = format(parsedNumber, 'E.164')
		} else {
			log(record)
			alert('Phone number does not appear to be valid. Please make sure you selected the proper dialing prefix and check the number.')
			return null
		}

		// Update address info if available
		log('Updating Address Record')
		const isAddressValid = this.formAddressManager.prepareForSubmission(record)
		if (!isAddressValid) {
			return null
		}

		// Clean up fields used only on client side
		delete record.phone_number
		delete record['dialingCode']

		// If setting active, null out status if they were previously resigned or terminated
		if (record.active && !this.employee?.active) {
			if (this.employee?.status === 'RESIGNED' || this.employee?.status === 'TERMINATED') {
				record.status = null
			}
		}

		record.employment_status_notes = this.empForm.get('employment_status_notes').value

		return record
	}

	updateDynamicTextareas() {
		DisplayHelper.updateDynamicTextAreas(500)
	}

	public textResizer(elm: HTMLElement) {
		elm.style.height = 'auto'
		elm.style.height = elm.scrollHeight + 'px'
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)

		switch (trigger) {
			case 'auto_check_in_out':
				help.header = 'Auto Clock In/Out'
				help.message =
					'When selected, this option will automatically clock an employee in/out based on their schedule. Do <b>NOT</b> select this option for standard employees or their time will not be properly tracked.'
				break
			case 'commute_distance':
				help.header = 'Commute Range'
				help.message = `Enter the maximum distance an employee is willing to commute for a shift.`
				break
			case 'department':
				help.header = 'Department'
				help.message = 'Optional field used to specify the organizational department for this employee.'
				break
			case 'details':
				help.header = 'Employee Details'
				help.message = `Enter extra detail information for this employee that may be relevant.`
				break
			case 'displayName':
				help.header = 'Display Name'
				help.message =
					'The Display Name is used to identify this employee throughout the site and may be overridden. You may wish to change the Display Name if you have multiple employees with the same first and last names.'
				break
			case 'emp_code':
				help.header = 'Station PIN'
				help.message =
					'Unique employee personal identification number (PIN) for use with timeclock stations which have been setup to use employee PIN codes. Maximum of 10 digits.'
				break
			case 'active':
				help.header = 'Active Status'
				help.message =
					'Active employees are visible throughout the system. Inactive employees are hidden from certain lists and dropdown menus when it is not necessary to show them.'
				break
			case 'empAppLink':
				help.header = 'Employee App Link'
				help.message = `Click the green button to copy an authentication link to your clipboard which you can send to this employee. This link will allow them to automatically sign into the employee web portal without having to use the normal registration process. `
				break
			case 'externalID':
				help.header = 'External ID'
				help.message =
					'Optional field used to link this employee with an external identifier such as an employee or payroll ID. Must be unique for each employee.'
				break
			case 'empSupervisor':
				help.header = 'Employee Supervisor'
				help.message = 'From the dropdown menu, please select the supervisor who is responsible for this employee.'
				break
			case 'gpsRequests':
				help.header = 'GPS Requests'
				help.message =
					'When selected, this option instructs the system to request GPS information from the employee when they clock in/out using the phone based IVR system.<br><br>GPS sharing requests are only sent when the employee calls from their mobile phone and are <b>NOT</b> sent when they call from a land line phone number.'
				break
			case 'inboundDispatch':
				help.header = 'Inbound Dispatch'
				help.message =
					'This is the mobile or land line phone that will ring for inbound calls if the employee is clocked in to the special ‘dispatch’ job.  If no number is specified, the employee’s mobile phone will be used.'
				break
			case 'jobFloating':
				help.header = 'Job Floating'
				help.message =
					'Enable this option if the employee performs multiple jobs during the day and you wish to keep them on the clock during transit between jobs.'
				break
			case 'language':
				help.header = 'Language'
				help.message = `Choose the language the system will use when communicating with this employee. This includes phone calls, text messages, emails, and other notifications.`
				break
			case 'adpPayRate':
				help.header = 'Base Pay Rate'
				help.message = `This is the pay rate which will be used when calculating pay for time entries when no job-specific pay rates for this employee are provided. This value is managed through your ADP account.`
				break
			case 'payRate':
				help.header = 'Base Pay Rate'
				help.message = `This is the pay rate which will be used when calculating pay for time entries when no job-specific pay rates for this employee are provided.`
				break
			case 'phone_sms_id':
				help.header = 'Send SMS From'
				help.message =
					'This option allows you to choose which of your provisioned numbers will be used to send SMS messages (text messages) to this employee. Clear the field to use your company default.'
				break
			case 'phoneNumber':
				help.header = 'Phone Number'
				help.message = 'From the dropdown menu, please select the appropriate country code for the phone number you wish to use.'
				break
			case 'status':
				help.header = 'Employment Status'
				help.message = 'If an employee has resigned or been terminated, you can set that here.'
				break
			case 'employmnet_status_notes':
				help.header = 'Status Notes'
				help.message = 'Enter any additional notes about the employee status here.'
				break
			case 'userGroup':
				help.header = 'Supervisor Group'
				help.message =
					'Optionally assign a supervisor group which will allow members of this group to manage this employee and view related records.'
				break
			case 'voicePrint':
				help.header = 'Voice Fingerprints'
				help.message =
					'When selected, this option will require the employee to say their name during clock in when using the call-in phone system.'
				break

			case 'round_up_timer_disable':
				help.header = 'Auto Adjustments'
				help.message = `When selected, time entries for this employeewill not be automatically adjusted to match the scheduled shift start time.`
				break

			case 'round_down_timer_disable':
				help.header = 'Auto Adjustments'
				help.message = `When selected, time entries for this employee will not be automatically adjusted to match the scheduled shift end time.`
				break

			case 'travel':
				help.header = 'Travel Tracking'
				help.message = `When selected, this option will expose a button in the employee web app for tracking travel.`
				break
			case 'max_hrs_daily':
				help.header = 'Max Hours Daily'
				help.message = 'The maximum number of hours an employee may work per day.'
				break
			case 'max_shifts_daily':
				help.header = 'Max Shifts Daily'
				help.message = 'The maximum number of shifts an employee may work per day.'
				break
			case 'max_hrs_weekly':
				help.header = 'Max Hours Weekly'
				help.message = 'The maximum number of hours an employee may work per week.'
				break
			case 'max_days_weekly':
				help.header = 'Max Days Weekly'
				help.message = 'The maximum number of days an employee may work per week.'
				break

			default:
				help.header = 'Topic Unavailable'
				help.message = 'No help information for this topic is currently available.'
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
