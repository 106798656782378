<app-activity-banner
	[isProcessing]="isLoading"
	[messageBlockClass]="'ab-message-block tts-hl-gray'"
	[message]="'Loading Records ...'"
></app-activity-banner>

<div [hidden]="isLoading || isEditing">
	<div class="alert alert-info alert-bg-gray" style="margin-bottom: 30px">
		Skills enable the phone system to optimally route incoming calls to the best-equipped call center agent. For assistance with this feature,
		please contact <b><a class="link-text" href="mailto:support@telephonetimesheets.com">support</a></b
		>.
		<!-- <br /><br />After creating a skill and linking it to incoming numbers and agents, you can manage the individual agent settings below. -->
	</div>

	<div *ngIf="skills.length === 0" class="no-records">No Skills Setup</div>

	<div *ngFor="let card of skills" class="card">
		<div>
			<div class="card-title-wrap">
				<div class="link-text float-right" style="margin-left: 12px">
					<i
						title="Edit"
						(click)="editRecord(card)"
						class="fa fa-pencil-square-o action-icon action-icon-edit has-pointer"
						aria-hidden="true"
					></i>
					<!-- <i title="Clone" (click)="cloneRecord(vm)" class="fa fa-files-o action-icon action-icon-clone has-pointer" aria-hidden="true"></i> -->
					<i
						title="Delete"
						(click)="removeSkill(card)"
						class="fa fa-trash-o action-icon action-icon-delete has-pointer"
						[class.skill-locked]="card.skill.locked"
					></i>
				</div>
				<div class="card-title tts-hl-2" [class.card-error]="card.skill.phone_number_skill.length === 0 || card.skill.users_skill.length === 0">
					{{ card.skill.name }}
				</div>
				<div *ngIf="card.skill.details" class="card-description">{{ card.skill.details }}</div>
			</div>

			<!-- Linked Numbers List -->
			<div *ngIf="card.showDeleteConfirmation === false">
				<hr style="margin: 10px 0px 15px 0px" />

				<div>
					<app-form-expander
						[style]="{ 'margin-top': '0px' }"
						[label]="'Inbound Numbers'"
						[labelStyle]="{ 'font-size': '0.8em', 'color': 'gray' }"
						[counter]="card.skill.phone_number_skill.length"
						[minCounterWidth]="'38px'"
						[(isExpanded)]="card.expander.showLinkedNumbers"
					>
						<div *ngFor="let pnw of card.phoneNumbers">
							<div class="card item-card" [class.item-linked]="card.isPhoneNumberLinked(pnw.pn)">
								<div>
									<div>
										<div *ngIf="pnw.link" class="link-text float-right" (click)="removeNumber(card, pnw.pn)">unlink</div>
										<div *ngIf="!pnw.link" class="link-text float-right" (click)="addNumber(card, pnw.pn)">link</div>
										<div class="link-title">{{ formatPhoneNumberLabel(pnw.pn.id) }}</div>
									</div>
									<div>
										{{ formatPhoneNumberForId(pnw.pn.id) }}
									</div>
								</div>
							</div>
						</div>
					</app-form-expander>
				</div>

				<div>
					<app-form-expander
						[style]="{ 'margin-top': '0px' }"
						[label]="'Call Center Agents'"
						[labelStyle]="{ 'font-size': '0.8em', 'color': 'gray' }"
						[counter]="card.skill.users_skill.length"
						[minCounterWidth]="'38px'"
						[(isExpanded)]="card.expander.showLinkedAgents"
					>
						<div *ngFor="let uw of card.users; let idx = index">
							<div class="card item-card" [class.item-linked]="card.isUserLinked(uw.user)">
								<div>
									<div>
										<div *ngIf="uw.link" class="link-text float-right" (click)="removeAgent(card, uw.user)">unlink</div>
										<div *ngIf="!uw.link" class="link-text float-right" (click)="addAgent(card, uw.user)">link</div>
										<div class="link-title">
											{{ formatUserNameForId(uw.user.id) }}
										</div>
									</div>
									<div *ngIf="uw.link">
										<div class="link-block">
											<select
												class="form-select form-select-sm float-right"
												style="width: 80px"
												[(ngModel)]="uw.link.priority"
												(change)="setPriority(uw.link)"
											>
												<option *ngFor="let priority of priorityOptions" [ngValue]="priority.value">{{ priority.label }}</option>
											</select>
											<div class="link-label">
												Priority<i class="far fa-info-circle tts-info-btn" aria-hidden="true" (click)="showHelp('priority')"></i>
											</div>
										</div>
										<div class="link-block">
											<select
												class="form-select form-select-sm float-right"
												style="width: 80px"
												[(ngModel)]="uw.link.always_route"
												(change)="setAlwaysRoute(uw.link)"
											>
												<option *ngFor="let aro of alwaysRouteOptions" [ngValue]="aro.value">{{ aro.label }}</option>
											</select>
											<div class="link-label">
												Always Route<i class="far fa-info-circle tts-info-btn" aria-hidden="true" (click)="showHelp('always_route')"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</app-form-expander>
				</div>

				<hr *ngIf="card.skill.phone_number_skill.length === 0 || card.skill.users_skill.length === 0" style="margin: 15px 0px 15px 0px" />
				<div *ngIf="card.skill.phone_number_skill.length === 0">
					<div class="no-numbers" style="color: firebrick">No inbound numbers linked</div>
				</div>
				<div *ngIf="card.skill.users_skill.length === 0">
					<div class="no-numbers" style="color: firebrick">No call center agents linked</div>
				</div>
			</div>

			<div *ngIf="card.showDeleteConfirmation">
				<hr />
				<div>
					<div class="float-right" style="padding-left: 25px">
						<button
							class="btn btn-sm btn-outline-secondary"
							style="width: 60px; margin-right: 12px"
							(click)="card.showDeleteConfirmation = false"
						>
							No
						</button>
						<button class="btn btn-sm btn-outline-danger" style="width: 60px" (click)="deleteRecord(card)">Yes</button>
					</div>
					<div class="delete-confirm-label">Are you sure you want to delete this skill?</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="isEditing">
	<app-call-center-manage-skill-edit
		[dialogManager]="dialogManager"
		[recordId]="editAction.recordId"
		(endEditing)="endEditing()"
	></app-call-center-manage-skill-edit>
</div>
