<div *ngIf="vm.currentView === 'MAIN'" class="section-container">
	<div>
		<!-- Show the logged in status  -->
		<div *ngIf="!isLoggedIn" class="alert alert-danger header-alert">
			<div class="status-note">
				<div style="margin: auto; width: 200px">You are not signed in to receive calls</div>
			</div>
			<button
				*ngIf="!isLoggedIn"
				[disabled]="!isCallCenterEnabled || !dispatchPhoneManager.isFormValid"
				class="btn btn-sm btn-block btn-success btn-pad"
				(click)="signInAsDispatcherBtnClicked()"
			>
				<span *ngIf="!isWorking.signIn">Agent Sign-In</span>
				<span *ngIf="isWorking.signIn" class="fas fa-loader fa-spin"></span>
			</button>
		</div>

		<div *ngIf="isLoggedIn" class="alert alert-success header-alert">
			<div class="status-note">
				<div>
					<span>You are signed in to receive calls at</span>
					<span [class.hl-chocolate]="isNumberOverridden" style="white-space: nowrap"> {{ agentNumber }}</span>
				</div>
			</div>

			<button *ngIf="isLoggedIn" class="btn btn-sm btn-block btn-danger btn-pad" (click)="signOutAsDispatcherBtnClicked()">
				<span *ngIf="!isWorking.signOut">Agent Sign-Out</span>
				<span *ngIf="isWorking.signOut" class="fas fa-loader fa-spin"></span>
			</button>
		</div>

		<div class="row" style="margin-top: 18px">
			<div class="col-6">
				<button [disabled]="!isCallCenterEnabled" class="btn btn-block btn-outline-secondary" (click)="makeOutgoingCall()">Dial Number</button>
			</div>
			<div class="col-6">
				<button class="btn btn-block btn-outline-secondary" (click)="switchToAddressBook()">Contacts</button>
			</div>
		</div>
	</div>

	<div *ngIf="isCallCenterEnabled">
		<div class="options-block signed-in-copy">
			<i class="far fa-gear float-right update-icon link-text" (click)="switchToUpdateNumber()"></i>
			<div>
				<div class="active-timestamp">
					Agent: {{ agentName }} &#64; <span [class.hl-chocolate]="isNumberOverridden">{{ agentNumber }}</span>
				</div>
				<div *ngIf="isLoggedIn" class="active-timestamp">Signed in: {{ activeStatusDateTime | dateFormatter: signedInFormat }}</div>
			</div>
		</div>
	</div>

	<!-- Call Center Not Enabled Note -->
	<div *ngIf="!isCallCenterEnabled && !isLoggedIn" class="alert alert-warning" style="margin-top: 35px">
		Telephone Timesheets can provide a dedicated toll-free number for calls from your clients, vendors, employees, etc. Incoming calls can be
		recorded and transcribed for compliance or quality assurance. Please <a href="mailto:support@telephonetimesheets.com">email us</a> if you would
		like to enable <strong>Inbound Call Center</strong> functionality. This is a free add-on.
	</div>
</div>

<div *ngIf="vm.currentView === 'UPDATENUMBER'" class="section-container">
	<div class="alert alert-info alert-bg-gray" style="margin-bottom: 40px; font-weight: 500">
		This is the phone number where calls from the system will be directed, while you are signed in as a call center agent.
		<span *ngIf="isNumberOverridden">
			If you clear the override, the system will resume directing calls to your mobile number at
			<span style="color: chocolate">{{ dispatchPhoneManager.placeholder | displayPhone }}</span
			>.
		</span>
	</div>

	<!-- Phone number for MODIFICATION -->
	<app-form-phone-number [manager]="dispatchPhoneManager" (showHelp)="showHelp($event)"></app-form-phone-number>

	<!-- Call Center DISABLED Update Phone Number Button - Submits phone number change -->
	<button
		[disabled]="!dispatchPhoneManager.isFormValid"
		class="btn btn-block btn-success btn-pad"
		style="margin-top: 20px"
		(click)="updatePhoneNumberBtnClicked()"
	>
		<span *ngIf="!isWorking.updateNumber">Update Agent Number</span>
		<span *ngIf="isWorking.updateNumber" class="fas fa-loader fa-spin"></span>
	</button>
	<button [disabled]="!isNumberOverridden" class="btn btn-block btn-primary" style="margin-top: 8px" (click)="clearNumberOverride()">
		Clear Override
	</button>
	<!-- <button class="btn btn-block btn-outline-secondary btn-pad" style="margin-top: 24px" (click)="returnFromOverrideNumber()">
		Back to Call Center
	</button> -->
</div>

<div *ngIf="vm.currentView === 'ADDRESSBOOK'" class="section-container">
	<app-click-to-call-address-book (callOrTextHasBeenPlaced)="returnFromAddressBook()"></app-click-to-call-address-book>
</div>

<div *ngIf="vm.currentView === 'PLACECALL'" class="section-container">
	<app-activity-banner [isProcessing]="isWorking.sendText" [message]="'Sending Message ...'"></app-activity-banner>
	<app-activity-banner [isProcessing]="isWorking.placeCall" [message]="'Placing Call ...'"></app-activity-banner>

	<div [hidden]="isWorking.placeCall || isWorking.sendText">
		<div class="options-block">
			<div class="options-title-inline">Name</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('placeCallName')"></i>

			<input class="form-control" [(ngModel)]="placeCallName" />
		</div>
		<app-form-phone-number [manager]="placeCallPhoneManager" (showHelp)="showHelp($event)"></app-form-phone-number>
		<div class="options-block">
			<div class="options-title-inline">Message</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('placeCallMessage')"></i>
			<div class="float-right ctc-msg-count" [class.ctc-msg-exceeded]="maxTextLengthExceeded">
				{{ placeCallMessage.length }} / {{ smsCharLimit }}
			</div>
			<textarea
				class="options-textarea"
				[(ngModel)]="placeCallMessage"
				[maxlength]="smsCharLimit"
				placeholder="Enter message here if sending a text message"
			></textarea>
		</div>

		<div class="row">
			<div class="col-6">
				<button
					[disabled]="!placeCallPhoneManager.isNumberValid || placeCallMessage.length === 0"
					class="btn btn-block btn-success btn-pad"
					(click)="sendTextMsgBtnClicked()"
					style="margin-bottom: 12px"
				>
					<span *ngIf="!isWorking.sendText">Send Text</span>
					<span *ngIf="isWorking.sendText" class="fas fa-loader fa-spin"></span>
				</button>
			</div>
			<div class="col-6">
				<button
					[disabled]="!placeCallPhoneManager.isNumberValid"
					class="btn btn-block btn-success btn-pad"
					(click)="placeCallBtnClicked()"
					style="margin-bottom: 12px"
				>
					<span *ngIf="!isWorking.placeCall">Place Call</span>
					<span *ngIf="isWorking.placeCall" class="fas fa-loader fa-spin"></span>
				</button>
			</div>
		</div>
	</div>
</div>

<div [hidden]="vm.currentView !== 'MAIN'">
	<app-dispatch-active-call-manager></app-dispatch-active-call-manager>
</div>
