import { DateTimeHelper } from '@app/helpers/datetime'
import moment from 'moment-timezone'
import { OpenShiftOfferRecord } from './open-shift'

const exampeScheuldeLog = {
	job_end: '2020-04-27T22:00:00',
	jobsite_description: 'Job Site 1 Sup A',
	emp_supervisor_id: 1791,
	schedule_id: null,
	transaction_id: null,
	job_id: 12173,
	jobsite_supervisor_id: 1791,
	anytime: false,
	employee_name: 'Employee 1 Sup A',
	jobsite_id: 10567,
	emp_supervisor_name: 'Managed Supervisor A',
	employee_id: 21604,
	recurring_freq: 'WEEKLY',
	job_description: 'Job 1 Site 1 Sup A',
	id: 1557404,
	company_id: 1433,
	jobsite_supervisor_name: 'Managed Supervisor A',
	job_date: '2020-04-27',
	enabled: true,
	timezone: 'America/Chicago',
	recurrence_end: null,
	recurrence: 'FREQ=WEEKLY;INTERVAL=1;BYDAY=MO;WKST=MO',
	job_start: '2020-04-27T14:00:00',
	comments: null,
	employee_first: 'Employee 1',
	created: '2020-04-25T15:31:19.723491',
	employee_last: 'Sup A',
}

export class ScheduleLogRecord {
	anytime: boolean
	comments: string
	company_id: number
	created: string // Date
	emp_supervisor_id: number
	emp_supervisor_name: string
	employee_count: number
	employee_count_override: number
	employee_first: string
	employee_first_override: string
	employee_id: number
	employee_id_override: number
	employee_last: string
	employee_last_override: string
	employee_name: string
	employee_name_override: string
	enabled: boolean
	enable_notifications: boolean
	enabled_override: boolean
	end_time: string
	end_time_override: string
	id: number
	job_date: string // Date
	job_description: string
	job_end: string // Date
	job_id: number
	job_start: string // Date
	jobsite_description: string
	jobsite_id: number
	jobsite_supervisor_id: number
	jobsite_supervisor_name: string
	open_shift: boolean = false
	recurring_freq: 'YEARLY' | 'MONTHLY' | 'WEEKLY' | 'DAILY'
	recurrence: string
	recurrence_end: string // Date
	schedule_id: number
	start_time: string
	start_time_override: string
	timezone: string
	transaction_id: number
	updated: string
	schedule_os_offer: Array<OpenShiftOfferRecord> = [] // Computed property / metadata

	constructor(record?: ScheduleLogRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(record.created)
			this.updated = DateTimeHelper.stripUtcTag(record.updated)
			this.job_date = DateTimeHelper.stripUtcTag(record.job_date)
			this.job_start = DateTimeHelper.stripUtcTag(record.job_start)
			this.job_end = DateTimeHelper.stripUtcTag(record.job_end)
			this.recurrence_end = DateTimeHelper.stripUtcTag(record.recurrence_end)
		}
	}

	makeDate(dateStr: string): Date {
		const date = DateTimeHelper.stripUtcTag(dateStr)
		const result = moment(date, 'UTC')
		return result.isValid() ? result.utc().toDate() : null
	}

	clone() {
		return new ScheduleLogRecord(this)
	}

	effectiveEmpId(): number {
		return this.employee_id_override || this.employee_id_override === 0 ? this.employee_id_override : this.employee_id
	}

	effectiveEmpFirst(): string {
		return this.employee_first_override ? this.employee_first_override : this.employee_first
	}

	effectiveEmpLast(): string {
		return this.employee_last_override ? this.employee_last_override : this.employee_last
	}

	effectiveEmpName(): string {
		return this.employee_name_override ? this.employee_name_override : this.employee_name
	}

	effectiveEnabled(): boolean {
		return this.enabled_override !== null ? this.enabled_override : this.enabled
	}

	effectiveStartTime(): string {
		return this.start_time_override ? this.start_time_override : this.start_time
	}

	effectiveEndTime(): string {
		return this.end_time_override ? this.end_time_override : this.end_time
	}

	effectiveEmpCount(): number {
		return this.employee_count_override ? this.employee_count_override : this.employee_count
	}

	isShiftOver(): boolean {
		return moment(this.job_end).isBefore(moment(), 'seconds')
	}
}
