import { EmployeeRecord, JobRecord, JobSiteRecord, TransactionLogRecord } from '@app/models'
import { Global } from '@app/models/global'
import { log } from './logger'

export class DataTablesHelper {
	static fixedHeader = { headerOffset: 56 }
}
export class CacheHelper {
	static empCache = {}
	static siteCache = {}
	static jobCache = {}
	static schedCache = {}
	static transCache = {}

	static updateCache() {
		log('CacheHelper Loading')
		const dbSrvc = Global.coreSrvc.dbSrvc
		CacheHelper.empCache = dbSrvc.empSrvc.getCache()
		CacheHelper.siteCache = dbSrvc.siteSrvc.getCache()
		CacheHelper.jobCache = dbSrvc.jobSrvc.getCache()
		CacheHelper.schedCache = dbSrvc.schedulerSrvc.getCache()
		CacheHelper.transCache = dbSrvc.tranSrvc.getCache()
	}

	static getEmpById(id: number): EmployeeRecord {
		return this.empCache[id]
	}

	static getSiteById(id: number): JobSiteRecord {
		return this.siteCache[id]
	}

	static getJobById(id: number): JobRecord {
		return this.jobCache[id]
	}

	static getSchedById(id: number): JobRecord {
		return this.schedCache[id]
	}

	static getTransById(id: number): TransactionLogRecord {
		return this.transCache[id]
	}
}
