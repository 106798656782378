import { DatabaseService } from '@app/services'
import { ColorVendor, EmployeeRecord, GenericEmailListManager, JobRecord, JobSiteRecord, RecordTagContainer } from '@app/models'
import { log, DateTimeHelper } from '@app/helpers'

import { DateSelectArg, EventApi } from '@fullcalendar/core'
import { Global } from './global'

import { RRule, RRuleSet, rrulestr, Weekday } from 'rrule'

import _ from 'lodash'
import moment from 'moment-timezone'

export type ScheduleStatusType = 'ALL' | 'BATCHED' | 'TAGFILTER' | 'EMPCOUNT' | 'INPROGRESS' | 'TODAY' | 'EXPIRED' | 'INACTIVEJOB' | 'ENABLED'
export type NewScheduleType = 'SINGLE' | 'MULTI'
export type PendingScheduleTakeAction = 'APPROVE' | 'REJECT' | 'UPDATE'

export class PendingScheduleApprovalAction {
	recordId: number = null
	title = ''
	notes = ''
	takeAction: PendingScheduleTakeAction = null
	showConfirmation = false

	isDialogVisible = false
	isProcessing = false

	approveWithWorkorder = false
	approveWithWorkorderEmailListManager: GenericEmailListManager = null
	vendorName = ''

	showOvertimeOverrideCheckbox = false
	confirmOvertimeOverride = false

	showTimeOffOverrideCheckbox = false
	confirmTimeOffOverride = false
}

export class ScheduleStatusFilter {
	available = false

	active = false
	showInProgress = true
	showScheduledForToday = true
	showExpired = true
	showInactiveJob = true
	showEmpCount = true
	showBatchSelection = true

	current: ScheduleStatusType = 'ALL'

	get schedFilterActive(): boolean {
		return this.active
	}

	setFilterStatus(status: ScheduleStatusType) {
		if (status === 'ALL') {
			this.showAll()
			this.current = 'ALL'
		}
		if (status === 'INPROGRESS') {
			this.hideAll()
			this.showInProgress = true
			this.current = 'INPROGRESS'
		}
		if (status === 'TODAY') {
			this.hideAll()
			this.showScheduledForToday = true
			this.current = 'TODAY'
		}
		if (status === 'INACTIVEJOB') {
			this.hideAll()
			this.showInactiveJob = true
			this.current = 'INACTIVEJOB'
		}
		if (status === 'EMPCOUNT') {
			this.hideAll()
			this.showEmpCount = true
			this.current = 'EMPCOUNT'
		}
		if (status === 'BATCHED') {
			this.hideAll()
			this.showBatchSelection = true
			this.current = 'BATCHED'
		}
	}
	private hideAll() {
		this.active = true
		this.showInProgress = false
		this.showScheduledForToday = false
		this.showInactiveJob = false
		this.showEmpCount = false
		this.showBatchSelection = false
	}
	private showAll() {
		this.active = false
		this.showInProgress = true
		this.showScheduledForToday = true
		this.showExpired = Global.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.series.showExpiredSchedules
		this.showInactiveJob = true
		this.showEmpCount = true
		this.showBatchSelection = true
	}
}

export class ScheduleLogOverride {}

export interface ISchedule {
	id: number
	employee_id: number
	job_id: number
	company_id: number
	description: string
	enabled: boolean
	monday: boolean
	tuesday: boolean
	wednesday: boolean
	thursday: boolean
	friday: boolean
	saturday: boolean
	sunday: boolean
	day_additional: string
	created: string
	updated: string
}

export class Schedule implements ISchedule {
	id: number = null
	employee_id: number = null
	job_id: number = null
	company_id: number = null
	description: string = null
	enabled: boolean = null
	monday: boolean = null
	tuesday: boolean = null
	wednesday: boolean = null
	thursday: boolean = null
	friday: boolean = null
	saturday: boolean = null
	sunday: boolean = null
	day_additional: string = null
	created: string = null
	updated: string = null

	constructor(record?: ISchedule) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
		}
	}
}

export interface IScheduleEntryShiftAssignment {
	employee_id: number
	days_of_week: Array<number>
}

export type ScheduleApprovalState = 'DIRECT' | 'PENDING' | 'APPROVED' | 'REJECTED'

// ScheduleEntry is a schedule_recur record

export class ScheduleEntry {
	id: number = null
	parent_schedule_id: number = null
	company_id: number = null
	employee_id: number = null
	employee_ids: Array<number> = []
	emp_ids_days_of_week: any // Really it's an Array<IScheduleEntryShiftAssignment> and you send in JSON stringified version of same
	job_id: number = null
	created: string = null
	updated: string = null
	enabled: boolean = null
	start_date: string = null
	recurrence: string = null
	recurrence_freq: string = null
	recurrence_end: string = null
	comments: string = null
	legacy_entry: number = null
	error_msg: string = null
	notification_email: string = null
	schedule_one_time = false

	description: string = null
	start_time: string = null
	end_time: string = null
	employee_count: number = null
	break_time: string = null
	break_time_worked: string = null
	overage_time: string = null

	holiday = false
	assigned_color = null
	schedule_log_overrides: Array<ScheduleLogOverride> = []

	approval_state: ScheduleApprovalState = 'DIRECT'
	approval_state_last_updated: string = null // Date
	approval_state_last_user_id: number = null

	approve_with_workorder = false
	workorder_email: string = null

	tags_json: string = null // '{"tags":[{"label":"test","expire":"2024-08-10T05:00:00.000Z","ts":"2024-08-11T10:30:07.529Z"}]}'
	xtagContainer: RecordTagContainer = null

	constructor(record?: ScheduleEntry) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			if (!this.employee_ids) {
				this.employee_ids = []
			} // Make into empty array if null
			this.created = DateTimeHelper.stripUtcTag(record.created)
			this.updated = DateTimeHelper.stripUtcTag(record.updated)
			this.approval_state_last_updated = DateTimeHelper.stripUtcTag(record.approval_state_last_updated)
			this.normalizeTimes()
			this.timeAdjustRuleString()
			this.parseEmpIdsDaysOfWeek()

			this.xtagContainer = new RecordTagContainer(this.tags_json)

			// Report if a schedule was found which does not have a recurrence rule
			if (!this.recurrence) {
				Global.reportGlobalErrorOneTime(
					'MISSINGRECURRENCE',
					'error',
					'Record Error',
					`A schedule was found to be missing it's recurrence rule. Please report this error to customer support.`,
				)
				log('SCHEDULE MISSING RECURRENCE RULE:', this.id)
			}
		}
	}

	clone(): ScheduleEntry {
		const clone = new ScheduleEntry()
		const copy = JSON.parse(JSON.stringify(this))
		for (const attr in clone) {
			if (clone.hasOwnProperty(attr)) {
				;(clone as any)[attr] = copy[attr]
			}
		}
		return clone
	}

	parseEmpIdsDaysOfWeek() {
		// Setup assignment for legacy format
		try {
			const isWeekly = this.getFrequency() === RRule.WEEKLY
			if (this.employee_id === 1 && !this.emp_ids_days_of_week && isWeekly) {
				const result = this.employee_ids.map((empId) => ({
					employee_id: empId,
					days_of_week: [1, 2, 3, 4, 5, 6, 7],
				}))
				this.emp_ids_days_of_week = result
				return
			}
		} catch (error) {}

		// Setup assignment for new multi-employee format
		const daysOfWeekInput: Array<IScheduleEntryShiftAssignment> = this.emp_ids_days_of_week || []
		const tweakedInput = daysOfWeekInput.map((dow) => ({
			employee_id: dow.employee_id,
			days_of_week: dow.days_of_week || [1, 2, 3, 4, 5, 6, 7],
		}))
		this.emp_ids_days_of_week = tweakedInput
	}

	buildEmpIdsDaysOfWeekForUpdate(): string {
		const isWeeklySchedule = this.recurrence_freq === 'WEEKLY'
		const isMultiEmployeeSchedule = this.employee_id === 1
		const assignments = this.getShiftAssignments() || []
		const assignmentManager = new ScheduleAssignmentManager()
		assignmentManager.setupManager(assignments)

		if (isMultiEmployeeSchedule) {
			if (isWeeklySchedule) {
				return assignmentManager.getUpdateString()
			} else {
				const empIdsDaysOfWeek = this.employee_ids.map((id) => ({ employee_id: id, days_of_week: null }))
				return JSON.stringify(empIdsDaysOfWeek)
			}
		} else {
			return null
		}
	}

	getShiftAssignments(): Array<IScheduleEntryShiftAssignment> {
		return this.emp_ids_days_of_week
	}

	getStartDateString(): string {
		return moment(this.start_date).format('YYYY-MM-DD')
	}

	makeDate(dateStr: string): Date {
		if (!dateStr) {
			return null
		}
		return moment(dateStr).toDate()
	}

	normalizeTimes() {
		this.start_time = DateTimeHelper.normalizeTime(this.start_time)
		this.end_time = DateTimeHelper.normalizeTime(this.end_time)
	}

	adjustRuleString() {
		const recurrence = this.recurrence
		if (recurrence && !recurrence.includes('RRULE:')) {
			this.recurrence = 'RRULE:' + recurrence
		}
	}

	timeAdjustRuleString() {
		let ruleStr = this.recurrence
		if (!ruleStr) return null

		if (ruleStr.includes('EXDATE')) {
			log('Found an EXDATE')
		}

		if (!ruleStr.includes('RRULE:')) {
			ruleStr = 'RRULE:' + ruleStr
		}

		const lineComps = ruleStr.split('\n')
		const newLineComps = []
		for (const lineComp of lineComps) {
			if (!lineComp.includes('UNTIL')) {
				newLineComps.push(lineComp)
			} else {
				const comps = lineComp.split(';')
				// log('COMPS', comps)
				const newComps = []
				for (const comp of comps) {
					if (!comp.includes('UNTIL')) {
						newComps.push(comp)
					} else {
						const untilComps = comp.split('=')
						const date = untilComps[1]
						if (date) {
							const newUntilDate = date.split('T')
							const newUntilString = 'UNTIL=' + newUntilDate[0] + 'T235959'
							newComps.push(newUntilString)
						}
					}
				}
				const result = newComps.join(';')
				newLineComps.push(result)
			}
		}
		const finalResult = newLineComps.join('\n')
		// log('Rule Modified', finalResult)

		this.recurrence = finalResult
		// return result
	}

	getScheduledDays() {
		const ruleString = this.recurrence
		const ruleSet = rrulestr(ruleString, { forceset: true }) as RRuleSet
		const rule = ruleSet.rrules()[0]
		return rule.options.byweekday || []
	}

	getFrequency() {
		const ruleString = this.recurrence
		const ruleSet = rrulestr(ruleString, { forceset: true }) as RRuleSet
		const rule = ruleSet.rrules()[0]
		return rule.options.freq
	}

	getRule(): RRule {
		const ruleString = this.recurrence
		const ruleSet = rrulestr(ruleString, { forceset: true }) as RRuleSet
		const rule = ruleSet.rrules()[0]
		return rule
	}

	// Used to display Scheduled Weekly / Monthly / Yearly or Scheduled on YYYY-MM-DD for one time schedules
	getScheduledOnString(): string {
		const freq = this.recurrence_freq
		if (this.schedule_one_time) {
			return `${this.start_date}`
		}
		switch (freq) {
			case 'WEEKLY':
				return 'Weekly'
			case 'MONTHLY':
				return 'Monthly'
			case 'YEARLY':
				return 'Yearly'
		}
		return ''
	}
}

export type ScheduleListItemType = 'EMP' | 'JOB'
export interface ListItemEntry {
	empName: string
	jobName: string
	rule: RRule
	entry: ScheduleEntry
}
export interface ScheduleListTimeFormattable {
	startTime: string
	endTime: string
	isAnytime: boolean
}
export interface ScheduleListRuleFormattable {
	rule: RRule
}
export interface ScheduleListItem {
	type: string
	entries: Array<ScheduleListItemEntry>
}

export class ScheduleEmpListItem {
	type: ScheduleListItemType = 'EMP'
	empId: number
	firstName: string
	lastName: string
	entries: Array<ScheduleListItemEntry> = []

	get visible(): boolean {
		for (const entry of this.entries) {
			if (entry.visible) {
				return true
			}
		}
		return false
	}

	constructor() {}

	sortEntries() {
		const timeSorted = _.sortBy(this.entries, 'sortTime')
		this.entries = _.sortBy(timeSorted, 'jobName')
	}

	jobNames(): Array<string> {
		return this.entries.map((entry) => entry.jobName)
	}

	filter(text: string, useExactMatch: boolean) {
		for (const entry of this.entries) {
			entry.filter(text, useExactMatch)
		}
	}
	filterForDayViewDate(date: Date) {
		for (const entry of this.entries) {
			entry.filterForDayViewDate(date)
		}
	}
	filterForScheduleStatus(filter: ScheduleStatusFilter) {
		for (const entry of this.entries) {
			entry.filterForScheduleStatus(filter)
		}
	}
	filterForSubSection(subSection: 'CURRENT' | 'PENDING') {
		for (const entry of this.entries) {
			entry.filterForSubSection(subSection)
		}
	}
}

export class ScheduleJobListItem {
	type: ScheduleListItemType = 'JOB'
	elemId: string
	job: JobRecord
	jobId: number
	jobName: string
	startTime: string
	endTime: string
	isAnytime: boolean
	tzAbrev: string
	entries: Array<ScheduleListItemEntry> = []

	get hasPending(): boolean {
		for (const entry of this.entries) {
			if (entry.isPending) {
				return true
			}
		}
		return false
	}

	get hasNonPending(): boolean {
		for (const entry of this.entries) {
			if (!entry.isPending) {
				return true
			}
		}
		return false
	}

	get visible(): boolean {
		for (const entry of this.entries) {
			if (entry.visible) {
				return true
			}
		}
		return false
	}

	constructor(job: JobRecord, site: JobSiteRecord, siteTzZoneName: string) {
		const is12Hours = DateTimeHelper.format12Hour
		// const startTimeMom = moment('2017-01-01').add(moment.duration(job.start_time))
		// const startTime = is12Hours ? startTimeMom.format('h:mm a') : startTimeMom.format('HH:mm')
		// const endTimeMom = moment('2017-01-01').add(moment.duration(job.end_time))
		// const endTime = is12Hours ? endTimeMom.format('h:mm a') : endTimeMom.format('HH:mm')
		this.elemId = `item-card-id-${job.id}`
		this.job = job
		this.jobName = job.description
		this.jobId = job.id
		// this.startTime = startTime
		// this.endTime = endTime
		this.isAnytime = true
		this.tzAbrev = moment.tz(siteTzZoneName).zoneAbbr()
	}

	sortEntries() {
		this.entries = _.sortBy(this.entries, 'sortTime')
	}

	filter(text: string, useExactMatch) {
		for (const entry of this.entries) {
			entry.filter(text, useExactMatch)
		}
	}
	filterForDayViewDate(date: Date) {
		for (const entry of this.entries) {
			entry.filterForDayViewDate(date)
		}
	}
	filterForScheduleStatus(filter: ScheduleStatusFilter) {
		for (const entry of this.entries) {
			entry.filterForScheduleStatus(filter)
		}
	}
	filterForSubSection(subSection: 'CURRENT' | 'PENDING') {
		for (const entry of this.entries) {
			entry.filterForSubSection(subSection)
		}
	}
}

// Schedule List Items

export interface ScheduleEditActionSelectedEntry {
	empName: string
	jobName: string
	entry: ScheduleEntry
	rule: string
	newSchedType: NewScheduleType
}

export class ScheduleListItemEntry {
	emp: EmployeeRecord
	empCount: number
	job: JobRecord
	site: JobSiteRecord
	entry: ScheduleEntry
	comments: string
	employeeList: Array<EmployeeRecord> = []
	conflicts = []
	breakInfoSummary = ''
	managedBy = ''

	childEntries = []

	info: ScheduleInfo

	dayViewDate: Date = null
	subSection: 'CURRENT' | 'PENDING' = 'CURRENT'
	statusFilter = new ScheduleStatusFilter()

	matchesFilterText = true
	matchesDayViewDate = true
	matchesSubSection = true
	matchesScheduleStatusFilter = true

	showScheduledEmployeeList = false
	showScheduleChanges = false

	colorVendor = new ColorVendor()

	isIndividualEmpSchedWithInactiveEmp = false

	get schedColorStyle(): Object {
		const schedRecurId = this.entry.id
		const defaultIdColor = ColorVendor.getColorById(schedRecurId)
		const assignedColor = this.entry.assigned_color ? this.entry.assigned_color : defaultIdColor
		return { color: assignedColor }
	}

	get visible(): boolean {
		const dayViewMatch = this.dayViewDate ? this.matchesDayViewDate : true
		const statusFilterMatch = this.statusFilter.active ? this.matchesScheduleStatusFilter : true
		const expirationMatch = this.statusFilter.showExpired || (!this.statusFilter.showExpired && this.matchesScheduleStatusFilter)
		const textFilterMatch = this.matchesFilterText
		const subSectionMatch = this.subSection ? this.matchesSubSection : true
		const empCountMatch = this.statusFilter.active && this.statusFilter.showEmpCount ? this.matchesFilterText : true

		return dayViewMatch && statusFilterMatch && textFilterMatch && subSectionMatch && empCountMatch && expirationMatch
	}

	get rule(): RRule {
		return this.info.rule
	}
	get nextUpInfo(): string {
		return this.info.nextUpInfo
	}
	get lastUpInfo(): string {
		return this.info.lastUpInfo
	}
	get siteTimeInfo(): string {
		return this.info.siteTimeInfo
	}
	get scheduledTimeInfo(): string {
		return this.info.scheduledTimeInfo
	}
	get futureStart(): string {
		return this.info.futureStart
	}
	get futureEnd(): string {
		return this.info.futureEnd
	}

	get sortTime(): string {
		// Used to sort shifts for schedule list
		if (this.isAnytimeJob) {
			return ' '
		} else {
			return this.startTime
		}
	}

	get startTime(): string {
		return this.info.startTime
	}
	get endTime(): string {
		return this.info.endTime
	}
	get tzAbrev(): string {
		return this.info.jobSiteTzAbbr
	}

	get isAnytimeJob(): boolean {
		return this.info.isAnytimeJob
	}
	get isJobActive(): boolean {
		return this.info.isJobActive
	}
	get isScheduledForToday(): boolean {
		return this.info.isScheduledForToday
	}
	get isUpcomingForToday(): boolean {
		return this.info.isUpcomingForToday
	}
	get isInProgress(): boolean {
		return this.info.isInProgress ? true : false
	}
	get isCompletedForToday(): boolean {
		return this.info.isCompletedForToday
	}
	get isScheduledOneTime(): boolean {
		return this.info.isScheduledOneTime
	}
	get isExpired(): boolean {
		return !this.hasNextDate && !this.isScheduledForToday
	}
	get hasNextDate(): boolean {
		return this.info.hasNextUpDate
	}
	get isPending(): boolean {
		return this.entry.approval_state === 'PENDING'
	}

	get empName(): string {
		return this.emp.name
	}
	get jobName(): string {
		return this.job.description
	}
	get childCount(): number {
		return this.childEntries.length
	}
	get description(): string {
		return this.entry.description
	}
	get isEnabled(): boolean {
		return this.entry.enabled
	}
	get isWeekly(): boolean {
		return this.rule.options.freq === 2
	}
	get isMultDayJob(): boolean {
		return this.job.multi_day
	}
	get errorMsg(): string {
		return this.entry.error_msg
	}
	get notes(): string {
		return this.entry.comments
	}

	constructor(
		dbSrvc: DatabaseService,
		emp: EmployeeRecord,
		job: JobRecord,
		site: JobSiteRecord,
		siteTzZoneName: string,
		entry: ScheduleEntry,
		calculateNextUp: boolean,
	) {
		const infoData = new ScheduleInfoData(null, job, site, siteTzZoneName, entry, calculateNextUp)
		this.info = new ScheduleInfo(infoData)

		this.emp = emp
		this.job = job
		this.site = site
		this.comments = entry.comments
		this.entry = entry

		this.setupScheduleTypeInformation()
		this.setupBreakInformation(job, entry)
		this.setupChildEntries(dbSrvc)
		this.setupEmployeeList(dbSrvc)

		// Setup managed by info
		const userId = this.entry.approval_state_last_user_id
		const user = dbSrvc.settingSrvc.getUserForId(userId)
		if (user) this.managedBy = user.first_name + ' ' + user.last_name
	}

	private setupScheduleTypeInformation() {
		const empId = this.entry.employee_id
		// Any Employee Schedule
		if (empId === 0) {
			this.empCount = this.entry.employee_count
		}
		// Multi-Employee Schedule
		if (empId === 1) {
			const empIds = this.entry.employee_ids
			this.empCount = empIds.length
		}
		// Individual Employee Schedule
		if (empId !== 0 && empId !== 1) {
			if (!this.emp.active) {
				this.isIndividualEmpSchedWithInactiveEmp = true
			}
		}
	}

	setupBreakInformation(job: JobRecord, entry: ScheduleEntry) {
		const breakTime = entry.break_time ? entry.break_time : job.break_time
		const breakApply = entry.break_time_worked ? entry.break_time_worked : job.break_time_worked
		const breakTimeDur = moment.duration(breakTime).asMinutes()
		const breakApplyDur = moment.duration(breakApply)
		const breakApplyComponents = DateTimeHelper.formatDuration('H:m', breakApplyDur).split(':')
		const breakString = breakTime ? `${breakTimeDur}min break` : ``
		const applyString =
			breakTime && breakApply
				? ` every ${breakApplyComponents[0]}hr ${breakApplyComponents[1] === '0' ? '' : breakApplyComponents[1] + 'min'}`
				: breakTime
					? ` per shift`
					: ''
		this.breakInfoSummary = breakString + applyString
	}

	private setupChildEntries(dbSrvc: DatabaseService) {
		const schedId = this.entry.id
		this.childEntries = dbSrvc.schedulerSrvc.getChildEntriesForParentId(schedId)
	}

	private setupEmployeeList(dbSrvc: DatabaseService) {
		if (this.emp.id !== 1) {
			return
		}
		const empIds = this.entry.employee_ids || []
		const list = empIds.map((id) => dbSrvc.empSrvc.getEmployeeById(id)).filter((emp) => !!emp)

		const sortedList = _.sortBy(list, 'name')
		const activeEmps = sortedList.filter((emp) => emp.active)
		const inactiveEmps = sortedList.filter((emp) => !emp.active)

		this.employeeList = [...activeEmps, ...inactiveEmps]
	}

	calculateNextUpInformation() {
		this.info.calculateNextUpInformation()
	}

	filter(text: string, useExactMatch: boolean): boolean {
		if (!text) {
			this.matchesFilterText = true
			return true
		}
		const timeInfo = this.isAnytimeJob ? 'anytime' : `${this.scheduledTimeInfo}`
		const scheduledEmployees = this.employeeList.map((e) => e.name).join(', ')
		// log('Scheduled Employees', scheduledEmployees)
		const expiredTag = this.isExpired ? '#expired' : ''
		const enabledTag = this.isEnabled ? '#enabled' : '#disabled'
		const tagsSearchString = this.entry.xtagContainer.getSearchString()
		const data =
			`${expiredTag}${enabledTag}${tagsSearchString}${this.empName}*${this.jobName}*${this.description}*${this.managedBy}*${timeInfo}*${scheduledEmployees}*${this.notes}` ||
			''
		const filterString = data.toLowerCase()

		// Handle employee count filtering
		if (this.statusFilter.active && this.statusFilter.showEmpCount) {
			const empCountString = this.empCount ? `${this.empCount}` : '1'
			if (text === empCountString) {
				this.matchesFilterText = true
			} else {
				this.matchesFilterText = false
			}
			return this.matchesFilterText
		}

		if (useExactMatch) {
			if (filterString.includes(text)) {
				this.matchesFilterText = true
			} else {
				this.matchesFilterText = false
			}
			return this.matchesFilterText
		}

		const terms = text.trim().split(' ')
		let count = terms.length
		for (const term of terms) {
			if (filterString.includes(term)) {
				count--
			}
		}
		this.matchesFilterText = count === 0
		return this.matchesFilterText
	}

	filterForDayViewDate(date: Date): boolean {
		this.dayViewDate = date // Leave at top as we need to store the day view date internally
		if (!date) {
			this.matchesDayViewDate = true
			return true
		}

		const logCondition = false // Setup condition such as this.jobName === 'Job Name'

		if (logCondition) {
			log('DT1/empName', this.empName)
			log('DT1/isAnytimeJob', this.isAnytimeJob)
			log('DT1/isOneTimeSchedule', this.isScheduledOneTime)
		}

		const dateStr = this.isAnytimeJob
			? moment(date).format('YYYY-MM-DDT00:00:00:00')
			: moment(date).format('YYYY-MM-DD') + 'T' + this.entry.start_time

		// Match start date when one-time but not an anytime schedule
		if (this.isScheduledOneTime && !this.isAnytimeJob) {
			const scheduleDate = this.entry.start_date
			if (dateStr.includes(scheduleDate)) {
				this.matchesDayViewDate = true
			} else {
				this.matchesDayViewDate = false
			}
			return this.matchesDayViewDate
		}

		const tz = Global.coreSrvc.dbSrvc.settingSrvc.getTimezoneZoneNameForId(this.site.timezone_id)
		const adjustedDate = moment.tz(dateStr, 'YYYY-MM-DDTHH:mm:ss', tz).toDate()

		const rule = this.rule
		const dates = DateTimeHelper.makeUtcDayViewDates(adjustedDate)
		const matches = rule.between(dates.start, dates.end, true)
		if (matches.length > 0) {
			this.matchesDayViewDate = true
		} else {
			this.matchesDayViewDate = false
		}

		if (logCondition) {
			log('DT1/entryStartTime', this.entry.start_time)
			log('DT1/isAnytimeJob', this.isAnytimeJob)
			log('DT1/dateStr', dateStr)
			log('DT1/dates', dates)
			log('DT1/adjustedDate', adjustedDate)
			log('DT1/adjustedDateIso', adjustedDate.toISOString())
			log('DT1/matches', matches)
			log('DT1/matchesDayViewDate', this.matchesDayViewDate)
		}

		return this.matchesDayViewDate
	}

	filterForScheduleStatus(filter: ScheduleStatusFilter): boolean {
		// console.log('Filter', filter)
		this.statusFilter = filter
		if (!filter) {
			this.matchesScheduleStatusFilter = true
			return true
		}
		const showInProgress = filter.showInProgress ? this.isInProgress : false
		const showEmpCount = filter.showEmpCount ? this.matchesFilterText : false
		const showScheduledForToday = filter.showScheduledForToday ? this.isScheduledForToday : false
		const showInactiveJob = filter.showInactiveJob ? !this.isJobActive : false
		const showBatchSelection = filter.showBatchSelection
			? Global.coreSrvc.dbSrvc.schedulerSrvc.scheduleBatchUpdateList.includes(this.entry.id)
			: false

		const isExpired = !this.hasNextDate && !this.isScheduledForToday
		const isDisabled = !this.isEnabled

		// Need to adjust isVisible computed property of parent when adding a toggle option
		if (!filter.showExpired && isExpired) {
			this.matchesScheduleStatusFilter = false
			return false
		}

		if (showInProgress || showEmpCount || showScheduledForToday || showInactiveJob || showBatchSelection) {
			this.matchesScheduleStatusFilter = true
		} else {
			this.matchesScheduleStatusFilter = false
		}
		return this.matchesScheduleStatusFilter
	}

	filterForSubSection(subSection: 'CURRENT' | 'PENDING') {
		this.subSection = subSection
		if (!subSection) {
			this.matchesSubSection = true
			return true
		}
		if (subSection === 'CURRENT') {
			if (!this.isPending) {
				this.matchesSubSection = true
			} else {
				this.matchesSubSection = false
			}
		}
		if (subSection === 'PENDING') {
			if (this.isPending) {
				this.matchesSubSection = true
			} else {
				this.matchesSubSection = false
			}
		}
		return this.matchesSubSection
	}
}

// Schedule Info

export interface IScheduleInfoData {
	identifier: string
	timezone: string
	startDate: string
	startTime: string
	endDate: string
	endTime: string
	ruleStr: string
	calculateNextUp: boolean
	job: JobRecord
	entry: ScheduleEntry
}

export class ScheduleInfoData implements IScheduleInfoData {
	calculateNextUp = true
	identifier: string
	timezone: string
	startDate: string
	startTime: string
	endDate: string
	endTime: string
	ruleStr: string

	entry: ScheduleEntry
	job: JobRecord

	constructor(identifier: string, job: JobRecord, site: JobSiteRecord, siteTzZoneName: string, entry: ScheduleEntry, calculateNextUp: boolean) {
		this.calculateNextUp = calculateNextUp
		this.identifier = identifier
		this.timezone = siteTzZoneName
		this.startDate = entry.start_date
		this.startTime = entry.start_time
		this.endDate = entry.recurrence_end
		this.endTime = entry.end_time
		this.ruleStr = entry.recurrence
		this.entry = entry
		this.job = job
	}

	static makeScheduleInfoData(
		identifier: string,
		job: JobRecord,
		site: JobSiteRecord,
		siteTzZoneName: string,
		startDate: string,
		startTime: string,
		endDate: string,
		endTime: string,
		ruleStr: string,
		entry: ScheduleEntry,
	) {
		const data: IScheduleInfoData = {
			calculateNextUp: true,
			identifier: identifier,
			timezone: siteTzZoneName,
			startDate: startDate,
			startTime: startTime,
			endDate: endDate,
			endTime: endTime,
			ruleStr: ruleStr,
			entry: entry,
			job: job,
		}
		return data
	}
}

export class ScheduleInfo {
	data: IScheduleInfoData
	identifier: string
	timezone: string
	jobSiteTzAbbr: string
	startDate: string
	startTime: string
	endTime: string
	rule: RRule
	ruleSet: RRuleSet

	lastUpMom: moment.Moment
	nextUpMom: moment.Moment

	isAnytimeJob: boolean
	isJobActive: boolean
	isScheduledForToday: boolean
	isInProgress: boolean
	isCompletedForToday: boolean
	isUpcomingForToday: boolean
	isScheduledOneTime: boolean
	hasNextUpDate: boolean

	lastUpInfo: string
	nextUpInfo: string
	scheduledTimeInfo: string
	siteTimeInfo: string
	futureStart: string
	futureEnd: string

	constructor(data: IScheduleInfoData) {
		this.data = data
		this.identifier = data.identifier
		this.timezone = data.timezone
		this.startDate = data.startDate
		this.startTime = data.startTime
		this.endTime = data.endTime
		this.isAnytimeJob = this.startTime === this.endTime
		this.isJobActive = data.job.active
		this.jobSiteTzAbbr = moment.tz(this.timezone).zoneAbbr()

		const recurRule = data.ruleStr

		if (this.isAnytimeJob) {
			this.startTime = '00:00:01'
			this.endTime = '23:59:59'
		}

		const dtStart = this.startDate.replace(/\-/g, '') + 'T' + this.startTime.replace(/\:/g, '')

		const ruleString = `DTSTART;TZID=${data.timezone}:${dtStart};\n${recurRule}`
		// this.rule = RRule.fromString(ruleString)
		this.ruleSet = rrulestr(ruleString, { forceset: true }) as RRuleSet
		this.rule = this.ruleSet.rrules()[0]

		const is12Hours = DateTimeHelper.format12Hour
		this.siteTimeInfo = is12Hours ? moment.tz(this.timezone).format('ddd Do @ h:mm a z') : moment.tz(this.timezone).format('ddd Do @ HH:mm z')

		if (data.calculateNextUp) {
			this.calculateNextUpInformation()
		}

		this.setScheduledTimeInfo()

		// Check if scheduled one time
		if (this.rule) {
			this.isScheduledOneTime = this.rule.options.count === 1
		}

		// Check if schedule doesn't start until future date
		// const schedStart = moment.tz(dtStart, 'YYYY-MM-DDTHH:mm:ss', this.timezone)
		const schedStart = moment.tz(dtStart, 'YYYYMMDDTHHmmss', this.timezone)
		if (schedStart.isValid()) {
			if (schedStart.isAfter(moment.now())) {
				this.futureStart = schedStart.format('ddd, MMM Do YYYY')
			}
		}

		const endDate = this.data.endDate
		if (endDate) {
			const endTime = this.endTime
			const endString = endDate + 'T' + endTime
			const schedEnd = moment.tz(endString, 'YYYY-MM-DDTHH:mm:ss', this.timezone)
			if (schedEnd.isValid()) {
				if (schedEnd.isSameOrAfter(moment.now(), 'day')) {
					this.futureEnd = schedEnd.format('ddd, MMM Do YYYY')
				}
			}
		}
	}

	calculateNextUpInformation() {
		this.setLastAndNextUpInfo()
		this.setIsScheduledForToday()
		if (this.isScheduledForToday) {
			this.setIsInProgress()
		}
		this.setIsCompletedForToday()
	}

	momentFromRuleDate(date: Date): moment.Moment {
		const dateString = date.toISOString().replace('Z', '')
		return moment(dateString)
	}

	setLastAndNextUpInfo() {
		const localMom = moment().format('YYYY-MM-DDTHH:mm:ss')
		const nowUtcDate = moment.tz(localMom, 'YYYY-MM-DDTHH:mm:ss', this.timezone).utc(true).toDate()

		// Setup lastUpMom and lastUpInfo
		const lastUpDate = this.rule.before(nowUtcDate)
		if (lastUpDate) {
			const lastUpMom = this.momentFromRuleDate(lastUpDate)
			this.lastUpMom = lastUpMom
			this.lastUpInfo = 'Last up ' + lastUpMom.clone().tz(this.timezone).format('ddd MMM Do') // ddd MMM Do @ h:mm a z
		} else {
			this.lastUpInfo = 'No past dates scheduled'
		}

		// Set nextUpMom and nextUpInfo
		const nextUpDate = this.rule.after(nowUtcDate)
		if (nextUpDate) {
			const nextUpMom = this.momentFromRuleDate(nextUpDate)
			this.nextUpMom = nextUpMom
			this.nextUpInfo = 'Next up ' + nextUpMom.clone().tz(this.timezone).format('ddd MMM Do, YYYY')
			this.hasNextUpDate = true
		} else {
			this.nextUpInfo = 'No future dates scheduled'
			this.hasNextUpDate = false
		}
	}

	setIsScheduledForToday() {
		const nowMom = moment.tz(this.timezone)
		const lastUpMom = this.lastUpMom ? this.lastUpMom.clone().tz(this.timezone) : null
		const nextUpMom = this.nextUpMom ? this.nextUpMom.clone().tz(this.timezone) : null

		if (lastUpMom && nowMom.isSame(lastUpMom, 'day')) {
			this.isScheduledForToday = true
		}
		if (nextUpMom && nowMom.isSame(nextUpMom, 'day')) {
			this.isScheduledForToday = true
		}

		if (this.isScheduledForToday === undefined) {
			this.isScheduledForToday = false
		}
	}

	setIsInProgress() {
		if (!this.lastUpMom) {
			this.isInProgress = false
			return
		}

		const nowMom = moment.tz(this.timezone)
		const localDateStr = nowMom.format('YYYY-MM-DD')
		const startMom = moment.tz(localDateStr + this.startTime, 'YYYY-MM-DDHH:mm:ss', this.timezone)
		const endMom = moment.tz(localDateStr + this.endTime, 'YYYY-MM-DDHH:mm:ss', this.timezone)
		if (endMom.isBefore(startMom, 'seconds')) {
			endMom.add(1, 'day')
		}

		if (nowMom.isBetween(startMom, endMom, 'seconds', '[]')) {
			this.isInProgress = true
		} else {
			this.isInProgress = false
		}
	}

	setIsCompletedForToday() {
		if (this.isScheduledForToday) {
			const is12Hours = DateTimeHelper.format12Hour
			const nowMom = moment.tz(this.timezone)
			const localDateStr = moment.tz(this.timezone).format('YYYY-MM-DD')
			const startMom = moment.tz(localDateStr + this.startTime, 'YYYY-MM-DDHH:mm:ss', this.timezone)
			const endMom = moment.tz(localDateStr + this.endTime, 'YYYY-MM-DDHH:mm:ss', this.timezone)
			if (nowMom.isBefore(startMom, 'seconds') && nowMom.isSame(startMom, 'day')) {
				this.isUpcomingForToday = true
				this.nextUpInfo = 'Starts ' + (is12Hours ? startMom.format('ddd, h:mm a z') : startMom.format('ddd, HH:mm z'))
			}
			if (nowMom.isAfter(endMom, 'seconds') && !this.isUpcomingForToday) {
				this.isCompletedForToday = true
			}
		}
		if (this.isCompletedForToday === undefined) {
			this.isCompletedForToday = false
		}
		if (this.isUpcomingForToday === undefined) {
			this.isUpcomingForToday = false
		}
	}

	localSiteTime() {
		return moment().tz(this.timezone).format('ddd Do @ h:mm a z')
	}

	setScheduledTimeInfo() {
		const is12Hours = DateTimeHelper.format12Hour
		const startMom = moment.tz(this.startTime, 'HH:mm:ss', this.timezone)
		const endMom = moment.tz(this.endTime, 'HH:mm:ss', this.timezone)
		if (endMom.isBefore(startMom, 'seconds')) {
			// console.log('end before start')
			endMom.add(1, 'day')
		}
		if (is12Hours) {
			this.scheduledTimeInfo = startMom.format('h:mm a') + ' - ' + endMom.format('h:mm a z')
		} else {
			this.scheduledTimeInfo = startMom.format('HH:mm') + ' - ' + endMom.format('HH:mm z')
		}
	}
}

export interface IScheduleOptionsDayOption {
	value: number
	aValue: Array<number>
	isoValue: number
	fcValue: number
	name: string
	short: string
	code: string
	rRuleDay: Weekday
}

export class ScheduleOptions {
	static ruleConst = {
		byWeekdayDefault: [0, 1, 2, 3, 4, 5, 6],
		bySetPosDefault: [],
	}

	static ruleOptions = [
		{ value: 0, name: 'Custom', rule: '' },
		{ value: 1, name: 'No End', rule: 'RRULE:FREQ=WEEKLY;INTERVAL=2;BYDAY=WE,MO,FR,SU' },
		{
			value: 2,
			name: 'Count End',
			rule: 'DTSTART;TZID=America/Denver:20190312T000000\nRRULE:FREQ=DAILY;COUNT=30;INTERVAL=1;WKST=MO',
		},
		{
			value: 3,
			name: 'Date End',
			rule: 'DTSTART;TZID=America/Los_Angeles:20190312T000000\nRRULE:FREQ=WEEKLY;INTERVAL=2;BYDAY=SA,SU;UNTIL=20190531T050000',
		},
		{ value: 4, name: 'By Month', rule: 'RRULE:FREQ=YEARLY;COUNT=30;INTERVAL=1;WKST=MO;BYDAY=TH;BYMONTH=1,8' },
	]

	static dayOptions: Array<IScheduleOptionsDayOption> = [
		{ value: 0, aValue: [0], isoValue: 1, fcValue: 1, name: 'Monday', short: 'Mon', code: 'Mo', rRuleDay: RRule.MO },
		{ value: 1, aValue: [1], isoValue: 2, fcValue: 2, name: 'Tuesday', short: 'Tue', code: 'Tu', rRuleDay: RRule.TU },
		{
			value: 2,
			aValue: [2],
			isoValue: 3,
			fcValue: 3,
			name: 'Wednesday',
			short: 'Wed',
			code: 'We',
			rRuleDay: RRule.WE,
		},
		{
			value: 3,
			aValue: [3],
			isoValue: 4,
			fcValue: 4,
			name: 'Thursday',
			short: 'Thu',
			code: 'Th',
			rRuleDay: RRule.TH,
		},
		{ value: 4, aValue: [4], isoValue: 5, fcValue: 5, name: 'Friday', short: 'Fri', code: 'Fr', rRuleDay: RRule.FR },
		{
			value: 5,
			aValue: [5],
			isoValue: 6,
			fcValue: 6,
			name: 'Saturday',
			short: 'Sat',
			code: 'Sa',
			rRuleDay: RRule.SA,
		},
		{ value: 6, aValue: [6], isoValue: 7, fcValue: 0, name: 'Sunday', short: 'Sun', code: 'Su', rRuleDay: RRule.SU },
	]

	static positionOptions = [
		{ value: 1, aValue: [1], name: '1st' },
		{ value: 2, aValue: [2], name: '2nd' },
		{ value: 3, aValue: [3], name: '3rd' },
		{ value: 4, aValue: [4], name: '4th' },
		{ value: -1, aValue: [-1], name: 'Last' },
	]

	static monthOptions = [
		{ value: 1, name: 'January', code: 'Jan' },
		{ value: 2, name: 'February', code: 'Feb' },
		{ value: 3, name: 'March', code: 'Mar' },
		{ value: 4, name: 'April', code: 'Apr' },
		{ value: 5, name: 'May', code: 'May' },
		{ value: 6, name: 'June', code: 'Jun' },
		{ value: 7, name: 'July', code: 'Jul' },
		{ value: 8, name: 'August', code: 'Aug' },
		{ value: 9, name: 'September', code: 'Sep' },
		{ value: 10, name: 'October', code: 'Oct' },
		{ value: 11, name: 'November', code: 'Nov' },
		{ value: 12, name: 'December', code: 'Dec' },
	]

	static freqOptions = [
		{ value: 0, name: 'Yearly', interval: 'year', rRuleFreq: RRule.YEARLY },
		{ value: 1, name: 'Monthly', interval: 'month', rRuleFreq: RRule.MONTHLY },
		{ value: 2, name: 'Weekly', interval: 'week', rRuleFreq: RRule.WEEKLY },
		// { value: 3, name: 'Daily', interval: 'day', rRuleFreq: RRule.DAILY },
		// { value: 4, name: 'Hourly', interval: 'hour', rRuleFreq: RRule.HOURLY },
	]

	static getDayOption(label: string, value: any) {
		return ScheduleOptions.dayOptions.find((day) => day[label] === value)
	}

	static sortDaysByLongName(names: Array<string>) {
		return ScheduleOptions.dayOptions.filter((opt) => names.includes(opt.name)).map((opt) => opt.name)
	}

	static convertIsoDayArray(days: Array<number>): string {
		return days.map((day) => ScheduleOptions.dayOptions.find((opt) => day === opt.isoValue)?.code).toString()
	}

	// static getDayOptionsIsoDayValue(value: number) {
	// 	return value + 1
	// }
}

export class ScheduleAssignmentManager {
	originalAssignment: Array<IScheduleEntryShiftAssignment> = []

	monday: Array<number> = []
	tuesday: Array<number> = []
	wednesday: Array<number> = []
	thursday: Array<number> = []
	friday: Array<number> = []
	saturday: Array<number> = []
	sunday: Array<number> = []

	constructor(data?: Array<IScheduleEntryShiftAssignment>) {
		if (data) {
			this.setupManager(data)
		}
	}

	currentRRule: () => RRule

	currentScheduleType: () => number = () => null
	currentSelectedRRuleDays: () => Array<number> = () => [] // Setup in parent Return Array of RRule (not ISO) for selected days
	toggleWeekday: (day?: IScheduleOptionsDayOption) => void = () => false // Setup in child

	setupManager(data: Array<IScheduleEntryShiftAssignment>) {
		this.originalAssignment = data
		for (const item of data) {
			const empId = item.employee_id
			const daysOfWeek = item.days_of_week
			for (const day of daysOfWeek) {
				switch (day) {
					case 1:
						this.monday.push(empId)
						break
					case 2:
						this.tuesday.push(empId)
						break
					case 3:
						this.wednesday.push(empId)
						break
					case 4:
						this.thursday.push(empId)
						break
					case 5:
						this.friday.push(empId)
						break
					case 6:
						this.saturday.push(empId)
						break
					case 7:
						this.sunday.push(empId)
						break
				}
			}
		}
	}

	areAssignmentsValid() {
		// Get a list of names for currently selected days and check if associated day has someone scheduled
		const currentlySelected = this.currentSelectedRRuleDays() || []
		const daysSelected = ScheduleOptions.dayOptions.filter((day) => currentlySelected.includes(day.value)).map((day) => day.name.toLowerCase())
		for (const day of daysSelected) {
			if (this[day].length === 0) {
				return false
			}
		}
		return true
	}

	getOriginalEmployeeIds(): Array<number> {
		return this.originalAssignment.map((r) => r.employee_id)
	}

	getUpdateString(): string {
		// [{ employee_id: 21615, days_of_week: [1, 2, 3, 4] }, { employee_id: 24480, days_of_week: [3, 4, 5, 6, 7] }]
		const empList = [...this.monday, ...this.tuesday, ...this.wednesday, ...this.thursday, ...this.friday, ...this.saturday, ...this.sunday]
		const dowList = _.uniq(empList).map((empId) => ({
			employee_id: empId,
			days_of_week: this.getDaysScheduledForId(empId),
		}))
		return dowList.length > 0 ? JSON.stringify(dowList) : null
	}

	getDaysScheduledForId(id: number): Array<number> {
		const daysList = ScheduleOptions.dayOptions
		const schedDays = []
		daysList.forEach((day) => {
			const dayName = day.name.toLowerCase()
			if (this[dayName].includes(id)) {
				schedDays.push(day.isoValue)
			}
		})
		log('Scheduled Days', id, schedDays)
		if (schedDays.length === 7) {
			return null
		}
		return schedDays
	}
}

// Full Calendar event mouse over/leave info
export interface FCMouseEnterLeaveInfo {
	event: EventApi
	el: HTMLElement
	jsEvent: Event
	view: Object
}

export class NewShiftRangeSelectInfo {
	empId: number
	jobId: number
	range: DateSelectArg

	constructor(range: DateSelectArg, empId: number, jobId: number) {
		this.empId = empId
		this.jobId = jobId
		this.range = range
	}
}
