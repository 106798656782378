import { log } from '@app/helpers/logger'
import { DatabaseService } from '@app/services/backend/database.service'
import { Checkpoint } from './checkpoint'
import { Incident } from './incident'
import { JobSiteRecord } from './jobsite'
import { PFPImageFile } from './pfp'
import { ShiftSummaryReport } from './report-shiftsummary'
import { DistanceUnitFormat, UserReportType } from './report-user'
import { TransactionLogRecord } from './transaction'

// SHIFTREPORT -> Rendering of an Incident Log Record
// SHIFTSUMMARY -> Rendering of a Shift Summary

export type ReportRenderDataType = 'SHIFTREPORT' | 'SHIFTSUMMARY'

export interface ShiftSummaryReportPublicResponseData {
	jobSite: JobSiteRecord
	transaction: TransactionLogRecord
	checkpoints: Array<Checkpoint>
	shiftReports: Array<Incident>
}

export interface ShiftSummaryReportPublicResponseConfig {
	companyAddress: any
	companyName: string
	countryCode: string
	formatTime12Hour: boolean
	defaultUnits: DistanceUnitFormat
	gpsAllowedDelay: string
	industryType: string
	logoUrl: string
	empPfp: any
}

export class ReportRendererConfig {
	reportData: any // Holds Incident object for rendering
	reportType: UserReportType
	publicView = false
	publicLink = ''

	formatTime12Hour = true // Global setting for portal
	defaultUnits: DistanceUnitFormat = 'IMPERIAL' // From company record
	logoUrl = null // From company record
	empPfpUrl = null // From employee record
	companyAddress = null // From company record
	companyName = null // From company record

	transaction = {
		actual_start: null,
		actual_end: null,
	}

	static buildPublicShiftReportConfig(configData: any, incident: Incident): ReportRendererConfig {
		log('Public shift report config/record', configData, incident)
		const config = new ReportRendererConfig()
		config.reportData = incident
		config.reportType = incident.report_type as UserReportType
		config.publicView = true
		config.publicLink = incident.publicReportLink

		for (const attr in configData) {
			if (configData.hasOwnProperty(attr)) {
				config[attr] = configData[attr]
			}
		}
		if (config.formatTime12Hour === null) config.formatTime12Hour = true
		if (config.defaultUnits === null) config.defaultUnits = 'IMPERIAL'
		if (configData.empPfp) {
			const pfpImageFile = new PFPImageFile(configData.empPfp)
			config.empPfpUrl = pfpImageFile.getPfpImageUrl()
		}

		return config
	}

	static buildPrivateShiftReportConfig(dbSrvc: DatabaseService, incident: Incident): ReportRendererConfig {
		const config = new ReportRendererConfig()
		config.reportData = incident
		config.reportType = incident.report_type as UserReportType
		config.publicView = false
		config.publicLink = incident.publicReportLink
		config.setupCompanyInfo(dbSrvc)

		// Lookup Transaction Info
		const transId = incident.transaction_log_id
		const trans = dbSrvc.tranSrvc.getTransactionById(transId)
		config.transaction.actual_end = trans?.actual_start
		config.transaction.actual_start = trans?.actual_end

		// Setup Emp PFP
		const areEmployeeProfilesAvailabe = dbSrvc.settingSrvc.areEmployeeProfilesAvailable()
		const emp = dbSrvc.empSrvc.getEmployeeById(trans?.employee_id)
		if (areEmployeeProfilesAvailabe && emp && emp.pfp_approved) {
			const pfpImage = emp.getPfpImageFile()
			if (pfpImage) {
				config.empPfpUrl = pfpImage.getPfpImageUrl()
			}
		}

		log('Config', config)
		return config
	}

	static buildPublicShiftSummaryReportConfig(
		responseConfig: ShiftSummaryReportPublicResponseConfig,
		responseData: ShiftSummaryReportPublicResponseData,
	): ReportRendererConfig {
		log('Building public shift summary report', responseConfig, responseData)
		const trans = responseData.transaction
		const config = new ReportRendererConfig()
		config.reportType = 'SHIFTSUMMARY'
		config.reportData = ShiftSummaryReport.buildFromPublicData(responseConfig, responseData)
		config.publicView = true
		config.publicLink = `${window.location.origin}/#/reports/shiftsummary/${trans.uuid}`
		config.formatTime12Hour = true
		config.defaultUnits = responseConfig.defaultUnits
		config.companyAddress = responseConfig.companyAddress

		config.logoUrl = responseConfig.logoUrl ?? ''
		config.companyName = responseConfig.companyName ?? ''
		config.transaction.actual_start = trans.actual_start
		config.transaction.actual_end = trans.actual_end

		if (responseConfig.empPfp) {
			const pfpImageFile = new PFPImageFile(responseConfig.empPfp)
			config.empPfpUrl = pfpImageFile.getPfpImageUrl()
		}

		return config
	}

	static buildPrivateShiftSummaryReportConfig(dbSrvc: DatabaseService, report: ShiftSummaryReport): ReportRendererConfig {
		const config = new ReportRendererConfig()
		const trans = report.reportData.transaction
		config.reportData = report
		config.reportType = report.metaData.format.reportType
		config.publicView = false
		config.publicLink = trans.uuid ? `${window.location.origin}/#/reports/shiftsummary/${trans.uuid}` : ''
		config.formatTime12Hour = report.reportData.displayPrefs.format12Hour
		config.setupCompanyInfo(dbSrvc)

		config.transaction.actual_start = report.reportData.transaction.actual_start
		config.transaction.actual_end = report.reportData.transaction.actual_end

		// Setup Emp PFP
		const areEmployeeProfilesAvailabe = dbSrvc.settingSrvc.areEmployeeProfilesAvailable()
		const emp = dbSrvc.empSrvc.getEmployeeById(trans?.employee_id)
		if (areEmployeeProfilesAvailabe && emp && emp.pfp_approved) {
			const pfpImage = emp.getPfpImageFile()
			if (pfpImage) {
				config.empPfpUrl = pfpImage.getPfpImageUrl()
			}
		}

		return config
	}

	private setupCompanyInfo(dbSrvc: DatabaseService) {
		const company = dbSrvc.settingSrvc.getCompany()
		const adminPrefs = dbSrvc.settingSrvc.getAdminPrefsForCompany()

		this.formatTime12Hour = adminPrefs.globalFormatTime12Hours

		this.logoUrl = company.logo_url
		this.companyAddress = company.address_json ? company.getAddress() : null

		if (!this.logoUrl) {
			this.companyName = company.name
		}
	}
}
