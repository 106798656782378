import { Component, OnInit, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import {
	BillingMessageEvent,
	ComponentBridgeName,
	FullScreenViewConfig,
	FullScreenViewEvent,
	HelpDialogMessage,
	Incident,
	ReportRendererConfig,
	WorkingDialogConfig,
} from '@app/models'

import { LoggedInCallback, CoreService } from '@app/services'

import { DisplayHelper, log } from '@app/helpers'
import { Subscription } from 'rxjs'

// import { appComponents } from './app.imports'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy, LoggedInCallback {
	fullScreenManager = new FullScreenViewConfig()

	isConnected = true
	billingMessage: BillingMessageEvent = null
	currentDisplayUser = null // Displays user in bottom bar when viewing as user
	subs = new Subscription()

	currentScrollPosition: number
	currentDocumentHeight: number

	workingDialogConfig = new WorkingDialogConfig()

	help = { header: '', msg: '', showDialog: false }
	contactSupportAction = { showDialog: false, title: '', resource: null, recordId: null as number }

	auditAction = {
		recordId: null,
		resource: 'none',
		trackType: null,
		header: 'Audit History',
		footer: 'Audit history for record',
		showDialog: false,
	}

	constructor(
		private coreSrvc: CoreService,
		private router: Router,
	) {
		log('Initialize AppComponent')
		this.isConnected = navigator.onLine ? true : false

		this.subs.add(this.coreSrvc.notifySrvc.helpMessage.subscribe((helpEvent) => this.handleHelpEvent(helpEvent)))
		this.subs.add(
			this.coreSrvc.connectSrvc.monitor().subscribe((isConnected) => {
				this.isConnected = isConnected
			}),
		)
		this.subs.add(
			this.coreSrvc.dbSrvc.billSrvc.billingMessageEvent.subscribe((event) => {
				this.handleBillingEvent(event)
			}),
		)
		this.subs.add(
			this.coreSrvc.eventSrvc.currentDisplayUser.subscribe((username) => {
				this.currentDisplayUser = username
			}),
		)
		this.subs.add(
			this.coreSrvc.eventSrvc.fullScreenView.subscribe((fullScreenEvent) => {
				this.handleFullScreenEvent(fullScreenEvent)
			}),
		)

		// Setup toast manager
		this.subs.add(
			this.coreSrvc.notifySrvc.notificationAlert.subscribe((notification) => {
				this.coreSrvc.msgSrvc.add(notification)
			}),
		)

		this.subs.add(
			this.coreSrvc.notifySrvc.notificationClear.subscribe((notification) => {
				this.coreSrvc.msgSrvc.clear('noteSrvc')
			}),
		)

		this.subs.add(
			this.coreSrvc.eventSrvc.showAuditLog.subscribe((auditActionEvent) => {
				log('AUDITACTIONEVENT', auditActionEvent)
				this.auditAction.recordId = auditActionEvent.recordId
				this.auditAction.resource = auditActionEvent.resource
				this.auditAction.footer = auditActionEvent.footer
				this.auditAction.trackType = auditActionEvent.trackType
				this.auditAction.showDialog = true
			}),
		)

		this.subs.add(this.coreSrvc.workSrvc.workingDialogEvents.subscribe((config) => this.workingDialogConfig.updateConfig(config)))

		this.subs.add(this.coreSrvc.dbSrvc.billSrvc.cardExpirationEvent.subscribe((daysLeft) => this.handleCardExpirationEvent(daysLeft)))

		// Date time picker events
		this.subs.add(this.coreSrvc.displaySrvc.datePickerDidOpen.subscribe((label) => this.handleDatePickerDidOpen(label)))
		this.subs.add(this.coreSrvc.displaySrvc.datePickerDidClose.subscribe((label) => this.handleDatePickerClose(label)))

		// Contact Support events
		this.subs.add(
			this.coreSrvc.eventSrvc.contactSupportEvent.subscribe((contactSupportEvent) => {
				log('CONTACTSUPPORTEVENT', contactSupportEvent)
				this.contactSupportAction.showDialog = true
				this.contactSupportAction.resource = contactSupportEvent.resource
				this.contactSupportAction.title = contactSupportEvent.title
				this.contactSupportAction.recordId = contactSupportEvent.recordId
			}),
		)

		// 20240729 - Check for duplicate declarations in appComponent
		// const uniqueAppComponents = Array.from(new Set(appComponents.map((component) => component.name)))
		// const hasDuplicates = appComponents.length !== uniqueAppComponents.length

		// if (hasDuplicates) {
		// 	log('Duplicate declarations found in appComponents array.')
		// } else {
		// 	log('No duplicate declarations found in appComponents array.')
		// }
	}

	ngOnInit() {
		const bridgeName: ComponentBridgeName = 'ngBridgeGlobal'
		window[bridgeName] = this

		if (this.isBrowserSupported()) {
			this.hideLoader()
			$('#app-loading-banner').remove()
			this.manageInitialAuthentication()
		} else {
			$('#app-loading-banner').remove()
			this.hideLoader()
			this.router.navigate(['/home/unsupported-browser'])
		}
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	handleHelpEvent(helpEvent: HelpDialogMessage) {
		this.help.header = helpEvent.header
		this.help.msg = helpEvent.message
		this.help.showDialog = true
	}

	manageInitialAuthentication() {
		const savedAdpSession = this.coreSrvc.sessionSrvc.getAdpSession()
		if (savedAdpSession) {
			this.router.navigate(['/home/adpDeveloperLogin'])
		} else {
			this.coreSrvc.loginSrvc.isAuthenticated(this)
		}
	}

	isBrowserSupported(): boolean {
		// return true // SET TRUE DISABLE BROWSER CHECK

		const deviceInfo = this.coreSrvc.devDetect.getDeviceInfo()
		log('Device Info', deviceInfo)
		if (deviceInfo && (deviceInfo.browser === 'ie' || deviceInfo.browser === 'IE')) {
			return false
		}
		return true
	}

	isLoggedIn(message: string, isLoggedIn: boolean) {
		log('AppComponent: the user is authenticated: ' + isLoggedIn)
		const mythis = this
		this.coreSrvc.cognito.getIdToken({
			callback() {},
			callbackWithParam(token: any) {
				// Include the passed-in callback here as well so that it's executed downstream
				log('AppComponent: calling initAwsService in callback')
				mythis.coreSrvc.awsUtil.initAwsService(null, isLoggedIn, token)
			},
		})
	}

	startRestoreFromFullScreenView() {
		$('html').addClass('hide-main-scrollbars')
		const element = $('#loading-spinner')
		element.css('opacity', 1)
		element.show()
	}

	finishRestoreFromFullScreenView(delay: number) {
		setTimeout(() => {
			this.hideLoader()
			$('html').removeClass('hide-main-scrollbars')
		}, delay + 1000)
	}

	hideLoader() {
		const element = $('#loading-spinner')
		element.fadeTo(1000, 0.5, () => {
			element.hide()
		})
	}

	displayFixButton(): boolean {
		const route = this.router.url
		// log(route)
		if (this.router.url === '/admin/settings/billing') return false
		return true
	}

	handleBillingEvent(event: BillingMessageEvent) {
		this.billingMessage = event
	}

	handleFullScreenEvent(event: FullScreenViewEvent) {
		log('Full Screen Event:', event)
		const action = event.action
		const modal = $('.modal-backdrop.show')
		this.fullScreenManager.action = action

		switch (action) {
			case 'SHOWSHIFTREPORT':
			case 'SHOWINCIDENTREPORT':
			case 'SHOWHOMECAREREPORT':
			case 'SHOWVEHICLEINSPECTIONREPORT':
			case 'SHOWCHECKLISTREPORT':
				this.saveScrollPosition()
				modal.hide()
				this.coreSrvc.eventSrvc.showFixedHeader.next(false)
				DisplayHelper.scrollToTopViewportAdjust()

				const incident = event.data as Incident
				this.fullScreenManager.config = ReportRendererConfig.buildPrivateShiftReportConfig(this.coreSrvc.dbSrvc, incident)
				this.fullScreenManager.isFullScreen = true
				break
			case 'SHOWSHIFTSUMMARYREPORT':
				this.saveScrollPosition()
				modal.hide()
				this.coreSrvc.eventSrvc.showFixedHeader.next(false)
				DisplayHelper.scrollToTopViewportAdjust()

				const report = event.data
				this.fullScreenManager.config = ReportRendererConfig.buildPrivateShiftSummaryReportConfig(this.coreSrvc.dbSrvc, report)
				this.fullScreenManager.isFullScreen = true
				break
			case 'CLOSE':
				this.startRestoreFromFullScreenView()
				modal.show()
				this.fullScreenManager.data = null
				this.fullScreenManager.isFullScreen = false
				const delay = this.restoreScrollPosition()
				this.coreSrvc.eventSrvc.removeBeforeUnloadBlocker()
				this.finishRestoreFromFullScreenView(delay)
				break
			default:
				alert(`An error was encountered. Please notifiy support that the report viewer was not able to display user report ID: ${incident.id}`)
		}
	}

	saveScrollPosition() {
		this.currentScrollPosition = window.scrollY
		this.currentDocumentHeight = document.body.scrollHeight
	}

	restoreScrollPosition(): number {
		const height = this.currentDocumentHeight ?? 1
		const delayMultiplier = this.coreSrvc.prefSrvc.data.transTablePageLength * 2
		const delay = Math.floor(height / 10) + delayMultiplier
		setTimeout(() => {
			$('html').scrollTop(this.currentScrollPosition)
		}, delay)
		return delay
	}

	showAuditLog(resource: string, recordId: number, footer: string) {
		log('showAuditLog', resource, recordId, footer)
		this.auditAction.resource = resource
		this.auditAction.recordId = recordId
		this.auditAction.footer = footer
		this.auditAction.trackType = null
		this.auditAction.showDialog = true
	}

	datePickerLabel = ''

	private handleDatePickerDidOpen(label: string) {
		if (!label) return
		setTimeout(() => {
			const dialog = document.querySelector('.owl-dt-dialog') as HTMLElement
			const floatingLabel = document.getElementById('date-picker-floating-title')

			if (dialog && floatingLabel) {
				const rect = dialog.getBoundingClientRect()
				floatingLabel.style.top = `${rect.top - 40}px`
				floatingLabel.style.left = `${rect.left}px`
				floatingLabel.style.display = 'block'
				this.datePickerLabel = label
			}
		}, 500)
	}

	private handleDatePickerClose(label: string) {
		this.datePickerLabel = ''
		const floatingLabel = document.getElementById('date-picker-floating-title')
		if (floatingLabel) {
			floatingLabel.style.display = 'none'
		}
	}

	private handleCardExpirationEvent(daysLeft: number) {
		if (daysLeft > 0) {
			const toastStyle = daysLeft > 6 ? 'warn' : 'error'
			this.coreSrvc.notifySrvc.notify(
				toastStyle,
				'Card Expires Soon',
				`Your credit card expires in ${daysLeft} days. Please update your info under Admin > Billing.`,
			)
		}
		if (daysLeft === 0) {
			this.coreSrvc.notifySrvc.notify(
				'error',
				'Card Expires Today',
				`Your credit card expires today. Please update your info under Admin > Billing.`,
			)
		}
		if (daysLeft < 0) {
			this.coreSrvc.notifySrvc.notify('error', 'Card Expired', `Your credit card has expired. Please update your info under Admin > Billing.`)
		}
	}
}
