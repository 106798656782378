<div *ngIf="!dataLoaded" class="loading-block">
	<div><i class="fas fa-loader fa-spin"></i>&nbsp;Loading reports...</div>
</div>
<div *ngIf="isDeleting" class="loading-block">
	<div><i class="fas fa-loader fa-spin"></i>&nbsp;Deleting report...</div>
</div>
<div *ngIf="isSaving" class="loading-block">
	<div><i class="fas fa-loader fa-spin"></i>&nbsp;Saving report...</div>
</div>

<!-- Begin Default Report Lister -->
<div *ngIf="!editReportAction.isEditing">
	<div *ngIf="transInfo.isLoaded" [hidden]="isDeleting || isSaving || isEditing || isCreating || isShowingImages" class="incd-trans-info">
		<div>{{ transInfo.empName }} / {{ transInfo.jobDate }}</div>
		<hr />
	</div>

	<div *ngIf="dataLoaded && incidents.length === 0 && !isCreating && !isSaving && !isEditing && !isPickingReportType" class="incd-empty">
		<h4 style="color: gray">No Employee Reports</h4>
	</div>

	<div [hidden]="isSaving || isDeleting" *ngIf="selectedIncident.isEditing">
		<div *ngIf="!isCreating" class="incd-selected-block">
			<div>
				<div class="float-right">
					<div class="incd-icon-block">
						<i *ngIf="selectedIncident.audio_file" class="far fa-volume-up incd-icon incd-play-icon" (click)="playAudio(selectedIncident)"></i>
					</div>
				</div>
				<div>
					<div class="incd-emp-name" [class.choc-color]="selectedIncident.isEditing">
						{{ selectedIncident.admin_submitted ? selectedIncident.admin_user_name : selectedIncident.employee_name }}
					</div>
					<div class="incd-date">{{ dateFormatter(selectedIncident.created) }}</div>
					<div *ngIf="!selectedIncident.isEditing" class="incd-transcript-prev">
						{{ selectedIncident.transcript ? selectedIncident.transcript : '&lt; no transcript available &gt;' }}
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="!isCreating && selectedIncident.audio_file" class="incd-transcript">
			<div class="incd-label">Transcript</div>
			<div class="incd-transcript">
				<textarea class="incd-transcript-textarea" [(ngModel)]="selectedIncident.transcript">{{ selectedIncident.transcript }}</textarea>
			</div>
		</div>

		<div class="incd-label">Notes</div>
		<div class="incd-notes">
			<textarea class="form-control incd-notes-textarea" [(ngModel)]="selectedIncident.notes">{{ selectedIncident.notes }}</textarea>
		</div>

		<div class="incd-update-block" *ngIf="selectedIncident.updated">
			Updated by <strong>{{ selectedIncident.updated_admin_user_name }}</strong
			><br />
			on <strong class="choc-color">{{ dateFormatter(selectedIncident.updated) }}</strong>
		</div>

		<div style="margin-top: 25px">
			<app-file-uploader [fileUploadManager]="fileUploadManager"></app-file-uploader>
		</div>
	</div>

	<div
		[hidden]="isDeleting || isSaving || isEditing || isCreating || isShowingImages || isViewingAuditLog || isPickingReportType"
		*ngIf="dataLoaded"
		class="incd-list-block"
	>
		<div *ngFor="let incident of incidents">
			<div class="card rep-card">
				<div>
					<div>
						<div class="float-right">
							<div class="incd-icon-block">
								<i
									*ngIf="incident.audio_file"
									title="Play Audio"
									class="fa fa-volume-up incd-icon incd-play-icon"
									(click)="playAudio(incident)"
								></i>
								<!-- <i
									title="Show Report"
									class="fa fa-eye incd-icon incd-rep-icon"
									*ngIf="true || incident.report_json"
									(click)="showReport(incident)"
								></i> -->
								<i title="Copy Public Link" class="far fa-file-invoice incd-icon incd-link-icon" (click)="showPublicLinkDialog(incident)"></i>

								<i
									title="Manage Photos"
									class="fa fa-picture-o incd-icon incd-photo-icon"
									*ngIf="incident.imageFiles.length > 0"
									(click)="showImages(incident)"
								></i>
								<!-- <i title="Copy Public Link" class="far fa-link incd-icon incd-link-icon" (click)="showPublicLinkDialog(incident)"></i> -->
								<i title="Edit Report" class="fa fa-edit incd-icon incd-edit-icon" (click)="editReport(incident)"></i>
								<i title="Delete Report" class="fa fa-trash-o incd-icon incd-delete-icon" (click)="incident.confirmDelete = true"></i>
								<i
									title="Audit Records"
									class="far fa-database incd-icon incd-rep-icon"
									[class.incd-rep-orange]="incident.updated"
									(click)="viewAuditLog(incident)"
								></i>
							</div>
						</div>
						<div>
							<div class="rep-type" [class.flag-red]="incident.report_type === 'INCIDENT'">{{ reportTypeFormatter(incident) }}</div>
							<div *ngIf="incident.report_type === 'CUSTOM'" style="font-size: small; color: gray">
								{{ incident.customReportData?.reportData?.employeeChecklists[0]?.title }}
							</div>
						</div>
					</div>

					<div *ngIf="incident.confirmDelete" class="incd-delete-block">
						<hr class="sep-delete" />
						<div class="float-right">
							<button class="btn btn-sm btn-secondary" (click)="incident.confirmDelete = false">Cancel</button>&nbsp;&nbsp;
							<button class="btn btn-sm btn-danger" (click)="confirmDelete(incident)">Delete</button>
						</div>
						<div class="incd-delete-confirm">Confirm Delete</div>
						<!-- <hr class="sep-delete"> -->
					</div>
					<!-- <hr style="margin: 8px 0px" /> -->
				</div>

				<div class="incd-content-block">
					<div *ngIf="incident.mileage" style="margin-bottom: 14px">
						<div>
							<!-- <a class="edit-notes-link" href="#" (click)="editShiftReport(incident);false">edit notes</a> -->
							<!-- <div class="incd-date">{{ dateFormatter(incident.created) }}</div> -->
							<div class="incd-mileage"><span class="tts-hl-2" style="font-weight: 600">Mileage:</span> {{ incident.formattedMileage }}</div>
						</div>
					</div>

					<div *ngIf="incident.audio_file">
						<div *ngIf="!incident.isEditing" class="incd-transcript-prev">
							{{ incident.transcript ? '"' + previewFormatter(incident.transcript) + '"' : '&lt; no transcript available &gt;' }}
						</div>
					</div>

					<div *ngIf="!incident.isEditing && !incident.audio_file" class="incd-notes-prev">
						<div>
							<div class="incd-note-label">Admin Notes:</div>
							<div>{{ incident.notes ? previewFormatter(incident.notes) : '&lt; no notes available &gt;' }}</div>
						</div>
						<div *ngIf="incident.report_type === 'SHIFT'" style="margin-top: 10px">
							<div class="incd-note-label">Summary:</div>
							<div>{{ incident.summary ? previewFormatter(incident.summary) : '&lt; no summary available &gt;' }}</div>
						</div>
						<div *ngIf="incident.report_type !== 'SHIFT'" style="margin-top: 10px">
							<div class="incd-note-label">{{ incident.getSummaryLabel() }}:</div>
							<div>{{ incident.getSummaryNote() ? previewFormatter(incident.getSummaryNote()) : '&lt; no notes available &gt;' }}</div>
						</div>
					</div>
				</div>

				<!-- <hr style="margin: 15px 0px 5px 0px" /> -->

				<div class="incd-list-updated" *ngIf="incident.updated">
					Updated by Admin: <strong>{{ incident.updated_admin_user_name }}</strong
					><br />
					on <strong class="choc-color">{{ dateFormatter(incident.updated) }}</strong>
				</div>
				<div class="incd-list-updated" *ngIf="incident.admin_user_name">
					Created by Admin: <strong>{{ incident.admin_user_name }}</strong
					><br />
					on <strong class="choc-color">{{ dateFormatter(incident.created) }}</strong>
				</div>
				<div class="incd-list-updated" *ngIf="incident.employee_name && !incident.admin_user_name">
					Submitted by Employee: <strong>{{ incident.employee_name }}</strong
					><br />
					on <strong class="choc-color">{{ dateFormatter(incident.created) }}</strong>
				</div>

				<div *ngIf="incident.pinned" class="incd-list-pinned tts-hl-2">
					<hr style="margin-top: 5px" />
					<div><i class="fa fa-thumbtack"></i> Report pinned to bulletin board</div>
				</div>
			</div>
		</div>
		<div *ngIf="totalShiftMileage" style="margin-top: 15px">
			<div><span class="tts-hl-2" style="font-size: 1.2em">Total shift mileage:</span> {{ totalShiftMileage }}</div>
		</div>
		<!-- <div *ngIf="incidents.length > 0" style="color: gray; font-size: 1.2em; margin-top: 16px">
			<i class="fa fa-eye" style="color: #0275d8"></i> and <i class="far fa-link" style="color: #0275d8"></i> actions moved to
			<i class="far fa-file-invoice" style="color: #0275d8"></i>
		</div> -->
	</div>

	<div *ngIf="isShowingImages" class="image-list">
		<div *ngIf="selectedImageList.length === 0">
			<div style="margin-top: 50px; font-size: 1.4em; color: grey; text-align: center">No Attached Photos</div>
		</div>
		<div *ngFor="let image of selectedImageList; let idx = index">
			<div class="card img-card">
				<div *ngIf="image.hasError" class="img-prev-wrap">
					<div class="img-prev-box">
						<div *ngIf="image.hasError" class="image-error">image error</div>
					</div>
				</div>
				<div>
					<img
						class="image-preview"
						[hidden]="image.hasError"
						(click)="showFullScreen(image.key)"
						(error)="imageError(image)"
						[src]="imageUrl(image.key)"
					/>
					<input type="text" class="form-control" style="margin-top: 16px" [(ngModel)]="image.linkText" placeholder="Enter link text" />
					<textarea
						class="form-control img-description-textarea"
						[(ngModel)]="image.description"
						placeholder="Enter photo description"
					></textarea>
				</div>

				<button class="btn btn-sm btn-secondary btn-block" style="margin-top: 16px" (click)="removeImage(idx)">Remove</button>
			</div>
		</div>
	</div>
</div>
<!-- End Default Report Lister -->

<div *ngIf="isPickingReportType">
	<!-- <div class="pick-report-title">Select a report type</div> -->
	<div *ngFor="let option of reportOptions">
		<button class="btn btn-sm btn-outline-main btn-block" style="margin-top: 16px" (click)="createNewFormReport(option.type, option.recordId)">
			{{ option.label }}
		</button>
	</div>
</div>

<div *ngIf="editReportAction.isEditing">
	<app-trans-reports-edit
		[dialogManager]="dialogManager"
		[transId]="transId"
		[record]="editReportAction.record"
		[report]="editReportAction.report"
		[reportConfig]="editReportAction.reportConfig"
		(recordSaved)="editReportSaved()"
		(actionCancelled)="editReportCancelled()"
	>
	</app-trans-reports-edit>
</div>

<div *ngIf="isViewingAuditLog">
	<div *ngIf="viewAuditAction.isDataLoaded">
		<app-audit-log-diff [records]="viewAuditAction.records" [entry]="viewAuditAction.auditEntry"></app-audit-log-diff>
	</div>
	<div *ngIf="!viewAuditAction.isDataLoaded" class="loading-block"><i class="fas fa-loader fa-spin"></i> Loading Data...</div>
</div>

<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Link Options"
	[(visible)]="publicLinkAction.isDialogVisible"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div>
		<div style="margin: 10px 0px">
			<div *ngIf="!publicLinkAction.showConfirmation">
				<button class="btn btn-sm btn-block btn-outline-main btn-mb-8" (click)="viewReportBtnClicked()">View Report</button>
				<!-- <button class="btn btn-sm btn-block btn-outline-main btn-mb-8" (click)="copyPublicLinkBtnClicked()">Copy Public Link</button> -->
				<button class="btn btn-sm btn-block btn-outline-main" (click)="publicLinkAction.showConfirmation = true">Refresh Public Link</button>
			</div>
			<div *ngIf="publicLinkAction.showConfirmation">
				<div style="line-height: 1.2em; font-weight: 600">
					<span style="color: firebrick">Warning: </span>This will refresh the public link for this report and invalidate the original link.
					Public access to this report will only be available using the new link.
				</div>
				<div style="margin-top: 20px">
					<button class="btn btn-sm btn-outline-secondary" (click)="publicLinkAction.showConfirmation = false">Cancel</button>
					<button class="btn btn-sm btn-outline-danger float-right" (click)="confirmRefreshBtnClicked()">Continue</button>
				</div>
			</div>
		</div>
	</div>
</p-dialog>
