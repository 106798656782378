<div class="map-dialog">
	<div class="modal fade" id="checkpointMapModal" data-bs-keyboard="false" data-bs-backdrop="static">
		<div class="modal-dialog">
			<div class="modal-content">
				<!-- Modal Header -->
				<div class="modal-header" style="height: 45px; padding: 4px 15px">
					<h4 class="modal-title">
						Checkpoints
						<a href="#" class="view-link" (click)="toggleView()">{{ currentView === 'MAP' ? 'Show List' : 'Show Map' }}</a>
					</h4>
					<button type="button" style="margin-top: 10px; margin-right: 15px" class="close" (click)="onClose()" data-bs-dismiss="modal">
						&times;
					</button>
				</div>

				<div id="checkpointMap" [hidden]="!hasMarkers || currentView === 'LIST'" #gmap class="map-box"></div>

				<div *ngIf="!hasMarkers && currentView === 'MAP'" class="map-box">
					<div class="no-map-data">No Map Data</div>
				</div>

				<div [hidden]="currentView === 'MAP'" class="list-view map-box">
					<app-checkpoints-list
						#cpListComponent
						[transId]="transId"
						(showCheckpoint)="showCheckpoint($event)"
						(checkpointSaved)="recordUpdatedEventHandler()"
						(showAuditLogDialog)="showAuditLogDialog($event)"
					></app-checkpoints-list>
				</div>

				<div
					*ngIf="currentView === 'MAP' && currentCheckpoint && !checkpointHasGps"
					class="d-flex align-items-center justify-content-center no-gps-info-window"
				>
					<div>
						<div class="info-checkpoint-title">Checkpoint {{ currentCheckpointIndex + 1 }}</div>
						<div [innerHTML]="currentNoGpsScroller | sanitizer"></div>
						<div class="no-gps-footer">No GPS Info</div>
						<div class="map-iw-footer">{{ currentCheckpointTimestamp }}</div>
					</div>
				</div>

				<!-- Modal footer -->
				<div class="modal-footer">
					<!-- Right side footer items -->
					<div class="modal-footer-right">
						<div *ngIf="hasMarkers && currentView === 'MAP'">
							<i
								*ngIf="checkpoints.length > 1"
								title="Previous Checkpoint"
								class="fa fa-left link-text map-icon-cp-nav"
								(click)="previousCheckpoint()"
							></i>
							<i
								*ngIf="checkpoints.length > 1"
								title="Next Checkpoint"
								class="fa fa-right link-text map-icon-cp-nav"
								(click)="nextCheckpoint()"
							></i>
							<i title="Zoom Checkpoints" class="far fa-bullseye map-icon-zoom" (click)="zoomToFitAllMarkers()"></i>
						</div>

						<div *ngIf="currentView === 'LIST' && !cpListComponent?.currentCheckpoint && !cpListComponent?.isCreatingTour" class="cp-new-btn">
							<button *ngIf="canAdminCreateCheckpoints" class="btn btn-sm btn-success" (click)="newCheckpoint()">New</button>
							<!-- <button *ngIf="!canAdminCreateCheckpoints" class="btn btn-sm btn-secondary" (click)="onClose()">Close</button> -->
						</div>
					</div>

					<!-- Left side footer items -->
					<div class="modal-footer-left">
						<div style="font-size: 0.9em" style="margin-left: 15px; height: 25px">
							<div [hidden]="currentView === 'LIST'">
								<div *ngIf="jobSiteMarker" (click)="panToJobSiteMarker()" class="locateButton">
									<img src="/assets/img/marker-green.png" style="width: 16px; height: 24px" />
									<span style="margin-left: 5px">Job Site</span>
								</div>
								<div
									*ngIf="checkpointMarkers?.length > 0"
									class="checkpoint-btn"
									(click)="panToLastCheckpointMarker()"
									(mouseenter)="checkPointHover = true"
									(mouseleave)="checkPointHover = false"
								>
									<img src="/assets/img/marker-blue.png" style="width: 16px; height: 24px" />
									<span style="margin-left: 5px">{{ isDesktop && checkPointHover ? 'Last Checkpoint' : 'Checkpoints' }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Google Map Icon Maker
http://www.googlemapsmarkers.com/v1/A/002d99/ (with label)
http://www.googlemapsmarkers.com/v1/0099FF/ (without label) -->
