import { ChangeDetectorRef, Component } from '@angular/core'
import { log } from '@app/helpers'
import { CoreService } from '@app/services'

@Component({
    selector: 'app-global-audio-player',
    templateUrl: './global-audio-player.component.html',
    styleUrls: ['./global-audio-player.component.scss'],
    standalone: false
})
export class GlobalAudioPlayerComponent {
	showDialog = false
	currentAudioFile: HTMLAudioElement

	constructor(private coreSrvc: CoreService, private cd: ChangeDetectorRef) {
		this.coreSrvc.audioSrvc.playAudioUrl.subscribe((url) => {
			this.playAudioUrl(url)
		})
	}

	private playAudioUrl(url: string) {
		log('Global Play Audio Url', url)
		this.currentAudioFile = new Audio(url)
		this.showDialog = true
		this.currentAudioFile.play().catch((err) => {
			this.coreSrvc.notifySrvc.notify('info', 'No Audio', 'This file contains no recorded audio data.')
			this.showDialog = false
			this.cd.detectChanges()
		})
		this.currentAudioFile.onended = () => {
			this.showDialog = false
			this.cd.detectChanges()
		}
	}

	public stopPlayingAudio() {
		log('Stop playing audio')
		this.currentAudioFile.pause()
	}

	public stopAndClose() {
		this.currentAudioFile.pause()
		this.showDialog = false
	}
}
