import { Pipe, PipeTransform } from '@angular/core'

import { log, Helper, DateTimeHelper } from '@app/helpers'
import moment from 'moment-timezone'

@Pipe({
    name: 'durationInHoursAndMinutes',
    standalone: false
})
export class DurationInHoursAndMinutesPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		if (!value) {
			return ''
		}
		const duration = moment.duration(value)
		if (!duration) {
			return ''
		}
		return DateTimeHelper.formatDuration('H:mm', duration)
	}
}
