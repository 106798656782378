import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { DateTimeHelper, FormHelper, log } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'
import { ScheduleHelper } from '@app/helpers/schedule'
import { DialogManager, HelpDialogMessage, OverrideProtectionDialogManager, SelectorPermissionName } from '@app/models'
import { ScheduleLogRecord } from '@app/models/schedule-log'
import { CoreService, DatabaseService } from '@app/services'

import moment from 'moment-timezone'
import { SelectItem } from 'primeng/api'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-scheduler-shift-view-edit',
    templateUrl: './shift-view-edit.component.html',
    styleUrls: ['./shift-view-edit.component.scss'],
    standalone: false
})
export class SchedulerShiftViewEditComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() manager: DialogManager
	@Output() recordUpdated = new EventEmitter<boolean>()

	isUpdating = false
	is12HourFormat = DateTimeHelper.format12Hour

	schedLog: ScheduleLogRecord
	notesForScheduler: string = null

	form = new SchedulerShiftViewEditForm()

	overrideProtection = new OverrideProtectionDialogManager()

	// showShiftEnableHint = false // DEPRECATED - Moved to form-input-switch
	// showNotificationsEnableHint = false // DEPRECATED - Moved to form-input-switch

	public employeeOptions: SelectItem[]
	public selectedEmployee: number = null

	public origSchedInfo = {
		jobName: '',
		empName: '',
		start: '',
		end: '',
		empCount: 0,
		tzAbrev: null,
	}

	constructor(private coreSrvc: CoreService) {
		this.subs.add(this.coreSrvc.dbSrvc.lambdaSrvc.dataAccessErrorEvent.subscribe((event) => this.handleDataAccessErrorEvent(event)))
	}

	get devDetect() {
		return this.coreSrvc.devDetect
	}

	get hasEnabledStatusBeenModified(): boolean {
		return this.schedLog.enabled !== this.form.enabled
	}
	get hasScheduledEmployeeBeenModified(): boolean {
		return this.schedLog.employee_id !== this.selectedEmployee
	}

	get hasEmployeeCountBeenModified(): boolean {
		return this.schedLog.employee_count !== this.form.employee_count
	}

	get hasStartBeenModified(): boolean {
		return this.hasTimeChanged('start_time')
	}

	get hasEndBeenModified(): boolean {
		return this.hasTimeChanged('end_time')
	}

	get hasBeenModified(): boolean {
		// Check enabled status
		if (this.form.enabled !== this.schedLog.enabled) {
			return true
		}
		// Employee Cound
		if (this.form.employee_count !== this.schedLog.employee_count) {
			return true
		}
		// Check scheduled employee
		if (this.selectedEmployee !== this.schedLog.employee_id) {
			return true
		}
		// Check start/end times
		if (this.hasTimeChanged('start_time') || this.hasTimeChanged('end_time')) {
			return true
		}
		return false
	}

	private subs = new Subscription()

	ngOnInit(): void {
		const origSchedLog = this.manager.dialogData as ScheduleLogRecord
		log('Schedule Log', origSchedLog.id, origSchedLog)
		this.schedLog = origSchedLog.clone()
		this.form.configure(origSchedLog)

		this.setupEmployeeOptions()
		this.setupOriginalInfo()

		this.notesForScheduler = this.schedLog.comments
	}

	ngAfterViewInit(): void {
		log('AfterViewInit')
		setTimeout(() => {
			this.manager.submitBtnAction = () => this.submitBtnClicked()
			this.manager.canSubmit = () => this.isFormValid()
		}, 250)
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	private handleDataAccessErrorEvent(event) {
		// log('Schedule got overlap exception', event)
		const type = event?.errorType
		if (type === 'com.sst.db.tts.exceptions.OvertimeDetectedException') {
			const errorMsg = event?.errorMessage
			log('Error Message', errorMsg)
			this.coreSrvc.notifySrvc.notify('error', 'Overtime Exceeded', errorMsg)
			this.overrideProtection.type = 'OVERTIME'
			this.overrideProtection.showDialog = true
		}
		if (type === 'com.sst.db.tts.exceptions.VacationDetectedException') {
			const errorMsg = event?.errorMessage
			log('Error Message', errorMsg)
			this.coreSrvc.notifySrvc.notify('error', 'Time-Off Conflict', errorMsg)
			this.overrideProtection.type = 'TIMEOFF'
			this.overrideProtection.showDialog = true
		}
	}

	private setupEmployeeOptions() {
		const accessHelper = new AccessHelper(this.coreSrvc, 'schedule')
		const permissions = accessHelper.getPermissionsFor('schedule')
		const isRestricted = permissions.isSelectorRestrictedFor(SelectorPermissionName.employee)
		const isManager = this.coreSrvc.dbSrvc.settingSrvc.isUserAManager()

		const assignedEmp =
			this.schedLog.employee_id_override || this.schedLog.employee_id_override === 0
				? this.schedLog.employee_id_override
				: this.schedLog.employee_id
		this.selectedEmployee = assignedEmp

		// Add any employees that you might not own that were set by unrestricted user
		const addEmpIds = [assignedEmp] // this.manager.getOriginalEmployeeIds() || []
		addEmpIds.push(0) // Slip in Any Employee

		const dropdown = this.coreSrvc.dbSrvc.empSrvc
			.getEmployeeDropdown(this.coreSrvc.dbSrvc, isRestricted, isManager, addEmpIds)
			.filter((item) => item.data.active === true)

		// Uncomment when form supports creating log entries from scratch
		// dropdown.unshift({
		// 	label: `Select Employee`,
		// 	value: null,
		// 	data: null,
		// })
		this.employeeOptions = dropdown
	}

	private setupOriginalInfo() {
		// Setup Timezone Abrev
		this.origSchedInfo.tzAbrev = moment.tz(this.schedLog.job_date, this.schedLog.timezone).zoneAbbr()
		// Setup job name
		const jobId = this.schedLog.job_id
		this.origSchedInfo.jobName = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)?.description

		// Setup employee name
		const empId = this.schedLog.employee_id
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)
		this.origSchedInfo.empName = emp?.name || 'Unknown'
		this.origSchedInfo.empCount = this.schedLog.employee_count

		// Setup start/end times
		const format = DateTimeHelper.format12Hour ? 'h:mm a' : 'HH:mm'
		const start = this.schedLog.job_date + 'T' + this.schedLog.start_time
		const startString = moment(start).format('YYYY-MM-DDTHH:mm')
		this.origSchedInfo.start = moment(startString).format(format)
		const end = this.schedLog.job_date + 'T' + this.schedLog.end_time
		const endString = moment(end).format('YYYY-MM-DDTHH:mm')
		this.origSchedInfo.end = moment(endString).format(format)
	}

	public setStartEndPickerTime(startEnd: string, date: Date) {
		log('Set', startEnd, date)
		if (startEnd === 'start') {
			this.form.job_start = date
			this.form.start_time = moment(date).format('HH:mm')
		} else {
			this.form.job_end = date
			this.form.end_time = moment(date).format('HH:mm')
		}
	}

	private hasTimeChanged(prop: string) {
		return this.schedLog[prop] !== this.form[prop]
	}

	private hasJobTimeChanged(prop: string) {
		const timezone = this.schedLog.timezone
		const origTime = this.schedLog[prop]
		const formTime = this.form[prop]

		const origString = moment(origTime).tz(timezone).format('YYYY-MM-DDTHH:mm')
		const formString = moment(formTime).format('YYYY-MM-DDTHH:mm')

		return origString !== formString
	}

	public validateNumericInput() {
		const value: string = `${this.form.employee_count}`
		const intValue = parseInt(value, 10)
		if (intValue) {
			this.form.employee_count = intValue
		} else {
			this.form.employee_count = null
		}
	}

	public handleAssignmentChange() {
		if (this.selectedEmployee !== 0) {
			this.form.employee_count = 1
		}
	}

	public undo(prop: string) {
		if (prop === 'employee_id') {
			log('reset employee id')
			this.selectedEmployee = this.schedLog[prop]
			return
		}
		if (prop === 'start_time') {
			this.form.resetStartTime()
		}
		if (prop === 'end_time') {
			this.form.resetEndTime()
		}
		this.form[prop] = this.schedLog[prop]
	}

	public calculateShiftLength(source: string) {
		let result = ''
		if (source === 'ORIG') {
			const comps = ScheduleHelper.calculateShiftLength(
				this.schedLog.timezone,
				this.schedLog.job_date,
				this.schedLog.start_time,
				this.schedLog.end_time,
			)
			result = `${comps.hr}:${comps.min}`
		}
		if (source === 'FORM') {
			const comps = ScheduleHelper.calculateShiftLength(this.schedLog.timezone, this.schedLog.job_date, this.form.start_time, this.form.end_time)
			result = `${comps.hr}:${comps.min}`
		}
		return result === '0:00' ? 'Anytime' : result
	}

	private submitBtnClicked() {
		log('Submit button clicked')
		// Guard against double submission
		if (this.isUpdating) return
		// FormHelper.trimOnlyWhitespace(this.form)

		const record = this.prepareUpdateRecord()
		if (!record) {
			return
		}
		this.isUpdating = true
		this.coreSrvc.dbSrvc.updateRecord('schedule_log', record).then((success) => {
			log('editShift lambda returned')
			if (success) {
				this.recordUpdated.emit(true)
				this.manager.isDialogVisible = false
			} else {
				this.isUpdating = false
			}
		})
	}

	submitOverrideClicked() {
		if (this.overrideProtection.type === 'OVERTIME') {
			this.overrideProtection.allowOvertimeOverride = true
		}
		if (this.overrideProtection.type === 'TIMEOFF') {
			this.overrideProtection.allowTimeoffOverride = true
		}
		this.submitBtnClicked()
	}

	private isFormValid(): boolean {
		if (this.form.start_time === this.form.end_time) {
			if (this.schedLog.anytime) {
				return true
			} else {
				return false
			}
		}
		return true
	}

	private prepareUpdateRecord(): ScheduleLogRecord {
		const record = new ScheduleLogRecord(this.schedLog)
		const timezone = this.schedLog.timezone

		// Setup overrides
		record.enabled_override = this.hasEnabledStatusBeenModified ? this.form.enabled : null
		record.employee_id_override = this.hasScheduledEmployeeBeenModified ? this.selectedEmployee : null
		record.employee_count_override = this.hasEmployeeCountBeenModified ? this.form.employee_count : null
		record.enable_notifications = this.form.enable_notifications
		record.open_shift = this.form.open_shift

		if (this.hasStartBeenModified) {
			const jobStartTime = this.form.job_start
			const startLocal = moment(jobStartTime).format('YYYY-MM-DDTHH:mm')
			const jobStartLocalTime = moment(jobStartTime).format('HH:mm')
			const startMom = moment.tz(startLocal, 'YYYY-MM-DDTHH:mm', timezone)
			record.start_time_override = jobStartLocalTime
		} else {
			record.start_time_override = null
		}
		if (this.hasEndBeenModified) {
			const jobEndTime = this.form.job_end
			const endLocal = moment(jobEndTime).format('YYYY-MM-DDTHH:mm')
			const jobEndLocalTime = moment(jobEndTime).format('HH:mm')
			const endMom = moment.tz(endLocal, 'YYYY-MM-DDTHH:mm', timezone)
			record.end_time_override = jobEndLocalTime
		} else {
			record.end_time_override = null
		}

		// If the overtime exception is to be overridden, insert the flag here
		// If it's an open shift then automatically override
		if (this.overrideProtection.allowOvertimeOverride || record.open_shift) {
			record['ot_override'] = true
		}
		if (this.overrideProtection.allowTimeoffOverride || record.open_shift) {
			record['vacation_override'] = true
		}
		return record
	}

	toggleCheckbox(prop: string) {
		log('prop', prop)
		this.form[prop] = !this.form[prop]
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'open_shift':
				;(help.header = 'Open Shift'), (help.message = 'When selected, this shift will be designated as an open shift which needs to be filled.')
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}

class SchedulerShiftViewEditForm {
	schedLog: ScheduleLogRecord
	enabled = false
	job_start: Date = null
	start_time: string = null
	job_end: Date = null
	end_time: string = null
	employee_count: number = null
	enable_notifications: boolean = true
	open_shift: boolean = false

	configure(schedLog: ScheduleLogRecord) {
		log('Enabled Status', schedLog.enabled, schedLog.enabled_override)
		this.schedLog = schedLog
		// Setup enabled status
		this.enabled = schedLog.enabled_override || schedLog.enabled_override === false ? schedLog.enabled_override : schedLog.enabled

		// Setup start/end times
		const timezone = schedLog.timezone
		const start = schedLog.job_start // schedLog.job_start_override ? schedLog.job_start_override : schedLog.job_start
		const startString = moment(start).tz(timezone).format('YYYY-MM-DDTHH:mm')
		this.job_start = moment(startString).toDate()
		this.start_time = schedLog.start_time_override ? schedLog.start_time_override : schedLog.start_time

		const end = schedLog.job_end // schedLog.job_end_override ? schedLog.job_end_override : schedLog.job_end
		const endString = moment(end).tz(timezone).format('YYYY-MM-DDTHH:mm')
		this.job_end = moment(endString).toDate()
		this.end_time = schedLog.end_time_override ? schedLog.end_time_override : schedLog.end_time

		// Setup employee count
		this.employee_count = schedLog.employee_count_override ? schedLog.employee_count_override : schedLog.employee_count

		this.enable_notifications = schedLog.enable_notifications
		this.open_shift = schedLog.open_shift
	}

	resetStartTime() {
		const start = this.schedLog.job_date + 'T' + this.schedLog.start_time
		const startString = moment(start).format('YYYY-MM-DDTHH:mm')
		this.job_start = moment(startString).toDate()
		this.start_time = this.schedLog.start_time
	}

	resetEndTime() {
		const end = this.schedLog.job_date + 'T' + this.schedLog.end_time
		const endString = moment(end).format('YYYY-MM-DDTHH:mm')
		this.job_end = moment(endString).toDate()
		this.end_time = this.schedLog.end_time
	}
}
