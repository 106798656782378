import { Component, EventEmitter, Output } from '@angular/core'
import { DropdownHelper, DropdownHelperConfig, log } from '@app/helpers'
import { IncidentLogViewManager } from '@app/models'
import { CoreService } from '@app/services'
import { SelectItem } from 'primeng/api'

import moment from 'moment-timezone'
import { AccessHelper } from '@app/helpers/access'

@Component({
    selector: 'app-shift-report-table-filter-bar',
    templateUrl: './shift-report-table-filter-bar.component.html',
    styleUrl: './shift-report-table-filter-bar.component.scss',
    standalone: false
})
export class ShiftReportTableFilterBarComponent {
	accessHelper: AccessHelper

	@Output() filterUpdated = new EventEmitter<boolean>()

	jobOptions: SelectItem[]
	empOptions: SelectItem[]
	tzOptions: SelectItem[]

	tzSelectAction = { isVisible: false }

	filterBarLabel = 'Shift Reports'

	constructor(private coreSrvc: CoreService) {
		this.setupAccessPermissions()
		this.setupDropdowns()
	}

	get viewManager(): IncidentLogViewManager {
		return this.coreSrvc.dbSrvc.incidentLogSrvc.viewManager
	}

	get devDetect() {
		return this.coreSrvc.devDetect
	}

	get currentTzAbrev(): string {
		const filterStartDate = this.viewManager.filterStartDate
		const dayViewDate = this.viewManager.dayViewDate
		const useDate = filterStartDate ? filterStartDate : dayViewDate ? dayViewDate : new Date()

		return moment(useDate).tz(this.viewManager.timezone).zoneAbbr()
	}

	private setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'incident')
		this.accessHelper.updateSupervisorIds()
	}

	setupDropdowns() {
		this.jobOptions = this.makeJobsDropdown() // this.coreSrvc.dbSrvc.jobSrvc.getDropdownData()
		this.empOptions = this.makeEmployeeDropdown() // this.coreSrvc.dbSrvc.empSrvc.getDropdownData()
		const timezones = this.coreSrvc.dbSrvc.settingSrvc
			.getSupportedTimezones()
			.filter((tz) => tz.active)
			.map((tz) => {
				return { label: tz.display_name ? tz.display_name : tz.zone_name, value: tz.zone_name }
			})
		timezones.unshift({ label: 'Automatic', value: null })
		this.tzOptions = timezones
	}

	makeEmployeeDropdown(): Array<SelectItem> {
		const config = new DropdownHelperConfig(this.coreSrvc.dbSrvc, 'DROPDOWN')
		config.isRestricted = this.accessHelper.isRestricted
		config.includeInactive = true
		const dropdownHelper = new DropdownHelper(config)
		dropdownHelper.nullOptionLabel = 'All Employees'
		const options = dropdownHelper.buildEmployeeMenuOptions()
		return options
	}

	makeJobsDropdown(): Array<SelectItem> {
		const config = new DropdownHelperConfig(this.coreSrvc.dbSrvc, 'DROPDOWN')
		config.isRestricted = this.accessHelper.isRestricted
		config.includeInactive = true
		const dropdownHelper = new DropdownHelper(config)
		dropdownHelper.nullOptionLabel = 'All Jobs'
		const options = dropdownHelper.buildJobMenuOptions()
		return options
	}

	public clearMenu(menu: 'JOB' | 'EMP') {
		switch (menu) {
			case 'JOB':
				this.viewManager.selectedJobId = null
				break
			case 'EMP':
				this.viewManager.selectedEmpId = null
				break
		}
	}

	public updateFilterBtnClicked() {
		log('updateFilterBtnClicked()')
	}

	public updateTimezone(timezone: string) {
		log('Update', timezone)
		this.tzSelectAction.isVisible = false
		if (timezone === null) {
			const defaultTimezone = this.coreSrvc.dbSrvc.settingSrvc.getCompany().timezone
			this.viewManager.timezone = defaultTimezone
		} else {
			this.viewManager.timezone = timezone
		}
		this.filterUpdated.next(true)
	}

	public openTzSelectDialog() {
		this.tzSelectAction.isVisible = true
	}
}
