<div class="modal fade" tabindex="-1" [id]="dialogManager.modalId" data-bs-keyboard="false" data-bs-backdrop="static" data-bs-focus="false">
	<!-- <div #modalElement class="modal-dialog" [class.tts-mobile-dialog]="isMobile" style="max-width: 450px" appDraggable handle=".modal-header"> -->
	<div #modalElement class="modal-dialog" [class.tts-mobile-dialog]="isMobile" style="max-width: 450px">
		<div class="modal-content" [class.tts-mobile-content]="isMobile">
			<!-- Modal Header -->
			<div class="modal-header">
				<div *ngIf="!dialogManager.isBackBtnVisible" class="modal-title title-text">
					{{ dialogManager.headerLabel }}
				</div>
				<div *ngIf="dialogManager.isBackBtnVisible" class="modal-title back-btn" (click)="backBtnClicked()">
					<i class="fa fa-chevron-left"></i> Back
				</div>
				<div class="upper-right">
					<div class="help-video"><app-help-video-link [key]="dialogManager.helpVideoKey"></app-help-video-link></div>
				</div>
			</div>

			<!-- Modal body -->
			<div class="modal-body">
				<ng-content></ng-content>
			</div>

			<!-- Modal footer -->
			<div class="modal-footer">
				<div class="float-right" style="margin-top: -3px">
					<button
						*ngIf="dialogManager.isCancelBtnVisble"
						class="btn btn-sm btn-secondary modal-btn"
						[disabled]="!dialogManager.canCancel()"
						(click)="cancelBtnClicked()"
					>
						{{ dialogManager.cancelBtnLabel }}
					</button>
					<button
						*ngIf="dialogManager.isSubmitBtnVisible"
						class="btn btn-sm btn-success modal-btn"
						style="margin-left: 12px"
						[disabled]="!dialogManager.canSubmit()"
						(click)="submitBtnClicked()"
					>
						{{ dialogManager.submitBtnLabel }}
					</button>
				</div>
				<!-- <div *ngIf="dialogManager.isFooterLabelVisible" class="modal-footer-left" style="font-size: .9em">
					<div class="modal-footer-label">
						<strong>{{ dialogManager.footerLabel }}</strong>
					</div>
				</div> -->
			</div>
		</div>
	</div>
</div>
