import { DateTimeHelper } from '@app/helpers/datetime'
import { ImageFile } from './image'
import { log } from '@app/helpers/logger'

export type CheckpointType = 'CHECKIN' | 'SHIFT' | 'CHECKOUT'

export type CheckpointSourceType = 'ADMIN' | 'EMP_APP' | 'IVR_LINK' | 'IVR_LANDLINE' | 'SMS' | 'KIOSK'

export class Checkpoint {
	tableName = 'checkpoints' // local tracking field

	type: CheckpointType = null
	client_error: string = null
	comments: string = null
	company_id: number = null
	created: string = null
	updated: string = null
	admin_created: string = null
	employee_id: number = null
	geo_address: string = null
	geo_latitude: number = null
	geo_longitude: number = null
	group: number = null
	id: number = null
	waypoint_name: string = null
	waypoint_uuid: string = null
	images_json: string = null
	source: CheckpointSourceType = null
	transaction_log_id: number = null
	tour_sequence_number = 1

	// Local state management - not part of db
	showImages = false
	imageList: Array<ImageFile> = []

	// origComments: string = null
	isEditing = false
	isSelected = false

	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.imageList = this.getImages()
			// this.origComments = this.comments
			this.created = DateTimeHelper.stripUtcTag(record.created)
			this.updated = DateTimeHelper.stripUtcTag(record.updated)
			this.admin_created = DateTimeHelper.stripUtcTag(record.admin_created)

			if (this.images_json && this.images_json.includes('87949cf5-8960-4460-b9cb-e9aee5b683ac')) {
				this.geo_latitude = null
				this.geo_longitude = null
			}
		}
	}

	get effectiveDateString(): string {
		return this.admin_created ? this.admin_created : this.created
	}

	getImages(): Array<ImageFile> {
		const json = this.images_json
		if (!json) {
			return []
		}
		const imagesPayload = JSON.parse(json)
		if (imagesPayload) {
			const files = imagesPayload.files
			if (files && files.length > 0) {
				return imagesPayload.files.map((rec) => new ImageFile(rec))
			} else {
				return []
			}
		} else {
			return []
		}
	}
}
