<app-activity-banner [isProcessing]="!isDataLoaded" [message]="'Loading Record ...'"></app-activity-banner>

<div *ngIf="isDataLoaded" [hidden]="confirmDelete || showRepeatWizard">
	<!-- No waypoints - only repeating interval available -->

	<div *ngIf="!tour?.type" class="alert alert-dialog">
		<!-- <div *ngIf="!currentWaypointVm && waypoints.length === 0" [hidden]="tour.type === 'STRUCTURED_HOUR_START'" class="alert alert-dialog"> -->
		<div *ngIf="waypoints.length === 0">
			<div class="options-title tts-hl-2">Select Checkpoint Method</div>

			<div style="margin: 18px 0px">
				<!-- Repeating Checkpoints -->
				<div class="form-check">
					<input
						class="form-check-input tour-type-radio"
						type="radio"
						name="basicCheckpoints"
						id="basicCheckpoints"
						[checked]="tour.type === 'UNSTRUCTURED'"
						(change)="setCheckpointMethod('UNSTRUCTURED')"
					/>
					<div class="form-label-block">
						<div class="tour-type-label tour-available">
							<label for="basicCheckpoints">Repeating Checkpoints </label>
						</div>
						<div class="tour-desc-label" (click)="setCheckpointMethod('UNSTRUCTURED')">
							Simple repeating checkpoints every half-hour, hour, or at any interval you choose. Tour timing starts when an employee clocks in
							for their shift.
						</div>
					</div>
				</div>

				<!-- Hourly Tours -->
				<div class="form-check" style="margin-top: 12px">
					<input
						class="form-check-input tour-type-radio"
						type="radio"
						name="hourBasedTour"
						id="hourBasedTour"
						[checked]="tour.type === 'STRUCTURED_HOUR_START'"
						(change)="setCheckpointMethod('STRUCTURED_HOUR_START')"
					/>
					<div class="form-label-block">
						<div class="tour-type-label tour-available">
							<label for="hourBasedTour">Hourly Tour </label>
						</div>
						<div class="tour-desc-label" (click)="setCheckpointMethod('STRUCTURED_HOUR_START')">
							A stop-by-stop tour which restarts at the top of every hour and repeats throughout the day, from midnight to midnight. Tours are
							shared by any employees clocked in for the job.
						</div>
					</div>
				</div>

				<!-- Daily Tours -->
				<div class="form-check" [class.advanced-tour-block]="!enableAdvancedTours" style="margin-top: 12px">
					<input
						class="form-check-input tour-type-radio"
						type="radio"
						name="dayBasedTour"
						id="dayBasedTour"
						[disabled]="!enableAdvancedTours"
						[checked]="tour.type === 'STRUCTURED_DAY_START'"
						(change)="setCheckpointMethod('STRUCTURED_DAY_START')"
					/>
					<div class="form-label-block">
						<div class="tour-type-label" [class.tour-available]="enableAdvancedTours">
							<label for="dayBasedTour">Daily Tour </label>
							<i *ngIf="!enableAdvancedTours" title="Footnote" class="fas fa-bolt footnote-icon"></i>
						</div>
						<div class="tour-desc-label" (click)="setCheckpointMethod('STRUCTURED_DAY_START')">
							A stop-by-stop tour which can last any length of time and repeat at any interval you choose over the course of a day. Tours are
							shared by any employees clocked in for the job.
						</div>
					</div>
				</div>

				<!-- Shift-timed Tours -->
				<div class="form-check" [class.advanced-tour-block]="!enableAdvancedTours" style="margin-top: 12px">
					<input
						class="form-check-input tour-type-radio"
						type="radio"
						name="shiftBasedTour"
						id="shiftBasedTour"
						[disabled]="!enableAdvancedTours"
						[checked]="tour.type === 'STRUCTURED'"
						(change)="setCheckpointMethod('STRUCTURED')"
					/>
					<div class="form-label-block">
						<div class="tour-type-label" [class.tour-available]="enableAdvancedTours">
							<label for="shiftBasedTour">Shift-timed Tour </label>
							<i *ngIf="!enableAdvancedTours" title="Footnote" class="fas fa-bolt footnote-icon"></i>
						</div>
						<div class="tour-desc-label" (click)="setCheckpointMethod('STRUCTURED')">
							A stop-by-stop tour which can last any length of time and repeat at any interval you choose over the course of a shift. A new
							tour is setup and timing starts when an employee clocks in.
						</div>
					</div>
				</div>
			</div>

			<!-- Advanced tour types blocking note -->
			<div *ngIf="!enableAdvancedTours" style="font-weight: 700; line-height: 1.4rem">
				<i title="Footnote" class="fas fa-bolt footnote-icon" style="margin-left: 0px; margin-right: 6px"></i>Please contact
				<a href="mailto:support@telephonetimesheets.com">support</a> if you would like to enable our advanced tour options.
			</div>
		</div>
	</div>

	<!-- Waypoints provided - All options available -->
	<!-- <div id="tour-header" *ngIf="waypoints.length > 0" style="margin-top: 20px">
		<div *ngIf="!currentWaypointVm">
			<div style="padding: 0px 0px">
				<hr />
						<div class="form-label-bold" style="margin-top: 16px; font-weight: 600; color: #887a56">
							Repeat this tour every
							<input
								type="tel"
								class="form-control form-inline num-input"
								[class.input-error]="hasRepeatError"
								style="width: 60px"
								[(ngModel)]="tour.repeatInterval"
								(change)="validateInt('repeatInterval')"
							/>
							min
						</div>
				<div *ngIf="waypoints.length > 1" style="margin-top: 12px">
							<hr />
							<div class="float-right" style="text-align: center; font-size: 18px; font-weight: 600">
								<div>
									<i *ngIf="previousStartOffset.length > 0" class="far fa-undo link-text" (click)="undoOffsetAdjustment()"></i>
									<button id="set-btn" class="outline-btn adjust-btn" style="margin-left: 12px" (click)="adjustStart()">set</button>
								</div>
							</div>
							<div class="adjust-offset-wrap">
								Start this tour
								<input
									id="adjust-input"
									#offsetAdjust
									type="number"
									inputmode="numeric"
									class="form-control form-inline num-input"
									style="width: 75px"
									(click)="offsetAdjust.select()"
									[(ngModel)]="offsetAdjustment"
									(keydown.enter)="adjustStart()"
								/>
								min after the shift begins
								<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('adjust_offsets')"></i>
							</div>
						</div>
			</div>
		<button class="btn btn-sm btn-block btn-outline-secondary">Change Order</button>
		</div>
	</div> -->

	<div
		*ngIf="!currentWaypointVm && isInternalUser && tour.type && (tour.type === 'STRUCTURED' || tour.type === 'STRUCTURED_DAY_START')"
		style="margin-bottom: 22px"
	>
		<p-select appScrollLockSelect id="tourType" [options]="tourTypeOptions" [(ngModel)]="tour.type" (onChange)="tourTypeChanged()"></p-select>
	</div>

	<div *ngIf="!currentWaypointVm && tour.type">
		<div class="alert alert-warning">
			<div>
				<div>
					To manage alerts for missed checkpoints, visit the <strong>Notifications</strong> section<span *ngIf="profile.name">
						and edit the profile named <strong>{{ profile.name }}</strong> or assign a new profile.</span
					><span *ngIf="!profile.name"> and assign a profile.</span>
				</div>
			</div>
			<div *ngIf="profile.name" class="np-status-block" style="margin-top: 16px">
				<hr />
				<div>
					<span class="float-right np-status" [class.np-status-on]="profile.notifyEmp">{{ profile.notifyEmp ? 'ON' : 'OFF' }}</span>
					<span [class.clr-green]="profile.notifyEmp">Employee notifications</span>
				</div>
				<div style="margin-top: 8px">
					<span class="float-right np-status" [class.np-status-on]="profile.notifySup">{{ profile.notifySup ? 'ON' : 'OFF' }}</span>
					<span [class.clr-green]="profile.notifySup">Supervisor notifications</span>
				</div>
			</div>

			<div *ngIf="hasRepeatError || hasOrderError" style="color: firebrick; line-height: 1.5em">
				<hr />
				<div *ngIf="hasRepeatError">
					Repeating Interval Issues
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('repeat_error')"></i>
				</div>
				<div *ngIf="hasOrderError">
					Checkpoint Offset / Ordering Issues
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('order_error')"></i>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="accessedFromTourTable && tour.type && !currentWaypointVm" style="margin-bottom: 22px">
		<div class="options-block" [class.input-required]="!tour.description">
			<div class="options-title-inline">Name</div>
			<span *ngIf="!tour.description" class="float-right">(required)</span>

			<input type="text" class="form-control" [(ngModel)]="tour.description" />
		</div>
		<div class="options-block">
			<div class="options-title">Linked Jobs</div>
			<app-multi-select-details
				[type]="'JOB'"
				placeholder="Select Jobs"
				selectedItemsLabel="Jobs Selected: {0}"
				[options]="linkedJobOptions"
				[model]="tour.jobIds"
				(modelChange)="linkedJobsChanged($event)"
			>
			</app-multi-select-details>
		</div>

		<div *ngIf="alert.linkedJobsCount > 1" style="margin-top: 10px">
			<div class="alert alert-danger">This tour is linked to multiple jobs and any changes will affect all linked jobs.</div>
		</div>
	</div>

	<!-- Repeating Checkpoints -->
	<div *ngIf="tour.type === 'UNSTRUCTURED'" style="margin: 20px 0px 20px 0px">
		<div class="form-label-bold expect-label" [class.input-required]="!tour.repeatInterval">
			Expect checkpoints every
			<input type="tel" class="form-control form-inline num-input" [(ngModel)]="tour.repeatInterval" (change)="validateInt('repeatInterval')" />
			min
		</div>
		<button class="btn btn-sm btn-block btn-outline-danger" style="margin-top: 22px" (click)="clearTourAndStartOver()">
			Clear Tour and Start Over
		</button>
	</div>

	<!-- Hour based tours -->
	<!-- <div *ngIf="tour.type === 'STRUCTURED_HOUR_START'">
		<div style="margin: 25px 0px 0px 0px">
			<div class="float-right" style="width: 150px">
				<app-date-time-picker
					[currentDate]="hourBased.startTime"
					[pickerType]="'TIME'"
					[pickerMode]="'dialog'"
					[pickerLabel]="'Start Time'"
					[stepMinute]="30"
					[format]="hourBased.timeFormat"
					[hour12Timer]="true"
					(pickerDidClose)="setTourStartTimeFromPicker($event)"
				>
				</app-date-time-picker>
			</div>
			<div class="options-section-header" style="display: inline-block">First tour starts at</div>
		</div>

		<div style="margin: 20px 0px 20px 0px">
			<div class="options-section-header" style="display: inline-block">The tour lasts no more than</div>
		</div>

		<div class="options-block options-block-radio-lg">
			<div class="form-check form-check-inline" (click)="updateTourLengthInterval(30); validateHourBasedRadioBtns()">
				<input type="radio" class="form-check-input block-events" [value]="30" [ngModel]="tour.tourLength" />
				<label class="form-check-label has-pointer" style="font-weight: bold">30 minutes</label>
			</div>
			<div class="form-check form-check-inline" style="margin-left: 20px" (click)="updateTourLengthInterval(60); validateHourBasedRadioBtns()">
				<input type="radio" class="form-check-input block-events" [value]="60" [ngModel]="tour.tourLength" />
				<label class="form-check-label has-pointer" style="font-weight: bold">60 minutes</label>
			</div>
		</div>

		<div>
			<div style="margin: 15px 0px 20px 0px">
				<div class="options-section-header" style="display: inline-block">and repeats every</div>
			</div>

			<div class="options-block options-block-radio-lg">
				<div class="form-check form-check-inline" (click)="updateTourRepeatInterval(30); validateHourBasedRadioBtns()">
					<input
						type="radio"
						[disabled]="tour.tourLength === 60"
						class="form-check-input block-events"
						[value]="30"
						[ngModel]="tour.repeatInterval"
					/>
					<label class="form-check-label has-pointer" style="font-weight: bold">30 minutes</label>
				</div>
				<div class="form-check form-check-inline" style="margin-left: 20px" (click)="updateTourRepeatInterval(60); validateHourBasedRadioBtns()">
					<input type="radio" class="form-check-input block-events" [value]="60" [ngModel]="tour.repeatInterval" />
					<label class="form-check-label has-pointer" style="font-weight: bold">60 minutes</label>
				</div>
			</div>
		</div>

		<div style="margin: 15px 0px 20px 0px">
			<div class="options-section-header" style="display: inline-block">from tour start to tour start</div>
		</div>
	</div> -->

	<div
		*ngIf="tour.type === 'STRUCTURED' || tour.type === 'STRUCTURED_DAY_START' || tour.type === 'STRUCTURED_HOUR_START'"
		[class.list-top-margin]="!currentWaypointVm"
	>
		<div *ngFor="let wpVm of waypoints; let idx = index">
			<app-tour-waypoint
				*ngIf="currentWaypointVm ? currentWaypointVm === wpVm : true"
				[wpVm]="wpVm"
				[idx]="idx"
				[timezone]="timezone"
				[tourType]="tour.type"
				[listLength]="waypoints.length"
				[linkedJobOptions]="waypointGpsJobOptions"
				[showGetGpsBtn]="accessedFromTourTable ? true : false"
				(moveDown)="moveDown($event)"
				(moveUp)="moveUp($event)"
				(editWaypoint)="editWaypoint($event)"
				(cloneWaypoint)="cloneWaypoint($event)"
				(deleteWaypoint)="deleteWaypoint($event)"
				(needsValidationCheck)="needsValidationCheck()"
				(inputFocused)="wpOffsetInputFocused($event)"
				(inputBlurred)="wpOffsetInputBlurred($event)"
			></app-tour-waypoint>
		</div>

		<!-- Free-form Edit mode OR individual checkboxes to enable/disable validation and freeze checkpoint offset times -->
		<!-- <div *ngIf="!currentWaypointVm" style="margin: 24px 0px">
			<div class="form-check" *ngIf="waypoints.length > 1">
				<input
					class="form-check-input free-form-checkbox"
					style="color: black"
					type="checkbox"
					value=""
					id="freeformEditMode"
					[(ngModel)]="freeFormEditMode"
					(change)="freeFormEditModeChanged()"
				/>
				<label class="form-check-label" for="freeformEditMode"> Enable free-form edit mode </label>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('freeformEditMode')"></i>
			</div>

			<div *ngIf="false && waypoints.length > 1" class="form-check">
				<input class="form-check-input" style="color: black" type="checkbox" value="" id="swapOffsetTimes" [(ngModel)]="freezeOffsetTimes" />
				<label class="form-check-label" for="swapOffsetTimes"> Freeze checkpoint offset times </label>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('freeze_offsets')"></i>
			</div>

			<div *ngIf="false" class="form-check">
				<input
					class="form-check-input"
					type="checkbox"
					value=""
					id="enableValidation"
					[(ngModel)]="enableValidation"
					(change)="validationCheckboxChanged()"
				/>
				<label class="form-check-label" for="enableValidation"> Enable checkpoint validation </label>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('enaable_validation')"></i>
			</div>
		</div> -->
	</div>

	<!-- Rest interval input -->
	<div *ngIf="tour.type === 'STRUCTURED' && waypoints.length > 0 && !currentWaypointVm" style="margin: 20px 0px 25px 0px">
		<div class="form-label-bold expect-label" style="line-height: 2rem">
			<span>Rest for</span>
			<input
				type="tel"
				class="form-control form-inline num-input"
				style="margin: 0px 8px"
				[(ngModel)]="tour.repeatInterval"
				(change)="validateInt('repeatInterval')"
				(focus)="setRestIntervalInputFocused()"
				(blur)="setRestIntervalInputBlurred()"
			/>
			<span>minutes after last checkpoint before repeating tour</span>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('repeatIntervalStructured')"></i>
			<!-- <i
				*ngIf="waypoints.length > 1"
				title="Repeat Wizard"
				class="far fa-wand-sparkles link-text"
				style="margin-left: 8px"
				(click)="startRepeatWizard()"
			></i> -->
		</div>
	</div>

	<!-- Advanced tour action buttons -->
	<div *ngIf="tour.type === 'STRUCTURED' || tour.type === 'STRUCTURED_DAY_START' || tour.type === 'STRUCTURED_HOUR_START'" style="margin-top: 15px">
		<div *ngIf="!currentWaypointVm">
			<button class="btn btn-sm btn-block btn-outline-success" style="margin-bottom: 12px" (click)="addNewWaypoint()">
				Add {{ waypoints.length > 0 ? 'Another' : 'a' }} Checkpoint
			</button>

			<button *ngIf="waypoints.length > 0" class="btn btn-sm btn-block btn-outline-danger" (click)="clearTourAndStartOver()">
				Clear Tour and Start Over
			</button>
		</div>

		<div *ngIf="waypoints.length === 0" class="tour-icon-wrap">
			<i class="fa-duotone fa-map-location-dot"></i>
		</div>
	</div>

	<div *ngIf="useRepeatIntervalPicker && tour.type === 'STRUCTURED_DAY_START' && waypoints.length > 0 && !currentWaypointVm">
		<div *ngIf="tour.repeatInterval" class="repeat-info">
			<div class="float-right" style="margin-left: 14px">
				<span class="link-text" (click)="startRepeatWizard()">Adjust</span>
			</div>
			<div>
				<div *ngIf="tour.repeatInterval && tour.repeatInterval > 0">
					<div *ngIf="!wpInputHasFocus">Tour repeats every {{ tourRepeatDurationInMInStr }}</div>
					<div *ngIf="wpInputHasFocus" [class.repeat-info-editmode]="wpInputHasFocus">Edit mode enabled ...</div>
				</div>
				<div *ngIf="tour.repeatInterval && tour.repeatInterval < 0" style="color: firebrick">Invalid repeat interval</div>
			</div>
		</div>
		<div class="repeat-info">
			<span *ngIf="!tour.repeatInterval" class="link-text" (click)="startRepeatWizard()"> Set a repeat interval for this tour </span>
		</div>
	</div>

	<div *ngIf="repeatIntervalCleared" class="alert alert-danger" style="margin: 18px 0px">
		A configuration change caused the repeat interval to be invalid. Please setup a new repeat interval if you want this tour to repeat.
	</div>

	<div *ngIf="!currentWaypointVm && tour.repeatInterval < 0" class="alert alert-danger" style="margin: 18px 0px; line-height: 1.3rem">
		This tour is set to repeat every <strong>{{ tourRepeatDurationInMInStr }}</strong> but the tour length is
		<strong>{{ tourDurationInMinStr }}</strong
		>. To fix this, either make the repeat interval longer or shorten the tour.
	</div>

	<!-- DEBUG INFO -->
	<!-- <div *ngIf="environment.development" style="margin: 18px 0px; font-weight: 600; color: chocolate">
		<div>lastTourDurationInMin: {{ lastTourDurationInMin }} minutes</div>
		<div>Length of Tour (start-end): {{ tourDurationInMin }} minutes</div>
		<div>Tour repeatInterval: {{ tour.repeatInterval }} minutes</div>
		<div>Length w/Repeat: {{ tourRepeatDurationInMin }} minutes</div>
		<hr />
		<h3>Tour Object</h3>
		<div>
			<code>
				<pre>{{ tour | json }}</pre>
			</code>
		</div>
	</div> -->

	<!-- Tour Schedule View -->
	<div
		*ngIf="(tour.type === 'STRUCTURED_HOUR_START' || tour.type === 'STRUCTURED_DAY_START') && waypoints.length > 0"
		[hidden]="currentWaypointVm || tour.repeatInterval < 0"
		style="margin-top: 25px"
	>
		<app-tour-schedule-view
			#scheduleView
			[tour]="tour"
			[waypoints]="waypoints"
			[tourRepeatDurationInMin]="tourRepeatDurationInMin"
		></app-tour-schedule-view>
	</div>
</div>

<!-- <div>
	<pre><code>{{ repeatWizard | json }}</code></pre>
</div> -->

<div *ngIf="showRepeatWizard">
	<div class="alert alert-info alert-bg-gray wiz-desc">
		This value determines how often the tour repeats and must be longer than the current tour length of
		<span class="tts-hl-1">{{ repeatWizard.tourDurInMinStr }}</span
		>.
	</div>

	<div class="wiz-title">Repeat tour every</div>

	<div style="text-align: center; margin: 30px 0px">
		<div class="wiz-opt" style="width: 84px">
			<p-select
				appScrollLockSelect
				id="repeatWizHrs"
				[scrollHeight]="'340px'"
				[options]="repeatWizard.hourOptions"
				[(ngModel)]="repeatWizard.selectedHours"
			></p-select>
		</div>
		<span style="margin-left: 8px; font-weight: 700">hours</span>
		<div class="wiz-opt" style="margin: 0px 20px"></div>
		<div class="wiz-opt" style="width: 84px">
			<p-select appScrollLockSelect id="repeatWizMin" [options]="repeatWizard.minuteOptions" [(ngModel)]="repeatWizard.selectedMinutes"></p-select>
		</div>
		<span style="margin-left: 8px; font-weight: 700">minutes</span>
	</div>

	<div class="wiz-btns" style="margin-top: 24px">
		<div class="d-flex justify-content-between">
			<button class="btn btn-sm btn-block btn-outline-danger" style="margin-right: 6px" (click)="clearTourRepeatInterval()">Clear Interval</button>
			<button class="btn btn-sm btn-block btn-outline-success" style="margin-left: 6px" (click)="calculateTourRepeatIntervalFromPicker()">
				Set Interval
			</button>
		</div>

		<button class="btn btn-sm btn-block btn-outline-secondary" style="margin-top: 12px" (click)="cancelRepeatWizard()">Back to Tour</button>
	</div>
</div>

<div *ngIf="confirmDelete" class="confirm-delete-wrap">
	<div style="margin-bottom: 40px">Are you sure? This action cannot be undone.</div>
	<div class="d-flex justify-content-between">
		<button class="btn btn-sm btn-block btn-outline-secondary btn-confirm" (click)="cancelDeleteConfirmation()">Cancel</button>
		<button class="btn btn-sm btn-block btn-outline-danger btn-confirm" (click)="conrirmDeleteBtnClicked()">Delete</button>
	</div>
</div>

<!-- Debugging Support -->
<div *ngIf="true && !showRepeatWizard" style="margin-top: 30px">
	<!-- <h3>View Waypoints</h3>
	<div>
		<code>
			<pre>{{ waypoints | json }}</pre>
		</code>
	</div> -->

	<!-- <hr /> -->

	<!-- <h3>Tour Record</h3>
	<div>
		<pre><code>{{ tourRecord | json }}</code></pre>
	</div>

	<hr /> -->

	<!-- <h3>Tour Object</h3>
	<div>
		<code>
			<pre>{{ tour | json }}</pre>
		</code>
	</div> -->

	<!-- <hr />
	<h3>DialogData</h3>
	<div>
		<pre><code>{{ dialogManager.dialogData | json }}</code></pre>
	</div> -->
</div>
