export class NotificationProfile {
	table = 'notification_profile'

	id: number
	company_id: number
	owner_id: number
	name: string

	client_phone_e164: string
	client_email: string
	supervisor_phone_e164: string
	supervisor_email: string
	checkpoint_frequency = 'PT1H'

	//////////////
	// Employee //
	//////////////

	// Notify employee for upcoming shift
	call_employee_preshift = false
	sms_employee_preshift = false
	emp_notification_preshift_interval = 'PT1H'

	// Notify employee for late check in
	call_employee_late_in = false
	emp_notification_delay_late_in = 'PT2M'
	emp_notification_interval_late_in: string
	emp_notification_duration_late_in: string

	// Notify employee for late check out
	call_employee_late_out = false
	emp_notification_delay_late_out = 'PT2M'
	emp_notification_interval_late_out: string
	emp_notification_duration_late_out: string

	// Notify employee for late checkpoint
	call_employee_checkpoint = false
	emp_notification_delay_checkpoint = 'PT5M'

	////////////
	// Client //
	////////////

	// Notify client on incident report
	call_client_on_incident = false
	sms_client_on_incident = false
	email_client_on_incident = false

	////////////////
	// Supervisor //
	////////////////

	// Notify supervisor for early check in
	call_supervisor_early_in = false
	sms_supervisor_early_in = false
	email_supervisor_early_in = false
	sup_notification_early_in_min_time = 'PT30M'

	// Notify supervisor for check in
	call_supervisor_at_check_in = false
	sms_supervisor_at_check_in = false
	email_supervisor_at_check_in = false

	// Notify supervisor for late check in
	call_supervisor_late_in = false
	sms_supervisor_late_in = false
	email_supervisor_late_in = false
	sup_notification_delay_late_in = 'PT15M'
	sup_notification_interval_late_in: string
	sup_notification_duration_late_in: string
	notify_on_clear = false

	// Notify supervisor for early check out
	call_supervisor_on_early_out = false
	sms_supervisor_on_early_out = false
	email_supervisor_on_early_out = false
	sup_notification_early_out_min_time = 'PT15M'

	// Notify supervisor for late check out
	call_supervisor_late_out = false
	sms_supervisor_late_out = false
	email_supervisor_late_out = false
	sup_notification_delay_late_out = 'PT15M'
	sup_notification_interval_late_out: string
	sup_notification_duration_late_out: string

	// Notify supervisor on GPS issue
	call_sup_on_gps_issue = false
	sms_sup_on_gps_issue = false
	email_sup_on_gps_issue = false
	sup_notification_time_late_gps = 'PT5M'
	sup_notification_distance_offsite_gps = 500

	// Notify supervisor for late checkpoint
	call_supervisor_checkpoint = false
	sms_supervisor_checkpoint = false
	email_supervisor_checkpoint = false
	sup_notification_delay_checkpoint = 'PT15M'

	// Notify supervisor for missed dispatch checkpoint
	call_supervisor_dispatch_cp_fail = false
	sms_supervisor_dispatch_cp_fail = false
	email_supervisor_dispatch_cp_fail = false

	// Notify supervisor for employee unschedule job
	call_supervisor_on_unscheduled_in = false
	sms_supervisor_on_unscheduled_in = false
	email_supervisor_on_unscheduled_in = false

	// Notify supervisor for incident report filed
	call_supervisor_on_incident = false
	sms_supervisor_on_incident = false
	email_supervisor_on_incident = false

	// Notify supervisor on QR code validation failed
	call_supervisor_qrfail_inout = false
	sms_supervisor_qrfail_inout = false
	email_supervisor_qrfail_inout = false

	job_ids: Array<number>

	created: string
	updated: string

	constructor(record?: NotificationProfile) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
		}
	}
}
