<div class="banner-block-wrapper">
	<div [hidden]="bvm.groupAllBanners">
		<app-banner-group></app-banner-group>
	</div>

	<div *ngIf="bvm.groupAllBanners">
		<div
			*ngIf="shouldShowBanner"
			class="top-banner noprint"
			[class.top-banner-warn]="status.isWarn"
			[class.top-banner-danger]="status.isDanger"
			(click)="bannerPanel.toggle($event, bannerTarget)"
		>
			<div class="top-banner-content" [class.blink]="status.isCritical">
				<div #bannerTarget>
					Notifications <span class="badge top-banner-badge">{{ status.notificationCount }}</span>
				</div>
			</div>
		</div>

		<div class="notify-wrap">
			<p-popover #bannerPanel styleClass="banner-panel">
				<div class="panel-wrap">
					<app-banner-group></app-banner-group>
				</div>
			</p-popover>
		</div>
	</div>
</div>
