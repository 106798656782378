import { Injectable, EventEmitter } from '@angular/core'
import { DatabaseService } from '@app/services'

import { EmployeeLoginRecord } from '@app/models'
import { environment } from '@env/environment'

import _ from 'lodash'
import { log } from '@app/helpers/logger'

@Injectable({
	providedIn: 'root',
})
export class EmployeeLoginService {
	dataLoaded = false
	list: Array<EmployeeLoginRecord> = []
	listChange = new EventEmitter<Array<EmployeeLoginRecord>>()
	lastUpdated: Date

	constructor() {
		log('Creating EmployeeLoginService')
	}

	clearData() {
		this.dataLoaded = false
		this.list = []
		this.lastUpdated = new Date()
	}

	getEmployeeLoginRecords(): Array<EmployeeLoginRecord> {
		return this.list
	}

	getEmployeeLoginAuthUrlByEmpId(dbSrvc: DatabaseService, id: number, companyId: number) {
		const empPhone = dbSrvc.empSrvc.getEmployeeById(id)?.phone_number_e164
		const customDomain = dbSrvc.settingSrvc.getCompany()?.emp_app_domain
		const empAppDdomain = customDomain ?? environment.empAppDomain
		const domain = window.location.origin.includes('localhost') ? 'http://localhost:4500' : 'https://' + empAppDdomain
		const empLoginRec = this.getEmployeeLoginRecordByE164Number(empPhone)

		if (empLoginRec) {
			const phone = (empLoginRec.phone_e164 || '').replace('+', '')
			const token = empLoginRec.token || ''
			if (phone && token) {
				let loginUrl = `${domain}/#/auth?token=${token}`
				if (companyId) {
					loginUrl += `&cid=${companyId}`
				}
				return loginUrl
			}
		}
		return null
	}

	getEmployeeScheduleUrlByEmpId(dbSrvc: DatabaseService, id: number) {
		const emp = dbSrvc.empSrvc.getEmployeeById(id)
		const customDomain = dbSrvc.settingSrvc.getCompany()?.emp_app_domain
		const empAppDdomain = customDomain ?? environment.empAppDomain
		const domain = window.location.origin.includes('localhost') ? 'http://localhost:4500' : 'https://' + empAppDdomain
		const empLoginRec = this.getEmployeeLoginRecordByE164Number(emp.phone_number_e164)

		if (empLoginRec) {
			return `${domain}/#/auth?token=${empLoginRec.token}&dlc=esv&cid=${emp.company_id}`
		}
		return null
	}

	getEmployeeLoginRecordById(id: number) {
		return this.list.find((rec) => rec.id === id)
	}

	getEmployeeLoginRecordByEmpId(id: number) {
		return this.list.find((rec) => rec.employee_id === id)
	}

	getEmployeeLoginRecordByE164Number(phoneE164: string) {
		return this.list.find((rec) => rec.phone_e164 === phoneE164)
	}

	removeLocalEmployeeLoginRecord(recordId: number) {
		this.list = this.list.filter((rec) => rec.id !== recordId)
	}

	setEmployeeLoginRecords(records: Array<EmployeeLoginRecord>) {
		this.lastUpdated = new Date()
		this.list = records.map((rec) => new EmployeeLoginRecord(rec))
		this.dataLoaded = true
		this.listChange.next(this.list)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateEmployeeLoginRecords(records: Array<EmployeeLoginRecord>) {
		const newRecords = records.map((rec) => new EmployeeLoginRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.list.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.list.push(newRecord)
			}
		}
		this.listChange.next(this.list)
	}
}
