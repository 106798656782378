<div class="nav-block">
	<div class="nav-bar">
		<div *ngIf="isLoading" class="updating-note"><i class="fas fa-loader fa-spin"></i> Loading Shifts...</div>
		<div *ngIf="!isLoading" class="d-flex justify-content-between">
			<div><i class="fa fa-chevron-circle-left link-text-dark nav-icon" (click)="prevBtnClicked()"></i></div>
			<div>{{ formatViewDate(jobDate) }}</div>
			<div><i class="fa fa-chevron-circle-right link-text-dark nav-icon" (click)="nextBtnClicked()"></i></div>
		</div>
	</div>
</div>

<div *ngIf="!isLoading" class="record-count" [class.no-shifts-msg]="displayCount === 0">
	<div *ngIf="schedLogCards.length > 0">{{ hasShiftsMsg }}</div>
	<div *ngIf="schedLogCards.length === 0">{{ noShiftsMsg }}</div>
</div>

<div *ngIf="false && !isLoading" style="margin-bottom: 18px">
	<div class="d-flex justify-content-between">
		<div class="badge table-tag" [class.tag-selected]="filterView === 'JOB'" (click)="filterView = 'JOB'">for this job</div>
		<div class="badge table-tag" [class.tag-selected]="filterView === 'EMP'" (click)="filterView = 'EMP'">for this employee</div>
		<div class="badge table-tag" [class.tag-selected]="filterView === 'ALL'" (click)="filterView = 'ALL'">in total</div>
	</div>
</div>

<div *ngIf="!isLoading" class="desc-block">
	<div>
		Pick a shift for <span class="desc-hl">{{ empNameLabel }}</span> who {{ transActualStart ? 'checked in' : 'was scheduled to check in' }} at
		<span class="desc-hl">{{ jobNameLabel }}</span> on
		<span class="desc-hl">{{ formatViewDate(transJobDate) }}.</span>
	</div>
	<div style="margin-top: 18px">
		<div>
			<div class="float-right">
				<span class="in-out-label">Out:</span
				><span class="tts-hl-1" [class.tts-hl-firebrick]="isNoShow"> {{ checkOutTime }} {{ transActualEnd ? timezoneAbrev : '' }}</span>
			</div>
			<div>
				<span class="in-out-label">In:</span
				><span class="tts-hl-1" [class.tts-hl-firebrick]="isNoShow">{{ checkInTime }} {{ transActualStart ? timezoneAbrev : '' }}</span>
			</div>
		</div>
	</div>
</div>

<div [hidden]="isLoading || schedLogCards.length === 0" style="margin-top: 26px">
	<app-search-filter [placeholder]="'filter shifts'" (search)="performSearch($event)"> </app-search-filter>
</div>
<!-- <div class="shift-status-block">
	<div *ngIf="!transActualStart && !transActualEnd" class="table-tag noshow-tag">NO SHOW</div>
</div> -->

<!-- <div>
	<div style="margin: 12px 0px">
		<p-multiselect
			*ngIf="manager.shift.option === 'JOB'"
			appScrollLockMultiSelect
			appendTo="body"
			placeholder="Select Jobs"
			selectedItemsLabel="Selected Jobs: {0}"
			[maxSelectedLabels]="0"
			filterBy="label"
			[showToggleAll]="true"
			[resetFilterOnHide]="true"
			[options]="jobOptions"
			[(ngModel)]="fetchJobIds"
			(onPanelHide)="updateJobs()"
		>
		</p-multiselect>
		<p-multiselect
			*ngIf="manager.shift.option === 'EMP'"
			appScrollLockMultiSelect
			appendTo="body"
			placeholder="Select Employees"
			selectedItemsLabel="Selected Employees: {0}"
			[maxSelectedLabels]="0"
			filterBy="label"
			[showToggleAll]="true"
			[resetFilterOnHide]="true"
			[options]="empOptions"
			[(ngModel)]="featchEmpIds"
			(onPanelHide)="updateEmployees()"
		>
		</p-multiselect>
	</div>
</div> -->

<div *ngIf="!isLoading" class="shift-list-block">
	<div *ngIf="schedLogCards.length > 0">
		<div *ngFor="let card of filteredSchedLogCards">
			<div
				*ngIf="card.matchesFilter"
				class="card raised-card shift-card"
				[class.shift-selected]="card === selectedCard"
				(click)="selectCard(card)"
			>
				<div style="font-weight: 600">
					<div class="float-right">{{ formatShiftTime(card) }}</div>
					<div>{{ formatViewDate(card.schedLog.job_date) }}</div>
				</div>
				<div class="d-flex justify-content-between">
					<div>
						<div><span class="shift-card-label">Employee:</span> {{ card.schedLog.employee_name }} {{ card.empCount }}</div>
						<div><span class="shift-card-label">Job:</span> {{ card.schedLog.job_description }}</div>
						<div><span class="shift-card-label">Scheduled:</span> {{ card.shiftDays }}</div>
						<div *ngIf="!card.schedLog.effectiveEnabled()" class="label-disabled">&lt; This shift has been disabled &gt;</div>
					</div>
					<div *ngIf="card === selectedCard" class="shift-check">
						<i class="fa fa-check"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
