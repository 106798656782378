import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { DateTimeHelper, DisplayHelper, log } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'
import {
	DialogManager,
	LocalPrefsData,
	SectionSwitcherConfig,
	SectionSwitcherTab,
	LocalPrefsGroup,
	LocalPrefsDialogData,
	ContactsViewManager,
	ContactsViewTabState,
} from '@app/models'
import { DatabaseService } from '@app/services'
import { OrganizationTableComponent } from './organization-table/organization-table.component'
import { ContactTableComponent } from './contact-table/contact-table.component'

// type OrganizationViewStateCurrentViewType = 'CLIENTS' | 'VENDORS' | 'ALL'

class ContactViewModel {
	showTooltips = false
	get currentView(): ContactsViewTabState {
		return this.dbSrvc.contactSrvc.viewManager.currentView
	}
	constructor(private dbSrvc: DatabaseService) {}
}

@Component({
    selector: 'app-entity',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    standalone: false
})
export class ContactComponent implements OnInit, AfterViewInit {
	isTableProcessing: boolean = false

	switcherConfig = new SectionSwitcherConfig()
	vm: ContactViewModel

	@ViewChild('contactTable') contactTable: ContactTableComponent
	@ViewChild('orgTable') orgTable: OrganizationTableComponent

	constructor(private dbSrvc: DatabaseService) {
		this.setupAccessPermissions()
		this.setupTabSwitcher()
		this.vm = new ContactViewModel(this.dbSrvc)
	}

	get createRecordLabel(): string {
		return this.viewManager.currentView === 'INDIVIDUALS' ? 'Add Contact' : 'Add Organization'
	}

	get viewManager(): ContactsViewManager {
		return this.dbSrvc.contactSrvc.viewManager
	}

	get subSectionTitle(): string {
		const currentView = this.viewManager.currentView
		return DisplayHelper.capitalizeSingleWord(currentView)
	}

	get lastUpdated(): string {
		const date = this.dbSrvc.orgSrvc.lastUpdated
		return `Updated ${DateTimeHelper.formatTimeFromDate(date)}`
	}

	ngOnInit() {}
	ngAfterViewInit() {
		scrollTo(0, 0)
	}

	private setupAccessPermissions(): void {
		// this.accessHelper = new AccessHelper(this.dbSrvc, 'contact')
	}

	canPerformAction(action) {
		return true // this.accessHelper.canPerformAction(action, false)
	}

	toggleTooltips() {
		// this.vm.showTooltips = !this.vm.showTooltips
		this.updateFixedHeader()
	}

	updateFixedHeader() {
		setTimeout(() => {
			const table: any = $('#contactTable').DataTable()
			table.fixedHeader.adjust()
		}, 100)
	}

	reloadData() {
		this.contactTable?.fetchAndReloadData()
	}

	createRecord() {
		if (this.viewManager.currentView === 'INDIVIDUALS' && this.contactTable) {
			this.contactTable.createRecord()
		}
		if (this.viewManager.currentView === 'ORGANIZATIONS' && this.orgTable) {
			this.orgTable.createRecord()
		}
	}

	openPrefsDialog() {
		if (this.viewManager.currentView === 'INDIVIDUALS' && this.contactTable) {
			this.contactTable.sectionPrefsDialogManager.isDialogVisible = true
		}
		if (this.viewManager.currentView === 'ORGANIZATIONS' && this.orgTable) {
			this.orgTable.sectionPrefsDialogManager.isDialogVisible = true
		}
	}

	// Begin Tab Swticher Methods
	private setupTabSwitcher() {
		const individualsTab = new SectionSwitcherTab('INDIVIDUALS', 'Individuals')
		const organizationTab = new SectionSwitcherTab('ORGANIZATIONS', 'Organizations')
		const config = new SectionSwitcherConfig([individualsTab, organizationTab])
		this.switcherConfig = config
		this.switcherConfig.setInitialTabById(this.dbSrvc.contactSrvc.viewManager.currentView)
	}

	public sectionTabClicked(tab: SectionSwitcherTab) {
		this.contactTable?.setPage(0)
		this.setDisplayState(tab.id as ContactsViewTabState)
	}

	private setDisplayState(state: ContactsViewTabState) {
		this.dbSrvc.contactSrvc.viewManager.currentView = state
		// this.contactTable.updateTable()
	}
}
