import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core'
import { Helper, log } from '@app/helpers'
import { FormPhoneNumberManager } from '@app/models'
import { DatabaseService } from '@app/services'

import { parseNumber, formatNumber, CountryCode, ParsedNumber, isValidNumberForRegion, isValidNumber } from 'libphonenumber-js'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-form-phone-number',
    templateUrl: './form-phone-number.component.html',
    styleUrls: ['./form-phone-number.component.scss'],
    standalone: false
})
export class FormPhoneNumberComponent implements OnInit, OnDestroy {
	constructor(private dbSrvc: DatabaseService) {}

	dialingCode: CountryCode
	countryCodeData = Helper.countryIsoData.filter((region) => region.empSupport)

	// inputString: string

	@Input() manager: FormPhoneNumberManager
	@Output() showHelp: EventEmitter<string> = new EventEmitter()

	subs = new Subscription()

	ngOnInit(): void {
		this.setupComponent()

		// Subscribe to configuration changes
		this.subs.add(
			this.manager.configChange.subscribe(() => {
				this.setupComponent()
			}),
		)
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	setupComponent() {
		// Add phone number to form input
		this.manager.formInput = this.manager.e164

		// If no number is provided, setup default dialing code
		if (!this.manager.e164) {
			this.dialingCode = (this.dbSrvc.settingSrvc.getCompany()?.country_iso ?? 'US') as CountryCode
		}

		// If a number is provided, parse it, format it, and set the dialing code
		if (this.manager.e164) {
			const parsedNumber = parseNumber(this.manager.e164) as ParsedNumber
			if (parsedNumber.phone && isValidNumber(parsedNumber)) {
				this.dialingCode = parsedNumber.country
			}
			this.formatNumber()
		}

		// If limitCodes is set, filter countryCodeData to only show the limited codes and the current dialing code
		if (this.manager.limitCodes.length > 0) {
			this.countryCodeData = this.countryCodeData.filter((region) => this.manager.limitCodes.includes(region.id) || region.id === this.dialingCode)
		}
	}

	formatNumber() {
		const countryCode: CountryCode = this.dialingCode
		const number = this.manager.formInput

		if (number && number.length > 3 && isValidNumberForRegion(number, countryCode)) {
			const parsedNumber = parseNumber(number, countryCode) as ParsedNumber
			this.manager.formInput = formatNumber(parsedNumber, 'NATIONAL')
			this.manager.e164 = formatNumber(parsedNumber, 'E.164')
		} else {
			this.manager.e164 = null
		}
	}

	handlePasteEvent(event: Event) {
		log('Paste Event', event)
		setTimeout(() => {
			this.formatNumber()
		}, 250)
	}

	helpBtnClicked() {
		if (this.manager.helpKey) {
			this.showHelp.emit(this.manager.helpKey)
		}
	}
}
