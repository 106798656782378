import { EventEmitter, Injectable } from '@angular/core'
import { log } from '@app/helpers/logger'
import { SecureFileRecord } from '@app/models'
import { Global } from '@app/models/global'
import _ from 'lodash'

@Injectable({
	providedIn: 'root',
})
export class SecureFileService {
	dataLoaded = false
	list: Array<SecureFileRecord> = []
	lastUpdated: Date
	listChange = new EventEmitter<Array<SecureFileRecord>>()

	constructor() {
		log('Creating SecureFileService')
	}

	clearData() {
		this.dataLoaded = false
		this.list = []
		this.lastUpdated = null
		this.listChange.emit(this.list)
	}

	getSecureFileList(): Array<SecureFileRecord> {
		return _.orderBy(this.list, ['created'], ['desc'])
	}

	getSecureFileById(id: number) {
		return this.list.find((rec) => rec.id === id)
	}

	setSecureFileRecords(records: Array<SecureFileRecord>) {
		this.lastUpdated = new Date()
		this.list = records.map((rec) => new SecureFileRecord(rec))
		this.dataLoaded = true
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateSecureFileeRecords(records: Array<SecureFileRecord>) {
		this.lastUpdated = new Date()
		const newRecords = records.map((rec) => new SecureFileRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.list.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.list.push(newRecord)
			}
		}
	}
}
