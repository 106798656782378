import { Injectable } from '@angular/core'

import { QBOCustomer, QBOVendor, QBOServiceItem, QBOSync } from '@app/models'

import { log } from '@app/helpers'
import _ from 'lodash'
import { Global } from '@app/models/global'

@Injectable({
	providedIn: 'root',
})
export class QuickbooksService {
	isUploading = false

	private qboCustomerList: QBOCustomer[] = []
	private qboVendorList: QBOVendor[] = []
	private qboServiceItemList: QBOServiceItem[] = []
	private qboSync: QBOSync

	public qboCustomerDataLoaded = false
	public qboVendorDataLoaded = false
	public qboServiceItemDataLoaded = false
	public qboSyncDataLoaded = false

	constructor() {
		log('Creating QuickBooksService')
	}

	dataLoaded(): boolean {
		return this.qboCustomerDataLoaded && this.qboVendorDataLoaded && this.qboServiceItemDataLoaded && this.qboSyncDataLoaded
	}

	clearData() {
		this.qboCustomerList = []
		this.qboServiceItemList = []
		this.qboSync = null
		this.qboCustomerDataLoaded = false
		this.qboServiceItemDataLoaded = false
		this.qboSyncDataLoaded = false
	}

	// Dropdown Data
	getCustomerDropdownData(): any[] {
		const dropdown = this.qboCustomerList.map((cust) => {
			return {
				label: cust.full_name,
				value: cust.id,
			}
		})

		const sortedDropdown = _.sortBy(dropdown, 'label')
		sortedDropdown.unshift({ label: 'Select Customer', value: null })
		return sortedDropdown
	}

	getVendorDropdownData(): any[] {
		const dropdown = this.qboVendorList.map((vendor) => {
			return {
				label: vendor.name,
				value: vendor.id,
			}
		})

		const sortedDropdown = _.sortBy(dropdown, 'label')
		sortedDropdown.unshift({ label: 'Select Vendor', value: null })
		return sortedDropdown
	}

	getServiceItemDropdownData(): any[] {
		const dropdown = this.qboServiceItemList.map((si) => {
			return {
				label: si.full_name,
				value: si.id,
			}
		})

		const sortedDropdown = _.sortBy(dropdown, 'label')
		sortedDropdown.unshift({ label: 'Select Service Item', value: null })
		return sortedDropdown
	}

	// Various Checkes

	isQBDCustomer(): boolean {
		const syncData = this.getQBOSyncData()
		if (syncData && syncData.desktop_sync) {
			return true
		}
		return false
	}

	isQBOCustomer(): boolean {
		const syncData = this.getQBOSyncData()
		if (syncData && !syncData.desktop_sync) {
			return true
		}
		return false
	}

	hasCustomerData(): boolean {
		return this.qboCustomerList.length > 0
	}

	hasVendorData(): boolean {
		return this.qboCustomerList.length > 0
	}

	hasServiceItemData(): boolean {
		return this.qboServiceItemList.length > 0
	}

	// Getters

	getQBOSyncData(): QBOSync {
		return this.qboSync
	}

	getQBOCustomerInfoForId(id: number): string {
		const cust = this.qboCustomerList.find((c) => c.id === id)
		return cust ? cust.full_name : ''
	}

	getQBOVendorInfoForId(id: number): string {
		const vendor = this.qboVendorList.find((c) => c.id === id)
		return vendor ? vendor.name : ''
	}

	getQBOServiceItemInfoForId(id: number): string {
		const serviceItem = this.qboServiceItemList.find((si) => si.id === id)
		return serviceItem ? serviceItem.full_name : ''
	}

	// Database service helpers

	setQBOCustomerRecords(records: Array<QBOCustomer>) {
		this.qboCustomerList = records.map((rec) => new QBOCustomer(rec))
		this.qboCustomerDataLoaded = true
	}

	setQBOVendorRecords(records: Array<QBOVendor>) {
		this.qboVendorList = records.map((rec) => new QBOVendor(rec))
		this.qboVendorDataLoaded = true
	}

	setQBOServiceItemRecords(records: Array<QBOServiceItem>) {
		this.qboServiceItemList = records.map((rec) => new QBOServiceItem(rec))
		this.qboServiceItemDataLoaded = true
	}

	setQBOSyncRecords(records: Array<QBOSync>) {
		const syncResults = records.map((rec) => new QBOSync(rec))
		this.qboSync = syncResults[0]
		this.qboSyncDataLoaded = true
	}
}
