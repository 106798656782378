import { Component, OnInit, ViewChild } from '@angular/core'

import {
	UserPermissions,
	CrudAction,
	SectionSwitcherTab,
	SectionSwitcherConfig,
	AnnouncementsViewManager,
	AnnouncementsViewTabState,
} from '@app/models'
import { CoreService } from '@app/services'
import { LanguageHelper, log } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'
import { environment } from '@env/environment'

import { AnnouncementsCheckinListComponent } from './checkin-list/checkin-list.component'
import { AnnouncementsScheduledListComponent } from './scheduled-list/scheduled-list.component'

import _ from 'lodash'

@Component({
    selector: 'app-announcements',
    templateUrl: './announcements.component.html',
    styleUrls: ['./announcements.component.scss'],
    standalone: false
})
export class AnnouncementsComponent implements OnInit {
	languageList = LanguageHelper.list

	accessHelper: AccessHelper
	permissions: UserPermissions

	// currentTab: SectionSwitcherTab = null
	switcherConfig = new SectionSwitcherConfig()

	isDataLoaded = false
	isShowingSectionHelp = false

	@ViewChild('checkinListComponent') checkinListComponent: AnnouncementsCheckinListComponent
	@ViewChild('scheduledListComponent') scheduledListComponent: AnnouncementsScheduledListComponent

	environment = environment

	constructor(private coreSrvc: CoreService) {
		this.setupAccessPermissions()
		this.setupTabSwitcher()
		this.loadData()
	}

	get viewManager(): AnnouncementsViewManager {
		return this.coreSrvc.dbSrvc.annSrvc.viewManager
	}

	get subSectionTitle(): string {
		const currentView = this.viewManager.currentView
		switch (currentView) {
			case 'CHECKIN':
				return 'Check In'
			case 'SCHEDULED':
				return 'Scheduled'
			default:
				return 'Unknown'
		}
	}

	ngOnInit(): void {}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'announcement')
		this.permissions = this.accessHelper.getPermissionsFor('announcement')
	}

	private setupTabSwitcher() {
		const checkinTab = new SectionSwitcherTab('CHECKIN', 'CHeck In')
		checkinTab.count = () => this.coreSrvc.dbSrvc.annSrvc.getCheckinAnnouncements().length
		const scheduledTab = new SectionSwitcherTab('SCHEDULED', 'Scheduled')
		scheduledTab.count = () => this.coreSrvc.dbSrvc.annSrvc.getScheduledAnnouncements().length
		const config = new SectionSwitcherConfig([checkinTab, scheduledTab])

		const savedState = this.coreSrvc.dbSrvc.annSrvc.viewManager.currentView
		this.switcherConfig = config
		this.switcherConfig.setInitialTabById(savedState)
		// this.viewManager.setCurrentView(checkinTab.id as AnnouncementsCurrentView)
	}

	public sectionTabClicked(tab: SectionSwitcherTab) {
		this.viewManager.setCurrentView(tab.id as AnnouncementsViewTabState)
	}

	loadData() {
		this.coreSrvc.dbSrvc.bulkRead(['announcements', 'announcements_scheduled']).then((result) => {
			// log('Checkin Announcement List', this.coreSrvc.dbSrvc.annSrvc.getCheckinAnnouncements())
			// log('Scheduled Announcement List', this.coreSrvc.dbSrvc.annSrvc.getScheduledAnnouncements())
			this.isDataLoaded = true
		})
	}

	canCreateRecord(): boolean {
		return this.canPerformAction(CrudAction.create, true)
	}
	canPerformAction(action: CrudAction, isMyRecord: boolean): boolean {
		return this.accessHelper.canPerformAction(action, isMyRecord)
	}

	createRecord() {
		log('Create Record Announcements Component')
		if (this.viewManager.currentView === 'CHECKIN') {
			this.checkinListComponent.createRecord()
		} else {
			this.scheduledListComponent.createRecord()
		}
		// Redirect to visible component
	}

	updateLanguage() {
		this.coreSrvc.dbSrvc.annSrvc.viewManager.save()
	}

	showSectionHelp() {
		log('Show section help')
		if (this.viewManager.currentView === 'CHECKIN') {
			this.checkinListComponent.isShowingSectionHelp = !this.checkinListComponent.isShowingSectionHelp
		}
		// Redirect to visible component
	}
}
