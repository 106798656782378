import { JobSiteRecord, TransactionLogRecord } from '@app/models'
import { CoreService, DatabaseService, PrefsService } from '@app/services'

import { TransactionService } from '@app/services/backend/transaction.service'

import { log, DateTimeHelper, MapHelper } from '@app/helpers'
import { Component, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter, OnDestroy, Renderer2 } from '@angular/core'

import { SelectItem } from 'primeng/api'
import moment from 'moment-timezone'
import _ from 'lodash'
import { Subscription } from 'rxjs'

import { MarkerClusterer, MarkerClustererOptions } from '@googlemaps/markerclusterer'
import { environment } from '@env/environment'

@Component({
    selector: 'app-live-view-dialog',
    templateUrl: './live-view-dialog.component.html',
    styleUrls: ['./live-view-dialog.component.scss'],
    standalone: false
})
export class TransactionLiveViewDialogComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() transId: string
	@Input() linkType: string
	@Output() closeDialog: EventEmitter<boolean> = new EventEmitter()

	private closeListener: () => void
	private subs = new Subscription()
	private isGlobalAccount = false

	// trans: Transaction;
	transInfos: Array<TransMapInfo> = []
	showLegendHighlight = false

	currentRange = 24
	showRangeSetControls = false
	viewRange: Array<SelectItem> = [
		{ label: '12 Hours', value: 12 },
		{ label: '24 Hours', value: 24 },
		{ label: '48 Hours', value: 48 },
		{ label: '72 Hours', value: 72 },
	]

	viewButtonTitle = 'Show List'
	currentView = 'MAP'

	private transMarkers: Array<google.maps.marker.AdvancedMarkerElement> = []

	@ViewChild('gmap', { static: true }) gmapElement: any
	map: google.maps.Map

	clusterer: MarkerClusterer
	private toggleOngoingBtn: HTMLButtonElement = null
	public showOngoingMarkers = true
	private clusteringEnabled = true
	private hideOngoingMarkersAfterReset = false

	constructor(
		renderer: Renderer2,
		private coreSrvc: CoreService,
		private transSrvc: TransactionService,
		private prefsSrvc: PrefsService,
	) {
		this.isGlobalAccount = this.coreSrvc.dbSrvc.settingSrvc.isGlobalAccount()

		// Setup listener for proximity link clicks
		this.closeListener = renderer.listen('document', 'click', (event) => {
			// if (event.target === $('div#transactionMapModal.modal.fade')[0] && $('body').hasClass('modal-open')) {
			// 	this.closeDialog.emit(true)
			// 	return
			// }
		})
		const prefs = this.prefsSrvc.data
		this.currentRange = prefs.defaultOpenTransactionMapRange

		// Check if clustering is enabled
		this.clusteringEnabled = this.coreSrvc.prefSrvc.data.transMapEnableClustering

		// Setup transaction update listener
		this.subs.add(
			this.coreSrvc.eventSrvc.transListUpdated.subscribe(() => {
				log('Map view got transaction update')
				this.coreSrvc.zone.run(() => this.reloadMapMarkers())
			}),
		)
	}

	ngOnInit() {
		this.setupMapData()
	}

	ngAfterViewInit() {
		const element: any = $('#transactionMapModal')
		element.modal('show')
		setTimeout(() => {
			if (this.isMapValid()) {
				this.initMap()
				this.addTransactionMarkers(true)
				this.zoomToFitAllMarkers()
				this.setInitialViewState()
			}
		}, 350)
	}

	ngOnDestroy() {
		log('Open Transaction Map ngDestroy')
		const element: any = $('#transactionMapModal')
		element.modal('hide')
		this.subs.unsubscribe()
	}

	reloadMapMarkers() {
		// Set a the flag which determines if markers need to be toggled off
		// this.logState('reloadMapMarkers')
		this.hideOngoingMarkersAfterReset = this.showOngoingMarkers ? false : true
		// this.toggleOngoingMarkersButton('ON')

		this.clearMapMarkers()
		this.setupMapData()
		this.addTransactionMarkers(true) // POSSIBLE SLOW DOWN BECAUSE WAS TRUE
	}

	clearMapMarkers() {
		this.clusterer?.clearMarkers()
		for (const marker of this.transMarkers) {
			// if (this.clusteringEnabled) this.clusterer?.removeMarker(marker)
			marker.map = null
		}
		this.transMarkers = []
		this.showOngoingMarkers = true
	}

	setCurrentRange() {
		this.showRangeSetControls = false
		this.prefsSrvc.data.defaultOpenTransactionMapRange = this.currentRange
		this.prefsSrvc.save()
		this.reloadMapMarkers()
	}

	setupMapData() {
		const flagGps = this.coreSrvc.prefSrvc.data.transMapFlagGpsIssues

		const range = this.currentRange
		const transactions = this.transSrvc.list
		const sortedTrans = _.sortBy(transactions, 'created').reverse()
		const transInfos = sortedTrans.map((t) => new TransMapInfo(this.coreSrvc.dbSrvc, t, range, flagGps, this.isGlobalAccount))
		const transInclude = transInfos.filter((ti) => ti.include)
		const transValid = transInclude.filter((ti) => ti.isMissing || ti.isOngoing || ti.isNoShow)
		this.transInfos = transValid

		// if (environment.development) {
		// 	this.setupMockData()
		// }
	}

	setupMockData() {
		const sites = this.coreSrvc.dbSrvc.siteSrvc.getJobSites().map((s) => new TransMapInfoMock(s))
		this.transInfos = [...this.transInfos, ...sites]
	}

	setupClusterer() {
		if (!this.clusteringEnabled) {
			return
		}

		// this.logState('setupClusterer')

		// Green 'rgb(0, 125, 0)'
		// Blue 'rgb(11, 102, 135)'
		const clusterColor = 'rgb(0, 125, 0, .8)'

		const clusterStyles = [
			{
				width: 60,
				height: 60,
				url: MapHelper.getGoogleClusterInlineSvg(clusterColor),
				textColor: 'white',
				textSize: 12,
			},
			{
				width: 70,
				height: 70,
				url: MapHelper.getGoogleClusterInlineSvg(clusterColor),
				textColor: 'white',
				textSize: 12,
			},
			{
				width: 80,
				height: 80,
				url: MapHelper.getGoogleClusterInlineSvg(clusterColor),
				textColor: 'white',
				textSize: 12,
			},
			// up to 5
		]

		const clusterList = this.transMarkers.filter((m) => !!m['isOngoing'])
		const options: MarkerClustererOptions = {
			markers: clusterList,
			map: this.map,
			renderer: customRenderer,
		}

		this.clusterer = new MarkerClusterer(options)
		// this.clusterer = new MarkerClusterer(this.map, this.transMarkers, { maxZoom: 16, styles: clusterStyles })
	}

	get hasMarkers(): boolean {
		if (this.transInfos.length > 0) {
			return true
		}
		return false
	}

	isMapValid(): boolean {
		return true
	}

	calculateZoom() {
		return 16
	}

	initMap() {
		const styles: any = [
			{
				featureType: 'poi',
				stylers: [
					{
						visibility: 'off',
					},
				],
			},
			{
				featureType: 'poi.business',
				stylers: [
					{
						visibility: 'off',
					},
				],
			},
			{
				featureType: 'transit',
				stylers: [
					{
						visibility: 'off',
					},
				],
			},
		]

		const noMapCenter = MapHelper.getNoMapCenter(this.coreSrvc.dbSrvc)
		const hasTransInfos = this.transInfos.length > 0
		const mapProp = {
			mapId: 'openTransMap',
			gestureHandling: 'greedy',
			center: new google.maps.LatLng(noMapCenter.lat, noMapCenter.lng),
			zoom: hasTransInfos ? this.calculateZoom() : noMapCenter.zoom,
			maxZoom: 18,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			streetViewControl: false,
			clickableIcons: false,
			// mapTypeControlOptions: { mapTypeIds: [] }
			mapTypeControl: true,
			mapTypeControlOptions: {
				style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
				mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID],
			},
		}
		this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp)

		this.map.addListener('click', () => {
			// alert('Map Clicked');
			this.closeAllInfoWindows()
		})

		// Add Toggle Clusterer
		const toggleButton = document.createElement('button')
		toggleButton.textContent = ''
		toggleButton.title = 'Toggle valid time entries'
		toggleButton.classList.add('gmap-btn')
		toggleButton.classList.add('gmap-btn-clusterer-on')
		toggleButton.classList.add('gmap-btn-on')

		toggleButton.addEventListener('click', () => this.toggleOngoingMarkers())

		this.toggleOngoingBtn = toggleButton
		this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(toggleButton)
	}

	toggleOngoingMarkers(inputOnOff?: 'ON' | 'OFF') {
		const onOff = inputOnOff ? inputOnOff : this.showOngoingMarkers ? 'OFF' : 'ON' // If on we are turning off and if off we are turning on
		this.showOngoingMarkers = onOff === 'ON' ? true : false

		// log('Toggle Ongoing Markers', onOff)

		this.toggleOngoingMarkersButton(onOff)

		if (this.clusteringEnabled) {
			this.toggleOngoingClustered(onOff)
		} else {
			this.toggleOngoingStandalone(onOff)
		}
	}

	toggleOngoingMarkersButton(onOff: 'ON' | 'OFF') {
		if (onOff === 'ON') {
			this.toggleOngoingBtn.classList.remove('gmap-btn-clusterer')
			this.toggleOngoingBtn.classList.add('gmap-btn-clusterer-on')
			return
		} else {
			this.toggleOngoingBtn.classList.remove('gmap-btn-clusterer-on')
			this.toggleOngoingBtn.classList.add('gmap-btn-clusterer')
			return
		}
	}

	toggleOngoingStandalone(onOff: 'ON' | 'OFF') {
		for (const marker of this.transMarkers) {
			if (marker['isOngoing']) {
				onOff === 'ON' ? (marker.map = this.map) : (marker.map = null)
			}
			// marker.map && marker['isOngoing'] ? (marker.map = null) : (marker.map = this.map)
		}
	}

	toggleOngoingClustered(onOff: 'ON' | 'OFF') {
		// log('Toggle Ongoing Clusters', onOff)
		if (onOff == 'ON') {
			this.setupClusterer()
		} else {
			this.clusterer.clearMarkers()
			// Also need to turn off the markers themselves
			this.toggleOngoingStandalone(onOff)
		}
	}

	addTransactionMarkers(async: boolean) {
		if (this.clusteringEnabled) {
			async = false
		}

		const transInfos = this.transInfos.filter((ti) => ti.include)

		if (async) {
			const chunks = _.chunk(transInfos, 50)
			this.processTransInfoChunks(chunks)
			return
		} else {
			const transMarkers = []
			for (const ti of transInfos) {
				const marker = this.makeMarker(ti)
				if (marker) {
					transMarkers.push(marker)
				}
			}
			this.transMarkers = transMarkers
			this.setupClusterer()
			if (this.showOngoingMarkers && this.hideOngoingMarkersAfterReset) {
				this.toggleOngoingMarkers('OFF')
			}
		}
	}

	processTransInfoChunks(chunks: Array<Array<TransMapInfo>>) {
		const chunk = chunks.pop()
		if (chunk) {
			for (const ti of chunk) {
				const marker = this.makeMarker(ti)
				if (marker) {
					this.transMarkers.push(marker)
				}
			}
			setTimeout(() => {
				this.processTransInfoChunks(chunks)
			})
		} else {
			this.setupClusterer()
			if (this.showOngoingMarkers && this.hideOngoingMarkersAfterReset) {
				this.toggleOngoingMarkers('OFF')
			}
		}
	}

	makeMarker(ti: TransMapInfo): google.maps.marker.AdvancedMarkerElement {
		// const address = Helper.getStreetInfoFromAddress(js.address);
		const lat = ti.lat
		const lng = ti.lng
		const latLng = new google.maps.LatLng(lat, lng)

		// Spin thru job sites and if lat and lng are set then
		// place a marker and info window onto map

		if (lat && lng) {
			// log('Adding:', checkPointNum, latLng);
			// const marker = new google.maps.Marker({
			// 	position: latLng,
			// 	map: this.map,
			// 	title: ti.employeeName,
			// 	icon: '/assets/img/' + ti.icon,
			// })

			const pinImg = document.createElement('img')

			pinImg.src = '/assets/img/' + ti.icon

			const marker = new google.maps.marker.AdvancedMarkerElement({
				position: latLng,
				map: this.map,
				title: ti.employeeName,
				content: pinImg,
			})

			const isOnTop = ti.icon === 'marker-red.png'
			if (isOnTop) {
				log('Got a red marker')
				marker.zIndex = google.maps.Marker.MAX_ZINDEX + 1000
			}

			let style = ''
			let jobStatus = 'Unknown'
			if (ti.isOngoing) {
				jobStatus = 'Ongoing Shift'
				style = 'style="color:green"'
			} else if (ti.isMissing) {
				jobStatus = 'Missing Checkout'
				style = 'style="color:chocolate"'
			} else if (ti.isNoShow) {
				jobStatus = 'No Show'
				style = 'style="color:firebrick"'
			}

			const checkInType = ti.isCellphone ? 'Mobile' : ti.isLandline ? 'Landline' : ti.isWebApi ? 'Web' : 'Admin'
			const ckeckInTypeInfo = ti.startTime ? `<div class="otm-info-checkin-type">${checkInType} Check-In</div>` : ''
			// const jobStatus = ti.isOngoing ? 'Ongoing' : ti.isMissing ? 'Missing Checkout' : ti.isNoShow ? 'No show' : 'Unknown'

			const missingGpsInfo = !ti.hasGpsFromUser && !ti.isNoShow && !ti.isLandline ? '<div class="otm-no-gps">No Employee GPS</div>' : ''
			const companyName = ti.companyName ? `<div class="otm-info-company">${ti.companyName}</div>` : ''

			const contentString = `
				<div id="content" class="map-iw-content">
					<div style="max-width: 300px; margin-bottom: 12px">
						<div class="otm-info-name">${ti.employeeName}</div>
						<div class="otm-info-job">${ti.jobDescription}</div>
						<div class="otm-info-company">${ti.companyName}</div>
						<div class="otm-info-start-time">${ti.startTime}</div>
						${ckeckInTypeInfo}
						<div class="otm-info-status" ${style}>${jobStatus}</div>
						${missingGpsInfo}
						</div>
					</div>
				</div>
				`

			const infoWindow = new google.maps.InfoWindow({
				content: contentString,
			})

			// Add listeners
			marker.addListener('click', () => {
				if (this.isInfoWindowOpen(infoWindow)) {
					infoWindow.close()
				} else {
					this.closeAllInfoWindows()
					infoWindow.open(this.map, marker)
				}
			})

			// NOT YET WORKING
			const isDesktop = this.coreSrvc.devDetect.isDesktop()
			if (isDesktop) {
				marker.content.addEventListener('mouseover', () => {
					// log('mouse over')
					infoWindow.open(this.map, marker)
				})

				marker.content.addEventListener('mouseout', () => {
					// log('mouse out')
					const isSticky = marker['isSticky']
					if (!isSticky) {
						infoWindow.close()
					}
				})
			}

			// Save copy of infoWindow in marker and save ref to marker
			marker['isSticky'] = false
			marker['infoWindow'] = infoWindow
			marker['transInfoId'] = ti.id
			marker['isOngoing'] = ti.isOngoing === true
			// infoWindow.open(this.map, marker);
			return marker
		}
	}

	setInitialViewState() {
		log('Trans Markers', this.transMarkers)
		if (this.transMarkers.length > 0) {
			this.currentView = 'MAP'
		} else {
			this.currentView = 'LIST'
		}
	}

	toggleView() {
		const currentView = this.currentView
		if (currentView === 'MAP') {
			this.currentView = 'LIST'
			this.viewButtonTitle = 'Show Map'
		} else {
			this.currentView = 'MAP'
			this.viewButtonTitle = 'Show List'
		}
		return false
	}

	panToMarker(marker: google.maps.marker.AdvancedMarkerElement) {
		this.closeAllInfoWindows()
		// this.map.setZoom(18)
		if (marker) {
			const latLng = marker.position
			this.map.panTo(latLng)
			const infoWindow = marker['infoWindow']
			infoWindow.open(this.map, marker)
			marker.map = this.map
		}
	}

	zoomToFitAllMarkers() {
		log('zoomToFit', this.transMarkers.length)
		if (this.transMarkers.length === 0) {
			return
		}
		const markers = this.transMarkers
		const bounds = new google.maps.LatLngBounds()
		for (const marker of markers) {
			const position = marker.position
			bounds.extend(position)
		}
		this.map.fitBounds(bounds)
	}

	showTransInfo(ti: TransMapInfo) {
		const tiId = ti.id
		const marker = this.getTransInfoMarkerForId(tiId)
		if (marker) {
			marker.zIndex = google.maps.Marker.MAX_ZINDEX + 1
			// log('Got marker', marker);
			this.panToMarker(marker)
			this.currentView = 'MAP'
		} else {
			alert('No Geo coordinates available')
		}
	}

	getTransInfoMarkerForId(id: number) {
		const marker = this.transMarkers.find((m) => m['transInfoId'] === id)
		return marker
	}

	closeAllInfoWindows() {
		this.transMarkers.forEach((marker) => {
			marker['infoWindow'].close()
		})
	}

	isInfoWindowOpen(infoWindow) {
		const map = infoWindow.getMap()
		return map !== null && typeof map !== 'undefined'
	}

	onClose() {
		this.closeDialog.emit(true)
	}

	formatTime(timeString: string): string {
		const checkpointMoment = moment(DateTimeHelper.stripUtcTag(timeString))
		return checkpointMoment.format('ddd MMM Do @ h:mm a')
	}

	eventType(): string {
		if (this.linkType === 'start') {
			return 'Check-In'
		} else {
			return 'Check-Out'
		}
	}

	logState(id: string) {
		log('Log State - ' + id)
		log(
			`-clusteringEnabled/showOngoingMarkers/hideOngoingMarkersAfterReset`,
			this.clusteringEnabled,
			this.showOngoingMarkers,
			this.hideOngoingMarkersAfterReset,
		)
		log('-transMarkers', this.transMarkers)
	}
}

class TransMapInfo {
	id: number
	lat: number
	lng: number

	employeeName = ''
	jobDescription = ''
	companyName = ''
	startTime = ''
	icon = 'marker-blue.png'

	include = false
	hasGps = false
	hasGpsFromUser = false
	flagGps = true

	isOngoing = false
	isMissing = false
	isNoShow = false

	isLandline = false
	isCellphone = false
	isWebApi = false
	isAdmin = false

	startMom: moment.Moment

	constructor(dbSrvc: DatabaseService, trans: TransactionLogRecord, range: number, flagGps: boolean, isGlobal: boolean = false) {
		const siteSrvc = dbSrvc.siteSrvc
		// Check if in range of 48 hours
		const timezone = trans.timezone
		const format12Hours = DateTimeHelper.format12Hour

		const metaData = trans['inMetaData'] ?? trans.bulidMetaData('IN')
		this.isLandline = metaData.isLandline
		this.isCellphone = metaData.isCellphone
		this.isWebApi = metaData.isWebApi
		this.isAdmin = !metaData.isCellphone && !metaData.isLandline && !metaData.isWebApi
		this.flagGps = flagGps

		const rangeCutoff = moment().subtract(range, 'hours')
		const startTimeString = DateTimeHelper.stripUtcTag(trans.actual_start)
		this.startMom = moment(startTimeString)

		if (this.startMom.isBefore(rangeCutoff)) {
			this.include = false
			return null
		} else {
			if (this.startMom.isValid()) {
				this.startTime = MapHelper.formatTimestamp(startTimeString, timezone, format12Hours, '@') // this.startMom.format('ddd MMM Do @ h:mm a')
			}
		}

		this.id = trans.id
		this.employeeName = trans.employee_first + ' ' + trans.employee_last

		const description = trans.job_description.split(' [')
		this.jobDescription = description ? description[0] : ''

		// Setup company name
		this.companyName = isGlobal ? (dbSrvc.settingSrvc.getGlobalCompanyById(trans.company_id)?.name ?? 'Unknown') : ''

		// Check for coordinates
		this.lat = trans.geo_start_latitude
		this.lng = trans.geo_start_longitude

		// If we got GPS from transaction, mark as GPS from user
		if (this.lat && this.lng) this.hasGpsFromUser = true

		// If global and we don't have GPS, check to see if available on join from job site info
		// which is available when in a global company
		if (isGlobal && !this.lat && !this.lng) {
			this.lat = trans['js_latitude'] ?? null
			this.lng = trans['js_longitude'] ?? null
			this.hasGps = true
		}

		// If still no lat/lng and we're not in global account, try to get GPS from job site through a lookup
		if (!isGlobal && !this.lat && !this.lng) {
			const site = siteSrvc.getJobSiteById(trans.jobsite_id)
			if (site) {
				const siteLat = site.geo_latitude
				const siteLng = site.geo_longitude

				if (siteLat && siteLng) {
					this.lat = siteLat
					this.lng = siteLng
					this.hasGps = true
				}
			}
		}

		const maxJobLength = dbSrvc.settingSrvc.getCompany().job_length_max ?? 16

		// Missing checkout
		const missingCutoff = moment().subtract(maxJobLength, 'hours')
		if (this.startMom.isBefore(missingCutoff) && !trans.actual_end) {
			this.isMissing = true
			this.include = true
			this.icon = 'marker-orange.png' // this.hasGpsFromUser ? 'marker-green.png' : 'marker-orange.png'
		}

		// Ongoing transaction
		if (this.startMom.isAfter(missingCutoff) && !trans.actual_end) {
			this.isOngoing = true
			this.include = true
			this.icon = this.hasGpsFromUser || !this.flagGps || this.isLandline || this.isAdmin ? 'marker-green.png' : 'marker-orange.png'
		}

		// No show
		if (!trans.actual_start && !trans.actual_end) {
			const createdString = DateTimeHelper.stripUtcTag(trans.created)
			const createdMom = moment(createdString)
			if (createdMom.isAfter(rangeCutoff)) {
				// log('No Show', trans);
				this.isNoShow = true
				this.include = true
				this.icon = 'marker-red.png'
			}
		}

		this.addJitter()
	}

	addJitter() {
		// log('Lat/Lng Orig', this.lat, this.lng)
		if (this.lat) {
			const val = Math.floor(Math.random() * 9) / 50000
			this.lat += val * (Math.round(Math.random()) * 2 - 1)
		}
		if (this.lng) {
			const val = Math.floor(Math.random() * 9) / 50000
			this.lng += val * (Math.round(Math.random()) * 2 - 1)
		}
		// log('Lat/Lng Final', this.lat, this.lng)
	}
}

const customRenderer = {
	render({ count, position }: { count: number; position: any }, stats: any) {
		const clusterColor = 'rgb(55, 143, 43, 1)'
		const svg = MapHelper.customMarkerSvg(clusterColor)
		// create marker using svg icon
		if (count > 1) {
			return new google.maps.Marker({
				position,
				icon: {
					url: `data:image/svg+xml;base64,${svg}`,
					scaledSize: new google.maps.Size(75, 75),
				},
				label: {
					text: String(count),
					color: 'rgba(255,255,255,0.9)',
					fontSize: '12px',
				},
				// adjust zIndex to be above other markers
				// zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
			})
		}
		return new google.maps.Marker({
			icon: {
				url: `${window.location.origin}/location-pin.svg`,
				scaledSize: new google.maps.Size(30, 30),
			},
		})
	},
}

class TransMapInfoMock {
	id: number
	lat: number
	lng: number

	employeeName = 'Employee Name'
	jobDescription = 'Job Description'
	companyName = 'Company Name'
	startTime = '9:00 am'
	icon = 'marker-green.png'

	include = true
	hasGps = true
	hasGpsFromUser = true
	flagGps = true

	isOngoing = true
	isMissing = false
	isNoShow = false

	isLandline = false
	isCellphone = false
	isWebApi = false
	isAdmin = false

	startMom: moment.Moment

	constructor(site: JobSiteRecord) {
		this.id = site.id
		this.lat = site.geo_latitude
		this.lng = site.geo_longitude
		this.jobDescription = site.description

		if (this.id % 100 === 0) {
			this.icon = 'marker-red.png'
			this.isOngoing = false
			this.isNoShow = true
		}

		this.addJitter()
	}

	addJitter() {
		// log('Lat/Lng Orig', this.lat, this.lng)
		if (this.lat) {
			const val = Math.floor(Math.random() * 9) / 50000
			this.lat += val * (Math.round(Math.random()) * 2 - 1)
		}
		if (this.lng) {
			const val = Math.floor(Math.random() * 9) / 50000
			this.lng += val * (Math.round(Math.random()) * 2 - 1)
		}
		// log('Lat/Lng Final', this.lat, this.lng)
	}
}

// class USGSOverlay extends google.maps.OverlayView {
// 	private bounds: google.maps.LatLngBounds
// 	private image: string
// 	private div?: HTMLElement

// 	constructor(bounds: google.maps.LatLngBounds, image: string) {
// 		super()

// 		this.bounds = bounds
// 		this.image = image
// 	}

// 	/**
// 	 * onAdd is called when the map's panes are ready and the overlay has been
// 	 * added to the map.
// 	 */
// 	onAdd() {
// 		this.div = document.createElement('div')
// 		this.div.style.borderStyle = 'none'
// 		this.div.style.borderWidth = '0px'
// 		this.div.style.position = 'absolute'

// 		// Create the img element and attach it to the div.
// 		const img = document.createElement('img')
// 		img.src = this.image
// 		img.style.width = '100%'
// 		img.style.height = '100%'
// 		img.style.position = 'absolute'
// 		this.div.appendChild(img)

// 		// Add the element to the "overlayLayer" pane.
// 		const panes = this.getPanes()
// 		panes.overlayLayer.appendChild(this.div)
// 	}

// 	draw() {
// 		// We use the south-west and north-east
// 		// coordinates of the overlay to peg it to the correct position and size.
// 		// To do this, we need to retrieve the projection from the overlay.
// 		const overlayProjection = this.getProjection()

// 		// Retrieve the south-west and north-east coordinates of this overlay
// 		// in LatLngs and convert them to pixel coordinates.
// 		// We'll use these coordinates to resize the div.
// 		const sw = overlayProjection.fromLatLngToDivPixel(
// 			this.bounds.getSouthWest()
// 		)
// 		const ne = overlayProjection.fromLatLngToDivPixel(
// 			this.bounds.getNorthEast()
// 		)

// 		// Resize the image's div to fit the indicated dimensions.
// 		if (this.div) {
// 			this.div.style.left = sw.x + 'px'
// 			this.div.style.top = ne.y + 'px'
// 			this.div.style.width = ne.x - sw.x + 'px'
// 			this.div.style.height = sw.y - ne.y + 'px'
// 		}
// 	}

// 	/**
// 	 * The onRemove() method will be called automatically from the API if
// 	 * we ever set the overlay's map property to 'null'.
// 	 */
// 	onRemove() {
// 		if (this.div) {
// 			(this.div.parentNode as HTMLElement).removeChild(this.div)
// 			delete this.div
// 		}
// 	}

// 	/**
// 	 *  Set the visibility to 'hidden' or 'visible'.
// 	 */
// 	hide() {
// 		if (this.div) {
// 			this.div.style.visibility = 'hidden'
// 		}
// 	}

// 	show() {
// 		if (this.div) {
// 			this.div.style.visibility = 'visible'
// 		}
// 	}

// 	toggle() {
// 		if (this.div) {
// 			if (this.div.style.visibility === 'hidden') {
// 				this.show()
// 			} else {
// 				this.hide()
// 			}
// 		}
// 	}

// 	toggleDOM(map: google.maps.Map) {
// 		if (this.getMap()) {
// 			this.setMap(null)
// 		} else {
// 			this.setMap(map)
// 		}
// 	}
// }
