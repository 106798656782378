import { Helper } from '@app/helpers/functions'
import { DatabaseService } from '@app/services'
import { SelectItem } from 'primeng/api/selectitem'

import _ from 'lodash'
import { CountryCode, formatNumber, isValidNumberForRegion, ParsedNumber, parseNumber, parsePhoneNumber } from 'libphonenumber-js'
import { log } from '@app/helpers/logger'
import { AccessHelper } from '@app/helpers/access'
import { CoreService, NotificationsService } from '@app/services'

export class JobSitePhoneEntry {
	name = ''
	number = ''
	isEmployee = false

	constructor() {}
}

export interface EmpNumberMultiSelectItem {
	name: string
	id: number
	phone: string
	active: boolean
}

export class EditFormJobSiteLinkedNumbersManagerConfig {
	showHeader = false
	allowCallsFromEmployeeMobilePhones = true
	countryCode: CountryCode = 'US'
	numbers: Array<string> = []
}

export class EditFormJobSiteLinkedNumbersManager {
	private accessHelper: AccessHelper
	private dbSrvc: DatabaseService
	private notifySrvc: NotificationsService
	private skipNumberCheck = false

	newLandlineInput = ''

	linkLandlineCheckbox = false
	linkEmpMobileCheckbox = false

	allowCallsFromEmployeeMobilePhones = true

	countryCode: CountryCode
	countryCodeData = Helper.countryIsoData.filter((region) => region.regionSupport)

	phoneEntries: JobSitePhoneEntry[] = []
	initialPhoneEntries: JobSitePhoneEntry[] = []

	employeePhoneNumbers: EmpNumberMultiSelectItem[] = []
	employeePhoneNumbersSelected: EmpNumberMultiSelectItem[] = []
	initialEmployeePhoneNumbers: EmpNumberMultiSelectItem[] = []

	showHeader = false
	showResetEmployeesSelectedDialog = false
	showResetLandlineNumbersDialog = false

	addMobileNumberDropdown: Array<SelectItem> = []

	constructor(coreSrvc: CoreService) {
		this.dbSrvc = coreSrvc.dbSrvc
		this.notifySrvc = coreSrvc.notifySrvc
		this.accessHelper = new AccessHelper(coreSrvc, 'site')
	}

	get isFormValid(): boolean {
		const currentPhoneInput = this.newLandlineInput
		if (currentPhoneInput) {
			return this.isPhoneValid(currentPhoneInput)
		}
		return true
	}

	get selectedEmployeesList(): Array<EmpNumberMultiSelectItem> {
		return _.sortBy(this.employeePhoneNumbersSelected, 'name')
	}

	get phoneNumberList(): Array<string> {
		// if (this.phoneEntries.length === 0 && this.employeePhoneNumbersSelected.length === 0) {
		// 	return ['+18888888888']
		// }
		const landlineNumbers = this.linkLandlineCheckbox ? this.phoneEntries.map((pe) => pe.number) : []
		const employeeNumbers = this.employeePhoneNumbersSelected ? this.employeePhoneNumbersSelected.map((ep) => ep.phone) : []
		return [...landlineNumbers, ...employeeNumbers]
	}

	get phoneNumberString(): string {
		const phoneNumbers = this.phoneNumberList
		return phoneNumbers.length > 0 ? this.phoneNumberList.join(',') : '+18888888888'
	}

	public setupManager(config: EditFormJobSiteLinkedNumbersManagerConfig) {
		this.showHeader = config.showHeader
		this.allowCallsFromEmployeeMobilePhones = config.allowCallsFromEmployeeMobilePhones
		this.countryCode = config.countryCode
		this.setupPhoneNumbers(config.numbers)
		this.setupAddMobileNumberDropdownMenu()
		this.setupEmployeeMultiSelect()
	}

	private setupPhoneNumbers(numbers: Array<string>) {
		numbers.forEach((num) => {
			const phoneEntry = this.makePhoneEntry(num)
			this.phoneEntries.push(phoneEntry)
			// const emp = this.dbSrvc.empSrvc.getEmployeeForPhoneNumberE164(num)
			// if (emp) {
			// 	const selectedEmp: EmpNumberMultiSelectItem = {
			// 		id: emp.id,
			// 		name: emp.name,
			// 		phone: emp.phone_number_e164,
			// 		active: emp.active
			// 	}
			// 	this.employeePhoneNumbersSelected.push(selectedEmp)
			// } else {
			// 	const phoneEntry = this.makePhoneEntry(num)
			// 	this.phoneEntries.push(phoneEntry)
			// }
		})
		// Make copies of initial phone number lists to revert if needed
		this.initialEmployeePhoneNumbers = JSON.parse(JSON.stringify(this.employeePhoneNumbersSelected))
		this.initialPhoneEntries = JSON.parse(JSON.stringify(this.phoneEntries))
		// Set numbers link checkboxes
		if (this.phoneEntries.length > 0) {
			this.linkLandlineCheckbox = true
		}
		if (this.employeePhoneNumbersSelected.length > 0) {
			this.linkEmpMobileCheckbox = true
		}
	}

	private setupEmployeeMultiSelect() {
		const userPermissions = this.accessHelper.getPermissionsFor('site')
		const isRestricted = false // userPermissions.isSelectorRestrictedFor('ELN') // Option deprecated
		log('Should filter employees', isRestricted)
		const isPrimaryOrInternal = this.dbSrvc.settingSrvc.isPrimaryOrInternalUser()
		const myUserId = isPrimaryOrInternal || !isRestricted ? null : this.dbSrvc.settingSrvc.getMyUserId()
		const isManager = this.dbSrvc.settingSrvc.isUserAManager()
		const managedSupIds = this.dbSrvc.settingSrvc.getManagedUserIds()

		const activeEmployees =
			isManager && isRestricted ? this.dbSrvc.empSrvc.getManagedActiveEmployees(managedSupIds) : this.dbSrvc.empSrvc.getActiveEmployees(myUserId)
		// const filteredEmployees = activeEmployees.filter(e => e.supervisor === myUserId)
		// const availableEmployees = isRestricted ? filteredEmployees : activeEmployees

		const dropdownList = activeEmployees.map((emp) => {
			return { id: emp.id, name: emp.name, phone: emp.phone_number_e164, active: emp.active }
		})
		this.employeePhoneNumbers = dropdownList
	}

	private setupAddMobileNumberDropdownMenu() {
		const dropdown = this.dbSrvc.empSrvc.getActiveEmployees().map((emp) => {
			return {
				label: emp.name,
				value: emp.phone_number_e164,
			}
		})
		const sortedDropdown = _.sortBy(dropdown, 'label')
		sortedDropdown.unshift({ label: 'Select Employee', value: null })
		this.addMobileNumberDropdown = sortedDropdown
	}

	private makePhoneEntry(num: string): JobSitePhoneEntry {
		const entry = new JobSitePhoneEntry()
		const emp = this.dbSrvc.empSrvc.getEmployeeForPhoneNumberE164(num)
		entry.number = num
		entry.name = emp ? emp.name : ''
		entry.isEmployee = emp ? true : false
		return entry
	}

	public isEmployee(idx: number): boolean {
		return this.phoneEntries[idx].isEmployee
	}

	public onAddNumber(): boolean {
		const currentNumber = this.newLandlineInput // newNumber.value
		if (!currentNumber) {
			return
		}
		log('Attempting to add', currentNumber)
		const number = currentNumber
		const countryCode = this.countryCode
		let parsedNumber: ParsedNumber

		// Skip checking country code when employee number added as it's alread E.164

		if (this.skipNumberCheck) {
			parsedNumber = parseNumber(number) as ParsedNumber
		} else {
			parsedNumber = parseNumber(number, countryCode) as ParsedNumber
		}

		if (!this.skipNumberCheck && (number.length < 3 || !isValidNumberForRegion(number, countryCode))) {
			const message = 'Phone number ' + number + ' is not valid for the region you have selected.'
			this.notifySrvc.notify('error', 'Invalid Number', message)
			// alert(message)
			return false
		}

		const internationalNumber = formatNumber(parsedNumber, 'E.164')

		// Check if the number already exists in the list
		const currentPhoneEntries = this.phoneEntries.map((pe) => pe.number)
		if (currentPhoneEntries.includes(internationalNumber)) {
			// alert('Phone number already exists in list.')
			this.newLandlineInput = ''
			return
		}

		const employee = this.dbSrvc.empSrvc.getEmployeeForPhoneNumberE164(internationalNumber)
		if (employee) {
			if (!this.allowCallsFromEmployeeMobilePhones) {
				this.notifySrvc.notify(
					'error',
					'Employee Blocking',
					'Your call block settings do not allow linking Employee mobile numbers. Go to Admin > Settings > Inbound Call Blocking to disable this setting.',
				)
				// alert(
				// 	'Employee mobile numbers may not be linked because your call blocking settings do not allow it. Go to Admin > Settings > Inbound call blocking'
				// )
				this.newLandlineInput = ''
				return false
			}
		}

		// const employee = this.dbSrvc.empSrvc.getEmployeeForPhoneNumberE164(internationalNumber)
		// if (employee) {
		// 	if (this.allowCallsFromEmployeeMobilePhones) {
		// 		alert('This is an employee phone number. Please use the Add Employee Mobile Numbers menu to select them.')
		// 	} else {
		// 		alert('This is an employee phone number and cannot be linked to a job site since you are blocking employee mobile numbers.')
		// 	}
		// 	this.newLandlineInput = ''
		// 	return false
		// }

		const entry = this.makePhoneEntry(internationalNumber)
		this.phoneEntries.push(entry)
		this.phoneEntries = _.sortBy(this.phoneEntries, 'name')

		this.newLandlineInput = ''
		return true
	}

	public onRemoveNumber(idx: number): boolean {
		this.phoneEntries.splice(idx, 1)
		return false
	}

	public removeEmployeePhone(emp) {
		this.employeePhoneNumbersSelected = this.employeePhoneNumbersSelected.filter((elm) => elm.id !== emp.id)
	}

	public formatPhone(input: HTMLInputElement) {
		const countryCode = this.countryCode
		const number = input.value
		if (number && number.length > 3 && isValidNumberForRegion(number, countryCode)) {
			const parsedNumber = parseNumber(number, countryCode) as ParsedNumber
			input.value = formatNumber(parsedNumber, 'NATIONAL')
		}
	}

	public isPhoneValid(number: string): boolean {
		const countryCode = this.countryCode
		// const number = input.value
		if (number.length > 3 && isValidNumberForRegion(number, countryCode)) {
			return true
		}
		return false
	}

	public landlineCheckboxChanged() {
		if (this.linkLandlineCheckbox) {
			setTimeout(() => {
				$('#landline-block')[0]?.scrollIntoView()
			}, 100)
		} else {
			this.clearLandlineNumbers()
		}
	}

	public empMobileCheckboxChanged() {
		if (!this.linkEmpMobileCheckbox) {
			this.clearEmployeeNumbers()
		}
	}

	private clearLandlineNumbers() {
		this.phoneEntries = []
		this.newLandlineInput = ''
	}

	public resetLandlineNumbers() {
		this.phoneEntries = JSON.parse(JSON.stringify(this.initialPhoneEntries))
		this.showResetLandlineNumbersDialog = false
	}

	private clearEmployeeNumbers() {
		this.employeePhoneNumbersSelected = []
	}

	public resetEmployeesSelected() {
		this.employeePhoneNumbersSelected = JSON.parse(JSON.stringify(this.initialEmployeePhoneNumbers))
		this.showResetEmployeesSelectedDialog = false
	}

	public getE164NumberFromInput() {
		const number = this.newLandlineInput || ''
		const isValidForRegion = isValidNumberForRegion(number, this.countryCode)
		if (isValidForRegion) {
			const phoneNumber = parsePhoneNumber(number, this.countryCode)
			if (phoneNumber) {
				return phoneNumber.number
			}
		}
		return null
	}

	// Get component in valid state or return error why it could not be prepared
	public prepareForSubmission(): string {
		// Check if an employee number is in the number input field
		if (!this.allowCallsFromEmployeeMobilePhones) {
			const currentE164 = this.getE164NumberFromInput() as string
			log('Current Num', currentE164)
			if (currentE164) {
				const employee = this.dbSrvc.empSrvc.getEmployeeForPhoneNumberE164(currentE164)
				if (employee) {
					return 'Employee mobile numbers may not be linked because your call blocking settings do not allow it.'
				}
			}
		}

		// If the form is otherwise valid then add the number and continue submission in parent
		if (this.isFormValid) {
			this.onAddNumber()
			return null
		} else {
			return 'The landline number entered is not valid for the region selected.'
		}
	}
}
