import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { DateTimeHelper, log, PhoneHelper } from '@app/helpers'
import {
	ClickToCallRecord,
	ClickToCallRequestBuilder,
	DataAccessRequest,
	DialogManager,
	FormPhoneNumberManager,
	HelpDialogMessage,
	C2CEventSubmittedEvent,
	UserRecord,
} from '@app/models'
import { AuditLogTrackEventData, CoreService, DatabaseService } from '@app/services'
import { Subscription } from 'rxjs'

type DispatchSettingsViewType = 'MAIN' | 'PLACECALL' | 'ADDRESSBOOK' | 'UPDATENUMBER'

class DispatchSettingsViewManager {
	currentView: DispatchSettingsViewType = 'MAIN'
}

@Component({
    selector: 'app-header-dispatch-settings',
    templateUrl: './header-dispatch-settings.component.html',
    styleUrls: ['./header-dispatch-settings.component.scss'],
    standalone: false
})
export class HeaderDispatchSettingsComponent implements OnInit, OnDestroy {
	signedInFormat = DateTimeHelper.format12Hour ? 'YYYY-MM-DD @ h:mm a' : 'YYYY-MM-DD @ HH:mm'
	@Input() dialogManager: DialogManager

	public dispatchPhoneManager: FormPhoneNumberManager

	public smsCharLimit = 140
	public placeCallName = ''
	public placeCallMessage = ''
	public placeCallTextType: 'CALL' | 'TEXT' = 'CALL'
	public placeCallPhoneManager: FormPhoneNumberManager

	private subs = new Subscription()

	// isChangingNumber = false
	isCallCenterEnabled = false

	isWorking = { signIn: false, signOut: false, clearNumber: false, updateNumber: false, placeCall: false, sendText: false }

	vm = new DispatchSettingsViewManager()

	agentName = ''

	constructor(private coreSrvc: CoreService) {
		this.subs.add(this.coreSrvc.callSrvc.c2cEventSubmitted.subscribe((event) => this.handleC2CEventSubmitted(event)))
		this.coreSrvc.dbSrvc.settingSrvc.usersChanged.subscribe(() => this.updateUI())

		this.setupAvailabilityCheck()
		// Set sms character limit
		this.smsCharLimit = this.coreSrvc.dbSrvc.settingSrvc.getCompany()?.sms_char_limit ?? 140

		const myActualUser = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
		this.agentName = myActualUser.first_name + ' ' + myActualUser.last_name
	}

	get maxTextLengthExceeded(): boolean {
		return this.placeCallMessage.length > this.smsCharLimit
	}

	get isLoggedIn(): boolean {
		return !!this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()?.dispatcher_active
	}

	get currentDispatchPhone(): string {
		const myActualUser = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
		return myActualUser.c2c_phone_e164 || myActualUser.phone_e164
	}

	get dispatchStatus(): string {
		return this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser().dispatcher_status
	}

	get activeStatusDateTime(): string {
		return this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser().dispatcher_active
	}

	get isNumberOverridden(): boolean {
		return !!this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser().c2c_phone_e164
	}

	get agentNumber(): string {
		const user = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
		const phoneE164 = user?.c2c_phone_e164 || user.phone_e164
		const agentPhone = PhoneHelper.formatPhoneFromE164(phoneE164)
		return `${agentPhone}`
	}

	public ngOnInit(): void {
		this.updateUI()
	}

	public ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	private handleC2CEventSubmitted(event: C2CEventSubmittedEvent) {
		if (event.success && event.action === 'CALL') this.returnFromAddressBook()
	}

	private updateUI(): void {
		this.coreSrvc.zone.run(() => {
			this.setupDispatchPhoneManager()
			this.setupPlaceCallPhoneManager()
		})
	}

	private clearWorking(): void {
		this.isWorking.signIn = false
		this.isWorking.signOut = false
		this.isWorking.clearNumber = false
		this.isWorking.updateNumber = false
	}

	private setupAvailabilityCheck() {
		const hasGlobal = this.coreSrvc.dbSrvc.settingSrvc.hasGlobalAccount()
		if (hasGlobal) {
			this.isCallCenterEnabled = this.coreSrvc.dbSrvc.settingSrvc.getGlobalCompany()?.cc_enable
		} else {
			this.isCallCenterEnabled = this.coreSrvc.dbSrvc.settingSrvc.getCompany()?.cc_enable
		}
	}

	private setupDispatchPhoneManager() {
		const user = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
		if (!this.dispatchPhoneManager) this.dispatchPhoneManager = new FormPhoneNumberManager()
		this.dispatchPhoneManager.e164 = user.c2c_phone_e164
		this.dispatchPhoneManager.label = `My Call Center Phone's #`
		this.dispatchPhoneManager.placeholder = PhoneHelper.formatPhoneFromE164(user.phone_e164)
		// this.dispatchPhoneManager.helpKey = 'dispatcherPhoneNumber'
		this.dispatchPhoneManager.configChange.next(true)
	}

	private setupPlaceCallPhoneManager() {
		if (!this.placeCallPhoneManager) this.placeCallPhoneManager = new FormPhoneNumberManager()
		this.placeCallPhoneManager.helpKey = 'placeCallNumber'
		this.placeCallPhoneManager.label = 'Phone Number'
		this.placeCallPhoneManager.isRequired = true
	}

	private reloadMyUser(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			const myUserId = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser().id
			this.coreSrvc.dbSrvc.readRecord('users', myUserId).then((result) => resolve(true))
		})
	}

	// Sign-in
	private signInAsDispatcher() {
		const loginPhone = this.dispatchPhoneManager.isNumberValid ? this.dispatchPhoneManager.e164 : null
		const request = new DataAccessRequest('none', 'cc_login', { phone_e164: loginPhone })
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			// Send Agent Sign In Event
			const myUser = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
			this.coreSrvc.eventSrvc.sendGenericTrackEvent('CALLCENTER', 'users', myUser.id, {
				action: 'SIGNIN',
				phone_e164: myUser.phone_e164 || null,
				c2c_phone_e164: myUser.c2c_phone_e164 || null,
			})

			this.reloadMyUser().then(() => {
				if (this.isWorking.signIn) {
					this.coreSrvc.notifySrvc.notify('success', 'Signed In', 'You are now active as a call center agent.', 4)
				}
				if (this.isWorking.updateNumber) {
					this.coreSrvc.notifySrvc.notify('success', 'Number Changed', 'Your agent phone number has been updated.', 4)
				}
				this.updateUI()
				this.clearWorking()
				this.vm.currentView = 'MAIN'
			})
		})
	}
	public signInAsDispatcherBtnClicked() {
		if (this.isWorking.signIn) return
		this.isWorking.signIn = true
		this.signInAsDispatcher()
	}

	// Sign-out
	private signOutAsDispatcher() {
		const request = new DataAccessRequest('none', 'cc_logout')
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			// Send Agent Sign Out Event
			const myUser = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
			this.coreSrvc.eventSrvc.sendGenericTrackEvent('CALLCENTER', 'users', myUser.id, {
				action: 'SIGNOUT',
				phone_e164: myUser.phone_e164 || null,
				c2c_phone_e164: myUser.c2c_phone_e164 || null,
			})

			this.reloadMyUser().then(() => {
				this.coreSrvc.notifySrvc.notify('error', 'Signed Out', 'You are no longer active as a call center agent.', 4)
				this.updateUI()
				this.clearWorking()
			})
		})
	}
	public signOutAsDispatcherBtnClicked() {
		if (this.isWorking.signOut) return
		this.isWorking.signOut = true
		this.signOutAsDispatcher()
	}

	// Update phone numbers
	private updatePhoneNumberForUserRecord() {
		const number = this.dispatchPhoneManager.e164
		const userRecord = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
		const updateRecord = new UserRecord(userRecord)
		updateRecord.c2c_phone_e164 = number
		this.coreSrvc.dbSrvc.updateRecord('users', updateRecord).then((success) => {
			if (success) {
				this.coreSrvc.notifySrvc.notify('success', 'Number Changed', 'Your agent phone number has been updated.', 4)
				this.returnFromOverrideNumber()
			}
			this.clearWorking()
			this.updateUI()
		})
	}

	public clearNumberOverride() {
		if (this.isWorking.clearNumber) return
		this.isWorking.clearNumber = true
		this.dispatchPhoneManager.e164 = null
		this.updatePhoneNumberBtnClicked()

		// DEPRECATED 20240425 - Only update phone number in user record whether signed in or not
		// if (this.isLoggedIn) {
		// 	this.signInAsDispatcher()
		// } else {
		// 	const number = this.dispatchPhoneManager.e164
		// 	const userRecord = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
		// 	const updateRecord = new UserRecord(userRecord)
		// 	updateRecord.c2c_phone_e164 = number
		// 	this.coreSrvc.dbSrvc.updateRecord('users', updateRecord).then((result) => {
		// 		this.coreSrvc.notifySrvc.notify('success', 'Number Changed', 'Your agent phone number has been updated.', 4)
		// 		this.clearWorking()
		// 		this.vm.currentView = 'MAIN'
		// 	})
		// }
	}

	public updatePhoneNumberBtnClicked() {
		if (this.isWorking.updateNumber) return
		this.isWorking.updateNumber = true
		this.updatePhoneNumberForUserRecord()

		// DEPRECATED 2024-04-25 - Only update phone number in user record whether signed in or not
		// if (this.isLoggedIn) {
		// 	this.signInAsDispatcher()
		// } else {
		// 	this.updatePhoneNumberForUserRecord()
		// }
	}

	private returnFromMakeOutgoingCall() {
		this.isWorking.placeCall = false
		this.isWorking.sendText = false
		this.placeCallName = ''
		this.placeCallMessage = ''
		this.placeCallPhoneManager.e164 = null
		this.placeCallPhoneManager.configChange.next(true)
		this.vm.currentView = 'MAIN'
		this.dialogManager.popStateAndApply()
		// this.dialogManager.backBtnAction = () => {}
		// this.dialogManager.isBackBtnVisible = false
		// this.dialogManager.isCancelBtnVisble = true
	}

	// Outgoing Calls

	public makeOutgoingCall() {
		this.dialogManager.pushState()
		this.isWorking.placeCall = false
		this.isWorking.sendText = false
		this.dialogManager.backBtnAction = () => this.returnFromMakeOutgoingCall()
		this.dialogManager.isBackBtnVisible = true
		this.dialogManager.cancelBtnLabel = 'Back'
		this.dialogManager.cancelBtnAction = () => this.returnFromMakeOutgoingCall()
		this.vm.currentView = 'PLACECALL'
	}

	public placeCallBtnClicked() {
		this.isWorking.placeCall = true
		const name = this.placeCallName || 'Outgoing Call'
		const number = this.placeCallPhoneManager.e164
		const c2cRec = new ClickToCallRecord('DISPATCH', name, null, number, null)

		const builder = new ClickToCallRequestBuilder(c2cRec, null)
		const request = builder.getLambdaRequest()
		log('Lambda Request', request)
		this.coreSrvc.dbSrvc.insertRecord('click_to_call_log', request).then((success) => {
			log('Call Request Sent')
			setTimeout(() => {
				this.coreSrvc.notifySrvc.notify('success', 'Request Sent', `Your call request to ${name} has been initiated.`, 3)
				this.returnFromMakeOutgoingCall()
			}, 500)
		})
	}

	public sendTextMsgBtnClicked() {
		this.isWorking.sendText = true
		const name = this.placeCallName || 'Outgoing Text'
		const number = this.placeCallPhoneManager.e164
		const c2cRec = new ClickToCallRecord('DISPATCH', name, null, number, null)
		const builder = new ClickToCallRequestBuilder(c2cRec, null)
		const request = builder.getLambdaRequest(this.placeCallMessage)
		log('SMS Lambda Request', request)
		this.coreSrvc.dbSrvc.insertRecord('click_to_call_log', request).then((success) => {
			log('SMS Request Sent')
			setTimeout(() => {
				this.coreSrvc.notifySrvc.notify('success', 'Message Sent', `Your text message to ${name} has been sent.`, 3)
				this.returnFromMakeOutgoingCall()
			}, 500)
		})
	}

	// Address Book
	public switchToAddressBook() {
		this.dialogManager.pushState()
		this.dialogManager.backBtnAction = () => this.returnFromAddressBook()
		this.dialogManager.isBackBtnVisible = true
		this.dialogManager.cancelBtnLabel = 'Back'
		this.dialogManager.cancelBtnAction = () => this.returnFromAddressBook()
		this.vm.currentView = 'ADDRESSBOOK'
	}

	public switchToUpdateNumber() {
		this.dialogManager.pushState()
		this.vm.currentView = 'UPDATENUMBER'
		this.dialogManager.isBackBtnVisible = true
		this.dialogManager.backBtnAction = () => this.returnFromOverrideNumber()
		this.dialogManager.cancelBtnLabel = 'Back'
		this.dialogManager.cancelBtnAction = () => this.returnFromOverrideNumber()
	}

	public returnFromOverrideNumber() {
		this.vm.currentView = 'MAIN'
		this.dialogManager.popStateAndApply()
	}

	public returnFromAddressBook() {
		this.vm.currentView = 'MAIN'
		this.dialogManager.popStateAndApply()
		// this.dialogManager.backBtnAction = () => {}
		// this.dialogManager.cancelBtnAction = null
		// this.dialogManager.cancelBtnLabel = 'Close'
		// this.dialogManager.isBackBtnVisible = false
		// this.dialogManager.isCancelBtnVisble = true
	}

	public showHelp(key: string) {
		// const dispatchNumberMsg = `This is the phone number of any desk or mobile phone you wish to use for call center services. All managed calls will be routed to this number. If no number is entered, the phone number you setup to sign into the admin portal will be used to receive calls.`
		const dispatchNumberMsg = `This is the phone number of the desk phone or mobile phone that you would like to use for outbound click-to-call phone calls and optionally, to receive incoming call center calls.\n\nNote: By default, the svstem will use the same mobile number that you use to sign into the admin portal.`
		switch (key) {
			case 'dispatcherPhoneNumber':
				const outboundHelpMsg = new HelpDialogMessage('Agent Number', dispatchNumberMsg)
				this.coreSrvc.notifySrvc.showHelp(outboundHelpMsg)
				break
			case 'placeCallName':
				const placeCallNameMsg = new HelpDialogMessage(
					'Name',
					'Enter the name of the party you are calling. If left blank Outgoing Call or Outgoing Text will be used for logging purposes.',
				)
				this.coreSrvc.notifySrvc.showHelp(placeCallNameMsg)
				break
			case 'placeCallNumber':
				const placeCallNumberMsg = new HelpDialogMessage('Phone Number', 'Enter the phone number of the party you wish to call or text.')
				this.coreSrvc.notifySrvc.showHelp(placeCallNumberMsg)
				break
			case 'placeCallMessage':
				const placeCallMessageMsg = new HelpDialogMessage('Message', 'Enter the text message you wish to send when using the Send Text option.')
				this.coreSrvc.notifySrvc.showHelp(placeCallMessageMsg)
				break
			default:
				this.coreSrvc.notifySrvc.showHelp(null)
		}
	}
}
