<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<form class="edit-form-block" novalidate [formGroup]="userForm" (keydown.enter)="$event.preventDefault()" autocomplete="off">
		<div class="options-block" [class.input-required]="!userForm.get('first_name').value">
			<div class="options-title-inline">First Name</div>
			<div *ngIf="!userForm.get('first_name').value" class="float-right">(required)</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="first_name" />
		</div>

		<div class="options-block" [class.input-required]="!userForm.get('last_name').value">
			<div *ngIf="!userForm.get('last_name').value" class="float-right">(required)</div>
			<div class="options-title-inline">Last Name</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="last_name" />
		</div>

		<div class="options-block">
			<!-- <div *ngIf="!userForm.get('last_name').value" class="input-required float-right">(required)</div> -->
			<div class="options-title-inline">External ID</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="external_id" />
		</div>

		<div class="options-block" [class.input-required]="!phoneNumber">
			<div *ngIf="!phoneNumber" class="float-right">(required)</div>
			<div class="options-title">Mobile Number</div>
			<div class="form-group">
				<table width="100%">
					<tr>
						<td style="width: 80px">
							<select
								class="form-control phone-country-select"
								[(ngModel)]="dialingCode"
								[ngModelOptions]="{ standalone: true }"
								(change)="formatNumber()"
							>
								<option *ngFor="let code of countryCodeData" value="{{ code.id }}">{{ code.id }}</option>
							</select>
						</td>
						<td>&nbsp;&nbsp;</td>
						<td style="max-width: 275px">
							<input
								(keyup)="formatNumber()"
								class="form-control"
								[class.is-valid]="isPhoneNumberValid"
								[(ngModel)]="phoneNumber"
								[ngModelOptions]="{ standalone: true }"
								type="tel"
								autocomplete="off"
							/>
						</td>
					</tr>
				</table>
			</div>
		</div>

		<!-- <app-form-phone-number [manager]="outboundC2CPhoneManager" (showHelp)="showHelp($event)"></app-form-phone-number> -->

		<div class="options-block">
			<div *ngIf="!userForm.get('email').value" class="input-optional float-right">(optional)</div>
			<div class="options-title-inline">Email Address</div>
			<input
				type="text"
				class="form-control"
				[class.is-valid]="userForm.get('email').value && isEmailValid()"
				autocomplete="off"
				formControlName="email"
			/>
		</div>

		<div *ngIf="allowedCompanies.dropdownVisible" class="options-block">
			<!-- <div *ngIf="!userForm.get('role').value" class="input-required float-right">(required)</div> -->
			<div class="options-title">Member Of</div>
			<p-multiselect
				appScrollLockMultiSelect
				placeholder="Select Companies"
				[maxSelectedLabels]="0"
				selectedItemsLabel="Companies Selected: {0}"
				[ngModelOptions]="{ standalone: true }"
				[options]="allowedCompanies.menuOptions"
				[(ngModel)]="allowedCompanies.selected"
			>
			</p-multiselect>

			<div *ngIf="false">
				{{ allowedCompanies.selected | json }}
			</div>
		</div>

		<!-- <div *ngIf="displayRoleDropdown" class="options-block" [class.input-required]="!userForm.get('role').value"> -->
		<div *ngIf="displaySupervisorTypeAndRolePermissions">
			<div class="options-block" [class.input-required]="!userForm.get('role').value">
				<div *ngIf="!userForm.get('role').value" class="input-required float-right">(required)</div>
				<div class="options-title-inline">Supervisor Type</div>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('role')"></i>
				<p-select appScrollLockSelect id="supervisorRole" [options]="roleDropdown" formControlName="role"></p-select>
			</div>

			<div *ngIf="userForm.get('role').value !== 'PRIMARY' && userForm.get('role').value !== 'NO_COGNITO'" class="options-block">
				<div class="options-title-inline">Role Permissions Template</div>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('supervisor_role_id')"></i>
				<p-select appScrollLockSelect id="rolePermissions" [options]="rolePermissionsDropdown" formControlName="supervisor_role_id"></p-select>
			</div>
		</div>

		<div *ngIf="shouldDisplayManagedByDropdown()" class="options-block">
			<div *ngIf="!userForm.get('managed_by').value" class="input-optional float-right">(optional)</div>
			<div class="options-title">Managed By</div>
			<p-select
				appScrollLockSelect
				id="managingSupervisor"
				placeholder="Select Manager"
				[options]="managerDropdown"
				formControlName="managed_by"
				[filter]="true"
				filterBy="label"
			></p-select>
		</div>

		<div *ngIf="roleDescription()" class="alert alert-warning">
			{{ roleDescription() }}
		</div>

		<!-- TTS-542: Deprecate Apply role permissions  -->
		<!-- <div *ngIf="false && isNew && userForm.get('role').value && !applyRolePermissions" class="alert alert-warning">
			This supervisor will <strong>NOT</strong> be restricted. They will be able to access and modify the records of any user. Check the box
			labeled '{{ this.isNew ? 'Apply' : 'Update' }} role permissions' to restrict access for this user.
		</div> -->

		<!-- TTS-542: Deprecate Apply role permissions  -->
		<!-- <div *ngIf="false">
			applyRolePermissions: {{ applyRolePermissions }}
			<br />
			<div>{{ userForm.value | json }}</div>
		</div> -->

		<!-- Bypass change notification for INTERNAL -->
		<div *ngIf="isAdminInternal" class="options-block">
			<div class="checkbox-item d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-checkbox [(ngModel)]="bypasChangesNotifications" [ngModelOptions]="{ standalone: true }" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap" style="top: 1px">
					<strong (click)="bypasChangesNotifications = !bypasChangesNotifications" class="has-pointer">Bypass Change Notifications</strong>
					<i
						title="More Information"
						class="far fa-info-circle tts-info-btn"
						aria-hidden="true"
						(click)="showHelp('bypassChangeNotification')"
					></i>
				</div>
			</div>
		</div>

		<!-- Form Expander Blocks -->
		<div style="margin-top: 35px">
			<app-form-expander
				*ngIf="shouldShowLinkedEmployeeOption"
				[style]="{ 'margin-top': '0px' }"
				[label]="'Linked Employee Options'"
				[isExpanded]="expandLinkedEmployeeOptions"
			>
				<div class="options-block">
					<div *ngIf="!userForm.get('linked_employee_id').value" class="input-optional float-right">(optional)</div>
					<div class="options-title-inline">Linked Employee</div>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('linked_employee_id')"></i>
					<p-select
						appScrollLockSelect
						id="linkedEmployee"
						[options]="linkedEmployeeDropdown"
						formControlName="linked_employee_id"
						[filter]="true"
						filterBy="label"
					></p-select>
				</div>

				<!-- Allow supervisor to edit their own time entries - target user cannot be primary -->
				<div *ngIf="!isThisUserPrimary && userForm.get('linked_employee_id').value" class="checkbox-item d-flex justify-content-between">
					<div class="checkbox-wrap">
						<p-checkbox formControlName="edit_own_trans" [binary]="true"></p-checkbox>
					</div>
					<div class="checkbox-label-wrap">
						<strong (click)="toggleCheckbox('edit_own_trans')" class="has-pointer">Allow this supervisor to edit their own time entries</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('edit_own_trans')"></i>
					</div>
				</div>
			</app-form-expander>

			<app-form-expander [style]="{ 'margin-top': '10px' }" [label]="'Notification Options'" [isExpanded]="expandNotificationOptions">
				<!-- Notification Options -->
				<div style="margin-bottom: 100px">
					<!-- Relay Incoming Text Messages -->
					<div class="options-block misc-options">
						<div class="options-checkbox" style="margin-bottom: 18px">
							<div class="row">
								<div class="col-1">
									<p-checkbox name="enableNotifications" formControlName="sms_email_enabled" [binary]="true"></p-checkbox>
								</div>
								<div class="col-11">
									<div style="margin-right: 20px">
										<strong (click)="toggleCheckbox('sms_email_enabled')" class="has-pointer">
											Relay employee text messages to this supervisor
										</strong>
										<i
											title="More Information"
											class="far fa-info-circle tts-info-btn"
											aria-hidden="true"
											(click)="showHelp('enableNotifications')"
										></i>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="userForm.get('sms_email_enabled').value" style="margin-left: 20px">
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-radioButton value="ALL" formControlName="email_target"></p-radioButton>
								</div>
								<div class="checkbox-label-wrap">
									<div (click)="userForm.get('email_target').setValue('ALL')"><strong>From all employees</strong></div>
								</div>
							</div>

							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-radioButton value="ASSIGNED" formControlName="email_target"></p-radioButton>
								</div>
								<div class="checkbox-label-wrap">
									<div (click)="userForm.get('email_target').setValue('ASSIGNED')">
										<strong>Only from employees assigned to this supervisor</strong>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- Notify on time off requests -->
					<div class="options-block misc-options">
						<div class="options-checkbox" style="margin-bottom: 18px">
							<div class="row">
								<div class="col-1">
									<p-checkbox name="timeoffNotifications" formControlName="time_off_enabled" [binary]="true"></p-checkbox>
								</div>
								<div class="col-11">
									<div style="margin-right: 20px">
										<strong (click)="toggleCheckbox('time_off_enabled')" class="has-pointer">
											Notify this supervisor for new employee submitted time off requests and approval status changes
										</strong>
										<i
											title="More Information"
											class="far fa-info-circle tts-info-btn"
											aria-hidden="true"
											(click)="showHelp('time_off_enabled')"
										></i>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="userForm.get('time_off_enabled').value" style="margin-left: 20px">
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-radioButton value="ALL" formControlName="time_off_target"></p-radioButton>
								</div>
								<div class="checkbox-label-wrap">
									<div (click)="userForm.get('time_off_target').setValue('ALL')"><strong>From all employees</strong></div>
								</div>
							</div>

							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-radioButton value="ASSIGNED" formControlName="time_off_target"></p-radioButton>
								</div>
								<div class="checkbox-label-wrap">
									<div (click)="userForm.get('time_off_target').setValue('ASSIGNED')">
										<strong>Only from employees assigned to this supervisor</strong>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- Notify max hours exceeded -->
					<div *ngIf="shouldShowMaxDailyHoursNotification" class="options-block misc-options">
						<div class="options-checkbox" style="margin-bottom: 18px">
							<div class="row">
								<div class="col-1">
									<p-checkbox name="maxDailyHoursNotification" formControlName="max_daily_enabled" [binary]="true"></p-checkbox>
								</div>
								<div class="col-11">
									<div style="margin-right: 20px">
										<strong (click)="toggleCheckbox('max_daily_enabled')" class="has-pointer">
											Notify this supervisor when an employee's daily hours worked exceeds the maximum daily hours threshold
										</strong>
										<i
											title="More Information"
											class="far fa-info-circle tts-info-btn"
											aria-hidden="true"
											(click)="showHelp('max_daily_enabled')"
										></i>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="userForm.get('max_daily_enabled').value" style="margin-left: 20px">
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-radioButton value="ALL" formControlName="max_daily_target"></p-radioButton>
								</div>
								<div class="checkbox-label-wrap">
									<div (click)="userForm.get('max_daily_target').setValue('ALL')"><strong>For any employee</strong></div>
								</div>
							</div>

							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-radioButton value="ASSIGNED" formControlName="max_daily_target"></p-radioButton>
								</div>
								<div class="checkbox-label-wrap">
									<div (click)="userForm.get('max_daily_target').setValue('ASSIGNED')">
										<strong>Only for employees assigned to this supervisor</strong>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- Notify schedule open shift offers -->
					<div *ngIf="canAccessOpenShiftOffers" class="options-block misc-options">
						<div class="options-checkbox" style="margin-bottom: 18px">
							<div class="row">
								<div class="col-1">
									<p-checkbox
										name="scheduleOpenShiftOfferNotification"
										formControlName="schedule_offer_enabled"
										[binary]="true"
									></p-checkbox>
								</div>
								<div class="col-11">
									<div style="margin-right: 20px">
										<strong (click)="toggleCheckbox('schedule_offer_enabled')" class="has-pointer">
											Notify this supervisor when an employee responds to an open shift offer
										</strong>
										<i
											title="More Information"
											class="far fa-info-circle tts-info-btn"
											aria-hidden="true"
											(click)="showHelp('schedule_offer_enabled')"
										></i>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="userForm.get('schedule_offer_enabled').value" style="margin-left: 20px">
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-radioButton value="ALL" formControlName="schedule_offer_target"></p-radioButton>
								</div>
								<div class="checkbox-label-wrap">
									<div (click)="userForm.get('schedule_offer_target').setValue('ALL')"><strong>From all employees</strong></div>
								</div>
							</div>

							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-radioButton value="ASSIGNED" formControlName="schedule_offer_target"></p-radioButton>
								</div>
								<div class="checkbox-label-wrap">
									<div (click)="userForm.get('schedule_offer_target').setValue('ASSIGNED')">
										<strong>Only from employees assigned to this supervisor</strong>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- Notify on modifying date locked time entries -->
					<div class="options-block misc-options">
						<div class="options-checkbox" style="margin-bottom: 18px">
							<div class="row">
								<div class="col-1">
									<p-checkbox name="dateLockedTimeEntriesNotification" formControlName="date_lock_enabled" [binary]="true"></p-checkbox>
								</div>
								<div class="col-11">
									<div style="margin-right: 20px">
										<strong (click)="toggleCheckbox('date_lock_enabled')" class="has-pointer">
											Notify this supervisor when time entry date lock violations occur
										</strong>
										<i
											title="More Information"
											class="far fa-info-circle tts-info-btn"
											aria-hidden="true"
											(click)="showHelp('date_lock_enabled')"
										></i>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="userForm.get('date_lock_enabled').value" style="margin-left: 20px">
							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-radioButton value="ALL" formControlName="date_lock_target"></p-radioButton>
								</div>
								<div class="checkbox-label-wrap">
									<div (click)="userForm.get('date_lock_target').setValue('ALL')"><strong>For all employees</strong></div>
								</div>
							</div>

							<div class="checkbox-item d-flex justify-content-between">
								<div class="checkbox-wrap">
									<p-radioButton value="ASSIGNED" formControlName="date_lock_target"></p-radioButton>
								</div>
								<div class="checkbox-label-wrap">
									<div (click)="userForm.get('date_lock_target').setValue('ASSIGNED')">
										<strong>Only for employees assigned to this supervisor</strong>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</app-form-expander>
		</div>

		<!-- <hr style="margin: 30px 0px;"> -->

		<div [hidden]="true">
			<button class="btn btn-sm btn-danger" type="button" (click)="cancel()">Cancel</button>
			<button class="btn btn-sm btn-success" type="button" [disabled]="!isFormValid()" style="margin-left: 15px" (click)="submit()">Save</button>
		</div>
	</form>
</div>
