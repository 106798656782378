import { Component, OnInit, Input } from '@angular/core'
import { log } from '@app/helpers'

import {
	IncidentReportContact,
	IncidentReportContactType,
	IncidentReportDepartmentContact,
	IncidentReportInjuredContact,
	ReportFieldContactPhoneInput,
	ReportFieldGenericInput,
	ReportGlobals,
	UserReportConfiguration,
	UserReportValidator,
} from '@app/models'

import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js'

import _ from 'lodash'

@Component({
    selector: 'app-incident-contact',
    templateUrl: './incident-contact.component.html',
    styleUrls: ['./incident-contact.component.scss'],
    standalone: false
})
export class IncidentContactComponent implements OnInit {
	format12Hour = ReportGlobals.displayPrefs.format12Hour
	datePickerFormat = ReportGlobals.getDatePickerFormat('DATETIME')

	@Input() type: IncidentReportContactType = null
	@Input() limit = null
	@Input() contacts: Array<IncidentReportContact> = []
	@Input() departments: Array<IncidentReportDepartmentContact> = []
	@Input() injured: Array<IncidentReportInjuredContact> = []
	@Input() reportConfig: UserReportConfiguration

	public typeLabel = ''
	private countryCode: CountryCode

	constructor() {
		// Don't do anything in constructor, component needs data from above.
		// Don't make calls to any service. Place needed data in reportConfig
	}

	ngOnInit(): void {
		this.countryCode = this.reportConfig.countryCode

		switch (this.type) {
			case 'INJURED':
				this.typeLabel = 'injured party'
				break
			case 'DEPARTMENT':
				this.typeLabel = 'police/fire/ems'
				break
			default:
				this.typeLabel = this.type.toLowerCase()
				break
		}
	}

	get isDefaultContact(): boolean {
		switch (this.type) {
			case 'DEPARTMENT':
				return false
			case 'INJURED':
				return false
			default:
				return true
		}
	}

	addContact() {
		switch (this.type) {
			case 'DEPARTMENT':
				const deptContact = new IncidentReportDepartmentContact()
				this.departments.push(deptContact)
				break
			case 'INJURED':
				const injuredContact = new IncidentReportInjuredContact()
				this.injured.push(injuredContact)
				break
			default:
				const generalContact = new IncidentReportContact({ type: this.type })
				this.contacts.push(generalContact)
		}
	}

	removeContact(idx: number) {
		switch (this.type) {
			case 'DEPARTMENT':
				this.departments.splice(idx, 1)
				break
			case 'INJURED':
				this.injured.splice(idx, 1)
				break
			default:
				this.contacts.splice(idx, 1)
		}
	}

	addNotes(contact: any) {
		contact.notes = new ReportFieldGenericInput({ style: 'TEXTAREA', skip: null })
	}

	capitalize(word: string) {
		return _.startCase(word)
	}

	contactDateLabel(type: IncidentReportContactType) {
		switch (type) {
			case 'WITNESS':
				return 'Statement Taken On'
			case 'INFORMANT':
				return 'Contact Date'
			default:
				return `${this.capitalize(this.typeLabel)} Contacted On`
		}
	}

	showDatePicker(type: IncidentReportContactType): boolean {
		if (type === 'CUSTOMER' || type === 'SUPERVISOR') {
			return true
		}
		return false
	}

	validateEmail(contact: any, field: string) {
		if (contact.hasOwnProperty(field)) {
			const email = contact[field]
			if (email) {
				const value = email.value
				if (!value) {
					email.valid = null
					return
				}
				if (value) {
					log('Email value', value)
					const isValid = UserReportValidator.validateEmailAddress(value)
					log('isValid', isValid)
					if (isValid) {
						email.valid = true
					} else {
						email.valid = false
					}
				}
			}
			log('Validated email', email)
		}
	}

	validatePhone(contact: any, field: string) {
		if (contact.hasOwnProperty(field)) {
			const phone = contact[field] as ReportFieldContactPhoneInput
			if (phone) {
				const number = phone.value
				if (!number) {
					phone.valid = null
					return
				}
				let phoneNumber
				if (number.includes('+')) {
					phoneNumber = parsePhoneNumberFromString(number)
				} else {
					phoneNumber = parsePhoneNumberFromString(number, this.countryCode)
				}
				if (phoneNumber && phoneNumber.isValid()) {
					phone.value = phoneNumber.formatInternational()
					phone.valid = true
				} else {
					phone.valid = false
				}
			}
		}
	}

	elementFocused(event: any) {
		log('Event', event)
		const id = event.target?.id
		// Remove any validation border colorering
		if (id) {
			$(`#${id}`).removeClass('ur-input-needs-info')
		}
	}

	phoneConfirmBtnClicked(phone: ReportFieldContactPhoneInput) {
		phone.confirmed = !phone.confirmed
	}

	shouldShowPhoneAlertFor(phone: ReportFieldContactPhoneInput) {
		if (!phone.value || phone.skip || phone.confirmed) {
			return false
		}
		if (phone.value && phone.valid === false) {
			return true
		}
		return false
	}
}

// +448081969975
