<div class="container section-container" style="margin-top: 40px; max-width: 800px">
	<div
		class="loading-banner d-flex align-items-center justify-content-center"
		*ngIf="isLoadingData || isUpdatingCard || isDeletingCard"
		style="margin-top: 20px; min-height: 58px"
	>
		<div *ngIf="isLoadingData"><i class="fas fa-loader fa-spin" aria-hidden="true"></i> Billing Check</div>
		<div *ngIf="isUpdatingCard"><i class="fas fa-loader fa-spin" aria-hidden="true"></i> Processing Card</div>
		<div *ngIf="isDeletingCard"><i class="fas fa-loader fa-spin" aria-hidden="true"></i> Removing Card</div>
	</div>

	<div *ngIf="isInternalUser" class="card card-body" style="margin-top: 20px; font-weight: 600">
		<div>
			<div class="float-right" [style.color]="internalUserBanner.color">{{ internalUserBanner.label }}</div>
			<div>Account Status</div>
		</div>
	</div>

	<!-- Billing Contacts -->
	<div style="margin-top: 20px">
		<app-billing-contacts></app-billing-contacts>
	</div>

	<!-- Billing Info / Next Billing Date -->
	<div [hidden]="isLoadingData">
		<app-billing-date-info></app-billing-date-info>
	</div>

	<!-- Billing Notes -->
	<div [hidden]="isLoadingData" style="margin-top: 20px">
		<app-billing-status-alert></app-billing-status-alert>
	</div>

	<!-- Stripe Billing -->
	<div *ngIf="isStripeCustomer && !isTestingAccount" [hidden]="isLoadingData" style="margin-top: 20px">
		<app-billing-stripe (stripeBlockSetup)="isLoadingStripeData = false"></app-billing-stripe>
	</div>

	<!-- PayPal Billing -->
	<div *ngIf="isPayPalCustomer && !isTestingAccount && !isInvoice && !isPrepaid" [hidden]="isLoadingData" style="margin-top: 20px">
		<app-billing-paypal
			(cardBlockSetup)="isLoadingPayPalData = false"
			(isUpdatingCard)="isUpdatingCard = $event"
			(isDeletingCard)="isDeletingCard = $event"
		></app-billing-paypal>
	</div>

	<!-- Billing History -->
	<div *ngIf="isStripeCustomer && hasBillingHistory && !isLoadingData" style="margin-top: 20px">
		<app-billing-log [billingHistory]="billingHistory"></app-billing-log>
	</div>
</div>
