export class ParserHelper {
	static deepCloneWithGetters<T>(obj: T): T {
		if (obj === null || typeof obj !== 'object') {
			return obj // Return the value if obj is not an object
		}

		// Handle arrays
		if (Array.isArray(obj)) {
			return obj.map((item) => ParserHelper.deepCloneWithGetters(item)) as unknown as T
		}

		// Create a new object with the same prototype as the original
		const clone = Object.create(Object.getPrototypeOf(obj))

		// Get all property names (including non-enumerable ones) and symbols
		const props = (Object.getOwnPropertyNames(obj) as (string | symbol)[]).concat(Object.getOwnPropertySymbols(obj) as (string | symbol)[])

		props.forEach((prop) => {
			const descriptor = Object.getOwnPropertyDescriptor(obj, prop)
			if (descriptor.get || descriptor.set) {
				// If the property has a getter or setter, define it on the clone
				Object.defineProperty(clone, prop, descriptor)
			} else if (typeof descriptor.value === 'object' && descriptor.value !== null) {
				// If the property is an object, deep clone it
				descriptor.value = ParserHelper.deepCloneWithGetters(descriptor.value)
				Object.defineProperty(clone, prop, descriptor)
			} else {
				// Otherwise, just copy the property
				Object.defineProperty(clone, prop, descriptor)
			}
		})

		return clone
	}
}
