<!-- Descriptions -->
<div class="options-block" [class.input-required]="!card.rule.description">
	<div class="options-title-inline">Break Description</div>
	<div *ngIf="!card.rule.description" class="float-right">(required)</div>
	<input type="text" class="form-control" autocomplete="off" [(ngModel)]="card.rule.description" />
</div>

<div class="options-block">
	<div class="options-title-inline">Payroll Type</div>
	<p-select appScrollLockSelect scrollHeight="220px" [options]="payrollTypeOptions" [(ngModel)]="card.rule.payrollType"></p-select>
</div>

<div class="options-section-header" style="margin-top: 25px">
	Managed By
	<i
		title="More Information"
		class="far fa-info-circle tts-info-btn"
		style="font-size: 1rem; margin-left: 0px"
		(click)="showHelp('breakOptions')"
	></i>
</div>

<!-- Managed By Type -->
<div class="radio-wrap" style="margin: 18px 0 26px">
	<div class="form-check form-check-inline">
		<input type="radio" class="form-check-input" id="managedBySystem" [value]="'SYSTEM'" [(ngModel)]="card.rule.managedByType" />
		<label
			class="form-check-label has-pointer"
			[class.tts-hl-2]="card.rule.managedByType === 'SYSTEM'"
			for="managedBySystem"
			style="font-weight: bold"
			>System</label
		>
	</div>
	<div class="form-check form-check-inline" style="margin-left: 20px">
		<input type="radio" class="form-check-input" id="managedByEmployee" [value]="'EMPLOYEE'" [(ngModel)]="card.rule.managedByType" />
		<label
			class="form-check-label has-pointer"
			[class.tts-hl-2]="card.rule.managedByType === 'EMPLOYEE'"
			for="managedByEmployee"
			style="font-weight: bold"
			>Employee</label
		>
	</div>
</div>

<div class="options-section-header" style="margin-top: 25px">Schedule</div>

<div class="card offset-duration" style="margin-top: 16px">
	<div>
		<span class="mr-8">Break {{ card.rule.managedByType === 'SYSTEM' ? 'starts' : 'should start' }}</span>
		<input
			id="startOffset"
			type="text"
			placeholder="hr:min"
			class="form-control form-inline inline-duration mr-8"
			[(ngModel)]="card.rule.startOffset"
			(change)="validateDurationHrsAndMinutes('startOffset')"
			(focus)="selectOnFocus($event)"
		/>
		<span
			>(hr:min) after employee clocks in for their shift and {{ card.rule.managedByType === 'SYSTEM' ? 'lasts' : 'last' }} for
			<input
				id="duration"
				type="tel"
				placeholder="min"
				class="form-control form-inline inline-number mr-8"
				[(ngModel)]="card.rule.duration"
				(change)="validateNumericInput('duration')"
				(focus)="selectOnFocus($event)"
			/>minutes</span
		>
	</div>
</div>

<div *ngIf="card.rule.managedByType === 'EMPLOYEE'">
	<div class="options-section-header" style="margin-top: 25px">Compliance Options</div>

	<!-- Start Break Reminder -->
	<div class="checkbox-item d-flex justify-content-between" style="margin-top: 16px">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="card.rule.startBreakReminder" [binary]="true" (onChange)="validateCheckboxBlock('startBreakReminder')"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap" style="line-height: 2rem; min-height: 60px">
			<div (click)="toggleCheckbox('startBreakReminder')">
				<i
					#startCycleIcon
					*ngIf="showOptionCycleBtn"
					title="Next Option"
					class="fad fas fa-arrows-spin link-text cycle-icon"
					(click)="$event.stopPropagation(); rotateIcon(startCycleIcon); card.rule.advanceTimePosition('startBreakReminderOffsetType')"
				></i>

				<div style="padding-right: 20px">
					<span *ngIf="card.rule.startBreakReminderOffsetType === 'AT_EVENT'"
						>Send employee a reminder when their scheduled break has started</span
					>

					<span *ngIf="card.rule.startBreakReminderOffsetType !== 'AT_EVENT'">
						<span class="mr-8">Send employee a reminder</span>
						<input
							id="startBreakReminderOffset"
							type="tel"
							placeholder="min"
							class="form-control form-inline inline-number mr-8"
							[(ngModel)]="card.rule.startBreakReminderOffset"
							(change)="validateNumericInput('startBreakReminderOffset')"
							(focus)="selectOnFocus($event)"
							(click)="$event.stopPropagation()"
						/>
					</span>

					<span *ngIf="card.rule.startBreakReminderOffsetType === 'BEFORE_EVENT'"
						>minutes <b style="color: chocolate">before</b> their scheduled break will start</span
					>
					<span *ngIf="card.rule.startBreakReminderOffsetType === 'AFTER_EVENT'"
						>minutes <b style="color: chocolate">after</b> their scheduled break has started</span
					>

					<i
						title="More Information"
						class="far fa-info-circle tts-info-btn"
						(click)="$event.stopPropagation(); showHelp('startBreakReminder')"
					></i>
				</div>
			</div>
		</div>
	</div>

	<hr />

	<!-- End Break Reminder -->
	<div class="checkbox-item d-flex justify-content-between" style="margin-top: 24px">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="card.rule.endBreakReminder" [binary]="true" (onChange)="validateCheckboxBlock('endBreakReminder')"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap" style="line-height: 2rem; min-height: 60px">
			<div (click)="toggleCheckbox('endBreakReminder')">
				<i
					#endCycleIcon
					*ngIf="showOptionCycleBtn"
					title="Next Option"
					class="fad fas fa-arrows-spin link-text cycle-icon"
					(click)="$event.stopPropagation(); rotateIcon(endCycleIcon); card.rule.advanceTimePosition('endBreakReminderOffsetType')"
				></i>

				<div style="padding-right: 20px">
					<span *ngIf="card.rule.endBreakReminderOffsetType === 'AT_EVENT'">Send employee a reminder when their scheduled break has ended</span>

					<span *ngIf="card.rule.endBreakReminderOffsetType !== 'AT_EVENT'">
						<span class="mr-8">Send employee a reminder</span>
						<input
							id="endBreakReminderOffset"
							type="tel"
							placeholder="min"
							class="form-control form-inline inline-number mr-8"
							[(ngModel)]="card.rule.endBreakReminderOffset"
							(change)="validateNumericInput('endBreakReminderOffset')"
							(click)="$event.stopPropagation()"
							(focus)="selectOnFocus($event)"
						/>
					</span>

					<span *ngIf="card.rule.endBreakReminderOffsetType === 'BEFORE_EVENT'"
						>minutes <b style="color: chocolate">before</b> their scheduled break will end</span
					>
					<span *ngIf="card.rule.endBreakReminderOffsetType === 'AFTER_EVENT'"
						>minutes <b style="color: chocolate">after</b> their scheduled break has ended</span
					>

					<i
						title="More Information"
						class="far fa-info-circle tts-info-btn"
						(click)="$event.stopPropagation(); showHelp('endBreakReminder')"
					></i>
				</div>
			</div>
		</div>
	</div>

	<hr />

	<!-- Flag break not started by offset -->
	<div class="checkbox-item d-flex justify-content-between" style="margin-top: 28px">
		<div class="checkbox-wrap">
			<p-checkbox
				[(ngModel)]="card.rule.flagBreakNotStartedBy"
				[binary]="true"
				(onChange)="validateCheckboxBlock('flagBreakNotStartedBy')"
			></p-checkbox>
		</div>
		<div class="checkbox-label-wrap" style="margin-right: 10px">
			<div style="line-height: 2rem">
				<span class="mr-8" (click)="toggleCheckbox('flagBreakNotStartedBy')"
					>Flag associated time entry if employee does not start this break within</span
				>

				<input
					id="flagBreakNotStartedByOffset"
					type="text"
					placeholder="hr:min"
					class="form-control form-inline inline-duration mr-8"
					[(ngModel)]="card.rule.flagBreakNotStartedByOffset"
					(change)="validateDurationHrsAndMinutes('flagBreakNotStartedByOffset')"
					(focus)="selectOnFocus($event)"
				/>

				<span (click)="toggleCheckbox('flagBreakNotStartedBy')">(hr:min) after clocking in</span>

				<i
					title="More Information"
					class="far fa-info-circle tts-info-btn"
					(click)="$event.stopPropagation(); showHelp('flagBreakNotStartedBy')"
				></i>
			</div>
		</div>
	</div>

	<hr />

	<!-- Flag break if duration is less than indicated -->
	<div class="checkbox-item d-flex justify-content-between" style="margin-top: 28px">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="card.rule.flagShortBreak" [binary]="true" (onChange)="validateCheckboxBlock('flagShortBreak')"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap" style="margin-right: 10px">
			<div style="line-height: 2rem">
				<span class="mr-8" (click)="toggleCheckbox('flagShortBreak')">Flag associated time entry if employee remains on break for less than</span>

				<input
					id="flagShortBreakDuration"
					type="tel"
					placeholder="min"
					class="form-control form-inline inline-number mr-8"
					[(ngModel)]="card.rule.flagShortBreakDuration"
					(change)="validateNumericInput('flagShortBreakDuration')"
					(focus)="selectOnFocus($event)"
				/>

				<span (click)="toggleCheckbox('flagShortBreak')">minutes</span>

				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="$event.stopPropagation(); showHelp('flagShortBreak')"></i>
			</div>
		</div>
	</div>

	<hr />

	<!-- End Break Reminder -->
	<div class="checkbox-item d-flex justify-content-between" style="margin-top: 28px">
		<div class="checkbox-wrap">
			<p-checkbox [(ngModel)]="card.rule.confirmEndBreakEarly" [binary]="true"></p-checkbox>
		</div>
		<div class="checkbox-label-wrap" style="margin-right: 10px">
			<div style="line-height: 2rem" (click)="toggleCheckbox('confirmEndBreakEarly')">
				<span>Require confirmation from employee when ending this break early</span>

				<i
					title="More Information"
					class="far fa-info-circle tts-info-btn"
					(click)="$event.stopPropagation(); showHelp('confirmEndBreakEarly')"
				></i>
			</div>
		</div>
	</div>
</div>

<!-- <div class="pre-code">{{ card.rule | json }}</div> -->
