<div class="diff-nav-block">
	<div *ngIf="isViewingFullRecord">
		<button class="btn btn-sm btn-outline-primary" (click)="viewFullRecord = null">Done</button>
	</div>
	<div *ngIf="!isViewingFullRecord">
		<div (click)="moveToNext()">
			<i class="fa fa-chevron-up diff-nav-icon" [class.diff-nav-icon-disabled]="!canMoveToNext"></i>
		</div>
		<div (click)="moveToPrevious()">
			<i class="fa fa-chevron-down diff-nav-icon" [class.diff-nav-icon-disabled]="!canMoveToPrevious"></i>
		</div>
	</div>
</div>

<!-- <div class="float-left">{{ currentOperation }}</div> -->

<div *ngIf="isDataLoaded">
	<div *ngIf="!isViewingFullRecord">
		<!-- If showing a login or track operation, always show the same event header -->
		<div *ngIf="isCurrentOperationLogin || isCurrentOperationTrack">
			<div *ngIf="isCurrentOperationLogin" class="header-label" style="color: chocolate">User Login Event</div>
			<div *ngIf="isCurrentOperationTrack" class="header-label" style="color: chocolate">Tracking Event</div>
			<div *ngIf="currentAdminUsername" class="diff-user-name"><b>For:</b> {{ currentAdminUsername }}</div>
			<div *ngIf="currentRecordDate" class="diff-cur-date">{{ currentRecordDate }}</div>
			<div *ngFor="let item of makePropertyListArray(currentData, false)">
				<div class="item-name">{{ propertyNameFormatter(item.name) }}</div>
				<div class="item-value" [innerHTML]="propertyValueFormatter(item.name, item.value, 'CURRENT', isViewingFullRecord) | sanitizer"></div>
			</div>
		</div>

		<!-- If showing the first record and it's not a login or track operation -->
		<div *ngIf="showCurrentRecord && !isCurrentOperationLogin && !isCurrentOperationTrack">
			<div class="header-initial" style="color: #014c8c">Initial Log Entry</div>
			<div *ngIf="currentAdminUsername" class="diff-user-name"><b>By:</b> {{ currentAdminUsername }}</div>
			<div *ngIf="currentRecordDate" class="diff-cur-date">{{ currentRecordDate }}</div>
			<div *ngIf="records.length > 1" style="margin-bottom: 20px"></div>
			<div *ngIf="records.length === 1" class="no-changes-initial">No changes to compare</div>
			<div *ngFor="let item of makePropertyListArray(currentData, false)">
				<div class="item-name">{{ propertyNameFormatter(item.name) }}</div>
				<div class="item-value" [innerHTML]="propertyValueFormatter(item.name, item.value, 'CURRENT', isViewingFullRecord) | sanitizer"></div>
			</div>
		</div>

		<!-- If not the initial record and not a login or track operation -->
		<div *ngIf="!showCurrentRecord">
			<div *ngIf="isCurrentOperationDelete">
				<div class="header-label" style="color: firebrick">Record Deleted</div>
				<div *ngIf="currentAdminUsername" class="diff-user-name"><b>By:</b> {{ currentAdminUsername }}</div>
				<div *ngIf="currentRecordDate" class="diff-cur-date">{{ currentRecordDate }}</div>
				<div class="no-changes-msg">No changes to compare</div>
			</div>

			<div *ngIf="isCurrentOperationInsert">
				<div class="header-label">Record Inserted</div>
				<div *ngIf="currentAdminUsername" class="diff-user-name"><b>By:</b> {{ currentAdminUsername }}</div>
				<div *ngIf="currentRecordDate" class="diff-cur-date">{{ currentRecordDate }}</div>
				<div class="no-changes-msg">No changes to compare</div>
			</div>

			<div *ngIf="isCurrentOperationUpdate">
				<div *ngIf="hasChanges">
					<div class="header-label">Changes Made</div>
					<div *ngIf="currentAdminUsername" class="diff-user-name"><b>By:</b> {{ currentAdminUsername }}</div>
					<div *ngIf="currentRecordDate" class="diff-cur-date">{{ currentRecordDate }}</div>
					<div *ngFor="let item of makePropertyListArray(difference, false)">
						<div class="item-name">{{ propertyNameFormatter(item.name) }}</div>
						<div
							class="item-value"
							[innerHTML]="propertyValueFormatter(item.name, item.value, 'CURRENT', isViewingFullRecord) | sanitizer"
						></div>
					</div>
					<span class="link-text" (click)="viewFullRecord = 'CURRENT'"><i class="far fa-eye"></i> view full record</span>

					<hr style="margin-top: 35px; margin-bottom: 25px" />

					<div class="header-label margin-b-20" style="color: firebrick">From Previous Record</div>
					<div *ngFor="let item of makePropertyListArray(previousData, true)">
						<div class="item-name">{{ propertyNameFormatter(item.name) }}</div>
						<div
							class="item-value"
							[innerHTML]="propertyValueFormatter(item.name, item.value, 'PREVIOUS', isViewingFullRecord) | sanitizer"
						></div>
					</div>
					<span class="link-text" (click)="viewFullRecord = 'PREVIOUS'"><i class="far fa-eye"></i> view full record</span>
				</div>

				<div *ngIf="!hasChanges" class="no-changes-block">
					<div class="header-label">Changes Made</div>
					<div *ngIf="currentAdminUsername" class="diff-user-name"><b>By:</b> {{ currentAdminUsername }}</div>
					<div *ngIf="currentRecordDate" class="diff-cur-date">{{ currentRecordDate }}</div>
					<div class="no-changes-msg">No changes to record</div>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="isViewingFullRecord">
		<div class="header-label" style="margin-bottom: 18px">Viewing Full Record</div>
		<div *ngIf="viewFullRecord === 'CURRENT'">
			<div *ngFor="let item of makePropertyListArray(currentData, false)">
				<div class="item-name">{{ propertyNameFormatter(item.name) }}</div>
				<div class="item-value" [innerHTML]="propertyValueFormatter(item.name, item.value, 'CURRENT', isViewingFullRecord) | sanitizer"></div>
			</div>
		</div>
		<div *ngIf="viewFullRecord === 'PREVIOUS'">
			<div *ngFor="let item of makePropertyListArray(fullPreviousRecord, false)">
				<div class="item-name">{{ propertyNameFormatter(item.name) }}</div>
				<div class="item-value" [innerHTML]="propertyValueFormatter(item.name, item.value, 'PREVIOUS', isViewingFullRecord) | sanitizer"></div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="!hasData" class="no-data">No Records Available</div>
