<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating" style="max-width: 425px; margin: 0 auto">
	<form class="form-block" novalidate [formGroup]="rulesForm" (keydown.enter)="$event.preventDefault()" autocomplete="off">
		<!-- Placeholder for Enabled Switch -->

		<div *ngIf="childEntryCount > 0">
			<div class="alert alert-danger alert-note">
				This entry has {{ childEntryCount }} dependent schedule{{ childEntryCount > 1 ? 's' : '' }} which will be cleared if you modify this
				entry.
			</div>
		</div>

		<div>
			<div *ngIf="false" class="options-block">
				<div class="options-title-inline">Schedule Type</div>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('scheduleType')"></i>
				<p-select
					appScrollLockSelect
					id="scheduleType"
					[options]="scheduleTypeDropdown"
					[(ngModel)]="selectedScheduleType"
					[ngModelOptions]="{ standalone: true }"
					(onChange)="doChangeScheduleTypeDropdown()"
				>
				</p-select>
			</div>

			<!-- Single employee dropdown with Any Employee as an option -->
			<div
				*ngIf="selectedScheduleType !== ScheduleType.multiEmployee"
				class="options-block"
				[class.input-required]="rulesForm.get('employee_id').value === null"
			>
				<div #pinTo class="options-title-inline">Employee</div>
				<span *ngIf="rulesForm.get('employee_id').value === null" class="input-required float-right">(required)</span>
				<p-select
					appScrollLockSelect
					formControlName="employee_id"
					[options]="employeesDropdown"
					[autofocusFilter]="true"
					[filter]="true"
					filterBy="label,data.external_id"
					[autofocusFilter]="devDetect.isDesktop()"
					(onChange)="doChangeSingleEmployeDropdown()"
				></p-select>
			</div>

			<div
				*ngIf="rulesForm.get('employee_id').value === 0"
				class="options-block"
				[class.input-required]="!(rulesForm.get('employee_count').value > 0)"
				style="margin-top: 10px; margin-bottom: 10px"
			>
				<div class="form-label-bold d-flex flex-row align-items-center">
					<span class="mr-8">Schedule</span>
					<input
						type="tel"
						class="form-control emp-count-input mr-8"
						formControlName="employee_count"
						(change)="validateNumericInput('employee_count')"
					/>
					<span class="mr-5">employee(s)</span>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('employee_count')"></i>
				</div>
			</div>

			<div class="options-block" [class.input-required]="!rulesForm.get('job_id').value">
				<span *ngIf="rulesForm.get('job_id').value" class="tts-hl-2 float-right">{{ displayShiftInfo() }}</span>
				<span *ngIf="!rulesForm.get('job_id').value" class="input-required float-right">(required)</span>
				<div class="options-title">Job</div>
				<p-select
					appScrollLockSelect
					id="job"
					formControlName="job_id"
					[options]="jobsDropdown"
					[filter]="true"
					filterBy="label,data.external_id"
					[resetFilterOnHide]="true"
					[autofocusFilter]="devDetect.isDesktop()"
					(onChange)="selectJob()"
				></p-select>
			</div>
		</div>

		<div *ngIf="duplicateJobFound">
			<div class="alert alert-warning">Employee has another schedule entry for this job, please review their schedule for overlap.</div>
		</div>

		<div *ngIf="pendingStatus.canUse && !isOneTimeShift">
			<div *ngIf="rulesForm.get('approval_state').value === 'DIRECT'" style="margin-bottom: 15px">
				<p-checkbox [(ngModel)]="pendingStatus.isPending" [ngModelOptions]="{ standalone: true }" [binary]="true"></p-checkbox>
				<div (click)="pendingStatus.isPending = !pendingStatus.isPending" class="form-checkbox-label has-pointer">Mark schedule as pending</div>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('markPending')"></i>
			</div>
			<div *ngIf="rulesForm.get('approval_state').value === 'PENDING'" style="margin-bottom: 15px">
				<p-checkbox [(ngModel)]="pendingStatus.isApproved" [ngModelOptions]="{ standalone: true }" [binary]="true"></p-checkbox>
				<div (click)="pendingStatus.isApproved = !pendingStatus.isApproved" class="form-checkbox-label has-pointer">
					Mark schedule as approved
				</div>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('markApproved')"></i>
			</div>
			<div *ngIf="(isNew && pendingStatus.isPending) || rulesForm.get('approval_state').value === 'PENDING'" class="options-block">
				<textarea class="sched-comments" formControlName="comments" placeholder="Optional shift notes"></textarea>
			</div>
		</div>

		<div style="margin-bottom: 15px" *ngIf="!rulesForm.get('holiday').value && showAnytimeCheckbox">
			<p-checkbox [(ngModel)]="isAnytimeJob" [ngModelOptions]="{ standalone: true }" [binary]="true"></p-checkbox>
			<div (click)="isAnytimeJob = !isAnytimeJob" class="form-checkbox-label has-pointer">Schedule any time of day</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('anytimeJob')"></i>
			<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="displayMakeAnytimeJobDialog()"></i> -->
		</div>

		<div *ngIf="rulesForm.value.holiday" class="alert alert-danger">
			A paid holiday will create time entries for this schedule automatically. Employees will not need to check in or out to log their shift.
		</div>

		<!-- Begin Job Start/End Time -->
		<div *ngIf="!isAnytimeJob" style="margin-top: 25px; margin-bottom: 25px">
			<div class="row">
				<div class="col-6">
					<div class="options-title">Start Time</div>
					<app-date-time-picker
						id="START_TIME"
						[currentDate]="rulesForm.value.start_time"
						[pickerType]="'TIME'"
						[pickerMode]="'dialog'"
						[pickerLabel]="'Start Time'"
						[format]="is12HourFormat ? 'h:mm a' : 'HH:mm'"
						[hour12Timer]="is12HourFormat"
						(pickerDidClose)="setStartEndPickerTime('start_time', $event)"
					>
					</app-date-time-picker>
				</div>
				<div class="col-6">
					<div class="options-title">End Time</div>
					<app-date-time-picker
						id="END_TIME"
						[currentDate]="rulesForm.value.end_time"
						[pickerType]="'TIME'"
						[pickerMode]="'dialog'"
						[pickerLabel]="'End Time'"
						[format]="is12HourFormat ? 'h:mm a' : 'HH:mm'"
						[hour12Timer]="is12HourFormat"
						(pickerDidClose)="setStartEndPickerTime('end_time', $event)"
					>
					</app-date-time-picker>
				</div>
			</div>
			<!-- DO NOT DELETE - May be useful -->
			<!-- <div *ngIf="calculateShiftLength()" class="row" style="margin-top: 20px">
				<div class="col-12">
					<strong
						>Current shift length is:
						<span class="tts-hl-3">{{ calculateShiftLength() }} hrs</span>
					</strong>
				</div>
			</div> -->
		</div>
		<!-- End Job Start/End Time -->

		<!-- REPEAT / ONE-TIME -->
		<div *ngIf="!isOneTimeShift">
			<hr />

			<div class="row" style="margin-bottom: 14px">
				<div class="col-12">
					<div class="form-check form-check-inline">
						<input
							type="radio"
							class="form-check-input"
							id="scheduleRepeat1"
							[value]="false"
							formControlName="schedule_one_time"
							(change)="doProcessScheduleRepeatType()"
						/>
						<label class="form-check-label has-pointer" for="scheduleRepeat1" style="font-weight: bold">Repeat</label>
					</div>
					<div class="form-check form-check-inline">
						<input
							type="radio"
							class="form-check-input"
							id="scheduleRepeat2"
							[value]="true"
							formControlName="schedule_one_time"
							(change)="doProcessScheduleRepeatType()"
						/>
						<label class="form-check-label has-pointer" for="scheduleRepeat2" style="font-weight: bold">One Time</label>
					</div>
				</div>
			</div>

			<hr *ngIf="isScheduledOneTime" style="margin-top: 5px" />
		</div>

		<div *ngIf="isScheduledOneTime" class="options-block" [class.input-required]="!rulesForm.get('startDate').value">
			<!-- <a href="#" class="float-right" (click)="clearDate('startDate')">clear</a> -->
			<span *ngIf="!rulesForm.get('startDate').value" class="input-required float-right">(required)</span>
			<div class="options-title">Schedule Date</div>
			<app-date-time-picker
				id="CUSTOM_START_DATE"
				[currentDate]="rulesForm.get('startDate').value"
				[pickerType]="'DATE'"
				[pickerMode]="'dialog'"
				[pickerLabel]="'Schedule Date'"
				[format]="'ddd MMM Do, YYYY'"
				(pickerDidClose)="rulesForm.get('startDate').setValue($event); updateStartEndDates()"
			>
			</app-date-time-picker>
		</div>

		<!-- Merged freq/interval dropdown -->
		<div *ngIf="!isScheduledOneTime" class="options-block interval-block">
			<select class="form-control" (change)="setFreqInterval($event)" [value]="currentFreqInterval">
				<!-- <option value="0">Daily</option> -->
				<option value="1">Weekly</option>
				<option value="2">Every Other Week</option>
				<option value="3">Every 3 Weeks</option>
				<option value="4">Every 4 Weeks</option>
				<option value="A">Monthly</option>
				<option value="B">Yearly</option>
			</select>
		</div>

		<!-- DO NOT DELTE - MIGHT BE ADVAMCED CONFIG - Deprecated with merged freq/interval dropdown -->
		<!-- <div id="freq" *ngIf="!isScheduledOneTime" class="options-block top-block">
			<select class="form-control" formControlName="freq" (change)="selectFrequency()">
				<option *ngFor="let freq of freqOptions" [ngValue]="freq">{{ freq.name }}</option>
			</select>
		</div> -->

		<!-- <div id="interval" *ngIf="rulesForm.value.freq.value === 2" class="options-block interval-block">
			<div>
				<div class="input-group">
					<div class="input-group-prepend">
						<div class="input-group-text"><span>every</span></div>
					</div>
					<input type="tel" class="form-control" formControlName="interval" (keyup)="updateNumericInput('interval')" />
					<div class="input-group-append">
						<div class="input-group-text">
							<span>{{ rulesForm.value.freq.interval }}(s)</span>
						</div>
					</div>
				</div>
			</div>
		</div> -->
		<!-- DO NOT DELTE - MIGHT BE ADVAMCED CONFIG - Deprecated with merged freq/interval dropdown -->

		<div *ngIf="rulesForm.value.freq.value === 0" id="bymonth" class="options-block month-buttons">
			<div class="row">
				<div *ngFor="let month of monthOptions" class="col-3" style="text-align: center">
					<div style="padding: 5px">
						<div class="bymonth-option" [class.box-highlighter]="isMonthSelected(month)" (click)="toggleMonth(month)">
							{{ month.code }}
						</div>
					</div>
				</div>
			</div>
			<div style="margin-top: 20px">
				<div *ngIf="rulesForm.value.freq.value === 0 && !hasMonthsSelected()" class="alert alert-warning alert-note">
					Please select a month from the list above.
				</div>
			</div>
		</div>

		<div *ngIf="!isScheduledOneTime && (rulesForm.value.freq.value === 0 || rulesForm.value.freq.value === 1)" class="options-block month-block">
			<div class="row month-bp-block">
				<div class="col-4">
					<div class="form-check form-radio">
						<input
							type="radio"
							class="form-check-input"
							value="bySetPos"
							[ngModel]="monthType"
							[ngModelOptions]="{ standalone: true }"
							(change)="toggleMonthlyType('bySetPos')"
						/>
						<div class="options-title-inline has-pointer" (click)="toggleMonthlyType('bySetPos')">on the</div>
					</div>
				</div>
				<div class="col-4">
					<select
						class="form-control form-inline mbp-select"
						formControlName="bysetpos"
						(change)="updateRule()"
						(focus)="onPositionalInputFocus()"
					>
						<option [ngValue]="ruleConst.bySetPosDefault">Any</option>
						<option *ngFor="let pos of positionOptions" [ngValue]="pos.value">{{ pos.name }}</option>
					</select>
				</div>
				<div class="col-4">
					<select
						class="form-control form-inline mbp-select"
						formControlName="byweekday"
						(change)="updateRule()"
						(focus)="onPositionalInputFocus()"
					>
						<option [ngValue]="ruleConst.byWeekdayDefault">Day</option>
						<option *ngFor="let day of dayOptions" [ngValue]="day.aValue">{{ day.short }}</option>
					</select>
				</div>
			</div>

			<div class="row month-od-block">
				<div class="col-4">
					<div class="form-check form-radio">
						<input
							type="radio"
							class="form-check-input form-inline"
							value="onDay"
							[ngModel]="monthType"
							[ngModelOptions]="{ standalone: true }"
							(change)="toggleMonthlyType('onDay')"
						/>
						<div class="options-title-inline has-pointer" (click)="toggleMonthlyType('onDay')">on day</div>
					</div>
				</div>
				<div class="col-4">
					<input
						type="tel"
						class="form-control form-inline month-by-day"
						(keyup)="constrainByMonthDay(true)"
						formControlName="bymonthday"
						(focus)="monthDayFocus()"
					/>
				</div>
				<div class="col-4">
					<!-- <button *ngIf="false" class="btn btn-outline-secondary btn-block" (click)="constrainByMonthDay()">Set</button> -->
					<div *ngIf="!isOnDaySelectionValid()" class="input-required" style="padding-top: 8px">
						<i class="fa fa-arrow-left on-day-arrow"></i>&nbsp;required
					</div>
				</div>
			</div>

			<div *ngIf="!isOnDaySelectionValid()" class="alert alert-warning alert-note" style="margin-top: 25px">
				Please specify a day of the month.
			</div>
		</div>

		<!-- <div *ngIf="true" class="debug-box" style="margin-top: 30px">
      <div class="alert alert-info">
        <h2>Form Object</h2>
        <pre><code>{{ rulesForm.value | json }}</code></pre>
      </div>
      <div class="alert alert-info">
        <h2>Rule Object</h2>
        <pre><code>{{ rule | json }}</code></pre>
      </div>
    </div> -->

		<div *ngIf="rulesForm.value.freq.value === 2">
			<!-- <div *ngIf="!hasDaysSelected()" class="alert alert-warning alert-note">Tap day buttons above to select.</div> -->
			<!-- <div style="font-size: 0.9em" class="float-right tts-hl-3">{{ listDaysSelected() }}</div> -->
			<span *ngIf="!hasDaysSelected()" class="input-required float-right">(required)</span>
			<div class="options-title" [class.input-required]="!hasDaysSelected()">On</div>
			<div id="byweekday" class="options-block weekday-buttons" [class.input-required]="!hasDaysSelected()" style="margin-bottom: 8px">
				<div
					*ngFor="let weekday of dayOptions"
					class="byweekday-option"
					[class.box-highlighter]="isWeekdaySelected(weekday)"
					[class.box-multiday]="isWeekdaySelectedForMultiDayJob(weekday)"
					(click)="toggleWeekday(weekday)"
				>
					{{ weekday.code }}
				</div>
			</div>
			<div *ngIf="hasDaysSelected() && isMultiDayJob()" class="alert alert-warning alert-note" style="margin-top: 25px">
				You are scheduling a multi-day job so please ensure you only select consecutive days.
			</div>

			<app-scheduler-employee-assignment
				*ngIf="selectedScheduleType === 1"
				[manager]="assignmentManager"
				(showHelp)="showHelp($event)"
				(enableWeekday)="enableWeekday($event)"
				(disableWeekday)="disableWeekday($event)"
			>
			</app-scheduler-employee-assignment>
		</div>

		<!-- DEPRECATED 20231202 - Moved under employee dropdown -->
		<!-- <div
			*ngIf="rulesForm.get('employee_id').value === 0"
			class="form-group options-block"
			[class.input-required]="!(rulesForm.get('employee_count').value > 0)"
			style="margin-top: 20px; margin-bottom: 20px"
		>
			<div class="form-rowr">
				<div class="form-label-bold">
					Schedule
					<input
						type="tel"
						class="form-control form-inline emp-count-input"
						formControlName="employee_count"
						(change)="validateNumericInput('employee_count')"
					/>
					employee(s)
				</div>
			</div>
		</div> -->

		<div
			*ngIf="selectedScheduleType === ScheduleType.multiEmployee && rulesForm.value.freq.value !== 2"
			class="options-block"
			[class.input-required]="!isMultiEmployeeChoiceValid()"
		>
			<div class="options-title-inline">Schedule Employees</div>
			<span *ngIf="!isMultiEmployeeChoiceValid()" class="input-required float-right">(required)</span>
			<app-multi-select-details
				[type]="'EMP'"
				placeholder="Select Employees"
				selectedItemsLabel="Employees Selected: {0}"
				[options]="multiEmployeeDropdown"
				[model]="rulesForm.get('employee_ids').value"
				(modelChange)="rulesForm.get('employee_ids').setValue($event)"
			>
			</app-multi-select-details>
		</div>

		<!-- DEPRECATED 20231203 -->
		<!-- <div *ngIf="!this.isFormValid()">
			<div class="alert alert-warning alert-note">
				You must set all <strong>required</strong> fields before you can {{ isNew ? 'save' : 'update' }} this schedule.
			</div>
		</div> -->

		<!-- <button (click)="getFormValidationErrors()">Validate</button>
		<button [disabled]="checkValid()">Save</button> -->

		<!-- Advanced Block -->
		<div style="margin-top: 30px; font-size: 1.4em; cursor: pointer" (click)="showAdvancedOptions = !showAdvancedOptions">
			<div class="float-right" style="color: gray">
				<i *ngIf="!showAdvancedOptions" class="fa fa-chevron-right"></i>
				<i *ngIf="showAdvancedOptions" class="fa fa-chevron-down"></i>
			</div>
			<div class="tts-hl-2">Advanced Options</div>
		</div>

		<div *ngIf="showAdvancedOptions">
			<div style="margin-top: 20px"></div>

			<!-- BEGIN - Date Block -->
			<!-- <hr class="row-divider" /> -->

			<div *ngIf="!isScheduledOneTime">
				<div class="options-block" [class.input-required]="!rulesForm.get('startDate').value">
					<!-- <a href="#" class="float-right" (click)="clearDate('startDate')">clear</a> -->
					<span *ngIf="!rulesForm.get('startDate').value" class="input-required float-right">(required)</span>
					<div class="options-title">{{ isScheduledOneTime ? 'Schedule Date' : 'Start Date' }}</div>
					<app-date-time-picker
						id="CUSTOM_START_DATE"
						[currentDate]="rulesForm.get('startDate').value"
						[pickerType]="'DATE'"
						[pickerMode]="'dialog'"
						[pickerLabel]="isScheduledOneTime ? 'Schedule Date' : 'Start Date'"
						[format]="'ddd MMM Do, YYYY'"
						(pickerDidClose)="rulesForm.get('startDate').setValue($event); updateStartEndDates()"
					>
					</app-date-time-picker>
				</div>

				<div id="date-count-select" class="options-block" style="margin-bottom: 12px" *ngIf="!isScheduledOneTime">
					<div class="row">
						<div class="col-5">
							<div class="options-title">
								<div style="padding-top: 10px">End On</div>
							</div>
						</div>
						<div class="col-7">
							<select
								class="form-control float-right"
								[(ngModel)]="endSelector"
								(change)="setEndSelector()"
								[ngModelOptions]="{ standalone: true }"
							>
								<option value="never">Never</option>
								<option value="until">End Date</option>
								<option value="count">Count Reached</option>
							</select>
						</div>
					</div>
				</div>

				<!-- End Date -->
				<div *ngIf="!isScheduledOneTime && endSelector === 'until'" class="options-block">
					<a href="#" class="float-right" (click)="clearDate('endDate')">clear</a>
					<div class="options-title">End Date</div>
					<app-date-time-picker
						id="CUSTOM_END_DATE"
						[currentDate]="rulesForm.get('endDate').value"
						[pickerType]="'DATE'"
						[pickerMode]="'dialog'"
						[pickerLabel]="'End Date'"
						[format]="'ddd MMM Do, YYYY'"
						[max]="scheduleEndDateMax"
						(pickerDidClose)="rulesForm.get('endDate').setValue($event); updateStartEndDates()"
					>
					</app-date-time-picker>
				</div>

				<div *ngIf="dateRangeError" class="alert alert-danger alert-note">
					The selected <strong>End Date</strong> must be the same as or later than the selected
					<strong>Start Date</strong>
				</div>

				<div id="count" *ngIf="!isScheduledOneTime && endSelector === 'count'" class="options-block">
					<div>
						<div class="input-group">
							<div class="input-group-prepend">
								<div class="input-group-text"><span>Count</span></div>
							</div>
							<input type="tel" class="form-control" formControlName="count" (keyup)="updateNumericInput('count')" />
							<div class="input-group-append">
								<div class="input-group-text"><span>time(s)</span></div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- END - Date Block -->

			<!-- Tags -->

			<app-form-tags
				#tagComp
				[tagsJson]="rulesForm.get('tags_json').value"
				[tagOptionsList]="tagOptionsList"
				(tagsJsonChange)="rulesForm.get('tags_json').setValue($event)"
			></app-form-tags>

			<div style="margin-top: 25px">
				<!-- <div class="row-divider"><hr></div> -->
				<div class="options-block">
					<!-- <span class="input-optional float-right">(optional)</span> -->
					<div class="options-title-inline">Schedule Details</div>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('details')"></i>
					<input
						type="text"
						class="form-control"
						[placeholder]="placeholder.description"
						(focus)="updatePlaceholder('description', 'focus')"
						(blur)="updatePlaceholder('description', 'blur')"
						autocomplete="off"
						formControlName="description"
					/>
				</div>

				<!-- Shift Notes -->
				<div class="options-block">
					<div class="options-title-inline">Notes for Scheduler</div>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('schedulerNotes')"></i>
					<div>
						<textarea
							class="sched-comments"
							formControlName="comments"
							[placeholder]="placeholder.comments"
							(focus)="updatePlaceholder('comments', 'focus')"
							(blur)="updatePlaceholder('comments', 'blur')"
						></textarea>
					</div>
				</div>
			</div>

			<!-- Schedule Change Notifications -->
			<div *ngIf="showSchedChangeEmail" style="margin-bottom: 25px">
				<hr style="margin-top: 25px; margin-bottom: 25px" />
				<div><strong class="tts-hl-2">Notify for schedule changes</strong></div>

				<div style="margin-top: 15px">
					<div style="margin-top: 10px">
						<div>
							<button tabindex="-1" *ngFor="let schedEmail of schedChangeEmails; let idx = index" class="btn btn-sm btn-block btn-email">
								{{ schedEmail }}
								<i (click)="removeEmail(idx, 'schedChangeEmail')" class="fa fa-times float-right btn-email-x" aria-hidden="true"></i>
							</button>
						</div>
					</div>

					<div class="form-group" style="margin-top: 20px">
						<table width="100%">
							<tr>
								<td>
									<input
										#newSchedEmail
										[(ngModel)]="newSchedChangeEmailInput"
										placeholder="enter email address"
										(focus)="newSchedEmail.placeholder = ''"
										(blur)="newSchedEmail.placeholder = 'enter email address'"
										[ngModelOptions]="{ standalone: true }"
										id="newSchedChangeEmail"
										class="form-control"
										type="text"
										autocapitalize="off"
										autocomplete="off"
									/>
								</td>
								<td>&nbsp;</td>
								<td style="text-align: right">
									<button
										(click)="addSchedChangeEmail(true)"
										(keyup.enter)="addSchedChangeEmail(true)"
										[disabled]="!isSchedChangeEmailInputValid()"
										class="btn btn-sm btn-success"
									>
										Add
									</button>
								</td>
							</tr>
						</table>
						<div
							*ngIf="newSchedChangeEmailInput && !isSchedChangeEmailInputValid()"
							style="color: firebrick; margin-top: 8px; font-weight: bold"
						>
							Not a valid email address
						</div>
						<div *ngIf="newSchedChangeEmailInput && isSchedChangeEmailInputValid()" style="color: green; margin-top: 8px">
							Click <strong>Add</strong> to include another address
						</div>
					</div>
				</div>

				<div class="row-divider">
					<hr />
				</div>
			</div>
			<!-- End Schedule Change Notifications -->

			<!-- Begin Managed Work Order Email Settings -->
			<div *ngIf="arePendingSchedulesEnabled" style="margin-bottom: 25px">
				<!-- <div style="margin-bottom: 15px"><strong class="tts-hl-2">Email Work Order To:</strong></div> -->

				<div class="checkbox-item d-flex justify-content-between" style="margin: 22px 0px 22px 0px">
					<div class="checkbox-wrap">
						<p-checkbox formControlName="approve_with_workorder" [binary]="true"></p-checkbox>
					</div>
					<div class="checkbox-label-wrap">
						<span
							*ngIf="rulesForm.get('approval_state').value === 'PENDING'"
							(click)="toggleFormCheckbox('approve_with_workorder')"
							class="has-pointer"
							><strong>Send workorder on approval</strong></span
						>
						<span
							*ngIf="rulesForm.get('approval_state').value === 'DIRECT' || rulesForm.get('approval_state').value === 'APPROVED'"
							(click)="toggleFormCheckbox('approve_with_workorder')"
							class="has-pointer"
							><strong>Send workorder on save</strong></span
						>
						<i
							title="More Information"
							class="far fa-info-circle tts-info-btn"
							aria-hidden="true"
							(click)="showHelp('approve_with_workorder')"
						></i>
					</div>
				</div>

				<div *ngIf="rulesForm.get('approve_with_workorder').value" class="form-group">
					<app-generic-email-list [manager]="approveWithWorkorderEmailListManager"></app-generic-email-list>
				</div>
				<div class="row-divider">
					<hr />
				</div>
			</div>
			<!-- End Managed Work Order Email Settings -->

			<div>
				<div class="options-section-header" style="margin-bottom: 20px">Override Job Settings</div>

				<div class="row" style="margin-top: 15px">
					<div class="col-9">
						<div class="form-group form-group-lg" style="padding-top: 5px">
							<strong class="has-pointer">Break Length</strong>
							<span class="text-muted tsf-time-abbrv">(min)</span>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('breakTime')"></i>
						</div>
					</div>
					<div class="col-3">
						<div class="form-group form-group-lg">
							<input
								id="breakLength"
								type="text"
								class="form-control job-override float-right"
								formControlName="break_time"
								[placeholder]="placeholder.break_time"
								(focus)="updatePlaceholder('break_time', 'focus')"
								(blur)="updatePlaceholder('break_time', 'blur')"
							/>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-9">
						<div class="form-group form-group-lg" style="padding-top: 5px">
							<strong class="has-pointer">Auto-apply Break</strong>
							<span class="text-muted tsf-time-abbrv">(hr:min)</span>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('breakTimeWorked')"></i>
						</div>
					</div>
					<div class="col-3">
						<div class="form-group form-group-lg">
							<input
								id="breakTimeWorked"
								type="text"
								class="form-control job-override float-right"
								formControlName="break_time_worked"
								[placeholder]="placeholder.break_time_worked"
								(focus)="updatePlaceholder('break_time_worked', 'focus')"
								(blur)="updatePlaceholder('break_time_worked', 'blur')"
							/>
						</div>
					</div>
				</div>

				<div *ngIf="isTrackingOverage" class="row" style="margin-bottom: 25px">
					<div class="col-9">
						<div style="max-width: 200px; line-height: 1.3em">
							<strong>Budgeted time for this shift</strong>
							<span class="text-muted tsf-time-abbrv">(hr:min)</span>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('trackOverage')"></i>
						</div>
					</div>
					<div class="col-3">
						<input
							class="form-control job-override float-right"
							formControlName="overage_time"
							[placeholder]="placeholder.overage_time"
							(focus)="updatePlaceholder('overage_time', 'focus')"
							(blur)="updatePlaceholder('overage_time', 'blur')"
						/>
					</div>
				</div>
			</div>

			<div *ngIf="showHolidayOption">
				<hr style="margin-top: 25px; margin-bottom: 25px" />
				<div class="options-section-header" style="margin-bottom: 20px">Miscellaneous Options</div>
				<div style="margin-top: 15px">
					<p-checkbox formControlName="holiday" [binary]="true" (onChange)="paidHolidayCheck()"></p-checkbox>
					<div (click)="togglePaidHoliday()" class="form-checkbox-label has-pointer">Auto check in/out</div>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('auto_check_in_out')"></i>
					<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="displayMakeAnytimeJobDialog()"></i> -->
				</div>
			</div>

			<hr style="margin-top: 25px; margin-bottom: 25px" />

			<!-- Enable Switch -->
			<app-form-input-switch
				[model]="rulesForm.get('enabled').value"
				(modelChange)="rulesForm.get('enabled').setValue($event)"
				[enabledLabel]="'Schedule is enabled'"
				[disabledLabel]="'Schedule is disabled'"
				[wrapStyle]="{ margin: '30px 0px 10px 0px' }"
				[labelStyle]="{ 'font-size': '1.4rem', 'font-weight': '400' }"
			></app-form-input-switch>

			<!-- Schedule record highlight color -->
			<div style="border: 1px solid lightgray; border-radius: 0.25rem; padding: 8px 0px 0px 8px; margin: 10px 0px 25px 0px">
				<app-color-picker
					[heading]="'Highlight Color'"
					[color]="rulesForm.get('assigned_color').value"
					(update)="rulesForm.get('assigned_color').setValue($event)"
				>
				</app-color-picker>
			</div>

			<!-- <div class="options-block" style="margin-top: 28px">
				<div class="float-right">
					<p-toggleswitch formControlName="enabled" binary="true"></p-toggleswitch>
				</div>
				<div *ngIf="rulesForm.value.enabled" class="options-title-inline" style="color: green; font-weight: 400; font-size: 1.4em">
					{{ isOneTimeShift ? 'Shift' : 'Schedule' }} is enabled
				</div>
				<div *ngIf="!rulesForm.value.enabled" class="options-title-inline" style="color: gray; font-weight: 400; font-size: 1.4em">
					{{ isOneTimeShift ? 'Shift' : 'Schedule' }} is disabled
				</div>
			</div> -->
		</div>
		<!-- End Advanced Block -->

		<div *ngIf="false">
			<div style="margin-bottom: 10px; font-size: 0.8em; color: gray; word-wrap: break-word">
				{{ currentRuleString }}
			</div>
			<div style="margin-bottom: 20px; font-size: 0.8em; color: gray">{{ currentTextString }}</div>
		</div>
	</form>

	<app-schedule-snapshot
		*ngIf="showAdvancedOptions && useScheduleSnapshot && !hideTable()"
		[rRuleCalculator]="rRuleCalculator"
	></app-schedule-snapshot>

	<div *ngIf="!useScheduleSnapshot">
		<div *ngIf="currentScheduleCount > 0 && !hideTable()">
			<div class="options-title">Upcoming Schedule</div>
		</div>
		<div *ngIf="currentScheduleCount === 0 || hideTable()">
			<div class="options-title" style="color: chocolate">No dates scheduled next 30 days</div>
		</div>
		<table [hidden]="hideTable()" id="rrule-dates" class="table table-sm" style="max-width: 600px; margin: auto"></table>
	</div>
</div>

<div *ngIf="false" class="debug-box" style="margin-top: 30px">
	<div class="alert alert-info">
		<h2>Form Object</h2>
		<pre><code>{{ rulesForm.value | json }}</code></pre>
	</div>
	<div class="alert alert-info">
		<h2>Rule Object</h2>
		<pre><code>{{ rule | json }}</code></pre>
	</div>
</div>

<p-dialog
	[header]="overrideProtection.headerLabel"
	[(visible)]="overrideProtection.showDialog"
	[modal]="false"
	[style]="{ width: '300px' }"
	[dismissableMask]="true"
>
	<div class="tts-info-msg">
		<span
			>Click <strong>Cancel</strong> to modify this schedule entry and deal with the issue. Click <strong>Submit</strong> to bypass
			{{ overrideProtection.typeLabel }} protection and record this schedule entry as is.</span
		>
	</div>
	<div style="margin-top: 18px">
		<button class="btn btn-sm btn-success float-right" (click)="submitOverrideClicked()">Submit</button>
		<button class="btn btn-sm btn-secondary" (click)="overrideProtection.showDialog = false">Cancel</button>
	</div>
</p-dialog>
