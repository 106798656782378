import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { DateTimeHelper, log } from '@app/helpers'
import { HelpDialogMessage, ImageFile } from '@app/models'
import { Global } from '@app/models/global'
import { Image as PImage } from 'primeng/image'

import moment from 'moment-timezone'
import { CoreService } from '@app/services'

@Component({
	selector: 'app-image-files-view',
	templateUrl: './image-files-view.component.html',
	styleUrls: ['./image-files-view.component.scss'],
	standalone: false,
})
export class ImageFilesViewComponent implements OnInit {
	navIconsVisible = false
	currentIndex = null
	currentPImage: PImage = null
	descriptionPlaceholder = '< no description provided >'

	@Input() images: Array<ImageFile> = []
	@Input() timezone: string = 'UTC'
	@Input() refTs: number // Optional reference timestamp which controls range check
	@Input() duration: string = DateTimeHelper.imgIssuesDuration // Optional ISO formatted duration

	rangeCheckIndexMap: Array<boolean> = []

	constructor(private coreSrvc: CoreService) {}

	get hasImages(): boolean {
		return this.images.length > 0
	}
	get hasImageDescriptions(): boolean {
		return this.images.filter((img) => !!img.description).length > 0
	}

	ngOnInit(): void {
		if (DateTimeHelper.imgIssuesDuration && this.refTs) {
			this.rangeCheckIndexMap = DateTimeHelper.tsInRange(this.images, this.refTs).map((val) => !val)
		} else {
			this.rangeCheckIndexMap = this.images.map((img) => false)
		}
		// log('Image Check List', this.rangeCheckIndexMap)
	}

	public showNavIcons(pImage: PImage, idx: number): void {
		this.currentIndex = idx
		this.currentPImage = pImage
		this.navIconsVisible = true

		// Pull out the nav icons and append to body
		setTimeout(() => {
			const navBut = $('.nav-icon-block')
			navBut.detach()
			$('body').append(navBut)
		}, 125)
	}

	public hideNavIcons(): void {
		this.currentIndex = null
		this.currentPImage = null
		this.navIconsVisible = false
	}

	public nextImage() {
		let idx = this.currentIndex + 1
		if (idx === this.images.length) {
			idx = 0
		}
		this.currentIndex = idx
		this.updatePreview()
	}

	public previousImage() {
		let idx = this.currentIndex - 1
		if (idx < 0) {
			idx = this.images.length - 1
		}
		this.currentIndex = idx
		this.updatePreview()
	}

	public photoDate(image: ImageFile) {
		const ts = DateTimeHelper.normalizeTimestamp(image.ts)
		return ts ? moment.unix(ts).tz(this.timezone).format('YYYY-MM-DD') : ''
	}

	public photoTime(image: ImageFile) {
		const ts = DateTimeHelper.normalizeTimestamp(image.ts)
		const format = DateTimeHelper.format12Hour ? 'h:mm a' : 'HH:mm'
		return ts ? moment.unix(ts).tz(this.timezone).format(format) : ''
	}

	private updatePreview() {
		const idx = this.currentIndex
		const image = this.images[idx]
		const src = image?.getUrl()
		// const alt = `Photo ${idx + 1}`

		const imageElem = $('.p-image-original')
		imageElem.attr('src', src)

		// this.currentPImage.src = src
		// this.currentPImage.alt = alt
		this.currentPImage.rotate = 0
		this.currentPImage.previewClick = false

		const cd = this.currentPImage['cd'] as ChangeDetectorRef
		cd.markForCheck()
	}

	public hasRangeError(idx: number) {
		return this.rangeCheckIndexMap[idx]
	}
	public showHelp(key: string): void {
		let topic = new HelpDialogMessage(null, null)
		const issueDur = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs().globalHighlightImgLastModDuration
		switch (key) {
			case 'timeError':
				topic.header = 'Time Issue'
				topic.message = `The last modified date on this image is greater than the allowed threshold of ${issueDur} min before submission.`
				break
		}
		this.coreSrvc.notifySrvc.showHelp(topic)
	}
}
