import { Component, OnDestroy } from '@angular/core'

import { log } from '@app/helpers'

@Component({
    selector: 'app-public-home',
    templateUrl: './home.component.html',
    standalone: false
})
export class PublicHomeComponent implements OnDestroy {
	constructor() {}

	ngOnDestroy(): void {
		$('body').css('background-color', '#ffffff')
	}
}
