<div>
	<div
		class="switcher-block"
		[class.lift-tablet-portrait]="liftOn === 'PORTRAIT'"
		[class.lift-tablet-landscape]="liftOn === 'LANDSCAPE'"
		[style]="switcherBlockStyle"
	>
		<div [style]="switcherWrapStyle">
			<div *ngIf="message" class="message-label">{{ message }}</div>
			<div *ngIf="!message" class="d-flex justify-content-evenly flex-wrap">
				<div class="switcher-item" *ngFor="let tab of tabs">
					<div *ngIf="type === 'COUNT'" (click)="tabBtnClicked(tab)">
						<span class="badge badge-pill badge-dark count-badge" [class.count-badge-active]="tab.id === currentTab.id">{{ tab.count() }}</span
						><br />
						<span class="count-title" [class.count-title-active]="tab.id === currentTab.id">{{ tab.label }} </span>
					</div>

					<div *ngIf="type === 'NOCOUNT'" (click)="tabBtnClicked(tab)">
						<div class="no-count-title" [class.no-count-title-active]="tab.id === currentTab.id">{{ tab.label }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
