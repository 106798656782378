import { Injectable, OnDestroy } from '@angular/core'
import { log } from '@app/helpers'
import { Global } from '@app/models/global'

import { fromEvent, merge, Subject, Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
// DEPRECATED - Moved to DialogHelper
// interface DialogSavePosition {
// 	top: string
// 	left: string
// }

export interface ScreenSize {
	width: number
	height: number
}

@Injectable({
	providedIn: 'root',
})
export class DisplayService implements OnDestroy {
	fadeInOutTracker = {}
	showFooter = true

	// dialogSavePosition: DialogSavePosition // DEPRECATED - Moved to DialogHelper

	public fixedHeaderStateChange = new Subject<'ADDED' | 'REMOVED'>()
	public screenSizeDidChange = new Subject<ScreenSize>()
	private screenSizeChangeSubscription: Subscription

	public datePickerDidOpen = new Subject<string>()
	public datePickerDidClose = new Subject<string>()

	constructor() {
		log('Creating DisplayService')
		// Setup listener on fixed header being added to or removed from the DOM
		// this.setupFixedHeaderListener()

		// Combine resize and orientationchange events into a single observable
		const resizeObservable = fromEvent(window, 'resize')
		const orientationChangeObservable = fromEvent(window, 'orientationchange')
		const combinedObservable = merge(resizeObservable, orientationChangeObservable)

		// Apply debouncing and subscribe to the combined observable
		this.screenSizeChangeSubscription = combinedObservable.pipe(debounceTime(100)).subscribe(() => this.onResizeOrOrientationChange())
	}

	ngOnDestroy(): void {
		this.screenSizeChangeSubscription?.unsubscribe()
	}

	// Screen Size Changes
	private onResizeOrOrientationChange() {
		const width = window.innerWidth
		const height = window.innerHeight
		this.screenSizeDidChange.next({ width: width, height: height })
	}

	// Section Loader - Begin
	private sectionLoaderCount = 0
	public sectionLoaderVisible = new Subject<boolean>()
	public startSectionLoader(delay?: number): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			this.sectionLoaderCount++
			this.sectionLoaderVisible.next(this.sectionLoaderCount > 0)
			setTimeout(() => {
				resolve(true)
			}, delay ?? 100)
		})
	}
	public stopSectionLoader() {
		if (this.sectionLoaderCount > 0) this.sectionLoaderCount--
		this.sectionLoaderVisible.next(this.sectionLoaderCount > 0)
	}
	public resetSectionLoader() {
		this.sectionLoaderCount = 0
		this.sectionLoaderVisible.next(false)
	}
	// Section Loader - End

	public enableAllTooltips(): void {
		// Hide stragglers
		const stragglers: any = $('.bs-tooltip-auto')
		stragglers.tooltip('hide')

		// Enable Tooltips
		const itemTooltips: any = $('.item-tooltip')
		itemTooltips.tooltip({ show: { effect: 'none', delay: 0 } })

		// Delay long enough to hide tooltips if cursor was hovering during page load
		setTimeout(() => {
			itemTooltips.tooltip('hide')
		}, 1000)
	}
	public hideAllTooltips() {
		const itemTooltip: any = $('.item-tooltip')
		itemTooltip.tooltip('hide')
	}

	public bringIntoView(id: string): void {
		setTimeout(() => {
			const element = document.getElementById(id)
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'center' })
			}
		}, 150)
	}

	public bringIntoViewBySelector(selector: string): void {
		setTimeout(() => {
			const element = $(selector)[0]
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'center' })
			}
		}, 150)
	}

	flashHtmlElementById(selector: string, delay: number) {
		setTimeout(() => {
			const element = $(selector)
			element.addClass('element-pulse')
			setTimeout(() => {
				element.removeClass('element-pulse')
			}, 3000)
		}, delay)
	}

	flashHtmlElementByIdWithClass(selector: string, delay: number, className: string) {
		setTimeout(() => {
			const element = $(selector)
			element.addClass(className)
			setTimeout(() => {
				element.removeClass(className)
			}, delay)
		}, delay)
	}

	fadeOutIn(selector: string, fadeClass: string, timeout: number = 3100) {
		// If we're currently animating then return
		if (this.fadeInOutTracker[selector]) return

		// Add selector to the tracking list
		this.fadeInOutTracker[selector] = true

		const element = $(selector)
		if (element) {
			element?.addClass(fadeClass)
			setTimeout(() => {
				element?.removeClass(fadeClass)
				this.fadeInOutTracker[selector] = false
			}, timeout)
		}
	}

	// Listens for the DataTables fixed header to be shown or removed and triggers a resize event
	// setupFixedHeaderListener(): void {
	// 	const bodyElement = document.querySelector('body')

	// 	if (!bodyElement) {
	// 		return // Ensure the body element exists
	// 	}

	// 	const observer = new MutationObserver((mutationsList) => {
	// 		for (const mutation of mutationsList) {
	// 			if (mutation.type === 'childList') {
	// 				// Check if the FixedHeader div is added or removed
	// 				const fixedHeaderDiv = bodyElement.querySelector('.dtfh-floatingparent.dtfh-floatingparenthead')

	// 				if (fixedHeaderDiv) {
	// 					console.log('Fixed header is displayed')
	// 					this.fixedHeaderStateChange.next('ADDED')
	// 				} else {
	// 					console.log('Fixed header is removed')
	// 					this.fixedHeaderStateChange.next('REMOVED')
	// 				}
	// 			}
	// 		}
	// 	})

	// 	// Observe for changes in the child elements of the body (i.e., adding/removing the fixed header div)
	// 	observer.observe(bodyElement, { childList: true })
	// }
}
