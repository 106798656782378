import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core'
import { Router, NavigationStart } from '@angular/router'

import { environment } from '@env/environment'
import { DialogManager, NavbarIcon, ScheduleViewTabState } from '@app/models'
import { ConnectionService, CoreService } from '@app/services'
import { Helper, log } from '@app/helpers'

import { Message } from 'primeng/message'
import { Subscription } from 'rxjs'

import introJs from 'intro.js'

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	standalone: false,
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
	environment = environment

	announcementsNavBarIconInstalled = false
	announcementsDialogManager = new DialogManager('announcementsScheduledGlobalEditModal')
	changePasswordDialogManager = new DialogManager('changePassword')
	dispatchDialogManager = new DialogManager('dispatchDialogManager')

	isConnected = true

	msgs: Message[] = []

	isCollapsed = true
	showWorkDialog = false
	showPeopleDialog = false
	showSettingsDialog = false
	showSchedulingDialog = false
	showOperationsDialog = false
	showReportsDialog = false
	showIntegrationDialog = false
	showMoreOptions = false

	menuManager = new HeaderMenuManager()

	subs = new Subscription()

	cardExpirationMessage = null

	canAccessEmpReports = false

	constructor(
		private cd: ChangeDetectorRef,
		private router: Router,
		private coreSrvc: CoreService,
		private connectSrvc: ConnectionService,
	) {
		this.subs.add(
			this.router.events.subscribe((e) => {
				if (e instanceof NavigationStart) {
					this.menuManager.reset()
					this.setActiveComponent(e.url)
				}
			}),
		)

		// Setup event handler for navbarNeedsUpdate
		this.subs.add(
			this.coreSrvc.notifySrvc.navBarNeedsUpdate.subscribe(() => {
				log('Got navbarNeedsUpdate')
				this.menuManager.reset()
				const url = this.router.url
				this.setActiveComponent(url)
			}),
		)

		this.subs.add(
			this.connectSrvc.monitor().subscribe((isConnected) => {
				this.isConnected = isConnected
				log('Connection online', this.isConnected)
			}),
		)

		// Setup trigger for announcement service ready
		this.subs.add(
			this.coreSrvc.dbSrvc.annSrvc.installNavBarIcon.subscribe(() => {
				this.coreSrvc.zone.run(() => {
					this.setupAnnouncementsNavbarIcon()
					this.setupReportMenuOption()
					this.runNewFeaturesTour()
				})
			}),
		)

		// Setup trigger for changes in company record
		this.subs.add(
			this.coreSrvc.dbSrvc.settingSrvc.companyChanged.subscribe(() => {
				this.coreSrvc.zone.run(() => {
					this.setupReportMenuOption()
				})
			}),
		)

		// Setup event to receive targeted announcement list
		this.subs.add(
			this.coreSrvc.dbSrvc.annSrvc.sendAnnouncementEvent.subscribe((event) => {
				const targetIds = event.ids
				this.openAnnouncementsDialog(targetIds)
			}),
		)
	}

	get isAuditMode(): boolean {
		return this.coreSrvc.auditMode
	}

	get isWorking(): boolean {
		return this.coreSrvc.workSrvc.isWorking()
	}

	get isAnalyticsEnabled(): boolean {
		return (
			this.coreSrvc.dbSrvc.settingSrvc.isInternalUser() || (this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()?.globalEnableAnalytics ?? false)
		)
	}

	get isJobSiteMerged(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.companyDataLoaded && this.coreSrvc.dbSrvc.settingSrvc.getCompany().merge_jobsite
	}

	get navbarIcons(): Array<NavbarIcon> {
		return this.coreSrvc.navbarSrvc.icons
	}

	get isAnnouncementFeatureReady(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.companyDataLoaded && this.coreSrvc.dbSrvc.settingSrvc.usersDataLoaded
	}

	get isNotificationsFeatureReady(): boolean {
		return true
	}

	get isInternalUser(): boolean {
		const user = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()
		if (user) {
			if (user.role === 'INTERNAL') {
				return true
			}
		}
		return false
	}

	get canCloseAccount(): boolean {
		const user = this.coreSrvc.dbSrvc.settingSrvc.getMyUser()
		if (user) {
			if (user.role === 'PRIMARY' || user.role === 'INTERNAL') {
				return true
			}
		}
		return false
	}

	get canAccessPayRates(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.getCompany()?.pay_rate || false
	}

	get isFileImportIntegrated(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.isFileImportIntegrated()
	}

	ngOnInit() {
		this.setupDispatchDialogManager()
	}

	ngAfterViewInit() {}

	ngOnDestroy() {
		this.subs.unsubscribe()
		this.coreSrvc.navbarSrvc.clearIcons()
	}

	collapseMenu() {
		this.isCollapsed = true
		return false
	}

	clearToasts() {
		this.coreSrvc.notifySrvc.clear()
	}

	toggleMenu() {
		this.isCollapsed = !this.isCollapsed
		return false
	}

	setActiveComponent(url: string) {
		// log('Setting header active state for', url)

		if (/\/admin\/dashboard/.test(url)) {
			this.menuManager.dashboardMenu = true
			return
		}

		// Top level menus
		if (/\/admin\/employees/.test(url)) {
			this.menuManager.peopleMenu = true
			this.menuManager.employeesMenu = true
			return
		}
		if (/\/admin\/jobsites/.test(url)) {
			this.menuManager.workMenu = true
			this.menuManager.jobSitesMenu = true
			return
		}
		if (/\/admin\/jobs/.test(url)) {
			this.menuManager.workMenu = true
			this.menuManager.jobsMenu = true
			return
		}
		if (/\/admin\/projects/.test(url)) {
			this.menuManager.workMenu = true
			this.menuManager.projectsMenu = true
			return
		}
		if (/\/admin\/breaks/.test(url)) {
			this.menuManager.workMenu = true
			this.menuManager.breaksMenu = true
			return
		}
		if (/\/admin\/tours/.test(url)) {
			this.menuManager.workMenu = true
			this.menuManager.toursMenu = true
			return
		}
		if (/\/admin\/notifications/.test(url)) {
			this.menuManager.workMenu = true
			this.menuManager.notificationsMenu = true
			return
		}

		if (/\/admin\/scheduler/.test(url)) {
			this.menuManager.schedulingMenu = true
			const selectedView = this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.currentView
			log('SELECTED VIEW', selectedView)
			switch (selectedView) {
				case 'SERIES':
					this.menuManager.schedulesMenu = true
					break
				case 'SHIFT':
					this.menuManager.shiftsMenu = true
					break
				case 'TIMEOFF':
					this.menuManager.timeOffMenu = true
					break
			}
			return
		}

		if (/\/admin\/transactions/.test(url)) {
			this.menuManager.operationsMenu = true
			this.menuManager.timeEntriesMenu = true
			return
		}

		// First handle employee reports option since it extends report path
		if (/\/admin\/reports\/employee/.test(url)) {
			this.menuManager.operationsMenu = true
			this.menuManager.reportsEmployee = true
			return
		}

		if (/\/admin\/reports/.test(url)) {
			this.menuManager.operationsMenu = true
			this.menuManager.reportsAdmin = true
			return
		}

		if (/\/admin\/analytics/.test(url)) {
			this.menuManager.operationsMenu = true
			this.menuManager.analytics = true
			return
		}

		// Integration menu

		if (/\/admin\/integrations/.test(url)) {
			this.menuManager.integrationsMenu = true
			if (/\/admin\/integrations\/fileimport/.test(url)) this.menuManager.fileImporter = true
			if (/\/admin\/integrations\/adp/.test(url)) this.menuManager.adp = true
			if (/\/admin\/integrations\/quickbooks/.test(url)) this.menuManager.quickbooks = true
			if (/\/admin\/integrations\/whentowork/.test(url)) this.menuManager.whenToWork = true
			if (/\/admin\/integrations\/wheniwork/.test(url)) this.menuManager.whenIWork = true
			return
		}

		// Admin menu

		if (/\/admin\/settings\/billing/.test(url)) {
			this.menuManager.adminMenu = true
			this.menuManager.billing = true
			return
		}
		if (/\/admin\/settings/.test(url)) {
			this.menuManager.adminMenu = true
			this.menuManager.settings = true
			return
		}
		if (/\/admin\/users/.test(url)) {
			// this.menuManager.adminMenu = true
			this.menuManager.peopleMenu = true
			this.menuManager.supervisors = true
		}
		if (/\/admin\/announcements/.test(url)) {
			// DEPRECATED 20240514
			// if (this.environment.development) {
			// 	this.menuManager.operationsMenu = true
			// } else {
			// 	this.menuManager.adminMenu = true
			// }
			this.menuManager.operationsMenu = true
			this.menuManager.announcements = true
			return
		}
		if (/\/admin\/contacts/.test(url)) {
			// this.menuManager.adminMenu = true
			this.menuManager.peopleMenu = true
			this.menuManager.contacts = true
		}
		if (/\/admin\/stations/.test(url)) {
			this.menuManager.adminMenu = true
			this.menuManager.stations = true
		}
		if (/\/admin\/payrates/.test(url)) {
			this.menuManager.adminMenu = true
			this.menuManager.payRates = true
		}
		if (/\/admin\/auditlog/.test(url)) {
			// DEPRECATED 20240514
			// if (this.environment.development) {
			// 	this.menuManager.operationsMenu = true
			// } else {
			// 	this.menuManager.adminMenu = true
			// }
			this.menuManager.operationsMenu = true
			this.menuManager.auditLog = true
			return
		}
		if (/\/admin\/comm-log/.test(url)) {
			// DEPRECATED 20240514
			// if (this.environment.development) {
			// 	this.menuManager.operationsMenu = true
			// } else {
			// 	this.menuManager.adminMenu = true
			// }
			this.menuManager.operationsMenu = true
			this.menuManager.commLog = true
			return
		}
		if (/\/admin\/close-account/.test(url)) {
			this.menuManager.adminMenu = true
			return
		}
	}

	isIconHidden(icon: NavbarIcon) {
		const isTransFilterActive = this.menuManager.timeEntriesMenu && !!this.coreSrvc.dbSrvc.tranSrvc.filterStartDate
		const isScheduleFilterActive = this.menuManager.schedulingMenu && !!this.coreSrvc.dbSrvc.schedulerSrvc.currentDayViewDate
		return icon.id !== 'announcements' && (isTransFilterActive || isScheduleFilterActive)
		// return !icon.visible() || isTransFilterActive || isScheduleFilterActive
	}

	routeTo(route: string, fragment?: string) {
		log('Route', route, fragment)
		this.collapseMenu()

		setTimeout(() => {
			if (fragment) {
				this.router.navigate([`${route}`], { fragment: fragment })
			} else {
				this.router.navigate([`${route}`])
			}
		}, 150)
	}

	// Call Center / Dispatch

	setupDispatchDialogManager() {
		this.dispatchDialogManager.headerLabel = 'My Call Center Status'
		this.dispatchDialogManager.canSubmit = () => false
		this.dispatchDialogManager.isSubmitBtnVisible = false
		this.dispatchDialogManager.cancelBtnLabel = 'Close'
	}

	setupReportMenuOption() {
		const prefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		this.canAccessEmpReports = true // environment.development ? true : prefs.reportEnableEmpReports // DEPRECATED 20240514
	}

	///////////////////
	// Announcements //
	///////////////////

	announcementTargetIds: Array<number> = []

	setupAnnouncementsNavbarIcon() {
		if (this.announcementsNavBarIconInstalled) return
		log('Installing Nav Bar Icon')
		const icon = new NavbarIcon()
		icon.id = 'announcements'
		icon.class = 'far fa-bullhorn right-menu-icon'
		icon.title = 'Send Announcment'
		icon.styleAttr = {
			'width': '20px',
			'height': '14px',
			'margin': '0px 14px 5px 8px',
			'font-size': '1em',
			'cursor': 'pointer',
		}
		icon.click = () => this.openAnnouncementsDialog([])
		icon.visible = () => true
		this.coreSrvc.navbarSrvc.addIcon(icon)
		log('Icons', this.coreSrvc.navbarSrvc.icons)
		this.announcementsNavBarIconInstalled = true
		this.cd.markForCheck()
	}

	openAnnouncementsDialog(targetIds: Array<number>) {
		this.announcementTargetIds = targetIds
		const isDataLoaded = this.coreSrvc.dbSrvc.settingSrvc.companyDataLoaded && this.coreSrvc.dbSrvc.settingSrvc.usersDataLoaded
		if (!isDataLoaded) {
			return
		}
		if (!this.announcementsDialogManager.isDialogVisible) {
			this.announcementsDialogManager.headerLabel = 'Send Announcement'
			this.announcementsDialogManager.isDialogVisible = true
		}
	}

	announcementProcessed() {
		log('Announcement Processed')
	}

	/////////////////////
	// Change Password //
	/////////////////////

	changePassword() {
		this.showSettingsDialog = false
		this.changePasswordDialogManager.headerLabel = 'Change Password'
		this.changePasswordDialogManager.footerLabel = 'Change password'
		this.changePasswordDialogManager.submitBtnLabel = 'Update'
		this.changePasswordDialogManager.canSubmit = () => false
		this.changePasswordDialogManager.isDialogVisible = true
	}
	passwordUpdateComplete() {
		this.coreSrvc.notifySrvc.notify('success', 'Updating', 'Reloading application so you can save your password.')
		this.changePasswordDialogManager.isDialogVisible = false
		setTimeout(() => {
			this.coreSrvc.dbSrvc.settingSrvc.reloadApplication()
		}, 2000)
	}

	// Notifications

	private setupNotificationsNavBarIcon() {
		log('Installing Nav Bar Icon')
		const icon = new NavbarIcon()
		icon.class = 'fa fa-bell-o'
		icon.title = 'Notifications'
		icon.styleAttr = { 'width': '20px', 'height': '14px', 'margin': '0px 14px 5px 8px', 'font-size': '.9em', 'cursor': 'pointer' }
		icon.click = () => this.openAnnouncementsDialog([])
		icon.visible = () => this.isNotificationsFeatureReady
		this.coreSrvc.navbarSrvc.addIcon(icon)
	}

	public onHideDialogFix(event) {
		log('event', event)
	}

	public showMainMenuButtons() {
		this.showMoreOptions = false
		setTimeout(() => {
			$('.btn-menu').blur()
		})
	}

	public reloadApp() {
		this.coreSrvc.dbSrvc.settingSrvc.reloadApplication()
	}

	public postMovedToPeopleToast(section: string) {
		if (this.environment.development) {
			this.coreSrvc.notifySrvc.notify(
				'info',
				'Moved to People',
				`Please access the ${section} section from the People menu. A future release will remove it from the Admin menu`,
				7,
			)
		}
	}

	switchToScheduleView(view: ScheduleViewTabState) {
		this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.setScheduleViewSection(view)
		this.router.navigate(['/admin/scheduler'])
		setTimeout(() => {
			this.coreSrvc.notifySrvc.navBarNeedsUpdate.next(true)
		}, 100)
	}

	showDropdown(dropdownItem: HTMLElement) {
		const dropdownMenu = dropdownItem.querySelector('.dropdown-menu')
		log('Dropdown Menu', dropdownMenu)
		if (dropdownMenu) {
			dropdownMenu.classList.add('show')
		}
	}

	// Method to hide the dropdown menu
	hideDropdown(dropdownItem: HTMLElement) {
		const dropdownMenu = dropdownItem.querySelector('.dropdown-menu')
		log('Dropdown Menu', dropdownMenu)
		if (dropdownMenu) {
			dropdownMenu.classList.remove('show')
		}
	}

	runNewFeaturesTour() {
		if (window.screen.width >= 1180) {
			this.reportsReminderTourDesktop()
		} else {
			this.reportsReminderTourMobile()
		}
	}

	reportsReminderTourDesktop() {
		const userIdSuffix = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()?.id ?? 0
		const cookieName = `gt-reportsmoved-20240605-${userIdSuffix}`
		const options: any = {
			overlayOpacity: 0.5,
			exitOnOverlayClick: false,
			dontShowAgain: true,
			dontShowAgainCookie: cookieName,
			steps: [
				{
					element: document.querySelector('#operationsMenu'),
					intro: `Just a reminder, we renamed <strong>Reports</strong> to <strong>Business Insight Reports</strong> and you can find it under the <strong>Operations</strong> menu.`,
				},
			],
		}
		this.coreSrvc.gtSrvc.startTour(options)
	}

	reportsReminderTourMobile() {
		const userIdSuffix = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()?.id ?? 0
		const cookieName = `gt-reportsmoved-20240605-${userIdSuffix}`
		const options: any = {
			overlayOpacity: 0.5,
			exitOnOverlayClick: false,
			dontShowAgain: true,
			dontShowAgainCookie: cookieName,
			steps: [
				{
					intro: `Just a reminder, we renamed <strong>Reports</strong> to <strong>Business Insight Reports</strong> and you can find it under the <strong>Operations</strong> menu.`,
				},
			],
		}
		this.coreSrvc.gtSrvc.startTour(options)
	}
}

class HeaderMenuManager {
	// Left Icons - Not in use right now
	dashboardMenu = false
	callCenterMenu = false

	// People Menus
	peopleMenu = false
	employeesMenu = false
	supervisors = false
	contacts = false

	// Work Menus
	workMenu = false
	jobSitesMenu = false
	jobsMenu = false
	projectsMenu = false
	breaksMenu = false
	toursMenu = false
	notificationsMenu = false

	// Scheduling Menu
	schedulingMenu = false
	schedulesMenu = false
	shiftsMenu = false
	timeOffMenu = false

	// Operations Menu
	operationsMenu = false
	timeEntriesMenu = false
	reportsEmployee = false
	reportsAdmin = false
	analytics = false
	commLog = false
	auditLog = false
	empReports = false

	// Reports Menu
	// reportsMenu = false

	// Integrations Menu
	integrationsMenu = false
	fileImporter = false
	adp = false
	quickbooks = false
	whenToWork = false
	whenIWork = false

	// Admin Menu
	adminMenu = false
	settings = false
	billing = false
	announcements = false
	stations = false
	payRates = false
	changePassword = false
	closeAccount = false

	reset() {
		for (const prop in this) {
			if (this[prop] === true) {
				;(this[prop] as boolean) = false
			}
		}
	}
}
