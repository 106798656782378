import { Component, OnInit } from '@angular/core'

import { QBOSync, IQBOSyncPayload, CrudAction } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'

import { log, DateTimeHelper } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'
import { Router } from '@angular/router'

@Component({
    selector: 'app-qbocustomer-data',
    templateUrl: './qbocustomer-data.component.html',
    styleUrls: ['./qbocustomer-data.component.scss'],
    standalone: false
})
export class QBOCustomerDataComponent implements OnInit {
	accessHelper: AccessHelper

	syncData: QBOSync
	lastImportDate = 'Ready for initial import'
	hasLog = false
	isWorking = false
	syncErrors = 0
	showTooltips = false

	CrudAction = CrudAction

	constructor(
		private router: Router,
		private coreSrvc: CoreService,
	) {
		this.setupAccessPermissions()
	}

	ngOnInit() {
		this.updateUI()
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'quickbooks')
	}

	canPerformAction(action: CrudAction) {
		return this.accessHelper.canPerformAction(action, false)
	}

	updateUI() {
		log('QBOSync Customer Component updating UI')
		this.syncData = this.coreSrvc.dbSrvc.qbSrvc.getQBOSyncData()
		this.syncErrors = this.syncData.customer_sync_errors
		if (this.syncData && this.syncData.customer_sync) {
			const importDate = this.syncData.customer_sync || null
			this.lastImportDate = DateTimeHelper.mediumDateTimeFromDateString(importDate)
			this.hasLog = true
		}
	}

	showLog(): boolean {
		return false
	}

	toggleTooltips(): boolean {
		this.showTooltips = !this.showTooltips
		return false
	}

	importData() {
		log('QBOSync Customer Component import data button clicked')
		if (!this.accessHelper.canPerformAction(CrudAction.update, false)) {
			this.accessHelper.notifyOperationNotAuthorized()
			return
		}
		this.isWorking = true
		const request: IQBOSyncPayload = {
			table: null,
			operation: 'customer',
		}
		this.coreSrvc.dbSrvc.lambdaSrvc.qboSync(request).then((result) => {
			this.coreSrvc.dbSrvc.readTable('qbo_sync').then((syncSuccess) => {
				this.coreSrvc.dbSrvc.readTable('qbo_customer').then((custSuccess) => {
					this.isWorking = false
					this.updateUI()
					this.router.navigate(['/admin/integrations/log/qbo/Customer'])
				})
			})
		})
	}
}
