<div *ngIf="!dialogManager.isEditing">
	<!-- <div style="margin-bottom: 15px">
		<app-search-filter [placeholder]="'filter records'" (search)="filterLinkedRecords($event)"> </app-search-filter>
	</div> -->

	<div *ngIf="false && linkedRecordsManager.filteredRecords.length > 0" class="ldc-contact-name">{{ contactName }} linked to:</div>

	<!-- No Checkpoints Message -->
	<div *ngIf="linkedRecordsManager.filteredRecords.length === 0" class="list-empty">
		<div style="color: gray">{{ emptyListMessage }}</div>
	</div>

	<div class="ldc-list">
		<div *ngFor="let card of linkedRecordsManager.filteredRecords">
			<div class="card ldc-card">
				<div>
					<div class="ldc-act-ico-wrap">
						<i class="far fa-edit ldc-act-ico act-ico-clr-green" (click)="editRecord(card)"></i>
						<i class="far fa-trash-alt ldc-act-ico act-ico-clr-red" (click)="card.showDeleteConfirmation = true"></i>
					</div>
					<div class="ldc-title">
						<div>{{ card.title }}</div>
						<!-- <div
							class="ldc-contact-type"
							[class.ldc-hl-primary]="card.linkRecord.association_type === 'PRIMARY'"
							[class.ldc-hl-emergency]="card.linkRecord.association_type === 'EMERGENCY'"
						>
							{{ formatContactType(card) }}
						</div> -->
					</div>
				</div>
				<div class="ldc-card-body">
					<hr *ngIf="card.linkRecord.details || card.showDeleteConfirmation" class="ldc-hr" />

					<!-- Visible content when not deleting -->
					<div *ngIf="!card.showDeleteConfirmation">
						<div class="ldc-card-note">{{ card.linkRecord.details }}</div>
						<hr class="ldc-hr" />
						<div class="ldc-notify" [class.ldc-notify-enabled]="card.linkRecord.notify">
							Notifications {{ card.linkRecord.notify ? 'Enabled' : 'Disabled' }}
						</div>
					</div>

					<!-- Visible content when deleting -->
					<div *ngIf="card.showDeleteConfirmation">
						<div class="d-flex justify-content-between">
							<div class="confirm-delete-label">Confirm Delete</div>
							<div>
								<button class="btn btn-sm btn-outline-secondary" style="margin-right: 12px" (click)="card.showDeleteConfirmation = false">
									Cancel
								</button>
								<button class="btn btn-sm btn-outline-danger" (click)="deleteRecord(card)">Delete</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="dialogManager.isEditing">
	<app-link-contact-org-edit
		[source]="source"
		[dialogManager]="dialogManager"
		(recordSaved)="recordSavedEventHandler()"
		(cancelEdit)="cancelEditEventHandler()"
	></app-link-contact-org-edit>
</div>
