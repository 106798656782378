<div>
	<div id="CHECKLIST_REPORT" class="ur-separator ur-report-sep" style="margin-bottom: 15px">Employee Survey</div>

	<div class="form-group">
		<div class="ur-basic-label">Shift Date</div>
		<app-date-time-picker
			id="SHIFT_DATE"
			[currentDate]="reportData.shiftDate.value"
			[pickerType]="reportData.shiftDate.style"
			[pickerMode]="'dialog'"
			[hour12Timer]="format12Hour"
			[format]="datePickerFormat"
			(pickerDidClose)="reportData.shiftDate.value = $event"
		>
		</app-date-time-picker>
	</div>

	<div id="EMPLOYEE_INFORMATION" class="ur-separator ur-report-sep">Employee Information</div>

	<div class="form-group">
		<div class="row">
			<div class="col-6">
				<div class="ur-basic-label">First Name</div>
				<div>
					<input
						id="EMPLOYEE_FIRST_NAME"
						class="form-control"
						[(ngModel)]="reportData.employee.firstName.value"
						(focus)="elementFocused($event)"
					/>
				</div>
			</div>
			<div class="col-6">
				<div class="ur-basic-label">Last Name</div>
				<div>
					<input
						id="EMPLOYEE_LAST_NAME"
						class="form-control"
						[(ngModel)]="reportData.employee.lastName.value"
						(focus)="elementFocused($event)"
					/>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="reportData.employeeChecklists?.length > 0">
		<div *ngFor="let checklist of reportData.employeeChecklists; let clIdx = index">
			<!-- <div *ngIf="clIdx === 0 && !checklist.title" [id]="'CHECKLISTS_BEGIN_' + clIdx" class="ur-separator ur-report-sep">Checklist Options</div> -->
			<div [id]="checklist.tagId" class="ur-separator ur-report-sep">{{ checklist.title || 'Checklist Options' }}</div>

			<div *ngIf="checklist.instructions" class="ur-block-note">
				<div>{{ checklist.instructions }}</div>
				<hr class="ur-form-item-seperator" />
			</div>
			<div *ngFor="let item of checklist.items; let idx = index">
				<app-checklist-report-item-checkbox
					*ngIf="item.type === 'BOOLEAN'"
					[item]="$any(item)"
					(toggleSkipCheckbox)="toggleSkipCheckbox($event)"
					(textResizer)="textResizer($event)"
				></app-checklist-report-item-checkbox>
				<app-checklist-report-item-input
					*ngIf="item.type === 'STRING'"
					[item]="$any(item)"
					(toggleSkipCheckbox)="toggleSkipCheckbox($event)"
					(textResizer)="textResizer($event)"
				></app-checklist-report-item-input>
				<app-checklist-report-item-range
					*ngIf="item.type === 'RANGE'"
					[item]="$any(item)"
					(toggleSkipCheckbox)="toggleSkipCheckbox($event)"
					(textResizer)="textResizer($event)"
				></app-checklist-report-item-range>
				<app-checklist-report-item-select
					*ngIf="item.type === 'SELECT'"
					[item]="$any(item)"
					(toggleSkipCheckbox)="toggleSkipCheckbox($event)"
					(textResizer)="textResizer($event)"
				></app-checklist-report-item-select>
				<hr *ngIf="idx < checklist.items.length - 1" class="ur-form-item-seperator" />
			</div>
		</div>
	</div>

	<!-- Original Checkout Checklist from company record data - DEPRECATED BUT CAN NEVER BE REMOVED -->
	<div *ngIf="reportData.companyChecklist?.length > 0">
		<div id="COMPANY_CHECKLIST" class="ur-separator ur-report-sep">Company Checklist</div>

		<div *ngFor="let companyOption of reportData.companyChecklist">
			<div class="row ur-checkbox-row">
				<div class="col-10">
					<label class="ur-checkbox-label" [for]="companyOption.tagId">{{ companyOption.label }}</label>
				</div>
				<div class="col-2 text-right">
					<input [id]="companyOption.tagId" type="checkbox" class="form-control ur-checkbox" [(ngModel)]="companyOption.value" />
				</div>
			</div>
		</div>
	</div>
	<!-- Original Checkout Checklist from company record data - DEPRECATED DEPRECATED BUT CAN NEVER BE REMOVED -->

	<div id="EMPLOYEE_INFORMATION" class="ur-separator ur-report-sep">Additional Comments</div>

	<!-- Additional Notes -->
	<div class="form-group">
		<textarea
			id="SUMMARY_NOTES"
			class="form-control ur-basic-textarea"
			[class.ur-skip-textarea]="reportData.summary.additionalNotes.skip"
			[disabled]="reportData.summary.additionalNotes.skip"
			[placeholder]="reportData.summary.additionalNotes.skip ? '- skipped -' : 'Additional comments'"
			[(ngModel)]="reportData.summary.additionalNotes.value"
			(focus)="elementFocused($event)"
		>
		</textarea>
	</div>
</div>
