<div class="section-table">
	<table id="stationTable" class="stripe nowrap" cellspacing="0" width="100%"></table>
	<app-table-footer-buttons [marginTop]="'15px'" [newBtnLabel]="'Add Station'" (newBtnClicked)="createRecord()"></app-table-footer-buttons>
	<i id="clear-search-icon" (click)="clearSearch()" class="fa fa-times-circle-o" aria-hidden="true"></i>
</div>

<app-modal-dialog *ngIf="editDialogManager.isDialogVisible" [dialogManager]="editDialogManager">
	<app-station-edit
		[recordId]="editAction.recordId"
		[action]="editAction.action"
		[dialogManager]="editDialogManager"
		(recordUpdated)="recordUpdated()"
	>
	</app-station-edit>
</app-modal-dialog>

<app-delete-record-dialog
	*ngIf="deleteAction.showDeleteRecordDialog"
	[showDeleteRecordDialog]="deleteAction.showDeleteRecordDialog"
	[tableName]="deleteAction.tableName"
	[recordId]="deleteAction.recordId"
	[recordLabel]="deleteAction.recordLabel"
	(cancelDelete)="deleteActionCancel()"
	(actionComplete)="deleteActionComplete($event)"
>
</app-delete-record-dialog>

<app-modal-dialog *ngIf="linkedRecordsDialogManager.isDialogVisible" [dialogManager]="linkedRecordsDialogManager">
	<div style="margin-bottom: 15px">
		<app-search-filter [placeholder]="'filter records'" (search)="filterLinkedRecords($event)"> </app-search-filter>
	</div>

	<ul style="color: gray">
		<li *ngFor="let record of linkedRecordsDialog.filteredRecords">{{ record }}</li>
	</ul>
</app-modal-dialog>

<app-basic-modal
	[(visible)]="empDebugAction.showDialog"
	modalId="empDebugModal"
	[preventBackgroundClose]="true"
	[removePadding]="true"
	[modalHeader]="empDebugAction.header"
	[modalFooter]="empDebugAction.footer"
	[modalWidth]="450"
	[modalHeight]="1150"
>
	<div *ngIf="empDebugAction.showDialog" style="width: 100%; height: 100%">
		<iframe
			id="empAppDebugFrame"
			width="100%"
			height="100%"
			[src]="empDebugAction.safeUrl"
			style="flex-grow: 1; border: none; margin: 0; padding: 0"
		></iframe>
	</div>
</app-basic-modal>

<app-modal-dialog *ngIf="sectionPrefsDialogManager.isDialogVisible" [dialogManager]="sectionPrefsDialogManager">
	<app-local-prefs [dialogManager]="sectionPrefsDialogManager" (prefsUpdated)="prefsDataSaved()"></app-local-prefs>
</app-modal-dialog>
