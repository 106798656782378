<hr />

<div *ngIf="!logoUploaderVisible" class="d-flex justify-content-between">
	<button class="btn-sub" [class.btn-half]="hasCustomLogo" (click)="prepareForLogoUpload()">Replace Company&nbsp;Logo</button>
	<button *ngIf="hasCustomLogo" class="btn-sub" [class.btn-half]="hasCustomLogo" (click)="removeCompanyLogo()">Remove Company&nbsp;Logo</button>
</div>

<div *ngIf="logoUploaderVisible" style="margin-top: 30px">
	<p-fileUpload
		#logoUploader
		name="myfile[]"
		[customUpload]="true"
		chooseLabel="Upload"
		[auto]="true"
		[multiple]="true"
		(uploadHandler)="uploadHandler($event)"
	>
		<ng-template let-file pTemplate="content">
			<div class="upload-info">
				<div *ngIf="!status.isUploading">Click the Upload button to upload file or drag and drop the logo here.</div>
				<div *ngIf="status.isUploading" class="upload-active">
					<i class="fas fa-loader fa-spin"></i> Uploading Logo ... {{ status.percentComplete }}%
				</div>
				<!-- <div *ngIf="status.isProcessing" class="upload-active"><i class="fas fa-loader fa-spin"></i> Processing File ...</div> -->
			</div>
		</ng-template>
		<ng-template pTemplate="file">
			<!-- Template for individual file -->
		</ng-template>
	</p-fileUpload>

	<div class="options-note-gold" style="margin-top: 25px">
		Supported file types for company logo are GIF, JPG, and PNG. The preferred format is PNG with a width of 250 pixels and a height of 60 pixels or
		of similar scale.
	</div>
</div>
