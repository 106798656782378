import { Component, OnInit } from '@angular/core'

import { LocationException } from '@app/models'
import { DatabaseService } from '@app/services'

import { log } from '@app/helpers'

@Component({
    selector: 'app-exceptions-location-list',
    templateUrl: './exceptions-location-list.component.html',
    styleUrls: ['./exceptions-location-list.component.scss'],
    standalone: false
})
export class ExceptionsLocationListComponent implements OnInit {
	constructor(private dbSrvc: DatabaseService) {}

	ngOnInit() {}

	get list(): LocationException[] {
		return this.dbSrvc.excptSrvc.getLocations()
	}
}
