import { Injectable, EventEmitter } from '@angular/core'
import { OnboardDefinitionRecord } from '@app/models'

import { log } from '@app/helpers'
import _ from 'lodash'
import { SelectItem } from 'primeng/api/selectitem'
import { Global } from '@app/models/global'

@Injectable({
	providedIn: 'root',
})
export class OnboardingService {
	OnboardDefinitionDataLoaded = false
	onboardDefinitionList: Array<OnboardDefinitionRecord> = []
	onboardDefinitionListChange = new EventEmitter<Array<OnboardDefinitionRecord>>()
	onboardDefinitionListLastUpdated: Date

	// viewManager = new BaseViewManager()

	constructor() {
		log('Creating OnboardingService')
	}

	clearData() {
		this.onboardDefinitionList = []
		this.OnboardDefinitionDataLoaded = false
		this.onboardDefinitionListLastUpdated = null
	}

	getOnboardDefinitionRecords(): Array<OnboardDefinitionRecord> {
		const result = _.sortBy(this.onboardDefinitionList, 'name')
		return result
	}

	getOnboardDefinitionRecordById(id: number) {
		return this.onboardDefinitionList.find((rec) => rec.id === id)
	}

	removeOnboardDefinitionRecord(recordId: number) {
		this.onboardDefinitionList = this.onboardDefinitionList.filter((rec) => rec.id !== recordId)
	}

	setOnboardDefinitionRecords(records: Array<OnboardDefinitionRecord>) {
		this.onboardDefinitionListLastUpdated = new Date()
		this.onboardDefinitionList = records.map((rec) => new OnboardDefinitionRecord(rec))
		this.OnboardDefinitionDataLoaded = true
		this.onboardDefinitionListChange.next(this.onboardDefinitionList)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateOnboardDefinitionRecords(records: Array<OnboardDefinitionRecord>) {
		const newRecords = records.map((rec) => new OnboardDefinitionRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.onboardDefinitionList.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.onboardDefinitionList.push(newRecord)
			}
		}
		this.onboardDefinitionListLastUpdated = new Date()
		this.onboardDefinitionListChange.next(this.onboardDefinitionList)
	}
}
