import { DatabaseService } from '@app/services/backend/database.service'
import { UserPermissionsList, PermissionTableName, UserPermissions, CrudAction } from '@app/models/permissions'
import { JobRecord, JobsViewTabState } from '@app/models/job'
import { EmployeeRecord, EmployeesViewTabState } from '@app/models/employee'
import { VacationRecord } from '@app/models/vacation'
import { JobSiteRecord } from '@app/models/jobsite'
import { TransactionLogRecord } from '@app/models/transaction'
import { UserRecord } from '@app/models/user'

import _ from 'lodash'
import { CoreService } from '@app/services'
import { Incident } from '@app/models'

export class SupervisorOwnedIds {
	employee: Array<number> = []
	site: Array<number> = []
	job: Array<number> = []
}

export class AccessHelper {
	private myUserId: number
	private coreSrvc: CoreService
	private dbSrvc: DatabaseService
	private permList: UserPermissionsList
	private ownedIds = new SupervisorOwnedIds()

	public isManager = false
	public isRestricted = false

	// Available if instantiated with a PermissionTableName
	private userPermissions: UserPermissions

	constructor(coreSrvc: CoreService, tableName?: PermissionTableName) {
		const myUser = coreSrvc.dbSrvc.settingSrvc.getMyUser()
		this.isManager = myUser.role === 'MANAGER'
		this.myUserId = myUser.id
		this.dbSrvc = coreSrvc.dbSrvc

		const accessPermissions = myUser.getAccessPermissions()
		this.permList = accessPermissions.getUserPermissionsList()

		this.updateSupervisorIds()
		if (tableName) {
			this.setupForTable(tableName)
		}
		// log('PERMISSION LIST', this.permList)
	}

	getUserPermissionsList(): UserPermissionsList {
		return this.permList
	}

	getPermissionsFor(tableName: PermissionTableName): UserPermissions {
		return this.permList.getPermissionsFor(tableName)
	}

	setupForTable(tableName: PermissionTableName) {
		this.userPermissions = this.permList.getPermissionsFor(tableName)
		this.isRestricted = !this.userPermissions.access.read && this.userPermissions.owner.read
	}

	getAccessibleIdsFor(type: 'employee' | 'site' | 'job') {
		switch (type) {
			case 'employee':
				return this.ownedIds.employee
			case 'site':
				return this.ownedIds.site
			case 'job':
				return this.ownedIds.job
		}
	}

	updateSupervisorIds() {
		const isManager = this.dbSrvc.settingSrvc.isUserAManager()
		const managedUserIds = this.dbSrvc.settingSrvc.getManagedUserIds()

		// Setup owned Employee IDs
		const allEmployeeIds = this.dbSrvc.empSrvc.getAllEmployees(this.myUserId).map((e) => e.id)
		const allManagedEmployeeIds = this.dbSrvc.empSrvc.getManagedAllEmployees(managedUserIds).map((e) => e.id)
		this.ownedIds.employee = isManager ? allManagedEmployeeIds : allEmployeeIds
		this.ownedIds.employee.unshift(0) // Add the Any Employee
		this.ownedIds.employee.unshift(1) // Add the Any Employee

		// Setup owned Job Site IDs
		const allJobSiteIds = this.dbSrvc.siteSrvc.getAllJobSites(this.myUserId).map((s) => s.id)
		const allManagedJobSiteIds = this.dbSrvc.siteSrvc.getAllManagedJobSites(managedUserIds).map((s) => s.id)
		this.ownedIds.site = isManager ? allManagedJobSiteIds : allJobSiteIds

		// Setup owned Job IDs which are based off of previously setup Job Site IDs
		const ownedJobIds = this.dbSrvc.jobSrvc.getJobsForJobSiteIds(this.ownedIds.site).map((j) => j.id)
		const unAssignedJobId = this.dbSrvc.jobSrvc.getUnassignedJob().id
		// const allManagedJobIds = this.dbSrvc.jobSrvc.getJobsForJobSiteIds(allManagedJobSiteIds).map(j => j.id)
		this.ownedIds.job = ownedJobIds
		this.ownedIds.job.unshift(unAssignedJobId) // Add the Unassigned Job
	}

	isMyRecord(id: number, type: 'employee' | 'site' | 'job') {
		switch (type) {
			case 'employee':
				return this.ownedIds.employee.includes(id)
			case 'site':
				return this.ownedIds.site.includes(id)
			case 'job':
				return this.ownedIds.job.includes(id)
		}
	}

	canPerformAction(action: CrudAction, isMyRecord: boolean, table?: PermissionTableName): boolean {
		let access = this.userPermissions.access
		let owner = this.userPermissions.owner

		if (table) {
			const tablePerms = this.getPermissionsFor(table)
			access = tablePerms.access
			owner = tablePerms.owner
		}

		switch (action) {
			case CrudAction.create:
				if (access.create || owner.create) {
					return true
				}
				return false
			case CrudAction.read:
				if (access.read) {
					return true
				}
				if (owner.read && isMyRecord) {
					return true
				}
				return false
			case CrudAction.update:
				if (access.update) {
					return true
				}
				if (owner.update && isMyRecord) {
					return true
				}
				return false
			case CrudAction.delete:
				if (access.delete) {
					return true
				}
				if (owner.delete && isMyRecord) {
					return true
				}
				return false
			default:
				return false
		}
	}

	notifyOperationNotAuthorized() {
		this.coreSrvc.notifySrvc.default('operationNotAuthorized')
	}

	getEmployeeTableList(displayState: EmployeesViewTabState): Array<EmployeeRecord> {
		this.updateSupervisorIds()

		const access = this.userPermissions.access
		const owner = this.userPermissions.owner
		const isSupRestricted = !access.read && owner.read
		const myUserId = this.dbSrvc.settingSrvc.getMyUserId()
		const isManager = this.dbSrvc.settingSrvc.isUserAManager()
		const managedSupIds = this.dbSrvc.settingSrvc.getManagedUserIds()

		if (displayState === 'ACTIVE') {
			return isManager && isSupRestricted
				? this.dbSrvc.empSrvc.getManagedActiveEmployees(managedSupIds)
				: this.dbSrvc.empSrvc.getActiveEmployees(isSupRestricted ? myUserId : null)
		}
		if (displayState === 'INACTIVE') {
			return isManager && isSupRestricted
				? this.dbSrvc.empSrvc.getManagedInctiveEmployees(managedSupIds)
				: this.dbSrvc.empSrvc.getInactiveEmployees(isSupRestricted ? myUserId : null)
		}
		if (displayState === 'ALL') {
			return isManager && isSupRestricted
				? this.dbSrvc.empSrvc.getManagedAllEmployees(managedSupIds)
				: this.dbSrvc.empSrvc.getAllEmployees(isSupRestricted ? myUserId : null)
		}
	}

	getJobSiteTableList(): Array<JobSiteRecord> {
		this.updateSupervisorIds()
		const isManager = this.dbSrvc.settingSrvc.isUserAManager()
		const managedSupIds = this.dbSrvc.settingSrvc.getManagedUserIds()
		const isRestricted = !this.userPermissions.access.read && this.userPermissions.owner.read
		const myUserId = this.dbSrvc.settingSrvc.getMyUserId()
		const supId = isRestricted ? myUserId : null
		return isManager && isRestricted
			? this.dbSrvc.siteSrvc.getAllManagedJobSites(managedSupIds).filter((s) => !s.company_default)
			: this.dbSrvc.siteSrvc.getAllJobSites(supId).filter((s) => !s.company_default)
	}

	getJobTableList(viewState: JobsViewTabState): Array<JobRecord> {
		this.updateSupervisorIds()
		const isSupRestricted = !this.userPermissions.access.read && this.userPermissions.owner.read
		const jobIdsForSup = this.ownedIds.job

		const allList = isSupRestricted
			? this.dbSrvc.jobSrvc.getJobsForTable().filter((j) => jobIdsForSup.includes(j.id))
			: this.dbSrvc.jobSrvc.getJobsForTable()

		if (viewState === 'ACTIVE') {
			return allList.filter((j) => j.active)
		}
		if (viewState === 'INACTIVE') {
			return allList.filter((j) => !j.active)
		}
		if (viewState === 'ALL') {
			return allList
		}
	}

	getVacationTableList(): Array<VacationRecord> {
		this.updateSupervisorIds()
		const isSupRestricted = !this.userPermissions.access.read && this.userPermissions.owner.read

		if (isSupRestricted) {
			return this.dbSrvc.vacSrvc.getVacations().filter((v) => this.ownedIds.employee.includes(v.employee_id))
		} else {
			return this.dbSrvc.vacSrvc.getVacations()
		}
	}

	getTransactionTableList(): Array<TransactionLogRecord> {
		this.updateSupervisorIds()

		const isSupRestricted = !this.userPermissions.access.read && this.userPermissions.owner.read
		const supId = isSupRestricted ? this.myUserId : null

		const list = this.dbSrvc.tranSrvc.getTransactions()
		return this.filterTransactionsForPermissions(list, supId)
	}

	private filterTransactionsForPermissions(list: Array<TransactionLogRecord>, supId?: number) {
		if (!supId) {
			return _.sortBy(list, 'row_number')
		}

		const permissions = this.permList.getPermissionsFor('transaction')
		const managedSupIds = this.dbSrvc.settingSrvc.getManagedUserIds()
		const isManager = this.dbSrvc.settingSrvc.isUserAManager()
		const isRestricted = !permissions.access.read && permissions.owner.read

		const empIdsForSup =
			isManager && isRestricted
				? this.dbSrvc.empSrvc.getManagedAllEmployees(managedSupIds).map((e) => e.id)
				: this.dbSrvc.empSrvc.getAllEmployees(supId).map((e) => e.id)
		const siteIdsForSup =
			isManager && isRestricted
				? this.dbSrvc.siteSrvc.getAllManagedJobSites(managedSupIds).map((e) => e.id)
				: this.dbSrvc.siteSrvc.getAllJobSites(supId).map((s) => s.id)
		const unassignedJobId = this.dbSrvc.jobSrvc.getUnassignedJob().id

		const supFilteredList = list.filter((t) => {
			if (t.employee_id === 0) {
				return t.jobsite_id === unassignedJobId || siteIdsForSup.includes(t.jobsite_id)
			}
			if (t.job_id === unassignedJobId) {
				return empIdsForSup.includes(t.employee_id)
			}
			return empIdsForSup.includes(t.employee_id) || siteIdsForSup.includes(t.jobsite_id)
		})

		const sortedList = _.sortBy(supFilteredList, 'row_number')
		return sortedList
	}

	getIncidentTableList(): Array<Incident> {
		this.updateSupervisorIds()

		const isSupRestricted = !this.userPermissions.access.read && this.userPermissions.owner.read
		const supId = isSupRestricted ? this.myUserId : null

		const list = this.dbSrvc.incidentLogSrvc.getIncidentLogRecords()
		return this.filterIncidentsForPermissions(list, supId)
	}

	private filterIncidentsForPermissions(list: Array<Incident>, supId?: number) {
		if (!supId) {
			return list
		}

		const permissions = this.permList.getPermissionsFor('incident')
		const managedSupIds = this.dbSrvc.settingSrvc.getManagedUserIds()
		const isManager = this.dbSrvc.settingSrvc.isUserAManager()
		const isRestricted = !permissions.access.read && permissions.owner.read

		const empIdsForSup =
			isManager && isRestricted
				? this.dbSrvc.empSrvc.getManagedAllEmployees(managedSupIds).map((e) => e.id)
				: this.dbSrvc.empSrvc.getAllEmployees(supId).map((e) => e.id)
		const siteIdsForSup =
			isManager && isRestricted
				? this.dbSrvc.siteSrvc.getAllManagedJobSites(managedSupIds).map((e) => e.id)
				: this.dbSrvc.siteSrvc.getAllJobSites(supId).map((s) => s.id)
		const unassignedJobId = this.dbSrvc.jobSrvc.getUnassignedJob().id

		const supFilteredList = list.filter((t) => {
			if (t.employee_id === 0) {
				return t.job_site_id === unassignedJobId || siteIdsForSup.includes(t.job_site_id)
			}
			if (t.job_id === unassignedJobId) {
				return empIdsForSup.includes(t.employee_id)
			}
			return empIdsForSup.includes(t.employee_id) || siteIdsForSup.includes(t.job_site_id)
		})

		return supFilteredList
	}

	getUserTableList(): Array<UserRecord> {
		this.updateSupervisorIds()

		const isManager = this.dbSrvc.settingSrvc.isUserAManager()
		const isSupRestricted = !this.userPermissions.access.read && this.userPermissions.owner.read
		const myRecord = this.dbSrvc.settingSrvc.getMyUser()

		return isManager && isSupRestricted
			? this.dbSrvc.settingSrvc.getManagedUsers()
			: isSupRestricted
				? [myRecord]
				: this.dbSrvc.settingSrvc.getUsers()
	}
}
