import { Component, OnInit } from '@angular/core'

@Component({
    selector: 'app-shell',
    templateUrl: './app.shell.html',
    styleUrls: ['./app.shell.scss'],
    standalone: false
})
export class AppShellComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
