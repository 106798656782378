import { DateTimeHelper, log } from '@app/helpers'
import moment from 'moment-timezone'

export class FISyncRecord {
	id: number
	company_id: number
	aws_access_key_id: string
	aws_secret_access_key: string
	emails: string
	employee_sync: string // Date
	employee_sync_errors: number
	job_sync: string // Date
	job_sync_errors: number
	supervisor_sync: string // Date
	supervisor_sync_errors: number
	transaction_log_sync: string // Date
	transaction_log_sync_errors: number
	vacation_sync: string // Date
	vacation_sync_errors: number
	created: string // Date
	updated: string // Date

	constructor(record?: FISyncRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.employee_sync = DateTimeHelper.stripUtcTag(record.employee_sync)
			this.job_sync = DateTimeHelper.stripUtcTag(record.job_sync)
			this.supervisor_sync = DateTimeHelper.stripUtcTag(record.supervisor_sync)
			this.transaction_log_sync = DateTimeHelper.stripUtcTag(record.transaction_log_sync)
			this.vacation_sync = DateTimeHelper.stripUtcTag(record.vacation_sync)
			this.created = DateTimeHelper.stripUtcTag(record.created)
			this.updated = DateTimeHelper.stripUtcTag(record.updated)
		}
	}
}

export class FISync {
	id: number
	companyId: number
	awsAccessKeyId: string
	awsSecretAccessKey: string
	emails: Array<string>
	employeeSyncDate: Date
	employeeSyncErrors: number
	jobSyncDate: Date
	jobSyncErrors: number
	supervisorSyncDate: Date
	supervisorSyncErrors: number
	transactionsSyncDate: Date
	transactionsSyncErrors: number
	vacationSyncDate: Date
	vacationSyncErrors: number
	created: Date
	updated: Date

	originalRecord: FISyncRecord

	constructor(record?: FISyncRecord) {
		if (record) {
			this.id = record.id
			this.companyId = record.company_id
			this.awsAccessKeyId = record.aws_access_key_id
			this.awsSecretAccessKey = record.aws_secret_access_key
			this.emails = record.emails ? record.emails.split(',') : []
			this.employeeSyncDate = record.employee_sync ? moment(record.employee_sync).toDate() : null
			this.employeeSyncErrors = record.employee_sync_errors || 0
			this.jobSyncDate = record.job_sync ? moment(record.job_sync).toDate() : null
			this.jobSyncErrors = record.job_sync_errors || 0
			this.supervisorSyncDate = record.supervisor_sync ? moment(record.supervisor_sync).toDate() : null
			this.supervisorSyncErrors = record.supervisor_sync_errors || 0
			this.transactionsSyncDate = record.transaction_log_sync ? moment(record.transaction_log_sync).toDate() : null
			this.transactionsSyncErrors = record.transaction_log_sync_errors || 0
			this.vacationSyncDate = record.vacation_sync ? moment(record.vacation_sync).toDate() : null
			this.vacationSyncErrors = record.vacation_sync_errors || 0
			this.created = record.created ? moment(record.created).toDate() : null
			this.updated = record.updated ? moment(record.updated).toDate() : null
			this.originalRecord = record
			log('FISync Constructor', this)
		}
	}

	buildUpdateRecord(): FISyncRecord {
		const record = new FISyncRecord()
		record.id = this.id
		record.company_id = this.companyId
		record.aws_access_key_id = this.awsAccessKeyId
		record.aws_secret_access_key = this.awsSecretAccessKey
		record.emails = this.emails.join(',')
		record.employee_sync = this.employeeSyncDate ? this.employeeSyncDate.toISOString() : null
		record.employee_sync_errors = this.employeeSyncErrors
		record.job_sync = this.jobSyncDate ? this.jobSyncDate.toISOString() : null
		record.job_sync_errors = this.jobSyncErrors
		record.transaction_log_sync = this.transactionsSyncDate ? this.transactionsSyncDate.toISOString() : null
		record.transaction_log_sync_errors = this.transactionsSyncErrors
		record.vacation_sync = this.vacationSyncDate ? this.vacationSyncDate.toISOString() : null
		record.vacation_sync_errors = this.vacationSyncErrors
		record.created = this.created ? this.created.toISOString() : null
		record.updated = this.updated ? this.updated.toISOString() : null
		return record
	}

	clone(): FISync {
		const record = this.buildUpdateRecord()
		const result = new FISync(record)
		result.originalRecord = this.originalRecord
		return result
	}
}
