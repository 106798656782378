import { Component, Input, OnInit } from '@angular/core'
import { log } from '@app/helpers'
import { ScheduleEntry } from '@app/models'
import { DatabaseService } from '@app/services'

class ScheduleViewChangedItemViewModel {
	id: number
	employeeCount: number
	startTime: string
	endTime: string
	startDate: string
	endDate: string

	rule: string

	constructor(entry: ScheduleEntry) {
		this.id = entry.id
		this.startDate = entry.start_date
		this.startTime = entry.start_time
		this.endDate = entry.recurrence_end
		this.endTime = entry.end_time
		this.rule = entry.recurrence
		this.employeeCount = entry.employee_count
	}

	get isOccurrenceChange(): boolean {
		return !!this.endDate
	}
	get isSeriesChange(): boolean {
		return !this.isOccurrenceChange
	}
}

@Component({
    selector: 'app-scheduler-list-changed-items',
    templateUrl: './changed-items.component.html',
    styleUrls: ['./changed-items.component.scss'],
    standalone: false
})
export class ScheduleViewChangedItemsComponent implements OnInit {
	list: Array<ScheduleViewChangedItemViewModel> = []

	@Input() recordId: number

	constructor(private dbSrvc: DatabaseService) {}

	ngOnInit(): void {
		this.loadData()
	}

	loadData() {
		const recurList = this.dbSrvc.schedulerSrvc.getChildEntriesForParentId(this.recordId)
		this.list = recurList.map((r) => new ScheduleViewChangedItemViewModel(r))
		log('Child Entries', recurList)
	}
}
