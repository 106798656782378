<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<form [hidden]="isUpdating" class="edit-form-block" novalidate [formGroup]="form" (keydown.enter)="$event.preventDefault()" autocomplete="off">
	<div class="options-block" [class.input-required]="!form.get('contact_id').value">
		<div class="options-title-inline">Contact</div>
		<span *ngIf="!form.get('contact_id').value" class="float-right">(required)</span>
		<p-select
			*ngIf="source === 'ORGANIZATION'"
			appScrollLockSelect
			placeholder="Select Contact"
			[options]="contactOptions"
			formControlName="contact_id"
			[filter]="true"
			filterBy="label,data.external_id"
		></p-select>
		<div *ngIf="source === 'CONTACT'" class="input-disabled">{{ contactName }}</div>
	</div>
	<div class="options-block" [class.input-required]="!form.get('vendor_client_id').value">
		<div class="options-title-inline">Client / Vendor</div>
		<span *ngIf="!form.get('vendor_client_id').value" class="float-right">(required)</span>
		<span *ngIf="form.get('vendor_client_id').value" class="float-right lce-org-type">{{ orgType }}</span>

		<p-select
			*ngIf="source === 'CONTACT'"
			appScrollLockSelect
			placeholder="Select Client / Vendor"
			[options]="organizationOptions"
			formControlName="vendor_client_id"
			[filter]="true"
			filterBy="label,data.external_id"
		>
		</p-select>
		<div *ngIf="source === 'ORGANIZATION'" class="input-disabled">{{ organizationName }}</div>
	</div>
	<div class="options-block">
		<div class="options-title-inline">Notes</div>
		<div>
			<textarea class="options-textarea" formControlName="details" placeholder="Enter notes"></textarea>
		</div>
	</div>

	<div class="options-block">
		<div class="checkbox-item d-flex justify-content-between">
			<div class="checkbox-wrap">
				<p-checkbox formControlName="notify" [binary]="true"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleCheckbox('notify')" class="has-pointer">Enable Notifications</strong>
				<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('notifyPhone')"></i> -->
			</div>
		</div>
	</div>
</form>
