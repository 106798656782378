import { Component, OnInit, ViewChild } from '@angular/core'
import { DateTimeHelper, log } from '@app/helpers'
import { CrudAction } from '@app/models'
import { DatabaseService } from '@app/services'
import { NotificationProfileTableComponent } from './notification-profile-table/profile-table.component'

type NotificationsComponentViewOptions = 'PROFILE' | 'PAUSED'

class NotificationsComponentViewModel {
	helpVisible = false
	noDataTipVisible = false
	currentView: NotificationsComponentViewOptions = 'PROFILE'
}

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    standalone: false
})
export class NotificationsComponent implements OnInit {
	CrudAction = CrudAction
	vm: NotificationsComponentViewModel = new NotificationsComponentViewModel()

	isDataLoaded = false

	@ViewChild('profileTable', { static: false }) profileTable: NotificationProfileTableComponent

	constructor(private dbSrvc: DatabaseService) {
		this.loadData()
	}

	get showSectionControls(): boolean {
		return true
	}
	get showNoDataTip(): boolean {
		return false
	}
	get profileCount(): number {
		return this.dbSrvc.npSrvc.getProfiles().length
	}
	get pausedCount(): number {
		return this.dbSrvc.vacSrvc.getVacations().length
	}
	get lastUpdated(): string {
		if (this.vm.currentView === 'PROFILE') {
			const date = this.dbSrvc.npSrvc.lastUpdated
			return `Updated ${DateTimeHelper.formatTimeFromDate(date)}`
		} else {
			const date = this.dbSrvc.vacSrvc.lastUpdated
			return `Updated ${DateTimeHelper.formatTimeFromDate(date)}`
		}
	}

	ngOnInit(): void {}

	public loadData() {
		log('Load Data')
		this.dbSrvc.bulkRead(['job', 'notification_profile', 'vacation']).then((loadResult) => (this.isDataLoaded = true))
	}

	public canPerformAction(action: CrudAction) {
		return true
	}

	public toggleTooltips(): boolean {
		this.vm.helpVisible = !this.vm.helpVisible
		this.updateFixedHeader()
		return false
	}

	public togglePrefsPanel(): void {
		this.profileTable.sectionPrefsDialogManager.isDialogVisible = true
	}

	public toggleProfiles() {
		log('Toggle Profiles')
		this.vm.currentView = 'PROFILE'
	}

	public togglePaused() {
		log('Toggle Paused')
		this.vm.currentView = 'PAUSED'
	}

	public createRecord() {
		log('Create Record')
		if (this.vm.currentView === 'PROFILE') {
			this.profileTable.createRecord()
		} else {
			// this.pausedTable.createRecord()
		}
	}

	private updateFixedHeader() {
		setTimeout(() => {
			const table: any = $('#notificationsTable').DataTable()
			table?.fixedHeader.adjust()
		}, 100)
	}
}
