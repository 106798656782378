<div class="option-dropdown-wrap noprint">
	<div
		[class.option-dropdown-mobile]="devDetect.isMobile()"
		[class.d-flex]="!devDetect.isMobile()"
		[class.justify-content-between]="!devDetect.isMobile()"
	>
		<div>
			<div class="header-wrap">
				<div class="header-block">
					<!-- (onPanelHide)="updateJobs()" -->
					<div class="header-block-menu">
						<div>
							<i class="fas fa-times-circle header-menu-clear" (click)="clearMenu('JOB')"></i>
						</div>
						<div class="header-block-select">
							<p-select
								appScrollLockSelect
								[appendTo]="devDetect.isMobile() ? null : 'body'"
								placeholder="All Jobs"
								[filter]="true"
								filterBy="label"
								[resetFilterOnHide]="true"
								[autofocusFilter]="devDetect.isDesktop()"
								[options]="jobOptions"
								[(ngModel)]="viewManager.selectedJobId"
							>
							</p-select>
						</div>
					</div>
				</div>
				<div class="header-block">
					<!-- (onPanelHide)="updateEmployees()" -->
					<div class="header-block-menu">
						<div>
							<i class="fas fa-times-circle header-menu-clear" (click)="clearMenu('EMP')"></i>
						</div>
						<div class="header-block-select">
							<p-select
								appScrollLockSelect
								[appendTo]="devDetect.isMobile() ? null : 'body'"
								placeholder="All Employees"
								[filter]="true"
								filterBy="label"
								[resetFilterOnHide]="true"
								[autofocusFilter]="devDetect.isDesktop()"
								[options]="empOptions"
								[(ngModel)]="viewManager.selectedEmpId"
							>
							</p-select>
						</div>
					</div>
				</div>
				<button class="btn btn-sm btn-success header-update-btn" (click)="filterUpdated.next(true)">
					<span>Update</span>
				</button>
			</div>
			<!-- </div> -->
		</div>

		<div *ngIf="!devDetect.isMobile()">
			<div *ngIf="!tzSelectAction.isVisible" class="calendar-date">
				{{ filterBarLabel }}
				<div class="tz-btn table-tag has-pointer" style="font-size: 0.8rem" (click)="openTzSelectDialog()">
					<span>{{ currentTzAbrev }}</span>
					<!-- <i *ngIf="isTimezoneLocked" class="far fa-lock" style="margin-left: 4px"></i> -->
				</div>
			</div>
			<div *ngIf="tzSelectAction.isVisible" class="tz-dd-block d-flex justify-content-between">
				<div class="tz-close-btn">
					<!-- <i *ngIf="isTimezoneLocked" class="far fa-unlock" style="margin-right: 8px" (click)="updateTimezone(null)"></i> -->
					<i class="fa fa-times-circle-o" (click)="tzSelectAction.isVisible = false"></i>
				</div>

				<!-- </div> -->
				<div style="width: 280px">
					<p-select
						appScrollLockSelect
						appendTo="body"
						styleClass="shift-tz-dd"
						scrollHeight="280px"
						placeholder="Automatic"
						[options]="tzOptions"
						[ngModel]="viewManager.timezone"
						(onChange)="updateTimezone($event.value)"
					></p-select>
				</div>
			</div>
		</div>
	</div>
</div>
