import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { DateTimeHelper, log } from '@app/helpers'
import { RecordTag, RecordTagContainer, DatabaseTableName } from '@app/models'
import { CoreService } from '@app/services'
import _ from 'lodash'
import moment from 'moment-timezone'
import { AutoComplete } from 'primeng/autocomplete'

@Component({
    selector: 'app-form-tags',
    templateUrl: './form-tags.component.html',
    styleUrls: ['./form-tags.component.scss'],
    standalone: false
})
export class FormTagsComponent implements OnInit {
	@Input() tagsJson: string = null
	@Input() tagOptionsList: Array<string> = [] // Generated from table records
	@Output() tagsJsonChange = new EventEmitter<string>()

	newTagLabel: string = ''
	tags: Array<RecordTag> = []

	table: DatabaseTableName
	tagOptions: Array<string> = [] // filtered autocomplete dropdown options

	selectedTag: RecordTag
	selectedTagLabel: string
	selectedTagExpiration: Date

	datePickerFormat = DateTimeHelper.format12Hour ? `MMM Do, YYYY @ h:mm a` : `MMM Do, YYYY @ HH:mm`

	@ViewChild('tagAutoComplete') tagAutoComplete: AutoComplete

	constructor(private coreSrvc: CoreService) {
		log('Tag Options List', this.tagOptionsList)
	}

	get trimmedTagInput(): string {
		return (this.newTagLabel ?? '').trim().toLowerCase()
	}

	get startAtPickerDefault(): Date {
		return moment().startOf('day').toDate()
	}

	public ngOnInit(): void {
		log('Incoming', this.tagsJson)
		const tagContainer = new RecordTagContainer(this.tagsJson)
		this.tags = _.orderBy(tagContainer.tags, 'label')
	}

	public searchTags(event) {
		const text: string = this.trimmedTagInput
		if (!text) {
			this.tagOptions = [...this.tagOptionsList]
			return
		}
		this.tagOptions = this.tagOptionsList.filter((opt) => opt.toLowerCase().includes(text))
	}

	public autoSelect(event) {
		log('autoSelect', event)
		this.newTagLabel = event.value
		this.addTag()
	}

	public clearInputField() {
		this.newTagLabel = ''
		this.tagAutoComplete.clear()

		const elm = $('.p-autocomplete-input')
		log('Element', elm)
		setTimeout(() => {
			elm.trigger('blur')
		}, 500)
	}

	public onEnterKeyPressed(event) {
		log('onEnterKeyPressed', event.target.value)
		this.addTag()
	}

	private doesTagExistForLabel(label: string): boolean {
		return !!this.tags.find((tag) => tag.label === label)
	}

	public addTag(): boolean {
		if (this.doesTagExistForLabel(this.trimmedTagInput)) {
			this.clearInputField()
			return true
		}

		const label = this.trimmedTagInput ?? ''
		if (label.includes('<')) {
			this.coreSrvc.notifySrvc.notify('error', 'Error', 'Tags cannot contain the < character.', 4)
			return false
		}
		if (label) {
			const tag = new RecordTag({ label: label, ts: moment().toISOString() })
			this.tags.push(tag)
			this.newTagLabel = ''
		}
		this.processChange()
		this.clearInputField()
		return true
	}

	public removeTag(tag: RecordTag) {
		this.tags = this.tags.filter((rec) => rec !== tag)
		if (this.selectedTag?.label === tag.label) this.selectedTag = null
		this.processChange()
	}

	private processChange() {
		this.tags.forEach((tag) => {
			tag.label = (tag.label ?? '').toLowerCase()
		})
		this.tags = _.orderBy(this.tags, 'label')
		const tagContainer = { tags: this.tags }
		const output = this.tags.length > 0 ? JSON.stringify(tagContainer) : null
		this.tagsJsonChange.next(output)
	}

	public modifyProperties(tag: RecordTag) {
		this.selectedTag = tag
		this.selectedTagExpiration = tag.expire ? moment(tag.expire).toDate() : null
		this.selectedTagLabel = tag.label
		this.coreSrvc.displaySrvc.bringIntoView('tag-properties-block')
	}

	public cancelModification() {
		log('cancelModification')
		this.selectedTag = null
	}

	public performModification() {
		const tag = this.tags.find((tag) => tag.label === this.selectedTag.label)
		tag.label = this.selectedTagLabel
		tag.expire = this.selectedTagExpiration ? moment(this.selectedTagExpiration).toISOString() : null
		this.selectedTag = null
		this.selectedTagExpiration = null
		this.processChange()
	}

	public confirmSetDate(event) {
		this.selectedTagExpiration = moment(event).toDate()
	}

	public clearSelectedTagExpire() {
		this.selectedTagExpiration = null
	}

	public formatStandardTime(tag: RecordTag) {
		return DateTimeHelper.standardTimeFromDateString(tag.ts)
	}
	public formatStandardDate(tag: RecordTag) {
		return DateTimeHelper.mediumDateFromDateString(tag.ts)
	}
}
