import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { ActiveCallLastStatus, ActiveCallLegRecord, UserRecord } from '@app/models'
import { CoreService } from '@app/services'
import { DateTimeHelper, log } from '@app/helpers'

import { Subscription } from 'rxjs'
import moment from 'moment-timezone'
import _ from 'lodash'

@Component({
    selector: 'app-dispatch-active-call-manager',
    templateUrl: './dispatch-active-call-manager.component.html',
    styleUrls: ['./dispatch-active-call-manager.component.scss'],
    standalone: false
})
export class DispatchActiveCallManagerComponent implements OnInit, OnDestroy {
	public isDataLoaded = false
	public list: Array<ActiveCallCard> = []

	private pollInterval = 4000 // Milliseconds
	private pollIntervalTimer: NodeJS.Timeout

	private uiUpdateInterval = 1000
	private uiPudateIntervalTimer: NodeJS.Timeout

	public myUser: UserRecord = null

	private subs = new Subscription()

	constructor(
		private coreSrvc: CoreService,
		private router: Router,
	) {
		this.myUser = this.coreSrvc.dbSrvc.settingSrvc.getMyUser()
		this.subs.add(this.coreSrvc.dbSrvc.activeCallSrvc.activeCallListChange.subscribe((list) => this.handleListChanged(list)))
	}

	public ngOnInit() {
		this.coreSrvc.workSrvc.blockWorking()

		// Setup display update interval
		this.uiPudateIntervalTimer = setInterval(() => {
			this.list.forEach((li) => li.updateCallDuration())
		}, this.uiUpdateInterval)
		this.coreSrvc.dbSrvc.activeCallSrvc.fetchActiveCalls()

		this.startPolling()
	}

	public ngOnDestroy() {
		clearInterval(this.pollIntervalTimer)
		clearInterval(this.uiPudateIntervalTimer)
		this.coreSrvc.workSrvc.unblockWorking()
		this.subs.unsubscribe()
	}

	private handleListChanged(list: Array<ActiveCallLegRecord>) {
		log('Calls have been fetched')
		this.isDataLoaded = true
		this.coreSrvc.zone.run(() => {
			// this.list = [mockLeg]
			this.list = _.orderBy(list, 'id', 'asc').map((li) => new ActiveCallCard(li))
		})
	}

	private startPolling() {
		this.pollIntervalTimer = setInterval(() => {
			this.coreSrvc.dbSrvc.activeCallSrvc.fetchActiveCalls()
		}, this.pollInterval)
	}

	public deleteBtnClicked(card: ActiveCallCard) {
		log('Delete', card)
		if (!card.acr.bridged) {
			this.operationNotPermittedAtThisTime()
			return
		}
		this.coreSrvc.dbSrvc.deleteRecord('active_call_legs', card.acr.id)
	}

	public toggleMute(card: ActiveCallCard) {
		if (!card.acr.bridged) {
			this.operationNotPermittedAtThisTime()
			return
		}
		card.acr.muted = !card.acr.muted
		this.coreSrvc.dbSrvc.updateRecord('active_call_legs', card.acr)
	}

	public viewTimeEntry(card: ActiveCallCard) {
		if (!card.hasTimeEntryLink) {
			this.operationNotPermittedAtThisTime()
			return
		}
		const transLink = card.timeEntryLink()
		this.coreSrvc.dbSrvc.clearServiceData()
		this.router.navigate([transLink])
	}

	public formatStatus(status: any) {
		return ActiveCallLastStatus.lookupType(status).toLowerCase()
	}

	public operationNotPermittedAtThisTime() {
		this.coreSrvc.notifySrvc.notify('info', 'Not Permitted', 'Operation not permitted at this time.', 3)
	}
}

class ActiveCallCard {
	acr: ActiveCallLegRecord
	callDuration = ''

	constructor(record: ActiveCallLegRecord) {
		this.acr = record
		this.updateCallDuration()
	}

	get hasTimeEntryLink(): boolean {
		return !!this.acr?.company_id && !!this.acr?.transaction_id
	}

	updateCallDuration() {
		const nowMom = moment().startOf('second')
		const startMom = moment(this.acr.created).startOf('second')
		const duration = Math.abs(moment.duration(nowMom.diff(startMom)).asSeconds() * 1000)

		this.callDuration = DateTimeHelper.formatDurationInHoursAndMinutes('HH:mm:ss', duration)
	}

	timeEntryLink(): string {
		const transId = this.acr.transaction_id
		const companyId = this.acr.company_id
		if (companyId && transId) {
			return `/redirect/record/${transId}/transactions/${companyId}`
		} else {
			return null
		}
	}
}

const mocks = [
	{
		id: 101,
		company_id: 1433,
		created: '2023-12-30 14:57:54.86539Z',
		click_to_call_log_id: 869,
		user_id: 1784,
		phone_e164: '+16128024949',
		display_name: 'Daryl Leibel',
		bridged: 0,
		channel: 'dude',
		muted: 0,
		hostname: 'IVREZC01',
		call_source: 'USERPHONE',
		last_status: 'CONNECTED',
		last_update: '2023-12-30 14:57:54.86539',
	},
	{
		id: 102,
		company_id: 1433,
		created: '2023-12-30 14:58:49.946483Z',
		click_to_call_log_id: 869,
		user_id: null,
		phone_e164: '+16122260725',
		display_name: 'Steve Jensen',
		bridged: 0,
		channel: 'steve',
		muted: 0,
		hostname: 'IVREZC01',
		call_source: 'EMPLOYEEPHONE',
		last_status: 'CONNECTED',
		last_update: '2023-12-30 14:58:49.946483',
	},
]

const mockLeg = new ActiveCallCard({
	id: 716,
	company_id: 1433,
	created: '2024-11-25 22:20:51.55206',
	click_to_call_log_id: 1771,
	user_id: 1784,
	phone_e164: '+16128024949',
	display_name: 'Employee Name',
	bridged: true,
	channel: 'channel',
	muted: false,
	hostname: 'hostname',
	call_source: 'EMPLOYEEPHONE',
	last_update: '2024-11-25 22:20:51.55206',
	last_status: 'CONNECTED',
	transaction_id: 925826,
} as ActiveCallLegRecord)
