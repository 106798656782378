import { DateTimeHelper } from '@app/helpers/datetime'

export class WIWSync {
	id: number
	company_id: number
	realm_id: string
	employee_sync: string // Timestamp without timezone
	position_sync: string // Timestamp without timezone
	schedule_sync: string // Timestamp without timezone
	location_sync: string // Timestamp without timezone
	transaction_log_lock: string // Timestamp without timezone
	created: string // Timestamp without timezone
	employee_sync_errors = 0
	position_sync_errors = 0
	schedule_sync_errors = 0
	location_sync_errors = 0
	desktop_sync = false
	desktop_version: string
	desktop_release: string
	desktop_company_name: string
	desktop_create_time: string
	desktop_payroll_reg: string
	desktop_payroll_ot: string
	desktop_payroll_hol: string
	desktop_payroll_othol: string
	updated: string // Timestamp without timezone
	o2_access_token: string
	o2_access_expires: string // Timestamp without timezone
	o2_refresh_token: string
	o2_refresh_expires: string // Timestamp without timezone

	constructor(record?: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
		this.employee_sync = DateTimeHelper.stripUtcTag(this.employee_sync)
		this.position_sync = DateTimeHelper.stripUtcTag(this.position_sync)
		this.schedule_sync = DateTimeHelper.stripUtcTag(this.schedule_sync)
		this.location_sync = DateTimeHelper.stripUtcTag(this.location_sync)
		this.transaction_log_lock = DateTimeHelper.stripUtcTag(this.transaction_log_lock)
		this.created = DateTimeHelper.stripUtcTag(this.created)
		this.updated = DateTimeHelper.stripUtcTag(this.updated)
	}

	clone(): WIWSync {
		// const json = JSON.stringify(this)
		// const parsed = JSON.parse(json)
		return new WIWSync(this)
	}
}
