import { Component, OnInit, AfterViewInit, EventEmitter, Output, OnDestroy } from '@angular/core'
import { Location } from '@angular/common'
import { CoreService, DatabaseService } from '@app/services'
import { EmpScoreWeight, DialogControlEvent } from '@app/models'
import { log } from '@app/helpers'
import { Subscription } from 'rxjs'

@Component({
	selector: 'app-score-edit',
	templateUrl: './edit.component.html',
	styleUrls: ['./edit.component.scss'],
	standalone: false,
})
export class ScoreEditComponent implements OnInit, AfterViewInit, OnDestroy {
	@Output() scoreSaved = new EventEmitter<boolean>()
	@Output() actionCancelled = new EventEmitter<boolean>()

	isUpdating = false
	scoreWeight = new EmpScoreWeight()
	currentWeight = null
	isConfigValid = true
	// showSetValueDialog = false

	private subs = new Subscription()
	constructor(
		private location: Location,
		private coreSrvc: CoreService,
	) {
		this.setupWeights()
		this.subs.add(this.coreSrvc.eventSrvc.dialogControlEvents.subscribe((event) => this.processControlEvents(event)))
		this.isFormValid()
	}

	processControlEvents(event: DialogControlEvent) {
		const callerId = event.callerId
		const direction = event.direction
		if (callerId === 'empScore' && direction === 'toContent') {
			const eventName = event.eventName
			if (eventName === 'saveBtnClicked') {
				this.saveScoreBtnClicked()
			}
		}
	}

	isFormValid(): boolean {
		const isValid = this.totalWeight === 100
		const saveBtnState = isValid ? 'enableSaveBtn' : 'disableSaveBtn'
		const event = new DialogControlEvent('empScore', 'toDialog', saveBtnState, null)
		this.coreSrvc.eventSrvc.dialogNotification(event)
		return isValid
	}

	get totalWeight(): number {
		return this.scoreWeight.totalWeight()
	}

	ngOnInit() {}

	ngAfterViewInit() {
		scrollTo(0, 0)
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	setupWeights() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const scoreConfig = company.emp_score_config
		this.isConfigValid = EmpScoreWeight.isConfigValid(scoreConfig)
		this.scoreWeight.setState(scoreConfig)
	}

	saveScoreBtnClicked() {
		// Guard against double submission
		if (this.isUpdating) {
			return
		}

		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const newScoreConfig = JSON.stringify(this.scoreWeight)
		company.emp_score_config = newScoreConfig
		this.isUpdating = true
		this.coreSrvc.dbSrvc.updateRecord('company', company).then((result) => {
			log('Result from save', result)
			this.scoreSaved.emit(true)
		})
	}

	cancelBtnClicked() {
		this.actionCancelled.emit(true)
	}

	resetScore() {
		this.scoreWeight = new EmpScoreWeight()
		this.isFormValid()
	}

	decrement(weight: string): boolean {
		const value = this.scoreWeight[weight]
		if (value > 0) {
			this.scoreWeight[weight]--
		}
		this.isFormValid()
		return false
	}

	increment(weight): boolean {
		const value = this.scoreWeight[weight]
		if (value < 100) {
			this.scoreWeight[weight]++
		}
		this.isFormValid()
		return false
	}
}
