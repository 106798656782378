<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<form class="edit-form-block" novalidate [formGroup]="editForm" (keydown.enter)="$event.preventDefault()" autocomplete="off">
		<div *ngIf="!isEditingTranslations && !isAttachingFiles">
			<div class="priority-block">
				<div class="switch">
					<p-toggleswitch formControlName="enabled" binary="true"></p-toggleswitch>
				</div>
				<div class="priority-label">Priority</div>
				<div class="order">
					<select class="form-select form-select-sm" formControlName="order">
						<option value="1">1</option>
						<option value="2">2</option>
						<option value="3">3</option>
						<option value="4">4</option>
						<option value="5">5</option>
					</select>
				</div>
			</div>

			<div [class.input-required]="!editForm.value.name">
				<div *ngIf="!editForm.value.name" class="float-right">(required)</div>
				<div class="edit-label">Name</div>
				<input class="form-control" formControlName="name" />
			</div>

			<!-- Schedule Dates -->

			<div style="margin-top: 20px">
				<div class="options-block" [class.input-required]="!editForm.get('startDate').value">
					<div>
						<div *ngIf="!editForm.get('startDate').value" class="float-right">(required)</div>
						<div class="options-title">Start Date</div>
					</div>
					<app-date-time-picker
						[currentDate]="editForm.value.startDate"
						[pickerType]="'DATE'"
						[pickerMode]="'dialog'"
						[pickerLabel]="'Start Date'"
						[hour12Timer]="is12Hour"
						[format]="'MMM Do, YYYY'"
						(pickerDidClose)="setDate('startDate', $event)"
					>
					</app-date-time-picker>
				</div>

				<div class="options-block" [class.input-required]="!editForm.get('endDate').value">
					<div>
						<div *ngIf="!editForm.get('endDate').value" class="float-right">(required)</div>
						<div class="options-title">End Date</div>
					</div>
					<app-date-time-picker
						[currentDate]="editForm.value.endDate"
						[pickerType]="'DATE'"
						[pickerMode]="'dialog'"
						[pickerLabel]="'End Date'"
						[hour12Timer]="is12Hour"
						[format]="'MMM Do, YYYY'"
						(pickerDidClose)="setDate('endDate', $event)"
					>
					</app-date-time-picker>
				</div>
			</div>

			<div *ngIf="showDateError" class="alert alert-danger" style="margin-top: 25px">Start date must be before end date</div>

			<div class="message-block" [class.input-required]="!editForm.value.bodyEN">
				<div>
					<div *ngIf="!editForm.value.bodyEN" class="float-right">(required)</div>
					<div class="edit-label">Message<i class="far fa-info-circle tts-info-btn" (click)="showHelp('message')"></i></div>
					<textarea class="form-control msg-textarea" formControlName="bodyEN"></textarea>
				</div>
			</div>

			<!-- Filter Blocks -->

			<div class="options-block filter-block">
				<div class="options-title-inline">Target Employees</div>
				<!-- <span *ngIf="!isMultiEmployeeChoiceValid()" class="input-required float-right">(required)</span> -->
				<app-multi-select-details
					[type]="'EMP'"
					defaultLabel="Select Employees"
					selectedItemsLabel="Employees Selected: {0}"
					[options]="multiEmployeeDropdown"
					[model]="editForm.get('filter_employee').value"
					(modelChange)="editForm.get('filter_employee').setValue($event)"
				>
				</app-multi-select-details>
			</div>

			<div class="options-block filter-block">
				<div class="options-title-inline">Target Job Sites</div>
				<!-- <span *ngIf="!isMultiEmployeeChoiceValid()" class="input-required float-right">(required)</span> -->
				<app-multi-select-details
					[type]="'SITE'"
					defaultLabel="Select Job Sites"
					selectedItemsLabel="Job Sites Selected: {0}"
					[options]="multiJobSiteDropdown"
					[model]="editForm.get('filter_location').value"
					(modelChange)="editForm.get('filter_location').setValue($event)"
				>
				</app-multi-select-details>
			</div>

			<!-- Options Block -->

			<div>
				<div class="edit-label blue">Announcement Options</div>
				<div class="checkbox-list">
					<div class="ann-label mb-2">
						<p-checkbox formControlName="alwaysPlay" [binary]="true"></p-checkbox>
						<div class="png-check-label" (click)="toggleCheckbox('alwaysPlay')">Always play</div>
						<i class="far fa-info-circle tts-info-btn" (click)="showHelp('alwaysPlay')"></i>
					</div>

					<div *ngIf="!editForm.get('alwaysPlay').value" class="ann-label mb-2">
						<p-checkbox formControlName="acknowledgement" [binary]="true"></p-checkbox>
						<div class="png-check-label" (click)="toggleCheckbox('acknowledgement')">Require acknowledgement</div>
						<i class="far fa-info-circle tts-info-btn" (click)="showHelp('acknowledgement')"></i>
					</div>

					<div *ngIf="false" class="ann-label mb-2">
						<p-checkbox formControlName="multiPlay" [binary]="true"></p-checkbox>
						<div class="png-check-label" (click)="toggleCheckbox('multiPlay')">Continue to next</div>
						<i class="far fa-info-circle tts-info-btn" (click)="showHelp('continue')"></i>
					</div>
				</div>
			</div>

			<div class="action-btn-block">
				<app-form-expander [style]="{ 'margin-top': '25px' }" [label]="'Advanced Options'" [isExpanded]="expandAdvancedOptions">
					<div class="tts-hl-2"><strong>Supported File Types for Attachments</strong></div>
					<div class="type-note">HTML, PDF, TXT, JPG / JPEG, PNG, GIF</div>
					<div style="margin-top: 20px">
						<app-file-uploader [fileUploadManager]="fileUploadManager"></app-file-uploader>
					</div>
				</app-form-expander>

				<!-- File Upload Component -->
				<!-- <div class="tts-hl-2"><strong>Supported File Types for Attachments</strong></div>
				<div class="type-note">HTML, PDF, TXT, JPG / JPEG, PNG, GIF</div>
				<div style="margin-top: 20px">
					<app-file-uploader [fileUploadManager]="fileUploadManager"></app-file-uploader>
				</div> -->
			</div>
		</div>
		<!-- Main form block end -->

		<!-- Edit block begin -->
		<div *ngIf="isEditingTranslations">
			<div class="message-block">
				<div>
					<div class="edit-label">Spanish</div>
					<textarea class="msg-textarea" formControlName="bodyES"></textarea>
				</div>
			</div>
			<div class="message-block">
				<div>
					<div class="edit-label">French</div>
					<textarea class="msg-textarea" formControlName="bodyFR"></textarea>
				</div>
			</div>
			<div class="message-block">
				<div>
					<div class="edit-label">Chinese</div>
					<textarea class="msg-textarea" formControlName="bodyCN"></textarea>
				</div>
			</div>
		</div>

		<!-- NEED TO KEEP TO SYNC EVENTS FOR DIALOG -->
		<div *ngIf="isFormValid()"></div>
	</form>
</div>
