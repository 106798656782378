import { DatabaseService } from '@app/services/backend/database.service'
import { Injectable } from '@angular/core'

import { JobSiteRecord, SectionListCountManager } from '@app/models'

import { log } from '@app/helpers'
import _ from 'lodash'

import { SelectItem } from 'primeng/api'

@Injectable({
	providedIn: 'root',
})
export class JobSitesService {
	list: JobSiteRecord[] = []
	dataLoaded = false
	lastUpdated: Date

	listCountManager = new SectionListCountManager()

	constructor() {
		log('Creating JobSitesService')
	}

	getCache() {
		const cache = {}
		for (const item of this.list) {
			cache[item.id] = item
		}
		return cache
	}

	hasSyncLockRecords(): boolean {
		return this.list.filter((rec) => rec.sync_lock).length > 0
	}

	count(supId?: number): number {
		const length = this.getAllJobSites(supId).length
		return length > 0 ? length - 1 : 0
	}

	managedCount(supIds: Array<number>) {
		// const counts = supIds.map(id => this.count(id))
		// return _.sum(counts)
		return this.getAllManagedJobSites(supIds).length
		// return length > 0 ? length - 1 : 0
	}

	alertCount(): number {
		return 0
		// let count = 0
		// this.list.forEach(site => {
		// 	const description = site.description || ''
		// 	if (description.includes('AutoCreated')) {
		// 		count++
		// 	}
		// })
		// return count
	}

	clearData() {
		this.list = []
		this.dataLoaded = false
	}

	getJobSiteDropdown(dbSrvc: DatabaseService, isRestricted: boolean, isManager: boolean, includeIds: Array<number>): any[] {
		const supId = dbSrvc.settingSrvc.getMyUserId()
		const managedSupIds = dbSrvc.settingSrvc.getManagedUserIds()

		const sites = isManager && isRestricted ? this.getAllManagedJobSites(managedSupIds) : this.getJobSites(isRestricted ? supId : null)
		const includeList = includeIds.map((id) => this.getJobSiteById(id)).filter((site) => !!site)

		includeList.forEach((site) => {
			if (!sites.includes(site)) {
				sites.push(site)
			}
		})

		const dropdown = sites
			.map((js) => js.id)
			.map((id) => {
				return {
					label: this.getJobSiteById(id).description,
					value: id,
				}
			})

		const sortedDropdown = _.sortBy(dropdown, 'label')
		return sortedDropdown
	}

	getDistricts(included: Array<string>): Array<string> {
		const districts = this.getAllJobSites()
			.map((j) => j.district)
			.filter((j) => !!j)
		const fullList = [...included, ...districts]
		const uniqOptions = _.uniq(fullList)
		return _.orderBy(uniqOptions, 'label')
	}

	getDistrictDropdown(included: Array<string>): Array<SelectItem> {
		return this.getDistricts(included).map((d) => ({ label: d, value: d, data: null }))
	}

	getUnassignedJobSite() {
		return this.list.find((s) => s.company_default)
	}

	// getJobSitesForTable(supId?: number): Array<JobSiteRecord> { // DEPRECATED
	// 	const unsorted = this.list.filter(site => !site.company_default)
	// 	const filtered = supId ? unsorted.filter(js => js.supervisor === supId) : unsorted
	// 	return _.sortBy(filtered, 'description')
	// }

	getAllJobSites(supId?: number): Array<JobSiteRecord> {
		const unsorted = this.list.filter((site) => !site.company_default)
		const unAssignedSite = this.list.find((site) => site.company_default)
		// const filtered = supId ? unsorted.filter(js => js.supervisor === supId) : unsorted // MULTISUP
		const filtered = supId ? unsorted.filter((js) => js.supervisor_ids.includes(supId)) : unsorted
		const sorted = _.sortBy(filtered, 'description')
		sorted.unshift(unAssignedSite)
		return sorted
	}

	getAllManagedJobSites(supIds: Array<number>): Array<JobSiteRecord> {
		const jobSites = this.getAllJobSites()
		// return jobSites.filter(js => supIds.includes(js.supervisor)) // MULTISUP
		return jobSites.filter((js) => supIds.some((id) => js.supervisor_ids.indexOf(id) !== -1))
	}

	getJobSites(supId?: number): Array<JobSiteRecord> {
		const unsorted = this.list.filter((site) => !site.company_default)
		const filtered = supId ? unsorted.filter((js) => js.supervisor_ids.includes(supId)) : unsorted
		return _.sortBy(filtered, 'description')
	}

	getJobSiteIdsForSupId(supId: number): Array<number> {
		return this.getJobSites(supId).map((s) => s.id)
	}

	getJobSiteById(id: number) {
		const jobSite = this.list.find((js) => js.id === id)
		return jobSite ? jobSite : null
	}

	getJobSitesForClientId(id: number) {
		return this.list.filter((js) => js.client_id === id)
	}

	getJobSitesByGroupId(id: number): Array<JobSiteRecord> {
		return this.getAllJobSites().filter((js) => js.supervisor_group_id === id)
	}

	jobSiteNameForId(id: number): string {
		const jobSite = this.getJobSiteById(id)
		if (jobSite) {
			return jobSite.description
		}
		return 'Job Site Not Found'
	}

	jobSitesForEmpMobileNumber(number: string): JobSiteRecord[] {
		return this.list.filter((site) => {
			const numbers = site.phone_number_regex
			const re = new RegExp(number, 'g')
			return re.test(numbers)
		})
	}

	jobSitesForEmpMobileNumberE164(number: string): JobSiteRecord[] {
		const value = '\\' + number
		const sortedSites = _.sortBy(this.list, 'description')
		return sortedSites.filter((site) => {
			const numbers = site.phone_number_regex_e164
			const re = new RegExp(value, 'g')
			return re.test(numbers)
		})
	}

	jobSitesForLinkedE164Number(number: string): Array<JobSiteRecord> {
		return this.list.filter((js) => (js.phone_number_regex_e164 ?? '').includes(number))
	}

	// getTimezoneStringForJobSiteId(id: number) {
	// 	const jobSite = this.getJobSiteById(id)
	// 	return TimezoneHelper.timezoneStringForId(jobSite.timezone_id)
	// }

	makeJobSite(record: JobSiteRecord): JobSiteRecord {
		const jobSite = new JobSiteRecord(record)
		return jobSite
	}

	setJobSiteRecords(records: Array<JobSiteRecord>) {
		this.lastUpdated = new Date()
		this.list = records.map((c) => this.makeJobSite(c))
		this.dataLoaded = true
	}

	// updateJobSiteRecords(records: Array<IJobSite>) {
	// 	for (const newRecord of records) {
	// 		if (newRecord) {
	// 			const currentRecord = this.list.find(rec => rec.id === newRecord.id)
	// 			if (currentRecord) {
	// 				for (const attr in newRecord) {
	// 					if (newRecord.hasOwnProperty(attr)) {
	// 						currentRecord[attr] = newRecord[attr]
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateJobSiteRecords(records: Array<JobSiteRecord>) {
		const siteRecords = records.map((rec) => this.makeJobSite(rec))
		for (const newRecord of siteRecords) {
			const currentRecord = this.list.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.list.push(newRecord)
			}
		}
	}
}
