import moment from 'moment-timezone'
import { log } from './logger'
// import { datetime } from 'rrule'

export class DateTimeHelper {
	static format12Hour = false
	static imgIssuesDuration = 'PT15M'

	/**
	 * @param time: A time value in either HH:mm:ss or HH:mm
	 * @returns A normalized time string in format HH:mm:00
	 */

	static getTimeFormat(): string {
		return DateTimeHelper.format12Hour ? 'h:mm a' : 'HH:mm'
	}

	static normalizeTimestamp(ts: number) {
		if (ts > 1000000000000) {
			return Math.floor(ts / 1000) // Convert milliseconds to seconds by dividing by 1000
		} else {
			return ts
		}
	}

	static normalizeTime(time: string) {
		if (time && time.length === 5) {
			return time + ':00'
		} else {
			return time
		}
	}

	/**
	 * @param dateString: A UTC formatted date string with [UTC] appended
	 * @returns A UTC formatted date string without [UTC] appended
	 */

	static stripUtcTag(dateString: string): string {
		if (dateString) {
			return dateString.replace('[UTC]', '')
		} else {
			return null
		}
	}

	/**
	 * @param durFormat: A duration formatting string
	 * @param duration: A ISO 8601 formatted duration string
	 * @returns A properly formatted duration that doesn't day wrap hours
	 */

	static formatDuration(durFormat: any, duration: any): string {
		let output = durFormat
		const milliseconds = duration
		let totalMilliseconds = 0
		const replaceRegexps = {
			years: /Y(?!Y)/g,
			months: /M(?!M)/g,
			weeks: /W(?!W)/g,
			days: /D(?!D)/g,
			hours: /H(?!H)/g,
			minutes: /m(?!m)/g,
			seconds: /s(?!s)/g,
			milliseconds: /S(?!S)/g,
		}
		const matchRegexps = {
			years: /Y/g,
			months: /M/g,
			weeks: /W/g,
			days: /D/g,
			hours: /H/g,
			minutes: /m/g,
			seconds: /s/g,
			milliseconds: /S/g,
		}
		for (const r in replaceRegexps) {
			if (replaceRegexps[r].test(output)) {
				const as = 'as' + r.charAt(0).toUpperCase() + r.slice(1)
				const value = String(Math.floor(moment.duration(milliseconds - totalMilliseconds)[as]()))
				let replacements = output.match(matchRegexps[r]).length - value.length
				output = output.replace(replaceRegexps[r], value)

				while (replacements > 0 && replaceRegexps[r].test(output)) {
					output = output.replace(replaceRegexps[r], '0')
					replacements--
				}
				output = output.replace(matchRegexps[r], '')

				const temp = {}
				temp[r] = value
				totalMilliseconds += moment.duration(temp).asMilliseconds()
			}
		}
		return output
	}

	/**
	 * Convert ISO date string to Date while keeping local time from timezone
	 * @param dateString: An ISO date string (with potential [UTC] tag)
	 * @param timezone: Timezone you are converting from
	 * @returns A standard JavaScript Date object with local time from timezone
	 */
	static convertIsoDateStringToLocalDate(dateString: any, timezone: string): Date {
		if (!dateString) return null
		const isoDateString = DateTimeHelper.stripUtcTag(dateString)
		const mom = moment(isoDateString)
		const formatString = mom.tz(timezone).format('YYYY-MM-DDTHH:mm:ss')
		const newDateMom = moment(formatString)
		return newDateMom.isValid() ? newDateMom.toDate() : null
	}

	/**
	 * Convert Date object to timezone keeping local time
	 * @param date: A JavaScript Date object
	 * @param timezone: Timezone you are converting to
	 * @returns ISO date string for timezone using local time of date provided
	 */
	static convertDateToIsoStringKeepingLocalTime(date: Date, timezone: string): string {
		const dateString = moment(date).format('YYYY-MM-DD HH:mm')
		const dateMom = moment.tz(dateString, 'YYYY-MM-DD HH:mm', timezone)
		return dateMom.isValid() ? dateMom.toISOString() : null
	}

	static formatDurationInMinutes(duration: string) {
		return moment.duration(duration).asMinutes()
	}

	static formatDurationInHoursAndMinutes(durFormat: any, duration: any): string {
		return DateTimeHelper.formatDuration(durFormat, duration)
	}

	static formatDate(date: Date, format: string) {
		if (!date) {
			return ''
		}
		const dateMom = moment(date)
		if (dateMom.isValid()) {
			return dateMom.format(format)
		}
		return 'Invalid'
	}

	/**
	 * Return formatted time string in 12 or 24 hour format
	 * @param date: A date
	 */

	static formatTimeFromDate(date: Date): string {
		if (!date) {
			return ''
		}
		const use12Hours = DateTimeHelper.format12Hour
		const mom = moment(date)
		if (mom.isValid()) {
			return use12Hours ? mom.format('h:mm a') : mom.format('HH:mm')
		}
		return 'Invalid'
	}

	/**
	 * Return formatted time string in 12 or 24 hour format.
	 * @param time: A time interval string in HH:mm format
	 */

	static formatTimeFromInterval(time: string) {
		const formatter = DateTimeHelper.format12Hour ? 'h:mm a' : 'HH:mm'
		const mom = moment('2019-01-01').add(moment.duration(time))
		return mom.format(formatter)
	}

	static standardTimeFromDateString(dateString: string): string {
		const formatter = DateTimeHelper.format12Hour ? 'h:mm a' : 'HH:mm'
		if (dateString) {
			const date = this.stripUtcTag(dateString)
			const mom = moment(date)
			if (mom) {
				return mom.format(formatter)
			}
		}
		return ''
	}

	static standardDateTimeFromDateString(dateString: string): string {
		const formatter = DateTimeHelper.format12Hour ? 'ddd. MMM. Do, YYYY [@] h:mm a' : 'ddd. MMM. Do, YYYY [@] HH:mm'
		if (dateString) {
			const date = this.stripUtcTag(dateString)
			const mom = moment(date)
			if (mom) {
				return mom.format(formatter)
			}
		}
		return ''
	}

	static mediumDateFromDateString(dateString: string): string {
		if (dateString) {
			const date = this.stripUtcTag(dateString)
			return moment(date).format('ddd. MMM Do, YYYY')
		} else {
			return ''
		}
	}

	static mediumDateTimeFromDate(date: Date): string {
		const dateFormatter = 'ddd. MMM. Do, YYYY'
		const timeFormatter = DateTimeHelper.format12Hour ? 'h:mm a' : 'HH:mm'
		if (date) {
			const mom = moment(date)
			if (mom) {
				const resultDateString = mom.format(dateFormatter)
				const resultTimeString = mom.format(timeFormatter)
				return resultDateString + ' at ' + resultTimeString
			}
		}
		return ''
	}

	static mediumDateTimeFromDateString(dateString: string): string {
		const dateFormatter = 'ddd. MMM. Do, YYYY'
		const timeFormatter = DateTimeHelper.format12Hour ? 'h:mm a' : 'HH:mm'
		if (dateString) {
			const date = this.stripUtcTag(dateString)
			const mom = moment(date)
			if (mom) {
				const resultDateString = mom.format(dateFormatter)
				const resultTimeString = mom.format(timeFormatter)
				return resultDateString + ' at ' + resultTimeString
			}
		}
		return ''
	}

	static formatHrAndMinFromMinutes(min: number) {
		const hours = Math.floor(min / 60)
		const minutes = min % 60
		if (min > 60) {
			return `${hours} hr ${minutes} min`
		} else {
			return `${minutes} min`
		}
	}

	static convertJSDateToRRuleDate(date: Date | string): Date {
		const dateStr = moment(date).format('YYYY-MM-DDTHH:mm:ss')
		return moment(dateStr, 'YYYY-MM-DDTHH:mm:ss').utc(true).toDate()
	}

	// Original makeUtcDayViewDates
	static makeUtcDayViewDates(date: Date): { start: Date; end: Date } {
		const startStr = moment(date).format('YYYY-MM-DDT[00:00:00]')
		const startDate = moment(startStr, 'YYYY-MM-DDTHH:mm:ss').utc(true).toDate()
		const endStr = moment(date).format('YYYY-MM-DDT[23:59:59]')
		const endDate = moment(endStr, 'YYYY-MM-DDTHH:mm:ss').utc(true).toDate()

		return { start: startDate, end: endDate }
	}

	// May be an optimization for the makeUtcDayViewDates but needs to deal with offset issue I think
	// static makeDayViewDatesForScheduleList(date: Date): { start: Date; end: Date } {
	// 	// Clone the input date to avoid modifying the original date
	// 	const startDate = new Date(date)
	// 	const endDate = new Date(date)

	// 	// Set the time components for the start date to 00:00:00
	// 	startDate.setUTCHours(0, 0, 0, 0)

	// 	// Set the time components for the end date to 23:59:59
	// 	endDate.setUTCHours(23, 59, 59, 999)

	// 	return { start: startDate, end: endDate }
	// }

	static trimMsFromDateString(dateStr: string) {
		if (!dateStr) return ''
		const comps = dateStr.split('.')
		return comps[0] ? comps[0] + 'Z' : ''
	}

	static formatMinutestAsHrsAndMinutes(totalMinutes: number): string {
		const days = Math.floor(totalMinutes / 1440)
		const hours = Math.floor((totalMinutes % 1440) / 60)
		const minutes = totalMinutes % 60

		const parts = []
		if (days > 0) {
			parts.push(`${days} day${days > 1 ? 's' : ''}`)
		}
		if (hours > 0) {
			parts.push(`${hours} hour${hours > 1 ? 's' : ''}`)
		}
		if (minutes > 0) {
			parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`)
		}

		return parts.join(' ')
	}

	/**
	 * @param records: Array of records with ts property (unix timestamp)
	 * @param refTs: A reference timestamp
	 * @param duration: ISO formatted duration string
	 * @returns an array of booleans mapped from records
	 */
	static tsInRange(records: Array<ITimeStampable>, refTs: number): boolean[] {
		const duration = DateTimeHelper.imgIssuesDuration
		return records.map((record) => {
			const recordTs = record.ts > 1000000000000 ? Math.floor(record.ts / 1000) : record.ts
			const adjustedRefTs = refTs > 1000000000000 ? Math.floor(refTs / 1000) : refTs
			const durationInSec = moment.duration(duration).asSeconds()
			const diff = adjustedRefTs - recordTs
			const isInRange = diff < durationInSec
			return isInRange
		})
	}

	static getDateFromLogEntryMessage(msg: string): Date {
		// Regular expression to match the date and time part of the string
		const regex = /\[.*?(\d{1,2} \w{3} \d{4} \d{2}:\d{2}:\d{2} (?:[+-]\d{4}|GMT))\]/

		// Extract the date and time part of the string
		const match = msg.match(regex)

		if (match) {
			// Remove the surrounding brackets and parse the date
			const dateString = match[0].slice(1, -1) // Remove '[' and ']'

			// Create a new Date object from the date string
			// Note: JavaScript Date object expects the date string in a specific format
			// We might need to transform the date string if it's not in ISO format.
			// For now, we assume the date string is in a valid format.
			const date = new Date(dateString)

			// Check if the date is valid
			if (!isNaN(date.getTime())) {
				return date
			}
		}

		return null
	}
}

export interface ITimeStampable {
	ts: number // ts is a unix time stamp that may be in seconds or milliseconds
}

export class CompTimer {
	static timers = {}

	static start(id: string) {
		const startTime = moment().unix()
		CompTimer.timers[id] = startTime
		console.log(`Timer ${id} start`, startTime)
	}

	static end(id: string) {
		const startTime = CompTimer.timers[id]
		const endTime = moment().unix()
		if (startTime) {
			const dur = endTime - startTime
			console.log(`Timer ${id} END = ${dur} seconds`)
		} else {
			console.log('Timer duration not valid')
		}
		CompTimer.timers[id] = null
	}
}
