import { Component, OnInit } from '@angular/core'

@Component({
    selector: 'app-no-data-tip',
    templateUrl: './no-data-tip.component.html',
    styleUrls: ['./no-data-tip.component.scss'],
    standalone: false
})
export class NoDataTipComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
