<div class="container section-container">
	<div class="sec-head-wrap">
		<div *ngIf="showSectionControls" class="sec-act-ico-wrap">
			<i
				class="fas fa-plus sec-act-ico"
				[class.section-control-disabled]="!canPerformAction(CrudAction.create)"
				title="Add Profile"
				(click)="createRecord()"
			>
			</i>
			<i class="far fa-cog sec-act-ico" (click)="togglePrefsPanel()" title="Preferences"></i>
			<i class="far fa-question-circle sec-act-ico" (click)="toggleTooltips()" title="Show Help"></i>
		</div>

		<div class="sec-title-wrap">
			<i class="far fa-bell sec-head-ico" aria-hidden="true"></i>
			Notifications
			<!-- <app-sub-section-title [isVisible]="true" [subSectionTitle]="'Profiles'"></app-sub-section-title> -->
		</div>

		<div class="sub-head-wrap">
			<div class="sub-head-ico-wrap">
				<i class="far fa-refresh sub-head-ico" (click)="loadData()" title="Refresh Data"></i>
				<div class="updated-wrap">{{ lastUpdated }}</div>
			</div>
		</div>
	</div>

	<div *ngIf="vm.helpVisible" class="card card-body tooltip-block tooltip-info-text tooltip-section-header">
		<button type="button" class="close" aria-label="Close" (click)="toggleTooltips()">
			<span aria-hidden="true">&times;</span>
		</button>
		<app-no-data-tip *ngIf="vm.noDataTipVisible"></app-no-data-tip>
		<strong class="tooltip-step">Note:</strong> Notifications help content
		<br />
	</div>

	<div>
		<app-notification-profile-table #profileTable *ngIf="vm.currentView === 'PROFILE'"></app-notification-profile-table>
	</div>
</div>
