import { Component, EventEmitter, Input, Output } from '@angular/core'
import { DateTimeHelper, DisplayHelper, log } from '@app/helpers'
import { CallLogRecordWrap, ClickToCallRecord, DataAccessRequest, Global } from '@app/models'
import { CoreService } from '@app/services'

@Component({
    selector: 'app-comm-log-card',
    templateUrl: './comm-log-card.component.html',
    styleUrl: './comm-log-card.component.scss',
    standalone: false
})
export class CommLogCardComponent {
	isInternalUser = false

	@Input() card: CallLogRecordWrap
	@Input() timezone: string
	@Input() dateFormat: string = 'ddd MMM Do, YYYY'
	@Input() timeFormat: string = DateTimeHelper.format12Hour ? 'h:mm a z' : 'HH:mm z'

	@Output() placeCall = new EventEmitter<ClickToCallRecord>()
	@Output() startTextMsg = new EventEmitter<ClickToCallRecord>()

	constructor(private coreSrvc: CoreService) {
		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()
	}

	public setupEvent(item: CallLogRecordWrap, source: 'FROM' | 'TO', eventType: 'CALL' | 'TEXT') {
		const name = source === 'FROM' ? item.fromName : item.toName
		const description = source === 'FROM' ? item.fromSource : item.toSource
		const info = name ? name : description
		const number = source === 'FROM' ? item.fromE164 : item.toE164
		const c2cRec = new ClickToCallRecord('UNSPECIFIED', info, null, number, null)
		if (eventType === 'CALL') {
			this.placeCall.emit(c2cRec)
		} else {
			this.startTextMsg.emit(c2cRec)
		}
	}

	public transcriptFormatter(str: string, preview: boolean) {
		if (!str) return '< no transcript available >'

		let result = str

		if (!this.isInternalUser && result.includes('auth?') && Global.authLinkRegex.test(result)) {
			result = result.replace(Global.authLinkRegex, 'token=[protected]')
		}

		return preview ? result : DisplayHelper.truncateString(result, 150)
	}

	public audioFilePlayed() {
		const id = this.card.clr.id
		const record = this.card.clr
		if (record) {
			const data = { type: 'AUDIOACCESS', payload: { fileId: record.audio_file } }
			const dataJson = data ? JSON.stringify(data) : null
			const options = { resource: 'click_to_call_log', resource_id: id, data: dataJson }
			const request = new DataAccessRequest('none', 'track_event', options, null)
			this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
				log('sendPlayAudioEvent Result', result)
			})
		}
	}
}
