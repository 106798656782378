import { DateTimeHelper } from '@app/helpers'

export type BaseViewState = 'ACTIVE' | 'INACTIVE' | 'ALL'

export class BaseViewManager {
	version = 1
	currentView: BaseViewState
	dayViewDate: Date
	timezone: string
	isProcessing = false
}

export class BaseRecord {
	id: number
	description: string
	created: string
	updated: string

	constructor(record?: BaseRecord) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
		}
	}
}
