import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { UserPermissionsComponent } from '@app/admin/users/user-permissions/user-permissions.component'
import { FormHelper, log } from '@app/helpers'
import { DialogManager, HelpDialogMessage, UserRoleRecord } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { SelectItem } from 'primeng/api'

@Component({
    selector: 'app-user-role-edit',
    templateUrl: './role-edit.component.html',
    styleUrls: ['./role-edit.component.scss'],
    standalone: false
})
export class UserRoleEditComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
	role: UserRoleRecord
	isNew = false
	isUpdating = false
	roleForm: UntypedFormGroup

	supervisorOptions: Array<SelectItem> = []

	isInternalUser = false

	@Input() dialogManager = new DialogManager()
	@Input() recordId: number
	@Input() action = 'edit'

	@Output() recordUpdated = new EventEmitter<UserRoleRecord>()
	@ViewChild('userPermComponent', { static: false }) userPermComponent: UserPermissionsComponent

	constructor(
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
	) {
		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.getMyUser().role === 'INTERNAL'
	}

	get isViewingSystemRoleFromRolesTableAndNotInternalUser(): boolean {
		return !this.isInternalUser && !!this.role?.isSystemRole()
	}

	ngOnInit() {
		if (this.action === 'new') {
			this.isNew = true
			this.role = new UserRoleRecord()
		}
		if (this.action === 'clone') {
			this.isNew = true
			this.role = this.coreSrvc.dbSrvc.settingSrvc.getUserRoleById(this.recordId)
		}
		if (this.action === 'edit') {
			this.isNew = false
			this.role = this.coreSrvc.dbSrvc.settingSrvc.getUserRoleById(this.recordId)
		}

		log('Init Group Edit', this.role)

		this.setupForm()
		this.setupSupervisorsDropdown()
	}

	ngAfterViewInit() {}

	ngAfterContentInit() {
		this.setupDialogManager()
	}

	ngOnDestroy() {}

	viwFormErrors() {
		FormHelper.getFormValidationErrors(this.roleForm)
	}

	isFormValid(): boolean {
		const isFormValid = this.roleForm.valid
		return isFormValid // && isEmailValid && isPhoneValid
	}

	setupForm() {
		const role = this.role
		this.roleForm = this.fb.group({
			id: [role.id],
			description: [role.description, Validators.required],
			details: [role.details ?? ''],
		})

		if (this.action === 'clone') {
			// const name = this.role.description + ' (Copy)'
			this.roleForm.get('description').setValue(null)
			this.roleForm.get('details').setValue(null)
		}

		if (this.role.isSystemRole() && !this.isInternalUser) {
			this.roleForm.get('description').disable()
			this.roleForm.get('details').disable()
		}
	}

	setupDialogManager() {
		this.dialogManager.canSubmit = () => this.isFormValid()
		this.dialogManager.submitBtnAction = () => this.submit()
	}

	setupSupervisorsDropdown() {
		this.supervisorOptions = this.coreSrvc.dbSrvc.settingSrvc.getUsersDropdownData()
		log('Supervisor Options', this.supervisorOptions)
	}

	submit() {
		// Guard against double submission
		if (this.isUpdating) return
		FormHelper.trimOnlyWhitespace(this.roleForm)

		const record = this.makeUpdateRecord()
		log('Record to submit', record)
		if (record) {
			if (this.isNew) {
				log('Adding new organization', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.insertRecord('supervisor_role', record).then((success) => {
					if (success) {
						this.recordUpdated.emit(this.role)
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			} else {
				log('Updating record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.updateRecord('supervisor_role', record).then((success) => {
					if (success) {
						this.recordUpdated.emit(this.role)
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			}
		}
	}

	getPermissionsJsonString() {}

	makeUpdateRecord(): Object {
		const record = new UserRoleRecord(this.role)
		const form = this.roleForm.value

		record.description = form.description
		record.details = form.details

		// Copy access permissions
		const accessPermissions = this.userPermComponent.accessPermissions
		if (accessPermissions) {
			record.permissions_json = accessPermissions.getJsonString() ?? JSON.stringify([])
		} else {
			record.permissions_json = JSON.stringify([])
		}
		return record
	}

	// Misc Methods

	toggleCheckbox(prop: string) {
		log('prop', prop)
		const current = this.roleForm.get(prop).value
		this.roleForm.get(prop).setValue(!current)
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
