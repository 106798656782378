export interface ITransactionException {
	id: number
	company_id: number
	job_id: number
	location_id: number
	employee_id: number
	employee_name: string
	exported: null
	actual_end: string
	actual_start: string
	job_date: string
	job_description: string
	notes: string
	supervisor_flag: boolean
	time_worked: string
	timezone: string
	warned_flag: boolean
	not_scheduled: boolean
	called_from_cell: boolean
	caller_id_name: string
	caller_id_number: string
	caller_id_number_e164: string
	geo_end_address: string
	geo_end_distance: string
	geo_end_latitude: string
	geo_end_longitude: string
	geo_start_address: string
	geo_start_distance: string
	geo_start_latitude: string
	geo_start_longitude: string
	created: string
}

export interface ILocationException {
	id: number
	company_id: number
	employee_id: number
	location_id: number
	caller_id_name: string
	caller_id_number: string
	caller_id_number_e164: string
	approved: boolean
	rejected: boolean
	usage: number
	created: string
}

export class TransactionException implements ITransactionException {
	id: number
	company_id: number
	job_id: number
	location_id: number
	employee_id: number
	employee_name: string
	exported: null
	actual_end: string
	actual_start: string
	job_date: string
	job_description: string
	notes: string
	supervisor_flag: boolean
	time_worked: string
	timezone: string
	warned_flag: boolean
	not_scheduled: boolean
	called_from_cell: boolean
	caller_id_name: string
	caller_id_number: string
	caller_id_number_e164: string
	geo_end_address: string
	geo_end_distance: string
	geo_end_latitude: string
	geo_end_longitude: string
	geo_start_address: string
	geo_start_distance: string
	geo_start_latitude: string
	geo_start_longitude: string
	created: string
	rejected_location: boolean

	constructor() {}
}

export class LocationException implements ILocationException {
	id: number
	company_id: number
	employee_id: number
	location_id: number
	caller_id_name: string
	caller_id_number: string
	caller_id_number_e164: string
	approved: boolean
	rejected: boolean
	usage: number
	created: string

	constructor() {}
}
