<div class="urr-page-box">
	<div class="urr-report-wrapper">
		<app-user-report-header
			[config]="config"
			[logoUrl]="vm.logoUrl"
			[companyName]="vm.companyName"
			[address]="vm.address"
			[reportType]="vm.reportType"
			[transactionMetaData]="vm.reportData.transactionMetaData"
			[publicView]="publicView"
			(printPageBtnClicked)="printPage()"
			(closeBtnClicked)="closeBtnClicked.emit($event)"
		>
		</app-user-report-header>

		<div class="urr-block no-page-break">
			<div class="card urr-card">
				<div>
					<div class="urr-label urr-header-label nowrap">Submitted:</div>
					<div class="urr-header-value">{{ vm.reportDate }}</div>
				</div>
				<div *ngIf="vm.modifiedDate">
					<div class="urr-label urr-header-label nowrap">Modified:</div>
					<div class="urr-header-value">{{ vm.modifiedDate }}</div>
				</div>
			</div>
		</div>

		<div class="urr-block no-page-break">
			<div class="card urr-card">
				<div>
					<div>
						<div class="urr-label urr-header-label nowrap">Admin Notes</div>
						<div class="urr-header-value">{{ vm.adminNotes }}</div>
					</div>
					<div>
						<div class="urr-label urr-header-label nowrap">Summary</div>
						<div class="urr-header-value">{{ vm.summary }}</div>
					</div>
					<div *ngIf="vm.mileage">
						<div class="urr-label urr-header-label nowrap">Mileage</div>
						<div class="urr-header-value">{{ vm.mileage }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<app-user-report-footer [incident]="incident" [images]="vm.images"></app-user-report-footer>
</div>
