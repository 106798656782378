import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { DatabaseService } from '@app/services'

import { log } from '@app/helpers'

@Injectable({ providedIn: 'root' })
export class DataLoadedGuard {
	constructor(
		private router: Router,
		private dbSrvc: DatabaseService,
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		log('CAN ACTIVATE ROUTE', route)
		if (this.isDataLoaded() && !this.isAccountDisabled()) {
			return true
		} else {
			if (this.isDataLoaded() && this.isAccountDisabled()) {
				if (route.routeConfig.path === 'close-account') {
					return true
				}
				if (route.routeConfig.path === 'settings/billing') {
					return true
				}
				// If no override conditions met then return false
				return false
			}
			this.router.navigate(['/admin/dashboard'])
			return false
		}
	}

	isDataLoaded(): boolean {
		return (
			this.dbSrvc.empSrvc.dataLoaded &&
			this.dbSrvc.siteSrvc.dataLoaded &&
			this.dbSrvc.jobSrvc.dataLoaded &&
			this.dbSrvc.tranSrvc.dataLoaded &&
			this.dbSrvc.settingSrvc.companyDataLoaded &&
			this.dbSrvc.settingSrvc.usersDataLoaded
		)
	}

	isAccountDisabled(): boolean {
		const isAccountDisabled = this.dbSrvc.settingSrvc.isAccountDisabled()
		const isInternalUser = this.dbSrvc.settingSrvc.isActualUserInternal()
		return isAccountDisabled && !isInternalUser
	}
}
