import { Pipe, PipeTransform } from '@angular/core'
import { JobSitesService } from '@app/services/backend/job-sites.service'

@Pipe({
    name: 'displayJobSiteName',
    standalone: false
})
export class DisplayJobSiteNamePipe implements PipeTransform {
	constructor(private jobSitesService: JobSitesService) {}

	transform(value: any, args?: any): any {
		const jobSite = this.jobSitesService.getJobSiteById(value)
		if (jobSite) {
			return jobSite.description
		}
		return 'Job Site Not Found'
	}
}
