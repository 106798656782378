import { Component, OnInit, AfterViewInit, Input, ChangeDetectorRef } from '@angular/core'
import { IncidentReportData, ReportGlobals, UserReportConfiguration } from '@app/models'
import { log } from '@app/helpers/logger'

@Component({
    selector: 'app-incident-report',
    templateUrl: './incident-report.component.html',
    styleUrls: ['./incident-report.component.scss'],
    standalone: false
})
export class IncidentReportComponent implements OnInit, AfterViewInit {
	@Input() reportData = new IncidentReportData()
	@Input() reportConfig: UserReportConfiguration

	format12Hour = ReportGlobals.displayPrefs.format12Hour
	datePickerFormat = ReportGlobals.getDatePickerFormat('DATETIME')
	incidentTypeOptions = ReportGlobals.incidentTypeOptionsForDefault

	constructor(private cd: ChangeDetectorRef) {
		// Don't do anything in constructor, component needs data from above.
		// Don't make calls to any service. Place needed data in reportConfig
	}

	ngOnInit(): void {
		this.setupIndustry()
	}

	ngAfterViewInit(): void {}

	setupIndustry() {
		log('Incident Report Component User Report Config', this.reportConfig)
		const industry = this.reportConfig.industryType

		switch (industry) {
			case 'SECURITY':
				this.incidentTypeOptions = ReportGlobals.incidentTypeOptionsForSecurity
				break
			case 'HEALTHCARE':
				this.incidentTypeOptions = ReportGlobals.incidentTypeOptionsForHealthCare
				break
		}
	}

	skipInput(prop: any, field: string) {
		log('Skipping', field)
		prop[field] = `< skipped >`
		this.cd.detectChanges()
	}

	elementFocused(event: any) {
		log('Event', event)
		const id = event.target?.id
		// Remove any validation border colorering
		if (id) {
			$(`#${id}`).removeClass('ur-input-needs-info')
		}
	}
}
