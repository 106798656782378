import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { DateTimeHelper, log } from '@app/helpers'
import { ScheduleLogRecord } from '@app/models/schedule-log'
import { CoreService, DatabaseService } from '@app/services'
import { Subscription } from 'rxjs'
import _ from 'lodash'
import moment from 'moment-timezone'

@Component({
    selector: 'app-open-shifts-list',
    templateUrl: './open-shifts-list.component.html',
    styleUrls: ['./open-shifts-list.component.scss'],
    standalone: false
})
export class OpenShiftsListComponent implements OnInit, OnDestroy {
	list: Array<OpenShiftWrapper> = []

	subs = new Subscription()

	@Input() searchText = null
	@Output() gotoOpenShiftEvent = new EventEmitter<ScheduleLogRecord>()
	@Output() offerOpenShiftEvent = new EventEmitter<ScheduleLogRecord>() // DEPRECATED but not cleaned up - use event in service

	public openShiftAction = new OpenShiftAction()

	countdownIntervalTimer: NodeJS.Timeout

	constructor(private coreSrvc: CoreService) {
		this.subs.add(
			this.coreSrvc.dbSrvc.schedulerSrvc.scheduleRecurListUpdated.subscribe(() => {
				this.updateUI()
			}),
		)
		this.subs.add(
			this.coreSrvc.dbSrvc.schedulerSrvc.openShiftListUpdated.subscribe(() => {
				this.updateUI()
			}),
		)
		this.countdownIntervalTimer = setInterval(() => {
			this.list?.forEach((osw) => osw.updateCountdown())
		}, 5000)
	}

	get filteredList(): Array<OpenShiftWrapper> {
		const filterText = this.searchText?.toLowerCase() ?? ''
		if (filterText) {
			return this.list.filter((osw) => osw.filterText.includes(filterText))
		}
		return this.list
	}

	get useSideScroller(): boolean {
		return !this.coreSrvc.devDetect.isMobile() && this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.shift.userSideScrollForOpenShiftList
	}
	get restrictToRange(): boolean {
		return this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.shift.restrictOpenShiftsToRange
	}

	ngOnInit(): void {
		this.updateUI()
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
		clearInterval(this.countdownIntervalTimer)
	}

	updateUI() {
		this.coreSrvc.zone.run(() => {
			const list = this.coreSrvc.dbSrvc.schedulerSrvc.getOpenShifts().map((sl) => new OpenShiftWrapper(this.coreSrvc.dbSrvc, sl))
			const filteredList = this.restrictToRange ? this.filterShiftList(list) : list
			const sortedList = _.orderBy(filteredList, 'sortDate')
			this.list = sortedList // [...sortedList, ...sortedList, ...sortedList] // tripple for testing
		})
	}

	private filterShiftList(list: Array<OpenShiftWrapper>): Array<OpenShiftWrapper> {
		const filteredList = []
		const range = this.coreSrvc.dbSrvc.schedulerSrvc.shiftViewManager.getLoaderDates()
		if (range.start && range.end) {
			list.forEach((osw) => {
				const schedLog = osw.schedLog
				const timezone = schedLog.timezone
				const jobStart = moment(schedLog.job_start)
				// log('range/timezone/jobdate/start/end', range.start, range.end, timezone, schedLog.job_date, schedLog.job_start, schedLog.job_end)

				const startMom = moment.tz(range.start, timezone).startOf('day')
				const endMom = moment.tz(range.end, timezone).subtract(1, 'd').endOf('day') // range end has an extra day to ensure it gets all schedule log entries

				const isInRange = jobStart.isBetween(startMom, endMom, 'minute', '[]')
				if (isInRange) filteredList.push(osw)
			})
			return filteredList
		} else {
			return list
		}
	}

	public shiftCardClicked(shift: OpenShiftWrapper) {
		this.gotoOpenShiftEvent.next(shift.schedLog)
		// this.openShiftAction.shiftWrapper = shift
		// this.openShiftAction.showDialog = true
	}

	public gotoOpenShiftBtnClicked() {
		this.openShiftAction.showDialog = false
		setTimeout(() => {
			this.gotoOpenShiftEvent.next(this.openShiftAction.shiftWrapper.schedLog)
		}, 500)
	}

	public offerOpenShiftBtnClicked() {
		this.openShiftAction.showDialog = false
		setTimeout(() => {
			this.coreSrvc.dbSrvc.schedulerSrvc.offerOpenShiftEvent.next(this.openShiftAction.shiftWrapper.schedLog)
		}, 300)
	}
}

class OpenShiftAction {
	shiftWrapper: OpenShiftWrapper
	showDialog = false
}

class OpenShiftWrapper {
	schedLog: ScheduleLogRecord
	empName = ''
	jobName = ''
	jobDate = ''
	sortDate = ''
	countdown = ''

	isWarning = false
	isCritial = false

	filterText = ''

	constructor(dbSrvc: DatabaseService, schedLog: ScheduleLogRecord) {
		this.schedLog = schedLog
		this.empName = dbSrvc.empSrvc.getEmployeeById(schedLog.employee_id)?.getDisplayName()
		this.jobName = dbSrvc.jobSrvc.getJobById(schedLog.job_id)?.description
		this.jobDate = moment(schedLog.job_date).format('ddd, MMM Do YYYY')
		this.sortDate = schedLog.job_start

		this.filterText = `${this.empName}~${this.jobName}`.toLowerCase()
		// Update countdown to shift
		this.updateCountdown()
	}

	get hasShiftBeenOffered(): boolean {
		return this.schedLog.schedule_os_offer.length > 0
	}

	get hasShiftBeenAccepted(): boolean {
		return this.schedLog.schedule_os_offer[0]?.status === 'OPEN_NEEDS_APPROVAL'
	}

	get offerCount(): number {
		return this.schedLog.schedule_os_offer[0]?.employee_ids.length ?? 0
	}

	// Doesn't look like os_offer_entry data is being included in schedule_os_offers so this doesn't work to count
	get acceptCount(): number {
		return this.schedLog.schedule_os_offer[0]?.schedule_os_offer_entry?.filter((osoe) => osoe.accepted).length ?? 0
	}

	public updateCountdown() {
		const start = this.schedLog.job_start
		const minToGo = moment(start).diff(moment(), 'minutes')

		this.isWarning = minToGo < 1440 ? true : false
		this.isCritial = minToGo < 720 ? true : false

		if (minToGo < 0) {
			this.isCritial = true
			this.countdown = 'Deadline Missed'
			return
		}
		this.countdown = 'Starts in ' + DateTimeHelper.formatMinutestAsHrsAndMinutes(minToGo)
	}
}
