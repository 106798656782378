import { Injectable, EventEmitter } from '@angular/core'
import { EmpJobPayRateRecord, OrgJobPayRateRecord, PayRateViewManager } from '@app/models/pay-rate'

import { log } from '@app/helpers'
import _ from 'lodash'
import { SelectItem } from 'primeng/api/selectitem'
import { Global } from '@app/models/global'

@Injectable({
	providedIn: 'root',
})
export class PayRatesService {
	empJobDataLoaded = false
	empJobList: Array<EmpJobPayRateRecord> = []
	empJobListChange = new EventEmitter<Array<EmpJobPayRateRecord>>()
	empJobLastUpdated: Date

	orgJobDataLoaded = false
	orgJobList: Array<OrgJobPayRateRecord> = []
	orgJobListChange = new EventEmitter<Array<OrgJobPayRateRecord>>()
	orgJobLastUpdated: Date

	viewManager = new PayRateViewManager()

	constructor() {
		log('Creating PayRatesService')
	}

	clearData() {
		this.clearEmpJobData()
		this.clearOrgJobData()
	}

	clearEmpJobData() {
		this.empJobDataLoaded = false
		this.empJobList = []
		this.empJobLastUpdated = null
	}

	clearOrgJobData() {
		this.orgJobDataLoaded = false
		this.orgJobList = []
		this.orgJobLastUpdated = null
	}
	//// EmpJob Model

	getEmpJobPayRates(): Array<EmpJobPayRateRecord> {
		return this.empJobList
	}

	getEmpJobPayRateById(id: number): EmpJobPayRateRecord {
		return this.empJobList.find((rec) => rec.id === id)
	}

	getEmpJobPayRatesForEmpId(id: number): Array<EmpJobPayRateRecord> {
		return this.empJobList.filter((rec) => rec.employee_id === id)
	}

	getEmpJobPayRatesForJobId(id: number): Array<EmpJobPayRateRecord> {
		return this.empJobList.filter((rec) => rec.job_id === id)
	}

	removeLocalEmpJobPayRateRecord(recordId: number) {
		this.empJobList = this.empJobList.filter((rec) => rec.id !== recordId)
	}

	setEmpJobPayRateRecords(records: Array<EmpJobPayRateRecord>) {
		this.empJobLastUpdated = new Date()
		this.empJobList = records.map((rec) => new EmpJobPayRateRecord(rec))
		this.empJobDataLoaded = true
		this.empJobListChange.next(this.empJobList)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateEmpJobPayRateRecords(records: Array<EmpJobPayRateRecord>) {
		const newRecords = records.map((rec) => new EmpJobPayRateRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.empJobList.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.empJobList.push(newRecord)
			}
		}
		this.empJobLastUpdated = new Date()
		this.empJobListChange.next(this.empJobList)
	}

	//// OrgJob Model

	getOrgJobPayRates(): Array<OrgJobPayRateRecord> {
		return this.orgJobList
	}

	getOrgJobPayRateById(id: number): OrgJobPayRateRecord {
		return this.orgJobList.find((rec) => rec.id === id)
	}

	getOrgJobPayRatesForEmpId(id: number): Array<OrgJobPayRateRecord> {
		return this.orgJobList.filter((rec) => rec.vendor_client_id === id)
	}

	getOrgJobPayRatesForJobId(id: number): Array<OrgJobPayRateRecord> {
		return this.orgJobList.filter((rec) => rec.job_id === id)
	}

	removeLocalOrgJobPayRateRecord(recordId: number) {
		this.orgJobList = this.orgJobList.filter((rec) => rec.id !== recordId)
	}

	setOrgJobPayRateRecords(records: Array<OrgJobPayRateRecord>) {
		this.orgJobLastUpdated = new Date()
		this.orgJobList = records.map((rec) => new OrgJobPayRateRecord(rec))
		this.orgJobDataLoaded = true
		this.orgJobListChange.next(this.orgJobList)
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateOrgJobPayRateRecords(records: Array<OrgJobPayRateRecord>) {
		const newRecords = records.map((rec) => new OrgJobPayRateRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.orgJobList.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.orgJobList.push(newRecord)
			}
		}
		this.orgJobLastUpdated = new Date()
		this.orgJobListChange.next(this.orgJobList)
	}
}
