import { environment } from '@env/environment'
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'
import { CoreService } from '@app/services'
import { FileUploaderProgress, FileUploadHelper, SecureFileUploadInfo } from '@app/models'

import { log } from '@app/helpers'
import S3 from 'aws-sdk/clients/s3'

@Component({
	selector: 'app-secure-files-upload',
	templateUrl: './secure-files-upload.component.html',
	styleUrls: ['./secure-files-upload.component.scss'],
	standalone: false,
})
export class SecureFilesUploadComponent implements OnInit {
	files: any[] = []
	currentFile: any

	@Output() uploadProcessStarted = new EventEmitter<boolean>() // Emit event when batch process begins
	@Output() uploadComplete = new EventEmitter<SecureFileUploadInfo>() // Emit even when a single file completes
	@Output() uploadProcessComplete = new EventEmitter<boolean>() // Emit event when batch process completes
	@ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef

	constructor(private coreSrvc: CoreService) {}

	ngOnInit(): void {}

	onFileDropped($event) {
		this.prepareFilesList($event)
	}

	fileBrowseHandler(event: any) {
		const target = event.target as HTMLInputElement
		const files = target.files as unknown as Array<any>
		this.prepareFilesList(files)
	}

	deleteFile(index: number) {
		if (this.files[index].progress < 100) {
			console.log('Upload in progress.')
			return
		}
		this.files.splice(index, 1)
	}

	// uploadFilesSimulator(index: number) {
	// 	setTimeout(() => {
	// 		if (index === this.files.length) {
	// 			setTimeout(() => {
	// 				// this.currentFile = null
	// 			}, 500)
	// 			return
	// 		} else {
	// 			this.currentFile = this.files[index]
	// 			// this.cd.detectChanges()
	// 			const progressInterval = setInterval(() => {
	// 				if (this.currentFile.progress === 100) {
	// 					clearInterval(progressInterval)
	// 					this.uploadFilesSimulator(index + 1)
	// 				} else {
	// 					this.currentFile.progress += 5
	// 				}
	// 			}, 200)
	// 		}
	// 	}, 1000)
	// }

	uploadFiles() {
		const file = this.files.shift()
		log('Upload', file)
		if (file) {
			this.currentFile = file
			const uploadFile = new SecureFileUploadInfo(file)
			setTimeout(() => {
				// Allow time for progress bar to reset
				this.hashFile(uploadFile).then(() => {
					this.uploadFile(uploadFile).then((uploadInfo) => {
						log('Upload Result', uploadInfo)
						if (uploadInfo.success) {
							this.processSuccessfulUpload(uploadInfo)
						} else {
							// this.processSuccessfulUpload(uploadInfo) // REMOVE AFTER TESTING
							this.coreSrvc.notifySrvc.notify(
								'error',
								'Upload Error',
								`An error was encountered uploading ${uploadInfo.file.name} - Please contact support to report this issue.`,
							)
						}
						setTimeout(() => {
							this.uploadFiles()
						}, 500)
					})
				})
			}, 1000)
		} else {
			log('Uploading complete')
			this.uploadsFinished()
		}
	}

	processSuccessfulUpload(uploadInfo: SecureFileUploadInfo) {
		log('Secure Files Upload Component - processSuccessfulUpload', uploadInfo)
		const name = uploadInfo.file.name
		this.uploadComplete.next(uploadInfo)
	}

	uploadsFinished() {
		this.files = []
		this.currentFile = null
		this.uploadProcessComplete.next(true)
	}

	/**
	 * Convert Files list to normal array list
	 * @param files (Files List)
	 */
	prepareFilesList(files: Array<any>) {
		if (this.currentFile) {
			return
		}

		for (const item of files) {
			item.progress = 0
			this.files.unshift(item)
		}
		this.fileDropEl.nativeElement.value = ''
		this.uploadProcessStarted.next(true)
		this.uploadFiles()
	}

	formatBytes(bytes) {
		return FileUploadHelper.formatBytes(bytes)
	}

	private hashFile(uploadFile: SecureFileUploadInfo): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			const reader = new FileReader()
			reader.onload = () => {
				const buffer: any = reader.result
				crypto.subtle
					.digest('SHA-256', buffer)
					.then((hashBuffer) => {
						const hashArray = Array.from(new Uint8Array(hashBuffer))
						const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('')
						uploadFile.sha256 = hashHex
						resolve(true)
					})
					.catch(() => {
						log('Encountered error hashing file object.')
						uploadFile.sha256 = null
						resolve(true)
					})
			}
			reader.onerror = reject
			reader.readAsArrayBuffer(uploadFile.file)
		})
	}

	uploadFile(uploadFile: SecureFileUploadInfo): Promise<SecureFileUploadInfo> {
		const companyId = this.coreSrvc.dbSrvc.settingSrvc.getCompany().id
		const userId = this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser().id
		// const uploadFile = new SecureFileUploadInfo(file)
		const bucketKey = uploadFile.uuid

		return new Promise((resolve, reject) => {
			const params: any = {
				Metadata: {
					company_id: `${companyId}`,
					user_id: `${userId}`,
					file_type: uploadFile.file.type,
				},
				Bucket: environment.secureUploadBucket,
				Key: bucketKey,
				ContentType: uploadFile.file.type,
				Body: uploadFile.file,
			}

			// Add hash to metadata if it exists
			if (uploadFile.sha256) {
				params.Metadata.sha256 = uploadFile.sha256
			}

			log('Upload Parameters', params)

			const bucket = new S3()
			bucket
				.upload(params, (err, data) => {
					if (err) {
						// Encountered an error
						log('Upload Error', err)
						resolve(uploadFile)
					} else {
						// Upload successful
						uploadFile.success = true
						resolve(uploadFile)
					}
				})
				.on('httpUploadProgress', (evt) => {
					const progress = new FileUploaderProgress(evt.loaded, evt.total)
					this.updatePercentComplete(progress)
				})
		})
	}

	updatePercentComplete(progress: FileUploaderProgress) {
		const result = (progress.bytesSent / progress.bytesTotal) * 100
		this.currentFile.progress = Math.floor(result)
	}
}
