<app-table-filter-buttons [manager]="tableFilterManager" (buttonClicked)="changeSubsection($event)"></app-table-filter-buttons>

<div class="section-table">
	<table id="organizationTable" class="stripe nowrap" cellspacing="0" width="100%"></table>
	<i id="clear-search-icon" (click)="clearSearch()" class="fa fa-times-circle-o"></i>
	<app-table-footer-buttons
		[filename]="'organization_records'"
		[newBtnLabel]="'Add Organization'"
		[exportBtnLabel]="'Export Records'"
		[table]="'organizationTable'"
		[marginTop]="'15px'"
		(newBtnClicked)="createRecord()"
	></app-table-footer-buttons>
</div>

<app-modal-dialog *ngIf="editDialogManager.isDialogVisible" [dialogManager]="editDialogManager">
	<app-organization-edit
		[recordId]="editAction.recordId"
		[action]="editAction.action"
		[dialogManager]="editDialogManager"
		(recordUpdated)="recordUpdated()"
	>
	</app-organization-edit>
</app-modal-dialog>

<app-delete-record-dialog
	*ngIf="deleteAction.showDeleteRecordDialog"
	[showDeleteRecordDialog]="deleteAction.showDeleteRecordDialog"
	[tableName]="deleteAction.tableName"
	[recordId]="deleteAction.recordId"
	[recordLabel]="deleteAction.recordLabel"
	(cancelDelete)="deleteActionCancel()"
	(actionComplete)="deleteActionComplete()"
>
</app-delete-record-dialog>

<app-modal-dialog *ngIf="linkedJobRecordsDialogManager.isDialogVisible" [dialogManager]="linkedJobRecordsDialogManager">
	<div style="margin-bottom: 15px">
		<app-search-filter [placeholder]="'filter records'" (search)="filterLinkedRecords($event, 'job')"> </app-search-filter>
	</div>

	<ul style="color: gray">
		<li *ngFor="let record of linkedJobRecordsManager.filteredRecords">{{ record.description }}</li>
	</ul>
</app-modal-dialog>

<app-modal-dialog *ngIf="linkedContactRecordsDialogManager.isDialogVisible" [dialogManager]="linkedContactRecordsDialogManager">
	<app-link-contact-org-list
		[dialogManager]="linkedContactRecordsDialogManager"
		[linkedRecordsManager]="linkedContactRecordsManager"
		[source]="'ORGANIZATION'"
		(recordUpdated)="contactOrgRecordSaved($event)"
	></app-link-contact-org-list>
</app-modal-dialog>

<app-modal-dialog *ngIf="sectionPrefsDialogManager.isDialogVisible" [dialogManager]="sectionPrefsDialogManager">
	<app-local-prefs [dialogManager]="sectionPrefsDialogManager" (prefsUpdated)="prefsDataSaved()"></app-local-prefs>
</app-modal-dialog>

<app-modal-dialog *ngIf="secureFilesDialogManager.isDialogVisible" [dialogManager]="secureFilesDialogManager">
	<app-secure-files-list [dialogManager]="secureFilesDialogManager" (recordUpdated)="updateRecord($event)"></app-secure-files-list>
</app-modal-dialog>
