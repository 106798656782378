<div class="section-table">
	<table id="smsTable" class="stripe nowrap" cellspacing="0" width="100%"></table>
	<i id="clear-search-icon" (click)="clearSearch()" class="fa fa-times-circle-o"></i>
	<app-table-footer-buttons
		[filename]="'sms_debug_records'"
		[newBtnLabel]="''"
		[exportBtnLabel]="'Export Records'"
		[table]="'smsTable'"
		[marginTop]="'15px'"
	></app-table-footer-buttons>
</div>

<!-- May use something like the following if we batch records into groups - app-sms-lod-data does not yet exist -->
<!-- <app-basic-modal
	[(visible)]="viewLogAction.showDialog"
	modalId="ivCallLogModal"
	[modalHeader]="viewLogAction.header"
	[modalFooter]="viewLogAction.footer"
	[modalWidth]="450"
>
	<app-sms-log-data *ngIf="viewLogAction.showDialog" [logId]="viewLogAction.callLogId" [timezone]="viewManager.timezone"></app-sms-log-data>
</app-basic-modal> -->
