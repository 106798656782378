import { Component, OnInit, OnDestroy, Input, AfterViewInit, Output, EventEmitter, AfterContentInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'

import { AdpRateCodeSelectItem, DialogManager, HelpDialogMessage, OrgJobPayRateRecord, PayRateViewManager } from '@app/models'
import { CoreService } from '@app/services'
import { log, FormHelper, DropdownHelperConfig, DropdownHelper } from '@app/helpers'
import { SelectItem } from 'primeng/api/selectitem'
import { NumberHelper } from '@app/helpers/number'

import _ from 'lodash'

@Component({
    selector: 'app-org-job-pay-rates-edit',
    templateUrl: './org-job-pay-rates-edit.component.html',
    styleUrls: ['./org-job-pay-rates-edit.component.scss'],
    standalone: false
})
export class OrgJobPayRatesEditComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
	shouldRestrictDropdowns = true

	payRate: OrgJobPayRateRecord
	isNew = false
	isUpdating = false
	rateForm: UntypedFormGroup

	@Input() dialogManager = new DialogManager()
	@Input() recordId: number
	@Input() action = 'edit'

	@Output() recordUpdated = new EventEmitter<OrgJobPayRateRecord>()

	currencySymbol = ''

	organizationOptions: Array<SelectItem> = []
	jobOptions: Array<SelectItem> = []
	adpRateCodes: Array<AdpRateCodeSelectItem> = []

	constructor(
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
	) {
		// Setup dropdown population (restrictions)
		const accessPermissions = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAccessPermissions().getAccessPermissionsFor('employee_job_pay_rate')
		this.shouldRestrictDropdowns = !accessPermissions.create // Restrict if user doesn't have all access create

		this.organizationOptions = this.makeOrganizationDropdown()
		this.jobOptions = this.makeJobsDropdown()
		this.currencySymbol = this.coreSrvc.dbSrvc.settingSrvc.getCompanyCurrencySymbol()
	}

	get viewManager(): PayRateViewManager {
		return this.coreSrvc.dbSrvc.payRatesSrvc.viewManager
	}

	get orgTypeLabel(): string {
		return _.capitalize(this.viewManager.currentView)
	}

	get billPayRateLabel(): string {
		const orgType = this.viewManager.currentView
		return orgType === 'CLIENT' ? 'Bill Rate' : 'Pay Rate'
	}

	makeOrganizationDropdown(): Array<SelectItem> {
		const orgType = this.viewManager.currentView === 'CLIENT' ? 'CLIENT' : 'VENDOR'
		const config = new DropdownHelperConfig(this.coreSrvc.dbSrvc, 'DROPDOWN')
		config.isRestricted = false // this.shouldRestrictDropdowns
		const dropdownHelper = new DropdownHelper(config)
		const options = dropdownHelper.buildOrganizationDropdownMenuOptions(orgType)
		return options
	}

	makeJobsDropdown(): Array<SelectItem> {
		const config = new DropdownHelperConfig(this.coreSrvc.dbSrvc, 'DROPDOWN')
		config.isRestricted = this.shouldRestrictDropdowns
		const dropdownHelper = new DropdownHelper(config)
		const options = dropdownHelper.buildJobMenuOptions()
		return options
	}

	ngOnInit() {
		if (this.action === 'new') {
			log('Creating a new client')
			this.isNew = true
			this.payRate = new OrgJobPayRateRecord()
		} else {
			this.isNew = false
			this.payRate = this.coreSrvc.dbSrvc.payRatesSrvc.getOrgJobPayRateById(this.recordId)
		}
		log('Init Pay Rate', this.payRate)
		this.setupForm()
	}

	ngAfterViewInit() {}

	ngAfterContentInit() {
		this.setupDialogManager()
	}

	ngOnDestroy() {}

	viwFormErrors() {
		FormHelper.getFormValidationErrors(this.rateForm)
	}

	isRateValid(): boolean {
		return true
	}

	isFormValid(): boolean {
		const isRateValid = this.isRateValid()
		const isFormValid = this.rateForm.valid
		return isFormValid && isRateValid
	}

	setupForm() {
		const payRate = this.payRate
		this.rateForm = this.fb.group({
			id: [payRate.id],
			company_id: [payRate.company_id],
			vendor_client_id: [payRate.vendor_client_id, Validators.required],
			job_id: [payRate.job_id, Validators.required],
			pay_rate: [payRate.pay_rate],
		})
		this.rateForm.get('pay_rate').setValidators(Validators.required)
	}

	setupDialogManager() {
		this.dialogManager.canSubmit = () => this.isFormValid()
		this.dialogManager.submitBtnAction = () => this.submit()
	}

	submit() {
		// Guard against double submission
		if (this.isUpdating) return
		// FormHelper.trimOnlyWhitespace(this.rateForm)

		const record = this.makeUpdateRecord()
		log('Record to submit', record)
		if (record) {
			if (this.isNew) {
				log('Adding new pay rate', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.insertRecord('vendor_client_job_pay_rate', record).then((success) => {
					if (success) {
						this.recordUpdated.emit(this.payRate)
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			} else {
				log('Updating record', record)
				this.isUpdating = true
				this.coreSrvc.dbSrvc.updateRecord('vendor_client_job_pay_rate', record).then((success) => {
					if (success) {
						this.recordUpdated.emit(this.payRate)
						this.dialogManager.isDialogVisible = false
					} else {
						this.isUpdating = false
					}
				})
			}
		}
	}

	formatInput(prop: string) {
		const value = this.rateForm.get(prop).value
		const newValue = NumberHelper.formatPayRate(value)
		this.rateForm.get(prop).setValue(newValue)
	}

	makeUpdateRecord(): Object {
		const form = this.rateForm.value
		const record = new OrgJobPayRateRecord(form)

		return record
	}

	// Misc Methods

	toggleCheckbox(prop: string) {
		log('prop', prop)
		const current = this.rateForm.get(prop).value
		this.rateForm.get(prop).setValue(!current)
	}

	showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'payRate':
				const orgType = this.viewManager.currentView
				if (orgType === 'CLIENT') {
					help.header = 'Bill Rate'
					help.message = 'Enter the bill rate for this client with respect to the specified job.'
				} else {
					help.header = 'Pay Rate'
					help.message = 'Enter the pay rate for this vendor with respect to the specified job.'
				}
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
