import { Component, OnInit, Input, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core'
import { NotificationProfile, DialogManager, HelpDialogMessage } from '@app/models'
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms'
import { CoreService } from '@app/services'
import { FormHelper, Helper, PhoneHelper } from '@app/helpers'
import { log } from '@app/helpers/logger'

import { CountryCode, parseNumber, isValidNumberForRegion, ParsedNumber, formatNumber } from 'libphonenumber-js'
import { SelectItem } from 'primeng/api'
import moment from 'moment-timezone'

class LinkedJobAlertItem {
	jobName: string
	repeatInterval: number
	waypointCount: number
	type: 'UNSTRUCTURED' | 'STRUCTURED'
}
class LinkedJobsAlert {
	linkedJobsCount = 0
	linkedJobs: Array<LinkedJobAlertItem> = []
}

class NotificationProfileSupervisorPhoneEntry {
	name = ''
	number = ''
	isUser = false
}

class NotificationProfileSupervisorEmailEntry {
	name = ''
	email = ''
	isUser = false
}

class NotificationProfileClientPhoneEntry {
	name = ''
	number = ''
	isClient = false
}

class NotificationProfileClientEmailEntry {
	name = ''
	email = ''
	isClient = false
}

@Component({
    selector: 'app-notification-profile-edit',
    templateUrl: './profile-edit.component.html',
    styleUrls: ['./profile-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NotificationProfileEditComponent implements OnInit, AfterViewInit, OnDestroy {
	profile = new NotificationProfile()
	isNew = false
	isUpdating = false
	isQRCodeValidationEnabled = false
	isCallCenterEnabled = false

	profileForm: UntypedFormGroup

	alert = new LinkedJobsAlert()

	@Input() recordId: number
	@Input() action = 'edit'
	@Input() dialogManager = new DialogManager()
	@Input() performParentAction = true
	// @Input() incrementJobLinkCount = false

	@Output() profileUpdated = new EventEmitter<boolean>()

	///

	linkedJobOptions: SelectItem[] = []

	noteMinInterval = 5
	noteMaxDuration = 120
	// defaultSupervisorPhone = ''
	// defaultSupervisorEmail = ''

	// Enable Options
	showNotifyEmpOnUpcomingShift = false
	showNotifySupOnEmpEarlyCheckin = false
	showNotifySupOnEmpCheckin = false
	showClientOptions = false
	showRepeatingInputs = false

	preShiftNotifyOptions: Array<SelectItem> = []

	newClientEmailInput: string
	clientEmails: Array<NotificationProfileClientEmailEntry> = []
	newClientPhontInput: string
	clientPhoneNumbers: Array<NotificationProfileClientPhoneEntry> = []
	clientPhoneValid = false

	newSupEmailInput: string
	supervisorEmails: Array<NotificationProfileSupervisorEmailEntry> = []
	newSupPhoneInput: string
	supervisorPhoneNumbers: Array<NotificationProfileSupervisorPhoneEntry> = []
	supPhoneValid = false
	supPhoneLimit: number = null

	newSchedChangeEmailInput: string
	schedChangeEmails: Array<string> = []

	countryCodeData = Helper.countryIsoData

	parentSubmitAction = () => {}

	constructor(
		private cd: ChangeDetectorRef,
		private fb: UntypedFormBuilder,
		private coreSrvc: CoreService,
	) {
		log('Profile Edit DatabaseService UUID', this.coreSrvc.dbSrvc.uuid)

		// Enable visibility of options
		const userPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		this.showNotifyEmpOnUpcomingShift = userPrefs.npEnableNoteEmpPreShift
		this.showNotifySupOnEmpEarlyCheckin = userPrefs.npEnableNoteEmpEarlyCheckin
		this.showNotifySupOnEmpCheckin = userPrefs.npEnableNoteEmpCheckin
		this.showRepeatingInputs = userPrefs.npEnableRepeatingInputs
		this.isQRCodeValidationEnabled = this.coreSrvc.dbSrvc.settingSrvc.getCompany().validate_qr_codes
		this.supPhoneLimit = userPrefs.npSupPhoneNumberLimit

		// Call center availability
		const hasGlobal = this.coreSrvc.dbSrvc.settingSrvc.hasGlobalAccount()
		if (hasGlobal) {
			const globalCompany = this.coreSrvc.dbSrvc.settingSrvc.getGlobalCompany()
			this.isCallCenterEnabled = globalCompany.cc_enable
		} else {
			this.isCallCenterEnabled = this.coreSrvc.dbSrvc.settingSrvc.getCompany().cc_enable
		}
	}

	public ngOnInit(): void {
		// log('NotificationProfileEditComponent OnInit')
		const profile = this.coreSrvc.dbSrvc.npSrvc.getProfileById(this.recordId)

		switch (this.action) {
			case 'edit':
				this.profile = profile
				break
			case 'clone':
				const clone = new NotificationProfile(profile)
				clone.id = null
				clone.job_ids = []
				clone.name = clone.name + ' Copy'
				this.isNew = true
				this.profile = clone
				break
			default:
				this.profile = new NotificationProfile()
				this.isNew = true
		}

		log('Current Profile', this.profile)
		// log('All profiles', this.coreSrvc.dbSrvc.npSrvc.getProfiles())

		// this.profile = this.coreSrvc.dbSrvc.npSrvc.getProfileById(this.recordId)
		// if (!this.profile) {
		// 	this.profile = new NotificationProfile()
		// 	this.isNew = true
		// }
		if (this.performParentAction) {
			this.parentSubmitAction = this.dialogManager.submitBtnAction
		}

		this.dialogManager.submitBtnAction = () => this.submitBtnClicked()

		this.setupForm()
		this.updateLinkedJobs()

		this.setupLinkedJobOptions()
		this.setupPreShiftNotifyOptions()

		this.setupClientOptionsVisibility()
		this.setupClientPhoneNumbers()
		this.setupClientEmails()

		this.setupSupervisorPhoneNumbers()
		this.setupSupervisorEmails()
		this.formatPhone('supDialingCode', 'supervisor_phone_e164')
	}

	public ngAfterViewInit() {
		setTimeout(() => {
			this.dialogManager.canSubmit = () => this.isFormValid()
		}, 100)
	}

	public ngOnDestroy() {
		log('Profile edit onDestroy')
	}

	private setupClientOptionsVisibility() {
		const userPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		const np = this.profile
		this.showClientOptions =
			userPrefs.npEnableClientOptions || np.sms_client_on_incident || np.call_client_on_incident || np.email_client_on_incident
	}

	public linkedJobsChanged(ids: Array<number>) {
		this.profileForm.get('job_ids').setValue(ids)
		this.updateLinkedJobs()
	}
	private updateLinkedJobs() {
		const linkedJobs = []
		const jobIds = this.profileForm.get('job_ids').value ?? []
		const jobs = jobIds.map((id) => this.coreSrvc.dbSrvc.jobSrvc.getJobById(id)).filter((job) => !!job)
		for (const job of jobs) {
			const item = new LinkedJobAlertItem()
			item.jobName = job.description
			item.repeatInterval = job.repeat_interval ? moment.duration(job.repeat_interval).asMinutes() : null
			item.waypointCount = job.waypoint_count
			linkedJobs.push(item)
		}
		this.alert.linkedJobs = linkedJobs
		this.alert.linkedJobsCount = jobs.length
	}

	private submitBtnClicked() {
		// log('NotficationProfileEditComponent submitBtnClicked')
		this.submitForm()
	}

	private setupForm() {
		const profile = this.profile
		this.profileForm = this.fb.group({
			id: [profile.id],
			owner_id: [profile.owner_id],
			name: [profile.name, Validators.required],

			client_phone_e164: [profile.client_phone_e164],
			client_email: [profile.client_email],
			supervisor_phone_e164: [profile.supervisor_phone_e164],
			supervisor_email: [profile.supervisor_email],
			checkpoint_frequency: [this.makeNotificationDelay(profile.checkpoint_frequency)],

			// Notify employee for upcoming shift
			call_employee_preshift: [profile.call_employee_preshift],
			sms_employee_preshift: [profile.sms_employee_preshift],
			emp_notification_preshift_interval: [profile.emp_notification_preshift_interval],

			// Notify employee for late check in
			call_employee_late_in: [profile.call_employee_late_in],
			emp_notification_delay_late_in: [this.makeNotificationDelay(profile.emp_notification_delay_late_in)],
			emp_notification_interval_late_in: [this.makeNotificationDelay(profile.emp_notification_interval_late_in)],
			emp_notification_duration_late_in: [this.makeNotificationDelay(profile.emp_notification_duration_late_in)],

			// Notify client on incident report
			call_client_on_incident: [profile.call_client_on_incident],
			sms_client_on_incident: [profile.sms_client_on_incident],
			email_client_on_incident: [profile.email_client_on_incident],

			// Notify employee for late check out
			call_employee_late_out: [profile.call_employee_late_out],
			emp_notification_delay_late_out: [this.makeNotificationDelay(profile.emp_notification_delay_late_out)],
			emp_notification_interval_late_out: [this.makeNotificationDelay(profile.emp_notification_interval_late_out)],
			emp_notification_duration_late_out: [this.makeNotificationDelay(profile.emp_notification_duration_late_out)],

			// Notify employee for late checkpoint
			call_employee_checkpoint: [profile.call_employee_checkpoint],
			emp_notification_delay_checkpoint: [this.makeNotificationDelay(profile.emp_notification_delay_checkpoint)],

			// Notify supervisor for early check in
			call_supervisor_early_in: [profile.call_supervisor_early_in],
			sms_supervisor_early_in: [profile.sms_supervisor_early_in],
			email_supervisor_early_in: [profile.email_supervisor_early_in],
			sup_notification_early_in_min_time: [this.makeNotificationDelay(profile.sup_notification_early_in_min_time)],

			// Notify supervisor for check in
			call_supervisor_at_check_in: [profile.call_supervisor_at_check_in],
			sms_supervisor_at_check_in: [profile.sms_supervisor_at_check_in],
			email_supervisor_at_check_in: [profile.email_supervisor_at_check_in],

			// Notify supervisor for late check in
			call_supervisor_late_in: [profile.call_supervisor_late_in],
			sms_supervisor_late_in: [profile.sms_supervisor_late_in],
			email_supervisor_late_in: [profile.email_supervisor_late_in],
			sup_notification_delay_late_in: [this.makeNotificationDelay(profile.sup_notification_delay_late_in)],
			sup_notification_interval_late_in: [this.makeNotificationDelay(profile.sup_notification_interval_late_in)],
			sup_notification_duration_late_in: [this.makeNotificationDelay(profile.sup_notification_duration_late_in)],
			notify_on_clear: [profile.notify_on_clear],

			// Notify supervisor for early check out
			call_supervisor_on_early_out: [profile.call_supervisor_on_early_out],
			sms_supervisor_on_early_out: [profile.sms_supervisor_on_early_out],
			email_supervisor_on_early_out: [profile.email_supervisor_on_early_out],
			sup_notification_early_out_min_time: [this.makeNotificationDelay(profile.sup_notification_early_out_min_time)],

			// Notify supervisor for late check out
			call_supervisor_late_out: [profile.call_supervisor_late_out],
			sms_supervisor_late_out: [profile.sms_supervisor_late_out],
			email_supervisor_late_out: [profile.email_supervisor_late_out],
			sup_notification_delay_late_out: [this.makeNotificationDelay(profile.sup_notification_delay_late_out)],
			sup_notification_interval_late_out: [this.makeNotificationDelay(profile.sup_notification_interval_late_out)],
			sup_notification_duration_late_out: [this.makeNotificationDelay(profile.sup_notification_duration_late_out)],

			// Notify supervisor on GPS issue
			call_sup_on_gps_issue: [profile.call_sup_on_gps_issue],
			sms_sup_on_gps_issue: [profile.sms_sup_on_gps_issue],
			email_sup_on_gps_issue: [profile.email_sup_on_gps_issue],
			sup_notification_time_late_gps: [this.makeNotificationDelay(profile.sup_notification_time_late_gps)],
			sup_notification_distance_offsite_gps: [profile.sup_notification_distance_offsite_gps],

			// Notify supervisor for late checkpoint
			call_supervisor_checkpoint: [profile.call_supervisor_checkpoint],
			sms_supervisor_checkpoint: [profile.sms_supervisor_checkpoint],
			email_supervisor_checkpoint: [profile.email_supervisor_checkpoint],
			sup_notification_delay_checkpoint: [this.makeNotificationDelay(profile.sup_notification_delay_checkpoint)],

			// Notify supervisor for missed dispatch checkpoint
			call_supervisor_dispatch_cp_fail: [profile.call_supervisor_dispatch_cp_fail],
			sms_supervisor_dispatch_cp_fail: [profile.sms_supervisor_dispatch_cp_fail],
			email_supervisor_dispatch_cp_fail: [profile.email_supervisor_dispatch_cp_fail],

			// Notify supervisor for employee unschedule job
			call_supervisor_on_unscheduled_in: [profile.call_supervisor_on_unscheduled_in],
			sms_supervisor_on_unscheduled_in: [profile.sms_supervisor_on_unscheduled_in],
			email_supervisor_on_unscheduled_in: [profile.email_supervisor_on_unscheduled_in],

			// Notify supervisor for incident report filed
			call_supervisor_on_incident: [profile.call_supervisor_on_incident],
			sms_supervisor_on_incident: [profile.sms_supervisor_on_incident],
			email_supervisor_on_incident: [profile.email_supervisor_on_incident],

			// Notify supervisor on QR code validation failed
			call_supervisor_qrfail_inout: [profile.call_supervisor_qrfail_inout],
			sms_supervisor_qrfail_inout: [profile.sms_supervisor_qrfail_inout],
			email_supervisor_qrfail_inout: [profile.email_supervisor_qrfail_inout],

			clientDialingCode: [this.coreSrvc.dbSrvc.settingSrvc.getCompany().country_iso],
			supDialingCode: [this.coreSrvc.dbSrvc.settingSrvc.getCompany().country_iso],

			job_ids: [profile.job_ids ?? []],
		})
		// Setup dialing codes from current numbers
		this.setupDialingCode('supDialingCode', 'supervisor_phone_e164')
	}

	private setupPreShiftNotifyOptions() {
		this.preShiftNotifyOptions = [
			{ label: '15 minutes', value: 'PT15M' },
			{ label: '30 minutes', value: 'PT30M' },
			{ label: '45 minutes', value: 'PT45M' },
			{ label: '1 hour', value: 'PT1H' },
			{ label: '2 hours', value: 'PT2H' },
			{ label: '3 hours', value: 'PT3H' },
			{ label: '6 hours', value: 'PT6H' },
			{ label: '12 hours', value: 'PT12H' },
			{ label: '24 hours', value: 'PT24H' },
		]
	}

	private setupLinkedJobOptions() {
		const includeIds = this.profile.job_ids || []
		const options = this.coreSrvc.dbSrvc.jobSrvc.getJobDropdown(this.coreSrvc.dbSrvc, false, false, false, [])
		const filteredOptions = options.filter((jdd) => jdd.data.active || includeIds.includes(jdd.data.id))
		filteredOptions.forEach((opt) => {
			const jId = opt.data?.id
			const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jId)
			if (job) {
				const np = this.coreSrvc.dbSrvc.npSrvc.getProfileById(job.notification_profile_id)
				if (np) {
					const npName = np.name
					opt.label = opt.label + ` (${npName})`
				}
			}
		})
		this.linkedJobOptions = filteredOptions
	}

	// private makeJobIds(ids: string): Array<number> {
	// 	log('IDS')
	// 	const list = (ids || '')
	// 		.split(',')
	// 		.filter((id) => !!id)
	// 		.map((id) => parseInt(id, 10))
	// 	log('Job IDs', list)
	// 	return list
	// }

	private setupDialingCode(codeField: string, phoneField: string) {
		const supPhone = this.profileForm.get(phoneField).value
		if (supPhone) {
			const parsedNumber = parseNumber(supPhone) as ParsedNumber
			const countryCode = parsedNumber?.country
			if (countryCode) {
				this.profileForm.get(codeField).setValue(countryCode)
			}
		}
	}

	public toggleCheckbox(prop: string) {
		const current = this.profileForm.get(prop).value
		this.profileForm.get(prop).setValue(!current)
		this.clearInvalidInputsIfNotRequired()
	}

	public clearInvalidInputsIfNotRequired() {
		if (!this.isSupervisorPhoneNumberRequired) {
			if (!this.supPhoneValid) {
				this.profileForm.get('supervisor_phone_e164').setValue(null)
				this.formatPhone('supDialingCode', 'supervisor_phone_e164')
			}
		}
		if (!this.isSupervisorEmailRequired) {
			if (!this.isEmailInputValid('supervisor')) {
				// log('Clearing email')
				this.newSupEmailInput = ''
			}
		}
		if (!this.isClientPhoneNumberRequired) {
			if (!this.clientPhoneValid) {
				this.profileForm.get('client_phone_e164').setValue(null)
				this.formatPhone('clientDialingCode', 'client_phone_e164')
			}
		}
		if (!this.isClientEmailRequired) {
			if (!this.isEmailInputValid('client')) {
				this.newClientEmailInput = ''
			}
		}
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'enableNotifications':
				help.header = 'System Messages'
				help.message =
					'When checked, Telephone Timesheets support will email system messages to this user, in certain instances.  For example, when an employee mistakenly sends a text message into the system like “Sorry, I’m gonna be late for my shift”'
				break
			case 'supervisorPhone':
				help.header = 'Supervisor Phone'
				help.message = `From the dropdown menu, please select the region for your supervisor and then enter their phone number.`
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}

	/// - Migration

	// Setup notification delay for form value
	private makeNotificationDelay(duration: string): number {
		if (!duration) {
			return null
		}
		const mom = moment.duration(duration)
		if (mom) {
			return mom.asMinutes()
		}
		return null
	}

	public validateNumericInput(input) {
		const value = this.profileForm.get(input).value
		const intValue = parseInt(value, 10)

		switch (input) {
			case 'emp_notification_interval_late_in':
			case 'emp_notification_interval_late_out':
			case 'sup_notification_interval_late_in':
			case 'sup_notification_interval_late_out':
				if (intValue && intValue < this.noteMinInterval) {
					this.coreSrvc.notifySrvc.notify('warn', 'Invalid Value', `The minimum value for this field is ${this.noteMinInterval} minutes.`, 3)
					this.profileForm.get(input).setValue(this.noteMinInterval)
				}
				return
			case 'emp_notification_duration_late_in':
			case 'emp_notification_duration_late_out':
			case 'sup_notification_duration_late_in':
			case 'sup_notification_duration_late_out':
				if (intValue && intValue > this.noteMaxDuration) {
					this.coreSrvc.notifySrvc.notify('warn', 'Invalid Value', `The maximum value for this field is ${this.noteMaxDuration} minutes.`, 3)
					this.profileForm.get(input).setValue(this.noteMaxDuration)
				}
				return
		}
		if (intValue) {
			this.profileForm.get(input).setValue(intValue)
		} else {
			this.profileForm.get(input).setValue(null)
		}
	}

	public formatPhone(code: string, field: string) {
		const countryCode: CountryCode = this.profileForm.get(code).value
		const number = this.profileForm.get(field).value
		if (number && number.length > 3 && isValidNumberForRegion(number, countryCode)) {
			const parsedNumber = parseNumber(number, countryCode) as ParsedNumber
			const patchValue = {}
			patchValue[field] = formatNumber(parsedNumber, 'NATIONAL')
			this.profileForm.patchValue(patchValue)
			if (field === 'client_phone_e164') {
				this.clientPhoneValid = true
			}
			if (field === 'supervisor_phone_e164') {
				this.supPhoneValid = true
			}
		} else {
			if (field === 'client_phone_e164') {
				this.clientPhoneValid = false
			}
			if (field === 'supervisor_phone_e164') {
				this.supPhoneValid = false
			}
		}
	}

	// Email Processing

	private setupSupervisorEmails() {
		// const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		// const defaultEmail = company.supervisor_email
		const emailString = this.profileForm.get('supervisor_email').value // || defaultEmail
		if (emailString) {
			const supEmails = []
			const emails = emailString.split(',')
			for (const email of emails) {
				const entry = new NotificationProfileSupervisorEmailEntry()
				entry.email = email
				const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForEmailAddress(email)
				if (user) {
					entry.name = user.first_name + ' ' + user.last_name
					entry.isUser = true
				}
				supEmails.push(entry)
			}
			this.supervisorEmails = supEmails
		}
	}

	private setupClientEmails() {
		const emailString = this.profileForm.get('client_email').value // || defaultEmail
		if (emailString) {
			const clientEmails = []
			const emails = emailString.split(',')
			log('Client Email Split', emails)
			for (const email of emails) {
				log('Got an email', email)
				const entry = new NotificationProfileClientEmailEntry()
				entry.email = email
				const client = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationByEmailAddress(email)
				if (client && client.type === 'CLIENT') {
					entry.name = client.name
					entry.isClient = true
				}
				clientEmails.push(entry)
			}
			this.clientEmails = clientEmails
		}
		log('Client Emails', this.clientEmails)
	}

	public isEmailInputValid(type: string): boolean {
		switch (type) {
			case 'client':
				return Helper.isValidEmail(this.newClientEmailInput)
			case 'supervisor':
				return Helper.isValidEmail(this.newSupEmailInput)
			default:
				return true
		}
	}

	// public isSchedChangeEmailInputValid(): boolean {
	// 	const email = this.newSchedChangeEmailInput
	// 	return Helper.isValidEmail(email)
	// }

	public addClientEmail(showAlert: boolean) {
		const email = this.newClientEmailInput
		if (email && this.isEmailInputValid('client')) {
			const existingEntry = this.clientEmails.find((ce) => ce.email === email)
			if (!existingEntry) {
				const entry = new NotificationProfileClientEmailEntry()
				entry.email = email
				const client = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationByEmailAddress(email)
				if (client && client.type === 'CLIENT') {
					entry.name = client.name
					entry.isClient = true
				}
				this.clientEmails.push(entry)
			}
			this.newClientEmailInput = ''
		} else {
			if (showAlert) {
				// alert('The supervisor email address you entered is not a valid address.')
				this.coreSrvc.notifySrvc.notify('error', 'Invalid Email', 'The supervisor email address you entered is not a valid address.')
			}
			return
		}
	}

	public addSupEmail(showAlert: boolean) {
		const email = this.newSupEmailInput
		if (email && this.isEmailInputValid('supervisor')) {
			const existingEntry = this.supervisorEmails.find((se) => se.email === email)
			if (!existingEntry) {
				const entry = new NotificationProfileSupervisorEmailEntry()
				entry.email = email
				const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForEmailAddress(email)
				if (user) {
					entry.name = user.first_name + ' ' + user.last_name
					entry.isUser = true
				}
				this.supervisorEmails.push(entry)
			}
			this.newSupEmailInput = ''
		} else {
			if (showAlert) {
				// alert('The supervisor email address you entered is not a valid address.')
				this.coreSrvc.notifySrvc.notify('error', 'Invalid Email', 'The supervisor email address you entered is not a valid address.')
			}
			return
		}
	}

	// public addSchedChangeEmail(showAlert: boolean) {
	// 	const email = this.newSchedChangeEmailInput
	// 	if (email && this.isSchedChangeEmailInputValid()) {
	// 		this.schedChangeEmails.push(email)
	// 		this.newSchedChangeEmailInput = ''

	// 	} else {
	// 		if (showAlert) {
	// 			// alert('The contact email address for schedule changes is not a valid address.')
	// 			this.coreSrvc.notifySrvc.notify('error', 'Invalid Email', 'The contact email address for schedule changes is not a valid address.')
	// 		}
	// 		return
	// 	}
	// }

	public removeEmail(type: string, idx: number) {
		switch (type) {
			case 'supervisor':
				this.supervisorEmails.splice(idx, 1)
				break
			case 'client':
				this.clientEmails.splice(idx, 1)
				break
		}
	}

	private setupClientPhoneNumbers() {
		const phoneNumberString = this.profile.client_phone_e164
		log('client_phone_e164', phoneNumberString)
		if (phoneNumberString) {
			const numbers = phoneNumberString.split(',')
			numbers.forEach((num) => {
				const entry = new NotificationProfileClientPhoneEntry()
				entry.number = num
				const client = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationByEmailAddress(num)
				if (client && client.type === 'CLIENT') {
					entry.name = client.name
					entry.isClient = true
				}
				this.clientPhoneNumbers.push(entry)
			})
		}
		this.profileForm.get('client_phone_e164').setValue(null)
		log('Client Phone Numbers', this.clientPhoneNumbers)
	}

	private setupSupervisorPhoneNumbers() {
		const phoneNumberString = this.profile.supervisor_phone_e164
		log('Sup Phone String', phoneNumberString)
		if (phoneNumberString) {
			const numbers = phoneNumberString.split(',')
			numbers.forEach((num) => {
				const entry = new NotificationProfileSupervisorPhoneEntry()
				entry.number = num
				const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForE164Number(num)
				if (user) {
					entry.name = user.first_name + ' ' + user.last_name
					entry.isUser = true
				}
				this.supervisorPhoneNumbers.push(entry)
			})
		}
		this.profileForm.get('supervisor_phone_e164').setValue(null)
		log('Client Phone Numbers', this.supervisorPhoneNumbers)
	}

	public isSupPhoneInputValid(): boolean {
		return false
	}

	private getSupNameForE164Number(phoneE164: string) {
		const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForE164Number(phoneE164)
	}

	public doesPhoneEntryResolve(type: string, idx: number) {
		switch (type) {
			case 'client':
				const clientEntry = this.clientPhoneNumbers[idx]
				return clientEntry.isClient
			case 'supervisor':
				const supEntry = this.supervisorPhoneNumbers[idx]
				return supEntry.isUser
			default:
				return false
		}
	}

	public addClientPhone() {
		if (this.clientPhoneValid) {
			const phoneNumber = this.profileForm.get('client_phone_e164').value
			const countryCode = this.profileForm.get('clientDialingCode').value as CountryCode
			const phoneE164 = PhoneHelper.formatPhoneE164(countryCode, phoneNumber)
			const existingEntry = this.clientPhoneNumbers.find((cp) => cp.number === phoneE164)
			if (!existingEntry) {
				const phoneEntry = new NotificationProfileClientPhoneEntry()
				phoneEntry.number = phoneE164
				const client = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationByE164Number(phoneE164)
				if (client && client.type === 'CLIENT') {
					phoneEntry.name = client.name
					phoneEntry.isClient = true
				}
				this.clientPhoneNumbers.push(phoneEntry)
			}
			this.profileForm.get('client_phone_e164').setValue(null)
			this.formatPhone('clientDialingCode', 'client_phone_e164')
		}
	}

	public addSupPhone() {
		if (this.supPhoneValid) {
			if (this.supervisorPhoneNumbers.length >= this.supPhoneLimit) {
				this.coreSrvc.notifySrvc.notify(
					'info',
					'Limit Reached',
					`You have reached the limit of ${this.supPhoneLimit} supervisor phone numbers for this notification profile.`,
				)
				this.profileForm.get('supervisor_phone_e164').setValue(null)
				this.formatPhone('supDialingCode', 'supervisor_phone_e164')
				return false
			}
			const phoneNumber = this.profileForm.get('supervisor_phone_e164').value
			const countryCode = this.profileForm.get('supDialingCode').value as CountryCode
			const phoneE164 = PhoneHelper.formatPhoneE164(countryCode, phoneNumber)
			const existingEntry = this.supervisorPhoneNumbers.find((sp) => sp.number === phoneE164)
			if (!existingEntry) {
				const phoneEntry = new NotificationProfileSupervisorPhoneEntry()
				phoneEntry.number = phoneE164
				const user = this.coreSrvc.dbSrvc.settingSrvc.getUserForE164Number(phoneE164)
				if (user) {
					phoneEntry.name = user.first_name + ' ' + user.last_name
					phoneEntry.isUser = true
				}
				this.supervisorPhoneNumbers.push(phoneEntry)
			}
			this.profileForm.get('supervisor_phone_e164').setValue(null)
			this.formatPhone('supDialingCode', 'supervisor_phone_e164')
		}
		return true
	}

	public removePhone(type: string, idx: number) {
		switch (type) {
			case 'supervisor':
				this.supervisorPhoneNumbers.splice(idx, 1)
				break
			case 'client':
				this.clientPhoneNumbers.splice(idx, 1)
				break
		}
	}

	private formatDurationForUpdateRecord(input: string): string {
		if (!input) {
			return null
		}
		const intValue = parseInt(input, 10)

		const absValue = Math.abs(intValue)

		if (!intValue) {
			return null
		}

		return moment.duration(absValue, 'minutes').toISOString()
	}

	/// - Migration

	public isFormValid(): boolean {
		const isProfileFormValid = this.profileForm.valid

		// Client Contact Info
		const clientPhoneInput = this.profileForm.get('client_phone_e164').value
		const isClientPhoneValid = clientPhoneInput ? this.clientPhoneValid : true
		const clientEmailInput = this.newClientEmailInput
		const isClientEmailValid = clientEmailInput ? Helper.isValidEmail(clientEmailInput) : true

		// Supervisor Contact Info
		const supPhoneInput = this.profileForm.get('supervisor_phone_e164').value
		const isSupPhoneValid = supPhoneInput ? this.supPhoneValid : true
		const supEmailInput = this.newSupEmailInput
		const isSupEmailValid = supEmailInput ? Helper.isValidEmail(supEmailInput) : true

		return isProfileFormValid && isClientPhoneValid && isClientEmailValid && isSupPhoneValid && isSupEmailValid
	}

	private submitForm() {
		// Guard against double submission
		if (this.isUpdating) return
		// FormHelper.trimOnlyWhitespace(this.profileForm)

		this.addClientPhone()
		this.addClientEmail(false)

		// Check sup phone numbers
		if (!this.addSupPhone()) return
		if (this.supervisorPhoneNumbers.length > this.supPhoneLimit) {
			this.coreSrvc.notifySrvc.notify(
				'info',
				'Limit Reached',
				`You have reached the limit of ${this.supPhoneLimit} supervisor phone numbers for this notification profile.`,
			)
			return
		}

		this.addSupEmail(false)
		this.cd.detectChanges()
		// this.addSupPhone()
		if (this.areSubmissionRequirementsMet()) {
			const record = this.makeUpdateRecord()
			this.isUpdating = true
			this.cd.detectChanges()
			log('Update Record', record)
			if (this.isNew) {
				this.coreSrvc.dbSrvc.insertRecord('notification_profile', record).then((insertSuccess) => {
					if (insertSuccess) {
						this.formSubmissionComplete()
						// this.coreSrvc.dbSrvc.readTable('notification_profile').then(readSuccess => {
						// })
					}
				})
			} else {
				this.coreSrvc.dbSrvc.updateRecord('notification_profile', record).then((updateSuccess) => {
					if (updateSuccess) {
						this.formSubmissionComplete()
						// this.coreSrvc.dbSrvc.readTable('notification_profile').then(readSuccess => {
						// })
					}
				})
			}
		}
	}

	public formSubmissionComplete() {
		this.profileUpdated.emit(true)
		this.parentSubmitAction()
	}

	public selectOnFocus(event: any): void {
		// 'event.target' refers to the DOM element that triggered the event
		const input = $(event.target)
		input.trigger('select')
	}

	private areSubmissionRequirementsMet(): boolean {
		const form = this.profileForm.value
		const profileName: string = form.name

		// Check for profile name
		if (!profileName) {
			alert('A profile name is required.')
			return false
		} else {
			const trimmedName = profileName.trim()
			const existingProfile = this.coreSrvc.dbSrvc.npSrvc.getProfileByName(trimmedName)
			if ((this.isNew && existingProfile) || (existingProfile && existingProfile?.id !== form.id)) {
				alert(`The 'Profile Name' you selected is already in use. Please choose another name.`)
				return false
			}
		}

		// Notify supervisor for early check in
		const shouldNotifySupForEarlyCheckIn = !!form.call_supervisor_early_in || !!form.sms_supervisor_early_in || !!form.email_supervisor_early_in
		if (shouldNotifySupForEarlyCheckIn) {
			const isValid = !!form.sup_notification_early_in_min_time
			if (!isValid) {
				alert(
					`'Notify supervisor when employee checks in early' requires that you specify the number of minutes which is considered an early check in.`,
				)
				return false
			}
		}

		// Call employee for late check in
		if (form.call_employee_late_in) {
			const isValid = !!form.emp_notification_delay_late_in
			if (!isValid) {
				alert(
					`'Call employee for late check in' requires that you specify how many minutes should elapse before employee is considered late. You may also specify how often they are notified and for how long these notifications repeat but these values are not required.`,
				)
				return false
			}
		}

		// Call employee for late check out
		if (form.call_employee_late_out) {
			const isValid = !!form.emp_notification_delay_late_out
			if (!isValid) {
				alert(
					`'Call employee for late check out' requires that you specify how many minutes should elapse before employee is considered late. You may also specify how often they are notified and for how long these notifications repeat but these values are not required.`,
				)
				return false
			}
		}

		// Call employee for late checkpoint
		if (form.call_employee_checkpoint) {
			const isValid = !!form.emp_notification_delay_checkpoint
			if (!isValid) {
				alert(`'Call employee for late checkpoint' requires that you specify how many minutes should elapse before employee is considered late.`)
				return false
			}
		}

		// Notify supervisor for late check in
		const shouldNotifySupForLateCheckIn = !!form.call_supervisor_late_in || !!form.email_supervisor_late_in || !!form.sms_supervisor_late_in
		if (shouldNotifySupForLateCheckIn) {
			const isValid = !!form.sup_notification_delay_late_in
			if (!isValid) {
				alert(
					`'Notify supervisor when employee is late checking in' requires that you specify how many minutes should elapse before employee is considered late. You may also specify how often they are notified and for how long these notifications repeat but these values are not required.`,
				)
				return false
			}
		}

		// Notify supervisor for early check out
		const shouldNotifySupForEarlyCheckOut =
			!!form.call_supervisor_on_early_out || !!form.email_supervisor_on_early_out || !!form.sms_supervisor_on_early_out
		if (shouldNotifySupForEarlyCheckOut) {
			const isValid = !!form.sup_notification_early_out_min_time
			if (!isValid) {
				alert(
					`'Notify supervisor when employee checks out early' requires that you specify the number of minutes which is considered an early check out.`,
				)
				return false
			}
		}

		// Notify supervisor for late check out
		const shouldNotifySupForLateCheckOut = !!form.call_supervisor_late_out || !!form.email_supervisor_late_out || !!form.sms_supervisor_late_out
		if (shouldNotifySupForLateCheckOut) {
			const isValid = !!form.sup_notification_delay_late_out
			if (!isValid) {
				alert(
					`'Notify supervisor when employee checks out late' requires that you specify how many minutes should elapse before employee is considered late. You may also specify how often they are notified and for how long these notifications repeat but these values are not required.`,
				)
			}
		}

		// Notify supervisor for GPS issue
		const shouldNotifySupForGpsIssue = !!form.call_sup_on_gps_issue || !!form.email_sup_on_gps_issue || !!form.sms_sup_on_gps_issue
		if (shouldNotifySupForGpsIssue) {
			const isValid = !!form.sup_notification_distance_offsite_gps || !!form.sup_notification_time_late_gps
			if (!isValid) {
				alert(
					`'Notify supervisor when employee has a GPS issue' requires either a time interval or distance range be set to indicate when a GPS issue should trigger a notification.`,
				)
				return false
			}
		}

		// Notify supervisor for late checkpoint
		const shouldNotifySupForLateCheckpoint =
			!!form.call_supervisor_checkpoint || !!form.email_supervisor_checkpoint || !!form.sms_supervisor_checkpoint
		if (shouldNotifySupForLateCheckpoint) {
			const isValid = !!form.sup_notification_delay_checkpoint
			if (!isValid) {
				alert(
					`'Notify supervisor when employee is late sending checkpoint' requires that you specify how many minutes should elapse before employee is considered late.`,
				)
				return false
			}
		}

		// Check for when a client phone is required
		if (this.isClientPhoneNumberRequired) {
			const isValid = !!form.client_phone_e164 || this.clientPhoneNumbers.length > 0
			if (!isValid) {
				alert('A client phone number is required.')
				return false
			}
		}

		// Check for when a client emial is required
		if (this.isClientEmailRequired) {
			const isValid = this.clientEmails.length > 0 || (this.newClientEmailInput && this.isEmailInputValid('client'))
			if (!isValid) {
				alert('A client email address is required.')
				return false
			}
		}

		// Check for when a supervisor phone is required
		if (this.isSupervisorPhoneNumberRequired) {
			const isValid = !!form.supervisor_phone_e164 || this.supervisorPhoneNumbers.length > 0
			if (!isValid) {
				alert('A supervisor phone number is required.')
				return false
			}
		}

		// Check for when a supervisor emial is required
		if (this.isSupervisorEmailRequired) {
			const isValid = this.supervisorEmails.length > 0 || (this.newSupEmailInput && this.isEmailInputValid('supervisor'))
			if (!isValid) {
				alert('A supervisor email address is required.')
				return false
			}
		}

		// All systems go
		return true
	}

	get isClientEmailRequired(): boolean {
		if (!this.profileForm) {
			return false
		}
		const form = this.profileForm.value
		return !!form.email_client_on_incident
	}

	get isClientPhoneNumberRequired(): boolean {
		if (!this.profileForm) {
			return false
		}
		const form = this.profileForm.value
		return !!form.call_client_on_incident || !!form.sms_client_on_incident
	}

	get isSupervisorEmailRequired(): boolean {
		if (!this.profileForm) {
			return false
		}
		const form = this.profileForm.value
		return (
			// Notify supervisor for early check in
			!!form.email_supervisor_early_in ||
			// Notify supervisor for check in
			!!form.email_supervisor_at_check_in ||
			// Notify supervisor for late check in
			!!form.email_supervisor_late_in ||
			// Notify supervisor for early check out
			!!form.email_supervisor_on_early_out ||
			// Notify supervisor for late check out
			!!form.email_supervisor_late_out ||
			// Notify supervisor on GPS issue
			!!form.email_sup_on_gps_issue ||
			// Notify supervisor for late checkpoint
			!!form.email_supervisor_checkpoint ||
			// Notify supervisor for missed dispatch checkpoint
			!!form.email_supervisor_dispatch_cp_fail ||
			// Notify supervisor for employee unschedule job
			!!form.email_supervisor_on_unscheduled_in ||
			// Notify supervisor for incident report filed
			!!form.email_supervisor_on_incident ||
			// Notify supervisor on QR code validation failed
			!!form.email_supervisor_qrfail_inout
		)
	}

	get isSupervisorPhoneNumberRequired(): boolean {
		if (!this.profileForm) {
			return false
		}
		const form = this.profileForm.value
		return (
			// Notify supervisor for early check in
			!!form.call_supervisor_early_in ||
			!!form.sms_supervisor_early_in ||
			// Notify supervisor for check in
			!!form.call_supervisor_at_check_in ||
			!!form.sms_supervisor_at_check_in ||
			// Notify supervisor for late check in
			!!form.call_supervisor_late_in ||
			!!form.sms_supervisor_late_in ||
			// Notify supervisor for early check out
			!!form.call_supervisor_on_early_out ||
			!!form.sms_supervisor_on_early_out ||
			// Notify supervisor for late check out
			!!form.call_supervisor_late_out ||
			!!form.sms_supervisor_late_out ||
			// Notify supervisor on GPS issue
			!!form.call_sup_on_gps_issue ||
			!!form.sms_sup_on_gps_issue ||
			// Notify supervisor for late checkpoint
			!!form.call_supervisor_checkpoint ||
			!!form.sms_supervisor_checkpoint ||
			// Notify supervisor for missed dispatch checkpoint
			!!form.call_supervisor_dispatch_cp_fail ||
			!!form.sms_supervisor_dispatch_cp_fail ||
			// Notify supervisor for employee unschedule job
			!!form.call_supervisor_on_unscheduled_in ||
			!!form.sms_supervisor_on_unscheduled_in ||
			// Notify supervisor for incident report filed
			!!form.call_supervisor_on_incident ||
			!!form.sms_supervisor_on_incident ||
			// Notify supervisor on QR code validation failed
			!!form.call_supervisor_qrfail_inout ||
			!!form.sms_supervisor_qrfail_inout
		)
	}

	private resetUnusedNestedFlags(record: NotificationProfile) {
		// Reset nested flags when no parent is selected
		if (!record.call_supervisor_late_in && !record.sms_supervisor_late_in && !record.email_supervisor_late_in) {
			record.notify_on_clear = false
		}
	}

	private makeUpdateRecord(): NotificationProfile {
		const form = this.profileForm.value
		const record = new NotificationProfile(form)
		this.resetUnusedNestedFlags(record)

		record.owner_id = form.owner_id
		record.name = form.name

		record.emp_notification_preshift_interval = form.emp_notification_preshift_interval // No need to format from enumerated list
		record.emp_notification_delay_checkpoint = this.formatDurationForUpdateRecord(form.emp_notification_delay_checkpoint)
		record.emp_notification_delay_late_in = this.formatDurationForUpdateRecord(form.emp_notification_delay_late_in)
		record.emp_notification_delay_late_out = this.formatDurationForUpdateRecord(form.emp_notification_delay_late_out)
		record.emp_notification_duration_late_in = this.formatDurationForUpdateRecord(form.emp_notification_duration_late_in)
		record.emp_notification_duration_late_out = this.formatDurationForUpdateRecord(form.emp_notification_duration_late_out)
		record.emp_notification_interval_late_in = this.formatDurationForUpdateRecord(form.emp_notification_interval_late_in)
		record.emp_notification_interval_late_out = this.formatDurationForUpdateRecord(form.emp_notification_interval_late_out)

		record.sup_notification_delay_checkpoint = this.formatDurationForUpdateRecord(form.sup_notification_delay_checkpoint)
		record.sup_notification_delay_late_in = this.formatDurationForUpdateRecord(form.sup_notification_delay_late_in)
		record.sup_notification_delay_late_out = this.formatDurationForUpdateRecord(form.sup_notification_delay_late_out)
		record.sup_notification_duration_late_in = this.formatDurationForUpdateRecord(form.sup_notification_duration_late_in)
		record.sup_notification_duration_late_out = this.formatDurationForUpdateRecord(form.sup_notification_duration_late_out)
		record.sup_notification_interval_late_in = this.formatDurationForUpdateRecord(form.sup_notification_interval_late_in)
		record.sup_notification_interval_late_out = this.formatDurationForUpdateRecord(form.sup_notification_interval_late_out)

		record.sup_notification_time_late_gps = this.formatDurationForUpdateRecord(form.sup_notification_time_late_gps)

		record.sup_notification_early_in_min_time = this.formatDurationForUpdateRecord(form.sup_notification_early_in_min_time)
		record.sup_notification_early_out_min_time = this.formatDurationForUpdateRecord(form.sup_notification_early_out_min_time)
		record.checkpoint_frequency = this.formatDurationForUpdateRecord(form.checkpoint_frequency)

		record.client_phone_e164 = this.clientPhoneNumbers.map((e) => e.number).join(',')
		record.client_email = this.clientEmails.map((e) => e.email).join(',')

		// const supCountryCode = this.profileForm.get('supDialingCode').value
		record.supervisor_phone_e164 = this.supervisorPhoneNumbers.map((c) => c.number).join(',')
		record.supervisor_email = this.supervisorEmails.map((e) => e.email).join(',')

		record.job_ids = this.profileForm.get('job_ids').value

		return record
	}
}
