import { DatabaseService } from '@app/services'
import { PrimeIcons } from 'primeng/api'
import { Checkpoint } from './checkpoint'
import { Incident } from './incident'
import { JobSiteRecord } from './jobsite'
import { ShiftSummaryReportPublicResponseConfig, ShiftSummaryReportPublicResponseData } from './report-renderer'
import { IUserReportFormat, ReportGlobals, TransactionMetaDataForReport, UserReportIndustryType, UserReportMetaData } from './report-user'
import { TransactionLogRecord } from './transaction'
import { ImageFile } from './image'

export class ShiftSummaryReport {
	metaData: UserReportMetaData
	reportData: ShiftSummaryReportData

	constructor(reportData: ShiftSummaryReportData) {
		const version = reportData.version
		const format = reportData.format
		this.metaData = new UserReportMetaData(version, format)
		this.reportData = reportData
	}

	static buildFromTransaction(
		dbSrvc: DatabaseService,
		trans: TransactionLogRecord,
		checkpoints: Array<Checkpoint>,
		shiftReports: Array<Incident>,
	): ShiftSummaryReport {
		const format: IUserReportFormat = {
			reportType: 'SHIFTSUMMARY',
			industryType: dbSrvc.settingSrvc.getCompany().industry as UserReportIndustryType,
		}
		const company = dbSrvc.settingSrvc.getCompany()
		const delay = company.gps_allowed_delay
		const countryCode = company.country_iso
		const format12Hours = company.getCompanyAdminPrefs().globalFormatTime12Hours
		const reportDataConfig = {
			format: format,
			format12Hours: format12Hours,
			countryCode: countryCode,
			gpsAllowedDelay: delay,
			transactionMetaData: trans.getReportMetaData(),
			jobSite: dbSrvc.siteSrvc.getJobSiteById(trans.jobsite_id) ?? null,
			transaction: trans,
			checkpoints: checkpoints,
			shiftReports: shiftReports,
		}
		const reportData = new ShiftSummaryReportData(reportDataConfig)
		const report = new ShiftSummaryReport(reportData)
		return report
	}

	static buildFromPublicData(
		configData: ShiftSummaryReportPublicResponseConfig,
		responseData: ShiftSummaryReportPublicResponseData,
	): ShiftSummaryReport {
		const format: IUserReportFormat = {
			reportType: 'SHIFTSUMMARY',
			industryType: configData.industryType as UserReportIndustryType,
		}
		const delay = configData.gpsAllowedDelay
		const countryCode = configData.countryCode
		const format12Hours = configData.formatTime12Hour
		const reportDataConfig = {
			format: format,
			format12Hours: format12Hours,
			countryCode: countryCode,
			gpsAllowedDelay: delay,
			transactionMetaData: responseData.transaction.getReportMetaData(),
			jobSite: responseData.jobSite,
			transaction: responseData.transaction,
			checkpoints: responseData.checkpoints,
			shiftReports: responseData.shiftReports,
		}
		const reportData = new ShiftSummaryReportData(reportDataConfig)
		const report = new ShiftSummaryReport(reportData)
		return report
	}
}

export class ShiftSummaryReportData {
	version = 1
	format: IUserReportFormat

	locale = 'en'
	countryCode = null // Company Region
	gpsAllowedDelay: string // Duration for flagging slow responses

	displayPrefs = {
		format12Hour: ReportGlobals.displayPrefs.format12Hour,
	}

	transactionMetaData: TransactionMetaDataForReport

	jobSite: JobSiteRecord
	transaction: TransactionLogRecord
	checkpoints: Array<Checkpoint>
	shiftReports: Array<Incident>

	constructor(data?: any) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
	}
}

export class ShiftSummaryMapDataSource {
	format12Hours = true
	gpsAllowedDelay: string // from company.allowed_delay
	countryCode: string = 'US'
	jobSite: JobSiteRecord
	transaction: TransactionLogRecord
	checkpoints: Array<Checkpoint>
}

export type MapEventType = 'IN' | 'OUT' | 'CP'

export class MapEvent {
	type: 'IN' | 'OUT' | 'CP'
	index: number // Timeline event index
	cpIndex: number // Checkpoint Index
	cpId: number // Checkpoint ID
	date: string
	icon: PrimeIcons = PrimeIcons.MAP_MARKER
	color: string = null
	image: string = null // The map marker image
	images: Array<ImageFile> = [] // The associated image files for check in/out or checkpoint
	hasGps = false

	constructor(index: number, type: MapEventType, date: string, image: string, images: Array<ImageFile>) {
		this.index = index
		this.type = type
		this.date = date
		this.image = image
		this.images = images
	}
}

export class ShiftSummaryReportEventItem {
	type: 'IN' | 'OUT' | 'CP'
	cp?: Checkpoint

	constructor(type: 'IN' | 'OUT' | 'CP', cp?: Checkpoint) {
		this.type = type
		this.cp = cp
	}
}
