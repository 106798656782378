import { AfterViewInit, Component, Input, OnInit } from '@angular/core'
import { log } from '@app/helpers'
import { DialogManager, VisualizationPrefs, VizHealthCenterManager, VizHealthCenterSectionAlert, VizHealthHelper } from '@app/models'
import { CoreService, VisualizationService } from '@app/services'

@Component({
    selector: 'app-visualization-prefs',
    templateUrl: './visualization-prefs.component.html',
    styleUrl: './visualization-prefs.component.scss',
    standalone: false
})
export class VisualizationPrefsComponent implements OnInit, AfterViewInit {
	isUpdating = false
	manager = new VizHealthCenterManager()
	defaultManager = new VizHealthCenterManager()

	searchText = ''
	showDetails = false

	@Input() dialogManager: DialogManager

	constructor(private coreSrvc: CoreService) {
		this.defaultManager.setupManager()
	}

	get vizSrvc(): VisualizationService {
		return this.coreSrvc.dbSrvc.vizSrvc
	}
	// get manager(): VizHealthCenterManager {
	// 	return this.coreSrvc.dbSrvc.vizSrvc.healthCenterManager
	// }
	ngOnInit(): void {
		const prefs = this.coreSrvc.dbSrvc.settingSrvc.getHealthCenterPrefsForCompany()
		log('PREFS', prefs)
		this.manager.setupManager()
		this.manager.configureAlertContributorStatusFromPrefs(prefs)
		this.manager.collapseAllItems()
	}

	ngAfterViewInit() {
		this.dialogManager.submitBtnAction = () => this.submit()
	}

	submit() {
		if (this.isUpdating) return
		this.isUpdating = true

		const healthPrefs = new VisualizationPrefs()

		this.manager.healthCenterCategories.forEach((category) => {
			category.sections.forEach((section) => {
				section.alerts.forEach((alert) => {
					const defaultAlert = this.defaultManager.getAlertForKey(alert.key)
					if (alert.enabled !== defaultAlert.enabled) {
						healthPrefs.enabled[alert.key] = alert.enabled
					}
					if (alert.contributor !== defaultAlert.contributor) {
						// If it's not enabled, it can't be a contributor so set that to false as well
						healthPrefs.contributors[alert.key] = alert.enabled ? alert.contributor : false
					}
				})
			})
		})

		this.coreSrvc.dbSrvc.vizSrvc.healthCenterManager = this.manager

		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		company.analytics_prefs_json = JSON.stringify(healthPrefs)
		this.coreSrvc.dbSrvc.updateRecord('company', company).then(() => {
			this.vizSrvc.fetchHealthCenterData().then(() => {
				const healthRecords = this.vizSrvc.getHealthCenterActivityLogRecords()
				this.vizSrvc.healthCenterManager.updateScore(healthRecords)
				this.dialogManager.isDialogVisible = false
				this.isUpdating = false
			})
		})
	}

	performSearch(searchText: string) {
		const lcText = (searchText ?? '').toLowerCase()
		this.searchText = lcText
		this.manager.applySearchFilter(lcText)
		if (lcText) {
			this.manager.expandAllItems()
		} else {
			this.manager.collapseAllItems()
		}
	}

	toggleEnableStatus(alert: VizHealthCenterSectionAlert) {
		// Get the default alert contributor status and assign that when the alert is enabled
		const defaultAlert = this.defaultManager.getAlertForKey(alert.key)
		if (alert.enabled) {
			alert.contributor = defaultAlert.contributor
		} else {
			alert.contributor = false
		}
	}

	toggleExpansion(isExpanded: boolean) {
		if (isExpanded) {
			this.manager.expandAllItems()
		} else {
			this.manager.collapseAllItems()
		}
	}

	resetToDefaults() {
		this.manager = new VizHealthCenterManager()
		this.manager.setupManager()
		this.manager.collapseAllItems()
		this.coreSrvc.notifySrvc.notify('info', 'Prefs Reset', 'Preferences have been reset. Click save to persist these changes.', 7)
	}

	alertMatchesDefault(alert: VizHealthCenterSectionAlert) {
		return alert.contributor === this.defaultManager.getAlertForKey(alert.key).contributor
	}

	alertDefaultContributorStatus(alert: VizHealthCenterSectionAlert) {
		return this.defaultManager.getAlertForKey(alert.key).contributor
	}
}
