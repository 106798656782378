import { Component, OnInit, EventEmitter, Output, ChangeDetectorRef, AfterViewInit, OnDestroy, Input, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { environment } from '@env/environment'

import {
	AdminPrefs,
	CrudAction,
	ListItemEntry,
	ScheduleEntry,
	ScheduleListItemEntry,
	ScheduleJobListItem,
	ScheduleOptions,
	ScheduleStatusType,
	ScheduleStatusFilter,
	ScheduleViewManager,
	SectionSwitcherConfig,
	TableFilterManager,
	TableFilterButton,
	ScheduleViewSeriesSubSectionType,
	HelpDialogMessage,
	GenericEmailListManager,
	PendingScheduleApprovalAction,
	DialogManager,
} from '@app/models'

import { CoreService, NotificationsService } from '@app/services'

import { AccessHelper } from '@app/helpers/access'
import { DeviceDetectorService } from 'ngx-device-detector'

import { log } from '@app/helpers'
import { Subject, Subscription } from 'rxjs'
import { SelectItem } from 'primeng/api'
import { MultiSelect } from 'primeng/multiselect'

import _ from 'lodash'
import moment from 'moment-timezone'

@Component({
    selector: 'app-scheduler-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    standalone: false
})
export class SchedulerListComponent implements OnInit, OnDestroy, AfterViewInit {
	environment = environment

	userPrefs: AdminPrefs
	accessHelper: AccessHelper

	isProcessingData = false
	isDataLoaded = false

	empSearch = ''
	jobSearch = ''
	shiftSearch = ''

	manager: ScheduleViewManager

	jobList: Array<ScheduleJobListItem> = []
	filteredJobList: Array<ScheduleJobListItem> = []

	tagOptions: Array<SelectItem> = []

	filteredChangeRequestId: number = null

	isNew = false
	arePendingSchedulesEnabled = false

	isCalculatingNextUp = false
	nextUpQueue = new Subject<Array<ScheduleListItemEntry>>()
	removeExpiredRecordsForW2W = false

	queueProcessing = {
		isProcessingEmployees: false,
		isProcessingJobs: false,
		isFinishedProcessingEmployees: false,
		isFinishedProcessingJobs: false,
	}

	auditAction = {
		recordId: null,
		resource: 'schedule_recur',
		header: 'Audit History',
		footer: 'Audit history for schedules',
		showDialog: false,
	}

	batchEditAction = { recordId: null as number }
	batchEditDialogManager = new DialogManager('scheduleBatchEditManager')

	approvalAction = new PendingScheduleApprovalAction()

	selectedEntryForChangeList = { recordId: null }

	selectedEntry: ListItemEntry = { empName: '', jobName: '', entry: null, rule: null }
	// showEditEntryModal = false
	defaultJobIdForEdit = null

	deleteInProgress = false
	showDeleteScheduleDialog = false
	blockedForDeletion = { schedRecurId: null }

	useExactMatch = true // Always on. No OR filtering

	get tagFilterMethodLabel(): string {
		return this.manager.series.tagFilterMethod === 'AND' ? 'Match All' : 'Match Any'
	}
	get isBatchingEnabled(): boolean {
		return this.manager.series.isBatchingEnabled
	}

	get statusFilter(): ScheduleStatusFilter {
		return this.coreSrvc.dbSrvc.schedulerSrvc.statusFilter
	}

	dayOptions = ScheduleOptions.dayOptions // Migrated

	selectedItemEntry: ScheduleListItemEntry
	showScheduleConflictsDialog = false

	get currentDayViewDate(): Date {
		return this.coreSrvc.dbSrvc.schedulerSrvc.currentDayViewDate
	}
	set currentDayViewDate(date: Date) {
		this.coreSrvc.dbSrvc.schedulerSrvc.currentDayViewDate = date
	}

	showWeekView = false
	get devDetect(): DeviceDetectorService {
		return this.coreSrvc.devDetect
	}

	get isViewingPending(): boolean {
		return this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.series.subSection === 'PENDING'
	}

	get viewManager(): ScheduleViewManager {
		return this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager
	}

	get hasPendingSchedules(): boolean {
		return this.coreSrvc.dbSrvc.schedulerSrvc.getPendingSchedules().length > 0
		// return this.jobList.filter((li) => li.hasPending).length > 0
	}

	@Input() switcherConfig: SectionSwitcherConfig
	@Output() listUpdated = new EventEmitter<boolean>()
	// @Output() dayViewProcessingComplete = new EventEmitter<boolean>() // Deprecated 20231111 - moved to schedule service
	@ViewChild('tagFilterMultiSelect') tagFilterMultiSelect: MultiSelect

	private subs = new Subscription()

	seriesTableFilterManager = new TableFilterManager()

	constructor(
		private cd: ChangeDetectorRef,
		private route: ActivatedRoute,
		private router: Router,
		private coreSrvc: CoreService,
		private noteSrvc: NotificationsService,
	) {
		this.manager = this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager

		this.setupTagOptions()
		this.setupAccessPermissions()
		this.setupTableFilterManager()
		this.arrangeDayOptionsForWkst()

		const userPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		this.arePendingSchedulesEnabled = userPrefs.schedulerEnablePendingQueue

		// Setup next up que processor
		this.subs.add(
			this.nextUpQueue.subscribe((queue: Array<ScheduleListItemEntry>) => {
				this.processNextUpQueue(queue)
			}),
		)

		this.subs.add(
			this.coreSrvc.dbSrvc.schedulerSrvc.dayViewToggleEvent.subscribe(() => {
				this.filterForDayViewDate(this.currentDayViewDate)
			}),
		)

		// When shift view clicks a day header, we need to recalculate the day view filter if in day mode
		this.subs.add(
			this.coreSrvc.dbSrvc.schedulerSrvc.recalculateDayViewFilter.subscribe(() => {
				if (this.currentDayViewDate) {
					this.filterForDayViewDate(this.currentDayViewDate)
				}
			}),
		)

		// Add listener for series sub section change
		this.subs.add(
			this.viewManager.seriesViewSubSectionChanged.subscribe((subSection) => {
				this.filterForSubSection(subSection)
				this.seriesTableFilterManager.setSelectedBtnById(subSection)
			}),
		)

		this.loadData()
	}

	ngOnInit() {
		this.setupFilter()
	}
	ngAfterViewInit() {}
	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	private setupTableFilterManager() {
		const currentBtn = new TableFilterButton()
		currentBtn.id = 'CURRENT'
		currentBtn.label = 'Current'
		currentBtn.count = null
		this.seriesTableFilterManager.buttons.push(currentBtn)

		const pendingBtn = new TableFilterButton()
		pendingBtn.id = 'PENDING'
		pendingBtn.label = 'Pending'
		pendingBtn.count = null
		this.seriesTableFilterManager.buttons.push(pendingBtn)

		const viewStateId = this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.series.subSection
		this.seriesTableFilterManager.setSelectedBtnById(viewStateId)
	}

	changeSubsection(btn: TableFilterButton) {
		const subSection = btn.id as ScheduleViewSeriesSubSectionType
		this.setSubSection(subSection)
	}

	setSubSection(subSection: ScheduleViewSeriesSubSectionType) {
		this.filterForSubSection(subSection)
		this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.series.subSection = subSection
	}

	private setViewStateForPending() {
		this.coreSrvc.dbSrvc.schedulerSrvc.statusFilter.setFilterStatus('ALL')
		this.resetDayViewFilter()
	}

	private setupTagOptions() {
		this.tagOptions = this.coreSrvc.dbSrvc.schedulerSrvc.getTagLabels().map((label) => {
			return { label: label.toUpperCase(), value: label }
		})
		log('Tag Options', this.tagOptions)
	}

	public updateTagMatchMethod(method: 'AND' | 'OR', tsoPanel: any) {
		this.manager.series.tagFilterMethod = method
		this.saveViewPrefs()
		this.loadData()
		tsoPanel.hide()
	}
	public handleTagSelectionChange(event) {
		this.loadData()
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'schedule')
		this.accessHelper.updateSupervisorIds()
	}
	canPerformAction(action: CrudAction, isMyRecord: boolean): boolean {
		return this.accessHelper.canPerformAction(action, isMyRecord)
	}

	processNextUpQueue(array: Array<ScheduleListItemEntry>) {
		for (let idx = 0; idx < 100; idx++) {
			if (array.length > 0) {
				const entry = array.shift()
				entry.info.calculateNextUpInformation()
			} else {
				break
			}
		}
		if (array.length > 0) {
			setTimeout(() => {
				this.nextUpQueue.next(array)
			}, 100)
		} else {
			// Finished processing next up
			if (this.queueProcessing.isProcessingJobs) {
				// We just finished processing jobs
				if (this.removeExpiredRecordsForW2W) this.removeExpiredForW2W()

				this.coreSrvc.dbSrvc.schedulerSrvc.isNextUpProcessing = false
				// log('Finished processing jobs next up', this.queueProcessing)
				this.queueProcessing.isProcessingJobs = false
				this.queueProcessing.isFinishedProcessingJobs = true
				this.queueProcessing.isFinishedProcessingEmployees = true
				this.statusFilter.available = true
				// Update status filter
				this.coreSrvc.zone.run(() => {
					this.filterForStatusView(this.statusFilter)
					this.searchList(this.manager.currentSearchText)
					this.cd.markForCheck()
					this.coreSrvc.displaySrvc.enableAllTooltips()
				})
			}
		}
	}

	get batchCount(): number {
		return this.coreSrvc.dbSrvc.schedulerSrvc.scheduleBatchUpdateList.length
	}
	get jobListCount() {
		return this.filteredJobList.filter((i) => i.visible).length
	}
	get shiftViewCount() {
		return this.coreSrvc.dbSrvc.schedulerSrvc.getSchedules().length
	}
	get changesCount() {
		return this.coreSrvc.dbSrvc.schedulerSrvc.getChangeRequests().filter((cr) => cr.request_status === 'PENDING').length
	}

	get selectedEntryEmpName(): string {
		return this.isNew ? 'New Schedule' : this.selectedEntry ? this.selectedEntry.empName : 'Unavailable'
	}
	get selectedEntryJobName(): string {
		return this.isNew ? 'Create a new schedule' : this.selectedEntry ? this.selectedEntry.jobName : 'No Job Selected'
	}
	get isSelectedEntryEmpActive(): boolean {
		const entry = this.selectedEntry as ScheduleListItemEntry
		return entry ? entry.emp?.active : true
	}

	get selectedEntryForEdit(): ScheduleEntry {
		if (this.isNew) {
			return null
		} else {
			const entryId = this.selectedEntry.entry.id
			const entry = this.coreSrvc.dbSrvc.schedulerSrvc.getScheduleForId(entryId)
			return entry
		}
	}
	get hasVisibleItems(): boolean {
		const currentList = this.manager.series.option
		// if (currentList === 'EMP') {
		// 	const empItems = this.filteredEmpList
		// 	for (const item of empItems) {
		// 		if (item.visible) {
		// 			return true
		// 		}
		// 	}
		// }
		if (currentList === 'JOB') {
			const jobItems = this.filteredJobList
			for (const item of jobItems) {
				if (item.visible) {
					return true
				}
			}
		}
		return false
	}

	get isShowingFilter(): boolean {
		return !!this.manager.currentSearchText && !!this.route.snapshot.params['filterField']
	}

	// Begin - Filtering for sub-sections

	get currentJobList(): Array<ScheduleJobListItem> {
		const currentFilter = this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.series.subSection
		if (currentFilter === 'CURRENT') {
			return this.filteredJobList.filter((li) => li.hasNonPending)
		} else {
			return this.filteredJobList.filter((li) => li.hasPending)
		}
	}

	filterForSubSection(subSection: 'CURRENT' | 'PENDING') {
		const list = this.jobList
		for (const job of list) {
			job.filterForSubSection(subSection)
		}
	}

	getItemEntriesForSubSection(entries: Array<ScheduleListItemEntry>) {
		const currentFilter = this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.series.subSection
		if (currentFilter === 'CURRENT') {
			return entries.filter((entry) => entry.visible && !entry.isPending)
		} else {
			return entries.filter((entry) => entry.visible && entry.isPending)
		}
	}
	// End - Filtering for sub-sections

	viewPendingSchedules(item: ScheduleJobListItem) {
		this.seriesTableFilterManager.setSelectedBtnById('PENDING')
		this.setSubSection('PENDING')
		setTimeout(() => {
			const elmnt = document.getElementById(item.elemId)
			if (elmnt) {
				elmnt.scrollIntoView({ behavior: 'smooth', block: 'center' })
				const selector = `#${item.elemId}`
				this.coreSrvc.displaySrvc.flashHtmlElementById(selector, 250)
				log('Gonna view this element', elmnt)
			}
		}, 100)
	}

	// If coming from a delete link for employee or job then setup search with that info
	setupFilter() {
		const filterField = this.route.snapshot.params['filterField'] || null
		const filterId = parseInt(this.route.snapshot.params['filterId'], 10) || null
		log('FilterField', filterField, 'FilterId', filterId)
		if (filterField && filterId) {
			this.useExactMatch = true
			this.manager.setCurrentView('SERIES')
			if (filterField === 'employee_id') {
				const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(filterId)
				if (emp) {
					this.manager.series.option = 'JOB'
					this.manager.series.searchText = emp.first + ' ' + emp.last
				}
			}
			if (filterField === 'job_id') {
				const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(filterId)
				if (job) {
					this.manager.series.option = 'JOB'
					this.manager.series.searchText = job.description
				}
			}
			this.manager.currentSearchText = this.manager.series.searchText
		}
	}

	arrangeDayOptionsForWkst() {
		const dayOptions = [...ScheduleOptions.dayOptions]
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const wkst = company.wkst || 0
		if (!wkst || wkst < 0 || wkst > 6) {
			this.dayOptions = dayOptions
			return
		}
		const comps = dayOptions.splice(wkst)
		const newDayOptions = [...comps, ...dayOptions]
		this.dayOptions = newDayOptions
	}

	showNoDataTip(): boolean {
		// if (this.empListCount === 0 && this.jobListCount === 0) {
		if (this.jobListCount === 0) {
			return true
		}
		return false
	}

	startProcessingData() {
		if (this.coreSrvc.dbSrvc.schedulerSrvc.count() > 100) {
			this.isProcessingData = true
		}
	}
	stopProcessingData() {
		this.isProcessingData = false
	}

	loadData() {
		this.coreSrvc.dbSrvc.readTable('schedule_recur').then(() => {
			this.coreSrvc.dbSrvc.schedulerSrvc.isNextUpProcessing = true
			this.coreSrvc.dbSrvc.schedulerSrvc.scheduleRecurDidFinishLoading.next(true)
			this.coreSrvc.zone.run(() => {
				this.coreSrvc.dbSrvc.schedulerSrvc.checkMultiScheduleStatus.next(true)
				this.setupTagOptions()
				this.updateUI()
			})
		})
	}

	updateUI() {
		// X2
		if (!this.hasPendingSchedules) {
			this.seriesTableFilterManager.setSelectedBtnById('CURRENT')
			this.setSubSection('CURRENT')
		}

		this.queueProcessing.isFinishedProcessingEmployees = false
		this.queueProcessing.isFinishedProcessingJobs = false
		this.statusFilter.available = false

		// Apply filters
		// this.loadEmpListItems()
		this.loadJobListItems()
		this.searchList(this.manager.series.searchText)
		if (this.currentDayViewDate) {
			this.filterForDayViewDate(this.currentDayViewDate)
		}
		const currentSubSection = this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.series.subSection
		this.filterForSubSection(currentSubSection)

		// Start calculating Next Up
		this.isCalculatingNextUp = true

		setTimeout(() => {
			// if (this.manager.series.option === 'EMP') {
			// 	this.calculateNextUp('EMP')
			// }
			if (this.manager.series.option === 'JOB') {
				this.calculateNextUp('JOB')
			}
			this.isDataLoaded = true
			this.isCalculatingNextUp = false
		}, 250) // 250 ms on timeout
	}

	calculateNextUp(list: 'EMP' | 'JOB') {
		log('Calculating Next Up for ', list)
		if (list === 'JOB') {
			const jobList = this.jobList.map((i) => i.entries)
			const flatList = _.flatMap(jobList)
			this.queueProcessing.isProcessingJobs = true
			this.nextUpQueue.next(flatList)
		}
	}

	toggleDayView() {
		this.coreSrvc.dbSrvc.schedulerSrvc.toggleDayView()
	}

	filterForDayViewDate(date: Date) {
		const jobList = this.jobList
		for (const job of jobList) {
			job.filterForDayViewDate(date)
		}
		setTimeout(() => {
			this.coreSrvc.dbSrvc.schedulerSrvc.dayViewProcessingComplete.next(true)
		}, 100)
	}

	resetDayViewFilter() {
		this.currentDayViewDate = null
		this.filterForDayViewDate(this.currentDayViewDate)
	}

	previousDay() {
		if (this.currentDayViewDate) {
			const currentMom = moment(this.currentDayViewDate).subtract(1, 'day')
			this.currentDayViewDate = currentMom.toDate()
			this.filterForDayViewDate(this.currentDayViewDate)
		}
	}

	nextDay() {
		if (this.currentDayViewDate) {
			const currentMom = moment(this.currentDayViewDate).add(1, 'day')
			this.currentDayViewDate = currentMom.toDate()
			this.filterForDayViewDate(this.currentDayViewDate)
		}
	}

	toggleStatus(entry: ListItemEntry) {
		log('Entry', entry)
		this.cd.markForCheck()
	}

	public switchToShiftViewForJob(id: number) {
		log('Switch to item', id)
		this.manager.shift.selectedJobIds = [id]
		// this.coreSrvc.prefSrvc.data.schedDefaultShiftView = id
		// this.coreSrvc.prefSrvc.save()
		scrollTo(0, 0)
		this.manager.performSearch('SHIFT', null)
		this.manager.setShiftViewOption('JOB')
		this.manager.setCurrentView('SHIFT')
		this.switcherConfig.setTab('SHIFT')
	}

	public showScheduleStatusFor(status: ScheduleStatusType) {
		// MODIFY AFTER MIGRATION
		this.statusFilter.setFilterStatus(status)
		this.filterForStatusView(this.statusFilter)
	}

	private removeExpiredForW2W() {
		log('Removing expired entries')
		const isW2WIntegrated = this.coreSrvc.dbSrvc.w2wSrvc.isW2WIntegrated()
		if (isW2WIntegrated) {
			for (const job of this.jobList) {
				job.entries = job.entries.filter((je) => je.hasNextDate)
			}
		}
	}

	filterForStatusView(filter: ScheduleStatusFilter) {
		// MODIFY AFTER MIGRATION
		// for (const emp of this.empList) {
		// 	emp.filterForScheduleStatus(filter)
		// }
		for (const job of this.jobList) {
			job.filterForScheduleStatus(filter)
		}
	}

	isFilterActive(): boolean {
		return this.useExactMatch
	}

	clearFilter() {
		this.statusFilter.setFilterStatus('ALL')
		this.viewManager.currentSearchText = null
		this.manager.series.searchText = null
		this.searchList(null)
		this.filterForStatusView(this.statusFilter)
	}

	getEmployeeIdsForTable(): Array<number> {
		const permissions = this.accessHelper.getPermissionsFor('schedule')
		const isRestricted = !permissions.access.read && permissions.owner.read
		const isManager = this.coreSrvc.dbSrvc.settingSrvc.isUserAManager()
		if (isRestricted) {
			// log('getEmployeeIdsForTable is restricted')
			return this.accessHelper.getAccessibleIdsFor('employee')
		}
		return this.coreSrvc.dbSrvc.schedulerSrvc.getSchedules().map((entry) => entry.employee_id)
	}

	filterEmpEntriesForJobAccess(itemEntries: Array<ScheduleEntry>): Array<ScheduleEntry> {
		const permissions = this.accessHelper.getPermissionsFor('schedule')
		const isRestricted = !permissions.access.read && permissions.owner.read
		if (isRestricted) {
			// log('filterEmpEntriesForJobAccess is restricted')
			return itemEntries.filter((e) => {
				if (e.employee_id === 0 || e.employee_id === 1) {
					return this.accessHelper.isMyRecord(e.job_id, 'job')
				} else {
					return this.accessHelper.isMyRecord(e.employee_id, 'employee') || this.accessHelper.isMyRecord(e.job_id, 'job')
				}
			})
		}
		return itemEntries
	}

	postErrorNotification(sched: ScheduleEntry) {
		const id = sched.id
		this.noteSrvc.notify(
			'error',
			'Record ID: ' + id,
			'Could not process a schedule log entry. Please notify support with the record ID number listed.',
		)
	}

	// Get available job ids based on permissions

	getJobIdsForTable(): Array<number> {
		const permissions = this.accessHelper.getPermissionsFor('schedule')
		if (!permissions.access.read && permissions.owner.read) {
			return this.accessHelper.getAccessibleIdsFor('job')
		}
		return this.coreSrvc.dbSrvc.schedulerSrvc.getSchedules().map((entry) => entry.job_id)
	}

	filterMainScheduleEntryList(itemEntries: Array<ScheduleEntry>): Array<ScheduleEntry> {
		let result = [...itemEntries]

		// Filter for enabled / disabled based on checkbox
		if (!this.manager.series.showDisabledSchedules) {
			result = result.filter((rec) => rec.enabled)
		}

		// Filter for tags and only match if all tags match. Need to check if selectedTags
		// exists because the multi-select migtht clear it out.

		const selectedTags = this.manager.series.selectedTags
		const tagFilterMethod = this.manager.series.tagFilterMethod
		if (selectedTags && selectedTags.length > 0) {
			if (tagFilterMethod === 'AND') {
				result = result.filter((rec) => {
					for (const tag of this.manager.series.selectedTags) {
						if (!rec.xtagContainer.getTagLabelsForFilter().includes(tag)) {
							return false
						}
					}
					return true
				})
			}
			if (tagFilterMethod === 'OR') {
				result = result.filter((rec) => {
					for (const tag of this.manager.series.selectedTags) {
						if (rec.xtagContainer.getTagLabelsForFilter().includes(tag)) {
							return true
						}
					}
					return false
				})
			}
		}

		// Filter based on permissions using job/employee ownership for supervisor
		const permissions = this.accessHelper.getPermissionsFor('schedule')
		if (!permissions.access.read && permissions.owner.read) {
			return result.filter((e) => {
				if (e.employee_id === 0 || e.employee_id === 1) {
					return this.accessHelper.isMyRecord(e.job_id, 'job')
				} else {
					return this.accessHelper.isMyRecord(e.employee_id, 'employee') || this.accessHelper.isMyRecord(e.job_id, 'job')
				}
			})
		}

		return result
	}

	loadJobListItems() {
		const jobList = []
		// const jobIds = this.getAccessibleJobIds()
		const jobIds = this.getJobIdsForTable()
		const uniq = Array.from(new Set(jobIds))

		uniq.forEach((jobId) => {
			const job = this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)
			const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(job.location_id)
			const siteTzZoneName = this.coreSrvc.dbSrvc.settingSrvc.getTimezoneZoneNameForId(site.timezone_id)
			if (job) {
				const listItem = new ScheduleJobListItem(job, site, siteTzZoneName)
				const scheduleEntries = this.coreSrvc.dbSrvc.schedulerSrvc.getEntriesForJobId(jobId)
				const filteredEntries = this.filterMainScheduleEntryList(scheduleEntries)
				filteredEntries.forEach((entry) => {
					const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(entry.employee_id)
					let empEntry
					try {
						empEntry = new ScheduleListItemEntry(this.coreSrvc.dbSrvc, emp, job, site, siteTzZoneName, entry, false)
					} catch (err) {
						console.log('ERROR creating schedule entry', entry)
					}
					if (empEntry) {
						listItem.entries.push(empEntry)
					}
				})
				if (listItem.entries.length > 0) {
					jobList.push(listItem)
				}
			}
		})
		const sortedList = _.sortBy(jobList, 'jobName')
		sortedList.forEach((entry) => entry.sortEntries())

		this.jobList = sortedList
		this.filteredJobList = [...sortedList]
	}

	showScheduleConflicts(entry: ScheduleListItemEntry) {
		this.selectedItemEntry = entry
		this.showScheduleConflictsDialog = true
	}

	viewTransactionsForSchedRecurId() {
		// #schedId:23933
		const id = this.blockedForDeletion.schedRecurId
		if (id) {
			this.coreSrvc.dbSrvc.tranSrvc.filterText = `#schedId:${id} #optr`
			this.router.navigate(['/admin/transactions'])
		}
	}

	auditRecord(item: ListItemEntry) {
		const recordId = item.entry.id
		this.auditAction.footer = 'Audit Series'
		this.auditAction.recordId = recordId
		this.auditAction.showDialog = true
	}

	canEditRecord(entry: ScheduleEntry): boolean {
		const empId = entry.employee_id
		const jobId = entry.job_id
		const isMyRecord = empId === 0 ? this.accessHelper.isMyRecord(jobId, 'job') : this.accessHelper.isMyRecord(empId, 'employee')
		return this.canPerformAction(CrudAction.update, isMyRecord)
	}

	editRecord(item: ListItemEntry) {
		this.coreSrvc.dbSrvc.schedulerSrvc.scheduleRecurEditRecordId.next(item.entry.id)
	}

	copyRecord(item: ListItemEntry) {
		this.coreSrvc.dbSrvc.schedulerSrvc.scheduleRecurCopyRecordId.next(item.entry.id)
	}

	canDeleteRecord(entry: ScheduleEntry): boolean {
		const empId = entry.employee_id
		const jobId = entry.job_id
		const isMyRecord = empId === 0 ? this.accessHelper.isMyRecord(jobId, 'job') : this.accessHelper.isMyRecord(empId, 'employee')
		return this.canPerformAction(CrudAction.delete, isMyRecord)
	}

	deleteRecord(item: ListItemEntry) {
		this.deleteInProgress = false

		if (!this.canDeleteRecord(item.entry)) {
			this.notifyOperationNotAuthorized()
			return
		}

		const schedRecurId = item.entry.id
		const openTrans = this.coreSrvc.dbSrvc.tranSrvc
			.getTransactions()
			.filter((t) => t.schedule_recur_id === schedRecurId && t.actual_start && !t.actual_end)

		// Check to see if there are open transaction for this schedule
		log('Open Transactions', openTrans)
		if (openTrans.length > 0) {
			// this.coreSrvc.notifySrvc.notify('error', 'Open Transactions', 'You must close any open transactions before deleting this schedule entry.')
			this.blockedForDeletion.schedRecurId = schedRecurId
		} else {
			this.blockedForDeletion.schedRecurId = null
		}

		log('ENTRY', item)
		const legacyId = item.entry.legacy_entry
		if (legacyId) {
			alert('Legacy entries cannot yet be edited in the new scheduler')
			return false
		} else {
			this.selectedEntry = item
			this.showDeleteScheduleDialog = true
		}
	}

	deleteScheduleConfirmed() {
		log('deleting', this.selectedEntry)
		const schedId = this.selectedEntry.entry.id
		const sched = this.coreSrvc.dbSrvc.schedulerSrvc.getScheduleForId(schedId)
		const emp = this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(sched.employee_id)
		const empName = emp.first + ' ' + emp.last
		this.deleteInProgress = true
		this.coreSrvc.dbSrvc.deleteRecord('schedule_recur', schedId).then((result) => {
			this.coreSrvc.dbSrvc.schedulerSrvc.removeLocalRecord(schedId)
			this.coreSrvc.notifySrvc.notify('success', 'Schedule Removed', `The selected schedule entry for ${empName} has been removed.`, 3)
			this.checkSubSectionForPendingSchedules()
			this.showDeleteScheduleDialog = false
			this.coreSrvc.dbSrvc.readTable('open_shifts')
			// this.listUpdated.next(true)
		})
	}

	// Begin Pending Schedule Management

	listItemTimeFormatter(item: ScheduleListItemEntry) {
		if (item.isAnytimeJob || item.startTime === item.endTime) {
			return `Anytime Job / ${item.tzAbrev}`
		} else {
			if (item.info) {
				return item.info.scheduledTimeInfo
			} else {
				const timeStr = item.startTime + ' - ' + item.endTime
				return `${timeStr} ${item.tzAbrev}`
			}
		}
	}

	takeAction(item: ScheduleListItemEntry) {
		log('Approval in list component', item)
		const empName = item.empName
		const schedTime = this.listItemTimeFormatter(item)

		this.approvalActionSetupEmail(item)
		this.approvalAction.title = `${empName} / ${schedTime}`
		this.approvalAction.recordId = item.entry.id
		this.approvalAction.notes = item.entry.comments
		this.approvalAction.takeAction = null
		this.approvalAction.showConfirmation = false
		this.approvalAction.isProcessing = false
		this.approvalAction.isDialogVisible = true

		// Reset approve with work order checkbox
		this.approvalAction.approveWithWorkorder = false

		// Reset overtime exception checkbox and hide it
		this.approvalAction.showOvertimeOverrideCheckbox = false
		this.approvalAction.confirmOvertimeOverride = false

		// Reset time off override checkbox and hide it
		this.approvalAction.showTimeOffOverrideCheckbox = false
		this.approvalAction.confirmTimeOffOverride = false
	}

	approvalActionSetupEmail(entry: ScheduleListItemEntry) {
		// Create a new email manager
		this.approvalAction.approveWithWorkorderEmailListManager = new GenericEmailListManager(this.coreSrvc)

		// If the entry has an email, use that
		if (entry.entry.workorder_email) {
			this.approvalAction.approveWithWorkorderEmailListManager.initWithString(entry.entry.workorder_email)
			return
		}

		// Otherwise, if the entry has a vendor, use that
		const vendorId = entry?.job?.vendor_id
		const vendor = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationById(vendorId)
		if (vendor) {
			this.approvalAction.vendorName = vendor.name
			if (vendor.email) this.approvalAction.approveWithWorkorderEmailListManager.initWithString(vendor.email)
		}
	}

	checkSubSectionForPendingSchedules() {
		this.loadData()
		this.listUpdated.next(true)
	}
	// End Pending Schedule Management

	changeActionComplete(action: string) {
		log('Action Completed')
		if (action === 'APPROVED' || action === 'REJECTED') {
			this.loadData()
		}
	}

	searchList(searchText) {
		this.manager.series.searchText = searchText
		const filterString = (searchText as string) || ''
		const lcFilterString = filterString.toLowerCase()

		if (this.manager.currentView === 'SERIES' && this.manager.series.option === 'JOB') {
			this.jobSearch = searchText
			for (const item of this.filteredJobList) {
				item.filter(lcFilterString, this.useExactMatch)
			}
		}
	}

	scheduleActionComplete() {
		log('List component received actionComplete')
		// this.showEditEntryModal = false
		this.coreSrvc.dbSrvc.schedulerSrvc.list = []
		this.coreSrvc.dbSrvc.readTable('schedule_recur').then((result) => {
			this.showScheduleStatusFor('ALL')
			this.loadData()
			this.listUpdated.next(true)
		})
	}

	backToTop(): boolean {
		$('html, body').animate({ scrollTop: '0px' })
		return false
	}

	notifyOperationNotAuthorized() {
		this.coreSrvc.notifySrvc.default('operationNotAuthorized')
	}

	public saveViewPrefs() {
		this.statusFilter.showExpired = this.manager.series.showExpiredSchedules
		this.manager.save()
		this.loadData()
	}

	public batchAction(action: 'EXITBATCH' | 'CLEAR' | 'SELECTVISIBLE' | 'PREVIEW' | 'DELETE' | 'EDIT') {
		switch (action) {
			case 'EXITBATCH':
				this.manager.series.isBatchingEnabled = false
				this.coreSrvc.dbSrvc.schedulerSrvc.clearBatchUpdateList()
				this.clearFilter()
				return
			case 'CLEAR':
				this.coreSrvc.dbSrvc.schedulerSrvc.clearBatchUpdateList()
				this.clearFilter()
				return
			case 'SELECTVISIBLE':
				for (const job of this.filteredJobList) {
					for (const schedItem of job.entries) {
						if (schedItem.visible && !this.coreSrvc.dbSrvc.schedulerSrvc.scheduleBatchUpdateList.includes(schedItem.entry.id)) {
							this.coreSrvc.dbSrvc.schedulerSrvc.addScheduleIdToBatchUpdate(schedItem.entry.id)
						}
					}
				}
				return
			case 'PREVIEW':
				// Preview is a toggle between 'preview' and 'show all'. If in preview mode (showBatchSelection), clear filter the filter
				// If not yet filtering, update the filter to view batched schedules. Then refresh the UI
				if (this.statusFilter.active && this.statusFilter.showBatchSelection) {
					this.clearFilter()
					return
				} else {
					this.statusFilter.setFilterStatus('BATCHED')
				}
				this.searchList(null)
				this.filterForStatusView(this.statusFilter)
				return
			case 'DELETE':
				break
			case 'EDIT':
				this.handleBatchEdit()
				return
		}
		this.coreSrvc.notifySrvc.notify('success', 'Batch Action', 'Batch action completed.')
		this.coreSrvc.dbSrvc.schedulerSrvc.clearBatchUpdateList()
	}

	private handleBatchEdit() {
		const batchIds = this.coreSrvc.dbSrvc.schedulerSrvc.scheduleBatchUpdateList
		if (batchIds.length === 0) {
			this.coreSrvc.notifySrvc.notify('info', 'Batch Edit', 'You must selecct at least one schedule befor performing a batch edit.')
			return
		}
		this.batchEditDialogManager.submitBtnLabel = 'Submit'
		this.batchEditDialogManager.headerLabel = 'Edit Batch'
		this.batchEditDialogManager.isDialogVisible = true
	}

	public batchActionComplete() {
		this.coreSrvc.dbSrvc.schedulerSrvc.clearBatchUpdateList()
		this.clearFilter()
		this.loadData()
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'approve_with_workorder':
				help.header = 'Work Orders'
				help.message =
					'When checked, the system will send a work order for this schedule to the email addresses provided. You can configure your workorder options under Admin > Settings > Work Orders.'
				break
			case 'overtime_override':
				help.header = 'Overtime Override'
				help.message = `This schedule exceeds overtime limits. Click this checkbox to confirm that the overtime generated by this schedule is approved.`
				break
			case 'timeoff_override':
				help.header = 'Time-Off Override'
				help.message = `This schedule entry conflicts with scheduled time-off. Click this checkbox to confirm the conflict is acceptable.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}
