<div class="map-dialog">
	<div class="modal fade" id="shiftDetailsDialog" data-bs-keyboard="false" data-bs-backdrop="static">
		<div class="modal-dialog">
			<div class="modal-content">
				<!-- Modal Header -->
				<div class="modal-header" style="height: 45px; padding: 4px 15px">
					<div>
						<h4 class="modal-title" style="display: inline-block">Details</h4>
						<!-- <h4 class="modal-title" style="color: chocolate; display: inline-block">{{ eventType() }}</h4> -->
						<div class="view-options" *ngIf="isMapValid()">
							<div *ngIf="currentView === 'DETAILS'" class="view-link link-text" (click)="toggleView('MAP')">Show Map</div>
							<div *ngIf="currentView === 'MAP'" class="view-link link-text" (click)="toggleView('DETAILS')">Show Info</div>
						</div>
						<!-- <div class="title-status">{{ checkInOutTimestampFormatter() }}</div> -->
						<button *ngIf="false" class="btn btn-sm btn-outline-secondary map-type-btn">Satellite</button>
					</div>

					<button type="button" style="margin-top: 10px; margin-right: 15px" class="close" (click)="onClose()" data-bs-dismiss="modal">
						&times;
					</button>
				</div>

				<!-- Modal body -->
				<div class="modal-body">
					<div id="proximityMap" [hidden]="currentView === 'DETAILS' || !isMapValid()" #gmap class="map-box"></div>
					<div *ngIf="currentView === 'MAP' && !isMapValid()" class="map-box">
						<div class="map-box-content map-invalid">
							<div style="font-size: 3em">Invalid Map</div>
							<div style="font-size: 1.5em">Missing GPS Info</div>
						</div>
					</div>
					<div *ngIf="currentView === 'DETAILS'" class="map-box">
						<div class="map-box-content details-view">
							<app-shift-details-wrapper [transId]="transId" [inOut]="inOut" (showMapMarker)="showOnMap($event)"></app-shift-details-wrapper>
						</div>
					</div>
				</div>

				<!-- Modal footer -->
				<div class="modal-footer" [class.footer-left]="isMapValid()" style="text-align: left; height: 45px">
					<div *ngIf="isMapValid() && currentView === 'MAP'">
						<div class="footer-legend-wrap">
							<div *ngIf="hasCheckinMarker" class="legend-icon-wrap link-text" (click)="panToCurrentLocation('IN')">
								<img class="legend-icon" src="/assets/img/marker-black.png" />{{ getEventType('IN') }}
							</div>
							<div *ngIf="hasCheckoutMarker" class="legend-icon-wrap link-text" (click)="panToCurrentLocation('OUT')">
								<img class="legend-icon" src="/assets/img/marker-black.png" />{{ getEventType('OUT') }}
							</div>
							<div *ngIf="showLegendHighlight" class="legend-icon-wrap link-text" (click)="panToExpectedLocation()">
								<img class="legend-icon" src="/assets/img/marker-green.png" />Job Site
							</div>
							<!-- <img src="/assets/img/bluemarker.png" style="width: 16px; height: 24px" />&nbsp;
								<span style="color: #888">{{ showLegendHighlight ? 'Other' : 'Job' }} Sites</span> -->
						</div>
						<!-- <img class="locateButton float-right" (click)="panToCurrentLocation()" src="/assets/img/locate.png" width="20" height="20" /> -->
					</div>
					<div *ngIf="!isMapValid()">
						<div class="footer-no-gps">No map available (GPS missing)</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Google Map Icon Maker
http://www.googlemapsmarkers.com/v1/A/002d99/ (with label)
http://www.googlemapsmarkers.com/v1/0099FF/ (without label) -->
