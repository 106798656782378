import { Injectable, EventEmitter } from '@angular/core'

import {
	ReportRecord,
	ReportHistoryRecord,
	IReportsPayload,
	IReportsLambdaResult,
	ReportType,
	ReportsViewManager,
	ReportSaveCompleteEvent,
} from '@app/models'
import { LambdaService } from '@app/services/aws/lambda.service'

import { log } from '@app/helpers'
import _ from 'lodash' // Don't add lodash to angular-cli.json or it goes in global scope.
import { Global } from '@app/models/global'

@Injectable({
	providedIn: 'root',
})
export class ReportsService {
	viewManager = new ReportsViewManager()

	reportsDataLoaded = false
	reportsHistoryDataLoaded = false
	reportTypesDataLoaded = false

	lastUpdated: Date

	reportRunStart = new EventEmitter<boolean>()
	reportRunComplete = new EventEmitter<boolean>()
	reportSaveComplete = new EventEmitter<ReportSaveCompleteEvent>()

	private reportsList: Array<ReportRecord> = []
	private reportsHistoryList: Array<ReportHistoryRecord> = []
	private reportTypesList: Array<ReportType> = []

	constructor(private lambdaSrvc: LambdaService) {
		log('Creating ReportsService')
	}

	reportsCount(): number {
		return this.reportsList.length
	}

	reportsHistoryCount(): number {
		return this.reportsList.length
	}

	clearData() {
		this.reportsList = []
		this.reportsDataLoaded = false
		this.reportsHistoryList = []
		this.reportsHistoryDataLoaded = false
		this.reportTypesList = []
		this.reportTypesDataLoaded = false
	}

	clearReportsData() {
		this.reportsList = []
		this.reportsDataLoaded = false
	}

	clearReportsHistoryData() {
		this.reportsHistoryList = []
		this.reportsHistoryDataLoaded = false
	}

	getReportTypes(): Array<ReportType> {
		return this.reportTypesList
	}

	getReportTypeDropdownData(): any[] {
		// const dropdown = [
		// 	{ label: 'Select Report Type', value: null },
		// 	{ label: 'Suspect Transactions', value: 'Suspect' },
		// 	{ label: 'Employee Summary', value: 'Employee' },
		// 	{ label: 'Job Summary', value: 'Job' },
		// 	{ label: 'Emp. & Job Summary', value: 'Daily' },
		// 	{ label: 'Job Site Summary', value: 'Location' },
		// 	{ label: 'Payroll Report', value: 'Payroll' },
		// 	{ label: 'Payroll (Overtime)', value: 'PayrollOvertime' },
		// 	{ label: 'Payroll (US Holiday + OT)', value: 'PayrollHoliday' },
		// 	{ label: 'QuickBooks Desktop Export', value: 'QBDesktop' },
		// 	{ label: 'Transaction Dump', value: 'CSVTransactions' },
		// ]
		const reportTypes = _.sortBy(this.getReportTypes(), 'order').map((rep) => ({
			label: rep.name,
			value: rep.java_class,
		}))
		return [{ label: 'Select Report Type', value: null }, ...reportTypes]
	}

	getReportTypeName(value: string): string {
		// switch (value) {
		// 	case 'Employee': return 'Employee Summary'
		// 	case 'EmployeeDecimal': return 'Employee Summary'
		// 	case 'Job': return 'Job Summary'
		// 	case 'JobDecimal': return 'Job Summary'
		// 	case 'Suspect': return 'Suspect Transactions'
		// 	case 'SuspectDecimal': return 'Suspect Transactions'
		// 	case 'Daily': return 'Emp. & Job Summary'
		// 	case 'DailyDecimal': return 'Emp. & Job Summary'
		// 	case 'Location': return 'Job Site Summary'
		// 	case 'LocationDecimal': return 'Job Site Summary'
		// 	case 'Payroll': return 'Payroll Report'
		// 	case 'PayrollDecimal': return 'Payroll Report'
		// 	case 'PayrollOvertime': return 'Payroll (Overtime)'
		// 	case 'PayrollOvertimeDecimal': return 'Payroll (Overtime)'
		// 	case 'PayrollHoliday': return 'Payroll (US Holiday + OT)'
		// 	case 'PayrollHolidayDecimal': return 'Payroll (US Holiday + OT)'
		// 	case 'QBDesktop': return 'QuickBooks Desktop Export'
		// 	case 'CSVTransactions': return 'Transaction Dump'
		// 	default: return 'Unknown Type'
		// }
		const reportType = this.reportTypesList.find((rt) => rt.java_class === value)
		return reportType ? reportType.name : 'Unknown Type'
	}

	getReportsPeriodDropdownData(companyId?: number): any[] {
		let dropdown: any[] = [{ label: 'Select Frequency', value: null }]

		dropdown = dropdown.concat([
			{ label: 'Custom Date Range', value: 'CUSTOM' },
			{ label: 'Daily', value: 'DAILY' },
			{ label: 'Weekly', value: 'WEEKLY' },
			{ label: 'Every Two Weeks', value: 'EVERY_2_WEEKS' },
			{ label: 'Twice Per Month', value: 'TWICE_MONTHLY' },
		])

		// Period Backend
		// TWICE_MONTHLY_25_10 - Special deal of 25th to 10th and 11th to 24th
		// TWICE_MONTHLY_26_10 - Special deal of 26th to 10th and 11th to 25th
		// TWICE_MONTHLY_27_10 - Special deal of 27th to 10th and 11th to 26th
		// TWICE_MONTHLY_28_11 - Special deal of 28th to 11th and 12th to 27th

		if (companyId === 153 || companyId === 2294) {
			dropdown = dropdown.concat([{ label: 'Twice Per Month (10/24)', value: 'TWICE_MONTHLY_25_10' }])
		}
		if (companyId === 19658) {
			dropdown = dropdown.concat([{ label: 'Twice Per Month (10/25)', value: 'TWICE_MONTHLY_26_10' }])
		}
		if (companyId === 160 || companyId === 2382) {
			dropdown = dropdown.concat([{ label: 'Twice Per Month (10/26)', value: 'TWICE_MONTHLY_27_10' }])
		}
		if (companyId === 8801) {
			dropdown = dropdown.concat([{ label: 'Twice Per Month (11/27)', value: 'TWICE_MONTHLY_28_11' }])
		}

		dropdown = dropdown.concat([{ label: 'Monthly', value: 'MONTHLY' }])
		return dropdown
	}

	getFrequencyNote(freq: string) {
		switch (freq) {
			case 'TWICE_MONTHLY_25_10':
				return '25th-10th / 11th-24th'
			case 'TWICE_MONTHLY_26_10':
				return '26th-10th / 11th-25th'
			case 'TWICE_MONTHLY_27_10':
				return '27th-10th / 11th-26th'
			case 'TWICE_MONTHLY_28_11':
				return '28th-11th / 12th-27th'
			default:
				return ''
		}
	}

	getReportFrequencyName(value: string): string {
		switch (value) {
			case 'CUSTOM':
				return 'Custom'
			case 'DAILY':
				return 'Daily'
			case 'WEEKLY':
				return 'Weekly'
			case 'EVERY_2_WEEKS':
				return 'Every Two Weeks'
			case 'TWICE_MONTHLY':
				return 'Twice Per Month'
			case 'TWICE_MONTHLY_28_11':
				return 'Twice Per Month (11/27)'
			case 'TWICE_MONTHLY_27_10':
				return 'Twice Per Month (10/26)'
			case 'TWICE_MONTHLY_25_10':
				return 'Twice Per Month (10/24)'
			case 'MONTHLY':
				return 'Monthly'
			default:
				return 'Unknown Type'
		}
	}

	removeLocalReportsRecord(recordId: number) {
		this.reportsList = this.reportsList.filter((rec) => rec.id !== recordId)
	}

	getReports(): ReportRecord[] {
		return this.reportsList
	}

	removeLocalHistoryRecord(recordId: number) {
		this.reportsHistoryList = this.reportsHistoryList.filter((rec) => rec.id !== recordId)
	}

	getReportsHistory(): ReportHistoryRecord[] {
		// If the sort order of this changes, reports VIEW section will need to be
		// updated as the flashLastRun expects reverse chronological order
		return this.reportsHistoryList
	}

	getReportById(id: number): ReportRecord {
		const report = this.reportsList.find((r) => r.id === id)
		return report ? report : null
	}

	getReportHistoryById(id: number): ReportHistoryRecord {
		const reportHistory = this.reportsHistoryList.find((r) => r.id === id)
		return reportHistory ? reportHistory : null
	}

	createReport(record): Promise<boolean> {
		return this.invokeReportsLambda('reports_add', record).then((result) => {
			if (result.success) {
				return Promise.resolve(true)
			} else {
				return Promise.resolve(false)
			}
		})
	}

	updateReport(record): Promise<boolean> {
		return this.invokeReportsLambda('reports_edit', record).then((result) => {
			if (result.success) {
				return Promise.resolve(true)
			} else {
				return Promise.resolve(false)
			}
		})
	}

	deleteReportById(id: number): Promise<boolean> {
		const record = { id: id }
		return this.invokeReportsLambda('reports_delete', record).then((result) => {
			if (result.success) {
				this.reportsList = this.reportsList.filter((r) => r.id !== id)
				return Promise.resolve(true)
			} else {
				return Promise.resolve(false)
			}
		})
	}

	deleteReportHistoryById(id: number): Promise<boolean> {
		const record = { id: id }
		return this.invokeReportsLambda('reports_log_delete', record).then((result) => {
			if (result.success) {
				this.reportsHistoryList = this.reportsHistoryList.filter((rh) => rh.id !== id)
				return Promise.resolve(true)
			} else {
				return Promise.resolve(false)
			}
		})
	}

	runReportById(id: number, standardReport?: boolean): Promise<boolean> {
		// last_period true for lambda actually means current period or standard report, it's inverted
		const request = { id: id, last_period: standardReport }
		log('RUN REPORT REQUEST', request)
		return this.invokeReportsLambda('reports_run', request).then((result) => {
			if (result.success) {
				return Promise.resolve(true)
			} else {
				return Promise.resolve(false)
			}
		})
	}

	runReportHistoryById(id: number): Promise<boolean> {
		const request = { id: id }
		return this.invokeReportsLambda('reports_log_run', request).then((result) => {
			if (result.success) {
				return Promise.resolve(true)
			} else {
				return Promise.resolve(false)
			}
		})
	}

	reportNameForId(id: number): string {
		const reports = this.reportsList.filter((r) => r.id === id)
		if (reports.length > 0) {
			return reports[0].name
		} else {
			return 'Report Deleted'
		}
	}

	reportTypeForId(id: number): string {
		const reports = this.reportsList.filter((r) => r.id === id)
		if (reports.length > 0) {
			const type = reports[0].java_class
			return this.getReportTypeName(type)
		} else {
			return 'Report Deleted'
		}
	}

	invokeReportsLambda(operation: string, record?: any): Promise<IReportsLambdaResult> {
		const payload: IReportsPayload = {
			operation: operation,
		}
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					payload[attr] = record[attr] || record[attr] === 0 ? record[attr].toString() : ''
				}
			}
		}
		return this.lambdaSrvc.reports(payload)
	}

	// makeReport(record: ReportRecord): ReportRecord {
	// 	const report = new ReportRecord(record)
	// 	report.normalizeTimes()
	// 	return report
	// }

	setReportRecords(records: Array<ReportRecord>) {
		this.lastUpdated = new Date()
		this.reportsList = records.map((rec) => new ReportRecord(rec))
		this.reportsDataLoaded = true
	}

	// makeReportHistory(record: ReportHistoryRecord): ReportHistoryRecord {
	// 	const history = new ReportHistoryRecord()
	// 	for (const attr in record) {
	// 		if (record.hasOwnProperty(attr)) {
	// 			history[attr] = record[attr]
	// 		}
	// 	}
	// 	return history
	// }

	setReportHistoryRecords(records: Array<ReportHistoryRecord>) {
		this.lastUpdated = new Date()
		this.reportsHistoryList = records.map((rec) => new ReportHistoryRecord(rec))
		this.reportsHistoryDataLoaded = true
	}

	setReportTypeRecords(records: Array<ReportType>) {
		this.reportTypesList = records.map((rec) => new ReportType(rec))
		this.reportTypesDataLoaded = true
	}
}
