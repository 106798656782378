import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { FormTagsComponent } from '@app/components/form-tags/form-tags.component'
import { DateTimeHelper, DropdownHelper, DropdownHelperConfig, log } from '@app/helpers'
import { DialogManager, DatabaseTableName, HelpDialogMessage, DataAccessRequest, EmployeeStatus } from '@app/models'
import { CoreService } from '@app/services'
import { DeviceDetectorService } from 'ngx-device-detector'
import { SelectItem } from 'primeng/api'
import moment from 'moment-timezone'

@Component({
    selector: 'app-employee-edit-batch',
    templateUrl: './employee-edit-batch.component.html',
    styleUrl: './employee-edit-batch.component.scss',
    standalone: false
})
export class EmployeeEditBatchComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
	resource = 'employee' as DatabaseTableName
	isUpdating = false

	record = new EmployeeBatchEditRecord()
	tagOptionsList = this.coreSrvc.dbSrvc.empSrvc.getTagLabels()

	supervisorOptions: Array<SelectItem> = []
	employmentStatusOptions: Array<SelectItem> = []

	departmentList: Array<string> = []
	departmentOptions: Array<string> = []

	timeFormat = DateTimeHelper.getTimeFormat()

	@Input() dialogManager: DialogManager
	@Output() actionComplete = new EventEmitter<boolean>()
	@ViewChild('tagComp') tagComp: FormTagsComponent

	constructor(private coreSrvc: CoreService) {
		this.setupDropdowns()
	}

	get devDetect(): DeviceDetectorService {
		return this.coreSrvc.devDetect
	}

	public ngOnInit(): void {
		this.record.employee_ids = this.coreSrvc.dbSrvc.empSrvc.batchManager.selectedRecords
	}

	public ngAfterViewInit(): void {}

	public ngAfterContentInit(): void {
		this.setupDialogManager()
	}

	public ngOnDestroy(): void {}

	public setupDialogManager() {
		this.dialogManager.submitBtnAction = () => this.submit()
	}

	private setupDropdowns() {
		const ddConfig = new DropdownHelperConfig(this.coreSrvc.dbSrvc, 'DROPDOWN')
		ddConfig.isRestricted = false
		const ddHelper = new DropdownHelper(ddConfig)

		this.supervisorOptions = ddHelper.buildSupervisorMenuOptions()
		this.employmentStatusOptions = this.coreSrvc.dbSrvc.empSrvc.getEmploymentStatusDropdown()
		this.departmentList = this.coreSrvc.dbSrvc.empSrvc.getDepartments()
	}

	public searchDepartment(event) {
		const text: string = this.record.department
		if (!text) {
			this.departmentOptions = [...this.departmentList]
			return
		}
		const filteredOptions = this.departmentList.filter((opt) => opt.toLowerCase().includes(text.toLowerCase()))
		this.departmentOptions = filteredOptions
	}

	public submit() {
		// Guard against double submission
		if (this.isUpdating) return

		// Add any tag that might be in the input field
		this.tagComp?.addTag()

		// Check for tag being edited
		if (this.tagComp?.selectedTag) {
			this.coreSrvc.notifySrvc.notify(
				'info',
				'Action Required',
				'You are curretnly modifying a tag. You must cancel or confirm your changes before you can save this record.',
			)
			return
		}

		const record = this.makeUpdateRecord()
		if (!record) return
		log('Record to submit', record)

		this.isUpdating = true
		const request = new DataAccessRequest(this.resource, 'batch_update', null, record)
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			log('Lambda Result', result)
			this.actionComplete.emit(true)
			this.dialogManager.isDialogVisible = false
		})
	}

	private makeUpdateRecord() {
		const updateRecord = new EmployeeBatchEditRecord(this.record)
		if (updateRecord.start_date) updateRecord.start_date = moment(updateRecord.start_date).format('YYYY-MM-DD') as any

		return updateRecord
	}

	public validateNumericInput(input) {
		const value = this.record['input']
		const intValue = parseInt(value, 10)
		if (intValue) {
			this.record['input'] = intValue
		} else {
			this.record['input'] = null
		}
	}

	defaultStartEndTime(type: 'START' | 'END'): Date {
		// Using moment, return a start date for 9am and an end time for 5pm
		return type === 'START'
			? moment().startOf('day').hour(9).minute(0).second(0).toDate()
			: moment().startOf('day').hour(17).minute(0).second(0).toDate()
	}

	public showHelp(trigger: string) {
		const help = new HelpDialogMessage(null, null)
		switch (trigger) {
			case 'tags_action':
				help.header = 'Update Action'
				help.message =
					'<b>Add</b> will add the listed tags to each batched record while leaving other tags in place.<br /><br /><b>Remove</b> will remove the listed tags from each batched record while leaving other tags in place.<br /><br /><b>Replace</b> will replace all tags in each batched record with the listed tags.'
				break
			default:
				help.header = 'Topic Unavailable'
				help.message = `No help information for this topic is currently available.`
		}
		this.coreSrvc.notifySrvc.helpMessage.next(help)
	}
}

class EmployeeBatchEditRecord {
	employee_ids: Array<number> = []

	active = false
	active_change = false

	supervisor_id: number = null
	supervisor_id_change = false

	start_date: Date = null
	start_date_change = false

	status: EmployeeStatus = null // Employee Status (optional dropdown)
	status_change = false

	department: string = null
	department_change = false

	tags_json: string
	tags_json_change = false
	tags_action: 'ADD' | 'REMOVE' | 'REPLACE_ALL' = 'ADD'

	constructor(data?: any) {
		if (data) {
			for (const attr in data) {
				if (data.hasOwnProperty(attr)) {
					this[attr] = data[attr]
				}
			}
		}
		this.employee_ids = [...this.employee_ids]
	}
}
