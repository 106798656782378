import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { TableFilterButton, TableFilterManager } from '@app/models'
import { log } from '@app/helpers'

@Component({
    selector: 'app-table-filter-buttons',
    templateUrl: './table-filter-buttons.component.html',
    styleUrls: ['./table-filter-buttons.component.scss'],
    standalone: false
})
export class TableFilterButtonsComponent implements OnInit {
	@Input() manager: TableFilterManager
	@Input() noMargins = false
	@Output() buttonClicked = new EventEmitter<TableFilterButton>()

	constructor() {}

	ngOnInit(): void {}

	filterButtonClicked(btn: TableFilterButton) {
		this.manager.selectedBtn = btn
		this.buttonClicked.emit(btn)
		log('Button Clicked', btn)
	}
}
