import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { DataTablesHelper, DisplayHelper, GeneralTableHelper, log, TableActionFormatter } from '@app/helpers'
import {
	ComponentBridgeName,
	CrudAction,
	DialogManager,
	LocalPrefsData,
	LocalPrefsDialogData,
	LocalPrefsGroup,
	DatabaseTableName,
	UserRoleRecord,
} from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { Subscription } from 'rxjs'
import { SupervisorGroupsFormatter, SupervisorRolesFormatter } from '../users-formatter'

import _ from 'lodash'

enum UserRoleTableColumnIndex {
	id,
	description,
	details,
	linkedUsers,
	actions,
}

@Component({
    selector: 'app-user-role-table',
    templateUrl: './role-table.component.html',
    styleUrls: ['./role-table.component.scss'],
    standalone: false
})
export class UserRoleTableComponent implements OnInit, OnDestroy, AfterViewInit {
	bridgeName: ComponentBridgeName = 'ngBridgeUserRoleTable'

	list: Array<UserRoleRecord> = []
	isDataLoaded = false

	editDialogManager = new DialogManager('userRolesDialog')
	editAction = { recordId: null, action: 'edit' }

	deleteAction = { tableName: 'supervisor_role' as DatabaseTableName, recordId: null, recordLabel: null, showDeleteRecordDialog: false }

	linkedRecordsDialogManager = new DialogManager('linkedRecordsModal')
	linkedRecordsDialog = { records: [], filteredRecords: [] }

	highlightMissingExternalIds = false

	managedUserIds = []

	isMergedJobSites = false
	isInternalUser = false

	private userRoleTable: any
	private defaultSortOrder = [[UserRoleTableColumnIndex.description, 'asc']]
	private defaultPageLength = 100

	debounceRedrawTable = _.debounce(this.redrawTable, 300)

	public sectionPrefsDialogManager = new DialogManager('sectionPrefsDialog')

	private subs = new Subscription()

	constructor(
		private coreSrvc: CoreService,
		private renderer: Renderer2,
	) {
		this.setupSubscriptions()
		this.setupAccessPermissions()
		this.setupComponent()
		this.setupLocalPrefsDialog()
	}

	get dtFilterText(): string {
		const input = $('.search-field-input')
		return (input?.val() as string) || ''
	}

	ngOnInit() {
		window[this.bridgeName] = this
		this.loadData()
	}

	ngAfterViewInit() {
		this.initTable()

		$('#userRoleTable_filter input').attr('placeholder', ' filter')
		$('#userRoleTable_filter input').addClass('search-field-input')

		setTimeout(() => {
			$('#clear-search-icon').detach().appendTo('#userRoleTable_filter label')
		})

		$('#userRoleTable_filter input').on('keyup', () => {
			this.debounceRedrawTable()
		})

		this.fetchAndReloadData()
	}

	ngOnDestroy() {
		this.userRoleTable['fixedHeader'].disable()
		window[this.bridgeName] = null
		this.subs.unsubscribe()
	}

	private setupSubscriptions() {
		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	private setupAccessPermissions() {
		// this.accessHelper = new AccessHelper(this.coreSrvc.dbSrvc, 'supervisor_role')
		// this.accessHelper.updateSupervisorIds()
		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()
	}
	private setupLocalPrefsDialog(): void {
		// this.sectionPrefsDialogManager.headerLabel = 'Preferences'
		// const columnVisibilityItems = LocalPrefsData.getSupRoleTableColumnDisplayPrefs()
		// const columnVisibilityGroup = new LocalPrefsGroup('Column Visibility', columnVisibilityItems)
		// const dialogData = new LocalPrefsDialogData([columnVisibilityGroup])
		// dialogData.localStorageKeyRemovalList = ['DataTables_userRoleTable']
		// this.sectionPrefsDialogManager.dialogData = dialogData
	}

	private handleScreenSizeChanges() {
		if (this.userRoleTable) {
			this.userRoleTable.columns.adjust().responsive.recalc()
			this.userRoleTable.fixedHeader.adjust()
		}
	}

	public prefsDataSaved(): void {
		log('UpdatePrefsData')
		this.sectionPrefsDialogManager.isDialogVisible = false
		this.updateTable()
	}

	canPerformAction(action: CrudAction, isMyRecord: boolean) {
		// return this.accessHelper.canPerformAction(action, isMyRecord)
		return true
	}
	isMyRecord(id: number): boolean {
		return true
	}

	// Manage UI
	setupComponent() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		// Check for missing ID highlight requirement
		this.highlightMissingExternalIds = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany().globalHighlightMissingExtId
		this.isMergedJobSites = this.coreSrvc.dbSrvc.settingSrvc.getCompany().merge_jobsite
	}

	enableAllTooltips() {
		// Enable Tooltips
		const tooltips: any = $('.item-tooltip')
		tooltips.tooltip({ show: { effect: 'none', delay: 0 } })
		// Delay long enough to hide tooltips if cursor was hovering during page load
		setTimeout(() => {
			tooltips.tooltip('hide')
		}, 1000)
	}

	clearSearch(): boolean {
		this.userRoleTable
			.search('')
			.order([[UserRoleTableColumnIndex.description, 'asc']])
			.rows()
			.invalidate()
			.draw()
		this.enableAllTooltips()
		return false
	}

	fetchData(): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			this.coreSrvc.dbSrvc.bulkRead(['users', 'supervisor_group', 'supervisor_role']).then((result) => {
				this.coreSrvc.dbSrvc.settingSrvc.userRolesLastUpdated = new Date()
				resolve(true)
			})
		})
	}

	loadData() {
		this.list = this.coreSrvc.dbSrvc.settingSrvc.getUserRoles()
		this.managedUserIds = this.coreSrvc.dbSrvc.settingSrvc.getManagedUserIds()
	}

	fetchAndReloadData() {
		this.fetchData().then((success) => {
			this.loadData()
			this.updateTable()
		})
	}

	redrawTable() {
		this.userRoleTable?.rows().invalidate().search(this.dtFilterText).draw(true)
		this.enableAllTooltips()
	}

	public updateTable() {
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadData()
			this.updateColumns()
		})
	}

	private updateColumns() {
		const linkedUsers = true // this.coreSrvc.prefSrvc.data.supRolesColSupervisorsVisible

		GeneralTableHelper.updateColumn(this.userRoleTable, UserRoleTableColumnIndex.linkedUsers, linkedUsers)

		this.userRoleTable.clear()
		this.userRoleTable.rows.add(this.list)
		this.userRoleTable.draw(false)
	}

	createRecord() {
		log('createRecord called')
		// if (!this.canPerformAction(CrudAction.create, null)) { this.accessHelper.notifyOperationNotAuthorized(); return }
		this.editAction.recordId = null
		this.editAction.action = 'new'
		this.editDialogManager.isCancelBtnVisble = true
		this.editDialogManager.cancelBtnLabel = 'Cancel'
		this.editDialogManager.headerLabel = 'Add Role'
		this.editDialogManager.dialogData = this.editAction
		this.editDialogManager.isDialogVisible = true
	}

	// Bridge Methods
	public editRecord(id: number) {
		const role = this.coreSrvc.dbSrvc.settingSrvc.getUserRoleById(id)
		if (role) {
			if (role.isSystemRole()) {
				if (this.isInternalUser) {
					this.editDialogManager.headerLabel = role.description + ' (System Role)'
					this.editDialogManager.isSubmitBtnVisible = true
					this.editDialogManager.cancelBtnLabel = 'Cancel'
				} else {
					this.editDialogManager.headerLabel = role.description + ' (System Role)'
					this.editDialogManager.isSubmitBtnVisible = false
					this.editDialogManager.cancelBtnLabel = 'Close'
				}
			} else {
				this.editDialogManager.headerLabel = role.description
				this.editDialogManager.isSubmitBtnVisible = true
				this.editDialogManager.cancelBtnLabel = 'Cancel'
			}
			this.editAction.recordId = id
			this.editAction.action = 'edit'
			this.editDialogManager.dialogData = this.editAction

			this.editDialogManager.isDialogVisible = true
		}
		log('Edit Action', this.editAction)
		log('Edit Dialog Manager', this.editDialogManager)
	}

	public cloneRecord(id: number) {
		const role = this.coreSrvc.dbSrvc.settingSrvc.getUserRoleById(id)
		this.editAction.recordId = id
		this.editAction.action = 'clone'
		this.editDialogManager.headerLabel = 'Clone ' + role.description
		this.editDialogManager.dialogData = this.editAction
		this.editDialogManager.isSubmitBtnVisible = true
		this.editDialogManager.cancelBtnLabel = 'Cancel'
		this.editDialogManager.isDialogVisible = true
	}

	recordUpdated() {
		log('recordUpdaated: Client record has been updated')
		// this.fetchAndReloadData()
		this.loadData()
		this.updateTable()
	}

	deleteRecord(id: number) {
		log('deleteRecord called')
		const role = this.coreSrvc.dbSrvc.settingSrvc.getUserRoleById(id)
		const linkCount = this.coreSrvc.dbSrvc.settingSrvc.getUsersByRoleId(id).length
		if (linkCount > 0) {
			this.coreSrvc.notifySrvc.notify(
				'error',
				`Template Linked`,
				`This role template is currently assigned to ${DisplayHelper.pluralize(
					'supervisor',
					linkCount,
					true,
				)} and cannot be deleted while in use.`,
			)
			return
		}
		if (role) {
			if (role.isSystemRole()) {
				this.coreSrvc.notifySrvc.notify('error', 'Not Allowed', 'Deleting system roles is not allowed.')
				return
			}
			this.deleteAction.recordId = role.id
			this.deleteAction.recordLabel = role.description
			this.deleteAction.tableName = 'supervisor_role'
			this.deleteAction.showDeleteRecordDialog = true
		}
	}

	deleteActionComplete(success: boolean) {
		if (success) {
			this.loadData()
			this.updateTable()
		}
		this.deleteAction.showDeleteRecordDialog = false
	}

	deleteActionCancel() {
		this.deleteAction.showDeleteRecordDialog = false
	}

	// Manage Linked Records

	public filterLinkedRecords(searchText: string) {
		log('Search', searchText)
		if (!searchText) {
			this.linkedRecordsDialog.filteredRecords = [...this.linkedRecordsDialog.records]
		} else {
			this.linkedRecordsDialog.filteredRecords = this.linkedRecordsDialog.records.filter((rec: string) => rec.toLowerCase().includes(searchText))
		}
	}

	public showLinkedRecords(id: number, type: string) {
		let headerLabel = ''
		switch (type) {
			case 'supervisor':
				const supNames = this.coreSrvc.dbSrvc.settingSrvc.getUsersByRoleId(id).map((u) => u.first_name + ' ' + u.last_name)
				this.linkedRecordsDialog.records = supNames
				headerLabel = 'Linked Supervisors'
				break
		}

		this.linkedRecordsDialog.filteredRecords = [...this.linkedRecordsDialog.records]

		this.linkedRecordsDialogManager.headerLabel = headerLabel
		this.linkedRecordsDialogManager.isSubmitBtnVisible = false
		this.linkedRecordsDialogManager.cancelBtnLabel = 'Close'
		this.linkedRecordsDialogManager.isDialogVisible = true
	}

	notifyOperationNotAuthorized() {
		this.coreSrvc.notifySrvc.default('operationNotAuthorized')
	}

	invalidateRowForRecord(group: UserRoleRecord) {
		log('Invalidated', group)
		const idx = this.list.indexOf(group)
		this.userRoleTable.rows(idx).invalidate().draw(false)
	}

	// DataTables Initialization

	private initTable() {
		this.coreSrvc.displaySrvc.startSectionLoader()
		this.userRoleTable = $('#userRoleTable').DataTable(<DataTables.Settings>{
			stateSave: false,
			responsive: true,
			processing: true,
			paging: true,
			pageLength: this.defaultPageLength,
			lengthChange: true,
			info: true,
			select: false,
			searching: true,
			fixedHeader: DataTablesHelper.fixedHeader,
			autoWidth: false,
			data: this.list,
			order: [[UserRoleTableColumnIndex.description, 'asc']],
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{
					responsivePriority: 1,
					targets: [UserRoleTableColumnIndex.description, UserRoleTableColumnIndex.linkedUsers],
				},
				{
					// ID
					targets: UserRoleTableColumnIndex.id,
					visible: false,
					searchable: true,
					title: 'ID',
					data: null,
					render: (rec: UserRoleRecord, t, f, m) => {
						return rec.id
					},
				},
				{
					// Description
					targets: UserRoleTableColumnIndex.description,
					visible: true,
					searchable: true,
					title: 'Role Name',
					data: null,
					render: (rec: UserRoleRecord, t, f, m) => {
						const isSystemRole = rec.id < 1000
						const name = rec.description || ''
						const sortString = isSystemRole ? `~${rec.id}` : rec.description
						const sortHtml = `<div style="display:none">${sortString}</div>`
						const systemRoleHtml = isSystemRole ? `<div class="table-tag bg-sysjob" style="width:130px">System Role</div>` : ''
						return `
						<div class="dtr-control-content">
							${sortHtml}
							${systemRoleHtml}
							<div style="min-width: 150px; line-height: 1.2em; white-space: normal">${name}</div>
						</div>`
					},
				},
				{
					// Details
					targets: UserRoleTableColumnIndex.details,
					visible: this.coreSrvc.prefSrvc.data.supGroupsColDetailsVisible,
					searchable: this.coreSrvc.prefSrvc.data.supGroupsColDetailsVisible,
					title: 'Details',
					data: null,
					render: (rec: UserRoleRecord, t, f, m) => {
						const details = rec.details || ''
						return `<div style="max-width: 100%; line-height: 1.2em; white-space: normal">${details}</div>`
					},
				},
				{
					// Linked Supervisors
					targets: UserRoleTableColumnIndex.linkedUsers,
					visible: this.coreSrvc.prefSrvc.data.supGroupsColSupervisorsVisible,
					searchable: this.coreSrvc.prefSrvc.data.supGroupsColSupervisorsVisible,
					title: 'Linked<br>Supervisors',
					data: null,
					render: (rec: UserRoleRecord, t, f, m) => {
						return SupervisorRolesFormatter.makeGroupsLinkedRecordsButton(
							this.coreSrvc.dbSrvc,
							this.bridgeName,
							this.dtFilterText,
							rec.id,
							'supervisor',
						)
					},
				},
				{
					// Actions
					targets: UserRoleTableColumnIndex.actions,
					visible: true,
					searchable: false,
					orderable: false,
					title: 'Actions',
					data: null,
					render: (rec: UserRoleRecord, t, f, m) => {
						const recordId = rec.id
						const isSystemRole = rec.isSystemRole()
						const isMyRecord = true // this.isMyRecord(rec.id)

						const canAccessEdit = true // this.accessHelper.canPerformAction(CrudAction.update, isMyRecord)
						const editLink = TableActionFormatter.editLink(this.bridgeName, 'editRecord', recordId, canAccessEdit)
						const viewLink = TableActionFormatter.viewLink(this.bridgeName, 'editRecord', recordId, canAccessEdit)
						const viewOrEditLink = isSystemRole ? (this.isInternalUser ? editLink : viewLink) : editLink

						const canClone = canAccessEdit
						const cloneLink = TableActionFormatter.cloneLink(this.bridgeName, 'cloneRecord', recordId, canClone)

						const canAccessDelete = rec.id > 1000
						const deleteLink = TableActionFormatter.deleteLink(this.bridgeName, 'deleteRecord', recordId, canAccessDelete)

						const auditLink = rec.isSystemRole()
							? this.isInternalUser
								? TableActionFormatter.auditLinkGlobal('supervisor_role', recordId, rec.description, true, !!rec.updated)
								: ''
							: TableActionFormatter.auditLinkGlobal('supervisor_role', recordId, rec.description, true, !!rec.updated)

						return `<span class="act-ico-wrap">${viewOrEditLink}${cloneLink}${deleteLink}${auditLink}</span>`
					},
				},
			],
			drawCallback: (settings) => {
				this.userRoleTable?.columns.adjust().responsive.recalc()
				this.coreSrvc.displaySrvc.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
			},
		})
		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter('userRoleTable', this.userRoleTable)
	}
}
