import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { PhoneHelper, log } from '@app/helpers'
import { DialogManager, SkillRecord, UserRecord } from '@app/models'
import { CoreService } from '@app/services'
import { SelectItem } from 'primeng/api'

@Component({
    selector: 'app-call-center-manage-skill-edit',
    templateUrl: './call-center-manage-skill-edit.component.html',
    styleUrl: './call-center-manage-skill-edit.component.scss',
    standalone: false
})
export class CallCenterManageSkillEditComponent implements OnInit, AfterContentInit {
	isUpdating = false
	isLoadingConfig = true

	@Input() recordId: number
	@Input() dialogManager: DialogManager

	@Output() endEditing = new EventEmitter<boolean>()

	skill: SkillRecord
	phoneOptions: Array<SelectItem> = []
	userOptions: Array<SelectItem> = []

	skillUsersList: Array<SkillUserVm> = []
	userSKillsList: Array<UserSkillVm> = []

	constructor(private coreSrvc: CoreService) {
		this.phoneOptions = this.coreSrvc.dbSrvc.settingSrvc
			.getCallInPhoneNumberRecords()
			.filter((pn) => pn.route_application !== 'TTS_PROD')
			.map((pn) => {
				return { label: PhoneHelper.formatPhoneFromE164(pn.number_e164) + ' / ' + pn.phoneNumberLabel, value: pn.id }
			})

		this.userOptions = this.coreSrvc.dbSrvc.settingSrvc
			.getUsers()
			.filter((u) => u.role !== 'NO_COGNITO')
			.map((u) => {
				return { label: u.first_name + ' ' + u.last_name, value: u.id }
			})

		this.coreSrvc.dbSrvc.bulkRead(['skill', 'users']).then((success) => {
			this.setupUserSkillsList()
			this.isLoadingConfig = false
		})
	}

	get isNew(): boolean {
		return !this.recordId
	}

	ngOnInit(): void {
		const record = this.coreSrvc.dbSrvc.callCenterSrvc.getSkillRecordById(this.recordId)
		this.skill = new SkillRecord(record)
	}

	ngAfterContentInit(): void {
		setTimeout(() => {
			this.dialogManager.submitBtnAction = () => this.submit()

			// Resize the description text area
			const textArea = document.getElementById('skillEditDescription')
			this.textResizer(textArea as HTMLElement)
		}, 250)
	}

	submit() {
		log('Submit Btn Clicked')
		this.isUpdating = true
		if (this.isNew) {
			this.coreSrvc.dbSrvc.insertRecord('skill', this.skill).then((success) => {
				if (success) {
					this.endEditing.emit(true)
					this.isUpdating = false
				}
			})
		} else {
			this.coreSrvc.dbSrvc.updateRecord('skill', this.skill).then((success) => {
				if (success) {
					this.endEditing.emit(true)
					this.isUpdating = false
				}
			})
		}
	}

	setupUserSkillsList() {
		const skills = this.coreSrvc.dbSrvc.callCenterSrvc.getSkillRecords()
		const users = this.coreSrvc.dbSrvc.settingSrvc.getUsers().filter((u) => u.role !== 'NO_COGNITO')

		// Setup skills config list
		const skillUsersList = []
		for (const skill of skills) {
			const skillUserVm = new SkillUserVm(skill)
			for (const user of users) {
				const skillLink = user.users_skill.find((s) => s.skill_id === skill.id)
				if (skillLink) {
					skillUserVm.users.push(user)
				}
			}
			if (skillUserVm.users.length > 0) skillUsersList.push(skillUserVm)
		}
		this.skillUsersList = skillUsersList

		// Setup users config list
		const userSKillsList = []
		for (const user of users) {
			const userSkillVm = new UserSkillVm(user)
			for (const skill of skills) {
				const skillLink = skill.users_skill.find((u) => u.user_id === user.id)
				if (skillLink) {
					userSkillVm.skills.push(skill)
				}
			}
			if (userSkillVm.skills.length > 0) userSKillsList.push(userSkillVm)
		}
		this.userSKillsList = userSKillsList
	}

	public textResizer(elm: HTMLElement) {
		if (elm) {
			elm.style.height = 'auto'
			elm.style.height = elm.scrollHeight + 'px'
		}
	}
}

class SkillUserVm {
	skill: SkillRecord
	users: Array<UserRecord> = []
	constructor(skill: SkillRecord) {
		this.skill = skill
	}
}

class UserSkillVm {
	user: UserRecord
	skills: Array<SkillRecord> = []
	constructor(user: UserRecord) {
		this.user = user
	}
}
