import {
	Component,
	ViewChild,
	OnInit,
	OnDestroy,
	AfterViewInit,
	Input,
	Output,
	EventEmitter,
	SimpleChanges,
	OnChanges,
	Renderer2,
	ElementRef,
} from '@angular/core'

import { DatabaseTableName, DialogControlEvent } from '@app/models'
import { UserLoginService, DatabaseService, CoreService } from '@app/services'
import { DeviceDetectorService } from 'ngx-device-detector'
import { log, Helper, DisplayHelper, DialogHelper } from '@app/helpers'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-basic-modal',
    templateUrl: './basic-modal.component.html',
    styleUrls: ['./basic-modal.component.scss'],
    standalone: false
})
export class BasicModalComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
	isMobile = false

	@Input() modalId: string
	@Input() modalHeader: string
	@Input() modalFooter: string
	@Input() modalWidth: number
	@Input() modalHeight: number
	@Input() saveBtnName = 'Save'
	@Input() saveBtnVisible = true

	@Input() removePadding = false
	@Input() preventBackgroundClose: boolean

	showBackBtn = false
	enableSaveBtn = false
	@Input() saveTable: string
	@Input() helpVidKey: string

	@Input() visible: boolean
	@Output() visibleChange: EventEmitter<boolean> = new EventEmitter()

	@ViewChild('modalElement', { static: true }) modalElement: ElementRef

	// private dialogListener: () => void

	private DEFAULT_WIDTH = 450 // In pixels
	maxHeight: number

	private subs = new Subscription()

	constructor(
		private coreSrvc: CoreService,
		private renderer: Renderer2,
		private devDetSrvc: DeviceDetectorService,
		private loginSrvc: UserLoginService,
	) {
		this.isMobile = this.devDetSrvc.isMobile()
		this.subs.add(this.coreSrvc.eventSrvc.dialogControlEvents.subscribe((event) => this.processControlEvent(event)))
	}

	ngOnInit() {
		const nativeElement = this.modalElement.nativeElement
		const width = this.modalWidth ? this.modalWidth : this.DEFAULT_WIDTH
		const height = this.modalHeight
		if (height) {
			const style = 'max-width: ' + width + 'px; max-height: ' + height + 'px;'
			this.renderer.setAttribute(nativeElement, 'style', style)
		} else {
			const style = 'max-width: ' + width + 'px; '
			this.renderer.setAttribute(nativeElement, 'style', style)
		}
	}

	ngAfterViewInit() {
		this.maxHeight = window.innerHeight
	}

	processControlEvent(controlEvent: DialogControlEvent) {
		const direction = controlEvent.direction
		if (direction === 'toDialog') {
			const eventName = controlEvent.eventName
			switch (eventName) {
				case 'showBackBtn':
					this.showBackBtn = true
					return
				case 'hideBackBtn':
					this.showBackBtn = false
					return
				case 'enableSaveBtn':
					this.enableSaveBtn = true
					return
				case 'disableSaveBtn':
					this.enableSaveBtn = false
					return
				default:
					log('Unhandled event', controlEvent)
			}
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.loginSrvc.setLastActive()
		if (changes['visible']) {
			const element: any = $('#' + this.modalId)

			if (this.visible) {
				element.modal('show')

				// Support for draggable dialogs - class .modal-header should have proper cursor
				// and need to add  appDraggable handle=".modal-header" to moda-dialog in template.
				// Also uncomment the block below and comment out element.modal('show') above.

				// if (this.coreSrvc.devDetect.isDesktop()) {
				// 	this.handleSavedPositioning()
				// } else {
				// 	element.modal('show')
				// }

				// DisplayHelper.setHtmlScrolling(false) // Disable HTML scrolling on modal open
			} else {
				element.modal('hide')
				// DisplayHelper.setHtmlScrolling(true) // Enable HTML scrolling on modal close
			}
		}
	}

	ngOnDestroy() {
		const element: any = $('#' + this.modalId)
		element.modal('hide')
		this.subs.unsubscribe()
	}

	onClose() {
		this.visible = false
		this.showBackBtn = false
		this.visibleChange.emit(this.visible)
	}

	backBtnClicked() {
		const callerId = this.saveTable
		const event = new DialogControlEvent(callerId, 'toContent', 'backBtnClicked', null)
		this.coreSrvc.eventSrvc.dialogNotification(event)
	}

	saveBtnClicked() {
		const callerId = this.saveTable
		const event = new DialogControlEvent(callerId, 'toContent', 'saveBtnClicked', null)
		this.coreSrvc.eventSrvc.dialogNotification(event)
	}

	private handleSavedPositioning() {
		DialogHelper.handleSavedPositioning(this.modalId)
	}
}
