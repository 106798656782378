import { Component, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { DisplayHelper } from '@app/helpers'

import { CognitoCallback, CoreService, UserRegistrationService } from '@app/services'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-auth-resend',
    templateUrl: './resendCode.component.html',
    standalone: false
})
export class ResendCodeComponent implements OnDestroy, CognitoCallback {
	mobile: string
	mobilee164: string
	errorMessage: string

	private subs = new Subscription()

	constructor(
		public registrationService: UserRegistrationService,
		public router: Router,
		private coreSrvc: CoreService,
	) {
		// Color background based on screen size
		this.subs.add(
			this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => {
				this.handleScreenSizeChange()
			}),
		)
		this.handleScreenSizeChange()
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	handleScreenSizeChange() {
		DisplayHelper.setBodyBackgroundForAuthFlow()
	}

	resendCode() {
		this.registrationService.resendCode(this.mobile, this)
	}

	cognitoCallback(error: any, result: any) {
		if (error != null) {
			this.errorMessage = 'Something went wrong...please try again'
		} else {
			this.router.navigate(['/home/confirmRegistration', this.mobile])
		}
	}
}
