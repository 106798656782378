<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<form class="edit-form-block" novalidate [formGroup]="rateForm" (keydown.enter)="$event.preventDefault()" autocomplete="off">
		<div class="options-block" [class.input-required]="!rateForm.get('vendor_client_id').value">
			<div class="options-title-inline">{{ orgTypeLabel }}</div>
			<div *ngIf="!rateForm.get('vendor_client_id').value" class="input-required float-right">(required)</div>
			<p-select
				appScrollLockSelect
				formControlName="vendor_client_id"
				[options]="organizationOptions"
				[filter]="true"
				filterBy="label,data.external_id"
				[resetFilterOnHide]="true"
				[required]="true"
			></p-select>
		</div>

		<div class="options-block" [class.input-required]="!rateForm.get('job_id').value">
			<div class="options-title-inline">Job</div>
			<div *ngIf="!rateForm.get('job_id').value" class="input-required float-right">(required)</div>
			<p-select
				appScrollLockSelect
				formControlName="job_id"
				[options]="jobOptions"
				[filter]="true"
				filterBy="label,data.external_id"
				[resetFilterOnHide]="true"
				[required]="true"
			></p-select>
		</div>

		<div class="options-block form-spacer" [class.input-required]="!rateForm.get('pay_rate').value" style="margin-top: 38px">
			<div class="float-right">
				<span class="pay-rate-symbol tts-hl-2">{{ currencySymbol }}</span>
				<input type="number" class="form-control pay-rate-input" formControlName="pay_rate" (blur)="formatInput('pay_rate')" />
			</div>
			<div style="padding-top: 4px">
				<strong>{{ billPayRateLabel }}</strong>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('payRate')"></i>
			</div>
		</div>
	</form>
</div>

<!-- <div *ngIf="true">
	<button class="btn btn-sm btn-secondary" (click)="viwFormErrors()">Form Errors</button>
	<div>{{ rateForm.valid }}</div>
	<br>
	<div>{{ rateForm.value | json }}</div>
</div> -->
