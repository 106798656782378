import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { DataTablesHelper, DisplayHelper, GeneralTableHelper, log, TableActionFormatter } from '@app/helpers'
import {
	ComponentBridgeName,
	CrudAction,
	DialogManager,
	LocalPrefsData,
	LocalPrefsDialogData,
	LocalPrefsGroup,
	StationRecord,
	DatabaseTableName,
} from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { Subscription } from 'rxjs'
import { StationFormatter } from '../station.formatter'

import _ from 'lodash'
import { DomSanitizer } from '@angular/platform-browser'
import { environment } from '@env/environment'

enum StationTableColumnIndex {
	id,
	description,
	setupCode,
	linkedEmployees,
	linkedJobs,
	notes,
	stationMode,
	ipLock,
	actions,
}

@Component({
    selector: 'app-station-table',
    templateUrl: './station-table.component.html',
    styleUrls: ['./station-table.component.scss'],
    standalone: false
})
export class StationTableComponent implements OnInit, OnDestroy, AfterViewInit {
	// accessHelper: AccessHelper

	bridgeName: ComponentBridgeName = 'ngBridgeStationTable'

	list: Array<StationRecord> = []
	isDataLoaded = false

	editDialogManager = new DialogManager('stationDialog')
	editAction = { recordId: null, action: 'edit' }

	deleteAction = { tableName: 'station' as DatabaseTableName, recordId: null, recordLabel: null, showDeleteRecordDialog: false }

	linkedRecordsDialogManager = new DialogManager('linkedRecordsModal')
	linkedRecordsDialog = { records: [], filteredRecords: [] }

	empDebugAction = {
		recordId: null,
		header: 'Debug Employee App',
		footer: 'Employee Name',
		safeUrl: null,
		showDialog: false,
	}

	isInternalUser = false

	private stationTable: any
	private defaultSortOrder = [[StationTableColumnIndex.description, 'asc']]
	private defaultPageLength = 100

	debounceRedrawTable = _.debounce(this.redrawTable, 300)

	public sectionPrefsDialogManager = new DialogManager('sectionPrefsDialog')

	private subs = new Subscription()

	constructor(
		private coreSrvc: CoreService,
		private sanitizer: DomSanitizer,
	) {
		this.setupSubscriptions()
		this.setupAccessPermissions()
		this.setupLocalPrefsDialog()
	}

	get dtFilterText(): string {
		const input = $('.search-field-input')
		return (input?.val() as string) || ''
	}

	public ngOnInit() {
		window[this.bridgeName] = this
		this.fetchAndReloadData()
	}

	public ngAfterViewInit() {
		this.initTable()

		$('#stationTable_filter input').attr('placeholder', ' filter')
		$('#stationTable_filter input').addClass('search-field-input')

		setTimeout(() => {
			$('#clear-search-icon').detach().appendTo('#stationTable_filter label')
		})

		// Handle count bubble search highlighting
		$('#stationTable_filter input').on('keyup', () => {
			this.debounceRedrawTable()
		})

		this.fetchAndReloadData()
	}

	public ngOnDestroy() {
		this.stationTable['fixedHeader'].disable()
		window[this.bridgeName] = null
		this.subs.unsubscribe()
	}

	private setupSubscriptions() {
		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	private setupAccessPermissions() {
		// this.accessHelper = new AccessHelper(this.coreSrvc.dbSrvc, 'supervisor_role')
		// this.accessHelper.updateSupervisorIds()
		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()
	}
	private setupLocalPrefsDialog(): void {
		this.sectionPrefsDialogManager.headerLabel = 'Preferences'
		const columnVisibilityItems = LocalPrefsData.getStationColumnDisplayPrefs()
		const columnVisibilityGroup = new LocalPrefsGroup('Column Visibility', columnVisibilityItems)
		const dialogData = new LocalPrefsDialogData([columnVisibilityGroup])
		this.sectionPrefsDialogManager.dialogData = dialogData
	}

	private handleScreenSizeChanges() {
		if (this.stationTable) {
			this.stationTable.columns.adjust().responsive.recalc()
			this.stationTable.fixedHeader.adjust()
		}
	}

	public prefsDataSaved(): void {
		log('UpdatePrefsData')
		this.sectionPrefsDialogManager.isDialogVisible = false
		this.updateTable()
	}

	public openPrefsDialog(): void {
		this.sectionPrefsDialogManager.isDialogVisible = true
	}

	canPerformAction(action: CrudAction, isMyRecord: boolean) {
		// return this.accessHelper.canPerformAction(action, isMyRecord)
		return true
	}
	isMyRecord(id: number): boolean {
		return true
	}

	enableAllTooltips() {
		this.coreSrvc.displaySrvc.enableAllTooltips()
	}

	clearSearch(): boolean {
		this.stationTable
			.search('')
			.order([[StationTableColumnIndex.description, 'asc']])
			.rows()
			.invalidate()
			.draw()
		this.enableAllTooltips()
		return false
	}

	fetchData(): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			this.coreSrvc.dbSrvc.bulkRead(['station']).then((result) => {
				resolve(true)
			})
		})
	}

	loadData() {
		this.list = this.coreSrvc.dbSrvc.stationSrvc.getStations()
		log('stationTable - loadData', this.list)
		// this.managedUserIds = this.coreSrvc.dbSrvc.settingSrvc.getManagedUserIds()
	}

	fetchAndReloadData() {
		this.fetchData().then((success) => {
			this.loadData()
			this.updateTable()
		})
	}

	redrawTable() {
		this.stationTable?.rows().invalidate().search(this.dtFilterText).draw(true)
		this.enableAllTooltips()
	}

	public updateTable() {
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadData()
			this.updateColumns()
		})
	}

	private updateColumns() {
		const setupCode = this.coreSrvc.prefSrvc.data.stationColSetupCodeVisible
		const linkedEmployees = this.coreSrvc.prefSrvc.data.stationColLinkedEmployeesVisible
		const linkedJobs = this.coreSrvc.prefSrvc.data.stationColLinkedJobsVisible
		const notes = this.coreSrvc.prefSrvc.data.stationColNotesVisible
		const stationMode = this.coreSrvc.prefSrvc.data.stationColStationModeVisible
		const ipLock = this.coreSrvc.prefSrvc.data.stationColIpLockVisible

		this.stationTable.clear()

		GeneralTableHelper.updateColumn(this.stationTable, StationTableColumnIndex.setupCode, setupCode)
		GeneralTableHelper.updateColumn(this.stationTable, StationTableColumnIndex.linkedEmployees, linkedEmployees)
		GeneralTableHelper.updateColumn(this.stationTable, StationTableColumnIndex.linkedJobs, linkedJobs)
		GeneralTableHelper.updateColumn(this.stationTable, StationTableColumnIndex.notes, notes)
		GeneralTableHelper.updateColumn(this.stationTable, StationTableColumnIndex.stationMode, stationMode)
		GeneralTableHelper.updateColumn(this.stationTable, StationTableColumnIndex.ipLock, ipLock)

		this.stationTable.rows.add(this.list)
		this.stationTable.draw(false)
	}

	createRecord() {
		log('createRecord called')
		// if (!this.canPerformAction(CrudAction.create, null)) { this.accessHelper.notifyOperationNotAuthorized(); return }
		this.editAction.recordId = null
		this.editAction.action = 'new'
		this.editDialogManager.headerLabel = 'Add Station'
		this.editDialogManager.dialogData = this.editAction
		this.editDialogManager.isDialogVisible = true
	}

	// Bridge Methods
	public editRecord(id: number) {
		const station = this.coreSrvc.dbSrvc.stationSrvc.getStationById(id)
		if (station) {
			this.editAction
			this.editAction.recordId = id
			this.editAction.action = 'edit'
			this.editDialogManager.headerLabel = 'Edit Station'
			this.editDialogManager.dialogData = this.editAction
			this.editDialogManager.isDialogVisible = true
		}
		log('Edit Action', this.editAction)
		log('Edit Dialog Manager', this.editDialogManager)
	}

	public cloneRecord(id: number) {
		const station = this.coreSrvc.dbSrvc.stationSrvc.getStationById(id)
		this.editAction.recordId = id
		this.editAction.action = 'clone'
		this.editDialogManager.headerLabel = `Clone ${station.description}`
		this.editDialogManager.dialogData = this.editAction
		this.editDialogManager.isSubmitBtnVisible = true
		this.editDialogManager.cancelBtnLabel = 'Cancel'
		this.editDialogManager.isDialogVisible = true
	}

	recordUpdated() {
		log('recordUpdaated: Station record has been updated')
		// this.fetchAndReloadData()
		this.loadData()
		this.updateTable()
	}

	deleteRecord(id: number) {
		log('deleteRecord called')
		const station = this.coreSrvc.dbSrvc.stationSrvc.getStationById(id)
		// const linkCount = (station?.employee_ids.length ?? 0) + (station?.job_ids.length ?? 0)
		// if (linkCount > 0) {
		// 	this.coreSrvc.notifySrvc.notify(
		// 		'error',
		// 		`Station Linked`,
		// 		`This station is currently linked to ${DisplayHelper.pluralize('record', linkCount, true)} and cannot be deleted while in use.`
		// 	)
		// 	return
		// }
		if (station) {
			this.deleteAction.recordId = station.id
			this.deleteAction.recordLabel = station.description
			this.deleteAction.tableName = 'station'
			this.deleteAction.showDeleteRecordDialog = true
		}
	}

	deleteActionComplete(success: boolean) {
		if (success) {
			this.loadData()
			this.updateTable()
		}
		this.deleteAction.showDeleteRecordDialog = false
	}

	deleteActionCancel() {
		this.deleteAction.showDeleteRecordDialog = false
	}

	// Manage Linked Records

	public filterLinkedRecords(searchText: string) {
		log('Search', searchText)
		if (!searchText) {
			this.linkedRecordsDialog.filteredRecords = [...this.linkedRecordsDialog.records]
		} else {
			this.linkedRecordsDialog.filteredRecords = this.linkedRecordsDialog.records.filter((rec: string) => rec.toLowerCase().includes(searchText))
		}
	}

	public showLinkedRecords(id: number, type: string) {
		const station = this.coreSrvc.dbSrvc.stationSrvc.getStationById(id)
		let headerLabel = ''
		switch (type) {
			case 'EMPLOYEE':
				const activeEmployees = this.coreSrvc.dbSrvc.empSrvc.getActiveEmployees()
				const selectedEmployees = station.employee_ids.map((empId) => this.coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)).filter((emp) => !!emp)
				const employees = station.employee_ids.length === 0 ? activeEmployees : selectedEmployees
				const employeeNames = employees.map((e) => e.first + ' ' + e.last)
				this.linkedRecordsDialog.records = employeeNames
				headerLabel = 'Linked Employees'
				break
			case 'JOB':
				const activeJobs = this.coreSrvc.dbSrvc.jobSrvc.getActiveJobs()
				const selectedJobs = station.job_ids.map((jobId) => this.coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)).filter((job) => !!job)
				const jobs = station.job_ids.length === 0 ? activeJobs : selectedJobs
				const jobNames = jobs.map((j) => j.description)
				this.linkedRecordsDialog.records = jobNames
				headerLabel = 'Linked Jobs'
				break
		}

		this.linkedRecordsDialog.filteredRecords = [...this.linkedRecordsDialog.records]

		this.linkedRecordsDialogManager.headerLabel = headerLabel
		this.linkedRecordsDialogManager.isSubmitBtnVisible = false
		this.linkedRecordsDialogManager.cancelBtnLabel = 'Close'
		this.linkedRecordsDialogManager.isDialogVisible = true
	}

	notifyOperationNotAuthorized() {
		this.coreSrvc.notifySrvc.default('operationNotAuthorized')
	}

	invalidateRowForRecord(group: StationRecord) {
		log('Invalidated', group)
		const idx = this.list.indexOf(group)
		this.stationTable.rows(idx).invalidate().draw(false)
	}

	empAppView(id: number) {
		const station = this.coreSrvc.dbSrvc.stationSrvc.getStationById(id)
		const empAppDdomain = environment.empAppDomain
		const domain = window.location.origin.includes('localhost') ? 'http://localhost:4500' : 'https://' + empAppDdomain
		const setupCode = station.setup_code

		const url = `${domain}/#/station/setup?code=${setupCode}&eadb=1`
		log('Unsafe URL', url)
		if (url) {
			this.empDebugAction.header = station.description
			this.empDebugAction.footer = 'Station Debugger'
			this.empDebugAction.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url)
			this.empDebugAction.showDialog = true
		} else {
			this.coreSrvc.notifySrvc.notify('warn', 'Debug View', `Could not generate URL for station ${station.description}.`)
		}
	}

	// DataTables Initialization

	private initTable() {
		this.coreSrvc.displaySrvc.startSectionLoader()
		this.stationTable = $('#stationTable').DataTable(<DataTables.Settings>{
			stateSave: false,
			responsive: true,
			processing: true,
			paging: true,
			pageLength: this.defaultPageLength,
			lengthChange: true,
			info: true,
			select: false,
			searching: true,
			fixedHeader: DataTablesHelper.fixedHeader,
			autoWidth: false,
			data: this.list,
			order: [[StationTableColumnIndex.description, 'asc']],
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{
					responsivePriority: 1,
					targets: [StationTableColumnIndex.description, StationTableColumnIndex.setupCode],
				},
				{
					// ID
					targets: StationTableColumnIndex.id,
					visible: false,
					searchable: true,
					title: 'ID',
					data: null,
					render: (rec: StationRecord, t, f, m) => {
						return rec.id
					},
				},
				{
					// Description
					targets: StationTableColumnIndex.description,
					visible: true,
					searchable: true,
					title: 'Station Name',
					data: null,
					render: (rec: StationRecord, t, f, m) => {
						const name = rec.description || ''
						return `
						<div class="dtr-control-content">
							<div style="min-width: 150px; line-height: 1.2em; white-space: normal">${name}</div>
						</div>`
					},
				},
				{
					// Setup Code
					targets: StationTableColumnIndex.setupCode,
					visible: this.coreSrvc.prefSrvc.data.stationColSetupCodeVisible,
					searchable: this.coreSrvc.prefSrvc.data.stationColSetupCodeVisible,
					title: 'Setup<br>Code',
					data: null,
					render: (rec: StationRecord, t, f, m) => {
						const code = rec.setup_code || ''
						return `<div class="table-setup-code">${code}</div>`
					},
				},
				{
					// Linked Employees
					targets: StationTableColumnIndex.linkedEmployees,
					visible: this.coreSrvc.prefSrvc.data.stationColLinkedEmployeesVisible,
					searchable: this.coreSrvc.prefSrvc.data.stationColLinkedEmployeesVisible,
					title: 'Linked<br>Employees',
					data: null,
					render: (rec: StationRecord, t, f, m) => {
						return StationFormatter.makeGroupsLinkedRecordsButton(this.coreSrvc, this.bridgeName, this.dtFilterText, rec.id, 'EMPLOYEE')
					},
				},
				{
					// Linked Jobs
					targets: StationTableColumnIndex.linkedJobs,
					visible: this.coreSrvc.prefSrvc.data.stationColLinkedJobsVisible,
					searchable: this.coreSrvc.prefSrvc.data.stationColLinkedJobsVisible,
					title: 'Linked<br>Jobs',
					data: null,
					render: (rec: StationRecord, t, f, m) => {
						return StationFormatter.makeGroupsLinkedRecordsButton(this.coreSrvc, this.bridgeName, this.dtFilterText, rec.id, 'JOB')
					},
				},
				{
					// Notes
					targets: StationTableColumnIndex.notes,
					visible: this.coreSrvc.prefSrvc.data.stationColNotesVisible,
					searchable: this.coreSrvc.prefSrvc.data.stationColNotesVisible,
					title: 'Notes',
					data: null,
					render: (rec: StationRecord, t, f, m) => {
						const stationNotes = rec.details ? rec.details : ''
						return stationNotes ? `<div class="table-note-block hide-scrollbars">${stationNotes}</div>` : stationNotes
					},
				},
				{
					// Mode
					targets: StationTableColumnIndex.stationMode,
					visible: this.coreSrvc.prefSrvc.data.stationColStationModeVisible,
					searchable: this.coreSrvc.prefSrvc.data.stationColStationModeVisible,
					title: 'Mode',
					data: null,
					render: (rec: StationRecord, t, f, m) => {
						const isKioskMode = rec.kiosk_mode
						const tagStyle = isKioskMode ? 'background-color:#a38535' : ''
						return `<div class="table-tag" style="width:90px;${tagStyle}">${isKioskMode ? 'Kiosk' : 'Personal'}</div>`
						// return GeneralTableHelper.booleanIconRenderer({ value: rec.kiosk_mode })
					},
				},
				{
					// IP Lock
					targets: StationTableColumnIndex.ipLock,
					visible: this.coreSrvc.prefSrvc.data.stationColIpLockVisible,
					searchable: this.coreSrvc.prefSrvc.data.stationColIpLockVisible,
					title: 'IP Lock',
					data: null,
					render: (rec: StationRecord, t, f, m) => {
						const iconHtml = GeneralTableHelper.booleanIconRenderer({ value: rec.ip_allowlist })
						return `<div class="ip-lock-wrap">${iconHtml}</div>`
					},
				},
				{
					// Actions
					targets: StationTableColumnIndex.actions,
					visible: true,
					searchable: false,
					orderable: false,
					title: 'Actions',
					data: null,
					render: (rec: StationRecord, t, f, m) => {
						const recordId = rec.id
						const isMyRecord = true // this.isMyRecord(rec.id)

						const canAccessEdit = true // this.accessHelper.canPerformAction(CrudAction.update, isMyRecord)
						const editLink = TableActionFormatter.editLink(this.bridgeName, 'editRecord', recordId, canAccessEdit)

						const canClone = canAccessEdit
						const cloneLink = TableActionFormatter.cloneLink(this.bridgeName, 'cloneRecord', recordId, canClone)

						const canAccessDelete = true // rec.employee_ids.length === 0 && rec.job_ids.length === 0
						const deleteLink = TableActionFormatter.deleteLink(this.bridgeName, 'deleteRecord', recordId, canAccessDelete)

						const canAccessEmpApp = !this.coreSrvc.auditMode || this.isInternalUser
						const empAppViewLink = canAccessEmpApp
							? TableActionFormatter.empAppViewLink(this.bridgeName, 'empAppView', recordId, canAccessEmpApp, true)
							: ''

						const auditLink = TableActionFormatter.auditLinkGlobal('station', recordId, rec.description, true, !!rec.updated)

						return `<span class="act-ico-wrap">${editLink}${cloneLink}${deleteLink}${auditLink}${empAppViewLink}</span>`
					},
				},
			],
			drawCallback: (settings) => {
				this.stationTable?.columns.adjust().responsive.recalc()
				this.coreSrvc.displaySrvc.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
			},
		})
		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter('stationTable', this.stationTable)
	}
}
