import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { log } from '@app/helpers'
import { JobRecord, TableSaver } from '@app/models'
import { CoreService } from '@app/services'

@Component({
    selector: 'app-table-footer-buttons',
    templateUrl: './table-footer-buttons.component.html',
    styleUrls: ['./table-footer-buttons.component.scss'],
    standalone: false
})
export class TableFooterButtonsComponent implements OnInit {
	useFormulaGuard = false
	btnStyle = { 'margin-top': '0px' }

	@Input() table: string = null
	@Input() filename = 'exported-data'

	@Input() newBtnLabel = 'Add Record'
	@Input() exportBtnLabel = 'Export Records'
	@Input() lockBtnLabel: string = null
	@Input() multiSchedBtnLabel: string = null

	@Input() marginTop: string = '0x'
	@Input() linkLabel: string = 'Add Record'

	@Output() newBtnClicked = new EventEmitter<boolean>()
	@Output() lockBtnClicked = new EventEmitter<boolean>()
	@Output() multiSchedBtnClicked = new EventEmitter<boolean>()

	constructor(private coreSrvc: CoreService) {
		const prefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		this.useFormulaGuard = prefs.globalExcelFormulaGuard
	}

	ngOnInit(): void {
		this.btnStyle['margin-top'] = this.marginTop
		// if (this.recordType) {
		// 	this.linkLabel = `Add ${this.recordType}`
		// }
	}

	exportBtnClicked() {
		if (this.table) {
			const dataTable = $(`#${this.table}`).dataTable()
			const records = dataTable.api().rows({ search: 'applied' }).data().toArray()

			if (records.length === 0) {
				this.coreSrvc.notifySrvc.notify('info', 'No Records', 'No records to export.', 3)
				return
			}

			const processedRecords = this.processRecords(records)
			const tableSave = new TableSaver(processedRecords)
			tableSave.exportToCsv(this.filename, this.table)
		}
	}

	processRecords(records: Array<any>): Array<any> {
		// Adjustments for any table
		records.forEach((rec: any) => {
			this.replaceNullValues(rec)
			if (this.useFormulaGuard) this.replaceFormulaSymbols(rec)
		})

		// Handle custom columns for jobsTable
		if (this.table === 'jobsTable') {
			records.forEach((job: JobRecord) => {
				const siteId = job.location_id
				const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(siteId)
				job['location_district'] = site?.district ?? ''
				job['location_address_formatted'] = site?.address_formatted ?? ''
			})
		}

		return records
	}

	replaceNullValues(obj: Record<string, unknown>): Record<string, unknown> {
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				if (typeof obj[key] === 'object' && obj[key] !== null) {
					// If the property is an object, recursively call the function
					obj[key] = this.replaceNullValues(obj[key] as Record<string, unknown>)
				} else if (!obj[key]) {
					// If the property value is the string 'null', replace with null
					obj[key] = ''
				}
			}
		}
		return obj
	}

	replaceFormulaSymbols(obj: Record<string, any>): void {
		// If the string starts with a potential formula character, prepend a single quote
		for (const key in obj) {
			if (typeof obj[key] === 'string') {
				if (/^[=@+\-*\/]/.test(obj[key])) {
					obj[key] = `'${obj[key]}`
				}
			}
		}
	}
}
