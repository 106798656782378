<div *ngIf="showBreakEditDialog">
	<app-break-edit
		[isDialog]="true"
		[isInline]="true"
		[transId]="transaction ? transaction.id : null"
		style="height: 500px"
		(editingComplete)="showBreakEditDialog = false"
		(durationUpdated)="breakLengthUpdated($event)"
	></app-break-edit>
</div>

<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="showBreakEditDialog || isUpdating || isPickingShift">
	<!-- DEPRECATED 2023-05-23 -->
	<!-- <div *ngIf="!isRecordValid" class="edit-record-not-found">
		<div>Record</div>
		<div>Not Found</div>
	</div> -->

	<div *ngIf="!isEditable">
		<div class="row">
			<div class="col-12">
				<div class="alert alert-danger">
					<div style="margin-bottom: 12px"><strong>Cannot Edit Time Entry</strong></div>
					<span *ngIf="recordExported">Record has been exported</span>
					<span *ngIf="employeeDeleted">Employee no longer exists</span>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="jobHasBeenDeleted">
		<div class="alert alert-danger">This time entry has been locked because the selected job has been deleted.</div>
	</div>

	<!-- DEPRECATED 2023-05-23 -->
	<!-- <form *ngIf="showForm" class="edit-form-block" novalidate [formGroup]="transForm" autocomplete="off"> -->
	<form class="edit-form-block" novalidate [formGroup]="transForm" autocomplete="off">
		<div *ngIf="modified" class="alert alert-warning" style="text-align: center">
			<strong>Time Entry Recently Modified</strong>
		</div>

		<div
			*ngIf="!isNew && transForm.value.exception && transForm.value.exception_type"
			class="alert alert-danger"
			style="color: rgb(92, 91, 91); line-height: 1.2em"
		>
			<div class="trans-alert-message">{{ transAlert.alertMessage() }}</div>
			<div *ngIf="transAlert.isPressedZero()" class="trans-alert-comment trans-alert-smalltext">
				<hr />
				{{ transAlert.jobsPresentedCount() }} jobs presented:<br />
				<ul style="margin-bottom: 0px">
					<li *ngFor="let jobName of transAlert.jobsPresented()">{{ jobName }}</li>
				</ul>
			</div>
			<div *ngIf="transAlert.showCallerId()" class="trans-alert-comment trans-alert-smalltext">
				<hr />
				<div style="margin-bottom: 12px">Caller ID Information</div>
				<div *ngIf="transAlert.checkInNumber()">
					Check In: {{ transAlert.checkInNumber() }}
					<span class="trans-alert-num-type" *ngIf="transAlert.checkInNumberType()"
						><small
							><strong>{{ transAlert.checkInNumberType() }}</strong></small
						></span
					>
				</div>
				<div *ngIf="transAlert.checkOutNumber()">
					Check Out: {{ transAlert.checkOutNumber() }}
					<span class="trans-alert-num-type" *ngIf="transAlert.checkOutNumberType()"
						><small
							><strong>{{ transAlert.checkOutNumberType() }}</strong></small
						></span
					>
				</div>
			</div>
		</div>

		<div class="form-group form-group-lg" [class.input-required]="isNew && !transForm.value.employee_id">
			<div class="input-label">
				<strong>Employee</strong>
				<span *ngIf="!transForm.value.employee_id" class="float-right">(required)</span>
			</div>
			<div *ngIf="isNew">
				<p-select
					appScrollLockSelect
					id="employee"
					[options]="employeesDropdown"
					formControlName="employee_id"
					[filter]="true"
					filterBy="label,data.external_id"
					[resetFilterOnHide]="true"
					[required]="true"
					(onChange)="employeeDropdownChanged()"
				></p-select>
			</div>
			<div *ngIf="!isNew" class="input-disabled">
				<span>{{ transForm.value.employee_name }}</span>
				<span *ngIf="!isEmployeeActive"> (Inactive)</span>
			</div>
		</div>

		<!-- BEGIN SHIFT BASED JOB PICKER -->
		<div *ngIf="!currentShiftInfo && !isOriginalEntryTravel" class="form-group form-group-lg" [class.input-required]="!transForm.value.job_id">
			<div class="input-label">
				<strong>Unscheduled Job</strong>
				<span *ngIf="!transForm.get('job_id').value" class="float-right">(required)</span>
			</div>
			<div>
				<p-select
					appScrollLockSelect
					id="shiftBasedJobDropdown"
					[options]="jobsDropdown"
					formControlName="job_id"
					[filter]="true"
					filterBy="label,data.external_id"
					[resetFilterOnHide]="true"
					[required]="true"
					(onChange)="jobDropdownChanged()"
				></p-select>
			</div>
		</div>
		<!-- END SHIFT BASED JOB PICKER -->

		<div style="margin-top: 20px">
			<div *ngIf="shouldShowJobDatePicker" class="options-block" [class.input-required]="!customJobDate">
				<div *ngIf="!customJobDate" class="input-required float-right">(required)</div>
				<div class="options-title-inline">{{ isCurrentEntryTravel ? 'Travel Date' : 'Job Date' }}</div>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('jobDate')"></i>
				<app-date-time-picker
					id="CUSTOM_JOB_DATE"
					[currentDate]="customJobDate"
					[pickerType]="'DATE'"
					[pickerMode]="'dialog'"
					[pickerLabel]="isCurrentEntryTravel ? 'Travel Date' : 'Job Date'"
					[format]="'ddd MMM Do, YYYY'"
					(pickerDidClose)="customJobDate = $event; updateJobDate()"
				>
				</app-date-time-picker>
			</div>

			<div *ngIf="!isCurrentEntryTravel" class="pick-shift-block" [class.pick-shift-pad]="!currentShiftInfo">
				<div *ngIf="currentShiftInfo" class="tts-hl-2" style="margin: 8px 0px 0px 0px">
					<div>{{ currentShiftInfo.jobName }}</div>
					<div *ngIf="currentShiftInfo.fillingIn" style="font-weight: 600; color: #87764e">
						<span>{{ currentShiftInfo.fillingIn }}</span>
						<span *ngIf="currentShiftInfo.recurName === 'Any Employee'"> ({{ currentShiftInfo.empCount }})</span>
					</div>
					<div>{{ currentShiftInfo.shift }}</div>
					<div [class.tts-hl-multiday]="isCurrentJobMultiday">{{ currentShiftInfo.summary }}</div>
					<div *ngIf="currentShiftInfo.oneTimeDate">{{ currentShiftInfo.oneTimeDate }}</div>
				</div>
				<div class="d-flex justify-content-between" style="margin-top: 18px">
					<button *ngIf="currentShiftInfo" class="btn btn-block btn-outline-secondary" style="margin-right: 12px" (click)="clearSelectedShift()">
						Clear Shift
					</button>
					<button class="btn btn-block btn-outline-secondary" (click)="startShiftPicker()">
						{{ currentShiftInfo ? 'Change Shift' : 'Select Shift' }}
					</button>
				</div>
			</div>

			<div *ngIf="customJobDate">
				<div class="options-block" [class.input-required]="isNew && !transForm.value.actual_start">
					<div>
						<div *ngIf="isNew && !transForm.value.actual_start" class="input-required float-right">(required)</div>
						<div class="options-title">Start Time</div>
					</div>
					<app-date-time-picker
						[currentDate]="transForm.value.actual_start"
						[pickerType]="'DATETIME'"
						[pickerMode]="'dialog'"
						[pickerLabel]="'Start Time'"
						[hour12Timer]="is12HourFormat"
						[format]="is12HourFormat ? 'ddd MMM Do [at] h:mm a' : 'ddd MMM Do [at] HH:mm'"
						[startAt]="transForm.get('actual_start').value ? null : startTimePickerStartAtDefault"
						(pickerDidClose)="setPickerDate('actual_start', $event)"
					>
					</app-date-time-picker>
					<div *ngIf="originalStartTime && originalStartTimeModified" class="job-time-info">
						<strong>Original:</strong>
						<span class="float-right">{{ originalStartTime }}</span>
					</div>
				</div>

				<div *ngIf="hasJobDateError" class="alert alert-danger">
					<strong>A valid Start Time may be no more than one day before or after the Job Date</strong>
				</div>

				<div class="options-block">
					<div>
						<div *ngIf="transForm.value.actual_end" class="clear-link float-right" (click)="clearEndTime()">clear</div>
						<div class="options-title">End Time</div>
					</div>
					<app-date-time-picker
						[currentDate]="transForm.value.actual_end"
						[pickerType]="'DATETIME'"
						[pickerMode]="'dialog'"
						[pickerLabel]="'End Time'"
						[hour12Timer]="is12HourFormat"
						[format]="is12HourFormat ? 'ddd MMM Do [at] h:mm a' : 'ddd MMM Do [at] HH:mm'"
						[startAt]="transForm.get('actual_end').value ? null : endTimePickerStartAtDefault"
						(pickerDidClose)="setPickerDate('actual_end', $event)"
					>
					</app-date-time-picker>
					<div *ngIf="originalEndTime && originalEndTimeModified" class="job-time-info">
						<strong>Original:</strong>
						<span class="float-right">{{ originalEndTime }}</span>
					</div>
				</div>
			</div>
		</div>

		<!-- Begin Alerts -->
		<div *ngIf="isFutureStartDate" class="alert alert-danger">
			<strong>Time entry is scheduled for a future date</strong>
		</div>

		<div class="shift-length-warning">
			<div *ngIf="hasShiftLengthWarning()" class="alert alert-danger"><strong>Warning: </strong>{{ shiftLengthWarning }}</div>
		</div>

		<div style="color: green; margin-bottom: 20px" *ngIf="doesShiftCrossDSTBoundary || showDSTSwitch">
			<div *ngIf="showDSTSwitch" class="float-right">
				<p-toggleswitch [(ngModel)]="dstShift" [ngModelOptions]="{ standalone: true }"></p-toggleswitch>
			</div>
			<strong [class.png-clr-gray]="showDSTSwitch && !dstShift">Adjust{{ showDSTSwitch ? '' : 'ed' }} for daylight savings</strong>
			<i
				*ngIf="showDSTSwitch"
				title="More Information"
				class="far fa-info-circle tts-info-btn"
				aria-hidden="true"
				(click)="showHelp('dstShift')"
			></i>
		</div>
		<!-- End Alerts -->

		<div class="form-group form-group-lg" style="margin-top: 30px">
			<div class="row">
				<div class="col-10">
					<div>
						<span *ngIf="!isCurrentEntryTravel">
							<strong>Shift Length</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('shiftLength')"></i>
						</span>
						<span *ngIf="isCurrentEntryTravel">
							<strong>Travel Time</strong>
							<!-- <i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('travelTime')"></i> -->
						</span>

						<span class="text-muted tsf-time-abbrv">(hr:min)</span>
					</div>
				</div>
				<div class="col-2">
					<div class="float-right">
						<strong class="tts-hl-3">{{ displayShiftLength() }}</strong>
					</div>
				</div>
			</div>
		</div>

		<!-- Begin non-travel shift length section -->
		<div *ngIf="!isCurrentEntryTravel">
			<div class="form-group form-group-lg">
				<div class="row">
					<div class="col-10">
						<div>
							<span class="link-text" style="font-weight: bold" (click)="openBreakLengthDialog()">Unpaid Breaks</span>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('unpaidBreaks')"></i>
							<span class="text-muted tsf-time-abbrv">(min)</span>
						</div>
					</div>
					<div class="col-2">
						<div class="float-right">
							<strong class="tts-hl-3">{{ displayBreakLength() }}</strong>
						</div>
					</div>
				</div>
			</div>

			<div *ngIf="useAdjustedTime" class="form-group form-group-lg">
				<div class="row">
					<div class="col-8">
						<div>
							<strong>Payable Hours</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('payableHours')"></i>
							<span class="text-muted tsf-time-abbrv">(hr:min)</span>
						</div>
					</div>
					<div class="col-4">
						<!-- <input hidden="true" (focus)="allowHoursWorkedCalculation=false" class="form-control adjust-time-input float-right" style="text-align: right" formControlName="adjustedTime"> -->
						<div class="float-right">
							<strong *ngIf="isOngoing" style="color: green">Ongoing</strong>
							<strong *ngIf="isMissing && !isOngoing" style="color: firebrick">Missing</strong>
							<strong *ngIf="isNoShow" style="color: firebrick">No Show</strong>
							<strong *ngIf="!(isOngoing || isMissing || isNoShow)" class="tts-hl-3">{{ transForm.get('adjustedTime').value }}</strong>
						</div>
					</div>
				</div>
			</div>

			<div *ngIf="false" style="margin-top: 10px">
				<hr class="bottom-divider" />
				<p-checkbox formControlName="enable_notifications" [binary]="true"></p-checkbox>
				<strong (click)="toggleCheckbox('enable_notifications')" class="has-pointer">Enable Notifications</strong>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('enableNotifications')"></i>
			</div>
		</div>
		<!-- End non-travel shift length section -->

		<!-- Admin Notes -->
		<hr class="bottom-divider" />
		<app-form-expander [style]="{ 'margin-top': '25px' }" [label]="'Admin Notes'" [centerOnExpansion]="true" [isExpanded]="expandAdminNotes">
			<div>
				<div style="margin-bottom: 22px">
					<!-- <div *ngIf="isFormValid()" style="margin-bottom: 22px"> -->
					<textarea
						[(ngModel)]="appendToAdminNotes"
						[ngModelOptions]="{ standalone: true }"
						placeholder="Add an admin note"
						class="form-control"
						style="margin-top: 12px"
						rows="3"
					></textarea>
				</div>
				<div *ngIf="!isEditingAdminNotes" class="notes-block">
					<div>{{ transForm.get('notes').value | adminNotesFormatter }}</div>
				</div>
				<div style="margin-bottom: 22px">
					<textarea
						*ngIf="canEditAdminNotes && isEditingAdminNotes && transaction?.notes"
						id="admin-notes-edit-textarea"
						formControlName="notes"
						class="form-control"
						rows="3"
					></textarea>
					<div *ngIf="canEditAdminNotes && transaction?.notes" style="margin-top: 18px">
						<div class="btn btn-sm btn-block btn-outline-primary" (click)="toggleAdminNotesEdit()">
							{{ isEditingAdminNotes ? 'done editing' : 'edit current notes' }}
						</div>
					</div>
				</div>
				<!-- <div *ngIf="!isFormValid() && canEditAdminNotes" style="margin-top: 20px">
					<div class="alert alert-warning">Notes may be edited after all required fields have benn set.</div>
				</div> -->
			</div>
		</app-form-expander>

		<!-- Employee Notes -->
		<app-form-expander [label]="'Employee Notes'" [centerOnExpansion]="true" [isExpanded]="expandEmployeeNotes">
			<div *ngIf="transForm.get('geo_start_emp_note').value || transForm.get('geo_end_emp_note').value">
				<div *ngIf="transForm.get('geo_start_emp_note').value" class="form-group form-group-lg">
					<div>
						<i
							class="far float-right link-text has-pointer"
							[class.fa-lock]="empNotesLock.geoStart"
							[class.fa-unlock]="!empNotesLock.geoStart"
							(click)="empNotesLock.geoStart = !empNotesLock.geoStart"
						></i>
						<div class="options-title">Employee Checkin Notes</div>
					</div>
					<div *ngIf="empNotesLock.geoStart" class="notes-block">{{ transForm.get('geo_start_emp_note').value | adminNotesFormatter }}</div>
					<textarea *ngIf="!empNotesLock.geoStart" formControlName="geo_start_emp_note" class="form-control" rows="3"></textarea>
				</div>
				<div *ngIf="transForm.get('geo_end_emp_note').value" class="form-group form-group-lg">
					<div>
						<i
							class="far float-right link-text has-pointer"
							[class.fa-lock]="empNotesLock.geoEnd"
							[class.fa-unlock]="!empNotesLock.geoEnd"
							(click)="empNotesLock.geoEnd = !empNotesLock.geoEnd"
						></i>
						<div class="options-title">Employee Checkout Notes</div>
					</div>
					<div *ngIf="empNotesLock.geoEnd" class="notes-block">{{ transForm.get('geo_end_emp_note').value | adminNotesFormatter }}</div>
					<textarea *ngIf="!empNotesLock.geoEnd" formControlName="geo_end_emp_note" class="form-control" rows="3"></textarea>
				</div>
			</div>
			<div *ngIf="transForm.get('emp_notes').value">
				<div>
					<i
						class="far float-right link-text has-pointer"
						[class.fa-lock]="empNotesLock.empNotes"
						[class.fa-unlock]="!empNotesLock.empNotes"
						(click)="empNotesLock.empNotes = !empNotesLock.empNotes"
					></i>
					<div class="options-title">System Notes</div>
				</div>

				<div *ngIf="empNotesLock.empNotes" c class="notes-block">{{ transForm.get('emp_notes').value | adminNotesFormatter }}</div>
				<textarea
					*ngIf="!empNotesLock.empNotes"
					formControlName="emp_notes"
					class="form-control"
					placeholder="no system notes"
					rows="3"
				></textarea>
			</div>
			<div *ngIf="!transForm.get('geo_start_emp_note').value && !transForm.get('geo_end_emp_note').value && !transForm.get('emp_notes').value">
				<strong>No employee notes submitted</strong>
			</div>
		</app-form-expander>

		<!-- Checkin Photos -->
		<app-form-expander [label]="'Check-In Photos'" [counter]="inPhotoCount" [centerOnExpansion]="true" [isExpanded]="expandPhotosCheckin">
			<app-file-uploader [fileUploadManager]="inFileUploadManager"></app-file-uploader>
		</app-form-expander>

		<!-- Checkout Photos -->
		<app-form-expander [label]="'Check-Out Photos'" [counter]="outPhotoCount" [centerOnExpansion]="true" [isExpanded]="expandPhotosCheckout">
			<app-file-uploader [fileUploadManager]="outFileUploadManager"></app-file-uploader>
		</app-form-expander>

		<!-- Pay Rates -->
		<div *ngIf="showPayRateOption">
			<app-form-expander [label]="'Bill / Pay Rates'" [centerOnExpansion]="true" [isExpanded]="expandPayRates">
				<!-- <hr style="margin-bottom: 22px" /> -->

				<!-- Employee Pay Rate -->
				<div>
					<div class="options-block form-spacer">
						<div class="float-right">
							<span class="pay-rate-symbol tts-hl-2">{{ currencySymbol }}</span>
							<input
								type="number"
								inputmode="decimal"
								[placeholder]="formatPlaceholderRate('pay_rate')"
								class="form-control pay-rate-input"
								formControlName="pay_rate"
								(blur)="formatRateInput('pay_rate')"
							/>
						</div>
						<div style="padding-top: 4px">
							<strong>Employee Pay Rate</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('empPayRate')"></i>
						</div>
					</div>
					<div *ngIf="!isNew" class="options-block form-spacer">
						<div class="pay-rate-source float-right" [class.pay-rate-from-trans]="transForm.get('pay_rate').value">
							{{ getPayRateSource('EMPLOYEE') }}
						</div>
						<strong>Pay Rate Source</strong>
					</div>
				</div>

				<!-- Client Bill Rate -->
				<div>
					<div class="options-block form-spacer">
						<div class="float-right">
							<span class="pay-rate-symbol tts-hl-2">{{ currencySymbol }}</span>
							<input
								type="number"
								inputmode="decimal"
								[placeholder]="formatPlaceholderRate('client_pay_rate')"
								class="form-control pay-rate-input"
								formControlName="client_pay_rate"
								(blur)="formatRateInput('client_pay_rate')"
							/>
						</div>
						<div style="padding-top: 4px">
							<strong>Client Bill Rate</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('clientPayRate')"></i>
						</div>
					</div>
					<div *ngIf="!isNew" class="options-block form-spacer">
						<div class="pay-rate-source float-right" [class.pay-rate-from-trans]="transForm.get('client_pay_rate').value">
							{{ getPayRateSource('CLIENT') }}
						</div>
						<strong>Bill Rate Source</strong>
					</div>
				</div>

				<!-- Vendor Pay Rate -->
				<div>
					<div class="options-block form-spacer">
						<div class="float-right">
							<span class="pay-rate-symbol tts-hl-2">{{ currencySymbol }}</span>
							<input
								type="number"
								inputmode="decimal"
								step=".01"
								[placeholder]="formatPlaceholderRate('vendor_pay_rate')"
								class="form-control pay-rate-input"
								formControlName="vendor_pay_rate"
								(blur)="formatRateInput('vendor_pay_rate')"
							/>
						</div>
						<div style="padding-top: 4px">
							<strong>Vendor Pay Rate</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('vendorPayRate')"></i>
						</div>
					</div>
					<div *ngIf="!isNew" class="options-block form-spacer">
						<div class="pay-rate-source float-right" [class.pay-rate-from-trans]="transForm.get('vendor_pay_rate').value">
							{{ getPayRateSource('VENDOR') }}
						</div>
						<strong>Pay Rate Source</strong>
					</div>
				</div>
			</app-form-expander>
		</div>

		<!-- Client / Vendor -->
		<app-form-expander *ngIf="hasClients || hasVendors" [label]="'Client / Vendor'" [centerOnExpansion]="true" [isExpanded]="expandClientVendor">
			<!-- Client Dropdown / Vendor Dropdown -->
			<div>
				<div *ngIf="hasClients" class="form-group">
					<div class="input-label">
						<strong>Client</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('clients')"></i>
						<!-- <span class="input-optional float-right">(optional)</span> -->
					</div>
					<p-select
						appScrollLockSelect
						id="clients"
						[options]="clientDropdown"
						formControlName="client_id"
						[filter]="true"
						filterBy="label"
					></p-select>
				</div>

				<div *ngIf="hasVendors" class="form-group">
					<div class="input-label">
						<strong>Vendor</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('vendors')"></i>
						<!-- <span class="input-optional float-right">(optional)</span> -->
					</div>
					<p-select
						appScrollLockSelect
						id="vendors"
						[options]="vendorDropdown"
						formControlName="vendor_id"
						[filter]="true"
						filterBy="label"
					></p-select>
				</div>
			</div>
		</app-form-expander>

		<!-- Copy Link / Contact Support -->
		<div *ngIf="transactionId" class="d-flex justify-content-between" style="margin-top: 20px">
			<button class="btn btn-sm btn-block btn-outline-secondary" style="margin-right: 6px" (click)="copyTransactionLink()">Copy Link</button>
			<button class="btn btn-sm btn-block btn-outline-secondary" style="margin-left: 6px" (click)="emailSupport()">Contact Support</button>
		</div>
		<!-- Shift Summary Public Link -->
		<div *ngIf="!isNew && !newUuid && !!transaction?.actual_start" style="margin-top: 12px">
			<button class="btn btn-sm btn-block btn-outline-danger" (click)="refreshPublicLink()">Refresh Shift Summary Report Public Link</button>
		</div>

		<div *ngIf="newUuid" style="margin-top: 20px; font-weight: 600">
			<div class="alert alert-warning">
				<span style="color: firebrick">Warning: </span> When you click <strong>Save</strong>, the existing public link to the
				<strong>Shift Summary Report</strong> for this time entry will be invalidated and no longer accessible. You can access the new link by
				viewing the report.
				<span class="link-text" (click)="newUuid = null">undo</span>
			</div>
		</div>

		<br />
	</form>
</div>

<p-dialog header="Shift Length" [(visible)]="showActualTimeWorkedDialog" [modal]="false" [style]="{ width: '300px' }" [dismissableMask]="true">
	Shift Length shows the total duration, in hours and minutes, between the check-in and check-out. It
	<strong style="color: firebrick">does not</strong> factor in any break time adjustments that may have been set for this job.
</p-dialog>

<p-dialog header="Shift Overlap" [(visible)]="overlapOverride.showDialog" [modal]="false" [style]="{ width: '300px' }" [dismissableMask]="true">
	<div class="tts-info-msg">
		Click <strong>Cancel</strong> to modify this time entry and deal with the overlap. Click <strong>Submit</strong> to bypass overlap protection
		and record this time entry as is.
	</div>
	<div style="margin-top: 18px">
		<button class="btn btn-sm btn-success float-right" (click)="submitOverlapOverride()">Submit</button>
		<button class="btn btn-sm btn-secondary" (click)="cancelOverlapOverride()">Cancel</button>
	</div>
</p-dialog>

<p-dialog
	header="Date Locked"
	[(visible)]="dateLockOverride.showDialog"
	[closable]="false"
	[modal]="false"
	[style]="{ width: '300px' }"
	[dismissableMask]="true"
>
	<div class="tts-info-msg">
		<span
			>This time entry has been date locked. Click <strong>Confirm</strong> to allow this record to be modified. Supervisors will be notified of
			the change.</span
		>
	</div>
	<div style="margin-top: 18px">
		<button class="btn btn-sm btn-success float-right" (click)="submitDateLockOverride()">Confirm</button>
		<button class="btn btn-sm btn-secondary" (click)="cancelDateLockOverride()">Cancel</button>
	</div>
</p-dialog>

<div *ngIf="false && !isUpdating">
	<button class="btn btn-sm btn-block btn-secondary" (click)="troubleshootRecord()">Validate Form</button>
	<div style="margin-top: 20px">
		<pre><code>{{ updateRecord | json }}</code></pre>
	</div>
</div>

<div class="container">
	<div *ngIf="false" class="alert alert-info" style="margin-top: 25px">
		<pre><code>{{ transForm.value | json }}</code></pre>
	</div>
</div>

<app-shift-log-picker
	*ngIf="isPickingShift"
	[jobId]="transForm.get('job_id').value"
	[empId]="transForm.get('employee_id').value"
	[jobDate]="transForm.get('job_date').value"
	[actualStart]="transForm.get('actual_start').value"
	[actualEnd]="transForm.get('actual_end').value"
	[timezone]="transForm.get('timezone').value"
	[schedLogId]="transForm.get('schedule_log_id').value"
	[empName]="transForm.get('employee_name').value"
	[jobName]="transForm.get('job_description').value"
	[dialogManager]="dialogManager"
	(closePicker)="cancelShiftPicker()"
	(shiftSelected)="shiftSelected($event)"
></app-shift-log-picker>
