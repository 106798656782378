<div class="ur-cli-block">
	<div class="ur-input-row">
		<div class="float-right ur-skip-btn-wrap">
			<div class="ur-skip-btn" style="top: 0px; margin-left: 4px" (click)="toggleSkipCheckbox.next(item)">
				{{ item.skip ? 'edit' : 'n/a' }}
			</div>
		</div>
		<div class="form-check-label ur-basic-label" [class.ur-label-disabled]="item.skip">{{ item.label }}</div>
	</div>
	<div *ngIf="!item.skip">
		<textarea
			#txtFieldxBasicTextArea
			rows="1"
			class="form-control ur-cli-comment"
			placeholder="Enter optional comments"
			[(ngModel)]="item.value"
			(input)="textResizer.next(txtFieldxBasicTextArea)"
		></textarea>
	</div>
</div>
