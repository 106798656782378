import { TransactionLogRecord } from '@app/models/transaction'
import { DisplayHelper } from '@app/helpers/display'
import { log } from '@app/helpers'

export type TransAlertType = 'PRESSED_ZERO' | 'EXPECTED_JOB_SITE_PHONE' | 'NOT_AT_SCHEDULED_JOB_SITE' | 'UNKNOWN_NUMBER_NOT_AT_JOB_SITE'

export class TransComment {
	jobs = []
	locations = []
	callerIDCheckIn = null
	callerIDCheckOut = null
	hasAnyScheduledJobs = null
	calledFromCellCheckIn = null
	calledFromCellCheckOut = null

	constructor(json: string) {
		let comment = null
		try {
			comment = JSON.parse(json)
		} catch (error) {
			console.log(error)
			return
		}
		if (comment) {
			for (const attr in comment) {
				if (comment.hasOwnProperty(attr)) {
					this[attr] = comment[attr]
				}
			}
		}
	}
}

export class TransAlert {
	trans: TransactionLogRecord
	type: TransAlertType
	comment: TransComment

	constructor(trans: TransactionLogRecord) {
		this.trans = trans
		this.type = trans.exception_type as TransAlertType
		this.comment = new TransComment(trans.exception_json)
	}

	isPressedZero(): boolean {
		if (this.type === 'PRESSED_ZERO') {
			return true
		}
		return false
	}

	jobsPresentedCount(): number {
		return this.comment.jobs.length
	}

	jobsPresented(): Array<string> {
		return this.comment.jobs.map((j) => j.name)
	}

	isUnknownNumber(): boolean {
		if (this.type === 'NOT_AT_SCHEDULED_JOB_SITE' || this.type === 'UNKNOWN_NUMBER_NOT_AT_JOB_SITE') {
			return true
		}
		return false
	}

	// Duplicate of isUnknownNumber - Refector/remove isUnknownNumber
	showCallerId(): boolean {
		if (this.type === 'EXPECTED_JOB_SITE_PHONE' || this.type === 'NOT_AT_SCHEDULED_JOB_SITE' || this.type === 'UNKNOWN_NUMBER_NOT_AT_JOB_SITE') {
			return true
		}
		return false
	}

	isCheckIn(): boolean {
		// return this.checkOutNumber() ? false : true
		return true
	}

	// Used for NOT_AT_SCHEDULED_JOB_SITE
	expectedJob(): string {
		const job = this.comment.jobs[0]
		if (job) {
			return job.name || 'Unassigned Job'
		}
		return 'Unassigned Job'
	}

	checkInNumberType(): string {
		if (this.comment.callerIDCheckIn) {
			if (this.comment.calledFromCellCheckIn) {
				return 'MOBILE'
			} else {
				return 'LANDLINE'
			}
		} else {
			return ''
		}
	}

	checkInNumber(): string {
		return DisplayHelper.displayPhone(this.comment.callerIDCheckIn)
	}

	checkOutNumberType(): string {
		if (this.comment.callerIDCheckOut) {
			if (this.comment.calledFromCellCheckOut) {
				return 'MOBILE'
			} else {
				return 'LANDLINE'
			}
		} else {
			return ''
		}
	}

	checkOutNumber(): string {
		return DisplayHelper.displayPhone(this.comment.callerIDCheckOut)
	}

	hasHighlight(): boolean {
		// if (this.type === 'PRESSED_ZERO') { return true }
		// if (this.type === 'UNKNOWN_NUMBER_NOT_AT_JOB_SITE') { return true }
		return false
	}

	hasEdit(): boolean {
		// if (this.type === 'PRESSED_ZERO') { return true }
		// if (this.type === 'UNKNOWN_NUMBER_NOT_AT_JOB_SITE') { return true }
		// return false
		return true
	}

	showIconInTransTable(): boolean {
		if (this.type === 'EXPECTED_JOB_SITE_PHONE') {
			return false
		}
		return true
	}

	alertMessage(): string {
		if (this.trans.exception) {
			const expectedJobName = this.expectedJob()
			const callerIdInfo = this.checkInNumber()
			switch (this.type) {
				case 'PRESSED_ZERO':
					return `Employee chose ‘other’ when presented with a choice of jobs.`
				case 'EXPECTED_JOB_SITE_PHONE':
					// return `Employee called from their mobile, but they were scheduled to call from a landline. Employee was prompted to confirm they were at the job site for ‘${expectedJobName}’ but they indicated they were somewhere else.`
					return `Employee called from their mobile, but they were scheduled to call from a landline. Employee was prompted to confirm they were at the job site for their expected job but they indicated they were somewhere else.`
				case 'NOT_AT_SCHEDULED_JOB_SITE':
					return `Employee called from an unrecognized phone number during a time when they were scheduled for job ‘${expectedJobName}’. Employee was prompted to confirm they were at the job site for this job, but they indicated they were somewhere else.`
				case 'UNKNOWN_NUMBER_NOT_AT_JOB_SITE':
					return `Employee called from phone number ${callerIdInfo} which is not linked to any job site.`
				default:
					return 'Unknown Alert Type'
			}
		} else {
			return ''
		}
	}
}
