import { DateTimeHelper, log } from '@app/helpers'
import { DistanceHelper } from './distance'
import { IReportImagesPayload, ReportImageFile } from './image'
import { ChecklistReport } from './report-checklist'
import { HomecareReport } from './report-homecare'
import { IncidentReport } from './report-incident'
import { DistanceUnitOption, UserReportType } from './report-user'
import { VehicleInspectionReport } from './report-vehicle'
import moment from 'moment-timezone'

export class Incident {
	id: number
	company_id: number
	employee_id: number
	job_site_id: number
	job_id: number
	created: string
	transaction_log_id: number
	audio_file: string
	transcript: string
	notes: string
	summary: string
	job_date: string
	images_json: string
	report_json: string
	report_type: string
	transcript_json: string
	employee_name: string
	job_description: string
	job_site_description: string
	admin_submitted: boolean
	admin_user_id: number
	admin_user_name: string
	updated: string
	updated_admin_user_id: number
	updated_admin_user_name: string

	mileage: number
	mileage_units: DistanceUnitOption

	confirmDelete = false
	isEditing = false
	origNotes = ''
	origTranscript = ''

	imageFiles: Array<ReportImageFile> = []

	uuid: string
	pinned: boolean = false

	customReportData: any

	constructor(record: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.origNotes = this.notes
			this.origTranscript = this.transcript
			this.imageFiles = this.getImages()
			this.created = DateTimeHelper.stripUtcTag(record.created)
			this.updated = DateTimeHelper.stripUtcTag(record.updated)
			if (this.report_type === 'CUSTOM' && this.report_json) {
				this.customReportData = JSON.parse(this.report_json)
			}
		}
	}

	get updatedOn(): string {
		return this.updated ?? this.created
	}

	get publicReportLink(): string {
		const origin = window.location.origin
		return `${origin}/#/reports/employee/${this.uuid}`
	}

	get formattedMileage(): string {
		if (!this.mileage) return ''
		return DistanceHelper.converter.setValue(this.mileage, this.mileage_units).getSmartDistanceString()
	}

	get reportIdentifier(): string {
		return this.created ? moment.utc(this.created).format('YY[-]MMDD[-]HHmm[-]ssSSS') : null
	}

	getImages(): Array<ReportImageFile> {
		const json = this.images_json
		if (!json) {
			return []
		}
		const imagesPayload: IReportImagesPayload = JSON.parse(json)
		if (imagesPayload) {
			const files = imagesPayload.files
			if (files && files.length > 0) {
				return imagesPayload.files.map((rec) => new ReportImageFile(rec))
			} else {
				return []
			}
		} else {
			return []
		}
	}

	getSummaryLabel(): string {
		switch (this.report_type as UserReportType) {
			case 'CHECKIN':
			case 'PRE_BREAK':
			case 'POST_BREAK':
			case 'CHECKOUT':
			case 'CUSTOM':
				return 'Comments'
			case 'HOMECARE':
				return 'Additional Notes'
			case 'INCIDENT':
				return 'What Happened'
			case 'VEHICLEINSPECTION':
				return 'Notes / Comments'
			default:
				return 'Summary Notes'
		}
	}

	getSummaryNote(): string {
		if (this.report_json) {
			const report = JSON.parse(this.report_json)
			if (report) {
				switch (this.report_type as UserReportType) {
					case 'SHIFT':
						return this.summary
					case 'CHECKIN':
					case 'PRE_BREAK':
					case 'POST_BREAK':
					case 'CHECKOUT':
					case 'CUSTOM':
						return (report as ChecklistReport).reportData?.summary?.additionalNotes?.value ?? ''
					case 'HOMECARE':
						return (report as HomecareReport).reportData?.summary?.additionalNotes?.value ?? ''
					case 'INCIDENT':
						return (report as IncidentReport).reportData?.describeIncident?.whatHappened?.value ?? ''
					case 'VEHICLEINSPECTION':
						return (report as VehicleInspectionReport).reportData?.additionalInspectionInformation?.notes?.value ?? ''
					default:
						return ''
				}
			}
		}
		return ''
	}
}

export class IncidentLogViewManager {
	version = 1
	selectedJobId: number = null
	selectedEmpId: number = null
	dayViewDate: Date = null
	timezone: string
	isLoading = false
	filterStartDate: Date = null
	filterEndDate: Date = null

	get isFilterRangeActive(): boolean {
		return !!this.filterStartDate && !!this.filterEndDate
	}

	setFilterRange(start: Date, end: Date) {
		this.filterStartDate = start
		this.filterEndDate = end
		this.dayViewDate = null
	}

	getFilterRangeStrings(): { start: string; end: string } | null {
		log('Filter Range', this.filterStartDate, this.filterEndDate)
		if (this.isFilterRangeActive) {
			return { start: moment(this.filterStartDate).format('YYYY-MM-DD'), end: moment(this.filterEndDate).format('YYYY-MM-DD') }
		}
		return null
	}

	clearFlterRange() {
		this.filterStartDate = null
		this.filterEndDate = null
		this.dayViewDate = null
	}
}
