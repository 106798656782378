<div [hidden]="hideBillingInfo" class="card card-body" style="margin-top: 20px">
	<div>
		<div class="float-right">
			<span *ngIf="trialDateInfo && !billingDateInfo" [class.trial-expired]="hasTrialExpired">{{ trialDateInfo }}</span>
			<span *ngIf="billingDateInfo">{{ billingDateInfo }}</span>
		</div>
		<div>
			<strong>{{ billingHeader }}</strong>
		</div>
	</div>
</div>
