import { Component, Input } from '@angular/core'
import { log } from '@app/helpers'
import { CoreService } from '@app/services'

@Component({
    selector: 'app-file-hash-compare',
    templateUrl: './file-hash-compare.component.html',
    styleUrls: ['./file-hash-compare.component.scss'],
    standalone: false
})
export class FileHashCompareComponent {
	@Input() sha256: string = null

	constructor(private coreSrvc: CoreService) {}

	public onFileDropped($event) {
		this.processFiles($event)
	}

	public fileBrowseHandler(event: any) {
		const target = event.target as HTMLInputElement
		const files = target.files as unknown as Array<any>
		this.processFiles(files)
	}

	processFiles(files: Array<any>) {
		const fileArray = []

		for (const item of files) {
			fileArray.unshift(item)
		}

		if (files.length > 1) {
			this.coreSrvc.notifySrvc.notify('error', 'Too Many Files', 'You may only select a single file for comparison.')
			return
		}

		const file = fileArray.pop()

		this.hashFile(file).then((hash) => {
			if (hash && hash === this.sha256) {
				this.coreSrvc.notifySrvc.notify('success', 'File Matches', 'The file you selected matches the stored file.')
			} else {
				this.coreSrvc.notifySrvc.notify('error', 'File Mismatch', 'The file you selected does not match the stored file.')
			}
		})
	}

	private hashFile(file: File): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			const reader = new FileReader()
			reader.onload = () => {
				const buffer: any = reader.result
				crypto.subtle
					.digest('SHA-256', buffer)
					.then((hashBuffer) => {
						const hashArray = Array.from(new Uint8Array(hashBuffer))
						const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('')
						resolve(hashHex)
					})
					.catch(() => {
						log('Encountered error hashing file object.')
						resolve(null)
					})
			}
			reader.onerror = reject
			reader.readAsArrayBuffer(file)
		})
	}
}
