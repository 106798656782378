export class NavbarIcon {
	id = ''
	title = 'Title'
	label = 'Label'
	imageUrl: string
	class: string
	width: string
	height: string
	styleAttr: any
	classAttr: string

	visible = () => true
	click = () => null
}
