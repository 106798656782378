import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute, Params } from '@angular/router'

import { DatabaseService, SettingsService, TransactionService } from '@app/services'

import { log } from '@app/helpers'
import { environment } from '@env/environment'

@Component({
    selector: 'app-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss'],
    standalone: false
})
export class RedirectComponent implements OnInit {
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private dbSrvc: DatabaseService,
	) {}

	ngOnInit() {
		// Various special redirects managed here
		// showIssues opens transactions view with show issues filter run
		// filterTransaction and transId go together => http://<localhost:4200>/#/redirect?filterTransaction=true&transId=918167

		setTimeout(() => {
			this.activatedRoute.queryParams.subscribe((params: Params) => {
				let url = params['url']
				const stripeInvoice = params['stripeInvoice']
				const showIssues = params['showIssues']
				const transId = parseInt(params['transId'], 10)
				const filterTransaction = params['filterTransaction']
				if (filterTransaction && transId) {
					url = '/admin/transactions'
					this.dbSrvc.tranSrvc.filterText = `#transId:${transId}`
					this.dbSrvc.tranSrvc.requiredId = transId
				}
				if (showIssues) {
					this.dbSrvc.tranSrvc.loadIssues = true
				}
				if (url) {
					this.dbSrvc.settingSrvc.setRedirectUrl(url)
					this.router.navigate(['/admin/dashboard'])
					return
				}
				if (stripeInvoice) {
					const invoiceUrl = `https://${environment.reportsDomain}/${stripeInvoice}`
					log('Stripe Invoice URL', invoiceUrl)
					window.location.href = invoiceUrl
					return
				}
				log(url)
			})
		}, 350)
	}
}

// http://localhost:4200/#/redirect?filterTransaction=true&transId=917545
// https://reports.tts-stage.com/ac69b0b2-9a50-45fd-912b-89a901f13b8d.html
// http://localhost:4200/#/redirect?stripeInvoice=ac69b0b2-9a50-45fd-912b-89a901f13b8d.html
