<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<div>
		<!-- <div class="options-checkbox">
			<div class="row">
				<div class="col-2">
					<p-checkbox [disabled]="hasEmployeesLinkedToJobSites" [(ngModel)]="blockCellCalls" [binary]="true"></p-checkbox>
				</div>
				<div class="col-10">
					<div style="margin-left:-20px;display:inline-block;">
						<strong class="has-pointer" [class.disabled]="hasEmployeesLinkedToJobSites" (click)="toggleBlockCalls()">Block calls from employee mobile phones</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('blockCellCalls')"></i>
					</div>
				</div>
			</div>
		</div> -->

		<div class="checkbox-item d-flex justify-content-between">
			<div class="checkbox-wrap">
				<p-checkbox [disabled]="hasEmployeesLinkedToJobSites" [(ngModel)]="blockCellCalls" [binary]="true"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleBlockCalls()" class="has-pointer" [class.disabled]="hasEmployeesLinkedToJobSites"
					>Block incoming calls to system from employee mobile phones</strong
				>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('blockCellCalls')"></i>
			</div>
		</div>

		<div class="checkbox-item d-flex justify-content-between">
			<div class="checkbox-wrap">
				<p-checkbox [(ngModel)]="useWhitelist" [binary]="true"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="useWhitelist = !useWhitelist" class="has-pointer">Only allow calls from numbers that are linked to a Job Site</strong>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('useWhitelist')"></i>
			</div>
		</div>

		<div *ngIf="hasEmployeesLinkedToJobSites" class="alert alert-warning" style="margin-top: 15px">
			If you wish to block calls from employee mobile phones you will first need to unlink all employees from their respective job sites.
		</div>

		<!-- <div class="options-checkbox">
			<div class="row">
				<div class="col-2">
					<p-checkbox [(ngModel)]="useWhitelist" [binary]="true"></p-checkbox>
				</div>
				<div class="col-10">
					<div style="margin-left:-20px;display:inline-block;">
						<strong class="has-pointer" (click)="useWhitelist = !useWhitelist">Only allow calls from numbers that are linked to a Job Site</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('useWhitelist')"></i>
					</div>
				</div>
			</div>
		</div> -->
	</div>

	<div *ngIf="!useWhitelist">
		<hr style="margin: 25px 0px" />

		<div class="form-group">
			<div class="form-label tts-hl-2">Block specific phone numbers</div>
			<table width="100%">
				<tr>
					<td style="width: 70px">
						<select #dialingCodeMenu class="form-control" [(ngModel)]="countryCode" (change)="formatPhone(newNumber)">
							<option *ngFor="let code of countryCodeData" value="{{ code.id }}">{{ code.id }}</option>
						</select>
					</td>
					<td>&nbsp;&nbsp;</td>
					<td colspan="3" style="max-width: 275px">
						<input
							#newNumber
							(keyup)="formatPhone(newNumber)"
							id="newNumber"
							class="form-control"
							[class.is-valid]="isPhoneValid(newNumber)"
							type="tel"
							autocomplete="off"
						/>
					</td>
				</tr>
			</table>
			<div style="text-align: center; margin-top: 20px">
				<button
					(click)="onAddNumber(newNumber)"
					(keyup.enter)="onAddNumber(newNumber); dialingCodeMenu.focus(); (false)"
					class="btn btn-sm btn-block btn-success"
					style="margin: 10px 0px 10px 0px"
				>
					Add Number
				</button>
			</div>
		</div>

		<!-- <hr> -->

		<div>
			<div *ngIf="hasListChanged" class="alert alert-warning">Changes have been made, make sure to click Save to commit these changes.</div>
		</div>

		<div>
			<h4 *ngIf="blackList.length > 0" class="tts-hl-2">Blocked Numbers</h4>
			<div *ngIf="blackList.length > 0" class="form-group" style="margin-top: 15px">
				<div class="btn btn-sm btn-block btn-phone" *ngFor="let entry of blackList; let idx = index">
					<div>
						{{ entry.number | displayPhone }}
						<i (click)="onRemoveNumber(idx)" class="fa fa-times float-right btn-phone-x" aria-hidden="true"></i>
					</div>
					<div *ngIf="isEmployee(idx)" class="tts-hl-2">
						<span style="padding-right: 13px"
							><strong>{{ entry.name }}</strong></span
						>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="useWhitelist" class="whitelist-note">
		<div class="alert alert-warning">
			Your current settings will block any phone numbers not linked to a job site.<span *ngIf="blockCellCalls">
				You are also blocking calls from employee mobile phones.</span
			><br /><br />
			If you wish to allow an employee to call in from a landline phone please make sure it is linked to a job site.<span *ngIf="!blockCellCalls">
				If you wish to allow employees to use their mobile phone, make sure the employee is linked to a job site.</span
			><span *ngIf="blockCellCalls">
				If you wish to allow employees to call in from their mobile phone you will need to uncheck the box for
				<strong>Block calls from employee mobile phones</strong> and make sure the employee is linked to a job site.</span
			>
		</div>
	</div>
</div>
