<!-- <div>Duration In Min: {{ tourRepeatDurationInMin }}</div> -->

<div *ngIf="tour.type === 'STRUCTURED'" class="alert alert-info shift-start-note">
	Set start / end times to view an example tour schedule. Please note, the actual tour schedule will be setup relative to when the employee clocks in
	for their shift.
</div>

<div *ngIf="tour.type === 'STRUCTURED'" style="margin: 20px 0px 40px 0px">
	<div>
		<div class="float-right" style="width: 140px">
			<app-date-time-picker
				[currentDate]="startTime"
				[pickerType]="'TIME'"
				[pickerMode]="'dialog'"
				[pickerLabel]="'Start Time'"
				[format]="timeFormat"
				[hour12Timer]="true"
				(pickerDidClose)="setTourStartTimeFromPicker($event)"
			>
			</app-date-time-picker>
		</div>
		<div class="options-section-header shift-start-label">Start Time</div>
	</div>

	<div style="margin-top: 28px">
		<div class="float-right" style="width: 140px">
			<app-date-time-picker
				[currentDate]="endTime"
				[pickerType]="'TIME'"
				[pickerMode]="'dialog'"
				[pickerLabel]="'End Time'"
				[format]="timeFormat"
				[hour12Timer]="true"
				(pickerDidClose)="setTourEndTimeFromPicker($event)"
			>
			</app-date-time-picker>
		</div>
		<div class="options-section-header shift-start-label">End Time</div>
	</div>
</div>

<!-- *ngIf="tourRepeatDurationInMin > 0 || (tour.type === 'STRUCTURED' && startTime && endTime)" -->

<div class="alert alert-bg-gray">
	<div *ngFor="let tourItem of tourList; let tourIdx = index">
		<div class="tour-block" [class.mt-0]="tourIdx === 0">
			<div class="tour-label">Tour {{ tourIdx + 1 }} starts at {{ tourItem.startTimeFormatted }}</div>
			<div *ngFor="let wp of waypoints; let wpIdx = index">
				<div *ngIf="shouldShowWaypoint(tourIdx, wp)" class="cp-label">
					Checkpoint {{ wpIdx + 1 }} at {{ formatTimeForCheckpoint(tourIdx, wp) }}
				</div>
			</div>
		</div>
	</div>
</div>

<!-- <hr />
<div>
	<pre>
		<code>
			{{ tourList | json }}
		</code>
	</pre>
</div> -->

<!-- <hr> -->
<!-- <div>Tour</div>
<div>
	<pre>
		<code>
			{{ tour | json }}
		</code>
	</pre>
</div> -->

<!-- <hr />
<div>Waypoints</div>
<div>
	<pre>
		<code>
			{{ waypoints | json }}
		</code>
	</pre>
</div> -->
