import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { DataTablesHelper, DisplayHelper, TableActionFormatter, log } from '@app/helpers'
import { SMSLogRecord, CommLogViewManager, ComponentBridgeName, ContactSupportEvent } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { SelectItem } from 'primeng/api'
import { CommLogSMSTableFormatter } from './comm-log-sms-table.formatter'

import moment from 'moment-timezone'
import { Subscription } from 'rxjs'

enum SMSTableColumnIndex {
	id = 0,
	date,
	inOut,
	smsFrom,
	smsTo,
	message,
	companyName,
	status,
	actions,
}

@Component({
    selector: 'app-comm-log-sms-table',
    templateUrl: './comm-log-sms-table.component.html',
    styleUrls: ['./comm-log-sms-table.component.scss'],
    standalone: false
})
export class CommLogSMSTableComponent implements OnInit, OnDestroy, AfterViewInit {
	bridgeName: ComponentBridgeName = 'ngBridgeCallLogSmsTable'

	list: Array<SMSLogRecord> = []
	viewManager: CommLogViewManager

	viewLogAction = {
		header: 'View Comm Log',
		footer: '',
		callLogId: null,
		showDialog: false,
	}

	private hasGlobalAccount = false
	private smsTable // : DataTables.DataTable;
	private defaultSortOrder = [[SMSTableColumnIndex.date, 'desc']]
	private tableFormatter: CommLogSMSTableFormatter

	timezoneOptions: Array<SelectItem> = []

	private authLinkRegex = /token=[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}/gi
	private isInternalUser = false

	private subs = new Subscription()

	constructor(
		private cd: ChangeDetectorRef,
		private coreSrvc: CoreService,
	) {
		this.viewManager = this.coreSrvc.dbSrvc.commLogSrvc.viewManager
		this.tableFormatter = new CommLogSMSTableFormatter(this.coreSrvc.dbSrvc)
		this.hasGlobalAccount = this.coreSrvc.dbSrvc.settingSrvc.hasGlobalAccount()
		this.isInternalUser = this.coreSrvc.dbSrvc.settingSrvc.isInternalUser()
		this.setupSubscriptions()
	}

	public ngOnInit(): void {
		window[this.bridgeName] = this
		this.fetchAndReload()
	}

	public ngOnDestroy() {
		this.smsTable['fixedHeader'].disable()
		window[this.bridgeName] = null
		this.subs.unsubscribe()
	}

	public ngAfterViewInit() {
		this.initTable()

		$('#smsTable_filter input').attr('placeholder', ' filter')
		$('#smsTable_filter input').addClass('search-field-input')

		setTimeout(() => {
			$('#clear-search-icon').detach().appendTo('#smsTable_filter label')
		})
	}

	private setupSubscriptions() {
		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	private handleScreenSizeChanges() {
		if (this.smsTable) {
			this.smsTable.columns.adjust().responsive.recalc()
			this.smsTable.fixedHeader.adjust()
		}
	}

	public clearSearch(): boolean {
		this.smsTable
			?.search('')
			.order([[SMSTableColumnIndex.date, 'desc']])
			.rows()
			.invalidate()
			.draw()
		this.coreSrvc.displaySrvc.enableAllTooltips()
		return false
	}

	private loadData() {
		this.list = this.coreSrvc.dbSrvc.commLogSrvc.getSmsRecords()
	}

	public updateTable() {
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadData()
			this.updateColumns()
		})
	}

	private updateColumns() {
		this.smsTable.clear()
		this.smsTable.rows.add(this.list)
		this.smsTable.draw(false)
	}

	public resetPage(reDraw: boolean) {
		this.smsTable.page(0)
		if (reDraw) {
			this.smsTable.draw(false)
		}
	}

	public fetchAndReload() {
		const options = this.makeRequestOptions()
		this.coreSrvc.dbSrvc.readTable('sms_log', options).then((readSuccess) => {
			this.updateTable()
		})
	}

	private makeRequestOptions(): Object {
		const dayViewDate = this.viewManager.dayViewDate
		if (dayViewDate) {
			const dateStr = moment(dayViewDate).format('YYYY-MM-DD')
			const timezone = this.viewManager.timezone
			const start = moment.tz(dateStr, timezone).startOf('day').toISOString()
			const end = moment.tz(dateStr, timezone).endOf('day').toISOString()
			return { start_date: start, end_date: end }
		}
		return {}
	}

	// public viewCallLog(id: number) {
	// 	const rec = this.coreSrvc.dbSrvc.callLogSrvc.getSmsRecordById(id)
	// 	const date = this.tableFormatter.date(rec.created, this.viewManager.timezone)
	// 	const time = this.tableFormatter.time(rec.created, this.viewManager.timezone)
	// 	this.viewLogAction.header = `${date} @ ${time}`
	// 	this.viewLogAction.footer = `${rec.provider_id}`
	// 	this.viewLogAction.callLogId = rec.id
	// 	this.viewLogAction.showDialog = true
	// }

	public contactSupport(id: number) {
		const event = new ContactSupportEvent('SMS Debug', 'sms_log', id)
		this.coreSrvc.eventSrvc.contactSupportEvent.next(event)
		// this.coreSrvc.dbSrvc.settingSrvc.emailSupportForGenericRecord('SMS Debug', 'sms_log', id)
	}

	private initTable() {
		this.coreSrvc.displaySrvc.startSectionLoader()
		this.smsTable = $('#smsTable').DataTable(<DataTables.Settings>{
			stateSave: false,
			responsive: true,
			processing: true,
			deferRender: true,
			paging: true,
			pageLength: 50,
			lengthChange: true,
			info: true,
			select: false,
			searching: true,
			fixedHeader: DataTablesHelper.fixedHeader,
			autoWidth: false,
			data: this.list,
			order: this.defaultSortOrder,
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{
					responsivePriority: 1,
					targets: [SMSTableColumnIndex.date],
				},
				// {
				// 	responsivePriority: 2,
				// 	targets: [ ]
				// },
				{
					// ID
					targets: SMSTableColumnIndex.id,
					visible: false,
					searchable: true,
					title: 'ID',
					data: null,
					render: (rec: SMSLogRecord, type, full, meta) => {
						return `${rec.id}`
					},
				},
				{
					// Date
					targets: SMSTableColumnIndex.date,
					visible: true,
					searchable: true,
					title: 'Date',
					data: null,
					render: (rec: SMSLogRecord, type, full, meta) => {
						const created = rec.created
						const date = this.tableFormatter.date(rec.created, this.viewManager.timezone)
						const time = this.tableFormatter.time(rec.created, this.viewManager.timezone)
						const result = `<span style="display: none">${created}</span>${date}<br>${time}`
						return `
						<div class="dtr-control-content">
							${result}
						</div>`
					},
				},
				{
					// In/Out
					targets: SMSTableColumnIndex.inOut,
					visible: true,
					searchable: true,
					title: 'In / Out',
					data: null,
					render: (rec: SMSLogRecord, type, full, meta) => {
						const direction = rec.direction === 'IN' ? 'Inbound' : 'Outbound'
						const tagStyle = direction === 'Inbound' ? 'backgroun-color: #8135a3' : 'background-color: #a38535'
						return `<div class="table-tag table-tag-c2c-details" style="${tagStyle}">${direction}</div>`
					},
				},
				{
					// SMS From
					targets: SMSTableColumnIndex.smsFrom,
					visible: true,
					searchable: true,
					title: 'SMS From',
					data: null,
					render: (rec: SMSLogRecord, type, full, meta) => {
						return this.tableFormatter.callLookup(rec.from)
					},
				},
				{
					// SMS To
					targets: SMSTableColumnIndex.smsTo,
					visible: true,
					searchable: true,
					title: 'SMS To',
					data: null,
					render: (rec: SMSLogRecord, type, full, meta) => {
						// return this.tableFormatter.callTo(rec)
						return this.tableFormatter.callLookup(rec.to)
					},
				},
				{
					// Message
					targets: SMSTableColumnIndex.message,
					visible: true,
					searchable: true,
					title: 'Message',
					data: null,
					render: (rec: SMSLogRecord, type, full, meta) => {
						let message = rec.body
						if (!this.isInternalUser && message.includes('auth?') && this.authLinkRegex.test(message)) {
							message = message.replace(this.authLinkRegex, 'token=[protected]')
						}

						const messageHtml = message ? `<div class="trans-note-block hide-scrollbars">${message}</div>` : ''
						return messageHtml
					},
				},
				{
					// Company Name
					targets: SMSTableColumnIndex.companyName,
					visible: this.hasGlobalAccount,
					searchable: this.hasGlobalAccount,
					title: 'Company',
					data: null,
					render: (rec: SMSLogRecord, type, full, meta) => {
						return rec.company_name
					},
				},
				{
					// Status
					targets: SMSTableColumnIndex.status,
					visible: true,
					searchable: true,
					title: 'Status',
					data: null,
					render: (rec: SMSLogRecord, type, full, meta) => {
						return this.tableFormatter.status(rec, this.viewManager.timezone)
					},
				},
				{
					// Actions
					targets: SMSTableColumnIndex.actions,
					visible: true,
					searchable: true,
					title: 'Actions',
					data: null,
					render: (rec: SMSLogRecord, type, full, meta) => {
						const supportLink = TableActionFormatter.supportLink(this.bridgeName, 'contactSupport', rec.id, 0)

						return `<span class="act-ico-wrap">${supportLink}</span>`
					},
				},
			],
			drawCallback: (settings) => {
				this.smsTable?.columns.adjust().responsive.recalc()
				this.coreSrvc.displaySrvc.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
			},
		})

		// Add default table event setup
		DisplayHelper.setupDefaultTableEvents(this.smsTable)

		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter('smsTable', this.smsTable)
	}
}
