import { environment } from '@env/environment'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { log, RecordBuilder } from '@app/helpers'
import { Checkpoint, DistanceHelper, Incident, JobSiteRecord, ReportRendererConfig, TransactionLogRecord } from '@app/models'
import { LambdaService, UserLoginService } from '@app/services'

import AWS from 'aws-sdk/global'

type ShiftReportPublicViewType = 'SHIFTREPORT' | 'SHIFTSUMMARYREPORT'

@Component({
    selector: 'app-shift-report-public-view',
    templateUrl: './shift-report-public-view.component.html',
    styleUrls: ['./shift-report-public-view.component.scss'],
    standalone: false
})
export class ShiftReportPublicViewComponent implements OnInit {
	loadingMessage = 'Initializing...'
	isDataLoaded = false
	hasError = false

	uuid: string
	reportType: ShiftReportPublicViewType = 'SHIFTREPORT'
	config: ReportRendererConfig

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private userSrvc: UserLoginService,
		private lambdaSrvc: LambdaService,
	) {}

	get reportTypeMsg(): string {
		const pathComponent = this.route.snapshot.params['reportType']
		switch (pathComponent) {
			case 'employee':
				return 'Shift Report ID:'
			case 'shiftsummary':
				return 'Shift Summary Report ID:'
			default:
				return ''
		}
	}

	ngOnInit(): void {
		log('GOT TO REPRORTS PUBLIC VIEW')
		const uuid = this.route.snapshot.params['uuid']
		const reportType = this.route.snapshot.params['reportType']
		this.reportType = reportType === 'shiftsummary' ? 'SHIFTSUMMARYREPORT' : 'SHIFTREPORT'
		this.uuid = uuid
		if (!reportType || !uuid) {
			this.router.navigate(['/'])
		}

		setTimeout(() => {
			this.loadingMessage = 'Preparing Report...'
			if (this.uuid) {
				this.userSrvc.isAuthenticated$().then((authenticationSuccess) => {
					log('Is logged in', authenticationSuccess)
					if (authenticationSuccess) {
						this.userSrvc.refresh().then((refreshSuccess) => {
							if (refreshSuccess) {
								this.sendRequest()
							} else {
								log('Error refreshing credentials')
							}
						})
					} else {
						this.updateAwsCredentials()
					}
				})
			}
		}, 1500)
	}

	updateAwsCredentials() {
		const region = environment.region
		const identityPoolId = environment.identityPoolId

		AWS.config.update({ region: region })
		const credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: identityPoolId })

		AWS.config.credentials = credentials
		log('AWS Credentials', credentials)

		// I you need to force load/check credentials uncomment following line
		AWS.config.getCredentials((err) => {
			if (err) {
				log(err)
			} else {
				this.sendRequest()
			}
		})
	}

	sendRequest() {
		const request = { operation: 'readbyuuid', params: { uuid: this.uuid, type: this.reportType } }
		log('Request to send', request)
		this.lambdaSrvc
			.employeeReportRequest(request)
			.then((result) => {
				log('Lambda Result', result)
				if (result.success) {
					switch (this.reportType) {
						case 'SHIFTREPORT':
							this.processShiftReportResultData(result.data)
							break
						case 'SHIFTSUMMARYREPORT':
							this.processShiftSummaryReportResultData(result.data)
							break
					}
				} else {
					this.hasError = true
				}
			})
			.catch((error) => {
				log('Response error', error)
				this.hasError = true
			})
	}

	processShiftReportResultData(data: any) {
		log('Process Shift Report Result', data)
		if (data) {
			const incidentData = data.incident_log
			if (incidentData) {
				// Stringify Images Json
				const imagesJson = incidentData.images_json
				if (imagesJson) {
					incidentData.images_json = JSON.stringify(imagesJson)
				}
				// Stringify Report Json
				const reportJson = incidentData.report_json
				if (reportJson) {
					incidentData.report_json = JSON.stringify(reportJson)
				}
				const incident = new Incident(incidentData)
				// this.incident = incident

				const configData = data.reportConfig
				if (configData) {
					this.config = ReportRendererConfig.buildPublicShiftReportConfig(configData, incident)
				}
			}

			// log('Final Incident', this.incident)
			log('Final Config', this.config)
			this.isDataLoaded = true
		}
	}

	processShiftSummaryReportResultData(data: any) {
		log('Process Shift Summary Report', data)
		if (data) {
			const configData = data.reportConfig ? data.reportConfig : null
			const checkpointsData = data.checkpoints ? JSON.parse(data.checkpoints) : null
			const checkpoints = checkpointsData ? RecordBuilder.buildRecords(checkpointsData) : []
			const jobSiteData = data.jobSite ? JSON.parse(data.jobSite) : null
			const jobSites = jobSiteData ? RecordBuilder.buildRecords(jobSiteData) : []
			const shiftReportData = data.shiftReports ? JSON.parse(data.shiftReports) : null
			const shiftReports = shiftReportData ? RecordBuilder.buildRecords(shiftReportData) : []
			const transactionData = data.transaction ? JSON.parse(data.transaction) : []
			const transaction = transactionData ? RecordBuilder.buildRecords(transactionData) : []

			const reportData = {
				jobSite: jobSites.map((js) => new JobSiteRecord(js)).shift() ?? null,
				transaction: transaction.map((tran) => new TransactionLogRecord(tran)).shift() ?? null,
				checkpoints: checkpoints.map((cp) => new Checkpoint(cp)),
				shiftReports: shiftReports.map((sr) => new Incident(sr)),
			}

			this.config = ReportRendererConfig.buildPublicShiftSummaryReportConfig(configData, reportData)
			DistanceHelper.converter.setUnitType(this.config.defaultUnits)
			this.isDataLoaded = true
		}
	}
}
