<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating">
	<div *ngIf="!isNew" style="margin-bottom: 20px">
		<div class="float-right">
			<button class="btn btn-sm btn-success" style="width: 50px" (click)="copySetupCode()">
				<i class="fa fa-clipboard"></i>
			</button>
		</div>
		<div class="options-section-header">
			<span>Station Setup Link</span>
			<i
				title="More Information"
				class="far fa-info-circle tts-info-btn"
				style="font-size: 1rem"
				aria-hidden="true"
				(click)="showHelp('setupLink')"
			></i>
		</div>
	</div>

	<form class="edit-form-block" novalidate [formGroup]="stationForm" (keydown.enter)="$event.preventDefault()" autocomplete="off">
		<div class="options-block" [class.input-required]="!stationForm.get('description').value">
			<div class="options-title-inline">Station Name</div>
			<div *ngIf="!stationForm.get('description').value" class="float-right">(required)</div>
			<input type="text" class="form-control" autocomplete="off" formControlName="description" />
		</div>

		<div class="options-block">
			<div class="options-title-inline">Allowed Employees</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('employee_ids')"></i>
			<app-multi-select-details
				[type]="'EMP'"
				placeholder="All Active Employees"
				selectedItemsLabel="Employees Selected: {0}"
				[options]="employeeOptions"
				[model]="stationForm.get('employee_ids').value"
				[group]="true"
				(modelChange)="stationForm.get('employee_ids').setValue($event)"
			>
			</app-multi-select-details>
		</div>

		<div class="options-block">
			<div class="options-title-inline">Allowed Jobs</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('job_ids')"></i>
			<app-multi-select-details
				[type]="'JOB'"
				placeholder="All Active Jobs"
				selectedItemsLabel="Jobs Selected: {0}"
				[options]="jobOptions"
				[group]="true"
				[model]="stationForm.get('job_ids').value"
				(modelChange)="stationForm.get('job_ids').setValue($event)"
			>
			</app-multi-select-details>
		</div>

		<div class="options-block">
			<div class="checkbox-item d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-checkbox formControlName="allow_default_job" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<strong (click)="toggleCheckbox('allow_default_job')" class="has-pointer">Include the default job</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('allow_default_job')"></i>
				</div>
			</div>
		</div>

		<div class="options-block">
			<div class="options-title-inline">Notes</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('details')"></i>
			<div>
				<textarea class="options-textarea" formControlName="details" placeholder="Enter notes"></textarea>
			</div>
		</div>

		<!-- Advanced Block -->
		<!-- <hr style="margin-top: 28px" /> -->

		<app-form-expander [label]="'Advanced Options'" [centerOnExpansion]="true" [(isExpanded)]="showAdvancedOptions">
			<div class="options-block">
				<div *ngIf="!stationForm.get('admin_code').value" class="input-optional float-right">optional</div>
				<div class="options-title-inline">Admin PIN</div>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('admin_code')"></i>
				<input type="text" class="form-control" autocomplete="off" formControlName="admin_code" (input)="adminCodeValidator()" />
			</div>

			<div class="options-block">
				<div *ngIf="!stationForm.get('ip_allowlist').value" class="input-optional float-right">optional</div>
				<div class="options-title-inline">IP Address / CIDR</div>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('ip_allowlist')"></i>
				<input type="text" class="form-control" autocomplete="off" formControlName="ip_allowlist" />
			</div>

			<div class="options-section-header" style="margin-top: 25px">
				Station Mode
				<i
					title="More Information"
					class="far fa-info-circle tts-info-btn"
					style="font-size: 1rem; margin-left: 0px"
					aria-hidden="true"
					(click)="showHelp('station_mode')"
				></i>
			</div>

			<div class="radio-wrap" style="margin: 14px 0 18px">
				<div class="form-check form-check-inline">
					<input
						type="radio"
						class="form-check-input"
						id="kioskModeFalse"
						[value]="false"
						formControlName="kiosk_mode"
						(change)="processKioskModeRadio()"
					/>
					<label
						class="form-check-label has-pointer"
						[class.tts-hl-2]="!stationForm.get('kiosk_mode').value"
						for="kioskModeFalse"
						style="font-weight: bold"
						>Personal Mode</label
					>
				</div>
				<div class="form-check form-check-inline" style="margin-left: 20px">
					<input
						type="radio"
						class="form-check-input"
						id="kioskModeTrue"
						[value]="true"
						formControlName="kiosk_mode"
						(change)="processKioskModeRadio()"
					/>
					<label
						class="form-check-label has-pointer"
						[class.tts-hl-2]="stationForm.get('kiosk_mode').value"
						for="kioskModeTrue"
						style="font-weight: bold"
						>Kiosk Mode</label
					>
				</div>
			</div>

			<div *ngIf="!stationForm.get('kiosk_mode').value" class="alert alert-danger" style="margin-top: 30px">
				<b>Personal Mode</b> stations should be unique. Do not use the same setup code on more than one device.
			</div>

			<div class="options-section-header" style="margin: 25px 0px 20px 0px">Miscellaneous Options</div>

			<!-- Use PIN pad for employee selection -->
			<div class="checkbox-item d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-checkbox formControlName="use_emp_pin" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<strong (click)="toggleCheckbox('use_emp_pin')" class="has-pointer">Use PIN pad for employee selection</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('use_emp_pin')"></i>
				</div>
			</div>

			<!-- Child - Match station PIN -->
			<div *ngIf="stationForm.get('use_emp_pin').value" class="checkbox-item checkbox-child d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-checkbox formControlName="match_emp_station_pin" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<strong (click)="toggleCheckbox('match_emp_station_pin')" class="has-pointer">Match employee station PIN</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('match_emp_station_pin')"></i>
				</div>
			</div>

			<!-- Child - Match external ID -->
			<div *ngIf="stationForm.get('use_emp_pin').value" class="checkbox-item checkbox-child d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-checkbox formControlName="match_emp_ext_id" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<strong (click)="toggleCheckbox('match_emp_ext_id')" class="has-pointer">Match employee external ID</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('match_emp_ext_id')"></i>
				</div>
			</div>

			<!-- Child - Match employee phone -->
			<div *ngIf="stationForm.get('use_emp_pin').value" class="checkbox-item checkbox-child d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-checkbox formControlName="match_emp_phone" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<strong (click)="toggleCheckbox('match_emp_phone')" class="has-pointer">Match employee phone number</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('match_emp_phone')"></i>
				</div>
			</div>

			<!-- Use PIN pad for job selection -->
			<div class="checkbox-item d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-checkbox formControlName="use_job_pin" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<strong (click)="toggleCheckbox('use_job_pin')" class="has-pointer">Use PIN pad for job selection</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('use_job_pin')"></i>
				</div>
			</div>

			<!-- Use QR Code at kiosk -->
			<div *ngIf="isSingleJobAssigned && stationForm.get('kiosk_mode').value" class="checkbox-item d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-checkbox formControlName="use_qr_code" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<strong (click)="toggleCheckbox('use_qr_code')" class="has-pointer">Use QR Code at kiosk</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('use_qr_code')"></i>
				</div>
			</div>

			<!-- Allow checkpoints from kiosk -->
			<div *ngIf="stationForm.get('kiosk_mode').value" class="checkbox-item d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-checkbox formControlName="kiosk_checkpoints_enabled" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<strong (click)="toggleCheckbox('kiosk_checkpoints_enabled')" class="has-pointer">Allow checkpoints from kiosk</strong>
					<i
						title="More Information"
						class="far fa-info-circle tts-info-btn"
						aria-hidden="true"
						(click)="showHelp('kiosk_checkpoints_enabled')"
					></i>
				</div>
			</div>

			<!-- Allow reports from kiosk -->
			<div *ngIf="stationForm.get('kiosk_mode').value" class="checkbox-item d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-checkbox formControlName="kiosk_reports_enabled" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<strong (click)="toggleCheckbox('kiosk_reports_enabled')" class="has-pointer">Allow reports from kiosk</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('kiosk_reports_enabled')"></i>
				</div>
			</div>

			<!-- Setup as a mobile station -->
			<div *ngIf="!stationForm.get('kiosk_mode').value" class="checkbox-item d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-checkbox formControlName="mobile_station" [binary]="true" (onChange)="scrollIntoView('#mobileStationE164Input')"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<strong (click)="toggleCheckbox('mobile_station')" class="has-pointer">Setup as a mobile station</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('mobile_station')"></i>
				</div>
			</div>

			<div style="margin-top: 20px">
				<app-form-phone-number
					id="mobileStationE164Input"
					*ngIf="stationForm.get('mobile_station').value"
					[manager]="mobileStationPhoneManager"
					(showHelp)="showHelp($event)"
				></app-form-phone-number>
			</div>

			<!-- <div *ngIf="stationForm.get('kiosk_mode').value" class="checkbox-item d-flex justify-content-between">
			<div class="checkbox-wrap checkbox-child">
				<p-checkbox formControlName="status_list" [binary]="true"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleCheckbox('status_list')" class="has-pointer">Enable Status List</strong>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('status_list')"></i>
			</div>
		</div> -->
		</app-form-expander>
	</form>
</div>
