import { DateTimeHelper } from '@app/helpers'
import { PhoneNumberRecord } from './phone-number'
import { UserRecord } from './user'

export class SkillRecord {
	id: number
	company_id: number

	created: string
	updated: string

	name: string
	details: string
	locked: boolean

	phone_number_ids: Array<number> = [] // Used to link phone numbers to this skill
	user_ids: Array<number> = [] // Used to link users to this skill

	phone_number_skill: Array<PhoneNumberSkillLinkRecord> = []
	users_skill: Array<UserSkillLinkRecord> = []

	constructor(record?: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
		this.created = DateTimeHelper.stripUtcTag(this.created)
		this.updated = DateTimeHelper.stripUtcTag(this.updated)
		this.phone_number_skill = this.phone_number_skill.map((rec) => new PhoneNumberSkillLinkRecord(rec))
		this.users_skill = this.users_skill.map((rec) => new UserSkillLinkRecord(rec))
		this.phone_number_ids = this.phone_number_skill.map((rec) => rec.phone_number_id)
		this.user_ids = this.users_skill.map((rec) => rec.user_id)
	}

	getLinkForUserId(id: number): UserSkillLinkRecord {
		return this.users_skill.find((rec) => rec.user_id === id)
	}
}
export class PhoneNumberSkillLinkRecord {
	created: string
	priority: string // Number 1-10
	skill_id: number
	company_id: number
	phone_number_id: number
	constructor(record?: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
		this.created = DateTimeHelper.stripUtcTag(this.created)
	}
}

export class UserSkillLinkRecord {
	created: string
	priority: string // Number 1-10
	skill_id: number
	company_id: number
	user_id: number

	name: string // Computed property
	always_route: boolean

	constructor(record?: any) {
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				this[attr] = record[attr]
			}
		}
		this.created = DateTimeHelper.stripUtcTag(this.created)
	}
}
