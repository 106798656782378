<div class="el-list-wrap hide-scrollbars">
	<!-- Call Log Record Card -->
	<div *ngFor="let item of list">
		<app-comm-log-card
			[card]="item"
			[timezone]="timezone"
			(placeCall)="placeCall.emit($event)"
			(startTextMsg)="startTextMsg.emit($event)"
		></app-comm-log-card>
	</div>
	<div *ngIf="isDataLoaded && list.length === 0" class="el-no-events">No Comm Log Events</div>
	<div *ngIf="!isDataLoaded || manager.eventStatus.isRefreshing" class="el-no-events"><i class="fas fa-loader fa-spin"></i> Loading...</div>
</div>
