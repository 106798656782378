<div class="container section-container">
	<div class="sec-head-wrap">
		<div class="sec-act-ico-wrap">
			<i
				class="fas fa-plus sec-act-ico"
				[class.section-control-disabled]="!canPerformAction(CrudAction.create)"
				(click)="createRecord()"
				title="Add Employee"
			></i>
			<i class="far fa-cog sec-act-ico" (click)="togglePrefsPanel()" title="Manage Columns"></i>
			<i class="far fa-question-circle sec-act-ico" (click)="toggleTooltips()" title="Show Help"></i>
		</div>

		<div class="sec-title-wrap">
			<i class="far fa-user sec-head-ico"></i>
			<span>Employees</span>
			<app-sub-section-title [isVisible]="true" [subSectionTitle]="subSectionTitle"></app-sub-section-title>
			<app-section-list-count [manager]="listCountManager"></app-section-list-count>
		</div>

		<div class="sub-head-wrap">
			<div class="sub-head-ico-wrap">
				<i
					class="fal fa-cubes sub-head-ico"
					[class.sub-head-ico-highlight]="batchManager.isActive"
					(click)="batchManager.toggleActive()"
					title="Batch Mode"
				></i>
				<i class="far fa-refresh sub-head-ico" (click)="fetchAndReloadData()" title="Refresh Data"></i>
			</div>
			<div class="updated-wrap">{{ lastUpdated() }}</div>
		</div>
	</div>

	<div>
		<app-section-switcher [config]="switcherConfig" [type]="'NOCOUNT'" [maxWidth]="'240px'" (tabClicked)="sectionTabClicked($event)">
		</app-section-switcher>
	</div>

	<div *ngIf="showTooltips" class="card card-body tooltip-block tooltip-info-text tooltip-section-header">
		<button type="button" class="close" aria-label="Close" (click)="toggleTooltips()">
			<span>&times;</span>
		</button>
		<app-no-data-tip *ngIf="showNoDataTip()"></app-no-data-tip>
		<strong class="tooltip-step">Note:</strong> You can manually enter your employees here, prior to their first clock in. You can also import your
		employees from QuickBooks Online using the QuickBooks Online section of the Settings page. Note: If you forget to enter en employee’s
		information prior to their first check in, the system will automatically add them with the label <strong>AutoCreated</strong>.<br /><br />
		<strong style="color: chocolate">Active / Inactive</strong> If an employee does not register any activity with the system for 30 days they will
		be marked inactive. The employee will automatically become active again the next time they use the system.
		<br />
	</div>

	<app-employee-table #empTable></app-employee-table>
</div>
