<div class="public-container" style="margin-top: -30px; margin-bottom: 50px">
	<form method="POST" action="#" role="form" autocomplete="off">
		<div style="text-align: center">
			<div style="margin-bottom: 20px">
				<img src="/assets/img/tts-logo.png" style="width: 100%" />
			</div>
			<div *ngIf="!errorMessage && !registeringUser">
				<div class="alert alert-warning">Fill in the form below to create a <strong>Telephone Timesheets</strong> account for your company</div>
				<!-- <hr /> -->
			</div>
		</div>
		<div [hidden]="registeringUser">
			<!-- <div class="form-group">
				<div style="text-align: center">
					<hr>
					<div class="alert alert-warning">Fill in the form below to create a Telephone Timesheets account for your company</div>
					<hr>
				</div>
				<div *ngIf="!errorMessage && !retrievedUser.isValid" class="alert-block">
					<strong>Warning:</strong> Once you click the 'Create your account' button, do not close this browser tab until registration has completed and you have been logged in. If the registration process is interrupted, you may need to contact support to finish setting up your account.
				</div>
			</div> -->
			<div *ngIf="errorMessage" class="alert alert-danger">
				{{ errorMessage }}
			</div>
			<div *ngIf="!errorMessage && retrievedUser.isValid">
				<div class="alert-pending-block">
					<div class="registration-notes">
						It appears a prior registration attempt was interrupted. Click <strong>Resume Registration</strong> to continue. If you completed
						registration on another device then choose <strong>Log in</strong> instead.
					</div>
				</div>
				<div class="pending-reg-buttons">
					<button class="btn btn-success" (click)="resumeRegistration()">Resume Registration</button>
					<br />
					<br />
					<button [routerLink]="['/home/login']" class="btn btn-primary">Log in</button>
				</div>
				<div class="pending-startover">
					<div class="registration-notes">
						If <strong style="color: chocolate">{{ retrievedUser.mobile | displayPhone }}</strong> is not your correct mobile number and you
						would like to register with a different mobile number then choose <strong>Start Over</strong>.
					</div>
				</div>
				<div class="pending-startover-btn">
					<button class="btn btn-danger" (click)="startOver()">Start Over</button>
				</div>
			</div>
			<div *ngIf="!errorUserExits && !retrievedUser.isValid">
				<div class="form-group">
					<label class="form-label register-label"><span style="color: chocolate">Region</span></label>
					<select
						class="form-select"
						[(ngModel)]="userRegistration.countryCode"
						[ngModelOptions]="{ standalone: true }"
						(change)="changeRegion()"
					>
						<option *ngFor="let code of countryCodeData" value="{{ code.id }}">{{ code.region }}</option>
						<!-- <option value="US">United States</option>
						<option value="CA">Canada</option>
						<option value="GB">Great Britain</option>
						<option value="JE">Jersey (UK)</option>
						<option value="PR">Puerto Rico</option> -->
					</select>
				</div>
				<div class="form-group">
					<label class="form-label register-label" for="signupFirstName">First Name</label>
					<input
						id="signupFirstName"
						type="text"
						maxlength="50"
						class="form-control"
						[class.is-valid]="userRegistration.firstName.length > 0"
						[(ngModel)]="userRegistration.firstName"
						[ngModelOptions]="{ standalone: true }"
					/>
				</div>
				<div class="form-group">
					<label class="form-label register-label" for="signupLastName">Last Name</label>
					<input
						id="signupLastName"
						type="text"
						maxlength="50"
						class="form-control"
						[class.is-valid]="userRegistration.lastName.length > 0"
						[(ngModel)]="userRegistration.lastName"
						[ngModelOptions]="{ standalone: true }"
					/>
				</div>
				<div class="form-group">
					<span class="float-right" style="color: gray">(optional)</span>
					<label class="form-label register-label" for="signupCompanyName">Company Name</label>
					<input
						id="signupCompanyName"
						type="text"
						maxlength="50"
						class="form-control"
						[(ngModel)]="userRegistration.companyName"
						[ngModelOptions]="{ standalone: true }"
					/>
				</div>
				<div class="form-group">
					<label class="form-label register-label" for="signupEmail">Email</label>
					<input
						#signupEmail
						id="signupEmail"
						type="email"
						maxlength="50"
						class="form-control"
						[class.is-valid]="checkEmail(signupEmail.value)"
						[(ngModel)]="userRegistration.email"
						[ngModelOptions]="{ standalone: true }"
					/>
				</div>
				<div class="form-group">
					<label class="form-label register-label" for="username">Mobile Phone</label>
					<input
						id="username"
						type="tel"
						maxlength="50"
						class="form-control"
						[class.is-valid]="mobileNumberValid"
						[(ngModel)]="userRegistration.mobile"
						[ngModelOptions]="{ standalone: true }"
						autocomplete="off"
						(keyup)="formatPhone()"
					/>
					<!-- <div class="input-field-notes">Ex: 6125551234 <strong>not</strong> 612-555-1234</div> -->
				</div>
				<div class="form-group">
					<label class="form-label register-label" for="new-password-text-field">Password</label>
					<span *ngIf="isPasswordValid()" class="float-right input-valid">valid</span>
					<input
						pPassword
						id="new-password-text-field"
						type="password"
						maxlength="25"
						class="form-control"
						[class.is-valid]="isPasswordValid()"
						length="40"
						[(ngModel)]="userRegistration.password"
						[ngModelOptions]="{ standalone: true }"
						autocomplete="new-password"
					/>
					<div class="input-field-notes">
						Passwords must be at least <strong>8</strong> characters long and include both letters and numbers.
					</div>
				</div>
				<div class="form-group">
					<label class="form-label register-label" for="confirm-password-text-field">Confirm Password</label>
					<span *ngIf="isConfirmationValid()" class="float-right input-valid">matches</span>
					<input
						id="confirm-password-text-field"
						type="password"
						maxlength="25"
						class="form-control"
						[class.is-valid]="isConfirmationValid()"
						length="40"
						[(ngModel)]="userRegistration.confirmation"
						[ngModelOptions]="{ standalone: true }"
						autocomplete="new-password"
					/>
				</div>

				<div class="registration-notes">
					<strong>Note:</strong> A valid mobile phone number is required to register your account. You will receive a confirmation code via text
					message at this number which you will use to finish setting up your account. Remember to leave this browser tab open until registration
					is complete.
				</div>

				<div class="form-group">
					<button (click)="onRegister()" id="signupSubmit" type="submit" class="btn btn-primary btn-block">Create your account</button>
				</div>
				<div class="tos-notes">
					By creating an account, you are agreeing to the Telephone Timesheets
					<div class="inline link-text" (click)="openLink('TOS')">Terms of Service</div>
					and acknowledging our
					<div class="inline link-text" (click)="openLink('PP')">Privacy Policy.</div>
				</div>
				<hr />
			</div>
			<div *ngIf="!retrievedUser.isValid">
				<div *ngIf="errorUserExits">
					Contact Support
					<a href="mailto:support@telephonetimesheets.com" class="float-right"> <i class="far fa-envelope"></i> Email</a>
				</div>
				<div>
					Forgot your password?
					<a [routerLink]="['/home/forgotPassword']" class="float-right"> <i class="far fa-lock"></i> Reset</a>
				</div>
				<div style="margin-top: 2px">
					Already have an account?
					<a [routerLink]="['/home/login']" class="float-right"> <i class="far fa-user"></i> Log in</a>
				</div>
			</div>
		</div>
	</form>

	<div *ngIf="false">
		<button (click)="saveReg()">Save Reg</button>
		<button (click)="getReg()">Get Reg</button>
		<div>{{ retrievedUser | json }}</div>
		<div>Is Valid: {{ retrievedUser.isValid }}</div>
		<div>Timestamp: {{ retrievedUser.timestamp }}</div>
		<div>Next Run: {{ retrievedUser.nextRun }}</div>
		<div>Current TIme: {{ currentDate }}</div>
	</div>

	<div *ngIf="registeringUser">
		<div style="text-align: center">
			<div style="font-size: 3rem">
				<i class="fas fa-loader fa-spin" aria-hidden="true"></i>
			</div>
			<br />
			<h2>Registering User</h2>
		</div>
	</div>

	<div *ngIf="false">
		<br />
		<br />
		<div class="alert alert-info">{{ userRegistration | json }}</div>
	</div>
</div>

<p-dialog
	styleClass="p-dialog-noheader"
	[header]="'Company Signup'"
	[(visible)]="showIntroDialog"
	[showHeader]="false"
	[modal]="true"
	[style]="{ width: '300px' }"
	[draggable]="false"
	[resizable]="false"
	[closable]="false"
	[dismissableMask]="false"
>
	<div class="intro-dialog">
		<div>Thank you for your interest in setting up a Telephone Timesheets account for your company. Click the button below to get started.</div>
		<button class="btn btn-sm btn-success btn-block" style="margin-top: 12px" (click)="showIntroDialog = false">Setup Company</button>
		<div style="margin-top: 18px">
			Are you are an employee looking to clock in for your job? Please click the button below to access our Employee Timeclock portal.
		</div>
		<button class="btn btn-sm btn-primary btn-block" style="margin-top: 12px" (click)="gotoTimeclock()">Employee Timeclock</button>
	</div>
</p-dialog>
