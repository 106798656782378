import { DatabaseTableName } from './table'
import { log } from '@app/helpers/logger'
import _ from 'lodash'

export class UserPermissions {
	key: DatabaseTableName
	permTable: PermissionTableName // Refactor renamed from tableName which was confusing
	access: AccessPermission
	owner: OwnerPermission
	selectors: Array<SelectorPermission>
	options: Array<OptionPermission>

	isSelectorRestrictedFor(type: SelectorPermissionType) {
		const selector = this.selectors.find((s) => s.type === type)
		if (selector) {
			return selector.restricted
		}
		return false
	}

	isOptionEnabledFor(type: OptionPermissionType) {
		const option = this.options.find((s) => s.type === type)
		if (option) {
			return option.enabled
		}
		return false
	}

	constructor(permItem?: IAccessPermissionItem) {
		if (permItem) {
			this.key = permItem.key
			this.permTable = permItem.table
			this.access = permItem.access
			this.owner = permItem.owner
			this.selectors = permItem.selectors
			this.options = permItem.options
		}
	}
}

// If PermissionTableName changes, add the new table to the getAllowedPermissionsForRole() method
// Comments show IAccessPermissionItem key / table / label
// key is the back end table the permission manages
// table is the PermissionTableName used by UserPermissionsList
// label is the label displayed in the permissions dialog

export type PermissionTableName =
	| 'account' // company / account / Settings
	| 'adp' // adp_sync / adp / ADP Integration
	| 'announcement' // announcements / announcement / Announcements
	| 'audit' // audit_log / audit / Audit Log
	| 'billing' // billing_log / billing / Billing Log
	| 'client' // DEPRECATED
	| 'calllogagent' // DEPRECATED - click_to_call_log / calllogagent / Call Log - Agents
	| 'calllogivr' // ivr_call_log / calllogivr / Comm Logs
	| 'employee' // employee / employee / Employees
	| 'fileimport' // file_import_sync / fileimport / File Import Integration
	| 'incident' // incident_log / incident / Employee Shift Reports
	| 'job' // job / job / Jobs
	| 'miscellaneous' // miscellaneous (not real) / miscellaneous / Miscellaneous
	| 'notifications' // notification_profile / notifications / Notifications
	| 'organization' // vendor_client_org / organization / Contacts (And Organizations)
	| 'quickbooks' // qbo_sync / quickbooks / QuickBooks Integration
	| 'payrates' // employee_job_pay_rate / payrates / Pay Rates
	| 'report' // reports / reports / Business Insight Reports
	| 'schedule' // schedule_recur / schedule / Scheduling
	| 'shiftreport' // incident_log
	| 'site' // locations / site / Job Sites
	| 'supervisor' // users / supervisor / Supervisors
	| 'transaction' // transaction_log / transaction / Time Entries
	| 'vacation' // vacation / vacation / Time Off
	| 'whentowork' // google_cal_sync / whentowork / WhenToWork Integration
	| 'wheniwork' // wiw_sync / wheniwork / When I Work Integration

// Deprecated 'ELN'
export type SelectorPermissionType = 'EMP' | 'EMS' | 'JOB' | 'SITE' | 'SUP' | 'SUPGRP' | 'RPO'

export enum SelectorPermissionName {
	employee = 'EMP',
	// empLinkedNumbers = 'ELN', // Deprecated
	empManagedSupervisors = 'EMS',
	job = 'JOB',
	// site = 'SITE', // Deprecated
	supervisor = 'SUP',
	supGroup = 'SUPGRP',
	reportOwner = 'RPO',
}

// Global Flags
// GBLACP - Agent Control Panel

// Settings (Account Configure ACTCXX)
// ACTACD - General / Profile
// ACTCPP - Portal Preferences
// ACTCES - Employee Scoring
// ACTCEA - Employee Application
// ACTCCB - Inbound Call Blocking
// ACTCCL - Checklist / Reports
// ACTCAF - Archived Files
// ACTCWO - Work Orders
// ACTCCS - Call Center (Settings)
// ACTCDA - Data / Analytics
// ACTMCL - Modify Company Logo

// Call Logs
// CLGAAL - Access Agent Logs

// Supervisors
// SUPROL - Configure Supervisor Roles
// SUPPRF - Configure Supervisor Prefs
// SUPLEM - Configure Linked Employee (Linked Employee Menu)

// Time Entries
// TEEEAN - Edit Admin Notes

export type OptionPermissionType =
	| 'GBLACP'
	| 'ACTCAD'
	| 'ACTCPP'
	| 'ACTCES'
	| 'ACTCEA'
	| 'ACTCCB'
	| 'ACTCCL'
	| 'ACTCAF'
	| 'ACTCWO'
	| 'ACTCCS'
	| 'ACTCDA'
	| 'ACTMCL'
	| 'CLGAAL'
	| 'SUPPRF'
	| 'SUPROL'
	| 'SUPLEM'
	| 'TEEEAN'

export enum OptionPermissionName {
	accountConfigureAccountDefaults = 'ACTCAD',
	accountConfigurePortalPrefs = 'ACTCPP',
	accountConfigureEmpScore = 'ACTCES',
	accountConfigureEmpApp = 'ACTCEA',
	accountConfigureCallBlocking = 'ACTCCB',
	accountConfigureChecklist = 'ACTCCL',
	accountConfigureArchivedFiles = 'ACTCAF',
	accountConfigureWorkorders = 'ACTCWO',
	accountConfigureCallCenter = 'ACTCCS',
	accountConfigureDataAnalytics = 'ACTCDA',
	accountConfigureModifyCompanyLogo = 'ACTMCL',

	// Call Logs
	callLogsAccessAgentLog = 'CLGAAL',

	supervisorConfigureRoles = 'SUPROL',
	supervisorConfigurePrefs = 'SUPPRF',
	supervisorLinkedEmployee = 'SUPLEM',

	timeEntryEditAdminNotes = 'TEEEAN',
}

export enum OptionFlagType {
	test = 'EMPTEST',
}

export enum CrudAction {
	create = 'create',
	read = 'read',
	update = 'update',
	delete = 'delete',
}

export class UserPermissionsList {
	// This is a permissions aggregator. Add a propery with the PermissionsTableName in order to
	// be able to easily access permissions for that permission item. This is not required as you
	// can use specific methods on AccessPermissions to get user owner and access permissions info.

	account = new UserPermissions() // Settings
	announcement = new UserPermissions() // Announcements (Checking & Scheduled)
	audit = new UserPermissions() // Audit Log
	billing = new UserPermissions() // Billing
	// client = new UserPermissions()
	calllogivr = new UserPermissions() // Comm Logs
	employee = new UserPermissions() // Employees
	incident = new UserPermissions() // Employee Shift Reports
	job = new UserPermissions() // Jobs
	notifications = new UserPermissions() // Notifications
	organization = new UserPermissions() // Contacts & Organizations
	quickbooks = new UserPermissions() // QuickBooks Integration
	payrates = new UserPermissions() // Pay Rates
	report = new UserPermissions() // Business Insight Reports
	schedule = new UserPermissions() // Scheduling
	site = new UserPermissions() // Job Sites
	supervisor = new UserPermissions() // Supervisors
	transaction = new UserPermissions() // Time Entries
	vacation = new UserPermissions() // Time Off
	miscellaneous = new UserPermissions() // Miscellaneous

	getPermissionsFor(tableName: PermissionTableName): UserPermissions {
		return this[tableName]
	}
}

export enum AccessPermissionKey {}

export interface IPermissionKeyData {
	data: any
	type: PermissionType
}

export interface IAccessPermissionData {
	key: DatabaseTableName
	values: Array<IPermissionKeyData>
}

export enum PermissionType {
	owner = 'OWNER',
	access = 'ACCESS',
	display = 'DISPLAY',
	select = 'SELECT',
	option = 'OPTION',
}

export enum AccessPermissionOption {
	create = 'create',
	read = 'read',
	update = 'update',
	delete = 'delete',
	noAccess = 'noAccess',
	supervisor = 'supervisor',
}

export class DisplayPermission {}

export interface IAccessPermissionItem {
	key: DatabaseTableName
	table: PermissionTableName
	label: string
	access: AccessPermission
	owner: OwnerPermission
	selectors: Array<SelectorPermission>
	options: Array<OptionPermission>
	showDetails: boolean // Show details information
	showAccess: boolean // Show access block
	showOwner: boolean // Show owner block
	canEdit: boolean // Can be edited
}

export class SelectorPermission {
	type: SelectorPermissionType
	restricted = false

	constructor(perms: string) {
		const data = perms.split(':')
		const type = data[0] as SelectorPermissionType
		const owner = data[1] as string
		this.type = type
		this.restricted = owner === 'O' ? true : false
	}

	isModified(): boolean {
		return this.restricted !== false
	}

	// A = All, O = Owned
	getAccessString(): string {
		const access = this.restricted ? 'O' : 'A'
		return `${this.type}:${access}`
	}
}

export class OptionPermission {
	type: OptionPermissionType
	enabled = true

	constructor(perms: string) {
		const data = perms.split(':')
		const type = data[0] as OptionPermissionType
		const state = data[1] as string
		this.type = type
		this.enabled = state === 'E' ? true : false
	}

	isModified(): boolean {
		return this.enabled === false
	}

	// E = Enabled, D = Disabled
	getAccessString(): string {
		const access = this.enabled ? 'E' : 'D'
		return `${this.type}:${access}`
	}
}

export class OwnerPermission {
	create = true
	read = true
	update = true
	delete = true

	constructor(perms?: string) {
		if (perms || perms === '') {
			if (perms.includes('C')) {
				this.create = true
			} else {
				this.create = false
			}
			if (perms.includes('R')) {
				this.read = true
			} else {
				this.read = false
			}
			if (perms.includes('U')) {
				this.update = true
			} else {
				this.update = false
			}
			if (perms.includes('D')) {
				this.delete = true
			} else {
				this.delete = false
			}
		}
	}

	isModified(): boolean {
		if (this.create === false) {
			return true
		}
		if (this.read === false) {
			return true
		}
		if (this.update === false) {
			return true
		}
		if (this.delete === false) {
			return true
		}
		return false
	}

	getAccessString(): string {
		let result = ''
		if (this.create) {
			result += 'C'
		}
		if (this.read) {
			result += 'R'
		}
		if (this.update) {
			result += 'U'
		}
		if (this.delete) {
			result += 'D'
		}
		return result
	}
}

export class AccessPermission {
	create = true
	read = true
	update = true
	delete = true
	noAccess = false
	supervisor = false

	constructor(perms?: string) {
		if (perms || perms === '') {
			if (perms.includes('C')) {
				this.create = true
			} else {
				this.create = false
			}
			if (perms.includes('R')) {
				this.read = true
			} else {
				this.read = false
			}
			if (perms.includes('U')) {
				this.update = true
			} else {
				this.update = false
			}
			if (perms.includes('D')) {
				this.delete = true
			} else {
				this.delete = false
			}
			if (perms.includes('N')) {
				this.noAccess = true
			} else {
				this.noAccess = false
			}
			if (perms.includes('S')) {
				this.supervisor = true
			} else {
				this.supervisor = false
			}
		}
	}

	isModified(): boolean {
		if (this.create === false) {
			return true
		}
		if (this.read === false) {
			return true
		}
		if (this.update === false) {
			return true
		}
		if (this.delete === false) {
			return true
		}
		if (this.noAccess === true) {
			return true
		}
		if (this.supervisor === true) {
			return true
		}
		return false
	}

	getAccessString(): string {
		let result = ''
		if (this.create) {
			result += 'C'
		}
		if (this.read) {
			result += 'R'
		}
		if (this.update) {
			result += 'U'
		}
		if (this.delete) {
			result += 'D'
		}
		if (this.noAccess) {
			result += 'N'
		}
		if (this.supervisor) {
			result += 'S'
		}
		return result
	}
}

export class AccessPermissions {
	// key: remote database table name, also used in routing-module as 'key' parameter for AccessGuard
	// table: local table reference for permissions in UserPermissionsList and PermissionTableName

	permissionItems: Array<IAccessPermissionItem> = [
		{
			key: 'employee',
			table: 'employee',
			label: 'Employees',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: [],
			options: [],
			showDetails: false,
			showAccess: true,
			showOwner: true,
			canEdit: true,
		},
		{
			key: 'users',
			table: 'supervisor',
			label: 'Supervisors',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: null,
			options: [new OptionPermission('SUPROL:E'), new OptionPermission('SUPLEM:E')], // For when roles are ready
			showDetails: false,
			showAccess: true,
			showOwner: true,
			canEdit: true,
		},
		// Permissions for Contacts includes Individuals and Organizations but uses the vendor_client_org table key for both
		{
			key: 'vendor_client_org',
			table: 'organization',
			label: 'Contacts',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: null,
			options: null,
			showDetails: false,
			showAccess: true,
			showOwner: false,
			canEdit: true,
		},
		{
			key: 'location',
			table: 'site',
			label: 'Job Sites',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: [],
			options: null,
			showDetails: false,
			showAccess: true,
			showOwner: true,
			canEdit: true,
		},
		{
			key: 'job',
			table: 'job',
			label: 'Jobs',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: [],
			options: null,
			showDetails: false,
			showAccess: true,
			showOwner: true,
			canEdit: true,
		},
		{
			key: 'notification_profile',
			table: 'notifications',
			label: 'Notifications',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: null,
			options: null,
			showDetails: false,
			showAccess: true,
			showOwner: false,
			canEdit: true,
		},
		// Permissions for Scheduling includes Schedules, Shifts, and Time Off but uses the schedule_recur table key for all of them
		{
			key: 'schedule_recur',
			table: 'schedule',
			label: 'Scheduling',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: [new SelectorPermission('EMP:A'), new SelectorPermission('JOB:A')],
			options: null,
			showDetails: false,
			showAccess: true,
			showOwner: true,
			canEdit: true,
		},
		// Vacation isn't currently exposed to end users. It's hidden using the getAllowedPermissionsForRole method
		{
			key: 'vacation',
			table: 'vacation',
			label: 'Time Off',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: [new SelectorPermission('EMP:A')],
			options: null,
			showDetails: false,
			showAccess: true,
			showOwner: true,
			canEdit: true,
		},

		{
			key: 'transaction_log',
			table: 'transaction',
			label: 'Time Entries',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: [new SelectorPermission('EMP:A')],
			options: [new OptionPermission('TEEEAN:E')],
			showDetails: false,
			showAccess: true,
			showOwner: true,
			canEdit: true,
		},
		{
			key: 'incident_log',
			table: 'incident',
			label: 'Employee Shift Reports',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: null,
			options: null,
			showDetails: false,
			showAccess: true,
			showOwner: true,
			canEdit: true,
		},
		{
			key: 'reports',
			table: 'report',
			label: 'Business Insight Reports',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: null, // [new SelectorPermission('RPO:A')],
			options: null,
			showDetails: false,
			showAccess: true,
			showOwner: true,
			canEdit: true,
		},
		{
			key: 'company',
			table: 'account',
			label: 'Settings',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: null,
			options: [
				new OptionPermission('ACTCAD:E'),
				new OptionPermission('ACTCPP:E'),
				new OptionPermission('ACTCES:E'),
				new OptionPermission('ACTCEA:E'),
				new OptionPermission('ACTCCB:E'),
				new OptionPermission('ACTCCL:E'),
				new OptionPermission('ACTCAF:E'),
				new OptionPermission('ACTCWO:E'),
				new OptionPermission('ACTCCS:E'),
				new OptionPermission('ACTCDA:E'),
				new OptionPermission('ACTMCL:E'),
			],
			showDetails: false,
			showAccess: false,
			showOwner: false,
			canEdit: true,
		},
		{
			key: 'billing_log',
			table: 'billing',
			label: 'Billing',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: null,
			options: null,
			showDetails: false,
			showAccess: false,
			showOwner: false,
			canEdit: true,
		},
		{
			key: 'announcements',
			table: 'announcement',
			label: 'Announcements',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: [new SelectorPermission('EMP:A'), new SelectorPermission('SITE:A')],
			options: null,
			showDetails: false,
			showAccess: true,
			showOwner: false,
			canEdit: true,
		},

		// {
		// 	key: 'client',
		// 	table: 'client',
		// 	label: 'Clients',
		// 	access: new AccessPermission(),
		// 	owner: new OwnerPermission(),
		// 	selectors: null,
		// 	options: null,
		// 	showDetails: false,
		// 	showAccess: true,
		// 	showOwner: false,
		// 	canEdit: true,
		// },

		{
			key: 'employee_job_pay_rate',
			table: 'payrates',
			label: 'Pay Rates',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: null,
			options: null,
			showDetails: false,
			showAccess: true,
			showOwner: true,
			canEdit: true,
		},

		{
			key: 'adp_sync',
			table: 'adp',
			label: 'ADP Integration',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: null,
			options: null,
			showDetails: false,
			showAccess: false,
			showOwner: false,
			canEdit: true,
		},
		{
			key: 'file_import_sync',
			table: 'fileimport',
			label: 'File Import Integration',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: null,
			options: null,
			showDetails: false,
			showAccess: false,
			showOwner: false,
			canEdit: true,
		},
		{
			key: 'qbo_sync',
			table: 'quickbooks',
			label: 'QuickBooks Integration',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: null,
			options: null,
			showDetails: false,
			showAccess: false,
			showOwner: false,
			canEdit: true,
		},
		{
			key: 'wiw_sync',
			table: 'wheniwork',
			label: 'When I Work Integration',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: null,
			options: null,
			showDetails: false,
			showAccess: false,
			showOwner: false,
			canEdit: true,
		},
		{
			key: 'google_cal_sync',
			table: 'whentowork',
			label: 'WhenToWork Integration',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: null,
			options: null,
			showDetails: false,
			showAccess: false,
			showOwner: false,
			canEdit: true,
		},

		{
			key: 'audit_log',
			table: 'audit',
			label: 'Audit Log',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: null,
			options: null,
			showDetails: false,
			showAccess: false,
			showOwner: false,
			canEdit: true,
		},
		{
			key: 'ivr_call_log',
			table: 'calllogivr',
			label: 'Comm Logs',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: null,
			options: [new OptionPermission('CLGAAL:E')],
			showDetails: false,
			showAccess: false,
			showOwner: false,
			canEdit: true,
		},
		{
			key: 'miscellaneous',
			table: 'miscellaneous',
			label: 'Miscellaneous',
			access: new AccessPermission(),
			owner: new OwnerPermission(),
			selectors: null,
			options: [new OptionPermission('GBLACP:E')],
			showDetails: false,
			showAccess: false,
			showOwner: false,
			canEdit: true,
		},
		// {
		// 	key: 'click_to_call_log',
		// 	table: 'calllogagent',
		// 	label: 'Call Log - Agents',
		// 	access: new AccessPermission(),
		// 	owner: new OwnerPermission(),
		// 	selectors: null,
		// 	options: null,
		// 	showDetails: false,
		// 	showAccess: false,
		// 	showOwner: false,
		// 	canEdit: true,
		// },
	]

	constructor(jsonString?: string) {
		if (jsonString) {
			try {
				// Setup access permissions
				const permissionData: Array<IAccessPermissionData> = JSON.parse(jsonString)
				for (const permData of permissionData) {
					const key = permData.key
					const permissionValues = permData.values
					for (const perm of permissionValues) {
						const type = perm.type
						if (type === PermissionType.access) {
							const permission = new AccessPermission(perm.data)
							this.updateAccessPermission(key, permission)
						}
						if (type === PermissionType.owner) {
							const ownerPermission = new AccessPermission(perm.data)
							this.updateOwnerPermission(key, ownerPermission)
						}
						if (type === PermissionType.select) {
							const selectorPermission = new SelectorPermission(perm.data)
							this.updateSelectorPermission(key, selectorPermission)
						}
						if (type === PermissionType.option) {
							const optionPermission = new OptionPermission(perm.data)
							this.updateOptionPermission(key, optionPermission)
						}
					}
				}
			} catch (err) {
				log('Got an error', err)
			}
		}
	}

	// In order for a user role to be able to set user permissions for a given section, the PermissionTableName needs to be in the role list below
	// This list is the sections each user role can modify. If it's not in the list, it won't appear in the permissions edit list.

	getAllowedPermissionsForRole(role: string): Array<PermissionTableName> {
		switch (role) {
			case 'INTERNAL':
				return [
					'account',
					'adp',
					'announcement',
					'audit',
					'billing',
					'calllogivr',
					'employee',
					'fileimport',
					'job',
					'miscellaneous',
					'notifications',
					'organization',
					'payrates',
					'quickbooks',
					'incident',
					'report',
					'schedule',
					'site',
					'supervisor',
					'transaction',
					// 'vacation', // Not included because it's under scheduler
					'wheniwork',
					'whentowork',
				]
			case 'PRIMARY':
				return [
					'account',
					'adp',
					'announcement',
					'audit',
					'billing',
					'calllogivr',
					'employee',
					'fileimport',
					'job',
					'miscellaneous',
					'notifications',
					'organization',
					'payrates',
					'quickbooks',
					'incident',
					'report',
					'schedule',
					'site',
					'supervisor',
					'transaction',
					// 'vacation', // Not included because it's under scheduler
					'wheniwork',
					'whentowork',
				]
			case 'MANAGER':
				return [
					'account',
					'adp',
					'announcement',
					'audit',
					'billing',
					'calllogivr',
					'employee',
					'fileimport',
					'job',
					'miscellaneous',
					'notifications',
					'organization',
					'payrates',
					'quickbooks',
					'incident',
					'report',
					'schedule',
					'site',
					'supervisor',
					'transaction',
					// 'vacation', // Not included because it's under scheduler
					'wheniwork',
					'whentowork',
				]
			case 'SECONDARY':
				return [
					'account',
					'adp',
					'announcement',
					'audit',
					'billing',
					'calllogivr',
					'employee',
					'fileimport',
					'job',
					'miscellaneous',
					'notifications',
					'organization',
					'payrates',
					'quickbooks',
					'incident',
					'report',
					'schedule',
					'site',
					'supervisor',
					'transaction',
					// 'vacation', // Not included because it's under scheduler
					'wheniwork',
					'whentowork',
				]
			case 'NO_COGNITO':
				return [
					'account',
					'adp',
					'announcement',
					'audit',
					'billing',
					'calllogivr',
					'employee',
					'fileimport',
					'job',
					'miscellaneous',
					'notifications',
					'organization',
					'payrates',
					'quickbooks',
					'incident',
					'report',
					'schedule',
					'site',
					'supervisor',
					'transaction',
					// 'vacation', // Not included because it's under scheduler
					'wheniwork',
					'whentowork',
				]
		}
	}

	// This will build the aggregate permissions list so long as there is a property in the UserPermissionsList
	// class with the same name as it's PermissionsTableName

	getUserPermissionsList(): UserPermissionsList {
		const list = new UserPermissionsList()
		for (const item of this.permissionItems) {
			const permTable = item.table
			const userPerms = list[permTable] as UserPermissions
			if (userPerms) {
				userPerms.key = item.key
				userPerms.permTable = permTable
				userPerms.access = item.access
				userPerms.owner = item.owner
				userPerms.selectors = item.selectors
				userPerms.options = item.options
			}
		}
		return list
	}

	getUserPermissionsFor(tableName: DatabaseTableName): UserPermissions {
		const permItem = this.getPermissionItemForKey(tableName)
		return permItem ? new UserPermissions(permItem) : null
	}

	getPermissionItemForKey(tableName: DatabaseTableName) {
		return this.permissionItems.find((p) => p.key === tableName)
	}

	getOwnerPermissionsFor(tableName: DatabaseTableName): OwnerPermission {
		const permissionItem = this.getPermissionItemForKey(tableName)
		return permissionItem ? permissionItem.owner : new OwnerPermission()
	}

	getAccessPermissionsFor(tableName: DatabaseTableName): AccessPermission {
		const permissionItem = this.getPermissionItemForKey(tableName)
		return permissionItem ? permissionItem.access : new AccessPermission()
	}

	getSelectorPermissionsFor(tableName: DatabaseTableName): Array<SelectorPermission> {
		const permissionItem = this.getPermissionItemForKey(tableName)
		return permissionItem ? permissionItem.selectors : []
	}

	getOptionPermissionsFor(tableName: DatabaseTableName): Array<OptionPermission> {
		const permissionItem = this.getPermissionItemForKey(tableName)
		return permissionItem ? permissionItem.options : []
	}

	isOwnerPermissionSetFor(tableName: DatabaseTableName, option: AccessPermissionOption) {
		const permissionItem = this.getPermissionItemForKey(tableName)
		if (permissionItem) {
			const ownerPerm = permissionItem.owner
			if (ownerPerm) {
				return ownerPerm[option]
			}
		}
		return true
	}

	isAccessPermissionSetFor(tableName: DatabaseTableName, option: AccessPermissionOption) {
		const permissionItem = this.getPermissionItemForKey(tableName)
		if (permissionItem) {
			return permissionItem.access[option]
		}
		return true
	}

	updateOwnerPermission(tableName: DatabaseTableName, ownerPermission: OwnerPermission) {
		const permissionItem = this.permissionItems.find((p) => p.key === tableName)
		if (permissionItem) {
			permissionItem.owner = ownerPermission
		}
	}

	updateAccessPermission(tableName: DatabaseTableName, permission: AccessPermission) {
		const permissionItem = this.permissionItems.find((p) => p.key === tableName)
		if (permissionItem) {
			permissionItem.access = permission
		}
	}

	updateSelectorPermission(tableName: DatabaseTableName, permission: SelectorPermission) {
		const permissionItem = this.permissionItems.find((p) => p.key === tableName)
		if (permissionItem) {
			const selectors = permissionItem.selectors
			if (selectors) {
				const selector = selectors.find((s) => s.type === permission.type)
				if (selector) {
					selector.restricted = permission.restricted
				}
			}
		}
	}

	updateOptionPermission(tableName: DatabaseTableName, permission: OptionPermission) {
		const permissionItem = this.permissionItems.find((p) => p.key === tableName)
		if (permissionItem) {
			const options = permissionItem.options
			if (options) {
				const option = options.find((s) => s.type === permission.type)
				if (option) {
					option.enabled = permission.enabled
				}
			}
		}
	}

	makeJsonAccessItem(item: IAccessPermissionItem): IAccessPermissionData {
		const result = { key: null, values: null }
		const values = []

		// Build the all records access permission
		if (item.access.isModified()) {
			const permString = item.access.getAccessString()
			const permission = { type: PermissionType.access, data: permString }
			values.push(permission)
		}

		// Build the owner access permission
		if (item.owner.isModified()) {
			const ownerPermString = item.owner.getAccessString()
			const ownerPermission = { type: PermissionType.owner, data: ownerPermString }
			values.push(ownerPermission)
		}

		// Build the selector permissions
		const selectors = item.selectors
		if (selectors) {
			for (const selector of selectors) {
				if (selector.isModified()) {
					const selectorPermsString = selector.getAccessString()
					const selectorPermission = { type: PermissionType.select, data: selectorPermsString }
					values.push(selectorPermission)
				}
			}
		}

		// Build the option permissions
		const options = item.options
		if (options) {
			for (const option of options) {
				if (option.isModified()) {
					const optionPermsString = option.getAccessString()
					const optionPermission = { type: PermissionType.option, data: optionPermsString }
					values.push(optionPermission)
				}
			}
		}

		result.key = item.key
		result.values = values

		return result
	}

	isPermissionItemModified(item: IAccessPermissionItem): boolean {
		if (item.access.isModified()) {
			return true
		}
		if (item.owner && item.access.supervisor && item.owner.isModified()) {
			return true
		}
		const selectors = item.selectors
		if (selectors) {
			for (const selector of item.selectors) {
				if (selector.isModified()) {
					return true
				}
			}
		}
		const options = item.options
		if (options) {
			for (const option of item.options) {
				if (option.isModified()) {
					return true
				}
			}
		}
		return false
	}

	getJsonString(): string {
		const sortedPermissionItems = _.sortBy(this.permissionItems, 'key')
		const filteredAccessItems = sortedPermissionItems.filter((p) => this.isPermissionItemModified(p))
		if (filteredAccessItems.length === 0) {
			return null
		}
		const result = filteredAccessItems.map((p) => this.makeJsonAccessItem(p))
		return JSON.stringify(result)
	}

	hasSupervisorRestrictions(): boolean {
		for (const item of this.permissionItems) {
			const permissions = item.access
			if (permissions.supervisor) {
				return true
			}
		}
		return false
	}
}
