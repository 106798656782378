<div *ngIf="manager.currentView === 'SERIES'">
	<!-- Sub-section table filter -->
	<div *ngIf="arePendingSchedulesEnabled" class="option-dropdown-wrap noprint">
		<app-table-filter-buttons
			[manager]="seriesTableFilterManager"
			[noMargins]="true"
			(buttonClicked)="changeSubsection($event)"
		></app-table-filter-buttons>
	</div>

	<div *ngIf="manager.series.isBatchingEnabled" class="batch-mode-block">
		<div>{{ batchCount }} schedule{{ batchCount !== 1 ? 's' : '' }} selected</div>
		<div class="batch-links">
			<div>
				<span class="link-text" (click)="batchAction('EXITBATCH')">exit batch</span> |
				<span class="link-text" (click)="batchAction('CLEAR')">unselect all</span> |
				<span class="link-text" (click)="batchAction('SELECTVISIBLE')">select visible</span>
			</div>

			<div>
				<span class="link-text" (click)="batchAction('PREVIEW')">{{
					statusFilter.active && statusFilter.showBatchSelection ? 'show all' : 'show batched'
				}}</span>
				| <span class="link-text" (click)="batchAction('EDIT')">edit batch</span>
			</div>
		</div>
	</div>

	<div
		*ngIf="!isDataLoaded || isShowingFilter || isProcessingData || queueProcessing.isProcessingJobs || statusFilter.active"
		class="alert alert-warning loading-box noprint"
	>
		<div *ngIf="!isDataLoaded || isProcessingData || queueProcessing.isProcessingJobs">
			<i class="fas fa-loader fa-spin"></i> Processing Schedules...
		</div>
		<div *ngIf="isDataLoaded && isShowingFilter">Filtered for Job or Employee</div>
		<div *ngIf="statusFilter.active && isDataLoaded && !isProcessingData && !queueProcessing.isProcessingJobs">
			<div class="filter-close" (click)="clearFilter()">&times;</div>
			<div *ngIf="statusFilter.showInProgress">In Progress Schedules</div>
			<div *ngIf="statusFilter.showBatchSelection">Preview Batch Selection</div>
			<div *ngIf="statusFilter.showScheduledForToday">Scheduled For Today</div>
			<div *ngIf="statusFilter.showInactiveJob">Inactive Jobs</div>
			<div *ngIf="statusFilter.showEmpCount">Filter By Employee Count</div>
		</div>
	</div>

	<div *ngIf="!isBatchingEnabled" class="header-wrap noprint">
		<div *ngIf="tagOptions.length > 0" class="header-block">
			<div class="d-flex justify-between">
				<i #tsoBannerTarget class="far fa-cog dd-settings-icon has-pointer" (click)="tsoPanel.toggle($event, tsoBannerTarget)"></i>
				<div style="width: 100%">
					<p-multiselect
						appScrollLockMultiSelect
						appendTo="body"
						[placeholder]="'Select Tags / ' + tagFilterMethodLabel"
						[selectedItemsLabel]="'Tags Selected: {0}'"
						[maxSelectedLabels]="0"
						[autofocusFilter]="devDetect.isDesktop()"
						filterBy="label"
						[showToggleAll]="true"
						[showClear]="true"
						[resetFilterOnHide]="true"
						[options]="tagOptions"
						[(ngModel)]="manager.series.selectedTags"
						(onClear)="handleTagSelectionChange($event)"
						(onPanelHide)="handleTagSelectionChange($event)"
					>
					</p-multiselect>
				</div>
			</div>
		</div>
		<div class="header-block">
			<div
				class="sch-checkbox prevent-select"
				[class.sch-checkbox-enabled]="manager.series.showDisabledSchedules"
				(click)="manager.series.showDisabledSchedules = !manager.series.showDisabledSchedules; saveViewPrefs()"
			>
				<div class="d-flex justify-content-between">
					<div class="checkbox-wrap">
						<p-checkbox [ngModel]="manager.series.showDisabledSchedules" [binary]="true"></p-checkbox>
					</div>
					<div class="checkbox-label-wrap">
						<strong class="has-pointer">Disabled schedules ({{ manager.series.showDisabledSchedules ? 'visible' : 'hidden' }})</strong>
					</div>
				</div>
			</div>
		</div>
		<div class="header-block">
			<div
				class="sch-checkbox prevent-select"
				[class.sch-checkbox-enabled]="manager.series.showExpiredSchedules"
				(click)="manager.series.showExpiredSchedules = !manager.series.showExpiredSchedules; saveViewPrefs()"
			>
				<div class="d-flex justify-content-between">
					<div class="checkbox-wrap">
						<p-checkbox [ngModel]="manager.series.showExpiredSchedules" [binary]="true"></p-checkbox>
					</div>
					<div class="checkbox-label-wrap">
						<strong class="has-pointer">Expired schedules ({{ manager.series.showExpiredSchedules ? 'visible' : 'hidden' }})</strong>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Tag Settings Overlay Panel -->
	<p-popover #tsoPanel [styleClass]="'settings-overlay-panel'">
		<div class="panel-wrap">
			<div style="text-align: center">
				<span class="match-label">Match Method</span>
				<span
					class="badge has-pointer"
					style="margin-right: 6px"
					[class.badge-bg-lightgray]="manager.series.tagFilterMethod === 'OR'"
					[class.text-bg-success]="manager.series.tagFilterMethod === 'AND'"
					(click)="updateTagMatchMethod('AND', tsoPanel)"
				>
					ALL
				</span>
				<span
					class="badge has-pointer"
					[class.badge-bg-lightgray]="manager.series.tagFilterMethod === 'AND'"
					[class.text-bg-success]="manager.series.tagFilterMethod === 'OR'"
					(click)="updateTagMatchMethod('OR', tsoPanel)"
				>
					ANY
				</span>
			</div>
		</div>
	</p-popover>

	<div class="noprint">
		<div *ngIf="(isDataLoaded && !hasVisibleItems) || currentDayViewDate" class="no-records-box">
			<div *ngIf="!currentDayViewDate" class="alert alert-info">No schedules to display</div>
			<div *ngIf="currentDayViewDate && hasVisibleItems" class="alert alert-info">
				Scheduled for {{ currentDayViewDate | dateFormatter: 'ddd, MMM Do, YYYY' }}
			</div>
			<div *ngIf="currentDayViewDate && !hasVisibleItems" class="alert alert-info">
				No schedules for {{ currentDayViewDate | dateFormatter: 'ddd, MMM Do, YYYY' }}
			</div>
		</div>
	</div>

	<!-- Commented option keeps full list hidden while processing -->
	<!-- <div class="main-list-box" *ngIf="!manager.isSwitchingViews && !isProcessingData && !queueProcessing.isProcessingJobs"> -->
	<div class="main-list-box">
		<div *ngIf="manager.series.option === 'JOB'" [hidden]="!isDataLoaded">
			<div *ngFor="let item of currentJobList; let cardIdx = index">
				<div *ngIf="item.visible" class="card" [id]="item.elemId">
					<div class="card-body">
						<div>
							<div class="list-item-header link-text" (click)="switchToShiftViewForJob(item.jobId)">
								{{ item.jobName }}<span style="margin-left: 8px; color: chocolate" *ngIf="!item.job.active">(Inactive)</span>
							</div>
							<hr class="list-item-header-hr" />
							<div *ngFor="let entry of getItemEntriesForSubSection(item.entries); let idx = index">
								<div *ngIf="entry.visible">
									<hr class="list-item-hr" *ngIf="idx !== 0" />
									<app-scheduler-list-item-entry
										[type]="'JOB'"
										[item]="entry"
										[userPrefs]="userPrefs"
										[shouldCalculateNextUp]="true"
										[canEditRecord]="canEditRecord(entry.entry)"
										[canDeleteRecord]="canDeleteRecord(entry.entry)"
										[isBatchingEnabled]="isBatchingEnabled"
										(auditRecord)="auditRecord($event)"
										(editRecord)="editRecord($event)"
										(copyRecord)="copyRecord($event)"
										(deleteRecord)="deleteRecord($event)"
										(takeAction)="takeAction($event)"
										(showScheduleConflicts)="showScheduleConflicts($event)"
									>
									</app-scheduler-list-item-entry>
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="!isViewingPending && item.hasPending">
						<div class="pending-banner">
							<span class="pending-msg" (click)="viewPendingSchedules(item)"
								>This job has pending schedules
								<!-- <i class="far fa-eye"></i> -->
							</span>
						</div>
					</div>
				</div>
				<!-- <div
					class="back-to-top"
					*ngIf="!currentDayViewDate && !statusFilter.active && !manager.currentSearchText && cardIdx !== 0 && cardIdx % 4 === 0"
				>
					<a href="#" (click)="backToTop()">back to top</a>
				</div> -->
			</div>
		</div>
	</div>
</div>
<!-- End Main List Block -->

<p-scrollTop></p-scrollTop>

<div *ngIf="manager.currentView === 'CHANGES'">
	<app-schedule-changes-list [filterId]="filteredChangeRequestId" (actionComplete)="changeActionComplete($event)"></app-schedule-changes-list>
	<div *ngIf="filteredChangeRequestId" style="text-align: center; margin-top: 25px">
		<button class="btn btn-sm btn-secondary" (click)="filteredChangeRequestId = null">Show All</button>
	</div>
</div>

<!-- DO NOT REPLACE WITH DELETE DIALOG COMPONENT - NEEDS CUSTOM DELETE PROCESS -->
<p-dialog
	styleClass="p-dialog-noheader"
	[(visible)]="showDeleteScheduleDialog"
	[modal]="false"
	[style]="{ width: '300px' }"
	[dismissableMask]="false"
	[focusOnShow]="false"
	[showHeader]="false"
>
	<div *ngIf="!deleteInProgress">
		<div *ngIf="!blockedForDeletion.schedRecurId" class="tts-default-font delete-confirm-text">
			Are you sure you wish to remove the entry scheduling
			<span class="confirm-bold">{{ selectedEntryEmpName }}</span>
			<span *ngIf="!isSelectedEntryEmpActive" class="confirm-bold"> (Inactive)</span>
			<span> for the job </span>
			<span class="confirm-bold">{{ selectedEntryJobName }}</span
			>?
		</div>

		<div *ngIf="blockedForDeletion.schedRecurId" class="tts-default-font delete-confirm-text">
			You must close any open transactions before you can delete this schedule entry.
		</div>

		<div class="delete-schedule-buttons" style="margin-top: 25px">
			<button class="btn btn-sm btn-secondary dialog-btn" (click)="showDeleteScheduleDialog = false">Cancel</button>
			<span class="float-right">
				<button *ngIf="!blockedForDeletion.schedRecurId" class="btn btn-sm btn-danger dialog-btn" (click)="deleteScheduleConfirmed()">
					Delete
				</button>
				<button *ngIf="blockedForDeletion.schedRecurId" class="btn btn-sm btn-success dialog-btn" (click)="viewTransactionsForSchedRecurId()">
					View
				</button>
			</span>
		</div>
	</div>
	<div class="delete-in-progress" *ngIf="deleteInProgress"><i class="fas fa-loader fa-spin"></i> Deleting Record...</div>
</p-dialog>
<!-- DO NOT REPLACE WITH DELETE DIALOG COMPONENT - NEEDS CUSTOM DELETE PROCESS -->

<p-dialog header="Schedule Conflict" [(visible)]="showScheduleConflictsDialog" [modal]="false" [style]="{ width: '300px' }" [dismissableMask]="true">
	<div *ngIf="showScheduleConflictsDialog" class="conflict-dialog">
		<div style="margin-bottom: 10px">This schedule entry conflicts with the following scheduled jobs for this employee:</div>
		<div *ngFor="let conflict of selectedItemEntry.conflicts" class="conflict-entry">{{ conflict }}</div>
	</div>
</p-dialog>

<!-- Begin Audit Record Dialog -->
<app-audit-modal
	[recordId]="auditAction.recordId"
	[resource]="auditAction.resource"
	[header]="auditAction.header"
	[footer]="auditAction.footer"
	[(visible)]="auditAction.showDialog"
></app-audit-modal>
<!-- End Audit Record Dialog -->

<!-- Pending ScheduleApproval Dialog -->
<app-pending-schedule-approval
	[approvalAction]="approvalAction"
	(approvalComplete)="checkSubSectionForPendingSchedules()"
></app-pending-schedule-approval>

<!-- Batch Edit -->
<app-modal-dialog *ngIf="batchEditDialogManager.isDialogVisible" [dialogManager]="batchEditDialogManager">
	<app-scheduler-batch-edit [dialogManager]="batchEditDialogManager" (actionComplete)="batchActionComplete()"></app-scheduler-batch-edit>
</app-modal-dialog>
