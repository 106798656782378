import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { DisplayHelper, log } from '@app/helpers'
import { BannerStatus, BannerViewManager } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { OverlayPanel } from 'primeng/overlaypanel'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-notifications-banner',
    templateUrl: './notifications-banner.component.html',
    styleUrls: ['./notifications-banner.component.scss'],
    standalone: false
})
export class NotificationsBannerComponent implements OnInit, OnDestroy {
	areNotificationsGrouped = false
	status: BannerStatus
	bvm: BannerViewManager
	subs = new Subscription()

	bannerStatusList: Array<BannerStatus> = []

	@ViewChild('bannerPanel') bannerPanel: OverlayPanel

	constructor(
		private router: Router,
		private coreSrvc: CoreService,
	) {
		// Link to banner service state
		const bvm = this.coreSrvc.bannerSrvc.viewManager
		this.bannerStatusList = [bvm.jobsiteNumbers, bvm.pendingTimeOff, bvm.pendingSchedules, bvm.openShifts, bvm.profileUpdate]
		this.bvm = this.coreSrvc.bannerSrvc.viewManager
		this.status = this.coreSrvc.bannerSrvc.viewManager.aggregator

		this.subs.add(this.coreSrvc.notifySrvc.databaseFinishedLoading.subscribe(() => this.updateUI()))
		this.subs.add(this.coreSrvc.bannerSrvc.groupBannerNeedsUpdate.subscribe(() => this.updateUI()))

		this.subs.add(
			this.coreSrvc.bannerSrvc.bannerPanelShouldHide.subscribe(() => {
				this.bannerPanel?.hide()
			}),
		)
	}

	get shouldShowBanner(): boolean {
		return this.status?.notificationCount > 0
	}

	public updateUI() {
		if (!this.bvm.groupAllBanners) return
		let isWarn = false
		let isDanger = false
		let isCritical = false
		let notificationCount = 0

		for (const banner of this.bannerStatusList) {
			if (banner && banner.isAvailable) {
				if (banner.isWarn) isWarn = true
				if (banner.isDanger) isDanger = true
				if (banner.isCritical) isCritical = true
				notificationCount += banner.notificationCount
			}
		}
		this.status.isWarn = isWarn
		this.status.isDanger = isDanger
		this.status.isCritical = isCritical
		this.status.notificationCount = notificationCount
	}

	public ngOnInit(): void {
		this.updateUI()
	}
	public ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	public viewNotificationsList() {}
}

interface IBannerStatus {
	isWarn: boolean
	isDanger: boolean
	isCritical: boolean
	notificationCount: number
}
