import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { log } from '@app/helpers'
import {
	ClickToCallGlobalConfig,
	ClickToCallListBuilder,
	ClickToCallRecord,
	ClickToCallRequestBuilder,
	ContactType,
	OrgTableDisplayState,
} from '@app/models'
import { CoreService } from '@app/services'
import { Subscription } from 'rxjs'

type AddressBookViewType = 'ADDRESSBOOK' | 'COMPOSEMESSAGE' | 'SENDINGMESSAGE' | 'PLACINGCALL'

enum AddressBookListType {
	activeEmployyes,
	inactiveEmployees,
	supervisors,
	generalContacts,
	clientContacts,
	vendorContacts,
}

interface AddressBookMenuOption {
	type: AddressBookListType
	label: string
}

@Component({
    selector: 'app-click-to-call-address-book',
    templateUrl: './click-to-call-address-book.component.html',
    styleUrls: ['./click-to-call-address-book.component.scss'],
    standalone: false
})
export class ClickToCallAddressBookComponent implements OnInit, OnDestroy {
	clickToCallEnabled = { backend: false, browser: false } // Keep this. Need availability check here for buuttons

	listBuilder: ClickToCallListBuilder

	options: Array<AddressBookMenuOption> = []
	selectedOptionType: AddressBookListType = AddressBookListType.activeEmployyes

	filterText = ''
	records: Array<ClickToCallRecord> = []

	selectedC2CRecord: ClickToCallRecord

	currentView: AddressBookViewType = 'ADDRESSBOOK'

	@Output() callOrTextHasBeenPlaced = new EventEmitter<boolean>()

	private subs = new Subscription()

	constructor(private coreSrvc: CoreService) {
		this.listBuilder = new ClickToCallListBuilder(this.coreSrvc.dbSrvc)
		this.setupMenuOptions()
		this.setupAvailability()
		this.updateUI()
	}

	get filteredRecords(): Array<ClickToCallRecord> {
		if (this.filterText) {
			return this.records.filter((rec) => rec.filterText.includes(this.filterText))
		} else {
			return this.records
		}
	}

	get canAccessCallBtn(): boolean {
		return this.clickToCallEnabled.backend || this.clickToCallEnabled.browser
	}

	ngOnInit(): void {}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	private setupAvailability() {
		const portalPrefs = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany()
		const backEndEnabled = this.coreSrvc.dbSrvc.settingSrvc.isClickToCallEnabled()

		// Setup Click to Call availability
		this.clickToCallEnabled.backend = backEndEnabled
		this.clickToCallEnabled.browser = portalPrefs.globalBrowserClickToCall
	}

	private setupMenuOptions() {
		this.options = [
			{ label: 'Active Employees', type: AddressBookListType.activeEmployyes },
			{ label: 'Inactive Employees', type: AddressBookListType.inactiveEmployees },
			{ label: 'Supervisors', type: AddressBookListType.supervisors },
			{ label: 'General Contacts', type: AddressBookListType.generalContacts },
			{ label: 'Client Contacts', type: AddressBookListType.clientContacts },
			{ label: 'Vendor Contacts', type: AddressBookListType.vendorContacts },
		]
	}

	private setupActiveEmployeeRecords() {
		const empIds: Array<number> = this.coreSrvc.dbSrvc.empSrvc.getActiveEmployees().map((emp) => emp.id)
		const empRecords = this.listBuilder.getRecordsForEmployees(empIds, 'EMPLOYEEPHONE')
		this.records = empRecords
	}

	private setupInactiveEmployeeRecords() {
		const empIds: Array<number> = this.coreSrvc.dbSrvc.empSrvc.getInactiveEmployees().map((emp) => emp.id)
		const empRecords = this.listBuilder.getRecordsForEmployees(empIds, 'EMPLOYEEPHONE')
		this.records = empRecords
	}

	private setupSupervisorRecords() {
		const supIds: Array<number> = this.coreSrvc.dbSrvc.settingSrvc.getUsers().map((user) => user.id)
		const supRecords = this.listBuilder.getRecordsForSupervisors(supIds, 'USERPHONE')
		this.records = supRecords
	}

	private setupGeneralContacts() {
		const contactIds = this.coreSrvc.dbSrvc.contactSrvc.getContacts().map((contact) => contact.id)
		const contactRecords = this.listBuilder.getRecordsForGeneralContacts(contactIds, 'CONTACT')
		this.records = contactRecords
	}

	private setupClientContacts() {
		const clientIds = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationsByViewState(OrgTableDisplayState.clients).map((client) => client.id)
		const clientRecords = this.listBuilder.getRecordsForPrimaryClients(clientIds, 'CLIENT')
		this.records = clientRecords
	}

	private setupVendorContacts() {
		const vendorIds = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationsByViewState(OrgTableDisplayState.vendors).map((client) => client.id)
		const vendorRecords = this.listBuilder.getRecordsForPrimaryVendors(vendorIds, 'VENDOR')
		this.records = vendorRecords
	}

	public updateUI() {
		switch (this.selectedOptionType) {
			case AddressBookListType.activeEmployyes:
				this.setupActiveEmployeeRecords()
				break
			case AddressBookListType.inactiveEmployees:
				this.setupInactiveEmployeeRecords()
				break
			case AddressBookListType.supervisors:
				this.setupSupervisorRecords()
				break
			case AddressBookListType.generalContacts:
				this.setupGeneralContacts()
				break
			case AddressBookListType.clientContacts:
				this.setupClientContacts()
				break
			case AddressBookListType.vendorContacts:
				this.setupVendorContacts()
				break
		}
	}

	public formatContactType(contactType: ContactType) {
		switch (contactType) {
			case 'SITE_MANAGER':
				return 'SITE MANAGER'
			default:
				return contactType
		}
	}

	public filterRecords(text: string) {
		this.filterText = text ? text.toLowerCase() : ''
	}

	public triggerC2CEvent(c2cRecord: ClickToCallRecord, action: 'CALL' | 'TEXT') {
		if (c2cRecord) {
			const config = new ClickToCallGlobalConfig(c2cRecord, action, null, null)
			this.coreSrvc.callSrvc.hideC2CTriggerHost()
			log('Config to global', config)
			this.coreSrvc.eventSrvc.clickToCallGlobalEvent.next(config)
		}
	}

	public placeCall(dest: ClickToCallRecord) {
		this.triggerC2CEvent(dest, 'CALL')
	}

	public startTextMsg(dest: ClickToCallRecord) {
		this.triggerC2CEvent(dest, 'TEXT')
	}

	public cancelTextMsg() {
		log('cancel text message')
		this.currentView = 'ADDRESSBOOK'
		this.selectedC2CRecord = null
	}

	public sendTextMsg(message: string): void {
		log('Send text message', message)
		this.currentView = 'SENDINGMESSAGE'

		const dest = this.selectedC2CRecord
		const builder = new ClickToCallRequestBuilder(dest, null)
		const request = builder.getLambdaRequest(message)
		log('SMS Lambda Request', request)
		this.coreSrvc.dbSrvc.insertRecord('click_to_call_log', request).then((success) => {
			log('SMS Request Sent')
			setTimeout(() => {
				this.coreSrvc.notifySrvc.notify('success', 'Message Sent', `Your text message to ${dest.description} has been sent.`, 3)
				this.currentView = 'ADDRESSBOOK'
				this.selectedC2CRecord = null
				this.callOrTextHasBeenPlaced.next(true)
			}, 1000)
		})
	}
}
