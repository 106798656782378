import { Component, OnInit } from '@angular/core'

import { TransactionException } from '@app/models'
import { DatabaseService } from '@app/services'

import { log } from '@app/helpers'

@Component({
    selector: 'app-exceptions-transaction-list',
    templateUrl: './transaction-list.component.html',
    styleUrls: ['./transaction-list.component.scss'],
    standalone: false
})
export class ExceptionsTransactionListComponent implements OnInit {

	list: TransactionException[]

	constructor(
		private dbSrvc: DatabaseService
	) {
		this.list = this.dbSrvc.excptSrvc.getTransactions()
		log('ExceptionsTransactionListComponent Init:', this.list)
	}

	ngOnInit() { }

}
