<div class="container">
	<span class="section-header"><i class="fa fa-trash" aria-hidden="true"></i> Delete Job Site</span><br>
	<strong *ngIf="jobSiteExists()" style="color:gray">Name: {{ jobSiteDescription }}</strong>

	<div *ngIf="!jobSiteExists()" class="alert alert-warning delete-alert" role="alert">
		<strong>Record Not Found</strong>
	</div>

	<div *ngIf="hasLinkedExceptions" class="alert alert-warning delete-alert">
		We ran into a problem deleting this job site. Please contact support at <a href="mailto:support@telephonetimesheets.com">support&#64;telephonetimesheets.com</a> or call <a href="tel://1-888-250-8463">1-888-250-8463</a> to report this issue.
	</div>

	<div [hidden]="isWorking">

		<div *ngIf="!requirementsMet()" class="alert alert-warning delete-alert" role="alert">
			<div *ngIf="jobsExist()"><strong>Warning:</strong> This job site cannot be deleted because it is currently associated with one or more jobs. You will first need to reassign those jobs to another job site or delete them manually.</div>
			<div *ngIf="isDefaultJobScheduled() && !jobsExist()"><strong>Warning:</strong> The default job is currently scheduled and deleting this site will delete these schedule entries as well.</div>
		</div>

		<div *ngIf="requirementsMet()"><br></div>

		<div *ngIf="jobSiteExists() && requirementsMet()" class="card card-body section-requirement text-center" style="max-width: 350px; margin: 0 auto">
			<p class="card-text">
				<span class="section-req-title">Are you sure?</span>
			</p>
			<div class="confirm-delete-btn-wrap">
				<button (click)="onCancel()" class="btn btn-sm btn-secondary">Cancel</button>
				<button (click)="deleteJobSite()" class="btn btn-sm btn-danger btn-confirm-delete">Delete</button>
			</div>
		</div>

		<div *ngIf="jobsExist()" class="card card-body section-requirement">
			<p class="card-text">
				<span class="section-req-title"><i class="fa fa-calendar" aria-hidden="true"></i> Jobs ({{ jobsCount() }})</span>
				<span class="float-right">
					<button [routerLink]="['/admin/jobs/filter', 'location_id', jobSiteId]" class="btn btn-success btn-sm btn-req-setup" title="view">view</button>
				</span>
			</p>
		</div>

		<!-- <div *ngIf="isDefaultJobScheduled() && !jobsExist()" class="card card-body section-requirement">
			<p class="card-text">
				<span class="section-req-title"><i class="fa fa-calendar" aria-hidden="true"></i> Schedule ({{ defaultJobScheduleCount() }})</span>
				<span class="float-right">
					<button [routerLink]="scheduleViewRouterLink" class="btn btn-success btn-sm btn-req-setup" title="view">view</button>
				</span>
			</p>
		</div> -->



		<!--<div *ngIf="schedulesExist()" class="card card-body section-requirement">
			<p class="card-text">
			<span class="section-req-title"><i class="fa fa-calendar" aria-hidden="true"></i> Schedule ({{ schedulesCount() }})</span>
			<span class="float-right">
				<button [routerLink]="['/admin/schedule']" class="btn btn-success btn-sm btn-req-setup" title="view">view</button>
			</span>
			</p>
		</div>   -->

		<div *ngIf="!requirementsMet()">
			<div class="row">
				<div class="col-12 col-sm-6">
					<div><button (click)="onCancel()" class="btn btn-secondary btn-block action-button">Cancel Operation</button></div>
				</div>
				<div class="col-12 col-sm-6">
					<div><button (click)="deleteJobSite()" [disabled]="jobsExist()" class="btn btn-danger btn-block action-button">Delete Job Site</button></div>
				</div>
			</div>
		</div>

	</div>

</div>
