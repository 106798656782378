import { Injectable } from '@angular/core'

import { ITransactionException, TransactionException, ILocationException, LocationException } from '@app/models'

import { log } from '@app/helpers'
import _ from 'lodash'
import { Subject } from 'rxjs'
import { Global } from '@app/models/global'

@Injectable({
	providedIn: 'root',
})
export class ExceptionsService {
	public transactionDataLoaded = false
	private transactionsList: TransactionException[] = []

	public locationDataLoaded = false
	private locationList: LocationException[] = []

	public transactionListUpdated = new Subject<boolean>()
	public locationListUpdated = new Subject<boolean>()

	constructor() {
		log('Creating ExceptionService')
	}

	clearData() {
		this.clearTransactionData()
		this.clearLocationData()
	}

	clearTransactionData() {
		this.transactionsList = []
		this.transactionDataLoaded = false
		this.transactionListUpdated.next(true)
	}
	clearLocationData() {
		this.locationList = []
		this.locationDataLoaded = false
		this.locationListUpdated.next(true)
	}

	transactionCount(): number {
		return this.transactionsList.length
	}
	locationCount(): number {
		return this.locationList.length
	}

	getTransactions(): TransactionException[] {
		return this.transactionsList
	}
	getLocations(): LocationException[] {
		return this.locationList
	}

	getTransactionById(id: number): TransactionException {
		const trans = this.transactionsList.find((t) => t.id === id)
		return trans ? trans : null
	}
	getLocationById(id: number): LocationException {
		const loc = this.locationList.find((l) => l.id === id)
		return loc ? loc : null
	}

	removeTransactionById(transId: number) {
		this.transactionsList = this.transactionsList.filter((t) => t.id !== transId)
	}
	removeLocationById(locId: number) {
		this.locationList = this.locationList.filter((l) => l.id !== locId)
	}

	makeTransactionException(record: ITransactionException): TransactionException {
		const trans = new TransactionException()
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				trans[attr] = record[attr]
			}
		}
		return trans
	}

	setTransactionExceptionRecords(records: Array<ITransactionException>) {
		this.transactionsList = records.map((c) => this.makeTransactionException(c))
		this.transactionDataLoaded = true
		this.transactionListUpdated.next(true)
	}

	makeLocationException(record: ILocationException): LocationException {
		const loc = new LocationException()
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				loc[attr] = record[attr]
			}
		}
		return loc
	}

	setLocationExceptionRecords(records: Array<ILocationException>) {
		log('LOC', records)
		this.locationList = records.map((c) => this.makeLocationException(c))
		this.locationDataLoaded = true
		this.locationListUpdated.next(true)
	}
}
