import { Injectable, EventEmitter } from '@angular/core'

import { BillingMessageEvent, IPaypalCard, IPaypalCardInfo, PaypalCardAction } from '@app/models'
import { LambdaService } from '@app/services/aws/lambda.service'

import { log } from '@app/helpers'
import moment from 'moment-timezone'
import { StripeCustomerInfo } from '@app/models/stripe'
import { Global } from '@app/models/global'
import { Subject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class BillingService {
	public cardExpirationDayCount = null
	public cardExpirationThreshold = 30
	public cardExpirationChecked = false

	public paypalCreditCardInfo: IPaypalCardInfo
	public stripeCustomerInfo: StripeCustomerInfo

	// Number of days before card expires
	public cardExpirationEvent = new EventEmitter<number>()

	// Post the bottom banner billing message if needed
	public billingMessageEvent = new Subject<BillingMessageEvent>()

	// Trigger UI Updates on billing components
	public updateUiForBillingComponents = new Subject<boolean>()
	public updateBillingNotes = new Subject<string>()

	constructor(private lambdaSrvc: LambdaService) {
		log('Creating BillingService')
	}

	clearData() {
		this.cardExpirationDayCount = null
		this.cardExpirationChecked = false
		this.paypalClearData()
		this.stripeClearData()
	}

	paypalClearData() {
		this.paypalCreditCardInfo = null
	}

	stripeClearData() {
		this.stripeCustomerInfo = null
	}

	paypalHasCardOnFile(): boolean {
		const card = this.paypalCreditCardInfo
		if (card && card.id) {
			return true
		}
		return false
	}

	paypalGetCardInfo(): Promise<any> {
		log('paypalGetCardInfo')
		const payload = { operation: 'read' }
		return this.lambdaSrvc.paypalInfo(payload).then((result) => {
			if (result.success) {
				const data = result.data
				if (data.errorMessage) {
					this.paypalCreditCardInfo = null
				} else {
					this.paypalCreditCardInfo = data
				}
			} else {
				this.paypalCreditCardInfo = null
			}
			return Promise.resolve(result)
		})
	}

	paypalResetExpirationInfo() {
		this.cardExpirationChecked = false
		this.cardExpirationDayCount = null
	}

	paypalCheckForCardExpiration() {
		log('paypalCheckForCardExpiration')
		if (!this.cardExpirationChecked) {
			this.paypalGetCardInfo().then((result) => {
				this.cardExpirationChecked = true
				const card = this.paypalCreditCardInfo
				if (card) {
					log('PayPal Default Card', card)
					this.checkCardExpiration(card.expire_month, card.expire_year)
				}
			})
		}
	}

	stripeCheckForCardExpiration() {
		log('stripeCheckeForCardExpiration')
		if (!this.cardExpirationChecked) {
			this.stripeGetCustomerInfo().then((stripeCustomerInfo) => {
				this.cardExpirationChecked = true
				const paymentMethod = stripeCustomerInfo.getDefaultPaymentMethod()
				if (paymentMethod) {
					log('Stripe Default Payment Method', paymentMethod)
					const card = paymentMethod.card
					if (card) {
						this.checkCardExpiration(card.exp_month, card.exp_year)
					}
				}
			})
		}
	}

	checkCardExpiration(expireMonth: number, expireYear: number) {
		log('Expire Month/Year', expireMonth, expireYear)
		if (expireMonth && expireYear) {
			const dateString = `${expireMonth}/${expireYear}`
			const expireMom = moment(dateString, 'M/YYYY').endOf('month')
			if (expireMom.isValid()) {
				const diff = expireMom.diff(moment(), 'days')
				log('Expiratioin Count', diff)
				if (diff < this.cardExpirationThreshold) {
					this.cardExpirationDayCount = diff
					this.cardExpirationEvent.emit(diff)
				}
			}
		}
	}

	paypalAddOrRetryInfo(record: IPaypalCard, action: PaypalCardAction): Promise<any> {
		log('paypalAddOrRetryInfo', record, action)
		const payload = { operation: action === 'ADD' ? 'add' : 'retry' }
		for (const attr in record) {
			if (record.hasOwnProperty(attr)) {
				payload[attr] = record[attr]
			}
		}
		log('Paypal lambda payload', payload)
		return this.lambdaSrvc.paypalInfo(payload).then((result) => {
			log('Paypal lambda result', result)
			if (result.success) {
				this.paypalCreditCardInfo = result.data
			} else {
				this.paypalCreditCardInfo = null
			}
			return Promise.resolve(result)
		})
	}

	paypalDeleteCardInfo(): Promise<any> {
		log('paypalDeleteCardInfo')
		const payload = { operation: 'delete' }
		return this.lambdaSrvc.paypalInfo(payload).then((result) => {
			if (result.success) {
				this.paypalCreditCardInfo = null
			}
			return Promise.resolve(result)
		})
	}

	// Stripe Mathods

	stripeGetPortalSession(): Promise<any> {
		log('stripeGetPortalSession')
		const payload = { operation: 'stripe_portal' }
		return this.lambdaSrvc.paypalInfo(payload).then((result) => {
			return Promise.resolve(result)
		})
	}

	stripeGetCustomerInfo(): Promise<StripeCustomerInfo> {
		log('stripeGetCustomerInfo')
		const payload = { operation: 'stripe_customer' }
		return this.lambdaSrvc.paypalInfo(payload).then((result) => {
			if (result.success) {
				this.stripeCustomerInfo = new StripeCustomerInfo(result.data)
				Global.coreSrvc.dbSrvc.billSrvc.setupBillingAlertMessages()
				return Promise.resolve(this.stripeCustomerInfo)
			} else {
				return Promise.reject(result)
			}
		})
	}

	// Billing Alert Messages
	setupBillingAlertMessages() {
		const company = Global.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const trialExpires = Global.coreSrvc.dbSrvc.settingSrvc.trialExpiresDate()

		const trialExpireDate = moment(trialExpires, 'YYYY-MM-DD')
		const today = moment()
		const trialDaysLeft = trialExpireDate.diff(today, 'days')

		const accountStatus = company.account_status

		// Setup various alert conditions which trigger banner

		if (accountStatus === 'TRIAL' && trialDaysLeft > 0) {
			const billingErrorMessage = 'Trial ends in ' + trialDaysLeft + ' day' + (trialDaysLeft !== 1 ? 's' : '')
			const buttonText = 'Buy'
			const trialEventMsg = new BillingMessageEvent(buttonText, billingErrorMessage, false)
			if (trialDaysLeft > 15) {
				trialEventMsg.warn = true
			}
			if (trialDaysLeft <= 7) {
				trialEventMsg.blink = true
			} else {
				trialEventMsg.blink = false
			}
			this.billingMessageEvent.next(trialEventMsg)
			return
		}
		if (accountStatus === 'TRIAL' && trialDaysLeft === 0) {
			const billingErrorMessage = 'Trial ends today'
			const buttonText = 'Buy'
			const trialEventMsg = new BillingMessageEvent(buttonText, billingErrorMessage, true)
			this.billingMessageEvent.next(trialEventMsg)
			return
		}
		if (accountStatus === 'TRIAL' && trialDaysLeft < 0) {
			const billingErrorMessage = 'Trial has ended'
			const buttonText = 'Buy'
			const trialEventMsg = new BillingMessageEvent(buttonText, billingErrorMessage, true)
			this.billingMessageEvent.next(trialEventMsg)
			return
		}
		if (accountStatus === 'BILLING_NEED_INFO') {
			const billingErrorMessage = 'Billing Info Needed'
			const buttonText = 'Fix'
			const trialEventMsg = new BillingMessageEvent(buttonText, billingErrorMessage, true)
			this.billingMessageEvent.next(trialEventMsg)
			return
		}
		if (accountStatus === 'BILLING_PAYMENT_FAILED') {
			const billingErrorMessage = 'Billing Payment Failed'
			const buttonText = 'Fix'
			const trialEventMsg = new BillingMessageEvent(buttonText, billingErrorMessage, true)
			this.billingMessageEvent.next(trialEventMsg)
			return
		}
		if (accountStatus === 'BILLING_PORTAL_LOCKED') {
			const billingErrorMessage = 'Admin Portal Disabled'
			const buttonText = 'Fix'
			const trialEventMsg = new BillingMessageEvent(buttonText, billingErrorMessage, true)
			this.billingMessageEvent.next(trialEventMsg)
			return
		}
		if (accountStatus === 'BILLING_ACCOUNT_LOCKED' || accountStatus === 'LOCKED_SUSPENDED') {
			const billingErrorMessage = 'Account Disabled'
			const buttonText = 'Fix'
			const trialEventMsg = new BillingMessageEvent(buttonText, billingErrorMessage, true)
			this.billingMessageEvent.next(trialEventMsg)
			return
		}
		if (accountStatus === 'PREPAID') {
			if (company.prepaid_balance <= 0) {
				const billingErrorMessage = 'Add More Funds'
				const buttonText = 'Info'
				const trialEventMsg = new BillingMessageEvent(buttonText, billingErrorMessage, true)
				this.billingMessageEvent.next(trialEventMsg)
				return
			}
		}
		this.billingMessageEvent.next(null)
	}
}
