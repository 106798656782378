<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div [hidden]="isUpdating || isManagingSkills">
	<div *ngIf="false" class="alert alert-warning">
		Disclaimer: Use of recording services indicates you agree to the follow all laws governing operation in your jurisdiction and blah blah blah ...
	</div>

	<!-- Main Form -->
	<form class="edit-form-block" novalidate [formGroup]="ccForm" (keydown.enter)="$event.preventDefault()" autocomplete="off">
		<div class="options-section-header">Outbound Calls</div>

		<!-- Call Support Banner -->
		<div *ngIf="!ccForm.get('c2c_enable').value">
			<div class="alert alert-warning" style="margin: 15px 0px 20px 0px">
				Please call support for more information or to enable <strong>Outbound Click-to-Call</strong> functionality.
			</div>
		</div>

		<!-- Outbound caller ID number -->
		<div *ngIf="ccForm.get('c2c_enable').value" style="margin: 15px 0px 20px 0px">
			<div>
				<div class="options-title-inline">Outbound Caller ID</div>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('outboundCallerId')"></i>
				<div class="input-disabled">{{ outboundCallerId }}</div>
			</div>
			<app-form-phone-number *ngIf="false && isInternal" [manager]="outboundPhoneManager" (showHelp)="showHelp($event)"></app-form-phone-number>
		</div>

		<div class="checkbox-item d-flex justify-content-between" [class.disabled]="!ccForm.get('c2c_enable').value">
			<div class="checkbox-wrap">
				<p-checkbox formControlName="c2c_record_enable" [binary]="true" (onChange)="updateDependencies()"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleCheckbox('c2c_record_enable')" class="has-pointer">Record all outbound calls</strong>
			</div>
		</div>

		<div class="checkbox-item d-flex justify-content-between" [class.disabled]="!ccForm.get('c2c_record_enable').value">
			<div class="checkbox-wrap checkbox-child">
				<p-checkbox formControlName="syn_c2c_email_enable" [binary]="true" (onChange)="updateDependencies()"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleCheckbox('syn_c2c_email_enable')" class="has-pointer">Email outbound call recordings</strong>
				<i title="More Information" class="far fa-info-circle tts-info-btn enable" (click)="showHelp('emailOutboundCallRecordings')"></i>
			</div>
		</div>

		<!-- C2C Preamble Message -->
		<!-- [class.input-required]="!ccForm.get('c2c_preamble_message').value" -->
		<div *ngIf="ccForm.get('c2c_record_enable').value" class="options-block" style="margin-top: 20px">
			<div class="options-title-inline">Outbound Call Preamble</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn enable" (click)="showHelp('c2c_preamble_message')"></i>
			<div *ngIf="!ccForm.get('c2c_preamble_message').value" class="input-optional float-right">(optional)</div>
			<textarea class="options-textarea" placeholder="Enter a preamble message here" formControlName="c2c_preamble_message"></textarea>
		</div>

		<!-- Email voicemail recordings to -->
		<div *ngIf="ccForm.get('c2c_enable').value && ccForm.get('syn_c2c_email_enable').value" style="margin-top: 30px">
			<div style="margin-bottom: 12px">
				<strong>Email Outbound Call Recordings</strong>
			</div>
			<app-generic-email-list [manager]="outboundCallRecordingsEmailListManager"></app-generic-email-list>
		</div>

		<hr style="margin: 30px 0px" />
		<div class="options-section-header">Inbound Calls</div>

		<!-- Call Support Banner -->
		<div *ngIf="!ccForm.get('cc_enable').value" style="margin: 15px 0px 20px 0px">
			<div class="alert alert-warning">
				Please call support for more information or to enable <strong>Inbound Call Center</strong> functionality.
			</div>
		</div>

		<!-- Inbound phone number -->
		<div *ngIf="ccForm.get('cc_enable').value" style="margin-top: 15px">
			<div *ngIf="inboundPhoneNumber" style="margin-bottom: 20px">
				<div class="options-title-inline">Inbound Dispatch Number</div>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('inboundPhone')"></i>
				<div class="input-disabled">{{ inboundPhoneNumber | displayPhone }}</div>
			</div>
			<app-form-phone-number *ngIf="false" [manager]="inboundPhoneManager" (showHelp)="showHelp($event)"></app-form-phone-number>
		</div>

		<div class="checkbox-item d-flex justify-content-between" [class.disabled]="!ccForm.get('cc_enable').value">
			<div class="checkbox-wrap">
				<p-checkbox formControlName="cc_record_enable" [binary]="true" (onChange)="updateDependencies()"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleCheckbox('cc_record_enable')" class="has-pointer">Record all inbound calls</strong>
			</div>
		</div>

		<div class="checkbox-item d-flex justify-content-between" [class.disabled]="!ccForm.get('cc_record_enable').value">
			<div class="checkbox-wrap checkbox-child">
				<p-checkbox formControlName="syn_cc_email_enable" [binary]="true" (onChange)="updateDependencies()"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleCheckbox('syn_cc_email_enable')" class="has-pointer">Email inbound call recordings </strong>
				<i title="More Information" class="far fa-info-circle tts-info-btn enable" (click)="showHelp('emailInboundCallRecordings')"></i>
			</div>
		</div>

		<!-- CC Preamble Message -->
		<!-- [class.input-required]="!ccForm.get('cc_record_message').value" -->
		<div *ngIf="ccForm.get('cc_record_enable').value" class="options-block" style="margin-top: 20px">
			<div class="options-title-inline">Inbound Call Preamble</div>
			<i title="More Information" class="far fa-info-circle tts-info-btn enable" (click)="showHelp('cc_preamble_message')"></i>
			<div *ngIf="!ccForm.get('cc_preamble_message').value" class="input-optional float-right">(optional)</div>
			<textarea class="options-textarea" placeholder="Enter a preamble message here" formControlName="cc_preamble_message"></textarea>
		</div>

		<!-- Email inbound recordings to -->
		<div *ngIf="ccForm.get('syn_cc_email_enable').value" style="margin: 30px 0px">
			<div style="margin-bottom: 12px">
				<strong>Email Inbound Call Recordings</strong>
			</div>
			<app-generic-email-list [manager]="inboundRecordingsEmailListManager"></app-generic-email-list>
		</div>

		<!-- <hr style="margin: 30px 0px" />
		<div class="options-section-header" style="margin-bottom: 25px">Voicemail System</div> -->

		<div class="checkbox-item d-flex justify-content-between" [class.disabled]="!ccForm.get('cc_enable').value">
			<div class="checkbox-wrap">
				<p-checkbox formControlName="cc_vm_enable" [binary]="true" (onChange)="updateDependencies()"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleCheckbox('cc_vm_enable')" class="has-pointer">Enable voicemail</strong>
			</div>
		</div>

		<div class="checkbox-item d-flex justify-content-between" [class.disabled]="!ccForm.get('cc_vm_enable').value">
			<div class="checkbox-wrap checkbox-child">
				<p-checkbox formControlName="syn_cc_vm_email_enable" [binary]="true" (onChange)="updateDependencies()"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="toggleCheckbox('syn_cc_vm_email_enable')" class="has-pointer">Email voicemail recordings</strong>
				<i title="More Information" class="far fa-info-circle tts-info-btn enable" (click)="showHelp('emailVoicemailRecordings')"></i>
			</div>
		</div>

		<!-- Voicemail Message -->
		<div
			*ngIf="ccForm.get('cc_vm_enable').value"
			class="options-block"
			[class.input-required]="!ccForm.get('cc_vm_message').value"
			style="margin-top: 20px"
		>
			<div class="options-title-inline">Voicemail Message</div>
			<div *ngIf="!ccForm.get('cc_vm_message').value" class="float-right">(required)</div>
			<textarea class="options-textarea" placeholder="Enter a voicemail message here" formControlName="cc_vm_message"></textarea>
		</div>

		<!-- Email voicemail recordings to -->
		<div *ngIf="ccForm.get('syn_cc_vm_email_enable').value" style="margin-top: 20px">
			<div style="margin-bottom: 12px">
				<strong>Email Voicemail Recordings</strong>
			</div>
			<app-generic-email-list [manager]="inboundVoicemailEmailListManager"></app-generic-email-list>
		</div>

		<!-- Manage Skills -->
		<div *ngIf="isInboundCallingEnabled" style="margin-top: 25px">
			<button class="btn btn-outline-secondary btn-block" (click)="startManagingSkills()">Manage Skills</button>
		</div>

		<!-- General Setting - Currently showing if inbound calling is enabled since only one option -->
		<div *ngIf="isInboundCallingEnabled">
			<div class="options-section-header" style="margin-top: 30px">General Settings</div>

			<div class="form-group form-group-lg" style="margin-top: 18px">
				<div class="row">
					<div class="col-8">
						<div>
							<strong>Active Agent Automatic Signout Timer</strong>
							<span class="text-muted tsf-time-abbrv">(hrs)</span>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('agent_logout_timer')"></i>
						</div>
					</div>
					<div class="col-4">
						<div class="float-right">
							<input
								type="tel"
								style="width: 55px; text-align: right"
								class="form-control small-input-inline"
								formControlName="agent_logout_timer"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>

<div *ngIf="isManagingSkills">
	<app-call-center-manage-skills [dialogManager]="dialogManager" (endManagingSkills)="endManagingSkills()"></app-call-center-manage-skills>
</div>

<!-- <div>
	<pre><code>{{ ccForm.value | json }}</code></pre>
</div> -->
