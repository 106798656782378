import { Subject } from 'rxjs'

export class SectionSwitcherTab {
	id: string
	label = 'Tab Name'
	count = () => 0

	constructor(id: string, label: string) {
		this.id = id
		this.label = label.toLowerCase()
	}
}

export class SectionSwitcherConfig {
	initialTab: SectionSwitcherTab
	tabs: Array<SectionSwitcherTab> = []

	tabChanged = new Subject<SectionSwitcherTab>()

	constructor(tabs?: Array<SectionSwitcherTab>) {
		if (tabs) {
			this.tabs = tabs
		}
	}

	setInitialTabById(id: string) {
		const setTab = this.tabs.find((tab) => tab.id === id)
		if (setTab) {
			this.initialTab = setTab
		}
	}

	setTab(id: string) {
		const tab = this.tabs.find((tab) => tab.id === id)
		if (tab) {
			this.tabChanged.next(tab)
		}
	}
}
