import { Component, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { ActivatedRoute } from '@angular/router'

import { JobSiteRecord } from '@app/models'
import { DatabaseService } from '@app/services'

import { log } from '@app/helpers'

@Component({
    selector: 'app-job-site-delete',
    templateUrl: './job-site-delete.component.html',
    styleUrls: ['./job-site-delete.component.scss'],
    standalone: false
})
export class JobSiteDeleteComponent implements OnInit {

	jobSiteId: number
	jobSiteDescription: string
	isWorking: boolean
	hasLinkedExceptions = false

	scheduleViewRouterLink = null

	private jobSite: JobSiteRecord

	constructor(
		route: ActivatedRoute,
		private location: Location,
		private dbSrvc: DatabaseService,
	) {
		this.jobSiteId = parseInt(route.snapshot.params['jobSiteId'], 10) || null
		this.jobSite = this.dbSrvc.siteSrvc.getJobSiteById(this.jobSiteId)
		if (this.jobSite) {
			this.jobSiteDescription = this.jobSite.description
		}
		this.setupScheduleViewLink()
	}

	ngOnInit() { }

	setupScheduleViewLink() {
		const defaultJob = this.dbSrvc.jobSrvc.getJobs().find(j => j.location_id === this.jobSiteId && j.auto_created)
		if (defaultJob) {
			this.scheduleViewRouterLink = ['/admin/scheduler/filter', 'job_id', defaultJob.id]
		}
		log('Router Link', this.scheduleViewRouterLink)
	}

	jobSiteExists(): boolean { return this.jobSite != null }
	jobsExist(): boolean { return this.jobsCount() > 0 }
	jobsCount(): number { return this.dbSrvc.jobSrvc.getJobsForJobSiteId(this.jobSiteId).length }
	requirementsMet(): boolean { return !this.jobsExist() && !this.isDefaultJobScheduled() }
	defaultJobScheduleCount(): number {
		const defaultJob = this.dbSrvc.jobSrvc.getJobs().find(j => j.location_id === this.jobSiteId && j.auto_created)
		if (defaultJob) {
			const schedules = this.dbSrvc.schedulerSrvc.getSchedules().filter(sched => sched.job_id === defaultJob.id)
			return schedules.length
		}
		return 0
	}
	isDefaultJobScheduled() {
		if (this.defaultJobScheduleCount() > 0) { return true }
		return false
	}

	deleteJobSite(): boolean {
		this.dbSrvc.deleteRecord('location', this.jobSiteId).then(delSuccess => {
			if (delSuccess) {
				this.dbSrvc.readTable('job').then(jobResult => {
					this.location.back()
				})
				// schedule_view deprecated
				// this.dbSrvc.readTable('schedule_view').then(readSuccess => {
				// 	if (readSuccess) {
				// 		this.location.back()
				// 	}
				// })
			}
		})
		return false
	}

	onCancel(): boolean {
		this.location.back()
		return false
	}

}
