<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

<div *ngIf="!isUpdating">
	<div class="alert alert-info alert-bg-gray" style="margin-bottom: 25px; line-height: 1.3rem">
		A <strong style="color: green">green</strong> highlight indicates an item is enabled and contributing to your Health Score. When grayed out, the
		item will neither contribute to your score nor be visible in Health Center.
	</div>

	<div style="margin: 0px 0px 20px 0px">
		<div style="margin-bottom: 6px">
			<app-search-filter [placeholder]="'filter items'" (search)="performSearch($event)"></app-search-filter>
		</div>

		<div style="text-align: right">
			<span class="link-text" (click)="toggleExpansion(false)">collapse all</span> |
			<span class="link-text" (click)="toggleExpansion(true)">expand all</span>
		</div>
	</div>

	<div *ngFor="let category of manager.healthCenterCategories">
		<app-form-expander
			*ngIf="category.matchesFilter"
			[style]="{ 'margin-top': '5px' }"
			[label]="category.label"
			[labelStyle]="{ 'font-size': '1em', 'font-weight': '500', 'color': '#807040' }"
			[counter]="category.expanded || searchText ? null : category.sections.length"
			[(isExpanded)]="category.expanded"
		>
			<div *ngFor="let section of category.sections">
				<app-form-expander
					*ngIf="section.matchesFilter"
					[style]="{ 'margin-left': '5px', 'margin-top': '5px' }"
					[label]="section.label"
					[labelStyle]="{ 'font-size': '1em', 'font-weight': '500', 'color': 'gray' }"
					[counter]="section.expanded || searchText ? null : section.alerts.length"
					[(isExpanded)]="section.expanded"
				>
					<div *ngFor="let alert of section.alerts; let idx = index">
						<div
							*ngIf="alert.matchesFilter"
							class="card alert-card"
							[class.mt-4]="idx > 0"
							[class.card-contributes]="alert.contributor"
							[class.card-disabled]="!alert.enabled"
						>
							<div class="card-content">
								<div class="switch-wrap float-right">
									<p-toggleswitch [(ngModel)]="alert.enabled" binary="true" (onChange)="toggleEnableStatus(alert)"></p-toggleswitch>
								</div>
								<div>
									<div>
										<b class="alert-label">{{ alert.label }}</b>
									</div>
									<div class="alert-description" [innerHTML]="alert.description | sanitizer"></div>

									<hr />

									<div *ngIf="alert.enabled">
										<div style="margin-top: 8px">
											<p-checkbox [style]="{ 'margin-right': '8px' }" [(ngModel)]="alert.contributor" [binary]="true"></p-checkbox>
											<div class="contributor-label" (click)="alert.contributor = !alert.contributor">Contribute to health score</div>
										</div>
										<div class="alert-default">
											<div *ngIf="alertMatchesDefault(alert)" class="float-right">
												<i class="fad fas fa-thumbs-up" style="font-size: 1.3rem; line-height: 1rem; color: #135c94"></i>
											</div>
											<div>
												<div *ngIf="alertMatchesDefault(alert)">Using recommended setting</div>
												<div *ngIf="!alertMatchesDefault(alert)" style="color: chocolate">
													{{
														alertDefaultContributorStatus(alert)
															? 'We recommend you enable contribution'
															: 'We recommend you disable contriubtion'
													}}
												</div>
											</div>
										</div>
									</div>

									<div *ngIf="!alert.enabled">Will not be shown in Health Center</div>
								</div>
							</div>
						</div>
					</div>
				</app-form-expander>
			</div>
		</app-form-expander>
	</div>

	<div style="margin-top: 20px">
		<button class="btn btn-sm btn-block btn-outline-secondary" (click)="resetToDefaults()">Reset To Defaults</button>
	</div>
</div>
