import { AfterViewInit, Component, OnDestroy } from '@angular/core'
import { log } from '@app/helpers'
import { CoreService } from '@app/services'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-billing-status-alert',
    templateUrl: './billing-status-alert.component.html',
    styleUrl: './billing-status-alert.component.scss',
    standalone: false
})
export class BillingStatusAlertComponent implements AfterViewInit, OnDestroy {
	billingNotes: string
	billingNotesAlertClass = 'alert alert-warning'

	private subs = new Subscription()

	constructor(private coreSrvc: CoreService) {
		this.updateUI()
	}

	ngAfterViewInit() {
		this.subs.add(this.coreSrvc.dbSrvc.billSrvc.updateUiForBillingComponents.subscribe({ next: () => this.updateUI() }))
		this.subs.add(this.coreSrvc.dbSrvc.billSrvc.updateBillingNotes.subscribe({ next: (notes) => (this.billingNotes = notes) }))

		// Status / Billing alert messages updated
		this.subs.add(this.coreSrvc.dbSrvc.billSrvc.billingMessageEvent.subscribe((event) => this.updateUI()))
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	get hasTrialExpired(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.hasTrialExpired()
	}

	get cardExpirationDayCount(): number {
		const count = this.coreSrvc.dbSrvc.billSrvc.cardExpirationDayCount
		const expirationThreshold = this.coreSrvc.dbSrvc.billSrvc.cardExpirationThreshold
		if (count < expirationThreshold) {
			return count
		}
		return null
	}

	private updateUI() {
		log('prepareBillingNotes')
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		if (!company) {
			return
		}
		const status = company.account_status
		const balance = company.prepaid_balance
		const absBalance = Math.abs(balance).toFixed(2)
		const curSym = company.currencySymbol()
		log('Company Status', status)

		switch (status) {
			case 'BILLING_NEED_INFO':
				if (this.hasTrialExpired) {
					this.billingNotes = 'Please update your payment information.'
				} else {
					this.billingNotes = 'Please update your payment information. This account will be disabled soon.'
				}
				this.billingNotesAlertClass = 'alert alert-danger'
				break

			case 'BILLING_PAYMENT_FAILED':
				const commonNote = `Please update your payment information using the <b>Manage in Stripe</b> button and setup a valid payment method. The system will automatically retry any failed billing attempt after you update your settings.`
				this.billingNotes =
					this.cardExpirationDayCount < 0 ? `You card has expired. ${commonNote}` : `The last billing attempt failed. ${commonNote}`
				this.billingNotesAlertClass = 'alert alert-danger'
				break

			case 'BILLING_PORTAL_LOCKED':
			case 'BILLING_ACCOUNT_LOCKED':
			case 'LOCKED_SUSPENDED':
				this.billingNotes = 'Your account is currently disabled. Please update your payment information to unlock your account.'
				this.billingNotesAlertClass = 'alert alert-danger'
				break

			case 'PREPAID':
				const empCount = this.coreSrvc.dbSrvc.empSrvc.getActiveEmployees().length
				const monthlyBillingCost = this.coreSrvc.dbSrvc.settingSrvc.monthlyBillingCost(empCount)
				if (balance < 0) {
					this.billingNotes =
						'Your prepaid account has a balance of <strong>-' +
						curSym +
						absBalance +
						'</strong>. Please email <a href="mailto:support@telephonetimesheets.com">support@telephonetimesheets.com</a> to request another invoice.'
					this.billingNotesAlertClass = 'alert alert-danger'
					return
				}
				if (balance === 0) {
					this.billingNotes =
						'Your prepaid account balance is <strong>' +
						curSym +
						'0</strong>. Please email <a href="mailto:support@telephonetimesheets.com">support@telephonetimesheets.com</a> to request another invoice.'
					this.billingNotesAlertClass = 'alert alert-danger'
					return
				}
				if (balance > monthlyBillingCost) {
					this.billingNotes = 'Your prepaid account balance is <strong>' + curSym + absBalance + '</strong>.'
					this.billingNotesAlertClass = 'alert alert-success'
					return
				} else {
					this.billingNotes = 'Your prepaid account balance is <strong>' + curSym + absBalance + '</strong>.'
					this.billingNotesAlertClass = 'alert alert-warning'
					return
				}

			case 'INVOICE':
			case 'ADP_INVOICE':
				if (balance < 0) {
					this.billingNotesAlertClass = 'alert alert-success'
					this.billingNotes = 'Your account credit is <strong>' + curSym + absBalance + '</strong>.'
				}
				if (balance === 0) {
					this.billingNotesAlertClass = 'alert alert-success'
					this.billingNotes = 'Your account balance is <strong>' + curSym + absBalance + '</strong>.'
				}
				if (balance > 0) {
					this.billingNotesAlertClass = 'alert alert-warning'
					this.billingNotes = 'Your account balance is <strong>' + curSym + absBalance + '</strong>.'
				}
				break

			case 'TESTING':
				this.billingNotes = 'This is a test account. No payment information required.'
				break

			case 'TRIAL':
				break

			default:
				this.billingNotes = null
				break
		}
	}
}
