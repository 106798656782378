import { Pipe, PipeTransform } from '@angular/core'
import { log, DateTimeHelper } from '@app/helpers'
import moment from 'moment-timezone'

@Pipe({
    name: 'dateTzFormatter',
    standalone: false
})
export class DateTzFormatter implements PipeTransform {
	transform(value: any, tz: string, format: string): any {
		if (!tz || !format) {
			return `args: 'tz':'format'`
		}
		const dateMom = moment.tz(value, tz)
		if (dateMom.isValid()) {
			return dateMom.format(format)
		} else {
			return 'Not Set'
		}
	}
}
