<app-basic-modal
	[modalId]="id"
	[visible]="visible"
	(visibleChange)="visibilityChanged($event)"
	[preventBackgroundClose]="true"
	[modalHeader]="header"
	[modalFooter]="footer"
	[modalWidth]="450"
>
	<div *ngIf="isDataLoaded">
		<app-audit-log-diff [records]="records" [entry]="auditEntry"></app-audit-log-diff>
	</div>
	<div *ngIf="isDataLoading" class="loading-block"><i class="fas fa-loader fa-spin"></i> Loading Data...</div>
</app-basic-modal>
