<div class="images-block d-flex flex-wrap justify-content-start">
	<div *ngFor="let image of images; let idx = index" class="image-wrap" (click)="fetchInfo(image)">
		<img [src]="image.getUrl()" [class.img-selected]="image === selectedImage" alt="Photo {{ idx + 1 }}" width="100" />
	</div>
</div>

<div *ngIf="selectedImage">
	<div class="hash"><strong>Image&nbsp;ID:</strong>&nbsp;{{ selectedImage?.sha256 }}</div>

	<hr />
	<div class="list-header">
		<div class="list-header-title">Found in {{ duplicates.length }} time entr{{ duplicates.length === 1 ? 'y' : 'ies' }}:</div>
	</div>

	<div *ngFor="let drw of duplicates" class="card dup-card">
		<div>
			<div class="d-flex justify-content-between">
				<div class="dup-card-label">
					ID: {{ drw.record.transaction_log_id }}
					<i *ngIf="transId === drw.record.transaction_log_id" class="fas fa-star star-icon"></i>
				</div>
				<div class="dup-card-label">{{ drw.record.job_date }}</div>
			</div>
			<div class="d-flex justify-content-between">
				<div class="link-text" (click)="viewTransaction(drw)">View Time Entry</div>
				<div class="link-text" (click)="viewShiftReport(drw)">View Shift Report</div>
			</div>
		</div>
	</div>
	<div class="list-footer"><i *ngIf="transId" class="fas fa-star" style="color: green"></i> indicates the current time entry</div>
</div>
