<div>
	<textarea
		#rangeBasicTextArea
		rows="1"
		class="form-control item-label-textarea"
		placeholder="Enter range description"
		[(ngModel)]="config.label"
		(input)="textResizer.emit(rangeBasicTextArea)"
		(focus)="clearError.emit(item)"
	></textarea>
	<div *ngIf="item.showConfig">
		<div class="checkbox-item cli-comment-block d-flex justify-content-between">
			<div class="checkbox-wrap">
				<p-checkbox [(ngModel)]="config.includeComment" [binary]="true"></p-checkbox>
			</div>
			<div class="checkbox-label-wrap">
				<strong (click)="config.includeComment = !config.includeComment" class="has-pointer">Include comment field</strong>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp.emit('itemHasComment')"></i>
			</div>
		</div>
		<hr />
		<div class="config-options">
			<div class="config-option">
				<input type="tel" class="form-control config-input float-right" [(ngModel)]="config.min" (focus)="clearError.emit(item)" />
				<div class="options-title-inline">Minimum Value</div>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp.emit('rangeMin')"></i>
			</div>
			<div class="config-option">
				<input type="tel" class="form-control config-input float-right" [(ngModel)]="config.max" (focus)="clearError.emit(item)" />
				<div class="options-title-inline">Maximum Value</div>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp.emit('rangeMax')"></i>
			</div>
			<!-- <div *ngIf="false" class="config-option">
				<input type="number" class="form-control config-input float-right" [(ngModel)]="config.step" (focus)="clearError.emit(item)" />
				<div class="options-title-inline">Step Value</div>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp.emit('rangeStep')"></i>
			</div> -->
			<div class="config-option">
				<div class="options-title-inline">Range Labels</div>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp.emit('rangeLabel')"></i>
				<div class="d-flex justify-content-between">
					<input
						type="text"
						maxlength="20"
						class="form-control range-label-input float-right"
						style="margin-right: 4px"
						placeholder="Left Label"
						[(ngModel)]="config.rangeLabel.left"
						(focus)="clearError.emit(item)"
					/>
					<input
						type="text"
						maxLength="20"
						class="form-control range-label-input float-right"
						style="margin-left: 4px"
						placeholder="Right Label"
						[(ngModel)]="config.rangeLabel.right"
						(focus)="clearError.emit(item)"
					/>
				</div>
			</div>
		</div>
	</div>
</div>
