import { RegionCode } from '@app/models'

export type CurrencyCode = 'CAD' | 'GBP' | 'JEP' | 'JMD' | 'USD'
export type CurrencySymbol = '' | '$' | '£'

export interface CurrencyListItem {
	region: RegionCode
	code: CurrencyCode
	symbol: CurrencySymbol
}
export class CurrencyHelper {
	static currencyList: Array<CurrencyListItem> = [
		{ region: 'CA', code: 'CAD', symbol: '$' },
		{ region: 'GB', code: 'GBP', symbol: '£' },
		{ region: 'JE', code: 'JEP', symbol: '£' },
		{ region: 'JM', code: 'JMD', symbol: '$' },
		{ region: 'PR', code: 'USD', symbol: '$' },
		{ region: 'US', code: 'USD', symbol: '$' },
	]

	static getCurrencySymbolForRegion(region: RegionCode): CurrencySymbol {
		const result = CurrencyHelper.currencyList.find((item) => item.region === region)
		return result ? result.symbol : ''
	}
}
