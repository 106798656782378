<app-activity-banner [isProcessing]="!status.isLoaded" [message]="'Loading Records ...'"></app-activity-banner>

<div *ngIf="status.isLoaded && !status.isEditing">
	<div style="margin-bottom: 32px">
		<app-search-filter [placeholder]="'filter numbers'" (search)="performSearch($event)"></app-search-filter>
	</div>

	<div *ngFor="let vm of list">
		<div *ngIf="vm.matchesSearchFilter" class="card">
			<div class="card-body">
				<div class="icon-wrap float-right">
					<i class="far fa-edit act-ico act-ico-clr-green act-ico-dark-hover" (click)="editRecord(vm)"></i>
					<i class="far fa-trash-alt act-ico act-ico-clr-red act-ico-dark-hover" (click)="deleteRecord(vm)"></i>
				</div>
				<div class="pn-desc tts-hl-2">{{ vm.record.description }}</div>
				<div class="pn-number">{{ vm.record.phone_e164 | displayPhone }}</div>
				<div *ngIf="vm.record.external_id" class="pn-ext-id tts-hl-1">{{ vm.record.external_id }}</div>

				<!-- Delete Confirmation -->
				<div *ngIf="vm.confirmDelete" class="delete-confirm-block">
					<hr class="delete-confirm-hr" />
					<div class="float-right">
						<button class="btn btn-sm btn-outline-secondary" style="margin-right: 12px" (click)="vm.confirmDelete = false">Cancel</button>
						<button class="btn btn-sm btn-outline-danger" (click)="confirmDelete(vm)" style="width: 60px">
							<span *ngIf="!vm.isDeleting">Delete</span>
							<i class="fa fa-loader fa-spin" *ngIf="vm.isDeleting"></i>
						</button>
					</div>
					<div class="delete-confirm-label">Confirm Delete</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="status.isEditing">
	<app-adp-ivr-phone-number-edit
		[dialogManager]="dialogManager"
		[recordId]="editAction.recordId"
		(recordUpdated)="editComplete()"
	></app-adp-ivr-phone-number-edit>
</div>
