<div class="container section-container">
	<!-- Reports - Define -->
	<div *ngIf="viewManager.currentView === 'DEFINE'" class="sec-head-wrap">
		<div class="sec-act-ico-wrap">
			<i
				class="fas fa-plus sec-act-ico"
				[class.section-control-disabled]="!canPerformAction(CrudAction.create)"
				(click)="createRecord()"
				title="Add Report"
			></i>
			<i class="far fa-cog sec-act-ico" (click)="prefsIconClicked()" title="Preferences"></i>
			<i class="far fa-question-circle sec-act-ico" (click)="toggleTooltips()" title="Show Help"></i>
		</div>

		<div class="sec-title-wrap">
			<i class="far fa-pie-chart sec-head-ico" aria-hidden="true"></i>
			<span>Insight Reports</span>
			<app-sub-section-title [isVisible]="true" [subSectionTitle]="subSectionTitle"></app-sub-section-title>
		</div>

		<div class="sub-head-wrap">
			<div class="sub-head-ico-wrap">
				<i class="far fa-refresh sub-head-ico" (click)="reloadTables()" title="Refresh Data"></i>
			</div>
			<div class="updated-wrap">{{ lastUpdated() }}</div>
		</div>
	</div>

	<!-- Reports - View -->
	<div *ngIf="viewManager.currentView === 'VIEW'" class="sec-head-wrap">
		<div class="sec-act-ico-wrap">
			<i class="far fa-cog sec-act-ico" (click)="prefsIconClicked()" title="Preferences"></i>
			<i class="far fa-question-circle sec-act-ico" (click)="toggleTooltips()" title="Show Help"></i>
		</div>

		<div class="sec-title-wrap">
			<div *ngIf="viewManager.currentView === 'VIEW' && !viewManager.dayViewDate">
				<i class="far fa-pie-chart sec-head-ico" aria-hidden="true"></i>
				<span>Insight Reports</span>
				<app-sub-section-title [isVisible]="true" [subSectionTitle]="subSectionTitle"></app-sub-section-title>
			</div>

			<div class="current-date" *ngIf="viewManager.currentView === 'VIEW' && viewManager.dayViewDate" (click)="openDayViewPicker()">
				<div style="display: inline-block">
					<app-date-time-picker
						[useTemplate]="true"
						[currentDate]="viewManager.dayViewDate"
						[pickerType]="'DATE'"
						[pickerMode]="'dialog'"
						[hour12Timer]="true"
						[format]="'MMM Do, YYYY'"
						(pickerDidClose)="setDayViewDate($event)"
					>
						{{ currentDayViewDate | dateFormatter: 'ddd, MMM Do' }}

						<div class="sec-title-ico-wrap">
							<i class="fal fa-calendar sec-title-ico"></i>
						</div>
					</app-date-time-picker>
				</div>
			</div>
		</div>

		<div class="sub-head-wrap">
			<div class="sub-head-ico-wrap">
				<!-- Day View Back and Forward Icons -->
				<div *ngIf="viewManager.currentView === 'VIEW'" class="ico-inline-block">
					<i *ngIf="!viewManager.dayViewDate" class="far fa-calendar sub-head-ico" (click)="dayViewDateIconClicked()"></i>
					<i
						*ngIf="viewManager.dayViewDate"
						class="far fa-calendar-check sub-head-ico sub-head-ico-highlight"
						(click)="dayViewDateIconClicked()"
					></i>
					<i *ngIf="viewManager.dayViewDate" class="far fa-arrow-left sub-head-ico" (click)="moveToPreviousDate()" title="Previous Day"></i>
					<i *ngIf="viewManager.dayViewDate" class="far fa-arrow-right sub-head-ico" (click)="moveToNextDate()" title="Next Day"></i>
				</div>
				<i class="far fa-refresh sub-head-ico" (click)="reloadTables()" title="Refresh Data"></i>
			</div>
			<div class="updated-wrap">{{ lastUpdated() }}</div>
		</div>
	</div>

	<div *ngIf="!prefsVisible">
		<app-section-switcher [config]="switcherConfig" [type]="'NOCOUNT'" [maxWidth]="'166px'" (tabClicked)="sectionTabClicked($event)">
		</app-section-switcher>
	</div>

	<!-- <div>
		<div class="badge tag-btn" style="margin-right: 8px" [class.bg-green]="!viewManager.showAllReports" (click)="toggleShowReports()">
			My Reports
		</div>
		<div class="badge tag-btn" [class.bg-green]="viewManager.showAllReports" (click)="toggleShowReports()">All Reports</div>
	</div> -->

	<app-table-filter-buttons [manager]="tableFilterManager" (buttonClicked)="changeSubsection($event)"></app-table-filter-buttons>

	<!-- <div class="alert alert-info" style="margin: 10px 0px 25pxpx 0px">
		Use the <strong>Define</strong> section to create and manage your reports. Use the <strong>View</strong> section to view completed reports.
	</div> -->

	<div *ngIf="showTooltips" class="card card-body tooltip-block tooltip-info-text tooltip-section-header">
		<button type="button" class="close" aria-label="Close" (click)="toggleTooltips()">
			<span aria-hidden="true">&times;</span>
		</button>

		<app-no-data-tip *ngIf="showNoDataTip()"></app-no-data-tip>

		Reports can be defined and edited here. We offer many different reports covering a wide range of useful information.<br /><br />
		<ul>
			<li><strong class="tooltip-step">Notes Entries</strong></li>
			<li><strong class="tooltip-step">Suspect Transactions</strong></li>
			<li><strong class="tooltip-step">Time Entries</strong></li>
			<li><strong class="tooltip-step">Employee Summary</strong></li>
			<li><strong class="tooltip-step">Employee (No Show and inclomplete)</strong></li>
			<li><strong class="tooltip-step">Employee DOW Scheduled vs Actual</strong></li>
			<li><strong class="tooltip-step">Employee DOW Scheduled vs Actual (OT Only)</strong></li>
			<li><strong class="tooltip-step">Job Summary</strong></li>
			<li><strong class="tooltip-step">Job DOW Scheduled vs Actual</strong></li>
			<li><strong class="tooltip-step">Job Costing and Profitability</strong></li>
			<li><strong class="tooltip-step">Emp. & Job Summary</strong></li>
			<li><strong class="tooltip-step">Job Site Summary</strong></li>
			<li><strong class="tooltip-step">Payroll Report</strong></li>
			<li><strong class="tooltip-step">Payroll (Holiday + OT)</strong></li>
			<li><strong class="tooltip-step">Custom Payroll Export</strong></li>
			<li><strong class="tooltip-step">Incident Report</strong></li>
			<li><strong class="tooltip-step">QuickBooks Desktop Export</strong></li>
			<li><strong class="tooltip-step">Transaction Dump</strong></li>
			<li><strong class="tooltip-step">Missing Transactions</strong></li>
			<li><strong class="tooltip-step">File Expiration</strong></li>
			<li><strong class="tooltip-step">Employee Performance</strong></li>
			<li><strong class="tooltip-step">New Employee Summary</strong></li>
			<li><strong class="tooltip-step">Emp. & Job Summary (Midnight)</strong></li>
			<li><strong class="tooltip-step">Payroll (Holiday Midnight + OT)</strong></li>
		</ul>

		By default, the Employee &amp; Job Summary report is created for you. If you wish to receive Payroll reports, you will need to add one that
		matches your payroll period. These reports can be run over any custom date range, or according to a recurring frequency. The frequency can be
		weekly, every two weeks, monthly, or twice per month (1st - 15th, and 16th - end of month).<br /><br />
		Each time a report is run, it creates a record in the Report History section. You can view past reports and re-run past reports from the history
		section. This is useful if you make changes in the time entry log and wish to re-run a payroll report, for example.<br /><br />
		It is a best practice to keep an eye on time entries regularly and fix up any forgotten check outs, or other issues. Then, when it is time to
		run payroll reports, your data will be accurate.<br />
	</div>

	<div>
		<app-insight-report-table #table *ngIf="viewManager.currentView === 'DEFINE'"></app-insight-report-table>
		<!-- <hr /> -->
		<app-insight-report-history-table #historyTable *ngIf="viewManager.currentView === 'VIEW'"></app-insight-report-history-table>
	</div>
</div>

<app-modal-dialog *ngIf="sectionPrefsDialogManager.isDialogVisible" [dialogManager]="sectionPrefsDialogManager">
	<app-local-prefs [dialogManager]="sectionPrefsDialogManager" (prefsUpdated)="prefsDataSaved()"></app-local-prefs>
</app-modal-dialog>

<app-modal-dialog *ngIf="reportViewDialogManager.isDialogVisible" [dialogManager]="reportViewDialogManager">
	<app-insight-report-edit [action]="'edit'" [reportId]="reportViewDialogManager.dialogData" [viewOnly]="true"></app-insight-report-edit>
</app-modal-dialog>

<app-modal-dialog *ngIf="filterViewDialogManager.isDialogVisible" [dialogManager]="filterViewDialogManager">
	<app-insight-report-filters-list [dialogManager]="filterViewDialogManager"></app-insight-report-filters-list>
</app-modal-dialog>
