<div class="container section-container" style="width: 100%">
	<div class="main-view noprint">
		<div class="sec-head-wrap">
			<div class="sec-act-ico-wrap">
				<!-- <i
					*ngIf="!isGlobalAccount"
					class="fas fa-plus sec-act-ico"
					[class.sec-act-ico-disabled]="!canPerformAction(CrudAction.create, null)"
					(click)="createRecord('SINGLE')"
					[title]="createRecordLabel"
				></i> -->
				<i
					*ngIf="!isGlobalAccount"
					class="fas fa-plus sec-act-ico"
					[class.sec-act-ico-disabled]="!canPerformAction(CrudAction.create, null)"
					(click)="createRecord('SINGLE')"
					[title]="createRecordLabel"
				></i>
				<i
					*ngIf="!isGlobalAccount && manager.currentView === 'SERIES' && showMultScheduleOption"
					class="far fa-users sec-act-ico"
					style="font-size: 0.9em"
					[class.sec-act-ico-disabled]="!canPerformAction(CrudAction.create, null)"
					(click)="createRecord('MULTI')"
					[title]="'Add Multi-Schedule'"
				></i>
				<i class="far fa-cog sec-act-ico" (click)="togglePreferences()" title="Manage Columns"></i>
				<i class="far fa-question-circle sec-act-ico" (click)="toggleTooltips()" title="Show Help"></i>
			</div>

			<div class="sec-title-wrap" [class.sec-head-title-highlight]="currentDayViewDate">
				<div *ngIf="!currentDayViewDate">
					<i class="far fa-calendar sec-head-ico" aria-hidden="true"></i>
					<span *ngIf="manager.currentView === 'SERIES'">Schedules</span>
					<span *ngIf="manager.currentView === 'SHIFT'">Shifts</span>
					<span *ngIf="manager.currentView === 'TIMEOFF'">Time Off</span>
					<!-- <span>Schedules</span>
					<app-sub-section-title [isVisible]="true" [subSectionTitle]="subSectionTitle"></app-sub-section-title> -->
				</div>
				<div
					*ngIf="currentDayViewDate"
					[owlDateTimeTrigger]="dtDayViewDate"
					(click)="hideDtTimePicker()"
					style="cursor: pointer; display: inline"
				>
					{{ currentDayViewDate | dateFormatter: 'ddd, MMM Do' }}
					<div class="sec-title-ico-wrap">
						<i class="fal fa-calendar sec-title-ico"></i>
					</div>
				</div>
			</div>

			<!-- Day View Date Picker -->
			<owl-date-time
				#dtDayViewDate
				[startAt]="currentDayViewDate"
				[hour12Timer]="true"
				[pickerType]="'both'"
				[pickerMode]="'dialog'"
				(afterPickerClosed)="showDtTimePicker(); dayViewPickerClosed()"
			></owl-date-time>
			<input
				[hidden]="true"
				class="form-control datetime-input"
				id="dayViewDate"
				placeholder=""
				[(ngModel)]="dayViewDate"
				[owlDateTime]="dtDayViewDate"
			/>

			<!-- Sub head icons -->
			<div class="sub-head-wrap">
				<div class="sub-head-ico-wrap">
					<div (click)="toggleDayView()" class="ico-inline-block" title="Toggle Day View">
						<i *ngIf="!isProcessingDayViewSchedules && !currentDayViewDate" class="far fa-calendar sub-head-ico"></i>
						<i
							*ngIf="!isProcessingDayViewSchedules && currentDayViewDate"
							class="far fa-calendar-check sub-head-ico sub-head-ico-highlight"
						></i>
						<i
							*ngIf="isProcessingDayViewSchedules"
							class="far fa-loader fa-spin sub-head-ico sub-head-ico-highlight"
							title="Day View Loading"
						></i>
					</div>

					<div *ngIf="currentDayViewDate || manager.currentView === 'SHIFT'" class="ico-inline-block">
						<i class="far fa-arrow-left sub-head-ico" (click)="previousDay()" title="Previous"></i>
						<i class="far fa-arrow-right sub-head-ico" (click)="nextDay()" title="Next"></i>
					</div>

					<i
						*ngIf="manager.currentView === 'SERIES' && showBatchEditIcon"
						class="fal fa-cubes sub-head-ico"
						[class.sub-head-ico-highlight]="manager.series.isBatchingEnabled"
						(click)="toggleBatchMode()"
						title="Batch Mode"
					></i>

					<!-- Refresh Icon -->
					<i class="far fa-refresh sub-head-ico" (click)="refreshBtnClicked()" title="Refresh Data"></i>
				</div>
				<div class="updated-wrap float-right">{{ lastUpdated() }}</div>
			</div>
		</div>

		<!-- <div class="noprint">
			<app-section-switcher [config]="switcherConfig" [type]="'NOCOUNT'" [maxWidth]="'320px'" (tabClicked)="sectionTabClicked($event)">
			</app-section-switcher>
		</div> -->

		<div [hidden]="manager.currentView === 'TIMEOFF'" class="search-box noprint">
			<input
				type="text"
				#searchInput
				class="form-control search-field"
				[(ngModel)]="manager.currentSearchText"
				(keyup)="debounceSearch($event)"
				[placeholder]="searchPlaceholder"
			/>
			<div class="search-actions">
				<div style="position: relative; top: 2px"></div>
				<i *ngIf="!manager.currentSearchText" class="fa fa-search search-action-magnify-ico"></i>
				<i
					title="Clear Filter"
					*ngIf="manager.currentSearchText"
					class="fa fa-times-circle-o search-action-magnify-ico search-clear"
					style="color: chocolate"
					(click)="debounceSearch(null)"
				></i>
				<div [hidden]="manager.currentView === 'SHIFT'" class="filter-type-wrap">
					<a data-bs-toggle="dropdown" href="#" id="searchDropdown" aria-haspopup="true" aria-expanded="false">
						<i class="fa fa-filter filter-type search-action-filter-ico" [class.filter-active]="statusFilter?.schedFilterActive"></i>
					</a>
					<div class="dropdown-menu dropdown-menu-end search-dropdown" aria-labelledby="searchDropdown">
						<div class="dropdown-item search-menu-item" (click)="showScheduleStatusFor('ALL')">Show All Schedules</div>
						<div
							class="dropdown-item search-menu-item"
							[class.active]="statusFilter.active && statusFilter.showInProgress"
							(click)="showScheduleStatusFor('INPROGRESS')"
						>
							Show In Progress Schedules
						</div>
						<div class="dropdown-divider"></div>
						<div
							class="dropdown-item search-menu-item"
							[class.active]="statusFilter.active && statusFilter.showInactiveJob"
							(click)="showScheduleStatusFor('INACTIVEJOB')"
						>
							Show Inactive Jobs
						</div>
						<div
							class="dropdown-item search-menu-item"
							[class.active]="statusFilter.active && statusFilter.showEmpCount"
							(click)="showScheduleStatusFor('EMPCOUNT')"
						>
							Filter By Employee Count
						</div>
						<div *ngIf="manager.currentView === 'SERIES' && batchCount > 0">
							<div class="dropdown-divider"></div>
							<div
								class="dropdown-item search-menu-item"
								[class.active]="statusFilter.active && statusFilter.showBatchSelection"
								(click)="showScheduleStatusFor('BATCHED')"
							>
								Preview Batch Selection
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="showTooltips" class="card card-body tooltip-block tooltip-info-text tooltip-section-header" style="margin-top: 45px">
			<button type="button" class="close" aria-label="Close" (click)="showTooltips = false">
				<span aria-hidden="true">&times;</span>
			</button>

			<div class="section-help-header" *ngIf="manager.currentView === 'SERIES'">Schedule View</div>
			<div class="section-help-header" *ngIf="manager.currentView === 'SHIFT'">Shift View</div>
			<div class="section-help-header" *ngIf="manager.currentView === 'TIMEOFF'">Time Off View</div>

			<div *ngIf="false && showNoDataTip()">
				<div style="max-width: 350px">
					<div class="week-start-label">Weekly schedule starts on</div>
					<select class="form-control week-start-select" [ngModel]="wkst" (change)="setWeekStart($event)">
						<option [value]="null">Monday</option>
						<option [value]="1">Tuesday</option>
						<option [value]="2">Wednesday</option>
						<option [value]="3">Thursday</option>
						<option [value]="4">Friday</option>
						<option [value]="5">Saturday</option>
						<option [value]="6">Sunday</option>
					</select>
				</div>
				<div class="week-start-note">
					You most likely won't need to change the day your weekly schedule starts but if your schedule starts on another day, you can change
					this setting now or update it later in the Account Defaults section of the Admin menu.
				</div>
			</div>

			<app-no-data-tip *ngIf="showNoDataTip()"></app-no-data-tip>

			<div *ngIf="manager.currentView === 'SERIES'">
				The <strong>Schedule View</strong> is where you setup your repeating schedules. Schedules are recurring shift templates which usually
				repeat weekly but can be configured to match your needs. If you need to add an individual shift, you can do that in the
				<strong>Shift View</strong>.

				<div *ngIf="arePendingSchedulesEnabled">
					<div style="margin-top: 20px">
						<strong class="tts-hl-2">Current</strong> This tab shows your current schedules. These schedules will create shift entries which may
						be managed in the <strong>Shfit View</strong>.
					</div>
					<div style="margin-top: 10px">
						<strong class="tts-hl-2">Pending</strong> This tab shows your pending schedules. These schedules will not create shift entries until
						the schedule has been approved. You may manage the approval workflow through the <strong>Actions</strong> button which is available
						on pending schedules.
					</div>
				</div>

				<div style="margin-top: 20px">
					<strong class="tooltip-step">Note:</strong> Creating schedules is optional. It is only necessary if you would like the system to notify
					employees and/or supervisors for events relating to checking in and out. This includes late check in, late check out, early check out,
					and missed checkpoints. These notification settings are managed in <strong>Notification Profiles</strong> and are assigned to
					individual jobs in the <strong>Jobs</strong> section.
				</div>
			</div>

			<div *ngIf="manager.currentView === 'SHIFT'">
				In the <strong>Shift View</strong> you can view the schedule two main ways, either by employees or by job. To view by employees, look for
				the dropdown menu above the schedule and select the View by employees icon <i class="fas fa-user" title="View schedule by employees"></i>.
				Next, select the employees you wish to view from the dropdown. Or select the View by job icon <i class="far fa-tasks-alt"></i> to look at
				schedules for a specific job and select the job from the dropdown.

				<div style="margin-top: 20px; font-size: 1.1em; line-height: 1.3em">
					<div><i class="fas fa-user" style="width: 25px; text-align: center"></i> View schedule by employees</div>
					<div><i class="far fa-tasks-alt" style="width: 25px; text-align: center"></i> View schedule by job</div>
				</div>

				<div style="margin-top: 16px" *ngIf="!devDetect.isMobile()">
					<span class="help-highlight">Change Timezone -</span>
					Click the timezone abbreviation button
					<div class="tz-btn table-tag">{{ tzAbrev }}</div>
					in the menu bar below to manually set a specific timezone. This is useful when you are viewing multiple shifts which cross timezones.
					Choose the <strong>Automatic</strong> option to have the timezone set automatically based on the results being displayed. When you have
					manually selected a timezone the button will be green to indicate it you are overriding this option.
				</div>

				<div style="margin-top: 20px">
					<div class="legend-item">
						<span class="help-highlight">Enabled / Disabled -</span> An enabled shift will have a solid background. Diagonal lines indicate a
						shift which is disabled. When a shift is disabled, No Show time entries are not created and no notifications will be sent to either
						employees or supervisors.
					</div>
					<div class="legend-item">
						<span class="help-highlight">**Employee Name** -</span> Asterisks are used to indicate an employee is currently inactive. In order
						to assign shifts to an employee they must be active. This status can be changed in their employee record.
					</div>
					<div class="legend-item">
						<span class="help-highlight">[Employee Name] -</span> When a shift has been manually re-assigned to another employee, the currently
						assigned employee's name will be displayed in brackets.
					</div>
					<!-- <div class="legend-item">
						<span class="help-highlight">(o) -</span> An (o) in the shift label indicates this shift is being offered as available to other
						employees.
					</div> -->
					<div class="legend-item">
						<span class="help-highlight">(x) -</span> An (x) in the shift label indicates notifications for this shift are disabled. No Show
						entries will still be created but notifications for this shift will not be sent out.
					</div>
					<div *ngIf="isMultiDayJobEnabled" class="legend-item">
						<span class="help-highlight">(m) -</span> An (m) in the shift label indicates this shift is part of a multi-day scheduled job.
					</div>

					<!-- <div class="legend-item">
						<span class="help-highlight">* -</span> An asterisk indicates the enabled / disabled status of the shift differs from that of the
						series.
					</div> -->
				</div>

				<div style="margin-top: 20px">
					More options for configuring the schedule view are available by selecting the preferences icon <i class="far fa-cog"></i>
				</div>
			</div>

			<div *ngIf="manager.currentView === 'TIMEOFF'">
				The <strong>Time Off</strong> view allows you to schedule times which your employees are not able to work. When time off has been setup
				for an employee, the <strong>Shift View</strong> will highlight conflicts between existing schedules and time off so you can re-assign
				shifts to other employees if needed.
			</div>
		</div>
	</div>

	<div [hidden]="manager.currentView !== 'SERIES' || manager.isSwitchingViews">
		<app-scheduler-list #seriesViewComponent [switcherConfig]="switcherConfig" (listUpdated)="updateUI()"></app-scheduler-list>
		<app-table-footer-buttons
			[marginTop]="'15px'"
			[newBtnLabel]="'Add Schedule'"
			(newBtnClicked)="createRecord('SINGLE')"
			[multiSchedBtnLabel]="showMultScheduleOption ? 'Add Multi-Schedule' : null"
			(multiSchedBtnClicked)="createRecord('MULTI')"
		></app-table-footer-buttons>
	</div>

	<div *ngIf="manager.currentView === 'SHIFT'">
		<app-scheduler-shift-view #shiftViewComponent [searchText]="manager.shift.searchText"></app-scheduler-shift-view>
	</div>

	<div *ngIf="manager.currentView === 'TIMEOFF'">
		<app-time-off-table #timeOffTable></app-time-off-table>
	</div>
</div>

<div *ngIf="currentDayViewDate" class="nav-clear-block">
	<div class="nav-clear-btn" (click)="toggleDayView()">exit day view</div>
</div>

<app-modal-dialog *ngIf="editDialogManager.isDialogVisible" [dialogManager]="editDialogManager">
	<app-scheduler-edit
		[dialogManager]="editDialogManager"
		[scheduleEntry]="editAction.selectedEntry.entry"
		[newSchedType]="editAction.selectedEntry.newSchedType"
		[isCopy]="editAction.isCopy"
		[defaultJobId]="editAction.defaultJobId"
		[source]="editAction.source"
		(actionComplete)="scheduleActionComplete()"
	></app-scheduler-edit>
</app-modal-dialog>
