import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { ClickToCallSourceConfig, ClickToCallDialogManager, ClickToCallListBuilder, ClickToCallRecord, ClickToCallViewModel } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { log } from '@app/helpers'
import { Dialog } from 'primeng/dialog'
import { Dropdown } from 'primeng/dropdown'
import _ from 'lodash'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-click-to-call-directory',
    templateUrl: './click-to-call-directory.component.html',
    styleUrls: ['./click-to-call-directory.component.scss'],
    standalone: false
})
export class ClickToCallDirectoryComponent implements OnInit, OnDestroy {
	manager: ClickToCallDialogManager
	vm = new ClickToCallViewModel()

	clickToCallEnabled = { backend: false, browser: false } // Keep this. Need availability check here for buuttons

	@Output() startTextMsg = new EventEmitter<ClickToCallRecord>()
	@Output() placeCall = new EventEmitter<ClickToCallRecord>()

	@ViewChild('clickToCallDialog', { static: true }) dialog: Dialog
	@ViewChild('listDropdown', { static: false }) listDropdown: Dropdown

	constructor(
		private cd: ChangeDetectorRef,
		private coreSrvc: CoreService,
	) {
		this.manager = this.coreSrvc.callSrvc.c2cManager
	}

	get canAccessCallBtn(): boolean {
		return this.clickToCallEnabled.backend || this.clickToCallEnabled.browser
	}

	ngOnInit(): void {}
	ngOnDestroy(): void {
		// this.subs.unsubscribe()
	}

	public resetVm() {
		this.vm = new ClickToCallViewModel()
	}

	public dialogOnShow(): void {
		setTimeout(() => {
			this.listDropdown?.focus()
		})
	}

	public setupComponent() {
		const config = this.manager.config
		this.setupAvailability()
		switch (config.type) {
			case 'TRANS':
				this.setupComponentForTransaction(config)
				break
		}
	}

	private setupAvailability() {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		const portalPrefs = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany()
		const backEndEnabled = this.coreSrvc.dbSrvc.settingSrvc.isClickToCallEnabled()

		// Setup Click to Call availability
		this.clickToCallEnabled.backend = backEndEnabled // ValidationHelper.validateEmailAddress(company.click_to_call)
		this.clickToCallEnabled.browser = portalPrefs.globalBrowserClickToCall
	}

	private setupComponentForTransaction(config: ClickToCallSourceConfig) {
		log('Setting up C2C for TRANS', config)
		log('Current dbSrvc', this.coreSrvc.dbSrvc)
		const id = config.sourceId
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(id)
		const records: Array<ClickToCallRecord> = []
		const listBuilder = new ClickToCallListBuilder(this.coreSrvc.dbSrvc)
		if (trans) {
			// Employee List
			const empIds: Array<number> = []
			empIds.push(trans.employee_id)
			const empRecords = listBuilder.getRecordsForEmployees(empIds, 'EMPLOYEEPHONE')
			records.push(...empRecords)

			// Employee Supervisor List
			const empSupRecords = listBuilder.getRecordsForEmployeeSupervisor(trans.employee_id, 'EMPLOYEESUPERVISOR')
			records.push(...empSupRecords)

			// Employee Details List
			const empDetailRecords = listBuilder.getRecordsForEmployeeDetails(trans.employee_id, 'EMPLOYEEDETAILS')
			records.push(...empDetailRecords)

			// Job Site Supervisor List
			const siteSupRecords = listBuilder.getRecordsForSiteSupervisor(trans.jobsite_id, 'SITESUPERVISOR')
			records.push(...siteSupRecords)

			// Job Site Details List
			const siteDetailRecords = listBuilder.getRecordsForSiteDetails(trans.jobsite_id, 'SITEDETAILS')
			records.push(...siteDetailRecords)

			// Job Details List
			const jobDetailRecords = listBuilder.getRecordsForJobDetails(trans.job_id, 'JOBDETAILS')
			records.push(...jobDetailRecords)

			// Scheduled Employees List
			const scheduledEmployeeRecords = listBuilder.getRecordsForScheduledEmployees(trans.schedule_recur_id, 'SCHEDULEDEMPLOYEE')
			records.push(...scheduledEmployeeRecords)

			// Schedule Details List
			const scheduleDetailRecords = listBuilder.getRecordsForScheduleDetails(trans.schedule_recur_id, 'SCHEDULEDETAILS')
			records.push(...scheduleDetailRecords)

			// Schedule Notes List
			const scheduleNoteRecords = listBuilder.getRecordsForScheduleNotes(trans.schedule_recur_id, 'SCHEDULENOTE')
			records.push(...scheduleNoteRecords)

			// Time Entry Notes List
			const transNoteRecords = listBuilder.getRecordsForTransactionNotes(trans.id, 'TRANSACTIONNOTE')
			records.push(...transNoteRecords)

			const transClientRecords = listBuilder.getRecordsForTransactionClients(trans.id, 'CLIENT')
			records.push(...transClientRecords)

			const transVendorRecords = listBuilder.getRecordsForTransactionVendors(trans.id, 'VENDOR')
			records.push(...transVendorRecords)

			log('Transaction', trans)
			this.vm.selectedOptionType = 'EMPLOYEEPHONE'
		}
		this.vm.addRecords(records)
		this.vm.options = this.vm.optionsForRecords(records)
	}
}

// View Model
