<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	[(visible)]="isVisible"
	[modal]="true"
	[showHeader]="false"
	[dismissableMask]="false"
	[focusOnShow]="true"
>
	<div style="width: 300px">
		<div class="message">{{ message }}</div>
		<div class="d-flex justify-content-between" style="margin-top: 25px">
			<button class="btn btn-sm btn-secondary" (click)="isVisibleChange.next(false)">Cancel</button>
			<button class="btn btn-sm btn-success" (click)="actionConfirmed.next(true); isVisibleChange.next(false)">Confirm</button>
		</div>
	</div>
</p-dialog>
