<div style="max-width: 100%; margin: 0 auto">
	<div *ngIf="!isRecordValid" class="edit-record-not-found">
		<div>Record</div>
		<div>Not Found</div>
	</div>

	<app-activity-banner [isProcessing]="isUpdating" [message]="'Updating Record ...'"></app-activity-banner>

	<div [hidden]="isUpdating">
		<!-- <button (click)="duplicateNumber.showDialog = true">OPEN</button>
		<div>{{ duplicateNumber | json }}</div> -->

		<div *ngIf="!isNew && empAppLoginUrl && isInternalUser" style="margin-bottom: 20px">
			<div class="float-right">
				<button class="btn btn-sm btn-success" style="width: 50px" (click)="copyCredential()">
					<i class="fa fa-clipboard"></i>
				</button>
			</div>
			<div class="options-section-header">
				<span>Employee App Link</span>
				<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('empAppLink')"></i>
			</div>
		</div>

		<form *ngIf="showForm" class="edit-form-block" novalidate [formGroup]="empForm" autocomplete="off">
			<div class="form-group" [class.input-required]="!empForm.get('first').value">
				<div class="input-label">
					<strong>First Name</strong>
					<span *ngIf="!empForm.get('first').value" class="float-right">(required)</span>
				</div>
				<div>
					<input id="empNameFirst" type="text" class="form-control" formControlName="first" autocomplete="off" (input)="onNameChange()" />
				</div>
			</div>

			<div class="form-group" [class.input-required]="!empForm.get('last').value">
				<div class="input-label form-spacer">
					<strong>Last Name</strong>
					<span *ngIf="!empForm.get('last').value" class="float-right">(required)</span>
				</div>
				<div>
					<input id="empNameLast" type="text" class="form-control" formControlName="last" autocomplete="off" (input)="onNameChange()" />
				</div>
			</div>

			<div class="form-group" [class.input-required]="!empForm.get('name').value">
				<div class="input-label form-spacer">
					<strong>Display Name</strong>
					<span *ngIf="!empForm.get('name').value" class="float-right">(required)</span>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('displayName')"></i>
				</div>
				<div>
					<input id="empNameDisplay" type="text" class="form-control" formControlName="name" autocomplete="off" />
				</div>
			</div>

			<div>
				<div *ngIf="showSupervisors" [class.input-required]="!empForm.get('supervisor').value && empForm.get('supervisor').value !== 0">
					<div class="form-group">
						<div class="input-label form-spacer">
							<strong>Employee Supervisor</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('empSupervisor')"></i>
							<span *ngIf="!empForm.get('supervisor').value && empForm.get('supervisor').value !== 0" class="float-right">(required)</span>
						</div>
						<p-select
							appScrollLockSelect
							id="supervisors"
							placeholder="Select Supervisor"
							[options]="supervisorOptions"
							formControlName="supervisor"
							[virtualScroll]="true"
							[virtualScrollItemSize]="30"
							[filter]="true"
							filterBy="label"
						></p-select>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div class="input-label">
					<strong [class.input-required]="!empForm.get('phone_number_e164').value">Phone Number</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('phoneNumber')"></i>
					<span *ngIf="!empForm.get('phone_number_e164').value" class="input-required float-right">(required)</span>
					<span *ngIf="empForm.get('phone_number_e164').value && !phoneNumberValid" class="input-required float-right">not valid</span>
					<span *ngIf="empForm.get('phone_number_e164').value && phoneNumberValid" class="float-right" style="color: green">valid</span>
				</div>

				<div class="form-group" [class.input-required]="!empForm.get('phone_number_e164').value">
					<table width="100%">
						<tr>
							<td style="width: 80px">
								<select class="form-control phone-country-select" formControlName="dialingCode" (change)="formatPhoneNumber()">
									<option *ngFor="let code of countryCodeData" value="{{ code.id }}">{{ code.id }}</option>
								</select>
							</td>
							<td>&nbsp;&nbsp;</td>
							<td style="max-width: 275px">
								<input
									#phoneInput
									(keyup)="formatPhoneNumber()"
									class="form-control"
									[class.is-valid]="phoneNumberValid"
									formControlName="phone_number_e164"
									type="tel"
									autocomplete="off"
								/>
							</td>
						</tr>
					</table>
				</div>
			</div>

			<!-- <div *ngIf="!phoneNumberValid" style="margin-top: 28px">
				<div *ngIf="!phoneNumberValid && empForm.get('phone_number_e164').value" class="alert alert-warning">
					Phone number not valid for
					<strong>{{ empForm.get('dialingCode').value }}</strong> region.
				</div>
			</div> -->

			<!-- Email Address -->
			<div class="form-group">
				<div class="input-label form-spacer">
					<strong>Email Address</strong>
					<!-- <span *ngIf="!empForm.get('email').value" class="input-required float-right">(required)</span> -->
				</div>
				<div>
					<input id="emailAddress" type="text" class="form-control" formControlName="email" autocomplete="off" />
				</div>
			</div>

			<!-- Language -->
			<div class="form-group">
				<div class="input-label form-spacer">
					<strong>Language</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('language')"></i>
				</div>
				<select class="form-select" formControlName="language">
					<option *ngFor="let lang of languageList" [value]="lang.value">{{ lang.label }}</option>
				</select>
			</div>

			<app-form-tags
				#tagComp
				[tagsJson]="empForm.get('tags_json').value"
				[tagOptionsList]="tagOptionsList"
				(tagsJsonChange)="empForm.get('tags_json').setValue($event)"
			></app-form-tags>

			<div style="margin-top: 25px">
				<app-form-address [manager]="formAddressManager" [showNotesOption]="false"></app-form-address>
			</div>

			<!-- Advanced Block -->
			<hr style="margin-top: 28px" />

			<app-form-expander
				[label]="'Advanced Options'"
				[centerOnExpansion]="true"
				[isExpanded]="showAdvancedOptions"
				(isExpandedChange)="showAdvancedOptions = $event; updateDynamicTextareas()"
			>
				<div class="form-group">
					<div class="input-label form-spacer">
						<strong>Employment Status</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('status')"></i>
					</div>
					<p-select appScrollLockSelect id="status" placeholder="Ongoing" [options]="statusOptions" formControlName="status"></p-select>
				</div>

				<div class="input-label form-spacer">
					<strong>Employment Status Notes</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('employment_status_notes')"></i>
				</div>
				<div>
					<textarea
						#empStatusNotes
						rows="1"
						id="empStatusNotes"
						type="text"
						class="form-control options-textarea-dynamic"
						formControlName="employment_status_notes"
						autocomplete="off"
						(input)="textResizer(empStatusNotes)"
					></textarea>
				</div>

				<div *ngIf="showUserGroups" class="form-group">
					<div class="input-label form-spacer">
						<strong>Supervisor Group</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('userGroup')"></i>
						<!-- <span *ngIf="!empForm.get('supervisor_group_id').value" class="input-required float-right">(required)</span> -->
					</div>
					<p-select
						appScrollLockSelect
						id="userGroupsDropdown"
						[options]="userGroupOptions"
						formControlName="supervisor_group_id"
						[filter]="true"
						filterBy="label"
					></p-select>
				</div>

				<div class="input-label form-spacer">
					<strong>External ID</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('externalID')"></i>
				</div>
				<div>
					<input id="externalId" type="text" class="form-control" formControlName="external_id" autocomplete="off" />
				</div>

				<div class="options-block" style="margin: 15px 0px">
					<div class="options-title-inline">Station PIN</div>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('emp_code')"></i>
					<input type="text" class="form-control" autocomplete="off" formControlName="emp_code" (input)="pinCodeValidator()" />
				</div>

				<div class="input-label form-spacer">
					<strong>Employee Details</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('details')"></i>
				</div>
				<div>
					<textarea
						#empDetailsTextarea
						rows="1"
						id="empDetails"
						type="text"
						class="form-control options-textarea-dynamic"
						formControlName="employee_details"
						autocomplete="off"
						(input)="textResizer(empDetailsTextarea)"
					></textarea>
				</div>

				<!-- Department -->
				<div style="width: 100%">
					<div class="input-label form-spacer">
						<div *ngIf="empForm.get('department').value" class="link-text float-right" (click)="empForm.get('department').setValue(null)">
							clear
						</div>
						<strong>Department</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('department')"></i>
						<!-- <a href="#" class="float-right" (click)="ncciCode=null;false">clear</a> -->
					</div>
					<p-autoComplete
						appScrollLockAutoComplete
						placeholder="Employee Department"
						[inputStyleClass]="'p-autocomplete-mod'"
						formControlName="department"
						[suggestions]="departmentOptions"
						[delay]="150"
						[completeOnFocus]="true"
						(completeMethod)="searchDepartment($event)"
					>
					</p-autoComplete>
				</div>

				<!-- Start Date -->
				<div class="options-block form-spacer">
					<div>
						<!-- <div *ngIf="!empForm.get('start_date').value" class="optional">(optional)</div> -->
						<div *ngIf="empForm.value.start_date" class="clear-link float-right" (click)="empForm.get('start_date').setValue(null)">clear</div>
						<div class="options-title">Start Date</div>
					</div>
					<app-date-time-picker
						[currentDate]="empForm.get('start_date').value"
						[pickerType]="'DATE'"
						[pickerMode]="'dialog'"
						[pickerLabel]="'Start Date'"
						[hour12Timer]="true"
						[format]="'MMM Do, YYYY'"
						(pickerDidClose)="empForm.get('start_date').setValue($event)"
					>
					</app-date-time-picker>
				</div>

				<!-- Birthday -->
				<div class="options-block">
					<div>
						<!-- <div *ngIf="!editForm.get('startDate').value" class="required">(required)</div> -->
						<div *ngIf="empForm.value.birthday" class="clear-link float-right" (click)="empForm.get('birthday').setValue(null)">clear</div>
						<div class="options-title">Birthday</div>
					</div>
					<app-date-time-picker
						[currentDate]="empForm.get('birthday').value"
						[pickerType]="'DATE'"
						[pickerMode]="'dialog'"
						[pickerLabel]="'Birthday'"
						[hour12Timer]="true"
						[format]="'MMM Do, YYYY'"
						(pickerDidClose)="empForm.get('birthday').setValue($event)"
					>
					</app-date-time-picker>
				</div>

				<!-- Anniversary -->
				<div class="options-block">
					<div>
						<div *ngIf="empForm.value.anniversary" class="clear-link float-right" (click)="empForm.get('anniversary').setValue(null)">
							clear
						</div>
						<div class="options-title">Anniversary</div>
					</div>
					<app-date-time-picker
						[currentDate]="empForm.get('anniversary').value"
						[pickerType]="'DATE'"
						[pickerMode]="'dialog'"
						[pickerLabel]="'Anniversary'"
						[hour12Timer]="true"
						[format]="'MMM Do, YYYY'"
						(pickerDidClose)="empForm.get('anniversary').setValue($event)"
					>
					</app-date-time-picker>
				</div>

				<div style="margin: 30px 0px 20px 0px">
					<div class="options-section-header">Travel Options</div>

					<div style="margin-top: 16px">
						<div
							*ngIf="empForm.get('commute_distance').value"
							class="link-text float-right"
							(click)="empForm.get('commute_distance').setValue(null)"
						>
							clear
						</div>
						<div class="options-title-inline">Maximum Commute Range</div>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('commute_distance')"></i>
					</div>

					<div class="input-group mb-3">
						<span class="input-group-text tts-height" id="dist-addon">Distance</span>
						<input
							type="number"
							inputmode="decimal"
							class="form-control"
							style="text-align: right"
							placeholder="enter distance in"
							formControlName="commute_distance"
						/>
						<select class="form-select tts-height" formControlName="commute_distance_units" style="max-width: 80px">
							<option value="KM">km</option>
							<option value="MI">mi</option>
						</select>
					</div>
				</div>

				<!-- Schedule Options -->
				<div *ngIf="isDevEnvironment">
					<div style="margin: 30px 0px 20px 0px">
						<div class="options-section-header">Schedule Options</div>
						<div *ngIf="isW2WIntegrated" class="sub-note">Schedule options are managed by an integrated service and may not be modified.</div>
					</div>

					<div class="options-block">
						<div class="float-right">
							<input type="number" class="form-control side-input" formControlName="max_hrs_daily" (blur)="formatInteger('max_hrs_daily')" />
						</div>
						<div>
							<strong>Max Hours Daily</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('max_hrs_daily')"></i>
						</div>
					</div>
					<div class="options-block">
						<div class="float-right">
							<input
								type="number"
								class="form-control side-input"
								formControlName="max_shifts_daily"
								(blur)="formatInteger('max_shifts_daily')"
							/>
						</div>
						<div>
							<strong>Max Shifts Daily</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('max_shifts_daily')"></i>
						</div>
					</div>
					<div class="options-block">
						<div class="float-right">
							<input type="number" class="form-control side-input" formControlName="max_hrs_weekly" (blur)="formatInteger('max_hrs_weekly')" />
						</div>
						<div>
							<strong>Max Hours Weekly</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('max_hrs_weekly')"></i>
						</div>
					</div>
					<div class="options-block">
						<div class="float-right">
							<input
								type="number"
								class="form-control side-input"
								formControlName="max_days_weekly"
								(blur)="formatInteger('max_days_weekly')"
							/>
						</div>
						<div>
							<strong>Max Days Weekly</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('max_days_weekly')"></i>
						</div>
					</div>
				</div>

				<!-- Payroll Options -->
				<div
					*ngIf="showPayRateOption || showTaxLocations || isQBDIntegrated || isQBOIntegrated || isADPIntegrated"
					class="options-section-header"
					style="margin: 30px 0px 20px 0px"
				>
					Payroll Options
				</div>

				<div *ngIf="!isADPIntegrated && showPayRateOption" class="options-block form-spacer">
					<div class="float-right">
						<span class="pay-rate-symbol tts-hl-2">{{ currencySymbol }}</span>
						<input type="number" class="form-control pay-rate-input" formControlName="pay_rate" (blur)="formatRateInput('pay_rate')" />
					</div>
					<div style="padding-top: 4px">
						<strong>Base Pay Rate</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('payRate')"></i>
					</div>
				</div>

				<div *ngIf="isADPIntegrated">
					<div class="float-right">
						<div *ngIf="empForm.get('pay_rate').value">
							<span class="pay-rate-symbol tts-hl-2">{{ currencySymbol }}</span>
							<span>{{ empForm.get('pay_rate').value }}</span>
						</div>
						<div *ngIf="!empForm.get('pay_rate').value" style="position: relative; top: 4px; font-weight: bold; color: chocolate">
							{{ notSet }}
						</div>
					</div>
					<div style="padding-top: 4px">
						<strong>ADP Base Pay Rate</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('adpPayRate')"></i>
					</div>
				</div>

				<!-- Tax Location -->
				<div *ngIf="showTaxLocations" style="width: 100%">
					<div class="input-label form-spacer">
						<div
							*ngIf="empForm.get('home_tax_location').value"
							class="link-text float-right"
							(click)="empForm.get('home_tax_location').setValue(null)"
						>
							clear
						</div>
						<strong>Home Tax Location</strong>
						<!-- <i title="More Information" class="far fa-info-circle tts-info-btn"  (click)="showHelp('workTaxLocation')"></i> -->
					</div>
					<p-autoComplete
						appScrollLockAutoComplete
						placeholder="Home Tax Location"
						[inputStyleClass]="'p-autocomplete-mod'"
						formControlName="home_tax_location"
						[suggestions]="taxLocationOptions"
						[delay]="150"
						[completeOnFocus]="true"
						(completeMethod)="searchTaxLocation($event)"
					>
					</p-autoComplete>
				</div>

				<!-- Payroll Options -->
				<!-- <div *ngIf="isQBDIntegrated || isQBOIntegrated || isADPIntegrated" class="qbd-items">
					<div>
						<p-checkbox  formControlName="union" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('union')" class="has-pointer">Union employee</strong>
					</div>
				</div> -->
				<!-- End QuickBooks Options -->

				<div style="margin: 25px 0px 20px 0px">
					<div class="options-section-header" style="display: inline-block">Employee Active/Inactive Status</div>
					<!-- <i title="More Information" class="far fa-info-circle tts-info-btn"  (click)="showHelp('active')"></i> -->
				</div>

				<div class="options-block options-block-radio-lg">
					<div class="form-check form-check-inline">
						<input
							type="radio"
							class="form-check-input"
							id="activeStatusTrue"
							[value]="true"
							formControlName="active"
							(change)="activeStatusChange()"
						/>
						<label class="form-check-label has-pointer" for="activeStatusTrue" style="font-weight: bold">Active</label>
						<!-- <i title="More Information" class="far fa-info-circle tts-info-btn"  (click)="showHelp('personal_mode')"></i> -->
					</div>
					<div class="form-check form-check-inline" style="margin-left: 20px">
						<input
							type="radio"
							class="form-check-input"
							id="activeStatusFalse"
							[value]="false"
							formControlName="active"
							(change)="activeStatusChange()"
						/>
						<label class="form-check-label has-pointer" for="activeStatusFalse" style="font-weight: bold">Inactive</label>
						<!-- <i title="More Information" class="far fa-info-circle tts-info-btn"  (click)="showHelp('kiosk_mode')"></i> -->
					</div>
				</div>

				<div class="alert" [class.alert-success]="empForm.get('active').value" [class.alert-danger]="!empForm.get('active').value">
					Active/Inactive status isn’t about billing; it’s to declutter the admin portal. Employees are marked inactive after 30 days without
					activity to keep things tidy. They automatically become active when they clock in. You can also manually mark them inactive (e.g. if
					terminated).<br /><br />
					Billing is straightforward: we check how many employees had time entries at the end of your monthly cycle. If they didn't clock in, you
					won't be charged for them.<br /><br />
					<strong>Note:</strong> When we bill you each month, we will send out an email that lists the names of every employee who had time
					entries.
				</div>

				<!-- SMS Override -->
				<div *ngIf="!!empForm.get('phone_sms_id').value || smsOverrideOptions.length > 0">
					<div class="options-section-header" style="margin-top: 20px">Messaging Options</div>
					<div class="form-group">
						<div class="input-label form-spacer">
							<strong>Send Texts From</strong>
							<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('phone_sms_id')"></i>
							<span *ngIf="!empForm.get('phone_sms_id').value" class="float-right input-optional">(optional)</span>
							<span
								*ngIf="empForm.get('phone_sms_id').value"
								class="float-right link-text"
								(click)="empForm.get('phone_sms_id').setValue(null)"
								>use default</span
							>
						</div>
						<p-select
							appScrollLockSelect
							id="phone_sms_id"
							[scrollHeight]="'340px'"
							[placeholder]="smsOverridePlaceholder"
							[options]="smsOverrideOptions"
							formControlName="phone_sms_id"
							[virtualScroll]="false"
							filterBy="label"
						></p-select>
					</div>
				</div>

				<div class="options-section-header" style="margin-top: 20px">Miscellaneous Options</div>
				<div class="row checkbox-block">
					<div *ngIf="showAutoCheckInOutOption" class="checkbox-item">
						<p-checkbox formControlName="auto_check_in_out" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('auto_check_in_out')" class="has-pointer">Enable auto clock in / out</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('auto_check_in_out')"></i>
					</div>

					<div *ngIf="hasRoundUpTimer" class="checkbox-item">
						<p-checkbox formControlName="round_up_timer_disable" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('round_up_timer_disable')" class="has-pointer">Disable clock-in auto adjust</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('round_up_timer_disable')"></i>
					</div>
					<div *ngIf="hasRoundDownTimer" class="checkbox-item">
						<p-checkbox formControlName="round_down_timer_disable" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('round_down_timer_disable')" class="has-pointer">Disable clock-out auto adjust</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('round_down_timer_disable')"></i>
					</div>

					<div *ngIf="showTravelTracking" class="checkbox-item">
						<p-checkbox formControlName="travel" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('travel')" class="has-pointer">Enable travel tracking</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('travel')"></i>
					</div>
					<div *ngIf="false" class="checkbox-item">
						<p-checkbox formControlName="floater" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('floater')" class="has-pointer">Enable job floating</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('jobFloating')"></i>
					</div>
					<div class="checkbox-item">
						<p-checkbox formControlName="disable_sms" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('disable_sms')" class="has-pointer">Enable GPS requests for IVR</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('gpsRequests')"></i>
					</div>
					<div class="checkbox-item">
						<p-checkbox formControlName="voice_fingerprint" [binary]="true"></p-checkbox>
						<strong (click)="toggleCheckbox('voice_fingerprint')" class="has-pointer">Enable voice fingerprints for IVR</strong>
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('voicePrint')"></i>
					</div>
				</div>
			</app-form-expander>

			<!-- End Advanced Block -->
		</form>

		<div *ngIf="linkedJobSites.length > 0" class="card card-body linked-sites-card">
			<div style="margin-bottom: 10px">
				<strong style="color: #444">Linked Job Sites</strong>
			</div>
			<div *ngFor="let site of linkedJobSites" style="color: chocolate">
				<i style="margin-left: 8px; margin-right: 5px" class="fa fa-map-marker"></i>
				{{ site.description }}
			</div>
		</div>
	</div>
</div>

<div *ngIf="false" class="alert alert-info" style="margin-top: 25px">
	<pre><code>{{ empForm.value | json }}</code></pre>
</div>

<app-merge-transfer-phone-number-dialog
	[(showDialog)]="duplicateNumber.showDialog"
	(mergeBtnClicked)="mergeBtnClicked()"
	(transferBtnClicked)="transferBtnClicked()"
></app-merge-transfer-phone-number-dialog>
