<div class="ur-cli-block">
	<div class="ur-checkbox-row">
		<div class="float-right ur-skip-btn-wrap">
			<div class="ur-skip-btn" style="top: 0px; margin-left: 4px" (click)="toggleSkipCheckbox.next(item)">
				{{ item.skip ? 'edit' : 'n/a' }}
			</div>
			<input
				[disabled]="item.skip"
				[id]="item.tagId"
				type="checkbox"
				class="form-check-input ur-checkbox"
				style="display: inline-block"
				[(ngModel)]="item.value"
			/>
		</div>
		<div class="form-check-label ur-checkbox-label" [class.ur-label-disabled]="item.skip">
			<label [for]="item.tagId">{{ item.label }}</label>
		</div>
	</div>
	<div *ngIf="!item.skip && item.comment?.include">
		<textarea
			#chkboxBasicTextArea
			rows="1"
			class="form-control ur-cli-comment"
			placeholder="Enter optional comments"
			[(ngModel)]="item.comment.value"
			(input)="textResizer.next(chkboxBasicTextArea)"
		></textarea>
	</div>
</div>
