import { Component, OnInit, ViewChild, ChangeDetectorRef, NgZone, AfterViewInit } from '@angular/core'
import { Router } from '@angular/router'
import { Location } from '@angular/common'

import { CompanyRecord, CrudAction } from '@app/models'
import { CoreService } from '@app/services'
import { AccessHelper } from '@app/helpers/access'
import { log } from '@app/helpers'

declare const intuit

@Component({
    selector: 'app-quickbooks-integration',
    templateUrl: './integration.component.html',
    styleUrls: ['./integration.component.scss'],
    standalone: false
})
export class QuickbooksIntegrationComponent implements OnInit, AfterViewInit {
	accessHelper: AccessHelper

	@ViewChild('uploadComponent') uploadComponent

	isUpdating = true

	syncData = null
	showDelete = false
	showEditDialog = false
	showUploadBox = false
	uploadingFile = false
	integratingDesktop = false

	payrollRegInput = null
	payrollOTInput = null
	payrollHolInput = null
	payrollHolOTInput = null
	showPayrollItemsTooltip = false

	disablePayrollReg = null
	disablePayrollOT = true
	disablePayrollHol = true
	disablePayrollHolOT = true

	qboIntegrating = false
	qboIntegrated = false
	private company: CompanyRecord

	qbWin: Window

	qboConnectReady = false
	qboImgSrc = '/assets/qb/C2QB_green_btn_med_default.svg'

	@ViewChild('qbContainer', { static: false }) qbContainer
	CrudAction = CrudAction

	constructor(
		private cd: ChangeDetectorRef,
		private location: Location,
		private router: Router,
		private zone: NgZone,
		private coreSrvc: CoreService,
	) {
		this.setupAccessPermissions()
		// Check if account is disabled and route to billing if so
		const isAccountDisabled = this.coreSrvc.dbSrvc.settingSrvc.isAccountDisabled()
		if (isAccountDisabled) {
			this.router.navigate(['/admin/settings/billing'])
		}

		this.company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		this.coreSrvc.dbSrvc.bulkRead(['qbo_customer', 'qbo_service_item', 'qbo_sync']).then((result) => {
			this.updateUI()
			this.isUpdating = false
		})
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'quickbooks')
	}

	canPerformAction(action: CrudAction) {
		return this.accessHelper.canPerformAction(action, false)
	}

	get integrationType(): string {
		if (this.isQbOnlineIntegrated) {
			return 'Online'
		}
		if (this.isQbDesktopIntegrated) {
			return 'Desktop'
		}
		return ''
	}

	get isQbIntegrated(): boolean {
		return this.syncData && this.syncData.id ? true : false
	}

	get isQbOnlineIntegrated(): boolean {
		return this.isQbIntegrated && !this.syncData.desktop_sync
	}

	get isQbOnlineIntegrationValid(): boolean {
		return this.isQbOnlineIntegrated && this.syncData.realm_id
	}

	get isQbDesktopIntegrated(): boolean {
		return this.isQbIntegrated && this.syncData.desktop_sync
	}

	ngOnInit() {}

	ngAfterViewInit() {
		// const url = 'https://secure.telephonetimesheets.com/prod/QBORequestToken?appCompanyId='
		this.setupQBOConnectButton()
		log('Intuit', intuit)
	}

	setupQBOConnectButton() {
		const request = { operation: 'url' }
		this.coreSrvc.dbSrvc.lambdaSrvc.qboOperations(request).then((result) => {
			log('QBOOperations Result', result)
			this.zone.run(() => {
				const url = result.url
				if (url) {
					intuit.ipp.anywhere.setup({
						grantUrl: url,
						datasources: {
							quickbooks: true,
						},
					})
					const nativeElement = this.qbContainer?.nativeElement
					if (nativeElement) {
						this.qbContainer.nativeElement.appendChild(document.createElement('ipp:connecttointuit'))
						intuit.ipp.anywhere.init()
						this.qboConnectReady = true
					}
				} else {
					alert('We encountered an error attempting to connect to Intuit. Please contact support for more assistance.')
				}
			})
		})
	}

	dataLoaded(): boolean {
		return this.coreSrvc.dbSrvc.qbSrvc.dataLoaded()
	}
	quickbooksIntegrating(): boolean {
		return this.qboIntegrating
	}
	quickbooksIntegrated(): boolean {
		return this.qboIntegrated
	}

	empSyncData(): string {
		const syncData = this.coreSrvc.dbSrvc.qbSrvc.getQBOSyncData()
		if (syncData && syncData.employee_sync) {
			return syncData.employee_sync
		}
		return null
	}

	updateUI() {
		const syncData = this.coreSrvc.dbSrvc.qbSrvc.getQBOSyncData()
		this.syncData = syncData

		// Update Desktop Payroll Items
		if (syncData) {
			this.payrollRegInput = syncData.desktop_payroll_reg
			this.payrollOTInput = syncData.desktop_payroll_ot
			this.payrollHolInput = syncData.desktop_payroll_hol
			this.payrollHolOTInput = syncData.desktop_payroll_othol
			this.checkPayrollItems()
		}

		if (this.isQbIntegrated) {
			this.qboIntegrated = true
		} else {
			this.qboIntegrated = false
		}

		this.qboIntegrating = false
		this.uploadingFile = false

		if (this.isQbIntegrated && this.isQbDesktopIntegrated) {
			this.showUploadBox = true
		} else {
			this.showUploadBox = false
		}
		if (this.uploadComponent) {
			this.uploadComponent.clear()
		}
	}

	editBtnClicked() {
		if (!this.canPerformAction(CrudAction.update)) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.showEditDialog = true
	}

	startIntegratingDesktop() {
		if (!this.canPerformAction(CrudAction.create)) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.integratingDesktop = true
		this.showUploadBox = true
	}

	finishedIntegrating(): boolean {
		this.coreSrvc.dbSrvc.bulkRead(['qbo_sync', 'company']).then((success) => {
			this.zone.run(() => {
				this.updateUI()
			})
		})
		return false
	}

	reloadApplication() {
		this.location.go('/admin/dashboard')
		this.coreSrvc.dbSrvc.settingSrvc.reloadApplication()
	}

	qboBtnMouseOver() {
		this.qboImgSrc = '/assets/qb/C2QB_green_btn_med_hover.svg'
	}

	qboBtnMouseOut() {
		this.qboImgSrc = '/assets/qb/C2QB_green_btn_med_default.svg'
	}

	qboConnectBtnClicked() {
		// this.startIntegratingOnline()
		this.qboIntegrating = true
		intuit.ipp.anywhere.controller.onConnectToIntuitClicked()
	}

	onDisconnected(disconnected) {
		if (disconnected) {
			this.qboIntegrated = false
			this.integratingDesktop = false
			this.coreSrvc.dbSrvc.bulkRead(['qbo_customer', 'qbo_service_item', 'qbo_sync', 'company']).then((success) => {
				this.zone.run(() => {
					this.updateUI()
					this.setupQBOConnectButton()
				})
			})
		}
	}

	showUploader(): boolean {
		if (!this.canPerformAction(CrudAction.update)) {
			return false
		}
		if (this.syncData && this.syncData.desktop_sync) {
			return true
		}
		if (this.company && this.syncData && this.company.qbo_id && !this.syncData.desktop_sync) {
			return false
		}
		if (this.showUploadBox === false) {
			return false
		}
		return true
	}

	checkPayrollItems() {
		if (this.payrollRegInput) {
			this.disablePayrollOT = null
			this.disablePayrollHol = null
		} else {
			this.disablePayrollOT = true
			this.disablePayrollHol = true
			this.disablePayrollHolOT = true
			this.payrollOTInput = null
			this.payrollHolInput = null
			this.payrollHolOTInput = null
		}

		if (this.payrollHolInput && this.payrollOTInput) {
			this.disablePayrollHolOT = null
		} else {
			this.disablePayrollHolOT = true
			this.payrollHolOTInput = null
		}
	}

	savePayrollItems() {
		const record = {
			desktop_payroll_hol: this.payrollHolInput,
			desktop_payroll_ot: this.payrollOTInput,
			desktop_payroll_othol: this.payrollHolOTInput,
			desktop_payroll_reg: this.payrollRegInput,
		}
		this.coreSrvc.dbSrvc.updateRecord('qbo_sync', record).then((success) => {
			log('Payroll Items Saved')
			this.coreSrvc.notifySrvc.notify('success', 'Changes Saved', 'Your QuickBooks Desktop payroll itmes have been updated.')
		})

		return false
	}

	notifyOperationNotAuthorized() {
		this.coreSrvc.notifySrvc.default('operationNotAuthorized')
	}
}
