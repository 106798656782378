<div #dashLoader [hidden]="!isLoading" class="dashboard-loader-wrapper">
	<div class="dashboard-loader">
		<div>Synchronizing</div>
		<div>Database</div>
		<div class="load-progress" style="margin-top: 45px">
			<div class="progress">
				<div
					#progressBarElem
					class="progress-bar bg-success progress-bar-striped progress-bar-animated"
					role="progressbar"
					style="width: 10%"
					aria-valuenow="100"
					aria-valuemin="0"
					aria-valuemax="100"
				></div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="!isLoading" class="container top-banner-block">
	<div *ngIf="showProvisioningErrorAlert" class="alert alert-danger alert-bg-gray" style="text-align: center">
		<div style="font-weight: 600">We encountered an error<br />provisioning your account.<br />Please contact support<br />at 1-888-250-8463</div>
	</div>

	<div *ngIf="showBillingErrorAlert">
		<div class="dashboard-alert-block">
			<strong [class.blink]="flashAlertMessage">{{ billingErrorMessage }}</strong>
			<a [routerLink]="['/admin/settings/billing']" class="btn btn-sm btn-outline-secondary btn-billing">{{ buttonText }}</a>
		</div>
	</div>

	<div
		*ngIf="cardExpirationDayCount || cardExpirationDayCount === 0"
		class="alert alert-warning"
		[class.card-alert-danger]="cardExpirationDayCount < 7"
		style="margin-bottom: 25px; text-align: center; font-weight: 600"
	>
		<span *ngIf="cardExpirationDayCount > 0">Credit card expires in {{ cardExpirationDayCount }} days</span>
		<span *ngIf="cardExpirationDayCount === 0">Credit card expires today</span>
		<span *ngIf="cardExpirationDayCount < 0">Credit card has expired</span>
		<a [routerLink]="['/admin/settings/billing']" class="btn btn-sm alert-btn-fix" [class.alert-btn-fix-danger]="cardExpirationDayCount < 7">Fix</a>
	</div>

	<div *ngIf="isAnalyticsEnabled" style="margin: 22px 0px">
		<app-viz-health-score-banner [fetchDataOnInit]="true" [navigateOnClick]="true"></app-viz-health-score-banner>
	</div>
</div>

<div *ngIf="!isLoading" id="dashboardContainer" class="container dash-container-md d-none d-md-block" style="max-width: 1000px; margin-bottom: 50px">
	<div *ngIf="showSelectors" class="row" style="margin-bottom: 25px">
		<div *ngIf="!showCompanySelector" class="col-4" style="text-align: center"></div>
		<div *ngIf="showCompanySelector && !showUserSelector" class="col-4" style="text-align: center"></div>
		<div *ngIf="showCompanySelector" class="col-4" style="text-align: center">
			<p-select
				appScrollLockSelect
				id="companies1"
				appendTo="body"
				[filter]="companiesDropdown.length > 10"
				[options]="companiesDropdown"
				[(ngModel)]="selectedCompanyId"
				[virtualScroll]="companiesDropdown.length > 50"
				[virtualScrollItemSize]="32"
				[scrollHeight]="companyDropdownScrollHeight"
				[resetFilterOnHide]="true"
				(onHide)="changeCompany()"
				filterBy="label,data.accountStatus,data.fullLabel"
			>
				<ng-template #selectedItem let-selectedOption>
					<span style="vertical-align: middle">{{ selectedOption.label }}</span>
				</ng-template>
				<ng-template #item let-comp>
					<div class="tts-default-font" style="position: relative">
						<div>
							<span [class]="comp.data.colorClass" style="margin-right: 8px">{{ comp.label }}<i [class]="comp.data.iconClass"></i></span>
						</div>
					</div>
				</ng-template>
			</p-select>
		</div>
		<div *ngIf="showCompanySelector" class="col-4" style="text-align: center"></div>
		<div class="col-4" style="text-align: center">
			<div *ngIf="showUserSelector">
				<p-select
					appScrollLockSelect
					appendTo="body"
					id="users1"
					[options]="usersDropdown"
					[(ngModel)]="selectedUserId"
					[filter]="usersDropdown.length > 10"
					placeholder="Switch to Supervisor"
					(onHide)="changeUser()"
					filterBy="label"
				></p-select>
			</div>
		</div>
		<div *ngIf="!showCompanySelector" class="col-4" style="text-align: center"></div>
	</div>

	<div class="row">
		<div *ngIf="isJobSiteMerged" class="col-md-4 col-sm-6">
			<div class="card card-body card-dashboard item-block-md has-pointer" (click)="showManual()">
				<span class="dash-card-icon">
					<i class="fal fa-book card-icon" aria-hidden="true" style="font-size: 0.9em"></i>
				</span>
				<h3 class="card-title">User Guide</h3>
			</div>
		</div>

		<div class="col-md-4 col-sm-6">
			<div
				class="card card-body card-dashboard item-block-md has-pointer"
				[class.disabled]="!canAccess('employee')"
				(click)="routeTo('employee', '/admin/employees')"
			>
				<span *ngIf="!employeesDataLoaded()" class="item-spinner-md">
					<i class="fa fa-2x fa-spinner fa-spin"></i>
				</span>
				<span *ngIf="empAlertCount > 0 && canAccess('employee')" class="item-alert-md"><i class="fa fa-exclamation-triangle"></i></span>
				<!-- <span *ngIf="employeesCount() > 0 && canAccess('employee')" class="badge badge-pill badge-dark item-count-md">{{ employeesCount() }}</span> -->
				<span class="dash-card-icon">
					<i *ngIf="currentRoute !== '/admin/employees'" class="fal fa-user card-icon" aria-hidden="true"></i>
					<i *ngIf="currentRoute === '/admin/employees'" class="fad fa-spinner fa-spin card-icon" aria-hidden="true"></i>
				</span>
				<h3 class="card-title">Employees</h3>
			</div>
		</div>

		<div *ngIf="!isJobSiteMerged" class="col-md-4 col-sm-6">
			<div
				*ngIf="!isJobSiteMerged"
				class="card card-body card-dashboard item-block-md has-pointer"
				[class.disabled]="!canAccess('site')"
				(click)="routeTo('site', '/admin/jobsites')"
			>
				<span *ngIf="!jobSitesDataLoaded()" class="item-spinner-md">
					<i class="fa fa-2x fa-spinner fa-spin"></i>
				</span>
				<span *ngIf="jobSiteAlertCount > 0 && canAccess('site')" class="item-alert-md"><i class="fa fa-exclamation-triangle"></i></span>
				<!-- <span *ngIf="jobSitesCount() > 0 && canAccess('site')" class="badge badge-pill badge-dark item-count-md">{{ jobSitesCount() }}</span> -->
				<span class="dash-card-icon">
					<i *ngIf="currentRoute !== '/admin/jobsites'" class="fal fa-map-marker-alt card-icon" aria-hidden="true"></i>
					<i *ngIf="currentRoute === '/admin/jobsites'" class="fad fa-spinner fa-spin card-icon" aria-hidden="true"></i>
				</span>
				<h3 class="card-title">Job Sites</h3>
			</div>
			<div *ngIf="isJobSiteMerged" style="text-align: center">
				<img src="/assets/appicon/apple-icon-152x152.png" style="opacity: 0.5" />
			</div>
		</div>

		<div class="col-md-4 col-sm-6">
			<div
				class="card card-body card-dashboard item-block-md has-pointer"
				[class.disabled]="!canAccess('job')"
				(click)="routeTo('job', '/admin/jobs')"
			>
				<span *ngIf="!jobsDataLoaded()" class="item-spinner-md">
					<i *ngIf="!currentRoute" class="fa fa-2x fa-spinner fa-spin"></i>
					<i *ngIf="currentRoute === 'jobs'" class="fad fa-spinner fa-spin" aria-hidden="true"></i>
				</span>
				<span *ngIf="jobAlertCount > 0 && canAccess('job')" class="item-alert-md"><i class="fa fa-exclamation-triangle"></i></span>
				<!-- <span *ngIf="jobsCount() > 0 && canAccess('job')" class="badge badge-pill badge-dark item-count-md">{{ jobsCount() }}</span> -->
				<span class="dash-card-icon">
					<i *ngIf="currentRoute !== '/admin/jobs'" class="fal fa-tasks-alt card-icon" aria-hidden="true"></i>
					<i *ngIf="currentRoute === '/admin/jobs'" class="fad fa-spinner fa-spin card-icon" aria-hidden="true"></i>
				</span>
				<h3 class="card-title">Jobs</h3>
			</div>
		</div>
	</div>

	<div class="row" style="margin-top: 25px">
		<div class="col-md-4 col-sm-6">
			<div
				class="card card-body card-dashboard item-block-md has-pointer"
				[class.disabled]="!canAccess('schedule')"
				(click)="showSchedulingDialog = true"
			>
				<span *ngIf="!schedulerDataLoaded()" class="item-spinner-md">
					<i class="fa fa-2x fa-spinner fa-spin"></i>
				</span>
				<!-- <span *ngIf="schedAlertCount > 0" class="item-alert-md"><i class="fa fa-exclamation-triangle"></i></span> -->
				<!-- <span *ngIf="false && scheduleCount() > 0" class="badge badge-pill badge-dark item-count-md">{{ scheduleCount() }}</span> -->
				<span class="dash-card-icon">
					<i *ngIf="currentRoute !== '/admin/scheduler'" class="fal fa-calendar card-icon" aria-hidden="true"></i>
					<i *ngIf="currentRoute === '/admin/scheduler'" class="fad fa-spinner fa-spin card-icon" aria-hidden="true"></i>
				</span>
				<h3 class="card-title">Scheduling</h3>
			</div>
		</div>

		<div *ngIf="isVacationMerged" class="col-md-4 col-sm-6">
			<div
				class="card card-body card-dashboard item-block-md has-pointer"
				[class.disabled]="!canAccess('notifications')"
				(click)="routeTo(null, '/admin/notifications')"
			>
				<span *ngIf="!notificationDataLoaded()" class="item-spinner-md">
					<i class="fa fa-2x fa-spinner fa-spin"></i>
				</span>
				<span *ngIf="false" class="item-alert-md"><i class="fa fa-exclamation-triangle"></i></span>
				<!-- <span *ngIf="vacationsCount() > 0 && canAccess('vacation')" class="badge badge-pill badge-dark item-count-md">{{ vacationsCount() }}</span> -->
				<span class="dash-card-icon">
					<i *ngIf="currentRoute !== '/admin/notifications'" class="fal fa-bell card-icon" aria-hidden="true"></i>
					<i *ngIf="currentRoute === '/admin/notifications'" class="fad fa-spinner fa-spin card-icon" aria-hidden="true"></i>
				</span>
				<h3 class="card-title">Notifications</h3>
			</div>
		</div>

		<div class="col-md-4 col-sm-6">
			<div
				class="card card-body card-dashboard item-block-md has-pointer"
				[class.disabled]="!canAccess('transaction')"
				(click)="routeTo('transaction', '/admin/transactions')"
			>
				<span *ngIf="!transactionsDataLoaded()" class="item-spinner-md">
					<i class="fa fa-2x fa-spinner fa-spin"></i>
				</span>
				<span class="dash-card-icon">
					<!-- <i *ngIf="currentRoute !== '/admin/transactions'" class="fa fa-clock-o" aria-hidden="true"></i> -->
					<img *ngIf="currentRoute !== '/admin/transactions'" class="img-time" src="/assets/img/section/time-entries.svg" />
					<i *ngIf="currentRoute === '/admin/transactions'" class="fad fa-spinner fa-spin card-icon" aria-hidden="true"></i>
				</span>
				<h3 class="card-title img-card-title" [class.img-card-title-padder]="currentRoute === '/admin/transactions'">Time Entries</h3>
			</div>
		</div>
	</div>

	<div *ngIf="showAdminOptions">
		<hr style="margin: 30px 0px" />

		<div class="row" style="margin-top: 25px">
			<div class="col-md-4 col-sm-6">
				<div
					class="card card-body card-dashboard item-block-md sub-card has-pointer"
					[class.disabled]="!canAccess('supervisor')"
					(click)="routeTo('supervisor', '/admin/users')"
				>
					<span class="dash-card-icon-sm">
						<i *ngIf="currentRoute !== '/admin/users'" class="fal fa-user card-icon" aria-hidden="true"></i>
						<i *ngIf="currentRoute === '/admin/users'" class="fad fa-spinner fa-spin card-icon" aria-hidden="true"></i>
					</span>
					<h3 class="card-title">Supervisors</h3>
				</div>
			</div>

			<div class="col-md-4 col-sm-6">
				<div
					class="card card-body card-dashboard item-block-md sub-card has-pointer"
					[class.disabled]="!canAccess('report')"
					(click)="reportsBtnClicked()"
				>
					<span class="dash-card-icon-sm">
						<i
							*ngIf="currentRoute !== '/admin/reports/business' && currentRoute !== '/admin/reports/employee'"
							class="fal fa-pie-chart card-icon"
							aria-hidden="true"
						></i>
						<i
							*ngIf="currentRoute === '/admin/reports/business' || currentRoute === '/admin/reports/employee'"
							class="fad fa-spinner fa-spin card-icon"
							aria-hidden="true"
						></i>
					</span>
					<h3 class="card-title">Reports</h3>
				</div>
			</div>

			<div class="col-md-4 col-sm-6">
				<div
					class="card card-body card-dashboard item-block-md sub-card has-pointer"
					[class.disabled]="!canAccess('announcement')"
					(click)="routeTo('announcement', '/admin/announcements')"
				>
					<span class="dash-card-icon-sm">
						<i *ngIf="currentRoute !== '/admin/announcements'" class="fal fa-bullhorn card-icon" aria-hidden="true"></i>
						<i *ngIf="currentRoute === '/admin/announcements'" class="fad fa-spinner fa-spin card-icon" aria-hidden="true"></i>
					</span>
					<h3 class="card-title">Announcements</h3>
				</div>
			</div>
		</div>

		<div class="row" style="margin-top: 25px">
			<div class="col-md-4 col-sm-6">
				<div
					class="card card-body card-dashboard item-block-md sub-card has-pointer"
					[class.disabled]="!canAccess('account')"
					(click)="routeTo('account', '/admin/settings')"
				>
					<span class="dash-card-icon-sm">
						<i *ngIf="currentRoute !== '/admin/settings'" class="fal fa-cog card-icon" aria-hidden="true"></i>
						<i *ngIf="currentRoute === '/admin/settings'" class="fad fa-spinner fa-spin card-icon" aria-hidden="true"></i>
					</span>
					<h3 class="card-title">Settings</h3>
				</div>
			</div>

			<div class="col-md-4 col-sm-6">
				<div
					class="card card-body card-dashboard item-block-md sub-card has-pointer"
					[class.disabled]="!canAccess('billing')"
					(click)="routeTo('billing', '/admin/settings/billing')"
				>
					<span class="dash-card-icon-sm">
						<i *ngIf="currentRoute !== '/admin/settings/billing'" class="fal fa-credit-card card-icon" aria-hidden="true"></i>
						<i *ngIf="currentRoute === '/admin/settings/billing'" class="fad fa-spinner fa-spin card-icon" aria-hidden="true"></i>
					</span>
					<h3 class="card-title">Billing</h3>
				</div>
			</div>

			<div class="col-md-4 col-sm-6">
				<div
					class="card card-body card-dashboard item-block-md sub-card has-pointer"
					[class.disabled]="!canAccess('audit') && !canAccess('calllogivr')"
					(click)="logsBtnClicked()"
				>
					<span class="dash-card-icon-sm">
						<i
							*ngIf="currentRoute !== '/admin/auditlog' && currentRoute !== '/admin/comm-log'"
							class="fal fa-database card-icon"
							aria-hidden="true"
						></i>
						<i
							*ngIf="currentRoute === '/admin/auditlog' || currentRoute === '/admin/comm-log'"
							class="fad fa-spinner fa-spin card-icon"
							aria-hidden="true"
						></i>
					</span>
					<h3 class="card-title">Logs</h3>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="!isLoading" class="container dash-container-sm d-md-none" style="max-width: 1000px; margin: 0 auto">
	<div *ngIf="showSelectors" style="margin-bottom: 25px">
		<div *ngIf="showCompanySelector" class="row">
			<div class="col-12" style="text-align: center">
				<p-select
					appScrollLockSelect
					id="companies2"
					appendTo="body"
					[options]="companiesDropdown"
					[(ngModel)]="selectedCompanyId"
					[filter]="companiesDropdown.length > 10"
					[virtualScroll]="companiesDropdown.length > 50"
					[virtualScrollItemSize]="32"
					[scrollHeight]="companyDropdownScrollHeight"
					(onHide)="changeCompany()"
					filterBy="label,data.accountStatus,data.fullLabel"
				>
					<ng-template #selectedItem let-selectedOption>
						<span style="vertical-align: middle">{{ selectedOption.label }}</span>
					</ng-template>
					<ng-template #item let-comp>
						<div class="tts-default-font" style="position: relative">
							<div>
								<span [class]="comp.data.colorClass" style="margin-right: 8px">{{ comp.label }}<i [class]="comp.data.iconClass"></i></span>
							</div>
						</div>
					</ng-template>
				</p-select>
			</div>
		</div>
		<div *ngIf="showUserSelector" class="row" style="margin-top: 10px">
			<div class="col-12" style="text-align: center">
				<p-select
					appScrollLockSelect
					id="users2"
					appendTo="body"
					[options]="usersDropdown"
					[(ngModel)]="selectedUserId"
					[filter]="usersDropdown.length > 10"
					placeholder="Switch to Supervisor"
					(onHide)="changeUser()"
					filterBy="label"
				></p-select>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-12 section-item-xs">
			<div class="card card-body item-block-sm" [class.disabled]="!canAccess('employee')" (click)="routeTo('employee', '/admin/employees')">
				<div *ngIf="currentRoute !== '/admin/employees'">
					<div class="item-icon-wrap">
						<i *ngIf="empAlertCount === 0" class="fal fa-user" aria-hidden="true"></i>
						<i *ngIf="empAlertCount > 0" class="fa fa-exclamation-triangle icon-alert-sm"></i>
					</div>
					Employees
				</div>
				<div *ngIf="currentRoute === '/admin/employees'">
					<div class="item-icon-wrap"><i class="fad fa-spinner fa-spin" aria-hidden="true"></i></div>
					Employees
				</div>
				<!-- <span *ngIf="employeesCount() > 0" class="badge badge-pill badge-dark item-count-xs">{{ employeesCount() }}</span> -->
			</div>
		</div>

		<div *ngIf="!isJobSiteMerged" class="col-12 section-item-xs">
			<div class="card card-body item-block-sm" [class.disabled]="!canAccess('site')" (click)="routeTo('site', '/admin/jobsites')">
				<div *ngIf="currentRoute !== '/admin/jobsites'">
					<div class="item-icon-wrap">
						<i *ngIf="jobSiteAlertCount === 0" class="fal fa-map-marker-alt" aria-hidden="true"></i>
						<i *ngIf="jobSiteAlertCount > 0" class="fa fa-exclamation-triangle icon-alert-sm"></i>
					</div>
					Job Sites
				</div>
				<div *ngIf="currentRoute === '/admin/jobsites'">
					<div class="item-icon-wrap"><i class="fad fa-spinner fa-spin" aria-hidden="true"></i></div>
					Job Sites
				</div>
				<!-- <span *ngIf="jobSitesCount() > 0" class="badge badge-pill badge-dark item-count-xs">{{ jobSitesCount() }}</span> -->
			</div>
		</div>

		<div class="col-12 section-item-xs">
			<div class="card card-body item-block-sm" [class.disabled]="!canAccess('job')" (click)="routeTo('job', '/admin/jobs')">
				<div *ngIf="currentRoute !== '/admin/jobs'">
					<div class="item-icon-wrap">
						<i *ngIf="jobAlertCount === 0" class="fal fa-tasks-alt" aria-hidden="true"></i>
						<i *ngIf="jobAlertCount > 0" class="fa fa-exclamation-triangle icon-alert-sm"></i>
					</div>
					Jobs
				</div>
				<div *ngIf="currentRoute === '/admin/jobs'">
					<div class="item-icon-wrap"><i class="fad fa-spinner fa-spin" aria-hidden="true"></i></div>
					Jobs
				</div>
				<!-- <span *ngIf="jobsCount() > 0" class="badge badge-pill badge-dark item-count-xs">{{ jobsCount() }}</span> -->
			</div>
		</div>

		<div class="col-12 section-item-xs">
			<div class="card card-body item-block-sm" [class.disabled]="!canAccess('schedule')" (click)="showSchedulingDialog = true">
				<div *ngIf="currentRoute !== '/admin/scheduler'">
					<div class="item-icon-wrap">
						<i class="fal fa-calendar" aria-hidden="true"></i>
						<!-- <i *ngIf="schedAlertCount > 0" class="fa fa-exclamation-triangle icon-alert-sm"></i> -->
					</div>
					Scheduling
				</div>
				<div *ngIf="currentRoute === '/admin/scheduler'">
					<div class="item-icon-wrap"><i class="fad fa-spinner fa-spin" aria-hidden="true"></i></div>
					Scheduling
				</div>
				<!-- <span *ngIf="scheduleCount() > 0" class="badge badge-pill badge-dark item-count-xs">{{ scheduleCount() }}</span> -->
			</div>
		</div>

		<div *ngIf="isVacationMerged" class="col-12 section-item-xs">
			<div class="card card-body item-block-sm" [class.disabled]="!canAccess('notifications')" (click)="routeTo(null, '/admin/notifications')">
				<div *ngIf="currentRoute !== '/admin/notifications'">
					<div class="item-icon-wrap">
						<i class="fal fa-bell" aria-hidden="true"></i>
						<!-- <i *ngIf="vacAlertCount > 0" class="fa fa-exclamation-triangle con-alert-sm"></i> -->
					</div>
					Notifications
				</div>
				<div *ngIf="currentRoute === '/admin/notifications'">
					<div class="item-icon-wrap"><i class="fad fa-spinner fa-spin" aria-hidden="true"></i></div>
					Notifications
				</div>
			</div>
		</div>

		<div class="col-12 section-item-xs">
			<div
				class="card card-body item-block-sm"
				[class.disabled]="!canAccess('transaction')"
				(click)="routeTo('transaction', '/admin/transactions')"
				style="height: 58px"
			>
				<div>
					<div class="item-icon-wrap">
						<!-- <i *ngIf="currentRoute !== '/admin/transactions'" class="fa fa-clock-o" aria-hidden="true"></i> -->
						<img
							*ngIf="currentRoute !== '/admin/transactions'"
							style="width: 30px; height: 30px; position: relative; top: -4px; left: -6px; opacity: 0.6"
							src="/assets/img/section/time-entries.svg"
						/>
						<i *ngIf="currentRoute === '/admin/transactions'" class="fad fa-spinner fa-spin" aria-hidden="true"></i>
					</div>
					Time Entries
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="showAdminOptions">
		<hr style="margin: 15px 0px 25px 0px" />

		<div class="row">
			<div class="col-12 section-item-xs">
				<div
					class="card card-body sub-card item-block-sm"
					[class.disabled]="!canAccess('supervisor')"
					(click)="routeTo('supervisor', '/admin/users')"
				>
					<div>
						<div class="item-icon-wrap">
							<i *ngIf="currentRoute !== '/admin/users'" class="fal fa-user" aria-hidden="true"></i>
							<i *ngIf="currentRoute === '/admin/users'" class="fad fa-spinner fa-spin" aria-hidden="true"></i>
						</div>
						Supervisors
					</div>
				</div>
			</div>

			<div class="col-12 section-item-xs">
				<div class="card card-body sub-card item-block-sm" [class.disabled]="!canAccess('report')" (click)="reportsBtnClicked()">
					<div>
						<div class="item-icon-wrap">
							<i
								*ngIf="currentRoute !== '/admin/reports/business' && currentRoute !== '/admin/reports/employee'"
								class="fal fa-pie-chart"
								aria-hidden="true"
							></i>
							<i
								*ngIf="currentRoute === '/admin/reports/business' || currentRoute === '/admin/reports/employee'"
								class="fad fa-spinner fa-spin"
								aria-hidden="true"
							></i>
						</div>
						Reports
					</div>
				</div>
			</div>

			<div class="col-12 section-item-xs">
				<div
					class="card card-body sub-card item-block-sm"
					[class.disabled]="!canAccess('announcement')"
					(click)="routeTo('announcement', '/admin/announcements')"
				>
					<div>
						<div class="item-icon-wrap">
							<i *ngIf="currentRoute !== '/admin/announcements'" class="fal fa-bullhorn" aria-hidden="true"></i>
							<i *ngIf="currentRoute === '/admin/announcements'" class="fad fa-spinner fa-spin" aria-hidden="true"></i>
						</div>
						Announcements
					</div>
				</div>
			</div>

			<div class="col-12 section-item-xs">
				<div
					class="card card-body sub-card item-block-sm"
					[class.disabled]="!canAccess('account')"
					(click)="routeTo('account', '/admin/settings')"
				>
					<div>
						<div class="item-icon-wrap">
							<i *ngIf="currentRoute !== '/admin/settings'" class="fal fa-cog" aria-hidden="true"></i>
							<i *ngIf="currentRoute === '/admin/settings'" class="fad fa-spinner fa-spin" aria-hidden="true"></i>
						</div>
						Settings
					</div>
				</div>
			</div>

			<div class="col-12 section-item-xs">
				<div
					class="card card-body sub-card item-block-sm"
					[class.disabled]="!canAccess('billing')"
					(click)="routeTo('billing', '/admin/settings/billing')"
				>
					<div>
						<div class="item-icon-wrap">
							<i *ngIf="currentRoute !== '/admin/settings/billing'" class="fal fa-credit-card" aria-hidden="true"></i>
							<i *ngIf="currentRoute === '/admin/settings/billing'" class="fad fa-spinner fa-spin" aria-hidden="true"></i>
						</div>
						Billing
					</div>
				</div>
			</div>

			<div class="col-12 section-item-xs">
				<div
					class="card card-body sub-card item-block-sm"
					[class.disabled]="!canAccess('audit') && !canAccess('calllogivr')"
					(click)="logsBtnClicked()"
				>
					<div>
						<div class="item-icon-wrap">
							<i
								*ngIf="currentRoute !== '/admin/auditlog' && currentRoute !== '/admin/comm-log'"
								class="fal fa-database"
								aria-hidden="true"
							></i>
							<i
								*ngIf="currentRoute === '/admin/auditlog' || currentRoute === '/admin/comm-log'"
								class="fad fa-spinner fa-spin"
								aria-hidden="true"
							></i>
						</div>
						Logs
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- <button (click)="testBtnClicked()">test</button> -->

<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Report Options"
	[(visible)]="showReportsDialog"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div>
		<button
			class="btn-menu btn-menu-primary"
			style="margin-top: 6px"
			(click)="showReportsDialog = false; routeTo('report', '/admin/reports/employee')"
		>
			Employee Shift Reports
		</button>
		<button
			class="btn-menu btn-menu-primary"
			style="margin-top: 10px; margin-bottom: 6px"
			(click)="showReportsDialog = false; routeTo('report', '/admin/reports/business')"
		>
			Business Insight Reports
		</button>
	</div>
</p-dialog>

<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Schedule Options"
	[(visible)]="showSchedulingDialog"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<!-- This hidden button is to fix a rendering bug which highlights first button -->
	<div>
		<div>
			<button class="btn-menu btn-menu-primary" style="margin-top: 6px" (click)="showSchedulingDialog = false; switchToScheduleView('SERIES')">
				Schedules
			</button>
			<button
				class="btn-menu btn-menu-primary"
				style="margin-top: 10px; margin-bottom: 6px"
				(click)="showSchedulingDialog = false; switchToScheduleView('SHIFT')"
			>
				Shifts
			</button>
			<button
				class="btn-menu btn-menu-primary"
				style="margin-top: 10px; margin-bottom: 6px"
				(click)="showSchedulingDialog = false; switchToScheduleView('TIMEOFF')"
			>
				Time Off
			</button>
		</div>
	</div>
</p-dialog>

<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Log Options"
	[(visible)]="showLogsDialog"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div>
		<button class="btn-menu btn-menu-primary" style="margin-top: 6px" (click)="showLogsDialog = false; routeTo('audit', '/admin/auditlog')">
			Audit Log
		</button>
		<button
			class="btn-menu btn-menu-primary"
			style="margin-top: 10px; margin-bottom: 6px"
			(click)="showLogsDialog = false; routeTo('calllogivr', '/admin/comm-log')"
		>
			Comm Log
		</button>
	</div>
</p-dialog>
