import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { UserLoginService } from '@app/services/aws/user-login.service'
import { log } from '@app/helpers'

@Injectable({ providedIn: 'root' })
export class AuthGuard {
	constructor(
		private router: Router,
		private userLoginSrvc: UserLoginService,
	) {}

	canActivate() {
		return this.userLoginSrvc
			.isAuthenticated$()
			.then((success) => {
				if (success) {
					return true
				} else {
					log('AUTHGUARD: CAN NOT ACCESS')
					this.router.navigate(['/home/login'])
					return false
				}
			})
			.catch((error) => {
				log('Error authenticating')
				this.router.navigate(['/home/login'])
				return false
			})
	}
}
