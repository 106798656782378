<!-- <div class="form-group">
	<div class="options-block">
		<div class="options-title">Location</div>
		<p-select
			appScrollLockSelect
			[options]="addressOptions"
			[(ngModel)]="manager.selectedLocationType"
			[filter]="false"
			[required]="false"
		></p-select>
	</div>
</div> -->

<div *ngIf="manager.selectedLocationType === 'address'" style="margin-top: 20px">
	<div>
		<div class="form-group">
			<label for="addrStreet1" class="form-control-label"> Street Address </label>
			<input
				id="addrStreet1"
				type="text"
				class="form-control"
				[(ngModel)]="manager.address.address_1"
				placeholder="Street Address 1"
				autocomplete="off"
			/>
		</div>
		<div class="form-group">
			<label for="addrStreet2" class="form-control-label"> Street Address 2</label>
			<input
				id="addrStreet2"
				type="text"
				class="form-control"
				[(ngModel)]="manager.address.address_2"
				placeholder="Street Address 2"
				autocomplete="off"
			/>
		</div>
		<div class="form-group">
			<label for="addrState" class="form-control-label"> City </label>
			<input id="addrCity" type="text" class="form-control" [(ngModel)]="manager.address.city" placeholder="City" autocomplete="off" />
		</div>
		<div class="row">
			<div class="col-7">
				<div class="form-group">
					<label for="district" class="form-control-label"> {{ districtLabel }}</label>
					<!-- <input
						id="district"
						type="text"
						class="form-control"
						[(ngModel)]="manager.address.district"
						placeholder="State / Province"
						autocomplete="off"
					/> -->
					<p-autoComplete
						appScrollLockAutoComplete
						[placeholder]="districtLabel"
						[inputStyleClass]="'p-autocomplete-mod'"
						[(ngModel)]="manager.address.district"
						[suggestions]="districtOptions"
						[delay]="150"
						[completeOnFocus]="true"
						(completeMethod)="searchStates($event)"
					>
					</p-autoComplete>
				</div>
			</div>
			<div class="col-5">
				<div class="form-group">
					<label for="addrZip" class="form-control-label"> Zip / Postcode </label>
					<input
						id="addrZip"
						type="text"
						class="form-control"
						[(ngModel)]="manager.address.postal_code"
						placeholder="Postcode"
						autocomplete="off"
					/>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="manager.showMapViewOption" style="margin: 8px 0px">
	<p-checkbox name="enableSatelliteView" (onChange)="manager.updateLocationType()" [(ngModel)]="manager.enableMapView" [binary]="true"></p-checkbox>
	<div class="checkbox-label" (click)="manager.enableMapView = !manager.enableMapView; manager.updateLocationType()">Set address using map pin</div>
</div>

<div *ngIf="manager.selectedLocationType === 'marker'" class="map-block">
	<app-edit-map-marker [latitude]="manager.latitude" [longitude]="manager.longitude" (gpsUpdated)="manager.gpsUpdated($event)"></app-edit-map-marker>
</div>
