import { Injectable, NgZone } from '@angular/core'
import { log } from '@app/helpers'
import { Global } from '@app/models/global'
import { environment } from '@env/environment'

import moment from 'moment-timezone'

import {
	AppService,
	AnalyticsService,
	AwsUtil,
	BannerService,
	BrandingService,
	CallService,
	CognitoUtil,
	ConnectionService,
	DatabaseService,
	DisplayService,
	EventService,
	ExceptionsService,
	NavbarService,
	NotificationsService,
	PrefsService,
	UserLoginService,
	UserSessionService,
	WorkingService,
} from '@app/services'
import { DeviceDetectorService } from 'ngx-device-detector'
import { MessageService } from 'primeng/api'
import { AudioService, ChatBotService, HelpService, GuidedTourService } from '@app/services'

@Injectable({ providedIn: 'root' })
export class CoreService {
	public env = environment
	public auditMode = environment.enableAuditMode

	constructor(
		// Load session / login related services
		public awsUtil: AwsUtil,
		public cognito: CognitoUtil,
		public loginSrvc: UserLoginService,
		public sessionSrvc: UserSessionService,

		// Load Core Services
		public appSrvc: AppService,
		public audioSrvc: AudioService,
		public analyticsSrvc: AnalyticsService,
		public bannerSrvc: BannerService,
		public brandingSrvc: BrandingService,
		public callSrvc: CallService,
		public cbSrvc: ChatBotService,
		public connectSrvc: ConnectionService,
		public devDetect: DeviceDetectorService,
		public displaySrvc: DisplayService,
		public eventSrvc: EventService,
		public excptSrvc: ExceptionsService,
		public gtSrvc: GuidedTourService,
		public helpSrvc: HelpService,
		public msgSrvc: MessageService,
		public navbarSrvc: NavbarService,
		public notifySrvc: NotificationsService,
		public prefSrvc: PrefsService,
		public workSrvc: WorkingService,

		// Load Database Service
		public dbSrvc: DatabaseService,

		// Setup non-service related references
		public zone: NgZone,
	) {
		log('AUDITMODE', this.auditMode)
		log('Core Services Created')
		Global.coreSrvc = this
		log('Global Namespace Now Available')
		this.dbSrvc.vizSrvc.viewManager.restore()
	}

	// Figure a better place for this

	public setTimeEntriesDayViewDate(date: string | Date) {
		if (date) {
			const dayViewMom = moment(date)
			if (dayViewMom.isValid()) {
				const dayViewDate = dayViewMom.toDate()
				this.dbSrvc.tranSrvc.filterStartDate = dayViewMom.toDate()
				this.dbSrvc.tranSrvc.filterEndDate = dayViewMom.toDate()
				this.prefSrvc.data.transDefaultToTodayView = true
				this.dbSrvc.tranSrvc.setDayViewDate(dayViewDate)
			}
		} else {
			this.dbSrvc.tranSrvc.deactivateTodayView()
			this.prefSrvc.data.transDefaultToTodayView = false
		}
	}
}
