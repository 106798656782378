import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrl: './confirmation-dialog.component.scss',
    standalone: false
})
export class ConfirmationDialogComponent {
	@Input() isVisible = false
	@Input() message = 'Are you sure?'
	@Output() isVisibleChange = new EventEmitter<boolean>()
	@Output() actionConfirmed = new EventEmitter<any>()
}
