import { DateTimeHelper } from '@app/helpers'

export class UserRoleRecord {
	id: number
	description: string
	details: string
	permissions_json: string
	created: string
	updated: string

	constructor(record?: Object) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
			this.updated = DateTimeHelper.stripUtcTag(this.updated)
		}
	}

	isSystemRole(): boolean {
		return this.id < 1000
	}
}

export enum AccessRoleType {
	fullAccess,
	manager,
	secondary,
	readOnly,
}

const fullAccessRole = [
	{
		key: 'users',
		values: [
			{
				type: 'ACCESS',
				data: '',
			},
		],
	},
	{
		key: 'company',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'billing_log',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
]

const managerAccessRole = [
	{
		key: 'adp_sync',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'announcements',
		values: [
			{
				type: 'SELECT',
				data: 'EMP:O',
			},
			{
				type: 'SELECT',
				data: 'SITE:O',
			},
		],
	},
	{
		key: 'billing_log',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'company',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'employee',
		values: [
			{
				type: 'ACCESS',
				data: '',
			},
			{
				type: 'SELECT',
				data: 'EMS:O',
			},
		],
	},
	{
		key: 'employee_job_pay_rate',
		values: [
			{
				type: 'ACCESS',
				data: '',
			},
			{
				type: 'OWNER',
				data: 'R',
			},
		],
	},
	{
		key: 'file_import_sync',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'google_cal_sync',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'job',
		values: [
			{
				type: 'ACCESS',
				data: '',
			},
			{
				type: 'SELECT',
				data: 'SITE:O',
			},
		],
	},
	{
		key: 'location',
		values: [
			{
				type: 'ACCESS',
				data: '',
			},
		],
	},
	{
		key: 'qbo_sync',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'reports',
		values: [
			{
				type: 'ACCESS',
				data: '',
			},
		],
	},
	{
		key: 'schedule_recur',
		values: [
			{
				type: 'ACCESS',
				data: '',
			},
			{
				type: 'SELECT',
				data: 'EMP:O',
			},
			{
				type: 'SELECT',
				data: 'JOB:O',
			},
		],
	},
	{
		key: 'transaction_log',
		values: [
			{
				type: 'ACCESS',
				data: '',
			},
			{
				type: 'SELECT',
				data: 'EMP:O',
			},
			{
				type: 'OPTION',
				data: 'TEEEAN:D',
			},
		],
	},
	{
		key: 'users',
		values: [
			{
				type: 'ACCESS',
				data: '',
			},
			{
				type: 'OPTION',
				data: 'SUPROL:D',
			},
			{
				type: 'OPTION',
				data: 'SUPPRF:D',
			},
			{
				type: 'OPTION',
				data: 'SUPLEM:D',
			},
		],
	},
	{
		key: 'vacation',
		values: [
			{
				type: 'ACCESS',
				data: '',
			},
		],
	},
	{
		key: 'wiw_sync',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'ivr_call_log',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
]

const supervisorAccessRole = [
	{
		key: 'employee',
		values: [
			{
				type: 'ACCESS',
				data: '',
			},
			{
				type: 'SELECT',
				data: 'EMS:O',
			},
		],
	},
	{
		key: 'employee_job_pay_rate',
		values: [
			{
				type: 'ACCESS',
				data: '',
			},
		],
	},
	{
		key: 'location',
		values: [
			{
				type: 'ACCESS',
				data: '',
			},
		],
	},
	{
		key: 'job',
		values: [
			{
				type: 'ACCESS',
				data: '',
			},
			{
				type: 'SELECT',
				data: 'SITE:O',
			},
		],
	},
	{
		key: 'schedule_recur',
		values: [
			{
				type: 'ACCESS',
				data: '',
			},
			{
				type: 'SELECT',
				data: 'EMP:O',
			},
			{
				type: 'SELECT',
				data: 'JOB:O',
			},
		],
	},
	{
		key: 'vacation',
		values: [
			{
				type: 'ACCESS',
				data: '',
			},
			{
				type: 'SELECT',
				data: 'EMP:O',
			},
		],
	},
	{
		key: 'transaction_log',
		values: [
			{
				type: 'ACCESS',
				data: '',
			},
			{
				type: 'SELECT',
				data: 'EMP:O',
			},
			{
				type: 'OPTION',
				data: 'TEEEAN:D',
			},
		],
	},
	{
		key: 'announcements',
		values: [
			{
				type: 'SELECT',
				data: 'EMP:O',
			},
			{
				type: 'SELECT',
				data: 'SITE:O',
			},
		],
	},
	{
		key: 'users',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'reports',
		values: [
			{
				type: 'ACCESS',
				data: '',
			},
		],
	},
	{
		key: 'company',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'billing_log',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'adp_sync',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'file_import_sync',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'qbo_sync',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'wiw_sync',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'google_cal_sync',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'ivr_call_log',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
]

const readOnlyAccessRole = [
	{
		key: 'adp_sync',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'announcements',
		values: [
			{
				type: 'ACCESS',
				data: 'R',
			},
			{
				type: 'OWNER',
				data: 'R',
			},
		],
	},
	{
		key: 'billing_log',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'company',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'employee',
		values: [
			{
				type: 'ACCESS',
				data: 'R',
			},
			{
				type: 'OWNER',
				data: 'R',
			},
		],
	},
	{
		key: 'employee_job_pay_rate',
		values: [
			{
				type: 'ACCESS',
				data: 'R',
			},
			{
				type: 'OWNER',
				data: 'R',
			},
		],
	},
	{
		key: 'file_import_sync',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'google_cal_sync',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'job',
		values: [
			{
				type: 'ACCESS',
				data: 'R',
			},
			{
				type: 'OWNER',
				data: 'R',
			},
		],
	},
	{
		key: 'location',
		values: [
			{
				type: 'ACCESS',
				data: 'R',
			},
			{
				type: 'OWNER',
				data: 'R',
			},
		],
	},
	{
		key: 'notification_profile',
		values: [
			{
				type: 'ACCESS',
				data: 'R',
			},
			{
				type: 'OWNER',
				data: 'R',
			},
		],
	},
	{
		key: 'qbo_sync',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'reports',
		values: [
			{
				type: 'ACCESS',
				data: 'R',
			},
			{
				type: 'OWNER',
				data: 'R',
			},
		],
	},
	{
		key: 'audit_log',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'schedule_recur',
		values: [
			{
				type: 'ACCESS',
				data: 'R',
			},
			{
				type: 'OWNER',
				data: 'R',
			},
		],
	},
	{
		key: 'transaction_log',
		values: [
			{
				type: 'ACCESS',
				data: 'R',
			},
			{
				type: 'OWNER',
				data: 'R',
			},
			{
				type: 'OPTION',
				data: 'TEEEAN:D',
			},
		],
	},
	{
		key: 'users',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'vacation',
		values: [
			{
				type: 'ACCESS',
				data: 'R',
			},
			{
				type: 'OWNER',
				data: 'R',
			},
		],
	},
	{
		key: 'vendor_client_org',
		values: [
			{
				type: 'ACCESS',
				data: 'R',
			},
			{
				type: 'OWNER',
				data: 'R',
			},
		],
	},
	{
		key: 'wiw_sync',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
	{
		key: 'ivr_call_log',
		values: [
			{
				type: 'ACCESS',
				data: 'N',
			},
			{
				type: 'OWNER',
				data: '',
			},
		],
	},
]

export class AccessRole {
	static getRole(role: AccessRoleType) {
		switch (role) {
			case AccessRoleType.fullAccess:
				return JSON.stringify(fullAccessRole)
			case AccessRoleType.manager:
				return JSON.stringify(managerAccessRole)
			case AccessRoleType.secondary:
				return JSON.stringify(supervisorAccessRole)
			case AccessRoleType.readOnly:
				return JSON.stringify(readOnlyAccessRole)
			default:
				return null
		}
	}

	static getRolesDropdownData(userRole: string) {
		const managerRole = {
			label: 'Manager Role',
			value: AccessRole.getRole(AccessRoleType.manager),
			data: { authorizedRoles: ['INTERNAL', 'PRIMARY'] },
		}
		const supervisorRole = {
			label: 'Supervisor Role',
			value: AccessRole.getRole(AccessRoleType.secondary),
			data: { authorizedRoles: ['INTERNAL', 'PRIMARY', 'MANAGER'] },
		}
		const readOnlyRole = {
			label: 'Read Only Role',
			value: AccessRole.getRole(AccessRoleType.readOnly),
			data: { authorizedRoles: ['INTERNAL', 'PRIMARY'] },
		}

		const availableRoles = [managerRole, supervisorRole, readOnlyRole]
		return availableRoles.filter((role) => role.data.authorizedRoles.includes(userRole))
	}
}
