import { Component, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core'
import { TransactionLogRecord, Checkpoint, CheckpointDataAccessRequest, ComponentBridgeName } from '@app/models'
import { CoreService } from '@app/services'
import { DateTimeHelper, DisplayHelper, MapHelper, log } from '@app/helpers'
import { CheckpointsListComponent } from './checkpoints-list/checkpoints-list.component'

@Component({
    selector: 'app-checkpoints-dialog',
    templateUrl: './checkpoints-dialog.component.html',
    styleUrls: ['./checkpoints-dialog.component.scss'],
    standalone: false
})
export class CheckpointsDialogComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() transId: number
	@Input() linkType: string
	@Output() closeDialog = new EventEmitter<boolean>()
	@Output() recordUpdated = new EventEmitter<boolean>()

	isDesktop = false
	isGlobalAccount = false
	canAdminCreateCheckpoints = true

	trans: TransactionLogRecord
	timezone: string

	checkpoints: Array<Checkpoint> = []
	address: string
	showLegendHighlight = false

	viewButtonTitle = 'Show List'
	currentView: 'MAP' | 'LIST' = 'LIST'

	checkPointHover = false

	private centerLat: number
	private centerLng: number

	public jobSiteMarker: google.maps.marker.AdvancedMarkerElement
	public lastCheckpointMarker: google.maps.marker.AdvancedMarkerElement
	public checkpointMarkers: Array<google.maps.marker.AdvancedMarkerElement> = []

	public currentOpenMarker = null
	public currentCheckpointIndex = null
	public currentCheckpoint: Checkpoint = null
	public currentCheckpointTimestamp: string = null
	public currentNoGpsScroller = null

	@ViewChild('gmap', { static: true }) gmapElement: any
	@ViewChild('cpListComponent', { static: true }) cpListComponent: CheckpointsListComponent

	map: google.maps.Map

	constructor(private coreSrvc: CoreService) {
		const userPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()

		// Set flag for global account
		this.isGlobalAccount = this.coreSrvc.dbSrvc.settingSrvc.isGlobalAccount()
		this.isDesktop = this.coreSrvc.devDetect.isDesktop()

		this.canAdminCreateCheckpoints = userPrefs.transEnableAdminCreatedCheckpoints
	}

	get hasMarkers(): boolean {
		return !!this.jobSiteMarker || this.checkpointMarkers?.length > 0
	}

	get checkpointHasGps(): boolean {
		const cp = this.currentCheckpoint
		return !!cp?.geo_latitude
	}

	ngOnInit() {
		// const transId = parseInt(this.transId, 10)
		const transId = this.transId
		this.trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(transId)
		this.timezone = this.trans.timezone
		const request = new CheckpointDataAccessRequest(transId)
		this.coreSrvc.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			const data = result.data
			const checkpoints = data.map((rec) => new Checkpoint(rec))
			checkpoints.forEach((cp) => (cp.showImages = true))
			this.checkpoints = checkpoints
			// this.debugThis()
			// this.loadImages() // DEP

			this.setupMapData()
			setTimeout(() => {
				if (this.isMapValid()) {
					this.enableTooltips()
					this.initMap()
					this.addJobSiteMarker()
					this.addCheckpointMarkers()
					this.setLastCheckpointMarker()
					// this.setInitialViewState()
					// this.zoomToFitAllMarkers()
					setTimeout(() => {
						this.panToLastCheckpointMarker()
					}, 150)
				}
			}, 300)
		})
	}

	debugThis() {
		const cps = this.checkpoints
		// log('Checkpoints', cps)
		cps.forEach((cp) => {
			// log('ImagesJson', cp.images_json)
			const images = cp.getImages()
			// log('Images for checkpoint', images)
		})
	}

	ngAfterViewInit() {
		const element: any = $('#checkpointMapModal')
		element.modal('show')
		// DisplayHelper.setHtmlScrolling(false)
	}

	ngOnDestroy() {
		const element: any = $('#checkpointMapModal')
		element.modal('hide')
		// DisplayHelper.setHtmlScrolling(true)
	}

	enableTooltips() {
		const itemTooltip: any = $('.item-tooltip')
		itemTooltip.tooltip({ show: { effect: 'none', delay: 0 } })
	}

	setupMapData() {
		const trans = this.trans
		if (!trans) {
			return
		}

		const jobSiteId = trans.jobsite_id
		const jobSite = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(jobSiteId)

		this.centerLat = jobSite?.geo_latitude
		this.centerLng = jobSite?.geo_longitude
	}

	isMapValid(): boolean {
		return true
	}

	calculateZoom() {
		return 16
	}

	initMap() {
		const showExtraControls = true // !this.isMobile
		const mapProp = {
			mapId: 'checkpointMap',
			gestureHandling: 'greedy',
			center: this.centerLat && this.centerLng ? new google.maps.LatLng(this.centerLat, this.centerLng) : null,
			zoom: this.calculateZoom(),
			maxZoom: 18,
			zoomControl: showExtraControls,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			streetViewControl: false,
		}
		this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp)

		this.map.addListener('click', () => {
			this.closeAllInfoWindows()
		})
	}

	addJobSiteMarker() {
		const jobSiteId = this.trans.jobsite_id
		const site = this.coreSrvc.dbSrvc.siteSrvc.getJobSiteById(jobSiteId)
		if (!site || !site.geo_latitude) return

		const result = MapHelper.buildJobSiteMarker(this.map, site)
		const marker = result.marker
		const infoWindow = result.infoWindow

		marker.addListener('click', () => {
			if (this.isInfoWindowOpen(infoWindow)) {
				infoWindow.close()
			} else {
				this.closeAllInfoWindows()
				this.openInfoWindow(marker)
			}
		})

		// Save copy of infoWindow in marker and keep ref for marker
		marker['infoWindow'] = infoWindow
		this.openInfoWindow(marker)
		this.jobSiteMarker = marker
	}

	addCheckpointMarkers() {
		const timezone = this.trans.timezone
		const format12Hours = DateTimeHelper.format12Hour
		let checkPointNum = 1
		for (const cp of this.checkpoints) {
			if (!cp.geo_latitude) {
				checkPointNum++
				continue
			}

			const includeImages = true // Global.coreSrvc.devDetect.isDesktop()
			const result = MapHelper.buildCheckpointMarker(this.map, cp, timezone, true, includeImages, checkPointNum, format12Hours)
			const marker = result.marker
			const infoWindow = result.infoWindow

			marker.addListener('click', () => {
				if (this.isInfoWindowOpen(infoWindow)) {
					infoWindow.close()
				} else {
					this.closeAllInfoWindows()
					this.openInfoWindow(marker)
				}
			})

			// Save copy of infoWindow in marker and save ref to marker
			marker['infoWindow'] = infoWindow
			marker['checkpointId'] = cp.id
			this.checkpointMarkers.push(marker)
			checkPointNum++
		}
	}

	setInitialViewState() {
		const marker = this.lastCheckpointMarker
		if (marker) {
			this.currentView = 'MAP'
		} else {
			this.currentView = 'LIST'
		}
	}

	getLastCheckpoint(): Checkpoint {
		const checkpoints = this.checkpoints
		const count = checkpoints.length
		if (count > 0) {
			return checkpoints[count - 1]
		}
		return null
	}

	setLastCheckpointMarker() {
		const lastCheckpoint = this.getLastCheckpoint()
		// log('Last Checkpoint', lastCheckpoint);
		if (lastCheckpoint) {
			this.checkpointMarkers.forEach((marker) => {
				if (marker['checkpointId'] === lastCheckpoint.id) {
					const infoWindow = marker['infoWindow']
					this.lastCheckpointMarker = marker
				}
			})
		}
	}

	toggleView() {
		const currentView = this.currentView
		if (currentView === 'MAP') {
			this.currentView = 'LIST'
			this.viewButtonTitle = 'Show Map'
		} else {
			this.currentView = 'MAP'
			this.viewButtonTitle = 'Show List'
		}
		this.openInfoWindow(this.currentOpenMarker)
		return false
	}

	panToLastCheckpointMarker() {
		this.closeAllInfoWindows()
		const marker = this.lastCheckpointMarker
		this.panToMarker(marker)
	}

	panToJobSiteMarker() {
		const message = this.isGlobalAccount
			? 'Address information is not available in global account, switch to company view to see job site marker.'
			: 'Address information for this job site is missing and may have been deleted. Reload your browser and try again.'
		if (!this.jobSiteMarker) {
			this.coreSrvc.notifySrvc.notify('error', 'Site Missing', message)
			return
		}
		this.closeAllInfoWindows()
		const marker = this.jobSiteMarker
		this.panToMarker(marker)
	}

	panToMarker(marker: google.maps.marker.AdvancedMarkerElement) {
		this.closeAllInfoWindows()
		if (marker) {
			const latLng = marker.position
			this.map.panTo(latLng)
			this.openInfoWindow(marker)
		}
	}

	openInfoWindow(marker: google.maps.marker.AdvancedMarkerElement) {
		if (!marker) return

		const infoWindow = marker['infoWindow']
		this.currentOpenMarker = marker
		this.setCheckpointIndexForMarker(marker)

		if (infoWindow) {
			infoWindow.open(this.map, marker)
			setTimeout(() => {
				MapHelper.initializeImageScroller()
				setTimeout(() => {
					MapHelper.updateImageScroller()
				}, 100)
			}, 100)
		}
	}

	setCheckpointIndexForMarker(marker: google.maps.marker.AdvancedMarkerElement) {
		const checkpointId = marker['checkpointId']
		const index = this.checkpoints.findIndex((cp) => cp.id === checkpointId)
		this.currentCheckpointIndex = index
		this.currentCheckpoint = this.checkpoints[index]
	}

	zoomToFitAllMarkers() {
		const markers = this.checkpointMarkers
		const jsMarker = this.jobSiteMarker
		const bounds = new google.maps.LatLngBounds()
		for (const marker of markers) {
			const position = marker.position
			bounds.extend(position)
		}
		if (jsMarker) {
			const position = jsMarker.position
			bounds.extend(position)
		}
		// this.map.setZoom(17)
		this.map.fitBounds(bounds)
	}

	showCheckpoint(cp: Checkpoint) {
		const cpId = cp.id
		const cpMarker = this.getCheckpointMarkerForId(cpId)
		this.closeAllInfoWindows()
		this.currentView = 'MAP'

		if (cpMarker) {
			this.panToMarker(cpMarker)
		} else {
			this.currentCheckpoint = cp
			this.currentCheckpointIndex = this.checkpoints.findIndex((c) => c.id === cpId)
			setTimeout(() => {
				this.setupNoGpsImageScrollerForCheckpointIndex()
			}, 100)
		}
	}

	showAuditLogDialog(cp: Checkpoint) {
		setTimeout(() => {
			const globalBridge: ComponentBridgeName = 'ngBridgeGlobal'
			const transId = cp.transaction_log_id
			const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(transId)
			const jobDate = trans.job_date
			const empName = trans.employee_name

			const bridge = window[globalBridge]
			bridge.showAuditLog('checkpoint', cp.id, jobDate + ' / ' + empName)
		}, 250)
		this.closeDialog.emit(true)

		// showAuditLog('${resource}',${recordId},'${footer ?? ''}')

		// const auditLink = TableActionFormatter.auditLinkGlobal(
		// 	'transaction_log',
		// 	recordId,
		// 	rec.job_date + ' / ' + rec.employee_name,
		// 	true,
		// 	!!rec.updated,
		// )
	}

	getCheckpointMarkerForId(id: number) {
		const marker = this.checkpointMarkers.find((m) => m['checkpointId'] === id)
		return marker
	}

	closeAllInfoWindows(clearCpIndex = true) {
		if (clearCpIndex) {
			this.currentCheckpointIndex = null
			this.currentCheckpoint = null
		}
		this.currentOpenMarker = null
		if (this.jobSiteMarker) {
			this.jobSiteMarker['infoWindow'].close()
		}
		this.checkpointMarkers.forEach((marker) => {
			marker['infoWindow'].close()
		})
	}

	isInfoWindowOpen(infoWindow) {
		const map = infoWindow.getMap()
		return map !== null && typeof map !== 'undefined'
	}

	onClose() {
		this.closeDialog.emit(true)
	}

	eventType(): string {
		if (this.linkType === 'start') {
			return 'Check-In'
		} else {
			return 'Check-Out'
		}
	}

	newCheckpoint() {
		this.cpListComponent.newCheckpoint()
	}

	recordUpdatedEventHandler() {
		this.recordUpdated.next(true)
	}

	// previousNav() {
	// 	// Use the currentOpenMarker to find the previous checkpoint using the checkpointMarkers array
	// 	// If you reach the beginning of the list then start at the end
	// 	const currentIndex = this.checkpointMarkers.findIndex((marker) => marker === this.currentOpenMarker)
	// 	if (currentIndex > 0) {
	// 		const previousCheckpoint = this.checkpointMarkers[currentIndex - 1]
	// 		if (previousCheckpoint) {
	// 			this.closeAllInfoWindows()
	// 			this.openInfoWindow(previousCheckpoint)
	// 		}
	// 	} else {
	// 		const previousCheckpoint = this.checkpointMarkers[this.checkpointMarkers.length - 1]
	// 		if (previousCheckpoint) {
	// 			this.closeAllInfoWindows()
	// 			this.openInfoWindow(previousCheckpoint)
	// 		}
	// 	}
	// }

	// nextNav() {
	// 	// Use the currentOpenMarker to find the next checkpoint using the checkpointMarkers array
	// 	// If you reach the end of the list then start at the beginning
	// 	const currentIndex = this.checkpointMarkers.findIndex((marker) => marker === this.currentOpenMarker)
	// 	if (currentIndex < this.checkpointMarkers.length - 1) {
	// 		const nextCheckpoint = this.checkpointMarkers[currentIndex + 1]
	// 		if (nextCheckpoint) {
	// 			this.closeAllInfoWindows()
	// 			this.openInfoWindow(nextCheckpoint)
	// 		}
	// 	} else {
	// 		const nextCheckpoint = this.checkpointMarkers[0]
	// 		if (nextCheckpoint) {
	// 			this.closeAllInfoWindows()
	// 			this.openInfoWindow(nextCheckpoint)
	// 		}
	// 	}
	// }

	nextCheckpoint() {
		const currentIndex = this.currentCheckpointIndex ?? this.checkpoints.length
		let marker = null
		if (currentIndex < this.checkpoints.length - 1) {
			const nextIndex = currentIndex + 1
			this.currentCheckpointIndex = nextIndex
			this.currentCheckpoint = this.checkpoints[nextIndex]
			const nextCheckpoint = this.checkpoints[nextIndex]
			marker = this.getCheckpointMarkerForId(nextCheckpoint.id)
		} else {
			const nextIndex = 0
			this.currentCheckpointIndex = nextIndex
			this.currentCheckpoint = this.checkpoints[nextIndex]
			const nextChecpoint = this.checkpoints[nextIndex]
			marker = this.getCheckpointMarkerForId(nextChecpoint.id)
		}
		if (marker) {
			this.closeAllInfoWindows(false)
			this.openInfoWindow(marker)
		} else {
			this.closeAllInfoWindows(false)
			this.setupNoGpsImageScrollerForCheckpointIndex()
		}
	}

	previousCheckpoint() {
		// This should match how nextItem works but go backward
		const currentIndex = this.currentCheckpointIndex
		let marker = null
		if (currentIndex > 0) {
			const previousIndex = currentIndex - 1
			this.currentCheckpointIndex = previousIndex
			this.currentCheckpoint = this.checkpoints[previousIndex]
			const previousCheckpoint = this.checkpoints[previousIndex]
			marker = this.getCheckpointMarkerForId(previousCheckpoint.id)
		} else {
			const previousIndex = this.checkpoints.length - 1
			this.currentCheckpointIndex = previousIndex
			this.currentCheckpoint = this.checkpoints[previousIndex]
			const previousCheckpoint = this.checkpoints[previousIndex]
			marker = this.getCheckpointMarkerForId(previousCheckpoint.id)
		}
		if (marker) {
			this.closeAllInfoWindows(false)
			this.openInfoWindow(marker)
		} else {
			this.closeAllInfoWindows(false)
			this.setupNoGpsImageScrollerForCheckpointIndex()
		}
	}

	setupNoGpsImageScrollerForCheckpointIndex() {
		const index = this.currentCheckpointIndex
		const checkpoint = this.checkpoints[index]
		if (checkpoint) {
			const format12Hours = DateTimeHelper.format12Hour
			this.currentCheckpointTimestamp = MapHelper.formatTimestamp(checkpoint.created, this.timezone, format12Hours, '/')
			const cpImages = checkpoint.imageList
			const imageScrollerHtml = MapHelper.buildImageScroller(cpImages, cpImages.length > 1)
			this.currentNoGpsScroller = imageScrollerHtml
			setTimeout(() => {
				MapHelper.initializeImageScroller()
				setTimeout(() => {
					MapHelper.updateImageScroller()
				}, 100)
			}, 100)
		}
	}
}
