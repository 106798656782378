import { log } from '@app/helpers'
import { RRule, RRuleSet, rrulestr } from 'rrule'

export interface RRuleCalculatorConfig {
	rRuleString: string
	timezone: string
	startTime: string
	endTime: string
	startDate: string
	endDate: string
}

export class RRuleCalculator {
	rRuleString: string = null
	rRule: RRule
	rRuleSet: RRuleSet

	timezone: string

	startTime: string
	endTime: string

	startDate: string
	endDate: string

	isAnytime = false

	constructor(data?: RRuleCalculatorConfig) {
		if (data) {
			this.updateRuleConfig(data)
		}
	}

	updateRuleConfig(data?: RRuleCalculatorConfig) {
		if (!data?.startDate) {
			return
		}
		this.rRuleString = data.rRuleString || ''
		// if (!this.rRuleString.includes('RRULE:')) { this.rRuleString = 'RRULE:' + this.rRuleString }
		this.timezone = data.timezone || ''
		this.startDate = data.startDate || ''
		this.startTime = data.startTime || ''
		this.endTime = data.endTime || ''
		this.isAnytime = this.startTime === this.endTime

		if (this.isAnytime) {
			this.startTime = '00:00:01'
			this.endTime = '23:59:59'
		}

		const dtStart = this.startDate.replace(/\-/g, '') + 'T' + this.startTime.replace(/\:/g, '')
		const ruleString = `DTSTART;TZID=${data.timezone}:${dtStart};\n${this.rRuleString}`
		// log('RULESTRING', ruleString)
		this.rRuleSet = rrulestr(ruleString, { forceset: true }) as RRuleSet
		this.rRule = this.rRuleSet.rrules()[0]
	}
}
