import { Component, Input, OnInit } from '@angular/core'
import { CoreService } from '@app/services'

import { EditFormJobSiteLocationManager } from '@app/models'
import { log } from '@app/helpers'

import { SelectItem } from 'primeng/api'

@Component({
    selector: 'app-job-site-edit-location',
    templateUrl: './job-site-edit-location.component.html',
    styleUrls: ['./job-site-edit-location.component.scss'],
    standalone: false
})
export class JobSiteEditLocationComponent implements OnInit {
	addressOptions: Array<SelectItem> = [
		{ label: 'Do not specify location', value: 'none' },
		{ label: 'Specify location using address', value: 'address' },
		{ label: 'Specify location using map marker', value: 'marker' },
	]

	public districtLabel = 'State'
	public districtOptions: Array<String> = []
	private districtList = []

	@Input() manager: EditFormJobSiteLocationManager

	constructor(private coreSrvc: CoreService) {
		this.districtList = this.coreSrvc.dbSrvc.siteSrvc.getDistricts([])
		this.districtLabel = this.coreSrvc.dbSrvc.settingSrvc.getDistrictLabel()
	}

	ngOnInit(): void {
		log('SHOWING LOCATION MANAGER')
	}

	public searchStates(event): void {
		const text: string = this.manager.address.district
		if (!text) {
			this.districtOptions = [...this.districtList]
			return
		}
		const filteredOptions = this.districtList.filter((opt) => opt.toLowerCase().includes(text.toLowerCase()))
		this.districtOptions = filteredOptions
	}
}
