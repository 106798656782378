<div class="public-container">
	<form novalidate (submit)="onNext()" autocomplete="off">
		<!-- <form class="form-signin" method="POST" action="#" role="form"> -->
		<div class="form-group">
			<h2 style="color: #777">Reset Password</h2>
		</div>
		<hr />
		<div *ngIf="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
		<br />
		<div *ngIf="true" class="form-group">
			<div class="row">
				<div class="col-4">
					<label class="region-label form-label"><span style="color: chocolate; font-size: 1.5rem; font-weight: 500">Region</span></label>
				</div>
				<div class="col-8">
					<select class="form-select form-select float-right" [(ngModel)]="countryCode" name="countryCode" (change)="formatPhone()">
						<option *ngFor="let code of countryCodeData" value="{{ code.id }}">{{ code.region }}</option>
						<!-- <option value="US">United States</option>
					<option value="CA">Canada</option>
					<option value="PR">Puerto Rico</option>
					<option value="GB">Great Britain</option>
					<option value="JE">Jersey (UK)</option> -->
					</select>
				</div>
			</div>
		</div>
		<div class="form-group">
			<label class="form-label" for="mobileNumber"> Mobile Number </label>
			<input
				id="mobileNumber"
				type="tel"
				maxlength="50"
				class="form-control"
				[class.is-valid]="mobileNumberValid"
				[(ngModel)]="mobile"
				[ngModelOptions]="{standalone: true}"
				(keyup)="formatPhone()"
			/>
		</div>
		<div class="form-group">
			<button (click)="onNext()" [disabled]="!isPhoneValid()" id="nextPage" type="button" class="btn btn-primary btn-block">Next</button>
		</div>

		<div class="alert alert-info alert-bg-gray">
			<div style="color: firebrick">
				Your device information and IP address <span *ngIf="ipAddress"><strong>{{ipAddress}}</strong></span> will be logged for any password reset
				attempt.
			</div>
			<div style="margin-top: 18px">
				Unauthorized access to accounts is prohibited under the Computer Fraud and Abuse Act (CFAA) (18 U.S.C. § 1030).
			</div>
			<div style="margin-top: 18px">Individuals attempting to reset an account without proper authorization may be subject to legal action.</div>
			<!-- <div *ngIf="ipAddress" style="font-weight: 600; margin-top: 20px">IP: {{ ipAddress }}</div> -->
		</div>

		<hr style="margin-top: 30px" />
		<p>
			<a [routerLink]="['/home/register']"> <i class="far fa-user"></i> Register</a> /
			<a [routerLink]="['/home/login']"> <i class="far fa-lock"></i> Log in</a>
		</p>
	</form>
</div>
