<!-- <button class="btn btn-sm btn-outline-secondary add-contact" (click)="addContact()">add contact</button> -->
<div *ngIf="isDefaultContact">
	<div *ngFor="let contact of contacts; let idx = index">
		<div [id]="contact.type + '_' + idx + '_HEAD'" class="ur-separator ur-report-sep">
			<a href="#" class="remove-link" (click)="removeContact(idx); (false)">remove the following {{ typeLabel }}</a>
		</div>
		<div class="form-group" *ngIf="showDatePicker(contact.type)">
			<div class="contact-label">{{ contactDateLabel(contact.type) }}</div>
			<app-date-time-picker
				[id]="contact.type + '_' + idx + '_CONTACTED'"
				[currentDate]="contact.date.value"
				[pickerType]="contact.date.type"
				[pickerMode]="'dialog'"
				[hour12Timer]="format12Hour"
				[format]="datePickerFormat"
				(pickerDidClose)="contact.date.value = $event"
			>
			</app-date-time-picker>
		</div>
		<div class="form-group">
			<div class="ur-skip-btn" *ngIf="!contact.name.value" (click)="contact.name.skip = !contact.name.skip">
				{{ contact.name.skip ? 'edit' : 'n/a' }}
			</div>
			<div class="contact-label" [class.ur-label-disabled]="contact.name.skip">{{ capitalize(typeLabel) }} Name</div>
			<div>
				<input
					class="form-control"
					[id]="contact.type + '_' + idx + '_NAME'"
					[class.ur-skip-input]="contact.name.skip"
					[disabled]="contact.name.skip"
					[placeholder]="contact.name.skip ? '- skipped -' : ''"
					[(ngModel)]="contact.name.value"
					(focus)="elementFocused($event)"
				/>
			</div>
		</div>
		<div class="form-group">
			<!-- <div *ngIf="!contact.phone.valid" class="invalid-icon"><i class="fa fa-ban"></i></div>
			<div *ngIf="contact.phone.value && contact.phone.valid" class="valid-icon"><i class="fa fa-check"></i></div> -->
			<div class="ur-skip-btn" *ngIf="!contact.phone.value" (click)="contact.phone.skip = !contact.phone.skip">
				{{ contact.phone.skip ? 'edit' : 'n/a' }}
			</div>
			<div class="ur-skip-btn" *ngIf="contact.phone.value && contact.phone.valid === false" (click)="phoneConfirmBtnClicked(contact.phone)">
				{{ contact.phone.confirmed ? 'edit' : 'confirm' }}
			</div>
			<div class="contact-label" [class.ur-label-disabled]="contact.phone.skip">{{ capitalize(typeLabel) }} Phone</div>
			<div>
				<input
					class="form-control"
					type="tel"
					[class.is-invalid]="contact.phone.value && !contact.phone.confirmed && contact.phone.valid === false"
					[class.is-valid]="contact.phone.value && contact.phone.valid === true"
					[class.ur-skip-input]="contact.phone.skip"
					[id]="contact.type + '_' + idx + '_PHONE'"
					[disabled]="contact.phone.skip || contact.phone.confirmed"
					[placeholder]="contact.phone.skip ? '- skipped -' : ''"
					[(ngModel)]="contact.phone.value"
					(focus)="contact.phone.valid = null; elementFocused($event)"
					(blur)="validatePhone(contact, 'phone')"
				/>
			</div>
			<div *ngIf="shouldShowPhoneAlertFor(contact.phone)" class="phone-alert">
				The phone number you entered does not appear to be valid for your region. Please check the number and tap 'Confirm' if it is valid.
			</div>
		</div>
		<div class="form-group">
			<div class="ur-skip-btn" *ngIf="!contact.email.value" (click)="contact.email.skip = !contact.email.skip">
				{{ contact.email.skip ? 'edit' : 'n/a' }}
			</div>
			<div class="contact-label" [class.ur-label-disabled]="contact.email.skip">{{ capitalize(typeLabel) }} Email</div>
			<div>
				<input
					class="form-control"
					type="email"
					[class.is-invalid]="contact.email.value && contact.email.valid === false"
					[class.is-valid]="contact.email.value && contact.email.valid === true"
					[class.ur-skip-input]="contact.email.skip"
					[id]="contact.type + '_' + idx + '_EMAIL'"
					[disabled]="contact.email.skip"
					[placeholder]="contact.email.skip ? '- skipped -' : ''"
					[(ngModel)]="contact.email.value"
					(focus)="contact.email.valid = null; elementFocused($event)"
					(blur)="validateEmail(contact, 'email')"
				/>
			</div>
		</div>
		<div class="add-link" *ngIf="!contact.notes" (click)="addNotes(contact)"><i class="fa fa-plus"></i> add notes for this {{ typeLabel }}</div>
		<div *ngIf="contact.notes" class="contact-notes form-group">
			<div class="contact-label">Contact Notes</div>
			<textarea class="form-control contact-textarea" [(ngModel)]="contact.notes.value"></textarea>
		</div>
		<div class="subtract-link" *ngIf="contact.notes" (click)="contact.notes = null">
			<i class="fa fa-minus"></i> remove notes for this {{ typeLabel }}
		</div>
	</div>
	<div class="add-link" *ngIf="!limit || contacts.length < limit" (click)="addContact()">
		<i class="fa fa-plus"></i> add {{ contacts.length > 0 ? 'another ' + typeLabel : typeLabel }} contact
	</div>
	<div *ngIf="contacts.length > 0" class="ur-separator ur-report-sep">
		<span class="end-sep-label">end of {{ typeLabel }} contacts</span>
	</div>
</div>

<!-- <button class="btn btn-sm btn-outline-secondary add-contact" (click)="addContact()">add contact</button> -->
<div *ngIf="type === 'DEPARTMENT'">
	<div *ngFor="let contact of departments; let idx = index">
		<div [id]="contact.type + '_' + idx + '_HEAD'" class="ur-separator ur-report-sep">
			<a href="#" class="remove-link" (click)="removeContact(idx); (false)">remove the following {{ typeLabel }}</a>
		</div>
		<div class="form-group">
			<div class="contact-label">Contacted On</div>
			<app-date-time-picker
				[id]="contact.type + '_' + idx + '_CONTACTED'"
				[currentDate]="contact.date.value"
				[pickerType]="contact.date.type"
				[pickerMode]="'dialog'"
				[hour12Timer]="format12Hour"
				[format]="datePickerFormat"
				(pickerDidClose)="contact.date.value = $event"
			>
			</app-date-time-picker>
		</div>
		<div class="form-group">
			<div class="ur-skip-btn" *ngIf="!contact.name.value" (click)="contact.name.skip = !contact.name.skip">
				{{ contact.name.skip ? 'edit' : 'n/a' }}
			</div>
			<div class="contact-label" [class.ur-label-disabled]="contact.name.skip">Name or Department</div>
			<div>
				<input
					class="form-control"
					[id]="contact.type + '_' + idx + '_NAME'"
					[class.ur-skip-input]="contact.name.skip"
					[disabled]="contact.name.skip"
					[placeholder]="contact.name.skip ? '- skipped -' : ''"
					[(ngModel)]="contact.name.value"
					(focus)="elementFocused($event)"
				/>
			</div>
		</div>
		<div class="form-group">
			<div class="ur-skip-btn" *ngIf="!contact.identifier.value" (click)="contact.identifier.skip = !contact.identifier.skip">
				{{ contact.identifier.skip ? 'edit' : 'n/a' }}
			</div>
			<div class="contact-label" [class.ur-label-disabled]="contact.identifier.skip">Identification Number</div>
			<div>
				<input
					class="form-control"
					[id]="contact.type + '_' + idx + '_IDENTIFIER'"
					[class.ur-skip-input]="contact.identifier.skip"
					[disabled]="contact.identifier.skip"
					[placeholder]="contact.identifier.skip ? '- skipped -' : 'Badge Number / Car ID'"
					[(ngModel)]="contact.identifier.value"
					(focus)="elementFocused($event)"
				/>
			</div>
		</div>
		<div class="form-group">
			<div class="ur-skip-btn" *ngIf="!contact.reportNumber.value" (click)="contact.reportNumber.skip = !contact.reportNumber.skip">
				{{ contact.reportNumber.skip ? 'edit' : 'n/a' }}
			</div>
			<div class="contact-label" [class.ur-label-disabled]="contact.reportNumber.skip">Report / Case Number</div>
			<div>
				<input
					class="form-control"
					[id]="contact.type + '_' + idx + '_CASENUMBER'"
					[class.ur-skip-input]="contact.reportNumber.skip"
					[disabled]="contact.reportNumber.skip"
					[placeholder]="contact.reportNumber.skip ? '- skipped -' : 'Tracking / Report / Case Number'"
					[(ngModel)]="contact.reportNumber.value"
					(focus)="elementFocused($event)"
				/>
			</div>
		</div>
		<div class="add-link" *ngIf="!contact.notes" (click)="addNotes(contact)"><i class="fa fa-plus"></i> add notes for this contact</div>
		<div *ngIf="contact.notes" class="contact-notes form-group">
			<div class="contact-label">Contact Notes</div>
			<textarea class="form-control contact-textarea" [(ngModel)]="contact.notes.value"></textarea>
		</div>
		<div class="subtract-link" *ngIf="contact.notes" (click)="contact.notes = null">
			<i class="fa fa-minus"></i> remove notes for this {{ typeLabel }}
		</div>
	</div>
	<div class="add-link" *ngIf="!limit || departments.length < limit" (click)="addContact()">
		<i class="fa fa-plus"></i> add {{ departments.length > 0 ? 'another ' + typeLabel : typeLabel }} contact
	</div>
	<div *ngIf="departments.length > 0" class="ur-separator ur-report-sep">
		<span class="end-sep-label">end of {{ typeLabel }} contacts</span>
	</div>
</div>

<div *ngIf="type === 'INJURED'">
	<div *ngFor="let contact of injured; let idx = index">
		<div [id]="contact.type + '_' + idx + '_HEAD'" class="ur-separator ur-report-sep">
			<a href="#" class="remove-link" (click)="removeContact(idx); (false)">remove the following {{ typeLabel }}</a>
		</div>
		<!-- <div class="form-group">
			<div class="contact-label">Injured Party Contacted On</div>
			<app-date-time-picker
				[currentDate]="contact.date.value"
				[pickerType]="contact.date.type"
				[pickerMode]="'dialog'"
				[hour12Timer]="format12Hour"
				[format]="datePickerFormat"
				(pickerDidClose)="contact.date.value = $event">
			</app-date-time-picker>
		</div> -->

		<div class="form-group">
			<div class="ur-skip-btn" *ngIf="!contact.name.value" (click)="contact.name.skip = !contact.name.skip">
				{{ contact.name.skip ? 'edit' : 'n/a' }}
			</div>
			<div class="contact-label" [class.ur-label-disabled]="contact.name.skip">Injured Party Name</div>
			<div>
				<input
					class="form-control"
					[id]="contact.type + '_' + idx + '_NAME'"
					[class.ur-skip-input]="contact.name.skip"
					[disabled]="contact.name.skip"
					[placeholder]="contact.name.skip ? '- skipped -' : ''"
					[(ngModel)]="contact.name.value"
					(focus)="elementFocused($event)"
				/>
			</div>
		</div>

		<div class="form-group">
			<div class="ur-skip-btn" *ngIf="!contact.phone.value" (click)="contact.phone.skip = !contact.phone.skip">
				{{ contact.phone.skip ? 'edit' : 'n/a' }}
			</div>
			<div class="ur-skip-btn" *ngIf="contact.phone.value && contact.phone.valid === false" (click)="phoneConfirmBtnClicked(contact.phone)">
				{{ contact.phone.confirmed ? 'edit' : 'confirm' }}
			</div>
			<div class="contact-label" [class.ur-label-disabled]="contact.phone.skip">Injured Party Phone</div>
			<div>
				<input
					class="form-control"
					type="tel"
					[id]="contact.type + '_' + idx + '_PHONE'"
					[class.is-invalid]="contact.phone.value && !contact.phone.confirmed && contact.phone.valid === false"
					[class.is-valid]="contact.phone.value && contact.phone.valid === true"
					[class.ur-skip-input]="contact.phone.skip"
					[disabled]="contact.phone.skip || contact.phone.confirmed"
					[placeholder]="contact.phone.skip ? '- skipped -' : ''"
					[(ngModel)]="contact.phone.value"
					(focus)="contact.phone.valid = null; elementFocused($event)"
					(blur)="validatePhone(contact, 'phone')"
				/>
			</div>
			<div *ngIf="shouldShowPhoneAlertFor(contact.phone)" class="phone-alert">
				The phone number you entered does not appear to be valid for your region. Please check the number and tap 'Confirm' if it is valid.
			</div>
		</div>

		<div class="form-group">
			<div class="ur-skip-btn" *ngIf="!contact.email.value" (click)="contact.email.skip = !contact.email.skip">
				{{ contact.email.skip ? 'edit' : 'n/a' }}
			</div>
			<div class="contact-label" [class.ur-label-disabled]="contact.email.skip">Injured Party Email</div>
			<div>
				<input
					class="form-control"
					type="email"
					[id]="contact.type + '_' + idx + '_EMAIL'"
					[class.is-invalid]="contact.email.value && contact.email.valid === false"
					[class.is-valid]="contact.email.value && contact.email.valid === true"
					[class.ur-skip-input]="contact.email.skip"
					[disabled]="contact.email.skip"
					[placeholder]="contact.email.skip ? '- skipped -' : ''"
					[(ngModel)]="contact.email.value"
					(focus)="contact.email.valid = null; elementFocused($event)"
					(blur)="validateEmail(contact, 'email')"
				/>
			</div>
		</div>

		<div class="row" style="margin-bottom: 25px; color: chocolate">
			<div class="col-10"><strong>Select YES if applicable</strong></div>
			<div class="col-2 text-right">
				<span style="margin-right: 5px"><strong>YES</strong></span>
			</div>
		</div>

		<div class="row ur-checkbox-row">
			<div class="col-10">
				<label class="form-check-label ur-checkbox-label" for="wasMedicalAssistanceRefused">Was medical assistance refused?</label>
			</div>
			<div class="col-2 text-right">
				<input
					id="wasMedicalAssistanceRefused"
					type="checkbox"
					class="form-check-input ur-checkbox"
					[(ngModel)]="contact.wasMedicalAssistanceRefused.value"
				/>
			</div>
		</div>

		<div class="row ur-checkbox-row">
			<div class="col-10">
				<label class="form-check-label ur-checkbox-label" for="wasMedicalAssistanceRequested">Was medical assistance requested?</label>
			</div>
			<div class="col-2 text-right">
				<input
					id="wasMedicalAssistanceRequested"
					type="checkbox"
					class="form-check-input ur-checkbox"
					[(ngModel)]="contact.wasMedicalAssistanceRequested.value"
				/>
			</div>
		</div>

		<div class="row ur-checkbox-row">
			<div class="col-10">
				<label class="form-check-label ur-checkbox-label" for="wasInjuredPartyWearingGlasses">Was injured party wearing glasses?</label>
			</div>
			<div class="col-2 text-right">
				<input
					id="wasInjuredPartyWearingGlasses"
					type="checkbox"
					class="form-check-input ur-checkbox"
					[(ngModel)]="contact.wasInjuredPartyWearingGlasses.value"
				/>
			</div>
		</div>

		<div class="row ur-checkbox-row">
			<div class="col-10">
				<label class="form-check-label ur-checkbox-label" for="wasInjuredPartyCarryingAnything">Was injured party carrying anything?</label>
			</div>
			<div class="col-2 text-right">
				<input
					id="wasInjuredPartyCarryingAnything"
					type="checkbox"
					class="form-check-input ur-checkbox"
					[(ngModel)]="contact.wasInjuredPartyCarryingAnything.value"
				/>
			</div>
		</div>

		<div *ngIf="contact.wasInjuredPartyCarryingAnything.value" class="form-group ur-checkbox-textarea">
			<textarea
				class="form-control ur-basic-textarea"
				[id]="contact.type + '_' + idx + '_DESCRIBE_CARRYING'"
				placeholder="Describe what injured party was carrying"
				[(ngModel)]="contact.wasInjuredPartyCarryingAnything.description"
				(focus)="elementFocused($event)"
			>
			</textarea>
		</div>

		<hr />

		<div class="form-group" style="margin-top: 15px">
			<div class="ur-skip-btn" *ngIf="!contact.describeInjuries.value" (click)="contact.describeInjuries.skip = !contact.describeInjuries.skip">
				{{ contact.describeInjuries.skip ? 'edit' : 'n/a' }}
			</div>
			<div class="ur-basic-label" [class.ur-label-disabled]="contact.describeInjuries.skip">Describe Injuries</div>
			<textarea
				class="form-control ur-basic-textarea"
				[id]="contact.type + '_' + idx + '_DESCRIBE_INJURIES'"
				[class.ur-skip-textarea]="contact.describeInjuries.skip"
				[disabled]="contact.describeInjuries.skip"
				[placeholder]="contact.describeInjuries.skip ? '- skipped -' : 'Describe injuries in detail.'"
				[(ngModel)]="contact.describeInjuries.value"
				(focus)="elementFocused($event)"
			>
			</textarea>
		</div>

		<div class="form-group" style="margin-top: 15px">
			<div
				class="ur-skip-btn"
				*ngIf="!contact.describeShoesOfInjuredParty.value"
				(click)="contact.describeShoesOfInjuredParty.skip = !contact.describeShoesOfInjuredParty.skip"
			>
				{{ contact.describeShoesOfInjuredParty.skip ? 'edit' : 'n/a' }}
			</div>
			<div class="ur-basic-label" [class.ur-label-disabled]="contact.describeShoesOfInjuredParty.skip">Describe Shoes</div>
			<textarea
				class="form-control ur-basic-textarea"
				[id]="contact.type + '_' + idx + '_DESCRIBE_SHOES'"
				[class.ur-skip-textarea]="contact.describeShoesOfInjuredParty.skip"
				[disabled]="contact.describeShoesOfInjuredParty.skip"
				[placeholder]="
					contact.describeShoesOfInjuredParty.skip ? '- skipped -' : 'Describe shoes of injured party in detail. What type of shoes were worn?'
				"
				[(ngModel)]="contact.describeShoesOfInjuredParty.value"
				(focus)="elementFocused($event)"
			>
			</textarea>
		</div>

		<div class="add-link" *ngIf="!contact.notes" (click)="addNotes(contact)"><i class="fa fa-plus"></i> add notes for this {{ typeLabel }}</div>
		<div *ngIf="contact.notes" class="contact-notes form-group">
			<div class="contact-label">Contact Notes</div>
			<textarea class="form-control contact-textarea" [(ngModel)]="contact.notes.value"></textarea>
		</div>
		<div class="subtract-link" *ngIf="contact.notes" (click)="contact.notes = null">
			<i class="fa fa-minus"></i> remove notes for this {{ typeLabel }}
		</div>
	</div>
	<div class="add-link" *ngIf="!limit || injured.length < limit" (click)="addContact()">
		<i class="fa fa-plus"></i> add {{ injured.length > 0 ? 'another ' + typeLabel : typeLabel }} contact
	</div>
	<div *ngIf="injured.length > 0" class="ur-separator ur-report-sep">
		<span class="end-sep-label">end of {{ typeLabel }} contacts</span>
	</div>
</div>
