import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { DataTablesHelper, DisplayHelper, GeneralTableHelper, JobTableFormatter, log, PhoneHelper, TableActionFormatter } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'
import {
	ComponentBridgeName,
	CrudAction,
	DialogManager,
	LocalPrefsData,
	LocalPrefsDialogData,
	LocalPrefsGroup,
	NotificationProfile,
	DatabaseTableName,
} from '@app/models'
import { CoreService, DatabaseService } from '@app/services'

import _ from 'lodash'
import { NotificationsFormatter } from '../notifications-formatter'
import { Subscription } from 'rxjs'

enum ProfileTableColumnIndex {
	id,
	name,
	linkedJobs,
	linkedNumbers,
	linkedEmails,
	notifyEmp,
	notifySup,
	actions,
}

@Component({
    selector: 'app-notification-profile-table',
    templateUrl: './profile-table.component.html',
    styleUrls: ['./profile-table.component.scss'],
    standalone: false
})
export class NotificationProfileTableComponent implements OnInit, OnDestroy, AfterViewInit {
	CrudAction = CrudAction
	bridgeName: ComponentBridgeName = 'ngBridgeNpProfileTable'

	accessHelper: AccessHelper
	canAccessAuditLog = false

	list: Array<NotificationProfile> = []
	hasDisabledItems = false

	editDialogManager = new DialogManager('npEditDialog', 'notificationProfileEditDialog')
	editAction = { recordId: null, action: 'edit' }
	deleteAction = {
		tableName: 'notification_profile' as DatabaseTableName,
		recordId: null,
		recordLabel: null,
		showDeleteRecordDialog: false,
	}

	linkedRecordsDialogManager = new DialogManager('linkedRecordsModal')
	linkedRecordsDialog = { records: [], filteredRecords: [], searchText: null }

	private npTable // : DataTables.DataTable;
	private defaultSortOrder = [[1, 'asc']]

	debounceRedrawTable = _.debounce(this.redrawTable, 300)

	public sectionPrefsDialogManager = new DialogManager('sectionPrefsDialog')

	private subs = new Subscription()

	constructor(private coreSrvc: CoreService) {
		this.setupSubscriptions()
		this.setupAccessPermissions()
		this.setupLocalPrefsDialog()
		this.setupLinkedRecordsDialogManager()
		this.loadData()
		log('DatabaseService UUID', this.coreSrvc.dbSrvc.uuid)
	}

	get dtFilterText(): string {
		const input = $('.search-field-input')
		return (input?.val() as string) || ''
	}

	public ngOnInit() {
		window[this.bridgeName] = this
	}

	public ngOnDestroy() {
		this.npTable['fixedHeader'].disable()
		window[this.bridgeName] = null
		this.subs.unsubscribe()
	}

	public ngAfterViewInit() {
		this.initTable()
		this.fetchAndReload()

		$('#npTable_filter input').attr('placeholder', ' filter')
		$('#npTable_filter input').addClass('search-field-input')

		// VALIDATE Performance
		$('#npTable_filter input').on('keyup', () => {
			this.debounceRedrawTable()
		})

		// this.enableAllTooltips()

		setTimeout(() => {
			$('#clear-search-icon').detach().appendTo('#npTable_filter label')
		})
	}

	private setupSubscriptions() {
		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	private setupLinkedRecordsDialogManager() {
		this.linkedRecordsDialogManager.isSubmitBtnVisible = false
		this.linkedRecordsDialogManager.cancelBtnLabel = 'Close'
	}

	private setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'notifications')
		const auditPerms = this.accessHelper.getPermissionsFor('notifications')
		this.canAccessAuditLog = auditPerms.access.read || auditPerms.owner.read
		log('Can Access Notifications', this.canAccessAuditLog)
	}

	private setupLocalPrefsDialog(): void {
		this.sectionPrefsDialogManager.headerLabel = 'Preferences'
		const columnVisibilityItems = LocalPrefsData.getNpTableColumnDisplayPrefs()
		const columnVisibilityGroup = new LocalPrefsGroup('Column Visibility', columnVisibilityItems)
		const dialogData = new LocalPrefsDialogData([columnVisibilityGroup])
		dialogData.localStorageKeyRemovalList = ['DataTables_npTable']
		this.sectionPrefsDialogManager.dialogData = dialogData
	}

	private handleScreenSizeChanges() {
		if (this.npTable) {
			this.npTable.columns.adjust().responsive.recalc()
			this.npTable.fixedHeader.adjust()
		}
	}

	public prefsDataSaved(): void {
		log('UpdatePrefsData')
		this.sectionPrefsDialogManager.isDialogVisible = false
		this.updateTable()
	}

	canPerformAction(action: CrudAction, isMyRecord: boolean): boolean {
		return this.accessHelper.canPerformAction(action, isMyRecord)
		// return true
	}

	isMyRecord(np: NotificationProfile) {
		return true
	}

	loadData() {
		this.list = this.coreSrvc.dbSrvc.npSrvc.getProfiles()
	}

	redrawTable() {
		this.npTable?.rows().invalidate().search(this.dtFilterText).draw(true)
		// this.enableAllTooltips()
	}

	private updateTable() {
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadData()
			this.updateColumns()
			this.hasDisabledItems = this.hasExpiredRecords()
		})
	}

	private updateColumns(): void {
		this.hasDisabledItems = this.hasExpiredRecords()

		const linkedJobs = this.coreSrvc.prefSrvc.data.npColLinkedJobsVisible
		const linkedNumbers = this.coreSrvc.prefSrvc.data.npColLinkedNumbersVisible
		const linkedEmails = this.coreSrvc.prefSrvc.data.npColLinkedEmailsVisible
		const notifyEmp = this.coreSrvc.prefSrvc.data.npColEmpNotificationsVisible
		const notifySup = this.coreSrvc.prefSrvc.data.npColSupNotificationsVisible

		GeneralTableHelper.updateColumn(this.npTable, ProfileTableColumnIndex.linkedJobs, linkedJobs)
		GeneralTableHelper.updateColumn(this.npTable, ProfileTableColumnIndex.linkedNumbers, linkedNumbers)
		GeneralTableHelper.updateColumn(this.npTable, ProfileTableColumnIndex.linkedEmails, linkedEmails)
		GeneralTableHelper.updateColumn(this.npTable, ProfileTableColumnIndex.notifyEmp, notifyEmp)
		GeneralTableHelper.updateColumn(this.npTable, ProfileTableColumnIndex.notifySup, notifySup)

		this.npTable.clear()
		this.npTable.rows.add(this.list)
		this.npTable.draw(false)
	}

	fetchAndReload() {
		this.coreSrvc.dbSrvc.readTable('notification_profile').then((readSuccess) => {
			this.updateTable()
		})
	}

	saveActionComplete(event) {
		log('Profile Save Action Complete', event)
		this.editDialogManager.isDialogVisible = false
		this.coreSrvc.dbSrvc.bulkRead(['job', 'notification_profile']).then((success) => {
			this.updateTable()
		})
		// this.editAction.showEditDialog = false
	}

	editActionCancelled() {
		log('Emp Table Cancel Action Complete')
		// this.editAction.showEditDialog = false
	}

	createRecord() {
		if (!this.canPerformAction(CrudAction.create, true)) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.editAction.recordId = null
		this.editAction.action = 'new'
		this.editDialogManager.headerLabel = 'Add Profile'
		this.editDialogManager.isDialogVisible = true
	}

	editRecord(id: number) {
		log('Edit', id)
		const isMyRecord = true
		if (!this.canPerformAction(CrudAction.update, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}
		this.editAction.recordId = id
		this.editAction.action = 'edit'
		this.editDialogManager.headerLabel = 'Edit Profile'
		this.editDialogManager.helpVideoKey = 'notificationsEditDialog'
		this.editDialogManager.isDialogVisible = true
	}

	cloneRecord(id: number) {
		log('Clone', id)
		// const isMyRecord = true
		// if (!this.canPerformAction(CrudAction.read, isMyRecord)) { this.notifyOperationNotAuthorized(); return }
		this.editAction.recordId = id
		this.editAction.action = 'clone'
		this.editDialogManager.headerLabel = 'Clone Profile'
		this.editDialogManager.isDialogVisible = true
	}

	deleteRecord(id: number) {
		const isMyRecord = true
		if (!this.canPerformAction(CrudAction.delete, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}
		const np = this.coreSrvc.dbSrvc.npSrvc.getProfileById(id)

		// Check for existing job links before deletion
		const jobsWithProfile = np.job_ids
		const count = jobsWithProfile.length
		if (jobsWithProfile.length > 0) {
			this.coreSrvc.notifySrvc.notify(
				'error',
				'Profile Linked',
				`This profile is in use by ${count} job${count > 1 ? 's' : ''} and must first be unlinked before deleting.`,
			)
			return
		}

		if (np) {
			this.deleteAction.recordId = id
			this.deleteAction.recordLabel = np.name
			this.deleteAction.tableName = 'notification_profile'
			this.deleteAction.showDeleteRecordDialog = true
		}
	}

	deleteActionCancel() {
		this.deleteAction.showDeleteRecordDialog = false
	}

	deleteActionComplete() {
		setTimeout(() => {
			this.deleteAction.showDeleteRecordDialog = false
			this.updateTable()
		}, 1000)
	}

	public clearSearch(): boolean {
		this.npTable?.search('').order(this.defaultSortOrder).rows().invalidate().draw()
		this.coreSrvc.displaySrvc.enableAllTooltips()
		return false
	}

	private hasExpiredRecords(): boolean {
		return false
	}

	notifyOperationNotAuthorized() {
		this.coreSrvc.notifySrvc.default('operationNotAuthorized')
	}

	public filterLinkedRecords(searchText: string) {
		log('Search', searchText)
		if (!searchText) {
			this.linkedRecordsDialog.filteredRecords = [...this.linkedRecordsDialog.records]
		} else {
			this.linkedRecordsDialog.filteredRecords = this.linkedRecordsDialog.records.filter((rec: string) =>
				rec.toLowerCase().includes(searchText.toLowerCase()),
			)
		}
	}

	public showLinkedRecords(id: number, type: string) {
		log('Show Linked Records')
		const np = this.coreSrvc.dbSrvc.npSrvc.getProfileById(id)
		let headerLabel = ''
		switch (type) {
			case 'jobs':
				headerLabel = 'Linked Jobs'
				this.linkedRecordsDialog.records = np.job_ids
					.map((jid) => this.coreSrvc.dbSrvc.jobSrvc.getJobById(jid))
					.filter((job) => !!job)
					.map((job) => (!job.active ? `[INACTIVE] - ${job.description}` : job.description))
				// this.linkedRecordsDialog.records = this.coreSrvc.dbSrvc.jobSrvc
				// 	.getJobs()
				// 	.filter((j) => j.notification_profile_id === id)
				// 	.map((lj) => lj.description)
				this.linkedRecordsDialog.filteredRecords = [...this.linkedRecordsDialog.records]
				break
			case 'numbers':
				headerLabel = 'Linked Phone Numbers'
				const clientNumbers = np.client_phone_e164?.split(',') ?? []
				const formattedClientNumbers = clientNumbers
					.map((num) => ({
						phone: PhoneHelper.formatPhoneFromE164(num),
						client: this.coreSrvc.dbSrvc.orgSrvc.getOrganizationByE164Number(num),
					}))
					.map((rec) => `Client: ${rec.phone} ${rec.client ? '/ ' + rec.client.name : ''}`)

				const userNumbers = np.supervisor_phone_e164?.split(',') ?? []
				const formattedUserNumbers = userNumbers
					.map((num) => ({
						phone: PhoneHelper.formatPhoneFromE164(num),
						user: this.coreSrvc.dbSrvc.settingSrvc.getUserForE164Number(num),
					}))
					.map((rec) => `Supervisor: ${rec.phone} ${rec.user ? '/ ' + rec.user.first_name + ' ' + rec.user.last_name : ''}`)

				const formattedNumberList = [...formattedClientNumbers, ...formattedUserNumbers]
				this.linkedRecordsDialog.records = formattedNumberList
				this.linkedRecordsDialog.filteredRecords = formattedNumberList
				break
			case 'emails':
				headerLabel = 'Linked Emails'
				const clientEmails = np.client_email?.split(',') ?? []
				const formattedClientEmails = clientEmails
					.map((email) => ({ email: email, client: this.coreSrvc.dbSrvc.orgSrvc.getOrganizationByEmailAddress(email) }))
					.map((rec) => `Client: ${rec.email} ${rec.client ? '/ ' + rec.client.name : ''}`)

				const userEmails = np.supervisor_email?.split(',') ?? []
				const formattedUserEmails = userEmails
					.map((email) => ({ email: email, user: this.coreSrvc.dbSrvc.settingSrvc.getUserForEmailAddress(email) }))
					.map((rec) => `Supervisor: ${rec.email} ${rec.user ? '/ ' + rec.user.first_name + ' ' + rec.user.last_name : ''}`)

				const formattedEmailList = [...formattedClientEmails, ...formattedUserEmails]
				this.linkedRecordsDialog.records = formattedEmailList
				this.linkedRecordsDialog.filteredRecords = formattedEmailList
				break
		}
		this.linkedRecordsDialogManager.headerLabel = headerLabel
		this.linkedRecordsDialogManager.isDialogVisible = true
	}

	public setPage(num: number) {
		this.npTable?.page(num)
	}

	private initTable() {
		this.coreSrvc.displaySrvc.startSectionLoader()
		this.npTable = $('#npTable').DataTable(<DataTables.Settings>{
			stateSave: false,
			responsive: true,
			processing: true,
			deferRender: true,
			paging: true,
			pageLength: 100,
			lengthChange: true,
			info: true,
			select: false,
			searching: true,
			fixedHeader: DataTablesHelper.fixedHeader,
			autoWidth: false,
			data: this.list,
			order: this.defaultSortOrder,
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{
					responsivePriority: 1,
					targets: [ProfileTableColumnIndex.name, ProfileTableColumnIndex.actions],
				},
				// {
				// 	responsivePriority: 2,
				// 	targets: [ ]
				// },
				{
					// ID
					targets: ProfileTableColumnIndex.id,
					visible: false,
					searchable: true,
					title: 'ID',
					data: null,
					render: (np: NotificationProfile, type, full, meta) => {
						const clientPhonesString = np.client_phone_e164 ?? ''
						return `${clientPhonesString}`
					},
				},
				{
					// Name
					targets: ProfileTableColumnIndex.name,
					visible: true,
					searchable: true,
					title: 'Profile Name',
					data: null,
					render: (np: NotificationProfile, type, full, meta) => {
						const wrapName = DisplayHelper.truncateForWrapDisplay(np.name)
						return `<div class="dtr-control-content">
							<div style="white-space:normal;line-height:1.2em;margin-top:3px;min-width:100px;max-width:400px;">${wrapName}</div>
						</div>`
					},
				},
				{
					// Linked Jobs
					targets: ProfileTableColumnIndex.linkedJobs,
					visible: this.coreSrvc.prefSrvc.data.npColLinkedJobsVisible,
					searchable: this.coreSrvc.prefSrvc.data.npColLinkedJobsVisible,
					title: 'Linked<br>Jobs',
					data: null,
					render: (np: NotificationProfile, type, full, meta) => {
						return NotificationsFormatter.makeLinkedRecordsButton(this.coreSrvc.dbSrvc, this.bridgeName, this.dtFilterText, np.id, 'jobs')
					},
				},
				{
					// Linked Numbers
					targets: ProfileTableColumnIndex.linkedNumbers,
					visible: this.coreSrvc.prefSrvc.data.npColLinkedNumbersVisible,
					searchable: this.coreSrvc.prefSrvc.data.npColLinkedNumbersVisible,
					title: 'Linked<br>Numbers',
					data: null,
					render: (np: NotificationProfile, type, full, meta) => {
						return NotificationsFormatter.makeLinkedRecordsButton(this.coreSrvc.dbSrvc, this.bridgeName, this.dtFilterText, np.id, 'numbers')
					},
				},
				{
					// Linked Emails
					targets: ProfileTableColumnIndex.linkedEmails,
					visible: this.coreSrvc.prefSrvc.data.npColLinkedEmailsVisible,
					searchable: this.coreSrvc.prefSrvc.data.npColLinkedEmailsVisible,
					title: 'Linked<br>Emails',
					data: null,
					render: (np: NotificationProfile, type, full, meta) => {
						return NotificationsFormatter.makeLinkedRecordsButton(this.coreSrvc.dbSrvc, this.bridgeName, this.dtFilterText, np.id, 'emails')
					},
				},
				{
					// Employee Notifications
					targets: ProfileTableColumnIndex.notifyEmp,
					visible: this.coreSrvc.prefSrvc.data.npColEmpNotificationsVisible,
					searchable: false,
					title: 'Employee<br>Notifications',
					data: null,
					render: (np: NotificationProfile, type, full, meta) => {
						const profileId = np.id
						const profile = this.coreSrvc.dbSrvc.npSrvc.getProfileById(profileId) || new NotificationProfile()
						const html = JobTableFormatter.makeNotificationForEmployee(profile)
						return `<div style="display: inline-block;margin-left:10px;margin-top:4px;">${html}</div>`
					},
				},
				{
					// Supervisor Notifications
					targets: ProfileTableColumnIndex.notifySup,
					visible: this.coreSrvc.prefSrvc.data.npColSupNotificationsVisible,
					searchable: false,
					title: 'Supervisor<br>Notifications',
					data: null,
					render: (np: NotificationProfile, type, full, meta) => {
						const profileId = np.id
						const profile = this.coreSrvc.dbSrvc.npSrvc.getProfileById(profileId) || new NotificationProfile()
						const html = JobTableFormatter.makeNotificationForSupervisor(profile)
						return `<div style="display: inline-block;margin-left:10px;margin-top:4px;">${html}</div>`
					},
				},
				{
					// Actions
					targets: ProfileTableColumnIndex.actions,
					visible: true,
					searchable: false,
					orderable: false,
					title: 'Actions',
					data: null,
					className: 'row-actions',
					render: (rec: NotificationProfile, type, full, meta) => {
						const recordId = rec.id
						const isMyRecord = this.isMyRecord(rec)
						const hasLog = rec.updated ? true : false

						const canAccessEdit = this.canPerformAction(CrudAction.update, isMyRecord)
						const editLink = TableActionFormatter.editLink(this.bridgeName, 'editRecord', recordId, canAccessEdit)

						const canAccessClone = true
						const cloneLink = TableActionFormatter.cloneLink(this.bridgeName, 'cloneRecord', recordId, canAccessClone)

						const canAccessDelete = this.canPerformAction(CrudAction.delete, isMyRecord)
						const deleteLink = TableActionFormatter.deleteLink(this.bridgeName, 'deleteRecord', recordId, canAccessDelete)

						const auditLink = TableActionFormatter.auditLinkGlobal('notification_profile', recordId, rec.name, true, !!rec.updated)

						return `<span class="act-ico-wrap">${editLink}${cloneLink}${deleteLink}${auditLink}</span>`
					},
				},
			],
			// 'createdRow': (row, data, dataIndex) => {
			// 	const enabled = this.checkEnabledStatus(data as Vacation)
			// 	if (!enabled) {
			// 		$(row).addClass('vac-disabled')
			// 	}
			// }
			drawCallback: (settings) => {
				this.npTable?.columns.adjust().responsive.recalc()
				// this.enableAllTooltips()
				this.coreSrvc.displaySrvc.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
			},
		})
		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter('npTable', this.npTable)
	}
}
