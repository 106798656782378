import { log } from '@app/helpers/logger'
import { CoreService } from '@app/services'
import { DatabaseService } from '@app/services/backend/database.service'
import { CountryCode } from 'libphonenumber-js'
import _ from 'lodash'
import { JobSiteRecord } from './jobsite'

export class Address {
	isValid = false

	private country: string
	private components = {}

	constructor(geo_last_coding?: string) {
		if (!geo_last_coding) {
			return this
		}

		const parsedData = JSON.parse(geo_last_coding)
		const addressComponents = parsedData[0].address_components

		_.each(addressComponents, (k, v1) => {
			_.each(addressComponents[v1].types, (k2, v2) => {
				this.components[addressComponents[v1].types[v2]] = addressComponents[v1].short_name
			})
		})

		// If we're able to see the country then it's a valid address
		if (this.components['country']) {
			this.country = this.components['country']
			this.isValid = true
		}
	}

	static parseAddress(addr: string): any {
		if (!addr) {
			return null
		}
		// log('Address: ', addr)
		const result = {
			street: null,
			city: null,
			state: null,
			zip: null,
		}
		const components = addr.split(', ')
		// log('Components:', components)
		if (components.length < 4) {
			return result
		}
		const stateZip = components[2].split(' ')
		result.street = components[0]
		result.city = components[1]
		if (stateZip.length < 2) {
			return result
		}
		result.state = stateZip[0]
		result.zip = stateZip[1]
		if (stateZip[2]) {
			result.zip += ' ' + stateZip[2]
		}
		return result
	}

	static formatAddressStringAsHtml(addr: string): string {
		if (!addr) {
			return ''
		}
		const components = addr.split(', ')
		const last = components.pop()
		if (!last) {
			return ''
		}
		return components.join('<br>')
	}

	static getStreetAddress(addr: string): string {
		if (!addr) {
			return ''
		}
		const components = addr.split(', ')
		const compsLength = components.length
		const street = compsLength < 5 ? components[0] : components[1]
		return street ? street : ''
	}

	getStreet(): string {
		if (this.isValid) {
			let address1 = ''
			if (this.components['route']) {
				address1 = this.components['route']
				if (this.components['street_number']) {
					address1 = this.components['street_number'] + ' ' + address1
				}
				return address1
			}
		}
		return ''
	}

	getCity(): string {
		if (this.isValid) {
			switch (this.country) {
				case 'GB':
					return this.components['postal_town'] ? this.components['postal_town'] : ''
				default:
					return this.components['locality'] ? this.components['locality'] : ''
			}
		}
		return ''
	}

	getPostCode(): string {
		if (this.isValid) {
			if (this.components['postal_code']) {
				return this.components['postal_code']
			}
		}
		return ''
	}

	getCountry(): string {
		if (this.isValid) {
			return this.country
		}
		return ''
	}
}

export class MailingAddress {
	addr_1: string
	addr_2: string
	addr_3: string
	city: string
	state: string
	post_code: string
	country: string

	constructor(data?: any) {
		for (const attr in data) {
			if (data.hasOwnProperty(attr)) {
				this[attr] = data[attr]
			}
		}
	}

	static buildFromJsonSting(jsonString: string): MailingAddress {
		try {
			const parsedData = JSON.parse(jsonString)
			return new MailingAddress(parsedData)
		} catch (error) {
			log('Error parsing address', jsonString)
			return new MailingAddress()
		}
	}
}

export class LocationAddress {
	address_1: string
	address_2: string
	city: string
	district: string
	postal_code: string
	address_formatted: string
	geo_coded_ts: string // timestamp without time zone
	requires_geo_coding: boolean // boolean NOT NULL DEFAULT false

	constructor(record?: LocationAddress) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
		}
	}

	static buildFromSite(site: JobSiteRecord): LocationAddress {
		const address = new LocationAddress()
		address.address_1 = site.address_1
		address.address_2 = site.address_2
		address.city = site.city
		address.district = site.district
		address.postal_code = site.postal_code
		address.address_formatted = site.address_formatted
		address.geo_coded_ts = site.geo_coded_ts
		address.requires_geo_coding = site.requires_geo_coding
		return address
	}

	addressString(): string {
		let address = this.address_1
		address += this.address_2 ? `, ${this.address_2}` : ''
		address += this.city ? `, ${this.city}` : ''
		address += this.district ? ` ${this.district}` : ''
		address += this.postal_code ? `, ${this.postal_code}` : ''
		return address
	}
}

// Canada:

// 450 Broadway
// Winnipeg, MB R3C 1S4

// * administrative_area_level_1:"MB" (province)
// administrative_area_level_2:"Division No. 11"
// country:"CA"
// * locality:"Winnipeg"
// neighborhood:"Downtown"
// political:"CA"
// postal_code:"R3C 1S4"
// premise:"Winnipeg Legislature Building"
// * route:"Broadway"
// * street_number:"450"

// Great Brittain:

// 1 Great Russell St
// Fitzrovia, London
// WC1B 3ND
// UK

// administrative_area_level_1: "England"
// administrative_area_level_2: "Greater London"
// country: "GB"
// neighborhood: "Fitzrovia"
// political: "GB"
// postal_code: "WC1B 3ND"
// * postal_town: "London"
// * route: "Great Russell St"
// * street_number: "1"

// United States:

// administrative_area_level_1: "MN" (State)
// administrative_area_level_2: "Hennepin County"
// country: "US"
// locality: "Edina"
// neighborhood: "Southwest Minneapolis"
// political: "US"
// postal_code: "55410"
// route: "France Ave S"
// street_number: "5031"

export interface IFormAddressable {
	address_1: string
	address_2: string
	city: string
	district: string
	postal_code: string
	address_notes: string
}

export class FormAddress {
	address_1 = null
	address_2 = null
	city = null
	district = null
	postal_code = null
	address_notes = null

	constructor(record?: IFormAddressable) {
		if (record) {
			this.setAddress(record)
		}
	}

	public setAddress(record: IFormAddressable): void {
		this.address_1 = record.address_1
		this.address_2 = record.address_2
		this.city = record.city
		this.district = record.district
		this.postal_code = record.postal_code
		this.address_notes = record.address_notes
	}
}

export class FormAddressManager {
	hasAddress = false
	address = new FormAddress()

	required = ['address_1', 'city', 'district']

	constructor(private coreSrvc: CoreService) {}

	get isFormValid(): boolean {
		return this.hasAddress ? this.address.address_1 && this.address.city && this.address.district : true
	}

	public setAddress(record: IFormAddressable): void {
		this.address.address_1 = record.address_1
		this.address.address_2 = record.address_2
		this.address.city = record.city
		this.address.district = record.district
		this.address.postal_code = record.postal_code
		this.address.address_notes = record.address_notes

		if (this.address.address_1) {
			this.hasAddress = true
		}
	}

	// Provide the record to be updated. Returns true if form has met requirements
	public prepareForSubmission(record: IFormAddressable): boolean {
		if (this.hasAddress) {
			if (this.isFormValid) {
				record.address_1 = this.address.address_1
				record.address_2 = this.address.address_2
				record.city = this.address.city
				record.district = this.address.district
				record.postal_code = this.address.postal_code
				record.address_notes = this.address.address_notes
				return true
			} else {
				this.coreSrvc.notifySrvc.notify('error', 'Address Error', 'Address Line 1, City, and State / Province are required fields.')
				return false
			}
		} else {
			record.address_1 = null
			record.address_2 = null
			record.city = null
			record.district = null
			record.postal_code = null
			record.address_notes = null
			return true
		}
	}
}

export class AddressHelper {
	public states = []
	private lookupTable = new Map<string, string>()

	constructor(private dbSrvc: DatabaseService) {
		const countryCode = dbSrvc.settingSrvc.getCompany().country_iso
		const country = addressHelperCountryList.find((item) => item.alpha2Code === countryCode)
		if (country) {
			this.states = country.states
		}
		this.setupLookupTable()
	}
	public addressFormatter(record: IFormAddressable): string {
		if (!record.address_1) {
			return ' '
		}

		const district = this.lookupCode(record.district) ?? ''
		let result = `<div class="cell-addr-block">`
		result += `<div class="cell-addr-line">${record.address_1 ?? ''}</div>`
		result += record.address_2 ? `<div class="cell-addr-line">${record.address_2 ?? ''}</div>` : ``
		result += `<div class="cell-addr-line">${record.city ?? ''}, ${district} ${record.postal_code ?? ''}</div>`
		result += `</div>`

		return result
	}

	public mapFormatter(record: IFormAddressable): string {
		if (!record.address_1) {
			return ' '
		}

		const addrString = `${record.address_1},${record.city},${record.district},${record.postal_code}`
		const url = `https://maps.google.com/?q=${addrString}`
		const mapIcon = `
			<div class="cell-map-block">
				<a href="${url}" target="_address"><i class="fa fa-map cell-icon-lg link-text" title="Show Map"></i></a>
			</div>
		`

		return mapIcon
	}

	public lookupCode(name: string): string {
		const key = name || ''
		const code = this.lookupTable.get(key.toLowerCase())
		return code ? code : name
	}

	private setupLookupTable(): void {
		const states = addressHelperCountryList.flatMap((c) => c.states).filter((s) => s.code !== 'XX')
		for (const state of states) {
			this.lookupTable.set(state.name.toLocaleLowerCase(), state.code)
		}
		// log('States', states)
	}
}

const addressHelperCountryList = [
	{
		country: 'Canada',
		alpha2Code: 'CA',
		alpha3Code: 'CAN',
		numberCode: '124',
		states: [
			{ code: 'AB', name: 'Alberta' },
			{ code: 'BC', name: 'British Columbia' },
			{ code: 'MB', name: 'Manitoba' },
			{ code: 'NB', name: 'New Brunswick' },
			{ code: 'NL', name: 'Newfoundland and Labrador' },
			{ code: 'NT', name: 'Northwest Territories' },
			{ code: 'NS', name: 'Nova Scotia' },
			{ code: 'NU', name: 'Nunavut' },
			{ code: 'ON', name: 'Ontario' },
			{ code: 'PE', name: 'Prince Edward Island' },
			{ code: 'QC', name: 'Quebec' },
			{ code: 'SK', name: 'Saskatchewan' },
			{ code: 'YT', name: 'Yukon Territory' },
		],
	},
	{
		country: 'Mexico',
		alpha2Code: 'MX',
		alpha3Code: 'MEX',
		numberCode: '484',
		states: [
			{ code: 'AG', name: 'Aguascalientes' },
			{ code: 'BN', name: 'Baja California' },
			{ code: 'BS', name: 'Baja California Sur' },
			{ code: 'CP', name: 'Campeche' },
			{ code: 'CS', name: 'Chiapas' },
			{ code: 'CI', name: 'Chihuahua' },
			{ code: 'CH', name: 'Coahuila de Zaragoza' },
			{ code: 'CL', name: 'Colima' },
			{ code: 'DF', name: 'Distrito Federal' },
			{ code: 'DG', name: 'Durango' },
			{ code: 'GJ', name: 'Guanajuato' },
			{ code: 'GE', name: 'Guerrero' },
			{ code: 'HD', name: 'Hidalgo' },
			{ code: 'JA', name: 'Jalisco' },
			{ code: 'MX', name: 'Mexico' },
			{ code: 'MC', name: 'Michoacan de Ocampo' },
			{ code: 'MR', name: 'Morelos' },
			{ code: 'NA', name: 'Nayarit' },
			{ code: 'NL', name: 'Nuevo Leon' },
			{ code: 'OA', name: 'Oaxaca' },
			{ code: 'PU', name: 'Puebla' },
			{ code: 'QE', name: 'Queretaro de Arteaga' },
			{ code: 'QI', name: 'Quintana Roo' },
			{ code: 'SL', name: 'San Luis Potosi' },
			{ code: 'SI', name: 'Sinaloa' },
			{ code: 'SO', name: 'Sonora' },
			{ code: 'TB', name: 'Tabasco' },
			{ code: 'TA', name: 'Tamaulipas' },
			{ code: 'TL', name: 'Tlaxcala' },
			{ code: 'VC', name: 'Veracruz-Llave' },
			{ code: 'YU', name: 'Yucatan' },
			{ code: 'ZA', name: 'Zacatecas' },
		],
	},
	{
		country: 'United Kingdom',
		alpha2Code: 'GB',
		alpha3Code: 'GBR',
		numberCode: '826',
		states: [
			{ code: 'I0', name: 'Aberconwy and Colwyn' },
			{ code: 'I1', name: 'Aberdeen City' },
			{ code: 'I2', name: 'Aberdeenshire' },
			{ code: 'I3', name: 'Anglesey' },
			{ code: 'I4', name: 'Angus' },
			{ code: 'I5', name: 'Antrim' },
			{ code: 'I6', name: 'Argyll and Bute' },
			{ code: 'I7', name: 'Armagh' },
			{ code: 'I8', name: 'Avon' },
			{ code: 'I9', name: 'Ayrshire' },
			{ code: 'IB', name: 'Bath and NE Somerset' },
			{ code: 'IC', name: 'Bedfordshire' },
			{ code: 'IE', name: 'Belfast' },
			{ code: 'IF', name: 'Berkshire' },
			{ code: 'IG', name: 'Berwickshire' },
			{ code: 'IH', name: 'BFPO' },
			{ code: 'II', name: 'Blaenau Gwent' },
			{ code: 'IJ', name: 'Buckinghamshire' },
			{ code: 'XX', name: 'Caernarfonshire' },
			{ code: 'IM', name: 'Caerphilly' },
			{ code: 'IO', name: 'Caithness' },
			{ code: 'IP', name: 'Cambridgeshire' },
			{ code: 'IQ', name: 'Cardiff' },
			{ code: 'IR', name: 'Cardiganshire' },
			{ code: 'IS', name: 'Carmarthenshire' },
			{ code: 'IT', name: 'Ceredigion' },
			{ code: 'IU', name: 'Channel Islands' },
			{ code: 'IV', name: 'Cheshire' },
			{ code: 'IW', name: 'City of Bristol' },
			{ code: 'IX', name: 'Clackmannanshire' },
			{ code: 'IY', name: 'Clwyd' },
			{ code: 'IZ', name: 'Conwy' },
			{ code: 'J0', name: 'Cornwall/Scilly' },
			{ code: 'J1', name: 'Cumbria' },
			{ code: 'J2', name: 'Denbighshire' },
			{ code: 'J3', name: 'Derbyshire' },
			{ code: 'J4', name: 'Derry/Londonderry' },
			{ code: 'J5', name: 'Devon' },
			{ code: 'J6', name: 'Dorset' },
			{ code: 'J7', name: 'Down' },
			{ code: 'J8', name: 'Dumfries and Galloway' },
			{ code: 'J9', name: 'Dunbartonshire' },
			{ code: 'JA', name: 'Dundee' },
			{ code: 'JB', name: 'Durham' },
			{ code: 'JC', name: 'Dyfed' },
			{ code: 'JD', name: 'East Ayrshire' },
			{ code: 'JE', name: 'East Dunbartonshire' },
			{ code: 'JF', name: 'East Lothian' },
			{ code: 'JG', name: 'East Renfrewshire' },
			{ code: 'JH', name: 'East Riding Yorkshire' },
			{ code: 'JI', name: 'East Sussex' },
			{ code: 'JJ', name: 'Edinburgh' },
			{ code: 'JK', name: 'England' },
			{ code: 'JL', name: 'Essex' },
			{ code: 'JM', name: 'Falkirk' },
			{ code: 'JN', name: 'Fermanagh' },
			{ code: 'JO', name: 'Fife' },
			{ code: 'JP', name: 'Flintshire' },
			{ code: 'JQ', name: 'Glasgow' },
			{ code: 'JR', name: 'Gloucestershire' },
			{ code: 'JS', name: 'Greater London' },
			{ code: 'JT', name: 'Greater Manchester' },
			{ code: 'JU', name: 'Gwent' },
			{ code: 'JV', name: 'Gwynedd' },
			{ code: 'JW', name: 'Hampshire' },
			{ code: 'JX', name: 'Hartlepool' },
			{ code: 'HAW', name: 'Hereford and Worcester' },
			{ code: 'JY', name: 'Hertfordshire' },
			{ code: 'JZ', name: 'Highlands' },
			{ code: 'K0', name: 'Inverclyde' },
			{ code: 'K1', name: 'Inverness-Shire' },
			{ code: 'K2', name: 'Isle of Man' },
			{ code: 'K3', name: 'Isle of Wight' },
			{ code: 'K4', name: 'Kent' },
			{ code: 'K4', name: 'Kincardinshire' },
			{ code: 'K6', name: 'Kingston Upon Hull' },
			{ code: 'K7', name: 'Kinross-Shire' },
			{ code: 'K8', name: 'Kirklees' },
			{ code: 'K9', name: 'Lanarkshire' },
			{ code: 'KA', name: 'Lancashire' },
			{ code: 'KB', name: 'Leicestershire' },
			{ code: 'KC', name: 'Lincolnshire' },
			{ code: 'KD', name: 'Londonderry' },
			{ code: 'KE', name: 'Merseyside' },
			{ code: 'KF', name: 'Merthyr Tydfil' },
			{ code: 'KG', name: 'Mid Glamorgan' },
			{ code: 'KI', name: 'Mid Lothian' },
			{ code: 'KH', name: 'Middlesex' },
			{ code: 'KJ', name: 'Monmouthshire' },
			{ code: 'KK', name: 'Moray' },
			{ code: 'KL', name: 'Neath & Port Talbot' },
			{ code: 'KM', name: 'Newport' },
			{ code: 'KN', name: 'Norfolk' },
			{ code: 'KP', name: 'North Ayrshire' },
			{ code: 'KQ', name: 'North East Lincolnshire' },
			{ code: 'KR', name: 'North Lanarkshire' },
			{ code: 'KT', name: 'North Lincolnshire' },
			{ code: 'KU', name: 'North Somerset' },
			{ code: 'KV', name: 'North Yorkshire' },
			{ code: 'KO', name: 'Northamptonshire' },
			{ code: 'KW', name: 'Northern Ireland' },
			{ code: 'KX', name: 'Northumberland' },
			{ code: 'KZ', name: 'Nottinghamshire' },
			{ code: 'L0', name: 'Orkney and Shetland Isles' },
			{ code: 'L1', name: 'Oxfordshire' },
			{ code: 'L2', name: 'Pembrokeshire' },
			{ code: 'L3', name: 'Perth and Kinross' },
			{ code: 'L4', name: 'Powys' },
			{ code: 'L5', name: 'Redcar and Cleveland' },
			{ code: 'L6', name: 'Renfrewshire' },
			{ code: 'L7', name: 'Rhonda Cynon Taff' },
			{ code: 'L8', name: 'Rutland' },
			{ code: 'L9', name: 'Scottish Borders' },
			{ code: 'LB', name: 'Shetland' },
			{ code: 'LC', name: 'Shropshire' },
			{ code: 'LD', name: 'Somerset' },
			{ code: 'LE', name: 'South Ayrshire' },
			{ code: 'LF', name: 'South Glamorgan' },
			{ code: 'LG', name: 'South Gloucesteshire' },
			{ code: 'LH', name: 'South Lanarkshire' },
			{ code: 'LI', name: 'South Yorkshire' },
			{ code: 'LJ', name: 'Staffordshire' },
			{ code: 'LK', name: 'Stirling' },
			{ code: 'LL', name: 'Stockton On Tees' },
			{ code: 'LM', name: 'Suffolk' },
			{ code: 'LN', name: 'Surrey' },
			{ code: 'LO', name: 'Swansea' },
			{ code: 'LP', name: 'Torfaen' },
			{ code: 'LQ', name: 'Tyne and Wear' },
			{ code: 'LR', name: 'Tyrone' },
			{ code: 'LS', name: 'Vale Of Glamorgan' },
			{ code: 'LT', name: 'Wales' },
			{ code: 'LU', name: 'Warwickshire' },
			{ code: 'LV', name: 'West Berkshire' },
			{ code: 'LW', name: 'West Dunbartonshire' },
			{ code: 'LX', name: 'West Glamorgan' },
			{ code: 'LY', name: 'West Lothian' },
			{ code: 'LZ', name: 'West Midlands' },
			{ code: 'M0', name: 'West Sussex' },
			{ code: 'M1', name: 'West Yorkshire' },
			{ code: 'M2', name: 'Western Isles' },
			{ code: 'M3', name: 'Wiltshire' },
			{ code: 'M4', name: 'Wirral' },
			{ code: 'M5', name: 'Worcestershire' },
			{ code: 'M6', name: 'Wrexham' },
			{ code: 'M7', name: 'York' },
		],
	},
	{
		country: 'United States',
		alpha2Code: 'US',
		alpha3Code: 'USA',
		numberCode: '840',
		states: [
			{ code: 'AL', name: 'Alabama' },
			{ code: 'AK', name: 'Alaska' },
			{ code: 'AZ', name: 'Arizona' },
			{ code: 'AR', name: 'Arkansas' },
			{ code: 'CA', name: 'California' },
			{ code: 'CO', name: 'Colorado' },
			{ code: 'CT', name: 'Connecticut' },
			{ code: 'DE', name: 'Delaware' },
			{ code: 'DC', name: 'District of Columbia' },
			{ code: 'FM', name: 'Federated States of Micronesia' },
			{ code: 'FL', name: 'Florida' },
			{ code: 'GA', name: 'Georgia' },
			{ code: 'GU', name: 'Guam' },
			{ code: 'HI', name: 'Hawaii' },
			{ code: 'ID', name: 'Idaho' },
			{ code: 'IL', name: 'Illinois' },
			{ code: 'IN', name: 'Indiana' },
			{ code: 'IA', name: 'Iowa' },
			{ code: 'KS', name: 'Kansas' },
			{ code: 'KY', name: 'Kentucky' },
			{ code: 'LA', name: 'Louisiana' },
			{ code: 'ME', name: 'Maine' },
			{ code: 'MH', name: 'Marshall Islands' },
			{ code: 'MD', name: 'Maryland' },
			{ code: 'MA', name: 'Massachusetts' },
			{ code: 'MI', name: 'Michigan' },
			{ code: 'MN', name: 'Minnesota' },
			{ code: 'MS', name: 'Mississippi' },
			{ code: 'MO', name: 'Missouri' },
			{ code: 'MT', name: 'Montana' },
			{ code: 'NE', name: 'Nebraska' },
			{ code: 'NV', name: 'Nevada' },
			{ code: 'NH', name: 'New Hampshire' },
			{ code: 'NJ', name: 'New Jersey' },
			{ code: 'NM', name: 'New Mexico' },
			{ code: 'NY', name: 'New York' },
			{ code: 'NC', name: 'North Carolina' },
			{ code: 'ND', name: 'North Dakota' },
			{ code: 'MP', name: 'Northern Mariana Islands' },
			{ code: 'OH', name: 'Ohio' },
			{ code: 'OK', name: 'Oklahoma' },
			{ code: 'OR', name: 'Oregon' },
			{ code: 'PW', name: 'Palau' },
			{ code: 'PA', name: 'Pennsylvania' },
			{ code: 'PR', name: 'Puerto Rico' },
			{ code: 'RI', name: 'Rhode Island' },
			{ code: 'SC', name: 'South Carolina' },
			{ code: 'SD', name: 'South Dakota' },
			{ code: 'TN', name: 'Tennessee' },
			{ code: 'TX', name: 'Texas' },
			{ code: 'UT', name: 'Utah' },
			{ code: 'VT', name: 'Vermont' },
			{ code: 'VI', name: 'Virgin Islands' },
			{ code: 'VA', name: 'Virginia' },
			{ code: 'WA', name: 'Washington' },
			{ code: 'WV', name: 'West Virginia' },
			{ code: 'WI', name: 'Wisconsin' },
			{ code: 'WY', name: 'Wyoming' },
		],
	},
]

// https://gist.github.com/ebaranov/41bf38fdb1a2cb19a781
