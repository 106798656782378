import { Component, OnInit, OnDestroy, AfterViewInit, Renderer2, ChangeDetectorRef, Output, EventEmitter } from '@angular/core'
import { TableActionFormatter, log, DisplayHelper, GeneralTableHelper, DataTablesHelper } from '@app/helpers'
import {
	CrudAction,
	Client,
	DialogManager,
	DatabaseTableName,
	OrganizationRecord,
	DataAccessRequest,
	OrgTableDisplayState,
	SecureFileDialogData,
	AddressHelper,
	LinkedRecordsManager,
	JobRecord,
	ContactRecord,
	ContactOrganizationLinkCard,
	LinkContactOrgHostRecordUpdateEvent,
	LocalPrefsData,
	LocalPrefsDialogData,
	LocalPrefsGroup,
	organizationTypeOptions,
	TableFilterManager,
	TableFilterButton,
	ClickToCallRecord,
	ClickToCallGlobalConfig,
	ComponentBridgeName,
} from '@app/models'
import { AccessHelper } from '@app/helpers/access'
import { Subscription } from 'rxjs'
import { CoreService, DatabaseService } from '@app/services'
import _ from 'lodash'
import { OrganizationFormatter } from '../formatter'

enum OrganizationTableColumnIndex {
	id,
	name,
	type,
	linkedJobs,
	linkedContacts,
	phone,
	email,
	address,
	map,
	notifyPhone,
	notifyText,
	notifyEmail,
	notes,
	payRate,
	files,
	actions,
}

@Component({
    selector: 'app-organization-table',
    templateUrl: './organization-table.component.html',
    styleUrls: ['./organization-table.component.scss'],
    standalone: false
})
export class OrganizationTableComponent implements OnInit, OnDestroy, AfterViewInit {
	accessHelper: AccessHelper

	bridgeName: ComponentBridgeName = 'ngBridgeOrganizationTable'

	list: Array<OrganizationRecord> = []
	isDataLoaded = false
	isPrimaryOrInternalUser = false
	isPrimaryInternalOrManager = false

	editDialogManager = new DialogManager()
	editAction = { recordId: null, action: 'edit' }

	deleteAction = {
		tableName: 'vendor_client_org' as DatabaseTableName,
		recordId: null,
		recordLabel: null,
		showDeleteRecordDialog: false,
	}
	// adminPrefsDialog = { group: 'user', userId: null, header: '', footer: '', show: false }
	// adminPermsDialog = { userId: null, header: '', footer: '', show: false }

	linkedJobRecordsDialogManager = new DialogManager('linkedJobRecordsModal')
	linkedJobRecordsManager = new LinkedRecordsManager<JobRecord>()

	linkedContactRecordsDialogManager = new DialogManager('linkedContactRecordsModal')
	linkedContactRecordsManager = new LinkedRecordsManager<ContactOrganizationLinkCard>()

	secureFilesDialogManager = new DialogManager('secureFilesDialog')

	tableFilterManager = new TableFilterManager()

	showDetailsModal = false
	showFilterDatesModal = false
	showDiffRecordsModal = false

	canViewManagerColumn = false
	canConfigurePrefs = false
	highlightMissingExternalIds = false

	isManager = false
	managedUserIds = []

	isPayRateAvailable = false

	private organizationTable: any
	private defaultSortOrder = [[OrganizationTableColumnIndex.name, 'asc']]
	private defaultPageLength = 50

	public debounceRedrawTable = _.debounce(this.redrawTable, 300)

	private generalTableHelper: GeneralTableHelper
	private addressHelper: AddressHelper

	public sectionPrefsDialogManager = new DialogManager('sectionPrefsDialog')

	private clickToCallEnabled = { backend: false, browser: false } // Keep this. Need availability check here for buuttons

	private subs = new Subscription()
	constructor(
		private cd: ChangeDetectorRef,
		private coreSrvc: CoreService,
		private renderer: Renderer2,
	) {
		this.setupSubscriptions()
		this.setupC2CAvailability()
		this.setupAccessPermissions()
		this.setupLocalPrefsDialog()
		this.setupTableFilterManager()

		// Setup table helpers
		this.addressHelper = new AddressHelper(coreSrvc.dbSrvc)

		// Check for missing ID highlight requirement
		this.highlightMissingExternalIds = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany().globalHighlightMissingExtId
		this.isPayRateAvailable = this.coreSrvc.dbSrvc.settingSrvc.getCompany().pay_rate
	}

	get canAccessC2CBtns(): boolean {
		return this.clickToCallEnabled.backend || this.clickToCallEnabled.browser
	}

	// Linked Records Filter Methods
	get dtFilterText(): string {
		const input = $('.search-field-input')
		return (input?.val() as string) || ''
	}

	ngOnInit() {
		window[this.bridgeName] = this
		this.loadData()
	}

	ngOnDestroy() {
		this.organizationTable['fixedHeader'].disable()
		window[this.bridgeName] = null
		this.subs.unsubscribe()
	}

	ngAfterViewInit() {
		this.initTable()

		$('#organizationTable_filter input').attr('placeholder', ' filter')
		$('#organizationTable_filter input').addClass('search-field-input')

		setTimeout(() => {
			$('#clear-search-icon').detach().appendTo('#organizationTable_filter label')
		})

		// Handle count bubble search highlights
		$('#organizationTable_filter input').on('keyup', () => {
			this.debounceRedrawTable()
		})

		this.fetchAndReloadData()
	}

	private setupSubscriptions() {
		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}
	// Linked Records Filter Methods

	private setupC2CAvailability() {
		const portalPrefs = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany()
		const backEndEnabled = this.coreSrvc.dbSrvc.settingSrvc.isClickToCallEnabled()

		// Setup Click to Call availability
		this.clickToCallEnabled.backend = backEndEnabled
		this.clickToCallEnabled.browser = portalPrefs.globalBrowserClickToCall
	}

	private setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'organization')
		this.accessHelper.updateSupervisorIds()
	}

	private setupTableFilterManager() {
		const showAllBtn = new TableFilterButton()
		showAllBtn.id = 'ALL'
		showAllBtn.label = 'Show All'
		showAllBtn.count = null
		this.tableFilterManager.buttons.push(showAllBtn)

		const clientBtn = new TableFilterButton()
		clientBtn.id = 'CLIENTS'
		clientBtn.label = 'Client'
		clientBtn.count = () => this.coreSrvc.dbSrvc.orgSrvc.getOrganizations('CLIENT').length
		this.tableFilterManager.buttons.push(clientBtn)

		const vendorBtn = new TableFilterButton()
		vendorBtn.id = 'VENDORS'
		vendorBtn.label = 'Vendor'
		vendorBtn.count = () => this.coreSrvc.dbSrvc.orgSrvc.getOrganizations('VENDOR').length
		this.tableFilterManager.buttons.push(vendorBtn)

		const viewStateId = this.coreSrvc.dbSrvc.orgSrvc.viewManager.currentView
		this.tableFilterManager.setSelectedBtnById(viewStateId)
	}

	private setupLocalPrefsDialog(): void {
		this.sectionPrefsDialogManager.headerLabel = 'Organization Preferences'
		let columnVisibilityItems = LocalPrefsData.getOrgTableColumnDisplayPrefs()
		if (!this.isPayRateAvailable) columnVisibilityItems = columnVisibilityItems.filter((item) => item.key !== 'orgColPayRateVisible')
		const columnVisibilityGroup = new LocalPrefsGroup('Column Visibility', columnVisibilityItems)
		const dialogData = new LocalPrefsDialogData([columnVisibilityGroup])
		dialogData.localStorageKeyRemovalList = ['DataTables_organizationTable']
		this.sectionPrefsDialogManager.dialogData = dialogData
	}

	private handleScreenSizeChanges() {
		if (this.organizationTable) {
			this.organizationTable.columns.adjust().responsive.recalc()
			this.organizationTable.fixedHeader.adjust()
		}
	}

	public prefsDataSaved(): void {
		log('UpdatePrefsData')
		this.sectionPrefsDialogManager.isDialogVisible = false
		this.updateTable()
	}

	redrawTable() {
		this.organizationTable?.rows().invalidate().search(this.dtFilterText).draw(true)
		this.coreSrvc.displaySrvc.enableAllTooltips()
	}

	canPerformAction(action: CrudAction, isMyRecord: boolean) {
		return this.accessHelper.canPerformAction(action, isMyRecord)
	}
	isMyRecord(id: number): boolean {
		return true
	}

	enableAllTooltips() {
		// Enable Tooltips
		const tooltips: any = $('.item-tooltip')
		tooltips.tooltip({ show: { effect: 'none', delay: 0 } })
		// Delay long enough to hide tooltips if cursor was hovering during page load
		setTimeout(() => {
			tooltips.tooltip('hide')
		}, 1000)
	}

	clearSearch(): boolean {
		this.organizationTable
			.search('')
			.order([[OrganizationTableColumnIndex.name, 'asc']])
			.rows()
			.invalidate()
			.draw()
		return false
	}

	fetchData(): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			this.coreSrvc.dbSrvc.bulkRead(['contact', 'vendor_client_org']).then((result) => {
				this.coreSrvc.dbSrvc.orgSrvc.lastUpdated = new Date()
				resolve(true)
			})
		})
	}

	loadData() {
		const currentView = this.coreSrvc.dbSrvc.orgSrvc.viewManager.currentView
		this.list = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationsByViewState(currentView)
	}

	fetchAndReloadData() {
		this.fetchData().then((success) => {
			this.updateTable()
		})
	}

	updateColumns() {
		const type = this.coreSrvc.dbSrvc.orgSrvc.viewManager.currentView === OrgTableDisplayState.all
		const linkedContacts = this.coreSrvc.prefSrvc.data.orgColLinkedContactsVisible
		const linkedJobs = this.coreSrvc.prefSrvc.data.orgColLinkedJobsVisible
		const phone = this.coreSrvc.prefSrvc.data.orgColPhoneVisible
		const email = this.coreSrvc.prefSrvc.data.orgColEmailVisible
		const address = this.coreSrvc.prefSrvc.data.orgColAddressVisible
		const map = this.coreSrvc.prefSrvc.data.orgColMapVisible
		const notifyPhone = this.coreSrvc.prefSrvc.data.orgColNotifyPhoneVisible
		const notifyText = this.coreSrvc.prefSrvc.data.orgColNotifyTextVisible
		const notifyEmail = this.coreSrvc.prefSrvc.data.orgColNotifyEmaiVisible
		const notes = this.coreSrvc.prefSrvc.data.orgColNotesVisible
		const payRate = this.isPayRateAvailable && this.coreSrvc.prefSrvc.data.orgColPayRateVisible
		const files = this.coreSrvc.prefSrvc.data.orgColFilesVisible

		// Set column visibility
		GeneralTableHelper.updateColumn(this.organizationTable, OrganizationTableColumnIndex.type, type)
		GeneralTableHelper.updateColumn(this.organizationTable, OrganizationTableColumnIndex.linkedContacts, linkedContacts)
		GeneralTableHelper.updateColumn(this.organizationTable, OrganizationTableColumnIndex.linkedJobs, linkedJobs)
		GeneralTableHelper.updateColumn(this.organizationTable, OrganizationTableColumnIndex.phone, phone)
		GeneralTableHelper.updateColumn(this.organizationTable, OrganizationTableColumnIndex.email, email)
		GeneralTableHelper.updateColumn(this.organizationTable, OrganizationTableColumnIndex.address, address)
		GeneralTableHelper.updateColumn(this.organizationTable, OrganizationTableColumnIndex.map, map)
		GeneralTableHelper.updateColumn(this.organizationTable, OrganizationTableColumnIndex.notifyPhone, notifyPhone)
		GeneralTableHelper.updateColumn(this.organizationTable, OrganizationTableColumnIndex.notifyText, notifyText)
		GeneralTableHelper.updateColumn(this.organizationTable, OrganizationTableColumnIndex.notifyEmail, notifyEmail)
		GeneralTableHelper.updateColumn(this.organizationTable, OrganizationTableColumnIndex.notes, notes)
		GeneralTableHelper.updateColumn(this.organizationTable, OrganizationTableColumnIndex.payRate, payRate)
		GeneralTableHelper.updateColumn(this.organizationTable, OrganizationTableColumnIndex.files, files)

		const currentView = this.coreSrvc.dbSrvc.orgSrvc.viewManager.currentView
		const payRateTitle = currentView === 'CLIENTS' ? 'Bill Rate' : currentView === 'VENDORS' ? 'Pay Rate' : 'Bill/Pay Rate'
		this.organizationTable.columns(OrganizationTableColumnIndex.payRate).header().to$().text(payRateTitle)

		this.organizationTable.clear()
		this.organizationTable.rows.add(this.list)
		this.organizationTable.draw(false)
		// this.organizationTable.columns.adjust().responsive.recalc() // Moved to drawCallback
	}

	updateTable() {
		log('orgTable - updateTable')
		this.managedUserIds = this.coreSrvc.dbSrvc.settingSrvc.getManagedUserIds()

		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadData()
			this.updateColumns()
			this.coreSrvc.dbSrvc.contactSrvc.viewManager.isSectionWorking = false
		})
	}

	createRecord() {
		log('createRecord called')
		if (!this.canPerformAction(CrudAction.create, null)) {
			this.accessHelper.notifyOperationNotAuthorized()
			return
		}
		this.editAction.recordId = null
		this.editAction.action = 'new'
		this.editDialogManager.dialogData = this.editAction
		this.editDialogManager.headerLabel = 'Add Organization'
		this.editDialogManager.isDialogVisible = true
	}

	// Bridge Methods
	public editRecord(id: number) {
		log('editRecord called')
		const isMyRecord = this.isMyRecord(id)
		if (!this.canPerformAction(CrudAction.update, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}
		const org = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationById(id)
		if (org) {
			this.editAction.recordId = id
			this.editAction.action = 'edit'
			this.editDialogManager.headerLabel = 'Edit Organization' // org.name
			this.editDialogManager.dialogData = this.editAction
			this.editDialogManager.isDialogVisible = true
		}
	}

	openFilesDialog(id: number) {
		const isMyRecord = this.isMyRecord(id)
		if (!this.canPerformAction(CrudAction.update, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}
		const headerLabel = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationById(id).name
		this.secureFilesDialogManager.dialogData = new SecureFileDialogData('ORGANIZATION', id, null)
		this.secureFilesDialogManager.headerLabel = headerLabel
		this.secureFilesDialogManager.isDialogVisible = true
	}

	updateRecord(id: number) {
		log('Update Organization', id)
		if (id) {
			this.coreSrvc.dbSrvc.readRecord('vendor_client_org', id).then((result) => {
				this.loadData()
				const org = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationById(id)
				const idx = this.list.indexOf(org)
				this.organizationTable.rows(idx).invalidate().draw(false)
			})
		} else {
			this.updateTable()
		}
	}

	recordUpdated() {
		log('recordUpdaated: Client record has been updated')
		this.loadData()
		this.updateTable()
	}

	deleteRecord(id: number) {
		log('deleteRecord called')
		const isMyRecord = this.isMyRecord(id)
		if (!this.canPerformAction(CrudAction.delete, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}
		const org = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationById(id)
		const isLinked = this.coreSrvc.dbSrvc.jobSrvc.getJobsForOrganizationId(id).length > 0
		if (isLinked) {
			this.coreSrvc.notifySrvc.notify(
				'error',
				'Organization Assigned',
				'In order to delete this organization you must unlink them from all jobs.',
				5,
			)
			return
		}
		if (org) {
			this.deleteAction.recordId = org.id
			this.deleteAction.recordLabel = org.name
			this.deleteAction.tableName = 'vendor_client_org'
			this.deleteAction.showDeleteRecordDialog = true
		}
	}

	deleteActionComplete() {
		this.loadData()
		this.updateTable()
		this.deleteAction.showDeleteRecordDialog = false
	}

	deleteActionCancel() {
		this.deleteAction.showDeleteRecordDialog = false
	}

	// Manage Linked Records

	public filterLinkedRecords(searchText: string, type: string) {
		log('Search', searchText)
		if (!searchText) {
			this.linkedJobRecordsManager.filteredRecords = [...this.linkedJobRecordsManager.records]
		} else {
			switch (type) {
				case 'job':
					this.linkedJobRecordsManager.filteredRecords = this.linkedJobRecordsManager.records.filter((rec) =>
						rec.description.toLowerCase().includes(searchText),
					)
					break
			}
		}
	}

	public contactOrgRecordSaved(event: LinkContactOrgHostRecordUpdateEvent) {
		log('Contact Table - contactOrgRecordSaved', event)
		const orgId = event.id
		if (orgId) {
			this.coreSrvc.dbSrvc.readRecord('vendor_client_org', orgId).then(() => {
				const record = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationById(orgId)
				this.invalidateRowForRecord(record)
				this.buildContactOrgLinkData(orgId)
			})
		}
	}

	public showLinkedRecords(id: number, type: string) {
		let headerLabel = ''
		switch (type) {
			case 'job':
				this.linkedJobRecordsManager.records = this.coreSrvc.dbSrvc.jobSrvc.getJobsForOrganizationId(id)
				this.linkedJobRecordsManager.filteredRecords = [...this.linkedJobRecordsManager.records]
				headerLabel = 'Linked Jobs'
				this.linkedJobRecordsDialogManager.headerLabel = headerLabel
				this.linkedJobRecordsDialogManager.isSubmitBtnVisible = false
				this.linkedJobRecordsDialogManager.cancelBtnLabel = 'Close'
				this.linkedJobRecordsDialogManager.isDialogVisible = true
				break
			case 'contact':
				const record = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationById(id)
				headerLabel = 'Linked Contacts'
				this.buildContactOrgLinkData(id)
				this.linkedContactRecordsDialogManager.headerLabel = headerLabel
				this.linkedContactRecordsDialogManager.isSubmitBtnVisible = true
				this.linkedContactRecordsDialogManager.submitBtnLabel = 'New'
				this.linkedContactRecordsDialogManager.cancelBtnLabel = 'Close'
				this.linkedContactRecordsDialogManager.dialogData = record
				this.linkedContactRecordsDialogManager.isDialogVisible = true
				break
		}
	}

	private buildContactOrgLinkData(id: number) {
		this.linkedContactRecordsManager.records = this.coreSrvc.dbSrvc.orgSrvc
			.getOrganizationById(id)
			.vendor_client_contact.map((rec) => new ContactOrganizationLinkCard(this.coreSrvc.dbSrvc, rec, 'ORGANIZATION'))
		this.linkedContactRecordsManager.filteredRecords = [...this.linkedContactRecordsManager.records]
	}

	public jsBridgeToggleProperty(id: number, prop: string) {
		const isMyRecord = true // this.accessHelper.isMyRecord(id, 'organization')
		if (!this.canPerformAction(CrudAction.update, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}
		log('Toggling', id, prop)
		this.coreSrvc.workSrvc.blockWorking()
		const org = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationById(id)
		org[prop] = !org[prop]
		this.coreSrvc.dbSrvc.updateRecord('vendor_client_org', org).then((success) => {
			if (success) {
				this.invalidateRowForRecord(org)
			} else {
				org[prop] = !org[prop]
			}
			this.coreSrvc.workSrvc.unblockWorking()
		})
	}

	notifyOperationNotAuthorized() {
		this.coreSrvc.notifySrvc.default('operationNotAuthorized')
	}

	invalidateRowForRecord(org: OrganizationRecord) {
		log('Invalidated', org)
		const idx = this.list.indexOf(org)
		this.organizationTable.rows(idx).invalidate().draw(false)
	}

	changeSubsection(btn: TableFilterButton) {
		this.coreSrvc.dbSrvc.contactSrvc.viewManager.isSectionWorking = true
		setTimeout(() => {
			const id = btn.id as OrgTableDisplayState
			this.coreSrvc.dbSrvc.orgSrvc.viewManager.setViewStateForId(id)
			this.updateTable()
		}, 100)
	}

	public handleC2CEvent(id: number, column, action: 'CALL' | 'TEXT') {
		const record = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationById(id)
		const type = record.type === 'CLIENT' ? 'CLIENT' : 'VENDOR'
		let name = ''
		let number = ''
		if (column === 'PHONE') {
			name = record.name || 'Unknown'
			number = record.phone_e164
		}
		if (name && number) {
			const c2cRecord = new ClickToCallRecord(type, name, null, number, null)
			const config = new ClickToCallGlobalConfig(c2cRecord, action, null, null)
			this.coreSrvc.eventSrvc.clickToCallGlobalEvent.next(config)
		}
		log('handleC2CEvent', id, column, action, record)
	}

	// DataTables Initialization

	private initTable() {
		this.coreSrvc.displaySrvc.startSectionLoader()
		this.organizationTable = $('#organizationTable').DataTable(<DataTables.Settings>{
			stateSave: false,
			responsive: true,
			processing: true,
			paging: true,
			pageLength: this.defaultPageLength,
			lengthChange: true,
			info: true,
			select: false,
			searching: true,
			fixedHeader: DataTablesHelper.fixedHeader,
			autoWidth: false,
			data: this.list,
			order: this.defaultSortOrder,
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{
					responsivePriority: 1,
					targets: [OrganizationTableColumnIndex.name],
				},
				{
					responsivePriority: 2,
					targets: [OrganizationTableColumnIndex.phone],
				},
				{
					responsivePriority: 3,
					targets: [OrganizationTableColumnIndex.type],
				},
				{
					responsivePriority: 4,
					targets: [OrganizationTableColumnIndex.notifyPhone, OrganizationTableColumnIndex.notifyText, OrganizationTableColumnIndex.notifyEmail],
				},
				{
					responsivePriority: 5,
					targets: [OrganizationTableColumnIndex.notes],
				},
				{
					responsivePriority: 6,
					targets: [OrganizationTableColumnIndex.actions],
				},
				{
					// ID
					targets: OrganizationTableColumnIndex.id,
					visible: false,
					searchable: true,
					title: 'ID',
					data: null,
					render: (rec: Client, t, f, m) => {
						return rec.id
					},
				},
				{
					// Name
					targets: OrganizationTableColumnIndex.name,
					visible: true,
					searchable: true,
					title: 'Name',
					data: null,
					render: (rec: OrganizationRecord, t, f, m) => {
						const name = rec.name || ''
						const externalId = rec.external_id ? `<div class="table-ext-id-normal">${rec.external_id}</div>` : ''
						const noExternalId =
							this.highlightMissingExternalIds && !rec.external_id ? `<div class="table-tag bg-chocolate">No ID Set</div>` : ''

						const nameHtml = `<div>${name}</div>`

						return `<div class="dtr-control-content">
							<div class="table-name">${nameHtml}${externalId}${noExternalId}</div>
						</div>`
					},
				},
				{
					// Type
					targets: OrganizationTableColumnIndex.type,
					visible: true,
					searchable: true,
					title: 'Type',
					data: null,
					render: (rec: OrganizationRecord, t, f, m) => {
						const type = rec.type
						const tagClass = type === 'CLIENT' ? 'table-tag-client' : 'table-tag-vendor'
						const label = rec.type === 'CLIENT' ? 'Client' : 'Vendor'
						return `<span class="table-tag ${tagClass}" style="width:60px;">${label}</span>`
					},
				},
				{
					// Linked Jobs
					targets: OrganizationTableColumnIndex.linkedJobs,
					visible: this.coreSrvc.prefSrvc.data.orgColLinkedJobsVisible,
					searchable: false,
					title: 'Linked<br>Jobs',
					data: null,
					render: (rec: Client, t, f, m) => {
						// return this.makeLinkedRecordsButton(rec.id, 'job')
						return OrganizationFormatter.makeLinkedRecordsButton(this.coreSrvc.dbSrvc, this.bridgeName, this.dtFilterText, rec.id, 'job')
					},
				},
				{
					// Linked Contacts
					targets: OrganizationTableColumnIndex.linkedContacts,
					visible: this.coreSrvc.prefSrvc.data.orgColLinkedContactsVisible,
					searchable: false,
					title: 'Linked<br>Contacts',
					data: null,
					render: (rec: Client, t, f, m) => {
						return OrganizationFormatter.makeLinkedRecordsButton(this.coreSrvc.dbSrvc, this.bridgeName, this.dtFilterText, rec.id, 'contact')
					},
				},
				{
					// Phone
					targets: OrganizationTableColumnIndex.phone,
					visible: this.coreSrvc.prefSrvc.data.orgColPhoneVisible,
					searchable: this.coreSrvc.prefSrvc.data.orgColPhoneVisible,
					title: 'Phone',
					data: null,
					render: (rec: OrganizationRecord, t, f, m) => {
						return GeneralTableHelper.makePHoneNumberWithC2C(
							rec.id,
							rec.phone_e164,
							'PHONE',
							this.canAccessC2CBtns,
							this.bridgeName,
							'handleC2CEvent',
						)
						// const phone = rec.phone_e164
						// const formattedPhone = DisplayHelper.displayPhone(phone)
						// return `<div style="white-space: nowrap">${formattedPhone}</div>`
					},
				},
				{
					// Email
					targets: OrganizationTableColumnIndex.email,
					visible: this.coreSrvc.prefSrvc.data.orgColEmailVisible,
					searchable: this.coreSrvc.prefSrvc.data.orgColEmailVisible,
					title: 'Email',
					data: null,
					render: (rec: OrganizationRecord, t, f, m) => {
						return `<div class="table-email">${rec.email || ''}</div>`
					},
				},
				{
					// Address
					targets: OrganizationTableColumnIndex.address,
					visible: this.coreSrvc.prefSrvc.data.orgColAddressVisible,
					searchable: this.coreSrvc.prefSrvc.data.orgColAddressVisible,
					title: 'Address',
					data: null,
					render: (emp: OrganizationRecord, t, f, m) => {
						return this.addressHelper.addressFormatter(emp)
					},
				},
				{
					// Map
					targets: OrganizationTableColumnIndex.map,
					visible: this.coreSrvc.prefSrvc.data.orgColMapVisible,
					searchable: this.coreSrvc.prefSrvc.data.orgColMapVisible,
					title: 'Map',
					data: null,
					render: (emp: OrganizationRecord, t, f, m) => {
						return this.addressHelper.mapFormatter(emp)
					},
				},
				{
					// Notify Phone
					targets: OrganizationTableColumnIndex.notifyPhone,
					visible: this.coreSrvc.prefSrvc.data.orgColNotifyPhoneVisible,
					searchable: false,
					title: 'Notify<br>Phone',
					data: null,
					render: (rec: OrganizationRecord, t, f, m) => {
						return TableActionFormatter.toggleCheckbox(this.bridgeName, rec, 'notify_phone', '20px')
					},
				},
				{
					// Notify SMS
					targets: OrganizationTableColumnIndex.notifyText,
					visible: this.coreSrvc.prefSrvc.data.orgColNotifyTextVisible,
					searchable: false,
					title: 'Notify<br>Text',
					data: null,
					render: (rec: OrganizationRecord, t, f, m) => {
						return TableActionFormatter.toggleCheckbox(this.bridgeName, rec, 'notify_sms', '20px')
					},
				},
				{
					// Notify Email
					targets: OrganizationTableColumnIndex.notifyEmail,
					visible: this.coreSrvc.prefSrvc.data.orgColNotifyEmaiVisible,
					searchable: false,
					title: 'Notify<br>Email',
					data: null,
					render: (rec: OrganizationRecord, t, f, m) => {
						return TableActionFormatter.toggleCheckbox(this.bridgeName, rec, 'notify_email', '20px')
					},
				},
				{
					// Notes / Details
					targets: OrganizationTableColumnIndex.notes,
					visible: this.coreSrvc.prefSrvc.data.orgColNotesVisible,
					searchable: this.coreSrvc.prefSrvc.data.orgColNotesVisible,
					title: 'Notes',
					data: null,
					render: (rec: OrganizationRecord, t, f, m) => {
						const details = rec.details || ''
						return details ? `<div class="table-note-block"><div class="table-note-copy">${details}</div></div>` : ''
					},
				},
				{
					// Pay Rate
					targets: OrganizationTableColumnIndex.payRate,
					visible: this.isPayRateAvailable && this.coreSrvc.prefSrvc.data.orgColPayRateVisible,
					searchable: false,
					title: 'Bill/Pay Rate',
					data: null,
					render: (rec: OrganizationRecord, t, f, m) => {
						return TableActionFormatter.checkmarkLink(!!rec.pay_rate, false, 'prt-payrate-icon', 35, null)
					},
				},
				{
					// Files
					targets: OrganizationTableColumnIndex.files,
					visible: this.coreSrvc.prefSrvc.data.orgColFilesVisible,
					searchable: false,
					title: 'Files',
					data: null,
					render: (rec: OrganizationRecord, t, f, m) => {
						return GeneralTableHelper.makeFilesCountBubble(rec.id, rec.file_uploads_metadata, this.bridgeName, 'openFilesDialog')
					},
				},
				{
					// Actions
					targets: OrganizationTableColumnIndex.actions,
					visible: true,
					searchable: false,
					orderable: false,
					title: 'Actions',
					data: null,
					render: (rec: OrganizationRecord, t, f, m) => {
						const recordId = rec.id
						const isMyRecord = this.isMyRecord(rec.id)

						const canAccessEdit = this.accessHelper.canPerformAction(CrudAction.update, isMyRecord)
						const editLink = TableActionFormatter.editLink(this.bridgeName, 'editRecord', recordId, canAccessEdit)

						const canAccessDelete = this.accessHelper.canPerformAction(CrudAction.delete, isMyRecord)
						const deleteLink = TableActionFormatter.deleteLink(this.bridgeName, 'deleteRecord', recordId, canAccessDelete)

						const auditLink = TableActionFormatter.auditLinkGlobal('vendor_client_org', recordId, rec.name, true, !!rec.updated)

						return `<span class="act-ico-wrap">${editLink}${deleteLink}${auditLink}</span>`
					},
				},
			],
			drawCallback: (settings) => {
				this.organizationTable?.columns.adjust().responsive.recalc()
				this.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
			},
		})
		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter('organizationTable', this.organizationTable)
	}
}
