<div class="container section-container">
	<div class="sec-head-wrap">
		<div class="sec-act-ico-wrap">
			<i
				class="fas fa-plus sec-act-ico"
				[class.section-control-disabled]="!canPerformAction('create')"
				(click)="createRecord()"
				[title]="createRecordLabel"
			></i>
			<i class="far fa-cog sec-act-ico" (click)="togglePrefs()" title="Manage Columns"></i>
			<i class="far fa-question-circle sec-act-ico" (click)="toggleTooltips()" title="Show Help"></i>
		</div>

		<div class="sec-title-wrap">
			<i class="far fa-user sec-head-ico" aria-hidden="true"></i>
			<span>Supervisors</span>
			<app-sub-section-title [isVisible]="true" [subSectionTitle]="subSectionTitle"></app-sub-section-title>
		</div>
	</div>

	<div>
		<app-section-switcher [config]="switcherConfig" [type]="'NOCOUNT'" [maxWidth]="'260px'" (tabClicked)="sectionTabClicked($event)">
		</app-section-switcher>
	</div>

	<div *ngIf="showTooltips" class="card card-body tooltip-block tooltip-info-text tooltip-section-header">
		<button type="button" class="close" aria-label="Close" (click)="toggleTooltips()">
			<span aria-hidden="true">&times;</span>
		</button>

		<!-- Users Help -->
		<div *ngIf="manager.currentView === 'USERS'" class="mb-0">
			<div class="help-intro">
				The <strong>Users</strong> section deals with supervisors who manage company resources such as employees and jobs using this admin portal.
				The 5 basic types of supervisor you can setup are as follows:
				<div class="help-block">
					<span class="help-hl-1">Primary Supervisor</span> - has full access to all areas of the portal and cannot be restricted. You will
					generally only have 1 primary supervisor who may span multiple company accounts. The primary supervisor is generally the account owner,
					cannot be restricted, and will have full access to all areas of the portal.
				</div>
				<div class="help-block">
					<span class="help-hl-1">Managing Supervisor</span> - These supervisors can manage their own records along with the records of any
					secondary supervisors assigned to them. They can also have permissions assigned to restrict their portal access.
					<span *ngIf="!isManagingSupervisorAvailable">
						This supervisor type is available with the free <strong>Tiered Managment</strong> add-on. Please contact support for more
						information about this feature which is available if you need to have supervisors who manage other supervisors.</span
					>
				</div>
				<div class="help-block">
					<span class="help-hl-1">Secondary Supervisor</span> - can be configured with access permissions which restrict their access to
					information and areas of the the admin portal. They can also be assigned to a managing supervisor when tierd managemet is enabled.
				</div>
				<div class="help-block">
					<span class="help-hl-1">Non-Admin Supervisor</span> - cannot access the admin portal and is only used to provide supplemental
					information in reports.
				</div>
				<div class="help-block">
					<span class="help-hl-1">Read Only Supervisor</span> - cannot make any modifications to company data, regardless of their assigned
					permissions. This is a legacy supervisor type and is available for some specific use cases. You should use the secondary supervisor
					with the <strong>View Only</strong> role permissions template instead.
				</div>
			</div>
			<div class="help-block">
				<span class="help-hl-3">Dispatch:</span> Indicates if the supervisor is signed in as a dispatcher. This is part of the free Call Center
				add-on.
			</div>
			<div class="help-block">
				<span class="help-hl-3">Admin:</span> Indicates if the supervisor has access to this admin portal. Please contact support if you need to
				change this setting for a supervisor.
			</div>
			<div class="help-block mb-0">
				<span class="help-hl-3">Notify</span> Indicates whether or not the supervisor will be notified of various events which may be configured
				elsewhere in the portal such as late check in or check out.
			</div>
		</div>

		<!-- Groups Help -->
		<div *ngIf="manager.currentView === 'GROUPS'">
			<div class="help-intro">
				The <strong>Groups</strong> section deals with managing groups of supervisors. This group can then be assigned to various resources such
				as employees and jos. This allows any member of the group to manage those resources.
			</div>
		</div>

		<!-- Roles Help -->
		<div *ngIf="manager.currentView === 'ROLES'">
			<div class="help-intro">
				The <strong>Roles</strong> section deals with managing role permission templates. These templates group together a custom set of access
				permissions which can be assigned to any number of individual supervisors. Changes to a template will automatically be inherited by any
				supervisor using the template. If you have several supervisors or multiple company accounts, defining role templates will make managing
				supervisor access much simpler. Please contact support if you would like assistance setting up roles. <br /><br />The following system
				roles are available for your use or you may clone one of them and modify for your needs.
			</div>
			<div class="help-block">
				<span class="help-hl-1">Full Access</span> - This role allows full access to all areas of the admin portal. All functionality that is
				managed by a permission setting is enabled.
			</div>
			<div class="help-block">
				<span class="help-hl-1">Restricted Manager</span> - This role allows general access for a managining supervisor by enabling areas of the
				portal they would most likely need along with viewing their own assigned records and the records of supervisors they manage.
			</div>
			<div class="help-block">
				<span class="help-hl-1">Restricted Supervisor</span> - This role is similar to the restricted manager role with some areas of the portal
				locked down as they are not likely to be needed by a general supervisor.
			</div>
			<div class="help-block">
				<span class="help-hl-1">View Only</span> - This role allows read only table access to all supervisor's records while locking down most
				other areas of the portal.
			</div>
			<div class="help-block"><span class="help-hl-1">Agent Only</span> - This role allows access to the agent control panel only.</div>
			<div class="help-block"><span class="help-hl-1">No Access</span> - This role does not allow access to any areas of the portal.</div>
		</div>
	</div>

	<div *ngIf="!prefsVisible" class="users-block">
		<app-user-table #userTable *ngIf="manager.currentView === 'USERS'"></app-user-table>
		<app-user-group-table #groupTable *ngIf="manager.currentView === 'GROUPS'"></app-user-group-table>
		<app-user-role-table #roleTable *ngIf="manager.currentView === 'ROLES'"></app-user-role-table>
	</div>
</div>
