<div style="max-width: 600px; margin: 0 auto; margin-top: 75px; padding: 20px">
	<div class="card card-body">
		<div class="row">
			<div class="col-12 record-item">
				<strong class="settings-card-title"> <i class="fa fa-user" aria-hidden="true"></i> Close Account </strong>
				<hr />
			</div>
		</div>
		<div *ngIf="!showDialog && !isWorking" class="text-muted">
			<div class="row">
				<div class="col-12">
					<table>
						<tr>
							<td class="align-top">
								<p-checkbox [(ngModel)]="approveClose" [binary]="true"></p-checkbox>
							</td>
							<td style="width: 20px">&nbsp;</td>
							<td>
								<div class="has-pointer" style="line-height: 1.2em" (click)="approveClose = !approveClose">
									I understand that by selecting this checkbox and clicking the button below, I will be closing my Telephone Timesheets
									account.
								</div>
							</td>
						</tr>
					</table>
				</div>
			</div>
			<div class="row">
				<div class="col-12" style="line-height: 1.4em">
					<br />
					<strong>Note:</strong> After clicking the button below you will be logged off and your account will be closed. Once your account is
					closed, you will no longer have access to this account and all of the data associated with the account will be
					<strong style="color: firebrick">PERMANENTLY DELETED</strong>. Upon closing your account, you will no longer receive any emails or
					billing charges from Telephone Timesheets.
					<br />
					<br />
					<div>
						<button class="btn btn-sm btn-secondary" (click)="cancelBtnClicked()">Cancel</button>
						<button
							class="btn btn-sm btn-danger float-right"
							[disabled]="!approveClose || !canPerformAction(CrudAction.delete)"
							(click)="onCloseAccount()"
						>
							Close Account
						</button>
					</div>
				</div>
			</div>
		</div>

		<div style="text-align: center" *ngIf="showDialog && !isWorking">
			<div style="margin-bottom: 25px; font-size: 1.5em; font-weight: bold">Are You Sure?</div>
			<div>
				<button class="btn btn-sm btn-block btn-secondary" style="margin-bottom: 15px" (click)="onCancelClose()">Do Not Close Account</button>
			</div>
			<div><button class="btn btn-sm btn-block btn-danger" (click)="onConfirmClose()">Close Account</button></div>

			<br />
		</div>

		<div *ngIf="isWorking">
			<h2><i class="fas fa-loader fa-spin" aria-hidden="true"></i> Processing</h2>
		</div>
	</div>
</div>
