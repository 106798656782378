<!-- Approval dialog -->
<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Link Options"
	[(visible)]="approvalAction.isDialogVisible"
	[modal]="true"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<div>
		<div *ngIf="!approvalAction.isProcessing">
			<div class="psa-title tts-hl-2 text-truncate">{{ approvalAction.title }}</div>
			<div *ngIf="!approvalAction.showConfirmation">
				<textarea class="options-textarea" placeholder="Add an optional note then select an action" [(ngModel)]="approvalAction.notes"></textarea>
			</div>

			<div *ngIf="approvalAction.showConfirmation">
				<div class="action-confirm-block">
					<div *ngIf="!approvalAction.approveWithWorkorder" style="padding-top: 12px">
						Are you sure you want to {{ approvalAction.takeAction === 'APPROVE' ? 'approve' : 'reject' }} this schedule? This action cannot be
						undone.
					</div>
					<div *ngIf="approvalAction.approveWithWorkorder">
						<div style="margin-bottom: 12px">Email Work Order To:</div>
						<app-generic-email-list [manager]="approvalAction.approveWithWorkorderEmailListManager"></app-generic-email-list>
					</div>
				</div>
			</div>

			<!-- Approve with work order checkbox -->
			<div *ngIf="approvalAction.showConfirmation" class="checkbox-item d-flex justify-content-between" style="margin: 22px 0px 0px 0px">
				<div class="checkbox-wrap">
					<p-checkbox [(ngModel)]="approvalAction.approveWithWorkorder" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap approval-checkbox-label">
					<span (click)="approvalAction.approveWithWorkorder = !approvalAction.approveWithWorkorder" class="has-pointer"
						>Approve with work order</span
					>
					<i
						title="More Information"
						class="far fa-info-circle tts-info-btn"
						aria-hidden="true"
						(click)="showHelp('approve_with_workorder')"
					></i>
				</div>
			</div>

			<!-- Time Off conflict override checkbox -->
			<div *ngIf="approvalAction.showTimeOffOverrideCheckbox" class="checkbox-item d-flex justify-content-between" style="margin: 8px 0px 0px 0px">
				<div class="checkbox-wrap">
					<p-checkbox [(ngModel)]="approvalAction.confirmTimeOffOverride" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap approval-checkbox-label">
					<span (click)="approvalAction.confirmTimeOffOverride = !approvalAction.confirmTimeOffOverride" class="has-pointer tts-hl-1"
						>Override time-off conflict
					</span>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('timeoff_override')"></i>
				</div>
			</div>

			<!-- Overtime override checkbox -->
			<div
				*ngIf="approvalAction.showOvertimeOverrideCheckbox"
				class="checkbox-item d-flex justify-content-between"
				style="margin: 8px 0px 0px 0px"
			>
				<div class="checkbox-wrap">
					<p-checkbox [(ngModel)]="approvalAction.confirmOvertimeOverride" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap approval-checkbox-label">
					<span (click)="approvalAction.confirmOvertimeOverride = !approvalAction.confirmOvertimeOverride" class="has-pointer tts-hl-1"
						>Override overtime conflict
					</span>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('overtime_override')"></i>
				</div>
			</div>

			<div *ngIf="!approvalAction.showConfirmation" style="margin-top: 16px">
				<div class="float-right">
					<button class="btn btn-sm btn-secondary" (click)="approvalAction.isDialogVisible = false">Cancel</button>
					<button class="btn btn-sm btn-success psa-btn-pad" (click)="takeActionBtnClicked('UPDATE')">Update</button>
				</div>
				<div>
					<button class="btn btn-sm btn-outline-success" (click)="takeActionBtnClicked('APPROVE')">Approve</button>
				</div>
			</div>

			<div *ngIf="approvalAction.showConfirmation">
				<div style="margin-top: 22px">
					<div class="float-right">
						<button *ngIf="approvalAction.takeAction === 'APPROVE'" class="btn btn-sm btn-success psa-btn-pad" (click)="confirmTakeAction()">
							Approve
						</button>
						<button *ngIf="approvalAction.takeAction === 'REJECT'" class="btn btn-sm btn-danger psa-btn-pad" (click)="confirmTakeAction()">
							Reject
						</button>
					</div>
					<button class="btn btn-sm btn-secondary" (click)="approvalAction.showConfirmation = false">Cancel</button>
				</div>
			</div>
		</div>

		<div *ngIf="approvalAction.isProcessing" class="action-processing-block"><i class="fas fa-loader fa-spin"></i> Processing Action ...</div>
	</div>
</p-dialog>
