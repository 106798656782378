import { DateTimeHelper, Helper, PhoneHelper } from '@app/helpers'
import { C2CDirectoryLookup, IVRLogRecord, ClickToCallSourceTypeFormatter } from '@app/models'
import { DatabaseService } from '@app/services'

import _ from 'lodash'
import moment from 'moment-timezone'

export class CommLogIVRTableFormatter {
	dbSrvc: DatabaseService
	directory: C2CDirectoryLookup

	constructor(dbSrvc: DatabaseService) {
		this.dbSrvc = dbSrvc
		this.directory = new C2CDirectoryLookup(dbSrvc)
	}

	date(date: string, tz: string) {
		return moment.tz(date, tz).format('YYYY-MM-DD')
	}

	time(date: string, tz: string) {
		const format12Hr = DateTimeHelper.format12Hour
		const mom = moment.tz(date, tz)
		return format12Hr ? mom.format('h:mm a') : mom.format('HH:mm')
	}

	callLookup(num: string) {
		const lookup = this.directory.lookupNumber(num, ['USER', 'SYSTEM', 'LINKEDJOB'])
		let result = `
			<div>${lookup ? lookup.name : 'Unknown Caller'}</div>
			<div>${PhoneHelper.formatPhoneFromE164(num)}</div>
		`
		if (lookup) {
			const source = ClickToCallSourceTypeFormatter.getDescription(lookup.source)
			result += `
				<div class="tts-hl-2" style="font-weight: bold; font-size:small">${source}</div>
			`
		}
		return result
	}

	callFrom(rec: IVRLogRecord) {
		const desc = rec.from_desc ?? ''
		const number = rec.from_e164
		const result = `
		<div>${desc}</div>
		<div>${PhoneHelper.formatPhoneFromE164(number)}</div>
	`
		return result
	}

	callTo(rec: IVRLogRecord) {
		const companyName = rec.company_name
		const number = rec.to_e164
		const result = `
			<div>${companyName}</div>
			<div>${PhoneHelper.formatPhoneFromE164(number)}</div>
		`
		return result
	}

	viewCallLog(rec: IVRLogRecord, bridgeTable: string) {
		return `<i title="View Log" onclick="${bridgeTable}.viewCallLog(${rec.id})" class="far fa-file-lines act-ico act-ico-clr-green"></i>`
	}
}
