<div [id]="'cp-list-item-content-' + cpIdx">
	<div [hidden]="cp.isEditing" class="cp-icon-box">
		<i *ngIf="cp.geo_longitude" title="Show on map" class="fa fa-map-marker cp-icon cp-map-icon" (click)="showCheckpoint.next(cp)"> </i>
		<i
			*ngIf="!cp.geo_longitude"
			title="Show on map"
			class="fa fa-map-marker cp-icon cp-map-icon"
			style="color: #a8a7b3"
			(click)="showCheckpoint.next(cp)"
		>
		</i>
		<i
			*ngIf="doesJobHaveTour && !showCheckbox"
			title="Add to tour"
			class="fa fa-map-pin cp-icon cp-map-icon"
			(click)="showAddWaypointConfirmation()"
		>
		</i>
		<i *ngIf="!isGlobalAccount && !showCheckbox" title="Edit Note" class="fa fa-edit cp-icon cp-edit-icon" (click)="editCheckpoint(cp)"> </i>
		<i
			*ngIf="!isGlobalAccount && isDeleteAllowed && !showCheckbox"
			title="Delete Checkpoint"
			class="far fa-trash-alt cp-icon cp-delete-icon"
			(click)="showDeleteCheckpointConfirmation()"
		>
		</i>
		<i
			*ngIf="!isGlobalAccount && !showCheckbox"
			title="Audit Log"
			class="far fa-database cp-icon cp-audit-icon"
			[class.cp-icon-orange]="cp.updated"
			(click)="showAuditLog(cp)"
		>
		</i>
		<div class="cp-source">
			<i
				*ngIf="cp.source === 'EMP_APP'"
				class="far fa-id-badge cp-icon cp-source-icon item-tooltip"
				data-bs-placement="left"
				title="Employee App Checkpoint"
			></i>
			<i
				*ngIf="cp.source === 'IVR_LINK'"
				class="far fa-link cp-icon cp-source-icon item-tooltip"
				data-bs-placement="left"
				title="Text Link Checkpoint"
			></i>
			<i
				*ngIf="cp.source === 'IVR_LANDLINE'"
				class="far fa-phone cp-icon cp-source-icon item-tooltip"
				data-bs-placement="left"
				title="Landline Checkpoint"
			></i>
			<i
				*ngIf="cp.source === 'ADMIN'"
				class="far fa-user cp-icon cp-source-icon item-tooltip"
				data-bs-placement="left"
				title="Admin Checkpoint"
			></i>
			<i
				*ngIf="cp.source === 'SMS'"
				class="far fa-message-check cp-icon cp-source-icon item-tooltip"
				data-bs-placement="left"
				title="SMS Checkpoint"
			></i>
			<i
				*ngIf="cp.source === 'KIOSK'"
				class="far fa-display cp-icon cp-source-icon cp-kiosk-icon item-tooltip"
				data-bs-placement="left"
				title="Kiosk Checkpoint"
			></i>

			<span *ngIf="showCheckbox">
				<i
					*ngIf="cp.isSelected"
					class="far fa-square-check cp-icon cp-batch-icon"
					style="color: green"
					title="Selected"
					(click)="cp.isSelected = !cp.isSelected"
				></i>
				<i *ngIf="!cp.isSelected" class="far fa-square cp-icon cp-batch-icon" title="Not Selected" (click)="cp.isSelected = !cp.isSelected"></i>
			</span>
		</div>
	</div>

	<div>
		<div *ngIf="cp.isEditing" class="float-right" style="margin-left: 12px">
			<button class="btn btn-sm btn-secondary" (click)="cancelCheckpointEdit(cp)">Cancel</button>
			<button class="btn btn-sm btn-success" style="margin-left: 8px" (click)="saveCheckpointEdit(cp)">Save</button>
		</div>
		<div>
			<div class="chkpt-card-title" [class.chkpt-type-highlight]="cp.type === 'CHECKIN' || cp.type === 'CHECKOUT'">
				<div *ngIf="!(cp.isEditing && cp.admin_created)">#{{ cpIdx + 1 }} / Tour {{ cp.tour_sequence_number }}</div>
				<div *ngIf="cp.isEditing && cp.admin_created" style="line-height: 1.6rem">New Checkpoint</div>
				<!-- {{ formatType(cp) }} -->
			</div>
			<div *ngIf="cp.id && !(cp.isEditing && cp.admin_created)">
				<div class="chkpt-date">
					<!-- <div class="chkpt-date-title">Checkpoint #{{ cpIdx + 1 }} / Tour {{ cp.tour_sequence_number }}</div> -->
					<div *ngIf="wplRecord?.expected_time">Expected: {{ formatTime(wplRecord.expected_time, 'LONG') }}</div>
					<div>Submitted: {{ formatTime(cp.admin_created ?? cp.created, 'LONG') }}</div>
					<div *ngIf="wplRecord?.emp_notified_ts">Emp Notified: {{ formatTime(wplRecord?.emp_notified_ts, 'LONG') }}</div>
					<div *ngIf="wplRecord?.sup_notified_ts">Sup Notified: {{ formatTime(wplRecord?.sup_notified_ts, 'LONG') }}</div>
					<!-- <div *ngIf="wplRecord?.geo_distance">Distance: {{ formatDistance(wplRecord.geo_distance) }}</div> -->
				</div>
				<div *ngIf="!cp.geo_longitude && !showConfirmDelete" class="chkpt-nogps" [class.chkpt-admin-created]="cp.admin_created">
					&lt; {{ formatGps(cp) }} &gt;
				</div>

				<!-- <hr *ngIf="!showConfirmDelete && (cp.waypoint_name || cp.imageList.length > 0)" /> -->
				<div *ngIf="cp.waypoint_name" class="cp-name">{{ cp.waypoint_name }}</div>
			</div>
			<div *ngIf="cp.isEditing && cp.admin_created" class="date-block chkpt-date-picker" style="margin-top: 10px">
				<div class="row">
					<div class="col-xl-6 col-sm-12">
						<app-date-time-picker
							[currentDate]="cpEdit.adminCreatedDate"
							[pickerType]="'DATETIME'"
							[pickerMode]="'dialog'"
							[pickerLabel]="'Checkpoint Time'"
							[hour12Timer]="is12HourFormat"
							[format]="datePickerFormat"
							(pickerDidClose)="cpEdit.adminCreatedDate = $event"
						>
						</app-date-time-picker>
					</div>
					<div class="col-xl-6 col-sm-12"></div>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="showConfirmDelete">
		<hr />
		<div class="d-flex justify-content-between">
			<div *ngIf="!isDeleting" class="confirm-delete-label">Confirm Delete</div>
			<div *ngIf="isDeleting" class="confirm-delete-label" style="color: gray"><i class="fa fa-floader fa-spin"></i> Deleting ...</div>
			<div *ngIf="!isDeleting">
				<button class="btn btn-sm btn-outline-secondary" style="margin-right: 12px" (click)="showConfirmDelete = false">Cancel</button>
				<button class="btn btn-sm btn-outline-danger" (click)="deleteCheckpoint(cp)">Delete</button>
			</div>
		</div>
	</div>
</div>

<div [hidden]="showConfirmDelete">
	<div *ngIf="!cp.isEditing && !!cp.comments" class="chkpt-comments">{{ cp.comments }}</div>
	<div *ngIf="cp.isEditing">
		<div class="row">
			<div class="col-xl-6 col-sm-12">
				<textarea class="form-control note-edit-textarea" [(ngModel)]="cpEdit.comments"></textarea>

				<div>
					<app-image-files-view [images]="cpEdit.currentImagesList" [timezone]="timezone"></app-image-files-view>
				</div>
			</div>
			<div class="col-xl-6 col-sm-12">
				<div style="margin-top: 18px">
					<app-file-uploader [fileUploadManager]="cpEdit.fileUploadManager" (listUpdated)="updateCurrentImagesList()"></app-file-uploader>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="!showCheckbox && showWaypointAddConfirmation">
	<hr />
	<div class="d-flex justify-content-between">
		<div class="confirm-action-label">
			<div>Confirm Import</div>
		</div>
		<div style="min-width: 145px">
			<button class="btn btn-sm btn-outline-secondary" style="margin-right: 12px" (click)="showWaypointAddConfirmation = false">Cancel</button>
			<button class="btn btn-sm btn-outline-success" (click)="addWaypointToTour(cp)">Confirm</button>
		</div>
	</div>
	<div class="confirm-action-sub-label">Import this checkpoint into the current tour for this job</div>
</div>

<div [hidden]="cp.isEditing || showConfirmDelete || showWaypointAddConfirmation" *ngIf="cp.showImages && cp.imageList.length > 0" class="image-list">
	<hr />
	<app-image-files-view [images]="cp.imageList" [timezone]="timezone" [refTs]="refTs"></app-image-files-view>
</div>
