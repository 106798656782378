import { environment } from '@env/environment'
import { Component, OnInit, ViewChild, EventEmitter, Output, OnDestroy } from '@angular/core'
import { DatabaseService, NotificationsService, UserLoginService } from '@app/services'
import { log } from '@app/helpers'

import S3 from 'aws-sdk/clients/s3'
import { v4 as uuid } from 'uuid'
@Component({
    selector: 'app-qbdfile-uploader',
    templateUrl: './qbdfile-uploader.component.html',
    styleUrls: ['./qbdfile-uploader.component.scss'],
    standalone: false
})
export class QBDFileUploaderComponent implements OnInit, OnDestroy {
	@ViewChild('uploadComponent', { static: true }) uploadComponent
	@Output() uploadStarted = new EventEmitter<boolean>()
	@Output() uploadComplete = new EventEmitter<boolean>()

	isUploading = false
	isProcessing = false
	companyId = ''
	percentComplete = 0
	bucketName = environment.qbdUploadBucket

	constructor(
		private loginSrvc: UserLoginService,
		private dbSrvc: DatabaseService,
		private noteSrvc: NotificationsService,
	) {}

	ngOnInit() {
		this.companyId = this.dbSrvc.settingSrvc.getCompany().id.toString()
	}

	ngOnDestroy() {
		this.noteSrvc.clear()
	}

	qbdFileHandler(event) {
		if (this.isUploading) {
			return
		}
		log(event)
		const fileList: FileList = event.files
		const file: File = fileList[0]
		// const fileReader = new FileReader()

		// Uncomment to skip local file check
		this.uploadFileToBucket(file)

		// Check if valid formatted QB file
		// fileReader.onload = (e) => {
		// 	const fileContent = e.currentTarget['result']
		// 	const fileCheck = fileContent.substring(0, 9)
		// 	log(fileCheck)
		// 	if (fileCheck !== '!TIMERHDR') {
		// 		this.noteSrvc.notify('warn', 'File Error', 'The selected file does not appear to be a QuickBooks data file.')
		// 		// this.isUploading = false
		// 		this.uploadComponent.clear()
		// 	} else {
		// 		// this.isUploading = true
		// 		this.uploadFileToBucket(file)
		// 	}
		// }
		// const headCheck = file.slice(0, 100)
		// fileReader.readAsText(headCheck)
	}

	uploadFileToBucket(file: File) {
		log('Upload Via S3', file)
		this.isUploading = true
		this.uploadStarted.emit(true)
		const filename = uuid()
		const params = {
			Metadata: {
				company_id: this.companyId,
			},
			Bucket: this.bucketName,
			Key: filename,
			ContentType: 'text/plain; charset=ISO-8859-1',
			Body: file,
		}
		// const params = {
		// 	Metadata: {
		// 		'company_id': this.companyId,
		// 	},
		// 	Bucket: this.bucketName,
		// 	Key: filename,
		// 	ContentType: file.type,
		// 	Body: file
		// }
		// log('Params', params)
		// return

		const bucket = new S3()
		this.noteSrvc.clear()
		bucket
			.upload(params, (err, data) => {
				this.isUploading = false
				if (err) {
					log('Error', err)
					this.noteSrvc.notify('error', 'Upload Error', 'An error occurred uploading your QuickBooks file. Please contact support.')
					this.uploadComponent.clear()
				} else {
					log('Data', data)
					this.noteSrvc.notify(
						'success',
						'Upload Successful',
						'Upload successful. Your QuickBooks data is now being processed and you will be notified when integration is complete.',
					)
					this.isProcessing = true
					const payload = { filename: filename }
					this.dbSrvc.lambdaSrvc
						.qbdSyncS3FileUploaded(payload)
						.then((result) => {
							log('QBDS3 Lambda Result', result)
							this.isProcessing = false
							if (result.FunctionError) {
								this.noteSrvc.notify(
									'error',
									'Integration Error',
									'We encountered an unknown error when processing your QuickBooks file. Please contact support to resolve this issue.',
								)
								return
							} else {
								this.noteSrvc.notify('success', 'Integration Complete', 'Your QuickBooks data file has been successfully integrated.')
								this.dbSrvc.bulkRead(['qbo_customer', 'qbo_service_item', 'qbo_sync', 'company']).then((success) => {
									this.uploadComplete.emit(true)
								})
							}
						})
						.catch((error) => {
							this.isProcessing = false
							log('QBDS3 Lambda Error', error)
							this.noteSrvc.notify('error', 'Integration Error', 'An error occurred integrating your QuickBooks file. Please contact support.')
						})
				}
			})
			.on('httpUploadProgress', (evt) => {
				this.updateProgress(evt)
			})
	}

	updateProgress(evt) {
		this.loginSrvc.setLastActive()
		const loaded = evt.loaded
		const total = evt.total
		const complete = parseInt(`${(loaded / total) * 100}`, 10)
		this.percentComplete = complete
		// log('Complete', complete)
	}
}
