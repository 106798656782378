import { Component, Input, OnInit } from '@angular/core'
import { AccessHelper } from '@app/helpers/access'
import { CrudAction, DialogManager } from '@app/models'
import { CoreService } from '@app/services'

@Component({
    selector: 'app-header-dispatcher',
    templateUrl: './header-dispatcher.component.html',
    styleUrls: ['./header-dispatcher.component.scss'],
    standalone: false
})
export class HeaderDispatcherComponent implements OnInit {
	@Input() dialogManager: DialogManager

	constructor(private coreSrvc: CoreService) {}
	get isCallCenterReady(): boolean {
		return this.coreSrvc.dbSrvc.settingSrvc.usersDataLoaded
	}

	get isSignedIn(): boolean {
		return !!this.coreSrvc.dbSrvc.settingSrvc.getMyActualUser()?.dispatcher_active
	}

	// get isCallCenterEnabled(): boolean {
	// 	return this.dbSrvc.settingSrvc.getCompany()?.cc_enable
	// }

	ngOnInit(): void {}

	public openAgentControlPanel() {
		const generalAccessHelper = new AccessHelper(this.coreSrvc, 'miscellaneous')
		const permissions = generalAccessHelper.getPermissionsFor('miscellaneous')
		const isEnabled = !permissions.access.noAccess
		const canAccessAgentControlPanel = permissions.isOptionEnabledFor('GBLACP')

		if (isEnabled && canAccessAgentControlPanel) {
			this.dialogManager.isDialogVisible = true
		} else {
			this.coreSrvc.notifySrvc.postNotAuthorizedNotification()
		}
	}
}
