<div class="section-table">
	<table id="agentTable" class="stripe nowrap" cellspacing="0" width="100%"></table>
	<i id="clear-search-icon" (click)="clearSearch()" class="fa fa-times-circle-o"></i>
	<app-table-footer-buttons
		[filename]="'call_text_records'"
		[newBtnLabel]="''"
		[exportBtnLabel]="'Export Records'"
		[table]="'agentTable'"
		[marginTop]="'15px'"
	></app-table-footer-buttons>
</div>

<p-dialog
	styleClass="p-dialog-noheader"
	[(visible)]="playAudioAction.showDialog"
	[modal]="true"
	[style]="{ width: '250px' }"
	[showHeader]="false"
	[baseZIndex]="5000"
	[dismissableMask]="true"
	(onHide)="stopPlayingAudio()"
>
	<div class="tts-default-font" style="text-align: center; color: #566978; font-weight: bold">
		<i class="fas fa-loader fa-spin"></i> Playing Audio...
	</div>
</p-dialog>

<app-basic-modal
	[(visible)]="transcriptAction.showDialog"
	modalId="agentCallLogModal"
	[modalHeader]="transcriptAction.header"
	[modalFooter]="transcriptAction.footer"
	[modalWidth]="450"
>
	<div>
		<div style="margin-top: 20px">
			<audio
				*ngIf="transcriptAction.audioFileUrl"
				controls
				controlsList="nodownload"
				[src]="transcriptAction.audioFileUrl"
				(play)="audioFilePlayed(transcriptAction.recordId)"
			>
				Your browser does not support the
				<code>audio</code> element.
			</audio>
		</div>
		<div style="margin-top: 25px">
			<div class="transcript-content"><b>Transcript:</b> {{ formatTranscript(transcriptAction.content) }}</div>
			<div *ngIf="transcriptAction.record?.last_status" class="details-content">
				<hr style="margin: 20px 0px 40px 0px" />
				<div><b>Details:</b> {{ formatDetails(transcriptAction.record) }}</div>
			</div>
			<div *ngIf="transcriptAction.imageFiles.length > 0" style="margin-top: 25px">
				<hr style="margin: 20px 0px 40px 0px" />
				<app-image-files-view [images]="transcriptAction.imageFiles" [timezone]="transcriptAction.timezone"></app-image-files-view>
			</div>
		</div>
		<!-- <pre><code>{{ transcriptAction.content | json }}</code></pre> -->
	</div>
</app-basic-modal>

<app-modal-dialog *ngIf="sectionPrefsDialogManager.isDialogVisible" [dialogManager]="sectionPrefsDialogManager">
	<app-local-prefs [dialogManager]="sectionPrefsDialogManager" (prefsUpdated)="prefsDataSaved()"></app-local-prefs>
</app-modal-dialog>

<app-audit-modal
	[recordId]="auditAction.recordId"
	[resource]="auditAction.resource"
	[header]="auditAction.header"
	[footer]="auditAction.footer"
	[(visible)]="auditAction.showDialog"
></app-audit-modal>
