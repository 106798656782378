import {
	Component,
	OnInit,
	AfterViewInit,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	Input,
	ViewChild,
	ElementRef,
	Renderer2,
	HostListener,
} from '@angular/core'
import { DialogManager } from '@app/models'

import { DeviceDetectorService } from 'ngx-device-detector'
import { log } from '@app/helpers/logger'
import { CoreService } from '@app/services'

import { debounce } from 'lodash'
import { DialogHelper, DisplayHelper } from '@app/helpers'

@Component({
    selector: 'app-modal-dialog',
    templateUrl: './modal-dialog.component.html',
    styleUrls: ['./modal-dialog.component.scss'],
    standalone: false
})
export class ModalDialogComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	isMobile = false

	@Input() modalWidth = 450
	@Input() modalHeight: number
	@Input() dialogManager = new DialogManager()

	@ViewChild('modalElement', { static: true }) modalElement: ElementRef

	constructor(
		private renderer: Renderer2,
		private devDetSrvc: DeviceDetectorService,
		private coreSrvc: CoreService,
	) {
		this.isMobile = this.devDetSrvc.isMobile()
	}

	ngOnInit(): void {
		this.setupComponent()
	}

	ngAfterViewInit(): void {
		this.openModal()
	}

	ngOnDestroy(): void {
		const element: any = $('#' + this.dialogManager.modalId)
		element.modal('hide')
		// DisplayHelper.setHtmlScrolling(true)
	}

	ngOnChanges(changes: SimpleChanges): void {}

	// Code block for handling resize events
	// debouncedHandleResize = debounce(this.handleResize, 250)
	// @HostListener('window:resize', ['$event'])
	// onResize(event: Event): void {
	// 	this.debouncedHandleResize(event)
	// }
	// private handleResize(event: Event): void {
	// 	if (this.dialogManager?.isDialogVisible) {
	// 		log('Resize Event', event)
	// 			DialogHelper.resetBootstrapModalDialog(this.dialogManager.modalId)
	// 	}
	// }

	private setupComponent() {
		const nativeElement = this.modalElement.nativeElement
		const width = this.modalWidth
		const height = this.modalHeight
		if (height) {
			const style = 'max-width: ' + width + 'px; max-height: ' + height + 'px;'
			this.renderer.setAttribute(nativeElement, 'style', style)
		} else {
			const style = 'max-width: ' + width + 'px; '
			this.renderer.setAttribute(nativeElement, 'style', style)
		}
	}

	public backBtnClicked(): void {
		this.dialogManager.backBtnAction()
	}
	public submitBtnClicked(): void {
		this.dialogManager.submitBtnAction()
	}
	public cancelBtnClicked(): void {
		log('Cancel Clicked')
		this.dialogManager.cancelBtnAction()
	}

	public openModal(): void {
		// this.dialogManager.resetCancelBtnAction() // DEPRECATED 20240902 - Default cancelBtnAction now set in DialogManager

		const element: any = $('#' + this.dialogManager.modalId)
		element.modal('show')

		// Support for draggable dialogs - class .modal-header should have proper cursor
		// and need to add  appDraggable handle=".modal-header" to moda-dialog in template.
		// Also uncomment the block below and comment out element.modal('show') above.

		// if (this.coreSrvc.devDetect.isDesktop()) {
		// 	this.handleSavedPositioning()
		// } else {
		// 	element.modal('show')
		// }
	}

	private handleSavedPositioning() {
		DialogHelper.handleSavedPositioning(this.dialogManager.modalId)
		// DisplayHelper.setHtmlScrolling(false) // Disable HTML scrolling on modal open
	}

	public closeModal(): void {
		log('Close Modal')
		this.dialogManager.isDialogVisible = false
		// DisplayHelper.setHtmlScrolling(true) // Enable HTML scrolling on modal close
	}
}
