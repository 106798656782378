<div class="filter-btn-block" [class.filter-btn-block-no-margin]="noMargins">
	<div *ngFor="let btn of manager.buttons; let idx = index">
		<div
			*ngIf="btn.count"
			class="badge filter-btn"
			[class.filter-btn-last]="idx === manager.buttons.length - 1"
			[class.filter-btn-selected]="btn === manager.selectedBtn"
			(click)="filterButtonClicked(btn)"
		>
			{{ btn.count() }} {{ btn.count() === 1 ? btn.label : btn.label + 's' }}
		</div>
		<div
			*ngIf="!btn.count"
			class="badge filter-btn"
			[class.filter-btn-last]="idx === manager.buttons.length - 1"
			[class.filter-btn-selected]="btn === manager.selectedBtn"
			(click)="filterButtonClicked(btn)"
		>
			{{ btn.label }}
		</div>
	</div>
</div>
