import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { log } from '@app/helpers'
import {
	ContactHelper,
	ContactOrganizationLinkCard,
	ContactOrganizationLinkRecord,
	ContactRecord,
	DialogManager,
	LinkContactOrgHostRecordUpdateEvent,
	LinkedRecordsManager,
	OrganizationRecord,
} from '@app/models'
import { DatabaseService } from '@app/services'

@Component({
    selector: 'app-link-contact-org-list',
    templateUrl: './link-contact-org-list.component.html',
    styleUrls: ['./link-contact-org-list.component.scss'],
    standalone: false
})
export class LinkContactOrgListComponent implements OnInit, AfterViewInit {
	@Input() source: 'CONTACT' | 'ORGANIZATION'
	@Input() linkedRecordsManager: LinkedRecordsManager<ContactOrganizationLinkCard>
	@Input() dialogManager: DialogManager // DialogData is a ContactRecord or an Organization Record

	@Output() recordUpdated = new EventEmitter<LinkContactOrgHostRecordUpdateEvent>()

	constructor(private dbSrvc: DatabaseService) {}

	get contactName(): string {
		return this.dialogManager.dialogData.name
	}

	get emptyListMessage(): string {
		return this.source === 'CONTACT' ? 'No Client / Vendor Linked' : 'No Contact linked'
	}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.dialogManager.submitBtnAction = () => this.editRecord()
		}, 150)
	}

	public filterLinkedRecords(searchText: string) {
		log('Search', searchText)
		if (!searchText) {
			this.linkedRecordsManager.filteredRecords = [...this.linkedRecordsManager.records]
		} else {
			if (this.source === 'CONTACT') {
				this.linkedRecordsManager.filteredRecords = this.linkedRecordsManager.records.filter((rec: ContactOrganizationLinkCard) =>
					(rec.organization.name + rec.linkRecord.details).toLowerCase().includes(searchText)
				)
			}
		}
	}

	editRecord(card?: ContactOrganizationLinkCard) {
		log('Edit Record', card)
		this.dialogManager.pushState()
		this.dialogManager.submitBtnLabel = 'Save'
		this.dialogManager.cancelBtnLabel = 'Cancel'

		const newRecord = card ? card.linkRecord.clone() : new ContactOrganizationLinkRecord()
		if (this.source === 'CONTACT') {
			const hostRecord = this.dialogManager.dialogData as ContactRecord
			newRecord.contact_id = hostRecord.id
		} else {
			const hostRecord = this.dialogManager.dialogData as OrganizationRecord
			newRecord.vendor_client_id = hostRecord.id
		}
		// Changing the dialog managers data to a link record for the edit form
		this.dialogManager.dialogData = newRecord
		this.dialogManager.isEditing = true
	}

	deleteRecord(card: ContactOrganizationLinkCard) {
		log('Delete Record', card)
		const hostRecordId = this.dialogManager.dialogData.id
		const linkRecordId = card.linkRecord.id
		this.dbSrvc.deleteRecord('vendor_client_contact', linkRecordId).then(() => {
			this.recordUpdated.next({ id: hostRecordId, source: this.source })
		})
	}

	recordSavedEventHandler() {
		log('LinkContactOrgListComponent - Record Saved Event Handler')
		this.dialogManager.popStateAndApply()
		const hostRecordId = this.dialogManager.dialogData.id
		this.recordUpdated.next({ id: hostRecordId, source: this.source })
	}

	cancelEditEventHandler() {
		log('Cancel Edit Event Handler')
		this.dialogManager.popStateAndApply()
	}

	formatContactType(card: ContactOrganizationLinkCard) {
		const type = card.linkRecord.association_type
		return ContactHelper.formatContactType(type) + ' CONTACT'
	}
}
