import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { log } from '@app/helpers'
import { BulkActionManager, BulkActionType } from '@app/models'

@Component({
    selector: 'app-bulk-action-dialog',
    templateUrl: './bulk-action-dialog.component.html',
    styleUrls: ['./bulk-action-dialog.component.scss'],
    standalone: false
})
export class BulkActionDialogComponent implements OnInit {

	@Input() manager: BulkActionManager

	constructor() { }

	ngOnInit(): void { }


}
