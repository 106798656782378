import { Injectable } from '@angular/core'
import { log } from '@app/helpers'
import { ChatBotMessage } from '@app/models'
import { BehaviorSubject, Subject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class ChatBotService {
	private messages: Array<ChatBotMessage> = []
	private messages$: BehaviorSubject<Array<ChatBotMessage>> = new BehaviorSubject<Array<ChatBotMessage>>([])
	public messageHasBeenPosted = new Subject<ChatBotMessage>()

	constructor() {}

	getMessages() {
		return this.messages
	}

	postMessage(message: ChatBotMessage) {
		const currentMessages = this.messages
		this.messages = [...currentMessages, message]
		// this.messages.next(updatedMessages)
		this.messageHasBeenPosted.next(message)
	}
}
