import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { DataTablesHelper, DisplayHelper, TableActionFormatter, log } from '@app/helpers'
import { IVRLogRecord, CommLogViewManager, ComponentBridgeName, ContactSupportEvent } from '@app/models'
import { CoreService } from '@app/services'
import { environment } from '@env/environment'
import { SelectItem } from 'primeng/api'
import { CommLogIVRTableFormatter } from './comm-log-ivr-table.formatter'

import moment from 'moment-timezone'
import { Subscription } from 'rxjs'

enum IVRTableColumnIndex {
	id = 0,
	date = 1,
	callId = 2,
	callFrom = 3,
	callTo = 4,
	actions = 5,
}

@Component({
    selector: 'app-comm-log-ivr-table',
    templateUrl: './comm-log-ivr-table.component.html',
    styleUrls: ['./comm-log-ivr-table.component.scss'],
    standalone: false
})
export class CommLogIVRTableComponent implements OnInit, OnDestroy, AfterViewInit {
	bridgeName: ComponentBridgeName = 'ngBridgeIVRLogivrTable'

	list: Array<IVRLogRecord> = []
	viewManager: CommLogViewManager

	// playAudioAction = { audioFile: null as HTMLAudioElement, showDialog: false } // audioFile: HTMLAudioElement
	viewLogAction = {
		header: 'View Comm Log',
		footer: '',
		callLogId: null,
		showDialog: false,
	}

	private ivrTable // : DataTables.DataTable;
	private defaultSortOrder = [[IVRTableColumnIndex.date, 'desc']]
	private tableFormatter: CommLogIVRTableFormatter

	timezoneOptions: Array<SelectItem> = []

	private subs = new Subscription()

	constructor(
		private cd: ChangeDetectorRef,
		private coreSrvc: CoreService,
	) {
		this.viewManager = this.coreSrvc.dbSrvc.commLogSrvc.viewManager
		this.tableFormatter = new CommLogIVRTableFormatter(this.coreSrvc.dbSrvc)
		this.setupSubscriptions()
	}

	public ngOnInit(): void {
		window[this.bridgeName] = this
		this.fetchAndReload()
	}

	public ngOnDestroy() {
		this.ivrTable['fixedHeader'].disable()
		window[this.bridgeName] = null
		this.subs.unsubscribe()
	}

	public ngAfterViewInit() {
		this.initTable()

		$('#ivrTable_filter input').attr('placeholder', ' filter')
		$('#ivrTable_filter input').addClass('search-field-input')

		setTimeout(() => {
			$('#clear-search-icon').detach().appendTo('#ivrTable_filter label')
		})
	}

	private setupSubscriptions() {
		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	private handleScreenSizeChanges() {
		if (this.ivrTable) {
			this.ivrTable.columns.adjust().responsive.recalc()
			this.ivrTable.fixedHeader.adjust()
		}
	}

	public clearSearch(): boolean {
		this.ivrTable
			?.search('')
			.order([[IVRTableColumnIndex.date, 'desc']])
			.rows()
			.invalidate()
			.draw()
		this.coreSrvc.displaySrvc.enableAllTooltips()
		return false
	}

	private loadData() {
		this.list = this.coreSrvc.dbSrvc.commLogSrvc.getIvrRecords()
	}

	public updateTable() {
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadData()
			this.updateColumns()
		})
	}

	private updateColumns() {
		this.ivrTable.clear()
		this.ivrTable.rows.add(this.list)
		this.ivrTable.draw(false)
	}

	public resetPage(reDraw: boolean) {
		this.ivrTable.page(0)
		if (reDraw) {
			this.ivrTable.draw(false)
		}
	}

	public fetchAndReload() {
		const options = this.makeRequestOptions()
		this.coreSrvc.dbSrvc.readTable('ivr_call_log', options).then((readSuccess) => {
			this.updateTable()
		})
	}

	private makeRequestOptions(): Object {
		const dayViewDate = this.viewManager.dayViewDate
		if (dayViewDate) {
			const dateStr = moment(dayViewDate).format('YYYY-MM-DD')
			const timezone = this.viewManager.timezone
			const start = moment.tz(dateStr, timezone).startOf('day').toISOString()
			const end = moment.tz(dateStr, timezone).endOf('day').toISOString()
			return { start_date: start, end_date: end }
		}
		return {}
	}

	public viewCallLog(id: number) {
		const rec = this.coreSrvc.dbSrvc.commLogSrvc.getIvrRecordById(id)
		const date = this.tableFormatter.date(rec.created, this.viewManager.timezone)
		const time = this.tableFormatter.time(rec.created, this.viewManager.timezone)
		this.viewLogAction.header = `${date} @ ${time}`
		this.viewLogAction.footer = `${rec.call_id}`
		this.viewLogAction.callLogId = rec.id
		this.viewLogAction.showDialog = true
	}

	public contactSupport(id: number) {
		const event = new ContactSupportEvent('IVR Debug', 'click_to_call_log', id)
		this.coreSrvc.eventSrvc.contactSupportEvent.next(event)
		// this.coreSrvc.dbSrvc.settingSrvc.emailSupportForGenericRecord('IVR Debug', 'ivr_call_log', id)
	}

	private initTable() {
		this.coreSrvc.displaySrvc.startSectionLoader()
		this.ivrTable = $('#ivrTable').DataTable(<DataTables.Settings>{
			stateSave: false,
			responsive: true,
			processing: true,
			deferRender: true,
			paging: true,
			pageLength: 50,
			lengthChange: true,
			info: true,
			select: false,
			searching: true,
			fixedHeader: DataTablesHelper.fixedHeader,
			autoWidth: false,
			data: this.list,
			order: this.defaultSortOrder,
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{
					responsivePriority: 1,
					targets: [IVRTableColumnIndex.date, IVRTableColumnIndex.actions],
				},
				// {
				// 	responsivePriority: 2,
				// 	targets: [ ]
				// },
				{
					// ID
					targets: IVRTableColumnIndex.id,
					visible: false,
					searchable: true,
					title: 'ID',
					data: null,
					render: (rec: IVRLogRecord, type, full, meta) => {
						return `${rec.id}`
					},
				},
				{
					// Date
					targets: IVRTableColumnIndex.date,
					visible: true,
					searchable: true,
					title: 'Date',
					data: null,
					render: (rec: IVRLogRecord, type, full, meta) => {
						const created = rec.created
						const date = this.tableFormatter.date(rec.created, this.viewManager.timezone)
						const time = this.tableFormatter.time(rec.created, this.viewManager.timezone)
						const result = `<span style="display: none">${created}</span>${date}<br>${time}`
						return `
						<div class="dtr-control-content">
							${result}
						</div>`
					},
				},
				{
					// Call ID
					targets: IVRTableColumnIndex.callId,
					visible: true,
					searchable: true,
					title: 'ID',
					data: null,
					render: (rec: IVRLogRecord, type, full, meta) => {
						return rec.call_id
					},
				},
				{
					// Call From
					targets: IVRTableColumnIndex.callFrom,
					visible: true,
					searchable: true,
					title: 'Call From',
					data: null,
					render: (rec: IVRLogRecord, type, full, meta) => {
						return this.tableFormatter.callFrom(rec)
					},
				},
				{
					// Call To
					targets: IVRTableColumnIndex.callTo,
					visible: true,
					searchable: true,
					title: 'Call To',
					data: null,
					render: (rec: IVRLogRecord, type, full, meta) => {
						return this.tableFormatter.callTo(rec)
					},
				},
				// {
				// 	// Company Name
				// 	targets: IVRTableColumnIndex.companyName,
				// 	visible: true,
				// 	searchable: true,
				// 	title: 'Company Name',
				// 	data: null,
				// 	render: (rec: CallLogIVRRecord, type, full, meta) => {
				// 		return rec.company_name
				// 	},
				// },
				{
					// Actions
					targets: IVRTableColumnIndex.actions,
					visible: true,
					searchable: true,
					title: 'Actions',
					data: null,
					render: (rec: IVRLogRecord, type, full, meta) => {
						const viewLog = this.tableFormatter.viewCallLog(rec, this.bridgeName)
						const supportLink = TableActionFormatter.supportLink(this.bridgeName, 'contactSupport', rec.id, 0)

						return `<span class="act-ico-wrap">${viewLog}${supportLink}</span>`
					},
				},
			],
			// 'createdRow': (row, data, dataIndex) => {
			// 	const enabled = this.checkEnabledStatus(data as Vacation)
			// 	if (!enabled) {
			// 		$(row).addClass('dt-row-disabled')
			// 	}
			// }
			drawCallback: (settings) => {
				this.ivrTable?.columns.adjust().responsive.recalc()
				this.coreSrvc.displaySrvc.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
			},
		})
		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter('ivrTable', this.ivrTable)
	}
}
