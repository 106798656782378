import { Component, OnInit } from '@angular/core'

import { QBOSync, IQBOSyncPayload, CrudAction } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'

import { log, DateTimeHelper } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'
import moment from 'moment'
import { Router } from '@angular/router'

@Component({
    selector: 'app-qbotransaction-data',
    templateUrl: './qbotransaction-data.component.html',
    styleUrls: ['./qbotransaction-data.component.scss'],
    standalone: false
})
export class QBOTransactionDataComponent implements OnInit {
	accessHelper: AccessHelper

	startDate: Date = null
	endDate: Date = null

	syncData: QBOSync
	lastImportDate = 'Ready for initial export'
	hasLog = false
	isWorking = false
	syncErrors = 0
	showTooltips = true

	CrudAction = CrudAction

	constructor(
		private router: Router,
		private coreSrvc: CoreService,
	) {
		this.setupAccessPermissions()
	}

	ngOnInit() {
		this.updateUI()
		const lastRunEndDate = this.syncData?.transaction_log_last_end ? moment(this.syncData.transaction_log_last_end).add(1, 'days').toDate() : null
		this.startDate = lastRunEndDate
	}

	setupAccessPermissions() {
		this.accessHelper = new AccessHelper(this.coreSrvc, 'quickbooks')
	}

	canPerformAction(action: CrudAction) {
		return this.accessHelper.canPerformAction(action, false)
	}

	updateUI() {
		log('QBOSync Transaction Component updating UI')
		this.syncData = this.coreSrvc.dbSrvc.qbSrvc.getQBOSyncData()
		this.syncErrors = this.syncData.transaction_log_sync_errors
		if (this.syncData && this.syncData.transaction_log_sync) {
			const importDate = this.syncData.transaction_log_sync
			this.lastImportDate = DateTimeHelper.mediumDateTimeFromDateString(importDate)
			this.hasLog = true
		}
	}

	showLog(): boolean {
		return false
	}

	toggleTooltips(): boolean {
		this.showTooltips = !this.showTooltips
		return false
	}

	employeesSyncd(): boolean {
		const syncData = this.coreSrvc.dbSrvc.qbSrvc.getQBOSyncData()
		if (syncData && syncData.employee_sync) {
			return true
		}
		return false
	}

	exportData() {
		log('QBOSync Transaction Component export data button clicked')
		if (!this.accessHelper.canPerformAction(CrudAction.update, false)) {
			this.accessHelper.notifyOperationNotAuthorized()
			return
		}

		if (!this.startDate || !this.endDate) {
			this.coreSrvc.notifySrvc.notify('info', 'Missing Dates', 'You must select a start and end date to export your data.')
			return
		}

		const startDate = moment(this.startDate)
		const endDate = moment(this.endDate)

		if (endDate.isBefore(startDate, 'day')) {
			this.coreSrvc.notifySrvc.notify('info', 'Invalid Dates', 'The end date cannot be before the start date.')
			return
		}

		this.isWorking = true
		const request: IQBOSyncPayload = {
			table: null,
			operation: 'transaction_log',
			start_date: startDate.format('YYYY-MM-DD'),
			end_date: endDate.format('YYYY-MM-DD'),
		}

		this.coreSrvc.dbSrvc.lambdaSrvc.qboSync(request).then((result) => {
			this.coreSrvc.dbSrvc.readTable('qbo_sync').then((success) => {
				this.isWorking = false
				this.updateUI()
				this.router.navigate(['/admin/integrations/log/qbo/TransactionLog'])
			})
		})
	}
}
