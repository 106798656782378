<div class="section-table">
	<table id="userTable" class="stripe nowrap" cellspacing="0" width="100%"></table>
	<app-table-footer-buttons
		[filename]="'user_records'"
		[newBtnLabel]="'Add User'"
		[exportBtnLabel]="'Export Users'"
		[table]="'userTable'"
		[marginTop]="'15px'"
		(newBtnClicked)="createRecord()"
	></app-table-footer-buttons>
	<i id="clear-search-icon" (click)="clearSearch()" class="fa fa-times-circle-o" aria-hidden="true"></i>
</div>

<app-modal-dialog *ngIf="editDialogManager.isDialogVisible" [dialogManager]="editDialogManager">
	<app-user-edit
		[recordId]="editAction.recordId"
		[action]="editAction.action"
		[dialogManager]="editDialogManager"
		(saveActionComplete)="userUpdated()"
		(closeDialog)="editDialogManager.isDialogVisible = false"
	></app-user-edit>
</app-modal-dialog>

<app-basic-modal
	[(visible)]="adminPrefsDialog.show"
	modalId="adminPrefsModal"
	[preventBackgroundClose]="true"
	[modalHeader]="adminPrefsDialog.header"
	[modalFooter]="adminPrefsDialog.footer"
	[modalWidth]="450"
	[saveTable]="'adminPrefs'"
>
	<div *ngIf="adminPrefsDialog.show">
		<app-admin-prefs [group]="adminPrefsDialog.group" [userId]="adminPrefsDialog.userId" (saveComplete)="prefsSaved()"></app-admin-prefs>
	</div>
</app-basic-modal>

<app-basic-modal
	[(visible)]="adminPermsDialog.show"
	modalId="adminPermsModal"
	[preventBackgroundClose]="true"
	[modalHeader]="adminPermsDialog.header"
	[modalFooter]="adminPermsDialog.footer"
	[modalWidth]="450"
	[saveTable]="'adminPerms'"
	[saveBtnVisible]="adminPermsDialog.saveBtnVisible"
>
	<div *ngIf="adminPermsDialog.show">
		<app-user-permissions [userId]="adminPermsDialog.userId" (saveComplete)="permissionsSaved()"></app-user-permissions>
	</div>
</app-basic-modal>

<app-delete-record-dialog
	*ngIf="deleteAction.showDeleteRecordDialog"
	[showDeleteRecordDialog]="deleteAction.showDeleteRecordDialog"
	[tableName]="deleteAction.tableName"
	[recordId]="deleteAction.recordId"
	[recordLabel]="deleteAction.recordLabel"
	(cancelDelete)="deleteActionCancel()"
	(actionComplete)="deleteActionComplete()"
></app-delete-record-dialog>

<app-modal-dialog *ngIf="linkedRecordsDialogManager.isDialogVisible" [dialogManager]="linkedRecordsDialogManager">
	<div *ngIf="linkedRecordsDialog.details" class="alert alert-info alert-bg-gray" style="margin-bottom: 20px">
		<div [innerHTML]="linkedRecordsDialog.details | sanitizer"></div>
	</div>
	<div style="margin-bottom: 15px">
		<app-search-filter [searchText]="dtFilterText" [placeholder]="'filter records'" (search)="filterLinkedRecords($event)"> </app-search-filter>
	</div>

	<ul style="color: gray">
		<li *ngFor="let record of linkedRecordsDialog.filteredRecords">{{ record }}</li>
	</ul>
</app-modal-dialog>

<app-modal-dialog *ngIf="transferOwnershipDialogManager.isDialogVisible" [dialogManager]="transferOwnershipDialogManager">
	<div *ngIf="transferOwnershipDialog.isUpdating">
		<div class="updating-note"><i class="fas fa-loader fa-spin"></i> Updating Records...</div>
	</div>
	<div *ngIf="!transferOwnershipDialog.isUpdating">
		<div style="font-weight: bold; color: #444; line-height: 1.2em">
			Select another user to transfer ownership of employee and job site records which currently belong to
			<span style="color: chocolate">{{ transferOwnershipDialog.sourceName }}</span>
		</div>
		<div class="options-block" style="margin-top: 25px">
			<div *ngIf="!transferOwnershipDialog.destId" class="input-required float-right">(required)</div>
			<div class="options-title tts-hl-2">Transfer Ownership To</div>
			<p-select
				appScrollLockSelect
				id="supervisorRole"
				[options]="transferOwnershipDialog.userList"
				[(ngModel)]="transferOwnershipDialog.destId"
			></p-select>
		</div>
		<div style="margin-top: 25px; font-size: 0.9em; line-height: 1.1em; color: #777">
			Note: This action only affects records for the company you are currently managing. For multi-company supervisors, you will need to transfer
			ownership of records from within each company you wish to change.
		</div>
	</div>
</app-modal-dialog>

<app-modal-dialog *ngIf="sectionPrefsDialogManager.isDialogVisible" [dialogManager]="sectionPrefsDialogManager">
	<app-local-prefs [dialogManager]="sectionPrefsDialogManager" (prefsUpdated)="prefsDataSaved()"></app-local-prefs>
</app-modal-dialog>

<app-modal-dialog *ngIf="userSkillDialogManager.isDialogVisible" [dialogManager]="userSkillDialogManager">
	<app-user-skill-link-list [dialogManager]="userSkillDialogManager" (skillLinkChange)="onSkillLinkChange()"></app-user-skill-link-list>
</app-modal-dialog>

<p-dialog
	styleClass="p-dialog-noheader p-dialog-whitebackground"
	header="Audit Options"
	[(visible)]="viewAuditAction.isAuditTypePickerVisible"
	[modal]="true"
	[style]="{ width: '300px' }"
	[showHeader]="false"
	[dismissableMask]="true"
	[focusOnShow]="false"
>
	<!-- This hidden button is to fix a rendering bug which highlights first button -->
	<div>
		<div>
			<button class="btn-menu btn-menu-primary" style="margin-top: 6px; margin-bottom: 10px" (click)="viewAuditLog(null)">
				Record Modification
			</button>
			<button class="btn-menu btn-menu-primary" style="margin-bottom: 6px" (click)="viewAuditLog('CALLCENTER')">Call Center Activity</button>
		</div>
	</div>
</p-dialog>
