import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import {
	TransactionMetaData,
	TransactionLogRecord,
	TransEventLogEntry,
	TransAlert,
	ImageFile,
	HelpDialogMessage,
	TransMetaGeoInfo,
} from '@app/models'
import { CoreService } from '@app/services'
import { log } from '@app/helpers'
import moment from 'moment-timezone'

@Component({
    selector: 'app-trans-meta-view',
    templateUrl: './shift-details-view.component.html',
    styleUrls: ['./shift-details-view.component.scss'],
    standalone: false
})
export class ShiftDetailsViewComponent implements OnInit {
	@Input() transId: number
	@Input() inOut: 'IN' | 'OUT'
	@Input() qrcRequired = false

	@Output() showMapMarker = new EventEmitter<{ type: 'IN' | 'OUT' | 'CHECKPOINT'; index: number }>()

	dateFormat = 'ddd MMM Do [at] h:mm a z' // or 'ddd MMM Do [at] HH:mm'"

	trans: TransactionLogRecord
	timezone: string
	refTs: number = null // Unix timestamp used for immage range check

	metaData: TransactionMetaData
	checkInType: string
	rawDataVisible = false

	showJobCodeBlock = false
	employeeName: string

	checkinEntries: Array<TransEventLogEntry> = []
	checkoutEntries: Array<TransEventLogEntry> = []

	transAlertMessage: string

	images: Array<ImageFile>

	geoInfo: TransMetaGeoInfo

	constructor(private coreSrvc: CoreService) {
		const company = this.coreSrvc.dbSrvc.settingSrvc.getCompany()
		this.showJobCodeBlock = company.useJobCodes
		const userPrefs = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()
		this.dateFormat = userPrefs.globalFormatTime12Hours ? 'ddd MMM Do [at] h:mm a z' : 'ddd MMM Do [at] HH:mm z'
		this.qrcRequired = this.coreSrvc.dbSrvc.settingSrvc.getCompany().validate_qr_codes
	}

	get showDetails(): boolean {
		if (this.inOut === 'IN' && !this.trans.actual_start && !this.trans.geo_start_images_json && !this.trans.geo_start_emp_note) {
			return false
		}
		if (this.inOut === 'OUT' && !this.trans.actual_end && !this.trans.geo_end_images_json && !this.trans.geo_end_emp_note) {
			return false
		}
		return true
	}

	get mapIconSrc(): string {
		const hasGps = this.inOut === 'IN' ? !!this.trans.geo_start_latitude : !!this.trans.geo_end_latitude
		if (this.inOut === 'IN') {
			return hasGps ? '/assets/img/marker-black-i.png' : '/assets/img/marker-checkin-nogps.png'
		} else {
			return hasGps ? '/assets/img/marker-black-o.png' : '/assets/img/marker-checkout-nogps.png'
		}
	}

	ngOnInit() {
		log('transId', this.transId)
		log('inOut', this.inOut)
		const trans = this.coreSrvc.dbSrvc.tranSrvc.getTransactionById(this.transId)
		if (trans) {
			this.trans = trans
			this.timezone = trans.timezone
			this.metaData = this.inOut === 'IN' ? new TransactionMetaData('IN', trans) : new TransactionMetaData('OUT', trans)
			log('Trans MetaData', this.metaData)
			log('Transaction', trans)
			this.setupTransactionData(trans)
			this.setupMetaData()
			// this.setupIvrLog()
			this.transAlertMessage = trans.exception ? new TransAlert(trans).alertMessage() : null
			this.images = trans.getImages(this.inOut)
			log('Images', this.images)
			const delay = this.coreSrvc.dbSrvc.settingSrvc.getCompany().gps_allowed_delay
			this.geoInfo = new TransMetaGeoInfo(this.inOut, this.trans, delay)

			// Setup reference timestamp
			const refTs = this.inOut === 'IN' ? moment(this.trans.actual_start).unix() : moment(this.trans.actual_end).unix()
			if (this.inOut === 'IN' && this.trans.actual_start) {
				this.refTs = refTs
			}
			if (this.inOut === 'OUT' && this.trans.actual_end) {
				this.refTs = refTs
			}
			log('Ref TS', this.inOut, this.refTs)
		}
	}

	setupTransactionData(trans: TransactionLogRecord) {
		if (trans) {
			this.employeeName = trans.employee_first + ' ' + trans.employee_last
		}
	}

	setupMetaData() {
		const metaData = this.metaData
		this.checkInType = metaData.checkInOutTypeLabel
		if (this.checkInType === 'station' && this.trans?.['inMetaData']?.mobileStationE164) {
			this.checkInType = 'mobile station'
		}
	}

	jobCodesEnteredFormatter(): string {
		const codeOne = this.metaData.jobCodeEntered1
		const codeTwo = this.metaData.jobCodeEntered2
		return `${codeOne || codeOne === 0 ? codeOne : 'NONE'} / ${codeTwo || codeTwo === 0 ? codeTwo : 'NONE'}`
	}

	public showOnMap(): void {
		const hasGps = this.inOut === 'IN' ? !!this.trans.geo_start_latitude : !!this.trans.geo_end_latitude
		if (hasGps) {
			const event = { type: this.inOut, index: null }
			this.showMapMarker.next(event)
		} else {
			this.coreSrvc.notifySrvc.notify('info', 'No GPS', 'This event does not have GPS information recorded.')
		}
	}

	public showHelp(key: string): void {
		let topic = new HelpDialogMessage(null, null)
		switch (key) {
			case 'gpsDistance':
				topic.header = 'GPS Distance'
				topic.message = 'Indicates how far away from the expected location the employee was when sharing their GPS.'
				break
			case 'gpsTime':
				topic.header = 'GPS Response'
				topic.message = 'Indicates how quickly the employee shared their GPS when requested by the system.'
				break
		}
		this.coreSrvc.notifySrvc.showHelp(topic)
	}
}
