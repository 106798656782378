import { AfterContentInit, Component, Input } from '@angular/core'
import { BaseRecord, CardViewAction, ComponentBridgeName, DatabaseTableName, DialogManager, EditCompleteEvent, EditFormAction } from '@app/models'
import { CoreService } from '@app/services'

import { log } from '@app/helpers'

@Component({
	selector: 'app-base-list',
	standalone: false,

	templateUrl: './base-list.component.html',
	styleUrl: './base-list.component.scss',
})
export class BaseListComponent implements AfterContentInit {
	resource: DatabaseTableName = 'none'

	isLoading = true
	editAction = { recordId: null, action: 'edit' as EditFormAction, isEditing: false }

	list: Array<BaseRecordWrapper> = []

	@Input() dialogManager = new DialogManager()

	constructor(private coreSrvc: CoreService) {
		this.loadData() // Used for testing
		// this.fetchAndReload()
	}

	ngAfterContentInit(): void {
		this.dialogManager.submitBtnAction = () => this.newRecord()
	}

	private loadData(): void {
		this.list = this.coreSrvc.dbSrvc.baseSrvc.getBaseRecords().map((record) => new BaseRecordWrapper(record))
		this.isLoading = false
	}

	private fetchAndReload(): Promise<boolean> {
		return new Promise((resolve) => {
			this.coreSrvc.dbSrvc.readTable(this.resource).then(() => {
				this.loadData()
				resolve(true)
			})
		})
	}

	private resetDialog(): void {
		this.editAction = { recordId: null, action: 'edit', isEditing: false }
		this.dialogManager.popStateAndApply()
		this.dialogManager.restoreScrollPosition('main')
	}

	public actionIconClicked(event: Event, action: CardViewAction, vm: BaseRecordWrapper) {
		event.stopPropagation()
		switch (action) {
			case 'EDIT':
				this.prepareDialogForEdit(vm, 'edit', 'Edit Record')
				break
			case 'CLONE':
				this.prepareDialogForEdit(vm, 'clone', 'Clone Record')
				break
			case 'DELETE':
				this.list.forEach((r) => (r.isConfimingDelete = false))
				vm.isConfimingDelete = true
				break
			case 'AUDIT':
				this.showAuditLogDialog(vm)
				break
			case 'EXPAND':
				vm.isExpanded = !vm.isExpanded
				break
		}
	}

	private prepareDialogForEdit(vm: BaseRecordWrapper, action: EditFormAction, header: string): void {
		this.dialogManager.saveScrollPosition('main')
		this.dialogManager.pushState()
		this.dialogManager.cancelBtnAction = () => this.resetDialog()
		this.dialogManager.cancelBtnLabel = 'Cancel'
		this.dialogManager.submitBtnLabel = 'Save'
		this.dialogManager.isSubmitBtnVisible = true
		this.dialogManager.headerLabel = header
		this.editAction.recordId = vm.record.id
		this.editAction.action = action
		this.editAction.isEditing = true
	}

	public newRecord() {
		const vm = new BaseRecordWrapper(new BaseRecord())
		this.prepareDialogForEdit(vm, 'new', 'New Record')
	}

	public recordUpdated(event: EditCompleteEvent) {
		const action = event.action

		if (action === 'new' || action === 'clone') {
			this.fetchAndReload().then(() => {
				this.resetDialog()
			})
		}

		if (action === 'edit') {
			const recordId = event.recordId
			const record = this.coreSrvc.dbSrvc.baseSrvc.getBaseRecordById(recordId)
			this.list.find((r) => r.record.id === record.id).record = record
			this.resetDialog()
		}
	}

	public confirmDelete(vm: BaseRecordWrapper): void {
		vm.isDeleting = true
		setTimeout(() => {
			this.list = this.list.filter((r) => r !== vm)
			vm.isConfimingDelete = false
		}, 1000)
	}

	private showAuditLogDialog(vm: BaseRecordWrapper): void {
		setTimeout(() => {
			const recordId = vm.record.id
			const record = this.coreSrvc.dbSrvc.baseSrvc.getBaseRecordById(recordId)
			const header = record.description

			const globalBridge: ComponentBridgeName = 'ngBridgeGlobal'
			const bridge = window[globalBridge]
			bridge.showAuditLog('base', recordId, header)
		}, 250)
		this.dialogManager.isDialogVisible = false
	}
}

class BaseRecordWrapper {
	record: BaseRecord
	isExpanded = false
	isConfimingDelete = false
	isDeleting = false

	constructor(record: BaseRecord) {
		this.record = record
	}
}
