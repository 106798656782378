import { Injectable, NgZone } from '@angular/core'
import { log } from '@app/helpers/logger'
import { HelpDialogMessage, ToastSeverity } from '@app/models'
import { Global } from '@app/models/global'
import { Subject } from 'rxjs'

type DefaultNotificationKey = 'operationNotAuthorized' | 'accessNotAuthorized'

@Injectable({
	providedIn: 'root',
})
export class NotificationsService {
	constructor(private zone: NgZone) {
		log('Creating NotificationService')
	}

	noPrimaryAdminNotificationPresented = false

	companyChangedNotification: Subject<boolean> = new Subject<boolean>()
	notificationAlert: Subject<Object> = new Subject<Object>()
	notificationClear: Subject<boolean> = new Subject<boolean>()

	databaseFinishedLoading: Subject<boolean> = new Subject<boolean>()

	helpMessage: Subject<HelpDialogMessage> = new Subject<HelpDialogMessage>()

	navBarNeedsUpdate = new Subject<boolean>()

	tracker = {
		globalBillingNoticePosted: false,
	}

	notify(severity: ToastSeverity, summary: string, detail: string, duration?: number) {
		this.zone.run(() => {
			if (duration) {
				const life = duration * 1000
				this.notificationAlert.next({
					severity: severity,
					summary: summary,
					detail: detail,
					key: 'noteSrvc',
					life: life,
				})
			} else {
				this.notificationAlert.next({
					severity: severity,
					summary: summary,
					detail: detail,
					key: 'noteSrvc',
					sticky: true,
				})
			}
		})
		// navigator?.vibrate?.(200) // Need to use optional chaining to not crash safari
	}

	clear() {
		this.zone.run(() => {
			this.notificationClear.next(true)
		})
	}

	default(key: DefaultNotificationKey) {
		switch (key) {
			case 'operationNotAuthorized':
				this.notify('error', 'Not Authorized', 'You are not authorized to perform this operation.', 2)
				break
			case 'accessNotAuthorized':
				this.notify('error', 'Not Authorized', 'You are not authorized to access this section.', 2)
				break
		}
	}

	showHelp(helpMessage: HelpDialogMessage) {
		this.zone.run(() => {
			const hasMessage = helpMessage && helpMessage.message
			const defaultMessage = new HelpDialogMessage('Topic Unavailable', 'Could not determine the help topic to display.')
			this.helpMessage.next(hasMessage ? helpMessage : defaultMessage)
		})
	}

	postNotAuthorizedNotification() {
		this.notify('error', 'Not Authorized', 'You are not authorized to perform this action.', 3)
	}
}
