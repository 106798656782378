<div
	[id]="'card_' + wpVm.twp.uuid"
	[class.card]="!wpVm.isEditing"
	[class.card-error]="!wpVm.isEditing && (wpVm.twp.startOffset < 0 || wpVm.hasInputIssue)"
>
	<div>
		<div class="head-wrap" [class.block-events]="wpVm.isConfirmingDelete">
			<div [id]="'wpFlash_' + wpVm.twp.uuid">
				<div *ngIf="!wpVm.isEditing" class="icon-block float-right">
					<!-- <i *ngIf="!wpVm.isExpanded" class="fas fa-chevron-right"></i>
					<i *ngIf="wpVm.isExpanded" class="fas fa-chevron-down"></i> -->
					<i class="far fa-edit icon-wrap" style="color: green" (click)="editWaypoint.next(wpVm); wpVm.isEditing = true"></i>
					<!-- <i class="far fa-clone icon-wrap" (click)="cloneWaypoint.next(wpVm)"></i> -->
					<i class="far fa-trash-alt icon-wrap" style="color: firebrick" (click)="deleteBtnClicked(wpVm)"></i>
					<i class="fas fa-arrow-up icon-wrap" [class.icon-disabled]="idx === 0" (click)="moveUp.next(wpVm)"></i>
					<i class="fas fa-arrow-down icon-wrap" [class.icon-disabled]="idx === listLength - 1" (click)="moveDown.next(wpVm)"></i>
				</div>
				<div class="chkpt-num tts-hl-2">
					<div>Checkpoint #{{ idx + 1 }}</div>
					<!-- Advanced Checkpoint Tour -->
					<div *ngIf="tourType === 'STRUCTURED' || tourType === 'STRUCTURED_HOUR_START'">
						<input
							#timeOffset
							type="tel"
							class="form-control form-inline num-input"
							style="margin-left: 0px"
							[class.input-error]="wpVm.twp.startOffset < 0 || wpVm.hasInputIssue"
							[(ngModel)]="wpVm.twp.startOffset"
							(click)="timeOffset.select()"
							(change)="validateInt('startOffset')"
							(focus)="inputFocused.next(wpVm)"
							(blur)="inputBlurred.next(wpVm)"
							(keyup.enter)="timeOffset.blur()"
						/>
						{{ offsetStartLabel }}
					</div>

					<!-- Timed Checkpoint Tour -->
					<div *ngIf="tourType === 'STRUCTURED_DAY_START'">
						<!-- <div style="font-weight: 500; font-size: 1rem; color: chocolate">startOffset: {{ wpVm.twp.startOffset }}</div> -->
						<div style="width: 160px">
							<app-date-time-picker
								[currentDate]="wpVm.clockTime"
								[pickerType]="'TIME'"
								[pickerMode]="'dialog'"
								[pickerLabel]="'Offset Time'"
								[format]="is12HourFormat ? 'h:mm a' : 'HH:mm'"
								[hour12Timer]="is12HourFormat"
								(pickerDidClose)="setWaypointClockTime($event)"
							>
							</app-date-time-picker>
						</div>
					</div>
				</div>
			</div>

			<!-- <div>
				<pre>{{ wpVm | json }}</pre>
			</div> -->

			<!-- <div class="uuid">{{ wp.uuid }}</div> -->
			<div>
				<div class="gps-coords">
					<strong
						>GPS:&nbsp;
						<span *ngIf="wpVm.isEditing && wpVm.twp.latitude && wpVm.twp.longitude" style="color: green"
							>{{ wpVm.twp.latitude }}, {{ wpVm.twp.longitude }}</span
						>
						<span *ngIf="!wpVm.isEditing && wpVm.twp.latitude && wpVm.twp.longitude" style="color: green"
							>&lt; location information available &gt;</span
						>
						<span *ngIf="!(wpVm.twp.latitude && wpVm.twp.longitude)" style="color: chocolate"
							>&lt; location information not available &gt;</span
						>
					</strong>
				</div>
				<!-- <div>ORDER: {{ wpVm.hasOrderIssue }} / REPEAT: {{ wpVm.hasRepeatIssue }}</div> -->
			</div>
		</div>

		<div>
			<div *ngIf="!wpVm.isEditing">
				<div *ngIf="wpVm.twp.name" class="cp-name-label top-pad">{{ wpVm.twp.name }}</div>
				<div *ngIf="wpVm.twp.description" class="cp-desc table-note-block top-pad">{{ wpVm.twp.description }}</div>
			</div>

			<div *ngIf="wpVm.isEditing">
				<hr />
				<div class="options-block">
					<div class="options-title">
						Public Identifier
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('identifier')"></i>
					</div>
					<input class="form-control" style="height: 120px" placeholder="" [(ngModel)]="wpVm.twp.name" />
				</div>
				<div class="options-block">
					<div class="options-title">
						Checkpoint Requirements
						<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('requirements')"></i>
					</div>
					<textarea
						class="form-control"
						style="height: 120px"
						placeholder="Enter checkpoint requirements ..."
						[(ngModel)]="wpVm.twp.description"
					></textarea>
				</div>
				<hr />

				<div *ngIf="linkedJobOptions.length > 0">
					<p-select
						appScrollLockSelect
						id="gpsDropdown"
						#setGpsDropdown
						placeholder="Set GPS from Linked Job"
						[scrollHeight]="'340px'"
						[options]="linkedJobOptions"
						[(ngModel)]="selectedGpsItem"
						(onChange)="setGpsFromJob($event)"
					></p-select>
					<!-- <hr /> -->
				</div>

				<div *ngIf="wpVm.isEditing" style="margin-top: 20px">
					<app-edit-map-marker
						#mapMarkerPicker
						[latitude]="wpVm.twp.latitude"
						[longitude]="wpVm.twp.longitude"
						[showClearLink]="true"
						(gpsUpdated)="gpsUpdated($event)"
					></app-edit-map-marker>
				</div>
			</div>

			<div *ngIf="wpVm.isConfirmingDelete">
				<hr />
				<div class="head-wrap d-flex align-items-center">
					<div class="d-flex justify-content-between flex-grow-1">
						<div class="flex-grow-1 delete-label">Confirm Delete</div>
						<div class="d-flex">
							<button class="btn btn-sm btn-block btn-secondary" (click)="wpVm.isConfirmingDelete = false">Cancel</button>
							<button class="btn btn-sm btn-block btn-danger" style="margin-left: 8px" (click)="deleteConfirmed(wpVm)">Delete</button>
						</div>
					</div>
				</div>
			</div>

			<div *ngIf="wpVm.twp.imageFiles.length > 0">
				<hr />
				<app-image-files-view [images]="wpVm.twp.getImageFiles()" [timezone]="timezone"></app-image-files-view>
			</div>

			<div *ngIf="wpVm.isEditing" style="margin-top: 20px">
				<div class="options-title">Manage Photos</div>
				<app-file-uploader
					[fileUploadManager]="fileUploadManager"
					[canEditLinkText]="false"
					(listUpdated)="updateCurrentImagesList()"
				></app-file-uploader>
			</div>
		</div>
	</div>
</div>
