<div>
	<app-shift-report-renderer
		*ngIf="reportType === 'SHIFT'"
		[config]="config"
		[publicView]="publicView"
		(closeBtnClicked)="closeFullScreenView($event)"
	></app-shift-report-renderer>
	<app-homecare-report-renderer
		*ngIf="reportType === 'HOMECARE'"
		[config]="config"
		[publicView]="publicView"
		(closeBtnClicked)="closeFullScreenView($event)"
	></app-homecare-report-renderer>
	<app-incident-report-renderer
		*ngIf="reportType === 'INCIDENT'"
		[config]="config"
		[publicView]="publicView"
		(closeBtnClicked)="closeFullScreenView($event)"
	></app-incident-report-renderer>
	<app-vehicle-inspection-report-renderer
		*ngIf="reportType === 'VEHICLEINSPECTION'"
		[config]="config"
		[publicView]="publicView"
		(closeBtnClicked)="closeFullScreenView($event)"
	>
	</app-vehicle-inspection-report-renderer>
	<app-checklist-report-renderer
		*ngIf="
			reportType === 'CHECKIN' || reportType === 'PRE_BREAK' || reportType === 'POST_BREAK' || reportType === 'CHECKOUT' || reportType === 'CUSTOM'
		"
		[config]="config"
		[publicView]="publicView"
		(closeBtnClicked)="closeFullScreenView($event)"
	></app-checklist-report-renderer>
	<app-shift-summary-report-renderer
		*ngIf="reportType === 'SHIFTSUMMARY'"
		[config]="config"
		[publicView]="publicView"
		(closeBtnClicked)="closeFullScreenView($event)"
	>
	</app-shift-summary-report-renderer>
</div>

<p-toast position="top-right" key="publicReportToast" [baseZIndex]="10000"></p-toast>
