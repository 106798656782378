import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { DisplayHelper, GeneralTableHelper, TableActionFormatter, log } from '@app/helpers'
import {
	DialogManager,
	ProjectRecord,
	LocalPrefsData,
	LocalPrefsDialogData,
	LocalPrefsGroup,
	DatabaseTableName,
	CrudAction,
	EditFormAction,
	ComponentBridgeName,
} from '@app/models'
import { CoreService } from '@app/services'
import { environment } from '@env/environment'

import { Router } from '@angular/router'
import { Subscription } from 'rxjs'

import moment from 'moment-timezone'
import _ from 'lodash'

enum ProjectTableColumnIndex {
	id = 0,
	date,
	actions,
}

@Component({
    selector: 'app-project-table',
    templateUrl: './project-table.component.html',
    styleUrls: ['./project-table.component.scss'],
    standalone: false
})
export class ProjectTableComponent implements OnInit, OnDestroy, AfterViewInit {
	bridgeName: ComponentBridgeName = `ngBridgeProjectTable`
	resource: DatabaseTableName = 'project'
	tableName = 'projectTable'

	list: Array<ProjectRecord> = []

	public debounceRedrawTable = _.debounce(this.redrawTable, 300)

	private dataTable // : DataTables.DataTable;
	private defaultSortOrder = [[ProjectTableColumnIndex.date, 'desc']]

	private subs = new Subscription()

	public sectionPrefsDialogManager = new DialogManager('sectionPrefsDialog')

	public editAction = { recordId: null, action: 'edit' as EditFormAction }
	public editDialogManager = new DialogManager(`${this.tableName}EditDialog`)

	public deleteAction = { tableName: this.resource as DatabaseTableName, recordId: null, recordLabel: null, showDeleteRecordDialog: false }

	constructor(
		private cd: ChangeDetectorRef,
		private router: Router,
		private coreSrvc: CoreService,
	) {
		this.setupSubscriptions()
		this.setupLocalPrefsDialog()
	}

	get dtFilterText(): string {
		const input = $('.search-field-input')
		return (input?.val() as string) || ''
	}

	get viewManager() {
		return this.coreSrvc.dbSrvc.incidentLogSrvc.viewManager
	}

	public ngOnInit(): void {
		window[this.bridgeName] = this
		this.fetchAndReload()
	}

	public ngOnDestroy() {
		this.subs.unsubscribe()
		this.dataTable['fixedHeader'].disable()
		window[this.bridgeName] = null
	}

	public ngAfterViewInit() {
		this.initTable()

		$(`#${this.tableName}_filter input`).attr(`placeholder`, ` filter`)
		$(`#${this.tableName}_filter input`).addClass(`search-field-input`)

		setTimeout(() => {
			$(`#clear-search-icon`).detach().appendTo(`#${this.tableName}_filter label`)
		})

		$(`#${this.tableName}_filter input`).on('keyup', () => {
			this.debounceRedrawTable()
		})
	}

	private setupSubscriptions() {
		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	private setupLocalPrefsDialog(): void {
		// this.sectionPrefsDialogManager.headerLabel = "Preferences";
		// let columnVisibilityItems = LocalPrefsData.getProjectColumnDisplayPrefs();
		// const columnVisibilityGroup = new LocalPrefsGroup(
		//   "Column Visibilityy",
		//   columnVisibilityItems
		// );
		// const dialogData = new LocalPrefsDialogData([columnVisibilityGroup]);
		// dialogData.localStorageKeyRemovalList = [
		//   `DataTables_${this.tableName}`,
		// ];
		// this.sectionPrefsDialogManager.dialogData = dialogData;
	}

	private handleScreenSizeChanges = () => {
		if (this.dataTable) {
			this.dataTable.columns.adjust().responsive.recalc()
			this.dataTable.fixedHeader.adjust()
		}
	}

	public clearSearch(): boolean {
		this.dataTable
			?.search('')
			.order([[ProjectTableColumnIndex.date, 'desc']])
			.rows()
			.invalidate()
			.draw()
		this.coreSrvc.displaySrvc.enableAllTooltips()
		return false
	}

	public canPerformAction(action: CrudAction, isMyRecord: boolean) {
		// return this.accessHelper.canPerformAction(action, isMyRecord)
		return true
	}
	public isMyRecord(id: number): boolean {
		return true
	}

	public prefsDataSaved(): void {
		log('UpdatePrefsData')
		this.sectionPrefsDialogManager.isDialogVisible = false
		this.updateTable()
	}

	public openPrefsDialog(): void {
		this.sectionPrefsDialogManager.isDialogVisible = true
	}

	private loadData() {
		this.list = this.coreSrvc.dbSrvc.projSrvc.getProjectRecords()
	}

	public updateTable() {
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadData()
			this.updateColumns()
		})
	}

	private updateColumns() {
		const date = true // this.coreSrvc.prefSrvc.data.commLogCallTextColInOutVisible

		this.dataTable.clear()
		GeneralTableHelper.updateColumn(this.dataTable, ProjectTableColumnIndex.date, date)

		this.dataTable.rows.add(this.list)
		this.dataTable.draw(false)
	}

	public resetPage(reDraw: boolean) {
		this.dataTable.page(0)
		if (reDraw) {
			this.dataTable.draw(false)
		}
	}

	public fetchAndReload() {
		const options = this.makeRequestOptions()
		this.coreSrvc.dbSrvc.readTable(this.resource, options).then((readSuccess) => {
			this.updateTable()
		})
	}

	public redrawTable() {
		this.dataTable?.rows().invalidate().search(this.dtFilterText).draw(true)
		this.coreSrvc.displaySrvc.enableAllTooltips()
	}

	private makeRequestOptions(): Object {
		const dayViewDate = this.viewManager.dayViewDate
		if (dayViewDate) {
			const dateStr = moment(dayViewDate).format('YYYY-MM-DD')
			const timezone = this.viewManager.timezone
			const start = moment.tz(dateStr, timezone).startOf('day').toISOString()
			const end = moment.tz(dateStr, timezone).endOf('day').toISOString()
			return { start_date: start, end_date: end }
		}
		return {}
	}

	public createRecord() {
		log('createRecord')
		// if (!this.canPerformAction(CrudAction.create, null)) { this.accessHelper.notifyOperationNotAuthorized(); return }
		this.editAction.recordId = null
		this.editAction.action = 'new'
		this.editDialogManager.headerLabel = 'Add Record'
		this.editDialogManager.isDialogVisible = true
	}

	public editRecord(id: number) {
		log('editRecord', id)
		this.editAction.recordId = id
		this.editAction.action = 'edit'
		this.editDialogManager.headerLabel = 'Edit Record'
		this.editDialogManager.isDialogVisible = true
	}

	public cloneRecord(id: number) {
		log('cloneRecord', id)
		this.editAction.recordId = id
		this.editAction.action = 'clone'
		this.editDialogManager.headerLabel = 'Clone Record'
		this.editDialogManager.isDialogVisible = true
	}

	public recordUpdated(id: number) {
		// If id is not null, this was an edit and we refresh just the row
		// If id is null, this was an insert and we refresh the whole table
		if (id) {
			this.loadData()
			const record = this.coreSrvc.dbSrvc.projSrvc.getProjectRecordById(id)
			const idx = this.list.indexOf(record)
			this.dataTable.rows(idx).invalidate().draw(false)
		} else {
			this.updateTable()
		}
	}

	public deleteRecord(id: number) {
		log('deleteRecord called')
		const record = this.coreSrvc.dbSrvc.projSrvc.getProjectRecordById(id)
		if (record) {
			this.deleteAction.recordId = record.id
			this.deleteAction.recordLabel = 'Record Description' // record.description
			this.deleteAction.tableName = this.resource
			this.deleteAction.showDeleteRecordDialog = true
		}
	}

	public deleteActionCancel() {
		this.deleteAction.showDeleteRecordDialog = false
	}

	public deleteActionComplete() {
		this.loadData()
		this.updateTable()
		this.deleteAction.showDeleteRecordDialog = false
	}

	private initTable() {
		this.coreSrvc.displaySrvc.startSectionLoader()
		const mythis = this
		this.dataTable = $(`#${this.tableName}`).DataTable(<DataTables.Settings>{
			stateSave: false,
			responsive: true,
			processing: true,
			deferRender: false,
			paging: true,
			pageLength: 50,
			lengthChange: true,
			info: true,
			select: false,
			searching: true,
			fixedHeader: true,
			autoWidth: false,
			data: this.list,
			order: this.defaultSortOrder,
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{
					responsivePriority: 1,
					targets: [ProjectTableColumnIndex.date],
				},
				{
					// ID
					targets: ProjectTableColumnIndex.id,
					visible: false,
					searchable: true,
					title: 'ID',
					data: null,
					render: (rec: ProjectRecord, type, full, meta) => {
						return `${rec.id}`
					},
				},
				{
					// Date
					targets: ProjectTableColumnIndex.date,
					visible: true,
					searchable: true,
					title: 'Date',
					data: null,
					render: (rec: ProjectRecord, type, full, meta) => {
						const created = rec.created
						const date = 'DATE' // this.tableFormatter.date(rec.created, this.viewManager.timezone)
						const time = 'TIME' // this.tableFormatter.time(rec.created, this.viewManager.timezone)
						const result = `<span style="display: none">${created}</span>${date}<br>${time}`
						return `
						<div class="dtr-control-content">
							${result}
						</div>`
					},
				},
				{
					// Actions
					targets: ProjectTableColumnIndex.actions,
					visible: this.coreSrvc.prefSrvc.data.commLogCallTextColInOutVisible,
					searchable: this.coreSrvc.prefSrvc.data.commLogCallTextColInOutVisible,
					title: 'Actions',
					data: null,
					render: (rec: ProjectRecord, type, full, meta) => {
						const editLink = TableActionFormatter.editLink(this.bridgeName, 'editRecord', rec.id, true)
						const cloneLink = TableActionFormatter.cloneLink(this.bridgeName, 'cloneRecord', rec.id, true)

						const hasAuditLog = true
						const canAccessAudit = true
						const auditLink = TableActionFormatter.auditLinkGlobal(this.resource, rec.id, '', canAccessAudit, hasAuditLog)

						return `<span class="act-ico-wrap">${editLink}${cloneLink}${auditLink}</span>`
					},
				},
			],
			// 'createdRow': (row, data, dataIndex) => {
			// 	const enabled = this.checkEnabledStatus(data as Vacation)
			// 	if (!enabled) {
			// 		$(row).addClass('vac-disabled')
			// 	}
			// }
			drawCallback: (settings) => {
				this.dataTable?.columns.adjust().responsive.recalc()
				this.coreSrvc.displaySrvc.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
			},
		})
		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter(`${this.tableName}`, this.dataTable)
	}
}
