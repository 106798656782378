import { Component, OnInit, OnDestroy, AfterViewInit, Renderer2 } from '@angular/core'
import { TableActionFormatter, log, DisplayHelper, DataTablesHelper } from '@app/helpers'
import {
	CrudAction,
	DialogManager,
	DatabaseTableName,
	OrgJobPayRateRecord,
	PermissionResolver,
	PayRateViewManager,
	ComponentBridgeName,
} from '@app/models'
import { AccessHelper } from '@app/helpers/access'
import { Subscription } from 'rxjs'
import { CoreService } from '@app/services'
import { PayRateHelper, PayRateTableFormatter } from '../pay-rate.helper'
import { NumberHelper } from '@app/helpers/number'

import _ from 'lodash'

enum OrgJobPayRatesTableColumnIndex {
	id,
	name,
	job,
	payRate,
	actions,
}

@Component({
    selector: 'app-org-job-pay-rates-table',
    templateUrl: './org-job-pay-rates-table.component.html',
    styleUrls: ['./org-job-pay-rates-table.component.scss'],
    standalone: false
})
export class OrgJobPayRatesTableComponent implements OnInit, OnDestroy, AfterViewInit {
	bridgeName: ComponentBridgeName = 'ngBridgeOrgJobPayRatesTable'

	accessHelper: AccessHelper
	permResolver: PermissionResolver

	list: Array<OrgJobPayRateRecord> = []
	isDataLoaded = false

	editDialogManager = new DialogManager()
	editAction = { recordId: null, action: 'edit' }

	deleteAction = { tableName: 'vendor_client_job_pay_rate' as DatabaseTableName, recordId: null, recordLabel: null, showDeleteRecordDialog: false }

	showDetailsModal = false
	showFilterDatesModal = false
	showDiffRecordsModal = false

	highlightMissingExternalIds = false

	currencySymbol = ''

	private orgJobPayRatesTable: any
	private defaultSortOrder = [
		[OrgJobPayRatesTableColumnIndex.name, 'asc'],
		[OrgJobPayRatesTableColumnIndex.job, 'asc'],
	]
	private defaultPageLength = 50

	private subs = new Subscription()

	constructor(
		private coreSrvc: CoreService,
		private renderer: Renderer2,
	) {
		this.setupSubscriptions()
		this.setupAccessPermissions()

		// Check for missing ID highlight requirement
		this.highlightMissingExternalIds = this.coreSrvc.dbSrvc.settingSrvc.getAdminPrefsForCompany().globalHighlightMissingExtId
		this.currencySymbol = this.coreSrvc.dbSrvc.settingSrvc.getCompanyCurrencySymbol()
	}

	get viewManager(): PayRateViewManager {
		return this.coreSrvc.dbSrvc.payRatesSrvc.viewManager
	}

	ngOnInit() {
		window[this.bridgeName] = this
		this.loadData()
	}

	ngAfterViewInit() {
		this.initTable()

		$('#orgJobPayRatesTable_filter input').attr('placeholder', ' filter')
		$('#orgJobPayRatesTable_filter input').addClass('search-field-input')

		setTimeout(() => {
			$('#clear-search-icon').detach().appendTo('#orgJobPayRatesTable_filter label')
		})

		this.fetchAndReloadData()
	}

	ngOnDestroy() {
		this.orgJobPayRatesTable['fixedHeader'].disable()
		window[this.bridgeName] = null
		this.subs.unsubscribe()
	}

	private setupSubscriptions() {
		this.subs.add(this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => this.handleScreenSizeChanges()))
	}

	private setupAccessPermissions() {
		// Use employee/job pay rate access permissions
		this.accessHelper = new AccessHelper(this.coreSrvc, 'payrates')
		this.permResolver = new PermissionResolver(this.coreSrvc.dbSrvc, 'employee_job_pay_rate')
		this.accessHelper.updateSupervisorIds()
	}

	private handleScreenSizeChanges() {
		if (this.orgJobPayRatesTable) {
			this.orgJobPayRatesTable.columns.adjust().responsive.recalc()
			this.orgJobPayRatesTable.fixedHeader.adjust()
		}
	}

	canPerformAction(action: CrudAction, isMyRecord: boolean) {
		return this.accessHelper.canPerformAction(action, isMyRecord)
	}
	isMyRecord(id: number): boolean {
		return true
	}

	clearSearch(): boolean {
		this.orgJobPayRatesTable
			.search('')
			.order([
				[OrgJobPayRatesTableColumnIndex.name, 'asc'],
				[OrgJobPayRatesTableColumnIndex.job, 'asc'],
			])
			.draw()
		return false
	}

	fetchData(): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			this.coreSrvc.dbSrvc.readTable('vendor_client_job_pay_rate').then((result) => {
				resolve(true)
			})
		})
	}

	loadData() {
		const list = this.coreSrvc.dbSrvc.payRatesSrvc.getOrgJobPayRates()
		let filteredList = list.filter((rec) => this.permResolver.canPerformAction(CrudAction.read, rec))
		if (this.viewManager.currentView === 'CLIENT') {
			filteredList = filteredList.filter((rec) => rec.type === 'CLIENT')
		}
		if (this.viewManager.currentView === 'VENDOR') {
			filteredList = filteredList.filter((rec) => rec.type === 'VENDOR')
		}
		this.list = filteredList

		// Check for missing pay rates if ADP integrated
		// if (this.isAdpIntegrated) {
		// 	const hasMissingRates = this.list.map((prr) => !prr.pay_rate).length > 0
		// 	this.isAdpWithMissingRates = hasMissingRates
		// }
	}

	fetchAndReloadData() {
		this.fetchData().then((success) => {
			this.updateTable()
		})
	}

	updateTable() {
		this.coreSrvc.displaySrvc.startSectionLoader().then(() => {
			this.loadData()
			this.updateColumns()
		})
	}

	private updateColumns() {
		// Rename the coloumn headers based on which whether we are looking at clients or vendors
		const isClientView = this.viewManager.currentView === 'CLIENT'
		const nameTitle = isClientView ? 'Client Name' : 'Vendor Name'
		const payRateTitle = isClientView ? 'Bill Rate' : 'Pay Rate'

		this.orgJobPayRatesTable.columns(OrgJobPayRatesTableColumnIndex.name).header().to$().text(nameTitle)
		this.orgJobPayRatesTable.columns(OrgJobPayRatesTableColumnIndex.payRate).header().to$().text(payRateTitle)

		this.orgJobPayRatesTable.clear()
		this.orgJobPayRatesTable.rows.add(this.list)
		this.orgJobPayRatesTable.draw(false)

		// Bind to key up on pay rate inputs
		// $('.pay-rate-table-input').on('keyup', (event) => this.processKeyUpEventFromPayRateInput(event))
	}

	createRecord() {
		log('createRecord called')
		if (!this.canPerformAction(CrudAction.create, null)) {
			this.accessHelper.notifyOperationNotAuthorized()
			return
		}

		const rateType = this.viewManager.currentView === 'CLIENT' ? 'Client Bill' : 'Vendor Pay'
		this.editAction.recordId = null
		this.editAction.action = 'new'
		this.editDialogManager.dialogData = this.editAction
		this.editDialogManager.headerLabel = `Add ${rateType} Rate`
		this.editDialogManager.isDialogVisible = true
	}

	// Bridge Methods
	public editRecord(id: number) {
		log('editRecord called')
		const isMyRecord = this.isMyRecord(id)
		if (!this.canPerformAction(CrudAction.update, isMyRecord)) {
			this.notifyOperationNotAuthorized()
			return
		}
	}

	recordUpdated() {
		log('recordUpdaated: Client record has been updated')
		this.loadData()
		this.updateTable()
	}

	deleteRecord(id: number) {
		log('deleteRecord called')
		const record = this.coreSrvc.dbSrvc.payRatesSrvc.getOrgJobPayRateById(id)
		const canDelete = this.permResolver.canPerformAction(CrudAction.delete, record)
		if (!canDelete) {
			this.notifyOperationNotAuthorized()
			return
		}

		const payRate = this.coreSrvc.dbSrvc.payRatesSrvc.getOrgJobPayRateById(id)
		if (payRate) {
			const orgName = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationById(payRate.vendor_client_id).name
			const jobName = this.coreSrvc.dbSrvc.jobSrvc.getJobById(payRate.job_id).description
			this.deleteAction.recordId = payRate.id
			this.deleteAction.recordLabel = `${orgName} / ${jobName}`
			this.deleteAction.tableName = 'vendor_client_job_pay_rate'
			this.deleteAction.showDeleteRecordDialog = true
		}
	}

	deleteActionComplete() {
		this.loadData()
		this.updateTable()
		this.deleteAction.showDeleteRecordDialog = false
	}

	deleteActionCancel() {
		this.deleteAction.showDeleteRecordDialog = false
	}

	processKeyUpEventFromPayRateInput(event) {
		// log('Event', event)
		const tagId = event?.currentTarget?.id
		const originalEvent = event?.originalEvent as KeyboardEvent
		const key = originalEvent?.key
		const type = originalEvent?.type
		// log('key/type', key, type)
		// if ((key === 'Enter' && type === 'keyup') || (key === 'Tab' && type === 'keydown')) {
		if (key === 'Enter') {
			this.updatePayRateIputForTagId(tagId)
		}
	}

	updatePayRateIputForTagId(tagId: string) {
		const payRateId = PayRateHelper.getPayRateIdFromTag(tagId)
		const payRateRecord = this.coreSrvc.dbSrvc.payRatesSrvc.getOrgJobPayRateById(payRateId)
		if (payRateRecord) {
			const payRateValue = $(`#${tagId}`).val() as number
			if (PayRateHelper.isValidPayRate(payRateValue)) {
				const newRecord = payRateRecord.clone()
				const newValue = NumberHelper.formatPayRate(payRateValue)
				newRecord.pay_rate = newValue
				this.coreSrvc.dbSrvc.updateRecord('vendor_client_job_pay_rate', newRecord).then((result) => {
					log('Update Result', result)
					const notice = 'Pay rate updated'
					this.coreSrvc.notifySrvc.notify('success', 'Updated', notice, 1)
					// this.updateTable()
					const updatedRate = this.coreSrvc.dbSrvc.payRatesSrvc.getOrgJobPayRateById(payRateId)
					this.invalidateRowForRecord(updatedRate)
					setTimeout(() => {
						const inputElem = $(`#${tagId}`)
						inputElem.trigger('focus')
					}, 200)
				})
			}
		}
	}

	updatePayRateRecordForSelectChange(id: number, rateCodeId: number) {
		log('Select Element', id, rateCodeId)
		const payRateRecord = this.coreSrvc.dbSrvc.payRatesSrvc.getOrgJobPayRateById(id)
		if (payRateRecord) {
			const newRecord = payRateRecord.clone()
			this.coreSrvc.dbSrvc.updateRecord('vendor_client_job_pay_rate', newRecord).then((result) => {
				log('Update Result', result)
				const notice = 'Pay rate updated'
				this.coreSrvc.notifySrvc.notify('success', 'Updated', notice, 1)
				const updatedRate = this.coreSrvc.dbSrvc.payRatesSrvc.getOrgJobPayRateById(id)
				this.invalidateRowForRecord(updatedRate)
			})
		}
	}

	notifyOperationNotAuthorized() {
		this.coreSrvc.notifySrvc.default('operationNotAuthorized')
	}

	invalidateRowForRecord(payRate: OrgJobPayRateRecord) {
		log('Invalidated', payRate)
		const idx = this.list.indexOf(payRate)
		this.orgJobPayRatesTable.rows(idx).invalidate().draw(false)
		const row = this.orgJobPayRatesTable.rows(idx) as Node
		log('Row Invalidated', row)
		$(`#payrate-input-id_${payRate.id}`).on('keyup', (event) => this.processKeyUpEventFromPayRateInput(event))
		this.removeTabIndexes()
	}

	removeTabIndexes() {
		setTimeout(() => {
			$('.dtr-control').attr('tabindex', -1)
		}, 100)
	}

	public setPage(num: number) {
		this.orgJobPayRatesTable?.page(num)
	}

	// DataTables Initialization

	private initTable() {
		this.coreSrvc.displaySrvc.startSectionLoader()
		this.orgJobPayRatesTable = $('#orgJobPayRatesTable').DataTable(<DataTables.Settings>{
			stateSave: false,
			responsive: true,
			processing: false,
			paging: true,
			pageLength: this.defaultPageLength,
			lengthChange: true,
			info: true,
			select: false,
			searching: true,
			fixedHeader: DataTablesHelper.fixedHeader,
			autoWidth: false,
			data: this.list,
			order: this.defaultSortOrder,
			orderMulti: false,
			language: { search: '' },
			columnDefs: [
				{
					responsivePriority: 1,
					targets: [OrgJobPayRatesTableColumnIndex.payRate],
				},
				{
					// ID
					targets: OrgJobPayRatesTableColumnIndex.id,
					visible: false,
					searchable: true,
					title: 'ID',
					tabindex: -1,
					data: null,
					render: (rec: OrgJobPayRateRecord, t, f, m) => {
						return rec.id
					},
				},
				{
					// Name
					targets: OrgJobPayRatesTableColumnIndex.name,
					visible: true,
					searchable: true,
					title: 'Name',
					tabindex: -1,
					data: null,
					render: (rec: OrgJobPayRateRecord, t, f, m) => {
						const org = this.coreSrvc.dbSrvc.orgSrvc.getOrganizationById(rec.vendor_client_id)
						const name = org?.name || ''
						return `
						<div class="dtr-control-content">
							${name}
						</div>`
					},
				},
				{
					// Job
					targets: OrgJobPayRatesTableColumnIndex.job,
					visible: true,
					searchable: true,
					title: 'Job',
					tabindex: -1,
					data: null,
					render: (rec: OrgJobPayRateRecord, t, f, m) => {
						const jobName = this.coreSrvc.dbSrvc.jobSrvc.getJobById(rec.job_id)?.description ?? ''
						return `<div>${jobName}</div>`
					},
				},
				{
					// Pay Rate
					targets: OrgJobPayRatesTableColumnIndex.payRate,
					visible: true,
					searchable: true,
					title: 'Pay Rate',
					data: null,
					render: (rec: OrgJobPayRateRecord, t, f, m) => {
						const canEdit = this.permResolver.canPerformAction(CrudAction.update, rec)
						return PayRateTableFormatter.payRateInput(rec, this.currencySymbol, canEdit)
					},
				},
				{
					// Actions
					targets: OrgJobPayRatesTableColumnIndex.actions,
					visible: true,
					searchable: false,
					orderable: false,
					title: 'Actions',
					tabindex: -1,
					data: null,
					render: (rec: OrgJobPayRateRecord, t, f, m) => {
						const recordId = rec.id
						const isMyRecord = this.isMyRecord(rec.id)

						const canDelete = this.permResolver.canPerformAction(CrudAction.delete, rec)
						const deleteLink = TableActionFormatter.deleteLink(this.bridgeName, 'deleteRecord', recordId, canDelete)

						const recType = rec.type === 'CLIENT' ? 'Client Bill Rate' : 'Vendor Pay Rate'
						const auditLink = TableActionFormatter.auditLinkGlobal('vendor_client_job_pay_rate', recordId, recType, true, !!rec.updated)

						return `<span class="act-ico-wrap">${deleteLink}${auditLink}</span>`
					},
				},
			],

			createdRow: (row, data, dataIndex) => {
				$(row)
					.find('.pay-rate-input')
					.on('keyup', (event) => this.processKeyUpEventFromPayRateInput(event))
			},

			drawCallback: (settings) => {
				this.orgJobPayRatesTable?.columns.adjust().responsive.recalc()
				this.coreSrvc.displaySrvc.enableAllTooltips()
				this.coreSrvc.displaySrvc.stopSectionLoader()
			},
		})
		// Add search filter highlighting
		DisplayHelper.setupTableFilterHighlighter('orgJobPayRatesTable', this.orgJobPayRatesTable)

		$('#orgJobPayRatesTable').on('draw.dt', () => {
			this.removeTabIndexes()
		})
		$('#orgJobPayRatesTable').on('order.dt', () => {
			this.removeTabIndexes()
		})
		$('#orgJobPayRatesTable').on('page', () => {
			this.orgJobPayRatesTable['fixedHeader'].adjust()
			this.updateTable()
		})
	}
}
