<div class="score-progress-bar">
	<!-- <div class="score-progress-fill" [style.width]="100 + '%'"></div> -->
	<div class="score-progress-fill" [style.width]="healthScore + '%'"></div>
	<div class="score-progress-content" [class.has-pointer]="navigateOnClick" (click)="navigateToHealthCenter()">
		<div>
			<span class="health-icon-wrap"><i class="fad fas fa-heart-pulse"></i></span>
			<span class="category-label">{{ isRefreshing ? 'Refreshing Score' : 'Health Score' }}</span>
			<i *ngIf="!isRefreshing && !navigateOnClick" class="far fa-refresh refresh-icon" (click)="refreshScore(true, true)"></i>
		</div>
		<div style="margin-right: 8px">
			<span *ngIf="!isRefreshing">{{ healthScore }}%</span>
			<i *ngIf="isRefreshing" class="far fa-loader fa-spin"></i>
		</div>
	</div>
</div>
