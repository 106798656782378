<div *ngIf="mergedDetails">
	<!-- Checkout Details -->
	<app-form-expander [label]="getEventType('IN')" [isExpanded]="showCheckinDetails">
		<app-trans-meta-view [transId]="transId" [inOut]="'IN'" (showMapMarker)="showMapMarker.next($event)"></app-trans-meta-view>
		<hr style="margin-top: 28px" />
	</app-form-expander>

	<!-- Checkout Details -->
	<app-form-expander [label]="getEventType('OUT')" [isExpanded]="showCheckoutDetails">
		<app-trans-meta-view [transId]="transId" [inOut]="'OUT'" (showMapMarker)="showMapMarker.next($event)"></app-trans-meta-view>
		<hr style="margin-top: 28px" />
	</app-form-expander>
</div>

<div *ngIf="!mergedDetails">
	<app-trans-meta-view *ngIf="!mergedDetails" [transId]="transId" [inOut]="inOut"></app-trans-meta-view>
	<hr style="margin-top: 28px" />
</div>

<!-- Employee Notes -->
<app-form-expander *ngIf="trans.emp_notes" [label]="'System Notes'" [isExpanded]="showEmployeeNotes">
	<div *ngIf="trans.emp_notes">
		<div *ngIf="trans.emp_notes" class="form-group form-group-lg">
			<div class="notes-block">{{ trans.emp_notes | adminNotesFormatter }}</div>
		</div>
	</div>
	<div *ngIf="!trans.emp_notes">
		<strong>No employee notes submitted</strong>
	</div>
</app-form-expander>

<!-- Event Log -->
<app-shift-details-event-log [transId]="transId"></app-shift-details-event-log>
