import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Incident, ReportRendererConfig, UserReportType } from '@app/models'
import { EventService } from '@app/services'
import { MessageService } from 'primeng/api'

@Component({
    selector: 'app-user-report-render-host',
    templateUrl: './user-report-render-host.component.html',
    styleUrls: ['./user-report-render-host.component.scss'],
    standalone: false
})
export class UserReportRenderHostComponent implements OnInit, OnDestroy {
	@Input() config: ReportRendererConfig
	@Input() incident: Incident
	@Input() reportType: UserReportType
	@Input() publicView = false

	// shiftReport = { notes: '< no information provided >' } - Unknown Use

	constructor(private eventSrvc: EventService, private msgSrvc: MessageService) {}

	// Intercept back button click to close the renderer
	popstateBlocker = (event) => {
		this.closeFullScreenView()
	}

	ngOnInit(): void {
		this.reportType = this.config.reportType
		this.publicView = this.config.publicView
		this.incident = this.config.reportData as Incident

		// Add this location to prevent back button from navigation back on the real page that
		// this renderer is hiding. Pop that location if the close box is used but let the
		// back button pop it.

		history.pushState(null, null, location.href)
		window.addEventListener('popstate', this.popstateBlocker)
		if (!this.publicView) {
			this.eventSrvc.addBeforeUnloadBlocker()
		}

		// Jump to top after we give the report a chance to begin rendering
		setTimeout(() => {
			scrollTo(0, 0)
		}, 150)
	}

	ngOnDestroy(): void {
		window.removeEventListener('popstate', this.popstateBlocker)
	}

	closeFullScreenView(needsPop?: boolean) {
		if (needsPop) {
			setTimeout(() => {
				if (needsPop) {
					history.back()
				}
			}, 250)
		}
		const eventData = { restoreScrollPosition: true }
		this.eventSrvc.closeFullscreenView(eventData)
		this.eventSrvc.showFixedHeader.next(true)
	}
}
