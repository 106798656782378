import { Component, OnInit, AfterViewInit, Input, ChangeDetectorRef } from '@angular/core'
import { HomecareReportData, HomecareReportHelper, ReportFieldCheckboxWithDescription, ReportGlobals, UserReportConfiguration } from '@app/models'
import { log } from '@app/helpers/logger'

class ServiceProvidedWrapper {
	key: string
	label: string
	service: ReportFieldCheckboxWithDescription

	constructor(key: string, label: string, service: ReportFieldCheckboxWithDescription) {
		this.key = key
		this.label = label
		this.service = service
	}
}
@Component({
    selector: 'app-homecare-report',
    templateUrl: './homecare-report.component.html',
    styleUrls: ['./homecare-report.component.scss'],
    standalone: false
})
export class HomecareReportComponent implements OnInit, AfterViewInit {
	@Input() reportData = new HomecareReportData()
	@Input() reportConfig: UserReportConfiguration

	format12Hour = ReportGlobals.displayPrefs.format12Hour
	datePickerFormat = ReportGlobals.getDatePickerFormat('DATE')
	serviceTypeOptions = ReportGlobals.serviceTypeOptionsForHomecareReport

	// Any changes need to servicesProvidedKeys needs an update in HomeCareReportData

	servicesProvided: Array<ServiceProvidedWrapper> = []

	constructor(private cd: ChangeDetectorRef) {
		// Don't do anything in constructor, component needs data from above.
		// Don't make calls to any service. Place needed data in reportConfig
	}

	ngOnInit(): void {
		for (const key of HomecareReportHelper.servicesProvidedKeys) {
			const service = this.reportData.servicesProvided[key]
			if (service) {
				const label = HomecareReportHelper.lookupServiceProvidedLabel(key)
				const item = new ServiceProvidedWrapper(key, label, service)
				this.servicesProvided.push(item)
			}
		}
	}

	ngAfterViewInit(): void {}

	skipInput(prop: any, field: string) {
		log('Skipping', field)
		prop[field] = `< skipped >`
		this.cd.detectChanges()
	}

	toggleSkipCheckbox(clOption: ReportFieldCheckboxWithDescription) {
		clOption.skip = !clOption.skip
		if (clOption.skip) {
			clOption.value = false
		} else {
			clOption.value = true
		}
	}

	elementFocused(event: any) {
		log('Event', event)
		const id = event.target?.id
		// Remove any validation border colorering
		if (id) {
			$(`#${id}`).removeClass('ur-input-needs-info')
		}
	}
}
