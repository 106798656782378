import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import {
	DistanceHelper,
	DistanceUnitOption,
	Incident,
	MailingAddress,
	ReportRendererConfig,
	ShiftReportData,
	UserReportImage,
	UserReportType,
} from '@app/models'
import { log } from '@app/helpers'

import moment from 'moment-timezone'

class ShiftReportViewModel {
	isDesktop = false
	images: Array<UserReportImage> = []

	reportType: UserReportType
	reportData: ShiftReportData

	reportDate = ''
	modifiedDate = ''
	adminNotes = ''
	summary = ''
	mileage = ''

	format12Hour = true
	timezone: string
	timeFormat = 'h:mm a'

	logoUrl: string
	companyName: string
	address: MailingAddress

	constructor(config: ReportRendererConfig) {
		const incident = config.reportData as Incident
		this.setupViewModel(incident, config)
		this.setupSectionData(config)
	}

	// get formattedMileageUnits(): string {
	// 	return (this.mileageUnits ?? '').toLowerCase()
	// }

	prepareForPrint() {}

	setupViewModel(incident: Incident, config: ReportRendererConfig) {
		this.images = incident.imageFiles.map((img) => new UserReportImage(img))
		this.reportType = incident.report_type as UserReportType
		this.reportData = JSON.parse(incident.report_json)?.reportData ?? new ShiftReportData()
		this.adminNotes = incident.notes ?? '< no admin notes >'
		this.summary = incident.summary ?? '< no summary >'

		// Mileage
		const mileage = incident.mileage
		const mileageUnits = incident.mileage_units
		DistanceHelper.converter.unitType = config.defaultUnits
		this.mileage = mileage ? DistanceHelper.converter.setValue(mileage, mileageUnits).getSmartDistanceString() : null

		// Setup timezone
		const timezone = moment.tz.guess()
		this.timezone = timezone

		// Setup time formatting
		this.format12Hour = config.formatTime12Hour

		// Setup report date
		const timeFormat = this.format12Hour ? 'h:mm a' : 'HH:mm'
		this.timeFormat = timeFormat
		this.reportDate = moment.tz(incident.created, timezone).format(`ddd MMM Do, YYYY @ ${timeFormat} z`)
		this.modifiedDate = incident.updated ? moment.tz(incident.updated, timezone).format(`ddd MMM Do, YYYY @ ${timeFormat} z`) : ''
	}

	setupSectionData(config: ReportRendererConfig) {
		this.setupHeader(config)
	}

	setupHeader(config: ReportRendererConfig) {
		this.logoUrl = config.logoUrl
		this.address = config.companyAddress
		this.companyName = config.companyName
	}
}

@Component({
    selector: 'app-shift-report-renderer',
    templateUrl: './shift-report-renderer.component.html',
    styleUrls: ['./shift-report-renderer.component.scss'],
    standalone: false
})
export class ShiftReportRendererComponent implements OnInit, OnDestroy {
	constructor() {}

	public vm: ShiftReportViewModel
	public incident: Incident

	@Input() config: ReportRendererConfig
	@Input() publicView = false
	@Output() closeBtnClicked = new EventEmitter<boolean>()

	ngOnInit(): void {
		this.incident = this.config.reportData as Incident
		this.vm = new ShiftReportViewModel(this.config)
		log('Viewing Report', this.incident)
	}

	printPage() {
		this.vm.prepareForPrint()
		setTimeout(() => {
			window.print()
		}, 500)
	}

	ngOnDestroy() {}
}
