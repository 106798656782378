<div *ngIf="incident" class="urr-report-wrapper no-page-break">
	<div *ngIf="images.length > 0" class="urr-block">
		<div class="card urr-card">
			<div class="urr-card-title">Attached Photos</div>
			<hr />
			<div class="urr-card-body">
				<div class="row">
					<div *ngFor="let img of images; let idx = index" class="col-auto">
						<div class="urr-img-box no-page-break" (click)="showFullScreen(img.url)">
							<img class="img-fluid" [src]="img.url" />
							<div style="text-align: center; font-variant: small-caps; font-size: 0.8em">photo {{ idx + 1 }}</div>
						</div>
					</div>
				</div>
				<div *ngFor="let img of images; let idx = index">
					<div *ngIf="img.description">
						<div class="urr-label urr-header-label">Photo {{ idx + 1 }}:</div>
						{{ img.description ? img.description : noDescriptionProvided }}
					</div>
				</div>
			</div>
		</div>
	</div>
	<div style="margin-top: 4px; font-size: 0.9em; color: gray">
		<div class="float-right">v{{ (incident.updated ? incident.updated : incident.created) | dateTzFormatter: 'UTC' : 'YYYY.MM.DD.HH.mm.ss' }}</div>
	</div>
</div>
<div style="text-align: center; opacity: 0; line-height: 1.2em">
	All information in this document is private and confidential. The use and dissemination of this report is restricted to authorized parties only.
</div>
