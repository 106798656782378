<div class="container section-container">
	<div class="sec-head-wrap">
		<div class="sec-act-ico-wrap">
			<i class="fas fa-plus sec-act-ico" (click)="createRecord()" title="Add Tour"></i>
			<i class="far fa-cog sec-act-ico" (click)="openPrefsDialog()" title="Manage Columns"></i>
			<i class="far fa-question-circle sec-act-ico" (click)="toggleSectionHelp()" title="Show Help"></i>
		</div>

		<div class="sec-title-wrap">
			<div>
				<i class="far fa-map-pin sec-head-ico"></i>
				<span>Tours</span>
			</div>
		</div>

		<div class="sub-head-wrap">
			<div class="sub-head-ico-wrap">
				<!-- Refresh Icon -->
				<i class="fa fa-refresh sub-head-ico" (click)="fetchAndReload()" title="Refresh Data"></i>
				<div class="updated-wrap">{{ lastUpdated }}</div>
			</div>
		</div>
	</div>

	<div *ngIf="showSectionHelp" class="card card-body tooltip-block tooltip-info-text tooltip-section-header">
		<button type="button" class="close" aria-label="Close" (click)="toggleSectionHelp()">
			<span aria-hidden="true">&times;</span>
		</button>
		Tours allow you to define explicit checkpoints you want your employees to report on. This might be checking parking, stairwells, exit doors, or
		any other place on site you wish to track.

		<br />
	</div>

	<app-tour-table #tourTable></app-tour-table>
</div>
