<app-shift-view-event-details [eventInfo]="eventInFocus"></app-shift-view-event-details>

<div class="option-dropdown-wrap noprint">
	<!-- <div *ngIf="isLoading" class="processing-status"><i class="fas fa-loader fa-spin"></i> Processing Shifts...</div> -->
	<div
		[class.option-dropdown-mobile]="devDetect.isMobile()"
		[class.d-flex]="!devDetect.isMobile()"
		[class.justify-content-between]="!devDetect.isMobile()"
	>
		<div>
			<div class="header-wrap">
				<div *ngIf="!isGlobalAccount" class="header-block header-block-jobs">
					<!-- (onPanelHide)="updateJobs()" -->
					<div class="header-block-menu">
						<div>
							<i class="fas fa-times-circle header-menu-clear" (click)="clearMenu('JOB')"></i>
						</div>
						<div class="header-block-select">
							<p-multiselect
								appScrollLockMultiSelect
								appendTo="body"
								[placeholder]="jobSelectorLabel"
								selectedItemsLabel="Jobs: {0}"
								[maxSelectedLabels]="0"
								[autofocus]="devDetect.isDesktop()"
								[autofocusFilter]="devDetect.isDesktop()"
								filterBy="label"
								[showToggleAll]="true"
								[resetFilterOnHide]="true"
								[options]="jobOptions"
								[(ngModel)]="manager.shift.selectedJobIds"
							>
							</p-multiselect>
						</div>
					</div>
				</div>
				<div class="header-block">
					<!-- (onPanelHide)="updateEmployees()" -->
					<div class="header-block-menu">
						<div>
							<i class="fas fa-times-circle header-menu-clear" (click)="clearMenu('EMP')"></i>
						</div>
						<div class="header-block-select">
							<p-multiselect
								appScrollLockMultiSelect
								appendTo="body"
								[placeholder]="empSelectorLabel"
								selectedItemsLabel="Employees: {0}"
								[maxSelectedLabels]="0"
								[autofocus]="devDetect.isDesktop()"
								[autofocusFilter]="devDetect.isDesktop()"
								filterBy="label"
								[showToggleAll]="true"
								[resetFilterOnHide]="true"
								[options]="empOptions"
								[(ngModel)]="manager.shift.selectedEmpIds"
							>
							</p-multiselect>
						</div>
					</div>
				</div>
				<button class="btn btn-sm btn-success header-update-btn" (click)="updateEmpAndJobLists(); jobOrEmpListChanged()">
					<!-- <span *ngIf="isLoading"><i class="fas fa-loader fa-spin"></i></span> -->
					<!-- <span *ngIf="!isLoading">Update</span> -->
					<span>Update</span>
				</button>
			</div>
			<!-- </div> -->
		</div>

		<div *ngIf="!devDetect.isMobile()">
			<div *ngIf="!tzSelectAction.isVisible" class="calendar-date">
				<span>{{ calendarDisplayDate.range }}</span>
				<span class="calendar-date-year">, {{ calendarDisplayDate.year }}</span>
				<div
					class="tz-btn table-tag has-pointer"
					[class.tz-btn-locked]="isTimezoneLocked"
					style="font-size: 0.8rem"
					(click)="openTzSelectDialog()"
				>
					<span>{{ currentTzAbrev }}</span>
					<i *ngIf="isTimezoneLocked" class="far fa-lock" style="margin-left: 4px"></i>
				</div>
			</div>
			<div *ngIf="tzSelectAction.isVisible" class="tz-dd-block d-flex justify-content-between" style="max-width: 275px">
				<!-- <div style="display: inline-block"> -->
				<div class="tz-close-btn">
					<i *ngIf="isTimezoneLocked" class="far fa-unlock" style="margin-right: 8px" (click)="updateTimezone(null)"></i>
					<i class="fa fa-times-circle-o" (click)="tzSelectAction.isVisible = false"></i>
				</div>

				<!-- </div> -->
				<div style="width: 280px">
					<p-select
						appScrollLockSelect
						appendTo="body"
						styleClass="shift-tz-dd"
						scrollHeight="280px"
						placeholder="Automatic"
						[options]="tzOptions"
						[ngModel]="currentTimezone"
						(onChange)="updateTimezone($event.value)"
					></p-select>
				</div>
			</div>
		</div>
		<!-- <div style="display: inline-block;"><i class="fa fa-refresh"></i></div> -->
	</div>
</div>

<div *ngIf="false && shouldDisplaySelectionBanner" class="noprint">
	<div class="alert alert-info tag-list-alert">
		Select one or more {{ manager.shift.option === 'EMP' ? 'employees' : 'jobs' }} from the dropdown menu above.
		<span *ngIf="manager.shift.option === 'EMP'"> Click <i class="far fa-tasks-alt"></i> to switch to job view.</span>
		<span *ngIf="manager.shift.option === 'JOB'"> Click <i class="fas fa-user"></i> to switch to employee view.</span>
	</div>
</div>

<!-- <div *ngIf="showNoMatchingShiftsAlert" class="noprint" style="margin-bottom: -24px">
	<div class="alert alert-info tag-list-alert">No matching shifts found for your current selection criteria</div>
</div> -->

<!-- <div class="options-wrap">
	<div class="table-tag bg-chocolate">Jobs</div>
	<div class="table-tag bg-chocolate">Employees</div>
</div> -->

<!-- <div>DistanceX: {{ deltaX }} / DistanceY: {{ deltaY }} / Direction: {{ direction }}</div> -->

<div class="noprint tag-list-block">
	<div *ngIf="manager.shift.shiftEventBgColorSource === 'EMP'">
		<app-shift-view-selected-employees
			#selectedEmpsTagList
			[empIds]="visibleEmpIds"
			[colorSource]="manager.shift.shiftEventBgColorSource"
			(listChanged)="empListChanged()"
		></app-shift-view-selected-employees>
	</div>

	<div *ngIf="manager.shift.shiftEventBgColorSource === 'JOB'">
		<app-shift-view-selected-jobs
			#selectedJobsTagList
			[jobIds]="visibleJobIds"
			[colorSource]="manager.shift.shiftEventBgColorSource"
			(listChanged)="jobListChanged()"
		></app-shift-view-selected-jobs>
	</div>
</div>

<div *ngIf="vwm.shouldShowWarning">
	<div class="alert alert-warning" style="margin-bottom: 26px; text-align: center">
		<div style="font-weight: bold">Schedule Limit Reached</div>
		<div style="margin-top: 10px">To optimize for performance, schedules are only calculated out {{ vwm.windowSize }} days.</div>
	</div>
</div>

<!-- Gesture Testing -->
<!-- <div>
	<div>
		<div class="float-right">X: {{ xCoord ?? '-' }} / Y: {{ yCoord ?? '-' }}</div>
		<div>isTouchDown: {{ isTouchDown }}</div>
	</div>
	<div>
		<div class="float-right">ΔX: {{ deltaX ?? '-' }} / ΔY: {{ deltaY ?? '-' }}</div>
		<div>isSwipeDetected: {{ isSwipeDetected }}</div>
	</div>
	<div>
		<div class="float-right">Pan/Swipe: {{ panDirection }} / {{ swipeDirection }}</div>
		<div>isPanDetected: {{ isPanDetected }}</div>
	</div>
	<br />
</div> -->

<!-- <div #calBlock id="calendar-block" class="calendar-block" (panleft)="handleSwipeLeft($event)" (panright)="handleSwipeRight($event)"> -->
<div #calBlock id="calendar-block" class="calendar-block">
	<full-calendar id="calendar" #calendar [options]="calendarOptions"></full-calendar>
</div>

<!-- Pinned Dropdowns for when jobs is set as multi-select -->

<div class="footer-wrap noprint">
	<div *ngIf="!isGlobalAccount" class="footer-block">
		<p-select
			appScrollLockSelect
			[ttsCenterOnShow]="false"
			appendTo="body"
			[options]="shiftEventBgOptions"
			styleClass="shift-pref-dd"
			[(ngModel)]="manager.shift.shiftEventBgColorSource"
			(ngModelChange)="updateShiftEventBgSource()"
		></p-select>
	</div>
	<div *ngIf="devDetect.isMobile()" class="footer-block">
		<p-select
			appScrollLockSelect
			[ttsCenterOnShow]="false"
			appendTo="body"
			styleClass="shift-tz-dd"
			scrollHeight="280px"
			placeholder="Automatic"
			[options]="tzOptions"
			[ngModel]="currentTimezone"
			(onChange)="updateTimezone($event.value)"
		></p-select>
	</div>
	<div *ngIf="!isGlobalAccount" class="footer-block">
		<div
			class="os-checkbox prevent-select"
			[class.os-checkbox-enabled]="manager.shift.showTimeOff"
			(click)="manager.shift.showTimeOff = !manager.shift.showTimeOff; saveViewPrefs()"
		>
			<div class="d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-checkbox [ngModel]="manager.shift.showTimeOff" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<strong class="has-pointer">Time off entries ({{ manager.shift.showTimeOff ? 'visible' : 'hidden' }})</strong>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="!isGlobalAccount" class="footer-block">
		<div
			class="os-checkbox prevent-select"
			[class.os-checkbox-enabled]="manager.shift.showOpenShifts"
			(click)="manager.shift.showOpenShifts = !manager.shift.showOpenShifts; saveViewPrefs()"
		>
			<div class="d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-checkbox [ngModel]="manager.shift.showOpenShifts" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<strong class="has-pointer">Open shift entries ({{ manager.shift.showOpenShifts ? 'visible' : 'hidden' }})</strong>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="!isGlobalAccount" class="footer-block">
		<div
			class="os-checkbox prevent-select"
			[class.os-checkbox-enabled]="manager.shift.showDisabledShifts"
			(click)="manager.shift.showDisabledShifts = !manager.shift.showDisabledShifts; saveViewPrefs()"
		>
			<div class="d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-checkbox [ngModel]="manager.shift.showDisabledShifts" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<strong class="has-pointer">Disabled shift entries ({{ manager.shift.showDisabledShifts ? 'visible' : 'hidden' }})</strong>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="manager.shift.showOpenShifts && !isGlobalAccount">
	<!-- <app-horizontal-scroll-menu> -->
	<app-open-shifts-list
		[searchText]="searchText"
		(gotoOpenShiftEvent)="gotoShiftLogShiftEventHandler($event)"
		(offerOpenShiftEvent)="offerOpenShiftEventHandler($event)"
	></app-open-shifts-list>
	<!-- </app-horizontal-scroll-menu> -->
</div>

<!-- <div style="margin-top: 8px; color: gray">
	<div class="tz-bottom-label" [class.tz-bottom-label-locked]="isTimezoneLocked">Timezone: {{ currentTimezone }}</div>
	<div style="display: inline-block; cursor: pointer" (click)="sectionPrefsDialogManager.isDialogVisible = true">
		<i class="far fa-gear" style="color: #0275d8"></i>
		modify display preferences
	</div>
</div> -->

<app-modal-dialog *ngIf="eventEditManager.isDialogVisible" [dialogManager]="eventEditManager">
	<app-scheduler-shift-view-edit [manager]="eventEditManager" (recordUpdated)="actionEventRecordUpdated()"></app-scheduler-shift-view-edit>
</app-modal-dialog>

<app-modal-dialog *ngIf="openShiftOfferDialogManager.isDialogVisible" [dialogManager]="openShiftOfferDialogManager">
	<app-open-shift-offer [dialogManager]="openShiftOfferDialogManager" (recordUpdated)="openShiftOfferRecordUpdated()"></app-open-shift-offer>
</app-modal-dialog>

<app-scheduler-shift-view-event-actions-dialog
	*ngIf="eventActionManager.showDialog"
	[manager]="eventActionManager"
	[eventEditManager]="eventEditManager"
	(recordUpdated)="actionEventRecordUpdated()"
></app-scheduler-shift-view-event-actions-dialog>

<app-modal-dialog *ngIf="sectionPrefsDialogManager.isDialogVisible" [dialogManager]="sectionPrefsDialogManager">
	<div class="shift-dropdown shift-menu-week-start">
		<div class="pref-header">
			<label>Week Starts On</label>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('weekStart')"></i>
		</div>
		<p-select
			appScrollLockSelect
			[options]="weekStartOptions"
			styleClass="pref-dd"
			scrollHeight="280px"
			[(ngModel)]="manager.shift.weekStart"
			(ngModelChange)="updateWeekStart()"
		></p-select>
	</div>

	<div class="shift-dropdown shift-menu-color-source">
		<div class="pref-header">
			<label>Highlight Style</label>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('highlightStyle')"></i>
		</div>
		<p-select
			appScrollLockSelect
			[options]="shiftEventBgOptions"
			styleClass="shift-pref-dd"
			[(ngModel)]="manager.shift.shiftEventBgColorSource"
			(ngModelChange)="updateShiftEventBgSource()"
		></p-select>
	</div>

	<div class="shift-dropdown shift-menu-slot-size">
		<div class="pref-header">
			<label>Display Style</label>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('displayStyle')"></i>
		</div>
		<p-select
			appScrollLockSelect
			[options]="slotSizeOptions"
			styleClass="shift-pref-dd"
			[(ngModel)]="manager.shift.slotDuration"
			(ngModelChange)="updateShiftSlotDuration()"
		></p-select>
	</div>

	<div class="shift-dropdown shift-menu-timezone">
		<div class="pref-header">
			<div class="tz-status float-right" [class.tts-hl-green]="this.isTimezoneLocked">
				{{ this.isTimezoneLocked ? 'locked' : 'automatic' }}
			</div>
			<label>Timezone</label>
			<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('timezone')"></i>
		</div>
		<p-select
			appScrollLockSelect
			styleClass="shift-tz-dd"
			scrollHeight="280px"
			placeholder="Automatic"
			[options]="tzOptions"
			[ngModel]="currentTimezone"
			(onChange)="updateTimezone($event.value)"
		></p-select>
	</div>

	<div style="margin-top: 38px">
		<div *ngIf="!devDetect.isMobile()" style="margin-bottom: 22px">
			<div class="checkbox-item d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-checkbox
						[(ngModel)]="manager.shift.userSideScrollForOpenShiftList"
						[binary]="true"
						(change)="updateOpenShiftCheckboxPref()"
					></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<div
						class="checkbox-label-text"
						(click)="
							manager.shift.userSideScrollForOpenShiftList = !manager.shift.userSideScrollForOpenShiftList; updateOpenShiftCheckboxPref()
						"
					>
						Use horizontal scroller for open shift list instead of wrapping list entries over multiple rows
					</div>
				</div>
			</div>
		</div>

		<div>
			<div class="checkbox-item d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-checkbox
						[(ngModel)]="manager.shift.restrictOpenShiftsToRange"
						[binary]="true"
						(change)="updateOpenShiftCheckboxPref()"
					></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<div
						class="checkbox-label-text"
						(click)="manager.shift.restrictOpenShiftsToRange = !manager.shift.restrictOpenShiftsToRange; updateOpenShiftCheckboxPref()"
					>
						Restrict open shift list entries to the current date range being shown in schedule.
					</div>
				</div>
			</div>
		</div>

		<!-- <div>
			<div class="checkbox-item d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-checkbox [(ngModel)]="manager.shift.detachShiftDetailsView" [binary]="true" (change)="updateOpenShiftCheckboxPref()"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<div
						class="checkbox-label-text"
						(click)="manager.shift.detachShiftDetailsView = !manager.shift.detachShiftDetailsView; manager.save()"
					>
						Detach shift details view so it floats above the schedule.
					</div>
				</div>
			</div>
		</div> -->
	</div>

	<!-- <app-local-prefs [dialogManager]="sectionPrefsDialogManager" (prefsUpdated)="prefsDataSaved()"></app-local-prefs> -->
</app-modal-dialog>
