import { parseNumber, ParsedNumber, CountryCode, isValidNumber } from 'libphonenumber-js'

import { DisplayHelper } from '@app/helpers/display'
import _ from 'lodash'
import { Global } from '@app/models/global'

export class Helper {
	// Remember to enable timezone support when setting up new regions

	static countryIsoData = [
		{ id: 'US', prefix: '+1', region: 'United States', regionSupport: true, empSupport: true },
		{ id: 'BG', prefix: '+359', region: 'Bulgaria', regionSupport: false, empSupport: true },
		{ id: 'CA', prefix: '+1', region: 'Canada', regionSupport: true, empSupport: true },
		{ id: 'GB', prefix: '+44', region: 'Great Britain', regionSupport: true, empSupport: true },
		{ id: 'JE', prefix: '+44', region: 'Jersey (UK)', regionSupport: true, empSupport: true },
		{ id: 'JM', prefix: '+1', region: 'Jamaica', regionSupport: true, empSupport: true },
		{ id: 'MX', prefix: '+53', region: 'Mexico', regionSupport: false, empSupport: true },
		{ id: 'PR', prefix: '+1', region: 'Puerto Rico', regionSupport: true, empSupport: true },
	]

	static escapeHtml(value) {
		return (
			value
				// .replace(/&/g, '&amp;')
				.replace(/</g, '&lt;')
				.replace(/>/g, '&gt;')
		)
		// .replace(/"/g, '&quot;')
		// .replace(/'/g, '&#039;');
	}

	static capitalizeFirstLetter(string) {
		if (!string) return string // Handle empty strings
		const result = `${string}`.charAt(0).toUpperCase() + string.slice(1)
		return result
	}

	static capitalizeEachWord(input: string): string {
		if (typeof input !== 'string' || input.length === 0) {
			return ''
		}

		return input
			.split(' ')
			.map((word) => {
				return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
			})
			.join(' ')
	}

	static sanitizeObject(object) {
		for (const attr in object) {
			if (object.hasOwnProperty(attr)) {
				if (typeof object[attr] === 'string') {
					object[attr] = Helper.escapeHtml(object[attr])
				}
				if (typeof object[attr] === 'object') {
					Helper.sanitizeObject(object[attr])
				}
			}
		}
	}

	static getCountryCodeFromE164(number: string): CountryCode {
		const parsedNumber = parseNumber(number) as ParsedNumber
		if (isValidNumber(parsedNumber)) {
			return parsedNumber.country
		}
		return null
	}

	// Format a full address for just the stree
	static getStreetInfoFromAddress(addressString: string): string {
		if (!addressString) {
			return ''
		}
		const addressParts = addressString.split(',')
		if (addressParts.length > 0) {
			return addressParts[0]
		}
		return addressString
	}

	static trimWhitespace(str: string) {
		if (!str) {
			return ''
		}
		return _.trim(str)
	}

	static stripNonNumeric(str: string): string {
		if (!str) {
			return ''
		}
		return str.replace(/\D/g, '')
	}

	static isPasswordValid(str: string): boolean {
		const letterTest = /[a-z]/i.test(str)
		const numberTest = /[0-9]/.test(str)
		const lengthTest = str.length >= 8 ? true : false
		return letterTest && numberTest && lengthTest
	}

	// Pull shift info from job description ex: JobName [1:00-2:00]
	static getShift(str: string): string {
		const re = /\[([^}]+)\]/
		const match = str.match(re)
		if (match && match.length > 1) {
			const shiftString = match[1]
			const components = shiftString.split('-')
			if (components.length > 1) {
				// If start and end times same it's anytime
				if (components[0] === components[1]) {
					return 'Anytime'
				}
			}
			return shiftString
		} else {
			return 'unavailable'
		}
	}

	// Pull job info from job description ex: JobName [1:00-2:00]
	static getJobName(str: string): string {
		if (!str) {
			return ''
		}
		const items = str.split('[')
		const name = items[0]
		if (name) {
			return name.trim()
			// return name.replace(/\s*$/, '')
		} else {
			return str
		}
	}

	// JobSitesComponent: format numbers for tooltips
	static getJobSiteNumbers(str: string): string[] {
		return str.split(',')
	}
	static formatJobSiteNumbers(str: string): string {
		const numbers = Helper.getJobSiteNumbers(str)
		const formattedNumbers = numbers.map((num) => DisplayHelper.displayPhone(num))
		return formattedNumbers.join('<br>')
	}

	// Email validation

	static isValidEmail(email: string): boolean {
		return Global.emailRegex.test(email)
	}

	static isPlural(num: number): boolean {
		return num > 1 ? true : false
	}

	static getCookieByName(cookieName: string): string | null {
		// Get all cookies in a single string
		const cookies = document.cookie.split('; ')

		// Iterate through the cookies array
		for (const cookie of cookies) {
			// Check if the cookie starts with the name followed by an equal sign
			if (cookie.startsWith(cookieName + '=')) {
				// Return the cookie value (everything after the '=')
				return cookie.substring(cookieName.length + 1)
			}
		}

		// If no match is found, return null
		return null
	}

	static downloadHtmlContent(htmlContent: string, fileName: string) {
		// Create a Blob from the HTML content
		const blob = new Blob([htmlContent], { type: 'text/html' })

		// Create a URL for the Blob
		const url = window.URL.createObjectURL(blob)

		// Create a link element
		const link = document.createElement('a')
		link.setAttribute('href', url) // Set the URL of the Blob
		link.setAttribute('download', fileName) // Set the desired filename for the downloaded file

		// Hide the link element
		link.style.display = 'none'

		// Add the link to the document body
		document.body.appendChild(link)

		// Simulate a click on the link to trigger the download
		link.click()

		// Remove the link from the document body
		document.body.removeChild(link)

		// Revoke the URL to release the Blob object from memory
		window.URL.revokeObjectURL(url)
	}
}
