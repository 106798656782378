import { Component, Input, OnInit } from '@angular/core'
import { DateTimeHelper, log } from '@app/helpers'
import { TransEventLogEntry } from '@app/models'
import { DatabaseService } from '@app/services'
import moment from 'moment-timezone'

@Component({
    selector: 'app-shift-details-event-log',
    templateUrl: './shift-details-event-log.component.html',
    styleUrls: ['./shift-details-event-log.component.scss'],
    standalone: false
})
export class ShiftDetailsEventLogComponent implements OnInit {
	@Input() transId: number

	isDataLoaded = false
	showEventLog = false

	timezone: string
	logEntries: Array<TransEventLogEntry> = []

	constructor(private dbSrvc: DatabaseService) {}

	ngOnInit(): void {
		this.setupLogDatag()
		const trans = this.dbSrvc.tranSrvc.getTransactionById(this.transId)
		this.timezone = trans?.timezone ?? this.dbSrvc.settingSrvc.getCompany().timezone
	}

	setupLogDatag() {
		const transId = this.transId.toString()
		const request = { table: 'transaction_event_log', operation: null, transaction_log_id: transId }
		log('Lambda Request', request)
		this.dbSrvc.lambdaSrvc.dataAccess(request).then((result) => {
			log('Result', result)
			this.logEntries = result.data.map((rec) => new TransEventLogEntry(rec))
			log('Total Entries:', this.logEntries.length)
			this.isDataLoaded = true
		})
	}

	public dateFormatter(date: string): string {
		const mom = moment(date)
		if (mom.isValid()) {
			return mom.tz(this.timezone).format('ddd MMM Do')
		}
	}

	public timeFormatter(date: string): string {
		const mom = moment(date)
		if (mom.isValid()) {
			const is12Hours = DateTimeHelper.format12Hour
			const timeFormat = is12Hours ? 'h:mm:ss a z' : 'HH:mm:ss z'
			return mom.tz(this.timezone).format(timeFormat)
		}
	}
}
