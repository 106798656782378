import { Component } from '@angular/core'

import { log } from '@app/helpers'

@Component({
    selector: 'app-public-landing',
    templateUrl: './landing.component.html',
    standalone: false
})
export class PublicLandingComponent {
	constructor() { }
}
