import { Injectable } from '@angular/core'

import { EmployeeChecklist, EmployeeChecklistRecord } from '@app/models'
import { log } from '@app/helpers'
import _ from 'lodash'
import { Global } from '@app/models/global'

@Injectable({ providedIn: 'root' })
export class EmployeeChecklistService {
	empChecklistDataLoaded = false
	empChecklist: EmployeeChecklistRecord[] = []
	empChecklistLastUpdated: Date

	constructor() {
		log('Creating EmployeeChecklistService')
	}

	clearData() {
		this.empChecklistDataLoaded = false
		this.empChecklist = []
		this.empChecklistLastUpdated = null
	}

	empChecklistCount(): number {
		return this.empChecklist.length
	}

	getEmployeeChecklists(): Array<EmployeeChecklistRecord> {
		return this.empChecklist
	}

	getChecklistsForFilter(empId: number, jobId) {
		if (empId === 0) return []
		const list: Array<EmployeeChecklistRecord> = []
		const checklists = this.getEmployeeChecklists()
		for (const checklist of checklists) {
			const filterEmpIds = checklist.employee_ids ?? []
			const filterJobIds = checklist.job_ids ?? []
			if ((filterEmpIds.length === 0 || filterEmpIds.includes(empId)) && (filterJobIds.length === 0 || filterJobIds.includes(jobId))) {
				list.push(checklist)
			}
		}
		return list
	}

	removeLocalEmpChecklistRecord(recordId: number) {
		this.empChecklist = this.empChecklist.filter((rec) => rec.id !== recordId)
	}

	setEmployeeChecklistRecords(records: Array<EmployeeChecklistRecord>) {
		this.empChecklistLastUpdated = new Date()
		this.empChecklist = records.map((r) => new EmployeeChecklistRecord(r))
		this.empChecklistDataLoaded = true
	}

	updateEmployeeChecklistRecords(records: Array<EmployeeChecklistRecord>) {
		for (const newRecord of records) {
			if (newRecord) {
				const currentRecord = this.empChecklist.find((rec) => rec.id === newRecord.id)
				if (currentRecord) {
					for (const attr in newRecord) {
						if (newRecord.hasOwnProperty(attr)) {
							currentRecord[attr] = newRecord[attr]
						}
					}
				}
			}
		}
	}

	/**
	 * Add or update local database records
	 * @param records: Array of records to ad or update.
	 */

	addOrUpdateEmployeeChecklistRecords(records: Array<EmployeeChecklistRecord>) {
		const newRecords = records.map((rec) => new EmployeeChecklistRecord(rec))
		for (const newRecord of newRecords) {
			const currentRecord = this.empChecklist.find((rec) => rec.id === newRecord.id)
			if (currentRecord) {
				for (const attr in newRecord) {
					if (newRecord.hasOwnProperty(attr)) {
						currentRecord[attr] = newRecord[attr]
					}
				}
			} else {
				this.empChecklist.push(newRecord)
			}
		}
	}
}
