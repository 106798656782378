import { DateTimeHelper } from '@app/helpers'
import { EventTimePosition } from './event'

export class BreaksViewManager {
	version = 1
}

export class BreakProfileRecord {
	id: number
	description: string
	details: string // admin notes
	created: string
	updated: string

	job_ids: Array<number> = []
	rules_json: string

	constructor(record: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
			this.created = DateTimeHelper.stripUtcTag(this.created)
		}
	}
}

export class BreakRule {
	description: string = null
	payrollType: 'PAID' | 'UNPAID' = 'PAID'
	managedByType: 'EMPLOYEE' | 'SYSTEM' = 'SYSTEM'
	startOffset: string = '4:00' // Offset from shift start (in hr:min format)
	duration: number = 30 // Duration of break (in minutes)

	startBreakReminder: boolean = false
	startBreakReminderOffset: number = null // Offset hich triggers the notification (in minutes)
	startBreakReminderOffsetType: EventTimePosition = 'BEFORE_EVENT'

	endBreakReminder: boolean = false
	endBreakReminderOffset: number = null // Offset which triggers the notification (in minutes)
	endBreakReminderOffsetType: EventTimePosition = 'AT_EVENT'

	flagBreakNotStartedBy = false
	flagBreakNotStartedByOffset: string = null // Flag break if not started by this offset (in hr:min format)

	flagShortBreak = false
	flagShortBreakDuration: number = null // Flag break if lasts less than this duration (in minutes)

	confirmEndBreakEarly: boolean = false
	confirmEndBreakEarlyOffset: number = null // Offset which triggers the warning confirmation (in minutes)

	constructor(record?: any) {
		if (record) {
			for (const attr in record) {
				if (record.hasOwnProperty(attr)) {
					this[attr] = record[attr]
				}
			}
		}
	}

	get payrollTypeLabel(): string {
		return this.payrollType === 'PAID' ? 'Paid' : 'Unpaid'
	}

	get manageTypeLabel(): string {
		return this.managedByType === 'EMPLOYEE' ? 'Employee Managed' : 'System Managed'
	}

	advanceTimePosition(prop: 'startBreakReminderOffsetType' | 'endBreakReminderOffsetType') {
		// Given a TimePosition property, advance from BEFORE to AT to AFTER then back to BEFORE
		if (this[prop] === 'BEFORE_EVENT') {
			this[prop] = 'AFTER_EVENT'
		} else if (this[prop] === 'AFTER_EVENT') {
			this[prop] = 'AT_EVENT'
		} else if (this[prop] === 'AT_EVENT') {
			this[prop] = 'BEFORE_EVENT'
		}
	}

	getTimePositionLabel(prop: 'startBreakReminderOffsetType' | 'endBreakReminderOffsetType') {
		if (this[prop] === 'BEFORE_EVENT') {
			return 'before'
		} else if (this[prop] === 'AFTER_EVENT') {
			return 'after'
		} else if (this[prop] === 'AT_EVENT') {
			return 'at'
		}
	}

	public makeUpdateRecord(): any {
		const record = JSON.parse(JSON.stringify(this)) as BreakRule

		if (record.startOffset && !record.startOffset.includes(':')) {
			record.startOffset = `00:${record.startOffset}`
		}

		return record
	}
}

export class BreakRuleCard {
	rule: BreakRule
	confirmDelete = false
	cardSummary = ''

	constructor(rule: BreakRule) {
		this.rule = rule
		this.updateSummary()
	}

	updateSummary() {
		const payrollTypeLabel = this.rule.payrollTypeLabel

		let hours = 0
		let minutes = 0
		const comps = this.rule.startOffset.split(':')
		if (comps.length === 2) {
			hours = parseInt(comps[0], 10)
			minutes = parseInt(comps[1], 10)
		}
		const hoursStr = `${hours} hour${hours === 1 ? '' : 's'}`
		const minutesStr = `${minutes} minute${minutes === 1 ? '' : 's'}`
		let duration = this.rule.duration

		const startText = this.rule.managedByType === 'SYSTEM' ? 'starts' : 'should start'
		const lastText = this.rule.managedByType === 'SYSTEM' ? 'lasts' : 'last'
		const payTypeText = this.rule.payrollType === 'PAID' ? '<b style="color:green">PAID</b>' : '<b style="color:firebrick">UNPAID</b>'

		this.cardSummary = `This ${payTypeText} break ${startText} <b>${hoursStr}</b> and <b>${minutesStr}</b> after clock in and ${lastText} for <b>${duration}</b> minutes.`
	}
}
