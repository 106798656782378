<div class="urr-page-box">
	<div class="urr-report-wrapper">
		<app-user-report-header
			[config]="config"
			[logoUrl]="vm.logoUrl"
			[companyName]="vm.companyName"
			[address]="vm.address"
			[reportType]="vm.reportType"
			[transactionMetaData]="vm.reportData.transactionMetaData"
			[publicView]="publicView"
			(printPageBtnClicked)="printPage()"
			(closeBtnClicked)="closeBtnClicked.emit($event)"
		>
		</app-user-report-header>

		<div class="urr-block no-page-break">
			<div class="card urr-card">
				<div>
					<div class="urr-label urr-header-label nowrap">Service Provided:</div>
					<div class="urr-header-value">{{ vm.reportInfo.serviceType }}</div>
				</div>
				<div>
					<div class="urr-label urr-header-label nowrap">Service Provider:</div>
					<div class="urr-header-value">{{ vm.reportInfo.serviceProviderName }}</div>
				</div>
				<div>
					<div class="urr-label urr-header-label nowrap">License Number:</div>
					<div class="urr-header-value">
						{{ vm.reportData.serviceProvider.license.skip ? vm.skipLabel : vm.reportData.serviceProvider.license.value }}
					</div>
				</div>
				<div>
					<div class="urr-label urr-header-label nowrap">Submitted On:</div>
					<div class="urr-header-value">{{ vm.reportDate }}</div>
				</div>
			</div>
		</div>

		<div class="urr-block no-page-break">
			<div class="card urr-card">
				<div>
					<div class="urr-label urr-header-label nowrap">Client:</div>
					<div class="urr-header-value">{{ vm.clientInfo.clientName }}</div>
				</div>
				<div>
					<div class="urr-label urr-header-label nowrap">Medicaid #:</div>
					<div class="urr-header-value">
						{{ vm.reportData.clientInformation.medicaidNumber.skip ? vm.skipLabel : vm.reportData.clientInformation.medicaidNumber.value }}
					</div>
				</div>
				<hr />
				<div>
					<div class="urr-label urr-header-label nowrap">Support Plan Effective Date:</div>
					<div class="urr-header-value">{{ vm.clientInfo.planDate }}</div>
				</div>
				<div>
					<div class="urr-description">{{ vm.reportData.supportPlan.planGoals.value }}</div>
				</div>
			</div>
		</div>

		<div class="urr-block">
			<div class="card urr-card urr-answer-card" *ngFor="let item of vm.servicesProvided">
				<div class="no-page-break">
					<div class="urr-answer-btn float-right" [class.urr-answer-yes]="item.value" [class.urr-answer-skip]="item.skip">
						{{ item.skip ? 'N/A' : item.value ? 'YES' : 'NO' }}
					</div>
					<div class="urr-label urr-answer-label">{{ item.label }}</div>
				</div>
			</div>
		</div>

		<div class="urr-block no-page-break">
			<div class="card urr-card">
				<div>
					<div class="urr-label">Summary of Service</div>
					<div class="urr-description">{{ vm.reportData.summary.summaryOfService.value || vm.skipLabel }}</div>
				</div>
				<div class="urr-block">
					<div class="urr-label">Outcome of Service</div>
					<div class="urr-description">{{ vm.reportData.summary.outcomeOfService.value || vm.skipLabel }}</div>
				</div>
				<div class="urr-block">
					<div class="urr-label">Additional Notes</div>
					<div class="urr-description">{{ vm.reportData.summary.additionalNotes.value || vm.noDescription }}</div>
				</div>
			</div>
		</div>
	</div>

	<app-user-report-footer [incident]="incident" [images]="vm.images"></app-user-report-footer>
</div>
