import { CountryCode, isValidNumberForRegion, parseNumber, ParsedNumber, formatNumber } from 'libphonenumber-js'

export class PhoneHelper {
	static formatPhoneE164(countryCode: CountryCode, number: string) {
		if (number && number.length > 3 && isValidNumberForRegion(number, countryCode)) {
			const parsedNumber = parseNumber(number, countryCode) as ParsedNumber
			return formatNumber(parsedNumber, 'E.164')
		} else {
			return null
		}
	}
	static formatPhoneFromE164(number: string) {
		if (!number) return ''
		const parsedNumber = parseNumber(number) as ParsedNumber
		return formatNumber(parsedNumber, 'NATIONAL')
	}
}
