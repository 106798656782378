import { Component, Inject, OnDestroy } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { Router, ActivatedRoute } from '@angular/router'
import { DisplayHelper, log } from '@app/helpers'

import { UserLoginService, DatabaseService, UserSessionService, CoreService } from '@app/services'
import { Subscription } from 'rxjs'

// Logout Component

@Component({
    selector: 'app-auth-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
    standalone: false
})
export class LogoutComponent implements OnDestroy {
	adpSession = null
	isInactiveLogout = false
	isLoggedOut = false
	enableGlobalSignout = false

	redirectTimer: NodeJS.Timeout = null
	returnToAdpTimer: NodeJS.Timeout = null

	private subs = new Subscription()
	constructor(
		public router: Router,
		private route: ActivatedRoute,
		private loginSrvc: UserLoginService,
		private sessionSrvc: UserSessionService,
		private coreSrvc: CoreService,
		@Inject(DOCUMENT) private document: Document,
	) {
		log('LogoutComponent - Created')

		// Color background based on screen size
		this.subs.add(
			this.coreSrvc.displaySrvc.screenSizeDidChange.subscribe(() => {
				this.handleScreenSizeChange()
			}),
		)
		this.handleScreenSizeChange()

		const adpUserSessionKey = 'adpUserSession'
		this.adpSession = localStorage.getItem(adpUserSessionKey)
		this.enableGlobalSignout = this.coreSrvc.dbSrvc.settingSrvc.getMyUserAdminPrefs()?.enableGlobalCognitoSignout ?? false

		// Check if logout was due to inactivity
		const currentUrl = this.route.snapshot.url.toString() || ''
		this.isInactiveLogout = currentUrl.includes('inactive')

		// Remove bottom viewing as user banner if set
		this.coreSrvc.eventSrvc.displayUserChanged(null)

		// Global logout the user
		const currentUser = this.loginSrvc.cognitoUtil.getCurrentUser()
		if (currentUser) {
			currentUser.getSession((err, session) => {
				if (err) {
					log('Error getting session', err)
					this.clearData()
				} else {
					if (session.isValid()) {
						if (this.enableGlobalSignout) {
							log('Perform a global Cognito signout')
							const token = session.getAccessToken().getJwtToken()
							currentUser.globalSignOut({
								onSuccess: (signoutMsg) => {
									log('Global Logout', signoutMsg)
								},
								onFailure: (signoutErr) => {
									log('Global Logout Error', signoutErr)
								},
							})
						}
						this.clearData()
					}
				}
			})
		}

		if (this.adpSession) {
			log('Logging out from ADP Session')
			this.clearData()
			this.returnToAdpLogoutPage()
		}
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
		if (this.redirectTimer) {
			log('Clearing timers')
			clearTimeout(this.redirectTimer)
			clearTimeout(this.returnToAdpTimer)
		}
	}

	handleScreenSizeChange() {
		DisplayHelper.setBodyBackgroundForAuthFlow()
	}

	returnToAdpLogoutPage() {
		log('Return to ADP logout page')
		this.returnToAdpTimer = setTimeout(() => {
			this.document.location.href = 'https://apps.adp.com/logout'
		}, 2000)
	}

	clearData() {
		this.coreSrvc.dbSrvc.adpSrvc.clearData()
		this.coreSrvc.dbSrvc.empSrvc.clearData()
		this.coreSrvc.dbSrvc.siteSrvc.clearData()
		this.coreSrvc.dbSrvc.jobSrvc.clearData()
		this.coreSrvc.dbSrvc.vacSrvc.clearData()
		this.coreSrvc.dbSrvc.tranSrvc.clearData()
		this.coreSrvc.dbSrvc.excptSrvc.clearLocationData()
		this.coreSrvc.dbSrvc.excptSrvc.clearTransactionData()
		this.coreSrvc.dbSrvc.reportSrvc.clearData()
		this.coreSrvc.dbSrvc.settingSrvc.clearCompanyData()
		this.coreSrvc.dbSrvc.qbSrvc.clearData()
		this.coreSrvc.dbSrvc.billSrvc.paypalClearData()

		this.loginSrvc.clearLastActive()
		this.sessionSrvc.clearCognitoCredentials()
		this.sessionSrvc.clearAdpSession()

		this.clearStaleCognitoData()
		this.redirectTimer = setTimeout(() => {
			this.router.navigate(['/'])
		}, 5000)
	}

	clearStaleCognitoData() {
		for (const key in localStorage) {
			const matcher = `${key}`.toLowerCase()
			if (matcher.includes('cognito')) {
				localStorage.removeItem(key)
			}
		}
	}
}
