import { DeviceDetectorService } from 'ngx-device-detector'

export class DeviceDetectorInfo {
	userAgent = ''
	browser = ''
	browserVersion = ''
	device = ''
	deviceType = ''
	orientation = ''
	os = ''
	osVersion = ''

	constructor(devDetect: DeviceDetectorService) {
		this.userAgent = devDetect.userAgent
		this.browser = devDetect.browser
		this.browserVersion = devDetect.browser_version
		this.device = devDetect.device
		this.deviceType = (devDetect.deviceType || '').toUpperCase()
		this.orientation = (devDetect.orientation || '').toUpperCase()
		this.os = devDetect.os
		this.osVersion = devDetect.os_version
	}

	jsonString(): string {
		return JSON.stringify(this)
	}
}
