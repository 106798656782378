import { Helper } from '@app/helpers/functions'

export class PaypalHelper {
	// Credit Card Functions

	// regexAmex = /^(?:3[47][0-9]{13})$/
	// regexVisa = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/
	// regexMastercard = /^(?:5[1-5][0-9]{14})$/
	// regexDiscover = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/
	// regexDiners = /^(?:3(?:0[0-5]|[68][0-9])[0-9]{11})$/
	// regexJcb = /^(?:(?:2131|1800|35\d{3})\d{11})$/

	private static splitIntoBlocks(value: string, blocks: Array<number>): string {
		const num = Helper.stripNonNumeric(value)
		if (!num) {
			return ''
		}
		const comps = num.split('')
		const result = []
		while (blocks.length) {
			let count = blocks.shift()
			if (!comps.length) {
				break
			}
			while (comps.length && count > 0) {
				const digit = comps.shift()
				result.push(digit)
				count--
			}
			result.push(' ')
		}
		return result.join('').trim()
	}

	/**
	 * Returns type of credit card given card number
	 * @param cardNumber Card number without punctuation
	 * @returns Credit card type
	 */

	static cardType(cardNumber: string): string {
		const type = /^5[1-5]/.test(cardNumber)
			? 'MasterCard'
			: /^4/.test(cardNumber)
			? 'Visa'
			: /^3[47]/.test(cardNumber)
			? 'Amex'
			: /^6011|65|64[4-9]|622(1(2[6-9]|[3-9]\d)|[2-8]\d{2}|9([01]\d|2[0-5]))/.test(cardNumber)
			? 'Discover'
			: ''
		return type
	}

	/**
	 * Formats card number into chunks first stripping non-numeric input
	 * @param cardNumber Card number with/without punctuation
	 * @returns Formatted credit card number
	 */

	static formatCardNumber(type: string, cardNumber: string): string {
		switch (type) {
			case 'Amex':
				return PaypalHelper.splitIntoBlocks(cardNumber, [4, 6, 5, 4])
			default:
				return PaypalHelper.splitIntoBlocks(cardNumber, [4, 4, 4, 4, 4])
		}
	}

	/**
	 * Checks validity of card number using Luhn Algorithm
	 * @param input Card number with/without punctuation
	 * @returns True if valid number or false if not valid
	 */

	static isValidCardNumber(input: string): boolean {
		let cardNumber = Helper.stripNonNumeric(input)
		if (!cardNumber) {
			return false
		}

		// Validate using Luhn Algorithm
		let nCheck = 0,
			nDigit = 0,
			bEven = false
		cardNumber = cardNumber.replace(/\D/g, '')

		for (let n = cardNumber.length - 1; n >= 0; n--) {
			const cDigit = cardNumber.charAt(n)
			nDigit = parseInt(cDigit, 10)

			if (bEven) {
				if ((nDigit *= 2) > 9) {
					nDigit -= 9
				}
			}

			nCheck += nDigit
			bEven = !bEven
		}
		return nCheck % 10 === 0
	}
}
