<div *ngIf="isSaving" class="loading-block">
	<div><i class="fas fa-loader fa-spin"></i>&nbsp;Saving report...</div>
</div>

<div [hidden]="isSaving">
	<div *ngIf="isUnknownReportType">Unknown Report Type</div>

	<div>
		<div>
			<div class="options-title tts-hl-2">Admin Notes</div>
			<textarea [(ngModel)]="notes" class="form-control options-textarea"></textarea>
		</div>

		<div *ngIf="reportType === 'SHIFT'" style="margin-top: 18px">
			<div class="options-title tts-hl-2">Summary</div>
			<textarea [(ngModel)]="summary" class="form-control options-textarea"></textarea>
		</div>

		<div class="options-block" style="margin-top: 20px">
			<div class="checkbox-item d-flex justify-content-between">
				<div class="checkbox-wrap">
					<p-checkbox [(ngModel)]="pinned" [binary]="true"></p-checkbox>
				</div>
				<div class="checkbox-label-wrap">
					<strong (click)="pinned = !pinned" class="has-pointer">Pin to bulletin board</strong>
					<i title="More Information" class="far fa-info-circle tts-info-btn" (click)="showHelp('pinned')"></i>
				</div>
			</div>
		</div>

		<div *ngIf="reportType === 'SHIFT'">
			<app-form-expander
				[style]="{ 'margin-top': '25px', 'font-size': '1.2em', 'font-weight': '500' }"
				[label]="'Additional Information'"
				[centerOnExpansion]="true"
			>
				<div class="input-group mb-3">
					<span class="input-group-text tts-height" id="mileage-addon">Mileage</span>
					<input type="number" inputmode="decimal" class="form-control" placeholder="enter mileage" [(ngModel)]="additionalData.mileage" />
					<select
						class="form-select tts-height"
						[(ngModel)]="additionalData.mileageUnits"
						style="max-width: 80px"
						(ngModelChange)="convertMileage()"
					>
						<option value="KM">km</option>
						<option value="MI">mi</option>
					</select>
				</div>
			</app-form-expander>
		</div>
	</div>

	<div *ngIf="reportType === 'INCIDENT'" style="padding: 5px">
		<app-incident-report [reportData]="reportData" [reportConfig]="reportConfig"></app-incident-report>
	</div>

	<div *ngIf="reportType === 'HOMECARE'" style="padding: 5px">
		<app-homecare-report [reportData]="reportData" [reportConfig]="reportConfig"></app-homecare-report>
	</div>

	<div *ngIf="reportType === 'VEHICLEINSPECTION'" style="padding: 5px">
		<app-vehicle-inspection-report [reportData]="reportData" [reportConfig]="reportConfig"></app-vehicle-inspection-report>
	</div>

	<div *ngIf="reportType === 'CHECKIN' || reportType === 'POST_BREAK' || reportType === 'CHECKOUT' || reportType === 'CUSTOM'" style="padding: 5px">
		<app-checklist-report [reportData]="reportData" [reportConfig]="reportConfig"></app-checklist-report>
	</div>

	<div style="margin-top: 25px">
		<app-file-uploader [fileUploadManager]="fileUploadManager"></app-file-uploader>
	</div>
</div>
