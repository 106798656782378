import { Directive, AfterViewInit } from '@angular/core'
import { log } from '@app/helpers'
import { OverlayOptions } from 'primeng/api'
import { AutoComplete } from 'primeng/autocomplete'

@Directive({
    selector: '[appScrollLockAutoComplete]',
    standalone: false
})
export class AppScrollLockAutoCompleteDirective implements AfterViewInit {
	constructor(private autocomplete: AutoComplete) {}
	ngAfterViewInit() {
		// log('Autocomplete', this.autocomplete)
		const overlayOptions: OverlayOptions = {
			listener: (event, options) => {
				if (options.type === 'outside') {
					return true
				}
			},
		}
		this.autocomplete.config.overlayOptions = overlayOptions
		// this.autocomplete.overlayOptions = overlayOptions
	}
}
