import { v4 as uuid } from 'uuid'

export type ChatBotMessageSource = 'LOCAL' | 'REMOTE'

export class ChatBotMessage {
	id = uuid()
	source: ChatBotMessageSource
	text: string

	constructor(source: ChatBotMessageSource, text: string) {
		this.source = source
		this.text = text
	}
}
