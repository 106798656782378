<div class="map-dialog">
	<div class="modal fade" id="transactionMapModal" data-bs-keyboard="false" data-bs-backdrop="static">
		<div class="modal-dialog">
			<div class="modal-content">
				<!-- Modal Header -->
				<div class="modal-header">
					<!-- style="background-color: #e3e5e8" -->
					<!-- ui-widget-overlay {
					background: #666666;
					opacity: .50;
					filter: Alpha(Opacity=50);
			  } -->
					<h4 class="modal-title">
						Live View Map
						<a href="#" class="view-link" (click)="toggleView()">{{ currentView === 'MAP' ? 'Show List' : 'Show Map' }}</a>
					</h4>
					<button type="button" style="margin-top: 15px; margin-right: 15px" class="close" (click)="onClose()" data-bs-dismiss="modal">
						&times;
					</button>
				</div>

				<!-- Modal body -->
				<!-- <div class="modal-body">
				<app-transaction-map [addr]="address"></app-transaction-map>
			</div> -->

				<!-- Map View -->
				<div id="openTransMap" [hidden]="currentView === 'LIST'" #gmap class="map-box"></div>

				<!-- List View -->
				<div [hidden]="currentView === 'MAP'" class="map-box list-view">
					<div *ngIf="!hasMarkers && transInfos.length === 0">
						<div class="no-transactions">No open transactions<br />for selected range</div>
					</div>

					<div *ngIf="hasMarkers || transInfos.length > 0">
						<ol style="width: 100%">
							<li class="li-trans-info" *ngFor="let ti of transInfos">
								<div>
									<div>
										<span>{{ ti.employeeName }}</span>
										<i
											*ngIf="ti.hasGpsFromUser"
											class="fa fa-map-marker item-icon-sm"
											style="margin-left: 10px; color: #b1a563"
											aria-hidden="true"
										></i>
										<a *ngIf="ti.hasGps; else noGps" href="#" class="trans-info-show float-right" (click)="showTransInfo(ti); (false)"
											>show</a
										>
										<ng-template #noGps>
											<span class="trans-info-no-gps float-right">no gps</span>
										</ng-template>
									</div>
									<div>{{ ti.jobDescription }}</div>
									<div *ngIf="ti.startTime">Started {{ ti.startTime }}</div>
									<div>
										Status:
										<span *ngIf="ti.isOngoing" class="li-ongoing">Ongoing</span>
										<span *ngIf="ti.isMissing" class="li-missing">Missing Checkout</span>
										<span *ngIf="ti.isNoShow" class="li-no-show">No Show</span>
									</div>
									<div *ngIf="false">GPS: {{ ti.hasGpsFromUser ? 'Provided by employee' : 'Retrieved from Job Site' }}</div>
								</div>
							</li>
						</ol>
						<div
							*ngIf="transInfos.length > 0"
							style="margin-top: 25px; margin-bottom: 20px; margin-left: 20px; margin-right: 20px; line-height: 1em"
						>
							<i class="fa fa-map-marker item-icon-sm" style="color: #b1a563" aria-hidden="true"></i> = Employee submitted GPS
						</div>
					</div>
				</div>

				<!-- Modal footer -->
				<div class="modal-footer" style="height: 55px; text-align: left; padding: 15 10 10 15">
					<div class="modal-footer-left" style="font-size: 0.9em">
						<div [hidden]="currentView === 'LIST'" style="margin-left: 15px; font-weight: 700">
							<div [hidden]="transInfos.length === 0" *ngIf="!showRangeSetControls">
								<span style="margin-right: 14px" [class.legend-lighten]="!showOngoingMarkers">
									<img src="/assets/img/marker-green.png" style="height: 28px" />
									<span class="legend-gray" [class.legend-green]="showOngoingMarkers"> Ongoing</span>
								</span>
								<span style="margin-right: 14px" [class.legend-lighten]="!showOngoingMarkers">
									<img src="/assets/img/marker-orange.png" style="height: 28px" />
									<span class="legend-gray" [class.legend-yellow]="showOngoingMarkers"> Issues</span>
								</span>
								<span>
									<img src="/assets/img/marker-red.png" style="height: 28px" />
									<span class="legend-red"> No Show</span>
								</span>
							</div>
						</div>
						<div *ngIf="showRangeSetControls" style="margin-top: -5px; margin-left: 15px">
							<table>
								<tr>
									<td style="font-size: 16px"><strong style="color: #888">Select&nbsp;Range:&nbsp;</strong></td>

									<td style="font-size: 16px">
										<p-select appScrollLockSelect id="range" [options]="viewRange" [(ngModel)]="currentRange"></p-select>
									</td>
									<td>
										<button class="btn btn-sm btn-primary range-btn" (click)="setCurrentRange()">Set</button>
									</td>
								</tr>
							</table>
						</div>
						<div *ngIf="(currentView === 'LIST' || transInfos.length === 0) && !showRangeSetControls" style="margin-left: 15px">
							<strong style="color: #888"
								>Tap button to set range <i style="margin-left: 5px" class="fa fa-arrow-right" aria-hidden="true"></i
							></strong>
						</div>
					</div>
					<button
						*ngIf="!showRangeSetControls"
						class="btn btn-sm btn-outline-secondary float-right"
						style="margin-top: -2px"
						(click)="showRangeSetControls = !showRangeSetControls"
					>
						{{ currentRange }}h
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Google Map Icon Maker
http://www.googlemapsmarkers.com/v1/A/002d99/ (with label)
http://www.googlemapsmarkers.com/v1/0099FF/ (without label) -->
