import { AfterContentInit, ChangeDetectorRef, Component, Input } from '@angular/core'
import { PhoneHelper } from '@app/helpers'
import { AdpIvrPhoneNumberRecord, DataAccessRequest, DialogManager, EditFormAction } from '@app/models'
import { CoreService } from '@app/services'

@Component({
    selector: 'app-adp-ivr-phone-numbers',
    templateUrl: './adp-ivr-phone-numbers.component.html',
    styleUrl: './adp-ivr-phone-numbers.component.scss',
    standalone: false
})
export class AdpIvrPhoneNumbersComponent implements AfterContentInit {
	@Input() dialogManager: DialogManager

	status = { isLoaded: false, isEditing: false }
	editAction = { recordId: null, action: null as EditFormAction }
	searchText: string = null
	list: Array<AdpPhoneNumberCard> = []

	constructor(
		private coreSrvc: CoreService,
		private cd: ChangeDetectorRef,
	) {
		this.loadData()
	}

	ngAfterContentInit() {
		this.dialogManager.submitBtnLabel = 'New'
		this.dialogManager.isSubmitBtnVisible = true
		this.dialogManager.submitBtnAction = () => this.addRecord()
	}

	private loadData() {
		this.coreSrvc.dbSrvc.readTable('adp_ivr_phone_number').then(() => {
			this.list = this.coreSrvc.dbSrvc.adpSrvc.getAdpIvrPhoneNumbers().map((rec) => new AdpPhoneNumberCard(rec))
			this.status.isLoaded = true
		})
	}

	public performSearch(searchText: string) {
		const lcText = (searchText ?? '').toLowerCase()
		this.searchText = lcText
		this.list.forEach((card) => {
			card.peformSearch(lcText)
		})
	}

	public addRecord() {
		this.dialogManager.pushState()
		this.editAction.recordId = null
		this.dialogManager.headerLabel = 'Add ADP IVR Phone Number'
		this.dialogManager.cancelBtnLabel = 'Cancel'
		this.dialogManager.submitBtnLabel = 'Save'
		this.dialogManager.cancelBtnAction = () => this.editComplete()
		this.status.isEditing = true
	}

	public editRecord(card: AdpPhoneNumberCard) {
		this.dialogManager.pushState()
		this.editAction.recordId = card.record.id
		this.dialogManager.headerLabel = 'Update ADP IVR Phone Number'
		this.dialogManager.cancelBtnLabel = 'Cancel'
		this.dialogManager.submitBtnLabel = 'Save'
		this.dialogManager.cancelBtnAction = () => this.editComplete()
		this.status.isEditing = true
	}

	public editComplete() {
		this.list = this.coreSrvc.dbSrvc.adpSrvc.getAdpIvrPhoneNumbers().map((rec) => new AdpPhoneNumberCard(rec))
		this.dialogManager.popStateAndApply()
		this.status.isEditing = false
		this.cd.markForCheck()
	}

	public deleteRecord(card: AdpPhoneNumberCard) {
		card.confirmDelete = true
	}

	public confirmDelete(card: AdpPhoneNumberCard) {
		this.coreSrvc.dbSrvc.deleteRecord('adp_ivr_phone_number', card.record.id).then(() => {
			this.coreSrvc.dbSrvc.removeLocalRecord('adp_ivr_phone_number', card.record.id)
			this.list = this.coreSrvc.dbSrvc.adpSrvc.getAdpIvrPhoneNumbers().map((rec) => new AdpPhoneNumberCard(rec))
		})
	}
}

class AdpPhoneNumberCard {
	record: AdpIvrPhoneNumberRecord
	confirmDelete = false
	isDeleting = false
	matchesSearchFilter: boolean = true
	searchString = ''

	constructor(record: AdpIvrPhoneNumberRecord) {
		this.record = record
		const formattedNumber = PhoneHelper.formatPhoneFromE164(record.phone_e164)
		this.searchString = `${this.record.external_id} ${this.record.phone_e164} ${formattedNumber} ${this.record.description}`.toLowerCase()
	}

	public peformSearch(searchText: string) {
		if (searchText === null || searchText === '') {
			this.matchesSearchFilter = true
			return
		}
		this.matchesSearchFilter = this.searchString.includes(searchText)
	}
}
