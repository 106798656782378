import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { DateTimeHelper, log } from '@app/helpers'
import { AccessHelper } from '@app/helpers/access'
import { PayRateViewManager, PayRateViewTabState, SectionSwitcherConfig, SectionSwitcherTab } from '@app/models'
import { DatabaseService } from '@app/services'
import { EmpJobPayRatesTableComponent } from './emp-job-pay-rates-table/emp-job-pay-rates-table.component'
import { OrgJobPayRatesTableComponent } from './org-job-pay-rates-table/org-job-pay-rates-table.component'
import _ from 'lodash'

class PayRatesViewModel {
	showSectionHelp = false
}

@Component({
    selector: 'app-pay-rates',
    templateUrl: './pay-rates.component.html',
    styleUrls: ['./pay-rates.component.scss'],
    standalone: false
})
export class PayRatesComponent implements OnInit, AfterViewInit {
	accessHelper: AccessHelper
	vm = new PayRatesViewModel()

	switcherConfig = new SectionSwitcherConfig()

	@ViewChild('empJobPayRatesTable') empJobPayRatesTable: EmpJobPayRatesTableComponent
	@ViewChild('orgJobPayRatesTable') orgJobPayRatesTable: OrgJobPayRatesTableComponent

	constructor(private dbSrvc: DatabaseService) {
		this.setupAccessPermissions()
		this.setupTabSwitcher()
	}

	get lastUpdated(): string {
		const date = this.dbSrvc.payRatesSrvc.empJobLastUpdated
		return `Updated ${DateTimeHelper.formatTimeFromDate(date)}`
	}

	get viewManager(): PayRateViewManager {
		return this.dbSrvc.payRatesSrvc.viewManager
	}

	get sectionTitle(): string {
		return this.viewManager.currentView
	}

	get subSectionTitle(): string {
		return _.capitalize(this.sectionTitle)
	}

	get helpLabelRateInfo(): string {
		const currentView = this.viewManager.currentView
		switch (currentView) {
			case 'EMPLOYEE':
				return 'employee pay rates'
			case 'CLIENT':
				return 'client bill rates'
			case 'VENDOR':
				return 'vendor pay rates'
		}
	}

	ngOnInit() {}
	ngAfterViewInit() {
		scrollTo(0, 0)
	}

	// Begin Tab Swticher Methods
	private setupTabSwitcher() {
		const employeeTab = new SectionSwitcherTab('EMPLOYEE', 'Employee')
		const clientTab = new SectionSwitcherTab('CLIENT', 'Client')
		const vendorTab = new SectionSwitcherTab('VENDOR', 'Vendor')
		const config = new SectionSwitcherConfig([employeeTab, clientTab, vendorTab])
		this.switcherConfig = config
		this.switcherConfig.setInitialTabById(this.viewManager.currentView)
	}

	public sectionTabClicked(tab: SectionSwitcherTab) {
		const state = tab.id as PayRateViewTabState
		this.viewManager.currentView = state
		if (state === 'CLIENT' || state === 'VENDOR') {
			this.orgJobPayRatesTable?.setPage(0)
			this.orgJobPayRatesTable?.updateTable()
		}
	}
	// End Tab Switcher Methods

	setupAccessPermissions() {
		// this.accessHelper = new AccessHelper(this.dbSrvc, 'organization')
	}
	canPerformAction(action) {
		// return this.accessHelper.canPerformAction(action, false)
		return true
	}

	toggleSectionHelp() {
		this.vm.showSectionHelp = !this.vm.showSectionHelp
		this.updateFixedHeader()
	}

	updateFixedHeader() {
		setTimeout(() => {
			const table: any = $('#payRatesTable').DataTable()
			table.fixedHeader.adjust()
		}, 100)
	}

	reloadData() {
		this.empJobPayRatesTable?.fetchAndReloadData()
	}

	createRecord() {
		if (this.viewManager.currentView === 'EMPLOYEE') {
			this.empJobPayRatesTable.createRecord()
		} else {
			this.orgJobPayRatesTable.createRecord()
		}
	}
}
