import { Component, OnDestroy, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { BannerStatus, BannerViewManager, ScheduleEntry, ScheduleInfo, ScheduleListItemEntry } from '@app/models'
import { CoreService, DatabaseService } from '@app/services'
import { Subject, Subscription } from 'rxjs'

import moment from 'moment-timezone'
import { log } from '@app/helpers'

@Component({
    selector: 'app-pending-schedules-banner',
    templateUrl: './pending-schedules-banner.component.html',
    styleUrls: ['./pending-schedules-banner.component.scss'],
    standalone: false
})
export class PendingSchedulesBannerComponent implements OnInit, OnDestroy {
	bvm: BannerViewManager
	status: BannerStatus

	subs = new Subscription()
	@Output() receivedUpdate = new Subject<boolean>()

	constructor(
		private router: Router,
		private coreSrvc: CoreService,
	) {
		this.bvm = this.coreSrvc.bannerSrvc.viewManager
		this.status = this.coreSrvc.bannerSrvc.viewManager.pendingSchedules
		this.subs.add(this.coreSrvc.notifySrvc.databaseFinishedLoading.subscribe(() => this.updateUI()))

		this.subs.add(
			this.coreSrvc.dbSrvc.schedulerSrvc.scheduleRecurListUpdated.subscribe(() => {
				log('PendingSchedulesBannerComponent Got update')
				this.updateUI()
			}),
		)
	}
	get shouldShowBanner(): boolean {
		return this.status?.notificationCount > 0
	}

	private updateUI() {
		const pendingList = this.coreSrvc.dbSrvc.schedulerSrvc.getPendingSchedules()
		const pendingCount = pendingList.length
		this.status.notificationCount = pendingCount

		const listItems = pendingList.map((pi) => new ScheduleWrapper(this.coreSrvc.dbSrvc, pi))

		let isWarn = false
		let isDanger = false
		let isCritical = false

		for (const item of listItems) {
			if (item.hoursUntilNextUp <= 24) isWarn = true
			if (item.hoursUntilNextUp <= 12) isDanger = true
			if (item.hoursUntilNextUp <= 1) isCritical = true
		}
		this.status.isWarn = isWarn
		this.status.isDanger = isDanger
		this.status.isCritical = isCritical

		this.coreSrvc.bannerSrvc.groupBannerNeedsUpdate.next(true)
		// log('Pending List', listItems)
	}

	public ngOnInit(): void {}
	public ngOnDestroy(): void {
		this.subs.unsubscribe()
	}
	public viewPendingSchedules() {
		this.coreSrvc.bannerSrvc.bannerPanelShouldHide.next(true)
		this.coreSrvc.dbSrvc.schedulerSrvc.scheduleViewManager.setSeriesViewSubSection('PENDING')
		this.coreSrvc.dbSrvc.schedulerSrvc.toggleDayView('OFF')
		this.router.navigate(['/admin/scheduler'])
	}
}

class ScheduleWrapper {
	hoursUntilNextUp = null
	listItemEntry: ScheduleListItemEntry
	info: ScheduleInfo

	constructor(dbSrvc: DatabaseService, schedRecur: ScheduleEntry) {
		const emp = dbSrvc.empSrvc.getEmployeeById(schedRecur.employee_id)
		const job = dbSrvc.jobSrvc.getJobById(schedRecur.job_id)
		const site = dbSrvc.siteSrvc.getJobSiteById(job?.location_id)
		const timezone = dbSrvc.settingSrvc.getTimezoneZoneNameForId(site?.timezone_id)

		try {
			const listItemEntry = new ScheduleListItemEntry(dbSrvc, emp, job, site, timezone, schedRecur, true)
			const info = listItemEntry?.info
			if (listItemEntry && info) {
				this.listItemEntry = listItemEntry
				this.info = info
				this.setupNextUpTimeCheck()
			}
			// log('Created ScheduleListItemEntry', this.info)
		} catch (err) {
			log('Error creating ScheduleListItemEntry for', schedRecur)
		}
	}

	setupNextUpTimeCheck() {
		const hasNextUp = this.listItemEntry?.hasNextDate
		if (hasNextUp) {
			const nextUpMoment = this.info?.nextUpMom
			const nowMoment = moment()

			const hoursLeft = nextUpMoment.diff(nowMoment, 'hours')
			this.hoursUntilNextUp = hoursLeft
		}
	}
}
