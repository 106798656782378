<div class="container">
	<div class="card wiw-card">
		<strong>When I Work Integration</strong>
		<hr />

		<div *ngIf="!isDataLoaded" class="loading-block"><i class="fas fa-loader fa-spin"></i> Loading Data ...</div>

		<div *ngIf="isWorking" class="loading-block"><i class="fas fa-loader fa-spin"></i> Synchronizing Data ...</div>

		<div *ngIf="!isIntegrated && isDataLoaded">
			<div class="tts-dev-banner">
				<div>When I Work Not Integrated</div>
			</div>
			<div class="alert alert-warning" style="margin-top: 25px">
				Please contact support if you are interested in integrating your When I Work schedule with Telephone Timesheets. For more information
				about the scheduling solution they offer, please visit their website.
				<div class="link-text" style="margin-top: 12px" (click)="gotoWhenIWorkSite()">View When I Work Website</div>
			</div>
		</div>

		<div *ngIf="isIntegrated && isDataLoaded && !isWorking">
			<div>
				<button class="btn btn-sm btn-success btn-block" (click)="syncNow()">
					<i *ngIf="!isWorking" class="fa fa-cloud-download" aria-hidden="true"></i>
					<i *ngIf="isWorking" class="fas fa-loader fa-spin" aria-hidden="true"></i>
					Sync Now
				</button>
			</div>

			<!-- <div *ngIf="false" class="alert alert-warning issues-alert" style="margin-top: 18px;height: 200px;overflow-y: scroll;">
				<div class="issues-header">The following issues must be resolved before you can synchronize your data with When I Work</div>
				<ol *ngIf="syncIssues.length > 0">
					<li *ngFor="let issue of syncIssues">{{ issue.message }}</li>
				</ol>
			</div> -->

			<hr />

			<!-- Employee Data -->
			<div>
				<div class="row">
					<div class="col-md-6 col-sm-12 sync-label">Employee Data</div>
					<div class="col-md-6 col-sm-12 sync-label">
						<i class="far fa-info-circle tts-info-btn sync-info-btn" (click)="vm.showEmpTooltip = !vm.showEmpTooltip"></i>
						<span>Last import on:</span>
						<div style="font-size: 0.8em">{{ vm.empSyncDate || 'Awaiting Initial Import' }}</div>
					</div>
				</div>
				<div style="margin-top: 20px">
					<button
						*ngIf="vm.empSyncErrorCount > 0"
						class="btn btn-block btn-sm btn-danger int-log-btn"
						[routerLink]="['/admin/integrations/log/wiw/Employee']"
					>
						View Log ( {{ vm.empSyncErrorCount }} Error{{ vm.empSyncErrorCount > 1 ? 's' : '' }} )
					</button>
					<button
						*ngIf="vm.empSyncErrorCount === 0"
						class="btn btn-block btn-sm btn-secondary int-log-btn"
						[routerLink]="['/admin/integrations/log/wiw/Employee']"
					>
						View Log ( No Errors )
					</button>
				</div>
				<div class="row" *ngIf="vm.showEmpTooltip">
					<div class="col-12 int-tooltip-block text-muted">
						Click the Sync Now button to perform an initial import of your employees, and to import new or modified employees maintained by When
						I Work.
					</div>
				</div>
				<hr class="int-log-divider" />
			</div>

			<!-- Position Data -->
			<div>
				<div class="row">
					<div class="col-md-6 col-sm-12 sync-label">Position Data</div>
					<div class="col-md-6 col-sm-12 sync-label">
						<i class="far fa-info-circle tts-info-btn sync-info-btn" (click)="vm.showPosTooltip = !vm.showPosTooltip"></i>
						<span>Last import on:</span>
						<div style="font-size: 0.8em">{{ vm.posSyncDate || 'Awaiting Initial Import' }}</div>
					</div>
				</div>
				<div style="margin-top: 20px">
					<button
						*ngIf="vm.posSyncErrorCount > 0"
						class="btn btn-block btn-sm btn-danger int-log-btn"
						[routerLink]="['/admin/integrations/log/wiw/Position']"
					>
						View Log ( {{ vm.posSyncErrorCount }} Error{{ vm.posSyncErrorCount > 1 ? 's' : '' }} )
					</button>
					<button
						*ngIf="vm.posSyncErrorCount === 0"
						class="btn btn-block btn-sm btn-secondary int-log-btn"
						[routerLink]="['/admin/integrations/log/wiw/Position']"
					>
						View Log ( No Errors )
					</button>
				</div>
				<div class="row" *ngIf="vm.showPosTooltip">
					<div class="col-12 int-tooltip-block text-muted">
						Click the Import Sync Now button to perform an initial import of your positions, and to import new or modified positions maintained
						by When I Work.
					</div>
				</div>
				<hr class="int-log-divider" />
			</div>

			<!-- Location Data -->
			<div>
				<div class="row">
					<div class="col-md-6 col-sm-12 sync-label">Location Data</div>
					<div class="col-md-6 col-sm-12 sync-label">
						<i class="far fa-info-circle tts-info-btn sync-info-btn" (click)="vm.showLocTooltip = !vm.showLocTooltip"></i>
						<span>Last import on:</span>
						<div style="font-size: 0.8em">{{ vm.locSyncDate || 'Awaiting Initial Import' }}</div>
					</div>
				</div>
				<div style="margin-top: 20px">
					<button
						*ngIf="vm.locSyncErrorCount > 0"
						class="btn btn-block btn-sm btn-danger int-log-btn"
						[routerLink]="['/admin/integrations/log/wiw/Location']"
					>
						View Log ( {{ vm.locSyncErrorCount }} Error{{ vm.locSyncErrorCount > 1 ? 's' : '' }} )
					</button>
					<button
						*ngIf="vm.locSyncErrorCount === 0"
						class="btn btn-block btn-sm btn-secondary int-log-btn"
						[routerLink]="['/admin/integrations/log/wiw/Location']"
					>
						View Log ( No Errors )
					</button>
				</div>
				<div class="row" *ngIf="vm.showLocTooltip">
					<div class="col-12 int-tooltip-block text-muted">
						Click the Import Sync Now button to perform an initial import of your locations, and to import new or modified locations maintained
						by When I Work.
					</div>
				</div>
				<hr class="int-log-divider" />
			</div>

			<!-- Schedule Data -->
			<div>
				<div class="row">
					<div class="col-md-6 col-sm-12 sync-label">Schedule Data</div>
					<div class="col-md-6 col-sm-12 sync-label">
						<i class="far fa-info-circle tts-info-btn sync-info-btn" (click)="vm.showSchedTooltip = !vm.showSchedTooltip"></i>
						<span>Last import on:</span>
						<div style="font-size: 0.8em">{{ vm.schedSyncDate || 'Awaiting Initial Import' }}</div>
					</div>
				</div>
				<div style="margin-top: 20px">
					<button
						*ngIf="vm.schedSyncErrorCount > 0"
						class="btn btn-block btn-sm btn-danger int-log-btn"
						[routerLink]="['/admin/integrations/log/wiw/Schedule']"
					>
						View Log ( {{ vm.schedSyncErrorCount }} Error{{ vm.schedSyncErrorCount > 1 ? 's' : '' }} )
					</button>
					<button
						*ngIf="vm.schedSyncErrorCount === 0"
						class="btn btn-block btn-sm btn-secondary int-log-btn"
						[routerLink]="['/admin/integrations/log/wiw/Schedule']"
					>
						View Log ( No Errors )
					</button>
				</div>
				<div class="row" *ngIf="vm.showSchedTooltip">
					<div class="col-12 int-tooltip-block text-muted">
						Click the Import Sync Now button to perform an initial import of your schedules, and to import new or modified schedules maintained
						by When I Work.
					</div>
				</div>
				<!-- <hr class="int-log-divider"> -->
			</div>
		</div>
	</div>
</div>
