<div *ngIf="hasImages">
	<div class="images-block d-flex flex-wrap justify-content-start">
		<div *ngFor="let image of images; let idx = index" class="image-wrap" [class.p-image-error]="hasRangeError(idx)">
			<p-image
				#pImage
				[src]="image.getUrl()"
				alt="Photo {{ idx + 1 }}"
				width="100"
				appendTo="body"
				[preview]="true"
				(onShow)="showNavIcons(pImage, idx)"
				(onHide)="hideNavIcons()"
			>
				<ng-template #indicator>
					<div class="img-indicator">
						<div class="sub-info">Photo {{ idx + 1 }}</div>
						<div *ngIf="timezone && image.ts">
							<div class="sub-info" style="display: inline-block">{{ photoDate(image) }}</div>
							<div class="sub-info" style="display: inline-block">{{ photoTime(image) }}</div>
						</div>
					</div>
				</ng-template>
			</p-image>
			<div *ngIf="hasRangeError(idx)" class="time-error" (click)="showHelp('timeError')">
				<span>Issue</span>
				<i title="More Information" class="far fa-info-circle" style="margin-left: 4px"></i>
			</div>
		</div>
	</div>
	<div *ngIf="hasImageDescriptions" class="images-desc-block">
		<div *ngFor="let image of images; let idx = index">
			<div *ngIf="image.description" class="image-desc-wrap">
				<span class="image-desc-label tts-hl-2">Photo {{ idx + 1 }}:</span> {{ image.description ? image.description : descriptionPlaceholder }}
			</div>
		</div>
	</div>
</div>

<div *ngIf="navIconsVisible && images.length > 1" class="d-flex justify-content-between nav-icon-block">
	<button type="button" class="p-image-action p-link image-nav-icon-btn" (click)="previousImage()"><i class="fas fa-arrow-left"></i></button>
	<button type="button" class="p-image-action p-link image-nav-icon-btn" (click)="nextImage()"><i class="fas fa-arrow-right"></i></button>
</div>
