import { Injectable } from '@angular/core'

import { FISync, FISyncRecord, IntegrationLog } from '@app/models'
import { log } from '@app/helpers'
import { Global } from '@app/models/global'

// import _ from 'lodash'

@Injectable({
	providedIn: 'root',
})
export class FileImportService {
	private fiSync: FISync = null
	private fiLog: Array<IntegrationLog> = []

	public fiSyncDataLoaded = false
	public fiLogDataLoaded = false

	constructor() {
		log('Creating FileImportService')
	}

	dataLoaded(): boolean {
		return this.fiSyncDataLoaded
	}

	isFileImportIntegrated(): boolean {
		return !!this.getFISync()
	}

	clearData() {
		this.fiSync = null
		this.fiSyncDataLoaded = false
	}

	getFISync(): FISync {
		return this.fiSync
	}

	setFIWSyncRecords(records: Array<Object>) {
		this.fiSync = records.map((rec) => new FISyncRecord(rec as FISyncRecord)).map((rec) => new FISync(rec))[0] || null
		this.fiSyncDataLoaded = true
	}

	getFILog(): Array<IntegrationLog> {
		return this.fiLog
	}

	setFILogRecords(records: Array<Object>) {
		this.fiLog = records.map((rec) => new IntegrationLog(rec))
		this.fiLogDataLoaded = true
	}

	logRecords() {
		log('FI Service Log loaded records')
	}
}
