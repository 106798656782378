<div class="section-table">
	<table id="tourTable" class="stripe nowrap" cellspacing="0" width="100%"></table>
	<i id="clear-search-icon" (click)="clearSearch()" class="fa fa-times-circle-o" aria-hidden="true"></i>
	<app-table-footer-buttons
		[filename]="'tour_records'"
		[newBtnLabel]="''"
		[exportBtnLabel]="'Export Records'"
		[table]="'tourTable'"
		[marginTop]="'15px'"
	></app-table-footer-buttons>
</div>

<app-modal-dialog *ngIf="sectionPrefsDialogManager.isDialogVisible" [dialogManager]="sectionPrefsDialogManager">
	<app-local-prefs [dialogManager]="sectionPrefsDialogManager" (prefsUpdated)="prefsDataSaved()"></app-local-prefs>
</app-modal-dialog>

<app-modal-dialog *ngIf="linkedRecordsDialogManager.isDialogVisible" [dialogManager]="linkedRecordsDialogManager">
	<div style="margin-bottom: 15px">
		<app-search-filter [placeholder]="'filter records'" (search)="filterLinkedRecords($event)"> </app-search-filter>
	</div>

	<ul style="color: gray">
		<li *ngFor="let record of linkedRecordsDialog.filteredRecords">{{ record }}</li>
	</ul>
</app-modal-dialog>

<app-delete-record-dialog
	*ngIf="deleteAction.showDeleteRecordDialog"
	[showDeleteRecordDialog]="deleteAction.showDeleteRecordDialog"
	[tableName]="deleteAction.tableName"
	[recordId]="deleteAction.recordId"
	[recordLabel]="deleteAction.recordLabel"
	(cancelDelete)="deleteActionCancel()"
	(actionComplete)="deleteActionComplete()"
>
</app-delete-record-dialog>

<app-modal-dialog *ngIf="tourEditDialogManager.isDialogVisible" [dialogManager]="tourEditDialogManager">
	<app-tour-edit
		[dialogManager]="tourEditDialogManager"
		[action]="editAction.action"
		[jobId]="editAction.recordId"
		(recordUpdated)="tourRecordUpdated($event)"
	></app-tour-edit>
</app-modal-dialog>

<app-modal-dialog *ngIf="sectionPrefsDialogManager.isDialogVisible" [dialogManager]="sectionPrefsDialogManager">
	<app-local-prefs [dialogManager]="sectionPrefsDialogManager" (prefsUpdated)="prefsDataSaved()"></app-local-prefs>
</app-modal-dialog>
