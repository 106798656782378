import { DatabaseTableName } from './table'
import { DatabaseService } from '@app/services'
import {
	CrudAction,
	AccessPermission,
	OptionPermission,
	OptionPermissionType,
	OwnerPermission,
	SelectorPermission,
	SelectorPermissionType,
} from './permissions'

export interface HasSupervisorIds {
	supervisor_ids: Array<number>
}

export class PermissionResolver {
	tableName: DatabaseTableName

	managedUserIds: Array<number> = []

	isPrimaryOrInternalUser = false

	owner: OwnerPermission
	access: AccessPermission
	selectors: Array<SelectorPermission> = []
	options: Array<OptionPermission> = []

	constructor(dbSrvc: DatabaseService, tableName: DatabaseTableName) {
		const myUser = dbSrvc.settingSrvc.getMyUser()

		this.tableName = tableName
		this.managedUserIds = myUser.managed_supervisor_ids

		const permissions = myUser.getAccessPermissions()
		this.owner = permissions.getOwnerPermissionsFor(tableName)
		this.access = permissions.getAccessPermissionsFor(tableName)
		this.selectors = permissions.getSelectorPermissionsFor(tableName)
		this.options = permissions.getOptionPermissionsFor(tableName)

		const role = myUser.role
		if (role === 'PRIMARY' || role === 'INTERNAL') {
			this.isPrimaryOrInternalUser = true
		}
	}

	public isSelectorRestrictedFor(type: SelectorPermissionType) {
		const selector = this.selectors.find((s) => s.type === type)
		if (selector) {
			return selector.restricted
		}
		return false
	}

	public isOptionEnabledFor(type: OptionPermissionType) {
		const option = this.options.find((s) => s.type === type)
		if (option) {
			return option.enabled
		}
		return false
	}

	public isMyRecord(record: HasSupervisorIds) {
		// log('Record Sup IDs', record.supervisor_ids)
		// log('Managed Sup Ids', this.managedUserIds)
		if (!record) {
			return false
		}
		if (this.isPrimaryOrInternalUser) {
			return true
		}
		if (record.supervisor_ids.includes(0)) {
			return true
		}
		return record.supervisor_ids.filter((id) => this.managedUserIds.includes(id)).length > 0
	}

	public canPerformAction(action: CrudAction, record: HasSupervisorIds) {
		switch (action) {
			case CrudAction.create:
				return this.owner.create
			case CrudAction.read:
				return this.access.read || (this.owner.read && this.isMyRecord(record))
			case CrudAction.update:
				return this.access.update || (this.owner.update && this.isMyRecord(record))
			case CrudAction.delete:
				return this.access.delete || (this.owner.delete && this.isMyRecord(record))
		}
	}
}
