import { CoreService } from '@app/services'

export class StationFormatter {
	public static makeGroupsLinkedRecordsButton(
		coreSrvc: CoreService,
		bridgeTableName: string,
		dtFilterText: string,
		id: number,
		type: string,
	): string {
		const station = coreSrvc.dbSrvc.stationSrvc.getStationById(id)
		const filterText = dtFilterText?.toLowerCase() ?? ''
		let recordsText = ''
		let recordCount = 0
		let isAllRecords = false
		switch (type) {
			case 'EMPLOYEE':
				const activeEmployees = coreSrvc.dbSrvc.empSrvc.getActiveEmployees()
				const inactiveEmployees = coreSrvc.dbSrvc.empSrvc.getInactiveEmployees()
				const selectedEmployees = station.employee_ids.map((empId) => coreSrvc.dbSrvc.empSrvc.getEmployeeById(empId)).filter((emp) => !!emp)
				const employees = station.employee_ids.length === 0 ? activeEmployees : selectedEmployees
				const employeeNames = employees.map((e) => e.first + ' ' + e.last)
				recordCount = employeeNames.length || 0
				recordsText = employeeNames.join(',').toLowerCase()
				isAllRecords = recordCount === activeEmployees.length + inactiveEmployees.length || selectedEmployees.length === 0
				break
			case 'JOB':
				const activeJobs = coreSrvc.dbSrvc.jobSrvc.getActiveJobs()
				const inactiveJobs = coreSrvc.dbSrvc.jobSrvc.getInactiveJobs()
				const selectedJobs = station.job_ids.map((jobId) => coreSrvc.dbSrvc.jobSrvc.getJobById(jobId)).filter((job) => !!job)
				const jobs = station.job_ids.length === 0 ? activeJobs : selectedJobs
				const jobNames = jobs.map((j) => j.description)
				recordCount = jobNames.length || 0
				recordsText = jobNames.join(',').toLowerCase()
				isAllRecords = recordCount === activeJobs.length + inactiveJobs.length || selectedJobs.length === 0
				break
		}

		// Check if current filter text is in the data for the associated record list
		const filterClass = filterText && recordsText.toLowerCase().includes(filterText) ? ' table-modal-btn-match' : ''
		const recordsTextHtml = recordCount > 0 ? `<div style="display:none;">${recordsText}</div>` : ''

		return recordCount > 0
			? `<button onclick="${bridgeTableName}.showLinkedRecords(${id}, '${type}')" class="btn btn-sm btn-default table-modal-btn${filterClass}" style="width: 105px">${
					isAllRecords ? 'all records' : recordCount === 1 ? `1 record` : recordCount + ` records`
				}</button>${recordsTextHtml}`
			: ''
	}
}
